// Utils
import hasSelectionInCodeBlock from './utils/hasSelectionInCodeBlock';
import getBlockDiv from '../../domUtils/getBlockDiv';
import getSelectionRect from '../../domUtils/getSelectionRect';

/**
 * Because code-blocks are horizontally scrollable, this ensures that
 * when inserting a new character the code block will be scrolled to show it.
 */
export default (char, editorState) => {
    if (!hasSelectionInCodeBlock(editorState)) return;

    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();

    requestAnimationFrame(() => {
        const divElement = getBlockDiv(startKey);
        if (!divElement) return;

        const selectionRect = getSelectionRect();
        const codeBlockBoundingBox = divElement.getBoundingClientRect();

        if (!selectionRect || !codeBlockBoundingBox) return;

        const cursorX = selectionRect.left;
        const codeBlockDivRight = codeBlockBoundingBox.right;

        if (cursorX < codeBlockDivRight - 10) return;

        divElement.scrollLeft = divElement.scrollLeft + 7;
    });
};
