// Lib
import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { inRange } from 'lodash/fp';
import { createStructuredSelector } from 'reselect';

// Utils
import * as rectLib from '../../../../../common/maths/geometry/rect';
import { getElementModalSize } from '../../../modal/elementModalUtils';
import { asObject } from '../../../../../common/utils/immutableHelper';
import { getFilenameFromUrl, appendToFilename } from '../imageModalFileUtils';
import {
    getCaption,
    getElementId,
    getFileMimeType,
    getHasDrawing,
    getImageProp,
    getShowCaption,
} from '../../../../../common/elements/utils/elementPropertyUtils';
import {
    getLargestImageSize,
    getOriginalImage,
    getImageEditorData,
    getImageEditorDataRotation,
    getOriginalImageWidth,
    getOriginalImageHeight,
} from '../imageModalUtils';
import { getImageSource } from '../../imageHelper';

// Actions
import { uploadImage } from '../../../attachments/attachmentActions';
import { updateElement } from '../../../actions/elementActions';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../../../feature/elementFeatureSelector';

// Components
import Icon from '../../../../components/icons/Icon';
import Button from '../../../../components/buttons/Button';
import Spinner from '../../../../components/loaders/Spinner';
import ElementImage from '../../../../components/images/ElementImage';
import Caption from '../../../../components/caption/Caption';
import { SimpleDrawingSvg } from '../../../drawing/DrawingSvg';
import ImageModalToolbarSecondaryTools from '../toolbar/ImageModalToolbarSecondaryTools';

// Constants
import { IMAGE_MODAL_FOOTER_SIZE } from '../imageModalConstants';
import { IMAGE_TYPES } from '../../../../../common/media/mediaConstants';
import { ExperimentId } from '../../../../../common/experiments/experimentsConstants';

// Styles
import './ImageModalEditor.scss';

const mapStateToProps = () =>
    createStructuredSelector({
        renderSecureImagesThroughProxy: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughProxy),
        renderSecureImagesThroughCF: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughCF),
    });

const mapDispatchToProps = (dispatch) => ({
    uploadEditedImage: (elementId, file) =>
        dispatch(
            uploadImage({
                id: elementId,
                file,
                imageType: IMAGE_TYPES.ELEMENT,
            }),
        ),
    updateElementImageData: ({ elementId, imageOriginal, imageEditorData, clearDrawing }) => {
        const update = {
            id: elementId,
            changes: {
                imageOriginal,
                imageEditorData,
                file: null,
                // Remove media param here. That the where the crop information for
                // command-drag aspect-ratio-free crops are saved.
                // So once it's "really" cropped, we can discard
                media: null,
            },
            silent: true,
            sync: true,
        };

        if (clearDrawing) {
            update.changes.drawing = null;
            update.changes.hasDrawing = false;
        }

        return dispatch(updateElement(update));
    },
    revertToOriginalImageData: ({ elementId, imageOriginal, clearDrawing }) => {
        const update = {
            id: elementId,
            changes: {
                image: imageOriginal,
                imageOriginal: null,
                imageEditorData: null,
                file: null,
            },
            silent: true,
            sync: true,
        };

        if (clearDrawing) {
            update.changes.drawing = null;
            update.changes.hasDrawing = false;
        }

        return dispatch(updateElement(update));
    },
});

const positionImageInCanvasCenter = (cropper) => {
    const { width, height } = cropper.getContainerData();
    const { naturalWidth, naturalHeight } = cropper.getImageData();

    const containerSize = Math.min(width, height);
    const imageSize = Math.max(naturalWidth, naturalHeight);

    const zoomScale = containerSize / imageSize;

    cropper.zoomTo(zoomScale, {
        x: width / 2,
        y: height / 2,
    });
};

const ImageModalEditor = (props) => {
    const {
        element,
        gridSize,
        attachment,
        windowWidth,
        windowHeight,
        idealHeight,
        cancelEditing,
        afterSaveCb,
        uploadEditedImage,
        updateElementImageData,
        revertToOriginalImageData,
        renderSecureImagesThroughProxy,
        renderSecureImagesThroughCF,
    } = props;

    const useSecureMediaUrl = true;

    const [initialRotation] = useState(getImageEditorDataRotation(element) || 0);
    const [currentRotation, setCurrentRotation] = useState(getImageEditorDataRotation(element) || 0);
    const [hideDrawing, setHideDrawing] = useState(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);

    const imageRef = useRef();
    const cropperRef = useRef();
    const isDirtyRef = useRef(false);
    const initialCropDataRef = useRef();
    const [editorIsReady, setEditorIsReady] = useState(false);

    const elementId = getElementId(element);
    const hasDrawing = getHasDrawing(element);
    const imageType = getFileMimeType(element) || 'image/jpeg';
    const caption = getShowCaption(element) && getCaption(element);

    const originalImageWidth = getOriginalImageWidth({ element, gridSize, attachment });
    const originalImageHeight = getOriginalImageHeight({ element, gridSize, attachment });

    const isRotatedOnSide = !!currentRotation && currentRotation !== -180;

    // Flip the image width and height if it's rotated onto its side
    const imageWidth = isRotatedOnSide ? originalImageHeight : originalImageWidth;
    const imageHeight = isRotatedOnSide ? originalImageWidth : originalImageHeight;

    const { modalWidth, modalHeight } = getElementModalSize({
        windowWidth,
        windowHeight,
        imageWidth,
        imageHeight,
        idealHeight,
        caption,
        footerSize: IMAGE_MODAL_FOOTER_SIZE,
        modalMinWidth: 0,
        modalMinHeight: 0,
        gridSize,
    });

    const svgWrapperStyle = {
        width: '100%',
        height: '100%',
    };

    const loadingImageStyles = {
        width: isRotatedOnSide ? modalHeight : modalWidth,
        height: isRotatedOnSide ? modalWidth : modalHeight,
        transform: `rotate(${initialRotation || 0}deg)`,
    };

    const imageDetails = getOriginalImage(element);
    const imageSize = getLargestImageSize(imageDetails);
    const largestUneditedImageUrl = getImageSource({
        imageDetails,
        imageSize,
        elementId,
        useSecureMediaUrl,
        renderSecureImagesThroughProxy,
        renderSecureImagesThroughCF,
    });

    useLayoutEffect(() => {
        if (cropperRef.current) return;

        const imageEditorData = getImageEditorData(element) && asObject(getImageEditorData(element));

        cropperRef.current = new Cropper(imageRef.current, {
            dragMode: 'none',
            viewMode: 2,
            center: false,
            movable: false,
            zoomOnTouch: false,
            zoomOnWheel: false,
            toggleDragModeOnDblclick: false,
            autoCrop: true,
            autoCropArea: 1,
            checkOrientation: false,
            ready: () => {
                const cropper = cropperRef.current;

                if (imageEditorData) {
                    cropper.clear();
                    cropper.setData(imageEditorData);
                    cropper.crop();
                    cropper.setData(imageEditorData);
                    positionImageInCanvasCenter(cropper);
                }

                setEditorIsReady(true);
            },
            cropmove: () => {
                isDirtyRef.current = true;
            },
        });

        return () => cropperRef.current.destroy();
    }, []);

    // In order to rotate the cropper instance, we need to wait until the container element is re-rendered,
    // then render the cropper and re-set the crop rectangle
    useLayoutEffect(() => {
        const cropper = cropperRef.current;
        if (!editorIsReady || !cropper?.render) return;

        const initialCropData = initialCropDataRef.current || cropper.getCropBoxData();
        const initialContainerData = cropper.getContainerData();

        // The modal might change scale when it gets rotated due to the a wide or tall aspect ratio.
        // We need to find out the scale change so that we can convert the initial crop rectangle into
        // the final crop rectangle
        const scalingFactor = modalWidth / rectLib.getHeight(initialContainerData);

        cropper.render();

        let newCropRect = {
            top: initialContainerData.width - initialCropData.left - initialCropData.width,
            left: initialCropData.top,
            width: initialCropData.height,
            height: initialCropData.width,
        };
        newCropRect = rectLib.asRect(newCropRect);
        newCropRect = rectLib.scale(scalingFactor, newCropRect);

        cropper.setCropBoxData({
            top: rectLib.getTop(newCropRect),
            left: rectLib.getLeft(newCropRect),
            width: rectLib.getWidth(newCropRect),
            height: rectLib.getHeight(newCropRect),
        });
    }, [currentRotation]);

    const handleRotateImage = () => {
        const cropper = cropperRef.current;

        isDirtyRef.current = true;

        // Save the current crop data, so we can rotate it on re-render (see useLayoutEffect above)
        initialCropDataRef.current = cropper.getCropBoxData();

        cropper.clear();
        cropper.rotate(-90);
        cropper.crop();

        const newRotation = currentRotation === -270 ? 0 : currentRotation - 90;

        setHideDrawing(initialRotation !== newRotation);
        setCurrentRotation(newRotation);
    };

    const onRotateLeftClick = () => {
        if (currentRotation === initialRotation && hasDrawing) return setShowWarningDialog(true);
        return handleRotateImage();
    };

    const handleContinueRotation = () => {
        setShowWarningDialog(false);
        handleRotateImage();
    };

    const handleCancelRotation = () => {
        setShowWarningDialog(false);
    };

    const handleSave = () => {
        const cropper = cropperRef.current;

        // if no changes were made, just exit the editor
        if (!isDirtyRef.current) {
            return afterSaveCb();
        }

        const imageData = cropper.getImageData();
        const imageEditorData = cropper.getData(true);
        const originalImage = getOriginalImage(element);

        const inWidthRange = inRange(
            imageData.naturalWidth - imageData.naturalWidth * 0.01,
            imageData.naturalWidth + imageData.naturalWidth * 0.01,
        );

        const inHeightRange = inRange(
            imageData.naturalHeight - imageData.naturalHeight * 0.01,
            imageData.naturalHeight + imageData.naturalHeight * 0.01,
        );

        const shouldRevertToOriginal =
            originalImage &&
            imageEditorData.rotate === 0 &&
            inWidthRange(imageEditorData.width) &&
            inHeightRange(imageEditorData.height);

        // if the image has returned to an uncropped, un-rotated state, replace the edited image with the original
        if (shouldRevertToOriginal) {
            revertToOriginalImageData({
                elementId,
                imageOriginal: originalImage.toJS(),
                clearDrawing: hideDrawing,
            });

            return afterSaveCb();
        }

        const blobCreatedCb = (blob) => {
            const imageOriginalData = getOriginalImage(element) || getImageProp(element);

            updateElementImageData({
                elementId,
                imageOriginal: imageOriginalData,
                imageEditorData,
                clearDrawing: hideDrawing,
            });

            // create extra data required to make Blob into a File
            const originalFilename = getFilenameFromUrl(largestUneditedImageUrl, imageType);
            const imageFileName = appendToFilename(originalFilename);

            blob.lastModifiedDate = new Date();
            blob.name = imageFileName;

            uploadEditedImage(elementId, blob);

            afterSaveCb();
        };

        cropper
            .getCroppedCanvas({
                width: imageEditorData.width,
                height: imageEditorData.height,
                maxWidth: 4096,
                maxHeight: 4096,
            })
            .toBlob(blobCreatedCb, imageType, 0.85);
    };

    return (
        <>
            <div className="image-modal-content">
                <div className="image-container">
                    <div className="ImageEditor">
                        <div
                            className={classNames('editor', { loading: !editorIsReady })}
                            style={{ width: modalWidth, height: modalHeight }}
                        >
                            {!editorIsReady && (
                                <div className="loading-message">
                                    <div className="loading-image" style={loadingImageStyles}>
                                        <ElementImage
                                            useSecureMediaUrl={useSecureMediaUrl}
                                            showBrokenIconOnError
                                            element={element}
                                            imageData={getOriginalImage(element)}
                                            imageType={IMAGE_TYPES.ELEMENT}
                                            widthPx={modalWidth}
                                            key={elementId}
                                        />
                                    </div>
                                    <SimpleDrawingSvg {...props} />
                                    <div className="loading-overlay">
                                        <Spinner show />
                                        Loading editor...
                                    </div>
                                </div>
                            )}

                            <img
                                ref={(c) => {
                                    imageRef.current = c;
                                }}
                                className="editor-source-image"
                                style={{ width: modalWidth, height: modalHeight }}
                                src={largestUneditedImageUrl}
                            />
                            {hasDrawing && !hideDrawing && editorIsReady && (
                                <div className="drawing-svg-container">
                                    <div className="drawing-wrapper" style={svgWrapperStyle}>
                                        <SimpleDrawingSvg {...props} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Caption
                        element={element}
                        textContent={getCaption(element)}
                        captionVisible={getShowCaption(element)}
                    />
                    {showWarningDialog && (
                        <div className="warning-dialog">
                            <div className="dialog-body">
                                <div className="dialog-text">
                                    <span className="warning-label">Warning:</span>
                                    &nbsp;rotating this image will clear the drawing
                                </div>

                                <div className="buttons">
                                    <Button className="StyledButton secondary" onClickFn={handleCancelRotation}>
                                        Cancel
                                    </Button>
                                    <Button className="StyledButton danger" onClickFn={handleContinueRotation}>
                                        Continue anyway
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ImageModalToolbarSecondaryTools className="ImageEditorModalToolbar">
                <Button
                    className="StyledButton secondary image-control rotate"
                    onClickFn={onRotateLeftClick}
                    disabled={!editorIsReady || showWarningDialog}
                >
                    <Icon name="modal-toolbar-rotate" />
                    Rotate left
                </Button>

                <Button className="StyledButton secondary cancel no-hover" onClickFn={cancelEditing}>
                    Cancel
                </Button>

                <Button
                    className="StyledButton primary save"
                    onClickFn={handleSave}
                    disabled={!editorIsReady || showWarningDialog}
                >
                    Save
                </Button>
            </ImageModalToolbarSecondaryTools>
        </>
    );
};

ImageModalEditor.propTypes = {
    element: PropTypes.object,
    gridSize: PropTypes.number,
    attachment: PropTypes.object,
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
    idealHeight: PropTypes.number,
    originalImageWidth: PropTypes.number,
    originalImageHeight: PropTypes.number,
    uploadEditedImage: PropTypes.func,
    updateElementImageData: PropTypes.func,
    revertToOriginalImageData: PropTypes.func,
    cancelEditing: PropTypes.func,
    afterSaveCb: PropTypes.func,
    renderSecureImagesThroughProxy: PropTypes.bool,
    renderSecureImagesThroughCF: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModalEditor);
