// Lib
import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { isEmpty } from 'lodash/fp';

export const handleFileUploadDrop = (props, monitor) => {
    const { uploadMultipleFiles, uploadFile, transactionId } = props;
    const { files } = monitor.getItem();

    if (isEmpty(files)) return;

    if (uploadMultipleFiles) {
        const uploadPromise = uploadMultipleFiles(files, transactionId);

        return {
            uploadPromise,
        };
    }

    const file = monitor.getItem().files && monitor.getItem().files[0];
    if (file && uploadFile) {
        const uploadPromise = uploadFile(file, transactionId);

        return {
            uploadPromise,
        };
    }
};

const FileDropTargetHOC = (Element) => {
    const FileDropTarget = (props) => {
        const [dropProps, dropConnector] = useDrop({
            accept: NativeTypes.FILE,
            drop: (item, monitor) => handleFileUploadDrop(props, monitor),
            collect: (monitor) => ({
                fileIsHovered: monitor.canDrop() && monitor.isOver(),
                fileCanDrop: monitor.canDrop(),
            }),
        });

        return <Element {...props} {...dropProps} connectFileDropTarget={dropConnector} />;
    };
    return FileDropTarget;
};

export default FileDropTargetHOC;
