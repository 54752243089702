export const ELEMENT_MOVE = 'ELEMENT_MOVE';
export const ELEMENT_CREATE = 'ELEMENT_CREATE';
export const ELEMENT_DELETE = 'ELEMENT_DELETE';
export const ELEMENT_UPDATE = 'ELEMENT_UPDATE';
export const ELEMENT_DIFF_UPDATE = 'ELEMENT_DIFF_UPDATE';
export const ELEMENT_SET_TYPE = 'ELEMENT_SET_TYPE';
export const ELEMENT_MOVE_AND_UPDATE = 'ELEMENT_MOVE_AND_UPDATE';
export const ELEMENT_CONVERT_TO_CLONE = 'ELEMENT_CONVERT_TO_CLONE';
export const ELEMENT_UNDO_CONVERT_TO_CLONE = 'ELEMENT_UNDO_CONVERT_TO_CLONE';
export const ELEMENT_MARK_AS_CLONED = 'ELEMENT_MARK_AS_CLONED';

export const ELEMENT_MOVE_MULTI = 'ELEMENT_MOVE_MULTI';

export const ELEMENT_UPDATE_ACL = 'ELEMENT_UPDATE_ACL';

// Server interactions
export const ELEMENT_FORCE_LOAD = 'ELEMENT_FORCE_LOAD';

// Attachments
export const ELEMENT_ATTACHMENT_UPLOADING = 'ELEMENT_ATTACHMENT_UPLOADING';
export const ELEMENT_ATTACHMENT_DATA_READY = 'ELEMENT_ATTACHMENT_DATA_READY';
export const ELEMENT_ATTACHMENT_CANCEL_FN_SET = 'ELEMENT_ATTACHMENT_CANCEL_FN_SET';
export const ELEMENT_ATTACHMENT_UPLOAD_PROGRESSED = 'ELEMENT_ATTACHMENT_UPLOAD_PROGRESSED';
export const ELEMENT_ATTACHMENT_UPLOAD_COMPLETED = 'ELEMENT_ATTACHMENT_UPLOAD_COMPLETED';
export const ELEMENT_ATTACHMENT_CLEAN = 'ELEMENT_ATTACHMENT_CLEAN';
export const ELEMENT_ATTACHMENT_UPLOAD_ERROR = 'ELEMENT_ATTACHMENT_UPLOAD_ERROR';
export const ELEMENT_ATTACHMENT_CLEAR_ERROR = 'ELEMENT_ATTACHMENT_CLEAR_ERROR';
export const ELEMENT_ATTACHMENT_CLEAR_ALL_ERRORS = 'ELEMENT_ATTACHMENT_CLEAR_ALL_ERRORS';

export const ELEMENT_ATTACHMENT_PROGRESS_RESET = 'ELEMENT_ATTACHMENT_PROGRESS_RESET';
export const ELEMENT_ATTACHMENT_DOWNLOAD_PROGRESSED = 'ELEMENT_ATTACHMENT_DOWNLOAD_PROGRESSED';
export const ELEMENT_ATTACHMENT_DOWNLOAD_COMPLETED = 'ELEMENT_ATTACHMENT_DOWNLOAD_COMPLETED';
export const ELEMENT_ATTACHMENT_DOWNLOADING = 'ELEMENT_ATTACHMENT_DOWNLOADING';

// Undo
export const ELEMENT_ATTACHMENT_ACCEPT_UNDO = 'ELEMENT_ATTACHMENT_ACCEPT_UNDO';
export const ELEMENT_ATTACHMENT_CANCEL_UPLOAD = 'ELEMENT_ATTACHMENT_CANCEL_UPLOAD';

// Icons
export const ELEMENT_ICON_FIND = 'ELEMENT_ICON_FIND';
export const ELEMENT_ICON_FIND_SUCCESS = 'ELEMENT_ICON_FIND_SUCCESS';
export const ELEMENT_ICON_FIND_FAILURE = 'ELEMENT_ICON_FIND_FAILURE';
export const ELEMENT_ICON_SEARCH = 'ELEMENT_ICON_SEARCH';

// Local client state
export const ELEMENT_SET_LOCAL_DATA_MULTI = 'ELEMENT_SET_LOCAL_DATA_MULTI';

export const ELEMENT_DND_TYPE = 'ELEMENT';

export const DROP_TARGET_BEHAVIOUR = {
    SHRINK: 'shrink',
    SHRINK_LIST: 'shrink-list',
    SHRINK_TEMPLATE_LIST: 'shrink-template-list',
    CANVAS: 'drop-canvas',
    UNINTERACTABLE: 'drop-uninteractable',
    NONE: null,
};

export const FILTER_STATES = {
    NONE: null,
    FOCUS: 'filter-focus',
    PASS: 'filter-pass',
    FAIL: 'filter-fail',
};

export const DROP_TARGET_TYPES = {
    IMAGE: DROP_TARGET_BEHAVIOUR.SHRINK,
    BOARD: DROP_TARGET_BEHAVIOUR.SHRINK,
    TRASH: DROP_TARGET_BEHAVIOUR.SHRINK,
    BREADCRUMB: DROP_TARGET_BEHAVIOUR.SHRINK,
    INBOX_CLOSED: DROP_TARGET_BEHAVIOUR.SHRINK,
    WORKSPACE: DROP_TARGET_BEHAVIOUR.SHRINK,
    LIST: DROP_TARGET_BEHAVIOUR.SHRINK_LIST,
    TEMPLATE_LIST: DROP_TARGET_BEHAVIOUR.SHRINK_TEMPLATE_LIST,
    CANVAS: DROP_TARGET_BEHAVIOUR.CANVAS,
    NONE: DROP_TARGET_BEHAVIOUR.NONE,
};

export const ELEMENT_UPDATE_TYPE = {
    ASSIGNMENT: 'ASSIGNMENT',
    TOGGLE_ASSIGNMENT: 'TOGGLE_ASSIGNMENT',
    TOGGLE_COMPLETION: 'TOGGLE_COMPLETION',
    DUE_DATE: 'DUE_DATE',
    TOGGLE_DUE_DATE: 'TOGGLE_DUE_DATE',
    REACTION: 'REACTION',
    TOGGLE_REACTION: 'TOGGLE_REACTION',
    COLOR_SPACE: 'COLOR_SPACE',
    CANVAS_ORIGIN: 'CANVAS_ORIGIN',
    TABLE: 'TABLE',
    // This is used when a diff update error occurs, so the client reverts to a full update
    DIFF_UPDATE_ERROR_RECOVERY: 'DIFF_UPDATE_ERROR_RECOVERY',
};

export const ELEMENT_CREATION_SOURCES = {
    SHORTCUT_KEY: 'SHORTCUT_KEY',
    CMD_ENTER: 'CMD_ENTER',
    DOUBLE_CLICK: 'DOUBLE_CLICK',
    IMAGE_SEARCH: 'IMAGE_SEARCH',
    TEMPLATE_POPUP: 'TEMPLATE_POPUP',
    FILE_UPLOAD_POPUP: 'FILE_UPLOAD_POPUP',
    IMAGE_UPLOAD_POPUP: 'IMAGE_UPLOAD_POPUP',
    TOOLBAR: 'TOOLBAR',
    DROPPED_ONTO_WINDOW: 'DROPPED_ONTO_WINDOW',
    CONTEXT_MENU: 'CONTEXT_MENU',
    QUICK_LINE: 'QUICK_LINE',
    AI_ASSISTANT: 'AI_ASSISTANT',
    WORKSPACE_BUTTON: 'WORKSPACE_BUTTON',
    MOBILE_UPLOAD: 'MOBILE_UPLOAD',
};

export const ELEMENT_MOVE_OPERATIONS = {
    DROP: 'DROP',
    TASK: 'TASK',
    COLLISION: 'COLLISION',
    NUDGE: 'NUDGE',
    ALIGN: 'ALIGN',
    TRASH: 'TRASH',
    CUT: 'CUT',
    CONNECTION_TRASH: 'CONNECTION_TRASH',
    CONNECTION_SWITCH: 'CONNECTION_SWITCH',
    PERMANENT_DELETE: 'PERMANENT_DELETE',
    CHANGE_ORDER: 'CHANGE_ORDER',
    UNSORTED: 'UNSORTED',
    REST: 'REST',
};

export const ELEMENT_ATTACHMENT_ACTION_TYPES = {
    UPLOAD: 'UPLOAD',
    DOWNLOAD: 'DOWNLOAD',
};

export const ELEMENT_DEFAULT_WIDTH = 34;
export const ELEMENT_SMALL_WIDTH = 8;

export const ELEMENT_VERSIONABLE_CONTENT_FIELDS = ['textContent', 'tableContent'];
