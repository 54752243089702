import * as Immutable from 'immutable';

import {
    IMAGE_SEARCH_SUBMIT,
    IMAGE_SEARCH_SUCCESS,
    IMAGE_SEARCH_FAILURE,
    IMAGE_SEARCH_SET_MODE,
    IMAGE_SEARCH_POPUP_MODE_DEFAULT,
    IMAGE_SEARCH_POPUP_MODE_UPLOAD,
} from './imageSearchPopupConstants';

const EMPTY_LIST = Immutable.List();

const initialState = Immutable.Map({
    loading: false,
    results: Immutable.Map(),
    resultsTotalCount: Immutable.Map(),
    submittedQuery: null,
    error: null,
    recentSearchList: EMPTY_LIST,
    mode: IMAGE_SEARCH_POPUP_MODE_DEFAULT,
});

const searchHistoryReducer = (state = 'default', { type, mode }) => {
    switch (type) {
        case IMAGE_SEARCH_SET_MODE:
            return state.includes(mode) ||
                mode === IMAGE_SEARCH_POPUP_MODE_DEFAULT ||
                mode === IMAGE_SEARCH_POPUP_MODE_UPLOAD
                ? state
                : state.push(mode);
        default:
            return state;
    }
};

const appendSearchResults = (resultsList = EMPTY_LIST, action) => {
    const results = Immutable.fromJS(action.results);
    return resultsList.concat(results);
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_SEARCH_SUBMIT:
            return state.set('loading', true).set('submittedQuery', action.query).set('error', null);
        case IMAGE_SEARCH_SUCCESS:
            return state
                .setIn(['results', action.query], appendSearchResults(state.getIn(['results', action.query]), action))
                .setIn(['resultsTotalCount', action.query], action.total)
                .set('loading', false)
                .set('error', null);
        case IMAGE_SEARCH_FAILURE:
            return state.set('loading', false).set('error', action.error);
        case IMAGE_SEARCH_SET_MODE:
            return state
                .set('mode', action.mode)
                .set('recentSearchList', searchHistoryReducer(state.get('recentSearchList'), action));
        default:
            return state;
    }
};
