// Utils
import { http } from '../../../utils/services/http';

// Constants
import { METHODS } from '../../../../common/utils/http/httpConstants';

import { GetProductsResponse, GetProductResponse } from '../../../../common/api/payments/checkout/productRouteTypes';
import { CheckoutProduct } from '../../../../common/payment/products/productTypes';

export const fetchPublicCheckoutProducts = async (): Promise<CheckoutProduct[]> => {
    const { data } = await http<never, GetProductsResponse>({
        url: 'payments/products',
        method: METHODS.GET,
        timeout: 30000,
    });

    return data.products;
};

export const fetchCheckoutProduct = async (productId: string): Promise<CheckoutProduct | undefined> => {
    const { data } = await http<never, GetProductResponse>({
        url: `payments/products/${productId}`,
        method: METHODS.GET,
        timeout: 30000,
    });

    return data.product;
};
