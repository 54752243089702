// Lib
import { curry } from 'lodash/fp';

// Utils
import { length } from '../../../utils/immutableHelper';

// Geometry
import { getX, getY } from '../dimensionUtil';
import { asRect, getBottom, getLeft, getRight, getTop } from './rectPropertyUtils';
import { getBottomRight, getTopLeft } from './rectPointUtils';

export const isEqual = (rect1, rect2) =>
    getLeft(rect1) === getLeft(rect2) &&
    getRight(rect1) === getRight(rect2) &&
    getTop(rect1) === getTop(rect2) &&
    getBottom(rect1) === getBottom(rect2);

export const containsPoint = curry(
    (rect, point) =>
        (rect &&
            point &&
            getX(point) >= getLeft(rect) &&
            getX(point) <= getRight(rect) &&
            getY(point) >= getTop(rect) &&
            getY(point) <= getBottom(rect)) ||
        false,
);

export const containsRect = curry(
    (containerRect, otherRect) =>
        getLeft(otherRect) >= getLeft(containerRect) &&
        getRight(otherRect) <= getRight(containerRect) &&
        getTop(otherRect) >= getTop(containerRect) &&
        getBottom(otherRect) <= getBottom(containerRect),
);

export const pointIsOnPerimeter = curry((rect, point) => {
    if (!rect || !point) return false;

    const x = getX(point);
    const y = getY(point);
    const left = getLeft(rect);
    const right = getRight(rect);
    const top = getTop(rect);
    const bottom = getBottom(rect);

    return (
        ((x === left || x === right) && y >= top && y <= bottom) ||
        ((y === top || y === bottom) && x >= left && x <= right)
    );
});

export const isOverlapping = curry((rect1, rect2) => {
    if (!rect1 || !rect2) return false;

    const topLeft1 = getTopLeft(rect1);
    const bottomRight1 = getBottomRight(rect1);
    const topLeft2 = getTopLeft(rect2);
    const bottomRight2 = getBottomRight(rect2);

    return !(
        bottomRight1.x <= topLeft2.x ||
        bottomRight1.y <= topLeft2.y ||
        bottomRight2.x <= topLeft1.x ||
        bottomRight2.y <= topLeft1.y
    );
});

export const overlappingRect = curry((rect1, rect2) => {
    if (!isOverlapping(rect1, rect2)) return null;

    // The opposite of the bounding rect
    return asRect({
        left: Math.max(getLeft(rect1), getLeft(rect2)),
        right: Math.min(getRight(rect1), getRight(rect2)),
        top: Math.max(getTop(rect1), getTop(rect2)),
        bottom: Math.min(getBottom(rect1), getBottom(rect2)),
    });
});

// FIXME Refactor when tests are written, could probably do some sort of reduce with the below method
export const getBoundingRect = (rects) => {
    // Return an empty rectangle when no rectangles are provided
    if (!rects || length(rects) < 1) return asRect({ x: 0, y: 0, width: 0, height: 0 });

    return asRect({
        left: Math.min(...rects.map(getLeft)),
        right: Math.max(...rects.map(getRight)),
        top: Math.min(...rects.map(getTop)),
        bottom: Math.max(...rects.map(getBottom)),
        width: Math.max(...rects.map(getRight)) - Math.min(...rects.map(getLeft)),
        height: Math.max(...rects.map(getBottom)) - Math.min(...rects.map(getTop)),
    });
};

export const getSimpleBoundingRect = (rect1, rect2) =>
    asRect({
        left: Math.min(getLeft(rect1), getLeft(rect2)),
        right: Math.max(getRight(rect1), getRight(rect2)),
        top: Math.min(getTop(rect1), getTop(rect2)),
        bottom: Math.max(getBottom(rect1), getBottom(rect2)),
    });
