import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const LinkVideoIconIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon link-video-icon"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM6.071 5.095a.571.571 0 0 0-.571.571v4.668a.573.573 0 0 0 .847.5l4.243-2.333a.572.572 0 0 0 0-1.002L6.347 5.166a.57.57 0 0 0-.276-.071z"
        />
    </svg>
);
const Memo = memo(LinkVideoIconIcon);
export default Memo;
