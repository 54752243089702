// Lib
import { flow, pickBy, mapKeys, startsWith } from 'lodash/fp';
import { createSelector } from 'reselect';

// Selectors
import { getLocation, getLocationQuery } from '../../app/routingSelector';

// Constants
import { TEMPLATE_USER_INITIAL_EDUCATION } from '../../../common/users/userEducationConstants';
import { APP_QUERY_PARAMS } from '../../../common/utils/urlConstants';

// On-boarding state
export const getCurrentOnboardingStep = (state) => state.getIn(['app', 'onboarding', 'currentStep']);
export const getCurrentOnboardingStepId = (state) => state.getIn(['app', 'onboarding', 'currentStep', 'id']);
export const getOnboardingListeners = (state) => state.getIn(['app', 'onboarding', 'listeners']);

// Routes
const FLOW_METADATA_PREFIX = 'data_';

const startsWithMetadataPrefix = startsWith(FLOW_METADATA_PREFIX);

const getQueryMetadata = flow(
    pickBy((v, k) => startsWithMetadataPrefix(k)),
    mapKeys((k) => k.replace(FLOW_METADATA_PREFIX, '')),
);

export const onboardingSelector = createSelector(
    getLocationQuery,
    (query) => query[APP_QUERY_PARAMS.INITIAL_ONBOARDING],
);

export const onboardingRouteFlowIdSelector = createSelector(
    getLocationQuery,
    (query) => query[APP_QUERY_PARAMS.ONBOARDING_FLOW_ID],
);

export const onboardingLoggedOutRedirectSelector = createSelector(
    getLocationQuery,
    (query) => query[APP_QUERY_PARAMS.LOGGED_OUT_REDIRECT],
);

export const onboardingMetadataSelector = createSelector(getLocationQuery, (query) => getQueryMetadata(query));

export const onboardingInitialEducationSelector = createSelector(
    getLocationQuery,
    (query) => query[APP_QUERY_PARAMS.INITIAL_EDUCATION] || TEMPLATE_USER_INITIAL_EDUCATION,
);

export const locationPath = createSelector(getLocation, (location) => `${location.pathname}${location.search}`);
