// Lib
import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Utils
import { asObject, prop } from '../../../../common/utils/immutableHelper';
import { shiftPaths } from '../drawingEditor/drawingEditorUtils';

// Components
import DrawingSvgRenderer from '../drawingEditor/drawingEditorCanvas/DrawingSvgRenderer';

// Constants
import { SKETCH_DEFAULT_ASPECT_RATIO } from '../../../../common/drawings/sketches/sketchConstants';

const SKETCH_PADDING_PERCENTAGE = 5 / 100;

/**
 * Converts annotation drawing data into a centered, padded SVG & paths
 * for use in a sketch card.
 */
export const convertAnnotationDataToSketchData = (drawing) => {
    const paths = asObject(prop('paths', drawing));

    if (!paths) return;

    // Initial dimensions
    const w0 = prop('width', drawing);
    const h0 = prop('height', drawing);

    // Padding
    const padding = Math.max(w0 * SKETCH_PADDING_PERCENTAGE, h0 * SKETCH_PADDING_PERCENTAGE);

    // Dimensions with padding
    const w1 = w0 + 2 * padding;
    const h1 = h0 + 2 * padding;

    // Target dimensions
    const wt = Math.max(w1, h1 * SKETCH_DEFAULT_ASPECT_RATIO);
    const ht = Math.round(wt / SKETCH_DEFAULT_ASPECT_RATIO);

    // Margins
    const mx = wt - w1;
    const my = ht - h1;

    // Translation
    const tx = Math.round(mx / 2) + padding;
    const ty = Math.round(my / 2) + padding;

    // Perform translation
    const translatedPaths = shiftPaths({ x: tx, y: ty }, paths).map((path) => {
        delete path.stroke;
        return path;
    });

    // Generate SVG
    const svg = ReactDOMServer.renderToStaticMarkup(
        <DrawingSvgRenderer paths={translatedPaths} width={wt} height={ht} useFallbackColor />,
    );

    // Return new drawing data
    return {
        paths: translatedPaths,
        svg,
        width: wt,
        height: ht,
    };
};
