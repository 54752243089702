import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-card"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M3 4h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M3 5h14v10H3z" />
            <path
                fill="#767C86"
                d="M5.5 7h9a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1zm0 2h9a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1zm0 2h8a.5.5 0 1 1 0 1h-8a.5.5 0 1 1 0-1z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniCardIcon);
export default Memo;
