// Lib
import React from 'react';

// Components
import List from '../../list/List';
import OrchestratedList from '../../list/OrchestratedList';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import {
    collisionDetectionManager,
    enableCollisionDetectionTemporarily,
} from '../../components/collision/collisionDetectionManager';

// Types
import { MNElement } from '../../../common/elements/elementModelTypes';

// Constants
import { IN_LIST_MODE } from '../../../common/inList/listTypes';

type ColumnOrchestratedListProps = {
    element: MNElement;
    elementId: string;
    gridSize: number;
    isPresentational: boolean;
    getContextZoomScale: () => number;
    childElementIds: string[];
    setIsBatchedRenderInProgress?: (isInProgress: boolean) => void;
};

const ColumnOrchestratedList = (props: ColumnOrchestratedListProps) => {
    const {
        element,
        elementId,
        gridSize,
        isPresentational,
        getContextZoomScale,
        childElementIds,
        setIsBatchedRenderInProgress,
    } = props;

    // For exports, or rendering a presentational component as a drag preview, we want it to complete loading as quickly as possible
    const renderInBatches = !isPresentational;
    const initialRenderSize = renderInBatches ? 20 : childElementIds.length;

    // If collision detection is temp enabled at the beginning of the render, we want to ensure it's also enabled for each new batch of content
    const beforeRenderBatch = collisionDetectionManager.temporarilyEnabled
        ? enableCollisionDetectionTemporarily
        : undefined;

    const onOrchestrationStart = () => {
        setIsBatchedRenderInProgress?.(true);
    };
    const onOrchestrationEnd = () => {
        setIsBatchedRenderInProgress?.(false);
    };

    return (
        <OrchestratedList
            childElementIds={childElementIds}
            onOrchestrationStart={onOrchestrationStart}
            onOrchestrationEnd={onOrchestrationEnd}
            beforeRenderBatch={beforeRenderBatch}
            initialRenderSize={initialRenderSize}
        >
            {(childElementIds: string[]) => {
                return (
                    <List
                        {...props}
                        childElementIds={childElementIds}
                        inListClass={IN_LIST_MODE.IN_LIST_COLUMN}
                        listId={getElementId(element)}
                        listStartChild={
                            <AttachedCommentsDropTarget
                                gridSize={gridSize}
                                isPresentational={isPresentational}
                                element={element}
                                elementId={elementId}
                                getContextZoomScale={getContextZoomScale}
                            />
                        }
                    />
                );
            }}
        </OrchestratedList>
    );
};

export default ColumnOrchestratedList;
