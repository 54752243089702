// Lib
import * as Immutable from 'immutable';

// Constants
import { CLONE_INSTANCES_FETCHED } from './cloneInstancesConstants';

const initialState = Immutable.Map();

const handleCloneInstancesFetched = (state, action) => {
    const {
        instanceCount,
        validInstanceCount,
        reachableInstanceBoardCount,
        reachableInstanceCount,
        reachableInstanceBoardIds,
        reachableInstanceIds,
    } = action;

    return state.set(
        action.elementId,
        Immutable.fromJS({
            instanceCount,
            validInstanceCount,
            reachableInstanceBoardCount,
            reachableInstanceCount,
            reachableInstanceBoardIds,
            reachableInstanceIds,
        }),
    );
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLONE_INSTANCES_FETCHED:
            return handleCloneInstancesFetched(state, action);
        default:
            return state;
    }
};
