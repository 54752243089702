import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const FileFormUploadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={48}
        viewBox="0 0 38 48"
        className="Icon file-form-upload"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#B5B5B5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 14H1v33h36V14H26"
            />
            <path
                fill="#B5B5B5"
                fillRule="nonzero"
                d="M18.25 1.539v27.93c0 .572.336 1.035.75 1.035s.75-.463.75-1.034V1.539c0-.572-.336-1.035-.75-1.035s-.75.463-.75 1.035z"
            />
            <path stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m12 8 7-7 7 7" />
        </g>
    </svg>
);
const Memo = memo(FileFormUploadIcon);
export default Memo;
