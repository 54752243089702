// Lib
import { EditorState, Modifier, SelectionState } from 'draft-js';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../draftjsConstants';

/**
 * This either inserts the text at a collapsed selection cursor, or replaces the selected characters with the text.
 */
export default (replacementText) => (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    let newContentState = Modifier.removeRange(contentState, selection, DraftRemovalDirection.forward);

    // Force the text to be inserted where the start of the text that was removed is
    let insertSelection = SelectionState.createEmpty(selection.getStartKey());
    insertSelection = insertSelection.merge({
        anchorKey: selection.getStartKey(),
        anchorOffset: selection.getStartOffset(),
        focusKey: selection.getStartKey(),
        focusOffset: selection.getStartOffset(),
        isBackward: false,
        hasFocus: true,
    });

    // This point onwards is a reimplementation of the Draft JS RichUtils.insertSoftNewline
    // I'm reimplementing it to prevent multiple undo operations being added to the Draft undo stack
    newContentState = Modifier.insertText(
        newContentState,
        insertSelection,
        replacementText,
        editorState.getCurrentInlineStyle(),
        null,
    );

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS);

    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};
