import * as Immutable from 'immutable';

import { TOOLTIP_SHOW, TOOLTIP_HIDE } from './tooltipConstants';

const initialState = Immutable.Map({ fade: true, activeTimestamp: 0 });

export default (state = initialState, action) => {
    switch (action.type) {
        case TOOLTIP_SHOW:
            return state
                .set('content', Immutable.Map(action.tooltip))
                .set('fade', action.fade)
                .set('activeTimestamp', action.activeTimestamp);
        case TOOLTIP_HIDE:
            return initialState;
        default:
            return state;
    }
};
