// Lib
import React from 'react';

// Components
import PageLoader from './PageLoader';

// Styles
import './InitialPageLoader.scss';

const INITIAL_PAGE_LOADER_OVERLAY_ID = 'initial-page-loader-overlay';

let pageLoaderRemoved = false;

export const removeInitialPageLoader = () => {
    if (pageLoaderRemoved) return;

    pageLoaderRemoved = !!document.getElementById(INITIAL_PAGE_LOADER_OVERLAY_ID);

    requestAnimationFrame(() => {
        const overlay = document.getElementById(INITIAL_PAGE_LOADER_OVERLAY_ID);
        overlay && overlay.parentNode.removeChild(overlay);
    });
};

const InitialPageLoader = (props) => (
    <div id={INITIAL_PAGE_LOADER_OVERLAY_ID} className="InitialPageLoader">
        <div className="fake-pwa-header" />
        <PageLoader />
    </div>
);

export default InitialPageLoader;
