// Lib
import { set } from 'lodash/fp';

// Utils
import { getTimestamp } from '../utils/timeUtil';
import { isCard, isCommentThread, isDocument } from './utils/elementTypeUtils';
import { getDueReminder, getElementId, getElementType, getHasClones } from './utils/elementPropertyUtils';

// Constants
import { REMINDER_VALUES } from '../../client/element/task/pills/assignment/dueDate/taskDueDateConstants';
import { ElementType } from './elementTypes';

const isClonedOriginal = (element) => (isCard(element) || isDocument(element)) && getHasClones(element);

const getDuplicatedElementType = (element) =>
    isClonedOriginal(element) ? ElementType.CLONE_TYPE : getElementType(element);

const getDuplicatedElementContent = (element, duplicateId) => {
    // If the element is a card and has clones, we want to clone it, rather than duplicate it,
    // so that it behaves consistently with duplicating clones (as Clones and Original Cards visually look the same).
    if (isClonedOriginal(element)) {
        return {
            linkTo: getElementId(element),
            clonedElementType: getElementType(element),
        };
    }

    const content = cleanReactionsFromElementContent(element.content);

    if (isCommentThread(element)) {
        return {
            ...content,
            threadId: duplicateId,
            duplicatedFromThreadId: getElementId(element),
        };
    }

    return content;
};

export const createDuplicateElementDef = ({ element, duplicateId, userId, transactionId, platform }) => ({
    ...element,
    _id: duplicateId,
    elementType: getDuplicatedElementType(element),
    content: getDuplicatedElementContent(element, duplicateId),
    meta: {
        ...element.meta,
        modifiedBy: userId,
        creator: userId,
        createdTime: getTimestamp(),
        platform,
    },
    duplicate: true,
    duplicatedId: getElementId(element),
    transactionId,
    acl: undefined,
});

export const createIdMap = (elementDefs) =>
    elementDefs.reduce((acc, elementDef) => {
        acc[elementDef.id] = elementDef._id;
        return acc;
    }, {});

const cleanCardContent = (element, idMap) => ({
    ...element.content,
    hasClones: false,
    clone: null,
});

const cleanReactionsFromElementContent = (content) => {
    if (!content?.reactions) return content;
    return {
        ...content,
        reactions: [],
    };
};

const cleanLineContent = (element, idMap) => ({
    ...element.content,
    start: {
        ...element.content.start,
        elementId: idMap[element.content.start.elementId],
    },
    end: {
        ...element.content.end,
        elementId: idMap[element.content.end.elementId],
    },
});

const cleanBoardContent = (elementDef) => {
    /* eslint-disable no-unused-vars */
    const {
        templateTags,
        templateKeywords,
        publicEditEnabled,
        publicEditEnabledTimestamp,
        publishedFeedbackEnabled,
        publishedMediaDownloadDisabled,
        publishedPasswordProtected,
        published,
        publishedTimestamp,
        welcomeMessage,
        embedGenerated,
        ...content
    } = elementDef.content;
    /* eslint-enable no-unused-vars */

    return content;
};

export const cleanDuplicateElementContent = (elementDef, idMap) => {
    switch (elementDef.elementType) {
        case ElementType.CARD_TYPE:
        case ElementType.DOCUMENT_TYPE:
            return cleanCardContent(elementDef, idMap);
        case ElementType.LINE_TYPE:
            return cleanLineContent(elementDef, idMap);
        case ElementType.BOARD_TYPE:
            return cleanBoardContent(elementDef, idMap);
        default:
            return elementDef.content;
    }
};

export const cleanDuplicatedDescendantTask = (element) => {
    const dueReminder = getDueReminder(element);

    if (!dueReminder) return element;

    // Remove due reminders from duplicated tasks, to force them to be set again
    let updatedElement = set('content.dueReminder', REMINDER_VALUES.NONE, element);
    updatedElement = set('content.dueReminderTimestamp', null, updatedElement);

    return updatedElement;
};

export const cleanDuplicatedDescendant = (element) => {
    const elementType = getElementType(element);

    switch (elementType) {
        case ElementType.TASK_TYPE:
            return cleanDuplicatedDescendantTask(element);
        default:
            return element;
    }
};

const duplicatedParentIdIsNotSelf = (elementDef, idMap) => {
    // duplicateId exists if element's parentId is getting duplicated
    const duplicateId = idMap[elementDef.location.parentId];

    // if so make sure the duplicated parentId is not yourself
    return duplicateId && elementDef._id !== duplicateId;
};

export const toNewElementIds = (idMap) => (elementDef) => ({
    ...elementDef,
    content: cleanDuplicateElementContent(elementDef, idMap),
    location: {
        ...elementDef.location,
        parentId: duplicatedParentIdIsNotSelf(elementDef, idMap)
            ? idMap[elementDef.location.parentId]
            : elementDef.location.parentId,
    },
});

export const createDuplicateDefs = ({
    elements,
    createDuplicateIdFn,
    originalParentId,
    newParentId,
    transactionId,
    userId,
    platform,
}) => {
    // Create an array of duplicated element definitions
    const rawDuplicatedElementDefs = elements.map((element, index) =>
        createDuplicateElementDef({
            element,
            duplicateId: createDuplicateIdFn(index),
            transactionId,
            userId,
            platform,
        }),
    );

    // Create idMap {originalId : duplicateId}
    const idMap = createIdMap(rawDuplicatedElementDefs);
    idMap[originalParentId] = newParentId;

    // Swap line start and end with the duplicated elementId
    return rawDuplicatedElementDefs.map(toNewElementIds(idMap));
};
