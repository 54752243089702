import { RawDraftContentState } from 'draft-js';

import rawGetText from '../../utils/editor/rawUtils/rawGetText';

/*********************
 * TABLE DATA PARSERS
 *********************/

export const CELL_DRAFT_JS_PREFIX = '@@mn-draft-js:';

export const isCellValueInDraftFormat = (contentString: string | number): boolean =>
    typeof contentString === 'string' && contentString.startsWith(CELL_DRAFT_JS_PREFIX);
/**
 * Cell content is saved in the DB as a string, this is because Handsontable only supports getting value of a cell in
 * string format. This function is called to convert a DraftJS content format to string. It prefixes the string with
 * `CELL_DRAFT_JS_PREFIX` to make it easy to determine the format of the content.
 */
export const stringifyCellContent = (rawContent: RawDraftContentState): string => {
    return CELL_DRAFT_JS_PREFIX + JSON.stringify(rawContent);
};

/**
 * This function parses a cell content string into either a DraftJS object if the content is of DraftJS format
 */
export const parseCellContentString = (contentString: string | number): RawDraftContentState | string | number => {
    try {
        if (typeof contentString === 'string' && isCellValueInDraftFormat(contentString)) {
            const valueJsonString = contentString.slice(CELL_DRAFT_JS_PREFIX.length);
            return JSON.parse(valueJsonString);
        }
    } catch (e) {
        // Do nothing
    }

    return contentString;
};

export const getRawTextFromCellContentString = (contentString: string | number): string | number => {
    const parsedContent = parseCellContentString(contentString);
    if (typeof parsedContent === 'object') return rawGetText(parsedContent);

    return contentString;
};
