import { AnyAction } from 'redux';

import { HybridElementEditStartAction, HybridElementSelectedAction } from '../hybridMiddlewareConstants';
import { ELEMENTS_SELECTED } from '../../../../../common/elements/selectionConstants';

import { getElement } from '../../../../element/utils/elementStoreUtils';
import { createHybridElementEditStartAction } from './createHybridActions/createHybridElementEditStartAction';
import { createHybridElementSelectedAction } from './createHybridActions/createHybridElementSelectedAction';
import platformSingleton from '../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../platform/platformTypes';
import { ElementType } from '../../../../../common/elements/elementTypes';

/*  ### Reacting to ELEMENTS_SELECTED
 *
 *  Behaviour depends on the element that is selected. Action is unchanged unless listed below:
 *
 *  #### ElementTypes.COMMENT_THREAD_TYPE
 *
 *  Transforms these actions to HYBRID_ELEMENT_EDIT_START actions.
 *
 *  See 'createHybridElementEditStartAction.ts'.
 */

const shouldOverrideElementsSelectedAction = (state: any, action: AnyAction, elementType: ElementType): boolean => {
    if (action.type === ELEMENTS_SELECTED && action.ids.length === 1) {
        switch (platformSingleton.legacyHybridUseCase) {
            case LegacyHybridUseCase.IOS_CANVAS: {
                switch (elementType) {
                    case ElementType.COMMENT_THREAD_TYPE:
                    case ElementType.BOARD_TYPE:
                    case ElementType.ALIAS_TYPE:
                        return true;
                    default:
                        return false;
                }
            }
            case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            case LegacyHybridUseCase.IOS_BOARD_LIST:
            case LegacyHybridUseCase.IPAD_OS:
                return false;
            default:
                return false;
        }
    }
    return false;
};

const getHybridActionForElementsSelected = (
    state: any,
    action: AnyAction,
): HybridElementEditStartAction | HybridElementSelectedAction | undefined => {
    const elementId = action.id ?? action.ids[0];
    if (!elementId) return;
    const element = getElement(state, elementId);
    if (!element) return;
    if (!shouldOverrideElementsSelectedAction(state, action, element.elementType)) return;
    // Already checked for Comment Thread in `shouldOverride`, but we write this for better local reasoning.
    switch (element.elementType) {
        case ElementType.COMMENT_THREAD_TYPE:
            return createHybridElementEditStartAction(state, action, elementId, ElementType.COMMENT_THREAD_TYPE);
        case ElementType.BOARD_TYPE:
        case ElementType.ALIAS_TYPE:
            return createHybridElementSelectedAction(action, element);
        default:
            return;
    }
};

export default (
    state: any,
    action: AnyAction,
): HybridElementEditStartAction | HybridElementSelectedAction | undefined =>
    getHybridActionForElementsSelected(state, action);
