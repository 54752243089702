import { useEffect } from 'react';
import { Editor } from '@tiptap/react';

const useEditorActivity = (editor: Editor | null, onActivity?: () => void) => {
    useEffect(() => {
        if (!editor) return;
        if (!onActivity) return;

        editor.on('update', onActivity);

        return () => {
            editor.off('update', onActivity);
        };
    }, [editor, onActivity]);
};

export default useEditorActivity;
