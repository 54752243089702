// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { createSelector, createStructuredSelector } from 'reselect';
import { round } from 'lodash';
import { compose } from '../../../node_module_clones/recompose';

// Utils
import { propIn } from '../../../common/utils/immutableHelper';
import * as pointLib from '../../../common/maths/geometry/point';
import { findNewControlPointForBezierSection } from './utils/lineControlPointUtil';
import { dropTargetAlreadyHandled } from '../../utils/dnd/dragAndDropUtils';
import { isColumn, isImage } from '../../../common/elements/utils/elementTypeUtils';
import { isIconViewLike } from '../../../common/elements/utils/elementDisplayUtils';
import { hasShiftKey } from '../../utils/keyboard/keyboardUtility';
import { getSnapCoordinate } from '../utils/elementSnapUtils';
import {
    getElementId,
    getLineEndConnectedElementId,
    getLineStartConnectedElementId,
    isElementLocked,
} from '../../../common/elements/utils/elementPropertyUtils';

// Measurements
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';

// Actions
import { endAttachMode, startAttachMode } from '../../utils/dnd/dndActions';

// Selectors
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getDraggedLineEdgesPx } from './lineEdgeSelector';
import { getDragModifierKeys } from '../../utils/dnd/modifierKeys/dragModifierKeysSelector';
import {
    attachModeHoveredElementIdSelector,
    attachModeHoveredStackSelector,
    attachModeTypeSelector,
} from '../../reducers/draggingSelector';
import { getShallowMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Singletons
import dragAndDropStateSingleton from '../dnd/dragAndDropStateSingleton';

// Components
import AttachModeHoverWatcher from '../dnd/AttachModeHoverWatcher';

// Constants
import { LINE_EDGE_DND_TYPE, LINE_EDGE } from '../../../common/lines/lineConstants';
import { AttachModeType, NO_DRAG_OFFSET } from '../../utils/dnd/dndConstants';

// Analytics
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';

/**
 * Using a thunk allows us to use the same selector as the line edge drag preview, so we
 * can be sure that passing in the same input will return the same output and thus give us
 * the accurate drop location.
 */
const getLineEdgeElementDropPosition =
    ({
        pos,
        offsetDiff,
        scrollDiff,
        initialClientOffset,
        initialSourceClientOffset,
        draggedEdge,
        draggedElement,
        hoveredElement,
    }) =>
    (dispatch, getState, { measurementsStore }) => {
        const state = getState();

        const hoveredElementId = getElementId(hoveredElement);
        const gridSize = getGridSize(state);

        const measurements = getShallowMeasurementsMap(measurementsStore.getState());
        const shouldSnapToAngle = hasShiftKey(getDragModifierKeys(state));

        // NOTE: Not handling snapping here. If we should then we can probably get the dragModifierKeys
        //  from the props, or use a thunk to retrieve them
        const dropEdgePositions = getDraggedLineEdgesPx(state, {
            element: draggedElement,
            hoveredElementId,
            draggedEdge,
            offsetDiff,
            scrollDiff,
            pos,
            initialClientOffset,
            initialSourceClientOffset,
            shouldSnapToAngle,
            measurements,
        });

        const { dropPosition, hoveredElementRect, dropIntersectionT, updatedControl } = dropEdgePositions;

        // If the curved line has been dropped inside an element rect, but it's not being snapped to the center
        // the curve needs to be cut and a new control point needs to be found, otherwise the line will change
        // shape when it snaps into place
        const newControl =
            dropIntersectionT && dropIntersectionT !== 1 && !dropPosition.snappedCenter
                ? findNewControlPointForBezierSection({
                      edgeDetails: dropEdgePositions,
                      gridSize,
                      draggedEdge,
                      intersectionT: dropIntersectionT,
                  })
                : updatedControl;

        const newPosition = {
            x: round(dropPosition.x / gridSize, 2),
            y: round(dropPosition.y / gridSize, 2),
            elementId: hoveredElementId,
            snapped: true,
        };

        // If we're snapping to the center, don't set a snapX or snapY, it will automatically choose the center
        if (!dropPosition.snappedCenter) {
            // The position from the hovered element's top left that the edge was dropped
            const dropCoordinateInElement = pointLib.reverseTranslate(hoveredElementRect, dropPosition);

            const isRelative = isImage(hoveredElement);

            newPosition.snapX = getSnapCoordinate({
                val: dropCoordinateInElement.x,
                min: 0,
                max: hoveredElementRect.width,
                isRelative,
                gridSize,
            });

            newPosition.snapY = getSnapCoordinate({
                val: dropCoordinateInElement.y,
                min: 0,
                max: hoveredElementRect.height,
                isRelative,
                gridSize,
            });

            newPosition.fixed = true;
        }

        return {
            newPosition,
            newControl,
        };
    };

/**
 * If the currently long hovered element ID is the same as this element ID we're connecting inside.
 */
const isLineEdgeConnectInsideModeSelector = (state, ownProps) =>
    attachModeTypeSelector(state) === AttachModeType.LINE_EDGE &&
    attachModeHoveredElementIdSelector(state) === ownProps.elementId;

/**
 * If this element isn't currently in the connect inside mode, but it's in the hover stack, then
 * we must be connecting inside its child.
 */
const isLineEdgeConnectingInsideChildSelector = () =>
    createSelector(
        attachModeHoveredStackSelector,
        isLineEdgeConnectInsideModeSelector,
        (state, ownProps) => ownProps.elementId,
        attachModeTypeSelector,
        (hoverStack, isConnectingInside, elementId, attachModeType) => {
            if (isConnectingInside) return false;
            return attachModeType === AttachModeType.LINE_EDGE && hoverStack.size > 1 && hoverStack.includes(elementId);
        },
    );

const mapStateToProps = () =>
    createStructuredSelector({
        isConnectingLineEdgeInside: isLineEdgeConnectInsideModeSelector,
        isConnectingLineEdgeInsideChild: isLineEdgeConnectingInsideChildSelector(),
    });

const mapDispatchToProps = (dispatch) => ({
    dispatchGetElementDropPosition: (args) => dispatch(getLineEdgeElementDropPosition(args)),
    // Used by the AttachModeHoverWatcher
    dispatchStartAttachMode: (hoveredElementId) =>
        dispatch(startAttachMode(hoveredElementId, AttachModeType.LINE_EDGE)),
    dispatchEndAttachMode: (hoveredElementId) => dispatch(endAttachMode(hoveredElementId)),
});

/**
 * Determines if the dragged line edge is currently already connected on the opposite edge.
 */
const getIsOtherEdgeAlreadyConnected = (props, monitor) => {
    const { element } = props;

    const elementId = getElementId(element);
    const draggedEdge = monitor.getItem().edge;
    const lineElement = monitor.getItem().element;

    const oppositeEdge = draggedEdge === LINE_EDGE.start ? LINE_EDGE.end : LINE_EDGE.start;
    return propIn(['content', oppositeEdge, 'elementId'], lineElement) === elementId;
};

/**
 * Determines if the dragged line is already connected to the hovered element by its other edge.
 */
const getIsAlreadyConnected = ({ draggedElement, hoveredElement, draggedEdge }) => {
    const hoveredElementId = getElementId(hoveredElement);

    const oppositeEdgeConnectedElementId =
        draggedEdge === LINE_EDGE.start
            ? getLineEndConnectedElementId(draggedElement)
            : getLineStartConnectedElementId(draggedElement);

    return oppositeEdgeConnectedElementId === hoveredElementId;
};

/*
 * Determines if the hoveredElement is a column and the start of the line is a child of the hovered column
 */
const getIsChildOfHoveredColumn = ({ draggedElement, hoveredElement, dispatchGetChildIds }) => {
    const hoveredElementId = getElementId(hoveredElement);
    const startElementId = getLineStartConnectedElementId(draggedElement);

    return isColumn(hoveredElement) && dispatchGetChildIds(hoveredElementId).includes(startElementId);
};

const elementLineEdgeDropTargetSpec = {
    drop: (props, monitor) => {
        const { dispatchGetElementDropPosition } = props;
        const hoveredElement = props.element;

        // Don't handle the drop if it's already been handled by a child.
        if (dropTargetAlreadyHandled(monitor)) return;

        const item = monitor.getItem();
        if (!item) return;

        const { pos, initialScrollPoint } = item;
        const draggedEdge = item.edge;
        const draggedElement = item.element;

        // If already connected by the other edge, don't connect this edge
        const isAlreadyConnected = getIsAlreadyConnected({
            draggedElement,
            hoveredElement,
            draggedEdge,
        });
        // The ignore flag is checked in the ConnectedEdgeDragHandle endDrag function
        if (isAlreadyConnected) return { ignore: true };

        const endScrollPoint = measurementsRegistry.getCanvasViewportScrollAsPoint();
        const scrollDiff = pointLib.difference(initialScrollPoint, endScrollPoint);

        const offsetDiff = monitor.getDifferenceFromInitialOffset() || NO_DRAG_OFFSET;
        const initialClientOffset = monitor.getInitialClientOffset();
        const initialSourceClientOffset = monitor.getInitialSourceClientOffset();

        const { newPosition, newControl } = dispatchGetElementDropPosition({
            pos,
            offsetDiff,
            scrollDiff,
            initialClientOffset,
            initialSourceClientOffset,
            draggedEdge,
            hoveredElement,
            draggedElement,
        });

        if (newPosition?.fixed) {
            sendAmplitudeEvent({
                eventType: EVENT_TYPE_NAMES.ATTACHED_LINE,
                userProperties: {
                    [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.ATTACHED_LINE]: true },
                },
            });
        }

        // NOTE: The quick line creation tool currently uses the offsetDiff, initialClientOffset
        //  and initialSourceClientOffset, but we should try to avoid this if possible
        return {
            offsetDiff,
            initialClientOffset,
            initialSourceClientOffset,
            newPosition,
            newControl,
        };
    },
    canDrop: (props, monitor) => {
        const item = monitor.getItem();

        const draggedEdge = item.edge;
        const draggedElement = item.element;
        const { element: hoveredElement, dispatchGetChildIds } = props;

        const isAlreadyConnected = getIsAlreadyConnected({
            draggedElement,
            hoveredElement,
            draggedEdge,
        });
        const isChildOfHoveredColumn = getIsChildOfHoveredColumn({
            draggedElement,
            hoveredElement,
            dispatchGetChildIds,
        });

        // If the line is already connected, but the element is locked, allow the drop as the element's
        // drop handler will cause the drop to be ignored. Otherwise, if we don't allow drop, the canvas
        // will handle the drop and actually create the line
        return (!props.isLocked || isAlreadyConnected) && !isChildOfHoveredColumn;
    },
};

const elementLineEdgeDropCollect = (monitor, props) => {
    const { element, elementId } = props;
    const { hoveredElementId } = dragAndDropStateSingleton;

    // Should only be hoverable if line can be dropped to hovered object
    const canDrop = !isColumn(element) || monitor.canDrop();

    const isHovered = monitor.isOver() && canDrop;
    const isLineEdgeHoveredShallow = monitor.isOver({ shallow: true }) && canDrop;
    const alreadyConnected = isHovered && getIsOtherEdgeAlreadyConnected(props, monitor);

    // This is a little dodgy, but it seems to work well and I think it's a performant way of
    // solving this problem (more so than how the ElementDragLayer inspects hovered targets)
    if (isLineEdgeHoveredShallow && hoveredElementId !== elementId && !alreadyConnected) {
        dragAndDropStateSingleton.hoveredElementId = elementId;
    } else if (!isLineEdgeHoveredShallow && hoveredElementId === elementId) {
        dragAndDropStateSingleton.hoveredElementId = null;
    }

    return {
        isLineEdgeHovered: isHovered && !alreadyConnected,
        isLineEdgeHoveredShallow: isLineEdgeHoveredShallow && !alreadyConnected,
    };
};

const HoverableLineEdgeDropTarget = (DecoratedComponent) => {
    const hoverableDropTarget = (props) => {
        const { element, elementId, isConnectingLineEdgeInside, isConnectingLineEdgeInsideChild } = props;

        const {
            isLineEdgeHovered,
            isLineEdgeHoveredShallow,
            dispatchStartAttachMode,
            dispatchEndAttachMode,
            ...passthroughProps
        } = props;

        const showIsHovered = !props.isLocked && isLineEdgeHovered && isLineEdgeHoveredShallow;

        return (
            <AttachModeHoverWatcher
                elementId={elementId}
                disableAttachMode={isIconViewLike(element) || isElementLocked(element)}
                isAttachMode={isConnectingLineEdgeInside || isConnectingLineEdgeInsideChild}
                isAttachModeThisElement={isConnectingLineEdgeInside}
                isHovered={isLineEdgeHovered}
                isHoveredShallow={isLineEdgeHoveredShallow}
                dispatchStartAttachMode={dispatchStartAttachMode}
                dispatchEndAttachMode={dispatchEndAttachMode}
            >
                <DecoratedComponent isLineEdgeHovered={showIsHovered} {...passthroughProps} />
            </AttachModeHoverWatcher>
        );
    };

    hoverableDropTarget.propTypes = LineEdgeDropTargetPropTypes;

    return hoverableDropTarget;
};

const LineEdgeDropTargetPropTypes = {
    elementId: PropTypes.string.isRequired,
    element: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
};

const LineEdgeDropTargetDecorator = (DecoratedComponent) => {
    const elementLineEdgeDropTarget = (props) => {
        const [dropProps, connector] = useDrop({
            accept: [LINE_EDGE_DND_TYPE],
            drop: (item, monitor) => elementLineEdgeDropTargetSpec.drop(props, monitor),
            canDrop: (item, monitor) => elementLineEdgeDropTargetSpec.canDrop(props, monitor),
            collect: (monitor) => elementLineEdgeDropCollect(monitor, props),
        });

        return <DecoratedComponent {...props} {...dropProps} connectLineEdgeDropTarget={connector} />;
    };

    return elementLineEdgeDropTarget;
};

const HookedLineEdgeDropTarget = (DecoratedComponent) => {
    const elementLineEdgeDropTarget = compose(
        connect(mapStateToProps, mapDispatchToProps),
        LineEdgeDropTargetDecorator,
        HoverableLineEdgeDropTarget,
    )(DecoratedComponent);

    elementLineEdgeDropTarget.propTypes = LineEdgeDropTargetPropTypes;

    return elementLineEdgeDropTarget;
};

export default HookedLineEdgeDropTarget;
