'use strict';

exports.__esModule = true;

var _extends =
    Object.assign ||
    function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];
            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _TransitionGroup = require('./TransitionGroup');

var _TransitionGroup2 = _interopRequireDefault(_TransitionGroup);

var _CSSTransitionGroupChild = require('./CSSTransitionGroupChild');

var _CSSTransitionGroupChild2 = _interopRequireDefault(_CSSTransitionGroupChild);

var _PropTypes = require('./utils/PropTypes');

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}

function _possibleConstructorReturn(self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return call && (typeof call === 'object' || typeof call === 'function') ? call : self;
}

function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: { value: subClass, enumerable: false, writable: true, configurable: true },
    });
    if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
}

var propTypes = {
    transitionName: _PropTypes.nameShape.isRequired,

    transitionAppear: _propTypes2.default.bool,
    transitionEnter: _propTypes2.default.bool,
    transitionLeave: _propTypes2.default.bool,
    transitionAppearTimeout: (0, _PropTypes.transitionTimeout)('Appear'),
    transitionEnterTimeout: (0, _PropTypes.transitionTimeout)('Enter'),
    transitionLeaveTimeout: (0, _PropTypes.transitionTimeout)('Leave'),
};

var defaultProps = {
    transitionAppear: false,
    transitionEnter: true,
    transitionLeave: true,
};

var CSSTransitionGroup = (function (_React$Component) {
    _inherits(CSSTransitionGroup, _React$Component);

    function CSSTransitionGroup() {
        var _temp, _this, _ret;

        _classCallCheck(this, CSSTransitionGroup);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return (
            (_ret =
                ((_temp =
                    ((_this = _possibleConstructorReturn(
                        this,
                        _React$Component.call.apply(_React$Component, [this].concat(args)),
                    )),
                    _this)),
                (_this._wrapChild = function (child) {
                    return _react2.default.createElement(
                        _CSSTransitionGroupChild2.default,
                        {
                            name: _this.props.transitionName,
                            appear: _this.props.transitionAppear,
                            enter: _this.props.transitionEnter,
                            leave: _this.props.transitionLeave,
                            appearTimeout: _this.props.transitionAppearTimeout,
                            enterTimeout: _this.props.transitionEnterTimeout,
                            leaveTimeout: _this.props.transitionLeaveTimeout,
                        },
                        child,
                    );
                }),
                _temp)),
            _possibleConstructorReturn(_this, _ret)
        );
    }

    // We need to provide this childFactory so that
    // ReactCSSTransitionGroupChild can receive updates to name, enter, and
    // leave while it is leaving.

    CSSTransitionGroup.prototype.render = function render() {
        return _react2.default.createElement(
            _TransitionGroup2.default,
            _extends({}, this.props, { childFactory: this._wrapChild }),
        );
    };

    return CSSTransitionGroup;
})(_react2.default.Component);

CSSTransitionGroup.displayName = 'CSSTransitionGroup';

CSSTransitionGroup.propTypes = process.env.NODE_ENV !== 'production' ? propTypes : {};
CSSTransitionGroup.defaultProps = defaultProps;

exports.default = CSSTransitionGroup;
module.exports = exports['default'];
