export const TIME_DIVISOR = 1000;

export const RADIX = 62;

export const TIMESTAMP_LENGTH = 6;
export const CLIENT_ID_LENGTH = 6;
export const CLIENT_TICK_LENGTH = 2;
export const ELEMENT_ID_LENGTH = TIMESTAMP_LENGTH + CLIENT_ID_LENGTH + CLIENT_TICK_LENGTH;

export const TICK_MAX = RADIX * RADIX;
export const CLIENT_OFFSET_MAX = RADIX * RADIX * RADIX * RADIX;

// Note: Some very old content (pre 2018) was created with an extra character in the ID
// See `src/common/uid/idValidator.js` for more details
export const ELEMENT_ID_REGEX_STR = `([0-9a-zA-Z]{${ELEMENT_ID_LENGTH},${ELEMENT_ID_LENGTH + 1}})`;
