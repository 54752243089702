import onTab from './onTab';

// Constants
import { KEY_CODES } from '../../../../utils/keyboard/keyConstants';

/**
 * On Chromium-based browsers, there is an ongoing bug where browser/library functionalities that updates the DOM
 * content could cause a "Failed to execute 'removeChild' on 'Node'" error in the browser/
 *
 * Read more on the error here: https://github.com/facebook/react/issues/11538
 *
 * In our case, Draft JS internal mechanism updates the DOM content, and would result in the element crashing. This
 * happen especially when the user adds a tab (\t) to the content block. We solve this by preventing the default tabbing
 * mechanism and adding in 4 spaces instead. This is quite hacky and would be good to remove after we move on from
 * Draft JS.
 */
const createTabReplacementPlugin = () => ({
    keyBindingFn(event, { getEditorState, setEditorState }) {
        if (event.keyCode === KEY_CODES.TAB) return onTab(event, { getEditorState, setEditorState });
    },
});

export default createTabReplacementPlugin;
