import { Plugin, PluginKey } from '@tiptap/pm/state';

export function linkClickHandler(clickableLinksWhenEditable: boolean): Plugin {
    return new Plugin({
        key: new PluginKey('linkClickHandler'),
        props: {
            handleDOMEvents: {
                click(view, event) {
                    const target = event.target as HTMLElement;
                    if (target?.nodeName !== 'A') return;

                    // Prevent click events when tapping an anchor.
                    // The click handler below will still run (it's actually listening to
                    // mouseUp under the hood), but this will stop any other handlers from
                    // running (eg selecting the element or starting to edit it).
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                },
            },
            handleClick: (view, pos, event) => {
                if (event.button !== 0) return false;

                if (view.editable && !clickableLinksWhenEditable) return false;

                const link = event.target as HTMLLinkElement;
                if (!link?.href) return false;

                window.open(link.href, '_blank');
                return true;
            },
        },
    });
}
