import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardStatusCommentOnlyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon board-status-comment-only"
        {...props}
    >
        <path
            fill="#2F63F7"
            fillRule="evenodd"
            d="M3 12a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-3l-2 2-2-2H3z"
        />
    </svg>
);
const Memo = memo(BoardStatusCommentOnlyIcon);
export default Memo;
