// Lib
import React from 'react';

// Utils
import { getHasClones, getTextContent } from '../../../common/elements/utils/elementPropertyUtils';
import { useElementTiptapContent } from '../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Components
import CardEditor from './CardEditor';
import CloneCardEditor from './CloneCardEditor';
import CardTiptapEditor from './tiptap/CardTiptapEditor';

// Types
import { DocumentType } from '../../../common/tiptap/conversion/elementConversion/elementConversion';
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { TiptapContent } from '../../../common/tiptap/tiptapTypes';
import { RawDraftContentState } from 'draft-js';

type CardEditorSwitchProps = {
    element: ImMNElement;
    isClone: boolean;
    isEditing: boolean;
    isEditable: boolean;
    isSingleSelected: boolean;
    isFocusedForegroundElement: boolean;
    currentEditorKey: string;
    editorRef: (editor: HTMLDivElement | null) => void;
    saveContent: (content: TiptapContent | RawDraftContentState, transactionId?: string) => void;
    startEditing: () => void;
    moveElementsToTrash: () => void;
    createNewCard: () => void;
};

const BasicCardEditor = (props: CardEditorSwitchProps) => (
    <CardEditor textContent={getTextContent(props.element)} {...props} />
);

const CardEditorSwitch = (props: CardEditorSwitchProps) => {
    const {
        element,
        isClone,
        currentEditorKey,
        isEditable,
        isEditing,
        isSingleSelected,
        isFocusedForegroundElement,
        saveContent,
        startEditing,
        editorRef,
        moveElementsToTrash,
        createNewCard,
    } = props;

    const textContent = getTextContent(element);
    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);

    if (tiptapContent) {
        return (
            <CardTiptapEditor
                element={element}
                textContent={tiptapContent}
                currentEditorKey={currentEditorKey}
                isEditable={isEditable}
                isEditing={isEditing}
                isSingleSelected={isSingleSelected}
                isFocusedForegroundElement={isFocusedForegroundElement}
                saveContent={saveContent}
                startEditing={startEditing}
                editorRef={editorRef}
                moveElementsToTrash={moveElementsToTrash}
                createNewCard={createNewCard}
            />
        );
    }

    if (getHasClones(element) || isClone) return <CloneCardEditor {...props} />;

    return <BasicCardEditor {...props} />;
};

export default CardEditorSwitch;
