// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import Icon from '../../../../../components/icons/Icon';
import Button from '../../../../../components/buttons/Button';

// Utils

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import { TextAlignment, VerticalAlignment } from '../../../../../../common/table/CellTypeConstants';

import './TableCellAlignmentToolbarPopup.scss';

const TableCellAlignmentToolbarPopup = ({
    isPopupOpen,
    selectedElements,
    commonTextAlignment,
    commonVerticalAlignment,
    currentCellSelections,
    hotTableInstance,
    ...props
}) => (
    <ToolbarPopup {...props} popupId={PopupIds.TABLE_CELL_ALIGNMENT_TOOL} className="TableCellAlignmentToolbarPopup">
        <ul className="cell-alignment-options">
            <li>
                <Button
                    className={classNames('cell-alignment-options-button', {
                        active: commonTextAlignment === TextAlignment.LEFT,
                    })}
                    onClickFn={() =>
                        hotTableInstance.tableOperationsRef.current.updateCellTextAlignment(TextAlignment.LEFT)()
                    }
                >
                    <Icon name="toolbar-align-left" />
                </Button>
            </li>
            <li>
                <Button
                    className={classNames('cell-alignment-options-button', {
                        active: commonTextAlignment === TextAlignment.CENTER,
                    })}
                    onClickFn={() =>
                        hotTableInstance.tableOperationsRef.current.updateCellTextAlignment(TextAlignment.CENTER)()
                    }
                >
                    <Icon name="toolbar-align-center" />
                </Button>
            </li>
            <li>
                <Button
                    className={classNames('cell-alignment-options-button', {
                        active: commonTextAlignment === TextAlignment.RIGHT,
                    })}
                    onClickFn={() =>
                        hotTableInstance.tableOperationsRef.current.updateCellTextAlignment(TextAlignment.RIGHT)()
                    }
                >
                    <Icon name="toolbar-align-right" />
                </Button>
            </li>
        </ul>

        <div className="divider" />

        <ul className="cell-alignment-options">
            <li>
                <Button
                    className={classNames('cell-alignment-options-button', {
                        active: commonVerticalAlignment === VerticalAlignment.TOP,
                    })}
                    onClickFn={() =>
                        hotTableInstance.tableOperationsRef.current.updateCellVerticalAlignment(VerticalAlignment.TOP)()
                    }
                >
                    <Icon name="toolbar-align-top" />
                </Button>
            </li>
            <li>
                <Button
                    className={classNames('cell-alignment-options-button', {
                        active: commonVerticalAlignment === VerticalAlignment.MIDDLE,
                    })}
                    onClickFn={() =>
                        hotTableInstance.tableOperationsRef.current.updateCellVerticalAlignment(
                            VerticalAlignment.MIDDLE,
                        )()
                    }
                >
                    <Icon name="toolbar-align-middle" />
                </Button>
            </li>
            <li>
                <Button
                    className={classNames('cell-alignment-options-button', {
                        active: commonVerticalAlignment === VerticalAlignment.BOTTOM,
                    })}
                    onClickFn={() =>
                        hotTableInstance.tableOperationsRef.current.updateCellVerticalAlignment(
                            VerticalAlignment.BOTTOM,
                        )()
                    }
                >
                    <Icon name="toolbar-align-bottom" />
                </Button>
            </li>
        </ul>
    </ToolbarPopup>
);

TableCellAlignmentToolbarPopup.propTypes = {
    isPopupOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
    currentCellSelections: PropTypes.object,
    hotTableInstance: PropTypes.object,

    commonTextAlignment: PropTypes.string,
    commonVerticalAlignment: PropTypes.string,
};

export default TableCellAlignmentToolbarPopup;
