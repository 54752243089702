// Lib
import { toLower } from 'lodash/fp';

export default (filename: string): string => {
    const periodIndex = filename.lastIndexOf('.');

    if (periodIndex <= 0) return '';

    return toLower(filename.substring(periodIndex + 1, filename.length));
};
