/**
 * This adds DOM elements to an element node, using vanilla javascript, so that it
 * remains performant during drag operations.
 * It's used within the ElementDragPreview and also the CommentThreadDragPreview, because it changes
 * between rendered elements during a drag and needs to add the shadow back.
 */
export default (elementNode) => {
    if (!elementNode) return;

    // Create the dom element that allows the drag shadow to be animated
    //  NOTE: pseudo elements (before or after) can't be used here as we can't target
    //      them with JavaScript and as such can't animate the shadow opacity
    const dragShadow = document.createElement('div');
    dragShadow.className = 'drag-preview-shadow';

    // We also create a dom element for the initial shadow. This shadow is the same as
    // the shadow of an element at rest on the canvas. It fades out when the drag shadow fades in.
    const dragInitialShadow = document.createElement('div');
    dragInitialShadow.className = 'drag-preview-initial-shadow';

    elementNode.insertBefore(dragShadow, elementNode.firstChild);
    elementNode.insertBefore(dragInitialShadow, elementNode.firstChild);
};
