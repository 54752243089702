import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarDismissIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon toolbar-dismiss"
        {...props}
    >
        <path
            fill="#5F6672"
            fillRule="evenodd"
            d="m6.646 4.646.708.708-2.147 2.145L13 7.5v1H5.207l2.147 2.146-.708.708L3.293 8z"
        />
    </svg>
);
const Memo = memo(ToolbarDismissIcon);
export default Memo;
