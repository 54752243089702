// Lib
import { isEmpty } from 'lodash';

// Utils
import { getDomain } from '../../common/utils/urlUtil';

const ORGANIC_REFERRERS = ['google', 'bing', 'yahoo'];

const blacklistedReferrers = ['milanote.com'];

const isBlacklistedReferrer = (referrerDomain: string) =>
    blacklistedReferrers.some((blackReferrer) => referrerDomain.indexOf(blackReferrer) !== -1);

interface UtmData {
    utm_medium: string;
    utm_source: string;
}

const buildUtmData = (medium: string, source: string): UtmData => ({
    utm_medium: medium,
    utm_source: source,
});

/**
 * Gets the UTM data from the HREF.
 * If the HREF contains a gclid, the medium="cpc" and source="google"
 * Otherwise if there's UTM data in the href, pass it through.
 */
export const getUtmDataFromHref = (href?: string): UtmData | null => {
    if (!href) return null;

    const url = new URL(href);

    if (!url) return null;

    if (url?.searchParams?.has('gclid')) return buildUtmData('cpc', 'google');

    const utmMedium = url.searchParams.get('utm_medium');
    const utmSource = url.searchParams.get('utm_source');

    if (!utmMedium || !utmSource) return null;

    return buildUtmData(utmMedium, utmSource);
};

/**
 * Builds the UTM data from the referrer if there is a referrer.
 */
const getUtmDataFromReferrer = (referrer?: string): UtmData | null => {
    if (isEmpty(referrer)) return null;

    const referrerDomain = getDomain(referrer);
    if (isBlacklistedReferrer(referrerDomain)) return null;

    const matchedReferrer = ORGANIC_REFERRERS.reduce((matched: string | null, organicDomain: string) => {
        if (matched) return matched;
        if (referrerDomain.indexOf(organicDomain) !== -1) return organicDomain;
        return null;
    }, null);

    if (matchedReferrer) return buildUtmData('organic', matchedReferrer);

    return buildUtmData('referral', referrerDomain);
};

/**
 * Gets the UTM data from the HREF first and then tries the referrer if there's no data on the HREF.
 */
export const getUtmData = (href?: string, referrer?: string): UtmData | null =>
    getUtmDataFromHref(href) || getUtmDataFromReferrer(referrer);
