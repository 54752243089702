// Lib
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { isEmpty } from 'lodash/fp';

// Utils
import { asObject } from '../../../../../common/utils/immutableHelper';
import { getPathBoundingRect } from '../../drawingEditor/drawingEditorUtils';
import { getPathsFromElementContent } from '../../drawingEditor/drawingEditorElementContentUtils';
import {
    getCaption,
    getDrawingData,
    getDrawingHeight,
    getDrawingWidth,
    getShowCaption,
} from '../../../../../common/elements/utils/elementPropertyUtils';

// Components
import DrawingSvgRenderer from '../../drawingEditor/drawingEditorCanvas/DrawingSvgRenderer';

// Constants
import { GRID } from '../../../../utils/grid/gridConstants';
import {
    SKETCH_CANVAS_PIXEL_RATIO,
    SKETCH_DEFAULT_HEIGHT,
    SKETCH_DEFAULT_WIDTH,
} from '../../../../../common/drawings/sketches/sketchConstants';
import { getProjectedCaptionHeight } from '../../../modal/elementModalUtils';

/**
 * Builds the paths when the modal is opened.
 */
export const createInitialPaths = ({ element }) => {
    if (!element) return [];

    const drawing = asObject(getDrawingData(element));

    if (!drawing || !drawing.paths) return [];

    return getPathsFromElementContent({
        drawing,
        offset: { x: 0, y: 0 },
        scale: 1,
    });
};

const getSketchModalMargin = ({ windowWidth, windowHeight }) => {
    const minDimension = Math.min(windowWidth, windowHeight);
    return minDimension * 0.1;
};

/**
 * Gets the dimensions of the drawing modal based on the window width, height and drawing aspect ratio.
 */
export const getSketchModalDimensions = ({ windowWidth, windowHeight, element, gridSize }) => {
    const drawingWidth = getDrawingWidth(element) || SKETCH_DEFAULT_WIDTH * GRID.LARGE.size * SKETCH_CANVAS_PIXEL_RATIO;
    const drawingHeight =
        getDrawingHeight(element) || SKETCH_DEFAULT_HEIGHT * GRID.LARGE.size * SKETCH_CANVAS_PIXEL_RATIO;

    const aspectRatio = drawingWidth / drawingHeight;

    const caption = getShowCaption(element) ? getCaption(element) : null;
    const projectedCaptionHeight = getProjectedCaptionHeight(caption, gridSize);

    const toolbarBuffer = 8.3 * gridSize;

    const modalMargin = getSketchModalMargin({ windowWidth, windowHeight, gridSize });

    const availableHeight = windowHeight - 2 * modalMargin - toolbarBuffer - projectedCaptionHeight;
    const availableWidth = windowWidth - 2 * modalMargin;

    const modalWidth = Math.min(availableWidth, aspectRatio * availableHeight);
    const modalHeight = Math.min(availableWidth / aspectRatio, availableHeight);

    const scale = modalWidth / drawingWidth;

    return {
        modalWidth,
        modalHeight,
        drawingWidth,
        drawingHeight,
        scale,
    };
};

/**
 * Creates the drawing content that will be saved to the sketch element.
 */
export const createSketchElementDrawingContent = ({ paths, drawingWidth, drawingHeight }) => {
    if (!paths) return null;

    // Filter out any paths that don't have a bounding rect - this can happen when the
    //  perfect-freehand library returns "NaNs" for a line, such as when doing a single click
    const filteredPaths = paths.filter((path) => {
        const pathBoundingRect = getPathBoundingRect(path);
        return pathBoundingRect && pathBoundingRect.width > 0;
    });

    if (isEmpty(filteredPaths)) return null;

    const svg = ReactDOMServer.renderToStaticMarkup(
        <DrawingSvgRenderer paths={filteredPaths} width={drawingWidth} height={drawingHeight} useFallbackColor />,
    );

    return {
        paths: filteredPaths,
        svg,
        width: drawingWidth,
        height: drawingHeight,
    };
};
