// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import requestSecondAnimationFrame from '../../../common/utils/lib/requestSecondAnimationFrame';
import { syncedMeasurements } from '../../components/measurementsStore/elementMeasurements/syncedMeasurementsSingleton';

// Components
import ElementPlaceholder from './ElementPlaceholder';

// Constants
import { shouldShowPlaceholder } from './elementPlaceholderUtils';

/**
 * Decorator that adds element placeholder functionality to a component.
 * Keeps track of the visibility of the element and passes a placeholder component and boolean
 * telling the child whether to render that placeholder.
 */
export default (DecoratedComponent) => {
    class renderElementPlaceholderDecorator extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isVisible: true,
            };

            this.mounted = false;
        }

        componentDidMount() {
            this.mounted = true;
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        getElementVisibility = () => {
            const { elementId, initialElementsVisibility } = this.props;

            // Optionally pass in a map with the visibility value to be used before the first render
            if (initialElementsVisibility && !this.mounted) {
                return initialElementsVisibility.get(elementId) ?? this.state.isVisible;
            }

            return this.state.isVisible;
        };

        getElementMeasurements = () => {
            const { elementId, elementPlaceholderMeasurements } = this.props;

            // Optionally pass in measurements to be used for a placeholder before the element is rendered and measured
            return syncedMeasurements[elementId] || elementPlaceholderMeasurements?.[elementId];
        };

        onVisibilityChange = (isVisible) => {
            const { allowElementPlaceholder, setShouldPreventMeasure } = this.props;
            if (!allowElementPlaceholder) return;

            this.latestVisibility = isVisible;

            // When switching to placeholder, we want to prevent measuring BEFORE the render
            if (!isVisible) {
                setShouldPreventMeasure(true);
            }

            requestSecondAnimationFrame(() => {
                if (!this.mounted || isVisible !== this.latestVisibility || isVisible === this.state.isVisible) return;

                this.setState({ isVisible });
                this.props.onElementVisibilityChange?.(this.props.elementId, isVisible);

                // When switching to the actual element, we want to prevent measuring until AFTER the render
                if (isVisible) {
                    requestAnimationFrame(() => {
                        setShouldPreventMeasure(false);
                    });
                }
            });
        };

        getShouldRenderPlaceholder = (measurements) => {
            const { elementId, element, elementFilterData } = this.props;

            return shouldShowPlaceholder(
                measurements,
                elementId,
                element,
                this.getElementVisibility(),
                elementFilterData,
            );
        };

        render() {
            if (!this.props.allowElementPlaceholder) {
                return <DecoratedComponent {...this.props} />;
            }

            const { element, elementId, isSelected } = this.props;
            const measurements = this.getElementMeasurements();
            const shouldRenderPlaceholder = this.getShouldRenderPlaceholder(measurements);

            const placeholderElement = shouldRenderPlaceholder ? (
                <ElementPlaceholder
                    element={element}
                    placeholderMeasurements={measurements}
                    elementId={elementId}
                    isSelected={isSelected}
                />
            ) : null;

            return (
                <DecoratedComponent
                    {...this.props}
                    onVisibilityChange={this.onVisibilityChange}
                    placeholderElement={placeholderElement}
                    shouldRenderPlaceholder={shouldRenderPlaceholder}
                />
            );
        }
    }

    renderElementPlaceholderDecorator.propTypes = {
        shouldRenderPlaceholder: PropTypes.bool,
        boardSection: PropTypes.string,
        elementId: PropTypes.string,
        element: PropTypes.object,
        initialElementsVisibility: PropTypes.object,
        isElementVisible: PropTypes.bool,
        elementPlaceholderMeasurements: PropTypes.object,
        allowElementPlaceholder: PropTypes.bool,
        setShouldPreventMeasure: PropTypes.func,
        onElementVisibilityChange: PropTypes.func,
        elementFilterData: PropTypes.object,
        inTrash: PropTypes.bool,
        isSelected: PropTypes.bool,
        getElementVisibility: PropTypes.func,
    };

    return renderElementPlaceholderDecorator;
};
