import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MoreEllipsisIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={4}
        viewBox="0 0 16 4"
        className="Icon more-ellipsis"
        {...props}
    >
        <g fill="#B5B5B5" fillRule="evenodd">
            <circle cx={14} cy={2} r={2} />
            <circle cx={8} cy={2} r={2} />
            <circle cx={2} cy={2} r={2} />
        </g>
    </svg>
);
const Memo = memo(MoreEllipsisIcon);
export default Memo;
