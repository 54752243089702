// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import classNames from 'classnames';

// Components
import Icon from '../../components/icons/Icon';

// Styles
import './TaskDragHandle.scss';

const TaskDragHandle = ({ dragHandleWrapper, className }) =>
    dragHandleWrapper(
        <div className={classNames('TaskDragHandle', 'task-utility-button', className)}>
            <Icon className="Icon" name="task-drag" />
        </div>,
    );

TaskDragHandle.propTypes = {
    dragHandleWrapper: PropTypes.func,
    className: PropTypes.string,
};

export default TaskDragHandle;
