// Constants
import { BoardSections } from '../../common/boards/boardConstants';

export const WORKSPACE_SECTIONS = {
    CANVAS: BoardSections.CANVAS,
    INBOX: BoardSections.INBOX,
    TRASH_PERSONAL: 'TRASH_PERSONAL',
    TRASH_OTHERS: 'TRASH_OTHERS',
    QUICK_NOTES: 'QUICK_NOTES',
};
