// Utils
import delay from '../../../../common/utils/lib/delay';
import platformSingleton from '../../../platform/platformSingleton';
import { focusFakeInput } from '../../../utils/ipad/ipadUtils';
import { getDomElementId } from '../../../element/utils/elementUtil';
import { animateScroll } from '../../../utils/animation/animateScrollIntoView';
import { getElementType } from '../../../../common/elements/utils/elementPropertyUtils';
import { translateDOMRectIntoScrollableElementCoordinates } from '../../../utils/dom/scrollableElementUtils';
import { isPlatformIos } from '../../../platform/utils/platformDetailsUtils';

// Selectors
import { getElement } from '../../../element/selectors/elementSelector';
import { getMilanoteApplicationModeSelector } from '../../../platform/platformSelector';

// Constants
import { ELEMENT_EDIT_START } from '../../../../common/elements/selectionConstants';

// Types
import { UnknownAction } from 'redux';
import { ReduxStore } from '../../../types/reduxTypes';
import { MNElement } from '../../../../common/elements/elementModelTypes';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { ElementType } from '../../../../common/elements/elementTypes';
import { MilanoteApplicationMode } from '../../../platform/platformTypes';

/**
 * Horizontally centers the element that's being edited.
 */
// FIXME-MOBILE - This doesn't take into account if the element is wider than the screen
//  It also doesn't consider different types of elements
const getTargetScrollLeft = (scrollableElement: HTMLElement, targetRect: Rect, mnElement: MNElement) => {
    const windowRect = scrollableElement.getBoundingClientRect();
    const centerOffsetX = (windowRect.width - targetRect.width) / 2;

    return targetRect.left - centerOffsetX;
};

/**
 * Gets the target scrollTop property for the scrollable element.
 */
const getTargetScrollTop = (scrollableElement: HTMLElement, targetRect: Rect, mnElement: MNElement) => {
    const elementType = getElementType(mnElement);

    // We edit columns at the top of the element - but we still want some padding at the top to prevent it
    // from being too close to the top of the screen
    if (elementType === ElementType.COLUMN_TYPE) return targetRect.top - 150;

    // FIXME-MOBILE - ensure there's enough space when the virtual keyboard is shown (some devices will have
    //  less visible area than my Pixel 8 Pro).
    // FIXME-MOBILE - check all other element types
    // Otherwise the cursor is at the bottom
    // FIXME-MOBILE - This is an approximation - the virtual keyboard and toolbar will take up ~ half of the screen,
    //  so we want the element to fill up half of the remaining area
    const offset = Math.round(window.innerHeight / 4);

    // FIXME-MOBILE Adding 50 because iOS was still scrolling - double check this when implementing properly
    return targetRect.bottom - offset + 50;
};

/**
 * Determines the target scroll position for the scrollable element.
 */
const getTargetScroll = (scrollableElement: HTMLElement, targetRect: Rect, mnElement: MNElement) => ({
    left: getTargetScrollLeft(scrollableElement, targetRect, mnElement),
    top: getTargetScrollTop(scrollableElement, targetRect, mnElement),
});

// FIXME-MOBILE - This is a very rough implementation and needs to be polished
//  Some things it should do:
//  - Handle elements that are wider than the screen
//  - Handle different types of elements
//  - Handle the correct size of the virtual keyboard
//  - Change the target scroll position depending on where the element is on the screen? (e.g. if it's near the top,
//     scroll less)
//  - Handle the correct scrollable parent
//  - We might want to add extra space at the bottom of the scrollable area to allow the element to be scrolled
//     up high enough
export default (store: ReduxStore) => (next: Function) => async (action: UnknownAction) => {
    const state = store.getState();

    const applicationMode = getMilanoteApplicationModeSelector(state);

    const shouldInterceptAction =
        applicationMode === MilanoteApplicationMode.mobile && action.type === ELEMENT_EDIT_START;

    if (!shouldInterceptAction) return next(action);

    const { id } = action;

    const elementDomId = getDomElementId(id);
    const domElement = document.getElementById(elementDomId);

    if (!domElement) return next(action);

    const boundingClientRect = domElement.getBoundingClientRect();

    // FIXME-MOBILE - This should find the element's scrollable parent instead
    const scrollableElement = document.getElementById('canvas-viewport');

    if (!scrollableElement) return next(action);

    // FIXME-MOBILE clean this code up a bit (no magic strings, constants, etc).
    // We need the focus event to happen immediately on iOS otherwise the element won't become editable
    // We don't want to do it on Android otherwise the keyboard might flash up then disappear then show again
    if (isPlatformIos(platformSingleton)) {
        const mobileWorkspaceElement = document.querySelector('.ModernMobileWorkspace');
        focusFakeInput({ x: 0, y: 0 }, mobileWorkspaceElement);
    }

    const element = getElement(state, { elementId: id });

    const targetRect = translateDOMRectIntoScrollableElementCoordinates(scrollableElement, boundingClientRect);

    const targetScroll = getTargetScroll(scrollableElement, targetRect, element);

    animateScroll({
        scrollableElement,
        fromScrollLeft: scrollableElement.scrollLeft,
        fromScrollTop: scrollableElement.scrollTop,
        toScrollLeft: targetScroll.left,
        toScrollTop: targetScroll.top,
        interpolationFactor: 0.2,
    });

    // FIXME-MOBILE - Potentially make animateScroll return a promise and then use that to edit once complete
    //   Note - it might actually be beneficial to not wait for the scroll to complete before editing though
    // FIXME-MOBILE - We need wait slightly longer on iOS to prevent the body from scrolling if we switch to
    //  edit the card before the scroll has completed, but we want to switch slightly faster on Android so
    //  that the keyboard shows quicker
    const delayTime = isPlatformIos(platformSingleton) ? 250 : 100;
    await delay(delayTime);

    next(action);
};
