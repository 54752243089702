// Lib
import { createSelector } from 'reselect';

// Utils
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getChildrenViaGraph } from '../../../common/elements/utils/elementGraphUtils';
import { filterAndSortElementsInSection } from '../../../common/elements/utils/elementSortUtils';

// Selectors
import { getElements } from './elementsSelector';
import { elementGraphSelector } from './elementGraphSelector';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { IdGraph } from '../../../common/dataStructures/graphTypes';

type SortFn = (a: ImMNElement, b: ImMNElement) => number;
type SelectorProps = {
    originalElementId?: string;
    elementId?: string;
    element?: ImMNElement;
};

export const getElementChildren = () =>
    createShallowSelector(
        getElements,
        elementGraphSelector,
        (state: Immutable.Map<string, unknown>, props: SelectorProps) =>
            props.originalElementId || props.elementId || getElementId(props.element),
        (elements, elementGraph, elementId) =>
            getChildrenViaGraph({
                elements,
                elementGraph,
                parentId: elementId,
            }),
    );

export const getElementChildrenSorted = (sortFn?: SortFn) =>
    createSelector(getElementChildren(), (children) =>
        filterAndSortElementsInSection({
            elements: children,
            section: BoardSections.INBOX,
            sortFn,
        }),
    );

export const getElementChildIdsSelector = () =>
    createShallowSelector(
        // @ts-ignore TS struggling with JS file types
        elementGraphSelector,
        (state: Immutable.Map<string, unknown>, props: SelectorProps) => props.elementId || getElementId(props.element),
        (elementGraph: IdGraph, elementId) => elementGraph?.[elementId],
    );

export const getElementChildIdsSorted = (sortFn?: SortFn) =>
    createShallowSelector(getElementChildrenSorted(sortFn), (sortedChildren) =>
        sortedChildren.map(getElementId).toArray(),
    );
