// Lib
import { createSelector } from 'reselect';

// Utils
import * as pointLib from '../../../common/maths/geometry/point';
import { getLineControlSelector, makeGetAlignedLineEdgeDetails } from './lineSelector';
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getLinePointsOfInterest } from './utils/lineUtil';
import { getControlPosition, isControlPointCloseToStraightLineMidpoint } from './utils/lineControlPointUtil';
import { canvasZoomScaleSelector } from '../../canvas/store/canvasZoomSelector';

/**
 * Finds the new line points and beziers when offsetting the control point.
 */
export const getUpdatedLinePointsOfInterest = createSelector(
    makeGetAlignedLineEdgeDetails(),
    getLineControlSelector,
    getGridSize,
    canvasZoomScaleSelector,
    (state, ownProps) => ownProps.offsetDiff,
    (
        { startEdgeOrigin, startConnectionRects, endEdgeOrigin, endConnectionRects },
        control,
        gridSize,
        zoomScale,
        offsetDiff,
    ) => {
        // Find the control point
        let controlPoint = getControlPosition({ start: startEdgeOrigin, end: endEdgeOrigin, control, gridSize });

        // If there's no control point data, then find the original starting position of the control point
        // which is halfway between the start and end visible connection points
        if (!controlPoint) {
            const originalPointsOfInterest = getLinePointsOfInterest({
                startEdgeOrigin,
                startConnectionRects,
                endEdgeOrigin,
                endConnectionRects,
                controlPoint,
            });
            controlPoint = originalPointsOfInterest.controlPoint; // eslint-disable-line prefer-destructuring
        }

        const unscaledOffsetDiff = pointLib.reverseScale(zoomScale, offsetDiff);
        controlPoint = pointLib.translate(controlPoint, unscaledOffsetDiff);

        const isCloseToMidpoint = isControlPointCloseToStraightLineMidpoint({
            startEdgeOrigin,
            startConnectionRects,
            endEdgeOrigin,
            endConnectionRects,
            controlPoint,
        });

        if (isCloseToMidpoint) {
            controlPoint = null;
        }

        return getLinePointsOfInterest({
            startEdgeOrigin,
            startConnectionRects,
            endEdgeOrigin,
            endConnectionRects,
            controlPoint,
        });
    },
);
