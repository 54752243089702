// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isArray } from 'lodash/fp';

// Utils
import { toCssUrl } from '../../utils/cssUtil';

// Styles
import './BackgroundImage.scss';

const BackgroundImage = ({ className, source, children, style }) => {
    let backgroundImage = null;

    if (source) {
        backgroundImage = isArray(source) ? source.map(toCssUrl).join(',') : toCssUrl(source);
    }

    const styles = {
        ...style,
        backgroundImage,
    };

    return (
        <div className={classNames('BackgroundImage', className)} style={styles}>
            {children}
        </div>
    );
};

BackgroundImage.propTypes = {
    source: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    style: PropTypes.object,
};

export default BackgroundImage;
