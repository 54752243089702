// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { shouldShowFeatureSuggestion } from '../feature/elementFeatureUtils';

// Selectors
import { getDuplicateOriginalIdSelector } from '../duplicate/elementDuplicateSelector';

// Actions
import { convertDuplicateToClone } from '../actions/elementShortcutActions';
import { elementSuggestFeature, elementUnsuggestFeature } from '../suggestion/suggestionActions';
import { convertElementsToDocument } from '../document/documentActions';

// Hooks
import useUpdateEffect from '../../utils/react/useUpdateEffect';

// Components
import FeatureSuggestions from '../suggestion/FeatureSuggestions';
import ElementFeatureSuggestion from '../suggestion/ElementFeatureSuggestion';

// Constants
import { ELEMENT_FEATURES } from '../feature/elementFeatureConstants';
import { getElementId, getHasClones } from '../../../common/elements/utils/elementPropertyUtils';

const mapStateToProps = createStructuredSelector({
    copiedElementId: getDuplicateOriginalIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSuggestClone: (id) => dispatch(elementSuggestFeature({ id, feature: ELEMENT_FEATURES.CLONE })),
    dispatchUnsuggestClone: (id) => dispatch(elementUnsuggestFeature({ id, feature: ELEMENT_FEATURES.CLONE })),
    dispatchConvertDuplicateToClone: (element, transactionId) =>
        dispatch(convertDuplicateToClone(element, transactionId)),
    dispatchConvertElementsToDocument: (args) => dispatch(convertElementsToDocument(args)),
});

const shouldShowCloneSuggestion = (element, isClone) =>
    shouldShowFeatureSuggestion(ELEMENT_FEATURES.CLONE)(element) && !getHasClones(element) && !isClone;

const SUGGESTIONS = [
    {
        feature: ELEMENT_FEATURES.DOCUMENT,
        component: ElementFeatureSuggestion,
        shouldShow: shouldShowFeatureSuggestion(ELEMENT_FEATURES.DOCUMENT),
        question: 'Convert to document?',
        onConfirm: ({ element, dispatchConvertElementsToDocument }) =>
            dispatchConvertElementsToDocument([getElementId(element)]),
    },
    {
        feature: ELEMENT_FEATURES.CLONE,
        component: ElementFeatureSuggestion,
        shouldShow: shouldShowCloneSuggestion,
        question: 'Keep this copy in sync?',
        onConfirm: ({ element, dispatchConvertDuplicateToClone }, transactionId) =>
            dispatchConvertDuplicateToClone(element, transactionId),
    },
];

const CardFeatureSuggestions = (props) => {
    const { elementId, copiedElementId, dispatchSuggestClone, dispatchUnsuggestClone } = props;

    React.useEffect(() => {
        if (copiedElementId) dispatchSuggestClone(elementId);
    }, [copiedElementId]);

    useUpdateEffect(() => {
        if (!copiedElementId) dispatchUnsuggestClone(elementId);
    }, [copiedElementId]);

    return <FeatureSuggestions {...props} possibleSuggestions={SUGGESTIONS} />;
};

CardFeatureSuggestions.propTypes = {
    elementId: PropTypes.string,
    copiedElementId: PropTypes.string,
    dispatchSuggestClone: PropTypes.func,
    dispatchUnsuggestClone: PropTypes.func,
    dispatchConvertDuplicateToClone: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardFeatureSuggestions);
