import React from 'react';
import classNames from 'classnames';

// Utils
import { isIconViewLike } from '../../../common/elements/utils/elementDisplayUtils';
import { isImage } from '../../../common/elements/utils/elementTypeUtils';
import { getDomElementId } from '../utils/elementUtil';
import { getBackgroundColor, getImageThumbnail } from '../../../common/elements/utils/elementPropertyUtils';
import { toCssUrl } from '../../utils/cssUtil';
import { getColorCssValue } from '../utils/elementColorStyleUtils';

// Types
import { MNElement } from '../../../common/elements/elementModelTypes';

// Styles
import './ElementPlaceholder.scss';

type ElementPlaceholderProps = {
    element: MNElement;
    placeholderMeasurements: { width: number; height: number };
    elementId: string;
    isSelected: boolean;
};

const getPlaceholderBackground = (element: MNElement, isIconView: boolean) => {
    if (isIconView) return null;

    if (isImage(element)) {
        const imageThumbnailUrl = getImageThumbnail(element);
        if (imageThumbnailUrl) return toCssUrl(imageThumbnailUrl);
    }

    return getColorCssValue(getBackgroundColor(element));
};

const ElementPlaceholder = ({ element, placeholderMeasurements, elementId, isSelected }: ElementPlaceholderProps) => {
    const isIconView = isIconViewLike(element);
    const classes = classNames('ElementPlaceholder', { 'icon-view-placeholder': isIconView, selected: isSelected });
    const background = getPlaceholderBackground(element, isIconView);
    const { width, height } = placeholderMeasurements;
    const styles = { width, height, ...(background && { background }) };

    return <div id={getDomElementId(elementId)} style={styles} className={classes} />;
};

export default ElementPlaceholder;
