import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from '../../../node_module_clones/recompose';

import { navigateToBoard } from '../../reducers/navigationActions';

// Selectors
import aliasSelector from './aliasSelector';

// Utils
import { isTransientBoardFetchError } from '../board/boardErrorUtils';
import { isSkeleton } from '../../../common/elements/utils/elementTypeUtils';
import { getContent, getIsLinkAlias, getLinkedElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import Board from '../board/Board';
import BoardContainer from '../board/BoardContainer';
import AliasLoading from './AliasLoading';

// Styles
import './Alias.scss';

const showLoadingState = (linkedBoard, linkedBoardError) => {
    if (linkedBoardError) return isTransientBoardFetchError(linkedBoardError);

    if (!linkedBoard) return true;

    const linkedBoardIsSkeleton = isSkeleton(linkedBoard);

    return !getContent(linkedBoard) && !linkedBoardIsSkeleton;
};

const mapDispatchToProps = () => (dispatch) => ({
    dispatchNavigateToAlias: (boardId, keepSelection, newTab) =>
        dispatch(
            navigateToBoard({
                boardId,
                keepSelection,
                newTab,
            }),
        ),
});

const handlers = {
    navigateToAlias: (props) => (boardId, keepSelection, newTab) => {
        const { dispatchNavigateToAlias, element } = props;
        const linkedElementId = getLinkedElementId(element);
        dispatchNavigateToAlias(linkedElementId, keepSelection, newTab);
    },
};

const Alias = (props) => {
    const { linkedBoard, linkedBoardError, isPresentational, element, renderedElement } = props;

    if (showLoadingState(linkedBoard, linkedBoardError)) return <AliasLoading {...props} />;

    const isLinkAlias = getIsLinkAlias(element);

    return isPresentational ? (
        <Board {...props} isAlias isLinkAlias={isLinkAlias} element={renderedElement} />
    ) : (
        <BoardContainer {...props} isAlias isLinkAlias={isLinkAlias} element={renderedElement} />
    );
};

Alias.propTypes = {
    linkedElementId: PropTypes.string,
    isPresentational: PropTypes.bool,
    linkedBoard: PropTypes.object,
    linkedBoardError: PropTypes.object,
    element: PropTypes.object,
    renderedElement: PropTypes.object,
};

const enhance = compose(connect(aliasSelector, mapDispatchToProps), withHandlers(handlers));

export default enhance(Alias);
