import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypePercentageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-percentage"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                d="M20 17.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5Zm2.03-7.28a.75.75 0 0 1 0 1.06l-11 11a.75.75 0 0 1-1.06-1.06l11-11a.75.75 0 0 1 1.06 0ZM20 18.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-8-9.5a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5Zm0 1.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypePercentageIcon);
export default Memo;
