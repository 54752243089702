// Lib
import createLinkifyPluginBase from '../draftLinkifyPlugin';

import createHyperlinkPlugin from '../hyperlink';

// Components
import DraftLinkAnchor from './DraftLinkAnchor';
import DraftClickableLinkAnchor from './DraftClickableLinkAnchor';

/**
 * This allows "LINK" entities to be set on text.
 * The entity will have a URL and the text will be rendered as an anchor element.
 */
export const createLinkPlugin = createHyperlinkPlugin;

/**
 * This "linkifies" inline URLs such as www.milanote.com
 */
export const createLinkifyPlugin = ({ clickableLinks } = {}) =>
    createLinkifyPluginBase({
        target: '_blank',
        component: clickableLinks ? DraftClickableLinkAnchor : DraftLinkAnchor,
    });
