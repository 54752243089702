// Utils
import { prop, propIn } from '../../common/utils/immutableHelper';

// Constants
import { USER_ONLINE_STATUS } from '../../common/users/userConstants';
import {
    getDisplayName as getDisplayNameProp,
    getEmail,
    getFamilyName,
    getGivenName,
    getOnlineStatus,
} from '../../common/users/userHelper';
import { getIsGuestProp } from '../../common/users/utils/userPropertyUtils';

// Exports
export { getRole, isAdmin, isStaff } from '../../common/users/utils/userPropertyUtils';

export const getDisplayName = (user) => (user && (getDisplayNameProp(user) || getEmail(user))) || 'Someone';

export const getFirstName = (user, defaultFirstName = ':)') => (user && getGivenName(user)) || defaultFirstName;

export const getInitials = (user) => {
    const givenName = getGivenName(user);
    const familyName = getFamilyName(user);

    if (!givenName || !familyName) {
        return '•••';
    }

    return givenName[0] + familyName[0];
};

export const isOnline = (user) => user && getOnlineStatus(user) === USER_ONLINE_STATUS.CONNECTED;

export const isGuest = (user) => !user || getIsGuestProp(user);

export const isSubscribed = (user) => propIn(['subscription', 'active'], user);
export const isSubscriptionManuallyCreated = (user) => propIn(['subscription', 'manuallyCreated'], user);
export const isUserSubscriptionAdmin = (user, subscription) =>
    user && subscription && prop('_id', user) === prop('adminId', subscription);

export const getLastAccessDate = (user) => prop('lastAccessDate', user);
