import { MarkType, Node, NodeType } from '@tiptap/pm/model';

type JoinableNode = {
    node: Node;
    pos: number;
};

type MatchResult = {
    from: number;
    to: number;
    text: string;
};

export const findMatchingTextRanges = (
    doc: Node,
    regex: RegExp,
    forbiddenMarks: MarkType[] = [],
    forbiddenNodes: NodeType[] = [],
): MatchResult[] => {
    const matchResults: MatchResult[] = [];
    let nodes: JoinableNode[] = [];

    const canJoin = (n: Node) => {
        if (!n.isText) return false;
        if (n.marks.some((m) => forbiddenMarks.includes(m.type))) return false;
        return true;
    };

    const canDescend = (n: Node) => !forbiddenNodes.includes(n.type);

    const processCollectedNodes = () => {
        if (nodes.length === 0) return;

        const pos = nodes[0].pos;
        const text = nodes.map(({ node }) => node.text).join('');

        const matches = text.matchAll(regex);
        for (const match of matches) {
            const { index } = match;
            const [matchingText] = match;

            const from = pos + index;
            const to = from + matchingText.length;

            matchResults.push({ from, to, text: matchingText });
        }

        nodes = [];
    };

    // traverse the document, collecting sequences of adjacent text nodes
    doc.descendants((node, pos) => {
        if (!canDescend(node)) return false;

        if (!canJoin(node)) {
            processCollectedNodes();
            return true;
        }

        nodes.push({ node, pos });
        return false;
    });

    processCollectedNodes(); // in case the last nodes were text nodes

    return matchResults;
};
