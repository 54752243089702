export const getClipboardPasteCount = (state) => state.getIn(['local', 'clipboard', 'pasteCount']);
export const getClipboardOperation = (state) => state.getIn(['local', 'clipboard', 'operation']);
export const getClipboardSaveId = (state) => state.getIn(['local', 'clipboard', 'saveId']);
export const getClipboardElements = (state) => state.getIn(['local', 'clipboard', 'elements']);
export const getClipboardVisibleOnCanvas = (state) => state.getIn(['local', 'clipboard', 'visibleOnCanvas']);
export const getClipboardElementsBoundingRect = (state) => state.getIn(['local', 'clipboard', 'elementsBoundingRect']);
export const getClipboardCutElementsIdMapSelector = (state) => state.getIn(['local', 'clipboard', 'cutElementsIdMap']);
export const getClipboardLastEditedTimestamp = (state) => state.getIn(['local', 'clipboard', 'timestamp']);

export const getIsElementClipboardCutSelector = (state, ownProps) =>
    state.getIn(['local', 'clipboard', 'cutElementsIdMap', ownProps.elementId]);
