// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import hyperlinkOpenPopupDecorator from '../../components/editor/plugins/hyperlink/hyperlinkOpenPopupDecorator';
import MilanoteEditor from '../../components/editor/milanoteEditor/MilanoteEditor';

// Styles
import './TitleEditor.scss';

const TitleEditor = (props) => <MilanoteEditor {...props} className={classNames('TitleEditor', props.className)} />;

TitleEditor.propTypes = {
    className: PropTypes.string,
};

export default hyperlinkOpenPopupDecorator(TitleEditor);
