import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavSearchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon nav-search"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M10.5 1.25a9.25 9.25 0 0 1 9.25 9.25 9.213 9.213 0 0 1-2.2 5.99l4.98 4.98a.75.75 0 0 1-1.06 1.06l-4.98-4.98a9.213 9.213 0 0 1-5.99 2.2 9.25 9.25 0 0 1 0-18.5zm0 1.5a7.75 7.75 0 1 0 0 15.5 7.75 7.75 0 0 0 0-15.5z"
        />
    </svg>
);
const Memo = memo(NavSearchIcon);
export default Memo;
