// Lib
import { flow, size, compact, values, sortBy, map, head, last, get, parseInt } from 'lodash/fp';
import createCounter from './createCounter';

export default (initialSendBuffer = {}) => {
    let initialCount = flow(values, compact, last, get('id'), parseInt(10))(initialSendBuffer);

    initialCount = isNaN(initialCount) ? 0 : initialCount;

    const counter = createCounter(initialCount);

    let buffer = initialSendBuffer;

    const addActionToBuffer = (eventName, action) => {
        const id = counter();
        buffer[id] = {
            id,
            eventName,
            action,
        };
        return id;
    };

    const removeActionFromBuffer = (id) => {
        delete buffer[id];
    };

    const _size = () => flow(values, compact, size)(buffer);

    const isEmpty = () => _size() === 0;

    const clearBuffer = () => {
        buffer = {};
    };

    const getBuffer = () => buffer;

    const getAction = (id) => buffer[id];

    const getNextAction = () => flow(values, compact, sortBy('id'), head)(buffer);

    const getOrderedActions = () => flow(values, compact, sortBy('id'), map('action'))(buffer);

    return {
        addActionToBuffer,
        removeActionFromBuffer,
        size: _size,
        isEmpty,
        clearBuffer,
        getBuffer,
        getAction,
        getNextAction,
        getOrderedActions,
    };
};
