/* eslint-disable dot-notation */
// Lib
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash/fp';

// Utils
import filterSuggestions from './filterSuggestions';
import { prop } from '../../../../../common/utils/immutableHelper';
import { hasChanged } from '../../../../utils/react/propsComparisons';

// Components
import MentionSuggestionEntry from './MentionSuggestionEntry';

// Styles
import './MentionSuggestions.scss';

const possibleSuggestionsHaveChanged = hasChanged('possibleSuggestions');

const getPortalMountPoint = () => document.getElementById('mention-mount-point');

class MentionSuggestions extends React.Component {
    constructor(props) {
        super(props);

        this.lastSearchValue = '';

        this.state = {
            suggestions: props.possibleSuggestions,
            disabledSuggestions: [],
        };
    }

    componentDidUpdate(prevProps) {
        // Note: This executes every time the editor is focused, might want to investigate why
        // Don't update while editing to ensure the list doesn't change after invite
        if (!this.props.isEditing && possibleSuggestionsHaveChanged(prevProps, this.props)) {
            this.getSuggestions(this.lastSearchValue, this.props.possibleSuggestions);
        }
    }

    onSearchChange = ({ value }) => {
        this.lastSearchValue = value;
        this.getSuggestions(value, this.props.possibleSuggestions);
    };

    getSuggestions = (value, possibleSuggestions) => {
        const suggestions = filterSuggestions(value, possibleSuggestions);

        const groups = groupBy(prop('disabled'), suggestions);

        const enabledSuggestions = groups[false] || [];

        const disabledSuggestions = groups[true] || [];

        this.setState({
            suggestions: enabledSuggestions,
            disabledSuggestions,
        });
    };

    render() {
        const { Component, onAddMention } = this.props;
        const { suggestions, disabledSuggestions } = this.state;

        const portalMountPoint = getPortalMountPoint();

        if (!portalMountPoint) return null;

        return createPortal(
            <Component
                suggestions={suggestions}
                disabledSuggestions={disabledSuggestions}
                onSearchChange={this.onSearchChange}
                onAddMention={onAddMention}
                entryComponent={MentionSuggestionEntry}
            />,
            portalMountPoint,
        );
    }
}

MentionSuggestions.propTypes = {
    Component: PropTypes.elementType,
    possibleSuggestions: PropTypes.array,
    onAddMention: PropTypes.func,
    isEditing: PropTypes.bool,
};

export default MentionSuggestions;
