import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SaveWorkspaceArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon save-workspace-arrow"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#323B4A"
                d="M5.53 6.53a.749.749 0 1 1-1.06-1.06l2-2a.749.749 0 0 1 1.06 0l2 2a.749.749 0 1 1-1.06 1.06L7 5.06 5.53 6.53z"
                className="color-fill"
            />
            <path
                stroke="#323B4A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 5.5V9a2 2 0 0 0 2 2h2.5"
                className="color-stroke"
            />
        </g>
    </svg>
);
const Memo = memo(SaveWorkspaceArrowIcon);
export default Memo;
