// Lib
import * as Immutable from 'immutable';

// Selectors
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { getUsers } from '../../../user/usersSelector';

// Constants
import { getTimestamp, TIMES } from '../../../../common/utils/timeUtil';

const bringUserToTop = (userId) => (userA, userB) => {
    if (userA.get('_id') === userId) return -1;
    if (userB.get('_id') === userId) return 1;
    return 0;
};

const timestampIsRecent = (currentTime) => (timestamp) => currentTime - timestamp - 20 * TIMES.SECOND < 0;

const getCommentThreadActivity = (state, { threadId }) => state.getIn(['remoteActivity', 'commentThreads', threadId]);

export const getIsCreatorActive = (state, { threadId, creatorId }) => {
    const creatorTimestamp = state.getIn(['remoteActivity', 'commentThreads', threadId, creatorId]);
    const currentTime = getTimestamp();
    return timestampIsRecent(currentTime)(creatorTimestamp);
};

export const makeGetCommentThreadActiveUsers = () =>
    createShallowSelector(
        getCommentThreadActivity,
        getUsers,
        (state, { creatorId }) => creatorId,
        (threadActivity, users, creatorId) => {
            if (!threadActivity) return Immutable.Seq(); // eslint-disable-line new-cap

            const currentTime = Date.now();
            return threadActivity
                .filter(timestampIsRecent(currentTime))
                .map((timestamp, userId) => users.get(userId))
                .sort(bringUserToTop(creatorId))
                .valueSeq();
        },
    );
