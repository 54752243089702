// Lib
import * as Immutable from 'immutable';

// Constants
import { DRAG_MODIFIER_KEYS_SET } from './dragModifierKeysConstants';
import { DRAG_END } from '../../../reducers/draggingConstants';
import { ELEMENT_MOVE_AND_UPDATE } from '../../../../common/elements/elementConstants';

const initialState = Immutable.fromJS({
    ctrlKey: false,
    shiftKey: false,
    altKey: false,
    metaKey: false,
});

export default function modifierKeyReducer(state = initialState, action) {
    switch (action.type) {
        case DRAG_MODIFIER_KEYS_SET:
            return Immutable.fromJS(action.modifierKeys);
        // This is used to prevent the modifier keys from getting stuck when using the quick line
        case ELEMENT_MOVE_AND_UPDATE:
        case DRAG_END:
            return initialState;
        default:
            return state;
    }
}
