import http from '../../utils/services/http';

export const searchForIcon = (query, count, queryType) => {
    const requestConfig = {
        url: '/icons',
        params: {
            search: query && query.toLowerCase(),
            queryType,
            count,
        },
        timeout: 15000,
    };

    return http(requestConfig);
};
