// Lib
import axios from 'axios';

// Constants
import { TIMES } from '../../utils/timeUtil';
import { METHODS } from '../../utils/http/httpConstants';

const DEFAULT_MEDIA_SERVER_HTTP_CONFIG = {
    method: METHODS.GET,
    timeout: 2 * TIMES.SECOND,
    responseType: 'json',
};

export default (apiUrl, jwtToken) => (httpConfig) => {
    const axiosConfig = {
        ...DEFAULT_MEDIA_SERVER_HTTP_CONFIG,
        ...httpConfig,
    };

    if (jwtToken) {
        axiosConfig.headers = {
            Authorization: `Bearer ${jwtToken}`,
            ...axiosConfig.headers,
        };
    }

    axiosConfig.url = `${apiUrl}/${httpConfig.url}`;

    return axios(axiosConfig);
};
