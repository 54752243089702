import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuGroupIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-group"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M2.75 19V5A2.25 2.25 0 0 1 5 2.75h14A2.25 2.25 0 0 1 21.25 5v14a2.25 2.25 0 0 1-2.096 2.245L19 21.25H5A2.25 2.25 0 0 1 2.75 19zM19.751 8.416 19.75 5a.75.75 0 0 0-.75-.75H5a.75.75 0 0 0-.75.75l.001 3.416h15.5zm0 5.667V9.916h-15.5v4.167h15.5zM19.75 19l.001-3.417h-15.5L4.25 19c0 .38.282.693.648.743L5 19.75h14a.75.75 0 0 0 .75-.75z"
        />
    </svg>
);
const Memo = memo(ContextMenuGroupIcon);
export default Memo;
