// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ElementPresentational from './ElementPresentational';
import ElementContainer from './ElementContainer';
import ElementErrorBoundary from './error/ElementErrorBoundary';
import ElementPreviewPresentational from './ElementPreviewPresentational';

// Styles
import './Element.scss';

const getDisplayElement = (props) => {
    if (props.isPreview) return ElementPreviewPresentational;
    if (props.isPresentational) return ElementPresentational;
    return ElementContainer;
};

const Element = React.memo(function Element(props) {
    const DisplayElement = getDisplayElement(props);

    return (
        <ElementErrorBoundary elementId={props.elementId} isPresentational={props.isPresentational}>
            <DisplayElement {...props} />
        </ElementErrorBoundary>
    );
});

Element.propTypes = {
    currentBoardId: PropTypes.string.isRequired,
    elementId: PropTypes.string,
    isPresentational: PropTypes.bool,
    isPreview: PropTypes.bool,
};

export default Element;
