import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from '../../../node_module_clones/react-transition-group';

const DEFAULTS = {
    transitionName: 'fade',
    duration: 300,
    appear: false,
};

export default ({ transitionName, duration, appear } = DEFAULTS) =>
    (DecoratedComponent) => {
        const AnimateFadeDecorator = ({ fadeIn = false, show = true, ...props }) => {
            if (fadeIn) {
                return (
                    <CSSTransitionGroup
                        transitionName={transitionName}
                        transitionAppear={appear}
                        transitionAppearTimeout={duration}
                        transitionEnterTimeout={duration}
                        transitionLeaveTimeout={duration}
                    >
                        {show ? <DecoratedComponent {...props} /> : null}
                    </CSSTransitionGroup>
                );
            }

            return show ? <DecoratedComponent {...props} /> : null;
        };

        AnimateFadeDecorator.propTypes = {
            fadeIn: PropTypes.bool,
            show: PropTypes.bool,
        };

        return AnimateFadeDecorator;
    };
