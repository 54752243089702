// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import RouteLink from '../buttons/RouteLink';

// Styles
import './ModalActionsList.scss';

const ModalActionsList = ({ actions, location }) => (
    <ul className="ModalActionsList">
        {actions.map((action, index) => (
            <li key={index}>
                {action.to ? (
                    <RouteLink to={action.to} keepParams={action.keepParams} location={location}>
                        {action.text}
                    </RouteLink>
                ) : (
                    <a href={action.href || '#'} onClick={action.func}>
                        {action.text}
                    </a>
                )}
            </li>
        ))}
    </ul>
);

ModalActionsList.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            func: PropTypes.func,
            to: PropTypes.string,
            keepParams: PropTypes.bool,
            href: PropTypes.string,
        }),
    ),
    location: PropTypes.object,
};

export default ModalActionsList;
