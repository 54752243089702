// Lib
import { isString } from 'lodash';

// Constants
import { METHODS } from '../../../utils/http/httpConstants';
import { TIMES } from '../../../utils/timeUtil';

const FormData = global.FormData || require('form-data');

export default (mediaHttp) => ({
    // FIXME This is currently used for uploading images via the public API and chrome extension
    // At some stage this should be refactored to use the new presign method
    uploadMediaFile: (imageData, options) => {
        const optionsData = JSON.stringify(options);
        let data;
        if (isString(imageData)) {
            data = {
                dataUri: imageData,
                options: optionsData,
            };
        } else {
            data = new FormData();
            data.append('file', imageData);
            data.append('options', optionsData);
        }

        const requestConfig = {
            url: 'image',
            method: METHODS.POST,
            timeout: 5 * TIMES.MINUTE,
            data,
        };

        if (data.getHeaders) {
            requestConfig.headers = data.getHeaders();
        }

        return mediaHttp(requestConfig);
    },
    resizeImage: ({ url, slug, callbackUrl, imageType }) => {
        const requestConfig = {
            url: 'image/resize',
            method: METHODS.POST,
            timeout: TIMES.MINUTE,
            data: {
                url,
                slug,
                callbackUrl,
                imageType,
            },
        };
        return mediaHttp(requestConfig);
    },
    createThumbnail: ({ url }) => {
        const requestConfig = {
            url: 'image/thumb',
            method: METHODS.GET,
            timeout: TIMES.MINUTE,
            params: {
                url,
            },
        };
        return mediaHttp(requestConfig);
    },
});
