export const HEADERS = {
    ACCEPT: 'Accept',
    CONTENT_TYPE: 'Content-Type',
    CONTENT_LENGTH: 'Content-Length',
    CACHE_CONTROL: 'Cache-Control',
};

export const CACHE_CONTROL_KEYS = {
    MAX_AGE: 'max-age',
    S_MAX_AGE: 's-maxage',
    STALE_WHILE_REVALIDATE: 'stale-while-revalidate',
    STALE_IF_ERROR: 'stale-if-error',
    PRIVATE: 'private',
};

export const ERROR_CODES = {
    CONNECTION_ABORTED: 'ECONNABORTED',
    CONNECTION_RESET: 'ECONNRESET',
    CONNECTION_REFUSED: 'ECONNREFUSED',
};

// Only use methods supported by CloudFront:
// https://docs.aws.amazon.com/cloudfront/latest/APIReference/API_AllowedMethods.html
export const METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    OPTIONS: 'options',
};

// NOTE: custom tokens shouldn't include dots "."
export const CUSTOM_HTTP_HEADERS = {
    PERMISSION_TOKENS: 'milanote-com-permission-tokens',
    NO_THROTTLE: 'milanote-com-no-throttle',

    // Used to switch to a custom version of the API
    API_FEATURE: 'milanote-com-api-feature',

    // Used so the server can give hints to the client about how long the data is valid for
    DATA_EXPIRY: 'milanote-com-data-expiry',
    DATA_FETCHED_TIME: 'milanote-com-data-fetched-time',

    CMS_CACHE_RESPONSE_TYPE: 'milanote-com-cms-cache',
};

export const AWS_HEADERS = {
    WAF_ACTION: 'x-amzn-waf-action',
    WAF_TOKEN: 'x-aws-waf-token',
};

export const AWS_HEADERS_VALUES = {
    WAF_ACTION: {
        CHALLENGE: 'challenge',
    },
};
