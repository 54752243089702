// Const
import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

// Writing our own isValid function based on the MongoDB driver version to reduce file size as this will also
// be included on the client
const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
const isObjectIdValid = (id) => {
    if (id === null) return false;

    if (typeof id === 'number') {
        return true;
    }

    if (typeof id === 'string') {
        return id.length === 12 || (id.length === 24 && checkForHexRegExp.test(id));
    }

    return false;
};

const EMAIL_ERROR_OBJECT = {
    name: FAILURE_NAMES.objectId,
    type: FAILURE_TYPES.ERROR,
    message: 'Not a valid objectId',
};

const getErrorObject = (fieldValue) => ({
    ...EMAIL_ERROR_OBJECT,
    value: fieldValue,
});

export default (fieldValue) => {
    if (!fieldValue) return null;
    if (!isObjectIdValid(fieldValue)) return getErrorObject(fieldValue);
    return null;
};
