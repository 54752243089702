// Lib
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import ContextMenuItem from '../ContextMenuItem';

// Utils
import { getImageSource } from '../../../element/image/imageHelper';
import { getElementId, getImageProp, getFileUrl } from '../../../../common/elements/utils/elementPropertyUtils';
import { getIsFeatureEnabledForCurrentUser } from '../../../element/feature/elementFeatureSelector';
import { isFile, isImage } from '../../../../common/elements/utils/elementTypeUtils';
import { triggerDownload } from '../../../element/attachments/attachmentDownloadActions';

// Constants
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';

const mapStateToProps = createStructuredSelector({
    renderSecureImagesThroughProxy: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughProxy),
    // Default to enabled for guest users
    renderSecureImagesThroughCF: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughCF, true),
});

const downloadTitle = (singleImage, allImages) => {
    const originalText = 'Download Original Image';

    if (singleImage) return originalText;
    if (allImages) return `${originalText}s`;
    return 'Download';
};

const mapDispatchToProps = (dispatch) => ({
    dispatchTriggerDownload: (params) => dispatch(triggerDownload(params)),
});

const DownloadOriginalContextMenuItem = ({
    contextMenuHandlers,
    allImages,
    singleImage,
    selectedElements,
    renderSecureImagesThroughProxy,
    renderSecureImagesThroughCF,
    dispatchTriggerDownload,
}) => {
    const download = useCallback(
        () =>
            selectedElements.forEach((el) => {
                let url;

                if (isFile(el)) {
                    url = getFileUrl(el);
                } else {
                    url = getImageSource({
                        imageSize: 'original',
                        imageDetails: getImageProp(el),
                        elementId: getElementId(el),
                        useSecureMediaUrl: true,
                        renderSecureImagesThroughProxy,
                        renderSecureImagesThroughCF,
                        optimizeForWebRender: false,
                    });
                }

                if (url) dispatchTriggerDownload({ url, id: getElementId(el) });
            }),
        [selectedElements],
    );

    const showDownloadOption = selectedElements.every((el) => isFile(el) || isImage(el));
    const title = downloadTitle(singleImage, allImages);

    return (
        <ContextMenuItem
            {...contextMenuHandlers}
            title={title}
            id="downloadOriginal"
            show={showDownloadOption}
            onClick={download}
        />
    );
};

DownloadOriginalContextMenuItem.propTypes = {
    contextMenuHandlers: PropTypes.object,
    allImages: PropTypes.bool,
    selectedElements: PropTypes.object,
    singleImage: PropTypes.bool,
    renderSecureImagesThroughProxy: PropTypes.bool,
    renderSecureImagesThroughCF: PropTypes.bool,
    dispatchTriggerDownload: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadOriginalContextMenuItem);
