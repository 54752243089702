// Utils
import replaceSelectionWithText from '../../customRichUtils/replaceSelectionWithText';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';

const NEWLINE_REGEX = /\r\n?|\n/g;

export default (text, html, editorState, { setEditorState }) => {
    if (!text) return HANDLER_RETURN_VALUES.notHandled;

    const cleanedText = text.replace(NEWLINE_REGEX, ' ').trim();

    const updatedEditorState = replaceSelectionWithText(cleanedText)(editorState);

    setEditorState(updatedEditorState);

    return HANDLER_RETURN_VALUES.handled;
};
