export const ENABLE_DEBUG_TOOLS = 'ENABLE_DEBUG_TOOLS';
export const DEBUG_TOOL_TOGGLE = 'TOGGLE_DEBUG_TOOL';
export const ENABLE_DEBUG_TOOLBAR = 'ENABLE_DEBUG_TOOLBAR';

export const DEBUG_LOCAL_STORAGE_KEY = 'milanote.debug';
export const DEBUG_DISABLE_CACHE_LOCAL_STORAGE_KEY = 'milanote.debug.disableCache';
export const DEBUG_DISABLE_LOCAL_CACHE_RESET_LOCAL_STORAGE_KEY = 'milanote.debug.disableLocalCacheReset';

export enum DebugComponent {
    GLOBAL = 'global',
    LOGS = 'logs',
    TOOLBAR = 'toolbar',
    STOPWATCH = 'stopwatch',
}
