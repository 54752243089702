// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { isStartEdge } from '../utils/lineUtil';
import { getLineMarkerId } from './LineMarker';

// Constants
import { LINE_WEIGHT } from '../../../../common/lines/lineConstants';

/**
 * The marker size gets multiplied in SVG by the stroke weight, so we need to divide by it too.
 * NOTE: The arrow width and height is the same, so just calling it "marker size" for now.
 */
const getMarkerSize = (lineWeight = LINE_WEIGHT.M) => {
    if (lineWeight <= LINE_WEIGHT.S) {
        return 8 / lineWeight;
    }

    if (lineWeight <= LINE_WEIGHT.M) {
        return 10 / lineWeight;
    }

    return 13 / lineWeight;
};

const getStartArrowPath = (markerSize) => `M${markerSize},0 L0,${markerSize / 2} L${markerSize},${markerSize} z`;

const getEndArrowPath = (markerSize) => `M0,0 L${markerSize},${markerSize / 2} L0,${markerSize} z`;

const ArrowMarker = ({ lineWeight, edge, elementId, idSuffix }) => {
    const markerSize = getMarkerSize(lineWeight);
    const path = isStartEdge(edge) ? getStartArrowPath(markerSize) : getEndArrowPath(markerSize);
    const offset = 1;
    const refX = isStartEdge(edge) ? offset : markerSize - offset;

    return (
        <marker
            id={getLineMarkerId({ edge, elementId, idSuffix })}
            className="arrow"
            viewBox={`0 0 ${markerSize} ${markerSize}`}
            refX={refX}
            refY={markerSize / 2}
            markerWidth={markerSize}
            markerHeight={markerSize}
            orient="auto"
            markerUnits="strokeWidth"
        >
            <path d={path} />
        </marker>
    );
};

ArrowMarker.propTypes = {
    edge: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
    idSuffix: PropTypes.string,
    lineWeight: PropTypes.number,
};

export default ArrowMarker;
