// Constants
import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

const NUMBER_ERROR_OBJECT = {
    name: FAILURE_NAMES.number,
    type: FAILURE_TYPES.ERROR,
    debounced: true,
    message: 'Not a valid number',
};

export default (fieldValue) => {
    if (!fieldValue) return;

    const numericValue = parseInt(fieldValue, 10);
    if (isNaN(numericValue)) return NUMBER_ERROR_OBJECT;
};
