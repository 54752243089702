import { fetchBoard, fetchVisibleDescendantBoards } from '../element/board/boardService';

import { ELEMENT_FORCE_LOAD } from '../../common/elements/elementConstants';

export default (store) => (next) => (action) => {
    if (action.type !== ELEMENT_FORCE_LOAD) return next(action);

    store
        .dispatch(
            fetchBoard({
                boardId: action.id,
                force: true,
                excludeSelf: true,
                loadNextBoards: true,
                loadAncestors: false,
            }),
        )
        .then(() => store.dispatch(fetchVisibleDescendantBoards(action.id)));

    return next(action);
};
