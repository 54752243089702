// Lib
import { createSelector } from 'reselect';

// Utils
import { getBoardNotificationPreference } from './boardNotificationPreferenceUtils';

// Selectors
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getElements } from '../../element/selectors/elementsSelector';

export const getAllBoardNotificationPreferences = (state) =>
    state.getIn(['notifications', 'boardNotificationPreferences']);

// FIXME depending on where this is used we might want to make it more performant (i.e. fetching ancestors)
export const getCurrentBoardNotificationPreference = createSelector(
    getCurrentBoardId,
    getElements,
    getAllBoardNotificationPreferences,
    (currentBoardId, elements, boardNotificationPreferences) =>
        getBoardNotificationPreference({
            elementId: currentBoardId,
            elements,
            boardNotificationPreferences,
        }),
);
