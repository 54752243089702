// Lib
import { flow } from 'lodash';

const removeSensitiveInformation = (normalisedError) => ({
    ...normalisedError,
    data: undefined,
    errorDetails: undefined,
});

const getResponseErrorData = (data) => {
    if (!data || !data.error) return undefined;
    const { error } = data;

    return {
        errorName: error.name,
        errorCode: error.code,
        errorMessage: error.message,
        errorDetails: error.details || error.detail,
    };
};

const getResponseRequestData = (request) => {
    if (!request) return undefined;

    return {
        // Overwrite the other apiUrl as this is more detailed
        apiUrl: request.responseURL,
    };
};

const getResponseData = (response) => {
    if (!response) return undefined;

    return {
        status: response.status,
        statusText: response.statusText,
        ...getResponseRequestData(response.request),
        ...getResponseErrorData(response.data),
    };
};

const getConfigData = (config) => {
    if (!config) return undefined;

    return {
        apiUrl: config.url,
        method: config.method,
        data: config.data,
    };
};

const normaliseError = ({ error, custom }) => ({
    code: error.code,
    custom,
    ...getConfigData(error.config),
    ...getResponseData(error.response),
});

export default ({ error, custom, sensitive }) => {
    const transformations = [normaliseError];
    if (sensitive) transformations.push(removeSensitiveInformation);
    return flow(transformations)({ error, custom });
};
