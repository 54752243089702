// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { isPlatformLegacyMobileApp } from '../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

/**
 * Hide the children if we're running on the mobile hybrid app (iPad app).
 */
type SwiftHybridHiddenProps = {
    children: React.ReactNode | React.ReactNode[];
    fallback?: React.ReactNode | React.ReactNode[] | React.ReactElement | null;
};

const SwiftHybridHidden = ({ children, fallback = null }: SwiftHybridHiddenProps): React.ReactElement => {
    const platformDetails = useSelector(getPlatformDetailsSelector);

    return (isPlatformLegacyMobileApp(platformDetails) ? fallback : children) as React.ReactElement;
};

export default SwiftHybridHidden;
