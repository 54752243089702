import { ImmutableMap } from '../../common/utils/immutableHelper';
import { AttachModeType } from '../utils/dnd/dndConstants';

export const isDraggingSelector = (state: ImmutableMap): boolean => state.getIn(['app', 'dragging', 'isDragging']);

const getDroppedElementIdsSelector = (state: ImmutableMap) =>
    state.getIn(['app', 'dragging', 'dropState', 'droppedElementIds']);

export const getDropState = (state: ImmutableMap, { elementId }: { elementId: string }) => {
    const droppedElementIds = getDroppedElementIdsSelector(state);

    if (!droppedElementIds || !droppedElementIds.includes(elementId)) return null;

    return state.getIn(['app', 'dragging', 'dropState']);
};

// Attach mode
export const isAttachModeSelector = (state: ImmutableMap): boolean => state.getIn(['app', 'dragging', 'attachMode']);
export const attachModeTypeSelector = (state: ImmutableMap): AttachModeType | null =>
    state.getIn(['app', 'dragging', 'attachModeType']);
export const attachModeHoveredStackSelector = (state: ImmutableMap) =>
    state.getIn(['app', 'dragging', 'attachModeHoveredStack']);
export const attachModeHoveredElementIdSelector = (state: ImmutableMap): string | null =>
    state.getIn(['app', 'dragging', 'attachModeHoveredElementId']);

export const getReplaceModeHoveredElementId = (state: ImmutableMap): string | null =>
    state.getIn(['app', 'dragging', 'replaceModeHoveredElementId']);
export const getIsReplaceModeActive = (state: ImmutableMap): boolean => !!getReplaceModeHoveredElementId(state);
export const getIsReplaceModeHovered = (state: ImmutableMap, { elementId }: { elementId: string }): boolean =>
    !!elementId && elementId === getReplaceModeHoveredElementId(state);
