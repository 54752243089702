// Utils
import {
    enableAmplitudeTracking as enableAmplitudeTrackingJS,
    setAmplitudeUser as setAmplitudeUserJS,
    clearAmplitudeUser as clearAmplitudeUserJS,
    sendAmplitudeUserProperties as sendAmplitudeUserPropertiesJS,
    sendAmplitudeEvent as sendAmplitudeEventJS,
    initialiseAmplitude as initialiseAmplitudeJS,
    initialiseAmplitudeData as initialiseAmplitudeDataJS,
    getAmplitudeDeviceId as getAmplitudeDeviceIdJS,
} from './amplitudeServiceJS';

import {
    setAmplitudeUser as setAmplitudeUserSwift,
    clearAmplitudeUser as clearAmplitudeUserSwift,
    sendAmplitudeUserProperties as sendAmplitudeUserPropertiesSwift,
    sendAmplitudeEvent as sendAmplitudeEventSwift,
    initialiseAmplitude as initialiseAmplitudeSwift,
} from './amplitudeServiceSwift';

import getClientConfig from '../utils/getClientConfig';

const passToSwift = () => getClientConfig().analytics?.passToSwift;

const noOp = () => {};

export const getAmplitudeDeviceId = passToSwift() ? noOp : getAmplitudeDeviceIdJS;
export const enableAmplitudeTracking = passToSwift() ? noOp : enableAmplitudeTrackingJS;
export const setAmplitudeUser = passToSwift() ? setAmplitudeUserSwift : setAmplitudeUserJS;
export const clearAmplitudeUser = passToSwift() ? clearAmplitudeUserSwift : clearAmplitudeUserJS;
export const sendAmplitudeUserProperties = passToSwift()
    ? sendAmplitudeUserPropertiesSwift
    : sendAmplitudeUserPropertiesJS;
export const sendAmplitudeEvent = passToSwift() ? sendAmplitudeEventSwift : sendAmplitudeEventJS;
export const initialiseAmplitude = passToSwift() ? initialiseAmplitudeSwift : initialiseAmplitudeJS;
export const initialiseAmplitudeData = passToSwift() ? noOp : initialiseAmplitudeDataJS;
