// Lib
import React from 'react';

import PropTypes from 'prop-types';

// Components
import RouteModalRoot from './RouteModalRoot';
import AuthenticatedContainer from '../../auth/AuthenticatedContainer';

const RouteModalRootAuthenticated = ({ children, ...props }) => (
    <AuthenticatedContainer>
        <RouteModalRoot {...props}>{children}</RouteModalRoot>
    </AuthenticatedContainer>
);

RouteModalRootAuthenticated.propTypes = {
    close: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    disableClose: PropTypes.bool,
    showBack: PropTypes.bool,
};

export default RouteModalRootAuthenticated;
