// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getData, getLoaded } from '../../attachments/attachmentsSelector';
import { getFileSizeComparisonString } from '../../../../common/files/fileSizeUtils';
import { getFileDataSize, getFileIsAwaitingPreview, getFileAttachmentProgress } from '../utils/fileElementUtils';

// Components
import ProgressBar from '../uploader/ProgressBar';

import './FileUploadProgress.scss';

const FileUploadProgress = ({ element, attachment }) => {
    const fileData = element.getIn(['content', 'file']) || getData(attachment);
    const awaitingPreview = getFileIsAwaitingPreview({ element, attachment });

    const progress = getFileAttachmentProgress({ element, attachment });

    const loaded = getLoaded(attachment) || 0;
    const fileSize = getFileDataSize(fileData);

    return (
        <div className="FileUploadProgress">
            <ProgressBar percentageComplete={progress} barbershop animate />
            {awaitingPreview ? (
                <span className="progress-message">Processing</span>
            ) : (
                <span className="progress-message">{getFileSizeComparisonString(loaded, fileSize)}</span>
            )}
        </div>
    );
};

FileUploadProgress.propTypes = {
    element: PropTypes.object,
    attachment: PropTypes.object,
};

export default FileUploadProgress;
