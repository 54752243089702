// Handlers
import handleReturn from './handleReturn';

/**
 * Requires the following props:
 *  - stopEditing
 */
export default () => ({
    handleReturn,
});
