// Lib
import { min } from 'lodash/fp';

// Utils
import { objectSize, prop } from '../../../common/utils/immutableHelper';
import {
    getImageHeight,
    getImageProp,
    getImageWidth,
    getImageOriginal,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getData } from '../attachments/attachmentsSelector';
import { getIsMilanoteDomain } from '../../../common/utils/urlUtil';

// Constants
import { IMAGE_WIDTH_MAX } from '../../../common/images/imageConstants';

export const getNaturalImageWidth = ({ element, attachmentData }) =>
    attachmentData ? prop('width', attachmentData) : getImageWidth(element);

export const getNaturalImageHeight = ({ element, attachmentData }) =>
    getImageHeight(element) || getNaturalImageWidth({ element, attachmentData });

export const getImageDefaultMaxWidth = ({ element, attachmentData }) =>
    min([getNaturalImageWidth({ element, attachmentData }), IMAGE_WIDTH_MAX]);

export const getImageAspectRatio = ({ element, attachmentData }) =>
    getNaturalImageWidth({ element, attachmentData }) / getNaturalImageHeight({ element, attachmentData });

export const getHasImageToShow = ({ element, attachment }) => {
    const imageData = getImageProp(element);
    const attachmentData = getData(attachment);
    return objectSize(imageData) > 0 || objectSize(attachmentData) > 0;
};

export const isMilanoteHostedImage = (element) => getIsMilanoteDomain(getImageOriginal(element));
