// Constants
import { ResourceTypes, RESOURCES_FETCHED } from '../../asyncResourceConstants';
import {
    TRASH_CLEAR_COMPLETE,
    TRASH_CLEAR_FAILED,
    TRASH_CLEAR_START,
    TRASH_TABS,
} from '../../../../../../workspace/toolbar/trash/store/trashConstants';

// Types
import { AnyAction } from 'redux';
import { AsyncResourceType } from '../asyncResourceReducerTypes';
import { AsyncResourceFetchedAction } from '../../asyncResourceActionsTypes';

const handleResourcesFetched = (state: AsyncResourceType, action: AsyncResourceFetchedAction): AsyncResourceType => {
    if (action.resource !== ResourceTypes.trash) return state;

    const updatedState = {
        ...state,
    };

    const ids = action.ids || [];

    for (const id of ids) {
        updatedState[id] = {
            ...updatedState[id],
            meta: {
                ...updatedState[id]?.meta,
                hasOlderTrash: action.data.hasOlderTrash ? true : updatedState[id]?.meta?.hasOlderTrash,
                hasMore: action.data.hasMore,
                earliestTimestamp: action.data.earliestTimestamp,
            },
        };
    }

    return updatedState;
};

const handlePersonalTabClear = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => ({
    ...state,
    [TRASH_TABS.PERSONAL]: {
        ...state[TRASH_TABS.PERSONAL],
        meta: {
            ...state[TRASH_TABS.PERSONAL].meta,
            clearing: true,
        },
    },
});

const handlePersonalTabClearComplete = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => ({
    ...state,
    [TRASH_TABS.PERSONAL]: {
        ...state[TRASH_TABS.PERSONAL],
        meta: {
            ...state[TRASH_TABS.PERSONAL].meta,
            clearing: false,
            fetched: false,
            hasMore: false,
        },
    },
});

const handlePersonalTabClearFailed = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => ({
    ...state,
    [TRASH_TABS.PERSONAL]: {
        ...state[TRASH_TABS.PERSONAL],
        meta: {
            ...state[TRASH_TABS.PERSONAL].meta,
            clearing: false,
        },
    },
});

const initialState: AsyncResourceType = {};

/**
 * Handles all the fetching state for the trash.
 */
export default (state = { ...initialState }, action: AnyAction): AsyncResourceType => {
    switch (action.type) {
        case RESOURCES_FETCHED:
            return handleResourcesFetched(state, action as AsyncResourceFetchedAction);
        case TRASH_CLEAR_START:
            return handlePersonalTabClear(state, action);
        case TRASH_CLEAR_COMPLETE:
            return handlePersonalTabClearComplete(state, action);
        case TRASH_CLEAR_FAILED:
            return handlePersonalTabClearFailed(state, action);
        default:
            return state;
    }
};
