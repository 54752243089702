// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import hyperlinkOpenPopupDecorator from '../plugins/hyperlink/hyperlinkOpenPopupDecorator';
import MilanoteMentionsEditor from '../plugins/mentions/MilanoteMentionsEditor';

// Plugins
import { createLinkPlugin, createLinkifyPlugin } from '../plugins/link';
import createHighlightPlugin from '../plugins/textColor';
import createMilanoteShortcutsPlugin from '../plugins/milanoteShortcuts';
import createDeleteOnEmptyPlugin from '../plugins/deleteOnEmpty';
import createEditingHelperPlugin from '../plugins/editingHelper';
import createSearchHighlightPlugin from '../plugins/searchHighlight';
import { createColorConverterPlugin } from '../plugins/universalElement';
import createMarkdownPlugin from '../plugins/markdownSupport';
import createStyleShorcutsPlugin from '../plugins/styleShortcuts';
import createTabReplacementPlugin from '../plugins/tabReplacementPlugin';

// Styles
import './SimpleTextEditor.scss';

const linkifyPlugin = createLinkifyPlugin({});
const linkPlugin = createLinkPlugin({});
const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
const milanoteShortcutsPlugin = createMilanoteShortcutsPlugin();
const markdownPlugin = createMarkdownPlugin({ enableBlockStyles: false });
const styleShortcutsPlugin = createStyleShorcutsPlugin({ enableBlockStyles: false });
const editingHelperPlugin = createEditingHelperPlugin();
const searchHighlightPlugin = createSearchHighlightPlugin();
const highlightPlugin = createHighlightPlugin();
const colorConverterPlugin = createColorConverterPlugin();
const tabReplacementPlugin = createTabReplacementPlugin();

const plugins = [
    searchHighlightPlugin,
    highlightPlugin,
    linkPlugin,
    linkifyPlugin,
    milanoteShortcutsPlugin,
    deleteOnEmptyPlugin,
    markdownPlugin,
    colorConverterPlugin,
    styleShortcutsPlugin,
    // Ensure this is last as it uses a handleReturn helper to remove inline styles by default
    editingHelperPlugin,
    tabReplacementPlugin,
];

const SimpleTextEditor = (props) => (
    <MilanoteMentionsEditor {...props} plugins={plugins} className={classNames('SimpleTextEditor', props.className)} />
);

SimpleTextEditor.propTypes = {
    className: PropTypes.string,
};

export default hyperlinkOpenPopupDecorator(SimpleTextEditor);
