// Lib
import { useEffect, useState } from 'react';
import { pure } from '../../../node_module_clones/recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Config
import getClientConfig from '../../utils/getClientConfig';

// Utils
import { enableAmplitudeTracking, initialiseAmplitudeData } from '../../analytics/amplitudeService';
import { addCookieBannerPageOverlay, cookieGroupAllowed, removeCookieBannerPageOverlay } from './oneTrustUtils';
import { getCurrentUserId, getCurrentUserOTToken, isGuestSelector } from '../../user/currentUserSelector';
import useOneTrustLoader from './useOneTrustLoader';
import { hideOneTrustBanner, setOneTrustConsentModel, setOneTrustStatus, showOneTrustBanner } from './oneTrustActions';
import { activeModalIdSelector } from '../../components/modal/activeModalSelector';
import usePrevious from '../../utils/react/usePrevious';
import useThrottledCallback from '../../utils/react/useThrottledCallback';
import { getShowOneTrustBanner } from './oneTrustSelectors';
import useUpdateEffect from '../../utils/react/useUpdateEffect';

// Constants
import { COOKIE_GROUPS, ONE_TRUST_CONSENT_MODEL, ONE_TRUST_STATUS } from './oneTrustConstants';

const mapStateToProps = createStructuredSelector({
    isGuest: isGuestSelector,
    userId: getCurrentUserId,
    userOTToken: getCurrentUserOTToken,
    activeModalId: activeModalIdSelector,
    showOneTrustBanner: getShowOneTrustBanner,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetOneTrustStatus: (status) => dispatch(setOneTrustStatus(status)),
    dispatchSetOneTrustConsentModel: (consentModel) => dispatch(setOneTrustConsentModel(consentModel)),
    dispatchShowOneTrustBanner: () => dispatch(showOneTrustBanner()),
    dispatchHideOneTrustBanner: () => dispatch(hideOneTrustBanner()),
});

const OneTrustInit = pure((props) => {
    const {
        isGuest,
        userId,
        userOTToken,
        activeModalId,
        showOneTrustBanner,
        dispatchSetOneTrustStatus,
        dispatchSetOneTrustConsentModel,
        dispatchShowOneTrustBanner,
        dispatchHideOneTrustBanner,
    } = props;

    const config = getClientConfig();

    const [oneTrustState] = useOneTrustLoader({ isGuest, userId, userOTToken });
    const [performanceCookiesAllowed, setPerformanceCookiesAllowed] = useState(() =>
        cookieGroupAllowed(COOKIE_GROUPS.PERFORMANCE_COOKIES),
    );

    const prevShowOneTrustBanner = usePrevious(showOneTrustBanner);

    /**
     * This function determines whether or not to show the OneTrust banner.
     *
     * Will show banner when user that has not given consent:
     * - is in a geographical location that OPTS IN of cookies by default (e.g. US), and on non modal pages in the app
     * - is in a geographical location that OPTS OUT of cookies by default (e.g. EU), and on any page in the app,
     *   including log in page.
     *   - NOTE: This is because in this situation, we'd want to get user's consent before they log in, in order for us
     *     to be able to track their actions as soon as possible
     */
    const checkShouldShowOneTrustBanner = useThrottledCallback(
        () => {
            if (oneTrustState !== ONE_TRUST_STATUS.INITIALISED) return;

            // For now, use consent model to determine whether consent banner needs to be displayed.
            // This might change in the future, e.g. CCPA banner would need to be shown even when its consent is opt-out.
            const consentModel = window.OneTrust.GetDomainData()?.ConsentModel?.Name;
            const isOptInConsentModel = consentModel === ONE_TRUST_CONSENT_MODEL.OPT_IN;

            const shouldShowOneTrustBanner =
                window.OneTrust.IsAlertBoxClosed() === false &&
                (isOptInConsentModel || (!isOptInConsentModel && activeModalId === null));

            if (shouldShowOneTrustBanner) {
                dispatchShowOneTrustBanner();
            } else {
                dispatchHideOneTrustBanner();
            }
        },
        50,
        [oneTrustState, activeModalId, dispatchShowOneTrustBanner, dispatchHideOneTrustBanner],
    );

    /**
     * When OneTrust is initialised, also initialise some Redux state, and determine whether or not
     * to show the cookie banner.
     */
    useEffect(() => {
        dispatchSetOneTrustStatus(oneTrustState);

        if (oneTrustState !== ONE_TRUST_STATUS.INITIALISED) return;

        const consentModel = window.OneTrust.GetDomainData()?.ConsentModel?.Name;
        dispatchSetOneTrustConsentModel(consentModel);

        checkShouldShowOneTrustBanner();
        setPerformanceCookiesAllowed(cookieGroupAllowed(COOKIE_GROUPS.PERFORMANCE_COOKIES));

        // eslint-disable-next-line new-cap
        window.OneTrust.OnConsentChanged(() => {
            checkShouldShowOneTrustBanner();
            setPerformanceCookiesAllowed(cookieGroupAllowed(COOKIE_GROUPS.PERFORMANCE_COOKIES));
        });
    }, [oneTrustState]);

    /**
     * When changing between non-modal and modal pages, check whether or not to show the OneTrust banner.
     * This is because in some geographical location, the banner is shown on non-modal pages, but not on modal pages.
     */
    useUpdateEffect(() => {
        if (showOneTrustBanner) {
            checkShouldShowOneTrustBanner();
        } else {
            // We use a timeout here to wait for the consent information to be fully synced before checking
            // whether to SHOW the banner.
            setTimeout(() => checkShouldShowOneTrustBanner(), 3000);
        }
    }, [activeModalId]);

    /**
     * When showOneTrustBanner value change, add/remove class to body to show/hide the cookie banner
     */
    useUpdateEffect(() => {
        if (!prevShowOneTrustBanner && showOneTrustBanner) {
            document.body.classList.add('show-cookie-banner');

            const consentModel = window.OneTrust?.GetDomainData()?.ConsentModel?.Name;
            const isOptInConsentModel = consentModel === ONE_TRUST_CONSENT_MODEL.OPT_IN;

            if (isOptInConsentModel) addCookieBannerPageOverlay();
        }
        if (prevShowOneTrustBanner && !showOneTrustBanner) {
            document.body.classList.remove('show-cookie-banner');
            removeCookieBannerPageOverlay();
        }
    }, [showOneTrustBanner]);

    /**
     * When performanceCookiesAllowed value change, determine whether or not to enable/disable analytics
     */
    useEffect(() => {
        if (performanceCookiesAllowed === null) return;

        enableAmplitudeTracking(performanceCookiesAllowed);

        if (performanceCookiesAllowed) {
            initialiseAmplitudeData(config);
        }
    }, [performanceCookiesAllowed]);

    return null;
});

export default connect(mapStateToProps, mapDispatchToProps)(OneTrustInit);
