// Lib
import { createSelector } from 'reselect';

// Utils
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import {
    getAsyncResourceEntityState,
    getAsyncResourcesTypeState,
} from '../../utils/services/http/asyncResource/asyncResourceSelector';
import { prop } from '../../../common/utils/immutableHelper';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import {
    getAsyncEntityError,
    isAsyncEntityError,
    isAsyncEntityFetched,
    isAsyncEntityFetching,
    isAsyncEntityCached,
} from '../../utils/services/http/asyncResource/asyncResourceUtils';

import { getCurrentVisibleBoardId, getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';

// Constants
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';

export const currentBoardIdSelector = (state, props) => getCurrentVisibleBoardId(state, props) || props?.currentBoardId;

const getElementIdFromElement = (state, ownProps) => getPhysicalId(ownProps.element);

// RESOURCES
export const boardResourcesSelector = getAsyncResourcesTypeState(ResourceTypes.boards);

/**
 * Counts the number of board resources that are in the fetched state.
 */
export const getFetchedBoardCount = createSelector(boardResourcesSelector, (boardResourcesState) => {
    let count = 0;
    for (const key in boardResourcesState) {
        if (boardResourcesState[key].fetched) {
            count++;
        }
    }
    return count;
});

// Current / current visible board resource state
export const getCurrentVisibleBoardResourceState = (state, props) =>
    getAsyncResourceEntityState(state, ResourceTypes.boards, currentBoardIdSelector(state, props));
export const getCurrentBoardResourceState = (state, props) =>
    getAsyncResourceEntityState(state, ResourceTypes.boards, getCurrentBoardId(state, props));

// Current visible board resource state getters
export const getIsCurrentVisibleBoardFetching = (state, props) =>
    isAsyncEntityFetching(getCurrentVisibleBoardResourceState(state, props));
export const getIsCurrentVisibleBoardFetched = (state, props) =>
    isAsyncEntityFetched(getCurrentVisibleBoardResourceState(state, props));
export const getIsCurrentVisibleBoardCached = (state, props) =>
    isAsyncEntityCached(getCurrentVisibleBoardResourceState(state, props));
export const getIsCurrentVisibleBoardError = (state, props) =>
    isAsyncEntityFetched(getCurrentVisibleBoardResourceState(state, props));
export const getCurrentVisibleBoardError = (state, props) =>
    prop('error', getCurrentVisibleBoardResourceState(state, props));

export const getFetchedBoardIds = createShallowSelector(boardResourcesSelector, (boardResourcesState) =>
    Object.values(boardResourcesState).filter(isAsyncEntityFetched).map(prop('id')),
);

export const getFailedBoardIds = createShallowSelector(boardResourcesSelector, (boardResourcesState) =>
    Object.values(boardResourcesState).filter(isAsyncEntityError).map(prop('id')),
);

export const getBoardResourceEntityState = (state, ownProps) =>
    getAsyncResourceEntityState(state, ResourceTypes.boards, getElementIdFromElement(state, ownProps));

export const getIsBoardFetched = (state, ownProps) =>
    isAsyncEntityFetched(getBoardResourceEntityState(state, ownProps));
export const getIsBoardFetching = (state, ownProps) =>
    isAsyncEntityFetching(getBoardResourceEntityState(state, ownProps));
export const getIsBoardFetchError = (state, ownProps) =>
    isAsyncEntityError(getBoardResourceEntityState(state, ownProps));

export const getBoardFetchError = (state, ownProps) =>
    getAsyncEntityError(getBoardResourceEntityState(state, ownProps));

// STATE
export const getCurrentBoard = (state, ownProps) => state.getIn(['elements', currentBoardIdSelector(state, ownProps)]);

export const getCurrentBoardInboxState = (state, ownProps) => {
    const currentBoard = getCurrentBoard(state, ownProps);
    return currentBoard && currentBoard.getIn(['content', 'inbox']);
};

export const getIsCurrentBoardRoot = (state, ownProps) => {
    const currentBoard = getCurrentBoard(state, ownProps);
    return currentBoard && !!currentBoard.getIn(['location', 'rootBoard']);
};
