// Constants
import {
    EDITOR_STATE_CHANGED,
    EDITOR_STYLE_COMMAND,
    EDITOR_COLOR_STYLE_COMMAND,
    EDITOR_ENTITY_CHANGE,
    EDITOR_ENTITY_REMOVE,
    EDITOR_UNMOUNT,
    EDITOR_SELECTION_SAVE,
    EDITOR_CLEAR_UNDO_REDO_STACK,
    EDITOR_UPDATE_BLOCK_METADATA,
} from './editorConstants';

export const editorStateChanged = ({ editorId, originalEditorId, editorState }) => ({
    type: EDITOR_STATE_CHANGED,
    editorId,
    originalEditorId,
    editorState,
});

export const editorStyleChange = ({ styleCommand, selection }) => ({
    type: EDITOR_STYLE_COMMAND,
    styleCommand,
    selection,
});

export const editorColorStyleChange = ({ addedStyles = [], removedStyles = [], addToUndoStack }) => ({
    type: EDITOR_COLOR_STYLE_COMMAND,
    addedStyles,
    removedStyles,
    addToUndoStack,
});

export const editorUpdateBlockMetadata = (metadata) => ({
    type: EDITOR_UPDATE_BLOCK_METADATA,
    metadata,
});

export const editorEntityChange = ({ entityType, mutability, data, forceSelection = true, mergeData = false }) => ({
    type: EDITOR_ENTITY_CHANGE,
    entityType,
    mutability,
    data,
    forceSelection,
    mergeData,
});

export const clearEditorUndoRedoStack = () => ({ type: EDITOR_CLEAR_UNDO_REDO_STACK });

export const editorEntityRemove = () => ({ type: EDITOR_ENTITY_REMOVE });

export const editorSelectionSave = ({ editorId, selection }) => ({
    type: EDITOR_SELECTION_SAVE,
    editorId,
    selection,
});

export const editorUnmount = () => ({ type: EDITOR_UNMOUNT });
