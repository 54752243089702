// Lib
import { isNumber, isUndefined } from 'lodash';

// Utils
import getClientConfig from '../utils/getClientConfig';

// Constants
import { LoggerLevel } from './loggerConstants';
import { DebugComponent } from '../debug/debugConstants';
import { getDebugValue, isGlobalDebugEnabled } from '../debug/debugUtil';

const DEFAULT_LOG_LEVEL = getClientConfig().debugLevel || LoggerLevel.ERROR;

/**
 * Converts a value from the debug config to a log level, if it's not already a number.
 */
const getLogLevelFromValue = (value: unknown): LoggerLevel => {
    if (isNumber(value)) return value as LoggerLevel;

    return value ? LoggerLevel.FINEST : LoggerLevel.ERROR;
};

/**
 * The log level that all loggers will inherit from if they or their parents are not specifically set.
 */
export const getGlobalLogLevel = (): LoggerLevel => {
    const logLevel = getDebugValue(DebugComponent.LOGS);

    if (!isUndefined(logLevel)) return getLogLevelFromValue(logLevel);

    const isDebugEnabled = isGlobalDebugEnabled();

    if (!isUndefined(isDebugEnabled)) return getLogLevelFromValue(isDebugEnabled);

    return DEFAULT_LOG_LEVEL;
};

/**
 * Gets the log level for the given channel.
 */
export const getChannelLogLevel = (channel: string): LoggerLevel => {
    const debugLevel = getDebugValue(`${DebugComponent.LOGS}.${channel}`);

    if (isUndefined(debugLevel)) return getGlobalLogLevel();

    return getLogLevelFromValue(debugLevel);
};

export const isLoggerDebugEnabled = (channel: string): boolean => getChannelLogLevel(channel) > LoggerLevel.WARN;
