// Lib
import { isEmpty } from 'lodash/fp';

// Utils
import { getIsPageVisible } from '../utils/dom/pageVisibilityUtils';
import { getNotificationElementId, getNotificationId } from '../../common/notifications/notificationModelUtils';

// Selectors
import { getCurrentBoardId } from '../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentUserId, isGuestSelector } from '../user/currentUserSelector';
import { getNotificationsSelector } from './notificationsSelector';

// Actions
import { removeNotifications } from '../../common/notifications/notificationActions';

// Constants
import { NOTIFICATION_TYPES, NOTIFICATIONS_ADD_OR_UPDATE } from '../../common/notifications/notificationConstants';
import { ResourceTypes, RESOURCES_FETCHED } from '../utils/services/http/asyncResource/asyncResourceConstants';

// Want to filter out new comments, board updates, board task updates or board views
const FILTERABLE_NOTIFICATION_TYPES = [
    NOTIFICATION_TYPES.COMMENT,
    NOTIFICATION_TYPES.BOARD_VIEW,
    NOTIFICATION_TYPES.BOARD_UPDATE,
    NOTIFICATION_TYPES.BOARD_TASKS_UPDATE,
];

const removeCurrentlyViewedNotifications = (store, action) => {
    if (!action) return action;

    const { notifications } = action;

    if (!notifications) return action;

    const state = store.getState();
    const isGuest = isGuestSelector(state);

    if (isGuest) return action;

    const currentBoardId = getCurrentBoardId(state);
    const currentUserId = getCurrentUserId(state);

    const persistedNotifications = getNotificationsSelector(state);

    const { notificationsToDelete, notificationsToPersist } = notifications.reduce(
        (acc, notification) => {
            const { userId, type } = notification;

            // The user should only ever receive notifications meant for them, but if they somehow receive somebody
            // else's notifications we don't want to go and delete them
            if (currentUserId !== userId) return acc;

            const elementId = getNotificationElementId(notification);
            const notificationId = getNotificationId(notification);

            const alreadyHaveNotification = persistedNotifications.has(notificationId);

            const shouldRemoveNotifications =
                !alreadyHaveNotification &&
                elementId === currentBoardId &&
                FILTERABLE_NOTIFICATION_TYPES.includes(type);

            shouldRemoveNotifications
                ? acc.notificationsToDelete.push(notification)
                : acc.notificationsToPersist.push(notification);

            return acc;
        },
        { notificationsToDelete: [], notificationsToPersist: [] },
    );

    if (!isEmpty(notificationsToDelete)) {
        store.dispatch(
            removeNotifications({
                userId: currentUserId,
                notificationIds: notificationsToDelete.map(getNotificationId),
            }),
        );
    }

    return {
        ...action,
        notifications: notificationsToPersist,
    };
};

const updateFetchedNotificationAction = (store, action) => {
    if (action.resource !== ResourceTypes.notifications) return action;

    if (action.data?.after) {
        action.data = removeCurrentlyViewedNotifications(store, action.data);
    }

    // If the action is not fetching notifications after a time then they will not be new notifications so we shouldn't
    // filter out any notifications in the currently viewed board
    return action;
};

const updateAction = (store, action) => {
    switch (action.type) {
        case RESOURCES_FETCHED:
            return updateFetchedNotificationAction(store, action);
        case NOTIFICATIONS_ADD_OR_UPDATE:
            return removeCurrentlyViewedNotifications(store, action);
        default:
            return action;
    }
};

/**
 * This middleware deletes any new notifications for boards that are currently being viewed.
 */
export default (store) => (next) => (action) => {
    // If the tab is not currently visible then we can't be currently viewing a board, so just pass it through
    if (!getIsPageVisible()) return next(action);

    const updatedAction = updateAction(store, action);

    if (!updatedAction) return;

    return next(updatedAction);
};
