export const TOOL_HEIGHT = {
    SQUARE: {
        large: 48,
        medium: 48,
        small: 43,
    },
    SHORT: {
        large: 38,
        medium: 38,
        small: 35,
    },
} as const;

/**
 * The order in which tools will collapse when the toolbar is resized.
 * The higher the number, the earlier the tool will collapse.
 * Any number can be used, but the following constants are provided for convenience.
 */
export const COLLAPSE_ORDER = {
    NEVER: 0,
    LATE: 10,
    DEFAULT: 100,
    EARLY: 1000,
    ALWAYS: 10000,
} as const;
