import * as Immutable from 'immutable';
import { isEmpty } from 'lodash/fp';

// Constants
import { RESOURCES_FETCHED, ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { AnyAction } from 'redux';
import { NavigationHistoryEntry } from '../../../common/api/users/me/currentUserRoutesTypes';
import { USER_NAVIGATE } from '../userConstants';

export type NavigationHistoryState = Immutable.Map<string, number>;

const initialState: NavigationHistoryState = Immutable.Map();

const updateViewedBoardsOnNavigate = (state: NavigationHistoryState, action: AnyAction) => {
    const { newBoardId, previousBoardId, timestamp } = action;

    let updatedState = state;

    if (newBoardId) {
        updatedState = updatedState.set(newBoardId, timestamp);
    }

    if (previousBoardId) {
        // Subtract 1 so that the previous board's timestamp will be less than the new board's
        updatedState = updatedState.set(previousBoardId, timestamp - 1);
    }

    return updatedState;
};

const updateHistory = (state: NavigationHistoryState, action: AnyAction) => {
    const { resource, data } = action;
    if (resource !== ResourceTypes.history) return state;

    const historyEntries: NavigationHistoryEntry[] = data?.history;
    if (!historyEntries || isEmpty(historyEntries)) return state;

    return state.withMutations((mutableState) => {
        historyEntries.forEach((entry) => {
            mutableState.set(entry.elementId, entry.lastView);
        });
    });
};

const navigationHistoryReducer = (state = initialState, action: AnyAction): NavigationHistoryState => {
    switch (action.type) {
        case USER_NAVIGATE:
            return updateViewedBoardsOnNavigate(state, action);
        case RESOURCES_FETCHED:
            return updateHistory(state, action);
        default:
            return state;
    }
};

export default navigationHistoryReducer;
