// Utils
import { registerElementType } from '../../elements/elementRegistry';
import elementConstructor from '../../elements/elementConstructor';

// Constants
import { SKETCH_DISPLAY_NAME, SKETCH_DEFAULT_WIDTH, SKETCH_DISPLAY_NAME_PLURAL } from './sketchConstants';

import { SKETCH_BACKGROUND_COLORS } from '../../colors/colorConstants';
import { ElementType } from '../../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.SKETCH_TYPE,
        content: {
            background: {
                color: SKETCH_BACKGROUND_COLORS.WHITE.name,
            },
            width: SKETCH_DEFAULT_WIDTH,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.SKETCH_TYPE,
        objectConstructorFn,
        displayName: SKETCH_DISPLAY_NAME,
        displayNamePlural: SKETCH_DISPLAY_NAME_PLURAL,
        ...params,
    });
