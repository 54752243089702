// Utils
import { prop, propIn } from '../../../../../common/utils/immutableHelper';

/**
 * Scales a single dimension.
 */
const transformPropertyInPlace = (measurementObj, property, translation, scale) => {
    const val = measurementObj[property];

    // eslint-disable-next-line eqeqeq
    if (val == undefined) return;

    measurementObj[property] = Math.round((val + translation) * scale);
};

/**
 * Shifts Rectangle { x, y, top, left, right, bottom, width, height } of *un*scaled values into scaled values
 * based on the current zoomState.
 */
export const transformMeasurementsInPlace = (unscaledMeasurementsObj, zoomState) => {
    const xTranslation = propIn(['translation', 'x'], zoomState);
    const yTranslation = propIn(['translation', 'y'], zoomState);
    const scale = prop('scale', zoomState);

    transformPropertyInPlace(unscaledMeasurementsObj, 'x', xTranslation, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'y', yTranslation, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'left', xTranslation, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'top', yTranslation, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'right', xTranslation, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'bottom', yTranslation, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'width', 0, scale);
    transformPropertyInPlace(unscaledMeasurementsObj, 'height', 0, scale);

    return unscaledMeasurementsObj;
};

/**
 * Shifts Rectangle { x, y, top, left, right, bottom, width, height } of scaled values back to its unscaled values
 * based on the current zoomState.
 *
 * I.e. The opposite of transformMeasurementsInPlace.
 */
export const unTransformMeasurementsInPlace = (scaledMeasurementsObj, zoomState) => {
    const xTranslation = -propIn(['translation', 'x'], zoomState);
    const yTranslation = -propIn(['translation', 'y'], zoomState);
    const scale = 1 / prop('scale', zoomState);

    return transformMeasurementsInPlace(scaledMeasurementsObj, {
        scale,
        translation: { x: xTranslation, y: yTranslation },
    });
};
