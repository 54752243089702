export const IMAGE_MODAL_FOOTER_SIZE = 80;

export const IMAGE_MODAL_MODES = {
    VIEW: 'VIEW',
    UPLOAD: 'UPLOAD',
    DRAW: 'DRAW',
    CROP: 'CROP',
};

export const IMAGE_MODAL_SECONDARY_TOOLBAR_ID = 'image-modal-toolbar-secondary-tools';
