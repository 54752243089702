import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CrossSmallIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon cross-small"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={10} cy={10} r={10} fill="#E60B0B" />
            <g fill="#FFF">
                <path d="m13.293 5.293-8 8 1.414 1.414 8-8z" />
                <path d="m13.293 14.707 1.414-1.414-8-8-1.414 1.414z" />
            </g>
        </g>
    </svg>
);
const Memo = memo(CrossSmallIcon);
export default Memo;
