import { Selection } from 'handsontable/plugins/contextMenu/contextMenu';
import { CellSelections } from '../../../../common/table/TableTypes';
import { getAllCellsBetween } from './tableCellSelectionUtils';
import hyperFormulaInstance from '../manager/hyperFormulaInstance';
import Handsontable from 'handsontable/base';

export function debugTableInstance(this: Handsontable.Core): void {
    console.info(this);
}

export const debugTableCell = (elementId: string) => {
    return function (this: Handsontable.Core, key: string, selection: Selection[], clickEvent: MouseEvent): void {
        const cellSelections: CellSelections = selection.map(({ start, end }) => [
            start.row,
            start.col,
            end.row,
            end.col,
        ]);

        getAllCellsBetween(cellSelections).forEach(({ row, col }) => {
            const sheet = hyperFormulaInstance.getSheetId(elementId);

            const formulaInfo =
                sheet === undefined
                    ? {}
                    : {
                          hfCellValue: hyperFormulaInstance.getCellValue({ sheet, row, col }),
                          hfCellType: hyperFormulaInstance.getCellType({ sheet, row, col }),
                          hfCellValueType: hyperFormulaInstance.getCellValueType({ sheet, row, col }),
                          hfCellValueDetailedType: hyperFormulaInstance.getCellValueDetailedType({ sheet, row, col }),
                      };

            console.info({
                row,
                col,
                cellData: this.getCellMeta(row, col).cellData,
                ...formulaInfo,
            });
        });
    };
};
