// Utils
import getSelectionRange from '../selection/getSelectionRange';
import getClosestEntityRange from './getClosestEntityRange';

/**
 * Returns the closest entity range to the current selection given the specified entity key.
 * Range is of the form: { startKey, startOffset, endKey, endOffset }
 *
 * NOTE: This algorithm isn't really ideal as it finds all the entities based on the specified entity key and chooses
 *  one that overlaps the current selection if it exists, otherwise it just chooses the first one it finds.
 *
 *  It would also be better if it found the correct entity key itself.
 */
export default (editorState, entityKey) => {
    const selection = editorState.getSelection();
    const selectionRange = getSelectionRange(selection);

    return getClosestEntityRange(editorState, selectionRange, entityKey);
};
