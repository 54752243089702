import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MilanoteLogoMediumIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={54}
        height={54}
        viewBox="0 0 54 54"
        className="Icon milanote-logo-medium"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#31303A" d="M27 0C12.088 0 0 12.088 0 27s12.088 27 27 27 27-12.088 27-27S41.912 0 27 0" />
            <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="m16.5 37.5 21-21v21h-21v-21l21 21"
            />
        </g>
    </svg>
);
const Memo = memo(MilanoteLogoMediumIcon);
export default Memo;
