// Handlers
import hyperlinkStategy from './hyperlinkStategy';
import createHyperlinkAnchor from './createHyperlinkAnchor';
import keyBindingFn from './keyBindingFn';
import handleKeyCommand from './handleKeyCommand';
import handlePastedText from './handlePastedText';

/**
 * This component allows inline links to be created (i.e.
 */
export default ({ clickableLinks } = {}) => ({
    decorators: [
        {
            strategy: hyperlinkStategy,
            component: createHyperlinkAnchor({ clickableLinks }),
        },
    ],
    keyBindingFn,
    handleKeyCommand,
    handlePastedText,
});
