import { convertKeyToEnvironmentSymbol } from '../../../workspace/shortcuts/modal/ShortcutKey';

export const getShortcutHtml = (shortcuts: Array<string>) => {
    let shortcutHtml = '';
    shortcuts.forEach((shortcut) => {
        const symbol = convertKeyToEnvironmentSymbol(shortcut);
        shortcutHtml += `<span class="shortcut-key ${symbol.length > 1 ? `shortcut-long` : ''}">${symbol}</span>`;
    });
    return `<span class="shortcut-key-group">${shortcutHtml}</span>`;
};
