import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getEmojiDataForCode, getSkinToneCodes } from './reactionPopupUtil';

import { SKIN_TONE_EMOJI_CODE } from './reactionPopupConstants';

import Button from '../../buttons/Button';
import PopupPanelMouseManager from '../../popupPanel/PopupPanelMouseManager';
import ReactionPopupEmojiButton from './ReactionPopupEmojiButton';

import './ReactionPopupSkinTonePicker.scss';

const ReactionPopupSkinTonePicker = ({ emojiData, skinTone, setSkinTone, gridSize }) => {
    const [isSelectingTone, setIsSelectingTone] = useState(false);

    const skinToneEmoji = useMemo(() => getEmojiDataForCode(emojiData, SKIN_TONE_EMOJI_CODE), [emojiData]);

    const selectSkinTone = (skinToneCode) => {
        setIsSelectingTone(!isSelectingTone);
        setSkinTone(skinToneCode);
    };

    const skinToneCodes = useMemo(() => getSkinToneCodes(emojiData), [emojiData]);

    if (!skinToneEmoji) return null;

    return (
        <PopupPanelMouseManager
            closePopup={() => setIsSelectingTone(false)}
            visible={isSelectingTone}
            ignoreClickContainment
        >
            <div className={classNames('ReactionPopupSkinTonePicker', { closed: !isSelectingTone })}>
                <ul className="tone-list">
                    {[null, ...skinToneCodes].map((skinToneCode, index) => (
                        <li
                            key={skinToneCode}
                            style={{
                                transform: `translate(${isSelectingTone ? index * gridSize * 3.6 : 0}px, 0px)`,
                                opacity: isSelectingTone || skinTone === skinToneCode ? 1 : 0,
                            }}
                        >
                            <ReactionPopupEmojiButton
                                onClickFn={() => selectSkinTone(skinToneCode)}
                                emoji={skinToneEmoji}
                                skinTone={skinToneCode}
                            />
                        </li>
                    ))}
                </ul>

                <Button
                    className={classNames('select-tone-button', { hidden: isSelectingTone })}
                    onClickFn={() => setIsSelectingTone(true)}
                >
                    Change skin tone
                </Button>
            </div>
        </PopupPanelMouseManager>
    );
};

ReactionPopupSkinTonePicker.propTypes = {
    skinTone: PropTypes.string,
    gridSize: PropTypes.number,
    emojiData: PropTypes.array,
    setSkinTone: PropTypes.func,
};

export default ReactionPopupSkinTonePicker;
