// Utils
import { prop } from '../../../common/utils/immutableHelper';

// Constants
import { SOCKET_CONNECTION_STATUS } from './socketConstants';

export { isSocketConnectionInitialised } from '../../app/initialisation/initialisationSelector';
export const getSocketConnection = (state) => state.getIn(['app', 'socketConnection']);
export const getSocketInterruptionTime = (state) => state.getIn(['app', 'socketConnection', 'interruptionTime']);
export const getSocketDisconnectionTime = (state) => state.getIn(['app', 'socketConnection', 'disconnectTime']);
export const getSocketConnectionStatus = (state) => state.getIn(['app', 'socketConnection', 'status']);
export const getSocketReconnectionMode = (state) => state.getIn(['app', 'socketConnection', 'reconnectionMode']);

export const socketConnectedSelector = (state) =>
    getSocketConnectionStatus(state) === SOCKET_CONNECTION_STATUS.CONNECTED;

export const socketDisconnectedSelector = (state) =>
    getSocketConnectionStatus(state) === SOCKET_CONNECTION_STATUS.DISCONNECTED;

// Property selectors
export const isSocketConnected = (socketConnection) =>
    prop('status', socketConnection) === SOCKET_CONNECTION_STATUS.CONNECTED;

export const isSocketDisconnected = (socketConnection) =>
    prop('status', socketConnection) === SOCKET_CONNECTION_STATUS.DISCONNECTED;
