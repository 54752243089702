// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FeatureSuggestion from '../FeatureSuggestion';

// Utils
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';

// Actions
import { startEditingElement } from '../../selection/selectionActions';

// Constants
import { SHOW_TITLE_PROPERTY, TITLE_SUGGESTION_ACK_PROPERTY } from '../../../../common/taskLists/taskListConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchStartEditingElement: ({ element }) =>
        dispatch(
            startEditingElement({
                id: getElementId(element),
                editorId: getMainEditorId({ element }),
                editorKey: getMainEditorKey({ element }),
            }),
        ),
});

@connect(null, mapDispatchToProps)
class TitleSuggestion extends React.Component {
    onConfirm = () => {
        const { dispatchStartEditingElement, element, hideInstantly } = this.props;

        hideInstantly();
        this.onAcknowledgement(true);
        dispatchStartEditingElement({ element });
    };

    onDismiss = () => this.onAcknowledgement(false);

    onAcknowledgement = (decision) => {
        const { dispatchUpdateElement, element } = this.props;

        dispatchUpdateElement({
            id: getElementId(element),
            changes: {
                [SHOW_TITLE_PROPERTY]: decision,
                [TITLE_SUGGESTION_ACK_PROPERTY]: true,
            },
        });
    };

    render() {
        const { question, added } = this.props;

        return (
            <FeatureSuggestion
                {...this.props}
                question={question}
                onConfirm={this.onConfirm}
                onDismiss={this.onDismiss}
                added={added}
            />
        );
    }
}

TitleSuggestion.propTypes = {
    element: PropTypes.object.isRequired,
    feature: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    added: PropTypes.bool,

    onConfirm: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    dispatchStartEditingElement: PropTypes.func,
    hideInstantly: PropTypes.func,
};

export default TitleSuggestion;
