/**
 * Find unserializable properties in an object.
 */
const findUnserializablePaths = (obj: unknown): string[] => {
    const unserializablePaths: string[] = [];

    const findUnserializablePathsRecursive = (obj: unknown, path: string) => {
        if (obj === null || obj === undefined) return;

        if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') return;

        if (Array.isArray(obj)) {
            obj.forEach((item, index) => {
                findUnserializablePathsRecursive(item, `${path}[${index}]`);
            });
            return;
        }

        if (typeof obj === 'object') {
            const typedObj = obj as { [key: string]: unknown };

            Object.keys(typedObj).forEach((key) => {
                findUnserializablePathsRecursive(typedObj[key], path ? `${path}.${key}` : key);
            });
            return;
        }

        unserializablePaths.push(path);
    };

    findUnserializablePathsRecursive(obj, '');

    return unserializablePaths;
};

export default findUnserializablePaths;
