// Lib
import Immutable from 'immutable';

// Utils
import globalLogger, { LoggerComponents } from '../../../logger';
import { getDebugSettings } from '../../../debug/debugUtil';
import { isFeatureEnabled } from '../../../../common/users/utils/userPropertyUtils';
import { buildPersistenceLayerActionMessage } from '../utils/persistenceLayerMessageBuilders';
import { buildClearElementsCacheOperation } from '../../../cache/utils/cacheOperationDataBuilder';

// Handlers
import persistenceLayerMiddlewareActionBuilder from './persistenceLayerMiddlewareActionBuilder';

// Purging
import elementPurgeMiddleware from './elementPurgeMiddleware';

// Web worker
import {
    getPersistenceLayerWorkerInstance,
    PersistenceLayerWorkerInstance,
} from '../worker/persistenceLayerWorkerInstance';

// Selectors
import { getCurrentUser } from '../../../user/currentUserSelector';
import { getCurrentBoardId } from '../../../element/selectors/currentBoardSelector';

// Actions
import { currentBoardIdSet } from '../../../reducers/currentBoardId/currentBoardIdActions';

// Analytics
import { NewRelicCustomAttributes, setNewRelicCustomAttribute } from '../../../analytics/newRelicUtils';

// Constants
import { CURRENT_USER_SET } from '../../../user/currentUserConstants';
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';
import { ELEMENTS_PURGE } from '../../../../common/elements/elementsConstants';
import { RESOURCES_FETCHED_FROM_CACHE } from '../../../utils/services/http/asyncResource/asyncResourceConstants';

// Types
import { AnyAction } from 'redux';
import { ReduxStore } from '../../../types/reduxTypes';
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';
import { PersistenceLayerMessageType } from '../utils/persistenceLayerCommunicationTypes';
import { ActionSource } from '../../../../common/actions/actionTypes';

const logger = globalLogger.createChannel(LoggerComponents.PERSISTENCE_LAYER_MIDDLEWARE);

const shouldHandleAction = (action: AnyAction) => {
    // We don't want to go around in circles, so ignore any actions sent from the worker
    if (action.source === ActionSource.WORKER) return false;

    // We don't want to handle the purging action here - this action is only for the main thread
    if (action.type === ELEMENTS_PURGE) return false;

    // If elements are fetched from the worker then we shouldn't send a cache update action back to the worker
    if (action.type === RESOURCES_FETCHED_FROM_CACHE) return false;

    // If any of the batch actions should not be handled, don't handle the batch action
    if (action.type === BATCH_ACTION_TYPE) return action.payload.every(shouldHandleAction);

    return true;
};

// TODO Write documentation for the persistence system
export default (store: ReduxStore) => (next: Function) => {
    let worker: PersistenceLayerWorkerInstance | null = null;
    const purgingMiddlewareActionHandler = elementPurgeMiddleware(store)(next);

    const initialiseWorker = () => {
        worker = getPersistenceLayerWorkerInstance();

        // Workers can't access local storage, so we need to initialise
        // it with the debug settings
        worker.postMessage({
            type: PersistenceLayerMessageType.INITIALISE_WORKER,
            payload: {
                debugSettings: getDebugSettings(),
            },
        });

        // NOTE: I'd prefer this to be in an isolated "selector worker" for better encapsulation, however
        //  this would suffer a performance penalty due to the need to serialize/deserialize the action
        //  another time. Thus doing it in the "persistence worker" means we only perform a single serialization
        worker.onMessage((event: MessageEvent) => {
            const { type, action } = event.data;

            if (type === PersistenceLayerMessageType.ACTION) {
                // Allow the middleware to recognise worker messages and not reprocess them
                action.source = ActionSource.WORKER;
                store.dispatch(action);
            }
        });
    };

    const state = store.getState();
    const initialCurrentUser = getCurrentUser(state);
    // If reloading the page and the user is already set, check if the feature is enabled
    let enablePersistenceMiddleware = isFeatureEnabled(ExperimentId.clientPersistence, initialCurrentUser);

    if (enablePersistenceMiddleware) initialiseWorker();

    /**
     * When the user data is getting set - determine whether the client persistence is enabled for the user
     * and if not then clear the indexedDB.
     */
    const checkFeatureFlag = (state: Immutable.Map<string, unknown>) => {
        const currentUser = getCurrentUser(state);
        const newEnablePersistenceMiddleware = isFeatureEnabled(ExperimentId.clientPersistence, currentUser);

        setNewRelicCustomAttribute(NewRelicCustomAttributes.CLIENT_PERSISTENCE_ENABLED, newEnablePersistenceMiddleware);

        // If it was enabled, but no longer is we should clear the indexedDB
        if (enablePersistenceMiddleware && !newEnablePersistenceMiddleware) {
            const clearMessage = buildClearElementsCacheOperation();
            worker?.postMessage(clearMessage);
        }

        if (!enablePersistenceMiddleware && newEnablePersistenceMiddleware) initialiseWorker();

        if (newEnablePersistenceMiddleware) {
            const currentBoardId = getCurrentBoardId(state);
            const currentBoardIdSetMessage = buildPersistenceLayerActionMessage(
                currentBoardIdSet({ boardId: currentBoardId, restored: false }),
            );
            worker?.postMessage(currentBoardIdSetMessage);
        }

        enablePersistenceMiddleware = newEnablePersistenceMiddleware;

        logger.info(
            `%c${enablePersistenceMiddleware ? 'Enabled' : 'Disabled'} for the current user`,
            enablePersistenceMiddleware ? 'color: green' : 'color: salmon',
        );
    };

    return (action: AnyAction) => {
        if (!shouldHandleAction(action)) return next(action);

        const preUpdateState = store.getState();
        const result = next(action);
        const postUpdateState = store.getState();

        if (action.type === CURRENT_USER_SET) {
            checkFeatureFlag(postUpdateState);
            return result;
        }

        if (!enablePersistenceMiddleware) return result;

        if (!worker) {
            logger.error(`Worker is not available, skipping action`, action.type);
            return result;
        }

        // This doesn't modify the action in any way - it simply listens for a
        // current board change and then determines whether elements need to be purged
        purgingMiddlewareActionHandler(action);

        const persistenceLayerMessage = persistenceLayerMiddlewareActionBuilder(
            action,
            preUpdateState,
            postUpdateState,
        );

        if (persistenceLayerMessage) {
            const perfStart = performance.now();
            worker.postMessage(persistenceLayerMessage);
            const perfEnd = performance.now();

            const perfTime = (perfEnd - perfStart).toFixed(2);

            logger.debug(`Post message took ${perfTime} ms.`, action.type);
        }

        return result;
    };
};
