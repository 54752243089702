// Lib
import { useCallback } from 'react';

// Utils
import { hasAltModifier, hasCommandModifier, hasShiftKey } from '../../../utils/keyboard/keyboardUtility';
import useDocumentKeyEventHandler from './drawingEditorCanvas/useDocumentKeyEventHandler';

// Constants
import { KEY_CODES } from '../../../utils/keyboard/keyConstants';
import { STROKE_SIZES, TOOL_MODES } from './drawingEditorConstants';

export default ({
    toolMode,
    undoPath,
    redoPath,
    cancelPath,
    setToolMode,
    setStrokeSize,
    switchToPreviousColor,
    handleSaveButton,
    colorPopupOpen,
    setColorPopupOpen,
}) => {
    const onKeyDown = useCallback(
        (event) => {
            if (event.keyCode === KEY_CODES.SPACEBAR) return;

            // To help with debugging - allow dark mode and dev-tools commands
            const ignoreCommand =
                hasCommandModifier(event) &&
                hasShiftKey(event) &&
                (event.keyCode === KEY_CODES.D || event.keyCode === KEY_CODES.C);

            if (ignoreCommand) return;

            event.stopPropagation();

            // Don't get in the way of the standard OS shortcuts
            if (hasAltModifier(event)) {
                const preventBrowserShortcut =
                    event.keyCode === KEY_CODES.UP_ARROW || event.keyCode === KEY_CODES.DOWN_ARROW;

                if (preventBrowserShortcut) {
                    event.preventDefault();
                }

                return;
            }

            if (hasCommandModifier(event)) {
                // If using the copy and paste shortcuts, or select all, duplicate, etc.
                // ignore and don't let the normal key handlers fire
                const preventBrowserShortcut =
                    event.keyCode === KEY_CODES.UP_ARROW ||
                    event.keyCode === KEY_CODES.DOWN_ARROW ||
                    event.keyCode === KEY_CODES.LEFT_ARROW ||
                    event.keyCode === KEY_CODES.RIGHT_ARROW ||
                    event.keyCode === KEY_CODES.C ||
                    event.keyCode === KEY_CODES.X ||
                    event.keyCode === KEY_CODES.V;

                if (preventBrowserShortcut) {
                    event.preventDefault();
                    return;
                }

                if (event.keyCode === KEY_CODES.S) {
                    event.preventDefault();
                    return handleSaveButton();
                }

                if (event.keyCode === KEY_CODES.Z) {
                    // cancel the current path, if one is being drawn
                    // (this is mostly applicable when using a multitouch gesture to undo)
                    cancelPath();

                    hasShiftKey(event) ? redoPath() : undoPath();

                    event.preventDefault();
                }

                return;
            }

            event.preventDefault();

            switch (event.keyCode) {
                case KEY_CODES.S:
                case KEY_CODES.V:
                    return setToolMode(TOOL_MODES.SELECT);
                case KEY_CODES.D:
                case KEY_CODES.P:
                case KEY_CODES.M:
                case KEY_CODES.B:
                    return setToolMode(TOOL_MODES.DRAW);
                case KEY_CODES.X:
                    return switchToPreviousColor();
                case KEY_CODES['1']: {
                    setStrokeSize(STROKE_SIZES.SMALL);
                    return setToolMode(TOOL_MODES.DRAW);
                }
                case KEY_CODES['2']: {
                    setStrokeSize(STROKE_SIZES.MEDIUM);
                    return setToolMode(TOOL_MODES.DRAW);
                }
                case KEY_CODES['3']: {
                    setStrokeSize(STROKE_SIZES.LARGE);
                    return setToolMode(TOOL_MODES.DRAW);
                }
                case KEY_CODES['4']: {
                    setStrokeSize(STROKE_SIZES.EXTRA_LARGE);
                    return setToolMode(TOOL_MODES.DRAW);
                }
                case KEY_CODES.C: {
                    if (toolMode !== TOOL_MODES.DRAW) return;
                    setColorPopupOpen(!colorPopupOpen);
                    return;
                }
                case KEY_CODES.E:
                    return setToolMode(TOOL_MODES.ERASE);
                case KEY_CODES.ESC:
                    // Let the select mode canvas handle the escape key
                    if (toolMode !== TOOL_MODES.SELECT) return handleSaveButton();
                    return;
                default:
                    return null;
            }
        },
        [undoPath, redoPath],
    );

    useDocumentKeyEventHandler({
        onKeyDown,
    });
};
