import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorRedoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon drawing-editor-redo"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="m11 8 5-4-5-4 .001 3.25H6a6 6 0 1 0 0 12h2.25a.75.75 0 0 0 0-1.5H6a4.5 4.5 0 0 1-4.495-4.288L1.5 9.25a4.5 4.5 0 0 1 4.288-4.495L6 4.75h5.001L11 8z"
        />
    </svg>
);
const Memo = memo(DrawingEditorRedoIcon);
export default Memo;
