// Lib
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../node_module_clones/recompose';

// Utils
import { navigateToRootWorkspace } from '../../reducers/navigationActions';
import { getOnboardingProgressStep, clearOnboardingProgressStep } from './config/onboardingProgressService';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';
import { clearCachedReduxState } from '../../offline/cache/localCache';

// Components
import workspaceUserOnlyInitialisationDecorator from '../decorators/workspaceUserOnlyInitialisationDecorator';
import AuthenticatedContainer from '../../auth/AuthenticatedContainer';

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigateToWorkspace: () => dispatch(navigateToRootWorkspace()),
});

const enhance = compose(workspaceUserOnlyInitialisationDecorator, connect(null, mapDispatchToProps));

const OnboardingClearRedirect = ({ dispatchNavigateToWorkspace }) => {
    useEffect(() => {
        const onboardingStep = getOnboardingProgressStep();

        sendAmplitudeEvent({
            eventType: 'Used clear onboarding route',
            eventProperties: {
                onboardingStep,
            },
        });

        clearOnboardingProgressStep();
        clearCachedReduxState();
        dispatchNavigateToWorkspace();
    }, []);

    return null;
};

OnboardingClearRedirect.propTypes = {
    dispatchNavigateToWorkspace: PropTypes.func,
};

export default enhance((props) => (
    <AuthenticatedContainer
        redirectParams={{
            from: encodeURIComponent(props.redirectPath),
        }}
    >
        <OnboardingClearRedirect {...props} />
    </AuthenticatedContainer>
));
