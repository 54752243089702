// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import AnchorButton from '../components/buttons/AnchorButton';

// Styles
import './SocialSignInButton.scss';

const SocialSignInButton = ({ children, className, logo, href, onClick }) => (
    <AnchorButton className={classNames('SocialSignInButton', className)} href={href} onClick={onClick}>
        <span className="logo-container">{logo}</span>
        <span className="text">{children}</span>
    </AnchorButton>
);

SocialSignInButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
    className: PropTypes.string,
    logo: PropTypes.element,
    href: PropTypes.string,
    onClick: PropTypes.func,
};

export default SocialSignInButton;
