// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash/fp';

// Components
import ElementReactions from '../reactions/ElementReactions';
import ColorSwatchContentEditable from './ColorSwatchContentEditable';

// Utils
import { getColor, getMedia, getShowColorValue } from '../../../common/elements/utils/elementPropertyUtils';
import { getElementCardCropInset, getElementCardRenderedWidthPx } from '../utils/elementUtil';
import { prop } from '../../../common/utils/immutableHelper';
import getPaddingForAspectRatio from '../resizing/utils/getPaddingForAspectRatio';
import { getColorCssValue } from '../utils/elementColorStyleUtils';

const ColorSwatchRenderer = (props) => {
    const {
        element,
        gridSize,
        inList,
        tempMediaSize,
        registerMediaElement,
        cropToGrid,
        onDoubleClick,
        elementContainerPaddingPx = 0,
    } = props;

    const color = getColor(element);
    const colorCssValue = getColorCssValue(color) || '#FFFFFF';

    // Ensure the rendered width is correct depending on whether in a column or on the canvas
    const widthPx = getElementCardRenderedWidthPx(props) - elementContainerPaddingPx * 2;
    const cropInset = getElementCardCropInset(inList);

    const savedSize = getMedia(element);

    const style = getPaddingForAspectRatio({
        savedSize,
        forcedSize: tempMediaSize,
        roundSaved: false,
        cropToGrid,
        cropInset,
        gridSize,
        widthPx,
    });

    const size = tempMediaSize || savedSize;
    const isSmallSize = prop('width', size) / gridSize < 15;
    const shouldShowColorValue = isSmallSize ? false : getShowColorValue(element);

    const wrapper = registerMediaElement || identity;

    return wrapper(
        <div
            className="ColorSwatchRenderer"
            style={{ ...style, background: colorCssValue }}
            onDoubleClick={onDoubleClick}
        >
            {shouldShowColorValue && <ColorSwatchContentEditable {...props} />}
            <ElementReactions {...props} />
        </div>,
    );
};

ColorSwatchRenderer.propTypes = {
    element: PropTypes.object.isRequired,
    inList: PropTypes.string,
    gridSize: PropTypes.number,
    tempMediaSize: PropTypes.object,
    registerMediaElement: PropTypes.func,
    cropToGrid: PropTypes.bool,
    isDragging: PropTypes.bool,
    onDoubleClick: PropTypes.func,
    elementContainerPaddingPx: PropTypes.number,
};

export default ColorSwatchRenderer;
