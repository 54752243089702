import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavRedoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        viewBox="0 0 18 19"
        className="Icon nav-redo"
        {...props}
    >
        <path
            fill="#8D929A"
            d="M13.11.728a.5.5 0 0 1 .702-.078l3.7 2.96a.5.5 0 0 1 0 .78l-3.7 2.96A.5.5 0 0 1 13 6.96V4.5H8a6.5 6.5 0 0 0 0 13h3V19H8A8 8 0 1 1 8 3h5V1.04a.5.5 0 0 1 .11-.312z"
        />
    </svg>
);
const Memo = memo(NavRedoIcon);
export default Memo;
