import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '../../../node_module_clones/react-contextmenu';

const ContextMenuDivider = ({ show = null }) => show && <MenuItem divider />;

ContextMenuDivider.propTypes = {
    show: PropTypes.bool,
};

export default ContextMenuDivider;
