// Utils
import { getTimestamp } from '../../../../../../common/utils/timeUtil';

// Constants
import { RESOURCE_ENTITY_INITIAL_STATE } from './defaultAsyncResourceEntityReducer';

// Types
import { AsyncResourceType } from './asyncResourceReducerTypes';
import { ResourceStatus } from '../asyncResourceConstants';

/**
 * Sets the entity's state correctly for a creation operation.
 */
export const handleResourceEntityCreation = (state: AsyncResourceType, entityId: string): AsyncResourceType => {
    const currentEntityState = state[entityId] || RESOURCE_ENTITY_INITIAL_STATE;
    const currentTime = getTimestamp();

    return {
        ...state,
        [entityId]: {
            ...currentEntityState,
            id: entityId,
            status: ResourceStatus.FETCHED,
            fetched: true,
            fetchedTime: currentTime,
            creationTime: currentTime,
            fetching: false,
        },
    };
};
