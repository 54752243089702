export const COOKIE_GROUPS = {
    // These cookies are necessary for the website to function and cannot be switched off in our systems.
    STRICTLY_NECESSARY_COOKIES: 'C0001',

    // These cookies allow us to count visits and traffic sources so we can measure and improve the
    // performance of our site.
    PERFORMANCE_COOKIES: 'C0002',

    // These cookies enable the website to provide enhanced functionality and personalisation.
    FUNCTIONAL_COOKIES: 'C0003',

    // These cookies may be set through our site by our advertising partners.
    TARGETING_COOKIES: 'C0004',
};

export const ONE_TRUST_STATUS = {
    UNFETCHED: 'UNFETCHED',
    FETCHING: 'FETCHING',
    INITIALISED: 'INITIALISED',
    ERROR: 'ERROR',
};

export const ONE_TRUST_CONSENT_MODEL = {
    OPT_IN: 'opt-in',
    OPT_OUT: 'opt-out',
};

// Action types

export const ONE_TRUST_SET_STATUS = 'ONE_TRUST_SET_STATUS';
export const ONE_TRUST_SHOW_BANNER = 'ONE_TRUST_SHOW_BANNER';
export const ONE_TRUST_HIDE_BANNER = 'ONE_TRUST_HIDE_BANNER';
export const ONE_TRUST_SET_CONSENT_MODEL = 'ONE_TRUST_SET_CONSENT_MODEL';
