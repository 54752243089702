import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const LockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" className="Icon lock" {...props}>
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M8 1.25A3.75 3.75 0 0 1 11.75 5v1H12a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h.25V5A3.75 3.75 0 0 1 8 1.25zm0 1.5A2.25 2.25 0 0 0 5.75 5v1h4.5V5A2.25 2.25 0 0 0 8 2.75z"
        />
    </svg>
);
const Memo = memo(LockIcon);
export default Memo;
