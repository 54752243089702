// Lib
import { NativeTypes } from 'react-dnd-html5-backend';

// Utils
import { length } from '../../common/utils/immutableHelper';
import { getWrappedReactDndMonitor } from '../utils/dnd/GlobalDragAndDropHelper';
import { getListChildNewLocation } from '../../common/elements/utils/elementLocationUtils';
import {
    dropTargetAlreadyHandled,
    handleElementDrop,
    handleFileDrop,
    handleImageUrlDrop,
    handleTaskDrop,
    handleTaskListDropOnTasks,
    handleTextDrop,
    handleUrlDrop,
    CUSTOM_TYPES,
} from '../utils/dnd/dragAndDropUtils';
import { isColumn, isCommentThread, isLine, isTask, isTaskList } from '../../common/elements/utils/elementTypeUtils';
import isDraggedItemATask from '../element/dnd/task/isDraggedItemATask';
import areDraggedItemsTaskListsWithoutHeadings from '../element/dnd/task/areDraggedItemsTaskListsWithoutHeadings';
import { getElementId, getIsCollapsed } from '../../common/elements/utils/elementPropertyUtils';

const getLocationOnList = (props, monitor) => {
    // Use the item's index, or add it to the end of the list
    const { listId, childElementIds } = props;
    const item = monitor.getItem();

    const index = item.index || item.index === 0 ? item.index : length(childElementIds);

    // Construct location object for the drop
    return getListChildNewLocation({ listId, index });
};

const handleListTaskDrop = handleTaskDrop(getLocationOnList);
const handleListTaskListDrop = handleTaskListDropOnTasks(getLocationOnList);
const handleListElementDrop = handleElementDrop(getLocationOnList);
const handleListFileDrop = handleFileDrop(getLocationOnList);
const handleListTextDrop = handleTextDrop(getLocationOnList);
const handleListUrlDrop = handleUrlDrop(getLocationOnList);
const handleListImageUrlDrop = handleImageUrlDrop(getLocationOnList);

const getAcceptedElementIds = (dropTargetElement, monitor) => {
    const { draggedElements } = monitor.getItem();
    const isDropTargetColumn = isColumn(dropTargetElement);
    return draggedElements
        .filter(
            (draggedEl) =>
                !isLine(draggedEl) &&
                !(isDropTargetColumn && isColumn(draggedEl)) &&
                !(getIsCollapsed(draggedEl) && isCommentThread(draggedEl)),
        )
        .map(getElementId);
};

// /* Drop Target functionality. */
export const listDropLocationFn = (props, dndMonitor, domNode) => {
    const monitor = getWrappedReactDndMonitor(dndMonitor);

    const { element } = props;

    // Don't handle the drop if it's already been handled by a child.
    if (dropTargetAlreadyHandled(monitor)) return;

    if (monitor.getItemType() === CUSTOM_TYPES.IMAGE_LINK) return handleListImageUrlDrop(props, monitor, domNode);
    if (monitor.getItemType() === NativeTypes.FILE) return handleListFileDrop(props, monitor, domNode);
    if (monitor.getItemType() === NativeTypes.TEXT) return handleListTextDrop(props, monitor, domNode);
    if (monitor.getItemType() === NativeTypes.URL) return handleListUrlDrop(props, monitor, domNode);
    if (!(isTask(element) || isTaskList(element)) && isDraggedItemATask(monitor)) {
        return handleListTaskDrop(props, monitor, domNode);
    }

    if ((isTask(element) || isTaskList(element)) && areDraggedItemsTaskListsWithoutHeadings(props, monitor)) {
        return handleListTaskListDrop(props, monitor, domNode);
    }

    const acceptedElementIds = getAcceptedElementIds(element, monitor);
    return handleListElementDrop(props, monitor, domNode, acceptedElementIds);
};
