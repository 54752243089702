// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getFileProp } from '../../../../common/elements/utils/elementPropertyUtils';
import { getFileDataType } from '../utils/fileElementUtils';

// Components
import FileIcon from '../../../components/fileIcon/FileIcon';

// Styles
import './FilePreviewUnavailable.scss';

const FilePreviewUnavailable = ({ element }) => {
    const fileData = getFileProp(element);

    return (
        <div className="FilePreviewUnavailable">
            <div className="unavailable-message">
                <FileIcon fileType={getFileDataType(fileData)} iconStyle="large" />
                <span className="preview-message">No preview available</span>
            </div>
        </div>
    );
};

FilePreviewUnavailable.propTypes = {
    element: PropTypes.object,
};

export default FilePreviewUnavailable;
