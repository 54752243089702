// Lib
import React from 'react';

// Components
import Spinner from '../loaders/Spinner';

// Styles
import './ModalLoader.scss';

const ModalLoader = () => (
    <div className="ModalLoader">
        <Spinner show />
    </div>
);

export default ModalLoader;
