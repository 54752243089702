// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Component
import Icon from '../../../../../components/icons/light/IconLightSizeLarge';
import ToolbarTool from '../../ToolbarTool';

// Actions
import { updateSelectedElements } from '../../../../../element/actions/elementActions';

// Utils
import fileGetMediaPlayerType from '../../../../../element/file/utils/fileGetMediaPlayerType';
import { getDisplayMode, getImageOriginal } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { ELEMENT_DISPLAY_MODES } from '../../../../../../common/elements/elementDisplayModeConstants';

const mapDispatchToProps = (dispatch) => ({
    setDisplayMode: ({ showMedia, displayMode }) => {
        dispatch(
            updateSelectedElements({
                changes: {
                    showMedia,
                    displayMode,
                },
            }),
        );
    },
});

const DisplayModeTool = (props) => {
    const { selectedElements, setDisplayMode } = props;

    const firstSelectedElement = selectedElements.first();

    const hasPreview = getImageOriginal(firstSelectedElement) || fileGetMediaPlayerType(firstSelectedElement);
    const toolName = hasPreview ? 'Preview' : 'Card View';
    const iconName = hasPreview ? 'toolbar-image-preview' : 'toolbar-card-view';

    const isActive = getDisplayMode(firstSelectedElement) === ELEMENT_DISPLAY_MODES.DETAIL_VIEW;

    const onClick = () =>
        setDisplayMode({
            showMedia: !isActive,
            displayMode: isActive ? ELEMENT_DISPLAY_MODES.ICON_VIEW : ELEMENT_DISPLAY_MODES.DETAIL_VIEW,
        });

    return (
        <ToolbarTool {...props} name={toolName} isActive={isActive} onClick={onClick}>
            <Icon name={iconName} />
        </ToolbarTool>
    );
};

DisplayModeTool.propTypes = {
    selectedElements: PropTypes.object,
    setDisplayMode: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(DisplayModeTool);
