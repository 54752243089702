import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAlignMiddleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-align-middle"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M24 15.25H8a.75.75 0 1 0 0 1.5h16a.75.75 0 1 0 0-1.5zM12.54 22.5a.5.5 0 0 0 .5.5h2.208l-.002 3.248a.752.752 0 1 0 1.504 0v-3.249l2.21.001a.5.5 0 0 0 .242-.063l.07-.047a.5.5 0 0 0 .078-.702l-2.96-3.7a.5.5 0 0 0-.78 0l-2.96 3.7a.5.5 0 0 0-.11.312zm0-13a.5.5 0 0 1 .5-.5h2.208l-.002-3.248a.752.752 0 1 1 1.504 0v3.249L18.96 9a.5.5 0 0 1 .242.063l.07.047a.5.5 0 0 1 .078.702l-2.96 3.7a.5.5 0 0 1-.78 0l-2.96-3.7a.5.5 0 0 1-.11-.312z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAlignMiddleIcon);
export default Memo;
