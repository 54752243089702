// Lib
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { getStrokeColor } from '../drawingEditorUtils';
import { stopDefaultAndPropagation } from '../../../../utils/domUtil';

// Components
import Button from '../../../../components/buttons/Button';
import FloatingPanel from '../../../../components/tooltips/FloatingPanel';
import PopupPanelMouseManager from '../../../../components/popupPanel/PopupPanelMouseManager';
import CustomColorInput from '../../../../workspace/toolbar/components/selectionTools/colorTools/CustomColorInput';
import ColorPopupContent from '../../../../workspace/toolbar/components/selectionTools/colorTools/popup/ColorPopupContent';
import ColorToolIcon from '../../../../workspace/toolbar/components/selectionTools/colorTools/ColorToolIcon';

// Hooks
import useTouchBlockedPointerEvent from './useTouchBlockedPointerEvent';

// Styles
import './DrawingEditorColorTool.scss';

const setDrawingEditorColorToolTempColorStyles = (color) => {
    // CSS backgrounds
    const colorTargets = document.querySelectorAll('.DrawingEditorColorButton .color-target');
    const colorTargetArray = colorTargets ? Array.from(colorTargets) : [];

    colorTargetArray.forEach((domNode) => {
        domNode.style.backgroundImage = 'none';
        domNode.style.backgroundColor = color;
    });

    const pathTargets = document.querySelectorAll('.DrawingPath.selected:not(.outline)');

    pathTargets.forEach((domNode) => {
        domNode.classList.contains('stroke-only')
            ? domNode.setAttribute('stroke', color)
            : domNode.setAttribute('fill', color);
    });
};

const DrawingEditorColorTool = ({
    isPenMode,
    isSketch,
    disabled,
    keepPopupOpen,
    strokeColor,
    defaultColors,
    colorPopupOpen,

    setStrokeColor,
    setColorPopupOpen,
    setCustomColorInputOpen,
}) => {
    const customColorInputRef = useRef();

    const togglePopup = useCallback(() => setColorPopupOpen(!colorPopupOpen), [setColorPopupOpen, colorPopupOpen]);
    const onButtonPress = useTouchBlockedPointerEvent(isPenMode, togglePopup);

    const closePopup = () => setColorPopupOpen(false);

    const openCustomColorPicker = useCallback(() => {
        if (customColorInputRef && customColorInputRef.current) {
            customColorInputRef.current.open();
        }
    }, [customColorInputRef]);

    const setColorAndClose = (color) => {
        setStrokeColor(color);
        !keepPopupOpen && closePopup();
    };

    return (
        <div className="DrawingEditorColorTool" onDoubleClick={stopDefaultAndPropagation}>
            <Button
                disabled={disabled}
                className="DrawingEditorColorButton"
                onPointerUp={!disabled ? onButtonPress : null}
                onDoubleClick={stopDefaultAndPropagation}
            >
                <ColorToolIcon color={getStrokeColor(strokeColor)} />
            </Button>

            <CustomColorInput
                className={classNames({ 'is-open': colorPopupOpen })}
                ref={customColorInputRef}
                onFocus={() => setCustomColorInputOpen(true)}
                onBlur={() => setCustomColorInputOpen(false)}
                initialColor={getStrokeColor(strokeColor)}
                setTempColor={setDrawingEditorColorToolTempColorStyles}
                setColor={setColorAndClose}
            />

            {colorPopupOpen && (
                <PopupPanelMouseManager
                    closePopup={closePopup}
                    visible
                    ignoredClicksSelector=".DrawingEditorColorButton,.FloatingPanel"
                >
                    <FloatingPanel
                        className={classNames('DrawingEditorColorPopup light color-popup', { sketch: isSketch })}
                        selector=".DrawingEditorColorButton"
                    >
                        <ColorPopupContent
                            defaultColors={defaultColors}
                            selectedColor={strokeColor}
                            closePopup={closePopup}
                            openCustomColorPicker={openCustomColorPicker}
                            setColor={setColorAndClose}
                            customColorInputRef={customColorInputRef}
                        />
                    </FloatingPanel>
                </PopupPanelMouseManager>
            )}
        </div>
    );
};

DrawingEditorColorTool.propTypes = {
    isPenMode: PropTypes.bool,
    isSketch: PropTypes.bool,
    backgroundColor: PropTypes.string,
    strokeColor: PropTypes.string,
    colorPopupOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    keepPopupOpen: PropTypes.bool,
    defaultColors: PropTypes.array,

    setStrokeColor: PropTypes.func,
    setColorPopupOpen: PropTypes.func,
    setCustomColorInputOpen: PropTypes.func,
};

export default DrawingEditorColorTool;
