// Util
import { getTimestamp } from '../utils/timeUtil';

/**
 * A generic element constructor which all elements can use to create their type.
 */
export default ({ id, location, content, timestamp, temp, meta = {} }) => ({
    id,
    location,
    content,
    temp,
    meta: {
        ...meta,
        modifiedTime: timestamp || getTimestamp(),
    },
});
