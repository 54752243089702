import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationViewedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-viewed"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#FFF" />
            <g transform="translate(1 1)">
                <circle cx={7} cy={7} r={7} fill="#4EB85A" />
                <path
                    fill="#FFF"
                    d="M10.5 4.013 6.125 8.388 3.937 6.125 2.7 7.362l2.806 2.882a.877.877 0 0 0 1.238 0l4.993-4.994L10.5 4.013z"
                />
            </g>
        </g>
    </svg>
);
const Memo = memo(NotificationViewedIcon);
export default Memo;
