// Libs
import React from 'react';

// Components
import AlignmentPopup from './AlignmentPopup';
import ToolbarPopupTool from '../../../ToolbarPopupTool';

const AlignmentTool = (props) => <ToolbarPopupTool {...props} toolName="alignment" PopupComponent={AlignmentPopup} />;

export default AlignmentTool;
