import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';

import Spinner from '../../../../../../../../components/loaders/Spinner';

import './IconList.scss';

const getIsActive = (selectedElements, icon) => {
    let isActive = true;
    selectedElements.forEach((element) => {
        const elementIconId = element.getIn(['content', 'icon', 'id']);
        const iconId = icon && icon.get('id');
        isActive = (isActive && !elementIconId && !iconId) || (iconId && elementIconId === iconId);
    });
    return isActive;
};

const IconListLoading = (props) => (
    <div className="IconList">
        <div className="iconlist-loading">
            <Spinner show />
        </div>
    </div>
);

const IconListEmpty = ({ popupQuery, setModeDefault }) => (
    <div className="IconList">
        <div className="iconlist-loading">
            <div className="no-results">
                <p>No icons found for &quot;{popupQuery}&quot;.</p>
                <p>
                    <a href="#" onClick={setModeDefault}>
                        See recommended icons
                    </a>
                </p>
            </div>
        </div>
    </div>
);

IconListEmpty.propTypes = {
    popupQuery: PropTypes.string,
    setModeDefault: PropTypes.func,
};

const IconListResults = (props) => (
    <div className="IconList">
        <ul>
            {props.iconList.map((icon, i) => (
                <li key={i}>
                    <IconButton {...props} icon={icon} isActive={getIsActive(props.selectedElements, icon)} />
                </li>
            ))}
        </ul>
    </div>
);

IconListResults.propTypes = {
    iconList: PropTypes.object,
    selectedElements: PropTypes.object,
};

const IconList = (props) => {
    const { iconList } = props;

    if (!iconList) return <IconListLoading {...props} />;
    if (!iconList.size) return <IconListEmpty {...props} />;
    return <IconListResults {...props} />;
};

IconList.propTypes = {
    iconList: PropTypes.object,
};

export default IconList;
