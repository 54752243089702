import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniDocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-document"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M5 3h7l4 4v9a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M11 4v4h4v8H5V4h6zm3.5 3H12V4.5L14.5 7z" />
            <path
                fill="#767C86"
                d="M7.5 10h5a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1zm0 2h5a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniDocumentIcon);
export default Memo;
