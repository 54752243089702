// Lib
import { createSelector, createStructuredSelector } from 'reselect';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import { isClone } from '../../../common/elements/utils/elementTypeUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getLinkedElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getRenderedCloneElement } from '../clone/cloneUtils';
import { isAsyncEntityFetched } from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Selectors
import { socketConnectedSelector } from '../../utils/socket/socketConnectionSelector';
import { elementsAsyncResourcesSelector, getElements } from '../selectors/elementSelector';
import { getCurrentModalElementId } from './elementModalCommonSelector';
import { canWrite } from '../../../common/permissions/permissionUtil';
import { getIsElectronSelector } from '../../components/electron/electronSelector';
import { getSpellCheckStatus } from '../../../common/electron/electronSelectors';
import { getUrlPermissionId } from '../../app/routingSelector';
import { currentBoardUserPermissionsSelector } from '../../utils/permissions/elementPermissionsSelector';
import { getLocalCacheHydrationTimestamp } from '../../offline/cache/localCacheSelector';

export const currentElementIdSelector = (state, props) =>
    getCurrentModalElementId(state) || props.elementId || (props.routeParams && props.routeParams.elementId);

export const getCurrentElement = createSelector(currentElementIdSelector, getElements, prop);

export const getCurrentElementResourceEntityState = createSelector(
    elementsAsyncResourcesSelector,
    currentElementIdSelector,
    (elementResourcesState, currentElementId) => elementResourcesState?.[currentElementId],
);

export const getIsCurrentElementFetched = createSelector(getCurrentElementResourceEntityState, isAsyncEntityFetched);

const getCurrentElementPermissions = (state, props) => {
    return currentBoardUserPermissionsSelector(state);
};

const getIsRemotelySelected = createSelector(
    currentElementIdSelector,
    (state) => state.getIn(['remoteActivity', 'selectedElements']),
    (elementId, remoteElementActivity) => {
        const remoteSelectionData = remoteElementActivity.get(elementId);
        return remoteSelectionData && !!remoteSelectionData.get('userId');
    },
);

const getCurrentElementIsEditable = createSelector(
    getCurrentElementPermissions,
    getIsRemotelySelected,
    (permissions, isRemotelySelected) => canWrite(permissions) && !isRemotelySelected,
);

const getSpellCheck = createSelector(getIsElectronSelector, getSpellCheckStatus, (isElectron, spellCheckStatus) =>
    isElectron ? spellCheckStatus : true,
);

export const getRenderedElement = createSelector(getCurrentElement, getElements, (currentElement, elements) => {
    if (!isClone(currentElement)) return currentElement;

    const linkedElementId = getLinkedElementId(currentElement);
    const linkedElement = getElement(elements, linkedElementId);

    return getRenderedCloneElement(currentElement, linkedElement);
});

export default createStructuredSelector({
    urlPermissionId: getUrlPermissionId,
    element: getRenderedElement,

    localCacheHydrationTimestamp: getLocalCacheHydrationTimestamp,
    currentElementResourceEntity: getCurrentElementResourceEntityState,
    isElementFetched: getIsCurrentElementFetched,

    permissions: getCurrentElementPermissions,
    isEditable: getCurrentElementIsEditable,

    socketConnected: socketConnectedSelector,
    spellCheck: getSpellCheck,
});
