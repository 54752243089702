import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationShareIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-share"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#FFF" />
            <g transform="translate(1 1)">
                <circle cx={7} cy={7} r={7} fill="#5F6672" />
                <path
                    fill="#FFF"
                    d="M8.4 7c1.04 0 1.96.774 2.087 1.932l.013.168v.175c0 .29-.235.525-.525.525h-5.95a.525.525 0 0 1-.525-.525V9.1c0-1.205.98-2.1 2.1-2.1h2.8zM7 3.5a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z"
                />
            </g>
        </g>
    </svg>
);
const Memo = memo(NotificationShareIcon);
export default Memo;
