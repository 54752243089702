// Utils
import getNewLine from './getNewLine';

/**
 * Return the selection in a block as an object:
 * { startLine, startOffset, endLine, endOffset }
 */
const getLineOffsets = (text, sep) => {
    const separator = sep || getNewLine(text);

    const endTextIndex = text.length;
    let nextLineIndex = 0;
    let prevLineIndex = -1;

    const lineOffsets = [];

    while (nextLineIndex >= 0 && nextLineIndex < endTextIndex) {
        nextLineIndex = text.indexOf(separator, nextLineIndex + separator.length);
        const endOffset = nextLineIndex !== -1 ? nextLineIndex : endTextIndex;

        lineOffsets.push({ startOffset: prevLineIndex + 1, endOffset });

        prevLineIndex = nextLineIndex;
    }

    return lineOffsets;
};

export default getLineOffsets;
