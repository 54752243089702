import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-lock"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M11 12a5 5 0 0 1 10 0v2h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1v-2zm11 3.5H10a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5zM19.5 14v-2a3.5 3.5 0 0 0-7 0v2h7z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarLockIcon);
export default Memo;
