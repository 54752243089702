// Lib
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { sum } from 'lodash/fp';
import { toNumber } from 'lodash';
import Handsontable from 'handsontable/base';
import { Events } from 'handsontable/pluginHooks';

// Utils
import {
    addClassToTableElement,
    getColumnBacklightElement,
    getColumnGuidelineElement,
    getHighlighted,
    getRowBacklightElement,
    getRowGuidelineElement,
    getSelectedTableElement,
    removeClassFromTableElement,
} from '../utils/tableDOMUtils';
import {
    getFirstSelection,
    isCellRefColHeader,
    isCellRefRowHeader,
    isColInSelection,
    isRowInSelection,
    isSelectingRefColHeader,
    isSelectingRefRowHeader,
} from '../utils/tableCellSelectionUtils';
import { convertColWidthsGUtoPx } from '../utils/tableSizeUtils';
import { isRightClick } from '../../../utils/mouseEventUtils';

// Types
import TableOperations from './TableOperations';

interface Props {
    elementId: string;
    gridSize: number;
    getContextZoomScale: () => number;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
    tableOperationsRef: React.RefObject<TableOperations>;
}

interface RefType {
    beforeOnCellMouseDown: Events['beforeOnCellMouseDown'];
}

const TableMoveColumnRowHandlers = forwardRef<RefType, Props>(function TableMoveColumnRowHandlersComponent(props, ref) {
    const { gridSize, getContextZoomScale, hotTableInstanceRef, tableOperationsRef } = props;

    const colBacklightPosition = useRef<number>();
    const rowBacklightPosition = useRef<number>();

    /**
     * Function to set the position of backlight elements while dragging rows or columns.
     * Sets the left value in columns and the top value in rows
     * Also shows or hides the guideline depending on whether the row/col can be moved or not
     */
    const setRowBacklightPosition = useCallback((event: MouseEvent, isInit?: boolean) => {
        const rowBacklightElement = getRowBacklightElement();
        if (!rowBacklightElement || isRightClick(event)) return;

        const zoomScale = getContextZoomScale();
        const table = getSelectedTableElement();
        if (!table) return;

        const tableRect = table.getBoundingClientRect();
        const activeHighlight = getHighlighted();
        if (!activeHighlight) return;

        const activeHighlightRect = activeHighlight.getBoundingClientRect();

        // Ask manualColumnMove plugin to refresh positions so that the guideline will be in the correct position
        if (isInit) {
            const manualRowMovePlugin = hotTableInstanceRef.current?.getPlugin<'manualRowMove'>('manualRowMove');

            manualRowMovePlugin?.refreshPositions();
        }

        // set correct row backlight position
        requestAnimationFrame(() => {
            // If cursor is outside the table, set backlight position to the same as previously used
            if (event.clientY < tableRect.top || event.clientY > tableRect.bottom) {
                rowBacklightElement.style.top = rowBacklightPosition.current + 'px';
                return;
            }

            const titleHeightVariable = getComputedStyle(table).getPropertyValue('--table-title-height');
            const titleHeight = toNumber(titleHeightVariable.split('px')[0]) * zoomScale;

            // Set backlight position
            const topClientOffset = event.clientY - tableRect.top;
            rowBacklightPosition.current = (topClientOffset - titleHeight) / zoomScale;
            rowBacklightElement.style.top = rowBacklightPosition.current + 'px';

            // Hide guideline element if it is next to the original position of the row
            const rowGuidelineElement = getRowGuidelineElement();
            if (rowGuidelineElement) {
                const guidelinePos = toNumber(rowGuidelineElement.style.top.split('px')[0]) + 1;
                const rowTop = Math.round((activeHighlightRect.top - tableRect.top - titleHeight) / zoomScale);
                const rowBottom = Math.round((activeHighlightRect.bottom - tableRect.top - titleHeight) / zoomScale);
                const hideGuideline = guidelinePos >= rowTop - 1 && guidelinePos <= rowBottom + 1;
                rowGuidelineElement.style.display = hideGuideline ? 'none' : 'block';
                rowGuidelineElement.classList.toggle('guideline-top', guidelinePos <= 1);
            }
        });
    }, []);

    const setColBacklightPosition = useCallback((event: MouseEvent, isInit?: boolean) => {
        const columnBacklightElement = getColumnBacklightElement();
        if (!columnBacklightElement || isRightClick(event)) return;

        const zoomScale = getContextZoomScale();
        const table = getSelectedTableElement();
        if (!table) return;

        const tableRect = table.getBoundingClientRect();
        const activeHighlight = getHighlighted();
        if (!activeHighlight) return;

        const activeHighlightRect = activeHighlight.getBoundingClientRect();

        // Ask manualColumnMove plugin to refresh positions so that the guideline will be in the correct position
        if (isInit) {
            const manualColMovePlugin = hotTableInstanceRef.current?.getPlugin<'manualColumnMove'>('manualColumnMove');

            manualColMovePlugin?.refreshPositions();
        }

        // set correct column backlight position
        requestAnimationFrame(() => {
            // If cursor is outside the table, set backlight position to the same as previously used
            if (event.clientX < tableRect.left || event.clientX > tableRect.right) {
                columnBacklightElement.style.left = colBacklightPosition.current + 'px';
                return;
            }

            // Set backlight position
            const leftClientOffset = event.clientX - tableRect.left;
            colBacklightPosition.current = leftClientOffset / zoomScale;
            columnBacklightElement.style.left = colBacklightPosition.current + 'px';

            // Hide guideline element if it is next to the original position of the column
            const columnGuidelineElement = getColumnGuidelineElement();
            if (columnGuidelineElement) {
                const guidelinePos = toNumber(columnGuidelineElement.style.left.split('px')[0]);
                const colLeft = Math.round((activeHighlightRect.left - tableRect.left) / zoomScale);
                const colRight = Math.round((activeHighlightRect.right - tableRect.left) / zoomScale);

                const hideGuideline = guidelinePos >= colLeft - 1 && guidelinePos <= colRight + 1;

                columnGuidelineElement.style.display = hideGuideline ? 'none' : 'block';
            }
        });
    }, []);

    const clearBacklightPosition = useCallback(() => {
        document.removeEventListener('mousemove', setRowBacklightPosition);
        document.removeEventListener('mousemove', setColBacklightPosition);
        document.removeEventListener('mouseup', clearBacklightPosition);

        requestAnimationFrame(() => {
            const rowGuidelineElement = getRowGuidelineElement();
            if (rowGuidelineElement) rowGuidelineElement.style.display = 'none';

            const colGuidelineElement = getColumnGuidelineElement();
            if (colGuidelineElement) colGuidelineElement.style.display = 'none';

            removeClassFromTableElement(hotTableInstanceRef.current, 'moving-row-col');
        });
    }, []);

    /**
     * Function to correct some of the styling issues with the backlight (shadow)
     * when dragging columns and rows to a new position.
     * * Calls the function to set the correct top/left position
     * * Corrects the margin-left value (moves the backlight from the cursor position to line up with
     * the start of the selected column(s))
     */
    const beforeOnCellMouseDown = useCallback(
        (event: MouseEvent, coords: Handsontable.CellCoords) => {
            // @ts-ignore - Handsontable private property
            if (event.isImmediatePropagationEnabled === false) return;

            if (!tableOperationsRef.current) return;

            const hotCurrentCellSelections = tableOperationsRef.current.getHotCurrentCellSelections();
            const hotColWidthsGU = tableOperationsRef.current.getHotColWidthsGU();

            // Only update the backlight if the previous selection is ref row or column header and the user selects
            // a ref row or column header again
            const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
            if (!firstCellSelection) return;
            if (!isSelectingRefColHeader(firstCellSelection) && !isSelectingRefRowHeader(firstCellSelection)) return;
            if (!isCellRefColHeader(coords) && !isCellRefRowHeader(coords)) return;

            const isMouseDownOnPrevSelection =
                isRowInSelection(coords.row, firstCellSelection) && isColInSelection(coords.col, firstCellSelection);
            if (!isMouseDownOnPrevSelection) return;

            const columnBacklightElement = getColumnBacklightElement();
            const rowBacklightElement = getRowBacklightElement();
            if (!columnBacklightElement && !rowBacklightElement) return;

            const setBacklightPosition = isSelectingRefColHeader(firstCellSelection)
                ? setColBacklightPosition
                : setRowBacklightPosition;

            // calculate the positions for backlights
            setBacklightPosition(event, true);

            addClassToTableElement(hotTableInstanceRef.current, 'moving-row-col');

            // Add event listener to continue calculating the correct position as the cursor moves
            document.addEventListener('mousemove', setBacklightPosition);
            document.addEventListener('mouseup', clearBacklightPosition);

            // Exit here if not dragging a column
            if (!columnBacklightElement) return;

            // Fix Handsontable bug - when dragging from the center of a column handsontable sets
            // margin-left to 0 or a low number resulting in an offset (affects columns only, not rows)
            // margin left accounts for the distance between the start of the selected column(s) and the cursor position

            // calculate distance from start of table to cursor
            const zoomScale = getContextZoomScale();
            const tableElement = getSelectedTableElement();
            if (!tableElement) return;

            const distanceToCursor = (event.clientX - tableElement.getBoundingClientRect().left) / zoomScale;

            // calculate distance from start of table to start of selected column
            if (hotColWidthsGU) {
                const colWidthsPx = convertColWidthsGUtoPx(hotColWidthsGU, gridSize);
                const distanceToColumn = sum(colWidthsPx.slice(0, coords.col));
                columnBacklightElement.style.marginLeft = distanceToColumn - distanceToCursor + 'px';
            }
        },
        [gridSize],
    );

    useImperativeHandle(ref, () => ({ beforeOnCellMouseDown }), [beforeOnCellMouseDown]);

    return null;
});

export default TableMoveColumnRowHandlers;
