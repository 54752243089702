/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import { repeat } from 'lodash/fp';

class EllipsisAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
        };
    }

    componentDidMount() {
        this.scheduleTimeout(160);
    }

    componentWillUnmount() {
        if (this.timeoutId) clearTimeout(this.timeoutId);
    }

    scheduleTimeout = (time) => {
        this.timeoutId = setTimeout(this.onTimeout, time);
    };

    onTimeout = () => {
        this.setState((prevState) => {
            const count = (prevState.count + 1) % 4;

            const nextTimeout = count < 3 ? 160 : 600;
            this.scheduleTimeout(nextTimeout);

            return { count };
        });
    };

    render() {
        const { count } = this.state;

        const text = repeat(count, '.');

        return <span className="EllipsisAnimation">{text}</span>;
    }
}

export default EllipsisAnimation;
