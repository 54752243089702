// Lib
import { isEmpty } from 'lodash';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Types
import { MNElement } from '../../../common/elements/elementModelTypes';
import {
    CacheOperationAddElementsData,
    CacheOperationClearElementsData,
    CacheOperationRemoveElementsData,
    CacheOperationType,
} from './cacheOperationTypes';

/**
 * Builds a cache operation data object for adding elements into the cache.
 */
export const buildAddElementsCacheOperation = (
    elements: MNElement[],
    actionType: string,
): CacheOperationAddElementsData | null => {
    if (isEmpty(elements)) return null;

    return {
        type: CacheOperationType.AddElements,
        actionType,
        elementIds: elements.map(getElementId),
        elements,
    };
};

/**
 * Builds a cache operation data object for removing elements from the cache.
 */
export const buildRemoveElementsCacheOperation = (
    elementIds: string[],
    actionType: string,
): CacheOperationRemoveElementsData | null => {
    if (isEmpty(elementIds)) return null;

    return {
        type: CacheOperationType.RemoveElements,
        actionType,
        elementIds,
    };
};

/**
 * Builds a cache operation data object for removing all the elements from the cache.
 */
export const buildClearElementsCacheOperation = (): CacheOperationClearElementsData => ({
    type: CacheOperationType.ClearElements,
});
