// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { scaleToGrid } from '../../../utils/grid/gridUtils';

// Components
import LineMarker, { getLineMarkerId } from './LineMarker';
import LinePath from './LinePath';

// Constants
import { LINE_EDGE } from '../../../../common/lines/lineConstants';

/**
 * Get the URL for given marker style.
 */
const getMarker = ({ edge, elementId, idSuffix }) => `url(#${getLineMarkerId({ edge, elementId, idSuffix })})`;

const LineSvg = (props) => {
    const {
        bezier,
        elementEvents,
        elementId,
        idSuffix,
        startStyle,
        endStyle,
        enableDrag,
        disableDrag,
        lineStyle,
        lineWeight,
        gridSize,
        updateLineControlPoint,
    } = props;

    const onDoubleClick = React.useCallback((event) => {
        if (!updateLineControlPoint) return;

        updateLineControlPoint({ control: null });
        event.stopPropagation();
        event.preventDefault();
    }, []);

    if (!bezier) return null;

    const startMarker = getMarker({ edge: LINE_EDGE.start, edgeStyle: startStyle, elementId, idSuffix });
    const endMarker = getMarker({ edge: LINE_EDGE.end, edgeStyle: endStyle, elementId, idSuffix });

    const strokeWidth = scaleToGrid(lineWeight, gridSize);

    return (
        <svg className="svg-line" version="1.1">
            <defs>
                <LineMarker
                    edge={LINE_EDGE.start}
                    elementId={elementId}
                    idSuffix={idSuffix}
                    edgeStyle={startStyle}
                    lineWeight={lineWeight}
                    strokeWidth={strokeWidth}
                />
                <LineMarker
                    edge={LINE_EDGE.end}
                    elementId={elementId}
                    idSuffix={idSuffix}
                    edgeStyle={endStyle}
                    lineWeight={lineWeight}
                    strokeWidth={strokeWidth}
                />
            </defs>
            <g>
                <LinePath
                    className="line-path"
                    bezier={bezier}
                    strokeWidth={strokeWidth}
                    lineStyle={lineStyle}
                    markerStart={startMarker}
                    markerEnd={endMarker}
                />
                <LinePath
                    className="line-hit-area"
                    bezier={bezier}
                    stroke="transparent"
                    onMouseOver={enableDrag}
                    onMouseOut={disableDrag}
                    {...elementEvents}
                    onDoubleClick={onDoubleClick}
                />
            </g>
        </svg>
    );
};

LineSvg.propTypes = {
    bezier: PropTypes.object,

    elementId: PropTypes.string.isRequired,
    elementEvents: PropTypes.object,
    idSuffix: PropTypes.string,
    startStyle: PropTypes.string,
    endStyle: PropTypes.string,
    gridSize: PropTypes.number.isRequired,
    isPresentational: PropTypes.bool,
    enableDrag: PropTypes.func,
    disableDrag: PropTypes.func,

    lineStyle: PropTypes.string,
    lineWeight: PropTypes.number,

    updateLineControlPoint: PropTypes.func,
};

export default LineSvg;
