// Lib
import fileDownload from 'js-file-download';
import dateformat from 'dateformat';

// Utils
import { getTimestamp } from '../../../../common/utils/timeUtil';
import { getColorDisplayValue } from '../../../../common/colors/elementColorFormatUtil';
import {
    getBackgroundColor,
    getDrawingHeight,
    getDrawingSvg,
    getDrawingWidth,
    getElementId,
} from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { MimeType } from '../../../../common/media/mimeConstants';

// Matches the opening SVG tag
const SVG_REGEX = /^<svg (.*?)>/;

export const downloadAsSvg = ({ element }) => {
    let svg = getDrawingSvg(element);

    if (!svg) return;

    let width = getDrawingWidth(element);
    width = width ? width + 2 : '102%';
    let height = getDrawingHeight(element);
    height = height ? height + 2 : '102%';
    const backgroundColor = getBackgroundColor(element);

    // If the sketch has a background colour, we need to save that colour to
    // the style of the downloaded svg
    if (backgroundColor) {
        const backgroundColorValue = getColorDisplayValue(backgroundColor);
        const rect = `<rect x="-1" y="-1" width="${width}" height="${height}" fill="${backgroundColorValue}" />`;
        svg = svg.replace(SVG_REGEX, `<svg $1>${rect}`);
    }

    const elementId = getElementId(element);
    const timestamp = dateformat(getTimestamp(), 'yymmdd_HHMM');

    fileDownload(svg, `sketch_${elementId}_${timestamp}.svg`, MimeType.SVG);
};
