// Lib
import { curry } from 'lodash/fp';

// Utils
import { prop } from '../../utils/immutableHelper';

// √
export const getPoint = (x, y) => ({ x, y });

// √
export const get1DDelta = (from, to, dimension) => to[dimension] - from[dimension];
export const getXDelta = (from, to) => get1DDelta(from, to, 'x');
export const getYDelta = (from, to) => get1DDelta(from, to, 'y');
export const getDelta = (from, to) => getPoint(getXDelta(from, to), getYDelta(from, to));
export const getDistance = (from, to) => Math.hypot(getXDelta(from, to), getYDelta(from, to));

// √
export const getPointBetween1D = (point1, point2, dimension, fraction) =>
    point1[dimension] + (point2[dimension] - point1[dimension]) * fraction;
export const getPointBetweenX = (point1, point2, fraction) => getPointBetween1D(point1, point2, 'x', fraction);
export const getPointBetweenY = (point1, point2, fraction) => getPointBetween1D(point1, point2, 'y', fraction);
export const getPointBetween = (point1, point2, fraction = 0.5) =>
    getPoint(getPointBetweenX(point1, point2, fraction), getPointBetweenY(point1, point2, fraction));

/**
 * Calculates an average point given an array of points passed in.
 * It sums all the dimensions of each point and then returns the sum divided by the number of points.
 *
 * E.g. For three points:
 * x = (p1.x + p2.x + p3.x) / 3, y = (p1.y + p2.y + p3.y) /3
 */
export const getAveragePoint = (points) =>
    points.reduce((acc, point, index) => {
        acc.x += point.x;
        acc.y += point.y;

        // If the final point take the average and return it to the caller
        if (index === points.length - 1) {
            acc.x /= points.length;
            acc.y /= points.length;
        }
        return acc;
    }, getPoint(0, 0));

const choosePoint = curry((selectorFn, axis, points) => selectorFn(...points.map(prop(axis))));
const getLeftBoundary = choosePoint(Math.min, 'x');
const getRightBoundary = choosePoint(Math.max, 'x');
const getTopBoundary = choosePoint(Math.min, 'y');
const getBottomBoundary = choosePoint(Math.max, 'y');
export const getTopLeftBoundaryPoint = (points) => getPoint(getLeftBoundary(points), getTopBoundary(points));
export const getBottomRightBoundaryPoint = (points) => getPoint(getRightBoundary(points), getBottomBoundary(points));
