// Utils
import { extractErrorDetailsForAction } from '../utils/errorUtil';

// Constants
import { USERS_LOAD, USER_ACTIVITY_LOAD, TRACK_USERS, USER_ACTIVITY_ERROR, USER_ACTIVITY_CLEAR } from './userConstants';

export const trackUsers = (userIds) => ({ type: TRACK_USERS, userIds });
export const loadUsers = (users) => ({ type: USERS_LOAD, users });
export const loadUser = (user) => ({
    type: USERS_LOAD,
    users: {
        [user._id]: user,
    },
});

export const userActivityLoad = ({ users, disconnectedUserIds }) => ({
    type: USER_ACTIVITY_LOAD,
    users,
    disconnectedUserIds,
});
export const userActivityError = (userIds, error) => ({
    type: USER_ACTIVITY_ERROR,
    userIds,
    error: extractErrorDetailsForAction(error),
});

export const userActivityClear = (userId) => ({ type: USER_ACTIVITY_CLEAR, userId });
