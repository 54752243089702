import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniTaskListSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-task-list-selected"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M3 4h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M3 5h14v10H3z" />
            <path
                fill="#767C86"
                d="M10.286 8h4a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1zm0 3h4a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1zM6.232 7.837a.429.429 0 1 0-.606.606l.857.857a.429.429 0 0 0 .606 0l1.428-1.428a.429.429 0 1 0-.606-.606L6.786 8.39l-.554-.554zm0 3a.429.429 0 0 0-.606.606l.857.857a.429.429 0 0 0 .606 0l1.428-1.428a.429.429 0 1 0-.606-.606L6.786 11.39l-.554-.554z"
            />
            <path
                fill="#323B4A"
                fillRule="nonzero"
                d="M16 3a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12zm0 2H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniTaskListSelectedIcon);
export default Memo;
