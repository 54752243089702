// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import getPaddingForAspectRatio, { getAspectRatio } from '../../element/resizing/utils/getPaddingForAspectRatio';

// Hooks
import usePrevious from '../../utils/react/usePrevious';
import useUpdateLayoutEffect from '../../utils/react/useUpdateLayoutEffect';

// Components
import ResponsiveImageFACC from './ResponsiveImageFACC';
import ProgressiveImage from './progressiveImages/ProgressiveImage';

// Constants
import { ELEMENT_RESIZE_INTERPOLATION_FACTOR } from '../../element/resizing/store/resizingConstants';

export const getRenderedHeight = ({ savedSize, forcedSize, renderedWidth }) => {
    const targetSize = forcedSize || savedSize;

    if (!targetSize) return null;
    const targetWidth = prop('width', targetSize);
    const targetHeight = prop('height', targetSize);

    const scaleFactor = renderedWidth / targetWidth;

    return targetHeight * scaleFactor;
};

const ResponsiveProgressiveImage = ({
    elementId,
    imageType,
    imageDetails,
    mediaDetails,
    alt,
    widthPx,
    savedWidthPx,
    imageQualityWidthPxOverride,
    onImageLoadCb,
    style,
    forcedSize,
    thumbnailUrl,
    cropToGrid,
    cropInset,
    gridSize,
    useSecureMediaUrl,
    showBrokenIconOnError,
    isPreview,
}) => {
    const thumb = thumbnailUrl || prop('thumb', imageDetails);
    const transparent = prop('transparent', imageDetails);

    const hasMediaDetails = !!prop('height', mediaDetails) && !!prop('width', mediaDetails);
    const savedSize = hasMediaDetails ? mediaDetails : imageDetails;

    const result = getPaddingForAspectRatio({
        savedSize,
        forcedSize,
        roundSaved: false,
        cropToGrid,
        cropInset,
        gridSize,
        widthPx,
    });

    const paddingBottom = result?.paddingBottom || '0%';
    const imageAspectRatio = 1 / getAspectRatio(imageDetails) || 0;

    const [forcedPaddingBottom, setForcedPaddingBottom] = React.useState(null);
    const previousPaddingBottom = usePrevious(paddingBottom);
    const animationIdRef = React.useRef(null);

    useUpdateLayoutEffect(() => {
        if (animationIdRef.current) cancelAnimationFrame(animationIdRef.current);

        const animateLinearly = (fromP, toP) => {
            const from = parseFloat(fromP);
            const to = parseFloat(toP);

            if (!from || Math.abs(to - from) < 0.01) {
                setForcedPaddingBottom(null);
                animationIdRef.current = null;
                return;
            }

            const newFrom = (to - from) * ELEMENT_RESIZE_INTERPOLATION_FACTOR + from;

            const updatedPadding = `${newFrom}%`;
            setForcedPaddingBottom(updatedPadding);

            requestAnimationFrame(() => animateLinearly(newFrom, to));
        };

        const steadyStateWidth = savedWidthPx || widthPx;

        const steadyStateResults = getPaddingForAspectRatio({
            savedSize,
            forcedSize: null,
            roundSaved: false,
            cropToGrid,
            cropInset,
            gridSize,
            widthPx: steadyStateWidth,
        });

        animateLinearly(previousPaddingBottom, steadyStateResults?.paddingBottom || 0);

        return () => {
            // If there's an animation occurring, finish it
            if (animationIdRef.current) cancelAnimationFrame(animationIdRef.current);
        };
    }, [cropToGrid]);

    const renderedHeight = getRenderedHeight({ savedSize, forcedSize, renderedWidth: widthPx });

    return (
        <ResponsiveImageFACC
            elementId={elementId}
            imageType={imageType}
            imageDetails={imageDetails}
            widthPx={widthPx}
            imageQualityWidthPxOverride={imageQualityWidthPxOverride}
            heightPx={renderedHeight}
            useSecureMediaUrl={useSecureMediaUrl}
            isPreview={isPreview}
        >
            {(imageSource) => (
                <ProgressiveImage
                    thumbnail={thumb}
                    source={imageSource}
                    style={style}
                    onImageLoadCb={onImageLoadCb}
                    alt={alt}
                    transparent={transparent}
                    forcedSize={forcedSize}
                    paddingBottom={forcedPaddingBottom || paddingBottom}
                    imageAspectRatio={imageAspectRatio}
                    showBrokenIconOnError={showBrokenIconOnError}
                    isPreview={isPreview}
                />
            )}
        </ResponsiveImageFACC>
    );
};

ResponsiveProgressiveImage.propTypes = {
    imageType: PropTypes.string.isRequired,
    elementId: PropTypes.string,
    imageDetails: PropTypes.object.isRequired,
    mediaDetails: PropTypes.object,
    widthPx: PropTypes.number.isRequired,
    savedWidthPx: PropTypes.number,
    imageQualityWidthPxOverride: PropTypes.number,
    onImageLoadCb: PropTypes.func,
    style: PropTypes.object,
    alt: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    forcedSize: PropTypes.object,
    cropToGrid: PropTypes.bool,
    cropInset: PropTypes.number,
    gridSize: PropTypes.number,
    useSecureMediaUrl: PropTypes.bool,
    showBrokenIconOnError: PropTypes.bool,
    isPreview: PropTypes.bool,
};

export default ResponsiveProgressiveImage;
