// Types
import { IdGraph, PhysicalParentGraph } from '../../../../common/dataStructures/graphTypes';

export const getBoardHierarchiesParentGraph = (
    boardHierarchies: IdGraph,
    columnIdToParentBoardIdMap: PhysicalParentGraph = {},
    elementIdToVirtualParentIdsMap: IdGraph = {},
) => {
    const boardIds = Object.keys(boardHierarchies);
    const boardIdCount = boardIds.length;

    const parentGraph: IdGraph = {};

    for (let i = 0; i < boardIdCount; i++) {
        const boardId = boardIds[i];
        let virtualParentBoardIds = boardHierarchies[boardId];

        if (!virtualParentBoardIds) continue;

        const parentIds = elementIdToVirtualParentIdsMap[boardId];

        if (parentIds) {
            // If the parent board IDs are columns, map their IDs to the column's parent IDs
            virtualParentBoardIds = parentIds.map((parentId) => columnIdToParentBoardIdMap[parentId] || parentId);
        }

        parentGraph[boardId] = virtualParentBoardIds;
    }

    return parentGraph;
};
