import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationReactionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-reaction"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#FFF" />
            <path
                fill="#767C86"
                d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1zm0 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11zm3 6.438c.437 0 .707.476.482.851C10.635 11.202 9.458 11.75 8 11.75c-1.458 0-2.635-.548-3.482-1.96A.562.562 0 0 1 5 8.938h6zM5.75 5.75a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm4.5 0a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationReactionIcon);
export default Memo;
