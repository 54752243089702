// Lib
import { useEffect, useRef, useCallback } from 'react';

const useDocumentKeyEventHandler = ({ onKeyDown, onKeyUp, onKeyPress }) => {
    const eventRefs = useRef({
        onKeyDown,
        onKeyUp,
        onKeyPress,
    });

    useEffect(() => {
        eventRefs.current.onKeyDown = onKeyDown;
        eventRefs.current.onKeyUp = onKeyUp;
        eventRefs.current.onKeyPress = onKeyPress;
    }, [onKeyDown, onKeyUp, onKeyPress]);

    const handleOnKeyDown = (event) => {
        eventRefs.current.onKeyDown && eventRefs.current.onKeyDown(event);
    };

    const handleOnKeyPress = useCallback((event) => {
        eventRefs.current.onKeyPress && eventRefs.current.onKeyPress(event);
    }, []);

    const handleOnKeyUp = useCallback((event) => {
        eventRefs.current.onKeyUp && eventRefs.current.onKeyUp(event);
    }, []);

    const attachListeners = () => {
        // True ensures this handler fires before others (using capture).
        //  Not sure if I should improve this API...
        document.addEventListener('keydown', handleOnKeyDown, true);
        document.addEventListener('keyup', handleOnKeyUp);
        document.addEventListener('keypress', handleOnKeyPress);
    };

    const detachListeners = () => {
        document.removeEventListener('keydown', handleOnKeyDown, true);
        document.removeEventListener('keyup', handleOnKeyUp);
        document.removeEventListener('keypress', handleOnKeyPress);
    };

    useEffect(() => {
        attachListeners();

        return () => {
            detachListeners();
        };
    }, []);
};

export default useDocumentKeyEventHandler;
