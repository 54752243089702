// Utils
import editorGetCurrentBlock from '../../customRichUtils/editorState/editorGetCurrentBlock';
import { updateSelectionBlockMetadata } from '../../customRichUtils/blocks/updateBlockMetadata';

export const handleUpdateBlockMetadata = (state, action) => {
    let editorState = state;
    const { metadata } = action;

    const currentBlock = editorGetCurrentBlock(editorState);
    if (!currentBlock) return editorState;

    const data = currentBlock.getData();
    if (!data) return editorState;

    editorState = updateSelectionBlockMetadata(editorState, metadata);

    return editorState;
};
