import { memoize } from 'lodash';

export const getLocalMonths = memoize((locale): { short: string[]; long: string[]; monthUS: string[] } => {
    const monthsShort = [];
    const monthsLong = [];
    const monthUS = [];
    for (let i = 0; i < 12; i++) {
        const date = new Date(2000, i, 1);
        monthsShort.push(date.toLocaleString(locale, { month: 'short' }).toLowerCase().replace('.', ''));
        monthsLong.push(date.toLocaleString(locale, { month: 'long' }).toLowerCase());
        monthUS.push(date.toLocaleString('en-US', { month: 'long' }).toLowerCase());
    }
    return { short: monthsShort, long: monthsLong, monthUS };
});

export const getLocalWeekdays = memoize((locale): { short: string[]; long: string[] } => {
    const weekdaysShort = [];
    const weekdaysLong = [];
    for (let i = 0; i < 7; i++) {
        const date = new Date(2000, 0, i + 1);
        weekdaysShort.push(date.toLocaleString(locale, { weekday: 'short' }).toLowerCase().replace('.', ''));
        weekdaysLong.push(date.toLocaleString(locale, { weekday: 'long' }).toLowerCase());
    }
    return { short: weekdaysShort, long: weekdaysLong };
});

/**
 * Returns an array of words that are used to connect date words in a date string.
 * For example, in the string "4 de enero" (4 january), the word "de" (of) is a connecting word.
 */
export const getConnectingDateWords = memoize((locale): string[] => {
    const date = new Date(2000, 0, 1);
    const longDate = date.toLocaleString(locale, { dateStyle: 'long' }).toLowerCase().replace(',', '').split(' ');
    if (longDate.length <= 1) return [];

    const months = getLocalMonths(locale);
    const weekdays = getLocalWeekdays(locale);
    const monthsAndWeekdays = [...months.short, ...months.long, ...weekdays.short, ...weekdays.long];

    const connectingWords: string[] = [];
    longDate.forEach((word, index) => {
        if (connectingWords.includes(word)) return;

        const wordIsMonthOrWeekday = monthsAndWeekdays.includes(word);
        const wordIsNumber = !isNaN(Number(word));
        if (!wordIsMonthOrWeekday && !wordIsNumber) connectingWords.push(word);
    });

    return connectingWords;
});

export const getLocalNumberFormattingSymbols = memoize(
    (locale): { thousandsSeparator: string; decimalSeparator: string } => {
        // Some languages don't group by default (e.g. 'es-ES') so we need to set grouping to true
        const numberFormat = new Intl.NumberFormat(locale, { useGrouping: true }).formatToParts(1000.1);
        const thousandsSeparator = numberFormat.find(({ type }) => type === 'group')?.value || ',';
        const decimalSeparator = numberFormat.find(({ type }) => type === 'decimal')?.value || '.';
        return { thousandsSeparator, decimalSeparator };
    },
);
