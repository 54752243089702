// Lib
import Immutable from 'immutable';

// Constants
import { ResourceTypes, RESOURCES_FETCHED } from '../utils/services/http/asyncResource/asyncResourceConstants';
import { CmsEntities } from './cmsConstants';

const RAW_DATA_ENTITIES = new Map();
RAW_DATA_ENTITIES.set(CmsEntities.EMOJI, true);
RAW_DATA_ENTITIES.set(CmsEntities.AI_ASSISTANT_SHORTCUTS, true);

// The rich media state is a map of ID to { contentState }
const initialState = Immutable.Map();

export default (state = initialState, action) => {
    const shouldLoadData = action.type === RESOURCES_FETCHED && action.resource === ResourceTypes.cms;

    if (!shouldLoadData) return state;

    const id = action.ids?.[0];
    const data = action.data;

    // Emoji data & AI assistant shortcuts gets saved as raw

    if (RAW_DATA_ENTITIES.has(id)) return state.set(id, Immutable.Map({ data }));

    return state.set(id, Immutable.fromJS({ data }));
};
