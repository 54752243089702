// Lib
import { LOCATION_CHANGE } from 'react-router-redux';
import * as AUTH_ACTION_TYPES from '../auth/authConstants';

const initialState = {
    locationBeforeTransitions: null,
};

let previousLocationBeforeTransitions = null;

/**
 * From react-router-redux source:
 *
 * This reducer will update the state with the most recent location history
 * has transitioned to. This may not be in sync with the router, particularly
 * if you have asynchronously-loaded routes, so reading from and relying on
 * this state it is discouraged.
 */
export default function routerReducer(state = initialState, action) {
    /* react-router-redux has the following behaviour:
     * - If the routing state is empty (null | undefined) and an action has occurred,
     *   it will reset the location to the initial location
     *
     * This is problematic for us because on logout we clear the entire state.
     * If the initial state was a "login-success" URL - then it will retry adding the token from the URL
     * and fetching the user data again. But if the token is expired the fetch will fail again, causing the
     * session to be logged out and the same loop to occur again.
     *
     * So if we don't have any state, but we've saved a previous location, we should restore the previous
     * location to avoid react-router-redux from sending the browser to the initial URL.
     * We can let the Milanote code handle where the user gets sent instead.
     */
    const shouldPreventClearedLocation =
        previousLocationBeforeTransitions && (state === initialState || action.type === AUTH_ACTION_TYPES.LOGOUT);

    if (shouldPreventClearedLocation) {
        return {
            ...state,
            locationBeforeTransitions: previousLocationBeforeTransitions,
        };
    }

    if (action.type === LOCATION_CHANGE) {
        previousLocationBeforeTransitions = action.payload;
        return {
            ...state,
            locationBeforeTransitions: action.payload,
        };
    }

    return state;
}
