import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ChevronRightOrangeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon chevron-right-orange"
        {...props}
    >
        <path
            fill="#F4511C"
            fillRule="evenodd"
            d="M9.44 8 6.97 5.53a.749.749 0 1 1 1.06-1.06l3 3a.749.749 0 0 1 0 1.06l-3 3a.749.749 0 1 1-1.06-1.06L9.44 8z"
        />
    </svg>
);
const Memo = memo(ChevronRightOrangeIcon);
export default Memo;
