// Lib
import React, { ReactElement } from 'react';
import { ConnectedProps, connect } from 'react-redux';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { registerDownloadSource } from '../../utils/fileDownloader/javascriptToSwiftFileDownloads';
import { isPlatformIos } from '../../platform/utils/platformDetailsUtils';
import { getExportErrorText } from './exportErrorUtils';

// Actions
import { exportElement } from './exportPopupActions';
import { closePopup } from '../../components/popupPanel/popupActions';
import { triggerDownload } from '../../element/attachments/attachmentDownloadActions';

// Components
import ExportPopupExportButton from './ExportPopupExportButton';
import FileIcon from '../../components/fileIcon/FileIcon';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';
import { PopupIds } from '../../components/popupPanel/popupConstants';
import { DOWNLOADING_STATUSES } from './exportConstants';

// Styles
import './ExportPopupExportButton.scss';
import { FileTypeInfo } from '../../../common/files/fileTypes';

type ExportElementArgs = {
    elementId: string;
    format: string;
    fileType: string;
    quality?: string;
    includeChildBoards?: boolean;
};

const mapDispatchToProps = (dispatch: Function) => ({
    dispatchExportElement: ({ elementId, format, fileType, quality, includeChildBoards }: ExportElementArgs) =>
        dispatch(exportElement({ elementId, format, fileType, quality, includeChildBoards })),
    dispatchClosePopup: () => dispatch(closePopup(PopupIds.EXPORT)),
    dispatchTriggerDownload: (args: any) => dispatch(triggerDownload(args)),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type ElementExportPopupButtonProps = PropsFromRedux & {
    children: React.ReactElement | string;
    elementId: string;
    fileType: FileTypeInfo['type'];
    mimeType: string;
    format: string;
    quality?: string;
    disabled?: boolean;
    includeChildBoards?: boolean;
};

const ElementExportPopupButton = (props: ElementExportPopupButtonProps): ReactElement => {
    const {
        children,
        elementId,
        disabled,
        fileType,
        mimeType,
        format,
        quality,
        includeChildBoards,
        dispatchExportElement,
        dispatchClosePopup,
        dispatchTriggerDownload,
    } = props;

    const [status, setStatus] = React.useState<string | null>(null);
    const [errorText, setErrorText] = React.useState<string | null>(null);

    const onClick = async () => {
        setStatus(DOWNLOADING_STATUSES.GENERATING);

        try {
            const exportUrl = await dispatchExportElement({
                elementId,
                format,
                fileType: mimeType,
                quality,
                includeChildBoards,
            });

            registerDownloadSource('.ExportUtil .button-content', { x: 0, y: 30 });

            dispatchTriggerDownload({
                url: exportUrl,
                onDownloadEnd: () => {
                    if (isPlatformIos(platformSingleton)) {
                        dispatchClosePopup();
                    }
                },
                id: elementId,
            });

            setStatus(DOWNLOADING_STATUSES.DOWNLOADING);
        } catch (err) {
            setStatus(DOWNLOADING_STATUSES.ERROR);
            const text = getExportErrorText(err);
            setErrorText(text);
        }

        setTimeout(() => {
            setStatus(null);
        }, TIMES.SECOND * 2);
    };

    return (
        <ExportPopupExportButton status={status} errorText={errorText} onClickFn={onClick} disabled={disabled}>
            <FileIcon fileType={fileType} iconStyle="mini" />

            <span>{children} </span>
        </ExportPopupExportButton>
    );
};

export default connector(ElementExportPopupButton);
