// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

/**
 * Fetch the image pixel data from the image
 *
 * @param {string} url
 */
const fetchImageData = (url) =>
    // FIXME Move function off the client so can be performed for all images,
    // without load on client or facing client compatibility issues
    new Promise((resolve, reject) => {
        // Can't fetch secure images with iPad as cookies can't be sent when cross-origin is anonymous
        if (isPlatformLegacyMobileApp(platformSingleton)) {
            reject('Fetching image data not supported by iPad app');
            return;
        }

        const image = new Image();

        // Set as anonymous to avoid security errors relating to the canvas being tainted by cross-origin image data
        if (!url.startsWith(window.location.origin)) {
            image.crossOrigin = 'Anonymous';
        }

        image.onload = () => {
            const canvas = document.createElement('canvas');

            canvas.width = image.width;
            canvas.height = image.height;

            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            const pixels = context.getImageData(0, 0, image.width, image.height);
            resolve(pixels);
        };

        image.onerror = (err) => reject(err);

        image.src = url;
    });

export default fetchImageData;
