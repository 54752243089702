// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

// Components
import ElementDragPreview from './ElementDragPreview';
import DebugUIPoint from '../../debug/ui/DebugUIPoint';
import DebugUILineVector from '../../debug/ui/DebugUILineVector';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getTranslationStyles } from '../../utils/cssUtil';
import { prop } from '../../../common/utils/immutableHelper';

// Styles
import './ElementDragPreviewPositioner.scss';

class ElementDragPreviewPositioner extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const {
            element,
            elementId,
            item,
            gridSize,
            currentBoardId,
            permissions,
            zoomScaleOnDragSource,
            dndDebugToolProperties,
        } = this.props;

        const unscaledElementOffset = get(['unscaledElementOffsetsMap', getElementId(element)], item);
        const style = unscaledElementOffset ? getTranslationStyles(unscaledElementOffset) : null;

        const debugShowElementOffset = prop('showElementOffset', dndDebugToolProperties);
        const debugShowElementDragPreviewPositioner = prop('showElementDragPreviewPositioner', dndDebugToolProperties);

        return (
            <div className="ElementDragPreviewPositioner" style={style}>
                {debugShowElementDragPreviewPositioner && (
                    <DebugUIPoint color="var(--debug-ui-point-element-drag-preview-positioner-color)" />
                )}

                {unscaledElementOffset && debugShowElementOffset && (
                    <DebugUILineVector
                        vector={unscaledElementOffset}
                        color="var(--debug-ui-line-element-offset-color)"
                    />
                )}

                <ElementDragPreview
                    permissions={permissions}
                    currentBoardId={currentBoardId}
                    elementId={elementId}
                    element={element}
                    isPresentational
                    gridSize={gridSize}
                    zoomScaleOnDragSource={zoomScaleOnDragSource}
                />
            </div>
        );
    }
}

ElementDragPreviewPositioner.propTypes = {
    elementId: PropTypes.string.isRequired,
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number.isRequired,
    currentBoardId: PropTypes.string.isRequired,
    permissions: PropTypes.number,
    item: PropTypes.object,
    zoomScaleOnDragSource: PropTypes.number,
    dndDebugToolProperties: PropTypes.object,
};

export default ElementDragPreviewPositioner;
