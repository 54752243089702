// Utils
import { prop } from '../../common/utils/immutableHelper';

// Constants
import { WORKSPACE_SECTIONS } from './workspaceConstants';

export const getWorkspaceSection = prop('workspaceSection');

export const isWorkspaceSectionCanvas = (obj) => getWorkspaceSection(obj) === WORKSPACE_SECTIONS.CANVAS;
export const isWorkspaceSectionInbox = (obj) => getWorkspaceSection(obj) === WORKSPACE_SECTIONS.INBOX;
export const isWorkspaceSectionTrashPersonal = (obj) => getWorkspaceSection(obj) === WORKSPACE_SECTIONS.TRASH_PERSONAL;
export const isWorkspaceSectionTrashOthers = (obj) => getWorkspaceSection(obj) === WORKSPACE_SECTIONS.TRASH_OTHERS;
export const isWorkspaceSectionQuickNotes = (obj) => getWorkspaceSection(obj) === WORKSPACE_SECTIONS.QUICK_NOTES;
