// Lib
import { useEffect } from 'react';
import { Editor } from '@tiptap/react';

/**
 * When the editor starts editing, ensure the caret is placed in the editor.
 */
const useTiptapEditorFocus = (editor: Editor | null, isEditing: boolean) => {
    useEffect(() => {
        // Reset the text selection when the editor is created.
        // (on some browsers (eg Chrome) it will initialise to a range of 0-2,
        // resulting in a visible highlight when focused even though there's
        // no content)
        editor?.commands.setTextSelection(0);
    }, [editor]);

    useEffect(() => {
        if (!isEditing) return;

        editor?.commands.focus();
    }, [isEditing]);
};

export default useTiptapEditorFocus;
