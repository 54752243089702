// Lib
import * as Immutable from 'immutable';
import { Modifier } from 'draft-js';

// Constants
import { DraftRemovalDirection } from '../draftjsConstants';

/**
 * Much of this code is duplicated from Draft JS's "DraftModifier.removeRange" function.
 * However that function behaves poorly for this scenario as it will collapse the block following
 * the selection into the first block of the selection, even
 */
export default (contentState, rangeToRemove) => {
    const startKey = rangeToRemove.getStartKey();
    const startOffset = rangeToRemove.getStartOffset();
    const endKey = rangeToRemove.getEndKey();
    const endOffset = rangeToRemove.getEndOffset();

    const startBlock = contentState.getBlockForKey(startKey);
    const endBlock = contentState.getBlockForKey(endKey);
    const endBlockLength = endBlock.getLength();

    const blockMap = contentState.getBlockMap();

    const firstBlockKey = blockMap.first().getKey();
    const lastBlockKey = blockMap.last().getKey();

    const removeFromStartOfLine = startOffset === 0;
    const removeToEndOfLine = endOffset === endBlockLength;
    const rangeSpansMultipleBlocks = startKey !== endKey;
    const removeEntireStartLine = removeFromStartOfLine && (rangeSpansMultipleBlocks || removeToEndOfLine);
    const removeEntireEndLine = removeToEndOfLine && (rangeSpansMultipleBlocks || removeFromStartOfLine);

    const selectionIsEntireContent =
        removeFromStartOfLine && firstBlockKey === startKey && removeToEndOfLine && lastBlockKey === endKey;

    // The default draft handling is fine in this scenario
    if ((!removeEntireStartLine && !removeEntireEndLine) || selectionIsEntireContent) {
        return Modifier.removeRange(contentState, rangeToRemove, DraftRemovalDirection.forward);
    }

    // FIXME This currently does not handle immutable entities. For example a mention.
    // Would need to use a method similar to Draft JS's "removeEntitiesAtEdges" function to extend the selection
    // To include these entities

    let targetBlock = null;
    let targetKey = null;

    if (!removeFromStartOfLine || !removeToEndOfLine) {
        const unClippedStartCharacters = startBlock.getCharacterList().slice(0, startOffset);
        const unClippedEndCharacters = endBlock.getCharacterList().slice(endOffset);
        const finalCharacterList = unClippedStartCharacters.concat(unClippedEndCharacters);

        const unClippedStartText = startBlock.getText().slice(0, startOffset);
        const unClippedEndText = endBlock.getText().slice(endOffset);
        const finalText = unClippedStartText + unClippedEndText;

        targetKey = removeFromStartOfLine ? endKey : startKey;
        targetBlock = removeFromStartOfLine ? endBlock : startBlock;
        targetBlock = targetBlock.merge({
            text: finalText,
            characterList: finalCharacterList,
        });
    }

    // This nulls the blocks within the start & end key range so that they are removed from the block map
    const newBlocks = blockMap
        .toSeq()
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat(Immutable.Map([[endKey, null]]))
        .map((_, k) => (k === targetKey ? targetBlock : null));

    const newBlockMap = blockMap.merge(newBlocks).filter((block) => !!block);

    // Either put the cursor at the start of the next line if it exists, or the end of the previous line
    const nextBlock = contentState.getBlockAfter(endKey);
    const prevBlock = contentState.getBlockBefore(startKey);

    let targetSelectionKey;
    let targetSelectionOffset;

    if (!removeFromStartOfLine) {
        targetSelectionKey = startKey;
        targetSelectionOffset = startOffset;
    } else if (!removeToEndOfLine) {
        targetSelectionKey = startKey;
        targetSelectionOffset = 0;
    } else if (nextBlock) {
        targetSelectionKey = nextBlock.getKey();
        targetSelectionOffset = 0;
    } else {
        targetSelectionKey = prevBlock.getKey();
        targetSelectionOffset = prevBlock.getLength();
    }

    const selectionAfter = rangeToRemove.merge({
        anchorKey: targetSelectionKey,
        anchorOffset: targetSelectionOffset,
        focusKey: targetSelectionKey,
        focusOffset: targetSelectionOffset,
        isBackward: false,
    });

    return contentState.merge({
        blockMap: newBlockMap,
        selectionBefore: rangeToRemove,
        selectionAfter,
    });
};
