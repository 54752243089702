// Lib
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import platformSingleton from '../platform/platformSingleton';
import { isDesktopPwa, isIphonePwa } from '../platform/utils/platformAppTypeUtils';

// Constants
import { PWA_APP_INSTALLED } from './store/pwaAppConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchPwaAppInstall: (platform) => dispatch({ type: PWA_APP_INSTALLED, platform }),
});

const PwaDetection = (props) => {
    const { dispatchPwaAppInstall } = props;

    React.useEffect(() => {
        const onAppInstalled = () => {
            // Give the app a chance to complete installation
            setTimeout(() => {
                if (isIphonePwa()) dispatchPwaAppInstall(platformSingleton.type);
                if (isDesktopPwa()) dispatchPwaAppInstall(platformSingleton.type);
            }, 2000);
        };

        window.addEventListener('appinstalled', onAppInstalled);

        return () => {
            window.removeEventListener('appinstalled', onAppInstalled);
        };
    }, []);

    return null;
};

PwaDetection.propTypes = {
    dispatchPwaAppInstall: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(PwaDetection);
