// Lib
import { createSelector } from 'reselect';
import { getUsers } from '../../../../../user/usersSelector';
import { getIsModalOpen } from '../../../../../components/modal/activeModalSelector';
import { activePopupSelector } from '../../../../../components/popupPanel/popupOpenSelector';
import { getHasPushBeenPermanentlyDismissed } from './pushSuggestionService';
import {
    arePushNotificationsAlreadyPermitted,
    arePushNotificationsDenied,
    arePushNotificationsSupported,
} from '../../../../../notifications/push/pushNotificationsManager';
import {
    getShowOneTrustBanner,
    isOneTrustError,
    isOneTrustInitialised,
} from '../../../../../app/oneTrust/oneTrustSelectors';

export const getPushAlreadySuggestedThisSession = (state) =>
    state.getIn(['app', 'popup', 'pushSuggestion', 'alreadySuggestedThisSession']);

export const getShouldShowSuggestionsPopup = createSelector(
    getUsers,
    activePopupSelector,
    getIsModalOpen,
    getPushAlreadySuggestedThisSession,
    isOneTrustInitialised,
    isOneTrustError,
    getShowOneTrustBanner,
    (
        users,
        activePopup,
        isActiveModal,
        pushAlreadySuggested,
        isOneTrustInitialised,
        isOneTrustError,
        showOneTrustBanner,
    ) => {
        const isConnectedToOtherUsers = (users && users.size > 1) || false;

        return (
            !pushAlreadySuggested &&
            !isActiveModal &&
            (!activePopup || activePopup.size === 0) &&
            isConnectedToOtherUsers &&
            arePushNotificationsSupported() &&
            !arePushNotificationsAlreadyPermitted() &&
            !arePushNotificationsDenied() &&
            !getHasPushBeenPermanentlyDismissed() &&
            (isOneTrustInitialised || isOneTrustError) &&
            !showOneTrustBanner
        );
    },
);
