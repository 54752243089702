// Lib
import { createSelector } from 'reselect';

// Utils
import { hasNotChanged } from '../../utils/react/propsComparisons';
import { createCustomEqualDeepSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { getBreadcrumb } from './breadcrumbUtil';

// Selectors
import { getCurrentUserRootBoardId, isGuestSelector } from '../../user/currentUserSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getElements } from '../../element/selectors/elementsSelector';
import { areAliasesInitialised } from '../../app/initialisation/initialisationSelector';
import { getAclIdsSelector } from '../../utils/permissions/permissionsSelector';
import {
    boardIdToAliasIdsMapSelector,
    fullVirtualBoardParentGraphSelector,
} from '../../element/selectors/elementGraphSelector';
import { getFetchedBoardCount } from '../../element/board/boardSelector';

// 1st argument
const currentBoardIdHasNotChanged = hasNotChanged(0);
// 4th argument
const aclIdsHasNotChanged = hasNotChanged(3);
// 5th argument
const fullVirtualBoardParentGraphHasNotChanged = hasNotChanged(5);
// 6th argument
const fetchedBoardCountHasNotChanged = hasNotChanged(6);

/**
 * Do not recalculate, unless:
 * - Board graph has changed (new boards are received or structure has changed)
 * - ACL IDs change (e.g. new tokens are received)
 * - Current board ID changes (navigate to new board)
 * - The number of fetched boards has changed (a board might change from skeleton to fully fetched)
 */
const breadcrumbSelectorEqualityChecker = (prevArgs, nextArgs) =>
    // Deep children mod cache
    fullVirtualBoardParentGraphHasNotChanged(prevArgs, nextArgs) &&
    // ACL IDs haven't changed
    aclIdsHasNotChanged(prevArgs, nextArgs) &&
    // Current board ID
    currentBoardIdHasNotChanged(prevArgs, nextArgs) &&
    fetchedBoardCountHasNotChanged(prevArgs, nextArgs);

const createCustomElementChildrenSelector = createCustomEqualDeepSelector(breadcrumbSelectorEqualityChecker);

// NOTE: The order of the functions is important for the equality checker above
export const breadcrumbSelector = createCustomElementChildrenSelector(
    getCurrentBoardId,
    getCurrentUserRootBoardId,
    getElements,
    getAclIdsSelector,
    boardIdToAliasIdsMapSelector,
    // The following two are simply used in the equality check, they're not used when building the breadcrumb
    fullVirtualBoardParentGraphSelector,
    getFetchedBoardCount,
    getBreadcrumb,
);

/**
 * A detached breadcrumb is only valid if the current user is a guest or the current user has all their
 * aliases loaded.
 */
export const detachedBreadcrumbIsValidSelector = createSelector(
    areAliasesInitialised,
    isGuestSelector,
    (aliasesAreInitialised, isGuest) => isGuest || aliasesAreInitialised,
);
