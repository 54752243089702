import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarDueDateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-due-date"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M20.5 7.25a.75.75 0 0 1 .75.75v1H22a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V11a2 2 0 0 1 2-2h.75V8a.75.75 0 0 1 .648-.743l.102-.007a.75.75 0 0 1 .75.75v1h7.5V8a.75.75 0 0 1 .648-.743zm2 8.25h-13V22a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-6.5zm-.5-5H10a.5.5 0 0 0-.5.5v3h13v-3a.5.5 0 0 0-.5-.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarDueDateIcon);
export default Memo;
