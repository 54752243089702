// Utils
import { isFile, isLink } from './elementTypeUtils';
import { getImageOriginal, getImageRegular, getMediaType, getShowMedia } from './elementPropertyUtils';

// Constants
import { MEDIA_TYPES } from '../../links/richMediaConstants';

/**
 * Determines if an image is showing for a file or link element.
 */
export const isMediaImageShowing = (el) => {
    // If the media is hidden or there's no image to display, then a media image isn't showing
    if (!getShowMedia(el) || !(getImageOriginal(el) || getImageRegular(el))) return false;

    if (isFile(el)) return true;

    if (isLink(el)) return getMediaType(el) !== MEDIA_TYPES.RICH;

    return false;
};
