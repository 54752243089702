// Lib
import * as Immutable from 'immutable';
import { head } from 'lodash';

// Constants
import { ResourceTypes, RESOURCES_FETCHED } from '../../utils/services/http/asyncResource/asyncResourceConstants';

const initialState = Immutable.List();

const handleFetchedUserContacts = (state, action) => {
    if (!action.data) return state;

    const contactType = head(action.ids);
    const contacts = action.data;

    return Immutable.fromJS(contacts.map((contact) => ({ ...contact, contactType })));
};

export default (state = initialState, action) => {
    if (action.type !== RESOURCES_FETCHED || action.resource !== ResourceTypes.contacts) return state;

    return handleFetchedUserContacts(state, action);
};
