import React, { Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../../node_module_clones/recompose';
import classNames from 'classnames';

// Util
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

import measurementsConnect from '../../../components/measurementsStore/measurementsConnect';
import { getMeasurementsMap } from '../../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Selectors
import { elementGraphSelector } from '../../selectors/elementGraphSelector';
import { getElements } from '../../selectors/elementsSelector';
import { getCurrentVisibleBoardId } from '../../../reducers/currentBoardId/currentBoardIdSelector';
import { getGridSize } from '../../../utils/grid/gridSizeSelector';

// Actions
import { navigateToElement } from '../../../reducers/navigationActions';

// Components
import Button from '../../../components/buttons/Button';
import ImageModalFilesizePreloader from './ImageModalFilesizePreloader';

import useOrderedImageElements from './useOrderedImageElements';

// Constants
import { KEY_CODES } from '../../../utils/keyboard/keyConstants';

import './ImageModalNavigation.scss';

const mapMeasurementsStateToProps = (state) => ({
    measurements: getMeasurementsMap(state),
});

const mapStateToProps = createStructuredSelector({
    currentBoardId: getCurrentVisibleBoardId,
    elements: getElements,
    elementGraph: elementGraphSelector,
    gridSize: getGridSize,
});

const mapDispatchToProps = (dispatch) => ({
    navigateToImage: (elementId) => dispatch(navigateToElement({ elementId })),
});

const enhance = compose(measurementsConnect(mapMeasurementsStateToProps), connect(mapStateToProps, mapDispatchToProps));

const ImageModalNavigation = ({
    currentBoardId,
    element,
    showNavigation,
    navigateToImage,
    elements,
    elementGraph,
    measurements,
    uploadingAttachment,
}) => {
    const orderedImageElements = useOrderedImageElements({
        currentBoardId,
        elements,
        elementGraph,
        measurements,
    });

    const currentImageId = getElementId(element);

    const imageIds = orderedImageElements.map(getElementId);

    const currentIndex = imageIds.indexOf(currentImageId);
    const prevIndex = (currentIndex - 1) % imageIds.size;
    const nextIndex = (currentIndex + 1) % imageIds.size;

    const prevImageId = imageIds.get(prevIndex);
    const nextImageId = imageIds.get(nextIndex);

    const keyboardNavigate = useCallback(
        (event) => {
            if (!showNavigation) return;

            switch (event.keyCode) {
                case KEY_CODES.LEFT_ARROW:
                case KEY_CODES.UP_ARROW:
                    if (!prevImageId) return;
                    event.preventDefault();
                    return navigateToImage(prevImageId);
                case KEY_CODES.RIGHT_ARROW:
                case KEY_CODES.DOWN_ARROW:
                    if (!nextImageId) return;
                    event.preventDefault();
                    return navigateToImage(nextImageId);
                default:
                    break;
            }
        },
        [prevImageId, nextImageId, navigateToImage],
    );

    useEffect(() => {
        window.addEventListener('keydown', keyboardNavigate);
        return () => window.removeEventListener('keydown', keyboardNavigate);
    }, [keyboardNavigate]);

    return (
        <Fragment>
            {!uploadingAttachment && <ImageModalFilesizePreloader imagesToPreload={orderedImageElements} />}

            <div
                className={classNames('ImageModalNavigation', {
                    show: showNavigation && orderedImageElements.size >= 2,
                })}
            >
                <Button className="nav left" onClickFn={() => navigateToImage(prevImageId)}>
                    Previous
                </Button>
                <Button className="nav right" onClickFn={() => navigateToImage(nextImageId)}>
                    Next
                </Button>
            </div>
        </Fragment>
    );
};

ImageModalNavigation.propTypes = {
    currentBoardId: PropTypes.string,
    element: PropTypes.object,
    showNavigation: PropTypes.bool,
    prevImageId: PropTypes.string,
    nextImageId: PropTypes.string,
    navigateToImage: PropTypes.func,
    elements: PropTypes.object,
    elementGraph: PropTypes.object,
    measurements: PropTypes.object,
    uploadingAttachment: PropTypes.bool,
};

export default enhance(ImageModalNavigation);
