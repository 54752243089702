import React from 'react';
import Calendar from './Calendar';

import './SimpleDateTimePicker.scss';
import DueDateTimePicker from '../../element/task/pills/assignment/dueDate/DueDateTimePicker';
import PropTypes from 'prop-types';

// This date picker is a simplified version of the one used in the task due date picker.
// It is aimed more at general date/time selection rather than future/due dates

const SimpleDateTimePicker = ({ date, updateDate, hasTime, updateDateHasTime, locale }) => {
    return (
        <div className="SimpleDateTimePicker">
            <div className="calendar-section">
                <Calendar value={date} onChange={updateDate} futureOnly={false} locale={locale} />
            </div>

            <div className="time-section">
                <DueDateTimePicker
                    hasDueDateTime={hasTime}
                    setHasDueDateTime={updateDateHasTime}
                    dueDate={date}
                    setDueDate={updateDate}
                    unsetTimeMessage="Add time…"
                />
            </div>
        </div>
    );
};

SimpleDateTimePicker.propTypes = {
    date: PropTypes.instanceOf(Date),
    updateDate: PropTypes.func,
    hasTime: PropTypes.bool,
    updateDateHasTime: PropTypes.func,
    locale: PropTypes.string,
};

export default SimpleDateTimePicker;
