// Utils
import wrappedUpdateBlockMetadata from '../richUtils/wrappedUpdateBlockMetaData';
import returnFocusToEditor from '../richUtils/returnFocusToEditor';

// Components
import ChecklistEditorBlock from './components/ChecklistEditorBlock';

// Constants
import { CHECKLIST_BLOCK_TYPE } from './checklistConstants';

/**
 * Provides the component to be rendered by the block type as well as any props that go with it.
 */
export default (block, { getEditorState, setEditorState, getProps }) => {
    if (block.getType() !== CHECKLIST_BLOCK_TYPE) return null;

    const props = getProps();

    return {
        component: ChecklistEditorBlock,
        props: {
            isEditable: props.isEditable,
            updateMetadataFn: wrappedUpdateBlockMetadata(getEditorState, setEditorState),
            returnFocusToEditor: returnFocusToEditor(props, { getEditorState, setEditorState }),
            checked: !!block.getData().get('checked'),
        },
    };
};
