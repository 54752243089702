// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './ElementTip.scss';

const ElementTip = (props) => {
    const { className, icon, added, children } = props;

    const iconComponent = icon ? <div className="icon-container">{icon}</div> : null;

    return (
        <div className={classNames('ElementTip', className, { added })}>
            <div className="relative-container">
                <div className="absolute-container">
                    {iconComponent}
                    {children}
                </div>
            </div>
        </div>
    );
};

ElementTip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    added: PropTypes.bool,
    icon: PropTypes.object,
    className: PropTypes.string,
};

export default ElementTip;
