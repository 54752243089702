// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash';

// Utils
import createMarkup from '../../../utils/react/createMarkup';
import { prop } from '../../../../common/utils/immutableHelper';
import getPaddingForAspectRatio from '../../resizing/utils/getPaddingForAspectRatio';

// Styles
import './RichMediaIFrameContent.scss';

class RichMediaIFrameContent extends React.Component {
    constructor(props) {
        super(props);
        this.isReady = false;
    }

    componentDidMount() {
        // Wait two seconds before transitioning to the player in case the load event doesn't fire
        this.mountTimer = delay(this.dispatchReady, 2000);

        if (!this.container) return;

        const iFrame =
            this.container.querySelector('iframe.embedly-embed') ||
            this.container.querySelector('iframe.iframely-embed');
        if (!iFrame) return;

        iFrame.addEventListener('load', this.dispatchReady);
    }

    dispatchReady = () => {
        if (this.isReady) return;

        this.isReady = true;
        this.props.dispatchRichMediaReady();
    };

    render() {
        const { mediaData, tempMediaSize } = this.props;

        if (!mediaData) return null;

        const iFrameContent = prop('html', mediaData);

        const style = getPaddingForAspectRatio({ savedSize: mediaData, forcedSize: tempMediaSize, roundSaved: true });

        return (
            <div
                className="RichMediaIFrameContent"
                ref={(c) => {
                    this.container = c;
                }}
                style={style}
                dangerouslySetInnerHTML={createMarkup(iFrameContent)}
            />
        );
    }
}

RichMediaIFrameContent.propTypes = {
    mediaData: PropTypes.object,
    tempMediaSize: PropTypes.object,
    dispatchRichMediaReady: PropTypes.func,
};

export default RichMediaIFrameContent;
