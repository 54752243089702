import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationColorSwatchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-color-swatch"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#CCC"
                d="M1 1.002C1 .45 1.456 0 1.995 0h8.01c.55 0 .995.456.995 1.002v9.996c0 .553-.456 1.002-.995 1.002h-8.01C1.445 12 1 11.544 1 10.998V1.002Z"
            />
            <path fill="#276E7F" d="M1 .997C1 .447 1.456 0 1.995 0h8.01A1 1 0 0 1 11 .997V8H1V.997Z" />
            <path fill="#FFF" d="M2 8h8v3H2z" />
            <path fill="#318295" d="M2 1h8v7H2z" />
            <path
                fill="#CCC"
                d="M5 5.002C5 4.45 5.456 4 5.995 4h8.01c.55 0 .995.456.995 1.002v9.996c0 .553-.456 1.002-.995 1.002h-8.01C5.445 16 5 15.544 5 14.998V5.002Z"
            />
            <path fill="#C54867" d="M5 4.997c0-.55.456-.997.995-.997h8.01a1 1 0 0 1 .995.997V12H5V4.997Z" />
            <path fill="#FFF" d="M6 12h8v3H6z" />
            <path fill="#E15678" d="M6 5h8v7H6z" />
        </g>
    </svg>
);
const Memo = memo(NotificationColorSwatchIcon);
export default Memo;
