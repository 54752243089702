import React from 'react';
import { Range } from '@tiptap/core';
import { SuggestionOptions } from '@tiptap/suggestion';
import { Editor, posToDOMRect } from '@tiptap/react';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { MentionAttrs } from './Mention';
import { Point } from '../../../maths/geometry/pointTypes';
import { TiptapMentionsSuggesterPopup } from '../../../../client/element/card/tiptap/TiptapMentionsSuggesterPopup';

// TODO-TIPTAP-MENTIONS: Clean these types up once the implementation is more stable
type SuggestionCommand = (data: MentionAttrs) => void;

type SuggestionCallbackProps = {
    editor: Editor;
    range: Range;
    props?: MentionAttrs;
};
type RenderCallbackProps = SuggestionCallbackProps & {
    query: string;
    command: SuggestionCommand;
};

type SuggestionItem = {
    id: string;
    name: string;
};

type SuggestionInitOptions = {
    // These are for the mentions plugin to talk to the outside world
    onMentionCreated: (mention: MentionAttrs) => void;
    onQueryUpdated: (query: string | null) => void;
    onStarted: (position: Point | null) => void;
    onEnded: () => void;
    keyEvents: EventTarget;
};

export type MentionsSuggestionsManager = Omit<SuggestionOptions<SuggestionItem, MentionAttrs>, 'editor'> & {
    // and these are for the outside world to talk to the mentions plugin
    complete: () => void;
    setSelection: (data: MentionAttrs) => void;
};

/**
 * This object serves as a bridge between the main Milanote application state and the internal state of the
 * Tiptap editor. The Mention extension expects an object matching the SuggestionOptions spec as part of its
 * configuration.
 *
 * To help simplify the API, this isn't exposed directly; it should be used via the useSuggestionManager hook below.
 */
const suggestions = ({
    onMentionCreated,
    onQueryUpdated,
    onStarted,
    onEnded,
    keyEvents,
}: SuggestionInitOptions): MentionsSuggestionsManager => {
    let storedCommand: SuggestionCommand | null = null;
    let currentQuery: string | null = '';
    let currentSelection: MentionAttrs | null = null;

    const setQuery = (q: string | null) => {
        currentQuery = q;
        onQueryUpdated?.(q);
    };

    const complete = () => {
        if (!currentQuery) return;
        if (!currentSelection) return;

        storedCommand?.(currentSelection);
    };

    const hidePopup = () => {
        setQuery(null);
        currentSelection = null;
    };

    // TODO-TIPTAP-MENTIONS: Remove this once the implementation is stable
    const enableLog = false;
    const debugLog = enableLog ? () => {} : (msg: string, ...data: any) => console.log(`SUGGEST:${msg}`, ...data);

    return {
        complete: () => {
            complete();
        },

        setSelection: (data: MentionAttrs) => {
            debugLog('SELECTION', data);
            currentSelection = data;
        },

        command: ({ editor, range, props: mentionData }: SuggestionCallbackProps) => {
            debugLog('COMMAND', { editor, range, mentionData }, range);

            if (!mentionData) return;

            // The end point of the provided range is sometimes inaccurate and will leave
            // stray characters in the text. Using the current cursor position is more reliable.
            // (the provided start position is still accurate)
            const mentionRange = {
                from: range.from,
                to: editor.state.selection.$head.pos,
            };

            // TODO-TIPTAP-MENTIONS: Figure out logic for this
            const shouldInsertSpace = false;

            editor
                .chain()
                .insertContentAt(
                    mentionRange,
                    [
                        {
                            type: 'mention',
                            attrs: mentionData,
                        },
                        shouldInsertSpace && {
                            type: 'text',
                            text: ' ',
                        },
                    ].filter(Boolean),
                )
                .focus()
                .run();

            // TODO-TIPTAP-MENTIONS: Handle side effects when creating a new mention (eg generating notifications)
            // (possibly here, possibly in wherever's providing onMentionCreated)
            onMentionCreated?.(mentionData);
        },

        render: () => {
            // This is called when the editor component mounts, not when a lookup begins.
            // Subsequent @'s in the same editor will reuse this same object.

            return {
                onStart: ({ editor, range, command }: RenderCallbackProps) => {
                    debugLog('START', range);

                    // This is called whenever the cursor arrives anywhere in a valid "@foo" string.
                    // After typing @ of course, but also when placing focus, keyboard navigation, etc.
                    // TODO-TIPTAP-MENTIONS: Delay the actual mention lookup until the user types something.
                    storedCommand = command;
                    setQuery(null);

                    const position = posToDOMRect(editor.view, range.from, range.from);
                    onStarted?.({ x: position.x, y: position.y + position.height });

                    // hide the popup when the user clicks away without completing a mention
                    editor.on('blur', hidePopup);
                },

                onUpdate: (props: RenderCallbackProps) => {
                    debugLog('UPDATE', props, props.range);
                    setQuery(props.query);
                },

                onKeyDown: ({ event }: { event: KeyboardEvent }) => {
                    // This function's return value indicates whether the keypress should be
                    // handled by the editor as well (ie, should it type the character?)

                    // If no active query, always follow normal editor behaviour
                    if (!currentQuery) return false;

                    debugLog('KEYDOWN', event.key);

                    if (['Enter', 'Tab'].includes(event.key)) {
                        complete();
                        return true;
                    }

                    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
                        // dispatch as a new event; the EventTarget api doesn't like double-handling
                        keyEvents.dispatchEvent(new KeyboardEvent('keydown', { key: event.key }));
                        return true;
                    }

                    if (event.key === 'Escape') {
                        // TOOD-TIPTAP-MENTIONS: this should leave keyboard focus in the editor
                        // (currently fully deselects the element)
                        setQuery(null);
                        return true;
                    }

                    // TODO-TIPTAP-MENTIONS: non-typing keypresses should not trigger a query
                    return false;
                },

                onExit({ editor }: RenderCallbackProps) {
                    debugLog('EXIT');

                    // clear our local state
                    storedCommand = null;
                    setQuery(null);
                    onEnded?.();

                    editor.off('blur', hidePopup);
                },
            };
        },
    };
};

/**
 * This hook provides a simplified API for the Mention extension's suggestion system.
 * It returns a tuple containing the suggestion manager object to pass to Mention.configure
 * and a ReactNode to render the suggestion popup.
 */
export const useSuggestionsManager: () => [MentionsSuggestionsManager, ReactNode] = () => {
    const [currentQuery, setCurrentQuery] = useState<string | null>(null);
    const [popupPosition, setPopupPosition] = useState<Point | null>(null);

    // The key events will be coming from inside the Tiptap editor, and we want to dispatch
    // the responses to those keypresses *back* into it. Using an EventTarget lets us avoid
    // having to store refs to elements or complex callback chains.
    const keyEvents = useMemo(() => new EventTarget(), []);

    const suggestion = useMemo(
        () =>
            suggestions({
                onMentionCreated: (mention) => {
                    console.log('Mention created:', mention);
                    setCurrentQuery(null);
                    setPopupPosition(null);
                },
                onQueryUpdated: (q) => {
                    setCurrentQuery(q);
                },
                onStarted: (position) => {
                    setPopupPosition(position);
                },
                onEnded: () => {
                    setCurrentQuery(null);
                    setPopupPosition(null);
                },
                keyEvents,
            }),
        [],
    );

    const onSelectUser = useCallback(
        (userDetails: MentionAttrs) => {
            suggestion.setSelection(userDetails);
            suggestion.complete();
        },
        [suggestion],
    );

    const onPreviewUserChanged = useCallback(
        (userDetails: MentionAttrs) => {
            suggestion.setSelection(userDetails);
        },
        [suggestion],
    );

    const mentionsPopup = useMemo(
        () =>
            currentQuery ? (
                <TiptapMentionsSuggesterPopup
                    currentSuggestionQuery={currentQuery}
                    position={popupPosition}
                    onSelectUser={onSelectUser}
                    onPreviewUserChanged={onPreviewUserChanged}
                    keyEvents={keyEvents}
                />
            ) : null,
        [currentQuery, popupPosition, onSelectUser],
    );

    return [suggestion, mentionsPopup];
};
