// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import selectedElements from './selectedElementsReducer';
import commentThreads from '../element/comment/store/commentThreadActivityReducer';

export default combineReducers({
    selectedElements,
    commentThreads,
});
