// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import elementMeasurements from '../elementMeasurements/elementMeasurementsMapReducer';
import pointsOfInterest from '../../pointsOfInterest/poiMapReducer';

export default combineReducers({
    elementMeasurements,
    pointsOfInterest,
});
