// Lib
import * as Immutable from 'immutable';

// Constants
import { ResourceTypes, RESOURCES_FETCHED } from '../../utils/services/http/asyncResource/asyncResourceConstants';

const initialState = true;

export default (state = initialState, action) => {
    if (action.type !== RESOURCES_FETCHED) return state;
    if (action.resource !== ResourceTypes.notifications) return state;

    // Intentional != null as this will mean hasMore is either 'false' or 'true'
    return action?.data?.hasMore != null // eslint-disable-line eqeqeq
        ? Immutable.fromJS(action?.data?.hasMore)
        : state;
};
