// Lib
import * as Immutable from 'immutable';
import { reduce, keys } from 'lodash';
import { get } from 'lodash/fp';

// Utils
import { getUserIdFromAction } from '../../common/actionUtils';

// Reducers
import selectedElement from './selectedElementReducer';

// Constants
import {
    ELEMENTS_SELECTED,
    ELEMENTS_DESELECTED,
    ELEMENTS_DESELECT_ALL,
    ELEMENTS_FORCE_REMOTE_DESELECT,
} from '../../common/elements/selectionConstants';
import { USER_DISCONNECT, USER_ACTIVITY_LOAD, USER_NAVIGATE, USER_ACTIVITY_CLEAR } from '../user/userConstants';

const initialState = Immutable.Map();

const delegateToSelectedElement = (state, action, elementId) =>
    state.set(elementId, selectedElement(undefined, action));

const delegateToEachSelectedElement = (state, action) =>
    action.ids.reduce((newState, elementId) => delegateToSelectedElement(newState, action, elementId), state);

/**
 * When a user disconnects, remove all of their selected element entries.
 */
const clearSelectedElementsForUser = (state, action) => {
    const userId = getUserIdFromAction(action);
    return state.filter((selectedElementEntry) => selectedElementEntry.get('userId') !== userId);
};

/**
 * When a user force a remote user to deselect element, optimistically remove the selection from local state.
 */
const remotelyClearSelectedElementsForUser = (state, action) => {
    const userId = get('userId', action);
    const elementIds = get('ids', action);
    return state.filter(
        (selectedElementEntry, elementId) =>
            !elementIds.includes(elementId) || selectedElementEntry.get('userId') !== userId,
    );
};

const loadUserSelectedElements = (state, action) => {
    const selectedElementIdsMap = reduce(
        action.users,
        (elementIdsMap, activeUserData) => {
            const selectedElementIds = activeUserData.selectedElementIds
                ? activeUserData.selectedElementIds.split(',')
                : [];

            selectedElementIds.forEach((elementId) => {
                elementIdsMap[elementId] = { userId: activeUserData._id };
            });

            return elementIdsMap;
        },
        {},
    );

    const userIdsToClear = [...keys(action.users), ...action.disconnectedUserIds];
    return userIdsToClear
        .reduce((updatedState, userId) => clearSelectedElementsForUser(updatedState, { userId }), state)
        .merge(Immutable.fromJS(selectedElementIdsMap));
};

export default (state = initialState, action) => {
    if (action.type === USER_NAVIGATE) return clearSelectedElementsForUser(state, action);

    // Local actions
    if (action.remote !== true) {
        switch (action.type) {
            case USER_ACTIVITY_LOAD:
                return loadUserSelectedElements(state, action);
            case USER_ACTIVITY_CLEAR:
                return clearSelectedElementsForUser(state, action);
            case ELEMENTS_FORCE_REMOTE_DESELECT:
                return remotelyClearSelectedElementsForUser(state, action);
            default:
                return state;
        }
    }

    // Remote actions
    switch (action.type) {
        case ELEMENTS_SELECTED:
        case ELEMENTS_DESELECTED:
            return delegateToEachSelectedElement(state, action);
        case ELEMENTS_DESELECT_ALL:
            return clearSelectedElementsForUser(state, action);
        case USER_ACTIVITY_CLEAR:
        case USER_DISCONNECT:
            return clearSelectedElementsForUser(state, action);
        default:
            return state;
    }
};
