// Lib
import * as Immutable from 'immutable';

// Constants
import {
    BOARD_NOTIFICATION_PREFERENCES_LOAD,
    BOARD_NOTIFICATION_PREFERENCES_SET,
} from '../../../common/notifications/notificationConstants';

const initialState = Immutable.Map();

const handleNotificationPreferenceLoad = (state, action) => {
    const { boardNotificationPreferences = [] } = action;

    return state.withMutations((mutableState) => {
        boardNotificationPreferences.forEach((notificationPreference) => {
            mutableState.update(notificationPreference.boardId, (existingPreferences) =>
                existingPreferences
                    ? existingPreferences.merge(notificationPreference.preferences)
                    : Immutable.fromJS(notificationPreference.preferences),
            );
        });
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BOARD_NOTIFICATION_PREFERENCES_LOAD:
            return handleNotificationPreferenceLoad(state, action);
        case BOARD_NOTIFICATION_PREFERENCES_SET:
            return state.set(action.boardId, Immutable.fromJS(action.preferences));
        default:
            return state;
    }
};
