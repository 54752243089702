// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import shouldAutoEmbed from './utils/shouldAutoEmbed';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getRichMediaContentState, getRichMediaState } from './richMediaSelector';

// Actions
import { richMediaEmbedding, richMediaError, richMediaReady, richMediaReturnToPreview } from './richMediaActions';

// Constants
import { RICH_MEDIA_STATE } from './richMediaConstants';

const getPreviewableRichMediaProps = (element, contentState) => {
    const embeddingRichMedia = contentState === RICH_MEDIA_STATE.EMBEDDING;
    const showRichMedia = embeddingRichMedia || contentState === RICH_MEDIA_STATE.READY;
    const showError = contentState === RICH_MEDIA_STATE.ERROR;
    const showPreview = !contentState || contentState === RICH_MEDIA_STATE.PREVIEW || embeddingRichMedia || showError;

    return {
        contentState,
        showPreview,
        embeddingRichMedia,
        showRichMedia,
        showError,
    };
};

const getAutoEmbedRichMediaProps = (element, contentState) => {
    const embeddingRichMedia = false;
    const showError = contentState === RICH_MEDIA_STATE.ERROR;
    const showPreview = showError;
    const showRichMedia = !showError;

    return {
        contentState,
        showPreview,
        embeddingRichMedia,
        showRichMedia,
        showError,
    };
};

const mapStateToProps = createSelector(
    (state, ownProps) => ownProps.element,
    getRichMediaState,
    (element, richMediaState) => {
        const elementId = getElementId(element);
        const contentState = getRichMediaContentState(elementId, richMediaState);

        const autoEmbedRichMedia = shouldAutoEmbed(element);

        return autoEmbedRichMedia
            ? getAutoEmbedRichMediaProps(element, contentState)
            : getPreviewableRichMediaProps(element, contentState);
    },
);

const mapDispatchToProps = (dispatch) => ({
    dispatchRichMediaEmbedding: (id) => dispatch(richMediaEmbedding(id)),
    dispatchRichMediaReady: (id) => dispatch(richMediaReady(id)),
    dispatchRichMediaError: (id) => dispatch(richMediaError(id)),
    dispatchRichMediaReturnToPreview: (id) => dispatch(richMediaReturnToPreview(id)),
});

export default (DecoratedComponent) => {
    @connect(mapStateToProps, mapDispatchToProps)
    class richMediaConnectDecorator extends React.Component {
        dispatchRichMediaEmbedding = () => this.dispatchMediaUpdateFn(this.props.dispatchRichMediaEmbedding);

        dispatchRichMediaReady = () => this.dispatchMediaUpdateFn(this.props.dispatchRichMediaReady);

        dispatchRichMediaError = () => this.dispatchMediaUpdateFn(this.props.dispatchRichMediaError);

        dispatchRichMediaReturnToPreview = () =>
            this.dispatchMediaUpdateFn(this.props.dispatchRichMediaReturnToPreview);

        dispatchMediaUpdateFn = (fn) => {
            const { element } = this.props;
            const id = getElementId(element);
            return fn(id);
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    dispatchRichMediaEmbedding={this.dispatchRichMediaEmbedding}
                    dispatchRichMediaReady={this.dispatchRichMediaReady}
                    dispatchRichMediaError={this.dispatchRichMediaError}
                    dispatchRichMediaReturnToPreview={this.dispatchRichMediaReturnToPreview}
                />
            );
        }
    }

    richMediaConnectDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        showPreview: PropTypes.bool,
        dispatchRichMediaEmbedding: PropTypes.func,
        dispatchRichMediaReady: PropTypes.func,
        dispatchRichMediaError: PropTypes.func,
        dispatchRichMediaReturnToPreview: PropTypes.func,
    };

    return richMediaConnectDecorator;
};
