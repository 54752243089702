// Utils
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';

// Constants
import { CLONE_DISPLAY_NAME } from './cloneConstants';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => ({
    ...elementConstructor(params),
    elementType: ElementType.CLONE_TYPE,
});

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.CLONE_TYPE,
        objectConstructorFn,
        displayName: CLONE_DISPLAY_NAME,
        ...params,
    });
