// Constants
import { APP_QUERY_PARAMS } from '../../../common/utils/urlConstants';

export const ELEMENT_FILTER_SET = 'ELEMENT_FILTER_SET';
export const ELEMENT_FILTER_CLEAR = 'ELEMENT_FILTER_CLEAR';

export const ELEMENT_FILTER_TYPES = {
    SEARCH: 'SEARCH',
};

export const ELEMENT_FILTER_CONTEXTS = {
    SEARCH: 'search',
};

export const ELEMENT_QUERY_PARAMS_ARRAY = [
    APP_QUERY_PARAMS.FILTER_IDS,
    APP_QUERY_PARAMS.FILTER_QUERY,
    APP_QUERY_PARAMS.FILTER_CONTEXT,
];
