// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { prop } from '../../../../common/utils/immutableHelper';
import fileIsShowingFilePreview from '../utils/fileIsShowingFilePreview';
import fileGetMediaPlayerType from '../utils/fileGetMediaPlayerType';
import { getElementId, getImageProp } from '../../../../common/elements/utils/elementPropertyUtils';
import previewImageDropTargetDecorator from '../../dnd/elementDropTargets/previewImageDropTargetDecorator';

// Components
import FileMediaPlayer from '../mediaPlayer/FileMediaPlayer';
import FilePreviewImage from '../FilePreviewImage';
import FilePreviewUnavailable from './FilePreviewUnavailable';
import ImageReplacementOverlay from '../../image/ImageReplacementOverlay';

// Styles
import './FilePreview.scss';

const enhance = previewImageDropTargetDecorator();

/**
 * Gets an ID for the React media player depending on the context the video is being played in.
 */
const getPlayerId = ({ element, isModalView, isFocusedForegroundElement }) => {
    const elementId = getElementId(element);

    if (isModalView) return `${elementId}-modal`;

    if (isFocusedForegroundElement) return `${elementId}-focus`;

    return elementId;
};

const FilePreview = (props) => {
    const { element, forcePreview, connectFileDropTarget, isReplaceModeHovered } = props;

    const noFilePreview = !fileIsShowingFilePreview(element) && !forcePreview;

    if (noFilePreview) return null;

    const imageData = getImageProp(element);
    let previewElement;
    const mediaPlayerType = fileGetMediaPlayerType(element);

    if (mediaPlayerType) {
        const playerId = getPlayerId(props);

        previewElement = (
            <FileMediaPlayer {...props} imageData={imageData} playerId={playerId} playerType={mediaPlayerType} />
        );
    } else if (imageData && !prop('hasError', imageData)) {
        previewElement = <FilePreviewImage {...props} />;
    } else {
        previewElement = <FilePreviewUnavailable {...props} />;
    }

    const renderContent = (
        <div className="FilePreview">
            {isReplaceModeHovered && <ImageReplacementOverlay />}
            {previewElement}
        </div>
    );
    return connectFileDropTarget ? connectFileDropTarget(renderContent) : renderContent;
};

FilePreview.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    gridSize: PropTypes.number,
    widthPx: PropTypes.number,
    forcePreview: PropTypes.bool,
    isModalView: PropTypes.bool,
    isFocusedForegroundElement: PropTypes.bool,
    cropToGrid: PropTypes.bool,
    connectFileDropTarget: PropTypes.func,
    isReplaceModeHovered: PropTypes.bool,
};

export const FilePreviewWithImageHover = enhance(FilePreview);

export default FilePreview;
