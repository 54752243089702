export enum LoggerComponents {
    ANALYTICS = 'analytics',
    PERSISTENCE_LAYER = 'persistence-layer',
    PERSISTENCE_LAYER_MIDDLEWARE = 'persistence-layer.middleware',
    PERSISTENCE_LAYER_WORKER = 'persistence-layer.worker',
    PERSISTENCE_LAYER_WORKER_SELECTOR_CALCULATOR = 'persistence-layer.worker.selector-calculator',
    PERSISTENCE_LAYER_WORKER_STORE_INITIALISER = 'persistence-layer.worker.store-initialiser',
    PURGING_SYSTEM = 'purging-system',
    INSTANT_APP = 'instant-app',
    ASYNC_RESOURCE = 'async-resource',
    SERVICE_WORKER = 'service-worker',
    SERVICE_WORKER_REGISTRATION = 'service-worker.registration',
    SERVICE_WORKER_PUSH_NOTIFICATION_MANAGER = 'service-worker.push-notifications-manager',
    REDUX_LOG = 'redux-log',
    REDUX_LOG_MAIN = 'redux-log.main',
    REDUX_LOG_MEASUREMENTS = 'redux-log.measurements',
    REDUX_LOG_COLLAB = 'redux-log.collab',
    REDUX_LOG_EDITOR = 'redux-log.editor',
}

export enum LoggerLevel {
    // Always show errors
    ERROR = 0,
    WARN = 100,
    INFO = 200,
    FINE = 300,
    FINER = 400,
    FINEST = 500,
}
