/**
 * Builds a map from an array where the keys are entries and the value is true.
 * This is useful to do quick lookups for whether an array has an entry.
 */

type KeyIndex = { [key: string]: boolean };

export default (array: string[]): KeyIndex =>
    array.reduce((acc, tool) => {
        acc[tool] = true;
        return acc;
    }, {} as KeyIndex);
