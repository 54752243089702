/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';

// Utils
import { noLonger, now } from '../../utils/react/propsComparisons';
import { shouldShowFeatureSuggestion } from '../feature/elementFeatureUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { alreadySuggested } from '../suggestion/suggestionUtils';

// Selectors
import { getElementLocalData } from '../local/elementLocalDataSelector';

// Actions
import { elementSuggestFeature, elementUnsuggestFeature } from '../suggestion/suggestionActions';

// Constants
import { ELEMENT_FEATURES } from '../feature/elementFeatureConstants';
import { EDITOR_SINGLE_LINE_HEIGHT_GRID_UNITS } from '../../../common/cards/cardConstants';

// Prop comparison functions
const isNowEditing = now('isEditing');
const isNoLongerEditing = noLonger('isEditing');

// 48 is A4 at the default width of 34
const heightIsOverThreshold = (height, gridSize) => height > 48 * gridSize;

const mapStateToProps = createStructuredSelector({
    elementLocalData: getElementLocalData,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSuggestFeature: ({ id, feature }) => dispatch(elementSuggestFeature({ id, feature })),
    dispatchUnsuggestFeature: ({ id, feature }) => dispatch(elementUnsuggestFeature({ id, feature })),
});

export default (DecoratedComponent) => {
    @connect(mapStateToProps, mapDispatchToProps)
    class suggestDocumentDecorator extends React.Component {
        constructor(props) {
            super(props);

            this.initialEditorHeight = null;
            this.editorHeight = null;
        }

        componentWillReceiveProps(nextProps) {
            if (isNowEditing(this.props, nextProps)) {
                this.initialEditorHeight = null;
            }

            // If we stop editing and we've exceeded the height threshold at some point, check to see if
            // we're still over
            if (this.keyPressListenerAttached && isNoLongerEditing(this.props, nextProps)) {
                this.debouncedOnKeypress.flush();
            }
        }

        componentWillUnmount() {
            this.removeKeypressListener();
        }

        onEditorHeightChange = (newHeight) => {
            const { gridSize, element, dispatchUnsuggestFeature, dispatchSuggestFeature, elementLocalData } =
                this.props;

            const editorHeightChange = this.editorHeight ? newHeight - this.editorHeight : 0;
            this.editorHeight = newHeight;

            // If we shouldn't suggest because the user has already acknowledged the suggestion, then don't
            // do anything
            if (!shouldShowFeatureSuggestion(element, ELEMENT_FEATURES.DOCUMENT)) return;

            // If we've already suggested the long form note, but you've now fallen below the threshold again
            // remove the suggestion
            if (alreadySuggested(elementLocalData, ELEMENT_FEATURES.DOCUMENT)) {
                if (!heightIsOverThreshold(newHeight, gridSize)) {
                    dispatchUnsuggestFeature({ id: getElementId(element), feature: ELEMENT_FEATURES.DOCUMENT });
                }

                return;
            }

            // Don't handle the first height change when editing
            if (!this.initialEditorHeight) {
                this.initialEditorHeight = newHeight;
                return;
            }

            // Must be a paste as the height has increased by multiple lines, so open the popup immediately
            if (
                heightIsOverThreshold(newHeight, gridSize) &&
                editorHeightChange > EDITOR_SINGLE_LINE_HEIGHT_GRID_UNITS * gridSize
            ) {
                dispatchSuggestFeature({ id: getElementId(element), feature: ELEMENT_FEATURES.DOCUMENT });
                return;
            }

            // Don't need to keep attaching the keypress listener if we have already
            if (this.keyPressListenerAttached) return;

            // If the editor is over the threshold and the height is larger than the previous height
            // Then wait the debounce to show the message
            if (heightIsOverThreshold(newHeight, gridSize) && editorHeightChange > 0) {
                this.attachKeypressListener();
            }
        };

        attachKeypressListener = () => {
            if (this.keyPressListenerAttached) return;

            this.keyPressListenerAttached = true;
            this.editorContainerElement.addEventListener('keydown', this.debouncedOnKeypress);
        };

        removeKeypressListener = () => {
            if (!this.keyPressListenerAttached) return;

            this.keyPressListenerAttached = false;

            this.editorContainerElement.removeEventListener('keydown', this.debouncedOnKeypress);
        };

        onKeypress = () => {
            const { dispatchSuggestFeature, element, gridSize } = this.props;

            this.removeKeypressListener();

            // If we're not over the threshold anymore then don't suggest anything
            if (!heightIsOverThreshold(this.editorHeight, gridSize)) return;

            dispatchSuggestFeature({ id: getElementId(element), feature: ELEMENT_FEATURES.DOCUMENT });
        };

        debouncedOnKeypress = debounce(this.onKeypress, 1000);

        containerRef = (c) => {
            const { containerRef } = this.props;
            this.editorContainerElement = c;
            containerRef && containerRef(c);
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    containerRef={this.containerRef}
                    onHeightChange={this.onEditorHeightChange}
                />
            );
        }
    }

    suggestDocumentDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        elementLocalData: PropTypes.object,
        isEditing: PropTypes.bool,
        gridSize: PropTypes.number,

        dispatchSuggestFeature: PropTypes.func,
        dispatchUnsuggestFeature: PropTypes.func,

        containerRef: PropTypes.func,
    };

    return suggestDocumentDecorator;
};
