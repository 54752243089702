import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AiRefreshIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon ai-refresh"
        {...props}
    >
        <path
            fill="#2F63F7"
            fillRule="evenodd"
            d="M8 2c1.894 0 3.633.885 4.751 2.335L12.75 3a.5.5 0 0 1 .41-.492l.09-.008a.5.5 0 0 1 .5.5v2.5a.5.5 0 0 1-.5.5h-2.5a.5.5 0 1 1 0-1h1.251a5 5 0 1 0 .975 3.492.5.5 0 1 1 .995.098A6 6 0 1 1 8 2z"
        />
    </svg>
);
const Memo = memo(AiRefreshIcon);
export default Memo;
