// Lib
import { first, isEqual } from 'lodash/fp';
import Immutable from 'immutable';

// Utils
import logger from '../logger/logger';
import * as pointLib from '../../common/maths/geometry/point';
import * as rectLib from '../../common/maths/geometry/rect';
import { isLine } from '../../common/elements/utils/elementTypeUtils';
import { getX, getY } from '../../common/maths/geometry/dimensionUtil';
import { getCanvasDocumentBottomRightPaddingGridUnits } from './utils/canvasSizeUtils';
import { isLocationCanvas } from '../../common/elements/utils/elementLocationUtils';
import { getElements } from '../element/selectors/elementSelector';
import { getClosestUpBoardChildId, getElement } from '../../common/elements/utils/elementTraversalUtils';
import { asObject, getMany, prop, propIn } from '../../common/utils/immutableHelper';
import { getZoomContentRect } from './zoom/zoomScaleUtils';
import { getScrollableAreaSizeFromContentRect } from './zoom/zoomScrollAreaSizeUtils';
import { getHeight, getWidth } from '../../common/maths/geometry/rect';
import {
    getElementId,
    getImageHeight,
    getImageWidth,
    getLocationParentId,
    getPosition,
    getXPosition,
    getYPosition,
} from '../../common/elements/utils/elementPropertyUtils';

// Selectors
import getGridSize from '../utils/grid/gridSizeSelector';
import { getCanvasSize } from './store/canvasSizeSelector';
import {
    canvasZoomModeSelector,
    canvasZoomScaleSelector,
    canvasZoomTranslationPxSelector,
} from './store/canvasZoomSelector';
import { getCurrentBoardId } from '../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentBoardInitialCanvasOrigin } from './store/canvasInitialStateSelector';
import { getCurrentUser } from '../user/currentUserSelector';
import { getScrollAreaSize } from './store/scrollAreaSizeSelector';
import {
    getElementMeasurements,
    getMeasurementsMap,
} from '../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';
import {
    currentBoardCanvasElementsSelector,
    getCurrentVisibleBoardCanvasOrigin,
    getCurrentBoardCanvasOriginPx,
    getCurrentBoardChildren,
    getCurrentBoardVisibleDescendantIds,
} from '../element/selectors/currentBoardSelector';

// Actions
import { setCanvasScrollAreaSize, setCanvasSize } from './store/canvasActions';
import { errorModalOpen } from '../components/error/modals/errorModalActions';
import { updateBoardCanvasOrigin } from '../element/board/boardActions';

// Measurement Actions
import { createBatchAction } from '../store/reduxBulkingMiddleware';
import { measurementsSet } from '../components/measurementsStore/elementMeasurements/elementMeasurementsActions';

// Errors
import { manuallyReportError } from '../analytics/rollbarService';
import CanvasSizeError from './CanvasSizeError';

// Constants
import { ZOOM_MODES } from './zoom/zoomConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_DEFAULT_WIDTH,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
    ELEMENT_UPDATE_TYPE,
} from '../../common/elements/elementConstants';
import { CARD_DEFAULT_HEIGHT_GRID_UNITS } from '../../common/cards/cardConstants';
import { SKETCH_DEFAULT_HEIGHT } from '../../common/drawings/sketches/sketchConstants';
import { COLUMN_DEFAULT_HEIGHT_IN_GRID_UNIT } from '../../common/columns/columnConstants';
import { DEFAULT_LINE_HEIGHT, DEFAULT_LINE_WIDTH } from '../../common/lines/lineConstants';
import { DEFAULT_ICON_VIEW_HEIGHT, DEFAULT_ICON_VIEW_WIDTH } from '../../common/elements/elementDisplayModeConstants';
import { IMAGE_PLACEHOLDER_DEFAULT_HEIGHT } from '../../common/images/imageConstants';
import {
    COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
    COLOR_SWATCH_DEFAULT_WIDTH_GRID,
} from '../../common/colorSwatches/colorSwatchesConstants';
import {
    DEFAULT_CANVAS_ORIGIN,
    MAXIMUM_CANVAS_OVERFLOW_INCREMENT,
    MAXIMUM_POSITION_VALUE,
    MINIMUM_CANVAS_ORIGIN,
} from '../../common/elements/utils/elementPositionUtils';
import { BOARD_ERROR } from '../../common/error/errorConstants';

// Types
import { ReduxStore } from '../types/reduxTypes';
import { Point } from '../../common/maths/geometry/pointTypes';
import { Rect, RectSize } from '../../common/maths/geometry/rect/rectTypes';
import { ActionMove, ActionObject } from '../../common/actions/actionTypes';
import { ElementType } from '../../common/elements/elementTypes';

interface MeasurementsMap {
    [id: string]: Rect;
}

interface Measurement extends Rect {
    rects: Array<Rect>;
}

interface ClientActionObject extends ActionObject {
    initialMeasurements?: MeasurementsMap;
}

const CANVAS_TOP_LEFT_PADDING_GRID_UNITS = 5;
const CANVAS_BOUNDARY_THRESHOLD = 10;

/**
 * The new scroll area size must not be smaller than the existing scroll area size after a move or create.
 */
const getMinimumScrollAreaSize = (
    expectedScrollAreaSize: RectSize,
    currentScrollAreaSize: RectSize | null,
): RectSize => {
    const currentSize = currentScrollAreaSize || { height: 0, width: 0 };

    return {
        width: Math.ceil(Math.max(getWidth(expectedScrollAreaSize), getWidth(currentSize))),
        height: Math.ceil(Math.max(getHeight(expectedScrollAreaSize), getHeight(currentSize))),
    };
};

/**
 * The new scroll area size should include the the full content within its boundaries.
 */
const getZoomToFitNewScrollAreaSize = (
    zoomScale: number,
    zoomTranslation: Point,
    currentScrollAreaSize: RectSize | null,
    contentRect: Rect,
): RectSize => {
    let transformedContentRect: Rect = rectLib.scale(zoomScale, rectLib.translate(zoomTranslation, contentRect));

    // We want the content rect to extend to the top left
    transformedContentRect = rectLib.addMargins(
        {
            top: transformedContentRect.top,
            left: transformedContentRect.left,
        },
        transformedContentRect,
    );

    return getMinimumScrollAreaSize(transformedContentRect, currentScrollAreaSize);
};

/**
 * Determines the distance to the bottom right corner from the top left for a new image card.
 */
const getNewImageBottomRightTranslation = (action: ClientActionObject, gridSize: number): Point => {
    const imageWidth = getImageWidth(action);
    const imageHeight = getImageHeight(action);

    if (!imageWidth || !imageHeight) {
        return { x: ELEMENT_DEFAULT_WIDTH, y: IMAGE_PLACEHOLDER_DEFAULT_HEIGHT };
    }

    const renderedWidthGu = Math.min(imageWidth / gridSize, ELEMENT_DEFAULT_WIDTH);

    return {
        x: renderedWidthGu,
        y: (imageHeight / imageWidth) * renderedWidthGu,
    };
};

/**
 * Determines the translation from the top left to the bottom right for a new element of a given type.
 */
const getNewElementBottomRightTranslationGridUnits = (action: ClientActionObject, gridSize: number): Point => {
    switch (action.elementType) {
        case ElementType.BOARD_TYPE:
        case ElementType.ALIAS_TYPE:
        case ElementType.DOCUMENT_TYPE:
            return { x: DEFAULT_ICON_VIEW_WIDTH, y: DEFAULT_ICON_VIEW_HEIGHT };
        case ElementType.LINE_TYPE:
            return { x: DEFAULT_LINE_WIDTH, y: DEFAULT_LINE_HEIGHT };
        case ElementType.COLOR_SWATCH_TYPE:
            return { x: COLOR_SWATCH_DEFAULT_WIDTH_GRID, y: COLOR_SWATCH_DEFAULT_HEIGHT_GRID };
        case ElementType.IMAGE_TYPE:
            return getNewImageBottomRightTranslation(action, gridSize);
        case ElementType.SKETCH_TYPE:
            return { x: ELEMENT_DEFAULT_WIDTH, y: SKETCH_DEFAULT_HEIGHT };
        case ElementType.COLUMN_TYPE:
            return { x: ELEMENT_DEFAULT_WIDTH, y: COLUMN_DEFAULT_HEIGHT_IN_GRID_UNIT };
        default:
            return { x: ELEMENT_DEFAULT_WIDTH, y: CARD_DEFAULT_HEIGHT_GRID_UNITS };
    }
};

/**
 * Determines the bottom right position in pixels within the canvas document coordinate system of
 * a newly created element.
 */
const getElementRectPx = (
    creationAction: ClientActionObject,
    creationPointGridUnits: Point,
    gridSize: number,
    canvasOrigin: Point = DEFAULT_CANVAS_ORIGIN,
): Rect => {
    const newElementBottomRightTranslation = getNewElementBottomRightTranslationGridUnits(creationAction, gridSize);

    const topLeft = pointLib.difference(canvasOrigin, creationPointGridUnits);
    const bottomRight = pointLib.translate(topLeft, newElementBottomRightTranslation);

    const elementRect = rectLib.asRect({
        top: topLeft.y,
        left: topLeft.x,
        bottom: bottomRight.y,
        right: bottomRight.x,
    });

    return rectLib.scale(gridSize, elementRect);
};

/**
 * Determines the distance that the specified rectangle is overflowing the canvas rectangle to the top left.
 */
const getCanvasOriginOverflow = (rect: Rect, canvasRect: Rect, gridSize: number): Point => {
    const canvasTopLeft = rectLib.getTopLeft(canvasRect);
    const rectTopLeft = rectLib.getTopLeft(rect);

    const overflowGridUnits: Point = pointLib.scale(1 / gridSize, pointLib.difference(canvasTopLeft, rectTopLeft));

    return pointLib.getPoint(
        Math.round(Math.min(overflowGridUnits.x, 0)),
        Math.round(Math.min(overflowGridUnits.y, 0)),
    );
};

/**
 * Ensures that the canvas document includes the specified rect by changing the canvas origin and growing the
 * canvas size as appropriate.
 */
const resizeCanvasDocumentToIncludeRect =
    (rect: Rect, transactionId: string | undefined) => (dispatch: Function, getState: Function) => {
        const state = getState();

        const currentBoardId = getCurrentBoardId(state);
        const gridSize = getGridSize(state);
        const zoomMode = canvasZoomModeSelector(state);
        const zoomScale = canvasZoomScaleSelector(state);
        const zoomTranslation: Point = asObject(canvasZoomTranslationPxSelector(state));
        const canvasSize = getCanvasSize(state);
        const canvasRect = rectLib.asRect(canvasSize);
        const currentBoardCanvasOrigin = asObject<Point>(getCurrentVisibleBoardCanvasOrigin(state));

        // If we're in the zoom to fit mode we might need to grow the scroll area to include the newly created
        //  element and some padding.
        if (zoomMode === ZOOM_MODES.ZOOM_TO_FIT) {
            const currentScrollAreaSize: RectSize = asObject(getScrollAreaSize(state) || {});

            const paddedRect = rectLib.addMargins({ bottom: 3 * gridSize, right: 3 * gridSize }, rect);
            const newScrollAreaSize = getZoomToFitNewScrollAreaSize(
                zoomScale,
                zoomTranslation,
                currentScrollAreaSize,
                paddedRect,
            );

            const isEqualScrollArea =
                getWidth(currentScrollAreaSize) === getWidth(newScrollAreaSize) &&
                getHeight(currentScrollAreaSize) === getHeight(newScrollAreaSize);

            if (!isEqualScrollArea) {
                dispatch(setCanvasScrollAreaSize(newScrollAreaSize));
            }
        }

        // If the creation point is outside the canvas document, then grow the canvas document first
        // to prevent some rendering bugs
        // @ts-ignore - TypeScript struggles with curried functions
        if (rectLib.containsRect(canvasRect, rect)) return;

        // If the position is less than the canvasOrigin, then change the canvasOrigin & subsequently
        //  grow the canvas size by the amount it changes, as well (on top of the change below).
        const canvasOriginOverflowGridUnits = getCanvasOriginOverflow(rect, canvasRect, gridSize);

        const canvasOriginTranslationGridUnits = { x: 0, y: 0 };

        if (canvasOriginOverflowGridUnits.x) {
            canvasOriginTranslationGridUnits.x = canvasOriginOverflowGridUnits.x - CANVAS_TOP_LEFT_PADDING_GRID_UNITS;
        }

        if (canvasOriginOverflowGridUnits.y) {
            canvasOriginTranslationGridUnits.y = canvasOriginOverflowGridUnits.y - CANVAS_TOP_LEFT_PADDING_GRID_UNITS;
        }

        if (canvasOriginTranslationGridUnits.x || canvasOriginTranslationGridUnits.y) {
            const updatedState = getState();
            const scrollAreaSize: RectSize = asObject(getScrollAreaSize(updatedState) || {});

            const newScrollAreaSize = {
                width: getWidth(scrollAreaSize) - canvasOriginOverflowGridUnits.x * gridSize,
                height: getHeight(scrollAreaSize) - canvasOriginOverflowGridUnits.y * gridSize,
            };

            dispatch(setCanvasScrollAreaSize(newScrollAreaSize));

            const newCanvasOrigin = pointLib.translate(currentBoardCanvasOrigin, canvasOriginTranslationGridUnits);

            dispatch(updateBoardCanvasOrigin({ id: currentBoardId, canvasOrigin: newCanvasOrigin, transactionId }));
        }

        const canvasPadding = getCanvasDocumentBottomRightPaddingGridUnits(zoomScale);

        const newCanvasSize = {
            width: Math.max(
                rectLib.getWidth(canvasSize) - canvasOriginTranslationGridUnits.x * gridSize,
                rect.right + (canvasPadding.x - canvasOriginTranslationGridUnits.x) * gridSize,
            ),
            height: Math.max(
                rectLib.getHeight(canvasSize) - canvasOriginTranslationGridUnits.y * gridSize,
                rect.bottom + (canvasPadding.y - canvasOriginTranslationGridUnits.y) * gridSize,
            ),
        };

        // scrollAreaSize doesn't need to be provided here because creates will never reduce
        // the space of the scroll area size, and that's the only thing that we're currently
        // using the persisting of the scroll area size for
        // @ts-ignore - Doesn't require scrollAreaSize
        dispatch(setCanvasSize({ canvasSize: newCanvasSize }));
    };

/**
 * On creation, handle differently:
 * - Element types
 * - Zoom levels
 */
const handleElementCreate = (action: ClientActionObject, store: ReduxStore) => {
    if (action.remote) return;

    const { dispatch, getState } = store;

    const state = getState();

    const gridSize = getGridSize(state);

    const currentBoardId = getCurrentBoardId(state);

    const currentBoardCanvasOrigin: Point = asObject(getCurrentVisibleBoardCanvasOrigin(state));

    const actionParentId = getLocationParentId(action);

    // Only change the canvas size if a new element is created on the current canvas
    if (actionParentId !== currentBoardId || !isLocationCanvas(action)) return;

    // Get location
    const creationPointGridUnits = pointLib.getPoint(getXPosition(action), getYPosition(action));
    const newElementRectPx = getElementRectPx(action, creationPointGridUnits, gridSize, currentBoardCanvasOrigin);

    dispatch(resizeCanvasDocumentToIncludeRect(newElementRectPx, action.transactionId));
};

/**
 * Determines what the value should be for the "right" or "bottom" dimensions.
 * If the minimum canvas rectangle is beyond the canvas element rectangle, then leave it at the
 * minimum canvas rectangle, otherwise use the canvas element rectangle plus a given padding.
 */
const getNewCanvasRectMaxDimension = (
    currentCanvasDocumentDimension: number,
    canvasElementBoundingRectDimension: number,
    elementPadding: number,
): number => {
    if (currentCanvasDocumentDimension >= canvasElementBoundingRectDimension) return currentCanvasDocumentDimension;

    return canvasElementBoundingRectDimension + elementPadding;
};

/**
 * Determines the new canvas origin to use for a particular dimension.
 */
const getNewCanvasOriginDimension = (
    canvasElementBoundingDimension: number,
    currentCanvasOriginDimension: number,
    minimumCanvasOriginDimension: number,
    gridSize: number,
): number => {
    // If the canvas element bounding rect is within the minimum bounding rect and the origin is not
    // currently at the minimum
    const shouldShrinkToMinimum = canvasElementBoundingDimension > minimumCanvasOriginDimension;
    if (shouldShrinkToMinimum) return minimumCanvasOriginDimension;

    const shouldUsePaddedCanvasElementBounds =
        // If the canvas element bounding rect has extended beyond the current origin then we need
        // to push the current origin out
        canvasElementBoundingDimension < currentCanvasOriginDimension ||
        // The canvas element bounding rect is more than 10 grid points away from the current origin
        canvasElementBoundingDimension - CANVAS_BOUNDARY_THRESHOLD * gridSize > currentCanvasOriginDimension;

    if (shouldUsePaddedCanvasElementBounds) {
        return canvasElementBoundingDimension - CANVAS_TOP_LEFT_PADDING_GRID_UNITS * gridSize;
    }

    return currentCanvasOriginDimension;
};

/**
 * Determine the new bounding rect for a moved element.
 */
const getMoveBoundingRect = (
    move: ActionMove,
    elementMeasurement: Rect,
    canvasOrigin: Point = DEFAULT_CANVAS_ORIGIN,
    gridSize: number,
): Rect => {
    let movePositionGridUnits = pointLib.getPoint(getXPosition(move), getYPosition(move));
    movePositionGridUnits = pointLib.difference(canvasOrigin, movePositionGridUnits);

    const movePositionPx = pointLib.scale(gridSize, movePositionGridUnits);

    return rectLib.asRect({
        ...movePositionPx,
        width: rectLib.getWidth(elementMeasurement),
        height: rectLib.getHeight(elementMeasurement),
    });
};

/**
 *  Update the canvas element measurements based on the moves
 *  - Elements moved onto the canvas get their top left positions updated based on the move
 *  - Elements moved into columns should update the column's height
 *  - Elements moved out of columns should subtract the column's height
 */
const updateMeasurementsMapForMoves = (
    initialMoveMeasurements: MeasurementsMap | undefined,
    initialMeasurementsMap: Immutable.Map<string, Rect>,
    initialCanvasElementMeasurementsMap: Immutable.Map<string, Rect>,
    // Fixme need an MNElement type
    elements: Immutable.Map<string, unknown>,
    moves: Array<ActionMove>,
    currentBoardId: string,
    currentBoardCanvasOrigin: Point,
    gridSize: number,
): Immutable.Map<string, Rect> =>
    initialCanvasElementMeasurementsMap.withMutations((mutableMeasurements: Immutable.Map<string, Rect>) => {
        moves.reduce((acc, move) => {
            if (!move?.location?.position) return acc;

            const { id } = move;

            const element = getElement(elements, id);

            if (!element) return acc;

            // FIXME - Lines - we need to get the bounding box of the lines in order to
            //  correctly grow the canvas. For now just ignore them as they might not match their actual
            //  position on the board
            if (isLine(element)) return acc;

            const moveParentId = getLocationParentId(move);
            // @ts-ignore - TypeScript struggles with curried functions
            const moveSourceParentId = propIn(['from', 'parentId'], move);

            const elementMeasurement =
                // On a move between boards the moved element's measurements won't be in the measurements map,
                // so we grab them from the action if they're there
                initialMoveMeasurements?.[id] ||
                // @ts-ignore - TypeScript struggles with curried functions
                prop(id, initialMeasurementsMap);

            if (!elementMeasurement) return acc;

            const isMovingDirectlyOntoCurrentBoardCanvas = moveParentId === currentBoardId && isLocationCanvas(move);
            const isMovingOffCurrentBoardCanvas =
                !isMovingDirectlyOntoCurrentBoardCanvas && moveSourceParentId === currentBoardId;

            // If moving onto the canvas, update the measurement in the map
            if (isMovingDirectlyOntoCurrentBoardCanvas) {
                const elementRect = getMoveBoundingRect(move, elementMeasurement, currentBoardCanvasOrigin, gridSize);
                acc.set(id, elementRect);
            }

            // If moving off the canvas, remove the measurement from the map
            if (isMovingOffCurrentBoardCanvas) {
                acc.delete(id);
            }

            const targetLocationBoardChildId = getClosestUpBoardChildId(elements, moveParentId);
            const targetLocationBoardChild = getElement(elements, targetLocationBoardChildId);

            // If moving onto a canvas child, we need to increase the size of the canvas child by the size of
            // the moved element
            const isMovingOntoACanvasChild =
                targetLocationBoardChild !== element &&
                getLocationParentId(targetLocationBoardChild) === currentBoardId &&
                isLocationCanvas(targetLocationBoardChild);

            if (isMovingOntoACanvasChild) {
                // @ts-ignore - TypeScript struggles with curried functions
                const boardChildMeasurement: Rect = prop(targetLocationBoardChildId, acc);

                if (boardChildMeasurement) {
                    let columnRect = rectLib.asRect(boardChildMeasurement);
                    columnRect = rectLib.addMargins(
                        {
                            top: 0,
                            left: 0,
                            right: 0,
                            // @ts-ignore - TypeScript struggles with curried functions
                            bottom: prop('height', elementMeasurement),
                        },
                        columnRect,
                    );

                    acc.set(targetLocationBoardChildId, columnRect);
                }
            }

            // If we're moving out of a column we need to reduce the size of the column by the element's height
            const sourceLocationBoardChildId = getClosestUpBoardChildId(elements, moveSourceParentId);
            const sourceLocationBoardChild = getElement(elements, sourceLocationBoardChildId);

            const isMovingOutOfACanvasChild =
                sourceLocationBoardChild !== element &&
                getLocationParentId(sourceLocationBoardChild) === currentBoardId &&
                isLocationCanvas(sourceLocationBoardChild);

            if (isMovingOutOfACanvasChild) {
                // @ts-ignore - TypeScript struggles with curried functions
                const boardChildMeasurement: Rect = prop(targetLocationBoardChildId, acc);

                if (boardChildMeasurement) {
                    let columnRect = rectLib.asRect(boardChildMeasurement);
                    columnRect = rectLib.addMargins(
                        {
                            top: 0,
                            left: 0,
                            right: 0,
                            // @ts-ignore - TypeScript struggles with curried functions
                            bottom: -prop('height', elementMeasurement),
                        },
                        columnRect,
                    );

                    acc.set(targetLocationBoardChildId, columnRect);
                }
            }

            return acc;
        }, mutableMeasurements);

        return mutableMeasurements;
    });

/**
 * Calculates the expected new scroll area size and returns it if it's changed, otherwise returns undefined.
 */
const getNewScrollAreaSize = (
    canvasElementsBoundingRect: Rect,
    canvasDocumentSize: RectSize,
    currentBoardCanvasOriginPx: Point,
    newCanvasOriginPx: Point,
    store: ReduxStore,
): RectSize | undefined => {
    const { getState } = store;

    const state = getState();

    const zoomMode = canvasZoomModeSelector(state);

    const zoomScale = canvasZoomScaleSelector(state);

    // Don't worry about setting the new scroll area size when not zoomed out
    if (zoomScale >= 1 && zoomMode !== ZOOM_MODES.ZOOM_TO_FIT) return;

    const zoomTranslationPx: Point = asObject(canvasZoomTranslationPxSelector(state));
    const currentScrollAreaSize: RectSize = asObject(getScrollAreaSize(state) || {});

    const contentRect = getZoomContentRect(canvasElementsBoundingRect, canvasDocumentSize);

    let newScrollAreaSize;

    if (zoomMode === ZOOM_MODES.ZOOM_TO_FIT) {
        newScrollAreaSize = getZoomToFitNewScrollAreaSize(
            zoomScale,
            zoomTranslationPx,
            currentScrollAreaSize,
            contentRect,
        );
    } else {
        const expectedScrollAreaSize = getScrollableAreaSizeFromContentRect(contentRect, zoomScale, zoomTranslationPx);

        if (!expectedScrollAreaSize) return undefined;
        if (!currentScrollAreaSize) return expectedScrollAreaSize;

        const originChange = pointLib.difference(newCanvasOriginPx, currentBoardCanvasOriginPx);

        if (getX(originChange) > 0) {
            expectedScrollAreaSize.width += 2 * getX(originChange);
        }

        if (getY(originChange) > 0) {
            expectedScrollAreaSize.height += 2 * getY(originChange);
        }

        newScrollAreaSize = getMinimumScrollAreaSize(expectedScrollAreaSize, currentScrollAreaSize);
    }

    const isEqualScrollArea =
        getWidth(currentScrollAreaSize) === getWidth(newScrollAreaSize) &&
        getHeight(currentScrollAreaSize) === getHeight(newScrollAreaSize);

    // If the scroll areas are equal don't return a new scroll area, this helps with our
    // logic for dispatching the canvasSetSize action
    if (isEqualScrollArea) return undefined;

    return newScrollAreaSize;
};

/**
 * Updates the canvas origin after the move only if the user has the zoom feature enabled.
 */
const getNewCanvasOriginOnMove = (
    // TODO - Replace with user model at some stage
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentUser: any,
    canvasElementBoundingRectPx: Rect,
    currentBoardCanvasOriginPx: Point,
    minimumCanvasBoundingRect: Rect,
    gridSize: number,
) => {
    //  - The new canvas origin is the top left of this bounding box
    return {
        x: getNewCanvasOriginDimension(
            canvasElementBoundingRectPx.x,
            currentBoardCanvasOriginPx.x,
            minimumCanvasBoundingRect.x,
            gridSize,
        ),
        y: getNewCanvasOriginDimension(
            canvasElementBoundingRectPx.y,
            currentBoardCanvasOriginPx.y,
            minimumCanvasBoundingRect.y,
            gridSize,
        ),
    };
};

/**
 * When elements get moved, ensure that the canvas is large enough to contain where they're moved to.
 */
const handleElementMultiMove = (action: ClientActionObject, store: ReduxStore, measurementsStore: ReduxStore) => {
    if (action.remote || action.isRedo || action.isUndo) return;

    const { dispatch, getState } = store;
    const { moves, initialMeasurements } = action;

    const state = getState();
    const measurementsState = measurementsStore.getState();

    const elements = getElements(state);
    const gridSize: number = getGridSize(state);
    const zoomScale = canvasZoomScaleSelector(state);
    const currentBoardId = getCurrentBoardId(state);

    const currentUser = getCurrentUser(state);

    const currentBoardCanvasOrigin: Point = asObject(getCurrentVisibleBoardCanvasOrigin(state));
    const currentBoardCanvasOriginPx = getCurrentBoardCanvasOriginPx(state);

    if (!moves) return null;

    // Get initial canvas element measurements (probably as a map)
    const canvasElements = currentBoardCanvasElementsSelector(state);

    // Remove lines from the measurements map as they might not accurately represent their position on the board
    // which could result in incorrect bounding rectangle calculations.
    const canvasElementIds: string[] = asObject(canvasElements.filter((el) => !isLine(el)).map(getElementId));

    const measurementsMap = getMeasurementsMap(measurementsState);
    const initialCanvasElementMeasurementsMap = getMany(canvasElementIds, measurementsMap);

    // Update the canvas element measurements based on the moves
    const newCanvasElementMeasurementsMap = updateMeasurementsMapForMoves(
        initialMeasurements,
        measurementsMap,
        initialCanvasElementMeasurementsMap,
        elements,
        moves,
        currentBoardId,
        currentBoardCanvasOrigin,
        gridSize,
    );

    // (A) Get the bounding box of the updated canvas element measurements
    const canvasElementRects = newCanvasElementMeasurementsMap.valueSeq().toArray();
    const canvasElementBoundingRectUntranslatedPx = rectLib.getBoundingRect(canvasElementRects);
    const canvasElementBoundingRectPx = rectLib.translate(
        currentBoardCanvasOriginPx,
        canvasElementBoundingRectUntranslatedPx,
    );

    // (B) Get the bounding box of the initial canvas origin to current canvas document bottom right
    const currentBoardInitialOrigin = asObject<Point>(
        getCurrentBoardInitialCanvasOrigin(state) || currentBoardCanvasOrigin,
    );
    const currentBoardInitialOriginPx: Point = pointLib.scale(gridSize, currentBoardInitialOrigin);
    const canvasSize: RectSize = asObject(getCanvasSize(state));
    const canvasRect = rectLib.asRect({
        ...currentBoardCanvasOriginPx,
        ...canvasSize,
    });

    // This is the minimum size of the canvas (if we're going to shrink it).
    //  It's the initial canvas origin to the current canvas's right and bottom
    const minimumCanvasBoundingRect = rectLib.asRect({
        left: currentBoardInitialOriginPx.x,
        top: currentBoardInitialOriginPx.y,
        right: canvasRect.right,
        bottom: canvasRect.bottom,
    });

    //  - The new canvas origin is the top left of this bounding box (for zoom users)
    const newCanvasOriginPx: Point = getNewCanvasOriginOnMove(
        currentUser,
        canvasElementBoundingRectPx,
        currentBoardCanvasOriginPx,
        minimumCanvasBoundingRect,
        gridSize,
    );

    const canvasPadding = getCanvasDocumentBottomRightPaddingGridUnits(zoomScale);
    const newCanvasRect = rectLib.asRect({
        left: newCanvasOriginPx.x,
        top: newCanvasOriginPx.y,
        right: getNewCanvasRectMaxDimension(
            minimumCanvasBoundingRect.right,
            canvasElementBoundingRectPx.right,
            canvasPadding.x * gridSize,
        ),
        bottom: getNewCanvasRectMaxDimension(
            minimumCanvasBoundingRect.bottom,
            canvasElementBoundingRectPx.bottom,
            canvasPadding.y * gridSize,
        ),
    });

    //  - The new canvas size is its width and height
    const newCanvasSize = {
        width: newCanvasRect.width,
        height: newCanvasRect.height,
    };

    const newScrollAreaSize = getNewScrollAreaSize(
        canvasElementBoundingRectUntranslatedPx,
        newCanvasSize,
        currentBoardCanvasOriginPx,
        newCanvasOriginPx,
        store,
    );

    if (!isEqual(canvasSize, newCanvasSize) || newScrollAreaSize) {
        dispatch(setCanvasSize({ canvasSize: newCanvasSize, scrollAreaSize: newScrollAreaSize }));
    }

    // If the canvas origin has changed then update it
    if (!isEqual(newCanvasOriginPx, currentBoardCanvasOriginPx)) {
        const newCanvasOrigin = pointLib.round(pointLib.scale(1 / gridSize, newCanvasOriginPx));
        dispatch(
            updateBoardCanvasOrigin({
                id: currentBoardId,
                canvasOrigin: newCanvasOrigin,
                transactionId: action.transactionId,
            }),
        );
    }
};

/**
 * Translates a measurement from the measurements store by a given point.
 */
const shiftElementMeasurement = (elementMeasurement: Measurement, translationPx: Point): Measurement => ({
    ...elementMeasurement,
    ...rectLib.translate(translationPx, elementMeasurement),
    rects: elementMeasurement.rects.map((measurementRect) => ({
        ...measurementRect,
        ...rectLib.translate(translationPx, measurementRect),
    })),
});

/**
 * On a board canvas origin update, shift the measurements for all canvas elements
 */
const handleElementUpdate = (action: ClientActionObject, store: ReduxStore, measurementsStore: ReduxStore) => {
    if (action.updateType !== ELEMENT_UPDATE_TYPE.CANVAS_ORIGIN) return;

    const { updates } = action;

    const firstUpdate = first(updates);
    const updatedBoardId = firstUpdate?.id;

    const measurementsState = measurementsStore.getState();

    const state = store.getState();
    const currentBoardId = getCurrentBoardId(state);

    // Only update measurements if the current board has its origin changed
    if (updatedBoardId !== currentBoardId) return;

    const currentCanvasOrigin: Point = getCurrentVisibleBoardCanvasOrigin(state);
    const gridSize = getGridSize(state);

    const newCanvasOrigin = firstUpdate?.changes?.canvasOrigin || DEFAULT_CANVAS_ORIGIN;

    const canvasOriginChange: Point = pointLib.difference(currentCanvasOrigin, newCanvasOrigin);
    // NOTE: Multiplying by -1 as we need to push the elements in the opposite direction
    const elementMeasurementsTranslationPx: Point = pointLib.scale(-gridSize, canvasOriginChange);

    // For all elements on the canvas, shift their measurements by the change in
    const canvasElementIds = getCurrentBoardVisibleDescendantIds(state);

    const measurementUpdates = canvasElementIds
        .map((canvasElementId: string) => {
            const elementMeasurement = getElementMeasurements(measurementsState, { id: canvasElementId });

            if (!elementMeasurement) return null;

            const newMeasurements = shiftElementMeasurement(
                asObject(elementMeasurement),
                elementMeasurementsTranslationPx,
            );

            return measurementsSet({ id: canvasElementId, measurements: newMeasurements });
        })
        .filter((update: object) => !!update);

    const batchMeasurementsUpdate = createBatchAction({ actions: measurementUpdates });

    measurementsStore.dispatch(batchMeasurementsUpdate);
};

/**
 * Update the canvas size depending on the action type.
 */
const handleAction = (action: ClientActionObject, store: ReduxStore, measurementsStore: ReduxStore) => {
    switch (action.type) {
        // If the canvas origin has changed - we need to update the measurements for canvas elements
        case ELEMENT_UPDATE:
            return handleElementUpdate(action, store, measurementsStore);
        // On create ensure the canvas is large enough to include the new element
        case ELEMENT_CREATE:
            return handleElementCreate(action, store);
        // On move ensure that the canvas is large enough to include the newly moved elements
        case ELEMENT_MOVE_MULTI:
            return handleElementMultiMove(action, store, measurementsStore);
        default:
            return;
    }
};

type Dimension = 'x' | 'y';

/**
 * The maximum position of the element will either be the default maximum size of the canvas (10000,10000)
 * or it will be the current largest position of an element + 100 grid units, if it's beyond the maximum
 * size of the canvas.
 */
const getMaximumPositionValue = (state: Immutable.Map<string, unknown>, dimension: Dimension) => {
    const currentBoardChildren = getCurrentBoardChildren(state);
    return currentBoardChildren.reduce((currentMax, child) => {
        const childPosition = getPosition(dimension)(child) + MAXIMUM_CANVAS_OVERFLOW_INCREMENT;
        // @ts-ignore TypeScript seems like it doesn't understand reduce
        if (childPosition > currentMax) return childPosition;
        return currentMax;
    }, MAXIMUM_POSITION_VALUE);
};

/**
 * Determines the minimum position value for an element.
 * This is either the MINIMUM_CANVAS_ORIGIN (-500) or the current canvas origin if it's less than -500.
 *
 * This logic is to support boards that were created before the limit on the canvas origin was introduced.
 */
const getMinimumPositionValue = (state: Immutable.Map<string, unknown>, dimension: Dimension) => {
    const currentBoardCanvasOrigin = getCurrentVisibleBoardCanvasOrigin(state);

    // If the canvas origin is less than MINIMUM_CANVAS_ORIGIN, then use that as the minimum, otherwise
    // use the MINIMUM_CANVAS_ORIGIN as the minimum
    return Math.min(prop(dimension, currentBoardCanvasOrigin), MINIMUM_CANVAS_ORIGIN);
};

/**
 * Ensure that creates stay within the current canvas.
 */
const validateCreates = (action: ClientActionObject, store: ReduxStore): void => {
    if (!action.location) return;

    if (!isLocationCanvas(action)) return;

    const createX = getXPosition(action);
    const createY = getYPosition(action);

    const state = store.getState();

    const minX = getMinimumPositionValue(state, 'x');
    const minY = getMinimumPositionValue(state, 'y');

    if (createX < minX || createY < minY) {
        throw new CanvasSizeError(`Cannot extend the canvas origin beyond the minimum`, {
            friendlyMessage: "The canvas can't grow this far",
            tip: 'Try creating the content towards the bottom right',
            details: {
                position: {
                    x: createX,
                    y: createY,
                },
                min: {
                    x: minX,
                    y: minY,
                },
            },
        });
    }

    const maxX = getMaximumPositionValue(state, 'x');
    const maxY = getMaximumPositionValue(state, 'y');

    if (createX > maxX || createY > maxY) {
        throw new CanvasSizeError(`Cannot create element beyond maximum canvas size`, {
            friendlyMessage: "The canvas can't grow this far",
            details: {
                position: {
                    x: createX,
                    y: createY,
                },
                max: {
                    x: maxX,
                    y: maxY,
                },
            },
        });
    }

    return;
};

/**
 * Ensure that moves stay within the current canvas.
 */
const validateMoves = (action: ClientActionObject, store: ReduxStore): void => {
    const { moves } = action;

    if (!moves) return;

    const state = store.getState();

    const minX = getMinimumPositionValue(state, 'x');
    const minY = getMinimumPositionValue(state, 'y');

    const maxX = getMaximumPositionValue(state, 'x');
    const maxY = getMaximumPositionValue(state, 'y');

    moves.forEach((move) => {
        if (!isLocationCanvas(move)) return;
        if (!move?.location?.position) return;

        const moveX = getXPosition(move);
        const moveY = getYPosition(move);

        if (moveX < minX || moveY < minY) {
            throw new CanvasSizeError(`Cannot move an element beyond the minimum canvas origin`, {
                friendlyMessage: "The canvas can't grow this far",
                tip: 'Try moving the content towards the bottom right',
                details: {
                    position: {
                        x: moveX,
                        y: moveY,
                    },
                    min: {
                        x: minX,
                        y: minY,
                    },
                },
            });
        }

        if (moveX > maxX || moveY > maxY) {
            throw new CanvasSizeError(`Cannot move element beyond maximum canvas size`, {
                friendlyMessage: "The canvas can't grow this far",
                details: {
                    position: {
                        x: moveX,
                        y: moveY,
                    },
                    max: {
                        x: maxX,
                        y: maxY,
                    },
                },
            });
        }
    });
};

/**
 * Ensure that moves and creates won't cause the canvas to grow too large.
 */
const validateAction = (action: ClientActionObject, store: ReduxStore): void => {
    if (action.remote) return;

    switch (action.type) {
        case ELEMENT_CREATE:
            return validateCreates(action, store);
        case ELEMENT_MOVE_MULTI:
            return validateMoves(action, store);
        default:
            return;
    }
};

/**
 * Changes the canvas size on certain actions, such as creating elements.
 */
export default ({ measurementsStore }: { measurementsStore: ReduxStore }) =>
    (store: ReduxStore) =>
    (next: Function) =>
    (action: ClientActionObject) => {
        try {
            validateAction(action, store);
            handleAction(action, store, measurementsStore);
            // I couldn't find a better way to handle this in TS :/
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            logger.error('Failed to updated canvas origin', error, error?.details, action);
            manuallyReportError({ error, custom: error?.details });
            store.dispatch(
                errorModalOpen({
                    modalId: BOARD_ERROR.CANVAS_SIZE,
                    // @ts-ignore Structure of error modal data is not defined
                    data: {
                        error,
                        boardId: getCurrentBoardId(store.getState()),
                    },
                }),
            );

            // Don't process the action if there's an error with the move
            return;
        }

        return next(action);
    };
