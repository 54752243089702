// Utils
import * as pointsLib from '../../../common/maths/geometry/point';
import { getGridSizeLocalStorage } from './gridSizeStorageService';

import { GRID, GRID_SIZE_LIST, GRID_SIZE_LIST_TOUCH_DEVICE, GridSizeDefinition } from './gridConstants';
import { Point } from '../../../common/maths/geometry/pointTypes';

export const pixelsToGridPoints = (pixels: number, gridSize: number): number => Math.round(pixels / gridSize);
export const pixelsToPrevGridPoints = (pixels: number, gridSize: number): number => Math.floor(pixels / gridSize);
export const pixelsToNextGridPoints = (pixels: number, gridSize: number): number => Math.ceil(pixels / gridSize);
export const gridPointsToPixels = (gridPoints: number, gridSize: number): number => gridPoints * gridSize;

export const roundPixelToNearestGridPoint = (pixels: number, gridSize: number): number =>
    gridPointsToPixels(pixelsToGridPoints(pixels, gridSize), gridSize);
export const roundPixelToNextGridPoint = (pixels: number, gridSize: number): number =>
    gridPointsToPixels(pixelsToNextGridPoints(pixels, gridSize), gridSize);

const getGridSizeForWidth = (screenWidth: number, gridSizeList: GridSizeDefinition[]) =>
    gridSizeList.reduce((currValue, size) => (size.breakpoint >= screenWidth ? currValue : size), gridSizeList[0]);

export const calculateGridSize = (screenWidth: number, screenHeight: number, isTouchDevice: boolean) => {
    if (isTouchDevice) {
        return getGridSizeForWidth(Math.max(screenWidth, screenHeight), GRID_SIZE_LIST_TOUCH_DEVICE);
    }

    return getGridSizeForWidth(screenWidth, GRID_SIZE_LIST);
};

export const getGridSizeBodyClassName = (sizeName: string) => `app-size-${sizeName}`;

/**
 * TODO - Improve this API & function name
 */
export const getElementStyle = (widthGU: number, gridSize: number, noSetWidth: boolean) => ({
    // Need to subtract 2 from the width as the border is no longer part of the element size
    // It's now an absolutely positioned pseudo element :before, so the element itself must be 2px smaller
    // to account for the border
    width: !noSetWidth ? gridPointsToPixels(widthGU, gridSize) - 2 : 'auto',
});

export const positionPixelsToGridPoints = (position: Point, gridSize: number): Point =>
    pointsLib.round(pointsLib.scale(1 / gridSize, position) as any) as any;

/**
 * Scales "large grid pixels" into the appropriate number of pixels for the current grid size.
 * E.g. 10px in the large grid size would scale to 8px in the small grid size.
 */
export const scaleToGrid = (units: number, gridSize: number) => (gridSize / GRID.LARGE.size) * units;

/**
 * Scales pixels to the large grid pixels, based on the current grid size.
 * E.g. 8px when viewing the small grid size would scale up to 10px for the large grid size.
 */
export const scaleToLargeGridPixels = (units: number, gridSize: number) => (GRID.LARGE.size / gridSize) * units;

export const getStoredGridSize = (): GridSizeDefinition | undefined => {
    const storedGridSize = getGridSizeLocalStorage();
    return GRID_SIZE_LIST.find(({ name }) => name === storedGridSize);
};

export const isSmallGridSize = (gridSize: number): boolean => gridSize === GRID.SMALL.size;
export const isMediumGridSize = (gridSize: number): boolean => gridSize === GRID.MEDIUM.size;
export const isLargeGridSize = (gridSize: number): boolean => gridSize === GRID.LARGE.size;
