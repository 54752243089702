// Utils
import { ImMap, prop } from '../../../utils/immutableHelper';
import { reduceImTiptapContent } from './reduceImTiptapContent';
import { getMentionNodeText } from '../../extensions/mention/mentionUtils';

// Types
import { TiptapContentNode, TiptapNodeType } from '../../tiptapTypes';
import { isTiptapTextBlockNodeType } from '../isTiptapTextBlockNodeType';

/**
 * Gets the text that's explicitly on a Tiptap node.
 */
const getTiptapNodeText = (node: ImMap<TiptapContentNode> | TiptapContentNode): string => {
    const type = prop('type', node);

    switch (type) {
        case TiptapNodeType.mention:
            return getMentionNodeText(node);
        default:
            return prop('text', node) || '';
    }
};

/**
 * Retrieves the text content for a single Tiptap content block node by traversing its nodes.
 *
 * NOTE: This is intended for use by a single block node, otherwise it will concatenate all the
 * text nodes across every block.
 */
const getTiptapSingleBlockText = (node: ImMap<TiptapContentNode> | TiptapContentNode): string =>
    reduceImTiptapContent(
        node,
        (acc, tiptapNode) => {
            const text = getTiptapNodeText(tiptapNode);

            if (!text) return acc;

            return acc + text;
        },
        '',
    );

/**
 * If the node is a block type - returns the full text of that block, otherwise null.
 */
const getTiptapBlockText = (node: ImMap<TiptapContentNode> | TiptapContentNode): string | null => {
    const type = prop('type', node);

    if (isTiptapTextBlockNodeType(type)) return getTiptapSingleBlockText(node);

    return null;
};

/**
 * Gets an array of text block strings (each line in the Tiptap document) from the Tiptap content.
 */
const getTiptapContentLines = (editorContents: ImMap<TiptapContentNode> | TiptapContentNode): string[] =>
    reduceImTiptapContent(
        editorContents,
        (acc, tiptapNode) => {
            const text = getTiptapBlockText(tiptapNode);

            if (text) acc.push(text);

            return acc;
        },
        [] as string[],
    );

export default getTiptapContentLines;
