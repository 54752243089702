// Lib
import { isEmpty, get, flatMap, compact, flow, uniq } from 'lodash/fp';

// Utils
import { prop, propIn } from '../utils/immutableHelper';
import { isCommentId } from '../comments/commentModelUtil';
import {
    getNotificationActorIdsAsArray,
    getNotificationCollectionId,
    getNotificationCommentIdsAsArray,
    getNotificationCommentThreadIdsAsArray,
    getNotificationElementChangesAsObject,
    getNotificationElementId,
    getNotificationMentionCommentId,
    getNotificationMentionCommentThreadId,
    getNotificationMentionElementId,
    getNotificationAssignmentsAsArray,
    getNotificationShareActorId,
    getNotificationTemplateIdsAsArray,
    getNotificationType,
    getNotificationRemindersAsArray,
    getNotificationPermissionId,
} from './notificationModelUtils';

// Constants
import { NOTIFICATION_TYPES } from './notificationConstants';

const getReferencedUserIds = (notification) => {
    const type = getNotificationType(notification);

    switch (type) {
        case NOTIFICATION_TYPES.SHARE_REMINDER:
            return [...getNotificationActorIdsAsArray(notification), getNotificationShareActorId(notification)];
        default:
            return getNotificationActorIdsAsArray(notification);
    }
};

export const getNotificationsReferencedUserIds = flow(flatMap(getReferencedUserIds), uniq, compact);
export const getNotificationsReferencedPermissionIds = flow(flatMap(getNotificationPermissionId), uniq, compact);

/**
 * This is used to get permissions IDs matched to their element IDs, so this will only return a value if
 * there's a permission ID to begin with.
 */
const getPermissionIdElementIdTuple = (notification) => {
    const permissionId = getNotificationPermissionId(notification);

    if (!permissionId) return null;

    return [permissionId, getNotificationElementId(notification)];
};
/**
 * This is used to get permissions IDs matched to their
 */
export const getNotificationsReferencedPermissionIdAndAssociatedElementIds = (notifications) => {
    if (isEmpty(notifications)) return {};

    const tuples = compact(notifications.map(getPermissionIdElementIdTuple));

    if (isEmpty(tuples)) return {};

    let permissionIds = [];
    const permissionIdToElementIdsMap = {};

    tuples.forEach(([permissionId, elementId]) => {
        permissionIdToElementIdsMap[permissionId] = permissionIdToElementIdsMap[permissionId] || [];
        permissionIdToElementIdsMap[permissionId].push(elementId);

        permissionIds.push(permissionId);
    });

    permissionIds = uniq(compact(permissionIds));

    return { permissionIds, permissionIdToElementIdsMap };
};

export const getReferencedCommentIds = (notification) => {
    const type = getNotificationType(notification);

    switch (type) {
        case NOTIFICATION_TYPES.COMMENT:
            return getNotificationCommentIdsAsArray(notification);
        case NOTIFICATION_TYPES.MENTION:
            return [getNotificationMentionCommentId(notification)];
        default:
            return [];
    }
};

export const getNotificationsReferencedCommentIds = flow(flatMap(getReferencedCommentIds), uniq, compact);

export const getUnseenCommentIds = (notification, lastBoardView) => {
    const commentIds = getReferencedCommentIds(notification);
    return commentIds.filter((commentId) => {
        const seenTime = propIn(['details', 'commentChanges', commentId, 'seen'], notification);
        return !seenTime || (lastBoardView && lastBoardView < seenTime);
    });
};

export const getElementChangeIds = (notification) => {
    const elementChanges = getNotificationElementChangesAsObject(notification);
    return Object.keys(elementChanges).filter((elId) => !isCommentId(elId));
};

/**
 * Finds all the elementChanges that weren't seen prior to the last board view.
 */
export const getUnseenElementChangesIds = (notification, lastBoardView) => {
    const elementChanges = getNotificationElementChangesAsObject(notification);

    const elementChangeIds = Object.keys(elementChanges);

    return elementChangeIds.filter((elementId) => {
        const elementChange = elementChanges[elementId];
        const seen = prop('seen', elementChange);
        return !seen || seen > lastBoardView;
    });
};

const getNotificationAssignmentElementIds = (notification) => {
    const assignments = getNotificationAssignmentsAsArray(notification);
    return assignments ? assignments.map(get('assignedElementId')) : null;
};

const getNotificationReminderElementIds = (notification) => {
    const reminders = getNotificationRemindersAsArray(notification);
    return reminders ? reminders.map(get('elementId')) : null;
};

const getReferencedElementIds = (notification) => {
    const elementId = getNotificationElementId(notification);
    const threadIds = getNotificationCommentThreadIdsAsArray(notification);
    const mentionElementId = getNotificationMentionElementId(notification);
    const mentionCommentThreadId = getNotificationMentionCommentThreadId(notification);
    const assignedElementIds = getNotificationAssignmentElementIds(notification);
    const reminderElementIds = getNotificationReminderElementIds(notification);
    const elementChangeIds = getElementChangeIds(notification);
    const templateIds = getNotificationTemplateIdsAsArray(notification);
    const collectionId = getNotificationCollectionId(notification);

    return [
        elementId,
        ...threadIds,
        mentionElementId,
        mentionCommentThreadId,
        ...assignedElementIds,
        ...reminderElementIds,
        ...templateIds,
        ...elementChangeIds,
        collectionId,
    ];
};

export const getNotificationReferencedElementIds = flow(flatMap(getReferencedElementIds), uniq, compact);
