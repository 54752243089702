/**
 * This code closely follows https://github.com/ngs/draft-js-markdown-shortcuts-plugin.
 * This is more customised to Milanote's use case.
 */

// Utils
import handleBeforeInput from './handleBeforeInput';
import customStyleMap from './customStyleMap';
import keyBindingFn from './keyBindingFn';

export default ({ enableBlockStyles }) => ({
    handleBeforeInput: handleBeforeInput({ enableBlockStyles }),
    customStyleMap,
    keyBindingFn,
});
