// Lib
import { SelectionState, EditorState } from 'draft-js';

// Utils
import shiftSelection from './selection/shiftSelection';
import getSelectionBlockMap from './selection/getSelectionBlockMap';
import { insertTextInContentStateAt } from './insertTextAt';

// Constants
import { EditorChangeType } from '../draftjsConstants';

export default (insertionText) => (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const insertTextFn = insertTextInContentStateAt(insertionText);

    // Loop over selection blocks
    const blockRange = getSelectionBlockMap(editorState);
    let newContentState = blockRange.reduce((updatedContentState, contentBlock) => {
        const blockKey = contentBlock.getKey();
        const startOfBlock = SelectionState.createEmpty(blockKey);
        return insertTextFn(startOfBlock)(updatedContentState);
    }, contentState);

    const textLength = insertionText.length;
    const updatedSelection = shiftSelection(selection, textLength, textLength);
    newContentState = newContentState.set('selectionAfter', updatedSelection);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS);

    return EditorState.forceSelection(newEditorState, updatedSelection);
};
