// Lib
import { RawDraftContentBlock } from 'draft-js';
import * as jsondiffpatch from 'jsondiffpatch';

// Types
import { MNElementContent } from '../../../elements/elementModelTypes';

const diffPatcher = jsondiffpatch.create({
    objectHash: function (obj: RawDraftContentBlock, index: number) {
        // try to find a property which uniquely identifies this object
        return obj.key || obj.text || `index: ${index}`;
    },
});

export const getContentDiff = (previousState: object, currentState: object): object | void => {
    const delta = diffPatcher.diff(previousState, currentState);

    if (!delta) return;

    return delta;
};

export const patchContentWithDiff = (previousState: MNElementContent['textContent'], newChanges: object): object => {
    return diffPatcher.patch(previousState, newChanges);
};
