// Utils
import { getColorToPersist } from '../../../../../../common/colors/elementColorFormatUtil';
import { getIsLightColor } from '../../../../../../common/colors/coreColorUtil';
import { isElementContent } from '../../../../../utils/domUtil';

/**
 * This function is used to improve the performance when using the colour picker to choose a custom colour.
 * To prevent thrashing state changes, instead of dispatching an action on every colour change (which happens
 * every animation frame when using the colour wheel) we'll simply select elements with the appropriate
 * classes and update their specific colours accordingly.
 */
export default (colorValue) => {
    const color = getColorToPersist(colorValue);
    const isLightColour = getIsLightColor(color);

    // CSS backgrounds
    const colorTargets = document.querySelectorAll('.background-color-target');
    const colorTargetArray = colorTargets ? Array.from(colorTargets) : [];

    colorTargetArray.forEach((domNode) => {
        domNode.style.setProperty('--ws-element-background-primary', color);

        // For elements we want to avoid setting styles here with js since it can override the css variables
        // instead update just the variables and classes
        if (isElementContent(domNode)) {
            domNode.classList.remove('transparent');
            return;
        }

        domNode.style.backgroundImage = 'none';
        domNode.style.backgroundColor = color;
    });

    // Contrast colours
    const colorContrastTargets = document.querySelectorAll('.background-color-contrast-target');
    const colorContrastTargetsArray = Array.from(colorContrastTargets);

    colorContrastTargetsArray.forEach((domNode) => {
        if (isLightColour) {
            domNode.classList.add('colored-background');
            domNode.classList.add('colored-background-light');
            domNode.classList.remove('colored-background-dark');
        } else {
            domNode.classList.add('colored-background');
            domNode.classList.add('colored-background-dark');
            domNode.classList.remove('colored-background-light');
        }
    });
};
