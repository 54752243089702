// Utils
import { prop } from '../../../../common/utils/immutableHelper';
import Vector from '../../../../common/maths/geometry/Vector';
import { scaleToGrid, scaleToLargeGridPixels } from '../../../utils/grid/gridUtils';
import { getBezierSection } from '../../../../common/maths/geometry/bezierUtil';
import { getArePointsClose } from '../../../../common/maths/geometry/point';
import { getStraightLineMidpoint } from './lineElementIntersectionUtil';

// Constants
import { LINE_EDGE } from '../../../../common/lines/lineConstants';
import { GRID } from '../../../utils/grid/gridConstants';

// If the control point is within 20px of the middle point, snap to the middle point
const CONTROL_POINT_SNAPPING_DISTANCE = 20;

/**
 * Finds the halfway point along a line from start to end.
 */
export const getHalfwayPoint = (start, end) => {
    const lineVector = Vector.lineVector(start, end);
    return lineVector.multiplyScalar(0.5).add(start);
};

/**
 * Determines the ratio (0...1) of the control point position along the straight line between the start and the end.
 */
export const calculateControlPointParallelRatio = ({ controlPoint, startEdgeOrigin, endEdgeOrigin }) => {
    const lineVector = Vector.lineVector(startEdgeOrigin, endEdgeOrigin);
    // Move the controlPoint onto the start line's coordinate system
    const translatedControlPoint = Vector.fromObject(controlPoint).subtract(startEdgeOrigin);
    return lineVector.projectedRatio(translatedControlPoint);
};

/**
 * Determines the magnitude (in PX) that the control point is offset from the line.
 */
export const calculateControlPointPerpendicularMagnitude = ({ controlPoint, startEdgeOrigin, endEdgeOrigin }) => {
    const lineVector = Vector.lineVector(startEdgeOrigin, endEdgeOrigin);
    const perpendicularVector = lineVector.clone().perpendicular();

    // Move the controlPoint onto the start line's coordinate system
    const translatedControlPoint = Vector.fromObject(controlPoint).subtract(startEdgeOrigin);
    return perpendicularVector.projectedMagnitude(translatedControlPoint);
};

/**
 * Determines whether the point is close to another point
 */
const controlPointIsClose = (controlPoint, comparisonPoint) =>
    getArePointsClose(controlPoint, comparisonPoint, CONTROL_POINT_SNAPPING_DISTANCE);

/**
 * Snaps the control point to the straight line midpoint if it's close to it.
 */
export const isControlPointCloseToStraightLineMidpoint = ({
    startEdgeOrigin,
    startConnectionRects,
    endEdgeOrigin,
    endConnectionRects,
    controlPoint,
}) => {
    const straightLineMidpoint = getStraightLineMidpoint({
        startEdgeOrigin,
        startConnectionRects,
        endEdgeOrigin,
        endConnectionRects,
    });

    if (!straightLineMidpoint) return false;

    return controlPointIsClose(controlPoint, straightLineMidpoint);
};

/**
 * Finds the point, in pixels, that the control point should be drawn.
 * This uses an imaginary line between the start and end positions to determine where
 * the control point should show.
 * If the line is connected to elements, the start and end positions will be the centre point
 * of the element, thus the control point is calculated as though the line connects to the centres
 * of elements.
 *
 * NOTE: If the control point data does not exist, this will return null, allowing "getLine
 */
export const getControlPosition = ({ start, end, control, gridSize = GRID.LARGE.size }) => {
    if (!start || !end || !control) return null;

    const lineVector = Vector.lineVector(start, end);
    const perpendicularVector = lineVector.clone().perpendicular();

    // The perpMag is pixels perpendicular from the linear line.
    // NOTE: This means the control point changes based on the grid size :(
    let controlPerpMag = prop('perpMag', control) || 0;
    controlPerpMag = scaleToGrid(controlPerpMag, gridSize);

    // By default the control point is halfway along the straight line between the two points
    const controlParallelRatio = prop('parallelRatio', control) || 0.5;

    // Travel a ratio down the straight line between the start an end
    const controlParallel = lineVector.multiplyScalar(controlParallelRatio);
    // Then move perpendicular to that point, to find the control point
    const controlPerp = perpendicularVector.toLength(controlPerpMag);

    return controlParallel.add(controlPerp).add(start);
};

/**
 * Gets the values to be persisted for a control point.
 */
export const getUpdatedControlPoint = ({ start, startEdgeOrigin, end, endEdgeOrigin, controlPoint, gridSize }) => {
    if (!start || !end || !startEdgeOrigin || !endEdgeOrigin || !controlPoint) return null;

    const midpoint = getHalfwayPoint(start, end);

    // If the point is close to the midpoint, then don't set a control point, this will force a straight line
    if (getArePointsClose(controlPoint, midpoint, 0.1)) return null;

    let controlPointPerpendicularMagnitude = calculateControlPointPerpendicularMagnitude({
        controlPoint,
        startEdgeOrigin,
        endEdgeOrigin,
    });

    controlPointPerpendicularMagnitude = scaleToLargeGridPixels(controlPointPerpendicularMagnitude, gridSize);

    const controlPointParallelRatio = calculateControlPointParallelRatio({
        controlPoint,
        startEdgeOrigin,
        endEdgeOrigin,
    });

    return {
        perpMag: controlPointPerpendicularMagnitude,
        parallelRatio: controlPointParallelRatio,
    };
};

/**
 * Based on a "t" intersection, this will determine the control point that allows the line to
 * be split, but maintain the same bezier shape.
 */
export const findNewControlPointForBezierSection = ({ edgeDetails, gridSize, draggedEdge, intersectionT }) => {
    // Don't do anything if there's not already a control point
    if (!edgeDetails.controlPoint) return null;

    const draggedStart = draggedEdge === LINE_EDGE.start;

    const startT = draggedStart ? intersectionT : 0;
    const endT = !draggedStart ? intersectionT : 1;

    const startEdgeOrigin = draggedStart ? edgeDetails.start : edgeDetails.startEdgeOrigin;
    const endEdgeOrigin = !draggedStart ? edgeDetails.end : edgeDetails.endEdgeOrigin;

    const bezier = getBezierSection({
        start: edgeDetails.startEdgeOrigin,
        controlPoint: edgeDetails.controlPoint,
        end: edgeDetails.endEdgeOrigin,
        t1: startT,
        t2: endT,
    });

    return getUpdatedControlPoint({
        ...edgeDetails,
        startEdgeOrigin,
        endEdgeOrigin,
        controlPoint: bezier.get(0.5),
        gridSize,
    });
};
