// Util
import { createPrepareActionForServerFn } from './createPrepareActionForServerFn';
import { isCurrentBrowserLogoutAction } from '../../../auth/authUtils';
import { getUserIdFromAction } from '../../../../common/actionUtils';
import { isPlatformIframe } from '../../../platform/utils/platformDetailsUtils';

// Data selectors
import { isGuestSelector } from '../../../user/currentUserSelector';
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';
import { isBoardPreviewEnabledSelector } from '../../../workspace/boardPreview/boardPreviewSelector';

// Constants
import { CURRENT_USER_SET } from '../../../user/currentUserConstants';
import { LOGOUT } from '../../../auth/authConstants';
import { CHANNELS_UPDATED, SOCKET_FORCE_CONNECT, SOCKET_FORCE_DISCONNECT } from '../socketConstants';
import { ELECTRON_APP_SLEEP, ELECTRON_APP_WAKE } from '../../../../common/electron/electronConstants';
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';

/**
 * Only sync batched actions if some of the actions are marked as synced.
 */
const shouldSyncAction = (action) => {
    if (action.sync) return true;
    if (action.type !== BATCH_ACTION_TYPE) return false;

    const batchedActions = action.payload || [];
    return batchedActions.some((a) => a.sync);
};

export const createSocketMiddleware = (socketManager) => (store) => {
    const prepareActionForServer = createPrepareActionForServerFn(store);

    const state = store.getState();
    const platformDetails = getPlatformDetailsSelector(state);

    if (isPlatformIframe(platformDetails)) {
        return (next) => (action) => next(action);
    }

    return (next) => (action) => {
        const state = store.getState();
        const newState = next(action);

        const isBoardPreviewEnabled = isBoardPreviewEnabledSelector(state);
        if (isBoardPreviewEnabled) return newState;

        const isGuest = isGuestSelector(state);
        if (isGuest && action.sync && action.type !== LOGOUT) return newState;

        const shouldSync = shouldSyncAction(action);
        if (shouldSync) {
            const syncAction = prepareActionForServer(action);
            socketManager.emitAction(syncAction);
        }

        // On logout, disconnect the socket
        if (isCurrentBrowserLogoutAction(action)) {
            socketManager.disconnectAndDestroySocket();
            return newState;
        }

        switch (action.type) {
            case CURRENT_USER_SET:
                socketManager.connectSocket(getUserIdFromAction(action));
                break;
            case CHANNELS_UPDATED:
                socketManager.updateChannels(action);
                break;
            case SOCKET_FORCE_DISCONNECT:
            case ELECTRON_APP_SLEEP:
                socketManager.disconnectAndDestroySocket();
                break;
            case SOCKET_FORCE_CONNECT:
            case ELECTRON_APP_WAKE:
                socketManager.connectSocket();
                break;
            default:
                break;
        }

        return newState;
    };
};
