import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AiHistoryIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon ai-history"
        {...props}
    >
        <path
            fill="#2F63F7"
            d="M11.974 6a6 6 0 1 1-5.972 6.59.5.5 0 1 1 .996-.097A5 5 0 1 0 7.973 9h1.25a.5.5 0 0 1 0 1h-2.5a.5.5 0 0 1-.5-.5V7a.5.5 0 0 1 .5-.5l.09.008a.5.5 0 0 1 .41.492v1.335A5.994 5.994 0 0 1 11.973 6zM12 8.25a.5.5 0 0 1 .5.5v3.043l1.75 1.707a.5.5 0 0 1 .058.638l-.058.07a.5.5 0 0 1-.707 0l-1.897-1.854-.146-.147V8.75a.5.5 0 0 1 .5-.5z"
        />
    </svg>
);
const Memo = memo(AiHistoryIcon);
export default Memo;
