// Utils
import { runIfAvailable } from './analyticsInvocationUtils';

declare global {
    interface Window {
        newrelic: {
            setApplicationVersion: (applicationVersion: string | null) => void;
            setUserId: (userId: string | null) => void;
            setCustomAttribute: (key: string, value: string | boolean | number) => void;
            finished: (initialisationTime: number) => void;
            addPageAction: (actionName: string, attributes: Record<string, any>) => void;
        };
    }
}

export enum NewRelicPageActions {
    APP_MULTI_REFRESH = 'appMultiRefresh',
    BOARD_NAVIGATION = 'boardNavigation',
    ELEMENT_CREATION = 'elementCreation',
    FEATURE_FORCE_DISABLE = 'featureForceDisable',
    ELEMENT_PURGE = 'elementPurge',

    LOCAL_CACHE_KEYVAL_IDB_INIT = 'localCache.keyvalIdbInit',
    LOCAL_CACHE_GET_CACHED_REDUX_STATE = 'localCache.getCachedReduxState',
    LOCAL_CACHE_GET_CACHED_REDUX_STATE_CACHE_OBJECT_RETRIEVAL = 'localCache.getCachedReduxState.cacheObjectRetrieval',
    LOCAL_CACHE_GET_CACHED_REDUX_STATE_GET_PERSISTED_STATE = 'localCache.getCachedReduxState.getPersistedState',
}

export enum NewRelicCustomAttributes {
    CLIENT_LOCAL_CACHE = 'clientLocalCache',
    CLIENT_LOCAL_CACHE_REHYDRATION = 'clientLocalCacheRehydration',
    SUPPORTS_PERSISTENCE_LAYER = 'supportsPersistenceLayer',
    CLIENT_PERSISTENCE_ENABLED = 'clientPersistenceEnabled',
}

const isNewRelicAvailable = (): boolean => !!window.newrelic && typeof window.newrelic === 'object';
const runIfNewRelicIsAvailable = runIfAvailable('New Relic', isNewRelicAvailable);

/**
 * Set the application version (`application.version`) for page actions and browser interactions in New Relic.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setApplicationVersion/
 */
export const setNewRelicApplicationVersion = runIfNewRelicIsAvailable(function setNewRelicApplicationVersion(
    applicationVersion: string | null,
): void {
    window.newrelic.setApplicationVersion(applicationVersion);
});

/**
 * Set the User ID (`enduser.id`) for page actions and browser interactions in New Relic.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setuserid/
 */
export const setNewRelicUserId = runIfNewRelicIsAvailable(function setNewRelicUserId(userId: string | null): void {
    window.newrelic.setUserId(userId || 'guest');
});

/**
 * Submits a custom attribute to New Relic.
 * All future browser interaction events or page actions will include this attribute.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setcustomattribute/
 */
export const setNewRelicCustomAttribute = runIfNewRelicIsAvailable(function setNewRelicCustomAttribute(
    key: NewRelicCustomAttributes,
    value: string | boolean | number,
): void {
    window.newrelic.setCustomAttribute(key, value);
});

/**
 * Submits a custom attribute to New Relic.
 * All future browser interaction events or page actions will include this attribute.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setcustomattribute/
 */
export const setNewRelicMultipleCustomAttributes = runIfNewRelicIsAvailable(function setNewRelicCustomAttribute(
    attributesObject: Record<string, string | boolean | number>,
): void {
    for (const key in attributesObject) {
        window.newrelic.setCustomAttribute(key, attributesObject[key]);
    }
});

/**
 * Submits a PageAction event with the name "finished" to New Relic.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/finished/
 */
export const sendNewRelicPageLoadFinishedAction = runIfNewRelicIsAvailable(function sendNewRelicPageLoadFinishedAction(
    initialisationTime: number,
): void {
    window.newrelic.finished(initialisationTime);
});

/**
 * Submits a PageAction event to New Relic.
 * This should be used for custom events that are not covered by the standard New Relic events.
 *
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/addpageaction/
 */
export const sendNewRelicPageAction = runIfNewRelicIsAvailable(function sendNewRelicPageAction(
    actionName: NewRelicPageActions,
    attributes: Record<string, string | number | boolean> = {},
): void {
    window.newrelic.addPageAction(actionName, attributes);
});
