// Lib
import { prop } from '../../../../common/utils/immutableHelper';

let measurementsCache = {};

export const setMeasurementsCache = (newMeasurements) => {
    measurementsCache = newMeasurements;
};

export const getMeasurementsCache = () => measurementsCache;

export const getMeasurementFromCache = (elementId) => prop(elementId, measurementsCache);
