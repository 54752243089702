// Utils
import { prop } from '../../../common/utils/immutableHelper';

// Constants
import { BOARD_ERROR } from '../../../common/error/errorConstants';

// Types
import { AsyncResourceEntityErrorObject } from '../../utils/services/http/asyncResource/reducers/asyncResourceReducerTypes';

/**
 * Inspects the code of an error to determine if it is a transient error.
 */
export const isTransientBoardFetchError = (boardFetchError: AsyncResourceEntityErrorObject) => {
    if (!boardFetchError) return false;

    switch (prop('code', boardFetchError)) {
        case BOARD_ERROR.PASSWORD_PROTECTED:
        case BOARD_ERROR.DELETED:
        case BOARD_ERROR.ACCESS_DENIED:
        case BOARD_ERROR.NOT_FOUND:
            return false;
        default:
            return true;
    }
};

export const isBoardAccessDeniedError = (error: AsyncResourceEntityErrorObject) =>
    error?.code === BOARD_ERROR.ACCESS_DENIED;
