// FIXME-MOBILE - Move this into platformAppTypeUtils.ts
export const isInstalledPwa = () => {
    // Launched iOS
    if (navigator.standalone) return true;

    // Launched PWA
    const isLaunchedPwa =
        matchMedia &&
        (matchMedia('(display-mode: standalone)').matches ||
            matchMedia('(display-mode: window-controls-overlay)').matches);

    if (isLaunchedPwa) return true;

    return false;
};
