// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './MentionEntity.scss';

const MentionEntity = ({ children }) => <span className="MentionEntity">{children}</span>;

MentionEntity.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default MentionEntity;
