import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarColorModeBarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={16}
        viewBox="0 0 20 16"
        className="Icon toolbar-color-mode-bar"
        {...props}
    >
        <path
            fill="#BBBEC3"
            fillRule="evenodd"
            d="M18 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16zm.5 4h-17v10a.5.5 0 0 0 .41.492L2 14.5h16a.5.5 0 0 0 .492-.41L18.5 14V4z"
            className="fill"
        />
    </svg>
);
const Memo = memo(ToolbarColorModeBarIcon);
export default Memo;
