// Lib
import { Modifier, EditorState, SelectionState } from 'draft-js';

// Constants
import { EditorChangeType, DraftRemovalDirection } from '../draftjsConstants';

const clearCurrentBlockText = ({ currentContent, blockKey }) => {
    const currentBlock = currentContent.getBlockForKey(blockKey);
    const removalRange = SelectionState.createEmpty(blockKey).merge({
        focusOffset: currentBlock.getLength(),
        hasFocus: true,
    });

    return Modifier.removeRange(currentContent, removalRange, DraftRemovalDirection.forward);
};

const deleteCurrentBlock = ({ currentContent, selection, blockKey, direction }) => {
    const blockBefore = currentContent.getBlockBefore(blockKey);
    const blockAfter = currentContent.getBlockAfter(blockKey);

    // Focus the previous block if there is a previous block and the direction is backward, or if there's
    // no block after this one
    const focusPreviousBlock = blockBefore && (direction === DraftRemovalDirection.backward || !blockAfter);
    const focusKey = focusPreviousBlock ? blockBefore.getKey() : blockAfter.getKey();
    const focusOffset = focusPreviousBlock ? blockBefore.getLength() : 0;

    const selectionAfter = SelectionState.createEmpty(focusKey).merge({
        anchorOffset: focusOffset,
        focusOffset,
        hasFocus: true,
    });

    const currentBlockMap = currentContent.getBlockMap();
    const updatedBlockMap = currentBlockMap.delete(blockKey);

    return currentContent.merge({
        blockMap: updatedBlockMap,
        selectionBefore: selection,
        selectionAfter,
    });
};

export default ({ editorState, blockKey, direction }) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const blockBefore = currentContent.getBlockBefore(blockKey);
    const blockAfter = currentContent.getBlockAfter(blockKey);

    // If we only have one block, then we can't remove the entire block, so just delete the entire range.
    const updatedContent =
        !blockBefore && !blockAfter
            ? clearCurrentBlockText({ currentContent, blockKey })
            : deleteCurrentBlock({ currentContent, selection, blockKey, direction });

    return EditorState.push(
        editorState,
        updatedContent.set('selectionBefore', selection),
        EditorChangeType.REMOVE_RANGE,
    );
};
