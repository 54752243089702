import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

const getWindowInnerSize = () => [window.innerWidth, window.innerHeight];

const useObserveWindowSize = (throttleFreq = 100) => {
    // Initialise with current window dimensions.
    // Caution: must be a reference to a function, instead of executing the function.
    // Otherwise, it will get called on each render,
    // and retrieving window.innerWidth/height is an expensive operation
    // as it will trigger a forced reflow / layout
    const [windowSize, setWindowSize] = useState(getWindowInnerSize);

    const measureScreenSize = throttle(() => {
        setWindowSize(getWindowInnerSize());
    }, throttleFreq);

    useEffect(() => {
        measureScreenSize();

        window.addEventListener('resize', measureScreenSize);

        return () => {
            window.removeEventListener('resize', measureScreenSize);
        };
    }, []);

    return windowSize;
};

export default useObserveWindowSize;
