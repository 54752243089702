// Lib
import { countBy, map, min, max, clamp, flow, constant, identity } from 'lodash/fp';
import { reduce } from 'lodash';

// Utils
import { getBottom, getLeft, getRight, getTop } from '../../../../../../../common/maths/geometry/rect';
import { buildMovesForRects, getElementMeasurements, isArrangeable, shiftRectsForMoves } from '../arrangementUtils';

// Constants
import { ALIGNMENT_SIDE } from './alignmentConstants';

// Math utils
const alwaysZero = constant(0);
const clampPositive = clamp(0, Infinity);
const getMinValue = (prop) => flow(map(prop), min, clampPositive);
const getMaxValue = (prop) => flow(map(prop), max, clampPositive);
const getCenter = (valA, valB) => (valA + valB) / 2;

// Individual rectangle update functions
const shiftToLeft = (val) => (rect) => ({ ...rect, x: val, left: val, right: val + rect.width });
const shiftToRight = (val) => (rect) => ({ ...rect, x: val - rect.width, right: val, left: val - rect.width });
const shiftToTop = (val) => (rect) => ({ ...rect, y: val, top: val, bottom: val + rect.height });
const shiftToBottom = (val) => (rect) => ({ ...rect, y: val - rect.height, bottom: val, top: val - rect.height });
const shiftToHorizontalCenter = (val) => (rect) => ({
    ...rect,
    x: val - getCenter(0, rect.width),
    left: val - getCenter(0, rect.width),
    right: val + getCenter(0, rect.width),
});
const shiftToVerticalCenter = (val) => (rect) => ({
    ...rect,
    y: val - getCenter(0, rect.height),
    top: val - getCenter(0, rect.height),
    bottom: val + getCenter(0, rect.height),
});

// Value getters
const getHorizontalCenter = (rect) => getCenter(getLeft(rect), getRight(rect));
const getVerticalCenter = (rect) => getCenter(getTop(rect), getBottom(rect));

// Min possible value getters
const getMinPossibleLeft = alwaysZero;
const getMinPossibleTop = alwaysZero;
// Min possible right is the max width of any element
const getMinPossibleRight = getMaxValue('width');
const getMinPossibleBottom = getMaxValue('height');
// Min center point is half of the max value
const getMinPossibleHorizontalCenter = (rects) => getCenter(0, getMinPossibleRight(rects));
const getMinPossibleVerticalCenter = (rects) => getCenter(0, getMinPossibleBottom(rects));

// Min value getters
const getMinLeft = getMinValue('left');
const getMaxRight = getMaxValue('right');
const getMinTop = getMinValue('top');
const getMaxBottom = getMaxValue('bottom');
const getRectsHorizontalCenter = (rects) => getCenter(getMinLeft(rects), getMaxRight(rects));
const getRectsVerticalCenter = (rects) => getCenter(getMinTop(rects), getMaxBottom(rects));

const getMostCommon = (values, fallback) => {
    const counts = countBy(identity, values);

    const mostCommonEntry = reduce(
        counts,
        (acc, numberOfOccurrences, key) =>
            // If the number of occurrences is higher it's more common
            numberOfOccurrences > acc.numberOfOccurrences ||
            // Or if the number of occurrences is the same but it's closer to the fallback value, use it instead
            (numberOfOccurrences === acc.numberOfOccurrences && Math.abs(key - fallback) < Math.abs(acc.key - fallback))
                ? { key, numberOfOccurrences }
                : acc,
        { key: fallback, numberOfOccurrences: 1 },
    );

    return parseInt(mostCommonEntry.key, 10);
};

const alignRects =
    ({
        // Takes in rects and returns the min possible
        getMinPossibleVal,
        // Takes in rects and returns the value to use if there's no common value
        getFallbackVal,
        // Gets the current value for each rect
        getCurrentRectVal,
        // Updates the rect to the specified position
        alignTo,
    }) =>
    (rects) => {
        // Find minimum possible value
        const minVal = getMinPossibleVal(rects);

        // Find fallback value
        const fallbackVal = getFallbackVal(rects);

        // Find most common value, use fallback if necessary
        const values = rects.map(getCurrentRectVal);
        const mostCommonValue = getMostCommon(values, fallbackVal);

        // Use the max of the minimum and most common value
        const alignToValue = Math.max(minVal, mostCommonValue);

        // Update each rect to the new location
        return rects.map(alignTo(alignToValue));
    };

const alignRectsToLeft = alignRects({
    getMinPossibleVal: getMinPossibleLeft,
    getFallbackVal: getMinLeft,
    getCurrentRectVal: getLeft,
    alignTo: shiftToLeft,
});
const alignRectsToRight = alignRects({
    getMinPossibleVal: getMinPossibleRight,
    getFallbackVal: getMaxRight,
    getCurrentRectVal: getRight,
    alignTo: shiftToRight,
});
const alignRectsToHorizontalCenter = alignRects({
    getMinPossibleVal: getMinPossibleHorizontalCenter,
    getFallbackVal: getRectsHorizontalCenter,
    getCurrentRectVal: getHorizontalCenter,
    alignTo: shiftToHorizontalCenter,
});
const alignRectsToTop = alignRects({
    getMinPossibleVal: getMinPossibleTop,
    getFallbackVal: getMinTop,
    getCurrentRectVal: getTop,
    alignTo: shiftToTop,
});
const alignRectsToBottom = alignRects({
    getMinPossibleVal: getMinPossibleBottom,
    getFallbackVal: getMaxBottom,
    getCurrentRectVal: getBottom,
    alignTo: shiftToBottom,
});
const alignRectsToVerticalCenter = alignRects({
    getMinPossibleVal: getMinPossibleVerticalCenter,
    getFallbackVal: getRectsVerticalCenter,
    getCurrentRectVal: getVerticalCenter,
    alignTo: shiftToVerticalCenter,
});

const ALIGNMENT_FUNCTION_MAP = {
    [ALIGNMENT_SIDE.LEFT]: alignRectsToLeft,
    [ALIGNMENT_SIDE.RIGHT]: alignRectsToRight,
    [ALIGNMENT_SIDE.CENTER_HORIZ]: alignRectsToHorizontalCenter,
    [ALIGNMENT_SIDE.TOP]: alignRectsToTop,
    [ALIGNMENT_SIDE.BOTTOM]: alignRectsToBottom,
    [ALIGNMENT_SIDE.CENTER_VERT]: alignRectsToVerticalCenter,
};

export const getElementAlignmentMoves = ({ elements, currentBoard, measurements, gridSize, alignSide }) => {
    const elementsToAlign = elements.filter(isArrangeable);

    // First step is to correct measurements array based on element types
    const correctedMeasurements = getElementMeasurements({ elements: elementsToAlign, measurements, gridSize });

    // Find the point to align to
    const alignmentFn = ALIGNMENT_FUNCTION_MAP[alignSide];
    const alignedRects = alignmentFn(correctedMeasurements);
    const correctedAlignedRects = shiftRectsForMoves({ rects: alignedRects, elements: elementsToAlign, gridSize });

    // Get the moves to update to
    return buildMovesForRects({ elements: elementsToAlign, rects: correctedAlignedRects, gridSize, currentBoard });
};
