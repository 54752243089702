// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import {
    getCaption,
    getElementId,
    getFileProp,
    getImageHeight,
    getImageProp,
    getImageWidth,
    getMediaAudioOnly,
    getMediaOriginalHeight,
    getMediaOriginalWidth,
    getShowCaption,
} from '../../../../common/elements/utils/elementPropertyUtils';
import fileGetMediaPlayerType from '../utils/fileGetMediaPlayerType';
import { getData, getFetching } from '../../attachments/attachmentsSelector';
import { getElementModalSize } from '../../modal/elementModalUtils';
import { getMainEditorKey } from '../../utils/elementEditorUtils';
import fileCanShowMedia from '../utils/fileCanShowMedia';
import { getFileDataFilename, getFileDataType } from '../utils/fileElementUtils';

// Components
import DetailView from '../../../components/displayMode/DetailView';
import FilePreview from '../fileDetailView/FilePreview';
import FileIcon from '../../../components/fileIcon/FileIcon';
import EditableTitle from '../../../components/editableTitle/EditableTitle';
import FileUploadProgress from '../fileDetailView/FileUploadProgress';
import FileSecondaryDetails from '../fileDetailView/FileSecondaryDetails';
import Caption from '../../../components/caption/Caption';

// Hooks
import useObserveWindowSize from '../../../utils/dom/useObserveWindowSize';

// Constants
import { MEDIA_TYPES } from '../../../../common/links/richMediaConstants';

// Styles
import './FileModalDetailView.scss';

const hasPlayableMedia = (element) => {
    const mediaType = fileGetMediaPlayerType(element);
    return mediaType === MEDIA_TYPES.AUDIO || mediaType === MEDIA_TYPES.VIDEO;
};

const getContentWidth = ({ element, windowWidth }) => {
    const mediaType = fileGetMediaPlayerType(element);

    if (mediaType === MEDIA_TYPES.AUDIO) return windowWidth - 200;

    // Make videos 4 times bigger so even smaller videos will fill the screen
    if (mediaType === MEDIA_TYPES.VIDEO) {
        const videoWidth = getMediaOriginalWidth(element) || getImageWidth(element) || 320;
        return videoWidth * 4;
    }

    return getImageWidth(element) || 1280;
};

const getContentHeight = ({ element, gridSize, audioOnly }) => {
    const mediaType = fileGetMediaPlayerType(element);

    if (mediaType === MEDIA_TYPES.AUDIO || audioOnly) return gridSize * 5;

    // Make videos 4 times bigger so even smaller videos will fill the screen
    if (mediaType === MEDIA_TYPES.VIDEO) {
        const videoWidth = getMediaOriginalHeight(element) || getImageHeight(element) || 180;
        return videoWidth * 4;
    }

    return getImageHeight(element) || 720;
};

const getModalMinHeight = ({ element }) =>
    hasPlayableMedia(element)
        ? // If the file is playable media - then allow the height to be set by the media
          0
        : // Otherwise allow it to use the standard minimum modal height
          undefined;

const FileModalDetailView = (props) => {
    const { element, attachment, gridSize, showDownloadOption } = props;

    const [windowWidth, windowHeight] = useObserveWindowSize();

    const fileData = getFileProp(element) || getData(attachment);
    const imageData = getImageProp(element);

    const awaitingPreview = !imageData && fileCanShowMedia(element, fileData);
    const showProgress = awaitingPreview || getFetching(attachment);

    const audioOnly = getMediaAudioOnly(element);
    const fallbackTitle = getFileDataFilename(fileData);

    const imageWidth = getContentWidth({ element, windowWidth });
    const imageHeight = getContentHeight({ element, gridSize, audioOnly });
    const modalMinHeight = getModalMinHeight({ element, audioOnly });

    const caption = getShowCaption(element) && getCaption(element);

    const { modalWidth, modalHeight } = getElementModalSize({
        windowWidth,
        windowHeight,
        imageWidth,
        imageHeight,
        caption,
        footerSize: 55,
        modalMinHeight,
        modalMinWidth: 450,
        gridSize,
    });

    const editorKey = getMainEditorKey(props);

    return (
        <div className="FileModalDetailView" style={{ width: modalWidth, minHeight: modalHeight }}>
            <DetailView
                previewElement={<FilePreview {...props} widthPx={modalWidth} forcePreview audioOnly={audioOnly} />}
                iconElement={<FileIcon fileType={getFileDataType(fileData)} audioOnly={audioOnly} />}
                titleElement={
                    <EditableTitle
                        element={element}
                        elementId={getElementId(element)}
                        initialValue={fallbackTitle}
                        maxLength={500}
                        selectFirst
                        selectAllOnEdit
                        editorKey={editorKey}
                    />
                }
                secondaryElement={
                    showProgress ? (
                        <FileUploadProgress element={element} attachment={attachment} />
                    ) : (
                        <FileSecondaryDetails
                            element={element}
                            fileData={fileData}
                            showDownloadOption={showDownloadOption}
                        />
                    )
                }
            />

            <Caption element={element} textContent={getCaption(element)} captionVisible={getShowCaption(element)} />
        </div>
    );
};

FileModalDetailView.propTypes = {
    element: PropTypes.object,
    attachment: PropTypes.object,
    gridSize: PropTypes.number,
    showDownloadOption: PropTypes.bool,
};

export default FileModalDetailView;
