// Property accessors
import {
    getModifiedTime,
    getCreatedTime,
    getOrderProperty,
    getScore,
    getXPosition,
    getYPosition,
    isElementLocked,
} from './elementPropertyUtils';
import { isLocationInSection } from './elementLocationUtils';

export const sortByOrderProperty = (elementA, elementB) => getOrderProperty(elementA) - getOrderProperty(elementB);

export const sortByScoreProperty = (elementA, elementB) => getScore(elementA) - getScore(elementB);

export const sortByModified = (elementA, elementB) =>
    (getModifiedTime(elementA) || 0) - (getModifiedTime(elementB) || 0);

export const sortByCreated = (elementA, elementB) => (getCreatedTime(elementA) || 0) - (getCreatedTime(elementB) || 0);
export const sortByCreatedDesc = (elementA, elementB) => sortByCreated(elementB, elementA);

export const sortBy2dPosition = (elementA, elementB) => {
    const yPositionScore = getYPosition(elementA) - getYPosition(elementB);
    return yPositionScore !== 0 ? yPositionScore : getXPosition(elementA) - getXPosition(elementB);
};

// TODO-PERF This could probably be improved by doing the sorting in place, rather than using
//  the OOTB JavaScript sorting method. It's taking ~1ms for a board with 50 elements, and only
//  seems to execute on element updates, so it's probably not a massive priority
export const canvasElementSorter = (elA, elB) => {
    const isLockedA = isElementLocked(elA);
    const isLockedB = isElementLocked(elB);

    if (isLockedA && !isLockedB) return -1;
    if (isLockedB && !isLockedA) return 1;

    const score = sortByScoreProperty(elA, elB);
    if (score !== 0) return score;

    const modificationTimeScore = sortByModified(elA, elB);
    // The modification time is being set on the server, thus they're occasionally a ms apart and this
    // was causing the sort order to be slightly random.  Now we make sure the elements are at least more
    // than 2ms different in their modification time, otherwise we use their order property or 2D position.
    if (Math.abs(modificationTimeScore) > 2) return modificationTimeScore;

    const order = sortByOrderProperty(elA, elB);
    if (order !== 0) return order;

    return sortBy2dPosition(elA, elB);
};

export const filterAndSortElementsInSection = ({ elements, section, sortFn = sortByScoreProperty }) =>
    elements.valueSeq().filter(isLocationInSection(section)).sort(sortFn);
