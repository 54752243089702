/*eslint-disable */

import loadable from 'react-loadable';


const FileAcrobat = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_acrobat" */
        './jsx/file-acrobat'
    ),
    loading: () => null,
});
const FileImage = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_image" */
        './jsx/file-image'
    ),
    loading: () => null,
});
const FilePlaintext = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_plaintext" */
        './jsx/file-plaintext'
    ),
    loading: () => null,
});
const FileWord = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_word" */
        './jsx/file-word'
    ),
    loading: () => null,
});
const FileZip = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_zip" */
        './jsx/file-zip'
    ),
    loading: () => null,
});

export default {
    'file-acrobat': FileAcrobat,
    'file-image': FileImage,
    'file-plaintext': FilePlaintext,
    'file-word': FileWord,
    'file-zip': FileZip,
};
