// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import HotTableManager from '../../../../../element/table/manager/HotTableManager';
import { createStructuredSelector } from 'reselect';
import { getCurrentCellSelections } from '../../../../../element/table/tableSelector';
import { length } from '../../../../../../common/utils/immutableHelper';
import { getColorDisplayValue } from '../../../../../../common/colors/elementColorFormatUtil';
import { getThemeIsDarkMode } from '../../../../../user/account/accountModal/interface/themeSettings/themeSelector';
import { getBackgroundColorHex } from '../../../../../element/table/utils/tableCellFormattingUtils';

// Components
import ToolbarPopupTool from '../../ToolbarPopupTool';
import DesktopColorPopup from '../colorTools/popup/desktop/DesktopColorPopup';
import ColorToolIcon from '../colorTools/ColorToolIcon';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import {
    TABLE_DEFAULT_COLORS,
    TABLE_DEFAULT_HEADER_BACKGROUND_COLOR,
} from '../../../../../../common/table/tableConstants';

// Styles
import './TableCellColorTool.scss';

const mapStateToProps = createStructuredSelector({
    currentCellSelections: getCurrentCellSelections,
    isDarkMode: getThemeIsDarkMode,
});

const ColorToolPopupIcon = ({ selectedColor, isPopupOpen, currentCellSelections }) => {
    const color =
        currentCellSelections && currentCellSelections.size > 0
            ? getColorDisplayValue(selectedColor)
            : 'var(--ui-toolbar-tool-disabled-foreground)';
    const selectedColorDisplayValue =
        selectedColor === TABLE_DEFAULT_HEADER_BACKGROUND_COLOR ? 'var(--ws-table-header-row-background)' : color;

    return <ColorToolIcon color={selectedColorDisplayValue} isActive={isPopupOpen} />;
};

ColorToolPopupIcon.propTypes = {
    selectedColor: PropTypes.string,
    isPopupOpen: PropTypes.bool,
    currentCellSelections: PropTypes.object,
};

const TableCellColorTool = (props) => {
    const { selectedElements, currentCellSelections, isDarkMode } = props;

    const elementId = getElementId(selectedElements.first());
    const hotTableComponent = HotTableManager.getHotTableComponent(elementId);
    if (!hotTableComponent) return null;

    const { setCellBackground, getCellBackground, setTempColor } = hotTableComponent?.tableOperationsRef.current;
    const selectedColor = getCellBackground(currentCellSelections);

    return (
        <ToolbarPopupTool
            {...props}
            name="Cell color"
            buttonSelector=".ColorTool .ColorToolIcon"
            popupId={PopupIds.COLOR}
            toolName="color"
            className="table-cell-color-tool"
            disabled={length(currentCellSelections) === 0}
            customColorHandler={setCellBackground}
            IconComponent={ColorToolPopupIcon}
            PopupComponent={DesktopColorPopup}
            selectedColor={selectedColor}
            selectedColorHex={getBackgroundColorHex(selectedColor, isDarkMode)}
            customDefaultColors={Object.values(TABLE_DEFAULT_COLORS)}
            customTempColorHandler={setTempColor}
            currentCellSelections={currentCellSelections}
        />
    );
};

TableCellColorTool.propTypes = {
    selectedElements: PropTypes.object,
    currentCellSelections: PropTypes.object,
    isDarkMode: PropTypes.bool,
};

export default connect(mapStateToProps, null)(TableCellColorTool);
