// Constants
import { EDITOR_STATE_CHANGED, EDITOR_UNMOUNT } from '../editorConstants';

const initialState = null;

/**
 * Stores the current "originalEditorId".
 * This is used by clones and clone originals to determine when a clone (or clone original) editor is currently
 * being edited.
 * This allows us to use the state stored in this editor instead of
 */
export default function originalEditorIdReducer(state = initialState, action) {
    switch (action.type) {
        case EDITOR_STATE_CHANGED:
            return action.originalEditorId || action.editorId;
        case EDITOR_UNMOUNT:
            return initialState;
        default:
            return state;
    }
}
