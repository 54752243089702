// Selectors
import { getEditorContextTypeState } from './editorContextSelector';

// Constants
import { EDITOR_CONTEXT_REMOVE, EDITOR_CONTEXT_SET, EditorContextName } from './editorContextConstants';

// Types
import { AnyAction } from 'redux';
import { MarkdownEditorContextStateEntry } from './editorContextTypes';

interface SetEditorMarkdownContextAction extends AnyAction {
    contextName: EditorContextName.markdown;
    contextKey: string;
    context: MarkdownEditorContextStateEntry;
}

export type SetEditorContextActionContext = SetEditorMarkdownContextAction['context'];
export type SetEditorContextAction = SetEditorMarkdownContextAction;

export const setEditorContext = (
    contextName: EditorContextName,
    contextKey: string,
    context: SetEditorContextActionContext,
): SetEditorContextAction => ({
    type: EDITOR_CONTEXT_SET,
    contextName,
    contextKey,
    context,
});

export interface RemoveEditorContextAction extends AnyAction {
    contextName: EditorContextName;
    contextKey: string;
}

export const removeEditorContext = (contextName: EditorContextName, contextKey: string): RemoveEditorContextAction => ({
    type: EDITOR_CONTEXT_REMOVE,
    contextName,
    contextKey,
});

export const getEditorContextThunk =
    (contextName: EditorContextName) =>
    (dispatch: Function, getEditorState: Function): ReturnType<typeof getEditorContextTypeState> => {
        const editorState = getEditorState();
        return getEditorContextTypeState(editorState, { editorContextName: contextName });
    };
