// Lib
import * as Immutable from 'immutable';

// Constants
import { DOC_MODE_DEBUG_TOOL_NAME } from './debugDocumentModeConstants';
import { DEBUG_TOOL_TOGGLE } from '../debugConstants';
import { DOCUMENT_MODE_CALCULATION } from '../../workspace/legacyMobile/documentModeConstants';

const initialState = Immutable.fromJS({
    enabled: false,
    data: {},
});

export default (state = initialState, action) => {
    switch (action.type) {
        case DEBUG_TOOL_TOGGLE:
            if (action.toolName !== DOC_MODE_DEBUG_TOOL_NAME) return state;
            return state.set('enabled', action.enabled);
        case DOCUMENT_MODE_CALCULATION:
            return state.set('data', Immutable.fromJS(action.result));
        default:
            return state;
    }
};
