// Lib
import { flatMap, get } from 'lodash/fp';
import { FileTypeInfo, FileTypes } from './fileTypes';

const config = process.env.BROWSER
    ? // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('../../client/utils/getClientConfig').default()
    : require('../../../config');

export const FILE_DISPLAY_NAME = 'File';

// Upload constants
// Note - these values are specific to the route and are not the same as the 'fileConstants' file types
export const ACCEPTED_FILE_TYPES = {
    FILE: 'file',
    IMAGE: 'image',
    FILEPREVIEW: 'filepreview',
};

// FIXME: Worker threads don't have access to config
export const MEDIA_STORAGE_URL = config?.aws?.s3MediaStorageUrl;

export const MAX_FREE_FILES = 10;

const MEGABYTE = 1024 * 1000;
const GIGABYTE = 1000 * MEGABYTE;

export const DEFAULT_FILE_MAX_SIZE = {
    free: 50 * MEGABYTE,
    pro: 5 * GIGABYTE,
};
export const IMAGE_MIME_PATTERN = /^image\/(webp|png|gif|jpe?g|svg\+xml)/g;

export const FILE_TYPES_INFO: { [key in FileTypes]: FileTypeInfo } = {
    GENERIC: {
        type: FileTypes.GENERIC,
        preview: false,
        maxSize: DEFAULT_FILE_MAX_SIZE,
    },
    IMAGE: {
        type: FileTypes.IMAGE,
        mimePattern: IMAGE_MIME_PATTERN,
        extensions: [
            'jpg',
            'jpeg',
            'jpe',
            'gif',
            'png',
            'webp',
            'tif',
            'tiff',
            'bmp',
            'ico',
            'pbm',
            'pgm',
            'ppm',
            'svg',
        ],
        iconName: 'file-image',
        maxSize: {
            free: 10 * MEGABYTE,
            pro: 100 * MEGABYTE,
        },
        preview: true,
    },
    RAW_IMAGE: {
        type: FileTypes.RAW_IMAGE,
        extensions: [
            'jxr',
            'cr2',
            'crw',
            'dng',
            'mrw',
            'orf',
            'raw',
            'xmp',
            'nef',
            'x3f',
            'erf',
            'eps',
            'raf',
            'cube',
            '3dl',
        ],
        iconName: 'file-raw-image',
        preview: true,
    },
    SVG: {
        type: FileTypes.SVG,
        extensions: ['svg'],
        iconName: 'file-svg',
        preview: true,
    },
    ZIP: {
        type: FileTypes.ZIP,
        extensions: ['zip', 'rar', 'tar', '7z', 'gz'],
        iconName: 'file-zip',
        preview: false,
    },
    HTML: {
        type: FileTypes.HTML,
        extensions: ['htm', 'html'],
        iconName: 'file-html',
        preview: true,
    },
    CODE: {
        type: FileTypes.CODE,
        extensions: [
            'c',
            'css',
            'ini',
            'ins',
            'h',
            'js',
            'jar',
            'java',
            'json',
            'py',
            'pl',
            'php',
            'swift',
            'ts',
            'xml',
        ],
        iconName: 'file-code',
        preview: false,
    },
    PDF: {
        type: FileTypes.PDF,
        extensions: ['pdf'],
        iconName: 'file-acrobat',
        preview: true,
    },
    AUDIO: {
        type: FileTypes.AUDIO,
        extensions: [
            'mp3',
            'm4a',
            'mid',
            'wav',
            'wave',
            'amr',
            '3ga',
            'mka',
            'mpa',
            'opus',
            'oga',
            'pkf',
            'ra',
            'ram',
            'aiff',
            'pcm',
        ],
        iconName: 'file-audio',
        preview: false,
        mediaPlayerMimePattern: /^audio\/(x-)?(mp3|m4a|aac|mpeg|ogg|wav)/g,
    },
    VIDEO: {
        type: FileTypes.VIDEO,
        extensions: [
            'mp4',
            'm4p',
            'm4b',
            'm4r',
            'm4v',
            'mkv',
            'mk3d',
            'mks',
            'webm',
            'mov',
            'qt',
            'wmv',
            'divx',
            'xvid',
            'mpg',
            'mpeg',
            'mpeg4',
            'mp1',
            'mp2',
            'mlv',
            'm1a',
            'm2a',
            'mpv',
            'ogg',
            'ogv',
            'ogx',
            'ogm',
            'spx',
            'avi',
            'drp',
            'wma',
            'asf',
        ],
        iconName: 'file-movie',
        preview: true,
        mediaPlayerMimePattern: /^video\/(x-)?(mp4|quicktime|mov|mpeg|ogg)/g,
    },
    MSG: {
        type: FileTypes.MSG,
        extensions: ['msg'],
        iconName: 'file-plaintext',
        preview: false,
    },
    EMAIL: {
        type: FileTypes.EMAIL,
        extensions: ['eml'],
        iconName: 'file-email',
        preview: false,
    },
    TEXT: {
        type: FileTypes.TEXT,
        extensions: ['rtf', 'txt', 'md'],
        iconName: 'file-plaintext',
        preview: true,
    },
    CSV: {
        type: FileTypes.CSV,
        extensions: ['csv', 'tsv', 'tab'],
        iconName: 'file-numbers',
        preview: true,
    },
    DOCX: {
        type: FileTypes.DOCX,
        extensions: ['docx', 'docm', 'doc', 'odt', 'ott', 'otg', 'odg'],
        iconName: 'file-word',
        preview: true,
    },
    PPTX: {
        type: FileTypes.PPTX,
        extensions: ['pptx', 'pptm', 'ppt', 'odp', 'otp'],
        iconName: 'file-ppt',
        preview: true,
    },
    XLSX: {
        type: FileTypes.XLSX,
        extensions: ['xlsx', 'xlsm', 'xls', 'ods', 'odc', 'otc'],
        iconName: 'file-excel',
        preview: true,
    },

    PAGES: {
        type: FileTypes.PAGES,
        extensions: ['pages'],
        iconName: 'file-pages',
        preview: false,
    },
    KEYNOTE: {
        type: FileTypes.KEYNOTE,
        extensions: ['key'],
        iconName: 'file-keynote',
        preview: false,
    },
    NUMBERS: {
        type: FileTypes.NUMBERS,
        extensions: ['numbers'],
        iconName: 'file-numbers',
        preview: false,
    },
    SKETCH: {
        type: FileTypes.SKETCH,
        extensions: ['sketch'],
        iconName: 'file-sketch',
        preview: true,
        forcePreview: true,
    },

    AFDESIGN: {
        type: FileTypes.AFDESIGN,
        extensions: ['afdesign'],
        iconName: 'file-image',
        preview: false,
    },
    // Adobe Creative Suite
    PSD: {
        type: FileTypes.PSD,
        extensions: ['psd'],
        iconName: 'file-photoshop',
        preview: true,
        forcePreview: true,
    },
    // Lightroom
    LR: {
        type: FileTypes.LR,
        extensions: ['lrtemplate', 'lrcat', 'lrdata', 'lrdb', 'lrprev', 'lrpreview'],
        iconName: 'file-lightroom',
        preview: false,
    },
    // Premiere Pro
    PR: {
        type: FileTypes.PR,
        extensions: ['cfa', 'cpa', 'prproj'],
        iconName: 'file-premiere',
        preview: false,
    },
    // 3D Model
    MODEL_3D: {
        type: FileTypes.MODEL_3D,
        extensions: [
            'max',
            'blend',
            'mp',
            'ma',
            '3ds',
            'dwg',
            'gltf',
            'gltb',
            'obj',
            'fbx',
            'stl',
            'stp',
            'collade',
            'glb',
            'dae',
            'step',
            'wrl',
            'x3d',
            '3mf',
            'amf',
        ],
        iconName: 'file-3d',
        preview: false,
    },
    // Illustrator
    AI: {
        type: FileTypes.AI,
        extensions: ['ai', 'eps', 'ps', 'ai3', 'ai4', 'ai5', 'ai6', 'ai7', 'ai8', 'psf'],
        iconName: 'file-illustrator',
        preview: true,
        forcePreview: true,
    },
    // InDesign
    IND: {
        type: FileTypes.IND,
        extensions: ['ind', 'indd', 'idlk', 'indt'],
        iconName: 'file-indesign',
        preview: true,
        forcePreview: true,
    },
    // After Effects
    AE: {
        type: FileTypes.AE,
        extensions: ['aec', 'aep', 'aet', 'aetx', 'amx'],
        iconName: 'file-aftereffects',
        preview: false,
    },
    // Experience Design
    XD: {
        type: FileTypes.XD,
        extensions: ['xd'],
        iconName: 'file-experience-design',
        preview: true,
        forcePreview: true,
    },
    // Muse
    MUSE: {
        type: FileTypes.MUSE,
        extensions: ['muse', 'mulib'],
        iconName: 'file-muse',
        preview: false,
    },
    // Dreamweaver
    DW: {
        type: FileTypes.DW,
        extensions: ['dw', 'dwt'],
        iconName: 'file-dreamweaver',
        preview: true,
        forcePreview: true,
    },

    // Axure
    AXURE: {
        type: FileTypes.AXURE,
        extensions: ['rp'],
        iconName: 'file-axure',
        preview: false,
    },

    // Fonts
    FONT: {
        type: FileTypes.FONT,
        extensions: ['otf', 'ttc', 'ttf'],
        iconName: 'file-font',
        preview: true,
    },
    NON_PREVIEWABLE_FONT: {
        type: FileTypes.NON_PREVIEWABLE_FONT,
        extensions: ['eot', 'woff', 'woff2', 'ffil', 'afm', 'pfa', 'pfb', 'pfm', 'tfil'],
        iconName: 'file-font',
        preview: false,
    },
};

// File constants
export const COMMON_FILE_EXTENSIONS = {
    PLAIN_TEXT: 'txt',
    MARKDOWN: 'md',
    DOCX: 'docx',
    PDF: 'pdf',
    HTML: 'html',
    PNG: 'png',
};

export const BLACKLISTED_FILENAMES = [
    '.htaccess',
    'web.config',
    'robots.txt',
    'crossdomain.xml',
    'clientaccesspolicy.xml',
];

export const BLACKLISTED_EXTENSIONS = [
    'ade',
    'adp',
    'apk',
    'appx',
    'appxbundle',
    'bat',
    'cab',
    'chm',
    'cmd',
    'com',
    'cpl',
    'dll',
    'dmg',
    'exe',
    'hta',
    'htm',
    'html',
    'isp',
    'iso',
    'jse',
    'lib',
    'lnk',
    'mde',
    'msc',
    'msi',
    'msix',
    'msixbundle',
    'msp',
    'mst',
    'nsh',
    'pif',
    'ps1',
    'scr',
    'sct',
    'shb',
    'sys',
    'vb',
    'vbe',
    'vbs',
    'vxd',
    'wsc',
    'wsf',
    'wsh',
];

export const SUPPORTED_EXTENSTIONS = flatMap(get('extensions'), FILE_TYPES_INFO);
