import React from 'react';
import PropTypes from 'prop-types';

import { prop } from '../../../common/utils/immutableHelper';

import ElementReactions from '../reactions/ElementReactions';

const CommentReactions = ({ _id, content, ...rest }) => (
    <ElementReactions {...rest} elementId={_id} reactions={prop('reactions', content)} />
);

CommentReactions.propTypes = {
    _id: PropTypes.string,
    content: PropTypes.object,
};

export default CommentReactions;
