// Utils
import { prop } from '../../immutableHelper';
import rawHasText from './rawHasText';

export default (rawState) => {
    if (typeof rawState === 'string') return rawState;
    if (!rawHasText(rawState)) return '';

    const blocks = prop('blocks', rawState);
    return blocks.reduce((accText, block, index) => {
        const text = prop('text', block);
        return index === 0 ? text : `${accText}\n${text}`;
    }, '');
};
