// Lib
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Actions
import { elementModalElementIdSet, elementModalElementIdClear } from './elementModalActions';

export default (DecoratedComponent) => {
    const elementModalElementIdSynchroniser = ({ params: { elementId }, ...props }) => {
        const dispatch = useDispatch();
        const dispatchCurrentElementIdSet = (elementId) => dispatch(elementModalElementIdSet(elementId));
        const dispatchCurrentElementIdClear = () => dispatch(elementModalElementIdClear());

        useEffect(() => {
            dispatchCurrentElementIdSet(elementId);
        }, [elementId]);

        useEffect(() => () => dispatchCurrentElementIdClear(), []);

        return <DecoratedComponent elementId={elementId} {...props} />;
    };

    elementModalElementIdSynchroniser.propTypes = {
        params: PropTypes.object,
    };

    return elementModalElementIdSynchroniser;
};
