// Lib
import { curry } from 'lodash/fp';

// Utils
import { isOverlapping } from './rectComparisonUtils';
import { getBottom, getLeft, getRight, getTop } from './rectPropertyUtils';

export const distanceFromAnySide = curry((rect1, rect2) => {
    if (isOverlapping(rect1, rect2)) return 0;

    const mostLeftRect = getLeft(rect1) < getLeft(rect2) ? rect1 : rect2;
    const mostRightRect = getRight(rect1) > getRight(rect2) ? rect1 : rect2;
    // X difference
    let xDiff = getLeft(mostRightRect) - getRight(mostLeftRect);
    xDiff = Math.max(0, xDiff);

    const upperRect = getTop(rect1) < getTop(rect2) ? rect1 : rect2;
    const lowerRect = getBottom(rect1) > getBottom(rect2) ? rect1 : rect2;
    // X difference
    let yDiff = getTop(lowerRect) - getBottom(upperRect);
    yDiff = Math.max(0, yDiff);

    return Math.hypot(xDiff, yDiff);
});
