// Lib
import { NativeTypes } from 'react-dnd-html5-backend';
import { values, flow } from 'lodash';

// Utils
import {
    dropTargetAlreadyHandled,
    handleElementDrop,
    handleFileDrop,
    handleTaskDrop,
} from '../../utils/dnd/dragAndDropUtils';
import { translate, getBoundingRect, getTopLeft } from '../../../common/maths/geometry/rect';
import isDraggedItemATask from './task/isDraggedItemATask';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';

const DEFAULT_ELEMENT_POSITION = { x: 5, y: 5 };

/* Drop Target functionality. */
/*
 NOTES ON DROP LOCATION:
 Unfortunately due to some discrepancies between the HTML5DragAndDropBackend and the TouchBackend the TouchBackend
 does not guarantee the ordering of drop targets at this point in time.
 This means that the dropping of an element onto another element which is within another element doesn't guarantee
 that the top element will be the first to execute a drop handler function.
 For example, if a board is on top of a canvas and a card is dropped onto a board, there's no guarantee that the
 board's drop handler will fire before the canvas's drop handler.
 As such we need to make sure that all drop targets that can potentially have other drop targets on top of them
 check to see if a child has handled the drop before handling it itself.
 */
const dropResultFn = (getLocation) => (props, monitor, domNode) => {
    // Don't handle the drop if it's already been handled by a child.
    if (dropTargetAlreadyHandled(monitor)) return;

    const { dropPermitted } = props;

    if (dropPermitted === false) {
        console.warn("You don't have the permission to modify that board.");
        return;
    }

    if (isDraggedItemATask(monitor)) {
        return handleTaskDrop(getLocation)(props, monitor, domNode);
    }

    if (monitor.getItemType() === NativeTypes.FILE) {
        return handleFileDrop(getLocation)(props, monitor, domNode);
    }

    return handleElementDrop(getLocation)(props, monitor, domNode);
};

/*
 * inboxDropResultFn
 *  - Handle drop by placing element inside inbox
 */

const getElementInboxLocation = ({ element }) => getListChildNewLocation({ listId: getPhysicalId(element) });

export const inboxDropResultFn = dropResultFn(getElementInboxLocation);

/*
 * canvasDropResultFn
 *  - Handle drop by placing element on canvas, while preserving the element layouts
 *  - Elements will be placed on top left on canvas, starting from the default element position
 */

const convertToAbsGridUnits =
    (gridSize) =>
    ({ x, y }) => ({
        x: Math.abs(x / gridSize),
        y: Math.abs(y / gridSize),
    });

const getElementCanvasLocation = (props, monitor) => {
    const { element, gridSize } = props;
    const { unscaledElementOffsetsMap } = monitor.getItem();

    // Get position of which the elements needs to be shifted in order to preserve their positions
    const elementPositions = values(unscaledElementOffsetsMap);
    const position = flow([
        getBoundingRect,
        getTopLeft,
        convertToAbsGridUnits(gridSize),
        translate(DEFAULT_ELEMENT_POSITION),
    ])(elementPositions);

    return {
        parentId: getPhysicalId(element),
        section: BoardSections.CANVAS,
        position,
    };
};

export const canvasDropResultFn = dropResultFn(getElementCanvasLocation);
