import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ReminderBellIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon reminder-bell"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M12 4.25a6.001 6.001 0 0 1 5.987 5.586l.37 5.36a.65.65 0 0 0 .643.554h.75v1.9H14.5a2.5 2.5 0 0 1-4.995.114l-.003-.114H4.25v-1.9H5c.326 0 .597-.24.644-.593l.369-5.32A6.001 6.001 0 0 1 12 4.25zm.997 13.4h-1.996a1 1 0 0 0 1.992.067l.004-.067zM12 5.75a4.5 4.5 0 0 0-4.49 4.19l-.36 5.16c0 .381-.1.74-.273 1.05h10.238l-.078-.153a1.998 1.998 0 0 1-.185-.845L16.49 9.94A4.5 4.5 0 0 0 12 5.75z"
        />
    </svg>
);
const Memo = memo(ReminderBellIcon);
export default Memo;
