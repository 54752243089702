// Lib
import { flatMap, isEmpty } from 'lodash/fp';
import memoizeOne from 'memoize-one';

// Utils
import { createShallowSelector } from '../../../../utils/milanoteReselect/milanoteReselect';
import { convertUserToMention, getNameAbbreviations } from './mentionUtils';

// Selectors
import { getCurrentUserId, isGuestSelector } from '../../../../user/currentUserSelector';
import { currentBoardEditorsSelector } from '../../../../workspace/header/currentBoardHeader/sharing/popup/editing/boardEditorPanelSelector';
import {
    currentBoardBlockedUserIdsSelector,
    currentBoardHasPublicEditPermissionsSelector,
    currentBoardHasPublishedFeedbackEnabledSelector,
    currentBoardIsPublishedSelector,
    currentBoardRegisteredSharedUsers,
} from '../../../../utils/permissions/elementPermissionsSelector';
import { getRegisteredContacts } from '../../../../user/contacts/userContactsSelector';
import asBoolMap from '../../../../../common/utils/lib/asBoolMap';
import { getUserId } from '../../../../../common/users/utils/userPropertyUtils';

// Prevent mention suggestions from changing whenever users load (even though we're not editing)
const EMPTY_ARRAY = [];

// NOTE: Currently there's not much point in memoising this as so many operations cause changes to the users map
const getPossibleUserNames = memoizeOne((allUsers) =>
    allUsers ? flatMap(getNameAbbreviations, allUsers.toArray()) : EMPTY_ARRAY,
);

const buildSuggestions = memoizeOne((allUsers, filteredUsers, currentBoardEditorIds) => {
    if (!allUsers.size) return EMPTY_ARRAY;

    const possibleUserNames = getPossibleUserNames(allUsers);

    return allUsers
        .valueSeq()
        .toJS()
        .map(convertUserToMention(possibleUserNames, filteredUsers, currentBoardEditorIds));
});

const getNonBlockedUsers = memoizeOne((allUsers, blockedUserIds) => {
    if (isEmpty(blockedUserIds)) return allUsers;

    const blockedUserDictionary = asBoolMap(blockedUserIds);

    return allUsers.filter((user) => !blockedUserDictionary[getUserId(user)]);
});

/**
 * Returns the full list of users that can be mentioned.
 * Filtering is performed within the MilanoteEditor.
 *
 * This selector is designed to only calculate suggestions when required, to improve general performance.
 */
export const getUserMentionSuggestionsWhenEditing = () =>
    createShallowSelector(
        (state, { isEditing }) => isEditing,
        currentBoardRegisteredSharedUsers,
        isGuestSelector,
        getRegisteredContacts,
        currentBoardBlockedUserIdsSelector,
        currentBoardHasPublicEditPermissionsSelector,
        currentBoardHasPublishedFeedbackEnabledSelector,
        currentBoardIsPublishedSelector,
        getCurrentUserId,
        currentBoardEditorsSelector,
        (
            isEditing,
            currentBoardSharedUsers,
            isGuest,
            allUsers,
            blockedUserIds,
            currentBoardHasPublicEdit,
            currentBoardHasFeedbackEnabled,
            currentBoardIsPublished,
            currentUserId,
            currentBoardEditors,
        ) => {
            if (isGuest || !isEditing) return EMPTY_ARRAY;

            const showAllSuggestions =
                currentBoardHasPublicEdit || (currentBoardIsPublished && currentBoardHasFeedbackEnabled);

            // filter out the current user from the suggestions as we don't want to show unless they're searched for
            const currentBoardEditorIds = currentBoardEditors
                .map((editor) => editor.userId)
                .filter((userId) => userId !== currentUserId);

            const filteredUsers = showAllSuggestions
                ? getNonBlockedUsers(allUsers, blockedUserIds)
                : currentBoardSharedUsers;

            return buildSuggestions(allUsers, filteredUsers, currentBoardEditorIds);
        },
    );

/**
 * Almost identical to getUserMentionSuggestionsWhenEditing however this will calculate the possible suggestions
 * even when not editing.  Thus this should only be used for components that aren't always displayed, such as
 * the assignment popup.
 */
export const getUserMentionSuggestions = createShallowSelector(
    currentBoardRegisteredSharedUsers,
    isGuestSelector,
    getRegisteredContacts,
    currentBoardBlockedUserIdsSelector,
    currentBoardHasPublicEditPermissionsSelector,
    currentBoardHasPublishedFeedbackEnabledSelector,
    currentBoardIsPublishedSelector,
    currentBoardEditorsSelector,
    (
        currentBoardSharedUsers,
        isGuest,
        allUsers,
        blockedUserIds,
        currentBoardHasPublicEdit,
        currentBoardHasFeedbackEnabled,
        currentBoardIsPublished,
        currentBoardEditors,
    ) => {
        if (isGuest) return EMPTY_ARRAY;

        const showAllSuggestions =
            currentBoardHasPublicEdit || (currentBoardIsPublished && currentBoardHasFeedbackEnabled);

        const currentBoardEditorIds = currentBoardEditors.map((editor) => editor.userId);

        // we need currentBoardSharedUsers to filter out blocked users from the suggestions
        const filteredUsers = showAllSuggestions
            ? getNonBlockedUsers(allUsers, blockedUserIds)
            : currentBoardSharedUsers;

        return buildSuggestions(allUsers, filteredUsers, currentBoardEditorIds);
    },
);
