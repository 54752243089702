// Lib
import { useEffect, useCallback } from 'react';
import { ShapeInfo, Intersection } from 'kld-intersections';
import { throttle } from 'lodash';
import memoizeOne from 'memoize-one';

// Utils
import logger from '../../../../../logger/logger';
import * as rectLib from '../../../../../../common/maths/geometry/rect';
import { getPathStroke, getStrokeBoundingRect, getSvgPathFromStroke } from '../../drawingEditorUtils';

const findMatchingPathIds = (marqueeDimensions, pathShapeInfos) => {
    const matchingPathIds = {};

    if (!marqueeDimensions || !pathShapeInfos) return matchingPathIds;

    const marqueShapeInfo = ShapeInfo.rectangle(marqueeDimensions);

    pathShapeInfos.forEach((pathShapeInfo) => {
        if (!pathShapeInfo) return;

        const { id, shapeInfo, boundingRect } = pathShapeInfo;

        const doesIntersect =
            rectLib.containsRect(marqueeDimensions, boundingRect) ||
            Intersection.intersect(shapeInfo, marqueShapeInfo).status === 'Intersection';

        if (doesIntersect) {
            matchingPathIds[id] = true;
        }
    });

    return matchingPathIds;
};

const getPathStrokeForShapeInfo = (path) => {
    if (!path) return null;

    const { stroke, thinning, ...rest } = path;

    // Paths without thinning will have their stroke cached on the path
    if (thinning !== 0) return stroke;

    // Otherwise build a perfect freehand stroke so we can use the boundaries
    return getPathStroke({ ...rest, size: rest.size * 1.1 }, true);
};

const createPathShapeInfos = memoizeOne((paths) =>
    paths.map((path) => {
        try {
            const pathStroke = getPathStrokeForShapeInfo(path);
            const svgStroke = getSvgPathFromStroke(pathStroke, true);
            return {
                id: path.id,
                shapeInfo: ShapeInfo.path(svgStroke),
                boundingRect: getStrokeBoundingRect(path?.stroke),
            };
        } catch (e) {
            logger.error('Failed to create shape info for', path, e);
            return null;
        }
    }),
);

const useDrawingEditorSelectionMarqueePathFinder = ({
    paths,
    isMarqueeSelectMode,
    isAppendMode,
    initialSelectedPathIdsMap,
    marqueeDimensions,
    setSelectedPathIds,
}) => {
    const throttledCheckMatches = useCallback(
        throttle((inputMarqueeDimensions, inputPaths, inputIsAppendMode, inputInitialSelectedPathIdsMap) => {
            const shapeInfos = createPathShapeInfos(inputPaths);
            const newSelectedPathIdsMap = findMatchingPathIds(inputMarqueeDimensions, shapeInfos);
            const newSelectedPathIds = Object.keys(newSelectedPathIdsMap);

            if (!inputIsAppendMode) return setSelectedPathIds(newSelectedPathIds);

            const selectedPathIds = [];

            const initialSelectedIds = Object.keys(inputInitialSelectedPathIdsMap);

            initialSelectedIds.forEach((id) => {
                if (newSelectedPathIdsMap[id]) return;
                selectedPathIds.push(id);
            });

            newSelectedPathIds.forEach((id) => {
                if (inputInitialSelectedPathIdsMap[id]) return;
                selectedPathIds.push(id);
            });

            setSelectedPathIds(selectedPathIds);
        }, 50),
        [],
    );

    useEffect(() => {
        if (!isMarqueeSelectMode) return;
        throttledCheckMatches(marqueeDimensions, paths, isAppendMode, initialSelectedPathIdsMap);
    }, [marqueeDimensions]);
};

export default useDrawingEditorSelectionMarqueePathFinder;
