import { getFileContentType } from '../files/fileTypeInferenceUtils';
import { getFileName } from './getFileName';

/**
 * Builds a data URI string.
 *
 * @param mediaType {string} E.g. 'image/svg+xml'
 * @param extension {string} E.g. 'base64'
 * @param data {string}
 * @return {string} 'data:image/svg+xml;base64,......'
 */
export const getDataUri = ({
    mediaType = 'image/svg+xml',
    extension,
    data,
}: {
    mediaType: string;
    extension: string;
    data: string;
}): string => `data:${mediaType}${extension ? `;${extension}` : ''},${data}`;

/**
 * Creates a file from a Data URI.
 */
export const dataUriToFile = async (dataUri: string, filename: string): Promise<File | null> => {
    // Break down the content of the Data URL into its components.
    const contents = dataUri.split(',');
    const mimeTypeStr = contents[0].match(/:(.*?);/)?.[1];
    const base64ImageData = contents[1];
    if (!mimeTypeStr || !base64ImageData) return null;

    // Convert the base64 characters from ASCII to UInt8 values.
    const byteCharacters = atob(base64ImageData);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteNumbers], { type: mimeTypeStr });

    // Set up a temporary placeholder File, in order to determine what type of image it is.
    const _tempFile = new File([blob], filename);
    const fileContentType = await getFileContentType(_tempFile);

    // Create the final File's name, and create and return the resultant File.
    const fileName = getFileName(_tempFile, fileContentType);
    return new File([blob], fileName);
};
