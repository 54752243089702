import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationReminderClockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-reminder-clock"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#FFF" />
            <path
                fill="#767C86"
                d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1zm0 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11zm0 1.315a.75.75 0 0 1 .75.75v3.126l1.82 1.834a.749.749 0 1 1-1.063 1.057l-2.184-2.2-.073-.176v-3.64a.75.75 0 0 1 .75-.75z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationReminderClockIcon);
export default Memo;
