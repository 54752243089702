// Lib
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import * as Immutable from 'immutable';

// Singleton
import dragAndDropStateSingleton from './dragAndDropStateSingleton';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { focusFakeInput } from '../../utils/ipad/ipadUtils';
import * as elementFactory from '../../../common/elements/elementRegistry';
import { isBoardLike, isColumn } from '../../../common/elements/utils/elementTypeUtils';
import { getElementId, getIsCollapsed } from '../../../common/elements/utils/elementPropertyUtils';
import translateDropResultIntoElementLocation from './utils/translateDropResultIntoElementLocation';

// Actions
import { createAndEditElement } from '../actions/elementActions';
import { endOperation } from '../../utils/undoRedo/undoRedoActions';
import { dragStart, dragEnd } from '../../reducers/draggingActions';
import { closePopupsMatching } from '../../components/popupPanel/popupActions';

// Constants
import { ELEMENT_DND_TYPE } from '../../../common/elements/elementConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { DRAG_PREVIEW_ID } from '../../reducers/draggingConstants';
import { MetaToolIds } from '../../workspace/toolbar/config/toolDefinitions/toolbarMetaToolConfig';

// Styles
import './DraggableElementCreationTool.scss';

const defaultGetDefaultContentFn = () => ({});

/* WORKAROUND:
 * This is a synthetic event that we're creating to ensure that the React "SelectEventPlugin" works with
 * the drag and drop (react-dnd) and text editing (draft-js) libraries.
 *
 * React adds an "onSelect" event to content editable elements.  This is not standard in HTML, but the
 * Draft JS text editor relies on this event to track the position of the cursor or the selection state.
 *
 * The SelectEventPlugin prevents the onSelect event whenever a drag occurs (in case the user is selecting,
 * so it has a flag variable "mouseDown" that gets set to true on the mouseDown event and false on the
 * mouseUp event.
 * We're using the HTML5 backend of the React DnD library, so it uses the standard HTML5 drag and drop
 * events.  With HTML5 drag and drop the mouseMove and mouseUp events are not fired once a drag starts.
 * Thus the React "SelectEventPlugin" receives a "mouseDown" event, but never receives a mouse up event
 * when the drop finishes.
 * As such the SelectEventPlugin doesn't set mouseDown flag to false, and the onSelect event will not fire
 * until a future mouse click forces the mouseDown flag back to false.
 *
 * Because we immediately set the element into its editing state when an element tool drop occurs, the
 * onSelect event is not being fired.
 *
 * By firing this synthetic event we force the SelectEventPlugin to set its mouseDown flag to false, and as
 * such the onSelect events will now fire when entering the editing mode after being dropped onto the
 * canvas.
 */
const triggerSyntheticMouseUpEvent = () => {
    const event = new MouseEvent('mouseup', { bubbles: true });
    document.body.dispatchEvent(event);
};

/**
 * This function improves the behaviour of editing on certain devices, such as iPads,
 * by immediately focusing a "fake input", and then allowing focus to move to the new element's
 * input/content editable.
 * It seems that these devices don't allow asynchronous focus shifting to places far from where
 * the cursor currently is, for some reason. So this will shift the cursor to the place where
 * the element is dropped, and then when the new element is created it will take the cursor back
 * itself.
 */
const handleFakeDropFocus = (dropResult, domNode) => {
    if (!platformSingleton.features.isTouch) return;

    if (!dropResult?.clientOffset) return;

    const { clientOffset } = dropResult;

    focusFakeInput(clientOffset, domNode, false);
};

export const wasDraggableElementDragSuccess = (props, monitor) => {
    // Doing nothing if it was not dropped onto a compatible target
    if (!monitor.didDrop()) return false;

    const dropResult = monitor.getDropResult();

    // do nothing if no valid location was returned
    if (!dropResult.location) return false;

    // don't allow dropping onto trash
    if (dropResult.location.section === BoardSections.TRASH) return false;

    return true;
};

/**
 * Determines whether the newly created element should be selected and/or edited based on where
 * the new element is being created.
 */
const shouldSelectOrEditOnCreate = (dropResult, currentBoardId) => {
    const { dropTargetElement } = dropResult;

    if (!dropTargetElement) return false;

    // When dropping onto the current board we always want to select and edit the new element
    if (getElementId(dropTargetElement) === currentBoardId) return true;

    // If dropping onto a sub-board or alias we never want to select or edit the new element
    if (isBoardLike(dropTargetElement)) return false;

    // Don't select when dropping onto collapsed columns
    if (isColumn(dropTargetElement) && getIsCollapsed(dropTargetElement)) return false;

    // When dropping anywhere else, do select and edit
    return true;
};

const DraggableElementCreationTool = (props) => {
    const {
        beforeDragStart,
        beforeDragEnd,
        customOnDrop,
        elementType,
        getDefaultContent = defaultGetDefaultContentFn,
        currentBoardId,
        selectOnCreate = true,
        editOnCreate = true,
        creationSource,
        onDragEnd,
        gridSize,
        children,
        className,
        delayTouchStart,

        getElementScaledCustomDragOffset,
        getElementScaledGrabOffset,
        currentUserId,
        getContextZoomScale = () => 1,
    } = props;

    const dispatch = useDispatch();

    const startDragging = ({ source }) => dispatch(dragStart({ source }));
    const endDragging = (dropState) => dispatch(dragEnd(dropState));
    const dispatchEndMoveOperation = () => dispatch(endOperation('move'));
    const onDrop = ({
        elementType,
        location,
        content,
        currentBoardId,
        transactionId,
        select,
        edit = true,
        creationSource,
    }) => {
        const result = dispatch(
            createAndEditElement({
                elementType,
                location,
                content,
                currentBoardId,
                transactionId,
                select,
                edit,
                creationSource,
            }),
        );

        select &&
            dispatch(
                closePopupsMatching({
                    predicateFn: (activePopupId) => activePopupId.startsWith(MetaToolIds.MORE),
                }),
            );

        return result;
    };

    const fakeDropRef = useRef();

    const [{ isDragging }, connectDragSource, connectDragPreview] = useDrag({
        item: { id: DRAG_PREVIEW_ID, type: ELEMENT_DND_TYPE },
        begin: (monitor) => {
            beforeDragStart?.(props);

            startDragging({ source: creationSource });

            const element = Immutable.fromJS(
                elementFactory.createElementObject({
                    id: DRAG_PREVIEW_ID,
                    elementType,
                    content: getDefaultContent(props),
                    location: {},
                    meta: {
                        creator: currentUserId,
                    },
                }),
            );

            const elementId = getElementId(element);
            const zoomScaleOnDragSource = getContextZoomScale();

            // Need to do this to ensure that if a comment switches to its collapsed form, it's situated
            // on the mouse correctly
            dragAndDropStateSingleton.scaledGrabOffset = getElementScaledGrabOffset(props, monitor, element);

            // Create
            const scaledCustomDragOffset = getElementScaledCustomDragOffset(props, monitor, element);

            return {
                id: DRAG_PREVIEW_ID,
                element,
                isNewElement: true,
                draggedElementIds: [elementId],
                draggedElements: [element],
                zoomScaleOnDragSource,
                scaledCustomDragOffset,
                hoveredTypesRegistry: {},
            };
        },
        end: async (item, monitor) => {
            const dragSuccess = wasDraggableElementDragSuccess(props, monitor);

            beforeDragEnd?.(dragSuccess);

            const operationTransactionId = dispatchEndMoveOperation();

            if (!dragSuccess) {
                endDragging({ source: creationSource });
                onDragEnd?.(false);
                endDragging({ source: creationSource });
                return;
            }

            const dropResult = monitor.getDropResult();
            const { unscaledElementOffsetsMap, element, scaledCustomDragOffset } = monitor.getItem();

            const location = translateDropResultIntoElementLocation({
                dropResult,
                unscaledElementOffsetsMap,
                gridSize,
                elementId: getElementId(element),
                scaledCustomDragOffset,
            });

            const shouldSelectOrEdit = shouldSelectOrEditOnCreate(dropResult, currentBoardId);
            const shouldEdit = editOnCreate && shouldSelectOrEdit;
            const shouldSelect = selectOnCreate && shouldSelectOrEdit;

            if (shouldEdit) handleFakeDropFocus(dropResult, fakeDropRef.current);

            triggerSyntheticMouseUpEvent();

            const transactionId = dropResult.transactionId || operationTransactionId;

            const onDropFn = customOnDrop || onDrop;

            const createdElementId = await onDropFn({
                elementType,
                location,
                content: getDefaultContent(props),
                currentBoardId,
                transactionId,
                creationSource,
                select: shouldSelect,
                edit: shouldEdit,
            });

            if (createdElementId) {
                const dropState = dropResult ? dropResult.dropState : null;
                endDragging({
                    ...dragAndDropStateSingleton,
                    ...dropState,
                    source: creationSource,
                    droppedElementIds: [createdElementId],
                });
            } else {
                endDragging({ source: creationSource });
            }

            onDragEnd?.(true, createdElementId);
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        if (!connectDragPreview) return;
        connectDragPreview(getEmptyImage());
    }, [connectDragPreview]);

    return connectDragSource(
        <div
            ref={fakeDropRef}
            className={classNames('DraggableElementCreationTool', className, { dragging: isDragging })}
        >
            {children}
        </div>,
        { delayTouchStart },
    );
};

DraggableElementCreationTool.propTypes = {
    elementType: PropTypes.string.isRequired,
    currentBoardId: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),

    getDefaultContent: PropTypes.func,
    beforeDragStart: PropTypes.func,
    beforeDragEnd: PropTypes.func,

    getElementScaledCustomDragOffset: PropTypes.func,
    getElementScaledGrabOffset: PropTypes.func,

    selectOnCreate: PropTypes.bool,
    editOnCreate: PropTypes.bool,

    delayTouchStart: PropTypes.number,
};

export default DraggableElementCreationTool;
