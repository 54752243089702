// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash/fp';

// Components
import PageContent from './PageContent';

import ModalErrorBoundary from './errorBoundary/ModalErrorBoundary';
import ModalCloseButton from './ModalCloseButton';
import ModalBackButton from './ModalBackButton';

// Styles
import './Modal.scss';

class Modal extends React.Component {
    componentWillMount() {
        const { noTransition } = this.props;

        // If we don't want to transition the blur / grayscale
        noTransition ? document.body.classList.add('no-transition') : document.body.classList.remove('no-transition');
    }

    componentDidMount() {
        const { bodyClass = 'modal' } = this.props;

        // On a page change, componentWillUnmount of previous component might be called after componentWillMount
        // of the next component, so it is important to do this in componentDidMount to make sure bodyClass is
        // added and removed properly
        !isEmpty(bodyClass) && document.body.classList.add(bodyClass);
    }

    componentWillUnmount() {
        const { bodyClass = 'modal' } = this.props;

        !isEmpty(bodyClass) && document.body.classList.remove(bodyClass);
    }

    render() {
        const { children, className, onClick, styled, showBack } = this.props;

        return (
            <div className="modal-container" onClick={onClick}>
                <div className={classNames('Modal', className)}>
                    <ModalErrorBoundary {...this.props}>
                        {showBack && <ModalBackButton />}
                        <ModalCloseButton {...this.props} />
                        <PageContent className="modal-content" styled={styled}>
                            {children}
                        </PageContent>
                    </ModalErrorBoundary>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    close: PropTypes.func,
    showBack: PropTypes.bool,

    styled: PropTypes.bool,
    blur: PropTypes.bool,
    noTransition: PropTypes.bool,
    bodyClass: PropTypes.string,
};

export default Modal;
