// Lib
import { EditorState, SelectionState, Modifier } from 'draft-js';

// Constants
import { EditorChangeType } from '../draftjsConstants';

// From https://github.com/ngs/draft-js-markdown-shortcuts-plugin
const changeCurrentInlineStyle = (editorState, matchArr, style, character) => {
    const { index } = matchArr;

    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const key = selection.getStartKey();

    const block = currentContent.getBlockForKey(key);

    const currentInlineStyle = block.getInlineStyleAt(index);
    const newStyle = currentInlineStyle.add(style);

    // The entire string that has been matched on. E.g. " *my bold text*"
    const allMatchedText = matchArr[0];
    // The text before the start of the text to style.  E.g. " "
    const preTextToStyleText = matchArr[1];
    // The text to style. E.g. "my bold text"
    const textToStyle = matchArr[2];

    // Don't replace the spaces or characters before the markdown character
    const anchorOffset = index + preTextToStyleText.length;
    const focusOffset = index + allMatchedText.length - 1;

    const wordSelection = SelectionState.createEmpty(key).merge({
        anchorOffset,
        focusOffset,
    });

    let newContentState = Modifier.replaceText(currentContent, wordSelection, textToStyle, newStyle);

    if (character === ' ') {
        newContentState = Modifier.insertText(newContentState, newContentState.getSelectionAfter(), character);
    }

    let newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_INLINE_STYLE);

    newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());

    // Return the style back to how it was before the markdown
    const originalStyle = editorState.getCurrentInlineStyle();

    return EditorState.setInlineStyleOverride(newEditorState, originalStyle);
};

export default changeCurrentInlineStyle;
