// Lib
import { isEmpty, chunk, assign, union } from 'lodash';

// Util
import { http } from '../utils/services/http';
import { getElement } from '../../common/elements/utils/elementTraversalUtils';
import { isSkeleton } from '../../common/elements/utils/elementTypeUtils';
import { asyncResource } from '../utils/services/http/asyncResource/asyncResource';
import { getDataExpiryTimestamp } from '../utils/services/http/axiosResponseUtils';

// Actions
import { loadElements } from './actions/elementActions';
import { commentsLoad } from './comment/store/commentActions';
import { getTokenElementIdGroupsThunk } from '../utils/permissions/permissionsActions';

// State selectors
import { getElements } from './selectors/elementSelector';

// Constants
import { ResourceTypes } from '../utils/services/http/asyncResource/asyncResourceConstants';
import { TIMES } from '../../common/utils/timeUtil';

type TokenGroup = {
    token: string;
    elementIds: string[];
};

/**
 * Ensures that each group of elements is less than
 */
const getTokenElementBatches = (tokenGroups: TokenGroup[]): TokenGroup[] => {
    const groups: TokenGroup[] = [];

    tokenGroups.forEach(({ token, elementIds }) => {
        const elementIdBatches = chunk(elementIds, 200);

        elementIdBatches.forEach((elementIdBatch) => {
            groups.push({ token, elementIds: elementIdBatch });
        });
    });

    return groups;
};

type FetchElementsArgs = {
    elementIds: string[];
    force?: boolean;
    loadAncestors?: boolean;
    permissionIdsOverride?: string[];
};

/**
 * Fetch multiple elements from the server.
 */
export const fetchElements =
    (args: FetchElementsArgs) =>
    async (dispatch: Function): Promise<void> =>
        dispatch(
            asyncResource(
                ResourceTypes.elements,
                args.elementIds,
                args.force,
            )(async (elementIdsToFetch) => {
                const { loadAncestors, permissionIdsOverride } = args;

                const tokenGroups = await dispatch(
                    getTokenElementIdGroupsThunk({ elementIds: elementIdsToFetch, permissionIdsOverride }),
                );
                const batches = getTokenElementBatches(tokenGroups);

                const responses = await Promise.all(
                    batches.map((batch) =>
                        http({
                            url: 'elements',
                            timeout: 30 * TIMES.SECOND,
                            params: {
                                loadAncestors,
                                tokens: batch.token || undefined,
                                ids: batch.elementIds.join(','),
                            },
                        }),
                    ),
                );

                let commentsMap = {};
                let elementsMap = {};
                let errorsMap = {};
                let count = 0;
                let allIds: string[] = [];

                let expiry: number | undefined = undefined;

                responses.forEach((response) => {
                    const { data = {} } = response;
                    const { comments = {}, elements = {}, elementCount = 0, errors = {}, ids = [] } = data;

                    commentsMap = assign(commentsMap, comments);
                    elementsMap = assign(elementsMap, elements);
                    errorsMap = assign(errorsMap, errors);
                    count += elementCount;
                    allIds = union(allIds, ids);

                    const responseExpiry = getDataExpiryTimestamp(response);

                    if (responseExpiry && (!expiry || responseExpiry < expiry)) {
                        expiry = responseExpiry;
                    }
                });

                !isEmpty(elementsMap) && dispatch(loadElements(elementsMap));

                !isEmpty(commentsMap) && dispatch(commentsLoad({ comments: commentsMap }));

                return {
                    comments: commentsMap,
                    elements: elementsMap,
                    elementCount: count,
                    errors: errorsMap,
                    ids: allIds,
                    asyncResource: {
                        errors: errorsMap,
                        expiry,
                    },
                };
            }),
        );

/**
 * Similar to fetchElements however if any of the elements are skeletons it will force fetch them.
 */
// FIXME-PERMISSIONS - This is a bit of a hack to get around the issue when users are currently viewing
//  boards that this user has already visited with a permissionId. A better way might be to pre-fetch
//  permission IDs.
export const fetchElementsForceSkeletons =
    (args: FetchElementsArgs) =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        const { elementIds, force } = args;

        if (force) return dispatch(fetchElements(args));

        const state = getState();

        const elements = getElements(state);

        const overriddenForce = elementIds.some((elementId) => {
            const element = getElement(elements, elementId);
            return isSkeleton(element);
        });

        return dispatch(fetchElements({ ...args, force: overriddenForce }));
    };
