export const TOGGLE_SPELL_CHECK = 'TOGGLE_SPELL_CHECK';

export const AUTOUPDATE_DOWNLOAD_START = 'AUTOUPDATE_DOWNLOAD_START';
export const AUTOUPDATE_DOWNLOAD_COMPLETE = 'AUTOUPDATE_DOWNLOAD_COMPLETE';
export const AUTOUPDATE_DOWNLOAD_PROGRESS = 'AUTOUPDATE_DOWNLOAD_PROGRESS';
export const AUTOUPDATE_UPDATE_INSTALL = 'AUTOUPDATE_UPDATE_INSTALL';

export const AUTOUPDATE_CHECK_START = 'AUTOUPDATE_CHECK_START';
export const AUTOUPDATE_CHECK_COMPLETE = 'AUTOUPDATE_CHECK_COMPLETE';

export const AUTOUPDATE_FORCE_POLL = 'AUTOUPDATE_FORCE_POLL';
export const AUTOUPDATE_ERROR = 'AUTOUPDATE_ERROR';

export const ELECTRON_APP_SLEEP = 'ELECTRON_APP_SLEEP';
export const ELECTRON_APP_WAKE = 'ELECTRON_APP_WAKE';

export const ELECTRON_STORE_REHYDRATED = 'ELECTRON_STORE_REHYDRATED';

export const ELECTRON_APP_CLOSE_MESSAGE_SET = 'ELECTRON_APP_CLOSE_MESSAGE_SET';
export const ELECTRON_APP_CLOSE_MESSAGE_CLEAR = 'ELECTRON_APP_CLOSE_MESSAGE_CLEAR';

export const AUTOUPDATE_STATE = {
    NONE: undefined,
    CHECKING: 'CHECKING',
    DOWNLOADING: 'DOWNLOADING',
    READY: 'READY',
    ERROR: 'ERROR',
};
