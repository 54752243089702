// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import PrimaryErrorMessage from './PrimaryErrorMessage';

// Constants
import {
    SERVICE_STATUS_URL,
    HELP_CENTER_RESTRICTED_COUNTRY_TROUBLESHOOTING,
} from '../../../../common/utils/urlConstants';
import { RESPONSE_ERROR } from '../../../../common/error/errorConstants';

const getErrorMessage = (primaryError) => {
    if (primaryError?.isNetworkError) {
        return (
            <span>
                We couldn&apos;t connect to the Milanote servers.
                <br />
                Check the{' '}
                <a href={SERVICE_STATUS_URL} target="_blank" rel="noopener noreferrer">
                    status page
                </a>
                &nbsp;on our website for updates.
            </span>
        );
    }

    if (!primaryError?.message || primaryError.status === 500) {
        return (
            <span>
                Sorry, something went wrong. Please try again or check the{' '}
                <a href={SERVICE_STATUS_URL} target="_blank" rel="noopener noreferrer">
                    status page
                </a>{' '}
                if you continue to have problems.
            </span>
        );
    }

    if (primaryError?.code === RESPONSE_ERROR.EMBARGOED_IP) {
        return (
            <span>
                Your country or region is restricted{' '}
                <a href={HELP_CENTER_RESTRICTED_COUNTRY_TROUBLESHOOTING} target="_blank" rel="noopener noreferrer">
                    Find out more
                </a>
            </span>
        );
    }

    return primaryError.message;
};

const AuthErrorMessage = (props) => {
    const { primaryError, hide } = props;

    return <PrimaryErrorMessage message={getErrorMessage(primaryError)} show={!hide && !!primaryError} />;
};

AuthErrorMessage.propTypes = {
    primaryError: PropTypes.object,
    hide: PropTypes.bool,
};

export default AuthErrorMessage;
