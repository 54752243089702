// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { stubTrue } from 'lodash/fp';

// Utils
import { getElementId, getWidth } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

export default ({ getMinWidth, getIsEnabled = stubTrue, getDefaultMaxWidth }) =>
    (DecoratedComponent) => {
        class ElementSaveAspectRatioResizeDecorator extends React.Component {
            setElementSize = (size) => {
                const { setElementSize, element, dispatchUpdateElement } = this.props;
                dispatchUpdateElement({
                    id: getElementId(element),
                    changes: {
                        width: Math.max(Math.round(size.width), getMinWidth(this.props)),
                    },
                });
                setElementSize(size);
            };

            handleDoubleClick = (event) => {
                const { gridSize, element } = this.props;

                if (!getDefaultMaxWidth) return;

                event.preventDefault();
                event.stopPropagation();

                const savedWidth = getWidth(element) || ELEMENT_DEFAULT_WIDTH;

                const defaultMaxWidth = getDefaultMaxWidth(this.props);

                // Only toggle to the default width of the media if we're currently at the default element width
                const width = savedWidth === ELEMENT_DEFAULT_WIDTH ? defaultMaxWidth / gridSize : ELEMENT_DEFAULT_WIDTH;

                return this.setElementSize({ width });
            };

            render() {
                let additionalProps = null;
                if (getIsEnabled(this.props)) {
                    additionalProps = {
                        setElementSize: this.setElementSize,
                        handleDoubleClick: this.handleDoubleClick,
                    };
                }

                return <DecoratedComponent {...this.props} {...additionalProps} />;
            }
        }

        ElementSaveAspectRatioResizeDecorator.propTypes = {
            element: PropTypes.object,
            gridSize: PropTypes.number,
            dispatchUpdateElement: PropTypes.func,
            setElementSize: PropTypes.func,
        };

        return ElementSaveAspectRatioResizeDecorator;
    };
