import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MediaPlayerPauseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon media-player-pause"
        {...props}
    >
        <path
            fill="#767C86"
            fillRule="evenodd"
            d="M6 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h2zm6 0a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h2z"
        />
    </svg>
);
const Memo = memo(MediaPlayerPauseIcon);
export default Memo;
