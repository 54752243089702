// Lib
import { Modifier } from 'draft-js';

// Constants
import { STYLE_COMMANDS } from '../richText/richTextConstants';

export default (editorState) => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    return Modifier.setBlockType(content, selection, STYLE_COMMANDS.UNSTYLED);
};
