// Lib
import React from 'react';

// Components
import ErrorBoundary from '../../error/errorBoundary/ErrorBoundary';
import ModalErrorRenderer from './ModalErrorRenderer';

const ModalErrorBoundary = (props) => <ErrorBoundary {...props} ErrorRenderer={ModalErrorRenderer} />;

export default ModalErrorBoundary;
