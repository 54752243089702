import base62 from 'base62';
import { CLIENT_OFFSET_MAX, RADIX } from './uidConstants';
import padLeft from './padLeft';

let currentOffset = null;

export const initOffset = (value) => {
    currentOffset = value;
};
export const nextOffset = () => {
    currentOffset = (currentOffset + 1) % CLIENT_OFFSET_MAX;
    return currentOffset;
};

export const createClientId = (serverId = 0, workerId = 0) =>
    base62.encode(serverId % RADIX) + base62.encode(workerId % RADIX) + padLeft(base62.encode(nextOffset()), '0000');
