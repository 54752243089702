// Utils
import { dispatchSelector } from '../reduxUtils';
import { analyticsEvent } from '../../analytics';
import { isEmpty, length, prop } from '../../../common/utils/immutableHelper';
import { getNewTransactionId } from './undoRedoTransactionManager';
import { getClosestUpTaskListId } from '../../../common/elements/utils/elementTraversalUtils';

// Selectors
import { getElements } from '../../element/selectors/elementSelector';
import { getSelectedElementIds } from '../../element/selection/selectedElementsSelector';
import {
    getCurrentOperation,
    getMostRecentTransactionId,
    getNextRedoTransactionIdSelector,
    getNextUndoTransactionIdSelector,
    getNextUndoLocationChangeTransactionIdSelector,
    getNextUndoElementUpdateTransactionIdSelector,
    getNextUndoElementCreateTransactionIdSelector,
    getNextClipperOperationCompleteTransactionIdSelector,
    getNextRedoClipperOperationCompleteTransactionIdSelector,
} from './undoRedoSelector';

// Constants
import { ELEMENT_EDIT_START } from '../../../common/elements/selectionConstants';
import {
    START_OPERATION,
    END_OPERATION,
    POP_UNDO_STACK_TO,
    UNDO_REDO_REMOVE_TRANSACTION,
    UNDO_REDO_FINISH,
} from './undoRedoConstants';

const NO_ACTIONS = [];

/**
 * Removes a specific transaction from the undo/redo stack.
 */
export const removeUndoRedoTransaction = (transactionId) => ({
    transactionId,
    type: UNDO_REDO_REMOVE_TRANSACTION,
});

/**
 * Don't dispatch edit start actions if the element is no longer selected.
 * This improves usability so that draft JS doesn't capture the undo/redo functionality
 * when an element isn't already being edited.
 */
const canDispatchAction = (getState, action) => {
    if (!action) return false;

    if (action.type !== ELEMENT_EDIT_START) return true;

    const state = getState();
    const currentlySelectedElementIds = getSelectedElementIds(state);

    // Can only switch the editing state if we only have one selected element
    // and the element we're trying to select is the selected element or a child of it
    const canSetEditingState = !isEmpty(currentlySelectedElementIds) && length(currentlySelectedElementIds) === 1;

    if (!canSetEditingState) return false;

    const selectedElementId = currentlySelectedElementIds.first();

    const { id } = action;

    if (id === selectedElementId) return true;

    // If the selected element isn't the one to edit, check to see if it's
    // a task within a selected task list. If so it's safe to start editing
    const elements = getElements(state);

    const taskListId = getClosestUpTaskListId(elements, id);

    return taskListId === selectedElementId;
};

/**
 * Remove undo actions that are no longer valid.
 * For example - if we want to set the currently edited element then the element must
 *  be selected, so only execute the action if it is selected, otherwise skip to the
 *  next action.
 */
const filterAndDispatchActions = (dispatch, getState, actions) => {
    // Track whether any actions were actually dispatched
    let dispatchCount = 0;

    actions.forEach((action) => {
        // NOTE: we need to sequentially filter and dispatch the actions to ensure
        //  the state is updated for each iteration of the filtering
        if (!canDispatchAction(getState, action)) return;

        dispatchCount++;
        return dispatch(action);
    });

    return dispatchCount;
};

const getTransactionActions = (stackName, getState) => {
    const state = getState();
    const stack = state.getIn(['undoRedo', stackName]);

    if (stack.size <= 0) return NO_ACTIONS;

    const transactionId = stack.peek();
    return state.getIn(['undoRedo', 'transactions', transactionId]) || [];
};

export const undoAction = () => (dispatch, getState) => {
    let undoActions = getTransactionActions('undoStack', getState);
    undoActions = undoActions.reverse().map(prop('undo'));
    filterAndDispatchActions(dispatch, getState, undoActions);

    if (undoActions.size) {
        analyticsEvent('used-undo');
    }
};

export const popUndoStackTo = (transactionId) => ({
    type: POP_UNDO_STACK_TO,
    transactionId,
});

export const redoAction = () => (dispatch, getState) => {
    let redoActions = getTransactionActions('redoStack', getState);

    if (redoActions === NO_ACTIONS) return;

    redoActions = redoActions.map(prop('redo'));

    const dispatchCount = filterAndDispatchActions(dispatch, getState, redoActions);

    // If we dispatched an action on redo, end the redo action
    if (dispatchCount > 0) return;

    // Otherwise skip to the next redo and try that
    const state = getState();
    const nextRedoTransactionId = getNextRedoTransactionIdSelector(state);
    dispatch(removeUndoRedoTransaction(nextRedoTransactionId));
    dispatch(redoAction());
};

// Operation Actions
export const startOperation = (name) => (dispatch, getState) => {
    const state = getState();
    const operation = getCurrentOperation(state);

    // Don't dispatch a new operation if we're already in an operation.
    // This doesn't support parallel operations. If it's required this should be revisited
    if (operation) return;

    return dispatch({
        type: START_OPERATION,
        name,
        transactionId: getNewTransactionId(),
    });
};

export const endOperation = (name) => (dispatch, getState) => {
    const state = getState();
    const operation = getCurrentOperation(state);

    if (!operation) return;

    const transactionId = getMostRecentTransactionId(state);
    dispatch({
        type: END_OPERATION,
        name,
        transactionId,
    });
    return transactionId;
};

export const finishUndoingOrRedoing = () => ({
    type: UNDO_REDO_FINISH,
});

// Use these so selectors are only run when required
export const getMostRecentTransactionIdThunk = dispatchSelector(getMostRecentTransactionId);
export const getNextUndoTransactionId = dispatchSelector(getNextUndoTransactionIdSelector);
export const getNextUndoLocationChangeTransactionId = dispatchSelector(getNextUndoLocationChangeTransactionIdSelector);
export const getNextUndoElementUpdateTransactionId = dispatchSelector(getNextUndoElementUpdateTransactionIdSelector);
export const getNextUndoElementCreateTransactionId = dispatchSelector(getNextUndoElementCreateTransactionIdSelector);
export const getNextClipperOperationCompleteTransactionId = dispatchSelector(
    getNextClipperOperationCompleteTransactionIdSelector,
);

export const getNextRedoTransactionId = dispatchSelector(getNextRedoTransactionIdSelector);
export const getNextRedoClipperOperationCompleteTransactionId = dispatchSelector(
    getNextRedoClipperOperationCompleteTransactionIdSelector,
);
