// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { LINE_STYLE, LINE_WEIGHT } from '../../../../common/lines/lineConstants';

const getDashArray = ({ lineStyle, strokeWidth }) => {
    switch (lineStyle) {
        case LINE_STYLE.DASHED:
            return '5, 5';
        case LINE_STYLE.DOTTED:
            return `0, ${strokeWidth * 2}`;
        default:
            return null;
    }
};

const getLineCap = ({ lineStyle }) => {
    if (lineStyle !== LINE_STYLE.DOTTED) return null;
    return 'round';
};

export const getLineSvgPath = (bezier) => {
    if (!bezier) return null;

    const bezStart = bezier.points[0];
    const bezControl = bezier.points[1];
    const bezEnd = bezier.points[2];

    return `M${bezStart.x} ${bezStart.y} Q ${bezControl.x} ${bezControl.y} ${bezEnd.x} ${bezEnd.y}`;
};

const LinePath = (props) => {
    const { bezier, strokeWidth, lineStyle, ...rest } = props;

    return (
        <path
            d={getLineSvgPath(bezier)}
            strokeWidth={strokeWidth || LINE_WEIGHT.M}
            strokeDasharray={getDashArray({ lineStyle, strokeWidth })}
            strokeLinecap={getLineCap({ lineStyle })}
            {...rest}
        />
    );
};

LinePath.propTypes = {
    bezier: PropTypes.object,
    lineStyle: PropTypes.string,
    strokeWidth: PropTypes.number,
    gridSize: PropTypes.number,
};

export default LinePath;
