// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import OptimisticImage from './OptimisticImage';

// Utils
import { getUserImage } from '../../../common/users/utils/userPropertyUtils';

// Constants
import { IMAGE_TYPES } from '../../../common/media/mediaConstants';

// Styles
import './AvatarImage.scss';

const AvatarImage = ({ attachment, user, sizePx, onImageLoadCb }) => (
    <div className="AvatarImage">
        <OptimisticImage
            imageType={IMAGE_TYPES.AVATAR}
            imageDetails={getUserImage(user)}
            attachmentDetails={attachment}
            onImageLoadCb={onImageLoadCb}
            widthPx={sizePx}
            forcedSize={{ width: sizePx, height: sizePx }}
        />
    </div>
);

AvatarImage.propTypes = {
    user: PropTypes.object.isRequired,
    sizePx: PropTypes.number,
    attachment: PropTypes.object,
    onImageLoadCb: PropTypes.func,
};

export default AvatarImage;
