import { PopupIds } from '../../components/popupPanel/popupConstants';

export const ELEMENT_COUNT_INCREASE = 'ELEMENT_COUNT_INCREASE';
export const ELEMENT_COUNT_DECREASE = 'ELEMENT_COUNT_DECREASE';
export const ELEMENT_COUNT_SET = 'ELEMENT_COUNT_SET';

export const ELEMENT_COUNT_WARNING_START = 20;
export const ELEMENT_COUNT_WARNING_INTERVAL = 10;

export const ELEMENT_COUNT_LIMIT_POPUP = PopupIds.BILLING_ALERT;
