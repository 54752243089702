// Handlers
import keyBindingFn from './keyBindingFn';
import handleReturn from '../handleReturn';
import handlePastedText from '../handlePastedText';

/**
 * Requires the following props:
 *  - stopEditing
 */
export default () => ({
    handleReturn,
    keyBindingFn,
    handlePastedText,
});
