// Lib
import { EditorState } from 'draft-js';

// Utils
import clipRange from '../clipRange';

// Constants
import { EditorChangeType } from '../../draftjsConstants';

/**
 * This either inserts the text at a collapsed selection cursor, or replaces the selected characters with the text.
 */
export default (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const newContentState = clipRange(contentState, selection);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);

    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};
