export const enterFullscreen = (): void => {
    if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreenElement) return;

    if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.mozRequestFullscreen) {
        document.documentElement.mozRequestFullscreen();
    } else {
        document.documentElement.requestFullscreen();
    }
};

export const exitFullscreen = (): void => {
    if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozExitFullScreen) {
        document.mozExitFullScreen();
    } else {
        document.exitFullscreen();
    }
};
