import { getShowMedia, getImageProp, getImageError } from '../../../../common/elements/utils/elementPropertyUtils';
import fileGetMediaPlayerType from './fileGetMediaPlayerType';

export const fileCanShowPreview = (element) => {
    const hasMediaPlayerType = !!fileGetMediaPlayerType(element);
    if (hasMediaPlayerType) return true;

    const hasImage = !!getImageProp(element);
    const hasImageError = !!getImageError(element);

    return hasImage && !hasImageError;
};

const fileIsShowingFilePreview = (element) => {
    if (getShowMedia(element) === false) return false;

    return fileCanShowPreview(element);
};

export default fileIsShowingFilePreview;
