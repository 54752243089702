import { TextAlignment } from '../../../../table/CellTypeConstants';
import { TiptapContentNode } from '../../../tiptapTypes';

type DraftData = Partial<{
    ['text-align-center']: boolean;
    ['text-align-left']: boolean;
    ['text-align-right']: boolean;
}>;

const getAlignment = (node: TiptapContentNode): DraftData => {
    switch (node.attrs?.textAlign) {
        case TextAlignment.CENTER:
            return { ['text-align-center']: true };
        case TextAlignment.RIGHT:
            return { ['text-align-right']: true };
        default:
            return {};
    }
};

export const getGlobalData = (node: TiptapContentNode): DraftData => {
    return {
        ...getAlignment(node),
    };
};
