// Lib
import { isEmpty } from 'lodash/fp';

// Constants
import { SELECTORS_CACHE_UPDATE } from './cacheConstants';
import { ELEMENTS_PURGE } from '../../../common/elements/elementsConstants';

// Types
import { AnyAction } from 'redux';
import { ElementCountMap } from '../../../common/elements/elementCountsUtils';

export type ElementCountsMapCacheState = Record<string, ElementCountMap>;

/**
 * This builds up the elementCountsMap cache, rather than clobbering it each time.
 * This is so when navigating between boards the element counts don't need to be recalculated
 * (thus causing a flash).
 */
const handleSelectorsCacheUpdate = (
    state: ElementCountsMapCacheState,
    action: AnyAction,
): ElementCountsMapCacheState => {
    if (!action.data.elementCountsMap) return state;

    return {
        ...state,
        ...action.data.elementCountsMap,
    };
};

/**
 * Clear out any counts for purged elements.
 */
const handleElementsPurge = (state: ElementCountsMapCacheState, action: AnyAction): ElementCountsMapCacheState => {
    if (!action.elementIds || isEmpty(action.elementIds)) return state;

    const newState = { ...state };

    for (const elementId of action.elementIds) {
        delete newState[elementId];
    }

    return newState;
};

/**
 * Handles element counts map cache changes.
 */
export const elementCountsMapCacheReducer = (
    state: ElementCountsMapCacheState,
    action: AnyAction,
): ElementCountsMapCacheState => {
    switch (action.type) {
        case SELECTORS_CACHE_UPDATE:
            return handleSelectorsCacheUpdate(state, action);
        case ELEMENTS_PURGE: {
            return handleElementsPurge(state, action);
        }
        default:
            return state;
    }
};

export default elementCountsMapCacheReducer;
