// Lib
import React, { ReactElement } from 'react';

// Utils
import { getLinkFavicon, getLinkUrl } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import LinkURL from './LinkURL';
import Icon from '../../components/icons/Icon';

// Styles
import './LinkHeader.scss';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';

type LinkHeaderProps = {
    element: ImMNElement;
};

const LinkHeader = ({ element }: LinkHeaderProps): ReactElement | null => {
    const isLoaded = getLinkUrl(element);

    if (!isLoaded) return null;

    const favicon = getLinkFavicon(element);

    const faviconElement = favicon ? <img className="favicon" src={favicon} /> : <Icon name="link-icon" />;

    return (
        <div className="LinkHeader">
            <div className="favicon-container">{faviconElement}</div>
            <LinkURL element={element} />
        </div>
    );
};

export default LinkHeader;
