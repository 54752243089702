// Utils
import localStorageService from '../../../../../utils/services/localStorage/localStorage';

// Constants
import { THEMES } from './themeConstants';

// Persistence
export const THEME_LOCAL_STORAGE_KEY = 'milanote.theme';

export const getThemeLocalStorage = () => localStorageService.getItem(THEME_LOCAL_STORAGE_KEY);
export const setThemeLocalStorage = (value) => localStorageService.setItem(THEME_LOCAL_STORAGE_KEY, value);

// Browser functions
export const isBrowserThemeDarkMode = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
export const isBrowserThemeLightMode = () => window.matchMedia('(prefers-color-scheme: light)').matches;
export const isBrowserThemeNotSpecified = () => window.matchMedia('(prefers-color-scheme: no-preference)').matches;
export const isBrowserThemeSupported = () =>
    isBrowserThemeDarkMode() || isBrowserThemeLightMode() || isBrowserThemeNotSpecified();

export const getBrowserTheme = () => {
    if (isBrowserThemeDarkMode()) return THEMES.DARK;
    if (isBrowserThemeLightMode()) return THEMES.LIGHT;
    return null;
};

// Interface
export const retrieveThemeMode = () => getThemeLocalStorage() || THEMES.LIGHT;

export const retrieveTheme = () => {
    const persistedMode = getThemeLocalStorage();

    if (!persistedMode) return THEMES.LIGHT;

    if (persistedMode === THEMES.MATCH_BROWSER) return getBrowserTheme();

    return persistedMode;
};
