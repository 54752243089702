import { RefObject, useRef, TouchEvent, useCallback } from 'react';
import {
    getNewActiveSnapPoint,
    getNewSheetHeight,
    getSnapPointTopValue,
    setSheetHeight,
} from '../utils/snapPointUtils';
import { getIsScrollPossible, isMoveScroll } from '../utils/sheetScrollUtils';

const DRAGGING_CLASS = 'dragging';
const PREVENT_OVERSCROLL_CLASS = 'prevent-overscroll';
const IGNORE_DRAGS_ATTRIBUTE = 'data-sheet-ignore-drags';

const INITIAL_DRAG_STATE = {
    isDragging: false,
    isScrolling: false,
    isScrollPossible: false,
    isDragCancelled: false,
    hasDoneFirstTouchMove: false,
    sheetTouchOffset: 0,
    currentY: 0,
    velocity: 0,
    prevMoveTimestamp: 0,
};

export type DragState = {
    isDragging: boolean;
    isScrolling: boolean;
    isScrollPossible: boolean;
    isDragCancelled: boolean;
    hasDoneFirstTouchMove: boolean;
    sheetTouchOffset: number;
    currentY: number;
    velocity: number;
    prevMoveTimestamp: number;
};

export type ModalSheetHandlers = {
    handleSheetTouchStart: (event: TouchEvent) => void;
    handleSheetTouchMove: (event: TouchEvent) => void;
    handleSheetTouchEnd: () => void;
    handleSheetTouchCancel: () => void;
};

/**
 * Handle the touch events on the sheet.
 * @param activeSnapPoint
 * @param sheetRef
 * @param sheetContentRef
 * @param sheetInitialised
 * @param snapPointsState
 * @param goToSnapPoint
 * @param updateActiveSnapPoint
 * @param closeSheet
 */
const useModalSheetHandlers = (
    activeSnapPoint: number,
    sheetRef: RefObject<HTMLDivElement>,
    sheetContentRef: RefObject<HTMLDivElement>,
    sheetInitialised: boolean,
    snapPointsState: number[],
    goToSnapPoint: (snapPoint: number) => void,
    updateActiveSnapPoint: (snapPoint: number, goToPoint: boolean) => void,
    closeSheet: () => void,
): ModalSheetHandlers => {
    const dragState = useRef<DragState>(INITIAL_DRAG_STATE);
    const highestSnapPoint = Math.max(...snapPointsState);
    const highestSnapPosition = getSnapPointTopValue(highestSnapPoint);

    /**
     * Handle the touch start event on the sheet content.
     * Get all the starting measurements and set up the timeout to allow
     * the scroll handler to run before we start the drag
     * @param event
     */
    const handleSheetTouchStart = (event: TouchEvent) => {
        if (!sheetRef.current) return;

        document.querySelector('html')?.classList.add(PREVENT_OVERSCROLL_CLASS);

        const ignoreDrag = (event.target as HTMLElement).closest(`[${IGNORE_DRAGS_ATTRIBUTE}]`) !== null;

        const startY = event.touches[0].clientY;
        dragState.current = {
            ...INITIAL_DRAG_STATE,
            sheetTouchOffset: startY - sheetRef.current.getBoundingClientRect().top,
            currentY: startY,
            prevMoveTimestamp: performance.now(),
            isScrollPossible: getIsScrollPossible(event, sheetContentRef),
            isDragCancelled: ignoreDrag,
        };
    };

    /**
     * Handle the touch move event on the sheet content.
     * Check if drag is accepted and if so adjust the sheet height.
     * @param event
     */
    const handleSheetTouchMove = useCallback(
        (event: TouchEvent) => {
            const { prevMoveTimestamp, currentY, hasDoneFirstTouchMove, isDragging, isDragCancelled } =
                dragState.current;

            if (isDragCancelled) return;

            const newY = event.touches[0].clientY;
            const timeSinceLastMove = performance.now() - prevMoveTimestamp;
            dragState.current.velocity = (currentY - newY) / timeSinceLastMove;
            dragState.current.prevMoveTimestamp = performance.now();
            dragState.current.currentY = newY;

            if (!hasDoneFirstTouchMove) {
                dragState.current.hasDoneFirstTouchMove = true;
                dragState.current.isScrolling = isMoveScroll(dragState, sheetContentRef);
            }

            const shouldBeginDrag = !isDragging && !dragState.current.isScrolling && sheetInitialised;
            if (shouldBeginDrag) {
                dragState.current.isDragging = true;
                sheetRef.current?.classList.add(DRAGGING_CLASS);
            }

            if (!dragState.current.isDragging) return;

            const newSheetHeight = getNewSheetHeight(dragState, highestSnapPosition);
            setSheetHeight(sheetRef, newSheetHeight);
        },
        [sheetInitialised],
    );

    /**
     * Handle the touch end event on the sheet content.
     * If the sheet is being dragged, go to the new snap point.
     */
    const handleSheetTouchEnd = useCallback(() => {
        sheetRef.current?.classList.remove(DRAGGING_CLASS);
        document.querySelector('html')?.classList.remove(PREVENT_OVERSCROLL_CLASS);

        if (!dragState.current.isDragging) return;

        const newActiveSnapPoint = getNewActiveSnapPoint(dragState.current, snapPointsState);

        // If the new snap point is at the bottom, close the sheet
        if (newActiveSnapPoint === 0) {
            closeSheet();
            return;
        }

        // Use animation frame to ensure transition styles are reapplied before moving
        requestAnimationFrame(() => {
            updateActiveSnapPoint(newActiveSnapPoint, true);
        });
    }, [snapPointsState, closeSheet]);

    /**
     * Handle the touch cancel event on the sheet content.
     * Remove the classes and go to the previous snap point.
     */
    const handleSheetTouchCancel = useCallback(() => {
        sheetRef.current?.classList.remove(DRAGGING_CLASS);
        document.querySelector('html')?.classList.remove(PREVENT_OVERSCROLL_CLASS);

        // Reset to original height
        goToSnapPoint(activeSnapPoint);
    }, [activeSnapPoint]);

    return {
        handleSheetTouchStart,
        handleSheetTouchMove,
        handleSheetTouchEnd,
        handleSheetTouchCancel,
    };
};

export default useModalSheetHandlers;
