// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { throttle, debounce } from 'lodash';

// Utils
import { noLonger, now } from '../../utils/react/propsComparisons';
import { getDomElementId } from '../utils/elementUtil';

const isNowAttachModeThisElement = now('isAttachModeThisElement');
const isNowHoveredShallow = now('isHoveredShallow');
const isNoLongerHoveredShallow = noLonger('isHoveredShallow');
const isNoLongerHovered = noLonger('isHovered');

const HOVER_DELAY_DEFAULT = 500;

const getHoveredDomElement = (elementId) => document.getElementById(getDomElementId(elementId));

/**
 * This is similar to the imageHoverDropDecorator.
 * It waits a for the user's mouse to be steady over this element, while dragging,
 * then enters the "attach inside" mode
 */
class AttachModeHoverWatcher extends React.Component {
    componentDidUpdate(prevProps) {
        const { disableAttachMode, immediatelyEnterAttachMode } = this.props;

        if (disableAttachMode) return;

        // Long hover
        if (isNowHoveredShallow(prevProps, this.props)) {
            if (immediatelyEnterAttachMode) {
                this.enableAttachMode();
            } else {
                this.listening = true;
                this.debouncedEnableAttachMode();
                document.addEventListener('dragover', this.throttledOnMove);
                document.addEventListener('touchmove', this.throttledOnMove);
            }
        }
        if (isNoLongerHoveredShallow(prevProps, this.props)) this.clearListeners();

        if (isNoLongerHovered(prevProps, this.props)) this.clearAttachModeState();

        // Now connecting inside
        if (isNowAttachModeThisElement(prevProps, this.props)) this.addAttachModeStyles();
    }

    componentWillUnmount() {
        this.clearAttachModeState();
    }

    /**
     * Hit the debounce handler on every dragover or touchmove event.
     */
    onMove = (e) => {
        if (e.pageX === this.prevPageX && e.pageY === this.prevPageY) return;

        this.prevPageX = e.pageX;
        this.prevPageY = e.pageY;

        this.debouncedEnableAttachMode();
    };

    throttledOnMove = throttle(this.onMove, 20);

    /**
     * When the element is steady-long-hovered for long enough, enter the connect inside mode.
     */
    enableAttachMode = () => {
        const { elementId, dispatchStartAttachMode, isAttachModeThisElement } = this.props;
        if (isAttachModeThisElement) return;

        dispatchStartAttachMode(elementId);
    };

    debouncedEnableAttachMode = debounce(this.enableAttachMode, HOVER_DELAY_DEFAULT);

    /**
     * Add padding around the element to allow the drop target to grow in size.
     */
    addAttachModeStyles = () => {
        const { elementId } = this.props;

        // Add a DOM element that will extend the size of the hovered element, to allow the drop
        // to occur outside the element's border
        const domElement = getHoveredDomElement(elementId);
        const paddingElement = document.createElement('div');
        paddingElement.className = 'attach-mode-drop-padding';

        domElement.insertAdjacentElement('afterbegin', paddingElement);
    };

    clearAttachModeState = () => {
        this.clearListeners();
        requestAnimationFrame(() => requestAnimationFrame(this.cleanAttachModeDom));
    };

    clearListeners = () => {
        if (this.listening) {
            this.listening = false;

            this.throttledOnMove.cancel();
            document.removeEventListener('dragover', this.throttledOnMove);
            document.removeEventListener('touchmove', this.throttledOnMove);
            this.debouncedEnableAttachMode.cancel();
        }
    };

    /**
     * Remove the extra padding around the hovered element, remove the drag event listeners
     * and exit the connect inside mode.
     */
    cleanAttachModeDom = () => {
        const { elementId, dispatchEndAttachMode, isAttachMode } = this.props;

        isAttachMode && dispatchEndAttachMode(elementId);

        // Remove the added dom element
        const domElement = getHoveredDomElement(elementId);
        if (!domElement) return;

        const paddingElement = domElement.querySelector(':scope > .attach-mode-drop-padding');
        if (paddingElement) domElement.removeChild(paddingElement);
    };

    render() {
        return React.Children.only(this.props.children);
    }
}

AttachModeHoverWatcher.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

    elementId: PropTypes.string,

    disableAttachMode: PropTypes.bool,

    isAttachMode: PropTypes.bool,
    isAttachModeThisElement: PropTypes.bool,
    immediatelyEnterAttachMode: PropTypes.bool,
    isHovered: PropTypes.bool,
    isHoveredShallow: PropTypes.bool,

    dispatchStartAttachMode: PropTypes.func.isRequired,
    dispatchEndAttachMode: PropTypes.func.isRequired,
};

export default AttachModeHoverWatcher;
