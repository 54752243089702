// Types
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { ImMap, propIn } from '../../../utils/immutableHelper';
import { TiptapContentNode } from '../../tiptapTypes';

export const getNameFromMentionNode = (node: ProseMirrorNode | ImMap<TiptapContentNode> | TiptapContentNode): string =>
    propIn(['attrs', 'label'], node) || propIn(['attrs', 'id'], node) || 'Unknown';

export const getMentionNodeText = (node: ProseMirrorNode | ImMap<TiptapContentNode> | TiptapContentNode): string =>
    `@${getNameFromMentionNode(node)}`;
