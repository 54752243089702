import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarReactionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-reaction"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M16 7a9.116 9.116 0 0 1 2.186.268.75.75 0 1 1-.364 1.455A7.578 7.578 0 0 0 16 8.5a7.5 7.5 0 1 0 7.487 7.055.75.75 0 0 1 1.497-.087A9 9 0 1 1 16 7zm4 10.25a.75.75 0 0 1 .643 1.136C19.513 20.269 17.943 21 16 21c-1.943 0-3.513-.731-4.643-2.614A.75.75 0 0 1 12 17.25h8zM13 13a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm6 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4-6.25a.75.75 0 0 1 .743.648l.007.102v1.75h1.75a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.648.743l-.102.007h-1.75v1.75a.75.75 0 0 1-.648.743L23 13.25a.75.75 0 0 1-.743-.648l-.007-.102v-1.75H20.5a.75.75 0 0 1-.743-.648L19.75 10a.75.75 0 0 1 .648-.743l.102-.007h1.75V7.5a.75.75 0 0 1 .55-.723l.098-.02L23 6.75z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarReactionIcon);
export default Memo;
