import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarBoldIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-bold"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M12 22h4.857c2.748 0 4.096-1.4 4.096-3.27 0-1.816-1.289-2.882-2.566-2.947v-.117c1.172-.275 2.097-1.096 2.097-2.555 0-1.787-1.289-3.111-3.89-3.111H12v12zm2.174-1.816v-3.516h2.396c1.342 0 2.174.82 2.174 1.893 0 .955-.656 1.623-2.232 1.623h-2.338zm0-5.08v-3.311h2.197c1.277 0 1.94.674 1.94 1.6 0 1.054-.856 1.71-1.987 1.71h-2.15z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarBoldIcon);
export default Memo;
