import { DOM_POINTER_TYPES } from '../../../../utils/domConstants';

class ActivePointerEventCache {
    // using an array here rather than map so we can easily get the first touch event
    eventCache: Array<number>;

    constructor() {
        this.eventCache = [];
    }

    getFirstPointerId(): number | undefined {
        return this.eventCache[0];
    }

    add(pointerEvent: PointerEvent): void {
        this.eventCache.push(pointerEvent.pointerId);
    }

    remove(pointerEvent: PointerEvent): void {
        const pointerIdIndex = this.eventCache.indexOf(pointerEvent.pointerId);
        if (pointerIdIndex > -1) {
            this.eventCache.splice(pointerIdIndex, 1);
        }
    }

    size(): number {
        return this.eventCache.length;
    }

    clear(): void {
        this.eventCache = [];
    }

    shouldRespondTo(event: PointerEvent, isPenMode?: boolean): boolean {
        switch (event.pointerType) {
            case DOM_POINTER_TYPES.MOUSE: {
                if (event.type === 'pointerdown') return event.buttons === 1;
                return true;
            }
            case DOM_POINTER_TYPES.PEN:
                return true;
            case DOM_POINTER_TYPES.TOUCH: {
                if (isPenMode) return false;
                return this.eventCache.length === 0 || event.pointerId === this.getFirstPointerId();
            }
            default:
                return false;
        }
    }
}

export default ActivePointerEventCache;
