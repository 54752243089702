// Utils
import { getAutoHeight, getMaxHeight, getMinHeight } from '../../../common/elements/utils/elementPropertyUtils';

export const getIsAutoHeight = (element) =>
    !!getAutoHeight(element) || (!getMaxHeight(element) && !getMinHeight(element));

export const isHeightRestricted = (element) => !getAutoHeight(element) && !!getMaxHeight(element);

export const getCardDimensionPropertiesPx = ({ element, gridSize }) => {
    // If min height return min-height
    const savedMinHeight = getMinHeight(element);
    if (savedMinHeight) return { minHeight: savedMinHeight * gridSize };

    const savedMaxHeight = getMaxHeight(element);
    if (savedMaxHeight) return { maxHeight: savedMaxHeight * gridSize };

    // If not overflowed and no min height or no max height, just size the card based on the contents
    return {};
};

// Really 2.5, but a draft div has 9px of padding which isn't included in the container padding
const CARD_PADDING_GRID_UNITS = 3.4;

export const getIsEditorOverThreshold = (containerMaxHeight, editorHeight, gridSize) =>
    containerMaxHeight - CARD_PADDING_GRID_UNITS * gridSize < editorHeight;
