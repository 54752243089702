// Lib
import { get } from 'lodash';
import { FORM_ERROR } from 'final-form';

// Utils
import { isNetworkError } from '../../utils/errorUtil';
import logger from '../../logger/logger';

// Constants
import { RESPONSE_ERROR } from '../../../common/error/errorConstants';
import { ROLLBAR_LEVELS } from '../../analytics/rollbarConstants';
import { manuallyReportError } from '../../analytics/rollbarService';

export default ({ form, reportError = true, sensitive }) =>
    (err) => {
        const errorMessage = `${form} submission error`;
        logger.error(errorMessage, err, err.response);

        if (!err.response) {
            return {
                [FORM_ERROR]: {
                    message: get(err, 'message') || 'An unknown error occurred. Please try again or contact support.',
                    isNetworkError: isNetworkError(err),
                },
            };
        }

        const error = get(err, 'response.data.error');
        const isClientValidationError = error && error.code === RESPONSE_ERROR.VALIDATION_FAILURE;

        // Report form submission failure to Rollbar
        if (reportError && !isClientValidationError) {
            manuallyReportError({
                errorMessage,
                error: err,
                sensitive,
                level: ROLLBAR_LEVELS.ERROR,
            });
        }

        const primaryError = {
            [FORM_ERROR]: {
                message: get(error, 'message') || 'Something went wrong, please try again',
                isNetworkError: isNetworkError(err),
                status: err.response?.status,
                code: error?.code,
            },
        };

        // On validation errors respond with a structure that the form can react to
        // Only show primary error message to the user if it is a form error
        if (isClientValidationError) {
            return {
                ...error.details,
                ...(error.inner?.showPrimaryError ? primaryError : {}),
            };
        }

        return primaryError;
    };
