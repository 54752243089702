// Lib
import Bezier from 'bezier-js';
import { reduce } from 'lodash';

// Geometry
import * as rectLib from './rect';

// Make the bezier 't' value (ratio at which it sits along the line) halfway along the line
const DEFAULT_BEZIER_T = 0.5;

/**
 * http://stackoverflow.com/questions/9710616/explain-formula-to-curve-through-a-control-point
 */
const quadraticControlPointFormula = (dimension) => (start, end, lineControlPoint, ratio) =>
    (lineControlPoint[dimension] - Math.pow(1 - ratio, 2) * start[dimension] - Math.pow(ratio, 2) * end[dimension]) /
    (2 * ratio * (1 - ratio));
const quadraticControlPointFormulaX = quadraticControlPointFormula('x');
const quadraticControlPointFormulaY = quadraticControlPointFormula('y');

/**
 * Finds the actual quadratic control point, for a control point that sits on the line's path.
 */
export const calculateQuadraticControlPoint = (start, end, lineControlPoint, ratio = 0.5) => ({
    x: quadraticControlPointFormulaX(start, end, lineControlPoint, ratio),
    y: quadraticControlPointFormulaY(start, end, lineControlPoint, ratio),
});

/**
 * Finds the point, closes to the start of the bezier curve that intersects with a line.
 */
export const findBezierCurveLineIntersection = (line, bezier, shouldFindMinT) => {
    const intersections = bezier.intersects({ p1: line.start, p2: line.end });
    if (!intersections.length) return;

    // Sort and then pick the last intersection because it will be closer to the target of the line
    intersections.sort();
    const index = shouldFindMinT ? 0 : intersections.length - 1;
    return intersections[index];
};

/**
 * Finds the point along a bezier curve that intersects the sides of a rectangle closest to the start of the bezier.
 */
export const rectCurvedBezierIntersection = (sides, bezier, shouldFindMinT = false) => {
    const intersection = reduce(
        sides,
        (accIntersection, side) => {
            const newIntersection = findBezierCurveLineIntersection(side, bezier, shouldFindMinT);

            const foundBetterIntersection =
                !accIntersection ||
                (shouldFindMinT && newIntersection < accIntersection) ||
                (!shouldFindMinT && newIntersection > accIntersection);

            return foundBetterIntersection ? newIntersection : accIntersection;
        },
        null,
    );

    if (!intersection) return;

    // Save the t point on the intersection point, so it can be used in LinePath to render the correct curve
    const point = bezier.get(intersection);
    point.t = intersection;
    return point;
};

export const getBezierIntersection = (rect, bezier, shouldFindMinT = false) => {
    if (!bezier) return null;

    const sides = rectLib.getSides(rect);

    // Find the intersection using the Bezier library
    return rectCurvedBezierIntersection(sides, bezier, shouldFindMinT);
};

/**
 * Creates a bezier object based on a quadratic control point.
 * NOTE: The quadratic control point does NOT sit on the bezier curve, it's the real
 *  quadratic control point that the bezier formulas are based on.
 */
export const getBezierObject = (sourcePoint, quadraticControlPoint, targetPoint) => {
    // Force the target point to be a float - otherwise the bezier library can't handle intersections correctly
    const targetPointF = {
        x: targetPoint.x + 0.01,
        y: targetPoint.y + 0.01,
    };
    return new Bezier([sourcePoint, quadraticControlPoint, targetPointF]);
};

export const getBezierFromPoints = (sourcePoint, controlPoint, targetPoint, t = DEFAULT_BEZIER_T) => {
    // Force the target point to be a float - otherwise the bezier library can't handle intersections correctly
    const targetPointF = {
        x: targetPoint.x + 0.01,
        y: targetPoint.y + 0.01,
    };

    return controlPoint
        ? Bezier.quadraticFromPoints(sourcePoint, controlPoint, targetPointF, t)
        : new Bezier([sourcePoint, targetPointF]);
};

/**
 * Finds the point at which the bezier curve (made from the bezier points provided) intersects the rectangle.
 * If it intersects at many points it'll choose the point that's closest to the target.
 *
 * NOTE: The controlPoint here is a line control point - a point that actually sits on the bezier curve.
 */
export const rectBezierIntersection = (rect, { sourcePoint, controlPoint, targetPoint }) => {
    // Intentionally swapped the target and source points here
    const bezier = getBezierFromPoints(sourcePoint, controlPoint, targetPoint);
    return getBezierIntersection(rect, bezier);
};

export const getBezierSection = ({ start, controlPoint, end, controlT = DEFAULT_BEZIER_T, t1 = 0, t2 = 1 }) => {
    const bezier = getBezierFromPoints(start, controlPoint, end, controlT);
    return bezier.split(t1, t2);
};

export const getBezierPoint = ({ start, controlPoint, end, t }) => {
    const bezier = getBezierFromPoints(start, controlPoint, end);
    return bezier.compute(t);
};
