// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '../../../components/icons/Icon';

// Styles
import './RichMediaPreviewOverlay.scss';

const RichMediaPreviewOverlay = ({ onClick, show, iconName = 'play-arrow' }) => {
    if (!show) return null;

    return (
        <div className="RichMediaPreviewOverlay">
            <div className="circle-hit-area" onClick={onClick}>
                <div className="circle">
                    <Icon name={iconName} />
                </div>
            </div>
        </div>
    );
};

RichMediaPreviewOverlay.propTypes = {
    onClick: PropTypes.func,
    show: PropTypes.bool,
    iconName: PropTypes.string,
};

export default RichMediaPreviewOverlay;
