import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarColorModeBackgroundIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={16}
        className="Icon toolbar-color-mode-background"
        {...props}
    >
        <rect width={20} height={16} fill="#BBBEC3" fillRule="evenodd" className="fill" rx={2} />
    </svg>
);
const Memo = memo(ToolbarColorModeBackgroundIcon);
export default Memo;
