// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash/fp';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from '../../../node_module_clones/recompose';

// Utils
import { getRootBoardId } from '../../../common/users/utils/userPropertyUtils';
import { clearOnboardingProgressStep } from './config/onboardingProgressService';

// Selectors
import { getCurrentUser } from '../../user/currentUserSelector';
import {
    locationPath,
    onboardingInitialEducationSelector,
    onboardingMetadataSelector,
    onboardingRouteFlowIdSelector,
    onboardingSelector,
    onboardingLoggedOutRedirectSelector,
} from './onboardingSelector';

// Actions
import { onboardingAddListener, onboardingSetMetadata } from './onboardingActions';

import preloadOnboardingSteps from './config/preloadOnboardingSteps';
import { selectStep } from './onboardingUtil';

import { navigateToRootWorkspace } from '../../reducers/navigationActions';

// Components
import workspaceUserOnlyInitialisationDecorator from '../decorators/workspaceUserOnlyInitialisationDecorator';
import AuthenticatedContainer from '../../auth/AuthenticatedContainer';
import PageLoader from '../../components/loaders/PageLoader';

// Constants
import { APP_QUERY_PARAMS } from '../../../common/utils/urlConstants';

const templateAddRedirectSelector = createStructuredSelector({
    currentUser: getCurrentUser,
    redirectPath: locationPath,
    redirectOverride: onboardingLoggedOutRedirectSelector,
    onboarding: onboardingSelector,
    onboardingFlowId: onboardingRouteFlowIdSelector,
    onboardingQuery: onboardingMetadataSelector,
    onboardingInitialEducation: onboardingInitialEducationSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAddListener: (listenerConfig) => dispatch(onboardingAddListener(listenerConfig)),
    dispatchSetOnboardingMetadata: (metadata) => dispatch(onboardingSetMetadata(metadata)),
    dispatchNavigateToWorkspace: (boardId) => dispatch(navigateToRootWorkspace()),
    dispatchPreloadSteps: () => dispatch(preloadOnboardingSteps()),
});

const enhance = compose(
    workspaceUserOnlyInitialisationDecorator,
    connect(templateAddRedirectSelector, mapDispatchToProps),
);

class OnboardingUrlRedirect extends React.Component {
    componentDidMount() {
        // defer until aliases have been loaded into store (user becomes available first)
        delay(10, this.attachOnboardingListeners);
    }

    attachOnboardingListeners = () => {
        const {
            currentUser,
            onboardingFlowId,
            onboardingQuery,
            dispatchAddListener,
            dispatchPreloadSteps,
            dispatchSetOnboardingMetadata,
        } = this.props;

        if (Object.keys(onboardingQuery).length) dispatchSetOnboardingMetadata(onboardingQuery);

        if (!onboardingFlowId) return this.handleFinished();

        const rootBoardId = getRootBoardId(currentUser);

        dispatchPreloadSteps().then((result) => {
            // check that requested onboarding step exists
            if (!selectStep(onboardingFlowId)) return this.handleFinished();

            // Clear any existing onboarding progress
            clearOnboardingProgressStep();

            dispatchAddListener({
                id: `url-onboarding-${onboardingFlowId}`,
                predicate: (action) =>
                    action.type === '@@router/LOCATION_CHANGE' && action.payload.pathname.indexOf(rootBoardId) !== -1,
                gotoStep: onboardingFlowId,
            });

            this.handleFinished();
        });
    };

    handleFinished = () => {
        const { dispatchNavigateToWorkspace } = this.props;
        return dispatchNavigateToWorkspace();
    };

    render() {
        return <PageLoader />;
    }
}

OnboardingUrlRedirect.propTypes = {
    isMobileSize: PropTypes.bool,
    onboardingFlowId: PropTypes.string,
    onboardingQuery: PropTypes.object,
    currentUser: PropTypes.object,
    location: PropTypes.object,
    dispatchAddListener: PropTypes.func,
    dispatchSetOnboardingMetadata: PropTypes.func,
    dispatchNavigateToWorkspace: PropTypes.func,
    dispatchPreloadSteps: PropTypes.func,
};

export default enhance((props) => (
    <AuthenticatedContainer
        redirectToRegister
        redirectToUrl={props.redirectOverride}
        redirectParams={{
            [APP_QUERY_PARAMS.FROM_REDIRECTION]: encodeURIComponent(props.redirectPath),
            [APP_QUERY_PARAMS.INITIAL_ONBOARDING]: props.onboarding,
            [APP_QUERY_PARAMS.INITIAL_EDUCATION]: props.onboardingInitialEducation,
        }}
    >
        <OnboardingUrlRedirect {...props} />
    </AuthenticatedContainer>
));
