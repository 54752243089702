// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ToolbarPopupTool from '../components/ToolbarPopupTool';
import MorePopup from './MorePopup';

// Styles
import './MoreTool.scss';

const MoreTool = (props) => {
    const { collapsedTools = [] } = props;

    if (collapsedTools.length === 0) return null;

    return (
        <ToolbarPopupTool
            {...props}
            id={`more-tool-${props.toolsId}`}
            popupId={`more-${props.toolsId}`}
            toolName="more"
            preventFocus
            name=""
            PopupComponent={MorePopup}
        />
    );
};

MoreTool.propTypes = {
    toolsId: PropTypes.string.isRequired,
    collapsedTools: PropTypes.array.isRequired,
};

export default MoreTool;
