// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash';

// Utils
import { hasChanged } from '../../utils/react/propsComparisons';

const childrenHaveChanged = hasChanged('children');

/**
 * This component is used to keep rendering a sub-component that has a CSSTransitionGroup exit animation on it.
 * When the CSSTransitionGroup's child should be removed it will receive a "forceLeave" prop.
 */
class AnimationLeaveStall extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderedChildren: props.children,
            forceLeave: false,
        };
    }

    componentWillMount() {
        this.mounted = true;

        this.setState({
            renderedChildren: this.props.children,
            forceLeave: false,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (childrenHaveChanged(this.props, nextProps)) {
            const { children } = nextProps;

            if (children) {
                this.setState({ renderedChildren: children });
            } else {
                this.setState({ forceLeave: true });

                delay(() => {
                    if (!this.mounted) return;

                    this.setState({ forceLeave: false, renderedChildren: this.props.children });
                }, nextProps.delay || 0);
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { renderedChildren, forceLeave } = this.state;

        let childrenToRender = renderedChildren;

        if (childrenToRender && forceLeave) {
            childrenToRender = React.cloneElement(childrenToRender, {
                forceLeave,
            });
        }

        return childrenToRender || null;
    }
}

AnimationLeaveStall.propTypes = {
    children: PropTypes.node,
    delay: PropTypes.number,
};

export default AnimationLeaveStall;
