import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationCommentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-comment"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#C1D0FC"
                d="M2 13a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-3.586L8 15.414 5.586 13H2z"
            />
            <path
                fill="#EAEFFE"
                d="M2 12a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-4l-2 2-2-2H2z"
            />
            <path
                fill="#5882F8"
                d="M11.49 9c.25 0 .459.183.502.412L12 9.5c0 .276-.222.5-.51.5H3.51A.51.51 0 0 1 3 9.5c0-.276.222-.5.51-.5h7.98zm1-2c.25 0 .459.183.502.412L13 7.5c0 .276-.228.5-.51.5H3.51A.51.51 0 0 1 3 7.5c0-.276.228-.5.51-.5h8.98zm0-2c.25 0 .459.183.502.412L13 5.5c0 .276-.228.5-.51.5H3.51A.51.51 0 0 1 3 5.5c0-.276.228-.5.51-.5h8.98z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationCommentIcon);
export default Memo;
