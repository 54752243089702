import { getElements } from '../../element/selectors/elementSelector';
import { PREVIEW_MODES } from './previewConstants';

export const isPublishPreviewModeEnabledSelector = (state) =>
    state.getIn(['app', 'preview', 'mode']) === PREVIEW_MODES.PUBLISH_PREVIEW;
export const getPublishPreviewStartingBoardId = (state) => state.getIn(['app', 'preview', 'startingBoardId']);
export const getPublishPreviewPasswordValidated = (state) => state.getIn(['app', 'preview', 'passwordValidated']);

export const getPublishPreviewStartingBoard = (state) =>
    getElements(state).get(getPublishPreviewStartingBoardId(state));
