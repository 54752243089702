// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Icon from '../../icons/Icon';

// Styles
import './FormPrimaryMessage.scss';

const FormPrimaryMessage = ({ icon, message, children, show, className }) => {
    if (!show) return null;

    return (
        <div className={classNames('FormPrimaryMessage', className)}>
            {icon ? <Icon name={icon} /> : null}
            {message}
            {children}
        </div>
    );
};

FormPrimaryMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    icon: PropTypes.string,
    show: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FormPrimaryMessage;
