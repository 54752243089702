// Lib
import { uniq } from 'lodash/fp';

// Utils
import { asObject } from '../../../../common/utils/immutableHelper';

// Constants
import { ELEMENT_ACTIVITY_TYPES } from '../elementActivityConstants';

const getActivityEntryToReturn = (newEntry, currentEntry) => {
    if (!currentEntry) return newEntry;

    if (currentEntry.type === ELEMENT_ACTIVITY_TYPES.SHARE) return currentEntry;
    if (newEntry.type === ELEMENT_ACTIVITY_TYPES.SHARE) return newEntry;

    if (currentEntry.type === ELEMENT_ACTIVITY_TYPES.MENTION) return currentEntry;
    if (newEntry.type === ELEMENT_ACTIVITY_TYPES.MENTION) return newEntry;

    if (currentEntry.type === ELEMENT_ACTIVITY_TYPES.ADD) return currentEntry;
    if (newEntry.type === ELEMENT_ACTIVITY_TYPES.ADD) return newEntry;

    if (currentEntry.type === ELEMENT_ACTIVITY_TYPES.COMPLETE) return currentEntry;
    if (newEntry.type === ELEMENT_ACTIVITY_TYPES.COMPLETE) return newEntry;

    return {
        type: ELEMENT_ACTIVITY_TYPES.UPDATE,
        // This is used to dispatch "markNotificationElementAsSeen" actions
        isElementChange: true,
        actorIds: uniq((asObject(newEntry.actorIds) || []).concat(asObject(currentEntry.actorIds))),
        timestamp: Math.max(newEntry.timestamp, currentEntry.timestamp),
    };
};

export const mergeElementActivityEntries = (newEntry, currentEntry) => {
    if (!currentEntry) return newEntry;

    const entryToReturn = getActivityEntryToReturn(newEntry, currentEntry);

    entryToReturn.notificationIds = (newEntry.notificationIds || []).concat(currentEntry.notificationIds);

    return entryToReturn;
};
