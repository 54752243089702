// Utils
import { http } from '../utils/services/http';

/**
 * Hits the `/api/ping` route to determine if the app can connect to the API.
 *
 * At the time of writing this is used when the hybrid app is put to sleep/awoken to ensure that the
 * reconnecting state is correctly shown to the user.
 */
export const checkAppServerConnection = async () => {
    try {
        const response = await http({ url: 'ping' });
        return !!response?.data?.success;
    } catch (e) {
        return false;
    }
};
