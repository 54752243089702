import { DependencyList, useEffect, useRef } from 'react';
import { throttle } from 'lodash';

/**
 * Used to throttle a function in a function component.
 *
 * NOTE:
 * - Due to typescript constraints, the fn passed currently only take in 1 argument. Add more if necessary.
 * - Cannot specify return type since lodash type DebounceFunc is not exposed by the library.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useThrottledCallback = <ArgType, ReturnType>(
    fn: (args: ArgType) => ReturnType,
    delay: number,
    deps: DependencyList,
) => {
    const fnRef = useRef(fn);

    // In here, useRef will make sure that the debounced function does not get recreated every single time
    const throttledFn = useRef(throttle<(args: ArgType) => ReturnType>((args: ArgType) => fnRef.current(args), delay));

    // On every dependency change, recreate the function ref so that it will have the latest values
    useEffect(() => {
        fnRef.current = fn;

        return () => {
            throttledFn.current.cancel();
        };
    }, deps);

    return throttledFn.current;
};

export default useThrottledCallback;
