export const isPresentationModeEnabledSelector = (state) => !!state.getIn(['app', 'presentation', 'enabled']);

export const getIsPresentationModeHideCommentsEnabled = (state) => state.getIn(['app', 'presentation', 'hideComments']);

export const getIsPresentationModeEditingDisabled = (state) => state.getIn(['app', 'presentation', 'disableEditing']);
export const getPresentationModeIsFocusModeAllowed = (state) =>
    state.getIn(['app', 'presentation', 'isFocusModeAllowed']);
export const getPresentationModeFocusModeStatus = (state) => state.getIn(['app', 'presentation', 'focusModeStatus']);

export const getPresentationModeFocusedElementId = (state) => state.getIn(['app', 'presentation', 'focusedElementId']);
export const getPresentationModeFocusModeInitialZoomScale = (state) =>
    state.getIn(['app', 'presentation', 'focusModeInitialZoomScale']);
export const getPresentationModeFocusedElementProps = (state) =>
    state.getIn(['app', 'presentation', 'focusedElementProps']);
