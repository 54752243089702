import React from 'react';
import { FileTypes } from '../../../common/files/fileTypes';
import PropTypes from 'prop-types';

import Icon from '../../components/icons/Icon';

import './ImageReplacementOverlay.scss';

const ImageReplacementOverlay = ({ fileType }) => (
    <div className="ImageReplacementOverlay">
        <div className="overlay-icon">
            <Icon name="image-hover-replace" />
        </div>
        <div className="overlay-text">{fileType === FileTypes.VIDEO ? `Replace preview` : `Replace image`}</div>
    </div>
);

ImageReplacementOverlay.propTypes = {
    fileType: PropTypes.string,
};

export default ImageReplacementOverlay;
