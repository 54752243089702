// Lib
import React from 'react';
import { sum, throttle } from 'lodash';
import Handsontable from 'handsontable/base';

// Utils
import { resizeTable } from '../utils/tableSizeUtils';
import { manuallySetTableElementWidthPx } from '../utils/tableDOMUtils';
import TableOperations from './TableOperations';

interface Props {
    elementId: string;
    gridSize: number;
    isResizing: boolean;
    tempSize: { width: number; height: number } | null;
    tableOperationsRef: React.RefObject<TableOperations>;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
    getContextZoomScale: () => number;
    dispatchUpdateTableElement: (args: object) => void;
}

class TableResizeHandlers extends React.Component<Props> {
    // Adjust columns and rows with drag handle
    adjustTableSize = (): void => {
        const { gridSize, isResizing, tempSize, hotTableInstanceRef, getContextZoomScale, tableOperationsRef } =
            this.props;

        if (!isResizing || !tempSize || !hotTableInstanceRef.current) return;

        const hot = hotTableInstanceRef.current;
        if (!hot || hot.isDestroyed) return;

        const currentColWidths = tableOperationsRef.current?.getHotColWidthsGU() || [];
        const { rowDif, columnDif } = resizeTable(hot, currentColWidths, tempSize, gridSize, getContextZoomScale) ?? {};

        const source = 'resizeHandle';
        const nRows = hot.countRows();
        const nCols = hot.countCols();

        if (!rowDif && !columnDif) return;

        // When adjusting table size, alter both rows and columns in 1 batched render
        hot?.batch(() => {
            // Adjust rows
            if (rowDif > 0) {
                hot.alter('insert_row_below', nRows, rowDif, source);
            } else if (rowDif < 0) {
                const numberToRemove = -rowDif;
                hot.alter('remove_row', nRows - numberToRemove, numberToRemove, source);
            }

            // For column changes we also need to update column and table widths
            if (!columnDif) return;

            // Adjust columns
            if (columnDif > 0) {
                hot.alter('insert_col_end', nCols, columnDif, source);
            } else if (columnDif < 0) {
                const numberToRemove = -columnDif;
                hot.alter('remove_col', nCols - numberToRemove, numberToRemove, source);
            }

            // Adjust table width
            const newColWidthsGu = tableOperationsRef.current?.getHotColWidthsGU();
            const newTableWidthGu = sum(newColWidthsGu);
            const newTableWidthPx = newTableWidthGu * gridSize - 2;
            manuallySetTableElementWidthPx(hot, newTableWidthPx);
        });
    };

    throttleAdjustTableSize = throttle(this.adjustTableSize, 20);

    componentDidUpdate(prevProps: Props): void {
        // When resizing is done, update the table element
        if (!this.props.isResizing && prevProps.isResizing) {
            const { tableOperationsRef, dispatchUpdateTableElement } = this.props;
            const newData = tableOperationsRef.current?.getHotTableData();
            const newColWidthsGu = tableOperationsRef.current?.getHotColWidthsGU();

            dispatchUpdateTableElement({
                id: this.props.elementId,
                changes: { tableContent: { data: newData, colWidthsGU: newColWidthsGu } },
            });
        }

        if (this.props.tempSize !== prevProps.tempSize) {
            this.throttleAdjustTableSize();
        }
    }

    render(): null {
        return null;
    }
}

export default TableResizeHandlers;
