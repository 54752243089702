import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '../../../node_module_clones/react-router';

const RouteLink = ({ className, children, to, location, keepParams = false }) => {
    const destination = keepParams && location ? `${to}${location.search}` : to;
    return (
        <Link className={classNames('RouteLink', className)} to={destination}>
            {children}
        </Link>
    );
};

RouteLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    to: PropTypes.string,
    className: PropTypes.string,
    location: PropTypes.object,
    keepParams: PropTypes.bool,
};

export default RouteLink;
