// Lib
import * as Immutable from 'immutable';

// Constants
import {
    PRESENTATION_MODE_END,
    PRESENTATION_MODE_START,
    PRESENTATION_MODE_SETTINGS_UPDATE,
    FOCUS_MODE_ACTIVE,
    FOCUS_MODE_APPEARING,
    FOCUS_MODE_DISAPPEARING,
    FOCUS_MODE_INACTIVE,
    FocusModeStatus,
} from './presentationConstants';

const initialState = Immutable.Map({
    enabled: false,
    hideComments: false,
    disableEditing: false,
    isFocusModeAllowed: true,
    focusedElementId: null,
    focusModeInitialZoomScale: 1,
    focusModeStatus: FocusModeStatus.INACTIVE,
    focusedElementProps: {},
});

export default (state = initialState, action) => {
    switch (action.type) {
        case PRESENTATION_MODE_START:
            return state.set('enabled', true);
        case PRESENTATION_MODE_SETTINGS_UPDATE:
            return state
                .set('hideComments', action.hideComments)
                .set('disableEditing', action.disableEditing)
                .set('isFocusModeAllowed', action.isFocusModeAllowed);
        case PRESENTATION_MODE_END:
            return state.set('enabled', false).set('focusedElementId', null);
        case FOCUS_MODE_APPEARING:
            return state
                .set('focusedElementId', action.elementId)
                .set('focusModeInitialZoomScale', action.initialZoomScale)
                .set('focusedElementProps', action.elementProps)
                .set('focusModeStatus', FocusModeStatus.APPEARING);
        case FOCUS_MODE_INACTIVE:
            return state
                .set('focusedElementId', null)
                .set('focusModeInitialZoomScale', 1)
                .set('focusedElementProps', {})
                .set('focusModeStatus', FocusModeStatus.INACTIVE);
        case FOCUS_MODE_ACTIVE:
            return state.set('focusModeStatus', FocusModeStatus.ACTIVE);
        case FOCUS_MODE_DISAPPEARING:
            return state.set('focusModeStatus', FocusModeStatus.DISAPPEARING);
        default:
            return state;
    }
};
