// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FeatureSuggestion from '../../../suggestion/FeatureSuggestion';

// Utils
import { getElementId } from '../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { moveCompletedTasksToTrash } from '../../taskListActions';
import { elementUnsuggestFeature } from '../../../suggestion/suggestionActions';

// Constants
import { DELETE_TASKS_SUGGESTION_DISMISSED_PROPERTY } from '../../../../../common/taskLists/taskListConstants';
import { FEATURE_SUGGESTIONS } from '../../../suggestion/suggestionConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchMoveCompletedTasksToTrash: ({ taskListId, isEditable }) =>
        dispatch(
            moveCompletedTasksToTrash({
                taskListId,
                isEditable,
            }),
        ),
    dispatchUnsuggestDeleteTasks: ({ id }) =>
        dispatch(
            elementUnsuggestFeature({
                id,
                feature: FEATURE_SUGGESTIONS.DELETE_COMPLETED_TASKS,
            }),
        ),
});

@connect(null, mapDispatchToProps)
class DeleteTasksSuggestion extends React.Component {
    onConfirm = (event) => {
        const { dispatchMoveCompletedTasksToTrash, dispatchUnsuggestDeleteTasks, element, isEditable, hideInstantly } =
            this.props;

        // Prevent element from getting selected
        event.preventDefault();
        event.stopPropagation();

        const taskListId = getElementId(element);

        dispatchMoveCompletedTasksToTrash({
            taskListId,
            isEditable,
        });

        dispatchUnsuggestDeleteTasks({ id: taskListId });

        hideInstantly();
    };

    onDismiss = () => {
        const { dispatchUpdateElement, element } = this.props;

        // Only update on dismiss
        dispatchUpdateElement({
            id: getElementId(element),
            changes: {
                [DELETE_TASKS_SUGGESTION_DISMISSED_PROPERTY]: true,
            },
        });
    };

    render() {
        const { question, added } = this.props;

        return (
            <FeatureSuggestion
                {...this.props}
                question={question}
                onConfirm={this.onConfirm}
                onDismiss={this.onDismiss}
                added={added}
            />
        );
    }
}

DeleteTasksSuggestion.propTypes = {
    element: PropTypes.object.isRequired,
    feature: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    added: PropTypes.bool,

    onConfirm: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    dispatchStartEditingElement: PropTypes.func,
    dispatchMoveCompletedTasksToTrash: PropTypes.func,
    dispatchUnsuggestDeleteTasks: PropTypes.func,
    hideInstantly: PropTypes.func,
    resetHide: PropTypes.func,
    isEditable: PropTypes.bool,
};

export default DeleteTasksSuggestion;
