import React, { Fragment, useState, memo, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as List, areEqual } from 'react-window';
import { first, throttle, sortBy, take, toPairs, flow, map, reverse } from 'lodash/fp';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { getUnified, getCategory, getEmojisRowData, getCompleteEmojiCode } from './reactionPopupUtil';

import reactionPopupSelector from './reactionPopupSelector';

// Actions
import { fetchEmojiData } from '../emojiDataActions';
import { updateReaction } from '../../../element/reactions/reactionActions';
import { updateCurrentUser } from '../../../user/currentUserActions';

import { PICKER_ROW_LENGTH, PICKER_ROW_TYPES, CATEGORIES, RECENT_CATEGORY_ID } from './reactionPopupConstants';

import PopupModeMenu from '../../popupPanel/types/modeMenu/PopupModeMenu';
import ReactionPopupEmojiButton from './ReactionPopupEmojiButton';
import ReactionPopupSkinTonePicker from './ReactionPopupSkinTonePicker';
import EmojiImage from '../EmojiImage';
import Spinner from '../../loaders/Spinner';

import './ReactionPopup.scss';

const prepareRecentlyUsedEmojiCodes = (emojiCode, recentlyUsed) => {
    const count = recentlyUsed[emojiCode] || 0;

    const allRecent = {
        ...recentlyUsed,
        [emojiCode]: count + 1,
    };

    return allRecent;
};

const orderRecentlyUsed = flow(
    toPairs,
    sortBy('1'),
    reverse,
    take(PICKER_ROW_LENGTH * 3),
    map(([emojiCode]) => emojiCode),
);

const getEmojiPickerRowKey = ({ emojis, title }) =>
    title || `${getUnified(first(emojis))}${getCategory(first(emojis))}`;

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchEmojiData: () => dispatch(fetchEmojiData()),
    dispatchAddReaction: ({ elementIds, emojiCode }) =>
        dispatch(
            updateReaction({
                updateIds: elementIds,
                emojiCode,
            }),
        ),
    dispatchUpdateRecentlyUsed: (reactionsRecent) =>
        dispatch(
            updateCurrentUser({
                changes: { settings: { reactionsRecent } },
            }),
        ),
    dispatchUpdateSkinTone: (reactionsSkinTone) =>
        dispatch(
            updateCurrentUser({
                changes: { settings: { reactionsSkinTone } },
            }),
        ),
});

const ReactionPopupRow = memo(function ReactionPopupRow({
    style,
    index,
    data: { rows, skinTone, selectedReactionIds, handleClickEmoji, gridSize },
}) {
    const row = rows[index];

    if (row.type === PICKER_ROW_TYPES.TITLE) {
        return (
            <div style={style} className={classNames('emoji-list-row title', { first: index === 0 })}>
                <h2>{row.title}</h2>
            </div>
        );
    }

    return (
        <div style={{ ...style, display: 'flex' }} className="emoji-list-row emojis">
            {row.emojis.map((emoji) => (
                <ReactionPopupEmojiButton
                    key={getUnified(emoji)}
                    emoji={emoji}
                    gridSize={gridSize}
                    selected={selectedReactionIds.indexOf(emoji.unified) > -1}
                    skinTone={skinTone}
                    onClickFn={() => handleClickEmoji(emoji)}
                />
            ))}
        </div>
    );
},
areEqual);

ReactionPopupRow.propTypes = {
    style: PropTypes.object,
    data: PropTypes.object,
    index: PropTypes.number,
};

const ReactionPopupContent = ({
    emojiData = [],
    gridSize = 10,
    skinTone,
    recentlyUsed,
    elementIds,
    selectedReactionIds,
    closePopup,
    dispatchAddReaction,
    dispatchFetchEmojiData,
    dispatchUpdateRecentlyUsed,
    dispatchUpdateSkinTone,
}) => {
    const [currentCategory, setCurrentCategory] = useState(first(CATEGORIES).name);
    const [searchFilter, setSearchFilter] = useState('');
    const listRef = useRef();

    const emojiDataLoaded = emojiData.length > 0;

    useEffect(() => {
        if (!emojiDataLoaded) {
            dispatchFetchEmojiData();
        }
    }, []);

    const handleClickEmoji = (emoji) => {
        dispatchUpdateRecentlyUsed(prepareRecentlyUsedEmojiCodes(getUnified(emoji), recentlyUsed));

        dispatchAddReaction({
            elementIds,
            emojiCode: getCompleteEmojiCode(emoji, skinTone),
        });

        closePopup && closePopup();
    };

    const emojisRowData = useMemo(() => {
        const allEmojiData = [
            ...orderRecentlyUsed(recentlyUsed)
                .map((recentEmojiCode, index) => {
                    const emoji = emojiData.find((_emoji) => getUnified(_emoji) === recentEmojiCode);

                    return {
                        ...emoji,
                        category: RECENT_CATEGORY_ID,
                        sort_order: index,
                    };
                })
                .filter((emoji) => !!emoji),
            ...emojiData,
        ];

        return getEmojisRowData(allEmojiData, searchFilter);
    }, [searchFilter, emojiData, recentlyUsed]);

    const pickerModeList = useMemo(
        () =>
            CATEGORIES.map((category) => ({
                slug: category.name,
                title: category.name,
                disabled: !emojisRowData.find(
                    (row) => row.type === PICKER_ROW_TYPES.TITLE && row.title === category.name,
                ),
            })),
        [emojisRowData],
    );

    useEffect(() => {
        const currentMode = pickerModeList.find((mode) => mode && mode.slug === currentCategory);
        if (currentMode && !currentMode.disabled) return;

        const firstEnabledMode = pickerModeList.find((mode) => mode && !mode.disabled);
        setCurrentCategory((firstEnabledMode && firstEnabledMode.slug) || 'none');
    }, [pickerModeList]);

    const getEmojiPickerRowSize = useMemo(
        () => (_, index) => {
            const nextRow = emojisRowData[index + 1];

            return nextRow && nextRow.type === PICKER_ROW_TYPES.TITLE ? 46 : 34;
        },
        [emojisRowData],
    );

    const getKeyOfItemAtIndex = (index) => getEmojiPickerRowKey(emojisRowData[index], index);
    const getHeightOfItemAtIndex = (index) => getEmojiPickerRowSize(emojisRowData[index], index);

    const getTitleAtScrollOffset = (scrollOffset) => {
        let height = 0;
        let currentTitle;

        for (let i = 0; i < emojisRowData.length; i++) {
            const row = emojisRowData[i];

            height += getEmojiPickerRowSize(row, i);

            if (emojisRowData[i].type === PICKER_ROW_TYPES.TITLE) {
                currentTitle = row.title;
            }

            if (height >= scrollOffset) return currentTitle;
        }
    };

    const setEmojiCategory = ({ mode }) => {
        setCurrentCategory(mode);

        const scrollToRowIndex = emojisRowData.findIndex(
            ({ type, title }) => type === PICKER_ROW_TYPES.TITLE && title === mode,
        );

        if (scrollToRowIndex > -1 && listRef.current) {
            listRef.current.scrollToItem(scrollToRowIndex, 'start');
        }
    };

    const handleEmojiListScroll = throttle(10, (event) => {
        if (event.scrollUpdateWasRequested) return;

        const scrollOffset = event.scrollOffset === 0 ? 0 : event.scrollOffset + 150;
        const closestTitle = getTitleAtScrollOffset(scrollOffset);

        if (closestTitle && closestTitle !== currentCategory) {
            setCurrentCategory(closestTitle);
        }
    });

    const pickerListWidthGridPoints = ((32 + 4) * PICKER_ROW_LENGTH + 16) / 10;
    const pickerListHeightGridPoints = 505 / 10;

    const pickerListWidth = pickerListWidthGridPoints * gridSize;
    const pickerListHeight = pickerListHeightGridPoints * gridSize;

    return (
        <Fragment>
            <PopupModeMenu
                tools={
                    <ReactionPopupSkinTonePicker
                        emojiData={emojiData}
                        skinTone={skinTone}
                        setSkinTone={dispatchUpdateSkinTone}
                        gridSize={gridSize}
                    />
                }
                showSearch
                popupMode={currentCategory}
                setMode={setEmojiCategory}
                modeList={pickerModeList}
                searchPlaceholder="Search emojis..."
                clearSearchOnSubmit={false}
                handleSearchChange={setSearchFilter}
            />

            {emojiDataLoaded && emojisRowData.length ? (
                <List
                    className="emoji-list"
                    onScroll={handleEmojiListScroll}
                    ref={listRef}
                    width={pickerListWidth}
                    height={pickerListHeight}
                    itemCount={emojisRowData.length}
                    itemSize={getHeightOfItemAtIndex}
                    itemKey={getKeyOfItemAtIndex}
                    itemData={{
                        rows: emojisRowData,
                        skinTone,
                        selectedReactionIds,
                        handleClickEmoji,
                        gridSize,
                    }}
                    overscanCount={10}
                >
                    {ReactionPopupRow}
                </List>
            ) : (
                <div style={{ width: pickerListWidth, height: pickerListHeight }} className="no-results">
                    {emojiDataLoaded ? (
                        <div className="no-results-text">
                            No emoji found <EmojiImage emojiCode="1F614" size={24} />
                        </div>
                    ) : (
                        <div className="no-results-text">
                            <Spinner show />
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

ReactionPopupContent.propTypes = {
    gridSize: PropTypes.number,
    emojiData: PropTypes.array,
    elementIds: PropTypes.array,
    selectedReactionIds: PropTypes.array,
    skinTone: PropTypes.string,
    recentlyUsed: PropTypes.object,
    closePopup: PropTypes.func,
    dispatchAddReaction: PropTypes.func,
    dispatchFetchEmojiData: PropTypes.func,
    dispatchUpdateRecentlyUsed: PropTypes.func,
    dispatchUpdateSkinTone: PropTypes.func,
};

export default connect(reactionPopupSelector, mapDispatchToProps)(ReactionPopupContent);
