// Utils
import editorStoreConnect from '../../../../../components/editor/store/components/editorStoreConnect';

// Actions
import { editorStyleChange } from '../../../../../components/editor/store/editorActions';

// Components
import RichTextTool from './RichTextTool';

// Utils
import { getEditorState } from '../../../../../components/editor/store/reducers/editorStoreSelector';
import editorGetCurrentBlock from '../../../../../components/editor/customRichUtils/editorState/editorGetCurrentBlock';

// Constants
import {
    STYLE_COMMAND_TYPES,
    EditorStyleTypes,
    STYLE_COMMANDS,
} from '../../../../../components/editor/richText/richTextConstants';
import editorGetCurrentInlineStyle from '../../../../../components/editor/customRichUtils/editorState/editorGetCurrentInlineStyle';

const mapStateToProps = (state, ownProps) => {
    const styleCommand = ownProps.item?.toolSettings?.styleCommand || ownProps.styleCommand || '';

    const editorState = getEditorState(state);
    if (!editorState) return { isActive: false };

    const styleType = STYLE_COMMAND_TYPES[styleCommand];
    const currentBlock = editorGetCurrentBlock(editorState);
    if (!currentBlock) return { isActive: false };

    const blockType = currentBlock.getType();

    if (styleType === EditorStyleTypes.BLOCK) return { isActive: blockType === styleCommand };
    if (
        styleCommand === STYLE_COMMANDS.BOLD &&
        (blockType === STYLE_COMMANDS.HEADING || blockType === STYLE_COMMANDS.LARGE_HEADING)
    ) {
        return { disabled: true };
    }
    if (styleCommand === STYLE_COMMANDS.ITALIC && blockType === STYLE_COMMANDS.BLOCKQUOTE) return { disabled: true };

    const inlineStyle = editorGetCurrentInlineStyle(editorState);
    return { isActive: inlineStyle.has(styleCommand) };
};

const mapEditorDispatchToProps = (dispatch, ownProps) => ({
    onPointerDown: (event) => {
        const styleCommand = ownProps.item?.toolSettings?.styleCommand || ownProps.styleCommand || '';

        event.preventDefault();
        const styleChangeAction = editorStyleChange({ styleCommand });
        dispatch(styleChangeAction);
    },
});

export default editorStoreConnect(mapStateToProps, mapEditorDispatchToProps)(RichTextTool);
