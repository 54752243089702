import { hasShiftKey } from '../../../../../utils/keyboard/keyboardUtility';

export default (event, { getProps }) => {
    event.preventDefault();
    event.stopPropagation();

    if (hasShiftKey(event)) return;

    // FIXME-TABLE check whether this can be reused with onRightOrDown
    const { jumpToFirstCell } = getProps();

    jumpToFirstCell && jumpToFirstCell();
};
