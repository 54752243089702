// Lib
import { RichUtils, convertToRaw } from 'draft-js';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import {
    JOIN_NEXT_TASK_COMMAND,
    JOIN_PREVIOUS_TASK_COMMAND,
    OPEN_DUE_DATE_POPUP,
    TOGGLE_TASK_COMMAND,
} from './keyBindingFn';

const handleToggleTaskCommand = (editorState, { getProps }) => {
    const { toggleComplete } = getProps();
    toggleComplete && toggleComplete();
    return HANDLER_RETURN_VALUES.handled;
};

const handleOpenDueDatePopup = (editorState, { getProps }) => {
    const { openDueDatePopup } = getProps();

    if (!openDueDatePopup) return HANDLER_RETURN_VALUES.notHandled;

    openDueDatePopup();
    return HANDLER_RETURN_VALUES.handled;
};

/**
 * If the user presses 'backspace' at the start of a task.
 */
const handleJoinPreviousTaskCommand = (editorState, { getProps }) => {
    const { onStartBackspace } = getProps();

    const currentContent = editorState.getCurrentContent();
    const textContent = convertToRaw(currentContent);

    onStartBackspace && onStartBackspace({ textContent });

    return HANDLER_RETURN_VALUES.handled;
};

/**
 * If the user presses 'delete' at the end of a task.
 */
const handleJoinNextTaskCommand = (editorState, { getProps }) => {
    const { onEndDelete } = getProps();

    const currentContent = editorState.getCurrentContent();
    const textContent = convertToRaw(currentContent);

    onEndDelete && onEndDelete({ textContent });

    return HANDLER_RETURN_VALUES.handled;
};

export default (command, editorState, eventTimeStamp, draftProps) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);

    if (newEditorState) {
        const { setEditorState } = draftProps;
        setEditorState(newEditorState);
        return HANDLER_RETURN_VALUES.handled;
    }

    switch (command) {
        case OPEN_DUE_DATE_POPUP:
            return handleOpenDueDatePopup(editorState, draftProps);
        case TOGGLE_TASK_COMMAND:
            return handleToggleTaskCommand(editorState, draftProps);
        case JOIN_PREVIOUS_TASK_COMMAND:
            return handleJoinPreviousTaskCommand(editorState, draftProps);
        case JOIN_NEXT_TASK_COMMAND:
            return handleJoinNextTaskCommand(editorState, draftProps);
        default:
            return HANDLER_RETURN_VALUES.notHandled;
    }
};
