// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../node_module_clones/recompose';

// Components
import ListElementPresentational from './ListElementPresentational';
import ListElementContainer from './ListElementContainer';

const ListElement = (props) => {
    const { isPresentational } = props;
    const DisplayElement = isPresentational ? ListElementPresentational : ListElementContainer;
    return <DisplayElement {...props} />;
};

ListElement.propTypes = {
    currentBoardId: PropTypes.string.isRequired,
    isPresentational: PropTypes.bool,
    inList: PropTypes.string,
};

export default pure(ListElement);
