// Lib
import { zipObject, isFunction, curry, map } from 'lodash';

/**
 * Creates a new object with default values based on the keys passed in.
 * The default values can be set by a function that takes a key and returns a value, or can be constant.
 *
 * <pre>
 *     keysToObject(null, ['a', 'b', 'c']);
 *     // => { a: null, b: null, c: null }
 * </pre>
 */
export default curry((defaultValue, keys) => {
    const defaultValueFunction = isFunction(defaultValue) ? defaultValue : (key) => defaultValue;

    return zipObject(keys, map(keys, defaultValueFunction));
});
