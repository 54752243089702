// Constants
import { BOARD_ERROR } from '../../common/error/errorConstants';

interface CanvasSizeErrorParams {
    code?: string;
    name?: string;
    friendlyMessage: string;
    tip?: string;
    details?: object;
}

/**
 * An error related to the canvas size.
 */
class CanvasSizeError extends Error {
    public code: string;
    public friendlyMessage: string;
    public tip?: string;
    public details?: object;

    constructor(message = 'Canvas size error', params: CanvasSizeErrorParams) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CanvasSizeError);
        }

        this.name = 'CanvasSizeError';

        // Custom debugging information
        this.code = BOARD_ERROR.CANVAS_SIZE;
        // this.message = BOARD_ERROR.CANVAS_SIZE;
        this.friendlyMessage = params.friendlyMessage;
        this.tip = params.tip;
        this.details = params.details;
    }
}

export default CanvasSizeError;
