import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementSketchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={26}
        viewBox="0 0 32 26"
        className="Icon element-sketch"
        {...props}
    >
        <defs>
            <filter
                id="ElementSketchIcon__a"
                width="131.2%"
                height="138.5%"
                x="-15.6%"
                y="-15.4%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <path
                id="ElementSketchIcon__b"
                d="M0 1.997C0 .894.89 0 2.002 0h27.996A2 2 0 0 1 32 1.997v22.006C32 25.106 31.11 26 29.998 26H2.002A2 2 0 0 1 0 24.003V1.997z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <use xlinkHref="#ElementSketchIcon__b" fill="#000" filter="url(#ElementSketchIcon__a)" />
                <use xlinkHref="#ElementSketchIcon__b" fill="#FFF" />
            </g>
            <path
                fill="#48505E"
                d="M20.79 5.344a.88.88 0 0 1-.095 1.18l-.078.066-2.54 1.878-1.34 1.005-.84.637-.613.47-.83.644-.744.586-.68.55-.641.53-.416.354-.414.362-.187.168-.34.312-.155.148-.286.282-.26.273c-.084.09-.164.18-.243.272a4.335 4.335 0 0 0-.353.466 2.025 2.025 0 0 0-.095.16l-.023.047.04-.003c.247-.028.525-.087.885-.182l.187-.05.236-.069.496-.153.54-.179.614-.215 1.042-.383 2.483-.94.877-.318.14-.049c.17-.058.322-.109.47-.155l.318-.095c.233-.066.394-.105.585-.13l.18-.019c.133-.006.221 0 .373.038.066.02.136.051.229.106.103.07.2.169.242.256.05.086.085.18.095.246a.98.98 0 0 1-.039.502l-.055.14c-.038.08-.079.154-.123.222a3.554 3.554 0 0 1-.132.197l-.152.199c-.056.07-.12.146-.193.233l-.202.227-.204.223-.333.35-.256.261-2.02 2.007-.123.125 1.598-.444.588-.155.338-.08.158-.033.306-.058.15-.024.307-.042a6.993 6.993 0 0 1 1.51-.03c.578.065 1.086.205 1.526.443.497.258.923.658 1.214 1.136.107.174.197.36.27.554l.069.197c.065.21.108.408.132.611l.016.205a.567.567 0 0 1-1.123.149l-.013-.093a2.24 2.24 0 0 0-.334-1.013 1.954 1.954 0 0 0-.778-.706c-.3-.157-.666-.252-1.093-.294a5.815 5.815 0 0 0-1.242.044c-.41.056-.831.149-1.36.295l-1.843.54-.269.073-.24.061-.24.055-.214.042-.182.026a1.766 1.766 0 0 1-.507.005.931.931 0 0 1-.404-.151l-.106-.08-.062-.063-.042-.05-.036-.056a.8.8 0 0 1-.086-.218l-.012-.092-.003-.054c0-.036.002-.066.006-.1l.03-.144.032-.09c.047-.109.09-.188.155-.283l.108-.15.152-.193.16-.189.184-.204.465-.495 1.818-1.855.346-.363.147-.158-.61.23-3.009 1.186-.671.256-.627.229c-.493.176-.924.32-1.35.446l-.399.111a6.139 6.139 0 0 1-1.103.206c-.19.013-.332.013-.501-.007a1.509 1.509 0 0 1-.347-.087 1.097 1.097 0 0 1-.436-.298 1.494 1.494 0 0 1-.32-1.315c.069-.31.19-.575.375-.868.135-.216.287-.423.465-.637.249-.297.497-.567.785-.857l.304-.297.34-.32.385-.352.212-.19.423-.37.428-.367.668-.56.722-.592.802-.644 1.237-.976 1.574-1.222 1.458-1.115L19.55 5.18a.89.89 0 0 1 1.24.164z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementSketchIcon);
export default Memo;
