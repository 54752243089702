// Lib
import * as Immutable from 'immutable';
import { isEmpty } from 'lodash/fp';

// Utils
import { canvasElementSorter } from '../../../common/elements/utils/elementSortUtils';
import { calculateResetScores } from '../../../common/elements/listOrderScores/scoreCalculator';
import { getUserIdFromAction } from '../../../common/actionUtils';
import { getElementId, getLocationSection, getModifiedBy } from '../../../common/elements/utils/elementPropertyUtils';
import { isSkeleton } from '../../../common/elements/utils/elementTypeUtils';
import {
    buildEntireElementGraph,
    getSortedChildrenInSectionViaGraph,
} from '../../../common/elements/utils/elementGraphUtils';

// Reducers
import { element } from './elementReducer';

// Constants
import * as TYPES from '../../../common/elements/elementConstants';
import * as ELEMENTS_ACTION_TYPES from '../../../common/elements/elementsConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { TRASH_CLEAR_COMPLETE } from '../../workspace/toolbar/trash/store/trashConstants';

const initialState = Immutable.Map();

const loadElements = (state, action) =>
    state.withMutations((mutableState) => {
        if (!isEmpty(action.deletedElementIds)) {
            action.deletedElementIds.forEach((elementId) => {
                mutableState.delete(elementId);
            });
        }

        if (isEmpty(action.elements)) return mutableState;

        const elementsArray = Object.values(action.elements);

        elementsArray.forEach((newElement) => {
            mutableState.update(newElement.id || newElement._id, (existingElement) => {
                if (!existingElement) return Immutable.fromJS(newElement);

                if (isSkeleton(newElement)) return existingElement;

                return Immutable.fromJS(newElement);
            });
        });
    });

/**
 * Remove the specified elements from the store.
 */
const handleElementsPurge = (state, action) =>
    isEmpty(action.elementIds)
        ? state
        : state.withMutations((mutableState) => {
              action.elementIds.forEach((elementId) => {
                  mutableState.delete(elementId);
              });

              return mutableState;
          });

const createElement = (state, action) => {
    // step 1: create the element
    const newElement = element(null, action);
    if (!newElement) return state;

    // step 2: insert element into the master element Map
    return state.set(getElementId(newElement), newElement);
};

const deleteElement = (state, action) => state.delete(action.id);

/**
 * Delegates the handling of the action to each element in the action's ids list.
 * If an element does not exist, does nothing.
 */
const delegateToEachElementIfExists = (iterablePropName) => (state, action) =>
    state.withMutations((mutableState) => {
        action[iterablePropName].forEach((prop) => {
            // If we don't have the element then we cannot update it
            if (!mutableState.get(prop.id)) return;
            mutableState.update(prop.id, (el) => element(el, action));
        });
    });

const delegateUpdateToEachElementIfExists = delegateToEachElementIfExists('updates');
const delegateMoveToEachElementIfExists = delegateToEachElementIfExists('moves');

/**
 * Delegates the handling of the action to the element which has been specified in the action.id.
 * If the element does not exist, does nothing.
 */
const delegateToElementIfExists = (state, action) => {
    // If we don't have the element then we cannot update it
    if (!state.get(action.id)) return state;
    return state.update(action.id, (el) => element(el, action));
};

const resetScores = (state, action) => {
    // Gets children in sorted order based on their current scores
    const elementGraph = buildEntireElementGraph({ elements: state });

    const children = getSortedChildrenInSectionViaGraph({
        elements: state,
        elementGraph,
        parentId: action.parentId,
        section: action.section,
        sortFn: canvasElementSorter,
    });

    // Calculates the new 'reset' scores to use for each child
    const resetScoresArray = calculateResetScores(children.size);

    // Loop over each child (in order) and replace their current score with the new, reset score.
    const updatedElementMap = children.reduce((elementMap, el, index) => {
        elementMap[getElementId(el)] = el.setIn(['location', 'position', 'score'], resetScoresArray[index]);
        return elementMap;
    }, {});

    return state.merge(updatedElementMap);
};

const emptyUserTrashedElements = (state, action) => {
    const userId = getUserIdFromAction(action);

    if (!userId) return state;

    return state.filter((elementState) => {
        const modifiedBy = getModifiedBy(elementState);
        const section = getLocationSection(elementState);

        return modifiedBy !== userId || section !== BoardSections.TRASH;
    });
};

/**
 * Delegates the handling of the action to the element which has been specified in the action.id.
 * If the element does not exist, does nothing.
 */
const handleConvertElementToClone = (state, action) => {
    // If we don't have the element then we cannot update it
    if (!state.get(action.id)) return state;

    return state
        .update(action.id, (el) => element(el, action))
        .update(action.originalElementId, (el) => element(el, { ...action, type: TYPES.ELEMENT_MARK_AS_CLONED }));
};

const handleUndoConvertElementToClone = (state, action) => {
    // If we don't have the element then we cannot update it
    if (!state.get(action.clonedElementId)) return state;

    return state
        .update(action.clonedElementId, (el) => element(el, action))
        .update(action.originalElementId, (el) =>
            element(el, {
                id: action.originalElementId,
                type: TYPES.ELEMENT_UPDATE,
                updates: [
                    {
                        id: action.originalElementId,
                        changes: { hasClones: action.originalElementHasClones },
                    },
                ],
            }),
        );
};

/**
 * Handles the full elements state.
 * Usually delegates to the elementReducer to handle individual element updates.
 */
export default function elements(state = initialState, action) {
    switch (action.type) {
        case TRASH_CLEAR_COMPLETE:
            return emptyUserTrashedElements(state, action);
        case ELEMENTS_ACTION_TYPES.ELEMENTS_LOAD:
            return loadElements(state, action);
        case TYPES.ELEMENT_CREATE:
            return createElement(state, action);
        case TYPES.ELEMENT_DELETE:
            return deleteElement(state, action);
        case TYPES.ELEMENT_UPDATE:
            return delegateUpdateToEachElementIfExists(state, action);
        case TYPES.ELEMENT_DIFF_UPDATE:
            return delegateToElementIfExists(state, action);
        case TYPES.ELEMENT_MOVE_MULTI:
            return delegateMoveToEachElementIfExists(state, action);
        case TYPES.ELEMENT_CONVERT_TO_CLONE:
            return handleConvertElementToClone(state, action);
        case TYPES.ELEMENT_UNDO_CONVERT_TO_CLONE:
            return handleUndoConvertElementToClone(state, action);
        case TYPES.ELEMENT_SET_TYPE:
        case TYPES.ELEMENT_MOVE_AND_UPDATE:
        case TYPES.ELEMENT_UPDATE_ACL:
            return delegateToElementIfExists(state, action);
        // Attachment uploads
        case TYPES.ELEMENT_ICON_FIND:
        case TYPES.ELEMENT_ICON_FIND_SUCCESS:
        case TYPES.ELEMENT_ICON_FIND_FAILURE:
        case TYPES.ELEMENT_ICON_SEARCH:
            return delegateToElementIfExists(state, action);
        case ELEMENTS_ACTION_TYPES.ELEMENTS_RESET_SCORES:
            return resetScores(state, action);
        case ELEMENTS_ACTION_TYPES.ELEMENTS_PURGE:
            return handleElementsPurge(state, action);
        // TODO Restructure so that the elements reducer is no longer in 'common'
        default:
            return state;
    }
}
