// Lib
import { createSelector } from 'reselect';
import { compact } from 'lodash/fp';

// Utils
import { getMany } from '../../../common/utils/immutableHelper';
import { getAllAncestorIds } from '../../../common/dataStructures/treeUtils';
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';

// Selectors
import { getElements } from './elementsSelector';
import { elementGraphSelector, parentIdMapSelector } from './elementGraphSelector';

// This is duplicated to avoid a circular dependency
const getElementId = (state, ownProps) =>
    ownProps.elementId || (ownProps.element && (ownProps.element.get('id') || ownProps.element.get('_id')));

/**
 * Gets the ancestor IDs (not including the current element ID) of the current element.
 */
export const getElementAncestorIdsSelector = () =>
    createShallowSelector(getElementId, parentIdMapSelector, (elementId, parentIdMap) =>
        getAllAncestorIds(parentIdMap, elementId),
    );

/**
 * Gets the ancestor IDs including the current element ID.
 */
export const getBranchElementIdsSelector = () =>
    createSelector(getElementId, getElementAncestorIdsSelector(), (elementId, ancestorIds) => {
        ancestorIds.push(elementId);
        return ancestorIds;
    });

export const getBranchElementsSelector = () =>
    createShallowSelector(getBranchElementIdsSelector(), getElements, getMany);

/**
 * This thunk can be used to retrieve an element's child IDs within action handlers.
 */
export const getElementChildrenIdsThunk = (elementIds) => (dispatch, getState) => {
    const state = getState();
    const elementsMap = elementGraphSelector(state);
    return compact(elementIds.flatMap((elementId) => elementsMap[elementId] || []));
};
