// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getElementId, getTitle } from '../../../common/elements/utils/elementPropertyUtils';
import getEditorJsonText from '../../../common/tiptap/utils/jsonContentUtils/getEditorJsonText';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';

// Components
import TitleEditor from './TitleEditor';

class TitleEditorWrapper extends React.Component {
    saveTitle = (textContent) => {
        const { dispatchUpdateTitle, element } = this.props;

        const title = getEditorJsonText(textContent).replaceAll('\n', '');

        dispatchUpdateTitle({ id: getElementId(element), title });
    };

    render() {
        const { element } = this.props;

        return (
            <TitleEditor
                {...this.props}
                editorId={getMainEditorId(this.props)}
                editorKey={getMainEditorKey(this.props)}
                textContent={getTitle(element)}
                saveContent={this.saveTitle}
            />
        );
    }
}

TitleEditorWrapper.propTypes = {
    element: PropTypes.object.isRequired,
    dispatchUpdateTitle: PropTypes.func,
};

export default TitleEditorWrapper;
