// Lib
import * as Immutable from 'immutable';

// Constants
import { LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS } from '../../auth/authConstants';

// Types
import { AnyAction } from 'redux';

const initialState = Immutable.Map({
    hydrated: false,
    hydrationTimestamp: 0,
    cacheTimestamp: 0,
    cacheVersion: null,
});

export default (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case LOGOUT:
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            return initialState;
        default:
            return state;
    }
};
