// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../../node_module_clones/recompose';
import classNames from 'classnames';

// Utils
import { now } from '../../../utils/react/propsComparisons';
import * as pointLib from '../../../../common/maths/geometry/point';
import { getSnapCoordinate } from '../../utils/elementSnapUtils';
import { handleElementDrop } from '../../../utils/dnd/dragAndDropUtils';
import { isCommentThread } from '../../../../common/elements/utils/elementTypeUtils';
import { getIsCollapsed } from '../../../../common/elements/utils/elementPropertyUtils';
import { getCommentThreadDropPosition } from '../../../utils/dnd/dropPositionUtils';
import { multiRef } from '../../../utils/multiRef';

// State
import dragAndDropStateSingleton from '../dragAndDropStateSingleton';

// Selectors
import { attachModeHoveredElementIdSelector, isAttachModeSelector } from '../../../reducers/draggingSelector';

// Actions
import { endAttachMode, startAttachMode } from '../../../utils/dnd/dndActions';

// Decorators
import dontUpdateForKeys from '../../../utils/milanoteRecompose/dontUpdateForKeys';

// Components
import ElementDropTarget from './ElementDropTarget';
import AttachModeHoverWatcher from '../AttachModeHoverWatcher';
import onlyShowIfNotPresentational from './onlyShowIfNotPresentational';

// Analytics
import { sendAmplitudeEvent } from '../../../analytics/amplitudeService';
import { EVENT_TYPE_NAMES } from '../../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../common/analytics/statsConstants';

// Constants
import { ELEMENT_DND_TYPE } from '../../../../common/elements/elementConstants';
import { BoardSections } from '../../../../common/boards/boardConstants';
import { AttachModeType } from '../../../utils/dnd/dndConstants';

// Styles
import './AttachedCommentsDropTarget.scss';

const nowCanDrop = now('canDrop');
const nowIsHovered = now('isHovered');

/**
 * If the currently long hovered element ID is the same as this element ID we're connecting inside.
 */
const isAttachModeThisElementSelector = (state, ownProps) =>
    attachModeHoveredElementIdSelector(state) === ownProps.elementId;

const mapStateToProps = () =>
    createStructuredSelector({
        isAttachModeEnabled: isAttachModeSelector,
        isAttachModeThisElement: isAttachModeThisElementSelector,
    });

const mapDispatchToProps = (dispatch) => ({
    dispatchStartAttachMode: (hoveredElementId) => dispatch(startAttachMode(hoveredElementId, AttachModeType.HOT_SPOT)),
    dispatchEndAttachMode: (hoveredElementId) => dispatch(endAttachMode(hoveredElementId)),
});

const getDropLocation = (props, monitor, domNode) => {
    if (!domNode?.getBoundingClientRect) return;

    const { gridSize, isRelative, getContextZoomScale } = props;
    const { element: draggedElement, scaledCustomDragOffset } = monitor.getItem();

    const zoomScale = getContextZoomScale();

    // Get the domNode's DOM rect & get the drop position
    const hoveredElementRect = domNode.getBoundingClientRect();

    const dropPosition = getCommentThreadDropPosition(
        draggedElement,
        monitor.getSourceClientOffset(),
        dragAndDropStateSingleton.scaledGrabOffset,
        scaledCustomDragOffset,
    );

    // Translate the drop coordinate into the element's coordinate system
    const scaledDropCoordinateInElement = pointLib.reverseTranslate(hoveredElementRect, dropPosition);

    const position = {
        x: getSnapCoordinate({
            val: scaledDropCoordinateInElement.x / zoomScale,
            min: 0,
            max: hoveredElementRect.width / zoomScale,
            isRelative,
            gridSize,
        }),
        y: getSnapCoordinate({
            val: scaledDropCoordinateInElement.y / zoomScale,
            min: 0,
            max: hoveredElementRect.height / zoomScale,
            isRelative,
            gridSize,
        }),
    };

    sendAmplitudeEvent({
        eventType: EVENT_TYPE_NAMES.ATTACHED_COMMENT_THREAD,
        userProperties: {
            [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.ATTACHED_COMMENT_THREAD]: true },
        },
    });

    return {
        parentId: props.elementId,
        section: BoardSections.ATTACHED,
        position,
    };
};

const dropTargetConfig = {
    drop: (props, monitor, domNode) => {
        const acceptedElementIds = monitor.getItem().draggedElementIds;
        return handleElementDrop(getDropLocation)(props, monitor, domNode, acceptedElementIds);
    },
    canDrop: (props, monitor) => {
        const { isAttachModeThisElement } = props;

        const item = monitor.getItem();

        const { element, draggedElementIds } = item;

        return isAttachModeThisElement && draggedElementIds.length === 1 && isCommentThread(element);
    },
    collect: (monitor) => {
        const item = monitor.getItem();

        const isDragging =
            !!item &&
            monitor.getItemType() === ELEMENT_DND_TYPE &&
            item.draggedElementIds.length === 1 &&
            isCommentThread(item.element);

        return {
            isHovered: monitor.isOver({ shallow: true }),
            // NOTE: We need to add a class to the dropTarget when canDrop is true so that we can move the
            //  drop target element to the top of the stacking order, so it can accept drops / hovers.
            //  In the past, this has caused performance issues when it was happening for elements.
            //  It might not be a problem in this case because this is a much simpler component than each Element,
            //  however if it does cause issues we should see if we can add the property via vanilla JS instead.
            canDrop: monitor.canDrop() || false,
            isDragging,
            immediatelyEnterAttachMode: isDragging && getIsCollapsed(item.element),
        };
    },
};

class AttachedCommentsDropTarget extends React.Component {
    constructor(props) {
        super(props);

        this.dropTargetRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        const { setParentHoveredChildAcceptsDrop, isHovered, canDrop } = nextProps;

        // Drag and Drop - Shallow hover hack
        // If this list is now shallowly hovered, tell a parent list whether this list will accept the drop or not
        if (
            (setParentHoveredChildAcceptsDrop && isHovered && nowCanDrop(this.props, nextProps)) ||
            (setParentHoveredChildAcceptsDrop && nowIsHovered(this.props, nextProps))
        ) {
            setParentHoveredChildAcceptsDrop(canDrop);
        }
    }

    render() {
        const {
            connectDropTarget,
            isHovered,
            isDragging,
            elementId,
            isAttachModeEnabled,
            isAttachModeThisElement,
            dispatchStartAttachMode,
            dispatchEndAttachMode,
            immediatelyEnterAttachMode,
        } = this.props;

        const dropTargetClassnames = classNames('AttachedCommentsDropTarget', {
            hovered: isHovered,
            'is-dragging': isDragging,
        });

        return (
            <AttachModeHoverWatcher
                elementId={elementId}
                isHovered={isHovered}
                isHoveredShallow={isHovered}
                isAttachMode={isAttachModeEnabled}
                isAttachModeThisElement={isAttachModeThisElement}
                immediatelyEnterAttachMode={immediatelyEnterAttachMode || isAttachModeEnabled}
                dispatchStartAttachMode={dispatchStartAttachMode}
                dispatchEndAttachMode={dispatchEndAttachMode}
            >
                <div ref={multiRef([this.dropTargetRef, connectDropTarget])} className={dropTargetClassnames} />
            </AttachModeHoverWatcher>
        );
    }
}

AttachedCommentsDropTarget.propTypes = {
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,

    isPresentational: PropTypes.bool,
    isHovered: PropTypes.bool,
    isRelative: PropTypes.bool,

    isDragging: PropTypes.bool,
    canDrop: PropTypes.bool,

    connectDropTarget: PropTypes.func,

    immediatelyEnterAttachMode: PropTypes.bool,
    isAttachModeThisElement: PropTypes.bool,
    isAttachModeEnabled: PropTypes.bool,
    dispatchStartAttachMode: PropTypes.func,
    dispatchEndAttachMode: PropTypes.func,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,
};

const enhance = compose(
    // Prevent the drop target from being added on drag.
    onlyShowIfNotPresentational,
    // No need to re-render this component when the element changes,
    // it's only used to determine the drop target element
    dontUpdateForKeys(['element']),
    connect(mapStateToProps, mapDispatchToProps),
    // NOTE: Must be the last entry in the decorators so it can use the dropTargetRef on drop
    ElementDropTarget(dropTargetConfig),
);

export default enhance(AttachedCommentsDropTarget);
