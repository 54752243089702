// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { now } from '../../../utils/react/propsComparisons';

// Components
import FormInput from './FormInput';
import VisibilityButton from './VisibilityButton';

// Styles
import './PasswordInput.scss';

const nowFocused = now('isFocused');
const nowVisible = now('isVisible');

/**
 * Creates an input field for a password which can be switched between hidden and shown.
 */
class PasswordInput extends React.Component {
    constructor(props) {
        super(props);

        this.passwordInputRef = React.createRef();

        this.state = {
            isVisible: false,
            isFocused: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (nowVisible(prevState, this.state) || nowFocused(prevState, this.state)) {
            this.passwordInputRef.current && this.passwordInputRef.current.focus();
        }
    }

    toggleFocus = () => {
        this.setState({ isFocused: true });
    };

    toggleBlur = () => {
        this.setState({ isFocused: false });
    };

    toggleVisibility = (event) => {
        event.preventDefault();

        this.setState((prevState) => ({
            isVisible: !prevState.isVisible,
        }));
    };

    render() {
        const { canShowPassword, error, ...inputProps } = this.props;
        const { isVisible, isFocused } = this.state;
        const classes = classNames('field PasswordInput', { 'has-show-button': canShowPassword });

        return (
            <div className="field-row">
                <div className={classes}>
                    <FormInput
                        {...inputProps}
                        error={error}
                        ref={this.passwordInputRef}
                        type={isVisible ? 'text' : 'password'}
                        onFocus={this.toggleFocus}
                        onBlur={this.toggleBlur}
                        className="password-input"
                    />
                    {canShowPassword ? (
                        <VisibilityButton
                            {...this.props}
                            error={error}
                            onClick={this.toggleVisibility}
                            isFocused={isFocused}
                            isVisible={isVisible}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

PasswordInput.propTypes = {
    error: PropTypes.object,
    canShowPassword: PropTypes.bool,
};

export default PasswordInput;
