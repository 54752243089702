// Lib
import { flow } from 'lodash';

// Utils
import logger from '../../../logger/logger';
import { isColorSwatch, isTask } from '../../../../common/elements/utils/elementTypeUtils';
import { isLocationCanvas } from '../../../../common/elements/utils/elementLocationUtils';
import * as pointLib from '../../../../common/maths/geometry/point';
import { getIconViewIconCentreOffset } from '../../../utils/dnd/dragPositionUtils';
import { getElementId, getMediaHeight, getMediaWidth } from '../../../../common/elements/utils/elementPropertyUtils';
import { isIconViewLike } from '../../../../common/elements/utils/elementDisplayUtils';
import { asPoint } from '../../../../common/maths/geometry/point';
import { isWorkspaceSectionCanvas } from '../../../workspace/workspacePropertyUtils';
import { prop } from '../../../../common/utils/immutableHelper';

// Constants
import { NO_DRAG_OFFSET } from '../../../utils/dnd/dndConstants';

const isGrabbedElement = ({ grabbedElement, element }) => getElementId(grabbedElement) === getElementId(element);

// Drag offsets
const getIconViewInListOriginOffset = ({ element, gridSize, measurementsMap }) => {
    const selectedElementMeasurements = prop(getElementId(element), measurementsMap);
    const originOffset = prop('originOffset', selectedElementMeasurements);
    return pointLib.difference(getIconViewIconCentreOffset(gridSize), originOffset);
};

const getGrabbedElementUnscaledDragOffset = ({ grabbedElement, unscaledGrabOffset, gridSize }) => {
    if (isIconViewLike(grabbedElement) && !isLocationCanvas(grabbedElement)) {
        return pointLib.difference(getIconViewIconCentreOffset(gridSize), unscaledGrabOffset);
    }

    if (isColorSwatch(grabbedElement) && !isLocationCanvas(grabbedElement)) {
        const colorSwatchCentreOffset = { x: getMediaWidth(grabbedElement) / 2, y: getMediaHeight(grabbedElement) / 2 };
        return pointLib.reverseTranslate(colorSwatchCentreOffset, unscaledGrabOffset);
    }

    // A task is grabbed from a small handle on the right side of the
    if (isTask(grabbedElement)) return { x: -28 * gridSize, y: 0 };

    return NO_DRAG_OFFSET;
};

/**
 * This handles situations where the element needs to be repositioned because the dragged preview is not visually
 * the same as the element that was grabbed (for example, boards in a list are a different shape).
 */
const getElementUnscaledOriginOffset = ({ grabbedElement, unscaledGrabOffset, element, gridSize, measurementsMap }) => {
    // The grabbed element might be a different shape, so we need to correct its position
    if (isGrabbedElement({ grabbedElement, element })) {
        return getGrabbedElementUnscaledDragOffset({ grabbedElement, unscaledGrabOffset, gridSize });
    }

    if (isIconViewLike(element) && !isLocationCanvas(element)) {
        return getIconViewInListOriginOffset({ element, gridSize, measurementsMap });
    }

    return NO_DRAG_OFFSET;
};

const getSelectedElementTranslation = ({
    grabbedElement,
    element,
    measurementsScaledPositions,
    gridSize,
    index,
    zoomScaleOnDragSource,
}) => {
    // If this element is the grabbed element, no translation is necessary
    if (isGrabbedElement({ grabbedElement, element })) {
        return NO_DRAG_OFFSET;
    }

    const selectedElementId = getElementId(element);
    const selectedElementScaledPosition = prop(selectedElementId, measurementsScaledPositions);

    const grabbedElementId = getElementId(grabbedElement);
    const grabbedElementScaledPosition = prop(grabbedElementId, measurementsScaledPositions);

    // If the elements are not in the measurements map then just offset them from each other
    if (!grabbedElementScaledPosition || !selectedElementScaledPosition) {
        const missingMeasurementIds = [];
        !grabbedElementScaledPosition && missingMeasurementIds.push(grabbedElementId);
        !selectedElementScaledPosition && missingMeasurementIds.push(selectedElementId);

        logger.warn('No measurements found for ', missingMeasurementIds);

        return {
            x: 3 * gridSize * (index + 1),
            y: 3 * gridSize * (index + 1),
        };
    }

    return pointLib.reverseScale(
        zoomScaleOnDragSource,
        pointLib.reverseTranslate(grabbedElementScaledPosition, selectedElementScaledPosition),
    );
};

const addOffsetToMapReducer =
    ({
        grabbedElement,
        measurementsMap,
        measurementsScaledPositions,
        gridSize,
        unscaledGrabOffset,
        zoomScaleOnDragSource,
    }) =>
    (offsetMap, element, index) => {
        offsetMap[getElementId(element)] = pointLib.translate(
            getElementUnscaledOriginOffset({
                grabbedElement,
                unscaledGrabOffset,
                element,
                gridSize,
                measurementsMap,
                index,
            }),
            getSelectedElementTranslation({
                grabbedElement,
                element,
                measurementsScaledPositions,
                gridSize,
                index,
                zoomScaleOnDragSource,
            }),
        );
        return offsetMap;
    };

/**
 * To calculate the offset between dragged elements, we would need to convert measurementsMap to be:
 * - Scaled -- because we are calculating the difference of the positions as shown on the interface
 * - Measure from the top left of the canvas scroll area
 */
const prepareMeasurementScaledPositions = ({ measurementsMap, zoomScale, zoomTranslationPx }) => {
    return Object.keys(measurementsMap).reduce((acc, elementId) => {
        const elementMeasurement = prop(elementId, measurementsMap);
        const elementUnscaledPosition = asPoint(elementMeasurement);

        acc[elementId] = elementUnscaledPosition;
        if (isWorkspaceSectionCanvas(elementMeasurement)) {
            acc[elementId] = flow(
                pointLib.scale(zoomScale),
                pointLib.translate(zoomTranslationPx),
            )(elementUnscaledPosition);
        }

        return acc;
    }, {});
};

export default ({
    grabbedElement,
    draggedElements,
    measurementsMap,
    gridSize,
    unscaledGrabOffset,
    zoomScale,
    zoomScaleOnDragSource,
    zoomTranslationPx,
}) => {
    const measurementsScaledPositions = prepareMeasurementScaledPositions({
        measurementsMap,
        zoomScale,
        zoomTranslationPx,
    });

    return draggedElements.reduce(
        addOffsetToMapReducer({
            grabbedElement,
            measurementsMap,
            measurementsScaledPositions,
            gridSize,
            unscaledGrabOffset,
            zoomScaleOnDragSource,
        }),
        {},
    );
};
