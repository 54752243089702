// Lib
import { get } from 'lodash/fp';

// Selectors
import { getAttachments } from '../element/attachments/attachmentsSelector';

// Constants
import {
    ELEMENT_ATTACHMENT_CANCEL_UPLOAD,
    ELEMENT_DELETE,
    ELEMENT_MOVE_MULTI,
} from '../../common/elements/elementConstants';
import { BoardSections } from '../../common/boards/boardConstants';

const createCancelElementAttachmentFn =
    ({ getState, dispatch }) =>
    ({ id }) => {
        const state = getState();
        const attachments = getAttachments(state);
        const cancelFn = attachments.getIn([id, 'cancelFn']);

        if (!cancelFn) return;

        cancelFn();
    };

const createHandleMultiMoveFn = (cancelElementAttachment) => (action) =>
    action.moves &&
    action.moves
        .filter((move) => get(['location', 'section'], move) === BoardSections.TRASH)
        .forEach(cancelElementAttachment);

const createHandleActionFn = (store) => {
    const cancelElementAttachment = createCancelElementAttachmentFn(store);
    const handleMultiMove = createHandleMultiMoveFn(cancelElementAttachment);

    return (action) => {
        switch (action.type) {
            case ELEMENT_ATTACHMENT_CANCEL_UPLOAD:
            case ELEMENT_DELETE:
                return cancelElementAttachment(action);
            case ELEMENT_MOVE_MULTI:
                return handleMultiMove(action);
            default:
                return null;
        }
    };
};

export default (store) => {
    const handleAction = createHandleActionFn(store);
    return (next) => (action) => {
        handleAction(action);
        return next(action);
    };
};
