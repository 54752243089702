// Constants
import { ERROR_CODES } from '../utils/http/httpConstants';

export const ERROR_THROW = 'ERROR_THROW';
export const ERROR_CLEAR = 'ERROR_CLEAR';

// boards
export const BOARD_ERROR = {
    GENERIC_ERROR: 'GENERIC_ERROR',
    NOT_FOUND: 'BOARD_NOT_FOUND',
    DELETED: 'BOARD_DELETED',
    ACCESS_DENIED: 'BOARD_ACCESS_DENIED',
    PASSWORD_PROTECTED: 'BOARD_PASSWORD_PROTECTED',
    TIMEOUT: ERROR_CODES.CONNECTION_ABORTED,
    CONNECTION_LOST: ERROR_CODES.CONNECTION_RESET,
    CANVAS_SIZE: 'CANVAS_SIZE',
};

// elements
export const ELEMENT_ERROR = {
    DIFF_UPDATE_ERROR: 'ElementDiffUpdateError',
};

export const ERROR_MESSAGES = {
    NETWORK_ERROR: 'Network Error',
};

export const USER_ERROR = {
    NOT_FOUND: 'USER_NOT_FOUND',
    APP_INIT_ERROR: 'APP_INIT_ERROR',
};

export const ATTACHMENT_ERROR = {
    FREE_FILE_COUNT_LIMIT: 'FREE_FILE_COUNT_LIMIT',
    GENERAL_ERROR: 'GENERAL_ERROR',
};

export const RESPONSE_ERROR = {
    VALIDATION_FAILURE: 'INVALID',
    LOCKED: 'LOCKED',
    NOT_ADMIN: 'NOT_ADMIN',
    NOT_SUBSCRIBED: 'NOT_SUBSCRIBED',
    BAD_REQUEST: 'BAD_REQUEST',
    FORBIDDEN: 'FORBIDDEN',
    EMBARGOED_IP: 'EMBARGOED_IP',
};
