import { AnyAction } from 'redux';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { getFileType } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { cloneDeep } from 'lodash';
import { HYBRID_ACTION_TYPES, HybridLocationChangeFileAction } from '../../hybridMiddlewareConstants';
import { FileTypes } from '../../../../../../common/files/fileTypes';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../platform/platformTypes';

// Creating the Action for File Elements

const shouldCreateHybridActionForFile = (fileElement: MNElement): boolean => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_BOARD_LIST:
        case LegacyHybridUseCase.IOS_CANVAS:
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
        case LegacyHybridUseCase.IPAD_OS:
            return true;
        default:
            return false;
    }
};

export const createHybridPresentModalPdfAction = (
    originalAction: AnyAction,
    fileElement: MNElement,
): HybridLocationChangeFileAction | undefined => {
    if (!shouldCreateHybridActionForFile(fileElement)) return;
    if (!getFileType(fileElement)) return;
    const modifiedAction = cloneDeep(originalAction) as HybridLocationChangeFileAction;
    modifiedAction.fileElement = fileElement;
    switch (getFileType(fileElement)) {
        case FileTypes.PDF:
            modifiedAction.type = HYBRID_ACTION_TYPES.PRESENT_MODAL_PDF;
            break;
        default:
            return;
    }
    return modifiedAction;
};
