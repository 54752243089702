// Handlers
import { handleStyleCommand } from './handleStyleCommand';
import { handleColorStyleCommand } from './handleColorStyleCommand';
import handleEntityChange from './handleEntityChange';
import handleEntityRemoval from './handleEntityRemoval';
import handleEditorUndoRedoClear from './handleEditorUndoRedoClear';
import { handleUpdateBlockMetadata } from './handleUpdateBlockMetadata';

// Constants
import {
    EDITOR_STATE_CHANGED,
    EDITOR_STYLE_COMMAND,
    EDITOR_ENTITY_CHANGE,
    EDITOR_ENTITY_REMOVE,
    EDITOR_UNMOUNT,
    EDITOR_CLEAR_UNDO_REDO_STACK,
    EDITOR_COLOR_STYLE_COMMAND,
    EDITOR_UPDATE_BLOCK_METADATA,
} from '../editorConstants';

const initialState = null;

/**
 * Stores a map of measurements of target IDs.
 */
export default function editorStateReducer(state = initialState, action) {
    switch (action.type) {
        case EDITOR_STATE_CHANGED:
            return action.editorState;
        case EDITOR_STYLE_COMMAND:
            return handleStyleCommand(state, action);
        case EDITOR_COLOR_STYLE_COMMAND:
            return handleColorStyleCommand(state, action);
        case EDITOR_UPDATE_BLOCK_METADATA:
            return handleUpdateBlockMetadata(state, action);
        case EDITOR_ENTITY_CHANGE:
            return handleEntityChange(state, action);
        case EDITOR_ENTITY_REMOVE:
            return handleEntityRemoval(state, action);
        case EDITOR_UNMOUNT:
            return initialState;
        case EDITOR_CLEAR_UNDO_REDO_STACK:
            return handleEditorUndoRedoClear(state, action);
        default:
            return state;
    }
}
