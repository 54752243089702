// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from '../../../node_module_clones/recompose';
import { defer } from 'lodash/fp';

// Utils
import measurementsConnect from '../../components/measurementsStore/measurementsConnect';
import { getMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Actions
import { endElementResizing, startElementResizing } from './store/resizingActions';
import { measurementsSet } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsActions';

// Components
import Icon from '../../components/icons/Icon';
import pointerDecorator from './elementResizeHandlePointerDecorator';

// Styles
import './ElementResizeHandle.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchStartResizing: (elementId) => dispatch(startElementResizing(elementId)),
    dispatchEndResizing: () => dispatch(endElementResizing()),
});

const handlers = {
    onStartResize:
        ({ elementId, dispatchStartResizing }) =>
        () =>
            dispatchStartResizing(elementId),
    // Use defer to ensure that collisions aren't checked when the resizing has finished
    onEndResize:
        ({ dispatchEndResizing }) =>
        () =>
            defer(dispatchEndResizing),
};

const mapMeasurementsDispatchToProps = (dispatch) => ({
    getCurrentMeasurements: (elementId) =>
        dispatch((_dispatch, getState) => {
            const state = getState();
            const measurementsMap = getMeasurementsMap(state);
            return measurementsMap.get(elementId);
        }),
    dispatchMeasurementsSet: ({ id, measurements }) => dispatch(measurementsSet({ id, measurements })),
});

const ElementResizeHandle = (props) => {
    const { onPointerDown, onTouchStart, onDoubleClick, showHandle, documentMode } = props;

    if (!showHandle || documentMode) return null;

    return (
        <div
            className="ElementResizeHandle"
            onPointerDown={onPointerDown}
            onTouchStart={onTouchStart}
            onDoubleClick={onDoubleClick}
        >
            <Icon name="element-resize-handle" />
        </div>
    );
};

ElementResizeHandle.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    gridSize: PropTypes.number,
    showHandle: PropTypes.bool,
    onPointerDown: PropTypes.func,
    onTouchStart: PropTypes.func,
    onDoubleClick: PropTypes.func,
    documentMode: PropTypes.bool,

    getContextZoomScale: PropTypes.func,
    getCurrentMeasurements: PropTypes.func,
    dispatchMeasurementsSet: PropTypes.func,
};

const enhance = compose(
    connect(null, mapDispatchToProps),
    withHandlers(handlers),
    measurementsConnect(null, mapMeasurementsDispatchToProps),
    pointerDecorator,
);

export default enhance(ElementResizeHandle);
