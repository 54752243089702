// Utils
import { getLastNKeys } from '../../utils/milanoteLodash/getLastNKeys';

const INBOX_RETURN_STATE_LOCAL_STORAGE_KEY = 'milanote.returnState.inbox';
const RETURN_STATE_LIMIT = 100;

interface InboxReturnState {
    scrollTop: number;
    height: number;
}

/**
 * Clears all inbox return states from local storage.
 */
export const clearInboxReturnState = (): void => {
    if (!global.sessionStorage) return;

    sessionStorage.removeItem(INBOX_RETURN_STATE_LOCAL_STORAGE_KEY);
};

/**
 * Retrieves all inbox return states from local storage.
 */
const getPersistedAllInboxReturnState = (): Record<string, InboxReturnState> | null => {
    if (!global.sessionStorage) return null;

    try {
        const currentReturnStateString = sessionStorage.getItem(INBOX_RETURN_STATE_LOCAL_STORAGE_KEY);
        return currentReturnStateString ? JSON.parse(currentReturnStateString) : null;
    } catch (e) {
        return null;
    }
};

/**
 * Retrieves a specific board ID's inbox return state from local storage, if it exists.
 */
export const getInboxReturnState = (boardId: string): InboxReturnState | null => {
    if (!global.sessionStorage) return null;

    const persistedInboxReturnState = getPersistedAllInboxReturnState();
    return persistedInboxReturnState?.[boardId] || null;
};

/**
 * Saves the inbox return state to local storage and ensures it doesn't grow to more than 100 entries.
 */
export const saveInboxReturnState = (boardId: string, inboxReturnState: InboxReturnState): void => {
    if (!global.sessionStorage) return;

    const currentInboxReturnState = getPersistedAllInboxReturnState() || {};

    delete currentInboxReturnState[boardId];

    const newInboxReturnState = getLastNKeys(
        { ...currentInboxReturnState, [boardId]: inboxReturnState },
        RETURN_STATE_LIMIT,
    );

    sessionStorage.setItem(INBOX_RETURN_STATE_LOCAL_STORAGE_KEY, JSON.stringify(newInboxReturnState));
};
