// Lib
import { get, isNil } from 'lodash/fp';
import { assign, chunk, difference, isEmpty, keys } from 'lodash';

// Utils
import http from '../utils/services/http';
import { getSessionId } from '../device/deviceSessionService';

// Actions
import { loadUsers, userActivityLoad, userActivityError, loadUser, userActivityClear } from './userActions';

// Utils
import delay from '../../common/utils/lib/delay';
import { getCurrentUserId, isGuestSelector } from './currentUserSelector';
import { isRealUserId } from '../../common/users/userHelper';
import { asyncResource } from '../utils/services/http/asyncResource/asyncResource';
import { getDataExpiryTimestamp } from '../utils/services/http/axiosResponseUtils';

// Selectors
import { getIdleOnlineUserIds, getTrackedUserIds } from '../utils/collaboration/remoteUsersSelector';

// Constants
import { METHODS } from '../../common/utils/http/httpConstants';
import { TIMES } from '../../common/utils/timeUtil';
import { ResourceTypes } from '../utils/services/http/asyncResource/asyncResourceConstants';

export const fetchMe = () => http({ url: 'users/me', timeout: 15000, retry: 1 });

export const fetchCurrentUserAppInitialisationData = ({ timezone, disableCache } = {}) => {
    const paramsObj = {};

    if (disableCache) {
        paramsObj.disable_cache = true;
    }

    if (!isNil(timezone)) {
        paramsObj.timezone = timezone;
    }

    const params = !isEmpty(paramsObj) ? paramsObj : undefined;
    return http({ url: 'users/me/app-init', timeout: 2 * TIMES.MINUTE, retry: 1, params });
};

export const fetchCurrentUserInitialQuickSearchBoardSummariesHttp = () =>
    http({ url: 'users/me/quick-search-init', timeout: 2 * TIMES.MINUTE, retry: 1 });

export const fetchCurrentUserInitialCustomTemplatesHttp = () =>
    http({ url: 'users/me/custom-templates-init', timeout: 2 * TIMES.MINUTE, retry: 1 });

export const updateMe = ({ userChanges }) =>
    http({ url: 'users/me', method: METHODS.PUT, timeout: 11000, retry: 1, data: { userChanges } }).then(
        (response) => response.data,
    );

export const updateCurrentUserEmail = ({ email, password }) =>
    http({ url: 'users/me/email', method: METHODS.PUT, timeout: 11000, retry: 1, data: { email, password } }).then(
        (response) => response.data,
    );

export const refreshMe = () => http({ url: 'users/me/refresh', timeout: 11000 });

export const deleteMe = ({ userId, password }) =>
    http({ url: 'users/me', method: 'delete', data: { id: userId, password }, timeout: 100000 }).then(
        (response) => response.data,
    );

/**
 * Retrieves the user activity for the specified user IDs directly from the REST API (no batching).
 */
const fetchUserActivityHttp = (userIds) => {
    const usersActivityHttpConf = {
        url: 'users/activity',
        params: {
            ids: userIds.join(','),
        },
    };

    return http(usersActivityHttpConf);
};

/**
 * If the current user has remote activity in a different session, we want to double-check that this is correct
 * because it might simply be due to an electron app refresh or quickly closing the current tab and opening a new tab.
 */
const doubleCheckCurrentUserActivity = () => async (dispatch, getState) => {
    await delay(2000);

    const state = getState();
    const currentUserId = getCurrentUserId(state);

    const response = await fetchUserActivityHttp([currentUserId]);
    const users = get(['data', 'users'], response) || {};

    const currentUserHasSelection = !isEmpty(users[currentUserId]?.selectedElementIds);

    // If the current user still has a selection then just assume it's correct
    if (currentUserHasSelection) return;

    // Otherwise clear it
    dispatch(userActivityClear(currentUserId));
};

/**
 * Retrieves just the user activity details for the specified user IDs.
 */
export const fetchUserActivity = (userIds) => async (dispatch, getState) => {
    const state = getState();

    const isGuest = isGuestSelector(state);
    if (isGuest) return;

    const userIdsToFetch = userIds.filter(isRealUserId);

    if (!userIdsToFetch.length) return Promise.resolve();

    const userIdBatches = chunk(userIdsToFetch, 100);

    const currentSessionId = getSessionId();
    const currentUserId = getCurrentUserId(state);

    try {
        const responses = await Promise.all(userIdBatches.map(fetchUserActivityHttp));

        const usersMap = {};
        let shouldDoubleCheckUserActivity = false;

        responses.forEach((response) => {
            const users = get(['data', 'users'], response) || {};

            for (const userEntryId in users) {
                if (!users.hasOwnProperty(userEntryId)) continue;

                const userEntry = users[userEntryId] || {};

                if (userEntry.selectedElementsSessionId !== currentSessionId) {
                    usersMap[userEntryId] = userEntry;

                    if (userEntryId === currentUserId && !isEmpty(userEntry.selectedElementIds)) {
                        // The current user has remote activity in a different session, but this might be
                        // incorrect if redis has been slow to update
                        // E.g. Refreshing in electron will have a new session ID, or quickly closing the current
                        //  tab and opening a new tab.
                        shouldDoubleCheckUserActivity = true;
                    }
                }
            }
        });

        const disconnectedUserIds = difference(userIdsToFetch, keys(usersMap));
        dispatch(userActivityLoad({ users: usersMap, disconnectedUserIds }));

        if (shouldDoubleCheckUserActivity) dispatch(doubleCheckCurrentUserActivity());

        return responses;
    } catch (err) {
        dispatch(userActivityError(userIdsToFetch, err));
        return err;
    }
};

/**
 * Fetches the activity for all users that are known/connected to the current user.
 */
export const fetchAllUserActivity = () => (dispatch, getState) => {
    const state = getState();

    const userIds = getTrackedUserIds(state);

    return dispatch(fetchUserActivity(userIds));
};

/**
 * Checks for all idle users and retrieves their updated activity information if any are found.
 */
export const checkIdleUsers = () => (dispatch, getState) => {
    const state = getState();
    const idleUserIds = getIdleOnlineUserIds(state);

    if (!idleUserIds.length) return;

    return dispatch(fetchUserActivity(idleUserIds));
};

/**
 * Retrieves the user details as well as the user activity for the specified user IDs.
 */
export const fetchUsers =
    (userIds, force = false) =>
    (dispatch, getState) => {
        const realUserIds = userIds.filter(isRealUserId);

        if (isEmpty(realUserIds)) return;

        dispatch(
            asyncResource(
                ResourceTypes.users,
                realUserIds,
                force,
            )(async (missingUserIds) => {
                const userIdBatches = chunk(missingUserIds, 100);

                const responses = await Promise.all(
                    userIdBatches.map((ids) => {
                        dispatch(fetchUserActivity(ids));

                        return http({
                            url: 'users',
                            params: {
                                ids: ids.join(','),
                            },
                            retry: 3,
                        });
                    }),
                );

                const expiry = responses.reduce((minExpiry, response) => {
                    const responseExpiry = getDataExpiryTimestamp(response);

                    if (!minExpiry) return responseExpiry;

                    return Math.min(minExpiry, responseExpiry);
                }, undefined);

                let usersMap = {};

                responses.forEach((response) => {
                    const users = get(['data', 'users'], response) || {};
                    usersMap = assign(usersMap, users);
                });

                dispatch(loadUsers(usersMap));

                return {
                    responses,
                    asyncResource: {
                        expiry,
                    },
                };
            }),
        );
    };

let usersToFetch = [];
let fetchUsersTimeout;
export const debouncedBatchFetchUsers =
    (userIds = []) =>
    (dispatch) => {
        usersToFetch = usersToFetch.concat(userIds);

        if (fetchUsersTimeout) clearTimeout(fetchUsersTimeout);

        fetchUsersTimeout = setTimeout(() => {
            dispatch(fetchUsers(usersToFetch));
            usersToFetch = [];
        }, 100);
    };

// FIXME This doesn't use the resources
export const fetchUserWithEmail =
    ({ userEmail }) =>
    (dispatch, getState) => {
        if (!userEmail) {
            return Promise.reject(new Error('Must provide a user email.'));
        }

        const lowerCaseUserEmail = userEmail.toLowerCase();
        const state = getState();
        const users = state.get('users');

        const existingUser = users.find((userEntry) => userEntry.get('email') === lowerCaseUserEmail);

        if (existingUser) return Promise.resolve(existingUser.toJS());

        const userReq = {
            method: METHODS.GET,
            url: 'users',
            params: {
                email: lowerCaseUserEmail,
            },
        };

        return http(userReq).then((response) => {
            const userData = response.data.user;
            if (!userData || !userData._id) return;

            dispatch(loadUser(userData));

            return userData;
        });
    };

export const getOrInviteUser =
    ({ userEmail, initialEducation, onboarding }) =>
    (dispatch) => {
        if (!userEmail) {
            return Promise.reject(new Error('Must provide a user email.'));
        }

        const lowerCaseUserEmail = userEmail.toLowerCase();

        const userReq = {
            method: METHODS.POST,
            url: 'users/get-or-invite',
            data: {
                email: lowerCaseUserEmail,
                initialEducation,
                onboarding,
            },
            retry: 3,
        };

        return http(userReq).then((response) => {
            const userData = response.data.user;
            dispatch(loadUser(userData));
            return userData;
        });
    };
