// https://spin.atomicobject.com/2016/12/07/pixels-and-palettes-extracting-color-palettes-from-images/
// https://github.com/mattnedrich/palette-maker/blob/master/javascript/histogram-palette-builder.js

import { sortBy, sum, take } from 'lodash/fp';

const MAX_COLORS = 10;
const DIMENSION_MAX = 256;

const sortByLength = sortBy((array) => array.length);

const getPixelRed = ([red]) => red;
const getPixelGreen = ([_, green]) => green;
const getPixelBlue = ([_, __, blue]) => blue;

const averagePixelArray = (pixels) => {
    const totalRed = sum(pixels.map(getPixelRed));
    const totalGreen = sum(pixels.map(getPixelGreen));
    const totalBlue = sum(pixels.map(getPixelBlue));

    return [
        Math.round(totalRed / pixels.length),
        Math.round(totalGreen / pixels.length),
        Math.round(totalBlue / pixels.length),
    ];
};

const getKeyForPixel = ([red, green, blue], bucketsPerDimension) => {
    const bucketSize = DIMENSION_MAX / bucketsPerDimension;

    const redBucket = Math.floor(red / bucketSize);
    const greenBucket = Math.floor(green / bucketSize);
    const blueBucket = Math.floor(blue / bucketSize);

    return `${redBucket}:${greenBucket}:${blueBucket}`;
};

const createPalette = (pixels) => {
    const bucketMap = {};

    pixels.forEach((pixel) => {
        const key = getKeyForPixel(pixel, 5);

        if (bucketMap[key]) {
            bucketMap[key].push(pixel);
        } else {
            bucketMap[key] = [pixel];
        }
    });

    const buckets = Object.values(bucketMap);
    const sortedBuckets = sortByLength(buckets).reverse();

    const colors = take(MAX_COLORS, sortedBuckets.map(averagePixelArray));

    return colors;
};

export default createPalette;
