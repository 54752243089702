/* eslint-disable import/no-mutable-exports */
// Utils
import platformSingleton from '../platform/platformSingleton';
import { isPlatformElectronMac, isPlatformLegacyMobileApp } from '../platform/utils/platformDetailsUtils';

let configureStore;

if (isPlatformElectronMac(platformSingleton)) {
    configureStore = require('./configureStoreElectronClient').default;
} else if (isPlatformLegacyMobileApp(platformSingleton)) {
    configureStore = require('./configureStoreMobileHybrid').default;
} else {
    configureStore = require('./configureStoreWeb').default;
}

export default configureStore;
/* eslint-enable import/no-mutable-exports */
