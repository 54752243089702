import React from 'react';
import MobileModalSheet, { Content, Overlay, Sheet } from '../../../../mobile/structural/modalSheet/MobileModalSheet';

type MobileToolbarSheetProps = {
    className?: string;
    isSheetOpen: boolean;
    setIsSheetOpen: (isOpen: boolean) => void;
    activeSnapPoint: number;
    setActiveSnapPoint: (activeSnapPoint: number) => void;
    onSheetClose?: () => void;
    children: React.ReactNode;
    onCloseTransitionEnd?: () => void;
};

const MobileToolbarSheet = (props: MobileToolbarSheetProps) => {
    const { children, ...rest } = props;

    return (
        <MobileModalSheet defaultSnapPoint={'0.5max'} {...rest}>
            <Overlay />
            <Sheet>
                <Content>{children}</Content>
            </Sheet>
        </MobileModalSheet>
    );
};

export default MobileToolbarSheet;
