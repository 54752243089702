import { getElementBoundingRect } from '../domUtil';

export const registerDownloadSource = (domPath, offset = { x: 0, y: 0 }) => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.milanoteDownloader) {
        window.webkit.messageHandlers.milanoteDownloader.postMessage({ domPath, offset });
    }
};

// Expose for use in Swift layer
window.getElementBoundingRect = getElementBoundingRect;
