// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Utils
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import {
    getIsLineLabelEnabled,
    getLineStyle,
    getLineWeight,
} from '../../../common/elements/utils/elementPropertyUtils';

// Components
import EdgeDragHandle from './EdgeDragHandle';
import ConnectedEdgeDragHandle from './ConnectedEdgeDragHandle';
import ControlPointDragHandle from './ControlPointDragHandle';
import ConnectedControlPointDragHandle from './ConnectedControlPointDragHandle';
import LineLabelContainer from './label/LineLabelContainer';
import LineGhostSvg from './LineGhostSvg';
import LineSvg from './svg/LineSvg';
import MiniElementUserTag from '../../user/userActivity/MiniElementUserTag';
import AbsolutePositioner from '../../components/layout/AbsolutePositioner';

// Constant
import { LINE_EDGE, LINE_WEIGHT } from '../../../common/lines/lineConstants';

// Styles
import './Line.scss';

class Line extends React.Component {
    componentDidMount() {
        this.props.disableDrag && this.props.disableDrag();
    }

    render() {
        const {
            elementId,
            elementEvents,
            isSelected,
            colorHex,
            isPresentational,
            shouldHideLineInPresentationMode,
            start,
            end,
            updateLineEdge,
            gridSize,
            toggleLineVisibility,
            startStyle,
            endStyle,
            isRemotelySelected,
            updateLineControlPoint,
            controlPoint,
            enableDrag,
            disableDrag,
            element,
            startEdgeOrigin,
            endEdgeOrigin,
            dragModifierKeys,
            dispatchPointDragStart,
            invisible,
            startHandleMode,
            endHandleMode,
            controlHandleMode,
            isEditable,
            isEditing,
            onKeyDown,
            lineRef,
            filterQuery,
            remoteSelectionData,
            visibleBezier,
            startGhostBezier,
            endGhostBezier,
            showStartGhost,
            showEndGhost,
        } = this.props;

        if (isEmpty(start) || isEmpty(end)) return null;

        if (shouldHideLineInPresentationMode) return null;

        const classes = elementClassNames('Line focusable', this.props);

        const lineStyle = getLineStyle(element);
        const lineWeight = getLineWeight(element) || LINE_WEIGHT.M;

        const hasLabel = !!getIsLineLabelEnabled(element);

        return (
            <div
                id={getDomElementId(elementId)}
                className={classes}
                ref={lineRef}
                tabIndex="-1"
                onMouseDown={this.checkHitArea}
                onKeyDown={onKeyDown}
                style={{ '--ws-line-color': colorHex }}
            >
                <LineGhostSvg show={showStartGhost} bezier={startGhostBezier} />
                <LineGhostSvg show={showEndGhost} bezier={endGhostBezier} />
                <LineSvg
                    elementId={elementId}
                    elementEvents={elementEvents}
                    isPresentational={isPresentational}
                    bezier={visibleBezier}
                    startStyle={startStyle}
                    endStyle={endStyle}
                    gridSize={gridSize}
                    lineStyle={lineStyle}
                    lineWeight={lineWeight}
                    idSuffix={isPresentational ? 'drag' : null}
                    updateLineControlPoint={updateLineControlPoint}
                    disableDrag={!invisible ? disableDrag : null}
                    enableDrag={!invisible ? enableDrag : null}
                />
                <LineLabelContainer
                    startEdgeOrigin={startEdgeOrigin}
                    endEdgeOrigin={endEdgeOrigin}
                    isRemotelySelected={isRemotelySelected}
                    remoteSelectionData={remoteSelectionData}
                    toggleLineVisibility={toggleLineVisibility}
                    dispatchPointDragStart={dispatchPointDragStart}
                    updateLineControlPoint={updateLineControlPoint}
                    filterQuery={filterQuery}
                    isPresentational={isPresentational}
                    isEditable={isEditable}
                    isEditing={isEditing}
                    isSelected={isSelected}
                    gridSize={gridSize}
                    controlPoint={controlPoint}
                    element={element}
                    elementId={elementId}
                    elementEvents={elementEvents}
                />
                {!hasLabel && (
                    <AbsolutePositioner className="line-user-tag-container" {...controlPoint}>
                        <MiniElementUserTag
                            isRemotelySelected={isRemotelySelected}
                            remoteSelectionData={remoteSelectionData}
                        />
                    </AbsolutePositioner>
                )}
                <EdgeDragHandle pos={startEdgeOrigin} show handleMode={startHandleMode} />
                <EdgeDragHandle pos={endEdgeOrigin} show handleMode={endHandleMode} />
                {!hasLabel && !isEditing && (
                    <ControlPointDragHandle pos={controlPoint} show={!hasLabel} handleMode={controlHandleMode} />
                )}
                <ConnectedEdgeDragHandle
                    edge={LINE_EDGE.start}
                    pos={startEdgeOrigin}
                    show={isSelected && !isPresentational}
                    updateLineEdge={updateLineEdge}
                    gridSize={gridSize}
                    toggleLineVisibility={toggleLineVisibility}
                    dragModifierKeys={dragModifierKeys}
                    dispatchPointDragStart={dispatchPointDragStart}
                    element={element}
                    origin={endEdgeOrigin}
                    elementId={elementId}
                />
                <ConnectedEdgeDragHandle
                    edge={LINE_EDGE.end}
                    pos={endEdgeOrigin}
                    show={isSelected && !isPresentational}
                    updateLineEdge={updateLineEdge}
                    gridSize={gridSize}
                    toggleLineVisibility={toggleLineVisibility}
                    dragModifierKeys={dragModifierKeys}
                    dispatchPointDragStart={dispatchPointDragStart}
                    element={element}
                    origin={startEdgeOrigin}
                    elementId={elementId}
                />
                <ConnectedControlPointDragHandle
                    startEdgeOrigin={startEdgeOrigin}
                    endEdgeOrigin={endEdgeOrigin}
                    toggleLineVisibility={toggleLineVisibility}
                    dispatchPointDragStart={dispatchPointDragStart}
                    updateLineControlPoint={updateLineControlPoint}
                    gridSize={gridSize}
                    controlPoint={controlPoint}
                    element={element}
                    elementId={elementId}
                    show={isSelected && !isPresentational && !hasLabel}
                />
            </div>
        );
    }
}

Line.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string.isRequired,
    elementEvents: PropTypes.object,
    isSelected: PropTypes.bool,
    isPresentational: PropTypes.bool,
    colorHex: PropTypes.string,
    start: PropTypes.object,
    end: PropTypes.object,
    updateLineEdge: PropTypes.func,
    updateLineControlPoint: PropTypes.func,
    toggleLineVisibility: PropTypes.func,
    gridSize: PropTypes.number,
    startStyle: PropTypes.string,
    endStyle: PropTypes.string,
    isRemotelySelected: PropTypes.bool,
    remoteSelectionData: PropTypes.object,
    startHandleMode: PropTypes.string,
    endHandleMode: PropTypes.string,
    controlHandleMode: PropTypes.string,
    startEdgeOrigin: PropTypes.object,
    endEdgeOrigin: PropTypes.object,
    controlPoint: PropTypes.object,
    enableDrag: PropTypes.func,
    disableDrag: PropTypes.func,
    dragModifierKeys: PropTypes.object,
    shouldHideLineInPresentationMode: PropTypes.bool,
    dispatchPointDragStart: PropTypes.func,
    invisible: PropTypes.bool,
    filterQuery: PropTypes.string,

    isEditable: PropTypes.bool,
    isEditing: PropTypes.bool,

    // key down handling
    lineRef: PropTypes.object,
    onKeyDown: PropTypes.func,

    // Bezier object
    visibleBezier: PropTypes.object,
    startGhostBezier: PropTypes.object,
    endGhostBezier: PropTypes.object,

    showStartGhost: PropTypes.bool,
    showEndGhost: PropTypes.bool,
};

export default Line;
