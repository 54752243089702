// Lib
import { negate, isEmpty } from 'lodash';

// Utils
import { getElementId, isElementLocked } from '../../../common/elements/utils/elementPropertyUtils';
import { validateElementTypeEditPermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { canvasElementSorter } from '../../../common/elements/utils/elementSortUtils';
import {
    doesElementConnectTwoElements,
    getConnectedElements,
    getConnectingElementIds,
} from '../connections/elementConnectionsUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isInColumn } from '../../../common/elements/utils/elementLocationUtils';

// Selectors
import { getSelectedElementIds, getSelectedElements } from '../selection/selectedElementsSelector';
import { getCurrentBoardVisibleDescendants } from '../selectors/currentBoardSelector';

/**
 * Gets the elements that will be dragged based on a grabbed element and the currently selected elements.
 */
export const getDraggedElementsThunk =
    ({ grabbedElement, permissions }) =>
    (dispatch, getState) => {
        const state = getState();

        const selectedElementIds = getSelectedElementIds(state);
        const selectedElements = getSelectedElements(state);

        const grabbedElementId = getElementId(grabbedElement);

        let draggedElements;

        if (selectedElementIds.size && selectedElementIds.includes(grabbedElementId)) {
            draggedElements = selectedElements
                .filter(negate(isElementLocked))
                .filter(validateElementTypeEditPermission(permissions))
                .toArray()
                .sort(canvasElementSorter);
        } else {
            draggedElements = [grabbedElement];
        }

        const currentBoardElements = getCurrentBoardVisibleDescendants(state);
        const draggedElementIds = draggedElements.map(getElementId);

        // Get all lines that are connected to draggedElements
        const lines = getConnectedElements(currentBoardElements.valueSeq(), draggedElementIds).toArray();

        if (!isEmpty(lines)) {
            lines.forEach((line) => {
                // Add line to draggedElements if it connects two draggedElements
                if (doesElementConnectTwoElements(draggedElementIds)(line)) {
                    draggedElements.push(line);
                }
            });
        }

        // Add elements connected by selected lines
        const connectingElementIds = getConnectingElementIds(draggedElements);

        if (!isEmpty(connectingElementIds)) {
            connectingElementIds.forEach((connectingElementId) => {
                if (selectedElementIds.size && selectedElementIds.includes(connectingElementId)) return;

                const connectingElement = getElement(currentBoardElements, connectingElementId);
                if (!connectingElement) return;

                if (isInColumn(currentBoardElements)(connectingElement)) return;

                draggedElements.push(connectingElement);
            });
        }

        return draggedElements;
    };
