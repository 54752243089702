// Lib
import { EditorState, Modifier, SelectionState } from 'draft-js';

// Utils
import clamp from '../../../../../common/maths/clamp';
import isListBlockType from '../../customRichUtils/blocks/isListBlockType';
import replaceSelectionWithText from '../../customRichUtils/replaceSelectionWithText';
import shiftSelection from '../../customRichUtils/selection/shiftSelection';
import insertTextAtStartOfEachBlock from '../../customRichUtils/insertTextAtStartOfEachBlock';
import getSelectionBlockMap from '../../customRichUtils/selection/getSelectionBlockMap';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../../draftjsConstants';

const MAX_DEPTH = 4;

const getSelectedBlocksSeq = (editorState) => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    const currentContent = editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();

    return blockMap
        .toSeq()
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);
};

const blockIsList = (block) => isListBlockType(block.getType());

const isSelectionWithinList = (editorState) => getSelectedBlocksSeq(editorState).some(blockIsList);

const handleListIndentChange = (editorState, increaseIndentation) => {
    const currentContent = editorState.getCurrentContent();

    const updatedBlocks = getSelectedBlocksSeq(editorState)
        .filter(blockIsList)
        .map((block) => {
            const depth = block.getDepth();
            if (increaseIndentation && depth === MAX_DEPTH) return block;

            const depthAdjustment = increaseIndentation ? 1 : -1;
            const newDepth = clamp(0, MAX_DEPTH, depth + depthAdjustment);
            return block.set('depth', newDepth);
        });

    if (!updatedBlocks.count()) return editorState;

    const selection = editorState.getSelection();
    const blockMap = currentContent.getBlockMap();
    const updatedBlockMap = blockMap.merge(updatedBlocks);

    const updatedContent = currentContent.merge({
        blockMap: updatedBlockMap,
        selectionBefore: selection,
        selectionAfter: selection,
    });

    return EditorState.push(editorState, updatedContent, EditorChangeType.ADJUST_DEPTH);
};

const replaceWithTabCharacter = replaceSelectionWithText('\t');
const insertTabCharacterAtStartOfLines = insertTextAtStartOfEachBlock('\t');

const insertTab = (editorState) =>
    editorState.getSelection().isCollapsed()
        ? replaceWithTabCharacter(editorState)
        : insertTabCharacterAtStartOfLines(editorState);

const blockHasTabAtStart = (contentState, blockKey) => {
    const contentBlock = contentState.getBlockForKey(blockKey);

    const blockText = contentBlock.getText();
    const firstCharacter = blockText[0];

    return firstCharacter === '\t';
};

const removeTabFromStartOfContentBlock = (contentState, blockKey) => {
    if (!blockHasTabAtStart(contentState, blockKey)) return contentState;

    let removalRange = SelectionState.createEmpty(blockKey);
    removalRange = removalRange.merge({ focusOffset: 1 });

    return Modifier.removeRange(contentState, removalRange, DraftRemovalDirection.backward);
};

const removeTabFromStartOfEachLine = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const anchorKey = selection.getAnchorKey();
    const focusKey = selection.getFocusKey();

    const anchorHadTab = blockHasTabAtStart(contentState, anchorKey);
    const focusHadTab = blockHasTabAtStart(contentState, focusKey);

    // Loop over selection blocks
    const blockRange = getSelectionBlockMap(editorState);
    let newContentState = blockRange.reduce((updatedContentState, contentBlock) => {
        const blockKey = contentBlock.getKey();
        return removeTabFromStartOfContentBlock(updatedContentState, blockKey);
    }, contentState);

    if (newContentState === contentState) return editorState;

    const newSelection = shiftSelection(selection, anchorHadTab ? -1 : 0, focusHadTab ? -1 : 0);
    newContentState = newContentState.set('selectionAfter', newSelection);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);

    return EditorState.forceSelection(newEditorState, newSelection);
};

const handleSingleLineTabRemoval = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // First get the character immediately before the startAnchor
    const startOffset = selection.getStartOffset();

    // If we're at offset 0 try deleting the first
    if (startOffset === 0) return removeTabFromStartOfEachLine(editorState);

    const startKey = selection.getStartKey();
    const startBlock = contentState.getBlockForKey(startKey);

    const startBlockText = startBlock.getText();
    const previousCharacter = startBlockText.slice(startOffset - 1, startOffset);

    if (previousCharacter !== '\t') return removeTabFromStartOfEachLine(editorState);

    // If the character is '\t' remove it
    let removalRange = SelectionState.createEmpty(selection.getStartKey());
    removalRange = removalRange.merge({
        anchorKey: selection.getStartKey(),
        anchorOffset: selection.getStartOffset() - 1,
        focusKey: selection.getStartKey(),
        focusOffset: selection.getStartOffset(),
        isBackward: false,
        hasFocus: false,
    });

    let newContentState = Modifier.removeRange(contentState, removalRange, DraftRemovalDirection.backward);

    // Set the selection to the new spot
    const newSelection = shiftSelection(selection, -1, -1);
    newContentState = newContentState.set('selectionAfter', newSelection);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);

    return EditorState.forceSelection(newEditorState, newSelection);
};

const removeTab = (editorState) =>
    editorState.getSelection().isCollapsed()
        ? handleSingleLineTabRemoval(editorState)
        : removeTabFromStartOfEachLine(editorState);

const handleNormalTab = (editorState, increaseIndentation) =>
    increaseIndentation ? insertTab(editorState) : removeTab(editorState);

/**
 * Allows multi-line indenting in one go, whereas the RichUtils only allows single lines.
 */
export default (event, { getEditorState, setEditorState }) => {
    // NOTE: This is a workaround to allow the mention suggestions to auto-complete on tab.
    // If this causes issues it will need to be revisited
    if (event.isDefaultPrevented()) return;

    event.preventDefault();
    const editorState = getEditorState();

    const increaseIndentation = !event.shiftKey;
    const updatedState = isSelectionWithinList(editorState)
        ? handleListIndentChange(editorState, increaseIndentation)
        : handleNormalTab(editorState, increaseIndentation);

    if (updatedState && updatedState !== editorState) setEditorState(updatedState);
};
