// Utils
import processError from './processError';
import addUserId from './addUserId';

// Constants
import { ROLLBAR_LEVELS } from './rollbarConstants';

// Utils
import logger from '../logger/logger';

const getRollbar = () => {
    try {
        return window.Rollbar;
    } catch (err) {
        logger.error('Unable to retrieve Rollbar', err);
        return null;
    }
};

export const initialiseRollbar = () => {
    const rollbar = getRollbar();

    if (!rollbar) return;

    rollbar.configure({
        // Don't send or anonymize PII information sent to Rollbar
        captureIp: 'anonymize',
        captureUsername: false,
        captureEmail: false,
    });
};

export const setRollbarUser = (user) => {
    const rollbar = getRollbar();

    if (!rollbar) return;

    rollbar.configure({
        payload: {
            person: {
                id: user._id,
            },
        },
    });
};

export const manuallyReportError = (args) => {
    const { errorMessage, error = {}, sensitive, custom, level = ROLLBAR_LEVELS.ERROR, getState } = args;

    const rollbar = getRollbar();

    if (!rollbar) return;

    const levelFn = !!rollbar[level] ? level : ROLLBAR_LEVELS.ERROR;

    let processedError = processError({ error, sensitive, custom });
    processedError = addUserId(processedError, getState);

    rollbar[levelFn](errorMessage, error, processedError);
};
