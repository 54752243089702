// Lib
import React from 'react';
import { connect } from 'react-redux';

// Utils
import {
    getSelectedElementColorStatusClass,
    getFirstSelectedSecondaryColor,
    getSelectedElementType,
    getSupportColorBar,
} from './colorToolUtils';
import { isCard } from '../../../../../../common/elements/utils/elementTypeUtils';

// Components
import ToolbarPopupTool from '../../ToolbarPopupTool';
import ColorToolIcon from './ColorToolIcon';
import ColorPopup from './popup/desktop/DesktopColorPopup';

// Selectors
import { colorToolSelector, ColorToolSelectorReturnType } from './colorToolSelector';

// Constants
import { BACKGROUND_COLORS } from '../../../../../../common/colors/colorConstants';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

// Types
import { ToolbarItemConfig } from '../../../toolbarTypes';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

// Styles
import './DesktopColorTool.scss';

type DesktopColorToolProps = ColorToolSelectorReturnType & {
    selectedElements: Immutable.List<ImMNElement>;
    editorKey: string;
    item: ToolbarItemConfig;
    isPopupOpen: boolean;
};

const ColorToolPopupIcon = ({
    selectedElements,
    selectedColor,
    selectedBackgroundColor,
    selectedBackgroundColorName,
    isPopupOpen,
    supportBackgroundColor,
}: DesktopColorToolProps) => {
    const selectedType = getSelectedElementType(selectedElements);
    const selectedStatus = getSelectedElementColorStatusClass(selectedElements);
    const supportColorBar = getSupportColorBar(selectedElements);
    const secondaryColor = getFirstSelectedSecondaryColor(selectedElements);

    const backgroundColorClass = BACKGROUND_COLORS[selectedBackgroundColorName]?.className;

    return (
        <ColorToolIcon
            key={`${selectedColor}${selectedBackgroundColor}`}
            className={backgroundColorClass}
            supportColorBar={supportColorBar}
            supportBackgroundColor={supportBackgroundColor}
            selectedType={selectedType}
            selectedStatus={selectedStatus}
            color={selectedColor}
            backgroundColor={selectedBackgroundColor}
            isActive={isPopupOpen}
            secondaryColor={secondaryColor}
        />
    );
};

const DesktopColorTool = (props: DesktopColorToolProps) => {
    const { selectedElements, item } = props;

    const toolName = selectedElements.every(isCard) ? 'Card color' : 'Color';

    return (
        <ToolbarPopupTool
            {...props}
            buttonSelector=".ColorTool .ColorToolIcon"
            popupId={item?.uid || PopupIds.COLOR}
            toolName="color"
            name={item?.name || toolName}
            IconComponent={ColorToolPopupIcon}
            PopupComponent={ColorPopup}
        />
    );
};

const enhance = connect(colorToolSelector);

export default enhance(DesktopColorTool);
