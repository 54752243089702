// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ShortcutKey from './modal/ShortcutKey';

// Styles
import './ShortcutKeyGroup.scss';

const ShortcutKeyGroup = (props) => {
    const { shortcut, preferSymbol, size } = props;

    if (!shortcut) return null;

    return (
        <div className="ShortcutKeyGroup">
            {shortcut.map((key, index) => (
                <ShortcutKey preferSymbol={preferSymbol} key={index} size={size}>
                    {key}
                </ShortcutKey>
            ))}
        </div>
    );
};

ShortcutKeyGroup.propTypes = {
    size: PropTypes.string,
    shortcut: PropTypes.array,
    preferSymbol: PropTypes.bool,
};

export default ShortcutKeyGroup;
