// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { difference, isEqual } from 'lodash';

// Utils
import rIC from '../../common/utils/lib/rIC';
import { hasChanged, noLonger, now } from '../utils/react/propsComparisons';

const childElementIdsHaveChanged = hasChanged('childElementIds');
const batchedRenderNoLongerInProgress = noLonger('isBatchedRenderInProgress');
const batchedRenderNowInProgress = now('isBatchedRenderInProgress');

const MAX_ITERATIONS = 80;
const EMPTY_ARRAY = [];
const DEFAULT_RENDER_INCREMENT = 20;

class OrchestratedList extends React.Component {
    state = {
        isBatchedRenderInProgress: true,
        elementIdsToRender: EMPTY_ARRAY,
    };

    componentDidMount() {
        this.startBatchedRender();
    }

    componentWillReceiveProps(nextProps) {
        const { isBatchedRenderInProgress } = this.state;
        if (!isBatchedRenderInProgress && !nextProps.batchRenderAdditionalContent) return;

        if (!childElementIdsHaveChanged(this.props, nextProps)) return;

        const addedElements = difference(nextProps.childElementIds, this.props.childElementIds);
        const removedElements = difference(this.props.childElementIds, nextProps.childElementIds);

        // Handle changes during batched rendering
        if (isBatchedRenderInProgress) {
            addedElements.forEach((elementId) => {
                const index = nextProps.childElementIds.indexOf(elementId);
                if (index === -1) return;

                // If the element in the new childElementIds is before the final element in the initialRenderElementIds,
                // insert it into the initialRenderElementIds at the correct position
                if (index <= this.initialRenderElementIds.length - 1) {
                    this.initialRenderElementIds.splice(index, 0, elementId);
                    return;
                }

                // Otherwise add it to the orderedElementIds
                this.orderedElementIds.splice(index - this.initialRenderElementIds.length, 0, elementId);
            });

            removedElements.forEach((elementId) => {
                let index = this.initialRenderElementIds.indexOf(elementId);
                if (index !== -1) {
                    this.initialRenderElementIds.splice(index, 1);
                }

                index = this.orderedElementIds.indexOf(elementId);
                if (index !== -1) {
                    this.orderedElementIds.splice(index, 1);
                }
            });
        }

        // After batched render has finished, if content has been added to the end, trigger a new batched render
        // e.g. clicking the "Load more" button in trash.
        if (!isBatchedRenderInProgress && this.props.batchRenderAdditionalContent && addedElements.length > 0) {
            // We only want to trigger a new batched render if all the new elements are added to the END of the list
            if (!isEqual(addedElements, nextProps.childElementIds.slice(-addedElements.length))) return;

            if (!nextProps.childElementIds || !nextProps.childElementIds.length) return;

            // Set the state to trigger a new batched render after the props have updated
            this.updateRenderState({ isBatchedRenderInProgress: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (batchedRenderNoLongerInProgress(prevState, this.state) && this.props.onOrchestrationEnd) {
            this.props.onOrchestrationEnd();
        }

        // Restart the batched render
        if (this.props.batchRenderAdditionalContent && batchedRenderNowInProgress(prevState, this.state)) {
            if (this.props.onOrchestrationStart) this.props.onOrchestrationStart();

            // Reset the lists and counter for the new batched render
            const newInitialRenderSize = prevProps.childElementIds.length + this.props.initialRenderSize;
            this.initialRenderElementIds = this.props.childElementIds.slice(0, newInitialRenderSize);
            this.orderedElementIds = this.props.childElementIds.slice(this.initialRenderElementIds.length);
            this.numberOfIterations = 0;

            this.scheduleBatchedRender();
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    updateRenderState = (newState) => {
        const { beforeRenderBatch } = this.props;

        // The only expected state update that wouldn't trigger a new render is setting isBatchedRenderInProgress to true
        if (beforeRenderBatch && !batchedRenderNowInProgress(this.state, newState)) {
            beforeRenderBatch();
        }

        this.setState(newState);
    };

    startBatchedRender = () => {
        if (this.props.onOrchestrationStart) this.props.onOrchestrationStart();

        const { initialRenderSize = DEFAULT_RENDER_INCREMENT } = this.props;

        this.initialRenderElementIds =
            this.props.childElementIds && this.props.childElementIds.length
                ? this.props.childElementIds.slice(0, initialRenderSize)
                : EMPTY_ARRAY;

        // This is the array of Ids that will be gradually added as the batched render runs
        this.orderedElementIds = this.props.childElementIds.slice(initialRenderSize);
        this.numberOfIterations = 0;

        this.scheduleBatchedRender();
    };

    scheduleBatchedRender = () => {
        if (this.unmounted) return;

        const { childElementIds, renderIncrement = DEFAULT_RENDER_INCREMENT } = this.props;
        const { elementIdsToRender } = this.state;

        this.numberOfIterations++;

        // First render
        if (this.numberOfIterations === 1) {
            this.updateRenderState({
                elementIdsToRender: this.initialRenderElementIds,
                isBatchedRenderInProgress: this.initialRenderElementIds.length < childElementIds.length,
            });

            if (this.initialRenderElementIds.length < childElementIds.length) {
                rIC(this.scheduleBatchedRender);
            }

            return;
        }

        // Too many renders, bail out
        if (this.numberOfIterations > MAX_ITERATIONS) {
            this.updateRenderState({ isBatchedRenderInProgress: false });
            return;
        }

        // Later renders
        const currentlyRenderedElementCount = elementIdsToRender.length;
        const totalElementCount = childElementIds.length;

        const newRenderCount = Math.min(currentlyRenderedElementCount + renderIncrement, totalElementCount);

        const finishedInitialRender = newRenderCount >= totalElementCount;

        if (finishedInitialRender) {
            this.updateRenderState({ isBatchedRenderInProgress: false });
        } else {
            const newElementIdsToRender = [
                ...this.initialRenderElementIds,
                ...this.orderedElementIds.slice(0, newRenderCount - this.initialRenderElementIds.length),
            ];

            this.updateRenderState({
                elementIdsToRender: newElementIdsToRender,
            });

            // Schedule another render if it's not finished rendering
            rIC(this.scheduleBatchedRender);
        }
    };

    render() {
        const { isBatchedRenderInProgress, elementIdsToRender } = this.state;
        const { childElementIds, children } = this.props;

        if (!children || typeof children !== 'function') return null;

        const renderedElementIds = isBatchedRenderInProgress ? elementIdsToRender : childElementIds;

        // Pass list length, so we can prevent multiple child renders by forcing the list size to the completed list
        return children(renderedElementIds, childElementIds.length);
    }
}

OrchestratedList.propTypes = {
    childElementIds: PropTypes.array,
    initialRenderSize: PropTypes.number,
    onOrchestrationStart: PropTypes.func,
    onOrchestrationEnd: PropTypes.func,
    beforeRenderBatch: PropTypes.func,
    batchRenderAdditionalContent: PropTypes.bool,
    renderIncrement: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

export default OrchestratedList;
