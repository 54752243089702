// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { stopDefaultAndPropagation, stopPropagationOnly } from '../../../utils/domUtil';

// Actions
import { intercomShowMessages } from '../../../analytics/analyticsActions';
import { refreshPageAndClearLocalCache } from '../../../reducers/navigationActions';

// Components
import LinkButton from '../../buttons/LinkButton';
import DefaultErrorFurtherDetails from './DefaultErrorFurtherDetails';

// Styles
import './ErrorRenderer.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleIntercom: () => dispatch(intercomShowMessages()),
    dispatchRefreshPageAndClearLocalCache: () => dispatch(refreshPageAndClearLocalCache()),
});

const DefaultErrorRenderer = (props) => {
    const {
        className,
        heading = 'Something went wrong',
        dispatchRefreshPageAndClearLocalCache,
        furtherMessage = '',
    } = props;

    return (
        <div
            className={classNames('ErrorRenderer', className)}
            onClick={stopPropagationOnly}
            onDoubleClick={stopDefaultAndPropagation}
        >
            <div className="error-message">
                <h3>{heading}</h3>

                <p className="refresh-message">
                    First try{' '}
                    <LinkButton onClick={dispatchRefreshPageAndClearLocalCache}>refreshing the page</LinkButton>
                    {furtherMessage}
                </p>
            </div>
            <DefaultErrorFurtherDetails {...props} />
        </div>
    );
};

DefaultErrorRenderer.propTypes = {
    className: PropTypes.string,
    furtherDetails: PropTypes.string,
    furtherMessage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
    heading: PropTypes.string,
    dispatchToggleIntercom: PropTypes.func,
    dispatchRefreshPageAndClearLocalCache: PropTypes.func,
    getErrorDetails: PropTypes.func,
    error: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(DefaultErrorRenderer);
