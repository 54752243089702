// Lib
import { useMemo } from 'react';
import { get } from 'lodash/fp';

const OAUTH_ERROR = {
    ACCESS_DENIED: 'access_denied',
    USER_CANCELLED_AUTHORIZE: 'user_cancelled_authorize',
    UNREGISTERED_USER: 'unregistered_user',
    CONFIRMATION_FAILURE: 'confirmation_failure',
    // Custom message
    EMAIL_CONFLICT: 'email_conflict',
};

const getErrorParam = get(['query', 'error']);
const getEmailParam = get(['query', 'email']);
const getProviderParam = get(['query', 'provider']);
const getOriginalEmailParam = get(['query', 'original-email']);
const getNewEmailParam = get(['query', 'new-email']);

// Access denied is user initiated, so we shouldn't show an error in that case
const getShowOauthError = (location) => {
    const error = getErrorParam(location);
    return !!error && error !== OAUTH_ERROR.ACCESS_DENIED && error !== OAUTH_ERROR.USER_CANCELLED_AUTHORIZE;
};

const getSocialProvider = (location) => getProviderParam(location) || 'Google';

const getOauthErrorMessage = (location) => {
    const error = getErrorParam(location);

    switch (error) {
        case OAUTH_ERROR.UNREGISTERED_USER: {
            const email = getEmailParam(location);
            return email ? `The email ${email} is not registered` : 'That email is not registered';
        }
        case OAUTH_ERROR.CONFIRMATION_FAILURE:
            return `Failed to register the ${getEmailParam(location)} account`;
        case OAUTH_ERROR.EMAIL_CONFLICT:
            return (
                `Your ${getSocialProvider(location)} Sign In email has changed, from ` +
                `"${getOriginalEmailParam(location)}" to "${getNewEmailParam(location)}"` +
                ' but another account is already using the new email.' +
                '  Please contact support to resolve this issue.'
            );
        default:
            return `Something went wrong during ${getSocialProvider(location)} sign in`;
    }
};

export default (location) =>
    useMemo(() => {
        const showOauthError = getShowOauthError(location);
        const errorMessage = getOauthErrorMessage(location);

        return showOauthError && errorMessage ? { message: errorMessage } : undefined;
    }, [location]);
