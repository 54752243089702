// Lib
import React, { ReactElement } from 'react';

// Components
import ElementReactionPillContainer from '../../../reactions/ElementReactionPillContainer';

// Styles
import './TaskReactionPill.scss';

interface TaskReactionPillProps {
    elementId: string;
    permissions: number;
}

const TaskReactionPill = (props: TaskReactionPillProps): ReactElement => (
    <div className="task-reaction-pill-container">
        <ElementReactionPillContainer {...props} className="TaskReactionPill" />
    </div>
);

export default TaskReactionPill;
