import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CheckboxCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon checkbox-checked"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={16} height={16} fill="#CBCED2" rx={2} />
            <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m4 8 3 3 5-5" />
        </g>
    </svg>
);
const Memo = memo(CheckboxCheckedIcon);
export default Memo;
