// Services
import { getCurrentUserToken } from '../../../auth/authService';

export default (config) => {
    const token = getCurrentUserToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};
