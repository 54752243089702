export const ACCESS_BITS: { [key: string]: number } = {
    READ: 1,
    WRITE: 1 << 1,
    SAVE: 1 << 2,
    PERMISSIONS: 1 << 2,
    FEEDBACK: 1 << 3,
    OWNER: 1 << 4,

    // Blocked is any negative - javascript uses the two's complement system to represent negative numbers,
    // meaning that the largest bit (#32) will be 1 if it's negative
    BLOCKED: 1 << 31,
};
