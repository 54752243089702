// Constants
import SELECTED_TOOL_CONFIG from './toolDefinitions/toolbarSelectedToolConfig';
import EDITING_TOOL_CONFIG from './toolDefinitions/toolbarEditingToolConfig';
import META_TOOL_CONFIG from './toolDefinitions/toolbarMetaToolConfig';

/**
 * sets of commonly used tools
 * */
export const TOOL_SETS = {
    /**
     * Set of tools that are used for multi-select arrangement
     * */
    MULTI_SELECT_ARRANGEMENT: [
        SELECTED_TOOL_CONFIG.ALIGNMENT,
        SELECTED_TOOL_CONFIG.DISTRIBUTE,
        SELECTED_TOOL_CONFIG.CONNECT_WITH_LINES,
        SELECTED_TOOL_CONFIG.MOBILE_CONTEXT_MENU,
    ],
    /**
     * Text editing tools that only support inline styles
     * */
    TEXT_EDITING_INLINE: [
        EDITING_TOOL_CONFIG.TEXT_STYLES,
        EDITING_TOOL_CONFIG.TEXT_BOLD,
        EDITING_TOOL_CONFIG.TEXT_ITALIC,
        EDITING_TOOL_CONFIG.TEXT_STRIKETHROUGH,
        EDITING_TOOL_CONFIG.TEXT_HYPERLINK,
    ],
    /**
     * Text editing tools that support inline and block styles
     * */
    TEXT_EDITING_INLINE_AND_BLOCK: [
        EDITING_TOOL_CONFIG.TEXT_BLOCK_STYLES,
        EDITING_TOOL_CONFIG.TEXT_BOLD,
        EDITING_TOOL_CONFIG.TEXT_ITALIC,
        EDITING_TOOL_CONFIG.TEXT_STRIKETHROUGH,
        EDITING_TOOL_CONFIG.TEXT_BULLET_LIST,
        EDITING_TOOL_CONFIG.TEXT_ORDERED_LIST,
        EDITING_TOOL_CONFIG.TEXT_CENTERED,
        EDITING_TOOL_CONFIG.TEXT_HYPERLINK,
    ],

    /**
     * Standard set of tools for editing captions,
     * including a predicate to only show them when specifically editing a caption
     */
    TEXT_EDITING_CAPTION: [
        EDITING_TOOL_CONFIG.TEXT_STYLES,
        EDITING_TOOL_CONFIG.TEXT_BOLD,
        EDITING_TOOL_CONFIG.TEXT_ITALIC,
        EDITING_TOOL_CONFIG.TEXT_STRIKETHROUGH,
        EDITING_TOOL_CONFIG.TEXT_HYPERLINK,
        EDITING_TOOL_CONFIG.TEXT_CENTERED,
    ],
} as const;

export const TOOLBAR_TOOLS = {
    ...SELECTED_TOOL_CONFIG,
    ...EDITING_TOOL_CONFIG,
    ...META_TOOL_CONFIG,
} as const;
