/**
 * The measurements store is one animation-frame behind actual element measurements due to
 * the debouncing / bulking of the MEASUREMENT_SET events.
 * Sometimes we need the immediately up to date measurements in order to avoid a render using
 * old measurements (e.g. lines inside columns).
 *
 * Because these instances will be already following a React render cycle we can use a singleton,
 * rather than adding it to the state, as we don't want to force re-renders here, just use the most
 * up to date information when we are re-rendering.
 */
export const syncedMeasurements = {};

export const setSyncedMeasurement = (id, measurements) => {
    syncedMeasurements[id] = measurements;
};

export const removeSyncedMeasurement = (id) => {
    syncedMeasurements[id] = null;
};
