// Utils
import isOnlyChecklist, { editorIsOnlyCheckedChecklist } from '../utils/isOnlyChecklist';

// Constants
import { HANDLER_RETURN_VALUES } from '../../../draftjsConstants';

import { ElementType } from '../../../../../../common/elements/elementTypes';

export default (editorState, { getProps }) => {
    if (!isOnlyChecklist(editorState)) return HANDLER_RETURN_VALUES.notHandled;

    const { updateElementType, preventSaveOnUnmount } = getProps();

    const isComplete = editorIsOnlyCheckedChecklist(editorState);

    // Ensure the editor component doesn't force an update event now that we're
    // changing the component type
    preventSaveOnUnmount();

    const data = { content: { isComplete } };
    updateElementType({ elementType: ElementType.TASK_TYPE, data });

    return HANDLER_RETURN_VALUES.notHandled;
};
