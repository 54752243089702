// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ToolbarPopup from '../components/toolbarPopup/ToolbarPopup';
import GroupedToolList from './GroupedToolList';

// Styles
import './MorePopup.scss';

class MorePopupContents extends React.Component {
    componentWillUnmount() {
        this.props.closePopup();
    }

    render() {
        const { collapsedTools } = this.props;
        return <GroupedToolList {...this.props} className="MorePopupContents" tools={collapsedTools} />;
    }
}

const MorePopup = (props) => (
    <ToolbarPopup
        ignoredClicksSelector={`#more-tool-${props.toolsId}`}
        popupId={props.popupId}
        buttonSelector={`#more-tool-${props.toolsId} .Icon.toolbar-more`}
        className={classNames('MorePopup', `toolbar-size-${props.gridSizeName}`, props.className)}
        arrowColor="var(--ws-canvas-background)"
        closePopup={props.closePopup}
    >
        <MorePopupContents {...props} />
    </ToolbarPopup>
);

MorePopupContents.propTypes = MorePopup.propTypes = {
    className: PropTypes.string,
    popupId: PropTypes.string.isRequired,
    toolsId: PropTypes.string.isRequired,
    collapsedTools: PropTypes.array.isRequired,
    toolsConfig: PropTypes.object.isRequired,
    closePopup: PropTypes.func.isRequired,
    gridSizeName: PropTypes.string.isRequired,
};

export default MorePopup;
