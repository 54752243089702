const requestIdleCallbackPolyfill = (
    cb: (deadline: { didTimeout: boolean; timeRemaining: () => number }) => void,
    options?: {
        timeout?: number;
    },
) => {
    const start = Date.now();

    return setTimeout(() => {
        cb({
            didTimeout: false,
            timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
        });
    }, 1);
};

const cancelIdleCallbackPolyfill = (id: number) => {
    clearTimeout(id);
};

export const cancelIC = window.cancelIdleCallback || cancelIdleCallbackPolyfill;

export default window.requestIdleCallback || requestIdleCallbackPolyfill;
