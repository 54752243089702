let cachedIsGrammarlyInstalled: boolean | null = null;

/**
 * Looks for the Grammarly extension in the DOM.
 */
export const isGrammarlyInstalled = (): boolean => {
    if (cachedIsGrammarlyInstalled !== null) return cachedIsGrammarlyInstalled;

    cachedIsGrammarlyInstalled = global.document.querySelector('grammarly-desktop-integration') !== null;

    return cachedIsGrammarlyInstalled;
};

/**
 * Currently the only error that we're aware of that Grammarly causes is an IndexSizeError.
 * We'll start restrictive and expand if we find more cases.
 */
export const isGrammarlyError = (error: Error): boolean => {
    return error?.name === 'IndexSizeError' && isGrammarlyInstalled();
};
