declare global {
    interface Navigator {
        standalone?: boolean;
    }
}

export const getIsElectronMac = () => process.env.APPLICATION_MODE === 'electron';
export const isIphonePwa = () => !!global.navigator?.standalone;
export const isDesktopPwa = () =>
    global.matchMedia &&
    (global.matchMedia('(display-mode: standalone)').matches ||
        global.matchMedia('(display-mode: window-controls-overlay)').matches);
export const isInIFrame = () => !!global.window && global.window.self !== global.window.top;
