// Utils
import { containsPoint } from './rectComparisonUtils';
import { translate } from './rectTransformationUtils';

export const anyRectsContainsPoint = (rects, point) => {
    if (!rects || !point) return false;
    return rects.some((rect) => containsPoint(rect, point));
};

export const translateRects = (offset, rects) => {
    if (!rects) return null;
    if (!offset) return rects;
    return rects.map((rect) => translate(offset, rect));
};
