// Lib
import { defer } from 'lodash';

// Utils
import { getElement } from '../../element/selectors/elementSelector';
import { analyticsEvent } from '../../analytics';
import doesEditorJsonHaveText from '../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';
import { getCaption, getElementType, getShowCaption } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { startEditingElement, finishEditingElement } from '../../element/selection/selectionActions';
import { updateElement, updateMultipleElements } from '../../element/actions/elementActions';

// Selectors
import { getSelectedElementIds, getSelectedRenderedElements } from '../../element/selection/selectedElementsSelector';

// Constants
import { CAPTION_EDITOR_KEY } from './captionConstants';

const changeCaption =
    (desiredShowCaptionState) =>
    ({ elementId }) =>
    (dispatch, getState) => {
        const state = getState();
        const element = getElement(state, { elementId });

        // If already hiding the caption then no need to fire event
        if (getShowCaption(element) === desiredShowCaptionState) return;

        dispatch(
            updateElement({
                id: elementId,
                changes: { showCaption: desiredShowCaptionState },
            }),
        );
    };

export const setShowCaption = changeCaption(true);
export const setHideCaption = changeCaption(false);

export const toggleCaption =
    ({ editOnToggle = true } = {}) =>
    (dispatch, getState) => {
        const state = getState();

        const selectedElementIds = getSelectedElementIds(state);
        const selectedElements = getSelectedRenderedElements(state);
        const firstElement = selectedElements.first();

        const showingCaption = getShowCaption(firstElement);

        const isNowShowingCaption = !showingCaption;

        const addedRemoved = isNowShowingCaption ? 'added' : 'removed';
        analyticsEvent(`${addedRemoved}-${getElementType(firstElement)}-caption`);

        dispatch(
            updateMultipleElements({
                updates: selectedElementIds.toJS().map((id) => ({
                    id,
                    changes: {
                        showCaption: isNowShowingCaption,
                    },
                })),
            }),
        );

        const shouldEdit =
            editOnToggle && selectedElementIds.size === 1 && !doesEditorJsonHaveText(getCaption(firstElement));

        if (shouldEdit) {
            const editActions = () => {
                if (isNowShowingCaption) {
                    const elementId = selectedElementIds.first();

                    dispatch(
                        startEditingElement({
                            id: elementId,
                            editorId: `${elementId}-${CAPTION_EDITOR_KEY}`,
                            editorKey: CAPTION_EDITOR_KEY,
                        }),
                    );
                } else {
                    dispatch(finishEditingElement(selectedElementIds.first()));
                }
            };

            // Need to use a timeout so that the hiding & showing of the caption occurs AFTER the simpleTextEditor
            // performs its content updates.  Otherwise undo / redo would not be seen by the user.
            defer(editActions);
        }

        return isNowShowingCaption;
    };
