// Lib
import { isEqual } from 'lodash/fp';
import { sum } from 'lodash';

// Actions
import { createAndSelectElement, updateElement } from '../actions/elementActions';

// Selectors
import { getCurrentCellSelections } from './tableSelector';
import { userLanguagePreferenceSelector } from '../../user/currentUserSelector';

// Utils
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import {
    deselectCellSelectionSubset,
    isSelectionSubsetOf,
    reorderCellSelection,
} from './utils/tableCellSelectionUtils';
import { asObject } from '../../../common/utils/immutableHelper';
import { convertTableElementChangesToReduxFormat } from './utils/tableDataUtils';

// Constants
import * as SELECTION_ACTION_TYPES from '../../../common/elements/selectionConstants';
import { ELEMENT_UPDATE_TYPE } from '../../../common/elements/elementConstants';
import { ElementType } from '../../../common/elements/elementTypes';

export const createTable = (args) => (dispatch) => {
    const { location, content, currentBoardId, transactionId = getNewTransactionId(), creationSource } = args;

    return dispatch(
        createAndSelectElement({
            elementType: ElementType.TABLE_TYPE,
            location,
            content,
            currentBoardId,
            transactionId,
            creationSource,
        }),
    );
};

/**
 * Update the meta information for the selection, like cell selection for tables
 */
export const updateCellSelectionMeta =
    (id, cellSelection, selectionLayerLevel = 0, transactionId) =>
    (dispatch, getState) => {
        const state = getState();

        if (!cellSelection) {
            return dispatch({
                type: SELECTION_ACTION_TYPES.TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
                id,
                cellSelections: null,
            });
        }

        const prevCellSelections = asObject(getCurrentCellSelections(state, { elementId: id })) || [];
        let newCellSelections = [];

        if (selectionLayerLevel === 0) {
            newCellSelections[selectionLayerLevel] = cellSelection;
        } else {
            const orderedCellSelection = reorderCellSelection(cellSelection);

            let shouldAddToCellSelections = true;
            prevCellSelections.forEach((prevCellSelection) => {
                const orderedPrevCellSelection = reorderCellSelection(prevCellSelection);

                if (isEqual(orderedCellSelection, orderedPrevCellSelection)) {
                    shouldAddToCellSelections = false;
                    return;
                }

                if (isSelectionSubsetOf(orderedCellSelection, orderedPrevCellSelection)) {
                    newCellSelections.push(
                        ...deselectCellSelectionSubset(orderedPrevCellSelection, orderedCellSelection),
                    );
                    shouldAddToCellSelections = false;
                    return;
                }

                newCellSelections.push(prevCellSelection);
            });

            if (shouldAddToCellSelections) {
                newCellSelections[selectionLayerLevel] = cellSelection;
            }
        }

        return dispatch({
            type: SELECTION_ACTION_TYPES.TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
            id,
            cellSelections: newCellSelections,
            transactionId,
        });
    };

export const updateTableElement =
    ({ id, changes, batchUndoActions, batchRedoActions, transactionId, silent }) =>
    (dispatch, getState) => {
        const state = getState();
        const locale = userLanguagePreferenceSelector(state);
        const changesToDispatch = convertTableElementChangesToReduxFormat(changes, locale);

        // if there is a change to the col widths, update the table width as well
        if (changesToDispatch?.tableContent?.colWidthsGU && !changesToDispatch.width) {
            changesToDispatch.width = Math.round(sum(changesToDispatch.tableContent.colWidthsGU));
        }
        dispatch(
            updateElement({
                id,
                updateType: ELEMENT_UPDATE_TYPE.TABLE,
                changes: changesToDispatch,
                batchUndoActions,
                batchRedoActions,
                transactionId,
                silent,
            }),
        );
    };
