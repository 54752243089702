// Lib
import { difference, get, intersection, memoize } from 'lodash/fp';

// Types
import { ElementToolConfigMap } from '../components/elementTools/elementToolListConfig';

// Constants
import { GRID } from '../../../utils/grid/gridConstants';

const getMoreToolHeight = memoize((gridSizeName) => {
    switch (gridSizeName) {
        case GRID.SMALL.name:
            return 32;
        case GRID.MEDIUM.name:
            return 37;
        case GRID.LARGE.name:
        default:
            return 41;
    }
});

const getToolPadding = memoize((gridSizeName) => {
    switch (gridSizeName) {
        case GRID.SMALL.name:
            return 13;
        case GRID.MEDIUM.name:
            return 18;
        case GRID.LARGE.name:
        default:
            return 18;
    }
});

const getToolHeight = (toolName: string, gridSizeName: string, toolsConfig: ElementToolConfigMap) => {
    const toolHeight: number =
        get([toolName, 'height', gridSizeName], toolsConfig) ||
        get(['default', 'height', gridSizeName], toolsConfig) ||
        0;

    const paddingHeight = getToolPadding(gridSizeName);

    return toolHeight + paddingHeight;
};

const calculateAvailableToolsCollapsibleOrder = (availableTools: string[], collapseOrder: string[] = []) => {
    const highestPriorityTools = difference(availableTools, collapseOrder);
    const lowestPriorityTools = intersection(availableTools, collapseOrder).reverse();
    return [...highestPriorityTools, ...lowestPriorityTools];
};

interface GetVisibleToolsParams {
    availableTools: string[];
    collapseOrder: string[];
    toolsConfig: ElementToolConfigMap;
    gridSizeName: string;
    hasConfiguredMoreTool: boolean;
    availableHeight?: number;
    unavailableHeight?: number;
}

interface VisibleToolsAcc {
    visibleTools: string[];
    totalHeight: number;
    limited: boolean;
}

export const getVisibleTools = ({
    availableTools,
    collapseOrder,
    toolsConfig,
    gridSizeName,
    hasConfiguredMoreTool,
    availableHeight = 0,
    unavailableHeight = 0,
}: GetVisibleToolsParams): string[] => {
    const priorityOrderedAvailableTools = calculateAvailableToolsCollapsibleOrder(availableTools, collapseOrder);

    const appendVisibleTool = (visibleToolsAcc: VisibleToolsAcc, toolName: string) => {
        const { totalHeight, visibleTools } = visibleToolsAcc;

        const newTotalHeight = totalHeight + getToolHeight(toolName, gridSizeName, toolsConfig);

        return {
            ...visibleToolsAcc,
            visibleTools: [...visibleTools, toolName],
            totalHeight: newTotalHeight,
        };
    };

    const moreToolHeight = hasConfiguredMoreTool ? 0 : getMoreToolHeight(gridSizeName);
    const totalAvailableHeight = availableHeight - unavailableHeight - moreToolHeight;

    const { visibleTools } = priorityOrderedAvailableTools.reduce(
        (visibleToolsAcc, toolName) => {
            const { totalHeight, limited } = visibleToolsAcc;

            const toolConfig = toolsConfig[toolName] || toolsConfig.default;

            // If the tool should always show, then add it to the visible tools list
            const alwaysVisible = toolConfig.alwaysVisiblePredicate?.(toolsConfig);
            if (alwaysVisible) return appendVisibleTool(visibleToolsAcc, toolName);

            // If we're already limited we can't add any more tools
            if (limited) return visibleToolsAcc;

            // If a tool should always show in the collapsed menu, then don't add it to the visible tools list
            if (toolConfig.alwaysCollapsed) return visibleToolsAcc;

            const toolHeight = getToolHeight(toolName, gridSizeName, toolsConfig);

            const newTotalHeight = totalHeight + toolHeight;
            if (newTotalHeight < totalAvailableHeight) {
                return appendVisibleTool(visibleToolsAcc, toolName);
            }

            return {
                ...visibleToolsAcc,
                limited: true,
            };
        },
        { visibleTools: [], totalHeight: 0, limited: false } as VisibleToolsAcc,
    );

    return visibleTools;
};
