import { useEditorDispatch } from '../../../../components/editor/store/components/EditorStoreProvider';
import {
    getEditorIdThunk,
    getEditorStateThunk,
    saveEditorSelection,
} from '../../../../components/editor/store/components/editorStoreThunks';
import { SelectionState } from 'draft-js';
import {
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

/**
 * Get the function to return the editor to focus. Works for both draftJS and tiptap editors.
 */
const useMobileTextStyleEditorHandlers = (): {
    focusEditor: () => void;
    isTiptapEditor: boolean;
} => {
    const editorDispatch = useEditorDispatch();
    // @ts-ignore Can't deal with thunks
    const dispatchGetEditorState = () => editorDispatch(getEditorStateThunk());
    // @ts-ignore Can't deal with thunks
    const dispatchGetEditorId = () => editorDispatch(getEditorIdThunk());
    const dispatchForceEditorSelection = (editorId: string, selection: SelectionState) =>
        editorDispatch(saveEditorSelection(editorId, selection));

    const isTiptapEditor = useTiptapActiveEditorSelector((activeEditor) => !!activeEditor);

    const focusDraftEditor = () => {
        const editorState = dispatchGetEditorState();
        const currentSelection = editorState.getSelection();

        const editorId = dispatchGetEditorId() as unknown as string;
        dispatchForceEditorSelection(editorId, currentSelection);
    };

    const focusTiptapEditor = useActiveTiptapEditorCallback((activeEditor) => {
        activeEditor?.commands.focus();
    });

    const focusEditor = isTiptapEditor ? focusTiptapEditor : focusDraftEditor;

    return {
        focusEditor,
        isTiptapEditor,
    };
};

export default useMobileTextStyleEditorHandlers;
