// Utils
import { chooseSingular, cleanEmailList, listToEnglish } from '../../../utils/stringUtil';
import emailValidator from './emailValidator';

// Constants
import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

export default (fieldValue, validationConfig = {}) => {
    if (!fieldValue) return null;

    const emails = cleanEmailList(fieldValue);

    if (emails.length <= 0) return null;

    const erroredEmails = [];

    for (const email of emails) {
        const validationError = emailValidator(email, validationConfig);

        if (validationError) {
            erroredEmails.push(email);
        }
    }

    if (erroredEmails.length > 0) {
        return {
            name: FAILURE_NAMES.email,
            type: FAILURE_TYPES.ERROR,
            debounce: true,
            message:
                `${listToEnglish(erroredEmails)} ` +
                `${chooseSingular(erroredEmails.length, 'is an invalid email', 'are invalid emails')}`,
            value: erroredEmails,
            hideBeforeSubmit: true,
        };
    }

    return null;
};
