// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '../../../../../components/icons/Icon';
import AnchorButton from '../../../../../components/buttons/AnchorButton';
import TimeInput from '../../../../../components/datetime/TimeInput';
import StyledButton from '../../../../../components/buttons/StyledButton';

// Styles
import './DueDateTimePicker.scss';

const UnsetDueDateTimePicker = (props) => (
    <div className="DueDateTimePicker unset">
        <StyledButton className="enable-time secondary" tabIndex={0} onClick={() => props.setHasDueDateTime(true)}>
            <Icon name="clock" />
            <span className="time-text">{props.unsetTimeMessage || 'Add due time…'}</span>
        </StyledButton>
    </div>
);

const DueDateTimePicker = (props) => {
    const { hasDueDateTime, dueDate, setDueDate } = props;

    if (!hasDueDateTime) return <UnsetDueDateTimePicker {...props} />;

    return (
        <div className="DueDateTimePicker set">
            <Icon name="clock" />
            <div className="inputs">
                <TimeInput value={dueDate} onChange={setDueDate} />
                <AnchorButton className="secondary" tabIndex={0} onClick={() => props.setHasDueDateTime(false)}>
                    Clear
                </AnchorButton>
            </div>
        </div>
    );
};

UnsetDueDateTimePicker.propTypes = DueDateTimePicker.propTypes = {
    hasDueDateTime: PropTypes.bool,
    setHasDueDateTime: PropTypes.func,
    dueDate: PropTypes.object,
    setDueDate: PropTypes.func,
};

export default DueDateTimePicker;
