// Lib
import { reduce } from 'lodash';

// Utils
import {
    matchHeading,
    matchOrderedList,
    matchList,
    matchBlockquote,
    matchLargeHeading,
} from './markdownPatternMatcher';

// Constants
import { STYLE_COMMANDS } from '../../../richText/richTextConstants';

const BLOCK_TYPE_MATCH_FUNCTIONS_MAP = {
    [STYLE_COMMANDS.HEADING]: matchHeading,
    [STYLE_COMMANDS.LARGE_HEADING]: matchLargeHeading,
    [STYLE_COMMANDS.ORDERED_LIST]: matchOrderedList,
    [STYLE_COMMANDS.LIST]: matchList,
    [STYLE_COMMANDS.BLOCKQUOTE]: matchBlockquote,
};

export default (line) =>
    reduce(
        BLOCK_TYPE_MATCH_FUNCTIONS_MAP,
        (acc, fn, blockType) => {
            if (acc) return acc;
            return fn(line) ? blockType : null;
        },
        null,
    );
