// Lib
import { NativeTypes } from 'react-dnd-html5-backend';

// Utils
import {
    validateElementTypeEditPermission,
    validateElementTypeSavePermission,
} from '../../../../common/elements/utils/elementTypePermissionsUtils';

// Constants
import { ElementType } from '../../../../common/elements/elementTypes';

const hasValidDropPermissions = (props, elementType) => {
    const { permissions, elementPermissions, linkedElementPermissions } = props;

    // If we have linked element permissions, then that's the destination for this drop,
    // so ensure that the destination board will accept the element
    const targetElementPermissions =
        linkedElementPermissions !== undefined ? linkedElementPermissions : elementPermissions;

    return (
        // The current board must have edit permissions
        validateElementTypeEditPermission(permissions, elementType) &&
        // The target element must also have edit permissions
        validateElementTypeEditPermission(targetElementPermissions, elementType) &&
        // The current board must have the same save permissions as the target element (demo boards)
        validateElementTypeSavePermission(permissions, elementType) ===
            validateElementTypeSavePermission(targetElementPermissions, elementType)
    );
};

export default ({ props, monitor }) => {
    const itemType = monitor.getItemType();

    if (itemType === NativeTypes.FILE) return hasValidDropPermissions(props, ElementType.FILE_TYPE);
    if (itemType === NativeTypes.TEXT) return hasValidDropPermissions(props, ElementType.CARD_TYPE);
    if (itemType === NativeTypes.URL) return hasValidDropPermissions(props, ElementType.LINK_TYPE);

    return monitor.getItem().draggedElements?.every((element) => hasValidDropPermissions(props, element));
};
