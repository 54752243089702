// Lib
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { stopDefaultAndPropagation, stopPropagationOnly } from '../../../../utils/domUtil';

// Components
import Button from '../../../../components/buttons/Button';
import Icon from '../../../../components/icons/Icon';
import PopupPanelMouseManager from '../../../../components/popupPanel/PopupPanelMouseManager';
import FloatingPanel from '../../../../components/tooltips/FloatingPanel';

// Hooks
import useTouchBlockedPointerEvent from './useTouchBlockedPointerEvent';

// Constants
import { STROKE_SIZES } from '../drawingEditorConstants';

// Styles
import './DrawingEditorStrokeSizeTool.scss';

const getIconName = (strokeSize) => {
    switch (strokeSize) {
        case STROKE_SIZES.SMALL:
            return 'drawing-editor-stroke-1';
        case STROKE_SIZES.LARGE:
            return 'drawing-editor-stroke-3';
        case STROKE_SIZES.EXTRA_LARGE:
            return 'drawing-editor-stroke-4';
        case STROKE_SIZES.MEDIUM:
        default:
            return 'drawing-editor-stroke-2';
    }
};

const StrokeSizeIcon = ({ strokeSize }) => <Icon name={getIconName(strokeSize)} />;

StrokeSizeIcon.propTypes = {
    strokeSize: PropTypes.number,
};

const StrokeSizeButton = ({ disabled, strokeSize, className, onPointerUp, active }) => (
    <Button
        disabled={disabled}
        className={classNames('DrawingEditorStrokeSizeButton', className, { active })}
        onPointerUp={(event) => onPointerUp && onPointerUp(event, strokeSize)}
        onDoubleClick={stopDefaultAndPropagation}
    >
        <StrokeSizeIcon strokeSize={strokeSize} />
    </Button>
);

StrokeSizeButton.propTypes = {
    strokeSize: PropTypes.number,
    className: PropTypes.string,
    onPointerUp: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
};

const DrawingEditorStrokeSizeTool = ({
    disabled,
    isPenMode,
    keepPopupOpen,
    strokeSize,
    setStrokeSize,
    strokePopupOpen,
    setStrokePopupOpen,
}) => {
    const closePopup = () => setStrokePopupOpen(false);
    const togglePopup = useCallback(() => setStrokePopupOpen(!strokePopupOpen), [setStrokePopupOpen, strokePopupOpen]);
    const onButtonPress = useTouchBlockedPointerEvent(isPenMode, togglePopup);

    const setStrokeSizeAndClose = (event, size) => {
        setStrokeSize(size);
        !keepPopupOpen && closePopup();
    };

    return (
        <div className="DrawingEditorStrokeSizeTool" onDoubleClick={stopPropagationOnly}>
            <StrokeSizeButton
                disabled={disabled}
                className="popup-button"
                strokeSize={strokeSize}
                onPointerUp={!disabled ? onButtonPress : null}
            />

            {strokePopupOpen && (
                <PopupPanelMouseManager
                    closePopup={closePopup}
                    visible
                    ignoredClicksSelector=".DrawingEditorStrokeSizeButton,.FloatingPanel"
                >
                    <FloatingPanel
                        className="DrawingEditorStrokeSizePopup light color-popup"
                        selector=".DrawingEditorStrokeSizeButton.popup-button"
                    >
                        <div className="stroke-sizes">
                            <StrokeSizeButton
                                className="square"
                                active={STROKE_SIZES.SMALL === strokeSize}
                                strokeSize={STROKE_SIZES.SMALL}
                                onPointerUp={setStrokeSizeAndClose}
                            />
                            <StrokeSizeButton
                                className="square"
                                active={STROKE_SIZES.MEDIUM === strokeSize}
                                strokeSize={STROKE_SIZES.MEDIUM}
                                onPointerUp={setStrokeSizeAndClose}
                            />
                            <StrokeSizeButton
                                className="square"
                                active={STROKE_SIZES.LARGE === strokeSize}
                                strokeSize={STROKE_SIZES.LARGE}
                                onPointerUp={setStrokeSizeAndClose}
                            />
                            <StrokeSizeButton
                                className="square"
                                active={STROKE_SIZES.EXTRA_LARGE === strokeSize}
                                strokeSize={STROKE_SIZES.EXTRA_LARGE}
                                onPointerUp={setStrokeSizeAndClose}
                            />
                        </div>
                    </FloatingPanel>
                </PopupPanelMouseManager>
            )}
        </div>
    );
};

DrawingEditorStrokeSizeTool.propTypes = {
    strokeSize: PropTypes.number,
    keepPopupOpen: PropTypes.bool,
    strokePopupOpen: PropTypes.bool,
    isPenMode: PropTypes.bool,
    disabled: PropTypes.bool,
    setStrokeSize: PropTypes.func,
    setStrokePopupOpen: PropTypes.func,
};

export default DrawingEditorStrokeSizeTool;
