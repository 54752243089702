// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withHandlers } from '../../../../../node_module_clones/recompose';

import Button from '../../../buttons/AnchorButton';

// Styles
import './PopupModeMenuButton.scss';

const enhance = withHandlers({
    handleButtonClick:
        ({ setMode, mode }) =>
        (event) => {
            event.preventDefault();
            if (mode.disabled) return;

            setMode({ mode: mode.slug });
        },
});

const PopupModeMenuButton = ({ mode, popupMode, handleButtonClick }) => (
    <Button
        onClickFn={handleButtonClick}
        disabled={mode.disabled}
        className={classNames('PopupModeMenuButton', { active: mode.slug === popupMode, disabled: mode.disabled })}
    >
        <span className="title">{mode.title}</span>
    </Button>
);

PopupModeMenuButton.propTypes = {
    mode: PropTypes.object,
    popupMode: PropTypes.string,
    handleButtonClick: PropTypes.func,
};

export default enhance(PopupModeMenuButton);
