// Lib
import { capitalize, head, trim } from 'lodash/fp';

// Utils
import { prop } from '../../../../../common/utils/immutableHelper';
import { getShareCount, getUserId } from '../../../../../common/users/utils/userPropertyUtils';
import { getEmail, getFamilyName, getGivenName, getImage, getNameProp } from '../../../../../common/users/userHelper';
import countOccurrences from '../../../../utils/milanoteLodash/countOccurrences';

export const getNameAbbreviations = (user) => {
    const givenName = trim(capitalize(getGivenName(user)));
    const familyName = trim(capitalize(getFamilyName(user)));

    if (!givenName) return [];

    if (!familyName) return [givenName];

    const familyNameInitial = head(familyName);

    if (familyName === familyNameInitial) {
        return [givenName, `${givenName} ${familyNameInitial}`];
    }

    return [givenName, `${givenName} ${familyNameInitial}`, `${givenName} ${familyName}`];
};

/**
 * In order to allow the mentions popup to work without rewriting it ourselves we have to ensure the
 * "mention" object it receives has a unique "id" property and that the "name" property is the string we
 * want to display.
 */
export const convertUserToMention =
    (possibleUserNames = [], availableSuggestions, sharedBoardUserIds = []) =>
    (user) => {
        const mentionedUserNameAbbreviations = getNameAbbreviations(user);

        const { bestName } = mentionedUserNameAbbreviations.reduce(
            (acc, name) => {
                const { matchCount } = acc;

                // If there's only one match use that as the current name
                if (matchCount <= 1) return acc;

                const thisCount = countOccurrences(name, possibleUserNames);

                if (thisCount < matchCount) {
                    return {
                        bestName: name,
                        matchCount: thisCount,
                    };
                }

                return acc;
            },
            { bestName: null, matchCount: possibleUserNames.length },
        );

        const userId = getUserId(user);

        const disabled = !prop(userId, availableSuggestions);

        return {
            id: getUserId(user),
            name: bestName || getEmail(user),
            _name: getNameProp(user),
            email: getEmail(user),
            image: getImage(user),
            shareCount: getShareCount(user),
            disabled,
            sharingCurrentBoard: sharedBoardUserIds.includes(userId),
        };
    };

/**
 * In order to use the standard name accessor methods and the existing <UserAvatar> component we need to
 * convert a mention back into the expected user form.
 */
export const convertMentionToUser = (mention) => ({
    ...mention,
    _id: mention.id,
    name: mention._name,
});
