// Utils
import { getDisplayMode } from './elementPropertyUtils';
import { isBoardLike, isDocument } from './elementTypeUtils';

// Constants
import { ELEMENT_DISPLAY_MODES } from '../elementDisplayModeConstants';

const isDisplayMode = (displayMode) => (element) => getDisplayMode(element) === displayMode;

export const isIconViewMode = isDisplayMode(ELEMENT_DISPLAY_MODES.ICON_VIEW);

export const isIconViewLike = (element) => isBoardLike(element) || isIconViewMode(element);

// Document-specific helpers
export const isDocumentInIconView = (element) => isDocument(element) && isIconViewMode(element);
