import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorToolSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={18}
        viewBox="0 0 12 18"
        className="Icon drawing-editor-tool-select"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M.787.104a1.31 1.31 0 0 1 1.286.15l.107.087 8.942 8.187c.374.342.511.87.35 1.35-.146.433-.51.753-.95.85l-.135.022-2.261.256 1.953 4.402a1.28 1.28 0 0 1-.452 1.576l-.112.068-.113.055-1.852.788c-.612.261-1.32.02-1.642-.547l-.06-.117-1.975-4.453-1.768 1.387c-.314.247-.72.333-1.1.246l-.14-.041-.123-.05a1.29 1.29 0 0 1-.735-1.028L0 13.157V1.286C0 .77.31.306.787.104zm.718 1.66v10.958l2.96-2.321 2.676 6.032 1.469-.624-2.692-6.068 3.827-.434-8.24-7.543z"
        />
    </svg>
);
const Memo = memo(DrawingEditorToolSelectIcon);
export default Memo;
