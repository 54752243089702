import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationMentionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-mention"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#C1D0FC" />
            <g transform="translate(1 1)">
                <circle cx={7} cy={7} r={7} fill="#EAEFFE" />
                <path
                    fill="#2F63F7"
                    d="M7.19 12c1.014 0 2.09-.25 2.584-.502l-.335-.98c-.375.139-1.283.394-2.183.394-2.612 0-4.025-1.382-4.025-3.883 0-2.331 1.35-3.956 3.927-3.956 2.103 0 3.67 1.166 3.666 3.358.004 1.594-.249 2.312-.88 2.312-.336 0-.533-.263-.537-.645V4.424h-1.1v.432H8.25c-.126-.34-.884-.652-1.74-.536-1.101.142-2.159 1.026-2.159 2.678 0 1.702.947 2.702 2.234 2.76.895.042 1.59-.348 1.775-.807h.048c.095.579.615.895 1.413.853C11.518 9.742 12.004 8.26 12 6.496 12.004 3.961 10.26 2 7.185 2 3.91 2 2.004 3.972 2 7.044 2.004 10.19 3.878 12 7.19 12zm-.277-3.35c-1.03 0-1.377-.818-1.381-1.698.004-.88.505-1.525 1.389-1.525.983 0 1.346.471 1.35 1.52.012 1.128-.36 1.703-1.358 1.703z"
                />
            </g>
        </g>
    </svg>
);
const Memo = memo(NotificationMentionIcon);
export default Memo;
