// Geometry
import * as pointLib from '../point';

// Utils
import { getLeft, getWidth, getTop, getHeight, getRight, getBottom } from '.';

// Constants
import { RECT_CORNERS } from './rectConstants';

const getHorizontalCenter = (rect) => getLeft(rect) + getWidth(rect) / 2;
const getVerticalCenter = (rect) => getTop(rect) + getHeight(rect) / 2;

export const getTopLeft = (rect) => pointLib.getPoint(getLeft(rect), getTop(rect));
export const getTopCenter = (rect) => pointLib.getPoint(getHorizontalCenter(rect), getTop(rect));
export const getTopRight = (rect) => pointLib.getPoint(getRight(rect), getTop(rect));
export const getRightCenter = (rect) => pointLib.getPoint(getRight(rect), getVerticalCenter(rect));
export const getBottomRight = (rect) => pointLib.getPoint(getRight(rect), getBottom(rect));
export const getBottomCenter = (rect) => pointLib.getPoint(getHorizontalCenter(rect), getBottom(rect));
export const getBottomLeft = (rect) => pointLib.getPoint(getLeft(rect), getBottom(rect));
export const getLeftCenter = (rect) => pointLib.getPoint(getLeft(rect), getVerticalCenter(rect));
export const getOrigin = getTopLeft;

export const getCornerPoint = (corner, rect) => {
    switch (corner) {
        case RECT_CORNERS.TOP_LEFT:
            return getTopLeft(rect);
        case RECT_CORNERS.TOP_RIGHT:
            return getTopRight(rect);
        case RECT_CORNERS.BOTTOM_RIGHT:
            return getBottomRight(rect);
        default:
            return getBottomLeft(rect);
    }
};

export const getRectCenterPoint = (rect) => pointLib.getPoint(getHorizontalCenter(rect), getVerticalCenter(rect));
