// Lib
import { isEmpty, trim } from 'lodash';

// Utils
import { prop, propIn, length } from '../../immutableHelper';

// Could make this more strict.  E.g. Even with multiple blocks, if they're all just spaces then it's false
export default (rawState) => {
    const blocks = prop('blocks', rawState);

    if (!blocks) return false;

    return length(blocks) > 1 || !isEmpty(trim(propIn([0, 'text'], blocks)));
};
