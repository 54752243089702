// Utils
import { buildPersistenceLayerActionMessage } from '../utils/persistenceLayerMessageBuilders';

// Selectors
import { getElements } from '../../../element/selectors/elementsSelector';

// Actions
import { buildCacheOperationFromElementsAction } from '../../../cache/utils/buildCacheOperationFromElementAction';

// Constants
import { CURRENT_BOARD_ID_SET } from '../../../reducers/currentBoardId/currentBoardIdConstants';

// Types
import { AnyAction } from 'redux';
import Immutable from 'immutable';
import { PersistenceLayerMessageAction } from '../utils/persistenceLayerCommunicationTypes';

/**
 * Builds a post message that will be treated as an action by the persistence layer.
 */
export default (
    action: AnyAction,
    preUpdateState: Immutable.Map<string, unknown>,
    postUpdateState: Immutable.Map<string, unknown>,
): PersistenceLayerMessageAction | null => {
    if (!action) return null;

    const preUpdateElementsState = getElements(preUpdateState);
    const postUpdateElementsState = getElements(postUpdateState);
    const cacheOperationAction = buildCacheOperationFromElementsAction(
        action,
        postUpdateElementsState,
        preUpdateElementsState,
    );

    if (cacheOperationAction) return buildPersistenceLayerActionMessage(cacheOperationAction);

    // Handle other actions here
    switch (action.type) {
        case CURRENT_BOARD_ID_SET:
            return buildPersistenceLayerActionMessage(action);
        default:
            return null;
    }
};
