// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import friendlyTimeFormatter from '../../../common/utils/datetime/friendlyTimeFormatter';
import { getTimestamp, TIMES } from '../../../common/utils/timeUtil';

const getUpdateInterval = (timestamp, currentTime) => {
    const millisPassed = currentTime - timestamp;

    // No point creating a day long interval, so just do nothing
    if (millisPassed > TIMES.DAY) return null;

    if (millisPassed > TIMES.HOUR) return TIMES.HOUR;

    return TIMES.MINUTE;
};

class FriendlyTimestamp extends React.Component {
    constructor(props) {
        super(props);

        this.intervalId = null;

        this.state = {
            currentTime: getTimestamp(),
        };
    }

    componentDidMount() {
        this.checkInterval();
    }

    componentWillUnmount() {
        this.intervalId && clearInterval(this.intervalId);
    }

    checkInterval = () => {
        const newInterval = getUpdateInterval(this.props.timestamp, this.state.currentTime);

        if (newInterval !== this.currentInterval) {
            // Clear the old interval
            this.intervalId && clearInterval(this.intervalId);

            // If there's no new interval, then simply finish
            if (!newInterval) return;

            // Otherwise, set a new interval
            this.currentInterval = newInterval;
            this.intervalId = setInterval(this.updateCurrentTime, this.currentInterval);
        }
    };

    updateCurrentTime = () => {
        this.setState({ currentTime: getTimestamp() });
        this.checkInterval();
    };

    render() {
        const { currentTime } = this.state;
        const { title, timestamp, showSuffix, lessThanMinuteMessage } = this.props;

        return (
            <div className="FriendlyTimestamp" title={title}>
                {friendlyTimeFormatter({
                    timestamp,
                    fromTimestamp: currentTime,
                    showSuffix,
                    lessThanMinuteMessage,
                })}
            </div>
        );
    }
}

FriendlyTimestamp.propTypes = {
    timestamp: PropTypes.number,
    showSuffix: PropTypes.bool,
    lessThanMinuteMessage: PropTypes.string,
    title: PropTypes.string,
};

export default FriendlyTimestamp;
