// Lib
import { createStructuredSelector, createSelector } from 'reselect';

// Util
import { createShallowSelector } from '../../../../utils/milanoteReselect/milanoteReselect';
import { getMany } from '../../../../../common/utils/immutableHelper';

// Selector
import { boardVisibleElementGraphSelector } from '../../../../element/selectors/elementGraphSelector';
import { getCurrentUserQuickNotesRootBoardId } from '../../../../user/currentUserSelector';
import { getElements } from '../../../../element/selectors/elementsSelector';

const getQuickNotesBoard = createSelector(
    getElements,
    getCurrentUserQuickNotesRootBoardId,
    (elements, quickNotesRootId) => elements.get(quickNotesRootId),
);

export const quickNotesElementSelector = createStructuredSelector({
    element: getQuickNotesBoard,
    elementId: getCurrentUserQuickNotesRootBoardId,
    quickNotesRootId: getCurrentUserQuickNotesRootBoardId,
});

/**
 * The visible elements within quick notes.
 */
export const getQuickNotesElementIdsDeep = createShallowSelector(
    boardVisibleElementGraphSelector,
    getCurrentUserQuickNotesRootBoardId,
    (visibleElementGraph, quickNotesBoardId) => visibleElementGraph[quickNotesBoardId] || [],
);

export const getQuickNotesElementsDeep = createShallowSelector(getQuickNotesElementIdsDeep, getElements, getMany);
