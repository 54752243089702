import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardSharedUsersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon board-shared-users"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#333" />
            <path
                fill="#FFF"
                d="M9.6 8c1.193 0 2.25.891 2.387 2.224L12 10.4v.2a.6.6 0 0 1-.6.6H4.6a.6.6 0 0 1-.6-.6v-.2C4 9.023 5.12 8 6.4 8h3.2zM8 4a1.6 1.6 0 1 1 0 3.2A1.6 1.6 0 0 1 8 4z"
            />
        </g>
    </svg>
);
const Memo = memo(BoardSharedUsersIcon);
export default Memo;
