import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AiSubmitIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon ai-submit"
        {...props}
    >
        <path
            fill="#2F63F7"
            fillRule="evenodd"
            d="m8.354 2.646 5 5 .011.013a.503.503 0 0 1 .033.039l-.044-.052A.502.502 0 0 1 13.5 8v.01a.503.503 0 0 1-.005.064L13.5 8a.502.502 0 0 1-.146.354l-5 5a.5.5 0 0 1-.708-.708L11.792 8.5H3a.5.5 0 0 1 0-1l8.792-.001-4.146-4.145a.5.5 0 0 1-.057-.638l.057-.07a.5.5 0 0 1 .708 0z"
        />
    </svg>
);
const Memo = memo(AiSubmitIcon);
export default Memo;
