/**
 * This schedules a callback to be run two animation frames in the future.
 *
 * This is commonly required when you need a task to run within an animation frame, but after the next
 * animation frame.
 */
export const requestPromisedAnimationFrame = (callbackFn: () => unknown): Promise<unknown> =>
    new Promise((resolve) => {
        requestAnimationFrame(() => {
            const result = callbackFn();
            resolve(result);
        });
    });

export default requestPromisedAnimationFrame;
