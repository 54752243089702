// Constants
import { EDITOR_STATE_CHANGED, EDITOR_UNMOUNT } from '../editorConstants';

const initialState = null;

/**
 * Stores a map of measurements of target IDs.
 */
export default function editorIdReducer(state = initialState, action) {
    switch (action.type) {
        case EDITOR_STATE_CHANGED:
            return action.editorId;
        case EDITOR_UNMOUNT:
            return initialState;
        default:
            return state;
    }
}
