// Lib
import Immutable from 'immutable';
import { ElementCountMap } from '../../../common/elements/elementCountsUtils';

export const getSelectorsCache = (state: Immutable.Map<string, unknown>) => state.getIn(['cache', 'selectors']);

// TODO - At the moment this map is always changed every time there's a cache update, however most of the
//  fields in the map will be the same. Ideally we'd only change the fields that have changed.
export const getSelectorsCacheElementCountsMap = (
    state: Immutable.Map<string, unknown>,
): Record<string, ElementCountMap> | undefined => getSelectorsCache(state)?.elementCountsMap;
