// Handlers
import keyBindingFn from './keyBindingFn';
import handleKeyCommand from './handleKeyCommand';
import handleReturn from './handleReturn';
import handlePastedText from './handlePastedText';
import handleBeforeInput from './handleBeforeInput';

const createCodeEditorPlugin = () => ({
    handleKeyCommand,
    keyBindingFn,
    handleReturn,
    handlePastedText,
    handleBeforeInput,
});

export default createCodeEditorPlugin;
