// Lib
import { defer } from 'lodash';

// Utils
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { getChildren } from '../../../common/elements/utils/elementTraversalUtils';
import { getTitle } from '../../../common/elements/utils/elementPropertyUtils';
import { getElements } from '../selectors/elementsSelector';

// Actions
import { createElementSync, setElementTypeAndUpdateElement } from '../actions/elementActions';
import { moveMultipleElements } from '../actions/elementMoveActions';
import { startEditingElement } from '../selection/selectionActions';
import { analyticsEvent } from '../../analytics';
import { initializeBoard } from '../board/boardActions';

// Constants
import { InboxState } from '../../../common/boards/boardConstants';
import { ElementType } from '../../../common/elements/elementTypes';

export const createColumnWithChildren =
    ({ location, content, childIdArray, sync = true, transactionId = getNewTransactionId(), onDrop }) =>
    (dispatch, getState) => {
        if (onDrop) analyticsEvent('created-column-cmd-drop');

        // Create the new column
        const columnElementId = dispatch(
            createElementSync({
                elementType: ElementType.COLUMN_TYPE,
                location,
                content,
                sync,
                transactionId,
            }),
        );

        const moves = childIdArray.map((id) => ({
            id,
            location: getListChildNewLocation({ listId: columnElementId }),
        }));

        dispatch(moveMultipleElements({ moves, transactionId }));

        defer(() => dispatch(startEditingElement({ id: columnElementId, editorKey: ElementType.COLUMN_TYPE })));

        return { columnElementId, transactionId };
    };

export const convertColumnToBoard =
    ({ elementId, transactionId = getNewTransactionId() }) =>
    async (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const columnElement = elements.get(elementId);
        if (!columnElement) return;

        const columnChildren = getChildren(elements, elementId);

        dispatch(
            setElementTypeAndUpdateElement({
                id: elementId,
                elementType: ElementType.BOARD_TYPE,
                transactionId,
                changes: { inbox: columnChildren.size > 0 ? InboxState.OPEN : InboxState.CLOSED },
            }),
        );

        const title = getTitle(columnElement);
        dispatch(initializeBoard({ id: elementId, title, transactionId }));
    };
