// Lib
import { defaultProps } from '../../../node_module_clones/recompose';

// Utils
import { shouldShowTitleSuggestion } from '../suggestion/title/titleSuggestionUtils';
import { shouldShowDeleteTasksSuggestion } from './suggestion/deleteTasks/deleteTasksSuggestionUtils';

// Components
import FeatureSuggestions from '../suggestion/FeatureSuggestions';
import TitleSuggestion from '../suggestion/title/TitleSuggestion';
import DeleteTasksSuggestion from './suggestion/deleteTasks/DeleteTasksSuggestion';

// Constants
import { FEATURE_SUGGESTIONS } from '../suggestion/suggestionConstants';

const SUGGESTIONS = [
    {
        feature: FEATURE_SUGGESTIONS.TITLE,
        shouldShow: shouldShowTitleSuggestion,
        question: 'Add a title to this list?',
        component: TitleSuggestion,
    },
    {
        feature: FEATURE_SUGGESTIONS.DELETE_COMPLETED_TASKS,
        shouldShow: shouldShowDeleteTasksSuggestion,
        question: 'Delete completed tasks?',
        component: DeleteTasksSuggestion,
    },
];

const enhance = defaultProps({
    possibleSuggestions: SUGGESTIONS,
});

export default enhance(FeatureSuggestions);
