const createCurrentlyVisibleElementsSingleton = () => {
    const currentlyVisibleElements: Set<string> = new Set();

    const getIsVisible = (id: string) => currentlyVisibleElements.has(id);

    const updateVisibility = (id: string, isVisible: boolean) =>
        isVisible ? currentlyVisibleElements.add(id) : currentlyVisibleElements.delete(id);

    const remove = (id: string) => currentlyVisibleElements.delete(id);

    return {
        getIsVisible,
        updateVisibility,
        remove,
    };
};

const currentlyVisibleElementsSingleton = createCurrentlyVisibleElementsSingleton();

export default currentlyVisibleElementsSingleton;
