import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon share"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={24} height={24} fill="#E8E9EB" rx={12} />
            <path
                fill="#5F6672"
                d="M11.82 13a2.5 2.5 0 0 1 2.339 1.615l.39 1.03A1 1 0 0 1 13.614 17H6.5a1 1 0 0 1-.937-1.348l.38-1.022A2.5 2.5 0 0 1 8.285 13h3.535zm5.673-5a.75.75 0 0 1 .75.75v1.492h1.492a.75.75 0 0 1 .742.64l.008.11a.75.75 0 0 1-.639.743l-.11.008h-1.493v1.492a.75.75 0 0 1-1.5 0v-1.492H15.25a.75.75 0 0 1-.742-.64l-.008-.11a.75.75 0 0 1 .64-.742l.11-.008h1.492V8.75a.75.75 0 0 1 .75-.75zm-7.446-1a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z"
            />
        </g>
    </svg>
);
const Memo = memo(ShareIcon);
export default Memo;
