import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileHeaderBackChevronIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon mobile-header-back-chevron"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="m8.81 12 6.47-6.47a.75.75 0 0 0-1.06-1.06l-7 7a.75.75 0 0 0 0 1.06l7 7a.75.75 0 0 0 1.06-1.06L8.81 12z"
        />
    </svg>
);
const Memo = memo(MobileHeaderBackChevronIcon);
export default Memo;
