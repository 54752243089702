// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../../../../../node_module_clones/recompose';
import { createStructuredSelector } from 'reselect';
import { negate } from 'lodash/fp';

// Actions
import { moveMultipleElements } from '../../../../../../element/actions/elementMoveActions';
import { forceElementsMeasurements } from '../../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsActions';

// Selectors
import { getCurrentBoard } from '../../../../../../reducers/currentBoardId/currentBoardIdSelector';
import { getMeasurementsMapThunk } from '../../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Components
import ArrangementPopup from '../ArrangementPopup';
import Button from '../../../../../../components/buttons/Button';
import Icon from '../../../../../../components/icons/Icon';
import TooltipSource from '../../../../../../components/tooltips/TooltipSource';

// Utils
import { toArray } from '../../../../../../../common/utils/immutableHelper';
import { getElementId, isElementLocked } from '../../../../../../../common/elements/utils/elementPropertyUtils';
import measurementsConnect from '../../../../../../components/measurementsStore/measurementsConnect';
import { getHorizontallyDistributedMoves, getVerticallyDistributedMoves } from './distributionUtils';

// Constants
import { PopupIds } from '../../../../../../components/popupPanel/popupConstants';
import { TooltipPositions } from '../../../../../../components/tooltips/tooltipConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../../../../../common/elements/elementConstants';

// Styles
import './DistributePopup.scss';

const mapStateToProps = createStructuredSelector({
    currentBoard: getCurrentBoard,
    unlockedSelectedElements: (state, ownprops) => ownprops.selectedElements.filter(negate(isElementLocked)),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchMoveMultipleElements: (moves) =>
        dispatch(
            moveMultipleElements({
                moves,
                moveOperation: ELEMENT_MOVE_OPERATIONS.ALIGN,
            }),
        ),
    dispatchForceElementsMeasurements: (elementIds) => dispatch(forceElementsMeasurements(elementIds)),
});

const mapMeasurementsDispatchToProps = (dispatch) => ({
    dispatchGetMeasurementsMap: () => dispatch(getMeasurementsMapThunk()),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    measurementsConnect(null, mapMeasurementsDispatchToProps),
);

class DistributePopup extends React.Component {
    distributeHorizontally = async () => {
        const {
            dispatchForceElementsMeasurements,
            dispatchGetMeasurementsMap,
            unlockedSelectedElements,
            currentBoard,
            gridSize,
        } = this.props;

        const elementIds = toArray(unlockedSelectedElements.map(getElementId));
        await dispatchForceElementsMeasurements(elementIds);

        const measurements = dispatchGetMeasurementsMap();

        const moves = getHorizontallyDistributedMoves({
            measurements,
            selectedElements: unlockedSelectedElements,
            currentBoard,
            gridSize,
        });
        this.performDistribution({ moves });
    };

    distributeVertically = async () => {
        const {
            dispatchForceElementsMeasurements,
            dispatchGetMeasurementsMap,
            unlockedSelectedElements,
            currentBoard,
            gridSize,
        } = this.props;

        const elementIds = toArray(unlockedSelectedElements.map(getElementId));
        await dispatchForceElementsMeasurements(elementIds);

        const measurements = dispatchGetMeasurementsMap();
        const moves = getVerticallyDistributedMoves({
            measurements,
            selectedElements: unlockedSelectedElements,
            currentBoard,
            gridSize,
        });
        this.performDistribution({ moves });
    };

    performDistribution = ({ moves }) => {
        const { dispatchMoveMultipleElements, closePopup } = this.props;
        dispatchMoveMultipleElements(moves);
        closePopup();
    };

    render() {
        const { unlockedSelectedElements } = this.props;

        return (
            <ArrangementPopup
                popupId={PopupIds.DISTRIBUTE}
                className="DistributePopup"
                buttonSelector=".DistributeTool .icon"
            >
                <Button onClickFn={this.distributeHorizontally} disabled={unlockedSelectedElements.size <= 2}>
                    <TooltipSource
                        enabled
                        tooltipText="Distribute horizontally"
                        position={TooltipPositions.RIGHT}
                        delay={700}
                        pollPosition
                        triggerOnMouseEnter
                    >
                        <Icon name="distribute-horizontally" />
                    </TooltipSource>
                </Button>
                <Button onClickFn={this.distributeVertically} disabled={unlockedSelectedElements.size <= 2}>
                    <TooltipSource
                        enabled
                        tooltipText="Distribute vertically"
                        position={TooltipPositions.RIGHT}
                        delay={700}
                        pollPosition
                        triggerOnMouseEnter
                    >
                        <Icon name="distribute-vertically" />
                    </TooltipSource>
                </Button>
            </ArrangementPopup>
        );
    }
}

DistributePopup.propTypes = {
    gridSize: PropTypes.number,
    dispatchGetMeasurementsMap: PropTypes.func,
    dispatchMoveMultipleElements: PropTypes.func,
    dispatchForceElementsMeasurements: PropTypes.func,
    selectedElements: PropTypes.object,
    unlockedSelectedElements: PropTypes.object,
    closePopup: PropTypes.func,
    currentBoard: PropTypes.object,
};

export default enhance(DistributePopup);
