// Utils
import registerLine from '../../../common/lines/index';

// Components
import presentationalComponent from './LinePresentationalWrapper';
import containerComponent from './LineContainer';

// Constants
import { TOOLBAR_TOOLS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerLine({
        containerComponent,
        presentationalComponent,
        tools: {
            selected: [
                TOOLBAR_TOOLS.COLOR,
                TOOLBAR_TOOLS.START_ARROW,
                TOOLBAR_TOOLS.END_ARROW,
                TOOLBAR_TOOLS.LINE_LABEL,
                TOOLBAR_TOOLS.LINE_STYLE,
                TOOLBAR_TOOLS.LINE_WEIGHT,
                TOOLBAR_TOOLS.ALIGNMENT,
                TOOLBAR_TOOLS.DISTRIBUTE,
                TOOLBAR_TOOLS.MOBILE_CONTEXT_MENU,
            ],
        },
    });
