import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MenuDownArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon menu-down-arrow"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M5.53 6.47a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06L8 8.94 5.53 6.47z"
        />
    </svg>
);
const Memo = memo(MenuDownArrowIcon);
export default Memo;
