// Web worker
// @ts-ignore import/default
// eslint-disable-next-line import/default
import PersistenceLayerWorker from '../worker/persistenceLayer.worker';

// Types
import { PersistenceLayerMessageType } from '../utils/persistenceLayerCommunicationTypes';
import { PersistenceLayerFetchBoardsResponseMessage } from './cachedBoardFetching/persistenceLayerFetchBoardsTypes';

let instance: PersistenceLayerWorkerInstance | null = null;

/**
 * The Persistence Layer worker holds a lot of state that we want to leverage across the application.
 * This class is a wrapper around the worker to ensure that we only have a single instance of the worker
 * running at any given time.
 *
 * That way we can ensure that the state is shared every time we invoke the persistence layer worker.
 */
export class PersistenceLayerWorkerInstance {
    worker: PersistenceLayerWorker;
    callbacks: Set<(event: MessageEvent) => void> = new Set();

    constructor() {
        if (instance) {
            return instance;
        }

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        instance = this;

        this.worker = new PersistenceLayerWorker();

        this.worker.onmessage = (event: MessageEvent) => {
            this.callbacks.forEach((callback) => callback(event));
        };

        return instance;
    }

    onMessage(callback: (event: MessageEvent) => void) {
        this.callbacks.add(callback);
    }

    postMessage(message: any) {
        this.worker.postMessage(message);
    }

    removeMessageListener(callback: (event: MessageEvent) => void) {
        this.callbacks.delete(callback);
    }

    /**
     * Allows a 'FETCH_BOARDS' operation to be performed in a promise based fashion.
     */
    // TODO - We should explore this concept more generically
    //  E.g. "Request / Response / Error" with a resource type
    fetchBoards(boardIds: string[]): Promise<PersistenceLayerFetchBoardsResponseMessage['payload']> {
        return new Promise((resolve, reject) => {
            const requestId = crypto.randomUUID();

            const onMessage = (event: MessageEvent) => {
                if (event.data.requestId !== requestId) return;

                this.removeMessageListener(onMessage);

                if (event.data.type === PersistenceLayerMessageType.FETCH_BOARDS_RESPONSE) {
                    resolve(event.data.payload);
                } else {
                    reject(event.data.payload);
                }
            };

            this.callbacks.add(onMessage);

            this.postMessage({
                type: PersistenceLayerMessageType.FETCH_BOARDS_REQUEST,
                requestId,
                payload: {
                    boardIds,
                },
            });
        });
    }
}

/**
 * Convenience method to retrieve the instance of the persistence layer worker.
 * This is clearer than writing "new PersistenceLayerWorkerInstance();" because it's very clear
 * you're retrieving a singleton instance.
 */
export const getPersistenceLayerWorkerInstance = (): PersistenceLayerWorkerInstance => {
    if (!instance) {
        instance = new PersistenceLayerWorkerInstance();
    }

    return instance;
};
