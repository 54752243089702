import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarImageEditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-image-edit"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M19.75 24v1.25a.75.75 0 1 1-1.5 0V24h1.5zm-9-9v5c0 .647.492 1.18 1.122 1.244l.128.006h10.25a.75.75 0 1 1 0 1.5H12a2.75 2.75 0 0 1-2.745-2.582L9.25 20v-5h1.5zM17 12.25a2.75 2.75 0 0 1 2.745 2.582l.005.168v5h-1.5v-5a1.25 1.25 0 0 0-1.122-1.244L17 13.75H6.75a.75.75 0 1 1 0-1.5H17zm3-7.75v1.75h1a4.75 4.75 0 0 1 4.745 4.533l.005.217v2a.75.75 0 0 1-1.493.102L24.25 13v-2a3.25 3.25 0 0 0-3.066-3.245L21 7.75h-1V9.5L17 7l3-2.5zM10 9a.75.75 0 0 1 .75.75V11h-1.5V9.75A.75.75 0 0 1 10 9z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarImageEditIcon);
export default Memo;
