// Lib
import { isEmpty } from 'lodash';

// Utils
import { asObject } from '../../common/utils/immutableHelper';
import { logDebugData } from '../debug/log/logUtils';

// Selectors
import {
    getCurrentUserCustomerId,
    getCurrentUserEmail,
    getCurrentUserExperiments,
    getCurrentUserId,
    getCurrentUserRootBoardId,
    getCurrentUserSubscriptionId,
} from '../user/currentUserSelector';
import {
    getCurrentBoardIdFromState,
    getCurrentVisibleBoardId,
} from '../reducers/currentBoardId/currentBoardIdSelector';
import { getSelectedElementIds } from '../element/selection/selectedElementsSelector';
import {
    getCurrentlyEditingEditorId,
    getCurrentlyEditingEditorKey,
    getCurrentlyEditingId,
} from '../element/selection/currentlyEditingSelector';

/**
 * Prints useful details about the current state to the console.
 */
const printUsefulDetails = (getState) => () => {
    const state = getState();

    const currentUserId = getCurrentUserId(state);
    const currentUserEmail = getCurrentUserEmail(state);
    const rootBoardId = getCurrentUserRootBoardId(state);
    const subscriptionId = getCurrentUserSubscriptionId(state);
    const customerId = getCurrentUserCustomerId(state);
    const experiments = asObject(getCurrentUserExperiments(state));
    const experimentsStr = experiments ? `\n\t\t\t\t${experiments.join('\n\t\t\t\t')}` : '';

    const currentBoardId = getCurrentBoardIdFromState(state);
    const currentVisibleBoardId = getCurrentVisibleBoardId(state);

    const selectedElementIds = asObject(getSelectedElementIds(state)).join(', ');
    const currentlyEditingElementId = getCurrentlyEditingId(state);
    const currentlyEditingEditorId = getCurrentlyEditingEditorId(state);
    const currentlyEditingEditorKey = getCurrentlyEditingEditorKey(state);

    const debugData = [
        {
            title: 'Current user',
            entries: [
                ['ID', currentUserId],
                ['Email', currentUserEmail],
                ['Root board ID', rootBoardId],
                ['Subscription ID', subscriptionId],
                ['Customer ID', customerId],
                ['Experiments', experimentsStr],
            ],
        },
        {
            title: 'Current board',
            entries: [
                ['Board ID', currentBoardId],
                ['Visible board ID', currentVisibleBoardId],
            ],
        },
        {
            title: 'Selected elements',
            entries: [
                ['Selected element IDs', selectedElementIds],
                ['Editing element ID', currentlyEditingElementId],
                ['Editing editor ID', currentlyEditingEditorId],
                ['Editing editor key', currentlyEditingEditorKey],
            ],
        },
    ];

    logDebugData(debugData);
};

export default (prefix = '') =>
    ({ getState, dispatch }) => {
        if (window) {
            window[`_${prefix}dispatch`] = dispatch;
            window[`_${prefix}state`] = getState();

            if (isEmpty(prefix)) {
                window._mn_details = printUsefulDetails(getState);
            }
        }

        return (next) => (action) => {
            const nextAction = next(action);
            if (window) {
                window[`_${prefix}state`] = getState();
            }
            return nextAction;
        };
    };
