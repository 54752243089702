// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { identity, delay, constant } from 'lodash/fp';
import { connect } from 'react-redux';

// Utils
import {
    getElementId,
    getFileProp,
    getImageColors,
    getShowMedia,
} from '../../../common/elements/utils/elementPropertyUtils';
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import fileCanShowMedia from './utils/fileCanShowMedia';
import { getTimestamp, TIMES } from '../../../common/utils/timeUtil';
import { propIn, toArray } from '../../../common/utils/immutableHelper';
import { getFileDefaultMaxWidth } from './utils/fileElementUtils';
import { isIconViewMode } from '../../../common/elements/utils/elementDisplayUtils';
import { getColorIsColorful } from '../../../common/colors/coreColorUtil';

// Actions
import { acceptElementAttachmentUndo, uploadAttachment } from '../attachments/attachmentActions';
import { navigateToElement } from '../../reducers/navigationActions';
import { updateElement } from '../actions/elementActions';
import { mediaPlayerReset } from './mediaPlayer/mediaPlayerActions';
import { switchConnectedElementParents } from '../connections/elementConnectionsActions';

// Components
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import elementSaveAspectRatioResizeDecorator from '../resizing/elementSaveAspectRatioResizeDecorator';
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import ImageColorPaletteObserver from '../../components/images/imageColorExtraction/ImageColorPaletteObserver';
import File from './File';

// Constants
import { FILE_MIN_WIDTH_GRID } from './fileElementConstants';
import { ELEMENT_DISPLAY_MODES } from '../../../common/elements/elementDisplayModeConstants';

const PREVIEW_CHECK_WAIT_TIME = TIMES.MINUTE;

const hasPreview = (element) => !!propIn(['content', 'previewReady'], element);
const getUploadedTime = propIn(['content', 'file', 'uploadedTimestamp']);

const nowHasUploadedTime = (oldElement, newElement) => !getUploadedTime(oldElement) && getUploadedTime(newElement);
const nowHasPreview = (oldElement, newElement) => !hasPreview(oldElement) && hasPreview(newElement);
const nowHasShowMedia = (oldElement, newElement) => !getShowMedia(oldElement) && getShowMedia(newElement);
const nowHasImageColors = (oldElement, newElement) => !getImageColors(oldElement) && getImageColors(newElement);

const shouldCheckForPreview = (element) => {
    const fileData = getFileProp(element);
    // Check for preview if we have file data and it can show a preview, but we currently don't have a preview
    return fileData && fileCanShowMedia(element) && !hasPreview(element);
};

const getPreviewCheckDelay = (element) => {
    const uploadedTimestamp = getUploadedTime(element);
    if (!uploadedTimestamp) return 0;

    const now = getTimestamp();
    // Wait the check time - the time that has passed
    return Math.max(PREVIEW_CHECK_WAIT_TIME - (now - uploadedTimestamp), 0);
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUploadFile: (elementId, file, options, transactionId) =>
        dispatch(uploadAttachment({ id: elementId, file, options, transactionId })),
    dispatchNavigateToElement: (elementId) => dispatch(navigateToElement({ elementId })),
    dispatchPreviewError: (elementId, error) =>
        dispatch(
            updateElement({
                id: elementId,
                changes: {
                    image: {
                        error,
                        hasError: true,
                    },
                    previewReady: true,
                },
            }),
        ),
    dispatchSetDisplayMode: (elementId, displayMode) =>
        dispatch(
            updateElement({
                id: elementId,
                changes: {
                    displayMode,
                },
            }),
        ),
    dispatchResetMediaPlayer: (playerId) => dispatch(mediaPlayerReset({ playerId })),
    dispatchSwitchConnectedElementParents: (args) => dispatch(switchConnectedElementParents(args)),
    dispatchAcceptElementAttachmentUndo: (props) => dispatch(acceptElementAttachmentUndo(props)),
});

@connect(null, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys'])
@captionContainerDecorator
@elementResizeDecorator({ getMinWidth: constant(FILE_MIN_WIDTH_GRID) })
@elementSaveAspectRatioResizeDecorator({
    getMinWidth: constant(FILE_MIN_WIDTH_GRID),
    getDefaultMaxWidth: getFileDefaultMaxWidth,
})
class FileContainer extends React.Component {
    componentWillMount() {
        this.schedulePreviewStatusCheck(this.props);
    }

    componentDidUpdate(prevProps) {
        const { element, dispatchResetMediaPlayer } = prevProps;

        // If we now have an uploaded time, start the timer
        if (nowHasUploadedTime(element, this.props.element)) this.schedulePreviewStatusCheck(this.props);

        // If we now have the preview, clear the timer
        if (nowHasPreview(element, this.props.element)) this.clearSchedule();

        if (nowHasShowMedia(element, this.props.element)) {
            dispatchResetMediaPlayer(getElementId(element));
        }

        if (isIconViewMode(this.props.element) && nowHasImageColors(element, this.props.element)) {
            this.handleIconReceivedColors(this.props);
        }
    }

    componentWillUnmount() {
        this.clearSchedule();
    }

    schedulePreviewStatusCheck = ({ element }) => {
        if (!shouldCheckForPreview(element)) return;

        const waitTime = getPreviewCheckDelay(element);
        this.previewStatusTimer = delay(waitTime, this.checkPreviewStatus);
    };

    checkPreviewStatus = () => {
        const { element, dispatchPreviewError } = this.props;

        if (hasPreview(element)) return;

        dispatchPreviewError(getElementId(element), { message: 'Failed to receive preview within the expected time' });
    };

    clearSchedule = () => {
        if (this.previewStatusTimer) clearTimeout(this.previewStatusTimer);
    };

    handleKey = (event) => {
        if (!this.props.isSelected) return;
        if (event.target !== this.container) return;
        this.props.captionKeyHandler(event, this.props);
    };

    uploadFile = (file, transactionId) => {
        const { dispatchUploadFile, element } = this.props;
        dispatchUploadFile(getElementId(element), file, null, transactionId);
    };

    handleIconReceivedColors = ({ element, dispatchSetDisplayMode }) => {
        const colors = toArray(getImageColors(element));
        const colorfulColors = colors.filter(getColorIsColorful);

        if (colorfulColors.length > 1) {
            dispatchSetDisplayMode(getElementId(element), ELEMENT_DISPLAY_MODES.DETAIL_VIEW);
        }
    };

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div
                className="FileContainer"
                ref={(c) => {
                    this.container = c;
                }}
                onKeyDown={this.handleKey}
                tabIndex="-1"
            >
                <ImageColorPaletteObserver element={this.props.element} />
                <File {...this.props} uploadFile={this.uploadFile} />
            </div>,
        );
    }
}

FileContainer.propTypes = {
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    canConnectLineEdge: PropTypes.bool,
    isSelected: PropTypes.bool,
    captionKeyHandler: PropTypes.func,
    inList: PropTypes.string,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    dispatchUploadFile: PropTypes.func,
    dispatchPreviewError: PropTypes.func,
    dispatchResetMediaPlayer: PropTypes.func,
};

export default FileContainer;
