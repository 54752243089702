// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { isGrammarlyError, isGrammarlyInstalled } from './grammarlyUtils';

// Components
import ElementErrorRenderer from './ElementErrorRenderer';
import ErrorBoundary from '../../components/error/errorBoundary/ErrorBoundary';

const getErrorMessage = (error) => {
    if (isGrammarlyError(error)) {
        return `Error rendering element: Grammarly error: ${error.toString()}`;
    }

    return `Error rendering element: ${error?.toString()}`;
};

const getRollbarErrorDetails = (error, info, props) => ({
    elementId: props.elementId,
    stack: info.componentStack,
    hasGrammarly: isGrammarlyInstalled(),
});

const ElementErrorBoundary = (props) => (
    <ErrorBoundary
        {...props}
        doNotReport={props.isPresentational}
        ErrorRenderer={ElementErrorRenderer}
        getErrorMessage={getErrorMessage}
        getRollbarErrorDetails={getRollbarErrorDetails}
    />
);

ElementErrorBoundary.propTypes = {
    elementId: PropTypes.string,
    isPresentational: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ElementErrorBoundary;
