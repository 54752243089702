import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ModalToolbarOpenNewTabIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon modal-toolbar-open-new-tab"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M15.25 9a.75.75 0 1 1 0 1.5H11a1.5 1.5 0 0 0-1.493 1.356L9.5 12v9a1.5 1.5 0 0 0 1.356 1.493L11 22.5h9a1.5 1.5 0 0 0 1.493-1.356L21.5 21v-4.25a.75.75 0 1 1 1.5 0V21a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h4.25zm9-2a.75.75 0 0 1 .743.648L25 7.75v5a.75.75 0 0 1-1.493.102l-.007-.102V9.56l-6.97 6.97a.75.75 0 0 1-1.133-.976l.073-.084 6.968-6.97H19.25a.75.75 0 0 1-.743-.648L18.5 7.75a.75.75 0 0 1 .648-.743L19.25 7h5z"
        />
    </svg>
);
const Memo = memo(ModalToolbarOpenNewTabIcon);
export default Memo;
