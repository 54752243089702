// Lib
import * as Immutable from 'immutable';

// Utils
import { buildMovesForRects, getElementMeasurements, isArrangeable, shiftRectsForMoves } from '../arrangementUtils';
import {
    ImElementMeasurement,
    ElementMeasurement,
} from '../../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsTypes';
import { roundPixelToNextGridPoint } from '../../../../../../utils/grid/gridUtils';

// Types
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';

type GetRectValueFn = (rect: ElementMeasurement) => number;
type SetRectValueFn = (rect: ElementMeasurement, val: number) => ElementMeasurement;

const getTop = (rect: ElementMeasurement) => rect.top;
const getBottom = (rect: ElementMeasurement) => rect.bottom;
const getHeight = (rect: ElementMeasurement) => rect.height;
const getLeft = (rect: ElementMeasurement) => rect.left;
const getRight = (rect: ElementMeasurement) => rect.right;
const getWidth = (rect: ElementMeasurement) => rect.width;

const setLeft = (rect: ElementMeasurement, val: number) => ({ ...rect, x: val, left: val });
const setTop = (rect: ElementMeasurement, val: number) => ({ ...rect, y: val, top: val });

const getMinRect = (getMinVal: GetRectValueFn, getFallbackVal: GetRectValueFn) => (rects: ElementMeasurement[]) =>
    rects.reduce((currMinRect: ElementMeasurement | null, rect) => {
        if (!currMinRect) return rect;

        if (getMinVal(rect) < getMinVal(currMinRect)) return rect;

        if (getMinVal(rect) === getMinVal(currMinRect) && getFallbackVal(rect) < getFallbackVal(currMinRect)) {
            return rect;
        }

        return currMinRect;
    }, null) as ElementMeasurement;

const getMaxRect = (getMaxVal: GetRectValueFn, getFallbackVal: GetRectValueFn) => (rects: ElementMeasurement[]) =>
    rects.reduce((currMaxRect: ElementMeasurement | null, rect) => {
        if (!currMaxRect) return rect;

        if (getMaxVal(rect) > getMaxVal(currMaxRect)) return rect;

        if (getMaxVal(rect) === getMaxVal(currMaxRect) && getFallbackVal(rect) > getFallbackVal(currMaxRect)) {
            return rect;
        }

        return currMaxRect;
    }, null) as ElementMeasurement;

/**
 * Determines the space to use between each element.
 */
const getSpaceBetween = (emptySpace: number, gridSize: number, emptySpaceBetweenRounded: number) => {
    // When the empty space is positive we want to snap to either the grid unit or the existing space between the elements
    if (emptySpace > 0) return Math.max(gridSize, emptySpaceBetweenRounded);
    // If there's no empty space, don't move anything
    if (emptySpace === 0) return 0;
    // If the rounded empty space between is greater than the negative grid size, but the empty space is negative
    // (I.e. between negative grid size and 0 - E.g. -10px to -1px) then set the space between to 0 rather than
    // using a negative grid size
    if (emptySpaceBetweenRounded > -gridSize) return 0;
    // Otherwise use negative grid units
    return Math.min(gridSize * -1, emptySpaceBetweenRounded);
};

const distributeRects =
    (
        getMinVal: GetRectValueFn,
        getMaxVal: GetRectValueFn,
        getFallbackVal: GetRectValueFn,
        getSize: GetRectValueFn,
        setPos: SetRectValueFn,
    ) =>
    (rects: ElementMeasurement[], gridSize: number) => {
        // start by ordering the rects by their min value to establish consistent outer shapes
        const orderedRects = rects.sort((rectA, rectB) => {
            const minA = getMinVal(rectA);
            const minB = getMinVal(rectB);

            if (minA === minB) return getFallbackVal(rectA) - getFallbackVal(rectB);

            return minA - minB;
        });

        // Find outer shape or shapes
        const minRect = getMinRect(getMinVal, getFallbackVal)(orderedRects);
        const maxRect = getMaxRect(getMaxVal, getFallbackVal)(orderedRects);

        // Get the min value of the min rect
        const minVal = getMinVal(minRect);
        // Get the max value of the max rect
        const maxVal = getMaxVal(maxRect);

        // Find the space between them
        const remainingSpace = maxVal - minVal;

        // The tail shapes are the only shapes that will be moved (the first shape will stay in its original position)
        const tailShapes = orderedRects.filter(({ id }) => id !== minRect.id);

        // Find the total size of all the rects
        const totalSize = orderedRects.reduce((totalSize, rect) => totalSize + getSize(rect), 0);

        // Find the even margin between inner each shape
        const emptySpace = remainingSpace - totalSize;
        const emptySpaceBetweenRounded = Math.round(emptySpace / tailShapes.length);

        const spaceBetween = getSpaceBetween(emptySpace, gridSize, emptySpaceBetweenRounded);
        const snappedSpaceBetween = roundPixelToNextGridPoint(spaceBetween, gridSize);

        // Starting at the edge of the first bounding shape at the margin, add the margin
        // Then add the width of the current rect and margin to find the left position
        // for each of the inner rects
        const startingPositions = tailShapes.reduce(
            (positions, currentShape, i) => {
                positions.push(positions[i] + getSize(currentShape) + snappedSpaceBetween);
                return positions;
            },
            [minVal + getSize(minRect) + snappedSpaceBetween],
        );

        const updatedTailShapes = tailShapes.reduce((distributedShapes, currentShape, index) => {
            const newShapePosition = setPos(currentShape, startingPositions[index]);
            distributedShapes.push(newShapePosition);
            return distributedShapes;
        }, [] as ElementMeasurement[]);

        return updatedTailShapes;
    };

interface GetDistributedMovesFnParams {
    measurements: Immutable.Map<string, ImElementMeasurement>;
    selectedElements: Immutable.List<ImMNElement>;
    currentBoard: ImMNElement;
    gridSize: number;
}

type GetDistributedMovesFn = (props: GetDistributedMovesFnParams) => ReturnType<typeof buildMovesForRects>;

export const getDistributedMoves = (
    getMinVal: GetRectValueFn,
    getMaxVal: GetRectValueFn,
    getFallbackVal: GetRectValueFn,
    getSize: GetRectValueFn,
    setPos: SetRectValueFn,
): GetDistributedMovesFn => {
    const distributionFn = distributeRects(getMinVal, getMaxVal, getFallbackVal, getSize, setPos);

    return ({ measurements, selectedElements, currentBoard, gridSize }) => {
        // Filter elements that shouldn't be distributed
        const elementsToDistribute = selectedElements.filter(isArrangeable) as Immutable.List<ImMNElement>;

        // Get their measurements
        // Add their IDs to the measurements
        const relevantMeasurementRects = getElementMeasurements({
            elements: elementsToDistribute,
            measurements,
            gridSize,
        });

        // Get their rects for measurements
        const distributedRects = distributionFn(relevantMeasurementRects, gridSize);

        const correctedAlignedRects = shiftRectsForMoves({
            rects: distributedRects,
            elements: elementsToDistribute,
            gridSize,
        });

        return buildMovesForRects({
            elements: elementsToDistribute,
            rects: correctedAlignedRects,
            gridSize,
            currentBoard,
        });
    };
};

export const getHorizontallyDistributedMoves = getDistributedMoves(getLeft, getRight, getTop, getWidth, setLeft);
export const getVerticallyDistributedMoves = getDistributedMoves(getTop, getBottom, getLeft, getHeight, setTop);
