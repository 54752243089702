// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormInput.scss';

const FormInput = React.forwardRef(function FormInput(props, formInputRef) {
    const { name, className, error, ...inputProps } = props;

    return (
        <input
            className={classNames('FormInput', className, { error: !!error })}
            id={name}
            name={name}
            ref={formInputRef}
            {...inputProps}
        />
    );
});

FormInput.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.object,
};

export default FormInput;
