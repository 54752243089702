// Constants
import { HYBRID_ACTION_TYPES } from './hybridMiddlewareConstants';
import { ELEMENTS_SELECTED, ELEMENT_EDIT_START } from '../../../../common/elements/selectionConstants';
import { ELEMENT_CREATE, ELEMENT_UPDATE } from '../../../../common/elements/elementConstants';
import { LOCATION_CHANGE } from 'react-router-redux';

// Utils
import { shouldForwardElementsSelectedActionToNextMiddleware } from './hybridPreMiddlewareFilters/elementsSelected/hybridFilterElementsSelectedActions';
import { shouldForwardElementEditStartActionToNextMiddleware } from './hybridPreMiddlewareFilters/elementEditStart/hybridFilterElementEditStartActions';
import { undoAction, redoAction } from '../../../utils/undoRedo/undoRedoActions';
import { ReduxStore } from '../../../types/reduxTypes';
import { AnyAction } from 'redux';
import { default as createHybridActionFromElementEditStartAction } from './hybridPreMiddlewareActions/elementEditStartActionHybridHandler';
import { default as createHybridActionFromElementsSelectedAction } from './hybridPreMiddlewareActions/elementsSelectedActionHybridHandler';
import { default as createHybridActionFromFocusModeElementClickedAction } from './hybridPreMiddlewareActions/focusModeElementClickedActionHybridHandler';
import { default as createHybridActionFromLocationChangeAction } from './hybridPreMiddlewareActions/locationChangeActionHybridHandler';
import { FOCUS_MODE_ELEMENT_CLICKED } from '../../../workspace/presentation/presentationConstants';
import createUndoAction from '../../../utils/undoRedo/middleware/createUndoAction';
import createRedoAction from '../../../utils/undoRedo/middleware/createRedoAction';
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';

const createHybridActionIfAble = (state: any, action: AnyAction): AnyAction | undefined => {
    switch (action.type) {
        case ELEMENT_EDIT_START:
            return createHybridActionFromElementEditStartAction(state, action);
        case ELEMENTS_SELECTED:
            return createHybridActionFromElementsSelectedAction(state, action);
        case FOCUS_MODE_ELEMENT_CLICKED:
            return createHybridActionFromFocusModeElementClickedAction(state, action);
        case LOCATION_CHANGE:
            return createHybridActionFromLocationChangeAction(state, action);
        default:
            return;
    }
};

const addUndoRedoIfRequired = (store: ReduxStore, action: AnyAction): AnyAction => {
    const currentState = store.getState();
    switch (action.type) {
        case ELEMENT_CREATE:
        case ELEMENT_UPDATE:
            if (action.isUndo || action.isRedo) return action;
            if (action.undoRedoAction === undefined) {
                action.undoRedoAction = {
                    undo: createUndoAction(currentState, action),
                    redo: createRedoAction(currentState, action),
                };
            }
            if (action.transactionId === undefined) action.transactionId = getNewTransactionId();
            return action;
        default:
            return action;
    }
};

const performSideEffect = (store: ReduxStore, action: AnyAction) => {
    const { dispatch } = store;
    switch (action.type) {
        case HYBRID_ACTION_TYPES.REDO:
            dispatch(redoAction());
            return;
        case HYBRID_ACTION_TYPES.UNDO:
            dispatch(undoAction());
            return;
        default:
            return;
    }
};

const shouldForwardActionToNextMiddleware = (state: any, action: AnyAction): boolean => {
    switch (action.type) {
        case ELEMENTS_SELECTED:
            return shouldForwardElementsSelectedActionToNextMiddleware(state, action);
        case ELEMENT_EDIT_START:
            return shouldForwardElementEditStartActionToNextMiddleware(state, action);
        case HYBRID_ACTION_TYPES.REDO:
        case HYBRID_ACTION_TYPES.UNDO:
            return false;
        default:
            return true;
    }
};

export default (store: ReduxStore) =>
    (next: Function) =>
    (action: AnyAction): AnyAction | undefined => {
        if (action.remote) return next(action);

        // 1. Add undo/redo actions to the action if required
        const updatedAction = addUndoRedoIfRequired(store, action);

        // 2. Perform any side-effects required for the action.
        performSideEffect(store, updatedAction);

        // 3. Create the hybrid action (if able).
        const state = store.getState();
        const hybridAction = createHybridActionIfAble(state, updatedAction);

        // 3a. If able to create a hybrid action, finish by forwarding it to the next middleware.
        if (hybridAction) return next(hybridAction);

        // 4. If unable to create a hybrid action, early exit if we want to cut off the action.
        if (!shouldForwardActionToNextMiddleware(state, action)) return;

        // 4b. Otherwise, forward the action to the other middleware as 'usual'.
        return next(action);
    };
