// Lib
import React, { createContext, ReactNode, useContext } from 'react';

// Components
import MobileModalPortal from './MobileModalPortal';
import SnapPointsStateDebugger from './snapPointDebugger/MobileModalSnapPointDebugger';
import Button from '../../../components/buttons/Button';

// Hooks
import useModalSheetDragState, { ModalSheetDragState } from './hooks/useModalSheetDragState';

// Utils
import classNames from 'classnames';

// Styles
import './MobileModalSheet.scss';

const SheetContext = createContext<ModalSheetDragState>({
    sheetContentRef: { current: null },
    sheetRef: { current: null },
    sheetOverlayRef: { current: null },
    snapPointsState: [],
    isSheetMounted: false,
    closeSheet: () => {},
    handleSheetTouchStart: () => {},
    handleSheetTouchMove: () => {},
    handleSheetTouchEnd: () => {},
    handleSheetTouchCancel: () => {},
});
export const useSheetContext = () => useContext(SheetContext);

export interface MobileModalSheetProps {
    className?: string;
    children: ReactNode;
    isSheetOpen: boolean;
    setIsSheetOpen: (isSheetOpen: boolean) => void;
    activeSnapPoint: number;
    setActiveSnapPoint: (activeSnapPoint: number) => void;
    defaultSnapPoint?: number | string; // e.g. null to open at content height, 0.5 to open at 50%, or '0.5max' to open at content height with a max of 50%
    snapPoints?: number[];
    dismissible?: boolean;
    onSheetClose?: () => void;
    onCloseTransitionEnd?: () => void;
}

/**
 * The main component that wraps the sheet and its content, and controls the drag functionality.
 * Here we add all the props that determine the drag functionality of the sheet.
 * See docs/mobile-modal-sheet.md for more details on how to use this component.
 */
export const MobileModalSheet = (props: MobileModalSheetProps) => {
    const { children, className } = props;

    const modalSheetDragState = useModalSheetDragState(props);
    const { isSheetMounted } = modalSheetDragState;

    return (
        <MobileModalPortal>
            <SheetContext.Provider value={modalSheetDragState}>
                {isSheetMounted ? (
                    <div className={classNames('MobileModalSheet', className)} data-toolbar-ignore-clicks>
                        {children}
                    </div>
                ) : null}
            </SheetContext.Provider>
        </MobileModalPortal>
    );
};

MobileModalSheet.displayName = 'MobileModalSheet';

/**
 * The overlay that covers the screen when the sheet is open.
 */
export const Overlay = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    const { closeSheet, sheetOverlayRef } = useSheetContext();

    const onClickHandler = () => {
        onClick?.();
        closeSheet();
    };

    return <div className={classNames('Overlay', className)} ref={sheetOverlayRef} onClick={onClickHandler} />;
};

/**
 * The sheet itself. This is the area that can be dragged up and down.
 * It has the scrollable content area inside it, as well as an optional drag handle.
 */
export const Sheet = ({ children, className }: { children: ReactNode; className?: string }) => {
    const { sheetRef, handleSheetTouchStart, handleSheetTouchMove, handleSheetTouchEnd, handleSheetTouchCancel } =
        useSheetContext();

    return (
        <div
            className={classNames('Sheet', className)}
            ref={sheetRef}
            onTouchStart={handleSheetTouchStart}
            onTouchMove={handleSheetTouchMove}
            onTouchEnd={handleSheetTouchEnd}
            onTouchCancel={handleSheetTouchCancel}
        >
            {children}
        </div>
    );
};

/**
 * The drag handle that can be used to drag the sheet up and down.
 */
export const Handle = ({ className }: { className?: string }) => <div className={classNames('Handle', className)} />;

/**
 * The header of the sheet. This is the draggable area at the top of the sheet that can contain a handle, heading, close button or any other custom components.
 */
export const Header = ({ children, className }: { children: ReactNode; className?: string }) => (
    <div className={classNames('Header', className)}>{children}</div>
);

/**
 * The heading of the sheet. This is the title of the sheet that appears at the top of the sheet.
 */
export const Heading = ({ children }: { children: ReactNode }) => <div className="Heading">{children}</div>;

/**
 * The close button for the sheet. Has an orange 'Done' by default if no child component is provided.
 */
export const CloseButton = ({
    children,
    className,
    onClick,
}: {
    children?: ReactNode;
    className?: string;
    onClick?: () => void;
}) => {
    const { closeSheet } = useSheetContext();

    const onClickHandler = () => {
        onClick?.();
        closeSheet();
    };

    const buttonContent = children || <span className="default-content-style">Done</span>;

    return (
        <Button className={classNames('CloseButton', className)} onClick={onClickHandler}>
            {buttonContent}
        </Button>
    );
};

/**
 * The content of the sheet. Here is where the main scrollable content of the sheet should be placed.
 */
export const Content = ({ children }: { children: ReactNode }) => {
    const { sheetContentRef } = useSheetContext();

    return (
        <div className="Content" ref={sheetContentRef}>
            {children}
        </div>
    );
};

/**
 * A non-draggable area that can be used to prevent sheet dragging on certain elements.
 */
export const NonDraggableArea = ({ children }: { children: ReactNode }) => (
    <div data-sheet-ignore-drags>{children}</div>
);

/**
 * Debugging tool to show the snap points on the sheet.
 */
export const SnapPointsDebugger = () => (
    <SnapPointsStateDebugger snapPointsState={useSheetContext()?.snapPointsState} />
);

export default MobileModalSheet;
