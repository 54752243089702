import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorStroke4Icon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        className="Icon drawing-editor-stroke-4"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="nonzero"
            d="m10.86.666-.328.254-2.26 1.759-.983.777-1.33 1.068-.71.581-.642.536-.404.343-.58.503-.588.53-.37.342-.32.308-.292.29-.272.284-.26.29c-.096.109-.18.208-.268.314a7.375 7.375 0 0 0-.573.793c-.317.52-.484.919-.6 1.441-.049.25-.073.451-.08.655l.003.236c.01.241.035.43.082.626l.036.145.025.09.048.147c.035.093.069.174.103.252l.054.115c.045.101.109.211.198.346l.103.15.138.179.066.076.135.142.166.154a3.07 3.07 0 0 0 .796.497l.192.076.143.05.208.056.186.04.152.026.261.028c.248.017.432.014.687-.004.357-.033.64-.082 1.024-.172l.178-.044.426-.118.02.102a2.585 2.585 0 0 0 .474.99l.095.111.113.12.114.107c.129.113.237.193.358.267l.175.099.177.085.096.039.166.057.159.047.088.02.154.03c.386.05.621.052.947.015l.178-.023.286-.051.28-.063.306-.079.327-.092 1.116-.343.694-.208.273-.072.142-.033.208-.04.132-.021c.258-.039.493-.052.687-.04l.08.008c.1.012.175.031.212.048l.021.062.006.035-.01-.026A2.265 2.265 0 0 0 15.769 18l.166-.01.15-.021c1.046-.18 1.818-1.01 1.9-2.035v-.026l.008-.042a1.01 1.01 0 0 0 .002-.22l-.014-.157-.005-.124-.012-.112-.01-.055-.02-.074-.007-.05a4.493 4.493 0 0 0-.136-.57 4.547 4.547 0 0 0-.466-1.045 4.49 4.49 0 0 0-1.747-1.648l-.217-.111a5.256 5.256 0 0 0-1.21-.399l-.088-.017.013-.023c.026-.047.05-.095.074-.144l.064-.145.08-.216.058-.191.03-.128.031-.17c.018-.126.025-.209.029-.301l.002-.103-.008-.218a4.397 4.397 0 0 0-.023-.193l-.025-.144a2.726 2.726 0 0 0-.331-.848l-.089-.138-.057-.079-.084-.107-.118-.133a2.825 2.825 0 0 0-.518-.429l-.182-.103a2.933 2.933 0 0 0-.37-.165l-.162-.053 2.219-1.56.217-.171c1.233-1.098 1.441-2.867.53-4.167l-.112-.15a3.22 3.22 0 0 0-4.47-.54z"
        />
    </svg>
);
const Memo = memo(DrawingEditorStroke4Icon);
export default Memo;
