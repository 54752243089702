import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarContextMenuIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 40 40"
        className="Icon toolbar-context-menu"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={40} height={40} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M11.5 23a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm8.75 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zM29 23a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarContextMenuIcon);
export default Memo;
