// Lib
import * as Immutable from 'immutable';
import { forEach } from 'lodash';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import { APP_SET_INITIALISED } from './initialisationActions';
import { SOCKET_CONNECT } from '../../utils/socket/socketConstants';

const initialState = Immutable.Map({
    pageLoad: getTimestamp(),
    workspaceLoadIdle: 0,
    currentUser: 0,
    remoteUsers: 0,
    aliases: 0,
    elementCounts: 0,
    boardSummaries: 0,
    boardHierarchies: 0,
    customTemplates: 0,
    socketConnection: 0,
    appInit: 0,
});

const setInitialisedState = (state, action) =>
    state.withMutations((mutableState) => {
        forEach(action.details, (value, property) => {
            mutableState.set(property, action.timestamp);
        });
    });

export default (state = initialState, action) => {
    switch (action.type) {
        case SOCKET_CONNECT:
            return state.set('socketConnection', action.timestamp);
        case APP_SET_INITIALISED:
            return setInitialisedState(state, action);
        default:
            return state;
    }
};
