// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { isUserSubscribed } from '../../../../../user/currentUserSelector';

// Actions
import { closePopup } from '../../../../../components/popupPanel/popupActions';

// Components
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';

// Styles
import './ArrangementPopup.scss';

const mapStateToProps = (state) => ({
    isSubscribed: isUserSubscribed(state),
});

const mapDispatchToProps = (dispatch) => ({
    closeArrangementPopup: (popupId) => dispatch(closePopup(popupId)),
});

const ArrangementPopup = ({ children, popupId, buttonSelector, className, closeArrangementPopup }) => (
    <ToolbarPopup
        popupId={popupId}
        buttonSelector={buttonSelector}
        className={classNames('ArrangementPopup', className)}
        closePopup={() => closeArrangementPopup(popupId)}
    >
        <div className="tools">{children}</div>
    </ToolbarPopup>
);

ArrangementPopup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    popupId: PropTypes.string,
    buttonSelector: PropTypes.string,
    className: PropTypes.string,
    isSubscribed: PropTypes.bool,
    closeArrangementPopup: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArrangementPopup);
