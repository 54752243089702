import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniBoardSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-board-selected"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <g transform="translate(3 3)">
                <rect width={14} height={14} fill="#5882F8" rx={3} />
                <rect width={3.063} height={3.063} x={7.438} y={7.438} fill="#FFF" rx={0.875} />
                <rect width={3.063} height={3.063} x={3.5} y={7.438} fill="#FFF" rx={0.875} />
                <rect width={3.063} height={3.063} x={7.438} y={3.5} fill="#FFF" rx={1.531} />
                <rect width={3.063} height={3.063} x={3.5} y={3.5} fill="#FFF" rx={0.875} />
            </g>
            <path
                fill="#323B4A"
                fillRule="nonzero"
                d="M14 1a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H6a5 5 0 0 1-5-5V6a5 5 0 0 1 5-5h8zm0 2H6a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniBoardSelectedIcon);
export default Memo;
