import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementAudioIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-audio"
        {...props}
    >
        <defs>
            <filter
                id="ElementAudioIcon__a"
                width="131.2%"
                height="131.2%"
                x="-15.6%"
                y="-12.5%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <rect id="ElementAudioIcon__b" width={32} height={32} x={0} y={0} rx={2} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <use xlinkHref="#ElementAudioIcon__b" fill="#000" filter="url(#ElementAudioIcon__a)" />
                <use xlinkHref="#ElementAudioIcon__b" fill="#FFF" />
            </g>
            <path fill="#4EB85A" d="M2 0h28a2 2 0 0 1 2 2v22H0V2a2 2 0 0 1 2-2z" />
            <path
                fill="#FFF"
                d="M12.75 6a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-1.5 0V6.75a.75.75 0 0 1 .75-.75zm7.5 0a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-1.5 0V6.75a.75.75 0 0 1 .75-.75zm-10 3a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75zm7.5 0a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75zm5 0a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75zm-15 1.5a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 .75-.75zm7.5 0a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 .75-.75zm10 0a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 .75-.75z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementAudioIcon);
export default Memo;
