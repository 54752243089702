import { ReduxStore } from '../../../../types/reduxTypes';
import {
    RESOURCES_FETCHED,
    RESOURCES_FETCHING,
} from '../../../../utils/services/http/asyncResource/asyncResourceConstants';
import {
    getSwiftWebkitMessageHandlers,
    swiftEncodeJson,
    swiftWebkitMessageHandlersExist,
} from '../../utils/swiftUtils';
import { ResourcesNotifierAction } from './swiftResourcesNotifierTypes';

/**
 * Notifies the Swift apps of relevant 'RESOURCES_*' actions so that they can respond to
 * the webview having started/finished loading certain boards and elements, for example.
 */
const swiftResourcesNotifier =
    (_store: ReduxStore) =>
    (next: Function) =>
    (action: ResourcesNotifierAction): Function => {
        if (!swiftWebkitMessageHandlersExist()) return next(action);
        const handlers = getSwiftWebkitMessageHandlers();
        if (!handlers.milanoteResourcesNotifier) return next(action);

        switch (action.type) {
            case RESOURCES_FETCHED:
            case RESOURCES_FETCHING: {
                /**
                 * Assumes that the type structure of the action is the same as what
                 * the Swift apps expect for this message.
                 */
                const body = swiftEncodeJson(action);
                handlers.milanoteResourcesNotifier.postMessage(body);
                break;
            }
        }

        return next(action);
    };

export default swiftResourcesNotifier;
