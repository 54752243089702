// Services
import localStorageService from '../utils/services/localStorage/localStorage';
import { removeCapacitorAuthCookie } from './capacitorAuthService';

// Constants
import { USER_LOCAL_STORAGE_TOKEN_KEY } from './authConstants';

export const getCurrentUserToken = () => localStorageService.getItem(USER_LOCAL_STORAGE_TOKEN_KEY);

export const setCurrentUserToken = (value) => {
    localStorageService.setItem(USER_LOCAL_STORAGE_TOKEN_KEY, value);

    // Update token stored on Electron main process
    // eslint-disable-next-line no-unused-expressions
    window.desktopIntegration?.setMnToken?.(value);
};

export const removeCurrentUserToken = () => {
    localStorageService.removeItem(USER_LOCAL_STORAGE_TOKEN_KEY);

    // Update token stored on Electron main process
    // eslint-disable-next-line no-unused-expressions
    window.desktopIntegration?.setMnToken?.(undefined);

    removeCapacitorAuthCookie();
};
