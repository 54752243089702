// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from '../../../node_module_clones/recompose';
import { createStructuredSelector } from 'reselect';
import { get, identity } from 'lodash/fp';

// Utils
import { isGrammarlyError } from './grammarlyUtils';

// Selectors
import makeElementSelector, { getElement } from '../selectors/elementSelector';
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getCurrentBoardId } from '../selectors/currentBoardSelector';
import { currentBoardUserPermissionsSelector } from '../../utils/permissions/elementPermissionsSelector';

// Components
import DefaultErrorRenderer from '../../components/error/errorBoundary/DefaultErrorRenderer';
import GrammarlyErrorRenderer from '../../components/error/errorBoundary/GrammarlyErrorRenderer';
import DraggableElement from '../dnd/DraggableElement';

// Styles
import './ElementErrorRenderer.scss';

const getErrorDetails = ({ elementId, error }) => `Element ID: ${elementId}
Error: ${error && error.toString()}
Stack: ${get('stack', error)}`;

const ElementErrorRenderer = (props) => {
    if (isGrammarlyError(props.error)) {
        return <GrammarlyErrorRenderer {...props} getErrorDetails={getErrorDetails} />;
    }

    return (
        <DefaultErrorRenderer
            {...props}
            className="ElementErrorRenderer"
            heading={'Something went wrong within this card'}
            getErrorDetails={getErrorDetails}
        />
    );
};

ElementErrorRenderer.propTypes = {
    elementId: PropTypes.string,
    error: PropTypes.object,
};

const mapContainerStateToProps = createStructuredSelector({
    gridSize: getGridSize,
    currentBoardId: getCurrentBoardId,
    permissions: currentBoardUserPermissionsSelector,
    element: getElement,
});

let ElementContainerErrorRenderer = (props) => {
    const { connectDragSource, connectDragPreview, isDragging } = props;

    React.useEffect(() => {
        connectDragPreview && connectDragPreview(getEmptyImage());
    }, []);

    const wrapper = connectDragSource || identity;

    return wrapper(
        <div className={classNames('ElementContainerErrorRenderer', { dragging: isDragging })}>
            <ElementErrorRenderer {...props} />
        </div>,
    );
};

const enhance = compose(connect(mapContainerStateToProps), connect(makeElementSelector), DraggableElement);

ElementContainerErrorRenderer.propTypes = {
    isDragging: PropTypes.bool,
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
};

ElementContainerErrorRenderer = enhance(ElementContainerErrorRenderer);

const ElementErrorRendererSwitch = (props) =>
    props.isPresentational ? <ElementErrorRenderer {...props} /> : <ElementContainerErrorRenderer {...props} />;

ElementErrorRendererSwitch.propTypes = {
    isPresentational: PropTypes.bool,
};

// eslint-disable-next-line new-cap
export default ElementErrorRendererSwitch;
