import { useLayoutEffect } from 'react';

import usePrevious from '../../../../../../../utils/react/usePrevious';

import { COLOR_POPUP_MODE } from '../colorPopupConstants';

type UseActualColorPopupModeProps = {
    isPopupOpen: boolean;
    isBackgroundMode: boolean;
    supportColorBar: boolean;
    selectedColor: string | null;
    selectedBackgroundColor: string | null;
    setColorPopupMode: (mode: string) => void;
};

const useActualColorPopupMode = ({
    isPopupOpen,
    isBackgroundMode,
    supportColorBar,
    selectedColor,
    selectedBackgroundColor,
    setColorPopupMode,
}: UseActualColorPopupModeProps) => {
    const prevIsPopupOpen = usePrevious(isPopupOpen);

    let actualPopupMode;

    useLayoutEffect(() => {
        // on popup open
        const popupDidOpen = isPopupOpen && isPopupOpen !== prevIsPopupOpen;
        if (!popupDidOpen) return;

        if (isBackgroundMode && selectedColor && !selectedBackgroundColor) {
            // if currently open to background mode, set to color mode if there is a color set, but no background set
            setColorPopupMode(COLOR_POPUP_MODE.COLOR);
        } else if (!isBackgroundMode && !selectedColor) {
            // set to background mode
            setColorPopupMode(COLOR_POPUP_MODE.BACKGROUND);
        }
    }, [isPopupOpen, prevIsPopupOpen]);

    if (isBackgroundMode) {
        actualPopupMode = COLOR_POPUP_MODE.BACKGROUND;
    } else if (!isBackgroundMode && supportColorBar) {
        actualPopupMode = COLOR_POPUP_MODE.COLOR_BAR;
    } else {
        actualPopupMode = COLOR_POPUP_MODE.COLOR;
    }

    return actualPopupMode;
};

export default useActualColorPopupMode;
