// Utils
import { ImMap, prop } from '../../../utils/immutableHelper';
import { asNum, getX, getY } from '../dimensionUtil';
import { Rect } from './rectTypes';

type PartialRect = Partial<Rect> | ImMap<Rect>;

// Rectangle property accessors
export const getLeft = getX;
export const getTop = getY;
export const getRight = (rect: PartialRect): number =>
    asNum(prop('right', rect) || getLeft(rect) + prop('width', rect));
export const getBottom = (rect: PartialRect): number =>
    asNum(prop('bottom', rect) || getTop(rect) + prop('height', rect));

export const getWidth = (rect: PartialRect): number => asNum(prop('width', rect) || getRight(rect) - getLeft(rect));
export const getHeight = (rect: PartialRect): number => asNum(prop('height', rect) || getBottom(rect) - getTop(rect));

// Rectangle constructor
export const asRect = (rect: PartialRect): Rect => {
    const x = getX(rect);
    const y = getY(rect);

    return {
        x,
        y,
        top: y,
        right: getRight(rect),
        bottom: getBottom(rect),
        left: x,
        width: getWidth(rect),
        height: getHeight(rect),
    };
};
