// Lib
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getSelectedElementColorStatusClass, getSelectedElementType } from './colorToolUtils';
import { getColorToPersist } from '../../../../../../common/colors/elementColorFormatUtil';
import setTemporarySelectedElementsColorStyles from './setTemporarySelectedElementsColorStyles';

// Components
import ToolbarTool from '../../ToolbarTool';
import CustomColorInput from './CustomColorInput';
import ColorToolIcon from './ColorToolIcon';

// Actions
import { updateSelectedElementsColors } from './colorActions';

// Selectors
import { colorToolSelector } from './colorToolSelector';

// Styles
import './CustomColorTool.scss';

const mapDispatchToProps = (dispatch) => ({
    setTempColor: setTemporarySelectedElementsColorStyles,
    setColor: (colorValue) => {
        const color = getColorToPersist(colorValue);
        dispatch(updateSelectedElementsColors({ color, useExistingColorSpace: true }));
    },
});

const enhance = connect(colorToolSelector, mapDispatchToProps);

const CustomColorTool = (props) => {
    const { selectedElements, selectedColor, selectedColorHex, setColor, setTempColor } = props;
    const selectedType = getSelectedElementType(selectedElements);
    const selectedStatus = getSelectedElementColorStatusClass(selectedElements);

    const customColorInputRef = useRef(null);

    // On mobile for some reason it needs a long hold or double tap to open the color picker
    // This way it will open immediately
    const onClick = () => {
        customColorInputRef.current?.open();
    };

    return (
        <div className="CustomColorTool">
            <ToolbarTool {...props} name="Color" onClick={onClick}>
                <ColorToolIcon color={selectedColor} selectedType={selectedType} selectedStatus={selectedStatus} />
                <CustomColorInput
                    ref={customColorInputRef}
                    className="custom-color-picker-input"
                    initialColor={selectedColorHex}
                    setTempColor={setTempColor}
                    setColor={setColor}
                />
            </ToolbarTool>
        </div>
    );
};

CustomColorTool.propTypes = {
    selectedElements: PropTypes.object,
    selectedColor: PropTypes.string,
    selectedColorHex: PropTypes.string,
    setColor: PropTypes.func,
    setTempColor: PropTypes.func,
};

export default enhance(CustomColorTool);
