// Components
import getRangeIndex from './getRangeIndex';

// Utils
import {
    getElementId,
    getLocationParentId,
    getLocationSection,
} from '../../common/elements/utils/elementPropertyUtils';

// Constants
import { BoardSections } from '../../common/boards/boardConstants';

// FIXME This function gets called way more than it needs to be. It would be great to throttle this somehow.
//  NOTE: We cannot simply wrap in a rafThrottle (we tried this and broke sub-list dnd)
//      This is because the function is shared between every instance of the decorator.
//      It needs to be a throttled function *per* instance of the component.
//      I couldn't find a performant simple way of doing this.
export const listDropHoverFn = (props, monitor) => {
    if (!monitor?.canDrop()) return;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    if (!clientOffset) return;

    const {
        showPlaceholderForDraggedElement = false,
        getRangeArray,
        getContextZoomScale,
        getBoundingRectTop,
        getOffsetTop,
        getHoveredIndex,
        setHoveredIndex,
    } = props;

    const zoomScale = getContextZoomScale();

    // Determine rectangle on screen
    const { element, draggedElementIds, dragStartTimestamp } = monitor.getItem();

    const hoverBoundingRectTop = getBoundingRectTop(dragStartTimestamp);
    const offsetTop = getOffsetTop(dragStartTimestamp);

    // Get pixels to the top
    const hoverPositionFromListTop = clientOffset.y - hoverBoundingRectTop - offsetTop * zoomScale;

    const rangeArray = getRangeArray(dragStartTimestamp);

    // Find the index that the hovered item is currently hovering over
    let hoveredIndex = getRangeIndex(hoverPositionFromListTop, rangeArray, zoomScale, draggedElementIds);

    let showPlaceholder = true;

    if (draggedElementIds?.length === 1) {
        const hoveringItemId = getElementId(element);
        const hoveringItemParentId = getLocationParentId(element);
        const hoveringItemSection = getLocationSection(element);

        const { listId, childElementIds } = props;

        // If dragging an item from the current list, then check the index because
        // the placeholder should only be shown if its index will change.
        if (listId === hoveringItemParentId && hoveringItemSection === BoardSections.INBOX) {
            const currentIndex = childElementIds.reduce(
                (acc, childId, index) => (hoveringItemId === childId ? index : acc),
                null,
            );

            // If the index is the current position, or the next position (which would result in the same position
            // anyway) then don't show the placeholder, and just set the index to the current position so that the
            // move event won't fire. (There's no point firing a move event when nothing changes).
            if (currentIndex === hoveredIndex || currentIndex + 1 === hoveredIndex) {
                hoveredIndex = currentIndex;
                showPlaceholder = showPlaceholderForDraggedElement || false;
            }
        }
    }

    // Set on the item so that it can drop it at the correct index in the drop handler
    monitor.getItem().index = hoveredIndex;
    const currentHoveredIndex = getHoveredIndex();
    if (
        (!showPlaceholder && currentHoveredIndex === null) ||
        (showPlaceholder && hoveredIndex === currentHoveredIndex)
    ) {
        return;
    }

    // If the placeholder should be shown, set the hovered index, otherwise set it to null
    setHoveredIndex(showPlaceholder ? hoveredIndex : null);
};
