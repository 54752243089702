import { isNil } from 'lodash';
import { BaseEditor } from 'handsontable/editors';
import { EDITOR_STATE } from 'handsontable/editors/baseEditor';
import { convertToRaw } from 'draft-js';

// Utils
import rawGetText from '../../../../common/utils/editor/rawUtils/rawGetText';
import { isFormula } from '../utils/tableFormulaUtils';
import { parseCssPx } from '../../../utils/cssUtil';
import HotTableManager from '../manager/HotTableManager';
import { getCellBackgroundColor } from '../utils/tableCellFormattingUtils';
import { isSelectingSingleSelectionAndCell } from '../utils/tableCellSelectionUtils';

// Constants
import { KEY_CODES } from '../../../utils/keyboard/keyConstants';
import { stringifyCellContent } from '../../../../common/table/utils/tableCellContentStringUtils';

export const EDITOR_TYPE = 'milanote';

const SHORTCUTS_GROUP = 'milanoteCellEditor';

// From Handsontable
const SHORTCUTS_GROUP_BASE_EDITOR = 'baseEditor';
const SHORTCUTS_GROUP_NAVIGATION = 'editorManager.navigation';
const SHORTCUTS_GROUP_EDITOR_MANAGER = 'editorManager.handlingEditor';

class MilanoteCellEditor extends BaseEditor {
    static get EDITOR_TYPE() {
        return EDITOR_TYPE;
    }

    constructor(instance) {
        super(instance);

        this.content = null;
        this.manualRowResizePluginInstance = this.hot.getPlugin('manualRowResize');

        this.bindEvents();

        this.hot.addHookOnce('afterDestroy', this.destroy);
    }

    bindEvents() {
        this.addHook('afterScrollHorizontally', () => this.initEditorPositionAndSize());
        this.addHook('afterScrollVertically', () => this.initEditorPositionAndSize());

        this.addHook('afterColumnResize', () => this.initEditorPositionAndSize());
        this.addHook('afterRowResize', () => this.initEditorPositionAndSize());
        this.addHook('afterFormulasValuesUpdate', () => setTimeout(() => this.initEditorPositionAndSize()));
        this.addHook('onMilanoteCellEditorChange', (editorState) => this.onMilanoteCellEditorChange(editorState));
    }

    /**
     * 1. Called when a cell is selected (not in edit mode yet), to prepare it for edit mode
     */
    prepare(row, col, prop, TD, originalValue, cellProperties) {
        super.prepare(row, col, prop, TD, originalValue, cellProperties);

        this.EDITOR_POSITIONER = this.hot.rootElement.parentElement.querySelector('.handsontable-input-positioner');
        this.EDITOR_HOLDER = this.EDITOR_POSITIONER?.querySelector('.handsontable-input-holder');

        if (!this.EDITOR_POSITIONER || !this.EDITOR_HOLDER) return;

        this.EDITOR_HOLDER.style.backgroundColor = getCellBackgroundColor(cellProperties.cellData);

        this.initEditorPositionAndSize();
    }

    /**
     * 2. Called when the cell enters edit mode
     */
    beginEditing(initialValue, event) {
        if (
            !isSelectingSingleSelectionAndCell(this.hot.getSelected()) ||
            this.hot.milanoteProps.isResizing || // don't allow cell edits while resizing to avoid syncing/saving issues
            this.state !== EDITOR_STATE.VIRGIN
        )
            return;

        const { elementId } = this.hot.milanoteProps;

        super.beginEditing(initialValue, event);

        const hotTable = HotTableManager.getHotTableComponent(elementId);
        if (!hotTable) return;

        hotTable.startEditingCellEditor({ row: this.row, col: this.col });

        if (isNil(initialValue)) return;

        // If initialValue is defined, override the current editor value with initialValue
        const cellEditor = hotTable.cellEditorRef.current;
        if (!cellEditor) return;

        cellEditor.setPlainText(initialValue);

        // Trigger compositionstart event to make sure that the editor is in composition mode
        if (event && event.type === 'compositionstart') {
            const eventClone = new event.constructor(event.type, event);
            cellEditor.editor.editor.editor.dispatchEvent(eventClone);
        }
    }

    /**
     * 3. Called to set a value inside editor if required
     *
     *    Do nothing here because onMilanoteCellEditorChange will be called when milanote editor going to editing mode,
     *    which will sync the content of the editor to this class
     */
    setValue(value) {
        // Do nothing
    }

    /**
     * 4. Called right after beginEditing, to open the editor
     */
    open() {
        this.EDITOR_HOLDER.style.display = 'flex';

        // Hide contents of the current TD when it is being edited.
        // This is so that the editor can be resized properly.
        if (this.TD.firstChild) {
            this.TD.firstChild.classList.add('hidden');
        }

        const shortcutManager = this.hot.getShortcutManager();

        shortcutManager.setActiveContextName('editor');

        this.registerShortcuts();

        this.resizeRenderer({ forceResize: true });
    }

    /**
     * 5. Called by MilanoteCelLEditorComponent on every change to the editor. This is to sync the changes in this class
     */
    onMilanoteCellEditorChange(editorState) {
        this.setContent(editorState);

        this.resizeRenderer();
    }

    /**
     * 6. Called before the cell exits edit mode, the return value of it will be the value used by Handsontable in
     *    the beforeChange hook
     */
    getValue() {
        const editCellValue = this.getEditCellValue();
        if (isFormula(editCellValue)) {
            if (editCellValue.includes('(') && !editCellValue.includes(')')) {
                return editCellValue + ')';
            }

            return editCellValue;
        }

        return stringifyCellContent(this.content);
    }

    /**
     * 7. Called as user finish editing a cell
     */
    finishEditing(restoreOriginalValue, ctrlDown, callback) {
        // Just before the user finish editing, get the last updated editor state and save it to this.content,
        // this is to make sure that the latest changes are saved.
        const { elementId } = this.hot.milanoteProps;

        const hotTableComponent = HotTableManager.getHotTableComponent(elementId);
        if (!hotTableComponent?.cellEditorRef?.current) return;

        const editorState = hotTableComponent.cellEditorRef.current.getEditorState();

        // If the editor is in composition mode, we need to synchronously get it out of composition mode so
        //  the editor can be updated prior to "finishEditing" being called on the cell.
        // To exit composition mode, we need to simulate a key press, otherwise the editor will wait for
        //  a 20ms delay before exiting the composition mode, by using the right arrow key, the event will
        //  have its default prevented by the DraftEditorCompositionHandler.
        // Once this composition mode is exited, the MilanoteEditor will save the editor state to this.content
        //  via the "this.setEditorState" in the saveData, which is called from the onChange.
        if (editorState && editorState.isInCompositionMode()) {
            const cellEditor = hotTableComponent.cellEditorRef.current;

            const keyDownEvent = new KeyboardEvent('keydown', { which: KEY_CODES.RIGHT_ARROW });
            cellEditor.editor.editor._onKeyDown(keyDownEvent);
        } else {
            this.setContent(editorState);
        }

        super.finishEditing(restoreOriginalValue, ctrlDown, callback);

        hotTableComponent.finishEditingCellEditor();
    }

    /**
     * 8. Called when the cell exits edit mode
     */
    close() {
        this.EDITOR_HOLDER.style.display = 'none';
        if (this.TD.firstChild) {
            this.TD.firstChild.classList.remove('hidden');
        }

        this.content = null;

        this.unregisterShortcuts();

        // After closing the editor, restore the renderer size to fit the renderer content.
        // This is to cater for cases where the renderer displays a different content than the editor,
        // e.g. formulas, date type, etc.
        this.resizeRenderer({ newHeight: 0 });
    }

    cancelChanges() {
        this.restoreRendererSize();

        super.cancelChanges();
    }

    focus() {}

    destroy() {
        this.clearHooks();
    }

    /******************
     * HELPER FUNCTIONS
     ******************/

    setContent(editorState) {
        this.content = convertToRaw(editorState.getCurrentContent());
    }

    getEditCellValue() {
        return rawGetText(this.content);
    }

    initEditorPositionAndSize() {
        // This check is required as this.TD is used by this.getEditedCellRect(). Without this check,
        // this.getEditedCellRect() can sometimes crash the element.
        if (!this.TD) return;

        const editedCellRect = this.getEditedCellRect();
        if (!editedCellRect) return;

        const { showTitle, showCaption, isResizing, gridSize } = this.hot.milanoteProps;

        // user is unable to edit while resizing, so no point in repositioning the editor
        if (isResizing) return;

        const { top, start: left, width, maxWidth } = editedCellRect;

        const nRows = this.hot.countRows();
        const nCols = this.hot.countCols();

        const tdStyle = getComputedStyle(this.TD);

        this.showTitle = showTitle;
        this.showCaption = showCaption;
        this.isFirstRow = top <= 1;
        this.isLastRow = this.row === nRows - 1;

        // When the title is not showing, everything is shifted up 0.5px
        this.EDITOR_POSITIONER.style.top = `${this.isFirstRow ? 0 : top}px`;
        this.EDITOR_POSITIONER.style.left = `${left}px`;

        const hPadding = parseCssPx(tdStyle.paddingLeft) + parseCssPx(tdStyle.paddingRight) + 2; // Add 2 to account for inset borders
        const vPadding = parseCssPx(tdStyle.paddingTop) + parseCssPx(tdStyle.paddingBottom) + 2; // Add 2 to account for inset borders

        // min height for the editor is the inner height of the tallest OTHER cell in the row,
        // or 2 * gridSize if there is just 1 column
        const minHeight = Math.max(
            ...new Array(nCols).fill(1).map((_, col) => {
                if (col === this.col) return 0;

                const offset = this.row === 0 ? 1 : 0;
                const offsetHeight = this.hot.getCell(this.row, col)?.firstChild?.offsetHeight;
                return offsetHeight ? offsetHeight - offset : 0;
            }),
            2 * gridSize,
        );

        this.EDITOR_HOLDER.style.minHeight = `${minHeight}px`;
        this.EDITOR_HOLDER.style.width = `${Math.min(width, maxWidth) - hPadding}px`;

        // Adjust classnames to reflect first and last rows
        this.EDITOR_HOLDER.classList.remove('first-row', 'last-row');
        if (this.isFirstRow) this.EDITOR_HOLDER.classList.add('first-row');
        if (this.isLastRow) this.EDITOR_HOLDER.classList.add('last-row');

        this.initialHeight = this.EDITOR_HOLDER.offsetHeight - 1;

        // min height for editor including padding and border
        this.minTotalHeight = minHeight + vPadding - 1;
    }

    /**
     * @param newHeight - If undefined, will use the current height of the editor holder
     * @param forceResize - If true, will resize the renderer and rerender table even if
     *                           the new height is the same as the current height
     */
    resizeRenderer({ newHeight, forceResize = false } = {}) {
        const row = this.row;
        const currentHeight = this.TD.offsetHeight;

        // min height for editor including padding and border
        const minTotalHeight = this.minTotalHeight;

        // The following lines needs to be inside requestAnimationFrame to ensure
        // we get the latest EDITOR_HOLDER.offsetHeight
        requestAnimationFrame(() => {
            newHeight = newHeight ?? this.EDITOR_HOLDER.offsetHeight - 1;

            if (newHeight < minTotalHeight) newHeight = minTotalHeight;

            if (!forceResize && currentHeight === newHeight) return;

            this.manualRowResizePluginInstance.setManualSize(row, newHeight);

            if (this.hot && !this.hot.isDestroyed) this.hot.render();
        });
    }

    restoreRendererSize() {
        if (this.initialHeight !== null) this.resizeRenderer(this.initialHeight);
    }

    /**
     * Register shortcuts responsible for handling editor.
     *
     * @private
     */
    registerShortcuts() {
        const shortcutManager = this.hot.getShortcutManager();
        const editorContext = shortcutManager.getContext('editor');
        const contextConfig = {
            runOnlyIf: () => !!this.hot.getSelected(),
            group: SHORTCUTS_GROUP,
        };

        // Shift+Enter will be used by draft js editing to insert a line break
        editorContext.removeShortcutsByKeys(['shift', 'enter']);

        editorContext.addShortcuts(
            [
                {
                    keys: [['Tab']],
                    callback: (event) => {
                        const tableMeta = this.hot.getSettings();
                        const tabMoves =
                            typeof tableMeta.tabMoves === 'function' ? tableMeta.tabMoves(event) : tableMeta.tabMoves;

                        this.hot.selection.transformStart(tabMoves.row, tabMoves.col, true);
                    },
                },
                {
                    keys: [['Shift', 'Tab']],
                    callback: (event) => {
                        const tableMeta = this.hot.getSettings();
                        const tabMoves =
                            typeof tableMeta.tabMoves === 'function' ? tableMeta.tabMoves(event) : tableMeta.tabMoves;

                        this.hot.selection.transformStart(-tabMoves.row, -tabMoves.col);
                    },
                },
                {
                    keys: [['Control', 'Enter']],
                    callback: () => false, // Will block closing editor.
                    runOnlyIf: (event) =>
                        // Handsontable trigger a data population for multiple selection.
                        !this.hot.selection.isMultiple() &&
                        // catch CTRL but not right ALT (which in some systems triggers ALT+CTRL)
                        !event.altKey,
                    relativeToGroup: SHORTCUTS_GROUP_EDITOR_MANAGER,
                    position: 'before',
                },
                {
                    keys: [['Meta', 'Enter']],
                    callback: () => false, // Will block closing editor.
                    runOnlyIf: () => !this.hot.selection.isMultiple(), // Handsontable trigger a data population for multiple selection.
                    relativeToGroup: SHORTCUTS_GROUP_EDITOR_MANAGER,
                    position: 'before',
                },
                {
                    keys: [['Alt', 'Enter']],
                    callback: () => false, // Will block closing editor.
                    relativeToGroup: SHORTCUTS_GROUP_EDITOR_MANAGER,
                    position: 'before',
                },

                // Resize after undo
                {
                    keys: [['Control/Meta', 'Z']],
                    preventDefault: false,
                    callback: () => this.resizeRenderer(),
                },
                // Resize after redo
                {
                    keys: [['Control/Meta', 'Shift', 'Z']],
                    preventDefault: false,
                    callback: () => this.resizeRenderer(),
                },
            ],
            contextConfig,
        );

        const arrowContextConfig = {
            runOnlyIf: () => !!this.hot.getSelected() && !this.hot.getActiveEditor().isOpened(),
            group: 'editorManager.navigation.stopPropagation',
            position: 'after',
            relativeToGroup: 'editorManager.navigation',
        };

        editorContext.addShortcuts(
            [
                {
                    keys: [['ArrowUp'], ['ArrowRight'], ['ArrowDown'], ['ArrowLeft']],
                    callback: () => {
                        // Do nothing. This is to prevent the element from shifting around on canvas when using
                        // arrow keys to end editing state.
                    },
                    stopPropagation: true,
                },
            ],
            arrowContextConfig,
        );
    }

    /**
     * Unregister shortcuts responsible for handling editor.
     *
     * @private
     */
    unregisterShortcuts() {
        const shortcutManager = this.hot.getShortcutManager();
        const editorContext = shortcutManager.getContext('editor');

        editorContext.removeShortcutsByGroup(SHORTCUTS_GROUP_NAVIGATION);
        editorContext.removeShortcutsByGroup(SHORTCUTS_GROUP);
        editorContext.removeShortcutsByGroup(SHORTCUTS_GROUP_BASE_EDITOR);
    }
}

export default MilanoteCellEditor;
