import { get, sortBy, groupBy, flatMap, chunk, escapeRegExp } from 'lodash/fp';

import { CATEGORIES, PICKER_ROW_LENGTH, PICKER_ROW_TYPES } from './reactionPopupConstants';

export const getUnified = get('unified');

export const getCompleteEmojiCode = (emoji, skinTone) =>
    get(['skin_variations', skinTone, 'unified'], emoji) || getUnified(emoji);

export const getCategory = get('category');

export const getSkinToneCodes = (emojiData) => {
    const groupedEmoji = groupBy('category')(emojiData);
    const skinTones =
        groupedEmoji['Component'] || // new category name
        groupedEmoji['Skin Tones'] || // old category name
        []; // fallback: empty array, shouldn't happen

    return skinTones.map(getUnified);
};

export const getEmojiDataForCode = (emojiData, emojiCode) => emojiData.find((emoji) => getUnified(emoji) === emojiCode);

export const getEmojisRowData = (emojiData, searchFilter = '') =>
    flatMap(({ name, groups }) => {
        const groupedEmojis = groupBy('category')(emojiData);

        const searchFilterRegex = new RegExp(escapeRegExp(searchFilter), 'i');

        const matchEmojiFilter = (emoji) =>
            !![emoji.name, ...(emoji.short_names || [])].find((string) => string && string.match(searchFilterRegex));

        const allEmojisInCategory = flatMap((groupName) => sortBy('sort_order', groupedEmojis[groupName]))(
            groups,
        ).filter((emoji) => (searchFilter.length ? matchEmojiFilter(emoji) : true));

        const categoryRows = chunk(PICKER_ROW_LENGTH, allEmojisInCategory).map((emojis) => ({
            type: PICKER_ROW_TYPES.EMOJI,
            emojis,
        }));

        if (!categoryRows.length) return [];

        return [
            {
                type: PICKER_ROW_TYPES.TITLE,
                title: name,
            },
            ...categoryRows,
        ];
    }, CATEGORIES);
