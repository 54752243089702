// Constants
import { ELEMENT_FEATURES } from '../../client/element/feature/elementFeatureConstants';

// Element
export const ELEMENT_DUPLICATE = 'ELEMENT_DUPLICATE';

// User
export const FETCH_ME = 'FETCH_ME';
export const PASSWORD_RESET = 'PASSWORD_RESET';

export const USER_INVITE = 'USER_INVITE';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_REFERRAL = 'USER_REFERRAL';

export const USER_EMAIL_VERIFIED = 'USER_EMAIL_VERIFIED';

export const SUBSCRIPTION_START = 'SUBSCRIPTION_START';
export const SUBSCRIPTION_ADD = 'SUBSCRIPTION_ADD';
export const SUBSCRIPTION_REMOVE = 'SUBSCRIPTION_REMOVE';
export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';
export const SUBSCRIPTION_REQUEST_CANCEL = 'SUBSCRIPTION_REQUEST_CANCEL';
export const SUBSCRIPTION_RESUBSCRIBE = 'SUBSCRIPTION_RESUBSCRIBE';

// Mention
export const MENTIONED_A_USER = 'MENTIONED_A_USER';
export const MENTIONED_BY_USER = 'MENTIONED_BY_USER';

// Assign Tasks
export const ASSIGNED_TASK_TO_A_USER = 'ASSIGNED_TASK_TO_A_USER';
export const ASSIGNED_TASK_BY_A_USER = 'ASSIGNED_TASK_BY_A_USER';

// Session
export const SESSION_START = 'SESSION_START';

// Amplitude
export const AMPLITUDE_USER_PROPS = {
    FEATURE: 'Has used feature',
    EXAMPLE_BOARDS: 'Example boards viewed',
    EXAMPLE_BOARDS_FLAG: 'Example boards viewed',
    DEFAULT_CONTENT_LIMIT: 'Original content limit',
    CURRENT_CONTENT_LIMIT: 'Current content limit',
    MAX_REFERRAL_BONUS: 'Max referral bonus',
    GIFT_BONUS: 'Gift bonus',
    REGISTRATION_DATE: 'Registration date',
    REGISTRATION_MONTH: 'Registration month',
    REGISTRATION_AVENUE: 'Registration avenue',
    GUEST_AB_GROUP: 'Guest AB group',
    REGISTRATION_ENTRY_POINT: 'Registration entry point',
    INVITATION_DATE: 'Invitation date',
    INVITED_FROM_BOARD_FLAG: 'Invited from board',
    REGISTERED_ON_PUBLIC_EDIT_FLAG: 'Registered on public editable board',
    REGISTERED_ON_PUBLISHED_FLAG: 'Registered on published board',
    SUBSCRIPTION: 'Subscription',
    STATUS: 'Status',
    EMAIL: 'Email',
    GUEST_VIEWED_PUBLIC_BOARDS: 'Has viewed public boards as guest',
    REFERRED_A_FRIEND: 'Successfully referred a friend',
    REFERRED_BY_USER: 'Referred by user',
    REGISTERED_WITH_REFERRAL_CODE: 'Registered with referral code',
    REFERRAL_CAMPAIGN: 'Referral campaign',
    REFERRAL_URL: 'referrer',
    REFERRAL_DOMAIN: 'referring_domain',
    EMAIL_VERIFIED: 'Email verified',
    THEME: 'Theme',
    SHOW_GRID: 'Show grid',
    PUSH_CARDS: 'Push cards',
    TEMPLATE_PICKER: 'Enable template picker',
    DRAWING_INK_EFFECT: 'Enable drawing ink effect',
    THREE_D_EFFECTS: '3D effects',
    ANONYMOUS_VIEWING: 'Anonymous viewing',
    SANS_SERIF_HEADINGS: 'Disable serif headings',
    CREATE_NOTES_ON_DOUBLE_CLICK: 'Disable create Notes on double click',
    SHOW_UNDO_REDO_BUTTONS: 'Show undo redo buttons',
    LANGUAGE_PREFERENCE: 'Language preference',
    CURRENCY_PREFERENCE: 'Currency preference',

    EXPERIMENTS: 'Experiments',

    // domain subscription
    DOMAIN_SUBSCRIPTION_INVITED: 'Invited to domain subscription',
    DOMAIN_SUBSCRIPTION_CLAIMED: 'Claimed domain subscription',
    DOMAIN_SUBSCRIPTION_ID: 'Domain subscription id',
};

export const TRACKED_FEATURES = {
    DESKTOP_APP: 'Desktop app',
    PWA: 'PWA',
    IPHONE_APP: 'iPhone app',
    FILES: 'Files',
    COMMENTS: 'Comments',
    SEARCH: 'Search',
    BOARD_SEARCH: 'Board search',

    IMAGE_SEARCH: 'ImageSearch',

    FILE_UPLOAD_VIA_POPUP: 'File upload via popup',
    IMAGE_UPLOAD_VIA_POPUP: 'Image upload via popup',

    DOUBLE_CLICK_NOTE: 'Double click card creation',
    CMD_ENTER_NOTE: 'Cmd enter note',
    QUICK_LINE: 'Quick line',
    CHECKLIST: 'Checklist used',
    MARKDOWN: 'Markdown',
    EXPORTED_DOCUMENT: 'Exported document',
    ATTACHED_COMMENT_THREAD: 'Attached comment thread',
    COLLAPSED_COMMENT_THREAD: 'Collapsed comment thread',
    ATTACHED_LINE: 'Attached line',
    IMAGE_DRAWING: 'Image drawing',

    RESIZE_CARD: 'Resized card',
    CLONED_CARD: 'Cloned card',

    INVITED_USER: 'Invited user',
    SECRET_LINK: 'Secret link',
    EDITABLE_LINK: 'Editable link',

    TEMPLATE_PICKER: 'Template Picker',
    TEMPLATE_CUSTOM: 'Custom Template',

    PUSH_NOTIFICATIONS: 'Push notifications',
    RECEIVED_PUSH_NOTIFICATIONS: 'Received push notifications',
    CLICKED_PUSH_NOTIFICATIONS: 'Clicked push notifications',

    // Mentions
    MENTIONED_A_USER: 'Mentioned another user',
    MENTIONED_BY_USER: 'Mentioned by a user',

    // Assignments
    ASSIGNED_TASK_TO_A_USER: 'Assigned task to another user',
    ASSIGNED_TASK_BY_A_USER: 'Assigned task by a user',

    DUE_DATE: 'Due date',

    BOARD_BACKGROUND: 'Set board background',

    UI_V2: 'Visited UI V2',

    // Power ups
    [ELEMENT_FEATURES.DOCUMENT]: 'Document element',
    [ELEMENT_FEATURES.TRANSPARENT]: 'Transparent element',

    // Zoom
    ZOOM: 'Zoom',
    ZOOM_TO_FIT: 'Zoom to fit',

    AI: 'AI Ideas',

    CHECKOUT_V2: 'Checkout V2',

    CLIENT_LOCAL_CACHE: 'Client Local cache',
};

export const ANALYTICS_ZOOM_TYPES = {
    ZOOM_IN: 'zoom in',
    ZOOM_OUT: 'zoom out',
    RESET_ZOOM: 'Reset to 100%',
};
