// Lib
import { createSelector } from 'reselect';

// Utils
import { asObject } from '../../../common/utils/immutableHelper';

export const canvasZoomStateSelector = (state) => state.getIn(['app', 'canvas', 'zoom']);
export const canvasZoomModeSelector = (state) => state.getIn(['app', 'canvas', 'zoom', 'mode']);
export const canvasZoomScaleSelector = (state) => state.getIn(['app', 'canvas', 'zoom', 'scale']);
export const canvasZoomTranslationPxSelector = (state) => state.getIn(['app', 'canvas', 'zoom', 'translation']);
export const canvasZoomTargetScaleSelector = (state) => state.getIn(['app', 'canvas', 'zoom', 'targetScale']);

export const canvasZoomTranslationPxPojoSelector = createSelector(canvasZoomTranslationPxSelector, asObject);
