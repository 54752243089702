// Lib
import { AnyAction } from 'redux';

// Utils
import { isCurrentBrowserLogoutAction } from '../../../../../auth/authUtils';

// Reducers
import defaultAsyncResourceTypeReducer from './defaultAsyncResourceTypeReducer';
import boardsAsyncResourceReducer from './customReducers/boardsAsyncResourceReducer';
import elementsAsyncResourceReducer from './customReducers/elementsAsyncResourceReducer';
import trashAsyncResourceReducer from './customReducers/trashAsyncResourceReducer';

// Constants
import { LOGIN_SUBMIT, LOGOUT } from '../../../../../auth/authConstants';
import {
    RESOURCES_FETCH_ERROR,
    RESOURCES_FETCHED,
    RESOURCES_FETCHED_FROM_CACHE,
    RESOURCES_FETCHING,
    RESOURCES_MARKED_AS_STALE,
    RESOURCES_CACHED,
    RESOURCES_UPDATE_FETCHED_TIME,
} from '../asyncResourceConstants';

// Types
import { AsyncResourceReducerState, AsyncResourceType } from './asyncResourceReducerTypes';

const initialState: AsyncResourceReducerState = {};

// -------- Custom resource reducers - START ----------
// Reducers that are defined here can extend the default resource action handling
type CustomReducersMap = Map<string, (state: AsyncResourceType, action: AnyAction) => AsyncResourceType>;

const customReducersMap: CustomReducersMap = new Map();
customReducersMap.set('boards', boardsAsyncResourceReducer);
customReducersMap.set('elements', elementsAsyncResourceReducer);
customReducersMap.set('trash', trashAsyncResourceReducer);

// -------- Custom resource reducers - END ----------

const handleResourcesAction = (state: AsyncResourceReducerState, action: AnyAction): AsyncResourceReducerState => {
    const { resource } = action;

    const updatedState = { ...state };

    updatedState[resource] = defaultAsyncResourceTypeReducer(state[resource], action);

    return updatedState;
};

const defaultAsyncResourceReducer = (state = initialState, action: AnyAction): AsyncResourceReducerState => {
    switch (action.type) {
        case RESOURCES_FETCHING:
        case RESOURCES_FETCHED:
        case RESOURCES_CACHED:
        case RESOURCES_FETCHED_FROM_CACHE:
        case RESOURCES_UPDATE_FETCHED_TIME:
        case RESOURCES_FETCH_ERROR:
        case RESOURCES_MARKED_AS_STALE:
            return handleResourcesAction(state, action);
        default:
            return state;
    }
};

/**
 * Delegates any resources actions to the appropriate resource type reducer.
 */
export default (state = initialState, action: AnyAction): AsyncResourceReducerState => {
    // If there's a logout from the same browser, reset the state, otherwise ignore it
    if (action.type === LOGOUT) return isCurrentBrowserLogoutAction(action) ? initialState : state;

    // On login submit, reset the resources state in case they've tried to reach that board already
    // but been redirected to login because they're currently a guest user
    if (action.type === LOGIN_SUBMIT) return initialState;

    let updatedState = defaultAsyncResourceReducer(state, action);

    let hasCustomStateChanges = false;

    for (const customReducerKey of customReducersMap.keys()) {
        const customReducer = customReducersMap.get(customReducerKey);

        if (!customReducer) continue;

        const currentReducerState = updatedState[customReducerKey];

        const updatedReducerState = customReducer(currentReducerState, action);

        hasCustomStateChanges = hasCustomStateChanges || currentReducerState !== updatedReducerState;

        updatedState[customReducerKey] = updatedReducerState;
    }

    // Need to ensure the object reference changes when there's been changes
    if (hasCustomStateChanges) {
        updatedState = {
            ...updatedState,
        };
    }

    return updatedState;
};
