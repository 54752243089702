// Lib
import * as Immutable from 'immutable';

// Constants
import { PUSH_NOTIFICATIONS_SUGGESTION_SHOWN } from './pushSuggestionConstants';

const initialState = Immutable.Map({
    alreadySuggestedThisSession: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case PUSH_NOTIFICATIONS_SUGGESTION_SHOWN:
            return state.set('alreadySuggestedThisSession', true);
        default:
            return state;
    }
};
