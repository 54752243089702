// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SearchClearButton from '../../../../components/form/SearchClearButton';

// Styles
import './AssignmentSearchForm.scss';

const AssignmentSearchForm = (props) => {
    const { searchTerm, setSearchTerm } = props;

    const inputRef = React.useRef();

    React.useEffect(() => {
        setTimeout(() => {
            if (!inputRef || !inputRef.current) return;
            inputRef.current.focus();
        });
    }, []);

    const onSetSearchTerm = (event) => setSearchTerm(event.target.value);

    return (
        <div className="AssignmentSearchForm">
            <input
                ref={inputRef}
                tabIndex={0}
                className="input-field"
                placeholder="Search"
                value={searchTerm}
                onChange={onSetSearchTerm}
            />
            <SearchClearButton show />
        </div>
    );
};

AssignmentSearchForm.propTypes = {
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func,
};

export default AssignmentSearchForm;
