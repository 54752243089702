/**
 * This object can be used when characters need to be added to the next mounted editor, but
 * the editor is not available yet.
 * For example:  The document cards can accept key-presses which will open the card modal
 * and insert those characters into the modal editor.
 */

let typingBuffer: string = '';

export const clear = (): void => {
    typingBuffer = '';
};

export const addChar = (char: string) => {
    if (!char) return;

    typingBuffer += char;
};

export const hasChars = (): boolean => !!typingBuffer.length;

export const getChars = (): string => typingBuffer;
