// Lib
import * as Immutable from 'immutable';

import {
    ONBOARDING_SET_STEP,
    ONBOARDING_FINISH,
    ONBOARDING_REMOVE_LISTENER,
    ONBOARDING_ADD_LISTENER,
    ONBOARDING_SET_METADATA,
    ONBOARDING_STEPS_LOADED,
} from './onboardingConstants';

const initialState = Immutable.fromJS({
    currentStep: null,
    listeners: {},
    metadata: {},
    loaded: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case ONBOARDING_STEPS_LOADED:
            return state.set('loaded', true);
        case ONBOARDING_SET_STEP:
            return state.set('currentStep', action.step);
        case ONBOARDING_FINISH:
            return state.set('currentStep', null);
        case ONBOARDING_ADD_LISTENER:
            return state.setIn(['listeners', action.listener.id], Immutable.fromJS(action.listener));
        case ONBOARDING_REMOVE_LISTENER:
            return state.deleteIn(['listeners', action.listenerId]);
        case ONBOARDING_SET_METADATA:
            return state.set('metadata', state.get('metadata').merge(Immutable.fromJS(action.data)));
        default:
            return state;
    }
};
