import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-arrow-left"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path fill="#323B4A" d="m16 12-8 4 8 4v-3h7a1 1 0 0 0 .993-.883L24 16a1 1 0 0 0-.883-.993L23 15h-7v-3z" />
        </g>
    </svg>
);
const Memo = memo(ToolbarArrowLeftIcon);
export default Memo;
