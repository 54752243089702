// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Style
import './DetailView.scss';

const DetailView = React.forwardRef(function DetailView(props, ref) {
    const { className, style, previewElement, iconElement, secondaryElement, titleElement } = props;

    return (
        <div className={classNames('DetailView', className)} style={style} ref={ref}>
            {previewElement}
            <div className="metadata-container">
                <div className="icon-container">{iconElement}</div>

                <div className="details-container">
                    <div className="title-container">{titleElement}</div>
                    {secondaryElement && <div className="secondary-details-container">{secondaryElement}</div>}
                </div>
            </div>
        </div>
    );
});

DetailView.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    previewElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    iconElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    titleElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    secondaryElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DetailView;
