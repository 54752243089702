import useLocalStorageReducer from '../../../../utils/services/localStorage/useLocalStorageReducer';
import { CHECKOUT_STATE } from '../checkoutConstants';

export type CheckoutStoredState = {
    selectedProductId: string | undefined;
    selectedPriceId: string | undefined;
    quantity: number;
    selectedSeatCount: number | undefined;
    addMemberIds: string[];
    removeMemberIds: string[];
    initialMemberIds: string[];
};

type CheckoutAction = { type: 'update'; payload: Partial<CheckoutStoredState> } | { type: 'reset' };

const checkoutReducer = (state: CheckoutStoredState, action: CheckoutAction): CheckoutStoredState => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        case 'reset':
            return {
                ...state,
                // selectedProductId & selectedPriceId are not reset,
                // because they are used to persist the selected product and price across navigation back to the product selection page
                quantity: 1,
                selectedSeatCount: undefined,
                addMemberIds: [],
                removeMemberIds: [],
                initialMemberIds: [],
            };
        default:
            return state;
    }
};

const useCheckoutReducer = () => {
    const [
        {
            selectedProductId,
            selectedPriceId,
            quantity,
            selectedSeatCount,
            addMemberIds,
            removeMemberIds,
            initialMemberIds,
        },
        dispatch,
    ] = useLocalStorageReducer<CheckoutStoredState, CheckoutAction>(CHECKOUT_STATE, checkoutReducer, {
        selectedProductId: '',
        selectedPriceId: '',
        quantity: 1,
        selectedSeatCount: undefined,
        addMemberIds: [],
        removeMemberIds: [],
        initialMemberIds: [],
    });

    return {
        selectedProductId,
        selectedPriceId,
        quantity,
        selectedSeatCount,
        addMemberIds,
        removeMemberIds,
        initialMemberIds,
        update: (payload: Partial<CheckoutStoredState>) => dispatch({ type: 'update', payload }),
        reset: () => dispatch({ type: 'reset' }),
    };
};

export default useCheckoutReducer;
