import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuOrderBackIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-order-back"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M19.5 13.05a2.25 2.25 0 0 1 2.25 2.25v4.2a2.25 2.25 0 0 1-2.25 2.25h-4.2a2.25 2.25 0 0 1-2.25-2.25v-4.2a2.25 2.25 0 0 1 2.25-2.25h4.2zm0 1.5h-4.2a.75.75 0 0 0-.75.75v4.2c0 .414.336.75.75.75h4.2a.75.75 0 0 0 .75-.75v-4.2a.75.75 0 0 0-.75-.75zM8.7 2.25a2.25 2.25 0 0 1 2.25 2.25v4.2a2.25 2.25 0 0 1-2.25 2.25H4.5A2.25 2.25 0 0 1 2.25 8.7V4.5A2.25 2.25 0 0 1 4.5 2.25h4.2zm0 1.5H4.5a.75.75 0 0 0-.75.75v4.2c0 .414.336.75.75.75h4.2a.75.75 0 0 0 .75-.75V4.5a.75.75 0 0 0-.75-.75zm-1.2 9.3a.75.75 0 0 1 .75.75v.9c0 .58.47 1.05 1.05 1.05h.9a.75.75 0 1 1 0 1.5h-.9a2.55 2.55 0 0 1-2.55-2.55v-.9a.75.75 0 0 1 .75-.75zm7.2-6.3a2.55 2.55 0 0 1 2.55 2.55v.9a.75.75 0 1 1-1.5 0v-.9c0-.58-.47-1.05-1.05-1.05h-.9a.75.75 0 1 1 0-1.5h.9z"
        />
    </svg>
);
const Memo = memo(ContextMenuOrderBackIcon);
export default Memo;
