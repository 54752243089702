import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_TYPE,
    ELEMENT_UPDATE,
    ELEMENT_DIFF_UPDATE,
} from '../../common/elements/elementConstants';
import { ELEMENT_DUPLICATE } from '../../common/analytics/statsConstants';

export const FAKE_SAVE = 'FAKE_SAVE';

const initialState = null;

export default (state = initialState, action) => {
    if (action.type === FAKE_SAVE) return action.timestamp;

    if (!action.sync || !action.timestamp) return state;

    switch (action.type) {
        case ELEMENT_CREATE:
        case ELEMENT_DELETE:
        case ELEMENT_SET_TYPE:
        case ELEMENT_MOVE_AND_UPDATE:
        case ELEMENT_MOVE_MULTI:
        case ELEMENT_UPDATE:
        case ELEMENT_DUPLICATE:
        case ELEMENT_DIFF_UPDATE:
            return action.timestamp;
        default:
            return state;
    }
};
