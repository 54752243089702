// Lib
import { get } from 'lodash/fp';

// Utils
import getFileExtension from './getFileExtension';
import { lookupTypeDefinitionsByExtension } from './getFileType';
import { getMimeTypeSupportsMediaPlayer } from '../../client/element/file/utils/fileGetMediaPlayerType';

// Types
import { FileTypeInfo } from './fileTypes';

// Constants
import { FILE_TYPES_INFO } from './fileConstants';

export const fileTypeSupportsPreviews = (fileType: string): boolean =>
    get([fileType, 'preview'], FILE_TYPES_INFO) || false;

export const fileTypeSupportsForcePreviews = (fileType: string): boolean =>
    get([fileType, 'forcePreview'], FILE_TYPES_INFO) || false;

const getFileType = (filename: string): FileTypeInfo => {
    const extension = getFileExtension(filename);
    return lookupTypeDefinitionsByExtension(extension);
};

export const isPreviewableFile = (filename: string): boolean => {
    const fileType = getFileType(filename);
    return fileType && fileTypeSupportsPreviews(fileType.type);
};

export const isPreviewableFontFile = (filename: string): boolean => {
    const fileType = getFileType(filename);
    return fileType === FILE_TYPES_INFO.FONT;
};

export const isForcePreviewableFile = (filename: string): boolean => {
    const fileType = getFileType(filename);
    return fileType && fileTypeSupportsForcePreviews(fileType.type);
};

export const showFileInDetailView = (file: File): boolean => {
    return !!getMimeTypeSupportsMediaPlayer(file?.type) || isForcePreviewableFile(file?.name);
};
