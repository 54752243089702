import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorStroke1Icon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        className="Icon drawing-editor-stroke-1"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M14.389.387a.99.99 0 0 1-.107 1.327l-.088.075-2.857 2.112-1.508 1.13-.945.718-.69.529-.933.724-.837.66-.766.618-.72.597-.469.398-.465.407-.21.189-.383.351-.175.166-.322.317-.292.307a10.9 10.9 0 0 0-.273.306 4.865 4.865 0 0 0-.397.525 2.23 2.23 0 0 0-.107.18l-.026.053.045-.004a6.96 6.96 0 0 0 .995-.204l.21-.057.267-.077.558-.172.606-.202.692-.242 1.172-.43L9.157 9.63l.987-.357.159-.056c.189-.065.36-.122.528-.174l.357-.107c.262-.074.444-.118.658-.147l.202-.02a1.2 1.2 0 0 1 .42.042c.074.022.154.057.258.119.116.08.224.19.272.288a.848.848 0 0 1 .107.277c.019.088.022.15.019.253-.008.12-.028.213-.062.312l-.063.157c-.043.09-.089.173-.137.25-.05.078-.097.15-.15.221l-.17.224c-.064.079-.135.164-.218.262l-.227.256-.23.251-.374.394-.288.293-2.273 2.258-.138.14 1.797-.5.662-.173.38-.09.179-.038.343-.064c.057-.01.113-.02.17-.028l.344-.047a7.925 7.925 0 0 1 1.7-.033c.65.073 1.22.23 1.716.498.56.29 1.039.74 1.366 1.278.12.196.222.404.305.623l.076.222c.073.235.122.459.149.687l.018.23a.639.639 0 0 1-1.263.168l-.015-.105a2.538 2.538 0 0 0-.376-1.14 2.201 2.201 0 0 0-.875-.793c-.338-.177-.75-.284-1.23-.33a6.433 6.433 0 0 0-1.397.049c-.462.063-.935.167-1.53.331l-2.073.607-.303.083-.27.069-.27.062-.24.046-.206.03a1.99 1.99 0 0 1-.57.006 1.034 1.034 0 0 1-.454-.17l-.12-.09-.07-.071-.047-.057-.04-.063a.825.825 0 0 1-.096-.245l-.014-.103-.004-.061c0-.04.002-.074.007-.112l.035-.162.035-.1c.052-.124.102-.213.174-.32l.121-.17.172-.215.18-.213.206-.23.524-.557 2.045-2.087.39-.408.164-.178-.685.26-3.386 1.333-.755.288-.705.258c-.554.198-1.04.36-1.518.501l-.45.126c-.48.127-.824.193-1.24.232a2.853 2.853 0 0 1-.565-.009 1.74 1.74 0 0 1-.39-.097 1.231 1.231 0 0 1-.49-.335c-.357-.4-.48-.99-.36-1.48.078-.35.214-.647.422-.977.152-.243.323-.475.523-.716.28-.334.559-.639.883-.964l.342-.335.382-.36.434-.395.239-.215.475-.416.481-.413.752-.63.813-.665.902-.725L8.084 3.97l1.771-1.374 1.64-1.255L12.994.203a1.002 1.002 0 0 1 1.395.184z"
        />
    </svg>
);
const Memo = memo(DrawingEditorStroke1Icon);
export default Memo;
