import { FAILURE_TYPES, FAILURE_NAMES } from '../validationConstants';

const ERROR_OBJECT = {
    name: FAILURE_NAMES.required,
    type: FAILURE_TYPES.ERROR,
    hideBeforeSubmit: true,
    hideIfTouched: true,
};

/**
 * Returns an error object if a field's config states that the field is required, but the field does not have a value.
 * Otherwise, returns null.
 */
export default (fieldValue, validationConfig) =>
    validationConfig.required && !fieldValue ? { ...ERROR_OBJECT, fieldName: validationConfig.name } : null;
