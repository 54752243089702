// Lib
import { isEmpty, identity } from 'lodash/fp';

// Utils
import { getAsyncResourceEntityState } from '../../utils/services/http/asyncResource/asyncResourceSelector';
import { isAsyncEntityCached } from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Selectors
import { getIsClientPersistenceEnabledForCurrentUser } from '../feature/elementFeatureSelector';

// Service
import { fetchBoards } from './boardService';

// Actions
import { createBatchAction } from '../../store/reduxBulkingMiddleware';
import { loadElements } from '../../../common/elements/elementActions';
import {
    fetchedAsyncResourceFromCache,
    markAsyncResourceAsStale,
} from '../../utils/services/http/asyncResource/asyncResourceActions';

// Constants
import { SUPPORTS_PERSISTENCE_LAYER } from '../../offline/persistence/utils/persistenceLayerSupport';

// Types
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';

// Conditionally import the web worker only in environments that support it
const getPersistenceLayerWorkerInstance = SUPPORTS_PERSISTENCE_LAYER
    ? // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('../../offline/persistence/worker/persistenceLayerWorkerInstance').getPersistenceLayerWorkerInstance
    : identity;

const fetchingFromCacheSet = new Set();

const addToFetchingFromCacheSet = (boardIds: string[]) => {
    for (const boardId of boardIds) {
        fetchingFromCacheSet.add(boardId);
    }
};

const removeFromFetchingFromCacheSet = (boardIds: string[]) => {
    for (const boardId of boardIds) {
        fetchingFromCacheSet.delete(boardId);
    }
};

export const fetchBoardsFromLocalCache = (boardIds: string[]) => async (dispatch: Function, getState: Function) => {
    if (!SUPPORTS_PERSISTENCE_LAYER) return;

    const state = getState();

    const hasClientPersistence = getIsClientPersistenceEnabledForCurrentUser(state);

    if (!hasClientPersistence) return;

    // If a board is not pre-fetched, ignore it - let the standard fetch handle this
    const boardIdsToFetch = boardIds.filter((boardId) => {
        // Already fetching from the cache so don't try again
        if (fetchingFromCacheSet.has(boardId)) return false;

        const boardResourceState = getAsyncResourceEntityState(state, ResourceTypes.boards, boardId);
        return isAsyncEntityCached(boardResourceState);
    });

    if (isEmpty(boardIdsToFetch)) return;

    try {
        const persistenceLayerWorkerInstance = getPersistenceLayerWorkerInstance();

        addToFetchingFromCacheSet(boardIdsToFetch);

        // First see if the board is pre-fetched
        // If not, just use the standard fetchBoards method
        // TODO should probably have some sort of timeout for fetching from cache
        const { elements } = await persistenceLayerWorkerInstance.fetchBoards(boardIdsToFetch);

        const batchAction = createBatchAction({
            actions: [fetchedAsyncResourceFromCache(ResourceTypes.boards, boardIdsToFetch), loadElements(elements)],
        });

        dispatch(batchAction);
    } catch (error) {
        // Mark as stale so that the loading states show correctly (as it's no longer pre-fetched)
        dispatch(markAsyncResourceAsStale(ResourceTypes.boards, boardIdsToFetch));

        // If they don't exist - do a force fetch of the boards
        dispatch(
            fetchBoards({
                boardIds: boardIdsToFetch,
                force: true,
                canvasOrder: false,
            }),
        );
    } finally {
        removeFromFetchingFromCacheSet(boardIdsToFetch);
    }
};
