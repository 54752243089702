// Lib
import { values } from 'lodash';

// Constants
import { IMAGE_SIZES, IMAGE_WIDTH_THUMB } from '../images/imageConstants';
import { USER_AVATAR_SIZES } from '../users/userConstants';

export const IMAGE_TYPES = {
    ELEMENT: 'ELEMENT',
    LINK: 'LINK',
    AVATAR: 'AVATAR',
    BOARD: 'BOARD',
    BOARD_BACKGROUND: 'BOARD_BACKGROUND',
    FILE: 'FILE',
};

export const IMAGE_TYPE_CONFIGS = {
    [IMAGE_TYPES.ELEMENT]: {
        name: IMAGE_TYPES.ELEMENT,
        sizes: values(IMAGE_SIZES)
            .sort((sizeA, sizeB) => sizeA.width - sizeB.width)
            // Don't include the thumbnail in the resizing - that uses special rules
            .filter((size) => size.name !== 'thumb'),
        thumb: {
            enabled: true,
            width: IMAGE_WIDTH_THUMB,
        },
    },
    // TODO Figure out a better way to make this work with the new flow.. if possible
    [IMAGE_TYPES.LINK]: {
        name: IMAGE_TYPES.LINK,
        sizes: [
            { name: 'regular', width: 338, height: 190, quality: 80 },
            { name: 'large', width: 338 * 2, height: 190 * 2, quality: 60 },
        ],
        thumb: {
            enabled: true,
            width: IMAGE_WIDTH_THUMB,
        },
    },
    [IMAGE_TYPES.AVATAR]: {
        name: IMAGE_TYPES.AVATAR,
        sizes: [
            { name: 'small', width: USER_AVATAR_SIZES.SMALL * 2, quality: 90 },
            { name: 'regular', width: USER_AVATAR_SIZES.REGULAR * 2, quality: 90 },
            { name: 'large', width: USER_AVATAR_SIZES.LARGE * 2, quality: 90 },
            { name: 'original', width: Infinity, original: true },
        ],
        thumb: {
            enabled: false,
        },
    },
    [IMAGE_TYPES.BOARD]: {
        name: IMAGE_TYPES.BOARD,
        sizes: [
            { name: 'regular', width: 144, height: 144, quality: 75, enlargeIfRequired: true },
            { name: 'original', width: Infinity, original: true },
        ],
        thumb: {
            enabled: false,
        },
    },
    // This is used to ensure properties such as the primary colour of the image are determined
    [IMAGE_TYPES.BOARD_BACKGROUND]: {
        name: IMAGE_TYPES.BOARD,
        sizes: [{ name: 'original', width: Infinity, original: true }],
        thumb: {
            enabled: false,
        },
    },
    [IMAGE_TYPES.FILE]: {
        name: IMAGE_TYPES.FILE,
        sizes: [{ name: 'regular', width: 300 }, { name: 'large', width: 600 }, IMAGE_SIZES.ORIGINAL],
        thumb: {
            enabled: true,
            width: IMAGE_WIDTH_THUMB,
        },
    },
};
