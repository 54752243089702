// Lib
import * as Immutable from 'immutable';

// Constants
import { ENABLE_PREVIEW_MODE } from './boardPreviewConstants';

const initialState = Immutable.Map({
    enabled: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case ENABLE_PREVIEW_MODE:
            return state.set('enabled', true);
        default:
            return state;
    }
};
