import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GreenTickIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon green-tick"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#4EB85A" />
            <path fill="#FFF" d="m12 4.586-5 5-3-3L2.586 8l3.707 3.707a1 1 0 0 0 1.414 0L13.414 6 12 4.586z" />
        </g>
    </svg>
);
const Memo = memo(GreenTickIcon);
export default Memo;
