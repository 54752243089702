// Lib
import { defer } from 'lodash';
import { useMemo } from 'react';

// Utils
import { isTiptapEditorEmpty } from '../../../../common/tiptap/utils/isTiptapEditorEmpty';

// Hooks
import useCommonTiptapEditorExtensions from '../../../../common/tiptap/hooks/useCommonTiptapEditorExtensions';

// Custom extensions
import { MentionsSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';
import { KeyboardShortcuts } from '../../../../common/tiptap/extensions/KeyboardShortcuts';
import { CommentDocument } from '../../../../common/tiptap/extensions/CommentDocument';

// Types
import { Editor } from '@tiptap/react';
import { Extensions } from '@tiptap/core';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import { KeyboardShortcutCommand } from '@tiptap/core/src/types';
import { MentionPluginKey } from '@tiptap/extension-mention';

type UseCommentTiptapEditorExtensionsArgs = {
    placeholder: string | undefined;
    onEmptyBackspace: (() => void) | undefined;
    saveContent: (content: TiptapContent) => void;
    suggestionsManager: MentionsSuggestionsManager;
    isEditable?: boolean;
    dispatch?: Function;
    onSubmit?: (text: TiptapContent) => void;
};

/**
 * Gets the extensions for comment Tiptap editors.
 */
const useCommentTiptapEditorExtensions = ({
    placeholder,
    onEmptyBackspace,
    saveContent,
    suggestionsManager,
    isEditable,
    dispatch,
    onSubmit,
}: UseCommentTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useCommonTiptapEditorExtensions({
        saveContent,
        onEmptyBackspace,
        placeholder,
        suggestionsManager,
        isEditable,
        dispatch,
    });

    return useMemo(() => {
        const onReturn: KeyboardShortcutCommand = ({ editor }) => {
            if (isTiptapEditorEmpty(editor as unknown as Editor)) return false;

            // Don't submit the comment if the mention popup is active
            const mentionPluginState = MentionPluginKey.getState(editor.state);

            if (mentionPluginState.active) return false;

            // We need to defer the handling of this to prevent the Mousetrap
            //  event handler from being triggered - because the element would be
            //  editable and selected while the event gets propagated upwards
            defer(() => {
                const currentContent = editor.getJSON();
                onSubmit?.(currentContent as TiptapContent);
            });

            return !!onSubmit;
        };

        return [
            CommentDocument,
            ...commonExtensions,
            onSubmit &&
                KeyboardShortcuts.configure({
                    keyboardShortcuts: {
                        // Submit the caption on return or enter
                        Return: onReturn,
                        Enter: onReturn,
                    },
                }),
        ].filter(Boolean) as Extensions;
    }, [commonExtensions, suggestionsManager]);
};

export default useCommentTiptapEditorExtensions;
