// Lib
import { clamp } from 'lodash';

// Utils
import * as rectLib from '../../../common/maths/geometry/rect';

// Constants
import { ZOOM_PROPS } from './zoomConstants';

// Types
import { Rect, RectSize } from '../../../common/maths/geometry/rect/rectTypes';

/**
 * Uses the top left margin of the content and adds it to the bottom right to ensure that the margin is
 * maintained around all sides of the content.
 */
export const addZoomMarginsToCanvasElementsBoundingRect = (canvasElementsBoundingRect: Rect): Rect => {
    const xMargin = Math.min(canvasElementsBoundingRect.x, 300);
    const yMargin = Math.min(canvasElementsBoundingRect.y, 300);

    return rectLib.addMargins(
        {
            top: yMargin,
            left: xMargin,
            bottom: yMargin,
            right: xMargin,
        },
        canvasElementsBoundingRect,
    );
};

/**
 * Determines the content rectangle to use.
 * If the canvas elements bounding rectangle is empty, use the canvas document rectangle instead.
 */
export const getZoomContentRect = (
    canvasElementsBoundingRect: Rect,
    canvasDocumentSize: RectSize,
    canvasViewportClientRect?: Rect,
): Rect => {
    if (canvasElementsBoundingRect?.width && canvasElementsBoundingRect?.height) {
        const paddedCanvasElementsBoundingRect = addZoomMarginsToCanvasElementsBoundingRect(canvasElementsBoundingRect);

        if (!canvasViewportClientRect) return paddedCanvasElementsBoundingRect;

        return rectLib.asRect({
            x: paddedCanvasElementsBoundingRect.x,
            y: paddedCanvasElementsBoundingRect.y,
            width: Math.max(paddedCanvasElementsBoundingRect.width, canvasViewportClientRect.width),
            height: Math.max(paddedCanvasElementsBoundingRect.height, canvasViewportClientRect.height),
        });
    }

    // If there's the viewport, fallback to that
    if (canvasViewportClientRect) return rectLib.moveTo({ x: 0, y: 0 }, canvasViewportClientRect);

    // Otherwise fallback to the document size
    return rectLib.asRect(canvasDocumentSize);
};

/**
 * Determines the maximum scale to use based on the current scale and whether a new operation has started.
 * If we're < 100% then the max scale is 100%, otherwise it's 300%.
 */
const getMaxScaleLimit = (currentScale: number, isManualZoomOperation: boolean): number => {
    // On a manual zoom operation if we're currently at a scale of 1 we can go beyond that
    if (isManualZoomOperation) {
        return currentScale >= 1 ? ZOOM_PROPS.MAX_SCALE : 1;
    }

    // Otherwise we stay at 1 to prevent the scroll inertia on Macs from always scrolling beyond 1
    return currentScale <= 1 ? 1 : ZOOM_PROPS.MAX_SCALE;
};

/**
 * Determines the minimum scale required to get the longest dimension to show within the viewport.
 */
const getMinimumRatio = (canvasViewportClientRect: DOMRect, contentRectSize: RectSize): number =>
    Math.min(
        canvasViewportClientRect.height / contentRectSize.height,
        canvasViewportClientRect.width / contentRectSize.width,
    );

/**
 * Determines the minimum scale, considering the viewport and document sizes that will ensure that
 * the document can be displayed within the viewport, including some padding around the document, down
 * to a limit of 10%.
 */
export const getMinScale = (canvasViewportClientRect: DOMRect, contentRect: RectSize): number => {
    const hasMeasurements =
        canvasViewportClientRect?.height &&
        canvasViewportClientRect?.width &&
        contentRect?.height &&
        contentRect?.width;

    if (!hasMeasurements) return ZOOM_PROPS.MIN_SCALE;

    const minScale = getMinimumRatio(canvasViewportClientRect, contentRect);

    // Must be able to at least zoom out to 50% and no further than 10%
    return clamp(minScale, ZOOM_PROPS.MIN_SCALE, ZOOM_PROPS.MIN_SCALE_MAX);
};

/**
 * Determines the minimum scale to use based on the current scale and whether a new operation has started.
 * If we're > 100% then the min scale is 100%, otherwise it's the minimum scale for the zoomed out mode.
 */
const getMinScaleLimit = (
    currentScale: number,
    isManualZoomOperation: boolean,
    canvasViewportClientRect: DOMRect,
    canvasDocumentSize: RectSize,
    contentRect: Rect,
): number => {
    const minScale = getMinScale(canvasViewportClientRect, contentRect);

    // On a new zoom operation if we're currently at a scale of 1 we can go beyond that
    if (isManualZoomOperation) {
        return currentScale <= 1 ? minScale : 1;
    }

    // Otherwise we stay at 1 to prevent the scroll inertia on Macs from always scrolling beyond 1
    return currentScale >= 1 ? 1 : minScale;
};

/**
 * Determines if the zoom is at its limit depending on the current scale and the direction
 * that we're trying to scale.
 */
const hasScaleReachedLimit = (
    scrollDelta: number,
    existingScale: number,
    minScaleLimit: number,
    maxScaleLimit: number,
): boolean => {
    // Nothing to do if trying to zoom in when already fully zoomed in
    if (existingScale >= maxScaleLimit && scrollDelta <= 0) return true;

    // Nothing to do if trying to zoom out when already fully zoomed out
    if (existingScale <= minScaleLimit && scrollDelta >= 0) return true;

    return false;
};

/**
 * Determines the next scale to use based on the current state and scroll operation.
 *
 * This will limit the scale to two sections / zoom operations.
 *  - One mode is "zoom out" where you can go from 100% -> min scale, or min scale -> 100%, but no further
 *  - The next mode is "zoom in" where you can go from 100% -> 300%, or 300% to 100%, but no less
 *
 * To transition between these two modes a new zoom operation is required.
 * I.e. A pause between distinct manual zooms is required.
 */
export const getDesiredScaleFromScroll = (
    scrollDelta: number,
    currentTargetScale: number,
    currentScale: number,
    isManualZoomOperation: boolean,
    canvasViewportClientRect: DOMRect,
    canvasDocumentSize: RectSize,
    contentRect: Rect,
): number => {
    const maxScaleLimit = getMaxScaleLimit(currentScale, isManualZoomOperation);
    const minScaleLimit = getMinScaleLimit(
        currentScale,
        isManualZoomOperation,
        canvasViewportClientRect,
        canvasDocumentSize,
        contentRect,
    );

    const hasReachedLimit = hasScaleReachedLimit(scrollDelta, currentTargetScale, minScaleLimit, maxScaleLimit);

    if (hasReachedLimit) return currentTargetScale;

    const scaleDelta = 1 + scrollDelta * (-1 * ZOOM_PROPS.SCALE_SPEED_FACTOR);

    return clamp(currentTargetScale * scaleDelta, minScaleLimit, maxScaleLimit);
};
