// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FormPrimaryMessage from './FormPrimaryMessage';

// Styles
import './PrimaryErrorMessage.scss';

const PrimaryErrorMessage = ({ message, show, children, icon = 'cross-small' }) => (
    <FormPrimaryMessage className="PrimaryErrorMessage" message={message} show={show} icon={icon}>
        {children}
    </FormPrimaryMessage>
);

PrimaryErrorMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    show: PropTypes.bool,
    icon: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default PrimaryErrorMessage;
