import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarMediaPreviewIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-media-preview"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M23 8c1.105 0 2 1.018 2 2v12c0 .982-.895 2-2 2H9c-1.105 0-2-1.018-2-2V10c0-.982.895-2 2-2h14zm-9.248 4.068A.5.5 0 0 0 13 12.5v7a.5.5 0 0 0 .752.432l6-3.5a.5.5 0 0 0 0-.864z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarMediaPreviewIcon);
export default Memo;
