// Lib
import { isEmpty } from 'lodash';
import { CopyPaste } from 'handsontable/plugins';

// Utils
import { parseTableHTMLFromClipboardData } from '../utils/tableDataUtils';

/**
 * @plugin MilanoteCopyPastePlugin
 *
 */
class MilanoteCopyPastePlugin extends CopyPaste {
    /**
     * This is copied from Handsontable's CopyPaste.ts.
     *
     * Changes:
     * - Instead of Handsontable's method of parsing HTML table string, using a function
     *   consistent to how Milanote parse HTML table strings in other parts of the codebase.
     */
    onPaste(event: ClipboardEvent): void {
        if (!this.hot.isListening() || this.isEditorOpened()) return;

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        const { values } = (event.clipboardData && parseTableHTMLFromClipboardData(event.clipboardData)) || {};

        if (values === undefined || isEmpty(values)) return;

        if (this.hot.runHooks('beforePaste', values, this.copyableRanges) === false) return;

        const [startRow, startColumn, endRow, endColumn] = this.populateValues(values);

        this.hot.selectCell(
            startRow,
            startColumn,
            Math.min(this.hot.countRows() - 1, endRow),
            Math.min(this.hot.countCols() - 1, endColumn),
        );

        this.hot.runHooks('afterPaste', values, this.copyableRanges);
    }
}

export default MilanoteCopyPastePlugin;
