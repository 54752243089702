// Lib
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// Utils
import { isChallengeResponse } from './responseChallengeInterceptor';
import delay from '../lib/delay';

// Constants
// eslint-disable-next-line @typescript-eslint/naming-convention
import HttpStatus from 'http-status-codes';
import { ERROR_CODES } from './httpConstants';

const isServerError = (err: AxiosError) => err.response && err.response.status >= HttpStatus.INTERNAL_SERVER_ERROR;
const isTimeoutError = (err: AxiosError) =>
    err.code === ERROR_CODES.CONNECTION_ABORTED || err.code === ERROR_CODES.CONNECTION_RESET;
const isRetriableError = (err: AxiosError) =>
    isServerError(err) || isTimeoutError(err) || (err.response && isChallengeResponse(err.response));
const hasRetryConfig = (err: AxiosError) =>
    err.config && err.config.retry && err.config._retryCount !== err.config.retry;

const POWER = 3;
// milliseconds
const INITIAL_DELAY = 100;

// Server error delay will have an exponential back off
// 100ms, 800ms, 2.7s, 6.4s...
const getServerErrorDelay = (err: AxiosError) => INITIAL_DELAY * Math.pow(err.config?._retryCount || 0, POWER);
// Timeout error delay will multiply the timeout by the number of retries in case the server is overloaded
const getTimeoutErrorDelay = (err: AxiosError) => (err.config?.timeout || 1000) * (err.config?._retryCount || 0);
const getDelay = (err: AxiosError) => (isTimeoutError(err) ? getTimeoutErrorDelay(err) : getServerErrorDelay(err));

export default (axiosInstance: AxiosInstance, errorCb: (err: AxiosError) => void) =>
    (err: Error): Promise<AxiosInstance | AxiosResponse> => {
        if (!axios.isAxiosError(err)) throw err;

        // Don't retry if not configured to or it's not a retriable error
        if (!hasRetryConfig(err) || !isRetriableError(err)) throw err;
        if (!err.config) throw err;

        err.config._retryCount = err.config._retryCount || 0;
        errorCb && errorCb(err);

        err.config._retryCount = err.config._retryCount + 1;
        const delayTime = getDelay(err);

        return delay(delayTime).then(() => axiosInstance(err.config as AxiosRequestConfig));
    };
