// Actions
import { finishEditingElement } from '../../element/selection/selectionActions';

// Utils
import { dispatchSyntheticBrowserUndoRedoEvent } from '../../utils/undoRedo/undoRedoBrowserUtils';

// Constants
import { HYBRID_APP_UNDO, HYBRID_APP_REDO, HYBRID_APP_DID_HIDE_VIRTUAL_KEYBOARD } from './hybridAppStoreConstants';

export default (store) => (next) => (action) => {
    if (action.remote) return next(action);
    const { dispatch } = store;

    // eslint-disable-next-line default-case
    switch (action.type) {
        case HYBRID_APP_UNDO:
            dispatchSyntheticBrowserUndoRedoEvent({
                shiftKey: false,
            });
            break;
        case HYBRID_APP_REDO:
            dispatchSyntheticBrowserUndoRedoEvent({
                shiftKey: true,
            });
            break;
        // When hiding the virtual keyboard (and not transferring to the physical keyboard)
        // ensure that we're no longer in the editing state. Then copies will happen as expected.
        case HYBRID_APP_DID_HIDE_VIRTUAL_KEYBOARD:
            dispatch(finishEditingElement());
            break;
    }

    return next(action);
};
