// Lib
import { ContentState, EditorState } from 'draft-js';

/**
 * Adds new undo content state to editor state undo stack.
 */
export default (editorState: EditorState, newUndoContentState: ContentState): EditorState => {
    const currentUndoStack = editorState.getUndoStack();

    let newUndoStack = currentUndoStack.pop();

    newUndoStack = newUndoStack.push(newUndoContentState);

    // Need to update the current content selectionBefore to match the new undo stack's selection after
    // so that they match
    const newSelectionBefore = newUndoContentState.getSelectionAfter();

    let newContentState = editorState.getCurrentContent();
    // @ts-ignore This is correct but the types are wrong
    newContentState = newContentState.set('selectionBefore', newSelectionBefore);

    return EditorState.set(editorState, { currentContent: newContentState, undoStack: newUndoStack });
};
