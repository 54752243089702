// Lib
import { EditorState, Modifier } from 'draft-js';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../draftjsConstants';

// From https://github.com/ngs/draft-js-markdown-shortcuts-plugin
export default (editorState, type, text, blockMetadata = {}) => {
    if (!type) return editorState;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const removalRange = selection.merge({ anchorOffset: 0 });
    let newContentState = Modifier.removeRange(currentContent, removalRange, DraftRemovalDirection.forward);

    const key = selection.getStartKey();
    const blockMap = newContentState.getBlockMap();
    const block = blockMap.get(key);
    const data = block.getData().merge(blockMetadata);
    const newBlock = block.merge({ type, data });

    newContentState = newContentState.merge({
        blockMap: blockMap.set(key, newBlock),
    });

    return EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_BLOCK_TYPE);
};
