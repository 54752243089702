// Lib
import { useState } from 'react';

const useDrawingEditorSelectionState = () => {
    const [selectedPathIdsMap, setSelectedPathIdsMap] = useState({});

    const setSelectedPathIds = (pathIds = []) => {
        const newPathIdsMap = pathIds.reduce((acc, pathId) => {
            acc[pathId] = true;
            return acc;
        }, {});
        setSelectedPathIdsMap(newPathIdsMap);
    };

    const toggleSelectedPath = (pathId) => {
        const newSelectedPathIdsMap = { ...selectedPathIdsMap };

        if (selectedPathIdsMap[pathId]) {
            delete newSelectedPathIdsMap[pathId];
        } else {
            newSelectedPathIdsMap[pathId] = true;
        }

        setSelectedPathIdsMap(newSelectedPathIdsMap);
    };

    const clearSelectedPaths = () => setSelectedPathIdsMap({});

    return {
        selectedPathIdsMap,
        setSelectedPathIds,
        toggleSelectedPath,
        clearSelectedPaths,
    };
};

export default useDrawingEditorSelectionState;
