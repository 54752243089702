// Lib
import { compact } from 'lodash/fp';

// Utils
import {
    getIsLineStartSnapped,
    getLineStartConnectedElementId,
    getIsLineEndSnapped,
    getLineEndConnectedElementId,
} from '../../../../common/elements/utils/elementPropertyUtils';
import { asObject, prop, propIn } from '../../../../common/utils/immutableHelper';
import { getSnapValue } from '../../utils/elementSnapUtils';

// Geometry
import * as rectLib from '../../../../common/maths/geometry/rect';
import * as pointLib from '../../../../common/maths/geometry/point';
import { getBezierFromPoints } from '../../../../common/maths/geometry/bezierUtil';
import { getTargetIntersectionPoint } from './lineElementIntersectionUtil';
import { getHalfwayPoint } from './lineControlPointUtil';

// Constants
import { LINE_EDGE, LINE_MARKER_STYLE } from '../../../../common/lines/lineConstants';
import { LINE_COLUMN_ALIGNMENT_THRESHOLD, LINE_COLUMN_TITLE_VERTICAL_CENTRE } from '../lineUiConstants';
import { ElementType } from '../../../../common/elements/elementTypes';

const ELEMENT_LINE_MARGIN_PX = 1;

const isLineEdgeSnapped = (lineEdge) => !!prop('snapped', lineEdge);
export const isStartEdge = (edge) => edge === LINE_EDGE.start;
export const isEndEdge = (edge) => edge === LINE_EDGE.end;

export const getAlignedColumnLineOrigins = ({
    startEdge,
    startElementType,
    startElementRect,
    endEdge,
    endElementType,
    endElementRect,
    gridSize,
    startEdgeOrigin,
    endEdgeOrigin,
}) => {
    const returnObj = {
        startEdgeOrigin,
        endEdgeOrigin,
        aligned: false,
    };

    const shouldChangeOrigin =
        startElementType === endElementType &&
        startElementType === ElementType.COLUMN_TYPE &&
        !prop('fixed', startEdge) &&
        !prop('fixed', endEdge) &&
        startElementRect &&
        endElementRect;

    if (!shouldChangeOrigin) return returnObj;

    const yDiff = Math.abs(startElementRect.top - endElementRect.top);

    if (yDiff > LINE_COLUMN_ALIGNMENT_THRESHOLD * gridSize) return returnObj;

    // Align to a straight line
    const newTop = Math.max(startElementRect.top, endElementRect.top);

    return {
        startEdgeOrigin: {
            ...startEdgeOrigin,
            y: newTop + LINE_COLUMN_TITLE_VERTICAL_CENTRE * gridSize,
        },
        endEdgeOrigin: {
            ...endEdgeOrigin,
            y: newTop + LINE_COLUMN_TITLE_VERTICAL_CENTRE * gridSize,
        },
        aligned: true,
    };
};

/**
 * If the line has arrowheads, to prevent weird rendering issues when the arrowheads overlap,
 * or the line is shorter than the arrowhead, set a minimum length for the lines.
 */
export const getLineMinLength = (startStyle, endStyle, gridSize) => {
    const hasStartArrow = startStyle === LINE_MARKER_STYLE.ARROW;
    const hasEndArrow = endStyle === LINE_MARKER_STYLE.ARROW;

    if (hasStartArrow && hasEndArrow) return 1.5 * gridSize;
    if (hasStartArrow || hasEndArrow) return gridSize;

    return 1;
};

/**
 * Gets an array of the element IDs that the line is connected to.
 */
export const getLineConnectedElementIds = (line) =>
    compact([
        (getIsLineStartSnapped(line) && getLineStartConnectedElementId(line)) || null,
        (getIsLineEndSnapped(line) && getLineEndConnectedElementId(line)) || null,
    ]);

/**
 * Determines the connection point for the given dimension.
 * maxProp specifies the property of the connectedElementMeasurements to use as the max value
 * for the dimension.  E.g. The maximum value in the x direction is the width of the element's measurements.
 */
const getSnapDimension = (dimension, edgeProperty, maxProp) => (lineEdge, connectedElementMeasurements, gridSize) => {
    const edgeVal = prop(edgeProperty, lineEdge);
    const maxVal = prop(maxProp, connectedElementMeasurements);

    const snapVal = getSnapValue(edgeVal, 0, maxVal, gridSize);

    if (snapVal !== null) {
        // If the line is attached to the edge, then we should calculate the intersection, in case the
        // element is moved and the line is now in a new direction
        // Otherwise, the line should sit at the exact point that's been determined
        return snapVal;
    }

    // If the line sits at the centre point then we should determine the intersection
    return propIn(['originOffset', dimension], connectedElementMeasurements) + ELEMENT_LINE_MARGIN_PX;
};

const getSnapX = getSnapDimension('x', 'snapX', 'width');
const getSnapY = getSnapDimension('y', 'snapY', 'height');

/**
 * This will determine the connection point based on the properties of the line edge.
 *
 * The lineEdge is of the form { x, y, snapped, elementId }.
 * The x and y can be of the following forms:
 * - null or a number: This is the default & legacy format. In this case the value will be ignored
 *          and the originOffset (center) of the connected element's measurements will be used.
 * - '<number>%': This will position as a ratio on that dimension.
 * - '<number>rem': This will position in pixels, scaled to the grid.
 */
export const getConnectionOrigin = (lineEdge, connectedElementMeasurements, gridSize) => {
    const x = getSnapX(lineEdge, connectedElementMeasurements, gridSize);
    const y = getSnapY(lineEdge, connectedElementMeasurements, gridSize);

    return {
        x,
        y,
        fixed: prop('fixed', lineEdge),
    };
};

/**
 * The connection origin is in the element's coordinate system.
 * E.g. The center of a 100x200 rectangle would be 50x100.
 * We need to translate this position into the line's coordinate system, so we need to subtract the connected
 * element's offset from the origin.
 */
const getTranslatedConnectionOrigin = (
    lineEdge,
    lineElementPositionPx,
    connectedElementPositionPx,
    connectedElementMeasurements,
    gridSize,
) => {
    const connectionOrigin = getConnectionOrigin(lineEdge, connectedElementMeasurements, gridSize);

    const connectedElementPositionWithMarginPx = pointLib.difference(
        { x: ELEMENT_LINE_MARGIN_PX, y: ELEMENT_LINE_MARGIN_PX },
        connectedElementPositionPx,
    );

    // Find the difference between the line's grid position and the connected element's grid position
    const connectedElementOffset = pointLib.difference(lineElementPositionPx, connectedElementPositionWithMarginPx);

    return {
        ...connectionOrigin,
        ...pointLib.translate(connectedElementOffset, connectionOrigin),
    };
};

/**
 * The measurements map can be a frame old. To ensure the lines don't flash to the old measurements
 * we need to translate to the known new position (connectedElementPosition).
 * Thus we need to figure out the difference between the known position (connectedElementPositionPx) and
 * the stored measurements position (connectedElementMeasurementsTopLeft).
 * Usually this will be 0, but after an update it might be a frame behind and need to be updated.
 */
const getConnectedElementTranslation = (
    lineElementPositionPx,
    connectedElementPositionPx,
    connectedElementMeasurements,
) => {
    const connectedElementMeasurementsTopLeft = rectLib.getTopLeft(connectedElementMeasurements);

    // The difference between what the measurements should be vs what they currently are.
    const measurementsOffset = pointLib.difference(connectedElementPositionPx, connectedElementMeasurementsTopLeft);

    const translation = pointLib.translate(measurementsOffset, lineElementPositionPx);
    return pointLib.scale(-1, translation);
};

/**
 * Translates the line edge into an origin & rect (if the line edge is connected to an element)
 * for the line's coordinate system (treating the top left as 0, 0 px).
 * If the line isn't connected to an element, there will be no rect.
 */
export const getEdgeOriginAndRects = ({
    lineEdge,
    measurementsMap,
    connectedElementPosition,
    lineElementPosition,
    gridSize,
}) => {
    // If the line edge isn't snapped, then don't try to find the target rectangle
    if (!isLineEdgeSnapped(lineEdge)) {
        return {
            // Scale the edge into px
            origin: pointLib.scale(gridSize, asObject(lineEdge)),
            elementRect: null,
            rects: null,
        };
    }

    // The line's top left position in pixels
    const lineElementPositionPx = pointLib.scale(gridSize, asObject(lineElementPosition));
    const connectedElementPositionPx = pointLib.scale(gridSize, asObject(connectedElementPosition));

    // The measurements in px in the canvas coordinate system of the connected element
    const connectedElementId = prop('elementId', lineEdge);
    const connectedElementMeasurements = asObject(measurementsMap[connectedElementId]);

    // If we don't currently have measurements, then don't provide an origin (and the line will remain hidden)
    const noMeasurements =
        !lineElementPositionPx || !connectedElementMeasurements || !connectedElementMeasurements.rects;
    if (noMeasurements) {
        return {
            origin: null,
            elementRect: null,
            rects: null,
        };
    }

    // Shift up and to the left by a margin to sit on the element border correctly
    const connectedElementTranslation = getConnectedElementTranslation(
        lineElementPositionPx,
        connectedElementPositionPx,
        connectedElementMeasurements,
        gridSize,
    );
    let elementRect = rectLib.addPadding(ELEMENT_LINE_MARGIN_PX, ELEMENT_LINE_MARGIN_PX, connectedElementMeasurements);
    elementRect = rectLib.translate(connectedElementTranslation, elementRect);

    const paddedConnectedElementMeasurements = {
        ...connectedElementMeasurements,
        ...elementRect,
    };

    // Boards use two rects to determine where the line intersects to prevent the line from passing through the title.
    const rects = connectedElementMeasurements.rects.map((measurementRect) => {
        const paddedRect = rectLib.addPadding(ELEMENT_LINE_MARGIN_PX, ELEMENT_LINE_MARGIN_PX, measurementRect);
        return rectLib.translate(connectedElementTranslation, paddedRect);
    });

    // If the line edge is "fixed" or connecting to a board it won't use the centre of the rectangle
    const origin = getTranslatedConnectionOrigin(
        lineEdge,
        lineElementPositionPx,
        connectedElementPositionPx,
        paddedConnectedElementMeasurements,
        gridSize,
    );

    return {
        origin,
        elementRect,
        rects,
    };
};

/**
 * Based on the start and end edge properties (origin and rect) and the control point,
 * this function calculates the relevant start and endpoints for a line:
 * {
 *     start: { x, y },
 *     end: { x, y },
 *     startEdgeOrigin: { x, y },
 *     endEdgeOrigin: { x, y },
 *     startConnectionRects: [{ x, y, left, right, top, bottom }],
 *     endConnectionRects: [{ x, y, left, right, top, bottom }],
 *     controlPoint: { x, y },
 * }
 */
export const getLinePointsOfInterest = ({
    startEdgeOrigin,
    startConnectionRects,
    endEdgeOrigin,
    endConnectionRects,
    controlPoint,
}) => {
    if (!startEdgeOrigin || !endEdgeOrigin) return {};

    // If a control point isn't provided, we want to draw a straight line between the two points
    const isStraightLine = !controlPoint;
    let _controlPoint = controlPoint || getHalfwayPoint(startEdgeOrigin, endEdgeOrigin);

    const fullBezier =
        startEdgeOrigin && endEdgeOrigin ? getBezierFromPoints(startEdgeOrigin, _controlPoint, endEdgeOrigin) : null;

    // When we're simply intersecting a single rectangle, we want to use the closest intersection to the origin
    // This is because it allows us to curve the line in different ways and it feels a lot better with the control
    // point.
    // However, in the case that we have multiple rectangles (boards) this logic no longer holds up, because
    // using the closest intersection could cause the line to be drawn through the title.
    // What appears to be a better compromise here is to use the first intersection, so getRectsIntersection
    // will flip the value in this scenario.
    const startVisibleConnectionPoint = getTargetIntersectionPoint(
        startEdgeOrigin,
        startConnectionRects,
        fullBezier,
        true,
    );
    const endVisibleConnectionPoint = getTargetIntersectionPoint(endEdgeOrigin, endConnectionRects, fullBezier, false);

    let startVisibleT = startVisibleConnectionPoint.t || 0;
    let endVisibleT = endVisibleConnectionPoint.t || 1;

    const isLineCompletelyWithinStartRect =
        !startVisibleT &&
        startConnectionRects &&
        rectLib.anyRectsContainsPoint(startConnectionRects, startEdgeOrigin) &&
        rectLib.anyRectsContainsPoint(startConnectionRects, _controlPoint) &&
        rectLib.anyRectsContainsPoint(startConnectionRects, endEdgeOrigin);
    const isLineCompletelyWithinEndRect =
        endVisibleT === 1 &&
        endConnectionRects &&
        rectLib.anyRectsContainsPoint(endConnectionRects, startEdgeOrigin) &&
        rectLib.anyRectsContainsPoint(endConnectionRects, _controlPoint) &&
        rectLib.anyRectsContainsPoint(endConnectionRects, endEdgeOrigin);

    if (isLineCompletelyWithinStartRect) {
        startVisibleT = 1;
    }

    if (isLineCompletelyWithinEndRect) {
        endVisibleT = 0;
    }

    // Find the bezier curves based on the intersection points
    const startGhostBezier =
        (fullBezier && startVisibleT > 0) || isLineCompletelyWithinStartRect
            ? fullBezier.split(0, startVisibleT)
            : null;
    const visibleBezier =
        fullBezier && !isLineCompletelyWithinStartRect && !isLineCompletelyWithinEndRect
            ? fullBezier.split(startVisibleT, endVisibleT)
            : null;
    const endGhostBezier =
        (fullBezier && endVisibleT < 1) || isLineCompletelyWithinEndRect ? fullBezier.split(endVisibleT, 1) : null;

    // Make sure the control point is halfway between the visible connection points for straight lines
    if (isStraightLine) {
        _controlPoint = getHalfwayPoint(startVisibleConnectionPoint, endVisibleConnectionPoint);
    }

    return {
        start: startVisibleConnectionPoint,
        end: endVisibleConnectionPoint,
        startEdgeOrigin,
        endEdgeOrigin,
        startConnectionRects,
        endConnectionRects,
        controlPoint: _controlPoint,

        // Bezier curves
        fullBezier,
        visibleBezier,
        startGhostBezier,
        endGhostBezier,
    };
};

/**
 * Clears the line points and beziers if the line is not a minimum length.
 * Also sets the control point to halfway between the start and end points, if it's currently
 * halfway between the origins.
 */
export const enforceLineMinLength = (linePois, { minLength = 0 } = {}) => {
    const { start, startConnectionRects, end, endConnectionRects, controlPoint } = linePois;

    if (!start || !end) return linePois;

    const isControlPointWithinAnElement =
        rectLib.anyRectsContainsPoint(startConnectionRects, controlPoint) ||
        rectLib.anyRectsContainsPoint(endConnectionRects, controlPoint);

    // Estimation, not 100% accurate - the line might still be slightly visible even in this scenario
    const isBezierCovered =
        isControlPointWithinAnElement &&
        !start.fixed &&
        !end.fixed &&
        ((startConnectionRects && rectLib.anyRectsContainsPoint(startConnectionRects, end)) ||
            (endConnectionRects && rectLib.anyRectsContainsPoint(endConnectionRects, start)));

    // Ensure the line is visible, otherwise don't show it
    if (isBezierCovered) return {};

    const startEndDistSq =
        startConnectionRects || endConnectionRects ? pointLib.getDistanceSquared(start, end) : Infinity;
    const startControlDistSq = isControlPointWithinAnElement ? 0 : pointLib.getDistanceSquared(start, controlPoint);
    const endControlDistSq = isControlPointWithinAnElement ? 0 : pointLib.getDistanceSquared(controlPoint, end);

    const maxDist = Math.max(startEndDistSq, startControlDistSq, endControlDistSq);

    // Ensure the line is a minimum length, otherwise don't show it
    if (maxDist < minLength * minLength) return {};

    return linePois;
};
