// Lib
import { endsWith, flow, map, max, min, set } from 'lodash/fp';

// Utils
import { manuallyReportError } from '../../../client/analytics/rollbarService';
import { isColumn } from './elementTypeUtils';
import { getParent } from './elementTraversalUtils';
import { getLocationParentId, getLocationSection, getRootBoardProperty, getScore } from './elementPropertyUtils';
import { getTimestamp } from '../../utils/timeUtil';

// Constants
import { BOARD_ID_TRASH_APPEND, BoardSections } from '../../boards/boardConstants';
import { ROLLBAR_LEVELS } from '../../../client/analytics/rollbarConstants';

export const isChildOf = (parentId) => (element) => getLocationParentId(element) === parentId;

export const isLocationInSection = (section) => (element) => getLocationSection(element) === section;

export const isLocationMarkedAsDeleted = isLocationInSection(BoardSections.DELETED);
export const isLocationSpecificallyTrash = isLocationInSection(BoardSections.TRASH);
export const isLocationTrash = (element) => isLocationSpecificallyTrash(element) || isLocationMarkedAsDeleted(element);
export const isLocationAttached = isLocationInSection(BoardSections.ATTACHED);
export const isLocationCanvas = isLocationInSection(BoardSections.CANVAS);
export const isLocationInbox = isLocationInSection(BoardSections.INBOX);
export const isLocationRootBoard = (element) => getRootBoardProperty(element) === true;

export const isInColumn = (elementList) => (element) =>
    isLocationInbox(element) && isColumn(getParent(elementList, element));

export const isInUnsortedNotes = (elementList, currentBoardId) => (element) => {
    const parent = getParent(elementList, element);
    return (
        (isLocationInbox(element) && getLocationParentId(element) === currentBoardId) ||
        (isLocationInbox(parent) && getLocationParentId(parent) === currentBoardId)
    );
};

export const getHighestScore = (elements) => elements.length && flow(map(getScore), max)(elements);

export const getLowestScore = (elements) => elements.length && flow(map(getScore), min)(elements);

// Location structure functions
export const getListChildNewLocation = ({ listId, index = 0 }) => ({
    parentId: listId,
    section: BoardSections.INBOX,
    position: {
        index,
    },
});

export const getTrashId = (boardId) => `${boardId}${BOARD_ID_TRASH_APPEND}`;
export const isTrashId = endsWith(BOARD_ID_TRASH_APPEND);
export const removeTrashSuffix = (elementId) => elementId && elementId.replace(BOARD_ID_TRASH_APPEND, '');

/**
 * We've had an issue that occurs very infrequently where an element gets multiple "-trash" suffixes
 * added to its trash location.
 * This function attempts to identify these locations and send a warning message to Rollbar when it occurs.
 */
export const getTrashIdSafely = (boardId) => {
    const isCurrentBoardIdTrashId = isTrashId(boardId);

    // If we're somehow trying to add "-trash" to the end of a board ID that already has "-trash"
    // then something's gone wrong, so we want to find out how...
    if (isCurrentBoardIdTrashId) {
        const message = 'A "-trash" location is being added to a trash board ID';
        console.warn(message, boardId, Error().stack);
        manuallyReportError({
            errorMessage: message,
            level: ROLLBAR_LEVELS.WARNING,
            error: {
                code: 'CUSTOM',
                boardId,
                stack: Error().stack,
            },
        });

        // If running in development mode, pause execution so we can debug this issue
        if (process.env.APPLICATION_MODE === 'development') {
            // PLEASE TELL GLENN IF YOU ENCOUNTER THIS ISSUE
            // eslint-disable-next-line no-debugger
            debugger;
        }
    }

    return isCurrentBoardIdTrashId ? boardId : getTrashId(boardId);
};

export const getTrashLocation = ({ currentBoardId, addedDate = getTimestamp() }) => ({
    parentId: getTrashIdSafely(currentBoardId),
    section: BoardSections.TRASH,
    position: {
        addedDate,
    },
});

export const getDeleteLocation = ({ currentBoardId, deletedDate = getTimestamp() }) => ({
    parentId: getTrashIdSafely(currentBoardId),
    section: BoardSections.DELETED,
    position: {
        deletedDate,
    },
});

export const setMoveRestoreLocation = set(['location', 'position', 'restoreLocation']);
const setTrashAddedDate = set(['location', 'position', 'addedDate']);

export const addRestoreLocationForMovesToTrash = (move) =>
    isLocationTrash(move) ? setMoveRestoreLocation(move.from, move) : move;

export const addDateForMovesToTrash = (move) =>
    isLocationTrash(move) ? setTrashAddedDate(getTimestamp(), move) : move;
