// Constants
import { ActionMonitoringData, ActionType, ActionUser, LogAction } from '../actionTypes';

/**
 * An action that can be used to track certain operations via New Relic.
 */
export const monitorLogAction = ({
    user,
    operation,
    data,
}: {
    user: ActionUser;
    operation: string;
    data: ActionMonitoringData;
}): LogAction => ({
    type: ActionType.LOG,
    monitoring: {
        operation,
        ...data,
    },
    user,
});
