// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './CardCloneInstancesPopupContentInfo.scss';

const CardCloneInstancesPopupContentInfo = (props) => {
    const { children, className } = props;

    return <div className={classNames('CardCloneInstancesPopupContentInfo', className)}>{children}</div>;
};

CardCloneInstancesPopupContentInfo.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

export default CardCloneInstancesPopupContentInfo;
