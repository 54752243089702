// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { analyticsEvent } from '../../analytics/index';

// Measurements
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';

// Constants
import { ELEMENT_RESIZE_INTERPOLATION_FACTOR } from './store/resizingConstants';

export default ({ getMinWidth, getMinHeight, enableHeight }) =>
    (DecoratedComponent) => {
        class ElementResizeDecorator extends React.Component {
            constructor(props) {
                super(props);

                this.state = { size: null, isResizing: false };

                this.animationId = null;
            }

            componentWillUnmount() {
                // If we're currently animating - end that animation
                if (this.animationId) cancelAnimationFrame(this.animationId);
            }

            setTempElementSize = (size) => {
                if (!size) {
                    this.setState({ size: null });
                    return;
                }

                const newSize = {
                    width: Math.max(size.width, getMinWidth(this.props)),
                };

                if (enableHeight) {
                    const minHeight = getMinHeight ? getMinHeight(this.props) : 0;
                    newSize.height = Math.max(size.height, minHeight);
                }

                this.setState({ size: newSize });
                this.setIsResizing(true);
            };

            setElementSize = () => {
                const { size } = this.state;

                this.setIsResizing(false);
                analyticsEvent('resized-element');

                if (!size) return this.cleanupSetSize();

                // If we're currently animating - end that animation
                if (this.animationId) cancelAnimationFrame(this.animationId);

                const { width, height } = size;

                const animateSize = (from, to) => {
                    if (!from || !to) return this.cleanupSetSize();

                    const endAnimation =
                        Math.abs(to.width - from.width) < 0.1 &&
                        (!from.height || Math.abs(to.height - from.height) < 0.1);

                    if (endAnimation) return this.cleanupSetSize();

                    const newFrom = {
                        width: (to.width - from.width) * ELEMENT_RESIZE_INTERPOLATION_FACTOR + from.width,
                    };

                    if (from.height) {
                        newFrom.height = (to.height - from.height) * ELEMENT_RESIZE_INTERPOLATION_FACTOR + from.height;
                    }

                    this.setState({ size: newFrom });

                    requestAnimationFrame(() => animateSize(newFrom, to));
                };

                const targetSize = {
                    width: Math.round(width),
                    height: Math.round(height),
                };

                animateSize(size, targetSize);
            };

            cleanupSetSize = () => {
                this.setState({ size: null });
            };

            /**
             * A bit hacky, but prevents the need to pass a reference to the canvas down to every component.
             */
            setIsResizing = (isResizing) => {
                if (this.state.isResizing === isResizing) return;

                this.setState({ isResizing });
                const canvasViewportElement = measurementsRegistry.getCanvasViewportDomElement();
                this.state.isResizing
                    ? canvasViewportElement.classList.add('resizing-element')
                    : canvasViewportElement.classList.remove('resizing-element');
            };

            render() {
                const { size, isResizing } = this.state;

                return (
                    <DecoratedComponent
                        {...this.props}
                        tempSize={size}
                        isResizing={isResizing}
                        setElementSize={this.setElementSize}
                        setTempElementSize={this.setTempElementSize}
                    />
                );
            }
        }

        ElementResizeDecorator.propTypes = {
            dispatchUpdateElement: PropTypes.func,
        };

        return ElementResizeDecorator;
    };
