export const MEDIA_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
    RICH: 'RICH',
    MAP: 'MAP',
    DOC: 'DOC',
    PDF: 'PDF',
    NO_MEDIA: 'NO_MEDIA',
};

export const RESIZEABLE_MEDIA = [
    MEDIA_TYPES.IMAGE,
    MEDIA_TYPES.VIDEO,
    MEDIA_TYPES.AUDIO,
    MEDIA_TYPES.RICH,
    // TODO Remove map when migrations have occurred
    MEDIA_TYPES.MAP,
    MEDIA_TYPES.PDF,
];

export const FORCED_ASPECT_RATIO_MEDIA = [MEDIA_TYPES.IMAGE, MEDIA_TYPES.VIDEO];

export const RICH_MEDIA_MIN_HEIGHT = 8;
