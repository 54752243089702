import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

const PASSWORD_ERROR_OBJECT = {
    name: FAILURE_NAMES.password,
    type: FAILURE_TYPES.ERROR,
    debounced: true,
    message: 'Must be at least 6 characters',
};

// This should be more complex in the future, however the only validation we're currently doing is on field length
export default (fieldValue, validationConfig) => (fieldValue && fieldValue.length < 6 ? PASSWORD_ERROR_OBJECT : null);
