// Lib
import { curry } from 'lodash/fp';

// Utils
import * as lineLib from '../line';
import * as pointLib from '../point';
import { getBottom, getLeft, getRight, getTop } from './rectPropertyUtils';

// Constants
import { RECT_EDGES } from './rectConstants';

const makeGetLineFn = (getX1, getY1, getX2, getY2) => (rect) =>
    lineLib.getLine(pointLib.getPoint(getX1(rect), getY1(rect)), pointLib.getPoint(getX2(rect), getY2(rect)));

export const getTopLine = makeGetLineFn(getLeft, getTop, getRight, getTop);
export const getRightLine = makeGetLineFn(getRight, getTop, getRight, getBottom);
export const getBottomLine = makeGetLineFn(getLeft, getBottom, getRight, getBottom);
export const getLeftLine = makeGetLineFn(getLeft, getTop, getLeft, getBottom);

const RECT_SIDE_LINE_GETTERS = {
    [RECT_EDGES.top]: getTopLine,
    [RECT_EDGES.right]: getRightLine,
    [RECT_EDGES.bottom]: getBottomLine,
    [RECT_EDGES.left]: getLeftLine,
};

export const getEdgeLine = curry((side, rect) => {
    const lineGetterFn = RECT_SIDE_LINE_GETTERS[side];
    return lineGetterFn ? lineGetterFn(rect) : null;
});

export const getSides = (rect) => ({
    top: getTopLine(rect),
    right: getRightLine(rect),
    bottom: getBottomLine(rect),
    left: getLeftLine(rect),
});
