// Lib
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import classNames from 'classnames';

// Selector
import { getThemeIsDarkMode } from '../../user/account/accountModal/interface/themeSettings/themeSelector';

// Components
import iconsStyleLargeLight from './icons/large/light';
import iconsStyleLargeDark from './icons/large/dark';
import iconsStyleOutlinedLight from './icons/outlined/light';
import iconsStyleOutlinedDark from './icons/outlined/dark';
import iconStyleMiniLight from './icons/mini/light';
import iconStyleMiniDark from './icons/mini/dark';

// Constants
import { FILE_TYPES_INFO } from '../../../common/files/fileConstants';

// Types
import { FileTypeInfo } from '../../../common/files/fileTypes';

import './FileIcon.scss';

const getIconTypeThemeIndexes = (iconStyle: string | undefined) => {
    switch (iconStyle) {
        case 'large':
            return {
                light: iconsStyleLargeLight,
                dark: iconsStyleLargeDark,
            };
        case 'mini':
            return {
                light: iconStyleMiniLight,
                dark: iconStyleMiniDark,
            };
        case 'outlined':
        default:
            return {
                light: iconsStyleOutlinedLight,
                dark: iconsStyleOutlinedDark,
            };
    }
};

const mapStateToProps = (state: Immutable.Map<string, unknown>) => ({
    isDarkMode: getThemeIsDarkMode(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type FileIconProps = PropsFromRedux & {
    fileType: FileTypeInfo['type'];
    iconName?: string;
    iconTheme?: string;
    iconStyle?: string;
    audioOnly?: boolean;
    disabled?: boolean;
};

const FileIcon = ({
    fileType,
    iconName,
    isDarkMode,
    iconTheme,
    iconStyle = 'outlined',
    audioOnly,
    disabled,
}: FileIconProps) => {
    const fileTypeDefinition = audioOnly ? FILE_TYPES_INFO.AUDIO : FILE_TYPES_INFO[fileType] || FILE_TYPES_INFO.GENERIC;
    const iconFilename = iconName || fileTypeDefinition.iconName || 'file-file';
    const theme = iconTheme || (isDarkMode ? 'dark' : 'light') || 'light';

    const themeIndex = getIconTypeThemeIndexes(iconStyle)[theme];
    const IconComponent = themeIndex[iconFilename];

    if (!IconComponent) return null;

    return (
        <div className={classNames('FileIcon', { disabled })}>
            <IconComponent />
        </div>
    );
};

export default connector(FileIcon);
