// Lib
import { createSelector } from 'reselect';
import { getIsPublicTemplateChild } from '../../../common/templates/templateUtil';

export const getIsCurrentBoardIdInitialised = (state) => state.getIn(['app', 'currentBoardId', 'initialized']);

export const getIsCurrentBoardRestored = (state) => state.getIn(['app', 'currentBoardId', 'restored']);

/**
 * This can be useful for selectors that don't want to rely on props due to memoisation issues.
 *
 * For example - When performing a hovered drag and drop, the 'boardActivitySelector' was being calculated many
 * times as the selector was oscillating between the current board and the previous board.
 * I wasn't able to find the cause of the oscillation - but relying on the state alone seemed to solve the issue.
 */
export const getCurrentBoardIdFromState = (state) => state.getIn(['app', 'currentBoardId', 'current']);

export const getVisibleBoardId = (state) => state.getIn(['app', 'currentBoardId', 'visible']);

export const getCurrentBoardId = (state, props) => (props && props.currentBoardId) || getCurrentBoardIdFromState(state);

export const getCurrentVisibleBoardId = (state) => getVisibleBoardId(state) || getCurrentBoardIdFromState(state);

export const isShowingTemplateSelector = (state) =>
    !!getVisibleBoardId(state) && getVisibleBoardId(state) !== getCurrentBoardId(state);

export const getCurrentBoard = createSelector(
    (state) => state.get('elements'),
    getCurrentBoardId,
    (elements, currentBoardId) => elements.get(currentBoardId),
);

export const getVisibleBoard = (state) => state.getIn(['elements', getVisibleBoardId(state)]);

export const getCurrentVisibleBoard = createSelector(
    (state) => state.get('elements'),
    getCurrentVisibleBoardId,
    (elements, currentBoardId) => elements.get(currentBoardId),
);

/**
 * NOTE: At the moment, you will be viewing a public template only when the visible currentBoard ID is set.
 * If this assumption becomes incorrect, this selector will need to change.
 */
export const isViewingPublicTemplateSelector = (state) => {
    if (!getVisibleBoardId(state)) return false;

    const visibleBoard = getVisibleBoard(state);
    return getIsPublicTemplateChild(visibleBoard);
};
