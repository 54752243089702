import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';
import { isElementId } from '../../../uid/idValidator';

const ELEMENT_ID_ERROR_OBJECT = {
    name: FAILURE_NAMES.elementId,
    type: FAILURE_TYPES.ERROR,
    message: 'Not a valid elementId',
};

export default (fieldValue, validationConfig) => {
    if (!fieldValue) return;

    if (!isElementId(fieldValue)) return ELEMENT_ID_ERROR_OBJECT;

    return null;
};
