// Lib
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { getDrawingViewBox } from '../utils/drawingCanvasUtils';

// Components
import DrawingPath from './DrawingPath';
import OutlinedDrawingPath from './OutlinedDrawingPath';
import DrawingEditorCanvasSvg from './DrawingEditorCanvasSvg';

// Constants
import { DOM_POINTER_TYPES } from '../../../../utils/domConstants';

// Styles
import './DrawingEditorCanvasEraseMode.scss';

const DrawingEditorCanvasEraseMode = (props) => {
    const { paths, canvasScale, removePaths, debugUpdateAllStrokes, debugDrawingLastUpdateTime } = props;

    const [hoveredPathId, setHoveredPathId] = useState();

    const viewBox = getDrawingViewBox(props);

    return (
        <DrawingEditorCanvasSvg viewBox={viewBox} className="DrawingEditorCanvasEraseMode">
            {paths.map((path) => {
                const { id, ...rest } = path;

                const isHovered = id === hoveredPathId;

                const onPointerUp = (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setHoveredPathId(undefined);
                    removePaths([id]);
                };

                const onPointerEnter = (event) => {
                    const isHover =
                        event.buttons !== 1 &&
                        (event.pointerType === DOM_POINTER_TYPES.MOUSE || event.pointerType === DOM_POINTER_TYPES.PEN);

                    if (isHover) {
                        setHoveredPathId(id);
                        return;
                    }

                    removePaths([id]);
                };

                const onPointerLeave = (event) => {
                    setHoveredPathId(undefined);
                };

                return (
                    <React.Fragment key={id}>
                        <DrawingPath
                            onPointerUp={onPointerUp}
                            onPointerEnter={onPointerEnter}
                            onPointerLeave={onPointerLeave}
                            {...rest}
                            debugUpdateAllStrokes={debugUpdateAllStrokes}
                            debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
                        />
                        {isHovered && (
                            <OutlinedDrawingPath
                                className="deletable-hovered"
                                {...rest}
                                canvasScale={canvasScale}
                                debugUpdateAllStrokes={debugUpdateAllStrokes}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </DrawingEditorCanvasSvg>
    );
};

DrawingEditorCanvasEraseMode.propTypes = {
    paths: PropTypes.array,
    removePaths: PropTypes.func,

    canvasScale: PropTypes.number,
    viewBox: PropTypes.string,
    viewBoxX: PropTypes.number,
    viewBoxY: PropTypes.number,
    viewBoxWidth: PropTypes.number,
    viewBoxHeight: PropTypes.number,

    saveAndExitDrawingEditor: PropTypes.func,

    debugUpdateAllStrokes: PropTypes.bool,
    debugDrawingLastUpdateTime: PropTypes.number,
};

export default DrawingEditorCanvasEraseMode;
