// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Singleton
import zoomStateSingleton from '../../canvas/zoom/zoomStateSingleton';

// Utils
import { hasCommandKeyCode, hasCommandModifier } from '../../utils/keyboard/keyboardUtility';
import { asObject } from '../../../common/utils/immutableHelper';

export default (Component) => {
    class ElementResizeHandlePointer extends React.Component {
        componentWillUnmount() {
            if (this.attachedTouchHandlers) {
                document.removeEventListener('touchmove', this.onTouchMove, { passive: false });
                document.removeEventListener('touchend', this.onTouchEnd, { passive: false });
            }
        }

        /**
         * Prevent touch events from scrolling the canvas.
         */
        onTouchStart = (event) => {
            this.attachedTouchHandlers = true;
            event.preventDefault();
            event.stopPropagation();

            // Passive: false is important here, as this allows preventDefault to be called
            document.addEventListener('touchmove', this.onTouchMove, { passive: false });
            document.addEventListener('touchend', this.onTouchEnd, { passive: false });
        };

        onTouchMove = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        onTouchEnd = (event) => {
            // NOTE: We don't want to prevent default or stop propagation here, otherwise it will prevent
            //  The mouse/click events from running and it will stop the double click handler from working

            document.removeEventListener('touchmove', this.onTouchMove, { passive: false });
            document.removeEventListener('touchend', this.onTouchEnd, { passive: false });

            this.attachedTouchHandlers = false;
        };

        onPointerDown = (event) => {
            const { setTempElementSize, getCurrentMeasurements, elementId, setAllowAnyAspectRatio, onStartResize } =
                this.props;

            if (!setTempElementSize) return;
            if (event.button === 1 || event.button === 2) return;

            // Track simultaneous pointer events
            event.preventDefault();
            event.stopPropagation();

            this.initialMeasurements = getCurrentMeasurements(elementId);

            if (!this.initialMeasurements) return;

            this.currentWidth = this.initialMeasurements.get('width');
            this.currentHeight = this.initialMeasurements.get('height');
            this.startX = event.clientX;
            this.startY = event.clientY;

            document.addEventListener('pointermove', this.startDragging);
            setAllowAnyAspectRatio && document.addEventListener('keydown', this.onKeyDown);
            setAllowAnyAspectRatio && document.addEventListener('keyup', this.onKeyUp);
            document.addEventListener('pointerup', this.cancelDragging);

            onStartResize && onStartResize();
        };

        onPointerMove = (event) => {
            const { setTempElementSize, setAllowAnyAspectRatio } = this.props;

            // If we're performing a zoom operation, then don't handle the element resize
            if (zoomStateSingleton.getIsZooming()) return this.cancelPointerMoveDragging();

            setAllowAnyAspectRatio && setAllowAnyAspectRatio(hasCommandModifier(event));

            event.preventDefault();
            event.stopPropagation();

            this.currentX = event.clientX;
            this.currentY = event.clientY;

            setTempElementSize(this.getElementSize(event.clientX, event.clientY));
        };

        onKeyDown = (event) => {
            const { setAllowAnyAspectRatio } = this.props;

            setAllowAnyAspectRatio && setAllowAnyAspectRatio(hasCommandKeyCode(event));

            if (this.currentX && this.currentY) {
                const { setTempElementSize } = this.props;
                setTempElementSize(this.getElementSize(this.currentX, this.currentY));
            }
        };

        onKeyUp = (event) => {
            const { setAllowAnyAspectRatio } = this.props;
            hasCommandKeyCode(event) && setAllowAnyAspectRatio && setAllowAnyAspectRatio(false);
        };

        onPointerUp = (event) => {
            const { setElementSize, setAllowAnyAspectRatio, onEndResize } = this.props;

            event.preventDefault();
            event.stopPropagation();

            this.currentX = null;
            this.currentY = null;

            document.addEventListener('click', this.onClick, true);
            document.removeEventListener('pointermove', this.onPointerMove);
            document.removeEventListener('pointerup', this.onPointerUp);
            setAllowAnyAspectRatio && document.removeEventListener('keydown', this.onKeyDown);
            setAllowAnyAspectRatio && document.removeEventListener('keyup', this.onKeyUp);

            setElementSize(this.getElementSize(event.clientX, event.clientY));

            setAllowAnyAspectRatio && setAllowAnyAspectRatio(false);

            onEndResize && onEndResize();
        };

        /**
         * Prevent the click event from selecting the element.
         */
        onClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            document.removeEventListener('click', this.onClick, true);
        };

        onDoubleClick = (event) => {
            const { handleDoubleClick } = this.props;
            handleDoubleClick && handleDoubleClick(event);
        };

        getElementSize = (currentX, currentY) => {
            const { gridSize, getContextZoomScale } = this.props;

            const zoomScale = getContextZoomScale();

            // Transform the width change into grid size
            const widthChange = (currentX - this.startX) / (gridSize * zoomScale);
            const heightChange = (currentY - this.startY) / (gridSize * zoomScale);

            const currentWidth = this.currentWidth / gridSize;
            const currentHeight = this.currentHeight / gridSize;

            return {
                width: currentWidth + widthChange,
                height: currentHeight + heightChange,
            };
        };

        startDragging = (event) => {
            event.preventDefault();
            event.stopPropagation();

            document.removeEventListener('pointermove', this.startDragging);
            document.removeEventListener('pointerup', this.cancelDragging);

            document.addEventListener('pointermove', this.onPointerMove);
            document.addEventListener('pointerup', this.onPointerUp);
        };

        cancelDragging = () => {
            document.removeEventListener('pointermove', this.startDragging);
            document.removeEventListener('pointerup', this.cancelDragging);
        };

        /**
         * If a zoom operation starts while an element resize is occurring, we need to
         * cancel the element resize and clear the state.
         */
        cancelPointerMoveDragging = () => {
            const { elementId, setTempElementSize, setAllowAnyAspectRatio, onEndResize, dispatchMeasurementsSet } =
                this.props;

            this.currentX = null;
            this.currentY = null;

            document.removeEventListener('pointermove', this.onPointerMove);
            document.removeEventListener('pointerup', this.onPointerUp);
            setAllowAnyAspectRatio && document.removeEventListener('keydown', this.onKeyDown);
            setAllowAnyAspectRatio && document.removeEventListener('keyup', this.onKeyUp);

            this.cancelDragging();

            // Return the measurements back to what they were before the resize started
            dispatchMeasurementsSet({ id: elementId, measurements: asObject(this.initialMeasurements) });

            onEndResize && onEndResize();
            setTempElementSize(null);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    onTouchStart={this.onTouchStart}
                    onPointerDown={this.onPointerDown}
                    onDoubleClick={this.onDoubleClick}
                />
            );
        }
    }

    ElementResizeHandlePointer.propTypes = {
        children: PropTypes.node,
        gridSize: PropTypes.number,
        inList: PropTypes.string,
        showHandle: PropTypes.bool,
        setElementSize: PropTypes.func,
        setTempElementSize: PropTypes.func,
        elementId: PropTypes.string,
        getCurrentMeasurements: PropTypes.func,
        dispatchMeasurementsSet: PropTypes.func,
        handleDoubleClick: PropTypes.func,
        setAllowAnyAspectRatio: PropTypes.func,

        onStartResize: PropTypes.func,
        onEndResize: PropTypes.func,
        getContextZoomScale: PropTypes.func,
    };

    return ElementResizeHandlePointer;
};
