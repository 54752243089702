// Lib
import { EditorState, SelectionState, Modifier } from 'draft-js';

// Constants
import { EditorChangeType } from '../../draftjsConstants';
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

/**
 * Should only be called when a space is added after another space when within an inline code style.
 * This will change the previous space to be unstyled.
 */
export default (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const key = selection.getStartKey();
    const index = selection.getStartOffset();

    const block = currentContent.getBlockForKey(key);

    const currentInlineStyle = block.getInlineStyleAt(index - 1);
    const nextInlineStyle = block.getInlineStyleAt(index);

    // Don't remove the style if we're in the middle of a code block
    if (currentInlineStyle.has(STYLE_COMMANDS.CODE) && nextInlineStyle.has(STYLE_COMMANDS.CODE)) {
        return editorState;
    }

    const newStyle = nextInlineStyle.delete(STYLE_COMMANDS.UNSTYLED);

    const wordSelection = SelectionState.createEmpty(key).merge({
        anchorOffset: index - 1,
        focusOffset: index,
    });

    const newContentState = Modifier.replaceText(currentContent, wordSelection, ' ', newStyle);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_INLINE_STYLE);

    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};
