// Constants
import { STYLE_COMMANDS } from '../../../richText/richTextConstants';

const hasSelectionInCodeBlock = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);

    return currentBlock.getType() === STYLE_COMMANDS.CODE_BLOCK;
};

export default hasSelectionInCodeBlock;
