// Lib
import React, { ReactElement } from 'react';

// Utils
import { isCenteredTextAlignActive } from '../../../../../../common/tiptap/extensions/TextAlign';

// Hooks
import {
    ActiveTiptapEditorSelector,
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import createEditorTypeToggledComponent from './createEditorTypeToggledComponent';
import CenterTextRichTextToolContainer from '../richContentTools/CenterTextRichTextToolContainer';
import RichTextTool from '../richContentTools/RichTextTool';

// Types
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';

const isCenteredTextAlignButtonEnabled: ActiveTiptapEditorSelector = (editor) => editor?.can().setTextAlign('center');

const TiptapCenterTextTool = (props: ToolbarToolProps): ReactElement => {
    const isActive = useTiptapActiveEditorSelector(isCenteredTextAlignActive);
    const isEnabled = useTiptapActiveEditorSelector(isCenteredTextAlignButtonEnabled);

    const toggleCenter = useActiveTiptapEditorCallback((activeEditor, event: PointerEvent) => {
        if (!activeEditor) return;

        event.preventDefault();

        const currentlyActive = isCenteredTextAlignActive(activeEditor);

        currentlyActive ? activeEditor.commands.unsetTextAlign() : activeEditor.commands.setTextAlign('center');
    }, []);

    return (
        <RichTextTool
            isActive={isActive}
            disabled={!isEnabled}
            iconName={props.iconName || 'toolbar-center-text'}
            onPointerDown={toggleCenter}
            {...props}
        />
    );
};

export default createEditorTypeToggledComponent(TiptapCenterTextTool, CenterTextRichTextToolContainer);
