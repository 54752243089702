import React from 'react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Icon from '../icons/Icon';
import { ICON_SIZES } from '../icons/iconConstants';

import './ModalCloseButton.scss';

const ModalCloseButton = ({ close, disableClose, hideClose }) => {
    if (!close || disableClose || hideClose) return null;

    return (
        <Button onClickFn={close} className="ModalCloseButton">
            <Icon name="close-x" size={ICON_SIZES.LARGE} />
        </Button>
    );
};

ModalCloseButton.propTypes = {
    close: PropTypes.func,
    disableClose: PropTypes.any,
    hideClose: PropTypes.bool,
};

export default ModalCloseButton;
