// Utils
import editorIsOnly from '../../../customRichUtils/editorState/editorIsOnly';

export const isUncheckedChecklist = (text) => text === '[]' || text === '[ ]';
export const isCheckedChecklist = (text) => text === '[x]';

export const editorIsOnlyCheckedChecklist = editorIsOnly(isCheckedChecklist);

const isEmptyChecklist = (text) => isUncheckedChecklist(text) || isCheckedChecklist(text);

export default editorIsOnly(isEmptyChecklist);
