export const TOOLTIP_SHOW = 'TOOLTIP_SHOW';
export const TOOLTIP_HIDE = 'TOOLTIP_HIDE';

export const HIDDEN_POSITION = {
    top: -99999,
    left: -99999,
};

export enum TooltipPositions {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
    CENTER = 'center',
}

export enum TooltipAlignment {
    START = 'start',
    // Default
    CENTER = 'center',
    END = 'end',
}
