// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { round } from 'lodash';

// Utils
import { getPathStroke, getStrokeColor, getSvgPathFromStroke } from '../drawingEditorUtils';

const DrawingPath = (props) => {
    const {
        className,
        stroke,
        points,
        size,
        color,
        thinning,
        smoothing,
        streamline,
        hasPressure,
        debugUpdateAllStrokes,
        // eslint-disable-next-line no-unused-vars
        debugDrawingLastUpdateTime,
        ...rest
    } = props;

    const renderedStroke = debugUpdateAllStrokes
        ? getPathStroke({ points, size, thinning, smoothing, streamline, hasPressure })
        : stroke || getPathStroke({ points, size, thinning, smoothing, streamline, hasPressure });

    const strokeColor = getStrokeColor(color);

    // When there's no thinning we want to simply draw a path and use the stroke
    // to let the browser create a nicely rendered thick line
    if (thinning === 0) {
        return (
            <path
                className={classNames('DrawingPath', 'stroke-only', className)}
                stroke={strokeColor}
                strokeWidth={round(size, 1)}
                strokeLinecap="round"
                fill="none"
                d={getSvgPathFromStroke(renderedStroke, false)}
                {...rest}
            />
        );
    }

    return (
        <path
            className={classNames('DrawingPath', className)}
            fill={strokeColor}
            d={getSvgPathFromStroke(renderedStroke, true)}
            {...rest}
        />
    );
};

DrawingPath.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    stroke: PropTypes.array,
    points: PropTypes.array,
    size: PropTypes.number,
    thinning: PropTypes.number,
    smoothing: PropTypes.number,
    streamline: PropTypes.number,
    color: PropTypes.string,
    hasPressure: PropTypes.bool,
    isSelected: PropTypes.bool,

    debugUpdateAllStrokes: PropTypes.bool,
    debugDrawingLastUpdateTime: PropTypes.number,
    pathOnClick: PropTypes.func,
};

export default React.memo(DrawingPath);
