export enum FileTypes {
    GENERIC = 'GENERIC',
    IMAGE = 'IMAGE',
    RAW_IMAGE = 'RAW_IMAGE',
    SVG = 'SVG',
    ZIP = 'ZIP',
    HTML = 'HTML',
    CODE = 'CODE',
    PDF = 'PDF',
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
    MSG = 'MSG',
    EMAIL = 'EMAIL',
    TEXT = 'TEXT',
    CSV = 'CSV',
    DOCX = 'DOCX',
    PPTX = 'PPTX',
    XLSX = 'XLSX',
    PAGES = 'PAGES',
    KEYNOTE = 'KEYNOTE',
    NUMBERS = 'NUMBERS',
    SKETCH = 'SKETCH',
    AFDESIGN = 'AFDESIGN',
    PSD = 'PSD',
    LR = 'LR',
    PR = 'PR',
    AI = 'AI',
    IND = 'IND',
    AE = 'AE',
    XD = 'XD',
    MUSE = 'MUSE',
    DW = 'DW',
    AXURE = 'AXURE',
    FONT = 'FONT',
    NON_PREVIEWABLE_FONT = 'NON_PREVIEWABLE_FONT',
    MODEL_3D = 'MODEL_3D',
}

export interface FileTypeInfo {
    type: FileTypes;
    preview: boolean;

    mimePattern?: RegExp;
    mediaPlayerMimePattern?: RegExp;
    extensions?: Array<string>;
    iconName?: string;
    maxSize?: {
        free: number;
        pro: number;
    };
    forcePreview?: boolean;
}
