// Lib
import React from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';
import { MenuItem } from '../../../node_module_clones/react-contextmenu';

// Utils
import friendlyTimeFormatter from '../../../common/utils/datetime/friendlyTimeFormatter';
import { getDisplayName, getId } from '../../../common/users/userHelper';
import { getTimestamp, TIMES } from '../../../common/utils/timeUtil';

// Components
import FriendlyTimestamp from '../../components/datetime/FriendlyTimestamp';
import UserAvatar from '../../components/userAvatar/UserAvatar';

// Constants
import { USER_AVATAR_SIZES } from '../../../common/users/userConstants';

// Styles
import './ElementModificationContextMenuItems.scss';

const getDisplayUserName = (currentUserId, user) => (currentUserId === getId(user) ? 'you' : getDisplayName(user));

const ElementModificationContextMenuItems = (props) => {
    const { currentUserId, createdUser, modifiedUser, createdTime, modifiedTime } = props;

    const createdUserId = getId(createdUser);
    const modifiedUserId = getId(modifiedUser);

    const createdAndModifiedBySameUser = createdUserId === modifiedUserId;

    const currentTime = getTimestamp();

    const createdTimeString = friendlyTimeFormatter({ timestamp: createdTime, fromTimestamp: currentTime });
    const modifiedTimeString = friendlyTimeFormatter({ timestamp: modifiedTime, fromTimestamp: currentTime });
    const modifiedCloseToCreationTime =
        modifiedTime - createdTime < 5 * TIMES.MINUTE || createdTimeString === modifiedTimeString;

    const createdTimeFullTimestamp = dateformat(createdTime, 'd mmm yyyy - HH:MM');
    const modifiedTimeFullTimestamp = dateformat(modifiedTime, 'd mmm yyyy - HH:MM');

    const createdUserName = getDisplayUserName(currentUserId, createdUser);
    const modifiedUserName = getDisplayUserName(currentUserId, modifiedUser);

    if (createdAndModifiedBySameUser && modifiedCloseToCreationTime) {
        return (
            <MenuItem disabled>
                <UserAvatar user={createdUser} size={USER_AVATAR_SIZES.REGULAR_SMALL} />
                <div className="detail">
                    <div className="time-detail">Created by {createdUserName}</div>
                    <div className="time-detail">
                        <FriendlyTimestamp timestamp={modifiedTime} showSuffix title={modifiedTimeFullTimestamp} />
                    </div>
                </div>
            </MenuItem>
        );
    }

    if (createdAndModifiedBySameUser && !modifiedCloseToCreationTime) {
        const creationPrefix = createdTime > currentTime - 4 * TIMES.WEEK ? '' : ' on';
        const modificationPrefix = modifiedTime > currentTime - 4 * TIMES.WEEK ? '' : ' on';

        return (
            <MenuItem disabled>
                <UserAvatar user={createdUser} size={USER_AVATAR_SIZES.REGULAR_SMALL} />
                <div className="detail">
                    <div className="time-detail">
                        Created by {createdUserName}
                        {creationPrefix}&nbsp;
                        <FriendlyTimestamp timestamp={createdTime} showSuffix title={createdTimeFullTimestamp} />
                    </div>
                    <div className="time-detail">
                        Last modified{modificationPrefix}&nbsp;
                        <FriendlyTimestamp timestamp={modifiedTime} showSuffix title={modifiedTimeFullTimestamp} />
                    </div>
                </div>
            </MenuItem>
        );
    }

    return (
        <>
            {createdUser && (
                <MenuItem disabled>
                    <UserAvatar user={createdUser} size={USER_AVATAR_SIZES.REGULAR_SMALL} />
                    <div className="detail">
                        <span>Created by {createdUserName}</span>
                        {createdTime && <FriendlyTimestamp timestamp={createdTime} title={createdTimeFullTimestamp} />}
                    </div>
                </MenuItem>
            )}
            {modifiedUser && (
                <MenuItem disabled>
                    <UserAvatar user={modifiedUser} size={USER_AVATAR_SIZES.REGULAR_SMALL} />
                    <div className="detail">
                        <span>Modified by {modifiedUserName}</span>
                        {modifiedTime && (
                            <FriendlyTimestamp timestamp={modifiedTime} title={modifiedTimeFullTimestamp} />
                        )}
                    </div>
                </MenuItem>
            )}
        </>
    );
};

ElementModificationContextMenuItems.propTypes = {
    currentUserId: PropTypes.string,
    createdUser: PropTypes.object,
    modifiedUser: PropTypes.object,
    createdTime: PropTypes.number,
    modifiedTime: PropTypes.number,
};

export default ElementModificationContextMenuItems;
