// Constants
import * as SELECTION_ACTION_TYPES from '../../../../common/elements/selectionConstants';
import { ELEMENT_ATTACHMENT_ACCEPT_UNDO, ELEMENT_UPDATE } from '../../../../common/elements/elementConstants';
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';

export const addRedoActionProperties = (action) => ({
    ...action,
    isRedo: true,
});

const createElementUpdateRedoAction = (state, action) => {
    const redoAction = addRedoActionProperties(action);

    if (action.batchRedoActions && action.batchRedoActions.length > 0) {
        return {
            type: BATCH_ACTION_TYPE,
            payload: [redoAction, ...action.batchRedoActions.map((action) => ({ ...action, isRedo: true }))],
        };
    }

    return redoAction;
};

export default (state, action) => {
    switch (action.type) {
        // Once an attachment upload is undone, you can't redo it
        case ELEMENT_ATTACHMENT_ACCEPT_UNDO:
        // Don't redo element selections. This feels better because otherwise the user's cursor will get 'stuck' in editors
        // and the user will have to deselect the element in order to continue undoing
        // eslint-disable-next-line no-fallthrough
        case SELECTION_ACTION_TYPES.ELEMENTS_SELECTED:
            return null;
        case ELEMENT_UPDATE:
            return createElementUpdateRedoAction(state, action);
        default:
            return addRedoActionProperties(action);
    }
};
