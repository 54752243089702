import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ModalToolbarDrawIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon modal-toolbar-draw"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="m23.1 7.562.13.12 1.063 1.063c.8.8.894 2.052.249 2.959l-.108.14-1.682 2.019a2.485 2.485 0 0 1-.53 1.062l-.141.154-5.744 5.642a9.5 9.5 0 0 1-2.13 1.602l-.34.178-2.838 1.42a1.497 1.497 0 0 1-.544.152l-2.053.96a1 1 0 0 1-1.378-1.227l.045-.108.764-2.245a1.5 1.5 0 0 1 .095-.413l.058-.132 1.42-2.84a9.5 9.5 0 0 1 1.514-2.192l.265-.276 5.745-5.643a2.49 2.49 0 0 1 1.01-.615l2.162-1.8a2.3 2.3 0 0 1 2.969.02zm-3.76 3.373a1 1 0 0 0-1.225 0l-.095.083-5.744 5.643-.254.266a8 8 0 0 0-1.072 1.487l-.173.326-1.419 2.839 1 1 2.839-1.42.325-.172a8 8 0 0 0 1.488-1.072l.266-.254 5.744-5.643.084-.094a1 1 0 0 0 0-1.226l-.084-.094-1.585-1.586-.095-.083zm1.903-2.226-.087.062-1.046.87c.09.061.178.128.262.201l.123.115 1.586 1.586c.097.097.185.201.263.31l.86-1.033a.7.7 0 0 0 .027-.862l-.07-.082L22.1 8.814a.7.7 0 0 0-.856-.105z"
        />
    </svg>
);
const Memo = memo(ModalToolbarDrawIcon);
export default Memo;
