// Lib
import * as Immutable from 'immutable';

// Utils
import { isInstalledPwa } from '../pwaUtils';

// Constants
import { PWA_APP_INSTALLED } from './pwaAppConstants';

const initialState = Immutable.Map({
    isPwa: isInstalledPwa(),
});

export default (state = initialState, action) => {
    switch (action.type) {
        case PWA_APP_INSTALLED:
            return state.set('isPwa', true);
        default:
            return state;
    }
};
