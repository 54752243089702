// Lib
import * as Immutable from 'immutable';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from '../../../../node_module_clones/redux-logger/src';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { isGlobalDebugEnabled } from '../../../debug/debugUtil';
import { isLoggerDebugEnabled, LoggerComponents } from '../../../logger';
import getClientConfig from '../../../utils/getClientConfig';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

// Middleware
import addStateToWindowMiddleware from '../../../store/addStateToWindowMiddleware';
import editorActionsToSwiftMiddleware from './editorActionsToSwiftMiddleware';

// Reducer
import rootEditorStateReducer from './reducers/index';

// Constants
import { DEBUG_LEVEL } from '../../../../../config/configConstants';
import { EDITOR_STATE_CHANGED } from './editorConstants';

// Actions to filter from the logger
export const LOG_FILTER_ACTIONS = Immutable.Map({
    [EDITOR_STATE_CHANGED]: DEBUG_LEVEL.FINE,
});

export default function configureEditorStore(initialState = Immutable.Map()) {
    const middlewareList = [thunk];
    const config = getClientConfig();

    if (isLoggerDebugEnabled(LoggerComponents.REDUX_LOG_EDITOR)) {
        const filteredActions = LOG_FILTER_ACTIONS.filter((logLevel) => logLevel >= config.debugLevel)
            .keySeq()
            .toArray();

        middlewareList.push(
            createLogger({
                predicate: (getState, action) => filteredActions.indexOf(action.type) === -1,
                collapsed: true,
                duration: true,
                timestamp: false,
                colors: {
                    title: (action) => 'goldenrod',
                },
            }),
        );
    }

    if (isGlobalDebugEnabled()) {
        middlewareList.push(addStateToWindowMiddleware('editor_'));
    }

    // only add the editor action middleware when running in hybrid mode
    if (isPlatformLegacyMobileApp(platformSingleton)) {
        middlewareList.push(editorActionsToSwiftMiddleware);
    }

    const middleware = applyMiddleware.apply(null, middlewareList);

    return createStore(rootEditorStateReducer, initialState, middleware);
}
