// Lib
import { isEmpty } from 'lodash';

// Utils
import { getFeatureData, getIsFeatureEnabled } from './elementFeatureUtils';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { asObject } from '../../../common/utils/immutableHelper';

// Actions
import { updateMultipleElements } from '../actions/elementActions';

// Constants
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';

// Selectors
import { getSelectedRenderedElements } from '../selection/selectedElementsSelector';

export const toggleElementFeature = (feature, elements) => (dispatch, getState) => {
    let elementsToUpdate = elements;
    if (elementsToUpdate === undefined) {
        const state = getState();

        elementsToUpdate = asObject(getSelectedRenderedElements(state));
    }

    if (isEmpty(elementsToUpdate)) return;

    const firstElement = elementsToUpdate[0];
    const isEnabled = getIsFeatureEnabled(feature)(firstElement);

    // If we perform this operation as an object it will clear the other data (currently there is no other data)
    dispatch(
        updateMultipleElements({
            updates: elementsToUpdate.map((element) => {
                const otherChanges = {};

                return {
                    id: getElementId(element),
                    changes: {
                        [feature]: {
                            ...getFeatureData(feature)(element),
                            enabled: !isEnabled,
                        },
                        ...otherChanges,
                    },
                };
            }),
        }),
    );

    // Analytics
    const analyticsFeatureName = TRACKED_FEATURES[feature];
    if (analyticsFeatureName) {
        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.ELEMENT_UPDATE,
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [analyticsFeatureName]: true },
            },
        });
    }
};
