import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MilanoteLogoSmallIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        className="Icon milanote-logo-small"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={9} cy={9} r={9} fill="#1B2536" />
            <path
                fill="#FFF"
                d="M12 11.242 9.793 9.035 12 6.83v4.413zM6.83 12l2.205-2.207L11.243 12H6.829zm-.76-5.171 2.207 2.206-2.207 2.207V6.83zm6.67-1.788a.535.535 0 0 0-.584.116l-3.122 3.12-3.12-3.12A.536.536 0 0 0 5 5.535v7.005a.537.537 0 0 0 .15.367l.002.002.001.001.008.008.001.002h.002a.52.52 0 0 0 .367.151h7.009a.54.54 0 0 0 .531-.53V5.536a.536.536 0 0 0-.33-.495z"
            />
        </g>
    </svg>
);
const Memo = memo(MilanoteLogoSmallIcon);
export default Memo;
