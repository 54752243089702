// Lib
import detectIndent from 'detect-indent';

// For some reason \t results in errors within Draft... So sticking to spaces for now.
export const DEFAULT_INDENTATION = '    ';

const getIndentation = (text) => {
    const result = detectIndent(text);
    return result.indent || DEFAULT_INDENTATION;
};

export default getIndentation;
