/**
 * Returns the last n keys of an object.
 */
export const getLastNKeys = <V>(obj: { [key in string]: V }, n = 1) => {
    const keys = Object.keys(obj);
    if (keys.length <= n) return obj;

    return keys.slice(keys.length - n, keys.length).reduce(
        (acc, key) => ({
            ...acc,
            [key]: obj[key],
        }),
        {},
    );
};
