import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ColumnCollapseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon column-collapse"
        {...props}
    >
        <path
            fill="#1B2536"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5z"
        />
    </svg>
);
const Memo = memo(ColumnCollapseIcon);
export default Memo;
