// Lib
import { openDB } from 'idb';
import { isEmpty } from 'lodash';

// Utils
import logger from '../../../logger/logger';

// Constants
import { MNElement } from '../../../../common/elements/elementModelTypes';
import {
    MN_OBJECTS_DATABASE_NAME,
    MN_OBJECTS_DATABASE_VERSION,
    MnObjectsIdbElementsStoreIndexNames,
    MnObjectsIdbSchemaV1,
    MnObjectsIdbStoreNames,
} from './mnObjectsIdbTypes';

const LOG_PREFIX = `${MN_OBJECTS_DATABASE_NAME} (v${MN_OBJECTS_DATABASE_VERSION}): `;

export const createMnObjectsIdb = () => {
    return openDB<MnObjectsIdbSchemaV1>(MN_OBJECTS_DATABASE_NAME, MN_OBJECTS_DATABASE_VERSION, {
        upgrade: (upgradeDb) => {
            logger.info(`${LOG_PREFIX}Upgrading IndexedDB database to version ${MN_OBJECTS_DATABASE_VERSION}`);

            // Create the elements store, if it hasn't already been created
            if (!upgradeDb.objectStoreNames.contains(MnObjectsIdbStoreNames.elements)) {
                const elementsStore = upgradeDb.createObjectStore(MnObjectsIdbStoreNames.elements, { keyPath: '_id' });

                elementsStore.createIndex(MnObjectsIdbElementsStoreIndexNames.locationParentId, 'location.parentId', {
                    unique: false,
                });

                elementsStore.createIndex(MnObjectsIdbElementsStoreIndexNames.contentLinkTo, 'content.linkTo', {
                    unique: false,
                });
            }
        },
    });
};

let mnObjectsIdbPromise: ReturnType<typeof createMnObjectsIdb>;

export const initialiseMnObjectsIdb = () => {
    if (!mnObjectsIdbPromise) {
        mnObjectsIdbPromise = createMnObjectsIdb();
    }

    return mnObjectsIdbPromise;
};

const getMnObjectsIdb = () => {
    if (!mnObjectsIdbPromise) return initialiseMnObjectsIdb();
    return mnObjectsIdbPromise;
};

/**
 * Gets all elements from the "elements" store in mn-objects-idb.
 */
export const getAllElementsFromMnObjectsIdb = async () => {
    const db = await getMnObjectsIdb();
    return db.getAll(MnObjectsIdbStoreNames.elements);
};

/**
 * Uses a cursor to iterate over all elements in the "elements" store in mn-objects-idb in batches.
 */
export const getAllElementsInBatchesFromMnObjectsIdb = async (
    callback: (elements: MNElement[]) => void,
    batchSize = 1000,
) => {
    const db = await getMnObjectsIdb();

    const tx = db.transaction(MnObjectsIdbStoreNames.elements, 'readonly');

    let elements: MNElement[] = [];
    let i = 0;

    for await (const cursor of tx.store) {
        elements.push(cursor.value);
        i++;

        if (i === batchSize) {
            callback(elements);
            elements = [];
            i = 0;
        }
    }

    if (elements.length) {
        callback(elements);
    }
};

/**
 * Gets multiple elements from the "elements" store in mn-objects-idb.
 */
export const getElementsFromMnObjectsIdb = async (elementIds: string[]) => {
    if (isEmpty(elementIds)) return [];

    const db = await getMnObjectsIdb();

    const tx = db.transaction(MnObjectsIdbStoreNames.elements, 'readonly');

    const elementGetPromises = [];
    for (const elementId of elementIds) {
        elementGetPromises.push(tx.store.get(elementId));
    }

    const elements = await Promise.all(elementGetPromises);

    return elements;
};

/**
 * Add a single element to the "elements" store in mn-objects-idb.
 */
export const addElementToMnObjectsIdb = async (element: MNElement) => {
    const db = await getMnObjectsIdb();
    await db.put(MnObjectsIdbStoreNames.elements, element);
};

/**
 * Adds multiple elements to the "elements" store in mn-objects-idb.
 */
export const addMultipleElementsToMnObjectsIdb = async (elements: MNElement[]) => {
    if (isEmpty(elements)) return;

    const db = await getMnObjectsIdb();

    const tx = db.transaction(MnObjectsIdbStoreNames.elements, 'readwrite');

    const promises = [];
    for (const element of elements) {
        promises.push(tx.store.put(element));
    }
    promises.push(tx.done);

    await Promise.all(promises);
};

/**
 * Adds multiple elements to the "elements" store in mn-objects-idb.
 */
export const deleteMultipleElementsFromMnObjectsIdb = async (elementIds: string[]) => {
    if (isEmpty(elementIds)) return;

    const db = await getMnObjectsIdb();

    const tx = db.transaction(MnObjectsIdbStoreNames.elements, 'readwrite');

    const promises = [];
    for (const elementId of elementIds) {
        promises.push(tx.store.delete(elementId));
    }
    promises.push(tx.done);

    await Promise.all(promises);
};

/**
 * Clears all the elements from mn-objects-idb.
 */
export const clearAllElementsFromMnObjectsIdb = async () => {
    const db = await getMnObjectsIdb();
    await db.clear(MnObjectsIdbStoreNames.elements);
};
