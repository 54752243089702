// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

// Components
import DefaultErrorRenderer from '../../error/errorBoundary/DefaultErrorRenderer';

// Styles
import './PopupErrorRenderer.scss';

const getErrorDetails = ({ popupId, error }) => `Popup ID: ${popupId}
Error: ${error && error.toString()}
Stack: ${get('stack', error)}`;

const PopupErrorRenderer = (props) => (
    <DefaultErrorRenderer
        {...props}
        className="PopupErrorRenderer"
        heading="Something went wrong in this popup"
        getErrorDetails={getErrorDetails}
    />
);

PopupErrorRenderer.propTypes = {
    popupId: PropTypes.string,
    error: PropTypes.object,
};

export default PopupErrorRenderer;
