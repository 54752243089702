import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavQuickNotesIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon nav-quick-notes"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M16 1a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h8zm0 1.5H8A1.5 1.5 0 0 0 6.5 4v16A1.5 1.5 0 0 0 8 21.5h8a1.5 1.5 0 0 0 1.5-1.5V4A1.5 1.5 0 0 0 16 2.5zm-2.5 16a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1zm-.5-14a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1z"
        />
    </svg>
);
const Memo = memo(NavQuickNotesIcon);
export default Memo;
