const getInitialisationTime = (property) => (state) => state.getIn(['app', 'initialised', property]);
const isInitialised = (property) => (state) => getInitialisationTime(property)(state) > 0;

export const isCurrentUserInitialised = isInitialised('currentUser');
export const getCurrentUserInitialisationTimestamp = getInitialisationTime('currentUser');
export const areRemoteUsersInitialised = isInitialised('remoteUsers');
export const areAliasesInitialised = isInitialised('aliases');
export const areElementCountsInitialised = isInitialised('elementCounts');
export const areBoardSummariesInitialised = isInitialised('boardSummaries');
export const areBoardHierarchiesInitialised = isInitialised('boardHierarchies');
export const isSocketConnectionInitialised = isInitialised('socketConnection');
export const areCustomTemplatesInitialised = isInitialised('customTemplates');
export const isAppInitCompleted = isInitialised('appInit');
export const getAppInitTimestamp = getInitialisationTime('appInit');

export const getPageLoadTimestamp = getInitialisationTime('pageLoad');
export const getWorkspaceLoadIdleTimestamp = getInitialisationTime('workspaceLoadIdle');
