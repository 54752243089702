// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../../node_module_clones/recompose';

const getStyles = ({ depth }) => ({
    width: `${depth * 2.7}rem`,
});

const TaskDepthIndent = ({ depth }) => <div className="TaskDepthIndent" style={getStyles({ depth })} />;

TaskDepthIndent.propTypes = {
    depth: PropTypes.number,
};

export default pure(TaskDepthIndent);
