// Lib
import { EditorState, SelectionState } from 'draft-js';
import { EditorRange } from './editorContextTypes';

/**
 * Gets the context key from the current selection.
 */
export const getSimpleSelectionFromSelection = (selectionState: SelectionState): EditorRange => ({
    anchorKey: selectionState.getAnchorKey(),
    anchorOffset: selectionState.getAnchorOffset(),
    focusKey: selectionState.getFocusKey(),
    focusOffset: selectionState.getFocusOffset(),
});

/**
 * Gets the context key from the current selection.
 */
export const getSelectionEditorContextKey = (editorState: EditorState): string => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();
    const focusKey = selection.getFocusKey();

    return `${anchorKey}:${anchorOffset}-${focusKey}:${focusOffset}`;
};
