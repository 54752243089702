// Lib
import * as Immutable from 'immutable';

// Constants
import { DRAG_START, DRAG_END, REPLACE_MODE_END, REPLACE_MODE_START } from './draggingConstants';
import { DND_3D_EFFECTS_SET } from '../user/account/accountModal/interface/interfaceSettingsConstants';
import { USER_NAVIGATE } from '../../common/users/userConstants';
import { LINE_POINT_DRAG_START } from '../element/line/lineUiConstants';
import { ATTACH_MODE_END, ATTACH_MODE_START } from '../utils/dnd/dndConstants';

const initialState = Immutable.fromJS({
    isDragging: false,
    draggedIds: [],
    dropState: null,

    // Long hover image/file replacement state
    replaceModeHoveredElementId: null,

    // Line edge & attaching comments state
    attachMode: false,
    attachModeType: null,
    attachModeHoveredElementId: null,
    attachModeHoveredStack: Immutable.List(),
});

export default (state = initialState, action) => {
    switch (action.type) {
        case DRAG_START:
            return state.set('dropState', null).set('isDragging', true).set('draggedIds', Immutable.List(action.ids));
        case DRAG_END:
            return Immutable.fromJS({
                isDragging: false,
                draggedIds: [],
                dropState: action.dropState,

                replaceModeHoveredElementId: null,

                attachMode: false,
                attachModeType: null,
                attachModeHoveredElementId: null,
                attachModeHoveredStack: Immutable.List(),
            });
        case USER_NAVIGATE:
        case DND_3D_EFFECTS_SET:
            return state.set('dropState', null);
        case REPLACE_MODE_START:
            return state.set('replaceModeHoveredElementId', action.elementId);
        case REPLACE_MODE_END:
            return state.set('replaceModeHoveredElementId', null);
        // Line edge updates
        case ATTACH_MODE_START:
            return state
                .set('attachMode', true)
                .set('attachModeType', action.attachModeType)
                .set('attachModeHoveredElementId', action.hoveredElementId)
                .update('attachModeHoveredStack', (stack) => stack.push(action.hoveredElementId));
        case ATTACH_MODE_END: {
            let currentlyHoveredStack = state.get('attachModeHoveredStack');

            const currentlyHoveredStackIndex = currentlyHoveredStack.indexOf(action.hoveredElementId);
            currentlyHoveredStack =
                currentlyHoveredStackIndex !== -1
                    ? currentlyHoveredStack.delete(currentlyHoveredStackIndex)
                    : currentlyHoveredStack;

            const newHoveredId = currentlyHoveredStack.last();

            return state
                .set('attachMode', !!newHoveredId)
                .set('attachModeHoveredElementId', newHoveredId)
                .set('attachModeHoveredStack', currentlyHoveredStack);
        }
        case LINE_POINT_DRAG_START:
            return state
                .set('attachMode', false)
                .set('attachModeType', null)
                .set('attachModeHoveredElementId', null)
                .set('attachModeHoveredStack', Immutable.List());
        default:
            return state;
    }
};
