import { ELEMENT_MODAL_ELEMENT_ID_SET, ELEMENT_MODAL_ELEMENT_ID_CLEAR } from './elementModalConstants';

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_MODAL_ELEMENT_ID_SET:
            return action.elementId;
        case ELEMENT_MODAL_ELEMENT_ID_CLEAR:
            return initialState;
        default:
            return state;
    }
};
