import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CloseXSmallIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={9}
        height={9}
        viewBox="0 0 9 9"
        className="Icon close-x-small"
        {...props}
    >
        <g fill="#FFF" fillRule="evenodd">
            <path d="M7.496.592a.854.854 0 0 1 1.21.002c.335.335.332.88.002 1.21l-6.49 6.49a.854.854 0 0 1-1.21-.002.859.859 0 0 1-.002-1.21l6.49-6.49Z" />
            <path d="M1.006 1.804a.854.854 0 0 1 .002-1.21.859.859 0 0 1 1.21-.002l6.49 6.49a.854.854 0 0 1-.002 1.21.859.859 0 0 1-1.21.002l-6.49-6.49Z" />
        </g>
    </svg>
);
const Memo = memo(CloseXSmallIcon);
export default Memo;
