// Actions Types
export const EDITOR_STATE_CHANGED = 'EDITOR_STATE_CHANGED';
export const EDITOR_STYLE_COMMAND = 'EDITOR_STYLE_COMMAND';
export const EDITOR_COLOR_STYLE_COMMAND = 'EDITOR_COLOR_STYLE_COMMAND';
export const EDITOR_UPDATE_BLOCK_METADATA = 'EDITOR_UPDATE_BLOCK_METADATA';
export const EDITOR_ENTITY_CHANGE = 'EDITOR_ENTITY_CHANGE';
export const EDITOR_ENTITY_REMOVE = 'EDITOR_ENTITY_REMOVE';
export const EDITOR_CLEAR_UNDO_REDO_STACK = 'EDITOR_CLEAR_UNDO_REDO_STACK';

export const EDITOR_SELECTION_SAVE = 'EDITOR_SELECTION_SAVE';

export const EDITOR_UNMOUNT = 'EDITOR_UNMOUNT';
