import React from 'react';
import classNames from 'classnames';

import './CheckoutModalContent.scss';

type CheckoutModalContentProps = {
    className?: string;
    children: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
};

const CheckoutModalContent = ({
    className,
    children,
    header,
    footer,
}: CheckoutModalContentProps): React.ReactElement => {
    return (
        <div className={classNames('CheckoutModalContent', className)}>
            <div className="checkout-modal-header">{header}</div>
            <div className="checkout-modal-body">{children}</div>
            <div className="checkout-modal-footer">{footer}</div>
        </div>
    );
};

export default CheckoutModalContent;
