// Enums
export enum CellTypeNames {
    AUTO = 'Auto',
    NUMBER = 'Number',
    CURRENCY = 'Currency',
    PERCENTAGE = 'Percentage',
    TEXT = 'Text',
    DATE_TIME = 'DateTime',
    CHECKBOX = 'Checkbox',
    // RATING = 'Rating',
    // PROGRESS = 'Progress',
}

// Valid type object names, which just excludes auto as we never actually create an auto type object
export type CellTypeObjectNames = Exclude<CellTypeNames, CellTypeNames.AUTO>;

export enum FormatOptions {
    DECIMALS = 'decimals',
    THOUSANDS_SEPARATOR = 'thousandsSeparator',
    ACCOUNTING = 'accounting',
    CURRENCY = 'currency',
    CURRENCY_DISPLAY = 'currencyDisplay',
    HAS_TIME = 'hasTime',
    HAS_SECONDS = 'hasSeconds',
    DATE_FORMAT = 'dateFormat',
    TIME_FORMAT = 'timeFormat',
    TIME_IN_BRACKETS = 'timeInBrackets',
}

export enum TextAlignment {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export enum VerticalAlignment {
    TOP = 'top',
    MIDDLE = 'middle',
    BOTTOM = 'bottom',
}

export enum CurrencyDisplayOptions {
    SYMBOL = 'symbol',
    NARROW_SYMBOL = 'narrowSymbol',
    CODE = 'code',
    NAME = 'name',
}

export enum DateStringFormatOptions {
    NONE = 'none', // don't show date
    WORDS_LONG = 'wordsLong', // 1 February 2006
    WORDS_SHORT = 'wordsShort', // 1 Feb 2006
    NUMERIC_LONG = 'numericLong', // 01/02/2006
    NUMERIC_SHORT = 'numericShort', // 01/02/06
    NUMERIC_DASHED = 'numericDashed', // 01-02-2006
    WEEKDAY = 'weekday', // Wednesday, 1 Feb 2006
}

export enum TimeStringFormatOptions {
    NONE = 'none', // don't show time
    TWELVE_HOUR_SECONDS = 'twelveHourSeconds', // 02:35:47 pm
    TWELVE_HOUR = 'twelveHour', // 02:35 pm
    TWENTY_FOUR_HOUR_SECONDS = 'twentyFourHourSeconds', // 14:35:47
    TWENTY_FOUR_HOUR = 'twentyFourHour', // 14:35
}

// Interfaces

export type CellTypeConfigObject = {
    [key in CellTypeNames]: CellTypeOptionsObject;
};
export interface CellTypeOptionsObject {
    displayName: string;
    icon: string;
    formattingOptions?: FormatOptionsObject;
    alignment: string;
    allowCellEditing: boolean;
}

export interface FormatOptionsObject {
    [FormatOptions.DECIMALS]?: { defaultValue: number | 'auto' };
    [FormatOptions.THOUSANDS_SEPARATOR]?: { defaultValue: boolean };
    [FormatOptions.ACCOUNTING]?: { defaultValue: boolean };
    [FormatOptions.CURRENCY]?: { defaultValue: string };
    [FormatOptions.CURRENCY_DISPLAY]?: { defaultValue: string };
    [FormatOptions.HAS_TIME]?: { defaultValue: boolean };
    [FormatOptions.HAS_SECONDS]?: { defaultValue: boolean };
    [FormatOptions.DATE_FORMAT]?: { defaultValue: DateStringFormatOptions };
    [FormatOptions.TIME_FORMAT]?: { defaultValue: TimeStringFormatOptions };
    [FormatOptions.TIME_IN_BRACKETS]?: { defaultValue: boolean };
}

// Cell type config
export const CellTypeConstants: CellTypeConfigObject = {
    // We don't ever set the cell type as auto, these details are just used for the config of the button
    // and to get the cell type selection in the popup
    [CellTypeNames.AUTO]: {
        displayName: 'Auto',
        icon: 'cell-type-auto',
        alignment: TextAlignment.LEFT,
        allowCellEditing: true,
    },
    [CellTypeNames.NUMBER]: {
        displayName: 'Number',
        icon: 'cell-type-number',
        formattingOptions: {
            [FormatOptions.DECIMALS]: {
                defaultValue: 'auto',
            },
            [FormatOptions.THOUSANDS_SEPARATOR]: {
                defaultValue: true,
            },
        },
        alignment: TextAlignment.RIGHT,
        allowCellEditing: true,
    },
    [CellTypeNames.CURRENCY]: {
        displayName: 'Currency',
        icon: 'cell-type-currency',
        formattingOptions: {
            [FormatOptions.DECIMALS]: {
                defaultValue: 2,
            },
            [FormatOptions.THOUSANDS_SEPARATOR]: {
                defaultValue: true,
            },
            [FormatOptions.ACCOUNTING]: {
                defaultValue: false,
            },
            [FormatOptions.CURRENCY]: {
                defaultValue: 'USD',
            },
            [FormatOptions.CURRENCY_DISPLAY]: {
                defaultValue: CurrencyDisplayOptions.NARROW_SYMBOL,
            },
        },
        alignment: TextAlignment.RIGHT,
        allowCellEditing: true,
    },
    [CellTypeNames.PERCENTAGE]: {
        displayName: 'Percentage',
        icon: 'cell-type-percentage',
        formattingOptions: {
            [FormatOptions.DECIMALS]: {
                defaultValue: 2,
            },
            [FormatOptions.THOUSANDS_SEPARATOR]: {
                defaultValue: true,
            },
        },
        alignment: TextAlignment.RIGHT,
        allowCellEditing: true,
    },
    [CellTypeNames.TEXT]: {
        displayName: 'Text',
        icon: 'cell-type-text',
        alignment: TextAlignment.LEFT,
        allowCellEditing: true,
    },
    [CellTypeNames.DATE_TIME]: {
        displayName: 'Date & Time',
        icon: 'cell-type-datetime',
        alignment: TextAlignment.RIGHT,
        allowCellEditing: true,
        formattingOptions: {
            [FormatOptions.HAS_TIME]: {
                defaultValue: false,
            },
            [FormatOptions.HAS_SECONDS]: {
                defaultValue: false,
            },
            [FormatOptions.DATE_FORMAT]: {
                defaultValue: DateStringFormatOptions.WORDS_SHORT,
            },
            [FormatOptions.TIME_FORMAT]: {
                defaultValue: TimeStringFormatOptions.NONE,
            },
            [FormatOptions.TIME_IN_BRACKETS]: {
                defaultValue: true,
            },
        },
    },
    [CellTypeNames.CHECKBOX]: {
        displayName: 'Checkbox',
        icon: 'cell-type-checkbox',
        alignment: TextAlignment.CENTER,
        allowCellEditing: false,
    },
};
