// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import logger from '../../../logger/logger';
import { manuallyReportError } from '../../../analytics/rollbarService';

// Actions
import { localCacheForceClear } from '../../../offline/cache/localCacheActions';

// Components
import DefaultErrorRenderer from './DefaultErrorRenderer';

const getDefaultErrorMessage = (error, info) => `Error rendering component: ${error.toString()}`;

const getDefaultRollbarErrorDetails = (error, info) => ({
    stack: info.componentStack,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLocalCacheForceClear: () => dispatch(localCacheForceClear()),
});

class ErrorBoundary extends React.Component {
    state = {
        hasRenderError: false,
        error: null,
    };

    static getDerivedStateFromError(error) {
        return {
            hasRenderError: true,
            error,
        };
    }

    handleBeforeUnload = (event) => {
        if (this.state.hasRenderError) {
            const { dispatchLocalCacheForceClear } = this.props;

            dispatchLocalCacheForceClear();
        }
    };

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidCatch(error, info) {
        const {
            doNotReport,
            getErrorMessage = getDefaultErrorMessage,
            getRollbarErrorDetails = getDefaultRollbarErrorDetails,
            dispatchLocalCacheForceClear,
        } = this.props;

        if (doNotReport) return;

        dispatchLocalCacheForceClear();

        const errorMessage = getErrorMessage(error, info, this.props);

        logger.error(errorMessage, error);
        logger.error('Component stack', info.componentStack);

        const errorDetails = getRollbarErrorDetails(error, info, this.props);

        manuallyReportError({
            errorMessage,
            error: errorDetails,
        });
    }

    render() {
        const { ErrorRenderer = DefaultErrorRenderer } = this.props;

        return this.state.hasRenderError ? (
            <ErrorRenderer {...this.props} error={this.state.error} />
        ) : (
            this.props.children
        );
    }
}

ErrorBoundary.propTypes = {
    elementId: PropTypes.string,
    doNotReport: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    ErrorRenderer: PropTypes.func,
    getErrorMessage: PropTypes.func,
    getRollbarErrorDetails: PropTypes.func,
    dispatchLocalCacheForceClear: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
