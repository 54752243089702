export const ELEMENT_EDIT_START = 'ELEMENT_EDIT_START';
export const ELEMENT_EDIT_COMPLETE = 'ELEMENT_EDIT_COMPLETE';

// Selection action types
export const ELEMENTS_SELECTED = 'ELEMENTS_SELECTED';
export const ELEMENTS_DESELECTED = 'ELEMENTS_DESELECTED';
export const ELEMENTS_DESELECT_ALL = 'ELEMENTS_DESELECT_ALL';
export const ELEMENTS_FORCE_REMOTE_DESELECT = 'ELEMENTS_FORCE_REMOTE_DESELECT';

// For table elements
export const TABLE_ELEMENT_CELL_SELECTIONS_UPDATE = 'TABLE_ELEMENT_CELL_SELECTIONS_UPDATE';
