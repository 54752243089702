// Constants
import { PERMISSION_VALUES } from '../../../common/permissions/permissionConstants';

export const getPermissionsTypeFriendlyName = (permissions) => {
    switch (permissions) {
        case PERMISSION_VALUES.FULL_ACCESS:
            return 'edit';
        case PERMISSION_VALUES.READ_AND_FEEDBACK:
            return 'feedback';
        default:
            return 'read';
    }
};
