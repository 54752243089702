import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const QuickLineCircleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon quick-line-circle"
        {...props}
    >
        <rect width={16} height={16} fill="#FFF" fillRule="evenodd" rx={8} />
    </svg>
);
const Memo = memo(QuickLineCircleIcon);
export default Memo;
