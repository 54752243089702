// Lib
import { Modifier, EditorState } from 'draft-js';

// DOM Utils
import getBlockDiv from '../../../domUtils/getBlockDiv';

// Utils
import getNewLine from './getNewLine';
import getLines from './getLines';
import getLineAnchorForOffset from './getLineAnchorForOffset';
import getIndentForLine from './getIndentForLine';
import insertNewline from '../../../customRichUtils/editorState/insertNewline';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../../../draftjsConstants';
import { DEFAULT_INDENTATION } from './getIndentation';
import { STYLE_COMMANDS } from '../../../richText/richTextConstants';

const shouldIncreaseIndentation = (prevChar) =>
    prevChar === '{' || prevChar === '[' || prevChar === '(' || prevChar === ':';

/**
 *  Insert a new line with right indentation.
 */
const insertNewCodeLine = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    // Detect newline separation
    const newLineChar = getNewLine(blockText);

    // Create line to insert with right indentation
    const lines = getLines(blockText, newLineChar);
    const currentLineIndex = getLineAnchorForOffset(blockText, startOffset, newLineChar).line;
    const currentLine = lines[currentLineIndex];

    // We should exit the code block if we're returning at the end of the code block and there's a
    // blank line preceding it
    const shouldExitCodeBlock = startOffset === blockText.length && lines[currentLineIndex] === '';

    if (shouldExitCodeBlock) {
        // Remove the last empty lines of the code block
        const rangeToRemove = selection.merge({
            focusKey: startKey,
            focusOffset: startOffset - 1,
            anchorKey: startKey,
            anchorOffset: startOffset,
            isBackward: true,
        });
        const newContentState = Modifier.removeRange(contentState, rangeToRemove, DraftRemovalDirection.backward);
        const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);

        return insertNewline(newEditorState, STYLE_COMMANDS.UNSTYLED);
    }

    const prevChar = blockText[startOffset - 1];
    const standardIndent = DEFAULT_INDENTATION;
    const increaseIndentation = shouldIncreaseIndentation(prevChar);

    const indentForLine = getIndentForLine(currentLine);

    const lineToInsert = `${newLineChar}${indentForLine}${increaseIndentation ? standardIndent : ''}`;

    // Add or replace
    const newContentState = selection.isCollapsed()
        ? Modifier.insertText(contentState, selection, lineToInsert)
        : Modifier.replaceText(contentState, selection, lineToInsert);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS);

    // Scroll the div to the very left, as the Draft / browser doesn't seem to do this automatically
    // (probably Draft's fault)
    requestAnimationFrame(() => {
        const endKey = selection.getStartKey();
        const divElement = getBlockDiv(endKey);
        divElement.scrollLeft = 0;
    });

    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};

export default insertNewCodeLine;
