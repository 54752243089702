// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { stubTrue } from 'lodash/fp';

// Utils
import { isPlatformNativeApp } from '../../../../../platform/utils/platformDetailsUtils';
import { getBrowserTheme, retrieveThemeMode, setThemeLocalStorage } from './themeService';
import { hasSeen } from '../../../../../../common/users/userEducationUtil';
import { intercomTrackEvent } from '../../../../../analytics/intercomService';
import { getTimestamp, TIMES } from '../../../../../../common/utils/timeUtil';
import { getHasShownDarkModeSuggestion } from '../../../../../workspace/header/workspaceToolsHeader/account/darkModeSuggestion/darkModeSuggestionService';

// Actions
import { setTheme, setThemeMode } from './themeActions';
import { openPopup } from '../../../../../components/popupPanel/popupActions';

// Selectors
import { getPlatformDetailsSelector } from '../../../../../platform/platformSelector';
import { activePopupSelector } from '../../../../../components/popupPanel/popupOpenSelector';
import { hybridAppNativeThemeSelector } from '../../../../../hybridApp/store/hybridAppSelector';
import { getThemeInitialisedSelector, getThemeModeSelector, getThemeSelector } from './themeSelector';
import {
    getWorkspaceLoadIdleTimestamp,
    isAppInitCompleted,
} from '../../../../../app/initialisation/initialisationSelector';
import {
    getCurrentUserAccountRegistrationDate,
    getCurrentUserEducation,
    isCurrentUserFetched,
} from '../../../../currentUserSelector';
import {
    getShowOneTrustBanner,
    isOneTrustError,
    isOneTrustInitialised,
} from '../../../../../app/oneTrust/oneTrustSelectors';

// Constants
import { THEMES } from './themeConstants';
import { ONBOARDING_EDUCATION_CODES } from '../../../../../../common/users/userEducationConstants';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import usePrevious from '../../../../../utils/react/usePrevious';

const canShowDarkModeSuggestionSelector = (state) =>
    isCurrentUserFetched(state) &&
    !!getWorkspaceLoadIdleTimestamp(state) &&
    !!isAppInitCompleted(state) &&
    hasSeen(getCurrentUserEducation(state), ONBOARDING_EDUCATION_CODES.DESKTOP_ONBOARDING) &&
    getCurrentUserAccountRegistrationDate(state) < getTimestamp() - TIMES.DAY &&
    activePopupSelector(state).size === 0 &&
    (isOneTrustInitialised(state) || isOneTrustError(state));

const mapStateToProps = createStructuredSelector({
    isInitialised: getThemeInitialisedSelector,
    mode: getThemeModeSelector,
    theme: getThemeSelector,
    nativeTheme: hybridAppNativeThemeSelector,
    canShowDarkModeSuggestion: canShowDarkModeSuggestionSelector,
    isShowingOneTrustBanner: getShowOneTrustBanner,
    platformDetails: getPlatformDetailsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetThemeMode: (mode) => dispatch(setThemeMode(mode)),
    dispatchSetTheme: (theme) => dispatch(setTheme(theme)),
    // Keep the dark mode suggestion popup open unless the close button is pressed
    dispatchSuggestDarkMode: (mode) => dispatch(openPopup(PopupIds.DARK_MODE_SUGGESTION, stubTrue)),
});

const ThemeManager = (props) => {
    const {
        isInitialised,
        mode,
        theme,
        nativeTheme,
        canShowDarkModeSuggestion,
        isShowingOneTrustBanner,
        platformDetails,
        dispatchSetThemeMode,
        dispatchSetTheme,
        dispatchSuggestDarkMode,
    } = props;

    const prevIsShowingOneTrustBanner = usePrevious(isShowingOneTrustBanner);

    const setBodyClass = () => {
        const isLightTheme = theme === THEMES.LIGHT;

        if (isLightTheme) {
            document.body.classList.remove(THEMES.DARK);
            document.documentElement.classList.remove('ion-palette-dark');
            document.body.classList.add(THEMES.LIGHT);
        } else {
            document.body.classList.remove(THEMES.LIGHT);
            document.body.classList.add(THEMES.DARK);
            document.documentElement.classList.add('ion-palette-dark');
        }
    };

    // Detect if a user is in dark mode, but has not set the interface to dark mode
    // (and not specifically set the interface to light-mode either)
    // Needs to execute before the effect that sets the theme to local storage
    React.useEffect(() => {
        // Ignore this on electron
        if (!isInitialised) return;

        // A different theme has been chosen, so no need alerting them to the other themes
        if (mode !== THEMES.LIGHT) return;

        // The browser is in dark mode
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            intercomTrackEvent('dark-mode-os-light-mode-milanote');
        }
    }, []);

    // If mode is match browser, ensure the theme is correct on initial load
    // This is necessary for if the system theme has been changed since milanote was last open
    React.useEffect(() => {
        if (!isInitialised) return;

        if (mode !== THEMES.MATCH_BROWSER) return;

        const browserTheme = getBrowserTheme();

        if (theme !== browserTheme) {
            dispatchSetTheme(browserTheme);
        }
    }, []);

    // Set the theme class on the body whenever it changes
    React.useEffect(() => {
        if (!isInitialised) return;

        setBodyClass();
    }, [theme]);

    // Store the theme mode in local storage
    React.useEffect(() => {
        if (!isInitialised) return;

        setThemeLocalStorage(mode);
    }, [mode]);

    // In the electron app, Set the theme into state on mount
    React.useEffect(() => {
        if (isInitialised) return;

        const persistedTheme = retrieveThemeMode();
        dispatchSetThemeMode(persistedTheme);
    }, []);

    const modeRef = React.useRef(mode);

    // Update modeRef whenever mode changes
    React.useEffect(() => {
        if (!isInitialised) return;
        modeRef.current = mode;
    }, [mode]);

    const onBrowserLightThemeEvent = (e) => {
        e.matches && modeRef.current === THEMES.MATCH_BROWSER && dispatchSetTheme(THEMES.LIGHT);
    };

    const onBrowserDarkThemeEvent = (e) => {
        e.matches && modeRef.current === THEMES.MATCH_BROWSER && dispatchSetTheme(THEMES.DARK);
    };

    React.useEffect(() => {
        // React to system theme changes while milanote is open
        window.matchMedia('(prefers-color-scheme: light)').addListener(onBrowserLightThemeEvent);
        window.matchMedia('(prefers-color-scheme: dark)').addListener(onBrowserDarkThemeEvent);
    }, []);

    React.useEffect(() => {
        if (!canShowDarkModeSuggestion || isShowingOneTrustBanner) return;

        // If the OneTrust banner has just been closed, delay the dark mode suggestion popup
        const delay = prevIsShowingOneTrustBanner && !isShowingOneTrustBanner ? 1000 : 0;

        // If the user has already been presented with the dark mode popup, or the user has already
        // set a "theme" preference, ignore
        if (getHasShownDarkModeSuggestion() || modeRef.current !== THEMES.LIGHT) return;

        // If their OS/browser prefers dark mode and they're using light mode, show suggestion popup
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) setTimeout(dispatchSuggestDarkMode, delay);

        if (isPlatformNativeApp(platformDetails) && nativeTheme === THEMES.DARK) {
            setTimeout(dispatchSuggestDarkMode, delay);
        }
    }, [canShowDarkModeSuggestion, isShowingOneTrustBanner, nativeTheme]);

    return null;
};

ThemeManager.propTypes = {
    dispatchSetThemeMode: PropTypes.func,
    dispatchSetTheme: PropTypes.func,
    dispatchSuggestDarkMode: PropTypes.func,
    isInitialised: PropTypes.bool,
    nativeTheme: PropTypes.string,
    mode: PropTypes.string,
    theme: PropTypes.string,
    isShowingOneTrustBanner: PropTypes.bool,
    canShowDarkModeSuggestion: PropTypes.bool,
    platformDetails: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeManager);
