// Utils
import { updateElement } from '../actions/elementActions';

// Selectors
import { getElements } from '../selectors/elementsSelector';
import { getClosestUpTaskListId, getElement } from '../../../common/elements/utils/elementTraversalUtils';

// Constants
import { ELEMENT_UPDATE_TYPE } from '../../../common/elements/elementConstants';

export const setTaskCompletion =
    ({ id, isComplete, transactionId }) =>
    (dispatch) => {
        return dispatch(
            updateElement({
                id,
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_COMPLETION,
                changes: { isComplete },
                transactionId,
            }),
        );
    };

export const getParentTaskListIdThunk =
    ({ elementId }) =>
    (dispatch, getState) => {
        const state = getState();

        const elements = getElements(state);

        return getClosestUpTaskListId(elements, elementId);
    };

export const getParentTaskListThunk =
    ({ elementId }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const taskListId = getClosestUpTaskListId(elements, elementId);
        return getElement(elements, taskListId);
    };
