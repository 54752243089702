import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ButtonToggleDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11}
        height={10}
        viewBox="0 0 11 10"
        className="Icon button-toggle-down"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#D8D8D8" d="M2 0h7a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
            <path fill="#323B4A" d="M5.5 7 8 3H3z" />
        </g>
    </svg>
);
const Memo = memo(ButtonToggleDownIcon);
export default Memo;
