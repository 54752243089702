import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AlignLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon align-left"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M8.75 7.25A.75.75 0 0 1 9.5 8v16A.75.75 0 1 1 8 24V8a.75.75 0 0 1 .75-.75zM23 17a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h10zm-.5 1.5h-9v3h9v-3zM20 9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7zm-.5 1.5h-6v3h6v-3z"
            />
        </g>
    </svg>
);
const Memo = memo(AlignLeftIcon);
export default Memo;
