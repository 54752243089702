import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeNumberIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-number"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                fillRule="nonzero"
                d="M10.408 12.6H8.89L7 13.744v1.369l1.749-1.048h.044V19.9h1.615v-7.3Zm1.71 7.3h5.445v-1.262h-3.207v-.05l1.115-1.044c1.57-1.369 1.991-2.053 1.991-2.884 0-1.265-1.081-2.16-2.718-2.16-1.603 0-2.703.916-2.7 2.35h1.533c-.004-.7.458-1.127 1.156-1.127.67 0 1.17.399 1.17 1.04 0 .582-.372.98-1.066 1.619l-2.718 2.406V19.9Zm9.333.1c1.715 0 2.942-.902 2.938-2.15.004-.908-.6-1.557-1.722-1.693V16.1c.854-.146 1.443-.723 1.439-1.543.004-1.152-1.051-2.057-2.632-2.057-1.566 0-2.745.877-2.767 2.139h1.547c.019-.556.552-.916 1.212-.916.653 0 1.089.378 1.085.926.004.574-.507.963-1.241.963h-.713v1.133h.713c.865 0 1.405.414 1.402 1.002.003.581-.519.98-1.257.98-.712 0-1.245-.356-1.275-.89h-1.626C18.58 19.111 19.774 20 21.451 20Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeNumberIcon);
export default Memo;
