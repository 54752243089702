// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// List drag and drop
import makeCanDropFn from '../dnd/canDrop/makeCanDropFn';
import onlyTasks from '../dnd/canDrop/onlyTaskPredicate';

// Utils
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import { propIn } from '../../../common/utils/immutableHelper';
import { getElementStyle } from '../../utils/grid/gridUtils';
import { getElementId, getWidth } from '../../../common/elements/utils/elementPropertyUtils';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';

// Selectors
import { getShowTitle } from './taskListSelector';

// Components
import List from '../../list/List';
import ColorBar from '../../components/colors/ColorBar';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import EmptyTaskListPlaceholder from './EmptyTaskListPlaceholder';
import TaskListFeatureSuggestions from './TaskListFeatureSuggestions';
import QuickNotesActivityIndicator from '../activity/QuickNotesActivityIndicator';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';
import TaskListTitleEditor from './TaskListTitleEditor';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

// Style
import './TaskList.scss';

const taskListCanDrop = makeCanDropFn([onlyTasks]);

const TaskList = (props) => {
    const {
        currentBoardId,
        childElementIds,
        element,
        gridSize,
        inList,
        elementEvents,
        moveToTrashHandler,
        createNewTaskList,
        changeIndentationHandler,
        goToPreviousTaskHandler,
        goToNextTaskHandler,
        dispatchUpdateElement,
        onTaskToggle,
        isEditable,
        isEditingChild,
        isEditing,
        isSelected,
        tempSize,
        setParentHoveredChildAcceptsDrop,
        onStartBackspaceHandler,
        onEndDeleteHandler,
        onTaskListUpdatedHandler,
        isPresentational,
        isPresentationModeEnabled,
        permissions,
        getContextZoomScale,
        getContextZoomTranslationPx,
        selectThisElement,
        shouldFocusOnlyWhenSelected,
    } = props;

    const colorName = propIn(['content', 'color'], element);

    const showTitle = getShowTitle(element);

    const taskClasses = classNames('TaskList drag-handle contrast-shadow', {
        'show-title': showTitle,
        'no-title': !showTitle,
        'editing-child': isEditingChild,
        'focus-editing': shouldFocusOnlyWhenSelected && (isEditing || isEditingChild),
    });

    const classes = elementClassNames(taskClasses, props);

    const elementId = getElementId(element);

    const savedElementSize = getWidth(element);
    const elementWidth = (tempSize && tempSize.width) || savedElementSize || ELEMENT_DEFAULT_WIDTH;

    const allowTaskDrag = showTitle || childElementIds.length > 1;

    const emptyTaskListComponent = !childElementIds.length ? (
        <EmptyTaskListPlaceholder largeTop={!showTitle} {...props} />
    ) : null;

    return (
        <div
            id={getDomElementId(elementId)}
            className={classes}
            style={getElementStyle(elementWidth, gridSize, inList)}
            {...elementEvents}
        >
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <QuickNotesActivityIndicator {...props} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementUserTag {...props} />
            {showTitle && <TaskListTitleEditor {...props} />}
            <AttachedCommentsDropTarget
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                elementId={elementId}
                element={element}
                gridSize={gridSize}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
            <List
                {...props}
                depth={0}
                enableDoubleClick={false}
                listId={elementId}
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                listElementProps={{
                    isParentTaskListSelected: isSelected,
                    taskListElementId: elementId,
                    allowDrag: allowTaskDrag,
                    noTitle: !showTitle,
                    moveToTrashHandler,
                    createNewTaskList,
                    changeIndentationHandler,
                    goToPreviousTaskHandler,
                    goToNextTaskHandler,
                    onStartBackspaceHandler,
                    onEndDeleteHandler,
                    onTaskListUpdatedHandler,
                    depth: 0,
                    taskListOnMouseDown: elementEvents && elementEvents.onMouseDown,
                    taskListOnContextMenu: elementEvents && elementEvents.onContextMenu,
                    onTaskToggle,
                    selectParentTaskList: selectThisElement,
                }}
                placeholderComponent={emptyTaskListComponent}
                listCanDropFn={taskListCanDrop}
            />
            {!isPresentationModeEnabled && (
                <TaskListFeatureSuggestions
                    fullWidth
                    isEditable={isEditable}
                    element={element}
                    cardWidth={ELEMENT_DEFAULT_WIDTH}
                    dispatchUpdateElement={dispatchUpdateElement}
                />
            )}
        </div>
    );
};

TaskList.propTypes = {
    currentBoardId: PropTypes.string,
    element: PropTypes.object.isRequired,
    childElementIds: PropTypes.array,
    gridSize: PropTypes.number,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isPresentational: PropTypes.bool,
    inList: PropTypes.string,
    className: PropTypes.string,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    permissions: PropTypes.number,
    createNewTaskList: PropTypes.func,
    isPresentationModeEnabled: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,

    moveToTrashHandler: PropTypes.func,
    changeIndentationHandler: PropTypes.func,
    goToPreviousTaskHandler: PropTypes.func,
    goToNextTaskHandler: PropTypes.func,
    onStartBackspaceHandler: PropTypes.func,
    onEndDeleteHandler: PropTypes.func,
    onTaskListUpdatedHandler: PropTypes.func,

    dispatchUpdateElement: PropTypes.func,
    onTaskToggle: PropTypes.func,
    isEditable: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditingChild: PropTypes.bool,

    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,

    selectThisElement: PropTypes.func,
};

export default TaskList;
