// Lib
import createMarkup from './react/createMarkup';
import { getHeight, getWidth, getX, getY } from '../../common/maths/geometry/rect';
import DOMPurify from 'dompurify';

export const cleanEncodedSvgForInnerHtml = (svg) => createMarkup(DOMPurify.sanitize(atob(svg)));
export const cleanSvgForInnerHtml = (svg) => createMarkup(DOMPurify.sanitize(svg));

export const getViewBox = (viewBoxRect) => {
    if (!viewBoxRect) return null;

    const x = getX(viewBoxRect) || 0;
    const y = getY(viewBoxRect) || 0;
    const width = getWidth(viewBoxRect);
    const height = getHeight(viewBoxRect);

    if (!width || !height) return null;

    return `${x} ${y} ${width} ${height}`;
};
