/**
 * Functions relating to the Milanote predefined colours.
 */
// Constants
import { BACKGROUND_COLORS, COLORS, COLUMN_COLORS, SKETCH_BACKGROUND_COLORS } from './colorConstants';
import { ElementType } from '../elements/elementTypes';

export type PredefinedColor = {
    name: string | null;
    css: string | null;
    hex: string | null;
};

const VAR_RE = new RegExp(`^var\\(.*\\)$`, 'i');

export const getBackgroundPredefinedColorMapForType = (
    elementType: ElementType,
): { [key: string]: PredefinedColor } => {
    switch (elementType) {
        case ElementType.COLUMN_TYPE:
            return COLUMN_COLORS;
        case ElementType.SKETCH_TYPE:
            return SKETCH_BACKGROUND_COLORS;
        default:
            return COLORS;
    }
};

/**
 * Determines if the css string is a variable format (e.g. "var(--color-element-blue)").
 */
export const isPredefinedVarColorFormat = (str: string): boolean => (str && str.match && !!str.match(VAR_RE)) || false;

/**
 * Determines if predefined colour css strings (e.g. "var(--color-element-blue)") will have low contrast
 * with the interface colour (e.g. header, popup, etc) of the current theme.
 */
export const getIsLowContrastPredefinedColorCss = (colorCss: string, isDarkModeTheme: boolean): boolean => {
    switch (colorCss) {
        case SKETCH_BACKGROUND_COLORS.WHITE.css:
            return !isDarkModeTheme;
        default:
            return false;
    }
};

export const getPredefinedColor = (colorName: string): PredefinedColor | undefined =>
    COLORS[colorName as keyof typeof COLORS];

export const getPredefinedBackgroundColor = (colorName: string): PredefinedColor | undefined =>
    BACKGROUND_COLORS[colorName as keyof typeof BACKGROUND_COLORS] ||
    SKETCH_BACKGROUND_COLORS[colorName as keyof typeof SKETCH_BACKGROUND_COLORS];

export const getPredefinedColorOrBackground = (
    colorName: string,
    predefinedColorMap?: { [key: string]: PredefinedColor },
): PredefinedColor | undefined =>
    predefinedColorMap?.[colorName] || getPredefinedColor(colorName) || getPredefinedBackgroundColor(colorName);
