export default (content) => {
    let styles;

    const blockMap = content.getBlockMap();

    blockMap.forEach((block) => {
        const characterList = block.getCharacterList();

        characterList.forEach((characterMetadata) => {
            const characterStyles = characterMetadata.getStyle();

            if (characterStyles) {
                styles = styles?.intersect(characterStyles) || characterStyles;
            }
        });
    });

    return styles;
};
