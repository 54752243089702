export default (domElement) => {
    const ancestorElements = [];

    let currentElement = domElement;

    while (currentElement) {
        ancestorElements.push(currentElement);
        currentElement = currentElement.parentElement;
    }

    return ancestorElements;
};
