// Utils
import { loadImageFromDataUrl } from './loadImage';

/**
 * Fetches an image from a URL and returns its width and height.
 */
export default async (url) => {
    const event = await loadImageFromDataUrl(url);

    const img = event.target;

    const { width, height } = img;

    return {
        url,
        width,
        height,
    };
};
