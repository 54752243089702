import React from 'react';

import { elementContextMenuConnect } from '../../../../contextMenu/ElementContextMenuItemsContainer';
import MobileModalSheetMenu from '../../../../../mobile/structural/modalSheet/menu/MobileModalSheetMenu';
import Icon from '../../../../../components/icons/Icon';

type MobileElementContextMenuContentProps = {
    showCutCopy: boolean;
    canGroupInColumn: boolean;
    lockToolWillEnableLock: boolean;
    showBringToFront: boolean;
    showSendToBack: boolean;
    cut: () => void;
    copy: () => void;
    dispatchDuplicateElements: () => void;
    groupInColumn: () => void;
    toggleLock: () => void;
    bringToFront: () => void;
    sendToBack: () => void;
    deleteElement: () => void;
};

const MobileElementContextMenuContent = (props: MobileElementContextMenuContentProps) => {
    return (
        <MobileModalSheetMenu
            menuItems={[
                {
                    label: 'Cut',
                    icon: <Icon name="context-menu-cut" />,
                    onClick: props.cut,
                    dismissSheetOnSelect: true,
                    visible: props.showCutCopy,
                },
                {
                    label: 'Copy',
                    icon: <Icon name="context-menu-copy" />,
                    onClick: props.copy,
                    dismissSheetOnSelect: true,
                    visible: props.showCutCopy,
                },
                {
                    label: 'Duplicate',
                    icon: <Icon name="context-menu-duplicate" />,
                    onClick: props.dispatchDuplicateElements,
                    dismissSheetOnSelect: true,
                },
                {
                    label: 'Group into Column',
                    icon: <Icon name="context-menu-group" />,
                    onClick: props.groupInColumn,
                    dismissSheetOnSelect: true,
                    visible: props.canGroupInColumn,
                },
                {
                    label: props.lockToolWillEnableLock ? 'Lock position' : 'Unlock position',
                    icon: <Icon name="context-menu-lock" />,
                    onClick: props.toggleLock,
                    dismissSheetOnSelect: true,
                },
                {
                    label: 'Bring to front',
                    icon: <Icon name="context-menu-order-front" />,
                    onClick: props.bringToFront,
                    dismissSheetOnSelect: true,
                    visible: props.showBringToFront,
                },
                {
                    label: 'Send to back',
                    icon: <Icon name="context-menu-order-back" />,
                    onClick: props.sendToBack,
                    dismissSheetOnSelect: true,
                    visible: props.showSendToBack,
                },
                {
                    label: 'Move to trash',
                    icon: <Icon name="context-menu-trash" />,
                    onClick: props.deleteElement,
                    dismissSheetOnSelect: true,
                },
            ]}
        />
    );
};

export default elementContextMenuConnect(MobileElementContextMenuContent);
