// Lib
import * as Immutable from 'immutable';
import { pickBy, isNull } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

// Utils
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { getElement as getElementFromElements } from '../../../common/elements/utils/elementTraversalUtils';
import { getOriginalAliasContent } from '../../../common/alias/aliasUtils';

// Selectors
import { getElement } from '../selectors/elementSelector';
import { getElements } from '../selectors/elementsSelector';
import { getContent, getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import { asObject } from '../../../common/utils/immutableHelper';
import { createElementUserPermissionsSelector } from '../../utils/permissions/elementPermissionsSelector';
import { getBoardFetchError } from '../board/boardSelector';

const pickByNotNil = pickBy((value) => !!value);

export const getRenderedAliasElement = (aliasElement, linkedBoard) => {
    const elementObject = asObject(aliasElement);
    const content = getContent(elementObject);
    const linkedContent = asObject(getContent(linkedBoard));

    const originalContent = getOriginalAliasContent(aliasElement);

    // since content is picked by not nil, if secondaryColor is null we need to add it separately
    const keepSecondaryColorNull = isNull(content.secondaryColor);

    const renderedElement = {
        ...elementObject,
        content: {
            ...originalContent,
            ...linkedContent,
            ...pickByNotNil(content),
            ...(keepSecondaryColorNull && { secondaryColor: null }),
        },
    };

    return Immutable.fromJS(renderedElement);
};

const linkedElementIdSelector = () => createSelector(getElement, getPhysicalId);

const linkedElementSelector = () =>
    createShallowSelector(getElements, linkedElementIdSelector(), getElementFromElements);

const renderedAliasElementSelector = () =>
    createShallowSelector(getElement, linkedElementSelector(), getRenderedAliasElement);

/**
 * Gets the current user's permissions for the linked element (e.g. the board that an alias links to).
 */
const linkedElementPermissionsSelector = () => {
    const getLinkedElementId = linkedElementIdSelector();
    const getElementPermissions = createElementUserPermissionsSelector();

    return (state, ownProps) => {
        const linkedElementId = getLinkedElementId(state, ownProps);
        return getElementPermissions(state, { elementId: linkedElementId });
    };
};

export default () =>
    createStructuredSelector({
        linkedElementPermissions: linkedElementPermissionsSelector(),
        linkedElementId: linkedElementIdSelector(),
        linkedBoard: linkedElementSelector(),
        linkedBoardError: getBoardFetchError,
        renderedElement: renderedAliasElementSelector(),
    });
