export const ELEMENT_ACTIVITY_TYPES = {
    SHARE: 'SHARE',
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    COMPLETE: 'COMPLETE',
    MENTION: 'MENTION',
    COMMENT: 'COMMENT',
    CHANGE: 'CHANGE',
    REACTION: 'REACTION',
};

export const ELEMENT_INDICATOR_TYPES = {
    FILTER: 'filter',
    UNSEEN_CHANGES: 'unseen_changes',
};
