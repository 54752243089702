import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../components/icons/Icon';
import {
    DateStringFormatOptions,
    FormatOptions,
    TimeStringFormatOptions,
} from '../../../../common/table/CellTypeConstants';
import Checkbox from '../../../components/editor/plugins/checklist/components/Checkbox';
import { getFormattedDate, getFormattedTime } from '../../../../common/table/utils/tableCellFormattingUtils';

const DateFormatOptions = (props) => {
    const { sharedFormattingValues, updateCellTypeFormat, locale } = props;
    const { dateFormat, timeFormat, timeInBrackets } = sharedFormattingValues;

    // Milanote's birthday!
    const exampleDate = new Date('February 29, 2016 13:24:56');

    const timeOnlyDisplayed = dateFormat === DateStringFormatOptions.NONE;
    const dateOnlyDisplayed = timeFormat === TimeStringFormatOptions.NONE;

    return (
        <>
            <div className="DateFormatOptions celltype-format-section">
                <div className="celltype-format-title">Date format:</div>
                <div className="celltype-format-inputs">
                    <div className="dropdown-container celltype-format-input">
                        <select
                            value={dateFormat}
                            onChange={(e) => updateCellTypeFormat({ [FormatOptions.DATE_FORMAT]: e.target.value })}
                            className="dropdown"
                        >
                            {Object.values(DateStringFormatOptions).map((option, i) => (
                                <option
                                    key={i}
                                    value={option}
                                    disabled={option === DateStringFormatOptions.NONE && dateOnlyDisplayed}
                                >
                                    {getFormattedDate(exampleDate, option, locale) || 'None'}
                                </option>
                            ))}
                        </select>
                        <div className="arrow-icon-container">
                            <Icon name="dropdown-arrow" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="DateFormatOptions celltype-format-section">
                <div className="celltype-format-title">Time format:</div>
                <div className="celltype-format-inputs">
                    <div className="dropdown-container celltype-format-input">
                        <select
                            value={timeFormat}
                            onChange={(e) => {
                                updateCellTypeFormat({
                                    [FormatOptions.TIME_FORMAT]: e.target.value,
                                    ...(e.target.value !== TimeStringFormatOptions.NONE && {
                                        [FormatOptions.HAS_TIME]: true,
                                    }),
                                });
                            }}
                            className="dropdown"
                        >
                            {Object.values(TimeStringFormatOptions).map((option, i) => (
                                <option
                                    key={i}
                                    value={option}
                                    disabled={option === TimeStringFormatOptions.NONE && timeOnlyDisplayed}
                                >
                                    {getFormattedTime(exampleDate, option, locale) || 'None'}
                                </option>
                            ))}
                        </select>
                        <div className="arrow-icon-container">
                            <Icon name="dropdown-arrow" />
                        </div>
                    </div>
                    {timeFormat !== TimeStringFormatOptions.NONE && (
                        <div className="checkbox-input celltype-format-input">
                            <Checkbox
                                checked={timeInBrackets}
                                onClick={() =>
                                    updateCellTypeFormat({ [FormatOptions.TIME_IN_BRACKETS]: !timeInBrackets })
                                }
                            />
                            <span>Show time in brackets</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

DateFormatOptions.propTypes = {
    sharedFormattingValues: PropTypes.object,
    updateCellTypeFormat: PropTypes.func,
    locale: PropTypes.string,
};

export default DateFormatOptions;
