import React from 'react';
import classNames from 'classnames';
// @ts-ignore - No type definitions available for this package
import { goBack } from 'react-router-redux';
import { ConnectedProps, connect } from 'react-redux';

import Button from '../buttons/Button';
import Icon from '../icons/Icon';

import './ModalBackButton.scss';

const mapDispatchToProps = (dispatch: Function) => ({
    goBackHistory: () => dispatch(goBack()),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ModalBackButtonProps = PropsFromRedux & {
    onClick?: () => void;
    className?: string;
    title?: string;
};

const ModalBackButton = ({
    className,
    title = 'Back',
    onClick,
    goBackHistory,
}: ModalBackButtonProps): React.ReactElement => {
    const handleBackClickFn = onClick || goBackHistory;

    return (
        <Button onClick={handleBackClickFn} className={classNames('ModalBackButton', className)}>
            <span>
                <Icon name="chevron-left" />
            </span>{' '}
            {title}
        </Button>
    );
};

export default connector(ModalBackButton);
