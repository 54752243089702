// Lib
import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

// Hooks
import useUpdatedRef from '../../utils/react/useUpdatedRef';

const EMPTY_TRANSLATION = { x: 0, y: 0 };

// Create context
const ZoomStateContext = React.createContext();

// PROVIDER
const getProviderValue = memoizeOne((getContextZoomScale, getContextZoomTranslationPx) => ({
    getContextZoomScale,
    getContextZoomTranslationPx,
}));

export const ZoomStateContextProvider = (props) => {
    const { children, zoomScale, zoomTranslationPx } = props;

    const zoomScaleRef = useUpdatedRef(zoomScale || 1);
    const zoomTranslationRef = useUpdatedRef(zoomTranslationPx || EMPTY_TRANSLATION);

    const getContextZoomScale = React.useCallback(() => zoomScaleRef.current, []);
    const getContextZoomTranslationPx = React.useCallback(() => zoomTranslationRef.current, []);

    // Ensure that the context object reference remains constant so that it doesn't cause re-renders
    const providerValue = getProviderValue(getContextZoomScale, getContextZoomTranslationPx);

    return <ZoomStateContext.Provider value={providerValue}>{children}</ZoomStateContext.Provider>;
};

ZoomStateContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    zoomScale: PropTypes.number,
    zoomTranslationPx: PropTypes.object,
};

export const zoomStateContextProviderDecorator = (DecoratedComponent) => {
    const ComponentWithZoomStateContextProvider = (props) => {
        const { zoomScale, zoomTranslationPx } = props;
        return (
            <ZoomStateContextProvider zoomScale={zoomScale} zoomTranslationPx={zoomTranslationPx}>
                <DecoratedComponent {...props} />
            </ZoomStateContextProvider>
        );
    };

    ComponentWithZoomStateContextProvider.propTypes = {
        zoomScale: PropTypes.number.isRequired,
        zoomTranslationPx: PropTypes.object.isRequired,
    };

    return ComponentWithZoomStateContextProvider;
};

// CONSUMER
export const zoomStateContextConsumer = (DecoratedComponent) => {
    class ZoomStateContextContextConsumer extends React.Component {
        render() {
            return (
                <DecoratedComponent
                    getContextZoomScale={this.context.getContextZoomScale}
                    getContextZoomTranslationPx={this.context.getContextZoomTranslationPx}
                    {...this.props}
                />
            );
        }
    }

    ZoomStateContextContextConsumer.contextType = ZoomStateContext;

    return ZoomStateContextContextConsumer;
};
