import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';

import { COMMENT_THREAD_DISPLAY_NAME } from './commentConstants';
import { ACCESS_BITS } from '../permissions/permissionConstants';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { id, content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.COMMENT_THREAD_TYPE,
        content: {
            threadId: id,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.COMMENT_THREAD_TYPE,
        objectConstructorFn,
        displayName: COMMENT_THREAD_DISPLAY_NAME,
        editPermission: ACCESS_BITS.FEEDBACK,
        savePermission: ACCESS_BITS.FEEDBACK,
        ...params,
    });
