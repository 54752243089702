import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLineLabelIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-line-label"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#323B4A"
                d="M22 12a2 2 0 0 1 2 2v1.25h2.182c.452 0 .818.336.818.75 0 .38-.308.693-.707.743l-.111.007H24V18a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-1.25H5.818C5.366 16.75 5 16.414 5 16c0-.38.308-.693.707-.743l.111-.007H8V14a2 2 0 0 1 2-2h12zm-2 3h-8a1 1 0 0 0-.117 1.993L12 17h8a1 1 0 0 0 0-2z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarLineLabelIcon);
export default Memo;
