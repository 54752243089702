// Lib
import { cloneDeep } from 'lodash/fp';

// Utils
import { getMobileAppModeForCurrentUser } from './utils/platformAppModeUtils';
import { dangerouslyUpdatePlatformSingletonDetails, platformSingleton } from './platformSingleton';

// Types
import { UnknownAction } from 'redux';
import { MNUser } from '../../common/users/userModelTypes';
import { CURRENT_USER_SET } from '../user/currentUserConstants';
import {
    ApplicationType,
    MilanoteApplicationMode,
    PLATFORM_DETAILS_UPDATE,
    PlatformDetails,
    PlatformDetailsUpdateAction,
    PlatformType,
} from './platformTypes';
import { PWA_APP_INSTALLED } from '../pwaApp/store/pwaAppConstants';

const initialState = cloneDeep(platformSingleton);

const handlePlatformDetailsUpdate = (state: PlatformDetails, action: PlatformDetailsUpdateAction) => {
    dangerouslyUpdatePlatformSingletonDetails(action.details);

    return {
        ...state,
        ...action.details,
    };
};

/**
 * When loading the current user we need to determine if the user is using the modern or legacy mobile app.
 */
const handleCurrentUserSet = (state: PlatformDetails, action: UnknownAction) => {
    if (state.appMode === MilanoteApplicationMode.desktop) return state;

    // If we're loading the mobile app we need to determine if the current user is using the modern or legacy app
    const user = action.user as MNUser;
    const appMode = getMobileAppModeForCurrentUser(user, state.legacyHybridUseCase);

    dangerouslyUpdatePlatformSingletonDetails({ appMode });

    return {
        ...state,
        appMode,
    };
};

export default (state = initialState, action: UnknownAction) => {
    switch (action.type) {
        case CURRENT_USER_SET:
            return handleCurrentUserSet(state, action);
        case PLATFORM_DETAILS_UPDATE:
            return handlePlatformDetailsUpdate(state, action as PlatformDetailsUpdateAction);
        case PWA_APP_INSTALLED:
            return {
                ...state,
                appType: action.platform === PlatformType.iphone ? ApplicationType.iphonePwa : ApplicationType.pwa,
            };
        default:
            return state;
    }
};
