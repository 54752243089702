// Types
import { Point } from '../maths/geometry/pointTypes';
import { MNElement, MNElementLocation } from '../elements/elementModelTypes';
import { ElementType } from '../elements/elementTypes';

export enum ActionSource {
    REMOTE = 'REMOTE',
    WORKER = 'WORKER',
}

export interface ActionChanges {
    canvasOrigin?: Point;
}

export interface ActionUpdate {
    id: string;
    changes: ActionChanges;
}

export interface ActionMove {
    from?: MNElementLocation;
    id: string;
    location: MNElementLocation;
}

export interface ActionUser {
    _id: string;
    clientId: string;
    clientTick: number;
}

export enum ActionMonitoringDataKey {
    REQUEST_MODE = 'requestMode',
    ACTION_TYPE = 'actionType',
}

export enum ActionType {
    // This action should be used if logging specific events for New Relic
    LOG = 'LOG',
}

export type ActionMonitoringData = Partial<Record<ActionMonitoringDataKey, string>>;

export type ActionMonitoring = { operation: string } & ActionMonitoringData;

export type LogAction = {
    type: ActionType.LOG;
    monitoring: ActionMonitoring;
    user: ActionUser;
};

export type ActionObject = Partial<{
    id: string;
    clonedElementId: string;
    originalElementId: string;
    type: string;
    remote: boolean;
    silent: boolean;
    elementType: ElementType;
    moves: Array<ActionMove>;
    transactionId: string;
    location: MNElementLocation;
    updateType: string;
    updates: Array<ActionUpdate>;
    elements: Record<string, MNElement>;
    // Depending on the action, the user ID can be in user._id or userId here (or both)
    user: ActionUser;
    userId: string;
    isUndo: boolean;
    isRedo: boolean;
    elementIds: string[];
    deviceId: string;
    sessionId: string;
    bufferFlush: boolean;
    left: string[];
    joined: string[];
    channels: string[] | string;
    timestamp: number;
    monitoring: ActionMonitoring;
    source: ActionSource;
    attempt: number;
}>;

export type BatchActionObject = {
    type: string;
    payload: Array<ActionObject>;
};
