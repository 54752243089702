// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import RichMediaIFrameContent from './RichMediaIFrameContent';

// Styles
import './RichMediaContent.scss';

const RichMediaContent = (props) => {
    const { show } = props;
    if (!show) return null;

    return (
        <div className="RichMediaContent">
            <RichMediaIFrameContent {...props} />
            <div className="mouse-blocker" />
        </div>
    );
};

RichMediaContent.propTypes = {
    show: PropTypes.bool,
};

export default RichMediaContent;
