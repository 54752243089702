import { FAILURE_TYPES, FAILURE_NAMES, URL_REGEX } from '../../validationConstants';

type ValidationConfig = {
    name?: string;
    type?: string;
    hideBeforeSubmit?: boolean;
    hideIfTouched?: boolean;
    maxLength?: number;
};

type ErrorObject = {
    name: string;
    type: string;
    debounced: boolean;
    message: string;
    value?: string;
    hideBeforeSubmit?: boolean;
    hideIfTouched?: boolean;
};

// regex that detects html & script tags
const HTML_TAGS_REGEX = /(<([^>]+)>)/gi;

// regex that detects email addresses
const EMAIL_REGEX = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

const TEXT_ERROR_OBJECT = {
    name: FAILURE_NAMES.email,
    type: FAILURE_TYPES.ERROR,
    debounced: true,
};

const getErrorObject = (fieldValue: string, validationConfig: ValidationConfig) => ({
    ...TEXT_ERROR_OBJECT,
    value: fieldValue,
    name: validationConfig.name || TEXT_ERROR_OBJECT.name,
    hideBeforeSubmit: !!validationConfig.hideBeforeSubmit,
});

export default (
    fieldValue: string,
    validationConfig: ValidationConfig = {
        maxLength: 500,
    },
): ErrorObject | null => {
    if (!fieldValue) return null;
    if (validationConfig.maxLength && fieldValue.length > validationConfig.maxLength) {
        return {
            ...getErrorObject(fieldValue, validationConfig),
            message: `Max length is ${validationConfig.maxLength} characters`,
        };
    }

    if (fieldValue.match(HTML_TAGS_REGEX)) {
        return {
            ...getErrorObject(fieldValue, validationConfig),
            message: 'Contains invalid characters',
        };
    }

    if (fieldValue.match(EMAIL_REGEX)) {
        return {
            ...getErrorObject(fieldValue, validationConfig),
            message: 'Cannot include email addresses',
        };
    }

    if (fieldValue.match(URL_REGEX)) {
        return {
            ...getErrorObject(fieldValue, validationConfig),
            message: 'Cannot include urls',
        };
    }

    return null;
};
