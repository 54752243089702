// Utils
import { asObject } from '../../../common/utils/immutableHelper';
import { pixelsToGridPoints } from '../../utils/grid/gridUtils';
import { addMargins, asRect, getBoundingRect } from '../../../common/maths/geometry/rect';
import { isLine } from '../../../common/elements/utils/elementTypeUtils';
import { isIconViewLike } from '../../../common/elements/utils/elementDisplayUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { DEFAULT_ICON_VIEW_WIDTH } from '../../../common/elements/elementDisplayModeConstants';
import { BOARD_ALIGNMENT_OFFSET } from '../../workspace/toolbar/components/selectionTools/arrangementTools/alignmentTools/alignmentConstants';
import { LINE_HIT_AREA } from '../../element/line/lineUiConstants';

export const getAdjustedElementMeasurement = (measurements, gridSize) => (el) => {
    const elementId = getElementId(el);
    const measurement = asObject(measurements.get(elementId));
    measurement.id = elementId;

    if (isLine(el)) {
        return {
            ...measurement,
            // Line offsets are not dependent on grid size
            ...addMargins({ right: -LINE_HIT_AREA, bottom: -LINE_HIT_AREA }, measurement),
        };
    }

    if (isIconViewLike(el)) {
        // Not including the text in the board measurements
        const width = (DEFAULT_ICON_VIEW_WIDTH - 2) * gridSize;
        const height = (DEFAULT_ICON_VIEW_WIDTH - 2) * gridSize;

        const left = measurement.left + BOARD_ALIGNMENT_OFFSET * gridSize;
        const top = measurement.top + BOARD_ALIGNMENT_OFFSET * gridSize;

        const rect = asRect({ left, top, width, height });

        return {
            ...measurement,
            ...rect,
        };
    }

    return {
        ...measurement,
        ...addMargins({ right: 2, bottom: 2 }, measurement),
    };
};

export const getBoundingRectInGridUnits = ({ elements, measurements, gridSize, rounded = true }) => {
    const measurementsArray = elements.map(getAdjustedElementMeasurement(measurements, gridSize));
    const boundingRect = getBoundingRect(measurementsArray);

    return {
        left: rounded ? pixelsToGridPoints(boundingRect.left, gridSize) : boundingRect.left / gridSize,
        right: rounded ? pixelsToGridPoints(boundingRect.right, gridSize) : boundingRect.right / gridSize,
        top: rounded ? pixelsToGridPoints(boundingRect.top, gridSize) : boundingRect.top / gridSize,
        bottom: rounded ? pixelsToGridPoints(boundingRect.bottom, gridSize) : boundingRect.bottom / gridSize,
    };
};
