// Lib
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SharePlugin } from '../../../capacitor_plugins/share';

// Selectors
import { getCurrentUserQuickNotesRootBoardId } from '../../user/currentUserSelector';

// Actions
import { createElementForShareExtension, createImageElementForShareExtension } from '../actions/shareExtensionActions';

// Constants
import { CAPACITOR_SHARE_PLUGIN_NAME } from '../../../capacitor_plugins/pluginConstants';

export default function () {
    const dispatch = useDispatch();
    const quickNotesBoardId = useSelector(getCurrentUserQuickNotesRootBoardId);

    useEffect(() => {
        if (!Capacitor.isPluginAvailable(CAPACITOR_SHARE_PLUGIN_NAME)) return;

        SharePlugin.addListener('onShareText', ({ text }: { text: string }) => {
            dispatch(createElementForShareExtension(text, quickNotesBoardId));
        });

        SharePlugin.addListener('onShareImageUri', ({ uri }: { uri: string }) => {
            dispatch(createImageElementForShareExtension(uri, quickNotesBoardId));
        });

        return () => {
            SharePlugin.removeAllListeners();
        };
    }, [quickNotesBoardId]);
}
