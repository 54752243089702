/**
 * A collection of functions to manipulate colours, dealing with colours alone
 * without any Milanote specific logic.
 *
 * Ideally only "colour arithmetic" will be performed in here.
 */
// Lib
import chroma, { Color } from 'chroma-js';

// Constants
import { TEXT_COLOUR_DARK, TEXT_COLOUR_LIGHT } from './colorConstants';

const WCAG_AA_CONTRAST = 4.5;

export const getColorLightness = (color: Color | string): number => chroma(color).get('hsl.l');
export const getColorSaturation = (color: Color | string): number => chroma(color).get('hsl.s');
export const isColorAccessible = (colorA: Color | string, colorB: Color | string): boolean =>
    chroma.contrast(colorA, colorB) > WCAG_AA_CONTRAST;

/**
 * Determines if the colour is light or dark based on the contrast of the standard text colours on top of them.
 */
export const getIsLightColor = (color: string | Color): boolean => {
    try {
        // calculate expected contrast ratio of light text or dark text
        const contrastDarkText = chroma.contrast(TEXT_COLOUR_DARK, color);
        const contrastLightText = chroma.contrast(TEXT_COLOUR_LIGHT, color);

        // then use the highest contrast version
        return contrastDarkText > contrastLightText;
    } catch (e) {
        // If the colour isn't valid then we'll just assume it's light
        return true;
    }
};

/**
 * Returns a color that is a mix of the base color and the overlay color.
 * Great for when you need it to look like there is one color with an opacity over another color.
 */
export const mixColorsForOverlayEffect = (baseColor: string, overlayColor: string, mixAmount = 0.1): string =>
    chroma.mix(baseColor, overlayColor, mixAmount, 'rgb').toString();

/**
 * Returns true if colorValue is darker than the comparison color.
 */
export const getIsColorDarkerThan = (colorValue: string, comparisonColorValue: string): boolean =>
    chroma(colorValue).luminance() < chroma(comparisonColorValue).luminance();

/**
 * Determines if the colour has some luminance and saturation to be considered "colourful".
 */
export const getColorIsColorful = (colorValue: string): boolean => {
    try {
        const color = chroma(colorValue);

        return color.get('hsl.s') > 0.3 && color.get('hsl.l') > 0.2;
    } catch (error) {
        return false;
    }
};
