export enum MimeType {
    PDF = 'application/pdf',
    HTML = 'text/html',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    MARKDOWN = 'text/markdown; charset=UTF-8',
    PLAIN_TEXT = 'text/plain; charset=UTF-8',
    // Images
    GIF = 'image/gif',
    WEBP = 'image/webp',
    JPEG = 'image/jpeg',
    SVG = 'image/svg+xml',
    PNG = 'image/png',
    OCTET_STREAM = 'application/octet-stream',
    ZIP = 'application/zip',
}
