import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CursorRemoteSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={37}
        height={43}
        className="Icon cursor-remote-select"
        {...props}
    >
        <defs>
            <filter
                id="CursorRemoteSelectIcon__a"
                width="244%"
                height="160.9%"
                x="-76%"
                y="-34.5%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter
                id="CursorRemoteSelectIcon__b"
                width="186.9%"
                height="167.4%"
                x="-43.5%"
                y="-27%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.3 0"
                />
            </filter>
            <path
                id="CursorRemoteSelectIcon__c"
                d="m0 14.086.006.102a.75.75 0 0 0 1.267.436l3.686-3.58h5.791a.75.75 0 0 0 .531-1.28L1.984.444C1.12-.421 0 .046 0 1.265v12.822z"
            />
        </defs>
        <g
            fill="none"
            fillRule="evenodd"
            filter="url(#CursorRemoteSelectIcon__a)"
            transform="rotate(-15 51.375 -32.947)"
        >
            <use xlinkHref="#CursorRemoteSelectIcon__c" fill="#000" filter="url(#CursorRemoteSelectIcon__b)" />
            <use xlinkHref="#CursorRemoteSelectIcon__c" fill="#2F63F7" />
            <path fill="#FFF" d="m1.5 2.083 7.443 7.46H4.675a.75.75 0 0 0-.476.171l-.13.107L1.5 12.313V2.083z" />
        </g>
    </svg>
);
const Memo = memo(CursorRemoteSelectIcon);
export default Memo;
