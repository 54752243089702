// Constants
import { ENABLE_DEBUG_TOOLS, DEBUG_TOOL_TOGGLE, ENABLE_DEBUG_TOOLBAR } from './debugConstants';

// Utils
import { enableDebug } from './debugUtil';

export const enableDebugTools = () => {
    enableDebug();
    return {
        type: ENABLE_DEBUG_TOOLS,
    };
};

export const enableDebugToolbar = () => {
    enableDebug();
    return {
        type: ENABLE_DEBUG_TOOLBAR,
    };
};

export const toggleDebugTool = ({ enabled, toolName }) => ({
    type: DEBUG_TOOL_TOGGLE,
    enabled,
    toolName,
});
