import { ALIAS_DISPLAY_NAME } from './aliasConstants';
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => ({
    ...elementConstructor(params),
    elementType: ElementType.ALIAS_TYPE,
});

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.ALIAS_TYPE,
        objectConstructorFn,
        displayName: ALIAS_DISPLAY_NAME,
        ...params,
    });
