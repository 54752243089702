// Lib
import { getCurrentBoardVisibleDescendantIds } from '../../selectors/currentBoardSelector';
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';

export const getLocalCommentInputIsEditing = (state, { _id }) =>
    state.getIn(['app', 'commentsInput', 'map', _id, 'isEditing']);

export const getLocalCommentInputText = (state, { _id }) => state.getIn(['app', 'commentsInput', 'map', _id, 'text']);

export const getCommentsInputMap = (state) => state.getIn(['app', 'commentsInput', 'map']);

export const unsavedCommentsOnCurrentBoard = createShallowSelector(
    getCommentsInputMap,
    getCurrentBoardVisibleDescendantIds,
    (comments, currentBoardDescendantIds) =>
        comments
            .filter((comment) => !!comment.getIn(['text', 'blocks', 0, 'text']) || !!comment.get('isEditing'))
            .filter((_, elementId) => currentBoardDescendantIds.includes(elementId)),
);
