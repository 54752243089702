// Utils
import { propIn } from '../../../../common/utils/immutableHelper';
import translateDropResultIntoElementLocation from './translateDropResultIntoElementLocation';

const makeAddToMovesArrayReducer =
    ({ dropResult, unscaledElementOffsetsMap, gridSize, draggedElementIds, highestScore, scaledCustomDragOffset }) =>
    (movesArray, elementId) => {
        const location = translateDropResultIntoElementLocation({
            dropResult,
            unscaledElementOffsetsMap,
            elementId,
            gridSize,
            draggedElementIds,
            highestScore,
            scaledCustomDragOffset,
        });
        movesArray.push({ id: elementId, location });
        return movesArray;
    };

export default ({ dropResult, item, gridSize, measurementsMap, highestScore }) => {
    const { unscaledElementOffsetsMap, draggedElementIds } = item;

    const addToMovesArrayReducer = makeAddToMovesArrayReducer({
        dropResult,
        unscaledElementOffsetsMap,
        gridSize,
        // For some reason we need to copy the array as it's getting sorted in place somewhere
        draggedElementIds: [...draggedElementIds],
        highestScore,
        scaledCustomDragOffset: item.scaledCustomDragOffset,
    });

    return dropResult.acceptedElementIds
        .sort((idA, idB) => propIn([idA, 'top'], measurementsMap) - propIn([idB, 'top'], measurementsMap))
        .reduce(addToMovesArrayReducer, []);
};
