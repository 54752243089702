// Selectors
import { getCurrentlyEditingId } from '../selection/currentlyEditingSelector';

// Actions
import { setElementLocalData } from '../local/elementLocalDataActions';

/**
 * This helper action uses a thunk to retrieve the currently editing ID for performance reasons.
 * Previously this was a connected property which caused all elements to re-render when the ID of the
 * currently editing element changed.
 */
export const setElementTemporaryEditorState =
    ({ editorState }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentlyEditingId = getCurrentlyEditingId(state);

        dispatch(setElementLocalData({ id: currentlyEditingId, data: { editorState } }));
    };
