// Utils
import { isDomElement } from './domNodeUtils';

/**
 * Starting from a DOM Node or Element this will return itself if it's a DOM Element
 * or the first ancestor that is a DOM Element, if one exists.
 */
export const getClosestDomElement = (domNode) => {
    if (!domNode) return null;

    if (isDomElement(domNode)) return domNode;

    return getClosestDomElement(domNode.parentNode);
};
