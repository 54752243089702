// Lib
import React, { ReactElement, RefCallback } from 'react';

// Utils
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Hooks
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import DocumentModalDraftJs from './DocumentModalDraftJs';
import DocumentModalTiptap from './DocumentModalTiptap';

// Types
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';

// Styles
import './DocumentModal.scss';

interface DocumentModalProps {
    elementId: string;
    element: ImMNElement;

    isEditable: boolean;
    spellCheck: boolean;

    isModalView: boolean;
    isPreview: boolean;

    permissions: number;

    elementInstanceModalRef: RefCallback<HTMLDivElement>;
}

const DocumentModal = (props: DocumentModalProps): ReactElement => {
    const { element, elementId, isEditable, spellCheck, isModalView, isPreview, elementInstanceModalRef, permissions } =
        props;

    const textContent = getTextContent(element);
    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);

    if (tiptapContent) {
        return (
            <DocumentModalTiptap
                element={element}
                textContent={tiptapContent}
                elementId={elementId}
                permissions={permissions}
                isEditable={isEditable}
                spellCheck={spellCheck}
                isModalView={isModalView}
                isPreview={isPreview}
                elementInstanceModalRef={elementInstanceModalRef}
            />
        );
    }

    return <DocumentModalDraftJs {...props} />;
};

export default DocumentModal;
