export const HYBRID_APP_WILL_TERMINATE = 'HYBRID_APP_WILL_TERMINATE';

export const HYBRID_APP_ENTER_FOREGROUND = 'HYBRID_APP_ENTER_FOREGROUND';
export const HYBRID_APP_ENTER_BACKGROUND = 'HYBRID_APP_ENTER_BACKGROUND';

export const HYBRID_APP_DID_BECOME_ACTIVE = 'HYBRID_APP_DID_BECOME_ACTIVE';
export const HYBRID_APP_RESIGN_ACTIVE = 'HYBRID_APP_RESIGN_ACTIVE';

export const HYBRID_APP_DID_HIDE_VIRTUAL_KEYBOARD = 'HYBRID_APP_DID_HIDE_VIRTUAL_KEYBOARD';

export const HYBRID_APP_CHANGE_THEME = 'HYBRID_APP_CHANGE_THEME';

// Peripherals / Gestures
export const PENCIL_DOUBLE_TAP = 'PENCIL_DOUBLE_TAP';
export const TWO_FINGER_TAP = 'TWO_FINGER_TAP';
export const HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL = 999;

// Editing
export const HYBRID_APP_UNDO = 'HYBRID_APP_UNDO';
export const HYBRID_APP_REDO = 'HYBRID_APP_REDO';

// Synthetic events
export const HYBRID_APP_CUSTOM_DOM_EVENTS = {
    PENCIL_DOUBLE_TAP: 'pencildbltap',
};

export const PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS = {
    ignore: 'ignore',
    switchEraser: 'switchEraser',
    switchPrevious: 'switchPrevious',
    showColorPalette: 'showColorPalette',
    showInkAttribute: 'showInkAttributes',
};
