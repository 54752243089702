import { ImMap, prop } from '../../../utils/immutableHelper';
import { TiptapContentNode } from '../../tiptapTypes';

type TcNode = ImMap<TiptapContentNode> | TiptapContentNode;

/**
 * This is the same as reduceTiptapContent, but supports Immutable objects
 * as well.
 * I'm keeping them separate as Immutable objects intend to be phased out
 * and I don't want to litter the code with "prop" unless necessary.
 *
 * @warning: Do not use this unless you know the function will be executed
 *  against Immutable objects.
 */
export const reduceImTiptapContent = <T>(
    tiptapNode: TcNode,
    reducer: (acc: T, node: TcNode, ancestors: TcNode[]) => T,
    initialVal: T,
    ancestors: TcNode[] = [],
): T => {
    const newVal = reducer(initialVal, tiptapNode, ancestors);

    if (!prop('content', tiptapNode)) return newVal;

    return prop('content', tiptapNode).reduce(
        (acc: T, currentTiptapNode: TcNode) =>
            reduceImTiptapContent(currentTiptapNode, reducer, acc, [tiptapNode, ...ancestors]),
        newVal,
    );
};
