// Lib
import React from 'react';

import PropTypes from 'prop-types';

// Styles
import './FileInput.scss';

class FileInput extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    /**
     * Handles the selection of a file to upload.
     * If the file is not provided or is not an image then it does nothing, otherwise it delegates the uploading
     * to a passed in property.
     */
    handleFileSelection = (event) => {
        const { uploadFile, uploadMultipleFiles } = this.props;

        // If there's no handlers, do nothing
        if (!uploadFile && !uploadMultipleFiles) return;

        if (!this.fileInputRef.current) return;

        const { files } = this.fileInputRef.current;

        // Don't do anything when no files are uploaded
        if (!files.length) return;

        return uploadMultipleFiles ? uploadMultipleFiles(files) : uploadFile(files[0]);
    };

    click = () => {
        this.fileInputRef.current && this.fileInputRef.current.click();
    };

    render() {
        const { id, accept = 'image/*', multiple } = this.props;
        return (
            <input
                className="FileInput"
                type="file"
                accept={accept}
                multiple={multiple}
                name={`file-input-${id}`}
                id={`file-input-${id}`}
                ref={this.fileInputRef}
                onChange={this.handleFileSelection}
            />
        );
    }
}

FileInput.propTypes = {
    id: PropTypes.string.isRequired,
    uploadFile: PropTypes.func,
    uploadMultipleFiles: PropTypes.func,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
};

export default FileInput;
