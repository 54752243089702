// Lib
import { isEmpty } from 'lodash/fp';

// Utils
import { getNotificationId, getNotificationViewedTimestamp } from '../../common/notifications/notificationModelUtils';
import {
    getNotificationReferencedElementIds,
    getNotificationsReferencedCommentIds,
    getNotificationsReferencedPermissionIdAndAssociatedElementIds,
    getNotificationsReferencedUserIds,
} from '../../common/notifications/notificationsListUtils';

// Selectors
import { getElements } from '../element/selectors/elementsSelector';
import { getAllComments } from '../element/comment/store/commentsSelector';
import { getNotificationsSelector } from './notificationsSelector';
import { mapNotificationData } from './cleansedNotificationsSelector';
import { markNotificationsAsSeen } from './notificationActions';

// Services
import { fetchUsers } from '../user/userService';
import { fetchElements } from '../element/elementService';
import { fetchComments } from '../element/comment/store/commentsService';
import { getBatchedPermissionIdTokens } from '../utils/permissions/permissionsActions';

export const markHiddenNotificationsAsViewed = (data) => (dispatch, getState) => {
    const state = getState();
    const elements = getElements(state);
    const comments = getAllComments(state);
    const notifications = getNotificationsSelector(state);

    const mappingFn = mapNotificationData(elements, comments);

    const hiddenNotificationIds = notifications.reduce((acc, notification) => {
        const viewedTimestamp = getNotificationViewedTimestamp(notification);

        if (viewedTimestamp) return acc;

        const mappedNotificationData = mappingFn(notification);

        if (!mappedNotificationData) {
            acc.push(getNotificationId(notification));
        }

        return acc;
    }, []);

    !isEmpty(hiddenNotificationIds) && dispatch(markNotificationsAsSeen(hiddenNotificationIds));

    return data;
};

export const fetchReferencedNotificationsData = (responseData) => async (dispatch, getState) => {
    const { notifications } = responseData;

    if (!notifications) return Promise.resolve(responseData);

    // Get permission IDs from the notifications
    const { permissionIds = [], permissionIdToElementIdsMap = {} } =
        getNotificationsReferencedPermissionIdAndAssociatedElementIds(notifications);

    // Get tokens for the permission IDs
    await dispatch(
        getBatchedPermissionIdTokens({
            permissionIds,
            permissionIdToElementIdsMap,
        }),
    );

    // Get User IDs
    const referencedUserIds = getNotificationsReferencedUserIds(notifications);

    // Get Element IDs
    const referencedElementIds = getNotificationReferencedElementIds(notifications);

    // Referenced Comment IDs
    const referencedCommentIds = getNotificationsReferencedCommentIds(notifications);

    await Promise.all([
        dispatch(fetchUsers(referencedUserIds)),
        dispatch(fetchElements({ elementIds: referencedElementIds, loadAncestors: true })),
    ]);

    // Wait to fetch comments until after the elements fetching is complete otherwise we might double up
    await dispatch(fetchComments({ commentIds: referencedCommentIds }));

    return responseData;
};
