// Constants
import { BLOCK_METADATA, STYLE_COMMANDS } from '../../richText/richTextConstants';
import canBlockTypeAlignCenter from './canBlockTypeAlignCenter';

const CENTER_TEXT_CLASS = 'center-text';

const getBlockClasses = (contentBlock) => {
    let classes;

    const blockType = contentBlock.getType();

    if (blockType === STYLE_COMMANDS.SMALL_TEXT) {
        classes = 'small-text';
    }

    const data = contentBlock.getData();

    if (data && !!data.get(BLOCK_METADATA.TEXT_ALIGN_CENTER)) {
        classes = classes ? `${classes} ${CENTER_TEXT_CLASS}` : CENTER_TEXT_CLASS;
    }

    return classes;
};

export default (contentBlock) => {
    const blockType = contentBlock.getType();

    return canBlockTypeAlignCenter(blockType) ? getBlockClasses(contentBlock) : undefined;
};
