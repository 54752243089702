// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { EDIT_HYPERLINK_DRAFT_COMMAND } from './hyperlinkPluginConstants';

export default (command, editorState, eventTimeStamp, { getProps }) => {
    if (command !== EDIT_HYPERLINK_DRAFT_COMMAND) return HANDLER_RETURN_VALUES.notHandled;

    const { openHyperlinkPopup } = getProps();
    openHyperlinkPopup && openHyperlinkPopup();
};
