// Utils
import { getTimestamp } from '../utils/timeUtil';

// Constants
import {
    USER_CONNECT,
    USER_UPDATE,
    USER_REFRESH,
    USER_DISCONNECT,
    USER_MARK_AS_DISCONNECTED,
    USER_ACTIVITY_HEARTBEAT,
    USER_ENABLE_EXPERIMENT,
} from './userConstants';

export const userConnect = (userId) => ({
    type: USER_CONNECT,
    userId,
    timestamp: getTimestamp(),
});

export const userDisconnect = (userId) => ({
    type: USER_DISCONNECT,
    userId,
    timestamp: getTimestamp(),
});

export const userMarkAsDisconnected = (userId) => ({
    type: USER_MARK_AS_DISCONNECTED,
    userId,
    timestamp: getTimestamp(),
});

export const updateUser = ({ userId, changes, personal = false, sync = true }) => ({
    type: USER_UPDATE,
    userId,
    changes,
    personal,
    sync,
});

export const userActivityHeartbeat = ({ userId }) => ({
    type: USER_ACTIVITY_HEARTBEAT,
    userId,
    timestamp: getTimestamp(),
    sync: true,
});

/**
 * Update user merges changes onto the user, refresh user overwrites that user.
 */
export const refreshUser = ({ userId, user }) => ({
    type: USER_REFRESH,
    userId,
    user,
});

/**
 * NOTE: This action is currently only sent from the server to the client.
 *  It isn't handled on the server when sent from the client.
 */
export const userEnableExperiment = ({ userId, experimentName }) => ({
    type: USER_ENABLE_EXPERIMENT,
    userId,
    experimentName,
});
