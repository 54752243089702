// Lib
import { connect } from 'react-redux';

// Constants
import { EditorStoreContext } from './EditorStoreProvider';

export default (...args) => {
    const opts = {
        ...args[3],
        context: EditorStoreContext,
        getDisplayName: (component) => `EditorStoreConnect(${component})`,
    };
    return connect(args[0], args[1], args[2], opts);
};
