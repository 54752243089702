// Lib
import { useMemo } from 'react';
import { Extensions } from '@tiptap/core';
import { CommandProps } from '@tiptap/core/src/types';
import Document from '@tiptap/extension-document';
import { defer } from 'lodash';

// Custom extensions
import { Heading } from '../../../../common/tiptap/extensions/Heading';
import { KeyboardShortcuts } from '../../../../common/tiptap/extensions/KeyboardShortcuts';
import { SmallText } from '../../../../common/tiptap/extensions/SmallText';
import { TaskList } from '../../../../common/tiptap/extensions/TaskList';
import { TaskItem } from '../../../../common/tiptap/extensions/TaskItem';
import { BulletList } from '../../../../common/tiptap/extensions/list/BulletList';
import { OrderedList } from '../../../../common/tiptap/extensions/list/OrderedList';
import { TextAlign } from '../../../../common/tiptap/extensions/TextAlign';
import { Blockquote } from '../../../../common/tiptap/extensions/Blockquote';
import { CodeBlock } from '../../../../common/tiptap/extensions/CodeBlock';
import { TabHandler } from '../../../../common/tiptap/extensions/TabHandler';
import { InlineStyleClearer } from '../../../../common/tiptap/extensions/InlineStyleClearer';
import { UniversalCard } from '../../../../common/tiptap/extensions/universalCard/UniversalCard';
import { ListKeymap } from '../../../../common/tiptap/extensions/list/ListKeymap';
import { ListCommands } from '../../../../common/tiptap/extensions/list/ListCommands';
import useCommonTiptapEditorExtensions from '../../../../common/tiptap/hooks/useCommonTiptapEditorExtensions';

// Types
import { DispatchCreateNewElementFunction } from '../../actions/elementCreateActionTypes';
import { MentionsSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Constants
import { ELEMENT_CREATION_SOURCES } from '../../../../common/elements/elementConstants';

type UseCardTiptapEditorExtensionsArgs = {
    moveElementsToTrash?: () => void;
    saveContent?: (textContent: TiptapContent, transactionId?: string) => void;
    suggestionsManager?: MentionsSuggestionsManager;
    createNewCard?: DispatchCreateNewElementFunction;
    isEditable?: boolean;
    dispatch?: Function;
    onHighlightsChanged?: Function;
    clickableLinksWhenEditable?: boolean;
};

/**
 * Retrieves the Tiptap extensions for the Card's main editor.
 */
const useCardTiptapEditorExtensions = ({
    moveElementsToTrash,
    saveContent,
    suggestionsManager,
    createNewCard,
    isEditable,
    dispatch,
    onHighlightsChanged,
    clickableLinksWhenEditable,
}: UseCardTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useCommonTiptapEditorExtensions({
        saveContent,
        onEmptyBackspace: moveElementsToTrash,
        placeholder: 'Start typing...',
        suggestionsManager,
        isEditable,
        dispatch,
        onHighlightsChanged,
        clickableLinksWhenEditable,
    });

    return useMemo(() => {
        /**
         * When Cmd+Enter is pressed, create a new card.
         */
        const onModEnter = ({ editor }: CommandProps) => {
            // Save the content first, before creating a new element, so that the undo stack is correct
            const currentContent = editor.getJSON() as TiptapContent;
            saveContent?.(currentContent);

            // Need to defer this to prevent the app keyboard shortcut handlers from creating a new element as well.
            // The first new element would get created by this function, then selected, then the keyboardShortcuts
            // handlers would be triggered and another new element would be created.
            defer(() => {
                createNewCard?.({ creationSource: ELEMENT_CREATION_SOURCES.CMD_ENTER });
            });

            return !!createNewCard;
        };

        return [
            Document,
            Heading,
            BulletList,
            OrderedList,
            ListCommands,
            Blockquote,
            // Improves the editing functionality in lists.
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/listkeymap
            ListKeymap,
            // Allows text to be centred
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/textalign
            TextAlign,
            SmallText,
            TaskList,
            CodeBlock,
            // Adds or removes tabs on lines
            TabHandler,
            // Clears inline styles when the user presses Enter
            InlineStyleClearer,
            ...commonExtensions,
            createNewCard &&
                KeyboardShortcuts.configure({
                    keyboardShortcuts: {
                        // Create a new card on mod+enter
                        'Mod-Return': onModEnter,
                        'Mod-Enter': onModEnter,
                    },
                }),
            TaskItem.configure({
                nested: true,
                onReadOnlyChecked: (node, checked) => !!isEditable,
            }),
            isEditable && UniversalCard.configure({ dispatch }),
        ].filter(Boolean) as Extensions;
    }, [commonExtensions, saveContent, createNewCard, isEditable, dispatch]);
};

export default useCardTiptapEditorExtensions;
