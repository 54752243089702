import rIC from './rIC';

const usePolyfill = !window.requestIdleCallback;

/**
 * requestIdleCallback polyfill.
 */
export default (fn) => {
    let callbackId;

    const throttled = (...args) => {
        const latestArgs = args;
        // If we have already requested an animation frame, return
        if (callbackId) return;

        // Wait an animation frame
        callbackId = rIC(() => {
            callbackId = null;
            fn(...latestArgs);
        });
    };

    throttled.cancel = () => {
        if (!callbackId) return;
        usePolyfill ? clearTimeout(callbackId) : window.cancelIdleCallback(callbackId);
        callbackId = null;
    };

    return throttled;
};
