// Lib
import React from 'react';

// Styles
import './ToolSeparator.scss';

const ToolSeparator = () => (
    <div className="ToolSeparator">
        <div className="separator-line" />
    </div>
);

export default ToolSeparator;
