// Lib
import { Modifier, EditorState, getVisibleSelectionRect } from 'draft-js';

// Utils
import { DEFAULT_INDENTATION } from './getIndentation';
import getNewLine from './getNewLine';
import getLines from './getLines';
import getLineAnchorForOffset from './getLineAnchorForOffset';
import getBlockDiv from '../../../domUtils/getBlockDiv';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../../../draftjsConstants';

/**
 * When backspacing to an old line, Draft JS doesn't scroll to the end of the line, so do it manually.
 */
const attemptScrollToCursor = (blockKey) => {
    requestAnimationFrame(() => {
        const selectionRect = getVisibleSelectionRect(window);
        const divElement = getBlockDiv(blockKey);

        if (!divElement) return;

        const codeBlockBoundingBox = divElement.getBoundingClientRect();
        const cursorX = selectionRect.left;
        const codeBlockDivRight = codeBlockBoundingBox.right;

        // Don't scroll
        if (cursorX < codeBlockDivRight) return;

        divElement.scrollLeft = cursorX - codeBlockBoundingBox.width;
    });
};

/**
 * Remove last indentation before cursor, return undefined if no modification is done.
 */
const removeIndent = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) return;

    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    // Detect newline separator and indentation
    const newLine = getNewLine(blockText);
    const indent = DEFAULT_INDENTATION;

    // Get current line
    const lines = getLines(blockText, newLine);
    const lineAnchor = getLineAnchorForOffset(blockText, startOffset, newLine);

    const currentLine = lines[lineAnchor.line];
    const beforeSelection = currentLine.slice(0, lineAnchor.offset - 1);

    // Backspacing onto old line
    if (lineAnchor.line > 0 && lineAnchor.offset <= 1) attemptScrollToCursor(startKey);

    // Is the anchor point in front of a tab and are we sitting on a tab line
    if (!beforeSelection.endsWith(indent)) return;

    // Is the anchor on a tab line (e.g. 4, 8, 12, etc)
    const tabLineOvershoot = beforeSelection.length % indent.length;
    const evenTabsBefore = tabLineOvershoot === 0;

    const indentToRemove = evenTabsBefore ? indent.length : tabLineOvershoot;

    // Remove indent
    const beforeIndentOffset = startOffset - indentToRemove;
    const rangeToRemove = selection.merge({
        focusKey: startKey,
        focusOffset: beforeIndentOffset,
        anchorKey: startKey,
        anchorOffset: startOffset,
        isBackward: true,
    });

    const newContentState = Modifier.removeRange(contentState, rangeToRemove, DraftRemovalDirection.backward);
    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);

    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};

export default removeIndent;
