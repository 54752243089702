// Lib
import { Types } from 'mongoose';

export enum UserNavigationType {
    BOARD = 'BOARD',
    TEMPLATE = 'TEMPLATE',
}

export enum UserNavigationOperation {
    SEARCH = 'SEARCH',
    GENERAL = 'GENERAL',
}

export type UserNavigation = {
    _id: Types.ObjectId | string;
    userId: Types.ObjectId | string;
    elementId: string;
    permissionId: string;
    viewCount: number;
    lastView: number;
    lastEdit: number;
    navigationType: string;
};
