// Utils
import {
    createNextVersionId,
    getVersionNumber,
    getVersionSessionId,
    hasVersionableProperties,
} from './elementVersionUtils';

export enum VersionComparisonOutcomes {
    IGNORE_VERSIONING,
    CREATE_SERVER_VERSION,
    USE_NEW_VERSION_ID,
    IGNORE_CHANGE,
    VERSION_CONFLICT,
    OUTDATED_SERVER_VERSION,
}

/**
 * Compares the persisted version ID and the modified version ID and provides a mode to match the comparison.
 *
 * See: https://app.milanote.com/1Pg7SH1mLPPh75?p=j7XSk2haP1G
 */
export const compareMilanoteVersions = (
    changes: { [prop: string]: unknown },
    databaseElementVersionId?: string,
    actionModifiedVersionId?: string,
    actionNewVersionId?: string,
): VersionComparisonOutcomes => {
    const changeRequiresVersioning = hasVersionableProperties(changes);

    const mustCompareVersions = changeRequiresVersioning || !!actionModifiedVersionId || !!actionNewVersionId;

    // If there's no changes that require versioning and no version IDs provided, then ignore versioning
    if (!mustCompareVersions) return VersionComparisonOutcomes.IGNORE_VERSIONING;

    // If versioning is happening but there's no modified version
    if (!actionModifiedVersionId) {
        // Change requires versioning but there's no version data provided, so just use a server created version ID
        // NOTE: This is a legacy flow for clients that aren't up to date with the versioning strategy
        //  This could potentially result in accidental data loss
        if (!actionNewVersionId) return VersionComparisonOutcomes.CREATE_SERVER_VERSION;

        // If there's no persisted version and no modified version, but we have a new version ID, use the
        //  new version ID as the persisted and modified versions match
        if (!databaseElementVersionId) return VersionComparisonOutcomes.USE_NEW_VERSION_ID;

        // No modified version was provided, but there is a persisted version, and we have a new version ID
        //  thus there's been a version conflict
        return VersionComparisonOutcomes.VERSION_CONFLICT;
    }

    // If we encounter a valid version update scenario, use the new version ID if one is provided, otherwise
    //  create a new version ID on the server
    const validVersioningOutcome = actionNewVersionId
        ? VersionComparisonOutcomes.USE_NEW_VERSION_ID
        : VersionComparisonOutcomes.CREATE_SERVER_VERSION;

    // There's no persisted version ID, so we can't check whether the modified ID matches
    if (!databaseElementVersionId) return validVersioningOutcome;

    // We have a modified version ID and an element version ID, so compare against each other

    // If they're the same, continue as per normal
    // E.g. Persisted "A-2", Modified "A-2", New "A-3" (or "B-3")
    if (databaseElementVersionId === actionModifiedVersionId) return validVersioningOutcome;

    const persistedVersionSessionId = getVersionSessionId(databaseElementVersionId);
    const persistedVersionNumber = getVersionNumber(databaseElementVersionId);
    const modifiedVersionSessionId = getVersionSessionId(actionModifiedVersionId);
    const modifiedVersionNumber = getVersionNumber(actionModifiedVersionId);

    // If we're modifying a version from a different session than what's been persisted, there's clearly
    // a version conflict. E.g. "A-3" is persisted, but "B-2" is modified", there's definitely a version conflict.
    if (persistedVersionSessionId !== modifiedVersionSessionId) return VersionComparisonOutcomes.VERSION_CONFLICT;

    // Now the modified version is the same session ID as the persisted version

    // If the modified version is newer, the consumer needs to decide what to do with it
    // E.g. Persisted "A-2", Modified "A-4", New "A-5" (or "B-5")
    if (modifiedVersionNumber > persistedVersionNumber) return VersionComparisonOutcomes.OUTDATED_SERVER_VERSION;

    // Otherwise, it's modifying an older version, so we need to check if the new version is a new session,
    // If so it's a version conflict, otherwise it can safely be ignored because a newer change will have
    // applied already
    const newVersionSessionId = getVersionSessionId(actionNewVersionId);

    // E.g. Persisted "A-3", Modified "A-1", New "A-2". "A-3" has already been persisted so we can ignore this
    //  intermediate change
    if (newVersionSessionId === persistedVersionSessionId) return VersionComparisonOutcomes.IGNORE_CHANGE;

    // E.g. Persisted "A-2", Modified "A-1", New "B-2"
    return VersionComparisonOutcomes.VERSION_CONFLICT;
};

/**
 * Determines the new version ID to use based on the outcome of the version comparison.
 */
export const getNextVersionId = (
    versionComparisonOutcome: VersionComparisonOutcomes,
    databaseElementVersionId?: string,
    actionModifiedVersionId?: string,
    actionNewVersionId?: string,
): string | undefined => {
    switch (versionComparisonOutcome) {
        case VersionComparisonOutcomes.IGNORE_CHANGE:
        case VersionComparisonOutcomes.IGNORE_VERSIONING:
            return;
        case VersionComparisonOutcomes.CREATE_SERVER_VERSION:
            return createNextVersionId('server', databaseElementVersionId);
        case VersionComparisonOutcomes.USE_NEW_VERSION_ID:
            return actionNewVersionId;
        case VersionComparisonOutcomes.VERSION_CONFLICT:
        case VersionComparisonOutcomes.OUTDATED_SERVER_VERSION:
            return actionNewVersionId || createNextVersionId('server', databaseElementVersionId);
    }
};
