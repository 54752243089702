// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getFileType } from '../../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { currentBoardHasMediaDownloadAccessSelector } from '../../../utils/permissions/elementPermissionsSelector';
import { getAttachment } from '../../attachments/attachmentsSelector';

// Components
import FileModalNativeView from './FileModalNativeView';
import FileModalDetailView from './FileModalDetailView';

// Constants
import { FILE_TYPES_INFO } from '../../../../common/files/fileConstants';

// Styles
import './FileModal.scss';

const mapStateToProps = createStructuredSelector({
    attachment: getAttachment,
    showDownloadOption: currentBoardHasMediaDownloadAccessSelector,
});

const getFileIsNativeViewable = (fileType) => {
    switch (fileType) {
        case FILE_TYPES_INFO.TEXT.type:
        case FILE_TYPES_INFO.PDF.type:
            return true;
        default:
            return false;
    }
};

const FileModal = (props) => {
    const { element } = props;

    const fileType = getFileType(element);
    const isNativeViewable = getFileIsNativeViewable(fileType);

    const FileModalRenderer = isNativeViewable ? FileModalNativeView : FileModalDetailView;

    return (
        <div
            className={classNames('FileModal', {
                'native-view': isNativeViewable,
                'detail-view': !isNativeViewable,
            })}
        >
            <FileModalRenderer {...props} />
        </div>
    );
};

FileModal.propTypes = {
    element: PropTypes.object,
    showDownloadOption: PropTypes.bool,
};

export default connect(mapStateToProps)(FileModal);
