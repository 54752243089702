// Lib
import { isEmpty } from 'lodash/fp';

// Utils
import globalLogger, { LoggerComponents } from '../../../logger';
import rIC, { cancelIC } from '../../../../common/utils/lib/rIC';
import { objectSize, toArray } from '../../../../common/utils/immutableHelper';
import { getAllAncestorIds } from '../../../../common/dataStructures/treeUtils';

// Selectors
import { getElements } from '../../../element/selectors/elementsSelector';
import { getNotificationsSelector } from '../../../notifications/notificationsSelector';
import { getNotificationReferencedElementIds } from '../../../../common/notifications/notificationsListUtils';
import {
    aliasIdToPhysicalIdMapSelector,
    boardVisibleElementGraphSelector,
    parentIdMapSelector,
} from '../../../element/selectors/elementGraphSelector';
import { getSortedViewedBoardIds } from '../../../user/navigationHistory/navigationHistorySelector';
import { getCurrentBoardAncestorAndSelfIdsSelector } from '../../../element/selectors/currentBoardSelector';

// Actions
import { createBatchAction } from '../../../store/reduxBulkingMiddleware';
import { purgeElements } from '../../../../common/elements/elementActions';
import { cachedAsyncResource } from '../../../utils/services/http/asyncResource/asyncResourceActions';

// Constants
import { USER_NAVIGATE } from '../../../../common/users/userConstants';

// Types
import { AnyAction } from 'redux';
import { ReduxStore } from '../../../types/reduxTypes';
import { ActionSource } from '../../../../common/actions/actionTypes';
import { ResourceTypes } from '../../../utils/services/http/asyncResource/asyncResourceConstants';
import { IdTree } from '../../../../common/dataStructures/graphTypes';
import { NewRelicPageActions, sendNewRelicPageAction } from '../../../analytics/newRelicUtils';

const STORED_ELEMENTS_THRESHOLD = 750;
const VIEWED_BOARDS_THRESHOLD = 15;

let idleCallbackId: number | null = null;

const logger = globalLogger.createChannel(LoggerComponents.PURGING_SYSTEM);

/**
 * Purges elements from the Redux store when the threshold is reached.
 * This is to prevent the store from growing too large.
 *
 * If:
 *  - The number of viewed boards is greater than or equal to VIEWED_BOARDS_THRESHOLD, and
 *  - The number of stored elements is greater than or equal to STORED_ELEMENTS_THRESHOLD
 *
 * Then, purge elements:
 *  - That are descendants of those viewed boards
 *  - That are not required for the activity digest / notifications
 *  - That are not aliases or boards linked to by aliases
 *  - That aren't in the ancestors of the current board
 */
export default (store: ReduxStore) => (next: Function) => (action: AnyAction) => {
    if (action.source === ActionSource.REMOTE) return;
    if (action.type !== USER_NAVIGATE) return;

    if (idleCallbackId) cancelIC(idleCallbackId);

    const state = store.getState();
    const dispatch = store.dispatch;

    const viewableBoardIds = getSortedViewedBoardIds(state);

    if (viewableBoardIds?.length < VIEWED_BOARDS_THRESHOLD) return;

    const storedElementsCount = objectSize(getElements(state));

    if (storedElementsCount <= STORED_ELEMENTS_THRESHOLD) return;

    const currentBoardAncestors = getCurrentBoardAncestorAndSelfIdsSelector(state);

    const boardIdsToPurge = viewableBoardIds
        // Get the oldest viewed boards
        .slice(VIEWED_BOARDS_THRESHOLD)
        // The current board should never be in this list, but just make certain
        .filter((boardId) => !currentBoardAncestors.includes(boardId));

    const visibleElementsGraph = boardVisibleElementGraphSelector(state);

    const boardsToPurgeDescendantElementIdsSet = new Set<string>(
        boardIdsToPurge
            .map((boardId) => visibleElementsGraph[boardId])
            .filter(Boolean)
            .flat(),
    );

    // Don't purge elements that are referenced by notifications, or the ancestors of these elements
    const notifications = getNotificationsSelector(state);
    const referencedElementIds = getNotificationReferencedElementIds(toArray(notifications));
    const parentIdMap = parentIdMapSelector(state);

    const referencedElementAndAncestorIdsSet = new Set<string>(
        referencedElementIds.map((elementId) => [elementId, ...getAllAncestorIds(parentIdMap, elementId)]).flat(),
    );

    // Don't purge aliases or the boards they link to
    const aliasIdToPhysicalIdMap: IdTree = aliasIdToPhysicalIdMapSelector(state);
    const aliasIds = Object.keys(aliasIdToPhysicalIdMap);
    const linkedBoardIds = Object.values(aliasIdToPhysicalIdMap).filter(Boolean) as string[];

    const elementIdsToKeepSet = new Set([...referencedElementAndAncestorIdsSet, ...aliasIds, ...linkedBoardIds]);

    // TODO Use the commented code when Set.prototype.difference is becomes more widely available
    // const elementIdsToPurgeSet = boardsToPurgeDescendantElementIdsSet.difference(elementIdsToKeepSet);
    const elementIdsToPurgeSet = new Set([...boardsToPurgeDescendantElementIdsSet]);
    for (const elementId of elementIdsToKeepSet) {
        elementIdsToPurgeSet.delete(elementId);
    }

    if (isEmpty(elementIdsToPurgeSet)) return;

    const elementsToPurgeCount = elementIdsToPurgeSet.size;
    const remainingElementsCount = storedElementsCount - elementsToPurgeCount;

    logger.info('Purging elements', {
        elementsToPurgeCount,
        remainingElementsCount,
    });

    sendNewRelicPageAction(NewRelicPageActions.ELEMENT_PURGE, {
        elementsToPurgeCount,
        remainingElementsCount,
    });

    idleCallbackId = rIC(() => {
        const batchAction = createBatchAction({
            actions: [
                purgeElements(Array.from(elementIdsToPurgeSet), boardIdsToPurge),
                cachedAsyncResource(ResourceTypes.boards, boardIdsToPurge),
            ],
        });

        dispatch(batchAction);
    });
};
