import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationTaskCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-task-checked"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z" />
            <g transform="translate(1 1)">
                <rect width={14} height={14} fill="#A3A7AE" rx={2} />
                <path
                    fill="#FFF"
                    d="M10.5 4.013 6.125 8.388 3.5 5.763 2.263 7l3.243 3.244a.877.877 0 0 0 1.238 0l4.993-4.994L10.5 4.013z"
                />
            </g>
        </g>
    </svg>
);
const Memo = memo(NotificationTaskCheckedIcon);
export default Memo;
