import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const LinkFailIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={15}
        viewBox="0 0 16 15"
        className="Icon link-fail"
        {...props}
    >
        <defs>
            <path
                id="LinkFailIcon__a"
                d="M1.284 15c-.459 0-.883-.258-1.112-.678a1.427 1.427 0 0 1 0-1.357L6.887.678C7.117.258 7.541 0 8 0c.46 0 .883.258 1.112.678l6.716 12.287c.23.42.23.936 0 1.357-.23.42-.654.678-1.112.678H1.284Z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#F6A623"
                d="M1.284 15c-.459 0-.883-.258-1.112-.678a1.427 1.427 0 0 1 0-1.357L6.887.678C7.117.258 7.541 0 8 0c.46 0 .883.258 1.112.678l6.716 12.287c.23.42.23.936 0 1.357-.23.42-.654.678-1.112.678H1.284Z"
            />
            <g fill="#F6A623">
                <path d="M7.068 6.712 7 5.5h2l-.068 1.212L8.65 9.5H7.35zM8 12.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
            </g>
        </g>
    </svg>
);
const Memo = memo(LinkFailIcon);
export default Memo;
