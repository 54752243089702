// Lib
import React from 'react';
import classNames from 'classnames';

// Components
import Button, { ButtonProps } from './Button';

// Styles
import './LinkButton.scss';

type Props = ButtonProps & {
    children: React.ReactNode;
};

const LinkButton = (props: Props) => (
    <Button {...props} className={classNames('LinkButton', props.className)}>
        {props.children}
    </Button>
);

export default LinkButton;
