import { TASK_LIST_DISPLAY_NAME, SHOW_TITLE_PROPERTY } from './taskListConstants';
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.TASK_LIST_TYPE,
        content: {
            title: null,
            [SHOW_TITLE_PROPERTY]: false,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.TASK_LIST_TYPE,
        objectConstructorFn,
        displayName: TASK_LIST_DISPLAY_NAME,
        ...params,
    });
