// Lib
import { stateFromHTML } from 'draft-js-import-html';
import { convertToRaw } from 'draft-js';
import { DOMParser } from '@xmldom/xmldom';

// Constants
import { MimeType } from '../../media/mimeConstants';

const parser = new DOMParser();

const prepareHTML = (html) => {
    const prepared = html
        .replace(/h[1-2]/g, 'h2')
        .replace(/<h[3-6]>/g, '<p><strong>')
        .replace(/<\/h[3-6]>/g, '</strong></p>');

    return `<div>${prepared}</div>`;
};

const parseHTML = (html) => {
    const doc = parser.parseFromString(html, MimeType.HTML);
    return doc.documentElement;
};

export default (html) => convertToRaw(stateFromHTML(prepareHTML(html), { parser: parseHTML }));
