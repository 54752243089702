import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const HeaderEditableLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon header-editable-link"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1Zm1.992 7.501H6.008c.05 1.561.336 2.995.79 4.055C7.217 13.533 7.69 14 8 14c.31 0 .783-.467 1.202-1.444.454-1.06.74-2.494.79-4.055Zm-4.984 0H2.02a6.005 6.005 0 0 0 4.269 5.252c-.724-1.174-1.217-3.076-1.282-5.252Zm8.971 0h-2.987c-.065 2.176-.558 4.078-1.283 5.253A6.006 6.006 0 0 0 13.98 8.5ZM6.291 2.246l-.024.008A6.005 6.005 0 0 0 2.021 7.5h2.987c.065-2.176.558-4.079 1.283-5.254ZM8 2c-.31 0-.783.467-1.202 1.444-.454 1.06-.74 2.494-.79 4.056h3.984c-.05-1.562-.336-2.996-.79-4.056-.39-.912-.829-1.38-1.138-1.438L8 2Zm1.71.247.025.042c.711 1.178 1.192 3.06 1.257 5.211h2.987A6.005 6.005 0 0 0 9.71 2.247Z"
        />
    </svg>
);
const Memo = memo(HeaderEditableLinkIcon);
export default Memo;
