/**
 * Throttles calls to a function to ensure they only happen on a request animation frame.
 */
export default (fn) => {
    let requestId;
    let latestArgs;

    const throttled = (...args) => {
        latestArgs = args;

        // If we have already requested an animation frame, return
        if (requestId) return;

        // Wait an animation frame
        requestId = requestAnimationFrame(() => {
            requestId = null;
            fn(...latestArgs);
        });
    };

    throttled.cancel = () => {
        if (!requestId) return;
        cancelAnimationFrame(requestId);
        requestId = null;
    };

    return throttled;
};
