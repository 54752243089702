// Utils
import http from '../utils/services/http';
import { getDeviceId } from '../device/deviceService';

// Services
import { removeCurrentUserToken } from './authService';
import { clearCanvasReturnState } from '../canvas/canvasReturnStateService';
import { clearInboxReturnState } from '../inbox/returnState/inboxReturnStateService';
import { clearOnboardingProgressStep } from '../workspace/onboarding/config/onboardingProgressService';
import { clearDrawingLocalStorage } from '../element/drawing/drawingEditor/utils/drawingLocalStorageUtils';

// Selectors
import { getCurrentUserId } from '../user/currentUserSelector';

// Constants
import { METHODS } from '../../common/utils/http/httpConstants';
import * as AUTH_ACTION_TYPES from './authConstants';

export const clearUserSession = () =>
    http({
        method: METHODS.GET,
        url: 'auth/clear-session',
    });

// FIXME - Instead of performing this logic here, potentially move it to middleware?
//  Try to fix this so that the notifications can be unsubscribed from without causing
//  the electron build to fail
/**
 * Same as logout in authActions however this doesn't call unsubscribeFromPushNotifications
 * because that was causing the electron app to fail building.
 */
export const simpleLogout = () => (dispatch: Function, getState: Function) => {
    const state = getState();
    const userId = getCurrentUserId(state);

    // Make an API request to clear user session cookie
    clearUserSession();

    clearOnboardingProgressStep();
    clearDrawingLocalStorage();
    clearCanvasReturnState();
    clearInboxReturnState();
    removeCurrentUserToken();

    dispatch({
        type: AUTH_ACTION_TYPES.LOGOUT,
        deviceId: getDeviceId(),
        userId,
        sync: true,
    });
};
