// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { getTextContent } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { openDefaultAssignmentPopup } from './pills/assignment/taskAssignmentActions';
import { openCurrentlyEditingDueDatePopup } from './pills/assignment/dueDate/taskDueDateActions';

// Components
import TaskEditor from './TaskEditor';

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenAssignmentPopup: () => dispatch(openDefaultAssignmentPopup()),
    dispatchOpenDueDatePopup: () => dispatch(openCurrentlyEditingDueDatePopup()),
});

const TaskEditorWrapper = (props) => {
    const {
        element,
        editorRef,
        isEditable,
        isEditing,
        currentEditorKey,
        currentEditorId,
        filterQuery,
        saveContent,
        startEditing,
        stopEditing,
        onKeyboardDelete,
        changeIndentation,
        goToPreviousTask,
        goToNextTask,
        placeholder,
        spellCheck,
        createNewTask,
        onEnter,
        createNewTaskList,
        onAppUndo,
        onAppRedo,
        createMultipleNewTasksWithContent,
        setComplete,
        toggleComplete,
        dispatchOpenAssignmentPopup,
        dispatchOpenDueDatePopup,
        onStartBackspace,
        onEndDelete,
        shouldFocusOnlyWhenSelected,
    } = props;

    return (
        <TaskEditor
            editorId={getMainEditorId(props)}
            editorKey={getMainEditorKey(props)}
            currentEditorKey={currentEditorKey}
            currentEditorId={currentEditorId}
            editorRef={editorRef}
            isEditable={isEditable}
            isEditing={isEditing}
            placeholder={placeholder || 'Add a task...'}
            textContent={getTextContent(element)}
            filterQuery={filterQuery}
            saveContent={saveContent}
            startEditing={startEditing}
            stopEditing={stopEditing}
            onEmptyBackspace={onKeyboardDelete}
            onStartBackspace={onStartBackspace}
            onEndDelete={onEndDelete}
            changeIndentation={changeIndentation}
            goToPreviousTask={goToPreviousTask}
            goToNextTask={goToNextTask}
            createNewTask={createNewTask}
            createNewTaskList={createNewTaskList}
            createMultipleNewTasksWithContent={createMultipleNewTasksWithContent}
            spellCheck={spellCheck}
            onAppUndo={onAppUndo}
            onAppRedo={onAppRedo}
            openAssignmentPopup={dispatchOpenAssignmentPopup}
            openDueDatePopup={dispatchOpenDueDatePopup}
            onEnter={onEnter}
            setComplete={setComplete}
            toggleComplete={toggleComplete}
            shouldFocusOnlyWhenSelected={shouldFocusOnlyWhenSelected}
        />
    );
};

TaskEditorWrapper.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string,
    isEditable: PropTypes.bool,
    isEditing: PropTypes.bool,
    filterQuery: PropTypes.string,
    saveContent: PropTypes.func,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    moveElementsToTrash: PropTypes.func,
    onKeyboardDelete: PropTypes.func,
    changeIndentation: PropTypes.func,
    goToPreviousTask: PropTypes.func,
    goToNextTask: PropTypes.func,
    editorRef: PropTypes.func,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    updateElementType: PropTypes.func,
    createNewTask: PropTypes.func,
    createNewTaskList: PropTypes.func,
    createMultipleNewTasksWithContent: PropTypes.func,
    placeholder: PropTypes.string,
    spellCheck: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,
    onAppUndo: PropTypes.func,
    onAppRedo: PropTypes.func,
    onEnter: PropTypes.func,
    setComplete: PropTypes.func,
    toggleComplete: PropTypes.func,
    dispatchOpenAssignmentPopup: PropTypes.func,
    dispatchOpenDueDatePopup: PropTypes.func,
    onStartBackspace: PropTypes.func,
    onEndDelete: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(TaskEditorWrapper);
