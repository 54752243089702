export const COLOR_SWATCH_DISPLAY_NAME = 'Color';
export const COLOR_SWATCH_EDITOR_KEY = 'COLOR_SWATCH_COLOR';
export const COLOR_SWATCH_COLOR_PICKER_EDITOR_KEY = 'COLOR_SWATCH_COLOR_PICKER';

export const COLOR_SWATCH_DEFAULT_WIDTH_GRID = 17;
export const COLOR_SWATCH_DEFAULT_HEIGHT_GRID = 12;

export const COLOR_SWATCH_MIN_WIDTH_GRID = 4;
export const COLOR_SWATCH_MIN_HEIGHT_GRID = 4;

export const COLOR_SWATCH_CONTAINER_IN_LIST_PADDING_GU = 2.4;
