// Lib
import { isEqual } from 'lodash/fp';

// Utils
import {
    getNotificationCommentIsReply,
    getNotificationCommentThreadIdsAsArray,
    getNotificationElementId,
    getNotificationType,
    getNotificationUserId,
} from './notificationModelUtils';

// Constants
import { NOTIFICATION_TYPES } from './notificationConstants';

const areUserIdAndElementIdEqual = (notificationA, notificationB) =>
    getNotificationUserId(notificationA) === getNotificationUserId(notificationB) &&
    getNotificationElementId(notificationA) === getNotificationElementId(notificationB);

const areCommentNotificationsMergeable = (notificationA, notificationB) => {
    if (getNotificationCommentIsReply(notificationA) || getNotificationCommentIsReply(notificationB)) {
        return (
            areUserIdAndElementIdEqual(notificationA, notificationB) &&
            isEqual(
                getNotificationCommentThreadIdsAsArray(notificationA),
                getNotificationCommentThreadIdsAsArray(notificationB),
            )
        );
    }

    return areUserIdAndElementIdEqual(notificationA, notificationB);
};

/**
 * Determines if the properties of two notifications are similar enough to merge together or delete.
 */
export default (notificationA, notificationB) => {
    const notificationType = getNotificationType(notificationA);
    if (notificationType !== getNotificationType(notificationB)) return false;

    switch (notificationType) {
        case NOTIFICATION_TYPES.ASSIGNMENT:
        case NOTIFICATION_TYPES.BOARD_VIEW:
        case NOTIFICATION_TYPES.BOARD_UPDATE:
        case NOTIFICATION_TYPES.BOARD_TASKS_UPDATE:
        case NOTIFICATION_TYPES.BOARD_TASKS_REMINDER:
        case NOTIFICATION_TYPES.SHARE_REMINDER:
        case NOTIFICATION_TYPES.REACTION:
            return areUserIdAndElementIdEqual(notificationA, notificationB);
        case NOTIFICATION_TYPES.COMMENT:
            return areCommentNotificationsMergeable(notificationA, notificationB);
        default:
            return false;
    }
};
