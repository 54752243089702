// Lib
import React from 'react';

/**
 * Only executes the effect function after mount (e.g. the dependencies are updated, not initialised).
 */
const useUpdateLayoutEffect = (effectFn, deps) => {
    const isMounted = React.useRef(false);

    React.useLayoutEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        return effectFn();
    }, deps);
};

export default useUpdateLayoutEffect;
