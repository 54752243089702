// Lib
import { Modifier } from 'draft-js';

// Utils
import getSelectionStateForContent from './selection/getSelectionStateForContent';

const applyInlineStylesThroughoutContent = (contentState, styles) => {
    const selectionState = getSelectionStateForContent(contentState);

    let newContentState = contentState;
    styles?.forEach((style) => {
        newContentState = Modifier.applyInlineStyle(newContentState, selectionState, style);
    });

    return newContentState;
};

export default applyInlineStylesThroughoutContent;
