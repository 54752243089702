// Types
import { MNElement, MNElementMap } from '../../../common/elements/elementModelTypes';

// Utils
import { asObject } from '../../../common/utils/immutableHelper';

export const getElements = (state: Immutable.Map<string, any>): MNElementMap | undefined =>
    asObject(state.get('elements'));
export const getElement = (state: Immutable.Map<string, any>, elementId: string): MNElement | undefined =>
    asObject(state.getIn(['elements', elementId]));
