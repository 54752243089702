// Drop Functions
import { canvasDropResultFn } from './dropResultFn';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { ELEMENT_DND_TYPE } from '../../../common/elements/elementConstants';

export default (props, monitor, domNode) => {
    const dropResult = canvasDropResultFn(props, monitor, domNode);

    const itemType = monitor.getItemType();

    if (itemType !== ELEMENT_DND_TYPE) return { ...dropResult };

    const { draggedElements } = monitor.getItem();
    const acceptedElementIds = draggedElements.map((el) => getElementId(el));

    return {
        ...dropResult,
        acceptedElementIds,
    };
};
