// Lib
import * as Immutable from 'immutable';

import { ERROR_MODAL_OPEN } from './errorModalConstants';

const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case ERROR_MODAL_OPEN:
            return action.data ? Immutable.fromJS(action.data) : initialState;
        default:
            return state;
    }
};
