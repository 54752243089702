// Lib
import { camelCase } from 'lodash';

// Selectors
import { getUsers } from '../../user/usersSelector';
import { getElements } from '../../element/selectors/elementSelector';
import { getCanvasSize } from '../../canvas/store/canvasSizeSelector';
import { getCurrentBoardVisibleDescendants } from '../../element/selectors/currentBoardSelector';

// Utils
import { length, toArray } from '../../../common/utils/immutableHelper';
import { asNum } from '../../../common/maths/geometry/dimensionUtil';
import { getHeight, getWidth } from '../../../common/maths/geometry/rect';

// Analytics
import { setNewRelicMultipleCustomAttributes } from '../newRelicUtils';

// Constants
import { ALL_ELEMENT_TYPES } from '../../../common/elements/utils/elementTypeUtils';

// Types
import { ReduxStore } from '../../types/reduxTypes';
import { ElementCountMap, reduceTypeCounts } from '../../../common/elements/elementCountsUtils';
import { MNElement } from '../../../common/elements/elementModelTypes';
import { isSubscribed } from '../../user/userUtil';
import { getCurrentUser } from '../../user/currentUserSelector';

let store: ReduxStore | null = null;

/**
 * Caches a reference to the main Redux store.
 */
export const connectToStore = (mainReduxStore: ReduxStore): void => {
    store = mainReduxStore;
};

/**
 * Gets the analytics attributes for the current board's element counts.
 *
 * E.g. {
 *     'elementCounts.currentBoard.total': 5,
 *     'elementCounts.currentBoard.board': 2,
 *     'elementCounts.currentBoard.card': 3,
 * }
 */
export const getCurrentBoardElementCountAttributes = (prefix = 'currentBoard'): Record<string, number> => {
    if (!store) return {};

    const update: Record<string, number> = {};

    const state = store.getState();

    const currentBoardVisibleDescendants = toArray(getCurrentBoardVisibleDescendants(state)) as MNElement[];
    const elementTypeMap = currentBoardVisibleDescendants.reduce(reduceTypeCounts, {} as ElementCountMap);

    let total = 0;
    for (const key of ALL_ELEMENT_TYPES) {
        const elementTypeCount = elementTypeMap[key] || 0;
        total += elementTypeCount;
        update[`elementCounts.${prefix}.${camelCase(key)}`] = elementTypeCount;
    }

    update[`elementCounts.${prefix}.total`] = total;

    return update;
};

/**
 * Uses the store to retrieve various attributes useful for client side metrics.
 * Including:
 * - The number of elements in the store
 * - The number of users in the store
 * - The dimensions of the canvas
 * - The number of each type of element on the current board
 */
const collectCommonStoreAttributes = (): Record<string, string | number | boolean> | null => {
    if (!store) return null;

    const state = store.getState();

    const isCurrentUserSubscribed = isSubscribed(getCurrentUser(state)) || false;

    const storeElementsCounts = length(getElements(state));
    const storeUsersCounts = length(getUsers(state));

    const canvasSize = getCanvasSize(state);

    const update: Record<string, string | number | boolean> = {
        'endUser.isSubscribed': isCurrentUserSubscribed,
        'storeCounts.elements': storeElementsCounts,
        'storeCounts.users': storeUsersCounts,
        'canvasDimensions.width': asNum(getWidth(canvasSize)),
        'canvasDimensions.height': asNum(getHeight(canvasSize)),
    };

    const currentBoardElementCountAttributes = getCurrentBoardElementCountAttributes();

    return {
        ...update,
        ...currentBoardElementCountAttributes,
    };
};

/**
 * Gets attributes useful for client side metrics from the store and sends them to New Relic.
 */
export const updateCommonStoreAttributes = (): void => {
    const commonStoreAttributes = collectCommonStoreAttributes();

    if (!commonStoreAttributes) return;

    setNewRelicMultipleCustomAttributes(commonStoreAttributes);
};
