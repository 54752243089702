import * as Immutable from 'immutable';

import { initOffset, createClientId } from './clientId';
import { TICK_MAX, CLIENT_OFFSET_MAX } from './uidConstants';

let socketConnections = Immutable.Map();
let clientIds = Immutable.Map();
let serverId;
let workerId;

export const init = (sId = 0, wId = 0, offset) => {
    serverId = sId;
    workerId = wId;
    if (offset) {
        initOffset(offset);
    } else {
        initOffset(Math.floor(Math.random() * CLIENT_OFFSET_MAX));
    }
};

export const getClientIdResponse = () => ({
    clientId: createClientId(serverId, workerId),
    clientTick: Math.floor(Math.random() * TICK_MAX * 0.25),
});

export const clientConnected = (socketId, userId) => {
    const response = getClientIdResponse();

    socketConnections = socketConnections.set(socketId, response.clientId);
    clientIds = clientIds.set(response.clientId, response.clientTick);

    return response;
};

export const clientDisconnected = (socketId) => {
    clientIds = clientIds.delete(socketConnections.get(socketId));
    socketConnections = socketConnections.delete(socketId);
};

export const getTick = (clientId) => clientIds.get(clientId);
export const setTick = (clientId, tick) => {
    clientIds.set(clientId, tick);
};
