// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash';
import * as PlayerJS from 'player.js'; // eslint-disable-line no-unused-vars

// Utils
import createMarkup from '../../../../utils/react/createMarkup';
import { prop } from '../../../../../common/utils/immutableHelper';
import getPaddingForAspectRatio from '../../../resizing/utils/getPaddingForAspectRatio';

// Styles
import './IFrameVideoPlayer.scss';

const SRC_REGEX = /src="(.*?)"/;

/**
 * This function will replace videos hosted by third-party to make it cookieless, for compliance to GDPR.
 * The impact of this would be that we would lose view counts of the cookieless videos embedded on the site.
 */
export const makeCookielessVideoEmbedHtml = (htmlString) => {
    const srcString = htmlString.match(SRC_REGEX);
    const srcURLString = srcString && srcString[1];
    const src = srcURLString && new URL(srcURLString);

    if (!src || src.host !== 'cdn.embedly.com') return htmlString;

    const schema = src.searchParams.get('schema');

    // Make Vimeo video cookieless:
    // https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Player-parameters-overview#:~:text=account%20or%20higher.%C2%A0-,dnt,-true%20%2C%20false
    if (schema === 'vimeo') {
        const vimeoEmbedurlString = src.searchParams.get('src');
        if (!vimeoEmbedurlString) return htmlString;

        const vimeoEmbedurl = new URL(decodeURI(vimeoEmbedurlString));
        vimeoEmbedurl.searchParams.set('dnt', '1');

        src.searchParams.set('src', encodeURI(vimeoEmbedurl.toString()));
        return htmlString.replace(SRC_REGEX, `src="${src.toString()}"`);
    }

    return htmlString;
};

class IFrameVideoPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.isReady = false;
    }

    componentWillMount() {
        const { dispatchRichMediaError, videoData } = this.props;

        if (!videoData) {
            dispatchRichMediaError();
        }
    }

    componentDidMount() {
        if (!this.container) return;

        const iFrame =
            this.container.querySelector('iframe.embedly-embed') ||
            this.container.querySelector('iframe.iframely-embed');
        const player = new playerjs.Player(iFrame); // eslint-disable-line no-undef

        // Wait a second before transitioning to the player in case the player does not
        // support the 'ready' event
        this.mountTimer = delay(this.dispatchPlayerReady, 2000);

        player.on('ready', () => {
            this.cancelTimers();

            // Wait at most 1.5 seconds before transitioning to the player in case the player does not
            // support the 'play' event
            this.readyTimer = delay(this.dispatchPlayerReady, 2000);

            player.on('play', this.dispatchPlayerReady);
            player.on('ended', this.props.dispatchRichMediaReturnToPreview);
            player.on('error', this.props.dispatchRichMediaError);
        });
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillUnmount() {
        this.cancelTimers();
    }

    cancelTimers = () => {
        this.mountTimer && clearTimeout(this.mountTimer);
        this.readyTimer && clearTimeout(this.readyTimer);
    };

    dispatchPlayerReady = () => {
        if (this.isReady) return;

        this.isReady = true;
        this.props.dispatchRichMediaReady();
    };

    render() {
        const { videoData } = this.props;

        if (!videoData) return null;

        const html = prop('html', videoData);
        const cookielessHtml = makeCookielessVideoEmbedHtml(html);

        return (
            <div
                ref={(c) => {
                    this.container = c;
                }}
                className="IFrameVideoPlayer"
                style={getPaddingForAspectRatio({ savedSize: videoData, roundSaved: true })}
                dangerouslySetInnerHTML={createMarkup(cookielessHtml)}
            />
        );
    }
}

IFrameVideoPlayer.propTypes = {
    videoData: PropTypes.object.isRequired,
    dispatchRichMediaReady: PropTypes.func,
    dispatchRichMediaReturnToPreview: PropTypes.func,
    dispatchRichMediaError: PropTypes.func,
};

export default IFrameVideoPlayer;
