import { ERROR_MODAL_OPEN, ERROR_MODAL_CLOSE } from './errorModalConstants';

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case ERROR_MODAL_OPEN:
            return action.modalId;
        case ERROR_MODAL_CLOSE:
            return initialState;
        default:
            return state;
    }
};
