import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const UserGuestIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={19}
        viewBox="0 0 16 19"
        className="Icon user-guest"
        {...props}
    >
        <path
            fill="#929292"
            fillRule="evenodd"
            d="M4 4a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm12 12.248V15.17c0-.539-.202-1.4-.8-2.267-.997-1.445-3.096-2.903-7.196-2.904C3.896 10 1.798 11.46.8 12.905c-.598.866-.8 1.727-.8 2.266v1.077A12.944 12.944 0 0 0 8 19c3.017 0 5.794-1.028 8-2.752Z"
        />
    </svg>
);
const Memo = memo(UserGuestIcon);
export default Memo;
