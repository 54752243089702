// Lib
import { SelectionState } from 'draft-js';

// Utils
import { prop, length } from '../../immutableHelper';
import clamp from '../../../maths/clamp';

export default (rawState, index) => {
    const blocks = prop('blocks', rawState);

    if (!blocks) return null;

    const numBlocks = length(blocks);

    if (!numBlocks) return null;

    const lastBlock = prop(numBlocks - 1, blocks);

    const blockKey = prop('key', lastBlock);
    const blockText = prop('text', lastBlock);
    const blockTextLength = (blockText && blockText.length) || 0;

    const indexToFocus = clamp(0, blockTextLength, index);

    const selection = SelectionState.createEmpty(blockKey);
    return selection.merge({
        anchorKey: blockKey,
        anchorOffset: indexToFocus,
        focusKey: blockKey,
        focusOffset: indexToFocus,
        isBackward: false,
        hasFocus: true,
    });
};
