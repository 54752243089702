import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon nav-settings"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M14.071 1.25a.75.75 0 0 1 .72.538l.802 2.72c.45.204.877.445 1.283.724l2.875-.703a.75.75 0 0 1 .821.343l2.071 3.457a.75.75 0 0 1-.114.917l-2.05 2.043a7.018 7.018 0 0 1 .001 1.409l2.05 2.042a.75.75 0 0 1 .113.917l-2.07 3.457a.75.75 0 0 1-.822.343l-2.876-.704a8.822 8.822 0 0 1-1.28.723l-.804 2.735a.75.75 0 0 1-.72.539H9.929a.75.75 0 0 1-.72-.538l-.802-2.72a8.391 8.391 0 0 1-1.283-.724l-2.875.703a.75.75 0 0 1-.821-.343l-2.071-3.457a.75.75 0 0 1 .114-.917l2.05-2.043A7.265 7.265 0 0 1 3.483 12c0-.227.012-.46.036-.698L1.47 9.26a.75.75 0 0 1-.113-.917l2.07-3.457a.75.75 0 0 1 .822-.343l2.876.704a8.822 8.822 0 0 1 1.28-.723L9.21 1.79a.75.75 0 0 1 .72-.539h4.142zm-3.58 1.5-.745 2.533a.75.75 0 0 1-.433.481c-.578.24-1.112.54-1.582.893a.75.75 0 0 1-.628.129L4.43 6.13 2.945 8.61l1.892 1.886a.75.75 0 0 1 .213.636c-.043.31-.066.598-.066.867 0 .268.022.555.067.885a.75.75 0 0 1-.214.632l-1.892 1.886 1.485 2.48 2.673-.654a.75.75 0 0 1 .628.128c.487.366 1.008.66 1.577.89a.75.75 0 0 1 .438.484l.744 2.519h3.02l.744-2.533a.75.75 0 0 1 .433-.481 7.251 7.251 0 0 0 1.582-.893.75.75 0 0 1 .628-.129l2.673.655 1.485-2.48-1.892-1.886a.75.75 0 0 1-.213-.636c.043-.31.066-.598.066-.867a6.52 6.52 0 0 0-.067-.885.75.75 0 0 1 .214-.632l1.892-1.886-1.485-2.48-2.673.654a.75.75 0 0 1-.628-.128 6.923 6.923 0 0 0-1.577-.89.75.75 0 0 1-.438-.484L13.51 2.75h-3.02zM12 8.25c2.075 0 3.772 1.685 3.772 3.75S14.075 15.75 12 15.75c-2.075 0-3.772-1.685-3.772-3.75S9.925 8.25 12 8.25zm0 1.5c-1.249 0-2.272 1.015-2.272 2.25s1.023 2.25 2.272 2.25c1.249 0 2.272-1.015 2.272-2.25S13.249 9.75 12 9.75z"
        />
    </svg>
);
const Memo = memo(NavSettingsIcon);
export default Memo;
