// Lib
import ntc from './ntc';
import { isString } from 'lodash/fp';

// Utils
import { getColorDefaultHexValue } from './elementColorFormatUtil';
import { ColorObject } from './colorObjectUtil';

const customColorNameMapping = {
    'Double Colonial White': 'Double Colonial',
    'Double Spanish White': 'Double Spanish',
    'International Klein Blue': 'International Klein',
    'Macaroni and Cheese': 'Macaroni & Cheese',
    "Purple Mountain's Majesty": 'Purple Mountain',
    'Quarter Spanish White': 'Quarter Spanish',
    'School bus Yellow': 'School Bus Yellow',
    'Beauty Bush': 'Beauty Blush',
};

export const getColorName = (color: string | ColorObject): string | null => {
    const colorHex = getColorDefaultHexValue(color);

    if (!colorHex) return null;

    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const [_, colorName] = ntc.name(colorHex);

    if (!isString(colorName)) return null;

    const customColorName = customColorNameMapping[colorName as keyof typeof customColorNameMapping];

    return customColorName || colorName;
};
