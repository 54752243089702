// Action types
export const POI_SET = 'POI_SET';
export const POI_REMOVE = 'POI_REMOVE';
export const POI_ADD_MULTIPLE = 'POI_ADD_MULTIPLE';
export const POI_REMOVE_MULTIPLE = 'POI_REMOVE_MULTIPLE';
export const POI_MULTIPLE_SEEN = 'POI_MULTIPLE_SEEN';

export enum PoiType {
    search = 'SEARCH',
    element = 'ELEMENT',
    activity = 'ACTIVITY',
}
