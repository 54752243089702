import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CursorRemotePenIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        className="Icon cursor-remote-pen"
        {...props}
    >
        <defs>
            <filter
                id="CursorRemotePenIcon__a"
                width="203.3%"
                height="203.6%"
                x="-51.7%"
                y="-46.3%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter
                id="CursorRemotePenIcon__b"
                width="154.4%"
                height="154.5%"
                x="-27.2%"
                y="-21.8%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.3 0"
                />
            </filter>
            <path
                id="CursorRemotePenIcon__c"
                d="m16.52.553.13.12 1.062 1.063c.8.8.894 2.052.249 2.959l-.108.14-1.682 2.02a2.485 2.485 0 0 1-.53 1.062l-.141.153-5.744 5.643a9.5 9.5 0 0 1-2.13 1.601l-.34.178-2.838 1.42a1.497 1.497 0 0 1-.544.153l-2.472 1.182a1 1 0 0 1-1.378-1.226l.045-.108 1.183-2.468c.012-.14.043-.279.095-.413l.058-.133 1.42-2.839a9.5 9.5 0 0 1 1.514-2.193l.265-.276L10.38 2.95a2.49 2.49 0 0 1 1.01-.616l2.162-1.8a2.3 2.3 0 0 1 2.969.02z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#CursorRemotePenIcon__a)" transform="translate(3 3)">
            <use xlinkHref="#CursorRemotePenIcon__c" fill="#000" filter="url(#CursorRemotePenIcon__b)" />
            <use xlinkHref="#CursorRemotePenIcon__c" fill="#2F63F7" />
            <path
                fill="#FFF"
                d="M11.534 3.926a1 1 0 0 1 1.225 0l.095.083 1.585 1.586.084.094a1 1 0 0 1 0 1.226l-.084.094-5.744 5.643-.266.254a8 8 0 0 1-1.488 1.072l-.325.172-2.839 1.42-1-1 1.42-2.839.172-.325A8 8 0 0 1 5.44 9.918l.254-.266 5.744-5.643zM14.662 1.7a.7.7 0 0 1 .856.105l1.063 1.063.07.08a.7.7 0 0 1-.027.863l-.86 1.033a2.518 2.518 0 0 0-.264-.31L13.914 2.95l-.123-.115a2.5 2.5 0 0 0-.262-.201l1.046-.87z"
            />
        </g>
    </svg>
);
const Memo = memo(CursorRemotePenIcon);
export default Memo;
