// Lib
import React, { ReactElement } from 'react';

// Utils
import { getFetching } from '../attachments/attachmentsSelector';
import { getLinkType, getLinkUrl } from '../../../common/elements/utils/elementPropertyUtils';
import { getLocalError } from '../local/elementLocalDataUtils';

// Components
import Spinner from '../../components/loaders/Spinner';
import Icon from '../../components/icons/Icon';

// Constants
import { LINK_ELEMENT_LINK_TYPES } from '../../../common/links/linkConstants';

// Styles
import './LinkStateDisplay.scss';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { ImElementLocalData } from '../local/elementLocalDataTypes';
import { AttachmentState } from '../attachments/attachmentTypes';

const getDefaultLinkIconName = (element: ImMNElement): string => {
    switch (getLinkType(element)) {
        case LINK_ELEMENT_LINK_TYPES.VIDEO:
            return 'link-video-icon';
        case LINK_ELEMENT_LINK_TYPES.AUDIO:
            return 'link-audio-icon';
        case LINK_ELEMENT_LINK_TYPES.MAP:
            return 'link-map-icon';
        default:
            return 'link-icon';
    }
};

type LinkStateDisplayProps = {
    element: ImMNElement;
    elementLocalData: ImElementLocalData;
    attachment: AttachmentState;
};

const LinkStateDisplay = ({ element, elementLocalData, attachment }: LinkStateDisplayProps): ReactElement => {
    const fetching = !getLinkUrl(element) && getFetching(attachment);
    const error = getLocalError(elementLocalData);

    let displayElement = <Icon name={getDefaultLinkIconName(element)} />;

    if (error) {
        displayElement = <Icon name="link-fail" />;
    }

    if (fetching) {
        displayElement = <Spinner show strokeWeightMultiplier={2} />;
    }

    return <div className="LinkStateDisplay">{displayElement}</div>;
};

export default LinkStateDisplay;
