import * as Immutable from 'immutable';

import {
    ELEMENT_SEARCH_SUBMIT,
    ELEMENT_SEARCH_SUCCESS,
    ELEMENT_SEARCH_FAILURE,
    ELEMENT_SEARCH_CLEAR,
    ELEMENT_SEARCH_SET_QUERY,
} from './searchPopupConstants';

const EMPTY_LIST = Immutable.List();

const initialState = Immutable.Map({
    loading: false,
    results: EMPTY_LIST,
    resultsTotal: 0,
    searchResultsList: EMPTY_LIST,
    query: '',
    submittedQuery: null,
    error: null,
});

const addSearchResults = (state, { results, append, resultsTotal, searchResults }) => {
    const currentResults = append ? state.get('results') : EMPTY_LIST;
    const currentResultIds = currentResults.map((r) => r.get('id'));

    const newResults = Immutable.fromJS(results).filter((result) => !currentResultIds.includes(result.get('id')));

    return state
        .set('results', currentResults.concat(newResults))
        .set('searchResultsList', searchResults ? Immutable.fromJS(searchResults) : EMPTY_LIST)
        .set('resultsTotal', resultsTotal);
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_SEARCH_SET_QUERY:
            return state
                .set('loading', true)
                .set('results', EMPTY_LIST)
                .set('searchResultsList', action.searchResults ? Immutable.fromJS(action.searchResults) : EMPTY_LIST)
                .set('submittedQuery', '')
                .set('query', action.query);
        case ELEMENT_SEARCH_SUBMIT:
            return state
                .set('loading', true)
                .set('query', action.query)
                .set('submittedQuery', action.query)
                .set('searchResultsList', action.searchResults ? Immutable.fromJS(action.searchResults) : EMPTY_LIST)
                .set('results', EMPTY_LIST)
                .set('error', null);
        case ELEMENT_SEARCH_SUCCESS:
            return addSearchResults(state.set('loading', false), action);
        case ELEMENT_SEARCH_FAILURE:
            return state.set('loading', false).set('results', EMPTY_LIST).set('error', action.error);
        case ELEMENT_SEARCH_CLEAR:
            return initialState;
        default:
            return state;
    }
};
