import React from 'react';
import loadable from 'react-loadable';

const EmptyLoader = () => <div style={{ width: 'var(--checkout-modal-default-width)' }}></div>;

export const LoadableCheckoutProductsModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "checkout-products" */
            './product/ProductUpgradeModal'
        ),
    loading: EmptyLoader,
});

export const LoadableCheckoutReferModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "checkout-refer-friend" */
            './refer/ReferFriendModal'
        ),
    loading: EmptyLoader,
});

export const LoadableCheckoutProductPricingModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "checkout-product-pricing" */
            './productPricing/ProductPricingModal'
        ),
    loading: EmptyLoader,
});

export const LoadableCheckoutProductTeamsModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "checkout-product-teams" */
            './team/TeamManagementModal'
        ),
    loading: EmptyLoader,
});

export const LoadableCheckoutPaymentModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "checkout-payment" */
            './payment/CheckoutPaymentModal'
        ),
    loading: EmptyLoader,
});

export const LoadableCheckoutSuccessModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "checkout-success" */
            './success/CheckoutSuccessModal'
        ),
    loading: EmptyLoader,
});

export const preloadAllCheckoutModals = () => {
    LoadableCheckoutProductsModal.preload();
    LoadableCheckoutReferModal.preload();
    LoadableCheckoutProductPricingModal.preload();
    LoadableCheckoutProductTeamsModal.preload();
};
