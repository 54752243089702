// Utils
import { prop } from '../../../common/utils/immutableHelper';
import { getSuggestionsMap } from './suggestionUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';

// Selector
import { getElements } from '../selectors/elementsSelector';
import { getElementLocalData } from '../local/elementLocalDataSelector';

// Actions
import { setElementLocalData } from '../local/elementLocalDataActions';

const handleSuggestionChange =
    (willSuggest) =>
    ({ id, feature }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);
        const element = getElement(elements, id);
        const elementLocalData = getElementLocalData(state, { element });

        if (!element) return;

        let currentSuggestionState = getSuggestionsMap(elementLocalData);
        currentSuggestionState = currentSuggestionState ? currentSuggestionState.toJS() : {};
        const alreadySuggestedFeature = !!prop(feature, currentSuggestionState);

        if (willSuggest === alreadySuggestedFeature) return;

        return dispatch(
            setElementLocalData({
                id,
                data: {
                    suggestions: {
                        ...currentSuggestionState,
                        [feature]: willSuggest,
                    },
                },
            }),
        );
    };

export const elementSuggestFeature = handleSuggestionChange(true);
export const elementUnsuggestFeature = handleSuggestionChange(false);
