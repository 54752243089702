// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getHasDrawing } from '../../../../../common/elements/utils/elementPropertyUtils';

// Components
import ImageDrawing from '../../ImageDrawing';
import ImageModalDrawingModeContent from './ImageModalDrawingModeContent';

// Utils
import { IMAGE_MODAL_MODES } from '../imageModalConstants';

/**
 * This component will either render the read only drawing SVG, or the editor, depending on the
 * current "mode" of the ImageModal.
 */
const ImageModalDrawingSwitch = (props) => {
    const { mode, element } = props;

    if (mode === IMAGE_MODAL_MODES.DRAW) return <ImageModalDrawingModeContent {...props} />;

    return getHasDrawing(element) ? <ImageDrawing {...props} /> : null;
};

ImageModalDrawingSwitch.propTypes = {
    element: PropTypes.object,
    mode: PropTypes.string,
};

export default ImageModalDrawingSwitch;
