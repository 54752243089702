export const CHECKOUT_STATE = 'milanote.checkout.state';
export const CHECKOUT_NAVIGATION_STACK = 'milanote.checkout.navigationStack';

export const CHECKOUT_PRODUCTS_ROUTE = '/checkout/products';
export const CHECKOUT_TEAM_PRODUCT_ROUTE = '/checkout/products/team';
export const CHECKOUT_TEAM_MEMBERS_ROUTE = '/checkout/members';
export const CHECKOUT_REFER_ROUTE = '/checkout/refer';
export const CHECKOUT_SUCCESS_ROUTE = '/checkout/success';

// Legacy Checkout V1 routes
// TODO-CHECKOUT: Remove these constants once we have fully migrated to Checkout V2
export const V1_PLAN_UPGRADE_ROUTE = '/free-plan/upgrade';
export const V1_PLAN_REFER_ROUTE = '/free-plan/refer';
export const V1_PLAN_UPDATE_CARD_ROUTE = '/plan/card';
export const V1_PLAN_PAYMENT_ROUTE = '/plan/payment';
export const V1_PLAN_TEAM_ROUTE = '/plan/team';
export const V1_PLAN_SELECT_TYPE_ROUTE = '/plan/select-type';

export const CHECKOUT_V1_TO_V2_PATH_MAPPINGS: { [key: string]: string } = {
    [V1_PLAN_UPGRADE_ROUTE]: CHECKOUT_PRODUCTS_ROUTE,
    [V1_PLAN_REFER_ROUTE]: CHECKOUT_REFER_ROUTE,
    [V1_PLAN_UPDATE_CARD_ROUTE]: '/account/plan',
    [V1_PLAN_PAYMENT_ROUTE]: '/account/plan',
    [V1_PLAN_TEAM_ROUTE]: CHECKOUT_TEAM_MEMBERS_ROUTE,
    [V1_PLAN_SELECT_TYPE_ROUTE]: CHECKOUT_PRODUCTS_ROUTE,
};

export const CHECKOUT_V2_TO_V1_PATH_MAPPINGS: { [key: string]: string } = {
    [CHECKOUT_PRODUCTS_ROUTE]: V1_PLAN_UPGRADE_ROUTE,
    [CHECKOUT_TEAM_PRODUCT_ROUTE]: V1_PLAN_UPGRADE_ROUTE,
    [CHECKOUT_TEAM_MEMBERS_ROUTE]: V1_PLAN_TEAM_ROUTE,
    [CHECKOUT_REFER_ROUTE]: V1_PLAN_REFER_ROUTE,
    [CHECKOUT_SUCCESS_ROUTE]: '/',
};
