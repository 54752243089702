// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import controlPointDragSource from './controlPointDragSource';
import ControlPointDragHandle from './ControlPointDragHandle';

// Styles
import { stopPropagationOnly } from '../../utils/domUtil';

const ControlPointDragHandleDragSource = controlPointDragSource(ControlPointDragHandle);

/**
 * Needs to be a React component so that the parent's (Line) ref connects correctly.
 */
/* eslint-disable react/no-multi-comp */
class ConnectedControlPointDragHandle extends React.Component {
    onDoubleClick = (event) => {
        this.props.updateLineControlPoint({ control: null });

        event.stopPropagation();
        event.preventDefault();
    };

    render() {
        const { controlPoint, show } = this.props;
        if (!show) return null;

        return (
            <ControlPointDragHandleDragSource
                className="ControlPointDragHandle"
                isHitArea
                onMouseDown={stopPropagationOnly}
                onDoubleClick={this.onDoubleClick}
                pos={controlPoint}
                {...this.props}
            />
        );
    }
}

ConnectedControlPointDragHandle.propTypes = {
    show: PropTypes.bool,
    startEdgeOrigin: PropTypes.object,
    endEdgeOrigin: PropTypes.object,
    color: PropTypes.string,
    controlPoint: PropTypes.object,
    updateLineControlPoint: PropTypes.func,
    dispatchPointDragStart: PropTypes.func,
};

export default ConnectedControlPointDragHandle;
