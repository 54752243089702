// Constants
import { DRAG_START, DRAG_END, REPLACE_MODE_START, REPLACE_MODE_END } from './draggingConstants';

export const dragStart = ({ ids, source, finishEditing = true } = {}) => ({
    type: DRAG_START,
    ids,
    source,
    finishEditing,
});
export const dragEnd = (dropState) => ({ type: DRAG_END, dropState });
export const startReplaceMode = (elementId) => ({ type: REPLACE_MODE_START, elementId });
export const endReplaceMode = () => ({ type: REPLACE_MODE_END });
