import { STYLE_COMMANDS } from '../../richText/richTextConstants';

export default (blockType) => {
    switch (blockType) {
        case STYLE_COMMANDS.BLOCKQUOTE:
        case STYLE_COMMANDS.CODE_BLOCK:
        case STYLE_COMMANDS.LIST:
        case STYLE_COMMANDS.ORDERED_LIST:
        case STYLE_COMMANDS.CHECKLIST:
            return false;
        default:
            return true;
    }
};
