/**
 * This file contains utility functions for comparing colours using the Milanote colour system.
 */
// Lib
import chroma, { Color } from 'chroma-js';

// Utils
import { getIsLightColor } from './coreColorUtil';
import { getElementColorHexValue } from './elementColorFormatUtil';
import { getIsLowContrastPredefinedColorCss, isPredefinedVarColorFormat, PredefinedColor } from './colorPresetsUtil';

// Constants
import { COLORS, ICON_COLORS, TEXT_COLOUR_DARK, TEXT_COLOUR_LIGHT } from './colorConstants';

/**
 * Determines if the specified colour will have low contrast with the interface colour (e.g. header, popup, etc)
 * of the current theme.
 */
export const getIsLowContrastColorWithInterface = (colorCss: string, isDarkModeTheme: boolean): boolean => {
    if (isPredefinedVarColorFormat(colorCss)) return getIsLowContrastPredefinedColorCss(colorCss, isDarkModeTheme);

    const colorHex = getElementColorHexValue(colorCss);

    if (!colorHex) return !isDarkModeTheme;

    const comparisonColor = isDarkModeTheme ? TEXT_COLOUR_DARK : TEXT_COLOUR_LIGHT;

    const contrast = chroma.contrast(comparisonColor, colorHex);
    // harder to tell dark shades apart than light shades
    const minimumContrast = isDarkModeTheme ? 1.6 : 1.2;

    return contrast <= minimumContrast;
};

/**
 * Determines if an element colour is light or dark.
 * This can convert a predefined colour name to a hex value using a colour map.
 */
export const getIsLightElementColor = (
    elementColor: string | Color | null | undefined,
    isDefaultLight = true,
    predefinedMap: { [key: string]: PredefinedColor } = COLORS,
): boolean => {
    if (!elementColor) return isDefaultLight;

    const predefinedColor = predefinedMap[elementColor as keyof typeof predefinedMap];

    const color = predefinedColor?.hex || elementColor;

    return getIsLightColor(color);
};

/**
 * Determines if the colour is light for the purposes of icon views.
 */
export const getIsLightIconViewColor = (color: string): boolean => getIsLightElementColor(color, true, ICON_COLORS);
