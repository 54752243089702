import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuCutIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-cut"
        {...props}
    >
        <g fill="#323B4A">
            <path d="M6.824 2.25c1.976 0 3.573 1.624 3.573 3.622 0 1.997-1.597 3.622-3.573 3.622-1.977 0-3.574-1.625-3.574-3.622 0-1.998 1.597-3.622 3.574-3.622zm0 1.5c-1.143 0-2.074.947-2.074 2.122 0 1.174.931 2.122 2.074 2.122 1.142 0 2.073-.948 2.073-2.122 0-1.175-.931-2.122-2.073-2.122z" />
            <path d="M8.293 7.366a.75.75 0 0 1 1.06.01l3.652 3.714a.75.75 0 0 1-1.07 1.051l-3.65-3.714a.75.75 0 0 1 .009-1.06z" />
            <path d="M19.465 3.431a.75.75 0 0 1 1.07 1.052L9.354 15.855a.75.75 0 1 1-1.07-1.051L19.465 3.43z" />
            <path d="M6.824 13.737c1.976 0 3.573 1.625 3.573 3.622 0 1.997-1.597 3.622-3.573 3.622-1.977 0-3.574-1.625-3.574-3.622 0-1.997 1.597-3.622 3.574-3.622zm0 1.5c-1.143 0-2.074.947-2.074 2.122 0 1.175.931 2.122 2.074 2.122 1.142 0 2.073-.947 2.073-2.122 0-1.175-.931-2.122-2.073-2.122zm7.756-1.477a.75.75 0 0 1 1.06.01l4.895 4.978a.75.75 0 0 1-1.07 1.051l-4.894-4.977a.75.75 0 0 1 .01-1.061z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuCutIcon);
export default Memo;
