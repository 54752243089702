global.drawing = {
    overlayOpacity: 0.6,
    strokeWidth: 0,

    thinning: 0.6,
    smoothing: 0.5,
    streamline: 0.5,
    startTaper: 0,
    endTaper: 0,
};
