// Lib
import loadable from 'react-loadable';

const LoadableEmailVerificationRequiredModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "verification-required" */
            './EmailVerificationRequiredModal'
        ),
    loading: () => null,
});

export default LoadableEmailVerificationRequiredModal;
