import { isString } from 'lodash';

export const objectIdCreationSeconds = (objectId) => (isString(objectId) ? parseInt(objectId.substring(0, 8), 16) : 0);

export const dateFromObjectId = (objectId) => new Date(objectIdCreationSeconds(objectId) * 1000);

export const getEarliestObjectId = (objectIds = []) => {
    const { earliestObjectId } = objectIds.reduce(
        (acc, objectId) => {
            const { earliestTimestamp } = acc;

            const userCreationTimestamp = objectIdCreationSeconds(objectId.toString());

            if (!earliestTimestamp || userCreationTimestamp < earliestTimestamp) {
                return {
                    earliestTimestamp: userCreationTimestamp,
                    earliestObjectId: objectId,
                };
            }

            return acc;
        },
        { earliestTimestamp: 0, earliestObjectId: null },
    );

    return earliestObjectId;
};

export const convertObjectIdToString = (id) => id && (id.str || id.toString());
export const safeObjectIdToString = (id) => (id && (id.str || id.toString) ? convertObjectIdToString(id) : id);
