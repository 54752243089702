// Lib
import { createSelector } from 'reselect';
import { values } from 'lodash';

// Selectors
import { createShallowSelector } from '../utils/milanoteReselect/milanoteReselect';
import { getElements } from '../element/selectors/elementsSelector';
import { getUsers } from '../user/usersSelector';
import { getCurrentUserId } from '../user/currentUserSelector';
import { getAclIdsSelector } from '../utils/permissions/permissionsSelector';
import { getUserId, getUserCurrentBoardId } from '../../common/users/utils/userPropertyUtils';

// Utils
import { isOnline } from '../user/userUtil';
import { canGiveFeedbackOnBoard } from '../../common/permissions/elementPermissionsUtil';

export const getActiveUsers = createShallowSelector(getUsers, getCurrentUserId, (users, currentUserId) =>
    users.filter((user) => getUserId(user) !== currentUserId && isOnline(user)),
);

const buildBoardIdToUsersMap = (users, elements) =>
    users.reduce((boardIdMap, user) => {
        const usersCurrentBoardId = getUserCurrentBoardId(user);

        boardIdMap[usersCurrentBoardId] = boardIdMap[usersCurrentBoardId] || {
            board: elements.get(usersCurrentBoardId),
            users: [],
        };

        boardIdMap[usersCurrentBoardId].users.push(user);

        return boardIdMap;
    }, {});

export const getActiveUsersOnCollaborativeBoards = createShallowSelector(
    getActiveUsers,
    getElements,
    getAclIdsSelector,
    (users, elements, aclIds) =>
        users.filter((user) => {
            const userCurrentBoardId = getUserCurrentBoardId(user);
            return canGiveFeedbackOnBoard(elements, userCurrentBoardId, aclIds);
        }),
);

const getActiveUsersByCollaborativeBoardsMap = createSelector(
    getActiveUsersOnCollaborativeBoards,
    getElements,
    buildBoardIdToUsersMap,
);

export const getActiveUsersByCollaborativeBoards = createSelector(
    getActiveUsersByCollaborativeBoardsMap,
    (activeUsersByBoardsMap) => values(activeUsersByBoardsMap),
);

export const getActiveUsersOnCollaborativeBoardsCount = createSelector(
    getActiveUsersOnCollaborativeBoards,
    (activeUsers) => activeUsers.size,
);
