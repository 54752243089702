// Line constants
export const LINE_HIT_AREA = 20;
export const INSET = LINE_HIT_AREA / 2;

export const LINE_SVG_WIDTH = 12;
export const LINE_SVG_HEIGHT = 13;

export const LINE_COLUMN_ALIGNMENT_THRESHOLD = 3;
export const LINE_COLUMN_TITLE_VERTICAL_CENTRE = 3.2;

// Drag and drop
export const LINE_POINT_DRAG_START = 'LINE_POINT_DRAG_START';
