// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';

// Selectors
import { getElementChildIdsSelector } from '../selectors/elementChildrenSelector';

// Components
import CommentAttached from './CommentAttached';

// Styles
import './AttachedCommentsContainer.scss';

const mapStateToProps = () =>
    createStructuredSelector({
        childIds: getElementChildIdsSelector(),
    });

const AttachedCommentsContainer = (props) => {
    const { childIds } = props;

    if (isEmpty(childIds)) return null;

    return (
        <div className="AttachedCommentsContainer">
            {childIds.map((childId) => (
                <CommentAttached key={childId} {...props} element={null} elementId={childId} />
            ))}
        </div>
    );
};

AttachedCommentsContainer.propTypes = {
    elementId: PropTypes.string,
    element: PropTypes.object,
    childIds: PropTypes.array,
    permissions: PropTypes.number,
    gridSize: PropTypes.number,
    isPresentational: PropTypes.bool,
};

export default connect(mapStateToProps)(AttachedCommentsContainer);
