// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from '../../../node_module_clones/react-transition-group';

// Components
import Icon from '../../components/icons/Icon';

// Utils
import classNames from 'classnames';

// Styles
import './TaskDeleteButton.scss';

class TaskDeleteButton extends React.Component {
    moveToTrash = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const { elementId, moveToTrashHandler } = this.props;

        return moveToTrashHandler({ elementId });
    };

    render() {
        const { show, className } = this.props;

        const component = show ? (
            <div
                className={classNames('TaskDeleteButton', 'task-utility-button', className)}
                onMouseDown={this.moveToTrash}
            >
                <div className="background" />
                <Icon name="task-trash" />
            </div>
        ) : null;

        return (
            <div className="delete-button-container">
                <CSSTransitionGroup
                    transitionName="slide"
                    transitionAppear
                    transitionAppearTimeout={200}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                >
                    {component}
                </CSSTransitionGroup>
            </div>
        );
    }
}

TaskDeleteButton.propTypes = {
    show: PropTypes.bool,
    elementId: PropTypes.string.isRequired,
    className: PropTypes.string,
    moveToTrashHandler: PropTypes.func,
};

export default TaskDeleteButton;
