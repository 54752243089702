// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { now, noLonger, hasChanged } from '../../utils/react/propsComparisons';
import { hasCommandModifier } from '../../utils/keyboard/keyboardUtility';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateLineEdge } from './lineActions';
import { startEditingElement } from '../selection/selectionActions';

// Components
import LinePresentationalWrapper from './LinePresentationalWrapper';

// Constants
import { NON_CHARACTERS } from '../../utils/keyboard/keyConstants';
import { LINE_POINT_DRAG_START } from './lineUiConstants';

const isNowSingleSelected = now('isSingleSelected');
const isNoLongerEditing = noLonger('isEditing');
const elementHasChanged = hasChanged('element');
const isNoLongerInvisible = noLonger('invisible');

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateLineEdge: ({ element, edge, newPosition, newControl, transactionId }) =>
        dispatch(updateLineEdge({ element, edge, newPosition, newControl, transactionId })),
    // This is used to hide the tooltip during on-boarding
    dispatchPointDragStart: () => dispatch({ type: LINE_POINT_DRAG_START }),
    dispatchStartEditing: ({ id, editorKey, editorId }) => dispatch(startEditingElement({ id, editorKey, editorId })),
});

@connect(null, mapDispatchToProps)
class LineContainer extends React.Component {
    constructor(props) {
        super(props);

        this.lineRef = React.createRef();

        this.state = { invisible: false };
    }

    componentDidUpdate(prevProps, prevState) {
        const { isEditing, isSingleSelected } = this.props;

        const shouldFocus =
            (isNowSingleSelected(prevProps, this.props) && !isEditing) ||
            (isNoLongerEditing(prevProps, this.props) && isSingleSelected) ||
            (elementHasChanged(prevProps, this.props) && isSingleSelected && !isEditing) ||
            (isNoLongerInvisible(prevState, this.state) && isSingleSelected && !isEditing);

        // Focus the line so that the key down handler will work
        if (shouldFocus && this.lineRef.current) this.lineRef.current.focus();
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    updateLineControlPoint = ({ control }) => {
        const { dispatchUpdateElement, element } = this.props;

        dispatchUpdateElement({
            id: getElementId(element),
            changes: { control },
        });
    };

    updateLineEdge = ({ edge, newPosition, newControl, transactionId }) => {
        const { element, dispatchUpdateLineEdge } = this.props;
        return dispatchUpdateLineEdge({ element, edge, newPosition, newControl, transactionId });
    };

    toggleLineVisibility = () => {
        if (this.unmounted) return;

        this.setState((prevState) => ({
            invisible: !prevState.invisible,
        }));
    };

    /**
     * If an actual character is typed, start editing the line so the character will be inserted.
     */
    onKeyDown = (event) => {
        if (hasCommandModifier(event)) {
            return;
        }

        // don't trigger a start editing event when non-character keys are pressed
        if (NON_CHARACTERS.indexOf(event.which) !== -1) {
            return;
        }

        const { elementId, dispatchStartEditing } = this.props;

        // Start editing on keydown, then the ElementSimpleContentEditable will
        // accept the keypress and actually add the character
        dispatchStartEditing({
            id: elementId,
            editorId: getMainEditorId(this.props),
            editorKey: getMainEditorKey(this.props),
        });
    };

    render() {
        const { invisible } = this.state;
        return (
            <LinePresentationalWrapper
                lineRef={this.lineRef}
                className={classNames({ invisible })}
                onKeyDown={this.onKeyDown}
                updateLineEdge={this.updateLineEdge}
                updateLineControlPoint={this.updateLineControlPoint}
                toggleLineVisibility={this.toggleLineVisibility}
                showStartGhost={this.props.isSelected}
                showEndGhost={this.props.isSelected}
                {...this.props}
                invisible={invisible}
            />
        );
    }
}

LineContainer.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string.isRequired,
    isEditing: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,

    dispatchUpdateLineEdge: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    dispatchStartEditing: PropTypes.func,
};

export default LineContainer;
