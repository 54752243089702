import * as validatorRegistry from './validators/index';

const validatorNames = Object.keys(validatorRegistry);

/**
 * Uses the registered validators to determine whether the specified field value is valid given its field config.
 *
 * @param fieldValue {String} The value of the field.
 * @param validationConfig {Object} An object that specifies the validations to apply to the field value.
 *          See validationConstants.js.
 * @returns {Object|null} null if the field value is valid, otherwise an object describing the validation failure.
 */
export default (fieldValue, validationConfig) => {
    let errorObject = null;

    for (const validatorName of validatorNames) {
        // eslint-disable-next-line import/namespace
        const validator = validatorRegistry[validatorName];

        // Perform the validation
        errorObject = validator(fieldValue, validationConfig);

        // Short circuit on error to prevent unneeded validation tests
        if (errorObject) break;
    }

    return errorObject;
};
