import { sample } from 'lodash/fp';
import { getIconId } from '../../../common/elements/utils/elementPropertyUtils';

export const pickUnusedIcon = (icons, peerBoards) => {
    const existingIconsMap = new Map();

    // Initialise the existing icons map
    peerBoards.forEach((board) => {
        const iconId = getIconId(board);
        existingIconsMap.set(iconId, true);
    });

    // Find the first icon that hasn't already been used
    for (const icon of icons) {
        if (icon && !existingIconsMap.get(icon.id)) return icon;
    }

    // Otherwise just pick a random icon
    return sample(icons);
};
