import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementBoardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-board"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#5882F8" rx={8} />
            <rect width={7} height={7} x={8} y={8} fill="#FFF" rx={2} />
            <rect width={7} height={7} x={8} y={17} fill="#FFF" fillOpacity={0.75} rx={2} />
            <rect width={7} height={7} x={17} y={8} fill="#FFF" fillOpacity={0.25} rx={3.5} />
            <rect width={7} height={7} x={17} y={17} fill="#FFF" fillOpacity={0.5} rx={2} />
        </g>
    </svg>
);
const Memo = memo(ElementBoardIcon);
export default Memo;
