import { last, compact } from 'lodash/fp';

const getSvgImageDimensions = (imageDataUri) => {
    try {
        const svgBase64 = last(imageDataUri.split(','));
        const svgString = atob(svgBase64);

        const widthMatch = svgString.match(/<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/);
        const heightMatch = svgString.match(/<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/);

        const width = widthMatch && parseInt(widthMatch[1], 10);
        const height = heightMatch && parseInt(heightMatch[1], 10);

        if (width && height) {
            return {
                width,
                height,
            };
        }

        const viewboxMatch = svgString.match(/<svg[^>]*viewBox\s*=\s*\"?([\d ,.]+)\"?[^>]*>/);
        const viewBox = viewboxMatch && viewboxMatch[1];

        if (viewBox) {
            const parts = compact(viewBox.split(/[ ,]+/)).map((part) => parseInt(part, 10));

            const vbLeft = parts[0];
            const vbTop = parts[1];
            const vbWidth = parts[2];
            const vbHeight = parts[3];

            return {
                width: vbWidth - vbLeft,
                height: vbHeight - vbTop,
            };
        }
    } catch (err) {
        console.info('error parsing svg dimensions', err);
    }
};

export default getSvgImageDimensions;
