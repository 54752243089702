// Utils
import hasSelectionInCodeBlock from './utils/hasSelectionInCodeBlock';
import removeIndentOnCloseBrace from './utils/removeIndentOnCloseBrace';

const shouldAttemptIndentRemoval = (event) => event.key === '}' || event.key === ']' || event.key === ')';

export default (event, { getEditorState, setEditorState }) => {
    const editorState = getEditorState();
    if (!hasSelectionInCodeBlock(editorState)) return;

    if (shouldAttemptIndentRemoval(event)) return removeIndentOnCloseBrace(event, { getEditorState, setEditorState });
};
