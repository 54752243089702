// Utils
import logger from '../../logger/logger';

/**
 * Adds some state to the React synthetic event to state that the event has already been "handled".
 * At the time of writing this is being used by the ElementContainers to ensure that parent ElementContainers
 * don't handle events that occur on child ElementContainers, as well as the ElementSimpleContentEditable to prevent
 * the ElementContainer from stealing focus away from the edited text field.
 */
export const markEventAsHandled = (event) => {
    if (!event?.persist) {
        logger.warn(
            "markEventAsHandled: An event that cannot be persisted was provided. Are you sure it's a react event?",
            event,
        );
        return;
    }

    event.persist();
    event.handled = true;
};
