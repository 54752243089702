import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const FeatureTickIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={12}
        viewBox="0 0 16 12"
        className="Icon feature-tick"
        {...props}
    >
        <path d="M5.695 12 16 2.09 13.827 0 5.695 7.805 2.173 4.433 0 6.523z" />
    </svg>
);
const Memo = memo(FeatureTickIcon);
export default Memo;
