import Icon from '../../icons/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './NumberInput.scss';
export const NumberInput = ({
    className,
    value,
    maxValue,
    minValue,
    onChange,
    onIncrease,
    onDecrease,
    placeholder,
    showButtons,
}) => {
    const defaultOnIncrease = () => {
        const numberValue = Number(value);
        if (numberValue >= maxValue) return;

        onChange(isNaN(numberValue) ? 0 : numberValue + 1);
    };

    const defaultOnDecrease = () => {
        const numberValue = Number(value);
        if (numberValue <= minValue || isNaN(numberValue)) return;

        onChange(numberValue - 1);
    };

    return (
        <div className="NumberInput">
            <input
                className={classNames('number-input', className)}
                type="number"
                min={minValue}
                max={maxValue}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            {showButtons && (
                <div className="number-input-buttons">
                    <button onClick={onIncrease || defaultOnIncrease} className="up-button">
                        <Icon name="button-toggle-top" />
                    </button>
                    <button onClick={onDecrease || defaultOnDecrease} className="down-button">
                        <Icon name="button-toggle-down" />
                    </button>
                </div>
            )}
        </div>
    );
};

NumberInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    onChange: PropTypes.func,
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    placeholder: PropTypes.string,
    showButtons: PropTypes.bool,
};

export default NumberInput;
