import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '../../../node_module_clones/react-contextmenu';

import { getIsTemplate } from '../../../common/templates/templateUtil';

const ConvertTemplateContextMenuItems = ({ show, selectedElements, convertBoardToTemplate, revertTemplateToBoard }) => {
    if (!show) return null;

    const selectedBoard = selectedElements.first();

    const isTemplate = getIsTemplate(selectedBoard);

    return isTemplate ? (
        <MenuItem onClick={revertTemplateToBoard}>Revert template to board</MenuItem>
    ) : (
        <MenuItem onClick={convertBoardToTemplate}>Convert to template</MenuItem>
    );
};

ConvertTemplateContextMenuItems.propTypes = {
    show: PropTypes.bool,
    selectedElements: PropTypes.object,
    convertBoardToTemplate: PropTypes.func,
    revertTemplateToBoard: PropTypes.func,
    addTemplateEmptyState: PropTypes.func,
    removeTemplateEmptyState: PropTypes.func,
    navigateToTemplateTagEditor: PropTypes.func,
};

export default ConvertTemplateContextMenuItems;
