import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ImageFormUploadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={38}
        viewBox="0 0 50 38"
        className="Icon image-form-upload"
        {...props}
    >
        <path
            fill="#BBBEC3"
            fillRule="evenodd"
            d="M27 21.828V37a1 1 0 0 1-2 0V21.828l-4.586 4.586A1 1 0 0 1 19 25l6.646-6.646a.5.5 0 0 1 .708 0L33 25a1 1 0 0 1-1.414 1.414L27 21.828ZM50 27c0 5.951-4.688 11-10.488 11H34a1 1 0 0 1 0-2h5.512C44.192 36 48 31.86 48 27c0-4.491-3.27-8.332-7.534-8.825l-.478-.055a.5.5 0 0 1-.441-.536l.037-.48c.03-.372.044-.75.044-1.135C39.628 8.247 33.455 2 26 2c-6.683 0-12.226 5.052-13.237 11.82l-.061.412a.5.5 0 0 1-.477.426l-.416.015C6.354 14.864 2 19.322 2 25c0 5.814 4.557 11 10.163 11H18a1 1 0 0 1 0 2h-5.837C5.438 38 0 31.905 0 25c0-6.46 4.768-11.63 10.924-12.27C12.39 5.398 18.578 0 26 0c8.574 0 15.628 7.156 15.628 15.969 0 .124-.002.247-.004.37C46.442 17.351 50 21.829 50 27Z"
        />
    </svg>
);
const Memo = memo(ImageFormUploadIcon);
export default Memo;
