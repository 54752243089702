// Utils
import {
    getImageProp,
    getImageError,
    getFileProp,
    getShowMedia,
    getImageColors,
    getFileUploadedTimestamp,
} from '../../../../common/elements/utils/elementPropertyUtils';
import { getData, getPercentageComplete } from '../../attachments/attachmentsSelector';
import fileCanShowMedia from './fileCanShowMedia';
import fileGetMediaPlayerType from './fileGetMediaPlayerType';
import { getNaturalImageWidth } from '../../image/imageElementUtils';
import { prop } from '../../../../common/utils/immutableHelper';

// Constants
import { ELEMENT_DEFAULT_WIDTH, ELEMENT_ATTACHMENT_ACTION_TYPES } from '../../../../common/elements/elementConstants';
import { getTimestamp, TIMES } from '../../../../common/utils/timeUtil';

export const getFileData = ({ element, attachment }) => getFileProp(element) || getData(attachment);
export const hasFileData = ({ element, attachment }) => getFileData({ element, attachment });

export const hasPreviewImage = ({ element }) => !!getImageProp(element) && !getImageError(element);

export const getIsShowingMedia = ({ element, attachment }) =>
    (hasPreviewImage({ element }) || !!fileGetMediaPlayerType(element)) &&
    hasFileData({ element, attachment }) &&
    getShowMedia(element) &&
    fileCanShowMedia(element, attachment);

export const getFileDefaultMaxWidth = ({ element, attachment }) =>
    getIsShowingMedia({ element, attachment }) ? getNaturalImageWidth({ element }) : ELEMENT_DEFAULT_WIDTH;

export const getFileDataFilename = prop('filename');
export const getFileDataType = prop('type');
export const getFileDataSize = prop('size');

export const getFileIsAwaitingPreview = ({ element, attachment }) => {
    const fileData = getFileData({ element, attachment });
    const imageData = getImageProp(element);
    const awaitingPreview = !imageData && fileCanShowMedia(element, fileData);

    return awaitingPreview;
};

export const getFileUploadProgress = ({ element, attachment }) => {
    const awaitingPreview = getFileIsAwaitingPreview({ element, attachment });
    const percentageComplete = getPercentageComplete(attachment);

    // if it's been more than 5 minutes since the file uploaded, then bail out of loading state
    const tenMinutesAgo = getTimestamp() - TIMES.MINUTE * 5;
    if (getFileUploadedTimestamp(element) < tenMinutesAgo) return;

    const fileData = getFileData({ element, attachment });

    // if we have an image, and are waiting extract a color palette, we're in processing state (100)
    const awaitingColors = !!getImageProp(element) && !getImageColors(element) && fileCanShowMedia(element, fileData);
    if (awaitingColors) return 100;

    // if we're not waiting for an image (the file type can't be previewed), bail out
    if (!awaitingPreview && getFileProp(element)) return;

    // if we're waiting for preview, we're in processing state
    if (awaitingPreview) return 100;

    // if we have any uploading loading state, return that
    if (percentageComplete <= 100) return percentageComplete;

    // this prevents a couple-ms flash at the first render of the File, before the attachment is ready
    if (!getFileProp(element)) return 0;
};

export const getFileAttachmentProgress = ({ element, attachment }) => {
    if (!attachment) return;

    const attachmentActionType = prop('actionType', attachment);
    const progressLoaded = prop('loaded', attachment);
    const progressTotal = prop('total', attachment);

    const downloadProgress = (progressLoaded / progressTotal) * 100;
    const uploadProgress = getFileUploadProgress({ element, attachment });

    return attachmentActionType === ELEMENT_ATTACHMENT_ACTION_TYPES.DOWNLOAD ? downloadProgress : uploadProgress;
};
