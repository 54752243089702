// Lib
import React, { DependencyList, EffectCallback } from 'react';

/**
 * Only executes the effect function after mount (e.g. the dependencies are updated, not initialised).
 */
const useUpdateEffect = (effectFn: EffectCallback, deps: DependencyList): void => {
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        return effectFn();
    }, deps);
};

export default useUpdateEffect;
