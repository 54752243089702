// Utils
import { round } from 'lodash';
import { clamp, endsWith } from 'lodash/fp';

/**
 * Converts a value to either a percentage or rem units relative to the min and max values.
 *
 * If the value is within a buffer of the min, max or center, the value will always be a percentage:
 * 0%, 100% or 50% respectively.
 *
 * Otherwise it will depend on the "isRelative" argument. If true the returned value will be a percentage,
 * if not it will be in rems offset from the min value.
 */
export const getSnapCoordinate = ({ val, min, max, isRelative = false, gridSize, buffer = gridSize / 2 }) => {
    if (val < min + buffer) return '0%';
    if (val > max - buffer) return '100%';

    // Not sure if we want to make the centre point relative?
    const centre = (max + min) / 2;
    if (val > centre - buffer / 2 && val < centre + buffer / 2) return '50%';

    if (isRelative) {
        const percentage = round(((val - min) / (max - min)) * 100, 2);
        return `${percentage}%`;
    }

    const rems = round((val - min) / gridSize, 2);
    return `${rems}rem`;
};

/**
 * Converts a snapped value into a pixel value.
 * - If the edge value is a percentage (ends with "%") it will use that ratio between the min and max.
 * - If the edge value is in rems (ends with "rems") it will multiply it by the grid size and clamp to the min and max.
 * - Otherwise it will return null and getSnapDimension will fallback to the center point.
 */
export const getSnapValue = (value, min, max, gridSize = 1) => {
    // If it doesn't exist or it's a number, just fallback to the originOffset
    if (!value || !isNaN(value)) return null;

    if (endsWith('%', value)) {
        const percent = parseFloat(value.substring(0, value.length - 1));

        if (isNaN(percent)) return null;

        return (percent / 100) * (max - min);
    }

    if (endsWith('rem', value)) {
        const rems = parseFloat(value.substring(0, value.length - 3));

        if (isNaN(rems)) return null;

        return clamp(min, min + rems * gridSize, max);
    }

    return null;
};
