import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CheckboxAnimatedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon checkbox-animated"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={16} height={16} fill="#CBCED2" className="fill" rx={2} />
            <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m4 8 3 3 5-5"
                className="tick"
            />
        </g>
        <rect width={15} height={15} x={0.5} y={0.5} fill="none" stroke="#333D4E" className="border" rx={2} />
    </svg>
);
const Memo = memo(CheckboxAnimatedIcon);
export default Memo;
