// Lib
import * as Immutable from 'immutable';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from '../../../../node_module_clones/redux-logger/src';

// Utils
import { isGlobalDebugEnabled } from '../../../debug/debugUtil';
import { isLoggerDebugEnabled, LoggerComponents } from '../../../logger';

// Custom Middleware
import { bulkingMiddleware, enableReducerBatching } from '../../../store/reduxBulkingMiddleware';
import addStateToWindowMiddleware from '../../../store/addStateToWindowMiddleware';

// Reducer
import rootMeasurementsReducer from './reducer';

// Constants
import { BULK_MEASUREMENT_ACTIONS } from '../measurementsConstants';

export default function configureMeasurementsStore(initialState = Immutable.Map()) {
    const middlewareList = [];

    middlewareList.push(thunk);
    middlewareList.push(bulkingMiddleware({ bulkActionTypes: BULK_MEASUREMENT_ACTIONS }));

    if (isGlobalDebugEnabled()) {
        middlewareList.push(addStateToWindowMiddleware('msr_'));
    }

    if (isLoggerDebugEnabled(LoggerComponents.REDUX_LOG_MEASUREMENTS)) {
        middlewareList.push(
            createLogger({
                collapsed: true,
                duration: true,
                timestamp: false,
                colors: {
                    title: (action) => 'palevioletred',
                },
            }),
        );
    }

    const middleware = applyMiddleware.apply(null, middlewareList);

    return createStore(enableReducerBatching(rootMeasurementsReducer), initialState, middleware);
}
