// Lib
import { useEffect } from 'react';
import { isEmpty } from 'lodash/fp';

// Utils
import { getPathStroke } from '../../drawingEditorUtils';
import { getNewPathSmoothing, getNewPathStreamline, getNewPathThinning } from '../../utils/pathUtils';

// Constants
import { DRAWING_PATH_PROPERTIES } from '../../drawingEditorConstants';

const useDrawingEditorSelectedPathModifier = ({
    paths,
    setPaths,
    canvasScale,
    selectedPathIdsMap,
    onPathPropertyChangeRef,
    enableDrawingInkEffect,
}) => {
    // When a path property is changed, update the paths
    onPathPropertyChangeRef.current = ({ pathProperty, value }) => {
        if (isEmpty(selectedPathIdsMap)) return;

        let hasChanges = false;
        const newPaths = paths.map((path) => {
            const { id, hasPressure } = path;

            if (!selectedPathIdsMap[id]) return path;
            if (path[pathProperty] === value) return path;

            hasChanges = true;

            const updatedPath = {
                ...path,
                [pathProperty]: value,
            };

            if (pathProperty === DRAWING_PATH_PROPERTIES.SIZE) {
                updatedPath.size = value / canvasScale;
                updatedPath.thinning = getNewPathThinning({
                    strokeSize: value,
                    hasPressure,
                    enableDrawingInkEffect,
                });
                updatedPath.smoothing = getNewPathSmoothing({
                    strokeSize: value,
                    hasPressure,
                    enableDrawingInkEffect,
                });
                updatedPath.streamline = getNewPathStreamline({
                    strokeSize: value,
                    hasPressure,
                    enableDrawingInkEffect,
                });
                updatedPath.stroke = getPathStroke(updatedPath);
            }

            return updatedPath;
        });

        if (hasChanges) setPaths(newPaths);
    };

    // Remove the callback when we're no longer in select mode
    useEffect(
        () => () => {
            onPathPropertyChangeRef.current = null;
        },
        [],
    );
};

export default useDrawingEditorSelectedPathModifier;
