// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getFileUrl, getTitle } from '../../../../common/elements/utils/elementPropertyUtils';

// Styles
import './FileModalNativeView.scss';

const FileRendererNativeViewer = (props) => {
    const { element, showDownloadOption } = props;

    const fileUrl = getFileUrl(element);
    const title = getTitle(element);

    const updatedFileUrl = showDownloadOption ? fileUrl : fileUrl.concat('#toolbar=0');

    return <iframe title={title} className="FileModalNativeView" src={updatedFileUrl} />;
};

FileRendererNativeViewer.propTypes = {
    element: PropTypes.object,
    showDownloadOption: PropTypes.bool,
};

export default FileRendererNativeViewer;
