// Lib
import React from 'react';
import PropTypes from 'prop-types';
import ReactCalendar from 'react-calendar/dist/entry.nostyle';

// Utils
import { getDateIsLessThan, getStartOfDayDate } from '../../../common/utils/timeUtil';

// Styles
import './Calendar.scss';
import Icon from '../icons/Icon';

const tileClassName = ({ date, futureOnly }) => {
    if (!futureOnly) return null;
    const startOfTodayDate = getStartOfDayDate(new Date());

    return getDateIsLessThan(date, startOfTodayDate) ? 'react-calendar__tile--past' : null;
};

const formatShortWeekday = (locale, date) => date.toLocaleString(locale, { weekday: 'narrow' });

const Calendar = (props) => {
    const { value, onChange, locale = 'en-US' } = props;

    const calendarType = locale === 'en-US' ? 'US' : 'ISO 8601';

    const onDateChange = (updatedDate) => {
        // If there's an existing value, copy its time to the new date
        if (value) {
            updatedDate.setHours(value.getHours());
            updatedDate.setMinutes(value.getMinutes());
            updatedDate.setSeconds(value.getSeconds());
            updatedDate.setMilliseconds(value.getMilliseconds());
        }

        onChange && onChange(updatedDate);
    };

    return (
        <ReactCalendar
            className="Calendar"
            formatShortWeekday={formatShortWeekday}
            minDetail="month"
            minDate={props.futureOnly ? new Date() : null}
            tileClassName={tileClassName}
            calendarType={calendarType}
            showFixedNumberOfWeeks
            {...props}
            onChange={onDateChange}
            nextLabel={<Icon name="chevron-left" />}
            prevLabel={<Icon name="chevron-left" />}
        />
    );
};

Calendar.propTypes = {
    futureOnly: PropTypes.bool,
    value: PropTypes.object,
    onChange: PropTypes.func,
    locale: PropTypes.string,
};

export default Calendar;
