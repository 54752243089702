/**
 * Simple wrapper around the HTML5 FileReader object to turn its API into a promise based interface.
 * This doesn't expose all the functionality of the API (such as progress notifications etc, so should only be
 * used in simple situations.
 */
function readFile<T>(method: string) {
    return (file: File, byteCount: number = 0): Promise<T> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (onloadEvent) => resolve(reader.result as T);
            reader.onabort = (onabortEvent) => reject(onabortEvent);
            reader.onerror = (onerrorEvent) => reject(onerrorEvent);

            const filePortion = byteCount ? file.slice(0, byteCount) : file;
            (reader as any)[method](filePortion);
        });
}

// Methods to place into the file reader
export const readAsDataURL = readFile<string>('readAsDataURL');
export const readAsArrayBuffer = readFile<ArrayBuffer>('readAsArrayBuffer');
export const readAsText = readFile<string>('readAsText');
