// Lib
import { isNumber, negate } from 'lodash/fp';

// Utils
import { asObject } from '../../../../../../common/utils/immutableHelper';
import {
    getAutoColorIndex,
    getCaptionModified,
    getColorSpace,
    getElementId,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getElement } from '../../../../../../common/elements/utils/elementTraversalUtils';
import { isColorSwatch, isSkeleton } from '../../../../../../common/elements/utils/elementTypeUtils';
import { parseColorObject } from '../../../../../../common/colors/colorObjectUtil';
import { getColorName } from '../../../../../../common/colors/colorNameUtil';
import rawFromText from '../../../../../../common/utils/editor/rawUtils/rawFromText';
import { elementAllowsDualColors } from './dualColors/dualColorListUtils';
import { isUndefined } from 'lodash';

// Actions
import { updateMultipleElements } from '../../../../../element/actions/elementActions';

// Selectors
import { getElements } from '../../../../../element/selectors/elementsSelector';
import { getSelectedElements } from '../../../../../element/selection/selectedElementsSelector';
import { getCurrentlyEditingElement } from '../../../../../element/selection/currentlyEditingSelector';
import { getSelectedTaskIdSelector } from '../../../selector/selectionToolsSelector';

// Constants
import { BACKGROUND_COLORS } from '../../../../../../common/colors/colorConstants';

/**
 * Check if secondaryColor should be added to the updates.
 * Allow setting secondary color to null, but skip if no value is provided
 * @param element
 * @param secondaryColor
 * @returns {boolean}
 */
const shouldUpdateSecondaryColor = (element, secondaryColor) =>
    elementAllowsDualColors(element) && !isUndefined(secondaryColor);

export const updateElementsColors =
    ({ color, elements, useExistingColorSpace, secondaryColor }) =>
    (dispatch) => {
        const updates = asObject(
            elements.map((element) => {
                const changes = { color };

                if (shouldUpdateSecondaryColor(element, secondaryColor)) {
                    changes.secondaryColor = secondaryColor;
                }

                if (isNumber(getAutoColorIndex(element))) {
                    changes.autoColorIndex = null;
                }

                if (isColorSwatch(element)) {
                    const colorSpace = useExistingColorSpace ? getColorSpace(element) : null;
                    const newColor = parseColorObject(color || '#ffffff', colorSpace);
                    if (newColor) {
                        changes.color = newColor;

                        const isCaptionModified = getCaptionModified(element);
                        if (!isCaptionModified) {
                            changes.caption = rawFromText(getColorName(newColor));
                        }
                    } else {
                        delete changes.color;
                    }
                }

                return {
                    id: getElementId(element),
                    changes,
                };
            }),
        );

        return dispatch(updateMultipleElements({ updates }));
    };

export const updateElementsSecondaryColors =
    ({ secondaryColor, elements }) =>
    (dispatch) => {
        const updates = asObject(
            elements.map((element) => {
                if (!shouldUpdateSecondaryColor(element, secondaryColor)) return;

                const changes = { secondaryColor };

                return {
                    id: getElementId(element),
                    changes,
                    transactionId: -1,
                };
            }),
        );

        return dispatch(updateMultipleElements({ updates }));
    };

export const updateElementBackgroundColors =
    ({ color, secondaryColor, elements }) =>
    (dispatch) => {
        const isTransparent = color === BACKGROUND_COLORS.TRANSPARENT.name;

        const changes = {
            background: isTransparent ? null : { color },
            transparent: { enabled: isTransparent },
        };

        const updates = asObject(
            elements.map((element) => {
                if (shouldUpdateSecondaryColor(element, secondaryColor)) {
                    changes.secondaryColor = secondaryColor;
                }

                return {
                    id: getElementId(element),
                    changes,
                };
            }),
        );

        return dispatch(updateMultipleElements({ updates }));
    };

export const updateCurrentlyEditingColor = (color) => (dispatch, getState) => {
    const state = getState();
    const currentlyEditingElement = getCurrentlyEditingElement(state);

    dispatch(updateElementsColors({ color, elements: [currentlyEditingElement] }));
};

export const updateSelectedElementsBackgroundColors = (color, secondaryColor) => (dispatch, getState) => {
    const state = getState();
    const elementsToUpdate = getSelectedElements(state).filter(negate(isSkeleton));

    return dispatch(updateElementBackgroundColors({ color, secondaryColor, elements: elementsToUpdate }));
};

export const updateSelectedElementsColors =
    ({ color, useExistingColorSpace, secondaryColor }) =>
    (dispatch, getState) => {
        const state = getState();
        const selectedElements = getSelectedElements(state).filter(negate(isSkeleton));

        return dispatch(
            updateElementsColors({ color, secondaryColor, elements: selectedElements, useExistingColorSpace }),
        );
    };

export const updateSelectedElementsSecondaryColors =
    ({ secondaryColor, useExistingColorSpace }) =>
    (dispatch, getState) => {
        const state = getState();
        const selectedElements = getSelectedElements(state).filter(negate(isSkeleton));

        return dispatch(
            updateElementsSecondaryColors({ secondaryColor, elements: selectedElements, useExistingColorSpace }),
        );
    };

export const updateSelectedTaskColor = (color) => (dispatch, getState) => {
    const state = getState();
    const selectedTaskId = getSelectedTaskIdSelector(state);

    const elements = getElements(state);
    const selectedTask = getElement(elements, selectedTaskId);

    return dispatch(updateElementsColors({ color, elements: [selectedTask] }));
};
