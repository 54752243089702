// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './TaskStyler.scss';

const TaskStyler = ({ largeTop, largeBottom, className, ...props }) => (
    <div
        {...props}
        className={classNames('TaskStyler', className, {
            'large-top': largeTop,
            'large-bottom': largeBottom,
        })}
    />
);

TaskStyler.propTypes = {
    className: PropTypes.string,
    largeTop: PropTypes.bool,
    largeBottom: PropTypes.bool,
};

export default TaskStyler;
