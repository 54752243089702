import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarListIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-list"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M10 19.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm13 .75a.75.75 0 1 1 0 1.5h-8a.75.75 0 1 1 0-1.5zM10 14.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm13 .75a.75.75 0 1 1 0 1.5h-8a.75.75 0 1 1 0-1.5zM10 9.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm13 .75a.75.75 0 1 1 0 1.5h-8a.75.75 0 1 1 0-1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarListIcon);
export default Memo;
