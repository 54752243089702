// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './ClippedText.scss';

const ClippedText = ({ children }) => <span className="ClippedText">{children}</span>;

ClippedText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ClippedText;
