import { CellTypeNames, DateStringFormatOptions, TimeStringFormatOptions } from '../CellTypeConstants';
import { isNil } from 'lodash';
import { getCurrencyFromString } from './tableInputCurrencyUtils';
import { getDateTimeValuesFromInputString } from './tableInputDateTimeUtils';
import { convertToEnglishNumberFormatting, getIsInputNumber, validPercentageRegex } from './tableInputNumberUtils';

/**
 * Checks the input string to see if it should be formatted as a number,
 * returns the type of number it should be formatted as and the new value
 */
export const stringShouldFormatAsType = (
    str: string,
    isNewInput = false,
    locale: string,
    currencyPreference?: string,
):
    | {
          newCellType:
              | CellTypeNames.PERCENTAGE
              | CellTypeNames.CURRENCY
              | CellTypeNames.NUMBER
              | CellTypeNames.DATE_TIME;
          newCellValue: number | string;
          otherTypeOptions?: {
              currency?: string;
              hasTime?: boolean;
              hasSeconds?: boolean;
              dateFormat?: DateStringFormatOptions;
              timeInBrackets?: boolean;
              timeFormat?: TimeStringFormatOptions;
          };
      }
    | undefined => {
    // Check that there is a number in the string
    const numberValue = getIsInputNumber(str, isNewInput, locale);

    if (!isNil(numberValue)) {
        // Percentage
        const valueEndsWithPercentage = str.match(validPercentageRegex);
        if (valueEndsWithPercentage) {
            const newCellValue = isNewInput ? convertToEnglishNumberFormatting(str, locale) : str;
            return {
                newCellType: CellTypeNames.PERCENTAGE,
                newCellValue,
            };
        }

        // Currency
        const { currency } = getCurrencyFromString(str, currencyPreference);
        if (currency && numberValue) {
            return { newCellType: CellTypeNames.CURRENCY, newCellValue: numberValue, otherTypeOptions: { currency } };
        }

        // Number
        return { newCellType: CellTypeNames.NUMBER, newCellValue: numberValue };
    }

    // Date
    const dateFormatValues = getDateTimeValuesFromInputString(str, locale, undefined, isNewInput);
    if (dateFormatValues) {
        const { numericDateTimeString, hasTime, hasSeconds, dateFormat } = dateFormatValues;
        return {
            newCellType: CellTypeNames.DATE_TIME,
            newCellValue: numericDateTimeString,
            otherTypeOptions: { hasTime, hasSeconds, dateFormat },
        };
    }
};
