// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './DrawingSelectionMarquee.scss';

const DrawingSelectionMarquee = (props) => {
    const { isMarqueeSelectMode, marqueeDimensions } = props;

    if (!isMarqueeSelectMode || !marqueeDimensions) return null;

    return (
        <rect
            className="DrawingSelectionMarquee"
            x={marqueeDimensions.left}
            y={marqueeDimensions.top}
            width={marqueeDimensions.width}
            height={marqueeDimensions.height}
        />
    );
};

DrawingSelectionMarquee.propTypes = {
    isMarqueeSelectMode: PropTypes.bool,
    marqueeDimensions: PropTypes.object,
};

export default DrawingSelectionMarquee;
