const getNextNonSpaceCharacterIndex = ({ text, offset, isForward }) => {
    let i = offset + (isForward ? 1 : -1);

    while (i >= 0 && i <= text.length) {
        if (text[i] && text[i] !== ' ') return i;
        i = i + (isForward ? 1 : -1);
    }

    return i;
};

const getFollowingOffset = ({ text, offset, isJump, isForward }) =>
    isJump ? getNextNonSpaceCharacterIndex({ text, offset, isForward }) : offset + ((isForward && 1) || -1);

const getFollowingPoint = (isForward) => (editorState, currentPoint, isJump) => {
    const { key, offset } = currentPoint;

    const content = editorState.getCurrentContent();

    let block = content.getBlockForKey(key);
    let text = block.getText();

    let currentKey = key;
    let followingOffset = getFollowingOffset({ text, offset, isJump, isForward });

    while (followingOffset < 0 || followingOffset > text.length) {
        const followingBlockKey = isForward ? content.getKeyAfter(currentKey) : content.getKeyBefore(currentKey);

        // We're at the last block so just use its last position
        if (!followingBlockKey) {
            return {
                key: currentKey,
                offset: isForward ? text.length : 0,
            };
        }

        currentKey = followingBlockKey;
        block = content.getBlockForKey(currentKey);
        text = block.getText();

        followingOffset = getFollowingOffset({
            text,
            offset: isForward ? -1 : text.length + 1,
            isJump,
            isForward,
        });
    }

    return {
        key: currentKey,
        offset: followingOffset,
    };
};

export const getNextPoint = getFollowingPoint(true);
export const getPreviousPoint = getFollowingPoint(false);

const getPointFromSelection =
    (getPointFn) =>
    (editorState, isJump = false) => {
        const selection = editorState.getSelection();

        return getPointFn(editorState, { key: selection.getFocusKey(), offset: selection.getFocusOffset() }, isJump);
    };

/**
 * This function determines the next point that the cursor would appear at.
 * isJump - Whether the next position jumps characters (e.g. When 'alt' and arrow keys are used).
 */
export const getSelectionNextPoint = getPointFromSelection(getNextPoint);
/**
 * This function determines the previous point that the cursor would appear at (e.g. when moving backwards).
 * isJump - Whether the next position jumps characters (e.g. When 'alt' and arrow keys are used).
 */
export const getSelectionPreviousPoint = getPointFromSelection(getPreviousPoint);
