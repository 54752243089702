import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarHighlightIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-highlight"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#DCFF7F"
                d="M8 8h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"
                className="decoration"
            />
            <path
                fill="#323B4A"
                d="m9.606 21.03.921-2.654h3.919l.915 2.655h1.607L13.358 11H11.61L8 21.03h1.606zm1.362-3.927 1.48-4.281h.078l1.479 4.28h-3.037zm9.32 4.094c1.244 0 1.944-.632 2.223-1.195h.059v1.029H24v-4.996c0-2.19-1.724-2.625-2.92-2.625-1.36 0-2.615.548-3.104 1.92l1.376.313c.215-.534.764-1.048 1.749-1.048.89 0 1.371.438 1.425 1.2l.005.181c0 .394-.298.492-.996.579l-.905.102c-1.425.166-2.884.539-2.884 2.248 0 1.48 1.111 2.292 2.541 2.292zm.318-1.175c-.828 0-1.425-.372-1.425-1.097 0-.738.616-1.029 1.395-1.154l.725-.1c.504-.072 1.089-.178 1.235-.323v.97c0 .89-.71 1.704-1.93 1.704z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarHighlightIcon);
export default Memo;
