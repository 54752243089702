import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CommentPinIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={30}
        height={35}
        viewBox="0 0 30 35"
        className="Icon comment-pin"
        {...props}
    >
        <defs>
            <filter
                id="CommentPinIcon__a"
                width="141.7%"
                height="134.5%"
                x="-20.8%"
                y="-13.8%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.3 0"
                />
            </filter>
            <path
                id="CommentPinIcon__b"
                d="M3.515 3.515c4.686-4.687 12.283-4.688 16.97 0 4.687 4.686 4.688 12.283 0 16.97L12 28.971l-8.485-8.486c-4.687-4.686-4.688-12.283 0-16.97z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(3 2)">
            <use xlinkHref="#CommentPinIcon__b" fill="#000" filter="url(#CommentPinIcon__a)" />
            <path
                fill="#2F63F7"
                stroke="#FFF"
                strokeLinejoin="square"
                strokeWidth={2}
                d="M12 1c2.815 0 5.63 1.074 7.778 3.222A10.965 10.965 0 0 1 23 12c0 2.815-1.074 5.63-3.222 7.778L12 27.556l-7.778-7.778A10.965 10.965 0 0 1 1 12c0-2.815 1.074-5.63 3.222-7.778A10.965 10.965 0 0 1 12 1z"
            />
        </g>
    </svg>
);
const Memo = memo(CommentPinIcon);
export default Memo;
