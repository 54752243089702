// Utils
import { sendAmplitudeEvent } from '../../../../../analytics/amplitudeService';
import editorStoreConnect from '../../../../../components/editor/store/components/editorStoreConnect';

// Actions
import { editorStyleChange } from '../../../../../components/editor/store/editorActions';

// Components
import RichTextTool from './RichTextTool';

// Utils
import { getEditorState } from '../../../../../components/editor/store/reducers/editorStoreSelector';
import editorGetCurrentBlock from '../../../../../components/editor/customRichUtils/editorState/editorGetCurrentBlock';
import editorGetCurrentInlineStyle from '../../../../../components/editor/customRichUtils/editorState/editorGetCurrentInlineStyle';
import isBlockSelection from '../../../../../components/editor/customRichUtils/selection/isBlockSelection';
import isAllBlockTextSelection from '../../../../../components/editor/customRichUtils/selection/isAllBlockTextSelection';

// Constants
import { STYLE_COMMANDS } from '../../../../../components/editor/richText/richTextConstants';
import { EVENT_TYPE_NAMES } from '../../../../../../common/analytics/amplitudeEventTypesUtil';

const handleCodeClick = () => (dispatch, getState) => {
    const state = getState();
    const editorState = getEditorState(state);

    sendAmplitudeEvent({
        eventType: EVENT_TYPE_NAMES.TEXT_STYLE_TOGGLE,
        eventProperties: {
            style: STYLE_COMMANDS.CODE_BLOCK,
        },
    });

    let isBlockSelected = isBlockSelection(editorState) || isAllBlockTextSelection(editorState);

    if (!isBlockSelected) {
        const selection = editorState.getSelection();
        const inlineStyle = editorGetCurrentInlineStyle(editorState);
        const currentBlock = editorGetCurrentBlock(editorState);

        isBlockSelected =
            (selection.isCollapsed() && !inlineStyle.has(STYLE_COMMANDS.CODE)) ||
            currentBlock.type === STYLE_COMMANDS.CODE_BLOCK;
    }

    const styleCommand = isBlockSelected ? STYLE_COMMANDS.CODE_BLOCK : STYLE_COMMANDS.CODE;

    const styleChangeAction = editorStyleChange({ styleCommand });
    dispatch(styleChangeAction);
};

const mapStateToProps = (state) => {
    const editorState = getEditorState(state);
    if (!editorState) return { isActive: false };

    const currentBlock = editorGetCurrentBlock(editorState);
    if (!currentBlock) return { isActive: false };

    const blockType = currentBlock.getType();

    if (blockType === STYLE_COMMANDS.CODE_BLOCK) return { isActive: true };

    const inlineStyle = editorGetCurrentInlineStyle(editorState);
    return { isActive: inlineStyle.has(STYLE_COMMANDS.CODE) };
};

const mapEditorDispatchToProps = (dispatch) => ({
    onPointerDown: (event) => {
        event.preventDefault();
        dispatch(handleCodeClick());
    },
});

export default editorStoreConnect(mapStateToProps, mapEditorDispatchToProps)(RichTextTool);
