// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './CommentThreadCollapsedRemoteActivityIndicator.scss';

const CommentThreadCollapsedRemoteActivityIndicator = ({ show }) => {
    if (!show) return null;

    return (
        <div className="CommentThreadCollapsedRemoteActivityIndicator">
            <div className="pulse" />
        </div>
    );
};

CommentThreadCollapsedRemoteActivityIndicator.propTypes = {
    show: PropTypes.bool,
};

export default CommentThreadCollapsedRemoteActivityIndicator;
