import { compact } from 'lodash';
import HttpStatus from 'http-status-codes';
import AppError from './AppError';

import { FAILURE_TYPES } from '../validation/validationConstants';
import { RESPONSE_ERROR } from './errorConstants';

export default class ValidationError extends AppError {
    constructor(error) {
        error.name = 'ValidationError';
        error.status = HttpStatus.BAD_REQUEST;
        error.code = error?.code || RESPONSE_ERROR.VALIDATION_FAILURE;

        const fields = compact([error.field, ...(error.fields || [])]);

        if (!error.details) {
            error.details = fields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]: {
                        type: FAILURE_TYPES.ERROR,

                        // If show primary error, do not show field message
                        message: error.showPrimaryError ? undefined : error.message,
                        value: error.value,
                    },
                }),
                {},
            );
        }

        super(error);
    }
}
