// Utils
import { asObject, isEmpty } from '../../../common/utils/immutableHelper';
import { isLine } from '../../../common/elements/utils/elementTypeUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getTrashMoveAction } from '../../../common/elements/utils/elementTrashUtils';
import { getChildrenViaGraph } from '../../../common/elements/utils/elementGraphUtils';
import { isLocationAttached, isLocationInbox } from '../../../common/elements/utils/elementLocationUtils';
import { elementCanConnect, getConnectedElements, getConnectingElementIds } from './elementConnectionsUtils';
import {
    getElementId,
    getElementLocation,
    getLineEnd,
    getLineEndConnectedElementId,
    getLineStart,
    getLineStartConnectedElementId,
    getLocationParentId,
} from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getCurrentBoardVisibleDescendants } from '../selectors/currentBoardSelector';
import { getElements } from '../selectors/elementsSelector';
import { elementGraphSelector } from '../selectors/elementGraphSelector';

// Actions
import { getSelectedElements } from '../selection/selectedElementsSelector';
import { addSelectedElements } from '../selection/selectionActions';
import { updateMultipleElements } from '../actions/elementActions';
import { moveMultipleElements } from '../actions/elementMoveActions';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';

/**
 * Retrieves the element IDs for elements that CONNECT to the specified elementIDs, such as lines.
 * Lines state what they connect to, other elements don't know what connects to themselves.
 *
 * Using the current board elements might significantly reduce the number of elements that need to be traversed,
 * and as such be more performant.
 * This is only useful if we know that all connected elements will be on the current board (e.g. when starting a drag).
 */
export const getCurrentBoardConnectingElementIdsThunk =
    ({ elementIds }) =>
    (dispatch, getState) => {
        if (isEmpty(elementIds)) return [];

        const state = getState();
        const currentBoardElements = getCurrentBoardVisibleDescendants(state);

        return getConnectedElements(currentBoardElements.valueSeq(), elementIds).map(getElementId).toArray();
    };

/**
 * This will select all the elements that the currently selected elements *connect to*.
 * This is useful for instances when elements that connect to other things are nudged (e.g. lines).
 * Their connected elements can also be selected, so then they're all nudged together.
 */
export const selectConnectionTargets =
    ({ transactionId } = {}) =>
    (dispatch, getState) => {
        const state = getState();

        const selectedElements = getSelectedElements(state);

        // If there's any lines selected, then also select their connected element IDs
        const connectedElementIds = getConnectingElementIds(selectedElements);

        if (!isEmpty(connectedElementIds)) dispatch(addSelectedElements({ ids: connectedElementIds, transactionId }));
    };

const shouldDeleteLineOneMove = ({ element, move, moves, elements }) => {
    const elementId = getElementId(element);
    const elementParentId = getLocationParentId(element);
    const droppedParentId = move.location.parentId;
    const droppedSection = move.location.section;

    // If not dropping onto the canvas then the connected line must be deleted
    if (droppedSection === BoardSections.INBOX) {
        // If dropping onto the inbox of the same board, then we should delete
        if (elementParentId === droppedParentId) return true;

        const newParent = getElement(elements, droppedParentId);

        // Otherwise, only delete if the parent of the new parent is the current board and the new parent
        // is on the canvas
        return getLocationParentId(newParent) !== elementParentId || isLocationInbox(newParent);
    }

    // If dropping onto canvas of the same board, then no need to delete
    if (elementParentId === droppedParentId) return false;

    // Otherwise we need to determine if the line is being moved with its connected element,
    // in which case we shouldn't delete the line
    const moveIncludesThisLine = moves.some((thisMove) => getElementId(thisMove) === elementId);
    if (moveIncludesThisLine && droppedSection === BoardSections.CANVAS) return false;

    // Otherwise, we should delete the line
    return true;
};

/**
 * NOTE: Performance - This could potentially just modify the moves actions to be moves and updates,
 *  however there's currently no backend support for this so sticking to the existing method for now.
 */
export const handleConnectedElementMoves =
    ({ moves }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        // TODO Could prefilter elements to make the later filters quicker
        //  (e.g. Get all connected elements to begin with)
        const elementsSeq = elements.valueSeq();

        // For each of the moves, find out if the element has any connections
        moves.forEach((move) => {
            // Find the ID of the thing being moved
            const { id } = move;

            // If the element has any connected elements, handle the connected element update
            const connectedElements = getConnectedElements(elementsSeq, [id]);

            if (!isEmpty(connectedElements)) {
                connectedElements.forEach((connectedElement) => {
                    if (!elementCanConnect(connectedElement)) return;

                    // E.g. Board moved with line attached
                    if (shouldDeleteLineOneMove({ element: connectedElement, move, moves, elements })) {
                        // NOTE: Unfortunately we need to do it this way to prevent circular dependencies
                        // I first tried to dispatch a separate moveElementsToTrash action, but it killed
                        // the test suite due to what I think was a circular reference
                        const deletionMove = getTrashMoveAction(connectedElement);

                        moves.push(deletionMove);
                    }
                });
            }
        });

        return moves;
    };

export const getAttachedElements = (elementId) => (dispatch, getState) => {
    const state = getState();
    const elements = getElements(state);

    const elementGraph = elementGraphSelector(state);

    return getChildrenViaGraph({ elements, elementGraph, parentId: elementId }).valueSeq().filter(isLocationAttached);
};

/**
 * This is used by the imageDropTargetDecorator to swap lines from an old image onto the replacement
 * image element.
 *
 * NOTE: This only works with connected lines at the moment (as they're the only connected elements).
 */
export const switchConnectedElementParents =
    ({ initialConnectedId, newConnectedId, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentBoardElements = getCurrentBoardVisibleDescendants(state);

        const connectedElements = getConnectedElements(currentBoardElements.valueSeq(), [initialConnectedId]);

        const updates = connectedElements
            .map((connectedElement) => {
                if (!isLine(connectedElement)) return null;

                const id = getElementId(connectedElement);

                const changes = {};

                if (getLineStartConnectedElementId(connectedElement) === initialConnectedId) {
                    const initialStart = asObject(getLineStart(connectedElement));

                    changes.start = {
                        ...initialStart,
                        elementId: newConnectedId,
                    };
                }

                if (getLineEndConnectedElementId(connectedElement) === initialConnectedId) {
                    const initialEnd = asObject(getLineEnd(connectedElement));

                    changes.end = {
                        ...initialEnd,
                        elementId: newConnectedId,
                    };
                }

                if (isEmpty(changes)) return null;

                return {
                    id,
                    changes,
                };
            })
            .filter((update) => !!update)
            .toArray();

        if (!isEmpty(updates)) dispatch(updateMultipleElements({ updates, transactionId }));

        const attachedElements = dispatch(getAttachedElements(initialConnectedId));
        const moves = attachedElements
            .map((attachedElement) => {
                const location = asObject(getElementLocation(attachedElement));

                if (!location) return null;

                return {
                    id: getElementId(attachedElement),
                    location: {
                        ...location,
                        parentId: newConnectedId,
                    },
                };
            })
            .filter((move) => !!move)
            .toArray();

        if (!isEmpty(moves)) {
            dispatch(
                moveMultipleElements({
                    moves,
                    transactionId,
                    moveOperation: ELEMENT_MOVE_OPERATIONS.CONNECTION_SWITCH,
                }),
            );
        }
    };
