// Constants
import {
    RESOURCES_FETCH_ERROR,
    RESOURCES_FETCHED,
    RESOURCES_FETCHED_FROM_CACHE,
    RESOURCES_FETCHING,
    RESOURCES_MARKED_AS_STALE,
    RESOURCES_CACHED,
    RESOURCES_UPDATE_FETCHED_TIME,
    ResourceTypes,
} from './asyncResourceConstants';

// Types
import { AsyncResourceEntityErrorObject } from './reducers/asyncResourceReducerTypes';
import {
    AsyncResourceFetchedAction,
    AsyncResourceFetchErrorAction,
    AsyncResourceFetchingAction,
    AsyncResourceMarkAsStaleAction,
} from './asyncResourceActionsTypes';

export const fetchingAsyncResource = (resourceName: ResourceTypes, ids?: string[]): AsyncResourceFetchingAction => ({
    type: RESOURCES_FETCHING,
    resource: resourceName,
    ids,
});

export const fetchedAsyncResource = (
    resourceName: ResourceTypes,
    ids: string[] | undefined,
    rest?: object,
): AsyncResourceFetchedAction => ({
    type: RESOURCES_FETCHED,
    resource: resourceName,
    ids,
    ...rest,
});

export const cachedAsyncResource = (
    resourceName: ResourceTypes,
    ids: string[] | undefined,
    rest?: object,
): AsyncResourceFetchedAction => ({
    type: RESOURCES_CACHED,
    resource: resourceName,
    ids,
    ...rest,
});

export const fetchedAsyncResourceFromCache = (
    resourceName: ResourceTypes,
    ids: string[] | undefined,
    rest?: object,
): AsyncResourceFetchedAction => ({
    type: RESOURCES_FETCHED_FROM_CACHE,
    resource: resourceName,
    ids,
    ...rest,
});

export const updateFetchedTimeAsyncResource = (
    resourceName: ResourceTypes,
    ids: string[] | undefined,
    rest?: object,
): AsyncResourceFetchedAction => ({
    type: RESOURCES_UPDATE_FETCHED_TIME,
    resource: resourceName,
    ids,
    ...rest,
});

export const asyncResourceFetchError = (
    resourceName: ResourceTypes,
    errors: AsyncResourceEntityErrorObject[],
    errorTime: number,
    ids?: string[],
): AsyncResourceFetchErrorAction => ({
    type: RESOURCES_FETCH_ERROR,
    resource: resourceName,
    errors,
    errorTime,
    ids,
});

export const markAsyncResourceAsStale = (
    resourceName: ResourceTypes,
    ids: string[] | undefined,
): AsyncResourceMarkAsStaleAction => ({
    type: RESOURCES_MARKED_AS_STALE,
    resource: resourceName,
    ids,
});
