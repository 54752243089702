// Utils
import logger from '../../logger/logger';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';

// Selectors
import { getCurrentUserSegment } from '../../user/currentUserSelector';

// Actions
import { updateCurrentUser } from '../../user/currentUserActions';
import { markAsSeen, hasSeen } from '../../../common/users/userEducationUtil';
import { navigateToSegmentPicker } from '../../reducers/navigationActions';

// Constants
import {
    ONBOARDING_GOTO_STEP,
    ONBOARDING_SET_STEP,
    ONBOARDING_ADD_LISTENER,
    ONBOARDING_REMOVE_LISTENER,
    ONBOARDING_FINISH,
    ONBOARDING_SET_METADATA,
    ONBOARDING_STEPS_LOADED,
    ONBOARDING_STEP_PASSED,
} from './onboardingConstants';
import { ONBOARDING_EDUCATION_CODES } from '../../../common/users/userEducationConstants';

export const onboardingGotoStep = (step, meta) => ({ type: ONBOARDING_GOTO_STEP, step, meta });
export const onboardingSetStep = (step, meta) => ({ type: ONBOARDING_SET_STEP, step, meta });
export const onboardingFinish = () => ({ type: ONBOARDING_FINISH });

export const onboardingAddListener = (listener) => ({ type: ONBOARDING_ADD_LISTENER, listener });
export const onboardingRemoveListener = (listenerId) => ({ type: ONBOARDING_REMOVE_LISTENER, listenerId });

export const onboardingSetMetadata = (data) => ({ type: ONBOARDING_SET_METADATA, data });

export const onboardingStepsLoaded = () => ({ type: ONBOARDING_STEPS_LOADED });

export const onboardingStepPassed = (step) => ({ type: ONBOARDING_STEP_PASSED, step });

export const onboardingNavigateToSegmentPicker = () => (dispatch, getState) => {
    const state = getState();
    const currentUserSegment = getCurrentUserSegment(state);

    if (currentUserSegment) {
        logger.info('navigateToSegmentPicker: User already has a segment, skipping navigate to segment picker.');
        // dispatch a modal close action to simulate completion of the segment picker, allowing onboarding steps to proceed
        dispatch({ type: 'MODAL_CLOSE' });
        return;
    }

    dispatch(navigateToSegmentPicker());
};

export const clearPersistedUserOnboarding = () =>
    updateCurrentUser({
        changes: {
            onboarding: null,
        },
        personal: true,
        sync: true,
    });

export const markEducationCodeAsSeen = (educationCode) => (dispatch, getState) => {
    const currentUserEducation = getState().getIn(['app', 'currentUser', 'education']);

    const updatedEducation = markAsSeen(currentUserEducation, educationCode);

    if (
        hasSeen(updatedEducation, ONBOARDING_EDUCATION_CODES.EXPLAIN_NOTE) &&
        hasSeen(updatedEducation, ONBOARDING_EDUCATION_CODES.EXPLAIN_DOCUMENT) &&
        hasSeen(updatedEducation, ONBOARDING_EDUCATION_CODES.EXPLAIN_LINE) &&
        hasSeen(updatedEducation, ONBOARDING_EDUCATION_CODES.EXPLAIN_LINK) &&
        hasSeen(updatedEducation, ONBOARDING_EDUCATION_CODES.EXPLAIN_COLUMN) &&
        hasSeen(updatedEducation, ONBOARDING_EDUCATION_CODES.EXPLAIN_BOARD)
    ) {
        sendAmplitudeEvent({ eventType: 'Viewed all element explanation tooltips' });
    }

    dispatch(
        updateCurrentUser({
            changes: {
                education: updatedEducation,
            },
            personal: true,
            sync: true,
        }),
    );
};
