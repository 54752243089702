// Lib
import * as Immutable from 'immutable';
import { USER_CONTACTS_TRACK_USER_IDS } from './userContactsConstants';

const initialState = Immutable.Set();

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_CONTACTS_TRACK_USER_IDS:
            return state.concat(action.userIds);
        default:
            return state;
    }
};
