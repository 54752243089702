// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { branch } from '../../../node_module_clones/recompose';
import { identity } from 'lodash';

// Components
import ElementImage from '../../components/images/ElementImage';
import previewImageDropTargetDecorator from '../dnd/elementDropTargets/previewImageDropTargetDecorator';

// Constants
import { IMAGE_TYPES } from '../../../common/media/mediaConstants';

// Only allow preview image to be replaceable using a drop target if it is not in modal view
const enhance = branch(({ isModalView }) => !isModalView, previewImageDropTargetDecorator(), identity);

const FilePreviewImage = (props) => {
    const { element, widthPx, savedWidthPx, gridSize, connectFileDropTarget = identity, cropToGrid } = props;

    return connectFileDropTarget(
        <div className="FilePreviewImage">
            <ElementImage
                showBrokenIconOnError
                cropToGrid={cropToGrid}
                gridSize={gridSize}
                element={element}
                imageType={IMAGE_TYPES.FILE}
                widthPx={widthPx}
                savedWidthPx={savedWidthPx}
            />
        </div>,
    );
};

FilePreviewImage.propTypes = {
    element: PropTypes.object,
    tempSize: PropTypes.object,
    gridSize: PropTypes.number,
    widthPx: PropTypes.number,
    savedWidthPx: PropTypes.number,
    cropToGrid: PropTypes.bool,
};

export default enhance(FilePreviewImage);
