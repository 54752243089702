// Lib
import { useRef, useState } from 'react';
import requestSecondAnimationFrame from '../../../../../common/utils/lib/requestSecondAnimationFrame';

// Hooks
import useModalSheetInitialisation from './useModalSheetInitialisation';
import useModalSheetHandlers, { ModalSheetHandlers } from './useModalSheetHandlers';
import useModalSheetSnapPointState from './useModalSheetSnapPointState';

// Utils
import { runAfterNextTransitionEnds } from '../../../../utils/dom/domEventUtil';
import { setCurrentHeightForAnimation } from '../utils/snapPointUtils';

// Types
import { MobileModalSheetProps } from '../MobileModalSheet';

export const MAX_SHEET_HEIGHT = 0.95;
export const MAX_SUFFIX = 'max';

export interface ModalSheetDragState extends ModalSheetHandlers {
    sheetContentRef: React.RefObject<HTMLDivElement>;
    sheetRef: React.RefObject<HTMLDivElement>;
    sheetOverlayRef: React.RefObject<HTMLDivElement>;
    snapPointsState: number[];
    isSheetMounted: boolean;
    closeSheet: () => void;
}

const useModalSheetDragState = (props: MobileModalSheetProps): ModalSheetDragState => {
    const {
        snapPoints = [],
        defaultSnapPoint,
        isSheetOpen,
        setIsSheetOpen,
        activeSnapPoint,
        setActiveSnapPoint,
        onSheetClose,
        dismissible = true,
        onCloseTransitionEnd,
    } = props;

    const sheetOverlayRef = useRef<HTMLDivElement>(null);
    const sheetRef = useRef<HTMLDivElement>(null);
    const sheetContentRef = useRef<HTMLDivElement>(null);

    const [isSheetMounted, setIsSheetMounted] = useState(false);
    const closeAnimationInProgress = useRef(false);

    const { snapPointsState, goToSnapPoint, addSnapPoint, updateActiveSnapPoint } = useModalSheetSnapPointState(
        snapPoints,
        sheetRef,
        activeSnapPoint,
        setActiveSnapPoint,
        dismissible,
    );

    const closeSheet = () => {
        if (!dismissible || !isSheetMounted) return;

        onSheetClose?.();

        if (sheetRef.current?.style.height === 'auto') {
            setCurrentHeightForAnimation(sheetRef);
        }

        sheetOverlayRef.current?.classList.remove('overlay-visible');

        requestSecondAnimationFrame(() => {
            closeAnimationInProgress.current = true;
            updateActiveSnapPoint(0, true);

            runAfterNextTransitionEnds(sheetRef.current, () => {
                closeAnimationInProgress.current = false;
                onCloseTransitionEnd?.();
                setIsSheetMounted(false);
                if (isSheetOpen) setIsSheetOpen(false);
            });
        });
    };

    const sheetInitialised = useModalSheetInitialisation(
        isSheetOpen,
        sheetRef,
        sheetOverlayRef,
        defaultSnapPoint,
        addSnapPoint,
        updateActiveSnapPoint,
        closeSheet,
        isSheetMounted,
        setIsSheetMounted,
        closeAnimationInProgress,
    );

    const handlers = useModalSheetHandlers(
        activeSnapPoint,
        sheetRef,
        sheetContentRef,
        sheetInitialised,
        snapPointsState,
        goToSnapPoint,
        updateActiveSnapPoint,
        closeSheet,
    );

    return {
        ...handlers,
        sheetContentRef,
        sheetRef,
        snapPointsState,
        closeSheet,
        sheetOverlayRef,
        isSheetMounted,
    };
};

export default useModalSheetDragState;
