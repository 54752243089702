// Utils
import { createSessionId } from './deviceSessionIdCreator';

const SESSION_ID_LOCAL_STORAGE_KEY = 'msid';

let cachedSessionId;

/**
 * NOTE: The electron main process doesn't have access to the session storage API, so this will
 * cause a new session ID every time it's quit and re-opened.
 * The main window will have a different session ID to the main window, as well, but won't
 * likely be used as it's the main process that attaches the session ID to the events.
 */
const storeSessionId = (sessionId) => {
    if (global.sessionStorage) {
        global.sessionStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, sessionId);
    }

    cachedSessionId = sessionId;

    return cachedSessionId;
};

const retrieveSessionId = () => {
    if (cachedSessionId) return cachedSessionId;

    // If we're running in the main process of the electron app, we need to use the session ID that was
    // instantiated within the main process, because this is where the socket actions are sent (and the
    // session ID is added to the actions).
    if (global.mnSession?.sessionId) return global.mnSession.sessionId;

    // In Electron we want the session ID to be persisted across the window being closed and re-opened.
    // This is to ensure that remote selections aren't incorrectly assigned to a user who is simply
    // re-opening the app.
    if (global.desktopIntegration?.requestSessionIdSync) return global.desktopIntegration.requestSessionIdSync();

    if (global.sessionStorage) return global.sessionStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY);
};

export const getSessionId = () => {
    const persistedDeviceId = retrieveSessionId();

    cachedSessionId = persistedDeviceId;

    if (persistedDeviceId) return persistedDeviceId;

    const sessionId = createSessionId();
    storeSessionId(sessionId);

    return sessionId;
};
