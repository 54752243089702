// Lib
import React, { useEffect, useState } from 'react';
import Handsontable from 'handsontable/base';
import { isNil } from 'lodash';

// Utils
import { getTableScrollableElement } from '../utils/tableDOMUtils';
import useThrottledCallback from '../../../utils/react/useThrottledCallback';
import { isInDocumentModeList, isInList } from '../../../../common/inList/inListUtils';

// Types
import TableOperations from './TableOperations';

interface Props {
    gridSize: number;
    isPresentational: boolean;
    isSingleSelected: boolean;
    isResizing: boolean;
    isDragging: boolean;
    shouldCoverTable: boolean;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
    hotTableContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    tableOperationsRef: React.RefObject<TableOperations>;
    getContextZoomScale: () => number;
    // TODO: Make args type more specific
    dispatchUpdateTableElement: (args: object) => void;
    inList: string | null;
    isReadOnly: boolean;
}

const TableScrollHandlers = (props: Props) => {
    const {
        isSingleSelected,
        isDragging,
        shouldCoverTable,
        hotTableInstanceRef,
        hotTableContainerRef,
        inList,
        isReadOnly,
    } = props;

    const [showHotCover, setShowHotCover] = useState(shouldCoverTable);

    const isInDocumentMode = isInDocumentModeList(inList);
    // Is table inside a column/trash/unsorted notes etc where width is constrained
    const inElementList = isInList(inList);

    const tableScrollableElement = getTableScrollableElement(hotTableContainerRef.current);
    const scrollWidth = tableScrollableElement?.scrollWidth;
    const clientWidth = tableScrollableElement?.clientWidth;
    const isScrollable = !isNil(scrollWidth) && !isNil(clientWidth) && scrollWidth > clientWidth;

    useEffect(() => {
        // Always cover table in readonly mode, except if its in mobile view
        setShowHotCover((shouldCoverTable || isReadOnly) && !isInDocumentMode);
    }, [shouldCoverTable]);

    useEffect(() => {
        // Set viewportColumnRenderingOffset to 100 JUST AFTER the table has rendered in list view
        // This is to prevent the table from rendering extra columns offscreen when it's first rendered in list view
        // But the correct number of columns will be loaded after the initial render, ready for dragging and scrolling

        // small number of non-visible columns to be rendered to the left of the viewport
        // using 'auto' causes an issue where a table with a small width (<85px) will only render the first column

        if (!inList) return;

        const minOffset = 3;

        setTimeout(() => {
            if (!hotTableInstanceRef.current) return;

            hotTableInstanceRef.current.updateSettings({
                viewportColumnRenderingOffset: inElementList ? 100 : minOffset,
            });
        }, 0);
    }, [inElementList]);

    // This function temporarily removes the table cover to allow scrolling
    // when the user is trying to scroll a table, and prevents horizontal canvas scrolling

    let scrollTimer: NodeJS.Timeout | null = null;

    const updateTableCover = useThrottledCallback(
        () => {
            // We should only run this function if the table can scroll, and the user is trying to scroll it
            if (isSingleSelected || !isScrollable || !inElementList || isDragging) return;

            const canvasViewport = document.querySelector('.CanvasViewport');

            if (showHotCover) {
                // Prevent canvas scrolling
                canvasViewport?.classList.add('prevent-horizontal-scrolling');
                // Remove cover to allow scrolling
                setShowHotCover(false);
            }

            // If there is an existing timer, clear it
            if (scrollTimer !== null) clearTimeout(scrollTimer);

            // Set a timer to cover the table and reset the canvas scroll after a short delay
            scrollTimer = setTimeout(() => {
                canvasViewport?.classList.remove('prevent-horizontal-scrolling');
                setShowHotCover(true);
            }, 150);
        },
        100,
        [isSingleSelected, inElementList, isDragging, showHotCover, isScrollable],
    );

    const onWheelHandler = (event: React.WheelEvent) => {
        // If the user is scrolling vertically, don't do anything
        if (event.deltaX === 0) return;

        updateTableCover(event);
    };

    let touchStartX: number | null = null;

    const onTouchStartHandler = (event: React.TouchEvent) => {
        touchStartX = event.changedTouches[0].clientX;
    };

    const onTouchMoveHandler = (event: React.TouchEvent) => {
        // If the user is scrolling vertically, don't do anything
        if (event.changedTouches[0].clientX === touchStartX) return;

        updateTableCover(event);
    };

    return (
        <>
            {showHotCover && (
                // If element not selected, add cover over table in order to
                // prevent editing and allow dragging
                <div
                    className="HotTableCover"
                    onWheel={onWheelHandler}
                    onTouchStart={onTouchStartHandler}
                    onTouchMove={onTouchMoveHandler}
                ></div>
            )}
        </>
    );
};

export default TableScrollHandlers;
