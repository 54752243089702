// Lib
import { isObject, padStart } from 'lodash';

/**
 * Returns either the hour and meridiem or the hour, minute and meridiem if the minute !== 0.
 * E.g. 9am or 9:30am
 */
export default (datetime: Date | string | number): string => {
    const testTime: Date = isObject(datetime) ? datetime : new Date(datetime);

    const hours24 = testTime.getHours();
    const hours12 = hours24 > 12 ? hours24 - 12 : hours24 || 12;
    const meridiem = hours24 > 11 ? 'pm' : 'am';
    const minutes = testTime.getMinutes();

    return `${hours12}${minutes > 0 ? `:${padStart(minutes.toString(), 2, '0')}` : ''}${meridiem}`;
};
