import { keys } from 'lodash';

import * as Immutable from 'immutable';

import { ELEMENT_COUNT_INCREASE, ELEMENT_COUNT_DECREASE, ELEMENT_COUNT_SET } from './elementCountConstants';

const initalState = Immutable.Map();

const updateCount = (state = 0, modifiy) => Math.max(state + modifiy, 0);

/**
 * Abstract state transformer for element counts
 * @param {method} valueTransformer - method used to transform each key in the state
 * @param {string} state - current state of the reducer
 * @param {object} action - deconstructed for just { counts }; a map of elementCounts used to modify the existing state
 * @return {object} transformed state
 *
 * @example
 * // results in { CARD: 3, BOARD: 4 }
 * const stateTransform = (state = 0, modifiy) => state + modifiy;
 * transformCount(stateTransform)({ CARD: 2, BOARD: 1 }, { CARD: 1, BOARD: 3 })
 * @example
 */
const transformCount =
    (valueTransformer) =>
    (state, { counts }) =>
        state.withMutations((mutateState) => {
            const elementTypes = keys(counts);
            elementTypes.forEach((type) => {
                mutateState.set(type, valueTransformer(mutateState.get(type, 0), counts[type]));
            });
        });

const increaseElementCount = transformCount(updateCount);
const decreaseElementCount = transformCount((a, b) => updateCount(a, b * -1));

const setElementCounts = (state, { counts }) => (counts ? Immutable.fromJS(counts) : state);

export default (state = initalState, action) => {
    switch (action.type) {
        case ELEMENT_COUNT_SET:
            return setElementCounts(state, action);
        case ELEMENT_COUNT_INCREASE:
            return increaseElementCount(state, action);
        case ELEMENT_COUNT_DECREASE:
            return decreaseElementCount(state, action);
        default:
            return state;
    }
};
