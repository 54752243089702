// Lib
import * as Immutable from 'immutable';
import * as TYPES from '../../../common/elements/elementConstants';

// Attachment Uploads
const uploadingAttachment = (state, action) => {
    const stateUpdate = {
        fetching: true,
        fetched: false,
        actionType: action.actionType,
        id: action.id,
    };
    return state.mergeDeep(stateUpdate);
};
const attachmentDataReady = (state, action) => {
    const stateUpdate = {
        data: action.attachmentData,
    };
    return state.mergeDeep(stateUpdate);
};
const updateAttachmentProgress = (state, action) =>
    state
        .set('percentageComplete', action.percentageComplete)
        .set('loaded', action.loaded)
        .set('total', action.total)
        .set('actionType', action.actionType);

const completeAttachmentUpload = (state, action) => {
    const stateUpdate = {
        fetching: false,
        fetched: true,
        cancelFn: null,
        actionType: action.actionType,
    };
    return state.mergeDeep(stateUpdate);
};

const attachmentError = (state, action) => {
    const stateUpdate = {
        data: null,
        error: action.error,
        fetching: false,
        cancelFn: null,
    };
    return state.mergeDeep(stateUpdate);
};

const saveCancelFunction = (state, action) => state.set('cancelFn', action.cancelFn);

const clearError = (state, action) => state.delete('error');

const cleanAttachment = (state, action) => Immutable.Map();

const individualAttachmentReducer = (state = Immutable.Map(), action) => {
    switch (action.type) {
        case TYPES.ELEMENT_ATTACHMENT_UPLOADING:
        case TYPES.ELEMENT_ATTACHMENT_DOWNLOADING:
            return uploadingAttachment(state, action);
        case TYPES.ELEMENT_ATTACHMENT_UPLOAD_COMPLETED:
        case TYPES.ELEMENT_ATTACHMENT_DOWNLOAD_COMPLETED:
            return completeAttachmentUpload(state, action);
        case TYPES.ELEMENT_ATTACHMENT_CLEAN:
            return cleanAttachment(state, action);
        case TYPES.ELEMENT_ATTACHMENT_DATA_READY:
            return attachmentDataReady(state, action);
        case TYPES.ELEMENT_ATTACHMENT_UPLOAD_PROGRESSED:
        case TYPES.ELEMENT_ATTACHMENT_DOWNLOAD_PROGRESSED:
        case TYPES.ELEMENT_ATTACHMENT_PROGRESS_RESET:
            return updateAttachmentProgress(state, action);
        case TYPES.ELEMENT_ATTACHMENT_UPLOAD_ERROR:
            return attachmentError(state, action);
        case TYPES.ELEMENT_ATTACHMENT_CLEAR_ERROR:
            return cleanAttachment(state, action);
        case TYPES.ELEMENT_ATTACHMENT_CANCEL_FN_SET:
            return saveCancelFunction(state, action);
        case TYPES.ELEMENT_ATTACHMENT_CLEAR_ALL_ERRORS:
            return clearError(state, action);
        default:
            return state;
    }
};

const updateOne = (state, action) => state.update(action.id, (entry) => individualAttachmentReducer(entry, action));

const updateAllMatching = (state, action) =>
    state.withMutations((mutableState) => {
        action.ids.forEach((id) => updateOne(mutableState, { ...action, id }));
    });

const updateAll = (state, action) =>
    state.withMutations((mutableState) => {
        mutableState.forEach((entry, id) => updateOne(mutableState, { ...action, id }));
    });
export default (state = Immutable.Map(), action) => {
    switch (action.type) {
        case TYPES.ELEMENT_ATTACHMENT_UPLOADING:
        case TYPES.ELEMENT_ATTACHMENT_DOWNLOADING:
        case TYPES.ELEMENT_ATTACHMENT_DATA_READY:
        case TYPES.ELEMENT_ATTACHMENT_UPLOAD_PROGRESSED:
        case TYPES.ELEMENT_ATTACHMENT_DOWNLOAD_PROGRESSED:
        case TYPES.ELEMENT_ATTACHMENT_PROGRESS_RESET:
        case TYPES.ELEMENT_ATTACHMENT_UPLOAD_COMPLETED:
        case TYPES.ELEMENT_ATTACHMENT_DOWNLOAD_COMPLETED:
        case TYPES.ELEMENT_ATTACHMENT_CLEAN:
        case TYPES.ELEMENT_ATTACHMENT_UPLOAD_ERROR:
        case TYPES.ELEMENT_ATTACHMENT_CLEAR_ERROR:
        case TYPES.ELEMENT_ATTACHMENT_CANCEL_FN_SET:
            return action.ids ? updateAllMatching(state, action) : updateOne(state, action);
        case TYPES.ELEMENT_ATTACHMENT_CLEAR_ALL_ERRORS:
            return updateAll(state, action);
        default:
            return state;
    }
};
