// Lib
import * as Immutable from 'immutable';

// Constants
import { ELEMENT_FILTER_CLEAR, ELEMENT_FILTER_SET } from './elementFilterConstants';

// ids
// query
// context
const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_FILTER_SET:
            return Immutable.fromJS(action.elementFilter);
        case ELEMENT_FILTER_CLEAR:
            return initialState;
        default:
            return state;
    }
};
