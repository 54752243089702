import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAlignTopIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-align-top"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M24 8H8a.75.75 0 0 0 0 1.5h16A.75.75 0 1 0 24 8zm-11.46 7.5a.5.5 0 0 0 .5.5h2.21v6.947a.752.752 0 0 0 1.497.103l.007-.103L16.75 16l2.21.001a.5.5 0 0 0 .242-.063l.07-.047a.5.5 0 0 0 .078-.702l-2.96-3.7a.5.5 0 0 0-.78 0l-2.96 3.7a.5.5 0 0 0-.11.312z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAlignTopIcon);
export default Memo;
