import {
    SOCKET_CONNECT,
    SOCKET_DISCONNECT,
    CHANNELS_UPDATED,
    SOCKET_INTERRUPTION,
    SOCKET_FORCE_CONNECT,
    SOCKET_FORCE_DISCONNECT,
} from './socketConstants';

export const socketConnect = (timestamp) => ({ type: SOCKET_CONNECT, timestamp });
export const socketInterruption = () => ({ type: SOCKET_INTERRUPTION });
export const socketDisconnect = () => ({ type: SOCKET_DISCONNECT });

export const socketForceConnect = () => ({ type: SOCKET_FORCE_CONNECT });
export const socketForceDisconnect = () => ({ type: SOCKET_FORCE_DISCONNECT });

export const channelsUpdated = ({ joined, left, boardChannels }) => ({
    type: CHANNELS_UPDATED,
    joined,
    left,
    boardChannels,
});
