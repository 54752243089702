import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const TextClipperIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={48}
        height={38}
        viewBox="0 0 48 38"
        className="Icon text-clipper"
        {...props}
    >
        <defs>
            <filter
                id="TextClipperIcon__a"
                width="116.7%"
                height="123.1%"
                x="-8.3%"
                y="-7.7%"
                filterUnits="objectBoundingBox"
            >
                <feMorphology in="SourceAlpha" operator="dilate" radius={1} result="shadowSpreadOuter1" />
                <feOffset dy={1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.431372549 0 0 0 0 0.431372549 0 0 0 0 0.443137255 0 0 0 0.329313859 0"
                />
            </filter>
            <filter
                id="TextClipperIcon__c"
                width="150%"
                height="169.2%"
                x="-25%"
                y="-30.8%"
                filterUnits="objectBoundingBox"
            >
                <feMorphology in="SourceAlpha" operator="dilate" radius={1} result="shadowSpreadOuter1" />
                <feOffset dy={1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.431372549 0 0 0 0 0.431372549 0 0 0 0 0.443137255 0 0 0 0.329313859 0"
                />
            </filter>
            <path id="TextClipperIcon__b" d="M0 0h36v26H0z" />
            <path id="TextClipperIcon__d" d="M0 0h36v26H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(6 5)">
                <use xlinkHref="#TextClipperIcon__b" fill="#000" filter="url(#TextClipperIcon__a)" />
                <use xlinkHref="#TextClipperIcon__b" fill="#FFF" />
                <path stroke="#929292" strokeOpacity={0.3} d="M-.5-.5h37v27h-37z" />
            </g>
            <path fill="#929292" d="M11 11h26v1H11zm0 3h24v1H11zm0 3h26v1H11zm0 3h22v1H11z" />
            <g transform="translate(6 5)">
                <use xlinkHref="#TextClipperIcon__d" fill="#000" filter="url(#TextClipperIcon__c)" />
                <use xlinkHref="#TextClipperIcon__d" fill="#FFF" />
                <path stroke="#929292" strokeOpacity={0.3} d="M-.5-.5h37v27h-37z" />
            </g>
            <path fill="#929292" d="M11 11h26v1H11zm0 3h24v1H11zm0 3h26v1H11zm0 3h22v1H11z" />
        </g>
    </svg>
);
const Memo = memo(TextClipperIcon);
export default Memo;
