import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementDocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={26}
        height={32}
        viewBox="0 0 26 32"
        className="Icon element-document"
        {...props}
    >
        <defs>
            <filter
                id="ElementDocumentIcon__b"
                width="138.5%"
                height="131.2%"
                x="-19.2%"
                y="-12.5%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <filter
                id="ElementDocumentIcon__d"
                width="279.6%"
                height="277.8%"
                x="-89.7%"
                y="-77.8%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                />
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter2" />
                <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                </feMerge>
            </filter>
            <path
                id="ElementDocumentIcon__a"
                d="M.855 0H16.74c.228 0 .447.091.608.254l8.406 8.496c.158.16.247.376.247.602v21.793a.855.855 0 0 1-.855.855H.855A.855.855 0 0 1 0 31.145V.855C0 .383.383 0 .855 0z"
            />
            <path id="ElementDocumentIcon__e" d="m17.094 0 8.812 9h-8.042a.855.855 0 0 1-.855-.855v-.01L17.094 0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <mask id="ElementDocumentIcon__c" fill="#fff">
                    <use xlinkHref="#ElementDocumentIcon__a" />
                </mask>
                <use xlinkHref="#ElementDocumentIcon__a" fill="#000" filter="url(#ElementDocumentIcon__b)" />
                <use xlinkHref="#ElementDocumentIcon__a" fill="#FFF" />
                <g fill="#000" mask="url(#ElementDocumentIcon__c)">
                    <use xlinkHref="#ElementDocumentIcon__e" filter="url(#ElementDocumentIcon__d)" />
                </g>
                <path
                    fill="#FFF"
                    d="M17.095 0 26 9h-8.136a.855.855 0 0 1-.855-.855v-.01L17.095 0z"
                    mask="url(#ElementDocumentIcon__c)"
                />
            </g>
            <path
                fill="#48505E"
                d="M20.25 24c.38 0 .693.28.743.648l.007.102c0 .414-.334.75-.75.75H5.75a.749.749 0 1 1 0-1.5h14.5zm-9.962-8 .118 1.81h-.549c-.376-.917-.525-1.316-1.16-1.316h-.242v3.972c0 .43.172.517.69.54v.494H6.37v-.494c.525-.023.705-.11.705-.54v-3.972h-.25c-.635 0-.784.4-1.16 1.316h-.541L5.243 16h5.045zm9.96 4c.38 0 .695.28.745.648l.007.102c0 .414-.344.75-.753.75h-6.494a.75.75 0 1 1 0-1.5h6.494zm0-4c.38 0 .695.28.745.648l.007.102c0 .414-.344.75-.753.75h-6.494a.75.75 0 1 1 0-1.5h6.494z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementDocumentIcon);
export default Memo;
