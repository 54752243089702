import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileBoardsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        viewBox="0 0 25 24"
        className="Icon mobile-boards"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M8.48 1.5a3 3 0 0 1 3 3V8a3 3 0 0 1-3 3h-3.5a3 3 0 0 1-3-3V4.5a3 3 0 0 1 3-3h3.5zm0 1.5h-3.5a1.5 1.5 0 0 0-1.493 1.356L3.48 4.5V8a1.5 1.5 0 0 0 1.356 1.493l.144.007h3.5a1.5 1.5 0 0 0 1.493-1.356L9.98 8V4.5a1.5 1.5 0 0 0-1.356-1.493L8.48 3zm0 10a3 3 0 0 1 3 3v3.5a3 3 0 0 1-3 3h-3.5a3 3 0 0 1-3-3V16a3 3 0 0 1 3-3h3.5zm0 1.5h-3.5a1.5 1.5 0 0 0-1.493 1.356L3.48 16v3.5a1.5 1.5 0 0 0 1.356 1.493L4.98 21h3.5a1.5 1.5 0 0 0 1.493-1.356l.007-.144V16a1.5 1.5 0 0 0-1.356-1.493L8.48 14.5zm11.5-13a3 3 0 0 1 3 3V8a3 3 0 0 1-3 3h-3.5a3 3 0 0 1-3-3V4.5a3 3 0 0 1 3-3h3.5zm0 1.5h-3.5a1.5 1.5 0 0 0-1.493 1.356l-.007.144V8a1.5 1.5 0 0 0 1.356 1.493l.144.007h3.5a1.5 1.5 0 0 0 1.493-1.356L21.48 8V4.5a1.5 1.5 0 0 0-1.356-1.493L19.98 3zm0 10a3 3 0 0 1 3 3v3.5a3 3 0 0 1-3 3h-3.5a3 3 0 0 1-3-3V16a3 3 0 0 1 3-3h3.5zm0 1.5h-3.5a1.5 1.5 0 0 0-1.493 1.356L14.98 16v3.5a1.5 1.5 0 0 0 1.356 1.493l.144.007h3.5a1.5 1.5 0 0 0 1.493-1.356l.007-.144V16a1.5 1.5 0 0 0-1.356-1.493l-.144-.007z"
        />
    </svg>
);
const Memo = memo(MobileBoardsIcon);
export default Memo;
