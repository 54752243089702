// Utils
import {
    getBackgroundColor,
    getColor,
    getElementType,
    getSecondaryColor,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { displayCommentAsCollapsed } from '../../../../../../common/comments/commentModelUtil';
import { isIconViewLike } from '../../../../../../common/elements/utils/elementDisplayUtils';

// Constants
import { COLORS, BACKGROUND_COLORS } from '../../../../../../common/colors/colorConstants';
import { getIsFeatureEnabled } from '../../../../../element/feature/elementFeatureUtils';
import { ELEMENT_FEATURES } from '../../../../../element/feature/elementFeatureConstants';
import { ElementType } from '../../../../../../common/elements/elementTypes';

const elementHasNoColor = (element) => !getColor(element);
const elementHasColor = (color) => (element) => color === getColor(element);

export const getIsColorActive = (selectedElements, color) => {
    const comparisonFn = !color || color === COLORS.NONE ? elementHasNoColor : elementHasColor(color.name || color);

    return selectedElements.every(comparisonFn);
};

export const getFirstSelectedColor = (selectedElements) => {
    if (!selectedElements || !selectedElements.first()) return;

    return getColor(selectedElements.first());
};

export const getFirstSelectedSecondaryColor = (selectedElements) => {
    if (!selectedElements || !selectedElements.first()) return;

    return getSecondaryColor(selectedElements.first());
};

const isTransparencyEnabled = getIsFeatureEnabled(ELEMENT_FEATURES.TRANSPARENT);

export const getFirstSelectedBackgroundColor = (selectedElements) => {
    if (!selectedElements || !selectedElements.first()) return;

    const color = getBackgroundColor(selectedElements.first());
    const transparent = isTransparencyEnabled(selectedElements.first());

    return transparent ? BACKGROUND_COLORS.TRANSPARENT.name : color;
};

export const getSelectedElementType = (selectedElements) =>
    selectedElements?.first() && getElementType(selectedElements.first());

export const getSelectedElementColorStatusClass = (selectedElements) => {
    const firstElement = selectedElements?.first();
    const elementType = getElementType(firstElement);

    switch (elementType) {
        case ElementType.COMMENT_THREAD_TYPE: {
            const isCollapsed = displayCommentAsCollapsed(firstElement);
            return isCollapsed ? 'collapsed' : null;
        }
        default:
            return null;
    }
};

export const getSupportColorBar = (selectedElements) => {
    const firstElement = selectedElements.first();
    const elementType = getElementType(firstElement);

    switch (elementType) {
        case ElementType.ANNOTATION_TYPE:
        case ElementType.BOARD_TYPE:
        case ElementType.ALIAS_TYPE:
        case ElementType.LINE_TYPE:
        case ElementType.COLOR_SWATCH_TYPE:
        case ElementType.TABLE_TYPE:
            return false;
        case ElementType.COMMENT_THREAD_TYPE:
            return !displayCommentAsCollapsed(firstElement);
        case ElementType.FILE_TYPE:
        case ElementType.DOCUMENT_TYPE:
            return !isIconViewLike(firstElement);
        default:
            return true;
    }
};
