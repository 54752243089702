import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniFileSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-file-selected"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M5 3h7l4 4v9a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M11 4v4h4v8H5V4h6zm3.5 3H12V4.5L14.5 7z" />
            <path
                fill="#323B4A"
                fillRule="nonzero"
                d="M12 2a2 2 0 0 1 1.414.586l2.707 2.707A3 3 0 0 1 17 7.414V15a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zm0 2H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V7.414a1 1 0 0 0-.293-.707L12 4z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniFileSelectedIcon);
export default Memo;
