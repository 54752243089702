import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const HelpCenterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={59}
        height={53}
        viewBox="0 0 59 53"
        className="Icon help-center"
        {...props}
    >
        <defs>
            <path id="HelpCenterIcon__a" d="M41 14.5V0H5v48h36V36.75z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path stroke="#979797" strokeWidth={2} d="M30.442 6 35 13.287V52H1V6h29.442z" />
            <use xlinkHref="#HelpCenterIcon__a" fill="#FFF" />
            <path stroke="#979797" strokeWidth={2} d="M40 47H6V1h34v46z" />
            <g stroke="#979797" strokeWidth={2} transform="translate(29 13)">
                <circle cx={11} cy={11} r={11} />
                <path d="m21.232 16.49 6.486 6.485a3 3 0 1 1-4.243 4.243l-6.486-6.486 4.243-4.243z" />
            </g>
        </g>
    </svg>
);
const Memo = memo(HelpCenterIcon);
export default Memo;
