// Constants
import { CURRENT_BOARD_ID_SET, VISIBLE_BOARD_ID_SET } from './currentBoardIdConstants';

export const currentBoardIdSet = ({ boardId, restored }) => ({
    type: CURRENT_BOARD_ID_SET,
    boardId,
    restored,
});

export const visibleBoardIdSet = ({ boardId }) => ({
    type: VISIBLE_BOARD_ID_SET,
    boardId,
});
