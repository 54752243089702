// Lib
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose, shouldUpdate } from '../../../node_module_clones/recompose';

// Selectors
import { getElementId, getColourHex } from '../selectors/elementSelector';
import { getLineHandleMode } from './utils/lineHandleUtil';
import {
    makeGetEdgesPx,
    getLineStartStyleSelector,
    getLineEndStyleSelector,
    getIsLineStartSnappedSelector,
    getIsLineEndSnappedSelector,
    shouldHideLineInPresentationModeSelector,
    // Measurements store selector
    makeGetConnectedLineElementMeasurements,
    makeGetAlignedLineEdgeDetails,
} from './lineSelector';

// Components
import Line from './Line';

// Measurements
import measurementsConnect from '../../components/measurementsStore/measurementsConnect';

const mapMeasurementsStateToProps = () =>
    createSelector(makeGetConnectedLineElementMeasurements(), (measurements) => ({ measurements }));

const makeMapStateToProps = () =>
    createSelector(
        getElementId,
        makeGetEdgesPx(makeGetAlignedLineEdgeDetails),
        getColourHex(),
        getLineStartStyleSelector,
        getLineEndStyleSelector,
        getIsLineStartSnappedSelector,
        getIsLineEndSnappedSelector,
        shouldHideLineInPresentationModeSelector,
        (
            elementId,
            lineDetails,
            colorHex,
            startStyle,
            endStyle,
            startSnapped,
            endSnapped,
            shouldHideLineInPresentationMode,
        ) => ({
            elementId,
            // This includes the origins, connection points, rectangles and bezier curves
            ...lineDetails,
            colorHex,
            startStyle,
            endStyle,
            startHandleMode: getLineHandleMode({ snapped: startSnapped }),
            endHandleMode: getLineHandleMode({ snapped: endSnapped }),
            shouldHideLineInPresentationMode,
        }),
    );

const enhance = compose(
    measurementsConnect(mapMeasurementsStateToProps),
    // If the line is a drag preview don't update the lines when their connected elements are removed (for example while
    // navigating to a new board)
    shouldUpdate((props, nextProps) => !nextProps.isPresentational || !!nextProps.isPreview),
    connect(makeMapStateToProps),
);

const LinePresentationalWrapper = enhance(Line);

export default LinePresentationalWrapper;
