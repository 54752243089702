// Lib
import * as Immutable from 'immutable';
import { isUndefined, isNull } from 'lodash/fp';

// Constants
import { EDITOR_SELECTION_SAVE, EDITOR_UNMOUNT } from '../editorConstants';

const initialState = Immutable.Map({});

const saveSelection = (state, action) => {
    const { selection, editorId } = action;

    if (isUndefined(selection) || !editorId) return state;

    if (isNull(selection)) {
        return state.delete(editorId);
    }

    return state.set(editorId, selection);
};

/**
 * Stores the selection state of the editor which is useful when transferring it to another editor.
 * E.g. From the canvas card editor to the full screen editor.
 */
export default function savedSelectionReducer(state = initialState, action) {
    switch (action.type) {
        case EDITOR_SELECTION_SAVE:
            return saveSelection(state, action);
        case EDITOR_UNMOUNT:
            return initialState;
        default:
            return state;
    }
}
