let timeoutId = null;

// Potentially provide a list of element IDs that should be
// checked for collisions, rather than just generically turning it on or off?
export const collisionDetectionManager = {
    enabled: false,
    temporarilyEnabled: false,
    // Allows collision detection to be turned off in the rare scenario where it's
    // in an infinite loop
    blocked: false,
    logged: false,
};

export const disableCollisionDetection = () => {
    collisionDetectionManager.enabled = false;
    collisionDetectionManager.temporarilyEnabled = false;
};

export const enableCollisionDetection = (temporarilyEnabled = false) => {
    collisionDetectionManager.enabled = true;
    collisionDetectionManager.temporarilyEnabled = temporarilyEnabled;
};

/**
 * To prevent an infinite loop bug - this flag will prevent the actual collision moves
 * from being made, but will enable
 */
export const blockCollisionDetectionUntilNavigation = () => {
    collisionDetectionManager.blocked = true;
    collisionDetectionManager.logged = false;
};

export const unblockCollisionDetectionAfterNavigation = () => {
    collisionDetectionManager.blocked = false;
    collisionDetectionManager.logged = false;
};

/**
 * Enables collision detection for a single JavaScript event loop cycle,
 * unless it's already enabled, in which case it will simply leave it as enabled.
 */
export const enableCollisionDetectionTemporarily = () => {
    // If already enabled, then don't do so temporarily
    if (collisionDetectionManager.enabled && !collisionDetectionManager.temporarilyEnabled) return;

    // If not enabled, or temporarily enabled - enable collision detection, clear old timers and start a new timer
    enableCollisionDetection(true);

    clearTimeout(timeoutId);

    // Toggle off collision detection in two cycles
    // (Safari requires two for collapsing and expanding columns for some reason)
    timeoutId = setTimeout(() => {
        timeoutId = setTimeout(disableCollisionDetection, 0);
    }, 0);
};
