// Lib
import loadable from 'react-loadable';

export default loadable({
    loader: () =>
        import(
            /* webpackChunkName: "app-login" */
            './AppLogin'
        ),
    loading: () => null,
});
