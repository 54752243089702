// Constants
import {
    PREVIEW_MODE_END,
    PREVIEW_MODE_START,
    PUBLISH_PREVIEW_PASSWORD_VALIDATED,
    PREVIEW_MODES,
} from './previewConstants';

export const startPublishPreviewMode = (startingBoardId) => ({
    type: PREVIEW_MODE_START,
    startingBoardId,
    mode: PREVIEW_MODES.PUBLISH_PREVIEW,
});

export const endPreviewMode = () => ({ type: PREVIEW_MODE_END, mode: PREVIEW_MODES.DEFAULT });
export const setPublishPreviewPasswordValidated = () => ({ type: PUBLISH_PREVIEW_PASSWORD_VALIDATED });
