// Utils
import { isEmpty } from '../../../../../common/utils/immutableHelper';
import { getElementId, getElementType } from '../../../../../common/elements/utils/elementPropertyUtils';
import {
    cardOrDocumentHasContent,
    columnHasContent,
    imageHasContent,
    linkHasContent,
} from '../../../../../common/elements/utils/elementTrashUtils';

// Constants
import { ElementType } from '../../../../../common/elements/elementTypes';

const commentThreadHasComments = ({ element, commentsByThreadId }) => {
    const elementId = getElementId(element);
    const commentIds = commentsByThreadId[elementId];
    return !isEmpty(commentIds);
};

const elementHasChildren = ({ element, elementGraph }) => {
    const elementId = getElementId(element);
    const childrenIds = elementGraph[elementId];
    return !isEmpty(childrenIds);
};

export const elementIsInterestingForTrash = ({ element, elementGraph, commentsByThreadId }) => {
    const elementType = getElementType(element);

    switch (elementType) {
        case ElementType.DOCUMENT_TYPE:
        case ElementType.CARD_TYPE:
            return cardOrDocumentHasContent(element);
        case ElementType.IMAGE_TYPE:
            return imageHasContent(element);
        case ElementType.LINK_TYPE:
            return linkHasContent(element);
        case ElementType.TASK_LIST_TYPE:
            return elementHasChildren({ element, elementGraph });
        case ElementType.COMMENT_THREAD_TYPE:
            return commentThreadHasComments({ element, commentsByThreadId });
        case ElementType.COLUMN_TYPE:
            return columnHasContent(element) || elementHasChildren({ element, elementGraph });
        case ElementType.BOARD_TYPE:
        case ElementType.ALIAS_TYPE:
        case ElementType.FILE_TYPE:
            return true;
        case ElementType.TASK_TYPE:
        case ElementType.LINE_TYPE:
            return false;
        default:
            return true;
    }
};
