// Lib
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setHideCaption, setShowCaption } from './captionActions';
import { startEditingMainEditor } from '../../element/selection/selectionActions';

// Utils
import { analyticsEvent } from '../../analytics';
import { isCard, isColorSwatch } from '../../../common/elements/utils/elementTypeUtils';
import { getElementId, getElementType, getShowCaption } from '../../../common/elements/utils/elementPropertyUtils';
import { hasCommandModifier } from '../../utils/keyboard/keyboardUtility';
import { isElementBelowEditableWidth } from '../../element/utils/elementUtil';
import * as typingBuffer from '../../../common/tiptap/utils/typingBufferSingleton';
import { shouldCaptionUseTiptapEditor } from '../../../common/tiptap/conversion/elementConversion/tiptapElementConversionUtils';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../../element/feature/elementFeatureSelector';

// Constants
import { KEY_CODES, NON_CHARACTERS } from '../../utils/keyboard/keyConstants';
import { CAPTION_EDITOR_KEY } from './captionConstants';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';
import { ImMNElement, MNElement } from '../../../common/elements/elementModelTypes';

export type UseCaptionContainerProps = {
    element: ImMNElement;
    dispatchUpdateElement: (args: { id: string; changes: Partial<MNElement['content']>; sync: boolean }) => void;
    stopEditing: () => void;
    startEditing: (args: { editorId: string; editorKey: string }) => void;
    isEditing: boolean;
    isEditable: boolean;
    isTiptapConversionEnabled: boolean;
};

export type UseCaptionContainerCallbacks = {
    captionKeyHandler: (event: KeyboardEvent) => void;
    saveCaptionContent: (caption: string, sync: boolean) => void;
    showCaption: () => void;
    onEmptyBackspace: () => void;
};

// as they get converted to TS, components can switch over to the hook to maintain type correctness
export const useCaptionContainer = (props: UseCaptionContainerProps): UseCaptionContainerCallbacks => {
    const { isEditable, isEditing, element, startEditing, stopEditing, dispatchUpdateElement } = props;

    const isTiptapConversionEnabled = useSelector(getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion));

    const dispatch = useDispatch();

    const onEmptyBackspace = useCallback(() => {
        if (!isEditable) return;

        const elementId = getElementId(element);

        dispatch(setHideCaption({ elementId }));

        if (!isCard(element)) return stopEditing();

        dispatch(startEditingMainEditor(elementId));
    }, [isEditable, element, stopEditing]);

    const showCaption = useCallback(() => {
        if (!isEditable) return;

        analyticsEvent(`added-${getElementType(element)}-caption`, { shortcut: true });

        dispatch(setShowCaption({ elementId: getElementId(element) }));
    }, [element, isEditable]);

    const captionKeyHandler = useCallback(
        (event) => {
            // Unfortunately have to handle the escape key separately as it's not causing a keypress event
            if (event.which === KEY_CODES.ESC) {
                stopEditing();
                return;
            }

            // Unfortunately also have to handle this case to prevent an issue.
            if (event.which === KEY_CODES.BACKSPACE) {
                event.preventDefault();
                return;
            }

            if (hasCommandModifier(event)) {
                return;
            }

            // don't trigger a start editing event when non-character keys are pressed
            if (NON_CHARACTERS.indexOf(event.which) !== -1) {
                return;
            }

            const showingCaption = getShowCaption(element);
            if (!showingCaption) {
                showCaption();
            }

            if (isEditing) {
                return;
            }

            const isBelowEditableWidth = isElementBelowEditableWidth(props);

            if (isBelowEditableWidth) {
                return;
            }

            startEditing({
                editorId: `${getElementId(element)}-${CAPTION_EDITOR_KEY}`,
                editorKey: CAPTION_EDITOR_KEY,
            });

            if (!shouldCaptionUseTiptapEditor(element, isTiptapConversionEnabled)) return;

            // For the Tiptap editor, we need to:
            //  - Use the typing buffer to add the character to the editor, because it won't be
            //    otherwise as the editor isn't focused. (I believe Draft works without this
            //    because it relies on interpreting the DOM, rather than keydown events, I think...)
            //  - Stop the default event behaviour & propagation, otherwise Mousetrap handlers will fire
            event.stopPropagation();
            event.preventDefault();

            typingBuffer.addChar(event.key);
        },
        [isEditing, element, stopEditing, showCaption],
    );

    const saveCaptionContent = useCallback(
        (caption, sync) => {
            const changes: Partial<MNElement['content']> = { caption };

            if (isColorSwatch(element)) {
                changes.captionModified = true;
            }

            dispatchUpdateElement({
                id: getElementId(element),
                changes,
                sync,
            });
        },
        [element, isEditable, dispatchUpdateElement],
    );

    return {
        captionKeyHandler,
        saveCaptionContent,
        showCaption,
        onEmptyBackspace,
    };
};
