// Lib
import React from 'react';

/**
 * This component is used when the element does not exist / could not be retrieved for that user.
 */
const NotFoundElementModal = () => (
    <div className="NotFoundElementModal">
        <h2>This element could not be found</h2>
        <div>
            <p>Sorry, the element you&apos;re trying to view no longer exists.</p>
        </div>
    </div>
);

NotFoundElementModal.propTypes = {};

export default NotFoundElementModal;
