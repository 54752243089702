import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SearchClearIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon search-clear"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={10} cy={10} r={10} fill="#6F6F72" />
            <path
                fill="#FFF"
                d="m11.2 10 2.398-2.399a.848.848 0 0 0-1.2-1.2L10 8.802l-2.399-2.4a.848.848 0 1 0-1.2 1.2L8.802 10l-2.399 2.399a.848.848 0 0 0 1.2 1.199L10 11.199l2.399 2.399a.848.848 0 1 0 1.199-1.2L11.199 10z"
            />
        </g>
    </svg>
);
const Memo = memo(SearchClearIcon);
export default Memo;
