// Lib
import React, { ReactElement } from 'react';
import classNames from 'classnames';

// Styles
import './MobilePage.scss';

interface MobilePageProps {
    children: ReactElement | ReactElement[];
    className?: string;
}

const MobilePage = (props: MobilePageProps): ReactElement => {
    const { children, className } = props;

    return <div className={classNames('MobilePage', className)}>{children}</div>;
};

export default MobilePage;
