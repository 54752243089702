// Lib
import Immutable from 'immutable';
import memoizeOne from 'memoize-one';
import { isEmpty } from 'lodash/fp';

// Utils
import globalLogger from '../../logger/logger';
import { asObject, toIdArray } from '../../../common/utils/immutableHelper';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Message builder
import {
    buildAddElementsCacheOperation,
    buildClearElementsCacheOperation,
    buildRemoveElementsCacheOperation,
} from './cacheOperationDataBuilder';

// Constants
import {
    ELEMENT_CONVERT_TO_CLONE,
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_DIFF_UPDATE,
    ELEMENT_ICON_FIND,
    ELEMENT_ICON_FIND_FAILURE,
    ELEMENT_ICON_FIND_SUCCESS,
    ELEMENT_ICON_SEARCH,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_LOCAL_DATA_MULTI,
    ELEMENT_SET_TYPE,
    ELEMENT_UNDO_CONVERT_TO_CLONE,
    ELEMENT_UPDATE,
    ELEMENT_UPDATE_ACL,
} from '../../../common/elements/elementConstants';
import { LOGOUT } from '../../auth/authConstants';
import {
    ELEMENTS_LOAD,
    ELEMENTS_LOAD_INTO_WORKER_CACHE,
    ELEMENTS_PURGE,
} from '../../../common/elements/elementsConstants';
import { ELEMENT_EDIT_COMPLETE } from '../../../common/elements/selectionConstants';
import { TRASH_CLEAR_COMPLETE } from '../../workspace/toolbar/trash/store/trashConstants';

// Types
import { AnyAction } from 'redux';
import { MNElement } from '../../../common/elements/elementModelTypes';
import { ActionMove, ActionObject, ActionUpdate } from '../../../common/actions/actionTypes';
import {
    CacheOperationAddElementsData,
    CacheOperationData,
    CacheOperationRemoveElementsData,
} from './cacheOperationTypes';

const logger = globalLogger.createChannel('cache-operation-message-builder');

/**
 * Gets the elements from the elementsState and adds them cache operation data.
 */
const createAddElementsCacheOperation = (
    elementIds: string[],
    elementsState: Immutable.Map<string, unknown>,
    action: ActionObject,
): CacheOperationAddElementsData | null => {
    const updatedElements: MNElement[] = [];
    for (const elementId of elementIds) {
        const updatedElement: MNElement = asObject(getElement(elementsState, elementId));

        if (!updatedElement) continue;

        updatedElement._id = updatedElement._id || elementId;
        updatedElements.push(updatedElement);
    }

    return buildAddElementsCacheOperation(updatedElements, action.type || 'Unknown Action');
};

/**
 * Gets the element IDs updated from an update action and adds the updated elements to the cache operation data.
 */
const handleElementUpdateAction = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    if (!action.updates) return null;

    const updatedIds = action.updates.map((update: ActionUpdate) => update.id);
    return createAddElementsCacheOperation(updatedIds, elementsState, action);
};

/**
 * Gets the element IDs updated from an update action and adds the updated elements to the cache operation data.
 */
const handleElementMoveAction = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    if (!action.moves) return null;

    const updatedIds = action.moves.map((move: ActionMove) => move.id);
    return createAddElementsCacheOperation(updatedIds, elementsState, action);
};

const handleElementLoadAction = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    if (!action.elements) return null;

    const elementIds = Object.values(action.elements).map(getElementId);
    return createAddElementsCacheOperation(elementIds, elementsState, action);
};

const handleElementsLoadIntoWorkerCache = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    if (!action.elements) return null;

    const elements = Object.values(action.elements);

    return buildAddElementsCacheOperation(elements, action.type || 'Unknown Action');
};

const handleElementIdAction = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    if (!action.id) return null;

    return createAddElementsCacheOperation([action.id], elementsState, action);
};

const handleElementDeleteAction = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationRemoveElementsData | null => {
    if (!action.id) return null;

    return buildRemoveElementsCacheOperation([action.id], action.type || 'Unknown Action');
};

const handleElementsPurgeAction = (action: ActionObject): CacheOperationRemoveElementsData | null => {
    if (!action.elementIds || isEmpty(action.elementIds)) return null;

    return buildRemoveElementsCacheOperation(action.elementIds, action.type || 'Unknown Action');
};

const handleTrashClearCompleteAction = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
    preUpdateElementsState: Immutable.Map<string, unknown>,
): CacheOperationRemoveElementsData | null => {
    if (preUpdateElementsState === elementsState) return null;

    const elementIds = toIdArray(preUpdateElementsState) as string[];

    const missingElementIds = elementIds.filter((elementId) => !elementsState.has(elementId));

    return buildRemoveElementsCacheOperation(missingElementIds, action.type || 'Unknown Action');
};

const handleCloneActions = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    const ids = [action.id, action.originalElementId, action.clonedElementId].filter(Boolean) as string[];
    return createAddElementsCacheOperation(ids, elementsState, action);
};

/**
 * If an action occurs that hasn't been handled, but does cause an element update, we still want to
 * update the elements in the cache, but we have to use a manual process for determining the updates.
 */
const checkUnhandledElementChanges = (
    action: ActionObject,
    elementsState: Immutable.Map<string, unknown>,
    preUpdateElementsState: Immutable.Map<string, unknown>,
): CacheOperationAddElementsData | null => {
    if (preUpdateElementsState === elementsState) return null;

    const perfStart = performance.now();
    const changedElementIds: string[] = [];

    // @ts-ignore This should work fine
    for (const [id, element] of elementsState) {
        if (element !== preUpdateElementsState.get(id)) changedElementIds.push(id);
    }
    const perfEnd = performance.now();

    logger.warn('Elements changed without action being handled. Manual handling required.', {
        actionType: action.type,
        durationMs: perfEnd - perfStart,
    });

    logger.debug('Full action that caused the unhandled element changes:', action);

    return createAddElementsCacheOperation(changedElementIds, elementsState, action);
};

/**
 * For any action that causes an elements update, handle the update and add the updated elements to the cache.
 */
export const buildCacheOperationFromElementsAction = memoizeOne(
    (
        action: AnyAction,
        elementsState: Immutable.Map<string, unknown>,
        preUpdateElementsState: Immutable.Map<string, unknown>,
    ): CacheOperationData | null => {
        // This action won't change the elements state, but it will change the cache
        if (action.type === ELEMENTS_LOAD_INTO_WORKER_CACHE)
            return handleElementsLoadIntoWorkerCache(action, elementsState);

        if (elementsState === preUpdateElementsState) return null;

        switch (action.type) {
            case TRASH_CLEAR_COMPLETE:
                return handleTrashClearCompleteAction(action, elementsState, preUpdateElementsState);
            case ELEMENT_DELETE:
                return handleElementDeleteAction(action, elementsState);
            case ELEMENT_CONVERT_TO_CLONE:
            case ELEMENT_UNDO_CONVERT_TO_CLONE:
                return handleCloneActions(action, elementsState);
            case ELEMENT_SET_TYPE:
            case ELEMENT_DIFF_UPDATE:
            case ELEMENT_MOVE_AND_UPDATE:
            case ELEMENT_UPDATE_ACL:
            case ELEMENT_CREATE:
                return handleElementIdAction(action, elementsState);
            case ELEMENTS_LOAD:
                return handleElementLoadAction(action, elementsState);
            case ELEMENT_MOVE_MULTI:
                return handleElementMoveAction(action, elementsState);
            case ELEMENT_UPDATE:
                return handleElementUpdateAction(action, elementsState);
            // Ignored actions
            //    This is the redux action that initialises the store, so no need to handle it
            case '@@INIT':
            case ELEMENT_ICON_FIND:
            case ELEMENT_ICON_FIND_SUCCESS:
            case ELEMENT_ICON_FIND_FAILURE:
            case ELEMENT_ICON_SEARCH:
            case ELEMENT_EDIT_COMPLETE:
            case ELEMENT_SET_LOCAL_DATA_MULTI:
                return null;
            case LOGOUT:
                return buildClearElementsCacheOperation();
            // NOTE: This will only be handled within the main thread's elementSummariesReducer.
            //  The persistenceMiddleware will explicitly ignore it.
            case ELEMENTS_PURGE:
                return handleElementsPurgeAction(action);
            default:
                return checkUnhandledElementChanges(action, elementsState, preUpdateElementsState);
        }
    },
);
