// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';

// Utils
import { getViewBox } from '../../../../utils/svgUtils';
import { getPathStroke, getStrokeColor, getStrokeColorWithFallback, getSvgPathFromStroke } from '../drawingEditorUtils';

/**
 * This is used in drawingEditorElementContentUtils.js to render the SVG
 * string that will be saved to the element.
 * So it's kept as simple as possible.
 */
const DrawingSvgRenderer = (props) => {
    const { paths, width, height, useFallbackColor } = props;

    const getStrokeColorFn = useFallbackColor ? getStrokeColorWithFallback : getStrokeColor;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={getViewBox({ width, height })}>
            {paths.map(({ id, color, thinning, size, ...rest }) => {
                const renderedStroke = getPathStroke({ thinning, size, ...rest });

                // When there's no thinning we want to simply draw a path and use the stroke
                // to let the browser create a nicely rendered thick line
                if (thinning === 0) {
                    return (
                        <path
                            key={id}
                            className="stroke-only"
                            stroke={getStrokeColorFn(color)}
                            strokeWidth={round(size, 1)}
                            fill="none"
                            strokeLinecap="round"
                            d={getSvgPathFromStroke(renderedStroke, false)}
                        />
                    );
                }

                return <path key={id} fill={getStrokeColorFn(color)} d={getSvgPathFromStroke(renderedStroke, true)} />;
            })}
        </svg>
    );
};

DrawingSvgRenderer.propTypes = {
    paths: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    useFallbackColor: PropTypes.bool,
};

export default DrawingSvgRenderer;
