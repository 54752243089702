// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Utils
import {
    getDueDate,
    getDueReminder,
    getElementId,
    getShowDueDate,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getDueDateClass, getDueDatePillContent, getDueDatePopupId } from '../assignmentUiUtils';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../../../platform/platformSelector';

// Components
import TaskPill from '../TaskPill';
import TaskDueDatePopup from './TaskDueDatePopup';
import Icon from '../../../../../components/icons/Icon';

// Constants
import { REMINDER_VALUES } from './taskDueDateConstants';
import { MilanoteApplicationMode } from '../../../../../platform/platformTypes';

// Styles
import './TaskDueDatePill.scss';

const TaskDueDatePill = (props) => {
    const { className, element, isEditable } = props;

    const appMode = useSelector(getMilanoteApplicationModeSelector);
    const shouldBlockEdits = appMode === MilanoteApplicationMode.mobileLegacy;

    const elementId = getElementId(element);
    const popupId = getDueDatePopupId(elementId);

    const hasDueDate = getShowDueDate(element) && !!getDueDate(element);
    const dueReminder = getDueReminder(element);
    const hasReminder = dueReminder && dueReminder !== REMINDER_VALUES.NONE;

    return (
        <TaskPill
            className={classNames('TaskDueDatePill', className, getDueDateClass(element))}
            popupId={popupId}
            elementId={elementId}
            populated={hasDueDate}
            isEditable={isEditable && !shouldBlockEdits}
            popup={<TaskDueDatePopup popupId={popupId} elementId={elementId} />}
        >
            {hasReminder && <Icon name="reminder-bell-mini" />}
            {getDueDatePillContent(element)}
        </TaskPill>
    );
};

TaskDueDatePill.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    element: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
};

export default TaskDueDatePill;
