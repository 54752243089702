import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { isElementBelowEditableWidth } from '../../../../../element/utils/elementUtil';
import { getShowCaption } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Actions
import { toggleCaption } from '../../../../../components/caption/captionActions';

// Styles
import './CaptionTool.scss';

/**
 * Gets the selected IDs and the currently editing ID.
 * The first selected ID is the element we're interested in because we only apply the caption to one image element.
 * If the same image element is being edited then the caption will be visible.
 */
const getCaptionState = createSelector(
    (state, ownProps) => ownProps.selectedElements,
    (selectedElements) => {
        const firstElement = selectedElements.first();
        const showingCaption = firstElement ? getShowCaption(firstElement) : false;

        return {
            showingCaption,
        };
    },
);

/**
 * Toggles the caption on and off whenever the caption tool is clicked by either changing the image element to be
 * currently editing (which by default will put the cursor into the field and show the caption), or deleting the
 * caption text and changing the image to not be editing so that the caption will no longer have focus.
 */
const mapDispatchToProps = (dispatch, ownProps) => ({
    onPointerDown: (event) => {
        event.preventDefault();
        dispatch(toggleCaption());
    },
});

const CaptionTool = (props) => {
    const { showingCaption, name } = props;

    const disableCaption = isElementBelowEditableWidth(props);

    return (
        <div className="CaptionTool">
            <ToolbarTool {...props} name={name || 'Caption'} isActive={showingCaption} disabled={disableCaption}>
                <Icon name="toolbar-caption" />
            </ToolbarTool>
        </div>
    );
};

CaptionTool.propTypes = {
    name: PropTypes.string,
    iconName: PropTypes.string,
    styleType: PropTypes.string,
    showingCaption: PropTypes.bool,
};

export default connect(getCaptionState, mapDispatchToProps)(CaptionTool);
