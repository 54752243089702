import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import EmojiImage from '../EmojiImage';
import Button from '../../buttons/Button';

import { getCompleteEmojiCode } from './reactionPopupUtil';

import './ReactionPopupEmojiButton.scss';

const ReactionPopupEmojiButton = ({ className, emoji, selected, skinTone, gridSize = 10, onClickFn }) => (
    <Button className={classNames('ReactionPopupEmojiButton', className, { selected })} onClickFn={onClickFn}>
        <EmojiImage emojiCode={getCompleteEmojiCode(emoji, skinTone)} size={gridSize * 2.4} />
    </Button>
);

ReactionPopupEmojiButton.propTypes = {
    emoji: PropTypes.object,
    selected: PropTypes.bool,
    className: PropTypes.string,
    skinTone: PropTypes.string,
    gridSize: PropTypes.number,
    onClickFn: PropTypes.func,
};

export default ReactionPopupEmojiButton;
