import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { kebabCase } from 'lodash/fp';

// Components
import Icon from '../../../components/icons/Icon';
import Button from '../../../components/buttons/Button';
import FormattingOptionsPopup from './FormattingOptionsPopup';

// Manager
import { getHotInstance, getTableOperations } from '../manager/HotTableManager';

// Utils
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { cellTypeUpdateShouldHappen } from '../utils/tableCellTypeUtils';
import { getNormalisedHotCellValueFromHot } from '../utils/tableCellFormattingUtils';

// Constants
import { CellTypeConstants } from '../../../../common/table/CellTypeConstants';

// Styles
import './CellTypePopup.scss';

const CellTypeButton = (props) => {
    const { element, currentCellType, isActive, allSelectedCells, lastClickedType, setLastClickedType, locale } = props;
    const { displayName, icon, formattingOptions } = CellTypeConstants[currentCellType];
    const elementId = getElementId(element);

    // Does type have formatting options AND
    // do all the selected cells have the current cell type, OR is it the last clicked cell type?
    const showFormattingOptions = !!formattingOptions && (isActive || lastClickedType === currentCellType);

    // Check if at least one of the selected cells can actually be updated to the current cell type
    const hotInstance = getHotInstance(elementId);
    const isDisabled = !allSelectedCells.some(({ row, col }) => {
        const normalisedHotCellValue = getNormalisedHotCellValueFromHot(elementId, row, col, hotInstance);
        return cellTypeUpdateShouldHappen(normalisedHotCellValue, currentCellType, locale);
    });

    const updateCellType = useCallback(() => {
        // Don't update cell type if it's disabled
        if (isDisabled) return;

        getTableOperations(elementId)?.updateCellType(currentCellType);

        setLastClickedType(currentCellType);
    }, [elementId, currentCellType, isDisabled]);

    const updateCellTypeFormat = useCallback(
        (changes) => {
            getTableOperations(elementId)?.updateCellTypeFormat(changes);
        },
        [elementId],
    );

    return (
        <>
            <Button
                onMouseDown={updateCellType}
                className={classNames('CellTypeButton', kebabCase(currentCellType), {
                    active: isActive,
                    disabled: isDisabled,
                })}
            >
                <Icon name={icon} className={'CellTypeIcon'} />
                <span>{displayName}</span>
                {isActive && <Icon name={'block-style-tick'} />}
                {!isActive && !!formattingOptions && <Icon name="chevron-right" />}
            </Button>
            {showFormattingOptions && (
                <FormattingOptionsPopup
                    {...props}
                    showFormattingOptions={showFormattingOptions}
                    updateCellTypeFormat={updateCellTypeFormat}
                />
            )}
        </>
    );
};

CellTypeButton.propTypes = {
    currentCellType: PropTypes.string,
    dispatchUpdateCellType: PropTypes.func,
    element: PropTypes.object,
    isActive: PropTypes.bool,
    allSelectedCells: PropTypes.array,
    lastClickedType: PropTypes.string,
    setLastClickedType: PropTypes.func,
    locale: PropTypes.string,
};

const CellTypePopup = (props) => {
    const { activeCellType } = props;
    return (
        <>
            {Object.keys(CellTypeConstants).map((currentCellType) => (
                <CellTypeButton
                    {...props}
                    key={currentCellType}
                    currentCellType={currentCellType}
                    isActive={activeCellType === currentCellType}
                />
            ))}
        </>
    );
};

CellTypePopup.propTypes = {
    activeCellType: PropTypes.string,
};

export default CellTypePopup;
