// Services

import localStorageService from '../utils/services/localStorage/localStorage';

const LAST_REFRESH_TIME_LOCAL_STORAGE_KEY = 'milanote.lastRefreshTime';
const RESET_APP_CACHE_LOCAL_STORAGE_KEY = 'milanote.appLocalCacheReset';

export const getLastRefreshTimeLocalStorage = (): string | null =>
    localStorageService.getItem(LAST_REFRESH_TIME_LOCAL_STORAGE_KEY);
export const setLastRefreshTimeLocalStorage = (value: string): void =>
    localStorageService.setItem(LAST_REFRESH_TIME_LOCAL_STORAGE_KEY, value) as void;

export const getAppLocalCacheResetLocalStorage = (): string | null =>
    localStorageService.getItem(RESET_APP_CACHE_LOCAL_STORAGE_KEY);
export const setAppLocalCacheResetLocalStorage = (value: string): void =>
    localStorageService.setItem(RESET_APP_CACHE_LOCAL_STORAGE_KEY, value) as void;
