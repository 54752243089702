// Utils
import getNewLine from './getNewLine';

/**
 * Return the selection in a block as an object:
 * { startLine, startOffset, endLine, endOffset }
 */
const getLineSelection = (text, startOffset, endOffset, sep) => {
    const separator = sep || getNewLine(text);

    let startLineIndex = 0;
    let endLineIndex = 0;

    let nextLineIndex = 0;
    let lastLineIndex = 0;
    let firstLineIndex = 0;

    while (nextLineIndex >= 0 && nextLineIndex < endOffset) {
        if (nextLineIndex < startOffset) {
            startLineIndex++;
            firstLineIndex = nextLineIndex;
        }

        endLineIndex++;

        lastLineIndex = nextLineIndex;
        nextLineIndex = text.indexOf(separator, nextLineIndex + separator.length);
    }

    return {
        startLine: Math.max(startLineIndex - 1, 0),
        startOffset: startOffset - firstLineIndex,
        endLine: Math.max(endLineIndex - 1, 0),
        endOffset: endOffset - lastLineIndex,
    };
};

export default getLineSelection;
