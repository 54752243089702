// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { stopPropagationOnly } from '../../../../utils/domUtil';

// Actions
import { closePopupsMatching, openPopup } from '../../../../components/popupPanel/popupActions';

// Selectors
import { popupOpenSelector } from '../../../../components/popupPanel/popupOpenSelector';

// Components
import PopupTrigger from '../../../../components/popupPanel/PopupTrigger';

// Styles
import './TaskPill.scss';

const PillPopupTarget = ({ isOpen, popupId, pillRef }) => {
    const [style, setStyle] = React.useState({ width: 0 });

    React.useEffect(() => {
        if (!pillRef || !pillRef.current) return;

        // Prevent the popup jumping position when it's first mounted
        if (!isOpen && !style.width) return;

        const rect = pillRef.current.getBoundingClientRect();

        setStyle({ width: rect.width });
    }, [isOpen]);

    return <div id={`${popupId}-target`} style={style} className="popup-target" />;
};

PillPopupTarget.propTypes = {
    popupId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    pillRef: PropTypes.object,
};

const Pill = (props) => {
    const { children, className, popupId, isOpen, active, populated } = props;

    const pillRef = React.useRef();

    const classes = classNames('TaskPill', className, { active: isOpen || active, populated });

    return (
        <div ref={pillRef} className={classes}>
            <div className="pill-contents">
                <PillPopupTarget isOpen={isOpen} popupId={popupId} pillRef={pillRef} />
                {children}
            </div>
        </div>
    );
};

Pill.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    popupId: PropTypes.string.isRequired,
    active: PropTypes.bool,
    populated: PropTypes.bool,
    isOpen: PropTypes.bool,
};

const mapStateToProps = () =>
    createSelector(
        (state, ownProps) => popupOpenSelector(ownProps.popupId)(state, ownProps),
        (popupIsOpen) => ({
            popupIsOpen,
        }),
    );

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenPopup: ({ popupId }) => dispatch(openPopup(popupId)),
    dispatchCloseOtherPillPopups: (predicateFn) => dispatch(closePopupsMatching({ predicateFn })),
});

const TaskPill = (props) => {
    const { children, popupId, popup, dispatchCloseOtherPillPopups, isEditable } = props;

    // Ensure you can't open two task pills at the same time
    const onToggle = React.useCallback((isNowOpen) => {
        if (!isNowOpen) return;

        return dispatchCloseOtherPillPopups(
            (activePopupId) => activePopupId.startsWith('task') && activePopupId !== popupId,
        );
    }, []);

    return (
        <div className="task-pill-container" onMouseDown={stopPropagationOnly} onClick={stopPropagationOnly}>
            <PopupTrigger popupId={popupId} onToggle={onToggle} disabled={!isEditable}>
                {(isOpen) => (
                    <Pill {...props} isOpen={isOpen}>
                        {children}
                    </Pill>
                )}
            </PopupTrigger>
            {popup}
        </div>
    );
};

TaskPill.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    popupId: PropTypes.string.isRequired,
    popup: PropTypes.node,
    active: PropTypes.bool,
    populated: PropTypes.bool,
    popupIsOpen: PropTypes.bool,
    isEditable: PropTypes.bool,
    dispatchCloseOtherPillPopups: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPill);
