import { mergeAttributes } from '@tiptap/core';
import DefaultCodeBlock from '@tiptap/extension-code-block';

export const CodeBlock = DefaultCodeBlock.extend({
    renderHTML({ node, HTMLAttributes }) {
        return [
            'pre',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            [
                'code',
                {
                    class: node.attrs.language ? this.options.languageClassPrefix + node.attrs.language : null,
                },
                ['span', { class: 'code-content' }, 0],
            ],
        ];
    },

    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-.': () => this.editor.commands.toggleCodeBlock(),
            'Mod->': () => this.editor.commands.toggleCodeBlock(),
        };
    },
});
