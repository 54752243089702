// Utils
import { matchHyperlink } from './patternMatching/markdownPatternMatcher';
import insertLink from './insertLink';
import editorGetTextPriorToSelection from '../../customRichUtils/editorState/editorGetTextPriorToSelection';

export default (editorState, character) => {
    if (character && character !== ')' && character !== ' ') return editorState;

    // Get text before selection point
    const textBeforeCharacter = editorGetTextPriorToSelection(editorState);
    const line = `${textBeforeCharacter}${character}`;
    const linkMatch = matchHyperlink(line);

    return linkMatch ? insertLink(editorState, linkMatch, character) : editorState;
};
