// Lib
import { SelectionState } from 'draft-js';

// Utils
import { prop } from '../../immutableHelper';

export default (rawState) => {
    const blocks = prop('blocks', rawState);

    if (!blocks) return null;

    const firstBlock = prop(0, blocks);
    const blockKey = prop('key', firstBlock);

    const selection = SelectionState.createEmpty(blockKey);
    return selection.merge({
        anchorKey: blockKey,
        anchorOffset: 0,
        focusKey: blockKey,
        focusOffset: 0,
        isBackward: false,
        hasFocus: true,
    });
};
