// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getLineSvgPath } from './svg/LinePath';

// Styles
import './LineGhostSvg.scss';

const LineGhostSvg = (props) => {
    const { show, bezier } = props;

    if (!show || !bezier) return null;

    return (
        <svg className="LineGhostSvg" version="1.1">
            <path d={getLineSvgPath(bezier)} className="line-path ghost-line-path" />
        </svg>
    );
};

LineGhostSvg.propTypes = {
    show: PropTypes.bool,
    bezier: PropTypes.object,
};

export default LineGhostSvg;
