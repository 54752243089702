import { padCharsStart } from 'lodash/fp';
import { COLOR_SPACE } from './colorConstants';

type RgbArray = [number, number, number];
type HslArray = [number, number, number];

const octetRe = '([01]?\\d\\d?|2[0-4]\\d|25[0-5])';
const percentRe = '((?:\\d\\d?(?:\\.[0-9]*)?|100(?:.0*)?))%';
const hexUnitRe = '[0-9A-Fa-f]';

const hexShortContentRe = `(${hexUnitRe})(${hexUnitRe})(${hexUnitRe})`;
const hexContentRe = `(${hexUnitRe}{2})(${hexUnitRe}{2})(${hexUnitRe}{2})`;
const rgbContentRe = `${octetRe}, *${octetRe}, *${octetRe}`;
const hslContentRe = `([0-9]+(?:.[0-9]*)?), *${percentRe}, *${percentRe}`;

const hexShortRe = new RegExp(`^#${hexShortContentRe}$`, 'i');
const hexRe = new RegExp(`^#${hexContentRe}$`, 'i');
const rgbRe = new RegExp(`^rgb\\(${rgbContentRe}\\)$`, 'i');
const hslRe = new RegExp(`^hsl\\(${hslContentRe}\\)$`, 'i');

export const isHexColorFormat = (str: string | null | undefined): string[] | false =>
    (str && str.match && (str.match(hexRe) || str.match(hexShortRe))) || false;
export const isRgbColorFormat = (str: string | null | undefined): string[] | false =>
    (str && str.match && str.match(rgbRe)) || false;
export const isHslColorFormat = (str: string | null | undefined): string[] | false =>
    (str && str.match && str.match(hslRe)) || false;

/**
 COLOR CSS FORMAT
 - HEX: #111111
 - RGB: rgb(17,17,17)
 - HSL: hsl(300,50%,50%)
 */
export const isValidColorFormat = (str: string | null | undefined): string[] | false =>
    isHexColorFormat(str) || isHslColorFormat(str) || isRgbColorFormat(str);

const rgbDisplayRe = new RegExp(`^${rgbContentRe}$`, 'i');
const hslDisplayRe = new RegExp(`^${hslContentRe}$`, 'i');

export const isRgbColorDisplayFormat = (str: string | null | undefined): string[] | false =>
    (str && str.match && str.match(rgbDisplayRe)) || false;
export const isHslColorDisplayFormat = (str: string | null | undefined): string[] | false =>
    (str && str.match && str.match(hslDisplayRe)) || false;

/**
 COLOR DISPLAY FORMAT - Used to display colors in color swatches
  - HEX: #111111
  - RGB: 17,17,17
  - HSL: 300,50%,50%
 */
export const isValidColorDisplayFormat = (str: string | null | undefined): string[] | false =>
    isHexColorFormat(str) || isHslColorDisplayFormat(str) || isRgbColorDisplayFormat(str);

const hexShortInputRe = new RegExp(`^#?${hexShortContentRe}$`, 'i');
const hexInputRe = new RegExp(`^#?${hexContentRe}$`, 'i');

/**
 HEX INPUT FORMAT - Valid HEX input format for color swatches
  e.g. #123, #112233, 123, 112233
 */
// eslint-disable-next-line max-len
const isHexColorInputFormat = (str: string | null | undefined): string[] | false =>
    (str && str.match && (str.match(hexInputRe) || str.match(hexShortInputRe))) || false;

export const inferColorSpace = (str: string | null | undefined): string | null => {
    if (isHexColorFormat(str) || isHexColorInputFormat(str)) return COLOR_SPACE.HEX;
    if (isRgbColorFormat(str) || isRgbColorDisplayFormat(str)) return COLOR_SPACE.RGB;
    if (isHslColorFormat(str) || isHslColorDisplayFormat(str)) return COLOR_SPACE.HSL;
    return null;
};

const padHex = padCharsStart('0', 2);

export const rgbToHex = ([r, g, b]: RgbArray): string => {
    const hex = [r, g, b].map((x) => padHex(x.toString(16))).join('');
    return `#${hex}`;
};

const convertToFullHexCode = ([r, g, b]: [string, string, string]): string => `#${r}${r}${g}${g}${b}${b}`;

export const hexToRgb = (hex: string): RgbArray | null => {
    let hexFullText = hex;

    // If short hex format detected, convert hex to full format first
    const shortHexMatches = hex.match(hexShortInputRe);
    if (shortHexMatches) {
        // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
        const [_, r, g, b] = shortHexMatches;

        hexFullText = convertToFullHexCode([r, g, b]);
    }

    const matches = isHexColorInputFormat(hexFullText);
    if (!matches) return null;

    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const [_, r, g, b] = matches;

    return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
};

export const parseRgbText = (rgbText: string): RgbArray | null => {
    const matches = isRgbColorFormat(rgbText) || isRgbColorDisplayFormat(rgbText);
    if (!matches) return null;

    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const [_, r, g, b] = matches;

    return [parseInt(r, 10), parseInt(g, 10), parseInt(b, 10)];
};

export const parseHslText = (hslText: string): HslArray | null => {
    const matches = isHslColorFormat(hslText) || isHslColorDisplayFormat(hslText);
    if (!matches) return null;

    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const [_, h, s, l] = matches;

    return [parseInt(h, 10), parseInt(s, 10), parseInt(l, 10)];
};
