// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../node_module_clones/recompose';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import { isCommentThread } from '../../../common/elements/utils/elementTypeUtils';
import { isLocationAttached } from '../../../common/elements/utils/elementLocationUtils';
import { getLocationPosition } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getElement } from '../selectors/elementSelector';

// Components
import Element from '../Element';

const getPositionStyle = (elementPosition) => ({
    top: `clamp(0%, ${prop('y', elementPosition)}, 100%)`,
    left: `clamp(0%, ${prop('x', elementPosition)}, 100%)`,
});

const mapStateToProps = () =>
    createStructuredSelector({
        element: getElement,
    });

const CommentAttached = (props) => {
    const { currentBoardId, elementId, element, gridSize, permissions, isPresentational } = props;

    if (!isCommentThread(element) || !isLocationAttached(element)) return null;

    const elementPosition = getLocationPosition(element);

    return (
        <div className="CommentAttached HotSpot" style={getPositionStyle(elementPosition)}>
            <Element
                currentBoardId={currentBoardId}
                permissions={permissions}
                isPresentational={isPresentational}
                elementId={elementId}
                element={element}
                gridSize={gridSize}
            />
        </div>
    );
};

const enhance = compose(connect(mapStateToProps));

CommentAttached.propTypes = {
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object,
    permissions: PropTypes.number,
    gridSize: PropTypes.number,
    isPresentational: PropTypes.bool,
};

export default enhance(CommentAttached);
