// Actions
import { fetchReferencedNotificationsData } from './notificationDataFetchActions';

// Constants
import { NOTIFICATIONS_ADD_OR_UPDATE } from '../../common/notifications/notificationConstants';

export default (store) => (next) => (action) => {
    if (!action.remote || action.type !== NOTIFICATIONS_ADD_OR_UPDATE) return next(action);

    // Fetch missing data when new notifications arrive via sockets
    store.dispatch(fetchReferencedNotificationsData(action));

    return next(action);
};
