// Lib
import { debounce } from 'lodash';
import { isEmpty } from 'lodash/fp';

// Util
import { prop } from '../../common/utils/immutableHelper';
import * as notificationsService from './notificationsService';
import { getTimestamp } from '../../common/utils/timeUtil';
import { asyncResource } from '../utils/services/http/asyncResource/asyncResource';
import {
    getNotificationCommentChangeIsSeen,
    getNotificationElementChangeIsSeen,
} from '../../common/notifications/notificationModelUtils';

// Selectors
import { getNotificationsSelector, getOldestNotificationTime } from './notificationsSelector';

// Actions
import { fetchReferencedNotificationsData, markHiddenNotificationsAsViewed } from './notificationDataFetchActions';

// Constants
import { ResourceTypes } from '../utils/services/http/asyncResource/asyncResourceConstants';
import {
    NOTIFICATIONS_MARK_ALL_VIEWED,
    NOTIFICATIONS_MARK_ELEMENTS_VIEWED,
    NOTIFICATIONS_MARK_OBSERVED,
    NOTIFICATIONS_MARK_VIEWED,
} from '../../common/notifications/notificationConstants';

export const fetchNotifications =
    ({ after, before = undefined, notificationIds = undefined } = {}) =>
    (dispatch) =>
        dispatch(
            asyncResource(
                ResourceTypes.notifications,
                undefined,
                true,
            )(async () => {
                const data = await notificationsService.fetchNotifications({ after, before, notificationIds });

                await dispatch(fetchReferencedNotificationsData(data));
                await dispatch(markHiddenNotificationsAsViewed(data));

                return {
                    after,
                    before,
                    notificationIds,
                    ...data,
                };
            }),
        );

export const fetchMissingNotifications =
    ({ notificationIds = [] }) =>
    (dispatch, getState) => {
        const state = getState();
        const notifications = getNotificationsSelector(state);

        const missingNotificationIds = notificationIds.filter((notificationId) => !notifications.has(notificationId));

        if (isEmpty(missingNotificationIds)) return;

        return dispatch(fetchNotifications({ notificationIds: missingNotificationIds }));
    };

export const fetchOlderNotifications = () => (dispatch, getState) => {
    const state = getState();
    const oldestNotificationTimestamp = getOldestNotificationTime(state) || Date.now();
    return dispatch(fetchNotifications({ before: oldestNotificationTimestamp }));
};

export const markNotificationsAsSeen = (notificationIds) => ({
    type: NOTIFICATIONS_MARK_VIEWED,
    notificationIds,
    viewedTimestamp: getTimestamp(),
    sync: true,
});

export const markAllNotificationsAsSeen = (userId) => ({
    type: NOTIFICATIONS_MARK_ALL_VIEWED,
    userId,
    viewedTimestamp: getTimestamp(),
    sync: true,
});

export const markNotificationsAsObserved = (notificationIds) => ({
    type: NOTIFICATIONS_MARK_OBSERVED,
    notificationIds,
    observedTimestamp: getTimestamp(),
    sync: true,
});

/**
 * This debounces the firing of the 'NOTIFICATIONS_MARK_ELEMENTS_VIEWED' for half a second
 * to reduce the number of individual actions that are sent to the server.
 */
const createDebouncedMarkNotificationElementsAsSeen = () => {
    let collatedViews = [];
    let collatedCommentViews = [];

    const dispatchMarkNotificationElementsAsSeen = (dispatch) => {
        dispatch({
            type: NOTIFICATIONS_MARK_ELEMENTS_VIEWED,
            views: collatedViews,
            commentViews: collatedCommentViews,
            timestamp: getTimestamp(),
            sync: true,
        });
        collatedViews = [];
        collatedCommentViews = [];
    };
    const debouncedDispatchMarkNotificationElementsAsSeen = debounce(dispatchMarkNotificationElementsAsSeen, 500);

    return ({ views, commentViews }) =>
        (dispatch, getState) => {
            const state = getState();
            const notifications = getNotificationsSelector(state);

            const unseenViews = views.filter(({ notificationId, elementId }) => {
                const notification = prop(notificationId, notifications);
                return !getNotificationElementChangeIsSeen(notification, elementId);
            });

            const unseenCommentViews = commentViews.filter(({ notificationId, commentId }) => {
                const notification = prop(notificationId, notifications);
                return !getNotificationCommentChangeIsSeen(notification, commentId);
            });

            if (!isEmpty(unseenViews)) {
                collatedViews = collatedViews.concat(unseenViews);
            }

            if (!isEmpty(unseenCommentViews)) {
                collatedCommentViews = collatedCommentViews.concat(unseenCommentViews);
            }

            if (isEmpty(unseenViews) && isEmpty(unseenCommentViews)) return;

            debouncedDispatchMarkNotificationElementsAsSeen(dispatch);
        };
};

export const markNotificationElementsAsSeen = createDebouncedMarkNotificationElementsAsSeen();
