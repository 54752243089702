// Lib
import React from 'react';

import PropTypes from 'prop-types';

// Components
import DragHandle from './DragHandle';

// Styles
import './EdgeDragHandle.scss';

const EdgeDragHandle = (props) => <DragHandle className="EdgeDragHandle" {...props} />;

EdgeDragHandle.propTypes = {
    show: PropTypes.bool,
    pos: PropTypes.object,
};

export default EdgeDragHandle;
