import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarH1Icon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-h-1"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M19.005 19.949 19 16.5h-6l-.005 3.449c0 1.008.54 1.196 1.71 1.23V22H9v-.82c1.206-.035 1.71-.223 1.71-1.231v-7.915c0-.991-.504-1.18-1.71-1.213V10h5.704v.82c-1.17.035-1.71.223-1.71 1.214L13 15.5h6l.005-3.466c0-.991-.54-1.18-1.71-1.213V10H23v.82c-1.206.035-1.71.223-1.71 1.214v7.915c0 1.008.504 1.196 1.71 1.23V22h-5.704v-.82c1.17-.035 1.71-.223 1.71-1.231z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarH1Icon);
export default Memo;
