import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationTaskIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-task"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#CCC"
                d="M0 4.009C0 2.899.895 2 1.994 2h12.012C15.107 2 16 2.902 16 4.009v7.981c0 1.11-.895 2.009-1.994 2.009H1.994A2.002 2.002 0 0 1 0 11.991V4.01z"
            />
            <path
                fill="#FFF"
                d="M1 3.995A1 1 0 0 1 2.007 3h11.986C14.55 3 15 3.456 15 3.995v8.01a1 1 0 0 1-1.007.995H2.007A1.007 1.007 0 0 1 1 12.005v-8.01z"
            />
            <path
                fill="#767C86"
                d="M5.911 8.266a.427.427 0 0 1 .606 0 .427.427 0 0 1 0 .606L5.09 10.3a.427.427 0 0 1-.606 0l-.857-.857a.43.43 0 0 1 .606-.606l.554.554zM12.288 9c.244 0 .448.183.49.412l.008.088c0 .276-.215.5-.498.5H8.283a.504.504 0 0 1-.497-.5c0-.276.215-.5.497-.5h4.005zM5.911 5.266a.427.427 0 0 1 .606 0 .427.427 0 0 1 0 .606L5.09 7.3a.427.427 0 0 1-.606 0l-.857-.857a.43.43 0 0 1 .606-.606l.554.554zM12.288 6c.244 0 .448.183.49.412l.008.088c0 .276-.215.5-.498.5H8.283a.504.504 0 0 1-.497-.5c0-.276.215-.5.497-.5h4.005z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationTaskIcon);
export default Memo;
