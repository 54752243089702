// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { isEmpty, defer } from 'lodash';

// Geometry utils
import { getUpdatedControlPoint } from './utils/lineControlPointUtil';

// Selectors
import { getUpdatedLinePointsOfInterest } from './lineControlPointSelector';
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getShallowMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Constants
import { NO_DRAG_OFFSET } from '../../utils/dnd/dndConstants';
import { LINE_CONTROL_POINT_DND_TYPE } from '../../../common/lines/lineConstants';

const getDroppedControlPointPosition =
    ({ elementId, element, offsetDiff }) =>
    (dispatch, getState, { measurementsStore }) => {
        const state = getState();

        const measurements = getShallowMeasurementsMap(measurementsStore.getState());
        const linePois = getUpdatedLinePointsOfInterest(state, {
            elementId,
            element,
            offsetDiff,
            measurements,
        });

        const gridSize = getGridSize(state);

        const { start, end, controlPoint } = linePois;

        if (!start || !end || !controlPoint) return null;

        return getUpdatedControlPoint({ ...linePois, gridSize });
    };

const controlPointDragSource = (Component) => {
    const ControlPointDragSource = (props) => {
        const { elementId, toggleLineVisibility, pos, element, dispatchPointDragStart, updateLineControlPoint } = props;
        const dispatch = useDispatch();
        const dispatchGetDroppedControlPoint = (args) => dispatch(getDroppedControlPointPosition(args));

        const [dragProps, connectDragSource, connectDragPreview] = useDrag(
            {
                item: { id: elementId, type: LINE_CONTROL_POINT_DND_TYPE },
                begin: () => {
                    defer(toggleLineVisibility);
                    dispatchPointDragStart();
                    return { id: elementId, element, pos };
                },
                end: (item, monitor) => {
                    const dropResult = monitor.getDropResult();
                    if (!monitor.didDrop() || isEmpty(dropResult)) {
                        toggleLineVisibility();
                        return;
                    }

                    const { offsetDiff = NO_DRAG_OFFSET } = dropResult;

                    const control = dispatchGetDroppedControlPoint({ elementId, element, offsetDiff });

                    updateLineControlPoint({ control });
                    toggleLineVisibility();
                },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(),
                }),
            },
            [toggleLineVisibility, elementId, element, updateLineControlPoint, pos, dispatchPointDragStart],
        );

        return (
            <Component
                {...props}
                {...dragProps}
                connectDragPreview={connectDragPreview}
                connectDragSource={connectDragSource}
            />
        );
    };

    ControlPointDragSource.propTypes = {
        elementId: PropTypes.string,
        toggleLineVisibility: PropTypes.func,
        pos: PropTypes.object,
        element: PropTypes.object,
        dispatchPointDragStart: PropTypes.func,
        updateLineControlPoint: PropTypes.func,
    };

    return ControlPointDragSource;
};

export default controlPointDragSource;
