// Lib
import React from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'shallowequal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Singletons
import zoomStateSingleton from '../../canvas/zoom/zoomStateSingleton';

// Utils
import * as rectLib from '../../../common/maths/geometry/rect';
import measurementsConnect from '../measurementsStore/measurementsConnect';
import measurementsRegistry from '../measurementsStore/measurementsRegistry';
import { unTransformMeasurementsInPlace } from '../measurementsStore/elementMeasurements/utils/measureElementZoomUtils';

// Selectors
import { canvasZoomStateSelector } from '../../canvas/store/canvasZoomSelector';
import {
    getCurrentBoardId,
    getCurrentBoardVisibleDescendantLocations,
} from '../../element/selectors/currentBoardSelector';

// Components
import { poiBoardSectionContextConsumer } from './PoiBoardSectionContext';
import Measure from '../../../node_module_clones/react-measure/src/Measure';

// Actions
import { poiRemove, poiSet } from './poiActions';
import { hasChanged } from '../../utils/react/propsComparisons';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';

let poiInstanceCount = 0;

const zoomStateHasChanged = hasChanged('zoomState');
const poiTypeHasChanged = hasChanged('poiType');
const poiDataHasChanged = (oldProps, newProps) => !shallowEqual(oldProps.poiData, newProps.poiData);
const positionsHaveChanged = hasChanged('positions');

const translateMeasurementsViaMutation = (translation, measurements) => {
    if (!measurements || !translation) return measurements;

    if (measurements.x || measurements.y) {
        measurements.x += translation.x;
        measurements.y += translation.y;
    }
    measurements.top += translation.y;
    measurements.bottom += translation.y;
    measurements.left += translation.x;
    measurements.right += translation.x;
    return measurements;
};

const mapMeasurementsDispatchToProps = (dispatch) => ({
    dispatchPoiSet: ({ id, poiType, targetRect, data, currentBoardId, section }) =>
        dispatch(poiSet({ id, poiType, currentBoardId, section, targetRect, data })),
    dispatchPoiRemove: ({ id }) => dispatch(poiRemove({ id })),
});

const mapStateToProps = createStructuredSelector({
    currentBoardId: getCurrentBoardId,
    zoomState: canvasZoomStateSelector,
    // NOTE: This is a hack / workaround to only re-measure element POIs when element locations have changed
    positions: getCurrentBoardVisibleDescendantLocations,
});

export default (DecoratedComponent) => {
    @poiBoardSectionContextConsumer
    @measurementsConnect(undefined, mapMeasurementsDispatchToProps)
    @connect(mapStateToProps)
    class PoiDecorator extends React.Component {
        constructor(props) {
            super(props);

            poiInstanceCount++;

            this.id = `POI_${poiInstanceCount}`;

            this.measurements = null;
            this.poiSet = false;

            this.isAwaitingMeasurement = false;
        }

        componentWillReceiveProps(nextProps) {
            if (poiTypeHasChanged(this.props, nextProps) || poiDataHasChanged(this.props, nextProps)) {
                this.updateStoredPoi(nextProps);
            }
        }

        /**
         * NOTE: This is a hack / workaround to only re-measure components when we think the position might
         * have changed (rather than using a polling method or similar).
         * The ResizeObserver (which react-measure is built upon) only re-measures when the size of a DOM
         * element changes, not its position.
         *
         * If you pass in a property that changes when the position of the element has changed then it will
         * cause the element to re-measure itself.
         */
        componentDidUpdate(prevProps) {
            const shouldForceMeasure =
                positionsHaveChanged(prevProps, this.props) ||
                (this.isAwaitingMeasurement && zoomStateHasChanged(prevProps, this.props));

            if (shouldForceMeasure) this.forceMeasure();
        }

        componentWillUnmount() {
            this.removeStoredPoi(this.props);
        }

        updateStoredPoi = (props) => {
            const { dispatchPoiSet, poiData, poiType, currentBoardId, boardSection } = props;

            if (!boardSection) return;

            // If no poiType, no measurements or no context then just ignore this
            if (!poiType || !this.measurements || !boardSection) {
                return this.removeStoredPoi(props);
            }

            dispatchPoiSet({
                id: this.id,
                poiType,
                currentBoardId,
                section: boardSection,
                targetRect: this.measurements,
                data: poiData,
            });

            this.poiSet = true;
        };

        removeStoredPoi = (props) => {
            if (!this.poiSet) return;

            const { dispatchPoiRemove } = props;
            dispatchPoiRemove({ id: this.id });
            this.poiSet = false;
        };

        measureElement = (measurements) => {
            const { boardSection, commentId, zoomState } = this.props;

            if (zoomStateSingleton.getIsZooming()) {
                this.isAwaitingMeasurement = true;
                return;
            }

            this.isAwaitingMeasurement = false;

            const translation =
                boardSection === BoardSections.CANVAS ? measurementsRegistry.getCanvasViewportTranslation() : null;

            let translatedMeasurements = translateMeasurementsViaMutation(translation, measurements);

            // If this activity has a comment ID then it's for a comment, so we need to check the bounds of the
            // comment thread and make sure we restrict the measurements to its bounds
            // NOTE: This is only for activity indicators within expanded comment threads. Collapsed comment threads
            // will not enter this code path (they will when their popup is opened, however)
            if (commentId && this.measureComponent?._node) {
                const commentThreadParent = this.measureComponent._node.closest('.CommentThread');
                const threadClientRect = commentThreadParent && commentThreadParent.getBoundingClientRect();
                const threadMeasurements = rectLib.translate(translation, threadClientRect);

                translatedMeasurements = rectLib.clampWithinRect(threadMeasurements, translatedMeasurements);
            }

            translatedMeasurements = unTransformMeasurementsInPlace(translatedMeasurements, zoomState);

            // If there's been no changes to the measurements, ignore this update
            if (shallowEqual(this.measurements, translatedMeasurements)) return;

            this.measurements = translatedMeasurements;

            this.updateStoredPoi(this.props);
        };

        forceMeasure = () => {
            this.measureComponent && this.measureComponent.measure();
        };

        render() {
            const { positions, ...rest } = this.props; // eslint-disable-line no-unused-vars
            const { boardSection } = this.props;

            // If there's no board section, don't attach the measuring component
            if (!boardSection) return <DecoratedComponent {...this.props} />;

            return (
                <Measure
                    ref={(c) => {
                        this.measureComponent = c;
                    }}
                    onMeasure={this.measureElement}
                >
                    <DecoratedComponent {...rest} />
                </Measure>
            );
        }
    }

    PoiDecorator.propTypes = {
        currentBoardId: PropTypes.string,
        commentId: PropTypes.string,

        poiType: PropTypes.string.isRequired,
        poiData: PropTypes.object.isRequired,
        positions: PropTypes.object,
        // Would usually come from the context
        boardSection: PropTypes.string,
        dispatchPoiSet: PropTypes.func,
        dispatchPoiRemove: PropTypes.func,

        zoomState: PropTypes.object,
    };

    return PoiDecorator;
};
