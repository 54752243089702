// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { getUsers } from '../../user/usersSelector';
import { getShortName } from '../../../common/users/userHelper';
import { chooseSingular } from '../../../common/utils/stringUtil';
import { asObject } from '../../../common/utils/immutableHelper';

// Hooks
import useUpdateEffect from '../../utils/react/useUpdateEffect';

// Components
import elementActivityPoiDecorator from './elementActivityPoiDecorator';
import FriendlyTimestamp from '../../components/datetime/FriendlyTimestamp';
import FetchUsersOnMount from '../../user/fetchUsers/FetchUsersOnMount';
import ActivityIndicatorChangeDescription from './ActivityIndicatorChangeDescription';

import TooltipSource from '../../components/tooltips/TooltipSource';

// Styles
import './ActivityIndicator.scss';

const ACTIVITY_TOOLTIP_MAX_NAMES = 6;

const reactingUserNamesSelector = createSelector(
    (_, { currentUserId }) => currentUserId,
    (_, { elementActivity, commentActivity }) => elementActivity || commentActivity,
    getUsers,
    (currentUserId, activity, users) => {
        const missingUserIds = [];

        const userIds = activity.actorIds ? asObject(activity.actorIds) : [];

        const activityUserNames = userIds.map((userId) => {
            const user = users.get(userId);
            if (!user) missingUserIds.push(userId);

            return userId === currentUserId ? 'You' : getShortName(user);
        });

        if (activityUserNames.length > ACTIVITY_TOOLTIP_MAX_NAMES) {
            const leftoverCount = activityUserNames.length - ACTIVITY_TOOLTIP_MAX_NAMES;

            return [
                ...activityUserNames.slice(0, ACTIVITY_TOOLTIP_MAX_NAMES),
                `${leftoverCount} ${chooseSingular(leftoverCount, 'other', 'others')}`,
            ];
        }

        return {
            activityUserNames,
            missingUserIds,
        };
    },
);

const mapStateToProps = createSelector(reactingUserNamesSelector, (reactingUserNamesResult) => reactingUserNamesResult);

const ActivityIndicatorTooltipContent = connect(mapStateToProps)(
    ({ activityUserNames, missingUserIds, elementActivity, commentActivity }) => {
        const activity = elementActivity || commentActivity;

        return (
            <>
                <FetchUsersOnMount missingUserIds={missingUserIds} />
                <span className="ActivityIndicatorTooltipContent">
                    <ActivityIndicatorChangeDescription
                        activityType={activity?.type}
                        activityUserNames={activityUserNames}
                    />{' '}
                    <FriendlyTimestamp
                        timestamp={activity.timestamp}
                        showSuffix
                        lessThanMinuteMessage="a few seconds ago"
                    />
                </span>
            </>
        );
    },
);

const ActivityIndicator = (props) => (
    <div className="ActivityIndicator">
        <TooltipSource
            enabled
            triggerOnMouseEnter
            delay={0}
            tooltipText={<ActivityIndicatorTooltipContent {...props} />}
        >
            <SimpleActivityIndicator />
        </TooltipSource>
    </div>
);

export const SimpleActivityIndicator = (props) => <div className="SimpleActivityIndicator" />;
export const PoiActivityIndicator = elementActivityPoiDecorator(ActivityIndicator);

const PoiActivityIndicatorVisible = (props) => {
    const { toggleVal } = props;

    const [wasToggled, setWasToggled] = React.useState(false);

    useUpdateEffect(() => {
        if (!toggleVal) setWasToggled(true);
    }, [toggleVal]);

    if (wasToggled) return null;

    return <PoiActivityIndicator {...props} />;
};

PoiActivityIndicatorVisible.propTypes = {
    toggleVal: PropTypes.bool,
};

export default PoiActivityIndicatorVisible;
