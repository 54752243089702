// Utils
import registerClone from '../../../common/clone/index';
import component from './Clone';

export default () =>
    registerClone({
        containerComponent: component,
        presentationalComponent: component,
        showTool: false,
    });
