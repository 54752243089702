// Utils
import { createShallowSelector } from '../utils/milanoteReselect/milanoteReselect';
import { getEmail } from '../../common/users/userHelper';

export const getUsers = (state) => state.get('users');

export const getUsersByEmail = createShallowSelector(getUsers, (users) =>
    users.reduce((acc, user) => {
        const email = getEmail(user);
        acc[email] = user;
        return acc;
    }, {}),
);
