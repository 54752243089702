// Lib
import chroma, { Color } from 'chroma-js';
import { isEqual, isString } from 'lodash';
import memoizeOne from 'memoize-one';

// Utils
import { prop } from '../utils/immutableHelper';
import { getColorLightness, getColorSaturation, isColorAccessible } from './coreColorUtil';

// constants
const MIN_BG_COLOR_LIGHTNESS = 0.35;
export const MAX_COLOR_LIGHTNESS = 0.8;
export const MIN_COLOR_LIGHTNESS = 0.2;
export const MAX_PALETTE_SIZE = 18;

type DualColor = {
    primary: Color | string;
    secondary: Color | string;
};

export const isDualColor = (colorItem: DualColor | Color | string): colorItem is DualColor => {
    if (!colorItem || isString(colorItem)) return false;

    // check if object has keys
    if ('primary' in colorItem && 'secondary' in colorItem) return true;

    // check if map has keys
    return prop('primary', colorItem) && prop('secondary', colorItem);
};

/**
 * Logs a color to the console so that you can see the hex string with a background of that color.
 * Helpful for visualising colors as you are developing
 * @param {*} color as a string or a dualColor in the form {primary: `hex string`, secondary: `hex string`}
 */
export const logColor = (color: DualColor | Color): void => {
    if (isDualColor(color)) {
        const primary = prop('primary', color);
        const secondary = prop('secondary', color);
        console.info(
            `%c ${primary} ` + `%c ${secondary} `,
            `background: ${primary}; color: ${chroma(primary).luminance() > 0.5 ? '#000' : '#fff'}`,
            `background: ${secondary}; color: ${chroma(secondary).luminance() > 0.5 ? '#000' : '#fff'}`,
        );
    } else {
        console.info(
            `%c ${color.hex()} `,
            `background: ${color.hex()}; color: ${chroma(color.hex()).luminance() > 0.5 ? '#000' : '#fff'}`,
        );
    }
};
/**
 * Logs an array of colors visually
 */
export const logColorArray = (array: Array<DualColor | Color>, label: string): void => {
    console.info(label ?? 'Color array');
    array.forEach((color) => logColor(color));
    console.info('');
};

const getSimilarity = (primary: Color | string, secondary: Color | string, currentArray: DualColor[]) => {
    let similarityScore = 100;
    currentArray.forEach((existingPair) => {
        const combinedDif =
            chroma.deltaE(existingPair.primary, primary) + chroma.deltaE(existingPair.secondary, secondary);
        similarityScore = Math.min(similarityScore, combinedDif);
    });
    return similarityScore;
};

const isSimilar = (primary: Color | string, secondary: Color | string, currentArray: DualColor[]) =>
    getSimilarity(primary, secondary, currentArray) < 30;

const pairUpSuggestions = (
    baseColor: Color | string,
    colorSuggestionList: Array<Color | string>,
    dualColorArray: DualColor[],
): DualColor | undefined => {
    for (let index = 0; index < colorSuggestionList.length; index++) {
        const color = colorSuggestionList[index];
        if (!isColorAccessible(color, baseColor)) continue;
        const primary = getColorLightness(color) > getColorLightness(baseColor) ? color : baseColor;
        const secondary = getColorLightness(color) > getColorLightness(baseColor) ? baseColor : color;
        if (getColorLightness(primary) < MIN_BG_COLOR_LIGHTNESS || isSimilar(primary, secondary, dualColorArray))
            continue;
        return { primary, secondary };
    }
};

export const generateDualColorList = memoizeOne((colorSuggestions: Array<Color | string>): DualColor[] => {
    // check for suggested colors being too light
    const colorSuggestionList = colorSuggestions.map((color) =>
        getColorLightness(color) > MAX_COLOR_LIGHTNESS ? chroma(color).set('hsl.l', MAX_COLOR_LIGHTNESS).hex() : color,
    );

    const luminances = colorSuggestionList.map((color) => chroma(color).luminance());
    const highestLuminance = Math.max(...luminances);
    const lightest = Math.max(...colorSuggestionList.map((color) => getColorLightness(color)));
    const highestSaturation = Math.max(...colorSuggestionList.map((color) => getColorSaturation(color)));
    const isSaturatedPalette = highestLuminance < 0.3 && highestSaturation > 0.5;

    // Find accessible colors dualColors from original suggestion list
    const dualColorArray: DualColor[] = [];
    colorSuggestionList.forEach((color) => {
        const colorPair = pairUpSuggestions(color, colorSuggestionList, dualColorArray);
        if (colorPair) dualColorArray.push(colorPair);
    });

    // Create adjustment lists
    // Try to match with the best/most similar colors first then get less attractive but easier to match colors later
    const adjustmentsA: string[] = [];
    const adjustmentsB: string[] = [];
    const adjustmentsC: string[] = [];
    colorSuggestionList.forEach((color) => {
        const newSaturation =
            highestSaturation - getColorSaturation(color) < 0.3 || isSaturatedPalette ? highestSaturation : 0.5;
        const saturatedColor = chroma(color).set('hsl.s', newSaturation);
        adjustmentsA.push(
            chroma(saturatedColor).set('hsl.l', Math.min(lightest, MAX_COLOR_LIGHTNESS)).hex(),
            chroma(saturatedColor).set('hsl.l', 0.3).hex(),
        );
        adjustmentsB.push(
            chroma(saturatedColor).set('hsl.l', 0.8).hex(),
            chroma(saturatedColor).set('hsl.l', 0.25).hex(),
        );
        adjustmentsC.push(chroma(saturatedColor).set('hsl.l', 0.15).hex());
    });

    // Make matches
    colorSuggestionList.forEach((color) => {
        const colorPair =
            pairUpSuggestions(color, adjustmentsA, dualColorArray) ??
            pairUpSuggestions(color, adjustmentsB, dualColorArray) ??
            pairUpSuggestions(color, adjustmentsC, dualColorArray);
        if (colorPair) {
            dualColorArray.push(colorPair);
            return;
        }
        // Match remaining with variation of self
        const saturation = getColorSaturation(color);
        // if its a very saturated list then use the highest saturation from that list, else if saturation is greater
        // than 0.5, increase saturation by 0.1 else use the min of highest saturation and 0.5
        const lowerSaturation = saturation > 0.5 ? saturation + 0.1 : Math.min(highestSaturation, 0.5);
        const newSaturation = isSaturatedPalette ? highestSaturation : lowerSaturation;
        let primary = chroma(color).set('hsl.l', 0.75).set('hsl.s', newSaturation).hex();
        let secondary = chroma(color).set('hsl.l', 0.2).set('hsl.s', newSaturation).hex();
        let count = 0;
        // try reducing the saturation to find an accessible match
        while (isSimilar(primary, secondary, dualColorArray || !isColorAccessible(primary, secondary)) && count < 2) {
            const saturation = getColorSaturation(primary) - 0.1;
            primary = chroma(primary).set('hsl.s', saturation).hex();
            secondary = chroma(secondary).set('hsl.s', saturation).hex();
            count += 1;
        }
        if (getSimilarity(primary, secondary, dualColorArray) > 20) dualColorArray.push({ primary, secondary });
    });

    let index = 0;
    const remainingAdjusted = [...adjustmentsA, ...adjustmentsB, ...adjustmentsC];
    // to try to reach the minimum 7 dualColors, try matching up all of the adjusted colors with each other
    // this will be easier because they are quite light and dark, but will look a bit more different from the original suggestions
    while (dualColorArray.length < 7 && index <= 100) {
        const color = remainingAdjusted[index];
        const colorPair = pairUpSuggestions(color, remainingAdjusted, dualColorArray);
        if (colorPair && !isSimilar(colorPair.primary, colorPair.secondary, dualColorArray)) {
            remainingAdjusted.splice(remainingAdjusted.indexOf(color), 1);
            dualColorArray.push(colorPair);
        }
        index += 1;
        // add more to the end of the array
        remainingAdjusted.push(
            chroma(color)
                .set('hsl.s', getColorSaturation(color) - 0.1)
                .hex(),
        );
    }
    return dualColorArray;
}, isEqual);
