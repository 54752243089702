import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAlignBottomIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-align-bottom"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M8 23.7h16a.75.75 0 0 0 0-1.5H8a.75.75 0 1 0 0 1.5zm11.46-7.5a.5.5 0 0 0-.5-.5h-2.21V8.752a.752.752 0 0 0-1.497-.102l-.007.102.004 6.948h-2.21a.5.5 0 0 0-.242.062l-.07.047a.5.5 0 0 0-.078.703l2.96 3.7a.5.5 0 0 0 .78 0l2.96-3.7a.5.5 0 0 0 .11-.312z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAlignBottomIcon);
export default Memo;
