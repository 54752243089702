// Lib
import * as Immutable from 'immutable';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';
import {
    getNotificationId,
    getNotificationObservedTimestamp,
    getNotificationViewedTimestamp,
} from '../../../common/notifications/notificationModelUtils';
import mergeNotificationsData from './mergeNotificationsData';

// Constants
import { ResourceTypes, RESOURCES_FETCHED } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import {
    NOTIFICATIONS_ADD_OR_UPDATE,
    NOTIFICATIONS_REMOVE,
    NOTIFICATIONS_MARK_ALL_VIEWED,
    NOTIFICATIONS_MARK_VIEWED,
    NOTIFICATIONS_MARK_OBSERVED,
    NOTIFICATIONS_MARK_ELEMENTS_VIEWED,
} from '../../../common/notifications/notificationConstants';

const initialState = Immutable.Map();

const handleMarkElementsAsSeen = (state, action) => {
    const { views = [], commentViews = [], timestamp = getTimestamp() } = action;

    return state.withMutations((mutableState) => {
        views.forEach(({ notificationId, elementId }) => {
            mutableState.setIn([notificationId, 'details', 'elementChanges', elementId, 'seen'], timestamp);
        });

        commentViews.forEach(({ notificationId, commentId }) => {
            mutableState.setIn([notificationId, 'details', 'commentChanges', commentId, 'seen'], timestamp);
        });
    });
};

const handleMarkAllNotificationsAsSeen = (state, action) => {
    const { viewedTimestamp } = action;

    return state.withMutations((mutableState) => {
        const unseenNotificationIds = mutableState
            .filter((notification) => !getNotificationViewedTimestamp(notification))
            .map(getNotificationId);

        unseenNotificationIds.forEach((notificationId) =>
            mutableState.setIn([notificationId, 'viewedTimestamp'], viewedTimestamp),
        );
    });
};

const handleMarkNotificationsAsSeen = (state, action) => {
    const { notificationIds = [], viewedTimestamp } = action;

    return state.withMutations((mutableState) => {
        notificationIds.forEach((notificationId) => {
            mutableState.update(notificationId, (notification) =>
                notification && !getNotificationViewedTimestamp(notification)
                    ? notification.set('viewedTimestamp', viewedTimestamp)
                    : notification,
            );
        });
    });
};

const handleMarkNotificationsAsObserved = (state, action) => {
    const { notificationIds = [], observedTimestamp } = action;

    return state.withMutations((mutableState) => {
        notificationIds.forEach((notificationId) => {
            mutableState.update(notificationId, (notification) =>
                notification && !getNotificationObservedTimestamp(notification)
                    ? notification.set('observedTimestamp', observedTimestamp)
                    : notification,
            );
        });
    });
};

const handleRemoveMultiple = (state, action) =>
    state.withMutations((mutableState) => {
        const { notificationIds } = action;

        if (!notificationIds) return;

        notificationIds.forEach((notificationId) => {
            mutableState.delete(notificationId);
        });
    });

const handleNotificationsResourceFetched = (state, action) => {
    if (action.resource !== ResourceTypes.notifications) return state;

    return mergeNotificationsData(state, action.data);
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESOURCES_FETCHED:
            return handleNotificationsResourceFetched(state, action);
        case NOTIFICATIONS_ADD_OR_UPDATE:
            return mergeNotificationsData(state, action);
        case NOTIFICATIONS_REMOVE:
            return handleRemoveMultiple(state, action);
        case NOTIFICATIONS_MARK_ELEMENTS_VIEWED:
            return handleMarkElementsAsSeen(state, action);
        case NOTIFICATIONS_MARK_VIEWED:
            return handleMarkNotificationsAsSeen(state, action);
        case NOTIFICATIONS_MARK_ALL_VIEWED:
            return handleMarkAllNotificationsAsSeen(state, action);
        case NOTIFICATIONS_MARK_OBSERVED:
            return handleMarkNotificationsAsObserved(state, action);
        default:
            return state;
    }
};
