// Lib
import { createSelector } from 'reselect';
import { difference } from 'lodash/fp';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import { createShallowSelector } from '../milanoteReselect/milanoteReselect';
import { getPhysicalAncestors } from '../../../common/elements/utils/elementTraversalUtils';
import {
    getElementId,
    getIsPublicEditEnabled,
    getIsPublished,
} from '../../../common/elements/utils/elementPropertyUtils';

// Selector
import { getElements } from '../../element/selectors/elementsSelector';
import { getElementAncestorIdsSelector } from '../../element/selectors/elementTraversalSelector';
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getUrlPermissionId } from '../../app/routingSelector';

// Selectors
import { getCurrentUserId, isGuestSelector } from '../../user/currentUserSelector';

// Constants
import { PUBLIC_USER_ID } from '../../../common/users/userConstants';

export const getTokenPermissionIdsSelector = (state) => state.getIn(['permissions', 'permissionIds']);
export const getTokenAclIdsSelector = (state) => state.getIn(['permissions', 'aclIds']);
export const getBoardIdToPermissionIdMapSelector = (state) => state.getIn(['permissions', 'boardIdPermissionIdMap']);
export const getBoardIdEditPermissionIdMapSelector = (state) =>
    state.getIn(['permissions', 'boardIdEditPermissionIdMap']);
export const getBoardIdPublishPermissionIdMapSelector = (state) =>
    state.getIn(['permissions', 'boardIdPublishPermissionIdMap']);
export const getPermissionIdToTokenIndexMapSelector = (state) =>
    state.getIn(['permissions', 'permissionIdTokenIndexMap']);

export const getTokensSelector = (state) => state.getIn(['permissions', 'tokens']);

/**
 * Gets the ACL IDs applicable for the current user & saved tokens.
 */
export const getAclIdsSelector = createShallowSelector(
    isGuestSelector,
    getCurrentUserId,
    getTokenAclIdsSelector,
    (isGuest, currentUserId, aclIds) =>
        isGuest ? [...aclIds.toArray(), PUBLIC_USER_ID] : [currentUserId, ...aclIds.toArray(), PUBLIC_USER_ID],
);

/**
 * Gets the token for a given permission ID.
 */
export const getPermissionIdTokenSelector = (state, { permissionId }) => {
    const tokenIndex = state.getIn(['permissions', 'permissionIdTokenIndexMap', permissionId]);
    return state.getIn(['permissions', 'tokens', tokenIndex]);
};

export const getMultiplePermissionIdTokensSelector = (state, { permissionIds }) =>
    permissionIds.map((permissionId) => getPermissionIdTokenSelector(state, { permissionId }));

const getClosestPermissionId = ({ ancestorIds, boardIdPermissionIdMap }) => {
    if (!ancestorIds || !boardIdPermissionIdMap) return null;

    for (const ancestorId of ancestorIds) {
        const permissionId = prop(ancestorId, boardIdPermissionIdMap);

        if (permissionId) return permissionId;
    }

    return null;
};

/**
 * Using this in an attempt to centralise the management / retrieval of permission IDs for each board ID.
 * If a centralised system for tracking board IDs and their required permission ID won't work, then the
 * consumers of "fetchBoard" and "fetchElements" would need to determine and pass in the permission IDs
 * required on invocation.
 * This would affect far more locations in code.
 */

export const getClosestPermissionIdForElementIdSelector = () =>
    createSelector(
        (_, { elementId }) => elementId,
        getCurrentBoardIdFromState,
        getUrlPermissionId,
        getBoardIdToPermissionIdMapSelector,
        getElementAncestorIdsSelector(),
        (elementId, currentBoardId, urlPermissionId, boardIdPermissionIdMap, ancestorIds) => {
            if (urlPermissionId && currentBoardId === elementId) return urlPermissionId;

            // If current board exists within permissions map, we don't need to get ancestors
            if (boardIdPermissionIdMap && boardIdPermissionIdMap.get(elementId)) {
                return boardIdPermissionIdMap.get(elementId);
            }

            if (urlPermissionId && ancestorIds && ancestorIds.includes(currentBoardId)) return urlPermissionId;

            return getClosestPermissionId({ ancestorIds, boardIdPermissionIdMap });
        },
    );

export const getClosestPermissionIdsSelector = (state, { elementIds }) =>
    elementIds.map((elementId) => getClosestPermissionIdForElementIdSelector()(state, { elementId }));

export const getMissingPermissionIdTokensSelector = (state, { permissionIds }) => {
    const tokenPermissionIds = getTokenPermissionIdsSelector(state).toArray();
    return difference(permissionIds, tokenPermissionIds);
};

/*
-------------------------------------
--- Public URL Building Selectors ---
-------------------------------------
*/
// FIXME This selector isn't initialised correctly - it should use a per instance selector style `() => createSelector`
//  because it relies on the elementId. However at this time the selector is only used for the current board ID
//  so it will be safely memoising correctly.
//  NOTE: If we upgrade reselect to the version that uses WeakMaps (v5+) then we won't need to worry about
//   changing this func
export const getEditPermissionIdForElementIdSelector = createSelector(
    getElements,
    getBoardIdEditPermissionIdMapSelector,
    (state, ownProps) => ownProps.elementId,
    (elements, boardIdPermissionIdMap, elementId) => {
        const ancestors = getPhysicalAncestors(elements, elementId);

        // We can only use the ancestors that are publicly editable
        const ancestorIds = ancestors.filter(getIsPublicEditEnabled).map(getElementId);

        return getClosestPermissionId({ ancestorIds, boardIdPermissionIdMap });
    },
);

// FIXME - Same comment as getEditPermissionIdForElementIdSelector
export const getPublishPermissionIdForElementIdSelector = createSelector(
    getElements,
    getBoardIdPublishPermissionIdMapSelector,
    (state, ownProps) => ownProps.elementId,
    (elements, boardIdPermissionIdMap, elementId) => {
        const ancestors = getPhysicalAncestors(elements, elementId);

        // We can only use the ancestors that are actually published
        const ancestorIds = ancestors.filter(getIsPublished).map(getElementId);

        return getClosestPermissionId({ ancestorIds, boardIdPermissionIdMap });
    },
);

/**
 * This is almost the same as getPublishPermissionIdForElementIdSelector, however
 * the board being previewed might not be published, so we shouldn't filter out boards
 * that aren't published.
 */
// FIXME - Same comment as getEditPermissionIdForElementIdSelector
export const getPublishPreviewPermissionIdForElementIdSelector = createSelector(
    getElements,
    getBoardIdPublishPermissionIdMapSelector,
    (state, ownProps) => ownProps.elementId,
    (elements, boardIdPermissionIdMap, elementId) => {
        const ancestors = getPhysicalAncestors(elements, elementId);
        const ancestorIds = ancestors.map(getElementId);
        return getClosestPermissionId({ ancestorIds, boardIdPermissionIdMap });
    },
);
