// Utils
import { registerElementType } from '../../elements/elementRegistry';
import elementConstructor from '../../elements/elementConstructor';

// Constants
import { ANNOTATION_DISPLAY_NAME } from './annotationConstants';
import { ACCESS_BITS } from '../../permissions/permissionConstants';
import { ElementType } from '../../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.ANNOTATION_TYPE,
        content: {
            transparent: {
                enabled: true,
            },
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.ANNOTATION_TYPE,
        objectConstructorFn,
        displayName: ANNOTATION_DISPLAY_NAME,
        editPermission: ACCESS_BITS.FEEDBACK,
        savePermission: ACCESS_BITS.FEEDBACK,
        ...params,
    });
