import { useState, useEffect, useRef } from 'react';
import { getTimestamp } from '../../../common/utils/timeUtil';

/**
 *
 */
export default function useStalledBoolean(bool, minActiveTime = 1000) {
    // State and setters for debounced value
    const [stalledBool, setStalledBool] = useState(bool);

    const activeTimestamp = useRef(0);

    useEffect(() => {
        let handler;

        if (bool) {
            activeTimestamp.current = getTimestamp();
            setStalledBool(bool);
        } else {
            const currentTime = getTimestamp();
            let delay = activeTimestamp.current + minActiveTime - currentTime;
            delay = delay > 0 ? delay : 0;

            handler = setTimeout(() => {
                setStalledBool && setStalledBool(bool);
            }, delay);
        }

        // Clear the timeout on the next change
        return () => clearTimeout(handler);
    }, [bool]);

    return stalledBool;
}
