// Utils
import * as markDownPatterns from './markDownPatterns';

const matchPattern = (pattern) => (text) => text.match(pattern);

// Block styles
export const matchLargeHeading = matchPattern(markDownPatterns.LARGE_HEADING);
export const matchHeading = matchPattern(markDownPatterns.HEADING);
export const matchOrderedList = matchPattern(markDownPatterns.ORDERED_LIST);
export const matchList = matchPattern(markDownPatterns.LIST);
export const matchBlockquote = matchPattern(markDownPatterns.BLOCKQUOTE);

// This will only return the first match even if there are multiple
const matchPatterns = (patterns) => (text) =>
    patterns.reduce((currMatch, pattern) => currMatch || pattern.exec(text), null);

// Inline styles
export const matchBold = matchPatterns(markDownPatterns.BOLD);
export const matchItalic = matchPatterns(markDownPatterns.ITALIC);
export const matchCode = matchPatterns(markDownPatterns.CODE);
export const matchStrikethrough = matchPatterns(markDownPatterns.STRIKETHROUGH);
export const matchHyperlink = matchPatterns(markDownPatterns.HYPERLINK);

export const isTerminatingMarkdownCharacter = (char) =>
    markDownPatterns.TERMINATING_MARKDOWN_CHARS.indexOf(char) !== -1;
