import createMediaHttpService from './createMediaHttpService';
import createExportApi from './api/createExportApi';
import createImageApi from './api/createImageApi';
import createLinkApi from './api/createLinkApi';
import createPreviewApi from './api/createPreviewApi';

export default (apiUrl, jwtToken) => {
    const mediaHttp = createMediaHttpService(apiUrl, jwtToken);

    return {
        export: createExportApi(mediaHttp),
        image: createImageApi(mediaHttp),
        link: createLinkApi(mediaHttp),
        preview: createPreviewApi(mediaHttp),
    };
};
