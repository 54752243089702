import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAutoplayIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-autoplay"
        {...props}
    >
        <g fill="none" fillRule="nonzero">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M14 13.375c0-.289.299-.47.537-.323l4.286 2.624a.385.385 0 0 1 0 .648l-4.286 2.624c-.238.146-.537-.034-.537-.323v-5.25Z"
            />
            <path
                fill="#48505E"
                d="M16 7a.75.75 0 1 1 0 1.5 7.5 7.5 0 1 0 5.32 2.214.75.75 0 0 1 1.064-1.058A9 9 0 1 1 16 7Z"
            />
            <path fill="#48505E" d="m18.5 7.75-3-2.5v5z" />
        </g>
    </svg>
);
const Memo = memo(ToolbarAutoplayIcon);
export default Memo;
