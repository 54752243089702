// Utils
import { addMargins } from '../../../common/maths/geometry/rect';
import { getScrollForTarget } from '../dom/scrollableElementUtils';

export const animateScroll = ({
    scrollableElement,
    fromScrollLeft,
    toScrollLeft,
    fromScrollTop,
    toScrollTop,
    interpolationFactor,
}) => {
    let animationId = null;

    const cancel = () => {
        if (animationId) return cancelAnimationFrame(animationId);
    };

    const step = (originX, destX, originY, destY) => {
        const distX = destX - originX;
        const distY = destY - originY;

        // Terminal case, when both distances are less than a pixel we've reached our last animation frame
        if (Math.abs(distX) < 1 && Math.abs(distY) < 1) {
            scrollableElement.scrollLeft = destX;
            scrollableElement.scrollTop = destY;
            return;
        }

        // This will perform a scroll over a duration that is relative to the distance it needs to go.
        // This is better than a constant duration otherwise small distances would move very slowly
        const deltaX = interpolationFactor * distX;
        const deltaY = interpolationFactor * distY;

        const nextX = originX + deltaX;
        const nextY = originY + deltaY;

        scrollableElement.scrollTop = nextY;
        scrollableElement.scrollLeft = nextX;

        animationId = requestAnimationFrame(() => step(nextX, destX, nextY, destY));
    };

    animationId = requestAnimationFrame(() => step(fromScrollLeft, toScrollLeft, fromScrollTop, toScrollTop));

    return cancel;
};

/**
 * Scrolls a target rectangle into view using an interpolation type animation (like an ease-out).
 * The interpolation factor can be provided to speed up or slow down the scroll animation.
 */
const animateScrollIntoView = ({
    scrollableElement,
    targetRect,
    margins = {},
    viewportOptions = {},
    interpolationFactor = 0.3,
}) => {
    margins.top = margins.top || 0;
    margins.right = margins.right || 0;
    margins.bottom = margins.bottom || 0;
    margins.left = margins.left || 0;

    const targetRectWithMargin = addMargins(margins, targetRect);

    // Most important to get top left into view
    const { scrollLeft, scrollTop } = getScrollForTarget({
        scrollableElement,
        targetRect: targetRectWithMargin,
        viewportOptions,
    });

    return animateScroll({
        scrollableElement,
        // Starting point
        fromScrollLeft: scrollableElement.scrollLeft,
        fromScrollTop: scrollableElement.scrollTop,
        toScrollLeft: scrollLeft,
        toScrollTop: scrollTop,
        interpolationFactor,
    });
};

export default animateScrollIntoView;
