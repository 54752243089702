import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import randomString from '../../../common/uid/randomString';
import { noLonger } from '../../utils/react/propsComparisons';
import { showTooltip, hideTooltip } from './tooltipActions';
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import { TooltipPositions } from './tooltipConstants';

// Styles
import './Tooltip.scss';

const mapStateToProps = (state) => ({
    tooltipActive: !!state.getIn(['app', 'tooltip', 'content']),
    tooltipActiveTimestamp: state.getIn(['app', 'tooltip', 'activeTimestamp']),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenTooltip: ({ tooltip, fade }) => dispatch(showTooltip({ tooltip, fade })),
    dispatchHideTooltip: () => dispatch(hideTooltip()),
});

const noLongerEnabled = noLonger('enabled');

class TooltipSource extends Component {
    componentWillMount() {
        this.setState({ tooltipId: `tip-${randomString(6)}` });
    }

    componentWillReceiveProps(nextProps) {
        if (noLongerEnabled(this.props, nextProps) && nextProps.tooltipActive) this.hideTooltip();
    }

    showToolTip = (event) => {
        const {
            enabled,
            tooltipActive,
            tooltipActiveTimestamp,
            tooltipText,
            shortcut,
            position,
            distance,
            offset,
            arrowOffset,
            className,
            duration,
            delay,
            fade,
            getTargetRectFn,
            pollPosition,
        } = this.props;

        if (!enabled) return;

        // Ensure the delay has passed before considering a tooltip active
        const alreadyActive = tooltipActive && getTimestamp() >= tooltipActiveTimestamp;

        this.props.dispatchOpenTooltip({
            fade,
            tooltip: {
                selector: `#${this.state.tooltipId}`,
                text: tooltipText,
                shortcut,
                position: position || TooltipPositions.BOTTOM,
                distance,
                offset,
                duration,
                arrowOffset,
                className: `Tooltip small ${className}`,
                // Only delay the next tooltip if a tooltip isn't already active
                delay: alreadyActive || !delay ? 0 : delay,
                targetRect: getTargetRectFn && getTargetRectFn(),
                pollPosition,
            },
        });
    };

    hideTooltip = () => {
        const { dispatchHideTooltip, tooltipActive } = this.props;

        tooltipActive && dispatchHideTooltip();
    };

    render() {
        const {
            children,
            className,
            triggerOnMouseEnter,
            keepOnMouseLeave,
            triggerOnClick,
            triggerOnDrag,
            tooltipActive,
        } = this.props;
        const { tooltipId } = this.state;

        return (
            <div
                className={classNames('TooltipSource', className)}
                id={tooltipId}
                draggable={true}
                onClick={triggerOnClick && this.showToolTip}
                // Hides the tooltip when attempting a drag on the iPad when using the magic trackpad
                onMouseDown={tooltipActive ? this.hideTooltip : null}
                onMouseEnter={triggerOnMouseEnter && this.showToolTip}
                onMouseLeave={triggerOnMouseEnter && !keepOnMouseLeave ? this.hideTooltip : null}
                onScroll={this.hideTooltip}
                onDragStart={triggerOnDrag ? this.showToolTip : this.hideTooltip}
            >
                {children}
            </div>
        );
    }
}

TooltipSource.propTypes = {
    tooltipId: PropTypes.string,
    enabled: PropTypes.bool,
    className: PropTypes.string,
    tooltipText: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    shortcut: PropTypes.array,
    position: PropTypes.string,
    delay: PropTypes.number,
    distance: PropTypes.number,
    offset: PropTypes.number,
    duration: PropTypes.number,
    arrowOffset: PropTypes.number,
    fade: PropTypes.bool,
    tooltipActive: PropTypes.bool,
    tooltipActiveTimestamp: PropTypes.number,
    triggerOnMouseEnter: PropTypes.bool,
    keepOnMouseLeave: PropTypes.bool,
    triggerOnClick: PropTypes.bool,
    triggerOnDrag: PropTypes.bool,
    pollPosition: PropTypes.bool,
    children: PropTypes.node,
    getTargetRectFn: PropTypes.func,
    dispatchOpenTooltip: PropTypes.func,
    dispatchHideTooltip: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TooltipSource);
