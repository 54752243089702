// Lib
import { negate, uniq, flatten, intersection, isEmpty, first } from 'lodash';
import * as Immutable from 'immutable';

// Actions
import { createElementSync, updateElement } from './elementActions';
import { moveMultipleElements } from './elementMoveActions';
import { deselectAllElements } from '../selection/selectionActions';
import { convertElementToClone } from '../../../common/elements/elementActions';
import { duplicateMultipleElements } from '../duplicate/elementDuplicateActions';

// Selectors
import { getElements } from '../selectors/elementSelector';
import { elementGraphSelector } from '../selectors/elementGraphSelector';
import { getDuplicateOriginalIdSelector } from '../duplicate/elementDuplicateSelector';
import { filteredTrashElementIdsSelector } from '../../workspace/toolbar/trash/store/trashSelector';
import { currentBoardUserPermissionsSelector } from '../../utils/permissions/elementPermissionsSelector';
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getMeasurementsMapThunk } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Utils
import logger from '../../logger/logger';
import { asObject, length, toArray } from '../../../common/utils/immutableHelper';
import { getTimestamp } from '../../../common/utils/timeUtil';
import {
    getDisplayMode,
    getElementId,
    getElementType,
    isElementLocked,
} from '../../../common/elements/utils/elementPropertyUtils';
import {
    getDeleteLocation,
    getTrashLocation,
    isLocationCanvas,
    isLocationInbox,
} from '../../../common/elements/utils/elementLocationUtils';
import { canvasElementSorter } from '../../../common/elements/utils/elementSortUtils';
import { analyticsEvent } from '../../analytics';
import { getSelectedElements } from '../selection/selectedElementsSelector';
import { canBeCloned, isClone } from '../../../common/elements/utils/elementTypeUtils';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getAllOverlappingElements, getOverlappingElements } from './elementMoveActionUtil';
import { validateElementTypeEditPermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { getChildrenViaGraph } from '../../../common/elements/utils/elementGraphUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';

// Analytics
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';

// Constants
import { PASTE_OFFSET_X, PASTE_OFFSET_Y } from '../../workspace/shortcuts/clipboard/clipboardConstants';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';
import { ElementType } from '../../../common/elements/elementTypes';

export const moveElementsToTrash = (props) => (dispatch, getState) => {
    const {
        keepSelection = false,
        elements,
        elementId,
        elementIds,
        currentBoardId,
        transactionId = getNewTransactionId(),
    } = props;

    if (!currentBoardId) {
        console.warn('Trying to delete elements without supplying a currentBoardId');
        return;
    }

    const state = getState();
    const permissions = currentBoardUserPermissionsSelector(state);

    let elementsToDelete = elements;

    if (!elementsToDelete && elementIds) {
        elementsToDelete = Immutable.List(elementIds).map((id) => state.getIn(['elements', id]));
    }

    if (!elementsToDelete && elementId) {
        elementsToDelete = Immutable.List([state.getIn(['elements', elementId])]);
    }

    if (!elementsToDelete) {
        console.warn('Trying to delete elements without supplying any IDs');
        return;
    }

    elementsToDelete = elementsToDelete
        .filter(validateElementTypeEditPermission(permissions))
        .filter(negate(isElementLocked));

    if (!length(elementsToDelete)) return;

    const trashedElementIds = filteredTrashElementIdsSelector(state);
    const elementIdsToDelete = toArray(elementsToDelete.map(getElementId));

    const attemptingToDeleteTrashedElements = intersection(trashedElementIds, elementIdsToDelete).length > 0;

    if (attemptingToDeleteTrashedElements) {
        console.warn('Attempting to delete elements trashed elements');
        return;
    }

    !keepSelection && dispatch(deselectAllElements({ transactionId }));

    analyticsEvent('moved-element-to-trash-delete-key');

    const addedDate = getTimestamp();
    const moves = asObject(
        elementsToDelete.map((element) => ({
            id: getElementId(element),
            location: getTrashLocation({ currentBoardId, addedDate }),
        })),
    );
    dispatch(moveMultipleElements({ moves, transactionId, moveOperation: ELEMENT_MOVE_OPERATIONS.TRASH }));

    return transactionId;
};

/**
 * Instead of sending the elements to the "TRASH" section, this will send them to the "DELETED" section.
 * Elements that are "DELETED" won't be shown in the trash and they should also be able to be permanently
 * deleted.
 */
export const immediatelyDeleteElements = (props) => (dispatch, getState) => {
    const { elementIds, currentBoardId, transactionId = getNewTransactionId() } = props;

    if (isEmpty(elementIds)) {
        console.warn('Trying to delete elements without supplying any IDs');
        return;
    }

    const state = getState();
    const elements = getElements(state);
    const permissions = currentBoardUserPermissionsSelector(state);

    const elementsToDelete = Immutable.List(elementIds)
        .map((id) => getElement(elements, id))
        .filter(validateElementTypeEditPermission(permissions))
        .filter(negate(isElementLocked));

    if (!elementsToDelete.size) return;

    const deletedDate = getTimestamp();
    const moves = elementsToDelete
        .map((element) => ({
            id: getElementId(element),
            location: getDeleteLocation({ currentBoardId, deletedDate }),
        }))
        .toJS();
    dispatch(moveMultipleElements({ moves, transactionId, moveOperation: ELEMENT_MOVE_OPERATIONS.PERMANENT_DELETE }));

    return transactionId;
};

const getNewElementLocation = (element) => ({
    ...element.location,
    position: isLocationInbox(element)
        ? {
              ...element.location.position,
              order: element.location.order + 1,
          }
        : {
              ...element.location.position,
              x: element.location.position.x + PASTE_OFFSET_X,
              y: element.location.position.y + PASTE_OFFSET_Y,
          },
});

export const duplicateElements =
    ({ elements, shouldConvertAliasToBoard = false }) =>
    (dispatch, getState) => {
        const duplications = elements.map((element) => ({
            ...element,
            location: getNewElementLocation(element),
        }));

        dispatch(
            duplicateMultipleElements({
                duplications,
                shouldSelect: true,
                shouldConvertAliasToBoard,
            }),
        );
    };

export const duplicateSelectedElements =
    ({ shouldConvertAliasToBoard = false } = {}) =>
    (dispatch, getState) => {
        const selectedElements = getSelectedElements(getState()).toJS();

        dispatch(duplicateElements({ elements: selectedElements, shouldConvertAliasToBoard }));
    };

/**
 * Converts a duplicated element into a clone of its original.
 * This relies on the "elementDuplicateReducer" to keep track of the original element
 * of a duplicate.
 */
export const convertDuplicateToClone =
    (element, transactionId = getNewTransactionId()) =>
    (dispatch, getState) => {
        if (!element || isClone(element)) return;

        if (!canBeCloned(element)) return;

        const state = getState();
        const elementId = getElementId(element);

        const originalElementId = getDuplicateOriginalIdSelector(state, { elementId });

        if (!originalElementId) return;

        const elements = getElements(state);
        const originalElement = getElement(elements, originalElementId);

        if (!originalElement) return;

        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.ELEMENT_CLONE,
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.CLONED_CARD]: true },
            },
        });

        dispatch(
            convertElementToClone({
                id: elementId,
                originalElementId,
                clonedElementType: getElementType(originalElement),
                transactionId,
            }),
        );
    };

export const cloneElement = (element) => (dispatch, getState) => {
    if (isClone(element)) return dispatch(duplicateSelectedElements());

    const transactionId = getNewTransactionId();

    const originalElementId = getElementId(element);

    sendAmplitudeEvent({
        eventType: EVENT_TYPE_NAMES.ELEMENT_CLONE,
        userProperties: {
            [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.CLONED_CARD]: true },
        },
    });

    dispatch(
        updateElement({
            id: originalElementId,
            changes: {
                hasClones: true,
            },
            transactionId,
        }),
    );

    dispatch(
        createElementSync({
            elementType: ElementType.CLONE_TYPE,
            location: getNewElementLocation(element),
            content: {
                linkTo: getElementId(element),
                clonedElementType: getElementType(element),
                displayMode: getDisplayMode(element),
            },
            duplicate: true,
            duplicatedId: originalElementId,
            transactionId,
        }),
    );
};

export const cloneSelectedElement = () => (dispatch, getState) => {
    const selectedElements = getSelectedElements(getState()).toJS();

    if (length(selectedElements) !== 1) {
        logger.warn('Unable to clone multiple elements at the same time', selectedElements);
    }

    const element = first(selectedElements);

    return dispatch(cloneElement(element));
};

const getHighestScoreElement = (elements) =>
    elements.reduce((highestScoreElement, element) => {
        if (!highestScoreElement) return element;
        if (element.location.position.score > highestScoreElement.location.position.score) return element;
        return highestScoreElement;
    }, undefined);

const getLowestScoreElement = (elements) =>
    elements.reduce((lowestScoreElement, element) => {
        if (!lowestScoreElement) return element;
        if (element.location.position.score < lowestScoreElement.location.position.score) return element;
        return lowestScoreElement;
    }, undefined);

const existsInGroup = (selectedElement, elementsGroup) => {
    const elementIds = flatten(elementsGroup).map((el) => el.id);
    return elementIds.indexOf(selectedElement.id) !== -1;
};

export const groupElementsByOverlaps = (selectedElements, measurements) => {
    const selectedElementGroups = [];
    selectedElements.forEach((selectedElement) => {
        const overlappingElements = getOverlappingElements(selectedElement, selectedElements, measurements);

        if (!existsInGroup(selectedElement, selectedElementGroups)) {
            return selectedElementGroups.push(overlappingElements);
        }

        selectedElementGroups.forEach((selectedElementGroup, index) => {
            const groupedElementIds = selectedElementGroup.map((el) => el.id);
            if (groupedElementIds.indexOf(selectedElement.id) === -1) return;

            selectedElementGroups[index] = uniq(selectedElementGroup.concat(overlappingElements));
        });
    });
    return selectedElementGroups;
};

const allOnTop = (selectedElementGroup, sortedElementIds) =>
    selectedElementGroup.every(
        (el) => sortedElementIds.indexOf(el.id) > sortedElementIds.length - selectedElementGroup.length - 1,
    );

const allAtBottom = (selectedElementGroup, sortedElementIds) =>
    selectedElementGroup.every((el) => sortedElementIds.indexOf(el.id) < selectedElementGroup.length);

const getMultiElementOrderMoves = (
    increaseScore,
    selectedElementGroup,
    selectedElementGroupIndex,
    currentBoardElements,
    measurements,
) => {
    const targetElement = increaseScore
        ? getHighestScoreElement(selectedElementGroup)
        : getLowestScoreElement(selectedElementGroup);

    const filteredElements = currentBoardElements.filter(isLocationCanvas);
    const allOverlappingElements = uniq(
        getAllOverlappingElements(selectedElementGroup, filteredElements, measurements),
    );
    const sortedElementIds = allOverlappingElements.sort(canvasElementSorter).map(getElementId);
    const targetElementIndex = sortedElementIds.indexOf(targetElement.id);

    // If we're not overlapping any unselected elements then changing the order won't do anything
    if (allOverlappingElements.length === selectedElementGroup.length) return;

    // If we're increasing the score and we're already on top, then don't do anything
    if (increaseScore && allOnTop(selectedElementGroup, sortedElementIds)) return;

    // If we're decreasing the score and we're already on the bottom then don't do anything
    if (!increaseScore && allAtBottom(selectedElementGroup, sortedElementIds)) return;

    const index = increaseScore ? targetElementIndex + 2 : targetElementIndex - 1;

    return selectedElementGroup.sort(canvasElementSorter).reduce((movesAcc, selectedElement) => {
        movesAcc.push({
            id: selectedElement.id,
            location: {
                ...selectedElement.location,
                position: {
                    ...selectedElement.location.position,
                    index,
                    score: undefined,
                    group: selectedElementGroupIndex,
                },
            },
        });

        return movesAcc;
    }, []);
};

export const changeElementOrder =
    ({ increaseScore = false }, { measurementsDispatch }) =>
    (dispatch, getState) => {
        const measurements = measurementsDispatch(getMeasurementsMapThunk());

        const state = getState();
        const currentBoardId = getCurrentBoardIdFromState(state);
        const selectedElements = getSelectedElements(state).toJS();
        const elements = getElements(state);
        const elementGraph = elementGraphSelector(state);
        const currentBoardElements = getChildrenViaGraph({ elements, elementGraph, parentId: currentBoardId })
            .valueSeq()
            .toJS();

        if (!selectedElements.length) return;

        if (selectedElements.every(isLocationInbox)) return;

        const selectedElementGroups = groupElementsByOverlaps(selectedElements, measurements);

        const moves = selectedElementGroups.reduce((movesAcc, selectedElementGroup, selectedElementGroupIndex) => {
            const multiMoves = getMultiElementOrderMoves(
                increaseScore,
                selectedElementGroup,
                selectedElementGroupIndex,
                currentBoardElements,
                measurements,
            );

            if (!multiMoves) return movesAcc;

            return movesAcc.concat(multiMoves);
        }, []);

        dispatch(moveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.CHANGE_ORDER }));
    };
