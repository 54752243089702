// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Singletons
import dragAndDropStateSingleton from '../dnd/dragAndDropStateSingleton';

// Utils
import * as pointLib from '../../../common/maths/geometry/point';
import { getTransformOrigin, getTranslationStyles } from '../../utils/cssUtil';
import { getDefaultElementOffset } from '../../utils/dnd/dragPositionUtils';

// Selectors
import { isAttachModeSelector } from '../../reducers/draggingSelector';

// Hooks
import useUpdateLayoutEffect from '../../utils/react/useUpdateLayoutEffect';

// Components
import CommentThreadCollapsed from './collapsed/CommentThreadCollapsed';
import CommentThreadExpanded from './CommentThreadExpanded';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import './CommentThreadDragPreview.scss';

const CommentThreadDragPreviewTypeSwitch = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { showCollapsed, translation, handleMode, isAttachMode, ...rest } = props;
    const { elementId } = props;

    const initialCollapsed = React.useRef(showCollapsed);
    const [collapsedTranslationStyle, setCollapsedTranslationStyle] = React.useState(
        showCollapsed ? getTranslationStyles(translation) : null,
    );

    const [expandedTranslationStyle, setExpandedTranslationStyle] = React.useState(
        !showCollapsed ? getTranslationStyles(translation) : null,
    );
    const [expandedTransformOrigin] = React.useState(getTransformOrigin(dragAndDropStateSingleton.scaledGrabOffset));

    React.useEffect(() => {
        // No need to update
        if (!translation || initialCollapsed.current === showCollapsed) return;

        showCollapsed
            ? setCollapsedTranslationStyle(getTranslationStyles(translation))
            : setExpandedTranslationStyle(getTranslationStyles(translation));
    }, [showCollapsed, translation]);

    return (
        <div className={showCollapsed ? 'collapsed-thread' : 'expanded-thread'}>
            <div className="CommentThreadDragPreviewTypeSwitch collapsed">
                <CommentThreadCollapsed
                    {...rest}
                    threadId={elementId}
                    handleMode={handleMode}
                    style={collapsedTranslationStyle}
                />
            </div>
            <div className="CommentThreadDragPreviewTypeSwitch expanded" style={expandedTransformOrigin}>
                <CommentThreadExpanded {...rest} style={expandedTranslationStyle} />
            </div>
        </div>
    );
};

CommentThreadDragPreviewTypeSwitch.propTypes = {
    elementId: PropTypes.string,
    showCollapsed: PropTypes.bool,
    translation: PropTypes.object,
    handleMode: PropTypes.string,
    isAttachMode: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    isAttachMode: isAttachModeSelector,
});

const getHandleMode = (props) => {
    const { isAttachMode } = props;

    if (isAttachMode) return 'attach-mode';

    return null;
};

const getCollapseStateTransitionOffset = ({ initialCollapsed, showCollapsed, gridSize, zoomScaleOnDragSource }) => {
    const unscaledGrabOffset = pointLib.reverseScale(zoomScaleOnDragSource, dragAndDropStateSingleton.scaledGrabOffset);

    // Switching back to the initial state
    if (showCollapsed === initialCollapsed) {
        return initialCollapsed ? unscaledGrabOffset : null;
    }

    // If initially collapsed but no longer, we want to show the dragging preview as though it was
    // grabbed towards the top left, but not exactly on the top left
    if (initialCollapsed) {
        const expandedCommentTopCenter = getDefaultElementOffset({
            element: ElementType.COMMENT_THREAD_TYPE,
            gridSize,
        });
        return pointLib.translate(unscaledGrabOffset, expandedCommentTopCenter);
    }

    return unscaledGrabOffset;
};

const getCollapseStateTransitionTranslation = (args) => getCollapseStateTransitionOffset(args);

const CommentThreadDragPreview = (props) => {
    const { showCollapsed, gridSize, isSelected, isAttachMode, zoomScaleOnDragSource } = props;

    const [handleMode, setHandleMode] = React.useState(null);
    const rafId = React.useRef(null);

    const initialCollapsed = React.useRef(showCollapsed);
    const [translation, setTranslation] = React.useState({ x: 0, y: 0 });

    useUpdateLayoutEffect(() => {
        // Don't translate if the initial collapsed state is the same as the current collapsed state
        // just stick with the initial offset
        const newTranslation = getCollapseStateTransitionTranslation({
            showCollapsed,
            initialCollapsed: initialCollapsed.current,
            gridSize,
            zoomScaleOnDragSource,
        });

        setTranslation(newTranslation);
    }, [showCollapsed]);

    // We need to delay by an animation frame to ensure that dragged pins animate into the attached mode
    React.useEffect(() => {
        rafId.current = requestAnimationFrame(() => {
            const newHandleMode = getHandleMode(props);
            setHandleMode(newHandleMode);
        });

        return () => {
            if (rafId.current) cancelAnimationFrame(rafId.current);
        };
    }, [isSelected, isAttachMode]);

    return (
        <div className="CommentThreadDragPreview">
            <CommentThreadDragPreviewTypeSwitch handleMode={handleMode} translation={translation} {...props} />
        </div>
    );
};

CommentThreadDragPreview.propTypes = {
    showCollapsed: PropTypes.bool,
    isSelected: PropTypes.bool,
    isAttachMode: PropTypes.bool,
    gridSize: PropTypes.number,
    zoomScaleOnDragSource: PropTypes.number,
};

export default connect(mapStateToProps)(CommentThreadDragPreview);
