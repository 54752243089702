// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils;
import { getDrawingSvg, getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { GRID } from '../../../utils/grid/gridConstants';
import {
    SKETCH_MIN_WIDTH,
    SKETCH_MIN_HEIGHT,
    SKETCH_CANVAS_PIXEL_RATIO,
    SKETCH_DEFAULT_WIDTH,
} from '../../../../common/drawings/sketches/sketchConstants';

const SNAP_THRESHOLD = 2;

const calculateStaticHeightGridUnits = (elNode, emptyDrawingNode, gridSize) => {
    if (!elNode || !emptyDrawingNode) return 0;

    const elRect = elNode.getBoundingClientRect();
    const emptyDrawingRect = emptyDrawingNode.getBoundingClientRect();

    return (elRect.height - emptyDrawingRect.height) / gridSize;
};

export default (DecoratedComponent) => {
    class SketchResizeDecorator extends React.Component {
        constructor(props) {
            super(props);

            this.drawingElementRef = React.createRef();
            this.emptyDrawingRef = React.createRef();
        }

        setElementSize = (size) => {
            const { setElementSize, element, gridSize, dispatchUpdateElement } = this.props;

            let width = Math.max(Math.round(size.width), SKETCH_MIN_WIDTH);
            const height = Math.max(Math.round(size.height), SKETCH_MIN_WIDTH);

            const changes = {
                width,
            };

            // Implement width snapping for sketches
            const defaultWidthDiff = Math.abs(width - SKETCH_DEFAULT_WIDTH);
            if (defaultWidthDiff <= SNAP_THRESHOLD) {
                width = SKETCH_DEFAULT_WIDTH;
                changes.width = SKETCH_DEFAULT_WIDTH;
            }

            // We are free to set the aspect ratio if it's an empty sketch
            if (!getDrawingSvg(element)) {
                const staticHeight = calculateStaticHeightGridUnits(
                    this.drawingElementRef.current,
                    this.emptyDrawingRef.current,
                    gridSize,
                );

                // Increase the resolution to improve iPad behaviour
                const drawingWidth = width * GRID.LARGE.size * SKETCH_CANVAS_PIXEL_RATIO;
                const drawingHeight =
                    Math.max(SKETCH_MIN_HEIGHT, height - staticHeight) * GRID.LARGE.size * SKETCH_CANVAS_PIXEL_RATIO;

                changes.drawing = {
                    width: drawingWidth,
                    // Subtract 2 to sit within pixels
                    height: drawingHeight - 2 * SKETCH_CANVAS_PIXEL_RATIO,
                };
            }

            dispatchUpdateElement({
                id: getElementId(element),
                changes,
            });

            setElementSize(size);
        };

        setTempElementSize = (size) => {
            const { setTempElementSize, element, gridSize } = this.props;

            if (!size) {
                setTempElementSize(size);
                return;
            }

            let adjustedSize = size;

            // Implement width snapping for sketches
            const defaultWidthDiff = Math.abs(size.width - SKETCH_DEFAULT_WIDTH);

            if (defaultWidthDiff <= SNAP_THRESHOLD) {
                adjustedSize = {
                    ...adjustedSize,
                    width: SKETCH_DEFAULT_WIDTH,
                };
            }

            if (!getDrawingSvg(element)) {
                const staticHeight = calculateStaticHeightGridUnits(
                    this.drawingElementRef.current,
                    this.emptyDrawingRef.current,
                    gridSize,
                );

                adjustedSize = {
                    ...adjustedSize,
                    height: Math.max(SKETCH_MIN_HEIGHT, size.height - staticHeight),
                };
            }

            setTempElementSize(adjustedSize);
        };

        handleDoubleClick = (event) => {
            event.preventDefault();
            event.stopPropagation();

            // Might need to keep track of the scaling for annotation drawings
            return this.setElementSize({ width: SKETCH_DEFAULT_WIDTH });
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    drawingElementRef={this.drawingElementRef}
                    emptyDrawingRef={this.emptyDrawingRef}
                    setTempElementSize={this.setTempElementSize}
                    setElementSize={this.setElementSize}
                    handleDoubleClick={this.handleDoubleClick}
                />
            );
        }
    }

    SketchResizeDecorator.propTypes = {
        element: PropTypes.object,
        gridSize: PropTypes.number,
        dispatchUpdateElement: PropTypes.func,
        setElementSize: PropTypes.func,
        setTempElementSize: PropTypes.func,
    };

    return SketchResizeDecorator;
};
