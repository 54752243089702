// Lib
import * as Immutable from 'immutable';

// Constants
import { THEMES } from '../../user/account/accountModal/interface/themeSettings/themeConstants';
import {
    HYBRID_APP_CHANGE_THEME,
    HYBRID_APP_DID_BECOME_ACTIVE,
    HYBRID_APP_ENTER_BACKGROUND,
    HYBRID_APP_ENTER_FOREGROUND,
    HYBRID_APP_RESIGN_ACTIVE,
} from './hybridAppStoreConstants';

const initialState = Immutable.Map({
    isForeground: true,
    isActive: true,
    nativeTheme: THEMES.LIGHT,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case HYBRID_APP_ENTER_FOREGROUND:
            return state.set('isForeground', true);
        case HYBRID_APP_ENTER_BACKGROUND:
            return state.set('isForeground', false);
        case HYBRID_APP_DID_BECOME_ACTIVE:
            return state.set('isActive', true);
        case HYBRID_APP_RESIGN_ACTIVE:
            return state.set('isActive', false);
        case HYBRID_APP_CHANGE_THEME:
            return state.set('nativeTheme', action.theme);
        default:
            return state;
    }
};
