// Lib
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import { getElementId, getImageProp, getMedia } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { cleanElementAttachment } from '../../element/attachments/attachmentActions';

// Selectors
import { getAttachment } from '../../element/attachments/attachmentsSelector';

// Components
import OptimisticImage from './OptimisticImage';

const mapStateToProps = createStructuredSelector({
    attachment: getAttachment,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCleanElementAttachment: (id) => dispatch(cleanElementAttachment({ id })),
});

const ElementImage = ({
    element,
    // Optional override - if you don't want to use the standard image property on the element
    imageData,
    cropToGrid,
    cropInset,
    gridSize,
    children,
    widthPx,
    // Allows a different quality image to be used to the width that the image is being rendered at
    // This is important for focus mode which needs the aspect ratio to remain as is even when a higher
    //  quality image is used
    imageQualityWidthPxOverride,
    savedWidthPx,
    imageType,
    forcedSize,
    attachment,
    thumbnailUrl,
    dispatchCleanElementAttachment,
    useSecureMediaUrl,
    showBrokenIconOnError,
    isPreview,
}) => {
    const handleImageLoad = useCallback(
        (url) => {
            const elementId = getElementId(element);
            if (attachment && prop('fetched', attachment)) dispatchCleanElementAttachment(elementId);
        },
        [element],
    );

    const elementId = getElementId(element);
    const imageDetails = imageData || getImageProp(element);
    const mediaData = getMedia(element);

    return (
        <OptimisticImage
            elementId={elementId}
            imageType={imageType}
            imageDetails={imageDetails}
            mediaDetails={mediaData}
            attachmentDetails={attachment}
            onImageLoadCb={handleImageLoad}
            widthPx={widthPx}
            imageQualityWidthPxOverride={imageQualityWidthPxOverride}
            savedWidthPx={savedWidthPx}
            forcedSize={forcedSize}
            cropInset={cropInset}
            cropToGrid={cropToGrid}
            gridSize={gridSize}
            thumbnailUrl={thumbnailUrl}
            useSecureMediaUrl={useSecureMediaUrl}
            showBrokenIconOnError={showBrokenIconOnError}
            isPreview={isPreview}
        >
            {children}
        </OptimisticImage>
    );
};

ElementImage.propTypes = {
    element: PropTypes.object.isRequired,
    imageData: PropTypes.object,

    // Allows images to be cropped to sit perfectly within the grid
    cropToGrid: PropTypes.bool,
    cropInset: PropTypes.number,
    gridSize: PropTypes.number,

    attachment: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    thumbnailUrl: PropTypes.string,
    imageType: PropTypes.string.isRequired,
    widthPx: PropTypes.number.isRequired,
    imageQualityWidthPxOverride: PropTypes.number,
    savedWidthPx: PropTypes.number,
    forcedSize: PropTypes.object,
    dispatchCleanElementAttachment: PropTypes.func,

    // If true, will update image source to go through new secure link
    useSecureMediaUrl: PropTypes.bool,
    showBrokenIconOnError: PropTypes.bool,
    isPreview: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementImage);
