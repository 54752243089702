export const HANDLER_RETURN_VALUES = {
    handled: 'handled',
    notHandled: 'not-handled',
};

// I think future versions of Draft JS will expose this.  When they do this should be removed.
export const EditorChangeType = {
    MOVE_SELECTION_TO_END_OF_BLOCK: 'move-selection-to-end-of-block',
    MOVE_SELECTION_TO_START_OF_BLOCK: 'move-selection-to-start-of-block',
    CHANGE_BLOCK_TYPE: 'change-block-type',
    CHANGE_BLOCK_DATA: 'change-block-data',
    CHANGE_INLINE_STYLE: 'change-inline-style',
    SPLIT_BLOCK: 'split-block',
    REMOVE_RANGE: 'remove-range',
    ADJUST_DEPTH: 'adjust-depth',
    APPLY_ENTITY: 'apply-entity',
    INSERT_CHARACTERS: 'insert-characters',
    INSERT_FRAGMENT: 'insert-fragment',
};

export const DRAFT_COMMANDS = {
    BACKSPACE: 'backspace',
    DELETE: 'delete',
};

export const DraftRemovalDirection = {
    forward: 'forward',
    backward: 'backward',
};

export const DRAFT_ENTITY_MUTABILITY = {
    MUTABLE: 'MUTABLE',
    IMMUTABLE: 'IMMUTABLE',
    SEGMENTED: 'SEGMENTED',
};
