// Constants
import {
    LIVE_COLLABORATION_SOCKET_CONNECT,
    LIVE_COLLABORATION_SOCKET_DISCONNECT,
    LIVE_COLLABORATION_SOCKET_UPDATE_CHANNEL,
} from '../liveCollaborationConstants';

export const createLiveCollaborationSocketMiddleware = (socketManager) => (store) => (next) => (action) => {
    const newState = next(action);

    if (action.sync) {
        socketManager.emitAction(action);
    }

    switch (action.type) {
        case LIVE_COLLABORATION_SOCKET_CONNECT:
            socketManager.connectSocket(action);
            break;
        case LIVE_COLLABORATION_SOCKET_DISCONNECT:
            socketManager.disconnectSocket();
            break;
        case LIVE_COLLABORATION_SOCKET_UPDATE_CHANNEL:
            socketManager.updateChannel(action);
            break;
        default:
            break;
    }

    return newState;
};
