// Lib
import { range, reverse } from 'lodash';
import { clamp } from 'lodash/fp';

// 2^16 (Trello seem to do this...)
export const DEFAULT_INCREMENT = 65536;

export const calculateResetScores = (size) => {
    const scoresArray = [];
    for (let i = 0; i < size; i++) {
        scoresArray[i] = i * DEFAULT_INCREMENT;
    }
    return scoresArray;
};

/* eslint-disable eqeqeq */
const calculateIncrement = (prevScore, nextScore, insertCount) => {
    // == checks for undefined & null
    if (nextScore == null) return DEFAULT_INCREMENT;
    if (prevScore == null) return -DEFAULT_INCREMENT;
    return (nextScore - prevScore) / (insertCount + 1);
};

/* eslint-disable eqeqeq */
const calculateStartingScore = (prevScore, nextScore, increment) => {
    // == checks for undefined & null
    // If there's nothing in the list we start at 0
    if (prevScore == null && nextScore == null) return 0;
    // If there's nothing before this insert, then start at the nextScore plus the increment (which will be negative)
    if (prevScore == null) return nextScore + increment;
    // Otherwise just add the increment to the previous score
    return prevScore + increment;
};
/* eslint-enable eqeqeq */

export const calculateSingleScore = (prevScore, nextScore) => {
    const increment = calculateIncrement(prevScore, nextScore, 1);
    return calculateStartingScore(prevScore, nextScore, increment);
};

/**
 * Returns an array of scores for each inserted element, or null if a score collision occurs.
 *
 * @param scores {Number[]} The array of existing scores to insert new scores into.
 * @param index {Number} The index to insert new scores at.
 * @param insertCount {Number} The number of scores to insert.
 *
 * @return {number[]} The new scores for each inserted index.
 */
export const getNewScores = (scores, index, insertCount) => {
    const _index = clamp(0, scores.length, index);

    const prevScore = scores[_index - 1];
    // The old score at this index will become the next score after the insert
    const nextScore = scores[_index];

    const increment = calculateIncrement(prevScore, nextScore, insertCount);
    const startScore = calculateStartingScore(prevScore, nextScore, increment);

    // Score collision - the scores must be recalculated as we have hit the limit
    if (startScore === prevScore || startScore === nextScore) return null;

    const endScore = startScore + insertCount * increment;

    if (increment < 0) return reverse(range(startScore, endScore, increment));

    return range(startScore, endScore, increment);
};
