// Lib
import { createSelector } from 'reselect';
import { keys, get } from 'lodash/fp';

// Utils
import { isSkeleton } from '../../../common/elements/utils/elementTypeUtils';
import { createDeepSelector, createShallowSelector } from '../milanoteReselect/milanoteReselect';
import {
    getCurrentBoardIdFromState,
    getVisibleBoardId,
    getCurrentBoardId,
} from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentUserId, isGuestSelector } from '../../user/currentUserSelector';
import {
    hasRetrievedAllAncestors,
    isElementOrAncestorInTrash,
} from '../../../common/elements/utils/elementTraversalUtils';
import { getUsers } from '../../user/usersSelector';
import { length, getMany } from '../../../common/utils/immutableHelper';
import {
    getPermission,
    getCombinedPermissionsSummary,
    getSharedUserIds,
    canSaveBoard,
    canGiveFeedbackOnBoard,
    hasSecretLinkAcl,
    getBranchPublicPermissionProperties,
    confirmedInvalidPermissions,
    getBlockedUserIds,
} from '../../../common/permissions/elementPermissionsUtil';
import { userIsRegistered } from '../../../common/users/userHelper';
import { getElements } from '../../element/selectors/elementsSelector';
import { getBranchElementsSelector } from '../../element/selectors/elementTraversalSelector';
import {
    getIsPresentationModeEditingDisabled,
    isPresentationModeEnabledSelector,
} from '../../workspace/presentation/presentationSelector';
import { isPlatformIframe } from '../../platform/utils/platformDetailsUtils';
import { getLegacyHybridUseCaseSelector, getPlatformDetailsSelector } from '../../platform/platformSelector';
import {
    createFindFirstMatchingAncestorFromElementSelector,
    getCurrentBoardAncestorAndSelfIdsSelector,
    getCurrentBoardAncestorsAndSelfSelector,
} from '../../element/selectors/currentBoardSelector';
import {
    getAclIdsSelector,
    getBoardIdEditPermissionIdMapSelector,
    getBoardIdPublishPermissionIdMapSelector,
    getBoardIdToPermissionIdMapSelector,
    getClosestPermissionIdForElementIdSelector,
    getEditPermissionIdForElementIdSelector,
    getPublishPermissionIdForElementIdSelector,
} from './permissionsSelector';
import {
    getElementId,
    getLinkedElementId,
    getIsPublicEditEnabled,
    getIsPublished,
    getIsPublishedFeedbackEnabled,
    getPhysicalId,
    getIsPublishedMediaDownloadDisabled,
} from '../../../common/elements/utils/elementPropertyUtils';
import {
    canEditPermissions,
    canGiveFeedback,
    isBlocked,
    isFullAccess,
    isFeedbackOnlyAccess,
    isReadOnly,
} from '../../../common/permissions/permissionUtil';
import { isPublishPreviewModeEnabledSelector } from '../../workspace/preview/previewSelector';

// Constants
import { ACCESS_BITS, PERMISSION_VALUES } from '../../../common/permissions/permissionConstants';
import { LegacyHybridUseCase } from '../../platform/platformTypes';

/**
 * Are all the ancestors stored in the client side store.
 */
export const hasRetrievedAllAncestorsSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardIdFromState,
    (elements, currentBoardId) => hasRetrievedAllAncestors(elements, currentBoardId),
);

/**
 * Are any of the ancestors not a skeleton.
 */
export const hasAnyNonSkeletonAncestorsOrSelfSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    (ancestors) => ancestors.some((ancestor) => !!ancestor && !isSkeleton(ancestor)),
);

/**
 * Are the ancestors retrieved, with some not being skeletons.
 */
export const hasRetrievedReadableAncestorsSelector = createSelector(
    hasRetrievedAllAncestorsSelector,
    hasAnyNonSkeletonAncestorsOrSelfSelector,
    (hasRetrievedAllAncestors, hasAnyNonSkeletonAncestorsOrSelf) =>
        hasRetrievedAllAncestors && hasAnyNonSkeletonAncestorsOrSelf,
);

/**
 * Returns a number representing the permission that the current user has on the board, not taking into
 * account constraints such as whether the user is viewing a publish preview, or they're a guest user.
 */
export const currentBoardUnconstrainedUserPermissions = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardIdFromState,
    getAclIdsSelector,
    getPermission,
);

const branchPublicPermissionPropertiesSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardIdFromState,
    getBranchPublicPermissionProperties,
);

/**
 * Determines if any element in the current branch has the feedback enabled property set.
 * This is only important for the preview board, as the board might not be published yet,
 * but we want to know if it will have feedback permissions while previewing.
 */
const branchFeedbackEnabledPropertySelector = (state) =>
    branchPublicPermissionPropertiesSelector(state).feedbackEnabled;

/**
 * Returns a number representing the permission that the current user has in this board.
 * E.g. 31 for owner.
 */
export const currentBoardUserPermissionsSelector = (state) => {
    const unconstrainedPermission = currentBoardUnconstrainedUserPermissions(state);
    const isPresentationModeWithEditingDisabled =
        isPresentationModeEnabledSelector(state) && getIsPresentationModeEditingDisabled(state);

    if (isBlocked(unconstrainedPermission)) return unconstrainedPermission;

    const platformDetails = getPlatformDetailsSelector(state);
    if (isPlatformIframe(platformDetails)) return unconstrainedPermission & PERMISSION_VALUES.READ_ONLY;

    // Restrict publish preview mode boards to a maximum of feedback enabled
    if (isGuestSelector(state)) return unconstrainedPermission & PERMISSION_VALUES.READ_ONLY;

    // Return the `unconstrainedPermission` for iOS Canvas so elements can be moved on the canvas while in presentation mode
    const legacyHybridUseCase = getLegacyHybridUseCaseSelector(state);
    if (isPresentationModeWithEditingDisabled && legacyHybridUseCase === LegacyHybridUseCase.IOS_CANVAS)
        return unconstrainedPermission;

    if (isPresentationModeWithEditingDisabled) return unconstrainedPermission & PERMISSION_VALUES.READ_ONLY;

    if (!isPublishPreviewModeEnabledSelector(state)) return unconstrainedPermission;

    const feedbackEnabled = branchFeedbackEnabledPropertySelector(state);
    return feedbackEnabled ? PERMISSION_VALUES.READ_AND_FEEDBACK : PERMISSION_VALUES.READ_ONLY;
};

/**
 * Returns a number representing the permission that the current user specifically has in this board.
 * E.g. 15 for full read, write, save, feedback.
 *
 * NOTE: This is for the SPECIFIC user entry, e.g. "57707f5657cce92a257f7c1e", and does NOT include
 *  public permissions such as _other or _registered.  But is DOES include hierarchical permissions for
 *  that specific user.
 */
export const currentBoardSpecificUserPermissionsSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardId,
    getCurrentUserId,
    getPermission,
);

/**
 * Returns true if the current user's ACL IDs do not give them read access AND the
 * board's ancestors have been fetched.
 */
export const currentBoardAccessDeniedSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardIdFromState,
    getAclIdsSelector,
    (elements, currentBoardId, aclIds) =>
        confirmedInvalidPermissions(elements, currentBoardId, aclIds, ACCESS_BITS.READ),
);

export const isCurrentBoardInTrashSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardIdFromState,
    isElementOrAncestorInTrash,
);

export const visibleBoardUserPermissionsSelector = createSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getVisibleBoardId,
    getAclIdsSelector,
    (elements, boardId, aclIds) => {
        if (!boardId) return;
        return getPermission(elements, boardId, aclIds);
    },
);

/**
 * Returns a map of all the users who have access to the board and their corresponding permission number.
 */
export const currentBoardPermissionsSelector = createDeepSelector(
    getCurrentBoardAncestorsAndSelfSelector,
    getCurrentBoardId,
    getCombinedPermissionsSummary,
);

/**
 * Determines the permission that the current user has for a specific element (e.g. 15).
 *
 * NOTE: This is recalculated for every breadcrumb and every board whenever an element is changed / added to the
 *      elements store.
 *      This doesn't appear to be a large cost, but might be something to look at in the future, given it's only
 *      concerned with ACL changes.
 *      My feeling is that the cost of doing an "ACL index" might be more than the cost to just recompute on
 *      every element store change.
 */
export const createElementUserPermissionsSelector = () =>
    createSelector(
        getElements,
        (state, props) => getElementId(props.element) || props.elementId,
        getAclIdsSelector,
        getPermission,
    );

/**
 * Determines the permission that the current user has for a specific alias's linked board (e.g. 15).
 */
export const aliasUserPermissionsSelector = () =>
    createSelector(
        getElements,
        (state, props) => getLinkedElementId(props.element),
        getAclIdsSelector,
        (elements, linkedBoardId, aclIds) => getPermission(elements, linkedBoardId, aclIds),
    );

/**
 * Determines if the current board has valid permissions for the requested permissions.
 * Note - this will take into account whether the ancestor boards are all retrieved.
 */
const createCurrentBoardPermissionSelector = (requiredPermissionFn) =>
    createSelector(
        getCurrentBoardAncestorsAndSelfSelector,
        getCurrentBoardIdFromState,
        getAclIdsSelector,
        requiredPermissionFn,
    );
export const canSaveCurrentBoardSelector = createCurrentBoardPermissionSelector(canSaveBoard);
export const canGiveFeedbackOnCurrentBoardSelector = createCurrentBoardPermissionSelector(canGiveFeedbackOnBoard);

const elementIsPublishedOrHasPublicUserReadPermissions = (element) =>
    getIsPublished(element) || hasSecretLinkAcl(element);

export const publicEditAncestorBoardSelector =
    createFindFirstMatchingAncestorFromElementSelector(getIsPublicEditEnabled);
export const publishedAncestorBoardSelector = createFindFirstMatchingAncestorFromElementSelector(
    elementIsPublishedOrHasPublicUserReadPermissions,
);
export const publishedMediaDownloadEnabledAncestorBoardSelector = createFindFirstMatchingAncestorFromElementSelector(
    getIsPublishedMediaDownloadDisabled,
);

export const publishedFeedbackEnabledAncestorBoardSelector =
    createFindFirstMatchingAncestorFromElementSelector(getIsPublishedFeedbackEnabled);

export const currentBoardHasPublicEditPermissionsSelector = createSelector(
    hasRetrievedReadableAncestorsSelector,
    publicEditAncestorBoardSelector,
    (hasRetrievedReadableAncestors, publicEditAncestorBoard) =>
        hasRetrievedReadableAncestors && !!publicEditAncestorBoard,
);
export const currentBoardIsPublishedSelector = createSelector(
    hasRetrievedReadableAncestorsSelector,
    publishedAncestorBoardSelector,
    (hasRetrievedReadableAncestors, publishedAncestorBoard) =>
        hasRetrievedReadableAncestors && !!publishedAncestorBoard,
);
export const currentBoardHasPublishedFeedbackEnabledSelector = createSelector(
    hasRetrievedReadableAncestorsSelector,
    publishedFeedbackEnabledAncestorBoardSelector,
    (hasRetrievedReadableAncestors, publishedAncestorBoard) =>
        hasRetrievedReadableAncestors && !!publishedAncestorBoard,
);
export const currentBoardHasMediaDownloadDisabledSelector = createSelector(
    hasRetrievedReadableAncestorsSelector,
    publishedMediaDownloadEnabledAncestorBoardSelector,
    (hasRetrievedReadableAncestors, publishedMediaDownloadBoard) =>
        hasRetrievedReadableAncestors && !!publishedMediaDownloadBoard,
);

export const currentBoardHasMediaDownloadAccessSelector = createSelector(
    currentBoardUserPermissionsSelector,
    currentBoardHasMediaDownloadDisabledSelector,
    (boardPermissions, currentBoardHasMediaDownloadDisabled) =>
        !(
            currentBoardHasMediaDownloadDisabled &&
            (isFeedbackOnlyAccess(boardPermissions) || isReadOnly(boardPermissions))
        ),
);

/**
 * Gets the permission ID that enables public editing for the current board.
 */
export const currentBoardEditPermissionIdSelector = (state) => {
    const currentBoardId = getCurrentBoardIdFromState(state);
    const specificUserPermissions = currentBoardSpecificUserPermissionsSelector(state);

    // If they can't specifically edit the board, then they must be using their current permission ID
    // to edit the board, so return that instead
    if (!canEditPermissions(specificUserPermissions)) {
        return getClosestPermissionIdForElementIdSelector()(state, { elementId: currentBoardId });
    }

    const hasPublicEditPermissions = currentBoardHasPublicEditPermissionsSelector(state);

    if (!hasPublicEditPermissions) return null;

    return getEditPermissionIdForElementIdSelector(state, { elementId: currentBoardId });
};

/**
 * Gets the published permission ID for the current board.
 */
export const currentBoardPublishedPermissionIdSelector = (state) => {
    const elementId = getCurrentBoardIdFromState(state);
    return getPublishPermissionIdForElementIdSelector(state, { elementId });
};

/**
 * Returns an array of all the users on the board who have access to this board, including the public user ID if
 * a secret link is set.
 */
export const currentBoardPermissionsAclIdsSelector = createSelector(currentBoardPermissionsSelector, keys);

/**
 * Returns an array of the user IDs for all users with access to the current board.
 */
export const currentBoardSharedUserIdsSelector = createShallowSelector(
    currentBoardPermissionsSelector,
    getSharedUserIds,
);

/**
 * Returns an array of the user IDs for all users that have been blocked on this board or its ancestors.
 */
export const currentBoardBlockedUserIdsSelector = createShallowSelector(
    currentBoardPermissionsSelector,
    getBlockedUserIds,
);

/**
 * Returns an Immutable Map of the users that have access to the current board.
 */
export const currentBoardSharedUsersSelector = createShallowSelector(
    currentBoardSharedUserIdsSelector,
    getUsers,
    getMany,
);

/**
 * Returns an Immutable Map of the users that have access to the current board and are registered Milanote users.
 */
export const currentBoardRegisteredSharedUsers = createShallowSelector(currentBoardSharedUsersSelector, (sharedUsers) =>
    sharedUsers.filter(userIsRegistered),
);

export const currentBoardCanHaveSharedContributors = createSelector(
    currentBoardPermissionsSelector,
    (currentBoardPermissions) => {
        const permissionEntries = Object.values(currentBoardPermissions);
        return length(permissionEntries.filter((entry) => canGiveFeedback(get('permission', entry)))) > 1;
    },
);

export const currentBoardCanHaveRemoteEditorsSelector = createSelector(
    currentBoardPermissionsSelector,
    currentBoardHasPublicEditPermissionsSelector,
    (currentBoardPermissions, hasPublicEditPermissions) => {
        if (hasPublicEditPermissions) return true;

        const permissionEntries = Object.values(currentBoardPermissions);
        return length(permissionEntries.filter((entry) => isFullAccess(get('permission', entry)))) > 1;
    },
);

// GENERAL ELEMENT PERMISSIONS SELECTORS
/**
 * Returns true if the current user's ACL IDs do not give them read access AND the
 * board's ancestors have been fetched.
 *
 * NOTE: This uses the standard "elementId from props" selectors (e.g. getBranchElementsSelector)
 * but uses the initial props to set the element ID to the *physical element ID*.
 */
export const boardAccessDeniedSelector = (initialState, initialProps) => {
    // We need to use the physical ID for board permissions, so initialise these once
    const elementId = initialProps.element ? getPhysicalId(initialProps.element) : initialProps.elementId;

    const selectorProps = { elementId };

    // Create the selector only once
    const getThisBranchElementsSelector = getBranchElementsSelector();

    return createSelector(
        (state) => getThisBranchElementsSelector(state, selectorProps),
        getAclIdsSelector,
        (elements, aclIds) => confirmedInvalidPermissions(elements, elementId, aclIds, ACCESS_BITS.READ),
    );
};

/**
 * Finds the most permissive permission ID to use for the current board.
 */
export const getCurrentBoardBestUrlPermissionId = createSelector(
    currentBoardHasPublicEditPermissionsSelector,
    currentBoardIsPublishedSelector,
    getCurrentBoardAncestorAndSelfIdsSelector,
    getBoardIdEditPermissionIdMapSelector,
    getBoardIdPublishPermissionIdMapSelector,
    getBoardIdToPermissionIdMapSelector,
    (
        hasEditPermissions,
        isPublished,
        ancestorIds,
        editPermissionIdMap,
        publishPermissionIdMap,
        boardIdPermissionIdMap,
    ) => {
        // Edit permissions are preferred over published permissions
        const editAncestorId =
            hasEditPermissions && ancestorIds.find((ancestorId) => editPermissionIdMap?.get(ancestorId));

        if (editAncestorId) return editPermissionIdMap.get(editAncestorId);

        const publishedAncestorId =
            isPublished && ancestorIds.find((ancestorId) => publishPermissionIdMap?.get(ancestorId));

        if (publishedAncestorId) return publishPermissionIdMap.get(publishedAncestorId);

        if (!isPublished && !hasEditPermissions) return null;

        // Otherwise fallback to the board ID to permission ID map
        const fallbackAncestorId = ancestorIds.find((ancestorId) => boardIdPermissionIdMap?.get(ancestorId));

        if (!fallbackAncestorId) return null;

        return boardIdPermissionIdMap.get(fallbackAncestorId);
    },
);
