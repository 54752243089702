// Lib
import DefaultTextColor from '@tiptap/extension-color';

export const VALID_MILANOTE_TEXT_COLORS = new Set(['blue', 'green', 'orange', 'red', 'grey']);

/**
 * Ensures that the text colours are limited to the Milanote text colour palette.
 */
export const TextColor = DefaultTextColor.extend({
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    color: {
                        default: null,
                        parseHTML: (element: HTMLElement) => {
                            const color = element.style.color?.replace(/['"]+/g, '');

                            if (!color || !VALID_MILANOTE_TEXT_COLORS.has(color)) return null;

                            return color;
                        },
                        renderHTML: (attributes) => {
                            if (!attributes.color) return {};

                            return {
                                style: `color: ${attributes.color}`,
                            };
                        },
                    },
                },
            },
        ];
    },
});
