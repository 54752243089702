import { RawDraftContentState } from 'draft-js';

// We'll need to cumulatively keep track of offsets at each line and update
// entity ranges and inline style ranges accordingly.
// joinDraftJsParagraphsWithSoftNewlines

export const prepareDraftJsCaptionForConversion = (captionContent: RawDraftContentState): RawDraftContentState => {
    let currentOffset = 0;
    const entityRanges = [];
    const inlineStyleRanges = [];

    for (const block of captionContent.blocks) {
        const blockText = block.text;
        const blockTextLength = blockText.length;

        const blockEntityRanges = block.entityRanges;
        const blockInlineStyleRanges = block.inlineStyleRanges;

        const newBlockEntityRanges = blockEntityRanges.map((entityRange) => {
            const newEntityRange = { ...entityRange };
            newEntityRange.offset = entityRange.offset + currentOffset;
            return newEntityRange;
        });

        entityRanges.push(...newBlockEntityRanges);

        const newBlockInlineStyleRanges = blockInlineStyleRanges.map((inlineStyleRange) => {
            const newInlineStyleRange = { ...inlineStyleRange };
            newInlineStyleRange.offset = inlineStyleRange.offset + currentOffset;
            return newInlineStyleRange;
        });

        inlineStyleRanges.push(...newBlockInlineStyleRanges);

        // We need to add 1 for the newline character
        currentOffset += blockTextLength + 1;
    }

    const newCaptionContent: RawDraftContentState = { ...captionContent };

    const captionText = captionContent.blocks.map((block) => block.text).join('\n');

    // Simplify the caption content to a single block
    newCaptionContent.blocks = [
        {
            key: captionContent.blocks[0].key,
            text: captionText,
            type: 'unstyled',
            depth: 0,
            entityRanges,
            inlineStyleRanges,
        },
    ];

    return newCaptionContent;
};
