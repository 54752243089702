import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const PlayArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={20}
        viewBox="0 0 19 20"
        className="Icon play-arrow"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M17.72 11.09 2.082 19.848a1.25 1.25 0 0 1-1.861-1.09V1.243A1.25 1.25 0 0 1 2.082.153l15.636 8.755a1.252 1.252 0 0 1 0 2.182z"
        />
    </svg>
);
const Memo = memo(PlayArrowIcon);
export default Memo;
