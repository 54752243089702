// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import AnimationLeaveStall from '../../../components/animations/AnimationLeaveStall';
import ElementUserTag from '../../../user/userActivity/ElementUserTag';

const BoardIconTag = (props) => {
    const { isRemotelySelected } = props;

    let component = null;

    if (isRemotelySelected) {
        component = <ElementUserTag {...props} />;
    }

    return <AnimationLeaveStall delay={700}>{component}</AnimationLeaveStall>;
};

BoardIconTag.propTypes = {
    isRemotelySelected: PropTypes.bool,
    filterState: PropTypes.string,
};

export default BoardIconTag;
