// Constants
import { NOTIFICATIONS_ADD_OR_UPDATE, NOTIFICATIONS_REMOVE } from './notificationConstants';

export const removeNotifications = ({ userId, notificationIds, sync = true }) => ({
    type: NOTIFICATIONS_REMOVE,
    userId,
    notificationIds,
    sync,
});

export const addOrUpdateNotifications = ({ userId, notifications }) => ({
    type: NOTIFICATIONS_ADD_OR_UPDATE,
    notifications,
    userId,
});
