// Lib
import React, { useState } from 'react';
import FormulaDropdown from './FormulaDropdown';
import DatePickerContainer from './cellTypes/DatePickerContainer';
import PropTypes from 'prop-types';

const TablePopups = (props) => {
    const {
        element,
        tableContentData,
        gridSize,
        currentCellSelections,
        isCurrentlyEditingTableCell,
        dropdownRef,
        cellEditorRef,
        locale,
    } = props;

    const addFormulaToCell = (formula) => {
        if (!formula) return;
        cellEditorRef.current.setPlainText('=' + formula + '(');
    };

    const [showFormulaDropdown, setShowFormulaDropdown] = useState(false);

    return (
        <>
            <FormulaDropdown
                ref={dropdownRef}
                showFormulaDropdown={showFormulaDropdown}
                setShowFormulaDropdown={setShowFormulaDropdown}
                addFormulaToCell={addFormulaToCell}
            />
            {isCurrentlyEditingTableCell && (
                <DatePickerContainer
                    showFormulaDropdown={showFormulaDropdown}
                    isCurrentlyEditingTableCell={isCurrentlyEditingTableCell}
                    gridSize={gridSize}
                    element={element}
                    cellEditorRef={cellEditorRef}
                    currentCellSelections={currentCellSelections}
                    tableContentData={tableContentData}
                    locale={locale}
                />
            )}
        </>
    );
};

TablePopups.propTypes = {
    element: PropTypes.object,
    tableContentData: PropTypes.array,
    gridSize: PropTypes.number,
    currentCellSelections: PropTypes.object,
    isCurrentlyEditingTableCell: PropTypes.bool,
    dropdownRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    cellEditorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    locale: PropTypes.string,
};

export default TablePopups;
