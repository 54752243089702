// Lib
import { combineReducers } from 'redux-immutable';

// Utils
import { isCurrentBrowserLogoutAction } from '../auth/authUtils';

// Reducers
import routing from './routingImmutableReducer';
import elements from '../element/reducers/elementsReducer';
import permissions from '../utils/permissions/permissionsReducer';
import comments from '../element/comment/store/commentsReducer';
import users from '../user/usersReducer';
import app from './appReducer';
import local from './localReducer';
import undoRedo from '../utils/undoRedo/undoRedoReducer';
import lastSaved from './lastSavedReducer';
import remoteActivity from '../remoteActivity/remoteActivityReducer';
import debug from '../debug/debugReducer';
import asyncResources from '../utils/services/http/asyncResource/reducers/asyncResourceReducer';
import elementCount from '../user/elementCount/elementCountReducer';
import cache from './cache';
import notifications from '../notifications/reducers/notificationsReducer';
import boardExport from '../../client/workspace/export/exportReducer';
import localCache from '../offline/cache/localCacheReducer';
import oneTrust from '../app/oneTrust/oneTrustReducer';
import canvasOrder from '../element/board/canvasOrder/boardCanvasOrderReducer';
import platform from '../platform/platformReducer';

// Wrappers
import { wrappedElementSummariesReducer } from '../element/reducers/elementSummariesReducer';

// Constants
import { LOGOUT } from '../auth/authConstants';

/**
 * reducers/index.js
 *  - Returns the root reducer for the client.
 */
const rootReducer = combineReducers({
    debug,
    routing,
    elements,
    permissions,
    comments,
    canvasOrder,
    users,
    notifications,
    remoteActivity,
    app,
    local,
    undoRedo,
    lastSaved,
    asyncResources,
    elementCount,
    cache,
    localCache,
    boardExport,
    oneTrust,
    platform,
});

/**
 * Reset the entire application state when the current user logs out.
 */
const wrappedRootReducer = (state, action) => {
    // If there's a logout from the same browser, reset the state, otherwise ignore it
    if (action.type === LOGOUT) {
        return isCurrentBrowserLogoutAction(action) ? rootReducer(undefined, action) : state;
    }

    const nextState = rootReducer(state, action);

    return wrappedElementSummariesReducer(state, nextState, action);
};

export default wrappedRootReducer;
