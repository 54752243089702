// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ToolSeparator from '../ToolSeparator';

// Styles
import './SubToolList.scss';

const SubToolList = (props) => {
    const { className, showSeparators, children } = props;

    return (
        <div className={classNames('SubToolList', className)}>
            {children}
            {showSeparators && <ToolSeparator />}
        </div>
    );
};

SubToolList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    showSeparators: PropTypes.bool,
};

export default SubToolList;
