// Lib
import * as Immutable from 'immutable';

// Constants
import { MODAL_CLOSE, MODAL_OPEN, MODAL_SET_STATE } from './modalConstants';

const initialState = Immutable.Map({});

export default (state = initialState, action) => {
    switch (action.type) {
        case MODAL_OPEN:
        case MODAL_SET_STATE:
            return action.state ? state.set(action.modalName, Immutable.fromJS(action.state)) : state;
        case MODAL_CLOSE:
            return state.delete(action.modalName);
        default:
            return state;
    }
};
