// Types
import { Point } from '../../../../../common/maths/geometry/pointTypes';
import { Rect } from '../../../../../common/maths/geometry/rect/rectTypes';

/**
 * Not sure why, but modifying this in place appears to be necessary!
 * Doing so, for some reason results in a second "measureElement" invocation, whereas
 * if using the rectLib's translate function the measureElement isn't invoked for the second
 * time, and the node is null the first time, so boards don't get the correct originOffset.
 *
 * Unfortunately I can't understand why that's happening at this stage.
 * It might be to do with the react-measure library using a reference to the old measurement
 * to see if a new measurement should be taken? Now we're modifying the old measurement so it
 * will cause it to re-measure.
 */
export const shiftInPlace = (rect: Rect, offset: Point) => {
    rect.x = (rect.x || rect.left) + offset.x;
    rect.y = (rect.y || rect.top) + offset.y;
    rect.top += offset.y;
    rect.bottom += offset.y;
    rect.left += offset.x;
    rect.right += offset.x;

    return rect;
};
