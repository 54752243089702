// Lib
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { isAsyncEntityFetched } from '../../utils/services/http/asyncResource/asyncResourceUtils';
import { fetchUsers } from '../userService';

// Selectors
import { getAsyncResourcesTypeState } from '../../utils/services/http/asyncResource/asyncResourceSelector';

// Actions
import { forceRemoteDeselectElements } from '../../element/selection/selectionActions';

// Constants
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';

export const TAG_TYPE_ACTIVE = 'TAG_TYPE_ACTIVE';
export const TAG_TYPE_FILTER = 'TAG_TYPE_FILTER';

const makeRemoteTagSelector = () =>
    createSelector(
        (state, ownProps) => ownProps.isRemotelySelected,
        (state, ownProps) => ownProps.remoteSelectionData,
        (state) => state.get('users'),
        (isRemotelySelected, remoteSelectionData, users) => {
            if (!isRemotelySelected) return null;

            const userId = remoteSelectionData.get('userId');

            return {
                user: users.get(userId),
                userId,
                type: TAG_TYPE_ACTIVE,
            };
        },
    );

const makeElementUserTagSelector = () =>
    createSelector(
        getAsyncResourcesTypeState(ResourceTypes.users),
        makeRemoteTagSelector(),
        (userAsyncResources, remoteTag) => {
            if (!remoteTag) return {};

            const tag = remoteTag;
            const { userId } = tag;
            const userResourceState = userAsyncResources?.[userId];

            return {
                ...tag,
                isUserFetched: isAsyncEntityFetched(userResourceState),
            };
        },
    );

const mapDispatchToProps = (dispatch) => ({
    loadUser: (userId) => dispatch(fetchUsers([userId])),
    forceRemoteDeselectElement: (userId, elementId) => dispatch(forceRemoteDeselectElements(userId, [elementId])),
});

export default connect(makeElementUserTagSelector, mapDispatchToProps);
