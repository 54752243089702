// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';

const shouldOpenAssignmentsPopup = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();

    const block = contentState.getBlockForKey(startKey);
    const text = block.getText();

    // Must be triggered at the end of a task
    return (
        startOffset === text.length &&
        // and must have a space before it
        text.length > 1 &&
        text[text.length - 1] === ' '
    );
};

export default (char, editorState, eventTimeStamp, { getProps }) => {
    if (char !== '@') return HANDLER_RETURN_VALUES.notHandled;

    if (!shouldOpenAssignmentsPopup(editorState)) return HANDLER_RETURN_VALUES.notHandled;

    const { openAssignmentPopup } = getProps();

    if (openAssignmentPopup) {
        openAssignmentPopup();
        return HANDLER_RETURN_VALUES.handled;
    }

    return HANDLER_RETURN_VALUES.notHandled;
};
