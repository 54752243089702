import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-image"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M22 7a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H10a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h12zm-8 11.06-4.97 4.97a.758.758 0 0 1-.063.057A1.49 1.49 0 0 0 10 23.5h12c.401 0 .765-.157 1.034-.414l-.064-.056L19 19.06l-1.47 1.47a.75.75 0 0 1-1.06 0L14 18.06zm8-9.56H10A1.5 1.5 0 0 0 8.5 10v11.438l4.97-4.968a.75.75 0 0 1 .976-.073l.084.073L17 18.94l1.47-1.47a.75.75 0 0 1 1.06 0l3.97 3.969V10A1.5 1.5 0 0 0 22 8.5zM19 10a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarImageIcon);
export default Memo;
