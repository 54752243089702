// Lib
import { useMemo } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import { defer } from 'lodash';

// Custom extensions
import { Strike } from '../extensions/Strike';
import { Link } from '../extensions/hyperlink/Link';
import { Bold } from '../extensions/Bold';
import { Italic } from '../extensions/Italic';
import { DebouncedSaveCommands } from '../extensions/DebouncedSave';
import { TextStyle } from '../extensions/TextStyle';
import { TextColor } from '../extensions/TextColor';
import { TextHighlight } from '../extensions/TextHighlight';
import { Mention } from '../extensions/mention/Mention';
import { MentionsSuggestionsManager } from '../extensions/mention/suggestionsManager';
import { VirtualSelection } from '../extensions/VirtualSelection';
import { EmptyDelete } from '../extensions/EmptyDelete';
import { Paragraph } from '../extensions/Paragraph';
import { SearchHighlighter } from '../extensions/SearchHighlighter';
import { Focus } from '../extensions/focus/Focus';

// Types
import { Extensions } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { TiptapContent } from '../tiptapTypes';

type UseCommonTiptapEditorExtensionsArgs = {
    saveContent?: (content: TiptapContent) => void;
    onEmptyBackspace?: () => void;
    placeholder?: string;
    suggestionsManager?: MentionsSuggestionsManager;
    onHighlightsChanged?: Function;
    isEditable?: boolean;
    dispatch?: Function;
    clickableLinksWhenEditable?: boolean;
};

/**
 * The extensions common across all Milanote Tiptap element editors.
 */
const useCommonTiptapEditorExtensions = ({
    saveContent,
    onEmptyBackspace,
    placeholder,
    suggestionsManager,
    isEditable,
    dispatch,
    onHighlightsChanged,
    clickableLinksWhenEditable,
}: UseCommonTiptapEditorExtensionsArgs): Extensions =>
    useMemo(() => {
        const onEmptyDelete = (editor: Editor) => {
            // Save the empty content first, before deleting the element, so that the
            //  undo stack is correct
            const emptyContent = editor.getJSON() as TiptapContent;
            saveContent?.(emptyContent);

            // Similar to the onReturn handler, we need to defer the handling of
            //  this to prevent the Mousetrap delete element handler from being triggered
            defer(() => {
                onEmptyBackspace?.();
            });

            return !!onEmptyBackspace;
        };

        return [
            // The standard extensions for Tiptap
            //  Supports: Lists, quotes, code, inline styles, headings, history
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/starterkit
            StarterKit.configure({
                document: false,
                strike: false,
                heading: false,
                paragraph: false,
                codeBlock: false,
                blockquote: false,
                bulletList: false,
                orderedList: false,
                bold: false,
                italic: false,
                horizontalRule: false,
            }),
            Paragraph,
            Strike,
            Bold,
            Italic,
            Underline,
            // This extension is required for the TextColor extension to work
            TextStyle,
            TextColor,
            TextHighlight,
            DebouncedSaveCommands,
            SearchHighlighter.configure({ onHighlightsChanged }),
            VirtualSelection,
            Focus,
            onEmptyBackspace && EmptyDelete.configure({ onEmptyDelete }),
            suggestionsManager && Mention.configure({ suggestion: suggestionsManager }),
            // Show the placeholder text when the editor is empty
            Placeholder.configure({ placeholder, showOnlyWhenEditable: false }),
            // Allows hyperlinks
            //  See: https://tiptap.dev/docs/editor/extensions/marks/link#page-title
            Link.configure({ dispatch, isEditable, clickableLinksWhenEditable }),
        ].filter(Boolean) as Extensions;
    }, [placeholder, suggestionsManager, isEditable, dispatch, saveContent, onEmptyBackspace, onHighlightsChanged]);

export default useCommonTiptapEditorExtensions;
