import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationTableIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 12"
        className="Icon notification-table"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M1 0h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M1 1h14v10H1z" />
            <path fill="#E6E6E6" d="M1 1h14v3H1z" />
            <path fill="#CCC" d="M1 7h14v1H1z" />
            <path fill="#CCC" d="M10 1h1v10h-1zM5 1h1v10H5z" />
        </g>
    </svg>
);
const Memo = memo(NotificationTableIcon);
export default Memo;
