export enum EditorStyleTypes {
    INLINE = 'INLINE',
    BLOCK = 'BLOCK',
}

export const STYLE_COMMANDS = {
    BOLD: 'BOLD',
    ITALIC: 'ITALIC',
    UNDERLINE: 'UNDERLINE',
    LARGE_HEADING: 'header-one',
    HEADING: 'header-two',
    BLOCKQUOTE: 'blockquote',
    CODE: 'CODE',
    CODE_BLOCK: 'code-block',
    STRIKETHROUGH: 'STRIKETHROUGH',
    CENTER_TEXT: 'CENTER-TEXT',
    LIST: 'unordered-list-item',
    ORDERED_LIST: 'ordered-list-item',
    CHECKLIST: 'checklist',
    UNSTYLED: 'unstyled',
    SMALL_TEXT: 'small-text',
    HEADING_PREFIX: 'header',
    // Needs to match the one within the
    HYPERLINK: 'LINK',
    HIGHLIGHT: 'HIGHLIGHT',

    // Text alignment
    ALIGN_CENTER: 'ALIGN_CENTER',
};

export const STYLE_COMMAND_TYPES = {
    [STYLE_COMMANDS.BOLD]: EditorStyleTypes.INLINE,
    [STYLE_COMMANDS.ITALIC]: EditorStyleTypes.INLINE,
    [STYLE_COMMANDS.UNDERLINE]: EditorStyleTypes.INLINE,
    [STYLE_COMMANDS.LARGE_HEADING]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.HEADING]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.BLOCKQUOTE]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.CODE]: EditorStyleTypes.INLINE,
    [STYLE_COMMANDS.CODE_BLOCK]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.STRIKETHROUGH]: EditorStyleTypes.INLINE,
    [STYLE_COMMANDS.LIST]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.ORDERED_LIST]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.SMALL_TEXT]: EditorStyleTypes.BLOCK,

    [STYLE_COMMANDS.CHECKLIST]: EditorStyleTypes.BLOCK,
    [STYLE_COMMANDS.HIGHLIGHT]: EditorStyleTypes.INLINE,
};

export const ENTITIES = {
    LINK: 'LINK',
    CLIP: 'CLIP',
    MENTION: 'mention',
};

export const BLOCK_METADATA = {
    TEXT_ALIGN_CENTER: 'text-align-center',
    IGNORE_FOR_DELETE: 'ignore-for-delete',
    HEADER_TITLE: 'header-title',
};
