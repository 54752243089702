// Lib
import { useEffect } from 'react';

// Hooks
import usePrevious from '../../../../utils/react/usePrevious';

// Constants
import { TOOL_MODES } from '../drawingEditorConstants';
import {
    HYBRID_APP_CUSTOM_DOM_EVENTS,
    PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS,
} from '../../../../hybridApp/store/hybridAppStoreConstants';

/**
 * Listens for a synthetic 'pencildbltap' event and toggles between the erase and draw modes.
 */
export default function usePencilDoubleTapLegacy({ toolMode, setToolMode, setColorPopupOpen }) {
    const previousToolMode = usePrevious(toolMode);

    useEffect(() => {
        const onPencilDoubleTap = (event) => {
            if (event.detail === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.ignore) return;

            if (event.detail === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.showColorPalette) {
                setColorPopupOpen(true);
                return;
            }

            const switchToPrevious =
                !!previousToolMode && event.detail === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.switchPrevious;

            let nextToolMode;

            if (switchToPrevious) {
                nextToolMode = previousToolMode;
            } else {
                nextToolMode = toolMode !== TOOL_MODES.DRAW ? TOOL_MODES.DRAW : TOOL_MODES.ERASE;
            }

            setToolMode(nextToolMode);
        };

        document.addEventListener(HYBRID_APP_CUSTOM_DOM_EVENTS.PENCIL_DOUBLE_TAP, onPencilDoubleTap);

        return () => {
            document.removeEventListener(HYBRID_APP_CUSTOM_DOM_EVENTS.PENCIL_DOUBLE_TAP, onPencilDoubleTap);
        };
    }, [toolMode]);
}
