// Libs
import React from 'react';

// Components
import ToolbarPopupTool from '../ToolbarPopupTool';
import MorePopup from './MoreToolPopup';

// Styles
import { ToolbarItemConfig } from '../../toolbarTypes';
import Icon from '../../../../components/icons/Icon';

const MoreTool = (props: { item: ToolbarItemConfig }): React.ReactElement => {
    return (
        <ToolbarPopupTool
            {...props}
            id={props.item.uid}
            popupId={props.item.uid}
            toolName="more"
            preventFocus
            name=""
            PopupComponent={MorePopup}
        >
            <Icon name="toolbar-more" />
        </ToolbarPopupTool>
    );
};

export default MoreTool;
