'use strict';

exports.__esModule = true;
exports.nameShape = undefined;
exports.transitionTimeout = transitionTimeout;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}

function transitionTimeout(transitionType) {
    var timeoutPropName = 'transition' + transitionType + 'Timeout';
    var enabledPropName = 'transition' + transitionType;

    return function (props) {
        // If the transition is enabled
        if (props[enabledPropName]) {
            // If no timeout duration is provided
            if (props[timeoutPropName] == null) {
                return new Error(
                    timeoutPropName +
                        " wasn't supplied to CSSTransitionGroup: " +
                        "this can cause unreliable animations and won't be supported in " +
                        'a future version of React. See ' +
                        'https://fb.me/react-animation-transition-group-timeout for more ' +
                        'information.',
                );

                // If the duration isn't a number
            } else if (typeof props[timeoutPropName] !== 'number') {
                return new Error(timeoutPropName + ' must be a number (in milliseconds)');
            }
        }

        return null;
    };
}

exports.nameShape = _propTypes2.default.oneOfType([
    _propTypes2.default.string,
    _propTypes2.default.shape({
        enter: _propTypes2.default.string,
        leave: _propTypes2.default.string,
        active: _propTypes2.default.string,
    }),
    _propTypes2.default.shape({
        enter: _propTypes2.default.string,
        enterActive: _propTypes2.default.string,
        leave: _propTypes2.default.string,
        leaveActive: _propTypes2.default.string,
        appear: _propTypes2.default.string,
        appearActive: _propTypes2.default.string,
    }),
]);
