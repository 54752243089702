// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';

// Selectors
import { activePopupSelector } from '../../../../components/popupPanel/popupOpenSelector';
import { getGridSize } from '../../../../utils/grid/gridSizeSelector';

// Actions
import { closePopup } from '../../../../components/popupPanel/popupActions';

// Components
import PopupPanelMouseManager from '../../../../components/popupPanel/PopupPanelMouseManager';
import FloatingPanel from '../../../../components/tooltips/FloatingPanel';

// Constants
import { TooltipPositions } from '../../../../components/tooltips/tooltipConstants';

// Styles
import './TaskPillPopup.scss';

const mapStateToProps = createSelector(
    activePopupSelector,
    (state, ownProps) => ownProps.popupId,
    getGridSize,
    (popupState, popupId, gridSize) => ({
        isPopupOpen: popupState.has(popupId),
        gridSize,
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchClosePopup: (popupId) => dispatch(closePopup(popupId)),
});

const TaskPillPopup = (props) => {
    const { children, className, popupId, isPopupOpen, dispatchClosePopup, gridSize } = props;

    if (!isPopupOpen) return null;

    return (
        <FloatingPanel
            selector={`#${popupId}-target`}
            className={classNames('TaskPillPopup', className, 'light')}
            arrowColor="var(--ui-modal-background-primary)"
            arrowStrokeColor="var(--ui-modal-background-primary)"
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={0}
            arrowStyle={{ transform: 'translate(0px, 0px)' }}
            distance={10}
            offset={0}
            position={TooltipPositions.BOTTOM}
            gridSize={gridSize}
            responsive
            pollPosition
        >
            <PopupPanelMouseManager
                {...props}
                visible={isPopupOpen}
                popupId={popupId}
                ignoredClicksSelector=".Toolbar"
                closePopup={() => dispatchClosePopup(popupId)}
            >
                <div className="popup-content">{children}</div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

TaskPillPopup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    popupId: PropTypes.string.isRequired,
    isPopupOpen: PropTypes.bool,
    dispatchClosePopup: PropTypes.func,
    gridSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPillPopup);
