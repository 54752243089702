// Handlers
import { initialisePushSubscription } from './notifications/push/pushNotificationsManager';

// Utils
import globalLogger, { LoggerComponents } from './logger';
import { sendAmplitudeEvent } from './analytics/amplitudeService';
import platformSingleton from './platform/platformSingleton';
import { isPlatformNativeApp } from './platform/utils/platformDetailsUtils';

const logger = globalLogger.createChannel(LoggerComponents.SERVICE_WORKER_REGISTRATION);

// For now disabling the service worker on Electron and the iPad app as it doesn't work correctly
export const getIsServiceWorkerAvailable = () =>
    'serviceWorker' in navigator && !isPlatformNativeApp(platformSingleton);

const registerServiceWorker = (store) => {
    // Scope is optional here as it will default to the sub-directory of the service worker file
    navigator.serviceWorker
        .register('/sw.js', { scope: '/' })
        .then((reg) => {
            logger.info(`%cSuccessfully registered`, 'color: green', reg);
            initialisePushSubscription(store);
        })
        .catch((err) => {
            logger.error(`Registration failed`, err);
        });
};

export default (store) => {
    if (!getIsServiceWorkerAvailable()) {
        logger.warn(`Not available in this browser`);
        return;
    }

    logger.info(`%cAvailable and will be registered`, 'color: blue');
    registerServiceWorker(store);
};

let beforeInstallEvent = null;

window.addEventListener('beforeinstallprompt', async (event) => {
    event.preventDefault();
    logger.info(`%cCapturing install prompt`, 'color: green');
    beforeInstallEvent = event;
});

export const getPwaInstallEvent = () => beforeInstallEvent;
export const clearPwaInstallEvent = () => {
    beforeInstallEvent = null;
};

window.addEventListener('appinstalled', () => {
    sendAmplitudeEvent({ eventType: 'Installed PWA' });
});
