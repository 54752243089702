// Services
import { InternalAxiosRequestConfig } from 'axios';
import { getWafTokenAndSetHeader } from './awsTokenUtils';

/**
 *  Get the WAF token, and set into header so not relying on the browser to send it as a cookie
 */
export default async (config: InternalAxiosRequestConfig) => {
    await getWafTokenAndSetHeader(config.headers);

    return config;
};
