// Components
import StandardRichTextTool from './StandardRichTextToolContainer';
import HyperlinkRichTextTool from './HyperlinkRichTextToolContainer';
import CenterTextRichTextToolContainer from './CenterTextRichTextToolContainer';
import CodeRichTextToolContainer from './CodeRichTextToolContainer';
import IndentTaskToolContainer from '../taskTools/IndentTaskToolContainer';
import UnindentTaskToolContainer from '../taskTools/UnindentTaskToolContainer';

// Constants
import { STYLE_COMMANDS } from '../../../../../components/editor/richText/richTextConstants';
import { GRID } from '../../../../../utils/grid/gridConstants';

export const TOOL_KEYS = {
    BOLD: 'BOLD',
    ITALIC: 'ITALIC',
    LIST: 'LIST',
    ORDERED_LIST: 'ORDERED_LIST',
    CHECKLIST: 'CHECKLIST',
    HYPERLINK: 'HYPERLINK',
    HIGHLIGHT: 'HIGHLIGHT',
    STRIKETHROUGH: 'STRIKETHROUGH',
    CENTER_TEXT: 'CENTER_TEXT',
    CODE: 'CODE',
    INDENT: 'INDENT',
    UNINDENT: 'UNINDENT',
};

export const INLINE_STYLE_TOOLS = [
    TOOL_KEYS.BOLD,
    TOOL_KEYS.ITALIC,
    TOOL_KEYS.STRIKETHROUGH,
    TOOL_KEYS.HYPERLINK,
    TOOL_KEYS.CENTER_TEXT,
];

export const RICH_STYLE_TOOLS = [
    TOOL_KEYS.BOLD,
    TOOL_KEYS.ITALIC,
    TOOL_KEYS.STRIKETHROUGH,
    TOOL_KEYS.LIST,
    TOOL_KEYS.ORDERED_LIST,
    TOOL_KEYS.CENTER_TEXT,
    TOOL_KEYS.HYPERLINK,
];

export const RICH_TEXT_TOOL_COLLAPSE_ORDER = [
    TOOL_KEYS.STRIKETHROUGH,
    TOOL_KEYS.ORDERED_LIST,
    TOOL_KEYS.ITALIC,
    TOOL_KEYS.LIST,
    TOOL_KEYS.CHECKLIST,
    TOOL_KEYS.BOLD,
    TOOL_KEYS.CENTER_TEXT,
];

export const RICH_TEXT_TOOLS_CONFIG = {
    default: {
        height: {
            [GRID.LARGE.name]: 40,
            [GRID.MEDIUM.name]: 36,
            [GRID.SMALL.name]: 32,
        },
        component: StandardRichTextTool,
    },
    [TOOL_KEYS.BOLD]: {
        name: 'Bold',
        tooltipText: 'Bold',
        shortcut: ['command', 'b'],
        styleCommand: STYLE_COMMANDS.BOLD,
        iconName: 'toolbar-bold',
    },
    [TOOL_KEYS.ITALIC]: {
        name: 'Italic',
        tooltipText: 'Italic',
        // Adding a space to visually shift the 1 across slightly
        shortcut: ['command', ' ', ' ', 'i'],
        styleCommand: STYLE_COMMANDS.ITALIC,
        iconName: 'toolbar-italic',
    },
    [TOOL_KEYS.LIST]: {
        name: 'Bullet',
        tooltipText: 'Bullet',
        shortcut: ['command', 'shift', 'u'],
        styleCommand: STYLE_COMMANDS.LIST,
        iconName: 'toolbar-list',
    },
    [TOOL_KEYS.ORDERED_LIST]: {
        name: 'Numbered list',
        tooltipText: 'Numbered list',
        shortcut: ['command', 'shift', 'o'],
        styleCommand: STYLE_COMMANDS.ORDERED_LIST,
        iconName: 'toolbar-numlist',
    },
    [TOOL_KEYS.HYPERLINK]: {
        name: 'Hyperlink',
        tooltipText: 'Hyperlink',
        shortcut: ['command', 'k'],
        styleCommand: STYLE_COMMANDS.HYPERLINK,
        iconName: 'toolbar-hyperlink',
        component: HyperlinkRichTextTool,
    },
    [TOOL_KEYS.STRIKETHROUGH]: {
        name: 'Strikethrough',
        tooltipText: 'Strikethrough',
        // Adding a space to visually shift the 1 across slightly
        shortcut: ['command', 'shift', 'X'],
        styleCommand: STYLE_COMMANDS.STRIKETHROUGH,
        iconName: 'toolbar-strikethrough',
    },
    [TOOL_KEYS.CODE]: {
        name: 'Code',
        tooltipText: 'Code',
        // Adding a space to visually shift the 1 across slightly
        shortcut: ['command', ' ', ' ', '>'],
        styleCommand: STYLE_COMMANDS.CODE,
        iconName: 'toolbar-code',
        component: CodeRichTextToolContainer,
    },
    [TOOL_KEYS.CENTER_TEXT]: {
        name: 'Align center',
        tooltipText: 'Align center',
        // Adding a space to visually shift the 1 across slightly
        shortcut: ['command', ' ', ' ', '\\'],
        styleCommand: STYLE_COMMANDS.CENTER_TEXT,
        iconName: 'toolbar-center-text',
        component: CenterTextRichTextToolContainer,
    },
    [TOOL_KEYS.INDENT]: {
        name: 'Indent',
        tooltipText: 'Indent',
        iconName: 'toolbar-indent',
        shortcut: ['tab'],
        component: IndentTaskToolContainer,
    },
    [TOOL_KEYS.UNINDENT]: {
        name: 'Unindent',
        tooltipText: 'Unindent',
        iconName: 'toolbar-unindent',
        shortcut: ['shift', 'tab'],
        component: UnindentTaskToolContainer,
    },
};
