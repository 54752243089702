import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { stopPropagationOnly } from '../../utils/domUtil';

import PopupTrigger from '../../components/popupPanel/PopupTrigger';

import ElementReactionPopup from './ElementReactionPopup';
import Icon from '../../components/icons/Icon';

import './ElementReactionAddButton.scss';

const ElementReactionAddButton = ({ className, elementId, onPopupToggleCb }) => {
    const popupId = `reaction-${elementId}`;

    return (
        <div className={classNames('ElementReactionAddButton', className)} onClick={stopPropagationOnly}>
            <PopupTrigger popupId={popupId} onToggle={onPopupToggleCb}>
                {(isOpen) => (
                    <div className={classNames('add-icon', { active: isOpen })}>
                        <Icon name="reaction-add" />
                    </div>
                )}
            </PopupTrigger>

            <ElementReactionPopup popupId={popupId} elementIds={[elementId]} />
        </div>
    );
};

ElementReactionAddButton.propTypes = {
    elementId: PropTypes.string,
    className: PropTypes.string,
    onPopupToggleCb: PropTypes.func,
};

export default ElementReactionAddButton;
