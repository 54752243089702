// Utils
import logger from '../../logger/logger';
import mediaService from '../../utils/services/mediaService';
import { deselectAllElements } from '../../element/selection/selectionActions';
import { manuallyReportError } from '../../analytics/rollbarService';

// Selectors
import {
    getClosestPermissionIdForElementIdSelector,
    getPermissionIdTokenSelector,
} from '../../utils/permissions/permissionsSelector';
import { userLanguagePreferenceSelector } from '../../user/currentUserSelector';

// Analytics
import { mapToOldExportFormat } from '../../../common/export/exportUtils';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';

/**
 * Fetches a board export of the specified format and file type.
 */
export const exportElement =
    ({ elementId, format, fileType, quality, includeChildBoards }) =>
    (dispatch, getState) => {
        dispatch(deselectAllElements());

        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.EXPORTED_DOCUMENT,
            eventProperties: {
                format: mapToOldExportFormat(format, fileType),
            },
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.EXPORTED_DOCUMENT]: true },
            },
        });

        const state = getState();
        const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId });
        const permissionToken = getPermissionIdTokenSelector(state, { permissionId });
        const locale = userLanguagePreferenceSelector(state);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return mediaService.export
            .exportElement({
                elementId,
                format,
                fileType,
                quality,
                permissionId,
                permissionToken,
                includeChildBoards,
                locale,
                timezone,
            })
            .then(({ data }) => {
                const url = data.fileUrl;
                if (!url) throw new Error('No URL returned');

                return url;
            })
            .catch((error) => {
                logger.error('Failed to export element', error);
                manuallyReportError({
                    errorMessage: 'Failed to export element',
                    error,
                    custom: {
                        elementId,
                        format,
                        fileType,
                    },
                });
                return Promise.reject(error);
            });
    };

/**
 * Fetches a ZIP export of all the board media files.
 */
export const exportBoardMedia =
    ({ elementId, sessionId }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId });
            const permissionToken = getPermissionIdTokenSelector(state, { permissionId });

            const data = await mediaService.export.exportBoardMedia({
                boardId: elementId,
                sessionId,
                permissionId,
                permissionToken,
            });

            return data;
        } catch (error) {
            logger.error('Failed to export board', error);
            manuallyReportError({
                errorMessage: 'Failed to export board',
                error,
                custom: {
                    elementId,
                },
            });
            throw error;
        }
    };
