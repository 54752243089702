// Lib
import { Modifier, EditorState } from 'draft-js';

// Utils
import changeCurrentBlockType from '../../customRichUtils/changeCurrentBlockType';
import matchBlockTypeMarkdown from './patternMatching/matchBlockTypeMarkdown';

import getNewLine from '../codeEditor/utils/getNewLine';
import getLines from '../codeEditor/utils/getLines';
import getLineAnchorForOffset from '../codeEditor/utils/getLineAnchorForOffset';
import insertNewline from '../../customRichUtils/editorState/insertNewline';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../../draftjsConstants';
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

const handleEnterCodeBlock = (editorState) => {
    const currentSelection = editorState.getSelection();
    const key = currentSelection.getStartKey();
    const block = editorState.getCurrentContent().getBlockForKey(key);
    const text = block.getText();

    const position = currentSelection.getAnchorOffset();
    const lineBeforeCharacter = text.slice(0, position);

    if (lineBeforeCharacter !== '``') return editorState;

    const lineAfterCharacter = text.slice(position);

    return changeCurrentBlockType(editorState, STYLE_COMMANDS.CODE_BLOCK, lineAfterCharacter);
};

const handleLeaveCodeBlock = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    // This line has an indent at the start, the previous lines indent === this indent.
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    // Detect newline separation
    const newLineChar = getNewLine(blockText);

    const lines = getLines(blockText, newLineChar);

    const currentLineIndex = getLineAnchorForOffset(blockText, startOffset, newLineChar).line;
    const currentLine = lines[currentLineIndex];

    if (currentLine !== '``') return editorState;

    // If there's only a single line, just remove the last two characters
    const offsetToRemove = lines.length > 1 ? 3 : 2;

    // Remove the previous two caret characters
    const rangeToRemove = selection.merge({
        focusKey: startKey,
        focusOffset: startOffset - offsetToRemove,
        anchorKey: startKey,
        anchorOffset: startOffset,
        isBackward: true,
    });
    const newContentState = Modifier.removeRange(contentState, rangeToRemove, DraftRemovalDirection.backward);
    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);

    return insertNewline(newEditorState, STYLE_COMMANDS.UNSTYLED);
};

const handleCodeBlockStyle = (editorState) => {
    const currentSelection = editorState.getSelection();
    const key = currentSelection.getStartKey();
    const block = editorState.getCurrentContent().getBlockForKey(key);
    const blockType = block.getType();

    if (blockType === STYLE_COMMANDS.UNSTYLED) return handleEnterCodeBlock(editorState);
    if (blockType === STYLE_COMMANDS.CODE_BLOCK) return handleLeaveCodeBlock(editorState);

    return editorState;
};

export default (editorState, character) => {
    if (character === '`') return handleCodeBlockStyle(editorState);

    if (character !== ' ') return editorState;

    const currentSelection = editorState.getSelection();
    const key = currentSelection.getStartKey();
    const block = editorState.getCurrentContent().getBlockForKey(key);

    const blockType = block.getType();
    if (
        blockType !== STYLE_COMMANDS.UNSTYLED &&
        blockType !== STYLE_COMMANDS.LIST &&
        blockType !== STYLE_COMMANDS.ORDERED_LIST &&
        blockType !== STYLE_COMMANDS.CHECKLIST
    ) {
        return editorState;
    }

    const text = block.getText();

    const position = currentSelection.getAnchorOffset();
    const lineAfterCharacter = text.slice(position);
    const lineBeforeCharacter = text.slice(0, position);

    const newBlockType = matchBlockTypeMarkdown(lineBeforeCharacter);

    return changeCurrentBlockType(editorState, newBlockType, lineAfterCharacter);
};
