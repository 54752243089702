/**
 * NOTE: Ideally these functions will only be used during initialisation of the platform details singleton.
 */
const isBrowser =
    (testRegex: RegExp) =>
    (userAgent: string): boolean =>
        testRegex.test(userAgent);

export const isFirefoxUserAgent = isBrowser(/Firefox/g);
export const isChromeUserAgent = isBrowser(/Chrome/g);
export const isEdgeUserAgent = isBrowser(/Edg/g);

export const isTabletUserAgent = (userAgent: string) =>
    isBrowser(
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/,
    )(userAgent?.toLowerCase());

export const getUserAgent = (): string => global.navigator?.userAgent || global.window?.navigator?.userAgent || '';
