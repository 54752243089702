// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../../../node_module_clones/recompose';

// Utils
import getEditorJsonWordCount from '../../../../common/tiptap/utils/jsonContentUtils/getEditorJsonWordCount';
import { pluralise } from '../../../../common/utils/stringUtil';

const WordCount = ({ rawState }) => {
    const numberOfWords = rawState ? getEditorJsonWordCount(rawState) : 0;
    return (
        <span className="WordCount">
            {numberOfWords} {pluralise('word', numberOfWords)}
        </span>
    );
};

WordCount.propTypes = {
    rawState: PropTypes.object,
};

export default pure(WordCount);
