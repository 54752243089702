import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { elementClassNames } from '../utils/elementUtil';
import { hasCommandModifier } from '../../utils/keyboard/keyboardUtility';
import { getDisplayMode, getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getMainEditorKey } from '../utils/elementEditorUtils';

// Components
import DocumentDetailView from './documentViews/DocumentDetailView';
import DocumentIconView from './documentViews/DocumentIconView';
import DocumentEditableTitle from './DocumentEditableTitle';

// Constants
import { ELEMENT_DISPLAY_MODES } from '../../../common/elements/elementDisplayModeConstants';

// Actions
import { navigateToElement } from '../../reducers/navigationActions';
import { startEditingElement } from '../selection/selectionActions';
import { handleArrow } from '../../workspace/shortcuts/elementMoveKeyboardShortcutActions';

// Styles
import './Document.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigateToElement: ({ elementId }) => dispatch(navigateToElement({ elementId })),
    dispatchHandleArrowKeys: (event) => dispatch(handleArrow(event)),
    dispatchStartEditingTitle: (element) =>
        dispatch(
            startEditingElement({
                id: getElementId(element),
                editorKey: getMainEditorKey({ element }),
            }),
        ),
});

const Document = (props) => {
    const { element, elementEvents, dispatchNavigateToElement } = props;

    const elementId = getElementId(element);
    const displayMode = getDisplayMode(element);

    const documentClasses = classNames('Document');
    const className = elementClassNames(documentClasses, props);

    const documentElementEvents = {
        ...elementEvents,
        onDoubleClick: (event) => {
            elementEvents.onDoubleClick(event);

            // Don't open full screen when command clicking
            if (hasCommandModifier(event)) return;

            dispatchNavigateToElement({ elementId });
        },
    };

    switch (displayMode) {
        case ELEMENT_DISPLAY_MODES.DETAIL_VIEW:
            return (
                <DocumentDetailView
                    {...props}
                    className={className}
                    elementEvents={documentElementEvents}
                    titleElement={<DocumentEditableTitle {...props} />}
                />
            );
        case ELEMENT_DISPLAY_MODES.ICON_VIEW:
        default:
            return (
                <DocumentIconView
                    {...props}
                    className={className}
                    elementEvents={documentElementEvents}
                    titleElement={<DocumentEditableTitle {...props} />}
                />
            );
    }
};

Document.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    isSingleSelected: PropTypes.bool,
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    currentBoardId: PropTypes.string,
    elementEvents: PropTypes.object,
    isElementOpenInModal: PropTypes.bool,
    moveElementsToTrash: PropTypes.func,

    dispatchHandleArrowKeys: PropTypes.func,
    dispatchNavigateToElement: PropTypes.func,
    dispatchStartEditingTitle: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Document);
