import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MilanoteLogoLargeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={120}
        viewBox="0 0 120 120"
        className="Icon milanote-logo-large"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#31303A" d="M60 0C26.863 0 0 26.863 0 60s26.863 60 60 60 60-26.863 60-60S93.137 0 60 0" />
            <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={6.667}
                d="m36.667 83.333 46.666-46.666v46.666H36.667V36.667l46.666 46.666"
            />
        </g>
    </svg>
);
const Memo = memo(MilanoteLogoLargeIcon);
export default Memo;
