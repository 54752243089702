import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GestureMultiSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={58}
        height={61}
        viewBox="0 0 58 61"
        className="Icon gesture-multi-select"
        {...props}
    >
        <defs>
            <linearGradient id="GestureMultiSelectIcon__a" x1="2535.436%" x2="-42.245%" y1="2667.687%" y2="-47.057%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__b" x1="129.222%" x2="14.223%" y1="68.267%" y2="41.75%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__c" x1="1166.66%" x2="-1411.019%" y1="1176.247%" y2="-1538.5%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__d" x1="64.118%" x2="-4.846%" y1="87.34%" y2="-95.021%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__e" x1="142.115%" x2="-2435.563%" y1="147.267%" y2="-2567.477%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__f" x1="85.786%" x2="-29.213%" y1="58.252%" y2="31.735%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__g" x1="1510.892%" x2="-1066.786%" y1="1638.71%" y2="-1076.037%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="GestureMultiSelectIcon__h" x1="104.842%" x2="35.879%" y1="195.013%" y2="12.652%">
                <stop offset="0%" stopColor="#F37221" />
                <stop offset="86.72%" stopColor="#F37221" stopOpacity={0.48} />
                <stop offset="100%" stopColor="#F37221" stopOpacity={0.4} />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#F3F3F4" d="M15 12h36v20H15z" />
            <g fillRule="nonzero">
                <path
                    fill="url(#GestureMultiSelectIcon__a)"
                    d="M1.231-.516a.703.703 0 0 1 .095 1.4L1.231.89.878.889l.001.417c0 .356-.265.65-.608.697l-.095.007a.703.703 0 0 1-.697-.608l-.006-.096V.186c0-.355.264-.65.607-.696l.096-.006H1.23z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__b)"
                    d="M4.551.89h1.992a.703.703 0 1 0 0-1.406H4.551a.703.703 0 0 0 0 1.406zm5.312 0h1.992a.703.703 0 0 0 0-1.406H9.863a.703.703 0 0 0 0 1.406zm5.312 0h1.992a.703.703 0 0 0 0-1.406h-1.992a.703.703 0 0 0 0 1.406zm5.311 0h1.992a.703.703 0 0 0 0-1.406h-1.992a.703.703 0 0 0 0 1.406zm5.312 0h1.992a.703.703 0 0 0 0-1.406h-1.992a.703.703 0 1 0 0 1.406z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__c)"
                    d="M32.18-.516c.356 0 .65.264.696.607l.007.096v1.12a.703.703 0 0 1-1.4.095l-.006-.096-.001-.417h-.351a.703.703 0 0 1-.697-.607L30.42.187c0-.356.265-.65.608-.697l.096-.006h1.055z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__d)"
                    d="M31.477 5.185v2.19a.703.703 0 0 0 1.406 0v-2.19a.703.703 0 0 0-1.406 0zm0 5.842v2.19a.703.703 0 0 0 1.406 0v-2.19a.703.703 0 0 0-1.406 0zm0 5.842v2.191a.703.703 0 0 0 1.406 0v-2.19a.703.703 0 1 0-1.406 0z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__e)"
                    d="M32.18 23.089c.356 0 .65.264.696.607l.007.096v1.12c0 .355-.265.65-.608.696l-.095.007h-1.055a.703.703 0 0 1-.096-1.4l.096-.007h.351v-.416c0-.356.265-.65.608-.697l.096-.006z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__f)"
                    d="M27.8 24.208h-1.99a.703.703 0 0 0 0 1.407h1.99a.703.703 0 1 0 0-1.407zm-5.311 0h-1.992a.703.703 0 0 0 0 1.407h1.992a.703.703 0 1 0 0-1.407zm-5.312 0h-1.992a.703.703 0 0 0 0 1.407h1.992a.703.703 0 1 0 0-1.407zm-5.311 0H9.874a.703.703 0 0 0 0 1.407h1.992a.703.703 0 1 0 0-1.407zm-5.312 0H4.562a.703.703 0 0 0 0 1.407h1.992a.703.703 0 1 0 0-1.407z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__g)"
                    d="M.176 23.089c.356 0 .65.264.697.607l.006.096-.001.416h.353c.356 0 .65.265.697.608l.006.095c0 .356-.264.65-.608.697l-.095.007H.176a.703.703 0 0 1-.697-.608l-.006-.096v-1.12c0-.388.315-.702.703-.702z"
                    transform="translate(1 1)"
                />
                <path
                    fill="url(#GestureMultiSelectIcon__h)"
                    d="M.879 19.917v-2.19a.703.703 0 0 0-1.406 0v2.19a.703.703 0 0 0 1.406 0zm0-5.842v-2.191a.703.703 0 0 0-1.406 0v2.19a.703.703 0 0 0 1.406 0zm0-5.843v-2.19a.703.703 0 0 0-1.406 0v2.19a.703.703 0 0 0 1.406 0z"
                    transform="translate(1 1)"
                />
            </g>
            <path
                fill="#323B4A"
                d="M57.812 43.911c-.124-1.594-1.657-5.297-2.21-6.301-.777-1.415-1.585-1.857-2.559-1.793-.943.062-1.323.623-1.084 1.645.027.126.162.565.366 1.076a.14.14 0 0 1 .003.029s-.3-.626-.739-1.303a13.067 13.067 0 0 0-.36-.741c-.9-1.75-1.93-2.26-3.114-2.185-.874.058-1.35.468-1.399 1.21-.21 1.007-.076 2.296-.076 2.296s-.335-1.364-.621-2.192c0-.007-.004-.011-.004-.018-.542-2.185-2.193-2.573-3.546-2.484-1.067.072-1.655.753-1.606 1.548 0 .14.01.295.038.46.145.969.169 3.002.169 3.002s-.321-.802-.483-1.202c-1.969-4.844-3.93-9.688-5.92-14.522a13.425 13.425 0 0 0-1.108-2.13c-.64-.994-1.471-.925-2.19.018-1.115 1.472-.12 5.07.273 6.391 1.23 4.121 2.483 8.239 3.861 12.305.553 1.634.663 3.272.629 4.931l-.01.112a5.259 5.259 0 0 1-.754 2.094c-.497.774-1.05.612-1.66-.068-.995-1.101-1.116-3.117-1.185-3.92-.118-1.381.114-2.727-1.471-4.592-.812-.95-1.71-1.263-2.18-1.184-.5.083-.718 1.57-.697 3.477.024 2.152.014 4.311-.038 6.464-.073 2.93.95 5.423 2.587 7.737.98 1.39 2.096 2.62 3.287 3.765l.004-.014s3.457 3.185 10.318 3.177c1.503.011 3.236-.15 5.222-.572 5.702-1.209 7.477-5.618 8.033-8.32.28-1.984.428-3.985.41-5.997a15.852 15.852 0 0 0-.186-2.199z"
            />
        </g>
    </svg>
);
const Memo = memo(GestureMultiSelectIcon);
export default Memo;
