// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import {
    COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
    COLOR_SWATCH_DEFAULT_WIDTH_GRID,
} from '../../../common/colorSwatches/colorSwatchesConstants';
import { GRID } from '../../utils/grid/gridConstants';

export default (DecoratedComponent) => {
    class colorSwatchDoubleClickResizeDecorator extends React.Component {
        handleDoubleClick = (event) => {
            const { setMediaSize } = this.props;

            event.preventDefault();
            event.stopPropagation();

            return setMediaSize({
                width: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_WIDTH_GRID,
                height: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
            });
        };

        render() {
            return <DecoratedComponent {...this.props} handleDoubleClick={this.handleDoubleClick} />;
        }
    }

    colorSwatchDoubleClickResizeDecorator.propTypes = {
        setMediaSize: PropTypes.func,
    };

    return colorSwatchDoubleClickResizeDecorator;
};
