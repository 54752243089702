import { BoardSections } from '../../../common/boards/boardConstants';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';
import { POI_ADD_MULTIPLE, POI_MULTIPLE_SEEN, POI_REMOVE, POI_REMOVE_MULTIPLE, POI_SET, PoiType } from './poiConstants';

export type PointOfInterest = {
    id: string;
    poiType: PoiType;
    currentBoardId: string;
    section: BoardSections;
    targetRect: Rect;
    data: { count: number };
};

export const poiSet = ({ id, poiType, currentBoardId, section, targetRect, data }: PointOfInterest) => ({
    type: POI_SET,
    id,
    currentBoardId,
    section,
    poiType,
    targetRect,
    data,
});

export const poiRemove = ({ id }: { id: string }) => ({ type: POI_REMOVE, id });

export const poiAddMultiple = ({ pois }: { pois: PointOfInterest[] }) => ({ type: POI_ADD_MULTIPLE, pois });

export const poiRemoveMultiple = ({ ids }: { ids: string[] }) => ({ type: POI_REMOVE_MULTIPLE, ids });

export const markPoisAsSeen = ({ ids }: { ids: string[] }) => ({ type: POI_MULTIPLE_SEEN, ids });
