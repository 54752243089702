// Utils
import { createTimestamp } from '../../common/uid/timestamp';
import { getTimestamp } from '../../common/utils/timeUtil';
import randomString from '../../common/uid/randomString';

// Services
import localStorageService from '../utils/services/localStorage/localStorage';

const DEVICE_ID_LOCAL_STORAGE_KEY = 'mdid';

const createDeviceId = () => `mdid-${createTimestamp(getTimestamp())}${randomString(5)}`;

const storeDeviceId = (deviceId) => localStorageService.setItem(DEVICE_ID_LOCAL_STORAGE_KEY, deviceId);
export const retrieveDeviceId = () => localStorageService.getItem(DEVICE_ID_LOCAL_STORAGE_KEY);

export const getDeviceId = () => {
    const persistedDeviceId = retrieveDeviceId();

    if (persistedDeviceId) return persistedDeviceId;

    const deviceId = createDeviceId();
    storeDeviceId(deviceId);

    return deviceId;
};
