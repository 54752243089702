// Lib
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import logger from '../../../logger/logger';
import getClientConfig from '../../getClientConfig';
import { awsWafIntegrationAvailable } from './awsTokenUtils';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

import {
    configureMobileHybridHttp,
    handleMobileHybridHttpRequest,
    hasMobileHybridBridge,
} from './mobileHybridHttpBridge';

// Interceptors
import requestAuthorisationInterceptor from './requestAuthorisationInterceptor';
import requestWafTokenInterceptor from './requestWafTokenInterceptor';
import responseRetryInterceptor from '../../../../common/utils/http/responseRetryInterceptor';
import requestNoCacheInterceptor from './requestNoCacheInterceptor';
import responseChallengeInterceptor from '../../../../common/utils/http/responseChallengeInterceptor';

// Constants
import { METHODS } from '../../../../common/utils/http/httpConstants';

const clientConfig = getClientConfig();
const apiRootUrl = clientConfig.apiRoot || '';

export const DEFAULT_CONFIG: AxiosRequestConfig = {
    method: METHODS.GET,
    timeout: 21000,
    responseType: 'json',
    prefix: `${apiRootUrl}/api`,
    headers: {
        'Accept-Version': clientConfig.appVersion || '1.0.0',
        'Content-Type': 'application/json',
    },
};

// Request interceptors
axios.interceptors.request.use(requestAuthorisationInterceptor);
axios.interceptors.request.use(requestNoCacheInterceptor);
axios.interceptors.request.use(requestWafTokenInterceptor);

if (isPlatformLegacyMobileApp(platformSingleton)) {
    configureMobileHybridHttp();
}

// Response interceptors
const retryErrorCb = (err: AxiosError) =>
    logger.warn(
        `RETRYING: A http error occurred (${(err.config?.retry || 0) - (err.config?._retryCount || 0)} ` +
            'attempts remaining)',
        err,
        err.response,
        JSON.stringify(err),
    );
const retry = responseRetryInterceptor(axios, retryErrorCb);
axios.interceptors.response.use(responseChallengeInterceptor(awsWafIntegrationAvailable, retry), retry);

export const http = async <BodyType = any, ResponseType = any>(
    config: AxiosRequestConfig<BodyType>,
): Promise<AxiosResponse<ResponseType, BodyType>> => {
    const axiosConfig: AxiosRequestConfig = {
        ...DEFAULT_CONFIG,
        ...config,
        headers: {
            ...DEFAULT_CONFIG.headers,
            ...config.headers,
        },
    };

    axiosConfig.url = `${axiosConfig.prefix}/${config.url}`;

    if (isPlatformLegacyMobileApp(platformSingleton) && hasMobileHybridBridge) {
        return handleMobileHybridHttpRequest(axiosConfig);
    }

    return axios(axiosConfig);
};
