// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../../../node_module_clones/recompose';

// Components
import Icon from '../../../components/icons/Icon';

// Styles
import './BoardShortcutIcon.scss';

const getStyle = (size) => {
    if (!size) return null;

    return {
        fontSize: `${size / 7.2 || 1}em`,
    };
};

const BoardShortcutIcon = (props) => {
    const { isAlias, gridSize, size } = props;

    if (!isAlias) return null;

    return (
        <div className="BoardShortcutIcon" style={getStyle(size)}>
            <Icon name="board-shortcut" gridSize={gridSize} />
        </div>
    );
};

BoardShortcutIcon.propTypes = {
    isAlias: PropTypes.bool,
    gridSize: PropTypes.number,
    size: PropTypes.number,
};

export default pure(BoardShortcutIcon);
