import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ExternalLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon external-link"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M7.5 4a.5.5 0 0 1 0 1H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm6-2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V3.707L7.078 9.625a.497.497 0 0 1-.703-.703L12.293 3H9.5a.5.5 0 0 1 0-1z"
        />
    </svg>
);
const Memo = memo(ExternalLinkIcon);
export default Memo;
