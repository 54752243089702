import { MNSubscriptionProvider } from '../subscriptions/subscriptionTypes';

export enum ProductLocaleTextKey {
    PROFESSIONAL = 'professional',
    TEAM = 'team',
    FREE = 'free',
}

export enum ProductStatus {
    PUBLIC = 'public', // visible to users, able to be purchased
    PRIVATE = 'private', // not publicly visible to users, but able to be purchased
    ARCHIVED = 'archived', // not visible to users, not able to be purchased
}

export enum ProductPricingInterval {
    DAY = 'day',
    MONTH = 'month',
    WEEK = 'week',
    YEAR = 'year',
}

export enum ProductPricingScheme {
    TIERED = 'tiered',
    SEAT = 'seat',
    FREE = 'free',
}

export type CheckoutPrice = {
    _id: string;
    productId: string;

    description: string; // internal description, not user facing

    status: ProductStatus;

    provider: MNSubscriptionProvider;
    providerPriceId: string;

    amount: number;
    currency: string;
    interval: ProductPricingInterval;

    // if product.pricingScheme === 'tiered'
    maxSeats?: number;

    text: {
        checkoutDescription?: string; // user facing description (eg. "Up to 10 people")
        checkoutSavings?: string;
        displaySavingsInSummary?: boolean;
    };
};

/**
 * Product stored in the database
 * */
export type CheckoutProductFormModel = {
    _id: string;
    slug: string; // user-friendly, unique identifier for the product

    name: string; // display name, user facing (eg. "You're on the *Pro* plan")
    description: string; // internal description, not user facing

    status: ProductStatus;
    pricingScheme: ProductPricingScheme; // how the product is priced
    localeTextKey: ProductLocaleTextKey; // used to look up user facing text from locale json files

    // optional settings
    settings: {
        // if true, non-admin team members will not be able to be seen by other team members
        // useful for products used by larger teams
        hideNonAdminTeamMembers?: boolean;
    };
};

/**
 * Product stored in the database
 * */
export type CheckoutProductDBModel = CheckoutProductFormModel & {
    createdAt: Date;
    updatedAt: Date;
};

/**
 * Product returned to the client
 */

export type CheckoutProduct = CheckoutProductDBModel & {
    prices: CheckoutPrice[];
};
