// Constants
import { ATTACH_MODE_START, ATTACH_MODE_END } from './dndConstants';

export const startAttachMode = (hoveredElementId, attachModeType) => ({
    type: ATTACH_MODE_START,
    hoveredElementId,
    attachModeType,
});
export const endAttachMode = (hoveredElementId) => ({
    type: ATTACH_MODE_END,
    hoveredElementId,
});
