import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationDocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-document"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#CCC"
                d="M2 2.007C2 1.45 2.453 1 2.997 1H10l4 4v9.005c0 .55-.456.995-1.002.995H3.002C2.45 15 2 14.55 2 13.993V2.007Z"
            />
            <path fill="#FFF" d="M9 2v4h4v8H3V2h6Zm3.5 3H10V2.501L12.5 5Z" />
            <path
                fill="#767C86"
                d="M10.509 10a.5.5 0 0 1 .483.412L11 10.5a.5.5 0 0 1-.491.5H5.491a.5.5 0 0 1 0-1h5.018Zm0-2a.5.5 0 0 1 .483.412L11 8.5a.5.5 0 0 1-.491.5H5.491a.5.5 0 0 1 0-1h5.018Z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationDocumentIcon);
export default Memo;
