// Lib
import { createSelector } from 'reselect';
import { negate } from 'lodash/fp';

// Utils
import { isEmpty, prop, propIn } from '../../../../../common/utils/immutableHelper';
import { createDeepSelector, createShallowSelector } from '../../../../utils/milanoteReselect/milanoteReselect';
import { filterAssignmentSuggestions } from '../../../../components/editor/plugins/mentions/filterSuggestions';

// Selectors
import { getUserMentionSuggestions } from '../../../../components/editor/plugins/mentions/mentionsSelector';
import { getElement } from '../../../selectors/elementSelector';
import { getAssignments } from '../../../../../common/elements/utils/elementPropertyUtils';

export const getCurrentlyAssignedUserId = createSelector(getElement, (element) => {
    const assignments = getAssignments(element);

    return isEmpty(assignments) ? null : propIn([0, 'assigneeId'], assignments);
});

export const getFilteredSuggestions = createDeepSelector(
    (state, { searchTerm }) => searchTerm,
    getCurrentlyAssignedUserId,
    getUserMentionSuggestions,
    filterAssignmentSuggestions,
);

export const getEnabledSuggestions = createShallowSelector(getFilteredSuggestions, (suggestions) =>
    suggestions.filter(negate(prop('disabled'))),
);

export const getDisabledSuggestions = createShallowSelector(getFilteredSuggestions, (suggestions) =>
    suggestions.filter(prop('disabled')),
);
