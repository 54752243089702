import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { elementClassNames, getDomElementId } from '../utils/elementUtil';

// Components
import ColorBar from '../../components/colors/ColorBar';
import LinkHeader from './LinkHeader';
import LinkEditor from './LinkEditor';
import LinkMetadata from './LinkMetadata';
import Caption from '../../components/caption/Caption';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import LinkMedia from './richMedia/LinkMedia';
import ElementResizeHandle from '../resizing/ElementResizeHandle';
import ElementActivityIndicator from '../activity/ElementActivityIndicator';
import QuickNotesActivityIndicator from '../activity/QuickNotesActivityIndicator';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import ElementReactions from '../reactions/ElementReactions';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';

// Helpers
import { getElementStyle } from '../../utils/grid/gridUtils';
import {
    getCaption,
    getColor,
    getElementId,
    getImageProp,
    getLinkUrl,
    getMediaType,
    getShowCaption,
    getShowMedia,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { RESIZEABLE_MEDIA } from '../../../common/links/richMediaConstants';

// Styles
import './Link.scss';
import { useSelector } from 'react-redux';
import { getIsReplaceModeHovered } from '../../reducers/draggingSelector';

const Link = (props) => {
    const {
        currentBoardId,
        elementId,
        element,
        inList,
        gridSize,
        elementEvents,
        tempSize,
        isLocked,
        isEditable,
        isSelected,
        isPresentational,
        permissions,
        setParentHoveredChildAcceptsDrop,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const colorName = getColor(element);
    const captionContent = getCaption(element);
    const captionVisible = getShowCaption(element);

    const isReplaceModeHovered = useSelector((state) => getIsReplaceModeHovered(state, { elementId }));

    const isLoaded = !!getLinkUrl(element);

    const mediaType = getMediaType(element);
    // There's a bug where some links don't have a media type but do have an image
    const hasImageMedia = !!getImageProp(element);

    const showMedia = getShowMedia(element);
    const isResizableMedia = showMedia && (RESIZEABLE_MEDIA.indexOf(mediaType) !== -1 || (!mediaType && hasImageMedia));

    const canResize = isResizableMedia && !isLocked && isEditable && !inList;
    const savedElementSize = getWidth(element);
    const elementWidth = isResizableMedia
        ? (tempSize && tempSize.width) || savedElementSize || ELEMENT_DEFAULT_WIDTH
        : ELEMENT_DEFAULT_WIDTH;

    const linkClasses = classNames('Link drag-handle', { 'contrast-shadow': !showMedia || !isLoaded });
    const classes = elementClassNames(linkClasses, { ...props, isReplaceModeHovered });

    return (
        <div
            id={getDomElementId(getElementId(element))}
            className={classes}
            style={getElementStyle(elementWidth, gridSize, inList)}
            {...elementEvents}
        >
            <LinkMedia {...props} width={elementWidth} />
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementActivityIndicator {...props} />
            <QuickNotesActivityIndicator {...props} />
            <ElementUserTag {...props} />
            <LinkEditor {...props} />
            <LinkHeader {...props} />
            <LinkMetadata {...props} captionVisible={captionVisible} />
            <Caption
                {...props}
                placeholder="Add a description"
                textContent={captionContent}
                captionVisible={captionVisible}
            />
            <ElementReactions {...props} />
            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                isRelative
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                elementId={elementId}
                element={element}
                gridSize={gridSize}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
            <ElementResizeHandle {...props} showHandle={canResize} />
        </div>
    );
};

Link.propTypes = {
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isHovered: PropTypes.bool,
    startEditing: PropTypes.func,
    setEditHandler: PropTypes.func,
    stopEditing: PropTypes.func,
    saveContent: PropTypes.func,
    handleKey: PropTypes.func,
    className: PropTypes.string,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    isLocked: PropTypes.bool,
    isEditable: PropTypes.bool,
    isPresentational: PropTypes.bool,
    permissions: PropTypes.number,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default Link;
