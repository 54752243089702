import { escapeRegExp } from 'lodash/fp';

export enum QueryMode {
    PHRASE,
    WORD,
}

const CHARACTER_MAPPINGS: { [key: string]: string } = {
    // diacritic alias mappings
    a: String.fromCharCode(65, 97, 192, 224, 193, 225, 194, 226, 195, 227, 196, 228),
    e: String.fromCharCode(69, 101, 200, 232, 201, 233, 202, 234, 203, 235),
    i: String.fromCharCode(73, 105, 204, 236, 205, 237, 206, 238, 207, 239),
    o: String.fromCharCode(79, 111, 210, 242, 211, 243, 212, 244, 213, 245, 214, 246),
    n: String.fromCharCode(78, 110, 209, 241),
    u: String.fromCharCode(85, 117, 217, 249, 218, 250, 219, 251, 220, 252),
    c: String.fromCharCode(67, 99, 199, 231),
    y: String.fromCharCode(89, 121, 221, 253, 159, 255),
};

const splitWords = (query: string, queryMode: QueryMode) => {
    const trimmedQuery = query.trim();
    const words = trimmedQuery.split(/ +/).filter((word) => word.trim().length > 0);

    if (words.length <= 1) return trimmedQuery;

    const joinedWords = queryMode === QueryMode.PHRASE ? words.join(' ') : words.join('|');

    return `(?:${joinedWords})`;
};

const mapDiacritics = (query: string) =>
    query
        .split('')
        .map((char) => (CHARACTER_MAPPINGS[char.toLowerCase()] ? `[${CHARACTER_MAPPINGS[char.toLowerCase()]}]` : char))
        .join('');

export const prepareQuery = (query: string, queryMode: QueryMode = QueryMode.WORD): string => {
    const escapedQuery = escapeRegExp(query);
    const mappedQuery = mapDiacritics(escapedQuery);
    return splitWords(mappedQuery, queryMode);
};

export default (query: string, queryMode: QueryMode = QueryMode.WORD, flags = 'i'): RegExp =>
    new RegExp(prepareQuery(query, queryMode), flags);
