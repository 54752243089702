// Constants
import { CUSTOM_HTTP_HEADERS, METHODS } from '../../../utils/http/httpConstants';
import { TIMES } from '../../../utils/timeUtil';

export default (mediaHttp) => ({
    exportElement: ({
        elementId,
        format,
        fileType,
        quality,
        permissionId,
        permissionToken,
        userToken,
        includeChildBoards,
        locale,
        timezone,
    }) => {
        const requestConfig = {
            url: `export/${elementId}`,
            method: METHODS.POST,
            timeout: 2 * TIMES.MINUTE,
            data: {
                format,
                fileType,
                quality,
                permissionId,
                includeChildBoards,
                locale,
                timezone,
            },
            headers: {},
        };

        if (permissionToken) {
            requestConfig.headers[CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS] = permissionToken;
        }

        // Allow the server to provide the correct user token
        if (userToken) {
            requestConfig.headers.Authorization = `Bearer ${userToken}`;
        }

        return mediaHttp(requestConfig);
    },
    bulkExportBoard: ({ userId, boardId, format, fileType, quality }) => {
        const requestConfig = {
            url: `export/${boardId}/bulk`,
            method: METHODS.POST,
            timeout: 10 * TIMES.SECOND,
            data: {
                userId,
                format,
                fileType,
                quality,
            },
            headers: {},
        };

        return mediaHttp(requestConfig);
    },
    exportBoardMedia: ({ boardId, sessionId, permissionId, permissionToken }) => {
        const requestConfig = {
            url: `export/${boardId}/zip`,
            method: METHODS.POST,
            timeout: 10 * TIMES.SECOND,
            data: { sessionId, permissionId },
            headers: {},
        };

        if (permissionToken) {
            requestConfig.headers[CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS] = permissionToken;
        }

        return mediaHttp(requestConfig);
    },
});
