import React from 'react';
import PropTypes from 'prop-types';
import { FormatOptions } from '../../../../common/table/CellTypeConstants';
import { getCurrencyList } from '../../../utils/currencyUtils';
import Icon from '../../../components/icons/Icon';
import { getCurrencyDisplayOptions } from '../../../../common/table/utils/tableCellFormattingUtils';

const CurrencyFormatOptions = (props) => {
    const { sharedFormattingValues, updateCellTypeFormat, locale } = props;
    const { currency, currencyDisplay } = sharedFormattingValues;

    return (
        <div className="CurrencyFormatOptions celltype-format-section">
            <div className="celltype-format-title">Currency:</div>
            <div className="celltype-format-inputs">
                <div className="dropdown-container celltype-format-input">
                    <select
                        value={currency || ''}
                        onChange={(e) => updateCellTypeFormat({ [FormatOptions.CURRENCY]: e.target.value })}
                        className="dropdown"
                    >
                        <option value="" disabled>
                            Select currency
                        </option>
                        {getCurrencyList(locale).map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </select>
                    <div className="arrow-icon-container">
                        <Icon name="dropdown-arrow" />
                    </div>
                </div>
                {currency && (
                    <div className="dropdown-container celltype-format-input">
                        <select
                            value={currencyDisplay}
                            onChange={(e) => updateCellTypeFormat({ [FormatOptions.CURRENCY_DISPLAY]: e.target.value })}
                            className="dropdown"
                        >
                            {getCurrencyDisplayOptions(currency, locale).map((displayOption, i) => (
                                <option key={i} value={displayOption.option}>
                                    {displayOption.value}
                                </option>
                            ))}
                        </select>
                        <div className="arrow-icon-container">
                            <Icon name="dropdown-arrow" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

CurrencyFormatOptions.propTypes = {
    sharedFormattingValues: PropTypes.object,
    updateCellTypeFormat: PropTypes.func,
    locale: PropTypes.string,
};

export default CurrencyFormatOptions;
