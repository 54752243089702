// Lib
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Actions
import { fetchCurrentUser, setCurrentUserGuest } from '../../user/currentUserActions';

/**
 * This component is the same as the WorkspaceInitialisationDecorator, however it doesn't run the
 * initialiseFullWorkspace action because that's only needed when the full app workspace is being
 * rendered, not for previews or redirects.
 */
export default (DecoratedComponent) => {
    const WorkspaceUserOnlyInitialisationDecorator = (props) => {
        const { isUserAvailable, isGuest } = props;

        const dispatch = useDispatch();
        const initialiseCurrentUser = () => dispatch(fetchCurrentUser());
        const setGuestUser = () => dispatch(setCurrentUserGuest());

        useEffect(() => {
            if (!isUserAvailable) initialiseCurrentUser();
            if (isGuest) setGuestUser();
        }, [isGuest]);

        return <DecoratedComponent {...props} />;
    };

    WorkspaceUserOnlyInitialisationDecorator.propTypes = {
        isGuest: PropTypes.bool,
        isUserAvailable: PropTypes.bool,
    };

    return WorkspaceUserOnlyInitialisationDecorator;
};
