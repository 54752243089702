import {
    AUTOUPDATE_DOWNLOAD_START,
    AUTOUPDATE_DOWNLOAD_COMPLETE,
    AUTOUPDATE_DOWNLOAD_PROGRESS,
    AUTOUPDATE_CHECK_START,
    AUTOUPDATE_CHECK_COMPLETE,
    AUTOUPDATE_UPDATE_INSTALL,
    AUTOUPDATE_FORCE_POLL,
    AUTOUPDATE_ERROR,
    ELECTRON_APP_SLEEP,
    ELECTRON_APP_WAKE,
    TOGGLE_SPELL_CHECK,
    ELECTRON_STORE_REHYDRATED,
    ELECTRON_APP_CLOSE_MESSAGE_SET,
    ELECTRON_APP_CLOSE_MESSAGE_CLEAR,
} from './electronConstants';

export const toggleSpellCheck = ({ status }) => ({
    type: TOGGLE_SPELL_CHECK,
    status,
});

export const autoUpdateAvailable = () => ({
    type: AUTOUPDATE_DOWNLOAD_START,
});

export const autoUpdateComplete = ({ version }) => ({
    version,
    type: AUTOUPDATE_DOWNLOAD_COMPLETE,
});

export const autoUpdateProgress = ({ progress }) => ({
    type: AUTOUPDATE_DOWNLOAD_PROGRESS,
    progress,
});

export const autoUpdateInstallUpdate = () => ({
    type: AUTOUPDATE_UPDATE_INSTALL,
});

export const autoUpdateCheckForUpdates = () => ({
    type: AUTOUPDATE_CHECK_START,
});

export const autoUpdateCheckComplete = () => ({
    type: AUTOUPDATE_CHECK_COMPLETE,
});

export const autoUpdateForcePoll = () => ({
    type: AUTOUPDATE_FORCE_POLL,
});

export const autoUpdateError = ({ error }) => ({
    type: AUTOUPDATE_ERROR,
    error,
});

export const electronAppSleep = () => ({
    type: ELECTRON_APP_SLEEP,
});

export const electronAppWake = () => ({
    type: ELECTRON_APP_WAKE,
});

export const electronStoreRehydrated = () => ({
    type: ELECTRON_STORE_REHYDRATED,
});

// App close warning messages
export const electronSetCloseMessage = ({ messageType = 'warning', title, message, ...rest }) => ({
    type: ELECTRON_APP_CLOSE_MESSAGE_SET,
    messageType,
    title,
    message,
    ...rest,
});

export const electronClearCloseMessage = () => ({
    type: ELECTRON_APP_CLOSE_MESSAGE_CLEAR,
});
