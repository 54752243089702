import hashString from '../../../../common/utils/hashString';

import { ToolbarItemConfig } from '../toolbarTypes';

/**
 * Returns a unique hash for the given toolbar items.
 * This is used to determine if the toolbar list has changed.
 * */
export const getToolbarHash = (items: ToolbarItemConfig[]): number => {
    const itemIds = items.map((item) => item.id).join('');
    return hashString(itemIds);
};

/**
 * Determines if two JS sets are equal.
 */
export const getSetsEqual = (setA: Set<string>, setB: Set<string>): boolean => {
    if (setA.size !== setB.size) return false;
    return [...setA].every((value) => setB.has(value));
};
