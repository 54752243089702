// Lib
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { DOM_POINTER_TYPES } from '../../../../utils/domConstants';

// Styles
import './DrawingEditorToolbarIPadPenTools.scss';

const DrawingEditorToolbarIPadPenTools = (props) => {
    const { isPenMode, setIsPenMode } = props;
    const resetPenModeTimeout = useRef();

    useEffect(() => {
        const onPointerMove = (e) => {
            if (e.pointerType !== DOM_POINTER_TYPES.PEN) return;

            setIsPenMode(true);
        };

        const resetPenMode = () => {
            setIsPenMode(false);
        };

        const onPointerUp = (e) => {
            if (e.pointerType !== DOM_POINTER_TYPES.PEN) return;

            clearTimeout(resetPenModeTimeout.current);
            resetPenModeTimeout.current = setTimeout(resetPenMode, 2000);
        };

        document.addEventListener('pointermove', onPointerMove);
        document.addEventListener('pointerup', onPointerUp);

        return () => {
            document.removeEventListener('pointermove', onPointerMove);
            document.removeEventListener('pointerup', onPointerUp);

            clearTimeout(resetPenModeTimeout.current);
        };
    }, [isPenMode]);

    return null;
};

DrawingEditorToolbarIPadPenTools.propTypes = {
    toolMode: PropTypes.string,
    isPenMode: PropTypes.bool,
    setIsPenMode: PropTypes.func,
};

export default DrawingEditorToolbarIPadPenTools;
