// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { startAnnotationMode } from '../../../../../element/drawing/annotation/store/annotationActions';
import { deselectAllElements } from '../../../../../element/selection/selectionActions';
import { navigateToElement } from '../../../../../reducers/navigationActions';

// Utils
import {
    getElementId,
    getElementType,
    getHasDrawing,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isAnnotation, isImage, isSketch } from '../../../../../../common/elements/utils/elementTypeUtils';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';

const mapDispatchToProps = (dispatch) => ({
    dispatchEditAnnotationElement: (elementId) => dispatch(startAnnotationMode({ elementId })),
    dispatchDeselectAndNavigateToElement: (elementId, elementType) => {
        dispatch(deselectAllElements({ sync: false }));

        const params = !isSketch(elementType) ? { 'editing-drawing': true } : null;

        dispatch(navigateToElement({ elementId, params }));
    },
});

const EditDrawingTool = (props) => {
    const { name, selectedElements, dispatchEditAnnotationElement, dispatchDeselectAndNavigateToElement } = props;

    let toolName = name;
    const firstElement = selectedElements?.first();

    if (isImage(firstElement) && !getHasDrawing(firstElement)) {
        toolName = 'Draw on top';
    }

    const onClick = () => {
        const elementId = getElementId(firstElement);

        if (!elementId) return;

        isAnnotation(firstElement)
            ? dispatchEditAnnotationElement(elementId)
            : dispatchDeselectAndNavigateToElement(elementId, getElementType(firstElement));
    };

    return (
        <ToolbarTool {...props} onClick={onClick} name={toolName}>
            <Icon {...props} name="toolbar-draw" />
        </ToolbarTool>
    );
};

EditDrawingTool.propTypes = {
    name: PropTypes.string,
    show: PropTypes.bool,
    isActive: PropTypes.bool,
    selectedElements: PropTypes.object.isRequired,

    dispatchEditAnnotationElement: PropTypes.func,
    dispatchDeselectAndNavigateToElement: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(EditDrawingTool);
