import { createStore, applyMiddleware } from 'redux';

import getMiddleware from './getMiddleware';
import remoteStoreMiddleware from './remoteStoreMiddleware';
import hybridAppMiddleware from '../hybridApp/store/hybridAppMiddleware';
import getElectronInitialState from './getElectronInitialState';

import rootReducer from '../reducers';

import { enableReducerBatching } from './reduxBulkingMiddleware';
import { electronStoreRehydrated } from '../../common/electron/electronActions';

export default function configureStore(initialState, thunkArg) {
    // electronInitialState is the last saved redux state that is passed from the electron main process on page refresh
    const electronInitialState = getElectronInitialState();
    const state = electronInitialState || initialState;

    const middlewareList = [...getMiddleware({ thunkArg }), remoteStoreMiddleware, hybridAppMiddleware];

    const middleware = applyMiddleware.apply(null, middlewareList);

    const store = createStore(enableReducerBatching(rootReducer), state, middleware);

    store.dispatch(electronStoreRehydrated());

    return store;
}
