// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import * as elementService from '../elementService';
import { isClone } from '../../../common/elements/utils/elementTypeUtils';
import * as elementRegistry from '../../../common/elements/elementRegistry';
import { getElementType } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import cloneSelector from './cloneSelector';

// Components
import ElementPreviewPresentational from '../ElementPreviewPresentational';
import ElementPresentational from '../ElementPresentational';
import Spinner from '../../components/loaders/Spinner';

// Styles
import './Clone.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchElement: (elementId) => dispatch(elementService.fetchElements({ elementIds: [elementId] })),
});

let MissingClonedElement = (props) => {
    const { originalElementId, dispatchFetchElement } = props;

    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        originalElementId &&
            dispatchFetchElement(originalElementId).catch((err) => {
                setIsError(true);
                return Promise.reject(err);
            });
    }, [originalElementId]);

    if (isError) {
        return <div className="ErrorClonedElement">Error loading clone</div>;
    }

    return (
        <div className="MissingClonedElement">
            <Spinner show />
        </div>
    );
};

MissingClonedElement.propTypes = {
    originalElementId: PropTypes.string,
    dispatchFetchElement: PropTypes.func,
};

MissingClonedElement = connect(null, mapDispatchToProps)(MissingClonedElement);

const getDisplayElement = (props) => {
    const { isPreview, isPresentational, originalElement } = props;

    // If we've somehow ended up in a scenario where a clone has been cloned, then we want to bail
    // out, otherwise we'd be in an infinite loop.
    if (isClone(originalElement)) throw new Error('Cannot clone a clone');

    if (isPreview) return ElementPreviewPresentational;
    if (isPresentational) return ElementPresentational;

    return elementRegistry.getElementContainerComponent(getElementType(originalElement));
};

const Clone = (props) => {
    const { elementId, element, originalElementId, originalElement, renderedElement } = props;

    if (!originalElement) return <MissingClonedElement {...props} />;

    const DisplayElement = getDisplayElement(props);

    return (
        <DisplayElement
            {...props}
            isClone
            elementId={elementId}
            element={renderedElement}
            originalCloneElement={element}
            originalElementId={originalElementId}
            originalElement={originalElement}
        />
    );
};

Clone.propTypes = {
    elementId: PropTypes.string,
    originalElementId: PropTypes.string,
    element: PropTypes.object,
    originalElement: PropTypes.object,
    renderedElement: PropTypes.object,
};

export default connect(cloneSelector)(Clone);
