// Utils
import getEntityAtPoint from './getEntityAtPoint';
import getClosestEntityRange from './getClosestEntityRange';

/**
 * For a specified editor point ({ key, offset }) this will return the entityKey, entity object and
 * entityRange ({ startKey, startOffset, endKey, endOffset }) at that point, or an empty object if none.
 */
export default (editorState, { key, offset }) => {
    const { entityKey, entity } = getEntityAtPoint(editorState, { key, offset });

    if (!entityKey || !entity) return {};

    // TODO Try to improve this algorithm.
    //  Currently it finds all ranges and then chooses the one that matches this point.
    //  But it would be more efficient to just look ahead and behind of this point until the range ends.
    const range = {
        startKey: key,
        startOffset: offset,
        endKey: key,
        endOffset: offset,
    };
    const entityRange = getClosestEntityRange(editorState, range, entityKey);

    return {
        entityKey,
        entity,
        entityRange,
    };
};
