// Lib
import { trim } from 'lodash';
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

export default (predicateFn) => (contentState) => {
    const blockMap = contentState.getBlockMap();

    if (blockMap.size !== 1) return false;

    const firstBlockType = blockMap.first().getType();
    if (firstBlockType !== STYLE_COMMANDS.UNSTYLED) return false;

    const text = trim(contentState.getPlainText());
    return predicateFn(text);
};
