// Lib
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Util
import { getResponsiveImageSize, getImageSource } from '../../element/image/imageHelper';
import logger from '../../logger/logger';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../../element/feature/elementFeatureSelector';
import { isBoardPreviewEnabledSelector } from '../../workspace/boardPreview/boardPreviewSelector';

// Constants
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

const mapStateToProps = createStructuredSelector({
    isBoardPreview: isBoardPreviewEnabledSelector,
    renderSecureImagesThroughProxy: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughProxy),
    // Default to enabled for guest users
    renderSecureImagesThroughCF: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughCF, true),
});

/**
 * This component renders the appropriately sized image based on the size of the image and the image sizes available
 * to select from.
 */
const ResponsiveImageFACC = ({
    imageType,
    imageDetails,
    widthPx,
    imageQualityWidthPxOverride,
    heightPx,
    children,
    elementId,
    isBoardPreview,
    useSecureMediaUrl = false,
    renderSecureImagesThroughProxy,
    renderSecureImagesThroughCF,
    isPreview,
}) => {
    if (!imageDetails) return null;

    const qualityWidthPx = imageQualityWidthPxOverride || widthPx;

    // Get the best image size ('original', 'large', 'regular') for the width and height given
    const imageSize = getResponsiveImageSize({ widthPx: qualityWidthPx, heightPx, imageDetails, imageType });

    const imageSource = getImageSource({
        imageSize,
        imageDetails,
        elementId,

        // Do not use secure media url if in board preview mode, this will be handled in the future
        useSecureMediaUrl: useSecureMediaUrl && !isBoardPreview,

        renderSecureImagesThroughProxy,
        renderSecureImagesThroughCF,

        isPreview,
    });

    if (!imageSource) {
        logger.warn(`Expected a source '${imageSource}' but unable to find it`, imageSource);
        return null;
    }

    return children(imageSource);
};

ResponsiveImageFACC.propTypes = {
    imageType: PropTypes.string.isRequired,
    imageDetails: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired,
    widthPx: PropTypes.number.isRequired,
    imageQualityWidthPxOverride: PropTypes.number,
    heightPx: PropTypes.number,
    elementId: PropTypes.string,
    isBoardPreview: PropTypes.bool,
    useSecureMediaUrl: PropTypes.bool,
    renderSecureImagesThroughProxy: PropTypes.bool,
    renderSecureImagesThroughCF: PropTypes.bool,
    isPreview: PropTypes.bool,
};

export default connect(mapStateToProps)(ResponsiveImageFACC);
