// Utils
import globalLogger, { LoggerComponents } from '../../logger';
import { NewRelicPageActions, sendNewRelicPageAction } from '../newRelicUtils';
import * as analyticsTimingServiceAttributeManager from './analyticsTimingServiceAttributeManager';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';

// Types
import { ReduxStore } from '../../types/reduxTypes';

export type AnalyticsTimingServiceOperation =
    | NewRelicPageActions.LOCAL_CACHE_KEYVAL_IDB_INIT
    | NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE
    | NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_CACHE_OBJECT_RETRIEVAL
    | NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_GET_PERSISTED_STATE
    | NewRelicPageActions.ELEMENT_CREATION
    | NewRelicPageActions.BOARD_NAVIGATION;

const MAX_OPERATION_LENGTHS = {
    [NewRelicPageActions.LOCAL_CACHE_KEYVAL_IDB_INIT]: 60 * TIMES.SECOND,
    [NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE]: 60 * TIMES.SECOND,
    [NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_CACHE_OBJECT_RETRIEVAL]: 60 * TIMES.SECOND,
    [NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_GET_PERSISTED_STATE]: 60 * TIMES.SECOND,
    [NewRelicPageActions.BOARD_NAVIGATION]: 20 * TIMES.SECOND,
    [NewRelicPageActions.ELEMENT_CREATION]: 5 * TIMES.SECOND,
};

type CustomAttributes = {
    [key: string]: string | number | boolean;
};

type OperationData = CustomAttributes & {
    startTime: number;
};

const operationMap = new Map<string, OperationData>();

const logger = globalLogger.createChannel(LoggerComponents.ANALYTICS);

/**
 * Records the start time of an operation.
 *
 * Also allows custom attributes to be saved with the operation.
 */
export const startOperation = (
    operationName: AnalyticsTimingServiceOperation,
    extraCustomAttributes?: Record<string, string | number | boolean>,
): void => {
    performance.mark(`MN-${operationName}-start`);
    operationMap.set(operationName, { ...extraCustomAttributes, startTime: Date.now() });
};

/**
 * Checks if an operation is currently being tracked.
 */
export const doesOperationExist = (operationName: AnalyticsTimingServiceOperation): boolean =>
    operationMap.has(operationName);

/**
 * Clears out the timer for an existing operation.
 */
export const cancelOperation = (operationName: AnalyticsTimingServiceOperation): void => {
    operationMap.delete(operationName);
};

/**
 * Ends an operation, calculating its duration and sends it to New Relic.
 */
export const endOperation = (
    operationName: AnalyticsTimingServiceOperation,
    extraCustomAttributes?: Record<string, string | number | boolean>,
): number | null => {
    const operationEntry = operationMap.get(operationName);

    if (!operationEntry) return null;

    performance.mark(`MN-${operationName}-end`);
    performance.measure(`MN-${operationName}-duration`, `MN-${operationName}-start`, `MN-${operationName}-end`);

    const duration = Date.now() - operationEntry.startTime;
    operationMap.delete(operationName);

    const maxDuration = MAX_OPERATION_LENGTHS[operationName] || Infinity;
    if (duration > maxDuration) {
        logger.warn(
            `New Relic: Ignoring "${operationName}" operation that took longer than ${maxDuration} seconds`,
            duration,
        );
        return null;
    }

    const allCustomAttributes: CustomAttributes = { ...operationEntry, ...extraCustomAttributes };
    delete allCustomAttributes.startTime;

    analyticsTimingServiceAttributeManager.updateCommonStoreAttributes();
    sendNewRelicPageAction(operationName, { duration, ...allCustomAttributes });

    return duration;
};

/**
 * Allows the analytics timing service to access the main redux store when
 * an operation ends, so it can retrieve the necessary attributes.
 */
export const connectToStore = (mainReduxStore: ReduxStore): void => {
    analyticsTimingServiceAttributeManager.connectToStore(mainReduxStore);
};

/**
 * Surrounds a function with timing operations.
 */
export const wrapAsyncFunctionWithTimingOperation = <T>(
    operationName: AnalyticsTimingServiceOperation,
    fn: (...args: unknown[]) => Promise<T>,
) => {
    return function (...args: unknown[]): Promise<T> {
        startOperation(operationName);
        return fn(...args).finally(() => {
            endOperation(operationName);
        });
    };
};
