import { TiptapNodeType } from '../tiptapTypes';

const TIPTAP_TEXT_BLOCK_NODE_TYPES = new Set<string>([
    TiptapNodeType.paragraph,
    TiptapNodeType.heading,
    TiptapNodeType.smallText,
    TiptapNodeType.codeBlock,
]);

/**
 * Returns true if the tiptap node type string is a block
 * node that contains only text content nodes.
 */
export const isTiptapTextBlockNodeType = (type: string): boolean => TIPTAP_TEXT_BLOCK_NODE_TYPES.has(type);
