// Lib
import dateformat from 'dateformat';

// Utils
import humanTime from '../lib/humanTime';

// Constants
import { getTimestamp, TIMES } from '../timeUtil';

export default ({
    timestamp,
    fromTimestamp = getTimestamp(),
    lessThanMinuteMessage = 'A few seconds ago',
    showSuffix,
}: {
    timestamp: number;
    fromTimestamp?: number;
    lessThanMinuteMessage?: string;
    showSuffix?: boolean;
}): string => {
    const millisPassed = fromTimestamp - timestamp;

    // If less than a minute just show this string
    if (millisPassed > 0 && millisPassed < TIMES.MINUTE) return lessThanMinuteMessage;

    // If more than 4 weeks old, use the actual date E.g. 1 Jan 2017
    if (Math.abs(millisPassed) > 4 * TIMES.WEEK) return dateformat(timestamp, 'd mmm yyyy');

    return humanTime(millisPassed / 1000, showSuffix);
};
