import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const InboxEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: 'new 0 0 123 23',
        }}
        viewBox="0 0 123 23"
        className="Icon inbox-empty"
        {...props}
    >
        <path
            d="m77 12.4-2.4-2V.9c0-.5-.4-.9-.9-.9H49.3c-.5 0-.9.4-.9.9V10.3l-2.4 2c-.2.2-.3.4-.3.7v9.1c0 .5.4.9.9.9h29.8c.5 0 .9-.4.9-.9V13c0-.2-.2-.5-.3-.6zm-1.1.2h-1.3v-1.1l1.3 1.1zM49.3.9h24.4v11.6h-9v.4c0 .1-.4 2.8-3.2 2.8-2.7 0-3.2-2.7-3.2-2.8v-.4h-9V.9zm-.9 10.6v1.1h-1.3l1.3-1.1zm-1.8 10.6v-8.6h10.9c.3 1.3 1.4 3.2 4 3.2s3.7-1.9 4-3.2h10.9v8.6H46.6z"
            style={{
                fill: '#d7d7d7',
            }}
        />
        <path
            d="M52.9 4.5H70v.9H52.9zM52.9 9H70v.9H52.9zM52.9 6.8h16.2v.9H52.9z"
            style={{
                fill: '#d7d7d7',
            }}
        />
    </svg>
);
const Memo = memo(InboxEmptyIcon);
export default Memo;
