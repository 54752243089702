import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileQuickNotesIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        viewBox="0 0 25 24"
        className="Icon mobile-quick-notes"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="m8.931.235 13.426 1.411a2 2 0 0 1 1.78 2.198l-1.045 9.946a2 2 0 0 1-2.198 1.78l-1.3-.138.542 3.42a2 2 0 0 1-1.663 2.289L4.645 23.33a2 2 0 0 1-2.288-1.663L.793 11.791a2 2 0 0 1 1.662-2.288l3.55-.564.728-6.924A2 2 0 0 1 8.931.235zm-3.089 10.25-3.152.5a.5.5 0 0 0-.422.482l.006.09 1.565 9.876a.5.5 0 0 0 .482.422l.09-.006 13.827-2.19a.5.5 0 0 0 .422-.482l-.006-.09-.605-3.817-10.581-1.112a2 2 0 0 1-1.78-2.198l.154-1.476zm2.4-8.402-.017.089-1.046 9.945a.5.5 0 0 0 .357.532l.088.018 13.427 1.41a.5.5 0 0 0 .532-.356l.017-.088 1.045-9.945a.5.5 0 0 0-.356-.532l-.089-.018L8.774 1.727a.5.5 0 0 0-.532.356z"
        />
    </svg>
);
const Memo = memo(MobileQuickNotesIcon);
export default Memo;
