import { AnyAction } from 'redux';

import { elementGraphSelector } from '../../../../../element/selectors/elementGraphSelector';
import { getClosestUpTaskListId } from '../../../../../../common/elements/utils/elementTraversalUtils';
import { getDescendantsViaGraph } from '../../../../../../common/elements/utils/elementGraphUtils';
import { getElementType } from '../../../../../../common/elements/utils/elementPropertyUtils';

import { isTask } from '../../../../../../common/elements/utils/elementTypeUtils';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { getElement, getElements } from '../../../../../element/utils/elementStoreUtils';
import { getElements as getImmutableElements } from '../../../../../element/selectors/elementsSelector';
import { asObject } from '../../../../../../common/utils/immutableHelper';
import { HYBRID_ACTION_TYPES, HybridElementEditStartAction } from '../../hybridMiddlewareConstants';
import { getMainEditorKey } from '../../../../../element/utils/elementEditorUtils';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../platform/platformTypes';
import { ElementType } from '../../../../../../common/elements/elementTypes';

/*  ### Creating HYBRID_ELEMENT_EDIT_START actions
 *
 *  Forwards all properties of the provided original action with a few changes:
 *
 *  - Changes the type of the action to: 'HYBRID_ELEMENT_EDIT_START'
 *  - Adds the `element` property which provides the element on which editing is about to begin.
 *  - Adds the `relevantElements` property which provides all elements involved (and could
 *    possibly be changed) during the element-edit.
 */

// Task List and Task Utils

const getAllTaskElementsWithTaskListId = (state: any, taskListId: string): Map<string, MNElement> | undefined => {
    const elements = getImmutableElements(state);
    const elementGraph = elementGraphSelector(state);

    if (!elements) return;
    const taskListElement = elements.get(taskListId);
    const descendantTasks = getDescendantsViaGraph({ elements, elementGraph, parentId: taskListId });

    if (!taskListElement) return;
    let allElements = descendantTasks.filter((el) => isTask(el)).toMap();
    allElements = allElements.set(taskListId, taskListElement);
    return asObject(allElements);
};

const getAllTaskElementsContainingDescendantTaskId = (
    state: any,
    taskId: string,
): Map<string, MNElement> | undefined => {
    const elements = getElements(state);
    const taskListId = getClosestUpTaskListId(elements, taskId);
    return getAllTaskElementsWithTaskListId(state, taskListId);
};

const relevantPropsForHybridElementEditStart = (state: any, elementId: string) => {
    const props: {
        element: MNElement | undefined;
        relevantElements: Map<string, MNElement> | undefined;
    } = { element: undefined, relevantElements: undefined };

    props.element = getElement(state, elementId);
    switch (getElementType(props.element)) {
        case ElementType.TASK_LIST_TYPE:
            props.relevantElements = getAllTaskElementsWithTaskListId(state, elementId);
            break;
        case ElementType.TASK_TYPE:
            props.relevantElements = getAllTaskElementsContainingDescendantTaskId(state, elementId);
            break;
    }
    return props;
};

const shouldCreateHybridElementEditStart = (): boolean => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_CANVAS:
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
        case LegacyHybridUseCase.IOS_BOARD_LIST:
            return true;
        case LegacyHybridUseCase.IPAD_OS:
        default:
            return false;
    }
};

/// Allows caller to explicitly declare the elementId of the element for which editing is about to
/// begin, rather than inferring it from the action.
export const createHybridElementEditStartAction = (
    state: any,
    action: AnyAction,
    elementId: string,
    editorKey?: string,
): HybridElementEditStartAction | undefined => {
    if (!shouldCreateHybridElementEditStart()) return;

    const element = getElement(state, elementId);
    const hybridAction: HybridElementEditStartAction = {
        ...action,
        ...relevantPropsForHybridElementEditStart(state, elementId),
        id: elementId,
        type: HYBRID_ACTION_TYPES.ELEMENT_EDIT_START,
    };
    if (element) {
        hybridAction.element = element;
        hybridAction.editorKey = getMainEditorKey({ element });
    }
    if (editorKey) {
        hybridAction.editorKey = editorKey;
    }
    return hybridAction;
};
