// Lib
import HttpStatus from 'http-status-codes';

// Utils
import { getResponseStatusCode, getErrorName } from '../../utils/errorUtil';

export const getExportErrorText = (err: unknown): string => {
    const statusCode = getResponseStatusCode(err);

    if (statusCode === HttpStatus.TOO_MANY_REQUESTS) return 'Failed - please wait 20 seconds';

    const errorName = getErrorName(err);

    if (errorName === 'LimitExceededError') return 'Too many cards to export';

    return 'Failed to create export';
};
