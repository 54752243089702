// Lib
import { EditorState, Modifier } from 'draft-js';

// Utils
import getAllSelectionInlineStyles from '../../customRichUtils/selection/getAllSelectionInlineStyles';

// NOTE: This is a modified versions of "toggleInlineStyle" from RichTextEditorUtil.
//  This allows a style to be added (addedStyle) and another to be removed (removedStyle), rather
//  than just toggled
export const handleColorStyleCommand = (state, action) => {
    let editorState = state;
    const { removedStyles = [], addToUndoStack = true } = action;
    let { addedStyles = [] } = action;

    // Turn on or off the tracking of these changes
    editorState = EditorState.set(editorState, { allowUndo: addToUndoStack });

    const selection = editorState.getSelection();
    let inlineStyles = getAllSelectionInlineStyles(editorState);

    // If the selection is collapsed, force the styles
    if (selection.isCollapsed()) {
        const focusOffset = selection.getFocusOffset();

        // Weird bug - we need to add the previous line's colour to the style override if one exists,
        // otherwise the style override won't turn it off
        // It seems like the style override needs to toggle off current styles on new lines
        if (focusOffset === 0) {
            const currentInlineStyle = editorState.getCurrentInlineStyle();

            addedStyles &&
                currentInlineStyle &&
                currentInlineStyle.forEach((oldStyle) => {
                    if (addedStyles.includes(oldStyle)) {
                        addedStyles = addedStyles.filter((addedStyle) => addedStyle !== oldStyle);
                    } else {
                        addedStyles.push(oldStyle);
                    }
                });
        }

        addedStyles &&
            addedStyles.forEach((addedStyle) => {
                if (!inlineStyles.has(addedStyle)) {
                    inlineStyles = inlineStyles.add(addedStyle);
                }
            });

        removedStyles &&
            removedStyles.forEach((removedStyle) => {
                if (inlineStyles.has(removedStyle)) {
                    inlineStyles = inlineStyles.remove(removedStyle);
                }
            });

        return EditorState.setInlineStyleOverride(editorState, inlineStyles);
    }

    // If characters are selected, immediately apply or remove the
    // inline style on the document state itself.
    let newContent = editorState.getCurrentContent();

    // If the style is already present for the selection range, remove it.
    // Otherwise, apply it.
    removedStyles &&
        removedStyles.forEach((removedStyle) => {
            if (inlineStyles.has(removedStyle)) {
                newContent = Modifier.removeInlineStyle(newContent, selection, removedStyle);
            }
        });

    addedStyles &&
        addedStyles.forEach((addedStyle) => {
            if (!inlineStyles.has(addedStyle)) {
                newContent = Modifier.applyInlineStyle(newContent, selection, addedStyle);
            }
        });

    return EditorState.push(editorState, newContent, 'change-inline-style');
};
