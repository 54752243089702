// Lib
import { isEmpty, get } from 'lodash/fp';

// Utils
import { prop } from '../../../../../../../common/utils/immutableHelper';
import { getUserId } from '../../../../../../../common/users/utils/userPropertyUtils';
import { getEmail } from '../../../../../../../common/users/userHelper';
import { getPermissionsTypeFriendlyName } from '../../../../../sharing/boardSharingUtil';
import { cleanEmailList } from '../../../../../../../common/utils/stringUtil';

// Services
import logger from '../../../../../../logger/logger';
import { http } from '../../../../../../utils/services/http';
import { asyncResource } from '../../../../../../utils/services/http/asyncResource/asyncResource';
import { sendAmplitudeEvent } from '../../../../../../analytics/amplitudeService';
import multiEmailValidator from '../../../../../../../common/validation/validators/type/multiEmailValidator';

// Selectors
import { getCurrentBoardIdFromState } from '../../../../../../reducers/currentBoardId/currentBoardIdSelector';
import { getIsCurrentUserEmailVerified } from '../../../../../../user/currentUserSelector';

// Actions
import { addUsersToBoard } from '../../../../../sharing/boardSharingActions';

// Errors
import ValidationError from '../../../../../../../common/error/ValidationError';

// Constants
import { CONTACT_TYPES } from '../../../../../../user/contacts/userContactsConstants';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../../../../common/analytics/statsConstants';
import { PERMISSION_VALUES } from '../../../../../../../common/permissions/permissionConstants';
import { ResourceTypes } from '../../../../../../utils/services/http/asyncResource/asyncResourceConstants';
import { BOARD_EDITORS_LOAD } from './boardEditorConstants';

export const fetchBoardEditorTimes = (elementId, force) => async (dispatch, getState) =>
    dispatch(
        asyncResource(
            ResourceTypes.boardEditors,
            elementId,
            force,
        )(async () => {
            const response = await http({
                url: `elements/${elementId}/editors`,
            });

            const editors = get(['data', 'editors'], response);

            if (isEmpty(editors)) return;

            return dispatch({
                type: BOARD_EDITORS_LOAD,
                elementId,
                editors,
            });
        }),
    );

const trackShareAnalytics = ({ addUserIds = [], addNewUserEmails = [] }) => {
    sendAmplitudeEvent({
        eventType: 'Shared a board with someone',
        eventProperties: {
            shareType: getPermissionsTypeFriendlyName(PERMISSION_VALUES.FULL_ACCESS),
            usersAdded: addUserIds.length + addNewUserEmails.length,
        },
        userProperties: {
            [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.INVITED_USER]: true },
        },
    });
};

const getUserIdFromAddedUser = (user) => {
    const isGoogleContact = prop('contactType', user) === CONTACT_TYPES.GOOGLE;

    if (isGoogleContact) return prop('userId', user);

    return getUserId(user);
};

/**
 * Adds a single user as an editor to a board.
 * This is used by the editor suggestions popup to add individual users to the current board.
 */
export const addUserAsEditor =
    ({ user }) =>
    async (dispatch, getState) => {
        if (!user) return;

        const state = getState();
        const currentBoardId = getCurrentBoardIdFromState(state);

        // Don't add users if the current user hasn't verified their email
        if (!getIsCurrentUserEmailVerified(state)) return;

        const addUserIds = [];
        const addNewUserEmails = [];

        const userId = getUserIdFromAddedUser(user);

        // For existing users add via their ID
        if (userId) {
            addUserIds.push(userId);
        } else {
            // New users, add via their email
            const email = getEmail(user);
            addNewUserEmails.push(email);
        }

        // Save the state for these users, showing that they're being added (so spinners can be shown)
        //      Might be able to rely on the "ELEMENT_UPDATE_PERMISSIONS", "ELEMENT_UPDATE_ACL"
        //      and "ELEMENT_UPDATE_PERMISSIONS_ERROR" actions?

        trackShareAnalytics({ addUserIds, addNewUserEmails });

        try {
            // dispatch the action to add the users to the board
            await dispatch(
                addUsersToBoard({
                    boardId: currentBoardId,
                    addNewUserEmails,
                    addUserIds,
                    permissions: PERMISSION_VALUES.FULL_ACCESS,
                }),
            );
        } catch (e) {
            logger.error('Failed to add user as editor', e);
            throw e;
        }
    };

export const submitAddEditorInputText =
    ({ inputText }) =>
    async (dispatch, getState) => {
        const state = getState();

        // Don't add users if the current user hasn't verified their email
        if (!getIsCurrentUserEmailVerified(state)) return;

        const validationError = multiEmailValidator(inputText);
        if (validationError) {
            if (validationError.value && validationError.value.length > 1) {
                validationError.message = 'Multiple emails are invalid';
            }
            throw new ValidationError(validationError);
        }

        // Split text based on commas or spaces
        const emails = cleanEmailList(inputText);

        if (isEmpty(emails)) return;

        // Ensure no more than 20 email addresses are added at a time
        if (emails.length > 20) throw new ValidationError({ message: 'A maximum of 20 users can be added at once' });

        trackShareAnalytics({ addNewUserEmails: emails });

        try {
            const currentBoardId = getCurrentBoardIdFromState(state);

            // dispatch the action to add the users to the board
            await dispatch(
                addUsersToBoard({
                    boardId: currentBoardId,
                    addNewUserEmails: emails,
                    permissions: PERMISSION_VALUES.FULL_ACCESS,
                }),
            );
        } catch (e) {
            logger.error('Failed to add emails as editors', e);
            throw e;
        }
    };
