export const globalGridSizeSelector = (state) => state.getIn(['app', 'gridSize', 'size']);

export const getGridSize = (state, ownProps) =>
    (ownProps && ownProps.gridSize) || state.getIn(['app', 'gridSize', 'size']);
export const getGridSizeName = (state) => state.getIn(['app', 'gridSize', 'name']);

export const gridSizeSelector = (state) => ({
    gridSize: getGridSize(state),
});
export const gridSizeNameSelector = (state) => ({
    gridSizeName: getGridSizeName(state),
});

export default getGridSize;
