// Lib
import { browserHistory, hashHistory } from '../../node_module_clones/react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';

// Util
import platformSingleton from '../platform/platformSingleton';
import { isPlatformNativeApp } from '../platform/utils/platformDetailsUtils';

const history = isPlatformNativeApp(platformSingleton) ? hashHistory : browserHistory;

// Configure the browser history to instead be wrapped by the react-router-redux history
export const createStoreHistory = (store) => {
    return syncHistoryWithStore(history, store, {
        selectLocationState: (state) => state.get('routing'),
    });
};

export const historyMiddleware = () => routerMiddleware(history);
