import React from 'react';

import PropTypes from 'prop-types';

import ListPresentational from './ListPresentational';
import ListContainer from './ListContainer';

const List = (props) => {
    const { isPresentational } = props;
    const DisplayList = isPresentational ? ListPresentational : ListContainer;

    return <DisplayList {...props} />;
};

List.propTypes = {
    listCanDropFn: PropTypes.func,
    isPresentational: PropTypes.bool,
    childElementIds: PropTypes.array,
    listId: PropTypes.string,
    listSize: PropTypes.number,
    inListClass: PropTypes.string,
    listElementProps: PropTypes.object,
    listStartChild: PropTypes.node,
    listContainerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    placeholderComponent: PropTypes.node,
};

export default List;
