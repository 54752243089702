import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAlignRightIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-align-right"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M23 16.75h-8.25a.75.75 0 1 1 0-1.5H23a.75.75 0 1 1 0 1.5zm0-6H9a.75.75 0 1 1 0-1.5h14a.75.75 0 0 1 0 1.5zm0 12H11a.75.75 0 1 1 0-1.5h12a.75.75 0 1 1 0 1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAlignRightIcon);
export default Memo;
