import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CheckboxTickIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={18}
        viewBox="0 0 22 18"
        className="Icon checkbox-tick"
        {...props}
    >
        <g fill="none" fillRule="nonzero">
            <path
                fill="#FFF"
                d="m4.412 6.174-4.24 4.24 7.535 7.536L21.243 4.414 17 .172 7.706 9.457z"
                className="checkbox-tick-border"
            />
            <path
                fill="#b5b5b5"
                d="M7.707 15.121 18.414 4.414 17 3l-9.293 9.282L4.414 9 3 10.414z"
                className="checkbox-tick-path"
            />
        </g>
    </svg>
);
const Memo = memo(CheckboxTickIcon);
export default Memo;
