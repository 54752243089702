// Lib
import { head } from 'lodash';
import http from '../../utils/services/http';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';
import { CUSTOM_HTTP_HEADERS, METHODS } from '../../../common/utils/http/httpConstants';

export const changeBoardOwner = ({ boardId, updateUserIds }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/owner`,
        timeout: TIMES.SECOND * 15,
        data: {
            newOwnerId: head(updateUserIds),
        },
    });

export const addBoardPermissions = ({ boardId, addUserIds, updateUserIds, removeUserIds, permissionToken, ...rest }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/permissions-invite`,
        timeout: TIMES.SECOND * 15,
        retry: 2,
        data: {
            addUserIds,
            updateUserIds,
            removeUserIds,
            ...rest,
        },
        ...(permissionToken && {
            headers: {
                [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: permissionToken,
            },
        }),
    });

export const updateBoardPermissions = ({ boardId, removeUserIds, updateUserIds, permissions, permissionToken }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/permissions`,
        timeout: TIMES.SECOND * 15,
        retry: 2,
        data: {
            removeUserIds,
            updateUserIds,
            permissions,
        },
        ...(permissionToken && {
            headers: {
                [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: permissionToken,
            },
        }),
    });

export const publishBoard = ({ tokens, boardId, feature, ...rest }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/publish`,
        timeout: TIMES.SECOND * 15,
        retry: 2,
        data: rest,
        headers: {
            [CUSTOM_HTTP_HEADERS.API_FEATURE]: feature,
            [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: tokens,
        },
    });

export const unpublishBoard = ({ tokens, boardId }) =>
    http({
        method: METHODS.DELETE,
        url: `elements/${boardId}/publish`,
        timeout: TIMES.SECOND * 15,
        retry: 2,
        headers: {
            [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: tokens,
        },
    });

export const updatePublishBoardPasswordHttp = ({ boardId, permissionId, password, tokens }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/publish/${permissionId}/password`,
        timeout: TIMES.SECOND * 30,
        retry: 1,
        data: {
            password,
        },
        headers: {
            [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: tokens,
        },
    });

export const removePublishBoardPasswordHttp = ({ boardId, permissionId, tokens }) =>
    http({
        method: METHODS.DELETE,
        url: `elements/${boardId}/publish/${permissionId}/password`,
        timeout: TIMES.SECOND * 30,
        retry: 1,
        headers: {
            [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: tokens,
        },
    });

export const enableBoardPublicEditing = ({ boardId, permissionToken }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/public-edit`,
        timeout: TIMES.SECOND * 15,
        retry: 2,
        ...(permissionToken && {
            headers: {
                [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: permissionToken,
            },
        }),
    });

export const disableBoardPublicEditing = ({ boardId, permissionToken }) =>
    http({
        method: METHODS.DELETE,
        url: `elements/${boardId}/public-edit`,
        timeout: TIMES.SECOND * 15,
        retry: 2,
        ...(permissionToken && {
            headers: {
                [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: permissionToken,
            },
        }),
    });

export const resetBoardPermissionLinkHttp = ({ boardId, permissionId }) =>
    http({
        method: METHODS.PUT,
        url: `elements/${boardId}/reset-link/${permissionId}`,
        timeout: TIMES.SECOND * 15,
        retry: 1,
    });

export const saveToHomeHttp = ({ boardId, permissionId }) =>
    http({
        method: METHODS.POST,
        url: `elements/${boardId}/save-to-home`,
        timeout: TIMES.SECOND * 15,
        retry: 1,
        data: {
            permissionId,
        },
    });
