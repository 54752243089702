import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const TaskTrashIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon task-trash"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M6.496 1C5.676 1 5 1.676 5 2.496V3H2.5a.5.5 0 0 0 0 1H3v8.5c0 .824.676 1.5 1.5 1.5h7c.824 0 1.5-.676 1.5-1.5V4h.5a.5.5 0 1 0 0-1H11v-.504C11 1.676 10.324 1 9.504 1H6.496zm0 1h3.008c.281 0 .496.215.496.496V3H6v-.504C6 2.215 6.215 2 6.496 2zM4 4h8v8.5c0 .281-.219.5-.5.5h-7a.494.494 0 0 1-.5-.5V4z"
        />
    </svg>
);
const Memo = memo(TaskTrashIcon);
export default Memo;
