import React from 'react';
import Handsontable from 'handsontable/base';
import { Settings } from 'handsontable/plugins/contextMenu/contextMenu';
import { ContextMenu } from 'handsontable/plugins';

// Types
import TableOperations from './TableOperations';
import { TextAlignment, VerticalAlignment } from '../../../../common/table/CellTypeConstants';
import { isGlobalDebugEnabled } from '../../../debug/debugUtil';
import { debugTableCell, debugTableInstance } from '../utils/tableDebugUtils';

interface Props {
    elementId: string;
    tableOperationsRef: React.RefObject<TableOperations>;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
}

class TableContextMenuHandlers extends React.Component<Props> {
    getTableContextMenuConfig(): Settings {
        const { elementId, hotTableInstanceRef, tableOperationsRef } = this.props;

        if (!tableOperationsRef.current) return false;

        return {
            items: {
                cut: {},
                copy: {},
                paste: {
                    key: 'paste',
                    name: 'Paste',
                    callback() {
                        navigator.clipboard.readText().then((clipboard) => {
                            const copyPastePlugin =
                                hotTableInstanceRef.current &&
                                hotTableInstanceRef.current.getPlugin<'copyPaste'>('copyPaste');
                            copyPastePlugin?.paste(clipboard);
                        });
                    },
                },
                separator4: ContextMenu.SEPARATOR,
                add_rows_above_custom: {
                    name: tableOperationsRef.current.addRowsAboveMenuName,
                    disabled: tableOperationsRef.current.addRowsAboveDisabled,
                    callback: tableOperationsRef.current.addRowsAbove,
                },
                add_rows_below_custom: {
                    name: tableOperationsRef.current.addRowsBelowMenuName,
                    disabled: tableOperationsRef.current.addRowsBelowDisabled,
                    callback: tableOperationsRef.current.addRowsBelow,
                },
                separator1: ContextMenu.SEPARATOR,
                add_cols_left_custom: {
                    name: tableOperationsRef.current.addColsLeftMenuName,
                    disabled: tableOperationsRef.current.addColsLeftDisabled,
                    callback: tableOperationsRef.current.addColsLeft,
                },
                add_cols_right_custom: {
                    name: tableOperationsRef.current.addColsRightMenuName,
                    disabled: tableOperationsRef.current.addColsRightDisabled,
                    callback: tableOperationsRef.current.addColsRight,
                },
                separator2: ContextMenu.SEPARATOR,
                remove_rows_custom: {
                    name: tableOperationsRef.current.removeRowsMenuName,
                    disabled: tableOperationsRef.current.removeRowsDisabled,
                    callback: tableOperationsRef.current.removeRows,
                },
                remove_cols_custom: {
                    name: tableOperationsRef.current.removeColsMenuName,
                    disabled: tableOperationsRef.current.removeColsDisabled,
                    callback: tableOperationsRef.current.removeCols,
                },
                separator3: ContextMenu.SEPARATOR,
                custom_alignment: {
                    name: 'Change Alignment...',
                    submenu: {
                        items: [
                            {
                                key: 'custom_alignment:left',
                                name: tableOperationsRef.current.textAlignmentMenuName(TextAlignment.LEFT),
                                callback: tableOperationsRef.current.updateCellTextAlignment(TextAlignment.LEFT),
                            },
                            {
                                key: 'custom_alignment:center',
                                name: tableOperationsRef.current.textAlignmentMenuName(TextAlignment.CENTER),
                                callback: tableOperationsRef.current.updateCellTextAlignment(TextAlignment.CENTER),
                            },
                            {
                                key: 'custom_alignment:right',
                                name: tableOperationsRef.current.textAlignmentMenuName(TextAlignment.RIGHT),
                                callback: tableOperationsRef.current.updateCellTextAlignment(TextAlignment.RIGHT),
                            },
                            { key: 'separator', ...ContextMenu.SEPARATOR },
                            {
                                key: 'custom_alignment:top',
                                name: tableOperationsRef.current.verticalAlignmentMenuName(VerticalAlignment.TOP),
                                callback: tableOperationsRef.current.updateCellVerticalAlignment(VerticalAlignment.TOP),
                            },
                            {
                                key: 'custom_alignment:middle',
                                name: tableOperationsRef.current.verticalAlignmentMenuName(VerticalAlignment.MIDDLE),
                                callback: tableOperationsRef.current.updateCellVerticalAlignment(
                                    VerticalAlignment.MIDDLE,
                                ),
                            },
                            {
                                key: 'custom_alignment:bottom',
                                name: tableOperationsRef.current.verticalAlignmentMenuName(VerticalAlignment.BOTTOM),
                                callback: tableOperationsRef.current.updateCellVerticalAlignment(
                                    VerticalAlignment.BOTTOM,
                                ),
                            },
                        ],
                    },
                },
                ...(isGlobalDebugEnabled()
                    ? {
                          separator5: ContextMenu.SEPARATOR,

                          debugTableInstance: {
                              name: 'Debug Table Instance',
                              callback: debugTableInstance,
                              hidden: !isGlobalDebugEnabled(),
                          },
                          debugCell: {
                              name: 'Debug Cell',
                              callback: debugTableCell(elementId),
                              hidden: !isGlobalDebugEnabled(),
                          },
                      }
                    : {}),
            },
        };
    }

    render() {
        return null;
    }
}

export default TableContextMenuHandlers;
