// NOTE: This file is based on `amplitudeService.js`, created for passing Amplitude events to Swift on the Hybrid app.

// Utils
import getClientConfig from '../utils/getClientConfig';

// Constants
import {
    SEND_AMPLITUDE_EVENT,
    SET_AMPLITUDE_USER_PROPERTIES,
    SET_AMPLITUDE_USER,
    INITIALISE_AMPLITUDE,
} from './amplitudeSwiftMessageConstants';
import logger from '../logger/logger';

const warnAmplitudeNotAvailable = (fn) => (args) => {
    console.warn("Amplitude is attempting to be invoked, but it's not available", fn.name, args);
};

const ifAmplitudeIsAvailable =
    (fn) =>
    (...args) =>
        !!window?.webkit?.messageHandlers?.milanoteAmplitudeAnalytics && getClientConfig().analytics?.passToSwift
            ? fn(...args)
            : warnAmplitudeNotAvailable(fn)(...args);

export const setAmplitudeUser = ifAmplitudeIsAvailable((user) => {
    window.webkit.messageHandlers.milanoteAmplitudeAnalytics
        .postMessage({
            messageType: SET_AMPLITUDE_USER,
            userId: user._id,
        })
        .catch((error) => {
            logger.error('Amplitude user ID message could not be posted: ', user._id, error);
        });
});

export const clearAmplitudeUser = ifAmplitudeIsAvailable(() => {
    window.webkit.messageHandlers.milanoteAmplitudeAnalytics
        .postMessage({
            messageType: SET_AMPLITUDE_USER,
            userId: null,
        })
        .catch((error) => {
            logger.error('Amplitude clear user ID message could not be posted: ', error);
        });
});

export const sendAmplitudeUserProperties = ifAmplitudeIsAvailable(({ userProperties }) => {
    if (!userProperties) return;

    window.webkit.messageHandlers.milanoteAmplitudeAnalytics
        .postMessage({
            messageType: SET_AMPLITUDE_USER_PROPERTIES,
            userProperties,
        })
        .catch((error) => {
            logger.error('Amplitude user properties could not be posted: ', userProperties, error);
        });
});

export const sendAmplitudeEvent = ifAmplitudeIsAvailable(({ eventType, eventProperties, userProperties }) => {
    sendAmplitudeUserProperties({ userProperties });

    if (eventType) {
        window.webkit.messageHandlers.milanoteAmplitudeAnalytics
            .postMessage({
                messageType: SEND_AMPLITUDE_EVENT,
                eventType,
                eventProperties,
            })
            .catch((error) => {
                logger.error('Amplitude analytics event could not be posted: ', eventType, eventProperties, error);
            });
    }
});

export const initialiseAmplitude = ifAmplitudeIsAvailable((config) => {
    window.webkit.messageHandlers.milanoteAmplitudeAnalytics
        .postMessage({
            messageType: INITIALISE_AMPLITUDE,
            config,
        })
        .catch((error) => {
            logger.error('Amplitude initialise message could not be posted: ', config, error);
        });
});
