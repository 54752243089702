import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MenuEllipsisIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon menu-ellipsis"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M8 12a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 8 12zm5.5 0a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 13.5 12zm5.5 0a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 19 12z"
        />
    </svg>
);
const Memo = memo(MenuEllipsisIcon);
export default Memo;
