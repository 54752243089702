import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarDrawIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-draw"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <g fill="#48505E" fillRule="nonzero">
                <path d="M21.778 6.733a2.3 2.3 0 0 1 2.969.02l.13.12 1.062 1.063c.8.8.894 2.052.249 2.959l-.108.14-1.623 1.948a.8.8 0 0 1-1.293-.936l.063-.088 1.624-1.948a.7.7 0 0 0 .026-.862l-.07-.081-1.062-1.063a.702.702 0 0 0-.856-.105l-.087.062-1.948 1.623a.8.8 0 0 1-1.103-1.154l.079-.075 1.948-1.623zM8.391 21.754l-1.226 2.56a.998.998 0 0 0 1.333 1.334l2.561-1.226a.501.501 0 0 0 .138-.805l-2-2a.5.5 0 0 0-.805.137z" />
                <path d="m18.575 9.117-6.877 6.875a9.503 9.503 0 0 0-1.78 2.47L8.501 21.3a1.501 1.501 0 0 0 .281 1.731l1 1a1.5 1.5 0 0 0 1.732.281l2.839-1.419a9.515 9.515 0 0 0 2.469-1.78l6.873-6.873a2.501 2.501 0 0 0 0-3.536l-1.586-1.586a2.501 2.501 0 0 0-3.536 0zm1.06 1.06a1 1 0 0 1 1.32-.082l.094.083 1.586 1.586a1 1 0 0 1 .083 1.32l-.083.094-6.875 6.875a8.016 8.016 0 0 1-1.754 1.326l-.325.172-2.839 1.42-1-1 1.42-2.84a8.034 8.034 0 0 1 1.244-1.812l.254-.266 6.875-6.875z" />
            </g>
        </g>
    </svg>
);
const Memo = memo(ToolbarDrawIcon);
export default Memo;
