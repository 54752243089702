// Lib
import * as Immutable from 'immutable';

// Utils
import { getKeys, propIn } from '../../../common/utils/immutableHelper';

// Types
import { ImElementLocalData } from '../local/elementLocalDataTypes';

export const getSuggestionsMap: (elementLocalData: ImElementLocalData) => Immutable.Map<string, boolean> | undefined =
    propIn(['suggestions']);
export const getSuggestionsKeys = (elementLocalData: ImElementLocalData) => {
    const suggestions = getSuggestionsMap(elementLocalData)?.filter(Boolean);
    if (!suggestions) return [];
    return getKeys(suggestions);
};

export const alreadySuggested = (elementLocalData: ImElementLocalData, feature: string) =>
    !!propIn(['suggestions', feature], elementLocalData);
