// Lib
import { includes } from 'lodash/fp';

// Constants
import { BLACKLISTED_EXTENSIONS, BLACKLISTED_FILENAMES } from './fileConstants';
import getFileExtension from './getFileExtension';

// Types
import FileValidationError, { FileValidationErrorCodes } from '../error/FileValidationError';

export const validateFilename = (filename: string): FileValidationError | null => {
    if (!filename) {
        return new FileValidationError({
            code: FileValidationErrorCodes.NO_FILENAME,
            message: 'A filename must be provided',
        });
    }

    if (includes(filename, BLACKLISTED_FILENAMES)) {
        return new FileValidationError({
            code: FileValidationErrorCodes.INVALID_FILENAME,
            message: `The filename "${filename}" is not allowed`,
        });
    }

    const periodIndex = filename.lastIndexOf('.');
    if (periodIndex === 0) {
        return new FileValidationError({
            code: FileValidationErrorCodes.INVALID_FILENAME,
            message: 'Dot files are not allowed',
        });
    }

    const extension = getFileExtension(filename);
    const isInvalidExtension = includes(extension, BLACKLISTED_EXTENSIONS);
    if (isInvalidExtension) {
        return new FileValidationError({
            code: FileValidationErrorCodes.INVALID_EXTENSION,
            message: `.${extension} files are not supported`,
        });
    }

    return null;
};
