// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getImageEditorData } from '../imageModalUtils';

// Components
import ImageModalToolbarSecondaryTools from './ImageModalToolbarSecondaryTools';

// Styles
import './ImageModalUploadToolbar.scss';

const ImageModalUploadToolbar = (props) => {
    const { element } = props;

    return (
        <ImageModalToolbarSecondaryTools className="ImageModalUploadToolbar">
            {!!getImageEditorData(element) ? 'Saving changes...' : 'Uploading image...'}
        </ImageModalToolbarSecondaryTools>
    );
};

ImageModalUploadToolbar.propTypes = {
    element: PropTypes.object,
};

export default ImageModalUploadToolbar;
