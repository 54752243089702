// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId } from '../../../../../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { uploadImage } from '../../../../../../../../element/attachments/attachmentActions';
import { updateElement } from '../../../../../../../../element/actions/elementActions';
import { closePopup } from '../../../../../../../../components/popupPanel/popupActions';

// Utils
import { validateImageFile } from '../../../../../../../../../common/files/fileValidator';
import { getNewTransactionId } from '../../../../../../../../utils/undoRedo/undoRedoTransactionManager';

// Components
import BoardImagePicker from './BoardImagePicker';

// Constants
import { IMAGE_TYPES } from '../../../../../../../../../common/media/mediaConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchBoardImage: (id, file, transactionId = getNewTransactionId()) => {
        dispatch(updateElement({ id, changes: { icon: null }, transactionId }));
        dispatch(uploadImage({ id, file, imageType: IMAGE_TYPES.BOARD, transactionId }));
    },
    dispatchClosePopup: () => dispatch(closePopup()),
});

@connect(null, mapDispatchToProps)
class BoardImagePickerContainer extends React.Component {
    uploadImage = async (imageFile, transactionId) => {
        const { dispatchBoardImage, dispatchClosePopup, selectedElements } = this.props;

        const validationErrors = await validateImageFile(imageFile);
        if (validationErrors) {
            // TODO if validation errors, show them to the user
            return;
        }

        selectedElements.forEach((element) => dispatchBoardImage(getElementId(element), imageFile, transactionId));
        dispatchClosePopup();
    };

    render() {
        return <BoardImagePicker {...this.props} openFileSelect={this.openFileSelect} uploadFile={this.uploadImage} />;
    }
}

BoardImagePickerContainer.propTypes = {
    gridSize: PropTypes.number.isRequired,
    selectedElements: PropTypes.object.isRequired,
    selectedImage: PropTypes.string,
    dispatchBoardImage: PropTypes.func,
    dispatchClosePopup: PropTypes.func,
};

export default BoardImagePickerContainer;
