// Lib
import { head, tail, includes } from 'lodash/fp';

import { EditorState, ContentState, Modifier, convertToRaw, CharacterMetadata, RichUtils } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import BlockMapBuilder from 'draft-js/lib/BlockMapBuilder';
import splitTextIntoTextBlocks from 'draft-js/lib/splitTextIntoTextBlocks';
import getEntityKeyForSelection from 'draft-js/lib/getEntityKeyForSelection';

// Constants
import { EditorChangeType, HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

const buildFragmentsForHtml = (html) => {
    if (!html) return null;

    const fragment = DraftPasteProcessor.processHTML(html);

    if (!fragment) return null;

    const { contentBlocks, entityMap } = fragment;

    // Blocks that are completely 'strikethrough' styled are probably completed checklist items
    const completedBlocks = contentBlocks.reduce((acc, contentBlock) => {
        const isCompleted = contentBlock.characterList.every((characterMetadata) =>
            characterMetadata.hasStyle(STYLE_COMMANDS.STRIKETHROUGH),
        );

        if (isCompleted) {
            acc.push(contentBlock.getKey());
        }

        return acc;
    }, []);

    const unstyledContentBlocks = contentBlocks.map((contentBlock) => {
        const unstyledContentBlock = contentBlock.set('type', STYLE_COMMANDS.UNSTYLED);

        if (!includes(unstyledContentBlock.getKey(), completedBlocks)) return unstyledContentBlock;

        return unstyledContentBlock.update('characterList', (characterList) =>
            characterList.map((characterMetadata) =>
                CharacterMetadata.removeStyle(characterMetadata, STYLE_COMMANDS.STRIKETHROUGH),
            ),
        );
    });

    return {
        contentBlocks: unstyledContentBlocks,
        entityMap,
        completedBlocks,
    };
};

const buildFragmentsForText = (text, editorState) => {
    if (!text || !editorState) return null;

    const textBlocks = splitTextIntoTextBlocks(text);

    const character = CharacterMetadata.create({
        style: editorState.getCurrentInlineStyle(),
        entity: getEntityKeyForSelection(editorState.getCurrentContent(), editorState.getSelection()),
    });

    const currentBlockType = RichUtils.getCurrentBlockType(editorState);

    const contentBlocks = DraftPasteProcessor.processText(textBlocks, character, currentBlockType);

    return {
        contentBlocks,
        entityMap: null,
        completedBlocks: [],
    };
};

export default (text, html, editorState, { setEditorState, getProps }) => {
    const parsedPastedData = buildFragmentsForHtml(html) || buildFragmentsForText(text, editorState);

    if (!parsedPastedData) return null;

    const { contentBlocks, entityMap, completedBlocks } = parsedPastedData;

    // First block should be added to the current content
    const firstUnstyledBlock = head(contentBlocks);
    const firstBlockIsComplete = includes(firstUnstyledBlock.getKey(), completedBlocks);

    const firstFragmentMap = BlockMapBuilder.createFromArray([firstUnstyledBlock]);

    const newContent = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        firstFragmentMap,
    );

    const updatedEditorState = EditorState.push(
        editorState,
        newContent.set('entityMap', entityMap),
        EditorChangeType.INSERT_FRAGMENT,
    );

    setEditorState(updatedEditorState);

    const { createMultipleNewTasksWithContent, setComplete } = getProps();

    firstBlockIsComplete && setComplete();

    // For each of the remaining blocks:
    // - Convert to raw editor content
    // - Create a new task immediately below this task with the provided content
    const tailUnstyledBlocks = tail(contentBlocks);
    const rawContentArray = tailUnstyledBlocks.map((unstyledBlock) =>
        convertToRaw(ContentState.createFromBlockArray([unstyledBlock], entityMap)),
    );

    createMultipleNewTasksWithContent(rawContentArray, completedBlocks);

    return HANDLER_RETURN_VALUES.handled;
};
