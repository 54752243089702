import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuTrashIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-trash"
        {...props}
    >
        <g fill="#323B4A">
            <path d="M20.5 5.893a.75.75 0 1 1 0 1.5h-17a.75.75 0 1 1 0-1.5h17z" />
            <path d="M18.611 5.893a.75.75 0 0 1 .75.75v12.75c0 1.331-1.286 2.571-2.639 2.571H7.278c-1.353 0-2.64-1.24-2.64-2.571V6.643a.75.75 0 0 1 1.5 0v12.75c0 .49.604 1.071 1.14 1.071h9.444c.536 0 1.14-.58 1.14-1.071V6.643a.75.75 0 0 1 .75-.75z" />
            <path d="M13.889 2.25c1.353 0 2.639 1.24 2.639 2.571v1.822a.75.75 0 0 1-1.5 0V4.82c0-.49-.603-1.071-1.14-1.071h-3.777c-.536 0-1.139.581-1.139 1.071v1.822a.75.75 0 1 1-1.5 0V4.82c0-1.33 1.286-2.571 2.64-2.571h3.777z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuTrashIcon);
export default Memo;
