// Lib
import { combineReducers } from 'redux-immutable';
import * as Immutable from 'immutable';

// Reducers
import debugDocumentModeReducer from './documentMode/debugDocumentModeReducer';
import debugToolReducer from './store/debugToolReducer';

// Constants
import { DEBUG_TOOL_TOGGLE, ENABLE_DEBUG_TOOLBAR, ENABLE_DEBUG_TOOLS } from './debugConstants';
import { DOC_MODE_DEBUG_TOOL_NAME } from './documentMode/debugDocumentModeConstants';

const enabledReducer = (state = false, action) => {
    switch (action.type) {
        case ENABLE_DEBUG_TOOLS:
        case ENABLE_DEBUG_TOOLBAR:
            return true;
        default:
            return state;
    }
};

const toolbarReducer = (state = false, action) => {
    switch (action.type) {
        case ENABLE_DEBUG_TOOLBAR:
            return true;
        default:
            return state;
    }
};

const initialEnabledTools = Immutable.fromJS({});

const enabledToolsReducer = (state = initialEnabledTools, action) => {
    switch (action.type) {
        case DEBUG_TOOL_TOGGLE:
            return state.set(action.toolName, action.enabled);
        default:
            return state;
    }
};

const debugReducer = combineReducers({
    enabled: enabledReducer,
    toolbar: toolbarReducer,
    enabledTools: enabledToolsReducer,
    tools: debugToolReducer,
    [DOC_MODE_DEBUG_TOOL_NAME]: debugDocumentModeReducer,
});

const initialState = Immutable.Map();
const wrappedDebugReducer = (state = initialState, action) => {
    if (action.type !== ENABLE_DEBUG_TOOLS && !state.get('enabled')) return state;
    return debugReducer(state, action);
};

export default wrappedDebugReducer;
