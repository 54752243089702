// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { clamp } from 'lodash/fp';

// TODO this functionality has been duplicated into a hook useListFocusManager, should update all components
// using this class to use that hook directly

export default ({ loop = false } = {}) =>
    (DecoratedComponent) => {
        class ListFocusManager extends React.Component {
            constructor(props) {
                super(props);

                this.state = {
                    focusedIndex: props.initialFocusIndex,
                };
            }

            componentWillReceiveProps(nextProps) {
                if (nextProps.listSize < this.props.listSize || (nextProps.listSize && !this.props.listSize)) {
                    this.setState({ focusedIndex: nextProps.initialFocusIndex });
                }
            }

            setFocusIndex = (focusedIndex) => {
                if (focusedIndex === this.state.focusedIndex) return;
                const { listSize, initialFocusIndex } = this.props;

                this.setState({
                    focusedIndex: clamp(Math.min(initialFocusIndex, 0), listSize - 1, focusedIndex),
                });
            };

            moveListFocusUp = () => {
                const { listSize } = this.props;
                const { focusedIndex } = this.state;

                let nextFocus = focusedIndex - 1;
                nextFocus = loop && nextFocus < 0 ? listSize - 1 : nextFocus;

                return this.setFocusIndex(nextFocus);
            };

            moveListFocusDown = () => {
                const { listSize } = this.props;
                const { focusedIndex } = this.state;

                let nextFocus = focusedIndex + 1;
                nextFocus = loop && nextFocus > listSize - 1 ? 0 : nextFocus;

                return this.setFocusIndex(nextFocus);
            };

            render() {
                const { focusedIndex } = this.state;

                return (
                    <DecoratedComponent
                        {...this.props}
                        focusedIndex={focusedIndex}
                        setFocusIndex={this.setFocusIndex}
                        moveListFocusUp={this.moveListFocusUp}
                        moveListFocusDown={this.moveListFocusDown}
                    />
                );
            }
        }

        ListFocusManager.propTypes = {
            listSize: PropTypes.number.isRequired,
            initialFocusIndex: PropTypes.number.isRequired,
        };

        return ListFocusManager;
    };
