// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { getLineWeight } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateSelectedElements } from '../../../../../element/actions/elementActions';

// Components
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import Button from '../../../../../components/buttons/Button';
import Icon from '../../../../../components/icons/Icon';

// Constants
import { LINE_WEIGHT } from '../../../../../../common/lines/lineConstants';

// Styles
import './LinePopup.scss';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

const mapStateToProps = (state, ownProps) => ({
    lineWeight: getLineWeight(ownProps.selectedElements.first()),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateSelectedElements: ({ changes }) => dispatch(updateSelectedElements({ changes })),
});

let LineWeightPopupContent = (props) => {
    const { lineWeight, dispatchUpdateSelectedElements } = props;

    const setLineWeight = React.useCallback((newLineWeight) => () => {
        dispatchUpdateSelectedElements({
            changes: {
                lineWeight: newLineWeight,
            },
        });
    });

    return (
        <>
            <Button
                onClick={setLineWeight(LINE_WEIGHT.S)}
                className={classNames({ active: lineWeight === LINE_WEIGHT.S })}
            >
                <Icon name="toolbar-line-weight-1-px" />
            </Button>
            <Button
                onClick={setLineWeight(LINE_WEIGHT.M)}
                className={classNames({ active: lineWeight === LINE_WEIGHT.M || !lineWeight })}
            >
                <Icon name="toolbar-line-weight-2-px" />
            </Button>
            <Button
                onClick={setLineWeight(LINE_WEIGHT.L)}
                className={classNames({ active: lineWeight === LINE_WEIGHT.L })}
            >
                <Icon name="toolbar-line-weight-4-px" />
            </Button>
        </>
    );
};

LineWeightPopupContent.propTypes = {
    lineWeight: PropTypes.number,
    dispatchUpdateSelectedElements: PropTypes.func,
};

LineWeightPopupContent = connect(mapStateToProps, mapDispatchToProps)(LineWeightPopupContent);

const LineWeightPopup = (props) => {
    const { closePopup } = props;

    return (
        <ToolbarPopup
            popupId={PopupIds.LINE_WEIGHT}
            className="LineWeightPopup LinePopup"
            buttonSelector=".LineweightTool .icon"
            closePopup={closePopup}
        >
            <LineWeightPopupContent {...props} />
        </ToolbarPopup>
    );
};

LineWeightPopup.propTypes = {
    closePopup: PropTypes.func,
};

export default LineWeightPopup;
