import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeDatetimeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-datetime"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#323B4A"
                d="M20.25 8a.75.75 0 0 1 .75.75V10h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V12a2 2 0 0 1 2-2h1V8.75a.75.75 0 0 1 .648-.743L11.75 8a.75.75 0 0 1 .75.75V10h7V8.75a.75.75 0 0 1 .648-.743Zm2.25 8.25h-13V22a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-5.75ZM22 11.5H10a.5.5 0 0 0-.5.5v2.75h13V12a.5.5 0 0 0-.5-.5Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeDatetimeIcon);
export default Memo;
