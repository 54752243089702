import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { getElementStyle } from '../../../utils/grid/gridUtils';
import { getDomElementId } from '../../utils/elementUtil';
import { getColor, getElementId, getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';
import { getIsLightIconViewColor } from '../../../../common/colors/colorComparisonUtil';
import { getColorCssValue } from '../../utils/elementColorStyleUtils';
import { getShowQuickLineTool } from '../../quickLine/quickLineUtil';

// Components
import IconView from '../../../components/displayMode/IconView';
import WordCount from './WordCount';
import QuickLineCreationTool from '../../quickLine/QuickLineCreationTool';
import HiddenInput from './HiddenInput';
import DocumentStatus from './DocumentStatus';
import ElementUserTag from '../../../user/userActivity/ElementUserTag';
import ElementPaperIcon from '../../../components/paperIcon/ElementPaperIcon';
import FileIcon from '../../../components/fileIcon/FileIcon';

// Constants
import { DEFAULT_ICON_VIEW_WIDTH } from '../../../../common/elements/elementDisplayModeConstants';

// Styles
import './DocumentIconView.scss';

const DocumentIconView = (props) => {
    const {
        element,
        className,
        inList,
        documentMode,
        gridSize,
        elementEvents,
        currentBoardId,
        titleElement,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const elementId = getElementId(element);

    const color = getColor(element);
    const colorCss = getColorCssValue(color);

    const isLightBg = getIsLightIconViewColor(color);

    const iconTheme = colorCss && (isLightBg ? 'light' : 'dark');

    return (
        <div
            id={getDomElementId(elementId)}
            className={classNames('DocumentIconView', className)}
            style={getElementStyle(DEFAULT_ICON_VIEW_WIDTH, gridSize, inList)}
            {...elementEvents}
        >
            <HiddenInput {...props} />
            <IconView
                className="document-content"
                iconElement={
                    <ElementPaperIcon
                        className={iconTheme && `background-${iconTheme}`}
                        gridSize={gridSize}
                        inList={inList}
                        documentMode={documentMode}
                        style={{
                            '--ws-document-icon-background-color': colorCss,
                            '--ws-document-icon-fold-color': colorCss,
                        }}
                        iconElement={<FileIcon iconName="file-document" iconStyle="large" iconTheme={iconTheme} />}
                    >
                        <ElementUserTag {...props} />
                        <QuickLineCreationTool
                            show={getShowQuickLineTool(props)}
                            elementId={elementId}
                            element={element}
                            currentBoardId={currentBoardId}
                            gridSize={gridSize}
                            getContextZoomScale={getContextZoomScale}
                            getContextZoomTranslationPx={getContextZoomTranslationPx}
                        />
                        <DocumentStatus {...props} elementId={elementId} />
                    </ElementPaperIcon>
                }
                titleElement={titleElement}
                secondaryElement={<WordCount rawState={getTextContent(element)} />}
            />
        </div>
    );
};

DocumentIconView.propTypes = {
    element: PropTypes.object.isRequired,
    className: PropTypes.string,
    inList: PropTypes.string,
    documentMode: PropTypes.bool,
    gridSize: PropTypes.number,
    isSingleSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    currentBoardId: PropTypes.string,
    elementEvents: PropTypes.object,
    isElementOpenInModal: PropTypes.bool,
    moveElementsToTrash: PropTypes.func,

    dispatchHandleArrowKeys: PropTypes.func,
    dispatchNavigateToElement: PropTypes.func,

    titleElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default DocumentIconView;
