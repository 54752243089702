// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { stopPropagationOnly } from '../../utils/domUtil';
import { sanitiseUrl } from '../../../common/utils/urlUtil';

/**
 * Sanitises the URL to avoid XSS issues.
 * Uses state to avoid performing a regex match on every render.
 * noopener and noreferrer prevent "tabnabbing" attacks.
 */
class LinkAnchor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { sanitisedHref: null };
    }

    componentWillMount() {
        this.updateHref(this.props.url);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.updateHref(nextProps.url);
        }
    }

    updateHref = (url) => {
        this.setState({ sanitisedHref: sanitiseUrl(url) });
    };

    render() {
        const { sanitisedHref } = this.state;
        const { children, className, target = '_blank' } = this.props;
        return (
            <a
                className={className}
                href={sanitisedHref}
                target={target}
                rel="noopener noreferrer nofollow"
                onClick={stopPropagationOnly}
            >
                {children}
            </a>
        );
    }
}

LinkAnchor.propTypes = {
    url: PropTypes.string,
    target: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default LinkAnchor;
