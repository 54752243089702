// Utils
import { propIn } from '../../../../common/utils/immutableHelper';
import { getMediaType } from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { PROVIDERS } from '../../../../common/links/linkConstants';
import { MEDIA_TYPES } from '../../../../common/links/richMediaConstants';

// Providers that won't be auto-embeded
const AUTO_EMBED_BLACKLIST = {
    [PROVIDERS.YouTube]: true,
    [PROVIDERS.Vimeo]: true,
    [PROVIDERS.SoundCloud]: true,
    [PROVIDERS.Loom]: true,
};

export default (element) => {
    const providerName = propIn(['content', 'provider', 'name'], element);
    return !AUTO_EMBED_BLACKLIST[providerName] && getMediaType(element) !== MEDIA_TYPES.VIDEO;
};
