// Utils
import http from '../../utils/services/http';

// Constants
import { METHODS } from '../../../common/utils/http/httpConstants';
import { PUSH_NOTIFICATION_SUBSCRIPTION_TYPES } from '../../../common/notifications/pushNotificationConstants';

export const registerPushSubscription = ({ subscription, showSuccessNotification }) =>
    http({
        method: METHODS.POST,
        url: 'notifications/push/register',
        data: {
            subscription,
            showSuccessNotification,
            subscriptionType: PUSH_NOTIFICATION_SUBSCRIPTION_TYPES.WEB,
        },
    }).then((res) => res.data);

export const unregisterPushSubscription = ({ subscription }) =>
    http({
        method: METHODS.POST,
        url: 'notifications/push/unregister',
        data: {
            subscription,
            subscriptionType: PUSH_NOTIFICATION_SUBSCRIPTION_TYPES.WEB,
        },
    }).then((res) => res.data);
