import { RawDraftInlineStyleRange } from 'draft-js';
import { TiptapContentMark } from '../../../tiptapTypes';

const upperStyle = (mark: TiptapContentMark) => mark.type?.toUpperCase() || '';

const styleConverters: Record<string, (m: TiptapContentMark) => string> = {
    bold: upperStyle,
    italic: upperStyle,
    underline: upperStyle,
    code: upperStyle,
    strike: () => 'STRIKETHROUGH',
    textStyle: (mark) => `TEXT-${mark.attrs?.color}`,
    highlight: (mark) => (mark.attrs?.color === 'darkgrey' ? 'BG-dark-grey' : `BG-${mark.attrs?.color}`),
};

export const convertMarkToStyle = (mark: TiptapContentMark): string | undefined => {
    const converter = styleConverters[mark.type || ''];
    return converter?.(mark);
};

/**
 * In contrast to Tiptap, which stores unique mark groups against a length of text, Draft.js stores
 * unique marks against the range of text they apply to.
 * So this function collapses inline style ranges - created from a direct Tiptap conversion,
 * into a single range if they're adjacent.
 * E.g.
 *  [
 *      { style: 'BOLD', offset: 0, length: 5 },
 *      { style: 'BOLD', offset: 5, length: 5 },
 *      { style: 'BOLD', offset: 12, length: 5 },
 *  ]
 *
 *  becomes
 *  [
 *      { style: 'BOLD', offset: 0, length: 10 },
 *      { style: 'BOLD', offset: 12, length: 5 },
 *  ]
 */
export const collapseInlineStyleRanges = (
    inlineStyleRanges: RawDraftInlineStyleRange[],
): RawDraftInlineStyleRange[] => {
    const styleRangeGroups: Record<string, RawDraftInlineStyleRange[]> = {};

    inlineStyleRanges.forEach((styleRange) => {
        const { style } = styleRange;

        if (!styleRangeGroups[style]) {
            styleRangeGroups[style] = [];
        }

        styleRangeGroups[style].push(styleRange);
    });

    // Collapse the ranges if they are adjacent
    const collapsedRanges: RawDraftInlineStyleRange[] = [];

    Object.values(styleRangeGroups).forEach((styleRanges) => {
        const sortedRanges = styleRanges.sort((a, b) => a.offset - b.offset);

        let currentRange = sortedRanges[0];

        for (let i = 1; i < sortedRanges.length; i++) {
            const nextRange = sortedRanges[i];

            if (currentRange.offset + currentRange.length === nextRange.offset) {
                currentRange.length += nextRange.length;
            } else {
                collapsedRanges.push(currentRange);
                currentRange = nextRange;
            }
        }

        collapsedRanges.push(currentRange);
    });

    return collapsedRanges;
};
