import { memoize } from 'lodash';

// Choosing the default currency for each of these based on number of milanote
// users creating elements from each country
const duplicateSymbolsMap: { [key: string]: string } = {
    ['$']: 'USD', // Defaulting to USD
    ['£']: 'GBP', // FKP - falkland islands, GIP - gibraltar, GBP - united kingdom , SHP - saint helena, SYP - syria, SSP - south sudan
    ['kr']: 'SEK', // DKK - denmark, NOK - norway, SEK - sweden, ISK - iceland
    ['¥']: 'JPY', // CNY - china, JPY - japan
    ['₩']: 'KRW', // KRW - south korea, KPW - north korea
    ['Rs']: 'PKR', // LKR - sri lanka, NPR - nepal, PKR - pakistan, MUR - mauritius
};

export const getSymbolFromCurrencyCode = (currencyCode: string = 'USD'): string =>
    (0)
        .toLocaleString('en-US', {
            style: 'currency',
            currency: currencyCode,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();

export const getCurrencySymbolMap = memoize((currencyPreference): { [key: string]: string } => {
    // @ts-ignore - valid property of Intl
    const supportedCurrencyCodes = Intl.supportedValuesOf('currency');
    const currencySymbols: { [key: string]: string } = {};

    supportedCurrencyCodes.forEach((currencyCode: string) => {
        const symbol = getSymbolFromCurrencyCode(currencyCode);

        // If the symbol in duplicateSymbolsMap, then it is a duplicate and a default has been decided
        // If its already in currencySymbols, then it is a duplicate in which case use the first alphabetically
        if (duplicateSymbolsMap[symbol] || currencySymbols[symbol]) return;

        // return null if symbol is 3 or more letters and upper case - deciding not to
        // infer these as most of them have their own symbol it just isn't supported by Intl
        if (symbol.length >= 3 && symbol === symbol.toUpperCase()) return;

        currencySymbols[symbol] = currencyCode;
    });

    // add duplicateSymbolsMap to currencySymbols
    Object.keys(duplicateSymbolsMap).forEach((symbol) => {
        currencySymbols[symbol] = duplicateSymbolsMap[symbol];
    });

    // Make sure preference is in map (only relevant if it shares a symbol with other currencies)
    // e.g. if preference is 'AUD', we want '$' to return 'AUD' instead of 'USD'
    if (currencyPreference) currencySymbols[getSymbolFromCurrencyCode(currencyPreference)] = currencyPreference;

    return currencySymbols;
});

/**
 * Returns the currency symbol and currency code for a
 * given string if it contains a currency symbol at the beginning or end
 */
export const getCurrencyFromString = (
    str: string,
    currencyPreference?: string,
): { currencySymbol: string | null; currency: string | null } => {
    const currencySymbolMap = getCurrencySymbolMap(currencyPreference);

    // Check for currency symbol at the beginning or end of the string
    // Start with 3 chars and go down to 1 so that we can match the longest symbol first
    for (let i = 3; i >= 1; i--) {
        // Check for symbol at the beginning
        const subStrFirst = str.substring(0, i);
        if (currencySymbolMap[subStrFirst]) {
            return { currencySymbol: subStrFirst, currency: currencySymbolMap[subStrFirst] };
        }

        // Check for symbol at the end
        const subStrLast = str.substring(str.length - i, str.length);
        if (currencySymbolMap[subStrLast]) {
            return {
                currencySymbol: subStrLast,
                currency: currencySymbolMap[subStrLast],
            };
        }
    }

    return { currencySymbol: null, currency: null };
};

export const stripCurrencySymbols = (str: string): string => {
    const { currencySymbol } = getCurrencyFromString(str);
    return currencySymbol ? str.replace(currencySymbol, '') : str;
};
