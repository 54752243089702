import axios from 'axios';

const requester = axios.create();

const requestFileHead = async (url) => {
    try {
        const { headers } = await requester({
            url,
            method: 'head',
        });

        const size = parseInt(headers['content-length'], 10) || 0;
        const mime = headers['content-type'];
        const modifiedTime = new Date(headers['last-modified']).getTime();

        return {
            url,
            size,
            mime,
            modifiedTime,
        };
    } catch (error) {
        return {
            error,
            url,
            size: 0,
            modifiedTime: 0,
            type: 'image/jpeg',
        };
    }
};

export default requestFileHead;
