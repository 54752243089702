import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

const DATE_ERROR_OBJECT = {
    name: FAILURE_NAMES.date,
    type: FAILURE_TYPES.ERROR,
    debounced: true,
    message: 'Not a valid date',
};

export default (fieldValue, validationConfig) => {
    if (!fieldValue) return;

    const numericValue = parseInt(fieldValue, 10);
    if (isNaN(numericValue)) return DATE_ERROR_OBJECT;

    const date = new Date(numericValue);
    if (isNaN(date.getTime())) return DATE_ERROR_OBJECT;

    return null;
};
