// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { gridPointsToPixels } from '../../../utils/grid/gridUtils';
import getPaddingForAspectRatio from '../../resizing/utils/getPaddingForAspectRatio';

// Components
import RichMediaPreviewOverlay from './RichMediaPreviewOverlay';
import RichMediaErrorOverlay from './RichMediaErrorOverlay';
import Spinner from '../../../components/loaders/Spinner';
import OptimisticImage from '../../../components/images/OptimisticImage';
import ImageReplacementOverlay from '../../image/ImageReplacementOverlay';

// Constants
import { IMAGE_TYPES } from '../../../../common/media/mediaConstants';
import { FileTypes } from '../../../../common/files/fileTypes';

// Styles
import './RichMediaPreviewImage.scss';

const RichMediaPreviewImage = (props) => {
    const {
        imageData,
        mediaData,
        tempMediaSize,
        width,
        gridSize,
        onClick,
        show,
        showLoader,
        showError,
        showPreview,
        isReplaceModeHovered,
        previewIconName,
        attachment,
        OverlayComponent = RichMediaPreviewOverlay,
    } = props;

    const widthPx = gridPointsToPixels(width, gridSize);

    if (!show) return null;

    const imageElement = imageData ? (
        <OptimisticImage
            imageType={IMAGE_TYPES.ELEMENT}
            imageDetails={imageData}
            widthPx={widthPx}
            attachmentDetails={attachment}
            forcedSize={{ width: widthPx }}
        />
    ) : null;

    const previewImageClassNames = classNames('RichMediaPreviewImage', { loading: showLoader });

    const style = getPaddingForAspectRatio({ savedSize: mediaData, forcedSize: tempMediaSize, roundSaved: true });

    return (
        <div className={previewImageClassNames} style={style}>
            {isReplaceModeHovered && <ImageReplacementOverlay fileType={FileTypes.VIDEO} />}
            {imageElement}
            <OverlayComponent
                show={!showLoader && !showError && showPreview && !isReplaceModeHovered}
                onClick={onClick}
                iconName={previewIconName}
            />
            <Spinner show={showLoader} />
            <RichMediaErrorOverlay show={showError} />
        </div>
    );
};

RichMediaPreviewImage.propTypes = {
    element: PropTypes.object.isRequired,
    imageData: PropTypes.object,
    mediaData: PropTypes.object,
    tempMediaSize: PropTypes.object,
    width: PropTypes.number,
    gridSize: PropTypes.number,
    enablePlayer: PropTypes.func,
    onClick: PropTypes.func,
    show: PropTypes.bool,
    showLoader: PropTypes.bool,
    showPreview: PropTypes.bool,
    showError: PropTypes.bool,
    attachment: PropTypes.object,
    previewIconName: PropTypes.string,
    isReplaceModeHovered: PropTypes.bool,
    OverlayComponent: PropTypes.elementType,
};

export default RichMediaPreviewImage;
