import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { length } from '../../../common/utils/immutableHelper';

import EmojiImage from '../../components/emoji/EmojiImage';
import Button from '../../components/buttons/Button';
import ElementReactionPillTooltip from './ElementReactionPillTooltip';

import './ElementReactionPill.scss';

const ElementReactionPill = ({ className, emojiCode, currentUserId, userIds, gridSize, toggleReaction, disabled }) => (
    <ElementReactionPillTooltip emojiCode={emojiCode} currentUserId={currentUserId} userIds={userIds}>
        <Button
            className={classNames('ElementReactionPill', className, { active: userIds.indexOf(currentUserId) > -1 })}
            disabled={disabled}
            onClickFn={(event) => {
                event.stopPropagation();
                toggleReaction(emojiCode);
            }}
        >
            <EmojiImage emojiCode={emojiCode} size={(16 / 10) * gridSize} />
            <span className="count">{length(userIds)}</span>
        </Button>
    </ElementReactionPillTooltip>
);

ElementReactionPill.propTypes = {
    className: PropTypes.string,
    emojiCode: PropTypes.string,
    currentUserId: PropTypes.string,
    disabled: PropTypes.bool,
    gridSize: PropTypes.number,
    userIds: PropTypes.array,
    toggleReaction: PropTypes.func,
};

export default ElementReactionPill;
