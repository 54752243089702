/**
 * Gets the key for the block after the block specified.
 */
const getKeyAfter = (blockMap, key) =>
    blockMap
        .keySeq()
        .skipUntil((v) => v === key)
        .skip(1)
        .first();

/**
 * Returns a sub-map containing only the blocks that match the range.
 */
export default (blockMap, range) => {
    const { startKey, endKey } = range;

    const afterEndKey = getKeyAfter(blockMap, endKey);

    return blockMap
        .skipUntil((contentBlock) => contentBlock.get('key') === startKey)
        .takeWhile((contentBlock) => contentBlock.get('key') !== afterEndKey);
};
