// Lib
import memoizeOne from 'memoize-one';

// Utils
import { getImageEditorData, getImageEditorDataRotation } from './modal/imageModalUtils';
import { asRect, getHeight, getWidth, getX, getY } from '../../../common/maths/geometry/rect';
import {
    getDrawingData,
    getImageOriginalProp,
    getImageProp,
    getMedia,
} from '../../../common/elements/utils/elementPropertyUtils';

/**
 * Gets the crop rectangle if the image has specific media dimensions.
 * This might mean that Cmd+resize has been used to change the aspect ratio of the
 * image. If so, find the appropriate rectangle based on the centred image cropping
 * that occurs.
 */
const getCroppedMediaRect = (imageRect, mediaData) => {
    if (!mediaData) return imageRect;

    const mediaWidth = getWidth(mediaData);
    const mediaHeight = getHeight(mediaData);
    const mediaAspectRatio = mediaWidth / mediaHeight;

    const imageWidth = getWidth(imageRect);
    const imageHeight = getHeight(imageRect);
    const imageAspectRatio = imageWidth / imageHeight;

    if (mediaAspectRatio === imageAspectRatio) return null;

    const isCroppedHorizontally = mediaAspectRatio < imageAspectRatio;

    if (isCroppedHorizontally) {
        const width = imageHeight * mediaAspectRatio;

        return {
            x: (imageWidth - width) / 2 + getX(imageRect),
            y: getY(imageRect),
            width,
            height: imageHeight,
        };
    }

    const height = imageWidth / mediaAspectRatio;

    return {
        x: getX(imageRect),
        y: (imageHeight - height) / 2 + getY(imageRect),
        width: imageWidth,
        height,
    };
};

/**
 * Determines the crop rectangle for the image.
 * If the image hasn't been cropped using the image editor, then it's the full image dimensions.
 * If the image has been cropped then it's the crop dimensions (scaled if the drawing has a
 * different width to the image).
 */
const getImageCropRectangle = (element) => {
    const cropDimensions = getImageEditorData(element);
    const imageData = getImageOriginalProp(element) || getImageProp(element);

    // If the image isn't cropped, use the full width and height
    if (!cropDimensions) {
        return asRect({
            x: 0,
            y: 0,
            width: getWidth(imageData),
            height: getHeight(imageData),
        });
    }

    // Otherwise use the crop data to find the cropped rectangle
    const drawingData = getDrawingData(element);
    const drawingWidth = getWidth(drawingData);

    const rotation = getImageEditorDataRotation(element);
    const isRotatedOnSide = !!rotation && rotation !== -180;

    const originalImageData = getImageOriginalProp(element);
    const originalImageWidth = isRotatedOnSide ? getHeight(originalImageData) : getWidth(originalImageData);

    // If the drawing doesn't currently have a width, then it will be the same scale as the original image
    const drawingScale = drawingWidth ? drawingWidth / originalImageWidth : 1;

    const croppedX = drawingScale * getX(cropDimensions);
    const croppedY = drawingScale * getY(cropDimensions);
    const croppedWidth = drawingScale * getWidth(cropDimensions);
    const croppedHeight = drawingScale * getHeight(cropDimensions);

    return asRect({
        x: croppedX,
        y: croppedY,
        width: croppedWidth,
        height: croppedHeight,
    });
};

/**
 * Determines the crop rectangle for an image's drawing based on whether the image has been
 * cropped using the image editor, and/or if the image has been cropped using a manual
 * media size (e.g. using Cmd+resize).
 */
export const getImageDrawingCropRect = memoizeOne((element, mediaSize) => {
    const cropDimensions = getImageEditorData(element);
    const mediaData = mediaSize || getMedia(element);

    if (!cropDimensions && !mediaData) return null;

    // First get the crop rectangle for the image being rendered
    const imageCropRect = getImageCropRectangle(element);

    // Then crop it again if there's a manual media crop on the image
    return getCroppedMediaRect(imageCropRect, mediaData);
});
