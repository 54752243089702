// Lib
import { curry } from 'lodash/fp';

// Utils
import { hasPermission } from '../../permissions/permissionUtil';
import { getElementType } from './elementPropertyUtils';

import { ELEMENT_UPDATE_TYPE } from '../elementConstants';
import { ACCESS_BITS } from '../../permissions/permissionConstants';
import { ElementType } from '../elementTypes';

export const getRequiredEditPermission = (elementOrUpdateType) => {
    switch (getElementType(elementOrUpdateType)) {
        case ElementType.COMMENT_THREAD_TYPE:
        case ElementType.ANNOTATION_TYPE:
        case ELEMENT_UPDATE_TYPE.REACTION:
        case ELEMENT_UPDATE_TYPE.TOGGLE_REACTION:
            return ACCESS_BITS.FEEDBACK;
        default:
            return ACCESS_BITS.WRITE;
    }
};

export const getRequiredSavePermission = (elementOrUpdateType) => {
    switch (getElementType(elementOrUpdateType)) {
        case ElementType.COMMENT_THREAD_TYPE:
        case ElementType.ANNOTATION_TYPE:
        case ELEMENT_UPDATE_TYPE.REACTION:
        case ELEMENT_UPDATE_TYPE.TOGGLE_REACTION:
            return ACCESS_BITS.FEEDBACK;
        default:
            return ACCESS_BITS.SAVE;
    }
};

export const validateElementTypeEditPermission = curry((permissions, element) =>
    hasPermission(getRequiredEditPermission(element), permissions),
);

export const validateElementTypeSavePermission = curry((permissions, element) =>
    hasPermission(getRequiredSavePermission(element), permissions),
);
