import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const TaskDragIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon task-drag"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M12.5 11a.5.5 0 1 1 0 1h-9a.5.5 0 1 1 0-1zm0-4a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1zm0-4a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1z"
        />
    </svg>
);
const Memo = memo(TaskDragIcon);
export default Memo;
