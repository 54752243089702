// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './RichMediaOverlay.scss';

const RichMediaOverlay = ({ show, children, className }) => {
    if (!show) return null;

    return <div className={classNames('RichMediaOverlay', className)}>{children}</div>;
};

RichMediaOverlay.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

export default RichMediaOverlay;
