// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { onlyUpdateForPropTypes } from '../../../../node_module_clones/recompose';

// Utils
import { getError } from '../../attachments/attachmentsSelector';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import FileUploaderErrorContent from './FileUploaderErrorContent';
import FileUploaderContent from './FileUploaderContent';

// Styles
import './FileUploader.scss';

/**
 * Creates a component which enables the user to upload an image to the server.
 * This component will delegate the handling of uploading the file to the function passed in on the 'uploadImage'
 * property.
 */
class FileUploader extends React.Component {
    render() {
        const { element, className, fileIsHovered, fileCanDrop, connectFileDropTarget, attachment } = this.props;
        const error = getError(attachment);

        const displayElement = error ? (
            <FileUploaderErrorContent elementId={getElementId(element)} error={error} {...this.props} />
        ) : (
            <FileUploaderContent elementId={getElementId(element)} {...this.props} />
        );

        const classes = classNames('FileUploader drag-handle', className, {
            hovered: fileIsHovered && fileCanDrop,
        });

        const renderUploader = <div className={classes}>{displayElement}</div>;

        return connectFileDropTarget ? connectFileDropTarget(renderUploader) : renderUploader;
    }
}

FileUploader.propTypes = {
    element: PropTypes.object.isRequired,
    className: PropTypes.string,
    attachment: PropTypes.object,
    uploadFile: PropTypes.func,
    fileIsHovered: PropTypes.bool,
    fileCanDrop: PropTypes.bool,
    connectFileDropTarget: PropTypes.func,
};

export default onlyUpdateForPropTypes(FileUploader);
