import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getTableContent } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { asObject, length, toArray } from '../../../../../../common/utils/immutableHelper';
import { getSharedFormattingValues } from '../../../../../element/table/utils/tableCellTypeUtils';
import { getAllCellsBetween } from '../../../../../element/table/utils/tableCellSelectionUtils';

// Selectors
import { getCurrentCellSelections } from '../../../../../element/table/tableSelector';
import { userLanguagePreferenceSelector } from '../../../../../user/currentUserSelector';

// Components
import ToolbarPopupTool from '../../ToolbarPopupTool';
import CellTypePopupContainer from './CellTypeToolbarPopup';
import Icon from '../../../../../components/icons/Icon';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import { CellTypeConstants } from '../../../../../../common/table/CellTypeConstants';

const mapStateToProps = createStructuredSelector({
    currentCellSelections: getCurrentCellSelections,
    locale: userLanguagePreferenceSelector,
});

const CellTypeToolIcon = (props) => {
    const { activeCellType } = props;

    const iconName = CellTypeConstants[activeCellType]?.icon || 'toolbar-cell-type';
    return <Icon name={iconName} />;
};

CellTypeToolIcon.propTypes = {
    activeCellType: PropTypes.string,
};

const CellTypeTool = (props) => {
    const { currentCellSelections, element } = props;
    const allSelectedCells = getAllCellsBetween(toArray(currentCellSelections));
    const { data } = asObject(getTableContent(element));

    // formatting options that are shared between all the selected cells
    // these will be the preselected options in the popup
    const sharedFormattingValues = getSharedFormattingValues(data, allSelectedCells);
    const activeCellType = sharedFormattingValues?.name;

    // Keep track of the type that was clicked last so that we can show the formatting options
    const [lastClickedType, setLastClickedType] = useState(null);

    useEffect(() => {
        setLastClickedType(null);
    }, [currentCellSelections]);

    return (
        <ToolbarPopupTool
            {...props}
            name="Cell type"
            popupId={PopupIds.CELL_TYPE}
            active={false}
            IconComponent={CellTypeToolIcon}
            toolName="CellType"
            disabled={length(currentCellSelections) === 0}
            buttonSelector=".CelltypeTool .Icon"
            PopupComponent={CellTypePopupContainer}
            activeCellType={activeCellType}
            sharedFormattingValues={sharedFormattingValues}
            allSelectedCells={allSelectedCells}
            lastClickedType={lastClickedType}
            setLastClickedType={setLastClickedType}
        />
    );
};

CellTypeTool.propTypes = {
    name: PropTypes.string,
    iconName: PropTypes.string,
    element: PropTypes.object,
    currentCellSelections: PropTypes.object,
    dispatchAddRow: PropTypes.func,
};

export default connect(mapStateToProps, null)(CellTypeTool);
