import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuDuplicateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-duplicate"
        {...props}
    >
        <g fill="#323B4A">
            <path d="M19.5 7.65a2.25 2.25 0 0 1 2.25 2.25v9.6a2.25 2.25 0 0 1-2.25 2.25H9.9a2.25 2.25 0 0 1-2.25-2.25V9.9A2.25 2.25 0 0 1 9.9 7.65h9.6zm0 1.5H9.9a.75.75 0 0 0-.75.75v9.6c0 .414.336.75.75.75h9.6a.75.75 0 0 0 .75-.75V9.9a.75.75 0 0 0-.75-.75z" />
            <path d="M13.8 2.25a2.555 2.555 0 0 1 2.55 2.55.75.75 0 1 1-1.5 0c0-.576-.474-1.05-1.05-1.05h-9c-.576 0-1.05.474-1.05 1.05v9c0 .576.474 1.05 1.05 1.05a.75.75 0 1 1 0 1.5 2.555 2.555 0 0 1-2.55-2.55v-9A2.555 2.555 0 0 1 4.8 2.25h9z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuDuplicateIcon);
export default Memo;
