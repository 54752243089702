// Lib
import { map } from 'lodash';

// Selectors
import { elementGraphSelector } from '../element/selectors/elementGraphSelector';

// Util
import { getElement } from '../../common/elements/utils/elementTraversalUtils';
import { getInboxElements } from '../../common/elements/utils/elementGraphUtils';
import { getNewTransactionId } from '../utils/undoRedo/undoRedoTransactionManager';
import { updateElement, updateMultipleElements } from '../element/actions/elementActions';
import { getElements } from '../element/selectors/elementsSelector';
import { isBoard } from '../../common/elements/utils/elementTypeUtils';
import { isInboxClosed, isInboxOpen } from '../element/board/boardUtils';
import { getTimestamp } from '../../common/utils/timeUtil';
import { getInboxAutomaticOpenTimestamp } from '../../common/elements/utils/elementPropertyUtils';

// Constants
import { InboxState, INBOX_CLOSE_BUFFER_TIME } from './inboxConstants';
import { BoardSections } from '../../common/boards/boardConstants';

export const openInbox = ({
    id,
    sync = true,
    transactionId = getNewTransactionId(),
    setInboxAutomaticOpenTimestamp = false,
}) =>
    updateElement({
        id,
        changes: {
            inbox: InboxState.OPEN,
            inboxAutomaticOpenTimestamp: setInboxAutomaticOpenTimestamp ? getTimestamp() : null,
        },
        sync,
        transactionId,
    });

export const closeInbox = ({ id, sync = true, transactionId = getNewTransactionId() }) =>
    updateElement({
        id,
        changes: {
            inbox: InboxState.CLOSED,
            inboxAutomaticOpenTimestamp: null,
        },
        sync,
        transactionId,
    });

// --------------------------------------------------
// logic for if we shoud open inbox on element move
// --------------------------------------------------

const shouldCloseCurrentBoardInbox = (toLocation, fromLocation, state) => {
    if (fromLocation.section !== BoardSections.INBOX) return false;

    // never close the inbox if you're dragging to the inbox
    if (fromLocation.parentId === toLocation.parentId && toLocation.section === BoardSections.INBOX) return false;

    const elements = getElements(state);

    const currentParentEl = getElement(elements, fromLocation.parentId);

    // If not a board then we shouldn't worry about the inbox state
    if (!isBoard(currentParentEl)) return false;

    // If inbox is already closed, don't close it again
    if (isInboxClosed(currentParentEl)) return false;

    const elementGraph = elementGraphSelector(state);
    const currentInboxChildren = getInboxElements({ elements, elementGraph, parentId: fromLocation.parentId });

    const currentBoardInboxAutomaticOpenTimestamp = getInboxAutomaticOpenTimestamp(currentParentEl);

    if (currentInboxChildren.size > 0 && !currentBoardInboxAutomaticOpenTimestamp) return false;

    const currentInboxChildrenLocationSectionModifiedTimestamps = currentInboxChildren
        .map((child) => child.getIn(['meta', 'locationSectionModifiedTime']))
        .toArray()
        .filter((timestamp) => timestamp);

    // have elements been added to the inbox since it was last opened automatically
    const hasRecentlyAddedElements = currentInboxChildrenLocationSectionModifiedTimestamps.some(
        (timestamp) => timestamp + INBOX_CLOSE_BUFFER_TIME > currentBoardInboxAutomaticOpenTimestamp,
    );

    // if there's still chidren in the current inbox, dont close
    if (currentInboxChildren.size > 0 && hasRecentlyAddedElements) return false;

    return true;
};

const shouldOpenTargetInbox = (tolocation, fromLocation, state) => {
    // never open up inbox if you're moving to canvas
    if (tolocation.section === BoardSections.CANVAS) return false;

    // never open up the inbox automatically if you're on the same parent
    if (fromLocation.parentId === tolocation.parentId) return false;

    // trash doesn't have an inbox!
    if (tolocation.parentId.indexOf('trash') !== -1) return false;

    const elements = getElements(state);
    const parentEl = getElement(elements, tolocation.parentId);

    // If not a board then we shouldn't worry about the inbox state
    if (!isBoard(parentEl)) return false;

    // if inbox is already open, don't open it again
    if (isInboxOpen(parentEl)) return false;

    return true;
};

/**
 * Performs a multi-update which includes any of the inbox state changes that should occur as a result of the moves.
 */
export const setInboxFromMultiElementMove =
    ({ moves, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) => {
        const state = getState();

        // Determine the inbox state changes that need to be made as a result of the moves
        const inboxStateMap = moves.reduce((inboxMap, move) => {
            const fromLocation = move.from;
            const toLocation = move.location;

            if (shouldCloseCurrentBoardInbox(toLocation, fromLocation, state)) {
                inboxMap[fromLocation.parentId] = InboxState.CLOSED;
            }
            if (shouldOpenTargetInbox(toLocation, fromLocation, state)) {
                inboxMap[toLocation.parentId] = InboxState.OPEN;
            }
            return inboxMap;
        }, {});

        // Build an array of updates given the inbox state changes that need to be made
        const inboxUpdates = map(inboxStateMap, (inboxState, id) => {
            const changes = { inbox: inboxState };

            //  If we're opening the inbox, then set the automatic open timestamp
            if (inboxState === InboxState.OPEN) {
                changes.inboxAutomaticOpenTimestamp = getTimestamp();
            }

            return {
                id,
                changes,
            };
        });

        // If there's no updates, then don't dispatch the action.
        if (inboxUpdates.length === 0) return;

        dispatch(updateMultipleElements({ updates: inboxUpdates, transactionId }));
    };

// --------------------------------------------------
// logic for if we should open inbox on element create
// --------------------------------------------------

const shouldOpenInboxFromElementCreate = (toLocation, state) => {
    const { parentId, section } = toLocation;

    // don't open the inbox if we aren't creating something in the inbox
    if (section !== BoardSections.INBOX) return false;

    const elements = getElements(state);
    const parentEl = getElement(elements, parentId);

    if (!isBoard(parentEl)) return false;

    // if inbox is already open, don't open it again
    if (isInboxOpen(parentEl)) return false;

    return true;
};

export const setInboxFromElementCreate =
    ({ location, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) => {
        const state = getState();
        const shouldOpenInbox = shouldOpenInboxFromElementCreate(location, state);

        if (shouldOpenInbox)
            dispatch(openInbox({ id: location.parentId, transactionId, setInboxAutomaticOpenTimestamp: true }));
    };
