// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import animateFadeDecorator from '../animations/animateFadeDecorator';
import { isPlatformNativeApp } from '../../platform/utils/platformDetailsUtils';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// manually base-64 encoded version of ./sprites/logo-preloader-single-frame.gif
const STATIC_LOGO_BASE_64 =
    'url(data:image/gif;base64,R0lGODlhMAAwAPMAADU1NTo6Oj09PUREREVFRUZGRkdHR0hISFJSUlRUVGhoaAAAAAAAAAAAAAAAAAAAACH5BAAAAAAAIf8LSW1hZ2VNYWdpY2sOZ2FtbWE9MC40NTQ1NDUALAAAAAAwADAAAATqEASEBrg46837FUliDUqpWF6qaqQ5mSW6zlxrIrAp07yd/ydeD0iMCVe+4m93ZCmfzCYg+QRGhdSqVTrVLrfHbDbXXWKBB0D1Iqa11U/MOzVfy8GdOVzpNNfwF3YZemyAgXEbeoSHfH9+i4xFHmKQkUR0XlcYgnlVmpuImEWfoI0qY6SlkkhEqZZArKMrnI5KqbR9nim4d0uVe6uJYJW8ioavP8J+g8e0lYScv2U5O4LShct2173UyGTbzGaexysHVl7dUmPoRlzT7DCusfDt7tz0+Pn6+/z9MDj+8iEAFpDdiAQF0SEIACACADs=)';

const LogoSpinner = (props) => {
    const { animate = true } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);

    // In order to work on the server render, it can't use require, but to work in Electron/mobile hybrid it must
    const imgSrc = isPlatformNativeApp(platformDetails)
        ? require('./sprites/milanote-loader-sprite-210727.png')
        : '/img/milanote-loader-sprite-210727.png';

    // NOTE: We need an absolute URL for the sprite due to being used in server rendering
    return (
        <div className="LogoSpinner">
            <svg
                viewBox="0 0 54 54"
                width={54}
                height={54}
                style={{ position: 'absolute' }}
                className="path logo-spinner-circle"
            >
                <circle cx={27} cy={27} r={27} strokeWidth={2} strokeMiterlimit="10" stroke="none" fill="#353535" />
            </svg>
            <div
                className="logo-container"
                style={{
                    backgroundImage: STATIC_LOGO_BASE_64,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '24px 24px',
                }}
            >
                {animate && (
                    <img
                        className="animation-frame"
                        src={imgSrc}
                        width={24}
                        height={5280}
                        alt="Milanote loading icon"
                    />
                )}
            </div>
        </div>
    );
};

LogoSpinner.propTypes = {
    animate: PropTypes.bool,
};

export default animateFadeDecorator()(LogoSpinner);
