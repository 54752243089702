import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ModalToolbarRotateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon modal-toolbar-rotate"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M18 13a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h10zm-.5 1.5h-9v7h9v-7zm1.384-8.253a.5.5 0 0 1 .116.32v.683h1a4.75 4.75 0 0 1 4.745 4.533l.005.217v2a.75.75 0 0 1-1.493.102L23.25 14v-2a3.25 3.25 0 0 0-3.066-3.245L20 8.75h-1v.682a.5.5 0 0 1-.82.385l-1.72-1.433a.5.5 0 0 1 0-.768l1.72-1.433a.5.5 0 0 1 .704.064z"
        />
    </svg>
);
const Memo = memo(ModalToolbarRotateIcon);
export default Memo;
