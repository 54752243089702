import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Selectors
import { getCurrentCellSelections } from '../../../../../element/table/tableSelector';
import { userLanguagePreferenceSelector } from '../../../../../user/currentUserSelector';

// Components
import ToolbarPopupTool from '../../ToolbarPopupTool';
import Icon from '../../../../../components/icons/Icon';
import TableCellAlignmentToolbarPopup from './TableCellAlignmentToolbarPopup';

// Utils
import { getAllCellsBetween } from '../../../../../element/table/utils/tableCellSelectionUtils';
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { length } from '../../../../../../common/utils/immutableHelper';
import { getCellTextAlignment } from '../../../../../element/table/utils/tableCellFormattingUtils';
import HotTableManager from '../../../../../element/table/manager/HotTableManager';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import { TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT } from '../../../../../../common/table/tableConstants';

const mapStateToProps = createStructuredSelector({
    currentCellSelections: getCurrentCellSelections,
    locale: userLanguagePreferenceSelector,
});

const TableCellAlignmentTool = (props) => {
    const { currentCellSelections, element, locale } = props;

    const elementId = getElementId(element);
    const hotTableInstance = HotTableManager.getHotTableComponent(elementId);

    // Get common text alignment of all selected cells
    const commonTextAlignment = useMemo(() => {
        if (
            !currentCellSelections ||
            length(currentCellSelections) === 0 ||
            !hotTableInstance.hotTableInstanceRef.current
        )
            return;

        const allCells = getAllCellsBetween(currentCellSelections);
        const firstCell = allCells[0];
        const { cellData } = hotTableInstance.hotTableInstanceRef.current.getCellMeta(firstCell.row, firstCell.col);
        const displayValue = hotTableInstance.hotTableInstanceRef.current.getDataAtCell(firstCell.row, firstCell.col); // For formulas, will return formula result

        const firstTextAlignment = getCellTextAlignment(cellData, locale, displayValue);

        if (!firstTextAlignment) return;

        const hasDifferentTextAlignments = allCells.some(({ row, col }) => {
            const { cellData } = hotTableInstance.hotTableInstanceRef.current.getCellMeta(row, col);
            const displayValue = hotTableInstance.hotTableInstanceRef.current.getDataAtCell(row, col); // For formulas, will return formula result

            const textAlignment = getCellTextAlignment(cellData, locale, displayValue);
            return textAlignment !== firstTextAlignment;
        });

        if (hasDifferentTextAlignments) return;

        return firstTextAlignment;
    }, [element, currentCellSelections]);

    // Get common vertical alignment of all selected cells
    const commonVerticalAlignment = useMemo(() => {
        if (!currentCellSelections || length(currentCellSelections) === 0) return TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT;

        const allCells = getAllCellsBetween(currentCellSelections);
        const { cellData: firstCellData } = hotTableInstance.hotTableInstanceRef.current.getCellMeta(
            allCells[0].row,
            allCells[0].col,
        );
        const firstVerticalAlignment = firstCellData?.verticalAlignment;

        if (!firstVerticalAlignment) return TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT;

        const hasDifferentVerticalAlignments = allCells.some(({ row, col }) => {
            const { cellData } = hotTableInstance.hotTableInstanceRef.current.getCellMeta(row, col);
            return cellData?.verticalAlignment !== firstCellData?.verticalAlignment;
        });

        if (hasDifferentVerticalAlignments) return TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT;

        return firstVerticalAlignment;
    }, [element, currentCellSelections]);

    return (
        <ToolbarPopupTool
            {...props}
            name="Alignment"
            currentCellSelections={currentCellSelections}
            hotTableInstance={hotTableInstance}
            commonTextAlignment={commonTextAlignment}
            commonVerticalAlignment={commonVerticalAlignment}
            popupId={PopupIds.TABLE_CELL_ALIGNMENT_TOOL}
            active={false}
            toolName={'CellAlignment'}
            disabled={length(currentCellSelections) === 0}
            buttonSelector=".CellalignmentTool .icon"
            PopupComponent={TableCellAlignmentToolbarPopup}
        >
            <Icon name={`toolbar-align-${commonTextAlignment?.toLowerCase() || 'justified'}`} />
        </ToolbarPopupTool>
    );
};

TableCellAlignmentTool.propTypes = {
    iconName: PropTypes.string,
    element: PropTypes.object,
    locale: PropTypes.string,
    currentCellSelections: PropTypes.object,
    dispatchAddRow: PropTypes.func,
};

export default connect(mapStateToProps, null)(TableCellAlignmentTool);
