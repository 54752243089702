// Handlers
import handleLinkSubmission from './handleLinkSubmission';
import handleChecklistReturn from './task/handleChecklistReturn';
import handleChecklistBeforeInput from './task/handleChecklistBeforeInput';
import handleColorSubmission from './handleColorSubmission';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';

/**
 * Requires the following props:
 *  - changeType
 */
export const createLinkConverterPlugin = () => ({
    handleReturn: (event, editorState, { getProps }) =>
        handleLinkSubmission(editorState, getProps) ? HANDLER_RETURN_VALUES.handled : HANDLER_RETURN_VALUES.notHandled,
    onBlur: (event, { getEditorState, getProps }) => {
        const editorState = getEditorState();
        handleLinkSubmission(editorState, getProps);
    },
});

export const createColorConverterPlugin = () => ({
    handleReturn: (event, editorState, { getProps }) =>
        handleColorSubmission(editorState, getProps) ? HANDLER_RETURN_VALUES.handled : HANDLER_RETURN_VALUES.notHandled,
    onBlur: (event, { getEditorState, getProps }) => {
        const editorState = getEditorState();
        handleColorSubmission(editorState, getProps);
    },
});

export const createTaskConverterPlugin = () => ({
    handleBeforeInput: handleChecklistBeforeInput,
    handleReturn: handleChecklistReturn,
});
