// Utils
import getNewLine from './getNewLine';

/**
 * Return an anchor of a cursor in a block as a {line,offset} object
 */
const getLineAnchorForOffset = (text, offset, sep) => {
    const separator = sep || getNewLine(text);

    let lineIndex = 0;
    let nextLineIndex = 0;
    let lastLineIndex = 0;

    while (nextLineIndex >= 0 && nextLineIndex < offset) {
        lineIndex++;

        lastLineIndex = nextLineIndex;
        nextLineIndex = text.indexOf(separator, nextLineIndex + separator.length);
    }

    return {
        line: Math.max(lineIndex - 1, 0),
        offset: offset - lastLineIndex,
    };
};

export default getLineAnchorForOffset;
