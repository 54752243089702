import { AxiosHeaders } from 'axios';
import logger from '../../../logger/logger';
import { AWS_HEADERS } from '../../../../common/utils/http/httpConstants';

interface AwsWafIntegration {
    getToken: () => Promise<string>;
}

declare global {
    interface Window {
        AwsWafIntegration: AwsWafIntegration | undefined;
    }
}

export const awsWafIntegrationAvailable = (): boolean => window.AwsWafIntegration !== undefined;

/**
 * Get token from AWS WAF, if not already stored, and store as the cookie `aws-waf-token`.
 * This token should automatically be sent as a cookie with all requests to the API,
 * or can be sent explicitly as a header `x-aws-waf-token`.
 */
export const getWafToken = async (): Promise<string | undefined> => {
    // Where WAF script is not loaded (e.g. blocked, or unsupported client such as electron, mobile app web views).
    // Send the request without fetching a new token first.
    if (!awsWafIntegrationAvailable()) return undefined;

    return await window.AwsWafIntegration?.getToken().catch((err) => {
        // Need to catch the error here to prevent it from bubbling up to the UI (login page or error modal).
        logger.warn('Failed to generate a firewall token', err);
        return undefined;
    });
};

export const getWafTokenAndSetHeader = async (headers: AxiosHeaders): Promise<void> => {
    if (!headers) return;
    const wafToken = await getWafToken();
    wafToken && headers.set(AWS_HEADERS.WAF_TOKEN, wafToken);
};
