// Lib
import { EditorState, Modifier, SelectionState } from 'draft-js';

import { EditorChangeType } from '../draftjsConstants';
import shiftSelection from './selection/shiftSelection';

export const insertTextInContentStateAt = (insertionText) => (positionSelection) => (contentState) => {
    // Force the text to be inserted at the start of the position specified
    let insertSelection = SelectionState.createEmpty(positionSelection.getStartKey());
    insertSelection = insertSelection.merge({
        anchorKey: positionSelection.getStartKey(),
        anchorOffset: positionSelection.getStartOffset(),
        focusKey: positionSelection.getStartKey(),
        focusOffset: positionSelection.getStartOffset(),
        isBackward: false,
        hasFocus: true,
    });

    // This point onwards is a reimplementation of the Draft JS RichUtils.insertSoftNewline
    // I'm reimplementing it to prevent multiple undo operations being added to the Draft undo stack
    return Modifier.insertText(
        contentState,
        insertSelection,
        insertionText,
        // TODO Should I worry about inline style here?
        // editorState.getCurrentInlineStyle(),
        null,
        null,
    );
};

export default (insertionText) => (positionSelection) => (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    let newContentState = insertTextInContentStateAt(insertionText)(positionSelection)(contentState);

    const textLength = insertionText.length;
    const updatedSelection = shiftSelection(selection, textLength, textLength);
    newContentState = newContentState.set('selectionAfter', updatedSelection);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS);

    return EditorState.forceSelection(newEditorState, updatedSelection);
};
