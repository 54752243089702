// Lib
import React from 'react';

// Components
import FullScreenErrorModal from '../../components/modal/FullScreenErrorModal';
import ModalActionsList from '../../components/modal/ModalActionsList';

const PlatformCookieFullScreenWarning = () => (
    <FullScreenErrorModal>
        <h2>Sorry, you need to enable storage to use Milanote</h2>
        <p className="explanation">
            Milanote uses your browser&apos;s storage to save data while you&apos;re working, please enable it and try
            again.
        </p>
        <ModalActionsList actions={[{ text: 'Find out more', href: 'http://www.whatarecookies.com/' }]} />
    </FullScreenErrorModal>
);

PlatformCookieFullScreenWarning.propTypes = {};

export default PlatformCookieFullScreenWarning;
