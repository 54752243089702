import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ReminderBellMiniIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        viewBox="0 0 12 12"
        className="Icon reminder-bell-mini"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="#45971D">
            <path
                strokeLinejoin="round"
                d="M11 9.383V9.5H1v-.117c0-.465.191-.91.53-1.23.619-.587 1.048-1.873 1.288-3.859C3.037 2.484 4.243 1 6 1s2.963 1.484 3.182 3.294c.24 1.986.67 3.272 1.289 3.859.338.32.529.765.529 1.23z"
            />
            <path d="M4.5 10a1.5 1.5 0 0 0 3 0" />
        </g>
    </svg>
);
const Memo = memo(ReminderBellMiniIcon);
export default Memo;
