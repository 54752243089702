// Lib
import { cloneDeep, isEqual } from 'lodash';
import { convertFromRaw, RawDraftContentState } from 'draft-js';
import Handsontable from 'handsontable/base';

// Utils
import { propIn } from '../../../../common/utils/immutableHelper';
import { createTableCellEntry } from '../../../../common/table/utils/tableInitialisationUtils';
import { cellIsReadOnly } from './tableCellTypeUtils';
import doesEditorJsonHaveText from '../../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';
import { isFormula } from './tableFormulaUtils';
import { getCellLevelTextStyles } from './tableCellFormattingUtils';

// Types
import { CellData, CellDataChange } from '../../../../common/table/TableTypes';

// Constants
import { TABLE_CELL_EDITOR_KEY, TABLE_GRID_EDITOR_KEY } from '../../../../common/table/tableConstants';
import { parseCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';
import { ElementType } from '../../../../common/elements/elementTypes';
import { getColumnCount, getRowCount } from '../../../../common/table/utils/tableCellDataPropertyUtils';
import { cellCanBeDraftFormatted, formatCellAsType } from '../../../../common/table/utils/tableCellTypeUtils';

export const getTableEditorId = (elementId: string): string => `${elementId}-${ElementType.TABLE_TYPE}`;
export const getTableCellEditorId = (elementId: string): string => `${elementId}-${TABLE_CELL_EDITOR_KEY}`;
export const getTableGridEditorId = (elementId: string): string => `${elementId}-${TABLE_GRID_EDITOR_KEY}`;

const isCellContentPopulated = (content: RawDraftContentState | string | number | null): boolean => {
    if (typeof content === 'string') return content !== '';

    if (typeof content === 'number') return true;

    return doesEditorJsonHaveText(content);
};

/**
 * Deduce a new cellData based on previous cell data and new value coming in
 */
export const createNewCellData = (
    prevCellData: CellData,
    newCellValue: string | null,
    locale: string,
    currencyPreference: string,
): CellData => {
    let newCellData = cloneDeep(prevCellData) || createTableCellEntry();
    newCellData.value = newCellValue;

    const content = newCellData.value && parseCellContentString(newCellData.value);
    const hasContent = isCellContentPopulated(content);

    // If no content, always save as null for consistency
    if (!hasContent) {
        newCellData.value = null;

        if (cellIsReadOnly(newCellData)) {
            delete newCellData.type;
        }

        return newCellData;
    }

    // Check if we should set any cell type options by the cell contents,
    // e.g. change input of '$250,000' to a currency cell with value 25000
    newCellData = formatCellAsType(newCellData, prevCellData.value, locale, currencyPreference);

    // For cell content is not in draftJS format or cell is readonly cell, don't apply any formatting from inline styles
    if (typeof content !== 'object' || cellIsReadOnly(newCellData)) return newCellData;

    // if the inline styles don't match the cell level style, or the cell can't be draft formatted,
    // then update the cell level style to match the styles applied to the entire content
    const cellLevelTextStyles: string[] = content ? getCellLevelTextStyles(convertFromRaw(content)) : [];

    const stylesMatch = isEqual(cellLevelTextStyles.sort(), newCellData.textStyle?.sort() || []);
    if (!stylesMatch || !cellCanBeDraftFormatted(newCellData)) {
        newCellData.textStyle = cellLevelTextStyles;
    }

    return newCellData;
};

/**
 * Converts all cell changes [row, col, prevValue, newValue] to cellData changes [row, col, prevCellData, newCellData]
 */
export const createCellDataChanges = (
    changes: Array<Handsontable.CellChange>,
    data: Array<Array<CellData>>,
    locale: string,
    currencyPreference: string,
): Array<CellDataChange> => {
    return changes.map((change, index) => {
        const [row, col, , newCellValue] = change;

        const prevCellData = propIn([row, col], data);
        const newCellData = createNewCellData(prevCellData, newCellValue, locale, currencyPreference);

        return [row, col, prevCellData, newCellData];
    });
};

/**
 * Converts cell changes [row, col, prevValue, newValue] to cellDataChanges [row, col, prevCellData, newCellData],
 * specifically for changes that are copied from a source, such as copy/paste or autofill.
 *
 * For these type of changes, we'd need to find the origin of the copied data and apply the changes to the origin
 * cell data.
 */
export const createCellDataChangesFromSource = (
    changes: Array<[number, number, any, any]>,
    data: Array<Array<CellData>>,
    source: string,
    sourceCellDataArray: Array<Array<CellData>>,
    locale: string,
) => {
    const minChangeRow = Math.min(...changes.map(([row]) => row));
    const minChangeCol = Math.min(...changes.map(([_, col]) => col));

    return changes.map((change) => {
        const [row, col, , newCellValue] = change;

        const prevCellData = propIn([row, col], data);

        if (sourceCellDataArray) {
            const nRowsOfSource = getRowCount(sourceCellDataArray);
            const nColsOfSource = getColumnCount(sourceCellDataArray);
            const sourceRow = (row - minChangeRow) % nRowsOfSource;
            const sourceCol = (col - minChangeCol) % nColsOfSource;

            const sourceData = cloneDeep(sourceCellDataArray[sourceRow][sourceCol]);

            if (source === 'Autofill.fill' && isFormula(sourceData.value)) {
                sourceData.value = newCellValue;
            }

            return [row, col, prevCellData, sourceData];
        } else {
            return [row, col, prevCellData, createTableCellEntry(newCellValue, locale)];
        }
    });
};
