import { overlappingRect } from '../../common/maths/geometry/rect';
import { getDomElementId } from '../element/utils/elementUtil';
import * as pointLib from '../../common/maths/geometry/point';

export const getWindowHeight = () => window.innerHeight || document.documentElement.clientHeight;
export const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth;

export const getViewportOffset = () => ({
    x: Math.max(global.visualViewport?.offsetLeft, 0),
    y: Math.max(global.visualViewport?.offsetTop, 0),
});

export const isDOMNodeInView = (domNode) => {
    const rect = domNode.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= getWindowHeight() && rect.right <= getWindowWidth();
};

/**
 * Translates the "client" position of events into the "page" position by adding the
 * viewport offset to the position.
 */
export const getPagePositionFromClientPosition = (clientPosition) => {
    if (!clientPosition) return null;
    const viewportOffset = getViewportOffset();
    return pointLib.translate(clientPosition, viewportOffset);
};

/**
 * Determines the relative position to a DOM node, based on a page position.
 */
export const getRelativePosition = (pagePosition, containerDomNode) => {
    if (!pagePosition || !containerDomNode) return;

    // We need to use the pageX/Y because the offsetX/Y changes based on the hovered element.  Because of this
    // we need to subtract the domNode's translation from the pageX/Y
    const eventListenerBoundingRect = containerDomNode.getBoundingClientRect();

    return {
        x: pagePosition.x - eventListenerBoundingRect.left,
        y: pagePosition.y - eventListenerBoundingRect.top,
    };
};

export const isRightButton = (event) => event.button === 2;
export const isPrimaryButton = (event) => event.button === 0;

export const preventDefaultOnly = (event) => event.preventDefault();
export const stopPropagationOnly = (event) => event.stopPropagation();
export const stopDefaultAndPropagation = (event) => {
    if (!event) return;

    event.preventDefault();
    event.stopPropagation();
};

export const selectEndOfText = (element) => {
    element.setSelectionRange(element.value.length, element.value.length);
};

export const selectAllText = (element) => {
    element.setSelectionRange(0, element.value.length);
};

export const selectAndCopyNode = (targetNode) => {
    targetNode.focus();
    selectAllText(targetNode);
    document.execCommand('copy');
};

export const selectAndCopyInput = (event) => selectAndCopyNode(event.target);
export const selectAllTextInput = (event) => {
    event.target.focus();
    selectAllText(event.target);
};

export const getSelection = () => {
    if (window.getSelection) return window.getSelection();
    if (document.getSelection) return document.getSelection();
    if (document.selection) return document.selection.createRange().text;
};

export const getSelectionText = () => {
    const selection = getSelection();

    if (!selection || selection.isCollapsed || !selection.toString) return null;

    return selection.toString();
};

const getContainerDOMNode = (domNode) => {
    if (domNode === null || !(domNode instanceof HTMLElement)) {
        return null;
    }

    const { overflowY } = getComputedStyle(domNode);

    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (isScrollable && domNode.scrollHeight > domNode.clientHeight) {
        return domNode;
    }

    return getContainerDOMNode(domNode.parentNode);
};

export const getElementBoundingRect = (domNode) => {
    if (!domNode) return new DOMRect(); // eslint-disable-line no-undef

    const size = domNode.getBoundingClientRect();

    const containerDomNode = getContainerDOMNode(domNode);

    const containerSize =
        containerDomNode && containerDomNode.getBoundingClientRect && containerDomNode.getBoundingClientRect();

    if (!containerDomNode) return size;

    const { x, y, width, height } = overlappingRect(size, containerSize);
    const boundingRect = new DOMRect(x, y, width, height); // eslint-disable-line no-undef

    return boundingRect;
};

export const getElementDOMMeasurement = (elementId) => {
    const elementDomNode = document.getElementById(getDomElementId(elementId));
    if (!elementDomNode) return null;

    return elementDomNode.getBoundingClientRect();
};

/**
 * Check if current node is the content of an element by checking the id
 * e.g. 'Card' or 'Table' component
 * @param domNode
 */
export const isElementContent = (domNode) => domNode.id && domNode.id.match(/^el-\S*/);
