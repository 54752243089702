import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ExportPdfIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={23}
        height={26}
        viewBox="0 0 23 26"
        className="Icon export-pdf"
        {...props}
    >
        <defs>
            <filter id="ExportPdfIcon__b" width="200%" height="200%" x="-50%" y="-50%" filterUnits="objectBoundingBox">
                <feOffset dy={-1} in="SourceAlpha" result="shadowOffsetInner1" />
                <feComposite
                    in="shadowOffsetInner1"
                    in2="SourceAlpha"
                    k2={-1}
                    k3={1}
                    operator="arithmetic"
                    result="shadowInnerInner1"
                />
                <feColorMatrix
                    in="shadowInnerInner1"
                    result="shadowMatrixInner1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feOffset dx={1} in="SourceAlpha" result="shadowOffsetInner2" />
                <feComposite
                    in="shadowOffsetInner2"
                    in2="SourceAlpha"
                    k2={-1}
                    k3={1}
                    operator="arithmetic"
                    result="shadowInnerInner2"
                />
                <feColorMatrix
                    in="shadowInnerInner2"
                    result="shadowMatrixInner2"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixInner1" />
                    <feMergeNode in="shadowMatrixInner2" />
                </feMerge>
            </filter>
            <path id="ExportPdfIcon__a" d="M0 4h12v7H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#CACACA" d="M3 0v26h20V6.999L16 0z" />
            <path fill="#FFF" d="M4 1h11v7h7v17H4z" />
            <path fill="#FFF" d="m16 1.417 5.581 5.582H16z" />
            <g>
                <use xlinkHref="#ExportPdfIcon__a" fill="#E60B0B" />
                <use xlinkHref="#ExportPdfIcon__a" fill="#000" filter="url(#ExportPdfIcon__b)" />
            </g>
        </g>
    </svg>
);
const Memo = memo(ExportPdfIcon);
export default Memo;
