import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarQuoteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-quote"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M11.939 16.128c0-1.536 1.036-2.496 3.476-2.848V12C11.538 12.256 10 14.432 10 17.056 10 18.72 10.836 20 12.507 20c1.237 0 2.206-.8 2.206-2.016 0-1.056-.568-1.664-1.504-1.856h-1.27zm6.585 0h1.27c.936.192 1.504.8 1.504 1.856 0 1.216-.97 2.016-2.206 2.016-1.671 0-2.507-1.28-2.507-2.944 0-2.624 1.538-4.8 5.415-5.056v1.28c-2.44.352-3.476 1.312-3.476 2.848z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarQuoteIcon);
export default Memo;
