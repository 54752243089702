// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import UserAvatar from '../../components/userAvatar/UserAvatar';

// Constants
import { USER_AVATAR_SIZES } from '../../../common/users/userConstants';

// Styles
import './CommentLine.scss';

const CommentLine = ({ className, children, user, active, onDoubleClick }) => (
    <div className={classNames('CommentLine', className)} onDoubleClick={onDoubleClick}>
        <div className="avatar">
            <UserAvatar user={user} size={USER_AVATAR_SIZES.SMALL} active={active} />
        </div>
        <div className="details">{children}</div>
    </div>
);

CommentLine.propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    active: PropTypes.bool,
    onDoubleClick: PropTypes.func,
};

export default CommentLine;
