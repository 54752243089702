// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

// Components
import ErrorBoundary from '../error/errorBoundary/ErrorBoundary';
import DefaultErrorRenderer from '../error/errorBoundary/DefaultErrorRenderer';

// Styles
import './FloatingPanelErrorRenderer.scss';

const getErrorDetails = ({ className, error }) => `Popup: ${className}
Error: ${error && error.toString()}
Stack: ${get('stack', error)}`;

const FloatingPanelErrorRenderer = (props) => (
    <div>
        <DefaultErrorRenderer
            {...props}
            furtherMessage=" or opening and closing the popup."
            className="FloatingPanelErrorRenderer"
            heading="Something went wrong"
            getErrorDetails={getErrorDetails}
        />
    </div>
);

const FloatingPanelErrorBoundary = (props) => {
    const { children } = props;

    return (
        <ErrorBoundary ErrorRenderer={FloatingPanelErrorRenderer} getErrorMessage={getErrorDetails}>
            {children}
        </ErrorBoundary>
    );
};

FloatingPanelErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FloatingPanelErrorBoundary;
