/**
 * This file takes care of the retrieving of the store's clipping state and the tracking of the hovered
 * status.
 * The actual saving of the clipping state occurs in the cardClipperEditorStateManager because this has
 * access to the editor store's editorState and we'd like to avoid connecting to it again for performance
 * reasons.
 */

// Lib
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { prop } from '../../../../common/utils/immutableHelper';

// Selectors
import { getElementId } from '../../selectors/elementSelector';
import { getClipperState } from './store/clipperSelector';
import { getDragModifierKeys } from '../../../utils/dnd/modifierKeys/dragModifierKeysSelector';

// Constants
import { MODIFIER_KEYS } from '../../../utils/dnd/modifierKeys/dragModifierKeysConstants';

const mapStateToProps = createSelector(
    getElementId,
    getClipperState,
    getDragModifierKeys,
    (elementId, clipperState, dragModifierKeys) => {
        const isClipping = prop('clippedElementId', clipperState) === elementId;

        return {
            isClipping,
            isClipCopy: isClipping && dragModifierKeys.get(MODIFIER_KEYS.altKey),
            isClipDragging: isClipping ? prop('isClipDragging', clipperState) : false,
            clippedText: isClipping ? prop('clippedText', clipperState) : null,
        };
    },
);

export default connect(mapStateToProps);
