// Lib
import * as Immutable from 'immutable';

// Constants
import { PASSWORD_RESET_REQUESTED, PASSWORD_RESET_REQUEST_FAILED } from './forgottenPasswordConstants';

// Initial state
const initialState = Immutable.Map({
    resetSent: false,
    resetEmail: '',
    isError: false,
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case PASSWORD_RESET_REQUESTED:
            return state.set('resetSent', true).set('resetEmail', action.email);
        case PASSWORD_RESET_REQUEST_FAILED:
            return state.set('isError', true).set('error', action.error);
        default:
            return state;
    }
};
