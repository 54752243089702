import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CursorRemoteEraserIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        className="Icon cursor-remote-eraser"
        {...props}
    >
        <defs>
            <filter
                id="CursorRemoteEraserIcon__a"
                width="201.3%"
                height="205.6%"
                x="-50.6%"
                y="-47.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter
                id="CursorRemoteEraserIcon__b"
                width="153.3%"
                height="155.6%"
                x="-26.6%"
                y="-22.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.3 0"
                />
            </filter>
            <path
                id="CursorRemoteEraserIcon__c"
                d="M10.83.885a3.02 3.02 0 0 1 4.272 0l2.776 2.776a3.02 3.02 0 0 1 0 4.271l-9.482 9.482A2 2 0 0 1 6.982 18H4.825a2.517 2.517 0 0 1-1.78-.737l-2.16-2.161a3.02 3.02 0 0 1 0-4.272L10.83.885z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#CursorRemoteEraserIcon__a)" transform="translate(3 3)">
            <use xlinkHref="#CursorRemoteEraserIcon__c" fill="#000" filter="url(#CursorRemoteEraserIcon__b)" />
            <use xlinkHref="#CursorRemoteEraserIcon__c" fill="#2F63F7" />
            <path
                fill="#FFF"
                d="m5.323 8.527 4.911 4.911-2.612 2.613a1.5 1.5 0 0 1-.912.432l-.148.007H4.825l-.132-.009a1.007 1.007 0 0 1-.58-.286l-2.16-2.161-.098-.108a1.51 1.51 0 0 1 .097-2.028l3.371-3.37zm6.683-6.672a1.51 1.51 0 0 1 2.028.097L16.81 4.73l.098.107a1.51 1.51 0 0 1-.098 2.028l-5.508 5.506-4.91-4.91 5.506-5.508z"
            />
        </g>
    </svg>
);
const Memo = memo(CursorRemoteEraserIcon);
export default Memo;
