// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, chunk } from 'lodash/fp';

const getToolComponent = ({ toolName, toolsConfig }) =>
    get([toolName, 'component'], toolsConfig) || get(['default', 'component'], toolsConfig);

/**
 * This component renders out tools from the tool list config in groups of groupSize (four).
 */
const GroupedToolList = ({ className, tools, toolsConfig, children, groupSize = 4, ...props }) => (
    <div className={classNames('GroupedToolList', className)}>
        {chunk(groupSize, tools).map((toolGroup, index) => (
            <div key={index} className="toolGroup">
                {toolGroup.map((toolName) => {
                    const toolProps = toolsConfig[toolName];
                    const Component = getToolComponent({ toolName, toolsConfig });

                    return (
                        <Component
                            key={toolName}
                            {...props}
                            {...toolProps}
                            toolsConfig={toolsConfig}
                            toolName={toolName}
                        >
                            {null}
                        </Component>
                    );
                })}
            </div>
        ))}
        {children}
    </div>
);

GroupedToolList.propTypes = {
    className: PropTypes.string,
    groupSize: PropTypes.number,
    toolsConfig: PropTypes.object,
    tools: PropTypes.array.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default GroupedToolList;
