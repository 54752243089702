import { TiptapContentNode } from '../../../tiptapTypes';

/*
 * Finds any values in the structure that match `marks: []` and deletes them.
 *
 * The default converters add `marks: []` to all mark-less content objects, while the actual
 * editor just leaves that key unset. There's no difference in practice, but the discrepancy
 * breaks the unit tests.
 */
export const recursivelyStripEmptyMarks = (content: TiptapContentNode): TiptapContentNode =>
    Object.fromEntries(
        Object.entries(content)
            .filter(([k, v]: [string, any]) => {
                if (k !== 'marks') return true;
                return !!v.length;
            })
            .map(([k, v]) => {
                if (!v) return [k, v];
                if (Array.isArray(v)) return [k, v.map(recursivelyStripEmptyMarks)];
                if (typeof v === 'object') return [k, recursivelyStripEmptyMarks(v)];
                return [k, v];
            }),
    );
