import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeAutoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-auto"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                d="M22 8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h12Zm0 1.5H10a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V10a.5.5 0 0 0-.5-.5Zm-5.008 3 2.507 7.273h-1.647l-.54-1.659h-2.625l-.54 1.659H12.5l2.51-7.273h1.982Zm-.962 1.662h-.057l-.895 2.752h1.847l-.895-2.752Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeAutoIcon);
export default Memo;
