// Utils
import Vector from '../../../../common/maths/geometry/Vector';
import * as pointLib from '../../../../common/maths/geometry/point';
import * as lineLib from '../../../../common/maths/geometry/line';

const SNAP_ANGLE = 45;
const DIAGONALLY_DOWN_RIGHT_ANGLE = -45;
const DIAGONALLY_UP_LEFT_ANGLE = 135;
const HORIZONTAL_ANGLE = 180;
const VERTICAL_ANGLE = 90;

const matchDiagonal = (inverse) => (vector) => {
    const factor = inverse ? -1 : 1;
    if (Math.abs(vector.x) > Math.abs(vector.y)) return new Vector(vector.x, factor * vector.x);
    return new Vector(factor * vector.y, vector.y);
};
const matchHorizontal = (vector) => new Vector(vector.x, 0);
const matchVertical = (vector) => new Vector(0, vector.y);

const getSnapAngleRule = (snappedAngle) => {
    if (snappedAngle % HORIZONTAL_ANGLE === 0) return matchHorizontal;
    if (snappedAngle % VERTICAL_ANGLE === 0) return matchVertical;
    const inverseDiagonal = snappedAngle === DIAGONALLY_DOWN_RIGHT_ANGLE || snappedAngle === DIAGONALLY_UP_LEFT_ANGLE;
    return matchDiagonal(inverseDiagonal);
};

// NOTE: This uses trigonometric functions, but that's not a problem because it only happens on snap drags.
const getClosestSnappedAngle = (vector) => Math.round(vector.angleDeg() / SNAP_ANGLE) * SNAP_ANGLE;

/**
 * Based on a static point (a point that isn't being dragged) and a flexible point (the
 * point that is being dragged), determine where the flexible point should really be
 * based on the closest snapping angle.
 */
export const getEndpointSnappedToAngle = ({ staticPoint, flexiblePoint }) => {
    // Get the vector between the start and end points
    const lineVector = Vector.fromObject(pointLib.difference(staticPoint, flexiblePoint));
    // Figure out the closest angle that it should snap to
    const snappedAngle = getClosestSnappedAngle(lineVector);
    // Get the appropriate rule for updating the vector
    const snappedAngleRule = getSnapAngleRule(snappedAngle);
    // Update the vector according to the snapped angle's rule
    const updatedVector = snappedAngleRule(lineVector);
    // Translate the vector back into its original coordinates
    return pointLib.translate(staticPoint, updatedVector);
};

/**
 * If the two points are very close to creating a right angle vector, snap it to the right angle.
 */
export const snapIfCloseToRightAngle = ({ staticPoint, flexiblePoint, gridSize }) => {
    // Snap to right angle
    const vector = lineLib.getLineVectorAsPoint(staticPoint, flexiblePoint);

    // If the y is very small snap it
    if (Math.abs(vector.y) < gridSize && Math.abs(vector.y / vector.x) < 1 / (3 * gridSize)) {
        flexiblePoint.y = staticPoint.y;
        // If the x is very small, snap it
    } else if (Math.abs(vector.x) < gridSize && Math.abs(vector.x / vector.y) < 1 / (3 * gridSize)) {
        flexiblePoint.x = staticPoint.x;
    }

    return flexiblePoint;
};
