// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import RichMedia from '../RichMedia';
import VideoPlayer from './VideoPlayer';

const VideoMedia = ({
    element,
    elementId,
    currentBoardId,
    width,
    gridSize,
    isPresentational,
    attachment,
    isHovered,
    isReplaceModeHovered,
    canDrop,
    dispatchUploadFile,
    dispatchRichMediaEmbedding,
    dispatchAcceptElementAttachmentUndo,
    dispatchUpdateElement,
    dispatchMoveElementsToTrash,
    dispatchSwitchConnectedElementParents,
}) => (
    <RichMedia
        ContentComponent={VideoPlayer}
        element={element}
        elementId={elementId}
        currentBoardId={currentBoardId}
        width={width}
        gridSize={gridSize}
        isPresentational={isPresentational}
        attachment={attachment}
        isHovered={isHovered}
        isReplaceModeHovered={isReplaceModeHovered}
        canDrop={canDrop}
        dispatchUploadFile={dispatchUploadFile}
        dispatchRichMediaEmbedding={dispatchRichMediaEmbedding}
        dispatchAcceptElementAttachmentUndo={dispatchAcceptElementAttachmentUndo}
        dispatchUpdateElement={dispatchUpdateElement}
        dispatchMoveElementsToTrash={dispatchMoveElementsToTrash}
        dispatchSwitchConnectedElementParents={dispatchSwitchConnectedElementParents}
    />
);

VideoMedia.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string,
    width: PropTypes.number,
    gridSize: PropTypes.number,
    isPresentational: PropTypes.bool,
    attachment: PropTypes.object,
    isHovered: PropTypes.bool,
    isReplaceModeHovered: PropTypes.bool,
    canDrop: PropTypes.bool,
    dispatchUploadFile: PropTypes.func,
    dispatchRichMediaEmbedding: PropTypes.func,
    dispatchAcceptElementAttachmentUndo: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    dispatchSwitchConnectedElementParents: PropTypes.func,
};

export default VideoMedia;
