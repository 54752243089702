// Lib
import { pick } from 'lodash/fp';
import { keys, defaults } from 'lodash';

// Util
import emptyObject from './emptyObject';

/**
 * Creates a new object that is the intersection of the keys object and source object.
 * The keys object is the object used for the keys, the source object is the object to use for the values.
 * Any key that exists on the keys object but not the source object will be defaulted to null.
 *
 * <pre>
 *     pickWithDefault({ a: '1', b: '2', c: '3' }, { a: '4', d: '5', e: '6' });
 *     // => { a: '4', b: null, c: null }
 * </pre>
 *
 * @param keysObject {Object} The object to use for keys.
 * @param source {Object} The object to use for values.
 */
export default (keysObject, source) => {
    const pickKeys = keys(keysObject);
    const pickedValues = pick(pickKeys, source);
    const defaultValues = emptyObject(keysObject);
    return defaults(pickedValues, defaultValues);
};
