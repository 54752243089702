// Utils
import globalLogger, { LoggerComponents } from '../logger';

const logger = globalLogger.createChannel(LoggerComponents.ANALYTICS);

/**
 * Formats analytics invocations in a consistent way.
 */
export const logAnalytics = (serviceName: string, isAvailable: boolean, details: string, ...rest: any[]): void => {
    logger.log(`%c${serviceName}%s: %s`, 'color: grey', isAvailable ? '' : ' (⨯)', details, ...rest);
};

/**
 * Logs to the console and performs the callback if the service is available.
 */
export const runIfAvailable =
    (serviceName: string, isAvailableFn: () => boolean) =>
    (callback: (...args: any[]) => void) =>
    (...args: any[]): void => {
        const isAvailable = isAvailableFn();

        // NOTE: In order for the "callback.name" to work, the callback must be a named function; and it must
        //  be prevented from minification by using the "keep_fnames" option in the Webpack Terser configuration.
        logAnalytics(serviceName, isAvailable, callback.name, ...args);

        if (!isAvailable) return;

        callback(...args);
    };
