// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash/fp';
import classNames from 'classnames';

// Utils
import { getElementCardCropInset, getElementCardRenderedWidthPx } from '../utils/elementUtil';
import { getMedia } from '../../../common/elements/utils/elementPropertyUtils';
import getPaddingForAspectRatio from '../resizing/utils/getPaddingForAspectRatio';

// Components
import FileUploader from '../file/uploader/FileUploader';

// Constants
import { DEFAULT_IMAGE_UPLOADER_SIZE } from '../../../common/images/imageConstants';

// Styles
import './ImageUploader.scss';

const getPaddingForImageUploader = (props) => {
    const { element, gridSize, tempMediaSize, inList, isResizing } = props;

    const savedSize = getMedia(element) || DEFAULT_IMAGE_UPLOADER_SIZE;

    const widthPx = getElementCardRenderedWidthPx(props);
    const cropInset = getElementCardCropInset(inList);

    return getPaddingForAspectRatio({
        savedSize,
        widthPx,
        forcedSize: tempMediaSize,
        roundSaved: false,
        cropToGrid: !isResizing,
        cropInset,
        gridSize,
    });
};

class ImageUploader extends React.Component {
    render() {
        const { registerMediaElement, fileIsHovered, fileCanDrop } = this.props;

        const hovered = fileIsHovered && fileCanDrop;

        const wrapper = registerMediaElement || identity;

        const classes = classNames('ImageUploader', { hovered });

        return wrapper(
            <div className={classes} style={getPaddingForImageUploader(this.props)}>
                <FileUploader {...this.props} type="image" accept="*" />
            </div>,
        );
    }
}

ImageUploader.propTypes = {
    registerMediaElement: PropTypes.func,
    fileIsHovered: PropTypes.bool,
    fileCanDrop: PropTypes.bool,
};

export default ImageUploader;
