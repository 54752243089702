// Lib
import { EditorState } from 'draft-js';

// NOTE: This is not exposed by Draft JS, so must ensure of draft updates this still applies
import applyEntityToContentState from 'draft-js/lib/applyEntityToContentState';

// Constants
import { EditorChangeType } from '../draftjsConstants';

/**
 * This is like the "RichUtils.toggleLink" except it won't remove the link if it already exists.
 */
export default (editorState, selectionState, entityKey) => {
    const contentState = editorState.getCurrentContent();
    const updatedContentState = applyEntityToContentState(contentState, selectionState, entityKey);
    return EditorState.push(editorState, updatedContentState, EditorChangeType.APPLY_ENTITY);
};
