// Lib
import { keyBy, get, negate, isNull, clamp } from 'lodash/fp';

// Utils
import { prop, toArray } from '../../../../../../common/utils/immutableHelper';
import { isLine } from '../../../../../../common/elements/utils/elementTypeUtils';
import {
    getCanvasOriginCoordinates,
    getElementId,
    getElementLocation,
    getIsLineSnapped,
    getXPosition,
    getYPosition,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { pixelsToGridPoints } from '../../../../../utils/grid/gridUtils';
import { getLeft, getTop } from '../../../../../../common/maths/geometry/rect';
import { getAdjustedElementMeasurement } from '../../../../../canvas/dnd/canvasUtil';
import { isIconViewLike } from '../../../../../../common/elements/utils/elementDisplayUtils';

// Constants
import { BOARD_ALIGNMENT_OFFSET } from './alignmentTools/alignmentConstants';
import { ImMNElement, MNElementLocation } from '../../../../../../common/elements/elementModelTypes';
import {
    ImElementMeasurement,
    ElementMeasurement,
} from '../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsTypes';

const clampPositive = clamp(0, Infinity);

export const isArrangeable = (element: unknown): boolean => !(isLine(element) && getIsLineSnapped(element));

interface BuildMovesParams {
    elements: Immutable.List<ImMNElement>;
    rects: ElementMeasurement[];
    gridSize: number;
    currentBoard: ImMNElement;
}

export const buildMovesForRects = ({
    elements,
    rects,
    gridSize,
    currentBoard,
}: BuildMovesParams): { id: string; location: MNElementLocation }[] => {
    // Key by their IDs
    const updatedElementRects = keyBy('id', rects);

    // Map distributed rects to moves
    return elements
        .map((element) => {
            if (!element) return null;

            const elementId = getElementId(element);
            const location: MNElementLocation = getElementLocation(element).toJS();

            const updatedPosition = updatedElementRects[elementId];

            if (!updatedPosition) return null;

            const canvasOrigin = getCanvasOriginCoordinates(currentBoard);
            const canvasOriginX = prop('x', canvasOrigin) || 0;
            const canvasOriginY = prop('y', canvasOrigin) || 0;

            const x = pixelsToGridPoints(getLeft(updatedPosition), gridSize) + canvasOriginX;
            const y = pixelsToGridPoints(getTop(updatedPosition), gridSize) + canvasOriginY;

            if (x === getXPosition(element) && y === getYPosition(element)) return null;

            return {
                id: elementId,
                location: {
                    ...location,
                    position: {
                        ...get('position', location),
                        x,
                        y,
                    },
                },
            };
        })
        .filter(negate(isNull))
        .toJS();
};

interface GetElementMeasurementsParams {
    measurements: Immutable.Map<string, ImElementMeasurement>;
    elements: Immutable.List<ImMNElement>;
    gridSize: number;
}

export const getElementMeasurements = ({
    measurements,
    elements,
    gridSize,
}: GetElementMeasurementsParams): ElementMeasurement[] =>
    toArray(elements.map(getAdjustedElementMeasurement(measurements, gridSize))) || [];

const createRemoveBoardOffsetFn = (gridSize: number) => (val: number) =>
    clampPositive(val - BOARD_ALIGNMENT_OFFSET * gridSize);

interface ShiftRectsForMovesParams {
    rects: ElementMeasurement[];
    elements: Immutable.List<ImMNElement>;
    gridSize: number;
}

/**
 * Boards have extra padding on them that are not included in their rects.
 * So in order to position them correctly we need to remove this padding before performing the move.
 */
export const shiftRectsForMoves = ({ rects, elements, gridSize }: ShiftRectsForMovesParams): ElementMeasurement[] => {
    const rectsMap = keyBy('id', rects);

    return elements
        .map((element) => {
            const elementId = getElementId(element);
            const rect = rectsMap[elementId];

            if (!rect) return null;

            if (isIconViewLike(element)) {
                const removeBoardOffset = createRemoveBoardOffsetFn(gridSize);

                return {
                    ...rect,
                    x: removeBoardOffset(rect.x),
                    y: removeBoardOffset(rect.y),
                    left: removeBoardOffset(rect.left),
                    right: removeBoardOffset(rect.right),
                    top: removeBoardOffset(rect.top),
                    bottom: removeBoardOffset(rect.bottom),
                };
            }

            return rect;
        })
        .filter(negate(isNull))
        .toArray() as ElementMeasurement[];
};
