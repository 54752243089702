// Lib
import { reduce as reduceWithKeys, pickBy, forEach } from 'lodash';
import { first, keys, includes, isEmpty, stubTrue, isString } from 'lodash/fp';

// Utils
import { asObject, propIn } from '../utils/immutableHelper';
import { collapseBranchFieldProperties, reduceBranch } from '../elements/utils/elementLodash';
import {
    getAcl,
    getElementId,
    getIsPublishedPasswordProtected,
    getIsPublicEditEnabled,
    getIsPublished,
    getIsPublishedFeedbackEnabled,
} from '../elements/utils/elementPropertyUtils';
import {
    getElement,
    getParent,
    getPhysicalAncestors,
    hasRetrievedAllAncestors,
} from '../elements/utils/elementTraversalUtils';
import {
    canEditPermissions,
    canGiveFeedback,
    canRead,
    canWrite,
    hasPermission,
    isAccessDenied,
    isBlocked,
    isOwner,
} from './permissionUtil';
import { isRealUserId, isPublicId } from '../users/userHelper';

// Constants
import { PUBLIC_USER_ID } from '../users/userConstants';
import { ACCESS_BITS, PERMISSION_STATES } from './permissionConstants';

/**
 * When combining permissions:
 * - If the current element's specific permission isn't blocked, then combine it with the cumulative permission
 * - Otherwise if the user already has access via the cumulative permission, just use that
 * - Otherwise use the blocked permission
 *
 * This means that a user can be blocked at a higher level, but still be given access to lower level boards.
 *
 * NOTE: This algorithm relies on bottom up processing of elements (starting with the element and
 *   moving up its physical tree).
 */
const getCombinedPermission = (cumulativePermission, specificPermission) => {
    // If the current element permission is not blocked, just combine it with the currently combined permission
    if (!isBlocked(specificPermission)) {
        return cumulativePermission | specificPermission;
    }

    // If the currently combined permission has access, then just use the combined permission
    if (!isAccessDenied(cumulativePermission)) {
        return cumulativePermission;
    }

    // Otherwise use the blocked permission
    return specificPermission;
};

/*
--------------------------------
--- SPECIFIC ELEMENT ENTRIES ---
--------------------------------
*/

// Gets the ACL privilege for the specified user on ONLY this element.
export const getSpecificUserAcl = (element, aclId) => propIn(['acl', aclId], element) || 0;
export const hasSecretLinkAcl = (element) => canRead(getSpecificUserAcl(element, PUBLIC_USER_ID));

// Gets the combined ACL entry, given the provided aclIds, for a SPECIFIC element (not including its ancestors).
export const getCombinedAcl = (element, aclIds = []) =>
    isString(aclIds)
        ? getSpecificUserAcl(element, aclIds)
        : aclIds.reduce((combinedPermission, aclId) => combinedPermission | getSpecificUserAcl(element, aclId), 0);

/*
---------------------------------
--- RECURSIVE ELEMENT ENTRIES ---
---------------------------------
*/
/**
 * Gets the permissions for a specific user ID based on the entire ancestry of the element.
 */
export const getPermission = (elements, elementId, aclIds) =>
    // NOTE: This algorithm relies on bottom up processing of elements (starting with the element and
    //  moving up its physical tree).
    getPhysicalAncestors(elements, elementId).reduce(
        (cumulativePermission, element) => getCombinedPermission(cumulativePermission, getCombinedAcl(element, aclIds)),
        0,
    );

/**
 * Returns the combined ACL map of a physical branch, starting from "elementId".
 * This is the full permissions for a given element as the permissions are hierarchical, and if
 * a user has "FEEDBACK" access to a physical ancestor element, they also have at least "FEEDBACK"
 * access to descendant elements.
 *
 * Accepts: (elements, elementId)
 * Returns: { aclEntry: permission, .... }
 *
 *      E.g.  { "5716e1e7359974c74f0c2765": 31, "_other": 1 }
 */
export const getBranchCombinedAclMap = collapseBranchFieldProperties(
    // NOTE: ACL is an object. I think the [] is legacy because the original algorithm was written for
    //  immutable objects only, and expected methods like "forEach" or "reduce".
    (element) => getAcl(element) || [],
    (map, element, userEntry, userKey) => {
        // NOTE: This relies on the algorithm starting at the element and working up to the root
        map[userKey] = getCombinedPermission(map[userKey], userEntry);
        return map;
    },
);

/**
 * Returns a summary of the branch's ACL entries - keeping track of which element sets the permission.
 * Similar to "getBranchCombinedAclMap", this is used so the UI can determine whether the user's
 * permissions are set on the current board, or another.
 *
 * Accepts (elements, elementId)
 * Returns: { aclEntry: { permission, elementId }, .... }
 *
 *      E.g.  { "5716e1e7359974c74f0c2765": { permission: 31, elementId: '1IkUNr0EQoEb01' },
 *               "_other": { permission: 1, elementId: '1IkUVN0EQoEb0G' } }
 */
export const getCombinedPermissionsSummary = collapseBranchFieldProperties(
    (element) => getAcl(element) || [],
    (map, element, userEntry, userKey) => {
        map[userKey] = map[userKey] || {};
        // NOTE: This relies on the algorithm starting at the element and working up to the root
        map[userKey].permission = getCombinedPermission(map[userKey].permission, userEntry);

        // Keep track of the closest ancestor this is shared from
        map[userKey].elementId = map[userKey].elementId || getElementId(element);
        return map;
    },
);

/*
------------------------------
--- FIND ELEMENT FUNCTIONS ---
------------------------------
*/

/**
 * Finds the highest ancestor (closest to the root) that matches the permissions to check.
 * Note: ancestorIds must be ordered from closest to root to furtherest.
 */
// TODO Might want to change this to use the elementLodash reduce ancestors in the future?  Tests are now available
export const highestAncestorWithPermission = (requiredPermission, elementsMap, ancestorIds, aclIds) => {
    for (let i = 0; i < ancestorIds.length; i++) {
        const ancestorId = ancestorIds[i];
        const ancestor = getElement(elementsMap, ancestorId);

        const ancestorsUserPermissions = getCombinedAcl(ancestor, aclIds);

        // Bitwise & to ensure that all the permissions to check are matched on this ancestor
        if (hasPermission(requiredPermission, ancestorsUserPermissions)) {
            return ancestorId;
        }
    }

    return null;
};

/**
 * Returns all the ancestors which the user has the specified permissions to interact with.
 */
// TODO Might want to change this to use the elementLodash reduce ancestors in the future?  Tests are now available
export const ancestorsWithPermission = (requiredPermission, ancestors, ancestorIds, aclIds) => {
    const highestMatchId = highestAncestorWithPermission(requiredPermission, ancestors, ancestorIds, aclIds);
    const index = ancestorIds.indexOf(highestMatchId);

    if (index === -1) {
        return [];
    }

    return ancestorIds.slice(index);
};

/*
----------------------------
--- VALIDATION FUNCTIONS ---
----------------------------
*/

/**
 * Validates a user's permissions with confidence.
 * This will return with three states: 'VALID', 'INVALID' or 'UNKNOWN'.
 * If an elements ancestors have not been retrieved then we might not know whether an ancestor holds the permissions
 * that allow a confident 'INVALID'/'VALID' state.
 */
const validatePermissions = (elements, elementId, aclIds, requiredPermissions) => {
    if (hasPermission(requiredPermissions, getPermission(elements, elementId, aclIds))) {
        return PERMISSION_STATES.VALID;
    }
    return hasRetrievedAllAncestors(elements, elementId) ? PERMISSION_STATES.INVALID : PERMISSION_STATES.UNKNOWN;
};

export const confirmedValidPermissions = (elements, elementId, aclIds, requiredPermissions) =>
    validatePermissions(elements, elementId, aclIds, requiredPermissions) === PERMISSION_STATES.VALID;

export const confirmedInvalidPermissions = (elements, elementId, aclIds, requiredPermissions) =>
    validatePermissions(elements, elementId, aclIds, requiredPermissions) === PERMISSION_STATES.INVALID;

export const canSaveBoard = (elements, elementId, aclIds) =>
    confirmedValidPermissions(elements, elementId, aclIds, ACCESS_BITS.SAVE);

export const canGiveFeedbackOnBoard = (elements, elementId, aclIds) =>
    confirmedValidPermissions(elements, elementId, aclIds, ACCESS_BITS.FEEDBACK);

export const isLegacyPublic = (elements, elementId) => canRead(getPermission(elements, elementId, PUBLIC_USER_ID));
export const hasReadPermission = (elements, elementId, aclIds) => canRead(getPermission(elements, elementId, aclIds));
export const hasEditPermission = (elements, elementId, aclIds) =>
    canEditPermissions(getPermission(elements, elementId, aclIds));

/*
-----------------------------
--- SHARED USER FUNCTIONS ---
-----------------------------
TODO: These need to be reviewed, and likely moved elsewhere
*/
/**
 * Returns all the real user IDs on the ACL (including user IDs with blocked permissions).
 */
export const getAllAclUserIds = (acl) => Object.keys(acl).filter(isRealUserId);

/**
 * Returns all the real user Ids on an ACL map who have not been blocked.
 */
export const getSharedUserIds = (acl) => {
    const sharedUserIds = [];

    if (!acl) return sharedUserIds;

    for (const userId in acl) {
        if (!acl.hasOwnProperty(userId)) continue;

        const aclEntry = acl[userId];

        // This supports an ACL map that either maps a user ID to a permission, or a user ID to an
        //  object that contains the permission in a "permission" property
        const permission = Number.isInteger(aclEntry) ? aclEntry : aclEntry?.permission || 0;

        if (!isAccessDenied(permission) && isRealUserId(userId)) {
            sharedUserIds.push(userId);
        }
    }

    return sharedUserIds;
};

/**
 * Finds the real user IDs that have been blocked, given an ACL map.
 */
export const getBlockedUserIds = (acl) => {
    const blockedUserIds = [];

    if (!acl) return blockedUserIds;

    for (const userId in acl) {
        if (!acl.hasOwnProperty(userId)) continue;

        const aclEntry = acl[userId];

        // This supports an ACL map that either maps a user ID to a permission, or a user ID to an
        //  object that contains the permission in a "permission" property
        const permission = Number.isInteger(aclEntry) ? aclEntry : aclEntry?.permission || 0;

        if (isBlocked(permission) && isRealUserId(userId)) {
            blockedUserIds.push(userId);
        }
    }

    return blockedUserIds;
};

export const getPublicIds = (aclIds) => Object.keys(aclIds).filter(isPublicId);

const pickAclIdsBy = (pickByAclFilter) => (aclMap) => pickBy(aclMap, (permission, aclId) => pickByAclFilter(aclId));

const createIsAclIdFilter = (aclIds = []) => {
    const aclMap = {};
    isString(aclIds)
        ? (aclMap[aclIds] = true)
        : forEach(aclIds, (id) => {
              aclMap[id] = true;
          });
    return (aclId) => aclMap[aclId];
};

/*
-----------------------------------------------
--- ACL FILTER FUNCTIONS Predicate Functions ---
-----------------------------------------------
Create filter functions that accepts an aclMap to filter on
*/
/**
 * Creates an ACL Filter function by matching provided aclIds
 * @param { Object } aclFilterOptions Options to create filter with
 * @param { string[] } aclFilterOptions.aclIds ACL IDs to match by
 * @returns {Function} Filter Function with (aclMap) param
 */
export const aclFilterByMatchingAclIds = ({ aclIds }) => pickAclIdsBy(createIsAclIdFilter(aclIds));
/** Filter ACL IDs that are Public */
export const aclFilterByPublicAclIds = () => pickAclIdsBy(isPublicId);
/**
 * Creates an ACL Filter function by matching provided aclIds OR Public ACL IDs
 * @param { Object } aclFilterOptions Options to create filter with
 * @param { string[] } aclFilterOptions.aclIds ACL IDs to match by
 * @returns {Function} Filter Function with (aclMap) param
 */
export const aclFilterByMatchingOrPublicAcl = ({ aclIds }) => {
    const isInAclListFilter = createIsAclIdFilter(aclIds);

    return pickAclIdsBy((aclId) => isInAclListFilter(aclId) || isPublicId(aclId));
};

export const aclIdsHaveRequiredPermissions = ({ aclIds, requiredPermissions }) => {
    const permissionEntries = Object.values(aclIds);
    return (
        permissionEntries.some((permission) => hasPermission(requiredPermissions, permission)) &&
        permissionEntries.every((permission) => !hasPermission(ACCESS_BITS.BLOCKED, permission))
    );
};

export const getFilteredAclIdsForElement = ({ elements, elementId, aclIds = [], aclFilterPredicateFn }) => {
    const aclMap = getBranchCombinedAclMap(elements, elementId);
    const filter = aclFilterPredicateFn({ aclIds });
    return filter(aclMap);
};
/**
 * Checks if the element has the required permissions based on the filter provided
 * @param {Object} validationParams
 * @param {Object.<string,Object>} validationParams.elements Map of element ancestors
 * @param {string} validationParams.elementId ID of element
 * @param {number} validationParams.requiredPermissions
 * @param {string[]} validationParams.aclIds Array of ACL IDs to filter with
 * @param {function} validationParams.aclFilterPredicateFn Function used to create an ACL filter
 * @returns {boolean} true if element has the required permissions based on the filter provided
 */
export const elementHasRequiredPermission = ({
    elements,
    elementId,
    requiredPermissions,
    aclFilterPredicateFn,
    aclIds,
}) => {
    // Retrieve the ACL IDs matching the permission check
    const filteredAclIds = getFilteredAclIdsForElement({ elements, elementId, aclIds, aclFilterPredicateFn });
    return aclIdsHaveRequiredPermissions({ aclIds: filteredAclIds, requiredPermissions });
};

export const getSharedUserIdsForElement = ({ elements, elementId }) => {
    const acl = getBranchCombinedAclMap(elements, elementId);
    return getSharedUserIds(acl);
};

export const getBlockedUserIdsForElement = ({ elements, elementId }) => {
    const acl = getBranchCombinedAclMap(elements, elementId);
    return getBlockedUserIds(acl);
};

/**
 * Returns true if the specified element ID allows other users to add content or feedback.
 */
export const getElementCanHaveSharedContributors = ({ elements, elementId }) => {
    const combinedPermissions = getBranchCombinedAclMap(elements, elementId);
    const permissionEntries = Object.values(combinedPermissions);
    return permissionEntries.some((permission) => canGiveFeedback(permission) && !isOwner(permission));
};

export const getSharedUserIdsWithPermission = ({ elements, elementId, requiredPermissions }) => {
    const acl = getBranchCombinedAclMap(elements, elementId);
    const validPermissionsAcl = pickBy(acl, (permissionEntry) => hasPermission(requiredPermissions, permissionEntry));

    return getAllAclUserIds(validPermissionsAcl);
};

export const getElementOwner = ({ elements, elementId }) => {
    const sharedUsers = getSharedUserIdsWithPermission({ elements, elementId, requiredPermissions: ACCESS_BITS.OWNER });
    return first(sharedUsers);
};

export const getIsTopLevelSharedBoard = (elements, elementId, userId) => {
    const parentBoard = getParent(elements, elementId);
    const sharedUserIds = getSharedUserIdsForElement({
        elements,
        elementId: getElementId(parentBoard),
    });

    return sharedUserIds.length <= 1 || !includes(userId, sharedUserIds);
};

/**
 * Determines if the specific element has acl entries that at least have feedback permission.
 */
export const getSpecificElementHasContributorPermissions = (element) => {
    const acl = asObject(getAcl(element));
    if (isEmpty(acl)) return false;
    const permissionEntries = Object.values(acl);
    return permissionEntries.some(canGiveFeedback);
};

// Gets the users that this element has been specifically shared with
export const getSpecificallySharedUserIds = (element) => {
    const acl = asObject(getAcl(element));
    if (isEmpty(acl)) return [];
    return getSharedUserIds(acl);
};

export const isSpecificallySharedWithUser = (element, userId) => {
    const specificallySharedUserIds = getSpecificallySharedUserIds(element);
    return includes(userId, specificallySharedUserIds);
};

// FIXME Test this!
export const isSpecificElementSharedWithSpecificUsers = (element) => {
    const aclEntries = keys(asObject(getAcl(element)));
    return !!aclEntries && aclEntries.findIndex(isRealUserId) !== -1;
};

/**
 * Gets the highest shared board that this user has access to.
 */
export const getPathToTopLevelSharedBoard = (elements, elementId, userId) => {
    const physicalAncestors = getPhysicalAncestors(elements, elementId);

    const elementsToTopLevelSharedBoard = [];
    physicalAncestors.forEach((element) => {
        const sharedUserIds = getSharedUserIdsForElement({
            elements,
            elementId: getElementId(element),
        });

        // If more than one person can
        if (sharedUserIds.length > 1 && includes(userId, sharedUserIds)) {
            elementsToTopLevelSharedBoard.push(element);
        }
    });

    return elementsToTopLevelSharedBoard;
};

const createGetUserIdsOnAcl =
    (predicateFn = stubTrue) =>
    (element) => {
        const acl = asObject(getAcl(element));

        if (!acl) return [];

        return reduceWithKeys(
            acl,
            (userIds, permission, userId) => {
                if (predicateFn(permission, userId)) {
                    userIds.push(userId);
                }
                return userIds;
            },
            [],
        );
    };

export const isNotWritableByOtherUsers = (currentUserId) => {
    const predicateFn = (permission, userId) =>
        userId !== currentUserId && isRealUserId(userId) && canWrite(permission);

    const getOtherWritableUsers = createGetUserIdsOnAcl(predicateFn);

    return (element) => {
        const writableUsers = getOtherWritableUsers(element);
        return !writableUsers || !writableUsers.length;
    };
};

/**
 * Determines the current element's "collapsed" properties for publicEditEnabled,
 * publishedFeedbackEnabled and published, as these are all hierarchical properties.
 *
 * Accepts: (elementsMap, elementId)
 */
export const getBranchPublicPermissionProperties = reduceBranch(
    (acc, element) => {
        const elementId = getElementId(element);

        if (getIsPublicEditEnabled(element)) {
            acc.publicEditEnabled = true;
            acc.publicEditEnabledElementIds.push(elementId);
        }

        // Legacy
        if (hasSecretLinkAcl(element)) {
            acc.legacySecretLinkEnabled = true;
            acc.legacySecretLinkIds.push(elementId);
        }

        const isPublished = getIsPublished(element);

        const feedbackEnabled = getIsPublishedFeedbackEnabled(element);

        if (feedbackEnabled) {
            acc.feedbackEnabled = true;
        }

        // The feedback enabled property only applies if the board is also published
        if (isPublished && feedbackEnabled) {
            acc.publishedFeedbackEnabled = true;
            acc.publishedFeedbackEnabledElementIds.push(elementId);
        }

        if (isPublished) {
            acc.published = true;
            acc.publishedElementIds.push(elementId);
        }

        if (getIsPublishedPasswordProtected(element)) {
            acc.publishedPasswordProtected = true;
        }

        return acc;
    },
    {
        publicEditEnabled: false,
        publicEditEnabledElementIds: [],
        // This is true if the board or any of the ancestors have "publishedFeedbackEnabled"
        feedbackEnabled: false,
        // This is true only if the board or any of the ancestors have "publishedFeedbackEnabled" and "published"
        publishedFeedbackEnabled: false,
        publishedFeedbackEnabledElementIds: [],
        publishedMediaDownloadDisabled: false,
        publishedPasswordProtected: false,
        published: false,
        publishedElementIds: [],
        // Legacy
        legacySecretLinkEnabled: false,
        legacySecretLinkIds: [],
    },
);
