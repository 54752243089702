import { Extension } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { Node } from '@tiptap/pm/model';
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY, TiptapNodeType } from '../tiptapTypes';

const TAB_IN_SPACES = '    ';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        tabHandler: {
            insertTabChars: (posIndex: number, count: number) => ReturnType;
            removeTabChars: (posIndex: number, count: number) => ReturnType;
            increaseIndentation: () => ReturnType;
            decreaseIndentation: () => ReturnType;
        };
    }
}

type TabCharChange = {
    pos: number;
    count: number;
};

/**
 * Counts the number of spaces at the start of a string.
 */
const getStartSpaceCount = (text: string) => text.length - text.trimStart().length;

/**
 * Counts the number of spaces at the end of a string.
 */
const getEndSpaceCount = (text: string) => text.length - text.trimEnd().length;

/**
 * Determines the number of spaces directly before the cursor
 */
const getSpacesBeforeCursor = (state: EditorState): number => {
    // $from is the [resolved position](https://prosemirror.net/docs/ref/#model.ResolvedPos) of the start
    //  of the selection. Resolved positions have more context, rather than simply being indexes, they
    //  allow you to get the node, parent, and other information about the position.
    const { from, $from } = state.selection;

    // Get the current selected node start position
    const nodeStartPosition = $from.start();

    // Get all the text prior to the cursor
    const textBeforeCursor = state.doc.textBetween(nodeStartPosition, from, '', '');

    // Loop through the text before the cursor and count the number of spaces
    return getEndSpaceCount(textBeforeCursor);
};

/**
 * Determines the number of spaces at the start of a node.
 */
const getSpacesAtLineStart = (node: Node): number => {
    // We only want to check for spaces in the first node, otherwise we might delete the wrong thing
    const text = node.isText ? node.textContent : node.firstChild?.textContent;
    return getStartSpaceCount(text || '');
};

const getSpacesToInsert = (spaceCount: number) => TAB_IN_SPACES.length - (spaceCount % TAB_IN_SPACES.length);
const getSpacesToRemove = (spaceCount: number) => {
    if (spaceCount === 0) return 0;
    return spaceCount % TAB_IN_SPACES.length || TAB_IN_SPACES.length;
};

const getNextChildTextNode = (node: Node, startingOffset: number): Node | null => {
    for (let i = startingOffset; i < node.childCount; i++) {
        const child = node.child(i);

        if (child.isText) return child;
    }

    return null;
};

/**
 * This tab handler only deals with nodes with simple text content inside them,
 * not deeply nested nodes.
 */
const TAB_INDENTABLE_NODES = [TiptapNodeType.paragraph, TiptapNodeType.heading, TiptapNodeType.smallText];
const isTabIndentableNode = (node: Node) => TAB_INDENTABLE_NODES.includes(node.type.name as TiptapNodeType);

/**
 * Finds all the Tiptap document position indexes where tabs (as spaces)
 * should be inserted when the tab key is pressed.
 */
const getPositionsToInsertTabChars = (state: EditorState): TabCharChange[] => {
    const { from } = state.selection;

    // If the selection is empty - insert spaces at the cursor
    if (state.selection.empty) return [{ pos: from, count: TAB_IN_SPACES.length }];

    // Otherwise, we need to insert spaces at the start of each line in the selection
    const selectionStart = state.selection.from;
    const selectionEnd = state.selection.to;

    const tabChanges: TabCharChange[] = [];

    // Find the positions of the top level paragraphs in the selection
    //  - We don't want to add spaces to lists as this isn't what users would expect
    state.doc.nodesBetween(selectionStart, selectionEnd, (currentNode, position) => {
        // If the node is not a paragraph, we don't want to insert spaces into it
        if (!isTabIndentableNode(currentNode)) return false;

        const spaceCount = getSpacesToInsert(getSpacesAtLineStart(currentNode));
        tabChanges.push({ pos: position, count: spaceCount });

        // Ensure that text with soft line breaks also gets indented
        // Get the max index within the current node that we can check for new lines
        const maxIndex = Math.min(position + currentNode.nodeSize - 1, selectionEnd);
        // Subtract 1 to stay within the bounds of this node
        const currentNodeMaxPos = maxIndex - position - 1;

        currentNode.nodesBetween(0, currentNodeMaxPos, (node, nodeOffset, nodeParent, nodeIndex) => {
            if (node.type.name !== TiptapNodeType.hardBreak) return false;

            const nextNode = getNextChildTextNode(currentNode, nodeIndex);

            if (!nextNode) return false;

            const spaceCount = getSpacesToInsert(getSpacesAtLineStart(nextNode));

            // We want to indent the following Text node's text
            tabChanges.push({ pos: position + nodeOffset + 1, count: spaceCount });

            return false;
        });

        // We don't want to traverse any nodes, only get the top level positions
        return false;
    });

    // We want to insert the text *into* the node, not before it, so add 1 to each position
    return tabChanges.map((change) => ({
        ...change,
        pos: change.pos + 1,
    }));
};

/**
 * Finds the nodes and their positions that are indentable.
 */
const getTabIndentableNodesAndPositionBetween = (
    state: EditorState,
    from: number,
    to: number,
): { node: Node; pos: number }[] => {
    const tabIndentableNodes: { node: Node; pos: number }[] = [];

    state.doc.nodesBetween(from, to, (node, pos) => {
        if (isTabIndentableNode(node)) tabIndentableNodes.push({ node, pos });

        // We only want top level nodes
        return false;
    });

    return tabIndentableNodes;
};

/**
 * Finds the positions to remove spaces from and the number of spaces to remove at each.
 */
const getPositionAndCountsToRemoveTabChars = (state: EditorState): TabCharChange[] => {
    const { from, to } = state.selection;

    // If the selection is empty - remove spaces directly before the cursor
    if (state.selection.empty) {
        const spacesToRemove = Math.min(getSpacesBeforeCursor(state), TAB_IN_SPACES.length);

        if (spacesToRemove < 1) return [];

        return [{ pos: from - spacesToRemove, count: spacesToRemove }];
    }

    const tabIndentableSelectedNodes = getTabIndentableNodesAndPositionBetween(state, from, to);

    return tabIndentableSelectedNodes
        .flatMap(({ node, pos }) => {
            const indentablePositions = [{ node, pos }];

            // If there's any hard-breaks in the indentable node, check the start of those lines too
            node.nodesBetween(1, node.nodeSize - 2, (currentNode, nodePos, nodeParent, currentNodeIndex) => {
                if (currentNode.type.name !== TiptapNodeType.hardBreak) return false;

                const nextNode = getNextChildTextNode(node, currentNodeIndex);

                if (!nextNode) return false;

                indentablePositions.push({ node: nextNode, pos: pos + nodePos + 1 });

                return false;
            });

            return indentablePositions;
        })
        .map(({ node, pos }) => {
            const spacesToRemove = getSpacesToRemove(getSpacesAtLineStart(node));

            if (!spacesToRemove) return;

            // Get the position of the start of the inside of the node
            return { pos: pos + 1, count: spacesToRemove };
        })
        .filter(Boolean) as TabCharChange[];
};

export const TabHandler = Extension.create({
    name: 'tabHandler',

    // We need this extension to be trigger after the list extension, otherwise
    //  list indentation won't work correctly
    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY - 10,

    addCommands() {
        return {
            insertTabChars:
                (posIndex: number, charCount = TAB_IN_SPACES.length) =>
                ({ tr }) => {
                    // When multiple "insertTabChars" commands are run in a chain,
                    // the positions referred to by the caller will shift, because characters are
                    // being inserted.
                    // Using the mapping will compensate for these changes
                    const updatedPos = tr.mapping.map(posIndex);

                    const text = ' '.repeat(charCount);

                    tr.insertText(text, updatedPos);
                    return true;
                },
            removeTabChars:
                (posIndex: number, charCount = TAB_IN_SPACES.length) =>
                ({ tr }) => {
                    // Similar to above, compensate for position changes due to previous steps
                    const updatedPos = tr.mapping.map(posIndex);
                    tr.delete(updatedPos, updatedPos + charCount);
                    return true;
                },
            increaseIndentation:
                () =>
                ({ chain, state }) => {
                    const initialSelection = state.selection;

                    const positions = getPositionsToInsertTabChars(state);

                    // We want it to appear like the anchor and focus points of the selection remain
                    // constant after the tabs are inserted. So we need the selection to grow with the
                    // newly inserted characters - thus we need to figure out when a space is inserted
                    // before each of the anchor and focus points
                    const insertionsBeforeInitialSelectionStart = positions
                        .filter(({ pos }) => pos <= initialSelection.from)
                        .reduce((acc, { count }) => acc + count, 0);
                    const insertionsBeforeInitialSelectionEnd = positions
                        .filter(({ pos }, index) => pos - index * TAB_IN_SPACES.length <= initialSelection.to)
                        .reduce((acc, { count }) => acc + count, 0);

                    // Return selection to the original position
                    const newSelection = {
                        from: initialSelection.from + insertionsBeforeInitialSelectionStart,
                        to: initialSelection.to + insertionsBeforeInitialSelectionEnd,
                    };

                    chain()
                        .forEach(positions, ({ pos, count }, { commands }) => commands.insertTabChars(pos, count))
                        .setTextSelection(newSelection)
                        .run();

                    return true;
                },
            decreaseIndentation:
                () =>
                ({ state, chain }) => {
                    const positions = getPositionAndCountsToRemoveTabChars(state);

                    chain()
                        .forEach(positions, ({ pos, count }, { commands }) => commands.removeTabChars(pos, count))
                        .run();

                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            Tab: () => this.editor.commands.increaseIndentation(),
            'Shift-Tab': () => this.editor.commands.decreaseIndentation(),
        };
    },
});
