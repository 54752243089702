import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { delay } from 'lodash';

// Component Utils
import { getPopupIdTriggerClass, POPUP_TRIGGER_CLASS } from './PopupTrigger';

const LISTENER_CAPTURE_TRUE = {
    capture: true,
};

const getMouseEventName = ({ closeOnMouseUp }) => (closeOnMouseUp ? 'mouseup' : 'mousedown');

class PopupPanelMouseManager extends React.Component {
    componentWillMount() {
        const { visible } = this.props;

        this.listenersAttached = false;

        if (visible) {
            this.attachListener();
        }
    }

    componentWillReceiveProps(nextProps) {
        const { visible, onClose } = this.props;

        if (!visible && nextProps.visible) {
            this.attachListener();
        }

        if (visible && !nextProps.visible) {
            this.removeListener();
            onClose && onClose();
        }
    }

    componentWillUnmount() {
        const { onClose } = this.props;
        this.removeListener();
        onClose && onClose();
    }

    componentDidCatch(error, info) {
        this.error = true;
        throw error;
    }

    attachListener = () => {
        const { duration, stayOpen } = this.props;

        if (stayOpen) return;

        // Ensure that really quick double clicks are ignored
        this.attachTimerId = delay(() => {
            this.listenersAttached = true;

            document.addEventListener(getMouseEventName(this.props), this.checkTargetContained, LISTENER_CAPTURE_TRUE);
            document.addEventListener(getMouseEventName(this.props), this.closePopup, LISTENER_CAPTURE_TRUE);
        }, 250);

        if (duration) {
            this.delayedHide = delay(this.closePopup, duration);
        }
    };

    removeListener = () => {
        const { stayOpen } = this.props;

        clearTimeout(this.attachTimerId);

        if (stayOpen || !this.listenersAttached) return;

        this.listenersAttached = false;

        document.removeEventListener(getMouseEventName(this.props), this.checkTargetContained, LISTENER_CAPTURE_TRUE);
        document.removeEventListener(getMouseEventName(this.props), this.closePopup, LISTENER_CAPTURE_TRUE);

        if (this.delayedHide) {
            clearTimeout(this.delayedHide);
            this.delayedHide = null;
        }
    };

    // check if the click target is outside the popup _before_ making any state updates,
    // this is because there are a number of instances where the button will disapper after updating the state
    checkTargetContained = (event) => {
        const popup = !this.error && findDOMNode(this);

        this.targetContained = popup && popup?.contains(event.target);

        if (this.targetContained || this.props.ignoreClickContainment) return;

        const ignoredClicksSelector = this.props.ignoredClicksSelector
            ? `.FloatingPanel,.FloatingPanelErrorRenderer,${this.props.ignoredClicksSelector}`
            : '.FloatingPanel,.FloatingPanelErrorRenderer';

        // If the target is a tooltip, ignore the click
        const ignoredClickAncestor = event.target.closest(ignoredClicksSelector);

        if (ignoredClickAncestor) {
            this.targetContained = true;
            return;
        }

        // If using the popup trigger with the same ID, then let it handle the toggling of the popup
        const popupTrigger = event.target.closest(`.${POPUP_TRIGGER_CLASS}`);

        const popupIdTriggerClass = getPopupIdTriggerClass(this.props.popupId);

        this.targetContained = popupTrigger && popupTrigger.classList.contains(popupIdTriggerClass);
    };

    // after updating the state,
    closePopup = (event) => {
        // check if the target _was_ a child of the popup
        if (this.targetContained) return;

        // if not, close the popup.
        const { closePopup } = this.props;
        closePopup();

        if (this.error) {
            this.removeListener();
        }
    };

    render() {
        return this.props.children;
    }
}

PopupPanelMouseManager.propTypes = {
    closePopup: PropTypes.func.isRequired,
    closeOnMouseUp: PropTypes.bool,
    visible: PropTypes.bool,
    stayOpen: PropTypes.bool,
    ignoreClickContainment: PropTypes.bool,
    duration: PropTypes.number,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    popupId: PropTypes.string,
    // Prevents a popup close action from being dispatched if a click occurs within a HTML element
    // matching this selector
    ignoredClicksSelector: PropTypes.string,
};

export default PopupPanelMouseManager;
