// Types
import { MNElement } from '../../../common/elements/elementModelTypes';

/**
 * Messages types from the main thread to the Persistence Worker.
 */
export enum CacheOperationType {
    // Element changes
    AddElements = 'AddElements',
    RemoveElements = 'RemoveElements',
    ClearElements = 'ClearElements',
}

export type CacheOperationAddElementsData = {
    type: CacheOperationType.AddElements;
    actionType: string;
    elementIds: string[];
    elements: MNElement[];
};

export type CacheOperationRemoveElementsData = {
    type: CacheOperationType.RemoveElements;
    actionType: string;
    elementIds: string[];
};

export type CacheOperationClearElementsData = {
    type: CacheOperationType.ClearElements;
};

export type CacheOperationData =
    | CacheOperationAddElementsData
    | CacheOperationRemoveElementsData
    | CacheOperationClearElementsData;
