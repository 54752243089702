import { SwiftMilanoteMessageHandlers } from '../types/swiftMilanoteMessageHandlers';

/**
 * Converts objects to a string and back to JSON to ensure that they can be
 * safely sent to our Swift clients, for example through the `messageHandlers`.
 */
export const swiftEncodeJson = (obj: object): object => JSON.parse(JSON.stringify(obj));

export const getSwiftWebkitMessageHandlers = (): SwiftMilanoteMessageHandlers => window.webkit.messageHandlers;
export const swiftWebkitMessageHandlersExist = (): boolean => !!(window.webkit && window.webkit.messageHandlers);
