// Lib
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateElement } from '../../actions/elementActions';

// Hooks
import useCardTiptapEditorExtensions from '../../card/tiptap/useCardTiptapEditorExtensions';
import useClientElementTiptapEditor from '../../../components/tiptapEditor/useClientElementTiptapEditor';
import { useSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';
import useKeepModalEditorScrolledIntoView from './useKeepModalEditorScrolledIntoView';

// Components
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';

// Types
import { EditorContent } from '@tiptap/react';

interface DocumentModalTiptapEditorProps {
    element: ImMNElement;
    elementId: string;
    textContent: TiptapContent;
    editorId: string;
    editorKey: string;
    currentEditorKey: string;
    isEditable: boolean;
    isEditing: boolean;
    dispatchStartEditing: () => void;
}

const DocumentModalTiptapEditor = (props: DocumentModalTiptapEditorProps): ReactElement => {
    const {
        element,
        elementId,
        textContent,
        isEditable,
        editorId,
        editorKey,
        currentEditorKey,
        isEditing,
        dispatchStartEditing,
    } = props;

    const dispatch = useDispatch();

    const dispatchSaveContent = useCallback(
        (textContent: TiptapContent) => {
            // TODO - element diff update
            //  isDocumentDiffSyncingEnabled) {
            //      dispatch(updateElementContentDiff({ id, changes: { textContent } }));
            dispatch(updateElement({ id: elementId, changes: { textContent } }));
        },
        [dispatch, elementId],
    );

    useEffect(() => {
        if (isEditable) dispatchStartEditing();
    }, [isEditable]);

    const [suggestionsManager, mentionsPopup] = useSuggestionsManager();
    const extensions = useCardTiptapEditorExtensions({
        suggestionsManager,
        isEditable,
        dispatch,
        saveContent: dispatchSaveContent,
        clickableLinksWhenEditable: true,
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: textContent,
        extensions,

        editorId,
        editorKey,
        currentEditorKey,

        isEditing,
        isEditable,
        isSingleSelected: true,

        startEditing: dispatchStartEditing,
        saveContent: dispatchSaveContent,

        editorClassname: 'clickable-links-on-edit',
    });

    useKeepModalEditorScrolledIntoView(editor, isEditing);

    return (
        <div className="DocumentModalTiptapEditorContainer" onMouseDown={onMouseDown} onClick={onClick}>
            {mentionsPopup}
            <DraftJsConversionIndicator element={element} textContent={getTextContent(element)} />
            <EditorContent className="DocumentModalTiptapEditor" editor={editor} />
        </div>
    );
};

export default DocumentModalTiptapEditor;
