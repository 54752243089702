import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ColorWheelIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon color-wheel"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#9B28B4"
                d="m5.942 15.497-3.46 2a11.04 11.04 0 0 0 4.022 4.022l1.998-3.462a7.022 7.022 0 0 1-2.56-2.56"
            />
            <path fill="#491FA9" d="M5 12H1c0 2.004.544 3.877 1.481 5.496l3.461-1.999A6.968 6.968 0 0 1 5 12" />
            <path
                fill="#FE0101"
                d="m21.519 17.488-3.448-1.991a7.01 7.01 0 0 1-2.552 2.551l1.992 3.449a10.991 10.991 0 0 0 4.008-4.009"
            />
            <path
                fill="#D521C2"
                d="M8.502 18.057 6.504 21.52A10.937 10.937 0 0 0 12 23v-4a6.96 6.96 0 0 1-3.498-.943"
            />
            <path fill="#B7123B" d="M12 19v4c2.004 0 3.877-.545 5.496-1.481l-1.999-3.462A6.959 6.959 0 0 1 12 19" />
            <path fill="#0246FF" d="m5.942 8.502-3.46-1.999A10.934 10.934 0 0 0 1 12h4c0-1.276.346-2.468.942-3.498" />
            <path fill="#FEB800" d="M19 12h4c0-2.004-.544-3.877-1.481-5.496l-3.461 1.998A6.972 6.972 0 0 1 19 12" />
            <path
                fill="#FFFB06"
                d="m18.058 8.502 3.46-1.999a11.029 11.029 0 0 0-4.022-4.022l-2 3.461a7.025 7.025 0 0 1 2.562 2.56"
            />
            <path fill="#FF7E02" d="m18.058 15.497 3.46 2A10.943 10.943 0 0 0 23 12h-4a6.958 6.958 0 0 1-.942 3.497" />
            <path fill="#C7ED03" d="m15.497 5.942 1.999-3.46A10.933 10.933 0 0 0 12 1v4c1.275 0 2.467.346 3.497.942" />
            <path
                fill="#0098D0"
                d="m8.502 5.942-1.999-3.46a11.029 11.029 0 0 0-4.022 4.022l3.461 1.998a7.022 7.022 0 0 1 2.56-2.56"
            />
            <path fill="#15B42A" d="M12 5V1c-2.004 0-3.877.544-5.496 1.481l1.998 3.461A6.962 6.962 0 0 1 12 5" />
        </g>
    </svg>
);
const Memo = memo(ColorWheelIcon);
export default Memo;
