// Utils
import platformSingleton from '../../platform/platformSingleton';
import { prop } from '../../../common/utils/immutableHelper';
import { isPlatformApple } from '../../platform/utils/platformDetailsUtils';

// Constants
import { MODIFIER_KEYS } from '../dnd/modifierKeys/dragModifierKeysConstants';
import { KEY_CODES } from './keyConstants';

/**
 * True if the control key is being pressed.
 */
export const isControlKeyCommand = (eventKeys) => !!prop(MODIFIER_KEYS.ctrlKey, eventKeys);
export const hasControlModifier = isControlKeyCommand;

export const hasAltModifier = (eventKeys) => !!prop(MODIFIER_KEYS.altKey, eventKeys);

/**
 * Determines if the 'command modifier' is being used.  I.e. The 'command' key for macs, or the 'control' key for
 * others.
 *
 * @param eventKeys {Object} A key map containing boolean flags denoting if the key is pressed.
 *      E.g:  { metaKey: false, shiftKey: false, ctrlKey: true, ... }
 *
 *      This could also be a keyboard event object.
 * @return {Boolean} True if the 'command' key is being held and the platform is a mac, or the 'control' key is being
 *      held down for other platforms.
 */
export const hasCommandModifier = (eventKeys) =>
    isPlatformApple(platformSingleton) ? !!prop(MODIFIER_KEYS.metaKey, eventKeys) : isControlKeyCommand(eventKeys);

export const hasShiftKey = (eventKeys) => eventKeys && prop(MODIFIER_KEYS.shiftKey, eventKeys);

/**
 * This is used for specific command keydown, keypress or keyup events.
 */
export const hasCommandKeyCode = (event) =>
    isPlatformApple(platformSingleton)
        ? event.keyCode === KEY_CODES.CMD_LEFT || event.keyCode === KEY_CODES.CMD_RIGHT
        : event.keyCode === KEY_CODES.CTRL;

export const hasRemovalKeyCode = (event) => event.keyCode === KEY_CODES.BACKSPACE || event.keyCode === KEY_CODES.DELETE;

export const hasArrowKeyCode = (event) =>
    event.keyCode === KEY_CODES.LEFT_ARROW ||
    event.keyCode === KEY_CODES.UP_ARROW ||
    event.keyCode === KEY_CODES.RIGHT_ARROW ||
    event.keyCode === KEY_CODES.DOWN_ARROW;
