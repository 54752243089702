// Lib
import React from 'react';

// Components
import Icon from '../../../../../../../../components/icons/Icon';

// Styles
import './BoardImageUploadIcon.scss';

const BoardImageUploadIcon = () => (
    <div className="BoardImageUploadIcon">
        <Icon name="image-form-upload" />
    </div>
);

BoardImageUploadIcon.propTypes = {};

export default BoardImageUploadIcon;
