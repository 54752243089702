// Utils
import createKeyIndex from '../../../../common/utils/lib/createKeyIndex';

// Constants
import { LINK_ELEMENT_LINK_TYPES } from '../../../../common/links/linkConstants';
import { AI_ASSISTANT_TOOL_TYPE } from '../../../../common/ai/aiToolConstants';
import { ElementType } from '../../../../common/elements/elementTypes';

export const AUDIO_TOOL_KEY = `${ElementType.LINK_TYPE}-${LINK_ELEMENT_LINK_TYPES.AUDIO}`;
export const MAP_TOOL_KEY = `${ElementType.LINK_TYPE}-${LINK_ELEMENT_LINK_TYPES.MAP}`;
export const VIDEO_TOOL_KEY = `${ElementType.LINK_TYPE}-${LINK_ELEMENT_LINK_TYPES.VIDEO}`;
export const HEADER_TOOL_KEY = `${ElementType.CARD_TYPE}-HEADER`;
export const MORE_TOOL = 'MORE_TOOL';

const createToolMap = (toolsArray: string[]) => createKeyIndex([...toolsArray]);

export const DEFAULT_PRIMARY_TOOLS = createToolMap([
    ElementType.CARD_TYPE,
    ElementType.LINK_TYPE,
    ElementType.TASK_LIST_TYPE,
    ElementType.LINE_TYPE,
    ElementType.BOARD_TYPE,
    ElementType.COLUMN_TYPE,
    ElementType.TABLE_TYPE,
    ElementType.COMMENT_THREAD_TYPE,
    // secondary tools
    ElementType.IMAGE_TYPE,
    ElementType.FILE_TYPE,
    ElementType.ANNOTATION_TYPE,
    AI_ASSISTANT_TOOL_TYPE,
]);

export const DEFAULT_PRIMARY_TOOLS_TOUCH = createToolMap([
    ElementType.CARD_TYPE,
    ElementType.LINK_TYPE,
    ElementType.TASK_LIST_TYPE,
    ElementType.BOARD_TYPE,
    ElementType.COLUMN_TYPE,
    ElementType.TABLE_TYPE,
    ElementType.COMMENT_THREAD_TYPE,
    ElementType.SKETCH_TYPE,
    // secondary tools
    ElementType.IMAGE_TYPE,
    ElementType.FILE_TYPE,
    ElementType.ANNOTATION_TYPE,
]);
