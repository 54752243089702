export const getSelectionMarqueeDimensions = (startPosition, currentPosition, containerWidth = Infinity) => {
    const { x, y } = currentPosition;

    const dx = x - startPosition.x;
    const dy = y - startPosition.y;

    const DX = Math.abs(dx);
    const DY = Math.abs(dy);

    const left = dx < 0 ? Math.max(x, 0) : startPosition.x;
    const top = dy < 0 ? Math.max(y, 0) : startPosition.y;
    const width = dx < 0 ? DX - left + x : Math.min(DX, containerWidth - startPosition.x);
    const height = dy < 0 ? DY - top + y : DY;

    return {
        left,
        top,
        width,
        height,
    };
};
