import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const FileAftereffectsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={40}
        viewBox="0 0 32 40"
        className="Icon file-aftereffects"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="gray"
                d="M23.172 0a2 2 0 0 1 1.414.586l6.828 6.828A2 2 0 0 1 32 8.828V36a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h19.172zM22 1.5H4A2.5 2.5 0 0 0 1.5 4v32A2.5 2.5 0 0 0 4 38.5h24a2.5 2.5 0 0 0 2.5-2.5V10H22V1.5zm1.525.146-.025-.021V8.5h6.877l-6.852-6.854z"
            />
            <path
                fill="#AF72FF"
                fillRule="nonzero"
                d="m9.333 29 .935-2.695h3.977l.93 2.695h1.63l-3.663-10.182h-1.775L7.703 29h1.63zm1.382-3.987 1.502-4.345h.08l1.5 4.345h-3.082zm10.304 4.141c1.665 0 2.844-.82 3.182-2.063l-1.407-.254c-.269.721-.915 1.09-1.76 1.09-1.273 0-2.128-.826-2.168-2.298h5.43v-.527c0-2.759-1.651-3.838-3.382-3.838-2.127 0-3.53 1.62-3.53 3.968 0 2.371 1.383 3.922 3.635 3.922zm-2.148-4.638c.06-1.084.845-2.024 2.053-2.024 1.154 0 1.91.855 1.914 2.024h-3.967z"
            />
        </g>
    </svg>
);
const Memo = memo(FileAftereffectsIcon);
export default Memo;
