// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

// Components
import ListElementPresentational from './ListElementPresentational';

// FIXME-MEASURE This entire component could be removed if the measurements could accurately by retrieved
// by the react-measure library.  See the comment in listElementManager.calculateListRanges.
// NOTE: 16/10/20 - This might now be possible due to the improvements in measuring element positions
//  within columns.
class ListElementContainer extends React.Component {
    constructor(props) {
        super(props);

        this.getRange = this.getRange.bind(this);
    }

    /**
     * Register the list element with the parent on mount.
     */
    componentDidMount() {
        const { registerListElement } = this.props;
        registerListElement && registerListElement(this);
    }

    /**
     * Remove the list element from the parent when un-mounting.
     */
    componentWillUnmount() {
        const { deregisterListElement } = this.props;
        deregisterListElement && deregisterListElement(this);
    }

    /**
     * Retrieves the vertical point within the list that this index covers.
     *
     * @returns {Number} The vertical point within the list that determines whether the index should
     *              be more than the current index.
     */
    getRange() {
        const domNode = findDOMNode(this);
        // Get vertical middle
        return domNode.offsetTop + domNode.offsetHeight / 2;
    }

    render() {
        return <ListElementPresentational {...this.props} />;
    }
}

ListElementContainer.propTypes = {
    element: PropTypes.object,
    currentBoardId: PropTypes.string.isRequired,
    connectDropTarget: PropTypes.func,
    registerListElement: PropTypes.func,
    deregisterListElement: PropTypes.func,
};

export default ListElementContainer;
