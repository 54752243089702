// Lib
import { Modifier, EditorState } from 'draft-js';
// Utils
import { DEFAULT_INDENTATION } from '../../codeEditor/utils/getIndentation';

const insertTab = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    let newContentState;

    selection.isCollapsed()
        ? (newContentState = Modifier.insertText(contentState, selection, DEFAULT_INDENTATION))
        : (newContentState = Modifier.replaceText(contentState, selection, DEFAULT_INDENTATION));

    return EditorState.push(editorState, newContentState, 'insert-characters');
};

export default insertTab;
