// Utils
import { getViewportOffset } from '../domUtil';
import platformSingleton from '../../platform/platformSingleton';

const FAKE_INPUT_ELEMENT_ID = 'fake-element-create-input';
// Adding the class 'mousetrap' to this will mean that keyboard shortcuts WON'T be ignored while the input has focus
const FAKE_INPUT_ELEMENT_CLASS = `${FAKE_INPUT_ELEMENT_ID} mousetrap`;

/**
 * This function improves the behaviour of editing on certain devices, such as iPads,
 * by immediately focusing a "fake input", and then allowing focus to move to the new element's
 * input/content editable.
 * It seems that these devices don't allow asynchronous focus shifting to places far from where
 * the cursor currently is, for some reason. So this will shift the cursor to the place where
 * the element is dropped, and then when the new element is created it will take the cursor back
 * itself.
 *
 * NOTE: The ability to make the input read only is also provided to support double-tap card creation.
 */
export const focusFakeInput = (pagePosition, containerDomNode, readOnly = false) => {
    if (!platformSingleton.features.isTouch) return;

    if (!containerDomNode || !pagePosition) return;

    const newInputPosition = getFakeInputContainerOffset(pagePosition, containerDomNode);

    // Get the fake input
    let input = document.getElementById(FAKE_INPUT_ELEMENT_ID);

    // If it doesn't exist, create it
    if (!input) {
        input = document.createElement('input');
        input.setAttribute('id', FAKE_INPUT_ELEMENT_ID);
        input.setAttribute('class', FAKE_INPUT_ELEMENT_CLASS);
        input.setAttribute('type', 'text');
    }

    // If it's not a child of the container, move it to the container so that scrolling will work correctly
    if (input.parentElement !== containerDomNode) {
        containerDomNode.appendChild(input);
    }

    // This is used to ensure the container gets scrolled correctly
    input.style.left = `${newInputPosition.x}px`;
    input.style.top = `${newInputPosition.y}px`;

    // HACK - As stated above, we need to focus the input on the first mouseup of the double tap
    //  in order to show the virtual keyboard on the second mouseup (and subsequent element creation).
    //  However, to ensure that the virtual keyboard doesn't show on the first mouseup we set the
    //  input field to read only.
    //  By setting it to editable on the second mouseup the virtual keyboard shows sooner and makes the
    //  overall card creation feel more performant.
    input.readOnly = readOnly;

    input.focus();

    // There's a bug on the iPad App where if you double tap to open a sketch the fake input will show the focus
    // cursor until the next element is edited, so we set it back to read only after it's done its job.
    if (!readOnly) {
        requestAnimationFrame(() => {
            input = document.getElementById(FAKE_INPUT_ELEMENT_ID);

            if (!input) return;

            input.readOnly = true;
        });
    }
};

/**
 * Based on the page position coordinates, this function determines the appropriate place to position
 * the fake input field, considering the container element it will be placed inside of.
 */
const getFakeInputContainerOffset = (pagePosition, container) => {
    const containerRect = container.getBoundingClientRect();

    const viewportOffset = getViewportOffset();

    const x = pagePosition.x + container.scrollLeft - containerRect.left - viewportOffset.x;
    const y = pagePosition.y + container.scrollTop - containerRect.top - viewportOffset.y;

    return {
        x,
        y,
    };
};

/**
 * Synchronously focuses a fake input based on the position of a mouse event.
 * This is used on iPads to ensure that the new card gets focused correctly - for some reason iPads need
 * to focus synchronously after a mouse event otherwise editable focus won't be possible. So this fakes
 * a synchronous focus and then the new element can take the focus asynchronously once it's created.
 */
export const focusFakeInputFromMouseEvent = (event, readOnly = false) => {
    if (!platformSingleton.features.isTouch) return;

    if (!event) return;

    const container = event.currentTarget;

    return focusFakeInput({ x: event.pageX, y: event.pageY }, container, readOnly);
};
