export const SOCKET_CONNECTION_STATUS = {
    CONNECTED: 'CONNECTED',
    INTERRUPTED: 'INTERRUPTED',
    DISCONNECTED: 'DISCONNECTED',
};

export const SOCKET_RECONNECTION_MODE = {
    PAGE_LOAD: 'PAGE_LOAD',
    CONNECTION_LOST: 'CONNECTION_LOST',
    REHYDRATION: 'REHYDRATION',
};

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_INTERRUPTION = 'SOCKET_INTERRUPTION';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';

export const SOCKET_FORCE_CONNECT = 'SOCKET_FORCE_CONNECT';
export const SOCKET_FORCE_DISCONNECT = 'SOCKET_FORCE_DISCONNECT';

export const CHANNELS_UPDATED = 'CHANNELS_UPDATED';

export const SOCKET_ERROR = {
    CONNECTION_FAILURE: 'CONNECTION_FAILURE',
    ACTION_FAILURE: 'ACTION_FAILURE',
    ACTION_LIMIT_EXCEEDED: 'ACTION_LIMIT_EXCEEDED',
};
