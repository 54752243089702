// Lib
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Constants
import { IMAGE_MODAL_SECONDARY_TOOLBAR_ID } from '../imageModalConstants';

// Styles
import './ImageModalToolbarSecondaryTools.scss';

const ImageModalToolbarSecondaryTools = (props) => {
    const { children, className } = props;

    const mountPoint = document.getElementById(IMAGE_MODAL_SECONDARY_TOOLBAR_ID);

    if (!mountPoint) return null;

    const tools = <div className={classNames('ImageModalToolbarSecondaryTools', className)}>{children}</div>;

    return createPortal(tools, mountPoint);
};

ImageModalToolbarSecondaryTools.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

export default ImageModalToolbarSecondaryTools;
