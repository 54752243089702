/*
    Note:
        Changing the length of this array is problematic,
        because the palette that a board uses is based on a modulo
        of array length, so if the length changes, the palette
        of suggested colors will also change (on boards without images)
*/

export const DEFAULT_COLOR_PALETTES = [
    ['#6592c5', '#918abc', '#b090c4', '#c17b8a', '#f47772', '#eab174', '#eaca8b'],
    ['#55b2a6', '#82b29b', '#e1ddca', '#e9c56a', '#f4a361', '#e07a60', '#e77051'],
    ['#98b3bc', '#a3bfcd', '#bbd2d8', '#cfddda', '#d69f9d', '#df8288', '#ef6660'],
    ['#e9785e', '#dda16d', '#d5b3a3', '#d1cbc6', '#accde1', '#98c1d9', '#839fc3'],
    ['#75a4be', '#8abbd6', '#6ab8b3', '#b4d9d7', '#db9db7', '#c889a8', '#b992b4'],
    ['#96b74a', '#d0db85', '#c9d6a9', '#ddd9bd', '#f4ce6d', '#eab86a', '#e39d5e'],
    ['#8aabe7', '#acaee1', '#c5c6dc', '#f2d686', '#f7b801', '#f18701', '#f65f0e'],
    ['#cd8b69', '#cb997e', '#ddbea9', '#ead5c5', '#d4c7b0', '#b7b7a4', '#b8b882'],
    ['#b262c0', '#d47ff8', '#98dbb6', '#7ac7bf', '#93beca', '#82a9c0', '#6c9ec5'],
    ['#8bafba', '#a1c8cb', '#82cec7', '#a1dbd0', '#f5ceaa', '#e2aca8', '#f07167'],
    ['#fd4e7a', '#eb728f', '#e8a5c4', '#ddd9bd', '#decc9e', '#dbc07b', '#c7b27b'],
    ['#7db4c0', '#80c7ca', '#a8dadc', '#c9d4c7', '#e4d1b6', '#f09035', '#ed6c18'],
    ['#d9ed92', '#99d98c', '#52b69a', '#4aaba2', '#5e9cb9', '#95acbd', '#b8c7d1'],
    ['#c5a87a', '#e5c590', '#e9d3af', '#ffcdb2', '#ffb4a2', '#e6989c', '#d592a0'],
    ['#7db6c0', '#9dbec4', '#bcc8cb', '#fefbfc', '#e6ced4', '#e2b4bd', '#e87f93'],
];
