// Utils
import { prop } from '../../common/utils/immutableHelper';

// Constants
import { GRID } from './grid/gridConstants';

export const toCssUrl = (source) => `url(${source})`;
export const toCssPx = (value) => `${value}px`;

export const parseCssPx = (px) => parseFloat(px.replace('px', ''));

const getPoint = (translationPoint) => ({
    x: prop('x', translationPoint),
    y: prop('y', translationPoint),
});

export const getCssTransform = (transform) => ({
    transform,
    WebkitTransform: transform,
});

export const getRotationStyle = (rotation, measurement = 'rad') => getCssTransform(`rotate(${rotation}${measurement})`);

const getTranslation = ({ x, y, suffix = 'px', transformAppend = '' }) =>
    getCssTransform(`translate3d(${x}${suffix}, ${y}${suffix}, 0) ${transformAppend}`);

export const getTranslationStylesEms = (translationPoint, gridSize, fontSize = 16) => {
    const translatedFontSize = fontSize * (gridSize / GRID.LARGE.size);

    const { x, y } = getPoint(translationPoint);
    const xEm = x / translatedFontSize;
    const yEm = y / translatedFontSize;
    return getTranslation({ x: xEm, y: yEm, suffix: 'em' });
};

export const getTranslationStyles = (translationPoint, transformAppend) => {
    if (!translationPoint) return transformAppend;

    const { x, y } = getPoint(translationPoint);
    return getTranslation({ x, y, transformAppend });
};

export const convertToCssTransformation = (translationPoint, scale = 1) => {
    if (!translationPoint) return;

    return getTranslation({ ...translationPoint, transformAppend: `scale(${scale})` });
};

/**
 * Creates a CSS matrix3d transformation string based on specified transformation matrix.
 */
export const convertMatrixToCssTransformString = (transformationMatrix) =>
    transformationMatrix ? `matrix3d(${transformationMatrix.toString()})` : null;

/**
 * Creates a CSS transform object, appropriate to be set as a style object on a React component,
 * for a transformation matrix.
 */
export const convertMatrixToCssTransform = (transformationMatrix) =>
    getCssTransform(convertMatrixToCssTransformString(transformationMatrix));

/**
 * Create a CSS transformOrigin object, in order to specify a point where the transformation should anchored on
 */
export const getTransformOrigin = (point) => {
    if (!point) return null;
    return { transformOrigin: `${point.x}px ${point.y}px` };
};
