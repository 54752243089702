// Utils
import localStorageService from '../../../../utils/services/localStorage/localStorage';

// Constants
import { DRAWING_COLOR_KEY, DRAWING_STROKE_SIZE_KEY } from '../useDrawingEditorToolbarState';

export const clearDrawingLocalStorage = () => {
    localStorageService.removeItem(DRAWING_STROKE_SIZE_KEY);
    localStorageService.removeItem(DRAWING_COLOR_KEY);
};
