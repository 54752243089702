/* eslint-disable dot-notation */
// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import AssignmentSuggestionEntry from './AssignmentSuggestionEntry';
import MentionSuggestionEntry from '../../../../components/editor/plugins/mentions/MentionSuggestionEntry';

// Styles
import './AssignmentSuggestionResults.scss';

const AssignmentSuggestionResults = (props) => {
    const { enabledSuggestions, disabledSuggestions, searchTerm } = props;

    if (!enabledSuggestions.length && !disabledSuggestions.length) return null;

    return (
        <div className="AssignmentSuggestionResults">
            {enabledSuggestions.map((suggestion, index) => (
                <AssignmentSuggestionEntry key={suggestion.id} {...props} suggestion={suggestion} index={index} />
            ))}
            {disabledSuggestions.length ? (
                <div className="AssignmentSuggestionsDisabled" key="disabled-suggestions">
                    <div className="section-title">Not sharing this board</div>
                    {disabledSuggestions.map((suggestion, index) => {
                        const id = suggestion.id != null ? suggestion.id : suggestion.name;
                        return (
                            <MentionSuggestionEntry
                                key={id}
                                mention={suggestion}
                                index={index}
                                searchValue={searchTerm}
                                id={`suggestion-option-${id}-${index}`}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

AssignmentSuggestionResults.propTypes = {
    enabledSuggestions: PropTypes.array,
    disabledSuggestions: PropTypes.array,
    onClickSuggestion: PropTypes.func,
    focusedIndex: PropTypes.number,
    setFocusIndex: PropTypes.func,
    searchTerm: PropTypes.string,
};

export default React.memo(AssignmentSuggestionResults);
