import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GestureDragCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={50}
        height={57}
        viewBox="0 0 50 57"
        className="Icon gesture-drag-card"
        {...props}
    >
        <defs>
            <filter
                id="GestureDragCardIcon__a"
                width="155.6%"
                height="200%"
                x="-27.8%"
                y="-40%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={3} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.152360823 0"
                />
                <feOffset dx={1} dy={2} in="SourceAlpha" result="shadowOffsetOuter2" />
                <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.106262634 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                </feMerge>
            </filter>
            <path id="GestureDragCardIcon__b" d="M0 0h36v20H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero" opacity={0.94} transform="translate(6 4)">
                <use xlinkHref="#GestureDragCardIcon__b" fill="#000" filter="url(#GestureDragCardIcon__a)" />
                <use xlinkHref="#GestureDragCardIcon__b" fill="#FEFEFE" />
            </g>
            <path
                fill="#323B4A"
                d="M49.577 39.401c-.128-1.605-1.705-5.333-2.273-6.343-.8-1.424-1.63-1.87-2.632-1.804-.97.061-1.36.626-1.116 1.655.029.127.167.569.377 1.083a.138.138 0 0 1 .003.03s-.309-.631-.76-1.312c-.12-.268-.252-.525-.37-.746-.926-1.761-1.985-2.275-3.203-2.2-.899.059-1.39.472-1.439 1.218-.217 1.014-.078 2.311-.078 2.311s-.345-1.373-.64-2.206c0-.007-.003-.01-.003-.018-.558-2.2-2.256-2.59-3.648-2.5-1.098.073-1.701.757-1.652 1.558 0 .141.011.297.04.464.149.974.173 3.02.173 3.02s-.33-.807-.497-1.209c-2.025-4.876-4.042-9.752-6.088-14.618-.313-.746-.7-1.47-1.14-2.144-.657-1-1.514-.931-2.252.018-1.148 1.482-.125 5.104.28 6.434 1.265 4.148 2.554 8.292 3.971 12.386.569 1.644.682 3.293.647 4.963l-.01.112a5.24 5.24 0 0 1-.775 2.109c-.512.778-1.076.615-1.709-.07-1.023-1.108-1.147-3.137-1.218-3.944-.12-1.392.117-2.746-1.513-4.623-.835-.956-1.758-1.271-2.242-1.192-.515.084-.738 1.58-.717 3.5.025 2.166.014 4.34-.04 6.506-.074 2.949.978 5.46 2.661 7.789 1.01 1.398 2.157 2.637 3.382 3.79l.004-.015s3.555 3.206 10.613 3.198c1.545.011 3.328-.152 5.37-.576 5.865-1.217 7.691-5.655 8.263-8.375.288-1.996.44-4.01.423-6.036a16.799 16.799 0 0 0-.192-2.213z"
            />
        </g>
    </svg>
);
const Memo = memo(GestureDragCardIcon);
export default Memo;
