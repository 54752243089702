// Lib
import * as Immutable from 'immutable';

// Constants
import { CLIPPER_CLEAR, CLIPPER_SET } from './clipperConstants';
import { DRAG_END, DRAG_START } from '../../../../reducers/draggingConstants';

const initialState = Immutable.Map({
    isClipping: false,
    isClipDragging: false,
    clippedElementId: null,
    clippedText: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CLIPPER_SET:
            return state.merge({
                isClipping: true,
                clippedElementId: action.clippedElementId,
                clippedText: action.clippedText,
            });
        case CLIPPER_CLEAR:
            return initialState;
        case DRAG_START:
            return state.get('isClipping') ? state.set('isClipDragging', true) : state;
        case DRAG_END:
            return state.get('isClipping') ? state.set('isClipDragging', false) : state;
        default:
            return state;
    }
};
