// Utils
import * as pointLib from './point';
import Vector from './Vector';

export const getLine = (point1, point2) => ({
    start: point1,
    end: point2,
});

export const getLineLength = ({ start, end }) => pointLib.getDistance(start, end);

export const setLineLength = ({ start, end, length }) => {
    const vector = Vector.lineVector(start, end);
    vector.toLength(length);
    const newEnd = pointLib.translate(vector, start);
    return { start, end: newEnd };
};

export const getLineVectorAsPoint = (start, end) => pointLib.getPoint(end.x - start.x, end.y - start.y);
