// Utils
import { getTimestamp } from '../../../../../../common/utils/timeUtil';

// Constants
import {
    RESOURCES_FETCHED,
    RESOURCES_FETCHED_FROM_CACHE,
    RESOURCES_FETCHING,
    RESOURCES_MARKED_AS_STALE,
    RESOURCES_CACHED,
    RESOURCES_UPDATE_FETCHED_TIME,
    ResourceStatus,
} from '../asyncResourceConstants';

// Types
import { AsyncResourceEntity, AsyncResourceEntityErrorObject } from './asyncResourceReducerTypes';
import {
    AsyncResourceFetchedAction,
    AsyncResourceFetchErrorAction,
    AsyncResourceFetchingAction,
    AsyncResourceMarkAsStaleAction,
} from '../asyncResourceActionsTypes';
import logger from '../../../../../logger/logger';

export const RESOURCE_ENTITY_INITIAL_STATE: AsyncResourceEntity = {
    id: '',
    fetching: false,
    fetched: false,
    hasError: false,
    error: undefined,
    errorTime: undefined,
    errorCount: 0,
    expiry: undefined,
    status: ResourceStatus.NOT_FETCHED,
};

export const defaultAsyncResourceEntityError = (
    state = { ...RESOURCE_ENTITY_INITIAL_STATE },
    action: AsyncResourceFetchErrorAction,
    id: string,
    error: AsyncResourceEntityErrorObject,
): AsyncResourceEntity => ({
    ...state,
    id,
    status: ResourceStatus.ERRORED,
    fetching: false,
    fetched: true,
    hasError: true,
    error,
    errorTime: action.errorTime || getTimestamp(),
    errorCount: (state?.errorCount || 0) + 1,
    expiry: undefined,
});

const handleCachedOrFetchedUpdate = (
    id: string,
    state: AsyncResourceEntity,
    status: ResourceStatus,
    fetchedTime?: number,
    expiry?: number,
): AsyncResourceEntity => {
    const updatedState = {
        ...state,
        id,
        status,
        fetching: false,
        fetched: true,
        hasError: false,
        error: undefined,
        errorTime: undefined,
        errorCount: 0,
    };

    if (fetchedTime) {
        updatedState.fetchedTime = fetchedTime;
    }

    if (expiry) {
        updatedState.expiry = expiry;
    }

    return updatedState;
};

const handleFetched = (
    state: AsyncResourceEntity,
    action: AsyncResourceFetchedAction,
    id: string,
): AsyncResourceEntity =>
    handleCachedOrFetchedUpdate(id, state, ResourceStatus.FETCHED, action.fetchedTime || getTimestamp(), action.expiry);

/**
 * A "pre-fetch" will retrieve the resource, but save it to the IndexedDB cache
 * rather than the Redux store.
 */
const handleCached = (
    state: AsyncResourceEntity,
    action: AsyncResourceFetchedAction,
    id: string,
): AsyncResourceEntity =>
    // If the fetched time is not provided, it will use the fetched time that was already on the state.
    // This allows a "RESOURCES_CACHED" action to switch the status to "CACHED" without changing the fetched time.
    handleCachedOrFetchedUpdate(id, state, ResourceStatus.CACHED, action.fetchedTime, action.expiry);

/**
 * When fetched from cache we want to keep the fetch times etc constant.
 */
const handleFetchedFromCache = (
    state: AsyncResourceEntity,
    action: AsyncResourceFetchedAction,
    id: string,
): AsyncResourceEntity => {
    if (state?.status !== ResourceStatus.CACHED) {
        logger.warn('Attempted to mark a resource a fetched that was not already cached', id, state, action);
        return state;
    }

    return {
        ...state,
        status: ResourceStatus.FETCHED,
    };
};

const handleUpdateFetchedTime = (
    state: AsyncResourceEntity,
    action: AsyncResourceFetchedAction,
    id: string,
): AsyncResourceEntity => ({
    ...state,
    id,
    fetchedTime: action.fetchedTime || getTimestamp(),
});

const handleMarkAsStale = (
    state: AsyncResourceEntity,
    action: AsyncResourceFetchedAction,
    id: string,
): AsyncResourceEntity => ({
    ...state,
    id,
    status: ResourceStatus.STALE,
    fetching: false,
    fetched: false,
    expiry: undefined,
});

export default (
    state = { ...RESOURCE_ENTITY_INITIAL_STATE },
    action: AsyncResourceFetchingAction | AsyncResourceFetchedAction | AsyncResourceMarkAsStaleAction,
    id: string,
): AsyncResourceEntity => {
    switch (action.type) {
        case RESOURCES_FETCHING:
            return {
                ...state,
                id,
                status: ResourceStatus.FETCHING,
                fetching: true,
                expiry: undefined,
            };
        case RESOURCES_FETCHED:
            return handleFetched(state, action, id);
        case RESOURCES_CACHED:
            return handleCached(state, action, id);
        case RESOURCES_FETCHED_FROM_CACHE:
            return handleFetchedFromCache(state, action, id);
        case RESOURCES_MARKED_AS_STALE:
            return handleMarkAsStale(state, action, id);
        case RESOURCES_UPDATE_FETCHED_TIME:
            return handleUpdateFetchedTime(state, action, id);
        default:
            return state;
    }
};
