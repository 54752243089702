// Lib
import { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash/fp';

// Actions
import { endPreviewMode, startPublishPreviewMode } from './previewActions';

// Constants
import { PREVIEW_MODES } from './previewConstants';

const PublishPreviewInit = (props) => {
    const { children, close } = props;

    const dispatch = useDispatch();
    const dispatchStartPublishPreviewMode = (startingBoardId) =>
        dispatch(startPublishPreviewMode(startingBoardId, PREVIEW_MODES.PUBLISH_PREVIEW));
    const dispatchDisablePublishPreviewMode = () => dispatch(endPreviewMode());

    useEffect(() => {
        const currentBoardId = get(['params', 'currentBoardId'], props);
        dispatchStartPublishPreviewMode(currentBoardId);
        return dispatchDisablePublishPreviewMode;
    }, []);

    // For the element modal we need to pass the RouteModalManager's close function down
    return cloneElement(children, { close });
};

PublishPreviewInit.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    close: PropTypes.func,
};

export default PublishPreviewInit;
