import { DEFAULT_FILE_MAX_SIZE, FILE_TYPES_INFO, IMAGE_MIME_PATTERN } from './fileConstants';
import { validateFilename } from './filenameValidator';
import { lookupTypeDefinitionsByExtension } from './getFileType';
import getFileExtension from './getFileExtension';
import { getFileSizeForDisplay } from './fileSizeUtils';

// Types
import FileValidationError, { FileValidationErrorCodes } from '../error/FileValidationError';
import { inferFileMimeType } from './fileTypeInferenceUtils';

interface ValidateOptions {
    isSubscribed?: boolean;
}

export const inferIsFileCompatibleWithImageElement = async (file: File): Promise<Boolean> => {
    const mimeType = await inferFileMimeType(file);
    const imageTypeMatch = mimeType && mimeType.match(IMAGE_MIME_PATTERN);
    return !!imageTypeMatch;
};

export const isFileCompatibleWithImageElement = (file: File): Boolean => {
    const imageTypeMatch = file && file.type && file.type.match(IMAGE_MIME_PATTERN);
    return !!imageTypeMatch;
};

const validateFileSize = (file: File, options: ValidateOptions): FileValidationError | null => {
    const { isSubscribed = false } = options;

    const ext = getFileExtension(file.name);
    const fileTypeDefinition = lookupTypeDefinitionsByExtension(ext);
    const maxSizeDefinition = fileTypeDefinition.maxSize || DEFAULT_FILE_MAX_SIZE;

    const maxSizePropName = isSubscribed ? 'pro' : 'free';
    const maxSize = maxSizeDefinition[maxSizePropName];
    const maxSizeMb = getFileSizeForDisplay(maxSize);

    const fileSizeDisplay = getFileSizeForDisplay(file.size);

    if (file.size <= maxSize) return null;

    const message = `The maximum .${ext} size is ${maxSizeMb}, this file is ${fileSizeDisplay}`;
    console.warn(message);
    return new FileValidationError({
        code: FileValidationErrorCodes.MAX_SIZE,
        message,
        explanation: `The maximum .${ext} size is ${maxSizeMb}`,
        details: {
            type: fileTypeDefinition.type,
            max: maxSize,
            actual: file.size,
        },
    });
};

/**
 * Validates whether a file passed in is an image and is within the size limits expected.
 * @returns {Object} null if the file is valid, otherwise an object with error codes and details.
 */
export const validateImageFile = async (
    file: File,
    options: ValidateOptions = {},
): Promise<FileValidationError | null> => {
    // The file type must match the expected mime pattern
    const inferredImageType = await inferIsFileCompatibleWithImageElement(file);
    if (!inferredImageType) {
        const message = 'Sorry, this file type is not supported';
        console.warn(message);
        return new FileValidationError({
            code: FileValidationErrorCodes.INVALID_TYPE,
            message,
            explanation: 'You can upload PNGs, WEBPs, JPEGs and GIFs',
            details: {
                type: FILE_TYPES_INFO.IMAGE.type,
                expected: 'image/*',
                actual: file.type,
            },
        });
    }

    // Ensure the file is within the allowed limits
    const fileSizeError = validateFileSize(file, options);
    if (fileSizeError) return fileSizeError;

    // successful validation will not return an object
    return null;
};

export const validateFile = (file: File, options: ValidateOptions = {}): FileValidationError | null => {
    if (!file) {
        return new FileValidationError({
            code: FileValidationErrorCodes.NO_FILE,
            message: 'A file must be provided',
        });
    }

    // Validate filename
    const filenameError = validateFilename(file.name);
    if (filenameError) return filenameError;

    // Ensure the file is within the allowed limits
    const fileSizeError = validateFileSize(file, options);
    if (fileSizeError) return fileSizeError;

    return null;
};
