// Utils
import { setDnd3dEffectsEnabledLocalStorage } from './interfaceSettingsService';
import { clearDragAndDropStateSingleton } from '../../../../element/dnd/dragAndDropStateSingleton';

// Constants
import { DND_3D_EFFECTS_SET } from './interfaceSettingsConstants';

export const setDnd3dEffects = (enabled) => {
    setDnd3dEffectsEnabledLocalStorage(enabled);
    clearDragAndDropStateSingleton(true);

    return {
        type: DND_3D_EFFECTS_SET,
        enabled,
    };
};
