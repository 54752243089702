// Utils
import getBlockMapForRange from '../blocks/getBlockMapForRange';

// TODO Create test functions for these

/**
 * Determines if two ranges overlap each other.
 */
export default (blockMap, rangeA, rangeB) => {
    // Range A starts after range B
    if (rangeA.startKey === rangeB.endKey && rangeA.startOffset > rangeB.endOffset) return false;
    // Range A ends before the range B
    if (rangeA.endKey === rangeB.startKey && rangeA.endOffset < rangeB.startOffset) return false;

    const isRangeASingleLine = rangeA.startKey === rangeA.endKey;
    const isRangeBSingleLine = rangeB.startKey === rangeB.endKey;

    // If there's two single line ranges the above statements mean that if the keys are the same
    // then they must be overlapping and if they're not the same then they're not overlapping
    if (isRangeASingleLine && isRangeBSingleLine) return rangeA.startKey === rangeB.startKey;

    const rangeABlockMap = getBlockMapForRange(blockMap, rangeA);
    const rangeAKeys = rangeABlockMap.keySeq();

    const rangeBBlockMap = getBlockMapForRange(blockMap, rangeB);
    const rangeBKeys = rangeBBlockMap.keySeq();

    return rangeAKeys.some((key) => rangeBKeys.includes(key)) || rangeBKeys.some((key) => rangeAKeys.includes(key));
};
