import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorToolEraseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={18}
        viewBox="0 0 19 18"
        className="Icon drawing-editor-tool-erase"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="m15.102.885 2.776 2.776a3.019 3.019 0 0 1 0 4.271L12.37 13.44l.03.03-1.067 1.068-.031-.03-1.983 1.982h5.537a.756.756 0 0 1 .103 1.504l-.103.007L7.809 18H4.825a2.52 2.52 0 0 1-1.78-.737l-2.16-2.161a3.02 3.02 0 0 1 0-4.272L10.83.885a3.02 3.02 0 0 1 4.272 0zM5.323 8.527l-3.37 3.371a1.51 1.51 0 0 0-.098 2.028l.097.108 2.162 2.16a1 1 0 0 0 .58.287l.131.009h1.978l.381-.001 3.051-3.05-4.912-4.912zm6.683-6.672-.108.097L6.391 7.46l4.912 4.912 5.507-5.507a1.51 1.51 0 0 0 .098-2.028l-.098-.107-2.776-2.777a1.51 1.51 0 0 0-2.028-.097z"
        />
    </svg>
);
const Memo = memo(DrawingEditorToolEraseIcon);
export default Memo;
