// Lib
import * as Immutable from 'immutable';
import { get } from 'lodash/fp';

// Utils
import { getCurrentUserToken } from '../auth/authService';

// Constants
import {
    USER_UPDATE,
    USER_REFRESH,
    USER_NAVIGATE,
    USER_CHECKED_NOTIFICATIONS,
    USER_CHECKED_QUICK_NOTES,
    USER_ENABLE_EXPERIMENT,
} from '../../common/users/userConstants';
import { CURRENT_USER_SET } from './currentUserConstants';
import { LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS } from '../auth/authConstants';

const initialState = Immutable.Map({
    _id: 'guest',
    // TODO This might be erroneous, maybe it needs to be set during bootstrap
    // It causes the initial state object to forever be what it was when the app loaded
    isGuest: !getCurrentUserToken(),
    authenticated: false,
    fetched: false,
    clientId: 'NULLID',
    clientTick: 0,
});

/**
 * Some user update actions require a deep merge with the existing properties.
 */
const prepareUserChanges = (state, action) => {
    if (!action) return {};

    let { changes } = action;

    if (action.changes?.settings) {
        const initialUser = state.toJS();
        changes = {
            ...changes,
            settings: {
                ...initialUser.settings,
                ...action.changes.settings,
            },
        };
    }

    if (action.changes?.contentLimit) {
        const initialUser = state.toJS();
        changes = {
            ...changes,
            contentLimit: {
                ...initialUser.contentLimit,
                ...action.changes.contentLimit,
            },
        };
    }

    return changes;
};

const updateCurrentUserDetails = (state, action) => {
    if (action.userId !== state.get('_id')) return state;

    const userChanges = Immutable.fromJS(prepareUserChanges(state, action));
    return state.merge(userChanges);
};

const updateViewedBoardsOnNavigate = (state, action) => {
    const { newBoardId } = action;

    return state.set('lastViewedBoard', newBoardId);
};

/**
 * NOTE: This action is currently only sent from the server to the client.
 *  It isn't handled on the server when sent from the client.
 */
const handleUserEnableExperiment = (state, action) => {
    if (action.userId !== state.get('_id')) return state;

    const experiments = state.get('experiments') || Immutable.List();

    return state
        .set('experiments', experiments.push(action.experimentName))
        .setIn(['flags', action.experimentName], true);
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_UPDATE:
            return updateCurrentUserDetails(state, action);
        case USER_CHECKED_NOTIFICATIONS:
            return state.set('lastCheckedNotifications', action.timestamp);
        case USER_CHECKED_QUICK_NOTES:
            return state.set('lastCheckedQuickNotes', action.timestamp);
        case LOGOUT:
            return initialState.set('isGuest', true);
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            return state.set('isGuest', false);
        case USER_REFRESH:
        case CURRENT_USER_SET:
            return Immutable.fromJS({
                viewedBoards: {},
                // This is required so the PermissionAnalyticsOnInitialisation works correctly
                initialisationLastViewedBoardId: get(['user', 'lastViewedBoard'], action),
                ...action.user,
                isGuest: false,
                authenticated: true,
                fetched: true,
            });
        case USER_ENABLE_EXPERIMENT:
            return handleUserEnableExperiment(state, action);
        case USER_NAVIGATE:
            return updateViewedBoardsOnNavigate(state, action);
        default:
            return state;
    }
};
