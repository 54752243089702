// Lib
import React from 'react';
import classNames from 'classnames';

// Utils
import platformSingleton from '../../../platform/platformSingleton';

// Components
import TooltipSource from '../../../components/tooltips/TooltipSource';

// Constants
import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';

// Styles
import './Tool.scss';

// Types
import { MilanoteApplicationMode } from '../../../platform/platformTypes';

type ToolProps = {
    children: React.ReactNode;
    name?: string;
    iconName?: string;
    isDraggable?: boolean;
    isDragging?: boolean;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    onPointerDown?: (event: React.PointerEvent) => void;
    className?: string;
    id?: string;
    tooltip?: string;
    disabled?: boolean;
    tooltipEnabled?: boolean;
    mobileAllowPointerDown?: boolean;
    tooltipText?: string;
    shortcut?: string[];
};

const Tool = (props: ToolProps) => {
    const classes = {
        dragging: props.isDragging,
        active: props.isActive,
        draggable: props.isDraggable,
        hoverable: platformSingleton.features.supportsMouse,
        disabled: props.disabled,
    };

    const toolNameElement = props.name ? <span className="tool-name">{props.name}</span> : null;

    // on touch devices, use only the onClick event, it will not interfere with touch scrolling
    // TODO-MINI: WEB-11572 Determine if there's a cleaner way to handle this
    const pointerEvents =
        platformSingleton.appMode === MilanoteApplicationMode.mobile
            ? {
                  onClick: (event: React.MouseEvent) =>
                      props.onClick?.(event) || props.onPointerDown?.(event as React.PointerEvent),
              }
            : {
                  onClick: props.onClick,
                  onPointerDown: props.onPointerDown,
              };

    return (
        <div id={props.id} className={classNames('Tool', props.className, classes)} {...pointerEvents}>
            <div className="contents">
                <TooltipSource
                    enabled={props.tooltipEnabled}
                    tooltipText={props.tooltipText}
                    shortcut={props.shortcut}
                    position={TooltipPositions.RIGHT}
                    delay={700}
                    pollPosition
                    triggerOnMouseEnter
                >
                    <div className="icon">
                        {props.isDraggable && <div className="draggable-shadow">{props.children}</div>}
                        {props.children}
                    </div>
                </TooltipSource>
                {toolNameElement}
            </div>
        </div>
    );
};

export default Tool;
