// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getPaddingForAspectRatio from '../../element/resizing/utils/getPaddingForAspectRatio';

// Styles
import './CropToGridContainer.scss';

const CropToGridContainer = (props) => {
    const { children, dimensions, enableCrop = true, cropInset, gridSize, widthPx } = props;

    const styles = getPaddingForAspectRatio({
        savedSize: dimensions,
        roundSaved: false,
        cropToGrid: enableCrop,
        cropInset,
        gridSize,
        widthPx,
    });

    return (
        <div className="CropToGridContainer" style={styles}>
            {children}
        </div>
    );
};

CropToGridContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    dimensions: PropTypes.object,
    enableCrop: PropTypes.bool,
    cropInset: PropTypes.number,
    gridSize: PropTypes.number,
    widthPx: PropTypes.number,
};

export default CropToGridContainer;
