import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementImageMobileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-image-mobile"
        {...props}
    >
        <defs>
            <path id="ElementImageMobileIcon__a" d="M0 0h20v20H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#FFF" rx={4} />
            <g transform="translate(6 6)">
                <mask id="ElementImageMobileIcon__b" fill="#fff">
                    <use xlinkHref="#ElementImageMobileIcon__a" />
                </mask>
                <use xlinkHref="#ElementImageMobileIcon__a" fill="#C3E5FB" />
                <g mask="url(#ElementImageMobileIcon__b)">
                    <path fill="#7FB961" d="m6 7 18 20h-36z" />
                    <path fill="#9ED084" d="m16 9 18 20H-2z" />
                </g>
            </g>
        </g>
    </svg>
);
const Memo = memo(ElementImageMobileIcon);
export default Memo;
