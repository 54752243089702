import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const PaymentPersonIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon payment-person"
        {...props}
    >
        <path
            fill="#333"
            fillRule="evenodd"
            d="M11.2 9.778c2.418 0 4.556 1.78 4.785 4.43l.015.236v.356a1.2 1.2 0 0 1-1.2 1.2H1.2A1.2 1.2 0 0 1 0 14.8v-.356c0-2.676 2.239-4.666 4.8-4.666h6.4zM8 2c1.767 0 3.2 1.393 3.2 3.111 0 1.718-1.433 3.111-3.2 3.111-1.767 0-3.2-1.393-3.2-3.11C4.8 3.391 6.233 2 8 2z"
        />
    </svg>
);
const Memo = memo(PaymentPersonIcon);
export default Memo;
