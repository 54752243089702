// Utils
import { getData } from '../../attachments/attachmentsSelector';
import { prop, propIn } from '../../../../common/utils/immutableHelper';
import { getHeight, getWidth } from '../../../../common/maths/geometry/rect';
import {
    getImageOriginalProp,
    getImageProp,
    getMediaHeight,
    getMediaWidth,
} from '../../../../common/elements/utils/elementPropertyUtils';

export const getOriginalImage = (element) => getImageOriginalProp(element) || getImageProp(element);

export const getImageEditorData = propIn(['content', 'imageEditorData']);
export const getImageEditorDataRotation = propIn(['content', 'imageEditorData', 'rotate']);

// Cropped Images
export const getImageWidth = prop('width');
export const getImageHeight = prop('height');

export const getCroppedImageWidth = ({ element, gridSize, attachment }) =>
    getMediaWidth(element)
        ? getMediaWidth(element) * gridSize
        : getImageWidth(getData(attachment) || getImageProp(element));
export const getCroppedImageHeight = ({ element, gridSize, attachment }) =>
    getMediaHeight(element)
        ? getMediaHeight(element) * gridSize
        : getImageHeight(getData(attachment) || getImageProp(element));

export const getOriginalImageWidth = ({ element, gridSize, attachment }) =>
    getWidth(getOriginalImage(element)) || getCroppedImageWidth({ element, gridSize, attachment });
export const getOriginalImageHeight = ({ element, gridSize, attachment }) =>
    getHeight(getOriginalImage(element)) || getCroppedImageHeight({ element, gridSize, attachment });

const getOriginalSizeUrl = prop('original');
const getLargeSizeUrl = prop('large');
const getRegularSizeUrl = prop('regular');

export const getLargestImageSize = (image) =>
    (getOriginalSizeUrl(image) && 'original') ||
    (getLargeSizeUrl(image) && 'large') ||
    (getRegularSizeUrl(image) && 'regular');

export const getLargestImageUrl = (image) =>
    getOriginalSizeUrl(image) || getLargeSizeUrl(image) || getRegularSizeUrl(image);
