// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

export default (DecoratedComponent) => {
    class columnSaveSizeResizeDecorator extends React.Component {
        onResizeComplete = (size) => {
            const { elementId, setElementSize, dispatchUpdateElement } = this.props;

            const changes = {
                width: Math.max(Math.round(size.width), ELEMENT_DEFAULT_WIDTH),
            };

            dispatchUpdateElement && dispatchUpdateElement({ id: elementId, changes });

            setElementSize && setElementSize(size);
        };

        handleDoubleClick = () => {
            const { elementId, dispatchUpdateElement } = this.props;

            return dispatchUpdateElement({
                id: elementId,
                changes: {
                    width: null,
                },
            });
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    handleDoubleClick={this.handleDoubleClick}
                    setElementSize={this.onResizeComplete}
                />
            );
        }
    }

    columnSaveSizeResizeDecorator.propTypes = {
        elementId: PropTypes.string,
        element: PropTypes.object,
        gridSize: PropTypes.number,
        setElementSize: PropTypes.func,
        dispatchUpdateElement: PropTypes.func,
    };

    return columnSaveSizeResizeDecorator;
};
