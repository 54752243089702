// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import {
    getElementId,
    getImageProp,
    getMediaHeight,
    getMediaWidth,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getImageAspectRatio, getImageDefaultMaxWidth } from './imageElementUtils';
import { getData } from '../attachments/attachmentsSelector';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

export default (DecoratedComponent) => {
    class imageDoubleClickResizeDecorator extends React.Component {
        handleDoubleClick = (event) => {
            const { element, dispatchUpdateElement, setElementSize, attachment, gridSize } = this.props;

            event.preventDefault();
            event.stopPropagation();

            const imageData = getImageProp(element);
            const attachmentData = getData(attachment);
            const hasImage = imageData || attachmentData;

            // Ensure we have dimensions to match before changing size
            if (!hasImage) return;

            const hasMediaDimensions = getMediaWidth(element) && getMediaHeight(element);

            if (hasMediaDimensions) {
                // Remove the forced aspect ratio
                return dispatchUpdateElement({
                    id: getElementId(element),
                    changes: {
                        media: null,
                    },
                });
            }

            const savedWidth = getWidth(element) || ELEMENT_DEFAULT_WIDTH;

            let width = ELEMENT_DEFAULT_WIDTH;

            const defaultMaxWidth = getImageDefaultMaxWidth({ element, attachmentData });
            const aspectRatio = getImageAspectRatio({ element, attachmentData });

            const height = width / aspectRatio;

            // Only toggle to the default width of the media if we're currently at the default element width
            if (savedWidth === ELEMENT_DEFAULT_WIDTH) {
                width = defaultMaxWidth / gridSize;
            }

            return setElementSize({ width, height });
        };

        render() {
            return <DecoratedComponent {...this.props} handleDoubleClick={this.handleDoubleClick} />;
        }
    }

    imageDoubleClickResizeDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        dispatchUpdateElement: PropTypes.func,
        setElementSize: PropTypes.func,
        attachment: PropTypes.object,
        gridSize: PropTypes.number,
    };

    return imageDoubleClickResizeDecorator;
};
