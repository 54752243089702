// Lib
import { includes } from 'lodash/fp';
import * as Immutable from 'immutable';

// Constants
import {
    COMPOSE_EMAIL_SET_EDITOR_STATE,
    COMPOSE_MODAL_CLOSED,
    COMPOSE_MODAL_ADD_AND_REMOVE_RECIPIENTS,
} from './composeModalConstants';

const initialState = Immutable.fromJS({
    recipients: [],
    editorState: null,
});

const handleAddAndRemoveRecipients = (state, action) => {
    const { removedRecipients, addedRecipients } = action;

    return state.update('recipients', (recipients) => {
        let updatedRecipients = recipients.filter((recipient) => !includes(recipient, removedRecipients));

        if (addedRecipients) {
            const originalRecipients = recipients.toArray();
            const recipientsToAdd = addedRecipients.filter((email) => !includes(email, originalRecipients));

            updatedRecipients = updatedRecipients.concat(recipientsToAdd);
        }

        return updatedRecipients;
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPOSE_MODAL_CLOSED:
            return initialState;
        case COMPOSE_EMAIL_SET_EDITOR_STATE:
            return state.set('editorState', action.editorState);
        case COMPOSE_MODAL_ADD_AND_REMOVE_RECIPIENTS:
            return handleAddAndRemoveRecipients(state, action);
        default:
            return state;
    }
};
