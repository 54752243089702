// Lib
import React, { ReactElement, memo } from 'react';

// Utils
import { sanitiseUrl } from '../../../common/utils/urlUtil';
import { getLinkUrl } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import LinkAnchor from './LinkAnchor';

// Styles
import './LinkURL.scss';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';

type LinkURLProps = {
    element: ImMNElement;
};

const LinkURL = ({ element }: LinkURLProps): ReactElement => {
    const url = getLinkUrl(element);
    return (
        <LinkAnchor className="LinkURL" url={url}>
            <div>{sanitiseUrl(url)}</div>
        </LinkAnchor>
    );
};

// Once rendered this component never needs to update
export default memo(LinkURL, () => false);
