import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileTabMoreIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon mobile-tab-more"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M8 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm5.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm5.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
        />
    </svg>
);
const Memo = memo(MobileTabMoreIcon);
export default Memo;
