// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pure, compose } from '../../../node_module_clones/recompose';
import { defer, isEmpty, includes, difference } from 'lodash/fp';
import { CSSTransitionGroup } from '../../../node_module_clones/react-transition-group';
import classNames from 'classnames';

// Utils
import { getSuggestionsKeys } from './suggestionUtils';
import { hasChanged } from '../../utils/react/propsComparisons';
import { getUserEnableFeatureSuggestion } from '../../../common/users/utils/userPropertyUtils';
import { prop } from '../../../common/utils/immutableHelper';
import { getElementId, getModifiedTime } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { toggleElementFeature } from '../feature/elementFeatureActions';
import { navigateToElement } from '../../reducers/navigationActions';
import { elementUnsuggestFeature } from './suggestionActions';

// Selectors
import { getCurrentUser } from '../../user/currentUserSelector';
import { createDeepSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { getElementLocalData } from '../local/elementLocalDataSelector';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

// Styles
import './FeatureSuggestions.scss';

const forceHideHasChanged = hasChanged('forceHide');
const forceRenderHasChanged = hasChanged('forceRender');
const cardWidthHasChanged = hasChanged('cardWidth');
const addedSuggestionsHasChanged = hasChanged('addedSuggestions');

const EMPTY_ARRAY = [];

const makeMapStateToProps = () =>
    createDeepSelector(
        getCurrentUser,
        (state, { element }) => element,
        (state, { possibleSuggestions }) => possibleSuggestions,
        (state, { isClone }) => isClone,
        getElementLocalData,
        (currentUser, element, possibleSuggestions, isClone, elementLocalData) => {
            const elementSuggestions = getSuggestionsKeys(elementLocalData);

            const suggestionsToShow = [];
            const suggestionsToClear = [];

            possibleSuggestions.forEach((suggestion) => {
                const elementModifiedTime = getModifiedTime(element);
                const enableFeatureSuggestionTimestamp = prop(
                    suggestion.feature,
                    getUserEnableFeatureSuggestion(currentUser),
                );

                // If feature suggestion enabled, `enableFeatureSuggestionTimestamp` will be either undefined or
                // the timestamp when it was last enabled. If disabled, it will be null
                const userEnableFeatureSuggestion = enableFeatureSuggestionTimestamp !== null;

                // Will be true if user has modified the feature suggestion user preference after the last modification
                // of the element, therefore would trigger:
                // - suggestion to be hidden immediately
                // - suggestion to be cleared in the element
                const userHasModifiedEnableFeatureSuggestionPref =
                    enableFeatureSuggestionTimestamp && enableFeatureSuggestionTimestamp > elementModifiedTime;

                // Show suggestion if:
                // - it exists in elementSuggestions and marked as shouldShow
                // - user enabled the suggestion in user preference
                // - user has not modified this specific preference after the last modification of this element
                if (
                    includes(suggestion.feature, elementSuggestions) &&
                    suggestion.shouldShow(element, isClone) &&
                    userEnableFeatureSuggestion &&
                    !userHasModifiedEnableFeatureSuggestionPref
                ) {
                    suggestionsToShow.push(suggestion);
                }

                // Reset suggestion if:
                // - it exists in elementSuggestions
                // - user has modified this specific preference after the last modification of this element
                if (includes(suggestion.feature, elementSuggestions) && userHasModifiedEnableFeatureSuggestionPref) {
                    suggestionsToClear.push(suggestion);
                }
            });

            return {
                suggestions: suggestionsToShow.length ? suggestionsToShow : EMPTY_ARRAY,
                suggestionsToClear,
            };
        },
    );

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleElementFeature: (feature, elements) => dispatch(toggleElementFeature(feature, elements)),
    dispatchNavigateToElement: ({ elementId }) => dispatch(navigateToElement({ elementId })),
    dispatchClearFeatureSuggestion: ({ id, feature }) => dispatch(elementUnsuggestFeature({ id, feature })),
});

class FeatureSuggestions extends React.Component {
    constructor(props) {
        super(props);

        // This state is used to keep rendering the suggestions while they're being animated out
        this.state = {
            forceHide: false,
            forceRender: false,
            addedSuggestions: [],
            removedSuggestions: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        const { element, suggestions: nextSuggestions, suggestionsToClear, dispatchClearFeatureSuggestion } = nextProps;

        const currentSuggestions = this.props.suggestions;

        const addedSuggestions = difference(nextSuggestions, currentSuggestions);
        const removedSuggestions = difference(currentSuggestions, nextSuggestions);

        if (addedSuggestions.length || removedSuggestions.length) {
            this.setState({ addedSuggestions, removedSuggestions, forceRender: !!removedSuggestions.length });
        }

        suggestionsToClear &&
            suggestionsToClear.forEach((suggestion) => {
                dispatchClearFeatureSuggestion({ id: getElementId(element), feature: suggestion.feature });
            });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.suggestions.length !== this.props.suggestions.length ||
            forceRenderHasChanged(this.state, nextState) ||
            forceHideHasChanged(this.state, nextState) ||
            addedSuggestionsHasChanged(this.state, nextState) ||
            cardWidthHasChanged(this.props, nextProps)
        );
    }

    hideInstantly = () => {
        this.setState({ forceHide: true });

        defer(this.resetHide);
    };

    resetHide = () => {
        this.setState({ forceHide: false });
    };

    render() {
        const { forceRender, addedSuggestions, forceHide } = this.state;
        const { suggestions, cardWidth, fullWidth, dispatchToggleElementFeature } = this.props;

        if (forceHide) return null;

        if (cardWidth < ELEMENT_DEFAULT_WIDTH) return null;

        if (!forceRender && isEmpty(suggestions)) return null;

        return (
            <div className={classNames('FeatureSuggestions', { 'full-width': fullWidth })}>
                <CSSTransitionGroup
                    transitionName="slide"
                    transitionAppear
                    transitionAppearTimeout={300}
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}
                >
                    {suggestions.map((suggestion) => {
                        const Component = suggestion.component;
                        return (
                            <Component
                                key={suggestion.feature}
                                added={includes(suggestion, addedSuggestions)}
                                hideInstantly={this.hideInstantly}
                                resetHide={this.resetHide}
                                dispatchToggleElementFeature={dispatchToggleElementFeature}
                                {...this.props}
                                {...suggestion}
                            />
                        );
                    })}
                </CSSTransitionGroup>
            </div>
        );
    }
}

const enhancer = compose(pure, connect(makeMapStateToProps, mapDispatchToProps));

FeatureSuggestions.propTypes = {
    element: PropTypes.object.isRequired,
    possibleSuggestions: PropTypes.array.isRequired,
    suggestions: PropTypes.array,
    suggestionsToClear: PropTypes.array,
    dispatchUpdateElement: PropTypes.func,
    dispatchNavigateToElement: PropTypes.func,
    dispatchToggleElementFeature: PropTypes.func,
    dispatchClearFeatureSuggestion: PropTypes.func,
    cardWidth: PropTypes.number,
    fullWidth: PropTypes.bool,
};

export default enhancer(FeatureSuggestions);
