// Lib
import { flow, isString } from 'lodash/fp';

// Utils
import { prop } from '../utils/immutableHelper';
import { getPredefinedColorOrBackground, PredefinedColor } from './colorPresetsUtil';
import { isHexColorFormat, isValidColorDisplayFormat, isValidColorFormat } from './colorSpaceUtil';
import { ColorObject, getColorObjectDisplayValue, getColorObjectSpace, parseColorObject } from './colorObjectUtil';

// Utils
import { COLOR_SPACE, COLORS } from './colorConstants';

/**
 * This is used for activity digest emails and custom color pickers, where CSS variables cannot be used.
 * Otherwise, the client elementColorStyleUtils.getColorCssValue is the better function.
 */
export const getColorDefaultHexValue = (color: string | ColorObject): string =>
    prop('value', parseColorObject(color, COLOR_SPACE.HEX));

/**
 * Gets the hex code for a colour that can be saved on an element.
 * This can include a string of the predefined colours, a hex code string or a colour object
 * used on swatches.
 */
export const getElementColorHexValue = (
    elementColor: string | ColorObject | null,
    predefinedMap = COLORS,
): string | null => {
    if (!elementColor) return null;

    if (isString(elementColor)) {
        const predefinedColor = predefinedMap[elementColor as keyof typeof predefinedMap];

        if (predefinedColor) return predefinedColor.hex;

        if (isHexColorFormat(elementColor)) return elementColor;
    }

    return getColorDefaultHexValue(elementColor);
};

/**
 * Converts the color into a string suitable for display or use in CSS.
 */
export const getColorDisplayValue = (color: string | ColorObject | null): string | null => {
    if (!color) return null;

    const colorSpace = getColorObjectSpace(color);
    if (colorSpace) return getColorObjectDisplayValue(color);

    if (!isString(color)) return null;

    if (isValidColorDisplayFormat(color)) return color;
    if (isValidColorFormat(color)) {
        return flow(parseColorObject, getColorObjectDisplayValue)(color);
    }

    const colorMapEntry = getPredefinedColorOrBackground(color);
    if (colorMapEntry) return colorMapEntry.hex;

    return null;
};

/**
 * First use the color name if it's set (e.g. "RED" or "BLUE").
 * Otherwise, use the value that's passed in, which should be a hex value
 */
export const getColorToPersist = (colorValue: PredefinedColor | string | null): string | null => {
    if (!colorValue) return null;

    if (isString(colorValue)) return colorValue;

    return colorValue?.name || null;
};
