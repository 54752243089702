// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FieldValidationMessages from './validation/FieldValidationMessages';

const FormRow = ({ label, name, error, errors, HelperElement, children }) => (
    <div className="form-row">
        <div className="field-info">
            <label className="field-label" htmlFor={name}>
                {label}
            </label>

            {error && <FieldValidationMessages sanitisedError={error} />}
            {errors && errors.map((err, index) => <FieldValidationMessages sanitisedError={err} key={index} />)}
        </div>
        <div className="field-row">
            {Array.isArray(children) ? (
                children.map((child, index) => (
                    <div className="field" key={index}>
                        {child}
                    </div>
                ))
            ) : (
                <div className="field">{children}</div>
            )}
        </div>
        {HelperElement && <div className="field-helper">{HelperElement}</div>}
    </div>
);

FormRow.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.object),
    HelperElement: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FormRow;
