// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import hyperlinkOpenPopupDecorator from '../../components/editor/plugins/hyperlink/hyperlinkOpenPopupDecorator';
import MilanoteEditor from '../../components/editor/milanoteEditor/MilanoteEditor';

// Plugins
import { createLinkPlugin, createLinkifyPlugin } from '../../components/editor/plugins/link';
import createHighlightPlugin from '../../components/editor/plugins/textColor';
import createTaskShortcutsPlugin from '../../components/editor/plugins/taskShortcuts';
import createDeleteOnEmptyPlugin from '../../components/editor/plugins/deleteOnEmpty';
import createSearchHighlightPlugin from '../../components/editor/plugins/searchHighlight';
import createMarkdownPlugin from '../../components/editor/plugins/markdownSupport';
import createUndoRedoHelperPlugin from '../../components/editor/plugins/undoRedoHelper';
import createStyleShorcutsPlugin from '../../components/editor/plugins/styleShortcuts';
import createTabReplacementPlugin from '../../components/editor/plugins/tabReplacementPlugin';

// Styles
import './TaskEditor.scss';

const linkifyPlugin = createLinkifyPlugin({});
const linkPlugin = createLinkPlugin({});
const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
const undoRedoHelperPlugin = createUndoRedoHelperPlugin();
const taskShortcutsPlugin = createTaskShortcutsPlugin();
const markdownPlugin = createMarkdownPlugin({ enableBlockStyles: false });
const styleShortcutsPlugin = createStyleShorcutsPlugin({ enableBlockStyles: false });
const searchHighlightPlugin = createSearchHighlightPlugin();
const highlightPlugin = createHighlightPlugin();
const tabReplacementPlugin = createTabReplacementPlugin();

const plugins = [
    searchHighlightPlugin,
    highlightPlugin,
    linkPlugin,
    linkifyPlugin,
    deleteOnEmptyPlugin,
    taskShortcutsPlugin,
    undoRedoHelperPlugin,
    markdownPlugin,
    styleShortcutsPlugin,
    tabReplacementPlugin,
];

const TaskEditor = (props) => (
    <MilanoteEditor {...props} plugins={plugins} className={classNames('TaskEditor', props.className)} />
);

TaskEditor.propTypes = {
    className: PropTypes.string,
};

export default hyperlinkOpenPopupDecorator(TaskEditor);
