import {
    CANVAS_OBSERVER_CONFIG,
    INBOX_OBSERVER_CONFIG,
    ObserverOptions,
    QUICK_NOTES_OBSERVER_CONFIG,
    TRASH_OBSERVER_CONFIG,
} from './elementContentVisibleObserverConfig';
import { BoardSections, RootBoardTypes } from '../../../common/boards/boardConstants';

export const getObserverRootOptions = (
    boardSection: BoardSections | RootBoardTypes,
    inTrash: boolean,
): ObserverOptions => {
    if (inTrash) {
        return TRASH_OBSERVER_CONFIG;
    }

    switch (boardSection) {
        case BoardSections.INBOX:
            return INBOX_OBSERVER_CONFIG;
        case RootBoardTypes.QUICK_NOTES:
            return QUICK_NOTES_OBSERVER_CONFIG;
        default:
            return CANVAS_OBSERVER_CONFIG;
    }
};
