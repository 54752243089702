// Utils
import { isClone } from '../../../common/elements/utils/elementTypeUtils';
import {
    getClonedElementType,
    getElementId,
    getElementType,
} from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { CAPTION_EDITOR_KEY } from '../../components/caption/captionConstants';
import { COLOR_SWATCH_EDITOR_KEY } from '../../../common/colorSwatches/colorSwatchesConstants';
import { DOCUMENT_TITLE_EDITOR_KEY } from '../../../common/documents/documentConstants';
import { TABLE_TITLE_EDITOR_KEY } from '../../../common/table/tableConstants';
import { ElementType } from '../../../common/elements/elementTypes';

/**
 * Adds a suffix to the editor string (either ID or key) based on the context provided, if necessary.
 */
export const addEditorContextSuffix = ({ isFocusedForegroundElement, isModalView, editorKey }, defaultEditorKey) => {
    editorKey = editorKey || defaultEditorKey;

    if (isFocusedForegroundElement) return `${editorKey}-FOCUS`;
    if (isModalView) return `${editorKey}-MODAL`;
    return editorKey;
};

/**
 * Gets the editor ID that represents editing an element with a specific editor type
 */
export const getSpecificEditorId = (element, editorKey) => {
    return `${getElementId(element)}-${editorKey}`;
};

/**
 * Gets the main editor ID based on the element ID and type.
 */
const getMainEditorIdForElement = (element) => getSpecificEditorId(element, getElementType(element));

/**
 * Gets the main editor ID based on the element ID, type and context;
 */
export const getMainEditorId = (args) => {
    const { element } = args;
    return addEditorContextSuffix(args, getMainEditorIdForElement(element));
};

/**
 * Determines the main editor key, based on the element type.
 */
const getMainEditorKeyForElementType = (element) => {
    let elementType = getElementType(element);

    if (isClone(element)) {
        elementType = getClonedElementType(element);
    }

    switch (elementType) {
        case ElementType.LINK_TYPE:
            return 'LINK_TITLE';
        case ElementType.COLOR_SWATCH_TYPE:
            return COLOR_SWATCH_EDITOR_KEY;
        case ElementType.DOCUMENT_TYPE:
            return DOCUMENT_TITLE_EDITOR_KEY;
        case ElementType.TABLE_TYPE:
            return TABLE_TITLE_EDITOR_KEY;
        default:
            return getElementType(element);
    }
};

/**
 * Determines the main editor key, based on the element type and context of the element.
 */
export const getMainEditorKey = (args) => {
    const { element } = args;
    return addEditorContextSuffix(args, getMainEditorKeyForElementType(element));
};

/**
 * Gets an editor ID for an element's caption.
 */
export const getCaptionEditorId = (args) => {
    const { element } = args;
    const captionEditorId = getSpecificEditorId(element, CAPTION_EDITOR_KEY);
    return addEditorContextSuffix(args, captionEditorId);
};

/**
 * Gets an editor key for an element's caption.
 */
export const getCaptionEditorKey = (args) => addEditorContextSuffix(args, CAPTION_EDITOR_KEY);
