// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Selectors
import { boardAccessDeniedSelector } from '../../utils/permissions/elementPermissionsSelector';
import { getPhysicalBoardHasPasswordProtectedErrorSelector } from './boardPasswordProtectedSelector';

// Components
import ContainerElementChildCount from '../containerElement/ContainerElementChildCount';

// Styles
import './BoardEmptyContentTag.scss';

const mapStateToProps = (initialState, initialProps) =>
    createStructuredSelector({
        isPasswordProtected: getPhysicalBoardHasPasswordProtectedErrorSelector,
        accessDenied: boardAccessDeniedSelector(initialState, initialProps),
    });

const BoardEmptyContentsTag = (props) => {
    const { childrenTypes, isPasswordProtected, accessDenied, canShowCount, isBoardDuplicating } = props;

    if (isPasswordProtected && accessDenied) {
        return <div className="BoardEmptyContentsTag ElementContentsTag">Protected</div>;
    }

    if (accessDenied) {
        return <div className="BoardEmptyContentsTag ElementContentsTag">Access denied</div>;
    }

    return (
        <ContainerElementChildCount
            childrenTypes={childrenTypes}
            show={canShowCount}
            isBoardDuplicating={isBoardDuplicating}
        />
    );
};

BoardEmptyContentsTag.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string,

    childrenTypes: PropTypes.object,
    isPasswordProtected: PropTypes.bool,
    accessDenied: PropTypes.bool,
    canShowCount: PropTypes.bool,
    isBoardDuplicating: PropTypes.bool,
};

export default connect(mapStateToProps)(BoardEmptyContentsTag);
