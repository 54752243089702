import { isEmpty } from 'lodash';

import fieldValidationService from './fieldValidationService';

export const validateField = (field) => (value, _, meta) => {
    const fieldError = fieldValidationService(value, field);

    if (isEmpty(meta)) return fieldError;

    // If meta exists (field-level validation), only show error based on the error configuration:
    // - error.hideBeforeSubmit - Used to only show error after a submission failed
    // - error.hideIfTouched - Used to only show error when field is pristine, and hide error afterwards
    const shouldShowError =
        fieldError &&
        (!fieldError.hideBeforeSubmit || (fieldError.hideBeforeSubmit && meta.submitFailed)) &&
        (!fieldError.hideIfTouched || (fieldError.hideIfTouched && !meta.touched));

    return shouldShowError ? fieldError : undefined;
};

// Used to validate multiple fields during a form submission
export const validateFields = (fields) => (values) =>
    fields.reduce((acc, field) => {
        const value = values[field.name];
        const error = validateField(field)(value);

        if (!error) return acc;

        return { ...acc, [field.name]: error };
    }, {});
