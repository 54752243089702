// Lib
import { get } from 'lodash/fp';
import { prop } from '../../common/utils/immutableHelper';

// Constants
import { BOARD_ERROR, ERROR_MESSAGES } from '../../common/error/errorConstants';

export const extractErrorDetailsForAction = (error) =>
    error.response
        ? { id: error.id, url: error.url, ...error.response }
        : { id: error.id, url: error.url, message: error.message, code: error.code };

export const getErrorMessage = get(['message']);

export const getErrorMessageFromResponse = get(['response', 'data', 'error', 'message']);
export const getErrorDisplayMessageFromResponse = get(['response', 'data', 'error', 'inner', 'displayMessage']);
export const getErrorName = get(['response', 'data', 'error', 'name']);
export const getResponseStatusCode = get(['response', 'status']);

/**
 * Determines if an error relates to the network issues.
 */
export const isNetworkError = (errorDetails) =>
    prop('code', errorDetails) === BOARD_ERROR.TIMEOUT ||
    prop('code', errorDetails) === BOARD_ERROR.CONNECTION_LOST ||
    prop('message', errorDetails) === ERROR_MESSAGES.NETWORK_ERROR;
