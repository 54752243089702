import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorStroke3Icon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        className="Icon drawing-editor-stroke-3"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="nonzero"
            d="m11.842.507-.372.287-2.557 1.991-1.107.875-1.5 1.205-.797.652-.719.599-.449.382-.646.562-.653.586-.403.375-.352.337-.313.311-.286.3-.272.303c-.09.103-.18.208-.272.319a7.253 7.253 0 0 0-.554.768c-.284.467-.425.797-.528 1.26a3.4 3.4 0 0 0-.062.523l.004.201c.007.17.025.307.06.45l.04.16c.014.056.029.101.046.15.04.107.082.203.129.3.035.077.077.15.145.254l.102.147.114.145.153.161.124.112c.082.068.14.11.246.178.11.07.208.122.325.172l.186.074.087.028.181.049.14.029.118.018.23.023c.181.012.337.01.57-.007.326-.03.594-.077.974-.166l.186-.046.386-.107c.067-.02.137-.04.209-.063l.265-.083.549-.185.584-.21.44-.167a4.27 4.27 0 0 0-.156.227l-.068.11-.08.147-.092.212-.074.252-.024.137-.014.144-.002.11c.002.122.017.242.043.357a1.685 1.685 0 0 0 .305.639l.078.09.082.085.08.074c.073.064.14.113.215.16l.16.089.12.055.186.065.109.03.174.033c.275.035.446.035.717.004l.18-.023.262-.049.268-.06.314-.082.341-.097 1.24-.382.797-.237.343-.092.197-.046.279-.055.189-.03a5.077 5.077 0 0 1 1.043-.06c.318.025.583.087.776.181.199.092.349.22.47.395.057.085.107.184.147.294l.027.083.033.13.013.082.007.08c.083.688.677 1.2 1.374 1.189l.131-.008a1.354 1.354 0 0 0 1.204-1.229l.003-.118H18l-.017-.178-.01-.195h-.01l-.009-.067-.02-.127a4.025 4.025 0 0 0-.117-.484 3.976 3.976 0 0 0-.408-.917 3.896 3.896 0 0 0-1.512-1.428c-.523-.286-1.12-.464-1.82-.549l-.325-.028a8.425 8.425 0 0 0-1.373.034l-.136.018.16-.157.244-.25.335-.359.187-.214c.158-.187.272-.337.397-.528.068-.105.132-.218.191-.34l.054-.122.062-.17.04-.139.016-.076.024-.137a1.79 1.79 0 0 0 .015-.181l.002-.093-.008-.17a2.114 2.114 0 0 0-.108-.47 1.978 1.978 0 0 0-.151-.31l-.078-.12-.096-.123-.078-.084a1.886 1.886 0 0 0-.348-.285 2.118 2.118 0 0 0-.51-.226l-.153-.036-.2-.031-.09-.008-.142-.005-.084.001-.114.005-.156.014-.099.012-.203.035-.122.025-.32.075-.496.14-.385.12-.54.18-.584.201-1.125.397.422-.318.546-.406.905-.665.946-.686 1.728-1.233 2.438-1.714.185-.149a2.34 2.34 0 0 0 .37-3.097l-.102-.136a2.443 2.443 0 0 0-3.376-.384z"
        />
    </svg>
);
const Memo = memo(DrawingEditorStroke3Icon);
export default Memo;
