import * as Immutable from 'immutable';

import getClientConfig from '../utils/getClientConfig';

import {
    AUTOUPDATE_DOWNLOAD_START,
    AUTOUPDATE_DOWNLOAD_COMPLETE,
    AUTOUPDATE_DOWNLOAD_PROGRESS,
    AUTOUPDATE_CHECK_START,
    AUTOUPDATE_CHECK_COMPLETE,
    AUTOUPDATE_ERROR,
    AUTOUPDATE_STATE,
    TOGGLE_SPELL_CHECK,
    ELECTRON_APP_CLOSE_MESSAGE_CLEAR,
    ELECTRON_APP_CLOSE_MESSAGE_SET,
} from '../../common/electron/electronConstants';

const initialState = Immutable.Map({
    spellCheck: true,
    autoUpdate: AUTOUPDATE_STATE.NONE,
    appVersion: getClientConfig().appVersion,
    preUpdateStatus: AUTOUPDATE_STATE.NONE,
    autoUpdateProgress: null,

    // Replicates the "onBeforeUnload" in Electron
    closeMessage: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SPELL_CHECK:
            return state.setIn(['spellCheck'], action.status);
        case AUTOUPDATE_DOWNLOAD_START:
            return state.set('autoUpdate', AUTOUPDATE_STATE.DOWNLOADING);
        case AUTOUPDATE_DOWNLOAD_COMPLETE:
            return state.set('preUpdateStatus', AUTOUPDATE_STATE.READY).set('autoUpdate', AUTOUPDATE_STATE.READY);
        case AUTOUPDATE_DOWNLOAD_PROGRESS:
            return state.set('autoUpdateProgress', Immutable.Map(action.progress));
        case AUTOUPDATE_CHECK_START:
            return state.set('autoUpdate', AUTOUPDATE_STATE.CHECKING);
        case AUTOUPDATE_CHECK_COMPLETE:
            return state.set('autoUpdate', state.get('preUpdateStatus'));
        case AUTOUPDATE_ERROR:
            return state.set('autoUpdate', AUTOUPDATE_STATE.ERROR);
        // Replicates the "onBeforeUnload" in Electron to prevent the user from closing the app while data is syncing
        case ELECTRON_APP_CLOSE_MESSAGE_SET:
            return state.set('closeMessage', {
                ...action,
                messageType: undefined,
                type: action.messageType || 'warning',
            });
        case ELECTRON_APP_CLOSE_MESSAGE_CLEAR:
            return state.delete('closeMessage');
        default:
            return state;
    }
};
