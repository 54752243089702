// Lib
import * as Immutable from 'immutable';
import { isEmpty } from 'lodash/fp';

// Utils
import asBoolMap from '../../../common/utils/lib/asBoolMap';

// Constants
import {
    ELEMENT_INDIVIDUAL_DUPLICATION,
    ELEMENT_DUPLICATION_PROCESSING,
    ELEMENT_DUPLICATION_COMPLETE,
} from './elementDuplicateConstants';
import { CLONE_PROPERTIES } from '../clone/cloneActionMiddleware';
import {
    ELEMENT_CONVERT_TO_CLONE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
} from '../../../common/elements/elementConstants';
import { BoardSections } from '../../../common/boards/boardConstants';

const initialState = Immutable.fromJS({ loading: {} });

const handleUpdateDuplicateLoadingState = (state, action) =>
    state.withMutations((mutableState) => {
        const { elementIds, loading } = action;

        for (const elementId of elementIds) {
            if (!elementId) continue;

            mutableState.setIn(['loading', elementId], Immutable.fromJS({ id: elementId, loading }));
        }

        return mutableState;
    });

const handleElementUpdate = (state, action) => {
    const { updates } = action;

    if (!updates) return state;

    let updatedState = state;

    updates.forEach((update) => {
        const { id, changes } = update;

        if (!state.get(id)) return;

        const keys = Object.keys(changes);
        const isCloneChange = keys.every((key) => CLONE_PROPERTIES[key]);

        if (isCloneChange) return;

        updatedState = updatedState.delete(id);
    });

    return updatedState;
};

/**
 * If an element is deleted, ensure any tracked duplications are removed.
 */
const handleElementMove = (state, action) => {
    const { moves = [] } = action;

    const elementIdsMovedToTrash = moves
        .filter((move) => move?.location?.section === BoardSections.TRASH)
        .map((move) => move.id);

    if (isEmpty(elementIdsMovedToTrash)) return state;

    const elementIdsMovedToTrashMap = asBoolMap(elementIdsMovedToTrash);

    return state.filter((targetId) => !elementIdsMovedToTrashMap[targetId]);
};

/**
 * If we convert an element (B) to a clone of (A) and then convert another element (C)
 * to a clone that was a duplicate of B, then it will result in an infinite
 * loop of a clone of a clone.
 * So we need to switch the original ID that C points to when B becomes a clone, so that
 * it will make a clone of A rather than a clone of B.
 *
 * Also, remove the tracking that B is a duplicate of A.
 */
const handleConvertToClone = (state, action) =>
    state
        // Remove the duplication tracking of the clone, to prevent weird bugs on undo
        .filter((targetId, keyId) => keyId !== action.id)
        // Swap any clones pointing to the new clone to point to the original element of the clone
        .map((targetId) => (targetId === action.id ? action.originalElementId : targetId));

/**
 * Tracks the duplicate element ID and its original element ID, for use when suggesting the clone feature.
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(state, action);
        case ELEMENT_CONVERT_TO_CLONE:
            return handleConvertToClone(state, action);
        case ELEMENT_INDIVIDUAL_DUPLICATION:
            return state.set(action.duplicateElementId, action.originalElementId);
        case ELEMENT_UPDATE:
            return handleElementUpdate(state, action);
        case ELEMENT_DUPLICATION_PROCESSING:
        case ELEMENT_DUPLICATION_COMPLETE:
            return handleUpdateDuplicateLoadingState(state, action);
        default:
            return state;
    }
};
