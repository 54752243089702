// Lib
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Components
import Icon from '../../icons/Icon';

// Styles
import './MissingImage.scss';

const MissingImage = ({ src, style = {}, width, height, paddingBottom, showBrokenIconOnError }) => {
    const isSmallImage = width < 60 || height < 60;

    return (
        <div
            className="MissingImage"
            // Add data attribute to improve debuggability
            data-missing-image-src={src}
            style={{
                ...style,
                width,
                height,
                paddingBottom,
            }}
        >
            {showBrokenIconOnError && (
                <div className={classNames('missing-image-icon-container', { small: isSmallImage })}>
                    <Icon name="image-missing" />
                </div>
            )}
        </div>
    );
};

MissingImage.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    paddingBottom: PropTypes.string,
    showBrokenIconOnError: PropTypes.bool,
};

export default MissingImage;
