// Utils
import { handleResourceEntityCreation } from '../asyncResourceReducerUtils';
import defaultAsyncResourceTypeReducer from '../defaultAsyncResourceTypeReducer';

// Constants
import { ELEMENT_CREATE } from '../../../../../../../common/elements/elementConstants';
import {
    ResourceTypes,
    RESOURCES_FETCH_ERROR,
    RESOURCES_FETCHED,
    RESOURCES_FETCHING,
    RESOURCES_INVALIDATED,
    RESOURCES_UPDATE_FETCHED_TIME,
    RESOURCES_CACHED,
    RESOURCES_FETCHED_FROM_CACHE,
} from '../../asyncResourceConstants';

// Types
import { AnyAction } from 'redux';
import { AsyncResourceType } from '../asyncResourceReducerTypes';

const initialState: AsyncResourceType = {};

/**
 * If boards are being fetched, the board element is also being fetched, so we should handle those
 * actions too.
 */
const handleBoardResourceActions = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => {
    if (action.resource !== ResourceTypes.boards) return state;

    return defaultAsyncResourceTypeReducer(state, action);
};

export default (state = initialState, action: AnyAction): AsyncResourceType => {
    switch (action.type) {
        case RESOURCES_FETCHING:
        case RESOURCES_FETCHED:
        case RESOURCES_CACHED:
        case RESOURCES_FETCHED_FROM_CACHE:
        case RESOURCES_FETCH_ERROR:
        case RESOURCES_UPDATE_FETCHED_TIME:
        case RESOURCES_INVALIDATED:
            return handleBoardResourceActions(state, action);
        case ELEMENT_CREATE:
            return handleResourceEntityCreation(state, action.id);
        default:
            return state;
    }
};
