// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Singletons
import zoomStateSingleton from '../../canvas/zoom/zoomStateSingleton';
import currentlyVisibleElementsSingleton from './currentlyVisibleElementsSingleton';

// Constants
import { CANVAS_OBSERVER_CONFIG } from './elementContentVisibleObserverConfig';

const ElementContentVisibleObserver = (props) => {
    const {
        domRef,
        elementId,
        enable,
        onVisibilityChange,
        observerRootOptions = CANVAS_OBSERVER_CONFIG,
        setContentVisibility,
    } = props;

    const lastIsIntersectingRef = React.useRef(null);

    React.useEffect(() => {
        if (!enable) return;

        // If the browser doesn't support IntersectionObserver, don't attempt to set contentVisibility.
        if (!window.IntersectionObserver) return;

        if (!domRef || !domRef.current) return;

        /**
         * Use a separate function & the "lastIsIntersectingRef" so that it can be used in
         * a constant reference callback - when using registerZoomEndSubscriber.
         */
        const updateContentVisibility = () => {
            if (!domRef.current) return;

            const isVisible = !!lastIsIntersectingRef.current;

            if (setContentVisibility) {
                domRef.current.style.contentVisibility = isVisible ? 'visible' : 'auto';
            }

            currentlyVisibleElementsSingleton.updateVisibility(elementId, isVisible);
            onVisibilityChange?.(isVisible);
        };

        const intersectionCallback = ([intersection]) => {
            const { isIntersecting, boundingClientRect } = intersection;

            lastIsIntersectingRef.current = isIntersecting;

            // If for whatever reason this element doesn't have a bounding client rect, don't do anything
            if (!boundingClientRect.width && !boundingClientRect.height) return;

            // Delay the update of the content visibility until after the zoom has completed
            // to avoid painting while zooming (potentially contributing to jank)
            if (zoomStateSingleton.getIsZooming()) {
                zoomStateSingleton.registerZoomEndSubscriber(updateContentVisibility);
                return;
            }

            updateContentVisibility();
        };

        const { rootSelector, rootMargin } = observerRootOptions;

        const observer = new IntersectionObserver(intersectionCallback, {
            root: document.querySelector(rootSelector),
            rootMargin,
        });

        observer.observe(domRef.current);

        return () => {
            observer?.disconnect();
        };
    }, [setContentVisibility]);

    return null;
};

ElementContentVisibleObserver.propTypes = {
    enable: PropTypes.bool,
    domRef: PropTypes.object,
    elementId: PropTypes.string,
    onVisibilityChange: PropTypes.func,
    observerRootOptions: PropTypes.object,
    setContentVisibility: PropTypes.bool,
};

export default ElementContentVisibleObserver;
