import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MediaPlayerVolumeQuietIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={16}
        viewBox="0 0 19 16"
        className="Icon media-player-volume-quiet"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#929292" d="m0 8 9-7v14z" />
            <path fill="#929292" d="M2 5h4v6H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2z" />
            <path stroke="#929292" strokeWidth={2} d="M11.243 12.243a6 6 0 0 0 0-8.486" />
        </g>
    </svg>
);
const Memo = memo(MediaPlayerVolumeQuietIcon);
export default Memo;
