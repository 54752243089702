// Lib
import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Icon from '../icons/Icon';
import AvatarImage from '../images/AvatarImage';

// Util
import { getInitials } from '../../user/userUtil';
import { getIsAnonymousProp, getIsGuestProp, getUserImage } from '../../../common/users/utils/userPropertyUtils';
import { getEmail, getGivenName, getId } from '../../../common/users/userHelper';
import { objectIdCreationSeconds } from '../../../common/utils/objectIdUtil';

// Types
import { MNFileContent } from '../../../common/elements/elementModelTypes';
import { ImMNUser, MNUser } from '../../../common/users/userModelTypes';
import { UserAvatarInfo } from './userAvatarTypes';

const AVATAR_TYPES = {
    GUEST: 'guest',
    IMAGE: 'image',
    INITIALS: 'initials',
};

type UserAvatarProps = {
    user: MNUser | ImMNUser | UserAvatarInfo;
    active?: boolean;
    activeIndicator?: boolean;
    size: number;
    sizePx: number;
    attachment?: MNFileContent;
    onImageLoadCb?: () => void;
};

const getAvatarType = ({
    user,
    attachment,
}: {
    user: MNUser | ImMNUser | UserAvatarInfo;
    attachment: MNFileContent | undefined;
}) => {
    if (!user || getIsGuestProp(user) || getIsAnonymousProp(user)) return AVATAR_TYPES.GUEST;

    if (getUserImage(user) || (attachment && attachment.size)) return AVATAR_TYPES.IMAGE;

    if (getEmail(user) && !getGivenName(user)) return AVATAR_TYPES.GUEST;

    return AVATAR_TYPES.INITIALS;
};

const getAvatarColorClass = (user: MNUser | ImMNUser | UserAvatarInfo) => {
    const userAvatarColorIndex = objectIdCreationSeconds(getId(user)) % 8;
    return `avatar-color-${userAvatarColorIndex}`;
};

const GuestAvatar = () => (
    <span className="GuestAvatar">
        <Icon name="user-guest" />
    </span>
);

const UserAvatarType = ({ user, sizePx, attachment, onImageLoadCb }: Omit<UserAvatarProps, 'active'>): ReactElement => {
    const type = getAvatarType({ user, attachment });

    switch (type) {
        case AVATAR_TYPES.IMAGE:
            return (
                <div className="avatar-contents">
                    <AvatarImage attachment={attachment} user={user} sizePx={sizePx} onImageLoadCb={onImageLoadCb} />
                </div>
            );
        case AVATAR_TYPES.INITIALS:
            return (
                <div className={classNames('avatar-contents', getAvatarColorClass(user))}>
                    <span className="initals">{getInitials(user)}</span>
                </div>
            );
        case AVATAR_TYPES.GUEST:
        default:
            return (
                <div className="avatar-contents">
                    <GuestAvatar />
                </div>
            );
    }
};

UserAvatarType.propTypes = {
    type: PropTypes.string,
    user: PropTypes.object,
    active: PropTypes.bool,
    sizePx: PropTypes.number.isRequired,
    attachment: PropTypes.object,
    onImageLoadCb: PropTypes.func,
};

export default UserAvatarType;
