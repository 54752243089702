// Lib
import { SelectionState } from 'draft-js';

// Utils
import forceFocus from '../../customRichUtils/forceFocus';
import { hasAltModifier, hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';
import getEntityAtPoint from '../../customRichUtils/entity/getEntityAtPoint';
import getEntityAndRangeAtPoint from '../../customRichUtils/entity/getEntityAndRangeAtPoint';
import getRangeStartPoint from '../../customRichUtils/entity/getRangeStartPoint';
import getRangeEndPoint from '../../customRichUtils/entity/getRangeEndPoint';
import { getSelectionNextPoint, getSelectionPreviousPoint } from '../../customRichUtils/selection/getFollowingPoint';

// Constants
import { DRAFT_ENTITY_MUTABILITY } from '../../draftjsConstants';

/**
 * Jumps IMMUTABLE entities when using the left or right arrows to change the editor's caret position.
 */
const onHorizontalArrow =
    ({ nextPointFn, nextFocusPointFn }) =>
    (event, { getEditorState, setEditorState }) => {
        const editorState = getEditorState();
        const selection = editorState.getSelection();

        const nextPoint = nextPointFn(editorState, hasAltModifier(event));

        // We're at the start of the line, so let Draft handle the line changing
        if (!nextPoint) return;

        const { entity } = getEntityAtPoint(editorState, nextPoint);

        // Only want to handle immutable entities
        if (!entity || entity.mutability !== DRAFT_ENTITY_MUTABILITY.IMMUTABLE) return;

        const { entityRange } = getEntityAndRangeAtPoint(editorState, nextPoint);

        if (!entityRange) return;

        // If we're moving right and we've just hit the start of the entity, don't jump it yet
        if (entityRange.startOffset === nextPoint.offset && entityRange.startKey === nextPoint.key) return;

        // If right arrow this will choose the end of the range, on left arrow it will choose the start
        const nextFocusPoint = nextFocusPointFn(entityRange);

        // The browser handles the left key after the onLeftArrow function runs
        // so we need to add one to the desired offset, because the browser will subtract one when it handles it
        let newSelection = SelectionState.createEmpty(entityRange.startKey);
        newSelection = newSelection.merge({
            anchorKey: hasShiftKey(event) ? selection.getAnchorKey() : nextFocusPoint.key,
            anchorOffset: hasShiftKey(event) ? selection.getAnchorOffset() : nextFocusPoint.offset,
            focusKey: nextFocusPoint.key,
            focusOffset: nextFocusPoint.offset,
            isBackward: hasShiftKey(event) ? selection.getIsBackward() : false,
            hasFocus: true,
        });

        const updatedEditorState = forceFocus(editorState, newSelection);

        setEditorState(updatedEditorState);

        // Prevent the default browser handler from doing anything
        event.stopPropagation();
        event.preventDefault();
    };

export const onLeftArrow = onHorizontalArrow({
    nextPointFn: getSelectionPreviousPoint,
    nextFocusPointFn: getRangeStartPoint,
});
export const onRightArrow = onHorizontalArrow({
    nextPointFn: getSelectionNextPoint,
    nextFocusPointFn: getRangeEndPoint,
});
