// Lib
import { combineReducers } from 'redux-immutable';
import * as Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

// Reducers
import contentState from './richMediaContentStateReducer';

// Constants
import {
    RICH_MEDIA_EMBEDDING,
    RICH_MEDIA_ERROR,
    RICH_MEDIA_READY,
    RICH_MEDIA_RETURN_TO_PREVIEW,
} from './richMediaConstants';
import { DRAG_START } from '../../reducers/draggingConstants';

const individualRichMediaReducer = combineReducers({
    contentState,
});

const updateId = (state, action, id) =>
    state.update(id, (currIdState) => individualRichMediaReducer(currIdState, action));
const delegateToId = (state, action) => updateId(state, action, action.id);

const delegateToAllIds = (state, action) =>
    state.withMutations((mutableState) => action.ids && action.ids.forEach((id) => updateId(mutableState, action, id)));

const delegateToAllEntries = (state, action) =>
    state.withMutations((mutableState) => mutableState.forEach((val, id) => updateId(mutableState, action, id)));

// The rich media state is a map of ID to { contentState }
const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case RICH_MEDIA_EMBEDDING:
        case RICH_MEDIA_ERROR:
        case RICH_MEDIA_READY:
        case RICH_MEDIA_RETURN_TO_PREVIEW:
            return delegateToId(state, action);
        case DRAG_START:
            return delegateToAllIds(state, action);
        case LOCATION_CHANGE:
            return delegateToAllEntries(state, action);
        default:
            return state;
    }
};
