import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './EmptyListPlaceholder.scss';

const placeholderDefault = (
    <div className="default-placeholder">
        <div className="background" />
    </div>
);

const EmptyListPlaceholder = (props) => (
    <div className={classNames('EmptyListPlaceholder', { hovered: props.isHovered })}>
        {props.placeholderComponent ? props.placeholderComponent : placeholderDefault}
    </div>
);

EmptyListPlaceholder.propTypes = {
    isHovered: PropTypes.bool,
    placeholderComponent: PropTypes.element,
};

export default EmptyListPlaceholder;
