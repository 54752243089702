// Handlers
import handleReturn from './handleReturn';
import handlePastedText from './handlePastedText';
import keyBindingFn from './keyBindingFn';
import handleKeyCommand from './handleKeyCommand';
import handleBeforeInput from './handleBeforeInput';

export default () => ({
    handleReturn,
    handlePastedText,
    handleBeforeInput,
    keyBindingFn,
    handleKeyCommand,
});
