const SUFFIXES = ['GB', 'MB', 'KB', 'B'];
const SUFFIX_DECIMALS = {
    GB: 1,
    MB: 1,
};

const getDivisorForSuffix = (suffix) => {
    let suffixIndex = SUFFIXES.indexOf(suffix);
    if (suffixIndex === -1) {
        suffixIndex = 0;
    }

    return Math.pow(1000, SUFFIXES.length - 1 - suffixIndex);
};

export const getSizeIn = (suffix, size, minimise = false) => {
    const result = size / getDivisorForSuffix(suffix);

    let numDecimals = SUFFIX_DECIMALS[suffix] || 0;

    // If we are working with a confined space, don't show decimals for large numbers
    if ((minimise && result >= 10) || result >= 100) {
        numDecimals = 0;
    }

    return result.toFixed(numDecimals);
};

export const getFileSizeSuffix = (size = 0) =>
    SUFFIXES.reduce((sizeString, suffix, index) => {
        if (sizeString) return sizeString;

        const result = getSizeIn(suffix, size);
        if (result >= 1) return suffix;

        return null;
    }, null) || 'B';

export const getFileSizeForDisplay = (size = 0) => {
    const suffix = getFileSizeSuffix(size);
    const result = getSizeIn(suffix, size);
    return `${result} ${suffix}`;
};

export const getFileSizeComparisonString = (partial, total, minimise = false) => {
    const suffix = getFileSizeSuffix(total);
    const totalFormatted = getSizeIn(suffix, total, minimise);
    const partialFormatted = getSizeIn(suffix, partial, minimise);

    return `${partialFormatted} / ${totalFormatted} ${suffix}`;
};
