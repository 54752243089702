import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarCodeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-code"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M16.772 9.818a.75.75 0 0 1 1.456.364l-3 12a.75.75 0 0 1-1.456-.364zM10.47 12.47a.75.75 0 0 1 1.06 1.06L9.06 16l2.47 2.47a.75.75 0 0 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06zm11.06 0 3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 0 1-1.06-1.06L22.94 16l-2.47-2.47a.75.75 0 0 1 1.06-1.06z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarCodeIcon);
export default Memo;
