// Utils
import { insertMultilineIndentation, removeMultilineIndentation } from './utils/multilineIndentationUtils';
import insertTab from './utils/insertTab';

// Constants
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

// FIXME Handle shifting of multiple lines
const insertTabHandler = (editorState, { setEditorState }) => {
    const selection = editorState.getSelection();
    const updatedEditorState = selection.isCollapsed()
        ? insertTab(editorState)
        : insertMultilineIndentation(editorState);

    updatedEditorState && setEditorState(updatedEditorState);
};

const removeTabFromStartOfLine = (editorState, { setEditorState }) => {
    const newSate = removeMultilineIndentation(editorState);
    newSate && setEditorState(newSate);
};

const hasSelectionInList = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);
    const currentBlockType = currentBlock.getType();

    return (
        currentBlockType === STYLE_COMMANDS.LIST ||
        currentBlockType === STYLE_COMMANDS.ORDERED_LIST ||
        currentBlockType === STYLE_COMMANDS.CHECKLIST
    );
};

export default (event, { getEditorState, setEditorState }) => {
    const editorState = getEditorState();

    if (hasSelectionInList(editorState)) return;

    event.preventDefault();

    const increaseIndentation = !event.shiftKey;

    return increaseIndentation
        ? insertTabHandler(editorState, { setEditorState })
        : removeTabFromStartOfLine(editorState, { setEditorState });
};
