// Lib
import React from 'react';
import { useCaptionContainer, UseCaptionContainerCallbacks, UseCaptionContainerProps } from './useCaptionContainer';

type DecoratedComponentProps = UseCaptionContainerProps & UseCaptionContainerCallbacks;

export default (DecoratedComponent: React.ComponentType<DecoratedComponentProps>) =>
    function captionContainerDecorator(props: UseCaptionContainerProps) {
        const callbacks = useCaptionContainer(props);
        return <DecoratedComponent {...props} {...callbacks} />;
    };
