// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shallowEqual from 'shallowequal';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';

// Utils
import { sortByScoreProperty } from '../../../common/elements/utils/elementSortUtils';

// Selectors
import { getElementChildIdsSorted } from '../selectors/elementChildrenSelector';

const makeColumnSelector = () =>
    createSelector(getElementChildIdsSorted(sortByScoreProperty), (childElementIds) => ({
        childElementIds,
    }));

export default (DecoratedComponent) => {
    @connect(makeColumnSelector)
    class ListDataDecorator extends React.Component {
        shouldComponentUpdate(nextProps) {
            // Check for shallow equality on everything except for ancestorIds.
            return !shallowEqual(this.props, nextProps, (thisValue, nextValue, key) => {
                if (key !== 'childElementIds') return;
                return thisValue === nextValue || isEqual(thisValue, nextValue);
            });
        }

        render() {
            return <DecoratedComponent {...this.props} />;
        }
    }

    ListDataDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        childElementIds: PropTypes.array,
    };

    return ListDataDecorator;
};
