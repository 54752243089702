// Lib
import { compose } from '../../../node_module_clones/recompose';
import { NativeTypes } from 'react-dnd-html5-backend';
import { defer, omit, pick } from 'lodash/fp';

// Components
import ElementDropTarget from '../dnd/elementDropTargets/ElementDropTarget';
import { imageHoverWatcher } from './imageHoverDropDecorator';

// Utils
import { handleFileUploadDrop } from '../../components/tools/files/fileDropTargetDecorator';
import { isImage } from '../../../common/elements/utils/elementTypeUtils';
import {
    getCaption,
    getContent,
    getElementId,
    getElementLocation,
    getImageProp,
    getShowCaption,
    isElementLocked,
} from '../../../common/elements/utils/elementPropertyUtils';
import { asObject, objectSize } from '../../../common/utils/immutableHelper';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getData } from '../attachments/attachmentsSelector';
import doesEditorJsonHaveText from '../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';

// Constants
import { DROP_TARGET_TYPES } from '../../../common/elements/elementConstants';
import { DRAG_PREVIEW_ID } from '../../reducers/draggingConstants';

const getReplacementCaption = ({ droppedElement, element }) => {
    const originalCaption = asObject(getCaption(element));

    const droppedCaption = asObject(getCaption(droppedElement));
    const droppedCaptionHasText = doesEditorJsonHaveText(droppedCaption);
    const droppedShowCaption = getShowCaption(droppedElement) || false;

    // The dropped element doesn't have a caption, so just use the existing
    if (!droppedShowCaption || !droppedCaptionHasText) return originalCaption;

    // Otherwise use the new caption
    return droppedCaption;
};

const canStartReplaceMode = (monitor, props) => {
    if (monitor.getItemType() === NativeTypes.FILE) return true;

    const item = monitor.getItem();

    // Can only drop exactly one element onto image uploaders
    if (!item.element || item.draggedElements.length !== 1) return false;

    const { element: draggingElement, attachment } = item;
    const { element: hoveredElement } = props;

    if (!isImage(draggingElement)) return false;

    const imageData = getImageProp(draggingElement);

    // If the element being hovered is not an image, then we can't use the attachment
    if (!isImage(hoveredElement)) return objectSize(imageData) > 0;

    // If the image has image or attachment data, we can drop
    const attachmentData = getData(attachment);
    return objectSize(imageData) > 0 || objectSize(attachmentData) > 0;
};

const defaultImageDropTargetConfig = {
    acceptDropTypes: [NativeTypes.FILE],
    hoverType: DROP_TARGET_TYPES.IMAGE,
    drop: (props, monitor) => {
        const {
            elementId,
            element,
            currentBoardId,
            dispatchUpdateElement,
            dispatchAtomicMoveAndUpdate,
            dispatchMoveElementsToTrash,
            dispatchSwitchConnectedElementParents,
            dispatchAcceptElementAttachmentUndo,
        } = props;

        if (monitor.getItemType() === NativeTypes.FILE) {
            const transactionId = getNewTransactionId();

            // Enable undo of the newly uploaded image
            dispatchAcceptElementAttachmentUndo({ id: elementId, transactionId });

            return handleFileUploadDrop({ ...props, transactionId }, monitor);
        }

        const droppedElement = monitor.getItem().element;

        const droppedElementId = getElementId(droppedElement);

        // If the dropped image is dragged directly from the image popup, we want to update the original
        // placeholder image, as the dropped image doesn't exist
        if (droppedElementId === DRAG_PREVIEW_ID) {
            const droppedContent = asObject(getContent(droppedElement));
            const updatedContent = omit(['media', 'width'], droppedContent);

            // Hide the caption to prevent formatting from getting messed up
            updatedContent.showCaption = getShowCaption(element) || false;

            // Update this element with the image from the moved element
            dispatchUpdateElement({
                id: elementId,
                // Update the placeholder with the image of the dropped element
                changes: updatedContent,
                sync: true,
            });

            return {};
        }

        // Note that here we're actually deleting the drop target element (rather
        // than the dragged element) and placing the dragged image in its position.

        // In this case we want to update the dropped image just in case it's in the process of uploading
        const placeholderLocation = asObject(getElementLocation(element));
        const placeholderContent = asObject(getContent(element));
        const placeholderDimensionContent = pick(['media', 'width'], placeholderContent);

        const isTargetLocked = isElementLocked(element);
        placeholderDimensionContent.showCaption = getShowCaption(element) || false;
        placeholderDimensionContent.caption = getReplacementCaption({ droppedElement, element });
        placeholderDimensionContent.locked = isTargetLocked;

        const transactionId = getNewTransactionId();

        dispatchAtomicMoveAndUpdate({
            id: droppedElementId,
            location: placeholderLocation,
            changes: placeholderDimensionContent,
            transactionId,
        });

        // Move any connected lines or comments from the old element over to the new element
        dispatchSwitchConnectedElementParents({
            initialConnectedId: elementId,
            newConnectedId: droppedElementId,
            transactionId,
        });

        if (isTargetLocked) {
            // unlock it so we can send it to the trash
            dispatchUpdateElement({
                id: elementId,
                changes: {
                    locked: false,
                },
                transactionId,
            });
        }

        // Delete the placeholder after a delay to ensure the drop handling has been finished
        defer(() => dispatchMoveElementsToTrash({ elementId, currentBoardId, transactionId }));

        // Prevent lists or the canvas from handling the drop
        return {};
    },
    canDrop: (props, monitor) => {
        const { isReplaceModeHovered } = props;

        if (!isReplaceModeHovered) return false;

        return canStartReplaceMode(monitor, props);
    },
    collect: (monitor, props) => ({
        isHovered: monitor.isOver(),
        canDrop: monitor.canDrop(),
        canStartReplaceMode: monitor.isOver() && !props.isReplaceModeHovered && canStartReplaceMode(monitor, props),
    }),
    connectRef: (ref) => ({
        // Naming it like this so that the file upload component will use this connector function
        connectFileDropTarget: ref,
    }),
};

export default (overrideDropTargetConfig = {}) => {
    // A different reference of dropTargetConfig needs to be used for each usage of element drop target,
    // otherwise will cause drop target to behave inconsistently if used in multiple elements
    const dropTargetConfig = {
        ...defaultImageDropTargetConfig,
        ...overrideDropTargetConfig,
    };

    return compose(
        ElementDropTarget(dropTargetConfig),
        // This must be placed below the ElementDropTarget
        imageHoverWatcher,
    );
};
