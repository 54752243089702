import React from 'react';
import PropTypes from 'prop-types';
import { compact, padStart } from 'lodash';

import './FileMediaPlayerDuration.scss';

const HOUR_LENGTH = 60 * 60;
const MINUTE_LENGTH = 60;

const calulateHours = (duration) => Math.max(0, Math.floor(duration / HOUR_LENGTH));

const calulateMinutes = (duration) => Math.max(0, Math.floor(duration / MINUTE_LENGTH) - calulateHours(duration) * 60);

const calulateSeconds = (duration) =>
    Math.max(
        0,
        Math.floor(duration - calulateMinutes(duration) * MINUTE_LENGTH - calulateHours(duration) * HOUR_LENGTH),
    );

const formatDuration = (duration, totalDuratuon) => {
    const showHours = totalDuratuon > HOUR_LENGTH;
    const padMinutes = showHours || calulateMinutes(totalDuratuon) > 9;

    const hours = calulateHours(duration);
    const minutes = calulateMinutes(duration);
    const seconds = calulateSeconds(duration);

    return [
        ...compact([showHours && padStart(hours, 1, '0')]),
        padMinutes ? padStart(minutes, 2, '0') : minutes,
        padStart(seconds, 2, '0'),
    ];
};

const FileMediaPlayerDuration = ({ progress, duration }) => {
    const durationCurrent = formatDuration(progress * duration, duration);
    const durationMax = formatDuration(duration, duration);

    const durationCharacters = durationMax.join('').length;
    const playerDurationWidth = durationCharacters * 2 + 3;

    return (
        <div className="FileMediaPlayerDuration" style={{ width: `${playerDurationWidth}rem` }}>
            <span>{durationCurrent.join(':')}</span> / <span>{durationMax.join(':')}</span>
        </div>
    );
};

FileMediaPlayerDuration.propTypes = {
    progress: PropTypes.number,
    duration: PropTypes.number,
};

export default FileMediaPlayerDuration;
