// Lib
import React from 'react';
import { BoardSections } from '../../../common/boards/boardConstants';

/**
 * This is used to allow POIs to know what section they belong to.
 * Their section cannot be simply passed down as a prop due to some POIs existing within Draft JS, which
 * will not pass the parent props in.
 */
const PoiBoardSectionContext = React.createContext<BoardSections | null>(null);
export default PoiBoardSectionContext;

export const poiBoardSectionContextConsumer = (DecoratedComponent: any) => {
    class PoiBoardSectionContextConsumer extends React.Component {
        render() {
            return <DecoratedComponent boardSection={this.context} {...this.props} />;
        }
    }

    PoiBoardSectionContextConsumer.contextType = PoiBoardSectionContext;

    return PoiBoardSectionContextConsumer;
};
