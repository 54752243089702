// Utils
import { isOverlapping } from './rectComparisonUtils';

/**
 * Finds a graph of all the rectangles that overlap each other.
 */
export const getRectOverlapGraph = (rectsMap) => {
    const rectIds = Object.keys(rectsMap);
    const visitedRectIds = {};

    const overlapGraph = {};

    for (const currentRectId of rectIds) {
        if (visitedRectIds[currentRectId]) continue;

        visitedRectIds[currentRectId] = true;

        const currentRect = rectsMap[currentRectId];

        overlapGraph[currentRectId] = overlapGraph[currentRectId] || [];

        for (const otherRectId of rectIds) {
            if (visitedRectIds[otherRectId]) continue;

            const otherRect = rectsMap[otherRectId];

            if (isOverlapping(currentRect, otherRect)) {
                overlapGraph[currentRectId].push(otherRectId);

                overlapGraph[otherRectId] = overlapGraph[otherRectId] || [];
                overlapGraph[otherRectId].push(currentRectId);
            }
        }
    }

    return overlapGraph;
};
