// Lib
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import { getImageDrawingCropRect } from '../../imageDrawingUtils';
import { isEmpty } from '../../../../../common/utils/immutableHelper';
import { getHeight, getWidth, getX, getY } from '../../../../../common/maths/geometry/rect';
import useDrawingEditorState from '../../../drawing/drawingEditor/useDrawingEditorState';
import useDrawingEditorIPadState from '../../../drawing/drawingEditor/useDrawingEditorIPadState';
import useDrawingEditorToolbarState from '../../../drawing/drawingEditor/useDrawingEditorToolbarState';
import { getImageEditorDataRotation, getOriginalImageHeight, getOriginalImageWidth } from '../imageModalUtils';
import { createInitialPaths, createSketchElementDrawingContent } from '../../../drawing/sketch/modal/sketchModalUtils';
import {
    getDrawingHeight,
    getDrawingWidth,
    getElementId,
} from '../../../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { isCurrentUserSettingDrawingInkEffectEnabled } from '../../../../user/currentUserSelector';

// Actions
import { updateElement } from '../../../actions/elementActions';

// Hooks
import useSendAmplitudeEventOnMount from '../../../../analytics/useSendAmplitudeEventOnMount';
import useDrawingEditorKeyboardEventHandler from '../../../drawing/drawingEditor/useDrawingEditorKeyboardEventHandler';

// Components
import ImageModalToolbarSecondaryTools from '../toolbar/ImageModalToolbarSecondaryTools';
import DrawingEditorCanvas from '../../../drawing/drawingEditor/drawingEditorCanvas/DrawingEditorCanvas';
import DrawingEditorToolbar from '../../../drawing/drawingEditor/drawingEditorToolbar/DrawingEditorToolbar';

// Constants
import { SKETCH_LINE_COLORS_ARRAY } from '../../../../../common/colors/colorConstants';
import { EVENT_TYPE_NAMES } from '../../../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../../common/analytics/statsConstants';

// Styles
import './ImageModalDrawingModeContent.scss';

const mapStateToProps = createStructuredSelector({
    enableDrawingInkEffect: isCurrentUserSettingDrawingInkEffectEnabled,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateDrawing: ({ id, drawing, hasDrawing }) =>
        dispatch(
            updateElement({
                id,
                changes: { drawing, hasDrawing },
            }),
        ),
});

const ImageModalDrawingModeContent = (props) => {
    const {
        element,
        gridSize,
        attachment,
        modalWidth,
        finishEditingMode,
        dispatchUpdateDrawing,
        enableDrawingInkEffect,
        closeCallbackRef,
    } = props;

    // Analytics
    useSendAmplitudeEventOnMount({
        eventType: EVENT_TYPE_NAMES.IMAGE_DRAWING,
        userProperties: {
            [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.IMAGE_DRAWING]: true },
        },
    });

    const elementId = getElementId(element);

    const svgRef = useRef();
    const onPathPropertyChangeRef = useRef();
    const [editorClassName, setEditorClassName] = useState();

    const initialPaths = useMemo(() => createInitialPaths({ element }), [elementId]);

    const rotation = getImageEditorDataRotation(element);
    const isRotatedOnSide = !!rotation && rotation !== -180;

    const originalImageWidth = getOriginalImageWidth({ element, gridSize, attachment });
    const originalImageHeight = getOriginalImageHeight({ element, gridSize, attachment });

    const rotatedOriginalImageWidth = isRotatedOnSide ? originalImageHeight : originalImageWidth;
    const rotatedOriginalImageHeight = isRotatedOnSide ? originalImageWidth : originalImageHeight;

    const drawingWidth = getDrawingWidth(element) || rotatedOriginalImageWidth;
    const drawingHeight = getDrawingHeight(element) || rotatedOriginalImageHeight;

    const drawingCropRectangle = getImageDrawingCropRect(element);

    const scale = modalWidth / (getWidth(drawingCropRectangle) || drawingWidth);

    const {
        paths,
        currentPath,
        setPaths,
        beginPath,
        endPath,
        cancelPath,
        appendPointToCurrentPath,
        removePaths,
        undoPath,
        redoPath,
        canUndo,
        canRedo,
    } = useDrawingEditorState(initialPaths);

    const { isPenMode, setIsPenMode } = useDrawingEditorIPadState({ svgRef, undoPath, redoPath });

    const {
        toolMode,
        toolModeState,
        setToolModeState,
        strokeSize,
        strokeColor,
        colorPopupOpen,
        strokePopupOpen,
        customColorInputOpen,
        setToolMode,
        setStrokeSize,
        setStrokeColor,
        switchToPreviousColor,
        setStrokeSizeState,
        setStrokeColorState,
        setColorPopupOpen,
        setCustomColorInputOpen,
        setStrokePopupOpen,
        closeAllPopups,
    } = useDrawingEditorToolbarState({ onPathPropertyChangeRef });

    const saveDrawing = (pathsToSave) => {
        const drawing = createSketchElementDrawingContent({ paths: pathsToSave, drawingWidth, drawingHeight });

        if (!drawing && !isEmpty(pathsToSave)) return;

        dispatchUpdateDrawing({ id: elementId, drawing, hasDrawing: !isEmpty(pathsToSave) });
    };

    const saveAndExitDrawingEditor = (pathsToSave) => {
        saveDrawing(pathsToSave);
        finishEditingMode();
    };

    // When closing the modal via clicking the overlay, ensure the drawing is saved
    closeCallbackRef.current = (event) => {
        saveDrawing(paths);
    };

    const handleSaveButton = () => {
        saveDrawing(paths);
        finishEditingMode();
    };

    const cancelEditing = () => {
        finishEditingMode();
    };

    useDrawingEditorKeyboardEventHandler({
        toolMode,
        undoPath,
        redoPath,
        cancelPath,
        setToolMode,
        setStrokeSize,
        switchToPreviousColor,
        handleSaveButton,
        colorPopupOpen,
        setColorPopupOpen,
    });

    const wrapperStyles = {
        '--drawing-canvas-scale': scale,
    };

    return (
        <div className={classNames('ImageModalDrawingModeContent', editorClassName)} style={wrapperStyles}>
            <DrawingEditorCanvas
                ref={svgRef}
                canvasScale={scale}
                viewBoxX={getX(drawingCropRectangle)}
                viewBoxY={getY(drawingCropRectangle)}
                viewBoxWidth={getWidth(drawingCropRectangle) || drawingWidth}
                viewBoxHeight={getHeight(drawingCropRectangle) || drawingHeight}
                onPathPropertyChangeRef={onPathPropertyChangeRef}
                setEditorClassName={setEditorClassName}
                isEditable
                isPopupOpen={colorPopupOpen || strokePopupOpen || customColorInputOpen}
                isCurrentlyDrawing={!!currentPath}
                toolMode={toolMode}
                setToolModeState={setToolModeState}
                paths={paths}
                setPaths={setPaths}
                beginPath={beginPath}
                endPath={endPath}
                cancelPath={cancelPath}
                isPenMode={isPenMode}
                appendPointToCurrentPath={appendPointToCurrentPath}
                removePaths={removePaths}
                strokeColor={strokeColor}
                enableDrawingInkEffect={enableDrawingInkEffect}
                strokeSize={strokeSize}
                setStrokeSizeState={setStrokeSizeState}
                setStrokeColorState={setStrokeColorState}
                closeAllPopups={closeAllPopups}
                saveAndExitDrawingEditor={saveAndExitDrawingEditor}
            />

            <ImageModalToolbarSecondaryTools className="drawing-editor-toolbar-container">
                <DrawingEditorToolbar
                    isDrawing={!!currentPath}
                    defaultColors={SKETCH_LINE_COLORS_ARRAY}
                    isEditingExistingDrawing
                    undoPath={undoPath}
                    redoPath={redoPath}
                    canUndo={canUndo}
                    canRedo={canRedo}
                    isPenMode={isPenMode}
                    setIsPenMode={setIsPenMode}
                    toolMode={toolMode}
                    toolModeState={toolModeState}
                    strokeSize={strokeSize}
                    strokeColor={strokeColor}
                    colorPopupOpen={colorPopupOpen}
                    strokePopupOpen={strokePopupOpen}
                    setToolMode={setToolMode}
                    handleDiscardButton={cancelEditing}
                    handleSaveButton={handleSaveButton}
                    handleSetStrokeSize={setStrokeSize}
                    handleSetStrokeColor={setStrokeColor}
                    setColorPopupOpen={setColorPopupOpen}
                    setCustomColorInputOpen={setCustomColorInputOpen}
                    setStrokePopupOpen={setStrokePopupOpen}
                />
            </ImageModalToolbarSecondaryTools>
        </div>
    );
};

ImageModalDrawingModeContent.propTypes = {
    element: PropTypes.object,
    gridSize: PropTypes.number,
    attachment: PropTypes.object,

    modalWidth: PropTypes.number,
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
    idealHeight: PropTypes.number,

    enableDrawingInkEffect: PropTypes.bool,

    finishEditingMode: PropTypes.func,
    dispatchUpdateDrawing: PropTypes.func,
    closeCallbackRef: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModalDrawingModeContent);
