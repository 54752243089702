import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavCreateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={21}
        viewBox="0 0 22 21"
        className="Icon nav-create"
        {...props}
    >
        <path
            fill="#979797"
            fillRule="evenodd"
            d="M3.493 7.5a2.998 2.998 0 0 0-2.993 3c0 1.663 1.336 3 2.993 3H8.5L8 13v5.007A2.998 2.998 0 0 0 11 21c1.663 0 3-1.336 3-2.993V13l-.5.5h5.007a2.998 2.998 0 0 0 2.993-3c0-1.663-1.336-3-2.993-3H13.5l.5.5V2.993A2.998 2.998 0 0 0 11 0a2.99 2.99 0 0 0-3 2.993V8l.5-.5H3.493ZM9 8.5V2.993A1.99 1.99 0 0 1 11 1c1.104 0 2 .894 2 1.993V8.5h5.507a1.99 1.99 0 0 1 1.993 2c0 1.104-.894 2-1.993 2H13v5.507A1.99 1.99 0 0 1 11 20c-1.104 0-2-.894-2-1.993V12.5H3.493a1.99 1.99 0 0 1-1.993-2c0-1.104.894-2 1.993-2H9Z"
        />
    </svg>
);
const Memo = memo(NavCreateIcon);
export default Memo;
