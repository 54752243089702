const isDomElementScrollable = (node) => {
    if (!node || !(node instanceof Element)) return false;

    const computedStyle = window.getComputedStyle(node);

    if (!computedStyle) return false;

    return (
        computedStyle.overflow === 'auto' ||
        computedStyle.overflow === 'scroll' ||
        computedStyle['overflow-y'] === 'auto' ||
        computedStyle['overflow-y'] === 'scroll' ||
        computedStyle['overflow-x'] === 'auto' ||
        computedStyle['overflow-x'] === 'scroll'
    );
};

export default isDomElementScrollable;
