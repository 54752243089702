import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SearchCurrentBoardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={58}
        height={52}
        viewBox="0 0 58 52"
        className="Icon search-current-board"
        {...props}
    >
        <defs>
            <filter
                id="SearchCurrentBoardIcon__a"
                width="118.5%"
                height="117.8%"
                x="-9.2%"
                y="-5.9%"
                filterUnits="objectBoundingBox"
            >
                <feMorphology in="SourceAlpha" operator="dilate" radius={1} result="shadowSpreadOuter1" />
                <feOffset dy={1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.431372549 0 0 0 0 0.431372549 0 0 0 0 0.443137255 0 0 0 0.329313859 0"
                />
            </filter>
            <filter
                id="SearchCurrentBoardIcon__d"
                width="377.4%"
                height="373.9%"
                x="-138.7%"
                y="-136.9%"
                filterUnits="objectBoundingBox"
            >
                <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 1 0 0 0 0 0.992156863 0 0 0 0 0.866666667 0 0 0 0.5 0"
                />
            </filter>
            <filter
                id="SearchCurrentBoardIcon__f"
                width="111.7%"
                height="111.5%"
                x="-5.8%"
                y="-2.9%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
            </filter>
            <filter
                id="SearchCurrentBoardIcon__h"
                width="111.8%"
                height="115.4%"
                x="-5.9%"
                y="-3.8%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={0.5} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            </filter>
            <path id="SearchCurrentBoardIcon__b" d="M2.906 3.955h32.45v33.772H2.906z" />
            <path
                id="SearchCurrentBoardIcon__e"
                d="M24.156 15.742c1.493 0 2.704-1.226 2.704-2.738 0-1.513-1.21-2.739-2.704-2.739-1.494 0-2.704 1.226-2.704 2.739 0 1.512 1.21 2.738 2.704 2.738z"
            />
            <path id="SearchCurrentBoardIcon__i" d="M0 0h34v26H0z" />
            <linearGradient id="SearchCurrentBoardIcon__c" x1="50%" x2="50%" y1="100%" y2="0%">
                <stop offset="0%" stopColor="#96BA69" />
                <stop offset="100%" stopColor="#7AA948" />
            </linearGradient>
            <rect id="SearchCurrentBoardIcon__g" width={34.253} height={34.685} x={3.564} y={3.439} rx={3.629} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="rotate(-10 19.63 15.126)">
                <use xlinkHref="#SearchCurrentBoardIcon__b" fill="#000" filter="url(#SearchCurrentBoardIcon__a)" />
                <use xlinkHref="#SearchCurrentBoardIcon__b" fill="#FFF" />
                <path stroke="#A5A5A5" d="M2.406 3.455h33.45v34.772H2.406z" />
            </g>
            <path fill="#A8D8F4" d="M4.358 9.256 30.99 4.561l3.804 21.573-26.632 4.695z" />
            <path fill="#84AF53" d="m14.979 17.579 10.937 10.12-17.753 3.13z" />
            <path
                fill="url(#SearchCurrentBoardIcon__c)"
                d="m27.39 19.905 5.858 7.302H21.53z"
                transform="rotate(-10 27.89 17.84)"
            />
            <path
                fill="#000"
                fillOpacity={0.104}
                fillRule="nonzero"
                d="m30.99 4.56 3.803 21.574-26.63 4.696L4.359 9.256l26.63-4.696zM5.404 9.998l3.487 19.776 24.855-4.382L30.26 5.616 5.405 9.998z"
            />
            <g transform="rotate(-10 24.656 7.289)">
                <use xlinkHref="#SearchCurrentBoardIcon__e" fill="#000" filter="url(#SearchCurrentBoardIcon__d)" />
                <use xlinkHref="#SearchCurrentBoardIcon__e" fill="#FFFDDD" />
            </g>
            <g transform="rotate(10 -5.1 120.937)">
                <use xlinkHref="#SearchCurrentBoardIcon__g" fill="#000" filter="url(#SearchCurrentBoardIcon__f)" />
                <use xlinkHref="#SearchCurrentBoardIcon__g" fill="#C7C7C7" />
                <rect width={33.253} height={33.685} x={4.064} y={3.939} stroke="#A5A5A5" rx={3.629} />
            </g>
            <path
                fill="#FFF"
                d="m30.399 16.228 7.988 1.409-1.902 10.786-7.988-1.409zM28.18 28.812l7.988 1.409-.951 5.394-7.989-1.409zM40.163 17.95l7.988 1.408-3.17 17.978-7.988-1.41z"
            />
            <g transform="translate(12 24)">
                <use xlinkHref="#SearchCurrentBoardIcon__i" fill="#000" filter="url(#SearchCurrentBoardIcon__h)" />
                <use xlinkHref="#SearchCurrentBoardIcon__i" fill="#FFF" />
                <path stroke="#A5A5A5" strokeWidth={0.907} d="M.454.454v25.092h33.092V.454H.454z" />
            </g>
            <path fill="#929292" fillRule="nonzero" d="M18 32h22v-1H18zm0 3h21v-1H18zm0 3h22v-1H18zm0 3h20v-1H18z" />
        </g>
    </svg>
);
const Memo = memo(SearchCurrentBoardIcon);
export default Memo;
