// Utils
import { getTimestamp } from '../utils/timeUtil';

// Constants
import * as SELECTION_ACTION_TYPES from './selectionConstants';

export const deselectAll = ({ userId, sync = true }) => ({
    type: SELECTION_ACTION_TYPES.ELEMENTS_DESELECT_ALL,
    timestamp: getTimestamp(),
    user: {
        _id: userId,
    },
    sync,
});
