// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getFilenameFromUrl } from '../imageModalFileUtils';
import { getElementId, getFileMimeType, getImageProp } from '../../../../../common/elements/utils/elementPropertyUtils';
import { getImageSource } from '../../imageHelper';

// Components
import Icon from '../../../../components/icons/Icon';
import { getLargestImageSize } from '../imageModalUtils';
import ModalToolbarButton from '../modalToolbar/ModalToolbarButton';
import ModalToolbarDownloadButton from '../modalToolbar/ModalToolbarDownloadButton';
import DownloadOpenLinkInBrowserButton from '../../../../components/buttons/DownloadOpenLinkInBrowserButton';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../../../feature/elementFeatureSelector';

// Constants
import { IMAGE_MODAL_MODES } from '../imageModalConstants';
import { ExperimentId } from '../../../../../common/experiments/experimentsConstants';

// Styles
import './ImageModalViewToolbar.scss';

const mapStateToProps = createStructuredSelector({
    renderSecureImagesThroughProxy: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughProxy),
    // Default to enabled for guest users
    renderSecureImagesThroughCF: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughCF, true),
});

const ImageModalViewToolbar = (props) => {
    const {
        element,
        isEditable,
        setMode,
        renderSecureImagesThroughProxy,
        renderSecureImagesThroughCF,
        isPresentationMode,
    } = props;

    const elementId = getElementId(element);
    const imageType = getFileMimeType(element) || 'image/jpeg';
    const imageDetails = getImageProp(element);

    const largestImageUrl = getImageSource({
        imageSize: getLargestImageSize(imageDetails),
        imageDetails,
        elementId,

        useSecureMediaUrl: true,
        optimizeForWebRender: false,

        renderSecureImagesThroughProxy,
        renderSecureImagesThroughCF,
    });

    return (
        <div className="ImageModalViewToolbar">
            {!isPresentationMode && (
                <ModalToolbarDownloadButton
                    url={largestImageUrl}
                    filename={getFilenameFromUrl(largestImageUrl, imageType)}
                    elementId={elementId}
                >
                    Download
                </ModalToolbarDownloadButton>
            )}
            <DownloadOpenLinkInBrowserButton url={largestImageUrl}>
                <ModalToolbarButton>
                    <Icon name="modal-toolbar-open-new-tab" />
                    <div className="text">New tab</div>
                </ModalToolbarButton>
            </DownloadOpenLinkInBrowserButton>
            {isEditable && (
                <ModalToolbarButton onClickFn={() => setMode(IMAGE_MODAL_MODES.DRAW)}>
                    <Icon name="modal-toolbar-draw" />
                    <div className="text">Draw</div>
                </ModalToolbarButton>
            )}
            {isEditable && !isPresentationMode && (
                <ModalToolbarButton onClickFn={() => setMode(IMAGE_MODAL_MODES.CROP)}>
                    <Icon name="modal-toolbar-crop" />
                    <div className="text">Crop/rotate</div>
                </ModalToolbarButton>
            )}
        </div>
    );
};

ImageModalViewToolbar.propTypes = {
    element: PropTypes.object,
    isEditable: PropTypes.bool,
    isPresentationMode: PropTypes.bool,
    isEditingDisabled: PropTypes.bool,

    setMode: PropTypes.func,

    renderSecureImagesThroughProxy: PropTypes.bool,
    renderSecureImagesThroughCF: PropTypes.bool,
};

export default connect(mapStateToProps)(ImageModalViewToolbar);
