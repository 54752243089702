/**
 * Determines if the editor is empty.
 */
export default (editorState) => {
    if (!editorState) return true;

    const contentState = editorState.getCurrentContent();

    if (contentState.hasText()) return false;

    // The editor is "in composition mode" when IME is used to enter text (e.g. entering Korean text).
    // Composition mode will only be true when there's text in the editor, so in this case the editor isn't empty
    return !editorState.isInCompositionMode();
};
