// Lib
import { mapValues } from 'lodash';

// Constants
import {
    ELEMENT_CREATE,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
} from '../elements/elementConstants';

import { GUEST_NAVIGATE, USER_NAVIGATE } from '../users/userConstants';

import {
    FETCH_ME,
    USER_INVITE,
    USER_REGISTER,
    USER_REFERRAL,
    SUBSCRIPTION_START,
    SUBSCRIPTION_ADD,
    SUBSCRIPTION_CANCEL,
    SUBSCRIPTION_REMOVE,
    MENTIONED_A_USER,
    MENTIONED_BY_USER,
    ASSIGNED_TASK_TO_A_USER,
    ASSIGNED_TASK_BY_A_USER,
    USER_EMAIL_VERIFIED,
    SUBSCRIPTION_REQUEST_CANCEL,
    SUBSCRIPTION_RESUBSCRIBE,
} from './statsConstants';

import { ELEMENT_SEARCH_SUBMIT } from '../../client/components/search/searchPopupConstants';

import { PUSH_NOTIFICATION_SENT } from '../../server/app/components/notifications/pushNotifications/pushNotificationActions';

const EVENT_ORIGINS = {
    SERVER: 'SERVER',
    CLIENT: 'CLIENT',
};

export const EVENT_TYPE_NAMES = {
    ELEMENT_CREATE: 'Element created',
    ELEMENT_CLONE: 'Element cloned',
    ELEMENT_UPDATE: 'Element updated (move/edit/delete)',

    // Navigation
    NAVIGATION: 'Navigated to a board',

    ELEMENT_SHARE: 'Shared a board with someone',

    USER_INVITE: 'Invited to a board (but not signed up yet)',
    USER_REGISTER: 'Signed up',
    USER_REFERRAL: 'Successfully referred a friend',
    USER_EMAIL_VERIFIED: 'Verified email',

    SUBSCRIPTION_START: 'Pro account: subscribed as admin',
    SUBSCRIPTION_ADD: 'Pro account: person added to team',
    SUBSCRIPTION_CANCEL: 'Pro account: subscription cancelled by admin',
    SUBSCRIPTION_REMOVE: 'Pro account: person removed from team',
    SUBSCRIPTION_REQUEST_CANCEL: 'Pro account: requested cancellation',
    SUBSCRIPTION_RESUBSCRIBE: 'Pro account: resubscribed before cancellation',

    // Search
    ELEMENT_SEARCH_SUBMIT: 'Used search',

    CHECKLIST: 'Checklist used',
    ADDED_TITLE_TO_TO_DO_LIST: 'Added title to to-do list',
    DRAGGED_TO_DO_ITEM: 'Dragged a to-do item',

    // Text style
    TEXT_STYLE_TOGGLE: 'Toggled text block style',

    IMAGE_DRAWING: 'Drew on image',

    ATTACHED_LINE: 'Attached line',

    ATTACHED_COMMENT_THREAD: 'Attached comment thread',
    COLLAPSED_COMMENT_THREAD: 'Collapsed comment thread',

    EXPORTED_DOCUMENT: 'Exported document',

    INVITED_USER: 'Invited user',
    SECRET_LINK: 'Secret link',

    PUSH_PERMISSION_DECISION: 'Push permission decision',
    PUSH_SENT: 'Push sent',
    // PUSH_RECEIVED: 'Push received',
    // PUSH_CLICKED: 'Push clicked',

    MENTIONED_A_USER: 'Mentioned another user',
    MENTIONED_BY_USER: 'Mentioned by a user',

    ASSIGNED_TASK_TO_A_USER: 'Assigned task to another user',
    ASSIGNED_TASK_BY_A_USER: 'Assigned task by a user',

    ENTERED_PRESENTATION_MODE: 'Entered Presentation Mode',
    PRESENTATION_MODE_HIDE_COMMENTS_ENABLED: 'Presentation Mode: Hide comments enabled',
    PRESENTATION_MODE_PREVENT_EDITING_ENABLED: 'Presentation Mode: Prevent editing enabled',

    SWITCHED_TO_V1: 'Switched to V1',

    SWITCHED_THEME: 'Switched theme',
    SET_PREFERENCE: 'Set preference',
    SET_BOARD_BACKGROUND: 'Set board background',

    LOADED_IN_IFRAME: 'Loaded in iframe',

    // Payment tracking
    VIEW_PAYMENT_SCREEN_REFER: 'Viewed payment screen: refer a friend',
    VIEW_PAYMENT_SCREEN_PLANS: 'Viewed payment screen: plans list',
    VIEW_PAYMENT_SCREEN_PLAN_INDIVIDUAL_TEAM: 'Viewed payment screen: pro individual / team',
    VIEW_PAYMENT_SCREEN_TEAM: 'Viewed payment screen: team selection',
    VIEW_PAYMENT_SCREEN_PAYMENT: 'Viewed payment screen: payment',
    VIEW_PAYMENT_SCREEN_PAYMENT_SUCCESS: 'Viewed payment screen: payment success',
    VIEW_PAYMENT_SCREEN_PAYMENT_UPDATED: 'Viewed payment screen: payment updated',

    // Referral Tracking
    REFERRAL_TWITTER_SHARE: 'Referral: Twitter share',
    REFERRAL_FACEBOOK_SHARE: 'Referral: Facebook share',
    REFERRAL_EMAIL_SHARE: 'Referral: Email share',

    // Domain subscriptions
    DOMAIN_SUBSCRIPTION_INVITE_SENT: 'Invited to a domain subscription',
    DOMAIN_SUBSCRIPTION_INVITE_CLAIMED: 'Claimed invite to a domain subscription',
    DOMAIN_SUBSCRIPTION_RENEWAL_SENT: 'Sent a domain subscription renewal',
    DOMAIN_SUBSCRIPTION_RENEWAL_CLAIMED: 'Renewed domain subscription',

    // Checkout flow
    VIEWED_CHECKOUT_REFER_SCREEN: 'Viewed checkout screen: refer a friend',
    VIEWED_CHECKOUT_PRICING_SCREEN: 'Viewed checkout screen: pricing',
    VIEWED_CHECKOUT_PRODUCT_PRICING_SCREEN: 'Viewed checkout screen: product pricing options',
    VIEWED_CHECKOUT_TEAM_MANAGEMENT_SCREEN: 'Viewed checkout screen: team management',
    VIEWED_CHECKOUT_SUCCESS_SCREEN: 'Viewed checkout screen: success',
    VIEWED_STRIPE_CHECKOUT_SCREEN: 'Viewed Stripe checkout screen',

    // UNDO/REDO
    CLICKED_UNDO_BUTTON: 'Clicked undo button',
    CLICKED_REDO_BUTTON: 'Clicked redo button',

    // AI popup
    USED_AI_SHORTCUT: 'Used an AI prompt shortcut',
    AI_SUBMISSION: 'AI submission',
    AI_RESULT_GENERATED: 'AI result generated',
    VIEWED_AI_HISTORY: 'Viewed AI history',
    SELECTED_AI_HISTORY_ENTRY: 'Selected AI history entry',

    APP_MULTI_REFRESH: 'Refreshed app multiple times',
};

const EVENT_TYPE_MAP = {
    // -- Client - These events will only be sent when user consents to performance cookies

    [ELEMENT_CREATE]: {
        name: EVENT_TYPE_NAMES.ELEMENT_CREATE,
        origin: EVENT_ORIGINS.CLIENT,
    },

    [ELEMENT_SEARCH_SUBMIT]: {
        name: EVENT_TYPE_NAMES.ELEMENT_SEARCH_SUBMIT,
        origin: EVENT_ORIGINS.CLIENT,
    },

    // Throttled client-side event - cannot be moved to server
    [ELEMENT_MOVE_AND_UPDATE]: {
        name: EVENT_TYPE_NAMES.ELEMENT_UPDATE,
        origin: EVENT_ORIGINS.CLIENT,
    },

    // Throttled client-side event - cannot be moved to server
    [ELEMENT_MOVE_MULTI]: {
        name: EVENT_TYPE_NAMES.ELEMENT_UPDATE,
        origin: EVENT_ORIGINS.CLIENT,
    },

    // Throttled client-side event - cannot be moved to server
    [ELEMENT_UPDATE]: {
        name: EVENT_TYPE_NAMES.ELEMENT_UPDATE,
        origin: EVENT_ORIGINS.CLIENT,
    },

    // Throttled client-side event - cannot be moved to server
    [USER_NAVIGATE]: {
        name: EVENT_TYPE_NAMES.NAVIGATION,
        origin: EVENT_ORIGINS.CLIENT,
    },

    // Throttled client-side event - cannot be moved to server
    [GUEST_NAVIGATE]: {
        name: EVENT_TYPE_NAMES.NAVIGATION,
        origin: EVENT_ORIGINS.CLIENT,
    },

    // -- Server - These events will be sent disregarding user cookie consent settings

    [FETCH_ME]: {
        name: EVENT_TYPE_NAMES.NAVIGATION,
        origin: EVENT_ORIGINS.SERVER,
    },
    [USER_INVITE]: {
        name: EVENT_TYPE_NAMES.USER_INVITE,
        origin: EVENT_ORIGINS.SERVER,
    },
    [USER_REGISTER]: {
        name: EVENT_TYPE_NAMES.USER_REGISTER,
        origin: EVENT_ORIGINS.SERVER,
    },
    [USER_REFERRAL]: {
        name: EVENT_TYPE_NAMES.USER_REFERRAL,
        origin: EVENT_ORIGINS.SERVER,
    },
    [USER_EMAIL_VERIFIED]: {
        name: EVENT_TYPE_NAMES.USER_EMAIL_VERIFIED,
        origin: EVENT_ORIGINS.SERVER,
    },
    [SUBSCRIPTION_START]: {
        name: EVENT_TYPE_NAMES.SUBSCRIPTION_START,
        origin: EVENT_ORIGINS.SERVER,
    },
    [SUBSCRIPTION_ADD]: {
        name: EVENT_TYPE_NAMES.SUBSCRIPTION_ADD,
        origin: EVENT_ORIGINS.SERVER,
    },
    [SUBSCRIPTION_CANCEL]: {
        name: EVENT_TYPE_NAMES.SUBSCRIPTION_CANCEL,
        origin: EVENT_ORIGINS.SERVER,
    },
    [SUBSCRIPTION_REMOVE]: {
        name: EVENT_TYPE_NAMES.SUBSCRIPTION_REMOVE,
        origin: EVENT_ORIGINS.SERVER,
    },
    [SUBSCRIPTION_REQUEST_CANCEL]: {
        name: EVENT_TYPE_NAMES.SUBSCRIPTION_REQUEST_CANCEL,
        origin: EVENT_ORIGINS.SERVER,
    },
    [SUBSCRIPTION_RESUBSCRIBE]: {
        name: EVENT_TYPE_NAMES.SUBSCRIPTION_RESUBSCRIBE,
        origin: EVENT_ORIGINS.SERVER,
    },
    [PUSH_NOTIFICATION_SENT]: {
        name: EVENT_TYPE_NAMES.PUSH_SENT,
    },
    [MENTIONED_A_USER]: {
        name: EVENT_TYPE_NAMES.MENTIONED_A_USER,
        origin: EVENT_ORIGINS.SERVER,
    },
    [MENTIONED_BY_USER]: {
        name: EVENT_TYPE_NAMES.MENTIONED_BY_USER,
        origin: EVENT_ORIGINS.SERVER,
    },
    [ASSIGNED_TASK_TO_A_USER]: {
        name: EVENT_TYPE_NAMES.ASSIGNED_TASK_TO_A_USER,
        origin: EVENT_ORIGINS.SERVER,
    },
    [ASSIGNED_TASK_BY_A_USER]: {
        name: EVENT_TYPE_NAMES.ASSIGNED_TASK_BY_A_USER,
        origin: EVENT_ORIGINS.SERVER,
    },
};

const SERVER_EVENTS = mapValues(EVENT_TYPE_MAP, (entry) => entry.origin === EVENT_ORIGINS.SERVER);
const CLIENT_EVENTS = mapValues(EVENT_TYPE_MAP, (entry) => entry.origin === EVENT_ORIGINS.CLIENT);

export const isServerAnalyticsEventType = (eventType) => !!SERVER_EVENTS[eventType];
export const isClientAnalyticsEventType = (eventType) => !!CLIENT_EVENTS[eventType];

export const sanitiseEventTypeName = (eventType) => EVENT_TYPE_MAP[eventType] && EVENT_TYPE_MAP[eventType].name;
