import * as Immutable from 'immutable';
import { GRID_RESIZE, GRID } from './gridConstants';

import { getStoredGridSize, calculateGridSize } from './gridUtils';
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformIframe } from '../../platform/utils/platformDetailsUtils';

const createInitialState = () => {
    // electron app is unable to set gridsize immediately, so will be initialized on GridSizeManager mount
    if (typeof screen === 'undefined') {
        return Immutable.Map({
            size: GRID.LARGE.size,
            name: GRID.LARGE.name,
            initialized: false,
        });
    }

    if (isPlatformIframe(platformSingleton)) {
        return Immutable.Map({
            size: GRID.SMALL.size,
            name: GRID.SMALL.name,
            initialized: true,
        });
    }

    const { size, name } =
        getStoredGridSize() || calculateGridSize(screen.width, screen.height, platformSingleton.features.isTouch);

    return Immutable.Map({
        size: size || GRID.LARGE.size,
        name: name || GRID.LARGE.name,
        initialized: true,
    });
};

export default function gridReducer(state = createInitialState(), action) {
    switch (action.type) {
        case GRID_RESIZE:
            return Immutable.Map({
                size: action.gridSize,
                name: action.sizeName,
                initialized: true,
            });
        default:
            return state;
    }
}
