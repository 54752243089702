// Lib
import { uniq } from 'lodash/fp';

// Utils
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { isTaskList } from '../../../common/elements/utils/elementTypeUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getElements } from '../selectors/elementSelector';
import { elementGraphSelector } from '../selectors/elementGraphSelector';

// Actions
import { createElementSync } from '../actions/elementActions';

// Constants
import { getChildrenViaGraph } from '../../../common/elements/utils/elementGraphUtils';
import { ElementType } from '../../../common/elements/elementTypes';

/**
 * If any of the moves are from a TaskList then we need to check if the TaskList is now empty.
 * If it is empty then we need to add a new Task to that TaskList so that it behaves like normal in future
 * interactions.
 */
export const createTaskInEmptyTaskListsAfterMoves =
    ({ moves, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);
        const elementGraph = elementGraphSelector(state);

        const fromTaskListIds = moves.reduce((taskListIds, move) => {
            const fromParentId = move.from.parentId;

            const fromElement = getElement(elements, fromParentId);

            if (isTaskList(fromElement)) taskListIds.push(fromParentId);

            return taskListIds;
        }, []);

        uniq(fromTaskListIds).forEach((taskListId) => {
            const childCount = getChildrenViaGraph({ elements, elementGraph, parentId: taskListId }).size;

            if (childCount === 0) {
                const location = getListChildNewLocation({ listId: taskListId });

                dispatch(
                    createElementSync({
                        elementType: ElementType.TASK_TYPE,
                        location,
                        select: true,
                        transactionId,
                    }),
                );
            }
        });
    };
