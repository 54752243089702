import { registerPlugin } from '@capacitor/core';

import type { SignInWithAppleNativeAndroidPlugin } from './definitions';
import { CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_ANDROID_PLUGIN_NAME } from '../pluginConstants';

const SignInWithAppleNativeAndroid = registerPlugin<SignInWithAppleNativeAndroidPlugin>(
    CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_ANDROID_PLUGIN_NAME,
);

export * from './definitions';
export { SignInWithAppleNativeAndroid };
