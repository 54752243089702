// Lib
import { EditorState, RichUtils } from 'draft-js';

// Utils
import { handleCodeBlockCommand } from '../../plugins/codeEditor/utils/handleCodeBlockCommand';

// Constants
import { EditorStyleTypes, STYLE_COMMAND_TYPES, STYLE_COMMANDS } from '../../richText/richTextConstants';

/**
 * Quotes and code blocks work better when multiple blocks are merged into a single block.
 */
const shouldMergeMultipleBlocksIntoOne = (styleCommand) => styleCommand === STYLE_COMMANDS.CODE_BLOCK;

export const handleStyleCommand = (state, action) => {
    let editorState = state;

    const { selection, styleCommand } = action;

    if (shouldMergeMultipleBlocksIntoOne(styleCommand)) return handleCodeBlockCommand(state, styleCommand);

    const styleType = STYLE_COMMAND_TYPES[styleCommand];

    const toggleFn = styleType === EditorStyleTypes.INLINE ? RichUtils.toggleInlineStyle : RichUtils.toggleBlockType;

    if (selection) {
        editorState = EditorState.forceSelection(editorState, selection);
    }

    return toggleFn(editorState, styleCommand);
};
