import getStroke from 'perfect-freehand';

import { getConnectedGroups } from '../../../../common/dataStructures/graphUtils';
import { isHexColorFormat } from '../../../../common/colors/colorSpaceUtil';
import * as rectLib from '../../../../common/maths/geometry/rect';
import { translateFreehandPoint } from './perfectFreehandUtils';

import { SKETCH_LINE_COLORS, COLORS } from '../../../../common/colors/colorConstants';

// Debug
import './drawingGlobalState';

export const round = (number) => Math.round(number);
export const round2 = (number) => Math.round(number * 100) / 100;

export const getStrokeColor = (color) => {
    if (isHexColorFormat(color)) return color;

    switch (color) {
        case COLORS.GREY.name:
            return '#bbbec3';
        case COLORS.CYAN.name:
            return '#62dbc8';
        case COLORS.GREEN.name:
            return '#7cd651';
        case COLORS.BROWN.name:
            return '#d58558';
        case COLORS.YELLOW.name:
            return '#ffd14d';
        case COLORS.ORANGE.name:
            return '#ff8d48';
        case COLORS.RED.name:
            return '#ff5757';
        case COLORS.PINK.name:
            return '#ff6ed4';
        case COLORS.PURPLE.name:
            return '#ad6fff';
        case COLORS.BLUE.name:
            return '#4ebafd';
        case COLORS.DEEP_BLUE.name:
            return '#5882f8';
        case SKETCH_LINE_COLORS.WHITE.name:
            return '#ffffff';
        case COLORS.NONE:
        default:
            return undefined;
    }
};

/**
 * Annotations must always save the stroke colour, because they can get saved downloaded as an SVG.
 */
export const getStrokeColorWithFallback = (color) => getStrokeColor(color) || '#323b4a';

// turns input points & size into a perfect-freehand list of stroke points
export const getPathStroke = (args, forceOutline = false) => {
    const {
        points,
        size,
        hasPressure = false,
        thinning = global.drawing.thinning,
        smoothing = global.drawing.smoothing,
        streamline = global.drawing.streamline,
        startTaper = global.drawing.startTaper,
        endTaper = global.drawing.endTaper,
        last = true,
    } = args;

    // If there's no thinning - don't get points on the edges of the lines, just use the
    // the actual drawn points, then rely on the stroke-width to make a nicely rendered thick line
    if (!thinning && !forceOutline) return points.map((point) => [point[0], point[1]]);

    return (
        getStroke(points, {
            size,
            // The effect of pressure - accentuate when the input device supports pressure
            thinning,
            // How much to soften the stroke edges
            smoothing,
            // How much to streamline the stroke
            streamline,
            start: {
                taper: startTaper,
            },
            end: {
                taper: endTaper,
            },
            simulatePressure: !hasPressure,
            last,
        })
            // Remove any strokes points that have NaNs to avoid SVG rendering issues
            .filter((strokePoints) => !strokePoints.some(isNaN))
    );
};

// Turn the points returned from perfect-freehand into SVG path data.
export const getSvgPathFromStroke = (stroke, close) => {
    if (!stroke.length) return '';

    if (!close && stroke.length < 2) return `M ${stroke[0][0]} ${stroke[0][1]} l 0 0`;

    const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
            if (!close && i === stroke.length - 1) {
                acc.push('L', x0, y0);
                return acc;
            }

            const [x1, y1] = arr[(i + 1) % arr.length];
            acc.push(round2(x0), round2(y0), round2((x0 + x1) / 2), round2((y0 + y1) / 2));
            return acc;
        },
        ['M', ...stroke[0], 'Q'],
    );

    if (close) d.push('Z');

    return d.join(' ');
};

export const getStrokeBoundingRect = (stroke, margin = 0) => {
    let left = Infinity;
    let top = Infinity;
    let right = 0;
    let bottom = 0;

    stroke.forEach(([pointX, pointY]) => {
        left = Math.min(pointX - margin, left);
        top = Math.min(pointY - margin, top);

        right = Math.max(pointX + margin, right);
        bottom = Math.max(pointY + margin, bottom);
    });

    return {
        top,
        right,
        bottom,
        left,
        width: right - left,
        height: bottom - top,
    };
};

const getPathBoundingRectMargin = (path) => {
    const { thinning, size } = path;
    return thinning === 0 ? size / 2 : 0;
};

export const getPathBoundingRect = (path) => {
    const margin = getPathBoundingRectMargin(path);
    const stroke = getPathStroke(path);
    return getStrokeBoundingRect(stroke, margin);
};

export const getPathGroups = ({ paths, gridSize }) => {
    if (!paths || !paths.length) return [];

    const padding = 2 * gridSize;

    const boundingRectMap = paths.reduce((acc, path) => {
        const { id } = path;

        const stroke = getPathStroke(path);
        const margin = getPathBoundingRectMargin(path);

        let rect = getStrokeBoundingRect(stroke, margin);
        rect = rectLib.addPadding(padding, padding, rect);

        acc[id] = rect;

        return acc;
    }, {});

    const overlapGraph = rectLib.getRectOverlapGraph(boundingRectMap);
    return getConnectedGroups(overlapGraph);
};

export const shiftPaths = (offset, paths, pathIdsToShiftMap) =>
    paths.map((path) => {
        if (pathIdsToShiftMap && !pathIdsToShiftMap[path.id]) return path;

        const points = path.points.map(translateFreehandPoint(offset));
        const stroke = getPathStroke({ ...path, points });

        return {
            ...path,
            points,
            stroke,
        };
    });

export const shiftPathsToTop = (offset, paths, pathIdsToShiftMap) => [
    ...paths.filter((path) => pathIdsToShiftMap && !pathIdsToShiftMap[path.id]),
    ...paths
        .filter((path) => !pathIdsToShiftMap || pathIdsToShiftMap[path.id])
        .map((path) => {
            const points = path.points.map(translateFreehandPoint(offset));
            const stroke = getPathStroke({ ...path, points });

            return {
                ...path,
                points,
                stroke,
            };
        }),
];

export const reorderPathsToTop = (paths, pathIdsToShiftMap = {}) => [
    ...paths.filter((path) => !pathIdsToShiftMap[path.id]),
    ...paths.filter((path) => pathIdsToShiftMap[path.id]),
];

export const reorderPathsToBottom = (paths, pathIdsToShiftMap = {}) => [
    ...paths.filter((path) => pathIdsToShiftMap[path.id]),
    ...paths.filter((path) => !pathIdsToShiftMap[path.id]),
];
