// Lib
import { useEffect, useRef, useState } from 'react';
import { get } from 'lodash/fp';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformIpad } from '../../../platform/utils/platformDetailsUtils';
import { preventDefaultOnly } from '../../../utils/domUtil';
import { getTimestamp } from '../../../../common/utils/timeUtil';

export default ({ svgRef, undoPath, redoPath }) => {
    const [isPenMode, setIsPenMode] = useState(false);
    const lastTapEndTimeRef = useRef(0);
    const lastTapTouchCount = useRef(0);
    const clearTimeoutRef = useRef();
    const undoPathRef = useRef(undoPath);
    const redoPathRef = useRef(redoPath);

    useEffect(() => {
        if (!isPlatformIpad(platformSingleton)) return;

        undoPathRef.current = undoPath;
        redoPathRef.current = redoPath;
    }, [undoPath, redoPath]);

    // Remove unneeded events detection
    useEffect(() => {
        if (!isPlatformIpad(platformSingleton) || !svgRef.current) return;

        const onTouchStart = (e) => {
            e.preventDefault();

            const currentTouchCount = get(['touches', 'length'], e) || 0;

            if (currentTouchCount <= 1) return;

            clearTimeout(clearTimeoutRef.current);

            if (currentTouchCount < lastTapTouchCount.current) {
                clearTimeoutRef.current = setTimeout(() => {
                    lastTapEndTimeRef.current = 0;
                    lastTapTouchCount.current = 0;
                }, 600);
                return;
            }

            const currentTimestamp = getTimestamp();

            const startNewTapDetection =
                lastTapTouchCount.current !== currentTouchCount || currentTimestamp > lastTapEndTimeRef.current + 600;

            if (startNewTapDetection) {
                lastTapEndTimeRef.current = currentTimestamp;
                lastTapTouchCount.current = currentTouchCount;
                return;
            }

            // Two fingers for undo, three for redo
            lastTapTouchCount.current === 2 ? undoPathRef.current() : redoPathRef.current();

            lastTapEndTimeRef.current = 0;
            lastTapTouchCount.current = 0;
        };

        svgRef.current.addEventListener('gesturestart', preventDefaultOnly, false);
        svgRef.current.addEventListener('gestureend', preventDefaultOnly, false);
        svgRef.current.addEventListener('gesturechange', preventDefaultOnly, false);
        svgRef.current.addEventListener('touchstart', onTouchStart, false);
        svgRef.current.addEventListener('touchmove', preventDefaultOnly, false);
        svgRef.current.addEventListener('touchend', preventDefaultOnly, false);
        svgRef.current.addEventListener('touchcancel', preventDefaultOnly, false);

        return () => {
            if (svgRef.current) {
                svgRef.current.removeEventListener('gesturestart', preventDefaultOnly, false);
                svgRef.current.removeEventListener('gestureend', preventDefaultOnly, false);
                svgRef.current.removeEventListener('gesturechange', preventDefaultOnly, false);
                svgRef.current.removeEventListener('touchstart', onTouchStart, false);
                svgRef.current.removeEventListener('touchmove', preventDefaultOnly, false);
                svgRef.current.removeEventListener('touchend', preventDefaultOnly, false);
                svgRef.current.removeEventListener('touchcancel', preventDefaultOnly, false);
            }
        };
    }, []);

    return {
        isPenMode,
        setIsPenMode,
    };
};
