// Lib
import { isEmpty, map, first, flatMap } from 'lodash/fp';
import { negate } from 'lodash';

// Services
import { fetchBoards, fetchVisibleDescendantBoards } from '../board/boardService';

// Actions
import { createMultipleElementsSync } from '../actions/elementActions';
import { deleteElement } from '../../../common/elements/elementActions';
import { increaseElementCount } from '../../user/elementCount/elementCountActions';
import { createBatchAction } from '../../store/reduxBulkingMiddleware';
import { deselectAllElements, setSelectedElements } from '../selection/selectionActions';

// Selectors
import { getElements } from '../selectors/elementsSelector';
import { elementGraphSelector } from '../selectors/elementGraphSelector';
import { getClientIdSelector, getClientTickSelector } from '../../user/clientDataSelector';

// Util
import http from '../../utils/services/http';
import { asObject, getMany, prop } from '../../../common/utils/immutableHelper';
import createElementId from '../../../common/uid/createElementId';
import { getDescendantIds } from '../../../common/dataStructures/graphUtils';
import { getBoardContentFromAlias } from '../../../common/alias/aliasUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { createDuplicateDefs } from '../../../common/elements/elementDuplicateUtil';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getTokenElementIdGroupsThunk } from '../../utils/permissions/permissionsActions';
import { getElementId, getLinkedElementId, getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import { isAlias, isBoard, isCommentThread } from '../../../common/elements/utils/elementTypeUtils';
import delay from '../../../common/utils/lib/delay';

// Constants
import { METHODS } from '../../../common/utils/http/httpConstants';
import {
    ELEMENT_INDIVIDUAL_DUPLICATION,
    ELEMENT_DUPLICATION_PROCESSING,
    ELEMENT_DUPLICATION_COMPLETE,
} from './elementDuplicateConstants';
import { ElementType } from '../../../common/elements/elementTypes';

/**
 * This is used along with the duplicationReducer to keep track of elements that need a
 * "keep in-sync with original" message.
 */
const recordIndividualElementDuplication = (originalElementId, duplicateElementId) => ({
    type: ELEMENT_INDIVIDUAL_DUPLICATION,
    originalElementId,
    duplicateElementId,
});

export const setDuplicateLoading = (elementIds) => ({
    type: ELEMENT_DUPLICATION_PROCESSING,
    elementIds,
    loading: true,
    sync: true,
});

export const setDuplicateLoadingComplete = (elementIds) => ({
    type: ELEMENT_DUPLICATION_COMPLETE,
    elementIds,
    loading: false,
    sync: true,
});

const shouldDuplicateOnServer = (el) => isBoard(el) || isCommentThread(el);

const getCreateDuplicateIdFn = (state) => (duplicationIndex) => {
    const clientId = getClientIdSelector(state);
    const clientTick = getClientTickSelector(state) + duplicationIndex + 1;
    return createElementId(clientId, clientTick);
};

const duplicateDescendantsIntoContainerElementBatch =
    (duplications, tokens, transactionId, deleteOnError) => async (dispatch) => {
        try {
            const {
                data: { elementCount },
            } = await http({
                url: `elements/duplicate`,
                method: METHODS.POST,
                data: {
                    duplications,
                    tokens,
                },
                timeout: 60000,
            });

            dispatch(increaseElementCount(elementCount, transactionId));

            const elementCountSum = Object.values(elementCount).reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0,
            );

            /**
        Adding a delay here because the scenario that we have to handle is that when duplicating large boards, there is an API call to fetch the
        duplicate elements of a board so in the use case that a user duplicates a large board and immediately clicks on the duplicating board
        we are also doing a API call the fetch the new board of the elements. So, we need to add an artificial delay on between the duplicating and
        fetching so that mongodb gets time to do the READ after WRITE and the user knows that the board is still duplicating.
        */

            await delay(elementCountSum * 0.25);

            const boardIdsToReFetch = duplications
                .filter(({ elementType }) => isBoard(elementType))
                .map(({ newElementId }) => newElementId);

            await dispatch(
                fetchBoards({
                    boardIds: boardIdsToReFetch,
                    force: true,
                    excludeSelf: true,
                    loadAncestors: false,
                }),
            );

            // This is only necessary if the element being duplicated is a column
            // because it might have other visible container elements that need to be fetched
            const newColumnIds = duplications
                .filter(({ elementType }) => !isBoard(elementType))
                .map(({ newElementId }) => newElementId);

            if (!isEmpty(newColumnIds)) {
                for (const newColumnId of newColumnIds) {
                    dispatch(fetchVisibleDescendantBoards(newColumnId));
                }
            }
        } catch (err) {
            if (!err.response) return console.error(err);

            const { data } = err.response;

            if (deleteOnError) {
                const deletionActions = duplications.map((duplication) =>
                    deleteElement({
                        id: duplication.newElementId,
                        location: duplication.newElementLocation,
                        elementType: duplication.elementType,
                        transactionId,
                    }),
                );
                dispatch(createBatchAction({ actions: deletionActions, transactionId }));
            }

            alert(data.error && data.error.message); // eslint-disable-line no-alert
        }
    };

export const duplicateDescendantsIntoContainerElements =
    (duplications, transactionId, deleteOnError = true, showLoadingState = true) =>
    async (dispatch) => {
        const elementIdToDuplicationMap = duplications.reduce((acc, duplication) => {
            acc[duplication.originalElementId] = duplication;
            return acc;
        }, {});

        const newElementIds = duplications.map(({ newElementId }) => newElementId);

        if (showLoadingState) dispatch(setDuplicateLoading(newElementIds));

        const tokenElementIds = duplications.map(({ originalElementId }) => originalElementId);
        const tokenGroups = await dispatch(getTokenElementIdGroupsThunk({ elementIds: tokenElementIds }));

        try {
            for (const { token, elementIds } of tokenGroups) {
                const groupDuplications = elementIds.map((elementId) => elementIdToDuplicationMap[elementId]);
                await dispatch(
                    duplicateDescendantsIntoContainerElementBatch(
                        groupDuplications,
                        token,
                        transactionId,
                        deleteOnError,
                    ),
                );
            }
        } finally {
            if (showLoadingState) dispatch(setDuplicateLoadingComplete(newElementIds));
        }
    };

const toElement = (state) => (duplication) => {
    const copyElement = state.getIn(['elements', duplication.id]);
    if (!copyElement) return null;
    return { ...copyElement.toJS(), location: duplication.location };
};

/**
 * Duplicates visible elements within a single batched action and then
 * duplicates children of boards via server requests.
 */
const duplicateElementsBatched = (duplicateDefs, transactionId) => (dispatch, getState) => {
    dispatch(
        createMultipleElementsSync({
            // Need to map the _id property to "id"
            elements: duplicateDefs.map((def) => ({ ...def, id: def._id })),
            transactionId,
        }),
    );

    const updatedState = getState();
    const elementMap = getElements(updatedState);

    const duplications = duplicateDefs
        // don't continue if element creation wasn't successful
        .filter((elementDef) => getElement(elementMap, elementDef._id) && shouldDuplicateOnServer(elementDef))
        .map((elementDef) => ({
            originalElementId: elementDef.id,
            newElementId: elementDef._id,
            newElementLocation: elementDef.location,
            elementType: elementDef.elementType,
        }));
    if (isEmpty(duplications)) return;

    return dispatch(duplicateDescendantsIntoContainerElements(duplications, transactionId));
};

const prepareAliasForDuplicationAsBoard = (elementMap, shouldConvertAliasToBoard) => (element) => {
    if (!shouldConvertAliasToBoard || !isAlias(element)) return element;

    const linkedBoardId = getLinkedElementId(element);
    const linkedBoard = getElement(elementMap, linkedBoardId);

    return {
        ...element,
        id: getPhysicalId(element),
        elementType: ElementType.BOARD_TYPE,
        content: getBoardContentFromAlias(element, linkedBoard),
    };
};

/**
 * Gets all the descendants of elementsToDuplicate that needs to be duplicated.
 * This includes container elements that will be duplicated on the server (boards, comment threads, etc.)
 * and ALL the elements that will be duplicated on the client side,
 */
const getElementDescendantsToDuplicate = (state, { elementsToDuplicate }) => {
    const elements = getElements(state);
    const elementGraph = elementGraphSelector(state);

    const elementsToDescend = elementsToDuplicate.filter(negate(shouldDuplicateOnServer));
    const elementIdsToDescend = elementsToDescend.map(getElementId);

    const descendantIdsToDuplicate = flatMap(
        (parentId) =>
            getDescendantIds(elementGraph, parentId, {
                traversalPredicateFn: (elementId) => !shouldDuplicateOnServer(prop(elementId, elements)),
            }),
        elementIdsToDescend,
    );

    const descendantsToDuplicate = getMany(descendantIdsToDuplicate, elements);

    return asObject(descendantsToDuplicate.valueSeq());
};

export const duplicateMultipleElements =
    ({ moves, duplications, shouldSelect, shouldConvertAliasToBoard, transactionId = getNewTransactionId() }) =>
    async (dispatch, getState) => {
        const state = getState();

        // Create elements object from duplication which is a snapshot of the original
        // object with updated location
        let elements = duplications || moves.map(toElement(state));

        const elementsDescendantsToDuplicate = getElementDescendantsToDuplicate(state, {
            elementsToDuplicate: elements,
        });

        elements = [...elements, ...elementsDescendantsToDuplicate];

        const elementMap = getElements(state);

        const resolvedElements = elements.map(prepareAliasForDuplicationAsBoard(elementMap, shouldConvertAliasToBoard));

        const createDuplicateIdFn = getCreateDuplicateIdFn(state);
        const duplicateDefs = createDuplicateDefs({ elements: resolvedElements, createDuplicateIdFn, transactionId });

        if (shouldSelect) dispatch(deselectAllElements({ transactionId }));

        await dispatch(duplicateElementsBatched(duplicateDefs, transactionId));

        if (shouldSelect) dispatch(setSelectedElements({ ids: map('_id', duplicateDefs), transactionId }));

        if (duplicateDefs.length !== 1 || elements.length !== 1) return;

        const originalElementId = getElementId(first(elements));
        const duplicateElementId = getElementId(first(duplicateDefs));

        dispatch(recordIndividualElementDuplication(originalElementId, duplicateElementId));
    };
