import { useEffect } from 'react';
import { Extension, Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view';

const NAME = 'virtualSelection';

function clearVirtualSelection(view: EditorView) {
    view.dispatch(view.state.tr.setMeta(NAME, ''));
}

export const usePreservedSelection = (editor: Editor, isSingleSelected: boolean) => {
    useEffect(() => {
        // When the card loses focus, we want to preserve the selection, but when
        // the card _itself_ gets deselected (not just unfocused), we want to clear it.
        if (!editor || isSingleSelected) return;
        clearVirtualSelection(editor.view);
    }, [editor, isSingleSelected]);
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        virtualSelection: {
            /**
             * Command to apply a class to the current selection, that will be cleared
             * next time the editor is focused.
             */
            applyVirtualSelection: (highlightClass: string) => ReturnType;
        };
    }
}

const createPlugin = () =>
    new Plugin({
        key: new PluginKey(NAME),
        state: {
            init: () => DecorationSet.empty,

            apply: (transaction, oldState) => {
                const { selection, doc } = transaction;
                const highlightClass = transaction.getMeta(NAME);

                // When the provided class is empty, clear all decorations (this happens on blur)
                if (highlightClass === '') return DecorationSet.empty;

                // If the class is otherwise falsy, the transaction isn't related to this plugin
                if (!highlightClass) return oldState;

                // Grab the already-applied decorations' classes
                const classes = [...oldState.find().map((deco: Decoration) => deco.spec.class), highlightClass];
                const className = classes.join(' ');

                // Apply the decoration to the selection
                return DecorationSet.create(doc, [
                    Decoration.inline(
                        selection.from,
                        selection.to,
                        // add the data to the decoration's attrs (to actually style things)
                        { class: className },
                        // add the same data to the spec (for reading in subsequent transactions)
                        { class: className },
                    ),
                ]);
            },
        },

        props: {
            decorations(state) {
                return this.getState(state);
            },
            handleDOMEvents: {
                // setMeta isn't really modifying any document metadata here, we're just using it
                // to trigger a custom transaction that we respond to in `apply` above.

                blur: (view) => {
                    const { tr } = view.state;

                    // blur occurred with no selection; do nothing
                    if (tr.selection.from === tr.selection.to) return;

                    view.dispatch(tr.setMeta(NAME, 'virtual-selection'));
                },

                focus: clearVirtualSelection,
            },
        },
    });

export const VirtualSelection = Extension.create({
    name: NAME,

    addCommands() {
        return {
            applyVirtualSelection:
                (highlightClass: string) =>
                ({ tr, dispatch }) => {
                    if (!dispatch) return true;

                    dispatch(tr.setMeta(NAME, highlightClass));

                    return true;
                },
        };
    },

    addProseMirrorPlugins() {
        return [createPlugin()];
    },
});
