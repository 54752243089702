// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity, constant, negate } from 'lodash';
import { createStructuredSelector } from 'reselect';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import getGridSize from '../../utils/grid/gridSizeSelector';
import {
    getElementId,
    getImageProp,
    getLinkUrl,
    getMediaOriginalWidth,
    getMediaType,
    getMediaWidth,
    getShowCaption,
} from '../../../common/elements/utils/elementPropertyUtils';
import fieldValidationService from '../../../common/validation/fieldValidationService';

// Selectors
import { getElementLocalData } from '../local/elementLocalDataSelector';

// Drop target
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';

// Decorators
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import elementMediaResizeDecorator from '../resizing/elementSaveMediaResizeDecorator';
import elementSaveAspectRatioResizeDecorator from '../resizing/elementSaveAspectRatioResizeDecorator';

// Actions
import { setLinkElementUrl } from './linkActions';
import { moveElementsToTrash } from '../actions/elementShortcutActions';
import { acceptElementAttachmentUndo, uploadAttachment } from '../attachments/attachmentActions';
import { switchConnectedElementParents } from '../connections/elementConnectionsActions';
import { setElementLocalData } from '../local/elementLocalDataActions';

// Components
import Link from './Link';
import ImageColorPaletteObserver from '../../components/images/imageColorExtraction/ImageColorPaletteObserver';

// Constants
import { FORCED_ASPECT_RATIO_MEDIA, RICH_MEDIA_MIN_HEIGHT } from '../../../common/links/richMediaConstants';
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

const mapStateToProps = createStructuredSelector({
    gridSize: getGridSize,
    elementLocalData: getElementLocalData,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetLinkElementUrl: (id, url) => dispatch(setLinkElementUrl({ id, url })),
    dispatchMoveElementsToTrash: ({ currentBoardId, elementIds }) =>
        dispatch(moveElementsToTrash({ currentBoardId, elementIds })),
    dispatchUploadFile: (elementId, file, options, transactionId) =>
        dispatch(uploadAttachment({ id: elementId, transactionId, file, options })),
    dispatchSwitchConnectedElementParents: (args) => dispatch(switchConnectedElementParents(args)),
    dispatchAcceptElementAttachmentUndo: (args) => dispatch(acceptElementAttachmentUndo(args)),
    dispatchSetLocalData: (args) => dispatch(setElementLocalData(args)),
});

const getDefaultMaxWidth = ({ element }) => getMediaOriginalWidth(element) || getMediaWidth(element);
const shouldMaintainAspectRatio = ({ element }) => {
    const mediaType = getMediaType(element);
    return FORCED_ASPECT_RATIO_MEDIA.indexOf(mediaType) !== -1 || (!mediaType && !!getImageProp(element));
};

@connect(mapStateToProps, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys'])
@captionContainerDecorator
@elementResizeDecorator({ getMinWidth: constant(ELEMENT_DEFAULT_WIDTH) })
@elementSaveAspectRatioResizeDecorator({
    getMinWidth: constant(ELEMENT_DEFAULT_WIDTH),
    getIsEnabled: shouldMaintainAspectRatio,
    getDefaultMaxWidth,
})
@elementMediaResizeDecorator({
    getMinHeight: constant(RICH_MEDIA_MIN_HEIGHT),
    getMinWidth: constant(ELEMENT_DEFAULT_WIDTH),
    getDefaultMaxWidth,
    getIsEnabled: negate(shouldMaintainAspectRatio),
})
class LinkContainer extends React.Component {
    componentDidUpdate({ element: oldElement }) {
        // focus on mount
        const { element, isSelected } = this.props;

        if (!isSelected) return;

        const showCaption = getShowCaption(element);
        const oldShowCaption = getShowCaption(oldElement);

        if (!showCaption && oldShowCaption) {
            this.container.focus();
        }
    }

    setLocalData = ({ url, error }) => {
        const { dispatchSetLocalData, element } = this.props;

        dispatchSetLocalData({
            id: getElementId(element),
            data: { url, error },
        });
    };

    setLinkUrl = (url) => {
        const { dispatchSetLinkElementUrl, element } = this.props;

        const validationError = fieldValidationService(url, { type: 'url', allowIPAddress: true, required: true });

        if (validationError) return validationError;
        dispatchSetLinkElementUrl(getElementId(element), url);
    };

    moveElementsToTrash = () => {
        const { currentBoardId, element, dispatchMoveElementsToTrash } = this.props;
        dispatchMoveElementsToTrash({ currentBoardId, elementIds: [getElementId(element)] });
    };

    handleKey = (event) => {
        if (!this.props.isSelected) return;
        if (event.target !== this.container) return;
        if (!getLinkUrl(this.props.element)) return;
        this.props.captionKeyHandler(event, this.props);
    };

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div>
                <div
                    className="LinkContainer"
                    ref={(c) => {
                        this.container = c;
                    }}
                    onKeyDown={this.handleKey}
                    tabIndex="-1"
                >
                    <ImageColorPaletteObserver element={this.props.element} />
                    <Link
                        {...this.props}
                        setLinkUrl={this.setLinkUrl}
                        setLocalData={this.setLocalData}
                        moveElementsToTrash={this.moveElementsToTrash}
                    />
                </div>
            </div>,
        );
    }
}

LinkContainer.propTypes = {
    element: PropTypes.object.isRequired,
    elementLocalData: PropTypes.object,
    attachment: PropTypes.object,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    currentBoardId: PropTypes.string,
    isSelected: PropTypes.bool,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    dispatchShowCaption: PropTypes.func,
    dispatchSetLinkElementUrl: PropTypes.func,
    dispatchSetLocalData: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    inList: PropTypes.string,
    captionKeyHandler: PropTypes.func,
    canConnectLineEdge: PropTypes.bool,
};

export default LinkContainer;
