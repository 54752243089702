// Lib
import { SelectionState } from 'draft-js';

export default (contentState) => {
    const lastBlock = contentState.getLastBlock();
    const blockKey = lastBlock.getKey();
    const endIndex = lastBlock.getLength();

    const selection = SelectionState.createEmpty(blockKey);
    return selection.merge({
        anchorKey: blockKey,
        anchorOffset: endIndex,
        focusKey: blockKey,
        focusOffset: endIndex,
        isBackward: false,
        hasFocus: true,
    });
};
