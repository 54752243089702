import { Dispatch, Reducer, useEffect, useReducer } from 'react';

import localStorage from './localStorage';

const useLocalStorageReducer = <S, A>(
    key: string,
    reducer: Reducer<S, A>,
    initialState: S,
    transformFn = JSON.parse,
): [S, Dispatch<A>] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, dispatch] = useReducer(reducer, initialState, (initialState) => {
        try {
            // Get from local storage by key
            const item = localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? transformFn(item) : initialState;
        } catch (error) {
            return initialState;
        }
    });

    useEffect(() => {
        try {
            // Save state
            localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    }, [key, storedValue]);

    return [storedValue, dispatch] as const;
};

export default useLocalStorageReducer;
