import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '../../../node_module_clones/react-contextmenu';

// Components
import ShortcutKeyGroup from '../shortcuts/ShortcutKeyGroup';

const ContextMenuItem = ({ children, className, title, shortcut, show, onClick, onMouseUp, registerMenuItem, id }) => {
    if (!show) return null;

    const component = children || <span>{title}</span>;

    return (
        <div
            ref={(c) => registerMenuItem(id, c)}
            className={className}
            onContextMenu={onClick}
            onMouseUp={onMouseUp && onMouseUp(id)}
        >
            <MenuItem onClick={onClick}>
                {component}
                <ShortcutKeyGroup shortcut={shortcut} size="small" />
            </MenuItem>
        </div>
    );
};

ContextMenuItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    show: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseUp: PropTypes.func,
    registerMenuItem: PropTypes.func,
    shortcut: PropTypes.array,
};

export default ContextMenuItem;
