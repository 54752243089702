// Constants
import { EditorContextName } from './editorContextConstants';

// Types
import { EditorContextState, MarkdownEditorContextStateEntry } from './editorContextTypes';

export const getEditorContext = (state: Immutable.Map<string, object>): EditorContextState =>
    state.get('editorContext') as EditorContextState;

export function getEditorContextTypeState(
    state: Immutable.Map<string, object>,
    { editorContextName }: { editorContextName: EditorContextName.markdown },
): Record<string, MarkdownEditorContextStateEntry> | null;

export function getEditorContextTypeState(
    state: Immutable.Map<string, object>,
    { editorContextName }: { editorContextName: EditorContextName },
): unknown {
    const editorContext = getEditorContext(state);
    return editorContext[editorContextName];
}
