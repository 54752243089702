import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardPasswordProtectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon board-password-protected"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#333" />
            <path
                fill="#FFF"
                d="M8 3.2a3 3 0 0 1 3 3V7h.2a.8.8 0 0 1 .8.8V11a.8.8 0 0 1-.8.8H4.8A.8.8 0 0 1 4 11V7.8a.8.8 0 0 1 .8-.8H5v-.8a3 3 0 0 1 3-3zm0 1.2a1.8 1.8 0 0 0-1.8 1.8V7h3.6v-.8A1.8 1.8 0 0 0 8 4.4z"
            />
        </g>
    </svg>
);
const Memo = memo(BoardPasswordProtectedIcon);
export default Memo;
