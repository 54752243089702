// Utils
import { noLonger, now, stayedTrue, hasChanged } from '../../../utils/react/propsComparisons';

// Property comparison functions
export const noLongerEditingElement = noLonger('isEditing');
export const nowEditingElement = now('isEditing');
export const stayedEditingElement = stayedTrue('isEditing');
export const textContentHasChanged = hasChanged('textContent');
export const editorStateHasChanged = hasChanged('editorState');
export const savedSelectionHasChanged = hasChanged('savedSelection');
export const filterQueryHasChanged = hasChanged('filterQuery');

const currentEditorIdEqualsThisId = (props) => props.currentEditorId === props.editorId;
const currentEditorIdDoesNotEqualThisId = (props) => props.currentEditorId !== props.editorId;
const currentEditorIdNoLongerEqualsThisId = (oldProps, newProps) =>
    currentEditorIdEqualsThisId(oldProps) && currentEditorIdDoesNotEqualThisId(newProps);
const currentEditorIdNowEqualsThisId = (oldProps, newProps) =>
    currentEditorIdEqualsThisId(newProps) && currentEditorIdDoesNotEqualThisId(oldProps);

export const noLongerEditingThisEditor = (oldProps, newProps) =>
    (noLongerEditingElement(oldProps, newProps) && currentEditorIdEqualsThisId(oldProps)) ||
    (oldProps.isEditing && currentEditorIdNoLongerEqualsThisId(oldProps, newProps));

export const startedEditingThisElement = (oldProps, newProps) =>
    (nowEditingElement(oldProps, newProps) && currentEditorIdEqualsThisId(newProps)) ||
    (newProps.isEditing && currentEditorIdNowEqualsThisId(oldProps, newProps));

export const currentlyEditingThisEditor = (currentProps) =>
    currentProps.isEditing && currentEditorIdEqualsThisId(currentProps);
