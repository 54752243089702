// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from '../../../../../../../node_module_clones/recompose';
import { createStructuredSelector } from 'reselect';
import { negate } from 'lodash/fp';

// Actions
import { moveMultipleElements } from '../../../../../../element/actions/elementMoveActions';
import { forceElementsMeasurements } from '../../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsActions';

// Selectors
import { getCurrentBoard } from '../../../../../../element/selectors/currentBoardSelector';
import { getMeasurementsMapThunk } from '../../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Components
import ArrangementPopup from '../ArrangementPopup';
import Button from '../../../../../../components/buttons/Button';
import Icon from '../../../../../../components/icons/Icon';
import TooltipSource from '../../../../../../components/tooltips/TooltipSource';

// Utils
import { getElementAlignmentMoves } from './alignmentUtils';
import { toArray } from '../../../../../../../common/utils/immutableHelper';
import { getElementId, isElementLocked } from '../../../../../../../common/elements/utils/elementPropertyUtils';
import measurementsConnect from '../../../../../../components/measurementsStore/measurementsConnect';

// Constants
import { ALIGNMENT_SIDE } from './alignmentConstants';
import { TooltipPositions } from '../../../../../../components/tooltips/tooltipConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../../../../../common/elements/elementConstants';
import { PopupIds } from '../../../../../../components/popupPanel/popupConstants';

// Styles
import './AlignmentPopup.scss';

const mapStateToProps = createStructuredSelector({
    currentBoard: getCurrentBoard,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchMoveMultipleElements: (moves) =>
        dispatch(
            moveMultipleElements({
                moves,
                moveOperation: ELEMENT_MOVE_OPERATIONS.ALIGN,
            }),
        ),
    dispatchForceElementsMeasurements: (elementIds) => dispatch(forceElementsMeasurements(elementIds)),
});

const mapMeasurementsDispatchToProps = (dispatch) => ({
    dispatchGetMeasurementsMap: () => dispatch(getMeasurementsMapThunk()),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    measurementsConnect(null, mapMeasurementsDispatchToProps),
    withHandlers({
        alignElements:
            ({
                gridSize,
                selectedElements,
                dispatchGetMeasurementsMap,
                dispatchMoveMultipleElements,
                dispatchForceElementsMeasurements,
                closePopup,
                currentBoard,
            }) =>
            (alignSide) =>
            async () => {
                const unlockedSelectedElements = selectedElements.filter(negate(isElementLocked));
                const elementIds = toArray(unlockedSelectedElements.map((el) => getElementId(el)));
                dispatchForceElementsMeasurements(elementIds);

                const measurements = dispatchGetMeasurementsMap();

                const moves = getElementAlignmentMoves({
                    elements: unlockedSelectedElements,
                    currentBoard,
                    measurements,
                    gridSize,
                    alignSide,
                });

                dispatchMoveMultipleElements(moves);
                closePopup();
            },
    }),
);

const AlignmentPopup = (props) => {
    const { alignElements } = props;
    return (
        <ArrangementPopup popupId={PopupIds.ALIGNMENT} className="AlignmentPopup" buttonSelector=".AlignmentTool .icon">
            <Button onClickFn={alignElements(ALIGNMENT_SIDE.LEFT)}>
                <TooltipSource
                    enabled
                    tooltipText="Align left"
                    position={TooltipPositions.RIGHT}
                    pollPosition
                    delay={700}
                    triggerOnMouseEnter
                >
                    <Icon name="align-left" />
                </TooltipSource>
            </Button>
            <Button onClickFn={alignElements(ALIGNMENT_SIDE.CENTER_HORIZ)}>
                <TooltipSource
                    enabled
                    tooltipText="Align horizontal centers"
                    position={TooltipPositions.RIGHT}
                    pollPosition
                    delay={700}
                    triggerOnMouseEnter
                >
                    <Icon name="align-center-horiz" />
                </TooltipSource>
            </Button>
            <Button onClickFn={alignElements(ALIGNMENT_SIDE.RIGHT)}>
                <TooltipSource
                    enabled
                    tooltipText="Align right"
                    position={TooltipPositions.RIGHT}
                    pollPosition
                    delay={700}
                    triggerOnMouseEnter
                >
                    <Icon name="align-right" />
                </TooltipSource>
            </Button>
            <Button onClickFn={alignElements(ALIGNMENT_SIDE.TOP)}>
                <TooltipSource
                    enabled
                    tooltipText="Align top"
                    position={TooltipPositions.RIGHT}
                    pollPosition
                    delay={700}
                    triggerOnMouseEnter
                >
                    <Icon name="align-top" />
                </TooltipSource>
            </Button>
            <Button onClickFn={alignElements(ALIGNMENT_SIDE.CENTER_VERT)}>
                <TooltipSource
                    enabled
                    tooltipText="Align vertical centers"
                    position={TooltipPositions.RIGHT}
                    pollPosition
                    delay={700}
                    triggerOnMouseEnter
                >
                    <Icon name="align-center-vert" />
                </TooltipSource>
            </Button>
            <Button onClickFn={alignElements(ALIGNMENT_SIDE.BOTTOM)}>
                <TooltipSource
                    enabled
                    tooltipText="Align bottom"
                    position={TooltipPositions.RIGHT}
                    pollPosition
                    delay={700}
                    triggerOnMouseEnter
                >
                    <Icon name="align-bottom" />
                </TooltipSource>
            </Button>
        </ArrangementPopup>
    );
};

AlignmentPopup.propTypes = {
    selectedType: PropTypes.string,
    selectedElements: PropTypes.object,
    alignElements: PropTypes.func,
    distributeHorizontally: PropTypes.func,
    distributeVertically: PropTypes.func,
    closePopup: PropTypes.func,
    dispatchForceElementsMeasurements: PropTypes.func,
    dispatchGetMeasurementsMap: PropTypes.func,
    currentBoard: PropTypes.object,
};

export default enhance(AlignmentPopup);
