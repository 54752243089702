import { FAILURE_TYPES, FAILURE_NAMES } from '../validationConstants';

/**
 * Returns an error object if the field config has a max length and the field's value is longer than the max length.
 * Otherwise returns null.
 */
export default (fieldValue, validationConfig) => {
    if (!fieldValue) return null;

    // If there's no maxlength validation, or the field's length is less than the max length, don't return an error
    if (!validationConfig.maxLength || validationConfig.maxLength >= fieldValue.length) return null;

    return {
        name: FAILURE_NAMES.maxlength,
        type: FAILURE_TYPES.ERROR,
        debounced: true,
        message: `Must be less than ${validationConfig.maxLength} characters`,
    };
};
