export const RICH_MEDIA_RETURN_TO_PREVIEW = 'RICH_MEDIA_RETURN_TO_PREVIEW';
export const RICH_MEDIA_EMBEDDING = 'RICH_MEDIA_EMBEDDING';
export const RICH_MEDIA_READY = 'RICH_MEDIA_READY';
export const RICH_MEDIA_ERROR = 'RICH_MEDIA_ERROR';

export const RICH_MEDIA_STATE = {
    PREVIEW: 'PREVIEW',
    EMBEDDING: 'EMBEDDING',
    READY: 'READY',
    ERROR: 'ERROR',
};
