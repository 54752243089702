import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLoopIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-loop"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#48505E"
                d="M23.75 14.5a.75.75 0 0 1 .743.648l.007.102v2a5.75 5.75 0 0 1-5.53 5.746l-.22.004H10.5v1.75l-3-2.5 3-2.5v1.75h8.25a4.25 4.25 0 0 0 4.245-4.044L23 17.25v-2a.75.75 0 0 1 .75-.75zM21.5 7.25l3 2.5-3 2.5V10.5h-7.75a4.25 4.25 0 0 0-4.245 4.044l-.005.206v2a.75.75 0 0 1-1.493.102L8 16.75v-2a5.75 5.75 0 0 1 5.53-5.746L13.75 9h7.75V7.25z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarLoopIcon);
export default Memo;
