// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import List from './List';

/**
 * A LazyList only renders when it actually has children.
 * This is useful for elements such as Tasks, which usually don't have children and their
 * List element doesn't take up any visual space in this scenario, so we can avoid the performance
 * hit of adding the list element in this case.
 */
const LazyList = (props) => {
    const { childElementIds } = props;

    if (!childElementIds || childElementIds.length === 0) return null;

    return <List {...props} />;
};

LazyList.propTypes = {
    childElementIds: PropTypes.array,
};

export default LazyList;
