import { TiptapContentMark, TiptapContentNode } from '../../../tiptapTypes';

type MarkToNodeConverter = (content: TiptapContentNode, mark: TiptapContentMark) => TiptapContentNode;

/**
 * Promote a mark to a node by converting the mark to a node of the same type, and moving the
 * mark's attrs to the new node.
 *
 * Eg:
 * {
 *   type: 'text',
 *   text: '@McLean',
 *   marks: [
 *     { type: 'bold' },
 *     { type: 'mention', attrs: { id: '12345' } },  // <-- promotable
 *   ],
 * }
 *
 * becomes
 * {
 *   type: 'mention',  // <-- promoted
 *   text: '@McLean',
 *   attrs: { id: '12345' },
 *   marks: [ { type: 'bold' } ],
 * }
 *
 */
const promoteMark: MarkToNodeConverter = (content, mark) => {
    const otherMarks = content.marks?.filter((x) => x !== mark);
    const { attrs, ...rest } = content;
    return {
        ...rest,
        type: mark.type,
        attrs: { ...attrs, ...mark.attrs },
        marks: otherMarks,
    };
};

const markToNodeConverters: Record<string, MarkToNodeConverter> = {
    mention: (content, mark) => {
        const { text, ...rest } = promoteMark(content, mark);
        return rest;
    },
};

/**
 * Finds any marks in the content that should actually be nodes (anything with a corresponding
 * entry in the converter map above), and promote them.
 */
export const promoteMarksToNodes = (content: TiptapContentNode): TiptapContentNode => {
    // check if this content contains a mark that should actually be its node type
    // (this should only ever be 0 or 1 mark, and any such mark should not have
    // child nodes, so we won't need to recurse down the object)
    const promotableMark = content.marks?.find((x) => x.type in markToNodeConverters);
    if (promotableMark) {
        const converter = markToNodeConverters[promotableMark.type];
        return converter(content, promotableMark);
    }

    // otherwise continue recursing down the object, converting any marks downstream
    return Object.fromEntries(
        Object.entries(content).map(([k, v]) => {
            if (!v) return [k, v];
            if (Array.isArray(v)) return [k, v.map(promoteMarksToNodes)];
            if (typeof v === 'object') return [k, promoteMarksToNodes(v)];
            return [k, v];
        }),
    );
};
