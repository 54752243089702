// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import DrawingPath from './DrawingPath';

const OutlinedDrawingPath = ({ canvasScale = 1, ...rest }) => (
    <g>
        <DrawingPath
            {...rest}
            className={classNames('outline', rest.className)}
            size={rest.size + Math.round(2 / canvasScale)}
        />
        <DrawingPath {...rest} />
    </g>
);

OutlinedDrawingPath.propTypes = {
    canvasScale: PropTypes.number,
    size: PropTypes.number,
    className: PropTypes.string,
};

export default OutlinedDrawingPath;
