// Lib
import { createSelector } from 'reselect';

// TODO: Rename this file to "popupSelector.js", don't export the popupOpen as default

export const popupSelector = (expectedPopupId) => (state) => state.getIn(['app', 'popup', expectedPopupId]);

export const activePopupSelector = (state) => state.getIn(['app', 'popup', 'active']);
export const isAnyPopupActiveSelector = (state) => state.getIn(['app', 'popup', 'active']).size !== 0;

export const popupOpenSelector = (expectedPopupId) =>
    createSelector(activePopupSelector, (activePopup) => activePopup.has(expectedPopupId));

/**
 * Selector for checking if a popup is open, based on a popupId defined in the component's props
 */
export const isPopupIdOpenSelector = createSelector(
    (_state, ownProps) => ownProps.popupId,
    activePopupSelector,
    (popupId, activePopup) => activePopup.has(popupId),
);

export default popupOpenSelector;
