// Lib
import { AnyAction } from 'redux';

// Constants
import { ELEMENT_SET_LOCAL_DATA_MULTI } from '../../../common/elements/elementConstants';

// Types
import { ElementLocalData } from './elementLocalDataTypes';

export interface ElementLocalDataAction {
    id: string;
    data: ElementLocalData;
}

export interface MultipleElementsLocalDataAction {
    updates: ElementLocalDataAction[];
    transactionId?: string;
}

export const setMultipleElementsLocalData = ({
    updates,
    transactionId,
}: MultipleElementsLocalDataAction): AnyAction => ({
    type: ELEMENT_SET_LOCAL_DATA_MULTI,
    updates,
    transactionId,
});

export const setElementLocalData = ({
    id,
    data,
    transactionId,
}: ElementLocalDataAction & { transactionId?: string }): AnyAction =>
    setMultipleElementsLocalData({
        updates: [{ id, data }],
        transactionId,
    });
