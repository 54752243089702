/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { alreadySuggested } from '../suggestion/suggestionUtils';
import { shouldShowTitleSuggestion } from '../suggestion/title/titleSuggestionUtils';
import { hasChanged, noLonger } from '../../utils/react/propsComparisons';

// Selectors
import { getElementLocalData } from '../local/elementLocalDataSelector';
import { canShowTitleSuggestionSelector } from './taskListSelector';

// Actions
import { elementSuggestFeature } from '../suggestion/suggestionActions';

// Constants
import { FEATURE_SUGGESTIONS } from '../suggestion/suggestionConstants';

const childElementIdsHaveChanged = hasChanged('childElementIds');
const noLongerEditingChild = noLonger('isEditingChild');

const mapStateToProps = createStructuredSelector({
    elementLocalData: getElementLocalData,
    canShowTitleSuggestion: canShowTitleSuggestionSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSuggestTitle: ({ id }) =>
        dispatch(
            elementSuggestFeature({
                id,
                feature: FEATURE_SUGGESTIONS.TITLE,
            }),
        ),
});

export default (DecoratedComponent) => {
    @connect(mapStateToProps, mapDispatchToProps)
    class suggestTitleDecorator extends React.Component {
        componentWillReceiveProps(nextProps) {
            if (
                childElementIdsHaveChanged(this.props, nextProps) &&
                nextProps.childElementIds.length > this.props.childElementIds.length
            ) {
                this.onNewChildElement(nextProps);
            }
        }

        componentDidUpdate(prevProps) {
            if (this.keyDownListenerAttached && noLongerEditingChild(prevProps, this.props)) {
                // Keep the debounce going
                this.debouncedOnKeyDown();
            }
        }

        onNewChildElement = (props) => {
            const { element, isEditingChild, elementLocalData, canShowTitleSuggestion } = props;

            if (!canShowTitleSuggestion) return;

            if (!shouldShowTitleSuggestion(element)) return;

            if (alreadySuggested(elementLocalData, FEATURE_SUGGESTIONS.TITLE)) return;

            if (isEditingChild) {
                this.attachKeyDownListener();

                // Kick off the debounced handler
                this.debouncedOnKeyDown();
            } else {
                this.suggestTitle(props);
            }
        };

        attachKeyDownListener = () => {
            if (this.keyDownListenerAttached) return;

            this.keyDownListenerAttached = true;

            document.addEventListener('keydown', this.debouncedOnKeyDown);
        };

        removeKeyDownListener = () => {
            if (!this.keyDownListenerAttached) return;

            this.keyDownListenerAttached = false;

            document.removeEventListener('keydown', this.debouncedOnKeyDown);
        };

        suggestTitle = ({ dispatchSuggestTitle, element, childElementIds }) => {
            this.removeKeyDownListener();

            if (childElementIds.length <= 1) return;

            dispatchSuggestTitle({ id: getElementId(element) });
        };

        onKeyDown = () => this.suggestTitle(this.props);

        debouncedOnKeyDown = debounce(this.onKeyDown, 1000);

        render() {
            return <DecoratedComponent {...this.props} />;
        }
    }

    suggestTitleDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        childElementIds: PropTypes.array.isRequired,
        dispatchSuggestTitle: PropTypes.func,
        isEditingChild: PropTypes.bool,
        canShowTitleSuggestion: PropTypes.bool,
    };

    return suggestTitleDecorator;
};
