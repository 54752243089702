// Lib
import React, { useEffect } from 'react';
import { compose } from '../../../node_module_clones/recompose';
import PropTypes from 'prop-types';
import { identity, isEmpty } from 'lodash';

// Components
import ElementDropTarget from '../dnd/elementDropTargets/ElementDropTarget';

// Utils
import { isAnnotation, isLine } from '../../../common/elements/utils/elementTypeUtils';
import { getElementId, getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';

// Drop Utils
import inboxDropLocationFn from '../../inbox/inboxDropLocationFn';
import canvasDropLocationFn from '../dnd/canvasDropLocationFn';
import makeCanDropFn from '../dnd/canDrop/makeCanDropFn';
import preventAllElementPredicate from '../dnd/canDrop/preventAllElementPredicate';
import validElementPermissionsPredicate from '../dnd/canDrop/validElementPermissionsPredicate';
import navigateOnHoverDecorator from '../dnd/navigateOnHoverDecorator';
import onlyShowIfNotPresentational from '../dnd/elementDropTargets/onlyShowIfNotPresentational';
import preventSnappedDragDrop from '../dnd/canDrop/preventSnappedDragDrop';

// Constants
import { DROP_TARGET_TYPES, ELEMENT_DND_TYPE } from '../../../common/elements/elementConstants';
import { NATIVE_TYPES } from '../../utils/dnd/dragAndDropUtils';

// Style
import './BoardDropTarget.scss';

/**
 * Prevent boards in columns from being able to drop on themselves.
 */
const dontDropOnSelfPredicate = ({ props, monitor }) => {
    const { element } = props || {};
    const { draggedElementIds } = monitor?.getItem() || {};

    if (isEmpty(draggedElementIds)) return true;

    const actualElementId = getElementId(element);
    const physicalElementId = getPhysicalId(element);

    return !draggedElementIds.includes(actualElementId) && !draggedElementIds.includes(physicalElementId);
};

const dropTargetConfig = {
    drop: (props, monitor, domNode) => {
        const { element, dispatchGetChildIds } = props;

        const itemType = monitor.getItemType();
        const childIds = dispatchGetChildIds(getPhysicalId(element));
        const isEmptyBoard = isEmpty(childIds);

        // If board is empty and dropped element is ELEMENT_DND_TYPE, place elements into canvas with layout preserved
        if (isEmptyBoard && itemType === ELEMENT_DND_TYPE) return canvasDropLocationFn(props, monitor, domNode);

        // Otherwise, place elements into inbox
        return inboxDropLocationFn(props, monitor, domNode);
    },
    canDrop: makeCanDropFn([
        preventSnappedDragDrop,
        preventAllElementPredicate([isLine, isAnnotation]),
        validElementPermissionsPredicate,
        dontDropOnSelfPredicate,
    ]),
    acceptDropTypes: NATIVE_TYPES,
    hoverType: DROP_TARGET_TYPES.BOARD,
};

const enhance = compose(
    // Prevent the drop target from being added on drag.
    onlyShowIfNotPresentational,

    // Element Drop Target
    ElementDropTarget(dropTargetConfig),
    navigateOnHoverDecorator,
);

const BoardDropTarget = (props) => {
    const { connectDropTarget = identity, isHovered, setIsHovered, setParentHoveredChildAcceptsDrop } = props;

    useEffect(() => {
        setIsHovered(isHovered);

        if (setParentHoveredChildAcceptsDrop && isHovered) {
            setParentHoveredChildAcceptsDrop(true);
        }
    }, [isHovered]);

    return <div ref={connectDropTarget} className="BoardDropTarget" />;
};

BoardDropTarget.propTypes = {
    connectDropTarget: PropTypes.func,
    isHovered: PropTypes.bool,
    setIsHovered: PropTypes.func,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
};

export default enhance(BoardDropTarget);
