const config = process.env.BROWSER
    ? require('../../client/utils/getClientConfig').default()
    : require('../../../config');

// Use apiRoot if set (i.e. Electron), otherwise use the urlBase
export const APP_URL = config.apiRoot || config.urlBase;
export const CLASSIC_APP_URL = 'https://v1.milanote.com';
export const MARKETING_URL = 'https://www.milanote.com';
export const HELP_URL = 'http://help.milanote.com/';
export const SERVICE_STATUS_URL = 'http://status.milanote.com/';
export const BLOG_URL = 'https://medium.com/milanote';
export const MEDIA_CDN_URL = config.aws.s3MediaStorageUrl;
export const MEDIA_CDN_HOSTNAME = new URL(MEDIA_CDN_URL).hostname;
export const APP_MEDIA_PATH_PREFIX = '/media';
export const APP_MEDIA_URL = `${APP_URL}${APP_MEDIA_PATH_PREFIX}/`;
export const APP_MEDIA_HOSTNAME = new URL(APP_MEDIA_URL).hostname;
export const APP_HOSTNAME = new URL(APP_URL).hostname;
export const INSPIRATION_URL = 'https://www.milanote.com/inspiration';

export const WEB_CLIPPER_DOWNLOAD_URL = 'https://milanote.com/download-web-clipper';

export const FORGOTTEN_PASSWORD_URL = `${APP_URL}/forgotten-password`;

export const REFERRAL_REDIRECT_URL = `${MARKETING_URL}/`;

export const MARKETING_PLANS_URL = `${MARKETING_URL}/plans`;
export const MARKETING_POLL_URL = `${MARKETING_URL}/poll`;

export const MARKETING_REGISTER_URL = `${MARKETING_URL}/register`;

export const SHORTCUTS_HELP_URL = `${HELP_URL}getting-started/shortcuts`;
export const SHARING_HELP_URL = `${HELP_URL}en/articles/4324593-sharing-a-board`;

export const RELEASE_NOTES_WEB_URL = 'https://milanote.com/releases/web';
export const RELEASE_NOTES_MAC_URL = 'https://milanote.com/releases/mac';

export const HELP_CENTER_PAYMENT_TROUBLESHOOTING = `${HELP_URL}/en/articles/3600891-i-m-trying-to-upgrade-but-my-payment-isn-t-working`;

export const HELP_CENTER_RESTRICTED_COUNTRY_TROUBLESHOOTING = `${HELP_URL}en/articles/6005639-which-countries-regions-are-restricted`;

export const HELP_CENTER_FILE_UPLOAD_TYPES = `${HELP_URL}/en/articles/1159010-what-kind-of-files-can-i-upload`;

export const ELEMENT_MODAL_SUB_PATH = 'e';

export const MILANOTE_DOMAIN_SUFFIX = 'milanote.com';

export const ALTERNATE_WEB_HOSTS = config.web?.alternateHosts ?? [];

/**
 * Query params used throughout the app.
 */
export const APP_QUERY_PARAMS = {
    PERMISSION: 'p',
    PERMISSION_TOKENS: 'p_tokens',

    // Email unsubscribeFlag
    EMAIL: 'email',
    EMAIL_TYPE: 'email_type',

    // Element query params
    FILTER_IDS: 'filter_ids',
    FILTER_QUERY: 'filter_query',
    FILTER_CONTEXT: 'filter_context',

    // Onboarding flows
    ONBOARDING_FLOW_ID: 'flow',

    // Plans
    FEATURE_HIGHLIGHT: 'feature',

    // Registration / login
    ORIGIN: 'origin',
    INVITE_CODE: 'invite',
    MARKETING_REFERRAL_CODE: 'r',
    MARKETING_REFERRAL_CODE_2: 'ref',
    USER_REFERRER_CODE: 'referrer',
    SUCCESS_REDIRECT: 'success_redirect',
    REGISTRATION_AVENUE: 'registration_avenue',
    REGISTRATION_ENTRY_POINT: 'registration_entry_point',
    FROM_REDIRECTION: 'from',
    INITIAL_ONBOARDING: 'onboarding',
    INITIAL_EDUCATION: 'initial_education',
    PUBLIC_BOARD_ID: 'public_board_id',
    AB_GROUP: 'ab',
    FEATURE_FLAGS: 'feature_flags',

    // Override redirect when logged out
    LOGGED_OUT_REDIRECT: 'logged_out_redirect',

    // PERMISSION is also used in registration from a public board

    AUTH_TOKEN: 'token',

    // Export "preview" route params
    HIDE_INBOX: 'hide_inbox',
    HIDE_BACKGROUND: 'hide_background',
    CENTER_CANVAS: 'center_canvas',
    IMAGE_QUALITY: 'image_quality',
    DIRECT: 'direct',
    WORKSPACE_CLASS: 'workspace_class',

    // Payment flow route params
    PAYMENT_PLAN_TYPE: 'plan_type',
    PAYMENT_DEFAULT_SELECTED_PLAN_ID: 'default_selected_plan',
    PAYMENT_PLAN_IDS: 'plans',
    PAYMENT_TEAM_MEMBER_IDS: 'members',
    PAYMENT_TEAM_ADD_MEMBER_IDS: 'add_members',
    PAYMENT_TEAM_REMOVE_MEMBER_IDS: 'remove_members',
    PAYMENT_MODAL_HIDE_BACK_BUTTON: 'no_back',

    // Domain subscription
    DOMAIN_SUBSCRIPTION_ERROR_CODE: 'error_code',
    DOMAIN_SUBSCRIPTION_ERROR_MESSAGE: 'error_message',
    DOMAIN_SUBSCRIPTION_ERROR_TEAM_NAME: 'error_team_name',

    // Media Asset permissions
    VERSION: 'v',
    ELEMENT_ID: 'elementId',
    WIDTH: 'w',
    FORMAT: 'format',
};
