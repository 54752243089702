import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-link"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M4 3h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M4 4h12v12H4z" />
            <path
                stroke="#5F6672"
                strokeLinecap="round"
                d="M10.75 9.25c.781.781 1 2 .25 2.75l-1 1c-.75.75-2.219.781-3 0-.781-.781-.75-2.25 0-3"
            />
            <path
                stroke="#5F6672"
                strokeLinecap="round"
                d="M9.25 10.75c-.781-.781-1-2-.25-2.75l1-1c.75-.75 2.219-.781 3 0 .781.781.75 2.25 0 3"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniLinkIcon);
export default Memo;
