// Lib
import { clamp } from 'lodash/fp';

// Utils
import * as pointsLib from '../../../common/maths/geometry/rect';

/**
 * Will get the viewport rectangle of a scrollable element (the coordinates that are currently visible).
 * Scrollbars can optionally be included or excluded.
 *
 * NOTE: This will give coordinates from the start of the scrollable element.
 *      E.g. If the element hasn't been scrolled at all, top and left will be 0,0.
 */
export const getViewportRect = (
    scrollableElementNode,
    {
        excludeScrollbars = true,
        // Margins to inset the viewport by.
        insetMargins,
    } = {},
) => {
    // Just shortening the variable name
    const domNode = scrollableElementNode;

    if (!domNode) return null;

    const boundingRect = domNode.getBoundingClientRect();
    const {
        top: viewportTopInset = 0,
        right: viewportRightInset = 0,
        bottom: viewportBottomInset = 0,
        left: viewportLeftInset = 0,
    } = insetMargins || {};

    const verticalScrollbarWidth = (excludeScrollbars && domNode.offsetWidth - domNode.clientWidth) || 0;
    const horizontalScrollbarWidth = (excludeScrollbars && domNode.offsetHeight - domNode.clientHeight) || 0;

    const width = boundingRect.width - verticalScrollbarWidth - viewportLeftInset - viewportRightInset;
    const height = boundingRect.height - horizontalScrollbarWidth - viewportTopInset - viewportBottomInset;

    return {
        x: domNode.scrollLeft,
        y: domNode.scrollTop,
        top: domNode.scrollTop + viewportTopInset,
        left: domNode.scrollLeft + viewportLeftInset,
        bottom: domNode.scrollTop + viewportTopInset + height,
        right: domNode.scrollLeft + viewportLeftInset + width,

        width,
        height,
    };
};

/**
 * Translates a DOM rectangle into the same coordinate system as the scrollable element.
 * The DOMRect is from the window's top left, this will translate it to the scrollable element's coordinates.
 */
export const translateDOMRectIntoScrollableElementCoordinates = (scrollableElement, rectangle) => {
    const scrollableElementRect = scrollableElement.getBoundingClientRect();

    // Translate to "scrollable area" coordinates (either the canvas or inbox).
    // I.e. top left will be 0,0
    const scrollableAreaTranslation = {
        // Current x scroll - current left
        x: scrollableElement.scrollLeft - scrollableElementRect.left,
        // Current y scroll - current top
        y: scrollableElement.scrollTop - scrollableElementRect.top,
    };

    return pointsLib.translate(scrollableAreaTranslation, rectangle);
};

export const canScrollX = (scrollableElement) => scrollableElement.scrollWidth !== scrollableElement.clientWidth;
export const canScrollY = (scrollableElement) => scrollableElement.scrollHeight !== scrollableElement.clientHeight;
export const canScroll = (scrollableElement) => canScrollX(scrollableElement) || canScrollY(scrollableElement);

// Can only scroll left as far as we've already scrolled right
export const getScrollXMin = (scrollableElement) => -scrollableElement.scrollLeft;
export const getScrollXMax = (scrollableElement) => scrollableElement.scrollWidth - scrollableElement.scrollLeft;
// Can only scroll up as much as the scrollable element is scrolled down
export const getScrollYMin = (scrollableElement) => -scrollableElement.scrollTop;
export const getScrollYMax = (scrollableElement) => scrollableElement.scrollHeight - scrollableElement.scrollTop;

/**
 * How far needs to be scrolled to make the left edge of the target touch the left edge of the viewport.
 */
const getDistToScrollToEdge =
    (edge) =>
    ({ viewportRect, targetRect }) =>
        targetRect[edge] - viewportRect[edge];
export const getDistToScrollToLeftEdge = getDistToScrollToEdge('left');
const getDistToScrollToRightEdge = getDistToScrollToEdge('right');
const getDistToScrollToTopEdge = getDistToScrollToEdge('top');
const getDistToScrollToBottomEdge = getDistToScrollToEdge('bottom');

// Determine if the target is outside of the viewport
const getIsOverflowingLeft = ({ viewportRect, targetRect }) => viewportRect.left > targetRect.left;
const getIsOverflowingRight = ({ viewportRect, targetRect }) => viewportRect.right < targetRect.right;
const getIsOverflowingTop = ({ viewportRect, targetRect }) => viewportRect.top > targetRect.top;
const getIsOverflowingBottom = ({ viewportRect, targetRect }) => viewportRect.bottom < targetRect.bottom;

// Determine if the target is already at the extremities of the viewport
const targetIsOnViewportLeftEdge = ({ viewportRect, targetRect }) =>
    getDistToScrollToLeftEdge({ viewportRect, targetRect }) === 0;
const targetIsOnViewportTopEdge = ({ viewportRect, targetRect }) =>
    getDistToScrollToTopEdge({ viewportRect, targetRect }) === 0;

/**
 * If the target is overflowing the viewport to the left or right, we should scroll.
 */
const shouldScrollX = ({ viewportRect, targetRect }) =>
    getIsOverflowingLeft({ viewportRect, targetRect }) ||
    (getIsOverflowingRight({ viewportRect, targetRect }) &&
        // If the target is already exactly on the viewport left edge, we shouldn't scroll right
        !targetIsOnViewportLeftEdge({ viewportRect, targetRect }));

/**
 * If the target is overflowing the viewport to the left or right, we should scroll.
 */
const shouldScrollY = ({ viewportRect, targetRect }) =>
    getIsOverflowingTop({ viewportRect, targetRect }) ||
    (getIsOverflowingBottom({ viewportRect, targetRect }) &&
        // If the target is already exactly on the viewport left edge, we shouldn't scroll right
        !targetIsOnViewportTopEdge({ viewportRect, targetRect }));

/**
 * Determines whether scrolling should occur for a target rectangle within a scrollable element.
 */
export const shouldScroll = (scrollableElement, targetRect, viewportOptions) => {
    const viewportRect = getViewportRect(scrollableElement, viewportOptions);

    return (
        (canScrollX(scrollableElement) && shouldScrollX({ viewportRect, targetRect })) ||
        (canScrollY(scrollableElement) && shouldScrollY({ viewportRect, targetRect }))
    );
};

const getScrollLeft = ({ scrollableElement, viewportRect, targetRect }) => {
    const scrollRange = clamp(getScrollXMin(scrollableElement), getScrollXMax(scrollableElement));

    const scrollLeftX = getDistToScrollToLeftEdge({ viewportRect, targetRect });
    const scrollRightX = getDistToScrollToRightEdge({ viewportRect, targetRect });

    // Don't need to scroll X if the target is already within the X range
    if (scrollLeftX >= 0 && scrollRightX <= 0) return scrollableElement.scrollLeft;

    const scrollDiffX = scrollRange(
        Math.min(
            scrollLeftX,
            // If we're scrolling left, don't attempt to scroll to the right edge
            scrollLeftX < 0 ? Infinity : scrollRightX,
        ),
    );

    // Math.min will ensure the left edge is favoured if it won't fit in the viewport
    return scrollableElement.scrollLeft + scrollDiffX;
};

const getScrollTop = ({ scrollableElement, viewportRect, targetRect }) => {
    const scrollRange = clamp(getScrollYMin(scrollableElement), getScrollYMax(scrollableElement));

    const scrollTopY = getDistToScrollToTopEdge({ viewportRect, targetRect });
    // If we're scrolling up, don't attempt to scroll to the bottom edge
    const scrollBottomY = getDistToScrollToBottomEdge({ viewportRect, targetRect });

    // Don't need to scroll X if the target is already within the X range
    if (scrollTopY >= 0 && scrollBottomY <= 0) return scrollableElement.scrollTop;

    const scrollDiffY = scrollRange(
        Math.min(
            scrollTopY,
            // If we're scrolling left, don't attempt to scroll to the right edge
            scrollTopY < 0 ? Infinity : scrollBottomY,
        ),
    );

    // Math.min will ensure the top edge is favoured if it won't fit in the viewport
    return scrollableElement.scrollTop + scrollDiffY;
};

/**
 * Gets the updated scrollLeft and scrollTop to get the scrollableElement to show the targetRect.
 */
export const getScrollForTarget = ({ scrollableElement, targetRect, viewportOptions }) => {
    const viewportRect = getViewportRect(scrollableElement, viewportOptions);

    return {
        scrollLeft: getScrollLeft({ scrollableElement, viewportRect, targetRect }),
        scrollTop: getScrollTop({ scrollableElement, viewportRect, targetRect }),
    };
};

/**
 * Gets the current scroll position of a DOM element as x, y coordinates.
 */
export const getScrollAsPoint = (element) =>
    element ? { x: element.scrollLeft, y: element.scrollTop } : { x: 0, y: 0 };

export const scrollToBottom = (domElement) => {
    if (!domElement || !canScrollY(domElement)) return;

    domElement.scrollTop = domElement.scrollHeight - domElement.clientHeight;
};
