import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';

export const getDuplicateOriginalIdSelector = (state, ownProps) =>
    state.getIn(['app', 'duplications', ownProps.elementId]);

export const getDuplicateLoadingForCurrentBoard = (state) => {
    const currentBoardId = getCurrentBoardId(state);
    return state.getIn(['app', 'duplications', 'loading', currentBoardId, 'loading']);
};

export const getDuplicateLoading = (state, ownProps) =>
    state.getIn(['app', 'duplications', 'loading', ownProps.elementId, 'loading']);
