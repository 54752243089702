import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createUsableUrl } from '../../../../../../common/tiptap/extensions/hyperlink/createUsableUrl';
import { closePopup, togglePopup } from '../../../../../components/popupPanel/popupActions';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import popupOpenSelector from '../../../../../components/popupPanel/popupOpenSelector';
import { getCurrentlyEditingId } from '../../../../../element/selection/currentlyEditingSelector';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import RichTextTool from '../richContentTools/RichTextTool';
import HyperlinkPopup from './hyperlinkPopup/HyperlinkPopup';
import {
    ActiveTiptapEditorSelector,
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

const getCurrentUrl: ActiveTiptapEditorSelector = (editor) => editor?.getAttributes('link').href || '';
const getIsSelectionEmpty: ActiveTiptapEditorSelector = (editor) => editor?.state.selection.empty;

const TiptapHyperlinkTool = () => {
    const isPopupOpen = useSelector(popupOpenSelector(PopupIds.HYPERLINK));
    const currentlyEditingId = useSelector(getCurrentlyEditingId);

    const storedSelection = useRef<{ from: number; to: number } | null>(null);
    const restoreSelection = useActiveTiptapEditorCallback((activeEditor) => {
        if (!storedSelection.current) return;
        activeEditor?.commands.setTextSelection(storedSelection.current);
        storedSelection.current = null;
    }, []);

    const dispatch = useDispatch();
    const closeHyperlinkPopup = () => dispatch(closePopup(PopupIds.HYPERLINK));
    const openHyperlinkPopup = () => dispatch(togglePopup(PopupIds.HYPERLINK));

    const currentUrl = useTiptapActiveEditorSelector(getCurrentUrl);
    const isSelectionEmpty = useTiptapActiveEditorSelector(getIsSelectionEmpty);

    const onPopupOpen = useActiveTiptapEditorCallback(
        (activeEditor) => {
            if (!activeEditor) return;

            if (!currentUrl) return;

            storedSelection.current = {
                from: activeEditor.state.selection.from,
                to: activeEditor.state.selection.to,
            };
            activeEditor.commands.extendMarkRange('link', { href: currentUrl });
        },
        [currentUrl],
    );

    useEffect(() => {
        if (isPopupOpen) {
            onPopupOpen();
        } else {
            restoreSelection();
        }
    }, [isPopupOpen, onPopupOpen]);

    const onSetHyperlink = useActiveTiptapEditorCallback(
        (activeEditor, url: string) => {
            if (!activeEditor) return;

            if (url === currentUrl) {
                // no changes to the content, but still restore focus
                activeEditor.commands.focus();
                return;
            }

            // Here we have some text specifically selected -- just update the mark for exactly that selection
            activeEditor.chain().focus().setLink({ href: url }).run();
        },
        [currentUrl],
    );

    const onClearHyperlink = useActiveTiptapEditorCallback((activeEditor) => {
        if (!activeEditor) return;
        activeEditor.chain().focus().unsetLink().run();
    }, []);

    return (
        <div className="HyperLinkRichTextToolContainer">
            <RichTextTool
                onPointerDown={openHyperlinkPopup}
                iconName="toolbar-hyperlink"
                tooltipEnabled={!isPopupOpen}
                isOpen={isPopupOpen}
                styleCommand="link"
                disabled={isSelectionEmpty && !currentUrl}
                isActive={!!currentUrl}
            >
                <ToolbarPopup
                    popupId={PopupIds.HYPERLINK}
                    className="hyperlink-popup"
                    buttonSelector=".RichTextTool.link .icon"
                    closePopup={closeHyperlinkPopup}
                >
                    <HyperlinkPopup
                        onSetHyperlink={onSetHyperlink}
                        onClearHyperlink={onClearHyperlink}
                        onClose={closeHyperlinkPopup}
                        currentlyEditingId={currentlyEditingId}
                        url={currentUrl}
                        isActive={!!currentUrl}
                    />
                </ToolbarPopup>
            </RichTextTool>
        </div>
    );
};

export default TiptapHyperlinkTool;
