// Utils
import { getMany, prop } from '../../../common/utils/immutableHelper';
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { isContent, isBoardLike } from '../../../common/elements/utils/elementTypeUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { boardVisibleElementGraphSelector, elementGraphSelector } from '../selectors/elementGraphSelector';
import { getElement as getElementFromProps, getElements } from '../selectors/elementSelector';
import { getChildType, mapElementCountsToChildCounts } from './containerElementUtil';
import { getIsClientPersistenceEnabledForCurrentUser } from '../feature/elementFeatureSelector';
import { getSelectorsCacheElementCountsMap } from '../../reducers/cache/selectorsCacheSelector';

const getVisibleChildrenIdsSelector = () =>
    createShallowSelector(
        getElementFromProps,
        elementGraphSelector,
        boardVisibleElementGraphSelector,
        (element, elementGraph, boardVisibleElementGraph) => {
            const elementId = getPhysicalId(element);

            const visibleChildren = isBoardLike(element)
                ? boardVisibleElementGraph[elementId]
                : elementGraph[elementId];

            return visibleChildren || [];
        },
    );

const getVisibleChildrenSelector = () => createShallowSelector(getVisibleChildrenIdsSelector(), getElements, getMany);

/**
 * This selector uses the main thread's "elements" state in the redux store to calculate the types of its children.
 * This is used for Columns, because their contents will always be in the local redux store (as it's always visible
 * when the column is visible).
 * It's also used for boards when the "client persistence" feature is disabled, because all of that element's
 * children will also be loaded into the main thread's redux store.
 */
export const makeLocalStoreContainerElementChildrenTypesSelector = () =>
    createShallowSelector(getVisibleChildrenSelector(), (children) =>
        children.reduce((acc, child) => {
            if (!(isContent(child) || isBoardLike(child))) return acc;

            const type = getChildType(child);
            const typeVal = acc[type];
            acc[type] = typeVal ? typeVal + 1 : 1;
            return acc;
        }, {}),
    );

/**
 * This selector uses the "cache.selectors.elementCountsMap" state in the redux store to look up
 * the types of its children.
 */
const makeCachedContainerElementChildrenTypesSelector = () =>
    createShallowSelector(
        // NOTE: This object will change much more than it needs to because the cached elementCountsMap
        //  is a completely new object every time there's an update, even if the individual field is the same
        getSelectorsCacheElementCountsMap,
        (_, elementId) => elementId,
        (elementCountsMap, elementId) => {
            const elementCounts = elementCountsMap?.[elementId];

            // This selector will return nothing if we don't have a cached value in the counts map
            // The ContainerElementChildCount won't show anything while this is the case
            if (!elementCounts) return;

            return mapElementCountsToChildCounts(elementCounts);
        },
    );

/**
 * Gets the element counts for the visible descendants inside this element.
 *
 * NOTE: This is currently using an experimental feature that calculates the element counts
 *  within a worker thread. This is not yet enabled for all users.
 */
export const makeContainerElementChildrenTypesSelector = () => {
    // The old way of calculating the element counts - using elements that are within the main thread's redux store
    const localReduxStoreSelector = makeLocalStoreContainerElementChildrenTypesSelector();

    // A new way of calculating element counts that simply looks up the cached counts for the element
    // that were calculated within the persistence layer worker thread
    const cachedElementCountsSelector = makeCachedContainerElementChildrenTypesSelector();

    return (state, ownProps) => {
        const hasClientPersistence = getIsClientPersistenceEnabledForCurrentUser(state, ownProps);

        if (hasClientPersistence) {
            const element = getElementFromProps(state, ownProps);
            const elementId = getPhysicalId(element);
            return cachedElementCountsSelector(state, elementId);
        }

        return localReduxStoreSelector(state, ownProps);
    };
};

export const getChildrenTypeArraySelector = () =>
    createShallowSelector(getVisibleChildrenSelector(), (children) =>
        children.map((child) => prop('elementType', child)),
    );
