// Utils
import { asObject, prop } from '../../../common/utils/immutableHelper';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import {
    getAsyncEntityError,
    isAsyncEntityFetched,
    isAsyncEntityFetching,
} from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Selectors
import { getAsyncResourceEntityState } from '../../utils/services/http/asyncResource/asyncResourceSelector';

// Constants
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { CLONE_INSTANCES_UI_MODES } from './cloneInstancesConstants';

const getElement = (state, ownProps) => state.getIn(['elements', ownProps.elementId]);

export const cloneInstancesStateSelector = (state, ownProps) => {
    const element = getElement(state, ownProps);
    return state.getIn(['app', 'cloneInstances', getPhysicalId(element)]);
};
export const cloneReachableInstanceCountSelector = (state, ownProps) =>
    prop('reachableInstanceCount', cloneInstancesStateSelector(state, ownProps));
export const cloneInstanceBoardIdsSelector = (state, ownProps) =>
    prop('reachableInstanceBoardIds', cloneInstancesStateSelector(state, ownProps));

// Resources
const cloneResourceEntityState = (state, id) => getAsyncResourceEntityState(state, ResourceTypes.cloneInstances, id);

export const cloneInstancesModeSelector = (state, ownProps) => {
    // Need to get the element from the store, not from the props
    const element = state.getIn(['elements', ownProps.elementId]);
    const originalElementId = getPhysicalId(element);

    const cloneResourceEntity = cloneResourceEntityState(state, originalElementId);

    const fetched = isAsyncEntityFetched(cloneResourceEntity);
    const error = getAsyncEntityError(cloneResourceEntity);

    if (!fetched && !error) return CLONE_INSTANCES_UI_MODES.LOADING;

    const fetching = isAsyncEntityFetching(cloneResourceEntity);
    const cloneInstancesState = asObject(cloneInstancesStateSelector(state, { elementId: originalElementId }));

    if (!cloneInstancesState && fetching) return CLONE_INSTANCES_UI_MODES.LOADING;

    if (error) return CLONE_INSTANCES_UI_MODES.ERROR;

    // Should never happen
    if (!cloneInstancesState) return CLONE_INSTANCES_UI_MODES.INVALID;

    if (cloneInstancesState.validInstanceCount === 1) return CLONE_INSTANCES_UI_MODES.NO_CLONES;

    const hasNonReachableClones = cloneInstancesState.validInstanceCount !== cloneInstancesState.reachableInstanceCount;

    if (cloneInstancesState.reachableInstanceBoardCount === 1) {
        return hasNonReachableClones
            ? CLONE_INSTANCES_UI_MODES.PRIVATE_BOARDS
            : CLONE_INSTANCES_UI_MODES.CURRENT_BOARD_ONLY;
    }

    return CLONE_INSTANCES_UI_MODES.MANY_BOARDS;
};
