import { EditorState, Modifier } from 'draft-js';

export const getEditorStateOnCharWithoutMarkdownReplacement = (
    previousEditorState: EditorState,
    newEditorState: EditorState,
    char: string,
): EditorState => {
    if (previousEditorState === newEditorState) return previousEditorState;

    const previousContentState = previousEditorState.getCurrentContent();
    const previousContentStateSelection = previousContentState.getSelectionAfter();
    const nextContentState = Modifier.insertText(previousContentState, previousContentStateSelection, char);

    const selection = nextContentState.getSelectionAfter();

    return EditorState.set(previousEditorState, { currentContent: nextContentState, selection });
};
