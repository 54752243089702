// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';

// Utils
import { getMediaType, getShowMedia } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import LinkImage from './LinkImage';
import VideoMedia from '../../richMedia/components/video/VideoMedia';
import RichMedia from '../../richMedia/components/RichMedia';

// Constants
import { MEDIA_TYPES } from '../../../../common/links/richMediaConstants';

const LinkMediaElement = (props) => {
    const { element } = props;

    const showMedia = getShowMedia(element);
    if (!showMedia) return null;

    const mediaType = getMediaType(element);

    switch (mediaType) {
        case MEDIA_TYPES.VIDEO:
            return <VideoMedia {...props} />;
        // TODO Remove map when migrations have occurred
        case MEDIA_TYPES.MAP:
        case MEDIA_TYPES.RICH:
            return <RichMedia {...props} />;
        default:
            return <LinkImage {...props} />;
    }
};

const LinkMedia = (props) => {
    const { element, registerMediaElement } = props;

    const showMedia = getShowMedia(element);
    if (!showMedia) return null;

    const mediaWrapperFn = registerMediaElement || identity;
    return mediaWrapperFn(
        <div className="LinkMedia">
            <LinkMediaElement {...props} />
        </div>,
    );
};

LinkMediaElement.propTypes = LinkMedia.propTypes = {
    element: PropTypes.object.isRequired,
    registerMediaElement: PropTypes.func,
};

export default LinkMedia;
