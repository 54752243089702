import { useState, useRef } from 'react';

// Utils
import { getPoint } from '../../../../../../common/maths/geometry/point';
import { getSelectionMarqueeDimensions } from '../../../../../canvas/selection/selectionMarqueeUtils';

const useDrawingEditorSelectionMarqueeState = () => {
    const [isMarqueeSelectMode, setIsMarqueeSelectModeState] = useState(false);
    const [isAppendMode, setIsAppendState] = useState(false);
    const [initialSelectedPathIdsMap, setInitialSelectedPathIdsMap] = useState(false);
    const [marqueeDimensions, setMarqueeDimensions] = useState(null);
    const startPosition = useRef();

    const startMarqueeSelectMode = ({ point, appendMode, selectedPathIdsMap }) => {
        startPosition.current = getPoint(point[0], point[1]);
        setIsMarqueeSelectModeState(true);
        setIsAppendState(appendMode);
        setInitialSelectedPathIdsMap(selectedPathIdsMap);
    };

    const dragMarqueeSelectionTo = (point) => {
        const newPoint = getPoint(point[0], point[1]);
        const newMarqueeDimensions = getSelectionMarqueeDimensions(startPosition.current, newPoint);
        setMarqueeDimensions(newMarqueeDimensions);
    };

    const endMarqueeSelectMode = () => {
        setIsMarqueeSelectModeState(false);
        setMarqueeDimensions(null);
    };

    return {
        isMarqueeSelectMode,
        isAppendMode,
        initialSelectedPathIdsMap,
        marqueeDimensions,
        startMarqueeSelectMode,
        dragMarqueeSelectionTo,
        endMarqueeSelectMode,
    };
};

export default useDrawingEditorSelectionMarqueeState;
