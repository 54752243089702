// Services
import editorIsOnly from '../../../customRichUtils/editorState/editorIsOnly';
import fieldValidationService from '../../../../../../common/validation/fieldValidationService';

const isLink = (text) => {
    if (text.split(/\s/).length !== 1) return false;

    const validationError = fieldValidationService(text, { type: 'url', allowIPAddress: true, required: true });

    return !validationError;
};

export default editorIsOnly(isLink);
