export const LINE_DISPLAY_NAME = 'Line';

export const LINE_EDGE_DND_TYPE = 'LINE_EDGE';
export const LINE_CONTROL_POINT_DND_TYPE = 'LINE_CONTROL_POINT';

export const LINE_STYLE = {
    SOLID: 'SOLID',
    DASHED: 'DASHED',
    DOTTED: 'DOTTED',
};

export const LINE_EDGE = {
    start: 'start',
    end: 'end',
};

export const LINE_MARKER_STYLE = {
    ARROW: 'ARROW',
    DOT: 'DOT',
    NONE: 'NONE',
};

export const LINE_WEIGHT = {
    S: 1,
    M: 2,
    L: 4,
};

export const MIN_LINE_LENGTH = 4;
export const DEFAULT_LINE_WIDTH = 17;
export const DEFAULT_LINE_HEIGHT = DEFAULT_LINE_WIDTH;
