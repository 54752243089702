import fetchImageDataFromUrl from './fetchImageDataFromUrl';
import createImagePixelArray from './createImagePixelArray';
import createImageHistogramPalette from './createImageHistogramPalette';

import { rgbToHex } from '../../../../common/colors/colorSpaceUtil';

const getColorPaletteFromUrl = async (url) => {
    try {
        const imageData = await fetchImageDataFromUrl(url);

        const pixelCount = imageData.width * imageData.height;
        const pixelArray = createImagePixelArray(new Uint8Array(imageData.data), pixelCount, 20);

        const histogramPalette = createImageHistogramPalette(pixelArray);

        const paletteRgb = histogramPalette.map(rgbToHex);

        return paletteRgb;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export default getColorPaletteFromUrl;
