import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ExportTextIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={26}
        viewBox="0 0 20 26"
        className="Icon export-text"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CACACA" d="M0 0v26h20V6.999L13 0z" />
            <path fill="#FFF" d="M1 1h11v7h7v17H1z" />
            <path fill="#FFF" d="m13 1.417 5.581 5.582H13z" />
            <g fill="#929292">
                <path d="M4 12h12v1H4zM4 14h12v1H4zM4 16h12v1H4zM4 18h12v1H4z" />
            </g>
        </g>
    </svg>
);
const Memo = memo(ExportTextIcon);
export default Memo;
