import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationUnshareBoardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={34}
        viewBox="0 0 34 34"
        className="Icon notification-unshare-board"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={34} height={34} rx={3.579} />
            <path fill="#FFF" d="M8.16 8.16h8.16v10.88H8.16zm0 12.24h8.16v5.44H8.16zm9.52-12.24h8.16v17.68h-8.16z" />
        </g>
    </svg>
);
const Memo = memo(NotificationUnshareBoardIcon);
export default Memo;
