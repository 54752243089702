// Lib
import { shouldUpdate } from '../../../node_module_clones/recompose';
import shallowEqual from 'shallowequal';
import { omit } from 'lodash';

// Helper
import createHelper from './createHelper';

const dontUpdateForKeys = (propKeys) =>
    shouldUpdate((props, nextProps) => !shallowEqual(omit(nextProps, propKeys), omit(props, propKeys)));

export default createHelper(dontUpdateForKeys, 'dontUpdateForKeys');
