// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Selectors
import { getGridSize } from '../../../utils/grid/gridSizeSelector';
import { activePopupSelector } from '../../../components/popupPanel/popupOpenSelector';

// Actions
import { closePopup } from '../../../components/popupPanel/popupActions';

// Components
import FloatingPanel from '../../../components/tooltips/FloatingPanel';
import PopupPanelMouseManager from '../../../components/popupPanel/PopupPanelMouseManager';
import CommentThreadContainer from '../CommentThreadContainer';

// Constants
import { TooltipAlignment, TooltipPositions } from '../../../components/tooltips/tooltipConstants';

// Style
import './CommentThreadCollapsedPopup.scss';

const mapStateToProps = createSelector(
    activePopupSelector,
    (state, ownProps) => ownProps.popupId,
    (state, ownProps) => ownProps.forcePopupOpen,
    getGridSize,
    (popupState, popupId, forcePopupOpen, gridSize) => ({
        isPopupOpen: forcePopupOpen || popupState.has(popupId),
        gridSize,
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchClosePopup: (popupId) => dispatch(closePopup(popupId)),
});

const CommentThreadCollapsedPopup = (props) => {
    const { isPopupOpen, gridSize, popupId, dispatchClosePopup } = props;

    if (!isPopupOpen) return null;

    return (
        <FloatingPanel
            selector={`.popup-trigger-${popupId} .pin-button`}
            className="CommentThreadCollapsedPopup light"
            arrowColor="var(--ws-comment-background)"
            arrowStrokeColor="var(--ui-background-primary)"
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={0}
            arrowStyle={{ transform: 'translate(0px, 1px)' }}
            distance={15}
            offset={0}
            position={TooltipPositions.RIGHT}
            alignment={TooltipAlignment.START}
            gridSize={gridSize}
            responsive
            pollPosition
        >
            <PopupPanelMouseManager
                {...props}
                ignoredClicksSelector=".Toolbar,.mention-suggestion-entry"
                visible={isPopupOpen}
                popupId={popupId}
                closePopup={() => dispatchClosePopup(popupId)}
            >
                <CommentThreadContainer {...props} isCollapsedThread disableCollapse />
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

CommentThreadCollapsedPopup.propTypes = {
    isPopupOpen: PropTypes.bool,
    gridSize: PropTypes.number,
    popupId: PropTypes.string,
    elementId: PropTypes.string,
    permissions: PropTypes.number,

    dispatchClosePopup: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentThreadCollapsedPopup);
