// Lib
import { reduce } from 'lodash';
import { size, head, tail, last, take } from 'lodash/fp';
import { asObject } from '../../immutableHelper';

const shiftBy = (property) => (shiftAmount) => (obj) => ({
    ...obj,
    [property]: obj[property] + shiftAmount,
});

const shiftKeyBy = shiftBy('key');
const shiftOffsetBy = shiftBy('offset');

const mergeBlocks = (initialBlock, appendedBlock) => ({
    ...initialBlock,
    data: {
        ...appendedBlock.data,
        ...initialBlock.data,
    },
    entityRanges: initialBlock.entityRanges.concat(appendedBlock.entityRanges),
    inlineStyleRanges: initialBlock.inlineStyleRanges.concat(appendedBlock.inlineStyleRanges),
    text: initialBlock.text + appendedBlock.text,
});

export default (initialRawStateInput, appendedRawStateInput) => {
    if (!initialRawStateInput) return appendedRawStateInput;
    if (!appendedRawStateInput) return initialRawStateInput;

    const initialRawState = asObject(initialRawStateInput);
    const appendedRawState = asObject(appendedRawStateInput);

    // First determine how much to increment the entity map
    const initialEntityMap = initialRawState.entityMap;
    const initialEntityCount = size(initialRawState.entityMap);
    const shiftKey = shiftKeyBy(initialEntityCount);

    const finalEntityMap = reduce(
        appendedRawState.entityMap,
        (accMap, entity, originalEntityKey) => {
            accMap[parseInt(originalEntityKey, 10) + initialEntityCount] = entity;
            return accMap;
        },
        { ...initialEntityMap },
    );

    // Update the entity keys in the appended blocks to match the new entity keys
    const appendedBlocks = appendedRawState.blocks.map((block) => ({
        ...block,
        entityRanges: block.entityRanges.map(shiftKey),
    }));

    const startInitialBlocks =
        initialRawState.blocks.length > 1 ? take(initialRawState.blocks, initialRawState.blocks.length - 1) : [];
    const lastInitialBlock = last(initialRawState.blocks);
    const lastBlockTextLength = lastInitialBlock.text.length;
    const shiftOffset = shiftOffsetBy(lastBlockTextLength);

    const firstAppendedBlock = head(appendedBlocks);
    const tailAppendedBlocks = appendedBlocks.length > 1 ? tail(appendedBlocks) : [];

    // Update the ranges of the first appended block
    const updatedFirstAppendedBlock = {
        ...firstAppendedBlock,
        entityRanges: firstAppendedBlock.entityRanges.map(shiftOffset),
        inlineStyleRanges: firstAppendedBlock.inlineStyleRanges.map(shiftOffset),
    };

    const joinedBlock = mergeBlocks(lastInitialBlock, updatedFirstAppendedBlock);

    const finalBlocks = [...startInitialBlocks, joinedBlock, ...tailAppendedBlocks];

    return {
        blocks: finalBlocks,
        entityMap: finalEntityMap,
    };
};
