// Utils
import { shiftInPlace } from './measureElementMathsUtils';
import { getElementRects } from './measureElementDomUtils';
import { unTransformMeasurementsInPlace } from './measureElementZoomUtils';
import {
    getElementId,
    getElementType,
    getLocationSection,
} from '../../../../../common/elements/utils/elementPropertyUtils';

// Types
import { Point } from '../../../../../common/maths/geometry/pointTypes';
import { Rect } from '../../../../../common/maths/geometry/rect/rectTypes';
import { ImMNElement, MNElement } from '../../../../../common/elements/elementModelTypes';
import { BoardSections } from '../../../../../common/boards/boardConstants';
import { ElementMeasurement } from '../elementMeasurementsTypes';

type PartialMeasurement = Omit<ElementMeasurement, 'rects'>;

const removeFeatureSuggestionsHeightInPlace = (measurements: PartialMeasurement, featureSuggestionsHeight: number) => {
    if (!featureSuggestionsHeight) return measurements;

    measurements.height = measurements.height - featureSuggestionsHeight;
    measurements.bottom = measurements.bottom - featureSuggestionsHeight;

    return measurements;
};

export const getElementMeasurementFromCanvasDocument = (
    elementBoundingRect: Rect,
    element: ImMNElement | MNElement,
    zoomScale: number,
    zoomTranslationPx: Point,
    workspaceSection: BoardSections,
    featureSuggestionsHeight: number,
    originOffset: Point,
    canvasOffset: Point,
    node: Element | undefined,
): ElementMeasurement => {
    const measurements: PartialMeasurement = {
        ...elementBoundingRect,
        id: getElementId(element),
        workspaceSection,
        section: getLocationSection(element),
        elementType: getElementType(element),
        originOffset,
    };

    // Not sure why, but modifying this in place appears to be necessary!
    shiftInPlace(measurements, canvasOffset);
    removeFeatureSuggestionsHeightInPlace(measurements, featureSuggestionsHeight);

    // Scale the measurements based on the Canvas's current scale
    const zoomState = {
        scale: zoomScale,
        translation: zoomTranslationPx,
    };
    unTransformMeasurementsInPlace(measurements, zoomState);

    // TODO At the moment, this calculation requires the measurements to be first transformed & shifted
    //  before determining the "rects". Ideally this would be performed in the getElementDomMeasurementsData
    //  function instead and that would prevent the need for the node to be passed into this function
    const rects = getElementRects(element, measurements, node, canvasOffset);

    return {
        ...measurements,
        rects,
    };
};
