import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniBoardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-board"
        {...props}
    >
        <g fill="none" fillRule="evenodd" transform="translate(3 3)">
            <rect width={14} height={14} fill="#5882F8" rx={3} />
            <rect width={3.063} height={3.063} x={7.438} y={7.438} fill="#FFF" rx={0.875} />
            <rect width={3.063} height={3.063} x={3.5} y={7.438} fill="#FFF" rx={0.875} />
            <rect width={3.063} height={3.063} x={7.438} y={3.5} fill="#FFF" rx={1.531} />
            <rect width={3.063} height={3.063} x={3.5} y={3.5} fill="#FFF" rx={0.875} />
        </g>
    </svg>
);
const Memo = memo(ElementMiniBoardIcon);
export default Memo;
