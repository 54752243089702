// Lib
import thunk from 'redux-thunk';

// Custom middleware
import { createSocketMiddleware } from '../utils/socket/middleware/createSocketMiddleware';
import undoRedoMiddleware from '../utils/undoRedo/middleware/undoRedoMiddleware';
import createAnalyticsMiddleware from '../analytics/analyticsMiddleware';
import filterStateRoutingMiddleware from './filterStateRoutingMiddleware';
import elementCountMiddleWare from '../user/elementCount/elementCountMiddleware';
import addStateToWindowMiddleware from './addStateToWindowMiddleware';
import forceElementLoadMiddleware from './forceElementLoadMiddleware';
import onboardingMiddleware from '../workspace/onboarding/onboardingMiddleware';
import cancelAttachmentUploadMiddleware from './cancelAttachmentUploadMiddleware';
import activityMiddleware from '../notifications/activityMiddleware';
import notificationsFilteringMiddleware from '../notifications/notificationsFilteringMiddleware';
import notificationsDataFetchMiddleware from '../notifications/notificationsDataFetchMiddleware';
import { bulkingMiddleware } from './reduxBulkingMiddleware';
import userLogoutMiddleware from './userLogoutMiddleware';
import canvasSizeMiddleware from '../canvas/canvasSizeMiddleware';
import addUserToActionMiddleware from './addUserToActionMiddleware';
import addMetaVersionToUpdateActionMiddleware from './addMetaVersionToUpdateActionMiddleware';
import addTokensToActionMiddleware from './addTokensToActionMiddleware';
import cloneActionMiddleware from '../element/clone/cloneActionMiddleware';
import collisionDetectionMiddleware from '../components/collision/collisionDetectionMiddleware';
import unsavedCommentsAlertMiddleware from './unsavedCommentsAlertMiddleware';
import remoteActivityMiddleware from '../remoteActivity/remoteActivityMiddleware';
import elementContentDiffUpdateActionMiddleware from './elementContentDiffUpdateActionMiddleware';
import localCacheMiddleware from '../offline/cache/localCacheMiddleware';
import { createMainStoreLoggingMiddleware } from './mainStoreLoggingMiddleware';
import appServerReconnectionMiddleware from '../offline/appServerConnection/appServerReconnectionMiddleware';
import persistenceMiddleware from '../offline/persistence/middleware/persistenceMiddleware';
import debugReduxActionTimeMiddleware from '../debug/performance/debugReduxActionTimeMiddleware';
import { historyMiddleware } from './history';
import mobileEditingMiddleware from '../mobile/boardsPage/editing/mobileEditingMiddleware';

// Analytics
import { NewRelicCustomAttributes, setNewRelicCustomAttribute } from '../analytics/newRelicUtils';

// Utils
import { isGlobalDebugEnabled } from '../debug/debugUtil';
import { isLoggerDebugEnabled, LoggerComponents } from '../logger';
import { SUPPORTS_PERSISTENCE_LAYER } from '../offline/persistence/utils/persistenceLayerSupport';

// Constants
export default ({ socketManager, thunkArg = {} }) => {
    const middlewareList = [];

    middlewareList.push(thunk.withExtraArgument(thunkArg));

    middlewareList.push(mobileEditingMiddleware);

    if (isLoggerDebugEnabled(LoggerComponents.REDUX_LOG)) middlewareList.push(debugReduxActionTimeMiddleware);

    middlewareList.push(appServerReconnectionMiddleware);
    middlewareList.push(localCacheMiddleware);

    setNewRelicCustomAttribute(NewRelicCustomAttributes.SUPPORTS_PERSISTENCE_LAYER, !!SUPPORTS_PERSISTENCE_LAYER);

    middlewareList.push(remoteActivityMiddleware);
    middlewareList.push(cloneActionMiddleware);
    middlewareList.push(addUserToActionMiddleware);
    middlewareList.push(addMetaVersionToUpdateActionMiddleware);
    middlewareList.push(addTokensToActionMiddleware);
    middlewareList.push(userLogoutMiddleware);
    middlewareList.push(canvasSizeMiddleware(thunkArg));
    middlewareList.push(onboardingMiddleware);

    middlewareList.push(undoRedoMiddleware);

    middlewareList.push(historyMiddleware());

    middlewareList.push(cancelAttachmentUploadMiddleware);
    middlewareList.push(elementCountMiddleWare);
    middlewareList.push(filterStateRoutingMiddleware);

    middlewareList.push(createAnalyticsMiddleware());
    middlewareList.push(unsavedCommentsAlertMiddleware);

    middlewareList.push(notificationsDataFetchMiddleware);
    middlewareList.push(notificationsFilteringMiddleware);
    middlewareList.push(activityMiddleware);
    middlewareList.push(collisionDetectionMiddleware);

    if (socketManager) {
        middlewareList.push(createSocketMiddleware(socketManager));
    }

    middlewareList.push(bulkingMiddleware());
    middlewareList.push(elementContentDiffUpdateActionMiddleware);
    middlewareList.push(forceElementLoadMiddleware);

    if (isLoggerDebugEnabled(LoggerComponents.REDUX_LOG_MAIN)) {
        middlewareList.push(createMainStoreLoggingMiddleware());
    }

    if (isGlobalDebugEnabled()) {
        middlewareList.push(addStateToWindowMiddleware());
    }

    // Add this last so the action remains the same when the buildCacheOperationFromElementsAction is called
    // from here and then from within the elementSummariesSelector.ts (for memoisation)
    if (SUPPORTS_PERSISTENCE_LAYER) {
        middlewareList.push(persistenceMiddleware);
    }

    return middlewareList;
};
