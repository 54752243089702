// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { prop } from '../../../../common/utils/immutableHelper';
import { getElementStyle } from '../../../utils/grid/gridUtils';
import { getIsFeatureEnabled } from '../../feature/elementFeatureUtils';
import { getBackgroundColorClasses, getColorCssValue } from '../../utils/elementColorStyleUtils';
import { elementClassNames, getDomElementId } from '../../utils/elementUtil';
import { getBackgroundColor, getElementId, getWidth } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import ElementResizeHandle from '../../resizing/ElementResizeHandle';
import DrawingSvg from '../DrawingSvg';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../../common/elements/elementConstants';
import { ELEMENT_FEATURES } from '../../feature/elementFeatureConstants';
import { ANNOTATION_MIN_WIDTH } from '../../../../common/drawings/annotations/annotationConstants';

// Styles
import './Annotation.scss';

const Annotation = (props) => {
    const {
        elementEvents,
        gridSize,
        element,
        tempSize,
        isSelected,
        inList,
        isPresentational,
        isEditable,
        isLocked,
        isRemotelyEdited,
        editedDrawingId,
        dispatchEditAnnotationElement,
        drawingElementRef,
    } = props;

    const elementId = getElementId(element);

    if (isRemotelyEdited || editedDrawingId === elementId) return null;

    // Background colours
    // when dragging a card in an inbox, don't apply the background color,
    // as it should be the light grey instead
    const backgroundColor = getBackgroundColor(element);
    const colorCss = getColorCssValue(backgroundColor);

    const isTransparencyEnabled = getIsFeatureEnabled(ELEMENT_FEATURES.TRANSPARENT)(element);

    const savedCardWidth = getWidth(element);
    const drawingWidth = prop('width', tempSize) || savedCardWidth || ELEMENT_DEFAULT_WIDTH;

    // Resize handle
    const showResizeHandle = !isLocked && isEditable && !inList && drawingWidth >= ANNOTATION_MIN_WIDTH;

    const elementStyle = {
        ...getElementStyle(drawingWidth, gridSize, inList),
        '--ws-element-background-primary': colorCss,
    };

    const handleDoubleClick = () => {
        if (!isEditable) return;

        dispatchEditAnnotationElement(elementId);
    };

    const annotationClassNames = classNames('Annotation', {
        // Don't show a drag shadow if the element is transparent
        'drag-handle': !isTransparencyEnabled,
        'background-color-target': isSelected,
        'background-color-contrast-target': isSelected,
        ...getBackgroundColorClasses(backgroundColor),
    });

    const classes = elementClassNames(annotationClassNames, {
        ...props,
        transparent: isTransparencyEnabled,
    });

    return (
        <div
            id={getDomElementId(getElementId(element))}
            ref={drawingElementRef}
            className={classes}
            {...elementEvents}
            style={elementStyle}
        >
            <DrawingSvg {...props} onDoubleClick={handleDoubleClick} />
            {!isPresentational && (
                <ElementResizeHandle {...props} elementId={elementId} showHandle={showResizeHandle} />
            )}
        </div>
    );
};

Annotation.propTypes = {
    currentBoardId: PropTypes.string,
    element: PropTypes.object,
    gridSize: PropTypes.number,
    permissions: PropTypes.number,
    tempSize: PropTypes.object,

    elementEvents: PropTypes.object,

    inList: PropTypes.string,
    isSelected: PropTypes.bool,
    isPresentational: PropTypes.bool,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    isRemotelyEdited: PropTypes.bool,

    editedDrawingId: PropTypes.string,

    drawingElementRef: PropTypes.object,
    emptyDrawingRef: PropTypes.object,

    setParentHoveredChildAcceptsDrop: PropTypes.func,

    dispatchEditAnnotationElement: PropTypes.func,
    dispatchDeselectAndNavigateToElement: PropTypes.func,
};

export default Annotation;
