import { MimeType } from '../media/mimeConstants';

export enum BoardExportFormat {
    canvas = 'canvas',
    linear = 'linear',
}

export enum BoardExportQuality {
    regular = 'regular',
    high = 'high',
}

export type ValidExportFileTypes =
    | MimeType.PDF
    | MimeType.PNG
    | MimeType.DOCX
    | MimeType.MARKDOWN
    | MimeType.PLAIN_TEXT
    | MimeType.ZIP;

export enum BoardExportType {
    ELEMENT = 'ELEMENT',
    BOARD_MEDIA = 'BOARD_MEDIA',
}

export const BOARD_EXPORT_MEDIA = 'BOARD_EXPORT_MEDIA';

export const BOARD_EXPORT_MEDIA_RESET = 'BOARD_EXPORT_MEDIA_RESET';
