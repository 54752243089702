/* eslint-disable prefer-template */
// Lib
import { escapeRegExp } from 'lodash/fp';

// Block style markdown
export const ORDERED_LIST = /^\s*[1]+\.$/;
export const LIST = /^\s*[\*,•,\-]$/;
export const LARGE_HEADING = /^\s*#+$/;
export const HEADING = /^\s*##+$/;
export const BLOCKQUOTE = /^\s*>$/;

const createInlineStyleMarkdownRegex = (markdownChar) => {
    const escapedMarkdownChar = escapeRegExp(markdownChar);

    const regexString =
        // Match the start of the string, or space or any non-word (not a-z, A-Z, 0-9)
        '(^|\\s|\\W+)' +
        // Match the markdown char (*,_,`,~)
        escapedMarkdownChar +
        // Followed by one char that's not space or the markdown char OR
        // One char that's not space or the markdown char, followed by any char that's not the markdown character,
        // followed by one char that's not space or the markdown char
        `([^${markdownChar}\\s]|[^${markdownChar}\\s][^${markdownChar}]*[^${markdownChar}\\s])` +
        // Match the markdown char (*,_,`,~)
        escapedMarkdownChar +
        // Match the end of the string
        '$';
    return new RegExp(regexString);
};

// Inline style markdown
export const BOLD = [createInlineStyleMarkdownRegex('*')];
export const ITALIC = [createInlineStyleMarkdownRegex('_')];
export const CODE = [createInlineStyleMarkdownRegex('`')];
export const STRIKETHROUGH = [createInlineStyleMarkdownRegex('~')];

export const HYPERLINK = [/\[([^\]]+)]\(([^)"]+)(?: "([^"]+)")?\)\s*$/];

export const TERMINATING_MARKDOWN_CHARS = [' ', '*', '_', '~', '`', ')'];
