import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from '../../../node_module_clones/react-transition-group';
import classNames from 'classnames';

const Spinner = ({ className, strokeWeightMultiplier = 1 }) => (
    <div className={classNames('Spinner', className)}>
        <div className="spinner-wrapper">
            <div className="spinner-container">
                <svg className="circular" viewBox="25 25 50 50">
                    <defs>
                        <linearGradient id="spinner-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="var(--ui-loading-spinner-gradient-start)" stopOpacity={1.0} />
                            <stop offset="90%" stopColor="var(--ui-loading-spinner-gradient-stop)" stopOpacity={0.0} />
                            <stop offset="100%" stopColor="var(--ui-loading-spinner-gradient-stop)" stopOpacity={0.0} />
                        </linearGradient>
                    </defs>
                    <circle
                        className="path spinner-line"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="url(#spinner-gradient)"
                        strokeWidth={2 * strokeWeightMultiplier}
                        strokeMiterlimit="10"
                    />
                </svg>
            </div>
        </div>
    </div>
);

const SpinnerContainer = (props) => {
    const { show, fadeIn, className, strokeWeightMultiplier } = props;

    if (fadeIn) {
        return (
            <CSSTransitionGroup transitionName="fade" transitionEnterTimeout={200} transitionLeaveTimeout={200}>
                {show ? <Spinner className={className} strokeWeightMultiplier={strokeWeightMultiplier} /> : null}
            </CSSTransitionGroup>
        );
    }

    return show ? <Spinner className={className} strokeWeightMultiplier={strokeWeightMultiplier} /> : null;
};

Spinner.propTypes = SpinnerContainer.propTypes = {
    show: PropTypes.bool,
    fadeIn: PropTypes.bool,
    className: PropTypes.string,
    strokeWeightMultiplier: PropTypes.number,
};

export default SpinnerContainer;
