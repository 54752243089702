import React from 'react';
import classNames from 'classnames';
import { ElementType } from '../../../../../../common/elements/elementTypes';

import './DualColorIcon.scss';

enum IconType {
    DIAGONAL_HALF = 'diagonal-half',
    LETTER = 'letter',
}

const getIconType = (selectedType: string) => {
    if (selectedType === ElementType.CARD_TYPE) {
        return IconType.LETTER;
    }

    return IconType.DIAGONAL_HALF;
};

type DualColorSwatchIconProps = {
    secondaryColor: string;
    selectedType: string;
};

const DualColorIcon = (props: DualColorSwatchIconProps) => {
    const { secondaryColor, selectedType } = props;

    const iconType = getIconType(selectedType);

    return (
        <div className={classNames('DualColorIcon', iconType)}>
            {iconType === IconType.DIAGONAL_HALF && (
                <svg className="diagonal-half-icon" viewBox="0 0 9 9" preserveAspectRatio="none">
                    <polygon points="0,0 10,0 10,10" style={{ fill: secondaryColor }} />
                </svg>
            )}

            {iconType === IconType.LETTER && (
                <div className="secondary-color letter-icon" style={{ color: secondaryColor }}>
                    A
                </div>
            )}
        </div>
    );
};

export default DualColorIcon;
