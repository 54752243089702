export enum TrashElementTypes {
    'DATE' = 'date',
    'ELEMENT' = 'Element',
}

type Date = {
    listId: string;
    type: TrashElementTypes.DATE;
    timestamp: number;
};

type Element = {
    listId: string;
    type: TrashElementTypes.ELEMENT;
    elementId: string;
};

export type TrashListOrderedContent = Map<string, Date | Element>;
