// Lib
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { getCellTextAlignment, getBackgroundColorClass } from '../utils/tableCellFormattingUtils';

// Components
import CellCheckboxRenderer from '../cellTypes/CellCheckboxRenderer';
import CellFormulaErrorRenderer from './CellFormulaErrorRenderer';
import TableCellEditor from '../../../components/editor/tableCell/TableCellEditor';

// Hooks
import useMilanoteCellRendererFormulaInfo from './useMilanoteCellRendererFormulaInfo';
import useMilanoteCellRendererCellContentInfo from './useMilanoteCellRendererCellContentInfo';

// Constants
import { CellTypeConstants, CellTypeNames } from '../../../../common/table/CellTypeConstants';
import { TABLE_CELL_EDITOR_KEY } from '../../../../common/table/tableConstants';

import './MilanoteCellRendererComponent.scss';

const MilanoteCellRendererComponent = (props) => {
    const { row, col, hotCellValue, cellData = {}, hotTableInstance, hide, filterQuery } = props;
    const { type, textAlignment, textStyle, background } = cellData || {};
    const { locale, elementId } = hotTableInstance.milanoteProps;

    const { hfType, hfDetailedType, hfCellValue, isFormula, isFormulaError } = useMilanoteCellRendererFormulaInfo(
        props,
        [hotCellValue, cellData, locale],
    );

    const { rawContent, plainText, shouldRenderPlainText } = useMilanoteCellRendererCellContentInfo(
        { ...props, hfType, hfDetailedType },
        [hotCellValue, cellData, locale],
    );

    const editorTextAlignment = useMemo(
        () => getCellTextAlignment(cellData, locale, hotCellValue),
        [hotCellValue, cellData, locale],
    );

    const backgroundClasses = useMemo(() => getBackgroundColorClass(cellData), [background]);

    if (hide) return null;

    const className = classNames('MilanoteCellRendererComponent', backgroundClasses);

    // If cell contains a formula error, render as CellFormulaErrorRenderer
    if (isFormulaError) {
        return (
            <div className={className}>
                <CellFormulaErrorRenderer cellError={hfCellValue} />
            </div>
        );
    }

    // If cell contains a checkbox, render as CellCheckboxRenderer
    if (type?.name === CellTypeNames.CHECKBOX) {
        const alignment = textAlignment || CellTypeConstants[type.name].alignment;

        return (
            <div className={classNames(className, 'button-cell-renderer', `align-${alignment}`)}>
                <CellCheckboxRenderer
                    row={row}
                    col={col}
                    textContent={hotCellValue}
                    isEditable={!isFormula}
                    hotTableInstance={hotTableInstance}
                />
            </div>
        );
    }

    // If content is empty, render an empty div
    if (rawContent === undefined) {
        return <div className={className}></div>;
    }

    // If content is just a plain text without any styling or links, and there is no search query,
    // render a simple paragraph
    if (!filterQuery && shouldRenderPlainText) {
        return (
            <div className={className} style={{ textAlign: editorTextAlignment }}>
                <p>{plainText}</p>
            </div>
        );
    }

    return (
        <div className={className}>
            <TableCellEditor
                className="handsontable-renderer"
                isEditing={false}
                isEditable={false}
                textContent={rawContent}
                defaultStyleOverride={textStyle}
                textAlignment={editorTextAlignment}
                editorId={`${elementId}-${TABLE_CELL_EDITOR_KEY}-RENDERER`}
                editorKey={`${TABLE_CELL_EDITOR_KEY}-RENDERER`}
                filterQuery={filterQuery}
            />
        </div>
    );
};

MilanoteCellRendererComponent.propTypes = {
    row: PropTypes.number,
    col: PropTypes.number,
    hotCellValue: PropTypes.any,
    cellData: PropTypes.object,
    hotTableInstance: PropTypes.object,
    hide: PropTypes.bool,
    filterQuery: PropTypes.string,
};

export default MilanoteCellRendererComponent;
