import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';

const hasValue = (val) => !!val;

export const getLiveDrawings = (state) => state.get('liveDrawing');

export const liveDrawingEditedElementIdsSelector = createShallowSelector(
    (state) => state.get('liveDrawing'),
    (liveDrawingEditors) =>
        liveDrawingEditors
            .map(({ editedDrawingId }) => editedDrawingId)
            .filter(hasValue)
            .toArray(),
);
