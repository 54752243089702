import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CloneOutlinedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon clone-outlined"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#1B2536" />
            <path
                fill="#FFF"
                d="M11.36 6.4a.64.64 0 0 1 .64.64v4.32a.64.64 0 0 1-.64.64H7.04a.64.64 0 0 1-.64-.64V7.04a.64.64 0 0 1 .64-.64zM8.8 4a.8.8 0 0 1 .8.8v.8H7.2a1.6 1.6 0 0 0-1.6 1.6v2.4h-.8a.8.8 0 0 1-.8-.8v-4a.8.8 0 0 1 .8-.8z"
            />
        </g>
    </svg>
);
const Memo = memo(CloneOutlinedIcon);
export default Memo;
