import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuOrderFrontIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-order-front"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M14.1 7.65a2.25 2.25 0 0 1 2.25 2.25v4.2a2.25 2.25 0 0 1-2.25 2.25H9.9a2.25 2.25 0 0 1-2.25-2.25V9.9A2.25 2.25 0 0 1 9.9 7.65h4.2zm0 1.5H9.9a.75.75 0 0 0-.75.75v4.2c0 .414.336.75.75.75h4.2a.75.75 0 0 0 .75-.75V9.9a.75.75 0 0 0-.75-.75zm-5.7-6.9a2.55 2.55 0 0 1 2.55 2.55.75.75 0 0 1-1.5 0c0-.58-.47-1.05-1.05-1.05H4.8c-.58 0-1.05.47-1.05 1.05v3.6c0 .58.47 1.05 1.05 1.05a.75.75 0 0 1 0 1.5A2.55 2.55 0 0 1 2.25 8.4V4.8A2.55 2.55 0 0 1 4.8 2.25h3.6zm10.8 10.8a2.55 2.55 0 0 1 2.55 2.55v3.6a2.55 2.55 0 0 1-2.55 2.55h-3.6a2.55 2.55 0 0 1-2.55-2.55.75.75 0 1 1 1.5 0c0 .58.47 1.05 1.05 1.05h3.6c.58 0 1.05-.47 1.05-1.05v-3.6c0-.58-.47-1.05-1.05-1.05a.75.75 0 1 1 0-1.5z"
        />
    </svg>
);
const Memo = memo(ContextMenuOrderFrontIcon);
export default Memo;
