import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniTableIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-table"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M3 4h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M3 5h14v10H3z" />
            <path fill="#E6E6E6" d="M3 5h14v3H3z" />
            <path fill="#CCC" d="M3 11h14v1H3z" />
            <path fill="#CCC" d="M12 5h1v10h-1zM7 5h1v10H7z" />
        </g>
    </svg>
);
const Memo = memo(ElementMiniTableIcon);
export default Memo;
