export const RECT_EDGES = {
    top: 'top',
    right: 'right',
    bottom: 'bottom',
    left: 'left',
};

export const RECT_CORNERS = {
    TOP_LEFT: 'TOP_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
};
