// Utils
import { isEmpty } from 'lodash';
import { getElementId, getModifiedBy } from '../../../../common/elements/utils/elementPropertyUtils';
import getQueryRegex, { QueryMode } from '../../../../common/utils/getQueryRegex';
import { getElementFilterIds, getElementFilterQuery } from '../elementFilterSelector';
import elementContainsSearchQuery from '../../../components/search/contentSearch/elementContainsSearchQuery';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { ElementFilterData, ElementFilterDataMap, ElementFilterParams } from './elementFilterDataTypes';

const EMPTY_OBJECT = {};

export const buildElementFilterData = (args: ElementFilterParams): ElementFilterDataMap => {
    const { elementFilter, currentBoard, currentBoardDeepChildren, comments } = args;

    if (!elementFilter || isEmpty(elementFilter)) return EMPTY_OBJECT;

    const filterQuery = elementFilter.query || '';
    const filterIds = elementFilter.ids || [];

    const hasFilterQuery = filterQuery.length > 0;
    const hasFilterIds = filterIds.length > 0;

    if (!hasFilterQuery && !hasFilterIds) return EMPTY_OBJECT;

    const matchesQuery = (element?: ImMNElement): boolean => {
        if (!hasFilterQuery || !element) return false;

        return elementContainsSearchQuery({
            queryRegEx: getQueryRegex(filterQuery, QueryMode.PHRASE),
            comments,
        })(element);
    };

    const matchesFilterIds = (element?: ImMNElement): boolean => {
        if (!hasFilterIds || !element) return false;

        return filterIds.includes(getElementId(element));
    };

    const currentBoardAndChildren = currentBoardDeepChildren.set(getElementId(currentBoard), currentBoard);

    const result = currentBoardAndChildren
        .map(
            (element): ElementFilterData => ({
                elementId: getElementId(element),
                pass: matchesQuery(element),
                focus: matchesFilterIds(element),
                actorIds: [getModifiedBy(element)],
            }),
        )
        .filter((filterData) => !!filterData && (filterData.pass || filterData.focus))
        .toJS() as ElementFilterDataMap;

    return result;
};
