// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';

// Utils
import { prop } from '../../../../../common/utils/immutableHelper';
import { getAssignments, getElementId } from '../../../../../common/elements/utils/elementPropertyUtils';

// Components
import TaskAssignmentPillLayout from './TaskAssignmentPillLayout';

const TaskAssignmentPill = (props) => {
    const { element, isEditable } = props;

    const assignments = getAssignments(element);
    const elementId = getElementId(element);

    return (
        <>
            {isEmpty(assignments) ? (
                <TaskAssignmentPillLayout
                    popupId={`task-assignment-${elementId}-0`}
                    elementId={elementId}
                    isEditable={isEditable}
                >
                    Assign to…
                </TaskAssignmentPillLayout>
            ) : (
                assignments.map((assignment, index) => (
                    <TaskAssignmentPillLayout
                        key={index}
                        className="assigned"
                        popupId={`task-assignment-${elementId}-${index}`}
                        assignment={assignment}
                        elementId={elementId}
                        populated
                        {...props}
                    >
                        @{prop('assigneeName', assignment)}
                    </TaskAssignmentPillLayout>
                ))
            )}
        </>
    );
};

TaskAssignmentPill.propTypes = {
    element: PropTypes.object,
    isEditable: PropTypes.bool,
};

export default TaskAssignmentPill;
