// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getDrawingViewBox } from '../utils/drawingCanvasUtils';

// Components
import DrawingEditorCanvasSvg from './DrawingEditorCanvasSvg';
import DrawingPath from './DrawingPath';

const DrawingEditorCanvasReadOnlyMode = React.forwardRef(function DrawingEditorCanvasReadOnlyMode(props, ref) {
    const { paths } = props;

    const viewBox = getDrawingViewBox(props);

    return (
        <DrawingEditorCanvasSvg ref={ref} viewBox={viewBox} className="DrawingEditorCanvasReadOnlyMode">
            {paths.map(({ id, ...rest }) => (
                <DrawingPath key={id} {...rest} />
            ))}
        </DrawingEditorCanvasSvg>
    );
});

DrawingEditorCanvasReadOnlyMode.propTypes = {
    paths: PropTypes.array,
    viewBox: PropTypes.string,
    viewBoxX: PropTypes.number,
    viewBoxY: PropTypes.number,
    viewBoxWidth: PropTypes.number,
    viewBoxHeight: PropTypes.number,
};

export default DrawingEditorCanvasReadOnlyMode;
