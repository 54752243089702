// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { range, padStart } from 'lodash';

// Utils
import { getAreDatesSameDay, getDateIsLessThan } from '../../../common/utils/timeUtil';

// Styles
import './TimeInput.scss';

const hoursOptions = range(1, 12);
hoursOptions.unshift(12);

const minutesOptions = range(0, 60, 5);
const meridiemOptions = ['am', 'pm'];

const getMinimumHourIndex = (value, min) => {
    // If the selected date is less than the minimum date, then none of the hours can be selected
    if (getDateIsLessThan(value, min) && !getAreDatesSameDay(value, min)) return 13;

    // If the selected date is greater than the minimum date, then all hours can be selected
    if (!getAreDatesSameDay(value, min)) return 0;

    const valueHours24 = value.getHours();
    const isValuePm = valueHours24 > 11;

    const minHours24 = min.getHours();
    const isMinPm = minHours24 > 11;

    // The currently showing value is PM but the minimum value is AM, so any hour can be selected
    if (isValuePm && !isMinPm) return 0;

    // The currently showing value is AM but the minimum value is PM, so no hour can be selected
    if (!isValuePm && isMinPm) return 13;

    // Otherwise they're both in the same meridian, so need to restrict based on the minimum value
    return isMinPm ? minHours24 - 12 : minHours24;
};

const getMinimumMinutes = (value, min) => {
    // If the selected date is less than the minimum date, then no minutes can be selected
    if (getDateIsLessThan(value, min) && !getAreDatesSameDay(value, min)) return 60;

    // If the selected date is greater than the minimum date, then every minute can be selected
    if (!getAreDatesSameDay(value, min)) return 0;

    const valueHours24 = value.getHours();
    const minHours24 = min.getHours();

    // The selected hour is larger than the minimum, so every minute can be selected
    if (valueHours24 > minHours24) return 0;

    // The selected hour is less than the minimum, so no minutes can be selected
    if (valueHours24 < minHours24) return 60;

    // The selected hour is the same as the minimum, so only the minimum minutes and above can be selected
    return min.getMinutes();
};

const getMeridiemMinimumIndex = (value, min) => {
    // If the selected date is less than the minimum date, then neither of the meridiems can be selected
    if (getDateIsLessThan(value, min) && !getAreDatesSameDay(value, min)) return 2;

    // If the selected date is greater than the minimum date, then both meridiems can be selected
    if (!getAreDatesSameDay(value, min)) return 0;

    // const valueHours24 = value.getHours();
    // const isValuePm = valueHours24 > 11;

    const minHours24 = min.getHours();
    const isMinPm = minHours24 > 11;

    // If the minimum is PM only the PM option can be selected
    return isMinPm ? 1 : 0;
};

const TimeInput = (props) => {
    const { value, onChange, min } = props;

    const date = value || new Date();

    const hours24 = date.getHours();
    const isValuePm = hours24 > 11;
    const hours12 = hours24 > 12 ? hours24 - 12 : hours24 || 12;
    const minutesRoundedTo5 = Math.floor(date.getMinutes() / 5) * 5;

    const minHoursIndex = getMinimumHourIndex(date, min);
    const minimumMinutes = getMinimumMinutes(date, min);
    const minimumMeridiemIndex = getMeridiemMinimumIndex(date, min);

    const onHourChange = (event) => {
        const updatedDate = new Date(date);

        let newHours = parseInt(event.target.value, 10);

        if (isValuePm && newHours !== 12) {
            newHours += 12;
        } else if (!isValuePm && newHours === 12) {
            newHours = 0;
        }

        updatedDate.setHours(newHours);

        onChange(updatedDate);
    };

    const onMinuteChange = (event) => {
        const updatedDate = new Date(date);
        updatedDate.setMinutes(event.target.value);
        onChange(updatedDate);
    };

    const onMeridiemChange = (event) => {
        const updatedDate = new Date(date);

        const isPm = event.target.value === 'pm';
        let newHours = hours12;

        if (isPm && newHours !== 12) {
            newHours += 12;
        } else if (!isPm && newHours === 12) {
            newHours = 0;
        }

        updatedDate.setHours(newHours);

        onChange(updatedDate);
    };

    return (
        <div className="TimeInput">
            <select className="hours" value={hours12} onChange={onHourChange}>
                {hoursOptions.map((hourOption, index) => (
                    <option key={hourOption} value={hourOption} disabled={index < minHoursIndex}>
                        {padStart(hourOption, 2, '0')}
                    </option>
                ))}
            </select>
            :
            <select className="minutes" value={minutesRoundedTo5} onChange={onMinuteChange}>
                {minutesOptions.map((minuteOption) => (
                    <option key={minuteOption} value={minuteOption} disabled={minuteOption < minimumMinutes}>
                        {padStart(minuteOption, 2, '0')}
                    </option>
                ))}
            </select>
            <select className="meridiem" value={isValuePm ? 'pm' : 'am'} onChange={onMeridiemChange}>
                {meridiemOptions.map((meridiemOption, index) => (
                    <option key={meridiemOption} value={meridiemOption} disabled={index < minimumMeridiemIndex}>
                        {meridiemOption}
                    </option>
                ))}
            </select>
        </div>
    );
};

TimeInput.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    min: PropTypes.object,
};

export default TimeInput;
