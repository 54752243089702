import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-image"
        {...props}
    >
        <defs>
            <path id="ElementMiniImageIcon__a" d="M0 0h12v12H0z" />
            <path id="ElementMiniImageIcon__c" d="M1 0h12a1 1 0 0 1 1 1v9H0V1a1 1 0 0 1 1-1z" />
            <path id="ElementMiniImageIcon__f" d="M10.6 5.1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            <filter
                id="ElementMiniImageIcon__e"
                width="300%"
                height="300%"
                x="-100%"
                y="-100%"
                filterUnits="objectBoundingBox"
            >
                <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 1 0 0 0 0 0.992156863 0 0 0 0 0.866666667 0 0 0 0.5 0"
                />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M4 3h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
            <path fill="#68C0E0" d="M4 3h12a1 1 0 0 1 1 1v9H3V4a1 1 0 0 1 1-1z" />
            <g transform="translate(4 4)">
                <mask id="ElementMiniImageIcon__b" fill="#fff">
                    <use xlinkHref="#ElementMiniImageIcon__a" />
                </mask>
                <use xlinkHref="#ElementMiniImageIcon__a" fill="#FFF" />
                <g mask="url(#ElementMiniImageIcon__b)">
                    <g transform="translate(-1 -1)">
                        <mask id="ElementMiniImageIcon__d" fill="#fff">
                            <use xlinkHref="#ElementMiniImageIcon__c" />
                        </mask>
                        <use xlinkHref="#ElementMiniImageIcon__c" fill="#8AE0FF" />
                        <path fill="#4EB85A" d="M8 8 4.5 4.5 1 8v2h12V8l-2.5-2z" mask="url(#ElementMiniImageIcon__d)" />
                        <g mask="url(#ElementMiniImageIcon__d)">
                            <use
                                xlinkHref="#ElementMiniImageIcon__f"
                                fill="#000"
                                filter="url(#ElementMiniImageIcon__e)"
                            />
                            <use xlinkHref="#ElementMiniImageIcon__f" fill="#FFFCE6" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
const Memo = memo(ElementMiniImageIcon);
export default Memo;
