// Lib
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, startsWith } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { currentBoardIdSet } from '../reducers/currentBoardId/currentBoardIdActions';
import { replaceBoardUrl } from '../reducers/navigationActions';

// Selectors
import { getCurrentBoardId, getCurrentBoard } from '../reducers/currentBoardId/currentBoardIdSelector';
import { getIsCurrentVisibleBoardError } from '../element/board/boardSelector';
import { getUrlPermissionId } from './routingSelector';
import {
    getCurrentBoardBestUrlPermissionId,
    hasRetrievedReadableAncestorsSelector,
} from '../utils/permissions/elementPermissionsSelector';
import { getElementId, getTitle } from '../../common/elements/utils/elementPropertyUtils';
import { slugify } from '../../common/utils/stringUtil';

const getRouteSlug = get('slug');
const getPathname = get(['location', 'pathname']);
const startsWithPreview = startsWith('/preview');
const startsWithPublishPreview = startsWith('/publish-preview');

const CurrentBoardIdSynchroniser = ({ params, routeParams, ...props }) => {
    const lastBoardId = useRef();
    const { currentBoardId } = params;
    const routeSlug = getRouteSlug(routeParams);

    const stateCurrentBoardId = useSelector((state) => getCurrentBoardId(state, props));
    const currentBoard = useSelector((state) => getCurrentBoard(state, props));
    const isCurrentBoardError = useSelector(getIsCurrentVisibleBoardError);
    const currentUrlPermissionId = useSelector(getUrlPermissionId);
    const bestUrlPermissionId = useSelector(getCurrentBoardBestUrlPermissionId);
    const hasRetrievedReadableAncestors = useSelector(hasRetrievedReadableAncestorsSelector);

    const dispatch = useDispatch();
    const dispatchCurrentBoardIdSet = (boardId) => dispatch(currentBoardIdSet({ boardId }));
    const dispatchReplaceBoardUrl = ({ boardId, slug, permissionId }) =>
        dispatch(replaceBoardUrl({ boardId, slug, permissionId }));

    const pathName = getPathname(props);
    const isPreviewRoute = startsWithPreview(pathName);
    const isPublishPreviewRoute = startsWithPublishPreview(pathName);

    // if currentBoardId from route is different from currentBoardId in state,
    // update the state to reflect the route
    useEffect(() => {
        if (currentBoardId && currentBoardId !== lastBoardId.current && currentBoardId !== stateCurrentBoardId) {
            dispatchCurrentBoardIdSet(currentBoardId);
        }

        lastBoardId.current = currentBoardId;
    }, [currentBoardId, stateCurrentBoardId, isCurrentBoardError]);

    // This effect updates the URL to match the current board, by adding a slug and permission ID if necessary
    useEffect(() => {
        // Don't update the URL when in preview mode
        if (!currentBoard || isPreviewRoute || isPublishPreviewRoute) return;

        // We're in an intermediate state, we need to wait for the current board to be updated to match the URL
        if (getElementId(currentBoard) !== currentBoardId) return;

        // The ancestors aren't retrieved so don't update the URL yet
        if (!hasRetrievedReadableAncestors) return;

        const title = getTitle(currentBoard);
        const slug = title && slugify(title);

        // If the current URL matches
        // eslint-disable-next-line eqeqeq
        if (currentUrlPermissionId == bestUrlPermissionId && routeSlug === slug) return;

        dispatchReplaceBoardUrl({
            boardId: currentBoardId,
            slug,
            permissionId: bestUrlPermissionId,
        });
    }, [currentBoard, bestUrlPermissionId]);

    return null;
};

CurrentBoardIdSynchroniser.propTypes = {
    params: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default CurrentBoardIdSynchroniser;
