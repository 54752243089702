// Lib
import { asObject, prop } from '../../../../common/utils/immutableHelper';
import { getHotInstance } from '../../../element/table/manager/HotTableManager';

const EMPTY_ARRAY = [];

const getDraftBlockText = (draftBlock) => prop('text', draftBlock) || '';
const getDraftBlocks = (draftContent) => prop('blocks', draftContent) || EMPTY_ARRAY;

export const draftContentHasMatch = (queryRegEx) => (draftContent) => {
    if (!draftContent || typeof draftContent !== 'object') return false;
    return getDraftBlocks(draftContent).some((block) => !!getDraftBlockText(block).match(queryRegEx));
};

export const tableContentHasMatch = (queryRegEx) => (elementId, tableData) => {
    if (!tableData) return false;

    const hotInstance = getHotInstance(elementId);

    return asObject(tableData)?.some((row, rowIndex) =>
        row?.some((cell, colIndex) => hotInstance?.getCopyableData(rowIndex, colIndex)?.match(queryRegEx)),
    );
};

export const stringMatches = (queryRegEx) => (string) => string && !!string.match(queryRegEx);

export const stringMatchesProp = (propFn) => (queryRegex) => (element) => {
    const str = propFn(element);
    return stringMatches(queryRegex)(str);
};
