// Handlers
import { onLeftArrow, onRightArrow } from './onHorizontalArrowHandlers';
import onTab from './onTab';

// Constants
import { KEY_CODES } from '../../../../utils/keyboard/keyConstants';

export default (event, draftArgs) => {
    switch (event.keyCode) {
        // Jumps immutable text elements when using left or right arrows
        case KEY_CODES.LEFT_ARROW:
            return onLeftArrow(event, draftArgs);
        case KEY_CODES.RIGHT_ARROW:
            return onRightArrow(event, draftArgs);
        // Allows deep lists
        case KEY_CODES.TAB:
            return onTab(event, draftArgs);
        default:
            return undefined;
    }
};
