// Utils
import { getTitle } from '../../../../common/elements/utils/elementPropertyUtils';
import { getShowTitle, getTitleSuggestionAck } from '../../taskList/taskListSelector';

export const shouldShowTitleSuggestion = (element) => {
    // If is showing a title then don't suggest
    if (getShowTitle(element)) return false;

    // If already showing a title then don't suggest
    if (!!getTitle(element)) return false;

    // If already acknowledged then don't suggest
    if (getTitleSuggestionAck(element)) return false;

    return true;
};
