// Lib
import { LOCATION_CHANGE } from 'react-router-redux';

// Utils
import { isPlatformNativeApp } from '../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../platform/platformSelector';
import { unsavedCommentsOnCurrentBoard } from '../element/comment/store/commentsInputSelector';

export default (store) => (next) => (action) => {
    if (action.type !== LOCATION_CHANGE) return next(action);

    // The iPad app's webview fires two actions when navigating away from a board with unsaved comments.
    // That would cause this function (and therefore a "confirm" dialog) to happen twice.
    // One action fires upon attempting navigation, and a second when the user taps 'Cancel' in the confirm
    // dialog. A second action does NOT fire if the user selects 'Okay' and successfully navigates away.
    //
    // The first action is a 'PUSH' and the second is a 'POP'.
    // We want to present a dialog on the first action, but not present it again if the user decides
    // to not navigate away (when the user taps 'Cancel' in the dialog).
    // To achieve this, we explicitly ignore the 'POP' action.
    //
    // If the action is a result of the user triggering an "undo" gesture however, we want the dialog
    // to trigger (because in that case, there is only the one action, and not a 'PUSH'/'POP' pair).

    const { getState } = store;
    const state = getState();

    const unsavedComments = unsavedCommentsOnCurrentBoard(state);

    const platformDetails = getPlatformDetailsSelector(state);

    const isHybridAppAndNavigating =
        isPlatformNativeApp(platformDetails) && !action.isUndo && action.payload.action == 'POP';

    // if user is just navigating and there are no unsaved comments keep navigating
    if (isHybridAppAndNavigating && !unsavedComments.size) return next(action);
    // this is to handle the double window scenario for iPad as mentioned in the comments above
    if (isHybridAppAndNavigating && unsavedComments.size) return;

    if (!unsavedComments.size) return next(action);

    const displayMessage =
        unsavedComments > 1
            ? 'You have unsaved comments. Do you want to discard them?'
            : 'You have an unsaved comment. Do you want to discard it?';

    // eslint-disable-next-line no-alert
    const confirmation = confirm(displayMessage);
    if (confirmation) return next(action);
};
