// Utils
import changeCurrentBlockType from '../../customRichUtils/changeCurrentBlockType';
import updateBlockMetadata from '../../customRichUtils/blocks/updateBlockMetadata';
import { sendAmplitudeEvent } from '../../../../analytics/amplitudeService';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { CHECKLIST_BLOCK_TYPE } from './checklistConstants';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../../common/analytics/statsConstants';
import { EVENT_TYPE_NAMES } from '../../../../../common/analytics/amplitudeEventTypesUtil';

// Match the start of the string followed by [ ] or [] or [x]
const CHECKLIST_MARKDOWN_PATTERN = /^(\[ \]|\[\]|\[x\])$/i;

// TODO Maybe move this into the markdown plugin
export default (char, editorState, eventTimeStamp, { setEditorState }) => {
    if (char !== ' ') return HANDLER_RETURN_VALUES.notHandled;

    // TODO Use the code from markdown
    const currentSelection = editorState.getSelection();
    const key = currentSelection.getStartKey();
    const block = editorState.getCurrentContent().getBlockForKey(key);
    const text = block.getText();
    const position = currentSelection.getAnchorOffset();
    const lineBeforeCharacter = text.slice(0, position);
    const lineAfterCharacter = text.slice(position);

    if (lineBeforeCharacter.match(CHECKLIST_MARKDOWN_PATTERN)) {
        let updatedEditorState = changeCurrentBlockType(editorState, CHECKLIST_BLOCK_TYPE, lineAfterCharacter);

        if (lineBeforeCharacter === '[x]' || lineBeforeCharacter === '[X]') {
            updatedEditorState = updateBlockMetadata(updatedEditorState, block, { checked: true });
        }

        setEditorState(updatedEditorState);

        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.CHECKLIST,
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.CHECKLIST]: true },
            },
        });

        return HANDLER_RETURN_VALUES.handled;
    }

    return HANDLER_RETURN_VALUES.notHandled;
};
