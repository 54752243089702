import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isPlatformApple } from '../../platform/utils/platformDetailsUtils';
import platformSingleton from '../../platform/platformSingleton';

const EMOJI_CDN_URL = 'https://static.milanote.com/emoji-assets';

const unicodeEmojiFallback = (unified = '') =>
    unified
        .split('-')
        .map((codePoint) => String.fromCodePoint(parseInt(codePoint, 16)))
        .join('');

const getEmojiStyle = () => (isPlatformApple(platformSingleton) ? 'apple' : 'twitter');

const emojiCDNUrl = `${EMOJI_CDN_URL}/${getEmojiStyle()}/`;

const EmojiImage = ({ emojiCode, size = 24 }) => {
    const [displayFallback, setDisplayFallback] = useState(false);

    if (!emojiCode) return null;

    const imageUrl = emojiCode.toLowerCase();

    const imageUrlx1 = `${emojiCDNUrl}${imageUrl}.png`;
    const imageUrlx2 = `${emojiCDNUrl}${imageUrl}@2x.png`;

    if (displayFallback) {
        return (
            <span
                className="EmojiImage fallback"
                style={{
                    display: 'block',
                    fontSize: size * 0.7,
                    lineHeight: 1.5,
                    width: size,
                    height: size,
                }}
            >
                {unicodeEmojiFallback(emojiCode)}
            </span>
        );
    }

    return (
        <img
            className="EmojiImage"
            src={imageUrlx2}
            srcSet={`${imageUrlx1} 1x, ${imageUrlx2} 2x`}
            width={Math.round(size)}
            height={Math.round(size)}
            onError={(error) => setDisplayFallback(true)}
        />
    );
};

EmojiImage.propTypes = {
    emoji: PropTypes.object,
    emojiCode: PropTypes.string,
    size: PropTypes.number,
    platform: PropTypes.string,
};

export default EmojiImage;
