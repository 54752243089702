import { TASK_DISPLAY_NAME } from './tasksConstants';
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.TASK_TYPE,
        content: {
            textContent: null,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.TASK_TYPE,
        objectConstructorFn,
        displayName: TASK_DISPLAY_NAME,
        ...params,
    });
