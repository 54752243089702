// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './ProgressBar.scss';

const ProgressBar = ({ percentageComplete, barbershop, animate }) => (
    <div className="ProgressBar">
        <div className={classNames('bar', { barbershop, animate })} style={{ width: `${percentageComplete}%` }} />
    </div>
);

ProgressBar.propTypes = {
    percentageComplete: PropTypes.number,
    barbershop: PropTypes.bool,
    animate: PropTypes.bool,
};

export default ProgressBar;
