// Lib
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// Selectors
import { isEditingSelector } from '../../selectors/elementSelector';

// Actions
import { startEditingElement } from '../../selection/selectionActions';

// Components
import DocumentModalHeader from './DocumentModalHeader';
import DocumentModalSidebar from './DocumentModalSidebar';
import DocumentModalTiptapEditor from './DocumentModalTiptapEditor';

// Constants
import { DOCUMENT_MODAL_EDITOR_KEY, DOCUMENT_MODAL_TITLE_EDITOR_KEY } from './documentModalConstants';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './DocumentModalTiptap.scss';

const getModalEditorKey = (elementId: string) => `${elementId}-${DOCUMENT_MODAL_EDITOR_KEY}`;

interface DocumentModalTiptapProps {
    elementId: string;
    element: ImMNElement;
    textContent: TiptapContent;

    permissions: number;

    isEditable: boolean;
    spellCheck: boolean;

    isModalView: boolean;
    isPreview: boolean;

    elementInstanceModalRef: (elem: HTMLDivElement | null) => void;
}

const DocumentModalTiptap = (props: DocumentModalTiptapProps): ReactElement => {
    const { element, elementId, isPreview, isEditable, elementInstanceModalRef } = props;

    const editorId = getModalEditorKey(elementId);
    const editorKey = DOCUMENT_MODAL_EDITOR_KEY;

    const dispatch = useDispatch();

    const { isEditing, currentEditorKey } = useSelector((state) =>
        // @ts-ignore The types of createShallowSelector must not be quite right
        isEditingSelector()(state, { elementId, element }),
    );

    const dispatchStartEditing = useCallback(() => {
        dispatch(
            startEditingElement({
                id: elementId,
                editorId,
                editorKey,
                transactionId: undefined,
                canUndo: true,
            }),
        );
    }, [dispatch, elementId, editorId, editorKey]);

    const classes = classNames('DocumentModal DocumentModalTiptap', { preview: isPreview, 'read-only': !isEditable });

    return (
        <div className={classes} ref={elementInstanceModalRef}>
            {!isPreview && (
                <DocumentModalHeader
                    element={element}
                    elementId={elementId}
                    editorKey={DOCUMENT_MODAL_TITLE_EDITOR_KEY}
                    isEditable={isEditable}
                    onStopEditing={dispatchStartEditing}
                />
            )}
            <div className="document-modal-body">
                {!isPreview && (
                    <DocumentModalSidebar
                        elementId={elementId}
                        element={element}
                        isEditable={isEditable}
                        isEditingDocument={isEditing && currentEditorKey === editorKey}
                        dispatchStartEditing={dispatchStartEditing}
                        permissions={props.permissions}
                    />
                )}
                <div id="document-modal-content" className="content">
                    <DocumentModalTiptapEditor
                        element={element}
                        elementId={elementId}
                        textContent={props.textContent}
                        editorId={editorId}
                        editorKey={editorKey}
                        isEditable={isEditable}
                        isEditing={isEditing}
                        currentEditorKey={currentEditorKey}
                        dispatchStartEditing={dispatchStartEditing}
                    />
                </div>
                <div id="document-modal-side" />
            </div>
        </div>
    );
};

export default DocumentModalTiptap;
