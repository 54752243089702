import {
    LINE_DISPLAY_NAME,
    LINE_STYLE,
    LINE_MARKER_STYLE,
    LINE_EDGE,
    DEFAULT_LINE_HEIGHT,
    DEFAULT_LINE_WIDTH,
    LINE_WEIGHT,
} from './lineConstants';
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.LINE_TYPE,
        content: {
            [LINE_EDGE.start]: {
                x: 0,
                y: DEFAULT_LINE_HEIGHT,
            },
            [LINE_EDGE.end]: {
                x: DEFAULT_LINE_WIDTH,
                y: 0,
            },
            startStyle: LINE_MARKER_STYLE.NONE,
            endStyle: LINE_MARKER_STYLE.ARROW,
            lineStyle: LINE_STYLE.SOLID,
            lineWeight: LINE_WEIGHT.M,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.LINE_TYPE,
        objectConstructorFn,
        displayName: LINE_DISPLAY_NAME,
        ...params,
    });
