// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { constant, identity } from 'lodash';

// Utils
import { updateElement } from '../actions/elementActions';

// Actions
import { deselectAllElements } from '../selection/selectionActions';
import {
    createAndEditNewTaskList,
    goToPreviousTask,
    goToNextTask,
    indentTask,
    unIndentTask,
    moveTaskToTrash,
    onSubmitTaskTitle,
    joinToPreviousTask,
    joinNextTaskToThis,
} from './taskListActions';

// Components
import dispatchGetEditorStateConnect from '../../components/editor/store/components/dispatchGetEditorStateConnect';
import suggestDeleteTasksDecorator from './suggestion/deleteTasks/suggestDeleteTasksDecorator';
import suggestTitleDecorator from './suggestTitleDecorator';
import taskListDataDecorator from './taskListDataDecorator';
import newElementDecorator from '../newElementDecorator';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import TaskList from './TaskList';

// Constants
import { SHOW_TITLE_PROPERTY } from '../../../common/taskLists/taskListConstants';
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import elementSaveAspectRatioResizeDecorator from '../resizing/elementSaveAspectRatioResizeDecorator';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateAndEditNewElement: ({ location, currentBoardId, element, creationSource }) =>
        dispatch(createAndEditNewTaskList({ location, currentBoardId, element, creationSource })),
    dispatchMoveTaskToTrash: (props) => dispatch(moveTaskToTrash(props)),
    dispatchIndentTask: (props) => dispatch(indentTask(props)),
    dispatchUnIndentTask: (props) => dispatch(unIndentTask(props)),
    dispatchGoToPreviousTask: (props) => dispatch(goToPreviousTask(props)),
    dispatchGoToNextTask: (props) => dispatch(goToNextTask(props)),
    dispatchUpdateTitle: ({ id, title, transactionId }) =>
        dispatch(updateElement({ id, changes: { title }, transactionId })),
    dispatchHideTitle: ({ id, transactionId }) =>
        dispatch(updateElement({ id, changes: { [SHOW_TITLE_PROPERTY]: false }, transactionId })),
    dispatchDeselectTaskList: ({ stopEditing }) => {
        stopEditing && stopEditing();
        dispatch(deselectAllElements());
    },
    dispatchSubmitTaskTitle: ({ elementId }) => dispatch(onSubmitTaskTitle({ taskListId: elementId })),
    dispatchJoinToPreviousTask: (props) => dispatch(joinToPreviousTask(props)),
    dispatchJoinNextTaskToThis: (props) => dispatch(joinNextTaskToThis(props)),
    dispatchUpdateElement: ({ id, changes, transactionId }) => dispatch(updateElement({ id, changes, transactionId })),
});

@elementLineEdgeDropTarget
@taskListDataDecorator
@dispatchGetEditorStateConnect
@connect(null, mapDispatchToProps)
@newElementDecorator
@elementSaveAspectRatioResizeDecorator({
    getMinWidth: constant(ELEMENT_DEFAULT_WIDTH),
    getDefaultMaxWidth: constant(ELEMENT_DEFAULT_WIDTH),
})
@suggestTitleDecorator
@suggestDeleteTasksDecorator
class TaskListContainer extends React.Component {
    onStartBackspaceHandler = (args) => {
        const { dispatchJoinToPreviousTask } = this.props;
        dispatchJoinToPreviousTask({ ...this.props, ...args });
    };

    onEndDeleteHandler = (args) => {
        const { dispatchJoinNextTaskToThis } = this.props;
        dispatchJoinNextTaskToThis({ ...this.props, ...args });
    };

    moveToTrashHandler = ({ elementId, focusNextTask, forward }) => {
        const { dispatchMoveTaskToTrash } = this.props;
        dispatchMoveTaskToTrash({ ...this.props, elementId, focusNextTask, forward });
    };

    changeIndentationHandler = ({ elementId, increaseIndentation = true }) => {
        const { dispatchIndentTask, dispatchUnIndentTask } = this.props;
        return increaseIndentation
            ? dispatchIndentTask({ ...this.props, elementId })
            : dispatchUnIndentTask({ ...this.props, elementId });
    };

    goToPreviousTaskHandler = ({ elementId }) => {
        const { dispatchGoToPreviousTask } = this.props;
        dispatchGoToPreviousTask({ ...this.props, elementId });
    };

    goToNextTaskHandler = ({ elementId }) => {
        const { dispatchGoToNextTask } = this.props;
        dispatchGoToNextTask({ ...this.props, elementId });
    };

    deselectTaskList = () => {
        const { dispatchDeselectTaskList } = this.props;
        dispatchDeselectTaskList(this.props);
    };

    submitTaskTitle = () => {
        const { dispatchSubmitTaskTitle } = this.props;
        dispatchSubmitTaskTitle(this.props);
    };

    // This is used to mock an update to the Task List when children elements of Tasks are updated,
    // which is used to check whether or not to show feature suggestions.
    onTaskListUpdatedHandler = () => {
        const { element, dispatchUpdateElement } = this.props;
        dispatchUpdateElement({ id: getElementId(element), changes: {} });
    };

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge, createNewElement } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div>
                <TaskList
                    {...this.props}
                    submitTitle={this.submitTaskTitle}
                    deselectTaskList={this.deselectTaskList}
                    moveToTrashHandler={this.moveToTrashHandler}
                    changeIndentationHandler={this.changeIndentationHandler}
                    goToPreviousTaskHandler={this.goToPreviousTaskHandler}
                    goToNextTaskHandler={this.goToNextTaskHandler}
                    onStartBackspaceHandler={this.onStartBackspaceHandler}
                    onEndDeleteHandler={this.onEndDeleteHandler}
                    onTaskListUpdatedHandler={this.onTaskListUpdatedHandler}
                    createNewTaskList={createNewElement}
                />
            </div>,
        );
    }
}

TaskListContainer.propTypes = {
    element: PropTypes.object.isRequired,
    children: PropTypes.array,
    childElementIds: PropTypes.array,
    currentBoardId: PropTypes.string,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    isHovered: PropTypes.bool,
    isPresentational: PropTypes.bool,
    inList: PropTypes.string,
    canConnectLineEdge: PropTypes.bool,

    dispatchCreateTaskList: PropTypes.func,
    dispatchJoinToPreviousTask: PropTypes.func,
    dispatchJoinNextTaskToThis: PropTypes.func,
    createNewElement: PropTypes.func,

    dispatchMoveTaskToTrash: PropTypes.func,
    dispatchIndentTask: PropTypes.func,
    dispatchUnIndentTask: PropTypes.func,
    dispatchGoToPreviousTask: PropTypes.func,
    dispatchGoToNextTask: PropTypes.func,
    dispatchDeselectTaskList: PropTypes.func,
    dispatchSubmitTaskTitle: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
};

export default TaskListContainer;
