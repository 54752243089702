// Lib
import { throttle } from 'lodash';

// Utils
import {
    initialiseAmplitude,
    sendAmplitudeEvent,
    sendAmplitudeUserProperties,
    setAmplitudeUser,
} from './amplitudeService';
import { constructAmplitudeEvent } from '../../common/analytics/constructAmplitudeEvent';
import getClientConfig from '../utils/getClientConfig';
import { isClientAnalyticsEventType } from '../../common/analytics/amplitudeEventTypesUtil';
import {
    getCurrentContentLimit,
    getDefaultContentLimit,
    getEnabledExperiments,
    getGiftBonus,
    getMaxReferralBonus,
} from '../../common/users/utils/userPropertyUtils';
import localStorageService from '../utils/services/localStorage/localStorage';

// Constants
import { CURRENT_USER_SET } from '../user/currentUserConstants';
import { ELEMENT_MOVE_AND_UPDATE, ELEMENT_MOVE_MULTI, ELEMENT_UPDATE } from '../../common/elements/elementConstants';
import { AMPLITUDE_USER_PROPS } from '../../common/analytics/statsConstants';
import { GUEST_NAVIGATE, USER_NAVIGATE } from '../../common/users/userConstants';
import { TIMES } from '../../common/utils/timeUtil';
import { BATCH_ACTION_TYPE } from '../store/reduxBulkingMiddleware';

// Throttled events will be sent at most once every hour
const THROTTLED_EVENT_DELAY = TIMES.HOUR;

const getAmplitudeThrottleLocalStorageKey = (actionType) => `milanote.amplitude_limit.${actionType}`;

export const trackActionInAmplitude = (action) => {
    const amplitudeEventDetails = constructAmplitudeEvent({ event: action });

    sendAmplitudeEvent({
        eventType: amplitudeEventDetails.event_type,
        eventProperties: amplitudeEventDetails.event_properties,
        userProperties: amplitudeEventDetails.user_properties,
    });
};

const trackRateLimitedAction = (action) => {
    const { type } = action;

    // Check local storage for last navigation analytics event time
    const localStorageKey = getAmplitudeThrottleLocalStorageKey(type);
    const lastAnalyticsEventTimestamp = parseInt(localStorageService.getItem(localStorageKey), 10);

    // If greater than 4 hours ago, track the event
    if (lastAnalyticsEventTimestamp && lastAnalyticsEventTimestamp > Date.now() - 4 * TIMES.HOUR) return;

    localStorageService.setItem(localStorageKey, Date.now());

    return trackActionInAmplitude(action);
};

const throttleElementUpdateEvents = throttle(trackRateLimitedAction, THROTTLED_EVENT_DELAY);

const trackAmplitudeUser = (action) => {
    const { user } = action;

    setAmplitudeUser(user);

    sendAmplitudeUserProperties({
        userProperties: {
            [AMPLITUDE_USER_PROPS.DEFAULT_CONTENT_LIMIT]: getDefaultContentLimit(user),
            [AMPLITUDE_USER_PROPS.CURRENT_CONTENT_LIMIT]: getCurrentContentLimit(user),
            [AMPLITUDE_USER_PROPS.MAX_REFERRAL_BONUS]: getMaxReferralBonus(user),
            [AMPLITUDE_USER_PROPS.GIFT_BONUS]: getGiftBonus(user),
            [AMPLITUDE_USER_PROPS.EXPERIMENTS]: getEnabledExperiments(user),
        },
    });
};

/**
 * Tracks all batched actions in amplitude.
 */
const handleBatchedActions = (batchAction) => {
    const { payload } = batchAction;

    for (const action of payload) {
        trackActionInAmplitude(action);
    }
};

export default (store) => (next) => {
    const config = getClientConfig();
    initialiseAmplitude(config, store);

    // Initialise the throttled USER_NAVIGATE event so that the first event on the client won't get sent
    // (as it's already being sent on the server as part of the FETCH_ME event)
    localStorageService.setItem(getAmplitudeThrottleLocalStorageKey(USER_NAVIGATE), Date.now());

    return (action) => {
        if (action.remote) return;

        switch (action.type) {
            case BATCH_ACTION_TYPE:
                return handleBatchedActions(action);
            case USER_NAVIGATE:
            case GUEST_NAVIGATE:
                return trackRateLimitedAction(action);
            case CURRENT_USER_SET:
                return trackAmplitudeUser(action);
            case ELEMENT_UPDATE:
            case ELEMENT_MOVE_AND_UPDATE:
            case ELEMENT_MOVE_MULTI:
                return throttleElementUpdateEvents(action);
            default:
                return isClientAnalyticsEventType(action.type) ? trackActionInAmplitude(action) : undefined;
        }
    };
};
