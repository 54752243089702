import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ChevronLeftLargeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={9.551}
        height={16.963}
        className="Icon chevron-left-large"
        {...props}
    >
        <path
            fillOpacity={0.85}
            d="M0 8.477a.88.88 0 0 0 .273.644l7.745 7.568a.84.84 0 0 0 .634.264c.508 0 .899-.38.899-.889a.917.917 0 0 0-.264-.634l-7.11-6.953 7.11-6.954A.936.936 0 0 0 9.551.89.876.876 0 0 0 8.652 0a.869.869 0 0 0-.634.254L.273 7.832A.864.864 0 0 0 0 8.477Z"
        />
    </svg>
);
const Memo = memo(ChevronLeftLargeIcon);
export default Memo;
