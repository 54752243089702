// Lib
import { useEffect, useRef, useCallback } from 'react';

// Utils
import rafThrottle from '../../../../../common/utils/lib/rafThrottle';

const useDocumentPointerEventHandler = ({ onPointerDown, onPointerMove, onPointerUp }) => {
    const eventRefs = useRef({
        onPointerDown,
        onPointerMove,
        onPointerUp,
    });

    useEffect(() => {
        eventRefs.current.onPointerDown = onPointerDown;
        eventRefs.current.onPointerMove = onPointerMove;
        eventRefs.current.onPointerUp = onPointerUp;
    }, [onPointerDown, onPointerMove, onPointerUp]);

    const handleOnPointerDown = (event) => {
        eventRefs.current.onPointerDown && eventRefs.current.onPointerDown(event);
    };

    const handleOnPointerMove = useCallback(
        rafThrottle((event) => {
            eventRefs.current.onPointerMove && eventRefs.current.onPointerMove(event);
        }),
        [],
    );

    const handleOnPointerUp = useCallback((event) => {
        eventRefs.current.onPointerUp && eventRefs.current.onPointerUp(event);
    }, []);

    const attachListeners = () => {
        document.addEventListener('pointerdown', handleOnPointerDown);
        document.addEventListener('pointermove', handleOnPointerMove);
        document.addEventListener('pointerup', handleOnPointerUp);
        document.addEventListener('pointercancel', handleOnPointerUp);
    };

    const detachListeners = () => {
        document.removeEventListener('pointerdown', handleOnPointerDown);
        document.removeEventListener('pointermove', handleOnPointerMove);
        document.removeEventListener('pointerup', handleOnPointerUp);
        document.removeEventListener('pointercancel', handleOnPointerUp);
    };

    useEffect(() => {
        attachListeners();

        return () => {
            detachListeners();
        };
    }, []);
};

export default useDocumentPointerEventHandler;
