// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransitionGroup } from '../../../node_module_clones/react-transition-group';

// Styles
import './ElementTag.scss';

const ElementTag = (props) => {
    const { children, forceLeave, className, style } = props;

    let component = null;

    if (!forceLeave && children) {
        component = (
            <div className={classNames('ElementTag', className)} style={style}>
                {children}
            </div>
        );
    }

    return (
        <CSSTransitionGroup
            transitionName="pop"
            transitionAppear
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={700}
        >
            {component}
        </CSSTransitionGroup>
    );
};

ElementTag.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    forceLeave: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default ElementTag;
