// Lib
import { pick, reduce, add, isEmpty } from 'lodash';
import { negate } from 'lodash/fp';

// Actions
import { openPopup } from '../../components/popupPanel/popupActions';
import { increaseElementCount, decreaseElementCount, fetchElementDescendantsCount } from './elementCountActions';
import { fetchCurrentUserElementCounts, setCurrentUserContentLimitExceededFlag } from '../currentUserActions';

// Selectors
import {
    contentLimitExceededSelector,
    contentTotalCountSelector,
    contentTotalLimitSelector,
} from './elementCountSelector';
import { getElements } from '../../element/selectors/elementSelector';
import { getCurrentUserId, isUserSubscribed } from '../currentUserSelector';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { areElementCountsInitialised } from '../../app/initialisation/initialisationSelector';

// Utils
import { isContainer, isContent } from '../../../common/elements/utils/elementTypeUtils';
import { getElementType, getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import { isElementCreatedByUser } from '../../../common/elements/utils/elementUserUtils';
import { isMoveFromTrash, isMoveToOrFromTrash, isMoveToTrash } from '../../element/actions/elementMoveActionUtil';
import { prop, propIn } from '../../../common/utils/immutableHelper';
import { isUnlimited } from '../../../common/users/contentLimit';
import { reduceTypeCounts } from '../../../common/elements/elementCountsUtils';

// Constants
import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_TYPE,
} from '../../../common/elements/elementConstants';
import { ELEMENT_COUNT_INCREASE, ELEMENT_COUNT_DECREASE, ELEMENT_COUNT_LIMIT_POPUP } from './elementCountConstants';
import { BATCH_ACTION_TYPE } from '../../store/reduxBulkingMiddleware';

/**
 * Creations or deletions (of new elements) don't need to perform any requests to the server to find child elements
 * (as there won't be any).
 */
const createNewElementModifyCountMiddlewareFn = (transformCountElementFn) => (store, action) => {
    const { elementType } = action;
    // dispatch an action to reduce the element count based on the type of the element
    store.dispatch(transformCountElementFn({ [elementType]: 1 }, action.transactionId));
};

const increaseCountNewElement = createNewElementModifyCountMiddlewareFn(increaseElementCount);
const reduceCountNewElement = createNewElementModifyCountMiddlewareFn(decreaseElementCount);

const dispatchCountsUpdate = (transformCountElementFn, dispatch, countsMap, transactionId) => {
    if (reduce(countsMap, add, 0) <= 0) return;

    dispatch(transformCountElementFn(countsMap, transactionId));
};

const createModifyCountMiddlewareFn = (transformCountElementFn) => async (store, action) => {
    const state = store.getState();
    const currentUserId = getCurrentUserId(state);

    // If the action is a create or delete the || case will be used
    const moves = action.moves || [pick(action, ['id', 'location', 'from'])];
    const movedElementsOfInterest = moves
        .filter(isMoveToOrFromTrash)
        .map(({ id }) => state.getIn(['elements', id]))
        .filter(isElementCreatedByUser(currentUserId));

    const simpleElementMoves = movedElementsOfInterest.filter(negate(isContainer));
    const containerElementMoves = movedElementsOfInterest.filter(isContainer);

    // NOTE: This assumes that multi-moves will only ever all be to trash or from trash.
    const simpleCountsMap = simpleElementMoves.reduce(reduceTypeCounts, {});
    dispatchCountsUpdate(transformCountElementFn, store.dispatch, simpleCountsMap, action.transactionId);

    const containerElementPhysicalIds = containerElementMoves.map(getPhysicalId);

    if (!isEmpty(containerElementPhysicalIds)) {
        const containerCountsMap = await store.dispatch(fetchElementDescendantsCount(containerElementPhysicalIds));
        dispatchCountsUpdate(transformCountElementFn, store.dispatch, containerCountsMap, action.transactionId);
    }
};

const increaseCount = createModifyCountMiddlewareFn(increaseElementCount);

const reduceCount = createModifyCountMiddlewareFn(decreaseElementCount);

/**
 * When changing the type of an element, we want to decrement the count of the previous element type
 * and increment the count of the new element type.
 */
const handleSetElementType = (store, action) => {
    const { id, elementType, transactionId } = action;

    const state = store.getState();
    const elements = getElements(state);
    const originalElement = getElement(elements, id);
    const existingElementType = getElementType(originalElement);

    if (!existingElementType) return;

    if (existingElementType === elementType) return;

    const countsMap = {
        [existingElementType]: -1,
        [elementType]: 1,
    };

    store.dispatch(increaseElementCount(countsMap, transactionId));
};

/**
 * At the moment only handles creations & deletions in batch actions.
 */
const handleBatchAction = (store, action) => {
    const { payload } = action;

    const countsMap = {};

    payload.forEach((payloadAction) => {
        const { type, elementType } = payloadAction;

        let count = 0;

        if (type === ELEMENT_CREATE) {
            count = 1;
        } else if (type === ELEMENT_DELETE) {
            count = -1;
        }

        if (count !== 0) {
            countsMap[elementType] = (countsMap[elementType] || 0) + count;
        }
    });

    if (isEmpty(countsMap)) return;

    store.dispatch(increaseElementCount(countsMap, action.transactionId));
};

// only these actions will change the count
// otherwise, ignore the action
const actionTypePredicate = (action) => {
    switch (action.type) {
        case BATCH_ACTION_TYPE:
        case ELEMENT_DELETE:
        case ELEMENT_CREATE:
        case ELEMENT_COUNT_INCREASE:
        case ELEMENT_COUNT_DECREASE:
        case ELEMENT_SET_TYPE:
            return true;
        case ELEMENT_MOVE_MULTI:
            return action.moves.some(isMoveToOrFromTrash);
        default:
            return false;
    }
};

const remoteActionPredicate = (action, state) => {
    // if action is local, we care about it
    if (!action.remote) return true;

    const element = state.getIn(['elements', action.id]) || action;
    if (!element) return false;

    // NOTE: Again, we don't care about attached comments, so isContainer is fine for now.
    // if element is a container, we care about it even if it's isn't created by
    // the current user because it could contain elements we care about
    const elementType = prop('elementType', element);
    if (isContainer(elementType)) return true;

    // if element isn't a container, check the creator - we only care about
    // elements created by the current user
    const currentUserId = state.getIn(['app', 'currentUser', '_id']);
    const elementCreator = propIn(['meta', 'creator'], element);

    return elementCreator && elementCreator === currentUserId;
};

const actionPredicate = (action, state) => actionTypePredicate(action) && remoteActionPredicate(action, state);

const elementCountTrackingMiddleware = (store) => (next) => (action) => {
    if (action.isUndo || action.isRedo) return next(action);

    // NOTE: Here we're assuming that the move is either a deletion or addition, but it can't be both
    //  Need to change this in the future if this proves to be incorrect
    if (action.type === ELEMENT_MOVE_MULTI && action.moves.some(isMoveToTrash)) {
        reduceCount(store, action);
    }
    if (action.type === ELEMENT_MOVE_MULTI && action.moves.some(isMoveFromTrash)) {
        increaseCount(store, action);
    }

    if (action.type === ELEMENT_DELETE) {
        reduceCountNewElement(store, action);
    }

    if (action.type === ELEMENT_CREATE) {
        increaseCountNewElement(store, action);
    }

    if (action.type === ELEMENT_SET_TYPE) {
        handleSetElementType(store, action);
    }

    if (action.type === BATCH_ACTION_TYPE) {
        handleBatchAction(store, action);
    }

    return next(action);
};

const handleFreeUserAction = async (store, next, action) => {
    const { elementType, duplicate } = action;
    const { dispatch, getState } = store;

    // Elements that aren't content and aren't duplicates won't add to the content count
    if (!isContent(elementType) && !duplicate) return next(action);

    let state = getState();

    const userFlaggedContentLimitExceeded = contentLimitExceededSelector(state);
    const elementCountsAvailable = areElementCountsInitialised(state);

    if (!elementCountsAvailable) {
        // Fetch the counts just in case an app-init failure has caused no counts to be retrieved
        const userCountsPromise = dispatch(fetchCurrentUserElementCounts());

        // If element counts aren't available yet but the current user isn't flagged as exceeded, then just
        // allow them to add content without waiting
        if (!userFlaggedContentLimitExceeded) return next(action);

        // If the current count isn't fetched yet and the user's count was exceeded,
        // wait until it the count is fetched before allowing the update to occur
        await userCountsPromise;
        state = getState();
    }

    let totalContent = contentTotalCountSelector(state);
    let totalContentLimit = contentTotalLimitSelector(state);

    // No need to check content limits for unlimited users
    if (isUnlimited(totalContentLimit)) return next(action);

    if (totalContent >= totalContentLimit) {
        // Looks like we're over the limit, so check again to make sure that's still the case
        await dispatch(fetchCurrentUserElementCounts());
        state = getState();

        totalContent = contentTotalCountSelector(state);
        totalContentLimit = contentTotalLimitSelector(state);

        // If we're still over the content limit then don't allow the element to be created
        // and show the error popup
        if (totalContent >= totalContentLimit) {
            // User is over the limit but has not been flagged as such, so update the user flag
            if (!userFlaggedContentLimitExceeded) {
                store.dispatch(setCurrentUserContentLimitExceededFlag(true));
            }

            // Keep popup open on all events
            return store.dispatch(openPopup(ELEMENT_COUNT_LIMIT_POPUP, () => true));
        }
    }

    if (userFlaggedContentLimitExceeded) {
        // User is no longer over the limit, but they have been flagged as such, so update the user flag
        store.dispatch(setCurrentUserContentLimitExceededFlag(false));
    }

    return next(action);
};

const pushTo = (array) => (anAction) => array.push(anAction);

// this will intercept element create actions and stop them from occurring
// when the user's element count has been exceeded
const elementCreationInterceptMiddleware = (store) => (next) => (action) => {
    // If BATCH_ACTION_TYPE, run each action in the payload one by one, and re-batch the actions again afterwards
    if (action.type === BATCH_ACTION_TYPE) {
        const newPayload = [];

        // For each individual actions, run it through this function again, and only push valid actions to `newPayload`.
        //
        // For example, if a free user duplicate elements after reaching their content limit, the individual
        // actions won't be added to `newPayload`.
        action.payload.map(elementCreationInterceptMiddleware(store)(pushTo(newPayload)));

        action.payload = newPayload;

        if (action.payload.length === 0) return;

        return next(action);
    }

    if (action.remote || action.type !== ELEMENT_CREATE) return next(action);

    // If the user is subscribed there's no point running the logic below
    if (isUserSubscribed(store.getState())) return next(action);

    return handleFreeUserAction(store, next, action);
};

export default (store) => (next) => (action) => {
    const state = store.getState();

    // only count if the action is of interest
    if (!actionPredicate(action, state)) return next(action);

    return elementCreationInterceptMiddleware(store)(elementCountTrackingMiddleware(store)(next))(action);
};
