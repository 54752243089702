import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { clamp } from 'lodash';
import classNames from 'classnames';

import './FileMediaPlayerScrubberBar.scss';

export const SCRUBBER_AXIS = {
    HORIZONTAL: 'HORIZONTAL',
    VERTICAL: 'VERTICAL',
};

const FileMediaScrubberBar = ({ value, onDragStart, onDragEnd, onChange, axis = SCRUBBER_AXIS.HORIZONTAL }) => {
    const barRef = useRef();

    const [isDragging, setIsDragging] = useState(false);

    const isHoriz = axis === SCRUBBER_AXIS.HORIZONTAL;

    let barStyle;
    let scrubberStyle;

    if (isHoriz) {
        barStyle = { width: `${value * 100}%`, top: 0, left: 0 };
        scrubberStyle = { left: `${value * 100}%` };
    } else {
        barStyle = { height: `${value * 100}%`, bottom: 0, left: 0 };
        scrubberStyle = { top: `${(1 - value) * 100}%` };
    }

    const getScrubProgress = (event, barRect) =>
        isHoriz
            ? clamp((event.clientX - barRect.left) / barRect.width, 0, 1)
            : clamp(1 - (event.clientY - barRect.top) / barRect.height, 0, 1);

    const onBarClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const barRect = barRef.current.getBoundingClientRect();
        const mousePosition = getScrubProgress(event, barRect);

        onChange(mousePosition);
    };

    const onScrubberDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const barRect = barRef.current && barRef.current.getBoundingClientRect();
        if (!barRect) return;

        const mousePosition = getScrubProgress(event, barRect);

        onChange(mousePosition);
    };

    const onScrubberMouseUp = (event) => {
        event.preventDefault();
        event.stopPropagation();

        window.removeEventListener('mouseup', onScrubberMouseUp);
        window.removeEventListener('mousemove', onScrubberDrag);

        setIsDragging(false);
        onDragEnd && onDragEnd();
    };

    const onScrubberMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();

        window.addEventListener('mouseup', onScrubberMouseUp);
        window.addEventListener('mousemove', onScrubberDrag);

        setIsDragging(true);
        onDragStart && onDragStart();
    };

    // on unmount, clean up mouse listeners
    useEffect(
        () => () => {
            if (!isDragging) return;

            onDragEnd && onDragEnd();

            window.removeEventListener('mouseup', onScrubberMouseUp);
            window.removeEventListener('mousemove', onScrubberDrag);
        },
        [],
    );

    return (
        <div className="FileMediaPlayerScrubberBar" onClick={onBarClick}>
            <div
                ref={(c) => {
                    barRef.current = c;
                }}
                className={classNames('bar-container', axis.toLowerCase(), { dragging: isDragging })}
            >
                <div className="scrubber-bar-fill" style={barStyle} />
                <div
                    className={classNames('bar-scrubber', axis.toLowerCase())}
                    style={scrubberStyle}
                    onMouseDown={onScrubberMouseDown}
                />
            </div>
        </div>
    );
};

FileMediaScrubberBar.propTypes = {
    axis: PropTypes.string,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
};

export default FileMediaScrubberBar;
