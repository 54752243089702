export const POPUP_OPEN = 'POPUP_OPEN';
export const POPUP_CLOSE = 'POPUP_CLOSE';
export const POPUP_TOGGLE = 'POPUP_TOGGLE';

export const ARROW_DIR = {
    none: 'none',
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
};

export enum PopupSections {
    INTERNAL = 'INTERNAL',
    HEADER = 'HEADER',
    TOOLBAR = 'TOOLBAR',
    MODAL_HEADER = 'MODAL_HEADER',
}

export enum PopupIds {
    ADD_USERS_ADDITIONAL = 'add-users-additional',
    USER = 'user',
    BILLING_ALERT = 'billing-alert',
    ELECTRON_UPDATE_REQUIRED = 'electronUpdateRequired',
    SEARCH = 'search',
    EXPORT = 'export',
    HELP = 'help',
    NOTIFICATIONS = 'notifications',
    PUSH_SUGGESTION = 'push-suggestion',
    QUICK_NOTES = 'quickNotes',
    ELEMENT_CREATE = 'element-create',
    FILE_UPLOAD = 'fileUpload',
    IMAGE = 'image',
    ALIGNMENT = 'alignment',
    DISTRIBUTE = 'distribute',
    COLOR = 'color',
    ICON = 'icon',
    HYPERLINK = 'hyperlink',
    EXPORT_DOCUMENT = 'export-document',
    REMOTE_USERS = 'remoteUsers',
    INSPIRATION = 'inspiration',
    NOTIFICATION = 'notification',
    GUEST_SETTINGS = 'guest-settings',
    IMAGE_SEARCH = 'image-search',
    TRASH = 'trash',
    CURRENT_BOARD_MENU = 'current-board-menu',
    HEADER_EDITORS = 'header-editors',
    SHARE_MODAL_EDITORS = 'share-modal-editors',
    PUBLISHED_VIEWERS = 'published-viewers',
    DARK_MODE_SUGGESTION = 'dark-mode-suggestion',
    TOOLBAR_ERROR_INFO = 'toolbar-error-info',
    COLOR_DISPLAY = 'color-display',
    LINE_WEIGHT = 'lineWeight',
    REACTION_TOOL = 'reaction-tool',
    BLOCK_STYLE = 'block-style',
    BREADCRUMB_TRUNCATE_MENU = 'breadcrumb-truncate-menu',
    VIEW = 'view',
    EXPORT_BOARD = 'export-board',
    AI_ASSISTANT = 'ai-assistant',
    FORMULA_DROPDOWN = 'formula-dropdown',
    CELL_TYPE = 'cell-type',
    TABLE_CELL_ALIGNMENT_TOOL = 'table-cell-alignment-tool',
    CELL_TYPE_FORMATTING_OPTIONS = 'cell-type-formatting-options',
    TABLE_DATE_TIME_PICKER = 'table-date-time-picker',
}

export const POPUPS_BY_SECTION: { [key in PopupSections]: Array<PopupIds> } = {
    [PopupSections.INTERNAL]: [PopupIds.ADD_USERS_ADDITIONAL],
    [PopupSections.HEADER]: [
        PopupIds.USER,
        PopupIds.BILLING_ALERT,
        PopupIds.ELECTRON_UPDATE_REQUIRED,
        PopupIds.SEARCH,
        PopupIds.EXPORT,
        PopupIds.HELP,
        PopupIds.NOTIFICATIONS,
        PopupIds.PUSH_SUGGESTION,
        PopupIds.QUICK_NOTES,
        PopupIds.VIEW,
    ],
    [PopupSections.TOOLBAR]: [
        PopupIds.ELEMENT_CREATE,
        PopupIds.FILE_UPLOAD,
        PopupIds.IMAGE,
        PopupIds.ALIGNMENT,
        PopupIds.DISTRIBUTE,
        PopupIds.COLOR,
        PopupIds.ICON,
        PopupIds.HYPERLINK,
    ],
    [PopupSections.MODAL_HEADER]: [PopupIds.EXPORT_DOCUMENT],
};
