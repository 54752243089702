// Lib
import { last, initial } from 'lodash/fp';
// Be careful if the API ever changes
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import { ContentState } from 'draft-js';

export default (editorState) => {
    const selectedContent = getFragmentFromSelection(editorState);
    const currentContent = editorState.getCurrentContent();

    let blockArray = selectedContent.valueSeq().toArray();
    const lastBlock = last(blockArray);

    // Sometimes on a triple click / select the selection will be at the 0 index of the following line.
    // In this case we don't want to include the empty following line in the clipped text
    if (lastBlock.getLength() === 0) {
        blockArray = initial(blockArray);
    }

    return ContentState.createFromBlockArray(blockArray, currentContent.getEntityMap());
};
