import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AiBackIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon ai-back"
        {...props}
    >
        <path
            fill="#2F63F7"
            d="M13.146 7.146a.5.5 0 1 1 .708.708L9.707 12l4.147 4.146a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.708 0l-4.5-4.5a.5.5 0 0 1 0-.708l4.5-4.5z"
        />
    </svg>
);
const Memo = memo(AiBackIcon);
export default Memo;
