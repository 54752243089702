// Lib
import { Modifier, EditorState } from 'draft-js';

// Utils
import getLines from './getLines';
import getLineAnchorForOffset from './getLineAnchorForOffset';
import getNewLine from './getNewLine';
import getIndentation, { DEFAULT_INDENTATION } from './getIndentation';

// Constants
import { DraftRemovalDirection, EditorChangeType } from '../../../draftjsConstants';

/**
 * On a closing brace ") | } | ]" remove an indent if useful.
 */
export default (event, { getEditorState, setEditorState }) => {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    if (!selection.isCollapsed()) return;

    // This line has an indent at the start, the previous lines indent === this indent.
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    // Detect newline separation
    const newLineChar = getNewLine(blockText);

    const lines = getLines(blockText, newLineChar);

    if (lines.length < 2) return;

    const currentLineIndex = getLineAnchorForOffset(blockText, startOffset, newLineChar).line;
    const currentLine = lines[currentLineIndex];
    const prevLine = lines[currentLineIndex - 1];

    const currentLineIndent = getIndentation(currentLine);
    const prevLineIndent = getIndentation(prevLine);

    const shouldUnindent =
        currentLine.length > 0 &&
        currentLineIndent === prevLineIndent &&
        currentLineIndent.length === currentLine.length;

    if (!shouldUnindent) return;

    const indentationSizeToRemove = Math.min(DEFAULT_INDENTATION.length, currentLineIndent.length);

    // Remove the last two empty lines of the code block
    const rangeToRemove = selection.merge({
        focusKey: startKey,
        focusOffset: startOffset - indentationSizeToRemove,
        anchorKey: startKey,
        anchorOffset: startOffset,
        isBackward: true,
    });

    const newContentState = Modifier.removeRange(contentState, rangeToRemove, DraftRemovalDirection.backward);
    let newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);
    newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());

    return setEditorState(newEditorState);
};
