import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLineStyleDashIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-line-style-dash"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path stroke="#48505E" strokeDasharray="3 4" strokeLinecap="round" strokeWidth={2} d="M7.5 24.5 25 7" />
        </g>
    </svg>
);
const Memo = memo(ToolbarLineStyleDashIcon);
export default Memo;
