// Lib
import { prop } from '../utils/immutableHelper';

export const getUserNavigationUserId = prop('userId');
export const getUserNavigationElementId = prop('elementId');
export const getUserNavigationPermissionId = prop('permissionId');
export const getUserNavigationLastViewTimestamp = prop('lastView');
export const getUserNavigationLastEditTimestamp = prop('lastEdit');
export const getUserNavigationViewCount = prop('viewCount');

// Guest navigation
export const getGuestNavigationDeviceId = prop('deviceId');
