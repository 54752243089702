// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import handleEntityChange from '../../store/reducers/handleEntityChange';

// Actions
import { editorEntityChange } from '../../store/editorActions';
import { togglePopup } from '../../../popupPanel/popupActions';
import { setElementTemporaryEditorState } from '../../../../element/actions/elementEditorActions';

// Components
import dispatchGetEditorStateConnect from '../../store/components/dispatchGetEditorStateConnect';

// Constants
import { ENTITIES } from '../../richText/richTextConstants';
import { DRAFT_ENTITY_MUTABILITY } from '../../draftjsConstants';
import { PopupIds } from '../../../popupPanel/popupConstants';

const SELECTED_LINK_ENTITY_ACTION = editorEntityChange({
    entityType: ENTITIES.LINK,
    mutability: DRAFT_ENTITY_MUTABILITY.MUTABLE,
    data: { highlight: true },
    forceSelection: false,
    mergeData: true,
});

const mapDispatchToProps = (dispatch) => ({
    openHyperlinkPopup: () => dispatch(togglePopup(PopupIds.HYPERLINK)),
    dispatchSetTemporaryEditorState: ({ editorState }) => dispatch(setElementTemporaryEditorState({ editorState })),
});

export default (DecoratedComponent) => {
    @dispatchGetEditorStateConnect
    @connect(null, mapDispatchToProps)
    class HyperlinkOpenPopupDecorator extends React.Component {
        openHyperlinkPopup = () => {
            const { dispatchGetEditorState, dispatchSetTemporaryEditorState, openHyperlinkPopup } = this.props;

            const editorState = dispatchGetEditorState();
            const updatedEditorState = handleEntityChange(editorState, SELECTED_LINK_ENTITY_ACTION);
            dispatchSetTemporaryEditorState({ editorState: updatedEditorState });

            openHyperlinkPopup();
        };

        render() {
            return <DecoratedComponent {...this.props} openHyperlinkPopup={this.openHyperlinkPopup} />;
        }
    }

    HyperlinkOpenPopupDecorator.propTypes = {
        currentlyEditingId: PropTypes.string,
        openHyperlinkPopup: PropTypes.func,
        dispatchSetTemporaryEditorState: PropTypes.func,
        dispatchEditorLinkEntityChange: PropTypes.func,
        dispatchGetEditorState: PropTypes.func,
    };

    return HyperlinkOpenPopupDecorator;
};
