import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEmptyPlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        viewBox="0 0 48 48"
        className="Icon drawing-empty-placeholder"
        {...props}
    >
        <path
            fill="#BBBEC3"
            fillRule="evenodd"
            d="m37.309 6.583.158.125 1.836 1.54a3.393 3.393 0 0 1 .714 4.367l-.11.169-2.271 3.269c-.11.435-.305.855-.584 1.235l-.146.187-14.26 16.615a14.757 14.757 0 0 1-3.18 2.834l-.401.255-4.312 2.65a1.996 1.996 0 0 1-.75.273l-3.682 2.176a1.297 1.297 0 0 1-1.914-1.447l.04-.127 1.531-4.071c.004-.175.03-.35.081-.523l.059-.17 1.86-4.708a14.757 14.757 0 0 1 2.12-3.692l.299-.37 14.262-16.616a3.578 3.578 0 0 1 1.687-1.122l2.55-2.532a3.392 3.392 0 0 1 4.413-.317Zm-4.994 4.967a1.595 1.595 0 0 0-2.02.177l-.104.113-14.262 16.616-.287.355a12.757 12.757 0 0 0-1.628 2.734l-.175.422-1.861 4.708 1.728 1.45 4.312-2.65.384-.246a12.757 12.757 0 0 0 2.411-2.08l.3-.344 14.26-16.615.094-.123a1.596 1.596 0 0 0-.177-2.02l-.113-.104-2.74-2.3-.122-.093Zm2.096-3.326-.106.095-1.323 1.315c.196.092.385.206.564.338l.177.14 2.74 2.3c.283.236.52.508.71.803l1.092-1.573a1.393 1.393 0 0 0-.144-1.764l-.104-.097-1.836-1.54a1.392 1.392 0 0 0-1.77-.017Z"
        />
    </svg>
);
const Memo = memo(DrawingEmptyPlaceholderIcon);
export default Memo;
