import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={22}
        viewBox="0 0 32 22"
        className="Icon element-card"
        {...props}
    >
        <defs>
            <filter
                id="ElementCardIcon__a"
                width="131.2%"
                height="145.5%"
                x="-15.6%"
                y="-18.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <rect id="ElementCardIcon__b" width={32} height={22} rx={2} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use xlinkHref="#ElementCardIcon__b" fill="#000" filter="url(#ElementCardIcon__a)" />
            <use xlinkHref="#ElementCardIcon__b" fill="#FFF" />
            <path
                fill="#323B4A"
                d="M24.24 13c.385 0 .703.28.753.648l.007.102c0 .414-.33.75-.76.75H5.76a.753.753 0 0 1-.76-.75c0-.414.33-.75.76-.75h18.48zm2.003-4c.383 0 .7.28.75.648L27 9.75c0 .414-.346.75-.757.75H5.757A.752.752 0 0 1 5 9.75c0-.414.346-.75.757-.75h20.486zm0-4c.383 0 .7.28.75.648L27 5.75c0 .414-.346.75-.757.75H5.757A.752.752 0 0 1 5 5.75c0-.414.346-.75.757-.75h20.486z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementCardIcon);
export default Memo;
