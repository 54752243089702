// Utils
import replaceSelectionWithText from '../../customRichUtils/replaceSelectionWithText';
import hasSelectionInCodeBlock from './utils/hasSelectionInCodeBlock';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { DEFAULT_INDENTATION } from './utils/getIndentation';

const NEWLINE_REGEX = /\r\n?|\n|\r/g;
const TAB_REGEX = /\t/g;

export default (text, html, editorState, { setEditorState }) => {
    if (!text) return HANDLER_RETURN_VALUES.notHandled;
    if (!hasSelectionInCodeBlock(editorState)) return HANDLER_RETURN_VALUES.notHandled;

    const cleanedText = text
        // This ensures that the text stays within a single block, rather than separating into multiple blocks
        .replace(NEWLINE_REGEX, '\n')
        // For some reason, tab characters within the code block kill Draft.
        // So stripping them out and replacing them with spaces for now.
        .replace(TAB_REGEX, DEFAULT_INDENTATION);

    const updatedEditorState = replaceSelectionWithText(cleanedText)(editorState);

    setEditorState(updatedEditorState);

    return HANDLER_RETURN_VALUES.handled;
};
