// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import deepChildrenMod from './deepChildrenModCacheReducer';
import selectors from './selectorsCacheReducer';
import { initialElementSummariesState } from '../../element/reducers/elementSummariesReducer';

export default combineReducers({
    deepChildrenMod,
    selectors,
    // This is a special reducer that's handled via the wrappedElementSummariesReducer
    // because it needs access to the current state.
    // We need to add it here too, otherwise combineReducers will complain
    elementSummaries: (state = initialElementSummariesState) => state,
});
