import {
    ONE_TRUST_HIDE_BANNER,
    ONE_TRUST_SET_CONSENT_MODEL,
    ONE_TRUST_SET_STATUS,
    ONE_TRUST_SHOW_BANNER,
} from './oneTrustConstants';

export const setOneTrustStatus = (status) => ({
    type: ONE_TRUST_SET_STATUS,
    status,
});

export const setOneTrustConsentModel = (consentModel) => ({
    type: ONE_TRUST_SET_CONSENT_MODEL,
    consentModel,
});

export const showOneTrustBanner = () => ({
    type: ONE_TRUST_SHOW_BANNER,
});

export const hideOneTrustBanner = () => ({
    type: ONE_TRUST_HIDE_BANNER,
});
