// Lib
import jwtDecode from 'jwt-decode';
import { uniq, compact, chunk } from 'lodash/fp';

// Utils
import { http } from '../services/http';
import { isEmpty } from '../../../common/utils/immutableHelper';
import { canEditPermissions } from '../../../common/permissions/permissionUtil';
import { asyncResource } from '../services/http/asyncResource/asyncResource';

// Selectors
import {
    getClosestPermissionIdForElementIdSelector,
    getClosestPermissionIdsSelector,
    getMissingPermissionIdTokensSelector,
    getMultiplePermissionIdTokensSelector,
    getPermissionIdTokenSelector,
    getTokenPermissionIdsSelector,
} from './permissionsSelector';
import {
    currentBoardUserPermissionsSelector,
    publicEditAncestorBoardSelector,
    publishedAncestorBoardSelector,
} from './elementPermissionsSelector';
import { getCurrentBoard } from '../../reducers/currentBoardId/currentBoardIdSelector';

// Services
import { fetchMultiplePermissionIdTokens, fetchPermissionIdToken } from './permissionsService';
import { getElementId, getIsPublishedPasswordProtected } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { BOARD_PERMISSION_LOAD, PERMISSION_ID_ASSOCIATE_TO_BOARD, PERMISSION_TOKEN_SET } from './permissionsConstants';
import { ResourceTypes, RESOURCES_FETCHING } from '../services/http/asyncResource/asyncResourceConstants';
import { CUSTOM_HTTP_HEADERS } from '../../../common/utils/http/httpConstants';

export const associatePermissionIdToBoard = ({ permissionId, boardId }) => ({
    type: PERMISSION_ID_ASSOCIATE_TO_BOARD,
    permissionId,
    boardId,
});

export const handlePermissionTokenResponse = (result) => {
    const { token } = result;

    const decoded = token ? jwtDecode(token) : {};

    return { type: PERMISSION_TOKEN_SET, ...decoded, ...result };
};

/**
 * Retrieves a token for a given permission ID if a token hasn't already been retrieved for that permission ID.
 */
const getPermissionIdToken =
    ({ permissionId, elementId }) =>
    async (dispatch, getState) => {
        const state = getState();

        const existingTokenPermissionIds = getTokenPermissionIdsSelector(state);

        // If we already have a token for this permission ID, there's no need to fetch a new one
        if (existingTokenPermissionIds && existingTokenPermissionIds.includes(permissionId)) {
            return;
        }

        // NOTE: I moved this from boardService to here, because this is the point at which it's definitely
        //      required, and all invocations are currently from the boardService.
        //      If this assumption changes, then move this back into the boardService
        // Because we have to wait for the permission request to finish, adding a fetching state
        // to the board ID early, to prevent it from being fetched again
        dispatch({ type: RESOURCES_FETCHING, resource: ResourceTypes.boards, ids: [elementId] });

        const result = await fetchPermissionIdToken({ permissionId, elementId }).catch((err) => null);

        if (!result) return;

        dispatch(handlePermissionTokenResponse(result));

        return result;
    };

/**
 * Retrieves a token (if there is one) for the specified element ID.
 *
 * NOTE: This returns a single token, not an array of tokens.
 */
export const getTokenForElementIdThunk =
    ({ elementId, permissionIdOverride }) =>
    async (dispatch, getState) => {
        const state = getState();

        // Either use the overridden permissionId, or the permissionId associated to the board via state
        const permissionId = permissionIdOverride || getClosestPermissionIdForElementIdSelector()(state, { elementId });

        if (!permissionId) return;

        // If a permission ID is specified, fetch the permissions token before fetching anything else
        await dispatch(getPermissionIdToken({ permissionId, elementId }));

        const newState = getState();
        return getPermissionIdTokenSelector(newState, { permissionId });
    };

/**
 * Retrieves multiple tokens for the given permission IDs.
 */
export const getPermissionIdTokens =
    ({ permissionIds, elementIds }) =>
    async (dispatch, getState) => {
        const state = getState();

        const missingPermissionsIds = getMissingPermissionIdTokensSelector(state, { permissionIds });

        if (isEmpty(missingPermissionsIds)) return;

        const result = await fetchMultiplePermissionIdTokens({
            permissionIds: missingPermissionsIds,
            elementIds,
        }).catch((err) => null);

        if (!result) return;

        dispatch(handlePermissionTokenResponse(result));

        return result;
    };

/**
 * Fetches permission ID tokens in batches of 20.
 * If more than 20 permission IDs are fetched in a single token, the token can get quite large and can
 * result in 431 errors when sending the token in requests.
 * A token with 20 permission IDs in it is around 1000 characters.
 */
export const getBatchedPermissionIdTokens =
    ({ permissionIds, permissionIdToElementIdsMap }) =>
    (dispatch, getState) => {
        if (isEmpty(permissionIds)) return;

        // Batch the permission IDs into groups of 20
        const permissionIdBatches = chunk(20, permissionIds);

        // Fetch a token for each permission ID batch (the token will contain all the batch's permission IDs)
        return Promise.all(
            permissionIdBatches.map((permissionIdBatch) => {
                // Find the element IDs for the permission ID batch
                const batchElementIds = permissionIdBatch.reduce(
                    (acc, permissionId) => acc.concat(permissionIdToElementIdsMap[permissionId]),
                    [],
                );

                // Only send 20 elementIds per permissionId, to prevent too many elementIds to be sent along with the permission
                // request, which might cause the server to return a 413 Payload Too Large error.
                //
                // ElementIds are used by the permission token API to validate that the permission is being requested for the
                // element (or its ancestors) that the permission is associated to. So sending 20 elementIds would provide
                // enough validation for this purpose.
                //
                // A 413 Payload Too Large error could happen when retrieving permission tokens for boards in notifications,
                // when a user has a lot of unseen notifications from a large number of boards connected by a single
                // permissionId (e.g. permission created at a root board), resulting in a lot of elementIds to be associated
                // to a single permissionId.
                const elementIds = batchElementIds.slice(0, 20);
                return dispatch(getPermissionIdTokens({ permissionIds: permissionIdBatch, elementIds }));
            }),
        );
    };

const NO_TOKEN_KEY = 'NO_TOKEN';

/**
 * This thunk returns groups with a token and element IDs that require the token.
 * This is important because tokens can significantly increase the length of requests so we want
 * to only send requests with a single token, when possible.
 *
 * [{ token, elementIds }, ...]
 */
export const getTokenElementIdGroupsThunk =
    ({ elementIds, permissionIdsOverride }) =>
    async (dispatch, getState) => {
        const state = getState();

        // Get element IDs mapped to their permission IDs
        const permissionIds = permissionIdsOverride || getClosestPermissionIdsSelector(state, { elementIds });

        // Map permission IDs to their element IDs
        const permissionIdToElementIdsMap = elementIds.reduce((acc, elementId, index) => {
            const permissionId = permissionIds[index] || NO_TOKEN_KEY;

            acc[permissionId] = acc[permissionId] || [];
            acc[permissionId].push(elementId);

            return acc;
        }, {});

        const permissionIdsToFetch = uniq(compact(permissionIds));
        await dispatch(
            getBatchedPermissionIdTokens({
                permissionIds: permissionIdsToFetch,
                permissionIdToElementIdsMap,
            }),
        );

        // Get tokens for the permission ID groups
        const newState = getState();
        const tokens = getMultiplePermissionIdTokensSelector(newState, { permissionIds: permissionIdsToFetch });

        const tokenToElementIdsMap = tokens.reduce((acc, token, index) => {
            const permissionId = permissionIdsToFetch[index];

            acc[token] = acc[token] || { token, elementIds: [] };

            const permissionElementIds = permissionIdToElementIdsMap[permissionId];

            acc[token].elementIds = acc[token].elementIds.concat(permissionElementIds);

            return acc;
        }, {});

        const tokenElementIdGroups = Object.values(tokenToElementIdsMap);

        // Add the element IDs group that doesn't require tokens
        const noTokenElementIds = permissionIdToElementIdsMap[NO_TOKEN_KEY];
        if (noTokenElementIds) tokenElementIdGroups.push({ token: null, elementIds: noTokenElementIds });

        // Return tokens with the element IDs that they belong to
        return tokenElementIdGroups;
    };

export const boardPermissionLoad = ({ permissions }) => ({ type: BOARD_PERMISSION_LOAD, permissions });

/**
 * Retrieves the permission entries for the specified board.
 */
export const getPermissionsForBoard =
    ({ boardId }) =>
    async (dispatch) =>
        dispatch(
            asyncResource(
                ResourceTypes.boardPermissions,
                boardId,
            )(async () => {
                const tokens = await dispatch(getTokenForElementIdThunk({ elementId: boardId }));

                const response = await http({
                    url: `permissions/board/${boardId}`,
                    headers: {
                        [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: tokens,
                    },
                });

                const { permissions } = response.data;
                return dispatch(boardPermissionLoad({ permissions }));
            }),
        );

/**
 * If the user has edit permissions, this will retrieve any permission entries for the current board.
 */
export const getPermissionsForCurrentBoardIfRequired = () => async (dispatch, getState) => {
    const state = getState();
    const userPermissions = currentBoardUserPermissionsSelector(state);

    if (!canEditPermissions(userPermissions)) return;

    const elementIdsToFetch = [];

    const currentBoard = getCurrentBoard(state);
    const isPasswordProtected = getIsPublishedPasswordProtected(currentBoard);

    if (isPasswordProtected) elementIdsToFetch.push(getElementId(currentBoard));

    const publishedAncestorBoard = publishedAncestorBoardSelector(state);

    if (publishedAncestorBoard) elementIdsToFetch.push(getElementId(publishedAncestorBoard));

    const publicEditBoard = publicEditAncestorBoardSelector(state);

    if (publicEditBoard) elementIdsToFetch.push(getElementId(publicEditBoard));

    if (isEmpty(elementIdsToFetch)) return;

    return elementIdsToFetch.forEach((boardId) => dispatch(getPermissionsForBoard({ boardId })));
};
