// Utils
import { mergeElementCountMaps } from '../../../common/elements/elementCountsUtils';

// Selectors
import { getTokenElementIdGroupsThunk } from '../../utils/permissions/permissionsActions';

// Services
import { fetchElementDescendantCountsHttp } from './elementCountService';

// Constants
import { ELEMENT_COUNT_INCREASE, ELEMENT_COUNT_DECREASE, ELEMENT_COUNT_SET } from './elementCountConstants';

export const increaseElementCount = (counts, transactionId) => ({
    type: ELEMENT_COUNT_INCREASE,
    counts,
    transactionId,
});

export const decreaseElementCount = (counts, transactionId) => ({
    type: ELEMENT_COUNT_DECREASE,
    counts,
    transactionId,
});

export const setElementCount = (counts) => ({
    type: ELEMENT_COUNT_SET,
    counts,
});

export const fetchElementDescendantsCount = (elementIdsToFetch) => async (dispatch) => {
    const tokenGroups = await dispatch(getTokenElementIdGroupsThunk({ elementIds: elementIdsToFetch }));

    const countMaps = await Promise.all(
        tokenGroups.map(({ token, elementIds }) =>
            fetchElementDescendantCountsHttp(elementIds, token).then((data) => data.elementCounts),
        ),
    );

    return countMaps.reduce(mergeElementCountMaps, {});
};
