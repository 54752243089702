// Utils
import { isCurrentBrowserLogoutAction } from '../auth/authUtils';

// Actions
import { navigateToLogin } from '../reducers/navigationActions';

export default ({ dispatch }) =>
    (next) =>
    (action) => {
        const result = next(action);

        if (isCurrentBrowserLogoutAction(action)) {
            dispatch(navigateToLogin());
        }

        return result;
    };
