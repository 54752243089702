import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GestureNewNoteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={49}
        viewBox="0 0 35 49"
        className="Icon gesture-new-note"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#F37221"
                strokeWidth={1.406}
                d="M7.883 1.1c4.451-.7 8.628 2.327 9.334 6.765 1.266 10.726-14.003 13.13-16.115 2.543C.396 5.969 3.435 1.805 7.882 1.1z"
                opacity={0.6}
            />
            <path
                stroke="#F37221"
                strokeWidth={1.406}
                d="M7.46 4.85a4.61 4.61 0 0 1 5.972 2.587c2.015 5.754-6.106 8.94-8.569 3.365A4.586 4.586 0 0 1 7.46 4.85z"
            />
            <path
                fill="#323B4A"
                d="M34.808 32.106c-.126-1.577-1.69-5.238-2.254-6.23-.792-1.399-1.616-1.837-2.61-1.772-.96.06-1.348.615-1.105 1.626.028.124.165.558.373 1.063.004.015.004.029.004.029s-.307-.62-.754-1.288c-.12-.263-.25-.516-.366-.733-.92-1.73-1.969-2.235-3.177-2.16-.89.057-1.377.463-1.426 1.196-.215.996-.078 2.27-.078 2.27s-.341-1.349-.634-2.167c0-.007-.003-.01-.003-.018-.553-2.16-2.236-2.544-3.617-2.455-1.088.071-1.687.744-1.638 1.53 0 .139.01.292.039.455.148.958.173 2.968.173 2.968s-.328-.794-.493-1.189c-2.008-4.789-4.008-9.578-6.037-14.356a13.132 13.132 0 0 0-1.13-2.107c-.652-.982-1.5-.914-2.233.018-1.138 1.455-.123 5.013.278 6.32 1.254 4.073 2.532 8.144 3.937 12.164.564 1.616.677 3.235.641 4.875l-.01.11a5.098 5.098 0 0 1-.768 2.07c-.507.766-1.067.606-1.694-.067-1.014-1.088-1.137-3.081-1.208-3.874-.12-1.367.116-2.697-1.5-4.54-.828-.94-1.744-1.25-2.222-1.171-.511.082-.733 1.551-.712 3.437a175.71 175.71 0 0 1-.039 6.39c-.074 2.897.969 5.362 2.638 7.65 1 1.374 2.138 2.59 3.353 3.722l.004-.014S14.065 49.007 21.063 49c1.532.01 3.3-.15 5.325-.566 5.814-1.196 7.625-5.554 8.192-8.227.285-1.96.436-3.938.419-5.927a16.348 16.348 0 0 0-.19-2.174z"
            />
        </g>
    </svg>
);
const Memo = memo(GestureNewNoteIcon);
export default Memo;
