import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash/fp';

// Components
import FloatingPanel from '../../../components/tooltips/FloatingPanel';
import CurrencyFormatOptions from './CurrencyFormatOptions';
import DecimalFormatOptions from './DecimalFormatOptions';
import DateFormatOptions from './DateFormatOptions';
import PopupPanelMouseManager from '../../../components/popupPanel/PopupPanelMouseManager';

// Constants
import { CellTypeConstants, FormatOptions } from '../../../../common/table/CellTypeConstants';
import { TooltipAlignment, TooltipPositions } from '../../../components/tooltips/tooltipConstants';
import { PopupIds } from '../../../components/popupPanel/popupConstants';

const FormattingOptionsPopup = (props) => {
    const { showFormattingOptions, currentCellType, updateCellTypeFormat, sharedFormattingValues, locale } = props;
    const { formattingOptions } = CellTypeConstants[currentCellType];
    const shouldShowOption = (format) => Object.keys(formattingOptions).includes(format);

    return (
        <FloatingPanel
            className="CellTypeFormattingOptionsPopup light"
            selector={`.CellTypePopup .CellTypeButton.${kebabCase(currentCellType)}`}
            pollPosition
            offset={20}
            arrowWidth={0}
            distance={-4}
            alignment={TooltipAlignment.START}
            position={TooltipPositions.RIGHT}
        >
            <PopupPanelMouseManager
                {...props}
                visible={showFormattingOptions}
                popupId={PopupIds.CELL_TYPE_FORMATTING_OPTIONS}
                closePopup={() => {}}
            >
                <div className={'FormattingOptionsPopupContent'}>
                    {/*Decimals section*/}
                    {shouldShowOption(FormatOptions.DECIMALS) && (
                        <DecimalFormatOptions
                            sharedFormattingValues={sharedFormattingValues}
                            currentCellType={currentCellType}
                            updateCellTypeFormat={updateCellTypeFormat}
                            shouldShowOption={shouldShowOption}
                        />
                    )}

                    {/*Currency section*/}
                    {shouldShowOption(FormatOptions.CURRENCY) && (
                        <CurrencyFormatOptions
                            sharedFormattingValues={sharedFormattingValues}
                            updateCellTypeFormat={updateCellTypeFormat}
                            locale={locale}
                        />
                    )}

                    {/*DateTime section*/}
                    {shouldShowOption(FormatOptions.DATE_FORMAT) && (
                        <DateFormatOptions
                            sharedFormattingValues={sharedFormattingValues}
                            updateCellTypeFormat={updateCellTypeFormat}
                            locale={locale}
                        />
                    )}
                </div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

FormattingOptionsPopup.propTypes = {
    showFormattingOptions: PropTypes.bool,
    currentCellType: PropTypes.string,
    updateCellTypeFormat: PropTypes.func,
    sharedFormattingValues: PropTypes.object,
    locale: PropTypes.string,
};

export default FormattingOptionsPopup;
