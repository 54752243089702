// Constants
import {
    HYBRID_APP_CUSTOM_DOM_EVENTS,
    PENCIL_DOUBLE_TAP,
    TWO_FINGER_TAP,
    HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL,
} from './hybridAppStoreConstants';

/**
 * Certain hybrid app actions (such as PENCIL_DOUBLE_TAP) should be propagated as synthetic DOM events
 * so that components can receive them and interact with them as necessary.
 */
export default (store) => (next) => (action) => {
    if (action.type === PENCIL_DOUBLE_TAP) {
        const event = new CustomEvent(HYBRID_APP_CUSTOM_DOM_EVENTS.PENCIL_DOUBLE_TAP, {
            bubbles: true,
            detail: action.preferredTapAction,
        });
        document.documentElement.dispatchEvent(event);
    }

    if (action.type === TWO_FINGER_TAP) {
        const element = document.elementFromPoint(action.x, action.y);

        const event = new MouseEvent('contextmenu', {
            // The button needs to be specified to ensure marquee selection is not triggered when the context menu is triggered.
            // The marquee selector has a guard that checks for this button prop.
            button: 2,
            bubbles: true,
            clientX: action.x,
            clientY: action.y,
            // This is needed because the touch event on the hybrid app doesn't trigger a mouse down event,
            // which is expected by the normal handler.
            // See ElementContainer.js `onContextMenu` for more detail.
            detail: HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL,
        });

        element.dispatchEvent(event);
    }

    return next(action);
};
