// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { now } from '../../utils/react/propsComparisons';

// Actions
import { fetchElementModalElementAndAncestors, refreshParentBoardIfModified } from './elementModalActions';

// Selectors
import elementModalSelector from './elementModalSelector';
import {
    isAsyncEntityFetching,
    shouldFetchAsyncResource,
} from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Prop comparison functions
const socketHasReconnected = now('socketConnected');

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchElementAndAncestors: ({ elementId, permissionId }) =>
        dispatch(fetchElementModalElementAndAncestors(elementId, permissionId)),
    dispatchFetchParentBoardIfModified: (elementId) => dispatch(refreshParentBoardIfModified(elementId)),
});

export default (DecoratedComponent) => {
    @connect(elementModalSelector, mapDispatchToProps)
    class elementModalElementFetcher extends React.Component {
        componentWillMount() {
            const {
                elementId,
                urlPermissionId,
                currentElementResourceEntity,
                localCacheHydrationTimestamp,
                dispatchFetchElementAndAncestors,
            } = this.props;

            if (!elementId) return;

            if (shouldFetchAsyncResource(currentElementResourceEntity, localCacheHydrationTimestamp)) {
                dispatchFetchElementAndAncestors({ elementId, permissionId: urlPermissionId });
            }
        }

        componentWillReceiveProps(nextProps, _) {
            const { elementId, dispatchFetchParentBoardIfModified, currentElementResourceEntity } = nextProps;

            if (!elementId) return;

            // If the socket has reconnected, fetch the parent board if it has been modified.
            // This is to cover for cases where the currentBoardId is not accurately set for a modal page.
            if (!isAsyncEntityFetching(currentElementResourceEntity) && socketHasReconnected(this.props, nextProps)) {
                dispatchFetchParentBoardIfModified(elementId);
            }
        }

        render() {
            return <DecoratedComponent {...this.props} />;
        }
    }

    elementModalElementFetcher.propTypes = {
        elementId: PropTypes.string,
        urlPermissionId: PropTypes.string,
        element: PropTypes.object,

        currentElementResourceEntity: PropTypes.object,
        localCacheHydrationTimestamp: PropTypes.number,
        isElementFetched: PropTypes.bool,
        socketConnected: PropTypes.bool,

        dispatchFetchParentBoardIfModified: PropTypes.func,
        dispatchFetchElementAndAncestors: PropTypes.func,
    };

    return elementModalElementFetcher;
};
