/**
 * NOTE: This reducer intentionally uses POJOs rather than ImmutableJS objects.
 *  This is because the graphUtils.js works on POJOs and the selector for the board summaries
 *  virtual graph
 **/
// Lib
import { get, values, isEmpty } from 'lodash/fp';

// Utils
import { isBoard } from '../../../../common/elements/utils/elementTypeUtils';
import { getLocationParentId } from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import {
    BOARD_HIERARCHIES_LOAD,
    BOARD_HIERARCHIES_LOAD_ELEMENTS,
    BOARD_HIERARCHIES_REMOVE,
} from './boardHierarchiesConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_TYPE,
} from '../../../../common/elements/elementConstants';

// Types
import { AnyAction } from 'redux';
import { IdGraph } from '../../../../common/dataStructures/graphTypes';
import { ActionMove } from '../../../../common/actions/actionTypes';

const handleElementCreation = (state: IdGraph, action: AnyAction) => {
    if (!isBoard(action.elementType)) return state;

    const parentId = getLocationParentId(action);
    const virtualParentBoardIds = [parentId];

    return {
        ...state,
        [action.id]: virtualParentBoardIds,
    };
};

const handleElementMove = (state: IdGraph, action: AnyAction) => {
    let didUpdate = false;

    action.moves.forEach((elementMove: ActionMove) => {
        const sourceParentId = get(['from', 'parentId'], elementMove);
        const destinationParentId = get(['location', 'parentId'], elementMove);

        if (sourceParentId === destinationParentId) return;

        const existingEntry = state[elementMove.id];

        if (!existingEntry || !destinationParentId) return;

        // This logic simply ensures that any existing alias ID mappings remain as a virtual parent
        // board ID, but the old source parent ID will be replaced by the new destination parent ID
        const virtualParentBoardIds = existingEntry.reduce(
            (acc, boardId) => {
                if (boardId === sourceParentId) return acc;
                if (boardId === destinationParentId) return acc;

                acc.push(boardId);

                return acc;
            },
            [destinationParentId],
        );

        didUpdate = true;

        state[elementMove.id] = virtualParentBoardIds;
    });

    if (!didUpdate) return state;

    return {
        ...state,
    };
};

const handleDeletion = (state: IdGraph, action: AnyAction) => {
    if (!state[action.id]) return state;

    delete state[action.id];

    return {
        ...state,
    };
};

const handleElementLoad = (state: IdGraph, action: AnyAction) => {
    const stateUpdate: IdGraph = {};

    values(action.elements).forEach((element) => {
        if (!isBoard(element.elementType)) return;

        // If we already have the element in the hierarchy state, then no need to do anything
        if (state[element.id]) return;

        const parentId = getLocationParentId(element);

        stateUpdate[element.id] = [parentId];
    });

    if (isEmpty(stateUpdate)) return state;

    return {
        ...state,
        ...stateUpdate,
    };
};

const loadBoardHierarchies = (state: IdGraph, action: AnyAction) => {
    if (!action.boardHierarchies) return state;

    return {
        ...state,
        ...action.boardHierarchies,
    };
};

const handleRemoveBoardHierarchies = (state: IdGraph, action: AnyAction) => {
    const { boardIds } = action;

    if (!boardIds) return state;

    boardIds.forEach((boardId: string) => {
        delete state[boardId];
    });

    return { ...state };
};

export default (state: IdGraph = {}, action: AnyAction) => {
    switch (action.type) {
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(state, action);
        case ELEMENT_CREATE:
        case ELEMENT_SET_TYPE:
            return handleElementCreation(state, action);
        case ELEMENT_DELETE:
            return handleDeletion(state, action);
        case BOARD_HIERARCHIES_LOAD_ELEMENTS:
            return handleElementLoad(state, action);
        case BOARD_HIERARCHIES_LOAD:
            return loadBoardHierarchies(state, action);
        case BOARD_HIERARCHIES_REMOVE:
            return handleRemoveBoardHierarchies(state, action);
        default:
            return state;
    }
};
