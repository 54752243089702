// Utils
import handleConvertToTask from './handleConvertToTask';

// Constants
import { HANDLER_RETURN_VALUES } from '../../../draftjsConstants';

export default (char, editorState, eventTimeStamp, draftProps) => {
    if (char !== ' ') return HANDLER_RETURN_VALUES.notHandled;
    return handleConvertToTask(editorState, draftProps);
};
