// Lib
import { useEffect } from 'react';

/**
 * Sets a class on the body & html elements and removes them when it's changed.
 */
const useBodyClass = (bodyClass: string) => {
    useEffect(() => {
        if (!bodyClass) return;

        document.body.classList.add(bodyClass);

        return () => {
            document.body.classList.remove(bodyClass);
        };
    }, [bodyClass]);
};

export default useBodyClass;
