// Constants
import { METHODS } from '../../../utils/http/httpConstants';
import { TIMES } from '../../../utils/timeUtil';
import { APP_QUERY_PARAMS } from '../../../utils/urlConstants';

export default (mediaHttp) => ({
    fetchBoardPreview: ({ boardId, permissionId, maxAge, userToken, locale }) => {
        const requestConfig = {
            url: `preview/${boardId}`,
            method: METHODS.GET,
            timeout: TIMES.MINUTE,
            params: {
                [APP_QUERY_PARAMS.PERMISSION]: permissionId,
            },
        };

        // The server needs to supply this token separately, so the interceptor can't be used
        if (userToken) {
            requestConfig.headers = {
                Authorization: `Bearer ${userToken}`,
            };
        }

        if (maxAge) {
            requestConfig.params = {
                'max-age': maxAge,
            };
        }

        if (locale) {
            requestConfig.params = {
                ...requestConfig.params,
                locale,
            };
        }

        return mediaHttp(requestConfig);
    },
});
