// Lib
import React from 'react';

/**
 * This component is used when the element type does not have an appropriate modal component.
 */
const NoModalComponentElementModal = () => (
    <div className="NoModalComponentElementModal">
        <h2>This element cannot be displayed in full screen</h2>
        <div>
            <p>Sorry, the element you&apos;re trying to view cannot be displayed in full screen at this time.</p>
        </div>
    </div>
);

NoModalComponentElementModal.propTypes = {};

export default NoModalComponentElementModal;
