import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardTemplateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={9}
        viewBox="0 0 10 9"
        className="Icon board-template"
        {...props}
    >
        <path fill="#FFF" d="M1 0h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm0 3v5h8V3H1z" />
    </svg>
);
const Memo = memo(BoardTemplateIcon);
export default Memo;
