import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={10}
        viewBox="0 0 13 10"
        className="Icon board-link"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="nonzero"
            d="M8.125 3.283c-3.819 0-7.15 2.626-8.076 6.369L0 9.848a11.051 11.051 0 0 1 7.849-3.282h.276v3.282L13 4.924 8.125 0v3.283Z"
        />
    </svg>
);
const Memo = memo(BoardLinkIcon);
export default Memo;
