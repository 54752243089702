import { LOCATION_CHANGE } from 'react-router-redux';
import { DOCUMENT_MODAL_EDITOR_KEY } from '../../../../element/document/modal/documentModalConstants';
import { ELEMENT_MODAL_SUB_PATH } from '../../../../../common/utils/urlConstants';
import {
    HybridElementEditStartAction,
    HybridLocationChangeFileAction,
    HybridLocationChangeImageAction,
    HybridLocationChangeNavigationAction,
    HybridLocationChangeSketchAction,
} from '../hybridMiddlewareConstants';

import { AnyAction } from 'redux';
import { createHybridPresentModalPdfAction } from './createHybridActions/createHybridPresentModalPdfAction';
import { createHybridPresentModalImageAction } from './createHybridActions/createHybridPresentModalImageAction';
import { createHybridUserNavigateAction } from './createHybridActions/createHybridUserNavigateAction';
import { createHybridElementEditStartAction } from './createHybridActions/createHybridElementEditStartAction';
import { getElementType } from '../../../../../common/elements/utils/elementPropertyUtils';
import { getElement } from '../../../../element/utils/elementStoreUtils';
import { isElementId } from '../../../../../common/uid/idValidator';
import { createHybridPresentModalSketchAction } from './createHybridActions/createHybridPresentModalSketchAction';
import { ElementType } from '../../../../../common/elements/elementTypes';

/*  ### Reacting to @@router/LOCATION_CHANGE
 *
 *  Forwards all original properties of the original '@@router/LOCATION_CHANGE' action.
 *
 *  - Changes the type of the action depending on what the destination element is.
 *    - For example, if the element is:
 *      - An Image element, the resultant action type is: 'HYBRID_PRESENT_MODAL_IMAGE',
 *      - A File element, there is even more logic to determine what to do depending on
 *        the type of content in the file,
 *      - A board element, the resultant action type is: 'HYBRID_USER_NAVIGATE;
 *  - Adds the selected element as a property, with a property name to match.
 *    - For example, if the element is:
 *      - An Image element, the identifier is: 'imageElement',
 *      - A File element, the identifier is: 'fileElement'.
 */

// ### Creating a Hybrid "Present Modal" action

// Regular expression capturing the intermediate path, and also the (expected) destination
// element's ID.
//
// e.g. For '/e/<elementId>':
//      Matches: '/e/<elementId>',
//      Captures: the 'e' and the '<elementId>'.
const LOCATION_CHANGE_PATHNAME_REGEX_PATTERN = /^\/([a-zA-Z0-9]*)\/([a-zA-Z0-9]*)/;

const createHybridModalNavigationActionIfAble = (
    pathname: string,
    state: any,
    action: AnyAction,
):
    | HybridLocationChangeFileAction
    | HybridLocationChangeImageAction
    | HybridElementEditStartAction
    | HybridLocationChangeSketchAction
    | undefined => {
    const pathnameResult = pathname.match(LOCATION_CHANGE_PATHNAME_REGEX_PATTERN);
    // Check for THREE values in the results of the regex matches.
    // Index 0: The string that matched the regex pattern.
    // Index 1: The 1st capture - e.g. 'e' for element modals OR '<boardId>' for navigation changes.
    // Index 2: The 2nd capture - e.g. an <elementId> as a parameter to the 'e' route OR a <boardTitile> for navigation changes.
    if (pathnameResult !== null && pathnameResult.length === 3 && pathnameResult[1].length === 1) {
        const subpath = pathnameResult[1];
        if (subpath !== ELEMENT_MODAL_SUB_PATH) return;

        // For the 'e' (ELEMENT_MODAL_SUB_PATH) subpath, the second capture is an elementId.
        const elementId = pathnameResult[2];
        if (!isElementId(elementId)) return;
        const element = getElement(state, elementId);
        if (!element) return;
        switch (getElementType(element)) {
            case ElementType.FILE_TYPE:
                return createHybridPresentModalPdfAction(action, element);
            case ElementType.IMAGE_TYPE:
                return createHybridPresentModalImageAction(state, action, element);
            case ElementType.DOCUMENT_TYPE:
                return createHybridElementEditStartAction(state, action, elementId, DOCUMENT_MODAL_EDITOR_KEY);
            case ElementType.SKETCH_TYPE:
                return createHybridPresentModalSketchAction(action, element);
            default:
                break;
        }
    }
};

// ### Creating a Hybrid "User Navigate" action

// Regular expressiong capturing the navigation path including the board ID and the board's
// title
//
// e.g. For '/<boardId>/<boardTitle>':
//      Matches: '/<boardId>/<boardTitle>'
//      Captures: the '<boardId>' and the '<boardTitle>'
// e.g. For '/<boardId>/':
//      Matches: '/<boardId>/'
//      Captures: the '<boardId>'
const LOCATION_CHANGE_NAVIGATION_REGEX_PATTERN = /^\/([a-zA-Z0-9]*)(?:\/([a-zA-Z0-9-]*))?\/?/;

const createHybridUserNavigateActionIfAble = (
    pathname: string,
    action: AnyAction,
): HybridLocationChangeNavigationAction | undefined => {
    const navigationResult = pathname.match(LOCATION_CHANGE_NAVIGATION_REGEX_PATTERN);
    if (navigationResult !== null && action.payload?.action === 'PUSH') {
        const path = navigationResult[1];
        return createHybridUserNavigateAction(action, path);
    }
};

// Main Function

const getHybridLocationChangeAction = (
    state: any,
    action: AnyAction,
):
    | HybridLocationChangeFileAction
    | HybridLocationChangeImageAction
    | HybridLocationChangeNavigationAction
    | HybridElementEditStartAction
    | HybridLocationChangeSketchAction
    | undefined => {
    if (action.type !== LOCATION_CHANGE) return;
    const pathname: string | undefined = action.payload?.pathname;
    if (!pathname) return;
    return (
        createHybridModalNavigationActionIfAble(pathname, state, action) ??
        createHybridUserNavigateActionIfAble(pathname, action)
    );
};

export default (state: any, action: AnyAction): AnyAction | undefined => getHybridLocationChangeAction(state, action);
