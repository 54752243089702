// Lib
import { lowerFirst } from 'lodash/fp';

// Utils
import simpleTimeFormatter from '../../../../../common/utils/datetime/simpleTimeFormatter';
import friendlyDateFormatter from '../../../../../common/utils/datetime/friendlyDateFormatter';
import {
    getDueDate,
    getHasDueDateTime,
    getIsTaskComplete,
    getShowDueDate,
} from '../../../../../common/elements/utils/elementPropertyUtils';
import { getIsDueToday, getIsOverdue } from '../../../../../common/utils/timeUtil';
import friendlyTimeFormatter from '../../../../../common/utils/datetime/friendlyTimeFormatter';

export const getDueDatePopupId = (elementId) => `task-due-date-${elementId}`;

export const getElementIsOverdue = (element) => {
    if (getIsTaskComplete(element)) return false;

    const dueDate = new Date(getDueDate(element));
    const hasDueDateTime = getHasDueDateTime(element);

    return getIsOverdue(dueDate, hasDueDateTime);
};

export const getElementIsDueToday = (element) => {
    if (getIsTaskComplete(element)) return false;

    const dueDate = new Date(getDueDate(element));
    return getIsDueToday(dueDate);
};

export const getDueDatePillContent = (element) => {
    const dueDateValue = getDueDate(element);

    if (!getShowDueDate(element) || !dueDateValue) return 'Set due date…';

    const dueDate = new Date(dueDateValue);

    // Show the relative time if it's overdue and in the past
    if (getElementIsDueToday(element) && getElementIsOverdue(element)) {
        const timestamp = dueDate.getTime();
        return `Due ${lowerFirst(friendlyTimeFormatter({ timestamp, showSuffix: true }))}`;
    }

    const dateText = lowerFirst(
        friendlyDateFormatter({
            timestamp: dueDate,
            simpleFormat: 'd mmm',
            longFormat: 'd mmm yyyy',
        }),
    );

    const dueText = `Due ${dateText}`;

    return getHasDueDateTime(element) ? `${dueText} (${simpleTimeFormatter(dueDate)})` : dueText;
};

export const getDueDateClass = (element) => {
    if (!getShowDueDate(element) || !getDueDate(element)) return '';

    if (getIsTaskComplete(element)) return 'completed';

    if (getElementIsOverdue(element)) return 'overdue';

    if (getElementIsDueToday(element)) return 'due-today';

    return 'due-future';
};
