import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { Point } from '../../../../common/maths/geometry/pointTypes';
import { MentionAttrs } from '../../../../common/tiptap/extensions/mention/Mention';
import { suggestionEntryToTiptapMentionMark } from '../../../../common/tiptap/extensions/mention/tiptapDraftConversion';
import filterSuggestions from '../../../components/editor/plugins/mentions/filterSuggestions';
import { getUserMentionSuggestions } from '../../../components/editor/plugins/mentions/mentionsSelector';
import MentionSuggestionEntry from '../../../components/editor/plugins/mentions/MentionSuggestionEntry';

type Props = {
    currentSuggestionQuery: string;
    position: Point | null;
    onSelectUser: (data: MentionAttrs) => void;
    onPreviewUserChanged: (data: MentionAttrs) => void;
    keyEvents: EventTarget;
};

const getPortalMountPoint = () => document.getElementById('mention-mount-point');

export const TiptapMentionsSuggesterPopup = ({
    currentSuggestionQuery,
    onSelectUser,
    position,
    onPreviewUserChanged,
    keyEvents,
}: Props) => {
    const possibleSuggestions = useSelector(getUserMentionSuggestions);
    const filteredSuggestions = useMemo(
        () => filterSuggestions(currentSuggestionQuery, possibleSuggestions),
        [currentSuggestionQuery, possibleSuggestions],
    );

    const [focusedIndex, setFocusedIndex] = useState(0);

    // Clamp the selected index to the number of suggestions
    useEffect(() => {
        const max = filteredSuggestions.length - 1;
        if (focusedIndex <= max) return;
        if (max < 0) {
            setFocusedIndex(0);
            return;
        }
        setFocusedIndex(max);
    }, [filteredSuggestions]);

    // Attach key event handlers
    useEffect(() => {
        const handleKeyDown = (e: Event) => {
            if (!(e instanceof KeyboardEvent)) return;
            switch (e.key) {
                case 'ArrowDown':
                    setFocusedIndex((i) => Math.min(i + 1, filteredSuggestions.length - 1));
                    break;
                case 'ArrowUp':
                    setFocusedIndex((i) => Math.max(i - 1, 0));
                    break;
            }
        };
        keyEvents.addEventListener('keydown', handleKeyDown);
        return () => keyEvents.removeEventListener('keydown', handleKeyDown);
    }, []);

    // Update the suggestions manager any time there's a new selection (on load, on change)
    useEffect(() => {
        const suggestion = filteredSuggestions[focusedIndex];
        if (!suggestion) return;
        onPreviewUserChanged(suggestionEntryToTiptapMentionMark(suggestion));
    }, [filteredSuggestions, focusedIndex]);

    const portalMountPoint = useMemo(getPortalMountPoint, []);

    // shouldn't be possible for either of these to be null but it helps the type checker
    if (!portalMountPoint || !position) return null;

    // TODO-TIPTAP-MENTIONS: Should use a different component (& UX) on mobile
    return createPortal(
        <div
            className="MentionSuggestions"
            style={{
                left: position.x,
                top: position.y,
                // TODO-TIPTAP-MENTIONS: Animate this like MentionSuggestionEntry does
                transform: 'scale(1)',
                // TODO-TIPTAP-MENTIONS: This should be absolute like MentionSuggestionEntry?
                // (We're doing our positioning different somehow)
                position: 'fixed',
            }}
        >
            {filteredSuggestions.map((suggestion: any, i: number) => (
                <MentionSuggestionEntry
                    key={suggestion.id}
                    mention={suggestion}
                    className={i === focusedIndex ? 'focused' : ''}
                    searchValue={currentSuggestionQuery}
                    onMouseEnter={() => setFocusedIndex(i)}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSelectUser(suggestionEntryToTiptapMentionMark(suggestion));
                        return false;
                    }}
                />
            ))}
        </div>,
        portalMountPoint,
    );
};
