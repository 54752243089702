// Services
import { getDeviceId } from '../../../device/deviceService';
import { getSessionId } from '../../../device/deviceSessionService';

// Selectors
import { getClientIdSelector, getClientTickSelector } from '../../../user/clientDataSelector';

// Constants
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';
import { ELEMENT_MOVE_MULTI } from '../../../../common/elements/elementConstants';

/**
 * Common data to add to actions before sending them to the socket server.
 */
const prepareGeneralIndividualAction = (state, action) => ({
    ...action,
    user: {
        _id: state.getIn(['app', 'currentUser', '_id']),
        clientId: getClientIdSelector(state),
        clientTick: getClientTickSelector(state),
    },
    deviceId: getDeviceId(),
    sessionId: getSessionId(),
    undoRedoAction: undefined,
    transactionId: undefined,
    isUndo: undefined,
    isRedo: undefined,
});

/**
 * Remove the initial measurements from the action sent to the server.
 * They're not currently used by the server so there's no point sending the data.
 */
const prepareElementMoveMultiAction = (state, action) => {
    action.initialMeasurements = undefined;

    return prepareGeneralIndividualAction(state, action);
};

/**
 * Cleans up each individual action before sending it to the server.
 */
const prepareIndividualAction = (state, action) => {
    switch (action.type) {
        case ELEMENT_MOVE_MULTI:
            return prepareElementMoveMultiAction(state, action);
        default:
            return prepareGeneralIndividualAction(state, action);
    }
};

/**
 * Only sync batched actions that are marked as synced.
 */
const transformActionContent = (state, action) => {
    if (action.type !== BATCH_ACTION_TYPE) return action;

    // Only send synced actions
    const payload = action.payload.filter((a) => a.sync).map((a) => prepareIndividualAction(state, a));

    return {
        ...action,
        sync: true,
        payload,
    };
};

export const createPrepareActionForServerFn = (store) => (action) => {
    const state = store.getState();
    const transformedAction = transformActionContent(state, action);
    return prepareIndividualAction(state, transformedAction);
};
