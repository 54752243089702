// Lib
import { createSelector } from 'reselect';

// Utils
import { getCurrentBoardVisibleDescendants } from './currentBoardSelector';
import { isImage } from '../../../common/elements/utils/elementTypeUtils';

export const getCurrentBoardImagesSelector = createSelector(getCurrentBoardVisibleDescendants, (visibleDescendants) => {
    return visibleDescendants.filter(isImage);
});
