// Constants
import { STYLE_COMMANDS } from '../../richText/richTextConstants';
import {
    CUSTOM_SELECTION_STYLE,
    CUSTOM_TEXT_COLOR_HEX_PREFIX,
    TEXT_COLOR_PRESETS,
    TEXT_BACKGROUND_PRESETS,
} from './textColorConstants';

const TEXT_COLOUR_PRESET_STYLE_MAP = {
    // This is a legacy style - it has been superseded by the BG-green style
    [STYLE_COMMANDS.HIGHLIGHT]: {
        background: 'var(--ws-text-highlight-background-color)',
        color: 'var(--ws-text-highlight-color)',
    },
    [CUSTOM_SELECTION_STYLE]: { background: 'var(--ws-text-pseudo-select-color)' },
    [TEXT_COLOR_PRESETS.BLUE]: { color: 'var(--ws-text-color-blue)' },
    [TEXT_COLOR_PRESETS.GREEN]: { color: 'var(--ws-text-color-green)' },
    [TEXT_COLOR_PRESETS.ORANGE]: { color: 'var(--ws-text-color-orange)' },
    [TEXT_COLOR_PRESETS.RED]: { color: 'var(--ws-text-color-red)' },
    [TEXT_COLOR_PRESETS.GREY]: { color: 'var(--ws-text-color-grey)' },
    [TEXT_BACKGROUND_PRESETS.DARK_GREY]: {
        background: 'var(--ws-text-background-color-dark-grey)',
        color: 'var(--ws-text-background-color-dark-grey-text)',
    },
    [TEXT_BACKGROUND_PRESETS.BLUE]: {
        background: 'var(--ws-text-background-color-blue)',
        color: 'var(--ws-text-highlight-color)',
    },
    [TEXT_BACKGROUND_PRESETS.GREEN]: {
        background: 'var(--ws-text-background-color-green)',
        color: 'var(--ws-text-highlight-color)',
    },
    [TEXT_BACKGROUND_PRESETS.ORANGE]: {
        background: 'var(--ws-text-background-color-orange)',
        color: 'var(--ws-text-highlight-color)',
    },
    [TEXT_BACKGROUND_PRESETS.PINK]: {
        background: 'var(--ws-text-background-color-pink)',
        color: 'var(--ws-text-highlight-color)',
    },
    [TEXT_BACKGROUND_PRESETS.GREY]: {
        background: 'var(--ws-text-background-color-grey)',
        color: 'var(--ws-text-highlight-color)',
    },
};

export default (styleSet) => {
    if (!styleSet || styleSet.length < 1) return null;

    return styleSet.reduce((acc, styleName) => {
        const presetStyle = TEXT_COLOUR_PRESET_STYLE_MAP[styleName];
        if (presetStyle) {
            return {
                ...acc,
                ...presetStyle,
            };
        }

        if (!styleName.startsWith(CUSTOM_TEXT_COLOR_HEX_PREFIX)) return acc;

        const colorHex = styleName.substr(-6);
        return {
            ...acc,
            color: `#${colorHex}`,
        };
    }, null);
};
