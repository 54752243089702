import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PaperIcon from './PaperIcon';

import './ElementPaperIcon.scss';

const getPaperIconGridSize = ({ inList, documentMode }) => {
    if (documentMode) return { width: 4.0, height: 5 };
    if (inList) return { width: 4.8, height: 6 };
    return { width: 5.6, height: 7.2 };
};

const ElementPaperIcon = ({ children, iconElement, style, gridSize, inList, documentMode, className }) => {
    const { width, height } = getPaperIconGridSize({ inList, documentMode });

    return (
        <div className={classNames('ElementPaperIcon', className)} style={style}>
            <div className="drag-preview-shadow" />

            <PaperIcon gridSize={gridSize} width={width} height={height} />

            {children}

            {iconElement && <div className="icon">{iconElement}</div>}
        </div>
    );
};

ElementPaperIcon.propTypes = {
    className: PropTypes.string,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    documentMode: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    iconElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ElementPaperIcon;
