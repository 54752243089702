import { useEffect, useState } from 'react';
import * as productService from '../productService';

import { CheckoutProduct } from '../../../../../common/payment/products/productTypes';

import globalLogger from '../../../../logger';
import { uniqBy } from 'lodash';
import { CheckoutStoredState } from './useCheckoutReducer';

const logger = globalLogger.createChannel('checkout');

const uniqueById = (products: CheckoutProduct[]) => uniqBy(products, '_id');

const useProductFetching = (
    productId: string | undefined,
    updateState: (state: Partial<CheckoutStoredState>) => void,
) => {
    const [products, setProducts] = useState<CheckoutProduct[]>([]);
    const [productsFetchError, setProductsFetchError] = useState<string | undefined>(undefined);

    const addProducts = (newProducts: CheckoutProduct[]) => {
        setProducts((prevProducts) => uniqueById([...prevProducts, ...newProducts]));
    };

    const fetchPublicCheckoutProducts = async () => {
        try {
            const products = await productService.fetchPublicCheckoutProducts();

            addProducts(products);
        } catch (error) {
            logger.error(`Error fetching products`, error);
            setProductsFetchError(`Something went wrong fetching products`);
        }
    };

    const fetchCheckoutProduct = async (productId: string) => {
        try {
            const product = await productService.fetchCheckoutProduct(productId);
            if (!product) throw new Error(`Product not found`);
            addProducts([product]);
        } catch (error) {
            logger.error(`Error fetching product`, productId, error);
            setProductsFetchError(`Something went wrong fetching product ${productId}`);

            updateState({
                selectedProductId: undefined,
                selectedPriceId: undefined,
            });
        }
    };

    // on mount, fetch the publicly available products
    useEffect(() => {
        fetchPublicCheckoutProducts();
    }, []);

    // fallback fetch for product, if it's not returned by the default products list
    useEffect(() => {
        // wait for products to be fetched, so it won't trigger two simultaneous fetches
        if (!products.length) return;

        // if we already have the product, no need to fetch it again
        const product = products.find((product) => product._id === productId);
        if (product) return;

        if (!productId) return;
        fetchCheckoutProduct(productId);
    }, [productId, products]);

    return [products, productsFetchError] as const;
};

export default useProductFetching;
