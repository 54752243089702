// Lib
import io from 'socket.io-client';
import { get } from 'lodash/fp';

// Utils
import logger from '../../../logger/logger';
import platformSingleton from '../../../platform/platformSingleton';
import {
    isPlatformModernMobileApp,
    isPlatformLegacyMobileApp,
    isPlatformElectronMac,
} from '../../../platform/utils/platformDetailsUtils';

// Config
import getClientConfig from '../../../utils/getClientConfig';

// Constants
import { SOCKET_EVENT_NAMES } from '../../../../common/utils/socket/socketConstants';

const clientConfig = getClientConfig();
const websocketUrl = get(['collaboration', 'url'], clientConfig);

const createSocketClient = ({ channel, userId }) => {
    logger.info('%c Creating collaboration socket for channel', 'color: blue', websocketUrl, channel);

    const query = `channel=${channel}&userId=${userId}`;

    if (
        isPlatformElectronMac(platformSingleton) ||
        isPlatformLegacyMobileApp(platformSingleton) ||
        isPlatformModernMobileApp(platformSingleton)
    ) {
        return io(websocketUrl.replace('https', 'wss').replace('http', 'ws'), {
            query,
            transports: ['websocket', 'polling'],
        });
    }

    return io(websocketUrl, {
        query,
        transports: ['websocket', 'polling'],
    });
};

export default class LiveCollaborationSocketManager {
    constructor() {
        this.socketClient = null;
        this.store = null;
        this.dispatch = null;
    }

    connectSocket = ({ channel, userId }) => {
        // If already connected, just make sure the channel is correct
        if (this.socketClient && this.socketClient.connected) return this.emitChannelUpdate({ channel });

        // If already created, but not connected, just reconnect
        if (this.socketClient && this.socketClient.disconnected) {
            this.socketClient.connect();
            this.emitChannelUpdate({ channel });
            return;
        }

        // Otherwise create the socket client and attach the event listener
        this.socketClient = createSocketClient({ channel, userId });
        this.emitChannelUpdate({ channel });

        this.socketClient.on(SOCKET_EVENT_NAMES.ACTION, this.dispatch);
    };

    updateChannel = ({ channel }) => {
        if (!this.socketClient || this.socketClient.disconnected) return this.connectSocket({ channel });

        this.emitChannelUpdate({ channel });
    };

    emitChannelUpdate = ({ channel }) => this.socketClient.emit(SOCKET_EVENT_NAMES.UPDATE_CHANNELS, { channel });

    disconnectSocket = () => {
        this.socketClient && this.socketClient.io.disconnect();
    };

    emitAction = (action) => {
        if (!this.socketClient) return;

        this.socketClient.emit(SOCKET_EVENT_NAMES.ACTION, action);
    };

    subscribeToLiveCollaborationStore(store) {
        this.store = store;
        this.dispatch = this.store.dispatch.bind(this.store);
    }
}
