// Lib
import { flatMap, cloneDeep } from 'lodash/fp';

// Utils
import { getChildren } from '../../../../common/elements/utils/elementTraversalUtils';
import { propIn } from '../../../../common/utils/immutableHelper';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { sortByScoreProperty } from '../../../../common/elements/utils/elementSortUtils';

export default ({ dropResult, measurementsMap, elements }) => {
    // Sort the dragged task lists based on their position
    const orderedTaskLists = dropResult.acceptedElementIds.sort(
        (idA, idB) => propIn([idA, 'top'], measurementsMap) - propIn([idB, 'top'], measurementsMap),
    );

    // Flat map the task lists to their children
    const orderedTasks = flatMap(
        (taskListId) => getChildren(elements, taskListId).toList().sort(sortByScoreProperty).toArray(),
        orderedTaskLists,
    );

    // Return moves in the form { id, location (dropResult.location)
    return orderedTasks.map((task) => ({
        id: getElementId(task),
        location: cloneDeep(dropResult.location),
    }));
};
