// Lib
import { createSelector } from 'reselect';
import { head } from 'lodash/fp';

// Utils
import { isTask, isTaskList } from '../../../../common/elements/utils/elementTypeUtils';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { length } from '../../../../common/utils/immutableHelper';

// Selectors
import { getCurrentlyEditingElement } from '../../../element/selection/currentlyEditingSelector';
import { elementGraphSelector } from '../../../element/selectors/elementGraphSelector';
import { getSelectedElements } from '../../../element/selection/selectedElementsSelector';
import { getElements } from '../../../element/selectors/elementsSelector';

export const getSelectedTaskIdSelector = createSelector(
    getSelectedElements,
    getCurrentlyEditingElement,
    elementGraphSelector,
    (selectedElements, currentlyEditingElement, elementGraph) => {
        if (!!currentlyEditingElement && isTask(currentlyEditingElement)) return getElementId(currentlyEditingElement);

        const firstSelectedElement = selectedElements.first();
        const firstSelectedElementId = getElementId(firstSelectedElement);
        const firstSelectedElementChildrenIds = elementGraph[firstSelectedElementId];

        // This is a bit of a hack to work with Task Lists better
        const isOnlyTaskListSelectedWithSingleTask =
            selectedElements.size === 1 &&
            isTaskList(firstSelectedElement) &&
            length(firstSelectedElementChildrenIds) === 1;

        return isOnlyTaskListSelectedWithSingleTask ? head(firstSelectedElementChildrenIds) : null;
    },
);

export const getSelectedTaskSelector = createSelector(
    getSelectedTaskIdSelector,
    getElements,
    (selectedTaskId, elements) => elements.get(selectedTaskId),
);
