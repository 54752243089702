// Lib
import { LOCATION_CHANGE } from 'react-router-redux';

// Constants
import {
    RICH_MEDIA_STATE,
    RICH_MEDIA_EMBEDDING,
    RICH_MEDIA_ERROR,
    RICH_MEDIA_READY,
    RICH_MEDIA_RETURN_TO_PREVIEW,
} from './richMediaConstants';
import { DRAG_START } from '../../reducers/draggingConstants';

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case RICH_MEDIA_EMBEDDING:
            return RICH_MEDIA_STATE.EMBEDDING;
        case RICH_MEDIA_READY:
            return RICH_MEDIA_STATE.READY;
        case RICH_MEDIA_ERROR:
            return RICH_MEDIA_STATE.ERROR;
        case RICH_MEDIA_RETURN_TO_PREVIEW:
            return RICH_MEDIA_STATE.PREVIEW;
        case DRAG_START:
        case LOCATION_CHANGE:
            return initialState;
        default:
            return state;
    }
};
