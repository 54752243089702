import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileTabAddIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon mobile-tab-add"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={12} cy={12} r={12} fill="#F4511C" />
            <path
                fill="#FFF"
                d="M12 5.25a.75.75 0 0 1 .743.648L12.75 6v5.25H18a.75.75 0 0 1 .102 1.493L18 12.75h-5.25V18a.75.75 0 0 1-1.493.102L11.25 18v-5.25H6a.75.75 0 0 1-.102-1.493L6 11.25h5.25V6a.75.75 0 0 1 .75-.75z"
            />
        </g>
    </svg>
);
const Memo = memo(MobileTabAddIcon);
export default Memo;
