// Lib
import React, { useCallback } from 'react';
import { compose } from '../../../../node_module_clones/recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import shouldAutoEmbed from '../utils/shouldAutoEmbed';
import { getImageProp, getMedia } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import richMediaConnectDecorator from '../richMediaConnectDecorator';
import RichMediaPreviewImage from './RichMediaPreviewImage';
import RichMediaContent from './RichMediaContent';
import RichMediaDragPreview from './RichMediaDragPreview';

import previewImageDropTargetDecorator from '../../dnd/elementDropTargets/previewImageDropTargetDecorator';

const enhance = compose(richMediaConnectDecorator, previewImageDropTargetDecorator());

const RichMedia = (props) => {
    const {
        element,
        className,
        showPreview,
        showRichMedia,
        embeddingRichMedia,
        isPresentational,
        ContentComponent = RichMediaContent,
        PreviewComponent,
        connectFileDropTarget,
        attachment,
        dispatchRichMediaEmbedding,
    } = props;

    const mediaData = getMedia(element);
    const imageData = getImageProp(element);

    const onPreviewClick = useCallback(
        (event) => {
            event.stopPropagation();
            dispatchRichMediaEmbedding();
        },
        [dispatchRichMediaEmbedding],
    );

    let RenderedPreviewComponent = PreviewComponent;
    if (!RenderedPreviewComponent) {
        RenderedPreviewComponent = shouldAutoEmbed(element) ? RichMediaDragPreview : RichMediaPreviewImage;
    }

    const renderContent = (
        <div className={classNames('RichMedia', className)}>
            <ContentComponent {...props} show={showRichMedia && !isPresentational} mediaData={mediaData} />
            <RenderedPreviewComponent
                {...props}
                show={showPreview || isPresentational}
                showLoader={embeddingRichMedia}
                mediaData={mediaData}
                imageData={imageData}
                onClick={onPreviewClick}
                attachment={attachment}
            />
        </div>
    );

    return connectFileDropTarget ? connectFileDropTarget(renderContent) : renderContent;
};

RichMedia.propTypes = {
    element: PropTypes.object.isRequired,
    PreviewComponent: PropTypes.elementType,
    ContentComponent: PropTypes.elementType,
    showPreview: PropTypes.bool,
    embeddingRichMedia: PropTypes.bool,
    showRichMedia: PropTypes.bool,
    dispatchRichMediaEmbedding: PropTypes.func,
    className: PropTypes.string,
    isPresentational: PropTypes.bool,
    attachment: PropTypes.object,
    connectFileDropTarget: PropTypes.func,
};

export default enhance(RichMedia);
