import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationFileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-file"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFF"
                d="M9.454 0c.498 0 .98.19 1.338.533l3.13 2.987c.368.352.578.835.578 1.342v8.388c0 1.53-1.279 2.75-2.833 2.75H4.333C2.78 16 1.5 14.78 1.5 13.25V2.75C1.5 1.22 2.779 0 4.333 0h5.12z"
            />
            <path
                fill="#767C86"
                d="M9.454 1a.94.94 0 0 1 .648.256l3.13 2.988a.857.857 0 0 1 .268.618v8.388c0 .966-.82 1.75-1.833 1.75H4.333C3.321 15 2.5 14.216 2.5 13.25V2.75C2.5 1.784 3.32 1 4.333 1h5.12zM8 2.312H4.333a.448.448 0 0 0-.458.438v10.5c0 .242.205.438.458.438h7.334a.448.448 0 0 0 .458-.438v-7H8.687a.68.68 0 0 1-.68-.56L8 5.595V2.312zm4.014 2.625L9.375 2.419v2.518h2.639z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationFileIcon);
export default Memo;
