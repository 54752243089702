// Utils
import * as pointLib from '../../maths/geometry/point';
import { isLocationCanvas } from './elementLocationUtils';

// Types
import { Point } from '../../maths/geometry/pointTypes';
import { getXPosition, getYPosition } from './elementPropertyUtils';
import { MNElement } from '../elementModelTypes';
import { ActionMove } from '../../actions/actionTypes';

export const DEFAULT_CANVAS_ORIGIN = { x: 0, y: 0 };
export const MINIMUM_CANVAS_ORIGIN = -500;
export const MAXIMUM_CANVAS_ORIGIN = 0;

export const MAXIMUM_POSITION_VALUE = 10000;
export const MAXIMUM_CANVAS_OVERFLOW_INCREMENT = 100;

export const getRenderedCanvasPositionGridUnits = (
    elementPosition: Point,
    canvasOrigin: Point = DEFAULT_CANVAS_ORIGIN,
): Point => pointLib.difference(canvasOrigin, elementPosition);

export const getRenderedCanvasPositionPx = (elementPosition: Point, canvasOrigin: Point, gridSize: number): Point =>
    pointLib.scale(gridSize, getRenderedCanvasPositionGridUnits(elementPosition, canvasOrigin));

// Lists of elements
export const getElementsTopLeftPositionGridUnits = (elements: MNElement[]): Point =>
    elements.reduce(
        (acc, el) => {
            const x = getXPosition(el);
            const y = getYPosition(el);

            if (x < acc.x) {
                acc.x = x;
            }

            if (y < acc.y) {
                acc.y = y;
            }

            return acc;
        },
        { x: Infinity, y: Infinity },
    );

/**
 * Elements should sit perfectly on grid points, so we round their positions to the nearest integer.
 */
export function ensureElementPositionIsInteger<T extends ActionMove | MNElement>(inputObj: T): T {
    if (!inputObj?.location) return inputObj;

    if (!isLocationCanvas(inputObj)) return inputObj;

    const xPos = getXPosition(inputObj);
    const yPos = getYPosition(inputObj);

    if (Number.isInteger(xPos) && Number.isInteger(yPos)) return inputObj;

    return {
        ...inputObj,
        location: {
            ...inputObj.location,
            position: {
                ...inputObj.location.position,
                x: Math.round(xPos),
                y: Math.round(yPos),
            },
        },
    };
}
