// Lib
import { EditorState, RichUtils, SelectionState, Modifier } from 'draft-js';

// Constants
import { ENTITIES } from '../../richText/richTextConstants';
import { DRAFT_ENTITY_MUTABILITY, EditorChangeType } from '../../draftjsConstants';

// From https://github.com/ngs/draft-js-markdown-shortcuts-plugin
export default (editorState, matchArr, character) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const key = selection.getStartKey();
    const [matchText, text, url] = matchArr;
    const { index } = matchArr;
    const focusOffset = index + matchText.length;

    const wordSelection = SelectionState.createEmpty(key).merge({
        anchorOffset: index,
        focusOffset,
    });

    const nextContent = currentContent.createEntity(ENTITIES.LINK, DRAFT_ENTITY_MUTABILITY.MUTABLE, { url });

    const entityKey = nextContent.getLastCreatedEntityKey();
    let newContentState = Modifier.replaceText(nextContent, wordSelection, text, null, entityKey);

    if (character === ' ') {
        newContentState = Modifier.insertText(newContentState, newContentState.getSelectionAfter(), character);
    }

    const newWordSelection = wordSelection.merge({
        focusOffset: index + text.length,
    });

    let newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.APPLY_ENTITY);
    newEditorState = RichUtils.toggleLink(newEditorState, newWordSelection, entityKey);
    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};
