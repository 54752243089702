import { createSelector } from 'reselect';
import { ONE_TRUST_CONSENT_MODEL, ONE_TRUST_STATUS } from './oneTrustConstants';

export const isOneTrustStatus = (status) => (state) => state.getIn(['oneTrust', 'status']) === status;
export const isOneTrustInitialised = isOneTrustStatus(ONE_TRUST_STATUS.INITIALISED);
export const isOneTrustError = isOneTrustStatus(ONE_TRUST_STATUS.ERROR);

export const getOneTrustConsentModel = (state) => state.getIn(['oneTrust', 'consentModel']);
export const getShowOneTrustBanner = (state) => state.getIn(['oneTrust', 'showBanner']);

export const getShowMandatoryCookieBanner = createSelector(
    getShowOneTrustBanner,
    getOneTrustConsentModel,
    (showBanner, consentModel) => {
        return showBanner && consentModel === ONE_TRUST_CONSENT_MODEL.OPT_IN;
    },
);

export const getShowOptionalCookieBanner = createSelector(
    getShowOneTrustBanner,
    getOneTrustConsentModel,
    (showBanner, consentModel) => {
        return showBanner && consentModel !== ONE_TRUST_CONSENT_MODEL.OPT_IN;
    },
);
