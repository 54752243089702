// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { prop } from '../../../../common/utils/immutableHelper';
import { getDrawingHeight } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import Icon from '../../../components/icons/Icon';
import ElementReactions from '../../reactions/ElementReactions';

// Constants
import { GRID } from '../../../utils/grid/gridConstants';
import { SKETCH_CANVAS_PIXEL_RATIO } from '../../../../common/drawings/sketches/sketchConstants';

// Styles
import './EmptySketch.scss';

const getStyle = ({ tempSize, element }) => {
    const tempHeight = prop('height', tempSize);

    if (tempHeight) {
        return {
            height: `${tempHeight}rem`,
        };
    }

    const sketchHeight = getDrawingHeight(element);

    if (sketchHeight) {
        return {
            height: `${sketchHeight / (GRID.LARGE.size * SKETCH_CANVAS_PIXEL_RATIO)}rem`,
        };
    }

    return null;
};

const EmptySketch = React.forwardRef(function EmptySketch(props, ref) {
    const { onDoubleClick } = props;

    return (
        <div ref={ref} className="EmptySketch" onDoubleClick={onDoubleClick} style={getStyle(props)}>
            <div className="icon-container">
                <Icon name="drawing-empty-placeholder" />
            </div>
            <div className="text">
                {platformSingleton.features.supportsMouse
                    ? 'Double click to start drawing'
                    : 'Double tap to start drawing'}
            </div>
            <ElementReactions {...props} />
        </div>
    );
});

EmptySketch.propTypes = {
    element: PropTypes.object,
    tempSize: PropTypes.object,
    onDoubleClick: PropTypes.func,
};

export default EmptySketch;
