import { memoize, debounce } from 'lodash';
import getClientConfig from '../utils/getClientConfig';
import logger from '../logger/logger';
import { logAnalytics } from './analyticsInvocationUtils';

import { INTERCOM_OPERATIONS } from './analyticsConstants';
import { TIMES } from '../../common/utils/timeUtil';

const useIntercom = memoize(() => getClientConfig().analytics?.enabled);
export const intercomAvaliable = () => window && typeof window.Intercom !== 'undefined';

export const intercomAPI = (action) => (data, metadata) => {
    const isAvailable = useIntercom();

    logAnalytics('Intercom', isAvailable, action, data, metadata);

    if (!isAvailable) return;

    if (!!getClientConfig()?.analytics?.passToSwift && !!window?.webkit?.messageHandlers?.milanoteIntercomAnalytics) {
        window.webkit.messageHandlers.milanoteIntercomAnalytics
            .postMessage({
                action,
                data,
                metadata,
            })
            .catch((error) => {
                logger.error('Intercom analytics event could not be posted: ', data, metadata, error);
            });
        return;
    }
    if (!intercomAvaliable()) return logger.warn('Intercom not initialized yet');
    window.Intercom(action, data, metadata); // eslint-disable-line new-cap
};

export const intercomBoot = intercomAPI(INTERCOM_OPERATIONS.boot);
export const intercomShutdown = intercomAPI(INTERCOM_OPERATIONS.shutdown);
export const intercomTrackEvent = intercomAPI(INTERCOM_OPERATIONS.trackEvent);
export const intercomShowMessenger = intercomAPI(INTERCOM_OPERATIONS.show);
export const intercomOnMessage = intercomAPI(INTERCOM_OPERATIONS.onUnreadCountChange);

export const intercomSetUserProperties = (userId, properties) =>
    intercomBoot({
        app_id: getClientConfig().analytics.appId,
        user_id: userId,
        ...properties,
    });

// Throttle intercom updates so we don't get throttled by them.
// Prod is on an Intercom SPA beta program with a much higher rate limit
const intercomUpdateAction = intercomAPI(INTERCOM_OPERATIONS.update);

export const intercomUpdate = debounce(
    intercomUpdateAction,
    TIMES.SECOND * getClientConfig().analytics?.debounceTimeSeconds,
    { maxWait: TIMES.SECOND * getClientConfig().analytics?.debounceMaxWaitSeconds },
);
