// Lib
import React, { ReactElement } from 'react';

// Components
import Button from '../../components/buttons/Button';
import Spinner from '../../components/loaders/Spinner';
import Icon from '../../components/icons/Icon';

// Styles
import './ExportPopupExportButton.scss';

const DOWNLOADING_STATUSES = {
    GENERATING: 'GENERATING',
    DOWNLOADING: 'DOWNLOADING',
    ERROR: 'ERROR',
};

type ExportPopupExportButtonProps = {
    children: React.ReactElement[];
    status: string | null;
    errorText: string | null;
    disabled?: boolean;
    onClickFn: () => void;
};

const ExportPopupExportButton = (props: ExportPopupExportButtonProps): ReactElement => {
    const { children, status, disabled, errorText, onClickFn } = props;

    if (status === DOWNLOADING_STATUSES.ERROR) {
        return (
            <div className="ExportPopupExportButton error">
                <Icon name="link-fail" />
                <span className="text">{errorText}</span>
            </div>
        );
    }

    if (status === DOWNLOADING_STATUSES.GENERATING || status === DOWNLOADING_STATUSES.DOWNLOADING) {
        const text = status === DOWNLOADING_STATUSES.GENERATING ? 'Generating' : 'Downloading';
        return (
            <div className="ExportPopupExportButton loading">
                <Spinner show />
                <span className="text">{text}</span>
            </div>
        );
    }

    return (
        <Button className="ExportPopupExportButton" onClick={!disabled ? onClickFn : undefined} disabled={disabled}>
            {children}
        </Button>
    );
};

export default ExportPopupExportButton;
