export const dispatchSyntheticBrowserUndoRedoEvent = (eventProps = {}) => {
    const keyDetails = {
        key: 'z',
        code: 'KeyZ',
        charCode: 'z'.charCodeAt(0),
        keyCode: 90,
        which: 90,
        metaKey: true,
        // important note: the global keyboard event listener (in keyboardShortcuts.js)
        // attaches an undo/redo listener that is platform-specific, so if we ever need to expand middleware to support
        // a non-ipad platform, we'll need to dispatch a different event with ctrlKey set to true.
        ctrlKey: false,
        shiftKey: false,
        altKey: false,
        ...eventProps,
    };

    document.dispatchEvent(new KeyboardEvent('keydown', keyDetails));
    document.dispatchEvent(new KeyboardEvent('keyup', keyDetails));

    // the undo/redo gesture prevents the pointerup event from occurring on the web layer,
    // so we need to dispatch a synthetic pointerup event here to ensure the current stroke is cancelled
    document.dispatchEvent(new PointerEvent('pointerup', { pointerType: 'touch' }));
};
