// Lib
import { findWithRegex } from '../../../../../node_module_clones/draft-js-plugins/utils/findWithRegex';

// Utils
import getQueryRegex, { QueryMode } from '../../../../../common/utils/getQueryRegex';

export default (searchTerm) => {
    const searchTermRegEx = getQueryRegex(searchTerm, QueryMode.PHRASE, 'gi');
    return (contentBlock, callback, contentState) => findWithRegex(searchTermRegEx, contentBlock, callback);
};
