// Lib
import * as Immutable from 'immutable';

// Utils
import { getUserIdFromAction } from '../../common/actionUtils';

// Constants
import {
    ELEMENTS_SELECTED,
    ELEMENTS_DESELECTED,
    ELEMENTS_DESELECT_ALL,
} from '../../common/elements/selectionConstants';

// Initial state
const initialState = Immutable.Map({
    userId: null,
});

const updateSelectedElement = (state, action) => state.set('userId', getUserIdFromAction(action));
const removeSelectedElement = (state, action) => state.set('userId', null);

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENTS_SELECTED:
            return updateSelectedElement(state, action);
        case ELEMENTS_DESELECTED:
            return removeSelectedElement(state, action);
        case ELEMENTS_DESELECT_ALL:
            return initialState;
        default:
            return state;
    }
};
