// Lib
import { get, isEmpty, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import Handsontable from 'handsontable/base';

// Utils
import useDebouncedCallback from '../../../utils/react/useDebouncedCallback';
import useThrottledCallback from '../../../utils/react/useThrottledCallback';
import { getCellValueArray } from '../utils/tableDataUtils';
import { asObject } from '../../../../common/utils/immutableHelper';
import { convertColWidthsGUtoPx, getColWidthsResizeWidth } from '../utils/tableSizeUtils';
import { repositionAutofillHandle } from '../utils/tableCellSelectionUtils';
import TableOperations from './TableOperations';
import MilanoteEditingPlugin, { MILANOTE_EDITING_PLUGIN_NAME } from '../modules/MilanoteEditingPlugin';

// Types
import { CellSelections, TableContentData } from '../../../../common/table/TableTypes';
import { MNElement } from '../../../../common/elements/elementModelTypes';

interface Props {
    element: MNElement;
    mounted: boolean;
    tableContentData: TableContentData;
    currentCellSelections: CellSelections;
    tableContentColWidthsGU: number[];

    locale: string;
    inList: string | null;
    gridSize: number;
    isGridMoving: boolean;
    isPresentational: boolean;
    isUndoingOrRedoing: boolean;

    setIsGridMoving: (isGridMoving: boolean) => void;
    getContextZoomScale: () => number;

    hotTableContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
    tableOperationsRef: React.RefObject<TableOperations>;

    dispatchUpdateTableElement: (args: object) => void;
}

/**
 * This component is used to sync any changed table data from the Redux state, and apply it to the Handsontable instance
 * In general, we try to do all updates via the handsontable state, and then sync the redux state to the handsontable state
 * This component covers the scenario where the redux state is updated, and we need to sync the handsontable state e.g.
 * - Initialisation
 * - Undo/Redo
 * - Remote changes
 */
const TableStateSyncHandlers = (props: Props): null => {
    const {
        element,
        mounted,
        tableContentData,
        currentCellSelections,
        tableContentColWidthsGU,
        gridSize,
        inList,
        getContextZoomScale,
        isUndoingOrRedoing,
        isGridMoving,
        setIsGridMoving,

        hotTableContainerRef,
        hotTableInstanceRef,
        tableOperationsRef,
    } = props;

    /*******************
     * Table data
     *******************/

    const syncTableData = () => {
        if (!hotTableInstanceRef.current) return;
        if (isGridMoving) setIsGridMoving(false);

        const milanoteEditingPluginInstance = hotTableInstanceRef.current.getPlugin(
            MILANOTE_EDITING_PLUGIN_NAME,
        ) as MilanoteEditingPlugin;
        if (!milanoteEditingPluginInstance) return;

        const cellValuesFromHot = milanoteEditingPluginInstance.getOriginalSourceDataArray();
        const cellValuesFromRedux = getCellValueArray(tableContentData);

        if (!isEqual(cellValuesFromRedux, cellValuesFromHot)) {
            hotTableInstanceRef.current.updateData(cellValuesFromRedux);
        }

        // Evaluate if any cell data has changed
        const changes: Array<[number, number, Handsontable.CellValue]> = [];
        cellValuesFromRedux.forEach((rowData, row) => {
            rowData.forEach((_, col) => {
                if (!hotTableInstanceRef.current) return;

                const cellData = get(tableContentData, [row, col]);
                const { cellData: hotCellData } = hotTableInstanceRef.current.getCellMeta(row, col);

                if (!isEqual(hotCellData, cellData)) {
                    tableOperationsRef.current?.setCellMetaObject(row, col, { cellData });

                    changes.push([row, col, cellData?.value]);
                }
            });
        });

        repositionAutofillHandle(hotTableInstanceRef.current, hotTableContainerRef);

        if (!isEmpty(changes)) {
            hotTableInstanceRef.current.render();

            // This is required to ensure that the table is resized properly for the new content
            requestAnimationFrame(() => {
                if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

                hotTableInstanceRef.current.setDataAtCell(changes, 'CellMeta.init');
            });
        }
    };

    const debouncedSyncTableData = useDebouncedCallback<void, void, void, void>(syncTableData, 100, [tableContentData]);

    useEffect(() => {
        return !mounted || isUndoingOrRedoing || isGridMoving ? syncTableData() : debouncedSyncTableData();
    }, [tableContentData]);

    /*******************
     * Cell Selections
     *******************/

    const syncCellSelections = () => {
        if (!hotTableInstanceRef.current) return;

        const currentHotCellSelections = hotTableInstanceRef.current.getSelected();
        if (isEqual(asObject(currentCellSelections), currentHotCellSelections)) return;

        tableOperationsRef.current?.updateHotCellSelections(currentCellSelections);
    };

    const debouncedSyncCellSelections = useDebouncedCallback<void, void, void, void>(syncCellSelections, 100, [
        currentCellSelections,
    ]);

    useEffect(() => {
        !mounted || isUndoingOrRedoing ? syncCellSelections() : debouncedSyncCellSelections();
    }, [currentCellSelections]);

    /*******************
     * Column Widths
     *******************/

    // Update col widths px in hot table based on container size
    const syncColWidths = useThrottledCallback<void, void>(
        () => {
            const resizeToWidth = getColWidthsResizeWidth(inList, hotTableContainerRef, getContextZoomScale);
            const tableContentColWidthsPx = convertColWidthsGUtoPx(tableContentColWidthsGU, gridSize, resizeToWidth);

            if (!tableContentColWidthsPx) return;

            tableOperationsRef.current?.updateColWidthsPx(tableContentColWidthsPx);
        },
        100,
        [tableContentColWidthsGU, gridSize, inList],
    );

    /**
     * Observe changes in hot table size, and update col widths px
     */
    useEffect(() => {
        if (!hotTableInstanceRef.current || !inList) return;
        const resizeObserver = new ResizeObserver(() => syncColWidths());

        resizeObserver.observe(hotTableInstanceRef.current?.rootElement);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        const colResizeInstance = hotTableInstanceRef?.current?.getPlugin<'manualColumnResize'>('manualColumnResize');

        // We don't want to update hot table if we are still resizing
        // this is because if we are resizing multiple columns, this function can be called before all the colWidthGu
        // values have been accurately updated in the afterColumnResize function
        if (colResizeInstance?.milanoteValues?.isCurrentlyResizing) return;

        syncColWidths();
        repositionAutofillHandle(hotTableInstanceRef.current, hotTableContainerRef);
    }, [tableContentColWidthsGU, gridSize]);

    return null;
};

export default TableStateSyncHandlers;
