import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const PresentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon present"
        {...props}
    >
        <path
            fill="#777"
            d="M4 0a2.413 2.413 0 0 0-2.4 2.4c0 .612.24 1.175.625 1.6H1.6C.719 4 0 4.719 0 5.6v1.6h6.4V4.8h3.2v2.4H16V5.6c0-.881-.719-1.6-1.6-1.6h-.625a2.39 2.39 0 0 0 .625-1.6C14.4 1.084 13.316 0 12 0c-1.272 0-2.266.806-3.05 1.6h-1.9C6.266.806 5.272 0 4 0zm0 1.6c.516 0 1.447.612 2.15 1.35.119.125.094.128.2.25H4a.798.798 0 0 1-.8-.8c0-.444.356-.8.8-.8zm8 0c.444 0 .8.356.8.8 0 .444-.356.8-.8.8H9.65c.106-.122.081-.125.2-.25.703-.738 1.634-1.35 2.15-1.35zM.8 8v6.4c0 .881.719 1.6 1.6 1.6h4V8H.8zm8.8 0v8h4c.881 0 1.6-.719 1.6-1.6V8H9.6z"
        />
    </svg>
);
const Memo = memo(PresentIcon);
export default Memo;
