// Lib
import { curry, round as roundFn, ceil, mapValues, multiply } from 'lodash/fp';

// Utils
import { roundToStep as roundToS } from '../roundToStep';
import { getX, getY } from './dimensionUtil';

// Types
import { Point } from './pointTypes';
import { ImMap } from '../../utils/immutableHelper';

type ImOrPojoPoint = Point | ImMap<Point>;

export const getPoint = (x: number, y: number): Point => ({ x, y });

export const scale = curry((multiplier: number, point: Point): Point => mapValues(multiply(multiplier), point));
export const reverseScale = curry(
    (multiplier: number, point: Point): Point => mapValues(multiply(1 / multiplier), point),
);

export const round = (point: Point) => mapValues(roundFn, point);
export const roundUp = (point: Point) => mapValues(ceil, point);

export const roundToStep = (step: number, point: ImOrPojoPoint): Point => ({
    x: roundToS(getX(point), step),
    y: roundToS(getY(point), step),
});

export const getDistanceSquared = (point1: ImOrPojoPoint, point2: ImOrPojoPoint): number => {
    const xd = getX(point1) - getX(point2);
    const yd = getY(point1) - getY(point2);
    return xd * xd + yd * yd;
};

export const getDistance = (point1: ImOrPojoPoint, point2: ImOrPojoPoint): number =>
    Math.sqrt(getDistanceSquared(point1, point2));

export const getArePointsClose = (point1: ImOrPojoPoint, point2: ImOrPojoPoint, closeDistance: number): boolean => {
    if (!point1 || !point2) return false;

    const distSq = getDistanceSquared(point1, point2);

    return distSq < closeDistance * closeDistance;
};

export const equals = (point1: Point, point2: Point): boolean => point1.x === point2.x && point1.y === point2.y;

export const getAngleBetween = (point1: Point, point2: Point): number =>
    Math.atan2(point2.y - point1.y, point2.x - point1.x);

export const translate = curry((translationPoint: ImOrPojoPoint, point: ImOrPojoPoint) =>
    getPoint(getX(point) + getX(translationPoint), getY(point) + getY(translationPoint)),
);

export const difference = curry((translationPoint: ImOrPojoPoint, point: ImOrPojoPoint) =>
    getPoint(getX(point) - getX(translationPoint), getY(point) - getY(translationPoint)),
);
export const reverseTranslate = difference;

export const getVectorMagnitude = (point: Point): number => Math.sqrt(point.x * point.x + point.y * point.y);

export const asPoint = (obj: ImOrPojoPoint): Point => {
    if (!obj) return obj;

    return { x: getX(obj), y: getY(obj) };
};
