// Lib
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { EditorContent } from '@tiptap/react';
import useCommentTiptapEditorExtensions from './useCommentTiptapEditorExtensions';
import { useSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';
import useClientElementTiptapEditor from '../../../components/tiptapEditor/useClientElementTiptapEditor';
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import { CommentEditorProps } from './CommentEditorTypes';
import CommentTiptapPostButton from './CommentTiptapPostButton';
import useEditorActivity from './useEditorActivity';
import { SearchHighlightObserver } from '../../../../common/tiptap/extensions/SearchHighlighter';

type CommentTiptapEditorProps = Omit<CommentEditorProps, 'postButtonRef' | 'openHyperlinkPopup'> & {
    tiptapContent: TiptapContent | null;
    isEditable: boolean;
    isEditing: boolean;
};

export const CommentTiptapEditor = (props: CommentTiptapEditorProps): ReactElement => {
    const {
        element,
        placeholder,
        isEditable,
        isEditing,
        isSingleSelected,
        onEmptyBackspace,
        saveContent,
        tiptapContent,
        textContent, // NOTE: This is only for the draft indicator
        editorId,
        editorKey,
        currentEditorKey,
        startEditing,
        onSubmit,
        isUpdating,
        hidePostButton,
        spellCheck,
        onActivity,
        editorStateRef,
    } = props;

    const dispatch = useDispatch();
    const [suggestionsManager, mentionsPopup] = useSuggestionsManager();

    const extensions = useCommentTiptapEditorExtensions({
        placeholder,
        onEmptyBackspace,
        saveContent,
        suggestionsManager,
        isEditable,
        dispatch,
        onSubmit,
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: tiptapContent,
        extensions,

        editorId,
        editorKey,
        currentEditorKey,

        isEditable,
        isEditing,
        isSingleSelected,

        startEditing,
        saveContent,
    });

    // TODO-Tiptap - On removal of Draft.js rename this to editorInstanceRef
    if (editorStateRef) {
        editorStateRef.current = editor;
    }

    useEditorActivity(editor, onActivity);

    return (
        <div className="CommentEditor" onMouseDown={onMouseDown} onClick={onClick}>
            {mentionsPopup}
            <SearchHighlightObserver editor={editor} />
            <DraftJsConversionIndicator element={element} textContent={textContent} />
            <EditorContent className="CommentTiptapEditor" editor={editor} spellCheck={spellCheck} />
            {editor && !hidePostButton && isUpdating && <CommentTiptapPostButton onSubmit={onSubmit} editor={editor} />}
        </div>
    );
};
