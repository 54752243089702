import dragAndDropStateSingleton from '../dragAndDropStateSingleton';

// Types
import { DragAndDropSnapMode } from '../../../workspace/dnd/dragLayerStateTypes';

/**
 * Prevents snapped drag and drop from dropping onto boards, or navigating into them.
 *
 * NOTE: This relies on the dragModifierKeys being a prop of the component where this is used, because
 *  this singleton obviously doesn't trigger a react property lifecycle event when its properties change.
 */
export default () => dragAndDropStateSingleton.snapMode === DragAndDropSnapMode.NONE;
