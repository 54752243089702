// Utils
import areDraggedItemsTaskListsWithoutHeadings from '../task/areDraggedItemsTaskListsWithoutHeadings';
import { isTask } from '../../../../common/elements/utils/elementTypeUtils';

export default ({ props, monitor }) => {
    const item = monitor.getItem();
    return (
        (item.draggedElements && item.draggedElements.every(isTask)) ||
        areDraggedItemsTaskListsWithoutHeadings(props, monitor)
    );
};
