// Utils
import { getSelectionEditorContextKey } from '../../store/reducers/editorContext/editorContextUtils';

// Constants
import { KEYS } from '../../../../utils/keyboard/keyConstants';
import { EditorContextName } from '../../store/reducers/editorContext/editorContextConstants';

// Types
import { PluginProps } from '../pluginTypes';
import { EditorStyleTypes } from '../../richText/richTextConstants';
import { ContentBlock, ContentState, EditorState, Modifier } from 'draft-js';

export default (
    event: KeyboardEvent,
    { getEditorState, setEditorState, getProps }: PluginProps,
): string | undefined => {
    if (event.key !== KEYS.BACKSPACE) return;

    const { dispatchGetEditorContextThunk, dispatchRemoveEditorContext } = getProps();

    const editorState = getEditorState();

    const editorContextKey = getSelectionEditorContextKey(editorState);

    const markdownContext = dispatchGetEditorContextThunk(EditorContextName.markdown);
    const markdownContextEditorState = markdownContext?.[editorContextKey];

    if (!markdownContextEditorState) return;

    // Currently only handling block type changes (NOTE: only setting block type atm, so this check is redundant)
    if (markdownContextEditorState.replacementStyleType !== EditorStyleTypes.BLOCK) return;

    const {
        replacement: { blockType: replacementBlockType },
        restore: { blockType: restoreBlockType, text: restoreText },
    } = markdownContextEditorState;

    // Regardless of whether the block type is restored, we need to remove the markdown context
    dispatchRemoveEditorContext(EditorContextName.markdown, editorContextKey);

    if (!restoreBlockType) return;

    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const currentSelectionKey = currentSelection.getFocusKey();

    const currentBlock = currentContent.getBlockForKey(currentSelectionKey);

    if (!currentBlock) return;

    const currentBlockType = currentBlock.getType();

    // If the current block's type does not equal the block type we stored in the context, an external change
    // must have occurred meaning we should not restore the markdown context's previous block type
    if (currentBlockType !== replacementBlockType) return;

    // We need to insert the text that was removed when the markdown context was created and them move the selection
    let nextContentState = Modifier.insertText(currentContent, currentSelection, restoreText);
    const updatedBlock = nextContentState.getBlockForKey(currentSelectionKey);

    const newBlock = updatedBlock.merge({ type: restoreBlockType }) as ContentBlock;

    const blockMap = nextContentState.getBlockMap();
    nextContentState = nextContentState.merge({
        blockMap: blockMap.set(currentSelectionKey, newBlock),
    }) as ContentState;

    // Use EditorState.push to ensure the undo stack is updated
    const nextEditorState = EditorState.push(editorState, nextContentState, 'change-block-type');

    setEditorState(nextEditorState);

    // This is a dummy value to prevent other handlers from running
    return 'RESTORING_UN_STYLED_STATE';
};
