import { sendAmplitudeEvent } from '../../analytics/amplitudeService';

import { ICON_SEARCH_MODES } from '../../../common/icons/iconConstants';

export const sendBoardIconSetEvent = ({
    boardId,
    icon,
    searchQuery,
    isFallback,
    setMode = ICON_SEARCH_MODES.USER_ACTION,
}) => {
    sendAmplitudeEvent({
        eventType: 'Set board icon',
        eventProperties: {
            boardId,
            setMode,
            searchQuery: searchQuery && searchQuery.toLowerCase(),
            isFallback,
            iconType: icon.type,
            iconId: icon.id,
            iconName: icon.name,
            iconUrl: icon.png,
        },
    });
};
