/* eslint-disable no-param-reassign */
// Lib
import * as Immutable from 'immutable';
import { get, uniq } from 'lodash/fp';

// Utils
import { isEmpty, length } from '../../../../common/utils/immutableHelper';
import { getTimestamp, TIMES } from '../../../../common/utils/timeUtil';
import { getChangeType } from '../../../../common/notifications/elementChangesUtils';
import {
    getNotificationActorIds,
    getNotificationAssignmentElementId,
    getNotificationAssignments,
    getNotificationCommentIsReply,
    getNotificationElementChangesAsObject,
    getNotificationElementId,
    getNotificationId,
    getNotificationMentionCommentId,
    getNotificationMentionElementId,
    getNotificationObservedTimestamp,
    getNotificationReminderElementId,
    getNotificationReminders,
    getNotificationTimestamp,
    getNotificationType,
    getNotificationViewedTimestamp,
} from '../../../../common/notifications/notificationModelUtils';
import {
    getUnseenCommentIds,
    getUnseenElementChangesIds,
} from '../../../../common/notifications/notificationsListUtils';
import { mergeElementActivityEntries } from './mergeElementActivityEntries';

// Constants
import {
    NOTIFICATION_OBSERVED_TO_SEEN_TIME,
    NOTIFICATION_TYPES,
} from '../../../../common/notifications/notificationConstants';
import { ELEMENT_ACTIVITY_TYPES } from '../elementActivityConstants';

export const getNewChangeActivity = () => ({
    type: ELEMENT_ACTIVITY_TYPES.CHANGE,
    actorIds: Immutable.List(),
    notificationIds: [],
    changeCount: 0,
    timestamp: 0,
});

const mutateBoardActivityEntryWithChange = ({
    notificationId,
    activityMap,
    activityEntryId,
    actorIds,
    changeCount,
    timestamp,
}) => {
    let currentEntry = activityMap[activityEntryId] || getNewChangeActivity();

    if (currentEntry.type !== ELEMENT_ACTIVITY_TYPES.CHANGE) {
        currentEntry = getNewChangeActivity();
    }

    currentEntry.actorIds = currentEntry.actorIds.concat(actorIds);
    currentEntry.changeCount += changeCount;
    currentEntry.timestamp = Math.max(currentEntry.timestamp, timestamp);
    currentEntry.notificationIds.push(notificationId);

    activityMap[activityEntryId] = currentEntry;

    return activityMap;
};

const mutateActivityMapForShare = (activityMap, notification) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const actorIds = getNotificationActorIds(notification);
    const timestamp = getNotificationTimestamp(notification);

    const activityEntry = {
        type: ELEMENT_ACTIVITY_TYPES.SHARE,
        actorIds,
        timestamp,
        notificationIds: [notificationId],
    };

    activityMap[notificationElementId] = mergeElementActivityEntries(activityEntry, activityMap[notificationElementId]);

    return activityMap;
};

const mutateActivityMapForMention = (activityMap, notification) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const actorIds = getNotificationActorIds(notification);
    const timestamp = getNotificationTimestamp(notification);

    const mentionCommentId = getNotificationMentionCommentId(notification);
    const mentionElementId = getNotificationMentionElementId(notification);

    const activityEntryId = mentionElementId || mentionCommentId;

    activityMap = mutateBoardActivityEntryWithChange({
        activityMap,
        activityEntryId: notificationElementId,
        actorIds,
        changeCount: 1,
        timestamp,
        notificationId,
    });

    const activityEntry = {
        type: ELEMENT_ACTIVITY_TYPES.MENTION,
        actorIds,
        timestamp,
        notificationIds: [notificationId],
    };

    activityMap[activityEntryId] = mergeElementActivityEntries(activityEntry, activityMap[activityEntryId]);

    return activityMap;
};

const mutateActivityMapForComment = (activityMap, notification, lastBoardView) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const actorIds = getNotificationActorIds(notification);
    const timestamp = getNotificationTimestamp(notification);

    const unseenCommentIds = getUnseenCommentIds(notification, lastBoardView);

    if (isEmpty(unseenCommentIds)) return activityMap;

    const isReply = getNotificationCommentIsReply(notification);

    activityMap = mutateBoardActivityEntryWithChange({
        activityMap,
        activityEntryId: notificationElementId,
        actorIds,
        changeCount: unseenCommentIds.length,
        timestamp,
        notificationId,
    });

    unseenCommentIds.forEach((commentId) => {
        const activityEntry = {
            type: ELEMENT_ACTIVITY_TYPES.COMMENT,
            actorIds,
            timestamp,
            isReply,
            notificationIds: [notificationId],
        };

        activityMap[commentId] = mergeElementActivityEntries(activityEntry, activityMap[commentId]);
    });

    return activityMap;
};

const ACTIVITY_TYPE_MAP = {
    Added: ELEMENT_ACTIVITY_TYPES.ADD,
    Updated: ELEMENT_ACTIVITY_TYPES.UPDATE,
};

const getChangeActivityType = ({ elementType, changes }) => {
    const changeType = getChangeType({ elementType, changes });
    return ACTIVITY_TYPE_MAP[changeType] || ELEMENT_ACTIVITY_TYPES.COMPLETE;
};

const mutateActivityMapForBoardUpdate = (activityMap, notification, lastBoardView) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const actorIds = getNotificationActorIds(notification);
    const timestamp = getNotificationTimestamp(notification);

    const unseenChangedElementIds = getUnseenElementChangesIds(notification, lastBoardView);

    if (isEmpty(unseenChangedElementIds)) return activityMap;

    const changeCount = unseenChangedElementIds.length;

    activityMap = mutateBoardActivityEntryWithChange({
        activityMap,
        activityEntryId: notificationElementId,
        actorIds,
        changeCount,
        timestamp,
        notificationId,
    });

    const elementChanges = getNotificationElementChangesAsObject(notification);

    unseenChangedElementIds.forEach((elementId) => {
        const changeEntry = elementChanges[elementId];
        const { elementType, changes } = changeEntry;

        const changeActorIds = uniq(changes?.map(get('actorId')));

        const changeActivityType = getChangeActivityType({ elementType, changes });

        const activityEntry = {
            type: changeActivityType,
            // This is used to dispatch "markNotificationElementAsSeen" actions
            isElementChange: true,
            actorIds: changeActorIds,
            timestamp,
            notificationIds: [notificationId],
        };

        activityMap[elementId] = mergeElementActivityEntries(activityEntry, activityMap[elementId]);
    });

    return activityMap;
};

const mutateActivityMapForReaction = (activityMap, notification, lastBoardView) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const timestamp = getNotificationTimestamp(notification);
    const actorIds = getNotificationActorIds(notification);

    const unseenReactionComponentIds = getUnseenElementChangesIds(notification, lastBoardView);

    if (isEmpty(unseenReactionComponentIds)) return activityMap;

    activityMap = mutateBoardActivityEntryWithChange({
        activityMap,
        activityEntryId: notificationElementId,
        actorIds,
        changeCount: unseenReactionComponentIds.length,
        timestamp,
        notificationId,
    });

    const elementChanges = getNotificationElementChangesAsObject(notification);

    unseenReactionComponentIds.forEach((reactionComponentId) => {
        const changeEntry = elementChanges[reactionComponentId];
        const { changes } = changeEntry;

        const changeActorIds = uniq(changes?.map(get('actorId')));

        const activityEntry = {
            type: ELEMENT_ACTIVITY_TYPES.REACTION,
            // This is used to dispatch "markNotificationElementAsSeen" actions
            isElementChange: true,
            actorIds: changeActorIds,
            timestamp,
            notificationIds: [notificationId],
        };

        activityMap[reactionComponentId] = mergeElementActivityEntries(activityEntry, activityMap[reactionComponentId]);
    });

    return activityMap;
};

const mutateActivityMapForAssignment = (activityMap, notification) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const actorIds = getNotificationActorIds(notification);
    const timestamp = getNotificationTimestamp(notification);

    const assignments = getNotificationAssignments(notification);

    if (!assignments) return activityMap;

    const changeCount = length(assignments);

    activityMap = mutateBoardActivityEntryWithChange({
        activityMap,
        activityEntryId: notificationElementId,
        actorIds,
        changeCount,
        timestamp,
        notificationId,
    });

    assignments.forEach((assignment) => {
        const elementId = getNotificationAssignmentElementId(assignment);

        const activityEntry = {
            type: ELEMENT_ACTIVITY_TYPES.UPDATE,
            actorIds,
            timestamp,
            notificationIds: [notificationId],
        };
        activityMap[elementId] = mergeElementActivityEntries(activityEntry, activityMap[elementId]);
    });

    return activityMap;
};

const mutateActivityMapForTaskReminder = (activityMap, notification) => {
    const notificationId = getNotificationId(notification);
    const notificationElementId = getNotificationElementId(notification);
    const timestamp = getNotificationTimestamp(notification);

    const reminders = getNotificationReminders(notification);

    if (!reminders) return activityMap;

    const changeCount = length(reminders);

    activityMap = mutateBoardActivityEntryWithChange({
        activityMap,
        activityEntryId: notificationElementId,
        changeCount,
        timestamp,
        notificationId,
    });

    reminders.forEach((assignment) => {
        const elementId = getNotificationReminderElementId(assignment);

        const activityEntry = {
            type: ELEMENT_ACTIVITY_TYPES.UPDATE,
            timestamp,
            notificationIds: [notificationId],
        };
        activityMap[elementId] = mergeElementActivityEntries(activityEntry, activityMap[elementId]);
    });

    return activityMap;
};

/**
 * For each notification type, build an activity entry for the affected elements.
 */
const mutateActivityMap = (activityMap, notification, lastBoardView) => {
    const notificationType = getNotificationType(notification);

    switch (notificationType) {
        case NOTIFICATION_TYPES.SHARE:
            return mutateActivityMapForShare(activityMap, notification);
        case NOTIFICATION_TYPES.MENTION:
            return mutateActivityMapForMention(activityMap, notification);
        case NOTIFICATION_TYPES.COMMENT:
            return mutateActivityMapForComment(activityMap, notification, lastBoardView);
        case NOTIFICATION_TYPES.REACTION:
            return mutateActivityMapForReaction(activityMap, notification, lastBoardView);
        case NOTIFICATION_TYPES.BOARD_UPDATE:
        case NOTIFICATION_TYPES.BOARD_TASKS_UPDATE:
            return mutateActivityMapForBoardUpdate(activityMap, notification, lastBoardView);
        case NOTIFICATION_TYPES.ASSIGNMENT:
            return mutateActivityMapForAssignment(activityMap, notification);
        case NOTIFICATION_TYPES.BOARD_TASKS_REMINDER:
            return mutateActivityMapForTaskReminder(activityMap, notification);
        default:
            return activityMap;
    }
};

const EARLIEST_ACTIVE_NOTIFICATION_TIME = 1544673527724;

/**
 * This builds a map of:
 *  [ Parent Board ID || Element ID || Comment ID ]: Activity entry
 */
export default ({ notifications, viewedBoards, currentBoardId }) => {
    const currentTimestamp = getTimestamp();
    const lastBoardView = viewedBoards.get(currentBoardId) || currentTimestamp;

    return notifications.reduce((activityMap, notification) => {
        const notificationTimestamp = getNotificationTimestamp(notification);
        if (notificationTimestamp < EARLIEST_ACTIVE_NOTIFICATION_TIME) return activityMap;

        const notificationObservedTimestamp = getNotificationObservedTimestamp(notification);
        const notificationViewedTimestamp = getNotificationViewedTimestamp(notification);
        const notificationElementId = getNotificationElementId(notification);

        if (!notificationElementId) return activityMap;

        // Old observed notifications will not be shown in the activity map
        if (
            notificationObservedTimestamp &&
            notificationObservedTimestamp < currentTimestamp - NOTIFICATION_OBSERVED_TO_SEEN_TIME
        ) {
            return activityMap;
        }

        const notificationAlreadyViewed =
            notificationViewedTimestamp &&
            (currentBoardId !== notificationElementId ||
                notificationViewedTimestamp < lastBoardView - 5 * TIMES.SECOND);

        if (notificationAlreadyViewed) return activityMap;

        activityMap = mutateActivityMap(activityMap, notification, lastBoardView);

        return activityMap;
    }, {});
};
