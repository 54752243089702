// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';

// Utils
import { getElementCardRenderedWidthPx } from '../../utils/elementUtil';
import { getImageProp, getLinkProp } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import ElementImage from '../../../components/images/ElementImage';
import previewImageDropTargetDecorator from '../../dnd/elementDropTargets/previewImageDropTargetDecorator';
import ImageReplacementOverlay from '../../image/ImageReplacementOverlay';

// Constants
import { IMAGE_TYPES } from '../../../../common/media/mediaConstants';
import { LINK_PREVIEW_SIZE } from '../LinkConstants';

// Styles
import './LinkImage.scss';

const enhance = previewImageDropTargetDecorator();

const LinkImage = (props) => {
    const { element, gridSize, isReplaceModeHovered, connectFileDropTarget = identity, isResizing } = props;

    const linkData = getLinkProp(element);
    const imageData = getImageProp(element);

    const widthPx = getElementCardRenderedWidthPx(props);

    if (!linkData || !imageData || !imageData.size) return null;

    return connectFileDropTarget(
        <div className="LinkImage">
            {isReplaceModeHovered && <ImageReplacementOverlay />}
            <ElementImage
                showBrokenIconOnError
                cropToGrid={!isResizing}
                gridSize={gridSize}
                element={element}
                imageType={IMAGE_TYPES.ELEMENT}
                widthPx={widthPx}
                forcedSize={LINK_PREVIEW_SIZE}
            />
        </div>,
    );
};

LinkImage.propTypes = {
    element: PropTypes.object,
    gridSize: PropTypes.number,
    isHovered: PropTypes.bool,
    canDrop: PropTypes.bool,
    connectFileDropTarget: PropTypes.func,
    isResizing: PropTypes.bool,
    isReplaceModeHovered: PropTypes.bool,
};

export default enhance(LinkImage);
