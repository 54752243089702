import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

// Selectors
import { colorToolSelector, ColorToolSelectorReturnType } from '../../colorToolSelector';

// Utils
import {
    getFirstSelectedSecondaryColor,
    getSelectedElementColorStatusClass,
    getSelectedElementType,
    getSupportColorBar,
} from '../../colorToolUtils';

// Hooks
import useModalSheet from '../../../../../../../mobile/structural/modalSheet/hooks/useModalSheet';

// Components
import ColorToolIcon from '../../ColorToolIcon';
import MobileColorToolContent from './MobileColorToolContent';

// Constants
import { BACKGROUND_COLORS } from '../../../../../../../../common/colors/colorConstants';
import { ImMNElement } from '../../../../../../../../common/elements/elementModelTypes';

type MobileColorToolProps = ColorToolSelectorReturnType & {
    selectedElements: Immutable.List<ImMNElement>;
};

const MobileColorTool = (props: MobileColorToolProps): ReactElement => {
    const {
        selectedElements,
        supportBackgroundColor,
        selectedColor,
        selectedBackgroundColorName,
        selectedBackgroundColor,
        name,
    } = props;

    const { openSheet, closeSheet, sheetProps } = useModalSheet();

    const selectedType = getSelectedElementType(selectedElements);
    const selectedStatus = getSelectedElementColorStatusClass(selectedElements);
    const supportColorBar = getSupportColorBar(selectedElements);
    const secondaryColor = getFirstSelectedSecondaryColor(selectedElements);

    const backgroundColorIndex = selectedBackgroundColorName as keyof typeof BACKGROUND_COLORS;
    const backgroundColorClass = BACKGROUND_COLORS[backgroundColorIndex]?.className;

    return (
        <div className="MobileColorTool Tool">
            <div className="contents custom-mobile-tool" onClick={openSheet}>
                <ColorToolIcon
                    className={backgroundColorClass}
                    isActive={sheetProps.isSheetOpen}
                    color={selectedColor}
                    backgroundColor={selectedBackgroundColor}
                    supportBackgroundColor={supportBackgroundColor}
                    secondaryColor={secondaryColor}
                    selectedType={selectedType}
                    selectedStatus={selectedStatus}
                    supportColorBar={supportColorBar}
                />
                <span className="tool-name">{name}</span>
            </div>
            <MobileColorToolContent {...props} sheetProps={sheetProps} openSheet={openSheet} closeSheet={closeSheet} />
        </div>
    );
};

export default connect(colorToolSelector)(MobileColorTool);
