export const numbersAscending = (n1, n2) => n1 - n2;
export const numbersDescending = (n1, n2) => n2 - n1;

export const sortByString = (accessorFn, fallbackFn) => (objectA, objectB) => {
    const stringA = accessorFn(objectA);
    const stringB = accessorFn(objectB);

    if (stringA && !stringB) return -1;
    if (stringB && !stringA) return 1;

    if (!stringA && !stringB) return fallbackFn(objectA, objectB);

    return stringA.localeCompare(stringB) || 0;
};
