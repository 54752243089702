import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationBoardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-board"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={16} height={16} fill="#FFF" rx={4} />
            <rect width={14} height={14} x={1} y={1} fill="#5882F8" rx={3} />
            <rect width={3.063} height={3.063} x={4.5} y={4.5} fill="#FFF" rx={0.875} />
            <rect width={3.063} height={3.063} x={4.5} y={8.438} fill="#FFF" fillOpacity={0.75} rx={0.875} />
            <rect width={3.063} height={3.063} x={8.438} y={4.5} fill="#FFF" fillOpacity={0.25} rx={1.531} />
            <rect width={3.063} height={3.063} x={8.438} y={8.438} fill="#FFF" fillOpacity={0.5} rx={0.875} />
        </g>
    </svg>
);
const Memo = memo(NotificationBoardIcon);
export default Memo;
