import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarTransparentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-transparent"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path fill="#323B4A" d="M16 7a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 1.5v15a7.5 7.5 0 0 0 0-15z" />
        </g>
    </svg>
);
const Memo = memo(ToolbarTransparentIcon);
export default Memo;
