// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';
import useModalSheet from '../../../../../mobile/structural/modalSheet/hooks/useModalSheet';
import MobileModalSheet, {
    Content,
    Overlay,
    Sheet,
} from '../../../../../mobile/structural/modalSheet/MobileModalSheet';
import MobileElementContextMenuContent from './MobileElementContextMenuContent';

const MobileContextMenuTool = (props: any) => {
    const { openSheet, sheetProps } = useModalSheet();

    return (
        <>
            <ToolbarTool {...props} name={props.name} onClick={openSheet}>
                <Icon name="toolbar-context-menu" />
            </ToolbarTool>
            <MobileModalSheet defaultSnapPoint={'0.5max'} {...sheetProps}>
                <Overlay />
                <Sheet className="secondary">
                    <Content>
                        <MobileElementContextMenuContent {...props} />
                    </Content>
                </Sheet>
            </MobileModalSheet>
        </>
    );
};

MobileContextMenuTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
};

export default MobileContextMenuTool;
