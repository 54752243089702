// Lib
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import getColorPaletteFromUrl from './getImageColorPaletteFromUrl';
import { getElementId, getImageColors, getImageProp } from '../../../../common/elements/utils/elementPropertyUtils';
import { getImageSource } from '../../../element/image/imageHelper';

// Actions
import { updateElement } from '../../../element/actions/elementActions';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../../../element/feature/elementFeatureSelector';

// Constants
import PropTypes from 'prop-types';
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';

const mapStateToProps = createStructuredSelector({
    renderSecureImagesThroughProxy: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughProxy),
    // Default to enabled for guest users
    renderSecureImagesThroughCF: getIsFeatureEnabledForCurrentUser(ExperimentId.renderSecureImagesThroughCF, true),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateElementImageColors: (id, imageData) =>
        dispatch(
            updateElement({
                id,
                changes: {
                    image: imageData,
                },
                transactionId: -1,
                silent: true,
            }),
        ),
});

const ImageColorPaletteObserver = ({
    element,
    renderSecureImagesThroughProxy,
    renderSecureImagesThroughCF,
    dispatchUpdateElementImageColors,
}) => {
    const elementId = getElementId(element);
    const imageDetails = getImageProp(element);
    const imageColors = getImageColors(element);
    const imageUrl = getImageSource({
        imageDetails,
        imageSize: 'regular',
        elementId,
        useSecureMediaUrl: true,
        renderSecureImagesThroughProxy,
        renderSecureImagesThroughCF,
    });

    const fetchImageColorPallete = async () => {
        const colors = await getColorPaletteFromUrl(imageUrl);

        const imageData = getImageProp(element)?.toJS();
        if (!imageData) return;

        imageData.colors = colors;
        dispatchUpdateElementImageColors(elementId, imageData);
    };

    useEffect(() => {
        if (!imageUrl || imageColors) return;

        fetchImageColorPallete();
    }, [elementId, imageColors, imageUrl]);

    return null;
};

ImageColorPaletteObserver.propTypes = {
    element: PropTypes.object,
    renderSecureImagesThroughProxy: PropTypes.bool,
    renderSecureImagesThroughCF: PropTypes.bool,
    dispatchUpdateElementImageColors: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageColorPaletteObserver);
