// Lib
import loadable from 'react-loadable';

export default loadable({
    loader: () =>
        import(
            /* webpackChunkName: "sketch-modal" */
            './SketchModal'
        ),
    loading: () => null,
});
