/**
 * This file holds functions related to getting styles (e.g. CSS classes) for or from elements.
 */
// Lib
import { isString } from 'lodash/fp';

// Utils
import { getIsLightElementColor } from '../../../common/colors/colorComparisonUtil';
import { isValidColorFormat } from '../../../common/colors/colorSpaceUtil';
import { getPredefinedColorOrBackground, PredefinedColor } from '../../../common/colors/colorPresetsUtil';
import {
    ColorObject,
    getChromaColor,
    getColorObjectCssValue,
    getColorObjectSpace,
} from '../../../common/colors/colorObjectUtil';

// Constants
import { BACKGROUND_COLORS, COLORS, ICON_COLORS } from '../../../common/colors/colorConstants';

// Types
import { ImmutableMap } from '../../../common/utils/immutableHelper';

/**
 * If the colour is a predefined colour, return the CSS variable string.
 * If the color is a valid color string format or a color object, return CSS color format.
 *
 * e.g. "BLUE" will return "var(--color-element-blue)"
 */
export const getColorCssValue = (
    color: string | ColorObject | ImmutableMap | null,
    predefinedColorMap?: { [key: string]: PredefinedColor },
): string | null => {
    if (!isString(color)) {
        const colorSpace = getColorObjectSpace(color);
        if (colorSpace) return getColorObjectCssValue(color);

        return null;
    }

    const colorMapEntry = getPredefinedColorOrBackground(color, predefinedColorMap);
    if (colorMapEntry) return colorMapEntry.css;

    if (isValidColorFormat(color)) return color;

    return null;
};

/**
 * Determines the CSS property value for text colour based on the background colour.
 */
export const getCssPropertyForTextOnColor = (
    color: string | ColorObject | ImmutableMap,
    isDefaultLight: boolean,
    predefinedMap = ICON_COLORS,
): string => {
    if (!color) return isDefaultLight ? 'var(--ui-text-color-opposite-to-canvas)' : 'var(--ui-text-color-match-canvas)';

    const colorStr = isString(color) ? color : getChromaColor(color);

    const isLight = getIsLightElementColor(colorStr, isDefaultLight, predefinedMap as typeof COLORS);

    return isLight ? 'var(--ws-element-dark-text-on-color)' : 'var(--ws-element-light-text-on-color)';
};

/**
 * Line labels are the same as other elements, except the grey colour switches depending on dark mode too.
 */
export const getCssPropertyForLineLabelOnColor = (color: string | ColorObject | ImmutableMap): string => {
    if (color === COLORS.GREY.name) return 'var(--ui-text-color-opposite-to-canvas)';

    return getCssPropertyForTextOnColor(color, false);
};

/**
 * Gets the CSS classes based on the background colour.
 */
export const getBackgroundColorClasses = (
    backgroundColor: string,
    isDefaultBackgroundLight = true,
    predefinedMap: { [key: string]: PredefinedColor } = BACKGROUND_COLORS,
): Record<string, boolean> | undefined => {
    if (!backgroundColor) return;

    // then use the highest contrast version
    const isLightBackground = getIsLightElementColor(backgroundColor, isDefaultBackgroundLight, predefinedMap);

    return {
        'colored-background': true,
        'colored-background-light': isLightBackground,
        'colored-background-dark': !isLightBackground,
    };
};
