// Lib
import * as Immutable from 'immutable';
import { isArray } from 'lodash/fp';

// Utils
import {
    getNotificationId,
    getNotificationViewedTimestamp,
} from '../../../common/notifications/notificationModelUtils';
import areNotificationsSimilar from '../../../common/notifications/areNotificationsSimilar';

/**
 * If there is a new unseen notification, then remove similar old viewed notifications.
 */
const removeOldViewedNotifications = ({ newNotifications, mutableState }) => {
    newNotifications.forEach((newNotification) => {
        // For any unseen notification find any matching viewed notifications and remove them
        const viewedTimestamp = getNotificationViewedTimestamp(newNotification);

        if (!viewedTimestamp) {
            const existingNotificationKeys = mutableState.keySeq().toArray();

            // Loop through the existing state then update the mutable state
            existingNotificationKeys.forEach((existingNotificationId) => {
                const existingNotification = mutableState.get(existingNotificationId);
                if (!existingNotification) return;

                const existingViewedTimestamp = getNotificationViewedTimestamp(existingNotification);

                // Only remove seen notification
                if (!existingViewedTimestamp) return;

                if (!areNotificationsSimilar(newNotification, existingNotification)) return;

                // The existing notification is seen and similar to a new notification, so remove it
                mutableState.delete(existingNotificationId);
            });
        }

        mutableState.set(getNotificationId(newNotification), Immutable.fromJS(newNotification));
    });

    return mutableState;
};

export default (state, action) => {
    const { notifications } = action;

    const newNotifications = isArray(notifications) ? notifications : Object.values(notifications);

    return state.withMutations((mutableState) => removeOldViewedNotifications({ newNotifications, mutableState }));
};
