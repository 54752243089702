// Lib
import * as Immutable from 'immutable';

// Utils
import getClientConfig from '../../utils/getClientConfig';

// Constants
import { VERSION_UPDATE_AVAILABLE } from './versionConstants';

const initialState = Immutable.Map({
    currentVersion: getClientConfig().appVersion,
    updateAvailable: false,
    isCurrentVersionSafe: true,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case VERSION_UPDATE_AVAILABLE:
            return state.set('updateAvailable', true).set('isCurrentVersionSafe', action.safe);
        default:
            return state;
    }
};
