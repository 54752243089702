import React from 'react';
import PropTypes from 'prop-types';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import CellTypePopup from '../../../../../element/table/cellTypes/CellTypePopup';

const CellTypePopupContainer = ({ isPopupOpen, selectedElements, ...props }) => {
    const selectedElement = selectedElements.first();
    return (
        <ToolbarPopup {...props} popupId={PopupIds.CELL_TYPE} className="CellTypePopup">
            <CellTypePopup {...props} closePopup={null} element={selectedElement} />
        </ToolbarPopup>
    );
};

CellTypePopupContainer.propTypes = {
    isPopupOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
};

export default CellTypePopupContainer;
