import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeProgressIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-progress"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                d="M9 20a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v2a3 3 0 0 1-3 3H9Zm14-6.5h-5v5h5a1.5 1.5 0 0 0 1.5-1.5v-2a1.5 1.5 0 0 0-1.5-1.5Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeProgressIcon);
export default Memo;
