// Lib
import { useEffect, useRef } from 'react';
import { isEmpty, first } from 'lodash/fp';

const useDrawingEditorUpdateToolbarOnSelection = ({
    isMarqueeSelectMode,
    paths,
    selectedPathIdsMap,
    setStrokeSizeState,
    setStrokeColorState,
    canvasScale,
}) => {
    const hasSelectionsRef = useRef(false);

    useEffect(() => {
        if (isMarqueeSelectMode) return;

        const alreadyHadSelections = hasSelectionsRef.current;

        hasSelectionsRef.current = !isEmpty(selectedPathIdsMap);

        const selectedIds = Object.keys(selectedPathIdsMap);
        const shouldUpdateTools = hasSelectionsRef.current && (!alreadyHadSelections || selectedIds.length === 1);

        if (!shouldUpdateTools) return;

        const firstSelectedId = first(selectedIds);
        const path = paths.find((p) => p.id === firstSelectedId);

        if (!path) return;

        const { color, size } = path;

        setStrokeSizeState(Math.round(size * canvasScale));
        setStrokeColorState(color);
    }, [selectedPathIdsMap, isMarqueeSelectMode]);
};

export default useDrawingEditorUpdateToolbarOnSelection;
