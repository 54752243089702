import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ModalToolbarCropIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon modal-toolbar-crop"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M18.75 24v1.25a.75.75 0 1 1-1.5 0V24h1.5zm-8-8v4c0 .647.492 1.18 1.122 1.244l.128.006h9.25a.75.75 0 1 1 0 1.5H12a2.75 2.75 0 0 1-2.745-2.582L9.25 20v-4h1.5zM16 13.25a2.75 2.75 0 0 1 2.745 2.582l.005.168v4h-1.5v-4a1.25 1.25 0 0 0-1.122-1.244L16 14.75H6.75a.75.75 0 1 1 0-1.5H16zm3-6.682v.682h1a4.75 4.75 0 0 1 4.745 4.533l.005.217v2a.75.75 0 0 1-1.493.102L23.25 14v-2a3.25 3.25 0 0 0-3.066-3.245L20 8.75h-1v.682a.5.5 0 0 1-.82.385l-1.72-1.433a.5.5 0 0 1 0-.768l1.72-1.433a.5.5 0 0 1 .82.385zM10 10a.75.75 0 0 1 .75.75V12h-1.5v-1.25A.75.75 0 0 1 10 10z"
        />
    </svg>
);
const Memo = memo(ModalToolbarCropIcon);
export default Memo;
