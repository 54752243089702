import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarTextColorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={18}
        viewBox="0 0 22 18"
        className="Icon toolbar-text-color"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#DCDDDF"
                d="M1 0h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"
                className="background"
            />
            <path
                fill="#323B4A"
                d="m4.606 14.03.921-2.654h3.919l.915 2.655h1.607L8.358 4H6.61L3 14.03h1.606zm1.362-3.927 1.48-4.281h.078l1.479 4.28H5.968zm9.074 4.094c1.244 0 1.944-.632 2.224-1.195h.058v1.029h1.43V9.035c0-2.19-1.724-2.625-2.919-2.625-1.361 0-2.615.548-3.105 1.92l1.376.313c.216-.534.765-1.048 1.749-1.048.89 0 1.372.438 1.425 1.2l.005.181c0 .394-.298.492-.995.579l-.905.102c-1.425.166-2.885.539-2.885 2.248 0 1.48 1.112 2.292 2.542 2.292zm.313-1.175c-.827 0-1.425-.372-1.425-1.097 0-.738.616-1.029 1.395-1.154l.726-.1c.503-.072 1.088-.178 1.234-.323v.97c0 .89-.71 1.704-1.93 1.704z"
                className="text"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarTextColorIcon);
export default Memo;
