// Utils
import getEditorJsonText from './getEditorJsonText';

// Types
import { EditorContent, ImEditorContent } from '../../../elements/elementModelTypes';

const NEWLINE_REGEX = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed

const getEditorJsonWordCount = (content: ImEditorContent | EditorContent | string | undefined): number => {
    const jsonText = getEditorJsonText(content);

    // The following has been taken from the Draft JS Plugins' Counter Plugin
    // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/counter/src/WordCounter/index.tsx
    const cleanString = jsonText.replace(NEWLINE_REGEX, ' ').trim(); // replace above characters w/ space
    const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
    return wordArray ? wordArray.length : 0;
};

export default getEditorJsonWordCount;
