import keyBindingFn from './keyBindingFn';
import handleKeyCommand from './handleKeyCommand';

/**
 * Requires the props:
 *  - saveCurrentContent, and
 *  - emptyDelete
 */
export default () => ({
    keyBindingFn,
    handleKeyCommand,
});
