// Lib
import { SelectionState } from 'draft-js';

export default (contentState) => {
    if (!contentState) return null;

    const firstBlock = contentState.getFirstBlock();
    const blockKey = firstBlock.getKey();

    const selection = SelectionState.createEmpty(blockKey);
    return selection.merge({
        anchorKey: blockKey,
        anchorOffset: 0,
        focusKey: blockKey,
        focusOffset: 0,
        isBackward: false,
        hasFocus: true,
    });
};
