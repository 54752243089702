import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAssignIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-assign"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M19 17a5 5 0 0 1 4.995 4.783L24 22v1a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-1a5 5 0 0 1 5-5h6zm0 1.5h-6A3.5 3.5 0 0 0 9.5 22v.5h13v-.468l-.003-.185A3.5 3.5 0 0 0 19 18.5zM16 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 1.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAssignIcon);
export default Memo;
