import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-card"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#CCC"
                d="M0 4.009C0 2.899.895 2 1.994 2h12.012C15.107 2 16 2.902 16 4.009v7.982c0 1.11-.895 2.009-1.994 2.009H1.994A2.003 2.003 0 0 1 0 11.991V4.01z"
            />
            <path
                fill="#FFF"
                d="M1 3.995A1 1 0 0 1 2.007 3h11.986C14.55 3 15 3.456 15 3.995v8.01a1 1 0 0 1-1.007.995H2.007A1.007 1.007 0 0 1 1 12.005v-8.01z"
            />
            <path
                fill="#767C86"
                d="M11.49 9c.25 0 .459.183.502.412L12 9.5c0 .276-.222.5-.51.5H3.51A.51.51 0 0 1 3 9.5c0-.276.222-.5.51-.5h7.98zm1-2c.25 0 .459.183.502.412L13 7.5c0 .276-.228.5-.51.5H3.51A.51.51 0 0 1 3 7.5c0-.276.228-.5.51-.5h8.98zm0-2c.25 0 .459.183.502.412L13 5.5c0 .276-.228.5-.51.5H3.51A.51.51 0 0 1 3 5.5c0-.276.228-.5.51-.5h8.98z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationCardIcon);
export default Memo;
