// Lib
import { getVisibleSelectionRect } from 'draft-js';
import getTextSelectionFocusElement from './getTextSelectionFocusElement';

/**
 * This uses the draft-js getVisibleSelectionRect function to determine the current position of the
 * cursor, and if it can't be found it attempts to calculate it from the native DOM Selection API.
 *
 * It might not be found by draft-js when on a new line as the size of the bounding box of the span will
 * be 0.
 */
const getSelectionRect = () => {
    const textSelectionRect = getVisibleSelectionRect(window);
    if (textSelectionRect) return textSelectionRect;

    const selectionElement = getTextSelectionFocusElement();

    if (!selectionElement) return null;

    return selectionElement.getBoundingClientRect();
};

export default getSelectionRect;
