// Utils
import * as pointLib from '../../../common/maths/geometry/point';
import { Point } from '../../../common/maths/geometry/pointTypes';

// Types
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';
import { DragAndDropSnapMode } from '../../workspace/dnd/dragLayerStateTypes';

type DragAndDropStateSingleton = {
    hoveredElementId: string | null;
    // Offset from the top left corner of the element that it's been grabbed
    scaledGrabOffset: Point | null;

    maxDraggedElementDimension: number;
    animatingDrag: boolean;
    elementRotation: number | null;
    targetOffset: Point | null;
    maxRotation: number | null;
    lastTimestamp: number | null;
    shadowIntensity: number;
    groupPosition: Point | null;
    snapMode: DragAndDropSnapMode;
};

/**
 * This is used to keep track of state while dragging and also passing the drag state through
 * to the drop handlers on drop.
 *
 * A singleton is used because the drag layer will update on every mouse movement due to
 * the drag offset changing, so storing this state in redux would be wasteful as re-renders
 * are already going to happen.
 *
 * Drag and drop is one of the most performance sensitive operations, so we're trying to
 * refrain from adding extra workload unnecessarily.
 *
 * This is being used:
 *  - By the elementLineEdgeDropTarget and DraggedLineEdgePreview in LineDragLayer
 *    to determine the element ID that's currently hovered so that we can snap the line to that
 *    element in the drag preview.
 *
 *  - To track the "sourceOffset" which is how far in from the top left corner has the element been grabbed.
 *    This is used by the comment thread so that switching between the pin and the full comment
 *    thread can be positioned correctly.
 *
 *  - To share the element rotation state between the element drag  layer and the rest of the app
 *    (specifically the DraggableElement).
 *    The final element rotation state is internal state to the drag layer, however it's
 *    required outside the drag layer so that the drop animation can complete the rotation animation.
 */
const dragAndDropState: DragAndDropStateSingleton = {
    hoveredElementId: null,
    // Offset from the top left corner of the element that it's been grabbed
    scaledGrabOffset: null,

    maxDraggedElementDimension: 0,
    animatingDrag: false,
    elementRotation: null,
    targetOffset: null,
    maxRotation: null,
    lastTimestamp: null,
    shadowIntensity: 0,
    groupPosition: null,
    snapMode: DragAndDropSnapMode.NONE,
};

/**
 * Sets the dragAndDropStateSingleton properties using the drag layer state.
 */
export const updateDragAndDropStateSingletonFromDragLayerData = (
    dragLayerData: DragAndDropStateSingleton,
    timestamp: number,
): void => {
    Object.assign(dragAndDropState, dragLayerData);
    dragAndDropState.lastTimestamp = timestamp;
};

export const clearDragAndDropStateSingleton = (): void => {
    dragAndDropState.hoveredElementId = null;
    dragAndDropState.scaledGrabOffset = null;

    dragAndDropState.maxDraggedElementDimension = 0;
    dragAndDropState.animatingDrag = false;
    dragAndDropState.elementRotation = null;
    dragAndDropState.targetOffset = null;
    dragAndDropState.maxRotation = null;
    dragAndDropState.lastTimestamp = null;
    dragAndDropState.shadowIntensity = 0;
    dragAndDropState.groupPosition = null;
    dragAndDropState.snapMode = DragAndDropSnapMode.NONE;
};

/**
 * Gets the "sourceClientOffset" that's appropriate for the canvasDropTargetDecorator to use.
 * It expects it to be the "target location" which is the current group position plus the target offset.
 * Then the canvasElementDropAnimationStateManager will use the target offset to animate from the offset to the target.
 */
export const getSnappedSourceClientOffsetFromDragAndDropSingleton = () => {
    if (!dragAndDropState.groupPosition) return null;
    if (!dragAndDropState.targetOffset) return null;

    return pointLib.difference(dragAndDropState.targetOffset, dragAndDropState.groupPosition);
};

export const updateDragDimension = (rect: Rect) => {
    dragAndDropState.maxDraggedElementDimension = Math.max(
        dragAndDropState.maxDraggedElementDimension,
        rect.width,
        rect.height,
    );
    return dragAndDropState.maxDraggedElementDimension;
};

export const getDragDimension = (): number => dragAndDropState.maxDraggedElementDimension;

export const clearDragDimension = (): void => {
    dragAndDropState.maxDraggedElementDimension = 0;
};

export default dragAndDropState;
