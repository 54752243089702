// Types
import { ReduxStore } from '../../types/reduxTypes';
import { AnyAction } from 'redux';

/**
 * Adds a "timing" when doing a performance capture, so that we
 * can see how long specific redux actions take.
 */
export default (store: ReduxStore) =>
    (next: Function) =>
    (action: AnyAction): void => {
        const { type } = action;

        performance.mark(`redux-action-${type}-start`);
        next(action);
        performance.mark(`redux-action-${type}-end`);
        performance.measure(`redux-action-${type}`, `redux-action-${type}-start`, `redux-action-${type}-end`);
    };
