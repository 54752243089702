/**
 * Converts an array into a map where the array entries are keys and their values are true.
 * This can be useful to use for better performance when checking for an entry's existence in an array.
 */
const asBoolMap = (arr) => {
    if (!arr) return {};

    return arr.reduce((acc, id) => {
        acc[id] = true;
        return acc;
    }, {});
};

export default asBoolMap;
