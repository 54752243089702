// Lib
import { toUpper } from 'lodash';

// Utils
import { getColorDisplayValue, getColorToPersist } from '../../../../../../common/colors/elementColorFormatUtil';
import { getCssPropertyForTextOnColor } from '../../../../../element/utils/elementColorStyleUtils';
import { getIsLightColor } from '../../../../../../common/colors/coreColorUtil';
import { parseColorObject } from '../../../../../../common/colors/colorObjectUtil';
import { getColorName } from '../../../../../../common/colors/colorNameUtil';
import { inferColorSpace } from '../../../../../../common/colors/colorSpaceUtil';

const CAPTION_EDITOR_TEXT_SELECTOR =
    '.Caption:not(.caption-modified) .Editor span[data-text]' +
    ', .Caption:not(.caption-modified) .CaptionTiptapEditor p';

/**
 * Prevent board colour transitions from lagging behind other colour changes while
 * using the colour wheel.
 */
export const setColorTargetTransitionEnabled = (enable) => {
    const colorTargets = document.querySelectorAll('.color-target, .background-color-target');
    const colorTargetArray = colorTargets ? Array.from(colorTargets) : [];

    colorTargetArray.forEach((domNode) => {
        enable ? domNode.classList.remove('no-transition') : domNode.classList.add('no-transition');
    });
};

/**
 * This function is used to improve the performance when using the colour picker to choose a custom colour.
 * To prevent thrashing state changes, instead of dispatching an action on every colour change (which happens
 * every animation frame when using the colour wheel) we'll simply select elements with the appropriate
 * classes and update their specific colours accordingly.
 */
export default (colorValue) => {
    const color = getColorToPersist(colorValue);
    const isLightColour = getIsLightColor(color);

    // CSS backgrounds
    const colorTargets = document.querySelectorAll('.color-target');
    const colorTargetArray = colorTargets ? Array.from(colorTargets) : [];

    colorTargetArray.forEach((domNode) => {
        domNode.style.backgroundImage = 'none';
        domNode.style.backgroundColor = color;
    });

    // Lines
    const lineColorTargets = document.querySelectorAll('.Line.selected');
    const lineColorTargetArray = lineColorTargets ? Array.from(lineColorTargets) : [];

    lineColorTargetArray.forEach((domNode) => {
        domNode.style.setProperty('--ws-line-color', color);
    });

    const lineTextColorTargets = document.querySelectorAll('.Line.selected .LineLabel');
    const lineTextColorTargetArray = lineTextColorTargets ? Array.from(lineTextColorTargets) : [];

    lineTextColorTargetArray.forEach((domNode) => {
        const colorVar = isLightColour
            ? 'var(--ws-element-dark-text-on-color)'
            : 'var(--ws-element-light-text-on-color)';
        domNode.style.setProperty('color', colorVar);
    });

    // Comment threads
    const commentThreadTargets = document.querySelectorAll('.CommentThreadCollapsed.selected');
    const commentThreadTargetArray = commentThreadTargets ? Array.from(commentThreadTargets) : [];

    commentThreadTargetArray.forEach((domNode) => {
        const textColor = isLightColour
            ? 'var(--ws-element-dark-text-on-color)'
            : 'var(--ws-element-light-text-on-color)';
        domNode.style.setProperty('--comment-thread-background-color', color);
        domNode.style.setProperty('--comment-thread-text-color', textColor);
    });

    // SVG Stroke
    const colorStrokeTargets = document.querySelectorAll('.selected .color-target-stroke');
    const colorStrokeTargetArray = colorStrokeTargets ? Array.from(colorStrokeTargets) : [];

    colorStrokeTargetArray.forEach((domNode) => {
        domNode.style.stroke = color;
    });

    // SVG Fill
    const colorFillTargets = document.querySelectorAll('.selected .color-target-fill');
    const colorFillTargetArray = colorFillTargets ? Array.from(colorFillTargets) : [];

    colorFillTargetArray.forEach((domNode) => {
        domNode.style.fill = color;
    });

    // Contrast colours
    const colorContrastTargets = document.querySelectorAll('.color-contrast-target');
    const colorContrastTargetsArray = Array.from(colorContrastTargets);

    colorContrastTargetsArray.forEach((domNode) => {
        // Contrast is the opposite to the colour
        isLightColour ? domNode.classList.remove('light') : domNode.classList.add('light');
    });

    // Color Swatch
    const colorSwatchTargets = document.querySelectorAll('.ColorSwatch.selected');
    const colorSwatchTargetArray = colorTargets ? Array.from(colorSwatchTargets) : [];

    colorSwatchTargetArray.forEach((domNode) => {
        // Update color
        const colorSwatchRenderer = domNode.querySelector('.ColorSwatchRenderer');
        if (colorSwatchRenderer) {
            colorSwatchRenderer.style.background = color;
        }

        // Update color value
        const colorValueCaptionContainer = domNode.querySelector('.ColorSwatchContentEditable');
        const colorValueCaption =
            domNode.querySelector('.ElementSimpleContentEditable .title-text span') ||
            domNode.querySelector('.ElementSimpleContentEditable');

        if (colorValueCaptionContainer && colorValueCaption) {
            const currentColorValue = colorValueCaption.textContent;

            const colorSpace = inferColorSpace(currentColorValue);
            const colorText = getColorDisplayValue(parseColorObject(colorValue, colorSpace));

            colorValueCaptionContainer.style.color = getCssPropertyForTextOnColor(colorValue);
            colorValueCaption.textContent = toUpper(colorText) || '';
        }

        // Update color name
        const colorNameCaption = domNode.querySelector(CAPTION_EDITOR_TEXT_SELECTOR);
        if (colorNameCaption) {
            colorNameCaption.textContent = getColorName(colorValue);
        }
    });

    // Element Paper Icon
    const elementPaperIcons = document.querySelectorAll('.selected .ElementPaperIcon');

    elementPaperIcons.forEach((domNode) => {
        if (isLightColour) {
            domNode.classList.add('background-light');
            domNode.classList.remove('background-dark');
        } else {
            domNode.classList.add('background-dark');
            domNode.classList.remove('background-light');
        }

        domNode.style.setProperty('--ws-document-icon-background-color', colorValue);
        domNode.style.setProperty('--ws-document-icon-fold-color', colorValue);
    });
};
