// Lib
import { createSelector } from 'reselect';

// Utils
import fileIsShowingFilePreview from '../../../../../element/file/utils/fileIsShowingFilePreview';
import {
    getElementType,
    getImageProp,
    getMedia,
    getMediaType,
    getPreviewReady,
    getShowMedia,
} from '../../../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { MEDIA_TYPES } from '../../../../../../common/links/richMediaConstants';
import { ElementType } from '../../../../../../common/elements/elementTypes';

const getIsActive = (element) => {
    switch (getElementType(element)) {
        case ElementType.LINK_TYPE:
            return getShowMedia(element);
        case ElementType.FILE_TYPE:
            return fileIsShowingFilePreview(element);
        default:
            return false;
    }
};

const linkCanShowMedia = (element) =>
    // TODO: 'hasAttachment' is legacy and should be removed once a migration is successful
    element.getIn(['content', 'link', 'hasAttachment']) ||
    getMediaType(element) === MEDIA_TYPES.IMAGE ||
    // If there's no mediaType property, but there is an image, the image will be shown
    // so we need to allow it to be hidden as well
    (!getMediaType(element) && !!getImageProp(element)) ||
    !!getMedia(element);

const canShowMedia = (element) => {
    switch (getElementType(element)) {
        case ElementType.LINK_TYPE:
            return linkCanShowMedia(element);
        case ElementType.FILE_TYPE:
            return getPreviewReady(element);
        case ElementType.DOCUMENT_TYPE:
            return true;
        default:
            return false;
    }
};

export const mediaToolSelector = createSelector(
    (state, ownProps) => ownProps.selectedElements,
    (selectedElements) => {
        const showTool = selectedElements.every(canShowMedia);

        const isActive = showTool && getIsActive(selectedElements.first());

        const hasOnlyImages =
            showTool &&
            selectedElements.every(
                (element) =>
                    // TODO: 'hasAttachment' is legacy and should be removed once a migration is successful
                    element.getIn(['content', 'link', 'hasAttachment']) ||
                    getMediaType(element) === MEDIA_TYPES.IMAGE ||
                    (!getMediaType(element) && !!getImageProp(element)),
            );

        const displayName = hasOnlyImages ? 'Image' : 'Preview';

        return {
            showTool,
            isActive,
            displayName,
        };
    },
);
