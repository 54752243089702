import { useState } from 'react';

export type UseModalSheetHookSheetProps = {
    isSheetOpen: boolean;
    setIsSheetOpen: (setIsSheetOpen: boolean) => void;
    activeSnapPoint: number;
    setActiveSnapPoint: (activeSnapPoint: number) => void;
};

type UseModalSheetReturnType = {
    openSheet: () => void;
    closeSheet: () => void;
    updateActiveSnapPoint: (activeSnapPoint: number) => void;
    sheetProps: UseModalSheetHookSheetProps;
};

const useModalSheet = (): UseModalSheetReturnType => {
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [activeSnapPoint, setActiveSnapPoint] = useState(0);

    const openSheet = () => {
        setIsSheetOpen(true);
    };

    const closeSheet = () => {
        setIsSheetOpen(false);
    };

    const updateActiveSnapPoint = (activeSnapPoint: number) => {
        setActiveSnapPoint(activeSnapPoint);
    };

    return {
        openSheet,
        closeSheet,
        updateActiveSnapPoint,
        sheetProps: {
            isSheetOpen,
            setIsSheetOpen,
            activeSnapPoint,
            setActiveSnapPoint,
        },
    };
};

export default useModalSheet;
