// Lib
import { isString } from 'lodash';

// Utils
import { ImMap, prop } from '../../../utils/immutableHelper';
import { getEditorType } from '../editorTypeUtils';
import getTiptapContentLines from './getTiptapContentLines';

// Types
import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { TiptapContentNode } from '../../tiptapTypes';
import { ElementEditor } from '../../../elements/elementEditorConstants';
import { EditorContent, ImEditorContent } from '../../../elements/elementModelTypes';

/**
 * Converts DraftJS blocks into an array of text strings.
 */
const getDraftBlocksText = (draftContent: RawDraftContentState | ImMap<RawDraftContentState>): string[] => {
    const blocks = prop('blocks', draftContent);

    if (!blocks) return [];

    return blocks.map((block: RawDraftContentBlock | ImMap<RawDraftContentBlock>) => prop('text', block));
};

/**
 * Converts the content of an editor into an array of text strings.
 *
 * NOTE: Currently this supports both DraftJS and Tiptap content.
 *  When Draft JS support is completely removed, this can be simplified.
 */
export const getEditorJsonContentLines = (
    editorContents: ImEditorContent | EditorContent | string | undefined,
): string[] => {
    if (isString(editorContents)) return [editorContents];

    const editorType = getEditorType(editorContents);

    switch (editorType) {
        case ElementEditor.DraftJs:
            return getDraftBlocksText(editorContents as ImMap<RawDraftContentState> | RawDraftContentState);
        case ElementEditor.Tiptap:
            return getTiptapContentLines(editorContents as ImMap<TiptapContentNode> | TiptapContentNode);
        default:
            return [];
    }
};
