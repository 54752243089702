const UTILITY_BUTTON_DISPLAY = {
    HOVER: 'display-on-hover',
    ALWAYS: 'display-always',
    NONE: 'display-none',
};

export const getUtilityButtonDisplayClassName = (isTouch: boolean, isParentListSelected: boolean): string | null => {
    if (!isTouch) return UTILITY_BUTTON_DISPLAY.HOVER;

    if (isTouch && isParentListSelected) return UTILITY_BUTTON_DISPLAY.ALWAYS;

    return UTILITY_BUTTON_DISPLAY.NONE;
};
