// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { trackAttachmentDownload } from '../../element/attachments/attachmentsService';

// Components
import ElectronHidden from '../electron/ElectronHidden';
import SwiftHybridHidden from '../visibility/SwiftHybridHidden';

const DownloadOpenLinkInBrowserButton = ({ children, divider, className, url }) => {
    const onClick = () => {
        trackAttachmentDownload({ url });
    };

    return (
        <SwiftHybridHidden>
            <ElectronHidden>
                {divider}

                <a
                    href={url}
                    onClick={onClick}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames('DownloadOpenLinkButton', className)}
                >
                    {children}
                </a>
            </ElectronHidden>
        </SwiftHybridHidden>
    );
};

DownloadOpenLinkInBrowserButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    divider: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    className: PropTypes.string,
    url: PropTypes.string,
};

export default DownloadOpenLinkInBrowserButton;
