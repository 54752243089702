/* eslint-disable prefer-rest-params,prefer-destructuring */
import * as Immutable from 'immutable';
import shallowEqual from 'shallowequal';
import { createSelectorCreator } from 'reselect';
import { isEqual } from 'lodash/fp';

const defaultArgumentEqualityCheck = (prev, next) => {
    // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
    const length = prev.length;
    for (let i = 0; i < length; i++) {
        if (prev[i] !== next[i]) return false;
    }

    return true;
};

const argumentsAreEqual = (argumentEqualityCheck, prev, next) => {
    if (prev === null || next === null || prev.length !== next.length) return false;
    if (prev === next) return true;

    return argumentEqualityCheck(prev, next);
};

/**
 * This selector:
 *  - Will run the selector function if all arguments are not exactly equal.
 *  - However it will return the exact same result if the returned value is:
 *      - exactly equal, or
 *      - shallowly equal for an object
 *
 *  MOTIVATION:
 *  The most important thing in React world is strict equality.
 *  Pure components won't re-render if its props are strictly equal, selectors
 *  won't run if their inputs are strictly equal & strict equality is very cheap to compute.
 *
 *  Thus, by ensuring the output of a selector is strictly equal when nothing has changed
 *
 *
 *  NOTE: This has been rebuilt to remove object spreading as there's a slight performance hit with object spreading.
 */
export const smartMilanoteMemoize = (
    fn,
    // The function to check equality of the arguments
    argumentEqualityCheck = defaultArgumentEqualityCheck,
    // The function to check equality of the result
    resultEqualityCheck = shallowEqual,
) => {
    let lastArgs = null;
    let lastResult = null;

    return function milanoteMemoizedFn() {
        // No changes to the arguments, so just return the previous result
        if (argumentsAreEqual(argumentEqualityCheck, lastArgs, arguments)) {
            return lastResult;
        }

        lastArgs = arguments;

        // Calculate the new result, then compare it to the last result
        const newResult = fn.apply(null, arguments);

        if (newResult === lastResult) return lastResult;

        // If the new result is null, return immediately
        if (!newResult) {
            lastResult = newResult;
            return lastResult;
        }

        let areResultsEqual = false;

        // Immutable structure
        if (newResult.hashCode) {
            // Using Immutable.is instead of hashCode, because hashCode only seems to be
            // quicker if you're using the same hashCode many times (as it internally caches its value).
            areResultsEqual = Immutable.is(newResult, lastResult);
            // Object
        } else {
            areResultsEqual = resultEqualityCheck(lastResult, newResult);
        }

        if (!areResultsEqual) {
            lastResult = newResult;
        }

        return lastResult;
    };
};

export const createShallowSelector = createSelectorCreator(smartMilanoteMemoize);
export const createCustomEqualShallowSelector = (argumentEqualityCheckFn) =>
    createSelectorCreator(smartMilanoteMemoize, argumentEqualityCheckFn);

/**
 * These selectors use a deep equality check to determine whether the results are the same,
 * and if they are deeply equal they will return the previous result.
 *
 * Deep equality can come at a cost for large / deep objects, so use these with caution.
 * They are well suited to cases where the output is very often deeply the same as it was previously and there's
 * many dependent selectors.
 */
export const createDeepSelector = createSelectorCreator(smartMilanoteMemoize, defaultArgumentEqualityCheck, isEqual);
export const createCustomEqualDeepSelector = (argumentEqualityCheckFn) =>
    createSelectorCreator(smartMilanoteMemoize, argumentEqualityCheckFn, isEqual);

/**
 * The general process when choosing selectors are:
 * - If the result is primitive, just use 'createSelector'
 * - If the result is not primitive but will always be a new value, just use createSelector.
 *      E.g. Inputs: x, y.  Output: { x, y }.
 *          The selector will only be invoked when the input changes, thus the output will never be
 *          shallowly equal to the previous result, so there's no point using the createShallowSelector
 * - If the result is often shallowly equal, use the createShallowSelector
 * - If the result is often deeply equal, and the deep equality check doesn't take too long,
 *      use the createDeepSelector.
 *
 * Make sure you use the debug selectors (in milanoteReselectDebugger.js) to validate your choices.
 */
