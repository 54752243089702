/*eslint-disable */

import loadable from 'react-loadable';


const File3d = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_3_d" */
        './jsx/file-3d'
    ),
    loading: () => null,
});
const FileAcrobat = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_acrobat" */
        './jsx/file-acrobat'
    ),
    loading: () => null,
});
const FileAftereffects = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_aftereffects" */
        './jsx/file-aftereffects'
    ),
    loading: () => null,
});
const FileAppleMovie = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_apple_movie" */
        './jsx/file-apple-movie'
    ),
    loading: () => null,
});
const FileAudio = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_audio" */
        './jsx/file-audio'
    ),
    loading: () => null,
});
const FileAxure = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_axure" */
        './jsx/file-axure'
    ),
    loading: () => null,
});
const FileCode = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_code" */
        './jsx/file-code'
    ),
    loading: () => null,
});
const FileDocument = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_document" */
        './jsx/file-document'
    ),
    loading: () => null,
});
const FileDreamweaver = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_dreamweaver" */
        './jsx/file-dreamweaver'
    ),
    loading: () => null,
});
const FileEmail = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_email" */
        './jsx/file-email'
    ),
    loading: () => null,
});
const FileExcel = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_excel" */
        './jsx/file-excel'
    ),
    loading: () => null,
});
const FileExperienceDesign = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_experience_design" */
        './jsx/file-experience-design'
    ),
    loading: () => null,
});
const FileFile = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_file" */
        './jsx/file-file'
    ),
    loading: () => null,
});
const FileFont = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_font" */
        './jsx/file-font'
    ),
    loading: () => null,
});
const FileHtml = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_html" */
        './jsx/file-html'
    ),
    loading: () => null,
});
const FileIllustrator = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_illustrator" */
        './jsx/file-illustrator'
    ),
    loading: () => null,
});
const FileImage = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_image" */
        './jsx/file-image'
    ),
    loading: () => null,
});
const FileIndesign = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_indesign" */
        './jsx/file-indesign'
    ),
    loading: () => null,
});
const FileKeynote = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_keynote" */
        './jsx/file-keynote'
    ),
    loading: () => null,
});
const FileLightroom = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_lightroom" */
        './jsx/file-lightroom'
    ),
    loading: () => null,
});
const FileMovie = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_movie" */
        './jsx/file-movie'
    ),
    loading: () => null,
});
const FileMuse = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_muse" */
        './jsx/file-muse'
    ),
    loading: () => null,
});
const FileNumbers = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_numbers" */
        './jsx/file-numbers'
    ),
    loading: () => null,
});
const FileOtf = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_otf" */
        './jsx/file-otf'
    ),
    loading: () => null,
});
const FilePages = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_pages" */
        './jsx/file-pages'
    ),
    loading: () => null,
});
const FilePhotoshop = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_photoshop" */
        './jsx/file-photoshop'
    ),
    loading: () => null,
});
const FilePlaintext = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_plaintext" */
        './jsx/file-plaintext'
    ),
    loading: () => null,
});
const FilePpt = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_ppt" */
        './jsx/file-ppt'
    ),
    loading: () => null,
});
const FilePremiere = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_premiere" */
        './jsx/file-premiere'
    ),
    loading: () => null,
});
const FileRawImage = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_raw_image" */
        './jsx/file-raw-image'
    ),
    loading: () => null,
});
const FileRtf = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_rtf" */
        './jsx/file-rtf'
    ),
    loading: () => null,
});
const FileSketch = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_sketch" */
        './jsx/file-sketch'
    ),
    loading: () => null,
});
const FileSvg = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_svg" */
        './jsx/file-svg'
    ),
    loading: () => null,
});
const FileWord = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_word" */
        './jsx/file-word'
    ),
    loading: () => null,
});
const FileZip = loadable({
    loader: () => import(
        /* webpackChunkName: "icon-file_zip" */
        './jsx/file-zip'
    ),
    loading: () => null,
});

export default {
    'file-3d': File3d,
    'file-acrobat': FileAcrobat,
    'file-aftereffects': FileAftereffects,
    'file-apple-movie': FileAppleMovie,
    'file-audio': FileAudio,
    'file-axure': FileAxure,
    'file-code': FileCode,
    'file-document': FileDocument,
    'file-dreamweaver': FileDreamweaver,
    'file-email': FileEmail,
    'file-excel': FileExcel,
    'file-experience-design': FileExperienceDesign,
    'file-file': FileFile,
    'file-font': FileFont,
    'file-html': FileHtml,
    'file-illustrator': FileIllustrator,
    'file-image': FileImage,
    'file-indesign': FileIndesign,
    'file-keynote': FileKeynote,
    'file-lightroom': FileLightroom,
    'file-movie': FileMovie,
    'file-muse': FileMuse,
    'file-numbers': FileNumbers,
    'file-otf': FileOtf,
    'file-pages': FilePages,
    'file-photoshop': FilePhotoshop,
    'file-plaintext': FilePlaintext,
    'file-ppt': FilePpt,
    'file-premiere': FilePremiere,
    'file-raw-image': FileRawImage,
    'file-rtf': FileRtf,
    'file-sketch': FileSketch,
    'file-svg': FileSvg,
    'file-word': FileWord,
    'file-zip': FileZip,
};
