// Lib
import * as Immutable from 'immutable';
import { includes } from 'lodash';

// Utils
import { asObject } from '../../../common/utils/immutableHelper';

// Constants
import * as SELECTION_ACTION_TYPES from '../../../common/elements/selectionConstants';
import { START_OPERATION, END_OPERATION } from '../../utils/undoRedo/undoRedoConstants';

const initialState = Immutable.fromJS({
    ids: [],
    // A map of list ID => element selection anchor ID
    rangeAnchors: {},
    keepSelection: false,
    meta: {},
});

const handleElementSelectAction = (state, action) =>
    state
        .update('ids', (ids) => ids.concat(action.ids))
        .update('rangeAnchors', (existingRangeAnchors) => {
            const existingRangeAnchorsPojo = asObject(existingRangeAnchors);

            const updatedRangeAnchors = {
                ...existingRangeAnchorsPojo,
                ...action.rangeAnchors,
            };
            return Immutable.fromJS(updatedRangeAnchors);
        });

const handleElementDeselectAction = (state, action) =>
    state
        .update('ids', (ids) => ids.filterNot((id) => includes(action.ids, id)))
        .update('rangeAnchors', (existingRangeAnchors) => {
            return existingRangeAnchors
                .map((anchorId) => (action.ids.includes(anchorId) ? null : anchorId))
                .filter((id) => id !== null);
        })
        .set('meta', Immutable.fromJS({}));

export default function selection(state = initialState, action) {
    switch (action.type) {
        case SELECTION_ACTION_TYPES.ELEMENTS_SELECTED:
            return handleElementSelectAction(state, action);
        case SELECTION_ACTION_TYPES.ELEMENTS_DESELECTED:
            return handleElementDeselectAction(state, action);
        case SELECTION_ACTION_TYPES.ELEMENTS_DESELECT_ALL:
            return state.get('keepSelection') ? state : initialState;
        case START_OPERATION:
            return state.set('keepSelection', true);
        case END_OPERATION:
            return state.set('keepSelection', false);
        case SELECTION_ACTION_TYPES.TABLE_ELEMENT_CELL_SELECTIONS_UPDATE: {
            const isElementSingleSelected = state.get('ids').size === 1 && state.get('ids').first() === action.id;
            if (!isElementSingleSelected) return state;

            return state.set('meta', Immutable.fromJS({ currentCellSelections: action.cellSelections }));
        }
        default:
            return state;
    }
}
