// Utils
import localStorageService from '../../../../../utils/services/localStorage/localStorage';

// Constants
const PUSH_NOTIFICATION_SUGGESTED_KEY = 'milanote.push.suggested';
const PUSH_NOTIFICATION_SUGGESTION_PERMANENTLY_DISMISSED_KEY = 'milanote.push.dismissed_permanently';

export const getHasPushBeenSuggested = () => localStorageService.getItem(PUSH_NOTIFICATION_SUGGESTED_KEY) || false;
export const getHasPushBeenPermanentlyDismissed = () =>
    localStorageService.getItem(PUSH_NOTIFICATION_SUGGESTION_PERMANENTLY_DISMISSED_KEY) || false;

export const setPushHasBeenSuggested = () => localStorageService.setItem(PUSH_NOTIFICATION_SUGGESTED_KEY, true);
export const setPushHasBeenPermanentlyDismissed = () =>
    localStorageService.setItem(PUSH_NOTIFICATION_SUGGESTION_PERMANENTLY_DISMISSED_KEY, true);
