// Lib
import React from 'react';
import classNames from 'classnames';

// Components
import Button, { ButtonProps } from './Button';

// Styles
import './StyledButton.scss';

const StyledButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function StyledButton(props, ref) {
    return <Button ref={ref} {...props} className={classNames('StyledButton', props.className)} />;
});

export default StyledButton;
