import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeTextIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-text"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                d="m7.238 19.9.541-1.664h2.635l.542 1.664h1.653l-2.516-7.3h-1.99l-2.52 7.3h1.655Zm.934-2.87.898-2.762h.057l.898 2.763H8.172Zm5.214 2.87h3.12c1.639 0 2.512-.855 2.512-2.017 0-1.08-.773-1.733-1.604-1.772v-.071c.76-.171 1.319-.71 1.319-1.569 0-1.094-.813-1.871-2.424-1.871h-2.923v7.3Zm1.544-1.262V16.7h1.293c.742 0 1.202.428 1.202 1.03 0 .55-.375.91-1.237.91H14.93Zm0-2.983v-1.808h1.172c.685 0 1.073.353 1.073.88 0 .578-.47.928-1.101.928H14.93Zm11.43-.5c-.202-1.685-1.479-2.655-3.168-2.655-1.929 0-3.4 1.362-3.4 3.75 0 2.381 1.446 3.75 3.4 3.75 1.871 0 3.001-1.244 3.169-2.588l-1.562-.007c-.146.78-.759 1.23-1.582 1.23-1.109 0-1.861-.824-1.861-2.385 0-1.519.741-2.385 1.871-2.385.845 0 1.455.489 1.572 1.29h1.562Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeTextIcon);
export default Memo;
