/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from '../../../../node_module_clones/react-transition-group';
import classNames from 'classnames';

// Components
import CardClipperButton from './CardClipperButton';

// Styles
import './CardClipperTool.scss';

class CardClipperTool extends React.Component {
    onPointerDown = (event) => {
        const { onMouseDown, onClipStart } = this.props;

        onMouseDown && onMouseDown(event);

        onClipStart && onClipStart();

        // If the mouse up or drag end doesn't end up being received by this component then
        // a double click drag has been performed, so catch the mouse up on the document
        document.addEventListener('mouseup', this.onPointerUp);
        document.addEventListener('touchend', this.onPointerUp);
    };

    onPointerUp = (event) => {
        const { onMouseUp } = this.props;

        onMouseUp && onMouseUp(event);

        // A mouse up is an unsuccessful drag
        this.onDragEnd(false);
    };

    beforeDragStart = (props) => {
        const { beforeDragStart } = this.props;

        beforeDragStart && beforeDragStart(props);

        document.removeEventListener('mouseup', this.onPointerUp);
        document.removeEventListener('touchend', this.onPointerUp);
    };

    onDragEnd = (dragSuccess) => {
        const { onDragEnd, onClipEnd } = this.props;

        onDragEnd && onDragEnd(dragSuccess);
        onClipEnd && onClipEnd(dragSuccess);

        document.removeEventListener('mouseup', this.onPointerUp);
        document.removeEventListener('touchend', this.onPointerUp);
    };

    render() {
        const { isClipping, selectionYMidpoint } = this.props;

        return (
            <CSSTransitionGroup
                transitionName="fade"
                transitionAppear
                transitionAppearTimeout={100}
                transitionEnterTimeout={100}
                transitionLeaveTimeout={100}
            >
                {selectionYMidpoint !== 0 ? (
                    <div
                        className={classNames('CardClipperTool', { clipping: isClipping })}
                        // The pointer events seem to get fired at the wrong time, so we need to use
                        // individual touch and mouse event handlers
                        onTouchStart={this.onPointerDown}
                        onTouchEnd={this.onPointerUp}
                        onMouseDown={this.onPointerDown}
                        onMouseUp={this.onPointerUp}
                    >
                        <CardClipperButton
                            {...this.props}
                            y={selectionYMidpoint}
                            beforeDragStart={this.beforeDragStart}
                            onDragEnd={this.onDragEnd}
                        />
                    </div>
                ) : null}
            </CSSTransitionGroup>
        );
    }
}

CardClipperTool.propTypes = {
    selectionYMidpoint: PropTypes.number,
    isClipping: PropTypes.bool,

    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    beforeDragStart: PropTypes.func,
    beforeDragEnd: PropTypes.func,
    onDragEnd: PropTypes.func,

    onClipStart: PropTypes.func,
    onClipEnd: PropTypes.func,
};

export default CardClipperTool;
