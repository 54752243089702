import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import chroma from 'chroma-js';

// Utils
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import { prop } from '../../../common/utils/immutableHelper';

// Components
import CardCloneSection from '../clone/cloneInstances/CardCloneSection';
import ColorBar from '../../components/colors/ColorBar';
import Caption from '../../components/caption/Caption';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import ElementResizeHandle from '../resizing/ElementResizeHandle';
import ResizableCardEditorContainer from './resizable/ResizableCardEditorContainer';
import CardFeatureSuggestions from './CardFeatureSuggestions';
import ElementActivityIndicator from '../activity/ElementActivityIndicator';
import QuickNotesActivityIndicator from '../activity/QuickNotesActivityIndicator';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import ElementReactions from '../reactions/ElementReactions';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';

// Helpers
import {
    getBackgroundColor,
    getCaption,
    getColor,
    getElementId,
    getSecondaryColor,
    getShowCaption,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getIsAutoHeight } from './cardSizeUtil';
import { getElementStyle } from '../../utils/grid/gridUtils';
import { getIsFeatureEnabled } from '../feature/elementFeatureUtils';
import { getColorCssValue } from '../utils/elementColorStyleUtils';
import { isHexColorFormat } from '../../../common/colors/colorSpaceUtil';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';
import { getPredefinedBackgroundColor } from '../../../common/colors/colorPresetsUtil';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { ELEMENT_FEATURES } from '../feature/elementFeatureConstants';

// Styles
import './Card.scss';

const getBackgroundColorClasses = (backgroundColor) => {
    if (!backgroundColor) return;

    const colorHex = isHexColorFormat(backgroundColor)
        ? backgroundColor
        : getPredefinedBackgroundColor(backgroundColor)?.hex || '#ffffff';

    // calculate expected contrast ratio of light text or dark text
    const contrastDarkText = chroma.contrast('#3d4554', colorHex);
    const contrastLightText = chroma.contrast('#ffffff', colorHex);

    // then use the highest contrast version
    const isLightBackground = contrastDarkText > contrastLightText;

    return {
        'colored-background': true,
        'colored-background-light': isLightBackground,
        'colored-background-dark': !isLightBackground,
    };
};

const Card = (props) => {
    const {
        element,
        gridSize,
        isClone,
        inList,
        isLocked,
        isEditable,
        elementEvents,
        tempSize,
        dispatchUpdateElement,
        cardEditorRef,
        focusOnEditor,
        isClipping,
        isClipDragging,
        isClipCopy,
        isSelected,
        isPresentational,
        currentBoardId,
        permissions,
        setParentHoveredChildAcceptsDrop,
        getContextZoomScale,
        getContextZoomTranslationPx,
        isPresentationModeEnabled,
    } = props;

    const captionVisible = getShowCaption(element);
    const isTransparencyEnabled = getIsFeatureEnabled(ELEMENT_FEATURES.TRANSPARENT)(element);
    const colorName = getColor(element);

    const transparent = isTransparencyEnabled;

    const backgroundColor = getBackgroundColor(element);
    const colorCss = getColorCssValue(backgroundColor);

    const secondaryColor = getSecondaryColor(element);
    const secondaryColorCss = getColorCssValue(secondaryColor);

    const cardClasses = classNames('Card drag-handle contrast-shadow', {
        clipping: isClipping,
        'clip-copy': isClipCopy,
        'clip-dragging': isClipDragging,
        'background-color-target': isSelected,
        'background-color-contrast-target': isSelected,
        ...getBackgroundColorClasses(backgroundColor),
    });

    const classes = elementClassNames(cardClasses, { ...props, transparent });

    const elementId = getElementId(element);
    const captionContent = getCaption(element);

    const savedCardWidth = getWidth(element);
    const cardWidth = prop('width', tempSize) || savedCardWidth || ELEMENT_DEFAULT_WIDTH;

    const showResizeHandle = !isLocked && isEditable && !(inList && getIsAutoHeight(element));

    const elementStyle = {
        ...getElementStyle(cardWidth, gridSize, inList),
        '--ws-element-background-primary': colorCss,
        '--ws-element-primary': secondaryColorCss,
    };

    return (
        <div id={getDomElementId(elementId)} className={classes} {...elementEvents} style={elementStyle}>
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementActivityIndicator {...props} />
            <QuickNotesActivityIndicator {...props} />
            <ElementUserTag {...props} />
            <ResizableCardEditorContainer editorRef={cardEditorRef} transparent={transparent} {...props} />
            <ElementReactions {...props} />
            <CardCloneSection isClone={isClone} elementId={elementId} element={element} />
            {!isPresentationModeEnabled && (
                <CardFeatureSuggestions
                    focusOnEditor={focusOnEditor}
                    elementId={elementId}
                    element={element}
                    isClone={isClone}
                    cardWidth={cardWidth}
                    dispatchUpdateElement={dispatchUpdateElement}
                />
            )}
            <Caption
                {...props}
                placeholder="Add a description"
                textContent={captionContent}
                captionVisible={captionVisible}
            />
            {!isPresentational && (
                <ElementResizeHandle
                    {...props}
                    elementId={elementId}
                    defaultMaxWidth={ELEMENT_DEFAULT_WIDTH * 2 * gridSize}
                    showHandle={showResizeHandle}
                />
            )}
            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                gridSize={gridSize}
                elementId={elementId}
                element={element}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
        </div>
    );
};

Card.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    isLocked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isHovered: PropTypes.bool,
    isPresentational: PropTypes.bool,
    permissions: PropTypes.number,
    spellCheck: PropTypes.bool,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    saveContent: PropTypes.func,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    currentBoardId: PropTypes.string,
    createNewCard: PropTypes.func,
    onEmptyBackspace: PropTypes.func,
    saveCaptionContent: PropTypes.func,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    filterQuery: PropTypes.string,
    updateElementType: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    cardEditorRef: PropTypes.func,
    focusOnEditor: PropTypes.func,
    isPresentationModeEnabled: PropTypes.bool,

    isClipping: PropTypes.bool,
    isClipDragging: PropTypes.bool,
    isClipCopy: PropTypes.bool,

    setParentHoveredChildAcceptsDrop: PropTypes.func,
    isClone: PropTypes.bool,

    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default Card;
