// Lib
import { defer } from 'lodash';
import { NativeTypes } from 'react-dnd-html5-backend';
import * as Immutable from 'immutable';

// Utils
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import * as pointLib from '../../../common/maths/geometry/point';
import FileValidationError from '../../../common/error/FileValidationError';

// Constants
import { ELEMENT_CREATION_SOURCES, ELEMENT_DND_TYPE } from '../../../common/elements/elementConstants';
import { SHOW_TITLE_PROPERTY } from '../../../common/taskLists/taskListConstants';
import { LINE_CONTROL_POINT_DND_TYPE, LINE_EDGE_DND_TYPE } from '../../../common/lines/lineConstants';

export const CUSTOM_TYPES = {
    IMAGE_LINK: '_image_link_',
};

export const NATIVE_TYPES = [NativeTypes.FILE, NativeTypes.URL, NativeTypes.TEXT];

export const GROUPED_DND_TYPES = {
    ELEMENT: 'element',
    LINE: 'line',
    NATIVE: 'native',
};

export const getGroupedItemType = (itemType) => {
    switch (itemType) {
        case ELEMENT_DND_TYPE:
            return GROUPED_DND_TYPES.ELEMENT;
        case LINE_EDGE_DND_TYPE:
        case LINE_CONTROL_POINT_DND_TYPE:
            return GROUPED_DND_TYPES.LINE;
        case NativeTypes.FILE:
        case NativeTypes.TEXT:
        case NativeTypes.URL:
            return GROUPED_DND_TYPES.NATIVE;
        default:
            return null;
    }
};

/**
 * This helper function determines if a child component has already handled the drop event.
 *
 * @param monitor {DropTargetMonitor} The React DnD drop target monitor.
 * @returns {boolean} True if a child component has already handled the drop.
 */
export const dropTargetAlreadyHandled = (monitor) => {
    return !!monitor.getDropResult();
};

/**
 * The position from the top left of the dragged item to the actual grab position of the dragged item.
 * I expect this to be positive.
 */
export const getScaledGrabOffset = ({ clientOffset, sourceClientOffset }) =>
    pointLib.difference(sourceClientOffset, clientOffset);

// text dropping functionality
export const handleTextDrop = (getLocation) => (props, monitor, domNode) => {
    const { isEditable, createShortcutCard, currentBoardId } = props;

    if (!isEditable) return;

    const { text } = monitor.getItem();
    const location = getLocation(props, monitor, domNode);
    createShortcutCard({ location, text, currentBoardId });

    return {};
};

// file dropping functionality
export const handleFileDrop = (getLocation) => (props, monitor, domNode) => {
    const { isEditable, createShortcutFile, currentBoardId } = props;

    if (!isEditable) return;

    const { files } = monitor.getItem();
    files.forEach((file, i) => {
        const location = getLocation(props, monitor, domNode, i);

        createShortcutFile({
            location,
            file,
            currentBoardId,
            creationSource: ELEMENT_CREATION_SOURCES.DROPPED_ONTO_WINDOW,
        }).catch((err) => (err.type === FileValidationError.type ? null : Promise.reject(err)));
    });

    return {};
};

// url dropping functionality
export const handleUrlDrop = (getLocation) => (props, monitor, domNode) => {
    const { isEditable, createShortcutLink, currentBoardId, deselectAll } = props;
    if (!isEditable) return;
    const { urls } = monitor.getItem();

    urls.forEach((url, i) => {
        const location = getLocation(props, monitor, domNode, i);

        createShortcutLink({
            location,
            url,
            currentBoardId,
            creationSource: ELEMENT_CREATION_SOURCES.DROPPED_ONTO_WINDOW,
        })
            // Force the link to attempt resolution
            .then(deselectAll);
    });

    return {};
};

export const handleImageUrlDrop = (getLocation) => (props, monitor, domNode) => {
    const { isEditable, createShortcutImageLink, currentBoardId } = props;
    if (!isEditable) return;
    const { url, src, width, height, isDataUri } = monitor.getItem();

    const location = getLocation(props, monitor, domNode);

    createShortcutImageLink({ location, currentBoardId, src, url, width, height, isDataUri });

    return {};
};

// Object returned by this function will become the dropResult.
export const handleElementDrop =
    (getLocation, getDropState) => (props, monitor, domNode, acceptedElementIds, transactionId) => {
        const { getContextZoomScale = () => 1 } = props;

        const location = getLocation(props, monitor, domNode);
        const dropState = getDropState ? getDropState(props, monitor, domNode) : undefined;

        // The Milanote element (Card, Board, Column, etc.) that's accepting the drop
        const dropTargetElement =
            props?.element ||
            // For the canvas:
            props?.currentBoard;

        const zoomScaleOnDropTarget = getContextZoomScale();

        return {
            location,
            dropState,
            dropTargetElement,
            acceptedElementIds,
            transactionId,
            zoomScaleOnDropTarget,
            clientOffset: monitor?.getClientOffset(),
        };
    };

export const handleTaskDrop = (getTaskListLocation) => (props, monitor, domNode) => {
    const { createTaskList, dispatchHideElement, dispatchRevealElement } = props;
    const { draggedElementIds } = monitor.getItem();

    const dropLocation = getTaskListLocation(props, monitor, domNode);

    const { taskListElementId, transactionId } = createTaskList({
        location: dropLocation,
        content: {
            [SHOW_TITLE_PROPERTY]: false,
        },
    });

    // Hide until the move finishes to prevent the flash of empty task list
    // (there will instead be a flash of nothing)
    dispatchHideElement({ elementId: taskListElementId });
    // Reveal the task list after the move has completed
    defer(() => dispatchRevealElement({ elementId: taskListElementId }));

    const getTaskListChildLocation = () => getListChildNewLocation({ listId: taskListElementId });

    return handleElementDrop(getTaskListChildLocation)(
        props,
        monitor,
        domNode,
        draggedElementIds,
        transactionId,
        domNode,
    );
};

/**
 * This handles when task lists are dropped onto tasks / task lists.
 * We need to first delete the original task lists, then move the child tasks to the drop target task / task list.
 * This will perform the deleting of the tasks, the moving of the task lists will be handled in the
 * DraggableElement.
 */
export const handleTaskListDropOnTasks = (getLocation) => (props, monitor, domNode) => {
    const { dispatchMoveElementsToTrash, currentBoardId } = props;
    const { draggedElementIds, draggedElements } = monitor.getItem();

    // Delete the original task lists
    const transactionId = dispatchMoveElementsToTrash({
        elements: Immutable.List(draggedElements),
        currentBoardId,
    });

    // Return the location that the child tasks should be moved to
    return handleElementDrop(getLocation)(props, monitor, domNode, draggedElementIds, transactionId);
};
