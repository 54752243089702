import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorToolDrawIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        viewBox="0 0 18 19"
        className="Icon drawing-editor-tool-draw"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="m16.1.553.13.12 1.063 1.063c.8.8.894 2.052.249 2.959l-.108.14-1.682 2.02a2.489 2.489 0 0 1-.53 1.062l-.141.153-5.744 5.643a9.56 9.56 0 0 1-2.13 1.601l-.34.178-2.838 1.42a1.52 1.52 0 0 1-.544.153l-2.053.958a.999.999 0 0 1-1.378-1.226l.045-.108.764-2.244c.012-.14.043-.279.095-.413l.058-.133 1.42-2.839c.4-.799.91-1.537 1.514-2.193l.265-.276L9.96 2.95c.293-.294.64-.499 1.01-.616l2.162-1.8A2.298 2.298 0 0 1 16.1.553zm-3.76 3.373a.998.998 0 0 0-1.225 0l-.095.083-5.744 5.643-.254.266a7.977 7.977 0 0 0-1.072 1.488l-.173.325-1.419 2.839 1 1 2.839-1.42.325-.172a7.977 7.977 0 0 0 1.488-1.072l.266-.254 5.744-5.643.084-.094a1 1 0 0 0 0-1.226l-.084-.094-1.585-1.586-.095-.083zM14.243 1.7l-.087.062-1.046.871c.09.06.178.127.262.2l.123.116 1.586 1.585c.097.098.185.201.263.31l.86-1.033a.698.698 0 0 0 .027-.862l-.07-.081L15.1 1.805a.702.702 0 0 0-.856-.105z"
        />
    </svg>
);
const Memo = memo(DrawingEditorToolDrawIcon);
export default Memo;
