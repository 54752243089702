// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getDnd3dEffectsEnabledLocalStorage } from './interfaceSettingsService';

// Actions
import { setDnd3dEffects } from './interfaceSettingsActions';
import { areInterfaceSettingsInitialisedSelector } from './interfaceSettingsSelector';

const mapStateToProps = createStructuredSelector({
    isInitialised: areInterfaceSettingsInitialisedSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetDnd3dEffects: (enabled) => dispatch(setDnd3dEffects(enabled)),
});

const InterfaceSettingsManager = (props) => {
    const { isInitialised, dispatchSetDnd3dEffects } = props;

    // In the electron app, Set the theme into state on mount
    React.useEffect(() => {
        if (isInitialised) return;

        const persisted3dPreference = getDnd3dEffectsEnabledLocalStorage();
        dispatchSetDnd3dEffects(persisted3dPreference);
    }, []);

    return null;
};

InterfaceSettingsManager.propTypes = {
    isInitialised: PropTypes.bool,
    dispatchSetDnd3dEffects: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterfaceSettingsManager);
