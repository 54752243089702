// Lib
import { omit, forEach, isFunction } from 'lodash';

// Utils
import logger from '../logger/logger';
import { runIfAvailable } from './analyticsInvocationUtils';
import { getUtmData } from './utmClientUtils';
import { manuallyReportError } from './rollbarService';
import { tryUntil } from '../../common/utils/functionUtils';
import { getApplicationPlatform } from '../utils/platformUtils';
import platformSingleton from '../platform/platformSingleton';
import { isPlatformDesktopPwa, isPlatformElectronMac, isPlatformIframe } from '../platform/utils/platformDetailsUtils';

// Constants
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../common/analytics/statsConstants';
import { EVENT_TYPE_NAMES } from '../../common/analytics/amplitudeEventTypesUtil';
import { ROLLBAR_LEVELS } from './rollbarConstants';

const isAmplitudeAvailable = () => !!window.amplitude && isFunction(window.amplitude.getInstance);
const runIfAmplitudeIsAvailable = runIfAvailable('Amplitude', isAmplitudeAvailable);

export const getAmplitudeDeviceId = runIfAmplitudeIsAvailable(function getAmplitudeDeviceId() {
    return window.amplitude.getInstance()?.options?.deviceId;
});

export const enableAmplitudeTracking = runIfAmplitudeIsAvailable(function enableAmplitudeTracking(enable) {
    window.amplitude.getInstance().setOptOut(!enable);

    if (!enable) return;

    // Call enable tracking until Amplitude is initialised properly. This is required because we were
    // seeing cases where calling enableTracking does not reliably initialise Amplitude.
    tryUntil(
        () => window.amplitude.getInstance().enableTracking(),
        () => window.amplitude.getInstance()._isInitialized,
    )
        .then(() => {
            logger.info('%cAmplitude analytics successfully enabled', 'color: green');
        })
        .catch((error) => {
            manuallyReportError({
                errorMessage: 'Amplitude: Fail to enable Amplitude tracking',
                level: ROLLBAR_LEVELS.WARNING,
                error,
            });
        });
});

export const setAmplitudeUser = runIfAmplitudeIsAvailable(function setAmplitudeUser(user) {
    window.amplitude.getInstance().setUserId(user._id);
});

export const clearAmplitudeUser = runIfAmplitudeIsAvailable(function clearAmplitudeUser() {
    window.amplitude.getInstance().setUserId(null);
});

export const sendAmplitudeUserProperties = runIfAmplitudeIsAvailable(function sendAmplitudeUserProperties({
    userProperties,
}) {
    if (!userProperties) return;

    const hasExampleBoardId = !!userProperties[AMPLITUDE_USER_PROPS.EXAMPLE_BOARDS];
    if (hasExampleBoardId) {
        const identify = new window.amplitude.Identify().append(
            AMPLITUDE_USER_PROPS.EXAMPLE_BOARDS,
            userProperties[AMPLITUDE_USER_PROPS.EXAMPLE_BOARDS],
        );
        window.amplitude.identify(identify);
    }

    const otherUserProperties = omit(userProperties, [AMPLITUDE_USER_PROPS.EXAMPLE_BOARDS]);

    window.amplitude.getInstance().setUserProperties(otherUserProperties);
});

export const sendAmplitudeEvent = runIfAmplitudeIsAvailable(function sendAmplitudeEvent({
    eventType,
    eventProperties,
    userProperties,
}) {
    sendAmplitudeUserProperties({ userProperties });

    eventType && window.amplitude.getInstance().logEvent(eventType, eventProperties);
});

const manuallySetUtmData = runIfAmplitudeIsAvailable(function manuallySetAmplitudeUtmData() {
    const utmData = getUtmData(document.location.href, document.referrer);
    if (!utmData) return;

    const identify = new window.amplitude.Identify();

    forEach(utmData, (value, key) => {
        identify.set(key, value);
        identify.setOnce(`initial_${key}`, value);
    });

    window.amplitude.identify(identify);
});

export const initialiseAmplitude = runIfAmplitudeIsAvailable(function initialiseAmplitude(config) {
    const amplitudeConfig = {
        // Defer initialisation until we confirm that user has consented to performance cookies (see OneTrustInit.js)
        deferInitialization: true,

        forceHttps: true,

        // Allows an amplitude device ID to be specified via the "amp_device_id" parameter if set
        deviceIdFromUrlParam: true,

        // Google click identifier (if used)
        includeGclid: true,

        // UTM (Urchin traffic monitor) parameters
        includeUtm: true,

        // HTTP referrer header
        includeReferrer: true,

        // This is to ensure that requests will be made regardless of exiting page
        transport: 'beacon',

        trackingOptions: {
            ip_address: false,
        },

        // FIXME It looks like this is causing the loss first viewed marketing page
        // This is required to support UTM parameters set on the URL via public boards
        // saveParamsReferrerOncePerSession: false,
    };

    amplitudeConfig.platform = getApplicationPlatform();

    window.amplitude
        .getInstance()
        .init(config.analytics.amplitudeApiKey, null, amplitudeConfig, () =>
            logger.info('%cAmplitude analytics successfully loaded', 'color: green'),
        );
});

export const initialiseAmplitudeData = runIfAmplitudeIsAvailable(function initialiseAmplitudeData(config) {
    if (isPlatformIframe(platformSingleton)) {
        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.LOADED_IN_IFRAME,
            eventProperties: {
                referrer: document.referrer,
            },
        });
    }

    if (isPlatformElectronMac(platformSingleton)) {
        window.amplitude.getInstance().setVersionName(config.appVersion);
        sendAmplitudeUserProperties({
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.DESKTOP_APP]: true },
            },
        });
    } else if (isPlatformDesktopPwa(platformSingleton)) {
        sendAmplitudeUserProperties({
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.PWA]: true },
            },
        });
    } else {
        // Try to classify users as
        manuallySetUtmData();
    }
});
