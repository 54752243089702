// Utils
import { CheckoutPrice, ProductPricingScheme } from '../products/productTypes';
import { getSubscriptionProvider } from '../subscriptionPropertyUtil';

// Types
import {
    MNAppStoreSubscription,
    MNStripeSubscription,
    MNSubscription,
    MNSubscriptionProvider,
} from './subscriptionTypes';

export const isStripeSubscription = (subscription: MNSubscription): subscription is MNStripeSubscription =>
    getSubscriptionProvider(subscription) === MNSubscriptionProvider.STRIPE;

export const isAppStoreSubscription = (subscription: MNSubscription): subscription is MNAppStoreSubscription =>
    getSubscriptionProvider(subscription) === MNSubscriptionProvider.APPLE;

export const getSubscriptionMaxSeats = (
    pricingScheme: ProductPricingScheme,
    quantity: number,
    price: CheckoutPrice,
): number => {
    switch (pricingScheme) {
        case ProductPricingScheme.SEAT:
            return quantity;
        case ProductPricingScheme.TIERED:
            return price.maxSeats || 1;
        case ProductPricingScheme.FREE:
        default:
            return 0;
    }
};
