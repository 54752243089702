import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import {
    getElementId,
    getImageProp,
    getIsTransparentImage,
    getShowBackground,
} from '../../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateSelectedElements } from '../../../../../element/actions/elementActions';

// Selectors
import { getAttachments } from '../../../../../element/attachments/attachmentsSelector';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Styles
import './ShowBackgroundTool.scss';
import { propIn } from '../../../../../../common/utils/immutableHelper';

const getToolActive = (selectedElements) => getShowBackground(selectedElements?.first()) || false;

const shouldShowTool = (selectedElements, attachments) =>
    selectedElements.reduce((acc, element) => {
        // Once true, stay true
        if (acc) return acc;

        // If the image has transparency then show it
        if (getIsTransparentImage(element)) return true;

        // We already have fetched image data, so no need to check the attachments
        if (getImageProp(element) || !attachments) return false;

        // Otherwise, check to see if there's an attachment for this element with transparency
        const elementId = getElementId(element);
        return propIn([elementId, 'data', 'transparent'], attachments);
    }, false);

const toolSelector = createSelector(
    (state, props) => props.selectedElements,
    getAttachments,
    (selectedElements, attachments) => {
        const isActive = getToolActive(selectedElements);
        const showTool = shouldShowTool(selectedElements, attachments);

        return {
            isActive,
            showTool,
        };
    },
);

const mapDispatchToProps = (dispatch, ownProps) => ({
    onPointerDown: (event) => {
        const showBackgroundValue = getToolActive(ownProps.selectedElements);
        const changes = { showBackground: !showBackgroundValue };
        dispatch(updateSelectedElements({ changes }));
    },
});

const ShowBackgroundTool = (props) => {
    if (!props.showTool) return null;

    return (
        <ToolbarTool {...props} name={props.name} isActive={!props.isActive} className="ShowBackgroundTool">
            <Icon name="toolbar-transparent" />
        </ToolbarTool>
    );
};

ShowBackgroundTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
    showTool: PropTypes.bool,
};

export default connect(toolSelector, mapDispatchToProps)(ShowBackgroundTool);
