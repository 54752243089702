export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_SET_STATE = 'MODAL_SET_STATE';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export enum ModalIds {
    SHORTCUTS = 'SHORTCUTS',
    SHARE = 'SHARE',
    TABLE_ANNOUNCEMENT = 'TABLE_ANNOUNCEMENT',
    AI_REPORT_FEEDBACK = 'AI_REPORT_FEEDBACK',
}
