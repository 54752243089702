// Lib
import * as Immutable from 'immutable';
import { isUndefined, isNull } from 'lodash/fp';

// Services
import { getBrowserPrefersReducedMotion, getDnd3dEffectsEnabledLocalStorage } from './interfaceSettingsService';

// Constants
import { DND_3D_EFFECTS_SET } from './interfaceSettingsConstants';

// This is taken from browser.js - but re-implemented here because electron can't reference window
export const isTablet = () =>
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        navigator.userAgent.toLowerCase(),
    );

const createInitialState = () => {
    // electron app is unable to read from localStorage initially as it runs in the main thread,
    // so defer to the ThemeManager to initialise the value
    if (typeof screen === 'undefined') {
        return Immutable.Map({
            enableDnd3dEffects: true,
            initialised: false,
        });
    }

    const persistedDnd3dEffects = getDnd3dEffectsEnabledLocalStorage();

    const enableDnd3dEffects =
        isUndefined(persistedDnd3dEffects) || isNull(persistedDnd3dEffects)
            ? // if user has no preference, default to browser setting
              !getBrowserPrefersReducedMotion()
            : // otherwise, check user preference value
              persistedDnd3dEffects !== 'false';

    return Immutable.Map({
        enableDnd3dEffects,
        initialised: true,
    });
};

const initialState = createInitialState();

export default (state = initialState, action) => {
    switch (action.type) {
        case DND_3D_EFFECTS_SET:
            return state.set('enableDnd3dEffects', action.enabled).set('initialised', true);
        default:
            return state;
    }
};
