// Lib
import { createSelector } from 'reselect';

// Utils
import { asObject } from '../../../common/utils/immutableHelper';

// Types
import { RectSize } from '../../../common/maths/geometry/rect/rectTypes';

export const getScrollAreaSize = (state: any): RectSize | null => state.getIn(['app', 'canvas', 'scrollAreaSize']);
export const getScrollAreaSizeAsObject = createSelector(getScrollAreaSize, asObject);
