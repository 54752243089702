// Lib
import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, toLower } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import { getGridSize } from '../../../utils/grid/gridSizeSelector';
import { getAttachment, getData } from '../../attachments/attachmentsSelector';
import { hasAltModifier, hasCommandModifier } from '../../../utils/keyboard/keyboardUtility';
import { hasProgressiveImageLoaded } from '../../../components/images/progressiveImages/progressiveImagesSingleton';
import { getElementId, getImageThumbnail, getImageProp } from '../../../../common/elements/utils/elementPropertyUtils';
import { getImageSource } from '../imageHelper';

// Selectors
import { getIsFeatureEnabledForCurrentUser } from '../../feature/elementFeatureSelector';
import { currentBoardHasMediaDownloadAccessSelector } from '../../../utils/permissions/elementPermissionsSelector';
import { isPresentationModeEnabledSelector } from '../../../workspace/presentation/presentationSelector';

// Actions
import { navigateToElement } from '../../../reducers/navigationActions';
import { setSelectedElements } from '../../selection/selectionActions';

// Hooks
import useObserveWindowSize from '../../../utils/dom/useObserveWindowSize';
import useDocumentKeyEventHandler from '../../drawing/drawingEditor/drawingEditorCanvas/useDocumentKeyEventHandler';

// Utils

// Components
import ImageModalNavigation from './ImageModalNavigation';
import ImageModalToolbar from './toolbar/ImageModalToolbar';
import ImageModalModeSwitch from './content/ImageModalContent';

// Constants
import { IMAGE_MODAL_MODES } from './imageModalConstants';
import { KEY_CODES } from '../../../utils/keyboard/keyConstants';
import { IMAGE_SIZES } from '../../../../common/images/imageConstants';
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';

// Styles
import './ImageModal.scss';

/**
 * Determines the state that the modal should show in.
 */
const getMode = ({ attachment, isEditable, isEditing, isEditingDrawing }) => {
    if (isEditable && isEditing) return IMAGE_MODAL_MODES.CROP;

    if (isEditable && isEditingDrawing) return IMAGE_MODAL_MODES.DRAW;

    // Uploading if we have attachment data
    if (!!getData(attachment)) return IMAGE_MODAL_MODES.UPLOAD;

    return IMAGE_MODAL_MODES.VIEW;
};

const getIsEditingQueryParam = get(['query', 'editing']);
const getIsEditingDrawingQueryParam = get(['query', 'editing-drawing']);

const useSecureMediaUrl = true;

const renderSecureImagesThroughCFSelector = getIsFeatureEnabledForCurrentUser(
    ExperimentId.renderSecureImagesThroughCF,
    // Default to enabled for guest users
    true,
);

const lowerQualityImageUrlSelector = createSelector(
    (_, { element }) => element,
    renderSecureImagesThroughCFSelector,
    (element, renderSecureImagesThroughCF) => {
        const imageDetails = getImageProp(element);
        const elementId = getElementId(element);
        const imageUrls = [IMAGE_SIZES.LARGE, IMAGE_SIZES.REGULAR].map((imageSize) =>
            getImageSource({
                imageDetails,
                imageSize: imageSize.name,
                elementId,
                useSecureMediaUrl,
                renderSecureImagesThroughCF,
            }),
        );

        return imageUrls.find((url) => hasProgressiveImageLoaded(url)) || getImageThumbnail(element);
    },
);

const mapStateToProps = createStructuredSelector({
    attachment: getAttachment,
    lowerQualityImageUrl: lowerQualityImageUrlSelector,
    gridSize: getGridSize,
    showDownloadOption: currentBoardHasMediaDownloadAccessSelector,
    isPresentationMode: isPresentationModeEnabledSelector,
});

const mapDispatchToProps = (dispatch) => ({
    selectElement: (elementId) => {
        dispatch(setSelectedElements({ ids: [elementId] }));
    },
    setIsEditing: (elementId, isEditing) => {
        dispatch(
            navigateToElement({
                elementId,
                params: { editing: isEditing },
            }),
        );
    },
    setIsEditingDrawing: (elementId, isEditingDrawing) => {
        dispatch(
            navigateToElement({
                elementId,
                params: { 'editing-drawing': isEditingDrawing },
            }),
        );
    },
});

const ImageModal = (props) => {
    const {
        element,
        isEditable,
        location,
        setOutsideModalChildren,
        setIsEditing,
        setIsEditingDrawing,
        close,
        showDownloadOption,
        selectElement,
    } = props;

    const idealHeightRef = useRef();
    const setIdealHeight = useCallback((height) => {
        idealHeightRef.current = height;
    }, []);

    const elementId = getElementId(element);
    const isEditing = getIsEditingQueryParam(location) === 'true';
    const isEditingDrawing = getIsEditingDrawingQueryParam(location) === 'true';

    // If the modal was opened in editing drawing mode we should return to the canvas rather than the modal
    const [returnToCanvas] = useState(isEditing || isEditingDrawing);

    const mode = getMode({ ...props, isEditing, isEditingDrawing });

    const setMode = useCallback(
        (newMode) => {
            if (!isEditable) return;

            switch (newMode) {
                case IMAGE_MODAL_MODES.CROP:
                    return setIsEditing(elementId, true);
                case IMAGE_MODAL_MODES.DRAW:
                    return setIsEditingDrawing(elementId, true);
                default:
                    return setIsEditingDrawing(elementId, false);
            }
        },
        [elementId, isEditing, setIsEditing, setIsEditingDrawing],
    );

    const [windowWidth, windowHeight] = useObserveWindowSize();

    useEffect(() => {
        selectElement(elementId);
    }, [elementId]);

    useEffect(() => {
        // If the canvas image maintains focus then it will edit the caption whenever keys are pressed
        // eslint-disable-next-line no-unused-expressions
        document.activeElement?.blur?.();

        setOutsideModalChildren(
            <ImageModalNavigation
                element={element}
                showNavigation={mode === IMAGE_MODAL_MODES.VIEW || mode === IMAGE_MODAL_MODES.UPLOAD}
                uploadingAttachment={false}
            />,
        );
    }, [element, mode]);

    const onKeyDown = useCallback(
        (event) => {
            if (hasAltModifier(event) || hasCommandModifier(event) || !isEditable) return;

            switch (event.keyCode) {
                case KEY_CODES.P:
                case KEY_CODES.D:
                    return setIsEditingDrawing(elementId, true);
                default:
                    return null;
            }
        },
        [elementId],
    );

    useDocumentKeyEventHandler({
        onKeyDown,
    });

    const finishEditingMode = () => {
        if (returnToCanvas) return close();
        setMode(elementId, false);
    };

    return (
        <div className={classNames('ImageModal', toLower(mode))}>
            <ImageModalModeSwitch
                {...props}
                mode={mode}
                idealHeight={idealHeightRef.current}
                setIdealHeight={setIdealHeight}
                finishEditingMode={finishEditingMode}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                returnToCanvas={returnToCanvas}
                setIsEditingDrawing={setIsEditingDrawing}
            />
            {showDownloadOption && (
                <ImageModalToolbar
                    {...props}
                    mode={mode}
                    setMode={setMode}
                    setIsEditing={setIsEditing}
                    setIsEditingDrawing={setIsEditingDrawing}
                    showDownloadOption={showDownloadOption}
                />
            )}
        </div>
    );
};

ImageModal.propTypes = {
    element: PropTypes.object,
    attachment: PropTypes.object,
    location: PropTypes.object,
    lowerQualityImageUrl: PropTypes.string,
    isEditable: PropTypes.bool,
    showDownloadOption: PropTypes.bool,
    gridSize: PropTypes.number,
    setOutsideModalChildren: PropTypes.func,
    close: PropTypes.func,
    selectElement: PropTypes.func,
    setIsEditing: PropTypes.func,
    setIsEditingDrawing: PropTypes.func,
    closeCallbackRef: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
