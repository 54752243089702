// Lib
import { createSelector } from 'reselect';
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';

// Utils
import { isElementOrAncestorInTrash } from '../../../common/elements/utils/elementTraversalUtils';

// Selectors
import { getElements } from '../../element/selectors/elementsSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';

// Types
import { NavigationHistoryState } from './navigationHistoryReducer';

export const getNavigationHistory = (state: any): NavigationHistoryState => state.getIn(['app', 'navigationHistory']);

export const getSortedViewedBoardIds = createShallowSelector(
    getNavigationHistory,
    (navigationHistory: Immutable.Map<string, number>) => {
        const jsMap: {
            [key: string]: number;
        } = navigationHistory.toJS();

        return Object.entries(jsMap)
            .sort(([_idA, timestampA], [_idB, timestampB]) => timestampB - timestampA)
            .map(([id]) => id);
    },
);

/**
 * This filters out any viewed board that has since been deleted.
 */
export const getSortedViewableViewedBoardIds = createShallowSelector(
    getSortedViewedBoardIds,
    getElements,
    (viewedBoardIds, elements) => viewedBoardIds.filter((boardId) => !isElementOrAncestorInTrash(elements, boardId)),
);

export const getPreviousViewedBoardId = createSelector(
    getSortedViewedBoardIds,
    getCurrentBoardId,
    (viewedBoards, currentBoardId: string): string | null => {
        if (!viewedBoards || viewedBoards.length <= 1) return null;
        // Unfortunately the order of the sorted viewed boards isn't quite correct.
        // The currently viewed board is at index 1 and the previously viewed board is at position 0
        // The rest of the boards are in order of most recently viewed.
        // This is due to the selector recording viewed timestamps when navigating out of a board.
        return viewedBoards[0] === currentBoardId ? viewedBoards[1] : viewedBoards[0];
    },
);
