import * as Immutable from 'immutable';

import {
    MEDIA_PLAYER_PLAY,
    MEDIA_PLAYER_PAUSE,
    MEDIA_PLAYER_SET_PROGRESS,
    MEDIA_PLAYER_SET_VOLUME,
    DEFAULT_PLAYER_STATE,
    MEDIA_PLAYER_SET_MUTE,
    MEDIA_PLAYER_SET_DRAG_PREVIEW,
    MEDIA_PLAYER_SET_DURATION,
    MEDIA_PLAYER_ERROR,
    MEDIA_PLAYER_RESET,
} from './mediaPlayerConstants';

const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case MEDIA_PLAYER_PLAY:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) =>
                player.set('playing', true).set('hasBeenPlayed', true),
            );
        case MEDIA_PLAYER_PAUSE:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) => player.set('playing', false));
        case MEDIA_PLAYER_SET_PROGRESS:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) =>
                player.set('progress', action.progress),
            );
        case MEDIA_PLAYER_SET_DURATION:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) =>
                player.set('duration', action.duration),
            );
        case MEDIA_PLAYER_SET_VOLUME:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) => player.set('volume', action.volume));
        case MEDIA_PLAYER_SET_MUTE:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) => player.set('mute', action.mute));
        case MEDIA_PLAYER_SET_DRAG_PREVIEW:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) =>
                player.set('dragPreview', action.dragPreview),
            );
        case MEDIA_PLAYER_ERROR:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) => player.set('error', action.error));
        case MEDIA_PLAYER_RESET:
            return state.update(action.playerId, DEFAULT_PLAYER_STATE, (player) =>
                player.set('playing', false).set('progress', 0).set('hasBeenPlayed', false),
            );
        default:
            return state;
    }
};
