import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const LinkMapIconIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon link-map-icon"
        {...props}
    >
        <defs>
            <filter id="LinkMapIconIcon__a" colorInterpolationFilters="auto">
                <feColorMatrix
                    in="SourceGraphic"
                    values="0 0 0 0 0.639216 0 0 0 0 0.654902 0 0 0 0 0.682353 0 0 0 1.000000 0"
                />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#LinkMapIconIcon__a)">
            <path
                fill="#E84C4C"
                d="M8 0c3.59 0 6.5 3.026 6.5 6.757 0 1.831-.703 3.49-1.84 4.706l-.018.022-.022.023-.05.053-4.06 4.22a.703.703 0 0 1-1.02 0l-4.06-4.22-.05-.053-.022-.023-.017-.022A6.87 6.87 0 0 1 1.5 6.757C1.5 3.026 4.41 0 8 0zm0 4a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5z"
            />
        </g>
    </svg>
);
const Memo = memo(LinkMapIconIcon);
export default Memo;
