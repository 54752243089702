import { cond, stubTrue } from 'lodash';

// Constants
import { DEFAULT_NEW_ELEMENT_DRAG_OFFSET_GRID_UNITS } from './dndConstants';
import { DEFAULT_LINE_WIDTH, DEFAULT_LINE_HEIGHT } from '../../../common/lines/lineConstants';
import { MNElement } from '../../../common/elements/elementModelTypes';
import { DEFAULT_ICON_VIEW_WIDTH } from '../../../common/elements/elementDisplayModeConstants';

// Utils
import { isIconViewLike } from '../../../common/elements/utils/elementDisplayUtils';
import { isLine } from '../../../common/elements/utils/elementTypeUtils';
import * as pointLib from '../../../common/maths/geometry/point';
import { Point } from '../../../common/maths/geometry/pointTypes';

// Utils for calculating the drag origin
type DragOriginArgs = {
    item: any;
    gridSize: number;
    initialClientOffset: Point;
    initialSourceClientOffset: Point;
};

export const getIconViewIconCentreOffset = (gridSize: number) =>
    pointLib.getPoint((DEFAULT_ICON_VIEW_WIDTH * gridSize) / 2, (DEFAULT_ICON_VIEW_WIDTH * gridSize) / 2);

const sourceIsIconViewInList = ({ item }: DragOriginArgs) => item.fromList && isIconViewLike(item.element);

export const getDefaultDragOrigin = ({ initialSourceClientOffset, initialClientOffset }: DragOriginArgs) =>
    pointLib.difference(initialSourceClientOffset, initialClientOffset);

export const getElementDragOrigin = cond<DragOriginArgs, Point>([
    [sourceIsIconViewInList, ({ gridSize }: DragOriginArgs) => getIconViewIconCentreOffset(gridSize)],
    [stubTrue, getDefaultDragOrigin],
]);

const getNewIconViewIconCentreOffset = (gridSize: number) => pointLib.scale(-1, getIconViewIconCentreOffset(gridSize));

/**
 * Need to reposition lines so that the arrow is being held right in the middle.
 */
const getNewLineDragOffset = (gridSize: number) =>
    pointLib.scale(-gridSize, pointLib.getPoint(DEFAULT_LINE_WIDTH / 2, DEFAULT_LINE_HEIGHT / 2));

const getDefaultDragDropPosition = (gridSize: number) =>
    pointLib.scale(-gridSize, DEFAULT_NEW_ELEMENT_DRAG_OFFSET_GRID_UNITS);

export const getDefaultElementOffset = ({ element, gridSize }: { element: MNElement; gridSize: number }) => {
    if (isIconViewLike(element)) return getNewIconViewIconCentreOffset(gridSize);
    if (isLine(element)) return getNewLineDragOffset(gridSize);
    return getDefaultDragDropPosition(gridSize);
};
