import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AiAutoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon ai-auto"
        {...props}
    >
        <path
            fill="#2F63F7"
            fillRule="evenodd"
            d="M9.93 1.616c.395-.328.968.08.788.56L8.71 7.529H13a.5.5 0 0 1 .383.824l-.065.063-7.25 5.97c-.395.325-.966-.082-.786-.562l1.985-5.296L3 8.53a.5.5 0 0 1-.384-.821l.064-.064 7.25-6.03zM8.335 8.528l-1.461 3.899 4.733-3.898-3.272-.001zm.784-4.938L4.383 7.529h3.259L9.119 3.59z"
        />
    </svg>
);
const Memo = memo(AiAutoIcon);
export default Memo;
