// Lib
import React from 'react';
import Handsontable from 'handsontable/base';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { registerEditor } from 'handsontable/editors';
import {
    AutoColumnSize,
    Autofill,
    ContextMenu,
    CustomBorders,
    Formulas,
    ManualColumnMove,
    ManualRowResize,
    ManualRowMove,
    MultipleSelectionHandles,
    registerPlugin,
    UndoRedo,
} from 'handsontable/plugins';

// Custom Plugins/Editors
import MilanoteFormulaCellReferencePlugin from './modules/MilanoteFormulaCellReferencePlugin';
import MilanoteFormulaAutocompletePlugin from './modules/MilanoteFormulaAutocompletePlugin';
import MilanoteEditingPlugin from './modules/MilanoteEditingPlugin';
import MilanoteEditingCleanupPlugin from './modules/MilanoteEditingCleanupPlugin';
import MilanoteContextMenuPlugin, { MILANOTE_CONTEXT_MENU_PLUGIN_NAME } from './modules/MilanoteContextMenuPlugin';
import MilanoteManualColumnResizePlugin from './modules/MilanoteManualColumnResizePlugin';
import MilanoteCopyPastePlugin from './modules/MilanoteCopyPastePlugin';

import MilanoteCellEditor from './modules/MilanoteCellEditor';
import MilanoteCellEditorComponent from './modules/MilanoteCellEditorComponent';

import hyperFormulaInstance from './manager/hyperFormulaInstance';

// Config
import getClientConfig from '../../utils/getClientConfig';

// Components
import TableResizeHandlers from './handlers/TableResizeHandlers';
import TableCellEditingHandlers from './handlers/TableCellEditingHandlers';
import TableCellSelectionHandlers from './handlers/TableCellSelectionHandlers';
import TableColumnResizeHandlers from './handlers/TableColumnResizeHandlers';
import TableMoveColumnRowHandlers from './handlers/TableMoveColumnRowHandlers';
import TableScrollHandlers from './handlers/TableScrollHandlers';
import TableContextMenuHandlers from './handlers/TableContextMenuHandlers';
import TableOperations from './handlers/TableOperations';
import TablePopups from './TablePopups';
import HotTableManager from './manager/HotTableManager';
import HotTableRendererPortalManager from './manager/HotTableRendererPortalManager';
import TableStateSyncHandlers from './handlers/TableStateSyncHandlers';

// Utils
import { markEventAsHandled } from '../../utils/react/reactEventUtil';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { hasChanged } from '../../utils/react/propsComparisons';
import { getCellValueArray } from './utils/tableDataUtils';
import { convertColWidthsGUtoPx, getColWidthsResizeWidth } from './utils/tableSizeUtils';
import { addClassToHotTable, removeClassFromHotTable } from './utils/tableDOMUtils';

// Selectors
import {
    getTableContentColWidthsGUSelector,
    getTableContentDataSelector,
    isCurrentlyEditingTableCell,
} from './tableSelector';
import { getThemeIsDarkMode } from '../../user/account/accountModal/interface/themeSettings/themeSelector';
import { getCurrentlyEditingEditorIdForElement } from '../selection/currentlyEditingSelector';
import { getIsRedoing, getIsUndoing } from '../../utils/undoRedo/undoRedoSelector';
import { userCurrencyPreferenceSelector, userLanguagePreferenceSelector } from '../../user/currentUserSelector';

// Types
import { TableAxis, TableOperation } from '../../../common/table/TableTypes';

// Styles
import './HotTable.scss';
import './HotTableRefHeaders.scss';
import './HotContextMenu.scss';

const config = getClientConfig();

// Register Handonstable plugins
registerPlugin(ContextMenu);
registerPlugin(AutoColumnSize);
registerPlugin(ManualRowResize);
registerPlugin(ManualColumnMove);
registerPlugin(ManualRowMove);
registerPlugin(Formulas);
registerPlugin(CustomBorders);
registerPlugin(UndoRedo);
registerPlugin(Autofill);
registerPlugin(MultipleSelectionHandles);

// Register Hansontable custom plugins
registerPlugin(MilanoteManualColumnResizePlugin);
registerPlugin(MilanoteFormulaCellReferencePlugin);
registerPlugin(MilanoteFormulaAutocompletePlugin);
registerPlugin(MilanoteEditingPlugin);
registerPlugin(MilanoteEditingCleanupPlugin);
registerPlugin(MilanoteContextMenuPlugin);
registerPlugin(MilanoteCopyPastePlugin);

// Register Hansontable custom editor
registerEditor('milanoteCellEditor', MilanoteCellEditor);

const isSingleSelectedHasChanged = hasChanged('isSingleSelected');

const mapStateToProps = (state, { elementId }) =>
    createStructuredSelector({
        tableContentData: getTableContentDataSelector(),
        tableContentColWidthsGU: getTableContentColWidthsGUSelector(),
        currentlyEditingEditorId: getCurrentlyEditingEditorIdForElement(elementId),
        isCurrentlyEditingTableCell: isCurrentlyEditingTableCell(elementId),
        isDarkMode: getThemeIsDarkMode,
        isUndoingOrRedoing: (state) => getIsUndoing(state) || getIsRedoing(state),
        locale: userLanguagePreferenceSelector,
        currencyPreference: userCurrencyPreferenceSelector,
    });

class HotTable extends React.Component {
    constructor(props) {
        super(props);

        this.hotTableInstanceRef = props.hotTableInstanceRef;

        this.cellEditorRef = React.createRef();
        this.dropdownRef = React.createRef();

        this.tableCellEditingHandlersRef = React.createRef();
        this.tableCellSelectionHandlersRef = React.createRef();
        this.tableColumnResizeHandlersRef = React.createRef();
        this.tableMoveColumnRowHandlersRef = React.createRef();
        this.tableContextMenuHandlersRef = React.createRef();
        this.tableResizeHandlersRef = React.createRef();
        this.tableOperationsRef = React.createRef();
        this.hotTableRendererPortalManagerRef = React.createRef();

        this.state = {
            mounted: false,
            currentlyEditingCellEditorCoords: null,
            shouldCoverTable: !props.isSingleSelected,
        };
    }

    componentDidMount() {
        const {
            element,
            elementId,
            tableContentData,
            tableContentColWidthsGU,
            hotTableContainerRef,
            hotTableInstanceRef,
            gridSize,
            isDarkMode,
            inList,
            isReadOnly,
            isResizing,
            getContextZoomScale,
            showTitle,
            showCaption,
            locale,
            filterQuery,
        } = this.props;

        HotTableManager.addHotTableComponent(elementId, this);

        const tableContainer = hotTableContainerRef.current;

        const resizeToWidth = getColWidthsResizeWidth(inList, hotTableContainerRef, getContextZoomScale);
        const tableContentColWidthsPx = convertColWidthsGUtoPx(tableContentColWidthsGU, gridSize, resizeToWidth);

        hotTableInstanceRef.current = new Handsontable(tableContainer, {
            licenseKey: config.handsontable?.licenseKey,
            data: getCellValueArray(tableContentData),

            editor: isReadOnly ? null : 'milanoteCellEditor',
            renderer: this.hotTableRendererPortalManagerRef.current.getRendererWrapper(),

            colHeaders: true,
            rowHeaders: true,
            autoColumnSize: false,
            autoRowSize: false,
            manualRowMove: true,
            manualColumnMove: true,
            columnHeaderHeight: 0,
            rowHeaderHeight: 0,
            height: 'auto',
            formulas: { engine: hyperFormulaInstance, sheetName: elementId },
            manualColumnResize: tableContentColWidthsPx,
            manualRowResize: true,
            contextMenu: this.tableContextMenuHandlersRef.current.getTableContextMenuConfig(),
            outsideClickDeselects: false,
            colWidths: this.tableColumnResizeHandlersRef.current.getDefaultColWidth,
            rowHeights: this.tableColumnResizeHandlersRef.current.getDefaultRowHeight,

            // NOTE: Needs to be in the function format to avoid the `this` context being lost
            afterInit() {
                // This is a hack to get the table to get handsontable context menu event only triggered when there are
                // cell selections
                this.getPlugin(MILANOTE_CONTEXT_MENU_PLUGIN_NAME).removeHotContextMenuEventListeners();

                // This is a hack to force handsontable to open keyboard on cell selection on the iPad.
                // Handsontable, by default, does not do this: https://github.com/handsontable/handsontable/pull/5529
                const copyPastePluginInstance = this.getPlugin('CopyPaste');
                if (copyPastePluginInstance?.focusableElement?.focus && !isReadOnly) {
                    copyPastePluginInstance.focusableElement.focus = function () {
                        this.mainElement.value = ' ';
                        this.mainElement.select();
                    };
                }

                // This is a hack to force remove the minimum row height of 23px that handsontable sets
                // when setting row height
                const manualRowResizePluginInstance = this.getPlugin('manualRowResize');
                if (manualRowResizePluginInstance) {
                    manualRowResizePluginInstance.setManualSize = function (row, height) {
                        const physicalRow = this.hot.toPhysicalRow(row);
                        this.rowHeightsMap.setValueAtIndex(physicalRow, height);
                        return height;
                    };
                }
            },

            afterViewRender: (...args) => this.hotTableRendererPortalManagerRef.current.afterViewRender(...args),

            beforeChange: (...args) => this.tableCellEditingHandlersRef.current.beforeChange(...args),
            afterChange: (...args) => this.tableCellEditingHandlersRef.current?.afterChange(...args),
            afterCreateRow: (index, amount, source) =>
                this.tableCellEditingHandlersRef.current.afterGridAltered(
                    TableAxis.ROW,
                    TableOperation.INSERT,
                    index,
                    amount,
                    source,
                ),
            afterCreateCol: (index, amount, source) =>
                this.tableCellEditingHandlersRef.current.afterGridAltered(
                    TableAxis.COL,
                    TableOperation.INSERT,
                    index,
                    amount,
                    source,
                ),
            afterRemoveRow: (index, amount, _, source) =>
                this.tableCellEditingHandlersRef.current.afterGridAltered(
                    TableAxis.ROW,
                    TableOperation.REMOVE,
                    index,
                    amount,
                    source,
                ),
            afterRemoveCol: (index, amount, _, source) =>
                this.tableCellEditingHandlersRef.current.afterGridAltered(
                    TableAxis.COL,
                    TableOperation.REMOVE,
                    index,
                    amount,
                    source,
                ),

            beforeAutofill: (...args) => this.tableCellEditingHandlersRef.current.beforeAutofill(...args),
            beforeCopy: (...args) => this.tableCellEditingHandlersRef.current.beforeCopy(...args),
            beforeCut: (...args) => this.tableCellEditingHandlersRef.current.beforeCut(...args),
            beforePaste: (...args) => this.tableCellEditingHandlersRef.current.beforePaste(...args),
            beforeOnCellMouseDown: (...args) => {
                this.tableCellEditingHandlersRef.current.beforeOnCellMouseDown(...args);
                this.tableMoveColumnRowHandlersRef.current.beforeOnCellMouseDown(...args);
            },

            afterRowMove: (...args) =>
                this.tableCellEditingHandlersRef.current.afterGridMovement(TableAxis.ROW, ...args),
            afterColumnMove: (...args) =>
                this.tableCellEditingHandlersRef.current.afterGridMovement(TableAxis.COL, ...args),

            afterSelection: (...args) => {
                requestAnimationFrame(() => {
                    // Applied using plain javascript to improve performance, to avoid triggering another render
                    addClassToHotTable(hotTableInstanceRef.current, 'is-selecting-cell');
                });
                this.tableCellSelectionHandlersRef.current.afterSelection(...args);
            },
            afterSelectionEnd: (...args) => {
                requestAnimationFrame(() => {
                    // Applied using plain javascript to improve performance, to avoid triggering another render
                    removeClassFromHotTable(hotTableInstanceRef.current, 'is-selecting-cell');
                });
                this.tableCellSelectionHandlersRef.current.afterSelectionEnd(...args);
            },
            afterDeselect: (...args) => {
                this.tableCellSelectionHandlersRef.current.afterDeselect(...args);
            },

            afterColumnResize: (...args) => this.tableColumnResizeHandlersRef.current.afterColumnResize(...args),

            milanoteEditing: {
                tableOperationsRef: this.tableOperationsRef,
                isReadOnly,
                filterQuery,
            },
            milanoteFormulaCellReference: {
                cellEditorRef: this.cellEditorRef,
            },
            milanoteFormulaAutocomplete: {
                enabled: true,
                cellEditorRef: this.cellEditorRef,
                dropdownRef: this.dropdownRef,
            },
        });

        hotTableInstanceRef.current.milanoteProps = {
            gridSize,
            isDarkMode,
            showTitle,
            showCaption,
            getContextZoomScale,
            hotTableContainerRef,
            isResizing,
            elementId: getElementId(element),
            locale,
            filterQuery,
        };

        this.setState({ mounted: true });
    }

    componentDidUpdate(prevProps) {
        if (isSingleSelectedHasChanged(prevProps, this.props)) {
            // When isSingleSelected prop has changed, wait for a bit before covering table with overlay, this is to
            // allow all the necessary functions to complete before the table is covered
            if (this.props.isSingleSelected) {
                setTimeout(() => {
                    this.setState({ shouldCoverTable: false });
                }, 0);
            } else {
                this.setState({ shouldCoverTable: true });
            }
        }
    }

    componentWillUnmount() {
        const { element, hotTableInstanceRef } = this.props;
        HotTableManager.removeHotTableComponent(getElementId(element), this);

        // Destroy the hot table hooks
        Handsontable.hooks.destroy(hotTableInstanceRef.current);

        // Destroy the hot table instance
        // This is done in a setTimeout to ensure that the hot table instance is destroyed after
        // the hot table is finished moving (this happens when the table is being moved to a new location)
        setTimeout(() => {
            hotTableInstanceRef.current?.destroy();
        });
    }

    onMouseDown = (event) => {
        // This is done in order to stop the parent element (in ElementContainer) to handle this event when other areas of
        // the table is clicked.

        const { isSingleSelected } = this.props;
        isSingleSelected && markEventAsHandled(event);
    };

    startEditingCellEditor(cellCoords) {
        this.setState({ currentlyEditingCellEditorCoords: cellCoords });

        requestAnimationFrame(() => {
            const { dispatchStartEditingTableCell, elementId, isCurrentlyEditingTableCell } = this.props;
            if (!isCurrentlyEditingTableCell) dispatchStartEditingTableCell(elementId);
        });
    }

    finishEditingCellEditor() {
        this.setState({ currentlyEditingCellEditorCoords: null });

        requestAnimationFrame(() => {
            const { dispatchStartEditingTableGrid, isCurrentlyEditingTableCell } = this.props;
            if (isCurrentlyEditingTableCell) dispatchStartEditingTableGrid();
        });
    }

    render() {
        const { gridSize, hotTableContainerRef, hotTableInstanceRef, currentCellSelections } = this.props;
        const { mounted, currentlyEditingCellEditorCoords } = this.state;

        const nRows = hotTableInstanceRef.current?.countRows();
        const nCols = hotTableInstanceRef.current?.countCols();

        const isSelectedLastRow =
            !!currentCellSelections &&
            currentCellSelections.some(([startRow, , endRow]) => startRow === nRows - 1 || endRow === nRows - 1);
        const isSelectedLastCol =
            !!currentCellSelections &&
            currentCellSelections.some(([, startCol, , endCol]) => startCol === nCols - 1 || endCol === nCols - 1);

        return (
            <div
                className={classNames('HotTable', {
                    'is-selected-last-col': isSelectedLastCol,
                    'is-selected-last-row': isSelectedLastRow,
                })}
                onMouseDown={this.onMouseDown}
            >
                <TableScrollHandlers
                    tableOperationsRef={this.tableOperationsRef}
                    shouldCoverTable={this.state.shouldCoverTable}
                    {...this.props}
                />

                <TableOperations ref={this.tableOperationsRef} {...this.props} />

                <TableCellEditingHandlers
                    ref={this.tableCellEditingHandlersRef}
                    tableOperationsRef={this.tableOperationsRef}
                    mounted={mounted}
                    {...this.props}
                />
                <TableCellSelectionHandlers
                    ref={this.tableCellSelectionHandlersRef}
                    tableOperationsRef={this.tableOperationsRef}
                    {...this.props}
                />
                <TableColumnResizeHandlers
                    ref={this.tableColumnResizeHandlersRef}
                    tableOperationsRef={this.tableOperationsRef}
                    mounted={mounted}
                    {...this.props}
                />
                <TableResizeHandlers
                    ref={this.tableResizeHandlersRef}
                    tableOperationsRef={this.tableOperationsRef}
                    {...this.props}
                />
                <TableMoveColumnRowHandlers
                    ref={this.tableMoveColumnRowHandlersRef}
                    tableOperationsRef={this.tableOperationsRef}
                    {...this.props}
                />
                <TableContextMenuHandlers
                    ref={this.tableContextMenuHandlersRef}
                    tableOperationsRef={this.tableOperationsRef}
                    {...this.props}
                />

                <TableStateSyncHandlers
                    tableOperationsRef={this.tableOperationsRef}
                    mounted={mounted}
                    {...this.props}
                />

                <MilanoteCellEditorComponent
                    {...this.props}
                    cellEditorRef={this.cellEditorRef}
                    hotTableContainerRef={hotTableContainerRef}
                    hotTableInstanceRef={hotTableInstanceRef}
                    gridSize={gridSize}
                    currentlyEditingCellEditorCoords={currentlyEditingCellEditorCoords}
                    // Pass in mounted prop to ensure that the MilanoteCellEditorComponent
                    // is re-rendered after HotTable is mounted
                    mounted={mounted}
                />

                <TablePopups {...this.props} dropdownRef={this.dropdownRef} cellEditorRef={this.cellEditorRef} />

                <HotTableRendererPortalManager ref={this.hotTableRendererPortalManagerRef} />

                <div className="HotTableLib" ref={hotTableContainerRef}></div>
            </div>
        );
    }
}

HotTable.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    tableContentData: PropTypes.array,
    tableContentColWidthsGU: PropTypes.array,
    dispatchUpdateTableElement: PropTypes.func,
    gridSize: PropTypes.number,
    tempSize: PropTypes.object,
    currentCellSelections: PropTypes.object,
    isPresentational: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isResizing: PropTypes.bool,
    isDarkMode: PropTypes.bool,
    inList: PropTypes.string,
    isReadOnly: PropTypes.bool,
    showTitle: PropTypes.bool,
    showCaption: PropTypes.bool,
    isCurrentlyEditingTableCell: PropTypes.bool,
    locale: PropTypes.string,
    filterQuery: PropTypes.string,

    dispatchSetCellSelection: PropTypes.func,
    dispatchStartEditingTableCell: PropTypes.func,
    dispatchFinishEditingElement: PropTypes.func,

    dispatchUndoAction: PropTypes.func,
    dispatchRedoAction: PropTypes.func,
    dispatchDeselectElement: PropTypes.func,
    dispatchStartEditingTableGrid: PropTypes.func,

    hotTableInstanceRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    hotTableContainerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    getContextZoomScale: PropTypes.func,
};

export default connect(mapStateToProps)(HotTable);
