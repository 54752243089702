// Lib
import * as Immutable from 'immutable';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import {
    SOCKET_CONNECTION_STATUS,
    SOCKET_CONNECT,
    SOCKET_DISCONNECT,
    CHANNELS_UPDATED,
    SOCKET_INTERRUPTION,
    SOCKET_RECONNECTION_MODE,
} from './socketConstants';

const initialState = Immutable.Map({
    // TODO Determine if this is still correct!
    status: SOCKET_CONNECTION_STATUS.DISCONNECTED,
    reconnectionMode: SOCKET_RECONNECTION_MODE.PAGE_LOAD,
    channels: Immutable.List(),
    boardChannels: Immutable.List(),
});

const updateChannels = (state, action) =>
    state.withMutations((mutableState) =>
        mutableState
            .update('channels', (channels) =>
                channels
                    .filter((channelId) => action.left.indexOf(channelId) === -1)
                    .concat(Immutable.List(action.joined)),
            )
            .set('boardChannels', Immutable.List(action.boardChannels)),
    );

export default (state = initialState, action) => {
    switch (action.type) {
        case SOCKET_CONNECT:
            return state.set('status', SOCKET_CONNECTION_STATUS.CONNECTED);
        case SOCKET_INTERRUPTION:
            return state
                .set('status', SOCKET_CONNECTION_STATUS.INTERRUPTED)
                .set('reconnectionMode', SOCKET_RECONNECTION_MODE.CONNECTION_LOST)
                .set('interruptionTime', getTimestamp());
        case SOCKET_DISCONNECT:
            return state
                .set('status', SOCKET_CONNECTION_STATUS.DISCONNECTED)
                .set('reconnectionMode', SOCKET_RECONNECTION_MODE.CONNECTION_LOST)
                .set('disconnectTime', getTimestamp());
        case CHANNELS_UPDATED:
            return updateChannels(state, action);
        default:
            return state;
    }
};
