// Lib
import { connect } from 'react-redux';

// Constants
import { LiveCollaborationStoreContext } from './LiveCollaborationStoreProvider';

export default (...args) => {
    const opts = {
        ...args[3],
        context: LiveCollaborationStoreContext,
        getDisplayName: (component) => `LiveCollaborationStoreConnect(${component})`,
    };
    return connect(args[0], args[1], args[2], opts);
};
