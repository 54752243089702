import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingInputApplePencilIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon drawing-input-apple-pencil"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="m15.972 6.251.267.015c1.356.04 2.613.687 3.39 1.745-1.227.72-1.983 1.987-2.001 3.36.002 1.553.978 2.954 2.48 3.56a8.704 8.704 0 0 1-1.29 2.501c-.76 1.083-1.557 2.14-2.821 2.16-1.229.027-1.642-.689-3.05-.689-1.423 0-1.863.67-3.042.716-1.204.042-2.124-1.155-2.912-2.228-1.574-2.19-2.8-6.171-1.156-8.88.772-1.32 2.214-2.159 3.8-2.208 1.205-.023 2.325.776 3.066.776.727 0 2.11-.956 3.536-.813Zm.282-4.25a3.82 3.82 0 0 1-.947 2.824 3.553 3.553 0 0 1-2.75 1.239 3.64 3.64 0 0 1 .972-2.72 4.284 4.284 0 0 1 2.725-1.343Z"
        />
    </svg>
);
const Memo = memo(DrawingInputApplePencilIcon);
export default Memo;
