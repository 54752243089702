import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingEditorUndoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon drawing-editor-undo"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M5 8 0 4l5-4-.001 3.25H10a6 6 0 1 1 0 12H7.75a.75.75 0 0 1 0-1.5H10a4.5 4.5 0 0 0 4.495-4.288l.005-.212a4.5 4.5 0 0 0-4.288-4.495L10 4.75H4.999L5 8z"
        />
    </svg>
);
const Memo = memo(DrawingEditorUndoIcon);
export default Memo;
