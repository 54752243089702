// Constants
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

export default {
    [STYLE_COMMANDS.CODE]: {
        fontFamily: 'RobotoMono, Menlo, Courier New, Courier, monospace',
        fontSize: 'var(--font-size-ui-small)',
        wordWrap: 'break-word',
        background: 'var(--ws-text-code-background-color)',
        color: 'var(--ws-text-code-color)',

        // Using `em` below to follow the font-size defined by its container,
        // will result in smaller values in document preview
        padding: '0 0.6em 0.1em',
        borderRadius: '0.5em',
    },
};
