import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransitionGroup } from '../../../../node_module_clones/react-transition-group';

import Icon from '../../../components/icons/Icon';
import Spinner from '../../../components/loaders/Spinner';

// Utils
import { getColor } from '../../../../common/elements/utils/elementPropertyUtils';
import { getColorCssValue } from '../../../element/utils/elementColorStyleUtils';
import { getIsLowContrastColorWithInterface } from '../../../../common/colors/colorComparisonUtil';

import './BreadcrumbIcon.scss';

const BreadcrumbIcon = ({ element, isRoot, isLoading, isDarkModeTheme }) => {
    if (!element && !isRoot) return null;

    const colorName = getColor(element);
    const cssBackgroundValue = getColorCssValue(colorName);

    const iconStyle = {
        backgroundColor: isRoot ? 'transparent' : cssBackgroundValue,
    };

    const classes = classNames('BreadcrumbIcon', {
        loading: isLoading,
        'low-contrast': !isRoot && getIsLowContrastColorWithInterface(cssBackgroundValue, isDarkModeTheme),
    });

    return (
        <div className={classes} style={iconStyle}>
            {isRoot && <Icon name="milanote-logo-small" />}
            <CSSTransitionGroup
                key="spinner"
                transitionName="scale"
                transitionAppear
                transitionAppearTimeout={300}
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {isLoading && <Spinner show strokeWeightMultiplier={2} />}
            </CSSTransitionGroup>
        </div>
    );
};

BreadcrumbIcon.propTypes = {
    element: PropTypes.object,
    isRoot: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDarkModeTheme: PropTypes.bool,
};

export default BreadcrumbIcon;
