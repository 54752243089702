/* eslint-disable */
// Lib
import { createSelector } from 'reselect';

// Utils
import { asObject, prop, propIn } from '../../../common/utils/immutableHelper';
import { syncedMeasurements } from '../../components/measurementsStore/elementMeasurements/syncedMeasurementsSingleton';
import { createDeepSelector, createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { isLocationCanvas } from '../../../common/elements/utils/elementLocationUtils';
import { isColumn } from '../../../common/elements/utils/elementTypeUtils';
import { getControlPosition } from './utils/lineControlPointUtil';
import * as pointLib from '../../../common/maths/geometry/point';
import { getTopLeft } from '../../../common/maths/geometry/rect';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getMeasurementFromCache } from '../../components/measurementsStore/elementMeasurements/measurementsCacheSingleton';
import {
    enforceLineMinLength,
    getAlignedColumnLineOrigins,
    getEdgeOriginAndRects,
    getLineMinLength,
    getLinePointsOfInterest,
} from './utils/lineUtil';
import {
    getCanvasOriginCoordinates,
    getIsLineEndSnapped,
    getIsLineStartSnapped,
    getLineControl,
    getLineEndStyle,
    getLineStartStyle,
    getLocationParentId,
    getLocationPosition,
} from '../../../common/elements/utils/elementPropertyUtils';
import { memoizeResultShallow } from '../../../common/utils/lib/memoizeResult';

// Selectors
import { getElement, getElements } from '../selectors/elementSelector';
import { getElementPositionFromStateFirstSelector } from '../selectors/elementPositionSelector';
import {
    getMeasurementsMap,
    getMeasurementsMapFromProps,
} from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';
import {
    getIsPresentationModeHideCommentsEnabled,
    isPresentationModeEnabledSelector,
} from '../../workspace/presentation/presentationSelector';

// Constants
import { LINE_EDGE } from '../../../common/lines/lineConstants';

import { ElementType } from '../../../common/elements/elementTypes';

// ----- LINE PROPERTY GETTERS ----- //
export const getLineControlSelector = (state, ownProps) => getLineControl(getElement(state, ownProps));
export const getLineStartStyleSelector = (state, ownProps) => getLineStartStyle(getElement(state, ownProps));
export const getLineEndStyleSelector = (state, ownProps) => getLineEndStyle(getElement(state, ownProps));

export const getIsLineStartSnappedSelector = (state, ownProps) => getIsLineStartSnapped(getElement(state, ownProps));
export const getIsLineEndSnappedSelector = (state, ownProps) => getIsLineEndSnapped(getElement(state, ownProps));

export const getLineEdge = (edge) => (state, ownProps) => propIn(['content', edge], getElement(state, ownProps));

const getConnectedElementId = (edge) => (state, ownProps) => {
    const lineEdge = getLineEdge(edge)(state, ownProps);
    if (!prop('snapped', lineEdge)) return null;
    return prop('elementId', lineEdge);
};

const makeGetColumnElementPosition = () =>
    // We want to run this function each time so that we use the latest synced measurements,
    // but if the result is the same we don't want to be returning a new object
    // Just using shallow equality here, because the properties of the object are primitives
    memoizeResultShallow((gridSize, parentBoard, elementId) => {
        // NOTE: Using "syncedMeasurements" map here which is an immediately updated map
        //  of element measurements, rather than the measurements store which is an animation frame
        //  out of sync due to the bulk updating. We need it to be up to date to prevent a flash
        //  when rendering the line after a move
        const elementMeasurements = syncedMeasurements[elementId];
        const elementTopLeft = getTopLeft(elementMeasurements);

        const canvasOrigin = asObject(getCanvasOriginCoordinates(parentBoard));

        return pointLib.translate(canvasOrigin, pointLib.scale(1 / gridSize, elementTopLeft));
    });

/**
 * Gets the element's location as though it was on the canvas,
 * in the canvas's coordinate system (i.e. can be negative).
 */
export const makeGetCanvasElementPositionSelector = () => {
    const getColumnElementPosition = makeGetColumnElementPosition();

    return (state, { elementId }) => {
        const element = getElements(state).get(elementId);

        if (!element) return null;

        if (isLocationCanvas(element)) return getLocationPosition(element);

        const parentId = getLocationParentId(element);
        // Doubt this could happen, but just in case
        if (!parentId) return null;

        // If the parent is a column use it as the connected element
        const parent = getElements(state).get(parentId);
        if (!isColumn(parent)) return null;

        const gridSize = getGridSize(state);

        // If the parent is a column, find out the difference between the column's top left
        // and the elements top left and determine a position based on that.
        const parentBoardId = getLocationParentId(parent);
        const parentBoard = getElements(state).get(parentBoardId);

        return getColumnElementPosition(gridSize, parentBoard, elementId);
    };
};

/**
 * Finds the element's X,Y grid points position, if it's on the canvas.
 */
const makeGetConnectedElementPosition = (edge) => {
    const getCanvasElementPositionSelector = makeGetCanvasElementPositionSelector();

    return (state, ownProps) => {
        const elementId = getConnectedElementId(edge)(state, ownProps);
        return getCanvasElementPositionSelector(state, { elementId });
    };
};

export const getConnectedElementType = (edge) => (state, ownProps) => {
    const elementId = getConnectedElementId(edge)(state, ownProps);
    return state.getIn(['elements', elementId, 'elementType']);
};

/**
 * Finds the details for the line's edges based on whether the line is connected to any elements or not.
 * If it is connected, it requires the measurements map for the connected elements, and the edges will
 * be related to the connected component, otherwise they will be related to the values stored on the line element.
 *
 * All values are in PX at output and are scaled based on the grid size.
 * {
 *     startEdgeOrigin: { x, y },
 *     endEdgeOrigin: { x, y },
 *     startConnectionRects: [{ x, y, left, right, top, bottom, width, height }],
 *     endConnectionRects: [{ x, y, left, right, top, bottom, width, height }] |,
 * }
 */
export const makeGetLineEdgeDetails = () =>
    createDeepSelector(
        getLineEdge(LINE_EDGE.start),
        makeGetConnectedElementPosition(LINE_EDGE.start),
        getLineEdge(LINE_EDGE.end),
        makeGetConnectedElementPosition(LINE_EDGE.end),
        getElementPositionFromStateFirstSelector,
        // NOTE: The only measurements that are required here is the start and end connected elements
        //      Try not sending more measurements then that, or you'll be executing the selector more than necessary
        getMeasurementsMapFromProps,
        getGridSize,
        (
            startEdge,
            startElementPosition,
            endEdge,
            endElementPosition,
            lineElementPosition,
            measurementsMap,
            gridSize,
        ) => {
            // Prioritise synced measurements over stored measurements, however keep the stored measurements in the
            // selector arguments to ensure correct selection & re-rendering
            const startConnection = getEdgeOriginAndRects({
                lineEdge: startEdge,
                measurementsMap: syncedMeasurements || measurementsMap,
                connectedElementPosition: startElementPosition,
                lineElementPosition,
                gridSize,
            });
            const endConnection = getEdgeOriginAndRects({
                lineEdge: endEdge,
                measurementsMap: syncedMeasurements || measurementsMap,
                connectedElementPosition: endElementPosition,
                lineElementPosition,
                gridSize,
            });

            return {
                startEdgeOrigin: startConnection.origin,
                startConnectionRects: startConnection.rects,
                startElementRect: startConnection.elementRect,
                endEdgeOrigin: endConnection.origin,
                endConnectionRects: endConnection.rects,
                endElementRect: endConnection.elementRect,
            };
        },
    );

export const makeGetAlignedLineEdgeDetails = () =>
    createSelector(
        getLineEdge(LINE_EDGE.start),
        getConnectedElementType(LINE_EDGE.start),
        getLineEdge(LINE_EDGE.end),
        getConnectedElementType(LINE_EDGE.end),
        makeGetLineEdgeDetails(),
        getGridSize,
        (startEdge, startElementType, endEdge, endElementType, lineEdgeDetails, gridSize) => {
            const { startEdgeOrigin, startElementRect, endEdgeOrigin, endElementRect } = lineEdgeDetails;

            const updatedOrigins = getAlignedColumnLineOrigins({
                startEdge,
                startElementType,
                startElementRect,
                endEdge,
                endElementType,
                endElementRect,
                gridSize,
                startEdgeOrigin,
                endEdgeOrigin,
            });

            return {
                ...lineEdgeDetails,
                ...updatedOrigins,
            };
        },
    );

/**
 * Determines the relevant start, end and control points for a line.
 * This relies on the element or element ID being passed in as a property, and a measurements map containing
 * the connected elements measurements (i.e. the start and end connected element measurements, if they exist).
 *
 * These points are relative to the top left point of the element on the canvas.
 * {
 *     start: { x, y },
 *     end: { x, y },
 *     controlPoint: { x, y },
 *     startEdgeOrigin: { x, y },
 *     endEdgeOrigin: { x, y },
 *     startConnectionRects: [{ x, y, left, right, top, bottom, width, height }],
 *     endConnectionRects: [{ x, y, left, right, top, bottom, width, height }],
 * }
 *
 * NOTE: Can use just a standard "createSelector" here, because whenever one of the inputs changes
 *      the output will certainly change.
 */
export const makeGetEdgesPx = (lineEdgeDetailsSelectorFn = makeGetLineEdgeDetails) =>
    createSelector(
        lineEdgeDetailsSelectorFn(),
        getLineControlSelector,
        getLineStartStyleSelector,
        getLineEndStyleSelector,
        getGridSize,
        (
            { startEdgeOrigin, startConnectionRects, endEdgeOrigin, endConnectionRects },
            control,
            startStyle,
            endStyle,
            gridSize,
        ) => {
            // The control point is set as a perpendicular magnitude and parallel ratio, thus it needs to
            // be converted to an actual x,y point, based on these values
            let controlPoint = getControlPosition({ start: startEdgeOrigin, end: endEdgeOrigin, control, gridSize });

            // The start and end points are dependent on the control point and the rectangles of elements
            // because currently lines point to the centre of the rectangles, so changes to the control point
            // will change the intersection on with the rectangle edge.
            const linePois = getLinePointsOfInterest({
                startEdgeOrigin,
                startConnectionRects,
                endEdgeOrigin,
                endConnectionRects,
                controlPoint,
            });

            const minLength = getLineMinLength(startStyle, endStyle, gridSize);

            return enforceLineMinLength(linePois, { minLength });
        },
    );

// ----- MEASUREMENTS SELECTOR ----- //
// To improve memoisation
const EMPTY_OBJECT = {};

/**
 * Returns a measurements map with only the measurements of connected elements.
 * Note: This is a shallow POJO with each property being an immutable object,
 *  simply because that's what the line selector works with...
 */
export const makeGetConnectedLineElementMeasurements = () =>
    createShallowSelector(
        getConnectedElementId(LINE_EDGE.start),
        getConnectedElementId(LINE_EDGE.end),
        getMeasurementsMap,
        (startElementId, endElementId, measurementsMap) => {
            if ((!startElementId && !endElementId) || !measurementsMap) return EMPTY_OBJECT;

            const relevantMeasurementsMap = {};

            if (startElementId) {
                relevantMeasurementsMap[startElementId] =
                    measurementsMap.get(startElementId) ||
                    // If the measurement doesn't exist, it might have been a drop onto a new board, so use the cache
                    getMeasurementFromCache(startElementId);
            }

            if (endElementId) {
                relevantMeasurementsMap[endElementId] =
                    measurementsMap.get(endElementId) ||
                    // If the measurement doesn't exist, it might have been a drop onto a new board, so use the cache
                    getMeasurementFromCache(endElementId);
            }

            return relevantMeasurementsMap;
        },
    );

/**
 * Check if a line is connected to comments when in presentation mode
 */
export const shouldHideLineInPresentationModeSelector = (state, ownProps) => {
    const isPresentationModeEnabled = isPresentationModeEnabledSelector(state);

    // Early exit when not in presentation mode
    if (!isPresentationModeEnabled) return false;

    const isHideCommentsEnabled = getIsPresentationModeHideCommentsEnabled(state);

    if (!isHideCommentsEnabled) return false;

    const startConnectedElementType = getConnectedElementType(LINE_EDGE.start)(state, ownProps);
    const endConnectedElementType = getConnectedElementType(LINE_EDGE.end)(state, ownProps);

    return (
        startConnectedElementType === ElementType.COMMENT_THREAD_TYPE ||
        endConnectedElementType === ElementType.COMMENT_THREAD_TYPE
    );
};
