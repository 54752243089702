/**
 * deepChildrenModCache
 * ====================
 * This is intended to be used for performance gains to prevent unnecessary recalculations of the 'deep' children
 * for an element.
 * The getChildrenDeep operation is relatively expensive and would usually be executed on any element change (even
 * if the element was entirely unrelated).
 *
 * This cache stores a timestamp of the last time that the children of a board were changed (this is performed in
 * the elements reducer).
 * The getChildrenDeepSelector will then use this cache to determine whether its state needs to be updated, or whether
 * the previous result can be used instead.
 */

// Lib
import * as Immutable from 'immutable';
import { reduce, uniq, flatMap } from 'lodash';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import * as TYPES from '../../../common/elements/elementConstants';
import * as ELEMENTS_ACTION_TYPES from '../../../common/elements/elementsConstants';
import { isContainer } from '../../../common/elements/utils/elementTypeUtils';

const updateDeepChildrenMod = (state, ids, timestamp = getTimestamp()) =>
    state.withMutations((mutableState) => {
        ids.forEach((id) => mutableState.set(id, timestamp));
    });

const handleElementsLoad = (state, action) => {
    const ids = reduce(
        action.elements,
        (idArray, el) => {
            const { parentId } = el.location;
            parentId && idArray.push(parentId);
            return idArray;
        },
        [],
    );
    return updateDeepChildrenMod(state, ids);
};

const handleElementsPurge = (state, action) => updateDeepChildrenMod(state, action.boardIds);

const handleElementCreateOrDelete = (state, action) => {
    const ids = [action.location.parentId];
    return updateDeepChildrenMod(state, ids);
};

const isMoveRelevant = ({ from, location }) => from.parentId !== location.parentId;
const getMoveIds = ({ from, location }) => [location.parentId, from.parentId];

const handleElementMoveMulti = (state, action) => {
    const ids = uniq(flatMap(action.moves.filter(isMoveRelevant).map(getMoveIds)));

    if (ids.length === 0) return state;

    return updateDeepChildrenMod(state, ids);
};

const handleElementSetType = (state, action) => {
    if (!isContainer(action.elementType)) return state;

    return updateDeepChildrenMod(state, [action.id]);
};

export default (state = Immutable.Map(), action) => {
    switch (action.type) {
        case ELEMENTS_ACTION_TYPES.ELEMENTS_LOAD:
            return handleElementsLoad(state, action);
        case ELEMENTS_ACTION_TYPES.ELEMENTS_PURGE:
            return handleElementsPurge(state, action);
        case TYPES.ELEMENT_CREATE:
        case TYPES.ELEMENT_DELETE:
        case TYPES.ELEMENT_MOVE_AND_UPDATE:
            return handleElementCreateOrDelete(state, action);
        case TYPES.ELEMENT_MOVE_MULTI:
            return handleElementMoveMulti(state, action);
        case TYPES.ELEMENT_SET_TYPE:
            return handleElementSetType(state, action);
        default:
            return state;
    }
};
