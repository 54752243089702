// Lib
import { createSelector } from 'reselect';

// Util
import {
    getColor,
    getElementType,
    getLocationParentId,
    getPhysicalId,
    isElementLocked,
} from '../../../common/elements/utils/elementPropertyUtils';
import { validateElementTypeEditPermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { getAsyncResourcesTypeState } from '../../utils/services/http/asyncResource/asyncResourceSelector';
import { getColorCssValue } from '../utils/elementColorStyleUtils';
import {
    getCurrentlyEditingId,
    getCurrentlyEditingEditorKey,
    getCurrentlyEditingEditorId,
} from '../selection/currentlyEditingSelector';
import { getSelectedElementIds } from '../selection/selectedElementsSelector';
import { getDragModifierKeys } from '../../utils/dnd/modifierKeys/dragModifierKeysSelector';
import { getIsElectronSelector } from '../../components/electron/electronSelector';
import { getSpellCheckStatus } from '../../../common/electron/electronSelectors';
import { getIsElementOpenInModal } from '../modal/elementModalCommonSelector';

// Selectors
import { getLegacyHybridUseCaseSelector } from '../../platform/platformSelector';
import { getIsElementClipboardCutSelector } from '../../workspace/shortcuts/clipboard/clipboardSelectors';
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { attachModeHoveredElementIdSelector } from '../../reducers/draggingSelector';
import { getElements } from './elementsSelector';
import { elementGraphSelector } from './elementGraphSelector';
import { isDescendantId } from '../../../common/dataStructures/graphUtils';
import { getAttachment } from '../attachments/attachmentsSelector';
import {
    elementFilterDataSelector,
    getElementFilterQuerySelector,
    elementFilterEnabledSelector,
    createCurrentElementFilterDataSelector,
} from '../elementFilter/elementFilterSelector';
import {
    getPresentationModeFocusedElementId,
    isPresentationModeEnabledSelector,
} from '../../workspace/presentation/presentationSelector';
import { getElementLocalData } from '../local/elementLocalDataSelector';

// Constants
import { FILTER_STATES } from '../../../common/elements/elementConstants';
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { LegacyHybridUseCase } from '../../platform/platformTypes';

export { getElements } from './elementsSelector';

export const getElementId = (state, ownProps) =>
    ownProps.elementId || (ownProps.element && (ownProps.element.get('id') || ownProps.element.get('_id')));

export const getElementIdFromProps = getElementId;
export const getPhysicalElementIdSelector = (state, ownProps) => getPhysicalId(ownProps.element);
export const getElement = (state, ownProps) => ownProps.element || getElements(state).get(ownProps.elementId);

// Resources
export const elementsAsyncResourcesSelector = getAsyncResourcesTypeState(ResourceTypes.elements);

export const isEditingSelector = () =>
    createShallowSelector(
        getElementId,
        getElement,
        elementGraphSelector,
        getCurrentlyEditingId,
        getCurrentlyEditingEditorKey,
        getCurrentlyEditingEditorId,
        (elementId, element, elementGraph, currentlyEditingId, currentEditorKey, currentEditorId) => {
            let isEditing = false;
            let isEditingChild = false;
            let _currentEditorKey = null;
            let _currentEditorId = null;

            if (currentlyEditingId) {
                isEditing = currentlyEditingId === elementId;
                _currentEditorKey = isEditing ? currentEditorKey : null;
                _currentEditorId = isEditing ? currentEditorId : null;

                if (!isEditing) {
                    isEditingChild = isDescendantId(elementGraph, elementId, currentlyEditingId);
                }
            }

            return {
                isEditing,
                isEditingChild,
                currentEditorKey: _currentEditorKey,
                currentEditorId: _currentEditorId,
            };
        },
    );

export const isElementSelectedSelector = () =>
    createSelector(getElementId, getSelectedElementIds, (elementId, selectedElementIds) =>
        selectedElementIds.includes(elementId),
    );

const isSingleSelectedSelector = () =>
    createSelector(
        getElementId,
        getSelectedElementIds,
        (elementId, selectedElementIds) => selectedElementIds.size === 1 && selectedElementIds.includes(elementId),
    );

const remoteSelectionSelector = () =>
    createSelector(
        (state, props) => state.getIn(['remoteActivity', 'selectedElements', getElementId(state, props)]),
        (remoteSelectionData) => remoteSelectionData,
    );

const isRemotelySelectedSelector = () =>
    createSelector(
        remoteSelectionSelector(),
        isPresentationModeEnabledSelector,
        (remoteSelectionData, isPresentationModeEnabled) =>
            !isPresentationModeEnabled && remoteSelectionData && !!remoteSelectionData.get('userId'),
    );

const isLockedSelector = () => (state, ownProps) => isElementLocked(getElement(state, ownProps));

/**
 * An element "isFocusedBackgroundElement" if it's open in the focus mode,
 * but it's *not* the FocusModeElement component.
 */
const isFocusedBackgroundElementSelector = () => (state, ownProps) =>
    getElementId(state, ownProps) === getPresentationModeFocusedElementId(state) &&
    !ownProps.isFocusedForegroundElement;

const isEditableSelector = () =>
    createSelector(
        (state, props) => props.permissions,
        (state, props) => getElementType(props.element),
        validateElementTypeEditPermission,
    );

const filterStateSelector = () =>
    createSelector(
        elementFilterEnabledSelector,
        createCurrentElementFilterDataSelector(),
        (elementFilterEnabled, currentElementFilterData) => {
            if (!elementFilterEnabled) return FILTER_STATES.NONE;

            if (currentElementFilterData?.focus) return FILTER_STATES.FOCUS;
            if (currentElementFilterData?.pass) return FILTER_STATES.PASS;

            return FILTER_STATES.FAIL;
        },
    );

export const getElementColorFromState = (state, ownProps) => getColor(getElement(state, ownProps));

export const getColourHex = () => createSelector(getElementColorFromState, getColorCssValue);

const canConnectLineEdgeSelector = (state, ownProps) => {
    if (!ownProps.inList) return true;

    const hoveredElementId = attachModeHoveredElementIdSelector(state);

    if (!hoveredElementId) return false;

    const { elementId, element } = ownProps;

    return elementId === hoveredElementId || getLocationParentId(element) === hoveredElementId;
};

/**
 * The "two clicks to edit" functionality shouldn't be enabled for the legacy Android and iPhone apps.
 */
const getShouldFocusOnlyWhenSelected = createSelector(
    getLegacyHybridUseCaseSelector,
    (legacyHybridUseCase) => !legacyHybridUseCase || legacyHybridUseCase === LegacyHybridUseCase.IPAD_OS,
);

/**
 * If the currently long hovered element ID is the same as this element ID we're connecting inside.
 */
const isAttachingInsideSelector = (state, ownProps) => attachModeHoveredElementIdSelector(state) === ownProps.elementId;

export default () =>
    createSelector(
        isElementSelectedSelector(),
        isSingleSelectedSelector(),
        remoteSelectionSelector(),
        isRemotelySelectedSelector(),
        isEditingSelector(),
        isLockedSelector(),
        isFocusedBackgroundElementSelector(),
        getIsElementClipboardCutSelector,
        filterStateSelector(),
        isEditableSelector(),
        getDragModifierKeys,
        getElementFilterQuerySelector,
        getIsElementOpenInModal,
        getIsElectronSelector,
        getSpellCheckStatus,
        elementFilterDataSelector,
        getAttachment,
        canConnectLineEdgeSelector,
        isAttachingInsideSelector,
        isPresentationModeEnabledSelector,
        getShouldFocusOnlyWhenSelected,
        getElementLocalData,
        (
            isSelected,
            isSingleSelected,
            remoteSelectionData,
            isRemotelySelected,
            isEditing,
            isLocked,
            isFocusedBackgroundElement,
            isClipboardCut,
            filterState,
            isEditable,
            dragModifierKeys,
            filterQuery,
            isElementOpenInModal,
            isElectron,
            spellCheckStatus,
            elementFilterData,
            attachment,
            canConnectLineEdge,
            isAttachingInside,
            isPresentationModeEnabled,
            shouldFocusOnlyWhenSelected,
            elementLocalData,
        ) => ({
            ...isEditing,
            remoteSelectionData,
            isRemotelySelected,
            isSingleSelected,
            isSelected,
            isFocusedBackgroundElement,
            isLocked,
            isClipboardCut,
            filterState,
            filterQuery:
                ((filterState === FILTER_STATES.PASS || filterState === FILTER_STATES.FOCUS) && filterQuery) || null,
            isEditable,
            dragModifierKeys,
            isElementOpenInModal,
            spellCheck: isElectron ? spellCheckStatus : true,
            elementFilterData,
            attachment,
            canConnectLineEdge,
            isAttachingInside,
            isPresentationModeEnabled,
            shouldFocusOnlyWhenSelected,
            elementLocalData,
        }),
    );
