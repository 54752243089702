// Lib
import { EditorState, RichUtils, Modifier } from 'draft-js';

// Utils
import getSelectionBlockMap from '../../../customRichUtils/selection/getSelectionBlockMap';

// Constants
import { EditorChangeType } from '../../../draftjsConstants';
import { DEFAULT_INDENTATION } from './getIndentation';

/**
 * Code blocks can't have multiple blocks because they won't format correctly in exports and they
 * won't look right within the styling.
 * So we get all the text within each block and put it into a single block.
 */
export const handleCodeBlockCommand = (state, styleCommand) => {
    let editorState = state;
    const contentState = editorState.getCurrentContent();

    // First get all selected blocks
    const blockMap = getSelectionBlockMap(editorState);

    const firstBlock = blockMap.first();
    const firstBlockText = firstBlock.getText();
    const firstBlockEndOffset = firstBlockText.length;

    const lastBlock = blockMap.last();
    const lastBlockText = lastBlock.getText();
    const lastBlockEndOffset = lastBlockText.length;

    // Then get their text and join it
    const newBlockText = blockMap
        .toArray()
        .map((block) => block.getText())
        .join('\n')
        .replace(/\t/g, DEFAULT_INDENTATION);

    // Then overwrite the current text
    const selection = editorState.getSelection();
    const isBackward = selection.getIsBackward();

    const replacementRange = selection.merge({
        anchorOffset: isBackward ? firstBlockEndOffset : 0,
        focusOffset: isBackward ? 0 : lastBlockEndOffset,
    });

    const newContentState = Modifier.replaceText(contentState, replacementRange, newBlockText, null);

    editorState = EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS);

    // return editorState;
    return RichUtils.toggleBlockType(editorState, styleCommand);
};
