import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementResizeHandleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon element-resize-handle"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#1B2536"
                fillOpacity={0.5}
                d="M13.146 12.146a.5.5 0 0 1 .708.708l-1 1a.5.5 0 0 1-.708-.708zm0-4a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708-.708zm0-4a.5.5 0 0 1 .708.708l-9 9a.5.5 0 0 1-.708-.708z"
            />
            <path
                fill="#FFF"
                fillOpacity={0.7}
                d="M13.146 6.146a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708-.708zm0-4a.5.5 0 0 1 .708.708l-11 11a.5.5 0 0 1-.708-.708zm0 8a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708-.708z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementResizeHandleIcon);
export default Memo;
