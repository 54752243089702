import { Point } from '../../common/maths/geometry/pointTypes';
import { omit } from 'lodash/fp';
import { getLastNKeys } from '../utils/milanoteLodash/getLastNKeys';

const RETURN_STATE_LOCAL_STORAGE_KEY = 'milanote.returnState';
const RETURN_STATE_LIMIT = 100;

interface ReturnState {
    scrollTop: number;
    scrollLeft: number;
    width: number;
    height: number;
    zoomScale: number;
    zoomTranslationPx: Point;
}

export const clearCanvasReturnState = (): void => {
    localStorage.removeItem(RETURN_STATE_LOCAL_STORAGE_KEY);
};

export const clearBoardsFromCanvasReturnState = (...boardIds: string[]): void => {
    const currentReturnStateString = localStorage.getItem(RETURN_STATE_LOCAL_STORAGE_KEY);
    const currentReturnState = currentReturnStateString ? JSON.parse(currentReturnStateString) : {};

    const newReturnState = omit(boardIds, currentReturnState);

    localStorage.setItem(RETURN_STATE_LOCAL_STORAGE_KEY, JSON.stringify(newReturnState));
};

export const getCanvasReturnState = (boardId: string): ReturnState => {
    const currentReturnStateString = localStorage.getItem(RETURN_STATE_LOCAL_STORAGE_KEY);
    const currentReturnState = currentReturnStateString ? JSON.parse(currentReturnStateString) : {};

    return currentReturnState[boardId];
};

export const saveCanvasReturnState = (boardId: string, returnState: ReturnState): void => {
    const currentReturnStateString = localStorage.getItem(RETURN_STATE_LOCAL_STORAGE_KEY);
    const currentReturnState = currentReturnStateString ? JSON.parse(currentReturnStateString) : {};

    delete currentReturnState[boardId];

    const newReturnState = getLastNKeys({ ...currentReturnState, [boardId]: returnState }, RETURN_STATE_LIMIT);

    localStorage.setItem(RETURN_STATE_LOCAL_STORAGE_KEY, JSON.stringify(newReturnState));
};
