// Utils
import { asObject } from '../../../utils/immutableHelper';
import { convertTiptapContentToDraft } from '../editorConversion/toDraftJs/convertTiptapContentToDraft';
import { convertDraftContentToTiptap } from '../editorConversion/toTiptap/convertDraftContentToTiptap';
import { prepareDraftJsCaptionForConversion } from '../editorConversion/toTiptap/fromCaption/prepareDraftJsCaptionForConversion';

// Types
import { TiptapContent } from '../../tiptapTypes';
import { RawDraftContentState } from 'draft-js';
import { ImMNElement, MNElement, MNElementContent } from '../../../elements/elementModelTypes';

/**
 * Gets the content changes required to convert a Tiptap element to Draft JS.
 */
export const getElementTiptapToDraftChanges = (element: ImMNElement): MNElementContent => {
    const { content } = asObject<MNElement>(element);

    const changes: MNElementContent = {};

    if (content.textContent) {
        changes.textContent = convertTiptapContentToDraft(content.textContent as TiptapContent) as RawDraftContentState;
    }

    if (content.caption) {
        changes.caption = convertTiptapContentToDraft(content.caption as TiptapContent) as RawDraftContentState;
    }

    return changes;
};

export enum DocumentType {
    caption = 'caption',
    textContent = 'text-content',
}

/**
 * Converts an element's Draft JS raw content to Tiptap content, depending on the document type.
 *
 * E.g. Captions will be preprocessed to convert paragraphs to soft-new-lines.
 */
export const convertElementDraftContentToTiptap = (
    draftContent: RawDraftContentState,
    documentType?: DocumentType,
): TiptapContent => {
    let updatedDraftContent = draftContent;

    if (documentType === DocumentType.caption) {
        updatedDraftContent = prepareDraftJsCaptionForConversion(draftContent);
    }

    return convertDraftContentToTiptap(updatedDraftContent) as TiptapContent;
};

/**
 * Gets the content changes required to convert a Draft JS element to Tiptap.
 */
export const getElementDraftToTiptapChanges = (element: ImMNElement): MNElementContent => {
    const { content } = asObject<MNElement>(element);

    const changes: MNElementContent = {};

    if (content.textContent) {
        changes.textContent = convertElementDraftContentToTiptap(content.textContent as RawDraftContentState);
    }

    if (content.caption) {
        changes.caption = convertElementDraftContentToTiptap(
            content.caption as RawDraftContentState,
            DocumentType.caption,
        );
    }

    return changes;
};
