import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Utils
import { getHeaderAvoidanceInsets } from '../../../../components/tooltips/tooltipInsetUtils';
import { closePopup as closePopupAction } from '../../../../components/popupPanel/popupActions';

// Selectors
import { isPopupIdOpenSelector } from '../../../../components/popupPanel/popupOpenSelector';
import { globalGridSizeSelector } from '../../../../utils/grid/gridSizeSelector';
import { getCurrentBoard } from '../../../../reducers/currentBoardId/currentBoardIdSelector';

// Components
import FloatingPanel from '../../../../components/tooltips/FloatingPanel';
import PopupPanelMouseManager from '../../../../components/popupPanel/PopupPanelMouseManager';

// Constants
import { TooltipPositions } from '../../../../components/tooltips/tooltipConstants';

import './ToolbarPopup.scss';

const mapStateToProps = createStructuredSelector({
    isPopupOpen: isPopupIdOpenSelector,
    currentBoard: getCurrentBoard,
    globalGridSize: globalGridSizeSelector,
});

const mapDispatchToProps = (dispatch) => ({
    closePopup: (popupId) => dispatch(closePopupAction(popupId)),
});

const ToolbarPopup = ({
    buttonSelector,
    popupId,
    className,
    isPopupOpen,
    closePopup,
    ignoredClicksSelector,
    children,
    offset = 0,
    distance = 15,
    alignment,
    position = TooltipPositions.RIGHT,
    closeOnMouseUp = false,
    arrowColor,
    arrowTranslation = `${offset - 2}px`,
    arrowStrokeColor = 'none',
    arrowStrokeWidth,
    globalGridSize,
    currentBoard,
    ...props
}) => {
    if (!isPopupOpen) return null;

    return (
        <FloatingPanel
            selector={buttonSelector}
            className={classNames('ToolbarPopup light', className)}
            arrowColor={arrowColor}
            arrowStrokeColor={arrowStrokeColor}
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={arrowStrokeWidth}
            arrowStyle={{
                transform: `translate(${arrowTranslation}, 0.5px)`,
            }}
            distance={distance}
            offset={offset}
            position={position}
            alignment={alignment}
            pollPosition
            positionOffsetY={100}
            insets={getHeaderAvoidanceInsets(globalGridSize, currentBoard)}
        >
            <PopupPanelMouseManager
                {...props}
                visible={isPopupOpen}
                closePopup={closePopup}
                popupId={popupId}
                closeOnMouseUp={closeOnMouseUp}
                ignoredClicksSelector={ignoredClicksSelector}
            >
                <div className="popup-content">{children}</div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

ToolbarPopup.propTypes = {
    buttonSelector: PropTypes.string,
    popupId: PropTypes.string,
    className: PropTypes.string,
    isPopupOpen: PropTypes.bool,
    closePopup: PropTypes.func,
    ignoredClicksSelector: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    currentBoard: PropTypes.object,
    offset: PropTypes.number,
    distance: PropTypes.number,
    closeOnMouseUp: PropTypes.bool,
    stayOpen: PropTypes.bool,
    alignment: PropTypes.string,
    position: PropTypes.string,
    arrowColor: PropTypes.string,
    globalGridSize: PropTypes.number,
    arrowStrokeColor: PropTypes.string,
    arrowStrokeWidth: PropTypes.number,
    arrowTranslation: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarPopup);
