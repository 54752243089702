/* eslint-disable react/sort-comp */
/**
 * This decorator is responsible for the management of the editor state as it progresses through different
 * stages of the clipping.
 */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defer } from 'lodash/fp';
import { convertToRaw } from 'draft-js';

// Utils
import getSelectedTextContentState from '../../../components/editor/customRichUtils/selection/getSelectedTextContentState';
import removeSelection from '../../../components/editor/customRichUtils/selection/removeSelection';
import handleEntityChange from '../../../components/editor/store/reducers/handleEntityChange';
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { editorEntityChange } from '../../../components/editor/store/editorActions';
import { clipperClear, clipperOperationComplete, clipperSet } from './store/clipperActions';
import { getNextUndoElementCreateTransactionId } from '../../../utils/undoRedo/undoRedoActions';
import { setElementLocalData } from '../../local/elementLocalDataActions';

// Constants
import { DRAFT_ENTITY_MUTABILITY } from '../../../components/editor/draftjsConstants';
import { ENTITIES } from '../../../components/editor/richText/richTextConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchSetTemporaryEditorState: ({ id, editorState }) =>
        dispatch(setElementLocalData({ id, data: { editorState } })),
    dispatchClearLocalEditorState: ({ id }) => dispatch(setElementLocalData({ id, data: { editorState: null } })),
    dispatchClipperSet: ({ clippedElementId, clippedText }) => dispatch(clipperSet({ clippedElementId, clippedText })),
    dispatchClipperClear: () => dispatch(clipperClear()),
    dispatchClipperOperationComplete: (transactionId) => dispatch(clipperOperationComplete(transactionId)),
    dispatchGetElementCreateTransactionId: () => dispatch(getNextUndoElementCreateTransactionId()),
});

const EDITOR_ADD_CLIP_ENTITY_ACTION = editorEntityChange({
    entityType: ENTITIES.CLIP,
    mutability: DRAFT_ENTITY_MUTABILITY.MUTABLE,
});

export default (DecoratedComponent) => {
    @connect(null, mapDispatchToProps)
    class CardClipperEditorStateManager extends React.Component {
        onClipStart = (event) => {
            const { element, editorState, onClipStart, dispatchSetTemporaryEditorState, dispatchClipperSet } =
                this.props;

            this.wasCopy = false;

            const elementId = getElementId(element);

            this.originalSelection = editorState.getSelection();

            // Save clipped text
            const clippedTextContent = getSelectedTextContentState(editorState);
            const clippedText = convertToRaw(clippedTextContent);
            dispatchClipperSet({ clippedElementId: elementId, clippedText });

            // Update the temporary editor state to show the clipping selection
            const updatedEditorState = handleEntityChange(editorState, EDITOR_ADD_CLIP_ENTITY_ACTION);
            dispatchSetTemporaryEditorState({ id: elementId, editorState: updatedEditorState });

            onClipStart && onClipStart(event);
        };

        beforeDragEnd = (dragSuccess) => {
            const { element, beforeDragEnd, editorState, dispatchSetTemporaryEditorState, isClipCopy } = this.props;

            this.wasCopy = isClipCopy;

            if (!isClipCopy && dragSuccess) {
                // Set the temporary state to immediately clear the text without affecting the undo / redo stack
                const newEditorState = removeSelection(editorState);
                dispatchSetTemporaryEditorState({ id: getElementId(element), editorState: newEditorState });
            }

            beforeDragEnd && beforeDragEnd(dragSuccess);
        };

        onClipEnd = (clipWasSuccessful) => {
            const { onClipEnd } = this.props;

            onClipEnd && onClipEnd(clipWasSuccessful);

            clipWasSuccessful ? this.onCommit() : this.onRevert();
        };

        onCommit = () => {
            const {
                saveContent,
                editorState,
                dispatchGetElementCreateTransactionId,
                element,
                dispatchClearLocalEditorState,
                dispatchClipperClear,
                dispatchClearEditorUndoRedoStack,
                dispatchClipperOperationComplete,
            } = this.props;

            dispatchClearEditorUndoRedoStack();
            dispatchClipperClear();

            const elementId = getElementId(element);

            // Must clear after the editor state is removed in the copy case
            this.wasCopy && dispatchClearLocalEditorState({ id: elementId });

            // Unfortunately need to defer here due to the element create event happening asynchronously
            defer(() => {
                const transactionId = dispatchGetElementCreateTransactionId();

                if (!this.wasCopy) {
                    const newEditorState = removeSelection(editorState);
                    const rawText = convertToRaw(newEditorState.getCurrentContent());
                    saveContent(rawText, transactionId);

                    dispatchClearLocalEditorState({ id: elementId });
                }

                dispatchClipperOperationComplete(transactionId);
            });
        };

        /**
         * Restore the editor state prior to the clipping.
         */
        onRevert = () => {
            const {
                dispatchStartEditingElement,
                dispatchSaveEditorSelection,
                element,
                dispatchClearLocalEditorState,
                dispatchClipperClear,
            } = this.props;

            const elementId = getElementId(element);
            dispatchClearLocalEditorState({ id: elementId });
            dispatchClipperClear();

            const editorId = getMainEditorId(this.props);

            // Save selection then force selection
            dispatchSaveEditorSelection({ editorId, selection: this.originalSelection });

            dispatchStartEditingElement({
                id: elementId,
                editorKey: getMainEditorKey(this.props),
                editorId,
            });
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    beforeDragEnd={this.beforeDragEnd}
                    onClipStart={this.onClipStart}
                    onClipEnd={this.onClipEnd}
                    onDragEnd={this.onDragEnd}
                />
            );
        }
    }

    CardClipperEditorStateManager.propTypes = {
        element: PropTypes.object,
        editorId: PropTypes.string,
        editorState: PropTypes.object,

        isClipCopy: PropTypes.bool,

        onClipStart: PropTypes.func,
        onClipEnd: PropTypes.func,

        dispatchSetTemporaryEditorState: PropTypes.func,
        dispatchClearLocalEditorState: PropTypes.func,

        dispatchClipperSet: PropTypes.func,
        dispatchClipperClear: PropTypes.func,

        dispatchEditorEntityChange: PropTypes.func,
        dispatchClearEditorUndoRedoStack: PropTypes.func,
        focusOnEditor: PropTypes.func,
        dispatchSaveEditorSelection: PropTypes.func,
        saveContent: PropTypes.func,

        dispatchStartEditingElement: PropTypes.func,
        dispatchGetElementCreateTransactionId: PropTypes.func,

        beforeDragEnd: PropTypes.func,
        dispatchClipperOperationComplete: PropTypes.func,
    };

    return CardClipperEditorStateManager;
};
