import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarHyperlinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-hyperlink"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M16.934 13.634a.75.75 0 0 1 1.06 0 4.75 4.75 0 0 1 0 6.717l-1.885 1.886A4.75 4.75 0 1 1 9.39 15.52a.75.75 0 1 1 1.06 1.06 3.25 3.25 0 1 0 4.597 4.596l1.886-1.885a3.25 3.25 0 0 0 0-4.596.75.75 0 0 1 0-1.061zM15.52 9.39a4.75 4.75 0 0 1 6.717 6.718.75.75 0 0 1-1.06-1.06 3.25 3.25 0 1 0-4.597-4.597l-1.885 1.886a3.25 3.25 0 0 0 0 4.596.75.75 0 1 1-1.061 1.06 4.75 4.75 0 0 1 0-6.717z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarHyperlinkIcon);
export default Memo;
