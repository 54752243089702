// Helpers
import blockRendererFn from './blockRendererFn';
import blockRenderMap from './blockRenderMap';
import handleBeforeInput from './handleBeforeInput';

export default () => ({
    blockRendererFn,
    blockRenderMap,
    // TODO Maybe move this into the markdown plugin
    handleBeforeInput,
    // NOTE: There used to be a keybinding function and handle command function due to the suggestions in this
    // thread: https://github.com/facebook/draft-js/issues/132.
    // The API of Draft JS has changed since then and they were no longer being invoked.  They also appeared
    // to no longer be necessary.  Checkout the commit that this comment was added in if the code needs
    // to be reviewed
});
