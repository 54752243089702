// Lib
import { keys, difference, isEmpty } from 'lodash';

// Utils
import globalLogger, { LoggerComponents } from '../../../../logger';
import { getTimestamp } from '../../../../../common/utils/timeUtil';
import {
    buildActionErrorArray,
    convertAsyncResourceResponseErrorToEntityError,
    convertErrorToAsyncResourceResponseError,
    filterResultActionIds,
    getDefaultExpiryTimestamp,
    getErrorAlreadyHandledIds,
    getIdsToFetch,
    getRequestedEntityIds,
    getResourcePromiseErrors,
    getResourcePromiseResult,
    getResponseAlreadyHandledIds,
    getResponseExpiry,
    getResultFetchedTime,
} from './asyncResourceInternalUtils';

// Services
import { manuallyReportError } from '../../../../analytics/rollbarService';

// Actions
import {
    asyncResourceFetchError,
    fetchedAsyncResource,
    fetchingAsyncResource,
    cachedAsyncResource,
} from './asyncResourceActions';

// Types
import { ResourceTypes } from './asyncResourceConstants';
import { AsyncResourceWrappedFunction } from './asyncResourceTypes';

const logger = globalLogger.createChannel(LoggerComponents.ASYNC_RESOURCE);

/**
 * Wraps a promise and fires actions to track the state of the request (fetching, fetched, error).
 *
 * This will fire the following events as appropriate that can be used in reducers if desired:
 * - RESOURCE_FETCHING
 * - RESOURCE_FETCHED
 *    - Note: This will have a data property that contains the data returned by the wrapped function
 * - RESOURCE_FETCH_ERROR
 *
 * It will also only request the entity if it hasn't been fetched, or it's expired.
 */
export const asyncResource =
    (
        // The name of the "resource". This will either track the status for the resource individually
        // or group the entities provided by the ids property
        resourceName: ResourceTypes,
        // Optional - If provided the tracking of the fetching will be grouped inside the "resourceName"
        ids?: string[] | string,
        // If we should ignore whether the resource is already fetched
        forceFetch = false,
        // If the fetch isn't loading the data directly into memory
        cache = false,
    ) =>
    // An asynchronous function to wrap resource actions around
    (wrappedFunction: AsyncResourceWrappedFunction) =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        const requestedEntityIds = getRequestedEntityIds(ids);

        // Check if already fetching or if expired
        let state = getState();
        const [shouldFetch, fetchingIds] = getIdsToFetch(state, resourceName, requestedEntityIds, forceFetch);

        if (!shouldFetch || isEmpty(fetchingIds)) {
            logger.info('Ignoring async resource request', {
                resourceName,
                ids,
                forceFetch,
            });
            return;
        }

        const fetchStartTimestamp = getTimestamp();

        dispatch(fetchingAsyncResource(resourceName, fetchingIds));

        try {
            const response = await wrappedFunction(fetchingIds);

            state = getState();

            const fetchedTime = getResultFetchedTime(response) || fetchStartTimestamp;
            const expiry = getResponseExpiry(response) || getDefaultExpiryTimestamp();

            const result = getResourcePromiseResult(response);

            const resultErrors = getResourcePromiseErrors(response);

            const alreadyHandledIds = getResponseAlreadyHandledIds(response);
            const errorIds = keys(resultErrors);
            const fetchedIds = difference(fetchingIds, errorIds);

            const fetchedActionIds = filterResultActionIds(fetchedIds, alreadyHandledIds);

            // If there's been fetched entities, dispatch the async resource fetched action
            if (!isEmpty(fetchedActionIds)) {
                const fetchActionBuilder = cache ? cachedAsyncResource : fetchedAsyncResource;

                dispatch(
                    fetchActionBuilder(resourceName, fetchedActionIds, {
                        fetchedTime,
                        data: result,
                        expiry,
                    }),
                );
            }

            const errorActionIds = filterResultActionIds(errorIds, alreadyHandledIds);

            const errorArray = buildActionErrorArray(resultErrors);
            if (!isEmpty(errorActionIds) && errorArray?.length) {
                dispatch(asyncResourceFetchError(resourceName, errorArray, getTimestamp(), errorActionIds));
            }
        } catch (err: unknown) {
            const alreadyHandledIds = getErrorAlreadyHandledIds(err);

            const errorActionIds = filterResultActionIds(fetchingIds, alreadyHandledIds);

            if (!isEmpty(errorActionIds)) {
                const errorObject = convertErrorToAsyncResourceResponseError(err);
                const errorArray = errorActionIds.map((id) =>
                    convertAsyncResourceResponseErrorToEntityError(errorObject, id),
                );
                dispatch(asyncResourceFetchError(resourceName, errorArray, getTimestamp(), errorActionIds));
            }

            manuallyReportError({
                errorMessage: `${resourceName} resource fetching error: ${err}`,
                error: err,
                sensitive: false,
            });

            return Promise.reject(err);
        }
    };
