import { registerPlugin } from '@capacitor/core';

import type { SignInWithAppleNativeApplePlugin } from './definitions';
import { CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_APPLE_PLUGIN_NAME } from '../pluginConstants';

const SignInWithAppleNativeApple = registerPlugin<SignInWithAppleNativeApplePlugin>(
    CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_APPLE_PLUGIN_NAME,
);

export * from './definitions';
export { SignInWithAppleNativeApple };
