// Constants
import { EDITOR_STATE_CHANGED } from './editorConstants';
import getCurrentInlineStyle from '../customRichUtils/editorState/editorGetCurrentInlineStyle';
import canBlockTypeAlignCenter from '../plugins/styleShortcuts/canBlockTypeAlignCenter';
import { BLOCK_METADATA } from '../richText/richTextConstants';
import logger from '../../../logger/logger';

export default (store) => {
    // bridge to allow swift layer to dispatch into javascript store
    window.dispatchToEditorStore = ({ action }) => {
        store.dispatch(action);
    };

    return (next) => (action) => {
        if (action.remote) {
            return next(action);
        }

        const { editorState } = action;

        const message = {
            type: action.type,
            textInCurrentBlock: '',
            selectedBlockType: 'unstyled',
            selectedInlineStyle: [],
            selectedBlockMetadata: {},
            canSelectedBlockAlignCenter: true,
            selectedTextEntityType: 'none',
            canSelectedTextLink: false,
        };

        if (action.type && action.type === EDITOR_STATE_CHANGED && editorState) {
            const currentBlockKey = editorState.getSelection().getFocusKey();
            const currentBlock = editorState.getCurrentContent().getBlockForKey(currentBlockKey);

            // Set `textInCurrentBlock`
            message.textInCurrentBlock = (currentBlock && currentBlock.getText()) || '';

            // Set `selectedBlockType`
            const currentBlockType = currentBlock && currentBlock.getType();
            message.selectedBlockType = currentBlockType;

            // Set `selectedInlineStyle`
            const currentInlineStyle = getCurrentInlineStyle(editorState);
            message.selectedInlineStyle = currentInlineStyle.toJS();

            // Set metadata, currently only used for align center
            const metadata = currentBlock && currentBlock.getData();
            const alignedCenter = metadata ? !!metadata.get(BLOCK_METADATA.TEXT_ALIGN_CENTER) : false;
            message.selectedBlockMetadata = { [BLOCK_METADATA.TEXT_ALIGN_CENTER]: alignedCenter };

            // Set align center properties
            message.canSelectedBlockAlignCenter = canBlockTypeAlignCenter(currentBlockType);

            // Set text entity type
            const selection = editorState.getSelection();
            const startOffset = selection.getStartOffset();
            const entityKey = currentBlock && currentBlock.getEntityAt(startOffset);

            if (entityKey) {
                const entity = editorState.getCurrentContent().getEntity(entityKey);
                message.selectedTextEntityType = entity.getType();
                message.canSelectedTextLink = true;
            } else {
                message.canSelectedTextLink = startOffset !== selection.getEndOffset();
            }
        }

        // `window.webkit` is available when the client is ran on iOS WKWebView
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.milanoteEditorActions) {
            // `milanoteJStoSwift` needs to match the message handler name that in WKWebView's configuration

            window.webkit.messageHandlers.milanoteEditorActions.postMessage(message).catch((error) => {
                logger.error('Action could not be posted: ', message, error);
            });
        }

        if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            window.flutter_inappwebview.callHandler('flutterEditorActionHandler', message);
        }

        return next(action);
    };
};
