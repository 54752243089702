// Lib
import { get, isEmpty } from 'lodash/fp';

// Utils
import { http } from '../../../../../../../utils/services/http';
import { asyncResource } from '../../../../../../../utils/services/http/asyncResource/asyncResource';

// Selectors
import { getCurrentBoardIdFromState } from '../../../../../../../reducers/currentBoardId/currentBoardIdSelector';

// Actions
import { getTokenForElementIdThunk } from '../../../../../../../utils/permissions/permissionsActions';

// Constants
import { ResourceTypes } from '../../../../../../../utils/services/http/asyncResource/asyncResourceConstants';
import { PUBLISHED_BOARD_VIEWERS_LOAD, RESET_PUBLISHED_BOARD_VIEWERS } from './publishedBoardViewersConstants';
import { CUSTOM_HTTP_HEADERS } from '../../../../../../../../common/utils/http/httpConstants';

/**
 * Fetches the published board viewers.
 */
export const fetchCurrentBoardPublishedBoardViewers = () => async (dispatch, getState) => {
    const state = getState();

    const currentBoardId = getCurrentBoardIdFromState(state);

    dispatch(
        asyncResource(
            ResourceTypes.publishedBoardViewers,
            currentBoardId,
        )(async () => {
            const token = await dispatch(getTokenForElementIdThunk({ elementId: currentBoardId }));

            const response = await http({
                url: `elements/${currentBoardId}/views`,
                headers: {
                    [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: token,
                },
            });

            const views = get(['data', 'views'], response);
            const guestViews = get(['data', 'guestViews'], response);

            if (isEmpty(views) && isEmpty(guestViews)) return;

            dispatch({
                type: RESET_PUBLISHED_BOARD_VIEWERS,
            });

            return dispatch({
                type: PUBLISHED_BOARD_VIEWERS_LOAD,
                elementId: currentBoardId,
                views,
                guestViews,
            });
        }),
    );
};
