// Lib
import { useSelector } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { isPlatformLegacyMobileApp } from '../../../../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../../../../platform/platformSelector';

// Components
import CollapsableTools from '../../../collapsableTools/CollapsableTools';
import TextStyleRichTextToolContainer from './textStylesTool/TextStyleRichTextToolContainer';
import SubToolList from '../SubToolList';

// Constants
import {
    RICH_TEXT_TOOLS_CONFIG,
    RICH_STYLE_TOOLS,
    INLINE_STYLE_TOOLS,
    RICH_TEXT_TOOL_COLLAPSE_ORDER,
} from './richTextToolConstants';
import { CAPTION_EDITOR_KEY } from '../../../../../components/caption/captionConstants';
import { DOCUMENT_MODAL_EDITOR_KEY } from '../../../../../element/document/modal/documentModalConstants';
import { ElementType } from '../../../../../../common/elements/elementTypes';

// FIXME-DraftJS: Remove this component
const RichTextToolList = (props) => {
    const { editorKey, gridSizeName, availableHeight, unavailableHeight } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);

    if (isPlatformLegacyMobileApp(platformDetails)) return null;

    const showInlineTools =
        editorKey === CAPTION_EDITOR_KEY ||
        editorKey === ElementType.COMMENT_THREAD_TYPE ||
        editorKey === ElementType.TASK_TYPE;

    const showBlockStyleTools = editorKey === ElementType.CARD_TYPE || editorKey === DOCUMENT_MODAL_EDITOR_KEY;

    if (!showBlockStyleTools && !showInlineTools) return null;

    const availableTools = showBlockStyleTools ? RICH_STYLE_TOOLS : INLINE_STYLE_TOOLS;

    return (
        <SubToolList {...props} className="RichTextToolList">
            <TextStyleRichTextToolContainer {...props} showBlockStyleTools={showBlockStyleTools} />
            <CollapsableTools
                {...props}
                toolsId="text"
                toolsConfig={RICH_TEXT_TOOLS_CONFIG}
                collapseOrder={RICH_TEXT_TOOL_COLLAPSE_ORDER}
                availableTools={availableTools}
                availableHeight={availableHeight}
                gridSizeName={gridSizeName}
                unavailableHeight={unavailableHeight}
            />
        </SubToolList>
    );
};

RichTextToolList.propTypes = {
    editorKey: PropTypes.string,
    gridSizeName: PropTypes.string,
    availableHeight: PropTypes.number,
    unavailableHeight: PropTypes.number,
};

export default RichTextToolList;
