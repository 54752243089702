// Lib
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { getMainEditorId } from '../../utils/elementEditorUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Components
import CardClipperContents from './CardClipperContents';

// Types
import { DeviceFormFactor, MilanoteApplicationMode } from '../../../platform/platformTypes';

const CardClipper = (props) => {
    const { isEditing, isClipping } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);

    if (platformDetails.appMode !== MilanoteApplicationMode.desktop) return null;
    if (platformDetails.formFactor === DeviceFormFactor.phone) return null;

    if (!isClipping && !isEditing) return null;

    return <CardClipperContents {...props} editorId={getMainEditorId(props)} />;
};

CardClipper.propTypes = {
    isEditing: PropTypes.bool,
    editorState: PropTypes.object,

    isClipping: PropTypes.bool,
    onClipStart: PropTypes.func,
    onClipEnd: PropTypes.func,
};

export default CardClipper;
