// Lib
import React, { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';

// Utils
import {
    getFirstSelectedColor,
    getSelectedElementType,
} from '../../workspace/toolbar/components/selectionTools/colorTools/colorToolUtils';
import { getColorDefaultHexValue } from '../../../common/colors/elementColorFormatUtil';
import { isBoardLike, isLine } from '../../../common/elements/utils/elementTypeUtils';

// Actions
import { closePopup } from '../popupPanel/popupActions';
import setTemporarySelectedElementsColorStyles from '../../workspace/toolbar/components/selectionTools/colorTools/setTemporarySelectedElementsColorStyles';
import { updateSelectedElementsColors } from '../../workspace/toolbar/components/selectionTools/colorTools/colorActions';

// Components
import CustomColorInput from '../../workspace/toolbar/components/selectionTools/colorTools/CustomColorInput';

// Constants
import { DEFAULT_BOARD_COLOR, DEFAULT_ELEMENT_COLOR } from '../../../common/colors/colorConstants';
import { PopupIds } from '../popupPanel/popupConstants';
import { List } from 'immutable';
import { ImMNElement } from '../../../common/elements/elementModelTypes';

const mapDispatchToProps = (dispatch: Function) => ({
    setTempColor: setTemporarySelectedElementsColorStyles,
    setColor: (color: string) => {
        dispatch(updateSelectedElementsColors({ color, secondaryColor: null, useExistingColorSpace: true }));
        dispatch(closePopup(PopupIds.COLOR, false));
    },
});

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const getInitialColor = (elements: List<ImMNElement>): string => {
    // first check if our selection has a color already assigned that we should use
    const selectedColor = getFirstSelectedColor(elements);
    const selectedColorHex = getColorDefaultHexValue(selectedColor);
    if (selectedColorHex) return selectedColorHex;

    // otherwise go with a default color
    const firstElementType = getSelectedElementType(elements);
    return isBoardLike(firstElementType) || isLine(firstElementType) ? DEFAULT_BOARD_COLOR : DEFAULT_ELEMENT_COLOR;
};

type ElementColorPickerProps = ReduxProps & {
    openerEventTarget?: EventTarget;
    selectedColorHex?: string;
    elements?: List<ImMNElement>;
    onOpen?: (color: string) => void;
    onBlur?: (color: string) => void;
};

const ElementColorPicker = ({
    openerEventTarget,
    setColor,
    setTempColor,
    onOpen,
    onBlur,
    elements,
}: ElementColorPickerProps): ReactElement => (
    <CustomColorInput
        openerEventTarget={openerEventTarget}
        setTempColor={setTempColor}
        setColor={setColor}
        initialColor={elements && getInitialColor(elements)}
        onOpen={onOpen}
        onBlur={onBlur}
    />
);

export default connector(ElementColorPicker);
