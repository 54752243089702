// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import userContactsDataReducer from './userContactsDataReducer';
import userContactsUserIdsReducer from './userContactsUserIdsReducer';

export default combineReducers({
    contacts: userContactsDataReducer,
    userIds: userContactsUserIdsReducer,
});
