import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Util
import { prop } from '../../../../common/utils/immutableHelper';
import fileSupportsModalView from '../utils/fileSupportsModalView';
import { getFileSizeForDisplay } from '../../../../common/files/fileSizeUtils';
import { registerDownloadSource } from '../../../utils/fileDownloader/javascriptToSwiftFileDownloads';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Hooks
import useStableUniqueIdRef from '../../../utils/react/useStableUniqueIdRef';

// Components
import DownloadButton from '../../../components/buttons/DownloadButton';
import OpenLinkInElementModalButton from '../../../components/buttons/OpenLinkInElementModalButton';

const FileSecondaryDetails = ({ element, fileData, showDownloadOption }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const uniqueId = useStableUniqueIdRef();

    const supportsModalView = fileSupportsModalView(element);
    const elementId = getElementId(element);

    return (
        <div className="FileSecondaryDetails" id={uniqueId}>
            {fileData && showDownloadOption && (
                <DownloadButton
                    url={prop('url', fileData)}
                    filename={prop('filename', fileData)}
                    onDownloadStart={() => {
                        setIsDownloading(true);
                        registerDownloadSource(`#${uniqueId}`);
                    }}
                    onDownloadEnd={() => setIsDownloading(false)}
                    elementId={elementId}
                >
                    Download
                </DownloadButton>
            )}

            {supportsModalView && !isDownloading && (
                <>
                    <OpenLinkInElementModalButton
                        element={element}
                        divider={showDownloadOption && ' - '}
                        className="open-link"
                    >
                        Open
                    </OpenLinkInElementModalButton>
                    <span> {!showDownloadOption && ' - '}</span>
                </>
            )}

            {!isDownloading && (
                <>
                    {showDownloadOption && ' - '}
                    <span className="file-size">{getFileSizeForDisplay(prop('size', fileData))}</span>
                </>
            )}
        </div>
    );
};

FileSecondaryDetails.propTypes = {
    element: PropTypes.object,
    fileData: PropTypes.object,
    showDownloadOption: PropTypes.bool,
};

export default FileSecondaryDetails;
