// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { throttle, debounce } from 'lodash';

// Prop comparisons
import { noLonger, now } from '../../utils/react/propsComparisons';
import { getHasImageToShow } from './imageElementUtils';

const isNowHovered = now('isHovered');
const isNoLongerHovered = noLonger('isHovered');

const HOVER_DELAY_DEFAULT = 500;

export const imageHoverWatcher = (DecoratedComponent) => {
    class ImageHoverWatcher extends React.Component {
        componentWillReceiveProps(nextProps) {
            if (isNoLongerHovered(this.props, nextProps)) {
                this.disableDropTarget();
            }

            if (isNowHovered(this.props, nextProps)) {
                this.onHoverStart(nextProps);
            }
        }

        onHoverStart(nextProps) {
            if (!nextProps.canStartReplaceMode) return;

            // If it's a placeholder image, enable replacement mode immediately
            if (!getHasImageToShow(nextProps)) {
                this.enableReplaceMode();
                return;
            }

            this.debouncedEnableDropTarget();
            document.addEventListener('dragover', this.throttledOnDragOver);
        }

        enableReplaceMode = () => {
            const { setParentHoveredChildAcceptsDrop, dispatchStartReplaceMode, elementId } = this.props;

            dispatchStartReplaceMode(elementId);

            // Drag and Drop - Shallow hover hack
            // This will turn off a list's drop preview if the image is going to accept the drop
            setParentHoveredChildAcceptsDrop?.(true);
        };

        componentWillUnmount() {
            this.disableDropTarget();
        }

        onDragOver = (e) => {
            if (e.clientX === this.prevClientX && e.clientY === this.prevClientY) return;

            this.prevClientX = e.clientX;
            this.prevClientY = e.clientY;

            this.debouncedEnableDropTarget();
        };

        throttledOnDragOver = throttle(this.onDragOver, 20);

        onHoverDelayFinished = () => {
            this.enableReplaceMode();
            this.throttledOnDragOver.cancel();
            document.removeEventListener('dragover', this.throttledOnDragOver);
        };

        debouncedEnableDropTarget = debounce(this.onHoverDelayFinished, HOVER_DELAY_DEFAULT);

        disableDropTarget = () => {
            const { setParentHoveredChildAcceptsDrop, isReplaceModeHovered, dispatchEndReplaceMode } = this.props;

            // Drag and Drop - Shallow hover hack
            // This will turn off a list's drop preview if the image is going to accept the drop
            setParentHoveredChildAcceptsDrop?.(false);

            if (isReplaceModeHovered) {
                dispatchEndReplaceMode();
            }

            this.throttledOnDragOver.cancel();
            this.debouncedEnableDropTarget.cancel();
            document.removeEventListener('dragover', this.throttledOnDragOver);
        };

        render() {
            return <DecoratedComponent {...this.props} />;
        }
    }

    ImageHoverWatcher.propTypes = {
        element: PropTypes.object,
        placeholder: PropTypes.object,
        elementId: PropTypes.string,
        isReplaceModeHovered: PropTypes.bool,
        dispatchStartReplaceMode: PropTypes.func,
        dispatchEndReplaceMode: PropTypes.func,

        setParentHoveredChildAcceptsDrop: PropTypes.func,
    };

    return ImageHoverWatcher;
};
