// Persistence
import localStorageService from '../../../../utils/services/localStorage/localStorage';

export const DND_3D_EFFECTS_LOCAL_STORAGE_KEY = 'milanote.dnd.3d';

export const getDnd3dEffectsEnabledLocalStorage = () => localStorageService.getItem(DND_3D_EFFECTS_LOCAL_STORAGE_KEY);
export const setDnd3dEffectsEnabledLocalStorage = (value) =>
    localStorageService.setItem(DND_3D_EFFECTS_LOCAL_STORAGE_KEY, value);

export const getBrowserPrefersReducedMotion = () => window.matchMedia('(prefers-reduced-motion: reduce)').matches;
