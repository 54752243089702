// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../node_module_clones/recompose';

// Utils
import { getCreatedTime, getPlatform } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getLastCheckedQuickNotes } from '../../user/currentUserSelector';

// Components
import { poiBoardSectionContextConsumer } from '../../components/pointsOfInterest/PoiBoardSectionContext';
import { SimpleActivityIndicator } from './ActivityIndicator';

// Constants
import { PLATFORMS } from '../../../common/users/userConstants';
import { WORKSPACE_SECTIONS } from '../../workspace/workspaceConstants';

const mapStateToProps = createStructuredSelector({
    lastCheckedQuickNotes: getLastCheckedQuickNotes,
});

const enhance = compose(poiBoardSectionContextConsumer, connect(mapStateToProps));

const QuickNotesActivityIndicator = (props) => {
    const { element, boardSection, lastCheckedQuickNotes } = props;

    if (boardSection !== WORKSPACE_SECTIONS.QUICK_NOTES) return null;

    const platform = getPlatform(element);
    if (platform !== PLATFORMS.IPHONE && platform !== PLATFORMS.ANDROID) return null;

    const creationTime = getCreatedTime(element);
    if (creationTime < lastCheckedQuickNotes) return null;

    return <SimpleActivityIndicator />;
};

QuickNotesActivityIndicator.propTypes = {
    element: PropTypes.object,
    boardSection: PropTypes.string,
    lastCheckedQuickNotes: PropTypes.number,
};

export default enhance(QuickNotesActivityIndicator);
