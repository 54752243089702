// Lib
import * as Immutable from 'immutable';
import { BOARD_CANVAS_ORDER_LOAD } from '../../../../common/boards/boardConstants';

const initialState = Immutable.Map();
export default (state = initialState, action) => {
    switch (action.type) {
        case BOARD_CANVAS_ORDER_LOAD:
            return state.merge(action.canvasOrder);
        default:
            return state;
    }
};
