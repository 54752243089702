import { isPlainObject } from 'lodash';
import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';
import fieldValidationService from '../../fieldValidationService';

const OBJECT_ERROR_OBJECT = {
    name: FAILURE_NAMES.object,
    type: FAILURE_TYPES.ERROR,
    message: 'Must be a plain object',
};

export default (fieldValue, validationConfig) => {
    if (!fieldValue) return;

    if (!isPlainObject(fieldValue)) {
        return {
            ...OBJECT_ERROR_OBJECT,
            details: fieldValue,
        };
    }

    if (validationConfig.keys) {
        const fieldErrors = validationConfig.keys
            .map((keyType) => fieldValidationService(fieldValue[keyType.name], keyType))
            .filter((err) => !!err);

        if (fieldErrors.length) {
            return {
                ...OBJECT_ERROR_OBJECT,
                message: 'Object key validation failed',
                details: fieldErrors,
            };
        }
    }

    return null;
};
