export const DOM_TAG_NAMES = {
    HTML: 'HTML',
    DIV: 'DIV',
    SPAN: 'SPAN',
};

export const DOM_POINTER_TYPES = {
    MOUSE: 'mouse',
    PEN: 'pen',
    TOUCH: 'touch',
};

// ** iOS Safari ONLY **
export const SAFARI_DOM_TOUCH_TYPES = {
    // Finger
    DIRECT: 'direct',
    // Apple pencil
    STYLUS: 'stylus',
};
