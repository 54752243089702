// Types
import { LegacyHybridUseCase, MilanoteApplicationMode } from '../platformTypes';
import { ImMNUser, MNUser } from '../../../common/users/userModelTypes';
import { isFeatureEnabled } from '../../../common/users/utils/userPropertyUtils';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

/**
 * Determines the Milanote application mode, based on the hybrid use case.
 */
export const getAppModeFromHybridUseCase = (hybridUseCase: LegacyHybridUseCase): MilanoteApplicationMode => {
    switch (hybridUseCase) {
        case LegacyHybridUseCase.IOS_CANVAS:
        case LegacyHybridUseCase.IPAD_OS:
            return MilanoteApplicationMode.desktop;
        case LegacyHybridUseCase.IOS_BOARD_LIST:
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
        default:
            return MilanoteApplicationMode.mobileLegacy;
    }
};

/**
 * Determines the Milanote application mode for the current user based on their feature flags and if they're
 * running in the hybrid app context.
 */
export const getMobileAppModeForCurrentUser = (
    user: MNUser | ImMNUser,
    hybridUseCase: LegacyHybridUseCase | null,
): MilanoteApplicationMode => {
    if (hybridUseCase) return getAppModeFromHybridUseCase(hybridUseCase);

    return isFeatureEnabled(ExperimentId.mobileV2, user)
        ? MilanoteApplicationMode.mobile
        : MilanoteApplicationMode.mobileLegacy;
};
