// Utils
import { isCommentThread } from '../../../../common/elements/utils/elementTypeUtils';
import { isIconViewLike } from '../../../../common/elements/utils/elementDisplayUtils';
import { getLocationSection } from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { BoardSections } from '../../../../common/boards/boardConstants';
import { ACCESS_BITS } from '../../../../common/permissions/permissionBitsConstants';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';

// permissions predicates
export const feedbackRequired = (): number => ACCESS_BITS.FEEDBACK;
export const commentsFeedbackOtherwiseAll = (selectedElements: Immutable.List<ImMNElement>): number =>
    selectedElements.every(isCommentThread) ? ACCESS_BITS.FEEDBACK : ACCESS_BITS.WRITE;

// availability predicates
export const allSelectedElementsOnCanvas = (selectedElements: Immutable.List<ImMNElement>): boolean =>
    selectedElements.every((element) => getLocationSection(element) === BoardSections.CANVAS);

export const noSelectedElementsInIconViewMode = (selectedElements: Immutable.List<ImMNElement>): boolean =>
    selectedElements.every((element) => !isIconViewLike(element));
