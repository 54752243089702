import { compact } from 'lodash/fp';

// Constants
import { BLOCK_METADATA } from '../richText/richTextConstants';

const getPlaceholderClasses = (editorState) => {
    if (!editorState) return true;

    const contentState = editorState.getCurrentContent();

    const firstBlock = contentState.getFirstBlock();

    return compact([
        `placeholder-${firstBlock.getType()}`,
        firstBlock.getData().get(BLOCK_METADATA.TEXT_ALIGN_CENTER) ? 'placeholder-align-center' : undefined,
    ]);
};

export default getPlaceholderClasses;
