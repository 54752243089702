import React from 'react';

const AuthFormDisclaimerSection = () => (
    <div className="disclaimer">
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/terms">terms of service</a> and{' '}
        <a href="https://policies.google.com/privacy">privacy policy</a> apply.
    </div>
);

export default AuthFormDisclaimerSection;
