// Utils
import editorStoreConnect from './editorStoreConnect';

// Selectors
import { getEditorId, getEditorState } from '../reducers/editorStoreSelector';
import { editorSelectionSave } from '../editorActions';

const mapEditorDispatchToProps = (dispatch) => ({
    dispatchGetEditorState: () => dispatch((_, getState) => getEditorState(getState())),
    dispatchGetEditorId: () => dispatch((_, getState) => getEditorId(getState())),
    dispatchSaveEditorSelection: ({ editorId, selection }) => dispatch(editorSelectionSave({ editorId, selection })),
    dispatchSaveCurrentSelection: () =>
        dispatch((_, getState) => {
            const state = getState();
            const editorId = getEditorId(state);
            const editorState = getEditorState(state);

            const selection = editorState.getSelection();

            dispatch(editorSelectionSave({ editorId, selection }));
        }),
});

export default editorStoreConnect(null, mapEditorDispatchToProps);
