import { Point } from '../../../common/maths/geometry/pointTypes';

export enum DragAndDropMode {
    ATTACH,
    DEFAULT,
}

export enum DragAndDropSnapMode {
    NONE,
    // The x dimension remains constant, so the element will only be dragged vertically
    SNAP_HORIZONTAL,
    // The y dimension remains constant, so the element will only be dragged horizontally
    SNAP_VERTICAL,
}

export enum DragAndDropInterpolationStyle {
    NONE,
    TWO_D,
    THREE_D,
}

export type DragAndDropState = {
    groupPosition: Point;
    elementRotation: Point;
    shadowIntensity: number;

    dragAndDropMode: DragAndDropMode;
    snapMode: DragAndDropSnapMode;
    interpolationStyle: DragAndDropInterpolationStyle;
};
