import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContentEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        viewBox="0 0 48 48"
        className="Icon content-empty"
        {...props}
    >
        <path
            fill="#BBBEC3"
            fillRule="evenodd"
            d="M8 7c1.633 0 3.086.813 4 2.031C12.914 7.812 14.367 7 16 7h2a1 1 0 0 1 0 2h-2c-1.668 0-3 1.332-3 3v10h2a1 1 0 0 1 0 2h-2v12c0 1.668 1.332 3 3 3h2a1 1 0 0 1 0 2h-2c-1.633 0-3.086-.813-4-2.031C11.086 40.187 9.633 41 8 41H6a1 1 0 0 1 0-2h2c1.668 0 3-1.332 3-3V24H9a1 1 0 0 1 0-2h2V12c0-1.668-1.332-3-3-3H6a1 1 0 0 1 0-2zm32.537 6L41 19.8h-1.42c-1.697-4.042-2.13-5.17-4.815-5.17h-1.82v16.61c0 1.848 1.018 2.193 3.055 2.256V35H25.97v-1.504c2.067-.063 3.055-.408 3.055-2.257V14.63h-1.79c-2.686 0-3.118 1.129-4.846 5.172H21L21.463 13h19.074z"
        />
    </svg>
);
const Memo = memo(ContentEmptyIcon);
export default Memo;
