import * as Immutable from 'immutable';

import { BoardSections } from '../../common/boards/boardConstants';
import { SET_FOCUS_SECTION } from './focusConstants';

const initialState = Immutable.fromJS({
    section: BoardSections.CANVAS,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FOCUS_SECTION:
            return state.set('section', action.section);
        default:
            return state;
    }
};
