import { ActionObject } from '../../../../common/actions/actionTypes';
import { MNElement } from '../../../../common/elements/elementModelTypes';

// Action Types
export const HYBRID_ACTION_TYPES = {
    ELEMENT_SELECTED: 'HYBRID_ELEMENT_SELECTED',
    ELEMENT_EDIT_START: 'HYBRID_ELEMENT_EDIT_START',
    LAUNCH_APPLE_SUBSCRIPTION_STORE: 'HYBRID_LAUNCH_APPLE_SUBSCRIPTION_STORE',
    LAUNCH_APPLE_MANAGE_SUBSCRIPTION_VIEW: 'HYBRID_LAUNCH_APPLE_MANAGE_SUBSCRIPTION_VIEW',
    PRESENT_MODAL_IMAGE: 'HYBRID_PRESENT_MODAL_IMAGE',
    PRESENT_MODAL_PDF: 'HYBRID_PRESENT_MODAL_PDF',
    PRESENT_MODAL_SKETCH: 'HYBRID_PRESENT_MODAL_SKETCH',
    REDO: 'HYBRID_REDO',
    UNDO: 'HYBRID_UNDO',
    USER_NAVIGATE: 'HYBRID_USER_NAVIGATE',
} as const;

export interface HybridLaunchAppleSubscriptionStoreAction extends ActionObject {
    type: 'HYBRID_LAUNCH_APPLE_SUBSCRIPTION_STORE';
}

export interface HybridLaunchAppleManageSubscriptionViewAction extends ActionObject {
    type: 'HYBRID_LAUNCH_APPLE_MANAGE_SUBSCRIPTION_VIEW';
}

export interface HybridLocationChangeFileAction extends ActionObject {
    fileElement: MNElement;
    type: 'HYBRID_PRESENT_MODAL_PDF';
}

export interface HybridLocationChangeSketchAction extends ActionObject {
    sketchElement: MNElement;
    type: 'HYBRID_PRESENT_MODAL_SKETCH';
}

export interface HybridLocationChangeImageAction extends ActionObject {
    imageElement: MNElement;
    currentBoardImageElements: Array<MNElement>;
    type: 'HYBRID_PRESENT_MODAL_IMAGE';
}

export interface HybridLocationChangeNavigationAction extends ActionObject {
    path: String;
    type: 'HYBRID_USER_NAVIGATE';
}

export interface HybridElementEditStartAction extends ActionObject {
    element: MNElement | undefined;
    relevantElements: Map<string, MNElement> | undefined;
    id?: string;
    editorKey?: string;
    type: 'HYBRID_ELEMENT_EDIT_START';
}

export interface HybridElementSelectedAction extends ActionObject {
    id?: string;
    type: 'HYBRID_ELEMENT_SELECTED';
}
