import { getClientIdResponse } from './clientIdManager';
import createElementId from './createElementId';

import { TICK_MAX } from './uidConstants';

export default class ClientIdInstance {
    constructor() {
        this.refreshClientId();
    }

    refreshClientId() {
        const initId = getClientIdResponse();
        this.clientId = initId.clientId;
        this.clientTick = initId.clientTick;
    }

    getClientId() {
        return this.clientId;
    }

    getTick() {
        this.clientTick = this.clientTick + 1;

        if (this.clientTick >= TICK_MAX) {
            this.refreshClientId();
        }

        return this.clientTick;
    }

    getElementId() {
        const clientTick = this.getTick();
        const clientId = this.getClientId();

        return createElementId(clientId, clientTick);
    }
}

let globalInstance;
export const getGlobalClientId = () => {
    if (!globalInstance) globalInstance = new ClientIdInstance();
    return globalInstance;
};
