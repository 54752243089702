// Lib
import { throttle } from 'lodash';

// Utils
import { TIMES } from '../../../common/utils/timeUtil';

// The maximum number of actions of a single type that can be sent within the throttle time
const MAX_ACTION_TYPE_COUNT = 100;
const CLEAR_SENT_COUNT_THROTTLE_TIME = 10 * TIMES.SECOND;

export default () => {
    let sentCounts = {};

    /**
     * Resets the sent action counts.
     */
    const clearSentActionCount = () => {
        sentCounts = {};
    };

    /**
     * Clears the sent action count at most once every ten seconds.
     */
    const throttledClearSentActionCount = throttle(clearSentActionCount, CLEAR_SENT_COUNT_THROTTLE_TIME, {
        leading: false,
        trailing: true,
    });

    /**
     * This tracks the number of actions sent of a particular type within the throttle period.
     */
    const incrementSentActionCount = (action) => {
        if (!action) return;

        sentCounts[action.type] = sentCounts[action.type] || 0;
        sentCounts[action.type]++;

        throttledClearSentActionCount();
    };

    /**
     * For now this will report true unless the action type is exactly on the limit of the
     * throttled action type max counts.
     */
    const isWithinCountLimits = (action) => {
        if (!action) return true;

        const count = sentCounts[action.type];

        // TODO - Probably should change this to >= once the actual limiting has been implemented
        return count !== MAX_ACTION_TYPE_COUNT;
    };

    return {
        incrementSentActionCount,
        isWithinCountLimits,
    };
};
