import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuRedoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-redo"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M16.11 2.728a.5.5 0 0 1 .702-.078l3.7 2.96a.5.5 0 0 1 0 .78l-3.7 2.96A.5.5 0 0 1 16 8.96V6.5h-5a6.5 6.5 0 0 0 0 13h2.25a.75.75 0 1 1 0 1.5H11a8 8 0 1 1 0-16h5V3.04a.5.5 0 0 1 .11-.312z"
        />
    </svg>
);
const Memo = memo(ContextMenuRedoIcon);
export default Memo;
