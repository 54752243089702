import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileAddButtonIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon mobile-add-button"
        {...props}
    >
        <path
            fill="#F4511C"
            fillRule="evenodd"
            d="M12 3.25a.75.75 0 0 1 .743.648L12.75 4v7.25H20a.75.75 0 0 1 .102 1.493L20 12.75h-7.25V20a.75.75 0 0 1-1.493.102L11.25 20v-7.25H4a.75.75 0 0 1-.102-1.493L4 11.25h7.25V4a.75.75 0 0 1 .75-.75z"
        />
    </svg>
);
const Memo = memo(MobileAddButtonIcon);
export default Memo;
