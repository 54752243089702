// Lib
import { createSelector } from 'reselect';

// Selectors
import { getCurrentUserId } from '../../user/currentUserSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getUsers } from '../../user/usersSelector';

// Utils
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { isOnline } from '../../user/userUtil';
import { getUserId } from '../../../common/users/utils/userPropertyUtils';

export const usersCurrentlyViewingSelector = createSelector(
    getCurrentBoardId,
    getUsers,
    getCurrentUserId,
    (currentBoardId, users, currentUserId) =>
        users.filter(
            (user) =>
                isOnline(user) && user.get('currentBoardId') === currentBoardId && getUserId(user) !== currentUserId,
        ),
);

export const remoteUserIdsCurrentlyViewingSelector = createShallowSelector(
    usersCurrentlyViewingSelector,
    getCurrentUserId,
    (users, currentUserId) =>
        users
            .filter((user) => getUserId(user) !== currentUserId)
            .keySeq()
            .toArray(),
);
