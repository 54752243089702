const handleBeforeInput = (char, editorState, eventTimeStamp, { getProps }) => {
    const { onActivity } = getProps();
    onActivity && onActivity();
};

/**
 */
export default () => ({
    handleBeforeInput,
});
