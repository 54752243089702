// Lib
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

// Utils
import { getTableCellEditorId, getTableGridEditorId } from '../utils/tableCellEditingUtils';
import { fixSelectionBorderDisplay, repositionAutofillHandle } from '../utils/tableCellSelectionUtils';

const TableCellSelectionHandlers = forwardRef(function TableCellSelectionHandlersComponent(props, ref) {
    const {
        elementId,
        currentlyEditingEditorId,

        hotTableInstanceRef,
        tableOperationsRef,
        hotTableContainerRef,

        isSingleSelected,
        isResizing,
        isReadOnly,

        dispatchSetCellSelection,
        dispatchStartEditingTableGrid,
        dispatchFinishEditingElement,
    } = props;

    useEffect(() => {
        // Prevent cells from reselecting if another element is selected or if user is editing title/caption
        // this is just because on first click onto another element the table
        // rerenders before meta is cleared from app selection state

        if (!hotTableInstanceRef.current || isResizing) return;

        if (!isSingleSelected) {
            hotTableInstanceRef.current.deselectCell();
            hotTableInstanceRef.current.unlisten();
        }

        // Start listening when the table is single selected so that shortcuts work
        if (isSingleSelected && !isReadOnly) hotTableInstanceRef.current.listen();
    }, [isSingleSelected]);

    /**
     * If user focuses on another editor key on the table (e.g. title/caption), deselect the current cell selection
     */
    useEffect(() => {
        if (!hotTableInstanceRef.current || !hotTableInstanceRef.current.isListening()) return;

        const isEditingCellOrGrid =
            !currentlyEditingEditorId ||
            currentlyEditingEditorId === getTableGridEditorId(elementId) ||
            currentlyEditingEditorId === getTableCellEditorId(elementId);

        if (isEditingCellOrGrid) return;

        hotTableInstanceRef.current.deselectCell();
    }, [elementId, currentlyEditingEditorId]);

    const afterSelection = useCallback(
        (row, column, row2, column2, _, selectionLayerLevel) => {
            fixSelectionBorderDisplay(hotTableInstanceRef.current);

            const isEditingCellOrGrid =
                !currentlyEditingEditorId ||
                currentlyEditingEditorId === getTableGridEditorId(elementId) ||
                currentlyEditingEditorId === getTableCellEditorId(elementId);

            if (!isEditingCellOrGrid) {
                requestAnimationFrame(() => dispatchFinishEditingElement());
            }

            // Apply new cell selection to redux state if it has changed
            const newCellSelection = [row, column, row2, column2];

            repositionAutofillHandle(hotTableInstanceRef.current, hotTableContainerRef);

            const transactionId = tableOperationsRef.current.popTransactionIdForNextUpdateCellSelection();
            dispatchSetCellSelection(elementId, newCellSelection, selectionLayerLevel, transactionId);
        },
        [elementId, currentlyEditingEditorId],
    );

    const afterSelectionEnd = useCallback(() => {
        if (currentlyEditingEditorId !== getTableGridEditorId(elementId)) {
            dispatchStartEditingTableGrid(elementId);
        }
    }, [elementId, currentlyEditingEditorId]);

    const afterDeselect = useCallback(() => {
        // Use setTimeout here to make sure all operations have run before we check if
        // there are still selection on the table.
        setTimeout(() => {
            if (hotTableInstanceRef.current?.getSelected()) return;

            dispatchFinishEditingElement(elementId);
            dispatchSetCellSelection(elementId, null);
        });
    }, [elementId]);

    useImperativeHandle(
        ref,
        () => ({
            afterSelection,
            afterSelectionEnd,
            afterDeselect,
        }),
        [afterSelection, afterSelectionEnd, afterDeselect],
    );

    return null;
});

TableCellSelectionHandlers.propTypes = {
    elementId: PropTypes.string,
    currentlyEditingEditorId: PropTypes.string,

    hotTableInstanceRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    tableOperationsRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    hotTableContainerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    isSingleSelected: PropTypes.bool,
    isResizing: PropTypes.bool,
    isReadOnly: PropTypes.bool,

    dispatchSetCellSelection: PropTypes.func,
    dispatchStartEditingTableGrid: PropTypes.func,
    dispatchFinishEditingElement: PropTypes.func,
};

export default TableCellSelectionHandlers;
