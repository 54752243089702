/**
 * Lightweight method to retrieve the decoded token.
 * Using the JWT library is overly heavy considering all we want to do is get the
 * payload as an object.
 */
export const simpleDecodeJwt = (token: string | null): any | null => {
    if (!token) return null;

    try {
        const tokenParts = token.split('.');
        const payload = tokenParts[1];
        const decodedString = atob(payload);
        return JSON.parse(decodedString);
    } catch (e) {
        return null;
    }
};
