import { prop } from '../../../common/utils/immutableHelper';

export const getReactionUserId = prop('userId');
export const getReactionEmojiCode = prop('emojiCode');

export const getUniqueReactions = (reactions) =>
    reactions.reduce((acc, reaction) => {
        if (!acc) return acc;

        const userId = getReactionUserId(reaction);
        const emojiCode = getReactionEmojiCode(reaction);

        const reactionGroup = acc[emojiCode] || { emojiCode, userIds: [] };
        reactionGroup.userIds.push(userId);

        acc[emojiCode] = reactionGroup;

        return acc;
    }, {});
