const getCurrencyDisplayName = (currencyCode: string, locale: string): string => {
    if (!currencyCode) return '';
    // @ts-ignore - valid property of Intl
    return `${new Intl.DisplayNames(locale, { type: 'currency' }).of(currencyCode)} (${currencyCode})`;
};

export const getCurrencyList = (locale: string): Array<string> => {
    // @ts-ignore - valid property of Intl
    const supportedCurrencyCodes = Intl.supportedValuesOf('currency');

    const currencyOptions = supportedCurrencyCodes.map((currencyCode: string) => ({
        id: currencyCode,
        name: getCurrencyDisplayName(currencyCode, locale),
    }));

    return currencyOptions.sort((a: { id: string; name: string }, b: { id: string; name: string }) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
};
