// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity, constant } from 'lodash';
import { createStructuredSelector } from 'reselect';

// Selectors
import { getCurrentCellSelections } from './tableSelector';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import {
    getCaption,
    getElementId,
    getShowCaption,
    getShowTitle,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getTableCellEditorId, getTableEditorId, getTableGridEditorId } from './utils/tableCellEditingUtils';

// Components
import newElementDecorator from '../newElementDecorator';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import Table from './Table';

// Actions
import { updateElement, createAndEditElement } from '../actions/elementActions';
import { moveElementsToTrash } from '../actions/elementShortcutActions';
import {
    deselectAllElements,
    finishEditingElement,
    removeSelectedElements,
    startEditingElement,
} from '../selection/selectionActions';
import { updateCellSelectionMeta, updateTableElement } from './tableActions';
import { finishUndoingOrRedoing, redoAction, undoAction } from '../../utils/undoRedo/undoRedoActions';

// Constants
import {
    TABLE_CELL_EDITOR_KEY,
    TABLE_GRID_EDITOR_KEY,
    TABLE_MIN_HEIGHT,
    TABLE_MIN_WIDTH,
    TABLE_TITLE_EDITOR_KEY,
} from '../../../common/table/tableConstants';
import { SHOW_TITLE_PROPERTY } from '../../../common/taskLists/taskListConstants';
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { ElementType } from '../../../common/elements/elementTypes';

const mapStateToProps = createStructuredSelector({
    currentCellSelections: getCurrentCellSelections,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateAndEditNewElement: ({ location, currentBoardId, element, creationSource, content }) => {
        const caption = getCaption(element);

        return dispatch(
            createAndEditElement({
                creationSource,
                elementType: ElementType.TABLE_TYPE,
                location,
                currentBoardId,
                content: {
                    caption: caption && caption.toJS(),
                    showCaption: getShowCaption(element),
                    showTitle: getShowTitle(element),
                    ...content,

                    // For table elements, always set the width to the default width
                    width: ELEMENT_DEFAULT_WIDTH,
                },
                select: true,
            }),
        );
    },
    dispatchMoveElementsToTrash: ({ currentBoardId, elementIds }) =>
        dispatch(moveElementsToTrash({ currentBoardId, elementIds })),
    dispatchUpdateTitle: ({ id, title, transactionId }) =>
        dispatch(updateElement({ id, changes: { title }, transactionId })),
    dispatchHideTitle: ({ id, transactionId }) =>
        dispatch(updateElement({ id, changes: { [SHOW_TITLE_PROPERTY]: false }, transactionId })),
    dispatchDeselectAllElements: () => dispatch(deselectAllElements()),
    dispatchStartEditingTitle: (elementId) =>
        dispatch(
            startEditingElement({
                id: elementId,
                editorKey: TABLE_TITLE_EDITOR_KEY,
                editorId: getTableEditorId(elementId),
            }),
        ),
    dispatchStartEditingTableGrid: (elementId) =>
        dispatch(
            startEditingElement({
                id: elementId,
                editorKey: TABLE_GRID_EDITOR_KEY,
                editorId: getTableGridEditorId(elementId),
            }),
        ),
    dispatchStartEditingTableCell: (elementId) =>
        dispatch(
            startEditingElement({
                id: elementId,
                editorKey: TABLE_CELL_EDITOR_KEY,
                editorId: getTableCellEditorId(elementId),
            }),
        ),
    dispatchFinishEditingElement: (elementId) => dispatch(finishEditingElement(elementId)),
    dispatchSetCellSelection: (elementId, cellSelection, selectionLayerLevel, transactionId) =>
        dispatch(updateCellSelectionMeta(elementId, cellSelection, selectionLayerLevel, transactionId)),
    dispatchUpdateTableElement: (arg) => dispatch(updateTableElement(arg)),
    dispatchUndoAction: () => dispatch(undoAction()),
    dispatchRedoAction: () => dispatch(redoAction()),
    dispatchDeselectElement: (elementId) => dispatch(removeSelectedElements({ ids: [elementId] })),
    dispatchFinishUndoingOrRedoing: () => dispatch(finishUndoingOrRedoing()),
});

@connect(mapStateToProps, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys'])
@captionContainerDecorator
@elementResizeDecorator({
    getMinWidth: constant(TABLE_MIN_WIDTH),
    getMinHeight: constant(TABLE_MIN_HEIGHT),
    enableHeight: true,
})
@newElementDecorator
class TableContainer extends React.Component {
    moveElementsToTrash = () => {
        const { currentBoardId, element, dispatchMoveElementsToTrash } = this.props;
        dispatchMoveElementsToTrash({ currentBoardId, elementIds: [getElementId(element)] });
    };

    shouldComponentUpdate(nextProps) {
        return !nextProps.isElementOpenInModal || !this.props.isElementOpenInModal;
    }

    submitTableTitle = () => {
        const { dispatchDeselectAllElements } = this.props;
        dispatchDeselectAllElements();
    };

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge, createNewElement } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div>
                <Table
                    {...this.props}
                    updateElementType={this.updateElementType}
                    saveContent={this.saveContent}
                    createNewElement={createNewElement}
                    submitTitle={this.submitTableTitle}
                    moveElementsToTrash={this.moveElementsToTrash}
                />
            </div>,
        );
    }
}

TableContainer.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string.isRequired,
    languagePreference: PropTypes.string,
    createNewElement: PropTypes.func,
    inList: PropTypes.string,
    canConnectLineEdge: PropTypes.bool,
    gridSize: PropTypes.number,
    isElementOpenInModal: PropTypes.bool,
    dispatchSaveTextContent: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    isEditable: PropTypes.bool,
    connectLineEdgeDropTarget: PropTypes.func,
    dispatchStartEditingTitle: PropTypes.func,
    dispatchUpdateElementType: PropTypes.func,
    dispatchDeselectAllElements: PropTypes.func,
    dispatchUpdateTableElement: PropTypes.func,
};

export default TableContainer;
