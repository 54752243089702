import AppError from './AppError';

/**
 * An error to throw when there's a permissions error while traversing.
 */
export default class LimitExceededError extends AppError {
    constructor(error) {
        error.name = 'LimitExceededError';
        super(error);
    }
}
