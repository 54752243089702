// Constants
import {
    BOARD_HIERARCHIES_LOAD,
    BOARD_HIERARCHIES_LOAD_ELEMENTS,
    BOARD_HIERARCHIES_REMOVE,
} from './boardHierarchiesConstants';

// Types
import { IdGraph } from '../../../../common/dataStructures/graphTypes';
import { MNElementMap } from '../../../../common/elements/elementModelTypes';

export const boardHierarchiesLoad = (boardHierarchies: IdGraph) => ({ type: BOARD_HIERARCHIES_LOAD, boardHierarchies });
export const boardHierarchiesLoadElements = (elements: MNElementMap) => ({
    type: BOARD_HIERARCHIES_LOAD_ELEMENTS,
    elements,
});
export const boardHierarchiesRemove = (boardIds: string[]) => ({ type: BOARD_HIERARCHIES_REMOVE, boardIds });
