// Lib
import { useEffect, useRef } from 'react';
import { Editor } from '@tiptap/react';

// Hooks
import useUpdatedRef from '../../../client/utils/react/useUpdatedRef';

// Types
import { TiptapContent } from '../tiptapTypes';
import { Fragment } from '@tiptap/pm/model';

const DEFAULT_DEBOUNCE_TIME = 5000;
const DEFAULT_DEBOUNCE_COUNT = 80;

/**
 * Calls the onDebouncedUpdate function when:
 * - The editor is updated, after a debounce time (default 5 seconds)
 * - The editor is updated a certain number of times since the last update (default 20)
 * - The editor is blurred after an update
 * - The number of lines change.
 */
const useTiptapEditorDebouncedUpdate = (
    editor: Editor | null,
    onDebouncedUpdate: (content: TiptapContent) => void,
    debounceTime = DEFAULT_DEBOUNCE_TIME,
    debounceCount = DEFAULT_DEBOUNCE_COUNT,
): null => {
    // We need to invoke the current version of the update function when it's invoked,
    //  so use a ref to ensure it's always up-to-date.
    const debouncedUpdateFnRef = useUpdatedRef(onDebouncedUpdate);

    const prevEditorStateRef = useRef(editor?.state);

    useEffect(() => {
        if (!editor) return;

        let unsavedUpdateCount = 0;
        let lastSavedContent: Fragment = editor.state.doc.content;

        let timeoutId: number;

        /**
         * Immediately call the update function.
         */
        const flushUpdate = () => {
            if (unsavedUpdateCount === 0) return;

            // No new changes, so ignore update
            if (editor.state.doc.content.eq(lastSavedContent)) return;

            unsavedUpdateCount = 0;
            lastSavedContent = editor.state.doc.content;

            debouncedUpdateFnRef.current(editor.getJSON());
        };

        window.addEventListener('beforeunload', flushUpdate);

        /**
         * Wait either a timeout, or update count, before calling the update function.
         */
        const debounceUpdate = () => {
            if (timeoutId) clearTimeout(timeoutId);

            // If the number of lines change, flush immediately
            const hasLineCountChanged =
                prevEditorStateRef.current?.doc.content.childCount !== editor.state.doc.content.childCount;

            prevEditorStateRef.current = editor.state;
            unsavedUpdateCount++;

            if (hasLineCountChanged) return flushUpdate();

            if (unsavedUpdateCount >= debounceCount) return flushUpdate();

            timeoutId = setTimeout(flushUpdate, debounceTime) as unknown as number;
        };

        /**
         * Reset the unsaved update counter to prevent the next flush update from saving the content.
         */
        const onCanceledDebouncedUpdate = () => {
            unsavedUpdateCount = 0;
            if (timeoutId) clearTimeout(timeoutId);
        };

        editor.on('update', debounceUpdate);
        editor.on('blur', flushUpdate);
        // @ts-ignore - Custom Milanote event
        editor.on('flushDebouncedUpdate', flushUpdate);
        // @ts-ignore - Custom Milanote event
        editor.on('cancelDebouncedUpdate', onCanceledDebouncedUpdate);

        return () => {
            flushUpdate();

            editor.off('update', debounceUpdate);
            editor.off('blur', flushUpdate);
            // @ts-ignore - Custom Milanote event
            editor.off('flushDebouncedUpdate', flushUpdate);
            // @ts-ignore - Custom Milanote event
            editor.off('cancelDebouncedUpdate', onCanceledDebouncedUpdate);

            window.removeEventListener('beforeunload', flushUpdate);

            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [editor]);

    return null;
};

export default useTiptapEditorDebouncedUpdate;
