// Lib
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { toCssUrl } from '../../../../utils/cssUtil';
import { prop } from '../../../../../common/utils/immutableHelper';
import { getElementModalSize } from '../../../modal/elementModalUtils';
import { getCroppedImageHeight, getCroppedImageWidth } from '../imageModalUtils';
import {
    getCaption,
    getElementId,
    getImageThumbnail,
    getIsTransparentImage,
    getShowCaption,
} from '../../../../../common/elements/utils/elementPropertyUtils';

// Components
import Caption from '../../../../components/caption/Caption';
import ProgressBar from '../../../file/uploader/ProgressBar';
import ImageModalDrawingSwitch from './ImageModalDrawingSwitch';
import ElementImage from '../../../../components/images/ElementImage';

// Constants
import { IMAGE_TYPES } from '../../../../../common/media/mediaConstants';
import { IMAGE_MODAL_FOOTER_SIZE, IMAGE_MODAL_MODES } from '../imageModalConstants';

/**
 * Determines the current upload percentage.
 */
const getAttachmentUploadProgress = (attachment) =>
    ((prop('loaded', attachment) || 0) / (prop('total', attachment) || 0)) * 100;

const ImageModalViewModeContent = (props) => {
    const {
        element,
        attachment,
        gridSize,
        lowerQualityImageUrl,
        isEditable,

        mode,
        windowWidth,
        windowHeight,

        setIsEditingDrawing,

        closeCallbackRef,

        idealHeight,
        setIdealHeight,
    } = props;

    // Reset the close callback ref
    closeCallbackRef.current = null;

    const elementId = getElementId(element);
    const caption = getShowCaption(element) && getCaption(element);

    const imageHeight = getCroppedImageHeight({ element, gridSize, attachment });
    const imageWidth = getCroppedImageWidth({ element, gridSize, attachment });
    const isTransparent = getIsTransparentImage(element);

    // Ensure there's always at least colour in the background
    const imageThumbnailUrl = getImageThumbnail(element);

    // Set the thumbnail as a background image, unless it is transparent
    const imageWrapperStyle =
        !isTransparent && imageThumbnailUrl
            ? {
                  backgroundImage: toCssUrl(imageThumbnailUrl),
              }
            : null;

    const { modalWidth, modalHeight, idealModalHeight } = getElementModalSize({
        windowWidth,
        windowHeight,
        imageWidth,
        imageHeight,
        idealHeight,
        caption,
        footerSize: IMAGE_MODAL_FOOTER_SIZE,
        modalMinWidth: 0,
        modalMinHeight: 0,
        gridSize,
    });

    // If we have an ideal modal height and it's different to what's been saved, then store it.
    // This will mean that when cycling between images the previous image's ideal height will
    // be used
    if (idealModalHeight && idealHeight !== idealModalHeight) {
        setIdealHeight(idealModalHeight);
    }

    useEffect(() => {
        setIdealHeight(null);
    }, [windowWidth, windowHeight]);

    const onImageDoubleClick = useCallback(
        (event) => {
            if (!isEditable || mode === IMAGE_MODAL_MODES.DRAW) return;

            setIsEditingDrawing(elementId, true);
        },
        [elementId, isEditable],
    );

    return (
        <div className="ImageModalViewModeContent">
            <div className="image-modal-content" style={{ width: modalWidth, minHeight: modalHeight }}>
                <div className="image-container">
                    <div className="image-wrapper" onDoubleClick={onImageDoubleClick} style={imageWrapperStyle}>
                        <div style={{ maxWidth: imageWidth, maxHeight: imageHeight, height: modalHeight }}>
                            <ElementImage
                                useSecureMediaUrl
                                showBrokenIconOnError
                                element={element}
                                imageType={IMAGE_TYPES.ELEMENT}
                                widthPx={modalWidth}
                                thumbnailUrl={lowerQualityImageUrl}
                                key={elementId}
                            >
                                <ImageModalDrawingSwitch {...props} modalWidth={modalWidth} modalHeight={modalHeight} />
                            </ElementImage>
                            {/*      👆 key forces re-render of image when element id changes */}
                        </div>
                    </div>
                    <Caption
                        element={element}
                        textContent={getCaption(element)}
                        captionVisible={getShowCaption(element)}
                    />
                </div>
            </div>
            {mode === IMAGE_MODAL_MODES.UPLOAD && (
                <div className="uploading">
                    <ProgressBar percentageComplete={getAttachmentUploadProgress(attachment)} barbershop animate />
                </div>
            )}
        </div>
    );
};

ImageModalViewModeContent.propTypes = {
    element: PropTypes.object,
    attachment: PropTypes.object,
    gridSize: PropTypes.number,
    lowerQualityImageUrl: PropTypes.string,
    isEditable: PropTypes.bool,

    mode: PropTypes.string,
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,

    idealHeight: PropTypes.number,
    setIdealHeight: PropTypes.func,

    setIsEditingDrawing: PropTypes.func,

    closeCallbackRef: PropTypes.object,
};

export default ImageModalViewModeContent;
