// Lib
import { get } from 'lodash/fp';

// Utils
import globalLogger, { LoggerComponents } from '../../logger';
import { getIsServiceWorkerAvailable } from '../../registerServiceWorker';
import urlBase64ToUint8Array from '../../utils/services/file/urlBase64ToUint8Array';
import getClientConfig from '../../utils/getClientConfig';

// Selectors
import { isGuestSelector } from '../../user/currentUserSelector';

// Services
import { registerPushSubscription, unregisterPushSubscription } from './pushNotificationsService';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';

// Actions
import { navigateToUrl } from '../../reducers/navigationActions';

// Constants
import { NOTIFICATION_PERMISSIONS } from './pushNotificationsConstants';
import { PUSH_NOTIFICATION_MESSAGE_TYPES } from '../../../common/notifications/pushNotificationConstants';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';

const logger = globalLogger.createChannel(LoggerComponents.SERVICE_WORKER_PUSH_NOTIFICATION_MANAGER);

const clientConfig = getClientConfig();
const vapidPublicKey = get(['push', 'publicKey'], clientConfig);

export const arePushNotificationsSupported = () =>
    getIsServiceWorkerAvailable() && 'Notification' in window && 'PushManager' in window;

export const getPushNotificationsPermission = () =>
    arePushNotificationsSupported() ? Notification.permission : NOTIFICATION_PERMISSIONS.denied;

export const arePushNotificationsAlreadyPermitted = () =>
    arePushNotificationsSupported() && Notification.permission === NOTIFICATION_PERMISSIONS.granted;
export const arePushNotificationsDenied = () =>
    !arePushNotificationsSupported() || Notification.permission === NOTIFICATION_PERMISSIONS.denied;

/**
 * Unsubscribes the service worker from push notifications and notifies the server to do the same.
 */
export const unsubscribeFromPushNotifications = async () => {
    if (!arePushNotificationsSupported()) {
        logger.warn(`Push notifications are not available so cannot unsubscribe`);
        return false;
    }

    const reg = await navigator.serviceWorker.ready;
    const subscription = await reg.pushManager.getSubscription();

    if (!subscription) {
        logger.info(`No subscription to unsubscribe from`);
        return;
    }

    logger.info(`Unsubscribing from`, subscription);

    await subscription.unsubscribe();

    // Un-register on the server
    unregisterPushSubscription({ subscription });

    logger.info(`Successfully unsubscribed`, subscription);

    return true;
};

const registerPushSubscriptionOnServer = ({ subscription, showSuccessNotification }) =>
    registerPushSubscription({ subscription, showSuccessNotification })
        .then(() => {
            logger.info(`%cSubscription registered with server`, 'color: green', subscription);
            return subscription;
        })
        .catch((err) => {
            logger.error(`Subscription failed to register on the server`);
            return unsubscribeFromPushNotifications();
        });

/**
 * Subscribes the service worker to push notifications and register the subscription on the server.
 */
export const subscribeToPushNotifications = async () => {
    if (!arePushNotificationsSupported()) {
        logger.warn(`Push notifications are not available so cannot subscribe`);
        return false;
    }

    const reg = await navigator.serviceWorker.ready;

    if (!vapidPublicKey) {
        logger.warn(`No public VAPID key found, cannot subscribe to push service`);
        return;
    }

    logger.info(`Ready and about to create subscription`);

    let subscription = await reg.pushManager.getSubscription();

    if (subscription) {
        logger.info(`Subscription exists and does not need to be created again`);
        return subscription;
    }

    logger.info(`Subscription does not exist and needs to be created`);

    // Convert the VAPID key to a Unit8Array
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    let showSuccessNotification = false;

    if (!arePushNotificationsAlreadyPermitted()) {
        const result = await Notification.requestPermission();

        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.PUSH_PERMISSION_DECISION,
            eventProperties: {
                decision: result,
            },
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.PUSH_NOTIFICATIONS]: result },
            },
        });

        // Don't continue if the user didn't agree to enable permissions
        if (result === NOTIFICATION_PERMISSIONS.denied || result === NOTIFICATION_PERMISSIONS.default) {
            return Promise.reject(result);
        }

        showSuccessNotification = true;
    }

    subscription = await reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
    });

    logger.info(`%cWorker subscription successfully created`, 'color: green', subscription);

    // Registering on the server
    return registerPushSubscriptionOnServer({ subscription, showSuccessNotification });
};

/**
 * Used when logging in so that a new user's push notifications will be associated to this service worker.
 */
export const refreshPushNotificationsSubscription = async () => {
    if (!arePushNotificationsSupported()) {
        logger.warn(`Push notifications are not available so cannot refresh subscription`);
        return false;
    }

    const reg = await navigator.serviceWorker.ready;

    const subscription = await reg.pushManager.getSubscription();

    if (subscription) {
        logger.info(`A subscription exists, so unsubscribe from it first then resubscribe`, subscription);
        await unsubscribeFromPushNotifications();
    }

    return subscribeToPushNotifications();
};

const registerNavigationMessageHandler = (store) => {
    if (!arePushNotificationsSupported()) return false;

    navigator.serviceWorker.addEventListener('message', (event) => {
        const { data = {} } = event;
        const { url, type } = data;

        if (type !== PUSH_NOTIFICATION_MESSAGE_TYPES.NAVIGATE_TO) return;

        logger.info(`Click received - navigating to`, url);

        store.dispatch(navigateToUrl(url));
    });
};

export const initialisePushSubscription = async (store) => {
    registerNavigationMessageHandler(store);

    if (!arePushNotificationsSupported()) return;

    const state = store.getState();
    const isGuest = isGuestSelector(state);

    if (isGuest) return;

    const reg = await navigator.serviceWorker.ready;
    const subscription = await reg.pushManager.getSubscription();
    const pushNotificationsArePermitted = arePushNotificationsAlreadyPermitted();

    // If we don't have a subscription but push is permitted we should re-subscribe to push notifications
    if (!subscription && pushNotificationsArePermitted) {
        return subscribeToPushNotifications();
    }
};
