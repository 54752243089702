// Lib
import { EditorState } from 'draft-js';

// Constants
import { EditorChangeType } from '../../draftjsConstants';
import getSelectionBlockMap from '../selection/getSelectionBlockMap';

export const updateContentStateMultiBlockMetadata = (contentState, blocks, metadata) => {
    let blockMap = contentState.getBlockMap();

    if (!blockMap) return contentState;

    blocks.forEach((block) => {
        const blockKey = block.getKey();
        const existingBlock = contentState.getBlockForKey(blockKey);

        if (!existingBlock) return;

        const updatedBlock = existingBlock.mergeIn(['data'], metadata);

        blockMap = blockMap.merge({ [blockKey]: updatedBlock });
    });

    return contentState.merge({ blockMap });
};

export const updateMultiBlockMetadata = (editorState, blocks, metadata) => {
    const currentSelection = editorState.getSelection();

    let contentState = editorState.getCurrentContent();
    contentState = updateContentStateMultiBlockMetadata(contentState, blocks, metadata);

    const updatedState = EditorState.push(editorState, contentState, EditorChangeType.CHANGE_BLOCK_DATA);

    return EditorState.forceSelection(updatedState, currentSelection);
};

export const updateSelectionBlockMetadata = (editorState, metadata) => {
    const blockMap = getSelectionBlockMap(editorState);
    return updateMultiBlockMetadata(editorState, blockMap, metadata);
};

/**
 * Updates the metadata for a given key.
 * If the metadata is null the key will be deleted from the metadata map.
 *
 * @param block {Object} The block to update metadata for.
 * @param metadata {Object} The data to store against the block key in the metadata map.  If null the key will be
 *          removed from the map.
 * @param editorState {Function} The function to retrieve the editor state.
 */
export default (editorState, block, metadata) => updateMultiBlockMetadata(editorState, [block], metadata);
