// Lib
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Components
import DrawingEditorCanvasDrawMode from './DrawingEditorCanvasDrawMode';
import DrawingEditorCanvasEraseMode from './DrawingEditorCanvasEraseMode';
import DrawingEditorCanvasSelectMode from './selectMode/DrawingEditorCanvasSelectMode';
import DrawingEditorCanvasReadOnlyMode from './DrawingEditorCanvasReadOnlyMode';

// Constants
import { TOOL_MODES } from '../drawingEditorConstants';

const getCanvasContentComponent = (toolMode, isEditable) => {
    if (!isEditable) return DrawingEditorCanvasReadOnlyMode;

    switch (toolMode) {
        case TOOL_MODES.SELECT:
            return DrawingEditorCanvasSelectMode;
        case TOOL_MODES.ERASE:
            return DrawingEditorCanvasEraseMode;
        default:
            return DrawingEditorCanvasDrawMode;
    }
};

const DrawingEditorCanvas = forwardRef(function DrawingEditorCanvas(props, ref) {
    const { toolMode, isEditable } = props;

    const ContentComponent = getCanvasContentComponent(toolMode, isEditable);

    return (
        <div className="DrawingEditorCanvas" ref={ref}>
            <ContentComponent {...props} />
        </div>
    );
});

DrawingEditorCanvas.propTypes = {
    isEditable: PropTypes.bool,
    toolMode: PropTypes.string,
};

export default DrawingEditorCanvas;
