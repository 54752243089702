import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarCardViewIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-card-view"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#48505E"
                d="M25 9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V11a2 2 0 0 1 2-2h18zm0 1.5H7a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h18a.5.5 0 0 0 .5-.5V11a.5.5 0 0 0-.5-.5zM13.167 13c.46 0 .833.336.833.75v4.5c0 .414-.373.75-.833.75H9.833c-.46 0-.833-.336-.833-.75v-4.5c0-.414.373-.75.833-.75h3.334zm8.333 3.75a.75.75 0 0 1 .102 1.493l-.102.007h-5.222a.75.75 0 0 1-.102-1.493l.102-.007H21.5zm1.066-3.25a.75.75 0 0 1 .102 1.493l-.102.007h-6.288a.75.75 0 0 1-.102-1.493l.102-.007h6.288z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarCardViewIcon);
export default Memo;
