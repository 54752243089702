// Lib
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { cleanEncodedSvgForInnerHtml } from '../../utils/svgUtils';
import { prop } from '../../../common/utils/immutableHelper';
import usePrevious from '../../utils/react/usePrevious';

// Components
import Spinner from '../loaders/Spinner';

// Styles
import './ElementIcon.scss';

const getIconType = prop('type');
const getIconSvg = prop('svg');
const getIconString = prop('string');
const getIconFetching = prop('fetching');

const SVGIcon = ({ svg, className }) => (
    <div className={classNames('Icon SVGIcon', className)} dangerouslySetInnerHTML={cleanEncodedSvgForInnerHtml(svg)} />
);

SVGIcon.propTypes = {
    svg: PropTypes.string,
    className: PropTypes.string,
};

const StringIcon = ({ string, className }) => (
    <div className={classNames('Icon StringIcon', className)}>
        <div className={`string-icon-${string.length}`}>
            {string.split('').map((letter, i) => (
                <span key={i}>{letter}</span>
            ))}
        </div>
    </div>
);

StringIcon.propTypes = {
    string: PropTypes.string,
    className: PropTypes.string,
};

const ElementIcon = ({ icon, showLoading, enableFade }) => {
    const [faded, setFaded] = useState(false);

    const iconFetching = getIconFetching(icon);
    const prevIconFetching = usePrevious(iconFetching);
    const svg = getIconSvg(icon);
    const string = getIconString(icon);

    useEffect(() => {
        if (showLoading && prevIconFetching && !iconFetching) {
            setFaded(true);
            requestAnimationFrame(() => setFaded(false));
        }
    }, [showLoading, prevIconFetching, icon]);

    if (icon && showLoading && iconFetching) return <Spinner show />;
    if (icon && svg) return <SVGIcon className={classNames({ faded: faded && enableFade })} svg={svg} />;
    if (icon && string) {
        return <StringIcon className={classNames({ faded: faded && enableFade })} string={string} />;
    }
    return null;
};

const getStyle = (secondaryColor) => ({
    '--element-icon-color': secondaryColor,
});

const ElementIconBase = (props) => {
    const { icon, isDarkBackground, isSelected, secondaryColor } = props;

    const classes = classNames('ElementIcon', getIconType(icon), {
        'dual-color': !!secondaryColor,
        light: isDarkBackground,
        dark: !isDarkBackground,
        'color-contrast-target': isSelected,
    });

    return (
        <div className={classes} style={getStyle(secondaryColor)}>
            <ElementIcon {...props} />
        </div>
    );
};

ElementIconBase.propTypes = ElementIcon.propTypes = {
    icon: PropTypes.object,
    showLoading: PropTypes.bool,
    enableFade: PropTypes.bool,
    sizePx: PropTypes.number,
    isDarkBackground: PropTypes.bool,
    isSelected: PropTypes.bool,
    color: PropTypes.string,
};

export default ElementIconBase;
