// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shallowEqual from 'shallowequal';
import { get, isEqual } from 'lodash/fp';
import { createStructuredSelector } from 'reselect';

// Components
import Column from './Column';

// Selectors
import { getElementChildIdsSorted } from '../selectors/elementChildrenSelector';
import {
    getChildrenTypeArraySelector,
    makeLocalStoreContainerElementChildrenTypesSelector,
} from '../containerElement/containerElementSelector';
import { elementFilterDataSelector } from '../elementFilter/elementFilterSelector';
import { isAttachModeSelector } from '../../reducers/draggingSelector';

const getHasChildrenMatchingElementFilter = (elementFilterData, childElementIds) => {
    if (!elementFilterData || !Object.keys(elementFilterData).length) return false;

    return childElementIds.some((childElementId) => {
        return get([childElementId, 'pass'], elementFilterData);
    });
};

const makeColumnSelector = () =>
    createStructuredSelector({
        childElementIds: getElementChildIdsSorted(),
        // Columns should always use the local store for their children types, as they're always visible,
        // so we don't need to wait for the persistence thread to calculate the child types
        childrenTypes: makeLocalStoreContainerElementChildrenTypesSelector(),
        elementFilterData: elementFilterDataSelector,
        childrenTypeArray: getChildrenTypeArraySelector(),
        isAttachMode: isAttachModeSelector,
    });

@connect(makeColumnSelector)
class ColumnContainer extends React.Component {
    shouldComponentUpdate(nextProps) {
        // Check for shallow equality on everything except for ancestorIds.
        return !shallowEqual(this.props, nextProps, (thisValue, nextValue, key) => {
            if (key !== 'childElementIds') return;
            return thisValue === nextValue || isEqual(thisValue, nextValue);
        });
    }

    render() {
        const { elementFilterData, childElementIds } = this.props;

        const hasChildrenMatchingElementFilter = getHasChildrenMatchingElementFilter(
            elementFilterData,
            childElementIds,
        );

        return <Column {...this.props} hasChildrenMatchingElementFilter={hasChildrenMatchingElementFilter} />;
    }
}

ColumnContainer.propTypes = {
    element: PropTypes.object.isRequired,
    children: PropTypes.array,
    childElementIds: PropTypes.array,
    elementFilterData: PropTypes.object,
    currentBoardId: PropTypes.string,
    connectDropTarget: PropTypes.func,
    isHovered: PropTypes.bool,
    isPresentational: PropTypes.bool,
};

export default ColumnContainer;
