// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { snakeCase } from 'lodash';

// Components
import Tool from '../../Tool';

const ColorDisplayToolOption = ({ className, name, onPointerDown, isActive, colorDisplay }) => (
    <Tool
        className={classNames(`color-display-tool-option-${snakeCase(colorDisplay)}`, className)}
        name={name}
        onPointerDown={onPointerDown}
        isActive={isActive}
    >
        <div className="color-display-label">{colorDisplay}</div>
    </Tool>
);

ColorDisplayToolOption.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    onPointerDown: PropTypes.func,
    isActive: PropTypes.bool,
    colorDisplay: PropTypes.string,
};

export default ColorDisplayToolOption;
