// Lib
import { isEmpty } from 'lodash';
import { Modifier, EditorState, SelectionState } from 'draft-js';

// Utils
import getLineSelection from '../../codeEditor/utils/getLineSelection';
import getNewLine from '../../codeEditor/utils/getNewLine';
import getLines from '../../codeEditor/utils/getLines';
import getLineOffsets from '../../codeEditor/utils/getLineOffsets';

// Constants
import { DEFAULT_INDENTATION } from '../../codeEditor/utils/getIndentation';
import { DraftRemovalDirection, EditorChangeType } from '../../../draftjsConstants';

/**
 * Finds the lines that start with an indentation.
 */
const getLinesToShift = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // First get all the lines that are selected
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    const newLine = getNewLine(blockText);

    const lines = getLines(blockText, newLine);
    const lineSelection = getLineSelection(blockText, startOffset, endOffset, newLine);

    const linesToShift = [];
    // Then find the index of each line that has an indent at the start
    for (let i = lineSelection.startLine; i <= lineSelection.endLine; i++) {
        if (lines[i] && lines[i].startsWith(DEFAULT_INDENTATION)) {
            linesToShift.push(i);
        }
    }

    return linesToShift;
};

/**
 * Finds all lines with indentation and removes the first indent for each of those lines.
 */
export const removeMultilineIndentation = (editorState) => {
    const linesToShift = getLinesToShift(editorState);

    if (isEmpty(linesToShift)) return;

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    const newLine = getNewLine(blockText);

    const lineOffsets = getLineOffsets(blockText, newLine);

    let newContentState = contentState;

    const firstLineToShift = linesToShift[0];
    const firstLineToShiftOffset = lineOffsets[firstLineToShift];
    const minSelectionStartOffset = firstLineToShiftOffset.startOffset;

    const indentSize = DEFAULT_INDENTATION.length;

    // Then starting at the last line, remove the range of each indent
    // Need to reverse them to make the rangeToRemove calculation simpler
    linesToShift.reverse().forEach((lineIndex) => {
        const currentLineOffset = lineOffsets[lineIndex];

        const rangeToRemove = selection.merge({
            focusKey: startKey,
            focusOffset: currentLineOffset.startOffset,
            anchorKey: startKey,
            anchorOffset: currentLineOffset.startOffset + indentSize,
            isBackward: true,
        });
        newContentState = Modifier.removeRange(newContentState, rangeToRemove, DraftRemovalDirection.backward);
    });

    const newSelection = new SelectionState({
        anchorKey: startKey,
        anchorOffset: Math.max(startOffset - indentSize, minSelectionStartOffset),
        focusKey: startKey,
        focusOffset: endOffset - linesToShift.length * indentSize,
    });

    // Then set the selection to the current selection - the total range deleted
    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);
    return EditorState.forceSelection(newEditorState, newSelection);
};

/**
 * Adds an indent to the start of multiple lines.
 */
export const insertMultilineIndentation = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    const currentBlock = contentState.getBlockForKey(startKey);
    const blockText = currentBlock.getText();

    const newLine = getNewLine(blockText);

    const lineSelection = getLineSelection(blockText, startOffset, endOffset, newLine);
    const lineOffsets = getLineOffsets(blockText, newLine);

    const indentSize = DEFAULT_INDENTATION.length;

    let newContentState = contentState;
    let linesShifted = 0;

    // Loop through backwards to make the insertion logic simpler
    for (let i = lineSelection.endLine; i >= lineSelection.startLine; i--) {
        linesShifted++;
        const currentLineOffset = lineOffsets[i];

        const rangeToInsert = selection.merge({
            focusKey: startKey,
            focusOffset: currentLineOffset.startOffset,
            anchorKey: startKey,
            anchorOffset: currentLineOffset.startOffset,
            isBackward: false,
        });
        newContentState = Modifier.insertText(newContentState, rangeToInsert, DEFAULT_INDENTATION);
    }

    const newSelection = new SelectionState({
        anchorKey: startKey,
        anchorOffset: startOffset + indentSize,
        focusKey: startKey,
        focusOffset: endOffset + linesShifted * indentSize,
    });

    // Then set the selection to the current selection - the total range deleted
    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.REMOVE_RANGE);
    return EditorState.forceSelection(newEditorState, newSelection);
};
