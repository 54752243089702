import { last } from 'lodash/fp';
import { lookupTypeDefinitionsByExtension } from '../files/getFileType';
import { FILE_TYPES_INFO } from '../files/fileConstants';

export const getFileName = (file, contentType) => {
    const { name } = file;

    // detect if file has an extension
    const nameParts = name.split('.');
    const fileExtension = last(nameParts);
    const validExtension = lookupTypeDefinitionsByExtension(fileExtension);
    if (fileExtension && validExtension && validExtension !== FILE_TYPES_INFO.GENERIC) return name;

    // if no extension, use the content type to determine the extension
    const extension = contentType?.split('/')[1];
    // if no content type, use the file name as is
    return extension ? `${name}.${extension}` : name;
};
