import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavHelpIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon nav-help"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1.5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zm-.282 12.746c.504 0 .924.415.924.918a.923.923 0 1 1-1.847 0c0-.503.416-.918.923-.918zm.236-7.83c1.694 0 2.83 1.016 2.83 2.516 0 1.039-.508 1.74-1.334 2.24l-.142.09c-.68.456-.95.936-.966 1.931v.088h-1.32l.004-.325c.054-1.525.553-2.022 1.371-2.523.55-.342.979-.827.979-1.515 0-.817-.637-1.338-1.422-1.338-.706 0-1.404.429-1.459 1.408H9.092c.06-1.662 1.306-2.572 2.862-2.572z"
        />
    </svg>
);
const Memo = memo(NavHelpIcon);
export default Memo;
