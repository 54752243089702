// Types
import { AxiosResponse } from 'axios';

// Constants
import { CUSTOM_HTTP_HEADERS } from '../../../../common/utils/http/httpConstants';
import { getTimestamp } from '../../../../common/utils/timeUtil';

/**
 * Retrieves the custom "data expiry" header from responses, if one is provided,
 * otherwise returns undefined.
 */
export const getDataExpiryHeader = (res: AxiosResponse): number | undefined => {
    const dataExpiryTime = res.headers[CUSTOM_HTTP_HEADERS.DATA_EXPIRY];

    if (!dataExpiryTime) return undefined;

    return parseInt(dataExpiryTime);
};

/**
 * Returns the timestamp that the data will expire, based on the custom "data expiry" header
 * in the response.
 * Or returns undefined if the header doesn't exist.
 */
export const getDataExpiryTimestamp = (res: AxiosResponse): number | undefined => {
    const dataExpiryTime = getDataExpiryHeader(res);

    if (!dataExpiryTime) return undefined;

    return getTimestamp() + dataExpiryTime;
};

/**
 * Returns the timestamp that the data will expire, based on the custom "data expiry" header
 * in the response.
 * Or returns undefined if the header doesn't exist.
 */
export const getDataFetchedTimestamp = (res: AxiosResponse): number | undefined => {
    const dataFetchedTime = res.headers[CUSTOM_HTTP_HEADERS.DATA_FETCHED_TIME];

    if (!dataFetchedTime) return undefined;

    return parseInt(dataFetchedTime);
};

/**
 * Checks if `obj` has all the properties that an `AxiosResponse` requires.
 *
 * NOTE: Any changes made to this check needs to be reflected in the mobile hybrid apps.
 * The hybrid apps networking layer is made to specifically pass this check.
 */
export const isAxiosResponse = (obj: unknown): obj is AxiosResponse =>
    typeof obj === 'object' &&
    obj !== null &&
    'data' in obj &&
    'status' in obj &&
    'statusText' in obj &&
    'headers' in obj &&
    'config' in obj &&
    'request' in obj;
