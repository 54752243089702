// Lib
import { find, includes, flow, get, toLower } from 'lodash/fp';

// Utils
import getFileExtension from './getFileExtension';

// Types
import { FileTypeInfo } from './fileTypes';

// Constants
import { FILE_TYPES_INFO } from './fileConstants';

interface FileType {
    type: string;
    ext: string;
    mime: string;
}

export const lookupTypeDefinitionsByExtension = (extension: string): FileTypeInfo => {
    const matchingType = find(flow(get('extensions'), includes(toLower(extension))), FILE_TYPES_INFO);
    return matchingType || FILE_TYPES_INFO.GENERIC;
};

export const lookupTypeDefinition = (filename: string): FileTypeInfo => {
    const ext = getFileExtension(filename);
    return lookupTypeDefinitionsByExtension(ext);
};

export default (file: File): FileType => {
    const ext = getFileExtension(file.name);
    const typeDefinition = lookupTypeDefinitionsByExtension(ext);

    return {
        type: typeDefinition.type,
        ext,
        mime: file.type,
    };
};
