import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAddColumnIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-add-column"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#1B2536"
                fillOpacity={0.3}
                d="M24 7a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h16zM12.5 19.499h-4V23.5h4v-4.001zm11 0h-4V23.5h4v-4.001zm-5.5 0h-4V23.5h4v-4.001zm-5.5-5.5h-4v4h4v-4zm5.5 0h-4v4h4v-4zm5.5 0h-4v4h4v-4zM12.5 8.5h-4v3.999h4V8.5zm5.5 0h-4v3.999h4V8.5zm5.5 0h-4v3.999h4V8.5z"
            />
            <path
                fill="#48505E"
                fillRule="nonzero"
                d="M24 7a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-6V7h6zm-.5 12.499h-4V23.5h4v-4.001zm0-5.5h-4v4h4v-4zm0-5.499h-4v3.999h4V8.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAddColumnIcon);
export default Memo;
