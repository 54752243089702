// Lib
import { get } from 'lodash/fp';

// Global State
import './linesGlobalState';

// Utils
import * as rectLib from '../../../common/maths/geometry/rect';
import * as pointLib from '../../../common/maths/geometry/point';
import { getEndpointSnappedToAngle, snapIfCloseToRightAngle } from './utils/lineSnapAngleUtil';
import { getEdgeOriginAndRects, getLinePointsOfInterest, isEndEdge } from './utils/lineUtil';
import { getControlPosition, getHalfwayPoint } from './utils/lineControlPointUtil';
import { getIsLineEndSnapped, getIsLineStartSnapped } from '../../../common/elements/utils/elementPropertyUtils';
import { getDefaultDragOrigin } from '../../utils/dnd/dragPositionUtils';

// Selectors
import { getElementPositionFromStateFirstSelector } from '../selectors/elementPositionSelector';
import { isAttachModeSelector, attachModeHoveredElementIdSelector } from '../../reducers/draggingSelector';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getMeasurementsMapFromProps } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';
import { makeGetCanvasElementPositionSelector, getLineControlSelector, makeGetEdgesPx } from './lineSelector';

// Constants
import { LINE_EDGE } from '../../../common/lines/lineConstants';
import { canvasZoomScaleSelector } from '../../canvas/store/canvasZoomSelector';

/**
 * Util function to move the "flexible point" (the edge that's being dragged) from its initial position.
 */
const updateFlexiblePoint = ({
    offsetDiff,
    scrollDiff,
    pos,
    initialClientOffset,
    initialSourceClientOffset,
    shouldSnapToAngle,
    shouldAutomaticallySnap,
    staticPointOrigin,
    gridSize,
    zoomScale,
}) => {
    // We want the edge to sit at the exact center of the mouse, so we need to shift it to that point.
    // We know the grab area is 3rem, so we need to translate by half that amount minus the point that was
    // actually grabbed.
    const handleGrabOffset =
        initialClientOffset && initialSourceClientOffset
            ? getDefaultDragOrigin({ initialSourceClientOffset, initialClientOffset })
            : { x: 0, y: 0 };
    const scaledHandleGrabOffset = pointLib.reverseScale(zoomScale, handleGrabOffset);

    // Shift to the center point of the drag handle
    const handleTranslation = pointLib.difference({ x: 1.5 * gridSize, y: 1.5 * gridSize }, scaledHandleGrabOffset);

    const unscaledOffsetDiff = pointLib.reverseScale(zoomScale, offsetDiff);
    let flexiblePoint = pointLib.translate(unscaledOffsetDiff, pos);
    flexiblePoint = pointLib.translate(flexiblePoint, handleTranslation);
    flexiblePoint = pointLib.translate(flexiblePoint, scrollDiff);

    if (shouldSnapToAngle) {
        flexiblePoint = getEndpointSnappedToAngle({ staticPoint: staticPointOrigin, flexiblePoint });
    } else if (shouldAutomaticallySnap) {
        // Only snap to right angles if we're not dragging a bezier line and it's not already connected or hovered
        flexiblePoint = snapIfCloseToRightAngle({ staticPoint: staticPointOrigin, flexiblePoint, gridSize });
    }

    return flexiblePoint;
};

/**
 * Get the hovered element's rectangle in the line's coordinate system, so it can be used
 * for intersection detection.
 */
const getHoveredElementOriginAndRect = (state, ownProps) => {
    const { hoveredElementId } = ownProps;

    if (!hoveredElementId) return null;

    const measurementsMap = getMeasurementsMapFromProps(state, ownProps);
    const lineElementPosition = getElementPositionFromStateFirstSelector(state, ownProps);
    const gridSize = getGridSize(state, ownProps);
    const connectedElementPosition = makeGetCanvasElementPositionSelector()(state, { elementId: hoveredElementId });

    return getEdgeOriginAndRects({
        // For this path, the only important thing is that the line edge is snapped and has the hovered element ID
        lineEdge: { snapped: true, elementId: hoveredElementId },
        measurementsMap,
        connectedElementPosition,
        lineElementPosition,
        gridSize,
    });
};

/**
 * Determines which edges the line can be snapped to.
 */
const getCanSnapEdges = () => ({
    top: true,
    right: true,
    bottom: true,
    left: true,
});

/**
 * Default snap function for an edge point.
 */
const edgeSnapFn = ({ point, radius }, coordinate) => {
    const distSq = pointLib.getDistanceSquared(point, coordinate);
    if (distSq > radius * radius) return null;

    return {
        snapped: true,
        snappedToGuide: true,
        ...point,
    };
};

/**
 * Creates a list of snap points, depending on which edges the lines can snap to.
 * Each snap point can optionally provide a snapFn that will determine how to match a coordinate
 * and what point to return.
 */
const getSnapPoints = ({ canSnapEdges, rect, gridSize }) => {
    if (!rect) return null;

    const snapPoints = [];

    const radius = gridSize;

    // Snap if within 30% of the edge point
    const visibleRatioHoriz = (30 * rect.width) / 100;
    const visibleRatioVert = (30 * rect.height) / 100;

    // Edges
    if (canSnapEdges.top) {
        const point = rectLib.getTopCenter(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - visibleRatioHoriz / 2,
            y: point.y - 5,
            width: visibleRatioHoriz,
            height: visibleRatioVert / 2 + 5,
        });
        snapPoints.push({ point, radius, visibleRect });
    }
    if (canSnapEdges.right) {
        const point = rectLib.getRightCenter(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - visibleRatioHoriz / 2,
            y: point.y - visibleRatioVert / 2,
            width: visibleRatioHoriz / 2 + 5,
            height: visibleRatioVert,
        });
        snapPoints.push({ point, radius, visibleRect });
    }
    if (canSnapEdges.bottom) {
        const point = rectLib.getBottomCenter(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - visibleRatioHoriz / 2,
            y: point.y - visibleRatioVert / 2,
            width: visibleRatioHoriz,
            height: visibleRatioVert / 2 + 5,
        });
        snapPoints.push({ point, radius, visibleRect });
    }
    if (canSnapEdges.left) {
        const point = rectLib.getLeftCenter(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - 5,
            y: point.y - visibleRatioVert / 2,
            width: visibleRatioHoriz / 2 + 5,
            height: visibleRatioVert,
        });
        snapPoints.push({ point, radius, visibleRect });
    }

    // Corners
    if (canSnapEdges.top || canSnapEdges.left) {
        const point = rectLib.getTopLeft(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - 5,
            y: point.y - 5,
            width: visibleRatioHoriz / 2 + 5,
            height: visibleRatioVert / 2 + 5,
        });
        snapPoints.push({ point, radius, visibleRect });
    }
    if (canSnapEdges.top || canSnapEdges.right) {
        const point = rectLib.getTopRight(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - visibleRatioHoriz / 2,
            y: point.y - 5,
            width: visibleRatioHoriz / 2 + 5,
            height: visibleRatioVert / 2 + 5,
        });
        snapPoints.push({ point, radius, visibleRect });
    }
    if (canSnapEdges.bottom || canSnapEdges.right) {
        const point = rectLib.getBottomRight(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - visibleRatioHoriz / 2,
            y: point.y - visibleRatioVert / 2,
            width: visibleRatioHoriz / 2 + 5,
            height: visibleRatioVert / 2 + 5,
        });
        snapPoints.push({ point, radius, visibleRect });
    }
    if (canSnapEdges.bottom || canSnapEdges.left) {
        const point = rectLib.getBottomLeft(rect);
        const visibleRect = rectLib.asRect({
            x: point.x - 5,
            y: point.y - visibleRatioVert / 2,
            width: visibleRatioHoriz / 2 + 5,
            height: visibleRatioVert / 2 + 5,
        });
        snapPoints.push({ point, radius, visibleRect });
    }

    return snapPoints;
};

/**
 * Snaps to a point if it matches any of the snap points.
 */
const snapToPoints = ({ snapPoints, coordinate }) => {
    if (!snapPoints) return coordinate;

    for (const snapPoint of snapPoints) {
        const snappedPoint = edgeSnapFn(snapPoint, coordinate);
        if (snappedPoint) return snappedPoint;
    }

    return coordinate;
};

/**
 * Snaps to the boundary of an element if the coordinate is close enough to the boundary.
 */
const snapIfCloseToBoundary = ({ rect, coordinate }) => {
    if (!rect) return coordinate;

    if (coordinate.x < rect.left) {
        coordinate.x = rect.left;
    }
    if (coordinate.x > rect.right) {
        coordinate.x = rect.right;
    }
    if (coordinate.y < rect.top) {
        coordinate.y = rect.top;
    }
    if (coordinate.y > rect.bottom) {
        coordinate.y = rect.bottom;
    }

    return coordinate;
};

/**
 * Makes updates to the flexiblePointOrigin and prepares data required when dragging
 * a line edge in the connect inside mode.
 */
const getConnectInsideModeData = (state, ownProps) => {
    const { staticPointOrigin, gridSize, flexiblePointOrigin } = ownProps;

    let updatedFlexiblePointOrigin = flexiblePointOrigin;

    const hoveredElementRect = get('elementRect', getHoveredElementOriginAndRect(state, ownProps));

    // Add a flag so the getLinePointsOfInterest function can determine whether to
    // calculate the intersection with the rectangle or not
    const canSnapEdges = getCanSnapEdges({ origin: staticPointOrigin, rect: hoveredElementRect });

    const snapPoints = getSnapPoints({ canSnapEdges, rect: hoveredElementRect, gridSize });
    updatedFlexiblePointOrigin = snapIfCloseToBoundary({
        rect: hoveredElementRect,
        coordinate: updatedFlexiblePointOrigin,
    });
    updatedFlexiblePointOrigin = snapToPoints({ snapPoints, coordinate: updatedFlexiblePointOrigin });

    updatedFlexiblePointOrigin.fixed = true;

    return {
        flexiblePointOrigin: updatedFlexiblePointOrigin,
        hoveredElementRect,
        snapPoints,
    };
};

/**
 * Makes updates to the flexiblePointOrigin when dragging a line edge in the standard mode.
 */
const getStandardModeData = (state, ownProps) => {
    const { flexiblePointOrigin } = ownProps;

    flexiblePointOrigin.snappedCenter = true;

    return {
        flexiblePointOrigin,
    };
};

/**
 * Updates the start, end and control point positions based on which end of the line is being dragged.
 * This used to be in the LineDragPreview, but it's shared by the QuickLineCreationTool endDrag.
 *
 * NOTE: This will only ever be fired once each render, and will always change, thus a selector is unnecessary.
 */
export const getDraggedLineEdgesPx = (state, ownProps) => {
    const {
        draggedEdge,
        offsetDiff,
        scrollDiff,
        pos,
        shouldSnapToAngle,
        hoveredElementId,
        element,
        initialClientOffset,
        initialSourceClientOffset,
    } = ownProps;

    const isConnectInsideModeStateEnabled = isAttachModeSelector(state);
    const longHoveredId = attachModeHoveredElementIdSelector(state);
    const zoomScale = canvasZoomScaleSelector(state);

    const isConnectInsideMode = isConnectInsideModeStateEnabled && longHoveredId === hoveredElementId;

    const gridSize = getGridSize(state);
    // Get the line points of interest prior to a drag occurring
    // NOTE: This does not use the "aligned" version of the lineEdgeDetails selector because
    //  when you're moving a line edge we show the line connecting to the centre of the element, even
    //  columns, and not to the centre of the title.
    const initialEdgeDetails = makeGetEdgesPx()(state, ownProps);
    const { startConnectionRects, endConnectionRects } = initialEdgeDetails;

    const control = getLineControlSelector(state, ownProps);

    const isDraggingEnd = isEndEdge(draggedEdge);
    const staticEdge = isDraggingEnd ? LINE_EDGE.start : LINE_EDGE.end;
    const staticPointOrigin = initialEdgeDetails[`${staticEdge}EdgeOrigin`];

    const isStaticEdgeConnected = isDraggingEnd ? getIsLineStartSnapped(element) : getIsLineEndSnapped(element);
    const shouldAutomaticallySnap = !isStaticEdgeConnected && !hoveredElementId && !control;

    // Get the current position of the mouse
    let flexiblePointOrigin = updateFlexiblePoint({
        offsetDiff,
        scrollDiff,
        pos,
        initialClientOffset,
        initialSourceClientOffset,
        shouldSnapToAngle,
        shouldAutomaticallySnap,
        staticPointOrigin,
        gridSize,
        zoomScale,
    });

    const modeProps = {
        ...ownProps,
        gridSize,
        staticPointOrigin,
        flexiblePointOrigin,
    };

    const modeData = isConnectInsideMode
        ? getConnectInsideModeData(state, modeProps)
        : getStandardModeData(state, modeProps);

    // eslint-disable-next-line prefer-destructuring
    flexiblePointOrigin = modeData.flexiblePointOrigin;

    const startOrigin = isDraggingEnd ? staticPointOrigin : flexiblePointOrigin;
    const endOrigin = isDraggingEnd ? flexiblePointOrigin : staticPointOrigin;

    if (!startOrigin || !endOrigin) return {};

    // Don't use the hovered rect, so that we don't create rectangle intersections
    const _startConnectionRects = isDraggingEnd ? startConnectionRects : null;
    const _endConnectionRects = !isDraggingEnd ? endConnectionRects : null;

    const controlPoint = getControlPosition({ start: startOrigin, end: endOrigin, control, gridSize });

    const linePois = getLinePointsOfInterest({
        startEdgeOrigin: startOrigin,
        startConnectionRects: _startConnectionRects,
        endEdgeOrigin: endOrigin,
        endConnectionRects: _endConnectionRects,
        controlPoint,
    });

    const originLineMidpoint = getHalfwayPoint(startOrigin, endOrigin);
    if (pointLib.equals(originLineMidpoint, linePois.controlPoint)) {
        // If the control point is equal to the midpoint of the line, then make it the midpoint of the two
        // visible connections
        linePois.controlPoint = getHalfwayPoint(linePois.start, linePois.end);
    }

    // The position to use on drop
    const dropPosition = flexiblePointOrigin.snappedCenter ? flexiblePointOrigin : linePois[draggedEdge];

    return {
        ...modeData,
        ...linePois,
        isConnectInsideMode,
        dropPosition,
    };
};
