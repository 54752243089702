// Lib
import { curry, mapValues, multiply, round } from 'lodash/fp';

// Utils
import { getX, getY } from '../dimensionUtil';
import { asRect, getBottom, getHeight, getLeft, getRight, getTop, getWidth } from './rectPropertyUtils';
import { Rect } from './rectTypes';
import { Point } from '../pointTypes';

// FIXME Allow these to be used with immutable data
// FIXME This isn't quite right!
/**
 * Expands a rectangle by a scalar multiplier.
 */

export const scale = curry((multiplier: number, rect: Rect): Rect => mapValues(multiply(multiplier), rect));
export const reverseScale = curry((multiplier: number, rect: Rect): Rect => mapValues(multiply(1 / multiplier), rect));

export const roundVals = (rect: Rect) => mapValues(round, rect);

/**
 * Shifts a rectangle by the point { x, y }.
 */
export const translate = curry((translationPoint: Point, rect: Rect) =>
    asRect({
        x: getLeft(rect) + getX(translationPoint),
        y: getTop(rect) + getY(translationPoint),
        width: getWidth(rect),
        height: getHeight(rect),
    }),
);

/**
 * Shifts a rectangle by the point { -x, -y }.
 */
export const reverseTranslate = curry((translationPoint: Point, rect: Rect) =>
    asRect({
        x: getLeft(rect) - getX(translationPoint),
        y: getTop(rect) - getY(translationPoint),
        width: getWidth(rect),
        height: getHeight(rect),
    }),
);

/**
 * Moves the rectangle to the point (the top-left will be at the x, y).
 */
export const moveTo = (point: Point, rect: Rect): Rect =>
    asRect({
        x: getX(point),
        y: getY(point),
        width: getWidth(rect),
        height: getHeight(rect),
    });

/**
 * Grows the rect by the padding scalar value.
 */
export const addPadding = curry((xPadding: number, yPadding: number, rect: Rect) =>
    asRect({
        x: getX(rect) - xPadding,
        y: getY(rect) - yPadding,
        width: getWidth(rect) + 2 * xPadding,
        height: getHeight(rect) + 2 * yPadding,
    }),
);

/**
 * Grows the rect by adding margins to each of the sides.
 * Margins is { top, right, bottom, left }.
 */
export const addMargins = curry((margins: Partial<Rect>, rect: Rect) =>
    asRect({
        x: getX(rect) - getLeft(margins),
        y: getY(rect) - getTop(margins),
        width: getWidth(rect) + getLeft(margins) + getRight(margins),
        height: getHeight(rect) + getTop(margins) + getBottom(margins),
    }),
);

/**
 * Given a rectangle, it will be translated to ensure that it sits within the boundaryRect.
 *
 * NOTE: It currently assumes that the input rect can fit inside the boundary rect, and doesn't do any checks
 * to verify this.
 */
export const clampWithinRect = (boundaryRect: Rect, inputRect: Rect): Rect => {
    if (!boundaryRect || !inputRect) return inputRect;

    const boundaryX = getX(boundaryRect);
    const boundaryY = getY(boundaryRect);
    const boundaryWidth = getWidth(boundaryRect);
    const boundaryHeight = getHeight(boundaryRect);

    const inputX = getX(inputRect);
    const inputY = getY(inputRect);
    const inputWidth = getWidth(inputRect);
    const inputHeight = getHeight(inputRect);

    let xDiff = boundaryX > inputX ? boundaryX - inputX : 0;
    xDiff =
        boundaryX + boundaryWidth < inputX + inputWidth
            ? xDiff - boundaryX + boundaryWidth - inputX + inputWidth
            : xDiff;

    let yDiff = boundaryY > inputY ? boundaryY - inputY : 0;
    yDiff =
        boundaryY + boundaryHeight < inputY + inputHeight
            ? yDiff - boundaryY + boundaryHeight - inputY + inputHeight
            : yDiff;

    if (yDiff === 0 && xDiff === 0) return inputRect;

    return translate({ x: xDiff, y: yDiff }, inputRect);
};
