import { ELEMENT_DEFAULT_WIDTH } from '../../elements/elementConstants';
import { IMAGE_MIN_WIDTH_GRID } from '../../images/imageConstants';

export const SKETCH_DISPLAY_NAME = 'Sketch';
export const SKETCH_DISPLAY_NAME_PLURAL = 'Sketches';

export const SKETCH_CANVAS_PIXEL_RATIO = 10;

export const SKETCH_MIN_WIDTH = IMAGE_MIN_WIDTH_GRID;
export const SKETCH_MIN_HEIGHT = IMAGE_MIN_WIDTH_GRID;
export const SKETCH_DEFAULT_WIDTH = ELEMENT_DEFAULT_WIDTH;
// Subtract 1 pixel to stay within the grid
export const SKETCH_DEFAULT_HEIGHT = 20.9;

export const SKETCH_DEFAULT_ASPECT_RATIO = SKETCH_DEFAULT_WIDTH / SKETCH_DEFAULT_HEIGHT;
