export const ALIGNMENT_SIDE = {
    LEFT: 'LEFT',
    CENTER_HORIZ: 'CENTER_HORIZ',
    RIGHT: 'RIGHT',
    TOP: 'TOP',
    CENTER_VERT: 'CENTER_VERT',
    BOTTOM: 'BOTTOM',
};

export const BOARD_ALIGNMENT_OFFSET = 1;
