import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

export default (fieldValue, validationConfig) => {
    if (!fieldValue || !validationConfig.validValues) return;

    if (validationConfig.validValues.indexOf(fieldValue) !== -1) return;

    const validValues = validationConfig.validValues.join(',');

    const errorObject = {
        name: FAILURE_NAMES.select,
        type: FAILURE_TYPES.ERROR,
        debounced: true,
        message: `Not a valid ${validationConfig.name} value [${fieldValue}].  Valid values are [${validValues}]`,
        validValues,
        value: fieldValue,
    };

    return errorObject;
};
