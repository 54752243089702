// Lib
import { createSelector } from 'reselect';

// Utils
import { prop } from '../../../common/utils/immutableHelper';

// Selectors
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getElement, getElementId } from './elementSelector';
import { getCurrentVisibleBoardCanvasOrigin } from './currentBoardSelector';
import { getLocationPosition } from '../../../common/elements/utils/elementPropertyUtils';

export const getElementPositionSelector = (state, ownProps) => getLocationPosition(getElement(state, ownProps));

export const getElementPositionFromStateSelector = (state, ownProps) =>
    state.getIn(['elements', getElementId(state, ownProps), 'location', 'position']);

/**
 * This is used to support the lineSelector's makeGetLineEdgeDetails.
 * We use the element data from the state, if it exists, so it's in-sync with the other element position
 * updates during moves.
 * We need to fallback to the actual element from the props in the case that the element doesn't exist
 * in the state already, such as with the QuickLineCreationTool. It passes in a temporary element
 * structure, just so the appropriate values can be calculated.
 */
export const getElementPositionFromStateFirstSelector = (state, ownProps) =>
    getElementPositionFromStateSelector(state, ownProps) || getElementPositionSelector(state, ownProps);

export const makeGetElementPixelPosition = () =>
    createSelector(
        getElementPositionSelector,
        getGridSize,
        getCurrentVisibleBoardCanvasOrigin,
        (elementGridPosition, gridSize, canvasOrigin) => ({
            x: ((prop('x', elementGridPosition) || 0) - prop('x', canvasOrigin)) * gridSize,
            y: ((prop('y', elementGridPosition) || 0) - prop('y', canvasOrigin)) * gridSize,
        }),
    );
