import getClientConfig from '../utils/getClientConfig';

const recaptchaVerify = (actionName) =>
    new Promise((resolve, reject) => {
        if (!window.grecaptcha) return resolve(null);

        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(getClientConfig().recaptcha.siteKey, { action: actionName })
                .then((token, error) => {
                    if (error) return reject(error);
                    resolve(token);
                });
        });
    });

export default recaptchaVerify;
