import React from 'react';

import './ListDropPreview.scss';

const ListDropPreview = (props) => (
    <div className="ListDropPreview">
        <div className="preview-line" />
    </div>
);

export default ListDropPreview;
