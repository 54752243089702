// Lib
import { draftContentHasMatch, stringMatches, stringMatchesProp, tableContentHasMatch } from './matchUtils';
import elementCommentsContainsSearchQuery from './elementCommentsContainsSearchQuery';
import {
    isBoardLike,
    isCard,
    isClone,
    isColorSwatch,
    isColumn,
    isDocument,
    isFile,
    isImage,
    isLine,
    isLink,
    isSketch,
    isTable,
    isTask,
    isTaskList,
} from '../../../../common/elements/utils/elementTypeUtils';
import {
    getTitle,
    getLinkTitle,
    getShowCaption,
    getCaption,
    getTextContent,
    getLinkUrl,
    getImageKeywords,
    getFilename,
    getLineLabel,
    getIsLineLabelEnabled,
    getTableContentData,
    getElementId,
} from '../../../../common/elements/utils/elementPropertyUtils';

const imageKeywordsHasMatch = (queryRegEx) => (keywords) => {
    if (!keywords) return false;
    const entitiesString = keywords.join();
    return !!entitiesString.match(queryRegEx);
};

const matchesCaption = (queryRegEx) => (element) => {
    const caption = getCaption(element);
    const isShowingCaption = getShowCaption(element);
    const draftContentMatchesQuery = draftContentHasMatch(queryRegEx);
    return isShowingCaption && draftContentMatchesQuery(caption);
};

const matchesTitle = stringMatchesProp(getTitle);
const matchesLinkTitle = stringMatchesProp(getLinkTitle);

const elementContainsSearchQuery =
    ({ queryRegEx, comments }) =>
    (element) => {
        // This allows clones to show the highlighting when there's a match inside them
        // However when the actual content search occurs, it happens against the original element
        if (isClone(element)) return true;

        // Board & Column
        if (isBoardLike(element) || isColumn(element) || isTaskList(element)) return matchesTitle(queryRegEx)(element);

        // Anything with a comment thread attached to it
        if (elementCommentsContainsSearchQuery({ queryRegEx, comments })(element)) return true;

        // Card & Document
        if (isCard(element) || isDocument(element)) {
            const textContent = getTextContent(element);
            return (
                matchesTitle(queryRegEx)(element) ||
                matchesCaption(queryRegEx)(element) ||
                draftContentHasMatch(queryRegEx)(textContent)
            );
        }

        // Image
        if (isImage(element)) {
            const keywords = getImageKeywords(element);
            return matchesCaption(queryRegEx)(element) || imageKeywordsHasMatch(queryRegEx)(keywords);
        }

        // Link
        if (isLink(element)) {
            const linkUrl = getLinkUrl(element);
            return (
                matchesLinkTitle(queryRegEx)(element) ||
                stringMatches(queryRegEx)(linkUrl) ||
                matchesCaption(queryRegEx)(element)
            );
        }

        // File
        if (isFile(element)) {
            const filename = getFilename(element);
            return (
                matchesTitle(queryRegEx)(element) ||
                stringMatches(queryRegEx)(filename) ||
                matchesCaption(queryRegEx)(element)
            );
        }

        // Task
        if (isTask(element)) {
            const textContent = getTextContent(element);
            return draftContentHasMatch(queryRegEx)(textContent);
        }

        // Line
        if (isLine(element)) {
            const lineLabel = getIsLineLabelEnabled(element) ? getLineLabel(element) : null;
            return stringMatches(queryRegEx)(lineLabel);
        }

        // Sketch & Color Swatch
        if (isSketch(element) || isColorSwatch(element)) {
            return matchesCaption(queryRegEx)(element);
        }

        // Table
        if (isTable(element)) {
            const tableData = getTableContentData(element);
            const elementId = getElementId(element);
            return (
                matchesTitle(queryRegEx)(element) ||
                matchesCaption(queryRegEx)(element) ||
                tableContentHasMatch(queryRegEx)(elementId, tableData)
            );
        }

        return false;
    };

export default elementContainsSearchQuery;
