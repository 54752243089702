// Lib
import { useState, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { isEmpty, last } from 'lodash/fp';

// Utils
import { getPathStroke } from './drawingEditorUtils';
import { pointHasPressure } from './perfectFreehandUtils';
import {
    getNewPathRenderedSize,
    getNewPathSmoothing,
    getNewPathStreamline,
    getNewPathThinning,
} from './utils/pathUtils';

const MAX_UNDO_SIZE = 100;

const useDrawingEditorState = (initialPaths = []) => {
    const lastPointRef = useRef([]);

    const [paths, setPathsState] = useState(initialPaths);
    const [currentPath, setCurrentPath] = useState();

    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const setPaths = (newPaths) => {
        if (paths === newPaths) return;

        setUndoStack([...undoStack, paths].slice(-MAX_UNDO_SIZE));
        setRedoStack([]);
        setPathsState(newPaths);
    };

    useEffect(() => {
        setPaths(initialPaths);
    }, [initialPaths]);

    const beginPath = ({ size = 10, color, point, strokeSize, enableDrawingInkEffect }) => {
        const pathId = nanoid(6);
        const points = [point];

        const renderedSize = getNewPathRenderedSize({ size, strokeSize, enableDrawingInkEffect });
        const hasPressure = enableDrawingInkEffect && pointHasPressure(point);
        const thinning = getNewPathThinning({ strokeSize, hasPressure, enableDrawingInkEffect });
        const smoothing = getNewPathSmoothing({ strokeSize, hasPressure, enableDrawingInkEffect });
        const streamline = getNewPathStreamline({ strokeSize, hasPressure, enableDrawingInkEffect });

        const stroke = getPathStroke({
            points,
            size: renderedSize,
            hasPressure,
            thinning,
            smoothing,
            streamline,
            last: false,
        });

        lastPointRef.current = point;

        setCurrentPath({
            id: pathId,
            size: renderedSize,
            color,
            thinning,
            smoothing,
            streamline,
            points,
            stroke,
            hasPressure,
        });
    };

    const endPath = () => {
        if (!currentPath) return;

        lastPointRef.current = [];

        const stroke = getPathStroke({ ...currentPath, last: true });

        const currPath = {
            ...currentPath,
            stroke,
        };

        setCurrentPath(undefined);
        setPaths([...paths, currPath]);
    };

    const cancelPath = () => {
        if (!currentPath) return;

        lastPointRef.current = [];
        setCurrentPath(undefined);
    };

    const appendPointToCurrentPath = (point, color) => {
        if (!currentPath) return;

        if (lastPointRef.current[0] === point[0] && lastPointRef.current[1] === point[1]) return;

        lastPointRef.current = point;

        const hasPressure = pointHasPressure(point) || currentPath.hasPressure;

        const points = [...currentPath.points, point];
        const stroke = getPathStroke({ ...currentPath, points, hasPressure, last: false });

        setCurrentPath({
            ...currentPath,
            color,
            points,
            stroke,
            hasPressure,
        });
    };

    const clearPaths = () => {
        setPathsState([]);
        setUndoStack([]);
        setRedoStack([]);
    };

    const removePaths = (pathIds = []) => {
        const newPaths = paths.filter((path) => !pathIds.includes(path.id));
        setPaths(newPaths);
    };

    const undoPath = () => {
        if (isEmpty(undoStack)) return false;

        setRedoStack([...redoStack, paths]);

        const newPaths = last(undoStack);
        const newUndoStack = undoStack.slice(0, undoStack.length - 1);
        setUndoStack(newUndoStack);
        setPathsState(newPaths);

        return true;
    };

    const redoPath = () => {
        if (isEmpty(redoStack)) return false;

        setUndoStack([...undoStack, paths]);

        const newPaths = last(redoStack);
        const newRedoStack = redoStack.slice(0, redoStack.length - 1);
        setRedoStack(newRedoStack);
        setPathsState(newPaths);

        return true;
    };

    const allPaths = currentPath ? [...paths, currentPath] : paths;

    return {
        paths: allPaths,
        storedPaths: paths,
        currentPath,
        setPaths,
        beginPath,
        endPath,
        cancelPath,
        appendPointToCurrentPath,
        clearPaths,
        removePaths,
        undoPath,
        redoPath,
        canUndo: !isEmpty(undoStack),
        canRedo: !isEmpty(redoStack),
    };
};

export default useDrawingEditorState;
