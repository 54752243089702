import { RawDraftContentState } from 'draft-js';

import { DEFAULT_INDENTATION } from '../plugins/codeEditor/utils/getIndentation';

/**
 * Prepare editor raw content to be used in editor.
 * Replaces all tabs with default indentation of 4 spaces — as tabs are not supported in editor.
 **/
const prepareEditorRawContent = (contentState: RawDraftContentState): RawDraftContentState => {
    const blocks = contentState.blocks.map((block) => {
        // check if block has a tab, if not, return block as is
        if (!block.text.includes('\t')) return block;

        const text = block.text.replace(/\t/g, DEFAULT_INDENTATION);

        // adjust inline style offset and length to account for the change in text length
        const inlineStyleRanges = block.inlineStyleRanges.map((range) => ({
            ...range,
            offset: block.text.slice(0, range.offset).replace(/\t/g, DEFAULT_INDENTATION).length,
            length: block.text.slice(range.offset, range.offset + range.length).replace(/\t/g, DEFAULT_INDENTATION)
                .length,
        }));

        // adjust entity range offset and length to account for the change in text length
        const entityRanges = block.entityRanges.map((range) => ({
            ...range,
            offset: block.text.slice(0, range.offset).replace(/\t/g, DEFAULT_INDENTATION).length,
            length: block.text.slice(range.offset, range.offset + range.length).replace(/\t/g, DEFAULT_INDENTATION)
                .length,
        }));

        return {
            ...block,
            text,
            inlineStyleRanges,
            entityRanges,
        };
    });

    return {
        ...contentState,
        blocks,
    };
};

export default prepareEditorRawContent;
