import React from 'react';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';

import { hasChanged } from '../../utils/react/propsComparisons';

const hasDisableCloseChanged = hasChanged('disableClose');

class ModalKeyboardManager extends React.Component {
    componentWillMount() {
        const { close, disableClose } = this.props;

        if (close && !disableClose) this.bindEscKeyListener();
    }

    componentWillReceiveProps(nextProps) {
        if (!hasDisableCloseChanged(this.props, nextProps)) return;

        if (nextProps.disableClose) {
            this.unbindEscKeyListener();
        } else {
            this.bindEscKeyListener();
        }
    }

    componentWillUnmount() {
        this.unbindEscKeyListener();
    }

    bindEscKeyListener = () => {
        const { close } = this.props;
        if (this.escKeyBound) return;

        Mousetrap.bind('escape', (event) => close());
        this.escKeyBound = true;
    };

    unbindEscKeyListener = () => {
        if (!this.escKeyBound) return;

        Mousetrap.unbind('escape');
        this.escKeyBound = false;
    };

    render() {
        return this.props.children;
    }
}

ModalKeyboardManager.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    close: PropTypes.func,
    disableClose: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ModalKeyboardManager;
