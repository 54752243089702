import {
    MEDIA_PLAYER_PLAY,
    MEDIA_PLAYER_PAUSE,
    MEDIA_PLAYER_SET_PROGRESS,
    MEDIA_PLAYER_SET_VOLUME,
    MEDIA_PLAYER_SET_MUTE,
    MEDIA_PLAYER_SET_DRAG_PREVIEW,
    MEDIA_PLAYER_SET_DURATION,
    MEDIA_PLAYER_ERROR,
    MEDIA_PLAYER_RESET,
} from './mediaPlayerConstants';

export const mediaPlayerPlay = ({ playerId }) => ({
    type: MEDIA_PLAYER_PLAY,
    playerId,
});

export const mediaPlayerPause = ({ playerId }) => ({
    type: MEDIA_PLAYER_PAUSE,
    playerId,
});

export const mediaPlayerSetProgress = ({ playerId, progress }) => ({
    type: MEDIA_PLAYER_SET_PROGRESS,
    playerId,
    progress,
});

export const mediaPlayerSetDuration = ({ playerId, duration }) => ({
    type: MEDIA_PLAYER_SET_DURATION,
    playerId,
    duration,
});

export const mediaPlayerSetVolume = ({ playerId, volume }) => ({
    type: MEDIA_PLAYER_SET_VOLUME,
    playerId,
    volume,
});

export const mediaPlayerSetMute = ({ playerId, mute }) => ({
    type: MEDIA_PLAYER_SET_MUTE,
    playerId,
    mute,
});

export const mediaPlayerSetVideoDragPreview = ({ playerId, dragPreview }) => ({
    type: MEDIA_PLAYER_SET_DRAG_PREVIEW,
    playerId,
    dragPreview,
});

export const mediaPlayerSetError = ({ playerId, error }) => ({
    type: MEDIA_PLAYER_ERROR,
    playerId,
    error,
});

export const mediaPlayerReset = ({ playerId }) => ({
    type: MEDIA_PLAYER_RESET,
    playerId,
});
