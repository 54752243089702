import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLineStyleSolidIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-line-style-solid"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M23.793 6.793a1 1 0 0 1 1.497 1.32l-.083.094-17 17a1 1 0 0 1-1.497-1.32l.083-.094 17-17z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarLineStyleSolidIcon);
export default Memo;
