import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DropdownArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon dropdown-arrow"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M9.53 10.22a.749.749 0 1 0-1.06 1.06l3 3a.749.749 0 0 0 1.06 0l3-3a.749.749 0 1 0-1.06-1.06L12 12.69l-2.47-2.47z"
        />
    </svg>
);
const Memo = memo(DropdownArrowIcon);
export default Memo;
