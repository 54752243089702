// Utils
import { MNElement, MNElementSummary } from '../elementModelTypes';

/**
 * Converts an MNElement to an MNElementSummary.
 */
export const convertMNElementToMNElementSummary = (element: MNElement): MNElementSummary => {
    const elementSummary: MNElementSummary = {
        _id: element._id || element.id || 'unknown',
        elementType: element.elementType,
        location: {
            section: element.location?.section ?? null,
            parentId: element.location?.parentId ?? null,
        },
    };

    if (element.content?.linkTo) {
        elementSummary.content = {
            linkTo: element.content.linkTo,
        };
    }

    return elementSummary;
};

/**
 * Creates a JS Map of element summaries from an array of elements.
 */
export const createMNElementSummaryMap = (elements: MNElement[]): Map<string, MNElementSummary> => {
    const mnElementSummaryMap = new Map<string, MNElementSummary>();

    for (const element of elements) {
        const elementSummary = convertMNElementToMNElementSummary(element);
        mnElementSummaryMap.set(element._id, elementSummary);
    }

    return mnElementSummaryMap;
};
