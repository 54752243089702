// Lib
import { round } from 'lodash';

// Utils
import { DOM_POINTER_TYPES } from '../../../utils/domConstants';

/**
 * PRESSURE:
 *  Firefox
 *  Chrome  * 0 when hovering
 *          * 0.5 when drawing
 *  Safari  * 0 always
 *  iPad    * 0 when using a finger (pointerType = 'touch')
 *          * 0 -> 1 when using Apple Pencil (pointerType = 'pen')
 */
export const getPointerEventPressure = (event: PointerEvent): number =>
    event.pointerType !== DOM_POINTER_TYPES.PEN ? 0 : round(event.pressure, 2);

/**
 * Returns a vector of [x, y, pressure] for the specified pointer event.
 * Note: Pressure will be 0 unless a 'pen' pointerType is used.
 */
export const getPointerEventDetail = (
    event: PointerEvent,
    svgRef: React.MutableRefObject<HTMLElement>,
    canvasScale = 1,
    viewBoxX = 0,
    viewBoxY = 0,
    zoomScale = 1,
): [number, number, number] => {
    const drawingEditorClientRect = svgRef.current?.getBoundingClientRect() || { x: 0, y: 0 };

    // NOTE: View box might need to be calculated before the zoom scale is applied, but we're not sure for now as
    // zoom scale will only apply for annotation editors, which does not have a view box
    return [
        round((event.pageX - drawingEditorClientRect.x) / canvasScale / zoomScale + viewBoxX, 1),
        round((event.pageY - drawingEditorClientRect.y) / canvasScale / zoomScale + viewBoxY, 1),
        getPointerEventPressure(event),
    ];
};
