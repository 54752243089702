// Lib
import { combineReducers } from 'redux-immutable';

import activeModalId from './activeErrorModalIdReducer';
import data from './errorModalDataReducer';

export default combineReducers({
    activeModalId,
    data,
});
