// Constants
import { TIMES } from '../../../../../common/utils/timeUtil';

export const RESOURCES_FETCHING = 'RESOURCES_FETCHING';
export const RESOURCES_FETCHED = 'RESOURCES_FETCHED';
export const RESOURCES_CACHED = 'RESOURCES_CACHED';
export const RESOURCES_FETCHED_FROM_CACHE = 'RESOURCES_FETCHED_FROM_CACHE';
export const RESOURCES_UPDATE_FETCHED_TIME = 'RESOURCES_UPDATE_FETCHED_TIME';
export const RESOURCES_FETCH_ERROR = 'RESOURCES_FETCH_ERROR';
export const RESOURCES_MARKED_AS_STALE = 'RESOURCES_MARKED_AS_STALE';
export const RESOURCES_INVALIDATED = 'RESOURCES_INVALIDATED';

export enum ResourceTypes {
    elements = 'elements',
    boards = 'boards',
    cloneInstances = 'cloneInstances',
    users = 'users',
    boardPermissions = 'boardPermissions',
    boardEditors = 'boardEditors',
    publishedBoardViewers = 'publishedBoardViewers',
    cms = 'cms',
    contacts = 'contacts',
    notifications = 'notifications',
    history = 'history',
    trash = 'trash',
}

// This is used if the resource type doesn't use IDs
export const FALLBACK_ENTITY_ID = 'resourceState';

export enum ResourceStatus {
    NOT_FETCHED = 'NOT_FETCHED',
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
    CACHED = 'CACHED',
    ERRORED = 'ERRORED',
    STALE = 'STALE',
}

export const DEFAULT_ASYNC_RESOURCE_EXPIRY = 30 * TIMES.MINUTE;
