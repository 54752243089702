// Utils
import { hasCommandModifier, hasShiftKey } from '../../utils/keyboard/keyboardUtility';

// Types
import { SelectionMode } from './selectionConstants';

export const isAttemptingMultiSelect = (event) => getEventSelectionMode(event) !== SelectionMode.SINGLE;

/**
 * Determines the selection mode to use based on the kets pressed during an event.
 */
export const getEventSelectionMode = (event) => {
    if (hasShiftKey(event)) return SelectionMode.RANGE;
    if (hasCommandModifier(event)) return SelectionMode.MULTIPLE;
    return SelectionMode.SINGLE;
};
