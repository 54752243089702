import shallowEqual from 'shallowequal';
import { isEqual } from 'lodash';

/**
 * This function is a simple memoization function that compares the RESULT instead of the arguments
 * This is useful for functions where we DO want to recalculate the result each time, but if the result
 * is the same we don't want selectors or components to re-render.
 */
const memoizeResult = <ResultFn extends (...args: any[]) => ReturnType<ResultFn>>(
    resultFn: ResultFn,
    isEqual = shallowEqual,
) => {
    let lastResult: ReturnType<ResultFn>;

    return (...args: unknown[]): ReturnType<ResultFn> => {
        const newResult = resultFn(...args);

        // If either lastResult or newResult is falsy, or if they are not equal
        // then update lastResult and return that value
        if (!newResult || !lastResult || !isEqual(newResult, lastResult)) {
            lastResult = newResult;
        }

        return lastResult;
    };
};

export const memoizeResultShallow = <ResultFn extends (...args: any[]) => ReturnType<ResultFn>>(
    resultFn: ResultFn,
): ((...args: unknown[]) => ReturnType<ResultFn>) => memoizeResult(resultFn, shallowEqual);
export const memoizeResultDeep = <ResultFn extends (...args: any[]) => ReturnType<ResultFn>>(
    resultFn: ResultFn,
): ((...args: unknown[]) => ReturnType<ResultFn>) => memoizeResult(resultFn, isEqual);
