// Lib
import * as Immutable from 'immutable';
import { get, isUndefined, isEmpty } from 'lodash/fp';

// Utils
import * as elementFactory from '../../../common/elements/elementRegistry';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import logger from '../../logger/logger';
import { isLocationSectionChange } from '../../../common/elements/utils/elementMetadataUtil';
import { patchContentWithDiff } from '../../../common/utils/editor/contentDiff/jsonDiffUtil';
import { asObject, prop } from '../../../common/utils/immutableHelper';

// Constants
import * as TYPES from '../../../common/elements/elementConstants';
import { ElementType } from '../../../common/elements/elementTypes';

// Sub reducers
import iconReducer from './elementReducerIcons';

const initialState = Immutable.Map();

const createElement = (state, action) => Immutable.fromJS(elementFactory.createElementObject(action));

const updateElementAcl = (state, action) => state.set('acl', Immutable.Map(action.acl));

const updateMetadata = (state, { timestamp, silent, user }, meta) => {
    const updatedState = state.update('meta', (persistedMeta) => {
        return (persistedMeta || Immutable.Map())
            .set('versionId', meta?.versionId || prop('versionId', persistedMeta))
            .set(
                'locationSectionModifiedTime',
                meta?.locationSectionModifiedTime || prop('locationSectionModifiedTime', persistedMeta),
            );
    });

    const userId = get('_id', user);

    return !silent
        ? updatedState.setIn(['meta', 'modifiedBy'], userId).setIn(['meta', 'modifiedTime'], timestamp)
        : updatedState;
};

const applyChangesToElement = (state, { timestamp, silent, user }, changes, meta) => {
    const updatedState = state.update('content', (content) =>
        content ? content.merge(Immutable.fromJS(changes)) : Immutable.fromJS(changes),
    );

    return updateMetadata(updatedState, { timestamp, silent, user }, meta);
};

const updateElement = (state, action) => {
    const id = getElementId(state);
    const update = action.updates.filter((updateEntry) => updateEntry.id === id)[0];

    if (!update) {
        logger.error('Attempted to update element without an update', id, action.updates);
        return state;
    }

    return applyChangesToElement(state, action, update.changes, update.meta);
};

const applyMoveToElement = (state, action, move) => {
    const { timestamp, user } = action;
    const { location } = move;

    if (!location.parentId || !location.section || !location.position) return state;
    if (location.parentId === getElementId(state)) return state;

    const userId = get('_id', user);

    let updateState = state;

    if (isLocationSectionChange(action, move)) {
        updateState = state.setIn(['meta', 'locationSectionModifiedTime'], timestamp);
    }

    return updateState
        .set('location', Immutable.fromJS(location))
        .setIn(['meta', 'modifiedBy'], userId)
        .setIn(['meta', 'modifiedTime'], timestamp);
};

const multiMoveElement = (state, action) => {
    const id = getElementId(state);
    const move = action.moves.filter((moveEntry) => moveEntry.id === id)[0];

    // If we haven't found a move
    if (!move) {
        logger.error('Attempted to move element without a move', id, action.moves);
        return state;
    }

    return applyMoveToElement(state, action, move);
};

const moveAndUpdateElement = (state, action) =>
    state.withMutations((mutableState) => {
        applyMoveToElement(mutableState, action, action);
        applyChangesToElement(mutableState, action, action.changes, action.meta);
    });

const setElementTypeAndUpdateElement = (state, action) =>
    state.withMutations((mutableState) => {
        mutableState.set('elementType', action.elementType);
        applyChangesToElement(mutableState, action, action.changes, action.meta);
    });

const handleConvertElementToClone = (state, action) =>
    state.set('elementType', ElementType.CLONE_TYPE).set(
        'content',
        Immutable.fromJS({
            linkTo: action.originalElementId,
            clonedElementType: action.clonedElementType,
        }),
    );

const updateElementContentDiff = (state, action) => {
    const { changes } = action;
    const elementTextContent = state.getIn(['content', 'textContent']);

    // If we have a content diff then we need to patch the content
    if (!isEmpty(changes?.textContent) && !isUndefined(elementTextContent)) {
        const currentContent = asObject(elementTextContent);
        const patchedContent = patchContentWithDiff(currentContent, changes.textContent);

        const updatedState = state.updateIn(['content', 'textContent'], (content) => {
            return Immutable.fromJS(patchedContent);
        });

        return updateMetadata(updatedState, action, action.meta);
    }

    return state;
};

const handleMarkElementAsCloned = (state, action) => (state ? state.setIn(['content', 'hasClones'], true) : state);

const handleUndoConvertElementToClone = (state, action) =>
    state
        .set('elementType', action.clonedElementOriginalType)
        .set('content', Immutable.fromJS(action.clonedElementOriginalContent));

/**
 * Element Reducer.
 * Handles actions for individual elements.
 * This is always delegated to by the elementsReducer (it is not part of the reducer hierarchy created using
 * 'combineReducers').
 *
 * @param state {Element Object} The element that needs to respond to the action.
 * @param action {Object} The redux action to respond to.
 *
 * @returns {Object} The new state of the element.
 */
export const element = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.ELEMENT_UPDATE_ACL:
            return updateElementAcl(state, action);
        case TYPES.ELEMENT_CREATE:
            return createElement(state, action);
        case TYPES.ELEMENT_MOVE_MULTI:
            return multiMoveElement(state, action);
        case TYPES.ELEMENT_UPDATE:
            return updateElement(state, action);
        case TYPES.ELEMENT_DIFF_UPDATE:
            return updateElementContentDiff(state, action);
        case TYPES.ELEMENT_SET_TYPE:
            return setElementTypeAndUpdateElement(state, action);
        case TYPES.ELEMENT_MOVE_AND_UPDATE:
            return moveAndUpdateElement(state, action);
        case TYPES.ELEMENT_ICON_FIND:
        case TYPES.ELEMENT_ICON_FIND_SUCCESS:
        case TYPES.ELEMENT_ICON_FIND_FAILURE:
        case TYPES.ELEMENT_ICON_SEARCH:
            return iconReducer(state, action);
        case TYPES.ELEMENT_CONVERT_TO_CLONE:
            return handleConvertElementToClone(state, action);
        case TYPES.ELEMENT_MARK_AS_CLONED:
            return handleMarkElementAsCloned(state, action);
        case TYPES.ELEMENT_UNDO_CONVERT_TO_CLONE:
            return handleUndoConvertElementToClone(state, action);
        // On socket disconnection mark every element as stale so it will be re-fetched
        default:
            return state;
    }
};
