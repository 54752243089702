import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const IconArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={37}
        height={37}
        viewBox="0 0 34 37"
        className="Icon icon-arrow"
        {...props}
    >
        <defs>
            <filter
                id="IconArrowIcon__a"
                width="201.2%"
                height="166.5%"
                x="-50.6%"
                y="-30.5%"
                filterUnits="objectBoundingBox"
            >
                <feMorphology in="SourceAlpha" operator="dilate" radius={1.5} result="shadowSpreadOuter1" />
                <feOffset dy={1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2} />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.3 0"
                />
            </filter>
            <path id="IconArrowIcon__b" d="M1547 102h6v15h8v-15h6l-10-10z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(-1540 -87)">
            <use xlinkHref="#IconArrowIcon__b" fill="#000" filter="url(#IconArrowIcon__a)" />
            <path
                fill="#F4511C"
                stroke="#FFF"
                strokeWidth={1.5}
                d="m1557 90.94 11.81 11.81h-7.06v15h-9.5v-15h-7.06L1557 90.94z"
            />
        </g>
    </svg>
);
const Memo = memo(IconArrowIcon);
export default Memo;
