// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformApple } from '../../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Styles
import './ShortcutKey.scss';

export const convertKeyToEnvironment = (key, platformDetails = platformSingleton) => {
    const str = key.toLowerCase();
    switch (str) {
        case 'ctrl':
            return 'ctrl';
        case '⌘':
        case 'cmd':
        case 'command':
            return isPlatformApple(platformDetails) ? '⌘' : 'ctrl';
        case '⌥':
        case 'alt':
        case 'opt':
        case 'option':
            return isPlatformApple(platformDetails) ? 'option' : 'alt';
        default:
            return key;
    }
};

export const convertKeyToEnvironmentSymbol = (key, platformDetails = platformSingleton) => {
    const str = key.toLowerCase();
    switch (str) {
        case 'ctrl':
            return '⌃';
        case '⌘':
        case 'cmd':
        case 'command':
            return isPlatformApple(platformDetails) ? '⌘' : '⌃';
        case '⌥':
        case 'alt':
        case 'opt':
        case 'option':
            return isPlatformApple(platformDetails) ? '⌥' : 'alt';
        case 'shift':
            return '⇧';
        case 'return':
            return '⏎';
        case 'tab':
            return '⇥';
        default:
            return key;
    }
};

const shiftUp = (key) => {
    switch (key) {
        case '>':
        case '<':
            return true;
        default:
            return false;
    }
};

const ShortcutKey = ({ children, size = 'normal', preferSymbol = false }) => {
    const platformDetails = useSelector(getPlatformDetailsSelector);

    const char = preferSymbol
        ? convertKeyToEnvironmentSymbol(children, platformDetails)
        : convertKeyToEnvironment(children, platformDetails);
    const title = char !== children ? children : null;

    const classes = classNames('ShortcutKey', `shortcut-${size}`, {
        char: char.length === 1,
        'shift-up': shiftUp(children),
    });

    return (
        <span className={classes} title={title}>
            {char}
        </span>
    );
};

ShortcutKey.propTypes = {
    children: PropTypes.string.isRequired,
    size: PropTypes.string,
    preferSymbol: PropTypes.bool,
};

export default ShortcutKey;
