import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const LinkIconIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon link-icon"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M9.134 5.834a.749.749 0 0 1 1.06 0 4.75 4.75 0 0 1 0 6.717L8.31 14.437A4.75 4.75 0 0 1 1.592 7.72a.749.749 0 1 1 1.06 1.06 3.25 3.25 0 1 0 4.597 4.596l1.886-1.885a3.25 3.25 0 0 0 0-4.596.75.75 0 0 1 0-1.061zM7.72 1.59a4.75 4.75 0 1 1 6.717 6.718.749.749 0 1 1-1.06-1.06A3.251 3.251 0 0 0 8.78 2.651L6.895 4.538a3.248 3.248 0 0 0 0 4.596.75.75 0 0 1-1.061 1.06 4.75 4.75 0 0 1 0-6.717z"
        />
    </svg>
);
const Memo = memo(LinkIconIcon);
export default Memo;
