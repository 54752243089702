// Types
import { Matrix3D } from 'rematrix';
import { Point } from '../../../common/maths/geometry/pointTypes';
import { Rect, RectSize } from '../../../common/maths/geometry/rect/rectTypes';

// Utils
import { isDebugEnabled } from '../../debug/debugUtil';
import { getMinScale } from './zoomScaleUtils';
import { roundToPixel } from '../../utils/maths/clientPoint';
import * as matrixLib from '../../../common/maths/matrix/matrixUtils';
import * as pointLib from '../../../common/maths/geometry/point';
import * as rectLib from '../../../common/maths/geometry/rect';

/**
 * Determines the padding at the minimum scale for a given canvas.
 * This will be 0px at minimum.
 */
const getMinScalePaddingDimension = (
    canvasViewportDimension: number,
    contentRectDimension: number,
    minScale: number,
): number => Math.max((canvasViewportDimension - contentRectDimension * minScale) / 2, 0);

/**
 * Gets the padding at the minimum scale for a given viewport and document size.
 * This ensures that a dimension will have no less than 0px of padding at the minimum scale.
 */
export const getMinScalePadding = (canvasViewportSize: RectSize, contentRect: RectSize, minScale: number): Point => ({
    x: getMinScalePaddingDimension(canvasViewportSize.width, contentRect.width, minScale),
    y: getMinScalePaddingDimension(canvasViewportSize.height, contentRect.height, minScale),
});

/**
 * Linearly scale the padding from 0 at scale 1 to the target padding at the minimum scale.
 * This is the formula for a linear equation (y = mx + c)
 *
 * See: https://www.desmos.com/calculator/qlapqwyewj
 */
const getPaddingAtScaleDimension = (padding: number, minScale: number, currentScale: number): number =>
    Math.max((-padding / (1 - minScale)) * currentScale + padding / (1 - minScale), 0);

/**
 * Determines the padding that's expected to be used at a particular scale given the specified canvas viewport rect
 * and content rect.
 */
const getExpectedPaddingAtScale = (
    canvasViewportClientRect: DOMRect,
    contentRect: Rect,
    currentScale: number,
): Point => {
    // If we're not zoomed out the padding should always be 0
    if (currentScale >= 1) {
        return {
            x: 0,
            y: 0,
        };
    }

    const minScale = getMinScale(canvasViewportClientRect, contentRect);
    const minScalePadding = getMinScalePadding(canvasViewportClientRect, contentRect, minScale);

    return {
        x: getPaddingAtScaleDimension(minScalePadding.x, minScale, currentScale),
        y: getPaddingAtScaleDimension(minScalePadding.y, minScale, currentScale),
    };
};

/**
 * Calculates the scrollable area size for a given content rect and transformation.
 */
export const getScrollableAreaSizeFromContentRect = (
    contentRect: RectSize,
    scale: number,
    translation: Point,
): RectSize => ({
    width: contentRect.width * scale + 2 * translation.x,
    height: contentRect.height * scale + 2 * translation.y,
});

/**
 * Determines the new padding to use at the given scale.
 */
const getNewTranslation = (
    transformedContentRect: Rect,
    expectedPadding: Point,
    currentScale: number,
    targetRect: Rect,
): Point => {
    // If the canvas is zoomed to 100% then there should never be any translation shown
    // If it's more than 100% then translation might be shown on small boards when using zoom to fit
    if (currentScale === 1) {
        return {
            x: 0,
            y: 0,
        };
    }

    // Otherwise we want to use the maximum out of the expected padding (minus the intrinsic padding in the content)
    // at the current scale and the currently showing padding of the content rect
    return {
        x: Math.max(transformedContentRect.left, expectedPadding.x - targetRect.left, 0),
        y: Math.max(transformedContentRect.top, expectedPadding.y - targetRect.top, 0),
    };
};

/**
 * We should shrink the scroll area dimension to the viewport if it's not already equal and the scroll area
 * is only 1 or 2px larger than the viewport.
 * This prevents annoying useless scrollbars due to rounding up issues.
 */
const shouldShrinkScrollAreaDimensionToViewport = (scrollAreaDimension: number, canvasViewportDimension: number) =>
    canvasViewportDimension !== scrollAreaDimension && canvasViewportDimension + 2 >= scrollAreaDimension;

interface ScrollableAreaSizeReturn {
    newTranslation: Point;
    newCanvasScroll: Point;
    translationChange: Point;
    newScrollAreaSize: RectSize;
}

/**
 * Determines the scrollable area size based on the current scale,
 * assuming that equal padding exists on each horizontal side
 * and each vertical side.
 */
const getNewScrollableAreaSize = (
    canvasViewportClientRect: DOMRect,
    canvasDocumentSize: RectSize,
    contentRect: Rect,
    canvasScroll: Point = { x: 0, y: 0 },
    currentScale: number,
    currentTranslation: Point,
): ScrollableAreaSizeReturn => {
    // If we're zoomed out, use the content rectangle as the target, otherwise use the document
    const targetRect = currentScale < 1 ? contentRect : rectLib.asRect(canvasDocumentSize);

    // In order to determine the new translation of the document we need to determine the current translation
    // within the viewport. If it's more than the expected translation then we should use the existing translation
    // to ensure the document doesn't jump around when resetting the scroll and translation
    // So first we find the translation for the content within the viewport's frame of reference
    const contentTranslation = roundToPixel(pointLib.difference(canvasScroll, currentTranslation));
    const transformedContentRect = rectLib.moveTo(contentTranslation, rectLib.scale(currentScale, contentRect));

    // The expected padding is the padding / translation that we should show if it was purely based on the
    // ratio between the min scale padding and the current scale
    const expectedPadding = getExpectedPaddingAtScale(canvasViewportClientRect, contentRect, currentScale);

    // The new translation will be the maximum out of the expected padding and the padding that's currently visible
    const newTranslation = getNewTranslation(transformedContentRect, expectedPadding, currentScale, targetRect);

    // The scroll area size will be the size of the content plus the translation used as padding around the content
    const scrollAreaSize = getScrollableAreaSizeFromContentRect(targetRect, currentScale, newTranslation);

    // The translation change will determine the change to the canvas scroll that needs to be performed
    const translationChange = {
        x: newTranslation.x - currentTranslation.x || 0,
        y: newTranslation.y - currentTranslation.y || 0,
    };

    // New scroll = current scroll + translation change (Min 0);
    const newCanvasScroll = roundToPixel({
        x: Math.max(canvasScroll.x + translationChange.x, 0) || 0,
        y: Math.max(canvasScroll.y + translationChange.y, 0) || 0,
    });

    // NOTE: The new scroll area size must at least be as large as the new scroll + canvas viewport width
    //  to ensure that if we're zooming into an area that should no longer be in the scrollable area
    //  (because elements were moved, for example) then we don't get a render jump as the scrollable area
    //  resets in size
    const newScrollAreaSize = {
        width: Math.max(canvasViewportClientRect.width + newCanvasScroll.x, scrollAreaSize.width),
        height: Math.max(canvasViewportClientRect.height + newCanvasScroll.y, scrollAreaSize.height),
    };

    // If we end up in a situation when the scroll area is only slightly larger than the viewport size,
    // then just make the scroll area the same size as the viewport to avoid an annoying scrollbar that
    // only scrolls 1-2px
    if (shouldShrinkScrollAreaDimensionToViewport(newScrollAreaSize.width, canvasViewportClientRect.width)) {
        newScrollAreaSize.width = canvasViewportClientRect.width;
        translationChange.x -= newCanvasScroll.x;
    }
    if (shouldShrinkScrollAreaDimensionToViewport(newScrollAreaSize.height, canvasViewportClientRect.height)) {
        newScrollAreaSize.height = canvasViewportClientRect.height;
        translationChange.y -= newCanvasScroll.y;
    }

    if (isDebugEnabled()) {
        console.groupCollapsed('%c -- DEBUG ZOOM: getNewScrollableAreaSize', 'color: #48c2ff');
        console.info('%c arguments:', 'color: blue', {
            canvasViewportClientRect,
            canvasDocumentSize,
            contentRect,
            canvasScroll,
            currentScale,
            currentTranslation,
        });

        console.info('%c reset results:', 'color: green', {
            targetRect,
            contentTranslation,
            transformedContentRect,
            expectedPadding,
            newTranslation,
            scrollAreaSize,
            translationChange,
            newCanvasScroll,
            newScrollAreaSize,
        });
        console.groupEnd();
    }

    return {
        newTranslation,
        newCanvasScroll,
        translationChange,
        newScrollAreaSize,
    };
};

interface ResetCanvasPropertiesResponse {
    transformationPoint: Point;
    transformationMatrix: Matrix3D;
    scrollAreaSize: RectSize;
    canvasScroll: Point;
}

/**
 * Resets the canvas viewport, translation and scrollable area size to match the current scale's requirements.
 */
export const calculateResetCanvasProperties = (
    transformationPoint: Point,
    transformationMatrix: Matrix3D,
    canvasViewportClientRect: DOMRect,
    canvasDocumentSize: RectSize,
    contentRect: Rect,
    canvasScroll: Point,
): ResetCanvasPropertiesResponse => {
    const currentScale = matrixLib.getScale(transformationMatrix);
    const currentTranslation = matrixLib.getTranslation(transformationMatrix);

    // Find the new size based on the current scale
    const { newScrollAreaSize, newTranslation, translationChange, newCanvasScroll } = getNewScrollableAreaSize(
        canvasViewportClientRect,
        canvasDocumentSize,
        contentRect,
        canvasScroll,
        currentScale,
        currentTranslation,
    );

    // Update the translation on the transformation matrix
    const newTransformationMatrix = matrixLib.setTranslation(transformationMatrix, newTranslation);

    // Shift the transformation point based on the change to the matrix translation
    const newTransformationPoint = {
        x: Math.round((transformationPoint?.x || 0) + (translationChange?.x || 0)),
        y: Math.round((transformationPoint?.y || 0) + (translationChange?.y || 0)),
    };

    return {
        transformationPoint: newTransformationPoint,
        transformationMatrix: newTransformationMatrix,
        canvasScroll: newCanvasScroll,
        scrollAreaSize: newScrollAreaSize,
    };
};
