import Victor from 'victor';

/**
 * Simply extending Victor.
 * This will clobber Victor methods, so potentially dangerous if Victor updates.
 */
const Vector = Victor;

Vector.lineVector = function lineVector(start, end) {
    const endVector = end instanceof Victor ? end.clone() : Victor.fromObject(end);

    return endVector.subtract(start);
};

/**
 * Creates a vector of the same magnitude as this vector but perpendicular to it.
 */
Vector.prototype.perpendicular = function perpendicular() {
    const x = -this.y;
    this.y = this.x;
    this.x = x;
    return this;
};

/**
 * Creates a vector of the same magnitude as this vector but is the right perpendicular to it.
 */
Vector.prototype.perpendicularRight = function perpendicularRight() {
    const x = this.y;
    this.y = -this.x;
    this.x = x;
    return this;
};

/**
 * Makes the vector have a specific length, but remain in the same direction.
 */
Vector.prototype.toLength = function toLength(length) {
    return this.normalize().multiplyScalar(length);
};

/**
 * Ensures the vector's length is not greater than a specific amount.
 */
Vector.prototype.clampLength = function clampLength(length) {
    // Length squared is more performant because .length() uses a square root
    if (this.lengthSq() < length * length) return this;
    return this.normalize().multiplyScalar(length);
};

/**
 * Returns the magnitude of the projection of the "vector to project" onto this vector.
 * I.e. This is the "adjacent" in the right angle triangle between this vector and other vector.
 */
Vector.prototype.projectedMagnitude = function projectedMagnitude(vectorToProject) {
    return this.dot(vectorToProject) / this.length();
};

/**
 * Returns the ratio of the projection of the "vector to project" onto this vector.
 * I.e. This is the "adjacent" in the right angle triangle between this vector and other vector divided by
 * the length of this vector.
 */
Vector.prototype.projectedRatio = function projectedRatio(vectorToProject) {
    return this.dot(vectorToProject) / this.lengthSq();
};

Vector.prototype.toFixed = function toFixed(precision) {
    const _precision = precision || 8;
    this.x = Number(this.x.toFixed(_precision));
    this.y = Number(this.y.toFixed(_precision));
    return this;
};

export default Vector;
