// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getLineLabel, getIsLineLabelEnabled } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import controlPointDragSource from '../controlPointDragSource';
import LineLabel from './LineLabel';

let ConnectedLineLabel = (props) => {
    const onDoubleClick = React.useCallback((event) => {
        props.updateLineControlPoint({ control: null });

        event.stopPropagation();
        event.preventDefault();
    }, []);

    return <LineLabel onDoubleClick={onDoubleClick} {...props} />;
};

ConnectedLineLabel.propTypes = {
    updateLineControlPoint: PropTypes.func,
};

ConnectedLineLabel = controlPointDragSource(ConnectedLineLabel);

const LineLabelContainer = (props) => {
    const { isPresentational, isRemotelySelected, isEditable, isEditing, element } = props;

    const lineLabel = getLineLabel(element);
    const showLineLabel = (getIsLineLabelEnabled(element) && !!lineLabel) || isEditing;

    if (!showLineLabel) return null;

    const connectControlPointDragSource =
        !isPresentational &&
        !isRemotelySelected &&
        isEditable &&
        // Need to add this for Safari.
        // For some reason it won't edit correctly when it's got the DnD drag source
        !isEditing;

    return connectControlPointDragSource ? <ConnectedLineLabel {...props} /> : <LineLabel {...props} />;
};

LineLabelContainer.propTypes = {
    isPresentational: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,

    element: PropTypes.object,
};

export default LineLabelContainer;
