// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { prop } from '../../../../common/utils/immutableHelper';

// Components
import Icon from '../../../components/icons/Icon';
import LinkButton from '../../../components/buttons/LinkButton';

// Actions
import { clearElementAttachmentError } from '../../attachments/attachmentActions';

import './FileUploaderErrorContent.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchClearError: (id) => dispatch(clearElementAttachmentError({ id })),
});

class FileUploaderErrorContent extends React.Component {
    clearError = () => {
        const { elementId, dispatchClearError } = this.props;
        dispatchClearError(elementId);
    };

    render() {
        const { error, type } = this.props;

        return (
            <div className="FileUploaderErrorContent">
                <Icon name="error-alert" />
                <span className="details">
                    {prop('message', error) || `Unable to upload ${type}`}
                    <br />
                    {prop('explanation', error) || 'Sorry, something went wrong'}
                </span>
                <LinkButton className="cta" onClickFn={this.clearError}>
                    Try another {type}
                </LinkButton>
            </div>
        );
    }
}

FileUploaderErrorContent.propTypes = {
    error: PropTypes.object,
    dispatchClearError: PropTypes.func,
    elementId: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default connect(null, mapDispatchToProps)(FileUploaderErrorContent);
