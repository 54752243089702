// Lib
import { useLayoutEffect } from 'react';

// Utils
import * as typingBuffer from '../utils/typingBufferSingleton';

// Types
import { Editor } from '@tiptap/react';

/**
 * Sometimes the editor is not ready to receive text when the user is typing (e.g. when a caption is hidden
 * but an image is selected).
 * In these cases, we use the typingBuffer to store the text that the user is typing, then when the editor
 * becomes editable, we retrieve the text from the typingBuffer and insert it into the editor.
 */
export const useAddTypingBufferTextOnEdit = (editor: Editor | null, isEditing: boolean) => {
    useLayoutEffect(() => {
        if (!isEditing) return;

        const chars = typingBuffer.getChars();

        if (!chars) return;

        typingBuffer.clear();
        editor?.chain().focus('end').insertContent(chars).run();
    }, [isEditing]);
};

export default useAddTypingBufferTextOnEdit;
