import { Experiment } from './experimentTypes';

// experiment ids — these are used for convenience in code when testing for experiment matches
export enum ExperimentId {
    disableInviteLimit = 'disableInviteLimit',
    disableAutoShiftLimit = 'disableAutoShiftLimit',
    bypassRecaptchaChecks = 'bypassRecaptchaChecks',
    publicTemplateCreation = 'publicTemplateCreation',
    renderSecureImagesThroughProxy = 'renderSecureImagesThroughProxy',
    renderSecureImagesThroughCF = 'renderSecureImagesThroughCF',
    templateOnboardingV2 = 'templateOnboardingV2',
    documentDiffSyncing = 'documentDiffSyncing',
    aiAssistant = 'aiAssistant',
    graphDb = 'graphDb',
    graphDbAppInit = 'graphDbAppInit',
    // This will be removed once we have a better solution (remember to archive in the DB!)
    disableQQInvites = 'disableQQInvites',
    // Search
    boardSearch = 'boardSearch',
    // Custom templates weren't originally migrated across to neo4j, so we migrate them lazily as well
    customTemplatesGraphMigration = 'customTemplatesGraphMigration',
    clientLocalCache = 'clientLocalCache',
    // Android hybrid board list
    androidHybridBoardList = 'androidHybridBoardList',
    // Until released, this will activate Canvas on iPhone for non-staff members.
    // Staff members are able to activate this feature locally using local feature toggles.
    iosCanvas = 'iosCanvas',

    // Migration flags
    // NOTE: These are not real experiments, they are just used to track the progress of migrations.
    //   They are added to the user's flags object when their data has been migrated.
    //   This allows the server to check if the user supports graph DB syncing by looking at
    //   the user alone, without performing mongo queries in potentially hot areas of the code.
    graphDbSynced = 'graphDbSynced',
    boardSearchIndexComplete = 'boardSearchIndexComplete',

    checkoutV2TaxCollection = 'checkoutV2TaxCollection',
    clientPersistence = 'clientPersistence',

    // New user workspaces
    userWorkspaces = 'userWorkspaces',
    // this is not a real experiment, it's just a flag to indicate that the user's shared boards have been moved to the shared workspace
    userSharedBoardsMigrated = 'userSharedBoardsMigrated',

    // New mobile project
    mobileV2 = 'mobileV2',

    // Tiptap editor
    tiptapConversion = 'tiptapConversion',
}

// These experiments will be created in the database if they don't exist
// so that experiments ids can be easily synced between environments.
export const DEFAULT_EXPERIMENTS: Partial<Experiment>[] = [
    {
        _id: ExperimentId.disableInviteLimit,
        title: 'User flag: disable invite limit',
    },
    {
        _id: ExperimentId.disableAutoShiftLimit,
        title: 'User flag: disable auto shift limit',
    },
    {
        _id: ExperimentId.bypassRecaptchaChecks,
        title: 'User flag: disable auto shift limit',
    },
    {
        _id: ExperimentId.publicTemplateCreation,
        title: 'User flag: allow public template creation',
        staffEnabled: true,
    },
    {
        _id: ExperimentId.renderSecureImagesThroughProxy,
        title: 'Image security: render via proxy',
    },
    {
        _id: ExperimentId.renderSecureImagesThroughCF,
        title: 'Image security: render via CloudFront',
    },
    {
        _id: ExperimentId.templateOnboardingV2,
        title: 'Testing: New template onboarding',
    },
    {
        _id: ExperimentId.aiAssistant,
        title: 'Testing: AI Assistant popup',
    },
    {
        _id: ExperimentId.graphDb,
        title: 'Use graph DB for traversals',
    },
    {
        _id: ExperimentId.graphDbAppInit,
        title: 'Use graph DB for app init',
    },
    {
        _id: ExperimentId.disableQQInvites,
        title: 'User flag: Disable QQ invites',
    },
    {
        _id: ExperimentId.documentDiffSyncing,
        title: 'Document content diff syncing',
    },
    {
        _id: ExperimentId.boardSearch,
        title: 'Use board search',
    },
    {
        _id: ExperimentId.clientLocalCache,
        title: 'Use browser cache for instant app loading',
    },
    {
        _id: ExperimentId.androidHybridBoardList,
        title: 'Android: Use web view to display the board list',
    },
    {
        _id: ExperimentId.iosCanvas,
        title: 'iPhone: Use Canvas view on the "Your Boards" tab',
    },
    {
        _id: ExperimentId.clientPersistence,
        title: 'Use IndexedDB to cache elements',
    },
    {
        _id: ExperimentId.mobileV2,
        title: 'Use the new mobile V2 structure',
    },
    {
        _id: ExperimentId.checkoutV2TaxCollection,
        title: 'Collect tax in the new checkout flow',
    },
    {
        _id: ExperimentId.tiptapConversion,
        title: 'Render Draft cards with preview Tiptap content',
    },
    {
        _id: ExperimentId.userWorkspaces,
        title: 'New user workspaces',
    },
];

// These are the fields from the user model that will be taken into account when
// determining if a user is matched to an experiment.
export const USER_MODEL_EXPERIMENT_PROJECTION = {
    flags: 1,
    role: 1,
};
