// Utils
import hasSelectionInCodeBlock from './utils/hasSelectionInCodeBlock';
import removeIndent from './utils/removeIndent';

// Constants
import { DRAFT_COMMANDS, HANDLER_RETURN_VALUES } from '../../draftjsConstants';

const handleKeyCommand = (editorState, command) => {
    if (command === DRAFT_COMMANDS.BACKSPACE) return removeIndent(editorState);
};

export default (command, editorState, eventTimeStamp, { setEditorState }) => {
    if (!hasSelectionInCodeBlock(editorState)) return HANDLER_RETURN_VALUES.notHandled;

    const newState = handleKeyCommand(editorState, command);

    if (newState) {
        setEditorState(newState);
        return HANDLER_RETURN_VALUES.handled;
    }

    return HANDLER_RETURN_VALUES.notHandled;
};
