import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const UtilZoomToActualIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon util-zoom-to-actual"
        {...props}
    >
        <path fill="#666D7A" fillRule="nonzero" d="M21 3v6h-1V4.706L4.706 20H9v1H3v-6h1v4.292L19.292 4H15V3h6Z" />
    </svg>
);
const Memo = memo(UtilZoomToActualIcon);
export default Memo;
