// Lib
import { isEmpty } from '../../../../../../../common/utils/immutableHelper';
import getAllSelectionInlineStyles from '../../../../../../components/editor/customRichUtils/selection/getAllSelectionInlineStyles';

// Selectors
import { getEditorState } from '../../../../../../components/editor/store/reducers/editorStoreSelector';

// Actions
import { editorColorStyleChange } from '../../../../../../components/editor/store/editorActions';

// Constants
import {
    BACKGROUND_COLOR_PREFIX,
    TEXT_COLOR_PREFIX,
    CUSTOM_SELECTION_STYLE,
} from '../../../../../../components/editor/plugins/textColor/textColorConstants';
import { STYLE_COMMANDS } from '../../../../../../components/editor/richText/richTextConstants';

export const setCustomColorThunk =
    ({ colorStyle, addToUndoStack, showPseudoSelection }) =>
    (dispatch, getState) => {
        const editorStoreState = getState();
        const editorState = getEditorState(editorStoreState);

        const selectedStyles = getAllSelectionInlineStyles(editorState);

        const addedStyles = colorStyle ? [colorStyle] : [];
        const removedStyles = selectedStyles
            .filter(
                (style) =>
                    style !== colorStyle &&
                    (style.startsWith(TEXT_COLOR_PREFIX) ||
                        style.startsWith(BACKGROUND_COLOR_PREFIX) ||
                        style === STYLE_COMMANDS.HIGHLIGHT ||
                        (style === CUSTOM_SELECTION_STYLE && !showPseudoSelection)),
            )
            .toArray();

        if (isEmpty(addedStyles) && isEmpty(removedStyles)) return;

        if (showPseudoSelection) addedStyles.push(CUSTOM_SELECTION_STYLE);

        const styleChangeAction = editorColorStyleChange({ addedStyles, removedStyles, addToUndoStack });
        dispatch(styleChangeAction);
    };
