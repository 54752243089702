// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './CardOverflowOverlay.scss';

const CardOverflowOverlay = ({ show }) => <div className={classNames('CardOverflowOverlay', { show })} />;

CardOverflowOverlay.propTypes = {
    show: PropTypes.bool,
};

export default CardOverflowOverlay;
