// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';

// Components
import Button from '../../../components/buttons/Button';

// Styles
import './CommentPostButton.scss';

class CommentPostButton extends React.Component {
    onSubmit = (event) => {
        const { editorState, onSubmit } = this.props;

        const contentState = editorState.getCurrentContent();

        if (!contentState || !contentState.hasText()) return;

        const text = convertToRaw(contentState);
        onSubmit(text);
    };

    render() {
        const { isUpdating, editorState, buttonText = 'Send' } = this.props;

        if (!isUpdating) return null;

        const contentState = editorState.getCurrentContent();
        const disabled = !contentState || !contentState.hasText();

        return (
            <Button className="CommentPostButton" onMouseDown={this.onSubmit} disabled={disabled}>
                {buttonText}
            </Button>
        );
    }
}

CommentPostButton.propTypes = {
    onSubmit: PropTypes.func,
    isUpdating: PropTypes.bool,
    editorState: PropTypes.object,
    buttonText: PropTypes.string,
};

export default CommentPostButton;
