// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EditorBlock } from 'draft-js';

// Components
import Checkbox from './Checkbox';

// Utils
import platformSingleton from '../../../../../platform/platformSingleton';
import { stopPropagationOnly } from '../../../dependencies';

// Constants
import { BrowserEngineType } from '../../../../../platform/platformTypes';

// Styles
import './ChecklistEditorBlock.scss';

/**
 * Renders a checklist block level item to the list.
 *  _
 * | | Some text goes here.
 *  —
 */
export default class ChecklistEditorBlock extends React.Component {
    /**
     * Toggles the internal state of 'checked' as well as updating the editor's metadata for this block.
     * If the block becomes unchecked we delete the entry from the metadata map to prevent it from growing too large.
     */
    toggleChecked = (event) => {
        const { blockProps, block } = this.props;
        const { updateMetadataFn, returnFocusToEditor, checked } = blockProps;

        if (!blockProps.isEditable) return;

        event.stopPropagation();

        const newChecked = !checked;
        updateMetadataFn(block, { checked: newChecked });

        returnFocusToEditor();
    };

    render() {
        const { offsetKey, blockProps } = this.props;
        const { checked, isEditable } = blockProps;

        const blockClassNames = classNames('ChecklistEditorBlock', { checked, editable: isEditable });

        const currentBrowserIsFirefox = platformSingleton.browserEngine === BrowserEngineType.firefox;

        // Improvement for: https://github.com/Milanote/milanote/issues/2007
        // There is a strange Firefox bug when a contenteditable="false" node is within a contenteditable="true"
        // node that seems to cause the caret to get messed up when moving around.  Using an extra span
        // improves the behaviour, it's not perfect, but it's better than completely jumping to the start.
        // Might be worth creating a bare-bones test case at some stage for this scenario
        return (
            <div className={blockClassNames} data-offset-key={offsetKey}>
                {currentBrowserIsFirefox && <span />}
                <Checkbox checked={checked} onMouseDown={this.toggleChecked} onClick={stopPropagationOnly} />
                <div className="text">
                    <EditorBlock {...this.props} />
                </div>
            </div>
        );
    }
}

ChecklistEditorBlock.propTypes = {
    block: PropTypes.object.isRequired,
    blockProps: PropTypes.object.isRequired,
    offsetKey: PropTypes.string.isRequired,
};
