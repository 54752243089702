// Lib
import { useCallback, useEffect, useState } from 'react';
import { clamp } from 'lodash/fp';
import usePrevious from '../../utils/react/usePrevious';

// This has the duplicated functionality of ListFocusManager, all new components should use this hook
const useListFocusManager = (
    loop = false,
    initialFocusIndex: number,
    listSize: number,
): {
    focusedIndex: number;
    setFocusIndex: (newIndex: number) => void;
    moveListFocusUp: () => void;
    moveListFocusDown: () => void;
} => {
    const [focusedIndex, setFocusedIndex] = useState(initialFocusIndex);
    const prevListSize = usePrevious(listSize);

    useEffect(() => {
        if (listSize < (prevListSize || 0)) {
            setFocusedIndex(initialFocusIndex);
        }
    }, [listSize]);

    const setFocusIndex = useCallback(
        (newIndex) => {
            if (newIndex === focusedIndex) return;

            setFocusedIndex(clamp(Math.min(initialFocusIndex, 0), listSize - 1, newIndex));
        },
        [focusedIndex, listSize],
    );

    const moveListFocusUp = useCallback(() => {
        let nextFocus = focusedIndex - 1;
        nextFocus = loop && nextFocus < 0 ? listSize - 1 : nextFocus;

        return setFocusIndex(nextFocus);
    }, [focusedIndex, setFocusIndex, listSize]);

    const moveListFocusDown = useCallback(() => {
        let nextFocus = focusedIndex + 1;
        nextFocus = loop && nextFocus > listSize - 1 ? 0 : nextFocus;

        return setFocusIndex(nextFocus);
    }, [focusedIndex, setFocusIndex, listSize]);

    return {
        focusedIndex,
        setFocusIndex,
        moveListFocusUp,
        moveListFocusDown,
    };
};

export default useListFocusManager;
