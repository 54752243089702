// Lib
import React from 'react';

/**
 * This hook will ensure a focused list item is scrolled to if it's within a scrollable container.
 *
 * NOTE: This assumes that the scrollable container is the immediate parent of the list item.
 *  Perhaps a passed in ref could be used here if this is no longer the case?
 */
const useScrollOnListItemFocus = ({ focusedIndex, index }) => {
    const focused = focusedIndex === index;

    const listItemRef = React.useRef();

    React.useLayoutEffect(() => {
        if (!focused || !listItemRef.current) return;

        const parent = listItemRef.current.parentElement;

        const thisRect = listItemRef.current.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        const scrollMargin = thisRect.height / 2;

        // if focus is at bottom, scroll down
        if (parentRect.bottom - thisRect.bottom - scrollMargin < 0) {
            parent.scrollTop = thisRect.top - parentRect.top + parent.scrollTop - parentRect.height + thisRect.height;
        }

        // if focus is at top, scroll up
        if (thisRect.top - parentRect.top - scrollMargin < 0) {
            parent.scrollTop = thisRect.top - parentRect.top + parent.scrollTop;
        }
    }, [focused]);

    return [focused, listItemRef];
};

export default useScrollOnListItemFocus;
