import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementCommentThreadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={26}
        viewBox="0 0 32 26"
        className="Icon element-comment-thread"
        {...props}
    >
        <defs>
            <filter
                id="ElementCommentThreadIcon__a"
                width="121.9%"
                height="126.9%"
                x="-10.9%"
                y="-9.6%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <path
                id="ElementCommentThreadIcon__b"
                d="M2.002 22A1.995 1.995 0 0 1 0 20.009V1.99C0 .891.89 0 2.002 0h27.996C31.104 0 32 .89 32 1.991V20.01c0 1.1-.89 1.991-2.002 1.991H20l-4 4-4-4H2.002z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use xlinkHref="#ElementCommentThreadIcon__b" fill="#000" filter="url(#ElementCommentThreadIcon__a)" />
            <use xlinkHref="#ElementCommentThreadIcon__b" fill="#F4F7FE" />
            <path
                fill="#6D92F9"
                d="M24.24 14c.385 0 .703.28.753.648l.007.102c0 .414-.33.75-.76.75H5.76a.753.753 0 0 1-.76-.75c0-.414.33-.75.76-.75h18.48zm2.003-4c.383 0 .7.28.75.648l.007.102c0 .414-.346.75-.757.75H5.757A.752.752 0 0 1 5 10.75c0-.414.346-.75.757-.75h20.486zm0-4c.383 0 .7.28.75.648L27 6.75c0 .414-.346.75-.757.75H5.757A.752.752 0 0 1 5 6.75c0-.414.346-.75.757-.75h20.486z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementCommentThreadIcon);
export default Memo;
