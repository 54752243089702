// Lib
import React from 'react';

// Utils
import { getX, getY } from '../../../common/maths/geometry/dimensionUtil';

// Types
import { Point } from '../../../common/maths/geometry/pointTypes';

// Styles
import './DebugUIPoint.scss';

interface Props {
    color?: string;
    offset?: Point;
}

const DebugUIPoint = (props: Props) => {
    const { color = 'black', offset = { x: 0, y: 0 } } = props;

    return (
        <div className="DebugUIPoint">
            <div style={{ background: color, top: getY(offset) - 5, left: getX(offset) - 5 }} />
        </div>
    );
};

export default DebugUIPoint;
