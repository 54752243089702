import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardShortcutIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon board-shortcut"
        {...props}
    >
        <g fill="none">
            <path
                fill="#FFF"
                d="M5.293 3.707C4.663 3.077 5.109 2 6 2h7a1 1 0 0 1 1 1v7c0 .89-1.077 1.337-1.707.707l-1.34-1.34c-2.353 2.14-3.659 4.39-3.961 6.76-.13 1.018-1.527 1.203-1.917.253C3.307 12.072 3.74 8.306 6.377 5.244l.213-.24-1.297-1.297z"
            />
            <path fill="#333" d="M13 3H6l2 2-.225.216C4.828 8.108 4.236 11.703 6 16c.365-2.864 2.032-5.53 5-8l2 2V3z" />
        </g>
    </svg>
);
const Memo = memo(BoardShortcutIcon);
export default Memo;
