// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import elementUserTagConnect, { TAG_TYPE_ACTIVE } from './elementUserTagConnect';
import UserAvatar from '../../components/userAvatar/UserAvatar';
import ElementTag from '../../components/elements/ElementTag';

// Constants
import { USER_AVATAR_SIZES } from '../../../common/users/userConstants';

// Styles
import './MiniElementUserTag.scss';

const MiniElementUserTag = (props) => {
    const { user, type } = props;

    const isActive = type === TAG_TYPE_ACTIVE;

    const classes = classNames('MiniElementUserTag', {
        active: isActive,
        pulse: isActive,
    });

    const component = user ? (
        <UserAvatar user={user} size={USER_AVATAR_SIZES.SMALL} pulse={isActive} active={isActive} />
    ) : null;

    return <ElementTag className={classes}>{component}</ElementTag>;
};

MiniElementUserTag.propTypes = {
    isRemotelySelected: PropTypes.bool,
    remoteSelectionData: PropTypes.object,

    user: PropTypes.object,
    userId: PropTypes.string,
    isUserFetched: PropTypes.bool,
    type: PropTypes.string,
};

export default elementUserTagConnect(MiniElementUserTag);
