import * as Immutable from 'immutable';

import { BOARD_EXPORT_MEDIA, BOARD_EXPORT_MEDIA_RESET } from '../../../common/export/exportConstants';

const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case BOARD_EXPORT_MEDIA:
            return state.set(
                action.id,
                Immutable.fromJS({
                    fileName: action.fileName,
                    downloadUrl: action.downloadUrl,
                    sessionId: action.sessionId,
                }),
            );
        case BOARD_EXPORT_MEDIA_RESET:
            return state.delete(action.id);
        default:
            return state;
    }
};
