// Lib
import { omit, get, isUndefined } from 'lodash';

// Utils
import { isImageURL } from '../../../common/utils/urlUtil';
import { isImage } from '../../../common/elements/utils/elementTypeUtils';
import { getCaption, getShowCaption, getShowMedia } from '../../../common/elements/utils/elementPropertyUtils';
import rawFromText from '../../../common/utils/editor/rawUtils/rawFromText';

// Services
import getClientConfig from '../../utils/getClientConfig';
import mediaService from '../../utils/services/mediaService';
import { fetchBoard } from '../board/boardService';
import { getSelectedElementIds } from '../selection/selectedElementsSelector';
import { getAttachment, getFetching } from '../attachments/attachmentsSelector';
import fetchRemoteImage from '../../utils/services/image/fetchRemoteImage';

// Selectors
import { getElements } from '../selectors/elementsSelector';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';

// Actions
import { updateSelectedElements, setElementTypeAndUpdateElement } from '../actions/elementActions';
import { setElementLocalData } from '../local/elementLocalDataActions';
import { finishEditingElement } from '../selection/selectionActions';
import { uploadingElementAttachment, completedUploadingElementAttachment } from '../attachments/attachmentActions';

// Constants
import { MEDIA_TYPES } from '../../../common/links/richMediaConstants';
import { ElementType } from '../../../common/elements/elementTypes';

const environmentFolder = get(getClientConfig(), 'aws.s3Folder');

export const toggleLinkMedia = (currentState) => (dispatch, getState) => {
    const state = getState();

    const elementId = getSelectedElementIds(state).first();
    const firstSelectedElement = state.get('elements').get(elementId);

    const currentlyShowingMedia = isUndefined(currentState) ? getShowMedia(firstSelectedElement) : currentState;

    const changes = {
        showMedia: !currentlyShowingMedia,
    };

    dispatch(
        updateSelectedElements({
            id: elementId,
            changes,
        }),
    );
};

const getChangesCaption = (element, data) => {
    if (!isUndefined(getCaption(element))) return;

    let showCaption = getShowCaption(element);

    if (isUndefined(showCaption)) {
        showCaption = isUndefined(data.showCaption)
            ? !!data.description && data.mediaType !== MEDIA_TYPES.VIDEO && data.mediaType !== MEDIA_TYPES.RICH
            : data.showCaption;
    }

    const caption = isUndefined(data.caption) ? data.description : data.caption;

    return {
        caption: rawFromText(caption),
        showCaption,
    };
};

const prepareChanges = (element, data) => ({
    ...omit(data, ['description', 'elementType', 'caption', 'showCaption']),
    ...getChangesCaption(element, data),
});

const immediatelyChangeToImageElement = async ({ id, url, fetchLinkPromise, dispatch }) => {
    let done = false;

    // If the link finishes fetching before the image is fetched, then don't overwrite the link's result
    fetchLinkPromise.then(() => {
        done = true;
    });

    try {
        const { width, height } = await fetchRemoteImage(url);

        // If the link is already finished downloading then don't overwrite the link's data
        if (done) return;

        dispatch(
            setElementTypeAndUpdateElement({
                id,
                elementType: ElementType.IMAGE_TYPE,
                changes: {
                    image: { height, width, original: url, transparent: true },
                    showCaption: false,
                },
            }),
        );
    } catch (err) {
        // Ignore  errors when fetching the image, just treat it like a normal link card
    }
};

export const setLinkElementUrl =
    ({ id, url, transactionId }) =>
    async (dispatch, getState) => {
        const fetching = getFetching(getAttachment(getState(), { elementId: id }));
        if (fetching) return;

        dispatch(uploadingElementAttachment({ id }));
        dispatch(finishEditingElement());

        const fetchLinkPromise = mediaService.link.fetchLink({ id, url, environmentFolder });

        const initialState = getState();
        const initialElements = getElements(initialState);
        const initialElement = getElement(initialElements, id);

        // Immediately change into an image if we know they're loading an image (and the element isn't already an image)
        if (isImageURL(url) && !isImage(initialElement)) {
            immediatelyChangeToImageElement({ id, url, fetchLinkPromise, dispatch });
        }

        return fetchLinkPromise
            .then(({ data }) => {
                if (!data || data.error) throw new Error('The link details could not be fetched');

                const { elementType } = data;

                dispatch(completedUploadingElementAttachment({ id }));

                const state = getState();
                const elements = getElements(state);
                const element = getElement(elements, id);

                const changes = prepareChanges(element, data);

                dispatch(setElementTypeAndUpdateElement({ id, elementType, changes, transactionId }));

                if (elementType === ElementType.ALIAS_TYPE) dispatch(fetchBoard({ boardId: data.linkTo }));
            })
            .catch((err) => {
                console.error('Failed to resolve link', err);

                const error = {
                    message: err?.message || err?.code || 'Unknown error',
                };

                dispatch(completedUploadingElementAttachment({ id }));
                dispatch(
                    setElementLocalData({
                        id,
                        data: { error, url },
                    }),
                );
            });
    };
