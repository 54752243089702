// Utils
import { prop } from '../../../../../common/utils/immutableHelper';
import { getTimestamp } from '../../../../../common/utils/timeUtil';
import { getElement } from '../../../../../common/elements/utils/elementTraversalUtils';
import { getAssignments, getShowAssignments } from '../../../../../common/elements/utils/elementPropertyUtils';

// Selector
import { getCurrentlyEditingElement, getCurrentlyEditingId } from '../../../selection/currentlyEditingSelector';
import { getCurrentUserId } from '../../../../user/currentUserSelector';
import { getElements } from '../../../selectors/elementsSelector';

// Actions
import { updateElement } from '../../../actions/elementActions';
import { closePopupsMatching, openPopup } from '../../../../components/popupPanel/popupActions';

// Constants
import { ELEMENT_UPDATE_TYPE } from '../../../../../common/elements/elementConstants';

export const toggleElementShowAssignments =
    ({ id, sync = true }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);

        const isCurrentlyShowingAssignments = getShowAssignments(element);

        dispatch(
            updateElement({
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_ASSIGNMENT,
                id,
                changes: {
                    showAssignments: !isCurrentlyShowingAssignments,
                    assignments: null,
                },
                sync,
            }),
        );

        if (!isCurrentlyShowingAssignments) {
            const popupId = `task-assignment-${id}-0`;

            dispatch(openPopup(popupId));
            dispatch(
                closePopupsMatching({
                    predicateFn: (activePopupId) => activePopupId.startsWith('task') && activePopupId !== popupId,
                }),
            );
        }
    };

export const removeTaskAssignments =
    ({ id }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);

        const isCurrentlyShowingAssignments = getShowAssignments(element);

        if (!isCurrentlyShowingAssignments) return;

        dispatch(
            updateElement({
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_ASSIGNMENT,
                id,
                changes: {
                    showAssignments: false,
                    assignments: null,
                },
            }),
        );
    };

// TODO Should it have an assignment ID?
export const assignTask =
    ({ taskId, assigneeId, assigneeName, assigmentTimestamp = getTimestamp() }) =>
    (dispatch, getState) => {
        const state = getState();

        const elements = getElements(state);
        const task = getElement(elements, taskId);

        const isShowingAssignments = getShowAssignments(task);
        const assignments = getAssignments(task);
        const alreadyAssigned =
            assignments && assignments.some((assignment) => prop('assigneeId', assignment) === assigneeId);

        // If the specified user is already assigned, then do nothing
        if (isShowingAssignments && alreadyAssigned) return;

        const currentUserId = getCurrentUserId(state);

        dispatch(
            updateElement({
                id: taskId,
                updateType: ELEMENT_UPDATE_TYPE.ASSIGNMENT,
                changes: {
                    showAssignments: true,
                    assignments: [
                        {
                            assigneeId,
                            assigneeName,
                            assignerId: currentUserId,
                            assigmentTimestamp,
                        },
                    ],
                },
            }),
        );
    };

export const openDefaultAssignmentPopup = () => (dispatch, getState) => {
    const state = getState();
    const currentlyEditingId = getCurrentlyEditingId(state);
    const currentElement = getCurrentlyEditingElement(state);

    const isShowingAssignments = getShowAssignments(currentElement);

    return isShowingAssignments
        ? dispatch(openPopup(`task-assignment-${currentlyEditingId}-0`))
        : // Don't sync this change, otherwise it might conflict with text updates in the editor
          // This could mean that the "showAssignments" property won't be saved on the server if the
          // user doesn't select anything, but this is an edge case with unimportant consequences
          dispatch(toggleElementShowAssignments({ id: currentlyEditingId, sync: false }));
};
