// Lib
import * as Immutable from 'immutable';

// Constants
import {
    CANVAS_ZOOM_RESET_STATE,
    CANVAS_ZOOM_TRANSFORMATION_SET,
    CANVAS_ZOOM_TARGET_SCALE_SET,
} from './canvasConstants';

const initialState = Immutable.fromJS({
    mode: null,
    scale: 1,
    translation: { x: 0, y: 0 },
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CANVAS_ZOOM_TRANSFORMATION_SET:
            return state
                .set('mode', action.mode)
                .set('scale', action.scale)
                .setIn(['translation', 'x'], action.translation?.x)
                .setIn(['translation', 'y'], action.translation?.y)
                .remove('targetScale');
        case CANVAS_ZOOM_TARGET_SCALE_SET:
            return state.set('targetScale', action.targetScale);
        case CANVAS_ZOOM_RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
