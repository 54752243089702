// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Analytics
import { intercomTrackEvent } from '../../../../analytics/intercomService';

// Components
import ImageModalEditor from './ImageModalEditor';

const ImageModalCropModeContent = (props) => {
    const { finishEditingMode } = props;

    // close editor after short delay to allow image changes to propagate and render
    const closeEditorCallback = () => {
        intercomTrackEvent('edited-image');

        setTimeout(finishEditingMode, 50);
    };

    return <ImageModalEditor {...props} cancelEditing={finishEditingMode} afterSaveCb={closeEditorCallback} />;
};

ImageModalCropModeContent.propTypes = {
    finishEditingMode: PropTypes.func,
};

export default ImageModalCropModeContent;
