export default (event, { getEditorState, getProps }) => {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();

    const content = editorState.getCurrentContent();
    const startBlock = content.getBlockForKey(startKey);
    const numChars = startBlock.getLength();

    // Only handle right / down if already at the end of the task
    if (startOffset < numChars) return;

    event.stopPropagation();
    event.preventDefault();

    const { goToNextTask } = getProps();
    goToNextTask && goToNextTask();
};
