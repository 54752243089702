// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pure, compose } from '../../../node_module_clones/recompose';

// Components
import PopupToolButton from '../../components/popupPanel/PopupToolButton';
import PopupPanel from '../../components/popupPanel/PopupPanel';

// Actions
import { deselectAllElements } from '../../element/selection/selectionActions';

const DefaultWrapperComponent = ({ children, className }) => <div className={className}>{children}</div>;

DefaultWrapperComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    onToggle: (isPopupOpen) => {
        isPopupOpen && dispatch(deselectAllElements());
    },
});

class ExportUtil extends React.Component {
    render() {
        const {
            onToggle,
            popupSection,
            popupId,
            exportPopupElement,
            WrapperComponent = DefaultWrapperComponent,
        } = this.props;

        return (
            <WrapperComponent className="ExportUtil">
                <PopupToolButton
                    popupId={popupId}
                    closeSection={popupSection}
                    onToggle={onToggle}
                    text="Export"
                    showArrow
                />
                <PopupPanel {...this.props} popupId={popupId} className="ExportPopup">
                    {exportPopupElement}
                </PopupPanel>
            </WrapperComponent>
        );
    }
}

ExportUtil.propTypes = {
    elementId: PropTypes.string,
    popupId: PropTypes.string,
    popupSection: PropTypes.string,
    onToggle: PropTypes.func,
    WrapperComponent: PropTypes.elementType,
    exportPopupElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const enhance = compose(connect(null, mapDispatchToProps), pure);

export default enhance(ExportUtil);
