// Lib
import * as Immutable from 'immutable';

// Constants
import * as CHANGE_PASSWORD_ACTION_TYPES from './changePasswordConstants';

// Initial state
const initialState = Immutable.Map({
    isLoading: true,
    isError: false,
    isExpired: false,
    passwordUpdated: false,
    email: '',
    error: null,
    code: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_ACTION_TYPES.PASSWORD_CHANGE_FAILED:
            return state.set('isLoading', false).set('isError', true).set('error', action.error);
        case CHANGE_PASSWORD_ACTION_TYPES.PASSWORD_CHANGE_CODE_EXPIRED:
            return state.set('isLoading', false).set('isExpired', true);
        case CHANGE_PASSWORD_ACTION_TYPES.PASSWORD_CHANGE_INITIALISED:
            return state.set('isLoading', false).set('email', action.email).set('code', action.code);
        case CHANGE_PASSWORD_ACTION_TYPES.PASSWORD_CHANGED:
            return state.set('passwordUpdated', true);
        default:
            return state;
    }
};
