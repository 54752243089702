// Lib
import React from 'react';
import { isEmpty, get, includes } from 'lodash/fp';
import dataUriRegex from 'data-uri-regex';

// Constants
import { DATA_TRANSFER_TYPES } from '../../workspace/shortcuts/clipboard/clipboardConstants';
import { CUSTOM_TYPES } from './dragAndDropUtils';

let droppedData = null;

/**
 * If the GlobalDragAndDropHandler has some dropped data then it will return a new React DnD Monitor object
 * that provides the correct type and dropped data to be used by the drop handlers.
 */
export const getWrappedReactDndMonitor = (monitor) => {
    if (!droppedData) return monitor;

    // Potentially handle other types here too (of course the onDrop handler above would need to handle those too)
    if (droppedData.type !== CUSTOM_TYPES.IMAGE_LINK) return monitor;

    const wrappedMonitor = Object.create(monitor);

    // Force the monitor to return the native "URL" drop type
    wrappedMonitor.getItemType = () => droppedData.type;

    // Force the getItem invocation to return the same data structure expected by the react-dnd drop handlers
    wrappedMonitor.getItem = () => droppedData;

    return wrappedMonitor;
};

const asNum = (val) => parseInt(val, 10) || 0;

const getDimensions = (node) => ({
    width: asNum(get(['style', 'width'], node) || node.width),
    height: asNum(get(['style', 'height'], node) || node.height),
});

/**
 * This helper is used for native "text/html" types.
 * When dragging an image between Chrome browser tabs it uses a "text/html" data transfer type.
 */
class GlobalDragAndDropHelper extends React.Component {
    componentDidMount() {
        document.addEventListener('drop', this.onDrop, true);
    }

    componentWillUnmount() {
        document.removeEventListener('drop', this.onDrop, true);
    }

    onDrop = (event) => {
        const types = get(['dataTransfer', 'types'], event) || [];

        if (isEmpty(types)) return;

        // This is probably a drag and drop within Milanote
        if (includes(DATA_TRANSFER_TYPES.JSON, types)) return;

        if (!includes(DATA_TRANSFER_TYPES.HTML, types)) return;

        // If it includes files, the files should take precedence, so forget about handling 'text/html'
        if (includes(DATA_TRANSFER_TYPES.FILES, types)) return;

        const htmlData = event.dataTransfer.getData(DATA_TRANSFER_TYPES.HTML);
        const htmlDom = new DOMParser().parseFromString(htmlData, DATA_TRANSFER_TYPES.HTML);

        const childNodeLength = get(['body', 'childNodes', 'length'], htmlDom);

        // Only handling a single image drop
        if (childNodeLength !== 1) return;

        const childNode = get(['body', 'childNodes', 0], htmlDom);

        // Only interested in images
        if (!childNode || childNode.tagName !== 'IMG') return;

        if (!childNode.src) return;

        const src = childNode.src.toString();

        const isDataUri = src.match(dataUriRegex());
        const url = isDataUri ? event.dataTransfer.getData(DATA_TRANSFER_TYPES.URI_LIST) : src;

        const dimensions = getDimensions(childNode);

        droppedData = {
            type: CUSTOM_TYPES.IMAGE_LINK,
            src,
            url,
            isDataUri: !!isDataUri,
            ...dimensions,
        };

        document.addEventListener('dragover', this.clearData, true);
    };

    clearData = () => {
        droppedData = null;
        document.removeEventListener('dragover', this.clearData, true);
    };

    render() {
        return null;
    }
}

export default GlobalDragAndDropHelper;
