import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MediaPlayerFullscreenIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon media-player-fullscreen"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M2 10a1 1 0 0 1 .993.883L3 11v2h2a1 1 0 0 1 .117 1.993L5 15H2a1 1 0 0 1-.993-.883L1 14v-3a1 1 0 0 1 1-1zm12 0a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 1-1zM5 1a1 1 0 1 1 0 2H3v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1h3zm6 0h3a1 1 0 0 1 .993.883L15 2v3a1 1 0 0 1-1.993.117L13 5V3h-2a1 1 0 0 1-.117-1.993L11 1h3-3z"
        />
    </svg>
);
const Memo = memo(MediaPlayerFullscreenIcon);
export default Memo;
