/**
 * Determines if the current selection is across all the text in a single block.
 */
export default (editorState) => {
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) return false;

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    if (startKey !== endKey) return false;

    const startOffset = selection.getStartOffset();

    if (startOffset !== 0) return false;

    const contentState = editorState.getCurrentContent();
    const selectedBlock = contentState.getBlockForKey(startKey);
    const blockLength = selectedBlock.getLength();

    const endOffset = selection.getEndOffset();

    return blockLength === endOffset;
};
