// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import getPaddingForAspectRatio from '../../resizing/utils/getPaddingForAspectRatio';

// Components
import RichMediaErrorOverlay from './RichMediaErrorOverlay';
import RichMediaOverlay from './RichMediaOverlay';

// Styles
import './RichMediaDragPreview.scss';

const RichMediaDragPreview = (props) => {
    const { mediaData, tempMediaSize, show, showError } = props;

    if (!show) return null;

    return (
        <div
            className="RichMediaDragPreview"
            style={getPaddingForAspectRatio({ savedSize: mediaData, forcedSize: tempMediaSize, roundSaved: true })}
        >
            <RichMediaOverlay show={!showError} />
            <RichMediaErrorOverlay show={showError} />
        </div>
    );
};

RichMediaDragPreview.propTypes = {
    mediaData: PropTypes.object,
    tempMediaSize: PropTypes.object,
    width: PropTypes.number,
    show: PropTypes.bool,
    showError: PropTypes.bool,
};

export default RichMediaDragPreview;
