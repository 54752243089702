// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { getMainEditorKey } from '../utils/elementEditorUtils';
import { prop, propIn } from '../../../common/utils/immutableHelper';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import EditableTitle from '../../components/editableTitle/EditableTitle';
import LinkAnchor from './LinkAnchor';

// Styles
import './LinkMetadata.scss';

const LinkMetadata = (props) => {
    const { element, isEditable, isSingleSelected, filterQuery, captionVisible } = props;
    const linkData = propIn(['content', 'link'], element);

    if (!linkData) return null;

    const linkWrapper = <LinkAnchor url={prop('url', linkData)} />;

    const editorKey = getMainEditorKey(props);

    return (
        <div className={classNames('LinkMetadata', { 'no-caption': !captionVisible })}>
            <EditableTitle
                element={element}
                elementId={getElementId(element)}
                isEditable={isEditable}
                initialValue={prop('title', linkData)}
                maxLength={500}
                selectFirst
                selectAllOnEdit
                isSelected={isSingleSelected}
                wrapTitleElement={!isSingleSelected ? linkWrapper : null}
                filterQuery={filterQuery}
                editorKey={editorKey}
            />
        </div>
    );
};

LinkMetadata.propTypes = {
    element: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    filterQuery: PropTypes.string,
    captionVisible: PropTypes.bool,
};

export default LinkMetadata;
