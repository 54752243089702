// Lib
import React, { ReactElement } from 'react';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import doesEditorJsonHaveText from '../../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';

// Hooks
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Draft
import createRichTextEditor from '../../../components/editor/richText/createRichTextEditor';

// Components
import Icon from '../../../components/icons/Icon';
import DocumentDetailViewTiptapEditor from './DocumentDetailViewTiptapEditor';

// Types
import { ImEditorContent, ImMNElement } from '../../../../common/elements/elementModelTypes';
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';

const DocumentDraftEditor = createRichTextEditor({ clickableLinks: false });

const EmptyDocumentPreview = () => (
    <div className="EmptyDocumentPreview">
        <Icon name="content-empty" />
        <span className="preview-text">
            {platformSingleton.features.supportsMouse ? 'Double click to start typing' : 'Double tap to start typing'}
        </span>
    </div>
);

interface DocumentDetailViewEditorProps {
    element: ImMNElement;
    textContent: ImEditorContent;
    isFocusedForegroundElement: boolean;
}

const DocumentDetailViewEditor = (props: DocumentDetailViewEditorProps): ReactElement => {
    const { textContent, element } = props;

    const hasContent = doesEditorJsonHaveText(textContent);

    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);

    if (!hasContent) return <EmptyDocumentPreview />;

    const editorId = getMainEditorId(props);
    const editorKey = getMainEditorKey(props);

    if (tiptapContent) {
        return (
            <DocumentDetailViewTiptapEditor
                element={element}
                textContent={tiptapContent}
                editorId={editorId}
                editorKey={editorKey}
            />
        );
    }

    return (
        <DocumentDraftEditor
            element={element}
            editorId={editorId}
            editorKey={editorKey}
            textContent={textContent}
            isEditable={false}
        />
    );
};

export default DocumentDetailViewEditor;
