// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

// Utils
import { getShowDueDate } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getSelectedTaskIdSelector, getSelectedTaskSelector } from '../../../selector/selectionToolsSelector';

// Actions
import { toggleElementShowDueDatePill } from '../../../../../element/task/pills/assignment/dueDate/taskDueDateActions';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';

const isShowingDueDateSelector = createSelector(getSelectedTaskSelector, getShowDueDate);

const mapStateToProps = createStructuredSelector({
    selectedTaskId: getSelectedTaskIdSelector,
    isShowingDueDate: isShowingDueDateSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleShowDueDate: (id) => dispatch(toggleElementShowDueDatePill({ id })),
});

const DueDateTool = (props) => {
    const { isShowingDueDate, dispatchToggleShowDueDate, selectedTaskId } = props;

    const onToggle = React.useCallback(() => {
        dispatchToggleShowDueDate(selectedTaskId);
    }, [selectedTaskId]);

    return (
        <div className="AssignUserTool">
            <ToolbarTool {...props} onPointerDown={onToggle} name="Due date" isActive={isShowingDueDate}>
                <Icon name="toolbar-due-date" />
            </ToolbarTool>
        </div>
    );
};

DueDateTool.propTypes = {
    selectedTaskId: PropTypes.string,
    isShowingDueDate: PropTypes.bool,
    dispatchToggleShowDueDate: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DueDateTool);
