import React from 'react';
import PropTypes from 'prop-types';

import { listToEnglish } from '../../../common/utils/stringUtil';

import { ELEMENT_ACTIVITY_TYPES } from './elementActivityConstants';

const ActivityIndicatorChangeDescription = ({ activityType, activityUserNames }) => {
    const userNames = listToEnglish(activityUserNames);

    switch (activityType) {
        case ELEMENT_ACTIVITY_TYPES.ADD:
            return (
                <>
                    Added by <strong>{userNames}</strong>
                </>
            );
        case ELEMENT_ACTIVITY_TYPES.SHARE:
            return (
                <>
                    Shared by <strong>{userNames}</strong>
                </>
            );
        case ELEMENT_ACTIVITY_TYPES.MENTION:
            return (
                <>
                    Mentioned by <strong>{userNames}</strong>
                </>
            );
        case ELEMENT_ACTIVITY_TYPES.COMMENT:
            return (
                <>
                    <strong>{userNames}</strong> commented
                </>
            );
        case ELEMENT_ACTIVITY_TYPES.CHANGE:
            return (
                <>
                    <strong>{userNames}</strong> changed
                </>
            );
        case ELEMENT_ACTIVITY_TYPES.REACTION:
            return (
                <>
                    <strong>{userNames}</strong> reacted
                </>
            );
        case ELEMENT_ACTIVITY_TYPES.UPDATE:
        default:
            return (
                <>
                    Updated by <strong>{userNames}</strong>
                </>
            );
    }
};

ActivityIndicatorChangeDescription.propTypes = {
    activityType: PropTypes.string,
    activityUserNames: PropTypes.array,
};

export default ActivityIndicatorChangeDescription;
