// Lib
import { createStructuredSelector } from 'reselect';

// Utils
import { isPlatformElectronMac } from '../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

export const getIsElectronSelector = (state) => isPlatformElectronMac(getPlatformDetailsSelector(state));

export default createStructuredSelector({
    isElectron: getIsElectronSelector,
});
