// Lib
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { waitUntil } from '../../../common/utils/functionUtils';
import { manuallyReportError } from '../../analytics/rollbarService';
import { isPlatformIframe } from '../../platform/utils/platformDetailsUtils';
import getClientConfig from '../../utils/getClientConfig';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Constants
import { ONE_TRUST_STATUS } from './oneTrustConstants';

const oneTrustLoaded = () => !!window.OneTrust && !!window.OneTrust.Init && !!window.OneTrust.GetDomainData;

const useOneTrustLoader = ({ isGuest, userId, userOTToken }) => {
    const config = getClientConfig();

    const platformDetails = useSelector(getPlatformDetailsSelector);
    const [oneTrustStatus, setOneTrustStatus] = useState(ONE_TRUST_STATUS.UNFETCHED);

    const loadOneTrust = useCallback(
        (userId, userOTToken) => {
            // If user authenticated, set user information in window.OneTrust.dataSubjectParams before loading OneTrust
            // - This will be used to get user's cookie preference from OneTrust cloud, or send user's cookie preference
            //   to OneTrust Cloud
            // - This will help in Safari, where user's local cookie pref is removed by Safari every 7 days due to ITP

            window.OneTrust = window.OneTrust || {};
            window.OneTrust.dataSubjectParams = {};

            if (userId && userOTToken) {
                window.OneTrust.dataSubjectParams = {
                    id: userId,
                    token: userOTToken,
                    isAnonymous: false,
                };
            }

            // If OneTrust has not been loaded yet, embed OneTrust script into the DOM
            if (oneTrustStatus === ONE_TRUST_STATUS.UNFETCHED) {
                const { domainScript } = config.oneTrust;

                const script = document.createElement('script');
                script.src = `https://cdn.cookielaw.org/consent/${domainScript}/otSDKStub.js`;
                script.dataset.domainScript = domainScript;

                document.body.appendChild(script);

                setOneTrustStatus(ONE_TRUST_STATUS.FETCHING);

                waitUntil(oneTrustLoaded, { duration: 60000 })
                    .then(() => {
                        setOneTrustStatus(ONE_TRUST_STATUS.INITIALISED);
                    })
                    .catch((error) => {
                        setOneTrustStatus(ONE_TRUST_STATUS.ERROR);
                        manuallyReportError({ errorMessage: 'OneTrust failed to load', error });
                    });

                return;
            }

            // If OneTrust has previously been loaded, just sync the user cookie profile from OneTrust cloud
            if (oneTrustStatus === ONE_TRUST_STATUS.INITIALISED) {
                window.OneTrust.syncConsentProfile(userId, userOTToken, false);
            }
        },
        [oneTrustStatus],
    );

    useEffect(() => {
        // If in iframe, don't show cookie banner, and use default cookie settings per region
        if (isPlatformIframe(platformDetails)) return;

        if (!isGuest && userId && userId !== 'guest') {
            loadOneTrust(userId, userOTToken);
        }

        if (isGuest) {
            loadOneTrust();
        }
    }, [isGuest, userId]);

    return [oneTrustStatus];
};

export default useOneTrustLoader;
