import { combineReducers } from 'redux-immutable';

import active from './popupActiveReducer';
import icons from '../../workspace/toolbar/components/selectionTools/iconTools/popup/iconsPopupReducer';
import elementSearch from '../search/searchPopupReducer';
import imageSearch from '../../workspace/toolbar/components/elementTools/imageTool/popup/imageSearchPopupReducer';
import notifications from '../../workspace/header/workspaceToolsHeader/notifications/popup/notificationsPopupReducer';
import pushSuggestion from '../../workspace/header/workspaceToolsHeader/notifications/pushPopup/pushSuggestionPopupReducer';

export default combineReducers({
    active,
    icons,
    elementSearch,
    imageSearch,
    notifications,
    pushSuggestion,
});
