// Lib
import { isEmpty } from 'lodash/fp';

// Services
import { http } from '../../utils/services/http';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import { asyncResource } from '../../utils/services/http/asyncResource/asyncResource';

// Actions
import { loadElements } from '../../../common/elements/elementActions';

// Selectors
import { getTokensSelector } from '../../utils/permissions/permissionsSelector';

// Constants
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { CLONE_INSTANCES_FETCHED } from './cloneInstancesConstants';

const fetchedCloneInstances = ({
    originalElementId,
    instanceCount,
    validInstanceCount,
    reachableInstanceCount,
    reachableInstanceIds,
    reachableInstanceBoardCount,
    reachableInstanceBoardIds,
}) => ({
    type: CLONE_INSTANCES_FETCHED,
    elementId: originalElementId,
    instanceCount,
    validInstanceCount,
    reachableInstanceCount,
    reachableInstanceIds,
    reachableInstanceBoardCount,
    reachableInstanceBoardIds,
    timestamp: getTimestamp(),
});

/**
 * Fetches the data for other clone instances of this element.
 */
export const fetchCloneInstances =
    ({ elementId, checkCloneProps, force }) =>
    async (dispatch, getState) => {
        const state = getState();

        // Intentionally not using the getElements selector, as it results in an electron dependency issue
        const elementsState = state.get('elements');

        const element = getElement(elementsState, elementId);

        if (!element) return;

        const originalElementId = getPhysicalId(element);

        return dispatch(
            asyncResource(
                ResourceTypes.cloneInstances,
                originalElementId,
                true,
            )(async () => {
                // FIXME due to issues with the size of requests when sending all tokens, we might just want to send all
                //  ACL IDs here. Double check that there's no security issues with this though (e.g. ancestors are sent
                //  back in the response).
                // NOTE: We need all tokens here so the server knows which boards the user has permissions to view
                const tokens = getTokensSelector(state).toArray().join(',');

                const response = await http({
                    url: `elements/${elementId}/clones`,
                    params: { tokens, 'check-clone-props': checkCloneProps, id: originalElementId },
                });

                const { elements } = response.data;

                if (!isEmpty(elements)) dispatch(loadElements(elements));

                dispatch(fetchedCloneInstances(response.data));

                return response;
            }),
        );
    };
