// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import FullScreenModal from './FullScreenModal';

// Icons
import Icon from '../icons/Icon';

// Styles
import './FullScreenErrorModal.scss';

const FullScreenErrorModal = ({ children, className, overlay, close }) => (
    <FullScreenModal className={classNames('FullScreenErrorModal', className)} overlay={overlay} close={close}>
        <div className="logo">
            <Icon name="error-alert" />
        </div>
        {children}
    </FullScreenModal>
);

FullScreenErrorModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    overlay: PropTypes.bool,
    close: PropTypes.func,
    className: PropTypes.string,
};

export default FullScreenErrorModal;
