// Lib
import React from 'react';
import PropTypes from 'prop-types';
import loadable from 'react-loadable';

// Components
import IconLight from '../light/IconLight';

// Constants
import { ICON_SIZES } from '../iconConstants';

const IconLoader = () => <div className="Icon loading" />;

export const IconSizeLargeDark = loadable({
    loader: () => import(/* webpackChunkName: "icons-dark-large" */ './IconDarkSizeLarge'),
    loading: IconLoader,
});

export const IconSizeMediumDark = loadable({
    loader: () => import(/* webpackChunkName: "icons-dark-medium" */ './IconDarkSizeMedium'),
    loading: IconLoader,
});

export const IconSizeSmallDark = loadable({
    loader: () => import(/* webpackChunkName: "icons-dark-small" */ './IconDarkSizeSmall'),
    loading: IconLoader,
});

const IconDark = ({ name, iconSize }) => {
    switch (iconSize) {
        case ICON_SIZES.SMALL:
            return (
                <IconSizeSmallDark name={name} iconSize={iconSize}>
                    <IconSizeMediumDark name={name} iconSize={iconSize}>
                        <IconSizeLargeDark name={name} iconSize={iconSize}>
                            <IconLight name={name} iconSize={iconSize} />
                        </IconSizeLargeDark>
                    </IconSizeMediumDark>
                </IconSizeSmallDark>
            );
        case ICON_SIZES.MEDIUM:
            return (
                <IconSizeMediumDark name={name} iconSize={iconSize}>
                    <IconSizeLargeDark name={name} iconSize={iconSize}>
                        <IconLight name={name} iconSize={iconSize} />
                    </IconSizeLargeDark>
                </IconSizeMediumDark>
            );
        case ICON_SIZES.LARGE:
        default:
            return (
                <IconSizeLargeDark name={name} iconSize={iconSize}>
                    <IconLight name={name} iconSize={iconSize} />
                </IconSizeLargeDark>
            );
    }
};

IconDark.propTypes = {
    name: PropTypes.string,
    iconSize: PropTypes.string,
};
export default IconDark;
