export const USER_UPDATE = 'USER_UPDATE';
export const USER_REFRESH = 'USER_REFRESH';
export const USER_NAVIGATE = 'USER_NAVIGATE';
export const GUEST_NAVIGATE = 'GUEST_NAVIGATE';
export const USER_NAVIGATE_TEMPLATE = 'USER_NAVIGATE_TEMPLATE';
export const USER_ACTIVITY_HEARTBEAT = 'USER_ACTIVITY_HEARTBEAT';
export const USER_CHECKED_NOTIFICATIONS = 'USER_CHECKED_NOTIFICATIONS';
export const USER_CHECKED_QUICK_NOTES = 'USER_CHECKED_QUICK_NOTES';

// NOTE: This action is only sent from the server to the client at this stage
export const USER_ENABLE_EXPERIMENT = 'USER_ENABLE_EXPERIMENT';

export const USER_CONNECT = 'USER_CONNECT';
export const USER_MARK_AS_DISCONNECTED = 'USER_MARK_AS_DISCONNECTED';
export const USER_DISCONNECT = 'USER_DISCONNECT';

export const USER_ROLES = {
    USER: 'USER',
    CLIENT: 'CLIENT',
    ADMIN: 'ADMIN',
    STAFF: 'STAFF',
    BETA: 'BETA',

    // Server tokens
    SERVER: 'SERVER',
    DATA: 'DATA',
};

export const USER_STATUSES = {
    INVITED: 'INVITED',
    ACTIVE: 'ACTIVE',
    LOCKED: 'LOCKED',
    REMOVED: 'REMOVED',
};

export const USER_ONLINE_STATUS = {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    IDLE: 'IDLE',
};

export const USER_SOCIAL_AUTH_VENDORS = {
    GOOGLE: 'google',
    APPLE: 'apple',
};

export const USER_AUTHENTICATION_METHODS = {
    GOOGLE_SIGN_IN: 'GOOGLE_SIGN_IN',
    APPLE_SIGN_IN: 'APPLE_SIGN_IN',
    PASSWORD: 'PASSWORD',
};

export const NOTIFICATION_PREFERENCE_BITS = {
    PRODUCT_UPDATES: 1,
    ACTIVITY_DIGEST: 1 << 1,
    SHARED_BOARDS: 1 << 2,
    MENTIONS: 1 << 3,
    COMMENTS: 1 << 4,
    ASSIGNMENTS: 1 << 5,
    REMINDERS: 1 << 6,
};

export const EMAIL_PREFERENCE_POSSIBLE =
    NOTIFICATION_PREFERENCE_BITS.PRODUCT_UPDATES |
    NOTIFICATION_PREFERENCE_BITS.ACTIVITY_DIGEST |
    NOTIFICATION_PREFERENCE_BITS.SHARED_BOARDS |
    NOTIFICATION_PREFERENCE_BITS.MENTIONS |
    NOTIFICATION_PREFERENCE_BITS.ASSIGNMENTS |
    NOTIFICATION_PREFERENCE_BITS.REMINDERS;

// Expect to use no more than 8 bits for email preferences
export const NOTIFICATION_PREFERENCE_ALL = (1 << 8) - 1;
// The buffer bits that we're currently not using but might in the future
export const EMAIL_PREFERENCE_BUFFER_BITS = NOTIFICATION_PREFERENCE_ALL ^ EMAIL_PREFERENCE_POSSIBLE;

export const PUSH_NOTIFICATION_PREFERENCE_POSSIBLE =
    NOTIFICATION_PREFERENCE_BITS.SHARED_BOARDS |
    NOTIFICATION_PREFERENCE_BITS.MENTIONS |
    NOTIFICATION_PREFERENCE_BITS.COMMENTS |
    NOTIFICATION_PREFERENCE_BITS.ASSIGNMENTS |
    NOTIFICATION_PREFERENCE_BITS.REMINDERS;

// Users that are not signed in
export const PUBLIC_USER_ID = '_other';
export const STARTER_CONTENT_CREATOR_ID = '_default';
export const GUEST_USER_ID = 'guest';

export const USER_UPDATE_BROADCAST_WHITELIST = [
    'currentBoardId',
    'name',
    'role',
    'status',
    'image',
    'email',
    'onlineStatus',
    'emailVerifiedDate',
    'subscriptionId',
];
export const SAFE_USER_PROPERTY_WHITELIST = ['_id', 'subscription', 'flags', ...USER_UPDATE_BROADCAST_WHITELIST];
export const SAFE_USER_PROPERTY_WHITELIST_UNAUTHENTICATED = ['_id', 'name', 'image'];

// The time that a user can be idle for before being marked as disconnected
export const USER_IDLE_TIMEOUT_SECONDS = 10 * 60;
export const USER_IDLE_TIMEOUT_MILLISECONDS = USER_IDLE_TIMEOUT_SECONDS * 1000;

export const USER_AVATAR_SIZES = {
    EXTRA_LARGE: 128,
    MOBILE_PROFILE: 80,
    LARGE: 64,
    REGULAR: 36,
    REGULAR_SMALL: 30,
    SMALL: 24,
};

export const PLATFORMS = {
    WEB: 'WEB',
    PWA: 'PWA',
    MAC_DESKTOP_APP: 'MAC_DESKTOP_APP',
    IPAD: 'iPad',
    IPHONE: 'IPHONE',
    ANDROID: 'ANDROID',
    BROWSER_EXTENSION: 'BROWSER_EXTENSION',
    // Version conflicts will have a platform of 'CONFLICT' so they can be distinguished from others
    CONFLICT: 'CONFLICT',
};

export const USER_REGISTRATION_AVENUES = {
    BOARD_INVITE: 'board invite',
};

export const REGISTRATION_ENTRY_POINT = {
    PUBLIC_EDIT_REGISTRATION: 'public_edit',
    PUBLISHED_REGISTRATION: 'published',
    UTM_REGISTRATION: 'utm',
};

export const REGISTRATION_EVENT_NAMES = {
    [REGISTRATION_ENTRY_POINT.PUBLIC_EDIT_REGISTRATION]: 'Registered on editable board',
    [REGISTRATION_ENTRY_POINT.PUBLISHED_REGISTRATION]: 'Registered on published board',
    [REGISTRATION_ENTRY_POINT.UTM_REGISTRATION]: 'Registered on utm board',
};
