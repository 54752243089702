// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import initialState from './canvasInitialStateReducer';
import size from './canvasSizeReducer';
import scrollAreaSize from './scrollAreaSizeReducer';
import scaled from './canvasScaledReducer';
import zoom from './canvasZoomReducer';

export default combineReducers({
    initialState,
    size,
    scrollAreaSize,
    scaled,
    zoom,
});
