// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Selectors
import { getElement } from '../element/selectors/elementSelector';

// Components
import Element from '../element/Element';

// Styles
import './ListElement.scss';

const mapStateToProps = () =>
    createStructuredSelector({
        element: getElement,
    });

const ListElementPresentational = (props) => (
    <div className="ListElement">
        <Element {...props} />
    </div>
);

ListElementPresentational.propTypes = {
    currentBoardId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ListElementPresentational);
