// Lib
import Immutable from 'immutable';

// Utils
import { asObject } from '../../../common/utils/immutableHelper';
import { getContent, getElementId, getElementLocation } from '../../../common/elements/utils/elementPropertyUtils';

export const getRenderedCloneElement = (cloneElement, linkedElement) => {
    const linkedElementObject = asObject(linkedElement);
    const cloneElementObject = asObject(cloneElement);

    const location = getElementLocation(cloneElementObject);

    const cloneContent = getContent(cloneElementObject);
    const linkedContent = getContent(linkedElementObject);

    const elementId = getElementId(cloneElement);

    return Immutable.fromJS({
        ...cloneElementObject,
        ...linkedElementObject,
        _id: elementId,
        id: elementId,
        isClone: true,
        location,
        content: {
            ...linkedContent,
            ...cloneContent,
        },
    });
};
