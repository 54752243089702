// Utils
import localStorageService from '../../services/localStorage/localStorage';

// Buffer implementation
import socketSendBuffer from './socketSendBuffer';

const getPersistedSendBuffer = () => {
    const sendBufferStr = localStorageService.getItem('sendBuffer');
    localStorageService.removeItem('sendBuffer');
    return sendBufferStr ? JSON.parse(sendBufferStr) : {};
};

const persistSendBuffer = (sendBuffer) => {
    localStorageService.setItem('sendBuffer', JSON.stringify(sendBuffer));
};

const checkSendBufferBeforeUnload = (sendBuffer) => (event) => {
    if (sendBuffer.size()) persistSendBuffer(sendBuffer.getBuffer());
};

export default () => {
    const persistedSendBuffer = getPersistedSendBuffer();

    const sendBuffer = socketSendBuffer(persistedSendBuffer);
    window.addEventListener('beforeunload', checkSendBufferBeforeUnload(sendBuffer));

    return sendBuffer;
};
