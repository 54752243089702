// Components
import ClippedText from './ClippedText';

// Handlers
import clippingDecoratorStrategy from './clippingDecoratorStrategy';

export default () => ({
    decorators: [
        {
            strategy: clippingDecoratorStrategy,
            component: ClippedText,
        },
    ],
});
