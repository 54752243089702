// Lib
import { includes, isString } from 'lodash/fp';

export const GOOGLE_SCOPES = {
    openId: 'openid email profile',
    contacts_read_only: 'https://www.googleapis.com/auth/contacts.readonly',
};

export const ACCESS_TYPES = {
    online: 'online',
    offline: 'offline',
};

export const PROMPT = {
    consent: 'consent',
};

const convertToScopeArray = (scope) => (isString(scope) ? scope.split(' ') : scope);

export const hasScope = (currentScope, targetScope) => {
    const currentScopeArray = convertToScopeArray(currentScope);

    if (!currentScopeArray) return false;
    if (!targetScope) return true;

    return includes(targetScope, currentScopeArray);
};

export const addScope = (currentScope = '', newScope = '') => {
    const newScopeArray = convertToScopeArray(newScope);
    const currentScopeArray = convertToScopeArray(currentScope);

    return newScopeArray
        .reduce((updatedScopeArray, scopeToAdd) => {
            if (!hasScope(updatedScopeArray, scopeToAdd)) {
                updatedScopeArray.push(scopeToAdd);
            }

            return updatedScopeArray;
        }, currentScopeArray)
        .join(' ');
};
