// Lib
import React, { ReactElement } from 'react';
import { get } from 'lodash/fp';

// Components
import LinkButton from '../../buttons/LinkButton';

interface DefaultErrorFurtherDetailsProps {
    error: Error;
    furtherDetails: string;
    getErrorDetails?: (props: DefaultErrorFurtherDetailsProps) => string;
    dispatchToggleIntercom: () => void;
}

const getDefaultErrorDetails = ({ error }: DefaultErrorFurtherDetailsProps) => `Error: ${error && error.toString()}
Stack: ${get('stack', error)}`;

const DefaultErrorFurtherDetails = (props: DefaultErrorFurtherDetailsProps): ReactElement => {
    const { getErrorDetails = getDefaultErrorDetails, dispatchToggleIntercom, furtherDetails } = props;

    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const errorDetails = getErrorDetails(props);

    const copyTextAreaToClipboard = () => {
        if (!textAreaRef.current) return;
        textAreaRef.current.select();
        document.execCommand('copy');
    };

    return (
        <details>
            <summary>Otherwise</summary>
            <div className="info">
                <p className="support-message">
                    Please&nbsp;
                    <LinkButton onClick={dispatchToggleIntercom}>contact support</LinkButton>
                    &nbsp;and provide them with the following information:
                </p>
                {furtherDetails}
                <button className="clipboard" onClick={copyTextAreaToClipboard}>
                    Copy to clipboard
                </button>
                <div>
                    <textarea
                        ref={textAreaRef}
                        readOnly
                        value={errorDetails}
                        onFocus={copyTextAreaToClipboard}
                        onClick={copyTextAreaToClipboard}
                    />
                </div>
            </div>
        </details>
    );
};

export default DefaultErrorFurtherDetails;
