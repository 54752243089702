// Lib
import React from 'react';

// Components
import Button from '../../../../../../components/buttons/Button';

// Styles
import './CustomColorPickerContainer.scss';

type CustomColorPickerContainerProps = {
    closePopup?: () => void;
    openCustomColorPicker: () => void;
};

const CustomColorPickerContainer = ({ closePopup, openCustomColorPicker }: CustomColorPickerContainerProps) => {
    const onCustomColorButtonClick = () => {
        openCustomColorPicker();
        closePopup?.();
    };

    return (
        <div className="CustomColorPickerContainer">
            <div className="custom-color-picker-button-container">
                <Button className="custom-color-picker" onClick={onCustomColorButtonClick}>
                    <div className="color-wheel" />
                    <div className="color-picker-text">Custom color...</div>
                </Button>
            </div>
        </div>
    );
};

export default CustomColorPickerContainer;
