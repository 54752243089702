import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const EmbedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={16}
        viewBox="0 0 18 16"
        className="Icon embed"
        {...props}
    >
        <path
            fill="#5F6672"
            fillRule="evenodd"
            d="M9.772 1.818a.75.75 0 0 1 1.456.364l-3 12a.75.75 0 0 1-1.456-.364zM3.47 4.47a.75.75 0 0 1 1.06 1.06L2.06 8l2.47 2.47a.75.75 0 0 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06zm11.06 0 3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 0 1-1.06-1.06L15.94 8l-2.47-2.47a.75.75 0 0 1 1.06-1.06z"
        />
    </svg>
);
const Memo = memo(EmbedIcon);
export default Memo;
