import { AnyAction } from 'redux';
import { HybridElementEditStartAction, HybridLocationChangeImageAction } from '../hybridMiddlewareConstants';
import { createHybridElementEditStartAction } from './createHybridActions/createHybridElementEditStartAction';
import { createHybridPresentModalImageAction } from './createHybridActions/createHybridPresentModalImageAction';
import { getElement } from '../../../../element/utils/elementStoreUtils';
import { getElementType } from '../../../../../common/elements/utils/elementPropertyUtils';
import { ElementType } from '../../../../../common/elements/elementTypes';

const getHybridActionFromFocusModeElementClicked = (
    state: any,
    action: AnyAction,
): HybridLocationChangeImageAction | HybridElementEditStartAction | undefined => {
    const elementId = action.elementId;
    const element = getElement(state, elementId);
    if (element) {
        switch (getElementType(element)) {
            case ElementType.CARD_TYPE:
            case ElementType.COMMENT_THREAD_TYPE:
            case ElementType.TASK_LIST_TYPE:
                return createHybridElementEditStartAction(state, action, elementId);
            case ElementType.IMAGE_TYPE:
                return createHybridPresentModalImageAction(state, action, element);
            default:
                return;
        }
    }
};

// Export

export default (
    state: any,
    action: AnyAction,
): HybridLocationChangeImageAction | HybridElementEditStartAction | undefined =>
    getHybridActionFromFocusModeElementClicked(state, action);
