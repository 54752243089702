// Utils
import getEditorBlockCount from '../editorState/getEditorBlockCount';

/**
 * Determines if the current selection of the editor should ideally modify a block
 * style or an inline style.
 *  - If the selection is across multiple blocks then it should modify a block style.
 *  - If the selection is on a single block, and there is only one block,
 *      and the selection spans the entire block, it should modify a block style.
 *
 * Otherwise it should modify an inline style.
 */
export default (editorState) => {
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) return false;

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    if (startKey !== endKey) return true;

    const editorBlockCount = getEditorBlockCount(editorState);

    if (editorBlockCount !== 1) return false;

    const startOffset = selection.getStartOffset();

    if (startOffset !== 0) return false;

    const contentState = editorState.getCurrentContent();
    const selectedBlock = contentState.getBlockForKey(startKey);
    const blockLength = selectedBlock.getLength();

    const endOffset = selection.getEndOffset();

    return blockLength === endOffset;
};
