import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { stopPropagationOnly } from '../../../utils/domUtil';

import Button from '../../../components/buttons/Button';
import Icon from '../../../components/icons/Icon';
import FloatingPanel from '../../../components/tooltips/FloatingPanel';

import FileMediaPlayerScrubberBar, { SCRUBBER_AXIS } from './FileMediaPlayerScrubberBar';

import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';

import './FileMediaPlayerVolume.scss';

const getVolumeIconName = (volume) => (volume > 0 ? 'media-player-volume-loud' : 'media-player-volume-mute');

const FileMediaSliderControl = ({ volume, mute, setVolume, setMute, volumeId }) => {
    const [isDragging, setIsDragging] = useState(false);

    return (
        <FloatingPanel
            className={classNames('FileMediaSliderControl light', { dragging: isDragging })}
            selector={`#${volumeId}`}
            arrowWidth={0}
            arrowStrokeWidth={0}
            distance={10}
            offset={0}
            delay={0}
            position={TooltipPositions.TOP}
            pollPosition
        >
            <div className="scrubber-bar-container" onClick={stopPropagationOnly}>
                <FileMediaPlayerScrubberBar
                    axis={SCRUBBER_AXIS.VERTICAL}
                    value={volume}
                    onChange={setVolume}
                    onDragStart={() => {
                        setIsDragging(true);
                        if (mute) setMute(false);
                    }}
                    onDragEnd={() => setIsDragging(false)}
                />
            </div>
        </FloatingPanel>
    );
};

FileMediaSliderControl.propTypes = {
    volumeId: PropTypes.string,
    volume: PropTypes.number,
    mute: PropTypes.bool,
    setVolume: PropTypes.func,
    setMute: PropTypes.func,
};

const FileMediaPlayerVolume = ({
    playerId,
    volume,
    mute,
    setVolume,
    setMute,
    volumePopupVisible,
    setVolumePopupVisible,
}) => {
    const volumeId = `volume-${playerId}`;

    const handleVolumeButtonClick = (event) => {
        event && event.stopPropagation();
        setMute(!mute);
    };

    return (
        <Button
            className="FileMediaPlayerVolumeButton control-button"
            onClickFn={handleVolumeButtonClick}
            onMouseEnter={() => setVolumePopupVisible(true)}
            onMouseLeave={() => setVolumePopupVisible(false)}
            onDoubleClick={stopPropagationOnly}
            id={volumeId}
        >
            <Icon name={getVolumeIconName(mute ? 0 : volume)} />

            {volumePopupVisible && (
                <FileMediaSliderControl
                    volume={mute ? 0 : volume}
                    setVolume={setVolume}
                    mute={mute}
                    setMute={setMute}
                    volumeId={volumeId}
                />
            )}
        </Button>
    );
};

FileMediaPlayerVolume.propTypes = {
    playerId: PropTypes.string,
    volume: PropTypes.number,
    mute: PropTypes.bool,
    volumePopupVisible: PropTypes.bool,
    setVolume: PropTypes.func,
    setMute: PropTypes.func,
    setVolumePopupVisible: PropTypes.func,
};

export default FileMediaPlayerVolume;
