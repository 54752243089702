// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash/fp';

// Hooks
import useProgressiveImageLoader from './useProgressiveImageLoader';

// Components
import BackgroundImage from '../BackgroundImage';
import MissingImage from './MissingImage';

// Styles
import './ProgressiveImage.scss';

/**
 * Shows an image which progressively becomes better quality by first showing a thumbnail of the image,
 * and then, once the larger version has loaded, replaces the thumbnail with the full size image.
 *
 * NOTE: The component initially shows the source file because it would otherwise cause a flash of content when dragging
 * an element that has already loaded.
 * Now there will be a slight flash when the image has not yet loaded, but that's an acceptable trade off.
 */
const ProgressiveImage = (props) => {
    const {
        thumbnail,
        style,
        alt,
        forcedSize,
        transparent,
        paddingBottom,
        imageAspectRatio,
        source,
        onImageLoadCb,
        showBrokenIconOnError = false,
        isPreview,
    } = props;

    // Loads the image in the background and then swaps it in when it's ready to display
    const { hideImage, visibleSource, isError } = useProgressiveImageLoader({
        source,
        onImageLoadCb,
    });

    const width = get('width', forcedSize);
    const height = get('height', forcedSize);

    const fitVertical = imageAspectRatio < parseFloat(paddingBottom) / 100;

    if (isError) {
        return (
            <MissingImage
                src={source}
                style={style}
                width={width}
                height={height}
                paddingBottom={paddingBottom}
                showBrokenIconOnError={showBrokenIconOnError}
            />
        );
    }

    const styles = {
        ...style,
        paddingBottom,
    };

    const classes = classNames('image-node', { transparent: hideImage, 'fit-vertical': fitVertical });

    return (
        <BackgroundImage
            className="ProgressiveImage"
            style={styles}
            source={!transparent && !isPreview ? thumbnail : null}
        >
            <img className={classes} src={visibleSource} alt={alt} width={width} height={height} />
        </BackgroundImage>
    );
};

ProgressiveImage.propTypes = {
    source: PropTypes.string,
    visibleSource: PropTypes.string,
    thumbnail: PropTypes.string,
    onImageLoadCb: PropTypes.func,
    hasLoaded: PropTypes.bool,
    style: PropTypes.object,
    alt: PropTypes.string,
    forcedSize: PropTypes.object,
    imageAspectRatio: PropTypes.number,
    transparent: PropTypes.bool,
    hideImage: PropTypes.bool,
    paddingBottom: PropTypes.string,
    showBrokenIconOnError: PropTypes.bool,
    isPreview: PropTypes.bool,
};

export default ProgressiveImage;
