// Utils
import { propIn } from '../../../common/utils/immutableHelper';

// Helper
export const getDeepProp = (propArray) => {
    const propName = propArray[0];
    const getter = propIn(propArray.slice(1));
    return (props) => getter(props[propName]);
};

// Basic value comparison functions
const hasChangedComparisonFn = (oldVal, newVal) => oldVal !== newVal;
const hasNotChangedComparisonFn = (oldVal, newVal) => oldVal === newVal;
const noLongerComparisonFn = (oldVal, newVal) => oldVal && !newVal;
const nowComparisonFn = (oldVal, newVal) => !oldVal && newVal;
const stayedTrueComparisonFn = (oldVal, newVal) => oldVal && newVal;
const stayedFalseComparisonFn = (oldVal, newVal) => !oldVal && !newVal;

// Abstract comparison functions
const simplePropComparison = (comparisonFn) => (propName) => (oldProps, newProps) =>
    comparisonFn(oldProps[propName], newProps[propName]);

const deepPropComparison = (comparisonFn) => (propArray) => {
    const deepPropGetter = getDeepProp(propArray);
    return (oldProps, newProps) => comparisonFn(deepPropGetter(oldProps), deepPropGetter(newProps));
};

// Specific implementations
// Keeping these because there might be a slight performance gain
export const hasChanged = simplePropComparison(hasChangedComparisonFn);
export const hasNotChanged = simplePropComparison(hasNotChangedComparisonFn);
export const noLonger = simplePropComparison(noLongerComparisonFn);
export const now = simplePropComparison(nowComparisonFn);
export const stayedTrue = simplePropComparison(stayedTrueComparisonFn);
export const stayedFalse = simplePropComparison(stayedFalseComparisonFn);

export const deepPropHasChanged = deepPropComparison(hasChangedComparisonFn);
export const deepPropHasNotChanged = deepPropComparison(hasNotChangedComparisonFn);
export const deepPropNoLonger = deepPropComparison(noLongerComparisonFn);
export const deepPropNow = deepPropComparison(nowComparisonFn);
export const deepPropStayedTrue = deepPropComparison(stayedTrueComparisonFn);
export const deepPropStayedFalse = deepPropComparison(stayedFalseComparisonFn);
