// Lib
import React, { ReactElement } from 'react';

// Utils
import { getLinkUrl } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import LinkInput from './LinkInput';
import LinkStateDisplay from './LinkStateDisplay';

// Styles
import './LinkEditor.scss';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { ImElementLocalData } from '../local/elementLocalDataTypes';
import { AttachmentState } from '../attachments/attachmentTypes';

type LinkEditorProps = {
    element: ImMNElement;
    elementLocalData: ImElementLocalData;
    attachment: AttachmentState;

    isSelected: boolean;
    isEditing: boolean;
    shouldFocusOnlyWhenSelected: boolean;
    isSingleSelected: boolean;

    setLinkUrl: Function;
    setLocalData: Function;
    startEditing: Function;
    stopEditing: Function;
    moveElementsToTrash: Function;
    deselectAll: Function;
};

const LinkEditor = (props: LinkEditorProps): ReactElement | null => {
    const isLoaded = getLinkUrl(props.element);

    if (isLoaded) return null;

    return (
        <div className="LinkEditor">
            <LinkStateDisplay {...props} />
            <LinkInput {...props} />
        </div>
    );
};

export default LinkEditor;
