// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getMainEditorKey } from '../utils/elementEditorUtils';

// Components
import EditableTitle from '../../components/editableTitle/EditableTitle';

const DocumentEditableTitle = (props) => {
    const { element, isSingleSelected, filterQuery } = props;

    const elementId = getElementId(element);
    const editorKey = getMainEditorKey(props);

    return (
        <EditableTitle
            {...props}
            elementId={elementId}
            initialValue="New Document"
            selectFirst
            isSelected={isSingleSelected}
            selectAllOnEdit
            editorKey={editorKey}
            filterQuery={filterQuery}
            editDelay={platformSingleton.features.isTouch ? 0 : 100}
        />
    );
};

DocumentEditableTitle.propTypes = {
    element: PropTypes.object.isRequired,
    isSingleSelected: PropTypes.bool,
    filterQuery: PropTypes.string,
};

export default DocumentEditableTitle;
