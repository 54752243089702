import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationLiveIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        viewBox="0 0 14 14"
        className="Icon notification-live"
        {...props}
    >
        <circle cx={7} cy={7} r={6} fill="#2f63f7" fillRule="evenodd" stroke="#FFF" strokeWidth={2} />
    </svg>
);
const Memo = memo(NotificationLiveIcon);
export default Memo;
