import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-image"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z" />
            <path
                fill="#767C86"
                d="M12.375 1A2.625 2.625 0 0 1 15 3.625v8.75A2.625 2.625 0 0 1 12.375 15h-8.75A2.625 2.625 0 0 1 1 12.375v-8.75A2.625 2.625 0 0 1 3.625 1h8.75zM6.25 9.803l-3.473 3.474a.853.853 0 0 1-.056.049c.234.224.553.361.904.361h8.75c.35 0 .67-.137.905-.361l-.057-.05-2.16-2.16-1.286 1.286a.658.658 0 0 1-.929 0L6.25 9.803zm6.125-7.49h-8.75c-.725 0-1.313.587-1.313 1.312v8.259l3.474-3.473c.23-.23.59-.254.847-.07l.081.07 2.598 2.598 1.286-1.286a.658.658 0 0 1 .929 0l2.16 2.161V3.625c0-.725-.587-1.313-1.312-1.313zm-2.188 1.312a2.188 2.188 0 1 1-.002 4.376 2.188 2.188 0 0 1 .003-4.376zm0 1.313a.874.874 0 1 0 0 1.75.876.876 0 1 0 0-1.75z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationImageIcon);
export default Memo;
