// Lib
import { negate } from 'lodash';
import { keyBy, first } from 'lodash/fp';

// Utils
import { asObject, prop } from '../../../../../../common/utils/immutableHelper';
import { isLine } from '../../../../../../common/elements/utils/elementTypeUtils';
import { distanceFromAnySide } from '../../../../../../common/maths/geometry/rect';
import { getNewTransactionId } from '../../../../../utils/undoRedo/undoRedoTransactionManager';
import { isLocationCanvas } from '../../../../../../common/elements/utils/elementLocationUtils';
import {
    getElementId,
    getLineEndConnectedElementId,
    getLineStartConnectedElementId,
    getLocationPosition,
} from '../../../../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getCurrentBoardChildren } from '../../../../../element/selectors/currentBoardSelector';
import { getCurrentBoardIdFromState } from '../../../../../reducers/currentBoardId/currentBoardIdSelector';
import { getMeasurementsMap } from '../../../../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Actions
import { createAndEditElement } from '../../../../../element/actions/elementActions';

// Constants
import { LINE_MARKER_STYLE } from '../../../../../../common/lines/lineConstants';
import { BoardSections } from '../../../../../../common/boards/boardConstants';
import { ElementType } from '../../../../../../common/elements/elementTypes';

const keyById = keyBy('id');

const sortByDistance = (edgeA, edgeB) => edgeB.distance - edgeA.distance;

/**
 * Build a graph structure of the form:
 * {
 *     [elementId]: [
 *         { nodes: [elementId, neighbourId], distance },
 *         ...
 *     ],
 *     ...
 * }
 *
 * It's a map of element IDs to their edges (distances) to all other element IDs in the list.
 */
const getFullDistanceGraph = (elementIds, measurementsMap) => {
    const visitedNodes = {};
    const graph = {};

    for (const elementId of elementIds) {
        visitedNodes[elementId] = true;
        const elementRect = prop(elementId, measurementsMap);

        for (const neighbourId of elementIds) {
            if (visitedNodes[neighbourId]) continue;

            const neighbourRect = prop(neighbourId, measurementsMap);

            const distance = distanceFromAnySide(elementRect, neighbourRect);

            const edge = { nodes: [elementId, neighbourId], distance };

            graph[elementId] = graph[elementId] || [];
            graph[elementId].push(edge);

            graph[neighbourId] = graph[neighbourId] || [];
            graph[neighbourId].push(edge);
        }
    }

    return graph;
};

/**
 * Uses Prim's algorithm along with the graph built using "getFullDistanceGraph" to determine the
 * Minimum Spanning Tree (MST).
 */
const primsAlgorithm = (graph) => {
    const graphNodeIds = Object.keys(graph);
    let unvisitedNodesCount = graphNodeIds.length;

    const visitedNodes = {};

    // Arbitrarily choose a starting point
    const startingNodeId = first(graphNodeIds);
    let edgesToCheck = graph[startingNodeId];

    const mst = [];

    edgesToCheck = edgesToCheck.sort(sortByDistance);

    while (unvisitedNodesCount > 0 && edgesToCheck.length > 0) {
        const currentShortestEdge = edgesToCheck.pop();

        const node1 = currentShortestEdge.nodes[0];
        const node2 = currentShortestEdge.nodes[1];

        if (visitedNodes[node1] && visitedNodes[node2]) continue;

        // eslint-disable-next-line no-loop-func
        currentShortestEdge.nodes.forEach((nodeId) => {
            if (visitedNodes[nodeId]) return;

            visitedNodes[nodeId] = true;

            edgesToCheck = edgesToCheck.concat(graph[nodeId]);
        });

        mst.push(currentShortestEdge);
        unvisitedNodesCount = unvisitedNodesCount - 1;

        edgesToCheck = edgesToCheck.sort(sortByDistance);
    }

    return mst;
};

/**
 * Connects the specified elements by finding the MST and creating lines for each edge.
 */
export const connectElementsWithLines =
    (elements) =>
    (dispatch, getState, { measurementsStore }) => {
        const measurementsMap = asObject(getMeasurementsMap(measurementsStore.getState()));

        const state = getState();
        const currentBoardId = getCurrentBoardIdFromState(state);
        const currentBoardChildren = getCurrentBoardChildren(state);
        // Only check lines connected on both ends
        const currentBoardLines = currentBoardChildren.filter(
            (el) => isLine(el) && !!getLineStartConnectedElementId(el) && !!getLineEndConnectedElementId(el),
        );

        const filteredElements = elements.filter(negate(isLine)).filter(isLocationCanvas).toJS();

        const filteredElementsMap = keyById(filteredElements);
        const filteredElementIds = filteredElements.map(getElementId);

        const transactionId = getNewTransactionId();

        const graph = getFullDistanceGraph(filteredElementIds, measurementsMap);
        const mst = primsAlgorithm(graph);

        for (const newLineEdge of mst) {
            const startElementId = newLineEdge.nodes[0];
            const endElementId = newLineEdge.nodes[1];

            // Don't create a line if there's already a line connecting the two elements
            // eslint-disable-next-line no-loop-func
            const alreadyHasConnectedLine = currentBoardLines.some((line) => {
                const startConnectionId = getLineStartConnectedElementId(line);
                const endConnectionId = getLineEndConnectedElementId(line);

                return (
                    (startConnectionId === startElementId || startConnectionId === endElementId) &&
                    (endConnectionId === startElementId || endConnectionId === endElementId)
                );
            });

            // No need to create a new connection
            if (alreadyHasConnectedLine) continue;

            const startEl = filteredElementsMap[startElementId];
            const endEl = filteredElementsMap[startElementId];

            const startElPosition = getLocationPosition(startEl);
            const endElPosition = getLocationPosition(endEl);

            const start = {
                x: startElPosition?.x,
                y: startElPosition?.y,
                snapped: true,
                elementId: startElementId,
            };

            const end = {
                x: endElPosition?.x,
                y: endElPosition?.y,
                snapped: true,
                elementId: endElementId,
            };

            const newLine = {
                elementType: ElementType.LINE_TYPE,
                location: {
                    parentId: currentBoardId,
                    position: {
                        x: startElPosition?.x,
                        y: startElPosition?.y,
                    },
                    section: BoardSections.CANVAS,
                },
                content: {
                    start,
                    end,
                    startStyle: LINE_MARKER_STYLE.NONE,
                    endStyle: LINE_MARKER_STYLE.NONE,
                },
                currentBoardId,
                select: false,
                edit: false,
                transactionId,
            };

            dispatch(createAndEditElement(newLine));
        }
    };
