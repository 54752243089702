export const INTERCOM_OPERATIONS = {
    trackEvent: 'trackEvent',
    boot: 'boot',
    shutdown: 'shutdown',
    update: 'update',
    show: 'show',
    onUnreadCountChange: 'onUnreadCountChange',
};

export const INTERCOM_SHOW_MESSAGES = 'INTERCOM_SHOW_MESSAGES';
export const INTERCOM_SHOW_HELP_CENTER = 'INTERCOM_SHOW_HELP_CENTER';
