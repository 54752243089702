// Lib
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { Events } from 'handsontable/pluginHooks';
import Handsontable from 'handsontable/base';
import useDebouncedCallback from '../../../utils/react/useDebouncedCallback';

// Types
import TableOperations from './TableOperations';

// Constants
import { TABLE_DEFAULT_COL_WIDTH, TABLE_DEFAULT_ROW_HEIGHT } from '../../../../common/table/tableConstants';
import usePrevious from '../../../utils/react/usePrevious';

interface Props {
    elementId: string;
    gridSize: number;
    mounted: boolean;

    dispatchUpdateTableElement: (args: object) => void;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
    tableOperationsRef: React.RefObject<TableOperations>;
}

interface RefType {
    afterColumnResize: Events['afterColumnResize'];
}

const TableColumnResizeHandlers = forwardRef<RefType, Props>(function TableColumnResizeHandlersComponent(props, ref) {
    const { elementId, gridSize, mounted, dispatchUpdateTableElement, hotTableInstanceRef, tableOperationsRef } = props;

    const prevGridSize = usePrevious(gridSize);

    /**
     * Update row height when grid size changes
     */
    useEffect(() => {
        if (!mounted) return;

        const manualRowResizePlugin = hotTableInstanceRef.current?.getPlugin<'manualRowResize'>('manualRowResize');

        if (!hotTableInstanceRef.current || !manualRowResizePlugin || !prevGridSize) return;

        const nRows = hotTableInstanceRef.current.countRows();
        for (let row = 0; row < nRows; row++) {
            const currentHeightPx = hotTableInstanceRef.current.getRowHeight(row);
            const currentHeightGU = Math.ceil(currentHeightPx / prevGridSize);

            const borderTopOffset = row === 0 ? 1 : 0;
            const offset = row === 0 || row === nRows - 1 ? 1 : 0;

            manualRowResizePlugin.setManualSize(row, currentHeightGU * gridSize - borderTopOffset - offset);
        }
    }, [gridSize]);

    const afterColumnResize = useDebouncedCallback(
        (newSize: number, column: number, isDoubleClick: boolean): void => {
            const manualColumnResizePlugin =
                hotTableInstanceRef.current?.getPlugin<'manualColumnResize'>('manualColumnResize');

            if (!manualColumnResizePlugin) return;

            const hotTableData = tableOperationsRef.current?.getHotTableData();
            const hotColWidthsGU = tableOperationsRef.current?.getHotColWidthsGU();

            if (!hotColWidthsGU) return;

            dispatchUpdateTableElement({
                id: elementId,
                changes: {
                    tableContent: {
                        data: hotTableData,
                        colWidthsGU: hotColWidthsGU,
                    },
                },
            });
        },
        10,
        [gridSize, elementId],
    );

    /**
     * Keep the min col width value accurate in the hot table.
     */
    const getDefaultColWidth = useCallback(() => {
        return TABLE_DEFAULT_COL_WIDTH * gridSize;
    }, [gridSize]);
    /**
     * Keep the min col width value accurate in the hot table.
     */
    const getDefaultRowHeight = useCallback(
        (index) => {
            if (!hotTableInstanceRef.current) return;
            const nRows = hotTableInstanceRef.current.countRows();
            const borderTopOffset = index === 0 ? 1 : 0;
            const offset = index === 0 || index === nRows - 1 ? 1 : 0;
            return TABLE_DEFAULT_ROW_HEIGHT * gridSize - borderTopOffset - offset;
        },
        [gridSize],
    );

    useImperativeHandle(ref, () => ({ afterColumnResize, getDefaultColWidth, getDefaultRowHeight }), [
        afterColumnResize,
        getDefaultColWidth,
        getDefaultRowHeight,
    ]);

    return null;
});

export default TableColumnResizeHandlers;
