// Lib
import { useCallback } from 'react';

// Utils
import { DOM_POINTER_TYPES } from '../../../../utils/domConstants';

export default (touchBlockEnabled, pointerEventHandler) =>
    useCallback(
        (event, ...rest) => {
            if (touchBlockEnabled && event.pointerType !== DOM_POINTER_TYPES.PEN) return;
            return pointerEventHandler(event, ...rest);
        },
        [touchBlockEnabled, pointerEventHandler],
    );
