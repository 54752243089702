// Lib
import { memoize } from 'lodash';

// Utils
import { asObject } from '../../../../common/utils/immutableHelper';
import { getToolbarItemUID } from './toolbarAvailableToolsUtils';
import { getToolbarHash } from './toolbarGeneralUtils';

// Constants
import { GRID } from '../../../utils/grid/gridConstants';
import { TOOLBAR_TOOLS } from '../config/toolbarToolConfig';
import { COLLAPSE_ORDER } from '../config/toolbarToolConfigConstants';

// Types
import { MoreToolsSettings, ToolbarItemConfig } from '../toolbarTypes';
import { ImMNUser } from '../../../../common/users/userModelTypes';
import { UserToolsPreference, getPrimaryToolPreference } from './primaryToolsUtils';

const getItemPadding = memoize((gridSizeName: string): number => {
    switch (gridSizeName) {
        case GRID.SMALL.name:
            return 13;
        case GRID.MEDIUM.name:
            return 18;
        case GRID.LARGE.name:
        default:
            return 18;
    }
});

/**
 * Returns the total height of the tool list, including padding between tools.
 */
const getTotalToolListHeight = (toolList: ToolbarItemConfig[], gridSizeName: string) =>
    toolList.reduce((acc, item) => {
        return (
            acc + (item.height[gridSizeName as keyof ToolbarItemConfig['height']] || 0) + getItemPadding(gridSizeName)
        );
    }, 0);

const getCollapseOrder = (tool: ToolbarItemConfig, toolList: ToolbarItemConfig[]): number => {
    if (!tool.collapseOrder) return 0;

    if (typeof tool.collapseOrder === 'function') {
        return tool.collapseOrder(toolList);
    }

    return tool.collapseOrder;
};

/**
 * Returns lists of tools that should always be collapsed, and tools that should be passed to the next step.
 */
const getAlwaysCollapsedItems = (
    itemList: ToolbarItemConfig[],
): {
    renderedItems: ToolbarItemConfig[];
    collapsedItems: ToolbarItemConfig[];
} => {
    const collapsedItems = itemList.filter((tool) => getCollapseOrder(tool, itemList) >= COLLAPSE_ORDER.ALWAYS);
    const renderedItems = itemList.filter((tool) => getCollapseOrder(tool, itemList) < COLLAPSE_ORDER.ALWAYS);

    return {
        renderedItems,
        collapsedItems,
    };
};

/**
 * Sorts tools by collapse order, with the highest collapse order first.
 * Any tools with a zero or undefined collapse order will never be collapsed, so are removed from the list.
 */
const sortItemsByCollapseOrder = (itemList: ToolbarItemConfig[]): ToolbarItemConfig[] => {
    return [...itemList]
        .filter((tool) => getCollapseOrder(tool, itemList) > 0)
        .reverse()
        .sort((a, b) => {
            const collapseOrderA = getCollapseOrder(a, itemList);
            const collapseOrderB = getCollapseOrder(b, itemList);

            if (collapseOrderA < collapseOrderB) return 1;
            if (collapseOrderA > collapseOrderB) return -1;
            return 0;
        });
};

const getShouldCollapse = (tool: ToolbarItemConfig): boolean => (tool.collapseBehavior || 'collapse') === 'collapse';

const hasVisibleCollapsedItems = (collapsedItems: ToolbarItemConfig[]): boolean =>
    collapsedItems.length > 0 && collapsedItems.some(getShouldCollapse);

const getConfiguredMoreTool = (itemList: ToolbarItemConfig[]): ToolbarItemConfig | undefined =>
    itemList.find((item) => item.id === TOOLBAR_TOOLS.META_MORE.id);

/**
 * If there are collapsed items that should be available, add a MoreTool to the end of the list if one is not already present.
 * If there are no collapsed items that should be available, remove the MoreTool from the list.
 * Adds the collapsed items to the MoreTool's toolSettings.
 */
const collapseItemsIntoMoreTool = (
    renderedItems: ToolbarItemConfig[],
    collapsedItems: ToolbarItemConfig[],
): ToolbarItemConfig[] => {
    // if there's no collapsed items, remove the MoreTool from the list
    if (!hasVisibleCollapsedItems(collapsedItems)) {
        return renderedItems.filter((item) => item.id !== TOOLBAR_TOOLS.META_MORE.id);
    }

    // if the current toolList is configured with a MoreTool,
    // update the MoreTool's toolSettings with the collapsed items + popupId
    const collapsableItems = collapsedItems.filter(getShouldCollapse);
    const collapsableItemHash = getToolbarHash(collapsableItems).toString();

    const configuredMoreTool = getConfiguredMoreTool(renderedItems);
    if (configuredMoreTool) {
        configuredMoreTool.uid = getToolbarItemUID(configuredMoreTool.id, collapsableItemHash);
        configuredMoreTool.toolSettings = {
            ...configuredMoreTool.toolSettings,
            items: collapsableItems,
        } as MoreToolsSettings;

        return renderedItems;
    }

    // otherwise, create a new MoreTool and add it to the end of the list
    const moreTool: ToolbarItemConfig = {
        ...TOOLBAR_TOOLS.META_MORE,
        uid: getToolbarItemUID(TOOLBAR_TOOLS.META_MORE.id, collapsableItemHash),
        toolSettings: {
            ...TOOLBAR_TOOLS.META_MORE.toolSettings,
            items: collapsableItems,
        } as MoreToolsSettings,
    };

    return [...renderedItems, moreTool];
};

/**
 * Removes tools in collapse order until the total height of the tool list is <= the available height.
 */
export const collapseItemsToFit = (
    itemList: ToolbarItemConfig[],
    availableHeight: number,
    gridSizeName: string,
): ToolbarItemConfig[] => {
    const { renderedItems, collapsedItems } = getAlwaysCollapsedItems(itemList);

    // If the tool list is already smaller than the available height, don't collapse anything.
    if (getTotalToolListHeight(renderedItems, gridSizeName) <= availableHeight) {
        return collapseItemsIntoMoreTool(renderedItems, collapsedItems);
    }

    const collapseOrderedItems = sortItemsByCollapseOrder(itemList);

    // Remove tools from the end of the list until the total height is less than the available height.
    while (renderedItems.length && getTotalToolListHeight(renderedItems, gridSizeName) > availableHeight) {
        const collapsedTool = collapseOrderedItems.shift();
        if (!collapsedTool) break;

        const index = renderedItems.findIndex((tool) => tool.id === collapsedTool.id);
        if (index === -1) continue;

        collapsedItems.push(collapsedTool);
        renderedItems.splice(index, 1);
    }

    return collapseItemsIntoMoreTool(renderedItems, collapsedItems);
};

/**
 * Adjusts the collapse order based on the user's toolbar preferences.
 * No preference = use the tool's default collapse order.
 */
export const adjustItemCollapseOrderForUserPreferences = (
    items: ToolbarItemConfig[],
    currentUser: ImMNUser,
): ToolbarItemConfig[] => {
    const userToolsPreferences: UserToolsPreference = asObject(getPrimaryToolPreference(currentUser));

    return items.map((item) => {
        const toolPreference = userToolsPreferences?.[item.id];

        // if the user has no preference, use the tool's default collapse order
        if (typeof toolPreference === 'undefined') return item;

        const itemDefaultCollapseOrder = Math.min(getCollapseOrder(item, items), 99);
        const collapseOrder = toolPreference === true ? itemDefaultCollapseOrder : COLLAPSE_ORDER.ALWAYS;

        return {
            ...item,
            collapseOrder,
        };
    });
};

const TOOLBAR_SMALL_SIZE_BREAKPOINT = 680;

/**
 * Adjusts the gridSize used for the toolbar based on the available height.
 */
export const getAdjustedToolbarGridSize = (
    gridSizeName: string,
    availableHeight: number,
): {
    size: number;
    breakpoint: number;
    name: string;
} => {
    if (availableHeight < TOOLBAR_SMALL_SIZE_BREAKPOINT) return GRID.SMALL;

    switch (gridSizeName) {
        case GRID.SMALL.name:
            return GRID.SMALL;
        case GRID.MEDIUM.name:
            return GRID.MEDIUM;
        case GRID.LARGE.name:
        default:
            return GRID.LARGE;
    }
};
