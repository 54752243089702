// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';

// Components
import ActivityIndicator from './ActivityIndicator';
import { individualCommentActivitySelector } from './selectors/boardActivitySelector';

// Styles
import './CommentActivityIndicator.scss';

const mapStateToProps = () =>
    createStructuredSelector({
        commentActivity: individualCommentActivitySelector(),
    });

const CommentActivityIndicator = (props) => {
    const { commentActivity, _id, isSelected } = props;

    if (isEmpty(commentActivity)) return null;

    return <ActivityIndicator commentId={_id} toggleVal={isSelected} commentActivity={commentActivity} />;
};

CommentActivityIndicator.propTypes = {
    _id: PropTypes.string,
    commentActivity: PropTypes.object,
    isSelected: PropTypes.bool,
};

export default connect(mapStateToProps)(CommentActivityIndicator);
