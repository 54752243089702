// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import fileSupportsModalView from '../../element/file/utils/fileSupportsModalView';

// Actions
import { navigateToElement } from '../../reducers/navigationActions';

// Components
import LinkButton from './LinkButton';

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigateToElement: (elementId) => dispatch(navigateToElement({ elementId })),
});

const OpenLinkInElementModalButton = ({ children, divider, className, element, dispatchNavigateToElement }) => {
    const onClick = (event) => {
        if (fileSupportsModalView(element)) {
            const elementId = getElementId(element);

            dispatchNavigateToElement(elementId);
        }

        event && event.preventDefault();
        event && event.stopPropagation();
    };

    return (
        <span>
            {divider}

            <LinkButton
                onClick={onClick}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('DownloadOpenLinkButton', className)}
            >
                {children}
            </LinkButton>
        </span>
    );
};

OpenLinkInElementModalButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    divider: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    className: PropTypes.string,
    element: PropTypes.object,
    dispatchNavigateToElement: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(OpenLinkInElementModalButton);
