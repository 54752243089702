import { ContentState, SelectionState } from 'draft-js';

const getSelectionStateForContent = (contentState: ContentState): SelectionState => {
    const startBlock = contentState.getFirstBlock();
    const endBlock = contentState.getLastBlock();

    return new SelectionState({
        anchorKey: startBlock.getKey(),
        anchorOffset: 0,
        focusKey: endBlock.getKey(),
        focusOffset: endBlock.getText().length,
    });
};

export default getSelectionStateForContent;
