import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarMoreIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={16}
        viewBox="0 0 32 16"
        className="Icon toolbar-more"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={16} fill="#E3E4E6" rx={4} />
            <path
                fill="#323B4A"
                d="M9 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm7 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarMoreIcon);
export default Memo;
