// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import BackgroundImage from '../../../../../../components/images/BackgroundImage';

const IconToolImage = ({ imageSource }) => <BackgroundImage className="IconToolImage" source={imageSource} />;

IconToolImage.propTypes = {
    imageSource: PropTypes.string.isRequired,
};

export default IconToolImage;
