// Utils
import {
    getColor,
    getContent,
    getElementId,
    getIcon,
    getImageProp,
    getOriginalColor,
    getOriginalIcon,
    getOriginalImage,
    getOriginalSecondaryColor,
    getOriginalTitle,
    getSecondaryColor,
    getTitle,
} from '../elements/utils/elementPropertyUtils';
import { asObject } from '../utils/immutableHelper';

export const getAliasContentFromBoard = (board) => ({
    linkTo: getElementId(board),
    originalColor: getColor(board),
    originalSecondaryColor: getSecondaryColor(board),
    originalTitle: getTitle(board),
    originalIcon: getIcon(board),
    originalImage: getImageProp(board),
});

export const getOriginalAliasContent = (alias) => ({
    title: getOriginalTitle(alias),
    color: getOriginalColor(alias),
    secondaryColor: getOriginalSecondaryColor(alias),
    icon: getOriginalIcon(alias),
    image: getOriginalImage(alias),
});

export const getBoardContentFromAlias = (alias, linkedBoard) => ({
    ...asObject(getContent(linkedBoard)),
    title: getTitle(alias) || getTitle(linkedBoard) || getOriginalTitle(alias),
    color: getColor(alias) || getColor(linkedBoard) || getOriginalColor(alias),
    secondaryColor: getSecondaryColor(alias) || null, // if secondary is null we need to reflect that null value in the new board
    icon: asObject(getIcon(alias)) || asObject(getIcon(linkedBoard)) || getOriginalIcon(alias),
    image: asObject(getImageProp(alias)) || asObject(getImageProp(linkedBoard)) || getOriginalImage(alias),
});
