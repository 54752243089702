import { FIELD_TYPES } from '../validationConstants';
import emailValidator from './type/emailValidator';
import multiEmailValidator from './type/multiEmailValidator';
import passwordValidator from './type/passwordValidator';
import dateValidator from './type/dateValidator';
import selectValidator from './type/selectValidator';
import urlValidator from './type/urlValidator';
import elementIdValidator from './type/elementIdValidator';
import htmlValidator from './type/htmlValidator';
import objectValidator from './type/objectValidator';
import objectIdValidator from './type/objectIdValidator';
import numberValidator from './type/numberValidator';
import userTextValidator from './type/userTextValidator';

const TYPE_VALIDATORS = {
    [FIELD_TYPES.userText]: userTextValidator,
    [FIELD_TYPES.email]: emailValidator,
    [FIELD_TYPES.multiEmail]: multiEmailValidator,
    [FIELD_TYPES.password]: passwordValidator,
    [FIELD_TYPES.date]: dateValidator,
    [FIELD_TYPES.select]: selectValidator,
    [FIELD_TYPES.url]: urlValidator,
    [FIELD_TYPES.elementId]: elementIdValidator,
    [FIELD_TYPES.html]: htmlValidator,
    [FIELD_TYPES.object]: objectValidator,
    [FIELD_TYPES.objectId]: objectIdValidator,
    [FIELD_TYPES.number]: numberValidator,
};

/**
 */
export default (fieldValue, validationConfig) => {
    // If we specifically don't want to do type validation, then immediately return null
    if (validationConfig.disableTypeValidation) return null;

    const typeValidator = TYPE_VALIDATORS[validationConfig.type];

    return typeValidator ? typeValidator(fieldValue, validationConfig) : null;
};
