import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarStrikethroughIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-strikethrough"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#1B2536"
                d="M16.442 10c2.366 0 4.015 1.354 4.066 3.219h-1.695c-.125-1.075-1.075-1.7-2.411-1.7-1.433 0-2.338.733-2.338 1.723-.005 1.114 1.206 1.54 2.116 1.774l1.138.296c.58.143 1.223.355 1.788.688H24.5a.5.5 0 1 1 0 1h-4.292c.285.425.46.946.46 1.588 0 1.973-1.576 3.412-4.312 3.412-2.61 0-4.242-1.302-4.356-3.412h1.763c.102 1.268 1.228 1.87 2.582 1.87 1.496 0 2.582-.75 2.582-1.887 0-.836-.626-1.264-1.522-1.571H8.5a.5.5 0 1 1 0-1l5.677.001c-1.174-.556-1.864-1.407-1.864-2.657 0-2.008 1.791-3.344 4.129-3.344z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarStrikethroughIcon);
export default Memo;
