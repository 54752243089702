// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import { getHeaderAvoidanceInsets } from '../../components/tooltips/tooltipInsetUtils';

// Selectors
import { isPopupIdOpenSelector } from '../../components/popupPanel/popupOpenSelector';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getCurrentBoard } from '../selectors/currentBoardSelector';

// Actions
import { closePopup } from '../../components/popupPanel/popupActions';

// Components
import PopupPanelMouseManager from '../../components/popupPanel/PopupPanelMouseManager';
import FloatingPanel from '../../components/tooltips/FloatingPanel';
import ReactionPopup from '../../components/emoji/popup/ReactionPopup';

// Constants
import { TooltipPositions } from '../../components/tooltips/tooltipConstants';

const mapStateToProps = createStructuredSelector({
    isPopupOpen: isPopupIdOpenSelector,
    gridSize: getGridSize,
    currentBoard: getCurrentBoard,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchClosePopup: (popupId) => dispatch(closePopup(popupId, false)),
});

const ElementReactionPopup = (props) => {
    const { className, popupId, isPopupOpen, dispatchClosePopup, gridSize, currentBoard } = props;

    if (!isPopupOpen) return null;

    const closeReactionPopup = () => dispatchClosePopup(popupId);

    return (
        <FloatingPanel
            selector={`.popup-trigger-${popupId}`}
            className={classNames('ReactionPopup', className, 'light')}
            arrowColor="var(--ui-modal-background-primary)"
            arrowStrokeColor="var(--ui-background-primary)"
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={0}
            arrowStyle={{ transform: 'translate(0px, 1px)' }}
            distance={15}
            offset={2}
            position={TooltipPositions.RIGHT}
            gridSize={gridSize}
            responsive
            pollPosition
            insets={getHeaderAvoidanceInsets(gridSize, currentBoard)}
        >
            <PopupPanelMouseManager {...props} visible={isPopupOpen} popupId={popupId} closePopup={closeReactionPopup}>
                <div className="popup-content">
                    <ReactionPopup {...props} closePopup={closeReactionPopup} />
                </div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

ElementReactionPopup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    currentBoard: PropTypes.object,
    popupId: PropTypes.string.isRequired,
    isPopupOpen: PropTypes.bool,
    dispatchClosePopup: PropTypes.func,
    gridSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementReactionPopup);
