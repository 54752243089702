// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get, isEmpty } from 'lodash/fp';
import classNames from 'classnames';

// Selector
import { individualBoardActivitySelector } from './selectors/boardActivitySelector';

// Components
import elementActivityPoiDecorator from './elementActivityPoiDecorator';
import TextActivityIndicator from './TextActivityIndicator';

// Constants
import { ELEMENT_ACTIVITY_TYPES } from './elementActivityConstants';

// Styles
import './BoardActivityIndicator.scss';

const PoiTextActivityIndicator = elementActivityPoiDecorator(TextActivityIndicator);

const getActivityText = ({ elementActivity }) => {
    const activityType = get('type', elementActivity);

    switch (activityType) {
        case ELEMENT_ACTIVITY_TYPES.CHANGE:
            return get('changeCount', elementActivity);
        case ELEMENT_ACTIVITY_TYPES.ADD:
        case ELEMENT_ACTIVITY_TYPES.SHARE:
            return 'New';
        default:
            return null;
    }
};

const mapStateToProps = () =>
    createStructuredSelector({
        elementActivity: individualBoardActivitySelector(),
    });

const BoardActivityIndicator = (props) => {
    const { elementActivity, indicatorType } = props;

    if (isEmpty(elementActivity)) return null;

    const count = get('changeCount', elementActivity) || 1;

    const activityText = getActivityText(props);

    return (
        <PoiTextActivityIndicator
            {...props}
            poiData={{ count }}
            className={classNames('BoardActivityIndicator', indicatorType)}
        >
            {activityText}
        </PoiTextActivityIndicator>
    );
};

BoardActivityIndicator.propTypes = {
    elementActivity: PropTypes.object,
    indicatorType: PropTypes.string,
};

export default connect(mapStateToProps)(BoardActivityIndicator);
