import { startsWith } from 'lodash/fp';

// Utils
import getClientConfig from '../../../../utils/getClientConfig';

import { isElementId } from '../../../../../common/uid/idValidator';
import { getBoardIdFromFullUrl, getIsMilanoteDomain } from '../../../../../common/utils/urlUtil';

const clientConfig = getClientConfig();

const getIsBoardLink = (url) =>
    (startsWith(clientConfig.urlBase, url) || getIsMilanoteDomain(url)) && isElementId(getBoardIdFromFullUrl(url));

export const getLinkTarget = ({ href, target }) => (getIsBoardLink(href) ? '_self' : target);
