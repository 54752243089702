// Lib
import { NativeTypes } from 'react-dnd-html5-backend';

// Constants
import { ElementType } from '../../../../common/elements/elementTypes';

export default (elementPredicates) =>
    ({ monitor }) => {
        const itemType = monitor.getItemType();

        // Don't fail for files, text or urls unless they're aren't allowed
        if (itemType === NativeTypes.FILE)
            return !elementPredicates.some((predicate) => predicate(ElementType.FILE_TYPE));
        if (itemType === NativeTypes.TEXT)
            return !elementPredicates.some((predicate) => predicate(ElementType.CARD_TYPE));
        if (itemType === NativeTypes.URL)
            return !elementPredicates.some((predicate) => predicate(ElementType.LINK_TYPE));

        const item = monitor.getItem();
        return (
            item.element &&
            item.draggedElements.filter((element) => !elementPredicates.some((predicate) => predicate(element)))
                .length > 0
        );
    };
