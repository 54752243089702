// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ErrorBoundary from '../../error/errorBoundary/ErrorBoundary';
import PopupErrorRenderer from './PopupErrorRenderer';

const getErrorMessage = (error, info, props) => `Error rendering ${props.popupId} popup: ${error.toString()}`;

const getRollbarErrorDetails = (error, info, props) => ({
    popupId: props.popupId,
    stack: info.componentStack,
});

const PopupErrorBoundary = (props) => (
    <ErrorBoundary
        {...props}
        ErrorRenderer={PopupErrorRenderer}
        getErrorMessage={getErrorMessage}
        getRollbarErrorDetails={getRollbarErrorDetails}
    />
);

PopupErrorBoundary.propTypes = {
    popupId: PropTypes.string.isRequired,
};

export default PopupErrorBoundary;
