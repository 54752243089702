/*  ### Creating HYBRID_ELEMENT_SELECT actions
 *
 * Forwards all properties of the provided original action
 *
 */

import { AnyAction } from 'redux';
import { HYBRID_ACTION_TYPES, HybridElementSelectedAction } from '../../hybridMiddlewareConstants';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { getPhysicalId } from '../../../../../../common/elements/utils/elementPropertyUtils';

export const createHybridElementSelectedAction = (
    action: AnyAction,
    element: MNElement,
): HybridElementSelectedAction | undefined => {
    const hybridAction: HybridElementSelectedAction = {
        ...action,
        id: getPhysicalId(element),
        type: HYBRID_ACTION_TYPES.ELEMENT_SELECTED,
    };
    return hybridAction;
};
