/**
 * This is an implementation of idb-keyval based on idb, as described on https://www.npmjs.com/package/idb
 */
// Lib
import { IDBPDatabase, openDB } from 'idb';

// NOTE: This is named this way to match the original idb-keyval library
const DATABASE_NAME = 'keyval-store';
const STORE_NAME = 'keyval';

const createKeyvalIdb = () =>
    openDB(DATABASE_NAME, 1, {
        upgrade(db) {
            db.createObjectStore(STORE_NAME);
        },
    });

let dbPromise: Promise<IDBPDatabase<unknown>> | null = null;

export const initKeyvalIdb = async () => {
    if (!dbPromise) {
        dbPromise = createKeyvalIdb();
    }

    return dbPromise;
};

// This is the same as initKeyvalIdb but named differently to be clear about its purpose
const getKeyvalIdb = initKeyvalIdb;

export const get = async (key: string) => (await getKeyvalIdb()).get(STORE_NAME, key);

export const set = async (key: string, val: unknown) => (await getKeyvalIdb()).put(STORE_NAME, val, key);

export const del = async (key: string): Promise<void> => (await getKeyvalIdb()).delete(STORE_NAME, key);

export const keys = async () => (await getKeyvalIdb()).getAllKeys(STORE_NAME);
