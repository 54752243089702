import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AlignTopIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon align-top"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M14 12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V13a1 1 0 0 1 1-1h4zm-.5 1.5h-3v9h3v-9zM22 12a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h4zm-.5 1.5h-3v6h3v-6zM24 8a.75.75 0 1 1 0 1.5H8A.75.75 0 0 1 8 8z"
            />
        </g>
    </svg>
);
const Memo = memo(AlignTopIcon);
export default Memo;
