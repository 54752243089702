// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

// Utils
import editorStoreConnect from '../../components/editor/store/components/editorStoreConnect';

// Components
import CardEditor from './CardEditor';

// Selectors
import { isEditingCloneSelector, textContentSelector } from '../clone/cloneSelector';

const mapEditorStateToProps = () =>
    createStructuredSelector({
        textContent: textContentSelector,
        isEditingClone: isEditingCloneSelector,
    });

class CloneCardEditor extends React.Component {
    startEditing = (...args) => {
        const { startEditing, isEditingClone } = this.props;

        // If we've just been editing a clone, delay the start editing slightly so that
        // the element state will be updated before we start editing this editor
        if (isEditingClone && startEditing) {
            setTimeout(() => startEditing(...args), 0);
            return;
        }

        return startEditing && startEditing(...args);
    };

    render() {
        return <CardEditor {...this.props} startEditing={this.startEditing} />;
    }
}

CloneCardEditor.propTypes = {
    element: PropTypes.object,
    textContent: PropTypes.object,
    startEditing: PropTypes.func,

    isClone: PropTypes.bool,
    isEditingClone: PropTypes.bool,
    isEditing: PropTypes.bool,
};

export default editorStoreConnect(mapEditorStateToProps)(CloneCardEditor);
