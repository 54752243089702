// Lib
import React from 'react';
import * as Immutable from 'immutable';

// Constants
import { CHECKLIST_BLOCK_TYPE } from './checklistConstants';

/**
 * Render the following structure:
 * <ul className="checklist-ul">
 *     <li>...</li>
 *     ...
 * </ul>
 */
export default Immutable.Map({
    [CHECKLIST_BLOCK_TYPE]: {
        element: 'li',
        wrapper: <ul className="checklist-ul" />,
    },
});
