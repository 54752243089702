// Lib
import { isNull } from 'lodash/fp';

/**
 * Returns an array of ranges ({ startKey, startOffset, endKey, endOffset }) for a given entity.
 *
 * Note: Due to copy and paste, a single entity could exist multiple times in a given editor.
 *  This might result in some undesirable behaviour.  For example, if it's a link entity, changing the
 *  URL of one link will result in a change for all of the links.
 */
export default (editorState, entityKey) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const ranges = [];
    let currentBlock = blockMap.first();

    while (currentBlock) {
        let startKey = null;
        let startOffset = null;
        let endKey = null;
        let endOffset = null;

        const currentBlockKey = currentBlock.get('key');
        const nextBlockKey = contentState.getKeyAfter(currentBlockKey);
        const nextBlock = blockMap.get(nextBlockKey);

        const blockLength = currentBlock.getLength();

        for (let charIndex = 0; charIndex < blockLength; charIndex++) {
            const charEntityKey = currentBlock.getEntityAt(charIndex);

            if (charEntityKey === entityKey) {
                if (isNull(startKey)) {
                    startKey = currentBlockKey;
                    startOffset = charIndex;
                }

                if (charIndex !== blockLength - 1) {
                    const nextCharEntityKey = currentBlock.getEntityAt(charIndex + 1);
                    if (nextCharEntityKey !== entityKey) {
                        endKey = currentBlockKey;
                        endOffset = charIndex + 1;
                    }
                } else if (!nextBlock || nextBlock.getEntityAt(0) !== entityKey) {
                    endKey = currentBlockKey;
                    endOffset = charIndex + 1;
                }
            }

            if (!isNull(startOffset) && !isNull(endOffset)) {
                ranges.push({
                    startKey,
                    startOffset,
                    endKey,
                    endOffset,
                });

                startKey = null;
                startOffset = null;
                endKey = null;
                endOffset = null;
            }
        }

        currentBlock = nextBlock;
    }

    return ranges;
};
