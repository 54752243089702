import * as Immutable from 'immutable';

// Constants
import { CURRENT_BOARD_ID_SET, VISIBLE_BOARD_ID_SET } from './currentBoardIdConstants';

const initialState = Immutable.Map({
    initialized: false,
    // Restored determines if it was chosen on initial page load (e.g. lastViewedBoard)
    // If so, and the board is deleted, then we shouldn't show the error message
    restored: false,
    current: null,
    visible: null,
});

const setCurrentBoardId = (state, { boardId, restored = false }) =>
    state.set('initialized', true).set('restored', restored).set('visible', null).set('current', boardId);

export default (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_BOARD_ID_SET:
            return setCurrentBoardId(state, action);
        case VISIBLE_BOARD_ID_SET:
            return state.set('visible', action.boardId);
        default:
            return state;
    }
};
