import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MediaPlayerVolumeLoudIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon media-player-volume-loud"
        {...props}
    >
        <path
            fill="#767C86"
            fillRule="evenodd"
            d="M6.89 1.728A.5.5 0 0 1 7 2.04v11.92a.5.5 0 0 1-.812.39L3.375 11.3a1 1 0 0 1-.252-.3H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h1.123a1 1 0 0 1 .252-.3l2.813-3.05a.5.5 0 0 1 .702.078zM13.25 3a.75.75 0 0 1 .75.75v8.5a.75.75 0 1 1-1.5 0v-8.5a.75.75 0 0 1 .75-.75zm-3 2a.75.75 0 0 1 .75.75v4.5a.75.75 0 1 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75z"
        />
    </svg>
);
const Memo = memo(MediaPlayerVolumeLoudIcon);
export default Memo;
