export const getLineHandleMode = ({ forceShow, longHover, snapped, isDragging, snappedToGuide }) => {
    const classes = [];

    if (isDragging) classes.push('dragging');
    if (forceShow) classes.push('force-show');
    if (snapped) classes.push('snapped');
    if (snappedToGuide) classes.push('guide-snapped');
    if (longHover) classes.push('long-hover');

    return classes.join(' ');
};
