// Lib
import * as Immutable from 'immutable';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from '../../../../node_module_clones/redux-logger/src';

// Utils
import getClientConfig from '../../../utils/getClientConfig';
import LiveCollaborationSocketManager from '../socket/LiveCollaborationSocketManager';
import addStateToWindowMiddleware from '../../../store/addStateToWindowMiddleware';
import { isGlobalDebugEnabled } from '../../../debug/debugUtil';
import { isLoggerDebugEnabled, LoggerComponents } from '../../../logger';
import { createLiveCollaborationSocketMiddleware } from '../socket/createLiveCollaborationSocketMiddleware';

// Reducer
import liveCollaborationRootReducer from './liveCollaborationRootReducer';

// Constants
import { DEBUG_LEVEL } from '../../../../../config/configConstants';
import { CURSOR_UPDATE } from '../../../../common/users/liveCursor/liveCursorConstants';

// Actions to filter from the logger
export const LOG_FILTER_ACTIONS = Immutable.Map({
    [CURSOR_UPDATE]: DEBUG_LEVEL.FINE,
});

export default function configureLiveCollaborationStore(initialState = Immutable.Map()) {
    const liveCollaborationSocketManager = new LiveCollaborationSocketManager();

    const middlewareList = [];
    const config = getClientConfig();

    // TODO Potentially do some batching of actions here

    middlewareList.push(createLiveCollaborationSocketMiddleware(liveCollaborationSocketManager));

    if (isGlobalDebugEnabled()) {
        middlewareList.push(addStateToWindowMiddleware('collab_'));
    }

    if (isLoggerDebugEnabled(LoggerComponents.REDUX_LOG_COLLAB)) {
        const filteredActions = LOG_FILTER_ACTIONS.filter((logLevel) => logLevel >= config.debugLevel)
            .keySeq()
            .toArray();

        middlewareList.push(
            createLogger({
                predicate: (getState, action) => filteredActions.indexOf(action.type) === -1,
                collapsed: true,
                duration: true,
                timestamp: false,
                colors: {
                    title: (action) => 'indianred',
                },
            }),
        );
    }

    const middleware = applyMiddleware.apply(null, middlewareList);

    const store = createStore(liveCollaborationRootReducer, initialState, middleware);
    liveCollaborationSocketManager.subscribeToLiveCollaborationStore(store);

    return store;
}
