// Helper singleton
import { removeSyncedMeasurement, setSyncedMeasurement } from './syncedMeasurementsSingleton';

// Utils
import measurementsRegistry from '../measurementsRegistry';
import { asObject, isEmpty, prop } from '../../../../common/utils/immutableHelper';
import { getElementDomMeasurementsData } from './utils/measureElementDomUtils';
import { getElement } from '../../../../common/elements/utils/elementTraversalUtils';
import { getElementMeasurementFromCanvasDocument } from './utils/measureElementUtils';

// Selectors
import getGridSize from '../../../utils/grid/gridSizeSelector';
import { getElementMeasurements } from './elementMeasurementsSelector';
import { getElements } from '../../../element/selectors/elementSelector';
import { currentBoardCanvasElementIdsSelector } from '../../../element/selectors/currentBoardSelector';
import { canvasZoomScaleSelector, canvasZoomTranslationPxSelector } from '../../../canvas/store/canvasZoomSelector';

// Actions
import { createBatchAction } from '../../../store/reduxBulkingMiddleware';

// Constants
import { MEASUREMENT_REMOVE, MEASUREMENT_SET } from './elementMeasurementsConstants';

// Types
import { ElementMeasurement } from './elementMeasurementsTypes';
import { ReduxStore } from '../../../types/reduxTypes';
import { Point } from '../../../../common/maths/geometry/pointTypes';
import { asRect } from '../../../../common/maths/geometry/rect';
import { ActionObject } from '../../../../common/actions/actionTypes';

interface MeasurementSetAction extends ActionObject {
    id: string;
    measurements: ElementMeasurement;
}

interface MeasurementRemoveAction extends ActionObject {
    id: string;
}

export const measurementsSet = ({
    id,
    measurements,
}: {
    id: string;
    measurements: ElementMeasurement;
}): MeasurementSetAction => {
    setSyncedMeasurement(id, measurements);
    return { type: MEASUREMENT_SET, id, measurements };
};
export const measurementsRemove = ({ id }: { id: string }): MeasurementRemoveAction => {
    removeSyncedMeasurement(id);
    return { type: MEASUREMENT_REMOVE, id };
};

/**
 * Forces re-measurement of all elements and sets it into the measurements store.
 */
export const forceElementsMeasurements =
    (elementIds: string[]) =>
    async (
        dispatch: Function,
        getState: Function,
        { measurementsStore }: { measurementsStore: ReduxStore },
    ): Promise<void> => {
        if (isEmpty(elementIds)) return;

        const measurementsState = measurementsStore.getState();

        const state = getState();
        const allElements = getElements(state);

        const gridSize = getGridSize(state);
        const zoomScale = canvasZoomScaleSelector(state);
        const zoomTranslation: Point = asObject(canvasZoomTranslationPxSelector(state));

        const measurementUpdates = elementIds
            .map((id) => {
                const element = getElement(allElements, id);
                const elementNode = document.querySelector(`[data-element-id="${id}"]`);

                if (!elementNode) return;

                const elementMeasurement = getElementMeasurements(measurementsState, { id });
                const workspaceSection = prop('workspaceSection', elementMeasurement);

                const elementBoundingRect = asRect(elementNode.getBoundingClientRect());

                const { featureSuggestionsHeight, originOffset, canvasOffset } = getElementDomMeasurementsData(
                    element,
                    elementNode,
                    elementBoundingRect,
                    gridSize,
                    zoomScale,
                );

                const measurements = getElementMeasurementFromCanvasDocument(
                    elementBoundingRect,
                    element,
                    zoomScale,
                    zoomTranslation,
                    workspaceSection,
                    featureSuggestionsHeight,
                    originOffset,
                    canvasOffset,
                    elementNode,
                );

                // Update the cached values in the measureElementDecorator to ensure following calculations are accurate
                measurementsRegistry.updateElementCachedMeasurements(id, measurements);

                return measurementsSet({ id, measurements });
            })
            .filter((measurement) => !!measurement);

        if (isEmpty(measurementUpdates)) return;

        const batchMeasurementsUpdate = createBatchAction({ actions: measurementUpdates });

        measurementsStore.dispatch(batchMeasurementsUpdate);
    };

/**
 * Forces measure all canvas elements.
 */
export const forceMeasureAllCanvasElements =
    () =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        const state = getState();

        const canvasElementIds = currentBoardCanvasElementIdsSelector(state);

        dispatch(forceElementsMeasurements(canvasElementIds));
    };
