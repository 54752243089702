import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationUnshareIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-unshare"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="#E50C0C" strokeWidth={2}>
            <circle cx={8} cy={8} r={7} />
            <path strokeLinecap="square" d="m4 12 8-8" />
        </g>
    </svg>
);
const Memo = memo(NotificationUnshareIcon);
export default Memo;
