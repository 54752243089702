import { markInputRule } from '@tiptap/core';
import DefaultLink, { LinkOptions } from '@tiptap/extension-link';
import { Plugin } from '@tiptap/pm/state';

import { togglePopup } from '../../../../client/components/popupPanel/popupActions';
import { PopupIds } from '../../../../client/components/popupPanel/popupConstants';
import { createUsableUrl } from './createUsableUrl';
import { decorationAutolink } from './decorationAutolink';
import { linkClickHandler } from './linkClickHandler';

interface MilanoteLinkOptions extends LinkOptions {
    dispatch: Function;
    isEditable: boolean;
    clickableLinksWhenEditable: boolean;
}

const toggleHyperlinkPopup = togglePopup(PopupIds.HYPERLINK);

// Capture markdown link syntax - ie, [link text](link url)
// allowing for anything but a "]" in the text, and anything but a ")" in the url
const inputRegex = /\[([^\]]+)\]\(([^\)]+)\)$/;

export const Link = DefaultLink.extend<MilanoteLinkOptions>({
    addProseMirrorPlugins() {
        return [
            ...(this.parent?.() as Plugin[]),
            decorationAutolink(this.options.defaultProtocol),
            linkClickHandler(this.options.clickableLinksWhenEditable),
        ];
    },

    addOptions() {
        return {
            ...(this.parent?.() as LinkOptions),
            dispatch: () => {},
            isEditable: false,
            clickableLinksWhenEditable: false,
            defaultProtocol: 'https',
            linkOnPaste: true,

            openOnClick: false, // covered by linkClickHandler
            autolink: false, // covered by decorationAutolink
        };
    },

    addPasteRules() {
        // The DefaultLink behaviour is to apply a link mark to any pasted URLs, but we
        // want to leave them markless and let the autolink decoration handle them.
        return [];
    },

    addInputRules() {
        return [
            markInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes(match) {
                    // match contains [whole match, link text, link url]
                    // The return object is the attrs of the resulting node, but tiptap will also
                    // read the match array and use the last element in it as the link text.
                    // So, we pop the url from the match array rather than just reading it:
                    const href = match.pop()?.trim() || '';
                    return { href };
                },
            }),
        ];
    },

    /**
     * Opens the hyperlink popup when the user presses Cmd+K.
     */
    addKeyboardShortcuts() {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Mod-k': ({ editor }) => {
                const { isEditable, dispatch } = this.options;

                if (!isEditable) return false;
                if (!dispatch) return false;

                dispatch(toggleHyperlinkPopup);

                return true;
            },
        };
    },

    renderHTML({ mark, HTMLAttributes }) {
        const href = createUsableUrl(mark.attrs.href, this.options.defaultProtocol);
        return ['a', { ...HTMLAttributes, ...mark.attrs, href }, 0];
    },
});
