// Lib
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { onlyUpdateForPropTypes } from '../../../node_module_clones/recompose';

// Util
import { getChildrenDescription } from './containerElementUtil';
import workspaceInitialisationMonitoringSingleton from '../../workspace/initialisation/workspaceInitialisationMonitoringSingleton';

// Styles
import './ElementContentsTag.scss';

const ContainerElementChildCount = ({ children, childrenTypes, show, isBoardDuplicating, isBoard }) => {
    useEffect(() => {
        if (show && isBoard) {
            workspaceInitialisationMonitoringSingleton.setBoardCountsInitialised(true);
        }
    }, [show]);

    const description = useMemo(
        () => getChildrenDescription(childrenTypes, isBoardDuplicating),
        [childrenTypes, isBoardDuplicating],
    );

    return (
        <div className="ContainerElementChildCount ElementContentsTag">
            <span className={classNames('text', { show })}>{description}</span>
            {children}
        </div>
    );
};

ContainerElementChildCount.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    childrenTypes: PropTypes.object,
    show: PropTypes.bool,
    isBoardDuplicating: PropTypes.bool,
    isBoard: PropTypes.bool,
};

export default onlyUpdateForPropTypes(ContainerElementChildCount);
