// Lib
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';

// Plugins
import { ApplePencil } from '../../../../../capacitor_plugins/applePencil';

// Hooks
import usePrevious from '../../../../utils/react/usePrevious';

// Constants
import { TOOL_MODES } from '../drawingEditorConstants';
import { PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS } from '../../../../hybridApp/store/hybridAppStoreConstants';
import { CAPACITOR_APPLE_PENCIL_PLUGIN_NAME } from '../../../../../capacitor_plugins/pluginConstants';

/**
 * Listens for a synthetic 'pencildbltap' event and toggles between the erase and draw modes.
 */
export default function usePencilDoubleTap({ toolMode, setToolMode, setColorPopupOpen, setStrokePopupOpen }) {
    const previousToolMode = usePrevious(toolMode);

    const isPluginAvailable = Capacitor.isPluginAvailable(CAPACITOR_APPLE_PENCIL_PLUGIN_NAME);

    if (!isPluginAvailable) return;

    useEffect(() => {
        const onPencilDoubleTap = (event) => {
            if (event.preferredTapAction === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.ignore) return;

            if (event.preferredTapAction === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.showColorPalette) {
                setColorPopupOpen(true);
                return;
            }

            if (event.preferredTapAction === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.showInkAttribute) {
                setStrokePopupOpen(true);
                return;
            }

            const switchToPrevious =
                !!previousToolMode && event.preferredTapAction === PENCIL_DOUBLE_TAP_PREFERRED_ACTIONS.switchPrevious;

            let nextToolMode;

            if (switchToPrevious) {
                nextToolMode = previousToolMode;
            } else {
                nextToolMode = toolMode !== TOOL_MODES.DRAW ? TOOL_MODES.DRAW : TOOL_MODES.ERASE;
            }

            setToolMode(nextToolMode);
        };

        let applePencilDoubleTapListener;
        (async () => {
            applePencilDoubleTapListener = await ApplePencil.addListener('applePencilDoubleTap', onPencilDoubleTap);
        })();

        return () => {
            applePencilDoubleTapListener?.remove();
        };
    }, [toolMode]);
}
