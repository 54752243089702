// Lib
import { AxiosError, AxiosHeaders, AxiosInstance, AxiosResponse } from 'axios';

// Constants
// eslint-disable-next-line @typescript-eslint/naming-convention
import HttpStatus from 'http-status-codes';
import { AWS_HEADERS, AWS_HEADERS_VALUES } from './httpConstants';

const MAX_RETRY_COUNT = 1;

export const isChallengeResponse = (res: AxiosResponse): boolean =>
    res &&
    res.status === HttpStatus.ACCEPTED &&
    res.headers instanceof AxiosHeaders &&
    res.headers[AWS_HEADERS.WAF_ACTION] === AWS_HEADERS_VALUES.WAF_ACTION.CHALLENGE;

const retryRequestIfConfiguredTo = async (
    res: AxiosResponse,
    retryCb?: (err: Error) => Promise<AxiosInstance | AxiosResponse>,
): Promise<AxiosResponse> => {
    if (!retryCb) {
        return Promise.reject(
            new AxiosError(
                'Request failed due to Firewall challenge.  Retry not configured',
                res.status.toString(),
                res.config,
                res.request,
                res,
            ),
        );
    }

    // For Firewall challenges, retry at least once even if not configured to
    res.config.retry ||= MAX_RETRY_COUNT;
    res.config._retryCount ||= 0;

    const err = new AxiosError(
        'Request failed due to Firewall challenge',
        res.status.toString(),
        res.config,
        res.request,
        res,
    );

    // Call Retry handler to determine if should retry.
    // Relies on HTTP request interceptor to ensure a token is sent in the retry request.
    await retryCb(err);

    return Promise.resolve(res);
};

export default (
        awsWafIntegrationAvailable: () => boolean,
        retryCb?: (err: Error) => Promise<AxiosInstance | AxiosResponse>,
    ) =>
    async (res: AxiosResponse): Promise<AxiosResponse> => {
        if (!isChallengeResponse(res)) return Promise.resolve(res);

        if (!awsWafIntegrationAvailable()) {
            return Promise.reject(
                new AxiosError(
                    'Request failed due to Firewall challenge.  Unable to respond',
                    res.status.toString(),
                    res.config,
                    res.request,
                    res,
                ),
            );
        }

        try {
            return await retryRequestIfConfiguredTo(res, retryCb);
        } catch (err) {
            return Promise.reject(err);
        }
    };
