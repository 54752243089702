// Lib
import * as Immutable from 'immutable';
import { get, values, isNil } from 'lodash/fp';

// Utils
import { isBoard } from '../../../../common/elements/utils/elementTypeUtils';
import { getColor, getLocationParentId, getTitle } from '../../../../common/elements/utils/elementPropertyUtils';
import { getIsTemplate } from '../../../../common/templates/templateUtil';

// Constants
import { BOARD_SUMMARIES_LOAD, BOARD_SUMMARIES_LOAD_ELEMENTS, BOARD_SUMMARIES_REMOVE } from './boardSummariesConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_TYPE,
    ELEMENT_UPDATE,
} from '../../../../common/elements/elementConstants';

const loadBoardSummaries = (state, action) => {
    if (!action.boardSummaries) return state;

    return state.withMutations((mutableState) => {
        mutableState.set('fetching', false).set('fetched', true);

        action.boardSummaries.forEach((boardSummary) => {
            const summariesState = mutableState.get('summaries');
            const existingEntry = summariesState[boardSummary.id];

            summariesState[boardSummary.id] = existingEntry ? { ...existingEntry, ...boardSummary } : boardSummary;
        });

        // Force update
        mutableState.update('summaries', (summaries) => ({ ...summaries }));
    });
};

const handleElementCreation = (state, action) => {
    if (!isBoard(action.elementType)) return state;

    const title = getTitle(action);
    const color = getColor(action);
    const parentId = getLocationParentId(action);

    return state.update('summaries', (summaries) => {
        summaries[action.id] = { id: action.id, title, color, parentId };
        return { ...summaries };
    });
};

const handleElementMove = (state, action) =>
    state.withMutations((mutableState) => {
        let didUpdateSummaries = false;

        action.moves.forEach((elementMove) => {
            const sourceParentId = get(['from', 'parentId'], elementMove);
            const destinationParentId = get(['location', 'parentId'], elementMove);

            if (sourceParentId === destinationParentId) return;

            const summariesState = mutableState.get('summaries');
            const existingEntry = summariesState[elementMove.id];

            if (!existingEntry) return;

            summariesState[elementMove.id] = {
                ...existingEntry,
                parentId: destinationParentId,
            };

            didUpdateSummaries = true;
        });

        // Force new summaries reference
        if (didUpdateSummaries) mutableState.set('summaries', { ...mutableState.get('summaries') });
    });

const handleUpdate = (state, action) =>
    state.withMutations((mutableState) => {
        let didUpdateSummaries = false;

        action.updates.forEach((elementUpdate) => {
            const summariesState = mutableState.get('summaries');
            const existingEntry = summariesState[elementUpdate.id];

            if (!existingEntry) return;

            const titleUpdate = get(['changes', 'title'])(elementUpdate);
            const colorUpdate = get(['changes', 'color'])(elementUpdate);
            const isTemplateUpdate = get(['changes', 'isTemplate'])(elementUpdate);

            if (isNil(titleUpdate) && isNil(colorUpdate) && isNil(isTemplateUpdate)) return;

            const newEntry = { ...existingEntry };
            summariesState[elementUpdate.id] = newEntry;

            if (titleUpdate) newEntry.title = titleUpdate;
            if (colorUpdate) newEntry.color = colorUpdate;
            if (!isNil(isTemplateUpdate)) newEntry.isTemplate = isTemplateUpdate;

            didUpdateSummaries = true;
        });

        // Force new summaries reference
        if (didUpdateSummaries) mutableState.set('summaries', { ...mutableState.get('summaries') });
    });

const handleDeletion = (state, action) =>
    state.update('summaries', (summariesState) => {
        if (!summariesState[action.id]) return summariesState;

        delete summariesState[action.id];
        return { ...summariesState };
    });

const handleElementLoad = (state, action) =>
    state.withMutations((mutableState) => {
        const summariesState = mutableState.get('summaries');

        values(action.elements).forEach((element) => {
            if (!isBoard(element.elementType)) return;

            const existingEntry = summariesState[element.id];
            const title = getTitle(element);
            const color = getColor(element);
            const parentId = getLocationParentId(element);
            const isTemplate = getIsTemplate(element);

            let newEntry;

            if (existingEntry) {
                newEntry = {
                    ...existingEntry,
                    title,
                    color,
                    parentId,
                };

                if (isTemplate) {
                    newEntry.isTemplate = true;
                }
            } else {
                newEntry = { id: element.id, title, color, parentId };
            }

            summariesState[element.id] = newEntry;
        });

        mutableState.set('summaries', { ...summariesState });
    });

const handleRemoveBoardSummaries = (state, { boardIds }) =>
    state.update('summaries', (summariesState) => {
        boardIds.forEach((boardId) => {
            delete summariesState[boardId];
        });

        return { ...summariesState };
    });

const createInitialState = () =>
    Immutable.Map({
        summaries: {},
        fetching: false,
        fetched: false,
    });

export default (state = createInitialState(), action) => {
    switch (action.type) {
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(state, action);
        case ELEMENT_CREATE:
        case ELEMENT_SET_TYPE:
            return handleElementCreation(state, action);
        case ELEMENT_UPDATE:
            return handleUpdate(state, action);
        case ELEMENT_DELETE:
            return handleDeletion(state, action);
        case BOARD_SUMMARIES_LOAD_ELEMENTS:
            return handleElementLoad(state, action);
        case BOARD_SUMMARIES_LOAD:
            return loadBoardSummaries(state, action);
        case BOARD_SUMMARIES_REMOVE:
            return handleRemoveBoardSummaries(state, action);
        default:
            return state;
    }
};
