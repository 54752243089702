// Lib
import { RichUtils } from 'draft-js';

// Utils
import removeEntireBlock from '../../customRichUtils/removeEntireBlock';

// Constants
import { DraftRemovalDirection, HANDLER_RETURN_VALUES, DRAFT_COMMANDS } from '../../draftjsConstants';

const handleFullLineBackspace = (editorState, command) => {
    // Prevent a triple click from removing the following block's style
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    const contentState = editorState.getCurrentContent();
    const keyAfterStart = contentState.getKeyAfter(startKey);

    const isTripleClickDeletion =
        startKey !== endKey &&
        endKey === keyAfterStart &&
        selection.getStartOffset() === 0 &&
        selection.getEndOffset() === 0;

    if (isTripleClickDeletion) {
        const direction =
            command === DRAFT_COMMANDS.BACKSPACE ? DraftRemovalDirection.backward : DraftRemovalDirection.forward;
        return removeEntireBlock({ editorState, blockKey: startKey, direction });
    }

    return null;
};

const customCommandHandler = (editorState, command) => {
    switch (command) {
        case DRAFT_COMMANDS.DELETE:
        case DRAFT_COMMANDS.BACKSPACE:
            return handleFullLineBackspace(editorState, command);
        default:
            return null;
    }
};

/**
 * Handles a key command such as 'bold', 'italic' etc.
 */
export default (command, editorState, eventTimeStamp, { setEditorState }) => {
    const newEditorState =
        customCommandHandler(editorState, command) || RichUtils.handleKeyCommand(editorState, command);

    if (newEditorState) {
        setEditorState(newEditorState);
        return HANDLER_RETURN_VALUES.handled;
    }
    return HANDLER_RETURN_VALUES.notHandled;
};
