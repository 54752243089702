import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SearchMagnifyingGlassIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon search-magnifying-glass"
        {...props}
    >
        <path
            fill="#BBBEC3"
            fillRule="evenodd"
            d="M11 3a8 8 0 0 1 6.162 13.102l3.868 3.868a.75.75 0 0 1-1.06 1.06l-3.868-3.868A8 8 0 1 1 11 3zm0 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13z"
        />
    </svg>
);
const Memo = memo(SearchMagnifyingGlassIcon);
export default Memo;
