import { useEffect } from 'react';
import { sendAmplitudeEvent } from './amplitudeService';

interface Props {
    eventType: string;
    eventProperties?: object;
    userProperties?: object;
}

const useSendAmplitudeEventOnMount = ({ eventType, eventProperties, userProperties }: Props) => {
    useEffect(() => {
        sendAmplitudeEvent({
            eventType,
            eventProperties,
            userProperties,
        });
    }, []);
};

export default useSendAmplitudeEventOnMount;
