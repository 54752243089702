// Utils
import { propIn } from '../utils/immutableHelper';

export const getIsTemplate = propIn(['content', 'isTemplate']);
export const getIsPublicTemplateChild = propIn(['content', 'isPublicTemplateChild']);
export const getElementTemplateLink = propIn(['content', 'templateLink']);
export const getElementTemplateExampleLink = propIn(['content', 'templateExampleLink']);

export const isTemplateBoard = (element) => !!getIsTemplate(element);
export const isTemplateExampleBoard = (element) => !!getElementTemplateLink(element);
export const isTemplateEmptyBoard = (element) => !!getElementTemplateExampleLink(element);
export const isTemplateCustomBoard = (element) => isTemplateBoard(element) && !getIsPublicTemplateChild(element);
export const isTemplateCollectionBoard = (element) => !!propIn(['content', 'templateCollection'])(element);
export const isTemplatePublicParentBoard = propIn(['content', 'isPublicTemplate']);

export const getDefaultTemplateName = (userGivenName, matchedTemplateCount = 0) => {
    const userName = `${userGivenName}’s` || 'Your';

    return matchedTemplateCount === 0 ? `${userName} templates` : `${userName} templates (${matchedTemplateCount + 1})`;
};

export const getTemplateStatusText = (element) => {
    if (isTemplatePublicParentBoard(element)) return 'Public Template';
    if (isTemplateExampleBoard(element)) return 'Template (example)';
    if (isTemplateEmptyBoard(element)) return 'Template (empty)';
    return 'Template';
};
