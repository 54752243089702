// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Selectors
import { getThemeSelector } from '../../user/account/accountModal/interface/themeSettings/themeSelector';
import { getGridSizeName } from '../../utils/grid/gridSizeSelector';

// Components
import { IconSizeLargeLight, IconSizeMediumLight, IconSizeSmallLight } from '../../components/icons/light/IconLight';
import { IconSizeLargeDark, IconSizeMediumDark, IconSizeSmallDark } from '../../components/icons/dark/IconDark';

// Constants
import { THEMES } from '../../user/account/accountModal/interface/themeSettings/themeConstants';
import { GRID } from '../../utils/grid/gridConstants';

const preloadIcons = () => (dispatch, getState) => {
    const state = getState();

    const theme = getThemeSelector(state);
    const gridSize = getGridSizeName(state);

    IconSizeLargeLight.preload();

    if (gridSize === GRID.MEDIUM.name) IconSizeMediumLight.preload();
    if (gridSize === GRID.SMALL.name) IconSizeSmallLight.preload();

    if (theme === THEMES.DARK) {
        IconSizeLargeDark.preload();

        if (gridSize === GRID.MEDIUM.name) IconSizeMediumDark.preload();
        if (gridSize === GRID.SMALL.name) IconSizeSmallDark.preload();
    }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchPreloadIcons: () => dispatch(preloadIcons()),
});

/**
 * Preloads the appropriate icons depending on the theme and grid size, so they don't
 * flash in during page load.
 */
const IconPreloader = (props) => {
    const { dispatchPreloadIcons } = props;

    React.useEffect(() => {
        dispatchPreloadIcons();
    }, []);

    return null;
};

IconPreloader.propTypes = {
    dispatchPreloadIcons: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(IconPreloader);
