import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuSelectAllIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-select-all"
        {...props}
    >
        <g fill="#323B4A">
            <path d="M13.112 13.612a1.146 1.146 0 0 1 1.231-.257l7.201 2.8a1.15 1.15 0 0 1-.077 2.17l-2.756.854a.05.05 0 0 0-.033.033l-.854 2.756a1.15 1.15 0 0 1-2.17.075l-2.8-7.2-.04-.123a1.148 1.148 0 0 1 .298-1.108zm1.428 1.43 2.148 5.521.557-1.794c.136-.44.46-.794.88-.97l.142-.053 1.795-.558-5.522-2.147z" />
            <path d="M17.659 2.75a2.646 2.646 0 0 1 2.644 2.65l-.053 7.355a.75.75 0 0 1-1.5-.01l.054-7.35c0-.632-.513-1.145-1.145-1.145H5.395c-.632 0-1.145.513-1.145 1.145v12.264c0 .632.513 1.145 1.145 1.145h6.684a.75.75 0 1 1 0 1.5H5.395a2.645 2.645 0 0 1-2.645-2.645V5.395A2.645 2.645 0 0 1 5.395 2.75h12.264z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuSelectAllIcon);
export default Memo;
