export const COMPOSE_MODAL_CLOSED = 'COMPOSE_MODAL_CLOSED';
export const COMPOSE_EMAIL_SET_EDITOR_STATE = 'COMPOSE_EMAIL_SET_EDITOR_STATE';
export const COMPOSE_MODAL_ADD_AND_REMOVE_RECIPIENTS = 'COMPOSE_MODAL_ADD_AND_REMOVE_RECIPIENTS';

export const COMPOSE_MODAL_FLOWS = {
    invite: 'invite',
    share: 'share',
};

export const COMPOSE_MODAL_SHARE_TYPES = {
    view: 'view',
    edit: 'edit',
    comment: 'comment',
};
