export const ONBOARDING_GOTO_STEP = 'ONBOARDING_GOTO_STEP';
export const ONBOARDING_SET_STEP = 'ONBOARDING_SET_STEP';
export const ONBOARDING_FINISH = 'ONBOARDING_FINISH';

export const ONBOARDING_REMOVE_LISTENER = 'ONBOARDING_REMOVE_LISTENER';
export const ONBOARDING_ADD_LISTENER = 'ONBOARDING_ADD_LISTENER';

export const ONBOARDING_SET_METADATA = 'ONBOARDING_SET_METADATA';

export const ONBOARDING_STEPS_LOADED = 'ONBOARDING_STEPS_LOADED';

export const ONBOARDING_STEP_PASSED = 'ONBOARDING_STEP_PASSED';
