import { EditorState } from '@tiptap/pm/state';
import { Attrs, NodeRange, NodeType } from '@tiptap/pm/model';
import { doWrapInList, liftOutOfList, liftToOuterList } from './proseMirrorSchemaList';
import { findWrapping } from '@tiptap/pm/transform';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { TiptapDispatch } from '../../tiptapTypes';

/**
 * Similar to `liftListItem` from prosemirror-schema-list, however this continuously lifts
 * the range towards the document root until it's a child of the document root.
 */
export const liftListRangeToDoc = (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
    const tr = state.tr;

    let currentDepth = range.depth;

    while (currentDepth > 1) {
        const { $from, $to } = range;

        const listItemNode = $from.node(-1);

        const mappedFrom = tr.mapping.map($from.pos);
        const mappedTo = tr.mapping.map($to.pos);

        const $mappedFrom = tr.doc.resolve(mappedFrom);
        const $mappedTo = tr.doc.resolve(mappedTo);

        const blockRange = $mappedFrom.blockRange(
            $mappedTo,
            (node) => node.childCount > 0 && node.firstChild!.type === listItemNode.type,
        );

        if (!blockRange) return false;
        if (blockRange.depth === currentDepth) return false;
        if (!dispatch) return true;

        currentDepth = blockRange.depth;

        if ($mappedFrom.node(blockRange.depth - 1).type === listItemNode.type) {
            // Inside a parent list
            liftToOuterList(state, dispatch, listItemNode.type, blockRange);
        } else {
            // Outer list node
            liftOutOfList(state, dispatch, blockRange);
        }
    }

    return true;
};

/**
 * Similar to `wrapInList` from prosemirror-schema-list, however this wraps the
 * specified range in a list, rather than the selection.
 */
export const wrapInList =
    (listType: NodeType, attrs: Attrs | null = null) =>
    (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
        const { $from, $to } = range;

        let blockRange = $from.blockRange($to);
        let doJoin = false;
        let outerRange = blockRange;

        if (!blockRange) return false;

        // This is at the top of an existing list item
        if (
            blockRange.depth >= 2 &&
            $from.node(blockRange.depth - 1).type.compatibleContent(listType) &&
            blockRange.startIndex == 0
        ) {
            // Don't do anything if this is the top of the list
            if ($from.index(blockRange.depth - 1) == 0) return false;

            const $insert = state.tr.doc.resolve(blockRange.start - 2);

            outerRange = new NodeRange($insert, $insert, blockRange.depth);

            if (blockRange.endIndex < blockRange.parent.childCount) {
                blockRange = new NodeRange($from, state.tr.doc.resolve($to.end(blockRange.depth)), blockRange.depth);
            }

            doJoin = true;
        }

        const wrap = findWrapping(outerRange!, listType, attrs, blockRange);

        if (!wrap) return false;

        if (dispatch) dispatch(doWrapInList(state.tr, blockRange, wrap, doJoin, listType).scrollIntoView());

        return true;
    };

/**
 * Converts an existing list item's parent to a different list type.
 * E.g. If the parent list type is an ordered list, this will convert it to a bullet list.
 */
export const convertListItemParentListType =
    (listType: NodeType) => (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
        const { $from, $to } = range;

        const blockRange = $from.blockRange($to);

        if (!blockRange) return false;

        const parentListNode = findParentNodeClosestToPos(
            blockRange.$from,
            (node) => !!node.type.spec.group?.includes('list'),
        );

        if (!parentListNode) return false;

        if (!dispatch) return true;

        const { pos } = parentListNode;

        state.tr.setNodeMarkup(pos, listType);

        return true;
    };

/**
 * Merges adjacent lists of the same type.
 *
 * E.g:
 *  Ordered List
 *    List Item
 *      Paragraph
 *  Ordered List
 *    List Item
 *      Paragraph
 *
 * Becomes:
 *  Ordered List
 *    List Item
 *      Paragraph
 *    List Item
 *      Paragraph
 *
 * This is useful because the initial steps in the `changeToList` algorithm create individual
 * wrapping lists, regardless of whether they're adjacent to another list.
 */
export const mergeAdjacentLists = (listType: NodeType) => (state: EditorState, dispatch: TiptapDispatch) => {
    if (!dispatch) return true;

    // First find all the points at which the current list type is adjacent to itself
    const nodeEnds: Record<number, boolean> = {};
    const joinPositions: number[] = [];

    const { from, to, $from, $to } = state.tr.selection;

    // Start from the previous node to the current selection's list, if there is one
    const startParentList = findParentNodeClosestToPos($from, (node) => node.type === listType);
    const searchStartingPosition = startParentList ? Math.max(startParentList.pos - 1, 0) : from;

    const endParentList = findParentNodeClosestToPos($to, (node) => node.type === listType);
    const searchEndingPosition = endParentList
        ? Math.min(endParentList.pos + endParentList.node.nodeSize + 1, state.tr.doc.nodeSize - 2)
        : to;

    state.tr.doc.nodesBetween(searchStartingPosition, searchEndingPosition, (node, pos) => {
        // If we're matching the list type and there's already a list ending at this position
        // then we can join them
        if (node.type === listType) {
            nodeEnds[pos + node.nodeSize] = true;
            if (nodeEnds[pos]) joinPositions.push(pos);
        }

        // Recurse all the way to the bottom of every branch
        return true;
    });

    const initialStepsCount = state.tr.steps.length;

    // Then join the points
    joinPositions.forEach((pos) => {
        // Only map positions that have changed while performing this operation
        const mapping = state.tr.mapping.slice(initialStepsCount);

        const mappedPos = mapping.map(pos);

        state.tr.join(mappedPos);
    });

    return true;
};
