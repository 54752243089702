// Lib
import { useState } from 'react';

// Utils
import useLocalStorage from '../../../utils/services/localStorage/useLocalStorage';

// Constants
import { STROKE_SIZES, TOOL_MODES, DRAWING_PATH_PROPERTIES } from './drawingEditorConstants';

export const DRAWING_STROKE_SIZE_KEY = 'milanote.drawing.stroke-size';
export const DRAWING_COLOR_KEY = 'milanote.drawing.color';
export const DRAWING_PREVIOUS_COLOR_KEY = 'milanote.drawing.previous-color';

const useDrawingEditorToolbarState = ({ onPathPropertyChangeRef }) => {
    const [toolMode, setToolMode] = useState(TOOL_MODES.DRAW);
    const [toolModeState, setToolModeState] = useState({});
    const [previousStrokeColor, setPreviousStrokeColorState] = useLocalStorage(DRAWING_PREVIOUS_COLOR_KEY, null);
    const [strokeColor, setStrokeColorState] = useLocalStorage(DRAWING_COLOR_KEY, null);
    const [strokeSize, setStrokeSizeState] = useLocalStorage(DRAWING_STROKE_SIZE_KEY, STROKE_SIZES.MEDIUM, (val) =>
        parseInt(val, 10),
    );

    const [colorPopupOpen, setColorPopupOpenState] = useState(false);
    const [customColorInputOpen, setCustomColorInputOpen] = useState(false);
    const [strokePopupOpen, setStrokePopupOpenState] = useState(false);

    /**
     * Ensure that the colour popup is closed if the stroke popup is opened.
     */
    const setStrokePopupOpen = (nowOpen) => {
        if (nowOpen) setColorPopupOpenState(false);
        return setStrokePopupOpenState(nowOpen);
    };

    /**
     * Ensure that the stroke popup is closed if the colour popup is opened.
     */
    const setColorPopupOpen = (nowOpen) => {
        if (nowOpen) setStrokePopupOpenState(false);
        return setColorPopupOpenState(nowOpen);
    };

    const setToolModeWrapper = (mode) => {
        setToolMode(mode);
        setToolModeState({});
    };

    const setStrokeColor = (color) => {
        onPathPropertyChangeRef.current &&
            onPathPropertyChangeRef.current({
                oldValue: strokeColor,
                value: color,
                pathProperty: DRAWING_PATH_PROPERTIES.COLOR,
            });

        setPreviousStrokeColorState(strokeColor);
        setStrokeColorState(color);
    };

    const switchToPreviousColor = () => setStrokeColor(previousStrokeColor);

    const setStrokeSize = (size) => {
        onPathPropertyChangeRef.current &&
            onPathPropertyChangeRef.current({
                oldValue: strokeSize,
                value: size,
                pathProperty: DRAWING_PATH_PROPERTIES.SIZE,
            });

        setStrokeSizeState(size);
    };

    const closeAllPopups = () => {
        setColorPopupOpenState(false);
        setStrokePopupOpenState(false);
    };

    return {
        toolMode,
        strokeSize,
        strokeColor,
        colorPopupOpen,
        customColorInputOpen,
        setCustomColorInputOpen,
        strokePopupOpen,

        setToolMode: setToolModeWrapper,
        // Allow tool modes to pass state up
        toolModeState,
        setToolModeState,
        setStrokeSize,
        setStrokeColor,
        switchToPreviousColor,

        // This sets the actual state for size & colour but doesn't callback
        // things that are reacting to size selections
        // (e.g. useDrawingEditorSelectedPathModifier)
        setStrokeSizeState,
        setStrokeColorState,
        setColorPopupOpen,
        setStrokePopupOpen,

        closeAllPopups,
    };
};

export default useDrawingEditorToolbarState;
