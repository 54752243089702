// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ReactionToolbarPopup from './ReactionToolbarPopup';
import ToolbarPopupTool from '../../ToolbarPopupTool';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

const ReactionTool = (props) => (
    <ToolbarPopupTool
        {...props}
        popupId={PopupIds.REACTION_TOOL}
        toolName="Reaction"
        buttonSelector=".ReactionTool .icon"
        PopupComponent={ReactionToolbarPopup}
    />
);

ReactionTool.propTypes = {
    selectedElements: PropTypes.object,
    name: PropTypes.string,
};

export default ReactionTool;
