import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementTableIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={38}
        height={32}
        viewBox="0 0 38 32"
        className="Icon element-table"
        {...props}
    >
        <defs>
            <filter
                id="ElementTableIcon__a"
                width="131.2%"
                height="138.5%"
                x="-15.6%"
                y="-15.4%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <path
                id="ElementTableIcon__b"
                d="M0 1.997C0 .894.89 0 2.002 0h27.996A2 2 0 0 1 32 1.997v22.006C32 25.106 31.11 26 29.998 26H2.002A2 2 0 0 1 0 24.003V1.997Z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(3 2)">
                <use xlinkHref="#ElementTableIcon__b" fill="#000" filter="url(#ElementTableIcon__a)" />
                <use xlinkHref="#ElementTableIcon__b" fill="#FFF" />
            </g>
            <path fill="#D1D3D6" d="M5 2h28a2 2 0 0 1 2 2v4H3V4a2 2 0 0 1 2-2Z" />
            <path
                fill="#1B2536"
                fillOpacity={0.1}
                d="M14 2v12h10V2h1v12h10v1H25v6h10v1H25v6h-1v-6H14v6h-1v-6H3v-1h10v-6H3v-1h10V2h1Zm10 19v-6H14v6h10Z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementTableIcon);
export default Memo;
