import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarConnectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-connect"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M7 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v.269l4-2.818V8a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-.218l-4 2.816v.738l4 2.817V19a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-3.017l-4-2.817v.334a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5zm12.5 6v4h4v-4h-4zm0-11v4h4v-4h-4zM8.5 14v4h4v-4h-4z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarConnectIcon);
export default Memo;
