// Lib
import { includes, groupBy, mapValues, set, head, debounce } from 'lodash';
import { get, isEmpty } from 'lodash/fp';

// Utils
import { getConnectingElementIds } from '../connections/elementConnectionsUtils';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getNewScores } from '../../../common/elements/listOrderScores/scoreCalculator';
import { isBoard, isBoardLike } from '../../../common/elements/utils/elementTypeUtils';
import {
    addRestoreLocationForMovesToTrash,
    isLocationCanvas,
    isLocationInbox,
    isLocationTrash,
    removeTrashSuffix,
} from '../../../common/elements/utils/elementLocationUtils';
import { isAsyncEntityFetched } from '../../utils/services/http/asyncResource/asyncResourceUtils';
import {
    getPhysicalAncestorIds,
    getElement as getElementFromElementsMap,
} from '../../../common/elements/utils/elementTraversalUtils';
import { numbersAscending } from '../../../common/utils/sortUtil';
import { getAllOverlappingElements } from './elementMoveActionUtil';
import {
    getChildrenViaGraph,
    getSortedChildrenInSectionViaGraph,
} from '../../../common/elements/utils/elementGraphUtils';
import { getSelectedElements } from '../selection/selectedElementsSelector';
import {
    getScore,
    getXPosition,
    getYPosition,
    getElementId,
    getElementLocation,
    getLocationPosition,
    isElementLocked,
    getLocationParentId,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getAclIdsSelector } from '../../utils/permissions/permissionsSelector';
import { getPermission } from '../../../common/permissions/elementPermissionsUtil';
import { ensureElementPositionIsInteger } from '../../../common/elements/utils/elementPositionUtils';
import { validateElementTypeSavePermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { toArray } from '../../../common/utils/immutableHelper';

import * as baseElementActions from '../../../common/elements/elementActions';

// Selectors
import { elementGraphSelector } from '../selectors/elementGraphSelector';
import { getElement, getElements } from '../selectors/elementSelector';
import { boardResourcesSelector } from '../board/boardSelector';
import { getCurrentBoardId } from '../selectors/currentBoardSelector';

// Measurements
import { getMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Actions
import { setInboxFromMultiElementMove } from '../../inbox/inboxActions';
import { createTaskInEmptyTaskListsAfterMoves } from '../taskList/taskListMoveActions';
import { deselectAllElements } from '../selection/selectionActions';
import { handleConnectedElementMoves } from '../connections/elementConnectionsActions';

// Constants
import * as ELEMENTS_ACTION_TYPE from '../../../common/elements/elementsConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { PERMISSION_VALUES } from '../../../common/permissions/permissionConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';

const getFromLocation = (initialState, elementId) => {
    const initialElementState = getElement(initialState, { elementId });
    return initialElementState.get('location').toJS();
};

const addFromLocation = (initialState) => (move) => {
    move.from = getFromLocation(initialState, move.id);
    return move;
};

export const getInboxMoveScoreGroupKey = (move) => `${move.location.parentId}.${move.location.position.index}`;

const getCanvasMoveScoreGroupKey = (move) => `${move.location.position.group}`;

const moveRequiresScoreCalculation = (elements, boardResourcesState) => (move) => {
    const { location } = move;

    // If it already has a score, use it rather than calculate it again (useful when undoing)
    if (location.position.score != null) return false; // eslint-disable-line eqeqeq

    // check to see if we have access to the children of the board that's having a child added to its inbox
    const parentBoardResource = boardResourcesState?.[location.parentId];
    const parent = elements.get(location.parentId);

    // If we don't have it, then we can't calculate the score of the new item, so we just leave it as null for the
    // server to take care of
    if (!parent || (isBoard(parent) && !isAsyncEntityFetched(parentBoardResource))) return false;

    return true;
};

const resetScores = ({ parentId, section }) => ({
    type: ELEMENTS_ACTION_TYPE.ELEMENTS_RESET_SCORES,
    parentId,
    section,
    sync: true,
});

/**
 * This function returns the elements that a move group will need to position themselves amongst.
 * For example, a move to an INBOX will require all other inbox elements to check scores against.
 * For a move to the CANVAS all overlapping elements will be required to check scores against.
 */
const getGroupDestinationElements = (getState, moveGroup, measurements) => {
    const state = getState();

    const firstMove = head(moveGroup);
    const { section, parentId } = get('location', firstMove);

    const elements = getElements(state);
    const elementGraph = elementGraphSelector(state);
    const currentParentElements = getChildrenViaGraph({ elements, elementGraph, parentId }).valueSeq().toJS();

    if (section === BoardSections.INBOX) return currentParentElements.filter(isLocationInbox);

    return getAllOverlappingElements(moveGroup, currentParentElements, measurements);
};

const getGroupScores = (getState, moveGroup, measurements) => {
    const { location } = head(moveGroup);

    const currentScores = getGroupDestinationElements(getState, moveGroup, measurements)
        .map(getScore)
        .sort(numbersAscending);

    return getNewScores(currentScores, location.position.index, moveGroup.length);
};

const buildScoreMap =
    (dispatch, getState, { measurementsStore }) =>
    (moves) => {
        const measurementsState = measurementsStore.getState();
        const measurements = getMeasurementsMap(measurementsState);

        const state = getState();
        const elements = state.get('elements');
        const boardResourcesState = boardResourcesSelector(state);

        const movesRequiringScoreCalculation = moves.filter(
            moveRequiresScoreCalculation(elements, boardResourcesState),
        );

        // Group moves by parentId.index
        const allInbox = moves.every((move) => move.location.section === BoardSections.INBOX);
        const groupedMoves = allInbox
            ? groupBy(movesRequiringScoreCalculation, getInboxMoveScoreGroupKey)
            : groupBy(movesRequiringScoreCalculation, getCanvasMoveScoreGroupKey);

        // Calculate the scores for each one of those groups
        return mapValues(groupedMoves, (moveGroup) => {
            let newScores = getGroupScores(getState, moveGroup, measurements);

            // If the score is null a collision has occurred, so we need to refresh all elements scores before attempting
            // to insert this new / moved element
            if (newScores == null) {
                // eslint-disable-line eqeqeq
                dispatch(resetScores(head(moveGroup).location));

                // Try to calculate the scores again
                newScores = getGroupScores(getState, moveGroup, measurements) || [];
            }

            return {
                currentIndex: 0,
                newScores,
            };
        });
    };

/**
 * Updates the location object with a score if necessary.
 * The score is used in both the INBOX and the CANVAS to determine their order.
 * In the INBOX a higher score will mean they are lower in the list.
 * In the CANVAS a higher score will mean they will sit on top of other elements.
 *
 * If a score collision happens (i.e. two elements end up with the same score) this method will also dispatch the
 * 'ELEMENTS_RESET_SCORES' action which will recalculate the scores for every element and then attempt to calculate the
 * score again.
 */
export const updateLocation =
    (dispatch, getState, { measurementsStore }) =>
    (moves) => {
        const scoreMap = buildScoreMap(dispatch, getState, { measurementsStore })(moves);

        const state = getState();
        const elements = state.get('elements');
        const boardResourcesState = boardResourcesSelector(state);

        return (move) => {
            if (!moveRequiresScoreCalculation(elements, boardResourcesState)(move)) return move;

            const scoreMapKey =
                move.location.section === BoardSections.INBOX
                    ? getInboxMoveScoreGroupKey(move)
                    : getCanvasMoveScoreGroupKey(move);

            const scoreEntry = scoreMap[scoreMapKey];
            const { currentIndex = 0, newScores = [] } = scoreEntry;
            set(move, 'location.position.score', newScores[currentIndex]);
            set(scoreEntry, 'currentIndex', currentIndex + 1);

            return move;
        };
    };

/**
 * Ensures that a move won't result in a recursive structure.
 * NOTE: This only checks the initial state, not the end state! It would be possible to
 * configure a move such that a recursive structure was possible.
 */
const checkMoveSafety = (state) => (move) => {
    const elements = getElements(state);

    if (move.location.parentId.indexOf(move.id) !== -1) {
        console.warn('Paradox: Unable to perform move as the element is being moved onto itself.', move);
        return false;
    }

    const ancestorIds = getPhysicalAncestorIds(elements, move.location.parentId);

    // If the moved element exists in the ancestors of the
    if (includes(ancestorIds, move.id)) {
        console.warn("Paradox: Unable to perform move as the moved element exists in the location's ancestry.", move);
        return false;
    }

    const movedElement = getElementFromElementsMap(elements, move.id);
    const currentParentId = removeTrashSuffix(getLocationParentId(movedElement));
    const targetParentId = removeTrashSuffix(move.location.parentId);

    // Ensure we have the correct permission
    const aclIds = getAclIdsSelector(state);
    // Trashed element's original parents might not be loaded into the client store, so just assume that
    // the user has permission to move the element if it's in their trash, as they shouldn't be able to
    // view the element unless they do have permission to view the source board
    const sourcePermissions = isLocationTrash(movedElement)
        ? PERMISSION_VALUES.FULL_ACCESS
        : getPermission(elements, currentParentId, aclIds);
    const targetPermissions = getPermission(elements, targetParentId, aclIds);

    const canMoveFromSource = validateElementTypeSavePermission(sourcePermissions, movedElement);
    const canMoveToTarget = validateElementTypeSavePermission(targetPermissions, movedElement);

    return canMoveFromSource && canMoveToTarget;
};

/**
 * If the move being performed doesn't actually change anything (i.e. an element is dropped onto the same position
 * in a list) then don't perform the move, as it's a wasted effort.
 */
const shouldMove = (state) => (move, order) => {
    const { id, location } = move;

    // Can't move an element that we don't know about
    const element = getElement(state, { elementId: id });
    if (!element) return false;

    if (location.section !== BoardSections.INBOX) return true;

    const elements = getElements(state);

    const elementGraph = elementGraphSelector(state);
    const inboxElements = getSortedChildrenInSectionViaGraph({
        elements,
        elementGraph,
        parentId: location.parentId,
        section: location.section,
    });

    // The final index is the dropped location plus the order that the element is in when it's moved.
    const finalIndex = location.position.index + order;

    const elementAtIndex = inboxElements.get(finalIndex);

    return elementAtIndex ? getElementId(elementAtIndex) !== id : true;
};

/**
 * Deselects all elements if they're dropped onto a board.
 * NOTE: This also assumes that all moves
 */
const deselectOnBoardDrop =
    ({ moves, transactionId }) =>
    (dispatch, getState) => {
        const fromParentId = get([0, 'from', 'parentId'], moves);
        const destinationParentId = get([0, 'location', 'parentId'], moves);

        // Going to the same parentId so ignore
        if (fromParentId === destinationParentId) return;

        const state = getState();
        const currentBoardId = getCurrentBoardId(state);

        // If we're dropping onto the current board, ignore
        if (currentBoardId === destinationParentId) return;

        const element = getElement(state, { elementId: destinationParentId });

        if (isBoardLike(element)) dispatch(deselectAllElements({ transactionId }));
    };

/**
 * Dispatches a move event for an element, given the location to move the element to.
 * The location's position will be updated with a 'score' if necessary.
 */
export const moveMultipleElements =
    ({ moves, sync = true, transactionId = getNewTransactionId(), moveOperation, initialMeasurements }) =>
    (dispatch, getState, { measurementsStore }) => {
        // Add the from location to the moves
        const initialState = getState();

        const isSafeMultiMove = moves.every(checkMoveSafety(initialState));
        if (!isSafeMultiMove) {
            // TODO Dispatch a warning action that displays a message explaining why the move failed
            console.warn('Preventing moves as a paradox will occur.');
            return;
        }

        // TODO - Might be a good idea to do some clamping to prevent crazy numbers when moving.
        //  E.g. No more than the current canvasOrigin - A or canvasSize + B?

        let updatedMoves = moves
            .filter(shouldMove(initialState))
            // NOTE: This assumes that all moves are going to the same parentId & section.  If this assumption becomes
            //  incorrect, further work will need to be done here
            .map(updateLocation(dispatch, getState, { measurementsStore })(moves))
            .map(ensureElementPositionIsInteger)
            .map(addFromLocation(initialState))
            .map(addRestoreLocationForMovesToTrash);

        // If we no longer need to perform any moves, just finish
        if (!updatedMoves.length) return;

        updatedMoves = dispatch(handleConnectedElementMoves({ moves: updatedMoves }));

        dispatch(
            baseElementActions.moveMultipleElements({
                moves: updatedMoves,
                initialMeasurements,
                sync,
                transactionId,
                moveOperation,
            }),
        );

        // if you're dropping on a board that isn't the current one, open up it's unsorted notes
        dispatch(setInboxFromMultiElementMove({ moves: updatedMoves, transactionId }));

        // If you've moved all the Tasks out of a TaskList then add a new Task to that TaskList
        dispatch(createTaskInEmptyTaskListsAfterMoves({ moves: updatedMoves, transactionId }));

        dispatch(deselectOnBoardDrop({ moves: updatedMoves, transactionId }));
    };

const syncMoves = ({ moves, moveOperation, dispatch }) => dispatch(moveMultipleElements({ moves, moveOperation }));
const debouncedSyncMoves = debounce(syncMoves, 500);

export const shiftSelectedElements =
    ({ x = 0, y = 0, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) => {
        // Then get the selected elements again and perform the move
        const state = getState();
        const selectedElements = getSelectedElements(state);

        const selectedCanvasElements = selectedElements.filter((element) => {
            if (!isLocationCanvas(element)) return false;
            if (isElementLocked(element)) return false;

            return true;
        });

        if (selectedCanvasElements.size < 1 || (x === 0 && y === 0)) return;

        const selectedElementIds = toArray(selectedElements.map(getElementId));
        let movedElements = selectedCanvasElements;

        // If there's any lines selected, then also select their connected element IDs
        const connectedElementIds = getConnectingElementIds(selectedElements);

        if (!isEmpty(connectedElementIds)) {
            const elements = getElements(state);

            connectedElementIds.forEach((connectedElementId) => {
                if (selectedElementIds.includes(connectedElementId)) return;

                const connectedElement = getElementFromElementsMap(elements, connectedElementId);

                // If the connected element could not be found in the elements map, don't shift it
                if (connectedElement) {
                    movedElements = movedElements.push(connectedElement);
                }
            });
        }

        // For now just work with canvas elements
        const moves = movedElements
            .map((element) => ({
                id: getElementId(element),
                location: {
                    ...getElementLocation(element).toJS(),
                    position: {
                        ...getLocationPosition(element).toJS(),
                        x: getXPosition(element) + x,
                        y: getYPosition(element) + y,
                    },
                },
            }))
            .toArray();

        if (!moves.length) return;

        debouncedSyncMoves({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.NUDGE, dispatch });

        return dispatch(
            moveMultipleElements({
                moves,
                moveOperation: ELEMENT_MOVE_OPERATIONS.NUDGE,
                sync: false,
                transactionId,
            }),
        );
    };
