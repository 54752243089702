import { DependencyList, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

/**
 * Used to debounce a function in a function component.
 *
 * NOTE:
 * - Due to typescript constraints, the fn passed currently only take in 3 arguments. Add more if necessary.
 * - Cannot specify return type since lodash type DebounceFunc is not exposed by the library.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDebouncedCallback = <Arg1Type, Arg2Type, Arg3Type, ReturnType>(
    fn: (arg1: Arg1Type, arg2: Arg2Type, arg3: Arg3Type) => ReturnType,
    delay: number,
    deps: DependencyList,
) => {
    const fnRef = useRef(fn);

    // In here, useRef will make sure that the debounced function does not get recreated every single time
    const debouncedFn = useRef(
        debounce<(arg1: Arg1Type, arg2: Arg2Type, arg3: Arg3Type) => ReturnType>(
            (arg1: Arg1Type, arg2: Arg2Type, arg3: Arg3Type) => fnRef.current(arg1, arg2, arg3),
            delay,
        ),
    );

    // On every dependency change, recreate the function ref so that it will have the latest values
    useEffect(() => {
        fnRef.current = fn;

        return () => {
            debouncedFn.current.cancel();
        };
    }, deps);

    return debouncedFn.current;
};

export default useDebouncedCallback;
