import Handsontable from 'handsontable/base';
import { VerticalAlignment } from '../../../../common/table/CellTypeConstants';
import { TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT } from '../../../../common/table/tableConstants';

/*************************
 * TABLE ELEMENTS
 * **********************/

export const getTableScrollableElement = (hotTableContainer: HTMLDivElement | null): Element | null =>
    hotTableContainer && hotTableContainer.querySelector('.wtHolder');

export const getContainerWidth = (hotTableContainer: HTMLDivElement | null): number | undefined =>
    hotTableContainer?.parentElement?.getBoundingClientRect?.()?.width;

export const getTableDOMElementById = (id: string): HTMLDivElement | null =>
    document.querySelector<HTMLDivElement>(`.Table#el-${id}`);

export const getSelectedTableElement = (): HTMLDivElement | null =>
    document.querySelector<HTMLDivElement>('.Table.selected-single');

export const querySelectedTableElement = <T extends Element>(selectors: string): T | null => {
    const selectedTableElement = getSelectedTableElement();
    return selectedTableElement && selectedTableElement.querySelector<T>(selectors);
};

export const getHotElementFromHotInstance = (hotInstance: Handsontable): HTMLDivElement | null =>
    hotInstance?.rootElement as HTMLDivElement;

export const getHotTableElementFromHotInstance = (hotInstance: Handsontable): HTMLDivElement | null =>
    getHotElementFromHotInstance(hotInstance)?.parentElement as HTMLDivElement;

export const getTableElementFromHotInstance = (hotInstance: Handsontable): HTMLDivElement | null =>
    getHotElementFromHotInstance(hotInstance)?.parentElement?.parentElement as HTMLDivElement;

/*************************
 * MOVE ROW/COL
 * **********************/

export const getColumnBacklightElement = (): HTMLDivElement | null =>
    querySelectedTableElement('.selected-single .ht__manualColumnMove--backlight');
export const getRowBacklightElement = (): HTMLDivElement | null =>
    querySelectedTableElement('.selected-single .ht__manualRowMove--backlight');

export const getColumnGuidelineElement = (): HTMLDivElement | null =>
    querySelectedTableElement('.selected-single .ht__manualColumnMove--guideline');
export const getRowGuidelineElement = (): HTMLDivElement | null =>
    querySelectedTableElement('.selected-single .ht__manualRowMove--guideline');

/*************************
 * CELLS
 * **********************/

export const getHighlighted = (): HTMLDivElement | null => querySelectedTableElement('.highlight');

/*************************
 * GET INFO FROM DOM
 * **********************/

export const getHotTableWidthById = (id: string): number | undefined =>
    getTableDOMElementById(id)?.querySelector('.HotTable')?.getBoundingClientRect?.()?.width;

export const getCellVerticalAlignmentClassName = (verticalAlignment: VerticalAlignment): string => {
    verticalAlignment = verticalAlignment || TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT;

    switch (verticalAlignment) {
        case VerticalAlignment.TOP:
            return 'htTop';
        case VerticalAlignment.MIDDLE:
            return 'htMiddle';
        case VerticalAlignment.BOTTOM:
            return 'htBottom';
    }
};

/*************************
 * ADJUST DOM ELEMENTS
 * **********************/

export const manuallySetTableElementWidthPx = (hot: Handsontable, width: number): void => {
    const tableElement = getTableElementFromHotInstance(hot);
    if (tableElement) tableElement.style.width = `${width}px`;
};

/*************************
 * APPLY CLASSES MANUALLY
 * **********************/

export const addClassToTableElement = (hot: Handsontable | null, className: string): void => {
    if (!hot) return;

    const tableElement = getTableElementFromHotInstance(hot);
    if (tableElement) tableElement.classList.add(className);
};

export const removeClassFromTableElement = (hot: Handsontable | null, className: string): void => {
    if (!hot) return;

    const tableElement = getTableElementFromHotInstance(hot);
    if (tableElement) tableElement.classList.remove(className);
};

export const addClassToHotTable = (hot: Handsontable, className: string): void => {
    const hotTable = getHotTableElementFromHotInstance(hot);
    if (hotTable) hotTable.classList.add(className);
};

export const removeClassFromHotTable = (hot: Handsontable, className: string): void => {
    const hotTable = getHotTableElementFromHotInstance(hot);
    if (hotTable) hotTable.classList.remove(className);
};
