// Actions
import { registerSuccess } from './authActions';

// Utils
import { sendAmplitudeEvent } from '../analytics/amplitudeService';

// Components
import createAuthTokenSuccessRedirect from './createAuthTokenSuccessRedirect';

// Constants
import { REGISTRATION_EVENT_NAMES } from '../../common/users/userConstants';
import { APP_QUERY_PARAMS } from '../../common/utils/urlConstants';

const onSuccess =
    (queryParams = {}) =>
    (dispatch, getState) => {
        const registrationEntryPoint = queryParams[APP_QUERY_PARAMS.REGISTRATION_ENTRY_POINT];

        if (registrationEntryPoint) {
            const eventType = REGISTRATION_EVENT_NAMES[registrationEntryPoint];
            eventType && sendAmplitudeEvent({ eventType });
        }

        return dispatch(registerSuccess());
    };

export default createAuthTokenSuccessRedirect(onSuccess);
