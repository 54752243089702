// Lib
import * as Immutable from 'immutable';
import { AnyAction } from 'redux';

// Constants
import { ELEMENT_SET_LOCAL_DATA_MULTI } from '../../../common/elements/elementConstants';

// Types
import { ElementLocalDataAction } from './elementLocalDataActions';
import { ImmutableMap } from '../../../common/utils/immutableHelper';

const setElementLocalData = (state: any, action: ElementLocalDataAction) =>
    state.update(action.id, (data: any) => {
        const originalData = data || Immutable.Map();
        return originalData.merge(action.data);
    });

const initialState: ImmutableMap = Immutable.Map();

export default function localElements(state: ImmutableMap = initialState, action: AnyAction) {
    switch (action.type) {
        case ELEMENT_SET_LOCAL_DATA_MULTI:
            return action.updates.reduce(
                (state: ImmutableMap, action: ElementLocalDataAction) => setElementLocalData(state, action),
                state,
            );
        default:
            return state;
    }
}
