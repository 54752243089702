// Lib
import { isEmpty, get, uniq } from 'lodash/fp';
import { createSelector } from 'reselect';

// Utils
import { asObject } from '../../../../common/utils/immutableHelper';
import { createDeepSelector, createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { getElementId, getPhysicalElementIdSelector } from '../../selectors/elementSelector';
import buildActivityMap from './buildActivityMap';
import buildBoardHierarchicalActivityMap from './buildHierarchicalActivityMap';

// Selectors
import cleansedNotificationsSelector from '../../../notifications/cleansedNotificationsSelector';
import { getCurrentUserRootBoardId } from '../../../user/currentUserSelector';
import { getCurrentBoardId } from '../../../reducers/currentBoardId/currentBoardIdSelector';
import { getNavigationHistory } from '../../../user/navigationHistory/navigationHistorySelector';
import {
    aliasIdToPhysicalIdMapSelector,
    fullVirtualBoardParentGraphSelector,
} from '../../selectors/elementGraphSelector';
import { areBoardHierarchiesInitialised } from '../../../app/initialisation/initialisationSelector';
import { getCurrentBoardVisibleInboxDescendantIds } from '../../selectors/currentBoardSelector';
import { getThreadCommentIdsSelector } from '../../comment/store/commentsSelector';
import { isPresentationModeEnabledSelector } from '../../../workspace/presentation/presentationSelector';

// Constants
import { ELEMENT_ACTIVITY_TYPES } from '../elementActivityConstants';

/**
 * Returns a map of element ID to its activity.
 * This includes boards and their IMMEDIATE child activity.
 * For example:
 *  If a user has added cards A, B and C, into board Z, there will be 4 entries.
 *  One for each card, A, B and C, and one for the board Z with a change count of "3"
 */
export const elementActivityMapSelector = createDeepSelector(
    cleansedNotificationsSelector,
    getNavigationHistory,
    getCurrentBoardId,
    (notifications, viewedBoards, currentBoardId) => buildActivityMap({ notifications, viewedBoards, currentBoardId }),
);

/**
 * Returns a map of board ID to its hierarchical activity.
 * This is the collation of all descendent activity.
 */
export const boardHierarchicalActivitySelector = createDeepSelector(
    getCurrentUserRootBoardId,
    elementActivityMapSelector,
    fullVirtualBoardParentGraphSelector,
    (rootBoardId, boardActivity, fullVirtualBoardParentGraph) =>
        buildBoardHierarchicalActivityMap({
            rootBoardId,
            boardActivity,
            fullVirtualBoardParentGraph,
        }),
);

const EMPTY_LIST = [];
/**
 * This selector returns an array of notification IDs that being displayed on the current board.
 *
 * The smart selector is the perfect type of selector here because it will very regularly return
 * a new array with the same entries.
 */
export const currentBoardObservedNotificationIdsSelector = createShallowSelector(
    getCurrentBoardId,
    boardHierarchicalActivitySelector,
    (currentBoardId, boardActivityMap) => {
        if (isEmpty(boardActivityMap)) return EMPTY_LIST;

        const notificationIds = get('notificationIds', boardActivityMap[currentBoardId]);

        return isEmpty(notificationIds) ? EMPTY_LIST : notificationIds;
    },
);

/**
 * An activity count for the inbox.
 */
export const currentBoardInboxActivityCountSelector = createSelector(
    getCurrentBoardVisibleInboxDescendantIds,
    aliasIdToPhysicalIdMapSelector,
    elementActivityMapSelector,
    boardHierarchicalActivitySelector,
    (inboxElementIds, aliasIdToPhysicalIdMap, elementActivityMap, boardActivityMap) => {
        let count = 0;

        inboxElementIds.forEach((elementId) => {
            // First check if we have an entry for the element in the hierarchical board activity map
            if (boardActivityMap[elementId]) {
                count += get('changeCount', boardActivityMap[elementId]) || 1;
                return;
            }

            // Second see if we have a direct activity entry for the element
            if (elementActivityMap[elementId]) {
                count += get('changeCount', elementActivityMap[elementId]) || 1;
                return;
            }

            // Otherwise it might be an alias element, so check to see if the linked board has an activity entry
            const linkedId = aliasIdToPhysicalIdMap[elementId];
            if (linkedId && boardActivityMap[linkedId]) {
                count += get('changeCount', boardActivityMap[linkedId]) || 1;
            }
        });

        return count;
    },
);

// Used by most elements to determine their activity count
export const individualElementActivitySelector = () =>
    createShallowSelector(
        getElementId,
        elementActivityMapSelector,
        isPresentationModeEnabledSelector,
        (elementId, elementActivityMap, isPresentationModeEnabled) =>
            isEmpty(elementActivityMap) || isPresentationModeEnabled ? null : elementActivityMap[elementId],
    );

// Used by boards and aliases to determine their activity count
export const individualBoardActivitySelector = () =>
    createDeepSelector(
        getPhysicalElementIdSelector,
        boardHierarchicalActivitySelector,
        isPresentationModeEnabledSelector,
        (elementId, boardActivityMap, isPresentationModeEnabled) =>
            isEmpty(boardActivityMap) || isPresentationModeEnabled ? null : boardActivityMap[elementId],
    );

// Used by comments to show their activity
export const individualCommentActivitySelector = () =>
    createDeepSelector(
        areBoardHierarchiesInitialised,
        (state, ownProps) => ownProps._id,
        elementActivityMapSelector,
        isPresentationModeEnabledSelector,
        (isReady, commentId, boardActivity, isPresentationModeEnabled) =>
            isReady && !isPresentationModeEnabled ? boardActivity[commentId] : null,
    );

// Used by collapsed comment threads to display its activity
export const individualCommentThreadActivitySelector = () =>
    createDeepSelector(
        areBoardHierarchiesInitialised,
        getThreadCommentIdsSelector(),
        elementActivityMapSelector,
        isPresentationModeEnabledSelector,
        (isReady, commentIds, boardActivity, isPresentationModeEnabled) => {
            if (!isReady || isPresentationModeEnabled) return null;

            if (commentIds.length === 0) return null;

            return commentIds.reduce((acc, commentId) => {
                const commentActivity = boardActivity[commentId];

                if (!commentActivity) return acc;

                if (!acc) return commentActivity;

                return {
                    type: ELEMENT_ACTIVITY_TYPES.COMMENT,
                    actorIds: uniq((asObject(acc.actorIds) || []).concat(asObject(commentActivity.actorIds))),
                    timestamp: Math.max(acc.timestamp, commentActivity.timestamp),
                    isReply: acc.isReply && commentActivity.isReply,
                    notificationIds: acc.notificationIds.concat(commentActivity.notificationIds),
                };
            }, null);
        },
    );
