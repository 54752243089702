import { asObject } from '../../../../common/utils/immutableHelper';
import { currentBoardIdSelector, getCurrentBoard } from '../../../element/board/boardSelector';
import { getElements } from '../../../element/selectors/elementsSelector';
import { getCurrentUserId, isCurrentUserStaff } from '../../../user/currentUserSelector';
import { contentTotalLimitSelector, elementCountSelector } from '../../../user/elementCount/elementCountSelector';
import { getNavigationHistory } from '../../../user/navigationHistory/navigationHistorySelector';
import logger from '../../../logger/logger';
import {
    currentBoardEditPermissionIdSelector,
    currentBoardHasPublicEditPermissionsSelector,
    currentBoardIsPublishedSelector,
    currentBoardPublishedPermissionIdSelector,
    currentBoardUserPermissionsSelector,
    publicEditAncestorBoardSelector,
    publishedAncestorBoardSelector,
} from '../../../utils/permissions/elementPermissionsSelector';
import {
    getBoardIdPublishPermissionIdMapSelector,
    getTokenAclIdsSelector,
} from '../../../utils/permissions/permissionsSelector';
import { getRedoStack, getUndoStack } from '../../../utils/undoRedo/undoRedoSelector';
import { showShareUtilSelector } from '../../../workspace/header/currentBoardHeader/currentBoardHeaderSelector';
import {
    currentBoardPublishLinkViewerCountSelector,
    currentBoardPublishLinkGuestViewerCountSelector,
} from '../../../workspace/header/currentBoardHeader/sharing/popup/publish/viewers/publishedBoardViewersSelector';
import { isPresentationModeEnabledSelector } from '../../../workspace/presentation/presentationSelector';

const SWIFT_EXPOSED_SELECTORS = {
    'BoardSelector.currentBoardIdSelector': currentBoardIdSelector,
    'BoardSelector.getCurrentBoard': getCurrentBoard,
    'CurrentUserSelector.getCurrentUserId': getCurrentUserId,
    'CurrentUserSelector.isCurrentUserStaff': isCurrentUserStaff,
    'CurrentUserSelector.getViewedBoardsSelector': getNavigationHistory,
    'ElementCountSelector.contentTotalCountSelector': elementCountSelector,
    'ElementCountSelector.contentTotalLimitSelector': contentTotalLimitSelector,
    'ElementPermissionsSelector.currentBoardEditPermissionIdSelector': currentBoardEditPermissionIdSelector,
    'ElementPermissionsSelector.currentBoardIsPublishedSelector': currentBoardIsPublishedSelector,
    'ElementPermissionsSelector.currentBoardPublishedPermissionIdSelector': currentBoardPublishedPermissionIdSelector,
    'ElementPermissionsSelector.currentBoardUserPermissionsSelector': currentBoardUserPermissionsSelector,
    'ElementPermissionsSelector.currentBoardHasPublicEditPermissionsSelector':
        currentBoardHasPublicEditPermissionsSelector,
    'ElementPermissionsSelector.publicEditAncestorBoardSelector': publicEditAncestorBoardSelector,
    'ElementPermissionsSelector.publishedAncestorBoardSelector': publishedAncestorBoardSelector,
    'ElementsSelector.getElements': getElements,
    'UndoRedoSelector.getRedoStack': getRedoStack,
    'UndoRedoSelector.getUndoStack': getUndoStack,
    'PermissionsSelector.getTokenAclIdsSelector': getTokenAclIdsSelector,
    'PresentationSelector.isPresentationModeEnabledSelector': isPresentationModeEnabledSelector,
    'PublishedBoardViewersSelector.currentBoardPublishLinkViewerCountSelector':
        currentBoardPublishLinkViewerCountSelector,
    'PublishedBoardViewersSelector.currentBoardPublishLinkGuestViewerCountSelector':
        currentBoardPublishLinkGuestViewerCountSelector,

    'Test.getBoardIdPublishPermissionIdMapSelector': getBoardIdPublishPermissionIdMapSelector,
    'Test.showShareUtilSelector': showShareUtilSelector,
};

// Supports allowing the Swift apps to invoke selectors to access data from the store.
// Exposed selectors will need to be defined above.
const swiftSelectorApiMiddleware = (store) => {
    window.swiftInvokeSelector = ({ selectorName, params }) => {
        const selector = SWIFT_EXPOSED_SELECTORS[selectorName];
        if (!selector) {
            logger.error('Unable to find exposed selector with key: ' + selectorName);
            return;
        }
        return asObject(selector(store.getState(), params));
    };
    return (next) => (action) => next(action);
};

export default swiftSelectorApiMiddleware;
