// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash/fp';
import { round } from 'lodash';

// Utils
import { scale } from '../../../common/maths/geometry/point';
import {
    getElementId,
    getMedia,
    getMediaHeight,
    getMediaOriginalHeight,
    getMediaOriginalWidth,
    getMediaWidth,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';
import { hasChanged } from '../../utils/react/propsComparisons';
import { prop } from '../../../common/utils/immutableHelper';

// Components
import measurementsConnect from '../../components/measurementsStore/measurementsConnect';

// Selectors
import { getMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';
import { getElementDOMMeasurement } from '../../utils/domUtil';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

const elementHasChanged = hasChanged('element');

const getDefaultWidthFn = () => ELEMENT_DEFAULT_WIDTH;

const mapDispatchToProps = (dispatch) => ({
    getCurrentMeasurements: (elementId) =>
        dispatch((_dispatch, getState) => {
            const state = getState();
            const measurementsMap = getMeasurementsMap(state);
            return measurementsMap.get(elementId);
        }),
});

export default ({ getMinHeight, getMinWidth, getDefaultWidth = getDefaultWidthFn, getDefaultMaxWidth, getIsEnabled }) =>
    (DecoratedComponent) => {
        @measurementsConnect(null, mapDispatchToProps)
        class ElementSaveMediaResizeDecorator extends React.Component {
            constructor(props) {
                super(props);

                // Static height is the space that resizing shouldn't effect.
                // For example the description and title in a link should remain a constant size while resizing.
                // This isn't entirely true as the caption might wrap onto new lines or previous lines if the new width
                // allows, but it's good enough for our purposes.
                this.staticHeight = null;

                this.state = {
                    tempMediaSize: null,
                };
            }

            componentWillReceiveProps(nextProps) {
                if (elementHasChanged(this.props, nextProps)) {
                    this.staticHeight = null;
                }
            }

            getMediaSize = (elementSizeGridUnits) => {
                const { gridSize } = this.props;

                const desiredElementSizePx = scale(gridSize, elementSizeGridUnits);
                const desiredWidthPx = desiredElementSizePx.width;
                const desiredHeightPx = desiredElementSizePx.height - this.staticHeight;

                const minHeightPx = gridSize * getMinHeight(this.props);
                const minWidthPx = gridSize * getMinWidth(this.props);

                return {
                    width: Math.max(minWidthPx, desiredWidthPx),
                    height: Math.max(minHeightPx, desiredHeightPx),
                };
            };

            setTempElementSize = (elementSizeGridUnits) => {
                const { setTempElementSize } = this.props;

                if (isNull(this.staticHeight)) this.findStaticHeight();

                elementSizeGridUnits && this.setState({ tempMediaSize: this.getMediaSize(elementSizeGridUnits) });
                setTempElementSize(elementSizeGridUnits);
            };

            setElementSize = (elementSizeGridUnits) => {
                if (isNull(this.staticHeight)) this.findStaticHeight();

                const mediaSize = this.getMediaSize(elementSizeGridUnits);
                this.updateSize(elementSizeGridUnits, mediaSize);
            };

            setMediaSize = (mediaSizePx) => {
                if (isNull(this.staticHeight)) this.findStaticHeight();

                const { gridSize } = this.props;

                const staticHeightGU = this.staticHeight / gridSize;

                const elementSize = {
                    width: mediaSizePx.width / gridSize,
                    height: mediaSizePx.height / gridSize + staticHeightGU,
                };
                this.updateSize(elementSize, mediaSizePx);
            };

            updateSize = (sizeGridUnits, mediaSizePx) => {
                const { setElementSize, dispatchUpdateElement, element, gridSize } = this.props;

                this.staticHeight = null;

                this.setState({ tempMediaSize: null });

                let initialMediaState = getMedia(element);
                initialMediaState = initialMediaState && initialMediaState.toJS();

                const targetWidthGridUnits = Math.max(Math.round(sizeGridUnits.width), getMinWidth(this.props));

                let targetWidthPx = Math.max(mediaSizePx.width, getMinWidth(this.props));
                let targetHeightPx = Math.max(mediaSizePx.height, getMinHeight(this.props));

                const heightGridUnits = (targetWidthGridUnits / targetWidthPx) * targetHeightPx;

                // When snapping the height the px is the number of pixels rounded to a grid point minus two for borders
                targetHeightPx = Math.round(heightGridUnits) * gridSize - 2;
                // The target width must also be adjusted because the height is set using padding which is proportional
                // to the width
                targetWidthPx = Math.round(targetWidthPx / gridSize) * gridSize - 2;

                dispatchUpdateElement({
                    id: getElementId(element),
                    changes: {
                        width: targetWidthGridUnits,
                        media: {
                            ...initialMediaState,
                            width: targetWidthPx,
                            height: targetHeightPx,
                        },
                    },
                });

                setElementSize(sizeGridUnits);
            };

            findStaticHeight = () => {
                const { element } = this.props;

                const mediaBoundingRect = this.mediaElement.getBoundingClientRect();

                // We need the exact measurements at this point in time so we unfortunately can't use the measurements
                // store.  The measurements store can potentially lag in the update, which causes an incorrect static
                // height from being calculated.
                const elementSize = getElementDOMMeasurement(getElementId(element));

                this.staticHeight = prop('height', elementSize) - mediaBoundingRect.height;

                return this.staticHeight;
            };

            handleDoubleClick = (event) => {
                const { gridSize, element } = this.props;

                if (!getDefaultMaxWidth) return;

                event.preventDefault();
                event.stopPropagation();

                const defaultWidth = getDefaultWidth();

                // Get original aspect ratio
                const mediaWidth = getMediaWidth(element) || defaultWidth;
                const mediaHeight = getMediaHeight(element) || defaultWidth;

                const originalMediaWidth = getMediaOriginalWidth(element) || mediaWidth;
                const originalMediaHeight = getMediaOriginalHeight(element) || mediaHeight;

                const originalAspectRatio = originalMediaWidth / originalMediaHeight;

                // Get current aspect ratio
                const currentAspectRatio = mediaWidth / mediaHeight;

                const aspectRatiosAreEqual = round(currentAspectRatio, 1) === round(originalAspectRatio, 1);

                const size = {};

                if (aspectRatiosAreEqual) {
                    // If aspect ratios are equal we want to double check
                    const savedWidth = getWidth(element) || defaultWidth;

                    const defaultMaxWidth = getDefaultMaxWidth(this.props);
                    size.width = savedWidth === defaultWidth ? defaultMaxWidth / gridSize : defaultWidth;
                } else {
                    size.width = mediaWidth / gridSize;
                }

                const staticHeightGU = this.findStaticHeight() / gridSize;

                size.height = size.width / originalAspectRatio + staticHeightGU;

                return this.setElementSize(size);
            };

            mediaElementRef = (node) => {
                this.mediaElement = node;
            };

            registerMediaElement = (node) => React.cloneElement(node, { ref: this.mediaElementRef });

            render() {
                let additionalProps = null;

                if (getIsEnabled(this.props)) {
                    additionalProps = {
                        tempMediaSize: this.state.tempMediaSize,
                        setElementSize: this.setElementSize,
                        setTempElementSize: this.setTempElementSize,
                        setMediaSize: this.setMediaSize,
                        registerMediaElement: this.registerMediaElement,
                        handleDoubleClick: this.handleDoubleClick,
                    };
                }

                return <DecoratedComponent {...this.props} {...additionalProps} />;
            }
        }

        ElementSaveMediaResizeDecorator.propTypes = {
            element: PropTypes.object.isRequired,
            setElementSize: PropTypes.func.isRequired,
            setTempElementSize: PropTypes.func.isRequired,
            getCurrentMeasurements: PropTypes.func,
            dispatchUpdateElement: PropTypes.func,
            gridSize: PropTypes.number,
        };

        return ElementSaveMediaResizeDecorator;
    };
