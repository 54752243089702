import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setGridSize } from './gridActions';
import platformSingleton from '../../platform/platformSingleton';
import { getGridSizeBodyClassName, calculateGridSize, getStoredGridSize } from './gridUtils';
import { setGridSizeLocalStorage } from './gridSizeStorageService';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';
import { isPlatformIframe } from '../../platform/utils/platformDetailsUtils';

import { prop } from '../../../common/utils/immutableHelper';

import { GRID_SIZE_LIST } from './gridConstants';

const mapStateToProps = (state) => ({
    gridSize: state.getIn(['app', 'gridSize']),
    platformDetails: getPlatformDetailsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateGridSize: (size, name) => dispatch(setGridSize(size, name)),
});

const getSizeName = prop('name');
const getSizeInitialized = prop('initialized');

const ALL_GRID_SIZE_CLASS_NAMES = GRID_SIZE_LIST.map((gridSize) => getGridSizeBodyClassName(gridSize.name));

const GridSizeManager = ({ gridSize, updateGridSize, platformDetails }) => {
    const removeBodyClass = () => {
        document.body.classList.remove(...ALL_GRID_SIZE_CLASS_NAMES);
        document.documentElement.classList.remove(...ALL_GRID_SIZE_CLASS_NAMES);
    };

    const setBodyClass = (sizeName) => {
        const className = getGridSizeBodyClassName(sizeName);

        removeBodyClass();
        document.body.classList.add(className);
        document.documentElement.classList.add(className);
    };

    // on grid size change
    useEffect(() => {
        if (!getSizeInitialized(gridSize)) return;

        const sizeName = getSizeName(gridSize);

        if (sizeName) {
            setBodyClass(sizeName);
            !isPlatformIframe(platformDetails) && setGridSizeLocalStorage(sizeName);
        }

        return removeBodyClass;
    }, [gridSize]);

    // on mount, set grid size for electron app
    useEffect(() => {
        if (getSizeInitialized(gridSize)) return;

        const { size, name } =
            getStoredGridSize() || calculateGridSize(screen.width, screen.height, platformSingleton.features.isTouch);
        updateGridSize(size, name);
    }, []);

    return null;
};

GridSizeManager.propTypes = {
    gridSize: PropTypes.object,
    updateGridSize: PropTypes.func,
    platformDetails: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridSizeManager);
