import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuPasteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-paste"
        {...props}
    >
        <g fill="#323B4A">
            <path d="M14.544 2.25a1.5 1.5 0 0 1 1.5 1.5v2.192a1.5 1.5 0 0 1-1.5 1.5h-6.03a1.5 1.5 0 0 1-1.5-1.5V3.75a1.5 1.5 0 0 1 1.5-1.5h6.03zm0 1.5h-6.03v2.192h6.03V3.75z" />
            <path d="M7.765 4.096a.75.75 0 1 1 0 1.5H5.882c-.628 0-1.132.494-1.132 1.096v12.923c0 .602.504 1.097 1.132 1.097h11.294c.63 0 1.133-.495 1.133-1.097v.154a.75.75 0 0 1 1.5 0v-.154c0 1.437-1.182 2.597-2.633 2.597H5.882c-1.45 0-2.632-1.16-2.632-2.597V6.692c0-1.437 1.182-2.596 2.632-2.596h1.883zm9.411 0c1.451 0 2.633 1.16 2.633 2.596v1.693a.75.75 0 1 1-1.5 0V6.692c0-.602-.504-1.096-1.133-1.096h-1.882a.75.75 0 0 1 0-1.5h1.882z" />
            <path d="M10.588 13.327a.75.75 0 1 0 0 1.5H20a.75.75 0 1 0 0-1.5h-9.412z" />
            <path d="M16.76 9.85a.75.75 0 0 0-1.05 1.07l3.219 3.157-3.219 3.157a.75.75 0 0 0-.082.976l.072.084a.75.75 0 0 0 1.06.01l3.765-3.692a.75.75 0 0 0 0-1.07L16.76 9.848z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuPasteIcon);
export default Memo;
