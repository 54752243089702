// Lib
import { includes } from 'lodash';

interface RangeArrayEntry {
    id: string;
    range: number;
}

/**
 * Determines the index that corresponds to the "scaledPosTop" pixels from the top of the list, based
 * on the range array for items in the list.
 */
export default (
    // The "scaled" position from the top of the list, meaning that it's the number of pixels from the top
    //  of the list in the scaled coordinate system.  Generally this should simply be found by determining
    //  the event's y position and subtracting it from the bounding rect position of the top of the list
    scaledPosTop: number,
    // An ordered array of each item's vertical centre point, in unscaled coordinates.
    //  Thus, when comparing with the scaledPosTop we need to scale these coordinates
    unscaledRanges: Array<RangeArrayEntry>,
    // The current scale that the list is being rendered in
    zoomScale = 1,
    // Any element IDs that shouldn't be included when comparing the range, because they're part of the dragged
    //  items, for example
    invalidIds?: Array<string>,
): number => {
    let currentIndex = 0;
    let currentValidIndex = 0;
    let currentRange = unscaledRanges[currentIndex];
    while (currentRange && scaledPosTop > currentRange.range * zoomScale) {
        currentIndex++;

        if (!includes(invalidIds, currentRange.id)) {
            currentValidIndex = currentIndex;
        }

        currentRange = unscaledRanges[currentIndex];
    }
    return currentValidIndex;
};
