/**
 * A singleton is used here because it's not very important for this data to exist in the
 * redux store (and cause selectors to get run when the collection is updated).
 */
// Map - source url: boolean (loaded or not)
const progressiveImagesSingleton = {};

export const hasProgressiveImageLoaded = (source) => progressiveImagesSingleton[source]?.loaded || false;

export const hasProgressiveImageFailed = (source) => progressiveImagesSingleton[source]?.error || false;

export const markProgressiveImageAsLoaded = ({ source }) => {
    progressiveImagesSingleton[source] = { loaded: true, error: false };
};

export const markProgressiveImageAsFailed = ({ source }) => {
    progressiveImagesSingleton[source] = { loaded: false, error: true };
};
