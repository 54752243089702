// Lib
import React, { ReactElement } from 'react';
import MobilePage from './MobilePage';
import MobileHeader from './MobileHeader';
import MobilePageBody from './MobilePageBody';

const MobilePageEmptyLoader = (): ReactElement => (
    <MobilePage className="MobilePageEmptyLoader">
        <MobileHeader />
        <MobilePageBody />
    </MobilePage>
);

export default MobilePageEmptyLoader;
