// Lib
import { flatMap, get, intersection } from 'lodash';

// Utils
import { getX, getY } from '../../maths/geometry/dimensionUtil';

// Constants
import { BoardSections } from '../../boards/boardConstants';
import * as ACTION_TYPES from '../elementConstants';
import {
    CANVAS_MOVE_MEANINGFUL_DIST_SQRD,
    INBOX_MOVE_MEANINGFUL_DIST,
    UPDATE_MEANINGFUL_CHANGE_KEY_LIST,
} from '../../notifications/notificationConstants';

// Types
import { ActionMove, ActionObject } from '../../actions/actionTypes';
import { MNElementPosition } from '../elementModelTypes';

// ----------------------
// The following take an element action & decide whether it is
// a meaningful change or not
// ----------------------

/**
 * Determines if the move action should be treated as a "significant modification".
 * Is significant if:
 *  - The parent changes
 *  - The section changes
 *  - The move distance is more than a card's default width
 */
const isMoveMeaningful = (action: ActionObject, move: ActionMove): boolean => {
    if (!move.from) return false;
    if (move.location.parentId !== move.from.parentId) return true;
    if (move.location.section !== move.from.section) return true;

    if (move.location.section === BoardSections.INBOX) {
        const index = get(move, ['location', 'position', 'index'], 0);
        const indexFrom = get(move, ['from', 'position', 'index'], index);
        const distanceMoved = Math.abs(index - indexFrom);
        return distanceMoved >= INBOX_MOVE_MEANINGFUL_DIST;
    }

    const pos: MNElementPosition = get(move, ['location', 'position'], { x: 0, y: 0 });
    const posFrom = get(move, ['from', 'position'], pos);

    const dx = getX(pos) - getX(posFrom);
    const dy = getY(pos) - getY(posFrom);
    const distanceMovedSqrd = dx * dx + dy * dy;

    return distanceMovedSqrd >= CANVAS_MOVE_MEANINGFUL_DIST_SQRD;
};

/**
 * Determines if the update action is a "significant modification".
 * Is significant if the change is to a property we're interested in, such as textContent.
 */
const isUpdateMeaningful = (action: ActionObject): boolean => {
    const { updates } = action;
    if (!updates) return false;

    const changeKeys = flatMap(updates, (update) => Object.keys(update.changes));
    const changeList = intersection(changeKeys, UPDATE_MEANINGFUL_CHANGE_KEY_LIST);

    return changeList.length > 0;
};

/**
 * Determines if the change action is considered a "significant modification".
 */
export const isChangeMeaningful = (action: ActionObject, data: ActionMove): boolean => {
    if (action.silent) return false;

    switch (action.type) {
        // ELEMENT_DIFF_UPDATE update would always have content changes
        case ACTION_TYPES.ELEMENT_CREATE:
        case ACTION_TYPES.ELEMENT_UPDATE_ACL:
        case ACTION_TYPES.ELEMENT_DIFF_UPDATE:
            return true;
        case ACTION_TYPES.ELEMENT_MOVE_MULTI:
            return isMoveMeaningful(action, data);
        case ACTION_TYPES.ELEMENT_UPDATE:
            return isUpdateMeaningful(action);
        default:
            return false;
    }
};

// ----------------------
// The following take an element action & decide whether it should be marked as a location section change.
// ----------------------

/**
 * Determines if the move action is changing parent ID or changing section.
 */
const isMoveLocationSectionChange = (action: ActionObject, move: ActionMove): boolean => {
    if (!move.from || !move.location) return false;

    if (move.location.parentId !== move.from.parentId) return true;
    if (move.location.section !== move.from.section) return true;

    return false;
};

/**
 * Determines if the action should result in a meta location section change timestamp update.
 */
export const isLocationSectionChange = (action: ActionObject, data: ActionMove): boolean => {
    switch (action.type) {
        case ACTION_TYPES.ELEMENT_CREATE:
            return true;
        case ACTION_TYPES.ELEMENT_MOVE_MULTI:
            return isMoveLocationSectionChange(action, data);
        default:
            return false;
    }
};

/**
 * Determines if the action
 */
export const isAclChange = (action: ActionObject): boolean => action.type === ACTION_TYPES.ELEMENT_UPDATE_ACL;
