// From https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
const getPageVisibilityApiNames = () => {
    if (typeof document.msHidden !== 'undefined') {
        return {
            hiddenPropName: 'msHidden',
            visibilityChangeEventName: 'msvisibilitychange',
        };
    }

    if (typeof document.webkitHidden !== 'undefined') {
        return {
            hiddenPropName: 'webkitHidden',
            visibilityChangeEventName: 'webkitvisibilitychange',
        };
    }

    // Opera 12.10 and Firefox 18 and later support
    return {
        hiddenPropName: 'hidden',
        visibilityChangeEventName: 'visibilitychange',
    };
};

const { hiddenPropName, visibilityChangeEventName } = getPageVisibilityApiNames();

export const pageVisibilityChangeEventName = visibilityChangeEventName;

/**
 * Will return true if the current tab is visible.
 */
export const getIsPageVisible = () => !document[hiddenPropName];
