// Lib
import { drop, first, last, split } from 'lodash/fp';

// Utils
import randomString from '../../../../common/uid/randomString';

const splitSlash = split('/');
const splitQuery = split('?');
const splitPoint = split('.');

export const getFilenameFromUrl = (url, fileType) => {
    const lastPart = last(splitSlash(url));
    const filename = first(splitQuery(lastPart));

    if (filename.indexOf('.') > -1) return filename;

    const extension = last(splitSlash(fileType));
    return `${filename}.${extension}`;
};

export const appendToFilename = (filename) => {
    const parts = splitPoint(filename);

    return [`${first(parts)}-${randomString(6)}`, ...drop(1, parts)].join('.');
};
