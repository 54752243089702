// Types
import { DBSchema } from 'idb';
import { MNElement } from '../../../../common/elements/elementModelTypes';

export const MN_OBJECTS_DATABASE_NAME = 'mn-objects-idb';
export const MN_OBJECTS_DATABASE_VERSION = 1;

export enum MnObjectsIdbStoreNames {
    elements = 'elements',
}

export enum MnObjectsIdbElementsStoreIndexNames {
    locationParentId = 'locationParentId',
    contentLinkTo = 'contentLinkTo',
}

export interface MnObjectsIdbSchemaV1 extends DBSchema {
    [MnObjectsIdbStoreNames.elements]: {
        key: string;
        value: MNElement;
        indexes: {
            [MnObjectsIdbElementsStoreIndexNames.locationParentId]: string;
            [MnObjectsIdbElementsStoreIndexNames.contentLinkTo]: string;
        };
    };
}
