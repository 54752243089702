export const TOOL_MODES = {
    DRAW: 'draw',
    MOVE: 'move',
    SELECT: 'select',
    ERASE: 'erase',
};

export const STROKE_SIZES = {
    SMALL: 4,
    MEDIUM: 8,
    LARGE: 16,
    EXTRA_LARGE: 32,
};

export const DRAWING_PATH_PROPERTIES = {
    COLOR: 'color',
    SIZE: 'size',
};
