// Lib
import { values } from 'lodash/fp';

// Utils
import { asObject } from '../../../common/utils/immutableHelper';
import * as pointsLib from '../../../common/maths/geometry/point';
import * as geometryUtils from '../../../common/maths/geometry/geometryUtils';
import { getTopLeftBoundaryPoint } from '../../../common/documentOrder/geometry/point';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isColumn } from '../../../common/elements/utils/elementTypeUtils';
import {
    getElementId,
    getElementLocation,
    getLineEnd,
    getLineStart,
} from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getLineLength, setLineLength } from '../../../common/maths/geometry/line';
import { getCurrentUserId } from '../../user/currentUserSelector';
import { getElements } from '../selectors/elementSelector';

// Actions
import { deselectAll } from '../../../common/elements/selectionActions';
import { atomicMoveAndUpdate } from '../actions/elementActions';

// Constants
import { LINE_EDGE, MIN_LINE_LENGTH } from '../../../common/lines/lineConstants';

/**
 * Updates a line's edge (e.g. snapping it to an element, or moving it to a position on the canvas).
 *
 * edge: LINE_EDGE.start | LINE_EDGE.end
 */
export const updateLineEdge =
    ({ element, edge, newPosition, newControl, transactionId, sync }) =>
    (dispatch, getState) => {
        const id = getElementId(element);

        const changes = {
            start: asObject(getLineStart(element)),
            end: asObject(getLineEnd(element)),
            [edge]: newPosition,
        };

        if (newControl) {
            changes.control = newControl;
        }

        // Ensure the line is at least a size of MIN_LINE_LENGTH
        const newLineDist = getLineLength(changes);

        if (newLineDist < MIN_LINE_LENGTH) {
            // Need to extend the line
            const mobileEdge = edge === LINE_EDGE.start ? changes.start : changes.end;
            const staticEdge = edge === LINE_EDGE.start ? changes.end : changes.start;

            const movedEdge = geometryUtils.roundVals(
                setLineLength({ start: staticEdge, end: mobileEdge, length: MIN_LINE_LENGTH }).end,
            );

            changes[edge] = {
                ...changes[edge],
                ...movedEdge,
            };
        }

        const endpoints = values(changes);
        const translation = getTopLeftBoundaryPoint(endpoints);

        const initialLocation = asObject(getElementLocation(element));

        // FIXME-LINES Update z-index
        // Translate the actual element position down
        const updatedLocation = {
            ...initialLocation,
            position: {
                ...initialLocation.position,
                ...pointsLib.translate(translation, initialLocation.position),
            },
        };

        // Reverse the translation on the endpoints
        changes.start = {
            ...changes.start,
            ...pointsLib.reverseTranslate(translation, changes.start),
        };
        changes.end = {
            ...changes.end,
            ...pointsLib.reverseTranslate(translation, changes.end),
        };

        const result = dispatch(atomicMoveAndUpdate({ id, changes, location: updatedLocation, transactionId, sync }));

        const state = getState();
        const elements = getElements(state);
        const startElement = getElement(elements, changes.start.elementId);
        const endElement = getElement(elements, changes.end.elementId);

        if (isColumn(startElement) && isColumn(endElement)) {
            const userId = getCurrentUserId(state);
            dispatch(deselectAll({ userId }));
        }

        return result;
    };
