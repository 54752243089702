// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { deepPropHasChanged, deepPropNow, deepPropStayedTrue } from '../utils/react/propsComparisons';
import { getUserId } from '../../common/users/utils/userPropertyUtils';
import { manuallyReportError } from '../analytics/rollbarService';

// Services
import { fetchUsers } from './userService';

// Selector
import { getAsyncResourceEntityState } from '../utils/services/http/asyncResource/asyncResourceSelector';

// Constants
import { TIMES } from '../../common/utils/timeUtil';
import { ROLLBAR_LEVELS } from '../analytics/rollbarConstants';
import { ResourceTypes } from '../utils/services/http/asyncResource/asyncResourceConstants';

// Prop comparison
const userIsNowInError = deepPropNow(['userResource', 'hasError']);
const userStayedInError = deepPropStayedTrue(['userResource', 'hasError']);
const errorCountHasChanged = deepPropHasChanged(['userResource', 'errorCount']);

const mapStateToProps = () =>
    createStructuredSelector({
        userResource: (state, { user }) =>
            user && getAsyncResourceEntityState(state, ResourceTypes.users, getUserId(user)),
    });

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchUser: (userId) => dispatch(fetchUsers([userId], true)),
});

export default (DecoratedComponent) => {
    @connect(mapStateToProps, mapDispatchToProps)
    class UserReFetchDecorator extends React.Component {
        componentWillMount() {
            this.reFetchOnMountIfNecessary(this.props);
        }

        componentWillReceiveProps(nextProps) {
            if (
                userIsNowInError(this.props, nextProps) ||
                (userStayedInError(this.props, nextProps) && errorCountHasChanged(this.props, nextProps))
            ) {
                this.reFetchUserIfNecessary(nextProps);
            }
        }

        componentWillUnmount() {
            this.refetchTimeoutId && clearTimeout(this.refetchTimeoutId);
        }

        reFetchOnMountIfNecessary = (props) => {
            const { userResource, dispatchFetchUser, user } = props;

            if (!userResource || !user) return;

            const errorTime = userResource?.errorTime;
            const errorTimeAgo = Date.now() - errorTime;

            if (userResource?.errorCount > 10) return;

            // If the component is mounting and the last error was more than 5 minutes ago, try again
            if (userResource?.errorCount > 3 && errorTimeAgo > 5 * TIMES.MINUTE) {
                return dispatchFetchUser(getUserId(user));
            }

            this.reFetchUserIfNecessary(props);
        };

        reFetchUserIfNecessary = (props) => {
            const { userResource, dispatchFetchUser, user } = props;

            if (!userResource || !user) return;
            if (userResource?.fetching) return;
            if (!userResource?.hasError) return;

            if (userResource?.errorCount === 4) {
                const error = userResource?.error?.data;
                manuallyReportError({
                    errorMessage: 'User failed to fetch 4 times',
                    level: ROLLBAR_LEVELS.WARN,
                    error,
                    custom: { userId: getUserId(user) },
                });
            }
            if (userResource?.errorCount > 3) return;

            const errorTime = userResource?.errorTime;
            const errorTimeAgo = Date.now() - errorTime;

            if (userResource?.errorCount > 1 && (!errorTime || errorTimeAgo < 30 * TIMES.SECOND)) {
                this.refetchTimeoutId = setTimeout(() => {
                    this.refetchTimeoutId = null;
                    this.reFetchUserIfNecessary(props);
                }, 30 * TIMES.SECOND - (errorTimeAgo || 0) + 10);
                return;
            }

            return dispatchFetchUser(getUserId(user));
        };

        render() {
            const { userResource, dispatchFetchUser, ...rest } = this.props; // eslint-disable-line no-unused-vars
            return <DecoratedComponent {...rest} />;
        }
    }

    UserReFetchDecorator.propTypes = {
        user: PropTypes.object,
        userResource: PropTypes.object,
        dispatchFetchUser: PropTypes.string,
    };

    return UserReFetchDecorator;
};
