import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AlignBottomIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon align-bottom"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M24 22.5a.75.75 0 1 1 0 1.5H8a.75.75 0 1 1 0-1.5zM14 11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h4zm8-3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h4zm-8.5 4.5h-3v6h3v-6zm8-3h-3v9h3v-9z"
            />
        </g>
    </svg>
);
const Memo = memo(AlignBottomIcon);
export default Memo;
