// Lib
import loadable from 'react-loadable';

export default loadable({
    loader: () =>
        import(
            /* webpackChunkName: "register" */
            './Register'
        ),
    loading: () => null,
});
