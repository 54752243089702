import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementCloneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon element-clone"
        {...props}
    >
        <path
            fill="#1B2536"
            fillOpacity={0.3}
            fillRule="evenodd"
            d="M15 7v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2zm-2-1H7a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-2-3v2h-1V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2v1H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2z"
        />
    </svg>
);
const Memo = memo(ElementCloneIcon);
export default Memo;
