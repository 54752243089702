import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getUsers } from '../../user/usersSelector';
import { getShortName } from '../../../common/users/userHelper';
import { listToEnglish, chooseSingular } from '../../../common/utils/stringUtil';

import TooltipSource from '../../components/tooltips/TooltipSource';
import FetchUsersOnMount from '../../user/fetchUsers/FetchUsersOnMount';

import './ElementReactionPillTooltip.scss';

const REACTION_TOOLTIP_MAX_NAMES = 6;

const reactingUserNamesSelector = createSelector(
    (_, { currentUserId }) => currentUserId,
    (_, { userIds }) => userIds,
    getUsers,
    (currentUserId, userIds, users) => {
        const missingUserIds = [];

        let reactingUserNames = userIds
            .sort((a, b) => {
                if (a === currentUserId) return -1;
                if (b === currentUserId) return 1;
                return 0;
            })
            .map((userId) => {
                const user = users.get(userId);

                if (!user) missingUserIds.push(userId);

                return userId === currentUserId ? 'You' : getShortName(user);
            });

        if (reactingUserNames.length > REACTION_TOOLTIP_MAX_NAMES) {
            const leftoverCount = reactingUserNames.length - REACTION_TOOLTIP_MAX_NAMES;

            reactingUserNames = [
                ...reactingUserNames.slice(0, REACTION_TOOLTIP_MAX_NAMES),
                `${leftoverCount} ${chooseSingular(leftoverCount, 'other', 'others')}`,
            ];
        }

        return {
            reactingUserNames,
            missingUserIds,
        };
    },
);

const mapStateToProps = createSelector(reactingUserNamesSelector, (reactingUserNamesResult) => reactingUserNamesResult);

const ElementReactionPillTooltipContent = connect(mapStateToProps)(
    ({ reactingUserNames = [], missingUserIds = [] }) => (
        <span className="ElementReactionPillTooltip">
            <FetchUsersOnMount userIds={missingUserIds} />
            <strong>{listToEnglish(reactingUserNames)}</strong> reacted
        </span>
    ),
);

const ElementReactionPillTooltip = ({ tooltipText, currentUserId, userIds, emojiCode, children }) => (
    <TooltipSource
        enabled
        triggerOnMouseEnter
        delay={300}
        tooltipText={
            <ElementReactionPillTooltipContent currentUserId={currentUserId} userIds={userIds} emojiCode={emojiCode} />
        }
    >
        {children}
    </TooltipSource>
);

ElementReactionPillTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    tooltipText: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    emojiCode: PropTypes.string,
    currentUserId: PropTypes.string,
    userIds: PropTypes.array,
    missingUserIds: PropTypes.array,
};

export default ElementReactionPillTooltip;
