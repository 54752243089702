// Lib
import { split, head, flow, replace } from 'lodash/fp';

// Constants
import { MEDIA_STORAGE_URL } from '../files/fileConstants';
import { IMAGE_TYPES } from '../media/mediaConstants';
import { APP_MEDIA_URL } from '../utils/urlConstants';

// regex that detects the domain name from a url
const ORIGIN_REGEX = /https:\/\/[a-zA-Z0-9\-.]+\//;

/**
 * Transforms a Presigned URL into the URL for rendering
 * @param {string} presignedUrl
 * @param {string} imageType
 * @returns
 */
export const generateRenderUrlFromPresignedUrl = (presignedUrl: string, imageType: string): string => {
    // If the presignedUrl is already an Application Media Path, return it
    // this use case is for the web clipper
    if (presignedUrl.startsWith(APP_MEDIA_URL)) return presignedUrl;

    // Render using the Application Media Path for Image Elements
    // Otherwise, render using the Media CDN
    const mediaUrl = imageType === IMAGE_TYPES.ELEMENT ? APP_MEDIA_URL : MEDIA_STORAGE_URL;

    return flow(split('?'), head, replace(ORIGIN_REGEX, mediaUrl))(presignedUrl);
};
