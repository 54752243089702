// Types
import { Rect } from '../geometry/rect/rectTypes';

// A point vector in 4 dimensions for use in matrix multiplication
export type PointVector4D = [number, number, number, number];

// Points representing the D
export type RectPointVectors = [PointVector4D, PointVector4D, PointVector4D, PointVector4D];

/**
 * Array of points that can be used in matrix transformations:
 * [
 *   [top left 1 1],
 *   [top right 1 1],
 *   [bottom right 1 1],
 *   [bottom left 1 1]
 * ]
 */
export const convertToRectPoints = (rect: Rect): RectPointVectors => {
    if (!rect) {
        return [
            [0, 0, 1, 1],
            [0, 0, 1, 1],
            [0, 0, 1, 1],
            [0, 0, 1, 1],
        ];
    }

    return [
        [rect.left, rect.top, 1, 1],
        [rect.right, rect.top, 1, 1],
        [rect.right, rect.bottom, 1, 1],
        [rect.left, rect.bottom, 1, 1],
    ];
};

export const convertToRect = (rectPoints: RectPointVectors): Rect => {
    const top = rectPoints[0][1];
    const left = rectPoints[0][0];
    const bottom = rectPoints[2][1];
    const right = rectPoints[2][0];

    return {
        x: left,
        y: top,
        top,
        left,
        bottom,
        right,
        width: right - left,
        height: bottom - top,
    };
};
