import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DragIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="Icon drag" {...props}>
        <defs>
            <filter
                id="DragIcon__a"
                width="181.2%"
                height="181.2%"
                x="-40.6%"
                y="-40.6%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#DragIcon__a)" transform="translate(4.022 2.975)">
            <path
                fill="#000"
                d="M3.283 6.641a2.363 2.363 0 0 0-1.46-.63c-.76-.134-1.534.352-1.756 1.13a2.133 2.133 0 0 0 .228 1.615c.105.183.232.355.448.606l.573.652.157.188c.043.055.08.105.117.158l.234.367.89 1.457c.058.09.102.158.128.191l.847.941.79.748c.138.13.264.242.385.346.06.052.11.181.131.371.018.16.012.34-.008.513l-.018.12a.375.375 0 0 0 .369.446l.78.01 1.334.025.496-.002c.233-.004.408-.016.574-.036.417-.049.824-.494 1.226-1.126l.12-.191c.353.648.802 1.212 1.283 1.285.452.05.891.05 1.326.003a.376.376 0 0 0 .323-.471 3.027 3.027 0 0 1-.107-.82 3.687 3.687 0 0 1 .09-.919 7.079 7.079 0 0 1 1.104-1.643 6.3 6.3 0 0 0 .863-1.591c.027-.092.053-.189.078-.297l.098-.47c.265-1.475.563-2.937.894-4.388.064-.388.064-.8-.002-1.205-.145-.415-.405-.696-.742-.848a1.37 1.37 0 0 0-1.064-.03 1.758 1.758 0 0 0-.854.712c-.12.176-.237.368-.345.567v-1.63a2.244 2.244 0 0 0-.578-1.403c-.523-.457-1.21-.574-1.815-.336a1.92 1.92 0 0 0-.701.56l-.006-.091A1.552 1.552 0 0 0 8.8.183a2.082 2.082 0 0 0-1.707 0c-.52.25-.857.754-.89 1.32l-.046.33-.025.24-.008.099-.002.073c-.021.386-.03.722-.027 1.142a7.874 7.874 0 0 0-.7-1.421 2.441 2.441 0 0 0-1.088-.935c-.662-.187-1.318-.018-1.772.425a1.82 1.82 0 0 0-.386 1.513c.13.522.29 1.018.478 1.502l.244.55c.085.192.142.328.193.46l.057.152c.025.072.05.136.078.204l.214.5c.036.088.063.161.09.246l.113.365a8.037 8.037 0 0 0-.334-.307z"
            />
            <path
                fill="#FFF"
                d="m4.696 8.107-.138-.555a20.481 20.481 0 0 0-.251-.915l-.081-.262a4.792 4.792 0 0 0-.161-.427l-.172-.396-.129-.342-.144-.35-.299-.675c-.172-.442-.32-.908-.44-1.382a1.053 1.053 0 0 1 .21-.846c.232-.22.612-.319.967-.22.26.117.522.342.703.628.223.357.415.735.571 1.127l.112.297c.276.707.474 1.43.593 2.166l.093.463c.089.445.743.38.743-.073l-.001-1.393-.024-1.394-.002-.458c.001-.143.004-.279.008-.416l.024-.52.015-.151.055-.431a.836.836 0 0 1 .463-.72 1.331 1.331 0 0 1 1.083.005.797.797 0 0 1 .47.68l.024.334.065.754v3.653c.04.191.164.287.373.287.21 0 .335-.096.377-.289v-.011c.023-1.296.135-2.595.335-3.883.078-.258.294-.512.58-.649a.991.991 0 0 1 .994.185c.198.222.333.546.351.886v3.56c.023.175.132.263.329.263.196 0 .33-.06.403-.177.05-.155.103-.307.164-.468l.245-.618.184-.43.018-.037c.11-.216.24-.433.381-.64.124-.2.295-.344.498-.423a.623.623 0 0 1 .811.369c.027.205.033.458.007.717l-.025.194c-.034.277-.284 1.476-.752 3.598l-.106.59-.088.447-.05.222-.044.168a5.548 5.548 0 0 1-.732 1.353 7.468 7.468 0 0 0-1.229 1.876 4.268 4.268 0 0 0-.134 1.166c-.001.187.012.377.04.566a5.49 5.49 0 0 1-.736-.028c-.11-.017-.428-.405-.647-.776l-.063-.11a.749.749 0 0 0-.67-.406.754.754 0 0 0-.675.417c-.274.451-.65.893-.758.905l-.225.02-.33.012c-.127.002-.274.002-.448 0l-1.678-.031a2.694 2.694 0 0 0-.012-.412c-.039-.362-.155-.66-.39-.86l-.234-.208-.898-.846-.803-.893-.118-.184-.741-1.224a20.028 20.028 0 0 0-.348-.55l-.11-.15-.126-.158a16.584 16.584 0 0 0-.266-.307l-.38-.43a3.336 3.336 0 0 1-.384-.512 1.38 1.38 0 0 1-.152-1.042.832.832 0 0 1 .838-.594l.105.011c.416.03.772.185 1.046.444.266.23.504.468.724.722l.068.084.246.342.253.334c.127.126.281.158.462.095.272-.095.182-.637.163-.668z"
            />
            <path
                fill="#000"
                d="M11.353 8.53c.18 0 .332.128.367.299l.008.075v3.452a.375.375 0 0 1-.743.075l-.007-.075V8.904c0-.207.167-.374.375-.374zm-2.001 0c.18 0 .332.127.368.297l.008.075.01 3.454a.375.375 0 0 1-.743.077l-.007-.075-.01-3.454a.373.373 0 0 1 .374-.374zm-1.997-.005a.376.376 0 0 1 .37.3l.008.075.02 3.445a.377.377 0 0 1-.373.38.38.38 0 0 1-.37-.3l-.007-.076-.02-3.444a.375.375 0 0 1 .372-.38z"
            />
        </g>
    </svg>
);
const Memo = memo(DragIcon);
export default Memo;
