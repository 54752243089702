// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId, getMediaPlayerLoop } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { mediaToolSelector } from './mediaToolSelector';
import { updateMultipleElements } from '../../../../../../common/elements/elementActions';
import fileGetMediaPlayerType from '../../../../../element/file/utils/fileGetMediaPlayerType';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

import { MEDIA_PLAYER_TYPES } from '../../../../../element/file/mediaPlayer/mediaPlayerConstants';

/**
 * Toggles the caption on and off whenever the caption tool is clicked by either changing the image element to be
 * currently editing (which by default will put the cursor into the field and show the caption), or deleting the
 * caption text and changing the image to not be editing so that the caption will no longer have focus.
 */
const mapDispatchToProps = (dispatch) => ({
    dispatchSetMediaLoop: ({ elementIds, mediaPlayerLoop }) => {
        dispatch(
            updateMultipleElements({
                updates: elementIds.map((elementId) => ({
                    id: elementId,
                    changes: {
                        mediaPlayerLoop,
                    },
                })),
            }),
        );
    },
});

const MediaLoopTool = (props) => {
    const { showTool, isActive, name, selectedElements, dispatchSetMediaLoop } = props;

    const isMediaPlayerType = selectedElements.every(
        (element) =>
            fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.AUDIO ||
            fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.VIDEO,
    );

    if (!showTool || !isActive || !isMediaPlayerType) return null;

    const loopIsActive = selectedElements.every(getMediaPlayerLoop);

    const handleClick = () =>
        dispatchSetMediaLoop({
            elementIds: selectedElements.map(getElementId).toArray(),
            mediaPlayerLoop: !getMediaPlayerLoop(selectedElements.first()),
        });

    return (
        <ToolbarTool {...props} onClick={handleClick} name={name} isActive={loopIsActive}>
            <Icon name="toolbar-loop" />
        </ToolbarTool>
    );
};

MediaLoopTool.propTypes = {
    selectedElements: PropTypes.object,
    showTool: PropTypes.bool,
    isActive: PropTypes.bool,
    name: PropTypes.string,
    dispatchSetMediaLoop: PropTypes.func,
};

export default connect(mediaToolSelector, mapDispatchToProps)(MediaLoopTool);
