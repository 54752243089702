// Lib
import React from 'react';
import PropTypes from 'prop-types';
import loadable from 'react-loadable';

// Constants
import { ICON_SIZES } from '../iconConstants';

const IconLoader = () => <div className="Icon loading" />;

export const IconSizeLargeLight = loadable({
    loader: () => import(/* webpackChunkName: "icons-light-large" */ './IconLightSizeLarge'),
    loading: IconLoader,
});

export const IconSizeMediumLight = loadable({
    loader: () => import(/* webpackChunkName: "icons-light-medium" */ './IconLightSizeMedium'),
    loading: IconLoader,
});

export const IconSizeSmallLight = loadable({
    loader: () => import(/* webpackChunkName: "icons-light-small" */ './IconLightSizeSmall'),
    loading: IconLoader,
});

const IconLight = ({ name, iconSize }) => {
    switch (iconSize) {
        case ICON_SIZES.SMALL:
            return (
                <IconSizeSmallLight name={name} iconSize={iconSize}>
                    <IconSizeMediumLight name={name} iconSize={iconSize}>
                        <IconSizeLargeLight name={name} iconSize={iconSize} />
                    </IconSizeMediumLight>
                </IconSizeSmallLight>
            );
        case ICON_SIZES.MEDIUM:
            return (
                <IconSizeMediumLight name={name} iconSize={iconSize}>
                    <IconSizeLargeLight name={name} iconSize={iconSize} />
                </IconSizeMediumLight>
            );
        case ICON_SIZES.LARGE:
        default:
            return <IconSizeLargeLight name={name} iconSize={iconSize} />;
    }
};

IconLight.propTypes = {
    name: PropTypes.string,
    iconSize: PropTypes.string,
};
export default IconLight;
