// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { stopDefaultAndPropagation } from '../../../../utils/domUtil';

// Components
import Icon from '../../../../components/icons/Icon';
import Button from '../../../../components/buttons/Button';
import DrawingEditorToolbarExtendedPanel from './DrawingEditorToolbarExtendedPanel';

// Hooks
import useTouchBlockedPointerEvent from './useTouchBlockedPointerEvent';

// Styles
import './DrawingEditorToolbarUndoRedoTools.scss';

const DrawingEditorToolbarUndoRedoTools = (props) => {
    const { isPenMode, canUndo, canRedo, undoPath, redoPath } = props;

    const onUndo = useTouchBlockedPointerEvent(isPenMode, undoPath);
    const onRedo = useTouchBlockedPointerEvent(isPenMode, redoPath);

    return (
        <DrawingEditorToolbarExtendedPanel
            className="DrawingEditorToolbarUndoRedoTools"
            onDoubleClick={stopDefaultAndPropagation}
        >
            <Button className={classNames({ disabled: !canUndo })} onPointerUp={onUndo}>
                <Icon name="drawing-editor-undo" />
            </Button>
            <Button className={classNames({ disabled: !canRedo })} onPointerUp={onRedo}>
                <Icon name="drawing-editor-redo" />
            </Button>
        </DrawingEditorToolbarExtendedPanel>
    );
};

DrawingEditorToolbarUndoRedoTools.propTypes = {
    isPenMode: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    undoPath: PropTypes.func,
    redoPath: PropTypes.func,
};

export default DrawingEditorToolbarUndoRedoTools;
