import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './VisibilityButton.scss';

const VisibilityButton = (props) => {
    const { error, onClick, isVisible, isFocused } = props;

    const classes = classNames('VisibilityButton', { 'has-input-focus': isFocused, error });

    return <input type="button" className={classes} onClick={onClick} value={isVisible ? 'Hide' : 'Show'} />;
};

VisibilityButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    error: PropTypes.object,
};

export default VisibilityButton;
