import { createSelector } from 'reselect';
import * as Immutable from 'immutable';

// Util
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { getChildrenViaGraph } from '../../../../common/elements/utils/elementGraphUtils';

// Selector
import { getCurrentlyEditingId } from '../../../element/selection/currentlyEditingSelector';
import { getRenderedElements, getSelectedElementIds } from '../../../element/selection/selectedElementsSelector';
import { getElements } from '../../../element/selectors/elementsSelector';
import { getEditorState } from '../../../components/editor/store/reducers/editorStoreSelector';
import { elementGraphSelector } from '../../../element/selectors/elementGraphSelector';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import getFirstEntityInSelection from '../../../components/editor/store/reducers/getFirstEntityInSelection';

export const editedOrSelectedElementIdsSelector = createShallowSelector(
    getSelectedElementIds,
    getCurrentlyEditingId,
    (selectedElementIds, currentlyEditingId): Immutable.List<string> => {
        if (currentlyEditingId) {
            return Immutable.List([currentlyEditingId]);
        }

        return selectedElementIds;
    },
);

/**
 * Returns a list of of either:
 * 1. the currently edited element (if there is one)
 * 2. the selected elements (if there are any)
 */
export const editedOrSelectedElementsSelector = createShallowSelector(
    editedOrSelectedElementIdsSelector,
    getElements,
    (selectedElementIds, elements): Immutable.List<ImMNElement> => getRenderedElements(selectedElementIds, elements),
);

const EMPTY_LIST = Immutable.List();
export const editedOrSelectedElementsFromPropsSelector = createShallowSelector(
    (_: any, ownProps: any) => ownProps.selectedElementIds || EMPTY_LIST,
    getElements,
    (selectedElementIds, elements): Immutable.List<ImMNElement> => getRenderedElements(selectedElementIds, elements),
);

export const selectedChildElementsSelector = createShallowSelector(
    editedOrSelectedElementIdsSelector,
    getElements,
    elementGraphSelector,
    (selectedElementIds, elements, elementGraph): Immutable.List<ImMNElement> => {
        // only perform this selector if there is exactly one selected element
        if (selectedElementIds.size !== 1) return Immutable.List();

        return getChildrenViaGraph({
            elements,
            elementGraph,
            parentId: selectedElementIds.first(),
        }).toList();
    },
);

/**
 * Returns true if the user has selected text in the editor
 */
export const editorHasTextRangeSelectionSelector = createSelector(getEditorState, (editorState): boolean => {
    if (!editorState) return false;

    const firstEntityKey = getFirstEntityInSelection(editorState);
    const selection = editorState.getSelection();

    if (selection.isCollapsed() && !firstEntityKey) return false;

    return true;
});

export const getOnboardingDisableToolbar = (state: any): boolean =>
    state.getIn(['app', 'onboarding', 'currentStep', 'disable', 'contextMenu']) || false;
