import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementVideoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-video"
        {...props}
    >
        <defs>
            <filter
                id="ElementVideoIcon__a"
                width="131.2%"
                height="131.2%"
                x="-15.6%"
                y="-12.5%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <rect id="ElementVideoIcon__b" width={32} height={32} x={0} y={0} rx={2} />
            <path id="ElementVideoIcon__c" d="M2 0h28a2 2 0 0 1 2 2v22H0V2a2 2 0 0 1 2-2z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <use xlinkHref="#ElementVideoIcon__b" fill="#000" filter="url(#ElementVideoIcon__a)" />
                <use xlinkHref="#ElementVideoIcon__b" fill="#FFF" />
                <mask id="ElementVideoIcon__d" fill="#fff">
                    <use xlinkHref="#ElementVideoIcon__c" />
                </mask>
                <use xlinkHref="#ElementVideoIcon__c" fill="#70ADD3" />
                <circle
                    cx={7}
                    cy={4}
                    r={8}
                    fill="#00FFBA"
                    fillOpacity={0.601}
                    mask="url(#ElementVideoIcon__d)"
                    opacity={0.58}
                />
                <circle cx={18} cy={26} r={12} fill="#C54DE9" mask="url(#ElementVideoIcon__d)" opacity={0.688} />
                <circle
                    cx={28}
                    cy={14}
                    r={6}
                    fill="#FFF"
                    fillRule="nonzero"
                    mask="url(#ElementVideoIcon__d)"
                    opacity={0.311}
                />
            </g>
            <g transform="translate(9 5)">
                <circle cx={7} cy={7} r={7} fill="#000" opacity={0.4} />
                <path
                    fill="#FFF"
                    d="M9.203 7.438 5.741 9.342A.5.5 0 0 1 5 8.905v-3.81a.5.5 0 0 1 .741-.437l3.462 1.904a.501.501 0 0 1 0 .876z"
                />
            </g>
        </g>
    </svg>
);
const Memo = memo(ElementVideoIcon);
export default Memo;
