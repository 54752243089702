// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Style
import './TextActivityIndicator.scss';

const TextActivityIndicator = (props) => {
    const { children } = props;

    const wide = children && String(children).length > 1;

    return (
        <div className={classNames('TextActivityIndicator', { wide })}>
            <div className="text">{children}</div>
        </div>
    );
};

TextActivityIndicator.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default TextActivityIndicator;
