// Utils
import { getTimestamp } from '../../utils/timeUtil';
import { asObject, isEmpty } from '../../utils/immutableHelper';
import doesEditorJsonHaveText from '../../tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';
import { addRestoreLocationForMovesToTrash, getTrashLocation } from './elementLocationUtils';
import {
    getCaption,
    getElementId,
    getElementLocation,
    getElementType,
    getFileProp,
    getImageProp,
    getLinkUrl,
    getLocationParentId,
    getShowCaption,
    getTextContent,
    getTitle,
} from './elementPropertyUtils';

// Constants
import { COLUMN_DEFAULT_TITLE } from '../../columns/columnConstants';
import { BOARD_DEFAULT_TITLE } from '../../boards/boardConstants';

// Types
import { MNElement } from '../elementModelTypes';
import { ElementType } from '../elementTypes';

const elementHasCaption = (el: MNElement) => {
    const showCaption = getShowCaption(el);
    if (!showCaption) return false;

    const caption = getCaption(el);
    return doesEditorJsonHaveText(caption);
};

export const cardOrDocumentHasContent = (el: MNElement) => {
    const textContent = getTextContent(el);

    if (doesEditorJsonHaveText(textContent)) return true;

    return elementHasCaption(el);
};

export const boardHasContent = (el: MNElement) => {
    const title = getTitle(el);
    return !isEmpty(title) && title !== BOARD_DEFAULT_TITLE;
};

export const columnHasContent = (el: MNElement) => {
    const title = getTitle(el);
    return !isEmpty(title) && title !== COLUMN_DEFAULT_TITLE;
};

export const imageHasContent = (el: MNElement) => {
    const image = getImageProp(el);

    if (!isEmpty(image)) return true;

    return elementHasCaption(el);
};

export const linkHasContent = (el: MNElement) => {
    const url = getLinkUrl(el);
    return !!url;
};

export const taskHasContent = (el: MNElement) => {
    const textContent = getTextContent(el);
    return doesEditorJsonHaveText(textContent);
};

export const fileHasContent = (el: MNElement) => {
    const file = getFileProp(el);
    return !!file;
};

/**
 * This is used to determine if the specific element has content that should be shown in the trash.
 * Elements with children will still be shown even if they aren't interesting themselves - this is handled
 * by the trash selector.
 *
 * NOTE:  This could also use the "element registration" functionality - allowing an "isEmpty" function
 *  to be registered for each element type.  So far this appears to have caused more issues in terms of
 *  code cleanliness than it's solved, so I've opted to avoid that in this case.
 *  It may prove necessary if we ever have dynamic element types, or as an enforcement of implementation
 *  for new element types.
 */
export const elementHasInterestingContentForTrash = (el: MNElement) => {
    const elementType = getElementType(el);

    switch (elementType) {
        case ElementType.CARD_TYPE:
        case ElementType.DOCUMENT_TYPE:
            return cardOrDocumentHasContent(el);
        case ElementType.COLUMN_TYPE:
            return columnHasContent(el);
        case ElementType.IMAGE_TYPE:
            return imageHasContent(el);
        case ElementType.LINK_TYPE:
            return linkHasContent(el);
        case ElementType.BOARD_TYPE:
            return boardHasContent(el);
        // If a single task exists in the trash (without a containing task list, then it won't be shown)
        case ElementType.ALIAS_TYPE:
        case ElementType.TASK_LIST_TYPE:
        case ElementType.TASK_TYPE:
        case ElementType.COMMENT_THREAD_TYPE:
        case ElementType.LINE_TYPE:
        case ElementType.ANNOTATION_TYPE:
            return false;
        case ElementType.FILE_TYPE:
        default:
            return true;
    }
};

export const getTrashMoveAction = (el: MNElement) =>
    addRestoreLocationForMovesToTrash({
        id: getElementId(el),
        location: getTrashLocation({
            currentBoardId: getLocationParentId(el),
            addedDate: getTimestamp(),
        }),
        from: asObject(getElementLocation(el)),
    });

/**
 * Determines if the element is suitable for permanent deletion based on its content alone.
 */
export const canContentBePermanentlyDeleted = (el: MNElement) => {
    const elementType = getElementType(el);

    switch (elementType) {
        case ElementType.CARD_TYPE:
        case ElementType.DOCUMENT_TYPE:
            return !cardOrDocumentHasContent(el);
        case ElementType.IMAGE_TYPE:
            return !imageHasContent(el);
        case ElementType.LINK_TYPE:
            return !linkHasContent(el);
        case ElementType.FILE_TYPE:
            return !fileHasContent(el);
        case ElementType.COLOR_SWATCH_TYPE:
        case ElementType.LINE_TYPE:
            return true;
        default:
            return false;
    }
};
