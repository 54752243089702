// Lib
import * as Immutable from 'immutable';

// Constants
import { CANVAS_SET_INITIAL_ORIGIN } from './canvasConstants';

const initialState = Immutable.Map();

/**
 * Stores initial canvas state for a board.
 * Currently only the canvas origin.
 *
 * Structure is:
 * {
 *     boardId: {
 *         canvasOrigin: { x, y },
 *     }
 * }
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case CANVAS_SET_INITIAL_ORIGIN: {
            // Only set the initial origin if it doesn't already exist...
            const initialCanvasOrigin = state.getIn([action.boardId, 'canvasOrigin']);

            if (initialCanvasOrigin) return state;

            return state.update(action.boardId, (initialCanvasState) =>
                (initialCanvasState || Immutable.Map()).set('canvasOrigin', Immutable.fromJS(action.canvasOrigin)),
            );
        }
        default:
            return state;
    }
};
