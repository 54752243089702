// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ContextMenuItem from './ContextMenuItem';

// Styles
import './ContextMenuItemToggle.scss';

const ContextMenuItemToggle = (props) => {
    const { title, active } = props;

    return (
        <ContextMenuItem {...props} className="ContextMenuItemToggle">
            <span>{title}</span>
            {active && <span className="tick">✔︎</span>}
        </ContextMenuItem>
    );
};

ContextMenuItemToggle.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    id: PropTypes.string,
    title: PropTypes.string,
    show: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseUp: PropTypes.func,
    registerMenuItem: PropTypes.func,
    active: PropTypes.bool,
};

export default ContextMenuItemToggle;
