// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import forgottenPassword from '../../auth/modals/forgottenPassword/forgottenPasswordModalReducer';
import changePassword from '../../auth/modals/changePassword/changePasswordModalReducer';
import errorModal from '../error/modals/errorModalReducer';
import elementModal from '../../element/modal/elementModalReducer';
import compose from '../../compose/composeModalReducer';
import activeModal from './activeModalReducer';
import modalState from './modalStateReducer';

export default combineReducers({
    activeModal,
    modalState,
    errorModal,
    forgottenPassword,
    changePassword,
    elementModal,
    compose,
});
