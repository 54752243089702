export const CLONE_INSTANCES_FETCHED = 'CLONE_INSTANCES_FETCHED';

export const CLONE_INSTANCES_UI_MODES = {
    INVALID: 'INVALID',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    NO_CLONES: 'NO_CLONES',
    CURRENT_BOARD_ONLY: 'CURRENT_BOARD_ONLY',
    PRIVATE_BOARDS: 'PRIVATE_BOARDS',
    MANY_BOARDS: 'MANY_BOARDS',
};
