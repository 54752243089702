// Lib
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// Hooks
import useActualColorPopupMode from './useActualColorPopupMode';
import useColorUpdateHandlers from './useColorUpdateHandlers';

// Selectors
import currentBoardSuggestedColorsSelector from '../../currentBoardSuggestedColorsSelector';

// Components
import CustomColorInput from '../../CustomColorInput';

// Utils
import { getSupportColorBar } from '../../colorToolUtils';
import { elementAllowsDualColors } from '../../dualColors/dualColorListUtils';
import { isColumn, isSketch } from '../../../../../../../../common/elements/utils/elementTypeUtils';

// Constants
import { COLOR_POPUP_MODE } from '../colorPopupConstants';
import {
    BACKGROUND_COLORS,
    Color,
    COLORS,
    COLUMN_COLORS,
    SKETCH_BACKGROUND_COLORS,
} from '../../../../../../../../common/colors/colorConstants';

// Types
import { ColorToolSelectorReturnType } from '../../colorToolSelector';
import { ImMNElement } from '../../../../../../../../common/elements/elementModelTypes';

type ColorToolProps = ColorToolSelectorReturnType & {
    isPopupOpen: boolean;
    selectedElements: Immutable.List<ImMNElement>;
    supportBackgroundColor: boolean;
    customColorHandler?: (color: string | null, secondaryColor?: string | null | undefined) => void;
    customTempColorHandler?: (color: string | null, secondaryColor?: string | null | undefined) => void;
    customDefaultColors?: Color[];
    elementType: string;
    popupId?: string;
};

const COLORS_ARRAY = Object.values(COLORS);
const BACKGROUND_COLORS_ARRAY = Object.values(BACKGROUND_COLORS);
const SKETCH_BACKGROUND_COLORS_ARRAY = Object.values(SKETCH_BACKGROUND_COLORS);
const COLUMN_BACKGROUND_COLORS_ARRAY = Object.values(COLUMN_COLORS);

const getDefaultColors = ({ colorPopupMode, elementType }: { colorPopupMode: string; elementType: string }) => {
    if (colorPopupMode !== COLOR_POPUP_MODE.BACKGROUND) return COLORS_ARRAY;

    if (isSketch(elementType)) return SKETCH_BACKGROUND_COLORS_ARRAY;

    if (isColumn(elementType)) return COLUMN_BACKGROUND_COLORS_ARRAY;

    return BACKGROUND_COLORS_ARRAY;
};

const useColorToolState = (props: ColorToolProps) => {
    const {
        isPopupOpen,
        selectedElements,
        supportBackgroundColor,
        selectedColor,
        selectedBackgroundColor,
        customColorHandler,
        customTempColorHandler,
        customDefaultColors,
        elementType,
        selectedBackgroundColorHex,
        selectedColorHex,
    } = props;

    const supportDualColors = elementAllowsDualColors(selectedElements.first());
    const supportColorBar = getSupportColorBar(selectedElements);
    const suggestedColors = useSelector(currentBoardSuggestedColorsSelector);

    const customColorInputRef = useRef<CustomColorInput>(null);
    const openCustomColorPicker = useCallback(() => {
        if (customColorInputRef && customColorInputRef.current) {
            customColorInputRef.current.open();
        }
    }, [customColorInputRef]);

    const [colorPopupMode, setColorPopupMode] = useState(COLOR_POPUP_MODE.BACKGROUND);
    const isBackgroundMode = colorPopupMode === COLOR_POPUP_MODE.BACKGROUND && supportBackgroundColor;
    const actualPopupMode = useActualColorPopupMode({
        isPopupOpen,
        isBackgroundMode,
        supportColorBar,
        selectedColor,
        selectedBackgroundColor,
        setColorPopupMode,
    });

    const { setColorHandler, setTempColorHandler } = useColorUpdateHandlers(
        isBackgroundMode,
        selectedElements,
        supportDualColors,
        actualPopupMode,
    );

    const defaultColors = getDefaultColors({
        colorPopupMode: actualPopupMode,
        elementType,
    });

    return {
        popupColor: isBackgroundMode ? selectedBackgroundColor : selectedColor,
        popupColorHex: isBackgroundMode ? selectedBackgroundColorHex : selectedColorHex,
        customDefaultColors,
        supportColorBar,
        openCustomColorPicker,
        actualPopupMode,
        setColorPopupMode,
        supportDualColors,
        suggestedColors,
        defaultColors,
        customColorInputRef,
        setColor: customColorHandler || setColorHandler,
        setTempColor: customTempColorHandler || setTempColorHandler,
    };
};

export default useColorToolState;
