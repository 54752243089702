// Utils
import { isBoard } from './elementTypeUtils';
import { isTemplateCustomBoard } from '../../templates/templateUtil';
import { getColor, getElementId, getTitle } from './elementPropertyUtils';

// Types
import { MNElement } from '../elementModelTypes';

export interface BoardSummary {
    id: string;
    title: string;
    color: string;
    isTemplate?: boolean;
}

/**
 * Creates board summary objects for an array of elements.
 */
export const buildBoardSummaries = (elements: MNElement[]): BoardSummary[] =>
    elements.reduce((boardSummaries, element) => {
        if (!isBoard(element)) return boardSummaries;

        boardSummaries.push({
            id: getElementId(element),
            title: getTitle(element),
            color: getColor(element),
            isTemplate: isTemplateCustomBoard(element) ? true : undefined,
        });

        return boardSummaries;
    }, [] as BoardSummary[]);
