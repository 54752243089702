// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';

// Selector
import { individualElementActivitySelector } from './selectors/boardActivitySelector';

// Components
import ActivityIndicator from './ActivityIndicator';

const mapStateToProps = () =>
    createStructuredSelector({
        elementActivity: individualElementActivitySelector(),
    });

const ElementActivityIndicator = (props) => {
    const { elementActivity, elementId, isSelected } = props;

    if (isEmpty(elementActivity)) return null;

    return <ActivityIndicator toggleVal={isSelected} elementId={elementId} elementActivity={elementActivity} />;
};

ElementActivityIndicator.propTypes = {
    elementId: PropTypes.string,
    elementActivity: PropTypes.object,
    indicatorType: PropTypes.string,
    actors: PropTypes.array,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
};

export default connect(mapStateToProps)(ElementActivityIndicator);
