// Lib
import React, { useCallback } from 'react';
import { Editor, useEditor } from '@tiptap/react';
import { Extensions } from '@tiptap/core/src/types';
import { isEqual, isString } from 'lodash';

// Utils
import convertPersistedTextContentToTiptapContent from './utils/convertPersistedTextContentToTiptapContent';

// Hooks
import useTiptapTriggerEditable from './hooks/useTiptapTriggerEditable';
import useTiptapEditorDebouncedUpdate from './hooks/useTiptapEditorDebouncedUpdate';
import useTiptapEditorFocus from './hooks/useTiptapEditorFocus';
import useTiptapEditorPersistedContentSync from './hooks/useTiptapEditorPersistedContentSync';
import useSyncTiptapActiveEditorState from './hooks/useSyncTiptapActiveEditorState';
import useAddTypingBufferTextOnEdit from './hooks/useAddTypingBufferTextOnEdit';
import { usePreservedSelection } from './extensions/VirtualSelection';

// Types
import { TiptapContent, TiptapEditorContent } from './tiptapTypes';

// General Milanote Tiptap editor styles
import './styles/MilanoteTiptapEditorStyles.scss';

export type UseTiptapEditorProps = {
    persistedContent?: TiptapContent | null;
    extensions: Extensions;
    editorClassname?: string;

    editorId: string;
    editorKey: string;
    currentEditorKey?: string;

    isEditable: boolean;
    isEditing: boolean;
    isSingleSelected: boolean;

    startEditing?: (args: { editorId: string; editorKey: string }) => void;
    saveContent?: (content: TiptapContent) => void;
    updateActiveEditorStore?: (editor: Editor | null) => void;
};

export type UseTiptapEditorReturn = {
    editor: ReturnType<typeof useEditor>;
    onMouseDown: (event: React.MouseEvent) => void;
    onClick: (event: React.MouseEvent) => void;
};

/**
 * Determines if the updated content should get persisted & synced to the server.
 * If the persisted content is equal to the updated content, there's no point persisting it again
 * as it's already persisted.
 */
const shouldSaveContent = (persistedContent: TiptapEditorContent, updatedContent: TiptapContent): boolean => {
    if (!persistedContent) return true;
    if (isString(persistedContent)) return true;

    return !isEqual(persistedContent, updatedContent);
};

/**
 * Creates the Tiptap editor instance and sets up the necessary behaviours for it, such as:
 * - Making the editor editable when it is the currently edited editor.
 * - Saving the content on a de-bounce.
 * - Syncing the active editor to the context.
 * - Focusing the editor when it starts editing.
 * - Preventing mousedown events from reaching the Element when the editor is being edited.
 * - Starting editing when the Element is clicked.
 */
const useElementTiptapEditor = ({
    persistedContent,
    extensions,
    editorId,
    editorKey,
    currentEditorKey,
    isEditing,
    isEditable,
    isSingleSelected,
    startEditing,
    saveContent,
    updateActiveEditorStore,
    editorClassname = '',
}: UseTiptapEditorProps): UseTiptapEditorReturn => {
    // TODO-TIPTAP - Determine if we should memoise this function
    const content = convertPersistedTextContentToTiptapContent(persistedContent);

    const editor = useEditor({
        // @ts-ignore Can't import the right Extensions type for some reason...
        extensions,
        content,
        editorProps: {
            attributes: {
                class: editorClassname,
            },
        },
    });

    /**
     * Will prevent saving the content if it's the same as the currently persisted content.
     *
     * NOTE: This works on top of the functionality within the useTiptapEditorDebouncedUpdate hook,
     *  as that will prevent updates if the content is the same as its last tracked content, however
     *  updated due to remote users could change the stored content, but not its tracked saved content.
     */
    const onSaveContent = useCallback(
        (updatedContent: TiptapContent) => {
            // If the new content is the same as the currently persisted content, don't save it
            //  This will help avoid unnecessary updates on the undo stack
            //  (E.g. when deleting cards when empty)
            if (!shouldSaveContent(content, updatedContent)) return;

            saveContent?.(updatedContent);
        },
        [saveContent, content],
    );

    const isEditingThisEditor = currentEditorKey === editorKey && isEditing;

    // Add typingBuffer when captions get added
    useAddTypingBufferTextOnEdit(editor, isEditingThisEditor);
    // Only allow the editor to be editable if it is the currently edited editor.
    //  If we don't do this, we won't be able to drag on the editor, it would instead select the text
    useTiptapTriggerEditable(editor, isEditable && isEditingThisEditor);
    // Only store the content on a de-bounce
    useTiptapEditorDebouncedUpdate(editor, onSaveContent);
    // Sync the active editor to the context
    useSyncTiptapActiveEditorState(editor, isEditingThisEditor, updateActiveEditorStore);

    // Ensure the cursor is in the editor while editing
    useTiptapEditorFocus(editor, isEditingThisEditor);

    // Ensure updates made to the content, while it's not being edited (e.g. undo/redo or remote editing),
    // are reflected in the editor
    useTiptapEditorPersistedContentSync(editor, persistedContent, content, isEditingThisEditor);
    // Keep selections visible while popups are open
    usePreservedSelection(editor, isSingleSelected);

    /**
     * Prevent mousedown events from reaching the ElementContainer
     * to prevent the card from exiting editing mode.
     */
    const onMouseDown = useCallback(
        (event: React.MouseEvent) => {
            if (!isEditingThisEditor) return;
            event.stopPropagation();
        },
        [isEditingThisEditor],
    );

    /**
     * Start editing this element when it's clicked.
     */
    const onClick = useCallback(
        (event: React.MouseEvent) => {
            if (!startEditing) return;
            if (!isEditable) return;
            if (!isSingleSelected) return;
            if (isEditingThisEditor) return;

            startEditing({ editorId, editorKey });
            editor.commands.focusAtClick(event);
        },
        [isSingleSelected, editor, isEditingThisEditor, isEditable, editorId, editorKey],
    );

    return { editor, onMouseDown, onClick };
};

export default useElementTiptapEditor;
