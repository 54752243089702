import { defer } from 'lodash';
import { EditorState } from 'draft-js';

export default ({ isEditing }, { getEditorState, setEditorState }) =>
    () => {
        if (!isEditing) return;

        // Get the selection state before a delay
        const selection = getEditorState().getSelection();

        // For some reason Draft has started changing focus back to the start of the editor when the checkboxes are
        // clicked, so this will wait until after the focus has been changed and then force it back to position it
        // was before the focus was changed.
        defer(() => {
            const editorState = getEditorState();
            const newEditorState = EditorState.forceSelection(editorState, selection);
            setEditorState(newEditorState);
        });
    };
