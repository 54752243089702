import { RefObject } from 'react';
import requestSecondAnimationFrame from '../../../../../common/utils/lib/requestSecondAnimationFrame';
import { runAfterNextTransitionEnds } from '../../../../utils/dom/domEventUtil';
import { getDefaultSnapPointDetails } from './snapPointUtils';
import { MAX_SHEET_HEIGHT, MAX_SUFFIX } from '../hooks/useModalSheetDragState';

const setInitialSheetHeight = (
    sheetRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    updateActiveSnapPoint: (snapPoint: number, goToPoint: boolean) => void,
    setSheetInitialised: (sheetInitialised: boolean) => void,
    closeAnimationInProgress: React.MutableRefObject<boolean>,
) => {
    if (!sheetRef.current) return;

    const { point, suffix } = getDefaultSnapPointDetails(defaultSnapPoint);
    const defaultSnapIsMax = suffix && point && suffix === MAX_SUFFIX;

    // --- Set the specified default snap point ---

    if (point && !defaultSnapIsMax) {
        requestSecondAnimationFrame(() => {
            addSnapPoint(point);
            updateActiveSnapPoint(point, true);
            setSheetInitialised(true);
        });
        return;
    }

    // --- Set the initial snap point based on the content height ---

    const maxInitialSnapPoint = defaultSnapIsMax ? point : MAX_SHEET_HEIGHT;

    sheetRef.current.style.height = 'auto';
    sheetRef.current.style.maxHeight = `0px`;

    // raf ensures all style changes are applied before starting the transition
    requestSecondAnimationFrame(() => {
        if (!sheetRef.current || closeAnimationInProgress.current) return;

        sheetRef.current.style.maxHeight = `${maxInitialSnapPoint * 100}%`;

        runAfterNextTransitionEnds(sheetRef.current, () => {
            if (!sheetRef.current || closeAnimationInProgress.current) return;

            // After the transition to full content height or max has finished, add the height as a snap point
            const contentHeightAsDecimal = sheetRef.current.clientHeight / window.innerHeight;
            const initialSnapPoint = Math.min(maxInitialSnapPoint, contentHeightAsDecimal);

            addSnapPoint(initialSnapPoint);
            setSheetInitialised(true);

            // Set height and max height to the correct values
            // This is necessary when maxInitialSnapPoint is true, since we need to change the maxHeight,
            // and when that changes the height will be affected
            updateActiveSnapPoint(initialSnapPoint, true);
            sheetRef.current.style.maxHeight = `${MAX_SHEET_HEIGHT * 100}%`;
        });
    });
};

export default setInitialSheetHeight;
