// Lib
import * as Immutable from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { get } from 'lodash/fp';

// Utils
import { addUndoActionProperties } from './middleware/createUndoAction';
import { addRedoActionProperties } from './middleware/createRedoAction';

// Batch actions
import { BATCH_ACTION_TYPE, createBatchAction } from '../../store/reduxBulkingMiddleware';

const initialState = Immutable.Map();

const getLastAction = (actionList) => actionList.get(actionList.size - 1);

const lasActionIs = (actionType) => (actionList) => {
    if (!actionList) return false;
    const lastActionType = get(['undo', 'type'], getLastAction(actionList));
    return lastActionType === actionType;
};

const lastActionIsLocationChange = lasActionIs(LOCATION_CHANGE);
const lastActionIsBatchAction = lasActionIs(BATCH_ACTION_TYPE);

export const clearTransactions = (state = initialState, transactionIds) =>
    state.filter((actions, transactionId) => transactionIds.indexOf(transactionId) === -1);

/**
 * If an action is batched, we want to combine it into a single batched action on undo and redo.
 */
const handleBatchedAction = (state, action) => {
    let actionList;
    let batchedAction;

    if (state.has(action.transactionId)) {
        actionList = state.get(action.transactionId);

        if (lastActionIsBatchAction(actionList)) {
            batchedAction = getLastAction(actionList);
            actionList = actionList.pop();
        }
    }

    if (!batchedAction) {
        batchedAction = {
            undo: addUndoActionProperties(createBatchAction({ actions: [], transactionId: action.transactionId })),
            redo: addRedoActionProperties(createBatchAction({ actions: [], transactionId: action.transactionId })),
        };
    }

    batchedAction.undo.payload.push(action.undoRedoAction.undo);
    batchedAction.redo.payload.push(action.undoRedoAction.redo);

    if (!actionList) {
        actionList = Immutable.List([]);
    }

    actionList = actionList.push(batchedAction);

    return state.set(action.transactionId, actionList);
};

export default (state = initialState, action) => {
    if (!action.transactionId || !action.undoRedoAction) return state;

    // If an action is batched then we want to undo & redo it as part of a batch too
    if (action.batched) return handleBatchedAction(state, action);

    if (state.has(action.transactionId)) {
        return state.update(action.transactionId, (actionList) => {
            const { undoRedoAction } = action;
            let updatedActionList = actionList;

            // Collapse location change actions to prevent unnecessary multiple location changes on undo
            if (action.type === LOCATION_CHANGE && lastActionIsLocationChange(actionList)) {
                const lastAction = getLastAction(actionList);
                undoRedoAction.undo = lastAction.undo;
                updatedActionList = updatedActionList.pop();
            }

            return updatedActionList.push(undoRedoAction);
        });
    }

    return state.set(action.transactionId, Immutable.List([action.undoRedoAction]));
};
