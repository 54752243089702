import { Capacitor } from '@capacitor/core';
import { CAPACITOR_MN_TOKEN_PLUGIN_NAME } from '../../capacitor_plugins/pluginConstants';
import { MNToken } from '../../capacitor_plugins/userTokenManager';
import { getCurrentUserToken } from './authService';

export const setCapacitorAuthCookie = (token: string | null) => {
    if (!Capacitor.isPluginAvailable(CAPACITOR_MN_TOKEN_PLUGIN_NAME) || !token) return;

    MNToken.setMnToken({ token });
};

export const setCapacitorAuthCookieFromLocalStorage = () => {
    setCapacitorAuthCookie(getCurrentUserToken());
};

export const removeCapacitorAuthCookie = () => {
    if (!Capacitor.isPluginAvailable(CAPACITOR_MN_TOKEN_PLUGIN_NAME)) return;

    MNToken.removeMnToken();
};
