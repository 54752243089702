// Constants
import { METHODS } from '../../../utils/http/httpConstants';
import { TIMES } from '../../../utils/timeUtil';

export default (mediaHttp) => ({
    fetchLink: ({ id, url, environmentFolder, userId }) => {
        const requestConfig = {
            url: 'link',
            method: METHODS.POST,
            timeout: TIMES.MINUTE,
            data: {
                url,
                elementId: id,
                environmentFolder,
                userId,
            },
        };

        return mediaHttp(requestConfig);
    },
});
