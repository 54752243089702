// Utils
import { getEditorJsonContentLines } from './getEditorJsonContentLines';

// Types
import { EditorContent, ImEditorContent } from '../../../elements/elementModelTypes';

/**
 * Retrieves the text content as a single string.
 */
const getEditorJsonText = (content: ImEditorContent | EditorContent | string | undefined): string =>
    getEditorJsonContentLines(content).join('\n');

export default getEditorJsonText;
