import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AlignCenterHorizIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon align-center-horiz"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M16 7.25a.75.75 0 0 1 .75.75v1H20a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-3.25v2H22a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-5.25v1a.75.75 0 0 1-.648.743L16 24.75a.75.75 0 0 1-.75-.75v-1H10a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h5.25v-2H12a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h3.25V8a.75.75 0 0 1 .648-.743zm-.75 11.25H10.5v3h4.75v-3zm6.25 0h-4.75v3h4.75v-3zm-6.25-8H12.5v3h2.75v-3zm4.25 0h-2.75v3h2.75v-3z"
            />
        </g>
    </svg>
);
const Memo = memo(AlignCenterHorizIcon);
export default Memo;
