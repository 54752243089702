import { CellTypeNames, DateStringFormatOptions, FormatOptions, TimeStringFormatOptions } from '../CellTypeConstants';
import { getConnectingDateWords, getLocalMonths, getLocalWeekdays } from '../../utils/localLanguageUtils';
import { CellTypeObject, DateTimeTypeObject } from '../TableTypes';
import {
    dateObjectHasTime,
    dateTimeStringHasDate,
    dateTimeStringHasSeconds,
    getAreDatesSameDay,
    getDateFromString,
    numericDateDashedRegex,
    numericDateLongRegex,
    numericDateShortRegex,
    timeRegex12h,
    toNumericDateTimeString,
} from '../../utils/timeUtil';

/**
 * Returns the numeric date string from a cell data object
 * Note - this function assumes the string is a valid date, use getDateTimeValuesFromInputString for new user input
 */
export const getNumericDateTimeStringFromCellValue = (
    value: string,
    type: DateTimeTypeObject,
    locale: string,
): string | undefined => {
    if (!value || !type) return;

    const date = getDateFromString(value, locale);
    if (!date) return value;

    const { hasTime, hasSeconds } = type as DateTimeTypeObject;
    return toNumericDateTimeString(date, { hasTime, hasSeconds }, locale);
};

const adjustInputForDate = (
    str: string,
    locale: string | undefined,
): { inputString: string; inputFormat: DateStringFormatOptions | undefined } => {
    let inputString = String(str).trim().replace(',', '').replace('.', '').toLowerCase();
    const inputStringArray = inputString.split(' ');
    const stringHasSpaces = inputStringArray.length > 1;
    const compareString = (inputString: string, word: string) =>
        stringHasSpaces
            ? inputString.includes(word) && inputStringArray.includes(word)
            : inputStringArray.includes(word);

    let inputFormat;

    // Check if the string contains any months
    // Convert months in local language to US english
    // Use the inputFormat to determine whether the date is in words or numbers
    const { short, long, monthUS } = getLocalMonths(locale);
    for (let i = 0; i < 12; i++) {
        if (compareString(inputString, long[i])) {
            inputString = inputString.replace(long[i], monthUS[i]);
            inputFormat = DateStringFormatOptions.WORDS_LONG;
            break;
        }
        if (compareString(inputString, short[i])) {
            inputString = inputString.replace(short[i], monthUS[i]);
            inputFormat = DateStringFormatOptions.WORDS_SHORT;
            break;
        }
        if (compareString(inputString, monthUS[i])) {
            inputFormat = DateStringFormatOptions.WORDS_LONG;
            break;
        }
    }

    // Check if the string contains any weekdays, if so,
    // remove them and update the date format to show weekday
    const { short: weekdaysShort, long: weekdaysLong } = getLocalWeekdays(locale);
    for (let i = 0; i < 7; i++) {
        const match = inputString.match(weekdaysLong[i]) || inputString.match(weekdaysShort[i]);
        const localWeekday = match ? match[0] : null;
        if (localWeekday && compareString(inputString, localWeekday)) {
            inputString = inputString.replace(localWeekday, '');
            inputFormat = DateStringFormatOptions.WEEKDAY;
            break;
        }
    }

    // remove connecting words like 'of'
    getConnectingDateWords(locale).forEach((word) => {
        if (inputString.includes(word) && inputStringArray.includes(word))
            inputString = inputString.replace(`${word} `, '');
    });

    return { inputString, inputFormat };
};

/**
 * Returns commonly used date cell information for a given string
 * @param str
 * @param locale
 * @param type
 * @param isNewInput - if the input is new we want to adjust the input and get the formatting options
 */
export const getDateTimeValuesFromInputString = (
    str: string | null | undefined,
    locale: string,
    type?: DateTimeTypeObject | undefined,
    isNewInput = false,
):
    | {
          numericDateTimeString: string;
          hasTime: boolean;
          hasSeconds: boolean;
          dateFormat: DateStringFormatOptions;
          timeInBrackets: boolean;
      }
    | undefined => {
    if (!str) return;

    let inputString = String(str).trim();
    let inputFormat;

    if (isNewInput) {
        // We only want to check the format and adjust the input string if it's new
        const adjusted = adjustInputForDate(str, locale);
        inputString = adjusted.inputString.trim();
        inputFormat = adjusted.inputFormat;
    }
    const date = getDateFromString(inputString.trim(), locale);
    if (!date) return;

    if (!inputFormat) {
        // Check if the string contains dashes
        if (inputString.match(numericDateDashedRegex)) inputFormat = DateStringFormatOptions.NUMERIC_DASHED;
        // Check if the string contains slashes and is in the format dd/mm/yyyy
        if (inputString.match(numericDateLongRegex)) inputFormat = DateStringFormatOptions.NUMERIC_LONG;
        // Check if date is in the format mm/dd/yy
        if (inputString.match(numericDateShortRegex)) inputFormat = DateStringFormatOptions.NUMERIC_SHORT;
        // Otherwise default to words short
        if (!inputFormat) inputFormat = DateStringFormatOptions.WORDS_SHORT;
    }

    const hasTime = type?.hasTime || dateObjectHasTime(date) || !!inputString.match(/\d{1,2}:\d{1,2}/);
    const hasSeconds = type?.hasSeconds || dateTimeStringHasSeconds(inputString);
    const hasDate = dateTimeStringHasDate(inputString);
    const dateFormat = type?.dateFormat || hasDate ? inputFormat : DateStringFormatOptions.NONE;
    const numericDateTimeString = toNumericDateTimeString(date, { hasTime, hasSeconds }, locale);
    const timeInBrackets = type?.timeInBrackets || hasDate;

    return {
        numericDateTimeString,
        hasTime,
        hasSeconds,
        dateFormat,
        timeInBrackets,
    };
};

const getNewDateFormat = (
    prevDate: Date | null,
    prevTypeObject: CellTypeObject | undefined,
    newDate: Date,
    newTypeObject: DateTimeTypeObject | undefined,
) => {
    const wasPreviouslyDate = prevTypeObject && prevTypeObject.name === CellTypeNames.DATE_TIME;
    const prevDateDisplayed = wasPreviouslyDate && prevTypeObject?.dateFormat !== DateStringFormatOptions.NONE;
    const newDateDisplayed = newTypeObject?.dateFormat !== DateStringFormatOptions.NONE;

    // check if the date has changed and whether it should be shown now
    // If date was previously hidden, but it's changed in the datepicker, show it
    const dateHasChanged = !getAreDatesSameDay(prevDate, newDate);
    const dateRemoved = prevDateDisplayed && !newDateDisplayed;
    const showDate = wasPreviouslyDate ? !dateRemoved && (prevDateDisplayed || dateHasChanged) : newDateDisplayed;
    if (!showDate) return DateStringFormatOptions.NONE;

    // dateFormat we will use if we want to show the date
    return prevDateDisplayed ? prevTypeObject?.dateFormat : newTypeObject?.dateFormat;
};

const getNewTimeFormat = (
    rawInput: string | undefined,
    newTypeObject: DateTimeTypeObject | undefined,
    prevTypeObject: CellTypeObject | undefined,
    prevDate: Date | string | null,
    newDateHasTime: boolean,
    newHasSeconds: boolean | number | undefined,
): TimeStringFormatOptions => {
    const wasPreviouslyDate = prevTypeObject && prevTypeObject.name === CellTypeNames.DATE_TIME;
    const prevHasSeconds = prevDate && wasPreviouslyDate && prevTypeObject?.[FormatOptions.HAS_SECONDS];
    const prevDateHasTime = prevDate && wasPreviouslyDate && prevTypeObject[FormatOptions.HAS_TIME];
    const hasSecondsHasChanged = (prevHasSeconds && !newHasSeconds) || (!prevHasSeconds && newHasSeconds);
    const hasTimeHasChanged = (prevDateHasTime && !newDateHasTime) || (!prevDateHasTime && newDateHasTime);
    const timeHasBeenAdded = hasTimeHasChanged && newDateHasTime;

    const { NONE, TWELVE_HOUR_SECONDS, TWELVE_HOUR, TWENTY_FOUR_HOUR, TWENTY_FOUR_HOUR_SECONDS } =
        TimeStringFormatOptions;

    // if the new date has no time, return NONE
    if (!newDateHasTime && !wasPreviouslyDate) return NONE;

    // if the new date has no time and the previous date had time, return NONE since it has been removed
    if (!newDateHasTime && wasPreviouslyDate && hasTimeHasChanged) return NONE;

    // Initial format which is either the existing or the default 12h format
    const inferredFormat = newTypeObject?.timeFormat || TWELVE_HOUR;
    const existingFormatOption = wasPreviouslyDate ? prevTypeObject[FormatOptions.TIME_FORMAT] : inferredFormat;

    // If seconds hasn't changed and time hasn't been added, return existing format option
    const shouldUpdate = hasSecondsHasChanged || (timeHasBeenAdded && existingFormatOption === NONE);
    if (!shouldUpdate) return existingFormatOption;

    // Update the format based if seconds have been added or removed
    const is12hTime = rawInput?.toString().match(timeRegex12h);
    const currentTimeIn24h = timeHasBeenAdded
        ? !is12hTime
        : [TWENTY_FOUR_HOUR, TWENTY_FOUR_HOUR_SECONDS].includes(inferredFormat);

    if (newHasSeconds) return currentTimeIn24h ? TWENTY_FOUR_HOUR_SECONDS : TWELVE_HOUR_SECONDS;
    return currentTimeIn24h ? TWENTY_FOUR_HOUR : TWELVE_HOUR;
};

/**
 * When changing the contents of a datetime cell, we need to update these display options based on the input
 * Scenarios are extensively tested in 'Changing value for existing date type' in 'tableCellEditingUtils.test.ts'
 * For a more info on these scenarios see https://app.milanote.com/1R8vgV1Ae9QM5b/when-to-update-datetime-formatting
 */
export const getDateFormattingOptions = (
    newCellValue: string | number | null,
    newTypeObject: DateTimeTypeObject | undefined,
    options: {
        prevCellValue: string | null;
        prevTypeObject: CellTypeObject | undefined;
        locale: string;
        rawInput?: string;
    },
): Partial<DateTimeTypeObject> => {
    const { prevCellValue = null, prevTypeObject, locale, rawInput } = options;

    // If the existing type is not a datetime type, return
    if ((newTypeObject && newTypeObject.name !== CellTypeNames.DATE_TIME) || !newCellValue) return {};

    // Check if the previous cell type was also a datetime type
    const wasPreviouslyDate = prevTypeObject && prevTypeObject.name === CellTypeNames.DATE_TIME;
    const prevDate = prevCellValue !== null ? getDateFromString(prevCellValue, locale) : prevCellValue;

    // Get new date and check time
    const newDate = getDateFromString(newCellValue, locale);
    if (!newDate) return {};

    const newHasSeconds = newDate.getSeconds() || newTypeObject?.[FormatOptions.HAS_SECONDS];
    const newDateHasTime = dateObjectHasTime(newDate) || !!(newTypeObject && newTypeObject[FormatOptions.HAS_TIME]);

    // Get latest formatting options
    const dateFormat = getNewDateFormat(prevDate, prevTypeObject, newDate, newTypeObject);
    const showDate = dateFormat !== DateStringFormatOptions.NONE;
    const timeInBrackets = wasPreviouslyDate ? prevTypeObject.timeInBrackets : showDate;
    const timeFormat = getNewTimeFormat(
        rawInput,
        newTypeObject,
        prevTypeObject,
        prevDate,
        newDateHasTime,
        newHasSeconds,
    );

    return {
        [FormatOptions.HAS_TIME]: newDateHasTime,
        [FormatOptions.TIME_FORMAT]: timeFormat,
        [FormatOptions.TIME_IN_BRACKETS]: timeInBrackets,
        [FormatOptions.DATE_FORMAT]: dateFormat,
    };
};
