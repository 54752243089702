import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import electronSelector from './electronSelector';

const ElectronHidden = ({ children, isElectron }) => (isElectron ? null : children);

ElectronHidden.propTypes = {
    isElectron: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default connect(electronSelector)(ElectronHidden);
