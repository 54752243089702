// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

// Selectors
import {
    boardHierarchicalActivitySelector,
    elementActivityMapSelector,
} from '../activity/selectors/boardActivitySelector';
import { SimpleActivityIndicator } from '../activity/ActivityIndicator';

const columnHasActivitySelector = () =>
    createSelector(
        elementActivityMapSelector,
        boardHierarchicalActivitySelector,
        (state, ownProps) => ownProps.childElementIds,
        (elementActivityMap, boardHierarchicalActivityMap, childElementIds) =>
            childElementIds.some((elId) => elementActivityMap[elId] || boardHierarchicalActivityMap[elId]),
    );

const mapStateToProps = () =>
    createStructuredSelector({
        hasActivity: columnHasActivitySelector(),
    });

/**
 * Displays an activity indicator if any of the child elements have activity.
 */
const ColumnCollapsedActivityIndicator = (props) => {
    const { hasActivity } = props;

    if (!hasActivity) return true;

    return <SimpleActivityIndicator />;
};

ColumnCollapsedActivityIndicator.propTypes = {
    hasActivity: PropTypes.bool,
};

export default connect(mapStateToProps)(ColumnCollapsedActivityIndicator);
