// Utils
import { getElement } from '../../../../../../common/elements/utils/elementTraversalUtils';
import {
    getDueDate,
    getDueReminder,
    getDueReminderTimestamp,
    getHasDueDateTime,
    getShowDueDate,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getDueDatePopupId } from '../assignmentUiUtils';
import { getTimestampForReminder } from './taskDueDateReminderUtils';

// Selectors
import { getElements } from '../../../../selectors/elementsSelector';
import { getCurrentlyEditingElement, getCurrentlyEditingId } from '../../../../selection/currentlyEditingSelector';

// Actions
import { updateElement } from '../../../../actions/elementActions';
import { closePopupsMatching, openPopup } from '../../../../../components/popupPanel/popupActions';

// Constants
import { ELEMENT_UPDATE_TYPE } from '../../../../../../common/elements/elementConstants';

export const toggleElementShowDueDatePill =
    ({ id, sync = true }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);

        const isCurrentlyShowingDueDate = getShowDueDate(element);

        dispatch(
            updateElement({
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_DUE_DATE,
                id,
                changes: {
                    showDueDate: !isCurrentlyShowingDueDate,
                    dueDate: null,
                    hasDueDateTime: false,
                    dueReminder: null,
                    dueReminderTimestamp: null,
                },
                sync,
            }),
        );

        if (!isCurrentlyShowingDueDate) {
            const popupId = getDueDatePopupId(id);

            dispatch(openPopup(popupId));
            dispatch(
                closePopupsMatching({
                    predicateFn: (activePopupId) => activePopupId.startsWith('task') && activePopupId !== popupId,
                }),
            );
        }
    };

export const removeElementDueDate =
    ({ id }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);

        const isCurrentlyShowingDueDate = getShowDueDate(element);

        if (!isCurrentlyShowingDueDate) return;

        dispatch(
            updateElement({
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_DUE_DATE,
                id,
                changes: {
                    showDueDate: false,
                    dueDate: null,
                    hasDueDateTime: false,
                    dueReminder: null,
                    dueReminderTimestamp: null,
                },
            }),
        );
    };

/**
 * This supports setting a temporary due date state (while the due date popup is open) and committing the
 * updated due date state (when the popup closes).
 */
export const setElementDueDate = ({ taskId, dueDate, hasDueDateTime, dueReminder, sync = true, initialElement }) => {
    const dueReminderTimestamp = getTimestampForReminder({ dueDate, dueReminder });

    const updateDefinition = {
        id: taskId,
        updateType: ELEMENT_UPDATE_TYPE.DUE_DATE,
        changes: {
            showDueDate: true,
            dueDate,
            hasDueDateTime,
            dueReminder,
            dueReminderTimestamp,
        },
        sync,
    };

    if (sync && initialElement) {
        updateDefinition.undoChanges = {
            showDueDate: getShowDueDate(initialElement),
            dueDate: getDueDate(initialElement),
            hasDueDateTime: getHasDueDateTime(initialElement),
            dueReminder: getDueReminder(initialElement),
            dueReminderTimestamp: getDueReminderTimestamp(initialElement),
        };
    } else {
        // If un-synced then we're making temporary updates, so don't save it to the undo-redo stack
        updateDefinition.transactionId = -1;
    }

    return updateElement(updateDefinition);
};

export const openCurrentlyEditingDueDatePopup = () => (dispatch, getState) => {
    const state = getState();
    const currentlyEditingId = getCurrentlyEditingId(state);
    const currentElement = getCurrentlyEditingElement(state);

    const isShowingDueDate = getShowDueDate(currentElement);

    return isShowingDueDate
        ? dispatch(openPopup(getDueDatePopupId(currentlyEditingId)))
        : // Don't sync this change, otherwise it might conflict with text updates in the editor
          // This could mean that the "showAssignments" property won't be saved on the server if the
          // user doesn't select anything, but this is an edge case with unimportant consequences
          dispatch(toggleElementShowDueDatePill({ id: currentlyEditingId, sync: false }));
};
