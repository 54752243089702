// Constants
import { ELEMENT_RESIZING_END, ELEMENT_RESIZING_START } from './resizingConstants';

export const startElementResizing = (elementId) => ({
    type: ELEMENT_RESIZING_START,
    elementId,
});

export const endElementResizing = () => ({
    type: ELEMENT_RESIZING_END,
});
