// Lib
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import ElementSimpleContentEditable from '../../components/editableTitle/ElementSimpleContentEditable';

// Actions
import { deselectAllElements, finishEditingElement, startEditingElement } from '../selection/selectionActions';
import { updateElementsColors } from '../../workspace/toolbar/components/selectionTools/colorTools/colorActions';

// Utils
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { getColorDisplayValue } from '../../../common/colors/elementColorFormatUtil';
import { getCssPropertyForTextOnColor } from '../utils/elementColorStyleUtils';
import { getColor, getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { COLOR_SWATCH_EDITOR_KEY } from '../../../common/colorSwatches/colorSwatchesConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchStartEditing: ({ id, editorKey, editorId }) => dispatch(startEditingElement({ id, editorKey, editorId })),
    dispatchStopEditing: ({ id, keepSelection = false }) => {
        dispatch(finishEditingElement(id));
        !keepSelection && dispatch(deselectAllElements());
    },
    dispatchUpdateColor: ({ element, color }) => {
        dispatch(updateElementsColors({ elements: [element], color }));
    },
});

const ColorSwatchContentEditable = (props) => {
    const {
        element,
        isEditing,
        isEditable,
        isSelected,
        currentEditorKey,
        dispatchStartEditing,
        dispatchStopEditing,
        dispatchUpdateColor,
    } = props;

    const elementId = getElementId(element);
    const color = getColor(element);
    const colorDisplayValue = getColorDisplayValue(color)?.toUpperCase();

    const onStartEditing = useCallback(() => {
        dispatchStartEditing({
            id: elementId,
            editorId: getMainEditorId(props),
            editorKey: getMainEditorKey(props),
        });
    }, [elementId]);

    const onStopEditing = useCallback(
        ({ keepSelection }) => {
            dispatchStopEditing({
                id: elementId,
                keepSelection,
            });
        },
        [elementId],
    );

    const onUpdate = useCallback(
        (content) => {
            if (content === colorDisplayValue) return null;

            dispatchUpdateColor({ element, color: content });
        },
        [element],
    );

    return (
        <div className="ColorSwatchContentEditable" style={{ color: getCssPropertyForTextOnColor(color) }}>
            <ElementSimpleContentEditable
                selectAllOnEdit
                selectFirst
                isEditing={isEditing && currentEditorKey === COLOR_SWATCH_EDITOR_KEY}
                isEditable={isEditable}
                isSelected={isSelected}
                onStartEditing={onStartEditing}
                onStopEditing={onStopEditing}
                onUpdate={onUpdate}
            >
                {colorDisplayValue}
            </ElementSimpleContentEditable>
        </div>
    );
};

ColorSwatchContentEditable.propTypes = {
    element: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    currentEditorKey: PropTypes.string,
    dispatchStartEditing: PropTypes.func,
    dispatchStopEditing: PropTypes.func,
    dispatchUpdateColor: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ColorSwatchContentEditable);
