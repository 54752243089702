// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '../../../node_module_clones/react-contextmenu';
import { kebabCase } from 'lodash/fp';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import Icon from '../../components/icons/Icon';

const SelectedElementsContextMenuItem = ({
    show,
    selectedElements,
    logSelectedElement,
    onMouseUp,
    registerMenuItem,
}) => {
    if (!show) return null;

    return (
        <div>
            {selectedElements.map((el) => (
                <div
                    ref={(c) => registerMenuItem(getElementId(el), c)}
                    key={getElementId(el)}
                    onContextMenu={logSelectedElement(el)}
                    onMouseUp={onMouseUp(getElementId(el))}
                >
                    <MenuItem onClick={logSelectedElement(el)}>
                        <Icon name={`element-${kebabCase(el.get('elementType'))}`} />
                        <span className="dev-detail">{getElementId(el)}</span>
                    </MenuItem>
                </div>
            ))}
        </div>
    );
};

SelectedElementsContextMenuItem.propTypes = {
    show: PropTypes.bool,
    selectedElements: PropTypes.object,
    logSelectedElement: PropTypes.func,
    onMouseUp: PropTypes.func,
    registerMenuItem: PropTypes.func,
};

export default SelectedElementsContextMenuItem;
