import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { getElementId, getTableContentColWidthsGU } from '../../../common/elements/utils/elementPropertyUtils';
import { asObject } from '../../../common/utils/immutableHelper';
import { getHotTableWidthById } from './utils/tableDOMUtils';
import { convertColWidthsGUtoPx, getBorderRenderedWidth } from './utils/tableSizeUtils';
import { isInColumnList } from '../../../common/inList/inListUtils';

// styles
import './PresentationalTable.scss';

/**
 * Clone the dom if presentational to avoid re-rendering the table
 */
export const PresentationalTable = ({ element, gridSize, inList, isDragPreview }) => {
    const elementId = getElementId(element);
    const presentationalTableRef = useRef(null);

    // Select the part of the table that we want to display when dragging, ignoring things like ref headers
    const currentTable = document.querySelector(`#el-${elementId} .HotTable .HotTableLib .ht_master`);
    const clonedTable = currentTable?.cloneNode(true);

    /**
     * Resize column widths to match the full element width.
     * This is required because column widths can be adjusted when the table is in an element list.
     */
    const updatePresentationalColWidths = () => {
        const shouldResizeToWidth = !isDragPreview;

        // Make sure columns fit the full width of the element
        // In some cases like dragging a table out of a wide column where the table has been stretched,
        // null is used just to reset back to the default widths
        const resizeWidth = shouldResizeToWidth ? getHotTableWidthById(elementId) : null;

        const colWidthsGU = asObject(getTableContentColWidthsGU(element));
        const colWidthsPx = convertColWidthsGUtoPx(colWidthsGU, gridSize, resizeWidth);
        const colGroup = clonedTable.querySelector('table.htCore colgroup');

        for (let i = 1; i < colGroup.children.length; i++) {
            colGroup.children[i].style.width = `${colWidthsPx[i - 1]}px`;
        }
    };

    useEffect(() => {
        if (!clonedTable || !presentationalTableRef.current) return;

        // Calculate border render values
        const actualBorderWidth = getBorderRenderedWidth(currentTable.querySelector('td'));
        presentationalTableRef.current.style.setProperty(
            '--table-cell-border-rendered-width',
            `${actualBorderWidth}px`,
        );

        // This just excludes the scenario when we are dragging a column with a table inside
        // in which case we don't want to recalculate the widths
        const columnIsDragging = isInColumnList(inList);
        if (!columnIsDragging) updatePresentationalColWidths();

        // if there is already table content, remove it
        if (presentationalTableRef.current.firstChild) {
            presentationalTableRef.current.removeChild(presentationalTableRef.current.firstChild);
        }
        presentationalTableRef.current.appendChild(clonedTable);

        // remove unnecessary elements
        presentationalTableRef.current.querySelector('.htBorders')?.remove();
        presentationalTableRef.current.querySelector('thead')?.remove();

        // Remove parts of Milanote cell renderer that cause lots of hit tests
        presentationalTableRef.current.querySelectorAll('.MilanoteCellRendererComponent').forEach((renderer) => {
            const draftEditorRoot = renderer.querySelector('.DraftEditor-root');
            if (!draftEditorRoot) return;

            // Remove all the classnames that cause hit tests
            renderer.querySelector('.DraftEditor-editorContainer').className = '';
            renderer.querySelector('.public-DraftEditor-content').className = '';
            renderer.querySelector('.public-DraftStyleDefault-block').className = '';

            // Keep the alignment classname on draft editor root
            const alignmentClass = draftEditorRoot.className.split(' ')[1];
            draftEditorRoot.className = `presentation-cell-content ${alignmentClass}`;
        });
    }, [isDragPreview]);

    return (
        <div className="HotTable presentational-table">
            <div className="HotTableLib handsontable" ref={presentationalTableRef}></div>
        </div>
    );
};

PresentationalTable.propTypes = {
    element: PropTypes.object,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    isDragPreview: PropTypes.bool,
};

export default PresentationalTable;
