// Lib
import { EditorState, SelectionState, RichUtils } from 'draft-js';

// Utils
import getClosestEntityRangeToSelection from '../../customRichUtils/entity/getClosestEntityRangeToSelection';

// Expand selection to first and last entities or selection
const getSelectionToModify = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const selection = editorState.getSelection();
    const selectionStartKey = selection.getStartKey();
    const selectionStartOffset = selection.getStartOffset();

    const startBlock = blockMap.get(selectionStartKey);

    // Get start entity key
    const startEntityKey = startBlock.getEntityAt(selectionStartOffset);

    let targetSelectionStartKey = selectionStartKey;
    let targetSelectionStartOffset = selectionStartOffset;

    // If exists use start entity key's starting point
    if (startEntityKey) {
        // FIXME This isn't quite right. If the user has copied and pasted entities then they'll share the same
        //  entity key. They almost certainly don't want to edit both at the same time, but this will.
        const { startKey, startOffset } = getClosestEntityRangeToSelection(editorState, startEntityKey);
        targetSelectionStartKey = startKey;
        targetSelectionStartOffset = startOffset;
    }

    // Get end entity key
    const selectionEndKey = selection.getEndKey();
    const selectionEndOffset = selection.getEndOffset();

    const endBlock = blockMap.get(selectionEndKey);

    const endEntityKey = endBlock.getEntityAt(selectionEndOffset);

    let targetSelectionEndKey = selectionEndKey;
    let targetSelectionEndOffset = selectionEndOffset;

    if (endEntityKey) {
        // FIXME This isn't quite right. If the user has copied and pasted entities then they'll share the same
        //  entity key. They almost certainly don't want to edit both at the same time, but this will.
        const { endKey, endOffset } = getClosestEntityRangeToSelection(editorState, endEntityKey);
        targetSelectionEndKey = endKey;
        targetSelectionEndOffset = endOffset;
    }

    return SelectionState.createEmpty(targetSelectionEndKey).merge({
        anchorKey: targetSelectionStartKey,
        anchorOffset: targetSelectionStartOffset,
        focusKey: targetSelectionEndKey,
        focusOffset: targetSelectionEndOffset,
    });
};

export default (editorState, action) => {
    const selectionToRemove = getSelectionToModify(editorState);
    const updatedEditorState = EditorState.forceSelection(editorState, selectionToRemove);
    return RichUtils.toggleLink(editorState, updatedEditorState.getSelection(), null);
};
