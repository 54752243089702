import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MediaPlayerVolumeMuteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon media-player-volume-mute"
        {...props}
    >
        <path
            fill="#767C86"
            fillRule="evenodd"
            d="M6.89 1.728A.5.5 0 0 1 7 2.04v11.92a.5.5 0 0 1-.812.39L3 11H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h1l3.188-3.35a.5.5 0 0 1 .702.078zm3.806 3.17.084.072 1.97 1.969 1.97-1.97a.75.75 0 0 1 1.06 1.061L13.811 8l1.97 1.97a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073-1.97-1.969-1.97 1.97A.75.75 0 0 1 9.72 9.97L11.689 8l-1.97-1.97a.75.75 0 0 1-.136-.874l.064-.102.073-.084a.75.75 0 0 1 .976-.073z"
        />
    </svg>
);
const Memo = memo(MediaPlayerVolumeMuteIcon);
export default Memo;
