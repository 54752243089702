/**
 * A collection of utilities specific to the browser for handling points.
 */

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { roundToStep } from '../../../common/maths/geometry/point';

/**
 * Rounds a point to half a pixel on retina devices or a full pixel on normal devices.
 */
export const roundToPixel = (point) => roundToStep(platformSingleton.features.isRetina ? 0.5 : 1, point);
