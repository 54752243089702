// Lib
import Immutable from 'immutable';

// Utils
import { prop } from '../../../common/utils/immutableHelper';

// Selectors
import { getCurrentVisibleBoardError } from './boardSelector';
import { getPhysicalElementIdSelector } from '../selectors/elementSelector';
import { getAsyncResourceEntityState } from '../../utils/services/http/asyncResource/asyncResourceSelector';

// Constants
import { BOARD_ERROR } from '../../../common/error/errorConstants';
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';

/**
 * Determines if the board specified by the elementId has a password protected error.
 */
export const getPhysicalBoardHasPasswordProtectedErrorSelector = (
    state: Immutable.Map<string, unknown>,
    props: unknown,
): boolean => {
    const physicalBoardId = getPhysicalElementIdSelector(state, props);
    const boardResource = getAsyncResourceEntityState(state, ResourceTypes.boards, physicalBoardId);
    const boardError = prop('error', boardResource);
    return prop('code', boardError) === BOARD_ERROR.PASSWORD_PROTECTED;
};

/**
 * Determines if the current board has a password protected error.
 */
export const getCurrentBoardHasPasswordProtectedError = (
    state: Immutable.Map<string, unknown>,
    props?: unknown,
): boolean => {
    const currentBoardError = getCurrentVisibleBoardError(state, props);
    return prop('code', currentBoardError) === BOARD_ERROR.PASSWORD_PROTECTED;
};
