// Lib
import { get, pick } from 'lodash/fp';

// Constants
import { PASSWORD_PROTECTED_ACL_ID_PREFIX, PERMISSION_STATUS } from './permissionConstants';

export const getPermissionId = get('_id');
export const getAclId = get('aclId');
export const getBoardId = get('boardId');
export const getPermissionValue = get('permission');
export const getStatus = get('status');
export const getCreatorId = get('creatorId');
export const getCreatedTimestamp = get('createdTimestamp');
export const getPermissionPasswordEnabled = (permission) => !!get('passwordEnabled', permission);

/**
 * Doesn't return the board ID, as the board ID + permission ID combination is what allows someone access
 * to a board, so if a user is testing for permission IDs then they will only have half of what they need
 * to access the board.
 */
export const prepareMinimalPermissionForClient = pick(['_id', 'aclId', 'status']);

export const prepareProtectedPermission = pick(['_id', 'boardId']);

export const permissionIsActive = (permission) => permission && getStatus(permission) === PERMISSION_STATUS.ACTIVE;
export const permissionIsInactive = (permission) => permission && getStatus(permission) === PERMISSION_STATUS.INACTIVE;
export const permissionIsRemoved = (permission) => permission && getStatus(permission) === PERMISSION_STATUS.REMOVED;
export const permissionIsValid = (permission) =>
    permissionIsActive(permission) || permissionIsInactive(permission) || permissionIsRemoved(permission);

/**
 * Returns the permission data, adding a "protected" flag and prefixing the ACL ID with `-PROTECTED-`.
 * This can then be used by the server to determine whether a user would have access to the board if
 * they correctly enter the password.
 */
export const convertProtectedPermissionData = (permission) => ({
    ...permission,
    aclId: `${PASSWORD_PROTECTED_ACL_ID_PREFIX}${getAclId(permission)}`,
    protected: true,
});
