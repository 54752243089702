// Lib
import { EditorState, Modifier } from 'draft-js';

// Utils
import editorGetCurrentBlock from './editorGetCurrentBlock';
import { updateContentStateMultiBlockMetadata } from '../blocks/updateBlockMetadata';

// Constants
import { EditorChangeType } from '../../draftjsConstants';
import { BLOCK_METADATA } from '../../richText/richTextConstants';

export default (editorState, style) => {
    const selection = editorState.getSelection();
    let contentState = editorState.getCurrentContent();

    // Maintain centring of text
    const currentBlock = editorGetCurrentBlock(editorState);
    const shouldCenter = !!currentBlock?.getData()?.get(BLOCK_METADATA.TEXT_ALIGN_CENTER);

    contentState = Modifier.splitBlock(contentState, selection);
    if (style) {
        contentState = Modifier.setBlockType(contentState, contentState.getSelectionAfter(), style);
    }

    if (shouldCenter) {
        const selectionAfter = contentState.getSelectionAfter();
        const newBlockKey = selectionAfter.getFocusKey();
        const newBlock = contentState.getBlockForKey(newBlockKey);

        contentState = updateContentStateMultiBlockMetadata(contentState, [newBlock], {
            [BLOCK_METADATA.TEXT_ALIGN_CENTER]: true,
        });
    }

    const updatedEditorState = EditorState.push(editorState, contentState, EditorChangeType.SPLIT_BLOCK);

    const styleOverride = editorState.getInlineStyleOverride();

    return styleOverride ? EditorState.setInlineStyleOverride(updatedEditorState, null) : updatedEditorState;
};
