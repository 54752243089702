import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationTemplateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-template"
        {...props}
    >
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <rect width={10} height={10} x={0.5} y={0.5} fill="#BFBFBF" stroke="#9C9C9C" rx={1.8} />
            <rect width={10} height={10} x={3.5} y={3.5} fill="#B57FE5" stroke="#000" strokeOpacity={0.2} rx={1.8} />
        </g>
    </svg>
);
const Memo = memo(NotificationTemplateIcon);
export default Memo;
