// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './ModalToolbar.scss';

const ModalToolbar = (props) => {
    const { children, className, isPresentationMode } = props;
    const modalToolbarBodyClassNames = classNames('ModalToolbarBody', {
        truncate: isPresentationMode,
    });
    return (
        <div className={classNames('ModalToolbar', className)}>
            <div className={modalToolbarBodyClassNames}>{children}</div>
        </div>
    );
};

ModalToolbar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    isPresentationMode: PropTypes.bool,
};

export default ModalToolbar;
