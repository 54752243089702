// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TaskPillPopup from '../TaskPillPopup';
import TaskDueDatePopupContent from './LoadableTaskDueDatePopupContent';

// Styles
import './TaskDueDatePopup.scss';

const TaskDueDatePopup = (props) => (
    <TaskPillPopup {...props} className="TaskDueDatePopup">
        <TaskDueDatePopupContent {...props} />
    </TaskPillPopup>
);

TaskDueDatePopup.propTypes = {
    popupId: PropTypes.string.isRequired,
    isPopupOpen: PropTypes.bool,
    dispatchClosePopup: PropTypes.func,
};

export default TaskDueDatePopup;
