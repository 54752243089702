export const DRAG_MODIFIER_KEYS_SET = 'DRAG_MODIFIER_KEYS_SET';

export const MODIFIER_KEYS = {
    ctrlKey: 'ctrlKey',
    shiftKey: 'shiftKey',
    altKey: 'altKey',
    metaKey: 'metaKey',
};

export const MODIFIER_KEYS_NAMES = Object.keys(MODIFIER_KEYS);
