// Lib
import { flow, pick, uniq, values } from 'lodash/fp';

// Utils
import { isBoard } from '../../../../../../../common/elements/utils/elementTypeUtils';
import { handleResourceEntityCreation } from '../asyncResourceReducerUtils';
import { getTimestamp } from '../../../../../../../common/utils/timeUtil';

// Actions
import { AnyAction } from 'redux';

// Constants
import { CURRENT_BOARD_ID_SET } from '../../../../../../reducers/currentBoardId/currentBoardIdConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_TYPE,
    ELEMENT_UPDATE,
} from '../../../../../../../common/elements/elementConstants';

// Types
import { AsyncResourceType } from '../asyncResourceReducerTypes';
import { ResourceStatus } from '../../asyncResourceConstants';

/**
 * Clear any errors on the board that we're navigating to, so that it will get force
 * re-fetched.
 */
const handleCurrentBoardIdSet = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => {
    const currentBoardResourceState = state[action.boardId];

    if (!currentBoardResourceState) return state;

    if (!currentBoardResourceState.hasError) return state;

    // Clear errors when navigating to a board with errors, so that it will attempt to fetch the board again
    return {
        ...state,
        [action.boardId]: {
            ...currentBoardResourceState,
            status: ResourceStatus.NOT_FETCHED,
            fetching: false,
            fetched: false,
            hasError: false,
            error: undefined,
            errorTime: undefined,
            errorCount: 0,
        },
    };
};

/**
 * Update the fetchedTime of a board resource if the resource is already fetched.
 */
const updateResourceFetchedTime = (state: AsyncResourceType, boardId: string): AsyncResourceType => {
    if (!boardId) return state;

    const entityState = state[boardId];

    // Ignore it if there is no preloaded board resource, rather than creating a default state
    if (!entityState) return state;

    // Don't update if not fetched
    if (!entityState.fetched) return state;

    // Don't update if fetching
    if (entityState.fetching) return state;

    return {
        ...state,
        [boardId]: {
            ...entityState,
            fetchedTime: getTimestamp(),
        },
    };
};

/**
 * Initialises the fetch state of a newly created board, so that it won't be requested from the
 * server when navigated to.
 */
const handleBoardCreate = (state: AsyncResourceType, action: AnyAction): AsyncResourceType =>
    handleResourceEntityCreation(state, action.id);

/**
 * Handles an element create by:
 * - Updating the resource state if the created element is a board
 * - Updating the parent board's fetched time so that it won't get refetched on socket disconnections
 *   unnecessarily
 */
const handleElementCreate = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => {
    let updatedState = state;

    // Duplicated elements use "markAsFetched = false" so the client will show loaders as appropriate
    //  while they're being duplicated
    if (action.markAsFetched) {
        updatedState = isBoard(action.elementType) ? handleBoardCreate(updatedState, action) : updatedState;
    }

    return updateResourceFetchedTime(updatedState, action?.activity?.boardId);
};

/**
 * Update the source and destination boards' fetched times so that they won't get re-fetched on
 * socket disconnections unnecessarily.
 */
const handleElementMove = (state: AsyncResourceType, action: AnyAction): AsyncResourceType => {
    const parentActivityIds = flow(pick(['sourceBoardId', 'destinationBoardId']), values, uniq)(action?.activity);

    let updatedState = {
        ...state,
    };

    for (const id of parentActivityIds) {
        updatedState = updateResourceFetchedTime(updatedState, id);
    }

    return updatedState;
};

const initialState: AsyncResourceType = {};

export default (state = { ...initialState }, action: AnyAction): AsyncResourceType => {
    switch (action.type) {
        case CURRENT_BOARD_ID_SET:
            return handleCurrentBoardIdSet(state, action);
        case ELEMENT_CREATE:
        case ELEMENT_SET_TYPE:
            return handleElementCreate(state, action);
        case ELEMENT_UPDATE:
            return updateResourceFetchedTime(state, action?.activity?.boardId);
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(state, action);
        default:
            return state;
    }
};
