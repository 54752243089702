const createImagePixelArray = (imgData, pixelCount, quality) => {
    const pixels = imgData;
    const pixelArray = [];

    for (let i = 0, offset, r, g, b, a; i < pixelCount; i = i + quality) {
        offset = i * 4;
        r = pixels[offset + 0];
        g = pixels[offset + 1];
        b = pixels[offset + 2];
        a = pixels[offset + 3];

        // If pixel is mostly opaque
        if (typeof a === 'undefined' || a >= 125) {
            pixelArray.push([r, g, b]);
        }
    }

    return pixelArray;
};

export default createImagePixelArray;
