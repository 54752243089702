import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const QuickLineArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={10}
        viewBox="0 0 10 10"
        className="Icon quick-line-arrow"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M10 0 6.909 8.241 5.062 6.394 1.457 10 0 8.543l3.605-3.606-1.846-1.846z"
        />
    </svg>
);
const Memo = memo(QuickLineArrowIcon);
export default Memo;
