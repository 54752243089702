import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form } from 'react-final-form';
import { validateFields } from '../../../../common/validation/validationUtil';

import '../Form.scss';

const FinalForm = (props) => {
    const { fields, className, onSubmit, children, ...finalFormProps } = props;

    const onSubmitFn = useCallback(
        (values) => {
            // Revalidate fields before a submission, and return the errors as submission errors.
            // This is needed because some fields require a submission before the error message is displayed
            const errors = validateFields(fields)(values);
            if (!isEmpty(errors)) return errors;

            return onSubmit(values);
        },
        [onSubmit],
    );

    return (
        <Form onSubmit={onSubmitFn} {...finalFormProps}>
            {(formRenderProps) => {
                const { handleSubmit, submitting, submitErrors, errors, modifiedSinceLastSubmit, name } =
                    formRenderProps;

                // `display` contains form information that has been processed for form display purposes
                const display = {
                    // Prioritise on showing submission errors if it exist and
                    // the form has not been modified after last submit
                    errors: submitErrors && !modifiedSinceLastSubmit ? submitErrors : errors,
                };

                return (
                    <form
                        className={classNames('Form', className, { submitting })}
                        onSubmit={handleSubmit}
                        noValidate
                        name={name}
                    >
                        {children({ ...formRenderProps, display })}
                    </form>
                );
            }}
        </Form>
    );
};

FinalForm.propTypes = {
    fields: PropTypes.array,
    initialValues: PropTypes.object,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    children: PropTypes.func,
    name: PropTypes.string,
};

export default FinalForm;
