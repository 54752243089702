import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AiAdvanceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon ai-advance"
        {...props}
    >
        <path
            fill="#2F63F7"
            fillRule="evenodd"
            d="M11.06 7.646a.5.5 0 0 1 0 .708l-5 5a.5.5 0 1 1-.706-.708L10 8 5.354 3.354a.5.5 0 0 1-.058-.638l.058-.07a.5.5 0 0 1 .707 0l5 5z"
        />
    </svg>
);
const Memo = memo(AiAdvanceIcon);
export default Memo;
