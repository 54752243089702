import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ModalToolbarDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon modal-toolbar-download"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M16 7a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 1.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm0 3.25a.75.75 0 0 1 .743.648l.007.102v5.188l1.72-1.718a.75.75 0 0 1 .976-.073l.084.073a.75.75 0 0 1 .073.976l-.073.084-3 3a.75.75 0 0 1-.976.073l-.084-.073-3-3a.75.75 0 0 1 .976-1.133l.084.073 1.72 1.72V12.5a.75.75 0 0 1 .75-.75z"
        />
    </svg>
);
const Memo = memo(ModalToolbarDownloadIcon);
export default Memo;
