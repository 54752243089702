// Lib
import { last, sum, throttle, compact } from 'lodash';
import { ManualColumnResize } from 'handsontable/plugins';

// Utils
import { stopDefaultAndPropagation } from '../../../utils/domUtil';
import {
    getAllSelectedCols,
    isColInSelections,
    isRowInSelection,
    repositionAutofillHandle,
} from '../utils/tableCellSelectionUtils';
import { parseCssPx } from '../../../utils/cssUtil';
import { getNewColumnWidthsPx } from '../utils/tableSizeUtils';
import { getTableDOMElementById } from '../utils/tableDOMUtils';
import { roundPixelToNearestGridPoint, roundPixelToNextGridPoint } from '../../../utils/grid/gridUtils';
import requestPromisedAnimationFrame from '../../../../common/utils/lib/requestPromisedAnimationFrame';

// Types
import { CellSelections } from '../../../../common/table/TableTypes';

// Constants
import { TABLE_CELL_MIN_WIDTH } from '../../../../common/table/tableConstants';
import { ELEMENT_RESIZE_INTERPOLATION_FACTOR } from '../../resizing/store/resizingConstants';

export default class MilanoteManualColumnResizePlugin extends ManualColumnResize {
    private tableElement: HTMLDivElement | null = null;
    private moved = false;

    enablePlugin(): void {
        this.addHook('afterInit', (...args) => this.afterInit(...args));

        this.addHook('beforeColumnResize', (...args) => this.beforeColumnResize(...args));

        this.addHook('afterCreateCol', () => {
            setTimeout(this.updateColResizeHandles);
        });
        this.addHook('afterRemoveCol', () => this.afterRemoveCol());

        super.enablePlugin();
    }

    resetResizing = (): void => {
        this.tableElement = null;
        this.milanoteValues = {};
    };

    /**
     * Get the interval for resizing columns while dragging
     * A lower number gives a smoother resize, but can result in performance issues
     * For that reason we want to find the best interval for each table/scenario
     * @param hasSelection
     */
    getResizeInterval = (hasSelection: boolean): number => {
        // 10 looks really smooth, but is too slow when rendering on each resize
        if (!hasSelection) return 10;

        const cellCount = this.hot.countCols() * this.hot.countRows();

        // These numbers are just based on what feels good during testing
        return cellCount > 1000 ? 100 : 50;
    };

    initialiseColResize = (event: MouseEvent, thElement: HTMLTableCellElement): void => {
        // @ts-ignore - Custom property
        const { elementId, getContextZoomScale, hotTableContainerRef } = this.hot.milanoteProps;

        stopDefaultAndPropagation(event);

        if (!event.target) return;

        this.currentTH = thElement;

        // get element from event and use that to check the index of the col
        const column = this.hot.getCoords(event.target).col;

        this.hot.getActiveEditor()?.finishEditing();

        // We want to suspend render while resizing to improve performance
        // and stop flickering of borders, background colour etc. while rendering.
        // This means that selection borders won't be updated though, so for now we
        // are only suspending render when there aren't any selections
        const selections = this.hot.getSelected() as CellSelections;
        if (!selections) this.hot.suspendRender();

        // Set the interval for resizing
        const interval = this.getResizeInterval(!!selections);
        this.throttledLiveResize = throttle(this.liveResize, interval);

        this.resetResizing();

        this.tableElement = getTableDOMElementById(elementId);
        hotTableContainerRef.current?.classList.add('col-resizing');

        //****************** Add resizing event listeners ***********************

        document.addEventListener('mouseup', this.finishResizing);
        document.addEventListener('pointerup', this.finishResizing);
        document.addEventListener('mousemove', this.throttledLiveResize);
        document.addEventListener('pointermove', this.throttledLiveResize);

        //****************** Update this with accurate values ***********************

        // Update the selectedCols array
        const newResizingCols: number[] = [];

        // Get all the selections that include the header row
        const headerSelections = selections?.reduce(
            (acc, selection) => (isRowInSelection(-1, selection) ? [...acc, selection] : acc),
            [] as CellSelections,
        );

        // if the header ref is selected, and the current col is selected, we need to resize all selected cols
        if (headerSelections && isColInSelections(column, headerSelections)) {
            getAllSelectedCols(headerSelections).forEach((col) => newResizingCols.push(col));
        } else {
            // There are no header selections, so we just resize the current col
            newResizingCols.push(column);
        }
        this.selectedCols = newResizingCols;

        const colValues = [];
        const nCols = this.hot.countCols();
        for (let col = 0; col < nCols; col++) {
            colValues.push({
                initialWidth: this.hot.getColWidth(col) || 0,
                headerColElement: this.headerColElements[col] || null,
                bodyColElement: this.bodyColElements[col] || null,
            });
        }
        // Set the timer for when we double-click
        if (this.autoresizeTimeout === null) {
            // @ts-ignore - incorrect in handsontable types
            this.autoresizeTimeout = setTimeout(() => this.afterMouseDownTimeout(), 500);
        }

        const zoomScale = getContextZoomScale();
        const dragStartPos = event.clientX / zoomScale;

        const tableElement = getTableDOMElementById(elementId);

        const box = this.currentTH.getBoundingClientRect();
        this.newSize = box.width;
        this.startWidth = box.width;
        this.pressed = true;
        this.moved = false;
        this.currentCol = column;
        this.dblclick += 1;
        this.milanoteValues = {
            colValues,
            dragStartPos,
            tableStartWidth: parseCssPx(tableElement?.style.width || '0px'),
            isCurrentlyResizing: true,
        };
    };

    resizeCol = (col: number, newWidth: number): void => {
        const { headerColElement, bodyColElement } = this.milanoteValues?.colValues?.[col] || {};

        // Add the new width to the width map in colResize plugin
        this.newSize = newWidth;
        this.columnWidthsMap.setValueAtIndex(col, newWidth);

        // Better for performance to set the width directly on the col elements rather than using the
        // this.setManualSize method
        if (headerColElement) headerColElement.style.width = `${newWidth}px`;
        if (bodyColElement) bodyColElement.style.width = `${newWidth}px`;

        // Temporarily set the table element width while resizing
        if (this.tableElement) {
            this.tableElement.style.width = `${sum(this.getColWidthsPx())}px`;
        }
        this.hot.refreshDimensions();
    };

    liveResize = (event: MouseEvent): void => {
        this.moved = true;

        // @ts-ignore - Custom property
        const { gridSize, getContextZoomScale } = this.hot.milanoteProps;

        const { dragStartPos = 0, tableStartWidth, colValues } = this.milanoteValues;
        if (!colValues?.length || !tableStartWidth) return;

        const zoomScale = getContextZoomScale();
        const mousePos = event.clientX / zoomScale;
        const dif = mousePos - dragStartPos;

        const initialColWidths = this.selectedCols.map((col) => colValues[col].initialWidth);

        const minTableCellWidthPx = TABLE_CELL_MIN_WIDTH * gridSize;
        const newColWidths = getNewColumnWidthsPx(this.selectedCols, initialColWidths, minTableCellWidthPx, dif);

        // Visually update the col widths
        this.selectedCols.forEach((col, index) => this.resizeCol(col, newColWidths[index]));
    };

    throttledLiveResize = throttle(this.liveResize, 10);

    animateColWidthToAdjustToGrid = async (
        col: number,
        targetColWidthPx: number,
        currentColWidthPx: number = this.hot.getColWidth(col),
    ): Promise<void> => {
        const endAnimation = Math.abs(currentColWidthPx - targetColWidthPx) < 0.1;
        if (endAnimation) {
            this.resizeCol(col, targetColWidthPx);
            return;
        }

        const newColWidth =
            currentColWidthPx + (targetColWidthPx - currentColWidthPx) * ELEMENT_RESIZE_INTERPOLATION_FACTOR;

        this.resizeCol(col, newColWidth);

        const result = await requestPromisedAnimationFrame(() =>
            this.animateColWidthToAdjustToGrid(col, targetColWidthPx, newColWidth),
        );

        await result;
    };

    finishResizing = (): void => {
        // @ts-ignore - Custom property
        const { gridSize, hotTableContainerRef } = this.hot.milanoteProps;
        const { colValues } = this.milanoteValues;

        const isDoubleClick = this.dblclick > 1;
        if (!isDoubleClick && this.moved) {
            const startColWidthsPx = colValues?.map((col) => col.initialWidth);

            // 1. Determine the final col widths so that it fits into Milanote's grid points

            const finalColWidthsPx = this.getColWidthsPx();

            this.selectedCols.forEach((col) => {
                if (!finalColWidthsPx) return;

                finalColWidthsPx[col] = Math.round(this.hot.getColWidth(col));

                const isLastSelectedCol = col === last(this.selectedCols);
                if (isLastSelectedCol) {
                    const delta =
                        roundPixelToNearestGridPoint(sum(finalColWidthsPx) + 2, gridSize) - sum(finalColWidthsPx) - 2;

                    finalColWidthsPx[col] += delta;
                }
            }, []);

            // 2. Determine whether or not the col width (round off to the nearest grid units) has been updated,
            //    This will be used to determine whether or not
            const isUpdatedColWidthGU =
                roundPixelToNearestGridPoint(sum(startColWidthsPx), gridSize) !==
                roundPixelToNearestGridPoint(sum(finalColWidthsPx), gridSize);

            // 3. Animate the col widths to the final col widths

            if (isUpdatedColWidthGU) {
                Promise.all(
                    compact(
                        finalColWidthsPx.map((width, col) => {
                            if (width === startColWidthsPx?.[col]) return;

                            return this.animateColWidthToAdjustToGrid(col, width);
                        }),
                    ),
                ).then(() => {
                    this.onMouseUp();
                    this.resetResizing();
                    repositionAutofillHandle(this.hot, hotTableContainerRef);
                    setTimeout(() => {
                        hotTableContainerRef.current?.classList.remove('col-resizing');
                    });
                });
            }

            // 4. If size if not updated, animate resize column widths back to its original widths

            if (!isUpdatedColWidthGU) {
                Promise.all(
                    compact(
                        finalColWidthsPx.map(
                            (finalColWidth, col) =>
                                startColWidthsPx && this.animateColWidthToAdjustToGrid(col, startColWidthsPx[col]),
                        ),
                    ),
                ).then(() => {
                    this.resetResizing();
                    hotTableContainerRef.current?.classList.remove('col-resizing');
                });
            }
        }

        document.removeEventListener('mouseup', this.finishResizing);
        document.removeEventListener('pointerup', this.finishResizing);
        document.removeEventListener('mousemove', this.throttledLiveResize);
        document.removeEventListener('pointermove', this.throttledLiveResize);

        this.hot.resumeRender();
    };

    /**************************
     * HANDSONTABLE HOOKS
     **************************/

    afterInit(): void {
        // Remove the default mousemove event listener
        // @ts-ignore - valid property
        const mouseMoveEvent = this.eventManager.context.eventListeners?.find(({ event }) => event === 'mousemove');
        if (mouseMoveEvent) {
            const { element, event: eventName, callback } = mouseMoveEvent;
            this.eventManager.removeEventListener(element, eventName, callback);
        }

        // Remove the default mousedown event listener
        // @ts-ignore - valid property
        const mouseDownEvent = this.eventManager.context.eventListeners?.find(({ event }) => event === 'mousedown');
        if (mouseDownEvent) {
            const { element, event: eventName, callback } = mouseDownEvent;
            this.eventManager.removeEventListener(element, eventName, callback);
        }

        // Remove the default mouseup event listener
        // @ts-ignore - valid property
        const mouseUpEvent = this.eventManager.context.eventListeners?.find(({ event }) => event === 'mouseup');
        if (mouseUpEvent) {
            const { element, event: eventName, callback } = mouseUpEvent;
            this.eventManager.removeEventListener(element, eventName, callback);
        }

        this.updateColResizeHandles();
    }

    beforeColumnResize(newSizePx: number, col: number, isDoubleClick: boolean): number | void {
        // @ts-ignore - Custom property
        const { gridSize, getContextZoomScale, hotTableContainerRef } = this.hot.milanoteProps;

        if (isDoubleClick) {
            const zoomScale = getContextZoomScale();
            const newSizePxRounded = Math.round(newSizePx / zoomScale);

            const minTableCellWidthPx = TABLE_CELL_MIN_WIDTH * gridSize;

            let newSizePxFinal = Math.max(newSizePxRounded, minTableCellWidthPx);

            // Adjust width of the last selected column so that the whole table fits into Milanote's grid points
            const isLastSelectedCol = col === last(this.selectedCols);
            if (isLastSelectedCol) {
                const newColWidths = this.getColWidthsPx();
                newColWidths[col] = newSizePxFinal;

                const delta = roundPixelToNextGridPoint(sum(newColWidths), gridSize) - sum(newColWidths) - 2;

                newSizePxFinal += delta;
            }

            repositionAutofillHandle(this.hot, hotTableContainerRef);
            setTimeout(() => {
                hotTableContainerRef.current?.classList.remove('col-resizing');
            });

            return newSizePxFinal;
        }
    }

    afterRemoveCol(): void {
        // @ts-ignore - Custom property
        const { gridSize } = this.hot.milanoteProps;

        setTimeout(this.updateColResizeHandles);

        const colWidthsPx = this.getColWidthsPx();

        const delta = roundPixelToNearestGridPoint(sum(colWidthsPx), gridSize) - sum(colWidthsPx) - 2;

        if (delta === 0) return;

        const lastColIndex = this.hot.countCols() - 1;
        this.animateColWidthToAdjustToGrid(lastColIndex, colWidthsPx[lastColIndex] + delta);
    }

    /**************************
     * OVERRIDE PLUGIN METHODS
     **************************/

    setupHandlePosition(): void {
        // Do nothing. We don't want Handsontable's default handle positioning, as we have our own handles
        // and sometimes this function sets the resizing column incorrectly
    }

    /**************************
     * HELPERS
     **************************/

    getColWidthsPx = (): number[] => {
        const colWidthsPx: number[] = [];
        for (let i = 0; i < this.hot.countCols(); i++) {
            colWidthsPx[i] = this.hot.getColWidth(i);
        }
        return colWidthsPx;
    };

    updateColResizeHandles = (): void => {
        // Get a list of all the relevant column header elements
        const colRefHeaderElement = this.hot.rootElement.querySelector('.ht_clone_top');
        const thElements = Array.from(colRefHeaderElement?.querySelectorAll('th') || []);
        // Remove the first element which is the row header (not visible)
        thElements.shift();

        // Add the resize handle to each column header
        const { rootDocument } = this.hot;
        thElements.forEach((thElement, index) => {
            // only run if there isn't an existing handle
            if (thElement.querySelector('.manualColumnResizerHandle')) return;

            const newHandle = rootDocument.createElement('DIV');
            newHandle.className = 'manualColumnResizerHandle';

            newHandle.addEventListener('mousedown', (e) => this.initialiseColResize(e, thElement));
            newHandle.addEventListener('pointerdown', (e) => this.initialiseColResize(e, thElement));

            // On iPad, the pointermove event will be overridden by a touchmove event, which will scroll the canvas instead
            // of resizing the column. The following code will let TouchBackend.ts prevent this behaviour.
            newHandle.dataset.ignoreTouchMove = 'true';

            thElement.appendChild(newHandle);
        });

        this.headerColElements = this.hot.rootElement.querySelectorAll<HTMLTableColElement>(
            '.ht_clone_top colgroup col:not(.rowHeader)',
        );

        this.bodyColElements = this.hot.rootElement.querySelectorAll<HTMLTableColElement>(
            '.ht_master colgroup col:not(.rowHeader)',
        );
    };
}
