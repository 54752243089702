import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import {
    COLOR_SWATCH_DISPLAY_NAME,
    COLOR_SWATCH_DEFAULT_WIDTH_GRID,
    COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
} from './colorSwatchesConstants';
import { GRID } from '../../client/utils/grid/gridConstants';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.COLOR_SWATCH_TYPE,
        content: {
            width: COLOR_SWATCH_DEFAULT_WIDTH_GRID,
            media: {
                height: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
                width: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_WIDTH_GRID,
            },
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.COLOR_SWATCH_TYPE,
        objectConstructorFn,
        displayName: COLOR_SWATCH_DISPLAY_NAME,
        ...params,
    });
