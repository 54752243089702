// Lib
import React from 'react';
import PropTypes from 'prop-types';

/**
 * This locks the rendering of elements while they're being animated, to improve performance
 * and prevent overriding updates made using vanilla JS.
 */
class AnimationLock extends React.Component {
    shouldComponentUpdate(nextProps) {
        return !nextProps.animating;
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

AnimationLock.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    animating: PropTypes.bool,
};

export default AnimationLock;
