import { COLUMN_DISPLAY_NAME, COLUMN_DEFAULT_TITLE } from './columnConstants';
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.COLUMN_TYPE,
        content: {
            title: COLUMN_DEFAULT_TITLE,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.COLUMN_TYPE,
        objectConstructorFn,
        displayName: COLUMN_DISPLAY_NAME,
        ...params,
    });
