// Utils
import { getMetaVersionId } from '../../common/elements/utils/elementPropertyUtils';
import {
    createVersionId,
    createNextVersionId,
    hasVersionableProperties,
} from '../../common/elements/utils/elementVersionUtils';
import { getSessionId } from '../device/deviceSessionService';

// Selectors
import { getElement } from '../element/selectors/elementSelector';

// Constants
import { ELEMENT_CREATE, ELEMENT_UPDATE, ELEMENT_DIFF_UPDATE } from '../../common/elements/elementConstants';
import { BATCH_ACTION_TYPE } from './reduxBulkingMiddleware';

/**
 * Adds a meta.versionId property to ELEMENT_CREATE actions.
 */
const prepareElementCreateAction = (state, action) => {
    if (!action || action?.meta?.versionId) return action;

    const sessionId = getSessionId();
    const versionId = createVersionId(sessionId, 1);

    action.meta = {
        ...action.meta,
        versionId,
    };

    return action;
};

const updateElementActionMeta = (state, id, action) => {
    const element = getElement(state, { elementId: id });
    const modifiedVersionId = getMetaVersionId(element);

    const sessionId = getSessionId();
    const nextVersionId = createNextVersionId(sessionId, modifiedVersionId);

    action.meta = {
        ...action.meta,
        modifiedVersionId,
        versionId: nextVersionId,
    };
};

/**
 * Adds a meta.version property to ELEMENT_UPDATE updates if they are changing
 * a "versionable" property.
 */
const prepareElementUpdateAction = (state, action) => {
    const { updates = [] } = action;

    for (const update of updates) {
        const { id, changes } = update;

        if (!hasVersionableProperties(changes)) continue;
        updateElementActionMeta(state, id, update);
    }

    return action;
};

const prepareElementDiffUpdateAction = (state, action) => {
    const { id, changes } = action;

    if (!hasVersionableProperties(changes)) return action;
    updateElementActionMeta(state, id, action);

    return action;
};
/**
 * Adds a meta.version property to the action if necessary.
 */
const getUpdatedAction = (state, action) => {
    switch (action.type) {
        case BATCH_ACTION_TYPE:
            return {
                ...action,
                payload: action.payload.map((payloadAction) => getUpdatedAction(state, payloadAction)),
            };
        case ELEMENT_CREATE:
            return prepareElementCreateAction(state, action);
        case ELEMENT_UPDATE:
            return prepareElementUpdateAction(state, action);
        case ELEMENT_DIFF_UPDATE:
            return prepareElementDiffUpdateAction(state, action);
        default:
            return action;
    }
};

export default (store) => (next) => (action) => {
    if (action.remote) return next(action);

    const { getState } = store;
    const state = getState();

    const updatedAction = getUpdatedAction(state, action);

    return next(updatedAction);
};
