// Lib
import * as Immutable from 'immutable';

// Constants
import {
    LIVE_COLLABORATION_SOCKET_DISCONNECT,
    LIVE_COLLABORATION_SOCKET_UPDATE_CHANNEL,
} from '../../../remoteActivity/liveCollaboration/liveCollaborationConstants';
import { LIVE_DRAWING_END, LIVE_DRAWING_SET_PATHS, LIVE_DRAWING_UPDATE_PATH } from './liveDrawingConstants';

const initialState = Immutable.Map();

const liveDrawingSetPathsReducer = (state, action) => {
    if (!action.remote || !action.userId) return state;

    return state.update(action.userId, {}, () => ({
        paths: action.paths,
        editedDrawingId: action.editedDrawingId,
    }));
};

const liveDrawingUpdatePathReducer = (state, action) => {
    if (!action.remote || !action.userId || !action.path) return state;

    return state.update(action.userId, {}, ({ paths = [], ...rest }) => ({
        ...rest,
        paths: [...paths.filter(({ id }) => id !== action.path.id), action.path],
    }));
};

const liveDrawingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVE_DRAWING_SET_PATHS:
            return liveDrawingSetPathsReducer(state, action);
        case LIVE_DRAWING_UPDATE_PATH:
            return liveDrawingUpdatePathReducer(state, action);
        case LIVE_DRAWING_END:
            return !action.remote || !action.userId ? state : state.delete(action.userId);
        case LIVE_COLLABORATION_SOCKET_DISCONNECT:
        case LIVE_COLLABORATION_SOCKET_UPDATE_CHANNEL:
            return initialState;
        default:
            return state;
    }
};

export default liveDrawingReducer;
