// Lib
import dateformat from 'dateformat';
import { isObject } from 'lodash/fp';

// Utils
import { getStartOfDayDate, getStartOfYearDate, TIMES } from '../timeUtil';

const formatPastDate = (testTime: number, simpleFormat: string, longFormat: string, relativeTimestamp: number) => {
    const startOfDayDate = getStartOfDayDate(relativeTimestamp);
    const startOfDayMillis = startOfDayDate.getTime();

    if (testTime >= startOfDayMillis) return 'Today';

    const startOfYesterdayMillis = startOfDayMillis - TIMES.DAY;

    if (testTime >= startOfYesterdayMillis) return 'Yesterday';

    const startOfYearDate = getStartOfYearDate(relativeTimestamp);

    const format = testTime > startOfYearDate.valueOf() ? simpleFormat : longFormat;

    return dateformat(testTime, format);
};

const formatFutureDate = (testTime: number, simpleFormat: string, longFormat: string, relativeTimestamp: number) => {
    const startOfDayDate = getStartOfDayDate(relativeTimestamp);
    const startOfDayMillis = startOfDayDate.getTime();
    const startOfTomorrowMillis = startOfDayMillis + TIMES.DAY;
    const startOfDayAfterTomorrowMillis = startOfTomorrowMillis + TIMES.DAY;

    if (testTime < startOfTomorrowMillis) return 'Today';
    if (testTime < startOfDayAfterTomorrowMillis) return 'Tomorrow';

    const startOfNextYearDate = getStartOfYearDate(relativeTimestamp);
    startOfNextYearDate.setFullYear(startOfNextYearDate.getFullYear() + 1);

    const format = testTime < startOfNextYearDate.valueOf() ? simpleFormat : longFormat;

    return dateformat(testTime, format);
};

/**
 * This function converts a timestamp into a friendly time.
 * Currently only built for the past.
 *
 * E.g. "Today", "Yesterday", "Monday, Feb 11", "Tuesday, Dec 4, 2018".
 *
 * The relativeTimestamp can be used to align a timestamp to a particular timezone.
 * NOTE: The arguments must already be aligned to that timestamp.
 * How it works:
 *      1. The timestamp needs to be aligned to the correct timezone.
 *          For example.
 *          If today's date is 18 Sept 2019, and
 *          If the timestamp is supposed to be "9am 18 Sept 2019, AEST", in order to print
 *          "Today (9am)" the date must first be translated to the timezone of the server (US-EDT)
 *          Thus the timestamp needs to be "9am 18 Sept 2019, AEST" + 14 hours so it would instead
 *          be "9am, 18 Sept, 2019, EDT".
 *      2. The relativeTimestamp also needs to be aligned to the correct timestamp.
 *          If the timestamp is in AEST then we need to compare as though the relative time is in AEST too.
 *          For example.
 *          It's currently 8am, 18 Sept 2019, AEST, so we want to print "Today", however this timestamp is
 *          actually 6pm, 17 Sept 2019, EDT.  Thus when comparing the timestamp it would actually think the
 *          time is tomorrow.
 *          So the relative timestamp needs to be updated to be 8am, 18 Sept 2019, EDT so when comparing
 *          8am 18 Sept 2019 EDT with 9am 18 Sept 2019 EDT it will still be the same day "today"
 */
export default ({
    timestamp = Date.now(),
    simpleFormat = 'dddd, mmm d',
    longFormat = 'dddd, mmm d, yyyy',
    relativeTimestamp = Date.now(),
}: {
    timestamp?: number | Date;
    simpleFormat?: string;
    longFormat?: string;
    relativeTimestamp?: number | Date;
} = {}) => {
    const testTime = isObject(timestamp) ? timestamp.getTime() : timestamp;
    const relativeTime = isObject(relativeTimestamp) ? relativeTimestamp.getTime() : relativeTimestamp;

    return testTime > relativeTime
        ? formatFutureDate(testTime, simpleFormat, longFormat, relativeTime)
        : formatPastDate(testTime, simpleFormat, longFormat, relativeTime);
};
