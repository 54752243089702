// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForPropTypes } from '../../../node_module_clones/recompose';
import classNames from 'classnames';

// Utils
import { getColorCssValue } from '../../element/utils/elementColorStyleUtils';

// Styles
import './ColorBar.scss';

export const COLOR_BAR_ORIENTATIONS = {
    TOP: 'top',
    LEFT: 'left',
};

const ColorBar = ({ colorName, isSelected, isEditing, orientation = COLOR_BAR_ORIENTATIONS.TOP }) => {
    // Otherwise convert the colour name to a hex value and render the bar
    const cssBackgroundValue = getColorCssValue(colorName);
    const style = {
        backgroundColor: cssBackgroundValue,
    };

    const className = classNames('ColorBar', orientation, { 'color-target': isSelected || isEditing });

    return <div className={className} style={style} />;
};

ColorBar.propTypes = {
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    colorName: PropTypes.string,
    orientation: PropTypes.string,
};

export default onlyUpdateForPropTypes(ColorBar);
