// Lib
import { createSelector } from 'reselect';

// Utils
import { prop, propIn } from '../../../common/utils/immutableHelper';

// Selectors
import { getElementId } from '../selectors/elementSelector';
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';

// Constants
import * as TYPES from '../../../common/elements/elementConstants';

// Attachment getters
export const getData = prop('data');
export const getError = prop('error');
export const getFetching = prop('fetching');
export const getPercentageComplete = prop('percentageComplete');
export const getLoaded = prop('loaded');
export const getTotal = prop('total');

// File
export const getDataType = propIn(['data', 'type']);
export const getDataFilename = propIn(['data', 'filename']);
export const getDataFilesize = propIn(['data', 'size']);
export const getDataUri = propIn(['data', 'dataUri']);

const attachmentUploadReducer = (acc, attachment) => {
    if (!attachment) return acc;

    acc.count += 1;
    acc.actionType = attachment.get('actionType');
    // Only show progress if we know how much is being loaded
    if (attachment.get('loaded')) {
        acc.showProgress = true;
        acc.total += attachment.get('total');
        acc.loaded += attachment.get('loaded');
    }

    return acc;
};

// State selectors
export const getAttachment = (state, props) => state.getIn(['app', 'attachments', getElementId(state, props)]);

export const getAttachments = (state) => state.getIn(['app', 'attachments']);

export const isUploadingAttachment = createSelector(getAttachments, (attachments) =>
    attachments.some((attachment) => attachment.get('fetching')),
);

export const hasAttachmentsUploads = createSelector(getAttachments, (attachments) =>
    attachments.some(
        (attachment) =>
            attachment.get('fetching') && attachment.get('actionType') === TYPES.ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD,
    ),
);

export const hasAttachmentsDownloads = createSelector(getAttachments, (attachments) =>
    attachments.some(
        (attachment) =>
            attachment.get('fetching') &&
            attachment.get('actionType') === TYPES.ELEMENT_ATTACHMENT_ACTION_TYPES.DOWNLOAD,
    ),
);

export const isUploadingAttachmentWithProgress = createSelector(
    getAttachments,
    isUploadingAttachment,
    (attachments, isUploading) => isUploading && attachments.some((attachment) => attachment.get('loaded')),
);

export const getAttachmentErrors = createSelector(getAttachments, (attachments) =>
    attachments.filter(getError).map(getError),
);
export const allDownloadsSelector = createShallowSelector(getAttachments, (attachments) =>
    attachments
        .filter(
            (attachment) =>
                attachment.get('fetching') &&
                attachment.get('actionType') === TYPES.ELEMENT_ATTACHMENT_ACTION_TYPES.DOWNLOAD,
        )
        .reduce(attachmentUploadReducer, { count: 0, total: 0, loaded: 0, showProgress: false }),
);

export const allUploadsSelector = createShallowSelector(getAttachments, (attachments) =>
    attachments
        .filter(
            (attachment) =>
                attachment.get('fetching') &&
                attachment.get('actionType') === TYPES.ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD,
        )
        .reduce(attachmentUploadReducer, { count: 0, total: 0, loaded: 0, showProgress: false }),
);

export const getAttachmentProgress = createShallowSelector(
    getAttachments,
    (state, ownProps) => ownProps.elementId,
    (attachments, elementId) =>
        attachments
            .filter((attachment) => attachment.get('id') === elementId)
            .reduce(attachmentUploadReducer, { count: 0, total: 0, loaded: 0, showProgress: false }),
);
