import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementColumnIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-column"
        {...props}
    >
        <defs>
            <filter
                id="ElementColumnIcon__a"
                width="131.2%"
                height="131.2%"
                x="-15.6%"
                y="-12.5%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <rect id="ElementColumnIcon__b" width={32} height={32} rx={2} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use xlinkHref="#ElementColumnIcon__b" fill="#000" filter="url(#ElementColumnIcon__a)" />
            <use xlinkHref="#ElementColumnIcon__b" fill="#FFF" />
            <path fill="#F3F3F4" d="M3 9h26v9H3zm0 11h26v9H3z" />
            <rect width={18} height={1.5} x={7} y={4} fill="#323B4A" rx={0.75} />
        </g>
    </svg>
);
const Memo = memo(ElementColumnIcon);
export default Memo;
