import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DistributeHorizontallyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon distribute-horizontally"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M8.5 7.25a.75.75 0 0 1 .75.75v16a.75.75 0 1 1-1.5 0V8a.75.75 0 0 1 .75-.75zm15 0a.75.75 0 0 1 .75.75v16a.75.75 0 1 1-1.5 0V8a.75.75 0 0 1 .75-.75zM19 10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h6zm-.5 1.5h-5v9h5v-9z"
            />
        </g>
    </svg>
);
const Memo = memo(DistributeHorizontallyIcon);
export default Memo;
