import React from 'react';
import PropTypes from 'prop-types';

import { gridPointsToPixels } from '../../utils/grid/gridUtils';
import randomString from '../../../common/uid/randomString';

const PaperIcon = ({ gridSize, width, height }) => {
    const widthPx = Math.round(gridPointsToPixels(width, gridSize));
    const heightPx = Math.round(gridPointsToPixels(height, gridSize));

    const foldWidthPx = Math.round(heightPx - widthPx);
    const viewBoxWidth = Math.round(widthPx + 2);
    const viewBoxHeight = Math.round(heightPx + 2);

    // paths begin at 1,1 to allow space for the 2px width selection border
    const paperBackgroundPath = `M 1 1 h ${widthPx - foldWidthPx} l ${foldWidthPx} ${foldWidthPx} v ${
        heightPx - foldWidthPx
    } H 1 z`;
    const paperFoldPath = `M ${widthPx - foldWidthPx + 1} 1 l ${foldWidthPx} ${foldWidthPx} h ${-foldWidthPx} z`;

    // SVG filter defs must be unique for the document — so generate a sufficiently unique id per render
    const filterId = `paper-filter-${randomString(10)}`;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={viewBoxWidth}
            height={viewBoxHeight}
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        >
            <defs>
                <filter id={filterId} width="300%" height="300%" x="-100%" y="-87.5%" filterUnits="objectBoundingBox">
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
                    <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1.5" />
                    <feColorMatrix
                        in="shadowBlurOuter2"
                        result="shadowMatrixOuter2"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="shadowMatrixOuter2" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="var(--ws-document-icon-background-color)"
                    style={{ transform: 'translate(0,0)' }}
                    shapeRendering="crispEdges"
                    d={paperBackgroundPath}
                />
                <path filter={`url(#${filterId})`} shapeRendering="crispEdges" fill="#000" d={paperFoldPath} />
                <path fill="var(--ws-document-icon-fold-color)" shapeRendering="crispEdges" d={paperFoldPath} />
                <path
                    stroke="var(--ws-document-icon-selection-border-color)"
                    strokeWidth="2"
                    className="selection-stroke"
                    d={paperBackgroundPath}
                />
            </g>
        </svg>
    );
};

PaperIcon.propTypes = {
    gridSize: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default React.memo(PaperIcon);
