// Lib
import { flow } from 'lodash';

// Util
import { getCurrentOperation, getMostRecentTransactionId } from '../undoRedoSelector';
import { getNewTransactionId } from '../undoRedoTransactionManager';
import shouldAddUndo from './shouldAddUndo';
import createUndoAction from './createUndoAction';
import createRedoAction from './createRedoAction';

// Constants
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';

const addTransactionIdIfNeeded = (store) => (action) => {
    if (!action.transactionId) {
        const currentState = store.getState();
        const operation = getCurrentOperation(currentState);

        // NOTE: This could potentially use a white-list of action types for each operation
        //      which would then attach the same transaction ID to those actions.
        //      For now, it's left as a 'boolean style string' that catches all actions without
        //      a transaction ID.
        // If we're within a current operation, use its transaction ID
        action.transactionId = operation ? getMostRecentTransactionId(currentState) : getNewTransactionId();
    }
    return action;
};

const addUndoRedoAction = (store) => (action) => {
    const currentState = store.getState();
    action.undoRedoAction = {
        undo: createUndoAction(currentState, action),
        redo: createRedoAction(currentState, action),
    };
    return action;
};

const enrichIndividualAction = (store, action) =>
    flow(addTransactionIdIfNeeded(store), addUndoRedoAction(store))(action);

const enrichAction = (store, action) => {
    if (action.type !== BATCH_ACTION_TYPE) return enrichIndividualAction(store, action);

    return {
        ...action,
        payload: action.payload.map((payloadAction) => enrichIndividualAction(store, payloadAction)),
    };
};

export default (store) => (next) => (action) => {
    // check if the action should be added to the stack
    if (!shouldAddUndo(store, action)) return next(action);

    const enrichedAction = enrichAction(store, action);
    return next(enrichedAction);
};
