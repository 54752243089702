// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import * as elementRegistry from '../../common/elements/elementRegistry';
import { getElementType } from '../../common/elements/utils/elementPropertyUtils';

const ElementPresentational = (props) => {
    const { element, isDragging, dragOrigin, measureElementRef } = props;
    const DisplayElement = elementRegistry.getElementPresentationalComponent(getElementType(element));

    if (!DisplayElement) {
        console.warn('Unable to find a display element for:', element);
        return <div />;
    }

    const styles = dragOrigin ? { transformOrigin: `${dragOrigin.x}px ${dragOrigin.y}px` } : null;

    return (
        <div ref={measureElementRef} className={classNames('Element', { dragging: isDragging })} style={styles}>
            <DisplayElement {...props} />
        </div>
    );
};

ElementPresentational.propTypes = {
    element: PropTypes.object.isRequired,
    isDragging: PropTypes.bool,
    gridSize: PropTypes.number.isRequired,
    dragOrigin: PropTypes.object,
    measureElementRef: PropTypes.object,
};

export default ElementPresentational;
