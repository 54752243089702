// Lib
import { Capacitor } from '@capacitor/core';
import http from '../utils/services/http';
import { isEmpty, mapValues } from 'lodash/fp';

// Utils
import { getDeviceId } from '../device/deviceService';
import getClientConfig from '../utils/getClientConfig';
import logger from '../logger/logger';
import { createQueryString } from '../../common/utils/stringUtil';
import { fullPageNavigation } from '../reducers/navigationActions';
import platformSingleton from '../platform/platformSingleton';
import { isPlatformElectronMac } from '../platform/utils/platformDetailsUtils';
import { SignInWithAppleNativeApple } from '../../capacitor_plugins/signInWithAppleNativeApple';
import { SignInWithAppleNativeAndroid } from '../../capacitor_plugins/signInWithAppleNativeAndroid';
import { SignInWithGoogle } from '../../capacitor_plugins/signInWithGoogle';

// Constants
import {
    CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_ANDROID_PLUGIN_NAME,
    CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_APPLE_PLUGIN_NAME,
    CAPACITOR_SIGN_IN_WITH_GOOGLE_PLUGIN_NAME,
} from '../../capacitor_plugins/pluginConstants';
import { LOGOUT } from './authConstants';

export const isCurrentBrowserLogoutAction = (action) => {
    if (action.type !== LOGOUT) return false;

    // Used by the electron app when refreshing the page
    if (action.force) return true;

    // Need to clear the electron remote store state on logout, its device ID won't match
    // the login action, so we can use this logic for that scenario
    if (!action.remote && action.clientProcess) return true;

    const deviceId = getDeviceId();
    const actionDeviceId = action.deviceId;

    return !isEmpty(deviceId) && deviceId === actionDeviceId;
};

const authenticateWithGoogleForWebAndElectron = (userData) => {
    const encodedUserData = mapValues((val) => val && encodeURIComponent(val), userData);

    const queryString = createQueryString({
        ...encodedUserData,
        origin: encodeURIComponent(userData.origin || window.location.href),
    });

    const redirectPath = `/api/auth/google/sign-in-redirect?${queryString}`;

    let redirectUri = redirectPath;

    if (isPlatformElectronMac(platformSingleton)) {
        const clientConfig = getClientConfig();
        redirectUri = `${clientConfig.apiRoot}${redirectPath}`;
    }

    return fullPageNavigation(redirectUri);
};

const authenticateWithGoogleForCapacitor = async (userData) => {
    try {
        const { agreeToTerms, subscribeToProductUpdates } = userData;
        const nativePluginResponse = await SignInWithGoogle.onSignInWithGoogleButtonTap();
        const { email, platform, accessToken, idToken, first_name, last_name } = nativePluginResponse;
        const { data: { token, isRegistration } = {} } = await http({
            method: 'post',
            url: 'auth/google/sign-in-token',
            data: {
                agreeToTerms,
                email,
                platform,
                accessToken,
                idToken,
                subscribeToProductUpdates,
                first_name,
                last_name,
            },
        });
        const path = isRegistration ? `#/register-success?token=${token}` : `#/login-success?token=${token}`;
        fullPageNavigation(path);
    } catch (e) {
        logger.error('An error occured while attempting Google Sign In in Capacitor', e);
    }
};

const authenticateWithAppleForCapacitorAndroid = async (userData) => {
    const nativePluginResponse = await SignInWithAppleNativeAndroid.onSignInWithAppleButtonTap();
    const { navigationUri } = nativePluginResponse;
    const path = `#${navigationUri}`;
    fullPageNavigation(path);
};

const authenticateWithAppleForCapacitor = async () => {
    try {
        const nativePluginResponse = await SignInWithAppleNativeApple.onSignInWithAppleButtonTap();
        const { agreeToTerms, email, flow, id_token, subscribeToProductUpdates, user } = nativePluginResponse;
        const { data: { token, isRegistration } = {} } = await http({
            method: 'post',
            url: 'auth/apple/sign-in-token',
            data: {
                agreeToTerms,
                email,
                flow,
                id_token,
                subscribeToProductUpdates,
                user,
            },
        });
        const path = isRegistration ? `#/register-success?token=${token}` : `#/login-success?token=${token}`;
        fullPageNavigation(path);
    } catch (e) {
        logger.error('An error occurred while attempting Apple Sign In in Capacitor', e);
    }
};

const authenticateWithAppleForWebAndElectron = async (userData) => {
    try {
        const clientConfig = getClientConfig();

        const redirectURI = isPlatformElectronMac(platformSingleton)
            ? `${clientConfig.apiRoot}/api/auth/apple/callback`
            : `${clientConfig.urlBase}/api/auth/apple/callback`;

        global.AppleID &&
            global.AppleID.auth.init({
                clientId: 'com.milanote.app.web',
                scope: 'name email',
                redirectURI,
                state: JSON.stringify({
                    origin: window.location.href,
                    ...userData,
                }),
            });

        await global.AppleID.auth.signIn();
    } catch (e) {
        logger.error('An error occurred while attempting Apple Sign In', e);
    }
};

export const authenticateWithGoogle = async (userData) => {
    if (Capacitor.isPluginAvailable(CAPACITOR_SIGN_IN_WITH_GOOGLE_PLUGIN_NAME)) {
        return authenticateWithGoogleForCapacitor(userData);
    }
    return authenticateWithGoogleForWebAndElectron(userData);
};

export const authenticateWithApple = async (userData) => {
    if (Capacitor.isPluginAvailable(CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_ANDROID_PLUGIN_NAME)) {
        return authenticateWithAppleForCapacitorAndroid(userData);
    }
    if (Capacitor.isPluginAvailable(CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_APPLE_PLUGIN_NAME)) {
        return authenticateWithAppleForCapacitor();
    }
    return authenticateWithAppleForWebAndElectron(userData);
};
