import PropTypes from 'prop-types';
import React from 'react';

const onlyShowIfNotPresentational = (DecoratedComponent) => {
    const Component = (props) => (!props.isPresentational ? <DecoratedComponent {...props} /> : null);

    Component.propTypes = {
        isPresentational: PropTypes.bool,
    };

    return Component;
};

export default onlyShowIfNotPresentational;
