import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuUndoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-undo"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M7.89 2.728a.5.5 0 0 0-.702-.078l-3.7 2.96a.5.5 0 0 0 0 .78l3.7 2.96A.5.5 0 0 0 8 8.96V6.5h5a6.5 6.5 0 0 1 0 13h-2.25a.75.75 0 1 0 0 1.5H13a8 8 0 1 0 0-16H8V3.04a.5.5 0 0 0-.11-.312z"
        />
    </svg>
);
const Memo = memo(ContextMenuUndoIcon);
export default Memo;
