export const PRESENTATION_MODE_START = 'PRESENTATION_MODE_START';
export const PRESENTATION_MODE_END = 'PRESENTATION_MODE_END';
export const PRESENTATION_MODE_SETTINGS_UPDATE = 'PRESENTATION_MODE_SETTINGS_UPDATE';

export const PRESENTATION_MODE_HIDE_COMMENTS_KEY = 'milanote.presentation.hide-comments';
export const PRESENTATION_MODE_DISABLE_EDITING_KEY = 'milanote.presentation.disable-editing';
export const PRESENTATION_MODE_FOCUS_MODE_ALLOWED_KEY = 'milanote.presentation.allow-focus-mode';

// Focus mode
export const FOCUS_MODE_ACTIVE = 'FOCUS_MODE_ACTIVE';
export const FOCUS_MODE_APPEARING = 'FOCUS_MODE_APPEARING';
export const FOCUS_MODE_DISAPPEARING = 'FOCUS_MODE_DISAPPEARING';
export const FOCUS_MODE_INACTIVE = 'FOCUS_MODE_INACTIVE';

export const FOCUS_MODE_ELEMENT_CLICKED = 'FOCUS_MODE_ELEMENT_CLICKED';

// Focus mode status
export enum FocusModeStatus {
    APPEARING = 'APPEARING',
    DISAPPEARING = 'DISAPPEARING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}
