import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLongformIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-longform"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M23 6a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h14zm0 1.5H9a.5.5 0 0 0-.492.41L8.5 8v16a.5.5 0 0 0 .41.492L9 24.5h14a.5.5 0 0 0 .492-.41L23.5 24V8a.5.5 0 0 0-.41-.492L23 7.5zM20.21 19c.437 0 .79.336.79.75s-.353.75-.79.75h-8.42c-.437 0-.79-.336-.79-.75s.353-.75.79-.75zm0-3c.437 0 .79.336.79.75s-.353.75-.79.75h-8.42c-.437 0-.79-.336-.79-.75s.353-.75.79-.75zm0-3c.437 0 .79.336.79.75s-.353.75-.79.75h-8.42c-.437 0-.79-.336-.79-.75s.353-.75.79-.75zm0-3c.437 0 .79.336.79.75s-.353.75-.79.75h-8.42c-.437 0-.79-.336-.79-.75s.353-.75.79-.75z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarLongformIcon);
export default Memo;
