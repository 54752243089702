// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

// Components
import ArrowMarker from './ArrowMarker';

// Constants
import { LINE_MARKER_STYLE } from '../../../../common/lines/lineConstants';

export const getLineMarkerId = ({ edge, elementId, idSuffix }) =>
    compact(['marker', edge, elementId, idSuffix]).join('-');

/**
 * The round marker prevents the line path end from being a flat square.
 * We could use a stroke-linecap of "round", however this would cause the line to
 * extend out from behind the arrowhead and thus ruin the arrow's shape.
 * By using this only when an arrowhead isn't visible we get the best of both worlds.
 */
const RoundMarker = ({ edge, elementId, idSuffix }) => (
    <marker
        id={getLineMarkerId({ edge, elementId, idSuffix })}
        className="arrow"
        viewBox="0 0 2 2"
        refX={0.7}
        refY={1}
        markerWidth={1}
        markerHeight={1}
        orient="auto"
        markerUnits="strokeWidth"
    >
        <circle cx={1} cy={1} r={1} />
    </marker>
);

RoundMarker.propTypes = {
    edge: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
    idSuffix: PropTypes.string,
};

const LineMarker = (props) => {
    const { edgeStyle } = props;

    return edgeStyle === LINE_MARKER_STYLE.ARROW ? <ArrowMarker {...props} /> : <RoundMarker {...props} />;
};

LineMarker.propTypes = {
    edgeStyle: PropTypes.string,
};

export default LineMarker;
