import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={6}
        height={10}
        viewBox="0 0 6 10"
        className="Icon chevron-left"
        {...props}
    >
        <path
            fill="#777"
            fillRule="nonzero"
            d="M5.067 8.183a.625.625 0 1 1-.884.884l-4-4a.625.625 0 0 1 0-.884l4-4a.625.625 0 0 1 .884.884L1.509 4.625l3.558 3.558Z"
        />
    </svg>
);
const Memo = memo(ChevronLeftIcon);
export default Memo;
