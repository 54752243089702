// Lib
import React from 'react';

import { getX, getY } from '../../../common/maths/geometry/dimensionUtil';

import { Point } from '../../../common/maths/geometry/pointTypes';

import './DebugUILineVector.scss';

interface Props {
    vector: { x: number; y: number };
    offset?: Point;
    color?: string;
}

const DebugUILineVector = (props: Props) => {
    const { vector, offset = { x: 0, y: 0 }, color = 'black' } = props;

    const x = getX(vector);
    const y = getY(vector);

    return (
        <div className="DebugUILineVector">
            <div className="DebugUILineVector--positioner" style={{ left: getX(offset), top: getY(offset) }}>
                <div>
                    <svg
                        width={Math.abs(x)}
                        height={Math.abs(y)}
                        style={{
                            left: x < 0 ? 0 : -x,
                            top: y < 0 ? 0 : -y,
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            x1={x < 0 ? -x : 0}
                            y1={y < 0 ? -y : 0}
                            x2={x < 0 ? 0 : x}
                            y2={y < 0 ? 0 : y}
                            style={{ stroke: color, strokeWidth: 2 }}
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default DebugUILineVector;
