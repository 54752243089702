// Lib
import { get } from 'lodash';

// Utils
import { getUtmDataFromObject } from '../../common/utils/utmUtils';

// Constants
import { APP_QUERY_PARAMS } from '../../common/utils/urlConstants';

export const getLocation = (state) => get(state.get('routing'), 'locationBeforeTransitions') || {};
export const getLocationBeforeTransition = getLocation;
export const getLocationSearch = (state) => getLocation(state).search || '';
export const getLocationPathname = (state) => getLocation(state).pathname || '';
export const getLocationQuery = (state) => getLocation(state).query || {};
export const getLocationQueryParam = (param) => (state) => getLocationQuery(state)[param];

// Registration / Login parameters
export const getOrigin = getLocationQueryParam(APP_QUERY_PARAMS.ORIGIN);
export const getUrlInviteCode = getLocationQueryParam(APP_QUERY_PARAMS.INVITE_CODE);
export const getUrlMarketingReferralCode =
    getLocationQueryParam(APP_QUERY_PARAMS.MARKETING_REFERRAL_CODE) ||
    getLocationQueryParam(APP_QUERY_PARAMS.MARKETING_REFERRAL_CODE_2);
export const getUrlUserReferralCode = getLocationQueryParam(APP_QUERY_PARAMS.USER_REFERRER_CODE);
export const getUrlSuccessRedirect = getLocationQueryParam(APP_QUERY_PARAMS.SUCCESS_REDIRECT);
export const getUrlRegisteredUserOnboarding = getLocationQueryParam(APP_QUERY_PARAMS.INITIAL_ONBOARDING);
export const getUrlInitialEducation = getLocationQueryParam(APP_QUERY_PARAMS.INITIAL_EDUCATION);
export const getUrlRegistrationAvenue = getLocationQueryParam(APP_QUERY_PARAMS.REGISTRATION_AVENUE);
export const getUrlRegistrationEntryPoint = getLocationQueryParam(APP_QUERY_PARAMS.REGISTRATION_ENTRY_POINT);
export const getUrlPublicBoardId = getLocationQueryParam(APP_QUERY_PARAMS.PUBLIC_BOARD_ID);
export const getUrlAbGroup = getLocationQueryParam(APP_QUERY_PARAMS.AB_GROUP);
export const getUrlFeatureFlags = getLocationQueryParam(APP_QUERY_PARAMS.FEATURE_FLAGS);

export const getUrlPermissionId = getLocationQueryParam(APP_QUERY_PARAMS.PERMISSION);

export const getUrlEmail = getLocationQueryParam(APP_QUERY_PARAMS.EMAIL);
export const getUrlEmailType = getLocationQueryParam(APP_QUERY_PARAMS.EMAIL_TYPE);
export const getFeatureHighlight = getLocationQueryParam(APP_QUERY_PARAMS.FEATURE_HIGHLIGHT);

export const getUrlUtmParams = (state) => {
    const locationQuery = getLocationQuery(state);
    return getUtmDataFromObject(locationQuery);
};

// Preview parameters
export const getIsPreviewRoute = (state) => getLocationPathname(state).indexOf('/preview') === 0;
