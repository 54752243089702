import React from 'react';
import PropTypes from 'prop-types';

// Components
import IconLight from './light/IconLight';
import IconDark from './dark/IconDark';

const Icon = ({ isDarkMode, name, gridSize, getIconSizeForGridSize, size }) => {
    const iconSize = size || getIconSizeForGridSize(gridSize);
    return isDarkMode ? <IconDark name={name} iconSize={iconSize} /> : <IconLight name={name} iconSize={iconSize} />;
};

Icon.propTypes = {
    isDarkMode: PropTypes.bool,
    name: PropTypes.string,
    gridSize: PropTypes.number,
    getIconSizeForGridSize: PropTypes.func,
    size: PropTypes.string,
};

export default Icon;
