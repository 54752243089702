// Lib
import * as Immutable from 'immutable';

// Constants
import { CANVAS_SET_SIZE } from './canvasConstants';

const initialState = Immutable.Map({
    width: 0,
    height: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CANVAS_SET_SIZE:
            return state.set('width', action.canvasSize.width).set('height', action.canvasSize.height);
        default:
            return state;
    }
};
