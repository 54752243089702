'use strict';

var _CSSTransitionGroup = require('./CSSTransitionGroup');

var _CSSTransitionGroup2 = _interopRequireDefault(_CSSTransitionGroup);

var _TransitionGroup = require('./TransitionGroup');

var _TransitionGroup2 = _interopRequireDefault(_TransitionGroup);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}

module.exports = {
    TransitionGroup: _TransitionGroup2.default,
    CSSTransitionGroup: _CSSTransitionGroup2.default,
};
