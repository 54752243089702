// Lib
import { get } from 'lodash';

// Utils
import { createDeepSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { asObject, toArray } from '../../../common/utils/immutableHelper';
import {
    getElementId,
    getTableContentColWidthsGU,
    getTableContentData,
} from '../../../common/elements/utils/elementPropertyUtils';
import { createSelector } from 'reselect';
import { getCurrentlyEditingEditorId } from '../selection/currentlyEditingSelector';
import { convertReduxValueToHotValue } from './utils/tableDataUtils';
import { getTableCellEditorId } from './utils/tableCellEditingUtils';
import { getSelectedElementIds } from '../selection/selectedElementsSelector';
import { userLanguagePreferenceSelector } from '../../user/currentUserSelector';

export const getCurrentCellSelections = (state, { elementId, element }) => {
    elementId = elementId || getElementId(element);
    if (!elementId) return;

    const selectedElementIds = getSelectedElementIds(state);
    const isElementSingleSelected = selectedElementIds.size === 1 && selectedElementIds.first() === elementId;
    if (!isElementSingleSelected) return;

    return state.getIn(['app', 'selection', 'meta', 'currentCellSelections']);
};

/**
 * Used to get a memoized version of tableContent.data (in JS object format)
 */
export const getTableContentDataSelector = () =>
    createDeepSelector(
        (state, ownProps) => getTableContentData(ownProps.element),
        userLanguagePreferenceSelector,
        (tableContentData, locale) =>
            asObject(tableContentData).map((rows) =>
                rows.map((cell) => {
                    // Support old format as to not crash existing tables
                    // TODO: Just support new format when everyone is migrated over
                    const isInNewFormat = get(cell, 'value') !== undefined;
                    if (!isInNewFormat) return cell;

                    return convertReduxValueToHotValue(cell, locale);
                }),
            ),
    );

/**
 * Used to get a memoized version of tableContent.colWidthsGU (in JS object format)
 */
export const getTableContentColWidthsGUSelector = () => {
    return createDeepSelector(
        (state, ownProps) => ownProps.element,
        (element) => {
            return toArray(getTableContentColWidthsGU(element)) || [];
        },
    );
};

export const isCurrentlyEditingTableCell = (elementId) =>
    createSelector(
        getCurrentlyEditingEditorId,
        (currentlyEditingEditorId) => currentlyEditingEditorId === getTableCellEditorId(elementId),
    );
