// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ImageModalCropModeContent from './ImageModalCropModeContent';
import ImageModalViewModeContent from './ImageModalViewModeContent';

// Constants
import { IMAGE_MODAL_MODES } from '../imageModalConstants';

const ImageModalModeSwitch = (props) => {
    const { mode } = props;

    switch (mode) {
        case IMAGE_MODAL_MODES.CROP:
            return <ImageModalCropModeContent {...props} />;
        // To prevent cropped images from flashing when switching into draw mode, we need one component
        // to manage the image element
        case IMAGE_MODAL_MODES.DRAW:
        default:
            return <ImageModalViewModeContent {...props} />;
    }
};

ImageModalModeSwitch.propTypes = {
    mode: PropTypes.string,
};

export default ImageModalModeSwitch;
