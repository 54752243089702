export enum FileValidationErrorCodes {
    MAX_SIZE = 'MAX_SIZE',
    INVALID_TYPE = 'INVALID_TYPE',
    NO_FILE = 'NO_FILE',
    NO_FILENAME = 'NO_FILENAME',
    INVALID_FILENAME = 'INVALID_FILENAME',
    INVALID_EXTENSION = 'INVALID_EXTENSION',
}

interface Props {
    code: FileValidationErrorCodes;
    message: string;
    explanation?: string;
    details?: {
        type: string;
        expected?: string;
        actual: string | number;
        max?: number;
    };
}

export default class FileValidationError extends Error {
    public static type = 'VALIDATION';
    private error: boolean;
    private code: FileValidationErrorCodes;
    private explanation: string | undefined;
    private details:
        | {
              type: string;
              expected?: string;
              actual: string | number;
              max?: number;
          }
        | undefined;

    constructor(props: Props) {
        super(props.message);

        this.error = true;
        this.code = props.code;
        this.message = props.message;
        this.explanation = props.explanation;
        this.details = props.details;
    }
}
