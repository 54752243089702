// Libs
import { isEmpty } from 'lodash';

// Selectors
import {
    getClipboardElements,
    getClipboardOperation,
    getClipboardPasteCount,
    getClipboardElementsBoundingRect,
    getClipboardVisibleOnCanvas,
} from './clipboardSelectors';
import { getCurrentBoardId } from '../../../reducers/currentBoardId/currentBoardIdSelector';
import { getSelectedElements } from '../../../element/selection/selectedElementsSelector';
import { getCurrentFocus } from '../../../reducers/focusSelector';
import { getPageIdSelector } from '../../../reducers/sessionSelector';
import {
    currentBoardCanvasElementsSelector,
    getCurrentVisibleBoardCanvasOrigin,
} from '../../../element/selectors/currentBoardSelector';

// Actions
import { getVisibleCanvasWindowRectInGridUnitsThunk } from '../../../canvas/store/canvasActions';

// Services
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';
import { fetchBoards } from '../../../element/board/boardService';
import { fetchElements } from '../../../element/elementService';

// Utils
import logger from '../../../logger/logger';
import { asObject } from '../../../../common/utils/immutableHelper';
import { getElementId, getScore } from '../../../../common/elements/utils/elementPropertyUtils';
import { canBeAColumnChild } from '../../../../common/columns/columnUtils';
import { isOverlapping } from '../../../../common/maths/geometry/rect';
import { getPasteLocations, DuplicatedElement, LocationMap } from './clipboardPastePositionUtils';
import {
    hasVisibleDescendantsExcludingAttachments,
    isColumn,
} from '../../../../common/elements/utils/elementTypeUtils';

// Actions
import { moveMultipleElements } from '../../../element/actions/elementMoveActions';
import { duplicateMultipleElements } from '../../../element/duplicate/elementDuplicateActions';
import { deselectAllElements, setSelectedElements } from '../../../element/selection/selectionActions';

// Constants
import { ELEMENT_MOVE_OPERATIONS } from '../../../../common/elements/elementConstants';
import { CUT, ELEMENT_CLIPBOARD_PASTE, ELEMENT_CLIPBOARD_SAVE } from './clipboardConstants';

// Types
import { MNElement, MNElementLocation } from '../../../../common/elements/elementModelTypes';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { Point } from '../../../../common/maths/geometry/pointTypes';

type SaveToClipboardArgs = {
    operation: string;
    elements: MNElement[];
    saveId: string;
    pasteCount: number;
    elementsBoundingRect: Rect;
    transactionId?: number;
};

export const saveToClipboard =
    ({
        operation,
        elements,
        saveId,
        pasteCount,
        elementsBoundingRect,
        transactionId = getNewTransactionId(),
    }: SaveToClipboardArgs) =>
    (dispatch: Function) => {
        if (!elements.length) return;

        if (operation === CUT) dispatch(deselectAllElements());

        dispatch({
            type: ELEMENT_CLIPBOARD_SAVE,
            operation,
            elements,
            saveId,
            elementsBoundingRect,
            pasteCount,
            sync: true,
            transactionId,
            timestamp: Date.now(),
        });
    };

/**
 * Fetch pasted elements if they can contain children.
 */
const fetchPastedElements = (elements: DuplicatedElement[]) => async (dispatch: Function) => {
    const containerIdsToFetch = elements.filter(hasVisibleDescendantsExcludingAttachments).map(getElementId);

    if (!isEmpty(containerIdsToFetch)) {
        await dispatch(
            fetchBoards({
                boardIds: containerIdsToFetch,
                force: true,
                loadAncestors: true,
            }),
        );
    }

    const elementIdsToFetch = elements.filter((el) => !hasVisibleDescendantsExcludingAttachments(el)).map(getElementId);

    if (!isEmpty(elementIdsToFetch)) {
        await dispatch(
            fetchElements({
                elementIds: elementIdsToFetch,
                force: true,
                loadAncestors: true,
            }),
        );
    }
};

const handleCutElements = (elements: DuplicatedElement[], locations: LocationMap) => async (dispatch: Function) => {
    const moves = elements
        .filter((element) => locations.has(element.id))
        .map((element) => ({
            id: element.id,
            location: locations.get(element.id),
        }));

    if (isEmpty(moves)) {
        logger.warn('No elements to cut');
        return;
    }

    dispatch(
        moveMultipleElements({
            moves,
            moveOperation: ELEMENT_MOVE_OPERATIONS.CUT,
            transactionId: undefined,
            initialMeasurements: undefined,
        }),
    );
    dispatch(setSelectedElements({ ids: moves.map((move) => move.id), rangeAnchors: null, transactionId: undefined }));
};

const handleCopiedElements =
    (elements: DuplicatedElement[], locations: LocationMap, shouldSelect: boolean) => async (dispatch: Function) => {
        const duplications = elements
            .filter((element) => locations.has(element.id))
            .map((element) => ({
                ...element,
                location: locations.get(element.id),
            }));

        if (isEmpty(duplications)) {
            logger.warn('No elements to copy');
            return;
        }

        dispatch(
            duplicateMultipleElements({
                duplications,
                shouldSelect,
                moves: undefined,
                shouldConvertAliasToBoard: undefined,
                transactionId: undefined,
            }),
        );
    };

type PasteElementsState = {
    currentFocus: string;
    operation: string;
    currentBoardId: string;
    isDestinationColumn: boolean;
    clipboardElements: DuplicatedElement[];
    firstSelectedElementId: string;
    currentBoardCanvasOrigin: Point;
    pasteCount: number;
    canvasRect: Rect;
    elementsBoundingRect: Rect;
    highestScoreOnDestinationCanvas: number;
    visibleOnCanvas: boolean;
};

/**
 * Gets the relevant state to handle the paste operation.
 */
const getPasteElementsStateThunk =
    (elements: DuplicatedElement[]) =>
    (dispatch: Function, getState: Function): PasteElementsState => {
        const state = getState();

        const operation = getClipboardOperation(state);
        const clipboardElements = elements || asObject(getClipboardElements(state));
        const selectedElements = getSelectedElements(state);
        const currentBoardId = getCurrentBoardId(state);
        const currentFocus = getCurrentFocus(state);
        const prevPasteCount = getClipboardPasteCount(state);
        const prevVisibleOnCanvas = getClipboardVisibleOnCanvas(state);

        const highestScoreOnDestinationCanvas = currentBoardCanvasElementsSelector(state)
            .map(getScore)
            .reduce((a, b) => Math.max(a || 0, b), 0);

        // These measurements are in grid units from the top left of the canvas (canvas document coordinates)
        const elementsBoundingRect: Rect = asObject(getClipboardElementsBoundingRect(state));
        const canvasRect = dispatch(getVisibleCanvasWindowRectInGridUnitsThunk());

        // @ts-ignore - TS can't handle currying inside a JS file and the JS file needs to handle
        //  both immutable and POJOs, which we currently don't have a solution for
        const visibleOnCanvas = isOverlapping(elementsBoundingRect, canvasRect);

        const pasteCount = prevVisibleOnCanvas === visibleOnCanvas ? prevPasteCount + 1 : 0;
        const currentBoardCanvasOrigin: Point = asObject(getCurrentVisibleBoardCanvasOrigin(state));

        const isDestinationColumn =
            selectedElements.size === 1 &&
            selectedElements.every(isColumn) &&
            clipboardElements.every(canBeAColumnChild);

        const firstSelectedElementId = getElementId(selectedElements.first());

        return {
            currentFocus,
            operation,
            currentBoardId,
            isDestinationColumn,
            clipboardElements,
            firstSelectedElementId,
            currentBoardCanvasOrigin,
            pasteCount,
            canvasRect,
            elementsBoundingRect,
            highestScoreOnDestinationCanvas,
            visibleOnCanvas,
        };
    };

type PasteElementsArgs = {
    elements: DuplicatedElement[];
    location?: MNElementLocation;
    sessionId?: string;
};

export const pasteElements =
    ({ elements, location, sessionId }: PasteElementsArgs) =>
    async (dispatch: Function, getState: Function) => {
        const pasteElementsState = dispatch(getPasteElementsStateThunk(elements));

        const {
            operation,
            isDestinationColumn,
            clipboardElements,
            pasteCount,
            visibleOnCanvas,
            currentFocus,
            currentBoardId,
            firstSelectedElementId,
            currentBoardCanvasOrigin,
            canvasRect,
            elementsBoundingRect,
            highestScoreOnDestinationCanvas,
        } = pasteElementsState;

        if (!clipboardElements.length) return;

        const shouldSelect = !isDestinationColumn;

        const state = getState();
        const currentPageId = getPageIdSelector(state);

        // If we're pasting from a different session then we need to fetch the elements
        if (sessionId && currentPageId !== sessionId) await fetchPastedElements(elements);

        const locations = getPasteLocations(
            currentFocus,
            currentBoardId,
            isDestinationColumn,
            clipboardElements,
            firstSelectedElementId,
            currentBoardCanvasOrigin,
            pasteCount,
            canvasRect,
            elementsBoundingRect,
            visibleOnCanvas,
            highestScoreOnDestinationCanvas,
            location,
        );

        operation === CUT
            ? dispatch(handleCutElements(clipboardElements, locations))
            : dispatch(handleCopiedElements(clipboardElements, locations, shouldSelect));

        dispatch({
            type: ELEMENT_CLIPBOARD_PASTE,
            pasteCount,
            visibleOnCanvas,
            sync: true,
        });
    };
