// Utils
import { propIn } from '../../../common/utils/immutableHelper';

export const getFeatureData = (feature) => propIn(['content', feature]);
export const getIsFeatureEnabled = (feature) => (element) => propIn(['content', feature, 'enabled'], element) || false;
export const getIsFeatureSuggestionAcknowledged = (feature) => (element) =>
    propIn(['content', feature, 'suggestionAck'], element) || false;

export const shouldShowFeatureSuggestion = (feature) => (element) => {
    const featureAlreadyEnabled = getIsFeatureEnabled(feature)(element);
    if (featureAlreadyEnabled) return false;

    return !getIsFeatureSuggestionAcknowledged(feature)(element);
};
