import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarIndentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-indent"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#48505E"
                d="M16.75 20.25a.75.75 0 0 1 .102 1.493l-.102.007h-8a.75.75 0 0 1-.102-1.493l.102-.007h8zm4.47-7.28a.749.749 0 0 1 1.06 0l2.5 2.5a.749.749 0 0 1 0 1.06l-2.5 2.5a.749.749 0 1 1-1.06-1.06L23.19 16l-1.97-1.97a.749.749 0 0 1 0-1.06zm-4.47 2.28a.75.75 0 0 1 .102 1.493l-.102.007h-8a.75.75 0 0 1-.102-1.493l.102-.007h8zm0-5a.75.75 0 0 1 .102 1.493l-.102.007h-8a.75.75 0 0 1-.102-1.493l.102-.007h8z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarIndentIcon);
export default Memo;
