import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementColorSwatchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={28}
        height={32}
        viewBox="0 0 28 32"
        className="Icon element-color-swatch"
        {...props}
    >
        <defs>
            <filter
                id="ElementColorSwatchIcon__b"
                width="155.6%"
                height="143.5%"
                x="-27.8%"
                y="-17.4%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <filter
                id="ElementColorSwatchIcon__e"
                width="155.6%"
                height="143.5%"
                x="-27.8%"
                y="-17.4%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <path
                id="ElementColorSwatchIcon__a"
                d="M0 1.003A.999.999 0 0 1 .993 0h16.014c.548 0 .993.439.993 1.003v20.994A.998.998 0 0 1 17.007 23H.993A.992.992 0 0 1 0 21.997V1.003z"
            />
            <path
                id="ElementColorSwatchIcon__d"
                d="M0 1.003A.999.999 0 0 1 .993 0h16.014c.548 0 .993.439.993 1.003v20.994A.998.998 0 0 1 17.007 23H.993A.992.992 0 0 1 0 21.997V1.003z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="rotate(-4 17.978 .628)">
                <mask id="ElementColorSwatchIcon__c" fill="#fff">
                    <use xlinkHref="#ElementColorSwatchIcon__a" />
                </mask>
                <use xlinkHref="#ElementColorSwatchIcon__a" fill="#000" filter="url(#ElementColorSwatchIcon__b)" />
                <use xlinkHref="#ElementColorSwatchIcon__a" fill="#FFF" />
                <path fill="#318295" d="M-2.685-2.024h23v18h-23z" mask="url(#ElementColorSwatchIcon__c)" />
            </g>
            <g transform="rotate(4 -109.493 148.676)">
                <mask id="ElementColorSwatchIcon__f" fill="#fff">
                    <use xlinkHref="#ElementColorSwatchIcon__d" />
                </mask>
                <use xlinkHref="#ElementColorSwatchIcon__d" fill="#000" filter="url(#ElementColorSwatchIcon__e)" />
                <use xlinkHref="#ElementColorSwatchIcon__d" fill="#FFF" />
                <path fill="#E15678" d="M-2-2h22v18H-2z" mask="url(#ElementColorSwatchIcon__f)" />
            </g>
        </g>
    </svg>
);
const Memo = memo(ElementColorSwatchIcon);
export default Memo;
