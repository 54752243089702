import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarTrashEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-trash-empty"
        {...props}
    >
        <defs>
            <path
                id="ToolbarTrashEmptyIcon__a"
                d="M0 0h26l-1.778 24.007C24.099 25.66 22.657 27 20.999 27H5c-1.657 0-3.1-1.339-3.223-2.993L0 0z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(2 5)">
            <g transform="translate(1)">
                <mask id="ToolbarTrashEmptyIcon__b" fill="#fff">
                    <use xlinkHref="#ToolbarTrashEmptyIcon__a" />
                </mask>
                <use xlinkHref="#ToolbarTrashEmptyIcon__a" fill="#BBBEC3" />
                <path
                    fill="#A3A7AE"
                    d="M-1 4h28v4z"
                    mask="url(#ToolbarTrashEmptyIcon__b)"
                    transform="matrix(-1 0 0 1 26 0)"
                />
            </g>
            <rect width={28} height={4} fill="#BBBEC3" rx={1} />
        </g>
    </svg>
);
const Memo = memo(ToolbarTrashEmptyIcon);
export default Memo;
