// Lib
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './DrawingEditorCanvasSvg.scss';

const DrawingEditorCanvasSvg = forwardRef(function DrawingEditorCanvasSvg(props, ref) {
    const { children, className, ...rest } = props;

    const svgClasses = classNames('DrawingEditorCanvasSvg', className);

    return (
        <svg ref={ref} className={svgClasses} {...rest}>
            {children}
        </svg>
    );
});

DrawingEditorCanvasSvg.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

export default DrawingEditorCanvasSvg;
