// Lib
import { head } from 'lodash/fp';

// Utils
import getEntityRanges from './getEntityRanges';
import areRangesOverlapping from '../selection/areRangesOverlapping';

/**
 * Returns the closest entity range given a specific entityKey and range to look near.
 * Range is of the form: { startKey, startOffset, endKey, endOffset }
 *
 * NOTE: This algorithm isn't really ideal as it finds all the entities based on the specified entity key and chooses
 *  one that overlaps the current range, if one exists, otherwise it just chooses the first one it finds.
 */
export default (editorState, range, entityKey) => {
    const entityRanges = getEntityRanges(editorState, entityKey);

    // This shouldn't happen if you're certain that the entity exists
    if (entityRanges.length < 0) return null;

    // If there's only one match, then use it
    if (entityRanges.length === 1) return head(entityRanges);

    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const overlappingRange = entityRanges.find((entityRange) => areRangesOverlapping(blockMap, entityRange, range));

    if (overlappingRange) return overlappingRange;

    // TODO Could make this more sophisticated, but I'm not sure it's really needed.
    //  Would want to make sure this scenario is required before spending the time implementing it
    // Otherwise arbitrarily choose the head
    return head(entityRanges);
};
