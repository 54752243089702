// Lib
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { getLargestImageUrl } from './imageModalUtils';
import requestFileHead from '../../../utils/services/http/requestFileHead';
import {
    getElementId,
    getFileMimeType,
    getFileProp,
    getImageProp,
} from '../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateMultipleElements } from '../../actions/elementActions';
import { MimeType } from '../../../../common/media/mimeConstants';

const getImageUrl = (element) => getLargestImageUrl(getImageProp(element));

const mapDispatchToProps = (dispatch) => ({
    updateMultipleElementFileMetadata: (updates) =>
        dispatch(
            updateMultipleElements({
                updates,
                transactionId: -1,
                silent: true,
            }),
        ),
});

const isOctetStream = (mimeType) => mimeType === MimeType.OCTET_STREAM || mimeType === 'binary/octet-stream';

const ImageModalFilesizePreloader = ({ imagesToPreload, updateMultipleElementFileMetadata }) => {
    const fetchImageFileMetadata = async (elements) => {
        // Don't download file data for images that we already have file data for
        // unless they're an octet-stream, as those images are from Unsplash and should
        // instead be treated as JPEGs
        const imagesWithoutFileData = elements.filter((el) => !getFileProp(el) || isOctetStream(getFileMimeType(el)));

        const fileUrls = imagesWithoutFileData.map(getImageUrl);
        const fileDetails = await Promise.all(fileUrls.map(requestFileHead));

        const fileUpdates = imagesWithoutFileData
            .map((element, index) => {
                const file = fileDetails[index];

                // For some reason some Unsplash images are being returned at binary/octet-stream
                // Because we know they're supposed to be JPEGs, we can just override them to be JPEG
                // and it seems to work.
                if (isOctetStream(file?.mime)) {
                    file.mime = MimeType.JPEG;
                }

                return {
                    id: getElementId(element),
                    changes: {
                        file,
                    },
                };
            })
            .filter(
                ({
                    changes: {
                        file: { error },
                    },
                }) => !error,
            );

        if (!fileUpdates.length) return;

        updateMultipleElementFileMetadata(fileUpdates);
    };

    useEffect(() => {
        fetchImageFileMetadata(imagesToPreload.toArray());
    }, [imagesToPreload]);

    return null;
};

ImageModalFilesizePreloader.propTypes = {
    imagesToPreload: PropTypes.object,
    updateMultipleElementFileMetadata: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ImageModalFilesizePreloader);
