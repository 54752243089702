// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getElementId } from '../../../../../common/elements/utils/elementPropertyUtils';
import { alreadySuggested } from '../../../suggestion/suggestionUtils';
import { shouldShowDeleteTasksSuggestion } from './deleteTasksSuggestionUtils';

// Selectors
import { getElementLocalData } from '../../../local/elementLocalDataSelector';

// Actions
import { elementSuggestFeature, elementUnsuggestFeature } from '../../../suggestion/suggestionActions';
import { getCompletedTasks } from '../../taskListActions';

// Constants
import { FEATURE_SUGGESTIONS } from '../../../suggestion/suggestionConstants';

const mapStateToProps = createStructuredSelector({
    elementLocalData: getElementLocalData,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSuggestDeleteTasks: ({ id }) =>
        dispatch(
            elementSuggestFeature({
                id,
                feature: FEATURE_SUGGESTIONS.DELETE_COMPLETED_TASKS,
            }),
        ),
    dispatchUnsuggestDeleteTasks: ({ id }) =>
        dispatch(
            elementUnsuggestFeature({
                id,
                feature: FEATURE_SUGGESTIONS.DELETE_COMPLETED_TASKS,
            }),
        ),
    dispatchGetCompletedTasks: ({ taskListId }) => dispatch(getCompletedTasks({ taskListId })),
});

export default (DecoratedComponent) => {
    @connect(mapStateToProps, mapDispatchToProps)
    class suggestDeleteTasksDecorator extends React.Component {
        onTaskToggle = () => {
            const {
                dispatchSuggestDeleteTasks,
                dispatchGetCompletedTasks,
                dispatchUnsuggestDeleteTasks,
                element,
                elementLocalData,
            } = this.props;

            if (!shouldShowDeleteTasksSuggestion(element)) return;

            const completedTasks = dispatchGetCompletedTasks({ taskListId: getElementId(element) });
            const shouldKeepShowingSuggestion = completedTasks && !!completedTasks.size;
            const shouldShowSuggestion = completedTasks && completedTasks.size >= 2;
            const currentlyShowingSuggestion = alreadySuggested(
                elementLocalData,
                FEATURE_SUGGESTIONS.DELETE_COMPLETED_TASKS,
            );

            if (shouldKeepShowingSuggestion === currentlyShowingSuggestion) return;

            shouldShowSuggestion
                ? dispatchSuggestDeleteTasks({ id: getElementId(element) })
                : dispatchUnsuggestDeleteTasks({ id: getElementId(element) });
        };

        render() {
            return <DecoratedComponent {...this.props} onTaskToggle={this.onTaskToggle} />;
        }
    }

    suggestDeleteTasksDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        elementLocalData: PropTypes.object,
        childElementIds: PropTypes.array.isRequired,
        dispatchSuggestDeleteTasks: PropTypes.func,
        dispatchUnsuggestDeleteTasks: PropTypes.func,
        dispatchGetCompletedTasks: PropTypes.func,
        isEditingChild: PropTypes.bool,
    };

    return suggestDeleteTasksDecorator;
};
