import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarNumlistIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-numlist"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M9.46 17.84c1.09 0 1.811.623 1.811 1.506 0 .58-.28 1.056-1.327 2.01l-.743.73v.034h2.137V23H7.71v-.776l1.812-1.677c.463-.445.711-.724.711-1.129 0-.447-.333-.726-.78-.726-.465 0-.773.298-.77.786H7.66c-.003-1 .73-1.639 1.8-1.639zM23 20.25a.75.75 0 1 1 0 1.5h-8a.75.75 0 1 1 0-1.5zm0-5a.75.75 0 1 1 0 1.5h-8a.75.75 0 1 1 0-1.5zM10.416 8.91V14H9.34V9.93h-.03l-1.166.732v-.955l1.26-.798h1.012zM23 10.25a.75.75 0 1 1 0 1.5h-8a.75.75 0 1 1 0-1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarNumlistIcon);
export default Memo;
