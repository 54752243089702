import { AnyAction } from 'redux';
import { HybridLocationChangeNavigationAction, HYBRID_ACTION_TYPES } from '../../hybridMiddlewareConstants';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../platform/platformTypes';

// Creating the Action for navigation

const shouldCreateHybridActionForNavigation = (): boolean => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_CANVAS:
            return true;
        case LegacyHybridUseCase.IOS_BOARD_LIST:
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
        case LegacyHybridUseCase.IPAD_OS:
            return false;
        default:
            return false;
    }
};

export const createHybridUserNavigateAction = (
    originalAction: AnyAction,
    path: String,
): HybridLocationChangeNavigationAction | undefined => {
    if (shouldCreateHybridActionForNavigation()) {
        return {
            ...originalAction,
            path: path,
            type: HYBRID_ACTION_TYPES.USER_NAVIGATE,
        };
    }
};
