// Strategy
import searchTextStrategy from './searchTextStrategy';

// Components
import SearchHighlightSpan from './SearchHighlightSpan';

// Components
export default () => ({
    dynamicDecorators: (props) => {
        if (!props.filterQuery) return null;

        return [
            {
                strategy: searchTextStrategy(props.filterQuery),
                component: SearchHighlightSpan,
            },
        ];
    },
});
