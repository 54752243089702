import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MediaPlayerPlayIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon media-player-play"
        {...props}
    >
        <path
            fill="#767C86"
            fillRule="evenodd"
            d="M3 3.685v8.63a1 1 0 0 0 1.479.878l7.912-4.315a1 1 0 0 0 0-1.756L4.479 2.807A1 1 0 0 0 3 3.685z"
        />
    </svg>
);
const Memo = memo(MediaPlayerPlayIcon);
export default Memo;
