// Lib
import { omit } from 'lodash/fp';

// Utils
import { buildUrl } from '../../../../common/utils/urlUtil';
import { retrieveDeviceId } from '../../../device/deviceService';

// Constants
import { REGISTRATION_ENTRY_POINT, USER_REGISTRATION_AVENUES } from '../../../../common/users/userConstants';
import { APP_QUERY_PARAMS } from '../../../../common/utils/urlConstants';
import {
    PUBLIC_BOARD_REGISTRATION_INITIAL_EDUCATION,
    PUBLIC_EDIT_REGISTRATION_ONBOARDING,
} from '../../../../common/users/userEducationConstants';

export const buildAppRegisterOverlayUrl = ({
    registrationAvenue = USER_REGISTRATION_AVENUES.BOARD_INVITE,
    registrationEntryPoint = REGISTRATION_ENTRY_POINT.PUBLIC_EDIT_REGISTRATION,
    onboarding = PUBLIC_EDIT_REGISTRATION_ONBOARDING,
    initialEducation = PUBLIC_BOARD_REGISTRATION_INITIAL_EDUCATION,
    currentBoardId,
    permissionId,
    locationQuery,
    ...otherParams
}) => {
    // Remove the flow from the URL when redirecting to registration, if it exists,
    // to prevent the modal being opened again
    const updatedQueryState = omit([APP_QUERY_PARAMS.ONBOARDING_FLOW_ID], locationQuery);

    const queryParams = {
        ...updatedQueryState,
        ...otherParams,
        [APP_QUERY_PARAMS.INITIAL_EDUCATION]: initialEducation,
        [APP_QUERY_PARAMS.REGISTRATION_ENTRY_POINT]: registrationEntryPoint,
    };

    if (currentBoardId) {
        const fromUrl = buildUrl(`/${currentBoardId}`, updatedQueryState);

        queryParams[APP_QUERY_PARAMS.FROM_REDIRECTION] = encodeURIComponent(fromUrl);
        queryParams[APP_QUERY_PARAMS.PUBLIC_BOARD_ID] = currentBoardId;

        if (permissionId) {
            queryParams[APP_QUERY_PARAMS.PERMISSION] = permissionId;
        }
    }

    if (registrationAvenue) {
        queryParams[APP_QUERY_PARAMS.REGISTRATION_AVENUE] = registrationAvenue;
    }

    if (onboarding) {
        queryParams[APP_QUERY_PARAMS.INITIAL_ONBOARDING] = onboarding;
    }

    return buildUrl('/app-register', queryParams);
};

/**
 * Uses the local storage's device ID to ensure that 50% of guest users won't be able to close
 * the registration modal when they arrive on an editable board.
 */
export const getShouldPreventClose = () => {
    const deviceId = retrieveDeviceId();

    if (!deviceId) return false;

    let lastDeviceIdChar = deviceId[deviceId.length - 1];
    lastDeviceIdChar = lastDeviceIdChar && lastDeviceIdChar.toLowerCase();

    return lastDeviceIdChar < 5 || lastDeviceIdChar > 'm';
};
