import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GestureRedoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={47}
        height={45}
        viewBox="0 0 47 45"
        className="Icon gesture-redo"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#323B4A"
                d="M36.226 17.389c2.504 1.572 5.895 6.247 5.768 9.465-.09 2.322.567 5.283-7.036 12.177-4.162 3.772-8.737 2.586-11.33 1.431a8.298 8.298 0 0 1-.542-.254 12.166 12.166 0 0 1-1.574-.933c-1.89-1.222-4.037-2.885-4.571-3.353-1.71-1.51-2.512-3.666-3.055-4.99-.614-1.493-.79-3.876-.102-4.611.665-.709.51-.33 1.806 1.137a9.088 9.088 0 0 1-.334-.849c-.025-.047-.055-.091-.085-.135-2.525-4.122-5.058-8.243-7.58-12.367-.394-.65-.791-1.307-1.1-1.996-.401-.896-.028-1.46.973-1.539 1.06-.084 1.987.296 2.743.99a14.094 14.094 0 0 1 1.62 1.804c2.354 3.06 4.688 6.139 7.032 9.209a.624.624 0 0 0-.077-.163c-2.27-3.444-4.586-6.857-6.794-10.336-1.223-1.922-2.313-3.93-3.376-5.94-.508-.962-.079-1.635 1.03-1.747.523-.053 1.16-.043 1.584.206.84.495 1.707 1.07 2.317 1.803 1.438 1.725 2.732 3.564 4.082 5.36.804 1.066 4.648 6.023 5.447 7.091.077-.063-2.895-4.009-2.818-4.072-1.234-1.864-2.49-3.719-3.693-5.6-1.015-1.585-2.01-3.18-2.936-4.815-.578-1.02-.19-1.752.98-1.87.604-.06 1.268.067 1.85.26 1.072.356 1.807 1.173 2.495 2.022 2.835 3.49 5.688 6.966 8.503 10.472.94 1.172 1.979 2.235 3.278 2.999a5.473 5.473 0 0 0 1.04.468c.367.12.744.203 1.112.236 0 0-2.207-.978-3.781-2.823-.568-.667-1.204-1.403-1.735-2.108-.602-.807 4.04.342 8.86 3.37z"
            />
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="M25.387 5.103h7.374" />
            <path fill="#F37221" fillRule="nonzero" d="m31.956 8.207 4.754-3.104L31.956 2z" />
        </g>
    </svg>
);
const Memo = memo(GestureRedoIcon);
export default Memo;
