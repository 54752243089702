// Lib
import { createSelector } from 'reselect';

// Utils
import { getUserCurrentBoardId } from '../../../common/users/utils/userPropertyUtils';

// Selectors
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getActiveUsers } from '../../notifications/liveEditingSelector';
import { isPresentationModeEnabledSelector } from '../../workspace/presentation/presentationSelector';

export const getHasLiveCollaborators = createSelector(
    getActiveUsers,
    getCurrentBoardId,
    isPresentationModeEnabledSelector,
    (activeUsers, currentBoardId, isPresentationModeEnabled) =>
        !isPresentationModeEnabled ? activeUsers.some((user) => getUserCurrentBoardId(user) === currentBoardId) : false,
);
