export const KEY_CODES = {
    /* Modifiers / Special Keys. */
    BACKSPACE: 8,
    DELETE: 46,
    TAB: 9,
    SHIFT: 16,
    ENTER: 13,
    CTRL: 17,
    ALT: 18,
    CAPS: 20,
    ESC: 27,
    SPACEBAR: 32,

    CMD_LEFT: 91, // Windows key
    CMD_RIGHT: 93, // Windows menu

    /* Navigation. */
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,

    /* Direction keys. */
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,

    /* Numbers. */
    0: 48,
    1: 49,
    2: 50,
    3: 51,
    4: 52,
    5: 53,
    6: 54,
    7: 55,
    8: 56,
    9: 57,

    /* Characters. */
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,

    /* Special Characters */
    ACCENT: 192,
    DASH: 189,
    FORWARD_SLASH: 191,
    BACK_SLASH: 220,
    OPEN_BRACKET: 219,
    CLOSE_BRACKET: 221,
    SEMICOLON: 186,
    SINGLE_QUOTE: 222,
    COMMA: 188,
    PERIOD: 190,

    /* Numpad. */
    CLEAR: 12,
    MULTIPLY: 106,
    ADD: 107,
    SUBTRACT: 109,
    DECIMAL_POINT: 110,
    DIVIDE: 111,
    EQUAL: 187,

    /* F.. */
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    F13: 124,
    F14: 125,
    F15: 126,
    F16: 127,
    F17: 128,
    F18: 129,
    F19: 130,
};

export const NON_CHARACTERS = [
    KEY_CODES.BACKSPACE,
    KEY_CODES.DELETE,
    KEY_CODES.TAB,
    KEY_CODES.SHIFT,
    KEY_CODES.ENTER,
    KEY_CODES.CTRL,
    KEY_CODES.ALT,
    KEY_CODES.CAPS,
    KEY_CODES.ESC,
    KEY_CODES.SPACEBAR,

    KEY_CODES.CMD_LEFT,
    KEY_CODES.CMD_RIGHT,

    KEY_CODES.PAGE_UP,
    KEY_CODES.PAGE_DOWN,
    KEY_CODES.END,
    KEY_CODES.HOME,

    KEY_CODES.LEFT_ARROW,
    KEY_CODES.UP_ARROW,
    KEY_CODES.RIGHT_ARROW,
    KEY_CODES.DOWN_ARROW,
];

export const KEYS = {
    BACKSPACE: 'Backspace',
};
