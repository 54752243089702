// Utils
import { getGlobalLogLevel, getChannelLogLevel } from './loggerUtils';

// Constants
import { DEBUG_LEVEL } from '../../../config/configConstants';

class MilanoteLogger {
    /* eslint-disable no-console */
    channelName?: string;

    constructor(channelName?: string) {
        this.channelName = channelName;
    }

    createChannel = (subChannelName: string) => {
        return new MilanoteLogger(this.channelName ? `${this.channelName}.${subChannelName}` : subChannelName);
    };

    consoleLog =
        (logLevel: number, logMethod: Function) =>
        (...values: unknown[]) => {
            const debugLevel = this.channelName ? getChannelLogLevel(this.channelName) : getGlobalLogLevel();

            if (debugLevel < logLevel) return;

            if (this.channelName) {
                values[0] = `[${this.channelName}] ${values[0]}`;
            }

            logMethod.apply(console, values);
        };

    debug = this.consoleLog(DEBUG_LEVEL.FINE, console.log);
    log = this.consoleLog(DEBUG_LEVEL.FINE, console.log);
    info = this.consoleLog(DEBUG_LEVEL.INFO, console.info);
    table = this.consoleLog(DEBUG_LEVEL.INFO, console.table);
    error = this.consoleLog(DEBUG_LEVEL.ERROR, console.error);
    warn = this.consoleLog(DEBUG_LEVEL.WARN, console.warn);
    groupCollapsed = this.consoleLog(DEBUG_LEVEL.OFF, console.groupCollapsed);
    group = this.consoleLog(DEBUG_LEVEL.OFF, console.group);
    groupEnd = this.consoleLog(DEBUG_LEVEL.OFF, console.groupEnd);
    debugGroupCollapsed = this.consoleLog(DEBUG_LEVEL.OFF, console.groupCollapsed);
    debugGroup = this.consoleLog(DEBUG_LEVEL.OFF, console.group);
    debugGroupEnd = this.consoleLog(DEBUG_LEVEL.OFF, console.groupEnd);
}

const globalLogger = new MilanoteLogger();

export default globalLogger;
