/**
 * This schedules a callback to be run two animation frames in the future.
 *
 * This is commonly required when you need a task to run within an animation frame, but after the next
 * animation frame.
 */
export const requestSecondAnimationFrame = (callbackFn: () => void): (() => void) => {
    let rafId: number | null = null;

    rafId = requestAnimationFrame(() => {
        rafId = requestAnimationFrame(callbackFn);
    });

    return () => {
        if (!rafId) return;
        cancelAnimationFrame(rafId);
    };
};

export default requestSecondAnimationFrame;
