// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { stopPropagationOnly } from '../../../utils/domUtil';

// Components
import FileMediaPlayerScrubberBar from './FileMediaPlayerScrubberBar';

// Styles
import './FileMediaPlayerProgressBar.scss';

const FileMediaPlayerProgressBar = ({ progress, handleSeek, setIsDraggingScrubber }) => (
    <div className="FileMediaPlayerProgressBar" onDoubleClick={stopPropagationOnly}>
        <FileMediaPlayerScrubberBar
            value={progress}
            onChange={handleSeek}
            onDragStart={() => setIsDraggingScrubber(true)}
            onDragEnd={() => setIsDraggingScrubber(false)}
        />
    </div>
);

FileMediaPlayerProgressBar.propTypes = {
    progress: PropTypes.number,
    handleSeek: PropTypes.func,
    setIsDraggingScrubber: PropTypes.func,
};

export default FileMediaPlayerProgressBar;
