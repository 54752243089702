// Lib
import { get, map, uniq, isEmpty, reduce as reduceFp, forEach as forEachFp } from 'lodash/fp';

// Utils
import { chooseSingular, listSeparator, pluralise } from '../utils/stringUtil';
import { getElementType } from '../elements/utils/elementPropertyUtils';
import { getElementDisplayName } from '../elements/elementRegistry';

// Constants
import { BOARD_DEFAULT_TITLE } from '../boards/boardConstants';
import { isBoard, isSketch, isTask } from '../elements/utils/elementTypeUtils';
import { ELEMENT_CHANGE_TYPES } from './notificationConstants';
import { ElementType } from '../elements/elementTypes';

const CHANGE_TYPE_SORT_ORDER = ['Added', 'Updated', 'Completed'];
const ELEMENT_TYPE_SORT_ORDER = [
    ElementType.BOARD_TYPE,
    ElementType.CARD_TYPE,
    ElementType.IMAGE_TYPE,
    ElementType.DOCUMENT_TYPE,
    ElementType.FILE_TYPE,
    ElementType.LINK_TYPE,
    ElementType.TASK_LIST_TYPE,
    ElementType.TASK_TYPE,
    ElementType.LINE_TYPE,
    ElementType.COLUMN_TYPE,
    ElementType.COMMENT_THREAD_TYPE,
];

const reduce = reduceFp.convert({ cap: false });
const forEach = forEachFp.convert({ cap: false });

const updateSummarySortFn = (summaryA, summaryB) => {
    const changeTypeIndexA = CHANGE_TYPE_SORT_ORDER.indexOf(summaryA.changeType);
    const changeTypeIndexB = CHANGE_TYPE_SORT_ORDER.indexOf(summaryB.changeType);

    if (changeTypeIndexA !== changeTypeIndexB) return changeTypeIndexA - changeTypeIndexB;

    const elementTypeIndexA = ELEMENT_TYPE_SORT_ORDER.indexOf(summaryA.elementType);
    const elementTypeIndexB = ELEMENT_TYPE_SORT_ORDER.indexOf(summaryB.elementType);

    return elementTypeIndexA - elementTypeIndexB;
};

const changesValid = (changes) => {
    if (!changes) {
        console.warn('No changes to check');
        return false;
    }

    return true;
};

const isTaskCompletionChange = ({ elementType, changes }) => {
    if (!changesValid(changes)) return false;
    return isTask(elementType) && changes.some((change) => change.changeType === ELEMENT_CHANGE_TYPES.COMPLETE);
};

export const getChangeType = ({ elementType, changes }) => {
    if (!changesValid(changes)) return null;

    if (isTask(elementType) && changes.every((change) => change.changeType === ELEMENT_CHANGE_TYPES.COMPLETE)) {
        return null;
    }

    return changes.some(
        (change) =>
            change.changeType === ELEMENT_CHANGE_TYPES.CREATE || change.changeType === ELEMENT_CHANGE_TYPES.MOVE_IN,
    )
        ? 'Added'
        : 'Updated';
};

const isNewBoardChangeWithoutCustomTitle = (changeEntry) =>
    changeEntry &&
    changeEntry.isNewBoard &&
    (isEmpty(changeEntry.boardTitle) || changeEntry.boardTitle.toLowerCase() === BOARD_DEFAULT_TITLE.toLowerCase());

export const getUpdateSummaryList = (elementChangesMap) => {
    const changeCountMap = reduce(
        (acc, changeEntry) => {
            const { elementType, changes } = changeEntry;

            // Don't include new board changes that don't have custom titles
            if (isNewBoardChangeWithoutCustomTitle(changeEntry)) return acc;

            acc[elementType] = acc[elementType] || {};

            const changeType = getChangeType({ elementType, changes });

            if (changeType) {
                acc[elementType][changeType] = (acc[elementType][changeType] || 0) + 1;
            }

            if (isTaskCompletionChange({ elementType, changes })) {
                acc[elementType].Completed = (acc[elementType].Completed || 0) + 1;
            }

            return acc;
        },
        {},
        elementChangesMap,
    );

    return reduce(
        (descriptionList, elementTypeChangeCounts, elementType) => {
            forEach((count, changeType) => {
                if (!count) return;

                descriptionList.push({
                    elementType,
                    groupName: elementType.toLowerCase(),
                    changeType,
                    count,
                });
            }, elementTypeChangeCounts);

            return descriptionList;
        },
        [],
        changeCountMap,
    ).sort(updateSummarySortFn);
};

export const getNewBoardTitlesWithBoardIds = (elementChanges) =>
    reduce(
        (newBoardTitles, changeEntry) => {
            const { _id, elementType, isNewBoard, boardTitle } = changeEntry;

            if (isBoard(elementType) && isNewBoard && !isNewBoardChangeWithoutCustomTitle(changeEntry)) {
                newBoardTitles.push({ boardId: _id, title: boardTitle });
            }

            return newBoardTitles;
        },
        [],
        elementChanges,
    );

export const getNewBoardTitles = (elementChanges) => getNewBoardTitlesWithBoardIds(elementChanges).map(get('title'));

export const getElementChangeElementTypes = (elementChanges) => {
    const elementTypes = map(getElementType, elementChanges);
    return uniq(elementTypes);
};

export const getElementChangeElementTypeDisplayName = ({ elementType, count }) => {
    if (isSketch(elementType)) return chooseSingular(count, 'sketch', 'sketches');

    let elementTypeDisplayName = getElementDisplayName(elementType).toLowerCase();
    elementTypeDisplayName = isTask(elementType) ? `${elementTypeDisplayName} item` : elementTypeDisplayName;
    return pluralise(elementTypeDisplayName, count);
};

export const describeChanges = (elementChanges) => {
    if (isEmpty(elementChanges)) {
        return { conjunction: 'in' };
    }

    const updateSummaryList = getUpdateSummaryList(elementChanges);

    const conjunction = updateSummaryList.length === 1 && updateSummaryList[0].changeType === 'Added' ? 'to' : 'in';

    let sentence = ' ';

    updateSummaryList.forEach((updateSummary, index) => {
        const { count, changeType, elementType } = updateSummary;

        const separator = listSeparator(index, updateSummaryList.length);

        const isFirstEntry = index === 0;

        const elementTypeDisplayName = isFirstEntry
            ? getElementChangeElementTypeDisplayName({ elementType, count })
            : '';
        const elementTypeDescriptor = isFirstEntry ? ` ${elementTypeDisplayName}` : '';

        sentence += `${changeType.toLowerCase()} ${count}${elementTypeDescriptor}${separator}`;
    });

    return {
        sentence,
        conjunction,
    };
};
