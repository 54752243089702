// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import * as authActions from '../../auth/authActions';

// Components
import FullScreenModal from '../../components/modal/FullScreenModal';
import Icon from '../../components/icons/Icon';
import LinkButton from '../../components/buttons/LinkButton';

// Styles
import './MobileWebFullScreenSignupSuccess.scss';

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(authActions.logout()),
});

const MobileWebFullScreenSignupSuccess = ({ logout }) => (
    <FullScreenModal className="MobileWebFullScreenSignupSuccess">
        <Icon name="green-tick" />

        <h2>Your account has been created!</h2>

        <p className="explanation">
            To continue, please visit <a href="https://app.milanote.com">app.milanote.com</a> on a desktop computer or
            tablet.
        </p>
        <p className="explanation">
            Or <LinkButton onClickFn={logout}>log in</LinkButton> with a different account.
        </p>

        <p className="secondary">
            <span className="question">Why can’t I continue on my phone?</span>
            Milanote is a very visual tool, so setting up a new account is only possible on a larger screen.
        </p>
    </FullScreenModal>
);

MobileWebFullScreenSignupSuccess.propTypes = {
    logout: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(MobileWebFullScreenSignupSuccess);
