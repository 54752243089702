// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import useScrollOnListItemFocus from '../../../../components/lists/useScrollOnListItemFocus';
import MentionSuggestionEntry from '../../../../components/editor/plugins/mentions/MentionSuggestionEntry';
import Icon from '../../../../components/icons/Icon';

// Styles
import './AssignmentSuggestionEntry.scss';

const AssignmentSuggestionEntry = (props) => {
    const { currentlyAssignedUserId, suggestion, index, focusedIndex, onClickSuggestion, searchTerm } = props;

    const [focused, listItemRef] = useScrollOnListItemFocus({ focusedIndex, index });

    const isSelected = currentlyAssignedUserId === suggestion.id;
    return (
        <MentionSuggestionEntry
            entryRef={listItemRef}
            className={classNames('AssignmentSuggestionEntry', { focused })}
            key={suggestion.id}
            id={suggestion.id}
            searchValue={searchTerm}
            mention={suggestion}
            onMouseDown={() => onClickSuggestion(suggestion)}
        >
            {isSelected && <Icon name="notification-viewed" />}
        </MentionSuggestionEntry>
    );
};

AssignmentSuggestionEntry.propTypes = {
    onClickSuggestion: PropTypes.func,
    suggestion: PropTypes.object,
    focusedIndex: PropTypes.number,
    index: PropTypes.number,
    searchTerm: PropTypes.string,
    currentlyAssignedUserId: PropTypes.string,
};

export default AssignmentSuggestionEntry;
