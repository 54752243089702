// Utils
import { getUniqueReactions } from '../../reactions/reactionUtil';
import {
    getReactions,
    getShowAssignments,
    getShowDueDate,
} from '../../../../common/elements/utils/elementPropertyUtils';

// Types
import { isEmpty } from '../../../../common/utils/immutableHelper';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { TaskPillData } from './taskPillsTypes';
import { canGiveFeedback, canWrite } from '../../../../common/permissions/permissionUtil';

type UniqueReaction = {
    emojiCode: string;
    userIds: string[];
};

export const getTaskPillsList = (element: ImMNElement, permissions: number): TaskPillData[] => {
    const pillsList: TaskPillData[] = [];

    if (getShowDueDate(element)) pillsList.push({ type: 'DUE_DATE', id: 'DUE_DATE' });
    if (getShowAssignments(element)) pillsList.push({ type: 'ASSIGNMENT', id: 'ASSIGNMENT' });

    const reactions = getReactions(element);

    if (!isEmpty(reactions)) {
        const uniqueReactions: UniqueReaction[] = Object.values(getUniqueReactions(reactions));

        uniqueReactions.forEach(({ emojiCode, userIds }) => {
            pillsList.push({
                type: 'REACTION',
                id: `REACTION-${emojiCode}`,
                emojiCode,
                userIds,
            });
        });

        const userCanWriteReactions = canWrite(permissions) || canGiveFeedback(permissions);

        if (userCanWriteReactions) pillsList.push({ type: 'ADD_REACTION', id: 'ADD_REACTION' });
    }

    return pillsList;
};
