// Libs
import { uniq } from 'lodash';

// Utils
import { asRect, isOverlapping } from '../../../common/maths/geometry/rect';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';

// Types
import { MNElement, MNElementLocation } from '../../../common/elements/elementModelTypes';
import { ImElementMeasurementMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsTypes';
import { ImMap } from '../../../common/utils/immutableHelper';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';

const isTrashMove = (location: MNElementLocation): boolean => location.section === BoardSections.TRASH;
export const isMoveToTrash = ({ location }: { location: MNElementLocation }): boolean => isTrashMove(location);
export const isMoveFromTrash = ({ from }: { from: MNElementLocation }): boolean => isTrashMove(from);
export const isMoveToOrFromTrash = ({
    location,
    from,
}: {
    location: MNElementLocation;
    from: MNElementLocation;
}): boolean => isMoveToTrash({ location }) || isMoveFromTrash({ from });

type ElementLocationSummary = {
    id: string;
    location: MNElementLocation;
};

/**
 * Determines the elements that are overlapping with the specified element location.
 */
export const getOverlappingElements = (
    elementLocationSummary: ElementLocationSummary,
    currentBoardElements: MNElement[],
    measurements: ImElementMeasurementMap,
): MNElement[] => {
    // NOTE: The ImMap type isn't helping with the extension
    const elementMeasurement = measurements.get(elementLocationSummary.id) as unknown as ImMap<Rect> | undefined;

    if (!elementMeasurement) return [];

    const selectedElementRect = asRect(elementMeasurement);

    return currentBoardElements.filter((element) => {
        if (!element.id) return false;

        // NOTE: The ImMap type isn't helping with the extension
        const elementMeasurement = measurements.get(element.id) as unknown as ImMap<Rect> | undefined;

        if (!elementMeasurement) return false;

        const elementRect = asRect(elementMeasurement);

        // @ts-ignore TS can't handle the currying in a JS file
        return isOverlapping(selectedElementRect, elementRect);
    });
};

/**
 * Returns an array of overlapping elements for a group of element locations from a list of elements.
 */
export const getAllOverlappingElements = (
    elementLocationSummaries: ElementLocationSummary[],
    currentBoardElements: MNElement[],
    measurements: ImElementMeasurementMap,
): MNElement[] =>
    elementLocationSummaries.reduce((acc, elementLocationSummary) => {
        const overlappingElements = getOverlappingElements(elementLocationSummary, currentBoardElements, measurements);
        return uniq(acc.concat(overlappingElements));
    }, [] as MNElement[]);
