// Lib
import { cloneDeep } from 'lodash';

// Utils
import { asNum, getX, getY } from '../../../../common/maths/geometry/dimensionUtil';
import { isColumn, isLine } from '../../../../common/elements/utils/elementTypeUtils';
import { getElementsTopLeftPositionGridUnits } from '../../../../common/elements/utils/elementPositionUtils';
import {
    getLocationPosition,
    getLocationSection,
    getScore,
    getXPosition,
    getYPosition,
} from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { PASTE_OFFSET_X, PASTE_OFFSET_Y } from './clipboardConstants';
import { BoardSections } from '../../../../common/boards/boardConstants';

// Types
import { Point } from '../../../../common/maths/geometry/pointTypes';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { MNElement, MNElementLocation, MNElementPosition } from '../../../../common/elements/elementModelTypes';

export interface DuplicatedElement extends MNElement {
    id: string;
}

export type LocationMap = Map<string, MNElementLocation>;

const buildLocationMap = (
    elements: DuplicatedElement[],
    callback: (element: DuplicatedElement, i: number) => MNElementLocation,
): LocationMap => new Map(elements.map((element, i) => [element.id, callback(element, i)]));

const placeElementsInFront = (locations: LocationMap, frontScore: number): LocationMap => {
    // get the pasted set, sorted by score (z-index)
    const entries = [...locations.entries()].sort((a, b) => (a[1].position?.score || 0) - (b[1].position?.score || 0));

    // rewrite their locations to preserve that order, but in front of the new frontScore
    return new Map(
        entries.map(([id, { position, ...location }], i) => [
            id,
            { ...location, position: { ...position, score: frontScore + 1 + i } },
        ]),
    );
};

/**
 * Gets the canvas origin of a board when we know the elements bounding rect and also their position on the board.
 */
const getCanvasOriginFromElementsAndBoundingRect = (elements: MNElement[], elementsBoundingRect: Rect): Point => {
    const clipboardElementsTopLeft = getElementsTopLeftPositionGridUnits(elements);
    return {
        x: getX(clipboardElementsTopLeft) - asNum(elementsBoundingRect.left),
        y: getY(clipboardElementsTopLeft) - asNum(elementsBoundingRect.top),
    };
};

/**
 * Determines the shift in the canvas origin in the current board, based on the element positions and
 * elements bounding rect in the original board.
 *
 * If copying and pasting in the same board, the translation will be 0,0.
 */
const getCanvasOriginTranslation = (elements: MNElement[], canvasOrigin: Point, elementsBoundingRect: Rect): Point => {
    const originalCanvasOrigin = getCanvasOriginFromElementsAndBoundingRect(elements, elementsBoundingRect);

    return {
        // NOTE: The elementsBoundingRect gives us the offset for the element from the top left
        x: getX(canvasOrigin) - getX(originalCanvasOrigin),
        y: getY(canvasOrigin) - getY(originalCanvasOrigin),
    };
};

/**
 * If the clipboard elements are copied from a board that has a canvasOrigin that's been shifted, then the
 * position of the elements might be outside the visible area of the current board.
 *
 * In this case, we want to shift those elements to be inside the canvas origin of the current board (with
 * the same offset from the top left).
 */
export const repositionClipboardElementsForPaste = (
    clipboardElements: DuplicatedElement[],
    currentBoardCanvasOrigin: Point,
    elementsBoundingRect: Rect,
): DuplicatedElement[] => {
    const positionTranslation = getCanvasOriginTranslation(
        clipboardElements,
        currentBoardCanvasOrigin,
        elementsBoundingRect,
    );

    return clipboardElements.map((el) => {
        const shiftedElement = cloneDeep(el);

        shiftedElement.location = {
            ...shiftedElement.location,
            position: {
                ...shiftedElement.location?.position,
                // NOTE: The positionTranslation should ensure that the Math.max is unnecessary,
                //  but I'm adding it here due to the number of issues we've had with copy & paste
                x: Math.max(getXPosition(shiftedElement) + positionTranslation.x, getX(currentBoardCanvasOrigin)),
                y: Math.max(getYPosition(shiftedElement) + positionTranslation.y, getY(currentBoardCanvasOrigin)),
            },
        };

        return shiftedElement;
    });
};

/**
 * Get the new position of the element, based on its current position and a given translation.
 */
const getTranslatedPosition = (element: MNElement, positionTranslation: Point): Point => {
    const x = getXPosition(element) + getX(positionTranslation);
    const y = getYPosition(element) + getY(positionTranslation);

    return {
        x,
        y,
    };
};

/**
 * Determines the translation required to center the elements on the canvas, ensuring that they don't get
 * pasted beyond the top or the left of the current viewport.
 *
 * E.g. If centering the elements would cause them to get pasted beyond the left boundary of the window,
 *
 */
const getCanvasCenterTranslation = (elementsBoundingRect: Rect, canvasRect: Rect, pasteCount: number): Point => {
    const minCenterOffsetX = PASTE_OFFSET_X * pasteCount;
    const minCenterOffsetY = PASTE_OFFSET_Y * pasteCount;
    const trueCenterOffsetX = canvasRect.width / 2 - elementsBoundingRect.width / 2;
    const trueCenterOffsetY = canvasRect.height / 2 - elementsBoundingRect.height / 2;

    const centerOffsetX = Math.max(1, minCenterOffsetX, trueCenterOffsetX);
    const centerOffsetY = Math.max(1, minCenterOffsetY, trueCenterOffsetY);

    const canvasWindowTopLeft = {
        x: canvasRect.left,
        y: canvasRect.top,
    };

    const x = getX(canvasWindowTopLeft) + centerOffsetX - getX(elementsBoundingRect);
    const y = getY(canvasWindowTopLeft) + centerOffsetY - getY(elementsBoundingRect);

    return {
        x,
        y,
    };
};

export const getCanvasCenterPasteLocations = (
    elements: DuplicatedElement[],
    currentBoardId: string,
    canvasRect: Rect,
    elementsBoundingRect: Rect,
    pasteCount: number,
): LocationMap => {
    const centerTranslation = getCanvasCenterTranslation(elementsBoundingRect, canvasRect, pasteCount);

    return buildLocationMap(elements, (element) => ({
        parentId: currentBoardId,
        section: getLocationSection(element),
        position: getTranslatedPosition(element, centerTranslation),
    }));
};

export const getCanvasOffsetPasteLocations = (
    elements: DuplicatedElement[],
    currentBoardId: string,
    pasteCount: number,
): LocationMap => {
    const offsets = buildLocationMap(elements, (element) => ({
        parentId: currentBoardId,
        section: getLocationSection(element),
        position: {
            ...element.location?.position,
            x: getXPosition(element) + PASTE_OFFSET_X * pasteCount,
            y: getYPosition(element) + PASTE_OFFSET_Y * pasteCount,
        },
    }));

    return offsets;
};

const getCanvasPasteLocations = (
    elements: DuplicatedElement[],
    visibleOnCanvas: boolean,
    currentBoardId: string,
    canvasRect: Rect,
    elementsBoundingRect: Rect,
    pasteCount: number,
    highestScore: number,
): LocationMap => {
    const locations = visibleOnCanvas
        ? getCanvasOffsetPasteLocations(elements, currentBoardId, pasteCount)
        : getCanvasCenterPasteLocations(elements, currentBoardId, canvasRect, elementsBoundingRect, pasteCount);

    return placeElementsInFront(locations, highestScore);
};

const hasCanvasPosition = (elem: MNElement): boolean => elem.location?.section === BoardSections.CANVAS;

/*
 * Handles pasting a group of items to a specific point on the canvas
 * (eg via a context menu click rather than a global cmd+v)
 */
export const getCanvasSpecifiedPasteLocations = (
    elements: DuplicatedElement[],
    pasteLocation: MNElementLocation,
    elementsBoundingRect: Rect,
    currentBoardCanvasOrigin: Point,
    highestScore: number,
): LocationMap => {
    const { position, ...baseLocation } = pasteLocation;

    if (!position) throw new Error('Cannot calculate a paste offset without a clicked position');

    const pasteOffset = {
        x: getX(position) - getX(currentBoardCanvasOrigin),
        y: getY(position) - getY(currentBoardCanvasOrigin),
    };

    const canvasItems = elements.filter(hasCanvasPosition);
    const canvasLocations = buildLocationMap(canvasItems, (element) => ({
        ...baseLocation,
        position: {
            ...getLocationPosition(element),
            x: pasteOffset.x + getXPosition(element) - getX(elementsBoundingRect),
            y: pasteOffset.y + getYPosition(element) - getY(elementsBoundingRect),
        },
    }));

    const columnItems = elements.filter((el) => !hasCanvasPosition(el)).sort((a, b) => getScore(a) - getScore(b));
    const columnLocations = buildLocationMap(columnItems, (element, idx) => ({
        ...baseLocation,
        position: {
            ...getLocationPosition(element),
            x: pasteOffset.x + idx * PASTE_OFFSET_X,
            y: pasteOffset.y + idx * PASTE_OFFSET_Y,
        },
    }));

    return placeElementsInFront(new Map([...canvasLocations, ...columnLocations]), highestScore);
};

/**
 * Gets the position when pasting to the inbox.
 */
const getInboxPastePosition = (element: MNElement): MNElementPosition => ({
    ...element.location?.position,
    index: 0,
    score: undefined,
});

const shouldSendToCanvas = (element: MNElement, isDestinationColumn: boolean): boolean =>
    isLine(element) || (isDestinationColumn && isColumn(element));

const getInboxPasteLocations = (
    elements: DuplicatedElement[],
    visibleOnCanvas: boolean,
    currentBoardId: string,
    canvasRect: Rect,
    elementsBoundingRect: Rect,
    pasteCount: number,
    destinationParentId: string,
    isDestinationColumn: boolean,
    highestScore: number,
): LocationMap => {
    const canvasElements = elements.filter((element) => shouldSendToCanvas(element, isDestinationColumn));
    const inboxElements = elements.filter((element) => !shouldSendToCanvas(element, isDestinationColumn));

    const canvasLocationsMap = getCanvasPasteLocations(
        canvasElements,
        visibleOnCanvas,
        currentBoardId,
        canvasRect,
        elementsBoundingRect,
        pasteCount,
        highestScore,
    );

    const inboxLocationsMap = buildLocationMap(inboxElements, (element) => ({
        ...element.location,
        parentId: destinationParentId,
        section: BoardSections.INBOX,
        position: getInboxPastePosition(element),
    }));

    return new Map([...canvasLocationsMap, ...inboxLocationsMap]);
};

export const getPasteLocations = (
    currentFocus: string,
    currentBoardId: string,
    isDestinationColumn: boolean,
    clipboardElements: DuplicatedElement[],
    firstSelectedElementId: string,
    currentBoardCanvasOrigin: Point,
    pasteCount: number,
    canvasRect: Rect,
    elementsBoundingRect: Rect,
    visibleOnCanvas: boolean,
    highestScore: number,
    location?: MNElementLocation,
): LocationMap => {
    // Shift the clipboard elements to ensure they're within the canvas origin of the new board
    const translatedClipboardElements = repositionClipboardElementsForPaste(
        clipboardElements,
        currentBoardCanvasOrigin,
        elementsBoundingRect,
    );

    const isDestinationInbox = currentFocus === BoardSections.INBOX;

    if (isDestinationColumn || isDestinationInbox) {
        const destinationParentId = isDestinationColumn ? firstSelectedElementId : currentBoardId;
        return getInboxPasteLocations(
            translatedClipboardElements,
            visibleOnCanvas,
            currentBoardId,
            canvasRect,
            elementsBoundingRect,
            pasteCount,
            destinationParentId,
            isDestinationColumn,
            highestScore,
        );
    }

    // If provided a location then paste to that location
    // - Currently this is used when pasting via the canvas context menu
    if (location) {
        return getCanvasSpecifiedPasteLocations(
            translatedClipboardElements,
            location,
            elementsBoundingRect,
            currentBoardCanvasOrigin,
            highestScore,
        );
    }

    return getCanvasPasteLocations(
        translatedClipboardElements,
        visibleOnCanvas,
        currentBoardId,
        canvasRect,
        elementsBoundingRect,
        pasteCount,
        highestScore,
    );
};
