// This file is generated by scripts/createColorTokens/createColorTokens.ts

export type ColorEntry = {
    hex: string;
    rgba: string;
    css: string;
};

export type MNColorPalette = {
    alert: {
        alert_blue_050: ColorEntry;
        alert_blue_1000: ColorEntry;
        alert_blue_1500: ColorEntry;
        alert_blue_200: ColorEntry;
        alert_blue_900: ColorEntry;
        alert_green_050: ColorEntry;
        alert_green_1000: ColorEntry;
        alert_green_1500: ColorEntry;
        alert_green_200: ColorEntry;
        alert_green_900: ColorEntry;
        alert_orange_050: ColorEntry;
        alert_orange_1000: ColorEntry;
        alert_orange_1500: ColorEntry;
        alert_orange_200: ColorEntry;
        alert_orange_900: ColorEntry;
        alert_red_050: ColorEntry;
        alert_red_1000: ColorEntry;
        alert_red_1500: ColorEntry;
        alert_red_200: ColorEntry;
        alert_red_900: ColorEntry;
    };
    avatar: {
        avatar_bert: ColorEntry;
        avatar_elmo: ColorEntry;
        avatar_ernie: ColorEntry;
        avatar_gonzo: ColorEntry;
        avatar_grover: ColorEntry;
        avatar_kermit: ColorEntry;
        avatar_piggy: ColorEntry;
        avatar_rosita: ColorEntry;
        avatar_von_count: ColorEntry;
    };
    canvas: {
        canvas_dark_forest: ColorEntry;
        canvas_dark_galaxy: ColorEntry;
        canvas_dark_sand: ColorEntry;
        canvas_dark_sky: ColorEntry;
        canvas_darkmode: ColorEntry;
        canvas_light_forest: ColorEntry;
        canvas_light_galaxy: ColorEntry;
        canvas_light_sand: ColorEntry;
        canvas_light_sky: ColorEntry;
        canvas_lightmode: ColorEntry;
    };
    element: {
        element_blue_1000: ColorEntry;
        element_blue_900: ColorEntry;
        element_brown_1000: ColorEntry;
        element_brown_900: ColorEntry;
        element_green_1000: ColorEntry;
        element_green_900: ColorEntry;
        element_light_grey_1000: ColorEntry;
        element_light_grey_900: ColorEntry;
        element_medium_grey_1000: ColorEntry;
        element_medium_grey_900: ColorEntry;
        element_orange_1000: ColorEntry;
        element_orange_900: ColorEntry;
        element_pink_1000: ColorEntry;
        element_pink_900: ColorEntry;
        element_purple_1000: ColorEntry;
        element_purple_900: ColorEntry;
        element_red_1000: ColorEntry;
        element_red_900: ColorEntry;
        element_royal_blue_1000: ColorEntry;
        element_royal_blue_900: ColorEntry;
        element_teal_1000: ColorEntry;
        element_teal_900: ColorEntry;
        element_yellow_1000: ColorEntry;
        element_yellow_900: ColorEntry;
    };
    highlight: {
        light_blue_050: ColorEntry;
        light_blue_1500: ColorEntry;
        light_blue_200: ColorEntry;
        light_blue_900: ColorEntry;
        light_lime_050: ColorEntry;
        light_lime_1500: ColorEntry;
        light_lime_200: ColorEntry;
        light_lime_900: ColorEntry;
        light_orange_050: ColorEntry;
        light_orange_1000: ColorEntry;
        light_orange_1500: ColorEntry;
        light_orange_200: ColorEntry;
        light_pink_050: ColorEntry;
        light_pink_1500: ColorEntry;
        light_pink_200: ColorEntry;
        light_pink_900: ColorEntry;
        light_yellow_050: ColorEntry;
        light_yellow_1500: ColorEntry;
        light_yellow_200: ColorEntry;
        light_yellow_900: ColorEntry;
    };
    neutral: {
        darkmode_grey_050: ColorEntry;
        darkmode_grey_100: ColorEntry;
        darkmode_grey_1000: ColorEntry;
        darkmode_grey_200: ColorEntry;
        darkmode_grey_300: ColorEntry;
        darkmode_grey_400: ColorEntry;
        darkmode_grey_500: ColorEntry;
        darkmode_grey_600: ColorEntry;
        darkmode_grey_700: ColorEntry;
        darkmode_grey_750: ColorEntry;
        darkmode_grey_800: ColorEntry;
        darkmode_grey_900: ColorEntry;
        lightmode_grey_050: ColorEntry;
        lightmode_grey_100: ColorEntry;
        lightmode_grey_1000: ColorEntry;
        lightmode_grey_200: ColorEntry;
        lightmode_grey_300: ColorEntry;
        lightmode_grey_400: ColorEntry;
        lightmode_grey_500: ColorEntry;
        lightmode_grey_600: ColorEntry;
        lightmode_grey_700: ColorEntry;
        lightmode_grey_800: ColorEntry;
        lightmode_grey_900: ColorEntry;
        opacity: {
            darkmode_grey_1000_00: ColorEntry;
            darkmode_grey_1000_05: ColorEntry;
            darkmode_grey_1000_10: ColorEntry;
            darkmode_grey_1000_15: ColorEntry;
            darkmode_grey_1000_20: ColorEntry;
            darkmode_grey_1000_30: ColorEntry;
            darkmode_grey_1000_60: ColorEntry;
            darkmode_grey_1000_80: ColorEntry;
            lightmode_grey_1000_00: ColorEntry;
            lightmode_grey_1000_05: ColorEntry;
            lightmode_grey_1000_10: ColorEntry;
            lightmode_grey_1000_15: ColorEntry;
            lightmode_grey_1000_20: ColorEntry;
            lightmode_grey_1000_30: ColorEntry;
            lightmode_grey_1000_60: ColorEntry;
            lightmode_grey_1000_80: ColorEntry;
            white_1000_00: ColorEntry;
            white_1000_05: ColorEntry;
            white_1000_10: ColorEntry;
            white_1000_15: ColorEntry;
            white_1000_20: ColorEntry;
            white_1000_30: ColorEntry;
            white_1000_60: ColorEntry;
            white_1000_80: ColorEntry;
        };
        white_1000: ColorEntry;
    };
    primary: {
        deep_orange_050: ColorEntry;
        deep_orange_100: ColorEntry;
        deep_orange_1000: ColorEntry;
        deep_orange_200: ColorEntry;
        deep_orange_300: ColorEntry;
        deep_orange_400: ColorEntry;
        deep_orange_500: ColorEntry;
        deep_orange_600: ColorEntry;
        deep_orange_700: ColorEntry;
        deep_orange_800: ColorEntry;
        deep_orange_900: ColorEntry;
        royal_blue_050: ColorEntry;
        royal_blue_100: ColorEntry;
        royal_blue_1000: ColorEntry;
        royal_blue_200: ColorEntry;
        royal_blue_300: ColorEntry;
        royal_blue_400: ColorEntry;
        royal_blue_500: ColorEntry;
        royal_blue_600: ColorEntry;
        royal_blue_700: ColorEntry;
        royal_blue_800: ColorEntry;
        royal_blue_900: ColorEntry;
    };
};

const colorPalette: MNColorPalette = {
    alert: {
        alert_blue_050: { hex: '#e8f5fc', rgba: 'rgba(232, 245, 252, 1)', css: 'var(--alert-alertblue-050)' },
        alert_blue_1000: { hex: '#0e84bf', rgba: 'rgba(14, 132, 191, 1)', css: 'var(--alert-alertblue-1000)' },
        alert_blue_1500: { hex: '#0a4f72', rgba: 'rgba(10, 79, 114, 1)', css: 'var(--alert-alertblue-1500)' },
        alert_blue_200: { hex: '#cde9f7', rgba: 'rgba(205, 233, 247, 1)', css: 'var(--alert-alertblue-200)' },
        alert_blue_900: { hex: '#16a0e5', rgba: 'rgba(22, 160, 229, 1)', css: 'var(--alert-alertblue-900)' },
        alert_green_050: { hex: '#edf8ee', rgba: 'rgba(237, 248, 238, 1)', css: 'var(--alert-alertgreen-050)' },
        alert_green_1000: { hex: '#429d4c', rgba: 'rgba(66, 157, 76, 1)', css: 'var(--alert-alertgreen-1000)' },
        alert_green_1500: { hex: '#3a5a3e', rgba: 'rgba(58, 90, 62, 1)', css: 'var(--alert-alertgreen-1500)' },
        alert_green_200: { hex: '#d9eedb', rgba: 'rgba(217, 238, 219, 1)', css: 'var(--alert-alertgreen-200)' },
        alert_green_900: { hex: '#4eb85a', rgba: 'rgba(78, 184, 90, 1)', css: 'var(--alert-alertgreen-900)' },
        alert_orange_050: { hex: '#fff3e9', rgba: 'rgba(255, 243, 233, 1)', css: 'var(--alert-alertorange-050)' },
        alert_orange_1000: { hex: '#e4750e', rgba: 'rgba(228, 117, 14, 1)', css: 'var(--alert-alertorange-1000)' },
        alert_orange_1500: { hex: '#704d2d', rgba: 'rgba(112, 77, 45, 1)', css: 'var(--alert-alertorange-1500)' },
        alert_orange_200: { hex: '#ffe4ce', rgba: 'rgba(255, 228, 206, 1)', css: 'var(--alert-alertorange-200)' },
        alert_orange_900: { hex: '#ff8d22', rgba: 'rgba(255, 141, 34, 1)', css: 'var(--alert-alertorange-900)' },
        alert_red_050: { hex: '#fdefef', rgba: 'rgba(253, 239, 239, 1)', css: 'var(--alert-alertred-050)' },
        alert_red_1000: { hex: '#d6223e', rgba: 'rgba(214, 34, 62, 1)', css: 'var(--alert-alertred-1000)' },
        alert_red_1500: { hex: '#6a4141', rgba: 'rgba(106, 65, 65, 1)', css: 'var(--alert-alertred-1500)' },
        alert_red_200: { hex: '#f9dede', rgba: 'rgba(249, 222, 222, 1)', css: 'var(--alert-alertred-200)' },
        alert_red_900: { hex: '#f02848', rgba: 'rgba(240, 40, 72, 1)', css: 'var(--alert-alertred-900)' },
    },
    avatar: {
        avatar_bert: { hex: '#ebdc91', rgba: 'rgba(235, 220, 145, 1)', css: 'var(--avatar-avatarbert)' },
        avatar_elmo: { hex: '#e68c8c', rgba: 'rgba(230, 140, 140, 1)', css: 'var(--avatar-avatarelmo)' },
        avatar_ernie: { hex: '#f3bd7f', rgba: 'rgba(243, 189, 127, 1)', css: 'var(--avatar-avatarernie)' },
        avatar_gonzo: { hex: '#92c1f5', rgba: 'rgba(146, 193, 245, 1)', css: 'var(--avatar-avatargonzo)' },
        avatar_grover: { hex: '#92c1f5', rgba: 'rgba(146, 193, 245, 1)', css: 'var(--avatar-avatargrover)' },
        avatar_kermit: { hex: '#8ce696', rgba: 'rgba(140, 230, 150, 1)', css: 'var(--avatar-avatarkermit)' },
        avatar_piggy: { hex: '#e68cba', rgba: 'rgba(230, 140, 186, 1)', css: 'var(--avatar-avatarpiggy)' },
        avatar_rosita: { hex: '#94dcd5', rgba: 'rgba(148, 220, 213, 1)', css: 'var(--avatar-avatarrosita)' },
        avatar_von_count: { hex: '#ba9af3', rgba: 'rgba(186, 154, 243, 1)', css: 'var(--avatar-avatarvoncount)' },
    },
    canvas: {
        canvas_dark_forest: { hex: '#1c2624', rgba: 'rgba(28, 38, 36, 1)', css: 'var(--canvas-canvasdarkforest)' },
        canvas_dark_galaxy: { hex: '#26232a', rgba: 'rgba(38, 35, 42, 1)', css: 'var(--canvas-canvasdarkgalaxy)' },
        canvas_dark_sand: { hex: '#27241f', rgba: 'rgba(39, 36, 31, 1)', css: 'var(--canvas-canvasdarksand)' },
        canvas_dark_sky: { hex: '#1c2329', rgba: 'rgba(28, 35, 41, 1)', css: 'var(--canvas-canvasdarksky)' },
        canvas_darkmode: { hex: '#262626', rgba: 'rgba(38, 38, 38, 1)', css: 'var(--canvas-canvasdarkmode)' },
        canvas_light_forest: { hex: '#d9ebe6', rgba: 'rgba(217, 235, 230, 1)', css: 'var(--canvas-canvaslightforest)' },
        canvas_light_galaxy: { hex: '#e5e3e8', rgba: 'rgba(229, 227, 232, 1)', css: 'var(--canvas-canvaslightgalaxy)' },
        canvas_light_sand: { hex: '#f0eedf', rgba: 'rgba(240, 238, 223, 1)', css: 'var(--canvas-canvaslightsand)' },
        canvas_light_sky: { hex: '#dceaf6', rgba: 'rgba(220, 234, 246, 1)', css: 'var(--canvas-canvaslightsky)' },
        canvas_lightmode: { hex: '#ebedee', rgba: 'rgba(235, 237, 238, 1)', css: 'var(--canvas-canvaslightmode)' },
    },
    element: {
        element_blue_1000: { hex: '#47b9ff', rgba: 'rgba(71, 185, 255, 1)', css: 'var(--element-elementblue-1000)' },
        element_blue_900: { hex: '#6cc7ff', rgba: 'rgba(108, 199, 255, 1)', css: 'var(--element-elementblue-900)' },
        element_brown_1000: { hex: '#e78358', rgba: 'rgba(231, 131, 88, 1)', css: 'var(--element-elementbrown-1000)' },
        element_brown_900: { hex: '#ed936e', rgba: 'rgba(237, 147, 110, 1)', css: 'var(--element-elementbrown-900)' },
        element_green_1000: { hex: '#7cd651', rgba: 'rgba(124, 214, 81, 1)', css: 'var(--element-elementgreen-1000)' },
        element_green_900: { hex: '#92e36b', rgba: 'rgba(146, 227, 107, 1)', css: 'var(--element-elementgreen-900)' },
        element_light_grey_1000: {
            hex: '#d1d3d6',
            rgba: 'rgba(209, 211, 214, 1)',
            css: 'var(--element-elementlightgrey-1000)',
        },
        element_light_grey_900: {
            hex: '#dcdcdc',
            rgba: 'rgba(220, 220, 220, 1)',
            css: 'var(--element-elementlightgrey-900)',
        },
        element_medium_grey_1000: {
            hex: '#5f6672',
            rgba: 'rgba(95, 102, 114, 1)',
            css: 'var(--element-elementmediumgrey-1000)',
        },
        element_medium_grey_900: {
            hex: '#767c86',
            rgba: 'rgba(118, 124, 134, 1)',
            css: 'var(--element-elementmediumgrey-900)',
        },
        element_orange_1000: {
            hex: '#ff9329',
            rgba: 'rgba(255, 147, 41, 1)',
            css: 'var(--element-elementorange-1000)',
        },
        element_orange_900: { hex: '#ffa954', rgba: 'rgba(255, 169, 84, 1)', css: 'var(--element-elementorange-900)' },
        element_pink_1000: { hex: '#ff6dd4', rgba: 'rgba(255, 109, 212, 1)', css: 'var(--element-elementpink-1000)' },
        element_pink_900: { hex: '#ff8add', rgba: 'rgba(255, 138, 221, 1)', css: 'var(--element-elementpink-900)' },
        element_purple_1000: {
            hex: '#af72ff',
            rgba: 'rgba(175, 114, 255, 1)',
            css: 'var(--element-elementpurple-1000)',
        },
        element_purple_900: { hex: '#bf8eff', rgba: 'rgba(191, 142, 255, 1)', css: 'var(--element-elementpurple-900)' },
        element_red_1000: { hex: '#ff4d4d', rgba: 'rgba(255, 77, 77, 1)', css: 'var(--element-elementred-1000)' },
        element_red_900: { hex: '#ff7171', rgba: 'rgba(255, 113, 113, 1)', css: 'var(--element-elementred-900)' },
        element_royal_blue_1000: {
            hex: '#5882f8',
            rgba: 'rgba(88, 130, 248, 1)',
            css: 'var(--element-elementroyalblue-1000)',
        },
        element_royal_blue_900: {
            hex: '#799bf9',
            rgba: 'rgba(121, 155, 249, 1)',
            css: 'var(--element-elementroyalblue-900)',
        },
        element_teal_1000: { hex: '#62dbc8', rgba: 'rgba(98, 219, 200, 1)', css: 'var(--element-elementteal-1000)' },
        element_teal_900: { hex: '#7be6d5', rgba: 'rgba(123, 230, 213, 1)', css: 'var(--element-elementteal-900)' },
        element_yellow_1000: {
            hex: '#ffc92d',
            rgba: 'rgba(255, 201, 45, 1)',
            css: 'var(--element-elementyellow-1000)',
        },
        element_yellow_900: { hex: '#ffd96a', rgba: 'rgba(255, 217, 106, 1)', css: 'var(--element-elementyellow-900)' },
    },
    highlight: {
        light_blue_050: { hex: '#ebf9ff', rgba: 'rgba(235, 249, 255, 1)', css: 'var(--highlight-lightblue-050)' },
        light_blue_1500: { hex: '#1e7d9f', rgba: 'rgba(30, 125, 159, 1)', css: 'var(--highlight-lightblue-1500)' },
        light_blue_200: { hex: '#cdf1ff', rgba: 'rgba(205, 241, 255, 1)', css: 'var(--highlight-lightblue-200)' },
        light_blue_900: { hex: '#8ae0ff', rgba: 'rgba(138, 224, 255, 1)', css: 'var(--highlight-lightblue-900)' },
        light_lime_050: { hex: '#f8ffe5', rgba: 'rgba(248, 255, 229, 1)', css: 'var(--highlight-lightlime-050)' },
        light_lime_1500: { hex: '#55740e', rgba: 'rgba(85, 116, 14, 1)', css: 'var(--highlight-lightlime-1500)' },
        light_lime_200: { hex: '#daf88d', rgba: 'rgba(218, 248, 141, 1)', css: 'var(--highlight-lightlime-200)' },
        light_lime_900: { hex: '#c9f201', rgba: 'rgba(201, 242, 1, 1)', css: 'var(--highlight-lightlime-900)' },
        light_orange_050: { hex: '#fff8e0', rgba: 'rgba(255, 248, 224, 1)', css: 'var(--highlight-lightorange-050)' },
        light_orange_1000: { hex: '#ffce26', rgba: 'rgba(255, 206, 38, 1)', css: 'var(--highlight-lightorange-1000)' },
        light_orange_1500: { hex: '#984d00', rgba: 'rgba(152, 77, 0, 1)', css: 'var(--highlight-lightorange-1500)' },
        light_orange_200: { hex: '#ffefb9', rgba: 'rgba(255, 239, 185, 1)', css: 'var(--highlight-lightorange-200)' },
        light_pink_050: { hex: '#fff7fc', rgba: 'rgba(255, 247, 252, 1)', css: 'var(--highlight-lightpink-050)' },
        light_pink_1500: { hex: '#9b3e74', rgba: 'rgba(155, 62, 116, 1)', css: 'var(--highlight-lightpink-1500)' },
        light_pink_200: { hex: '#ffdef3', rgba: 'rgba(255, 222, 243, 1)', css: 'var(--highlight-lightpink-200)' },
        light_pink_900: { hex: '#ffa4de', rgba: 'rgba(255, 164, 222, 1)', css: 'var(--highlight-lightpink-900)' },
        light_yellow_050: { hex: '#fffce6', rgba: 'rgba(255, 252, 230, 1)', css: 'var(--highlight-lightyellow-050)' },
        light_yellow_1500: { hex: '#80792b', rgba: 'rgba(128, 121, 43, 1)', css: 'var(--highlight-lightyellow-1500)' },
        light_yellow_200: { hex: '#fffac0', rgba: 'rgba(255, 250, 192, 1)', css: 'var(--highlight-lightyellow-200)' },
        light_yellow_900: { hex: '#ffed00', rgba: 'rgba(255, 237, 0, 1)', css: 'var(--highlight-lightyellow-900)' },
    },
    neutral: {
        darkmode_grey_050: { hex: '#f2f2f2', rgba: 'rgba(242, 242, 242, 1)', css: 'var(--neutral-darkmodegrey-050)' },
        darkmode_grey_100: { hex: '#e6e6e6', rgba: 'rgba(230, 230, 230, 1)', css: 'var(--neutral-darkmodegrey-100)' },
        darkmode_grey_1000: { hex: '#000000', rgba: 'rgba(0, 0, 0, 1)', css: 'var(--neutral-darkmodegrey-1000)' },
        darkmode_grey_200: { hex: '#cccccc', rgba: 'rgba(204, 204, 204, 1)', css: 'var(--neutral-darkmodegrey-200)' },
        darkmode_grey_300: { hex: '#b3b3b3', rgba: 'rgba(179, 179, 179, 1)', css: 'var(--neutral-darkmodegrey-300)' },
        darkmode_grey_400: { hex: '#999999', rgba: 'rgba(153, 153, 153, 1)', css: 'var(--neutral-darkmodegrey-400)' },
        darkmode_grey_500: { hex: '#808080', rgba: 'rgba(128, 128, 128, 1)', css: 'var(--neutral-darkmodegrey-500)' },
        darkmode_grey_600: { hex: '#666666', rgba: 'rgba(102, 102, 102, 1)', css: 'var(--neutral-darkmodegrey-600)' },
        darkmode_grey_700: { hex: '#4d4d4d', rgba: 'rgba(77, 77, 77, 1)', css: 'var(--neutral-darkmodegrey-700)' },
        darkmode_grey_750: { hex: '#3d3d3d', rgba: 'rgba(61, 61, 61, 1)', css: 'var(--neutral-darkmodegrey-750)' },
        darkmode_grey_800: { hex: '#333333', rgba: 'rgba(51, 51, 51, 1)', css: 'var(--neutral-darkmodegrey-800)' },
        darkmode_grey_900: { hex: '#1a1a1a', rgba: 'rgba(26, 26, 26, 1)', css: 'var(--neutral-darkmodegrey-900)' },
        lightmode_grey_050: { hex: '#f3f3f4', rgba: 'rgba(243, 243, 244, 1)', css: 'var(--neutral-lightmodegrey-050)' },
        lightmode_grey_100: { hex: '#e8e9eb', rgba: 'rgba(232, 233, 235, 1)', css: 'var(--neutral-lightmodegrey-100)' },
        lightmode_grey_1000: { hex: '#1b2536', rgba: 'rgba(27, 37, 54, 1)', css: 'var(--neutral-lightmodegrey-1000)' },
        lightmode_grey_200: { hex: '#d1d3d6', rgba: 'rgba(209, 211, 214, 1)', css: 'var(--neutral-lightmodegrey-200)' },
        lightmode_grey_300: { hex: '#bbbec3', rgba: 'rgba(187, 190, 195, 1)', css: 'var(--neutral-lightmodegrey-300)' },
        lightmode_grey_400: { hex: '#a3a7ae', rgba: 'rgba(163, 167, 174, 1)', css: 'var(--neutral-lightmodegrey-400)' },
        lightmode_grey_500: { hex: '#8d929a', rgba: 'rgba(141, 146, 154, 1)', css: 'var(--neutral-lightmodegrey-500)' },
        lightmode_grey_600: { hex: '#767c86', rgba: 'rgba(118, 124, 134, 1)', css: 'var(--neutral-lightmodegrey-600)' },
        lightmode_grey_700: { hex: '#5f6672', rgba: 'rgba(95, 102, 114, 1)', css: 'var(--neutral-lightmodegrey-700)' },
        lightmode_grey_800: { hex: '#48505e', rgba: 'rgba(72, 80, 94, 1)', css: 'var(--neutral-lightmodegrey-800)' },
        lightmode_grey_900: { hex: '#323b4a', rgba: 'rgba(50, 59, 74, 1)', css: 'var(--neutral-lightmodegrey-900)' },
        opacity: {
            darkmode_grey_1000_00: {
                hex: '#00000000',
                rgba: 'rgba(0, 0, 0, 0)',
                css: 'var(--opacity-darkmodegrey-1000-00)',
            },
            darkmode_grey_1000_05: {
                hex: '#0000000d',
                rgba: 'rgba(0, 0, 0, 0.05)',
                css: 'var(--opacity-darkmodegrey-1000-05)',
            },
            darkmode_grey_1000_10: {
                hex: '#0000001a',
                rgba: 'rgba(0, 0, 0, 0.1)',
                css: 'var(--opacity-darkmodegrey-1000-10)',
            },
            darkmode_grey_1000_15: {
                hex: '#00000026',
                rgba: 'rgba(0, 0, 0, 0.15)',
                css: 'var(--opacity-darkmodegrey-1000-15)',
            },
            darkmode_grey_1000_20: {
                hex: '#00000033',
                rgba: 'rgba(0, 0, 0, 0.2)',
                css: 'var(--opacity-darkmodegrey-1000-20)',
            },
            darkmode_grey_1000_30: {
                hex: '#0000004d',
                rgba: 'rgba(0, 0, 0, 0.3)',
                css: 'var(--opacity-darkmodegrey-1000-30)',
            },
            darkmode_grey_1000_60: {
                hex: '#00000099',
                rgba: 'rgba(0, 0, 0, 0.6)',
                css: 'var(--opacity-darkmodegrey-1000-60)',
            },
            darkmode_grey_1000_80: {
                hex: '#000000cc',
                rgba: 'rgba(0, 0, 0, 0.8)',
                css: 'var(--opacity-darkmodegrey-1000-80)',
            },
            lightmode_grey_1000_00: {
                hex: '#1b253600',
                rgba: 'rgba(27, 37, 54, 0)',
                css: 'var(--opacity-lightmodegrey-1000-00)',
            },
            lightmode_grey_1000_05: {
                hex: '#1b25360d',
                rgba: 'rgba(27, 37, 54, 0.05)',
                css: 'var(--opacity-lightmodegrey-1000-05)',
            },
            lightmode_grey_1000_10: {
                hex: '#1b25361a',
                rgba: 'rgba(27, 37, 54, 0.1)',
                css: 'var(--opacity-lightmodegrey-1000-10)',
            },
            lightmode_grey_1000_15: {
                hex: '#1b253626',
                rgba: 'rgba(27, 37, 54, 0.15)',
                css: 'var(--opacity-lightmodegrey-1000-15)',
            },
            lightmode_grey_1000_20: {
                hex: '#1b253633',
                rgba: 'rgba(27, 37, 54, 0.2)',
                css: 'var(--opacity-lightmodegrey-1000-20)',
            },
            lightmode_grey_1000_30: {
                hex: '#1b25364d',
                rgba: 'rgba(27, 37, 54, 0.3)',
                css: 'var(--opacity-lightmodegrey-1000-30)',
            },
            lightmode_grey_1000_60: {
                hex: '#1b253699',
                rgba: 'rgba(27, 37, 54, 0.6)',
                css: 'var(--opacity-lightmodegrey-1000-60)',
            },
            lightmode_grey_1000_80: {
                hex: '#1b2536cc',
                rgba: 'rgba(27, 37, 54, 0.8)',
                css: 'var(--opacity-lightmodegrey-1000-80)',
            },
            white_1000_00: { hex: '#ffffff00', rgba: 'rgba(255, 255, 255, 0)', css: 'var(--opacity-white-1000-00)' },
            white_1000_05: { hex: '#ffffff0d', rgba: 'rgba(255, 255, 255, 0.05)', css: 'var(--opacity-white-1000-05)' },
            white_1000_10: { hex: '#ffffff1a', rgba: 'rgba(255, 255, 255, 0.1)', css: 'var(--opacity-white-1000-10)' },
            white_1000_15: { hex: '#ffffff26', rgba: 'rgba(255, 255, 255, 0.15)', css: 'var(--opacity-white-1000-15)' },
            white_1000_20: { hex: '#ffffff33', rgba: 'rgba(255, 255, 255, 0.2)', css: 'var(--opacity-white-1000-20)' },
            white_1000_30: { hex: '#ffffff4d', rgba: 'rgba(255, 255, 255, 0.3)', css: 'var(--opacity-white-1000-30)' },
            white_1000_60: { hex: '#ffffff99', rgba: 'rgba(255, 255, 255, 0.6)', css: 'var(--opacity-white-1000-60)' },
            white_1000_80: { hex: '#ffffffcc', rgba: 'rgba(255, 255, 255, 0.8)', css: 'var(--opacity-white-1000-80)' },
        },
        white_1000: { hex: '#ffffff', rgba: 'rgba(255, 255, 255, 1)', css: 'var(--neutral-white-1000)' },
    },
    primary: {
        deep_orange_050: { hex: '#fef6f3', rgba: 'rgba(254, 246, 243, 1)', css: 'var(--primary-deeporange-050)' },
        deep_orange_100: { hex: '#fdede8', rgba: 'rgba(253, 237, 232, 1)', css: 'var(--primary-deeporange-100)' },
        deep_orange_1000: { hex: '#f4511c', rgba: 'rgba(244, 81, 28, 1)', css: 'var(--primary-deeporange-1000)' },
        deep_orange_200: { hex: '#fcdcd1', rgba: 'rgba(252, 220, 209, 1)', css: 'var(--primary-deeporange-200)' },
        deep_orange_300: { hex: '#fbcbbb', rgba: 'rgba(251, 203, 187, 1)', css: 'var(--primary-deeporange-300)' },
        deep_orange_400: { hex: '#fab9a4', rgba: 'rgba(250, 185, 164, 1)', css: 'var(--primary-deeporange-400)' },
        deep_orange_500: { hex: '#f9a88d', rgba: 'rgba(249, 168, 141, 1)', css: 'var(--primary-deeporange-500)' },
        deep_orange_600: { hex: '#f89676', rgba: 'rgba(248, 150, 118, 1)', css: 'var(--primary-deeporange-600)' },
        deep_orange_700: { hex: '#f78560', rgba: 'rgba(247, 133, 96, 1)', css: 'var(--primary-deeporange-700)' },
        deep_orange_800: { hex: '#f67349', rgba: 'rgba(246, 115, 73, 1)', css: 'var(--primary-deeporange-800)' },
        deep_orange_900: { hex: '#f56233', rgba: 'rgba(245, 98, 51, 1)', css: 'var(--primary-deeporange-900)' },
        royal_blue_050: { hex: '#f4f7fe', rgba: 'rgba(244, 247, 254, 1)', css: 'var(--primary-royalblue-050)' },
        royal_blue_100: { hex: '#eaeffe', rgba: 'rgba(234, 239, 254, 1)', css: 'var(--primary-royalblue-100)' },
        royal_blue_1000: { hex: '#2f63f7', rgba: 'rgba(47, 99, 247, 1)', css: 'var(--primary-royalblue-1000)' },
        royal_blue_200: { hex: '#d5dffd', rgba: 'rgba(213, 223, 253, 1)', css: 'var(--primary-royalblue-200)' },
        royal_blue_300: { hex: '#c1d0fc', rgba: 'rgba(193, 208, 252, 1)', css: 'var(--primary-royalblue-300)' },
        royal_blue_400: { hex: '#abc0fb', rgba: 'rgba(171, 192, 251, 1)', css: 'var(--primary-royalblue-400)' },
        royal_blue_500: { hex: '#97b1fb', rgba: 'rgba(151, 177, 251, 1)', css: 'var(--primary-royalblue-500)' },
        royal_blue_600: { hex: '#82a1fa', rgba: 'rgba(130, 161, 250, 1)', css: 'var(--primary-royalblue-600)' },
        royal_blue_700: { hex: '#6d92f9', rgba: 'rgba(109, 146, 249, 1)', css: 'var(--primary-royalblue-700)' },
        royal_blue_800: { hex: '#5882f8', rgba: 'rgba(88, 130, 248, 1)', css: 'var(--primary-royalblue-800)' },
        royal_blue_900: { hex: '#4472f7', rgba: 'rgba(68, 114, 247, 1)', css: 'var(--primary-royalblue-900)' },
    },
};

export default colorPalette;
