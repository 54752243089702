// Lib
import { uniq, head } from 'lodash/fp';

// Selectors
import { getAllBoardNotificationPreferences } from './boardNotificationPreferencesSelector';

// Constants
import {
    BOARD_NOTIFICATION_PREFERENCES_LOAD,
    BOARD_NOTIFICATION_PREFERENCES_SET,
} from '../../../common/notifications/notificationConstants';

export const setBoardNotificationPreferences = ({ userId, boardId, preferences }) => ({
    type: BOARD_NOTIFICATION_PREFERENCES_SET,
    userId,
    boardId,
    preferences,
    sync: true,
});

export const unsubscribeFromBoardNotification = ({ userId, boardId }) =>
    setBoardNotificationPreferences({ userId, boardId, preferences: { subscribed: false } });

export const unsubscribeFromMultipleBoards =
    ({ userId, boardIds }) =>
    (dispatch, getState) => {
        const state = getState();

        const boardNotificationPreferences = getAllBoardNotificationPreferences(state);

        // Out of the board IDs to unsubscribe from ensure that any that are specifically subscribed to are unsubscribed
        const specificallySubscribedBoarIds = boardIds.filter((boardId) =>
            boardNotificationPreferences.getIn([boardId, 'subscribed']),
        );

        const boardIdsToUnsubscribeFrom = uniq([...specificallySubscribedBoarIds, head(boardIds)]);

        boardIdsToUnsubscribeFrom.forEach((boardId) => dispatch(unsubscribeFromBoardNotification({ userId, boardId })));
    };

export const subscribeToBoardNotification = ({ userId, boardId }) =>
    setBoardNotificationPreferences({ userId, boardId, preferences: { subscribed: true } });

export const loadBoardNotificationPreferences = ({ boardNotificationPreferences }) => ({
    type: BOARD_NOTIFICATION_PREFERENCES_LOAD,
    boardNotificationPreferences,
});
