// Utils
import { hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';

export default (event, { getProps }) => {
    const { changeIndentation } = getProps();

    event.preventDefault();
    event.stopPropagation();

    const increaseIndentation = !hasShiftKey(event);

    changeIndentation && changeIndentation({ increaseIndentation });
};
