// Lib
// This is required to match the API expected by some plugins, such as the mentions plugin
// (see resolveDecorators within draft-js-plugins-editor)
import decorateComponentWithProps from 'decorate-component-with-props';
import { get } from 'lodash/fp';
import { CompositeDecorator } from 'draft-js';
// FIXME This was used to avoid the issue discussed here:
// https://github.com/draft-js-plugins/draft-js-plugins/issues/995
import MultiDecorator from '../../../../../node_module_clones/draft-js-plugins/editor/src/Editor/MultiDecorator';

// Utils
import collate from './collate';

const collateDecorators = collate(get('decorators'));

export default (plugins, additionalDecorators, componentProps) => {
    const standardDecorators = collateDecorators(plugins);
    let decorators = additionalDecorators ? [...additionalDecorators, ...standardDecorators] : standardDecorators;

    // This is required to match the API expected by some plugins, such as the mentions plugin
    // (see resolveDecorators within draft-js-plugins-editor)
    decorators = decorators.map((decorator) => ({
        ...decorator,
        component: decorateComponentWithProps(decorator.component, componentProps),
    }));

    return new MultiDecorator([new CompositeDecorator(decorators)]);
};
