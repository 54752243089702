/**
 * These utils take in a map of 1-1 IDs.
 * This is different to the graphUtils because they have a map of 1-* IDs.
 */

// Types
import { PhysicalParentGraph } from './graphTypes';

/**
 * Gets the ancestor IDs for the given starting ID.
 * NOTE: This does *not* include the starting ID in the results.
 */
export const getAllAncestorIds = (parentIdMap: PhysicalParentGraph, startingId: string): string[] => {
    const ancestorIds = [];
    const visitedAncestors: Record<string, boolean> = {};
    let ancestorId = parentIdMap[startingId];

    while (ancestorId) {
        ancestorIds.push(ancestorId);
        ancestorId = parentIdMap[ancestorId];

        if (!ancestorId) break;

        if (visitedAncestors[ancestorId]) {
            console.warn(`Infinite loop in treeUtils/getAllAncestorIds for: ${startingId}`);
            break;
        }
        visitedAncestors[ancestorId] = true;
    }

    return ancestorIds;
};

/**
 * Gets the full path (ancestors and the starting ID) for the given starting ID and parent map.
 */
export const getFullPath = (parentIdMap: PhysicalParentGraph, startingId: string): string[] => {
    const ancestorIds = getAllAncestorIds(parentIdMap, startingId);
    ancestorIds.unshift(startingId);
    return ancestorIds;
};
