// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { identity } from 'lodash/fp';

// Utils
import { getColor, getLineLabel, getIsLineLabelEnabled } from '../../../../common/elements/utils/elementPropertyUtils';
import { getCssPropertyForLineLabelOnColor } from '../../utils/elementColorStyleUtils';
import { stopPropagationOnly } from '../../../utils/domUtil';

// Components
import LineLabelContentEditable from './LineLabelContentEditable';
import MiniElementUserTag from '../../../user/userActivity/MiniElementUserTag';

// Styles
import './LineLabel.scss';

const getStyle = ({ controlPoint = {}, elementColor }) => {
    const { x, y } = controlPoint;

    return {
        left: x,
        top: y,
        color: getCssPropertyForLineLabelOnColor(elementColor),
    };
};

const LineLabel = (props) => {
    const {
        element,
        isEditing,
        controlPoint,
        elementEvents,
        connectDragSource,
        connectDragPreview,
        onDoubleClick,
        isRemotelySelected,
        remoteSelectionData,
    } = props;

    React.useEffect(() => {
        connectDragPreview && connectDragPreview(getEmptyImage());
    }, []);

    const lineLabel = getLineLabel(element);
    const showLineLabel = (getIsLineLabelEnabled(element) && !!lineLabel) || isEditing;

    if (!showLineLabel) return null;

    const elementColor = getColor(element);

    const wrapperFn = connectDragSource || identity;

    // NOTE: We need the two div structure here because of a Safari bug.
    //  When the LineLabel was positioned outside the bounding box of the element, Safari wasn't able
    //  to correctly set the width of the content. Now it forces the absolutely positioned container
    //  to be big enough for the label, and that seems to solve the bug
    return wrapperFn(
        <div
            className="LineLabel"
            {...elementEvents}
            // Don't let the LineContainer key down handler fire when the label is being edited
            onKeyDown={stopPropagationOnly}
            onDoubleClick={onDoubleClick}
            style={getStyle({ controlPoint, elementColor })}
        >
            <div className="line-label-content">
                <LineLabelContentEditable {...props}>{lineLabel || 'Label'}</LineLabelContentEditable>
                <MiniElementUserTag isRemotelySelected={isRemotelySelected} remoteSelectionData={remoteSelectionData} />
            </div>
        </div>,
    );
};

LineLabel.propTypes = {
    element: PropTypes.object,
    controlPoint: PropTypes.object,
    lineDomRef: PropTypes.object,
    elementEvents: PropTypes.object,

    isEditing: PropTypes.bool,

    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    onDoubleClick: PropTypes.func,

    isRemotelySelected: PropTypes.bool,
    remoteSelectionData: PropTypes.object,
};

export default LineLabel;
