// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import editorStoreConnect from '../../../../../components/editor/store/components/editorStoreConnect';
import editorGetCurrentBlock from '../../../../../components/editor/customRichUtils/editorState/editorGetCurrentBlock';
import canBlockTypeAlignCenter from '../../../../../components/editor/plugins/styleShortcuts/canBlockTypeAlignCenter';

// Actions
import { editorUpdateBlockMetadata } from '../../../../../components/editor/store/editorActions';

// Selectors
import { getEditorState } from '../../../../../components/editor/store/reducers/editorStoreSelector';

// Components
import RichTextTool from './RichTextTool';

// Constants
import { BLOCK_METADATA } from '../../../../../components/editor/richText/richTextConstants';

import { ElementType } from '../../../../../../common/elements/elementTypes';

const toggleCenterTextThunk = () => (dispatch, getState) => {
    const state = getState();

    const editorState = getEditorState(state);

    const currentBlock = editorGetCurrentBlock(editorState);
    if (!currentBlock) return;

    const data = currentBlock.getData();
    if (!data) return;

    const shouldCenter = !data.get(BLOCK_METADATA.TEXT_ALIGN_CENTER);

    dispatch(
        editorUpdateBlockMetadata({
            [BLOCK_METADATA.TEXT_ALIGN_CENTER]: shouldCenter,
        }),
    );
};

const mapEditorStateToProps = (state) => {
    const editorState = getEditorState(state);
    if (!editorState) return { isActive: false };

    const currentBlock = editorGetCurrentBlock(editorState);
    if (!currentBlock) return { isActive: false };

    const blockType = currentBlock.getType();
    if (!canBlockTypeAlignCenter(blockType)) return { disabled: true };

    const data = currentBlock.getData();
    if (!data) return { isActive: false };

    return {
        isActive: !!data.get(BLOCK_METADATA.TEXT_ALIGN_CENTER),
    };
};

const mapEditorDispatchToProps = (dispatch) => ({
    onPointerDown: (event) => {
        event.preventDefault();
        dispatch(toggleCenterTextThunk());
    },
});

const CenterTextRichTextToolContainer = (props) => {
    const { editorKey } = props;

    if (editorKey === ElementType.COMMENT_THREAD_TYPE) return null;

    return <RichTextTool {...props} />;
};

CenterTextRichTextToolContainer.propTypes = {
    editorKey: PropTypes.string,
    iconName: PropTypes.string,
};

export default editorStoreConnect(mapEditorStateToProps, mapEditorDispatchToProps)(CenterTextRichTextToolContainer);
