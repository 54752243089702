import { isFunction } from 'lodash';

// old-style refs are callbacks, `useRef` is simply an object with a `current` property
type Ref = ((elem?: HTMLElement) => void) | { current?: HTMLElement };

type Options = {
    skipNullRef?: boolean;
};

/**
 * Function to make it easier to attach multiple refs to the same element.
 *
 * @param refs an array of refs to attach
 * @param options.allowNullRef if true, only update the refs when the element is non-null
 * @returns a callback that can be passed to a refs prop
 */
export const multiRef = (refs: Array<Ref>, options?: Options) => {
    return (elem?: HTMLElement) => {
        if (!elem && options?.skipNullRef) return;
        refs.filter(Boolean).forEach((r: Ref) => {
            if (isFunction(r)) {
                r(elem);
                return;
            }
            r.current = elem;
        });
    };
};
