// Lib
import { createSelector } from 'reselect';
import { isEmpty, flatMap, intersection } from 'lodash/fp';

// Utils
import {
    getCurrentBoardId,
    getCurrentBoardIdFromState,
    getCurrentVisibleBoardId,
    getCurrentVisibleBoard,
} from '../../reducers/currentBoardId/currentBoardIdSelector';
import { isLocationCanvas } from '../../../common/elements/utils/elementLocationUtils';
import { getAllAncestorIds } from '../../../common/dataStructures/treeUtils';
import { isClone } from '../../../common/elements/utils/elementTypeUtils';
import { getRenderedCloneElement } from '../clone/cloneUtils';

// Selectors
import getGridSize from '../../utils/grid/gridSizeSelector';
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import {
    aliasIdToPhysicalIdMapSelector,
    boardVisibleElementGraphSelector,
    elementGraphSelector,
    parentIdMapSelector,
} from './elementGraphSelector';
import { getElements } from './elementsSelector';
import {
    getCanvasOriginCoordinates,
    getElementId,
    getElementLocation,
    getElementType,
    getLinkedElementId,
    getPhysicalId,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getDescendantIds } from '../../../common/dataStructures/graphUtils';
import { getMany, prop } from '../../../common/utils/immutableHelper';
import { canvasElementSorter, filterAndSortElementsInSection } from '../../../common/elements/utils/elementSortUtils';
import { getNavigationHistory } from '../../user/navigationHistory/navigationHistorySelector';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';

// For convenience
export { getCurrentBoardId, getCurrentBoard } from '../../reducers/currentBoardId/currentBoardIdSelector';

const EMPTY_ARRAY = [];

/** Functions to retrieve children for the current board from graph selectors. * */
const createGraphChildIdSelector = (graphSelector) =>
    createShallowSelector(
        graphSelector,
        getCurrentVisibleBoardId,
        (visibleElementGraph, visibleBoardId) => visibleElementGraph[visibleBoardId] || EMPTY_ARRAY,
    );

/**
 * Visible descendants of the current board include all children of columns, task lists, tasks etc that
 * a user can see while viewing a board.
 */
export const getCurrentBoardVisibleDescendantIds = createGraphChildIdSelector(boardVisibleElementGraphSelector);
export const getCurrentBoardVisibleDescendants = createShallowSelector(
    getCurrentBoardVisibleDescendantIds,
    getElements,
    getMany,
);

// This version swaps the clones on the current board with their original elements
export const getCurrentBoardVisibleDescendantOriginalElements = createSelector(
    getCurrentBoardVisibleDescendants,
    getElements,
    (visibleDescendants, elementsMap) =>
        visibleDescendants.map((descendant) => {
            if (!isClone(descendant)) return descendant;

            const originalElementId = getLinkedElementId(descendant);
            const originalElement = getElement(elementsMap, originalElementId);

            return getRenderedCloneElement(descendant, originalElement);
        }),
);

export const getCurrentBoardVisiblePhysicalDescendantIds = createShallowSelector(
    getCurrentBoardIdFromState,
    boardVisibleElementGraphSelector,
    aliasIdToPhysicalIdMapSelector,
    (currentBoardId, visibleDescendantIdsMap, aliasIdToPhysicalIdMap) =>
        visibleDescendantIdsMap[currentBoardId]
            ? visibleDescendantIdsMap[currentBoardId].map(
                  (descendantId) => aliasIdToPhysicalIdMap[descendantId] || descendantId,
              )
            : [],
);

/**
 * The immediate children of the current board.
 */
export const getCurrentBoardChildIds = createGraphChildIdSelector(elementGraphSelector);
export const getCurrentBoardChildren = createShallowSelector(
    getCurrentBoardChildIds,
    getCurrentBoardVisibleDescendants,
    getMany,
);

/**
 * This is always used at the same time that the elements need to be sorted, so we may as well do it
 * in one single selector.
 */
export const currentBoardCanvasElementsSelector = createShallowSelector(getCurrentBoardChildren, (children) =>
    children.valueSeq().filter(isLocationCanvas).sort(canvasElementSorter).toList(),
);

export const currentBoardCanvasElementIdsSelector = createShallowSelector(
    currentBoardCanvasElementsSelector,
    (canvasElements) =>
        canvasElements.reduce((acc, el) => {
            acc.push(getElementId(el));
            return acc;
        }, []),
);

// Returns an Immutable value seq
export const currentBoardInboxElementsSelector = createShallowSelector(getCurrentBoardChildren, (children) =>
    filterAndSortElementsInSection({ elements: children, section: BoardSections.INBOX }),
);

// This seems to be so quick that doing any further selecting of the children is unnecessary
// E.g. Getting a subset of the child location properties
// However if many selectors were to rely on this data it could become useful
export const currentBoardInboxElementIdsSelector = createShallowSelector(
    currentBoardInboxElementsSelector,
    (inboxElements) =>
        inboxElements.reduce((acc, el) => {
            acc.push(getElementId(el));
            return acc;
        }, []),
);

/**
 * Gets a subset map of only the visible descendant's locations.
 * This is useful so that components can react to only changes in location.
 */
export const getCurrentBoardVisibleDescendantLocations = createShallowSelector(
    getCurrentBoardVisibleDescendants,
    (currentBoardVisibleDescendants) => currentBoardVisibleDescendants.map(getElementLocation),
);

/**
 * This uses a different method of getting the descendant IDs.
 * First it gets the inbox element IDs.
 * Secondly then uses the visible descendants map to flatMap over those IDs.
 */
export const getCurrentBoardVisibleInboxDescendantIds = createShallowSelector(
    currentBoardInboxElementIdsSelector,
    elementGraphSelector,
    getCurrentBoardVisibleDescendantIds,
    (inboxElementIds, elementGraph, currentBoardVisibleDescendantIds) => {
        // TODO Could improve this by using an optimised graph recursion
        let allInboxDescendantIds = flatMap((elementId) => getDescendantIds(elementGraph, elementId), inboxElementIds);
        allInboxDescendantIds = allInboxDescendantIds.concat(inboxElementIds);
        return intersection(currentBoardVisibleDescendantIds, allInboxDescendantIds);
    },
);

export const getCurrentBoardVisibleInboxDescendants = createShallowSelector(
    getCurrentBoardVisibleInboxDescendantIds,
    getCurrentBoardVisibleDescendants,
    getMany,
);

export const getCurrentBoardAncestorIdsSelector = createShallowSelector(
    getCurrentBoardIdFromState,
    parentIdMapSelector,
    (currentBoardId, parentIdMap) => getAllAncestorIds(parentIdMap, currentBoardId),
);

export const getCurrentBoardAncestorAndSelfIdsSelector = createShallowSelector(
    getCurrentBoardIdFromState,
    parentIdMapSelector,
    (currentBoardId, parentIdMap) => {
        const ancestorIds = getAllAncestorIds(parentIdMap, currentBoardId);
        ancestorIds.unshift(currentBoardId);
        return ancestorIds;
    },
);

export const getCurrentBoardAncestorsAndSelfSelector = createShallowSelector(
    getCurrentBoardAncestorAndSelfIdsSelector,
    getElements,
    getMany,
);

/**
 * Higher order function that creates a selector that will find the highest ancestor (closest to the root)
 * that matches the predicateFn passed in.
 */
export const createFindFirstMatchingAncestorFromRootSelector = (predicateFn) =>
    createSelector(
        getCurrentBoardAncestorAndSelfIdsSelector,
        getCurrentBoardAncestorsAndSelfSelector,
        (branchElementIds, branchElements) => {
            if (isEmpty(branchElementIds)) return null;

            const size = branchElementIds.length;

            // Go backwards through the array to get the highest ancestor with the property
            for (let i = size - 1; i >= 0; i--) {
                const elementId = branchElementIds[i];
                const element = getElement(branchElements, elementId);

                if (predicateFn(element, branchElements)) return element;
            }

            return null;
        },
    );

/**
 * Higher order function that creates a selector that will find the lowest ancestor (closest to the starting element)
 * that matches the predicateFn passed in.
 */
export const createFindFirstMatchingAncestorFromElementSelector = (predicateFn) =>
    createSelector(
        getCurrentBoardAncestorAndSelfIdsSelector,
        getCurrentBoardAncestorsAndSelfSelector,
        (branchElementIds, branchElements) => {
            if (isEmpty(branchElementIds)) return null;

            const size = branchElementIds.length;

            // Go backwards through the array to get the highest ancestor with the property
            for (let i = 0; i <= size - 1; i++) {
                const elementId = branchElementIds[i];
                const element = getElement(branchElements, elementId);

                if (predicateFn(element, branchElements)) return element;
            }

            return null;
        },
    );

/**
 * Functions to retrieve child properties for descendant IDs.
 * These are useful when used along with createShallowSelector to prevent unnecessary executions of the selector
 * function.
 * */
const createVisibleDescendantPropertySelector = (propertyAccessorFn) =>
    createShallowSelector(
        getCurrentBoardVisibleDescendantIds,
        getCurrentBoardVisibleDescendants,
        (elementIds, elements) => elementIds.map((elementId) => propertyAccessorFn(getElement(elements, elementId))),
    );

export const visibleDescendantPhysicalElementIdsSelector = createVisibleDescendantPropertySelector(getPhysicalId);
export const visibleDescendantElementTypesSelector = createVisibleDescendantPropertySelector(getElementType);

export const getCurrentBoardViewedTime = createShallowSelector(
    getCurrentBoardId,
    getNavigationHistory,
    (currentBoardId, viewedBoards) => prop(currentBoardId, viewedBoards),
);

export const getCurrentVisibleBoardCanvasOrigin = createSelector(getCurrentVisibleBoard, getCanvasOriginCoordinates);
export const getCurrentBoardCanvasOriginPx = createSelector(
    getCurrentVisibleBoardCanvasOrigin,
    getGridSize,
    (canvasOrigin, grisSize) => ({
        x: prop('x', canvasOrigin) * grisSize,
        y: prop('y', canvasOrigin) * grisSize,
    }),
);

const getCanvasOrder = prop('canvasOrder');
export const getCurrentBoardCanvasOrder = createShallowSelector(
    getCurrentBoardId,
    getCanvasOrder,
    (currentBoardId, canvasOrder) => prop(currentBoardId, canvasOrder),
);
