// Lib
import { clamp } from 'lodash';

// Utils
import * as rectLib from '../../../../common/maths/geometry/rect';
import * as pointLib from '../../../../common/maths/geometry/point';
import { getDomElementId } from '../../../element/utils/elementUtil';
import { getScrollAsPoint } from '../../../utils/dom/scrollableElementUtils';
import platformSingleton from '../../../platform/platformSingleton';
import {
    isAnnotation,
    isCard,
    isCommentThread,
    isLine,
    isTask,
} from '../../../../common/elements/utils/elementTypeUtils';
import { isIconViewLike } from '../../../../common/elements/utils/elementDisplayUtils';
import { getElementType, getIsCollapsed } from '../../../../common/elements/utils/elementPropertyUtils';
import { isLocationAttached } from '../../../../common/elements/utils/elementLocationUtils';

// Constants

// Types
import { Point } from '../../../../common/maths/geometry/pointTypes';
import { RectSize, Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { MNElement } from '../../../../common/elements/elementModelTypes';
import { LegacyHybridUseCase } from '../../../platform/platformTypes';
import { ElementType } from '../../../../common/elements/elementTypes';

/**
 * Retrieves the bounding rect for the presentation mode focus layer.
 */
export const getPresentationModeFocusLayerBoundingRect = (): Rect | null => {
    const focusLayerDomEl = document.querySelector('.PresentationModeFocusLayer');

    if (!focusLayerDomEl) return null;

    return focusLayerDomEl.getBoundingClientRect();
};

interface TransformationProps {
    position: Point;
    scale: number;
    size: RectSize;
}

/**
 * Determines if an element can be shown in focus mode.
 */
export const canFocusElement = (element: MNElement) => {
    // When converting from a document to a card, the displayMode property is maintained, so we need
    // to ensure that the element is not a card as well
    if (isIconViewLike(element) && !isCard(element)) return false;
    if (isLine(element)) return false;
    if (isTask(element)) return false;
    if (isAnnotation(element)) return false;
    if (isCommentThread(element) && (getIsCollapsed(element) || isLocationAttached(element))) return false;

    return true;
};

/**
 * Determines the starting position of the element.
 */
export const getOriginalElementTransformation = (
    elementId: string,
    initialZoomScale: number,
): TransformationProps | undefined => {
    const originalDomEl = document.getElementById(getDomElementId(elementId));
    const focusLayerDomEl = document.querySelector('.PresentationModeFocusLayer');
    const focusLayerRect = getPresentationModeFocusLayerBoundingRect();

    if (!originalDomEl || !focusLayerDomEl || !focusLayerRect) return;

    // Then start the element at the current position
    const originalElementRect = originalDomEl.getBoundingClientRect();
    const focusLayerScroll = getScrollAsPoint(focusLayerDomEl);

    return {
        position: pointLib.translate(
            // When returning to the original element's position we need to account for the layer's scroll
            focusLayerScroll,
            pointLib.reverseTranslate(focusLayerRect, originalElementRect),
        ),
        scale: initialZoomScale,
        size: originalElementRect,
    };
};

const FOCUS_ELEMENT_MARGIN_DEFAULT = 50;
const FOCUS_ELEMENT_MARGIN_IOS_CANVAS = 15;
const MIN_FINAL_SCALE_DEFAULT = 1.5;
const MIN_FINAL_SCALE_IOS_CANVAS = 1;
const MAX_FINAL_SCALE_TEXT = 2;
const MAX_FINAL_SCALE_IMAGE = 10;

const getFocusElementMargin = () => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_CANVAS:
            return FOCUS_ELEMENT_MARGIN_IOS_CANVAS;
        default:
            return FOCUS_ELEMENT_MARGIN_DEFAULT;
    }
};

const getMinFinalScale = () => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_CANVAS:
            return MIN_FINAL_SCALE_IOS_CANVAS;
        default:
            return MIN_FINAL_SCALE_DEFAULT;
    }
};

/**
 * Determines the maximum scale to use based on the element type.
 */
const getMaxFinalScale = (element: MNElement) => {
    const elementType = getElementType(element);

    switch (elementType) {
        case ElementType.CARD_TYPE:
        case ElementType.CLONE_TYPE:
        case ElementType.COLUMN_TYPE:
        case ElementType.TASK_LIST_TYPE:
        case ElementType.COLOR_SWATCH_TYPE:
        case ElementType.COMMENT_THREAD_TYPE:
            return MAX_FINAL_SCALE_TEXT;
        default:
            return MAX_FINAL_SCALE_IMAGE;
    }
};

const getScaleForRect = (
    contentRect: RectSize,
    focusLayerRect: RectSize,
    initialZoomScale: number,
    element: MNElement,
) => {
    const widthRatio = (focusLayerRect.width / contentRect.width) * initialZoomScale;
    const heightRatio = (focusLayerRect.height / contentRect.height) * initialZoomScale;

    const minRatio =
        platformSingleton.legacyHybridUseCase === LegacyHybridUseCase.IOS_CANVAS
            ? widthRatio
            : Math.min(widthRatio, heightRatio);

    const minScale = getMinFinalScale();
    const maxScale = getMaxFinalScale(element);

    return clamp(minRatio, minScale, maxScale);
};

/**
 * Determines the scale to render the focused element at after the animation is complete.
 */
const getFocusedElementScale = (
    originalElementRect: DOMRect,
    focusLayerRect: RectSize,
    initialZoomScale: number,
    element: MNElement,
): number => {
    // Determine what the ratio would be without any padding
    const approximateRatio = getScaleForRect(originalElementRect, focusLayerRect, initialZoomScale, element);

    // Then choose a padding that will make it close to 50px at the target ratio
    const padding = (getFocusElementMargin() / approximateRatio) * initialZoomScale;

    // @ts-ignore - TypeScript struggles with curried functions
    const paddedElementRect = rectLib.addMargins(
        {
            top: padding,
            right: padding,
            bottom: padding,
            left: padding,
        },
        originalElementRect,
    );

    return getScaleForRect(paddedElementRect, focusLayerRect, initialZoomScale, element);
};

/**
 * Determines the top left position of the element so that it's centred on the screen.
 */
const getFocusedElementPosition = (finalElementRect: RectSize, focusLayerRect: Rect, finalScale: number): Point => {
    const margin = getFocusElementMargin();
    return {
        x: Math.max((focusLayerRect.width - finalElementRect.width) / 2, margin),
        y: Math.max((focusLayerRect.height - finalElementRect.height) / 2, margin),
    };
};

/**
 * Determines the final position of the element at the end of the animation.
 */
export const getFocusedElementTransformation = (
    elementId: string,
    initialZoomScale: number,
    element: MNElement,
): TransformationProps | undefined => {
    const originalDomEl = document.getElementById(getDomElementId(elementId));
    const focusLayerDomEl = document.querySelector('.PresentationModeFocusLayer');
    const focusLayerRect = getPresentationModeFocusLayerBoundingRect();

    if (!originalDomEl || !focusLayerDomEl || !focusLayerRect) return;

    const originalElementRect = originalDomEl.getBoundingClientRect();

    const finalScale = getFocusedElementScale(originalElementRect, focusLayerRect, initialZoomScale, element);

    const finalElementRect: RectSize = rectLib.scale(
        finalScale / initialZoomScale,
        rectLib.asRect(originalElementRect),
    );

    const finalPosition = getFocusedElementPosition(finalElementRect, focusLayerRect, finalScale);

    return {
        size: finalElementRect,
        position: finalPosition,
        scale: finalScale,
    };
};
