// Services
import { fetchAllUserActivity } from '../../user/userService';
import { refreshIfModified } from '../../element/board/boardService';
import { checkAppServerConnection } from '../../offline/appServerConnectionUtil';

// Selectors
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getSocketInterruptionTime, socketConnectedSelector } from '../../utils/socket/socketConnectionSelector';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';
import {
    APP_DISABLE_SOCKET_STATUS_WARNINGS,
    APP_ENABLE_SOCKET_STATUS_WARNINGS,
    APP_END_CURRENT_BOARD_REFRESH,
    APP_START_CURRENT_BOARD_REFRESH,
} from './appServerReconnectionStatusConstants';
import { appDisableSocketStatusWarningsSelector } from './appServerReconnectionSelector';

export const appStartCurrentBoardRefresh = () => ({ type: APP_START_CURRENT_BOARD_REFRESH });
export const appEndCurrentBoardRefresh = () => ({ type: APP_END_CURRENT_BOARD_REFRESH });

export const appDisableSocketStatusWarnings = () => ({ type: APP_DISABLE_SOCKET_STATUS_WARNINGS });
export const appEnableSocketStatusWarnings = () => ({ type: APP_ENABLE_SOCKET_STATUS_WARNINGS });

// Block socket connection warnings upon returning to the app
const SOCKET_CONNECTION_WARNING_TIMEOUT = 10 * TIMES.SECOND;

let socketWarningTimeoutId: NodeJS.Timeout | string | number;
let orchestratingReconnection = false;

/**
 * If there's an existing socket warning timeout ID - cancel it.
 */
export const clearSocketWarningTimeout = (): void => {
    socketWarningTimeoutId && clearTimeout(socketWarningTimeoutId);
};

/**
 * We need to make sure that the socket status warnings are enabled so the loading spinner
 * will show if we disconnect or remain disconnected in the future
 */
const startSocketWarningEnabledTimer = () => (dispatch: Function, getState: Function) => {
    const state = getState();

    const isSocketWarningDisabled = appDisableSocketStatusWarningsSelector(state);

    // Socket warning are not disabled so we don't need to do anything here
    if (!isSocketWarningDisabled) return;

    socketWarningTimeoutId = setTimeout(() => {
        dispatch(appEnableSocketStatusWarnings());
    }, SOCKET_CONNECTION_WARNING_TIMEOUT);
};

/**
 * When returning to the application, refresh the current board if it's been modified since we last visited.
 */
export const orchestrateReconnect =
    () =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        // We don't want to orchestrate reconnection twice because it can result in strange behaviour with the
        // reconnecting spinner and unnecessary board refreshes
        if (orchestratingReconnection) return;

        orchestratingReconnection = true;

        const canConnectToAppServer = await checkAppServerConnection();

        if (!canConnectToAppServer) {
            dispatch(appEnableSocketStatusWarnings());
            orchestratingReconnection = false;
            return;
        }

        const state = getState();

        // If we are already connected, then we don't need to orchestrate reconnection, but we need
        // to make sure the socket warning will be enabled
        if (socketConnectedSelector(state)) {
            orchestratingReconnection = false;
            dispatch(startSocketWarningEnabledTimer());
            return;
        }

        clearSocketWarningTimeout();

        dispatch(startSocketWarningEnabledTimer());

        const socketInterruptionTime = getSocketInterruptionTime(state);
        const currentBoardId = getCurrentBoardIdFromState(state);

        const beforeRefresh = () => dispatch(appStartCurrentBoardRefresh());
        const afterRefresh = () => dispatch(appEndCurrentBoardRefresh());

        dispatch(fetchAllUserActivity());

        return dispatch(
            refreshIfModified({
                beforeRefresh,
                afterRefresh,
                boardId: currentBoardId,
                loadAncestors: true,
                // Only show the progress spinner if it was updated since the socket disconnection time
                afterTimestamp: socketInterruptionTime,
            }),
        ).finally(() => {
            orchestratingReconnection = false;
        });
    };

/**
 * Before disabling socket warnings, ensure we can connect to the app server.
 * Otherwise we might be disabling an existing "reconnecting" spinner while the user is offline.
 */
export const checkConnectionToDisableSocketWarnings =
    () =>
    async (dispatch: Function): Promise<void> => {
        const canConnectToAppServer = await checkAppServerConnection();

        // If we're currently online then assume that we'll also be online when we return
        if (canConnectToAppServer) {
            dispatch(appDisableSocketStatusWarnings());
        }
    };
