// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { identity, constant } from 'lodash/fp';

// Components
import ColumnPresentationalWrapper from './ColumnPresentationalWrapper';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import columnSaveSizeResizeDecorator from './columnSaveSizeResizeDecorator';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

@elementLineEdgeDropTarget
@elementResizeDecorator({ getMinWidth: constant(ELEMENT_DEFAULT_WIDTH) })
@columnSaveSizeResizeDecorator
class ColumnContainer extends React.Component {
    render() {
        const { connectLineEdgeDropTarget, inList } = this.props;

        const lineEdgeConnector = connectLineEdgeDropTarget && !inList ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div>
                <ColumnPresentationalWrapper {...this.props} />
            </div>,
        );
    }
}

ColumnContainer.propTypes = {
    element: PropTypes.object.isRequired,
    children: PropTypes.array,
    currentBoardId: PropTypes.string,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    isHovered: PropTypes.bool,
    isPresentational: PropTypes.bool,
    inList: PropTypes.string,
};

export default ColumnContainer;
