import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAlignCenterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-align-center"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M11.75 16.75h8.5a.75.75 0 1 0 0-1.5h-8.5a.75.75 0 1 0 0 1.5zm-2.75-6h14a.75.75 0 1 0 0-1.5H9a.75.75 0 0 0 0 1.5zm1 12h12a.75.75 0 1 0 0-1.5H10a.75.75 0 1 0 0 1.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAlignCenterIcon);
export default Memo;
