// Lib
import * as Immutable from 'immutable';

// Utils
import { getCurrentTransactionId } from './undoRedoTransactionManager';

// Reducers
import transactionsReducer, { clearTransactions } from './undoRedoTransactionsReducer';

// Constants
import { LOGOUT } from '../../auth/authConstants';
import {
    UNDO_STACK_LIMIT,
    UNDO_STACK_SLICE_SIZE,
    START_OPERATION,
    END_OPERATION,
    POP_UNDO_STACK_TO,
    UNDO_REDO_REMOVE_TRANSACTION,
    UNDO_REDO_FINISH,
} from './undoRedoConstants';

const initialState = Immutable.Map({
    operation: null,
    mostRecentTransactionId: getCurrentTransactionId(),
    transactions: Immutable.Map(),
    undoStack: Immutable.Stack(),
    redoStack: Immutable.Stack(),
});

// General Stack
const getNextFromStack = (stackName) => (state) => state.get(stackName).peek();
const removeNextFromStack = (stackName) => (state) => state.update(stackName, (stack) => stack.shift());

// UNDO STACK
const addToStackWithLimit = (value) => (stack) =>
    stack.size < UNDO_STACK_LIMIT ? stack.unshift(value) : stack.slice(0, UNDO_STACK_SLICE_SIZE).unshift(value);

const addToUndoStack = (state, undoActionId) => state.update('undoStack', addToStackWithLimit(undoActionId));
const getNextUndo = getNextFromStack('undoStack');
const removeNextUndo = removeNextFromStack('undoStack');

// REDO STACK
// No limit on the redo stack
const addToRedoStack = (state, redoActionId) =>
    state.update('redoStack', (redoStack) => redoStack.unshift(redoActionId));

const clearRedoStack = (state, action) => state.update('redoStack', (redoStack) => redoStack.clear());
const getNextRedo = getNextFromStack('redoStack');
const removeNextRedo = removeNextFromStack('redoStack');

/**
 * Removes a specific transaction ID from the undo redo stack.
 */
const removeTransaction = (state, transactionId) =>
    state
        .update('transactions', (transactions) => transactions.delete(transactionId))
        .update('undoStack', (undoStack) => undoStack.filter((id) => id !== transactionId))
        .update('redoStack', (redoStack) => redoStack.filter((id) => id !== transactionId));

// STATE REDUCER
export default (state = initialState, action) => {
    if (action.type === LOGOUT) {
        return initialState;
    }

    if (action.type === UNDO_REDO_REMOVE_TRANSACTION) {
        return removeTransaction(state, action.transactionId);
    }

    // This moves part of the undo stack over to the redo stack.
    // It's currently used for the full screen editor so that undoing will not include undos of the element updates
    // (which will have been handled by Draft's internal undo handler)
    if (action.type === POP_UNDO_STACK_TO) {
        const undoStack = state.get('undoStack');
        const redoStack = state.get('redoStack');

        const transactionIdIndex = undoStack.indexOf(action.transactionId);

        if (transactionIdIndex === -1) return initialState;

        const stackToMove = undoStack.slice(0, transactionIdIndex).reverse();
        const newUndoStack = undoStack.slice(transactionIdIndex);

        const newRedoStack = redoStack.unshiftAll(stackToMove);

        return state.set('undoStack', newUndoStack).set('redoStack', newRedoStack);
    }

    let updatedState = state;
    if (action.type === START_OPERATION) {
        updatedState = updatedState.set('operation', action.name);
    }
    if (action.type === END_OPERATION) {
        updatedState = updatedState.set('operation', null);
    }
    if (action.type === UNDO_REDO_FINISH) {
        updatedState = updatedState.set('isRedoing', false).set('isUndoing', false);
    }

    if (action.remote || !action.transactionId) return updatedState;

    const mostRecentTransactionId = updatedState.get('mostRecentTransactionId');
    const isNewTransaction = action.transactionId > mostRecentTransactionId;

    // Add the action to the transactions map if required
    updatedState = updatedState.update('transactions', (transactionsState) =>
        transactionsReducer(transactionsState, action),
    );

    if (isNewTransaction) {
        updatedState = updatedState.set('isRedoing', false).set('isUndoing', false);

        updatedState = updatedState.set('mostRecentTransactionId', action.transactionId);

        // Remove the transactions in the redo stack from the transactions map (as they can never be undone or redone
        // from this point onwards).
        const transactionIds = updatedState.get('redoStack');
        updatedState = updatedState.update('transactions', (transactionsState) =>
            clearTransactions(transactionsState, transactionIds),
        );

        // Add to undo stack
        updatedState = addToUndoStack(updatedState, action.transactionId);

        // clear redo stack
        updatedState = clearRedoStack(updatedState);
    }

    if (action.isUndo) {
        updatedState = updatedState.set('isRedoing', false).set('isUndoing', true);

        // The action to add to the redo stack now that it has been 'undone'
        const redoTransactionId = getNextUndo(updatedState);

        // Only change the stack if the action being undone is the first of the undo stack (subsequent actions will not
        // modify the stacks).
        if (action.transactionId === redoTransactionId) {
            updatedState = removeNextUndo(updatedState);
            updatedState = addToRedoStack(updatedState, redoTransactionId);
        }
    }

    if (action.isRedo) {
        updatedState = updatedState.set('isRedoing', true).set('isUndoing', false);

        // The action to add to the undo stack now that it has been 'redone'
        const undoTransactionId = getNextRedo(updatedState);

        // Only change the stack if the action being redone is the first of the redo stack (subsequent actions will not
        // modify the stacks).
        if (action.transactionId === undoTransactionId) {
            updatedState = removeNextRedo(updatedState);
            updatedState = addToUndoStack(updatedState, undoTransactionId);
        }
    }

    return updatedState;
};
