export const NO_DRAG_OFFSET = { x: 0, y: 0 };

// New elements by default will be grabbed 3 grid units in from the top left
export const DEFAULT_NEW_ELEMENT_DRAG_OFFSET_GRID_UNITS = { x: 3, y: 3 };

export const ATTACH_MODE_START = 'ATTACH_MODE_START';
export const ATTACH_MODE_END = 'ATTACH_MODE_END';

export enum AttachModeType {
    HOT_SPOT = 'HOT_SPOT',
    LINE_EDGE = 'LINE_EDGE',
}
