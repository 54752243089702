// Lib
import React, { ReactElement } from 'react';

// Components
import ElementReactionAddButton from '../../../reactions/ElementReactionAddButton';

// Styles
import './TaskAddReactionButton.scss';

const TaskAddReactionButton = (props: object): ReactElement => (
    <ElementReactionAddButton {...props} className="TaskAddReactionButton" />
);

export default TaskAddReactionButton;
