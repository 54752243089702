// Lib
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import usePrevious from '../../utils/react/usePrevious';

// Selectors
import { activePopupSelector } from './popupOpenSelector';

// Actions
import { closePopupsInSection, togglePopup } from './popupActions';

// Components
import DebouncedButton from '../buttons/DebouncedButton';

export const POPUP_TRIGGER_CLASS = 'PopupTrigger';

export const getPopupIdTriggerClass = (popupId) => `popup-trigger-${popupId}`;

const mapStateToProps = createSelector(
    activePopupSelector,
    (state, ownProps) => ownProps.popupId,
    (popupState, popupId) => ({
        isPopupOpen: popupState.has(popupId),
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchTogglePopup: ({ popupId, stayActivePredicate }) => dispatch(togglePopup(popupId, stayActivePredicate)),
    dispatchClosePopupsInSection: ({ section, popupId }) =>
        dispatch(
            closePopupsInSection({
                section,
                excludedIds: [popupId],
            }),
        ),
});

const PopupTrigger = (props) => {
    const {
        children,
        popupId,
        className,
        isPopupOpen,
        preventFocus,
        enableDoubleClick,
        closeSection,
        onToggle,
        onClick,
        stayActivePredicate,
        dispatchTogglePopup,
        dispatchClosePopupsInSection,
        disableToggleClose,
        disabled = false,
        forwardedRef,
    } = props;

    const receivedMouseDownRef = useRef();
    const prevIsPopupOpen = usePrevious(isPopupOpen);

    const togglePopupHandler = (event) => {
        // NOTE: This isn't 100% correct (as you could mousedown then mouseup elsewhere, followed by a
        // mousedown elsewhere and mouseup on this) but it's good enough without making the solution
        // overly complicated
        if (!receivedMouseDownRef.current) return;
        receivedMouseDownRef.current = false;

        closeSection && dispatchClosePopupsInSection({ section: closeSection, popupId });

        if (!isPopupOpen || !disableToggleClose) {
            dispatchTogglePopup({ popupId, stayActivePredicate });
        }

        onToggle && onToggle(!isPopupOpen, event);
    };

    const onMouseDown = (event) => {
        receivedMouseDownRef.current = true;

        if (preventFocus) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (!!prevIsPopupOpen !== isPopupOpen) {
            onToggle && onToggle(isPopupOpen);
        }
    }, [isPopupOpen]);

    return (
        <DebouncedButton
            forwardedRef={forwardedRef}
            className={classNames(POPUP_TRIGGER_CLASS, `popup-trigger-${popupId} maintain-enabled-styling`, className)}
            disabled={disabled}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={!enableDoubleClick ? togglePopupHandler : null}
            onDoubleClick={enableDoubleClick ? togglePopupHandler : null}
        >
            {children(isPopupOpen)}
        </DebouncedButton>
    );
};

PopupTrigger.propTypes = {
    popupId: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    stayActivePredicate: PropTypes.func,
    className: PropTypes.string,

    isPopupOpen: PropTypes.bool,
    dispatchTogglePopup: PropTypes.func,

    onToggle: PropTypes.func,
    onClick: PropTypes.func,

    dispatchClosePopupsInSection: PropTypes.func,
    closeSection: PropTypes.string,

    enableDoubleClick: PropTypes.bool,
    preventFocus: PropTypes.bool,
    // Don't close on click, only open
    disableToggleClose: PropTypes.bool,
    disabled: PropTypes.bool,

    forwardedRef: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupTrigger);
