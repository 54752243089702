export const LIVE_ACTIVITY_SUFFIX = '-LIVE';
export const PERSONAL_CHANNEL_SUFFIX = '-PERSONAL';

export const REDIS_CLIENT_TYPE = {
    PUBLISHER: 'publisher',
    SUBSCRIBER: 'subscriber',
};

export const REDIS_CLIENT_EVENT_NAMES = {
    READY: 'ready',
    WARNING: 'warning',
    ERROR: 'error',
    RECONNECTING: 'reconnecting',
    END: 'end',
    CONNECT: 'connect',
};

export const SOCKET_SERVER_EVENT_NAMES = {
    CONNECTION: 'connection',
};

export const SOCKET_EVENT_NAMES = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    ACTION: 'action',
    UPDATE_CHANNELS: 'update-channels',
    CONNECT_ERROR: 'connect_error',
    ERROR: 'error',
    JOIN_ROOM: 'join-room',
    LEAVE_ROOM: 'leave-room',
};
