import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniColorSwatchSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-color-swatch-selected"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M8 6h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1z" />
            <path fill="#1E7D9F" d="M8 6h8a1 1 0 0 1 1 1v7H7V7a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M8 14h8v3H8z" />
            <path fill="#16A0E5" d="M8 7h8v7H8z" />
            <path fill="#CCC" d="M4 2h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1z" />
            <path fill="#D6223E" d="M4 2h8a1 1 0 0 1 1 1v7H3V3a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M4 10h8v3H4z" />
            <path fill="#F02848" d="M4 3h8v7H4z" />
            <path
                fill="#323B4A"
                fillRule="nonzero"
                d="M11 1a3 3 0 0 1 3 3v1h1a3 3 0 0 1 2.995 2.824L18 8v8a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3v-1H5a3 3 0 0 1-2.995-2.824L2 12V4a3 3 0 0 1 3-3h6zm0 2H5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V4a1 1 0 0 0-1-1z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniColorSwatchSelectedIcon);
export default Memo;
