import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationAnnotationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-annotation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFF"
                d="m9.046 1.92-.17.074a2.945 2.945 0 0 0-.82.574L3.588 6.92l-.227.234a8.367 8.367 0 0 0-1.355 1.94L.9 11.287l-.066.146-.062.171c-.035.11-.06.215-.078.325l-.562 1.638-.013.026c-.316.905.077 1.844.895 2.233l.164.068c.444.16.937.137 1.368-.067L3.8 15.18c.271-.023.537-.097.784-.219l2.212-1.096.281-.147a8.426 8.426 0 0 0 1.902-1.414l4.47-4.353.145-.154.137-.17c.173-.231.306-.473.402-.725l.042-.125 1.175-1.398.108-.138c.815-1.133.69-2.649-.279-3.61l-.828-.82-.157-.145A2.804 2.804 0 0 0 10.595.64L9.046 1.92Z"
            />
            <path
                fill="#767C86"
                d="m13.546 1.427.1.093.83.822c.622.617.696 1.585.193 2.285l-.084.109-1.31 1.56a1.914 1.914 0 0 1-.414.82l-.11.119-4.476 4.359a7.397 7.397 0 0 1-1.66 1.237l-.264.138-2.212 1.096a1.174 1.174 0 0 1-.423.118l-1.6.741a.785.785 0 0 1-.674 0 .77.77 0 0 1-.4-.947l.035-.084.596-1.734c.009-.107.033-.215.073-.319l.046-.102 1.106-2.193a7.338 7.338 0 0 1 1.18-1.695l.207-.213L8.76 3.278c.228-.227.5-.385.787-.475l1.684-1.391a1.804 1.804 0 0 1 2.314.015Z"
            />
            <path
                fill="#FFF"
                d="M9.82 4.048c.23-.121.521-.1.735.064l.068.06 1.231 1.222.061.068.06.093c.122.227.102.514-.064.726l-.06.068-4.472 4.354-.206.195-.212.183a6.139 6.139 0 0 1-.927.63l-.252.133-2.146 1.063-.677-.671 1.074-2.128.133-.25.142-.24c.198-.314.425-.612.679-.888l.196-.204 4.47-4.353.073-.065Zm2.411-1.688a.45.45 0 0 1 .464.106l.828.82.05.058.041.07a.439.439 0 0 1-.064.466l-.592.703-.023-.03-.113-.12-1.236-1.226-.098-.091-.107-.087.707-.586.068-.048Z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationAnnotationIcon);
export default Memo;
