// Lib
import loadable from 'react-loadable';

export const LoadableShortcutsModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "shortcuts" */
            './ShortcutsModal'
        ),
    loading: () => null,
});

export const LoadableRouteShortcutsModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "route-shortcuts" */
            './RouteOpenShortcutsModal'
        ),
    loading: () => null,
});

export default LoadableShortcutsModal;
