// Lib
import * as Immutable from 'immutable';

// Utils
import { retrieveTheme, retrieveThemeMode } from './themeService';

// Constants
import { THEME_MODE_SET, THEME_SET, THEMES } from './themeConstants';

const createInitialState = () => {
    // electron app is unable to read from localStorage initially as it runs in the main thread,
    // so defer to the ThemeManager to initialise the value
    if (typeof screen === 'undefined') {
        return Immutable.Map({
            mode: THEMES.LIGHT,
            theme: THEMES.LIGHT,
            initialised: false,
        });
    }

    return Immutable.fromJS({
        mode: retrieveThemeMode(),
        theme: retrieveTheme(),
        initialised: true,
    });
};

const initialState = createInitialState();

export default (state = initialState, action) => {
    switch (action.type) {
        case THEME_MODE_SET:
            return state.set('mode', action.mode).set('theme', action.theme).set('initialised', true);
        case THEME_SET:
            return state.set('theme', action.theme).set('initialised', true);
        default:
            return state;
    }
};
