import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardPublishedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon board-published"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#333" />
            <path
                fill="#FFF"
                d="m13.116 6.84.155.2c.407.52.437 1.247.087 1.796l-.087.124c-2.585 3.452-7.705 3.518-10.387.199l-.155-.2a1.565 1.565 0 0 1-.087-1.796l.087-.123c2.585-3.453 7.705-3.519 10.387-.2zM7.999 6c-1.102 0-1.996.895-1.996 2s.894 2 1.996 2c1.103 0 1.997-.895 1.997-2s-.894-2-1.997-2z"
            />
        </g>
    </svg>
);
const Memo = memo(BoardPublishedIcon);
export default Memo;
