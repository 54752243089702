// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Decorator
import fileDropTargetDecorator from '../../../../../../../../components/tools/files/fileDropTargetDecorator';

// Components
import FileInput from '../../../../../../../../components/tools/files/FileInput';
import BoardImageUploadIcon from './BoardImageUploadIcon';
import BoardImage from '../../../../../../../../element/board/boardIcon/BoardImage';
import StyledButton from '../../../../../../../../components/buttons/StyledButton';

// Styles
import './BoardImagePicker.scss';

@fileDropTargetDecorator
class BoardImagePicker extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    /**
     * Delegates the click event on the fake file upload input to the real, hidden one.
     */
    openFileSelect = (event) => {
        this.fileInputRef.current && this.fileInputRef.current.click();
    };

    render() {
        const { selectedImage, selectedElements, gridSize, uploadFile, connectFileDropTarget } = this.props;

        const firstSelectedElement = selectedElements.first();
        const titleText = selectedImage ? 'Change image' : 'Choose an image';
        const icon = selectedImage ? (
            <div className="board-image-wrapper">
                <BoardImage element={firstSelectedElement} size={8} gridSize={gridSize} />
            </div>
        ) : (
            <BoardImageUploadIcon />
        );

        return connectFileDropTarget(
            <div className="BoardImagePicker" onClick={this.openFileSelect}>
                <div>
                    <FileInput ref={this.fileInputRef} id="board" uploadFile={uploadFile} />
                    {icon}
                </div>
                <StyledButton className="primary upload-title">{titleText}</StyledButton>
            </div>,
        );
    }
}

BoardImagePicker.propTypes = {
    gridSize: PropTypes.number.isRequired,
    selectedElements: PropTypes.object.isRequired,
    selectedImage: PropTypes.string,
    dispatchBoardImage: PropTypes.func,
    uploadFile: PropTypes.func.isRequired,
    fileIsHovered: PropTypes.bool,
    connectFileDropTarget: PropTypes.func,
};

export default BoardImagePicker;
