// Lib
import * as Immutable from 'immutable';

// Constants
import { CANVAS_SET_SCROLL_AREA_SIZE, CANVAS_SET_SIZE, CANVAS_ZOOM_TRANSFORMATION_SET } from './canvasConstants';

const initialState = null;

/**
 * Allows a scroll area size to be persisted.
 * This will ensure that the scroll area is _at least_ the size specified here. The CanvasScrollArea will ensure that
 * it uses the larger of the calculated scroll area size, based on the content, and the persisted scroll area size
 * (this value).
 *
 * NOTE: At this stage the scroll area size is updated when transitioning between boards by the
 * canvasReturnStateDecorator.js
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case CANVAS_SET_SCROLL_AREA_SIZE:
        case CANVAS_SET_SIZE:
        case CANVAS_ZOOM_TRANSFORMATION_SET: {
            if (!action.scrollAreaSize) return state;

            return Immutable.Map({
                width: action.scrollAreaSize.width,
                height: action.scrollAreaSize.height,
            });
        }
        default:
            return state;
    }
};
