// Lib
import { get, isString, isEmpty } from 'lodash/fp';

// Utils
import { http } from '../services/http';
import { manuallyReportError } from '../../analytics/rollbarService';

/**
 * Retrieves a JWT with the associated aclIds for the permission (and any existing permissions that the client
 * has requested).
 */
export const fetchPermissionIdToken = ({ permissionId, elementId }) =>
    http({ url: `permissions/token/${permissionId}`, params: { elementId } })
        .then(get('data'))
        .catch((err) => {
            manuallyReportError({
                errorMessage: 'Unable to retrieve permissions',
                error: err,
                sensitive: false,
            });
            throw err;
        });

/**
 * Retrieves a JWT with the associated aclIds for the permission (and any existing permissions that the client
 * has requested).
 */
export const fetchMultiplePermissionIdTokens = async ({ permissionIds, elementIds }) => {
    if (isEmpty(permissionIds)) return null;

    const permissionIdsString = isString(permissionIds) ? permissionIds : permissionIds.join(',');
    const elementIdsString = isString(elementIds) ? elementIds : elementIds.join(',');

    return http({
        url: 'permissions/token/',
        params: { ids: permissionIdsString, elementIds: elementIdsString },
    })
        .then(get('data'))
        .catch((err) => {
            manuallyReportError({
                errorMessage: 'Unable to retrieve permissions',
                error: err,
                sensitive: false,
            });
            throw err;
        });
};
