// Lib
import { isEqual } from 'lodash/fp';

// Actions
import { updateMultipleElements } from '../../element/actions/elementActions';
import { visibleBoardIdSet } from '../../reducers/currentBoardId/currentBoardIdActions';
import { duplicateDescendantsIntoContainerElements } from '../../element/duplicate/elementDuplicateActions';
import { fetchBoard } from '../../element/board/boardService';
import { onboardingStepPassed } from '../onboarding/onboardingActions';

import {
    getElementId,
    getElementLocation,
    getElementType,
    getBackground,
    getCanvasOriginCoordinates,
} from '../../../common/elements/utils/elementPropertyUtils';

// Utils
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';
import { asObject, isEmpty } from '../../../common/utils/immutableHelper';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';

import http from '../../utils/services/http';

// Constants
import { DEFAULT_CANVAS_ORIGIN } from '../../../common/elements/utils/elementPositionUtils';

export const copyTemplateBoardContents = (copyTemplateBoardId, currentBoard) => async (dispatch, getState) => {
    await dispatch(
        fetchBoard({
            boardId: copyTemplateBoardId,
            loadAncestors: false,
            addLoadedBoardSummaries: false,
        }),
    );

    const templateBoard = getState().getIn(['elements', copyTemplateBoardId]);
    const templateBackground = getBackground(templateBoard) && asObject(getBackground(templateBoard));

    const templateOrigin = asObject(getCanvasOriginCoordinates(templateBoard));

    const currentBoardChanges = {};

    if (templateBackground) {
        currentBoardChanges.background = templateBackground;
    }

    if (!isEqual(templateOrigin, DEFAULT_CANVAS_ORIGIN)) {
        currentBoardChanges.canvasOrigin = templateOrigin;
    }

    if (!isEmpty(currentBoardChanges)) {
        dispatch(
            updateMultipleElements({
                updates: [
                    {
                        id: getElementId(currentBoard),
                        changes: currentBoardChanges,
                        transactionId: -1,
                        silent: true,
                    },
                ],
            }),
        );
    }

    await dispatch(
        duplicateDescendantsIntoContainerElements(
            [
                {
                    originalElementId: copyTemplateBoardId,
                    newElementId: getElementId(currentBoard),
                    newElementLocation: getElementLocation(currentBoard),
                    elementType: getElementType(currentBoard),
                },
            ],
            -1,
            false,
            false,
        ),
    );

    dispatch(visibleBoardIdSet({ boardId: null }));

    dispatch(onboardingStepPassed('template-picker'));
};

export const convertBoardToTemplate = (boardId) => async (dispatch) => {
    sendAmplitudeEvent({
        eventType: 'Converted board into custom template',
        userProperties: {
            [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.TEMPLATE_CUSTOM]: true },
        },
    });

    dispatch(
        updateMultipleElements({
            updates: [
                {
                    id: boardId,
                    changes: {
                        isTemplate: true,
                    },
                },
            ],
        }),
    );

    await http({
        method: 'delete',
        url: 'uses/me/element-cache',
    });
};

export const revertTemplateToBoard = (boardId) => async (dispatch) => {
    dispatch(
        updateMultipleElements({
            updates: [
                {
                    id: boardId,
                    changes: {
                        isTemplate: false,
                        templateLink: null,
                        templateExampleLink: null,
                    },
                },
            ],
        }),
    );

    await http({
        method: 'delete',
        url: 'uses/me/element-cache',
    });
};
