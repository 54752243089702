// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FeatureSuggestion from './FeatureSuggestion';

// Utils
import { propIn } from '../../../common/utils/immutableHelper';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

class ElementFeatureSuggestion extends React.Component {
    onConfirm = (event, transactionId) => {
        const { onConfirm } = this.props;

        this.onAcknowledgement(true, transactionId);
        onConfirm && onConfirm(this.props, transactionId);
    };

    onDismiss = () => {
        this.onAcknowledgement(false);
    };

    onAcknowledgement = (decision, transactionId) => {
        const { element, feature, dispatchUpdateElement, dispatchToggleElementFeature } = this.props;

        const currentPowerUpData = propIn(['content', feature], element);

        dispatchUpdateElement({
            id: getElementId(element),
            changes: {
                [feature]: {
                    ...currentPowerUpData,
                    suggestionAck: true,
                },
            },
            transactionId,
        });

        if (decision === true) dispatchToggleElementFeature(feature, [element]);
    };

    render() {
        const { question, added } = this.props;

        return (
            <FeatureSuggestion
                {...this.props}
                question={question}
                onConfirm={this.onConfirm}
                onDismiss={this.onDismiss}
                added={added}
            />
        );
    }
}

ElementFeatureSuggestion.propTypes = {
    element: PropTypes.object.isRequired,
    feature: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    added: PropTypes.bool,

    onConfirm: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    dispatchToggleElementFeature: PropTypes.func,
};

export default ElementFeatureSuggestion;
