// Lib
import React from 'react';

// Hooks
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Components
import CommentEditor from './CommentDraftJsEditor';

// Types
import { CommentEditorProps } from './CommentEditorTypes';
import { CommentTiptapEditor } from './CommentTiptapEditor';
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';

const CommentEditorSwitch = (props: CommentEditorProps) => {
    const {
        element,
        textContent,
        isUpdating,
        isPreview,
        isEditing,
        isEditable,
        isSingleSelected,
        spellCheck,
        hidePostButton,
        placeholder,
        editorId,
        editorKey,
        currentEditorKey,
        currentEditorId,
        onActivity,
        saveContent,
        onSubmit,
        onEmptyBackspace,
        startEditing,
        filterQuery,
        editorStateRef,
    } = props;

    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);

    if (tiptapContent) {
        return (
            <CommentTiptapEditor
                textContent={textContent}
                tiptapContent={tiptapContent}
                element={element}
                isUpdating={isUpdating}
                isPreview={isPreview}
                isEditing={!!isEditing}
                isEditable={!!isEditable}
                isSingleSelected={isSingleSelected}
                spellCheck={spellCheck}
                hidePostButton={hidePostButton}
                placeholder={placeholder}
                editorId={editorId}
                editorKey={editorKey}
                currentEditorKey={currentEditorKey}
                currentEditorId={currentEditorId}
                onActivity={onActivity}
                saveContent={saveContent}
                onSubmit={onSubmit}
                onEmptyBackspace={onEmptyBackspace}
                startEditing={startEditing}
                filterQuery={filterQuery}
                editorStateRef={editorStateRef}
            />
        );
    }

    return <CommentEditor {...props} />;
};

export default CommentEditorSwitch;
