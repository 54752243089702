// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shallowEqual from 'shallowequal';

// Singletons
import zoomStateSingleton from '../../../canvas/zoom/zoomStateSingleton';

// Measurements
import Measure from '../../../../node_module_clones/react-measure/src/Measure';
import measurementsConnect from '../measurementsConnect';
import measurementsRegistry from '../measurementsRegistry';
import { syncedMeasurements } from './syncedMeasurementsSingleton';
import { measurementsSet, measurementsRemove } from './elementMeasurementsActions';

// Actions
import { checkCollisions } from '../../collision/elementCollisionActions';
import { getElementChildrenIdsThunk } from '../../../element/selectors/elementTraversalSelector';

// Utils
import logger from '../../../logger/logger';
import { isDebugEnabled } from '../../../debug/debugUtil';
import { manuallyReportError } from '../../../analytics/rollbarService';
import { prop } from '../../../../common/utils/immutableHelper';
import { isHeightRestricted } from '../../../element/card/cardSizeUtil';
import { collisionDetectionManager } from '../../collision/collisionDetectionManager';
import { getElementMeasurementFromCanvasDocument } from './utils/measureElementUtils';
import { getElementDomMeasurementsData } from './utils/measureElementDomUtils';
import { isLocationCanvas } from '../../../../common/elements/utils/elementLocationUtils';
import { isColumn, isAnnotation, isLine } from '../../../../common/elements/utils/elementTypeUtils';
import {
    getElementId,
    getElementLocation,
    getElementType,
    getXPosition,
    getYPosition,
} from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { ROLLBAR_LEVELS } from '../../../analytics/rollbarConstants';

const getShouldCheckForCollisions = ({
    isPreview,
    isEditable,
    syncedMeasurements,
    element,
    gridSize,
    previousDevicePixelRatio,
    previousMeasurements,
    previousGridSize,
    previousX,
    previousY,
    currentX,
    currentY,
    heightChange,
}) => {
    if (!collisionDetectionManager.enabled) return false;
    if (isPreview) return false;
    if (!isEditable) return false;
    if (!syncedMeasurements) return false;
    if (isLine(element)) return false;
    if (isAnnotation(element)) return false;

    // If the grid size changes, then don't bother checking collisions
    if (previousGridSize !== gridSize) return false;

    if (!isLocationCanvas(element)) return false;

    // Only trigger collision detection if the current zoom level of the browser is consistent
    if (previousDevicePixelRatio !== window.devicePixelRatio) return false;

    // If there's no previous measurements then this is a new card that's just been created
    if (!previousMeasurements) return true;

    // Don't trigger collision detection if the card is being moved
    if (previousX !== currentX) return false;
    if (previousY !== currentY) return false;

    // If a 3d card transition is still in progress the difference will be < 4px
    if (Math.abs(heightChange) <= 4) return false;

    // Also - A common cause of what seems to be a collision detection stuck in a loop is when a column
    //  changes by 6px. We're currently not sure how that is possible, but it seems to be related to the
    if (isColumn(element) && Math.abs(heightChange) === 6) return false;

    return true;
};

const mapDispatchToProps = (dispatch) => ({
    dispatchGetChildIds: (id) => dispatch(getElementChildrenIdsThunk([id])),
    dispatchCheckCollisions: (...args) => dispatch(checkCollisions(...args)),
});

const mapMeasurementsDispatchToProps = (dispatch) => ({
    dispatchMeasurementsSet: ({ id, measurements }) => dispatch(measurementsSet({ id, measurements })),
    dispatchMeasurementsRemove: ({ id }) => dispatch(measurementsRemove({ id })),
});

export default (DecoratedComponent) => {
    @connect(undefined, mapDispatchToProps)
    @measurementsConnect(undefined, mapMeasurementsDispatchToProps)
    class MeasureElementDecorator extends React.Component {
        constructor(props, context) {
            super(props, context);
            this.hasBeenMeasured = false;

            this.measureComponent = React.createRef();
            this.measureElementRef = React.createRef();

            this.previousMeasurements = null;
            this.previousGridSize = props.gridSize;
            // This will keep track of changes in browser zoom and prevent them
            // from triggering collision detection
            this.previousDevicePixelRatio = window.devicePixelRatio;
            this.preventMeasure = false;
        }

        componentWillMount() {
            const { element } = this.props;
            const id = getElementId(element);
            measurementsRegistry.registerElement(id, this);
        }

        /**
         * NOTE: The ResizeObserver only invokes its "onResize / measure" function when the DIMENSIONS of the
         * measured element change, not its position (top / left).
         * Thus we need to manually trigger a measure whenever we know that the position of the element
         * has changed. Right now that's only when the element's location or index changes, because if the element
         * is in a column, it will handle forcing its children to re-measure (see the bottom of measureElement).
         */
        componentDidUpdate(prevProps) {
            const { element } = this.props;

            const forceReMeasure = getElementLocation(element) !== getElementLocation(prevProps.element);

            if (forceReMeasure) this.forceMeasure();
        }

        componentWillUnmount() {
            const { element, dispatchMeasurementsRemove } = this.props;
            const id = getElementId(element);
            measurementsRegistry.removeElement(id, this);

            // Needed to do this because on 'undo' of a target move the element gets mounted again before dismount
            // of the previous version. Thus the dismount was causing the mounted measurements to get cleared
            // incorrectly
            if (measurementsRegistry.getElementReferenceCount(id) < 1 && this.hasBeenMeasured) {
                dispatchMeasurementsRemove({ id });
            }
        }

        forceMeasure = () => {
            if (!this.measureComponent.current) return;

            this.measureComponent.current.measure();
        };

        /**
         * Allows the measurement registry to get the measurements of the element from the canvas document.
         */
        getElementMeasurementFromCanvasDocument = (elementBoundingClientRect) => {
            const { element, getContextZoomScale, getContextZoomTranslationPx, gridSize, boardSection } = this.props;

            const node = this.measureElementRef.current;

            const zoomScale = getContextZoomScale();
            const zoomTranslation = getContextZoomTranslationPx();

            const { featureSuggestionsHeight, originOffset, canvasOffset } = getElementDomMeasurementsData(
                element,
                node,
                elementBoundingClientRect,
                gridSize,
                zoomScale,
            );
            return getElementMeasurementFromCanvasDocument(
                elementBoundingClientRect,
                element,
                zoomScale,
                zoomTranslation,
                boardSection,
                featureSuggestionsHeight,
                originOffset,
                canvasOffset,
                node,
            );
        };

        measureElement = (measurements) => {
            if (this.preventMeasure) return;

            // During zooms we don't want measurement changes to be saved to the store, because the
            // store won't be up-to-date with the correct zoom state and as such when translating and
            // scaling the measurements it won't be correct.
            if (zoomStateSingleton.getIsZooming()) {
                if (!!this.previousMeasurements) return;

                // If we haven't measured this element yet, then subscribe it to when the zoom ends and
                // re-measure the element
                zoomStateSingleton.registerZoomEndSubscriber(this.forceMeasure);

                return;
            }

            // If the element has no height, then don't record its measurement as it hasn't been rendered
            // correctly yet
            if (measurements.height === 0) return;

            // This is a hack to prevent full-screens from causing the canvas to keep growing.
            // Potentially be more strict here (i.e. only for link element types with a video media type) but
            // for now, keeping it general
            if (measurements.width === window.screen.width) return;

            const {
                currentBoardId,
                element,
                dispatchMeasurementsSet,
                dispatchGetChildIds,
                dispatchCheckCollisions,
                gridSize,
                isPreview,
                isEditable,
            } = this.props;

            const id = getElementId(element);
            const node = this.measureElementRef.current;

            measurements = this.getElementMeasurementFromCanvasDocument(measurements);

            // If the element measurements haven't changed since the last execution, don't update the store
            if (shallowEqual(this.previousMeasurements, measurements)) return;

            const currentY = getYPosition(element);
            const currentX = getXPosition(element);

            const heightChange = (measurements?.height || 0) - (this.previousMeasurements?.height || 0);

            const shouldCheckCollisionsProps = {
                isPreview,
                isEditable,
                syncedMeasurements,
                element,
                gridSize,
                previousDevicePixelRatio: this.previousDevicePixelRatio,
                previousMeasurements: this.previousMeasurements,
                previousGridSize: this.previousGridSize,
                previousX: this.previousX,
                previousY: this.previousY,
                currentX,
                currentY,
                heightChange,
            };

            const shouldCheckCollisions = getShouldCheckForCollisions(shouldCheckCollisionsProps);

            const isCommonCollisionDetectionBug =
                isColumn(element) &&
                heightChange === 6 &&
                // Make sure we would be performing collision detection if the height change was higher
                getShouldCheckForCollisions({ ...shouldCheckCollisionsProps, heightChange: 10 });

            // For some reason
            if (isCommonCollisionDetectionBug && isDebugEnabled()) {
                logger.groupCollapsed(
                    '%c WARNING: Possible collision detection bug - report this! ',
                    'background: #fee2d6',
                );

                logger.info(
                    `It's possible that a column collision detection bug has occurred.\n` +
                        `If you see this message, please report it to the team and collect details ` +
                        `about the actions you were performing.\n`,
                );

                logger.log('Details:', {
                    elementId: id,
                    ...shouldCheckCollisionsProps,
                });

                logger.groupEnd();
            }

            // First check for collisions before updating the measurements map
            // This is so we can determine the change in height of the new measurements
            if (shouldCheckCollisions) {
                const isNewCard = !this.previousMeasurements && !prop(id, syncedMeasurements);

                // Log the cause of the next attempt to perform collision detection
                if (collisionDetectionManager.blocked && !collisionDetectionManager.logged) {
                    collisionDetectionManager.logged = true;

                    const reason = isNewCard ? 'new card' : `height change of ${heightChange}px`;

                    logger.warn(
                        'Attempted to perform collision detection while blocked. ' +
                            'BOARD ID: %s, ID: %s, TYPE: %s, REASON:',
                        currentBoardId,
                        id,
                        getElementType(element),
                        reason,
                    );
                    manuallyReportError({
                        errorMessage: 'Attempted to perform collision detection while blocked',
                        level: ROLLBAR_LEVELS.WARNING,
                        custom: { currentBoardId, id, elementType: getElementType(element), reason },
                    });
                }

                dispatchCheckCollisions({
                    elementId: id,
                    elementMeasurement: measurements,
                    currentMeasurements: syncedMeasurements,
                    isNewCard,
                });
            }

            this.hasBeenMeasured = true;
            dispatchMeasurementsSet({ id, measurements });

            // If the column has changed in measurements, we should force-measure the children
            if (isColumn(element)) {
                const childIds = dispatchGetChildIds(id);
                childIds.forEach((childId) => measurementsRegistry.forceElementMeasure(childId));
            }

            // This works in cooperation with the ElementContentVisibleObserver.
            // It specifies the size to render the blank element when it's off screen and "content-visible" is "auto"
            if (node && node.style) {
                node.style.containIntrinsicSize = `${measurements.width}px ${measurements.height}px`;
            }

            this.previousGridSize = gridSize;
            this.previousMeasurements = measurements;
            this.previousDevicePixelRatio = window.devicePixelRatio;
            this.previousX = currentX;
            this.previousY = currentY;
        };

        updateCachedMeasurements = (measurements) => {
            this.previousMeasurements = measurements;
        };

        /**
         * PreventMeasure allows us to toggle measurements from within the component, when we
         * know that the measurements are not needed.
         * e.g. to prevent measurements from being taken when placeholders are rendered
         */
        setShouldPreventMeasure = (preventMeasure) => {
            this.preventMeasure = preventMeasure;
        };

        render() {
            const { documentMode, isEditing, isClipboardCut, isFocusedForegroundElement } = this.props;

            // If we're cutting an element don't even worry about rendering & measuring it
            if (isClipboardCut) return null;

            if (documentMode || isFocusedForegroundElement) return <DecoratedComponent {...this.props} />;

            // We don't want connected lines to keep jumping while editing cards that are height restricted
            // (because the cards expand when they're being edited)
            const shouldMeasure = !(isHeightRestricted(this.props.element) && isEditing);

            return (
                <Measure
                    ref={this.measureComponent}
                    whitelist={['width', 'height', 'top', 'left']}
                    shouldMeasure={shouldMeasure}
                    onMeasure={this.measureElement}
                >
                    <DecoratedComponent
                        {...this.props}
                        measureElementRef={this.measureElementRef}
                        forceMeasure={this.forceMeasure}
                        setShouldPreventMeasure={this.setShouldPreventMeasure}
                    />
                </Measure>
            );
        }
    }

    MeasureElementDecorator.propTypes = {
        currentBoardId: PropTypes.string,
        element: PropTypes.object.isRequired,
        isClipboardCut: PropTypes.bool,
        dispatchMeasurementsSet: PropTypes.func,
        dispatchMeasurementsRemove: PropTypes.func,
        dispatchGetChildIds: PropTypes.func,
        dispatchCheckCollisions: PropTypes.func,
        documentMode: PropTypes.bool,
        isEditable: PropTypes.bool,
        isEditing: PropTypes.bool,
        isPreview: PropTypes.bool,
        isFocusedForegroundElement: PropTypes.bool,
        gridSize: PropTypes.number,
        boardSection: PropTypes.string,

        getContextZoomScale: PropTypes.func,
        getContextZoomTranslationPx: PropTypes.func,
    };

    return MeasureElementDecorator;
};
