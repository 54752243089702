import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ColumnExpandIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon column-expand"
        {...props}
    >
        <path fill="#A3A7AE" fillRule="evenodd" d="m11 9-3 3-3-3h6zM8 4l3 3H5l3-3z" />
    </svg>
);
const Memo = memo(ColumnExpandIcon);
export default Memo;
