import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuLockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-lock"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M12 2.25c3.016 0 5.472 2.368 5.472 5.304v2.892H19a2.25 2.25 0 0 1 2.25 2.25v7.018a2.25 2.25 0 0 1-2.25 2.25H5a2.25 2.25 0 0 1-2.25-2.25v-7.018A2.25 2.25 0 0 1 5 10.446h1.527V7.554C6.528 4.618 8.985 2.25 12 2.25zm7 9.696H5a.75.75 0 0 0-.75.75v7.018c0 .414.336.75.75.75h14a.75.75 0 0 0 .75-.75v-7.018a.75.75 0 0 0-.75-.75zM12 3.75c-2.2 0-3.972 1.71-3.972 3.804l-.001 2.892h7.945V7.554C15.972 5.459 14.2 3.75 12 3.75z"
        />
    </svg>
);
const Memo = memo(ContextMenuLockIcon);
export default Memo;
