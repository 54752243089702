// Lib
import { last, take, isNumber, compact, uniq } from 'lodash/fp';
import chroma, { Color } from 'chroma-js';
import { createSelector } from 'reselect';
import quantize from '../../../../../../node_module_clones/quantize/quantize';

// Utils
import { createShallowSelector } from '../../../../../utils/milanoteReselect/milanoteReselect';
import {
    getImageColors,
    getCreatedTime,
    getDefaultColorPalette,
    getAutoColorIndex,
    getColor,
    getColorSpace,
    getSecondaryColor,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isBoard, isColorSwatch } from '../../../../../../common/elements/utils/elementTypeUtils';
import { hexToRgb, isHexColorFormat, rgbToHex } from '../../../../../../common/colors/colorSpaceUtil';

// Selectors
import {
    getCurrentBoard,
    getCurrentBoardVisibleDescendants,
} from '../../../../../element/selectors/currentBoardSelector';

// Constants
import { DEFAULT_COLOR_PALETTES } from '../../../../../../common/boards/boardDefaultColorPalettes';
import { MAX_COLOR_LIGHTNESS, MIN_COLOR_LIGHTNESS } from '../../../../../../common/colors/dualColorUtils';
import { getColorObjectCssValue } from '../../../../../../common/colors/colorObjectUtil';
import { COLOR_SPACE } from '../../../../../../common/colors/colorConstants';

const EMPTY_ARRAY: string[] = [];
const FALLBACK_COLOR = chroma('#000000');
const MIN_COLORS = 3;
const MAX_COLORS = 10;

const getColorPurityScore = (color: Color) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, sat, value] = color.hsv();
    return sat + value;
};

const getCurrentBoardColorSwatchColors = createShallowSelector(
    getCurrentBoardVisibleDescendants,
    (visibleDescendants): string[] => {
        const colors: string[] = [];

        visibleDescendants
            .filter(isColorSwatch)
            // convert colors to hex format
            .map((element): string | undefined => {
                const colorSwatchColor = getColorObjectCssValue(getColor(element));
                const colorSwatchSpace = getColorSpace(element);
                if (!colorSwatchColor) return;

                if (colorSwatchSpace === COLOR_SPACE.HEX) {
                    return colorSwatchColor;
                } else {
                    return chroma(colorSwatchColor).hex('rgb');
                }
            })
            // append to selected colors if unique
            .forEach((color) => {
                if (color && colors.indexOf(color) === -1) {
                    colors.push(color);
                }
            });

        if (!colors.length) return EMPTY_ARRAY;

        return colors;
    },
);

const getCurrentBoardImageColors = createShallowSelector(
    getCurrentBoardVisibleDescendants,
    (visibleDescendants): string[] => {
        let colors: string[] = [];

        visibleDescendants.forEach((element) => {
            const imageColors = getImageColors(element);
            if (!imageColors) return;

            colors = colors.concat(imageColors.toJS());
        });

        if (!colors.length) return EMPTY_ARRAY;

        return colors;
    },
);

const getCurrentBoardImageColorsQuantized = createShallowSelector(
    getCurrentBoardImageColors,
    (imageColors): string[] => {
        // only necessary to re-quantize colors when more than 10
        if (imageColors.length <= MAX_COLORS) return imageColors;

        const colorsRgb = compact(imageColors.map(hexToRgb));

        if (!colorsRgb || colorsRgb.length <= 0) return imageColors;

        const quantizedColors = quantize(colorsRgb, MAX_COLORS);
        if (!quantizedColors) return imageColors;

        const quantizedColorsPalette = quantizedColors.palette();
        if (!quantizedColorsPalette) return imageColors;

        return quantizedColorsPalette.map(rgbToHex);
    },
);

const getCurrentBoardChildBoardColors = createShallowSelector(
    getCurrentBoardVisibleDescendants,
    (visibleDescendants) => {
        const boardColors: string[] = [];

        visibleDescendants.forEach((element) => {
            if (!isBoard(element)) return;

            const boardColor = getColor(element);

            if (
                !!getSecondaryColor(element) ||
                isNumber(getAutoColorIndex(element)) ||
                !isHexColorFormat(boardColor) ||
                boardColors.indexOf(boardColor) > -1
            )
                return;

            boardColors.push(boardColor);
        });

        return boardColors;
    },
);

const getCurrentBoardDefaultSuggestedColors = createSelector(getCurrentBoard, (currentBoard): string[] => {
    const defaultColorPalette = getDefaultColorPalette(currentBoard);
    if (defaultColorPalette) return defaultColorPalette.toJS();

    // use board created time as a randomiser for selecting suggested colors
    const currentBoardCreatedTime = getCreatedTime(currentBoard);
    const colorIndex = currentBoardCreatedTime % DEFAULT_COLOR_PALETTES.length;

    return DEFAULT_COLOR_PALETTES[colorIndex];
});

type CurrentBoardSuggestedColorsSelectorResult = {
    isDefaultPalette: boolean;
    colors: string[];
};

const selectMostVariedColors = (colors: Color[], selectCount: number = MAX_COLORS): Color[] => {
    const firstColor = colors.shift() || FALLBACK_COLOR;
    const selectedColors = [firstColor];

    // re-sort the list of selected colors, picking the next most dissimilar color
    while (colors.length > 0 && selectedColors.length <= selectCount) {
        // find the next most dissimilar color
        const color = last(selectedColors) || FALLBACK_COLOR;

        const [mostDissimilarColor, mostDissimilarIndex] = colors.reduce(
            ([selectedColor, selectedIndex, selectedDelta], testColor, testIndex) => {
                const testDelta = chroma.deltaE(color, testColor);

                return testDelta >= selectedDelta
                    ? [testColor, testIndex, testDelta]
                    : [selectedColor, selectedIndex, selectedDelta];
            },
            [FALLBACK_COLOR, -1, -Infinity] as [Color, number, number],
        );

        // remove selected color from the list of colors to loop through
        colors.splice(mostDissimilarIndex, 1);

        // Exclude colors that are too dark or too light
        const tooLight = mostDissimilarColor.get('hsl.l') > MAX_COLOR_LIGHTNESS;
        const tooDark = mostDissimilarColor.get('hsl.l') < MIN_COLOR_LIGHTNESS;

        const excludeColor = tooLight || tooDark;

        if (!excludeColor) {
            selectedColors.push(mostDissimilarColor);
        }
    }

    return selectedColors;
};

const sortSelectedColorList = (selectedColors: Color[]): Color[] => {
    const firstColor = selectedColors.shift() || FALLBACK_COLOR;
    const sortedColors = [firstColor];

    // re-sort the list of selected colors, picking the next-most similar color
    while (selectedColors.length > 0) {
        const color = last(sortedColors) || FALLBACK_COLOR;

        // reduce the list of selected colors to the next most similar color
        const [mostSimilarColor, mostSimilarIndex, mostSimilarDelta] = selectedColors.reduce(
            ([selectedColor, selectedIndex, selectedDelta], testColor, testIndex) => {
                const testDelta = chroma.deltaE(color, testColor);

                return testDelta <= selectedDelta
                    ? [testColor, testIndex, testDelta]
                    : [selectedColor, selectedIndex, selectedDelta];
            },
            [FALLBACK_COLOR, -1, Infinity] as [Color, number, number],
        );

        // remove selected color from the list of colors to loop through
        selectedColors.splice(mostSimilarIndex, 1);

        // exclude shades of grey that are too similar
        const similarGrey = mostSimilarColor.get('hsl.s') < 0.2 && mostSimilarDelta < 15;

        const excludeColor = similarGrey;

        if (!excludeColor) {
            sortedColors.push(mostSimilarColor);
        }
    }

    return sortedColors;
};

const currentBoardSuggestedColorsSelector = createShallowSelector(
    getCurrentBoardImageColorsQuantized,
    getCurrentBoardChildBoardColors,
    getCurrentBoardColorSwatchColors,
    getCurrentBoardDefaultSuggestedColors,
    (
        imageColors,
        childBoardColors,
        childSwatchColors,
        defaultSuggestedColors,
    ): CurrentBoardSuggestedColorsSelectorResult => {
        if (!imageColors || imageColors.length === 0) {
            return {
                isDefaultPalette: true,
                colors: defaultSuggestedColors,
            };
        }

        const allSelectedColors = uniq([...imageColors, ...childBoardColors, ...childSwatchColors])
            .filter(isHexColorFormat)
            .map((hex) => chroma(hex));

        allSelectedColors.sort((colorA, colorB) => getColorPurityScore(colorB) - getColorPurityScore(colorA));

        const selectedColors = selectMostVariedColors(allSelectedColors);

        const sortedColors = sortSelectedColorList(selectedColors);

        if (sortedColors.length <= MIN_COLORS) {
            return {
                isDefaultPalette: true,
                colors: defaultSuggestedColors,
            };
        }

        const sortedColorsHex = sortedColors.map((color) => color.hex('rgb'));

        return {
            isDefaultPalette: false,
            colors: take(MAX_COLORS, sortedColorsHex),
        };
    },
);

export const currentBoardSuggestedColorSelectorWithDefault = currentBoardSuggestedColorsSelector;

const currentBoardSuggestedColorSelectorOnlyColors = createSelector(
    currentBoardSuggestedColorsSelector,
    ({ colors }) => colors,
);

export default currentBoardSuggestedColorSelectorOnlyColors;
