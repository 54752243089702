// Lib
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import {
    getFirstSelectedSecondaryColor,
    getSelectedElementColorStatusClass,
    getSelectedElementType,
} from '../colorToolUtils';

// Selectors
import { getThemeIsDarkMode } from '../../../../../../user/account/accountModal/interface/themeSettings/themeSelector';
import { currentBoardDualColorListSelector, DualColorList } from '../dualColors/currentBoardDualColorListSelector';

// Components
import ColorButton from './ColorButton';
import CustomColorPickerContainer from './CustomColorPickerContainer';
import ColorPopupModeSwitcher from './ColorPopupModeSwitcher';

// Styles
import './ColorPopupContent.scss';

// Constants
import { Color, COLORS } from '../../../../../../../common/colors/colorConstants';
import { COLOR_POPUP_MODE } from './colorPopupConstants';

// Types
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';

const COLORS_ARRAY = Object.values(COLORS);

type ColorPopupContentProps = {
    supportBackgroundColor: boolean;
    supportColorBar: boolean;
    isDarkModeTheme: boolean;
    colorPopupMode: string;
    openCustomColorPicker: () => void;
    selectedElements: Immutable.List<ImMNElement>;
    suggestedColors: string[];
    dualColorList: DualColorList;
    defaultColors: Color[];
    setColorPopupMode: (mode: string) => void;
    setColor: (color: string | null, secondaryColor?: string | null | undefined) => void;
    closePopup?: () => void;
    selectedColor: string | null;
    elementType: string;
    supportDualColors: boolean;
    maxSuggestedColors?: number;
};

const colorPopupSelector = createStructuredSelector({
    isDarkModeTheme: getThemeIsDarkMode,
    dualColorList: currentBoardDualColorListSelector,
});

/**
 * This is being moved to its own component so that it does not render unless the popup is open.
 */
const ColorPopupContent = ({
    supportBackgroundColor,
    supportColorBar,
    setColor,
    defaultColors = COLORS_ARRAY,
    selectedElements,
    selectedColor,
    suggestedColors = [],
    colorPopupMode,
    closePopup,
    openCustomColorPicker,
    setColorPopupMode,
    isDarkModeTheme,
    dualColorList = [],
    supportDualColors,
    maxSuggestedColors,
}: ColorPopupContentProps) => {
    const selectedType = getSelectedElementType(selectedElements);
    const selectedStatus = getSelectedElementColorStatusClass(selectedElements);
    const secondaryColor = getFirstSelectedSecondaryColor(selectedElements);

    const preventUpdateSecondaryColor = colorPopupMode === COLOR_POPUP_MODE.COLOR_BAR || !supportDualColors;
    const showDualColorOptions = supportDualColors && colorPopupMode !== COLOR_POPUP_MODE.COLOR_BAR;

    return (
        <div className="ColorPopupContent">
            {supportBackgroundColor && supportColorBar && (
                <ColorPopupModeSwitcher colorPopupMode={colorPopupMode} setColorPopupMode={setColorPopupMode} />
            )}

            <ul className="colors">
                {defaultColors.map((color) => (
                    <li key={color.name}>
                        <ColorButton
                            selectedType={selectedType}
                            selectedStatus={selectedStatus}
                            setColor={setColor}
                            color={color}
                            colorPopupMode={colorPopupMode}
                            isActive={selectedColor === color.css || selectedColor === color.name}
                            className={color.className}
                            isDarkModeTheme={isDarkModeTheme}
                            preventUpdateSecondaryColor={preventUpdateSecondaryColor}
                        />
                    </li>
                ))}
            </ul>

            {showDualColorOptions && (
                <>
                    <div className="divider" />
                    <ul className="colors">
                        {dualColorList.slice(0, maxSuggestedColors).map((color) => {
                            const { primary, secondary } = color;
                            return (
                                <li key={`${primary}${secondary}`}>
                                    <ColorButton
                                        selectedType={selectedType}
                                        selectedStatus={selectedStatus}
                                        setColor={setColor}
                                        color={primary}
                                        secondaryColor={secondary}
                                        colorPopupMode={colorPopupMode}
                                        isActive={selectedColor === primary && secondaryColor === secondary}
                                        isDarkModeTheme={isDarkModeTheme}
                                        preventUpdateSecondaryColor={preventUpdateSecondaryColor}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}

            {suggestedColors.length > 0 && (
                <>
                    <div className="divider" />

                    <ul className="colors">
                        {suggestedColors.slice(0, maxSuggestedColors).map((color) => (
                            <li key={color}>
                                <ColorButton
                                    selectedType={selectedType}
                                    selectedStatus={selectedStatus}
                                    setColor={setColor}
                                    color={color}
                                    colorPopupMode={colorPopupMode}
                                    isActive={selectedColor === color && (!secondaryColor || !showDualColorOptions)}
                                    isDarkModeTheme={isDarkModeTheme}
                                    preventUpdateSecondaryColor={preventUpdateSecondaryColor}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}

            <CustomColorPickerContainer closePopup={closePopup} openCustomColorPicker={openCustomColorPicker} />
        </div>
    );
};

export default connect(colorPopupSelector)(ColorPopupContent);
