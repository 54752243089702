import { EMAIL_REGEX, FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

const EMAIL_ERROR_OBJECT = {
    name: FAILURE_NAMES.email,
    type: FAILURE_TYPES.ERROR,
    debounced: true,
    message: 'Not a valid email',
};

const getErrorObject = (fieldValue, validationConfig) => ({
    ...EMAIL_ERROR_OBJECT,
    value: fieldValue,
    hideBeforeSubmit: !!validationConfig.hideBeforeSubmit,
});

export default (fieldValue, validationConfig = {}) => {
    if (!fieldValue) return null;
    if (!fieldValue.match(EMAIL_REGEX)) return getErrorObject(fieldValue, validationConfig);
    return null;
};
