// Utils
import { getStartOfDayDate, TIMES } from '../../../../../../common/utils/timeUtil';

// Constants
import { REMINDER_VALUES } from './taskDueDateConstants';

const NO_TIME_REMINDER_OPTIONS = [
    REMINDER_VALUES.NONE,
    REMINDER_VALUES.NINE_AM_DAY_OF,
    REMINDER_VALUES.NINE_AM_DAY_BEFORE,
];

const WITH_TIME_REMINDER_OPTIONS = [
    REMINDER_VALUES.NONE,
    REMINDER_VALUES.WHEN_DUE,
    REMINDER_VALUES.TEN_MINUTES_BEFORE,
    REMINDER_VALUES.ONE_HOUR_BEFORE,
    REMINDER_VALUES.NINE_AM_DAY_OF,
    REMINDER_VALUES.NINE_AM_DAY_BEFORE,
];

export const getReminderOptions = (hasDueDateTime) =>
    hasDueDateTime ? WITH_TIME_REMINDER_OPTIONS : NO_TIME_REMINDER_OPTIONS;

const getTimestampAt9amOn = (daysBefore) => (date) => {
    const updatedDate = getStartOfDayDate(date);
    updatedDate.setHours(9);
    updatedDate.setDate(updatedDate.getDate() - daysBefore);
    return updatedDate.getTime();
};

const getTimestampAt9amOnDay = getTimestampAt9amOn(0);
const getTimestampAt9amOnDayBefore = getTimestampAt9amOn(1);

export const getTimestampForReminder = ({ dueDate, dueReminder }) => {
    if (!dueDate) return null;

    const date = new Date(dueDate);

    const dueDateTimestamp = date.getTime();

    switch (dueReminder) {
        case REMINDER_VALUES.WHEN_DUE:
            return dueDateTimestamp;
        case REMINDER_VALUES.TEN_MINUTES_BEFORE:
            return dueDateTimestamp - 10 * TIMES.MINUTE;
        case REMINDER_VALUES.ONE_HOUR_BEFORE:
            return dueDateTimestamp - TIMES.HOUR;
        case REMINDER_VALUES.NINE_AM_DAY_OF:
            return getTimestampAt9amOnDay(date);
        case REMINDER_VALUES.NINE_AM_DAY_BEFORE:
            return getTimestampAt9amOnDayBefore(date);
        case REMINDER_VALUES.NONE:
        default:
            return null;
    }
};

const getValidReminderOptions = ({ dueDate, hasDueDateTime, minimumTimestamp }) => {
    const options = [REMINDER_VALUES.NONE];

    if (hasDueDateTime) {
        if (dueDate.getTime() <= minimumTimestamp) return options;

        options.push(REMINDER_VALUES.WHEN_DUE);

        const dueTimestamp = dueDate.getTime();

        if (minimumTimestamp > dueTimestamp - 10 * TIMES.MINUTE) return options;

        options.push(REMINDER_VALUES.TEN_MINUTES_BEFORE);

        if (minimumTimestamp > dueTimestamp - TIMES.HOUR) return options;

        options.push(REMINDER_VALUES.ONE_HOUR_BEFORE);
    }

    const dueDayAt9am = getStartOfDayDate(dueDate);
    dueDayAt9am.setHours(9);

    const dueDayAt9amTimestamp = dueDayAt9am.getTime();

    if (minimumTimestamp > dueDayAt9amTimestamp) return options;

    if (dueDate.getHours() >= 9) {
        options.push(REMINDER_VALUES.NINE_AM_DAY_OF);
    }

    const dayBeforeAt9amTimestamp = dueDayAt9amTimestamp - TIMES.DAY;

    if (minimumTimestamp > dayBeforeAt9amTimestamp) return options;

    options.push(REMINDER_VALUES.NINE_AM_DAY_BEFORE);

    return options;
};

const getDefaultReminder = ({ dueDate, hasDueDateTime, minimumTimestamp }) => {
    const possibleOptions = getValidReminderOptions({ dueDate, hasDueDateTime, minimumTimestamp });

    return possibleOptions.includes(REMINDER_VALUES['9AM_DAY_OF'])
        ? REMINDER_VALUES['9AM_DAY_OF']
        : possibleOptions[possibleOptions.length - 1];
};

/**
 * When the due date or hasDueDateTime changes, we need to update the reminder to ensure it's valid.
 */
export const getNewReminderOption = ({
    dueDate,
    hasDueDateTime,
    userPersistedDueReminder,
    dueReminder,
    minimumTimestamp = Date.now(),
}) => {
    if (userPersistedDueReminder === REMINDER_VALUES.NONE) return userPersistedDueReminder;

    const options = getValidReminderOptions({ dueDate, hasDueDateTime, minimumTimestamp });

    if (userPersistedDueReminder && options.includes(userPersistedDueReminder)) return userPersistedDueReminder;

    if (dueReminder === REMINDER_VALUES.NONE) return getDefaultReminder({ dueDate, hasDueDateTime, minimumTimestamp });

    if (options.includes(dueReminder)) return dueReminder;

    return getDefaultReminder({ dueDate, hasDueDateTime, minimumTimestamp });
};
