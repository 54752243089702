// Lib
import React from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

// Actions
import { closePopup } from '../../../../../../../components/popupPanel/popupActions';

// Hooks
import useColorToolState from '../hooks/useColorToolState';

// Components
import CustomColorInput from '../../CustomColorInput';
import ToolbarPopup from '../../../../toolbarPopup/ToolbarPopup';
import ColorPopupContent from '../ColorPopupContent';

// Types
import { ImMNElement } from '../../../../../../../../common/elements/elementModelTypes';
import { Color } from '../../../../../../../../common/colors/colorConstants';
import { ColorToolSelectorReturnType } from '../../colorToolSelector';

// Styles
import './DesktopColorPopup.scss';

type DesktopColorPopupProps = ColorToolSelectorReturnType & {
    ColorPopupContentComponent: React.ComponentType;
    selectedElements: Immutable.List<ImMNElement>;
    closeColorPopup: () => void;
    currentUserId: string;
    isPopupOpen: boolean;
    supportBackgroundColor: boolean;
    setAppStateSuggestedColors: (suggestedColors: string[]) => void;
    popupId: string;
    buttonSelector: string;
    customDefaultColors?: Color[];
    customColorHandler?: (color: string | null, secondaryColor?: string | null | undefined) => void;
    customTempColorHandler?: (color: string | null, secondaryColor?: string | null | undefined) => void;
    closePopup: () => void;
};

const DesktopColorPopup = (props: DesktopColorPopupProps) => {
    const { isPopupOpen, customDefaultColors, buttonSelector, popupId } = props;

    const {
        popupColor,
        popupColorHex,
        supportColorBar,
        openCustomColorPicker,
        actualPopupMode,
        setColorPopupMode,
        supportDualColors,
        defaultColors,
        customColorInputRef,
        setColor,
        setTempColor,
        suggestedColors,
    } = useColorToolState(props);

    const dispatch = useDispatch();
    const closeColorPopup = () => dispatch(closePopup(popupId));

    return (
        <div className={classNames('DesktopColorPopup', { 'is-open': isPopupOpen })}>
            <CustomColorInput
                className="custom-color-picker-input"
                ref={customColorInputRef}
                initialColor={popupColorHex}
                setTempColor={setTempColor}
                setColor={setColor}
                onBlur={debounce(closeColorPopup, 200)}
            />
            <ToolbarPopup
                popupId={popupId}
                className="color-popup"
                buttonSelector={buttonSelector}
                closePopup={closeColorPopup}
            >
                <ColorPopupContent
                    {...props}
                    defaultColors={customDefaultColors || defaultColors}
                    supportColorBar={supportColorBar}
                    setColor={setColor}
                    selectedColor={popupColor}
                    openCustomColorPicker={openCustomColorPicker}
                    colorPopupMode={actualPopupMode}
                    setColorPopupMode={setColorPopupMode}
                    supportDualColors={supportDualColors}
                    suggestedColors={suggestedColors}
                />
            </ToolbarPopup>
        </div>
    );
};

export default DesktopColorPopup;
