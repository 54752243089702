// Lib
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

// Selectors
import { isAttachModeSelector } from '../../reducers/draggingSelector';
import { getDuplicateLoading } from '../duplicate/elementDuplicateSelector';

// Utils
import { fetchElements } from '../elementService';
import usePrevious from '../../utils/react/usePrevious';
import {
    getLocationSection,
    getIsCollapsed,
    getThreadId,
    getDuplicatedFromThreadId,
} from '../../../common/elements/utils/elementPropertyUtils';

// Components
import CommentThreadCollapsed from './collapsed/CommentThreadCollapsed';
import CommentThreadExpanded from './CommentThreadExpanded';
import CommentThreadDragPreview from './CommentThreadDragPreview';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';

const showCollapsedSelector = () =>
    createSelector(
        (state, ownProps) => ownProps.disableCollapse,
        (state, ownProps) => getIsCollapsed(ownProps.element),
        (state, ownProps) => getLocationSection(ownProps.element),
        (state, ownProps) => ownProps.isDragPreview,
        (state) => isAttachModeSelector(state),
        (disableCollapse, isCollapsed, section, isDragPreview, isAttachMode) =>
            !disableCollapse &&
            ((isCollapsed && ((section !== BoardSections.INBOX && section !== BoardSections.TRASH) || isDragPreview)) ||
                (!isDragPreview && section === BoardSections.ATTACHED) ||
                (isDragPreview && isAttachMode)),
    );

const mapStateToProps = () =>
    createStructuredSelector({
        showCollapsed: showCollapsedSelector(),
        isDuplicating: getDuplicateLoading,
    });

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchElements: (...args) => dispatch(fetchElements(...args)),
});

const CommentThreadSwitch = (props) => {
    const { elementId, element, showCollapsed, isDragPreview, isDuplicating, dispatchFetchElements } = props;

    const [displayThreadId, setDisplayThreadId] = useState(getThreadId(element));

    const prevIsDuplicating = usePrevious(isDuplicating);

    useEffect(() => {
        // When duplicating, show the thread of the original element until the duplication is complete.
        // This is so that the user can immediately see the comments while still duplicating.
        // However, editing will be disabled.
        if (isDuplicating) {
            setDisplayThreadId(getDuplicatedFromThreadId(element));
        }

        if (prevIsDuplicating && !isDuplicating) {
            dispatchFetchElements({ elementIds: [elementId], force: true }).then(() => {
                setDisplayThreadId(getThreadId(element));
            });
        }
    }, [isDuplicating]);

    if (isDragPreview) {
        return <CommentThreadDragPreview {...props} threadId={displayThreadId} />;
    }

    return showCollapsed ? (
        <CommentThreadCollapsed {...props} threadId={displayThreadId} />
    ) : (
        <CommentThreadExpanded {...props} threadId={displayThreadId} />
    );
};

CommentThreadSwitch.propTypes = {
    elementId: PropTypes.string.isRequired,
    element: PropTypes.object.isRequired,
    showCollapsed: PropTypes.bool,
    disableCollapse: PropTypes.bool,
    isDragPreview: PropTypes.bool,
    isDuplicating: PropTypes.bool,
    dispatchFetchElements: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentThreadSwitch);
