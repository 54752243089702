import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DistributeVerticallyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon distribute-vertically"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M24 23.5a.75.75 0 1 1 0 1.5H8a.75.75 0 1 1 0-1.5zM21 12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h10zm-.5 1.5h-9v5h9v-5zM24 7a.75.75 0 1 1 0 1.5H8A.75.75 0 0 1 8 7z"
            />
        </g>
    </svg>
);
const Memo = memo(DistributeVerticallyIcon);
export default Memo;
