import { onboardingStepsLoaded } from '../onboardingActions';

let stepLoader;
let loadingStepsPromise;
if (process.env.BROWSER) {
    stepLoader = require('./getOnboardingStep').preloadOnboardingSteps;
} else {
    stepLoader = require('./getOnboardingStepElectron').preloadOnboardingSteps;
}

const preloadOnboardingSteps = () => (dispatch) => {
    if (loadingStepsPromise) return loadingStepsPromise;

    loadingStepsPromise = stepLoader().then(() => {
        loadingStepsPromise = null;
        return dispatch(onboardingStepsLoaded());
    });

    return loadingStepsPromise;
};

export default preloadOnboardingSteps;
