export default ({ contentState, selection }) => {
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();

    if (startOffset === 0) return '';

    const block = contentState.getBlockForKey(startKey);
    const text = block.getText();

    return text.substring(0, startOffset);
};
