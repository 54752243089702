// Lib
import React from 'react';
import PropTypes from 'prop-types';

import { stopPropagationOnly } from '../../../../utils/domUtil';
import { getLinkTarget } from './draftLinkUtil';

const DraftLinkAnchor = (props) => {
    const { target, href, className, children, onMouseDown } = props;

    const linkTarget = getLinkTarget({ href, target });

    return (
        <a
            className={`DraftLinkAnchor ${className}`}
            href={href}
            rel="noopener noreferrer nofollow"
            target={linkTarget}
            onMouseDown={onMouseDown}
            onClick={stopPropagationOnly}
        >
            {children}
        </a>
    );
};

DraftLinkAnchor.propTypes = {
    shouldPreventClick: PropTypes.bool,
    target: PropTypes.string,
    href: PropTypes.string,
    className: PropTypes.string,
    onMouseDown: PropTypes.func,

    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DraftLinkAnchor;
