// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';

// Utils
import { getElementStyle } from '../../../utils/grid/gridUtils';
import { elementClassNames, getDomElementId } from '../../utils/elementUtil';
import {
    getElementId,
    getColor,
    getWidth,
    getShowCaption,
    getCaption,
} from '../../../../common/elements/utils/elementPropertyUtils';
import { getIsShowingMedia, hasFileData } from '../utils/fileElementUtils';
import { stopPropagationOnly } from '../../../utils/domUtil';
import { getShowQuickLineTool } from '../../quickLine/quickLineUtil';

// Selectors
import { currentBoardHasMediaDownloadAccessSelector } from '../../../utils/permissions/elementPermissionsSelector';

// Components
import ColorBar from '../../../components/colors/ColorBar';
import Caption from '../../../components/caption/Caption';
import ElementUserTag from '../../../user/userActivity/ElementUserTag';
import FileUploader from '../uploader/FileUploader';
import ElementResizeHandle from '../../resizing/ElementResizeHandle';
import ElementActivityIndicator from '../../activity/ElementActivityIndicator';
import QuickNotesActivityIndicator from '../../activity/QuickNotesActivityIndicator';
import ElementReactions from '../../reactions/ElementReactions';
import QuickLineCreationTool from '../../quickLine/QuickLineCreationTool';
import AttachedCommentsDropTarget from '../../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../../attachedComment/AttachedCommentsContainer';
import FileDetailViewContent from './FileDetailViewContent';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../../common/elements/elementConstants';

// Styles
import './FileDetailView.scss';
import { getIsReplaceModeHovered } from '../../../reducers/draggingSelector';

const mapStateToProps = () =>
    createStructuredSelector({
        showDownloadOption: currentBoardHasMediaDownloadAccessSelector,
    });

const FileDetailView = (props) => {
    const {
        currentBoardId,
        elementId,
        element,
        inList,
        gridSize,
        tempSize,
        elementEvents,
        isLocked,
        attachment,
        isSelected,
        isEditable,
        isPresentational,
        className,
        permissions,
        setParentHoveredChildAcceptsDrop,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const colorName = getColor(element);
    const captionContent = getCaption(element);
    const captionVisible = getShowCaption(element);

    const hasFile = hasFileData({ element, attachment });
    const isShowingMedia = getIsShowingMedia({ element, attachment });
    const showHandle = !isLocked && isShowingMedia && !inList && isEditable;

    const isReplaceModeHovered = useSelector((state) => getIsReplaceModeHovered(state, { elementId }));

    const savedElementWidthGU = getWidth(element);
    const elementWidthGU = isShowingMedia
        ? (tempSize && tempSize.width) || savedElementWidthGU || ELEMENT_DEFAULT_WIDTH
        : ELEMENT_DEFAULT_WIDTH;

    const fileClasses = classNames('FileDetailView drag-handle', className, {
        'contrast-shadow': !isShowingMedia,
    });
    const classes = elementClassNames(fileClasses, { ...props, isReplaceModeHovered });

    return (
        <div
            id={getDomElementId(getElementId(element))}
            className={classes}
            style={getElementStyle(elementWidthGU, gridSize, inList)}
            {...elementEvents}
        >
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementActivityIndicator {...props} />
            <QuickNotesActivityIndicator {...props} />
            <ElementUserTag {...props} />

            {hasFile ? (
                <FileDetailViewContent {...props} />
            ) : (
                <FileUploader {...props} type="file" accept="*" message="Upload a file" />
            )}

            <Caption
                {...props}
                onDoubleClick={stopPropagationOnly}
                placeholder="Add a description"
                textContent={captionContent}
                captionVisible={captionVisible}
            />
            <ElementReactions {...props} />
            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                isRelative
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                elementId={elementId}
                element={element}
                gridSize={gridSize}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
            <ElementResizeHandle {...props} showHandle={showHandle} />
        </div>
    );
};

FileDetailView.propTypes = {
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    inList: PropTypes.string,
    gridSize: PropTypes.number,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    className: PropTypes.string,
    isLocked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    isPresentational: PropTypes.bool,
    permissions: PropTypes.number,
    handleDoubleClick: PropTypes.func,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    showDownloadOption: PropTypes.bool,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default connect(mapStateToProps)(FileDetailView);
