import { getIsCollapsed } from '../../../common/elements/utils/elementPropertyUtils';

// Types
import { MNElement } from '../../../common/elements/elementModelTypes';
import { Point } from '../../../common/maths/geometry/pointTypes';
import * as pointLib from '../../../common/maths/geometry/point';
import { isLocationAttached } from '../../../common/elements/utils/elementLocationUtils';

/**
 * Returns comment thread's drop position.
 */
export const getCommentThreadDropPosition = (
    draggedElement: MNElement,
    sourceClientOffset: Point,
    scaledGrabOffset: Point,
    scaledCustomDragOffset: Point,
): Point => {
    let dropPosition = sourceClientOffset;

    // If the comment thread is initially not collapsed, we need to adjust the grab offset and custom drag offset
    const initiallyCollapsed = getIsCollapsed(draggedElement) || isLocationAttached(draggedElement);
    if (!initiallyCollapsed) {
        dropPosition = pointLib.translate(scaledGrabOffset, dropPosition);
        dropPosition = pointLib.translate(scaledCustomDragOffset, dropPosition);
    }

    return dropPosition;
};
