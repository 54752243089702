import { createStore, applyMiddleware } from 'redux';
import { identity } from 'lodash/fp';
import { getSocketManager } from '../utils/socket/socketClient';
import getMiddleware from './getMiddleware';

import getInitialState from './getMobileHybridInitialState';
import hybridAppMiddleware from '../hybridApp/store/hybridAppMiddleware';
import hybridAppCustomDOMEventMiddleware from '../hybridApp/store/hybridAppCustomDOMEventMiddleware';

// Use the local storage version of the persisted send buffer
import createLocalStoragePersistedSendBuffer from '../utils/socket/buffer/localStoragePersistedSendBuffer';

import rootReducer from '../reducers';
import { enableReducerBatching } from './reduxBulkingMiddleware';
import { isGlobalDebugEnabled } from '../debug/debugUtil';
import swiftResourcesNotifier from '../hybridApp/swiftApp/middleware/resourcesNotifier/swiftResourcesNotifierMiddleware';
import swiftInjectWindowDispatchMiddleware from '../hybridApp/swiftApp/middleware/swiftInjectWindowDispatchMiddleware';
import swiftInjectWindowInvokeSelectorMiddleware from '../hybridApp/swiftApp/middleware/swiftInjectWindowInvokeSelectorMiddleware';

import hybridPostMiddleware from '../hybridApp/swiftApp/middleware/hybridPostMiddleware';
import hybridPreMiddleware from '../hybridApp/swiftApp/middleware/hybridPreMiddleware';

export default function configureStore(initialState, thunkArg) {
    const remoteState = getInitialState();
    const state = remoteState || initialState;

    const socketManager = getSocketManager(createLocalStoragePersistedSendBuffer);

    const middlewareList = [
        ...getMiddleware({ socketManager, thunkArg }),
        hybridAppMiddleware,
        hybridAppCustomDOMEventMiddleware,
        swiftResourcesNotifier,
        swiftInjectWindowInvokeSelectorMiddleware,
        swiftInjectWindowDispatchMiddleware,
        hybridPostMiddleware,
    ];

    // The `hybridPreMiddleware` needs to be the second middleware after the `thunk`
    // because it completely changes the types of some actions
    middlewareList.splice(1, 0, hybridPreMiddleware);

    const composeEnhancers = (isGlobalDebugEnabled() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || identity;
    const middleware = applyMiddleware.apply(null, middlewareList);

    const store = createStore(enableReducerBatching(rootReducer), state, composeEnhancers(middleware));
    socketManager.subscribeToStore(store);

    return store;
}
