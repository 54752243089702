// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import searchHighlightPoiDecorator from '../editor/plugins/searchHighlight/searchHighlightPoiDecorator';

const EditableTitleSearchHighlight = (props) => {
    const { children } = props;

    return <span className="EditableTitleSearchHighlight highlight">{children}</span>;
};

EditableTitleSearchHighlight.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default searchHighlightPoiDecorator(EditableTitleSearchHighlight);
