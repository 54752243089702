// Lib
import http from '../../utils/services/http';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';
import { CUSTOM_HTTP_HEADERS } from '../../../common/utils/http/httpConstants';

// Types
import { ElementCountsResponse } from '../../../server/app/api/elements/elementCountsRouteHandler';

export const fetchElementDescendantCountsHttp = (elementIds: string[], token: string): Promise<ElementCountsResponse> =>
    http({
        url: `elements/counts`,
        timeout: 2 * TIMES.MINUTE,
        params: {
            elementIds,
        },
        headers: {
            [CUSTOM_HTTP_HEADERS.PERMISSION_TOKENS]: token,
        },
    }).then((response) => response.data);
