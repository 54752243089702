// Lib
import * as Immutable from 'immutable';

// Utils
import { getDeviceId } from '../device/deviceService';
import { createTimestamp } from '../../common/uid/timestamp';
import { getTimestamp } from '../../common/utils/timeUtil';
import randomString from '../../common/uid/randomString';

const createPageId = () => `page_${createTimestamp(getTimestamp())}${randomString(5)}`;

const initialState = Immutable.fromJS({
    deviceId: getDeviceId(),
    pageId: createPageId(),
});

// No actions will update this data at the moment
export default (state = initialState, action) => state;
