// Lib
import React from 'react';
import PropTypes from 'prop-types';

const FloatingPanelArrow = React.forwardRef(function FloatingPanelArrow(props, ref) {
    const { width, height, color, strokeWidth, strokeColor, style } = props;

    return (
        <div ref={ref} className="arrow" style={style}>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
                <path
                    d={`M0 ${height} L${width / 2} 0 L${width} ${height}`}
                    fill={color}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
});

FloatingPanelArrow.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    strokeWidth: PropTypes.number,
    strokeColor: PropTypes.string,
    style: PropTypes.object,
};

export default FloatingPanelArrow;
