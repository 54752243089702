import { getFileMimeType, getMediaAudioOnly } from '../../../../common/elements/utils/elementPropertyUtils';

import { FILE_TYPES_INFO } from '../../../../common/files/fileConstants';
import { MEDIA_PLAYER_TYPES } from '../mediaPlayer/mediaPlayerConstants';

export const getMimeTypeSupportsMediaPlayer = (mimeType) => {
    if (!mimeType) return;
    if (mimeType.match(FILE_TYPES_INFO.AUDIO.mediaPlayerMimePattern)) return MEDIA_PLAYER_TYPES.AUDIO;
    if (mimeType.match(FILE_TYPES_INFO.VIDEO.mediaPlayerMimePattern)) return MEDIA_PLAYER_TYPES.VIDEO;
};

const fileGetMediaPlayerType = (element) => {
    if (getMediaAudioOnly(element)) return MEDIA_PLAYER_TYPES.AUDIO;

    const mimeType = getFileMimeType(element);
    return getMimeTypeSupportsMediaPlayer(mimeType);
};

export const isVideoFile = (element) => fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.VIDEO;
export const isAudioFile = (element) => fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.AUDIO;

export default fileGetMediaPlayerType;
