import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { identity } from 'lodash/fp';
import { Capacitor } from '@capacitor/core';

// Styles
import './ImageRenderer.scss';

// Utils
import { getElementCardCropInset, getElementCardRenderedWidthPx } from '../utils/elementUtil';
import { getRenderedHeight } from '../../components/images/ResponsiveProgressiveImage';
import {
    getElementId,
    getImageProp,
    getShowFullAspectRatio,
} from '../../../common/elements/utils/elementPropertyUtils';
import { ImagePresenter } from '../../../capacitor_plugins/imagePresenter';
import { getId } from '../../../common/users/userHelper';
import { isPlatformIpadCapacitor } from '../../platform/utils/platformDetailsUtils';
import platformSingleton from '../../platform/platformSingleton';

// Components
import ImageDrawing from './ImageDrawing';
import ElementImage from '../../components/images/ElementImage';
import ElementReactions from '../reactions/ElementReactions';
import ImageReplacementOverlay from './ImageReplacementOverlay';

// Constants
import { EXPORT_HIGH_QUALITY_IMAGE_WIDTH } from '../../../common/images/imageConstants';
import { IMAGE_TYPES } from '../../../common/media/mediaConstants';
import { CAPACITOR_IMAGE_PRESENTER_PLUGIN } from '../../../capacitor_plugins/pluginConstants';

/**
 * Renders a 'ProgressiveImage' for an element.
 * If the element has image data the progressive image will use the thumbnail from the image data, otherwise it will
 * use the data from the element's attachment as it must have just been uploaded.
 *
 * NOTE: This component should ONLY be used when there is actually image data (either in the element's content or
 * attachment).  If it doesn't have data this component will not display correctly.
 *
 * @param element {Object} The element object from the redux store.
 * @param showProgressive {Boolean} Whether to show a progressive image or standard image.
 */
class ImageRenderer extends React.Component {
    componentDidUpdate() {
        const { isEditing, isSingleSelected, isElementOpenInModal } = this.props;
        if (!this.imageRenderer) return;

        // Focus the image if:
        // - it's the only element selected
        // - it's not being edited
        // - it's not open in a modal
        // This enables captions to be created immediately
        if (isSingleSelected && !isEditing && !isElementOpenInModal) {
            this.imageRenderer.focus();
        }
    }

    openFullSizeImageOrNativeCarousel = (event) => {
        event.preventDefault();

        if (
            Capacitor.isPluginAvailable(CAPACITOR_IMAGE_PRESENTER_PLUGIN) &&
            !isPlatformIpadCapacitor(platformSingleton)
        ) {
            const { currentBoardImages, element } = this.props;

            ImagePresenter.presentImageCarousel({
                currentImageId: getId(element),
                allImages: JSON.stringify(currentBoardImages),
            });
        } else {
            const { element, dispatchNavigateToImage } = this.props;

            dispatchNavigateToImage(getElementId(element));
        }
    };

    render() {
        const {
            element,
            inList,
            isHovered,
            handleKey,
            gridSize,
            renderHighQualityImages,
            tempMediaSize,
            registerMediaElement,
            connectFileDropTarget,
            isResizing,
            isTransparent,
            isReplaceModeHovered,
            isPresentationModeEnabled,
            isPreview,
        } = this.props;

        const widthPx = getElementCardRenderedWidthPx(this.props);
        const imageQualityWidthPxOverride = renderHighQualityImages
            ? // Use full quality images if exporting at high quality
              EXPORT_HIGH_QUALITY_IMAGE_WIDTH
            : widthPx;

        const savedWidthPx = getElementCardRenderedWidthPx({ ...this.props, tempSize: null });

        const imageHeight = getRenderedHeight({
            savedSize: getImageProp(element),
            renderedWidth: widthPx,
        });

        const showReactions = imageHeight / gridSize > 5;

        const wrapper = registerMediaElement || identity;

        const cropToGrid = !(getShowFullAspectRatio(element) || isResizing || isTransparent);

        const cropInset = getElementCardCropInset(inList);

        return wrapper(
            <div
                className={classNames('ImageRenderer focusable', { canDrop: isHovered })}
                ref={(c) => {
                    connectFileDropTarget?.(c);
                    this.imageRenderer = c;
                }}
                tabIndex="-1"
                onKeyDown={handleKey}
                onDoubleClick={!isPresentationModeEnabled ? this.openFullSizeImageOrNativeCarousel : null}
            >
                {isReplaceModeHovered && <ImageReplacementOverlay />}
                <ElementImage
                    useSecureMediaUrl
                    showBrokenIconOnError
                    element={element}
                    cropToGrid={cropToGrid}
                    cropInset={cropInset}
                    gridSize={gridSize}
                    imageType={IMAGE_TYPES.ELEMENT}
                    widthPx={widthPx}
                    imageQualityWidthPxOverride={imageQualityWidthPxOverride}
                    savedWidthPx={savedWidthPx}
                    forcedSize={tempMediaSize}
                    isPreview={isPreview}
                >
                    {showReactions && <ElementReactions {...this.props} />}
                    <ImageDrawing {...this.props} />
                </ElementImage>
            </div>,
        );
    }
}

ImageRenderer.propTypes = {
    gridSize: PropTypes.number,
    currentBoardImages: PropTypes.object,
    element: PropTypes.object.isRequired,
    isHovered: PropTypes.bool,
    tempMediaSize: PropTypes.object,

    size: PropTypes.number,
    inList: PropTypes.string,

    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isElementOpenInModal: PropTypes.bool,
    isResizing: PropTypes.bool,
    isTransparent: PropTypes.bool,
    isPreview: PropTypes.bool,
    isReplaceModeHovered: PropTypes.bool,

    handleKey: PropTypes.func,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    saveContent: PropTypes.func,
    textContent: PropTypes.string,
    showCaption: PropTypes.func,
    onEmptyBackspace: PropTypes.func,
    renderHighQualityImages: PropTypes.bool,
    tempSize: PropTypes.object,

    registerMediaElement: PropTypes.func,
    connectFileDropTarget: PropTypes.func,
    dispatchNavigateToImage: PropTypes.func,
    deselectAll: PropTypes.func,

    isPresentationModeEnabled: PropTypes.bool,
};

export default ImageRenderer;
