const DARK_FILTER_CLASS_NAME = 'onetrust-banner-dark-filter';
/**
 * Add a cookie banner overlay element, that will span the page, and prevent users
 * from interacting with page before interacting with banner
 */
export const addCookieBannerPageOverlay = () => {
    const darkFilter = document.createElement('div');
    darkFilter.classList.add(DARK_FILTER_CLASS_NAME);

    document.body.append(darkFilter);
};

export const removeCookieBannerPageOverlay = () => {
    const darkFilters = document.getElementsByClassName(DARK_FILTER_CLASS_NAME);
    Array.from(darkFilters).forEach((darkFilter) => document.body.removeChild(darkFilter));
};

/**
 * Check whether a specific cookie group is allowed
 *
 * @param cookieGroupId Cookie category to check
 * @returns {boolean|null}
 */
export const cookieGroupAllowed = (cookieGroupId) => {
    if (!window.OnetrustActiveGroups) return null;

    return window.OnetrustActiveGroups.includes(cookieGroupId);
};
