import { memoize } from 'lodash';
import { flow, add, isUndefined, isNull } from 'lodash/fp';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 31;
const YEAR = DAY * 365;

export const TIMES = {
    SECOND,
    MINUTE,
    HOUR,
    DAY,
    WEEK,
    MONTH,
    YEAR,
};

export const isValidDate = (date: Date | number | string | null) => !!date && !isNaN(new Date(date).getTime());

export const millisToSeconds = (millis: number): number => Math.floor(millis / TIMES.SECOND);
export const getTimestamp = (): number => Date.now();
export const getIsoString = (timestamp?: number): string =>
    (timestamp ? new Date(timestamp) : new Date()).toISOString();
export const getTimestampSeconds = flow([getTimestamp, millisToSeconds]);
export const getFutureTimestamp = (millisInFuture: number) => flow([getTimestamp, add(millisInFuture)]);
export const getFutureTimestampSeconds = (millisInFuture: number) =>
    flow([getFutureTimestamp(millisInFuture), millisToSeconds]);

export const getIsoStringWithoutTimezone = (date: Date | null) => {
    if (date === null || !isValidDate(date)) return null;

    const timezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.valueOf() - timezoneOffset).toISOString().replace('Z', '');
};

// This will be an offset, such as -600
export const getLocalTimezone = memoize(() => new Date().getTimezoneOffset());

/**
 * Transforms the date/timestamp passed in to a timestamp that it would be in the specified timezone, so that if
 * a Date is created using it (in the local timezone), its properties match those in the specified timezone.
 *
 * E.g. At 2022-05-04T05:09:34.980Z on a server in the UTC timezone it will add 10 hours if the timezone
 *  is AEST (-600) and equal 2022-05-04T15:09:34.980Z (3pm on the 4th of May).
 *  Notice that both of these timestamps are in UTC time, however the second's properties actually match AEST.
 */
export const adjustLocalDateToTimezone = (time: number, timezone = getLocalTimezone()) => {
    const localTimezone = getLocalTimezone();
    const userTimezone = isUndefined(timezone) || isNull(timezone) ? localTimezone : timezone;

    const timezoneModifier = (localTimezone - userTimezone) * TIMES.MINUTE;

    return time + timezoneModifier;
};

/**
 * The exact opposite of the adjustLocalDateToTimezone function.
 * Changes a date with the desired properties into a UTC time for the specified timezone.
 */
export const getUTCforAdjustedDateInTimezone = (time: number, timezone = getLocalTimezone()) => {
    const localTimezone = getLocalTimezone();
    const userTimezone = isUndefined(timezone) || isNull(timezone) ? localTimezone : timezone;

    const timezoneModifier = (userTimezone - localTimezone) * TIMES.MINUTE;

    return time + timezoneModifier;
};

export const getStartOfDayDate = (date: Date | number | string) => {
    const startOfDayDate = new Date(date);
    startOfDayDate.setHours(0);
    startOfDayDate.setMinutes(0);
    startOfDayDate.setSeconds(0);
    startOfDayDate.setMilliseconds(0);
    return startOfDayDate;
};

export const getStartOfYearDate = (date: Date | number | string) => {
    const startOfYearDate = new Date(date);
    startOfYearDate.setMonth(0);
    startOfYearDate.setDate(1);
    startOfYearDate.setHours(0);
    startOfYearDate.setMinutes(0);
    startOfYearDate.setSeconds(0);
    startOfYearDate.setMilliseconds(0);
    return startOfYearDate;
};

export const getDaysSince = (millis1: number, millis2 = Date.now()) => {
    const millis11IsStart = millis1 < millis2;
    const start = millis11IsStart ? millis1 : millis2;
    const end = millis11IsStart ? millis2 : millis1;
    return Math.floor((end - start) / TIMES.DAY);
};

export const addDays = (initialDate: Date | number | string, daysToAdd: number) => {
    const updatedDate = new Date(initialDate);
    updatedDate.setDate(updatedDate.getDate() + daysToAdd);
    return updatedDate;
};

export const isMoreThan =
    (timeDiff: number) =>
    (dateToTest: Date, comparisonDate = new Date()) => {
        const time1 = comparisonDate.getTime();
        const time2 = dateToTest.getTime();
        return Math.abs(time1 - time2) > timeDiff;
    };

export const useUSDateFormat = (locale: string) => locale === 'en-US';

/**
 * Converts a date or timestamp into a date string of the form "YYYY-MM-DD".
 */
export const toDateString = (date: Date | number | string = Date.now()) => new Date(date).toISOString().substr(0, 10);
export const toMonthString = (date: Date | number | string = Date.now()) => new Date(date).toISOString().substr(0, 7);

/**
 * Converts a date or timestamp into a date string of the form "DD/MM/YYYY" or if in US "MM/DD/YYYY".
 */
export const getNumericDateString = (date: Date, locale: string) =>
    new Intl.DateTimeFormat(useUSDateFormat(locale) ? 'en-US' : 'en-GB').format(date);

export const getTimeString = (date: Date, hasSeconds: boolean, useTwelveHour: boolean) =>
    new Intl.DateTimeFormat('en-US', {
        // using 'en-US' here for all locales so that the time format is always the same
        timeStyle: hasSeconds ? 'medium' : 'short',
        hourCycle: useTwelveHour ? 'h11' : 'h23',
    }).format(date);

export const toNumericDateTimeString = (
    date: Date,
    options: {
        hasTime: boolean;
        hasSeconds?: boolean;
        useTwelveHour?: boolean;
        showDate?: boolean;
    },
    locale: string,
) => {
    const { hasTime, hasSeconds = false, useTwelveHour = false, showDate = true } = options;
    const dateString = showDate ? getNumericDateString(date, locale) : '';
    const timeString = hasTime ? getTimeString(date, hasSeconds, useTwelveHour) : '';
    return `${dateString} ${timeString}`.trim();
};

// DateTime regexes
export const numericDateShortRegex = /\d{1,2}\/\d{1,2}\/\d{2}($|\s)/; // mm/dd/yy or dd/mm/yy
export const numericDateLongRegex = /\d{1,2}\/\d{1,2}\/\d{4}($|\s)/; // mm/dd/yyyy or dd/mm/yyyy
export const numericDateDashedRegex = /\d{1,2}-\d{1,2}-\d{2,4}($|\s)/; // mm-dd-yy or dd-mm-yy or mm-dd-yyyy or dd-mm-yyyy

const numericDateTimeRegex = /^(\d{1,2})[/-](\d{1,2})[/-](\d{2,4})\s?(\d{1,2}(:\d{1,2})*\s?([a|p]m)?)?$/; // dd/mm/yy, mm/dd/yy, dd/mm/yyyy, mm/dd/yyyy, dd/mm/yy/hh:mm, mm/dd/yy hh:mm, dd/mm/yyyy hh:mm, mm/dd/yyyy hh:mm

// Checks if string matches format such as 1 Jan, Jan 1, 1 Jan 2020, Jan 1 2020, 1 Jan 2020 1:00pm, Jan 1 2020 1:00pm
const wordDateTimeRegex = /^((\d{1,2}\s\w{3,})|(\w{3,}\s\d{1,2})),?\s?(\d{4})?\s?(\d{1,2}(:\d{1,2})*\s?([a|p]m)?)?$/;

// Checks if string contains a month such as Jan, January, Feb, February, etc.
const monthsRegex =
    /Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|June?|July?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?/i;

export const timeRegex12h = /(^|\s)\d{1,2}(:\d{1,2}){0,2}\s?[a|p]m/i;
export const timeRegex12hOnly = /^\d{1,2}(:\d{1,2}){0,2}\s?[a|p]m$/i;
export const timeRegex24hOnly = /^\d{1,2}(:\d{1,2}){0,2}$/i;

// Regex for iso string
export const isoStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

export const getDateFromString = (input: string | number | null, locale: string) => {
    if (!input) return null;

    let dateString: string = String(input).trim();

    // Check if ISO string
    if (dateString.match(isoStringRegex)) {
        const dateFromIso = new Date(dateString);
        if (isValidDate(dateFromIso)) return dateFromIso;
    }

    // Check if epoch time
    if (dateString.match(/-?\d{8,}$/)) {
        const dateFromEpoch = new Date(Number(input));
        if (isValidDate(dateFromEpoch)) return dateFromEpoch;
    }

    // Strip any brackets
    dateString = dateString.replace(/\(|\)/g, '');

    // If user has entered a time like 4pm or 3:25pm, we need to add a space before the meridiem and :00,
    // so it is parsed correctly
    const twelveHourTime = dateString.match(timeRegex12h);
    const addMinutes = twelveHourTime && !twelveHourTime[0].includes(':');
    if (twelveHourTime || addMinutes) {
        const meridiemRegex = /\s?([a|p]m)/i;
        const meridiemMatches = dateString.match(meridiemRegex);
        const meridiem = meridiemMatches && meridiemMatches[1];
        dateString = dateString.replace(meridiemRegex, `${addMinutes ? `:00` : ``} ${meridiem}`);
    }

    // Check if string only contains a time
    if (dateString.match(timeRegex12hOnly) || dateString.match(timeRegex24hOnly)) {
        // If the string only contains a time, we need to add the current date before attempting to parse the date
        const date = new Date(`${new Intl.DateTimeFormat('en-US').format(new Date())} ${dateString}`);
        return isValidDate(date) ? date : null;
    }

    // Check if string matches any of the accepted formats
    const numericDateTime = dateString.match(numericDateTimeRegex);
    const wordDateTime = dateString.match(wordDateTimeRegex);
    const months = dateString.match(monthsRegex);
    const validWordDateTime = wordDateTime && months;
    if (!numericDateTime && !validWordDateTime) return null;

    if (numericDateTime) {
        // Date object uses US date format, so if the user is not in US we need to swap day and month before attempting to parse the date
        if (!useUSDateFormat(locale)) {
            // eslint-disable-next-line no-unused-vars
            const [, day, month, year, time] = numericDateTime;
            dateString = `${month}/${day}/${year} ${time || ''}`;
        }
        // Replace hyphens with slashes to ensure the date is parsed correctly
        dateString = dateString.replace(/-/g, '/');
    }

    if (wordDateTime) {
        // If the date string does not contain a year, we need to add the current year before attempting to parse the date
        // numeric date type requires a year to be present, so we only check for non-numeric
        const containsYear = dateString.match(/\d{4}/);
        dateString = containsYear ? dateString : `${dateString} ${new Date().getFullYear()}`;
    }

    const date = new Date(dateString);
    return isValidDate(date) ? date : null;
};

/**
 * Checks if a date object has a time component
 */
export const dateObjectHasTime = (date: Date) => date && date.getHours() + date.getMinutes() + date.getSeconds() > 0;

export const dateTimeStringHasSeconds = (str: string) => !!str.match(/\d{1,2}:\d{1,2}:\d{1,2}/);
export const dateTimeStringHasDate = (str: string) => !str.match(timeRegex12hOnly) && !str.match(timeRegex24hOnly);

export const getAreDatesSameDay = (date1: Date | null, date2: Date | null) =>
    date1 &&
    date2 &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

export const getDateIsLessThan = (testDate: Date, comparisonDate: Date) =>
    testDate && comparisonDate && testDate < comparisonDate;

export const getIsOverdue = (dueDate: Date, hasDueDateTime: boolean, comparisonDate = new Date()) => {
    const dueDateIsPassed = getDateIsLessThan(dueDate, comparisonDate);
    const dueDayIsToday = getAreDatesSameDay(dueDate, comparisonDate);

    // If there's no time on the date, then it's only in the past if the
    // due date is less than the current date and it's not due today
    return (dueDateIsPassed && hasDueDateTime) || (dueDateIsPassed && !dueDayIsToday);
};

export const getIsDueToday = (dueDate: Date, comparisonDate = new Date()) =>
    getAreDatesSameDay(dueDate, comparisonDate);
