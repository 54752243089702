import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ReactionAddIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon reaction-add"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M8 1.25a6.769 6.769 0 0 1 1.639.201.562.562 0 1 1-.272 1.092A5.493 5.493 0 0 0 8 2.375a5.624 5.624 0 1 0 0 11.25 5.624 5.624 0 0 0 5.615-5.959.563.563 0 0 1 1.123-.065A6.75 6.75 0 1 1 8 1.25zm3 7.688c.437 0 .707.476.482.851C10.635 11.202 9.458 11.75 8 11.75c-1.458 0-2.635-.548-3.482-1.96A.562.562 0 0 1 5 8.938h6zM5.75 5.75a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm4.5 0a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm3-4.688c.285 0 .52.212.557.487l.005.076v1.313h1.313c.285 0 .52.211.557.486l.005.076c0 .285-.211.52-.486.557l-.076.006h-1.313v1.312c0 .285-.211.52-.486.557l-.076.005a.561.561 0 0 1-.557-.486l-.005-.076V4.062h-1.313a.561.561 0 0 1-.557-.486l-.005-.076c0-.285.211-.52.486-.557l.076-.006h1.313V1.625c0-.259.174-.477.412-.542l.074-.015.076-.005z"
        />
    </svg>
);
const Memo = memo(ReactionAddIcon);
export default Memo;
