// Utils
import { getEditorType } from '../../utils/editorTypeUtils';
import { getCaption, getIsTiptapConversionRecentlyPostponed } from '../../../elements/utils/elementPropertyUtils';

// Types
import { ElementEditor } from '../../../elements/elementEditorConstants';
import { EditorContent, MNElement, ImMNElement } from '../../../elements/elementModelTypes';

/**
 * Determines if the current element should use the Tiptap editor for the given editorContent,
 * based on the current type of the editor and whether the Tiptap conversion is enabled.
 */
export const shouldUseTiptapEditor = (
    editorContent: EditorContent,
    element: ImMNElement | MNElement,
    isTiptapConversionEnabled: boolean,
) => {
    const editorType = getEditorType(editorContent);

    if (editorType === ElementEditor.Tiptap) return true;

    if (!isTiptapConversionEnabled) return false;

    // Otherwise check to see if the conversion is postponed
    return !getIsTiptapConversionRecentlyPostponed(element);
};

/**
 * Determines if the current element should use the Tiptap editor for its caption,
 * based on the current type of the caption and whether the Tiptap conversion is enabled.
 */
export const shouldCaptionUseTiptapEditor = (element: ImMNElement, isTiptapConversionEnabled: boolean) => {
    const currentCaption = getCaption(element);
    return shouldUseTiptapEditor(currentCaption, element, isTiptapConversionEnabled);
};
