import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const PointerIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon pointer"
        {...props}
    >
        <defs>
            <filter
                id="PointerIcon__a"
                width="208.3%"
                height="168.4%"
                x="-54.2%"
                y="-34.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#PointerIcon__a)" transform="translate(7 2)">
            <path
                fill="#FFF"
                d="M8.914 15.57c.362.737.02 1.817-.853 2.264-.872.448-1.81.347-2.272-.332-.173-.144-2.063-3.814-2.063-3.814L0 17.414V.586L11.414 12H7.162c.208.4 1.391 2.834 1.752 3.57z"
            />
            <path
                fill="#000"
                d="M1 3v12l3-3s2.332 4.625 2.404 4.754c.138.246.533.659 1.164.322.63-.336.537-.84.391-1.19L5.5 11H9L1 3z"
            />
        </g>
    </svg>
);
const Memo = memo(PointerIcon);
export default Memo;
