// Lib
import { difference, isArray, isEmpty, isString, reduce } from 'lodash';
import { isAxiosError } from 'axios';
import { isObject } from 'lodash/fp';

// Utils
import logger from '../../../../logger/logger';
import { shouldFetchAsyncResource } from './asyncResourceUtils';
import { getTimestamp } from '../../../../../common/utils/timeUtil';
import { isAppError } from '../../../../../common/error/errorTypeUtils';
import { getDataExpiryTimestamp, getDataFetchedTimestamp, isAxiosResponse } from '../axiosResponseUtils';

// Selectors
import { getAsyncResourceEntityState } from './asyncResourceSelector';
import { getLocalCacheHydrationTimestamp } from '../../../../offline/cache/localCacheSelector';

// Types
import { AsyncResourceEntityErrorObject } from './reducers/asyncResourceReducerTypes';
import { DEFAULT_ASYNC_RESOURCE_EXPIRY, FALLBACK_ENTITY_ID, ResourceTypes } from './asyncResourceConstants';
import {
    AsyncResourceErrorMap,
    AsyncResourcePromiseResult,
    AsyncResourceResponseErrorObject,
} from './asyncResourceTypes';

/**
 * Adds the missing properties to the response error to make a resource entity error.
 */
export const convertAsyncResourceResponseErrorToEntityError = (
    error: AsyncResourceResponseErrorObject,
    elementId: string,
) => ({
    ...error,
    id: elementId,
});

const UNKNOWN_ERROR_NAME = 'UnknownError';
const UNKNOWN_ERROR_STATUS = 0;

/**
 * Builds an error object from an error thrown by an async resource action.
 */
export const convertErrorToAsyncResourceResponseError = (err: unknown): AsyncResourceResponseErrorObject => {
    if (isString(err)) {
        return {
            details: {},
            inner: {},
            name: UNKNOWN_ERROR_NAME,
            status: UNKNOWN_ERROR_STATUS,
            code: err,
            message: err,
        };
    }

    if (!(err instanceof Error)) {
        const details = isObject(err) ? { ...err } : {};

        return {
            details,
            inner: {},
            name: UNKNOWN_ERROR_NAME,
            status: UNKNOWN_ERROR_STATUS,
            code: 'Unknown error',
            message: 'err is not an instance of Error',
        };
    }

    if (isAppError(err)) {
        return {
            details: err.details || {},
            inner: err.inner || {},
            name: err.name,
            status: err.status,
            code: err.code,
            message: err.message,
        };
    }

    if (isAxiosError(err)) {
        const cleansedAxiosError = { ...err };

        delete cleansedAxiosError.request;
        delete cleansedAxiosError.response?.request;
        delete cleansedAxiosError.config?.env;
        delete cleansedAxiosError.config?.transformRequest;
        delete cleansedAxiosError.config?.transformResponse;
        delete cleansedAxiosError.config?.transitional;
        delete cleansedAxiosError.config?.validateStatus;

        const fallbackError = {
            details: { url: err.config?.url, ...cleansedAxiosError },
            inner: {},
            name: err.name || UNKNOWN_ERROR_NAME,
            status: err.status || UNKNOWN_ERROR_STATUS,
            code: err.code || 'Unknown axios error',
            message: err.message || 'Unknown axios error',
            url: err.config?.url,
        };

        if (err.response) {
            const details = err.response.data?.details || fallbackError.details;

            return {
                details: {
                    url: err.config?.url,
                    ...details,
                },
                inner: err.response.data?.inner || fallbackError.inner,
                name: err.response.data?.name || fallbackError.name,
                status: err.response.data?.status || fallbackError.status,
                code: err.response.data?.code || fallbackError.code,
                message: err.response.data?.message || fallbackError.message,
            };
        }

        return fallbackError;
    }

    return {
        details: { ...err },
        inner: {},
        name: err.name || UNKNOWN_ERROR_NAME,
        status: UNKNOWN_ERROR_STATUS,
        code: err.toString() || 'Unknown error',
        message: err.message || err.toString() || 'Unknown error type',
    };
};

export const getResourcePromiseErrors = (response?: AsyncResourcePromiseResult): AsyncResourceErrorMap | undefined => {
    if (!response) return undefined;

    if (isAxiosResponse(response)) {
        return response.data?.errors;
    }

    return response.asyncResource?.errors;
};

/**
 * Gets the fetched time for the data from the response.
 */
export const getResultFetchedTime = (response?: AsyncResourcePromiseResult): number | undefined => {
    if (!response) return undefined;

    if (isAxiosResponse(response)) {
        // Favour the 'fetchedTime' property in the response as this will allow routes to override
        // the default api header behaviour, if necessary
        if (response.data.fetchedTime) return response.data.fetchedTime;

        return getDataFetchedTimestamp(response);
    }

    return response.asyncResource?.fetchedTime;
};

/**
 * If an async resource doesn't specify an expiry time, default it to 30 minutes from now.
 */
export const getDefaultExpiryTimestamp = () => getTimestamp() + DEFAULT_ASYNC_RESOURCE_EXPIRY;

/**
 * Gets the expiry time for the data from the response.
 */
export const getResponseExpiry = (response?: AsyncResourcePromiseResult): number | undefined => {
    if (!response) return undefined;

    if (isAxiosResponse(response)) {
        // Favour the 'expiry' property in the response as this will allow routes to override
        // the default api header behaviour, if necessary
        if (response?.data?.expiry) return response.data.expiry;

        return getDataExpiryTimestamp(response);
    }

    return response.asyncResource?.expiry;
};

/**
 * Gets the IDs that the wrapped async function has already handled (if any).
 */
export const getResponseAlreadyHandledIds = (response?: AsyncResourcePromiseResult): string[] | undefined => {
    if (isAxiosResponse(response)) return;

    return response?.asyncResource?.alreadyHandledIds;
};

export const getErrorAlreadyHandledIds = (err: any): string[] | undefined => err?.asyncResource?.alreadyHandledIds;

export const getResourcePromiseResult = (response?: AsyncResourcePromiseResult): any | undefined => {
    if (!response) return undefined;

    if (isAxiosResponse(response)) return response.data;

    return response;
};

export const buildActionErrorArray = (errorMap?: AsyncResourceErrorMap): AsyncResourceEntityErrorObject[] | undefined =>
    reduce(
        errorMap,
        (acc, error, elementId) => {
            if (!error) {
                logger.warn('Async Resources: An error map was provided without an error object', {
                    errorMap,
                    elementId,
                });

                return acc;
            }

            acc.push(convertAsyncResourceResponseErrorToEntityError(error.error, elementId));

            return acc;
        },
        [] as AsyncResourceEntityErrorObject[],
    );

/**
 * Gets the IDs to fetch as an array, using the FALLBACK_ENTITY_ID if no ids are provided.
 */
export const getRequestedEntityIds = (ids?: string[] | string): string[] => {
    if (!ids) return [FALLBACK_ENTITY_ID];

    if (isArray(ids)) return ids;

    return [ids];
};

/**
 * Looks at the resource state to determine whether the ID needs to be fetched or is already being fetched.
 */
export const getIdsToFetch = (
    state: object,
    resourceName: ResourceTypes,
    fetchingIds: string[],
    forceFetch?: boolean,
): [shouldFetch: boolean, idsToFetch: string[]] => {
    if (forceFetch) return [true, fetchingIds];

    const localCacheHydrationTimestamp = getLocalCacheHydrationTimestamp(state);

    const idsToFetch = fetchingIds?.filter((id) => {
        const resourceState = getAsyncResourceEntityState(state, resourceName, id);
        return shouldFetchAsyncResource(resourceState, localCacheHydrationTimestamp);
    });

    return [!isEmpty(idsToFetch), idsToFetch];
};

/**
 * Checks to see if the wrapped function has already handled the resource state as part of this request
 * and filters it out if it has.
 */
export const filterResultActionIds = (resultActionIds: string[], alreadyHandledIds: string[] | undefined): string[] => {
    if (!alreadyHandledIds) return resultActionIds;

    return difference(resultActionIds, alreadyHandledIds);
};
