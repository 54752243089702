/**
 * NOTE: This file is separated from the elementToolListUtils because it's used
 * in the onboardingListenersConfig and we need to prevent the import of some of its dependencies.
 */
// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { asObject } from '../../../../common/utils/immutableHelper';
import {
    getUserPrimaryToolsSettings,
    getUserPrimaryToolsTouchSettings,
} from '../../../../common/users/utils/userPropertyUtils';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

// Types
import { ImMNUser, MNUser } from '../../../../common/users/userModelTypes';

// Constants
import { DEFAULT_PRIMARY_TOOLS, DEFAULT_PRIMARY_TOOLS_TOUCH } from '../config/primaryToolsConstants';

export type UserToolsPreference = {
    [key: string]: boolean | undefined;
};

export const getHasToolPreferenceEnabled = (toolsPreference: UserToolsPreference, toolName: string): boolean =>
    toolsPreference?.[toolName] === true;

export const getPrimaryToolPreference = isPlatformLegacyMobileApp(platformSingleton)
    ? getUserPrimaryToolsTouchSettings
    : getUserPrimaryToolsSettings;
const defaultTools = isPlatformLegacyMobileApp(platformSingleton) ? DEFAULT_PRIMARY_TOOLS_TOUCH : DEFAULT_PRIMARY_TOOLS;

/**
 * Gets the primary elements to show for the current user.
 */
export const getUserPrimaryElementTools = (currentUser: MNUser | ImMNUser): UserToolsPreference => {
    const primaryPreference: UserToolsPreference = asObject(getPrimaryToolPreference(currentUser)) || {};

    return {
        ...defaultTools,
        ...primaryPreference,
    };
};
