// Utils
import { isTask } from '../../../../common/elements/utils/elementTypeUtils';

export default (monitor) => {
    const { draggedElementIds, element } = monitor.getItem();

    // Can only drag one task at a time
    if (draggedElementIds?.length > 1) return false;

    return isTask(element);
};
