import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementTaskListIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={22}
        viewBox="0 0 32 22"
        className="Icon element-task-list"
        {...props}
    >
        <defs>
            <filter
                id="ElementTaskListIcon__a"
                width="131.2%"
                height="145.5%"
                x="-15.6%"
                y="-18.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <path
                id="ElementTaskListIcon__b"
                d="M0 1.991C0 .891.89 0 2.002 0h27.996C31.104 0 32 .89 32 1.991V20.01c0 1.1-.89 1.991-2.002 1.991H2.002A1.995 1.995 0 0 1 0 20.009V1.99z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use xlinkHref="#ElementTaskListIcon__b" fill="#000" filter="url(#ElementTaskListIcon__a)" />
            <use xlinkHref="#ElementTaskListIcon__b" fill="#FFF" />
            <path
                fill="#323B4A"
                d="M10.47 11.47a.75.75 0 0 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97zM26.245 13c.382 0 .698.28.748.648l.007.102a.75.75 0 0 1-.755.75h-11.49a.75.75 0 1 1 0-1.5h11.49zM10.47 5.47a.75.75 0 0 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97zM26.245 7c.382 0 .698.28.748.648L27 7.75a.75.75 0 0 1-.755.75h-11.49a.75.75 0 1 1 0-1.5h11.49z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementTaskListIcon);
export default Memo;
