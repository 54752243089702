// Constants
import { SELECTORS_CACHE_UPDATE } from './cacheConstants';

// Sub-reducers
import elementCountsMapCacheReducer, { ElementCountsMapCacheState } from './selectorsCacheElementCountsMapReducer';

// Types
import { AnyAction } from 'redux';
import { ELEMENTS_PURGE } from '../../../common/elements/elementsConstants';

type CacheType = {
    elementCountsMap: ElementCountsMapCacheState;
};
type StateType = CacheType | null;

export default (state: StateType = null, action: AnyAction) => {
    switch (action.type) {
        case ELEMENTS_PURGE:
            return {
                ...state,
                elementCountsMap: elementCountsMapCacheReducer(state?.elementCountsMap || {}, action),
            };
        case SELECTORS_CACHE_UPDATE:
            return {
                ...state,
                ...action.data,
                elementCountsMap: elementCountsMapCacheReducer(state?.elementCountsMap || {}, action),
            };
        default:
            return state;
    }
};
