import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarAddRowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-add-row"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#1B2536"
                fillOpacity={0.3}
                d="M25 24a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16zM12.501 12.5v-4H8.5v4h4.001zm0 11v-4H8.5v4h4.001zm0-5.5v-4H8.5v4h4.001zm5.5-5.5v-4h-4v4h4zm0 5.5v-4h-4v4h4zm0 5.5v-4h-4v4h4zm5.499-11v-4h-3.999v4H23.5zm0 5.5v-4h-3.999v4H23.5zm0 5.5v-4h-3.999v4H23.5z"
            />
            <path
                fill="#48505E"
                fillRule="nonzero"
                d="M25 24a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-6h18v6zm-12.499-.5v-4H8.5v4h4.001zm5.5 0v-4h-4v4h4zm5.499 0v-4h-3.999v4H23.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarAddRowIcon);
export default Memo;
