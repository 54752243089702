// Lib
import { createLogger, DEFAULT_LOG_STYLE } from '../../node_module_clones/redux-logger/src';

// Utils
import getClientConfig from '../utils/getClientConfig';

// Constants
import { DEBUG_LEVEL } from '../../../config/configConstants';
import { BATCH_ACTION_TYPE } from './reduxBulkingMiddleware';
import { COMPOSE_EMAIL_SET_EDITOR_STATE } from '../compose/composeModalConstants';
import { ELEMENT_UPDATE, ELEMENT_UPDATE_TYPE } from '../../common/elements/elementConstants';

// Types
import { AnyAction } from 'redux';
import { ClientConfig } from '../../common/config/configTypes';

const LOG_FILTER_ACTIONS = new Map();
LOG_FILTER_ACTIONS.set('redux-form/CHANGE', DEBUG_LEVEL.FINE);
LOG_FILTER_ACTIONS.set(COMPOSE_EMAIL_SET_EDITOR_STATE, DEBUG_LEVEL.FINE);

/**
 * Determines the actions that should be filtered from the logging middleware, based on the client's debug level.
 */
const getFilteredActions = (): string[] => {
    const config: Partial<ClientConfig> = getClientConfig();

    const filteredActions: string[] = [];

    for (const [actionName, logLevel] of LOG_FILTER_ACTIONS) {
        if (logLevel >= (config.debugLevel || 0)) {
            filteredActions.push(actionName);
        }
    }

    return filteredActions;
};

const isElementDiffUpdateRecoveryAction = (action: AnyAction): boolean =>
    action?.type === ELEMENT_UPDATE && action.updateType === ELEMENT_UPDATE_TYPE.DIFF_UPDATE_ERROR_RECOVERY;

const ACTION_NAME_STYLES = new Map();
ACTION_NAME_STYLES.set('RESOURCES_MARKED_AS_STALE', 'color: orange');
ACTION_NAME_STYLES.set('RESOURCES_FETCH_ERROR', 'color: red');
ACTION_NAME_STYLES.set('RESOURCES_', 'color: steelblue');
ACTION_NAME_STYLES.set('ELEMENT', 'color: #4bad63');

const getActionNameStyle = (action: AnyAction): string => {
    // On diff update errors, we want to highlight the error
    if (isElementDiffUpdateRecoveryAction(action)) return 'color: OrangeRed';

    for (const [actionPrefix, style] of ACTION_NAME_STYLES) {
        if (action.type.startsWith(actionPrefix)) return style;
    }

    return '';
};

/**
 * Builds the redux-logger middleware for the main store.
 */
export const createMainStoreLoggingMiddleware = () => {
    const filteredActions = getFilteredActions();

    return createLogger({
        // @ts-ignore Typescript can't determine that this function is allowed
        predicate: (getState: Function, action: AnyAction) => filteredActions.indexOf(action.type) === -1,
        collapsed: true,
        duration: true,
        titleFormatter: (action: AnyAction, time: number, took: number) => {
            const source = action.source ? `* ${action.source} * ` : '';

            const titleSuffix = `%c${source}(in ${took.toFixed(2)} ms)`;

            if (action.type === BATCH_ACTION_TYPE) {
                const actions = action.payload.map((a: AnyAction) => a.type).join(', ');
                return `%c batch action %c${actions} ${titleSuffix}`;
            }

            if (action.type?.startsWith('RESOURCES')) {
                return `%c action %c${action.type} - ${action.resource} ${titleSuffix}`;
            }

            if (action.type === ELEMENT_UPDATE && !!action.updateType) {
                return `%c action %c${action.type} - ${action.updateType} ${titleSuffix}`;
            }

            return `%c action %c${action.type} ${titleSuffix}`;
        },
        // Milanote custom option
        customStyles: {
            title: (action: AnyAction) => {
                const actionNameStyle = getActionNameStyle(action);
                return [DEFAULT_LOG_STYLE, actionNameStyle, DEFAULT_LOG_STYLE];
            },
        },
    });
};
