import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GestureUndoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={47}
        height={45}
        viewBox="0 0 47 45"
        className="Icon gesture-undo"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="M22 5h-7.164" />
            <path fill="#F37221" fillRule="nonzero" d="M15.618 2 11 5l4.618 3z" />
            <path
                fill="#323B4A"
                d="M10.759 17.212c-2.503 1.578-5.887 6.278-5.754 9.517.095 2.337-.558 5.316 7.06 12.268 4.17 3.803 8.745 2.618 11.338 1.46.165-.07.346-.155.542-.255a12.102 12.102 0 0 0 1.573-.937c1.888-1.226 4.034-2.896 4.568-3.366 1.708-1.516 2.506-3.685 3.047-5.017.611-1.502.783-3.9.094-4.641-.666-.715-.511-.333-1.805 1.141.134-.3.243-.583.333-.853.025-.047.055-.092.084-.137 2.52-4.144 5.047-8.287 7.562-12.434.394-.653.79-1.314 1.098-2.006.4-.901.025-1.47-.977-1.55-1.06-.088-1.987.293-2.742.992-.592.548-1.13 1.17-1.618 1.811-2.35 3.077-4.679 6.171-7.018 9.257a.627.627 0 0 1 .076-.164c2.265-3.463 4.576-6.893 6.78-10.39 1.219-1.933 2.306-3.952 3.366-5.974.507-.967.076-1.645-1.033-1.76-.523-.054-1.161-.045-1.585.205-.839.496-1.706 1.074-2.315 1.81-1.435 1.734-2.726 3.583-4.075 5.389-.802 1.07-4.638 6.053-5.436 7.127-.078-.064 2.889-4.03 2.812-4.094 1.231-1.874 2.485-3.738 3.685-5.63 1.013-1.594 2.005-3.197 2.928-4.841.578-1.026.188-1.763-.983-1.883-.604-.062-1.268.064-1.85.257-1.072.357-1.806 1.179-2.493 2.032-2.83 3.507-5.678 7-8.488 10.524-.94 1.178-1.977 2.246-3.275 3.013a5.44 5.44 0 0 1-1.04.47c-.366.12-.744.203-1.112.235 0 0 2.206-.98 3.779-2.835.566-.67 1.201-1.41 1.731-2.118.601-.811-4.04.337-8.857 3.377z"
            />
        </g>
    </svg>
);
const Memo = memo(GestureUndoIcon);
export default Memo;
