// Lib
import { useState, useRef } from 'react';

// Utils
import { shiftPathsToTop } from '../../drawingEditorUtils';
import { getFreehandPointOffset } from '../../perfectFreehandUtils';
import { getVectorMagnitude } from '../../../../../../common/maths/geometry/point';

const useDrawingEditorDragAndDropState = ({ paths, setPaths }) => {
    const [draggedPathIdsMap, setDraggedPathIds] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [isDragMove, setIsDragMove] = useState(false);
    const dragStartPoint = useRef();
    const transformGroupRef = useRef();

    const startDrag = (point, pathIdsMap) => {
        if (Object.keys(pathIdsMap).length < 1) return;

        setIsDragging(true);
        setDraggedPathIds(pathIdsMap);
        dragStartPoint.current = point;
    };

    const onDrag = (point) => {
        if (!isDragging) return;

        if (!isDragMove) setIsDragMove(true);

        if (!transformGroupRef.current) return;

        const offset = getFreehandPointOffset(dragStartPoint.current, point);

        transformGroupRef.current.setAttribute('transform', `translate(${offset.x}, ${offset.y})`);
    };

    const endDrag = (point) => {
        if (!isDragging || !dragStartPoint.current) return;

        setIsDragging(false);
        setIsDragMove(false);
        setDraggedPathIds({});

        const offset = getFreehandPointOffset(dragStartPoint.current, point);

        if (getVectorMagnitude(offset) < 1) return;

        const movedPaths = shiftPathsToTop(offset, paths, draggedPathIdsMap);
        setPaths(movedPaths);
    };

    return {
        draggedPathIdsMap,
        isDragging,
        isDragMove,
        startDrag,
        onDrag,
        endDrag,
        transformGroupRef,
    };
};

export default useDrawingEditorDragAndDropState;
