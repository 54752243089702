// Lib
import { isEmpty, flatten, compact, throttle } from 'lodash';

// Utils
import logger from '../../logger/logger';
import * as rectLib from '../../../common/maths/geometry/rect';
import { pixelsToGridPoints } from '../../utils/grid/gridUtils';
import { asObject, length, prop } from '../../../common/utils/immutableHelper';
import {
    isAnnotation,
    isCard,
    isCommentThread,
    isLine,
    isTaskLike,
} from '../../../common/elements/utils/elementTypeUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isFeatureEnabled, getUserPushCards } from '../../../common/users/utils/userPropertyUtils';
import { blockCollisionDetectionUntilNavigation, collisionDetectionManager } from './collisionDetectionManager';
import {
    getElementId,
    getElementLocation,
    getIsLineSnapped,
    getLineStartY,
    getLineEndY,
    getLineEndX,
    getLineStartX,
    isElementLocked,
    getElementType,
} from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getCurrentUser } from '../../user/currentUserSelector';
import { getCollisionElementOriginalLocationY } from './collisionSelector';
import { getElements } from '../../element/selectors/elementsSelector';
import { getIsRedoing, getIsUndoing, getMostRecentTransactionId } from '../../utils/undoRedo/undoRedoSelector';
import { isCurrentlyResizingElement } from '../../element/resizing/store/resizingSelector';
import {
    currentBoardCanvasElementsSelector,
    getCurrentVisibleBoardCanvasOrigin,
} from '../../element/selectors/currentBoardSelector';
import { getWorkspaceLoadIdleTimestamp, getPageLoadTimestamp } from '../../app/initialisation/initialisationSelector';

// Actions
import { moveMultipleElements } from '../../element/actions/elementMoveActions';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';
import { getTimestamp, TIMES } from '../../../common/utils/timeUtil';
import { ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

// If we have over this many canvas elements, don't check for collisions as it will be too expensive
const CANVAS_ELEMENT_COUNT_THRESHOLD = 150;

// The maximum grid points between two elements for them to be considered "connected"
const MAXIMUM_CONNECTED_ELEMENT_GAP = 3;

// We need to add padding to the card to account for the shadow, which makes it appear closer to the card below
const SHADOW_PADDING = 4;

const getBottom = prop('bottom');

/**
 * Gets the original gap between the element that changed height and this neighbour element,
 * before the element changed height.
 */
const getOriginalGap = (neighbourElementMeasurement, originalRect, gridSize) => {
    const originalGapPx = neighbourElementMeasurement.top - (originalRect.bottom + SHADOW_PADDING);
    return pixelsToGridPoints(originalGapPx, gridSize);
};

/**
 * Gets the space to use between two elements that are being pushed.
 * By default it uses the existing space in between the two elements, unless it's greater than
 * 3 grid points, in which case it will default to 2 grid points.
 */
const getElementMargin = (originalGap) => (originalGap > MAXIMUM_CONNECTED_ELEMENT_GAP ? 2 : originalGap);

/**
 * If we're shifting elements up, we need to make sure that they won't end up overlapping another element as
 * a result.
 */
const getMinimumIncrement = (
    heightDiff,
    elementMeasurement,
    neighbourElements,
    measurementsMap,
    gridSize,
    handledIds,
    maxIncrement,
) => {
    if (heightDiff > 0) return 0;

    // Check if there's any elements immediately above this element that haven't been moved yet
    const collisionRect = rectLib.asRect({
        // Note: heightDiff is negative here, so we just add it
        top: elementMeasurement.top + heightDiff - 2 * gridSize,
        left: elementMeasurement.left,
        right: elementMeasurement.right,
        bottom: elementMeasurement.top,
    });

    return neighbourElements.reduce((acc, neighbourElement) => {
        const neighbourElementId = getElementId(neighbourElement);

        const neighbourElementMeasurement = measurementsMap[neighbourElementId];

        if (rectLib.getArea(neighbourElementMeasurement) === 0) return acc;

        // Don't include the measurement of any elements that have just been added to the canvas
        if (!neighbourElementMeasurement || neighbourElementMeasurement.section !== BoardSections.CANVAS) return acc;

        // Don't allow lines to impact minimum space between other elements
        if (isLine(neighbourElement)) return acc;

        // If already overlapping, then don't check against it
        if (rectLib.isOverlapping(elementMeasurement, neighbourElementMeasurement)) return acc;

        if (!rectLib.isOverlapping(collisionRect, neighbourElementMeasurement)) return acc;

        if (handledIds.includes(neighbourElementId)) return acc;

        const gridPointsAbove =
            Math.round((neighbourElementMeasurement.bottom - elementMeasurement.top) / gridSize) + 1;

        return Math.min(Math.max(acc, gridPointsAbove), maxIncrement);
    }, -Infinity);
};

/**
 * Determines the previous element measurements to use for the element ID that's being checked for collisions.
 *
 * Most collision checks will have the previous measurements already inside the measurementsMap, however
 * if it's a new card (e.g. created via Cmd+Return) then we pretend its old measurements were just above
 * where the new card is now, so we can use the same algorithm to find new overlapped cards that need to
 * be pushed down.
 */
const getPreviousElementMeasurement = (
    elementId,
    elementMeasurement,
    isNewCard,
    measurementsMap,
    elements,
    gridSize,
) => {
    const previousMeasurement = prop(elementId, measurementsMap);

    if (!isNewCard || previousMeasurement) return previousMeasurement;

    const element = getElement(elements, elementId);

    // Don't bother checking overlaps unless the new element is a card, task list or comment thread
    if (!isCard(element) && !isTaskLike(element) && !isCommentThread(element)) return null;

    return {
        ...elementMeasurement,
        // Move the current measurement up 8 grid points
        ...rectLib.translate({ x: 0, y: -8 * gridSize }, elementMeasurement),
    };
};

const recursivelyCheckCollisions = (
    elementId,
    elementMeasurement,
    isNewCard,
    state,
    measurementsMap,
    user,
    // Recursive properties
    handledIds = [],
    cachedCanvasElements,
    cachedBelowElements,
) => {
    if (!elementId || !elementMeasurement) return;

    handledIds.push(elementId);

    const elements = getElements(state);
    const gridSize = getGridSize(state);

    const canvasOrigin = getCurrentVisibleBoardCanvasOrigin(state);
    const canvasOriginY = prop('y', canvasOrigin);

    // We only check for collisions if the element being edited is on the canvas
    const changedCanvasElement = getElement(elements, elementId);

    if (collisionDetectionManager.blocked) {
        logger.warn('Blocked collision recursion for %s (%s)', elementId, getElementType(changedCanvasElement));
    }

    if (!changedCanvasElement) return;

    const canvasElementId = getElementId(changedCanvasElement);

    const previousElementMeasurement = getPreviousElementMeasurement(
        elementId,
        elementMeasurement,
        isNewCard,
        measurementsMap,
        elements,
        gridSize,
    );

    // If the previous element size was 0 it must have been a file dropped onto the canvas, changing
    // its size to its real size. So just ignore it.
    if (!previousElementMeasurement || !previousElementMeasurement.width || !previousElementMeasurement.height) return;

    // The height of recursive pushed elements doesn't change, so we need to find the difference between
    // their initial bottom and current bottom
    const heightDiff = getBottom(elementMeasurement) - getBottom(previousElementMeasurement);

    if (heightDiff === 0) return;

    const { top, right, bottom, left } = elementMeasurement;

    const originalRect = previousElementMeasurement;

    const originalRectWithPadding = rectLib.asRect({
        ...originalRect,
        bottom: originalRect.bottom + gridSize * (MAXIMUM_CONNECTED_ELEMENT_GAP + 1),
    });

    const newRect = rectLib.asRect({
        top,
        left,
        right,
        bottom,
    });

    const newRectWithPadding = rectLib.asRect({
        ...newRect,
        bottom: newRect.bottom + gridSize * (MAXIMUM_CONNECTED_ELEMENT_GAP + 1),
    });

    const collisionRect = rectLib.asRect({
        top,
        left,
        right,
        bottom: bottom + gridSize * MAXIMUM_CONNECTED_ELEMENT_GAP,
    });

    // TODO It would be great if we could determine the padding that's used on the board, and then use
    //  the same padding to keep things separated
    // Only need to check elements below the current element if pushing down
    const canvasElements = cachedCanvasElements || currentBoardCanvasElementsSelector(state);
    const neighbourElements = cachedBelowElements || canvasElements;

    // If we've got over CANVAS_ELEMENT_COUNT_THRESHOLD then don't check for collisions as the performance
    // will be too bad
    const overCanvasThreshold =
        length(neighbourElements) > CANVAS_ELEMENT_COUNT_THRESHOLD &&
        !isFeatureEnabled(ExperimentId.disableAutoShiftLimit, user);
    if (overCanvasThreshold) return [];

    const moves = [];
    const shiftedElementsDetails = [];

    const belowElements = [];
    let escapeHatch = false;

    neighbourElements.forEach((neighbourElement) => {
        if (escapeHatch) return;

        // Don't shift neighbour elements
        if (isElementLocked(neighbourElement)) return;
        if (isAnnotation(neighbourElement)) return;

        const neighbourElementId = getElementId(neighbourElement);

        if (neighbourElementId === canvasElementId) return;

        // Don't shift lines - unless they're horizontal
        const isNonHorizontalLine =
            isLine(neighbourElement) &&
            (getIsLineSnapped(neighbourElement) || getLineStartY(neighbourElement) !== getLineEndY(neighbourElement));

        if (isNonHorizontalLine) return;

        let neighbourElementMeasurement = measurementsMap[neighbourElementId];

        if (rectLib.getArea(neighbourElementMeasurement) === 0) {
            logger.warn('Neighbour element has no area', neighbourElement, neighbourElementMeasurement);
            return;
        }

        // If the element is a horizontal
        if (isLine(neighbourElement) && neighbourElementMeasurement) {
            const startX = getLineStartX(neighbourElement);
            const endX = getLineEndX(neighbourElement);

            const width = endX - startX;
            const widthPx = width * gridSize;

            neighbourElementMeasurement = {
                ...neighbourElementMeasurement,
                right: neighbourElementMeasurement.left + widthPx,
                bottom: neighbourElementMeasurement.top + 12,
                width: widthPx,
                height: 12,
            };

            // Only check overlapping elements if we're not checking a line
            // NOTE: New cards made by Cmd+return will always overlap a previous card when using the
            //  "originalRect" because the originalRect is faked to be 8 grid points above where it
            //  really is, so that the collision algorithm can work correctly. So don't check these
            //  for overlaps
        } else if (!isNewCard && rectLib.isOverlapping(originalRect, neighbourElementMeasurement)) {
            escapeHatch = true;
            return;
        }

        // Need to make sure the previous measurement wasn't taken when the element was in a different section.
        // In that case we wouldn't want to move the element as it was only recently moved
        if (!neighbourElementMeasurement || neighbourElementMeasurement.section !== BoardSections.CANVAS) return;

        if (neighbourElementMeasurement.top >= originalRect.bottom) belowElements.push(neighbourElement);

        // If the height has grown, and it didn't used to be too close to the element below, but it is now, shift down
        const shouldShiftDown =
            heightDiff > 0 &&
            !rectLib.isOverlapping(originalRect, neighbourElementMeasurement) &&
            rectLib.isOverlapping(collisionRect, neighbourElementMeasurement);

        // If the height has shrunk and it used to be close to the element below, but it isn't anymore, shift up
        const shouldShiftUp =
            heightDiff < 0 &&
            rectLib.isOverlapping(originalRectWithPadding, neighbourElementMeasurement) &&
            !rectLib.isOverlapping(newRect, neighbourElementMeasurement);

        // If the element went from "not connected" to "connected" then we need to save its original Y
        // position, so if we start shifting elements up again they won't go past their original position
        const isNowConnected =
            heightDiff > 0 &&
            !rectLib.isOverlapping(originalRectWithPadding, neighbourElementMeasurement) &&
            rectLib.isOverlapping(newRectWithPadding, neighbourElementMeasurement);

        if (!shouldShiftDown && !shouldShiftUp) {
            // Add a fake move even with the appropriate top Y properties so we can return to
            // where it started, if required
            if (isNowConnected) {
                const location = asObject(getElementLocation(neighbourElement));

                moves.push({
                    id: neighbourElementId,
                    location,
                    from: location,
                    autoPush: true,
                    saveOriginalLocation: true,
                    noSync: true,
                });
            }

            return;
        }

        handledIds.push(neighbourElementId);

        const originalGap = getOriginalGap(neighbourElementMeasurement, originalRect, gridSize);
        const elementMargin = getElementMargin(originalGap);

        const maxIncrement = shouldShiftUp ? 0 : Infinity;

        // If we're shifting elements up, we need to make sure we don't overlap other elements above
        const minIncrement = getMinimumIncrement(
            heightDiff,
            neighbourElementMeasurement,
            canvasElements,
            measurementsMap,
            gridSize,
            handledIds,
            maxIncrement,
        );

        let increment =
            Math.round((bottom + SHADOW_PADDING) / gridSize) +
            elementMargin -
            pixelsToGridPoints(neighbourElementMeasurement.top, gridSize);
        increment = Math.round(Math.max(minIncrement, increment));

        const location = asObject(getElementLocation(neighbourElement));

        if (!location) return;

        const newLocation = {
            ...location,
            position: {
                ...location.position,
            },
        };

        // The element wasn't "connected" but it now is, so we want to save its original location
        const saveOriginalLocation = shouldShiftDown && originalGap > MAXIMUM_CONNECTED_ELEMENT_GAP;

        // If shifting up, we don't want to go higher than the original Y
        const minimumY =
            (shouldShiftUp && getCollisionElementOriginalLocationY(state, { elementId: neighbourElementId })) ||
            // Can't go beyond the canvas origin
            canvasOriginY;

        // Use the measurements to determine the current grid point rather than element.position.y,
        // so that moves that are out of sync with measurements updates don't cause incorrect locations
        const updatedY = pixelsToGridPoints(neighbourElementMeasurement.top, gridSize) + increment + canvasOriginY;
        newLocation.position.y = Math.max(updatedY, minimumY);

        if (collisionDetectionManager.blocked) {
            const direction = shouldShiftUp ? 'up' : 'down';

            logger.warn(
                '- Collision between %s (%s) and %s (%s) - shifting %s by %s grid points',
                elementId,
                getElementType(changedCanvasElement),
                neighbourElementId,
                getElementType(neighbourElement),
                direction,
                increment,
                newRect,
                neighbourElementMeasurement,
            );
        }

        moves.push({
            id: neighbourElementId,
            location: newLocation,
            from: location,
            autoPush: true,
            saveOriginalLocation,
        });

        // In case the top is limited - we have to stop there
        const newTop = (newLocation.position.y - canvasOriginY) * gridSize;
        const newBottom = newTop + neighbourElementMeasurement.height;

        // Update the location of the shifted element and test it against other canvas elements
        shiftedElementsDetails.push({
            elementId: neighbourElementId,
            elementMeasurement: rectLib.asRect({
                ...neighbourElementMeasurement,
                top: newTop,
                bottom: newBottom,
            }),
        });
    });

    if (escapeHatch) return [];

    return compact(
        flatten([
            ...shiftedElementsDetails.map((details) =>
                recursivelyCheckCollisions(
                    details.elementId,
                    details.elementMeasurement,
                    // Any recursive card we check is not a new card
                    false,
                    state,
                    measurementsMap,
                    user,
                    handledIds,
                    canvasElements,
                    belowElements,
                ),
            ),
            moves,
        ]),
    );
};

/**
 * If an element is attempted to be moved multiple times, choose the one that moves it the most.
 */
const removeDuplicateMoves = (moves) => {
    const movesMap = {};

    moves.forEach((move) => {
        const { id, location } = move;

        if (movesMap[id] && movesMap[id].location.position.y > location.position.y) return;

        movesMap[id] = move;
    });

    return Object.values(movesMap);
};

/**
 * Don't check for collisions if we're within 5 seconds of the page load or 1 second of the page load idle.
 */
const isReadyToCheckCollisions = (state) => {
    const pageLoadTimestamp = getPageLoadTimestamp(state);
    const workspaceLoadIdleTime = getWorkspaceLoadIdleTimestamp(state);

    if (!pageLoadTimestamp || !workspaceLoadIdleTime) return false;
    if (getTimestamp() - pageLoadTimestamp < TIMES.SECOND * 5) return false;
    if (getTimestamp() - workspaceLoadIdleTime < TIMES.SECOND) return false;

    return true;
};

// Max 15 in two seconds
const MAX_COLLISION_MOVE_COUNT = 15;
const COLLISION_THROTTLE_PERIOD = TIMES.SECOND;
let collisionMoveCount = 0;

const clearCollisionCount = throttle(() => {
    collisionMoveCount = 0;
}, COLLISION_THROTTLE_PERIOD);

/**
 * Checks for collisions when an element changes its height and shifts collided with it.
 */
export const checkCollisions =
    ({ elementId, elementMeasurement, currentMeasurements, isNewCard }) =>
    (dispatch, getState) => {
        // Don't attempt any collision checking if we're blocking it
        if (collisionDetectionManager.blocked) return;

        const state = getState();

        const currentUser = getCurrentUser(state);

        if (!isReadyToCheckCollisions(state)) return;

        if (!getUserPushCards(currentUser)) return;

        const isResizing = isCurrentlyResizingElement(state);
        const isUndoing = getIsUndoing(state);
        const isRedoing = getIsRedoing(state);

        if (isResizing || isUndoing || isRedoing) return;

        let moves = recursivelyCheckCollisions(
            elementId,
            elementMeasurement,
            isNewCard,
            state,
            currentMeasurements,
            currentUser,
        );

        if (isEmpty(moves)) return;

        collisionMoveCount++;

        // If too many collision events occur in quick succession we're in an error state so we should
        // bail on further collisions until the user changes their board
        if (collisionMoveCount >= MAX_COLLISION_MOVE_COUNT) {
            blockCollisionDetectionUntilNavigation();

            // Perform one more collision check - but this time logging is enabled so we can try to debug
            // what's going wrong
            recursivelyCheckCollisions(
                elementId,
                elementMeasurement,
                isNewCard,
                state,
                currentMeasurements,
                currentUser,
            );

            return;
        }

        clearCollisionCount();

        moves = removeDuplicateMoves(moves);

        const mostRecentTransactionId = getMostRecentTransactionId(state);

        const shouldSync = moves.every((move) => !move.noSync);

        dispatch(
            moveMultipleElements({
                moves,
                transactionId: mostRecentTransactionId,
                sync: shouldSync,
                moveOperation: ELEMENT_MOVE_OPERATIONS.COLLISION,
            }),
        );
    };
