// Lib
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { flow } from 'lodash/fp';

// Utils
import { getPathStroke, round2 } from './drawingEditorUtils';
import { getX, getY } from '../../../../common/maths/geometry/dimensionUtil';
import { roundFreehandPoint, scaleFreehandPoint, translateFreehandPoint } from './perfectFreehandUtils';
import {
    getDrawingData,
    getWidth,
    getXPosition,
    getYPosition,
} from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import DrawingSvgRenderer from './drawingEditorCanvas/DrawingSvgRenderer';

export const translateAndRoundFreehandPoint = (translation) =>
    flow([translateFreehandPoint(translation), roundFreehandPoint]);

const scaleTranslateAndRoundFreehandPoint = (scale, translation) =>
    flow([scaleFreehandPoint(scale), translateFreehandPoint(translation), roundFreehandPoint]);

export const getDrawingElementContent = ({ paths, boundingRect }) => {
    if (!paths.length) return {};

    const { top, left, width, height } = boundingRect;

    // Offsets each point so their coordinates will be from the top left point of the
    // combined path bounding rect, rather than the drawing editor's top left point
    const translateAndRound = translateAndRoundFreehandPoint({ x: -left, y: -top });

    const translatedPaths = paths.map(({ points, stroke /* remove stroke from saved data */, ...rest }) => ({
        ...rest,
        points: points.map(translateAndRound),
    }));

    const svg = ReactDOMServer.renderToStaticMarkup(
        <DrawingSvgRenderer paths={translatedPaths} width={width} height={height} />,
    );

    return {
        paths: translatedPaths,
        svg,
        width,
        height,
    };
};

export const getPathsFromElementContent = ({ drawing, offset, scale }) => {
    const scaleTranslateAndRound = scaleTranslateAndRoundFreehandPoint(scale, offset);

    const translatedPaths = drawing.paths.map(({ points, size, ...rest }) => {
        const scaledSize = size * scale;

        const scaledPoints = points.map(scaleTranslateAndRound);

        const stroke = getPathStroke({
            ...rest,
            points: scaledPoints,
            size: scaledSize,
        });

        return {
            ...rest,
            size: round2(size * scale),
            points: scaledPoints,
            stroke,
        };
    });

    return translatedPaths;
};

export const createInitialPaths = ({ editedDrawingElement, gridSize, canvasTranslation, canvasOrigin }) => {
    if (!editedDrawingElement) return [];

    const element = editedDrawingElement.toJS();

    const width = getWidth(element);
    const drawing = getDrawingData(element);

    if (!drawing) return [];

    const offset = {
        left: (getXPosition(element) - getX(canvasOrigin)) * gridSize - canvasTranslation.x,
        top: (getYPosition(element) - getY(canvasOrigin)) * gridSize - canvasTranslation.y,
    };

    const scale = (width * gridSize - 2) / drawing.width;

    return getPathsFromElementContent({
        drawing,
        offset,
        scale,
    });
};
