const APPLICATION_MODE = {
    development: 'development',
    test: 'test',
    production: 'production',
};

const DEBUG_LEVEL = {
    OFF: 0,
    // Always show errors
    ERROR: 0,
    WARN: 100,
    INFO: 200,
    FINE: 300,
    FINER: 400,
    FINEST: 500,
};

const ENV = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
};

const SERVER_TYPE = {
    development: 'development',
    staging: 'staging',
    production: 'production',
    test: 'test',
};

const ROLE_TYPE = {
    worker: 'Worker',
    app: 'App',
    media: 'Media',
    migration: 'Migration',
    notifications: 'Notifications',
    collab: 'Collab',
    search: 'Search-index-worker',
};

module.exports = {
    APPLICATION_MODE,
    DEBUG_LEVEL,
    ENV,
    SERVER_TYPE,
    ROLE_TYPE,
};
