// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TaskPillPopup from './TaskPillPopup';
import TaskAssignmentPopupContent from './TaskAssignmentPopupContent';

// Styles
import './TaskAssignmentPopup.scss';

const TaskAssignmentPopup = (props) => (
    <TaskPillPopup {...props} className="TaskAssignmentPopup">
        <TaskAssignmentPopupContent {...props} />
    </TaskPillPopup>
);

TaskAssignmentPopup.propTypes = {
    popupId: PropTypes.string.isRequired,
    isPopupOpen: PropTypes.bool,
    dispatchClosePopup: PropTypes.func,
};

export default TaskAssignmentPopup;
