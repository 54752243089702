// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from '../../../../../../node_module_clones/recompose';

// Utils
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';

import fileGetMediaPlayerType from '../../../../../element/file/utils/fileGetMediaPlayerType';
import { mediaToolSelector } from './mediaToolSelector';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Actions
import { toggleLinkMedia } from '../../../../../element/link/linkActions';
import { mediaPlayerReset } from '../../../../../element/file/mediaPlayer/mediaPlayerActions';

/**
 * Toggles the caption on and off whenever the caption tool is clicked by either changing the image element to be
 * currently editing (which by default will put the cursor into the field and show the caption), or deleting the
 * caption text and changing the image to not be editing so that the caption will no longer have focus.
 */
const mapDispatchToProps = (dispatch) => ({
    dispatchToggleMedia: (isActive) => {
        dispatch(toggleLinkMedia(isActive));
    },
    resetMediaPlayer: (playerId) => {
        dispatch(mediaPlayerReset({ playerId }));
    },
});

const handlers = {
    onPointerDown:
        ({ dispatchToggleMedia, isActive, selectedElements, resetMediaPlayer }) =>
        (event) => {
            event.preventDefault();

            if (isActive) {
                const mediaPlayerIds = selectedElements
                    .filter((element) => !!fileGetMediaPlayerType(element))
                    .map(getElementId);

                mediaPlayerIds.forEach((playerId) => {
                    resetMediaPlayer(playerId);
                });
            }

            dispatchToggleMedia(isActive);
        },
};

const AttachmentTool = (props) => {
    const { showTool, isActive, displayName, selectedElements } = props;

    if (!showTool) return null;

    const mediaIconName = !!fileGetMediaPlayerType(selectedElements.first())
        ? 'toolbar-media-preview'
        : 'toolbar-image-preview';

    return (
        <ToolbarTool {...props} name={displayName} isActive={isActive}>
            <Icon name={mediaIconName} />
        </ToolbarTool>
    );
};

AttachmentTool.propTypes = {
    selectedElements: PropTypes.object,
    showTool: PropTypes.bool,
    isActive: PropTypes.bool,
    displayName: PropTypes.string,
    dispatchToggleMedia: PropTypes.func,
};

const enhancer = compose(connect(mediaToolSelector, mapDispatchToProps), withHandlers(handlers));

export default enhancer(AttachmentTool);
