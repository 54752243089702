import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const UtilZoomToFitIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon util-zoom-to-fit"
        {...props}
    >
        <path
            fill="#666D7A"
            fillRule="nonzero"
            d="M11 13v6h-1v-4.293l-6.146 6.147-.708-.708L9.293 14 5 14v-1h6Zm9.146-9.854.708.708-6.147 6.145L19 10v1h-6V5h1v4.293l6.146-6.147Z"
        />
    </svg>
);
const Memo = memo(UtilZoomToFitIcon);
export default Memo;
