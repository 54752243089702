import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Components
import IconComponent from './IconComponent';

// Selectors
import { getThemeIsDarkMode } from '../../user/account/accountModal/interface/themeSettings/themeSelector';
import getGridSize from '../../utils/grid/gridSizeSelector';

// Constants
import { GRID } from '../../utils/grid/gridConstants';
import { ICON_SIZES } from './iconConstants';

const iconSelector = createStructuredSelector({
    gridSize: getGridSize,
    size: (state, props) => props.size,
    isDarkMode: getThemeIsDarkMode,
});

const getIconSizeForGridSize = (gridSize) => {
    switch (gridSize) {
        case GRID.SMALL.size:
            return ICON_SIZES.SMALL;
        case GRID.MEDIUM.size:
            return ICON_SIZES.MEDIUM;
        case GRID.LARGE.size:
        default:
            return ICON_SIZES.LARGE;
    }
};

const Icon = ({ isDarkMode, name, gridSize, size }) => {
    return (
        <IconComponent
            isDarkMode={isDarkMode}
            name={name}
            gridSize={gridSize}
            getIconSizeForGridSize={getIconSizeForGridSize}
            overrideSize={size}
        />
    );
};

Icon.propTypes = {
    isDarkMode: PropTypes.bool,
    name: PropTypes.string,
    gridSize: PropTypes.number,
    size: PropTypes.string,

    getIconSizeForGridSize: PropTypes.func,
};

export default connect(iconSelector)(Icon);
