// Lib
import { difference, negate, isNull, isUndefined } from 'lodash/fp';

// Utils
import { getMany } from '../../utils/immutableHelper';
import { getClosestAncestorBoardId, getElement } from './elementTraversalUtils';
import { getElementId, getLinkedElementId, getLocationParentId, getPhysicalId } from './elementPropertyUtils';
import { isAlias, isBoard, isColumn, isContainer } from './elementTypeUtils';
import { getAllIdsStartingFrom, getChildIds, getDescendantIds } from '../../dataStructures/graphUtils';
import { filterAndSortElementsInSection } from './elementSortUtils';
import { isLocationCanvas, isLocationInbox } from './elementLocationUtils';

// WARNING: DO NOT USE THESE FUNCTIONS FOR SELECTORS.
// They can potentially take a while to execute & considering how many times selectors can invoke these methods
// It's better to use the optimised utilities in "elementGraphSelectorUtils.js"

export const buildBoardVisibleElementGraph = ({ elements }) => {
    // Performance improvement.  First get all the boardIds so we can short circuit more expensive checks
    const boardIds = elements.reduce((boardIdsMap, element) => {
        if (isBoard(element)) {
            boardIdsMap[getElementId(element)] = true;
        }
        return boardIdsMap;
    }, {});

    return elements.reduce((boardVisibleElementGraph, element) => {
        const parentId = getLocationParentId(element);

        const elementId = getElementId(element);
        const immediateParentIsBoard = boardIds[parentId];
        const parentBoardId = immediateParentIsBoard ? parentId : getClosestAncestorBoardId(elements, elementId);

        // If the parent elements haven't been retrieved yet, don't add this child to the map
        if (isUndefined(parentBoardId)) return boardVisibleElementGraph;

        boardVisibleElementGraph[parentBoardId] = boardVisibleElementGraph[parentBoardId] || [];
        boardVisibleElementGraph[parentBoardId].push(elementId);

        return boardVisibleElementGraph;
    }, {});
};

/**
 * Builds an entire graph structure representation of the elements passed in - only using physical parent IDs.
 */
export const buildEntireElementGraph = ({ elements }) =>
    elements.reduce((elementGraph, element) => {
        const parentId = getLocationParentId(element);
        elementGraph[parentId] = elementGraph[parentId] || [];
        elementGraph[parentId].push(getElementId(element));
        return elementGraph;
    }, {});

/**
 * Builds a graph of the elements, replacing Aliases with their physical linked boards.
 * This can mean that a physical ID will be the child of multiple parents.
 */
export const buildVirtualElementGraph = ({ elements }) =>
    elements.reduce((elementGraph, element) => {
        const parentId = getLocationParentId(element);
        elementGraph[parentId] = elementGraph[parentId] || [];
        elementGraph[parentId].push(getPhysicalId(element));
        return elementGraph;
    }, {});

// FIXME Creating an immutable structure as this is currently only used on the client side
export const getChildrenViaGraph = ({ elements, elementGraph, parentId }) => {
    const childrenIds = getChildIds(elementGraph, parentId);
    return getMany(childrenIds, elements);
};

export const getCanvasElements = ({ elements, elementGraph, parentId }) =>
    getChildrenViaGraph({ elements, elementGraph, parentId }).filter(isLocationCanvas);
export const getInboxElements = ({ elements, elementGraph, parentId }) =>
    getChildrenViaGraph({ elements, elementGraph, parentId }).filter(isLocationInbox);

export const getSortedChildrenInSectionViaGraph = ({ elements, elementGraph, parentId, section, sortFn }) =>
    filterAndSortElementsInSection({
        elements: getChildrenViaGraph({ elements, elementGraph, parentId }),
        sortFn,
        section,
    });

/**
 * This will replace aliases with the physical boards they link to.
 * This is used to help determine which socket channels should be connected to.
 */
export const getPhysicalChildrenIdsViaGraph = ({ elements, elementGraph, parentId }) =>
    getChildrenViaGraph({ elements, elementGraph, parentId }).map(getPhysicalId).toSeq().toArray();

// Siblings
/**
 * Returns the siblings of the specified element including the element (so its index is known).
 */
export const getSiblingsAndSelfViaGraph = ({ elements, elementGraph, elementId, sortFn }) => {
    const element = getElement(elements, elementId);
    const children = getChildrenViaGraph({ elements, elementGraph, parentId: getLocationParentId(element) });
    return sortFn ? children.sort(sortFn) : children;
};

export const getPreviousSiblingViaGraph = ({ elements, elementGraph, elementId, sortFn }) => {
    const element = getElement(elements, elementId);
    const siblingsAndSelf = getSiblingsAndSelfViaGraph({ elements, elementGraph, elementId, sortFn }).toList();

    const elementIndex = siblingsAndSelf.indexOf(element);
    return elementIndex > 0 ? siblingsAndSelf.get(elementIndex - 1) : null;
};

export const getDescendantsViaGraph = ({ elements, elementGraph, parentId }) => {
    const descendantIds = getDescendantIds(elementGraph, parentId);
    return getMany(descendantIds, elements);
};
export const getElementsStartingFrom = ({ elements, elementGraph, startElementId }) => {
    const relevantElementIds = getAllIdsStartingFrom(elementGraph, startElementId);
    return getMany(relevantElementIds, elements);
};

/**
 * Returns a simple graph structure representation for the element list in the form of:
 * {
 *    ID: [ CHILD_ID, CHILD_ID ],
 *    ...
 * }
 */
export const buildOrderedSubGraph = ({
    elements,
    elementGraph,
    startElementId,
    visited = {},
    graph = {},
    elementSortFn,
}) => {
    if (visited[startElementId]) return graph;

    const element = getElement(elements, startElementId);

    if (!element) return graph;

    let children = getChildrenViaGraph({
        elements,
        elementGraph,
        parentId: startElementId,
    });
    children = elementSortFn ? children.sort(elementSortFn) : children;
    const childrenIds = children.map(getElementId).filter(negate(isNull)).toArray();

    visited[startElementId] = true;
    graph[startElementId] = childrenIds;

    // NOTE: This is currently only used by task methods, and does not need to check attached elements
    //  so the "isContainer" logic makes sense. This should be revisited if the assumption no longer holds.
    // Optimisation to prevent filtering the element list unnecessarily
    const containerChildrenIds = children.filter(isContainer).map(getElementId).filter(negate(isNull)).toArray();

    const nonContainerChildrenIds = difference(childrenIds, containerChildrenIds);
    nonContainerChildrenIds.forEach((childId) => {
        graph[childId] = [];
    });

    return containerChildrenIds.reduce(
        (accGraph, childId) =>
            buildOrderedSubGraph({
                elements,
                elementGraph,
                startElementId: childId,
                visited,
                graph,
                elementSortFn,
            }),
        graph,
    );
};

export const buildPhysicalIdToVirtualIdMap = (elements) => {
    const linkedIdToParentIdsMap = {};
    const physicalBoardIdMap = {};
    const columnIdToParentBoardIdMap = {};
    const elementIdToVirtualParentIdsMap = {};

    // First:
    //  - Map all column IDs to their parent board IDs
    //  - Find the board IDs that are linked to by aliases and associate the alias's parent ID to that board
    //  - Keep track of boards that are part of the physical tree, as we don't want to change to an aliases parent ID
    //      in this case
    elements.forEach((element) => {
        if (isColumn(element)) {
            const columnId = getElementId(element);
            // If the parent ID of an element is a column, map that ID to the column's parent which will be a board.
            columnIdToParentBoardIdMap[columnId] = getLocationParentId(element);
        } else if (isAlias(element)) {
            const linkedBoardId = getLinkedElementId(element);
            linkedIdToParentIdsMap[linkedBoardId] = linkedIdToParentIdsMap[linkedBoardId] || [];
            const aliasParentId = getLocationParentId(element);
            linkedIdToParentIdsMap[linkedBoardId].push(aliasParentId);
        } else if (isBoard(element)) {
            const elementId = getElementId(element);
            physicalBoardIdMap[elementId] = true;
        }
    });

    // Then loop through each element again:
    //  - If the element does not have a mapped parent ID ignore it
    //  - If it does have a mapped parent ID then check if the original parent ID is part of the physical tree
    //      - If it is then we don't want to map it to the alias's parent ID
    elements.forEach((element) => {
        const elementId = getElementId(element);
        const mappedAliasParentIds = linkedIdToParentIdsMap[elementId];

        if (!mappedAliasParentIds) return;

        // If the parent of this board is in the physical tree, then include it in the parents list
        const parentId = getLocationParentId(element);
        if (physicalBoardIdMap[parentId]) mappedAliasParentIds.push(parentId);

        elementIdToVirtualParentIdsMap[elementId] = mappedAliasParentIds;
    });

    return {
        columnIdToParentBoardIdMap,
        elementIdToVirtualParentIdsMap,
    };
};
