// Lib
import { createSelector } from 'reselect';
import { toLower } from 'lodash/fp';

// Utils
import { getMany, prop } from '../../../common/utils/immutableHelper';
import { getEmail, userIsRegistered } from '../../../common/users/userHelper';
import { getMilanoteUserId } from './userContactsUtils';

// Selectors
import { getUsers } from '../usersSelector';

// State selectors
export const getUserContacts = (state) => state.getIn(['app', 'userContacts', 'contacts']);

export const getUserContactsByEmail = createSelector(getUserContacts, (contacts) =>
    contacts.reduce((contactsMap, contact) => {
        contactsMap[toLower(getEmail(contact))] = contact;
        return contactsMap;
    }, {}),
);

export const getUserContactsByMilanoteId = createSelector(getUserContacts, (contacts) =>
    contacts.reduce((contactsMap, contact) => {
        const milanoteUserId = getMilanoteUserId(contact);
        contactsMap[milanoteUserId] = contact;
        return contactsMap;
    }, {}),
);

export const getUserContactsMilanoteUserIds = (state) => state.getIn(['app', 'userContacts', 'userIds']);
export const getUserContactsMilanoteUsers = createSelector(getUserContactsMilanoteUserIds, getUsers, getMany);

export const getRegisteredContacts = createSelector(getUserContactsMilanoteUsers, (users) =>
    users.filter(userIsRegistered),
);

// Property accessors
export const getContactId = prop('_id');
export const getContactType = prop('contactType');
