import DefaultBlockquote from '@tiptap/extension-blockquote';

export const Blockquote = DefaultBlockquote.extend({
    content: 'paragraph+',

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            // Keeping the original Tiptap shortcut, in case it's standard
            'Mod-Shift-b': () => this.editor.commands.toggleBlockquote(),
            "Mod-'": () => this.editor.commands.toggleBlockquote(),
            'Mod-"': () => this.editor.commands.toggleBlockquote(),
        };
    },
});
