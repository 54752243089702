import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-link"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFF"
                d="m14.46 1.54.172.178a5.256 5.256 0 0 1-.171 7.253c-.62.62-1.608.651-2.265.092a5.268 5.268 0 0 1-1.362 3.526l-.174.183-1.689 1.689a5.256 5.256 0 0 1-7.432-7.432 1.673 1.673 0 0 1 2.265-.092 5.268 5.268 0 0 1 1.362-3.526l.174-.183L7.029 1.54a5.256 5.256 0 0 1 7.432 0z"
            />
            <path
                fill="#767C86"
                d="M9.003 6.047a.672.672 0 0 1 .95 0 4.255 4.255 0 0 1 0 6.018l-1.69 1.689a4.256 4.256 0 0 1-6.017-6.018.672.672 0 0 1 .95.95 2.912 2.912 0 0 0 4.118 4.118l1.689-1.69a2.91 2.91 0 0 0 0-4.117.672.672 0 0 1 0-.95zm-1.267-3.8a4.255 4.255 0 0 1 6.018 6.017.672.672 0 0 1-.95-.95 2.912 2.912 0 0 0-4.118-4.118l-1.689 1.69a2.91 2.91 0 0 0 0 4.117.672.672 0 0 1-.95.95 4.255 4.255 0 0 1 0-6.018z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationLinkIcon);
export default Memo;
