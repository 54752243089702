// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import { stopPropagationOnly } from '../../../utils/domUtil';

// Selectors
import { activePopupSelector } from '../../../components/popupPanel/popupOpenSelector';
import { getGridSize } from '../../../utils/grid/gridSizeSelector';

// Actions
import { closePopup } from '../../../components/popupPanel/popupActions';

// Components
import PopupPanelMouseManager from '../../../components/popupPanel/PopupPanelMouseManager';
import CardCloneInstancesPopupContent from './CardCloneInstancesPopupContent';
import FloatingPanel from '../../../components/tooltips/FloatingPanel';

// Constants
import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';

// Styles
import './CardCloneInstancesPopup.scss';

const mapStateToProps = createSelector(
    activePopupSelector,
    (state, ownProps) => ownProps.popupId,
    getGridSize,
    (popupState, popupId, gridSize) => ({
        isPopupOpen: popupState.has(popupId),
        gridSize,
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchClosePopup: (popupId) => dispatch(closePopup(popupId, false)),
});

const CardCloneInstancesPopup = (props) => {
    const { className, popupId, isPopupOpen, dispatchClosePopup, gridSize } = props;

    if (!isPopupOpen) return null;

    const closeThisPopup = () => dispatchClosePopup(popupId);

    return (
        <FloatingPanel
            selector={`.popup-trigger-${popupId}`}
            className={classNames('CardCloneInstancesPopup', className, 'light')}
            arrowColor="var(--ui-modal-background-primary)"
            arrowStrokeColor="var(--ui-background-primary)"
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={0}
            distance={10}
            offset={0}
            position={TooltipPositions.BOTTOM}
            gridSize={gridSize}
            responsive
            pollPosition
        >
            <PopupPanelMouseManager {...props} visible={isPopupOpen} popupId={popupId} closePopup={closeThisPopup}>
                <div className="popup-content" onClick={stopPropagationOnly}>
                    <CardCloneInstancesPopupContent {...props} />
                </div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

CardCloneInstancesPopup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    popupId: PropTypes.string.isRequired,
    isPopupOpen: PropTypes.bool,
    dispatchClosePopup: PropTypes.func,
    gridSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCloneInstancesPopup);
