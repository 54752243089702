// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from '../../../../../node_module_clones/react-transition-group';
import classNames from 'classnames';
import { get } from 'lodash/fp';
import { Capacitor } from '@capacitor/core';

// Utils
import platformSingleton from '../../../../platform/platformSingleton';
import { isPlatformIpad } from '../../../../platform/utils/platformDetailsUtils';

// Components
import Icon from '../../../../components/icons/Icon';
import StyledButton from '../../../../components/buttons/StyledButton';
import DrawingEditorColorTool from './DrawingEditorColorTool';
import DrawingEditorStrokeSizeTool from './DrawingEditorStrokeSizeTool';
import DrawingEditorToolModeButton from './DrawingEditorToolModeButton';
import DrawingEditorToolbarIPadPenTools from './DrawingEditorToolbarIPadPenTools';
import DrawingEditorToolbarUndoRedoTools from './DrawingEditorToolbarUndoRedoTools';

// Hooks
import usePencilDoubleTap from './usePencilDoubleTap';
import usePencilDoubleTapLegacy from './usePencilDoubleTapLegacy';
import usePrevious from '../../../../utils/react/usePrevious';
import useTouchBlockedPointerEvent from './useTouchBlockedPointerEvent';

// Constants
import { TOOL_MODES } from '../drawingEditorConstants';
import { CAPACITOR_APPLE_PENCIL_PLUGIN_NAME } from '../../../../../capacitor_plugins/pluginConstants';

// Styles
import './DrawingEditorToolbar.scss';

// If we're currently drawing, then there's no need to update the toolbar
const arePropsEqual = (prevProps, nextProps) => !!nextProps.isDrawing;

const DrawingEditorToolbar = React.memo(function DrawingEditorToolbar({
    isEditingExistingDrawing,

    toolMode,
    toolModeState,

    colorPopupOpen,
    strokePopupOpen,

    strokeSize,
    strokeColor,
    backgroundColor,
    defaultColors,

    handleSetStrokeColor,
    handleSetStrokeSize,
    handleDiscardButton,
    handleSaveButton,

    setToolMode,
    setColorPopupOpen,
    setCustomColorInputOpen,
    setStrokePopupOpen,

    canUndo,
    canRedo,
    undoPath,
    redoPath,

    isPenMode,
    setIsPenMode,
    isTouchEventInToolbarBlocked,
}) {
    if (Capacitor.isPluginAvailable(CAPACITOR_APPLE_PENCIL_PLUGIN_NAME)) {
        usePencilDoubleTap({ toolMode, setToolMode, setColorPopupOpen, setStrokePopupOpen });
    } else {
        // This is for the custom hybrid solution.
        // Once we completed the move to Capacitor, remove this and the if statement.
        usePencilDoubleTapLegacy({ toolMode, setToolMode, setColorPopupOpen });
    }

    const prevIsEditingExistingDrawing = usePrevious(isEditingExistingDrawing);

    const isEditing = isEditingExistingDrawing || prevIsEditingExistingDrawing;

    const disablePathTools =
        toolMode === TOOL_MODES.ERASE || (toolMode === TOOL_MODES.SELECT && !get('hasSelection', toolModeState));

    const onDiscard = useTouchBlockedPointerEvent(isTouchEventInToolbarBlocked, handleDiscardButton);
    const onSave = useTouchBlockedPointerEvent(isTouchEventInToolbarBlocked, handleSaveButton);

    return (
        <div
            className={classNames('DrawingEditorToolbar', { ipad: isPlatformIpad(platformSingleton) })}
            onDoubleClick={isPenMode ? null : handleSaveButton}
        >
            <DrawingEditorToolbarUndoRedoTools
                isPenMode={isPenMode}
                canUndo={canUndo}
                canRedo={canRedo}
                undoPath={undoPath}
                redoPath={redoPath}
            />
            <div className="tool-mode-controls">
                <DrawingEditorToolModeButton
                    name="Pen"
                    shortcut={['p']}
                    isPenMode={isPenMode}
                    currentToolMode={toolMode}
                    toolMode={TOOL_MODES.DRAW}
                    setToolMode={setToolMode}
                >
                    <Icon name="drawing-editor-tool-draw" />
                </DrawingEditorToolModeButton>
                <DrawingEditorToolModeButton
                    name="Selection tool"
                    shortcut={['v']}
                    isPenMode={isPenMode}
                    currentToolMode={toolMode}
                    toolMode={TOOL_MODES.SELECT}
                    setToolMode={setToolMode}
                >
                    <Icon name="drawing-editor-tool-select" />
                </DrawingEditorToolModeButton>
                <DrawingEditorToolModeButton
                    name="Eraser"
                    shortcut={['e']}
                    isPenMode={isPenMode}
                    currentToolMode={toolMode}
                    toolMode={TOOL_MODES.ERASE}
                    setToolMode={setToolMode}
                >
                    <Icon name="drawing-editor-tool-erase" />
                </DrawingEditorToolModeButton>
            </div>

            <div className="draw-controls">
                <DrawingEditorColorTool
                    isPenMode={isPenMode}
                    defaultColors={defaultColors}
                    disabled={disablePathTools}
                    keepPopupOpen={toolMode === TOOL_MODES.DRAW}
                    colorPopupOpen={colorPopupOpen}
                    setColorPopupOpen={setColorPopupOpen}
                    setCustomColorInputOpen={setCustomColorInputOpen}
                    backgroundColor={backgroundColor}
                    strokeColor={strokeColor}
                    setStrokeColor={handleSetStrokeColor}
                />

                <DrawingEditorStrokeSizeTool
                    isPenMode={isPenMode}
                    disabled={disablePathTools}
                    keepPopupOpen={toolMode === TOOL_MODES.SELECT}
                    strokePopupOpen={strokePopupOpen}
                    strokeSize={strokeSize}
                    setStrokeSize={handleSetStrokeSize}
                    setStrokePopupOpen={setStrokePopupOpen}
                />
            </div>

            <div className="exit-controls">
                <StyledButton className="secondary compact discard no-hover" onPointerUp={onDiscard}>
                    {isEditing ? 'Cancel' : 'Discard'}
                </StyledButton>
                <StyledButton className="primary compact save" onPointerUp={onSave}>
                    Save
                </StyledButton>
            </div>
            {isPlatformIpad(platformSingleton) && (
                <DrawingEditorToolbarIPadPenTools
                    toolMode={toolMode}
                    isPenMode={isPenMode}
                    setIsPenMode={setIsPenMode}
                />
            )}
        </div>
    );
}, arePropsEqual);

DrawingEditorToolbar.propTypes = {
    toolMode: PropTypes.string,
    toolModeState: PropTypes.object,
    strokeSize: PropTypes.number,
    strokeColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    defaultColors: PropTypes.array,

    drawingModeEnabled: PropTypes.bool,
    colorPopupOpen: PropTypes.bool,
    strokePopupOpen: PropTypes.bool,
    isEditingExistingDrawing: PropTypes.bool,

    setToolMode: PropTypes.func,
    handleSetStrokeColor: PropTypes.func,
    handleSetStrokeSize: PropTypes.func,

    setColorPopupOpen: PropTypes.func,
    setCustomColorInputOpen: PropTypes.func,
    setStrokePopupOpen: PropTypes.func,

    handleDiscardButton: PropTypes.func,
    handleSaveButton: PropTypes.func,

    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    undoPath: PropTypes.func,
    redoPath: PropTypes.func,

    isPenMode: PropTypes.bool,
    setIsPenMode: PropTypes.func,

    isTouchEventInToolbarBlocked: PropTypes.bool,
};

export default DrawingEditorToolbar;

export const DrawingEditorToolbarWithTransitions = (props) => (
    <CSSTransitionGroup
        transitionName="fade-exit-down"
        transitionAppear
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
        transitionAppearTimeout={300}
    >
        <DrawingEditorToolbar {...props} />
    </CSSTransitionGroup>
);

DrawingEditorToolbarWithTransitions.propTypes = {
    drawingModeEnabled: PropTypes.bool,
};
