// Monitoring
import { sendNewRelicPageLoadFinishedAction } from '../../analytics/newRelicUtils';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';

const areMetricsReadyToSend = (
    activityIndicatorsInitialisationTime: number,
    boardCountsInitialisationTime: number,
    awaitingBoardCounts: boolean,
): boolean => {
    if (!activityIndicatorsInitialisationTime) return false;

    // We have both the activity indicators and the board counts
    if (boardCountsInitialisationTime) return true;

    // We don't have the board counts, and we need to wait for them
    if (awaitingBoardCounts) return false;

    // We don't have the board counts, but we're not waiting for them
    return true;
};

const createPageInitialisationMonitoringSingleton = () => {
    let activityIndicatorsInitialisationTime = 0;
    let boardCountsInitialisationTime = 0;

    // Flag to track whether we need to wait for board counts
    let awaitingBoardCounts = false;
    // Flag to track whether the metric has been sent
    let metricSent = false;
    // Flag to track whether we should send metrics
    let shouldSendMetrics = false;

    const initialTime = performance.now();

    /**
     * If we started on a non workspace route, then we shouldn't send the metric.
     * We're detecting this scenario by seeing if the timer is initialised within 10 seconds
     * of the initial page load.
     */
    const initialiseTimer = (): void => {
        if (metricSent) return;

        shouldSendMetrics = performance.now() - initialTime < 10 * TIMES.SECOND;
    };

    /**
     * If an action is performed that should cancel the timer, then call this.
     */
    const cancelTimer = (): void => {
        shouldSendMetrics = false;
    };

    /**
     * Sends the metrics to New Relic.
     */
    const sendMetrics = (): void => {
        const initialisationTime = Math.max(activityIndicatorsInitialisationTime, boardCountsInitialisationTime);

        sendNewRelicPageLoadFinishedAction(initialisationTime);

        metricSent = true;
    };

    /**
     * Checks to see if the metrics are collected and sends them if so.
     */
    const checkAndSendMetrics = (): void => {
        if (metricSent) return;

        const metricsAreReady = areMetricsReadyToSend(
            activityIndicatorsInitialisationTime,
            boardCountsInitialisationTime,
            awaitingBoardCounts,
        );

        if (!metricsAreReady) return;

        sendMetrics();
    };

    /**
     * Tracks the first time that the activity indicators are initialised.
     */
    const setActivityIndicatorsInitialised = (): void => {
        if (activityIndicatorsInitialisationTime || !shouldSendMetrics || metricSent) return;

        activityIndicatorsInitialisationTime = Date.now();

        // Wait for a second to give the board counts time to register
        // This is because on a rehydration, the activity indicators will be immediately initialised
        //  while the board counts will take a few cycles
        setTimeout(checkAndSendMetrics, TIMES.SECOND);
    };

    /**
     * Registers that the board counts are expected to be initialised.
     */
    const registerBoardCountsWaiting = (): void => {
        awaitingBoardCounts = true;
    };

    /**
     * Tracks the first time that a board's counts are initialised
     */
    const setBoardCountsInitialised = (): void => {
        if (boardCountsInitialisationTime || !shouldSendMetrics || metricSent) return;

        boardCountsInitialisationTime = Date.now();

        // If board counts are initialised, we can immediately check if the activity indicators are also initialised
        checkAndSendMetrics();
    };

    return {
        initialiseTimer,
        cancelTimer,
        setActivityIndicatorsInitialised,
        setBoardCountsInitialised,
        registerBoardCountsWaiting,
    };
};

const workspaceInitialisationMonitoringSingleton = createPageInitialisationMonitoringSingleton();

export default workspaceInitialisationMonitoringSingleton;
