import {
    CellTypeNames,
    DateStringFormatOptions,
    FormatOptions,
    TextAlignment,
    TimeStringFormatOptions,
    VerticalAlignment,
} from './CellTypeConstants';

export enum TableAxis {
    ROW = 'row',
    COL = 'col',
}

export enum TableOperation {
    INSERT = 'insert',
    REMOVE = 'remove',
}

export interface CellCoordsObj {
    row: number;
    col: number;
}

export type CellSelection = [number, number, number, number];

export type CellSelections = Array<[number, number, number, number]>;

export interface CellData {
    value: string | null;

    // resultValue is used to store the last result of a formula, to make formula result is searchable
    resultValue?: string | number | null;

    type?: CellTypeObject;
    textStyle?: string[];
    textAlignment?: TextAlignment;
    verticalAlignment?: VerticalAlignment;
    readOnly?: boolean;
    background?: string;
}

export type CellDataChange = [number, number | string, CellData, CellData];

export type TableContentData = CellData[][];
export type TableColWidthsGU = number[];

export interface MNTableContent {
    data: TableContentData;
    colWidthsGU: TableColWidthsGU;
}

export type TableElementChanges = {
    tableContent?: MNTableContent;
    width?: number;
};

export type NumberTypeObject = {
    name: CellTypeNames.NUMBER;
    [FormatOptions.DECIMALS]: number | 'auto';
    [FormatOptions.THOUSANDS_SEPARATOR]: boolean;
};

export type CurrencyTypeObject = {
    name: CellTypeNames.CURRENCY;
    [FormatOptions.DECIMALS]: number | 'auto';
    [FormatOptions.THOUSANDS_SEPARATOR]: boolean;
    [FormatOptions.ACCOUNTING]: boolean;
    [FormatOptions.CURRENCY]?: string;
    [FormatOptions.CURRENCY_DISPLAY]?: string;
};

export type PercentageTypeObject = {
    name: CellTypeNames.PERCENTAGE;
    [FormatOptions.DECIMALS]: number | 'auto';
    [FormatOptions.THOUSANDS_SEPARATOR]: boolean;
};

export type TextTypeObject = {
    name: CellTypeNames.TEXT;
};

export type DateTimeTypeObject = {
    name: CellTypeNames.DATE_TIME;
    [FormatOptions.HAS_TIME]: boolean;
    [FormatOptions.HAS_SECONDS]: boolean;
    [FormatOptions.DATE_FORMAT]: DateStringFormatOptions;
    [FormatOptions.TIME_FORMAT]: TimeStringFormatOptions;
    [FormatOptions.TIME_IN_BRACKETS]: boolean;
};

export type CheckboxTypeObject = {
    name: CellTypeNames.CHECKBOX;
};

export type CellTypeObject =
    | NumberTypeObject
    | CurrencyTypeObject
    | PercentageTypeObject
    | TextTypeObject
    | DateTimeTypeObject
    | CheckboxTypeObject;

// This type will never be saved as a cell type, but is used to indicate that the cell type should be auto/removed
export type AutoCellTypeObject = { name: CellTypeNames.AUTO };
