// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { prop } from '../../../../common/utils/immutableHelper';
import { getElementCardRenderedWidthPx } from '../../utils/elementUtil';
import { getData, getDataFilename, getDataType, getFetching } from '../../attachments/attachmentsSelector';
import {
    getElementId,
    getFileProp,
    getImageProp,
    getMediaAudioOnly,
} from '../../../../common/elements/utils/elementPropertyUtils';
import { getMainEditorKey } from '../../utils/elementEditorUtils';
import fileCanShowMedia from '../utils/fileCanShowMedia';

// Components
import EditableTitle from '../../../components/editableTitle/EditableTitle';
import DetailView from '../../../components/displayMode/DetailView';
import { FilePreviewWithImageHover } from './FilePreview';
import FileIcon from '../../../components/fileIcon/FileIcon';
import FileSecondaryDetails from './FileSecondaryDetails';
import FileUploadProgress from './FileUploadProgress';

// Constants
import { FILE_TYPES_INFO } from '../../../../common/files/fileConstants';

// Styles
import './FileDetailViewContent.scss';

const FileDetailViewContent = (props) => {
    const { element, isEditable, isSingleSelected, isResizing, filterQuery, attachment, showDownloadOption } = props;
    const fileData = getFileProp(element) || getData(attachment);
    const imageData = getImageProp(element);
    const widthPx = getElementCardRenderedWidthPx(props);
    const savedWidthPx = getElementCardRenderedWidthPx({ ...props, tempSize: null });

    const isAttachmentAnImagePreview = getDataType(attachment) === FILE_TYPES_INFO.IMAGE.type;
    const awaitingPreview = !imageData && fileCanShowMedia(element, fileData);
    const isFetchingData = getFetching(attachment) && getDataFilename(attachment);

    const showProgress = !isAttachmentAnImagePreview && (awaitingPreview || isFetchingData);

    const fallbackTitle = prop('filename', fileData);
    const audioOnly = getMediaAudioOnly(element);

    const editorKey = getMainEditorKey(props);

    return (
        <DetailView
            className="FileDetailViewContent"
            previewElement={
                <FilePreviewWithImageHover
                    {...props}
                    cropToGrid={!isResizing}
                    widthPx={widthPx}
                    savedWidthPx={savedWidthPx}
                    audioOnly={audioOnly}
                />
            }
            iconElement={<FileIcon fileType={prop('type', fileData)} audioOnly={audioOnly} />}
            titleElement={
                <EditableTitle
                    element={element}
                    elementId={getElementId(element)}
                    isEditable={isEditable}
                    initialValue={fallbackTitle}
                    maxLength={500}
                    selectFirst
                    selectAllOnEdit
                    isSelected={isSingleSelected}
                    filterQuery={filterQuery}
                    editorKey={editorKey}
                />
            }
            secondaryElement={
                showProgress ? (
                    <FileUploadProgress element={element} attachment={attachment} />
                ) : (
                    <FileSecondaryDetails
                        element={element}
                        fileData={fileData}
                        showDownloadOption={showDownloadOption}
                    />
                )
            }
        />
    );
};

FileDetailViewContent.propTypes = {
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    isEditable: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isResizing: PropTypes.bool,
    filterQuery: PropTypes.string,
    showDownloadOption: PropTypes.bool,
};

export default FileDetailViewContent;
