// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { constant } from 'lodash/fp';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

// Decorators
import elementResizeDecorator from '../../resizing/elementResizeDecorator';
import dontUpdateForKeys from '../../../utils/milanoteRecompose/dontUpdateForKeys';

import liveCollaborationStoreConnect from '../../../remoteActivity/liveCollaboration/store/liveCollaborationStoreConnect';

// Actions
import { startAnnotationMode } from './store/annotationActions';

// Selector
import { getAnnotationEditedElementId } from './store/annotationSelector';
import { liveDrawingEditedElementIdsSelector } from '../store/liveDrawingSelector';

// Utils
import { getDrawingWidth, getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Components
import Annotation from './Annotation';

// Constants
import { ANNOTATION_MIN_WIDTH } from '../../../../common/drawings/annotations/annotationConstants';
import elementSaveAspectRatioResizeDecorator from '../../resizing/elementSaveAspectRatioResizeDecorator';

const mapStateToProps = createStructuredSelector({
    editedDrawingId: getAnnotationEditedElementId,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchEditAnnotationElement: (elementId) => dispatch(startAnnotationMode({ elementId })),
});

const createRemotelyIsEditedSelector = () =>
    createSelector(
        (_, { element }) => getElementId(element),
        liveDrawingEditedElementIdsSelector,
        (elementId, remoteEditedDrawingIds) => remoteEditedDrawingIds.indexOf(elementId) > -1,
    );

const mapCollabStateToProps = createStructuredSelector({
    isRemotelyEdited: createRemotelyIsEditedSelector(),
});

@dontUpdateForKeys(['dragModifierKeys', 'canDrop'])
@elementResizeDecorator({ getMinWidth: constant(ANNOTATION_MIN_WIDTH), enableHeight: true })
@elementSaveAspectRatioResizeDecorator({
    getMinWidth: constant(ANNOTATION_MIN_WIDTH),
    getDefaultMaxWidth: ({ element }) => getDrawingWidth(element),
})
@connect(mapStateToProps, mapDispatchToProps)
@liveCollaborationStoreConnect(mapCollabStateToProps)
class AnnotationContainer extends React.Component {
    render() {
        return (
            <div className="AnnotationContainer">
                <Annotation {...this.props} />
            </div>
        );
    }
}

AnnotationContainer.propTypes = {
    element: PropTypes.object.isRequired,

    canConnectLineEdge: PropTypes.bool,
    isSelected: PropTypes.bool,

    connectLineEdgeDropTarget: PropTypes.func,
    captionKeyHandler: PropTypes.func,
};

export default AnnotationContainer;
