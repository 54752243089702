// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { isEmpty } from '../../../common/utils/immutableHelper';
import { debouncedBatchFetchUsers } from '../userService';

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchUsers: (userIds) => dispatch(debouncedBatchFetchUsers(userIds)),
});

const FetchUsersOnMount = (props) => {
    const { userIds, dispatchFetchUsers } = props;

    React.useEffect(() => {
        if (isEmpty(userIds)) return;
        dispatchFetchUsers(userIds);
    }, []);

    return null;
};

FetchUsersOnMount.propTypes = {
    userIds: PropTypes.array,
    dispatchFetchUsers: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(FetchUsersOnMount);
