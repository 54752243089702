// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import searchHighlightPoiDecorator from './searchHighlightPoiDecorator';

const SearchHighlightSpan = (props) => <span className="SearchHighlightSpan">{props.children}</span>;

SearchHighlightSpan.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default searchHighlightPoiDecorator(SearchHighlightSpan);
