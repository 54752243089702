export const CONTENT_LIMIT_PRO_BASE = 9999999999999;
export const DEFAULT_CONTENT_LIMIT = 100;

export const DEFAULT_REFERRAL_BONUS = 20;
export const DEFAULT_MAX_REFERRAL_BONUS = 100;

export const isUnlimited = (contentTotalLimit) => contentTotalLimit >= CONTENT_LIMIT_PRO_BASE;

const getLimitedReferralBonus = ({ referralBonus, maxReferralBonus = DEFAULT_MAX_REFERRAL_BONUS }) =>
    Math.min(referralBonus, maxReferralBonus);

export const calculateContentLimit = ({ defaultContentLimit, giftsBonus = 0, referralBonus = 0, maxReferralBonus }) =>
    isUnlimited(defaultContentLimit)
        ? CONTENT_LIMIT_PRO_BASE
        : // The content limit is the starting content limit (default content limit) + any gift bonuses +
          //      referral bonuses up to a maximum limit (on a per user basis)
          defaultContentLimit + giftsBonus + getLimitedReferralBonus({ referralBonus, maxReferralBonus });
