import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ImageHoverReplaceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={49}
        height={49}
        viewBox="0 0 49 49"
        className="Icon image-hover-replace"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M11.167 22.756a1 1 0 0 1 .116 1.993l-.116.007h-1a1 1 0 0 1-.117-1.994l.117-.006h1zm15.5-11.5a1 1 0 0 1 .993.883l.007.117v1a1 1 0 0 1-1.994.116l-.006-.116v-1a1 1 0 0 1 1-1zm-22 0a1 1 0 0 1 .993.883l.007.117v1a1 1 0 0 1-1.994.116l-.006-.116v-1a1 1 0 0 1 1-1zm0 5a1 1 0 0 1 .993.883l.007.117v1a1 1 0 0 1-1.994.116l-.006-.116v-1a1 1 0 0 1 1-1zm22-.5a1 1 0 0 1 .993.883l.007.117v1a1 1 0 0 1-1.994.116l-.006-.116v-1a1 1 0 0 1 1-1zm16 6h-24a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3v-16a3 3 0 0 0-3-3zm-24 2h24a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-24a1 1 0 0 1-1-1v-16a1 1 0 0 1 1-1zm17.207-9.208a1 1 0 0 1 .083 1.32l-.083.095a1 1 0 0 1-1.32.083l-.094-.083-3.493-3.493-.145-.267c-.181-.346-.2-.482-.055-.787l.121-.232.079-.143 3.493-3.493a1 1 0 0 1 1.497 1.32l-.083.095-1.793 1.793h2.586a5 5 0 0 1 4.995 4.783l.005.217v2a1 1 0 0 1-1.994.116l-.006-.116v-2a3 3 0 0 0-2.824-2.995l-.176-.005H34.08l1.793 1.792zM11.167 5.756a1 1 0 0 1 .116 1.993l-.116.007h-1a1 1 0 0 1-.117-1.994l.117-.006h1zm10 0a1 1 0 0 1 .116 1.993l-.116.007h-1a1 1 0 0 1-.117-1.994l.117-.006h1zm-5 0a1 1 0 0 1 .116 1.993l-.116.007h-1a1 1 0 0 1-.117-1.994l.117-.006h1zm-10 .1a.9.9 0 0 1 .113 1.793l-.21.014a.6.6 0 0 0-.495.495l-.015.21a.9.9 0 0 1-1.793-.112 2.4 2.4 0 0 1 2.4-2.4zm19 0a.9.9 0 0 0-.113 1.793l.21.014a.6.6 0 0 1 .495.495l.015.21a.9.9 0 0 0 1.793-.112 2.4 2.4 0 0 0-2.4-2.4zm-19 18.8a.9.9 0 0 0 .113-1.793l-.21-.015a.6.6 0 0 1-.495-.495l-.015-.21a.9.9 0 0 0-1.793.113 2.4 2.4 0 0 0 2.4 2.4z"
        />
    </svg>
);
const Memo = memo(ImageHoverReplaceIcon);
export default Memo;
