// Lib
import { NativeTypes } from 'react-dnd-html5-backend';
import { identity } from 'lodash/fp';
import { branch, compose, withHandlers } from '../../../../node_module_clones/recompose';

// Utils
import { handleFileUploadDrop } from '../../../components/tools/files/fileDropTargetDecorator';
import { getElementId, getImageProp } from '../../../../common/elements/utils/elementPropertyUtils';
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';

// Constants
import { DRAG_PREVIEW_ID } from '../../../reducers/draggingConstants';
import imageDropTargetDecorator from '../../image/imageDropTargetDecorator';

const previewImageDropTargetConfig = {
    drop: (props, monitor) => {
        const {
            elementId,
            currentBoardId,
            dispatchUpdateElement,
            dispatchMoveElementsToTrash,
            dispatchSwitchConnectedElementParents,
            dispatchAcceptElementAttachmentUndo,
        } = props;

        const transactionId = getNewTransactionId();

        if (monitor.getItemType() === NativeTypes.FILE) {
            dispatchAcceptElementAttachmentUndo({ id: elementId, transactionId });
            return handleFileUploadDrop(props, monitor);
        }
        const droppedElement = monitor.getItem().element;
        const droppedElementId = getElementId(droppedElement);
        const droppedImage = getImageProp(droppedElement);

        // If there is no image, don't do anything
        if (!droppedImage) return {};

        // Update this element with the image from the dropped element
        dispatchUpdateElement({
            id: elementId,
            changes: { image: droppedImage },
            sync: true,
            transactionId,
        });

        // If image was dragged directly from the image popup, we're done
        // (there's no source element to delete)
        if (droppedElementId === DRAG_PREVIEW_ID) return {};

        // - Move any connected lines or comments from the dropped element over to the main element
        dispatchSwitchConnectedElementParents({
            initialConnectedId: droppedElementId,
            newConnectedId: elementId,
            transactionId,
        });

        // - Finally, delete the dropped element
        dispatchMoveElementsToTrash({ elementId: droppedElementId, currentBoardId, transactionId });

        // Prevent lists or the canvas from handling the drop
        return {};
    },
};

/**
 * This decorator is used to allow image elements or native image files to be hoverable and droppable
 * on top of the drop target, in order to replace the image of the element.
 *
 * Currently used to replace images in file and link image previews.
 */
export default () =>
    compose(
        withHandlers({
            uploadFile:
                ({ element, dispatchUploadFile }) =>
                (file, transactionId) =>
                    dispatchUploadFile(getElementId(element), file, { onlyReplaceImage: true }, transactionId),
        }),
        branch(
            ({ isPresentational }) => !isPresentational,
            imageDropTargetDecorator(previewImageDropTargetConfig),
            identity,
        ),
    );
