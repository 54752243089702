import platformSingleton from '../../../platform/platformSingleton';

/**
 * Determines if the persistence layer is supported on this device.
 */
export const checkPersistenceLayerSupport = () => {
    if (!platformSingleton.features.supportsIndexedDb) return false;
    if (!platformSingleton.features.supportsWorker) return false;
    return true;
};

export const SUPPORTS_PERSISTENCE_LAYER = checkPersistenceLayerSupport();
