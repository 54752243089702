// Lib
import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

// Styles
import './MobilePageBody.scss';

interface MobilePageBodyProps {
    children?: ReactNode | ReactNode[];
    className?: string;
}

const MobilePageBody = (props: MobilePageBodyProps): ReactElement => {
    const { children, className } = props;

    return (
        <div className={classNames('MobilePageBody', className)} data-scrollable>
            {children}
        </div>
    );
};

export default MobilePageBody;
