// Lib
import { NativeTypes } from 'react-dnd-html5-backend';

// Utils
import { ElementType } from '../../../../common/elements/elementTypes';

export default (elementTypePredicates) =>
    ({ monitor }) => {
        const itemType = monitor.getItemType();

        // Don't fail for files, text or urls unless they're aren't allowed
        if (itemType === NativeTypes.FILE)
            return !elementTypePredicates.some((predicate) => predicate(ElementType.FILE_TYPE));
        if (itemType === NativeTypes.TEXT)
            return !elementTypePredicates.some((predicate) => predicate(ElementType.CARD_TYPE));
        if (itemType === NativeTypes.URL)
            return !elementTypePredicates.some((predicate) => predicate(ElementType.LINK_TYPE));

        const item = monitor.getItem();
        return (
            item.element &&
            !item.draggedElements.some((element) => elementTypePredicates.some((predicate) => predicate(element)))
        );
    };
