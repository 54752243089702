// Lib
import { isNil } from 'lodash/fp';

// Utils
import { propIn } from '../../../common/utils/immutableHelper';
import { getBranchElements } from '../../../common/elements/utils/elementLodash';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { isLocationRootBoard } from '../../../common/elements/utils/elementLocationUtils';

export const getBoardNotificationPreference = ({ elements, elementId, boardNotificationPreferences }) => {
    // Physical ancestors and the current element
    const branchElements = getBranchElements(elements, elementId);

    return branchElements.reduce((preference, element) => {
        // If already set, just use that preference
        if (!isNil(preference)) return preference;

        const ancestorId = getElementId(element);

        // If we've reached the root board and we haven't encountered an overriding opt-out then we
        // default to opt in
        if (isLocationRootBoard(element)) return true;

        const boardPreference = propIn([ancestorId, 'subscribed'], boardNotificationPreferences);

        if (isNil(boardPreference)) return null;

        return boardPreference;
    }, null);
};
