import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementLineIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={28}
        viewBox="0 0 32 28"
        className="Icon element-line"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="m30.385 0-3 8-1.793-1.793-21.5 21.5a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414L24.177 4.792 22.385 3l8-3z"
        />
    </svg>
);
const Memo = memo(ElementLineIcon);
export default Memo;
