// Lib
import * as Immutable from 'immutable';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformIframe } from '../../platform/utils/platformDetailsUtils';

// Constants
import { CURRENT_BOARD_ID_SET } from '../../reducers/currentBoardId/currentBoardIdConstants';
import {
    PREVIEW_MODE_END,
    PREVIEW_MODE_START,
    PUBLISH_PREVIEW_PASSWORD_VALIDATED,
    PREVIEW_MODES,
} from './previewConstants';

const initialState = Immutable.Map({
    startingBoardId: null,
    passwordValidated: false,
    mode: PREVIEW_MODES.DEFAULT,
});

export default (state = initialState, action) => {
    switch (action.type) {
        // This is used to support loading in an iframe, which works very similarly to the publish
        // preview in terms of the breadcrumbs, so for simplicity I'm going to leverage the same code
        // to do the iframe breadcrumb
        case CURRENT_BOARD_ID_SET: {
            // If we're not in an iframe, or we've already set our starting board ID, do nothing
            if (!isPlatformIframe(platformSingleton) || state.get('startingBoardId')) return state;

            return state.set('startingBoardId', action.boardId);
        }
        case PREVIEW_MODE_START:
            return state.set('mode', action.mode).set('startingBoardId', action.startingBoardId);
        case PREVIEW_MODE_END:
            return initialState;
        case PUBLISH_PREVIEW_PASSWORD_VALIDATED:
            return state.set('passwordValidated', true);
        default:
            return state;
    }
};
