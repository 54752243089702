// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getIsColorActive } from '../toolbar/components/selectionTools/colorTools/colorToolUtils';
import { isSketch } from '../../../common/elements/utils/elementTypeUtils';

// Components
import ColorButton from '../toolbar/components/selectionTools/colorTools/popup/ColorButton';

// Constants
import { COLORS, SKETCH_BACKGROUND_COLORS } from '../../../common/colors/colorConstants';

const COLORS_ARRAY = Object.values(COLORS);
const SKETCH_BACKGROUND_COLORS_ARRAY = Object.values(SKETCH_BACKGROUND_COLORS);

const getDefaultColors = (selectedElements) =>
    selectedElements.every(isSketch) ? SKETCH_BACKGROUND_COLORS_ARRAY : COLORS_ARRAY;

const ColorContextMenuItem = ({ selectedElements, setColor, show }) => {
    if (!show) return null;

    const defaultColors = getDefaultColors(selectedElements);

    return (
        <div>
            <ul className="colors">
                {defaultColors.map((color) => (
                    <li key={color.name || 'default'}>
                        <ColorButton
                            selectedType={selectedElements.first().get('elementType')}
                            setColor={setColor}
                            color={color}
                            isActive={getIsColorActive(selectedElements, color)}
                            isContextMenu
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

ColorContextMenuItem.propTypes = {
    selectedElements: PropTypes.object,
    setColor: PropTypes.func,
    show: PropTypes.bool,
};

export default ColorContextMenuItem;
