import * as Immutable from 'immutable';

export const MEDIA_PLAYER_PLAY = 'MEDIA_PLAYER_PLAY';
export const MEDIA_PLAYER_PAUSE = 'MEDIA_PLAYER_PAUSE';
export const MEDIA_PLAYER_SET_PROGRESS = 'MEDIA_PLAYER_SET_PROGRESS';
export const MEDIA_PLAYER_SET_DURATION = 'MEDIA_PLAYER_SET_DURATION';
export const MEDIA_PLAYER_SET_VOLUME = 'MEDIA_PLAYER_SET_VOLUME';
export const MEDIA_PLAYER_SET_MUTE = 'MEDIA_PLAYER_SET_MUTE';
export const MEDIA_PLAYER_SET_DRAG_PREVIEW = 'MEDIA_PLAYER_SET_DRAG_PREVIEW';
export const MEDIA_PLAYER_ERROR = 'MEDIA_PLAYER_ERROR';
export const MEDIA_PLAYER_RESET = 'MEDIA_PLAYER_RESET';

export const DEFAULT_PLAYER_STATE = Immutable.Map({
    playing: false,
    progress: 0,
    duration: 0,
    volume: 1,
    mute: false,
    error: null,
    hasBeenPlayed: false,
});

export const MEDIA_PLAYER_TYPES = {
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
};
