// Lib
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';
import { getIsReplaceModeHovered } from '../../../reducers/draggingSelector';

// Constants
import { ELEMENT_DND_TYPE } from '../../../../common/elements/elementConstants';

// Actions
import { atomicMoveAndUpdate, createElementSync } from '../../actions/elementActions';
import { endReplaceMode, startReplaceMode } from '../../../reducers/draggingActions';
import { moveElementsToTrash } from '../../actions/elementShortcutActions';
import { setElementLocalData } from '../../local/elementLocalDataActions';

// Constants
import { ElementType } from '../../../../common/elements/elementTypes';

const mapDispatchToProps = (dispatch) => ({
    dispatchStartReplaceMode: (elementId) => dispatch(startReplaceMode(elementId)),
    dispatchEndReplaceMode: () => dispatch(endReplaceMode()),
    createTaskList: ({ location, content, transactionId = getNewTransactionId(), sync = true }) => {
        const taskListElementId = dispatch(
            createElementSync({
                elementType: ElementType.TASK_LIST_TYPE,
                location,
                content,
                sync,
                transactionId,
            }),
        );

        return { taskListElementId, transactionId };
    },
    dispatchHideElement: ({ elementId }) => dispatch(setElementLocalData({ id: elementId, data: { hidden: true } })),
    dispatchRevealElement: ({ elementId }) => dispatch(setElementLocalData({ id: elementId, data: { hidden: false } })),
    dispatchMoveElementsToTrash: (props) => dispatch(moveElementsToTrash(props)),
    dispatchAtomicMoveAndUpdate: (props) => dispatch(atomicMoveAndUpdate(props)),
});

const defaultCollect = (monitor) => ({
    isHovered: monitor.canDrop() && monitor.isOver({ shallow: true }),
    // Note: canDrop was removed from here for performance reasons.
    //  Forcing every element to update on drag was unnecessary and cost a reasonable amount of time
});

const defaultConnectRef = (ref) => ({
    connectDropTarget: ref,
});

const setHoveredType = (monitor, hoverType) => {
    const { hoveredTypesRegistry } = monitor.getItem();

    // drags inited without hoveredTypesRegistry represent external drags (eg a file upload)
    // - we don't need to change the drag display behaviour for those, so, just ignore
    if (!hoveredTypesRegistry) return;

    hoveredTypesRegistry[monitor.targetId] = hoverType;
};

const ElementDropTarget = ({
    acceptDropTypes = [],
    collect = defaultCollect,
    connectRef = defaultConnectRef,
    drop,
    hover,
    hoverType,
    canDrop,
}) => {
    const dropTypes = [ELEMENT_DND_TYPE, ...acceptDropTypes];

    if (!drop) throw new Error('No drop callback defined');

    return (Element) => {
        const DropTargetComponent = (props) => {
            const dispatch = useDispatch();

            // this is a small hack so that ListContainer can pass some callbacks to
            // its hover function - see listDropHoverFn for more details
            const [hoverCallbacks, setHoverCallbacks] = useState({});

            const { elementId } = props;
            const isReplaceModeHovered = useSelector((state) => getIsReplaceModeHovered(state, { elementId }));

            // construct the props as a separate object here so that we can pass it
            // to the drag & drop callbacks, which make use of it
            const baseProps = {
                ...props,
                ...mapDispatchToProps(dispatch),
                isReplaceModeHovered,
            };

            const dropRef = useRef();

            // Watch out - there's a lot of parameter swapping here -- this was done to
            // maintain parity with the old DropTarget decorator's API.
            // TODO (at some point after old one is removed): refactor the spec objects'
            // callback signatures to follow the ones expected by useDrop and simplify
            // all this!
            const [dropProps, connectorRef] = useDrop({
                accept: dropTypes,
                drop: (item, monitor) => drop(baseProps, monitor, dropRef.current),
                canDrop: canDrop && ((item, monitor) => canDrop(baseProps, monitor)),
                hover: (item, monitor) => {
                    setHoveredType(monitor, hoverType);
                    return hover?.({ ...baseProps, ...hoverCallbacks }, monitor, dropRef.current);
                },
                collect: (monitor) => collect(monitor, baseProps),
            });

            const connectorProps = connectRef((elem) => {
                dropRef.current = elem;
                return connectorRef(elem);
            });

            return <Element {...baseProps} {...dropProps} {...connectorProps} setHoverCallbacks={setHoverCallbacks} />;
        };

        DropTargetComponent.propTypes = {
            elementId: PropTypes.string,
        };

        return DropTargetComponent;
    };
};

export default ElementDropTarget;
