// Selectors
import { getCurrentUserId } from '../user/currentUserSelector';

// Constants
import { BATCH_ACTION_TYPE } from './reduxBulkingMiddleware';

const addUserToIndividualAction = (action, currentUserId) => ({
    ...action,
    user: {
        _id: currentUserId,
    },
});

const addUserToAction = (action, currentUserId) => {
    if (action.type !== BATCH_ACTION_TYPE) return addUserToIndividualAction(action, currentUserId);

    return addUserToIndividualAction(
        {
            ...action,
            payload: action.payload.map((a) => addUserToIndividualAction(a, currentUserId)),
        },
        currentUserId,
    );
};

export default (store) => (next) => (action) => {
    if (action.user || action.remote) return next(action);

    const { getState } = store;
    const state = getState();

    const currentUserId = getCurrentUserId(state);
    const updatedAction = addUserToAction(action, currentUserId);

    return next(updatedAction);
};
