import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const AlignCenterVertIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon align-center-vert"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M14 9a1 1 0 0 1 1 1v5.249h2V12a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3.249l1 .001a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.75.75l-1-.001V20a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-3.251h-2V22a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-5.25H8a.75.75 0 0 1-.743-.648L7.25 16a.75.75 0 0 1 .75-.75h1V10a1 1 0 0 1 1-1h4zm-.5 7.749-3 .001v4.75h3v-4.751zm8 0h-3V19.5h3v-2.751zm-8-6.249h-3v4.75l3-.001V10.5zm8 2h-3v2.749h3V12.5z"
            />
        </g>
    </svg>
);
const Memo = memo(AlignCenterVertIcon);
export default Memo;
