// Lib
import { useEffect, useRef } from 'react';

export default <T>(value: T, initialValue?: T): T | undefined => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
