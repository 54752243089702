import { BasePlugin } from 'handsontable/plugins';
import { EditorState } from 'draft-js';

// Types
import { CellCoordsObj } from '../../../../common/table/TableTypes';
import Core from 'handsontable/core';

// Utils
import { isFormula } from '../utils/tableFormulaUtils';

export const MILANOTE_FORMULA_AUTOCOMPLETE_PLUGIN_NAME = 'milanoteFormulaAutocomplete';

export const SHORTCUTS_GROUP_AUTOCOMPLETE_NAVIGATION = 'milanoteFormulaAutocomplete.navigation';
export const SHORTCUTS_GROUP_AUTOCOMPLETE_HANDLING_EDITOR = 'milanoteFormulaAutocomplete.handlingEditor';
export const SHORTCUTS_GROUP_EDITOR_HANDLING_EDITOR = 'editorManager.handlingEditor';

/**
 * @plugin FormulaAutocompletePlugin
 *
 */
class MilanoteFormulaAutocompletePlugin extends BasePlugin {
    configuration: {
        enabled: boolean;
    };
    editingCellCoords: CellCoordsObj | null;
    private autocompleteShortcutRegistered: boolean;

    static get PLUGIN_KEY(): string {
        return MILANOTE_FORMULA_AUTOCOMPLETE_PLUGIN_NAME;
    }

    constructor(hotInstance: Core) {
        super(hotInstance);

        this.configuration = {
            enabled: false,
        };

        this.editingCellCoords = null;
        this.autocompleteShortcutRegistered = false;
    }

    getConfig() {
        const settings = this.hot?.getSettings() as any;

        return settings?.[MILANOTE_FORMULA_AUTOCOMPLETE_PLUGIN_NAME] ?? {};
    }

    /**
     * Check if the plugin is enabled in the settings.
     */
    isEnabled(): boolean {
        return !!this.getConfig();
    }

    /**
     * Enable the plugin.
     */
    enablePlugin(): void {
        this.addHook('afterBeginEditing', () => this.onAfterBeginEditing());
        this.addHook('afterSelection', () => this.onAfterSelection());
        this.addHook('beforeKeyDown', (event) => this.beforeKeyDown(event));

        // @ts-ignore - Custom Milanote hook
        this.addHook('onMilanoteCellEditorChange', (editorState: EditorState) =>
            this.onMilanoteCellEditorChange(editorState),
        );
        super.enablePlugin();
    }

    /**
     * Disable the plugin.
     */
    disablePlugin(): void {
        super.disablePlugin();
    }

    /**
     * Update the plugin.
     */
    updatePlugin(): void {
        this.disablePlugin();
        this.enablePlugin();

        super.updatePlugin();
    }

    /******************
     * PLUGIN HOOKS
     ******************/

    beforeKeyDown(event: KeyboardEvent): boolean | void {
        const { dropdownRef } = this.getConfig();
        if (!dropdownRef?.current || !dropdownRef?.current.isFormulaDropdownOpen()) return;

        // Putting these in the beforeKeyDown hook instead of registerShortcuts because we haven't
        // found a way to run these shortcuts before the 'editorManager.navigation' shortcut group.
        if (event.code === 'ArrowDown') {
            event.preventDefault();
            event.stopImmediatePropagation();
            dropdownRef.current.moveListFocusDown();
            return false;
        }
        if (event.code === 'ArrowUp') {
            event.preventDefault();
            event.stopImmediatePropagation();
            dropdownRef.current.moveListFocusUp();
            return false;
        }
    }

    onAfterBeginEditing(): void {
        this.editingCellCoords = this.getCurrentCell();
    }

    onAfterSelection(): void {
        this.removeDropdown();
    }

    onMilanoteCellEditorChange(editorState: EditorState): void {
        const cellText = editorState.getCurrentContent().getPlainText();

        if (!isFormula(cellText)) {
            this.removeDropdown();
            return;
        }

        this.showDropdown(cellText);
    }

    /******************
     * HELPER FUNCTIONS
     ******************/

    getCurrentCell(): CellCoordsObj | null {
        const table = this.hot as Core;
        const selected = table.getSelected();
        return selected ? { row: selected[0][0], col: selected[0][1] } : null;
    }

    showDropdown(newCellContent: string): void {
        const { dropdownRef } = this.getConfig();
        if (!dropdownRef?.current) return;

        const hasFormulaSuggestions = dropdownRef.current.updateFormulaDropdown(true, newCellContent);

        if (hasFormulaSuggestions) {
            this.registerShortcuts();
        } else {
            this.unregisterShortcuts();
        }
    }

    removeDropdown(): void {
        const { dropdownRef } = this.getConfig();
        if (!dropdownRef?.current) return;

        dropdownRef.current.updateFormulaDropdown(false);

        this.unregisterShortcuts();
    }

    /**
     * Destroy the plugin.
     */
    destroy(): void {
        super.destroy();
        this.removeDropdown();
        this.editingCellCoords = null;
    }

    /**
     * Register shortcuts responsible for handling editor.
     *
     * @private
     */
    registerShortcuts() {
        if (this.autocompleteShortcutRegistered) return;

        const shortcutManager = this.hot.getShortcutManager();
        const editorContext = shortcutManager.getContext('editor');

        const { dropdownRef, cellEditorRef } = this.getConfig();

        if (!editorContext || !dropdownRef.current || !cellEditorRef.current) return;

        editorContext.addShortcuts([
            {
                keys: [['TAB'], ['ENTER']],
                callback: (event) => {
                    event.stopImmediatePropagation();
                    event.preventDefault();

                    dropdownRef.current.useFocusedFormula();

                    // move to next cell down
                    this.removeDropdown();

                    return false;
                },
                group: SHORTCUTS_GROUP_AUTOCOMPLETE_HANDLING_EDITOR,
                position: 'before',
                relativeToGroup: SHORTCUTS_GROUP_EDITOR_HANDLING_EDITOR,
            },
        ]);

        this.autocompleteShortcutRegistered = true;
    }

    /**
     * Unregister shortcuts responsible for handling editor.
     *
     * @private
     */
    unregisterShortcuts() {
        if (!this.autocompleteShortcutRegistered) return;

        const shortcutManager = this.hot.getShortcutManager();
        const editorContext = shortcutManager.getContext('editor');

        if (!editorContext) return;

        editorContext.removeShortcutsByGroup(SHORTCUTS_GROUP_AUTOCOMPLETE_NAVIGATION);
        editorContext.removeShortcutsByGroup(SHORTCUTS_GROUP_AUTOCOMPLETE_HANDLING_EDITOR);

        this.autocompleteShortcutRegistered = false;
    }
}

export default MilanoteFormulaAutocompletePlugin;
