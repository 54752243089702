import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CrossLargeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 40 40"
        className="Icon cross-large"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={20} cy={20} r={20} fill="#E60B0B" />
            <g fill="#FFF">
                <path d="m26.586 10.586-16 16 2.828 2.828 16-16z" />
                <path d="m26.586 29.414 2.828-2.828-16-16-2.828 2.828z" />
            </g>
        </g>
    </svg>
);
const Memo = memo(CrossLargeIcon);
export default Memo;
