import * as Immutable from 'immutable';

const getStoreInitialState = () => {
    if (!window || !window.milanoteSwiftState) return Immutable.Map();

    // Retrive the state from milanote-swift
    const milanoteSwiftState = Immutable.fromJS(window.milanoteSwiftState);

    // Clear it out after usage, so that the large JSON does not just hang around in memory
    window.milanoteSwiftState = {};

    return milanoteSwiftState || Immutable.Map();
};

export default getStoreInitialState;
