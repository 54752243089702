// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ToolbarPopupTool from '../../ToolbarPopupTool';
import LineWeightPopup from './LineWeightPopup';

const LineWeightTool = (props) => (
    <ToolbarPopupTool {...props} toolName="lineWeight" PopupComponent={LineWeightPopup} />
);

LineWeightTool.propTypes = {
    name: PropTypes.string,
    selectedElements: PropTypes.object.isRequired,
};

export default LineWeightTool;
