import { Dispatch, SetStateAction, useState } from 'react';

import localStorage from './localStorage';

const useLocalStorage = <T>(
    key: string,
    initialValue: T,
    transformFn = JSON.parse,
): [T, Dispatch<SetStateAction<T>>, () => void] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? transformFn(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setLocalStorageValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };

    const resetLocalStorageValue = () => {
        setLocalStorageValue(initialValue);
    };

    return [storedValue, setLocalStorageValue, resetLocalStorageValue] as const;
};

export default useLocalStorage;
