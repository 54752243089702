import urlregexp from 'urlregexp';

import {
    ILLEGAL_URL_SCHEME_REGEX,
    FAILURE_TYPES,
    FAILURE_NAMES,
    HOST_REGEX,
    LOCALHOST_REGEX,
    EMAIL_REGEX,
} from '../../validationConstants';

const URL_ERROR_OBJECT = {
    name: FAILURE_NAMES.url,
    type: FAILURE_TYPES.ERROR,
    debounced: true,
    message: 'Not a valid url',
};

export default (fieldValue, validationConfig) => {
    if (!fieldValue) return null;

    if (fieldValue.match(ILLEGAL_URL_SCHEME_REGEX)) return URL_ERROR_OBJECT;
    if (fieldValue.match(EMAIL_REGEX)) return URL_ERROR_OBJECT;
    if (fieldValue.match(urlregexp)) return null;
    if (validationConfig.allowIPAddress && fieldValue.match(HOST_REGEX)) return null;
    if (process.env.NODE_ENV === 'development' && fieldValue.match(LOCALHOST_REGEX)) return null;

    return URL_ERROR_OBJECT;
};
