/**
 * For a specified editor point ({ key, offset }) this will return the entityKey, entity object
 * at that point, or an empty object if none.
 */
export default (editorState, { key, offset }) => {
    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(key);
    const entityKey = block.getEntityAt(offset);

    if (!entityKey) return {};

    const entity = content.getEntity(entityKey);

    return {
        entityKey,
        entity,
    };
};
