// Lib
import { flow, eq, toLower } from 'lodash/fp';

// Util
import not from '../../client/utils/milanoteLodash/not';
import { prop, propIn } from '../utils/immutableHelper';
import { isPublicAclId } from '../permissions/permissionUtil';

// Constants
import { PUBLIC_USER_ID, STARTER_CONTENT_CREATOR_ID, GUEST_USER_ID, USER_STATUSES, USER_ROLES } from './userConstants';

export const isEqualToUserId = (testUserId) => (userId) => userId === testUserId;
export const isPublicUserId = isEqualToUserId(PUBLIC_USER_ID);
export const isGuestUserId = isEqualToUserId(GUEST_USER_ID);
export const isStarterUserId = isEqualToUserId(STARTER_CONTENT_CREATOR_ID);
export const isNotUserId = (testUserId) => flow([isEqualToUserId(testUserId), not]);

const checkForValidMongoDbId = /^[0-9a-fA-F]{24}$/;
export const isValidMongoUserId = (userId) => checkForValidMongoDbId.test(userId);

export const isRealUserId = (userId) =>
    userId &&
    isValidMongoUserId(userId) &&
    !isPublicUserId(userId) &&
    !isPublicAclId(userId) &&
    !isGuestUserId(userId) &&
    !isStarterUserId(userId);
export const isPublicId = (aclId) => aclId && (isPublicUserId(aclId) || isPublicAclId(aclId));

const userIs = (status) => flow(prop('status'), eq(status));
export const userIsInvited = userIs(USER_STATUSES.INVITED);
export const userAccountIsActive = userIs(USER_STATUSES.ACTIVE);
export const userAccountIsRemoved = userIs(USER_STATUSES.REMOVED);
export const userAccountIsLocked = userIs(USER_STATUSES.LOCKED);
export const userIsRegistered = (user) => userAccountIsActive(user) || userAccountIsLocked(user);

export const getId = prop('_id');
export const getNameProp = prop('name');
export const getDisplayName = propIn(['name', 'displayName']);
export const getGivenName = propIn(['name', 'givenName']);
export const getFamilyName = propIn(['name', 'familyName']);
export const getEmail = prop('email');
export const getLowercaseEmail = flow(prop('email'), toLower);
export const getGoogleSignInDetails = prop('google');
export const getAppleSignInDetails = prop('apple');
export const getRole = prop('role');
export const getImage = prop('image');
export const getRootBoardId = prop('rootBoardId');
export const getTemplateRootBoardId = prop('templateRootId');
export const getQuickNotesRootBoardId = prop('quickNotesRootId');
export const getCustomerId = prop('customerId');

// Client property accessors
export const getOnlineStatus = prop('onlineStatus');

export const isRoleStaff = flow(getRole, (role) => role === USER_ROLES.STAFF || role === USER_ROLES.ADMIN);

export const getName = (user) => getDisplayName(user) || getEmail(user) || 'Someone';

export const getShortName = (user) => getGivenName(user) || getEmail(user) || 'Someone';
