// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity } from 'lodash';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import { gridSizeSelector } from '../../utils/grid/gridSizeSelector';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';

// Actions
import { setElementTypeAndUpdateElement, updateElement } from '../actions/elementActions';
import { moveElementsToTrash } from '../actions/elementShortcutActions';
import {
    getNextClipperOperationCompleteTransactionId,
    getNextRedoClipperOperationCompleteTransactionId,
    getNextRedoTransactionId,
    getNextUndoTransactionId,
    redoAction,
    undoAction,
} from '../../utils/undoRedo/undoRedoActions';

// Constants

// Styles
import Document from './Document';

const mapDispatchToProps = (dispatch) => ({
    dispatchSaveTextContent: ({ id, textContent, transactionId }) =>
        dispatch(updateElement({ id, changes: { textContent }, transactionId })),
    dispatchMoveElementsToTrash: ({ currentBoardId, elementIds }) =>
        dispatch(moveElementsToTrash({ currentBoardId, elementIds })),
    dispatchUpdateElementType: ({ id, elementType, data }) =>
        dispatch(setElementTypeAndUpdateElement({ id, elementType, changes: data })),
    onAppUndo: () => {
        const nextUndoTransactionId = dispatch(getNextUndoTransactionId());
        const nextUndoClipperOperationTransactionId = dispatch(getNextClipperOperationCompleteTransactionId());

        if (nextUndoTransactionId && nextUndoClipperOperationTransactionId === nextUndoTransactionId) {
            dispatch(undoAction());
        }
    },
    onAppRedo: () => {
        const nextRedoTransactionId = dispatch(getNextRedoTransactionId());
        const nextRedoClipperOperationTransactionId = dispatch(getNextRedoClipperOperationCompleteTransactionId());

        if (nextRedoTransactionId && nextRedoClipperOperationTransactionId === nextRedoTransactionId) {
            dispatch(redoAction());
        }
    },
});

@connect(gridSizeSelector, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys'])
@captionContainerDecorator
class DocumentContainer extends React.Component {
    saveContent = (textContent, transactionId) => {
        const { isEditable, element, dispatchSaveTextContent } = this.props;
        if (!isEditable) return;

        dispatchSaveTextContent({ id: getElementId(element), textContent, transactionId });
    };

    moveElementsToTrash = () => {
        const { currentBoardId, element, dispatchMoveElementsToTrash } = this.props;
        dispatchMoveElementsToTrash({ currentBoardId, elementIds: [getElementId(element)] });
    };

    updateElementType = ({ elementType, data }) => {
        const { element, dispatchUpdateElementType } = this.props;
        dispatchUpdateElementType({ id: getElementId(element), elementType, data });
    };

    shouldComponentUpdate(nextProps) {
        return !nextProps.isElementOpenInModal || !this.props.isElementOpenInModal;
    }

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div>
                <Document
                    {...this.props}
                    updateElementType={this.updateElementType}
                    saveContent={this.saveContent}
                    moveElementsToTrash={this.moveElementsToTrash}
                />
            </div>,
        );
    }
}

DocumentContainer.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string.isRequired,
    createNewElement: PropTypes.func,
    inList: PropTypes.string,
    canConnectLineEdge: PropTypes.bool,
    gridSize: PropTypes.number,
    isElementOpenInModal: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    dispatchSaveTextContent: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    isEditable: PropTypes.bool,
    connectLineEdgeDropTarget: PropTypes.func,
    dispatchUpdateElementType: PropTypes.func,
};

export default DocumentContainer;
