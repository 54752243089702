import * as Immutable from 'immutable';
import { useEffect, useState } from 'react';

// Web worker
// eslint-disable-next-line import/default
import ModalOrderWorker from './imageModalOrder.worker';

// Util
import { propIn } from '../../../../common/utils/immutableHelper';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { isColumn, isImage } from '../../../../common/elements/utils/elementTypeUtils';

import {
    getCanvasElements,
    getSortedChildrenInSectionViaGraph,
} from '../../../../common/elements/utils/elementGraphUtils';

import { BoardSections } from '../../../../common/boards/boardConstants';

const worker = new ModalOrderWorker();

const useOrderedImageElements = ({ currentBoardId, elements, elementGraph, measurements, gridSize }) => {
    const [orderedImageElements, setOrderedImageElements] = useState(Immutable.List());

    useEffect(() => {
        const canvasElements = getCanvasElements({
            elements,
            elementGraph,
            parentId: currentBoardId,
        });

        const canvasElementSizes = canvasElements.map((element) => {
            const elementId = getElementId(element);
            const width = (propIn([elementId, 'width'], measurements) || 1) / gridSize;
            const height = (propIn([elementId, 'height'], measurements) || 1) / gridSize;

            return {
                id: elementId,
                width,
                height,
            };
        });

        worker.postMessage({
            elements: canvasElements.toJS(),
            elementSizes: canvasElementSizes.toArray(),
        });

        worker.onmessage = (event) => {
            const order = event.data;

            const orderedCanvasElements = Immutable.List(order).map((id) => canvasElements.get(id));

            const inboxElements = getSortedChildrenInSectionViaGraph({
                elements,
                elementGraph,
                parentId: currentBoardId,
                section: BoardSections.INBOX,
            }).toList();

            let allElements = orderedCanvasElements.concat(inboxElements);

            // find columns
            const columnChildren = allElements.filter(isColumn).map((column) => [
                getElementId(column),
                getSortedChildrenInSectionViaGraph({
                    elements,
                    elementGraph,
                    parentId: getElementId(column),
                    section: BoardSections.INBOX,
                }).toList(),
            ]);

            // replace each column in the list with it's ordered children
            columnChildren.forEach(([columnId, children]) => {
                allElements = allElements.splice(
                    allElements.findIndex((element) => getElementId(element) === columnId),
                    0,
                    ...children.toArray(),
                );
            });

            const orderedImages = allElements.filter(isImage);

            setOrderedImageElements(orderedImages);
        };
    }, []);

    return orderedImageElements;
};

export default useOrderedImageElements;
