import { IN_LIST_MODE } from './listTypes';

type InListMode = typeof IN_LIST_MODE[keyof typeof IN_LIST_MODE] | null;

export const isInList = (inList: InListMode): boolean => !!inList;
export const isInColumnList = (inList: InListMode): boolean => inList === IN_LIST_MODE.IN_LIST_COLUMN;
export const isInQuickNotesList = (inList: InListMode): boolean => inList === IN_LIST_MODE.IN_LIST_QUICK_NOTES;
export const isInUnsortedNotesList = (inList: InListMode): boolean => inList === IN_LIST_MODE.IN_LIST_UNSORTED_NOTES;
export const isInDocumentModeList = (inList: InListMode): boolean => inList === IN_LIST_MODE.IN_LIST_DOCUMENT_MODE;
export const isInTrashList = (inList: InListMode): boolean => inList === IN_LIST_MODE.IN_LIST_TRASH;
