/**
 * Resizes a given image to a given size returns a base64 string of the resized image
 * @returns {string} Data URL of the resized image
 */
const drawResizedImageToCanvasContext = (img: HTMLImageElement, resizeWidth: number): string => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas context');

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    const resizeHeight = (img.height * resizeWidth) / img.width;

    canvas.width = resizeWidth;
    canvas.height = resizeHeight;
    ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight);

    return canvas.toDataURL();
};

export default drawResizedImageToCanvasContext;
