import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationNewTemplateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        className="Icon notification-new-template"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={12} height={12} x={0.5} y={0.5} fill="#D5D5D5" stroke="#B8B8B8" rx={1.8} />
            <rect width={12} height={12} x={5.5} y={5.5} fill="#B57FE5" stroke="#000" strokeOpacity={0.2} rx={1.8} />
        </g>
    </svg>
);
const Memo = memo(NotificationNewTemplateIcon);
export default Memo;
