// Lib
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Utils
import { getDisplayMode, getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { elementClassNames } from '../utils/elementUtil';
import { getFileData } from './utils/fileElementUtils';

import FileIconView from './fileIconView/FileIconView';
import FileDetailView from './fileDetailView/FileDetailView';

// Constants
import { ELEMENT_DISPLAY_MODES } from '../../../common/elements/elementDisplayModeConstants';

const File = (props) => {
    const {
        element,
        attachment,
        dispatchNavigateToElement,
        elementEvents,
        isPresentationModeEnabled,
        isReplaceModeHovered,
    } = props;

    const displayMode = getDisplayMode(element);

    const elementId = getElementId(element);
    const className = elementClassNames('File', { ...props, isReplaceModeHovered });

    const onDoubleClick = useCallback(
        (event) => {
            event && event.preventDefault();
            event && event.stopPropagation();

            // If the file is in detail view and we're in presentation mode, then just rely on focus mode modals
            if (displayMode === ELEMENT_DISPLAY_MODES.DETAIL_VIEW && isPresentationModeEnabled) return;

            dispatchNavigateToElement(elementId);
        },
        [dispatchNavigateToElement, element, displayMode],
    );

    /**
     * Open the file in a modal on double click, if the file type allows.
     */
    const elementEventUpdated = {
        ...elementEvents,
        onDoubleClick,
    };

    const fileData = getFileData({ element, attachment });

    // Don't render file if it has no file data (eg. is currently being uploaded by a remote user)
    if (!fileData) return null;

    switch (displayMode) {
        case ELEMENT_DISPLAY_MODES.ICON_VIEW:
            return <FileIconView {...props} elementEvents={elementEventUpdated} className={className} />;
        case ELEMENT_DISPLAY_MODES.DETAIL_VIEW:
        default:
            return <FileDetailView {...props} elementEvents={elementEventUpdated} className={className} />;
    }
};

File.propTypes = {
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    inList: PropTypes.string,
    gridSize: PropTypes.number,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    isLocked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    isPresentational: PropTypes.bool,
    isPresentationModeEnabled: PropTypes.bool,
    permissions: PropTypes.number,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    dispatchNavigateToElement: PropTypes.func,
    isReplaceModeHovered: PropTypes.bool,
};

export default File;
