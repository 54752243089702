import { defer, isString } from 'lodash/fp';

import {
    onboardingGotoStep,
    onboardingSetStep,
    onboardingAddListener,
    onboardingRemoveListener,
    markEducationCodeAsSeen,
} from './onboardingActions';

import { selectStep } from './onboardingUtil';

import { hasSeen } from '../../../common/users/userEducationUtil';
import { getUserEducation } from '../../../common/users/utils/userPropertyUtils';
import { getCurrentUser } from '../../user/currentUserSelector';
import { getCurrentOnboardingStep, getCurrentOnboardingStepId, getOnboardingListeners } from './onboardingSelector';

import preloadImages from '../../utils/preloadImages';
import preloadOnboardingSteps from './config/preloadOnboardingSteps';

import ONBOARDING_LISTENERS from './config/onboardingListenersConfig';

import { ONBOARDING_GOTO_STEP, ONBOARDING_SET_STEP } from './onboardingConstants';

import { CURRENT_USER_SET } from '../../user/currentUserConstants';
import { ELECTRON_STORE_REHYDRATED } from '../../../common/electron/electronConstants';

const attachListeners = (dispatch, state) => {
    const currentUser = getCurrentUser(state);
    const education = getUserEducation(currentUser);

    const listenersToAttach = ONBOARDING_LISTENERS.filter((listener) => {
        const { attachmentPredicate } = listener;

        const shouldAttach = !hasSeen(education, listener.educationCode);
        return attachmentPredicate ? attachmentPredicate(state) && shouldAttach : shouldAttach;
    });

    // if user has any listners, load the onboarding steps
    if (listenersToAttach.length) dispatch(preloadOnboardingSteps());

    return listenersToAttach.forEach((listener) => {
        // if listener has images to preload for the tooltip, load them
        if (listener.preloadImages) preloadImages(listener.preloadImages);
        // add listener to store, ready to be watched for
        dispatch(onboardingAddListener(listener));
    });
};

const testListeners = (listeners, action, state, hasCurrentStep) =>
    listeners
        .filter((listener) => (hasCurrentStep && listener.get('overrideCurrentStep')) || !hasCurrentStep)
        .find((listener) => listener.get('predicate')(action, state));

const dispatchListenerAction = (store) => (listener, action) => {
    if (!listener) return;

    store.dispatch(onboardingRemoveListener(listener.get('id')));

    let gotoStep = listener.get('gotoStep');

    if (gotoStep) {
        gotoStep = isString(gotoStep) ? gotoStep : gotoStep(store.getState(), action);
        store.dispatch(onboardingGotoStep(gotoStep, action));
    }
    if (listener.get('step')) store.dispatch(onboardingSetStep(listener.get('step'), action));
    if (listener.get('educationCode')) store.dispatch(markEducationCodeAsSeen(listener.get('educationCode')));
};

const dispatchNextStepCallbacks = (store) => (nextStep, action) => {
    if (nextStep && nextStep.get('dispatch')) defer(store.dispatch, nextStep.get('dispatch')(action));
    if (nextStep && nextStep.get('callback')) defer(nextStep.get('callback'));
};

const dispatchSetStep = (store) => (step, action) =>
    defer(() => {
        // don't go to the same step twice
        if (getCurrentOnboardingStepId(store.getState()) === step) return;

        // Track onboarding step in Amplitude — disabled for now
        // sendAmplitudeEvent({ eventType: 'Onboarding step', eventProperties: { step } });

        store.dispatch(onboardingSetStep(selectStep(step, action, store.getState()), action));
    });

const getNextMiddleware = (next, action, currentStep) => {
    if (
        currentStep &&
        currentStep.getIn(['disable', 'actions']) &&
        currentStep.getIn(['disable', 'actions']).includes(action.type)
    ) {
        return () => null;
    }

    return () => next(action);
};

export default (store) => {
    const handleNextStepCallbacks = dispatchNextStepCallbacks(store);
    const handleListenerAction = dispatchListenerAction(store);
    const handleSetStep = dispatchSetStep(store);

    return (next) => (action) => {
        const state = store.getState();
        const currentStep = getCurrentOnboardingStep(state);
        const hasCurrentStep = currentStep && currentStep.get('id') !== 'empty-step';
        const listeners = getOnboardingListeners(state);
        const hasListeners = listeners && listeners.size;

        const runNextMiddleware = getNextMiddleware(next, action, currentStep);

        if (action.type === CURRENT_USER_SET || action.type === ELECTRON_STORE_REHYDRATED) {
            const nextMiddleware = runNextMiddleware();

            const currentUser = store.getState().getIn(['app', 'currentUser']);
            if (!currentUser.get('fetched')) return nextMiddleware;

            attachListeners(store.dispatch, store.getState());

            return nextMiddleware;
        }

        if (action.type === ONBOARDING_GOTO_STEP) {
            const nextMiddleware = runNextMiddleware();

            handleSetStep(action.step, action.meta);

            return nextMiddleware;
        }

        if (action.type === ONBOARDING_SET_STEP) {
            handleNextStepCallbacks(action.step, action.meta);
        }

        const nextStepFromAction =
            currentStep && currentStep.get('nextStep') && currentStep.get('nextStep')(action, state);

        if (nextStepFromAction) {
            handleSetStep(nextStepFromAction, action);
        }

        if (!hasCurrentStep && !hasListeners) return runNextMiddleware();

        if (hasListeners) {
            const result = runNextMiddleware();

            handleListenerAction(testListeners(listeners, action, state, hasCurrentStep), action);

            return result;
        }

        return runNextMiddleware();
    };
};
