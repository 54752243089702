// Lib
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

// Utils
import { decodeParams } from '../../common/utils/urlUtil';

// Actions
import { handleSuccessfulAuthentication } from './authActions';
import { navigateToRootWorkspace } from '../reducers/navigationActions';

// Constants
import { APP_QUERY_PARAMS } from '../../common/utils/urlConstants';

const getToken = get([APP_QUERY_PARAMS.AUTH_TOKEN]);

export default (onSuccessActionFn) => {
    const ReferralRedirect = ({ location }) => {
        const dispatch = useDispatch();
        useEffect(() => {
            const dispatchOnSuccess = (token, queryParams) =>
                dispatch((_dispatch, getState) => {
                    onSuccessActionFn && dispatch(onSuccessActionFn(queryParams));

                    // use replace so the token doesn't get into the browser history stack
                    handleSuccessfulAuthentication({ replaceUrl: true, dispatch, getState })({ data: { token } });
                });
            const dispatchNavigateToRootWorkspace = () => dispatch(navigateToRootWorkspace(true));

            const hashParams = decodeParams(location.hash);
            const queryParams = location.query;

            const token = getToken(hashParams) || getToken(queryParams);

            if (token) {
                dispatchOnSuccess(token, queryParams);
                return;
            }
            dispatchNavigateToRootWorkspace();
        }, [location]);

        return null;
    };

    ReferralRedirect.propTypes = {
        location: PropTypes.object,
    };

    return ReferralRedirect;
};
