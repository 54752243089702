import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarDisconnectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-disconnect"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M6 20a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-5zm1.5.5v4h4v-4h-4zM19 7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V7zm1.5.5v4h4v-4h-4z"
            />
            <path stroke="#48505E" strokeLinecap="round" strokeWidth={1.5} d="m20 12-2 2m-4 4-2 2" />
            <path stroke="#48505E" strokeDasharray="2,2" strokeLinecap="round" d="M21 21 11 11" />
        </g>
    </svg>
);
const Memo = memo(ToolbarDisconnectIcon);
export default Memo;
