// Lib
import { defer } from 'lodash';

// Utils
import { isControlKeyCommand, hasCommandModifier, hasAltModifier } from '../../../../utils/keyboard/keyboardUtility';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { ELEMENT_CREATION_SOURCES } from '../../../../../common/elements/elementConstants';

export default (event, editorState, { getProps }) => {
    const { createNewCard, saveCurrentContent } = getProps();
    if (createNewCard && (hasCommandModifier(event) || isControlKeyCommand(event))) {
        saveCurrentContent();

        defer(() => createNewCard({ creationSource: ELEMENT_CREATION_SOURCES.CMD_ENTER }));
        return HANDLER_RETURN_VALUES.handled;
    }

    if (hasAltModifier(event)) {
        saveCurrentContent();
        return HANDLER_RETURN_VALUES.handled;
    }

    return HANDLER_RETURN_VALUES.notHandled;
};
