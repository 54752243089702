// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import {
    getElementId,
    getIsTransparentImage,
    getShowBackground,
    getShowFullAspectRatio,
} from '../../../common/elements/utils/elementPropertyUtils';

// Components
import ContextMenuItemToggle from './ContextMenuItemToggle';

const ImageAspectRatioContextMenuItem = (props) => {
    const { contextMenuHandlers, allImages, selectedElements, dispatchUpdateElements } = props;

    if (!allImages) return null;

    if (selectedElements.every((el) => !getShowBackground(el) && getIsTransparentImage(el))) return null;

    const isShowingFullAspectRatio = getShowFullAspectRatio(selectedElements.first());

    const onClick = React.useCallback(() => {
        dispatchUpdateElements({
            updates: selectedElements.toArray().map((element) => ({
                id: getElementId(element),
                changes: {
                    showFullAspectRatio: !isShowingFullAspectRatio,
                },
            })),
        });
    }, []);

    return (
        <ContextMenuItemToggle
            {...contextMenuHandlers}
            show
            active={!isShowingFullAspectRatio}
            onClick={onClick}
            title="Crop Image to Fit Dot Grid"
            id="imageAspectRatio"
        />
    );
};

ImageAspectRatioContextMenuItem.propTypes = {
    allImages: PropTypes.bool,
    contextMenuHandlers: PropTypes.object,
    updateImageAspectRatioPreference: PropTypes.func,

    selectedElements: PropTypes.object,
    dispatchUpdateElements: PropTypes.func,
};

export default ImageAspectRatioContextMenuItem;
