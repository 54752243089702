export const EXPORT_BOARD_STATES = {
    FETCHING: 'FETCHING',
    FAILED: 'FAILED',
};

export const EXPORT_ROOT_ELEMENT_ID = 'export-root';

export const DOWNLOADING_STATUSES = {
    GENERATING: 'GENERATING',
    DOWNLOADING: 'DOWNLOADING',
    ERROR: 'ERROR',
};
