import HttpStatus from 'http-status-codes';
import { omit } from 'lodash';

function AppError(error) {
    Error.call(this, error.message);
    Error.captureStackTrace(this, this.constructor);
    this.name = error.name || 'ApplicationError';
    this.message = error.message;
    this.code = error.code;
    this.type = error.type;
    this.status = error.status || HttpStatus.BAD_REQUEST;
    this.details = error.details;
    this.inner = omit(error, ['status', 'code', 'message', 'name', 'details']);
}

AppError.prototype = Object.create(Error.prototype);
AppError.prototype.constructor = AppError;

export default AppError;
