// Lib
import { isString } from 'lodash/fp';

// Utils
import { asObject, prop, propIn } from '../utils/immutableHelper';

// Constants
import { NOTIFICATION_TYPES } from './notificationConstants';

// Getters
export const getNotificationType = (notificationType) =>
    isString(notificationType) ? notificationType : prop('type', notificationType);

export const getNotificationId = prop('_id');
export const getNotificationUserId = prop('userId');
export const getNotificationTimestamp = prop('timestamp');
export const getNotificationViewedTimestamp = prop('viewedTimestamp');
export const getNotificationObservedTimestamp = prop('observedTimestamp');
export const getNotificationActorIds = prop('actorIds');
export const getNotificationActorIdsAsArray = (notification) => asObject(getNotificationActorIds(notification) || []);
export const getNotificationDetails = prop('details');
export const getNotificationElementId = propIn(['details', 'elementId']);
export const getNotificationPermissionId = propIn(['details', 'permissionId']);
export const getNotificationBoardTitle = propIn(['details', 'boardTitle']);
export const getNotificationAncestorTitles = propIn(['details', 'ancestorTitles']);
export const getNotificationAncestorTitlesAsArray = (notification) =>
    asObject(getNotificationAncestorTitles(notification) || []);

// Board update
export const getNotificationElementChanges = propIn(['details', 'elementChanges']);
export const getNotificationElementChangesAsObject = (data) => asObject(getNotificationElementChanges(data) || {});
export const getNotificationElementChangeCount = (notification) =>
    Object.keys(getNotificationElementChangesAsObject(notification)).length;
export const getNotificationElementChangeIsSeen = (notification, elementId) =>
    propIn(['details', 'elementChanges', elementId, 'seen'], notification) || false;
export const getNotificationCommentChangeIsSeen = (notification, commentId) =>
    propIn(['details', 'commentChanges', commentId, 'seen'], notification) || false;
// Unshare
export const getNotificationBoardColor = propIn(['details', 'boardColor']);
// Shared
export const getNotificationMessage = propIn(['details', 'message']);
// Comment
export const getNotificationMentionElementId = propIn(['details', 'mentionElementId']);
export const getNotificationMentionCommentId = propIn(['details', 'mentionCommentId']);
export const getNotificationMentionCommentThreadId = propIn(['details', 'mentionCommentThreadId']);
export const getNotificationMentionElementType = propIn(['details', 'mentionElementType']);
// @deprecated
export const getNotificationMentionRange = propIn(['details', 'mentionRange']);
export const getNotificationMentionRanges = propIn(['details', 'mentionRanges']);
export const getNotificationMentionRangesAsArray = (notification) =>
    asObject(getNotificationMentionRanges(notification) || []);
// Share Reminder
export const getNotificationShareTimestamp = propIn(['details', 'shareTimestamp']);
export const getNotificationShareActorId = propIn(['details', 'shareActorId']);
// New template
export const getNotificationCollectionId = propIn(['details', 'collectionId']);
export const getNotificationCollectionTitle = propIn(['details', 'collectionTitle']);
export const getNotificationTemplateIds = propIn(['details', 'templateIds']);
export const getNotificationTemplateIdsAsArray = (notification) =>
    asObject(getNotificationTemplateIds(notification) || []);
export const getNotificationTemplateTitles = propIn(['details', 'templateTitles']);
export const getNotificationTemplateTitlesAsArray = (notification) =>
    asObject(getNotificationTemplateTitles(notification) || []);
// Comment
export const getNotificationCommentThreadIds = propIn(['details', 'commentThreadIds']);
export const getNotificationCommentThreadIdsAsArray = (notification) =>
    asObject(getNotificationCommentThreadIds(notification) || []);
export const getNotificationCommentIds = propIn(['details', 'commentIds']);
export const getNotificationCommentIdsAsArray = (notification) =>
    asObject(getNotificationCommentIds(notification) || []);
export const getNotificationCommentIsReply = propIn(['details', 'isReply']);

// Assignments
export const getNotificationAssignments = propIn(['details', 'assignments']);
export const getNotificationAssignmentsAsArray = (notification) =>
    asObject(getNotificationAssignments(notification) || []);
// Assignment entry getters
export const getNotificationAssignmentElementId = prop('assignedElementId');
export const getNotificationAssignmentAssignerId = prop('assignerUserId');
export const getNotificationAssignmentAssigneeId = prop('assigneeId');
export const getNotificationAssignmentText = prop('text');

// Reminders
export const getNotificationReminders = propIn(['details', 'reminders']);
export const getNotificationRemindersAsArray = (notification) => asObject(getNotificationReminders(notification) || []);
// Reminder entry getters
export const getNotificationReminderElementId = prop('elementId');
export const getNotificationReminderText = prop('text');
export const getNotificationReminderDueDate = prop('dueDate');
export const getNotificationReminderHasDueDateTime = prop('hasDueDateTime');

// Reactions
export const getNotificationReactionElementIds = propIn(['details', 'reactedElementIds']);
export const getNotificationReactionElementIdsAsArray = (notification) =>
    asObject(getNotificationReactionElementIds(notification) || []);

const isNotificationType = (targetType) => (inputType) => getNotificationType(inputType) === targetType;

export const isTaskReminderNotification = isNotificationType(NOTIFICATION_TYPES.BOARD_TASKS_REMINDER);
export const isAssignmentNotification = isNotificationType(NOTIFICATION_TYPES.ASSIGNMENT);
export const isMentionNotification = isNotificationType(NOTIFICATION_TYPES.MENTION);
export const isShareNotification = isNotificationType(NOTIFICATION_TYPES.SHARE);
export const isShareReminderNotification = isNotificationType(NOTIFICATION_TYPES.SHARE_REMINDER);
export const isCommentNotification = isNotificationType(NOTIFICATION_TYPES.COMMENT);
export const isBoardViewNotification = isNotificationType(NOTIFICATION_TYPES.BOARD_VIEW);
export const isBoardUpdateNotification = isNotificationType(NOTIFICATION_TYPES.BOARD_UPDATE);
export const isBoardTasksUpdateNotification = isNotificationType(NOTIFICATION_TYPES.BOARD_TASKS_UPDATE);
export const isReactionUpdateNotification = isNotificationType(NOTIFICATION_TYPES.REACTION);

export const sortNotificationsNewestFirst = (notificationA, notificationB) =>
    getNotificationTimestamp(notificationB) - getNotificationTimestamp(notificationA);
