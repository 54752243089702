import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ErrorAlertRoundIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon error-alert-round"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#FF8D22" />
            <path
                fill="#FFF"
                d="M8 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM8 3a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1z"
            />
        </g>
    </svg>
);
const Memo = memo(ErrorAlertRoundIcon);
export default Memo;
