// Utils
import { getSelection } from '../domUtil';
import { getClosestDomElement } from './domTraversalUtils';

/**
 * Determines if the current DOM selection focus is within the DOM Element provided.
 */
export const isDomSelectionFocusInside = (domElement) => {
    if (!domElement) return false;

    const focusNode = getSelection()?.focusNode;

    if (!focusNode) return false;

    const focusElement = getClosestDomElement(focusNode);

    if (!focusElement) return false;

    return domElement.contains(focusElement);
};
