import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLineWeightIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-line-weight"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M23 21a1.5 1.5 0 0 1 .144 2.993L23 24H9a1.5 1.5 0 0 1-.144-2.993L9 21h14zm.5-7a1 1 0 0 1 .117 1.993L23.5 16h-15a1 1 0 0 1-.117-1.993L8.5 14h15zm.5-6a.5.5 0 0 1 .09.992L24 9H8a.5.5 0 0 1-.09-.992L8 8h16z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarLineWeightIcon);
export default Memo;
