// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Editing helper plugin
import createMentionsEditingHelperPlugin from '../mentionsEditingHelper/index';
// COPIED FROM draft-js-mention-plugin
import createMentionPlugin from '../draftMentionsPlugin';

// Utils
// FIXME Clean up this function
import positionSuggestions from './positionSuggestions';

// Selectors
import { getUserMentionSuggestionsWhenEditing } from './mentionsSelector';

// Components
import MilanoteEditor from '../../milanoteEditor/MilanoteEditor';
import MentionEntity from './MentionEntity';
import MentionSuggestions from './MentionSuggestions';

// Constants
import { DRAFT_ENTITY_MUTABILITY } from '../../draftjsConstants';

const mapStateToProps = () =>
    createStructuredSelector({
        possibleSuggestions: getUserMentionSuggestionsWhenEditing(),
    });

@connect(mapStateToProps)
class MilanoteMentionsEditor extends React.Component {
    constructor(props) {
        super(props);

        const mentionsEditingHelperPlugin = createMentionsEditingHelperPlugin();

        this.mentionPlugin = createMentionPlugin({
            entityMutability: DRAFT_ENTITY_MUTABILITY.IMMUTABLE,
            positionSuggestions,
            mentionComponent: MentionEntity,
            mentionPrefix: '@',
            theme: {
                mentionSuggestionsEntryFocused: 'focused',
                mentionSuggestionsEntry: null,
                mentionSuggestions: 'MentionSuggestions',
            },
        });

        this.plugins = [this.mentionPlugin, mentionsEditingHelperPlugin, ...props.plugins];
    }

    render() {
        const { possibleSuggestions, onAddMention, children, isEditing } = this.props;

        return (
            <MilanoteEditor {...this.props} plugins={this.plugins}>
                {(...args) => (
                    <div className="editor-children">
                        {children && children(...args)}
                        <MentionSuggestions
                            isEditing={isEditing}
                            Component={this.mentionPlugin.MentionSuggestions}
                            possibleSuggestions={possibleSuggestions}
                            onAddMention={onAddMention}
                        />
                    </div>
                )}
            </MilanoteEditor>
        );
    }
}

MilanoteMentionsEditor.propTypes = {
    plugins: PropTypes.array.isRequired,
    possibleSuggestions: PropTypes.array,
    onAddMention: PropTypes.func,
    children: PropTypes.func,
    isEditing: PropTypes.bool,
};

export default MilanoteMentionsEditor;
