// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FileInput from '../../../components/tools/files/FileInput';
import Icon from '../../../components/icons/Icon';

class FileUploaderContent extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    /**
     * Delegates the click event on the fake file upload input to the real, hidden one.
     */
    openFileSelect = (event) => {
        this.fileInputRef.current && this.fileInputRef.current.click();
    };

    render() {
        const { uploadFile, elementId, type, message, hint, accept, isPresentational } = this.props;

        return (
            <div className="uploader-content">
                <div className="click-area" onClick={isPresentational ? null : this.openFileSelect}>
                    <FileInput
                        ref={this.fileInputRef}
                        id={elementId}
                        uploadFile={uploadFile}
                        accept={accept}
                        multiple
                    />

                    <div className={`${type}-icon-container`}>
                        <Icon name={`${type}-form-upload`} />
                    </div>

                    {message && <span className="cta">{message}</span>}
                </div>
                {hint && <p className="hint">{hint}</p>}
            </div>
        );
    }
}

FileUploaderContent.propTypes = {
    elementId: PropTypes.string,
    uploadFile: PropTypes.func,
    type: PropTypes.string,
    message: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    accept: PropTypes.string,
    isPresentational: PropTypes.bool,
};

export default FileUploaderContent;
