import { createSelector } from 'reselect';

import { prop } from '../../../common/utils/immutableHelper';
import { isContent } from '../../../common/elements/utils/elementTypeUtils';

// Constants

import { ElementType } from '../../../common/elements/elementTypes';

export const elementCountSelector = prop('elementCount');

export const contentElementCountSelector = createSelector(elementCountSelector, (elementCount) =>
    elementCount.filter((count, type) => isContent(type)),
);

export const contentTotalCountSelector = createSelector(contentElementCountSelector, (elementCount) =>
    elementCount.reduce((count, typeCount) => count + typeCount, 0),
);

export const fileCountSelector = createSelector(
    contentElementCountSelector,
    (elementCount) => elementCount.get(ElementType.FILE_TYPE) || 0,
);

export const contentTotalLimitSelector = (state) => state.getIn(['app', 'currentUser', 'contentLimit', 'current']);
export const contentLimitExceededSelector = (state) =>
    state.getIn(['app', 'currentUser', 'contentLimit', 'exceeded']) || false;
