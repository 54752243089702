import React from 'react';
import { Map } from 'immutable';
import Mention from './Mention';
import MentionSuggestions from './MentionSuggestions';
import MentionSuggestionsPortal from './MentionSuggestionsPortal';
import defaultRegExp from './defaultRegExp';
import mentionStrategy from './mentionStrategy';
import mentionSuggestionsStrategy from './mentionSuggestionsStrategy';
import suggestionsFilter from './utils/defaultSuggestionsFilter';
import defaultPositionSuggestions from './utils/positionSuggestions';

export const defaultTheme = {
    // CSS class for mention text
    mention: 'mention',
    // CSS class for suggestions component
    mentionSuggestions: 'mentionSuggestions',
    // CSS classes for an entry in the suggestions component
    mentionSuggestionsEntry: 'mentionSuggestionsEntry',
    mentionSuggestionsEntryFocused: 'mentionSuggestionsEntryFocused',
    mentionSuggestionsEntryText: 'mentionSuggestionsEntryText',
    mentionSuggestionsEntryAvatar: 'mentionSuggestionsEntryAvatar',
};

export default (config = {}) => {
    const callbacks = {
        keyBindingFn: undefined,
        handleKeyCommand: undefined,
        handleReturn: undefined,
        onChange: undefined,
    };

    const ariaProps = {
        ariaHasPopup: 'false',
        ariaExpanded: false,
        ariaOwneeID: undefined,
        ariaActiveDescendantID: undefined,
    };

    let searches = Map();
    let escapedSearch;
    let clientRectFunctions = Map();
    let isOpened;

    const store = {
        getEditorState: undefined,
        setEditorState: undefined,
        getPortalClientRect: (offsetKey) => clientRectFunctions.get(offsetKey)(),
        getAllSearches: () => searches,
        isEscaped: (offsetKey) => escapedSearch === offsetKey,
        escapeSearch: (offsetKey) => {
            escapedSearch = offsetKey;
        },

        resetEscapedSearch: () => {
            escapedSearch = undefined;
        },

        register: (offsetKey) => {
            searches = searches.set(offsetKey, offsetKey);
        },

        updatePortalClientRect: (offsetKey, func) => {
            clientRectFunctions = clientRectFunctions.set(offsetKey, func);
        },

        unregister: (offsetKey) => {
            searches = searches.delete(offsetKey);
            clientRectFunctions = clientRectFunctions.delete(offsetKey);
        },

        getIsOpened: () => isOpened,
        setIsOpened: (nextIsOpened) => {
            isOpened = nextIsOpened;
        },
    };

    // Styles are overwritten instead of merged as merging causes a lot of confusion.
    //
    // Why? Because when merging a developer needs to know all of the underlying
    // styles which needs a deep dive into the code. Merging also makes it prone to
    // errors when upgrading as basically every styling change would become a major
    // breaking change. 1px of an increased padding can break a whole layout.
    const {
        mentionPrefix = '',
        theme = defaultTheme,
        positionSuggestions = defaultPositionSuggestions,
        mentionComponent,
        mentionSuggestionsComponent: MentionSuggestionsComponent = MentionSuggestions,
        entityMutability = 'SEGMENTED',
        mentionTrigger = '@',
        mentionRegExp = defaultRegExp,
        supportWhitespace = false,
    } = config;
    const mentionSearchProps = {
        ariaProps,
        callbacks,
        theme,
        store,
        entityMutability,
        positionSuggestions,
        mentionTrigger,
        mentionPrefix,
    };
    const DecoratedMentionSuggestionsComponent = (props) => (
        <MentionSuggestionsComponent {...props} {...mentionSearchProps} />
    );
    const DecoratedMention = (props) => <Mention {...props} theme={theme} mentionComponent={mentionComponent} />;
    const DecoratedMentionSuggestionsPortal = (props) => <MentionSuggestionsPortal {...props} store={store} />;
    return {
        MentionSuggestions: DecoratedMentionSuggestionsComponent,
        decorators: [
            {
                strategy: mentionStrategy(mentionTrigger),
                component: DecoratedMention,
            },
            {
                strategy: mentionSuggestionsStrategy(mentionTrigger, supportWhitespace, mentionRegExp),
                component: DecoratedMentionSuggestionsPortal,
            },
        ],
        getAccessibilityProps: () => ({
            role: 'combobox',
            ariaAutoComplete: 'list',
            ariaHasPopup: ariaProps.ariaHasPopup,
            ariaExpanded: ariaProps.ariaExpanded,
            ariaActiveDescendantID: ariaProps.ariaActiveDescendantID,
            ariaOwneeID: ariaProps.ariaOwneeID,
        }),

        initialize: ({ getEditorState, setEditorState }) => {
            store.getEditorState = getEditorState;
            store.setEditorState = setEditorState;
        },

        keyBindingFn: (keyboardEvent) => callbacks.keyBindingFn && callbacks.keyBindingFn(keyboardEvent),
        handleReturn: (keyboardEvent) => callbacks.handleReturn && callbacks.handleReturn(keyboardEvent),
        onChange: (editorState) => {
            if (callbacks.onChange) return callbacks.onChange(editorState);
            return editorState;
        },
    };
};

export const defaultSuggestionsFilter = suggestionsFilter;
