import * as Immutable from 'immutable';

const EMPTY_STEP = Immutable.Map();

let onboardingSteps;

export const preloadOnboardingSteps = () =>
    import(/* webpackChunkName: "onboarding-content" */ './onboardingStepsConfig').then((loadedSteps) => {
        onboardingSteps = loadedSteps.default;
    });

export default (stepId) => {
    if (!onboardingSteps) {
        console.warn(`Trying to load onboarding step "${stepId}", but step config isn't loaded yet`);
        return EMPTY_STEP;
    }

    return onboardingSteps.get(stepId);
};
