import { HybridElementEditStartAction } from '../hybridMiddlewareConstants';
import { ReduxStore } from '../../../../types/reduxTypes';
import { AnyAction } from 'redux';
import { ELEMENT_EDIT_START } from '../../../../../common/elements/selectionConstants';
import { createHybridElementEditStartAction } from './createHybridActions/createHybridElementEditStartAction';
import platformSingleton from '../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../platform/platformTypes';
import { ElementType } from '../../../../../common/elements/elementTypes';

/*  ### Reacting to ELEMENT_EDIT_START
 *
 *  Transforms all ELEMENT_EDIT_START actions to HYBRID_ELEMENT_EDIT_START actions.
 *
 *  See 'createHybridElementEditStartAction.ts'.
 */

const shouldOverrideElementEditStart = (store: ReduxStore, action: AnyAction): boolean => {
    if (action.type === ELEMENT_EDIT_START) {
        switch (platformSingleton.legacyHybridUseCase) {
            case LegacyHybridUseCase.IOS_BOARD_LIST:
            case LegacyHybridUseCase.IOS_CANVAS:
            case LegacyHybridUseCase.ANDROID_BOARD_LIST:
                // If the element being edited is a Comment Thread, we leave the action
                // unmodified, and filter it out in our filter step.
                if (action.editorKey === ElementType.COMMENT_THREAD_TYPE) return false;
                return true;
            case LegacyHybridUseCase.IPAD_OS:
                return false;
            default:
                return false;
        }
    }
    return false;
};

const getHybridActionForElementEditStart = (
    state: any,
    action: AnyAction,
): HybridElementEditStartAction | undefined => {
    if (!shouldOverrideElementEditStart(state, action)) return;
    const elementId = action.id;
    return createHybridElementEditStartAction(state, action, elementId);
};

// Export

export default (state: any, action: AnyAction): HybridElementEditStartAction | undefined =>
    getHybridActionForElementEditStart(state, action);
