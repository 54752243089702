// Lib
import { isString } from 'lodash/fp';

// Utils
import { getEditorType } from '../editorTypeUtils';
import rawHasText from '../../../utils/editor/rawUtils/rawHasText';

// Types
import { TiptapContent, TiptapNodeType } from '../../tiptapTypes';
import { ElementEditor } from '../../../elements/elementEditorConstants';
import { ImMap, prop } from '../../../utils/immutableHelper';
import { EditorContent, ImEditorContent } from '../../../elements/elementModelTypes';
import { reduceImTiptapContent } from './reduceImTiptapContent';

/**
 * This uses reduceImTiptapContent rather than reduceTiptapContent because it's
 * more efficient for Immutable objects - and this will be used in the render phase
 * of DocumentDetailView - thus we want it to be as performant as possible.
 */
const hasTiptapText = (node: ImMap<TiptapContent> | TiptapContent): boolean =>
    reduceImTiptapContent(
        node,
        (acc, tiptapNode) => {
            if (acc) return acc;

            const text = prop('text', tiptapNode);

            if (text) return true;

            const type = prop('type', tiptapNode);

            return type === TiptapNodeType.mention;
        },
        false,
    );

/**
 * Determines if the editor JSON content has any text,
 * regardless of whether it's a Tiptap, Draft.js editor or string.
 */
const doesEditorJsonHaveText = (content: ImEditorContent | EditorContent | string | undefined): boolean => {
    if (!content) return false;

    if (isString(content)) return !!content;

    const editorType = getEditorType(content);

    if (editorType === ElementEditor.Tiptap) {
        return hasTiptapText(content as ImMap<TiptapContent> | TiptapContent);
    }

    return rawHasText(content);
};

export default doesEditorJsonHaveText;
