// Lib
import { includes, union, difference } from 'lodash/fp';

// Types
import { IdGraph } from './graphTypes';

type TraversalEntry = {
    id: string;
    depth: number;
};

type TraversalResult = TraversalEntry[];

/**
 * Gets the child IDs for a given parent ID.
 */
export const getChildIds = (graph: IdGraph, parentId: string): string[] => graph[parentId] || [];

/**
 * Gets all the child IDs for multiple parent IDs.
 */
export const getAllChildIds = (graph: IdGraph, parentIds: string[]): string[] => {
    if (!parentIds) return [];
    return parentIds.reduce((acc, id) => acc.concat(getChildIds(graph, id)), [] as string[]);
};

/**
 * Returns a breadth first traversal of a graph, including the depth of the vertex.
 */
export const breadthFirstTraversal = (graph: IdGraph, startingId: string, maxDepth = 0): TraversalResult => {
    const visited = {} as { [key: string]: boolean };
    const queue = [{ id: startingId, depth: 0 }] as TraversalEntry[];
    const result = [];

    while (queue.length > 0) {
        const { id, depth } = queue.shift() as TraversalEntry;
        const childIds = getChildIds(graph, id);

        if (visited[id]) continue;

        result.push({ id, depth });
        visited[id] = true;

        if (maxDepth && depth === maxDepth) continue;

        queue.push(
            ...childIds.map((childId) => {
                return { id: childId, depth: depth + 1 };
            }),
        );
    }

    return result;
};

type TraversalOptions = {
    // This predicate function is used to determine if a node should be traversed.
    //
    // NOTE: Failing the predicate will stop the children of the element to be traversed,
    // but it will still include the element itself in the result.
    traversalPredicateFn?: (elementId: string) => boolean;
};

type DepthFirstTraversalOptions = TraversalOptions & {
    maxDepth?: number;
    visited?: { [key: string]: boolean };
    depth?: number;
    result?: TraversalResult;
};

/**
 * Returns a depth first traversal of a graph, including the depth of the vertex.
 * Result is in the form { id, depth }.
 */
export const depthFirstTraversal = (
    graph: IdGraph,
    startingId: string,
    options: DepthFirstTraversalOptions = {},
): TraversalResult => {
    const { traversalPredicateFn = () => true, maxDepth = 0, visited = {}, depth = 0, result = [] } = options;

    const childIds = getChildIds(graph, startingId);
    const alreadyVisited = visited[startingId];

    if (!childIds || alreadyVisited) return result;

    result.push({ id: startingId, depth });
    visited[startingId] = true;

    if (!traversalPredicateFn(startingId)) return result;
    if (maxDepth && depth === maxDepth) return result;

    return childIds.reduce(
        (res, childId) =>
            depthFirstTraversal(graph, childId, {
                traversalPredicateFn: traversalPredicateFn,
                maxDepth,
                visited,
                depth: depth + 1,
                result,
            }),
        result,
    );
};

/**
 * The same as depth first search, but it doesn't track depth and should be more efficient
 * because it doesn't perform object allocations.
 * This is useful for large graph traversal that happens regularly.
 */
export const simpleDepthFirstTraversal = (
    graph: IdGraph,
    startingId: string,
    visited = {} as { [key: string]: boolean },
    result = [] as string[],
): string[] => {
    if (!startingId) return result;

    const childIds = graph[startingId] || [];
    const alreadyVisited = visited[startingId];

    if (!childIds || alreadyVisited) return result;

    result.push(startingId);
    visited[startingId] = true;

    return childIds.reduce((res, childId) => simpleDepthFirstTraversal(graph, childId, visited, result), result);
};

export const getFromDepthFirstTraversal = (
    dfsResult: TraversalResult,
    id: string,
): TraversalEntry | null | undefined => {
    if (!dfsResult || !id) return null;
    return dfsResult.find((entry) => entry.id === id);
};

export const getIndexFromDepthFirstTraversal = (dfsResult: TraversalResult, id: string): number => {
    if (!dfsResult || !id) return -1;
    return dfsResult.findIndex((entry) => entry.id === id);
};

export const getAllIdsStartingFrom = (graph: IdGraph, startingId: string, options: TraversalOptions = {}): string[] =>
    depthFirstTraversal(graph, startingId, options).map((entry) => entry.id);

export const getDescendantIds = (graph: IdGraph, parentId: string, options: TraversalOptions = {}): string[] =>
    getAllIdsStartingFrom(graph, parentId, options).slice(1);

export const isDescendantId = (graph: IdGraph, parentId: string, targetId: string): boolean => {
    const descendants = getDescendantIds(graph, parentId);
    return includes(targetId, descendants);
};

/**
 * Returns only the portion of the graph that can be reached from the starting ID.
 */
export const getSubGraph = (graph: IdGraph, startingId: string, subGraph = {} as IdGraph): IdGraph => {
    // Prevent infinite loops
    if (subGraph[startingId]) return subGraph;

    const childIds = getChildIds(graph, startingId);

    subGraph[startingId] = childIds;

    childIds.forEach((childId) => getSubGraph(graph, childId, subGraph));

    return subGraph;
};

/**
 * Finds all the groups for a disconnected graph.
 */
export const getConnectedGroups = (graph: IdGraph): string[][] => {
    let unvisitedNodes = Object.keys(graph);
    let visitedNodes: string[] = [];
    const groups = [];

    while (unvisitedNodes.length > 0) {
        const currentNode = unvisitedNodes[0];
        const treeIds = getAllIdsStartingFrom(graph, currentNode);

        groups.push(treeIds);

        visitedNodes = union(visitedNodes, treeIds);
        unvisitedNodes = difference(unvisitedNodes, visitedNodes);
    }

    return groups;
};
