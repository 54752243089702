// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { isCard, isColumn, isCommentThread, isDocument } from '../../../common/elements/utils/elementTypeUtils';

// Types
import { MNElement } from '../../../common/elements/elementModelTypes';
import { DraggedItem } from '../../canvas/dnd/dragTypes';

// Constants
const DRAGGING_CLASS = 'dragging';

const CODE_BLOCK_SELECTOR = 'pre div.public-DraftStyleDefault-block';
const TABLE_SELECTOR = '.HotTable .HotTableLib .ht_master .wtHolder';
const SCROLLABLE_ELEMENT_SELECTORS = [CODE_BLOCK_SELECTOR, TABLE_SELECTOR];

const scrollableElementsSelector = SCROLLABLE_ELEMENT_SELECTORS.join(', ');

/**
 * For scrollable elements, set scroll to 0
 * This is because the scroll position is not set on the drag preview, so instead of switching back and forth we just reset
 */
const resetScrollPositions = (element: MNElement, domElement: HTMLElement | null) => {
    // Check whether current element is a type that may have scrollable elements
    // - Cards and documents may have a code block
    // - Columns may contain a table (tables on their own can't scroll) or any other scrollable element
    if (!isColumn(element) && !isCard(element) && !isDocument(element)) return;

    // A timeout is used here so that the drag can begin first, and the scroll position can be reset in the background,
    // ready for when the actual element is shown again after the drag is finished
    setTimeout(() => {
        domElement?.querySelectorAll(scrollableElementsSelector)?.forEach((element) => {
            element.scrollLeft = 0;
        });
    });
};

/**
 * Check whether the drag class should be applied with vanilla JS to improve performance, or if it should be rendered
 * @param element
 */
export const canApplyDragClassManually = (element: MNElement) => {
    // - Comments can't be copied with alt+drag, and we can't check for that here, so render them instead.
    //   They also use useKeepScrolledToBottom which runs when isDragging is true
    return !isCommentThread(element);
};

const getDomElement = (elementId: string): HTMLElement | null =>
    document.querySelector(`.Element[data-element-id="${elementId}"]`);

/**
 * Hide the dragged elements by adding the 'dragging' class
 * This is done with vanilla JS to avoid re-rendering the elements
 * @param item
 * @return a callback to clear the prepared drag
 */
export const prepareElementsForDrag = (item: DraggedItem): (() => void) => {
    const { draggedElements, connectingElementIds } = item;

    let animationRequest: number | null = requestAnimationFrame(() => {
        animationRequest = null;

        draggedElements?.forEach((element: MNElement) => {
            if (!canApplyDragClassManually(element)) return;

            const elementId = getElementId(element);
            const domElement = getDomElement(elementId);

            domElement?.classList.add(DRAGGING_CLASS);
            resetScrollPositions(element, domElement);
        });

        // Apply class to connected lines
        connectingElementIds?.forEach((elementId: string) => {
            getDomElement(elementId)?.classList.add(DRAGGING_CLASS);
        });
    });

    /**
     * Remove any remaining 'dragging' classes from elements that were dragged
     * This is done with vanilla JS to avoid re-rendering the elements
     * Most elements will render on their own because of prop changes like location,
     * this just makes sure that any others are tidied up
     */
    return () => {
        // if the drag ends before the raf has had a chance to fire, cancel it
        if (animationRequest) cancelAnimationFrame(animationRequest);

        // clear all dragged elements' .dragging class
        // (this is harmless if there aren't any, so we may as well always do it
        // just in case something got left around from a prior drag)
        document.querySelectorAll(`.Element.${DRAGGING_CLASS}`).forEach((element) => {
            element.classList.remove(DRAGGING_CLASS);
        });
    };
};
