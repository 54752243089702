import { TiptapContentNode } from '../../../tiptapTypes';

type RewriteTreeCallback = (node: TiptapContentNode, ancestors: TiptapContentNode[]) => TiptapContentNode;

/**
 * Traverses a tree of Tiptap nodes, replacing each node with the result of a callback.
 * Replaces leaf nodes first, then their parents.
 * The callback is provided a list of ancestors as a second parameter (root first) to
 * allow for context-aware transformations.
 */
export const rewriteTree = (
    node: TiptapContentNode,
    callback: RewriteTreeCallback,
    ancestors: TiptapContentNode[] = [],
): TiptapContentNode => {
    if (!node.content) return callback(node, ancestors);

    const newContent = node.content.map((n) => rewriteTree(n, callback, [...ancestors, node]));
    return callback({ ...node, content: newContent }, ancestors);
};
