// Lib
import logger from '../../../logger/logger';
import { omit } from 'lodash/fp';

// Constants
import { INITIAL_STATE_HYDRATION_OMIT } from '../../../store/initialStateConstants';

// Actions exposed to Swift apps
import { currentBoardIdSet } from '../../../reducers/currentBoardId/currentBoardIdActions';
import { navigateToBoard } from '../../../reducers/navigationActions';
import {
    associatePermissionIdToBoard,
    getPermissionsForCurrentBoardIfRequired,
} from '../../../utils/permissions/permissionsActions';
import {
    disablePublicEditing,
    enablePublicEditing,
    publishBoard,
    unpublishBoard,
} from '../../../workspace/header/currentBoardHeader/sharing/popup/publish/publishBoardActions';
import { fetchCurrentBoardPublishedBoardViewers } from '../../../workspace/header/currentBoardHeader/sharing/popup/publish/viewers/publishedBoardViewersActions';
import { moveElementsToTrash } from '../../../element/actions/elementShortcutActions';
import { moveTaskToTrash } from '../../../element/taskList/taskListActions';
import { setCanvasZoomTransformation } from '../../../canvas/store/canvasActions';

/**
 * Actions exposed to Swift apps which are NOT `await`ed in the Swift app.
 * i.e. They do NOT support `await`ing (asynchronously) in Swift Concurrency.
 */
const SWIFT_EXPOSED_ACTIONS = {
    'CurrentBoardIdActions.currentBoardIdSet': currentBoardIdSet,
    'NavigationActions.navigateToBoard': navigateToBoard,
    'PermissionsActions.getPermissionsForCurrentBoardIfRequired': getPermissionsForCurrentBoardIfRequired,
    'ElementShortcutActions.moveElementsToTrash': moveElementsToTrash,
    'TaskListActions.moveTaskToTrash': moveTaskToTrash,
    'CanvasActions.setCanvasZoomTransformation': setCanvasZoomTransformation,
};

/**
 * Actions exposed to Swift apps which ARE `await`ed in the Swift app.
 * i.e. They support `await`ing (asynchronously) in Swift Concurrency.
 */
const SWIFT_EXPOSED_ASYNC_ACTIONS = {
    'PermissionsActions.associatePermissionIdToBoard': associatePermissionIdToBoard,
    'PublishBoardActions.publishBoard': publishBoard,
    'PublishBoardActions.unpublishBoard': unpublishBoard,
    'PublishBoardActions.disablePublicEditing': disablePublicEditing,
    'PublishBoardActions.enablePublicEditing': enablePublicEditing,
    'PublishedBoardViewersActions.fetchCurrentBoardPublishedBoardViewers': fetchCurrentBoardPublishedBoardViewers,
};

const omitHydrationState = omit(INITIAL_STATE_HYDRATION_OMIT);

/**
 * Supports communication from the Swift app to the JS code through using actions.
 * Actions which are supported will need to be manually defined above.
 */
const swiftSwiftToJsMiddleware = (store) => {
    window.getState = () => {
        const state = store.getState();
        return JSON.stringify(omitHydrationState(state.toJS()));
    };

    window.swiftDispatch = ({ action }) => {
        store.dispatch(action);
    };

    window.swiftDispatchExposedAction = ({ actionName, args }) => {
        const actionFunc = SWIFT_EXPOSED_ACTIONS[actionName];
        if (!actionFunc) {
            logger.error('Unable to find exposed action with key: ' + actionName);
            return;
        }
        store.dispatch(actionFunc(args));
    };

    window.swiftDispatchExposedAsyncAction = ({ actionName, args }) => {
        const actionFunc = SWIFT_EXPOSED_ASYNC_ACTIONS[actionName];
        if (!actionFunc) {
            logger.error('Unable to find exposed async action with key: ' + actionName);
            return;
        }
        return store.dispatch(actionFunc(args));
    };

    return (next) => (action) => next(action);
};

export default swiftSwiftToJsMiddleware;
