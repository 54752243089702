// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Prop comparisons
import { noLonger, now } from '../../utils/react/propsComparisons';

const isNowHovered = now('isHovered');
const isNoLongerHovered = noLonger('isHovered');

const HOVER_DELAY_DEFAULT = 700;
const HOVER_FEEDBACK_DURATION = 300;

export default (DecoratedComponent) => {
    class InteractOnHover extends React.Component {
        constructor(props) {
            super(props);
            this.hoveredTimerId = null;

            this.state = { showNavigationFeedback: false };
        }

        showNavigationFeedback = (show) => {
            const { setShowNavigationFeedback } = this.props;
            if (setShowNavigationFeedback) return setShowNavigationFeedback(show);

            this.setState({ showNavigationFeedback: show });
        };

        componentWillReceiveProps(nextProps) {
            if (isNowHovered(this.props, nextProps)) {
                // Delay for longer on the first hover
                const timeout = nextProps.hoverDelay || HOVER_DELAY_DEFAULT;
                this.hoveredTimerId = setTimeout(this.prepareToNavigate, timeout);
            }

            if (this.hoveredTimerId && isNoLongerHovered(this.props, nextProps)) {
                clearTimeout(this.hoveredTimerId);
                this.showNavigationFeedback(false);
            }
        }

        prepareToNavigate = () => {
            this.showNavigationFeedback(true);
            this.hoveredTimerId = setTimeout(this.hoveredInteraction, HOVER_FEEDBACK_DURATION);
        };

        hoveredInteraction = () => {
            const { handleHoverInteraction } = this.props;
            this.showNavigationFeedback(false);
            handleHoverInteraction();
        };

        render() {
            const { showNavigationFeedback } = this.state;

            return <DecoratedComponent {...this.props} showNavigationFeedback={showNavigationFeedback} />;
        }
    }

    InteractOnHover.propTypes = {
        handleHoverInteraction: PropTypes.func.isRequired,
        hoverDelay: PropTypes.number,
        setShowNavigationFeedback: PropTypes.func,
    };

    return InteractOnHover;
};
