export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
/**
 * Regular expression for matching links (used while pasting text from the clipboard to figure out what kind of
 * element to create).
 * From: http://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
 */
export const URL_REGEX =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g; // eslint-disable-line
export const HOST_REGEX =
    /(http(s)?:\/\/.)?^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d{2,4})?\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/; // eslint-disable-line
export const LOCALHOST_REGEX = /(localhost|dev):3000/g;

// Matches anything that has a colon ":" and is not preceded by http or https
export const ILLEGAL_URL_SCHEME_REGEX = /^(?!http).+?:(\/\/)?/;

export const FIELD_TYPES = {
    text: 'text',
    userText: 'userText', // text that is from a user-input source — more strict validation
    email: 'email',
    multiEmail: 'multiEmail',
    password: 'password',
    date: 'date',
    // FIXME Call this an enum instead
    select: 'select',
    html: 'html',
    url: 'url',
    elementId: 'elementId',
    object: 'object',
    objectId: 'objectId',
    number: 'number',
    checkbox: 'checkbox',
};

export const FAILURE_TYPES = {
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
};

export const FAILURE_NAMES = {
    required: 'required',
    email: 'email',
    date: 'date',
    select: 'select',
    password: 'password',
    maxlength: 'maxlength',
    url: 'url',
    elementId: 'elementId',
    object: 'object',
    objectId: 'objectId',
    number: 'number',
};

export const FIELDS = {
    givenName: {
        name: 'givenName',
        type: FIELD_TYPES.userText,
        required: true,
        maxLength: 50,
    },
    familyName: {
        name: 'familyName',
        type: FIELD_TYPES.userText,
        required: true,
        maxLength: 50,
    },
    email: {
        name: 'email',
        type: FIELD_TYPES.email,
        required: true,
        maxLength: 255,
    },
    multiEmail: {
        name: 'multiEmail',
        type: FIELD_TYPES.multiEmail,
        required: true,
    },
    password: {
        name: 'password',
        type: FIELD_TYPES.password,
        required: true,
        maxLength: 255,
    },
    code: {
        name: 'code',
        type: FIELD_TYPES.text,
        required: true,
        maxLength: 40,
        minLength: 40,
    },
};

FIELDS.loginPassword = {
    ...FIELDS.password,
    disableTypeValidation: true,
};

// Login page & route
export const LOGIN_FIELDS = {
    email: FIELDS.email,
    password: FIELDS.loginPassword,
    deviceId: {
        name: 'deviceId',
        type: FIELD_TYPES.text,
    },
};

export const REGISTER_META_FIELDS = {
    inviteCode: {
        name: 'inviteCode',
        type: FIELD_TYPES.text,
    },
    onboarding: {
        name: 'onboarding',
        type: FIELD_TYPES.text,
    },
    initialEducation: {
        name: 'initialEducation',
        type: FIELD_TYPES.number,
    },
    featureFlags: {
        name: 'featureFlags',
        type: FIELD_TYPES.text,
    },
    referrerCode: {
        name: 'referrerCode',
        type: FIELD_TYPES.text,
    },
    marketingReferralCode: {
        name: 'marketingReferralCode',
        type: FIELD_TYPES.text,
    },
    registrationAvenue: {
        name: 'registrationAvenue',
        type: FIELD_TYPES.text,
    },
    registrationEntryPoint: {
        name: 'registrationEntryPoint',
        type: FIELD_TYPES.text,
    },
    publicBoardId: {
        name: 'publicBoardId',
        type: FIELD_TYPES.text,
    },
    permissionId: {
        name: 'permissionId',
        type: FIELD_TYPES.text,
    },
    deviceId: {
        name: 'deviceId',
        type: FIELD_TYPES.text,
    },
    guestAbGroup: {
        name: 'guestAbGroup',
        type: FIELD_TYPES.text,
    },
    agreeToTerms: {
        name: 'agreeToTerms',
        type: FIELD_TYPES.checkbox,
        required: true,
    },
    subscribeToProductUpdates: {
        name: 'subscribeToProductUpdates',
        type: FIELD_TYPES.checkbox,
    },
    utm: {
        name: 'utm',
        type: FIELD_TYPES.object,
    },
};

// Registration page & route
export const REGISTER_FIELDS = {
    ...REGISTER_META_FIELDS,
    givenName: FIELDS.givenName,
    familyName: FIELDS.familyName,
    email: FIELDS.email,
    password: FIELDS.password,
};

export const REGISTER_CONFIRM_FIELDS = {
    ...REGISTER_FIELDS,
    password: {
        name: 'password',
        type: FIELD_TYPES.password,
        maxLength: 255,
    },
    avatar: {
        name: 'avatar',
        type: FIELD_TYPES.text,
    },
    googleSub: {
        name: 'googleSub',
        type: FIELD_TYPES.text,
    },
    appleSub: {
        name: 'appleSub',
        type: FIELD_TYPES.text,
    },
};

// Forgotten password page & reset route
export const REQUEST_PASSWORD_RESET_FIELDS = {
    email: FIELDS.email,
};

// Change password page
export const CHANGE_PASSWORD_FIELDS = {
    password: FIELDS.password,
};

// Invitaion page & route
export const INVITE_FIELDS = {
    email: FIELDS.email,
};

// Sharing Popup
export const ADD_SHARED_USER_FIELDS = {
    userEmail: {
        ...FIELDS.email,
        name: 'userEmail',
        hideBeforeSubmit: true,
    },
};

export const ELEMENT_FIELDS = {
    id: {
        name: 'id',
        type: FIELD_TYPES.elementId,
        required: true,
    },
    elementType: {
        name: 'elementType',
        type: FIELD_TYPES.select,
        validValues: ['TASK', 'CARD', 'LINK', 'IMAGE', 'TASK_LIST'],
        required: true,
    },
    location: {
        name: 'location',
        type: FIELD_TYPES.object,
        keys: [
            {
                name: 'parentId',
                type: FIELD_TYPES.elementId,
            },
            {
                name: 'section',
                type: FIELD_TYPES.select,
                validValues: ['CANVAS', 'INBOX', 'TRASH', 'ATTACHED'],
            },
            {
                name: 'position',
                type: FIELD_TYPES.object,
            },
        ],
    },
    content: {
        name: 'content',
        type: FIELD_TYPES.object,
        required: true,
    },
};

// Creating element with HTTP route
export const ELEMENT_CREATE_FIELDS = {
    elementType: ELEMENT_FIELDS.elementType,
    content: ELEMENT_FIELDS.content,
};

// TODO allow parentId type to have id-trash
const MOVE_LOCATION_FIELDS = {
    name: 'location',
    type: FIELD_TYPES.object,
    keys: [
        {
            name: 'parentId',
            required: true,
            // type: FIELD_TYPES.elementId,
        },
        {
            name: 'section',
            required: false,
            type: FIELD_TYPES.select,
            validValues: ['CANVAS', 'INBOX', 'TRASH'],
        },
        {
            name: 'position',
            required: false,
            type: FIELD_TYPES.object,
        },
    ],
};

export const ELEMENT_MOVE_FIELDS = {
    location: {
        ...MOVE_LOCATION_FIELDS,
        required: true,
    },
    from: {
        ...MOVE_LOCATION_FIELDS,
        name: 'from',
    },
};

export const ELEMENT_DUPLICATE_FIELDS = {
    id: {
        ...ELEMENT_FIELDS.id,
        required: false,
    },
    location: {
        ...ELEMENT_FIELDS.location,
        required: true,
    },
};

export const ELEMENT_UPDATE_FIELDS = {
    content: {
        ...ELEMENT_FIELDS.content,
        required: false,
    },
    location: {
        ...MOVE_LOCATION_FIELDS,
        required: false,
    },
};

export const COMMENT_ADD_FIELDS = {
    threadId: {
        name: 'threadId',
        required: true,
    },
    text: {
        name: 'text',
        required: true,
        type: FIELD_TYPES.object,
    },
};

export const COMMENT_EDIT_FIELDS = {
    _id: {
        name: '_id',
        required: true,
    },
    threadId: {
        name: 'threadId',
        required: true,
    },
    text: {
        name: 'text',
        required: true,
        type: FIELD_TYPES.object,
    },
};
