// Lib
import * as Immutable from 'immutable';

// Constants
import { MEASUREMENT_REMOVE, MEASUREMENT_SET } from './elementMeasurementsConstants';

const initialState = Immutable.Map();

/**
 * Stores a map of measurements of target IDs.
 */
export default function elementMeasurementsMapReducer(state = initialState, action) {
    switch (action.type) {
        case MEASUREMENT_SET:
            return state.set(action.id, Immutable.fromJS(action.measurements));
        case MEASUREMENT_REMOVE:
            return state.delete(action.id);
        default:
            return state;
    }
}
