// Lib
import { isEmpty, get } from 'lodash/fp';

// Utils
import { asObject, prop } from '../../immutableHelper';

export default (rawState) => {
    const links = [];
    const entityMap = asObject(prop('entityMap', rawState));

    if (!entityMap) return links;

    const keys = Object.keys(entityMap);

    if (isEmpty(keys)) return links;

    for (const key in keys) {
        // TODO Move this constant into common
        if (get([key, 'type'], entityMap) === 'LINK') {
            links.push(get([key, 'data', 'url'], entityMap));
        }
    }

    return links;
};
