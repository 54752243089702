// Lib
import * as Immutable from 'immutable';
import { get } from 'lodash/fp';

// Utils
import {
    getGuestNavigationDeviceId,
    getUserNavigationElementId,
    getUserNavigationViewCount,
} from '../../../../../../../../common/navigation/userNavigationPropertyUtils';
import { getViewerId } from './publishBoardViewersUtils';
import { canWrite } from '../../../../../../../../common/permissions/permissionUtil';

// Constants
import { PUBLISHED_BOARD_VIEWERS_LOAD, RESET_PUBLISHED_BOARD_VIEWERS } from './publishedBoardViewersConstants';
import { GUEST_NAVIGATE, USER_NAVIGATE } from '../../../../../../../../common/users/userConstants';

const initialState = Immutable.fromJS({
    boardViews: {},
    boardGuestViews: {},
});

const handlePublishedBoardViewersLoad = (state, action) =>
    state.withMutations((mutableState) => {
        if (!action.views && !action.guestViews) return;

        action.views &&
            action.views.forEach((view) => {
                const boardId = getUserNavigationElementId(view);
                const userId = getViewerId(view);
                return mutableState.setIn(['boardViews', boardId, userId], Immutable.fromJS(view));
            });

        action.guestViews &&
            action.guestViews.forEach((view) => {
                const boardId = getUserNavigationElementId(view);
                const deviceId = getGuestNavigationDeviceId(view);
                return mutableState.setIn(['boardGuestViews', boardId, deviceId], Immutable.fromJS(view));
            });
    });

const resetPublishedBoardViewers = (state, action) =>
    state.withMutations((mutableState) => {
        mutableState.set('boardViews', Immutable.fromJS({}));
        mutableState.set('boardGuestViews', Immutable.fromJS({}));
    });

const handleUserNavigate = (state, action) => {
    if (!action.remote) return state;
    if (!get(['activity', 'isNewBoardShared'], action)) return state;

    const { newBoardId, permission, userId, timestamp } = action;

    if (canWrite(permission)) return state;

    return state.updateIn(['boardViews', newBoardId, userId], (entry) => {
        if (entry) {
            return entry.set('viewCount', (getUserNavigationViewCount(entry) || 0) + 1).set('lastView', timestamp);
        }
    });
};

const handleGuestNavigate = (state, action) => {
    if (!action.remote) return state;

    const { newBoardId, deviceId, timestamp } = action;

    return state.updateIn(['boardGuestViews', newBoardId, deviceId], (entry) => {
        if (entry) {
            return entry.set('viewCount', (getUserNavigationViewCount(entry) || 0) + 1).set('lastView', timestamp);
        }

        return Immutable.fromJS({
            elementId: newBoardId,
            deviceId,
            lastView: timestamp,
            viewCount: 1,
        });
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PUBLISHED_BOARD_VIEWERS_LOAD:
            return handlePublishedBoardViewersLoad(state, action);
        case RESET_PUBLISHED_BOARD_VIEWERS:
            return resetPublishedBoardViewers(state, action);
        case USER_NAVIGATE:
            return handleUserNavigate(state, action);
        case GUEST_NAVIGATE:
            return handleGuestNavigate(state, action);
        default:
            return state;
    }
};
