import { getLocalNumberFormattingSymbols } from '../../utils/localLanguageUtils';
import { stripCurrencySymbols } from './tableInputCurrencyUtils';
import { isNil } from 'lodash';

const validNumberRegex = /^[0-9]{0,3}(,[0-9]{3})*(\.[0-9]*)?$/;
export const validPercentageRegex = /%$/;

export const convertToEnglishNumberFormatting = (str: string, locale: string): string => {
    const { thousandsSeparator, decimalSeparator } = getLocalNumberFormattingSymbols(locale);
    // These placeholders are important because if the thousands separator is a dot
    // and we replace that first, all the symbols would be commas and therefore
    // the thousands separator would be replaced with a dot instead of a comma
    // e.g. without placeholders: 1.000,00 -> 1,000,00 -> 1.000.00
    // e.g. with placeholders: 1.000,00 -> 1thousands000decimals00 -> 1,000.00
    const placeholders = str.replaceAll(thousandsSeparator, 'thousands').replaceAll(decimalSeparator, 'decimals');
    return placeholders.replaceAll('thousands', ',').replaceAll('decimals', '.');
};

/**
 * Removes any numeric symbols from a string such as the currency symbol, thousands separators, etc.
 * this way it can be converted to a number
 * @param str
 * @param isNewInput - if the input is new we SHOULD convert to english number formatting, otherwise DO NOT
 * @param locale
 */
export const removeNumberSymbols = (str: string, isNewInput: boolean, locale: string): string => {
    let parsedString = str;

    // If the input is new, convert to english number formatting otherwise if
    // the value is coming from our data then it is already in english number formatting
    // This is important because we can't tell just from the value whether it has english
    // or local formatting (e.g. 1.000 could be 1 in english formatting or 1000 in spanish formatting)
    parsedString = isNewInput ? convertToEnglishNumberFormatting(parsedString, locale) : parsedString;

    parsedString = parsedString.replace(validPercentageRegex, '');
    parsedString = stripCurrencySymbols(parsedString);
    parsedString = parsedString.replaceAll(/\s/g, '');

    const isValidNumber = parsedString.match(validNumberRegex);
    if (isValidNumber) parsedString = parsedString.replaceAll(',', '');

    return parsedString;
};

export const getIsInputNumber = (str: string, isNewInput: boolean, locale: string): number | null => {
    const strippedString = removeNumberSymbols(str, isNewInput, locale);
    const numberValue = strippedString.length > 0 ? Number(strippedString) : null;
    const valueIsNumber = !isNil(numberValue) && !isNaN(numberValue);
    return valueIsNumber ? numberValue : null;
};
