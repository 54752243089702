// Lib
import { merge } from 'lodash/fp';

// Utils
import { getTimestamp } from '../utils/timeUtil';
import { createElementObject } from './elementRegistry';
import { asObject } from '../utils/immutableHelper';
import { getContentDiff } from '../utils/editor/contentDiff/jsonDiffUtil';
import { getElement } from './utils/elementTraversalUtils';

// Selectors
import { getElements } from '../../client/element/selectors/elementsSelector';

// Constants
import * as TYPES from './elementConstants';
import * as ELEMENTS_ACTION_TYPE from './elementsConstants';

export const createElementWithId = (args) => {
    const {
        id,
        elementType,
        location,
        content = {},
        acl,
        sync = true,
        timestamp,
        meta = {},
        transactionId,
        duplicate,
        duplicatedId,
        creationSource,
        markAsFetched = true,
    } = args;

    const defaultObjectDefinition = createElementObject(args);
    const specifiedObjectDefinition = {
        id,
        elementType,
        location,
        content,
        meta,
        acl,
    };

    const resultingObjectDefinition = merge(defaultObjectDefinition, specifiedObjectDefinition);

    return {
        ...resultingObjectDefinition,
        type: TYPES.ELEMENT_CREATE,
        timestamp: timestamp || getTimestamp(),
        sync,
        transactionId,
        duplicate,
        duplicatedId,
        creationSource,
        markAsFetched,
    };
};

export const updateMultipleElements = ({
    updates,
    undoUpdates,
    sync = true,
    transactionId,
    silent,
    updateType,

    // Used to specify other actions that will be dispatched ON TOP OF the existing undo/redo action
    batchUndoActions,
    batchRedoActions,
}) => ({
    type: TYPES.ELEMENT_UPDATE,
    // An optional property that can give more fine grained details about what kind of element update this is
    updateType,
    updates,
    batchUndoActions,
    batchRedoActions,
    undoUpdates,
    silent,
    sync,
    timestamp: getTimestamp(),
    transactionId,
});

export const updateElementContentDiff =
    ({ id, changes, sync = true, transactionId, silent }) =>
    (dispatch, getState) => {
        const state = getState();

        const elements = getElements(state);
        const element = getElement(elements, id);

        const { textContent } = changes;

        const previousTextContent = asObject(element.getIn(['content', 'textContent']));
        const contentDiff = getContentDiff(previousTextContent, textContent);

        // only send the content diff to the server
        const updatedChanges = {
            textContent: contentDiff,
        };

        dispatch({
            type: TYPES.ELEMENT_DIFF_UPDATE,
            id,
            changes: updatedChanges,
            silent,
            sync,
            timestamp: getTimestamp(),
            transactionId,
        });
    };

export const updateElement = ({
    id,
    changes,
    undoChanges,
    sync = true,
    transactionId,
    silent,
    updateType,
    meta,

    // Used to specify other actions that will be dispatched ON TOP OF the existing undo/redo action
    batchUndoActions,
    batchRedoActions,
}) => {
    const updateData = {
        // An optional property that can give more fine grained details about what kind of element update this is
        updateType,
        updates: [{ id, changes, meta }],
        silent,
        sync,
        transactionId,

        batchUndoActions,
        batchRedoActions,
    };

    if (undoChanges) {
        updateData.undoUpdates = [{ id, changes: undoChanges }];
    }

    return updateMultipleElements(updateData);
};

export const moveMultipleElements = ({
    moves,
    sync = true,
    transactionId,
    silent,
    moveOperation,
    // Optional - allows the initial measurements of the moved elements to be provided so they can
    //  be used to calculate the new canvas size when moving between boards
    // NOTE: This property is not being sent to the server, it's removed by createPrepareActionForServerFn.js
    initialMeasurements,
}) => ({
    type: TYPES.ELEMENT_MOVE_MULTI,
    moves,
    initialMeasurements,
    sync,
    timestamp: getTimestamp(),
    transactionId,
    moveOperation,
    monitoring: {
        operation: moveOperation,
    },
});

export const moveElement = ({ move, sync = true, transactionId, silent }) =>
    moveMultipleElements({
        moves: [move],
        sync,
        transactionId,
        silent,
    });

export const atomicMoveAndUpdateElement = ({ id, location, from, changes, sync = true, transactionId, silent }) => ({
    type: TYPES.ELEMENT_MOVE_AND_UPDATE,
    id,
    location,
    from,
    changes,
    sync,
    timestamp: getTimestamp(),
    transactionId,
    silent,
});

export const updateElementACL = ({ id, acl, sync = true, transactionId, ...rest }) => ({
    ...rest,
    type: TYPES.ELEMENT_UPDATE_ACL,
    id,
    acl,
    sync,
    transactionId,
});

export const convertElementToClone = ({ id, originalElementId, clonedElementType, sync = true, transactionId }) => ({
    type: TYPES.ELEMENT_CONVERT_TO_CLONE,
    id,
    originalElementId,
    clonedElementType,
    sync,
    timestamp: getTimestamp(),
    transactionId,
});

export const setElementTypeAndUpdateElement = ({
    id,
    elementType,
    changes,
    undoChanges,
    sync = true,
    transactionId,
}) => ({
    type: TYPES.ELEMENT_SET_TYPE,
    id,
    elementType,
    changes,
    undoChanges,
    sync,
    timestamp: getTimestamp(),
    transactionId,
});

export const deleteElement = ({ id, location, elementType, sync = true, transactionId }) => ({
    type: TYPES.ELEMENT_DELETE,
    id,
    location,
    elementType,
    sync,
    transactionId,
});

export const loadElements = (elements = {}, deletedElementIds = []) => ({
    type: ELEMENTS_ACTION_TYPE.ELEMENTS_LOAD,
    elements,
    deletedElementIds,
});

export const loadElementsIntoWorkerCache = (elements = {}, deletedElementIds = []) => ({
    type: ELEMENTS_ACTION_TYPE.ELEMENTS_LOAD_INTO_WORKER_CACHE,
    elements,
    deletedElementIds,
});

/**
 * Remove the specified elements from the store.
 */
export const purgeElements = (elementIds, boardIds) => ({
    type: ELEMENTS_ACTION_TYPE.ELEMENTS_PURGE,
    elementIds,
    boardIds,
});
