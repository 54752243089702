// Lib
import * as Immutable from 'immutable';
import { pick } from 'lodash/fp';

// Constants
import { CURSOR_UPDATE, CURSOR_REMOVE } from '../../../../common/users/liveCursor/liveCursorConstants';
import {
    LIVE_COLLABORATION_SOCKET_DISCONNECT,
    LIVE_COLLABORATION_SOCKET_UPDATE_CHANNEL,
} from '../liveCollaborationConstants';

const initialState = Immutable.Map();

const cursorUpdateReducer = (state, action) => {
    if (!action.remote || !action.userId) return state;

    return state.update(action.userId, {}, (currentVal) => ({
        ...currentVal,
        ...pick(['x', 'y', 'status', 'cursorType'], action),
    }));
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CURSOR_UPDATE:
            return cursorUpdateReducer(state, action);
        case CURSOR_REMOVE:
            return !action.remote || !action.userId ? state : state.delete(action.userId);
        case LIVE_COLLABORATION_SOCKET_DISCONNECT:
        case LIVE_COLLABORATION_SOCKET_UPDATE_CHANNEL:
            return initialState;
        default:
            return state;
    }
};
