// Lib
import { toUpper, get, set, unset } from 'lodash';

// Utils
import { nanoid } from 'nanoid';
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

// Interceptors
import requestAuthorisationInterceptor from './requestAuthorisationInterceptor';
import requestNoCacheInterceptor from './requestNoCacheInterceptor';

// HTTP request config is converted to a string & back to JSON to ensure it can be safely
// sent to the swift layer via the webkit messageHandlers bridge
const safelyEncode = (params) => JSON.parse(JSON.stringify(params));
const getRequestPromise = (uuid) => get(window, `milanoteHttpRequest.promises.${uuid}`);
const setRequestPromise = (uuid, { resolve, reject }) =>
    set(window, `milanoteHttpRequest.promises.${uuid}`, { resolve, reject });
const removeRequestPromise = (uuid) => unset(window, `milanoteHttpRequest.promises.${uuid}`);

export const hasMobileHybridBridge =
    isPlatformLegacyMobileApp(platformSingleton) &&
    ((typeof window !== 'undefined' && window && window.flutter_inappwebview) ||
        (typeof window !== 'undefined' &&
            window &&
            window.webkit &&
            window.webkit.messageHandlers.milanoteHttpRequest));

export const configureMobileHybridHttp = () => {
    window.milanoteHttpRequest = window.milanoteHttpRequest || {};
    window.milanoteHttpRequest.promises = {};
    window.milanoteHttpRequest.callback = (uuid, response, rejection) => {
        const promise = getRequestPromise(uuid);
        if (!promise) {
            return;
        }

        const { resolve, reject } = { ...promise };

        removeRequestPromise(uuid);

        if (rejection) {
            reject(rejection);
            return;
        }

        resolve(response);
    };
};

export const handleMobileHybridHttpRequest = (requestConfig) =>
    new Promise((resolve, reject) => {
        const uuid = nanoid();
        setRequestPromise(uuid, { resolve, reject });

        // Apply these "interceptors" manually.
        const webKitConfig = requestAuthorisationInterceptor(requestNoCacheInterceptor(safelyEncode(requestConfig)));

        // ensure that http method is always uppercase
        webKitConfig.method = webKitConfig.method ? toUpper(webKitConfig.method) : 'GET';

        webKitConfig.uuid = uuid;

        if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            window.flutter_inappwebview.callHandler('flutterHttpRequest', webKitConfig);
        } else if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.milanoteHttpRequest
        ) {
            // `milanoteHttpRequest` needs to match the message handler name that in WKWebView's configuration
            window.webkit.messageHandlers.milanoteHttpRequest.postMessage(webKitConfig);
        }
    });
