// Lib
import { convertToRaw } from 'draft-js';

// Utils
import clearEditorState from '../../customRichUtils/clearEditorState';
import { hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';

export default (event, editorState, { getProps, setEditorState }) => {
    const { onSubmit } = getProps();

    if (!onSubmit) return HANDLER_RETURN_VALUES.notHandled;
    if (hasShiftKey(event)) return HANDLER_RETURN_VALUES.notHandled;

    const contentState = editorState.getCurrentContent();
    if (contentState.hasText()) {
        const currentContent = editorState.getCurrentContent();
        const rawText = convertToRaw(currentContent);

        onSubmit(rawText);

        const clearedState = clearEditorState(editorState);
        setEditorState(clearedState);
    }

    return HANDLER_RETURN_VALUES.handled;
};
