// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ModalToolbar from '../modalToolbar/ModalToolbar';
import ImageModalViewToolbar from './ImageModalViewToolbar';
import ImageModalUploadToolbar from './ImageModalUploadToolbar';
import StickyPortalMount from '../../../../components/animations/StickyPortalMount';

// Constants
import { IMAGE_MODAL_MODES, IMAGE_MODAL_SECONDARY_TOOLBAR_ID } from '../imageModalConstants';

// Styles
import './ImageModalToolbar.scss';

const ImageModalToolbar = (props) => {
    const { mode } = props;

    const showPrimary = mode === IMAGE_MODAL_MODES.VIEW;

    return (
        <ModalToolbar className="ImageModalToolbar" {...props}>
            <div className={classNames('toolbar-animation-container', showPrimary ? 'primary' : 'secondary')}>
                <div className="image-primary-tools">
                    <ImageModalViewToolbar {...props} />
                </div>
                {/* This will render the upload toolbar which will put it inside the portal mount point */}
                {mode === IMAGE_MODAL_MODES.UPLOAD && <ImageModalUploadToolbar {...props} />}
                <StickyPortalMount id={IMAGE_MODAL_SECONDARY_TOOLBAR_ID} className="image-secondary-tools" />
            </div>
        </ModalToolbar>
    );
};

ImageModalToolbar.propTypes = {
    element: PropTypes.object,
    mode: PropTypes.string,
    setMode: PropTypes.func,
    isPresentationMode: PropTypes.bool,
};

export default ImageModalToolbar;
