import React from 'react';

import PropTypes from 'prop-types';

/**
 * Shows a validation message if one exists.
 */
class FieldValidationMessages extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.sanitisedError !== this.props.sanitisedError;
    }

    render() {
        const { sanitisedError } = this.props;
        return sanitisedError && sanitisedError.message ? (
            <div className="field-message error">{sanitisedError.message}</div>
        ) : null;
    }
}

FieldValidationMessages.propTypes = {
    sanitisedError: PropTypes.object,
};
export default FieldValidationMessages;
