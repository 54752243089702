// Constants
import { STROKE_SIZES } from '../drawingEditorConstants';

/**
 * When thinning isn't enabled we want the line to be thinner due to the way it's rendered as a stroke.
 */
export const getNewPathRenderedSize = ({ size, strokeSize, enableDrawingInkEffect }) => {
    // Don't thin large strokes because we usually just want to fill large areas in this case
    if (enableDrawingInkEffect || strokeSize === STROKE_SIZES.EXTRA_LARGE) return size;

    return size / 2;
};

/**
 * When beginning a new path, determine the thinning that the path should have.
 */
export const getNewPathThinning = ({ strokeSize, hasPressure, enableDrawingInkEffect }) => {
    // Don't thin large strokes because we usually just want to fill large areas in this case
    if (!enableDrawingInkEffect || strokeSize === STROKE_SIZES.EXTRA_LARGE) return 0;

    // Boost the thinning for Apple Pencil thick lines so that the pressure has more of an effect
    if (hasPressure && strokeSize === STROKE_SIZES.LARGE) return 0.7;

    // Reduce the thinning for Apple Pencil thin lines so that the pressure has less of an effect
    if (hasPressure && strokeSize === STROKE_SIZES.SMALL) return 0.5;

    return undefined;
};

/**
 * When beginning a new path, determine the smoothing that the path should have.
 */
export const getNewPathSmoothing = ({ strokeSize, hasPressure, enableDrawingInkEffect }) => {
    if (!enableDrawingInkEffect) return undefined;

    // If we're not using the apple pencil
    if (!hasPressure) {
        return strokeSize === STROKE_SIZES.LARGE
            ? // And the stroke size is large, decrease the smoothing to reduce the glitch effect
              0.2
            : // Otherwise just use the default path streamline (and don't save it on the path)
              undefined;
    }

    // For thin stroke sizes - use the default smoothing & streamline
    if (strokeSize <= STROKE_SIZES.MEDIUM) return undefined;

    // Otherwise, for large strokes reduce the smoothing & streamline to reduce its effects
    return 0.1;
};

/**
 * When beginning a new path, determine the smoothing that the path should have.
 */
export const getNewPathStreamline = ({ strokeSize, hasPressure, enableDrawingInkEffect }) => {
    if (!enableDrawingInkEffect) return undefined;

    // If we're not using the apple pencil
    if (!hasPressure) {
        return strokeSize === STROKE_SIZES.LARGE
            ? // And the stroke size is large, increase the streamline to reduce the glitch effect
              1
            : // Otherwise just use the default path streamline (and don't save it on the path)
              undefined;
    }

    // For thin stroke sizes - use the default smoothing & streamline
    if (strokeSize <= STROKE_SIZES.LARGE) return undefined;

    // Otherwise, for large strokes reduce the smoothing & streamline to reduce its effects
    return 0.1;
};
