// Utils
import { getBezierFromPoints, getBezierIntersection } from '../../../../common/maths/geometry/bezierUtil';
import { getHalfwayPoint } from './lineControlPointUtil';

/**
 * Finds the intersection of a bezier curve and a rectangle or multiple rectangles.
 * shouldFindMinT determines whether the intersection closest to the start of the line (true) or
 * end of the line (false) should be found, in case there's multiple intersections.
 *
 * Usually we choose the point that's closest to the target point, because it has more pleasing results,
 * however for boards (the only case that we use multiple rects, at the moment) we choose the farthest point
 * from the target to prevent the line from crossing through the title text.
 * So in this case we swap the shouldFindMinT predicate.
 */
const getRectsBezierIntersection = (targetPoint, rects, bezier, shouldFindMinT) => {
    if (!rects) return targetPoint;

    // If we have two rects we're finding an intersection for a board. Due to the possibility that the line
    // might enter the title rect, then exit, it would always choose the exit point, which is incorrect.
    // In this case using the farthest intersection works best, so we swap the "shouldFindMinT"
    const findMinT = rects.length > 1 ? !shouldFindMinT : shouldFindMinT;

    const intersection = rects.reduce((currentIntersection, rect) => {
        const newIntersection = getBezierIntersection(rect, bezier, findMinT);

        if (!currentIntersection) return newIntersection;
        if (!newIntersection) return currentIntersection;

        const useNewIntersection =
            (findMinT && newIntersection.t < currentIntersection.t) ||
            (!findMinT && newIntersection.t > currentIntersection.t);

        return useNewIntersection ? newIntersection : currentIntersection;
    }, null);

    return intersection || targetPoint;
};

/**
 * Finds the point at which the line visibly intersects the target.
 * This is either the target if there's no 'target rect' or a point along the perimeter that the line
 * intersects with.
 */
export const getTargetIntersectionPoint = (targetPoint, rects, bezier, shouldFindMinT) =>
    !rects || !bezier || targetPoint.fixed
        ? targetPoint
        : getRectsBezierIntersection(targetPoint, rects, bezier, shouldFindMinT) || targetPoint;

/**
 * Finds the halfway point between the intersections of the straight line that connects the
 * start origin with the end origin.
 * This is used to snap the control point when it's close to its straight line position.
 */
export const getStraightLineMidpoint = ({
    startEdgeOrigin,
    startConnectionRects,
    endEdgeOrigin,
    endConnectionRects,
}) => {
    if (!startEdgeOrigin || !endEdgeOrigin) return null;

    const straightLineControl = getHalfwayPoint(startEdgeOrigin, endEdgeOrigin);
    const straightBezier = getBezierFromPoints(startEdgeOrigin, straightLineControl, endEdgeOrigin);

    const startIntersection = getTargetIntersectionPoint(startEdgeOrigin, startConnectionRects, straightBezier, true);
    const endIntersection = getTargetIntersectionPoint(endEdgeOrigin, endConnectionRects, straightBezier, false);

    return getHalfwayPoint(startIntersection, endIntersection);
};
