import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarCaptionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-caption"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#1B2536"
                d="M4 0h24a4 4 0 0 1 4 4v14H0V4a4 4 0 0 1 4-4zM0 19h32v9a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-9z"
                className="background decoration"
            />
            <path
                fill="#48505E"
                d="M23.25 26a.75.75 0 1 1 0 1.5H6.75a.75.75 0 1 1 0-1.5h16.5zm2-3a.75.75 0 1 1 0 1.5H6.75a.75.75 0 1 1 0-1.5h18.5z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarCaptionIcon);
export default Memo;
