// Utils
import matchInlineStyleMarkdown from './patternMatching/matchInlineStyleMarkdown';
import changeCurrentInlineStyle from '../../customRichUtils/changeCurrentInlineStyle';
import editorGetTextPriorToSelection from '../../customRichUtils/editorState/editorGetTextPriorToSelection';
import removeCodeInlineStyle from './removeCodeInlineStyle';

// Constants
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

export default (editorState, character) => {
    // Get text before selection point
    const textBeforeCharacter = editorGetTextPriorToSelection(editorState);

    const shouldRemoveInlineCodeStyle =
        character === ' ' &&
        textBeforeCharacter[textBeforeCharacter.length - 1] === ' ' &&
        editorState.getSelection().isCollapsed() &&
        editorState.getCurrentInlineStyle().includes(STYLE_COMMANDS.CODE);

    if (shouldRemoveInlineCodeStyle) return removeCodeInlineStyle(editorState);

    const line = `${textBeforeCharacter}${character}`;

    const styleMatch = matchInlineStyleMarkdown(line);

    if (styleMatch) {
        const { match, inlineStyle } = styleMatch;

        // Don't add inline code while in a code block
        if (inlineStyle === STYLE_COMMANDS.CODE) {
            const currentSelection = editorState.getSelection();
            const key = currentSelection.getStartKey();
            const block = editorState.getCurrentContent().getBlockForKey(key);
            const blockType = block.getType();

            if (blockType === STYLE_COMMANDS.CODE_BLOCK) return editorState;
        }

        return changeCurrentInlineStyle(editorState, match, inlineStyle, character);
    }

    return editorState;
};
