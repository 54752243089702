import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import FloatingPanel from '../../../components/tooltips/FloatingPanel';
import PopupPanelMouseManager from '../../../components/popupPanel/PopupPanelMouseManager';
import SimpleDateTimePicker from '../../../components/datetime/SimpleDateTimePicker';

// Constants
import { PopupIds } from '../../../components/popupPanel/popupConstants';
import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';
import { CellTypeNames } from '../../../../common/table/CellTypeConstants';

// Utils
import {
    dateTimeStringHasSeconds,
    getDateFromString,
    toNumericDateTimeString,
} from '../../../../common/utils/timeUtil';
import { isFormula } from '../utils/tableFormulaUtils';
import { get, isNil } from 'lodash';
import { getAllCellsBetween } from '../utils/tableCellSelectionUtils';
import { getTableContentCellTextStyle } from '../../../../common/elements/utils/elementPropertyUtils';
import { asObject } from '../../../../common/utils/immutableHelper';
import getEditorJsonText from '../../../../common/tiptap/utils/jsonContentUtils/getEditorJsonText';
import { parseCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';

const DatePickerContainer = (props) => {
    const {
        tableContentData,
        isCurrentlyEditingTableCell,
        gridSize,
        element,
        currentCellSelections,
        cellEditorRef,
        showFormulaDropdown,
        locale,
    } = props;

    // Get details from the currently editing cell
    const cellsBetween = getAllCellsBetween(currentCellSelections);
    const { row, col } = cellsBetween[0] || {};
    const editingCellData = cellsBetween.length === 1 ? asObject(get(tableContentData, [row, col])) : undefined;
    const { type: { hasTime = false, name } = {}, value } = editingCellData || {};
    const [hasTimeValue, setHasTimeValue] = useState(hasTime);

    const rawText = getEditorJsonText(parseCellContentString(value));

    // Get the date from the cell contents, or default to the current date
    const [calendarInputDate, setCalendarInputDate] = useState(
        () => getDateFromString(rawText, locale) || new Date(new Date().setHours(0, 0, 0, 0)),
    );

    const showDatePicker =
        isCurrentlyEditingTableCell && name === CellTypeNames.DATE_TIME && !showFormulaDropdown && !isFormula(rawText);
    useEffect(() => {
        if (isNil(calendarInputDate) || !showDatePicker) return;

        const inlineStyles = getTableContentCellTextStyle(cellsBetween[0])(element);

        // when date or hasTime changes, update the cell editor contents
        const currentEditorState = cellEditorRef?.current.getEditorState();
        const content = currentEditorState.getCurrentContent().getPlainText();
        const hasSeconds = dateTimeStringHasSeconds(content);
        const numericDateTimeString = toNumericDateTimeString(
            calendarInputDate,
            {
                hasTime: hasTimeValue,
                hasSeconds,
                useTwelveHour: true,
            },
            locale,
        );

        cellEditorRef?.current.setPlainText(numericDateTimeString, inlineStyles);
    }, [calendarInputDate, hasTimeValue]);

    if (!showDatePicker) return null;

    return (
        <FloatingPanel
            selector=".Table.selected .handsontable-input-holder"
            className={classNames('TableDateTimePicker', 'className', 'light')}
            position={TooltipPositions.BOTTOM}
            gridSize={gridSize}
            pollPosition
        >
            <PopupPanelMouseManager
                {...props}
                visible={showDatePicker}
                popupId={PopupIds.TABLE_DATE_TIME_PICKER}
                ignoredClicksSelector=".Toolbar"
                closePopup={() => {}}
            >
                <div className="popup-content" data-table-util={true}>
                    <SimpleDateTimePicker
                        locale={locale}
                        date={calendarInputDate}
                        updateDate={(date) => setCalendarInputDate(date)}
                        hasTime={hasTimeValue || false}
                        updateDateHasTime={(hasTime) => setHasTimeValue(hasTime)}
                    />
                </div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

DatePickerContainer.propTypes = {
    isCurrentlyEditingTableCell: PropTypes.bool,
    gridSize: PropTypes.number,
    element: PropTypes.object,
    tableContentData: PropTypes.array,
    currentCellSelections: PropTypes.object,
    updateCellTypeFormat: PropTypes.func,
    cellEditorRef: PropTypes.object,
    showFormulaDropdown: PropTypes.bool,
    locale: PropTypes.string,
};

export default DatePickerContainer;
