// Lib
import { defer } from 'lodash/fp';

// Utils
import logger from '../../../../logger/logger';
import { getElement } from '../../../../../common/elements/utils/elementTraversalUtils';
import { length } from '../../../../../common/utils/immutableHelper';

// Selectors
import { getElements } from '../../../selectors/elementsSelector';
import { getSelectedElementIds } from '../../../selection/selectedElementsSelector';
import { getAllAncestorIds } from '../../../../../common/dataStructures/treeUtils';
import { parentIdMapSelector } from '../../../selectors/elementGraphSelector';

// Actions
import { finishEditingElement, setSelectedElements, startEditingMainEditor } from '../../../selection/selectionActions';
import { isTaskList } from '../../../../../common/elements/utils/elementTypeUtils';
import { getParentTaskListIdThunk } from '../../taskActions';

export const getIsOnlyParentTaskListSelectedThunk =
    ({ elementId }) =>
    (dispatch, getState) => {
        const state = getState();

        const selectedElementIds = getSelectedElementIds(state);

        // If more than one element is selected then the parent task list isn't the only thing selected
        if (length(selectedElementIds) !== 1) return false;

        const taskListId = dispatch(getParentTaskListIdThunk({ elementId }));

        if (!taskListId) {
            logger.warning("Unable to find Task's parent Task List", elementId);
            return false;
        }

        return selectedElementIds.first() === taskListId;
    };

export const selectParentTaskList =
    ({ elementId }) =>
    (dispatch) => {
        const taskListId = dispatch(getParentTaskListIdThunk({ elementId }));
        return dispatch(setSelectedElements({ ids: [taskListId] }));
    };

export const returnFocusFromPillPopup =
    ({ elementId, initiallyEditing }) =>
    (dispatch, getState) => {
        if (!initiallyEditing) return dispatch(finishEditingElement(elementId));

        defer(() => {
            const state = getState();
            const elements = getElements(state);

            const selectedElementIds = getSelectedElementIds(state);

            // If there's no selected elements or there's more than one, then don't return focus to the editor
            if (length(selectedElementIds) !== 1) return dispatch(finishEditingElement(elementId));

            const selectedElementId = selectedElementIds.first();
            const selectedElement = getElement(elements, selectedElementId);

            // If it's not a task list then don't return focus to the editor
            if (!isTaskList(selectedElement)) return dispatch(finishEditingElement(elementId));

            const parentIdMap = parentIdMapSelector(state);
            const ancestorIds = getAllAncestorIds(parentIdMap, elementId);

            // If the selected element is not the task list parent, then don't return focus to the editor
            if (!ancestorIds.includes(selectedElementId)) return dispatch(finishEditingElement(elementId));

            dispatch(startEditingMainEditor(elementId));
        });
    };
