// Utils
import { hasCommandModifier, hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';

// Constants
import { KEY_CODES } from '../../../../utils/keyboard/keyConstants';
import { APP_REDO_CUSTOM_DRAFT_COMMAND, APP_UNDO_CUSTOM_DRAFT_COMMAND } from './undoRedoPluginConstants';

export default (event, { getEditorState }) => {
    if (event.keyCode !== KEY_CODES.Z || !hasCommandModifier(event)) return undefined;

    const editorState = getEditorState();

    const isRedo = hasShiftKey(event);

    if (isRedo) {
        const hasEditorRedo = editorState.getRedoStack().size > 0;
        return !hasEditorRedo ? APP_REDO_CUSTOM_DRAFT_COMMAND : undefined;
    }

    const hasEditorUndo = editorState.getUndoStack().size > 0;
    return !hasEditorUndo ? APP_UNDO_CUSTOM_DRAFT_COMMAND : undefined;
};
