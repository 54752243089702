import { FILE_DISPLAY_NAME } from './fileConstants';
import { ELEMENT_DISPLAY_MODES } from '../elements/elementDisplayModeConstants';

import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content } = params;
    return {
        ...elementConstructor(params),
        elementType: ElementType.FILE_TYPE,
        content: {
            file: null,
            previewReady: false,
            displayMode: ELEMENT_DISPLAY_MODES.ICON_VIEW,
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.FILE_TYPE,
        objectConstructorFn,
        displayName: FILE_DISPLAY_NAME,
        ...params,
    });
