// Lib
import { isNil } from 'lodash/fp';

// Utils
import { prop } from '../../../../common/utils/immutableHelper';
import { pixelsToGridPoints } from '../../../utils/grid/gridUtils';

/**
 * Determines the aspect ratio for the given dimensions.
 *
 * @param dimensions {{ width, height }} A POJO or Immutable object with width and height.
 *
 * @returns {number|undefined} The ratio of width / height.
 */
export const getAspectRatio = (dimensions) => {
    if (!dimensions) return;

    // Careful that the intended height takes into account the media aspect ratio...
    const targetWidth = prop('width', dimensions);
    const targetHeight = prop('height', dimensions);

    if (!targetWidth) return;
    if (!targetHeight) return;

    return targetWidth / targetHeight;
};

/**
 * Crops a ratio to perfect grid units.
 *
 * @param aspectRatio {number} The aspect ratio to crop.
 * @param cropInset {number} The number of pixels to subtract from the height. This is useful
 *          when sitting exactly on grid points isn't desirable. E.g. Images on the canvas sit
 *          within grid points, not on grid points, so subtract 2 pixels from these.
 * @param widthPx {number} The width of the rendered component in pixels.
 * @param gridSize {number} The grid size in pixels.
 *
 * @returns {number|undefined} The aspect ratio that's been cropped to the grid.
 */
export const getCroppedToGridAspectRatio = ({ aspectRatio, cropInset = 0, widthPx, gridSize }) => {
    if (!aspectRatio || !widthPx || !gridSize) return;

    const fullHeightPx = Math.round(widthPx / aspectRatio);
    const renderedHeightPx = pixelsToGridPoints(fullHeightPx, gridSize) * gridSize - cropInset;

    return widthPx / renderedHeightPx;
};

export const convertToPercentageString = (number, round = false) => {
    if (isNil(number)) return undefined;
    return round ? `${Math.floor(number * 100)}%` : `${number * 100}%`;
};

/**
 * Determines the paddingBottom required to keep the aspect ratio for the image.
 * Due to the way we perform resizing and image layout we don't render an exact
 * height, but instead render a container with padding (to set an aspect ratio)
 * and then centre the image inside that area.
 *
 * TODO - savedSize and forcedSize could be combined into a single parameter.
 *      This API could probably be improved a fair bit
 * @param savedSize {{ width: number, height: number }} (Optional)
 *          This is the saved dimensions for the element.
 *          It will either be the 'media' dimensions on the image element, or
 *          it will be the 'image' dimensions itself if no media properties are set.
 *          NOTE: In this context the units are not important, just their ratio.
 * @param forcedSize  {{ width: number, height: number }} (Optional)
 *          This will be a forced size, if either the savedSize isn't ready yet
 *          (because the image is still uploading) or the element is being resized.
 *          Again - in this context the units are not important, just their ratio.
 * @param roundSaved {boolean} (Optional)
 *          Rounds the padding to an even percentage - this seems to be used by the
 *          rich media components, from memory to prevent black border issues at
 *          the bottom.  I think it was a browser bug.
 * @param cropToGrid {boolean} (Optional)
 *          Changes the padding to force the aspect ratio to sit on the grid.
 * @param widthPx {number} (Optional)
 *          The width in pixels that will be rendered. This is only required if
 *          cropping to the grid.
 * @param cropInset {number} (Optional)
 *          Subtracts a number of pixels from the desired height. This is useful
 *          for ensuring the element fits within grid points, e.g. an Image element
 *          needs to be 2px shorter than the grid size when it's on the canvas
 *          otherwise it will overflow the grid point rather than sitting between it.
 * @param gridSize {number} (Optional)
 *          The grid size in pixels
 *
 * @returns {{paddingBottom: string}|undefined}
 *          The padding bottom as a string % to be used as a style on elements.
 */
export const getPaddingForAspectRatio = ({
    savedSize,
    forcedSize,
    roundSaved = true,
    cropToGrid,
    cropInset = 0,
    widthPx,
    gridSize,
}) => {
    // Use the forcedSize if it's set, otherwise use the saved size
    let aspectRatio = getAspectRatio(forcedSize || savedSize);

    if (!aspectRatio) return;

    const shouldRoundUp = !forcedSize && roundSaved;

    // If we're cropping to the grid, then we need to determine what the rendered size
    // should be and use that ratio instead
    if (cropToGrid && gridSize && widthPx) {
        aspectRatio = getCroppedToGridAspectRatio({
            aspectRatio,
            cropInset,
            widthPx,
            gridSize,
        });
    }

    return convertToPercentageString(1 / aspectRatio, shouldRoundUp);
};

export const asPaddingBottomStyleObject = (paddingBottom) => {
    if (isNil(paddingBottom)) return undefined;
    return { paddingBottom };
};

// TODO Fix the invocations of this to either create the object themselves or change the name of this file
export default (args) => asPaddingBottomStyleObject(getPaddingForAspectRatio(args));
