import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementFileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-file"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path fill="#BBBEC3" d="M10 6h8l6 6v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2z" />
            <path fill="#FFF" d="m18 6 6 6h-6z" />
        </g>
    </svg>
);
const Memo = memo(ElementFileIcon);
export default Memo;
