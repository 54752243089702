// Utils
import { isLine } from '../../../common/elements/utils/elementTypeUtils';
import {
    getElementId,
    getIsLineEndSnapped,
    getIsLineStartSnapped,
    getLineEndConnectedElementId,
    getLineStartConnectedElementId,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getLineConnectedElementIds } from '../line/utils/lineUtil';
import { toArray } from '../../../common/utils/immutableHelper';
import { isLocationCanvas } from '../../../common/elements/utils/elementLocationUtils';

/**
 * Determines whether the element type can connect to other elements.
 */
export const elementCanConnect = (element) => isLine(element);

/**
 * Returns true if the element connects to any of the element IDs specified.
 */
const doesElementConnectTo = (elementIds) => (element) => {
    if (!elementCanConnect(element)) return false;

    // If the line is no longer on the canvas then it's not worth checking as it's no longer connected
    if (!isLocationCanvas(element)) return false;

    // Don't state that this is a connected element if it's already a part of the elementIds
    if (elementIds.includes(getElementId(element))) return false;

    const startConnectedId = getIsLineStartSnapped(element) && getLineStartConnectedElementId(element);
    const endConnectedId = getIsLineEndSnapped(element) && getLineEndConnectedElementId(element);

    if (startConnectedId || endConnectedId) {
        for (const elementId of elementIds) {
            const isConnected = startConnectedId === elementId || endConnectedId === elementId;

            if (isConnected) return true;
        }
    }

    return false;
};

/**
 * Returns true if the element connects any 2 elements in array of element IDs specified.
 */
export const doesElementConnectTwoElements = (elementIds) => (element) => {
    if (!elementCanConnect(element)) return false;

    // If the line is no longer on the canvas then it's not worth checking as it's no longer connected
    if (!isLocationCanvas(element)) return false;

    // Don't state that this is a connected element if it's already a part of the elementIds
    if (elementIds.includes(getElementId(element))) return false;

    const startConnectedId = getIsLineStartSnapped(element) && getLineStartConnectedElementId(element);
    const endConnectedId = getIsLineEndSnapped(element) && getLineEndConnectedElementId(element);

    if (startConnectedId && endConnectedId) {
        return elementIds.includes(startConnectedId) && elementIds.includes(endConnectedId);
    }

    return false;
};

export const getConnectedElements = (elements, elementIds) => elements.filter(doesElementConnectTo(elementIds));

/**
 * Get all elements connected by line elements in array
 */
export const getConnectingElementIds = (elements) =>
    toArray(
        elements?.flatMap((el) => {
            if (!elementCanConnect(el)) return [];
            return getLineConnectedElementIds(el);
        }),
    );
