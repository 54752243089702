// Utils
import { hasCommandModifier, hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';
import isBlockSelection from '../../customRichUtils/selection/isBlockSelection';
import isAllBlockTextSelection from '../../customRichUtils/selection/isAllBlockTextSelection';

// Constants
import { KEY_CODES } from '../../../../utils/keyboard/keyConstants';
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

const isCollapsedWithinCode = (editorState) => {
    if (!editorState.getSelection().isCollapsed()) return false;

    const currentStyle = editorState.getCurrentInlineStyle();

    return currentStyle.includes(STYLE_COMMANDS.CODE);
};

const shouldSwitchToCodeBlock = (editorState) => {
    if (isBlockSelection(editorState)) return true;

    if (isAllBlockTextSelection(editorState)) return true;

    if (isCollapsedWithinCode(editorState)) return false;

    return editorState.getSelection().isCollapsed();
};

/**
 * KEYBOARD MAPPINGS:
 *  Handled elsewhere:
 *      - Bold:  ⌘ B
 *      - Italic:  ⌘ I
 *      - Underline:  ⌘ U
 *      - Hyperlink:  ⌘ K
 *  Handled here:
 *      - Highlight:  ⌘ J
 *      - Code:  ⌘ >
 *      - Strike-through:  ⇧ ⌘ X
 *  Block styles handled here:
 *      - Unstyled: ⇧ ⌘ 0
 *      - Heading:    ⇧ ⌘ 1 or 2
 *      - Bullet list:  ⇧ ⌘ U
 *      - Ordered list:   ⇧ ⌘ O
 *      - Quote:  ⇧ ⌘ >
 *      - Checklist ⇧ ⌘ \
 *      - Code:  ⌘ >
 */
export default ({ enableBlockStyles }) =>
    (event, { getEditorState }) => {
        if (!hasCommandModifier(event)) return undefined;

        // All block styles handled here require the shift key
        if (enableBlockStyles) {
            switch (event.keyCode) {
                case KEY_CODES[0]:
                    return hasShiftKey(event) ? STYLE_COMMANDS.UNSTYLED : undefined;
                case KEY_CODES[1]:
                    return hasShiftKey(event) ? STYLE_COMMANDS.LARGE_HEADING : undefined;
                case KEY_CODES[2]:
                    return hasShiftKey(event) ? STYLE_COMMANDS.HEADING : undefined;
                case KEY_CODES[9]:
                    return hasShiftKey(event) ? STYLE_COMMANDS.SMALL_TEXT : undefined;
                case KEY_CODES.U:
                    return hasShiftKey(event) ? STYLE_COMMANDS.LIST : undefined;
                case KEY_CODES.O:
                    return hasShiftKey(event) ? STYLE_COMMANDS.ORDERED_LIST : undefined;
                case KEY_CODES.PERIOD: {
                    // Triggers on . or >
                    // The listed keyboard shortcut is ">", but will trigger regardless of whether shift is down
                    // the "." character just wasn't very readable in the shortcut popup, so use ">" instead
                    const editorState = getEditorState();

                    if (shouldSwitchToCodeBlock(editorState)) return STYLE_COMMANDS.CODE_BLOCK;

                    break;
                }
                case KEY_CODES.SINGLE_QUOTE:
                    // Triggers on ' or "
                    // The listed keyboard shortcut is ", but will trigger regardless of whether shift is down
                    return STYLE_COMMANDS.BLOCKQUOTE;
                case KEY_CODES.BACK_SLASH:
                    return STYLE_COMMANDS.ALIGN_CENTER;
                default:
                    break;
            }
        }

        switch (event.keyCode) {
            case KEY_CODES.J:
                return STYLE_COMMANDS.HIGHLIGHT;
            case KEY_CODES.PERIOD: {
                if (hasShiftKey(event)) return;

                return STYLE_COMMANDS.CODE;
            }
            case KEY_CODES.X: {
                if (!hasShiftKey(event)) return;

                return STYLE_COMMANDS.STRIKETHROUGH;
            }
            default:
                break;
        }

        return undefined;
    };
