// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Utils
import { validateField } from '../../../../common/validation/validationUtil';

const getError = (meta) => {
    // Don't show error message when field has never previously gained and lost focus
    if (!meta.touched) return undefined;

    // Prioritise on showing submission errors if it exist and form has not been modified after last submit
    if (meta.submitError && !meta.modifiedSinceLastSubmit) return meta.submitError;

    return meta.error;
};

const FinalField = (props) => {
    const { field, children, ...finalFieldProps } = props;

    return (
        <Field name={field.name} validate={validateField(field)} type={field.type} {...finalFieldProps}>
            {({ input, meta }) =>
                children({
                    input,
                    meta,

                    // `display` contains form information that has been processed for form display purposes
                    display: { error: getError(meta) },
                })
            }
        </Field>
    );
};

FinalField.propTypes = {
    field: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired,
};

export default FinalField;
