// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId, getMediaPlayerAutoplay } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { mediaToolSelector } from './mediaToolSelector';
import { updateMultipleElements } from '../../../../../../common/elements/elementActions';
import fileGetMediaPlayerType from '../../../../../element/file/utils/fileGetMediaPlayerType';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

import { MEDIA_PLAYER_TYPES } from '../../../../../element/file/mediaPlayer/mediaPlayerConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchSetMediaAutoplay: ({ elementIds, mediaPlayerAutoplay }) => {
        dispatch(
            updateMultipleElements({
                updates: elementIds.map((elementId) => ({
                    id: elementId,
                    changes: {
                        mediaPlayerAutoplay,
                    },
                })),
            }),
        );
    },
});

const MediaLoopTool = (props) => {
    const { showTool, isActive, name, selectedElements, dispatchSetMediaAutoplay } = props;

    const isMediaPlayerType = selectedElements.every(
        (element) =>
            fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.AUDIO ||
            fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.VIDEO,
    );

    if (!showTool || !isActive || !isMediaPlayerType) return null;

    const loopIsActive = selectedElements.every(getMediaPlayerAutoplay);

    const handleClick = () =>
        dispatchSetMediaAutoplay({
            elementIds: selectedElements.map(getElementId).toArray(),
            mediaPlayerAutoplay: !getMediaPlayerAutoplay(selectedElements.first()),
        });

    return (
        <ToolbarTool {...props} onClick={handleClick} name={name} isActive={loopIsActive}>
            <Icon name="toolbar-autoplay" />
        </ToolbarTool>
    );
};

MediaLoopTool.propTypes = {
    selectedElements: PropTypes.object,
    showTool: PropTypes.bool,
    isActive: PropTypes.bool,
    name: PropTypes.string,
    dispatchSetMediaAutoplay: PropTypes.func,
};

export default connect(mediaToolSelector, mapDispatchToProps)(MediaLoopTool);
