// Measurements
import measurementsRegistry from '../../measurementsRegistry';

// Utils
import { shiftInPlace } from './measureElementMathsUtils';
import * as pointLib from '../../../../../common/maths/geometry/point';
import { isCommentThread } from '../../../../../common/elements/utils/elementTypeUtils';
import { isIconViewLike } from '../../../../../common/elements/utils/elementDisplayUtils';
import { displayCommentAsCollapsed } from '../../../../../common/comments/commentModelUtil';
import { asRect, getRectCenterPoint, getTopLeft } from '../../../../../common/maths/geometry/rect';

// Types
import { Point } from '../../../../../common/maths/geometry/pointTypes';
import { Rect } from '../../../../../common/maths/geometry/rect/rectTypes';
import { ImMNElement, MNElement } from '../../../../../common/elements/elementModelTypes';

const getCollapsedCommentRect = (measurements: Rect): Rect => {
    if (!measurements) return measurements;

    const newTop = measurements.top - measurements.height;
    const newLeft = measurements.left - measurements.width / 2;

    return {
        ...measurements,
        bottom: newTop + measurements.height,
        top: newTop,
        left: newLeft,
        right: newLeft + measurements.width,
        y: newTop,
        x: newLeft,
    };
};

/**
 * If the element is a board and we have a reference to its DOM node, find the board icon
 * and use that as the connection origin for lines.
 */
const getOriginRect = (element: ImMNElement | MNElement, measurements: Rect, node: Element | undefined): Rect => {
    if (!node) return measurements;

    if (isCommentThread(element) && displayCommentAsCollapsed(element)) return getCollapsedCommentRect(measurements);

    if (!isIconViewLike(element)) return measurements;

    // Get the board's icon to use as the connection origin
    const iconNode = node.querySelector('.icon-container');

    return iconNode ? iconNode.getBoundingClientRect() : measurements;
};

/**
 * Gets the origin in terms of an offset in px from the top left point of the connection target's measurements.
 * This allows a different origin to be used for irregular shaped elements, while still keeping the connection boundary
 * around the perimeter of the element.  For example, we want to connect to the centre of board icons, but want the
 * connection boundary to include the text.
 *
 * @param element The element that this decorator is rendering.
 * @param measurements An object with top, left, width and height properties.
 * @param node If the node exists it will be used to determine the origin, otherwise the boundary
 *      measurements will be used.
 * @param scale The current scale of the board.
 */
const getUnscaledOriginOffset = (
    element: ImMNElement | MNElement,
    measurements: Rect,
    node: Element | undefined,
    scale = 1,
): Point => {
    const originRectMeasurements = getOriginRect(element, measurements, node);
    const originPx = getRectCenterPoint(originRectMeasurements);
    const boundaryTopLeftPoint = getTopLeft(measurements);

    const scaledOriginOffset = pointLib.reverseTranslate(boundaryTopLeftPoint, originPx);
    const unscaledOriginOffset = pointLib.scale(1 / scale, scaledOriginOffset);
    return pointLib.round(unscaledOriginOffset);
};

/**
 * If an element is irregularly shaped (e.g. a board is an inverted T shape) we can use
 * multiple rects to describe its perimeter.
 * This can then be used to find intersections more accurately.
 */
export const getElementRects = (
    element: ImMNElement | MNElement,
    measurements: Rect,
    node: Element | undefined,
    canvasOffset: Point,
): Rect[] => {
    const rects: Rect[] = [measurements];

    if (!node) return rects;

    if (isCommentThread(element) && displayCommentAsCollapsed(element)) return [getCollapsedCommentRect(measurements)];

    if (!isIconViewLike(element)) return rects;

    // Add the EditableTitle bounding rect to the rects array
    const titleContainerNode = node.querySelector('.title-container');

    if (!titleContainerNode) return rects;

    const titleBoundingRect = asRect(titleContainerNode.getBoundingClientRect());

    // Not sure why, but modifying this in place appears to be necessary!
    shiftInPlace(titleBoundingRect, canvasOffset);

    rects.push(titleBoundingRect);

    return rects;
};

/**
 * Gets the height of the feature suggestions, if it exists.
 */
const getFeatureSuggestionsHeight = (node: Element | undefined, gridSize: number): number => {
    if (!node) return 0;

    const featureSuggestions = node.querySelector('.FeatureSuggestions');

    if (!featureSuggestions) return 0;

    // The FeatureSuggestions element has a -0.2rem margin
    return featureSuggestions.getBoundingClientRect().height - 0.2 * gridSize;
};

type ElementDomNodeMeasurementsData = {
    featureSuggestionsHeight: number;
    originOffset: Point;
    canvasOffset: Point;
};

/**
 * Gets the data that's relevant for the measurements calculations from the DOM.
 * This allows the measureElementUtils to operate on raw data and thus tested without mocking the DOM.
 */
export const getElementDomMeasurementsData = (
    element: ImMNElement | MNElement,
    node: Element | undefined,
    measurements: Rect,
    gridSize: number,
    zoomScale: number,
): ElementDomNodeMeasurementsData => {
    const canvasOffset = measurementsRegistry.getCanvasViewportTranslation();
    const featureSuggestionsHeight = getFeatureSuggestionsHeight(node, gridSize);
    const originOffset = getUnscaledOriginOffset(element, measurements, node, zoomScale);

    return {
        featureSuggestionsHeight,
        canvasOffset,
        originOffset,
    };
};
