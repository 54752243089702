// Lib
import { flow } from 'lodash/fp';
import { createSelector } from 'reselect';

// Utils
import { asObject, prop } from '../../../common/utils/immutableHelper';
import { createDeepSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { buildElementFilterData } from './dataBuilder/elementFilterDataBuilder';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import {
    getCurrentBoardVisibleDescendants,
    getCurrentBoardVisibleInboxDescendants,
} from '../selectors/currentBoardSelector';
import { getAllComments } from '../comment/store/commentsSelector';

// Selector
import { getQuickNotesElementsDeep } from '../../workspace/header/workspaceToolsHeader/quickNotes/quickNotesElementsSelector';

// Constants
import { ELEMENT_FILTER_TYPES } from './elementFilterConstants';
import { getCurrentBoard } from '../board/boardSelector';

// Element filter accessor methods
export const getElementFilterIds = prop('ids');
export const getElementFilterQuery = prop('query');
export const getElementFilterContext = prop('context');

// Element data accessor methods
export const getElementData = (elementId, elementData) => prop(elementId, elementData);
export const getPassesFilter = prop('pass');
export const getChangeCount = prop('changeCount');

export const getElementFilterSelector = (state) => state.getIn(['app', 'elementFilter']);
export const getElementFilterQuerySelector = flow(getElementFilterSelector, getElementFilterQuery);
export const getElementFilterContextSelector = flow(getElementFilterSelector, getElementFilterContext);

export const elementFilterEnabledSelector = createSelector(
    getElementFilterSelector,
    (elementFilter) => elementFilter && !elementFilter.isEmpty(),
);

export const elementFilterTypeSelector = createSelector(getElementFilterSelector, (elementFilter) => {
    if (!elementFilter || elementFilter.isEmpty()) return;

    if (getElementFilterQuery(elementFilter)) return ELEMENT_FILTER_TYPES.SEARCH;
});

const currentBoardFilterElementsSelector = createSelector(
    getCurrentBoardVisibleDescendants,
    getQuickNotesElementsDeep,
    (boardElements, quickNotesElements) => boardElements.merge(quickNotesElements),
);

export const elementFilterDataSelector = createDeepSelector(
    getElementFilterSelector,
    getCurrentBoard,
    currentBoardFilterElementsSelector,
    getAllComments,
    (elementFilter, currentBoard, currentBoardFilterElements, comments) =>
        buildElementFilterData({
            elementFilter: asObject(elementFilter),
            currentBoard,
            currentBoardDeepChildren: currentBoardFilterElements,
            comments,
        }),
);

export const currentBoardFilterDataSelector = createSelector(getCurrentBoardId, elementFilterDataSelector, prop);

export const currentBoardFilterCountSelector = createSelector(
    currentBoardFilterDataSelector,
    (currentBoardData) => getChangeCount(currentBoardData) || 0,
);

export const getCurrentElementId = (state, ownProps) => ownProps.elementId || getElementId(ownProps.element);
export const createCurrentElementFilterDataSelector = () =>
    createSelector(getCurrentElementId, elementFilterDataSelector, prop);

export const hasFilteredInboxElementsSelector = createSelector(
    elementFilterDataSelector,
    getCurrentBoardVisibleInboxDescendants,
    (elementFilterData, inboxElements) => {
        if (!elementFilterData || !inboxElements) return false;

        return inboxElements.some((inboxElement) => {
            const elementId = getElementId(inboxElement);
            return getPassesFilter(getElementData(elementId, elementFilterData));
        });
    },
);
