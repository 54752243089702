export const AUTH_FLOWS = {
    SIGN_IN: 'sign-in',
    REGISTER: 'register',
    REGISTER_CONFIRM: 'register-confirm',
    AUTHORISATION: 'authorisation',
};

export const AUTH_FRAGMENT_PARAMS = {
    GOOGLE_SUB: 'google-sub',
    APPLE_SUB: 'apple-sub',
};
