// Lib
import * as Immutable from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import { elementClassNames, getDomElementId } from '../utils/elementUtil';

// Components
import BoardIcon from '../board/boardIcon/BoardIcon';
import EditableTitle from '../../components/editableTitle/EditableTitle';
import IconView from '../../components/displayMode/IconView';
import BoardChildrenLoadObserver from '../board/BoardChildrenLoadObserver';

// Utils
import { getElementStyle } from '../../utils/grid/gridUtils';
import { getBoardIconSize } from '../board/Board';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { DEFAULT_ICON_VIEW_WIDTH } from '../../../common/elements/elementDisplayModeConstants';

const iconData = Immutable.Map({ fetching: true });

const AliasLoading = (props) => (
    <BoardChildrenLoadObserver {...props}>
        <div
            id={getDomElementId(getElementId(props.element))}
            className={elementClassNames('Board', props)}
            style={getElementStyle(DEFAULT_ICON_VIEW_WIDTH, props.gridSize, props.inList)}
        >
            <IconView
                className="board-content"
                iconElement={
                    <BoardIcon
                        size={getBoardIconSize(props)}
                        icon={iconData}
                        gridSize={props.gridSize}
                        getContextZoomScale={props.getContextZoomScale}
                        getContextZoomTranslationPx={props.getContextZoomTranslationPx}
                    />
                }
                titleElement={<EditableTitle />}
            />
        </div>
    </BoardChildrenLoadObserver>
);

AliasLoading.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number.isRequired,
    inList: PropTypes.string,
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isHovered: PropTypes.bool,
    className: PropTypes.string,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default AliasLoading;
