import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BlockStyleTickIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon block-style-tick"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="m13 3.586-7 7-3-3L1.586 9l3.707 3.707a1 1 0 0 0 1.414 0L14.414 5 13 3.586z"
        />
    </svg>
);
const Memo = memo(BlockStyleTickIcon);
export default Memo;
