// Lib
import React, { ReactElement } from 'react';

// Components
import ElementExportPopupButton from './ElementExportPopupButton';

// Constants
import { MimeType } from '../../../common/media/mimeConstants';
import { BoardExportFormat, BoardExportQuality } from '../../../common/export/exportConstants';
import { FILE_TYPES_INFO } from '../../../common/files/fileConstants';

// Styles
import './ExportPopup.scss';

type Props = {
    elementId: string;
};

const ExportPopupForDocumentModal = ({ elementId }: Props): ReactElement => (
    <div className="ExportPopup">
        <div className="export-section">
            <div className="export-options">
                <h3>Export as...</h3>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MimeType.PDF}
                    fileType={FILE_TYPES_INFO.PDF.type}
                    format={BoardExportFormat.linear}
                    quality={BoardExportQuality.high}
                >
                    PDF
                </ElementExportPopupButton>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MimeType.DOCX}
                    fileType={FILE_TYPES_INFO.DOCX.type}
                    format={BoardExportFormat.linear}
                >
                    Word document
                </ElementExportPopupButton>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MimeType.MARKDOWN}
                    fileType={FILE_TYPES_INFO.TEXT.type}
                    format={BoardExportFormat.linear}
                >
                    Markdown
                </ElementExportPopupButton>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MimeType.PLAIN_TEXT}
                    fileType={FILE_TYPES_INFO.TEXT.type}
                    format={BoardExportFormat.linear}
                >
                    Plain text
                </ElementExportPopupButton>
            </div>
        </div>
    </div>
);

export default ExportPopupForDocumentModal;
