// Lib
import { isEmpty, isString, curry } from 'lodash/fp';

// Utils
import { prop, propIn } from '../../utils/immutableHelper';
import { hasScope } from '../../utils/googleUtils';
import { getTimestamp } from '../../utils/timeUtil';

// Constants
import { USER_AUTHENTICATION_METHODS, USER_ROLES } from '../userConstants';
import { DEFAULT_CONTENT_LIMIT, DEFAULT_MAX_REFERRAL_BONUS } from '../contentLimit';

// All
export const getGoogleScope = propIn(['google', 'scope']);

export const getUserEmail = prop('email');
export const getRootBoardId = prop('rootBoardId');
export const getContentLimitObject = prop('contentLimit');
export const getCurrentContentLimit = propIn(['contentLimit', 'current']);
export const getDefaultContentLimit = (user) => propIn(['contentLimit', 'default'], user) || DEFAULT_CONTENT_LIMIT;
export const getGiftBonus = (user) => propIn(['contentLimit', 'giftBonus'], user) || 0;
export const getReferralBonus = (user) => propIn(['contentLimit', 'referralBonus'], user);
export const getMaxReferralBonus = (user) =>
    propIn(['contentLimit', 'maxReferralBonus'], user) || DEFAULT_MAX_REFERRAL_BONUS;
export const getLastCheckedNotifications = (user) => prop('lastCheckedNotifications', user) || 0;
export const getFlags = prop('flags');
export const getFeatureFlag = curry((flagName, user) => propIn(['flags', flagName], user) || false);
export const getUserTimezone = prop('timezone');
export const getUserSegment = (user) =>
    propIn(['userSegment', 'segment'], user) || propIn(['segments', 0], user) || 'generic';
export const getEnabledExperiments = prop('experiments');

// Server side only
export const getUserLocalPassword = propIn(['local', 'password']);
export const getGoogleData = prop('google');
export const getGoogleId = propIn(['google', 'id']);
export const getAppleId = propIn(['apple', 'id']);
export const getAccessToken = propIn(['google', 'accessToken']);
export const getRefreshToken = propIn(['google', 'refreshToken']);

// Property getters
export const getUserId = prop('_id');
export const getStatus = prop('status');
export const getStatusDetails = prop('statusDetails');
export const getUserImage = prop('image');
export const getUserEducation = prop('education');
export const getUserOnboardingStep = prop('onboarding');
export const getEmailIsVerified = (user) => !!prop('emailVerifiedDate', user);

// Subscriptions
export const getSubscriptionId = prop('subscriptionId');
export const getSubscriptionInviteId = propIn(['subscriptionMeta', 'inviteId']);
export const getSubscriptionRequiredDomains = propIn(['subscriptionMeta', 'requiredDomains']);
export const getSubscription = prop('subscription');
export const getCustomerId = prop('customerId');

// Settings
export const getUserSettings = prop('settings');
export const getPushNotificationsPreference = propIn(['settings', 'pushNotifications']);
export const getEmailNotificationsPreference = propIn(['settings', 'email']);
export const getUserShowGrid = propIn(['settings', 'showGrid']);
export const getUserPushCards = propIn(['settings', 'pushCards']);
export const getUserEnableTemplatePicker = propIn(['settings', 'enableTemplatePicker']);
export const getUserEnableDrawingInkEffect = propIn(['settings', 'enableDrawingInkEffect']);
export const getUserEnableDoubleClickToCreateNotes = propIn(['settings', 'enableDoubleClickToCreateNotes']);
export const getUserEnableUndoRedoButtonsForWeb = propIn(['settings', 'enableUndoRedoButtons']);
export const getUserEnableUndoRedoButtonsForIpad = propIn(['settings', 'enableUndoRedoButtonsForIpad']);
export const getUserLanguagePreference = propIn(['settings', 'languagePreference']);
export const getUserCurrencyPreference = propIn(['settings', 'currencyPreference']);

export const getEnableAnonymousViewing = (user) => !!propIn(['settings', 'enableAnonymousViewing'], user);
export const getUserDisableSerifHeadings = propIn(['settings', 'disableSerifHeadings']);
export const getUserPreferredColorDisplay = propIn(['settings', 'preferredColorDisplay']);
export const getUserEnableFeatureSuggestion = propIn(['settings', 'enableFeatureSuggestion']);

export const getUserPrimaryToolsSettings = propIn(['settings', 'primaryTools']);
export const getUserPrimaryToolsTouchSettings = propIn(['settings', 'primaryToolsTouch']);

export const getUserReactionsRecentlyUsed = propIn(['settings', 'reactionsRecent']);
export const getUserReactionsSkinTone = propIn(['settings', 'reactionsSkinTone']);

export const getAccountCreationDate = prop('accountCreationDate');
export const getAccountRegistrationDate = prop('accountRegistrationDate');

export const getAccountAge = (user) => {
    if (!getAccountRegistrationDate(user)) return -1;
    return getTimestamp() - getAccountRegistrationDate(user);
};

export const getUserBrowserExtensionsSettings = (currentUser) => propIn(['settings', 'browserExtensions'], currentUser);

// Client side only
export const getIsGuestProp = prop('isGuest');
export const getIsAnonymousProp = prop('isAnonymous');
export const getReferralCode = prop('referrerCode');
export const getUserCurrentBoardId = prop('currentBoardId');
export const getAuthMethods = prop('authMethods');
export const getShareCount = prop('shareCount');

export const hasPasswordAuthenticationMethod = (user) => {
    const authMethods = getAuthMethods(user);
    return authMethods && authMethods.includes(USER_AUTHENTICATION_METHODS.PASSWORD);
};

export const hasLinkedGoogleData = (user) => !isEmpty(getGoogleData(user));
export const hasGoogleScope = (user, scope) => hasLinkedGoogleData(user) && hasScope(getGoogleScope(user), scope);

// Functions
export const hasImage = (user) => !!getUserImage(user);

export const getRole = (user) => (isString(user) ? user : prop('role', user));

export const isAdmin = (user) => getRole(user) === USER_ROLES.ADMIN;
export const isStaff = (user) => getRole(user) === USER_ROLES.STAFF || isAdmin(user);

/**
 *
 * @param {ExperimentId} feature
 * @param {MNUser} user
 * @returns {boolean}
 */
export const isFeatureEnabled = (feature, user) => !!getEnabledExperiments(user)?.includes(feature);
