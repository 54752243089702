import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarLineWeight4PxIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-line-weight-4-px"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path stroke="#48505E" strokeLinecap="round" strokeWidth={4} d="M8 24 24 8" />
        </g>
    </svg>
);
const Memo = memo(ToolbarLineWeight4PxIcon);
export default Memo;
