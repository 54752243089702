import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" className="Icon plus" {...props}>
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M8.75 3.757V7.25h3.493a.75.75 0 0 1 .742.64l.008.11a.75.75 0 0 1-.64.742l-.11.008H8.75v3.493a.75.75 0 0 1-1.5 0V8.75H3.757a.75.75 0 0 1-.742-.64L3.007 8a.75.75 0 0 1 .64-.742l.11-.008H7.25V3.757a.75.75 0 0 1 1.5 0z"
        />
    </svg>
);
const Memo = memo(PlusIcon);
export default Memo;
