// Utils
import { asyncResource } from '../../utils/services/http/asyncResource/asyncResource';

// Constants
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { CmsEntities } from '../../cms/cmsConstants';

const EMOJI_DATA_URL = 'https://static.milanote.com/emoji-assets/data.json';

export const fetchEmojiData = () => async (dispatch) => {
    dispatch(
        asyncResource(
            ResourceTypes.cms,
            CmsEntities.EMOJI,
        )(async () => {
            const response = await fetch(EMOJI_DATA_URL);
            return response.json();
        }),
    );
};
