// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './CommentThreadCollapsedDragHandle.scss';

const HALF_BORDER_WIDTH = 0.25;

// NOTE: There's a lot of similarities between this and the DragHandle.
// Perhaps they could be abstracted
const CommentThreadCollapsedDragHandle = (props) => {
    const { className } = props;

    const radius = global.lines.handleSize / 2;

    return (
        <div className={classNames('CommentThreadCollapsedDragHandle', className)}>
            <div className="attach-mode-shadow" />
            <svg
                className="handle"
                version="1.1"
                width={global.lines.handleSize}
                height={global.lines.handleSize}
                viewBox={`0 0 ${global.lines.handleSize} ${global.lines.handleSize}`}
            >
                <circle cx={radius} cy={radius} r={radius - HALF_BORDER_WIDTH} />
            </svg>
        </div>
    );
};

CommentThreadCollapsedDragHandle.propTypes = {
    className: PropTypes.string,
};

export default CommentThreadCollapsedDragHandle;
