/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getElementId } from '../../common/elements/utils/elementPropertyUtils';
import { getTimestamp } from '../../common/utils/timeUtil';

// FIXME-MEASURE I attempted to use the measurements map to determine the range array, however I
//  realised it was more complex than I initially expected due to the need to translate from
//  page X & Y (the mouse position) to Canvas / Inbox X & Y (measurements map).
//  The measurements map currently saves measurements based on the Canvas & its scroll position,
//  even for elements within unsorted notes, which is incorrect and needs to be updated.
//  As such we would need a generic way to determine the scroll / offset from the actual offset
//  parent.
const calculateRangeArray = (listElements) =>
    listElements
        .map((listElement) => ({
            id: listElement.props.elementId || getElementId(listElement.props.element),
            range: listElement.getRange(),
        }))
        .sort((listElementA, listElementB) => listElementA.range - listElementB.range);

export default (DecoratedComponent) => {
    class listElementManager extends React.Component {
        constructor(props) {
            super(props);

            this.cacheTime = 0;
            this.listElements = [];
            this.rangeArray = null;
        }

        /**
         * Keeps track of the list elements for this list, so their vertical ranges can be extracted on hover.
         * @param listElement {ReactComponent} The ListElementContainer component for the list element.
         */
        registerListElement = (listElement) => {
            this.listElements.push(listElement);
        };

        /**
         * Removes an element from the array of list elements (because, for example it's been removed from the list.
         * @param listElement {ReactComponent} The ListElementContainer component for the list element.
         */
        deregisterListElement = (listElement) => {
            const listElementIndex = this.listElements.indexOf(listElement);
            if (listElementIndex !== -1) {
                this.listElements.splice(listElementIndex, 1);
            }
        };

        clearRangeArray = () => {
            this.rangeArray = null;
        };

        /**
         * Min age is used to determine when a cached rangeArray can be used or when it needs to be recalculated.
         * The caching prevents many unnecessary invocations of getBoundingRect during drag hovers.
         *
         * If a minAge isn't provided, the range array will be recalculated.
         */
        getRangeArray = (minAge = Infinity) => {
            if (!this.rangeArray || this.cacheTime < minAge) {
                this.cacheTime = getTimestamp();
                this.rangeArray = calculateRangeArray(this.listElements);
            }

            return this.rangeArray;
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    getRangeArray={this.getRangeArray}
                    registerListElement={this.registerListElement}
                    deregisterListElement={this.deregisterListElement}
                />
            );
        }
    }

    listElementManager.propTypes = {
        measurements: PropTypes.object,
    };

    return listElementManager;
};
