// Utils
import { simpleDepthFirstTraversal } from '../../../../common/dataStructures/graphUtils';
import combineBoardActivity from './combineBoardActivity';

/**
 * Builds a collated activity map for boards that should show activity indicators.
 * This map should be able to be cached well as it shouldn't change often.
 */
export default ({ boardActivity, fullVirtualBoardParentGraph }) => {
    // For each board activity entry
    const boardActivityIds = Object.keys(boardActivity);
    const boardActivityCount = boardActivityIds.length;

    const hierarchicalActivityMap = {};

    for (let i = 0; i < boardActivityCount; i++) {
        const boardId = boardActivityIds[i];
        const parentIds = fullVirtualBoardParentGraph[boardId];

        // If there's no parentIds then it's not a board activity entry, so ignore
        if (!parentIds) continue;

        // Traverse up the graph, finding all possible ancestors
        const dfsResult = simpleDepthFirstTraversal(fullVirtualBoardParentGraph, boardId);

        // and combine the entries
        dfsResult.forEach((ancestorId) => {
            hierarchicalActivityMap[ancestorId] = combineBoardActivity(
                hierarchicalActivityMap[ancestorId],
                boardActivity[boardId],
            );
        });
    }

    return hierarchicalActivityMap;
};
