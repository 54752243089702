// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from '../../node_module_clones/recompose';
import classNames from 'classnames';

// Utils
import { tokenizeMatches } from '../../common/utils/stringUtil';

const DefaultHighlightComponent = ({ children }) => <span className="highlight">{children}</span>;

DefaultHighlightComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const HighlightedText = ({ highlightText, HighlightComponent = DefaultHighlightComponent, text, className }) => (
    <span className={classNames('HighlightedText', className)}>
        {!highlightText && <span key="text">{text}</span>}
        {highlightText &&
            tokenizeMatches(highlightText, text).map(({ textPart, isMatch }, i) => {
                const Component = isMatch ? HighlightComponent : 'span';

                return <Component key={i}>{textPart}</Component>;
            })}
    </span>
);

HighlightedText.propTypes = {
    className: PropTypes.string,
    highlightText: PropTypes.string,
    text: PropTypes.string,
    HighlightComponent: PropTypes.elementType,
};

export default pure(HighlightedText);
