import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ChevronRightLargeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon chevron-right-large"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M14.69 12 8.22 5.53a.75.75 0 0 1 1.06-1.06l7 7a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06-1.06L14.69 12z"
        />
    </svg>
);
const Memo = memo(ChevronRightLargeIcon);
export default Memo;
