export type GridSizeDefinition = {
    size: number;
    breakpoint: number;
    name: string;
};

// if these are changed, also make sure to update GridSizeBodyClass.js,
// which sets the grid size class on initial render
export const GRID: Record<string, GridSizeDefinition> = {
    // FIXME-MOBILE - The grid size property should be independent of whether we're on mobile or not.
    MOBILE: { size: 10, breakpoint: 0, name: 'mobile' },
    SMALL: { size: 8, breakpoint: 600, name: 'small' },
    MEDIUM: { size: 9, breakpoint: 1300, name: 'medium' },
    LARGE: { size: 10, breakpoint: 1900, name: 'large' },
};

export const GRID_SIZE_LIST: GridSizeDefinition[] = [
    { size: 10, breakpoint: 0, name: 'mobile' },
    { size: 8, breakpoint: 600, name: 'small' },
    { size: 9, breakpoint: 1300, name: 'medium' },
    { size: 10, breakpoint: 1900, name: 'large' },
];

// screen display points reference
// https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/Displays/Displays.html
export const GRID_SIZE_LIST_TOUCH_DEVICE: GridSizeDefinition[] = [
    { size: 10, breakpoint: 0, name: 'mobile' },
    // 9.7" iPads
    { size: 8, breakpoint: 1000, name: 'small' },
    // 10.2" iPads
    { size: 9, breakpoint: 1190, name: 'medium' },
    // 12.9" iPads
    { size: 10, breakpoint: 1200, name: 'large' },
];

export const GRID_RESIZE = 'GRID_RESIZE';
