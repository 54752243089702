// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import { getIsLineLabelEnabled, getLineLabel } from '../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { deselectAllElements, finishEditingElement, startEditingElement } from '../../selection/selectionActions';
import { updateElement } from '../../actions/elementActions';

// Components
import ElementSimpleContentEditable from '../../../components/editableTitle/ElementSimpleContentEditable';

// Styles
import './LineLabelContentEditable.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchStartEditing: ({ id, editorKey, editorId }) => dispatch(startEditingElement({ id, editorKey, editorId })),
    dispatchStopEditing: ({ id, keepSelection = false }) => {
        dispatch(finishEditingElement(id));
        !keepSelection && dispatch(deselectAllElements());
    },
    dispatchUpdateLineLabel: ({ id, lineLabel, lineLabelEnabled }) =>
        dispatch(
            updateElement({
                id,
                changes: { lineLabel, lineLabelEnabled },
            }),
        ),
});

@connect(null, mapDispatchToProps)
class LineLabelContentEditable extends React.Component {
    onStartEditing = () => {
        const { elementId, dispatchStartEditing } = this.props;
        dispatchStartEditing({
            id: elementId,
            editorId: getMainEditorId(this.props),
            editorKey: getMainEditorKey(this.props),
        });
    };

    onStopEditing = ({ keepSelection }) => {
        const { elementId, dispatchStopEditing } = this.props;
        dispatchStopEditing({ id: elementId, keepSelection });
    };

    onUpdate = (label) => {
        const { elementId, element, dispatchUpdateLineLabel } = this.props;

        const labelSansNewlines = (label && label.replace('\n', '')) || '';

        const noLabel =
            !label ||
            labelSansNewlines.length <= 0 ||
            // Weird bug I couldn't fix closer to the source in ElementSimpleContentEditable
            // The innerText comes out as a string 'undefined' rather than the value undefined
            // This would cause the label to get updated to "undefined" if you click the label tool,
            // then unselect the line.
            // This fix will mean you can't actually name your line "undefined"
            label === 'undefined';

        // If all there is is a newline character
        if (noLabel) {
            // If already disabled, remain disabled
            if (!getIsLineLabelEnabled(element)) return;

            return dispatchUpdateLineLabel({
                id: elementId,
                lineLabelEnabled: false,
                lineLabel: getLineLabel(element),
            });
        }

        // Don't dispatch an update if there's been no changes
        if (getLineLabel(element) === label && getIsLineLabelEnabled(element)) return;

        return dispatchUpdateLineLabel({
            id: elementId,
            lineLabelEnabled: true,
            lineLabel: label,
        });
    };

    onEmptyBackspace = () => {
        const { elementId, dispatchStopEditing } = this.props;
        dispatchStopEditing({ id: elementId });
    };

    render() {
        const { children, isEditing, isEditable, isSelected, filterQuery } = this.props;

        return (
            <ElementSimpleContentEditable
                className="LineLabelContentEditable"
                isEditing={isEditing}
                isEditable={isEditable}
                isSelected={isSelected}
                enterKeyHint="Done"
                multiline
                selectAllOnEdit
                selectFirst
                filterQuery={filterQuery}
                onStartEditing={this.onStartEditing}
                onStopEditing={this.onStopEditing}
                onUpdate={this.onUpdate}
                onEmptyBackspace={this.onEmptyBackspace}
            >
                {children}
            </ElementSimpleContentEditable>
        );
    }
}

LineLabelContentEditable.propTypes = {
    children: PropTypes.string,

    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    multiline: PropTypes.bool,

    filterQuery: PropTypes.string,

    elementId: PropTypes.string,
    element: PropTypes.object,

    dispatchUpdateLineLabel: PropTypes.func,
    dispatchStartEditing: PropTypes.func,
    dispatchStopEditing: PropTypes.func,
};

export default LineLabelContentEditable;
