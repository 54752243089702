// Lib
import { trim } from 'lodash';

// Utils
import isOnlyLink from './utils/isOnlyLink';
import { getIgnoreAutoType } from '../../../../../common/elements/utils/elementPropertyUtils';

// Constants

import { ElementType } from '../../../../../common/elements/elementTypes';

export default (editorState, getProps) => {
    if (!isOnlyLink(editorState)) return false;

    const { updateElementType, preventSaveOnUnmount, element } = getProps();

    // If the element has been marked to ignore automatic type conversion, then don't trigger
    if (getIgnoreAutoType(element)) return;

    // Ensure the editor component doesn't force an update event now that we're
    // changing the component type
    preventSaveOnUnmount();

    const url = trim(editorState.getCurrentContent().getPlainText());
    updateElementType({ elementType: ElementType.LINK_TYPE, data: { url } });

    return true;
};
