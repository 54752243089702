// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { cleanSvgForInnerHtml } from '../../utils/svgUtils';
import { getDrawingSvg, isTransparencyEnabled } from '../../../common/elements/utils/elementPropertyUtils';

// Styles
import './DrawingSvg.scss';

const VIEW_BOX_REGEX = /viewBox=".+?"/;

const getSvgString = (element, viewBox) => {
    const svg = getDrawingSvg(element);

    if (!viewBox || !svg) return svg;

    if (!svg.includes(viewBox)) {
        return svg.replace('<svg ', `<svg viewBox="${viewBox}"`);
    }

    return svg.replace(VIEW_BOX_REGEX, `viewBox="${viewBox}"`);
};

export const SimpleDrawingSvg = React.forwardRef(function SimpleDrawingSvg(props, ref) {
    const { children, element, onDoubleClick, viewBox } = props;

    const svg = getSvgString(element, viewBox);

    return (
        <div className="DrawingSvg" ref={ref} onDoubleClick={onDoubleClick}>
            <div className="svg-content" dangerouslySetInnerHTML={cleanSvgForInnerHtml(svg)} />
            {children}
        </div>
    );
});

SimpleDrawingSvg.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    element: PropTypes.object,
    viewBox: PropTypes.string,
    onDoubleClick: PropTypes.func,
};

const DrawingSvgPathDraggable = (props) => {
    const { disableDrag, enableDrag } = props;

    const drawingNodeRef = React.useRef();

    // On mount, prevent drag, much like the Line.js so that hover events work correctly
    React.useEffect(() => {
        disableDrag && disableDrag();

        return () => {
            enableDrag && enableDrag();
        };
    }, []);

    return <SimpleDrawingSvg ref={drawingNodeRef} {...props} />;
};

DrawingSvgPathDraggable.propTypes = {
    enableDrag: PropTypes.func,
    disableDrag: PropTypes.func,
};

const DrawingSvgSwitch = (props) => {
    const { isPresentational, isDragPreview, isSelected, isRemotelySelected, enableDrag, element } = props;

    if (
        !isTransparencyEnabled(element) ||
        isSelected ||
        isRemotelySelected ||
        isPresentational ||
        isDragPreview ||
        !enableDrag
    ) {
        return <SimpleDrawingSvg {...props} />;
    }

    return <DrawingSvgPathDraggable {...props} />;
};

DrawingSvgSwitch.propTypes = {
    element: PropTypes.object,
    isSelected: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    isPresentational: PropTypes.bool,
    isDragPreview: PropTypes.bool,
    enableDrag: PropTypes.func,
};

export default DrawingSvgSwitch;
