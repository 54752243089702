// Utils
import logger from '../logger/logger';
import { isFeatureEnabled, getIsGuestProp, isStaff } from '../../common/users/utils/userPropertyUtils';

// Selectors
import { getCurrentUser } from '../user/currentUserSelector';

export const dispatchSelector = (selectorFn) => (props) => (dispatch, getState) => selectorFn(getState(), props);

export const featureCheckAction =
    (featureName, staffEnabled = false) =>
    (actionFn) =>
    (...args) =>
    (dispatch, getState) => {
        const state = getState();
        const user = getCurrentUser(state);

        if (getIsGuestProp(user)) {
            logger.warn('The "%s" feature is not enabled for guests', featureName, user);
            return;
        }

        const enabled = (staffEnabled && isStaff(user)) || isFeatureEnabled(featureName, user);

        if (!enabled) {
            logger.warn('This "%s" feature is not enabled for this user', featureName, user);
            return;
        }

        logger.info('Feature is being used', featureName);

        return dispatch(actionFn(...args));
    };
