// Lib
import { EditorState, Modifier, SelectionState } from 'draft-js';

// Utils
import forceFocus from './forceFocus';

// Constants
import { EditorChangeType } from '../draftjsConstants';

/**
 * Remove all inline styles all blocks inside editorState
 */
const removeAllInlineStyle = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelectionState = editorState.getSelection();

    let newContentState = currentContent;

    currentContent.getBlockMap().forEach((block) => {
        const selection = SelectionState.createEmpty(block.getKey()).merge({
            anchorOffset: 0,
            focusOffset: block.getText().length,
        });

        newContentState = Modifier.replaceText(currentContent, selection, block.getText(), null, null);
    });

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_INLINE_STYLE);

    return forceFocus(newEditorState, currentSelectionState);
};

export default removeAllInlineStyle;
