// Lib
import { intersection, first, slice, difference } from 'lodash';

// Utils
import { toArray } from '../../../common/utils/immutableHelper';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isLocationInbox } from '../../../common/elements/utils/elementLocationUtils';
import { getLocationParentId } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getElements } from '../selectors/elementSelector';
import { getElementChildIdsSorted } from '../selectors/elementChildrenSelector';
import { getParentIdRangeAnchor, getSelectedElementIds } from '../selection/selectedElementsSelector';

// Actions
import {
    addSelectedElements,
    removeSelectedElements,
    setSelectedElements,
    toggleSelectedElements,
} from '../selection/selectionActions';

// Types
import { SelectionMode } from '../selection/selectionConstants';

/**
 * Selects a single element, overwriting the current selection.
 * Also sets the current range anchors when appropriate.
 */
const handleSingleSelectElement = (id: string, transactionId: string) => (dispatch: Function, getState: Function) => {
    const state = getState();

    const elements = getElements(state);
    const selectedElement = getElement(elements, id);

    const parentId = getLocationParentId(selectedElement);
    const rangeAnchors = isLocationInbox(selectedElement) ? { [parentId]: id } : null;

    return dispatch(setSelectedElements({ ids: [id], rangeAnchors, transactionId }));
};

/**
 * Simply toggle the given element ID when attempting a multi-select.
 */
const handleMultiSelectElement = (id: string, transactionId: string) => (dispatch: Function, getState: Function) => {
    const state = getState();

    const elements = getElements(state);
    const selectedElement = getElement(elements, id);

    const parentId = getLocationParentId(selectedElement);

    const rangeAnchors = isLocationInbox(selectedElement) ? { [parentId]: id } : null;

    return dispatch(toggleSelectedElements({ ids: [id], rangeAnchors, transactionId }));
};

/**
 * Selects a range of elements, starting from an anchor point and selecting all elements in-between if the
 * selected element is within an INBOX.
 */
const handleRangeSelectElement = (id: string, transactionId: string) => (dispatch: Function, getState: Function) => {
    const state = getState();

    const elements = getElements(state);
    const selectedElement = getElement(elements, id);

    if (!isLocationInbox(selectedElement)) return dispatch(handleMultiSelectElement(id, transactionId));

    // Get range anchor for the parent that the selected element is in
    const parentId = getLocationParentId(selectedElement);

    const sortedChildIds = getElementChildIdsSorted()(state, { elementId: parentId });

    const existingSelectedElementIds = toArray(getSelectedElementIds(state));

    // NOTE: The intersection here will maintain the order of sortedChildIds
    const orderedExistingSelectedElementIds = intersection(sortedChildIds, existingSelectedElementIds);

    const rangeAnchor = getParentIdRangeAnchor(state, { id: parentId }) || first(orderedExistingSelectedElementIds);

    if (!rangeAnchor) return dispatch(handleMultiSelectElement(id, transactionId));

    const rangeAnchorIndex = sortedChildIds.indexOf(rangeAnchor);
    const selectedIndex = sortedChildIds.indexOf(id);

    const rangeStartIndex = Math.min(rangeAnchorIndex, selectedIndex);
    const rangeEndIndex = Math.max(rangeAnchorIndex, selectedIndex);

    const selectionRangeIds = slice(sortedChildIds, rangeStartIndex, rangeEndIndex + 1);

    // Determine the current elements that are selected in the list, then appropriately toggle them
    const currentlySelectedChildrenIdsToRemove = difference(orderedExistingSelectedElementIds, selectionRangeIds);
    const newSelectedChildrenIdsToAdd = difference(selectionRangeIds, orderedExistingSelectedElementIds);

    dispatch(removeSelectedElements({ ids: currentlySelectedChildrenIdsToRemove, transactionId }));
    return dispatch(addSelectedElements({ ids: newSelectedChildrenIdsToAdd, rangeAnchors: null, transactionId }));
};

/**
 * Handles the selection (or toggle) of a single element, based on the specified selection mode.
 */
export const handleSelectionModeSelectElement =
    (id: string, selectionMode: SelectionMode, transactionId: string) => (dispatch: Function, getState: Function) => {
        switch (selectionMode) {
            case SelectionMode.SINGLE:
                return dispatch(handleSingleSelectElement(id, transactionId));
            case SelectionMode.MULTIPLE:
                return dispatch(handleMultiSelectElement(id, transactionId));
            case SelectionMode.RANGE:
                return dispatch(handleRangeSelectElement(id, transactionId));
            default:
                return;
        }
    };
