import { isString } from 'lodash';

import { FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';

const OBJECT_ERROR_OBJECT = {
    name: FAILURE_NAMES.object,
    type: FAILURE_TYPES.ERROR,
    message: 'Must be a string',
};

export default (fieldValue, validationConfig) => {
    if (!fieldValue) return;

    if (!isString(fieldValue)) return OBJECT_ERROR_OBJECT;

    return null;
};
