// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getDepth } from '../../../../../../common/elements/utils/elementTraversalUtils';
import { getTaskListAncestorId } from '../../../../../../common/taskLists/taskListUtils';
import { sortByScoreProperty } from '../../../../../../common/elements/utils/elementSortUtils';
import { getPreviousSiblingViaGraph } from '../../../../../../common/elements/utils/elementGraphUtils';

// Selectors
import { getElements } from '../../../../../element/selectors/elementsSelector';
import { elementGraphSelector } from '../../../../../element/selectors/elementGraphSelector';
import { getCurrentlyEditingId } from '../../../../../element/selection/currentlyEditingSelector';

// Editor selectors
import { editorSelectionSave } from '../../../../../components/editor/store/editorActions';
import { getEditorId, getEditorState } from '../../../../../components/editor/store/reducers/editorStoreSelector';

// Actions
import { indentTask, MAX_TASK_DEPTH } from '../../../../../element/taskList/taskListActions';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';
import TooltipSource from '../../../../../components/tooltips/TooltipSource';

// Constants
import { TooltipPositions } from '../../../../../components/tooltips/tooltipConstants';

const handleIndentClick =
    () =>
    (dispatch, getState, { editorStore }) => {
        const dispatchSaveCurrentSelection = () => {
            const editorDispatch = editorStore.dispatch;
            const editorStoreState = editorStore.getState();

            const editorState = getEditorState(editorStoreState);
            const editorId = getEditorId(editorStoreState);

            const selection = editorState.getSelection();

            editorDispatch(editorSelectionSave({ editorId, selection }));
        };

        const state = getState();
        const currentlyEditingId = getCurrentlyEditingId(state);
        return dispatch(
            indentTask({
                elementId: currentlyEditingId,
                dispatchSaveCurrentSelection,
            }),
        );
    };

const mapStateToProps = (state) => {
    const currentlyEditingId = getCurrentlyEditingId(state);
    const elements = getElements(state);

    const elementGraph = elementGraphSelector(state);
    const previousSibling = getPreviousSiblingViaGraph({
        elements,
        elementGraph,
        elementId: currentlyEditingId,
        sortFn: sortByScoreProperty,
    });

    if (!previousSibling) return { disabled: true };

    const taskListId = getTaskListAncestorId(elements, currentlyEditingId);
    const currentDepth = getDepth(elements, taskListId, currentlyEditingId);

    const remainingDepth = MAX_TASK_DEPTH - currentDepth;

    if (remainingDepth < 1) return { disabled: true };

    return {};
};

const mapDispatchToProps = (dispatch) => ({
    onPointerDown: (event) => {
        event.preventDefault();
        dispatch(handleIndentClick());
    },
});

const IndentTaskTool = (props) => {
    const { tooltipEnabled = true, tooltipText, shortcut, iconName, disabled } = props;

    return (
        <TooltipSource
            enabled={tooltipEnabled}
            tooltipText={tooltipText}
            shortcut={shortcut}
            position={TooltipPositions.RIGHT}
            offset={8}
            delay={700}
            pollPosition
            triggerOnMouseEnter
        >
            <ToolbarTool {...props} name={null} className="RichTextTool" disabled={disabled}>
                <Icon {...props} name={iconName} />
            </ToolbarTool>
        </TooltipSource>
    );
};

IndentTaskTool.propTypes = {
    tooltipEnabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    shortcut: PropTypes.array,
    iconName: PropTypes.string,

    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndentTaskTool);
