import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GraphIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon graph"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M11.5 3a.5.5 0 0 0-.5.5V14h3V3.5a.5.5 0 0 0-.5-.5h-2Zm-9 2a.5.5 0 0 0-.5.5V14h3V5.5a.5.5 0 0 0-.5-.5h-2ZM7 7a.5.5 0 0 0-.5.5V14h3V7.5A.5.5 0 0 0 9 7H7Z"
        />
    </svg>
);
const Memo = memo(GraphIcon);
export default Memo;
