import { memoize } from 'lodash';
import { isOnline } from '../../user/userUtil';
import { getRootBoardId, getUserCurrentBoardId } from '../../../common/users/utils/userPropertyUtils';
import { MNUser } from '../../../common/users/userModelTypes';
import { UserAvatarInfo } from './userAvatarTypes';

export const getAvatarStyle = memoize((sizePx) => ({
    '--avatar-size': `${sizePx}px`,
}));

export const isUserActiveOnCurrentBoard = (user: MNUser | UserAvatarInfo, currentBoardId: string): boolean =>
    currentBoardId &&
    isOnline(user) &&
    getUserCurrentBoardId(user) === currentBoardId &&
    // Don't show the current user as active, the current user will be the only one with a root board ID
    !getRootBoardId(user);
