// Utils
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isAlias, isClone } from '../../../common/elements/utils/elementTypeUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { getElements } from '../selectors/elementSelector';
import { getRenderedAliasElement } from '../alias/aliasSelector';
import { getRenderedCloneElement } from '../clone/cloneUtils';

export const getSelectedElementIds = (state) => state.getIn(['app', 'selection', 'ids']);
export const getCurrentCellSelections = (state) => state.getIn(['app', 'selection', 'meta', 'currentCellSelections']);
export const getRangeAnchors = (state) => state.getIn(['app', 'selection', 'rangeAnchors']);
export const getParentIdRangeAnchor = (state, { id }) => state.getIn(['app', 'selection', 'rangeAnchors', id]);

export const getSelectedElementsCount = (state) => getSelectedElementIds(state).size;

export const getSelectedElements = createShallowSelector(
    getSelectedElementIds,
    getElements,
    (selectedElementIds, elements) =>
        selectedElementIds.map((id) => {
            const element = getElement(elements, id);

            if (!isAlias(element)) return element;

            const linkedElementId = getPhysicalId(element);
            const linkedElement = getElement(elements, linkedElementId);

            return getRenderedAliasElement(element, linkedElement);
        }),
);

export const getRenderedElements = (elementIds, elements) =>
    elementIds.map((id) => {
        const element = getElement(elements, id);

        if (!isAlias(element) && !isClone(element)) return element;

        const linkedElementId = getPhysicalId(element);
        const linkedElement = getElement(elements, linkedElementId);

        return isAlias(element)
            ? getRenderedAliasElement(element, linkedElement)
            : getRenderedCloneElement(element, linkedElement);
    });

/**
 * The same as getSelectedElements, but converts cloned elements into their original clones.
 */
export const getSelectedRenderedElements = createShallowSelector(
    getSelectedElementIds,
    getElements,
    getRenderedElements,
);
