// Utils
import { getFileType } from '../../../../common/elements/utils/elementPropertyUtils';
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformElectronMac } from '../../../platform/utils/platformDetailsUtils';

// Constants
import { FILE_TYPES_INFO } from '../../../../common/files/fileConstants';

export default (element) => {
    if (isPlatformElectronMac(platformSingleton)) return false;

    const fileType = getFileType(element);

    switch (fileType) {
        case FILE_TYPES_INFO.TEXT.type:
        case FILE_TYPES_INFO.PDF.type:
            return true;
        default:
            return false;
    }
};
