// Utils
import { clearFilter } from '../reducers/navigationActions';
import { getLocation } from '../app/routingSelector';
import { isLocationInbox } from '../../common/elements/utils/elementLocationUtils';
import { getElementFilterContextSelector } from '../element/elementFilter/elementFilterSelector';

// Constants
import {
    ELEMENTS_DESELECTED,
    ELEMENTS_DESELECT_ALL,
    ELEMENT_EDIT_COMPLETE,
    ELEMENT_EDIT_START,
} from '../../common/elements/selectionConstants';

import { DRAG_START, DRAG_END } from '../reducers/draggingConstants';
import { POPUP_CLOSE } from '../components/popupPanel/popupConstants';
import { HYBRID_ACTION_TYPES } from '../hybridApp/swiftApp/middleware/hybridMiddlewareConstants';
import { APP_QUERY_PARAMS } from '../../common/utils/urlConstants';
import { ELEMENT_FILTER_CONTEXTS } from '../element/elementFilter/elementFilterConstants';

const shouldClearFilterOnDragStart = (state, action) => {
    // clear filter on creation of a new card
    if (!action.ids) return true;

    const draggedElements = action.ids.map((id) => state.getIn(['elements', id]));
    return !draggedElements.some(isLocationInbox);
};

// we only want to clear the filter on deselect when not in the search context
// this prevents jumpy behavior when selecting elements in the search popup
const shouldClearFilterOnDeselect = (state, action) => {
    const filterContext = getElementFilterContextSelector(state);
    if (filterContext === ELEMENT_FILTER_CONTEXTS.SEARCH) return false;

    return true;
};

export default (store) => (next) => (action) => {
    if (action.remote) return next(action);

    const state = store.getState();
    const { search } = getLocation(state);
    if (!search) return next(action);

    switch (action.type) {
        case ELEMENT_EDIT_START:
        case ELEMENT_EDIT_COMPLETE:
        case HYBRID_ACTION_TYPES.ELEMENT_EDIT_START:
        case DRAG_END:
            // NOTE: Using "clearFilter" here instead of clearElementFilter due to timing issues with selection
            // and route changes.  If clearElementFilter is used the @@router/LOCATION_CHANGE is fired after the
            // selection occurs, which in turn clears the selection, so it results in no selection.
            store.dispatch(clearFilter());
            break;
        case ELEMENTS_DESELECTED:
        case ELEMENTS_DESELECT_ALL:
            if (shouldClearFilterOnDeselect(state)) {
                store.dispatch(clearFilter([APP_QUERY_PARAMS.FILTER_IDS]));
            }
            break;
        case POPUP_CLOSE: {
            if (getElementFilterContextSelector(state)) {
                store.dispatch(clearFilter([APP_QUERY_PARAMS.FILTER_CONTEXT]));
            }
            break;
        }
        case DRAG_START:
            if (shouldClearFilterOnDragStart(state, action)) {
                store.dispatch(clearFilter());
            }
            break;
        default:
            break;
    }

    return next(action);
};
