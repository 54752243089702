// Lib
import React from 'react';

import PropTypes from 'prop-types';

// Components
import BoardImagePickerContainer from './BoardImagePickerContainer';

// Styles
import './IconTabContentImagePicker.scss';

const IconTabContentImagePicker = (props) => (
    <div className="IconTabContentImagePicker">
        <BoardImagePickerContainer {...props} />
        <div className="instructions">Tip: 160 x 160px works best.</div>
    </div>
);

IconTabContentImagePicker.propTypes = {
    gridSize: PropTypes.number.isRequired,
    selectedElements: PropTypes.object.isRequired,
    dispatchBoardImage: PropTypes.func,
};

export default IconTabContentImagePicker;
