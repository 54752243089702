// Lib
import { createSelector } from 'reselect';
import * as Immutable from 'immutable';

// Utils
import { isEmpty, length } from '../../../../../../../../common/utils/immutableHelper';
import { createShallowSelector } from '../../../../../../../utils/milanoteReselect/milanoteReselect';
import {
    getGuestNavigationDeviceId,
    getUserNavigationLastViewTimestamp,
} from '../../../../../../../../common/navigation/userNavigationPropertyUtils';
import { getViewer, getViewerId } from './publishBoardViewersUtils';

// Selectors
import { getCurrentUserId } from '../../../../../../../user/currentUserSelector';
import { getCurrentBoardIdFromState } from '../../../../../../../reducers/currentBoardId/currentBoardIdSelector';

type PublishedBoardViewEntry = Immutable.Map<string, any>;
type PublishedBoardViewMap = Immutable.Map<string, PublishedBoardViewEntry>;

interface BoardViewEntry {
    id: string;
    viewer?: any;
    deviceId?: string;
    lastView: number;
    type: 'VIEWER' | 'GUEST_VIEWER';
}

const getCurrentBoardPublishLinkViewers = (state: any): PublishedBoardViewMap =>
    state.getIn(['local', 'publishedBoardViewers', 'boardViews', getCurrentBoardIdFromState(state)]);

export const currentBoardPublishLinkViewersSelector = createShallowSelector(
    getCurrentBoardPublishLinkViewers,
    getCurrentUserId,
    (boardViewers, currentUserId) => boardViewers?.filter((viewer) => getViewerId(viewer) !== currentUserId),
);

export const currentBoardPublishLinkViewerCountSelector = (state: any) =>
    length(currentBoardPublishLinkViewersSelector(state));

export const currentBoardPublishLinkGuestViewersSelector = (state: any): PublishedBoardViewMap =>
    state.getIn(['local', 'publishedBoardViewers', 'boardGuestViews', getCurrentBoardIdFromState(state)]);
export const currentBoardPublishLinkGuestViewerCountSelector = (state: any) =>
    length(currentBoardPublishLinkGuestViewersSelector(state));

const sortByLastView = (viewerA: BoardViewEntry, viewerB: BoardViewEntry) =>
    (getUserNavigationLastViewTimestamp(viewerB) || 0) - (getUserNavigationLastViewTimestamp(viewerA) || 0);

export const sortedCurrentBoardPublishLinkViewersSelector = createSelector(
    currentBoardPublishLinkViewersSelector,
    currentBoardPublishLinkGuestViewersSelector,
    (viewers, guestViewers) => {
        const viewerEntries: BoardViewEntry[] = [];

        if (!isEmpty(viewers)) {
            viewers.reduce((acc, viewer): BoardViewEntry[] => {
                // immutable / typescript insists that the acc _could_ be undefined here (incorrect, but whatever)
                const array = acc || [];

                array.push({
                    id: getViewerId(viewer),
                    viewer: getViewer(viewer),
                    lastView: getUserNavigationLastViewTimestamp(viewer),
                    type: 'VIEWER',
                });

                return array;
            }, viewerEntries);
        }

        if (!isEmpty(guestViewers)) {
            guestViewers.reduce((acc, viewer): BoardViewEntry[] => {
                const array = acc || [];

                array.push({
                    id: getGuestNavigationDeviceId(viewer),
                    deviceId: getGuestNavigationDeviceId(viewer),
                    lastView: getUserNavigationLastViewTimestamp(viewer),
                    type: 'GUEST_VIEWER',
                });

                return array;
            }, viewerEntries);
        }

        return viewerEntries.sort(sortByLastView);
    },
);
