// Util
import { createShallowSelector } from '../milanoteReselect/milanoteReselect';
import { isOnline } from '../../user/userUtil';
import { toIdArray } from '../../../common/utils/immutableHelper';

// Selectors
import { getUsers } from '../../user/usersSelector';
import { getCurrentUserId } from '../../user/currentUserSelector';

// Constants
import { USER_IDLE_TIMEOUT_MILLISECONDS } from '../../../common/users/userConstants';

export const getTrackedUserIds = createShallowSelector(getUsers, (trackedUserIds) => trackedUserIds.keySeq().toArray());

export const getHasConnectedUsers = createShallowSelector(
    getTrackedUserIds,
    (trackedUserIds) => trackedUserIds.length > 1,
);

const getCurrentlyViewedBoardIds = (users) =>
    users.reduce((boardIds, user) => {
        const usersCurrentBoardId = user.get('currentBoardId');
        if (!!usersCurrentBoardId && boardIds.indexOf(usersCurrentBoardId) === -1) {
            boardIds.push(usersCurrentBoardId);
        }
        return boardIds;
    }, []);

export const getRemoteCurrentlyViewedBoardIds = createShallowSelector(getUsers, getCurrentlyViewedBoardIds);

export const getOnlineUsers = createShallowSelector(getUsers, (users) => users.filter(isOnline));

export const getRemoteActiveCurrentlyViewedBoardIds = createShallowSelector(getOnlineUsers, getCurrentlyViewedBoardIds);

export const getIdleOnlineUsers = createShallowSelector(
    getOnlineUsers,
    getCurrentUserId,
    (onlineUsers, currentUserId) => {
        const currentTime = Date.now();
        // Find every user that might be stale because we haven't heard from them within the idle timeout
        const idleTimestamp = currentTime - USER_IDLE_TIMEOUT_MILLISECONDS;
        return onlineUsers.filter((user) => user.get('timestamp') < idleTimestamp && currentUserId !== user.get('_id'));
    },
);

export const getIdleOnlineUserIds = createShallowSelector(getIdleOnlineUsers, toIdArray);
