// Lib
import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, get } from 'lodash/fp';

// Utils
import { getDrawingViewBox } from '../../utils/drawingCanvasUtils';
import * as pointLib from '../../../../../../common/maths/geometry/point';
import { hasCommandModifier, hasShiftKey } from '../../../../../utils/keyboard/keyboardUtility';
import { isAttemptingMultiSelect } from '../../../../selection/selectionUtils';
import { getPointerEventDetail } from '../../drawingEditorEventUtils';
import { reorderPathsToBottom, reorderPathsToTop, shiftPaths } from '../../drawingEditorUtils';

// Components
import DrawingEditorCanvasSvg from '../DrawingEditorCanvasSvg';
import DrawingSelectionMarquee from './DrawingSelectionMarquee';
import {
    DrawingEditorSelectModeSvgHitAreaGroup,
    DrawingEditorSelectModeSvgStaticGroup,
    DrawingEditorSelectModeSvgTransformGroup,
} from './DrawingEditorSelectModeSvgGroups';

// Hooks
import useDrawingEditorSelectionMarqueePathFinder from './useDrawingEditorSelectionMarqueePathFinder';
import useDrawingEditorUpdateToolbarOnSelection from './useDrawingEditorUpdateToolbarOnSelection';
import useDrawingEditorSelectionMarqueeState from './useDrawingEditorSelectionMarqueeState';
import useDrawingEditorSelectedPathModifier from './useDrawingEditorSelectedPathModifier';
import useDrawingEditorDragAndDropState from './useDrawingEditorDragAndDropState';
import useDrawingEditorSelectionState from './useDrawingEditorSelectionState';
import useDocumentPointerEventHandler from '../useDocumentPointerEventHandler';
import useDocumentKeyEventHandler from '../useDocumentKeyEventHandler';

// Constants
import { KEY_CODES } from '../../../../../utils/keyboard/keyConstants';

// Styles
import './DrawingEditorCanvasSelectMode.scss';

const handleShiftPaths = ({ event, defaultOffset, selectedPathIdsMap, paths, setPaths, canvasScale = 1 }) => {
    event.stopPropagation();
    event.preventDefault();

    if (isEmpty(selectedPathIdsMap)) return;

    const scaledDefaultOffset = pointLib.scale(1 / canvasScale, defaultOffset);
    const offset = hasShiftKey(event) ? pointLib.scale(10, scaledDefaultOffset) : scaledDefaultOffset;

    const newPaths = shiftPaths(offset, paths, selectedPathIdsMap);
    return setPaths(newPaths);
};

const handleReorderPathsToTop = ({ event, selectedPathIdsMap, paths, setPaths }) => {
    event.stopPropagation();
    event.preventDefault();

    setPaths(reorderPathsToTop(paths, selectedPathIdsMap));
};

const handleReorderPathsToBottom = ({ event, selectedPathIdsMap, paths, setPaths }) => {
    event.stopPropagation();
    event.preventDefault();

    setPaths(reorderPathsToBottom(paths, selectedPathIdsMap));
};

const DrawingEditorCanvasSelectMode = (props) => {
    const {
        paths,
        setPaths,
        removePaths,

        canvasScale = 1,
        viewBoxX,
        viewBoxY,

        saveAndExitDrawingEditor,
        debugUpdateAllStrokes,
        debugDrawingLastUpdateTime,
        enableDrawingInkEffect,

        setStrokeSizeState,
        setStrokeColorState,
        setToolModeState,
        onPathPropertyChangeRef,
    } = props;

    const svgRef = useRef();
    const pointerEventStats = useRef({});

    const { selectedPathIdsMap, toggleSelectedPath, setSelectedPathIds, clearSelectedPaths } =
        useDrawingEditorSelectionState();

    const {
        isMarqueeSelectMode,
        isAppendMode,
        marqueeDimensions,
        initialSelectedPathIdsMap,
        startMarqueeSelectMode,
        dragMarqueeSelectionTo,
        endMarqueeSelectMode,
    } = useDrawingEditorSelectionMarqueeState();

    useDrawingEditorSelectionMarqueePathFinder({
        paths,
        isMarqueeSelectMode,
        isAppendMode,
        initialSelectedPathIdsMap,
        marqueeDimensions,
        setSelectedPathIds,
    });

    const { startDrag, onDrag, endDrag, transformGroupRef, isDragging, isDragMove, draggedPathIdsMap } =
        useDrawingEditorDragAndDropState({ paths, setPaths });

    useEffect(() => {
        if (isMarqueeSelectMode) return;

        const hasSelection = !isEmpty(selectedPathIdsMap);
        setToolModeState({ hasSelection });
    }, [selectedPathIdsMap, isMarqueeSelectMode]);

    useDrawingEditorSelectedPathModifier({
        paths,
        setPaths,
        canvasScale,
        selectedPathIdsMap,
        onPathPropertyChangeRef,
        enableDrawingInkEffect,
    });

    useDrawingEditorUpdateToolbarOnSelection({
        isMarqueeSelectMode,
        paths,
        selectedPathIdsMap,
        setStrokeSizeState,
        setStrokeColorState,
        canvasScale,
    });

    const onPointerDown = (event) => {
        if (event.buttons !== 1) return;

        event.preventDefault();

        const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY);

        const appendMode = isAttemptingMultiSelect(event);

        if (!appendMode) clearSelectedPaths();

        return startMarqueeSelectMode({ point, appendMode, selectedPathIdsMap });
    };

    const onDragPointerMove = (event) => {
        if (!isDragging) return;

        pointerEventStats.current.moveCount++;

        event.preventDefault();
        const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY);
        onDrag(point);
    };

    const onMarqueePointerMove = (event) => {
        if (!isMarqueeSelectMode) return;

        event.preventDefault();
        const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY);
        dragMarqueeSelectionTo(point);
    };

    const onPointerUp = (event) => {
        // This is used to ensure that paths aren't selected after a marquee select
        // (along with the pointer down in DrawingEditorSelectModeSvgHitAreaGroupDef)
        pointerEventStats.current.pointerDownPathId = null;

        event.preventDefault();

        if (isMarqueeSelectMode) return endMarqueeSelectMode();

        const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY);
        return endDrag(point);
    };

    useDocumentPointerEventHandler({
        onPointerMove: onMarqueePointerMove,
        onPointerUp,
    });

    const onKeyDown = useCallback(
        (event) => {
            switch (event.keyCode) {
                case KEY_CODES.A: {
                    if (!hasCommandModifier(event)) return;
                    const allPathIds = paths.map(get('id'));
                    setSelectedPathIds(allPathIds);
                    return;
                }
                case KEY_CODES.BACKSPACE:
                case KEY_CODES.DELETE: {
                    event.preventDefault();
                    event.stopPropagation();

                    const selectedIds = Object.keys(selectedPathIdsMap);
                    removePaths(selectedIds);
                    return setSelectedPathIds([]);
                }
                case KEY_CODES.UP_ARROW:
                    if (hasCommandModifier(event) && hasShiftKey(event)) {
                        return handleReorderPathsToTop({
                            event,
                            selectedPathIdsMap,
                            paths,
                            setPaths,
                        });
                    }

                    return handleShiftPaths({
                        event,
                        defaultOffset: { x: 0, y: -1 },
                        selectedPathIdsMap,
                        paths,
                        setPaths,
                        canvasScale,
                    });
                case KEY_CODES.DOWN_ARROW:
                    if (hasCommandModifier(event) && hasShiftKey(event)) {
                        return handleReorderPathsToBottom({
                            event,
                            selectedPathIdsMap,
                            paths,
                            setPaths,
                            canvasScale,
                        });
                    }

                    return handleShiftPaths({
                        event,
                        defaultOffset: { x: 0, y: 1 },
                        selectedPathIdsMap,
                        paths,
                        setPaths,
                        canvasScale,
                    });
                case KEY_CODES.LEFT_ARROW:
                    return handleShiftPaths({
                        event,
                        defaultOffset: { x: -1, y: 0 },
                        selectedPathIdsMap,
                        paths,
                        setPaths,
                        canvasScale,
                    });
                case KEY_CODES.RIGHT_ARROW:
                    return handleShiftPaths({
                        event,
                        defaultOffset: { x: 1, y: 0 },
                        selectedPathIdsMap,
                        paths,
                        setPaths,
                        canvasScale,
                    });
                case KEY_CODES.ESC: {
                    if (isEmpty(selectedPathIdsMap)) return saveAndExitDrawingEditor(paths);

                    event.preventDefault();
                    event.stopPropagation();
                    clearSelectedPaths();
                    return;
                }
                default:
                    return null;
            }
        },
        [paths, selectedPathIdsMap],
    );

    useDocumentKeyEventHandler({
        onKeyDown,
    });

    const viewBox = getDrawingViewBox(props);

    return (
        <DrawingEditorCanvasSvg
            ref={svgRef}
            viewBox={viewBox}
            className={classNames('DrawingEditorCanvasSelectMode', { 'drag-mode': isDragging })}
            onPointerDown={onPointerDown}
            onPointerMove={onDragPointerMove}
        >
            <DrawingEditorSelectModeSvgStaticGroup
                paths={paths}
                canvasScale={canvasScale}
                selectedPathIdsMap={selectedPathIdsMap}
                isDragMove={isDragMove}
                draggedPathIdsMap={draggedPathIdsMap}
                debugUpdateAllStrokes={debugUpdateAllStrokes}
                debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
            />
            <DrawingEditorSelectModeSvgHitAreaGroup
                svgRef={svgRef}
                canvasScale={canvasScale}
                viewBoxX={viewBoxX}
                viewBoxY={viewBoxY}
                pointerEventStats={pointerEventStats}
                paths={paths}
                toggleSelectedPath={toggleSelectedPath}
                selectedPathIdsMap={selectedPathIdsMap}
                setSelectedPathIds={setSelectedPathIds}
                startDrag={startDrag}
                debugUpdateAllStrokes={debugUpdateAllStrokes}
                debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
            />
            <DrawingEditorSelectModeSvgTransformGroup
                isDragMove={isDragMove}
                canvasScale={canvasScale}
                viewBoxX={viewBoxX}
                viewBoxY={viewBoxY}
                paths={paths}
                selectedPathIdsMap={selectedPathIdsMap}
                draggedPathIdsMap={draggedPathIdsMap}
                transformGroupRef={transformGroupRef}
                debugUpdateAllStrokes={debugUpdateAllStrokes}
                debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
            />
            <DrawingSelectionMarquee isMarqueeSelectMode={isMarqueeSelectMode} marqueeDimensions={marqueeDimensions} />
        </DrawingEditorCanvasSvg>
    );
};

DrawingEditorCanvasSelectMode.propTypes = {
    enableDrawingInkEffect: PropTypes.bool,

    paths: PropTypes.array,
    setPaths: PropTypes.func,
    setToolModeState: PropTypes.func,
    removePaths: PropTypes.func,

    canvasScale: PropTypes.number,
    viewBox: PropTypes.string,
    viewBoxX: PropTypes.number,
    viewBoxY: PropTypes.number,
    viewBoxWidth: PropTypes.number,
    viewBoxHeight: PropTypes.number,

    strokeColor: PropTypes.string,
    strokeSize: PropTypes.number,
    setStrokeSizeState: PropTypes.func,
    setStrokeColorState: PropTypes.func,

    closeAllPopups: PropTypes.func,
    saveAndExitDrawingEditor: PropTypes.func,

    debugUpdateAllStrokes: PropTypes.bool,
    debugDrawingLastUpdateTime: PropTypes.number,
    onPathPropertyChangeRef: PropTypes.object,
};

export default DrawingEditorCanvasSelectMode;
