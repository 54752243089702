// Lib
import { cloneDeep, identity, curry } from 'lodash/fp';

// Utils
import { safeReduce } from '../../utils/immutableHelper';
import { getLocationParentId } from './elementPropertyUtils';
import { getElement } from './elementTraversalUtils';

/**
 * Iterates over all the elements in a physical branch, starting from element ID and working up to the root.
 */
export const forEachBranchElement = curry((fn, elements, elementId) => {
    const visitedParents = {};
    let element = getElement(elements, elementId);

    while (element) {
        fn(element, elements);

        const parentId = getLocationParentId(element);
        element = getElement(elements, parentId);

        if (visitedParents[parentId]) {
            console.warn(`Infinite loop in forEachBranchElement for: ${elementId}`);
            break;
        }

        visitedParents[parentId] = true;
    }
});

/**
 * Creates an array entry for each element in a branch, starting from an element ID.
 * Returns the array.
 *
 * NOTE: This starts at the element and works its way up towards the branch root.
 */
export const mapOverBranchElements = curry((fn, elementsMap, elementId) => {
    const results = [];

    forEachBranchElement(
        (element, els) => {
            results.push(fn(element, els));
        },
        elementsMap,
        elementId,
    );

    return results;
});

/**
 * Retrieves each physical branch element (up to the root), given a map of elements and a starting element ID.
 */
export const getBranchElements = mapOverBranchElements(identity);

/**
 * Applies a reduction function over each element in a branch, starting from the element ID and iterating
 * through its ancestors.
 *
 * NOTE: This starts at the element and works its way up towards the branch root.
 */
export const reduceBranch = curry((fn, initial, elementsMap, elementId) =>
    getBranchElements(elementsMap, elementId).reduce(fn, cloneDeep(initial)),
);

/**
 * This iterates over each element in a branch, retrieves a specific property (or calculation) for each element,
 * then applies the "combiner" function to each of those property values.
 * E.g. The getter function might return the ACL map for each element.
 *      The combiner function iterates over each ACL entry, producing a reduced value.
 *
 * This can be thought of like a "double reduce" - it creates a reduction for each property value for each ancestor.
 *
 * NOTE: This starts at the element and works its way up towards the branch root.
 */
export const collapseBranchFieldProperties = curry((getterFn, combinerFn, elementsMap, elementId) =>
    reduceBranch(
        (map, element) => {
            // This must be a property that can be reduced.
            // E.g. The ACL is an object with aclIds mapping to permissions
            const currentElementFieldValue = getterFn(element, map, elementsMap);

            /* eslint-disable no-loop-func */
            return safeReduce(
                (accMap, userEntry, userKey) => combinerFn(accMap, element, userEntry, userKey),
                map,
                currentElementFieldValue,
            );
            /* eslint-enable no-loop-func */
        },
        {},
        elementsMap,
        elementId,
    ),
);
