// Lib
import { convertToRaw } from 'draft-js';
import { createSelector, createStructuredSelector } from 'reselect';

// Utils
import { createShallowSelector } from '../../utils/milanoteReselect/milanoteReselect';
import { getHasClones, getPhysicalId, getTextContent } from '../../../common/elements/utils/elementPropertyUtils';
import { getElement as getElementFromElements } from '../../../common/elements/utils/elementTraversalUtils';

// Selectors
import { getElement, getElements } from '../selectors/elementSelector';
import { getRenderedCloneElement } from './cloneUtils';
import {
    getEditorState,
    getOriginalEditorIdSelector,
} from '../../components/editor/store/reducers/editorStoreSelector';

const originalElementIdSelector = () => createSelector(getElement, getPhysicalId);

const originalElementSelector = () =>
    createShallowSelector(getElements, originalElementIdSelector(), getElementFromElements);

const cloneRenderedElement = () => createSelector(getElement, originalElementSelector(), getRenderedCloneElement);

export default () =>
    createStructuredSelector({
        originalElementId: originalElementIdSelector(),
        originalElement: originalElementSelector(),
        renderedElement: cloneRenderedElement(),
    });

export const isEditingCloneSelector = (state, ownProps) => {
    const { element, isClone, isEditing, originalEditorId } = ownProps;

    // If we're editing this element or it doesn't have clones, then just return the element's textContent
    if (isEditing || (!isClone && !getHasClones(element))) return false;

    // If the current original editor ID is the same as this element's original editor ID then
    // we are editing one of the clones
    return originalEditorId === getOriginalEditorIdSelector(state);
};

export const textContentSelector = (state, ownProps) => {
    const { element } = ownProps;

    const textContent = getTextContent(element);

    if (!isEditingCloneSelector(state, ownProps)) return textContent;

    const editorState = getEditorState(state);
    const currentContent = editorState?.getCurrentContent();
    const editorStateTextContent = currentContent && convertToRaw(currentContent);

    return editorStateTextContent || textContent;
};
