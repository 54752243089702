import base62 from 'base62';
import { startsWith } from 'lodash/fp';

import { ELEMENT_ID_LENGTH, TIMESTAMP_LENGTH, CLIENT_ID_LENGTH } from './uidConstants';
import { TIMES } from '../utils/timeUtil';

import { parseTimestamp } from './timestamp';

export const getTimestamp = (id) => parseTimestamp(id.slice(0, TIMESTAMP_LENGTH));
export const getClientId = (id) => id.slice(TIMESTAMP_LENGTH, TIMESTAMP_LENGTH + CLIENT_ID_LENGTH);
export const getClientTick = (id) => base62.decode(id.slice(TIMESTAMP_LENGTH + CLIENT_ID_LENGTH));

// There should never be any elements created before 29th Feb 2016
const MINIMUM_ELEMENT_TIMESTAMP = new Date('2016-02-29T00:00:00.000Z').getTime();

const startsWithUnderscore = startsWith('_');

export const isElementId = (id) => {
    if (!id) return false;
    if (startsWithUnderscore(id)) return false;

    // Some element IDs were accidentally created one character larger
    if (id.length !== ELEMENT_ID_LENGTH && id.length !== ELEMENT_ID_LENGTH + 1) return false;

    // Get the time portion of the element ID
    const timePart = id.substring(0, TIMESTAMP_LENGTH);

    const timestamp = base62.decode(timePart) * 1000;

    // The timestamp must be less than the current time (plus a days buffer)
    const maxTimestamp = Date.now() + TIMES.DAY;

    return timestamp > MINIMUM_ELEMENT_TIMESTAMP && timestamp < maxTimestamp;
};

export const checkIdValid = (id, clientId, lastTick) => {
    if (!isElementId(id)) return false;
    if (getClientId(id) !== clientId) return false;
    if (getClientTick(id) <= lastTick) return false;
    return true;
};
