import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeCurrencyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-currency"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#4C596E"
                fillRule="nonzero"
                d="M20.388 18.8c0-4.528-6.645-2.432-6.645-5.296 0-1.003.832-1.616 2.197-1.616.968 0 1.63.317 2.29.936.458.428.641.568 1.04.448l.264-.08c.512-.155.64-.47.377-.928-.562-.972-1.804-1.714-3.167-1.947l.105-1.437c.04-.544-.224-.832-.752-.832h-.16c-.528 0-.792.288-.752.832l.1 1.39c-1.966.204-3.302 1.48-3.302 3.234 0 4.464 6.613 2.576 6.613 5.296 0 1.1-.884 1.744-2.4 1.744a3.741 3.741 0 0 1-2.835-1.254c-.466-.51-.652-.634-1.065-.464l-.171.07c-.548.225-.688.498-.424.944.662 1.122 1.993 2 3.591 2.249l-.106 1.398c-.042.544.224.832.752.832h.16c.528 0 .788-.288.752-.832l-.09-1.35c2.196-.183 3.628-1.474 3.628-3.337Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeCurrencyIcon);
export default Memo;
