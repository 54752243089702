// Lib
import React, { useEffect } from 'react';
import { compose } from '../../../../node_module_clones/recompose';
import PropTypes from 'prop-types';
import { identity } from 'lodash';

// Drop Utils
import onlyShowIfNotPresentational from './onlyShowIfNotPresentational';
import elementLineEdgeDropTarget from '../../line/elementLineEdgeDropTarget';

// Style
import './LineDropTarget.scss';

const enhance = compose(
    // Prevent the drop target from being added on drag.
    onlyShowIfNotPresentational,

    // Line Drop Target
    elementLineEdgeDropTarget,
);

const LineDropTarget = (props) => {
    const { connectLineEdgeDropTarget = identity, isLineEdgeHovered, setLineHoverEffect } = props;

    useEffect(() => {
        setLineHoverEffect(isLineEdgeHovered);
    }, [isLineEdgeHovered]);

    return connectLineEdgeDropTarget(<div className="LineDropTarget" />);
};

LineDropTarget.propTypes = {
    isLineEdgeHovered: PropTypes.bool,
    setLineHoverEffect: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
};

export default enhance(LineDropTarget);
