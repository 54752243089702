// Utils
import { roundToStep } from '../roundToStep';

// Types
import { Matrix3D } from 'rematrix';
import { Point } from '../geometry/pointTypes';
import { PointVector4D } from './rectMatrixUtils';

/**
 * Utility functions to be used alongside the Rematrix node module.
 *
 * Rematrix represents 3D transformation matrices with an Array of 16 entries.
 * For our purposes it can be thought of as following:
 *
 * [   scaleX         0        0        0
 *       0          scaleY     0        0
 *       0            0        1        0
 *   translateX   translateY   0        1 ]
 *
 * NOTE: The transformation matrix offers more power than just this, but we only
 *   use scale and translation.
 */

export const getScaleX = (matrix: Matrix3D): number => {
    const scaleX = matrix?.[0];
    return scaleX || scaleX === 0 ? scaleX : 1;
};

export const getScaleY = (matrix: Matrix3D): number => {
    const scaleY = matrix?.[5];
    return scaleY || scaleY === 0 ? scaleY : 1;
};

/**
 * Assumes that both the X and Y scales are identical, so just uses the X scale.
 */
export const getScale = getScaleX;

/**
 * Directly sets the translation properties of the matrix.
 */
export const setScale = (matrix: Matrix3D, scalar: number): Matrix3D => {
    if (!matrix) return matrix;

    const newMatrix: Matrix3D = [...matrix];
    newMatrix[0] = scalar;
    newMatrix[5] = scalar;

    return newMatrix as Matrix3D;
};

export const getTranslationX = (matrix: Matrix3D): number => matrix?.[12] || 0;
export const getTranslationY = (matrix: Matrix3D): number => matrix?.[13] || 0;

/**
 * Gets the translation properties from a matrix as an { x, y } object.
 */
export const getTranslation = (matrix: Matrix3D): Point => ({
    x: getTranslationX(matrix),
    y: getTranslationY(matrix),
});

/**
 * Directly sets the translation properties of the matrix.
 */
export const setTranslation = (matrix: Matrix3D, translationPoint: Point): Matrix3D => {
    if (!matrix || !translationPoint) return matrix;

    const newMatrix: Matrix3D = [...matrix];

    if (translationPoint.x || translationPoint.x === 0) {
        newMatrix[12] = translationPoint.x;
    }

    if (translationPoint.y || translationPoint.y === 0) {
        newMatrix[13] = translationPoint.y;
    }

    return newMatrix as Matrix3D;
};

/**
 * Directly sets the translation properties of the matrix.
 */
export const roundTranslation = (matrix: Matrix3D, step = 1): Matrix3D => {
    if (!matrix) return matrix;

    const newMatrix: Matrix3D = [...matrix];

    newMatrix[12] = roundToStep(newMatrix[12], step);
    newMatrix[13] = roundToStep(newMatrix[13], step);

    return newMatrix as Matrix3D;
};

/**
 * Ensures that the input matrix's translation will not be larger than a translation limit.
 * This is useful when returning to 100% scale because we don't want any padding to show around the
 * canvas, thus the translation must be equal to or less than the canvas scroll.
 */
export const limitTranslation = (matrix: Matrix3D, translationLimit: Point): Matrix3D => {
    if (!matrix) return matrix;

    const newMatrix: Matrix3D = [...matrix];

    newMatrix[12] = Math.min(newMatrix[12], translationLimit.x);
    newMatrix[13] = Math.min(newMatrix[13], translationLimit.y);

    return newMatrix as Matrix3D;
};

// point • matrix
export const multiplyMatrixAndPoint = (matrix: Matrix3D, point: PointVector4D): PointVector4D => {
    if (!matrix || !point) return point;

    // Give a simple variable name to each part of the matrix, a column and row number
    const c0r0 = matrix[0];
    const c1r0 = matrix[1];
    const c2r0 = matrix[2];
    const c3r0 = matrix[3];
    const c0r1 = matrix[4];
    const c1r1 = matrix[5];
    const c2r1 = matrix[6];
    const c3r1 = matrix[7];
    const c0r2 = matrix[8];
    const c1r2 = matrix[9];
    const c2r2 = matrix[10];
    const c3r2 = matrix[11];
    const c0r3 = matrix[12];
    const c1r3 = matrix[13];
    const c2r3 = matrix[14];
    const c3r3 = matrix[15];

    const x = point[0];
    const y = point[1];
    const z = point[2];
    const w = point[3];

    // Multiply the point against each part of the 1st column, then add together
    const resultX = x * c0r0 + y * c0r1 + z * c0r2 + w * c0r3;

    // Multiply the point against each part of the 2nd column, then add together
    const resultY = x * c1r0 + y * c1r1 + z * c1r2 + w * c1r3;

    // Multiply the point against each part of the 3rd column, then add together
    const resultZ = x * c2r0 + y * c2r1 + z * c2r2 + w * c2r3;

    // Multiply the point against each part of the 4th column, then add together
    const resultW = x * c3r0 + y * c3r1 + z * c3r2 + w * c3r3;

    return [resultX, resultY, resultZ, resultW];
};

/**
 * Multiplies each of the points by the matrix.
 */
export const transformAllPoints = (matrix: Matrix3D, points: Array<PointVector4D> = []): Array<PointVector4D> =>
    points.map((point) => multiplyMatrixAndPoint(matrix, point));
