// Constants
import { CANVAS_SCALE_DISABLE, CANVAS_SCALE_ENABLE, CANVAS_SCALE_TOGGLE } from './canvasConstants';
import { USER_NAVIGATE } from '../../../common/users/userConstants';

export default (state = false, action) => {
    switch (action.type) {
        case CANVAS_SCALE_ENABLE:
            return true;
        case USER_NAVIGATE:
        case CANVAS_SCALE_DISABLE:
            return false;
        case CANVAS_SCALE_TOGGLE:
            return !state;
        default:
            return state;
    }
};
