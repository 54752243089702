// Utils
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';
import { getConnectedElements } from '../../connections/elementConnectionsUtils';
import { isEmpty } from '../../../../common/utils/immutableHelper';
import { isLine } from '../../../../common/elements/utils/elementTypeUtils';
import { getTrashMoveAction } from '../../../../common/elements/utils/elementTrashUtils';

// Selectors
import { getElements } from '../../selectors/elementsSelector';
import { getSelectedElementIds } from '../../selection/selectedElementsSelector';

// Actions
import { moveMultipleElements } from '../../actions/elementMoveActions';
import { updateSelectedElements } from '../../actions/elementActions';

// Analytics
import { sendAmplitudeEvent } from '../../../analytics/amplitudeService';
import { EVENT_TYPE_NAMES } from '../../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../common/analytics/statsConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../../common/elements/elementConstants';

export const toggleCollapseSelectedCommentThreads =
    (nowCollapsed, transactionId = getNewTransactionId()) =>
    (dispatch, getState) => {
        if (nowCollapsed) {
            sendAmplitudeEvent({
                eventType: EVENT_TYPE_NAMES.COLLAPSED_COMMENT_THREAD,
                userProperties: {
                    [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.COLLAPSED_COMMENT_THREAD]: true },
                },
            });

            const state = getState();
            const elements = getElements(state);

            // Delete connected lines of the selected elements
            const selectedElementIds = getSelectedElementIds(state);
            const connectedElements = getConnectedElements(elements.valueSeq(), selectedElementIds);

            if (!isEmpty(connectedElements)) {
                const moves = connectedElements.reduce((acc, connectedElement) => {
                    if (!isLine(connectedElement)) return null;

                    const deletionMove = getTrashMoveAction(connectedElement);
                    acc.push(deletionMove);

                    return acc;
                }, []);

                dispatch(
                    moveMultipleElements({
                        moves,
                        transactionId,
                        moveOperation: ELEMENT_MOVE_OPERATIONS.CONNECTION_TRASH,
                    }),
                );
            }
        }

        dispatch(
            updateSelectedElements({
                changes: {
                    collapsed: nowCollapsed,
                },
                transactionId,
            }),
        );
    };
