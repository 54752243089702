import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ExportWordIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={26}
        viewBox="0 0 20 26"
        className="Icon export-word"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CACACA" d="M0 0v26h20V6.999L13 0z" />
            <path fill="#FFF" d="M1 1h11v7h7v17H1z" />
            <path fill="#FFF" d="m13 1.417 5.581 5.582H13z" />
            <path
                fill="#2A579A"
                d="M5 12h1.963l.585 3.965c.052.442.108.886.169 1.332.06.447.117.895.169 1.346h.052c.087-.45.171-.9.253-1.346.083-.446.167-.89.254-1.332L9.355 12h1.625l.91 3.965c.087.433.171.873.253 1.32.083.446.167.899.254 1.358h.052c.06-.46.12-.91.175-1.352.057-.442.115-.884.176-1.326L13.385 12h1.833l-1.508 8.476h-2.405l-.832-3.848c-.07-.33-.132-.66-.189-.995a33.537 33.537 0 0 1-.149-.968h-.052l-.162.969c-.057.333-.12.665-.189.994l-.793 3.848H6.573L5 12Z"
            />
        </g>
    </svg>
);
const Memo = memo(ExportWordIcon);
export default Memo;
