// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getViewBox } from '../../utils/svgUtils';
import { getImageDrawingCropRect } from './imageDrawingUtils';
import { getHasDrawing } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import { SimpleDrawingSvg } from '../drawing/DrawingSvg';

// Styles
import './ImageDrawing.scss';

const ImageDrawing = (props) => {
    const { element, tempMediaSize } = props;

    const hasDrawing = getHasDrawing(element);

    if (!hasDrawing) return null;

    const drawingCropRect = getImageDrawingCropRect(element, tempMediaSize);
    const viewBox = getViewBox(drawingCropRect);

    return (
        <div className="ImageDrawing">
            <SimpleDrawingSvg {...props} viewBox={viewBox} />
        </div>
    );
};

ImageDrawing.propTypes = {
    element: PropTypes.object,
    tempMediaSize: PropTypes.object,
};

export default ImageDrawing;
