export const createRunningAverage = (windowSize: number) => {
    let sum = 0;
    let count = 0;
    const values: number[] = [];

    return {
        add: (value: number) => {
            sum += value;
            values.push(value);
            count++;

            if (count > windowSize) {
                sum -= values.shift() || 0;
                count--;
            }

            return sum / count;
        },
        size: () => count,
    };
};
