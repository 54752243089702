// Types
import { ReduxStore } from '../types/reduxTypes';
import { BatchActionObject } from '../../common/actions/actionTypes';

// Constants
import { BATCH_ACTION_IGNORE_NEXT, DiffUpdateStateAction } from '../../common/actions/batch/batchActions';
import { BATCH_ACTION_TYPE } from './reduxBulkingMiddleware';

// FIXME-DOCUMENT-SYNC: We dont need this middleware once clients are updated to use the new diff update action
export default (store: ReduxStore) =>
    (next: Function) =>
    (action: BatchActionObject): Function | void => {
        if (action.type !== BATCH_ACTION_TYPE) return next(action);

        const { payload } = action;

        const diffUpdateStateAction = payload.find(
            (action) => action.type === BATCH_ACTION_IGNORE_NEXT,
        ) as DiffUpdateStateAction;

        if (!diffUpdateStateAction) return next(action);

        const { ignoreActions } = diffUpdateStateAction;

        if (ignoreActions.length === 0) return next(action);

        const actuallyDispatchedActions = payload.filter(
            (batchAction) =>
                batchAction.type !== BATCH_ACTION_IGNORE_NEXT && !ignoreActions.includes(batchAction.type as string),
        );

        actuallyDispatchedActions.forEach((batchAction) => {
            store.dispatch(batchAction);
        });
    };
