// Lib
import React from 'react';
import classNames from 'classnames';
import * as Immutable from 'immutable';

// Components
import PopupTrigger from './PopupTrigger';
import Icon from '../icons/Icon';

// Types
import { ActionObject } from '../../../common/actions/actionTypes';

// Styles
import './PopupToolButton.scss';

interface PopupToolButtonProps {
    popupId: string;
    icon?: object;
    text?: object | string;
    onToggle?: () => void;
    className?: string;
    showArrow?: boolean;
    closeSection?: string;
    stayActivePredicate?: (state: Immutable.Map<string, unknown>, action: ActionObject) => boolean;
}

const PopupToolButton = React.forwardRef<HTMLButtonElement, PopupToolButtonProps>(function PopupToolButton(props, ref) {
    const { popupId, icon, text, className, showArrow, onToggle, closeSection, stayActivePredicate } = props;

    const textElement = text ? <span className="tool-text">{text}</span> : null;

    return (
        <PopupTrigger
            forwardedRef={ref}
            popupId={popupId}
            stayActivePredicate={stayActivePredicate}
            closeSection={closeSection}
            className={classNames('PopupToolButton highlight', className)}
            onToggle={onToggle}
        >
            {() => (
                <div className="button-content" id={`popup-${popupId}-button`}>
                    {icon}
                    {textElement}
                    {showArrow && (
                        <span className="arrow">
                            <Icon name="menu-down-arrow" />
                        </span>
                    )}
                </div>
            )}
        </PopupTrigger>
    );
});

export default PopupToolButton;
