// Lib
import React, { ReactElement } from 'react';

// Utils
import { stopDefaultAndPropagation, stopPropagationOnly } from '../../../utils/domUtil';

// Components
import LinkButton from '../../buttons/LinkButton';
import DefaultErrorFurtherDetails from './DefaultErrorFurtherDetails';

// Styles
import './GrammarlyErrorRenderer.scss';

interface GrammarlyErrorRendererProps {
    error: Error;
    dispatchRefreshPageAndClearLocalCache: (e: Event) => void;
    furtherDetails: string;
    dispatchToggleIntercom: () => void;
}

const GrammarlyErrorRenderer = (props: GrammarlyErrorRendererProps): ReactElement => {
    const { dispatchRefreshPageAndClearLocalCache } = props;

    return (
        <div
            className="ErrorRenderer ElementErrorRenderer GrammarlyErrorRenderer"
            onClick={stopPropagationOnly}
            onDoubleClick={stopDefaultAndPropagation}
        >
            <div className="error-message">
                <h3>A Grammarly error occurred within this card</h3>
                <div className="refresh-message">
                    <p>Grammarly is known to cause issues within Milanote.</p>
                    <p>
                        If you're using the Grammarly browser extension, please try disabling it and{' '}
                        <LinkButton onClick={dispatchRefreshPageAndClearLocalCache}>refreshing the page</LinkButton>.
                    </p>
                </div>
            </div>

            <DefaultErrorFurtherDetails {...props} />
        </div>
    );
};

export default GrammarlyErrorRenderer;
