import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniColumnSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-column-selected"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M4 2h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1z" />
            <path fill="#FFF" d="M4 3h12v14H4z" />
            <path fill="#D1D3D6" d="M5 6h10v4.5H5V6zm0 5.5h10V16H5v-4.5z" />
            <path fill="#767C86" d="M6.5 4h7a.5.5 0 1 1 0 1h-7a.5.5 0 0 1 0-1z" />
            <path
                fill="#323B4A"
                fillRule="nonzero"
                d="M16 1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12zm0 2H4v14h12V3z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniColumnSelectedIcon);
export default Memo;
