import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon clock"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm0 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 2.25a.75.75 0 0 1 .743.648l.007.102v3.237l1.836 2.294a.752.752 0 0 1-.117 1.055.752.752 0 0 1-.98-.036l-.075-.081-2.164-2.706V8.5a.75.75 0 0 1 .75-.75z"
        />
    </svg>
);
const Memo = memo(ClockIcon);
export default Memo;
