// Utils
import { isCommentThread, isLine } from '../../common/elements/utils/elementTypeUtils';
import { getElementId, getIsCollapsed } from '../../common/elements/utils/elementPropertyUtils';
import { inboxDropResultFn } from '../element/dnd/dropResultFn';

// Constants
import { ELEMENT_DND_TYPE } from '../../common/elements/elementConstants';

export default (props, monitor, domNode) => {
    const dropResult = inboxDropResultFn(props, monitor, domNode);

    const itemType = monitor.getItemType();

    if (itemType !== ELEMENT_DND_TYPE) return { ...dropResult };

    // Can't drop lines onto the inbox
    const { draggedElements } = monitor.getItem();
    const acceptedElementIds = draggedElements
        .filter((el) => !isLine(el) && !(isCommentThread(el) && getIsCollapsed(el)))
        .map((el) => getElementId(el));

    return {
        ...dropResult,
        acceptedElementIds,
    };
};
