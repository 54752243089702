import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ImagePopupUploadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={21}
        viewBox="0 0 24 21"
        className="Icon image-popup-upload"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#929292"
                d="M16 18h3.256C21.876 18 24 15.79 24 13.065c0-2.538-1.841-4.629-4.21-4.905.016-.202.024-.406.024-.612C19.814 3.38 16.565 0 12.558 0 8.93 0 5.923 2.771 5.388 6.39 2.394 6.498 0 9.055 0 12.195 0 15.4 2.499 18 5.581 18H9v-.9H5.581C3.004 17.1.9 14.911.9 12.194.9 9.54 2.91 7.379 5.42 7.29l.748-.027.11-.74C6.753 3.298 9.42.9 12.557.9c3.502 0 6.356 2.969 6.356 6.648 0 .182-.007.363-.02.543l-.067.863.86.1c1.927.225 3.413 1.943 3.413 4.01 0 2.237-1.73 4.036-3.844 4.036H16v.9Z"
            />
            <path
                fill="#979797"
                d="M13 11.207V20.5h-1v-9.293l-2.146 2.147-.354.353L8.793 13l.353-.354 3-3 .354-.353.177.177.177.176 3 3 .353.354-.707.707-.354-.353L13 11.207Z"
            />
        </g>
    </svg>
);
const Memo = memo(ImagePopupUploadIcon);
export default Memo;
