// Utils
import requestSecondAnimationFrame from '../../../common/utils/lib/requestSecondAnimationFrame';

// Analytics
import * as analyticsTimingService from './analyticsTimingService';
import { NewRelicPageActions } from '../newRelicUtils';

// Constants
import { ELEMENT_EDIT_START } from '../../../common/elements/selectionConstants';

// Types
import { AnyAction } from 'redux';

const handleElementEditStart = (action: AnyAction) => {
    if (!analyticsTimingService.doesOperationExist(NewRelicPageActions.ELEMENT_CREATION)) return;

    requestSecondAnimationFrame(() => {
        analyticsTimingService.endOperation(NewRelicPageActions.ELEMENT_CREATION);
    });
};

/**
 * If an action is potentially going to trigger an analytics timing event, this will handle it.
 */
export default (action: AnyAction) => {
    if (action.remote) return;

    switch (action.type) {
        case ELEMENT_EDIT_START:
            return handleElementEditStart(action);
    }
};
