// Utils
import { propIn } from '../utils/immutableHelper';
import { sortByScoreProperty } from '../elements/utils/elementSortUtils';
import { getDepth, getElement, searchPhysicalUp } from '../elements/utils/elementTraversalUtils';
import { depthFirstTraversal, getIndexFromDepthFirstTraversal } from '../dataStructures/graphUtils';
import { isTaskLike, isTaskList } from '../elements/utils/elementTypeUtils';
import { buildOrderedSubGraph } from '../elements/utils/elementGraphUtils';

export const getTaskListAncestorId = searchPhysicalUp(isTaskList);

export const getTaskDepth = (elements, elementId) => {
    const taskListId = getTaskListAncestorId(elements, elementId);
    return taskListId ? getDepth(elements, taskListId, elementId) : -1;
};

export const getAdjacentTask =
    (forward) =>
    ({ elements, elementGraph, elementId }) => {
        const taskListId = getTaskListAncestorId(elements, elementId);

        // Do depth first search
        const taskListGraph = buildOrderedSubGraph({
            elements,
            elementGraph,
            startElementId: taskListId,
            elementSortFn: sortByScoreProperty,
        });
        const dfsResult = depthFirstTraversal(taskListGraph, taskListId);
        // Find current element's index in depth first search
        const currentlyEditingElementIndex = getIndexFromDepthFirstTraversal(dfsResult, elementId);

        let newIndex = null;
        if (!currentlyEditingElementIndex) {
            // If we're currently at the tree root then choose the first child when going forward or the last
            // child when going backwards.
            newIndex = forward ? 1 : dfsResult.length - 1;
        } else {
            // Find element next to this element
            newIndex = currentlyEditingElementIndex + (forward ? 1 : -1);
        }

        const nowEditingTaskId = propIn([newIndex, 'id'], dfsResult);
        const nowEditingTaskElement = getElement(elements, nowEditingTaskId);

        if (!nowEditingTaskElement) return null;

        // Comments can be children of tasks, so if we encounter one we need to skip it
        if (!isTaskLike(nowEditingTaskElement)) {
            return getAdjacentTask(forward)({ elements, elementGraph, elementId: nowEditingTaskId });
        }

        return nowEditingTaskElement;
    };

export const getPreviousTask = getAdjacentTask(false);
export const getNextTask = getAdjacentTask(true);
