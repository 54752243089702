// Lib
import { EditorState } from 'draft-js';

// Utils
import replaceTopOfEditorStateUndoStack from '../../../customRichUtils/editorState/replaceTopOfEditorStateUndoStack';
import { getEditorStateOnCharWithoutMarkdownReplacement } from './getEditorStateWithoutMarkdownReplacement';

/**
 * Adds the editor state that would have been created by the given character (if the markdown plugin didn't exist)
 * to the undo stack.
 *
 * This allows the user to undo the markdown change.
 */
export default (previousEditorState: EditorState, newEditorState: EditorState, char: string): EditorState => {
    if (previousEditorState === newEditorState) {
        return previousEditorState;
    }

    const newUndoEditorState = getEditorStateOnCharWithoutMarkdownReplacement(
        previousEditorState,
        newEditorState,
        char,
    );

    return replaceTopOfEditorStateUndoStack(newEditorState, newUndoEditorState.getCurrentContent());
};
