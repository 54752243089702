export type DiffUpdateStateAction = {
    type: 'BATCH_ACTION_IGNORE_NEXT';
    ignoreActions: string[];
};

export const BATCH_ACTION_IGNORE_NEXT = 'BATCH_ACTION_IGNORE_NEXT';

export const createBatchActionMetadata = (ignoreActions: string[]): DiffUpdateStateAction => ({
    type: BATCH_ACTION_IGNORE_NEXT,
    ignoreActions,
});
