import base62 from 'base62';
import padLeft from './padLeft';
import { TICK_MAX } from './uidConstants';
import { createTimestamp } from './timestamp';

export default (clientId, clientIdTick, timestamp = new Date().getTime()) => {
    const time = createTimestamp(timestamp);
    const tick = base62.encode(clientIdTick % TICK_MAX);
    return time + clientId + padLeft(tick, '00');
};
