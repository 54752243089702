// Lib
import { negate, constant } from 'lodash/fp';
import memoizeOne from 'memoize-one';

// Utils
import { prop } from '../../../../../common/utils/immutableHelper';
import { sortByString } from '../../../../../common/utils/sortUtil';
import { getEmail, getDisplayName, getGivenName, getFamilyName } from '../../../../../common/users/userHelper';
import { convertMentionToUser } from './mentionUtils';
import { getShareCount } from '../../../../../common/users/utils/userPropertyUtils';

const rankMentions = (searchValue) => {
    const value = searchValue.toLowerCase();

    return (mention) => {
        let score = 0;

        // Converting a mention to the user object to use the existing property accessor methods
        const user = convertMentionToUser(mention);

        const displayName = (getDisplayName(user) || '').toLowerCase();
        const givenName = (getGivenName(user) || '').toLowerCase();
        const familyName = (getFamilyName(user) || '').toLowerCase();
        const email = (getEmail(user) || '').toLowerCase();

        const userIsDisabled = prop('disabled', user);

        const sharingCurrentBoard = prop('sharingCurrentBoard', user);

        if (givenName.indexOf(value) === 0) {
            score += 5;
        }

        if (displayName.indexOf(value) === 0) {
            score += 2;
        }

        if (familyName.indexOf(value) === 0) {
            score += 5;
        }

        if (email.indexOf(value) === 0) {
            score += 1;
        }

        if (score > 0 && !userIsDisabled) {
            score += 20;
        }

        if (sharingCurrentBoard) {
            score += 10;
        }

        return {
            ...mention,
            score,
        };
    };
};

const userHasScore = (user) => !!user.score;

const scoreComparator = (userA, userB) => userB.score - userA.score;

const getName = prop('name');
const getId = prop('id');
const getIsDisabled = prop('disabled');
const getSharingCurrentBoard = prop('sharingCurrentBoard');

const sortByName = (userA, userB) => {
    const userAIsDisabled = getIsDisabled(userA);
    const userBIsDisabled = getIsDisabled(userB);

    if (userAIsDisabled && !userBIsDisabled) return 1;
    if (userBIsDisabled && !userAIsDisabled) return -1;

    return sortByString(getName, constant(0))(userA, userB);
};

const sortBySharingStatusAndShareCountThenName = (userA, userB) => {
    if (getSharingCurrentBoard(userA) && !getSharingCurrentBoard(userB)) return -1;

    if (getSharingCurrentBoard(userB) && !getSharingCurrentBoard(userA)) return 1;

    const shareCountA = getShareCount(userA);
    const shareCountB = getShareCount(userB);

    if (shareCountB - shareCountA === 0) return sortByName(userA, userB);

    return shareCountB - shareCountA;
};

const sortUsersByAssignedAndSharingStatusThenName = (currentlyAssignedUserId) => (userA, userB) => {
    if (currentlyAssignedUserId) {
        if (currentlyAssignedUserId === getId(userA)) return -1;
        if (currentlyAssignedUserId === getId(userB)) return 1;
    }

    return sortBySharingStatusAndShareCountThenName(userA, userB);
};

export default (searchValue, possibleSuggestions) => {
    const filteredSuggestions = searchValue
        ? possibleSuggestions.map(rankMentions(searchValue)).filter(userHasScore).sort(scoreComparator)
        : possibleSuggestions.sort(sortBySharingStatusAndShareCountThenName);

    const length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
    return filteredSuggestions.slice(0, length);
};

const getEnabledSuggestions = memoizeOne((possibleSuggestions) => possibleSuggestions.filter(negate(getIsDisabled)));
const getDisabledSuggestions = memoizeOne((possibleSuggestions) => possibleSuggestions.filter(getIsDisabled));

export const filterAssignmentSuggestions = (searchValue, currentlyAssignedUserId, possibleSuggestions) => {
    const enabledSuggestions = getEnabledSuggestions(possibleSuggestions);
    const disabledSuggestions = getDisabledSuggestions(possibleSuggestions);

    const filteredEnabledSuggestions = searchValue
        ? enabledSuggestions.map(rankMentions(searchValue)).filter(userHasScore).sort(scoreComparator)
        : [...enabledSuggestions].sort(sortUsersByAssignedAndSharingStatusThenName(currentlyAssignedUserId));

    if (filteredEnabledSuggestions.length >= 5) return filteredEnabledSuggestions;

    // If there's less than 5, add on some disabled suggestions to the end
    const filteredDisabledSuggestions = searchValue
        ? disabledSuggestions.map(rankMentions(searchValue)).filter(userHasScore).sort(scoreComparator)
        : [...disabledSuggestions].sort(sortByName);

    if (!filteredDisabledSuggestions.length) return filteredEnabledSuggestions;

    return [
        ...filteredEnabledSuggestions,
        ...filteredDisabledSuggestions.slice(5 - filteredDisabledSuggestions.length),
    ];
};
