// Utils
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformIframe } from '../../platform/utils/platformDetailsUtils';

// Types
import { Rect, RectSize } from '../../../common/maths/geometry/rect/rectTypes';
import { Point } from '../../../common/maths/geometry/pointTypes';

export const EMPTY_CANVAS_SIZE = { width: 0, height: 0 };

const PADDING_IFRAME = { x: 5, y: 5 };
const PADDING_ZOOMED_OUT = { x: 5, y: 5 };
export const CANVAS_ELEMENT_PADDING_STANDARD = { x: 50, y: 40 };

/**
 * Determines the canvas padding to use at the bottom right of elements depending on the context that the board
 * is being displayed in.
 */
export const getCanvasDocumentBottomRightPaddingGridUnits = (zoomScale: number): Point => {
    if (isPlatformIframe(platformSingleton)) return PADDING_IFRAME;
    if (zoomScale < 1) return PADDING_ZOOMED_OUT;
    return CANVAS_ELEMENT_PADDING_STANDARD;
};

/**
 * Determines the next canvas size by inspecting all element measurements and:
 * - Adding padding around them if the current scale is >= 100%
 * - Adding padding around them if it's bigger than the previous canvas size
 * - Using the previous canvas size otherwise
 *
 * TODO-ZOOM - Determine if this really should be a calculated value and also what should
 *  happen when returning to 100%
 */
export const calculateNextCanvasSize = (
    prevCanvasSize: RectSize,
    canvasElementsBoundingRect: Rect,
    gridSize: number,
    canvasElementIds: Array<string>,
    zoomScale: number,
): RectSize => {
    // Use the maximums of the canvas elements bounding rect.
    // NOTE: this is *not* the width/height of the bounding rect as that might not start at 0
    const newCanvasSize = {
        width: canvasElementsBoundingRect.right || 0,
        height: canvasElementsBoundingRect.bottom || 0,
    };

    const canvasPadding = getCanvasDocumentBottomRightPaddingGridUnits(zoomScale);

    const nextWidth =
        zoomScale >= 1 || newCanvasSize.width > prevCanvasSize.width
            ? Math.round(Math.max(prevCanvasSize.width, newCanvasSize.width + canvasPadding.x * gridSize))
            : prevCanvasSize.width;

    const nextHeight =
        zoomScale >= 1 || newCanvasSize.height > prevCanvasSize.height
            ? Math.round(Math.max(prevCanvasSize.height, newCanvasSize.height + canvasPadding.y * gridSize))
            : prevCanvasSize.height;

    return {
        width: nextWidth,
        height: nextHeight,
    };
};

/**
 * Ensures the canvas document will not be smaller than the viewport.
 */
export const getMinimumCanvasDocumentSize = (canvasDocumentSize: RectSize, canvasViewportSize: RectSize): RectSize => {
    if (!canvasDocumentSize || !canvasViewportSize) return canvasDocumentSize;

    if (canvasDocumentSize.width < canvasViewportSize.width || canvasDocumentSize.height < canvasViewportSize.height) {
        return {
            width: Math.max(canvasDocumentSize.width, canvasViewportSize.width),
            height: Math.max(canvasDocumentSize.height, canvasViewportSize.height),
        };
    }

    return canvasDocumentSize;
};
