// Lib
import linkifyIt, { Match } from 'linkify-it';
// @ts-ignore Couldn't figure out how to .d.ts type a module that exports an array...
import tlds from 'tlds';

const linkify = linkifyIt();
linkify.tlds(tlds);

/**
 * Gets Linkify matches from a text string.
 */
export const getLinkifyMatchesFromText = (text: string): Match[] => {
    const matches = linkify.match(text);

    if (!matches) return [];

    return matches;
};

/**
 * Gets URLs from a text string.
 */
const getLinksFromText = (text: string): string[] => {
    const matches = getLinkifyMatchesFromText(text);
    return matches.map((match) => match.url);
};

export const isOnlyLinkText = (text: string): boolean => {
    const links = getLinksFromText(text);
    return links.length === 1 && links[0] === text;
};

export default getLinksFromText;
