// Lib
import { LOCATION_CHANGE } from 'react-router-redux';

// Analytics
import { intercomTrackEvent, intercomUpdate } from './intercomService';

import { contentTotalCountSelector, contentTotalLimitSelector } from '../user/elementCount/elementCountSelector';
import { isMoveToOrFromTrash } from '../element/actions/elementMoveActionUtil';
import { getTimestamp, TIMES } from '../../common/utils/timeUtil';
import { getPlatformDetailsSelector } from '../platform/platformSelector';
import { isPlatformElectronMac } from '../platform/utils/platformDetailsUtils';

// Constants
import { ELEMENT_CREATE, ELEMENT_MOVE_MULTI, ELEMENT_CREATION_SOURCES } from '../../common/elements/elementConstants';
import { USER_NAVIGATE } from '../../common/users/userConstants';
import { ELEMENT_SEARCH_SUBMIT } from '../components/search/searchPopupConstants';

const trackNavigate = (state, action) => {
    const currentUserId = state.getIn(['app', 'currentUser', '_id']);
    const currentBoardId = state.getIn(['users', currentUserId, 'currentBoardId']);

    const sharedBoardUsers = state
        .get('users')
        .filter((user) => user.get('currentBoardId') === currentBoardId || user.get('_id') === currentUserId);

    if (sharedBoardUsers.size >= 2) {
        intercomTrackEvent('live-shared-board', { users_count: sharedBoardUsers.size });
    }
};

const updateIntercom = (state, action) => {
    let intercomData = {};

    const platformDetails = getPlatformDetailsSelector(state);
    if (action.type === LOCATION_CHANGE && isPlatformElectronMac(platformDetails)) {
        intercomData['last-seen-on-electron'] = Math.floor(getTimestamp() / TIMES.SECOND);
    }

    const billableContent = contentTotalCountSelector(state);
    if (!billableContent) return intercomUpdate(intercomData);

    const contentLimit = contentTotalLimitSelector(state);
    const contentRemaining = contentLimit - billableContent;
    intercomData = { billable_content: billableContent, billable_content_remaining: contentRemaining, ...intercomData };
    intercomUpdate(intercomData);
};

const trackElementCreate = (state, action) => {
    if (action.remote) return;
    intercomTrackEvent(`created-${action.elementType.toLowerCase()}`);

    if (action.creationSource === ELEMENT_CREATION_SOURCES.QUICK_LINE) {
        intercomTrackEvent('used-quick-line');
    }

    if (action.creationSource === ELEMENT_CREATION_SOURCES.IMAGE_SEARCH) {
        intercomTrackEvent('used-image-search');
    }

    if (action.creationSource === ELEMENT_CREATION_SOURCES.DOUBLE_CLICK) {
        intercomTrackEvent('created-card-doubleclick');
    }

    // request updated auto messages from intercom
    updateIntercom(state, action);
};

const trackMultiMoveToTrash = (state, action) => {
    if (action.moves.some(isMoveToOrFromTrash)) updateIntercom(state, action);
};

const actionTypeMap = {
    [USER_NAVIGATE]: trackNavigate,
    [LOCATION_CHANGE]: updateIntercom,
    [ELEMENT_CREATE]: trackElementCreate,
    [ELEMENT_MOVE_MULTI]: trackMultiMoveToTrash,
    [ELEMENT_SEARCH_SUBMIT]: () => intercomTrackEvent('used-search'),
};

export default (store) => (next) => (action) => {
    const trackActionType = actionTypeMap[action.type];
    if (trackActionType) {
        trackActionType(store.getState(), action);
    }
};
