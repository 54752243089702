export default (event, { getEditorState, getProps }) => {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    const startOffset = selection.getStartOffset();

    // Only handle left / up if already at the start of the task
    if (startOffset !== 0) return;

    event.stopPropagation();
    event.preventDefault();

    const { goToPreviousTask } = getProps();
    goToPreviousTask && goToPreviousTask();
};
