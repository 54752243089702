// Lib
import { max, min, round, isString, sum, ceil } from 'lodash/fp';

// Utils
import { gridPointsToPixels } from '../../utils/grid/gridUtils';
import { asObject } from '../../../common/utils/immutableHelper';
import { getEditorJsonContentLines } from '../../../common/tiptap/utils/jsonContentUtils/getEditorJsonContentLines';

// Constants
import { GRID } from '../../utils/grid/gridConstants';

const CHARACTERS_PER_LINE = 70;
const LINE_HEIGHT_GU = 2;

const IDEAL_HEIGHT_DIFFERENCE_THRESHOLD = 50;

const MODAL_MARGIN_GU = 20;
const MODAL_MIN_WIDTH = 350;
const MODAL_MIN_HEIGHT = 500;

// NOTE: This includes the margin between the toolbar and the modal
const DEFAULT_TOOLBAR_SIZE = 80;

const getCaptionPadding = (gridSize) => {
    switch (gridSize) {
        case GRID.MEDIUM.size:
            return 35;
        case GRID.SMALL.size:
            return 31;
        default:
            return 39;
    }
};

const getProjectedCaptionBlockHeight = (gridSize) => (captionBlock) =>
    ceil(captionBlock.length / CHARACTERS_PER_LINE) * LINE_HEIGHT_GU * gridSize;

export const getProjectedCaptionHeight = (caption, gridSize) => {
    if (!caption) return 0;

    if (isString(caption)) {
        return sum(caption.split('\n').map(getProjectedCaptionBlockHeight(gridSize))) + getCaptionPadding(gridSize);
    }

    const blocks = getEditorJsonContentLines(asObject(caption));

    return sum(blocks.map(getProjectedCaptionBlockHeight(gridSize))) + getCaptionPadding(gridSize);
};

export const getElementModalSize = ({
    windowWidth,
    windowHeight,
    imageWidth,
    imageHeight,
    idealHeight,
    caption,
    footerSize = DEFAULT_TOOLBAR_SIZE,
    modalMarginGU = MODAL_MARGIN_GU,
    modalMinWidth = MODAL_MIN_WIDTH,
    modalMinHeight = MODAL_MIN_HEIGHT,
    gridSize = GRID.LARGE.size,
}) => {
    const imageWidthRatio = imageHeight / imageWidth;

    const projectedCaptionHeight = getProjectedCaptionHeight(caption, gridSize);

    const modalMargin = gridPointsToPixels(modalMarginGU, gridSize);
    const maxWidth = windowWidth - modalMargin;
    const maxHeight = windowHeight - modalMargin - footerSize - projectedCaptionHeight;

    const projectedImageWidth = maxHeight / imageWidthRatio;

    let width = round(max([modalMinWidth, min([projectedImageWidth, imageWidth, maxWidth])]));

    let height = round(max([modalMinHeight, min([width * imageWidthRatio, maxHeight])]));

    // If the calculated height is very close to the ideal height, then just use
    // the ideal height (and recalculate the width to match)
    if (idealHeight && Math.abs(height + projectedCaptionHeight - idealHeight) < IDEAL_HEIGHT_DIFFERENCE_THRESHOLD) {
        height = idealHeight - projectedCaptionHeight;
        width = round(height / imageWidthRatio);
    }

    return {
        modalWidth: width,
        modalHeight: height,
        // Initially the height may be 0 (due to the window dimensions not being known yet)
        // so wait until they're ready before determining the ideal height
        idealModalHeight: height ? height + projectedCaptionHeight : null,
    };
};
