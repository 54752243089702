// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FullScreenErrorModal from '../../components/modal/FullScreenErrorModal';
import ModalActionsList from '../../components/modal/ModalActionsList';

const PlatformUnsupportedFullScreenWarning = (props) => {
    const actions = [
        { text: 'Install Google Chrome instead...', href: 'https://www.google.com.au/chrome/' },
        { text: 'Continue anyway', func: props.acknowledgeUnsupportedBrowser },
    ];

    return (
        <FullScreenErrorModal>
            <h2>Sorry, your browser isn’t supported yet</h2>
            <p className="explanation">
                Your browser is missing some features required to run Milanote.
                <br />
                We recommend upgrading your browser or installing a modern browser like Google Chrome.
            </p>
            <ModalActionsList actions={actions} />
        </FullScreenErrorModal>
    );
};

PlatformUnsupportedFullScreenWarning.propTypes = {
    acknowledgeUnsupportedBrowser: PropTypes.func.isRequired,
};

export default PlatformUnsupportedFullScreenWarning;
