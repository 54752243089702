// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { prop, propIn } from '../../../common/utils/immutableHelper';
import getPaddingForAspectRatio from '../../element/resizing/utils/getPaddingForAspectRatio';

// Selectors
import { getDataUri } from '../../element/attachments/attachmentsSelector';

// Components
import ResponsiveProgressiveImage from './ResponsiveProgressiveImage';

// Styles
import './OptimisticImage.scss';

const AttachmentImage = (props) => {
    const { forcedSize, mediaDetails, attachmentDetails, widthPx, cropToGrid, cropInset, gridSize } = props;

    const uploadedFileData = getDataUri(attachmentDetails);

    if (!uploadedFileData) return null;

    const forcedAspectRatio = cropToGrid || !!forcedSize || !!mediaDetails;

    const attachmentSize = forcedSize ||
        (mediaDetails && { width: prop('width', mediaDetails), height: prop('height', mediaDetails) }) ||
        (cropToGrid && {
            width: propIn(['data', 'width'], attachmentDetails),
            height: propIn(['data', 'height'], attachmentDetails),
        }) || { width: widthPx };

    const style = forcedAspectRatio
        ? getPaddingForAspectRatio({
              forcedSize: attachmentSize,
              roundSaved: false,
              cropToGrid,
              cropInset,
              gridSize,
              widthPx,
          })
        : null;

    return (
        <div className={classNames('AttachmentImage', { 'force-aspect-ratio': forcedAspectRatio })} style={style}>
            <img draggable={false} src={uploadedFileData} {...attachmentSize} alt="" />
        </div>
    );
};

AttachmentImage.propTypes = {
    widthPx: PropTypes.number,
    forcedSize: PropTypes.object,
    mediaDetails: PropTypes.object,
    attachmentDetails: PropTypes.object,
    cropToGrid: PropTypes.bool,
    cropInset: PropTypes.number,
    gridSize: PropTypes.number,
};

const OptimisticImage = (props) => {
    const {
        elementId,
        imageType,
        imageDetails,
        mediaDetails,
        attachmentDetails,
        alt,
        widthPx,
        imageQualityWidthPxOverride,
        savedWidthPx,
        onImageLoadCb,
        children,
        forcedSize,
        thumbnailUrl,
        cropToGrid,
        cropInset,
        gridSize,
        useSecureMediaUrl,
        showBrokenIconOnError,
        isPreview,
    } = props;

    if (!imageDetails && !getDataUri(attachmentDetails)) return null;

    const uploadedFileData = getDataUri(attachmentDetails);

    // If we don't have the uploaded file data, then we should not show the progressive image as it might
    // not be completely downloaded.  But we do want to add it to the page so that it can be retrieved in the
    // background
    const showProgressiveImage = !uploadedFileData;

    let progressiveImage = null;
    // If we have image details render the progressive image to ensure its lifecycle hooks fire, even if display
    // is 'none'
    if (imageDetails) {
        const style = {
            display: showProgressiveImage ? 'block' : 'none',
        };

        progressiveImage = (
            <ResponsiveProgressiveImage
                elementId={elementId}
                imageType={imageType}
                imageDetails={imageDetails}
                mediaDetails={mediaDetails}
                style={style}
                alt={alt}
                widthPx={widthPx}
                imageQualityWidthPxOverride={imageQualityWidthPxOverride}
                savedWidthPx={savedWidthPx}
                cropToGrid={cropToGrid}
                cropInset={cropInset}
                gridSize={gridSize}
                onImageLoadCb={onImageLoadCb}
                forcedSize={forcedSize}
                thumbnailUrl={thumbnailUrl}
                useSecureMediaUrl={useSecureMediaUrl}
                showBrokenIconOnError={showBrokenIconOnError}
                isPreview={isPreview}
            />
        );
    }

    return (
        <div className="OptimisticImage">
            <AttachmentImage {...props} />
            {progressiveImage}
            {children}
        </div>
    );
};

OptimisticImage.propTypes = {
    imageType: PropTypes.string.isRequired,
    elementId: PropTypes.string,
    imageDetails: PropTypes.object,
    mediaDetails: PropTypes.object,
    attachmentDetails: PropTypes.object,
    onImageLoadCb: PropTypes.func,
    alt: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    thumbnailUrl: PropTypes.string,
    widthPx: PropTypes.number.isRequired,
    imageQualityWidthPxOverride: PropTypes.number,
    savedWidthPx: PropTypes.number,
    forcedSize: PropTypes.object,
    cropToGrid: PropTypes.bool,
    cropInset: PropTypes.number,
    gridSize: PropTypes.number,
    useSecureMediaUrl: PropTypes.bool,
    showBrokenIconOnError: PropTypes.bool,
    isPreview: PropTypes.bool,
};

export default OptimisticImage;
