import { isGlobalDebugEnabled } from '../../../debug/debugUtil';

const tableInstances = {};

const addHotTableComponent = (elementId, hotInstance) => {
    tableInstances[elementId] = hotInstance;
};

const removeHotTableComponent = (elementId, hotInstance) => {
    if (tableInstances[elementId] === hotInstance) {
        delete tableInstances[elementId];
    }
};

const getHotTableComponent = (elementId) => {
    return tableInstances[elementId];
};

const HotTableManager = {
    addHotTableComponent,
    removeHotTableComponent,
    getHotTableComponent,
};

export const getHotInstance = (elementId) => getHotTableComponent(elementId)?.hotTableInstanceRef?.current;
export const getTableOperations = (elementId) => getHotTableComponent(elementId)?.tableOperationsRef?.current;

if (isGlobalDebugEnabled()) {
    window.getHotTableComponent = getHotTableComponent;
    window.getHotInstance = getHotInstance;
}

export default HotTableManager;
