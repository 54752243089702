// Lib
import React from 'react';
import { createPortal } from 'react-dom';
import { isEqual } from 'lodash';

// Components
import MilanoteCellRendererComponent from '../modules/MilanoteCellRendererComponent';

// Utils
import { getCellVerticalAlignmentClassName } from '../utils/tableDOMUtils';
import { colorBorders, getCellBackgroundColor } from '../utils/tableCellFormattingUtils';

class HotTableRendererPortalManager extends React.Component {
    portalCacheMap = new Map();
    state = { portals: [] };

    afterViewRender = () => {
        this.setState({ portals: [...this.portalCacheMap.values()] }, () => this.portalCacheMap.clear());
    };

    initGetMemoisedPortal = () => {
        const memoisationPropsMap = {};
        const memoisationPortalMap = {};

        return (TD, props) => {
            const { row, col } = props;

            const memoisationKey = `${row}-${col}`;

            // Ghost tables are used to calculate the width of the table columns.
            // In this case, we only want to return the cached portal (if any).
            if (TD.getAttribute('ghost-table')) return memoisationPortalMap[memoisationKey];

            const lastProps = memoisationPropsMap[memoisationKey];
            const propsHaveChanged = !lastProps || !isEqual(lastProps, props);
            if (!propsHaveChanged && TD.innerHTML !== '') return memoisationPortalMap[memoisationKey];

            const portal = createPortal(<MilanoteCellRendererComponent {...props} />, TD, memoisationKey);

            memoisationPropsMap[memoisationKey] = props;
            memoisationPortalMap[memoisationKey] = portal;

            return portal;
        };
    };

    getMemoisedPortal = this.initGetMemoisedPortal();

    getRendererWrapper = () => {
        const hotTableComponent = this;

        // These values are passed in by Handsontable.
        return (hotTableInstance, TD, row, col, prop, hotCellValue, cellProperties) => {
            const { cellData } = cellProperties;
            const { locale, filterQuery } = hotTableInstance.milanoteProps || {};

            const portal = this.getMemoisedPortal(TD, {
                row,
                col,
                cellData,
                hotTableInstance,
                hotCellValue,
                locale,
                filterQuery,
            });

            // Ghost tables are used to calculate the width of the table columns.
            // We want to match the content to the real table, in order for the col widths to be calculated accurately.
            if (TD.getAttribute('ghost-table')) {
                TD.innerHTML = portal.containerInfo.innerHTML || '';
                return;
            }

            hotTableComponent.portalCacheMap.set(`${row}-${col}`, portal);

            TD.classList.add(getCellVerticalAlignmentClassName(cellData?.verticalAlignment));
            TD.style.background = getCellBackgroundColor(cellData);
            colorBorders(hotTableInstance, row, col);
        };
    };

    render() {
        return <div className="HotTableRendererPortalManager">{this.state.portals}</div>;
    }
}

HotTableRendererPortalManager.propTypes = {};

export default HotTableRendererPortalManager;
