import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarFormulaIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-formula"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M15.373 9.234c1.064 0 1.946.35 1.946 1.204 0 .448-.266.812-.742.952h-.756c.084-.784-.014-1.484-.574-1.484-.574 0-.98.714-1.12 2.45h1.736l-.168.896h-1.666l-.756 3.556c-.98 4.634-2.324 6.09-4.172 6.09-1.008 0-1.68-.406-1.68-1.078 0-.644.49-.98 1.036-1.05h.35c.042.854.224 1.512.658 1.512.434 0 .616-.546.98-2.562l1.204-6.468-1.399-.002.154-.686 1.455-.278c.504-1.918 1.498-3.052 3.514-3.052zm3.958 2.996.812 2.534.686-1.12c.602-.98 1.148-1.414 1.82-1.414.728 0 1.12.49 1.12 1.022 0 .644-.42 1.05-.994 1.246h-.308c0-.504-.126-.812-.448-.812-.35 0-.532.182-.952.826l-.672 1.036.658 2.058c.182.588.294.91.616.91.308 0 .616-.238 1.092-.77l.378.364c-1.036 1.47-1.736 2.03-2.59 2.03-.742 0-1.092-.588-1.386-1.414l-.476-1.33-.84 1.33c-.56.896-1.12 1.414-1.89 1.414-.658 0-1.036-.462-1.036-1.008 0-.672.392-1.078.98-1.288h.308c0 .518.126.84.462.84.35 0 .56-.238.938-.826l.798-1.204-1.064-2.982-.924-.476.14-.434 2.352-.532h.42z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarFormulaIcon);
export default Memo;
