// Lib
import * as Immutable from 'immutable';

// Constants
import {
    USER_UPDATE,
    USER_NAVIGATE,
    USER_CONNECT,
    USER_DISCONNECT,
    USER_ONLINE_STATUS,
    USER_ACTIVITY_LOAD,
    USER_REFRESH,
    USER_ACTIVITY_HEARTBEAT,
} from './userConstants';
import { CURRENT_USER_SET } from './currentUserConstants';
import { ELEMENTS_SELECTED, ELEMENTS_DESELECTED } from '../../common/elements/selectionConstants';
import { ELEMENT_UPDATE, ELEMENT_MOVE_MULTI } from '../../common/elements/elementConstants';

const initialState = Immutable.Map({
    currentBoardId: null,
    timestamp: null,
    selectedElementIds: null,
});

const updateUser = (state, action) => {
    if (!action) return state;

    const userChanges = Immutable.fromJS(action.changes);
    return state.mergeDeep(userChanges);
};

const setUser = (state, action) => state.mergeDeep(Immutable.fromJS(action.user));

const updateActiveUserBoard = (state, action) => {
    const { timestamp } = action;
    return state
        .set('onlineStatus', USER_ONLINE_STATUS.CONNECTED)
        .set('currentBoardId', action.newBoardId)
        .set('timestamp', timestamp);
};

const updateActiveUserTimestamp = (state, action) => {
    const { timestamp } = action;
    return state.set('onlineStatus', USER_ONLINE_STATUS.CONNECTED).set('timestamp', timestamp);
};

const connectUser = (state, action) =>
    state.set('onlineStatus', USER_ONLINE_STATUS.CONNECTED).set('timestamp', action.timestamp);

const disconnectUser = (state, action) =>
    state.set('onlineStatus', USER_ONLINE_STATUS.DISCONNECTED).set('selectedElementIds', null);

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_UPDATE:
            return updateUser(state, action);
        case USER_REFRESH:
        case CURRENT_USER_SET:
            return setUser(state, {
                user: {
                    ...action.user,
                    onlineStatus: USER_ONLINE_STATUS.CONNECTED,
                    authenticated: true,
                    fetched: true,
                },
            });
        case USER_NAVIGATE:
            return updateActiveUserBoard(state, action);
        case USER_ACTIVITY_HEARTBEAT:
        case ELEMENT_MOVE_MULTI:
        case ELEMENT_UPDATE:
        case ELEMENTS_SELECTED:
        case ELEMENTS_DESELECTED:
            return updateActiveUserTimestamp(state, action);
        case USER_CONNECT:
            return connectUser(state, action);
        case USER_ACTIVITY_LOAD:
        case USER_DISCONNECT:
            return disconnectUser(state, action);
        default:
            return state;
    }
};
