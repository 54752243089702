// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import RichMediaOverlay from './RichMediaOverlay';
import Icon from '../../../components/icons/Icon';

// Styles
import './RichMediaErrorOverlay.scss';

const RichMediaErrorOverlay = ({ show }) => {
    if (!show) return null;

    return (
        <RichMediaOverlay className="RichMediaErrorOverlay">
            <Icon name="error-alert" />
            Sorry, this media cannot be played
        </RichMediaOverlay>
    );
};

RichMediaErrorOverlay.propTypes = {
    show: PropTypes.bool,
};

export default RichMediaErrorOverlay;
