// Lib
import * as Immutable from 'immutable';

// Utils
import getSelectedTextContentState from './getSelectedTextContentState';
import editorGetCurrentInlineStyle from '../editorState/editorGetCurrentInlineStyle';

export default (editorState) => {
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
        return editorGetCurrentInlineStyle(editorState);
    }

    let styles = Immutable.OrderedSet();

    const selectedContentState = getSelectedTextContentState(editorState);
    const blockMap = selectedContentState.getBlockMap();

    blockMap.forEach((block) => {
        const characterList = block.getCharacterList();

        characterList.forEach((characterMetadata) => {
            const characterStyles = characterMetadata.getStyle();

            if (characterStyles) {
                styles = styles.union(characterStyles);
            }
        });
    });

    return styles;
};
