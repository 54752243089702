// Utils
import drawImageToNewCanvasContext from './drawImageToNewCanvasContext';

/**
 * Determines whether the DOM image element has transparency by drawing it to
 * a canvas and checking each pixel.
 */
export default (img) => {
    const { canvas, ctx } = drawImageToNewCanvasContext(img);
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const { data } = imgData;

    for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] < 255) return true;
    }

    return false;
};
