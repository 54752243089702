import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeCheckboxIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-checkbox"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                d="M22 8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h12Zm0 1.5H10a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V10a.5.5 0 0 0-.5-.5Zm-2.025 3.17a.75.75 0 0 1 .105 1.055l-4.5 5.5a.75.75 0 0 1-1.11.055l-2.5-2.5a.75.75 0 0 1 1.06-1.06l1.914 1.913 3.976-4.858a.75.75 0 0 1 1.055-.105Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeCheckboxIcon);
export default Memo;
