const findFirstDomAncestor = (fn) => {
    const findFirstDomAncestorRecursive = (node) => {
        if (!node || node === window) return null;
        return fn(node) ? node : findFirstDomAncestorRecursive(node.parentNode);
    };

    return findFirstDomAncestorRecursive;
};

export default findFirstDomAncestor;
