import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CellTypeRatingIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon cell-type-rating"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="none" rx={4} />
            <path
                fill="#48505E"
                d="M15.436 7.347a.632.632 0 0 1 1.128 0l2.439 4.892 5.458.785c.479.069.69.615.42.98l-.072.081-3.95 3.807.934 5.38c.082.474-.382.843-.82.695l-.092-.04L16 21.387l-4.88 2.54a.629.629 0 0 1-.922-.556l.009-.1.932-5.379-3.948-3.807a.62.62 0 0 1 .242-1.037l.106-.024 5.457-.785 2.44-4.892ZM16 9.577l-1.66 3.331a1.5 1.5 0 0 1-1.13.816l-3.684.529 2.655 2.56A1.5 1.5 0 0 1 12.635 18l-.018.147-.63 3.636 3.32-1.728a1.5 1.5 0 0 1 1.238-.066l.147.066 3.32 1.728-.63-3.635a1.5 1.5 0 0 1 .335-1.229l.102-.108 2.654-2.559-3.684-.53a1.5 1.5 0 0 1-1.057-.687l-.072-.128L16 9.577Z"
            />
        </g>
    </svg>
);
const Memo = memo(CellTypeRatingIcon);
export default Memo;
