import { MentionAttrs } from './Mention';
import randomString from '../../../uid/randomString';

// NOTE: This is used in both the old Draft.js mention data, as well as current
//  suggestion data
type SuggestionEntry = {
    id: string;
    name: string;
    _name: {
        givenName: string;
        familyName: string;
        displayName: string;
    };
    email: string;
    image: string;
    disabled: boolean;
    shareCount: number;
    sharingCurrentBoard: boolean;
    score: number;
};

// ~1 / 57 billion chance of collision
const MENTION_KEY_DEFAULT_LENGTH = 6;

/**
 * Converts data from a suggestion entry (which is also stored in the old Draft.js mentions)
 * to the format used by the Tiptap mention mark.
 */
export function suggestionEntryToTiptapMentionMark(
    suggestionEntry: SuggestionEntry,
    mentionKey: string = randomString(MENTION_KEY_DEFAULT_LENGTH),
): MentionAttrs {
    const { _name, name, ...mentionData } = suggestionEntry;
    return {
        mentionKey,
        ...mentionData,
        label: name || _name.displayName,
        ..._name,
    };
}
