import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const HeaderPublishedLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={11}
        viewBox="0 0 18 11"
        className="Icon header-published-link"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="m16.72 3.92.186.24c.62.79.656 1.9.11 2.728l-.11.152c-3.892 5.199-11.617 5.279-15.627.24l-.185-.24c-.62-.79-.656-1.9-.11-2.728l.11-.152c3.892-5.199 11.617-5.279 15.627-.24ZM9 2.6a2.997 2.997 0 0 0-2.995 3c0 1.657 1.34 3 2.994 3a2.997 2.997 0 0 0 2.995-3c0-1.657-1.34-3-2.995-3Z"
        />
    </svg>
);
const Memo = memo(HeaderPublishedLinkIcon);
export default Memo;
