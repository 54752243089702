// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { identity, stubFalse } from 'lodash/fp';
import classNames from 'classnames';

// Utils
import { stopPropagationOnly } from '../../../utils/domUtil';
import { elementClassNames, getDomElementId } from '../../utils/elementUtil';
import { getColor } from '../../../../common/elements/utils/elementPropertyUtils';
import { getColorCssValue, getCssPropertyForTextOnColor } from '../../utils/elementColorStyleUtils';

// Actions
import { setTemporarilyDisableGuestRegistrationForm } from '../../../reducers/guestNavigationActions';

// Selector
import { getThreadCommentsCountSelector } from '../store/commentsSelector';
import { individualCommentThreadActivitySelector } from '../../activity/selectors/boardActivitySelector';

// Components
import PopupTrigger from '../../../components/popupPanel/PopupTrigger';
import Icon from '../../../components/icons/Icon';
import CommentThreadCollapsedPopup from './CommentThreadCollapsedPopup';
import ActivityIndicator from '../../activity/ActivityIndicator';
import CommentThreadCollapsedDragHandle from './CommentThreadCollapsedDragHandle';
import CommentThreadCollapsedRemoteActivityIndicator from './CommentThreadCollapsedRemoteActivityIndicator';

// Constants
import { FILTER_STATES } from '../../../../common/elements/elementConstants';

// Styles
import './CommentThreadCollapsed.scss';

const getStyle = (elementColor) => {
    if (!elementColor) return null;

    const elementColorHex = getColorCssValue(elementColor);
    const textColor = getCssPropertyForTextOnColor(elementColor, true);

    return {
        '--comment-thread-background-color': elementColorHex,
        '--comment-thread-text-color': textColor,
    };
};

const mapStateToProps = () =>
    createStructuredSelector({
        commentsCount: getThreadCommentsCountSelector(),
        commentActivity: individualCommentThreadActivitySelector(),
    });

const CommentThreadCollapsed = (props) => {
    const {
        elementId,
        element,
        commentsCount,
        connectDragSource,
        elementEvents,
        commentActivity,
        handleMode,
        isRemotelySelected,
        hideEditing,
        hideComments,
        style,
        isEditable,
        filterState,
    } = props;

    const forcePopupOpen = filterState === FILTER_STATES.FOCUS || filterState === FILTER_STATES.PASS;

    const popupId = `collapsed-comment-${elementId}`;

    const elementColor = getColor(element);

    const commentThreadCollapsedClassNames = classNames('CommentThreadCollapsed', {
        'hide-comments': hideComments,
        'hide-replies': hideEditing,
    });

    const threadClassNames = elementClassNames(commentThreadCollapsedClassNames, props);

    const wrapper = connectDragSource || identity;

    return (
        <div className="CommentThreadCollapsedContainer" style={style}>
            {wrapper(
                <div
                    id={getDomElementId(elementId)}
                    className={threadClassNames}
                    {...elementEvents}
                    style={getStyle(elementColor)}
                >
                    <PopupTrigger
                        disableToggleClose
                        popupId={popupId}
                        stayActivePredicate={stubFalse}
                        onToggle={!isEditable ? setTemporarilyDisableGuestRegistrationForm : null}
                        onClick={!isEditable ? stopPropagationOnly : null}
                    >
                        {(isOpen) => (
                            <div className="pin-button">
                                <CommentThreadCollapsedDragHandle className={handleMode} />
                                <Icon name="comment-pin" />
                                <div className="comments-count-wrapper">
                                    <span className="comments-count">{Math.min(commentsCount, 99)}</span>
                                </div>
                                {!!commentActivity && (
                                    <ActivityIndicator toggleVal={isOpen} commentActivity={commentActivity} />
                                )}
                                <CommentThreadCollapsedRemoteActivityIndicator show={isRemotelySelected} />
                            </div>
                        )}
                    </PopupTrigger>
                </div>,
            )}
            <CommentThreadCollapsedPopup {...props} popupId={popupId} forcePopupOpen={forcePopupOpen} />
        </div>
    );
};

CommentThreadCollapsed.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    commentsCount: PropTypes.number,
    elementEvents: PropTypes.object,
    commentActivity: PropTypes.object,
    handleMode: PropTypes.string,
    style: PropTypes.object,
    filterState: PropTypes.string,

    isEditable: PropTypes.bool,
    isDragging: PropTypes.bool,
    isSelected: PropTypes.bool,
    hideComments: PropTypes.bool,
    hideEditing: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    isDragPreview: PropTypes.bool,
    attachModeWhileDragging: PropTypes.bool,

    connectDragSource: PropTypes.func,

    onClick: PropTypes.func,
};

export default connect(mapStateToProps)(CommentThreadCollapsed);
