import { RefObject, useEffect, useState } from 'react';
import setInitialSheetHeight from '../utils/setInitialSheetHeight';

/**
 * When sheet is opened, update height to the default snap point, and update the sheetInitialised value to show when it's done
 * @param isSheetOpen
 * @param sheetRef
 * @param sheetOverlayRef
 * @param defaultSnapPoint
 * @param addSnapPoint
 * @param updateActiveSnapPoint
 * @param closeSheet
 * @param isSheetMounted
 * @param setIsSheetMounted
 * @param closeAnimationInProgress
 */
const useModalSheetInitialisation = (
    isSheetOpen: boolean,
    sheetRef: RefObject<HTMLDivElement>,
    sheetOverlayRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    updateActiveSnapPoint: (snapPoint: number, goToPoint: boolean) => void,
    closeSheet: () => void,
    isSheetMounted: boolean,
    setIsSheetMounted: (isSheetMounted: boolean) => void,
    closeAnimationInProgress: React.MutableRefObject<boolean>,
): boolean => {
    const [sheetInitialised, setSheetInitialised] = useState<boolean>(false);

    /**
     * Close the sheet when going back in the browser history.
     * e.g. when the user presses the browser or native back button
     */
    const closeSheetOnBack = () => {
        // Add a blank history state to allow the user to go back
        window.history.pushState({}, '');
        window.addEventListener('popstate', closeSheet, { once: true });
    };

    useEffect(() => {
        if (isSheetOpen) {
            // Trigger sheet to open
            setIsSheetMounted(true);
        } else {
            // Trigger sheet to start animating closed
            closeSheet();
        }
    }, [isSheetOpen]);

    useEffect(() => {
        if (isSheetMounted) {
            // Set up the initial styling and functionality once sheet element exists
            sheetOverlayRef.current?.classList.add('overlay-visible');
            setInitialSheetHeight(
                sheetRef,
                defaultSnapPoint,
                addSnapPoint,
                updateActiveSnapPoint,
                setSheetInitialised,
                closeAnimationInProgress,
            );
            closeSheetOnBack();
        } else {
            setSheetInitialised(false);
        }
    }, [isSheetMounted]);

    return sheetInitialised;
};

export default useModalSheetInitialisation;
