// Lib
import { round } from 'lodash';

// Utils
import * as pointLib from '../../../../common/maths/geometry/point';
import { getX, getY } from '../../../../common/maths/geometry/dimensionUtil';

export const getFreehandPointDistSquared = (point1, point2) => {
    if (!point1 || !point2) return 0;

    const xd = point1[0] - point2[0];
    const yd = point1[1] - point2[1];
    return xd * xd + yd * yd;
};

// NOTE: Return undefined here to save on data size, if the stroke doesn't have pressure
export const pointHasPressure = (point) => (point && point[2] !== 0) || undefined;

export const getFreehandPointOffset = (startPoint, endPoint) => {
    const [startX, startY] = startPoint;
    const [endX, endY] = endPoint;
    return pointLib.getPoint(endX - startX, endY - startY);
};

export const scaleFreehandPoint =
    (scale) =>
    ([x, y, pressure]) =>
        [x * scale, y * scale, pressure];

export const translateFreehandPoint = (offset) => {
    const xOffset = getX(offset);
    const yOffset = getY(offset);

    return ([x, y, pressure]) => [x + xOffset, y + yOffset, pressure];
};

export const roundFreehandPoint = ([x, y, pressure]) => [round(x, 1), round(y, 1), pressure];
