// Actions
import { updateMultipleElements } from '../../../../../../common/elements/elementActions';

// Utils
import { getColor, getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isColorSwatch } from '../../../../../../common/elements/utils/elementTypeUtils';
import { isEmpty } from '../../../../../../common/utils/immutableHelper';
import { parseColorObject } from '../../../../../../common/colors/colorObjectUtil';

// Constants
import { ELEMENT_UPDATE_TYPE } from '../../../../../../common/elements/elementConstants';
import { COLOR_DISPLAY } from '../../../../../../common/colors/colorConstants';

export const updateElementsColorDisplay =
    ({ elements, colorDisplay }) =>
    (dispatch) => {
        if (isEmpty(elements)) return null;

        const updateType = ELEMENT_UPDATE_TYPE.COLOR_SPACE;
        const updates = [];

        elements.forEach((element) => {
            if (!isColorSwatch(element)) return null;

            const changes = {};

            if (colorDisplay === COLOR_DISPLAY.OFF) {
                changes.showColorValue = false;
            } else {
                const prevColor = getColor(element);
                const newColor = parseColorObject(prevColor, colorDisplay);

                changes.showColorValue = true;
                if (newColor) changes.color = newColor;
            }

            updates.push({
                id: getElementId(element),
                changes,
                updateType,
            });
        });

        if (isEmpty(updates)) return null;

        return dispatch(
            updateMultipleElements({
                updateType,
                updates,
            }),
        );
    };
