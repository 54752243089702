import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarImagePreviewIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-image-preview"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#DBDCDE" rx={4} />
            <path
                fill="#323B4A"
                d="M23 8c1.105 0 2 1.018 2 2v12c0 .982-.895 2-2 2H9c-1.105 0-2-1.018-2-2V10c0-.982.895-2 2-2h14zm-9 8-5 6h14l-3.5-4-1.963 2.244L14 16zm6-5a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarImagePreviewIcon);
export default Memo;
