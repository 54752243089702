export const IMAGE_SEARCH_SUBMIT = 'IMAGE_SEARCH_SUBMIT';
export const IMAGE_SEARCH_SUCCESS = 'IMAGE_SEARCH_SUCCESS';
export const IMAGE_SEARCH_FAILURE = 'IMAGE_SEARCH_FAILURE';

export const IMAGE_SEARCH_SET_MODE = 'IMAGE_SEARCH_SET_MODE';

export const IMAGE_SEARCH_POPUP_MODE_DEFAULT = '__DEFAULT__';
export const IMAGE_SEARCH_POPUP_MODE_UPLOAD = '__UPLOAD__';

export const IMAGE_SEARCH_POPUP_WEB_EXTENSION_ALERT_KEY = 'milanote.image-search.web-extension-panel-visibility';
