// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Button from '../../../../components/buttons/Button';

// Styles
import './ModalToolbarButton.scss';

const ModalToolbarButton = (props) => {
    const { children, className } = props;

    const classes = classNames('ModalToolbarButton', className);

    return (
        <Button {...props} className={classes}>
            <div className="button-contents">{children}</div>
        </Button>
    );
};

ModalToolbarButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

export default ModalToolbarButton;
