// Lib
import React, { ReactElement } from 'react';

// Hooks
import useMobileTextStyleEditorHandlers from './useMobileTextStyleEditorHandlers';
import useModalSheet from '../../../structural/modalSheet/hooks/useModalSheet';

// Components
import MobileToolbarSheet from '../../../../workspace/toolbar/components/mobileToolbarSheet/MobileToolbarSheet';
import TextStyleRichTextTool from '../../../../workspace/toolbar/components/selectionTools/richContentTools/textStylesTool/TextStyleRichTextTool';
import TextStyleRichTextToolPopupContent from '../../../../workspace/toolbar/components/selectionTools/richContentTools/textStylesTool/TextStyleRichTextToolPopup';
import TiptapTextStylePopupContent from '../../../../workspace/toolbar/components/selectionTools/editorTools/textStylePopup/TiptapTextStylePopupContent';

// Utils
import { focusFakeInput } from '../../../../utils/ipad/ipadUtils';
import { prop } from '../../../../../common/utils/immutableHelper';

// Types
import { ImMNUser, MNUser } from '../../../../../common/users/userModelTypes';
import { ImMNElement } from '../../../../../common/elements/elementModelTypes';

// Styles
import './MobileTextStyleRichTextTool.scss';

export type MobileTextStyleRichTextToolProps = {
    currentUser: ImMNUser | MNUser;
    currentUserId: string;
    currentBoardId: string;
    gridSize: number;
    gridSizeName: string;
    permissions: number;
    selectedElementIds: Immutable.List<string>;
    selectedElements: Immutable.List<ImMNElement>;
    editorKey: string;
    hasEditorSelection: boolean | undefined;
};

// FIXME-MOBILE - Add a style to the current editor to show the current selection
//  NOTE: Investigate whether it might be easier to do this using vanilla JS, so that we don't
//        accidentally save the selection state to the DB
const MobileTextStyleRichTextTool = (props: MobileTextStyleRichTextToolProps): ReactElement => {
    const { openSheet, sheetProps } = useModalSheet();

    const { focusEditor, isTiptapEditor } = useMobileTextStyleEditorHandlers();

    const onClickToolButton = () => {
        openSheet();

        // blur active input, so that there's space for the sheet to display
        const activeElement = document.activeElement as HTMLElement;
        activeElement?.blur();
    };

    /**
     * Focus fake input as read only, so that we can switch focus to the element editor later
     */
    const onSheetClose = () => {
        const mobileWorkspaceElement = document.querySelector('.ModernMobileWorkspace');
        focusFakeInput({ x: 0, y: 0 }, mobileWorkspaceElement, true);
    };

    const element = prop(0, props.selectedElements);
    const TextStylePopupContent = isTiptapEditor ? TiptapTextStylePopupContent : TextStyleRichTextToolPopupContent;

    return (
        <div className="MobileTextStyleRichTextTool">
            <div onClick={onClickToolButton}>
                <TextStyleRichTextTool isOpen={sheetProps.isSheetOpen} name="Text style" />
            </div>
            <MobileToolbarSheet
                {...sheetProps}
                className="text-style-rich-text-tool"
                onSheetClose={onSheetClose}
                onCloseTransitionEnd={focusEditor}
            >
                <TextStylePopupContent {...props} showBlockStyleTools={true} element={element} />
            </MobileToolbarSheet>
        </div>
    );
};

export default MobileTextStyleRichTextTool;
