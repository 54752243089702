// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { getPointerEventDetail } from '../../drawingEditorEventUtils';
import { isAttemptingMultiSelect } from '../../../../selection/selectionUtils';

// Components
import DrawingPath from '../DrawingPath';
import OutlinedDrawingPath from '../OutlinedDrawingPath';

const DrawingEditorSelectModeSvgStaticGroupDef = (props) => {
    const {
        paths,
        selectedPathIdsMap,
        isDragMove,
        canvasScale,
        draggedPathIdsMap,
        debugUpdateAllStrokes,
        debugDrawingLastUpdateTime,
    } = props;

    return (
        <g className="static-group">
            {paths.map((path) => {
                const { id, ...rest } = path;
                const isSelected = selectedPathIdsMap && selectedPathIdsMap[id];
                const isDraggingPath = isDragMove && draggedPathIdsMap[id];

                const pathClasses = classNames({
                    dragging: isDraggingPath,
                    selected: isSelected,
                });

                return (
                    <OutlinedDrawingPath
                        key={id}
                        canvasScale={canvasScale}
                        className={pathClasses}
                        {...rest}
                        debugUpdateAllStrokes={debugUpdateAllStrokes}
                        debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
                    />
                );
            })}
        </g>
    );
};

const DrawingEditorSelectModeSvgHitAreaGroupDef = (props) => {
    const {
        paths,
        selectedPathIdsMap,
        debugUpdateAllStrokes,
        debugDrawingLastUpdateTime,
        pointerEventStats,
        startDrag,
        toggleSelectedPath,
        setSelectedPathIds,
        svgRef,
        canvasScale,
        viewBoxX,
        viewBoxY,
    } = props;

    return (
        <g className="hit-area">
            {paths.map((path) => {
                const { id, ...rest } = path;
                const isSelected = selectedPathIdsMap && selectedPathIdsMap[id];

                const onPathPointerDown = (event) => {
                    if (event.buttons !== 1) return;

                    pointerEventStats.current.pointerDownPathId = id;

                    event.preventDefault();
                    event.stopPropagation();

                    const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY);
                    pointerEventStats.current.moveCount = 0;

                    const draggedIdsMap = isSelected ? selectedPathIdsMap : { [id]: true };

                    return startDrag(point, draggedIdsMap);
                };

                const onPathPointerUp = (event) => {
                    if (pointerEventStats.current.moveCount > 2) return;
                    if (pointerEventStats.current.pointerDownPathId !== id) return;

                    isAttemptingMultiSelect(event) ? toggleSelectedPath(id) : setSelectedPathIds([id]);
                };

                return (
                    <DrawingPath
                        key={id}
                        className="path-hit-area"
                        {...rest}
                        onPointerDown={onPathPointerDown}
                        onPointerUp={onPathPointerUp}
                        debugUpdateAllStrokes={debugUpdateAllStrokes}
                        debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
                    />
                );
            })}
        </g>
    );
};

const DrawingEditorSelectModeSvgTransformGroupDef = (props) => {
    const {
        paths,
        selectedPathIdsMap,
        draggedPathIdsMap,
        debugUpdateAllStrokes,
        debugDrawingLastUpdateTime,
        transformGroupRef,
        isDragMove,
        canvasScale,
    } = props;

    if (!isDragMove) return null;

    return (
        <g className="transform-group" ref={transformGroupRef}>
            {paths.map((path) => {
                const { id, ...rest } = path;
                if (!draggedPathIdsMap[id]) return null;

                const pathClasses = classNames('drag-preview', {
                    selected: selectedPathIdsMap && selectedPathIdsMap[id],
                });

                return (
                    <OutlinedDrawingPath
                        key={id}
                        canvasScale={canvasScale}
                        className={pathClasses}
                        {...rest}
                        debugUpdateAllStrokes={debugUpdateAllStrokes}
                        debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
                    />
                );
            })}
        </g>
    );
};

DrawingEditorSelectModeSvgTransformGroupDef.propTypes =
    DrawingEditorSelectModeSvgHitAreaGroupDef.propTypes =
    DrawingEditorSelectModeSvgStaticGroupDef.propTypes =
        {
            paths: PropTypes.array,
            isDragMove: PropTypes.bool,

            selectedPathIdsMap: PropTypes.object,
            draggedPathIdsMap: PropTypes.object,
            debugUpdateAllStrokes: PropTypes.bool,
            debugDrawingLastUpdateTime: PropTypes.number,

            canvasScale: PropTypes.number,

            svgRef: PropTypes.object,
            transformGroupRef: PropTypes.object,

            startDrag: PropTypes.func,
            toggleSelectedPath: PropTypes.func,
            setSelectedPath: PropTypes.func,
        };

export const DrawingEditorSelectModeSvgStaticGroup = React.memo(DrawingEditorSelectModeSvgStaticGroupDef);
export const DrawingEditorSelectModeSvgHitAreaGroup = React.memo(DrawingEditorSelectModeSvgHitAreaGroupDef);
export const DrawingEditorSelectModeSvgTransformGroup = React.memo(DrawingEditorSelectModeSvgTransformGroupDef);
