import { includes, debounce } from 'lodash';
import { batch, batching, type } from 'redux-batch-middleware';

const createDefaultIsBulkableAction =
    ({ bulkActionTypes = [] }) =>
    (action) =>
        includes(bulkActionTypes, action.type);

/**
 * Must be used in conjunction with redux-batch-middleware: https://github.com/mrydengren/redux-batch-middleware
 *
 * This allows specific actions to be debounced and batched together so they are dispatched as a single state update.
 */
export const bulkingMiddleware =
    (config = {}) =>
    (store) =>
    (next) => {
        const { debounceWait = 0, debounceOptions } = config;
        const isBulkableAction = config.isBulkableAction || createDefaultIsBulkableAction(config);
        const batchMiddleware = batch(store)(next);

        let bulkAction = [];

        const addToBulkAction = (action) => {
            bulkAction.push(action);
        };

        const dispatchBulkAction = () => {
            const bulkActionToDispatch = bulkAction;
            bulkAction = [];
            return batchMiddleware(bulkActionToDispatch);
        };

        const debouncedBulkActionDispatch = debounce(dispatchBulkAction, debounceWait, debounceOptions);

        return (action) => {
            if (!isBulkableAction(action)) return batchMiddleware(action);

            addToBulkAction(action);
            return debouncedBulkActionDispatch();
        };
    };

export const enableReducerBatching = batching;

export const BATCH_ACTION_TYPE = type;

export const createBatchAction = ({ actions, ...rest }) => ({
    type: BATCH_ACTION_TYPE,
    payload: actions.map((action) => ({
        ...action,
        batched: true,
    })),
    ...rest,
});
