// Lib
import { isEmpty } from 'lodash/fp';

// Util
import { fetchElements } from '../element/elementService';
import { isSkeleton } from '../../common/elements/utils/elementTypeUtils';
import { getElementId } from '../../common/elements/utils/elementPropertyUtils';
import { shouldFetchAsyncResource } from '../utils/services/http/asyncResource/asyncResourceUtils';

// Actions
import { finishEditingElement, removeSelectedElements } from '../element/selection/selectionActions';

// Selectors
import { getElements } from '../element/selectors/elementsSelector';
import { getElement } from '../../common/elements/utils/elementTraversalUtils';
import { getSelectedElementIds } from '../element/selection/selectedElementsSelector';
import { getLocalCacheHydrationTimestamp } from '../offline/cache/localCacheSelector';
import { getAsyncResourceEntityState } from '../utils/services/http/asyncResource/asyncResourceSelector';

// Constants
import * as ELEMENT_ACTION_TYPE from '../../common/elements/elementConstants';
import { BATCH_ACTION_TYPE } from '../store/reduxBulkingMiddleware';
import { ELEMENTS_FORCE_REMOTE_DESELECT, ELEMENTS_SELECTED } from '../../common/elements/selectionConstants';
import { ResourceTypes } from '../utils/services/http/asyncResource/asyncResourceConstants';

export default ({ dispatch, getState }) => {
    const fetchUnknownElements = (ids) => {
        const elementIdsToFetch = ids.filter((id) => {
            const state = getState();

            const elements = getElements(state);
            const el = getElement(elements, id);

            if (!el || !getElementId(el) || isSkeleton(el)) return true;

            const elementResourceEntity = getAsyncResourceEntityState(state, ResourceTypes.elements, id);

            const localCacheHydrationTime = getLocalCacheHydrationTimestamp(state);

            return shouldFetchAsyncResource(elementResourceEntity, localCacheHydrationTime);
        });

        if (elementIdsToFetch.length) {
            dispatch(fetchElements({ elementIds: elementIdsToFetch, force: true }));
        }
    };

    const handleRemoteAction = (action) => {
        // If it's a batch action, we need to check each action to determine whether
        // any of those actions need data
        if (action.type === BATCH_ACTION_TYPE) {
            const payload = action.payload || [];
            payload.forEach((a) => handleRemoteAction(a));
        }

        // if it's an action that requires loaded element actions
        if (action.type === ELEMENT_ACTION_TYPE.ELEMENT_MOVE_MULTI) {
            fetchUnknownElements(action.moves.map((move) => move.id));
        }

        if (action.type === ELEMENT_ACTION_TYPE.ELEMENT_UPDATE) {
            fetchUnknownElements(action.updates.map((update) => update.id));
        }

        // Locally deselect any element that:
        // - ELEMENT_SELECTED - Has been remotely selected. This is to prevent users from being able to
        //   edit the same element
        // - ELEMENT_FORCE_REMOTE_DESELECT - Has been forced to deselect remotely.
        if (action.type === ELEMENTS_SELECTED || action.type === ELEMENTS_FORCE_REMOTE_DESELECT) {
            const state = getState();
            const currentlySelectedElements = getSelectedElementIds(state);

            const elementsToDeselect = action.ids.filter((id) => currentlySelectedElements.includes(id));

            if (isEmpty(elementsToDeselect)) return;

            elementsToDeselect.forEach((id) => dispatch(finishEditingElement(id)));

            dispatch(removeSelectedElements({ ids: elementsToDeselect }));
        }
    };

    return (next) => (action) => {
        if (!action.remote) return next(action);

        handleRemoteAction(action);

        return next(action);
    };
};
