// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './DrawingEditorToolbarExtendedPanel.scss';

const DrawingEditorToolbarExtendedPanel = (props) => {
    const { children, className, ...rest } = props;

    return (
        <div {...rest} className={classNames('DrawingEditorToolbarExtendedPanel', className)}>
            {children}
        </div>
    );
};

DrawingEditorToolbarExtendedPanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DrawingEditorToolbarExtendedPanel;
