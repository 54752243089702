// Utils
import delay from '../../../../../../../common/utils/lib/delay';
import { getPermissionId } from '../../../../../../../server/app/components/permissions/permissionPropertyUtil';

// Services
import mediaService from '../../../../../../utils/services/mediaService';
import { sendAmplitudeEvent } from '../../../../../../analytics/amplitudeService';
import {
    removePublishBoardPasswordHttp,
    updatePublishBoardPasswordHttp,
} from '../../../../../sharing/boardSharingService';

// Actions
import { updateElement } from '../../../../../../element/actions/elementActions';
import {
    publishBoard as publishBoardAction,
    unpublishBoard as unpublishBoardAction,
    enableBoardPublicEditing,
    disableBoardPublicEditing,
} from '../../../../../sharing/boardSharingActions';
import { boardPermissionLoad, getTokenForElementIdThunk } from '../../../../../../utils/permissions/permissionsActions';

// Constants
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../../../../common/analytics/statsConstants';
import { ExperimentId } from '../../../../../../../common/experiments/experimentsConstants';

export const publishBoard =
    ({ boardId, commentsEnabled }) =>
    async (dispatch) => {
        sendAmplitudeEvent({
            eventType: 'Enabled secret link',
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.SECRET_LINK]: true },
            },
        });

        const publishData = {
            boardId,
            commentsEnabled,
            feature: ExperimentId.combinedSharePopup,
        };

        const tokens = await dispatch(getTokenForElementIdThunk({ elementId: boardId }));
        if (tokens) {
            publishData.tokens = tokens;
        }

        const response = await dispatch(publishBoardAction(publishData));

        const { permission } = response;
        dispatch(boardPermissionLoad({ permissions: [permission] }));

        const permissionId = getPermissionId(permission);

        mediaService.preview.fetchBoardPreview({ boardId, permissionId });
        return response;
    };

export const unpublishBoard =
    ({ boardId }) =>
    async (dispatch) => {
        // Need this to update immediately to prevent the url from flashing in and out
        dispatch(updateElement({ id: boardId, changes: { published: false }, sync: false }));

        const tokens = await dispatch(getTokenForElementIdThunk({ elementId: boardId }));

        return dispatch(unpublishBoardAction({ boardId, tokens })).catch((err) => {
            // Undo the local change
            dispatch(updateElement({ id: boardId, changes: { published: true }, sync: false }));
            throw err;
        });
    };

export const enablePublicEditing =
    ({ boardId }) =>
    async (dispatch) => {
        sendAmplitudeEvent({
            eventType: 'Enabled public edit link',
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.EDITABLE_LINK]: true },
            },
        });

        // Update this immediately to prevent the interface from flashing
        dispatch(updateElement({ id: boardId, changes: { publicEditEnabled: true }, sync: false }));

        // Allow the interface to update
        await delay(300);

        const response = await dispatch(enableBoardPublicEditing({ boardId }));

        const { permission } = response;
        dispatch(boardPermissionLoad({ permissions: [permission] }));

        const permissionId = getPermissionId(permission);

        mediaService.preview.fetchBoardPreview({ boardId, permissionId });
        return response;
    };

export const disablePublicEditing =
    ({ boardId }) =>
    async (dispatch) => {
        // Update this immediately to prevent the interface from flashing
        dispatch(updateElement({ id: boardId, changes: { publicEditEnabled: false }, sync: false }));
        // Allow the interface to update
        await delay(300);
        return dispatch(disableBoardPublicEditing({ boardId }));
    };

export const updatePublishedBoardPassword =
    ({ boardId, permissionId, password }) =>
    async (dispatch) => {
        const tokens = await dispatch(getTokenForElementIdThunk({ elementId: boardId }));
        return updatePublishBoardPasswordHttp({ boardId, permissionId, password, tokens });
    };

export const removePublishedBoardPassword =
    ({ boardId, permissionId }) =>
    async (dispatch) => {
        const tokens = await dispatch(getTokenForElementIdThunk({ elementId: boardId }));
        return removePublishBoardPasswordHttp({ boardId, permissionId, tokens });
    };
