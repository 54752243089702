import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniImageSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-image-selected"
        {...props}
    >
        <defs>
            <path id="ElementMiniImageSelectedIcon__a" d="M0 0h12v12H0z" />
            <path id="ElementMiniImageSelectedIcon__c" d="M1 0h12a1 1 0 0 1 1 1v9H0V1a1 1 0 0 1 1-1z" />
            <path id="ElementMiniImageSelectedIcon__f" d="M10.6 5.1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            <filter
                id="ElementMiniImageSelectedIcon__e"
                width="300%"
                height="300%"
                x="-100%"
                y="-100%"
                filterUnits="objectBoundingBox"
            >
                <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 1 0 0 0 0 0.992156863 0 0 0 0 0.866666667 0 0 0 0.5 0"
                />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#CCC" d="M4 3h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
            <path fill="#68C0E0" d="M4 3h12a1 1 0 0 1 1 1v9H3V4a1 1 0 0 1 1-1z" />
            <g transform="translate(4 4)">
                <mask id="ElementMiniImageSelectedIcon__b" fill="#fff">
                    <use xlinkHref="#ElementMiniImageSelectedIcon__a" />
                </mask>
                <use xlinkHref="#ElementMiniImageSelectedIcon__a" fill="#FFF" />
                <g mask="url(#ElementMiniImageSelectedIcon__b)">
                    <g transform="translate(-1 -1)">
                        <mask id="ElementMiniImageSelectedIcon__d" fill="#fff">
                            <use xlinkHref="#ElementMiniImageSelectedIcon__c" />
                        </mask>
                        <use xlinkHref="#ElementMiniImageSelectedIcon__c" fill="#8AE0FF" />
                        <path
                            fill="#4EB85A"
                            d="M8 8 4.5 4.5 1 8v2h12V8l-2.5-2z"
                            mask="url(#ElementMiniImageSelectedIcon__d)"
                        />
                        <g mask="url(#ElementMiniImageSelectedIcon__d)">
                            <use
                                xlinkHref="#ElementMiniImageSelectedIcon__f"
                                fill="#000"
                                filter="url(#ElementMiniImageSelectedIcon__e)"
                            />
                            <use xlinkHref="#ElementMiniImageSelectedIcon__f" fill="#FFFCE6" />
                        </g>
                    </g>
                </g>
            </g>
            <path
                fill="#323B4A"
                fillRule="nonzero"
                d="M14.5 2A3.5 3.5 0 0 1 18 5.5v9a3.5 3.5 0 0 1-3.5 3.5h-9A3.5 3.5 0 0 1 2 14.5v-9A3.5 3.5 0 0 1 5.5 2h9zm0 2h-9A1.5 1.5 0 0 0 4 5.5v9A1.5 1.5 0 0 0 5.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 4z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniImageSelectedIcon);
export default Memo;
