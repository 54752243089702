// Lib
import * as Immutable from 'immutable';

// Constants
import { NOTIFICATION_SET_SELECTED } from './notificationsPopupConstants';

const initialState = Immutable.Map({
    selectedNotificationData: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_SET_SELECTED:
            return state.set('selectedNotificationData', Immutable.fromJS(action));
        default:
            return state;
    }
};
