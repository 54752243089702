// Lib
import { SelectionState } from 'draft-js';

export default (selection, shiftAnchor = 0, shiftFocus = 0) => {
    if (!shiftAnchor && !shiftFocus) return selection;

    const shiftedSelection = SelectionState.createEmpty(selection.getAnchorKey());

    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusKey = selection.getFocusKey();
    const focusOffset = selection.getFocusOffset();

    // Note: This won't work for positive shift amounts that are greater than the number of characters in the block
    return shiftedSelection.merge({
        anchorKey,
        anchorOffset: Math.max(0, anchorOffset + shiftAnchor),
        focusKey,
        focusOffset: Math.max(0, focusOffset + shiftFocus),
        isBackward: selection.getIsBackward(),
        hasFocus: false,
    });
};
