import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNumber } from 'lodash';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { getElementStyle } from '../../../utils/grid/gridUtils';
import { getDomElementId } from '../../utils/elementUtil';
import { getColor, getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import {
    getFileData,
    getFileDataFilename,
    getFileDataSize,
    getFileDataType,
    getFileUploadProgress,
} from '../utils/fileElementUtils';
import { getFileSizeForDisplay } from '../../../../common/files/fileSizeUtils';
import { getColorCssValue } from '../../utils/elementColorStyleUtils';
import { getIsLightIconViewColor } from '../../../../common/colors/colorComparisonUtil';
import { getShowQuickLineTool } from '../../quickLine/quickLineUtil';
import { getMainEditorKey } from '../../utils/elementEditorUtils';
import { isPlatformPhoneOrMobileMode } from '../../../platform/utils/platformDetailsUtils';

// Components
import IconView from '../../../components/displayMode/IconView';
import QuickLineCreationTool from '../../quickLine/QuickLineCreationTool';
import ElementUserTag from '../../../user/userActivity/ElementUserTag';
import ElementPaperIcon from '../../../components/paperIcon/ElementPaperIcon';
import FileIcon from '../../../components/fileIcon/FileIcon';
import EditableTitle from '../../../components/editableTitle/EditableTitle';
import ProgressBar from '../uploader/ProgressBar';

// Constants
import { DEFAULT_ICON_VIEW_WIDTH } from '../../../../common/elements/elementDisplayModeConstants';

// Styles
import './FileIconView.scss';

const FileStatusProgressBar = ({ attachment, element }) => {
    const progress = getFileUploadProgress({ element, attachment });

    return (
        <div className="file-upload-progress">
            <span className="status-text">Saving</span>
            <ProgressBar percentageComplete={progress} barbershop animate />
        </div>
    );
};

FileStatusProgressBar.propTypes = {
    element: PropTypes.object,
    attachment: PropTypes.object,
    fileDownloader: PropTypes.object,
};

const FileIconView = (props) => {
    const {
        element,
        attachment,
        className,
        inList,
        documentMode,
        gridSize,
        elementEvents,
        isEditable,
        isSelected,
        filterQuery,
        currentBoardId,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const fileData = getFileData({ element, attachment });
    const fileUploadProgress = getFileUploadProgress({ element, attachment });

    const elementId = getElementId(element);

    const color = getColor(element);
    const colorCss = getColorCssValue(color);

    const iconTheme = colorCss && (getIsLightIconViewColor(color) ? 'light' : 'dark');

    const editorKey = getMainEditorKey(props);

    return (
        <div
            id={getDomElementId(elementId)}
            className={classNames('FileIconView', className)}
            style={getElementStyle(DEFAULT_ICON_VIEW_WIDTH, gridSize, inList)}
            {...elementEvents}
        >
            <IconView
                iconElement={
                    <ElementPaperIcon
                        className={iconTheme && `background-${iconTheme}`}
                        gridSize={gridSize}
                        inList={inList}
                        documentMode={documentMode}
                        style={{
                            '--ws-document-icon-background-color': colorCss,
                            '--ws-document-icon-fold-color': colorCss,
                        }}
                        iconElement={
                            isNumber(fileUploadProgress) ? (
                                <FileStatusProgressBar element={element} attachment={attachment} />
                            ) : (
                                <FileIcon
                                    fileType={getFileDataType(fileData)}
                                    iconStyle="large"
                                    iconTheme={iconTheme}
                                />
                            )
                        }
                    >
                        <ElementUserTag {...props} />
                        <QuickLineCreationTool
                            show={getShowQuickLineTool(props)}
                            elementId={elementId}
                            element={element}
                            currentBoardId={currentBoardId}
                            gridSize={gridSize}
                            getContextZoomScale={getContextZoomScale}
                            getContextZoomTranslationPx={getContextZoomTranslationPx}
                        />
                    </ElementPaperIcon>
                }
                titleElement={
                    <EditableTitle
                        element={element}
                        elementId={getElementId(element)}
                        isEditable={isEditable}
                        initialValue={getFileDataFilename(fileData)}
                        maxLength={500}
                        selectFirst
                        selectAllOnEdit
                        isSelected={isSelected}
                        filterQuery={filterQuery}
                        editDelay={isPlatformPhoneOrMobileMode(platformSingleton) ? 0 : 100}
                        editorKey={editorKey}
                    />
                }
                secondaryElement={<span className="file-size">{getFileSizeForDisplay(getFileDataSize(fileData))}</span>}
            />
        </div>
    );
};

FileIconView.propTypes = {
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    fileDownloader: PropTypes.object,
    className: PropTypes.string,
    inList: PropTypes.string,
    documentMode: PropTypes.bool,
    gridSize: PropTypes.number,
    isSingleSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    filterQuery: PropTypes.string,
    currentBoardId: PropTypes.string,
    elementEvents: PropTypes.object,
    isElementOpenInModal: PropTypes.bool,
    moveElementsToTrash: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default FileIconView;
