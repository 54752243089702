// Utils
import { TIMES } from '../utils/timeUtil';

// FIXME This is deprecated and should be removed when all clients are using the new notifications system
export const MODIFIED_ELEMENTS_MARK_SEEN = 'MODIFIED_ELEMENTS_MARK_SEEN';

export const MODIFIED_ELEMENTS_MAX = 10;

export const MODIFIED_ELEMENTS_BOARD_MESSAGES = {
    INVITED: 'INVITED',
    UPDATED: 'UPDATED',
    VIEWED: 'VIEWED',
};

export const MODIFIED_ELEMENTS_MESSAGES = {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    VIEWED: 'VIEWED',
};
// FIXME-END This is deprecated and should be removed when all clients are using the new notifications system

export const INBOX_MOVE_MEANINGFUL_DIST = 3;
export const CANVAS_MOVE_MEANINGFUL_DIST_SQRD = Math.pow(34, 2);
export const UPDATE_MEANINGFUL_CHANGE_KEY_LIST = [
    'textContent',
    'caption',
    'showCaption',
    'title',
    'showTitle',
    'image',
    'link',
    'isComplete',
    'assignments',
    'showAssignments',
    'dueDate',
    'tableContent',
];

export const MILANOTE_EMAILS = {
    DEVELOPER: 'developer@milanote.com',
    SUPPORT: 'support@milanote.com',
    INFO: 'info@milanote.com',
};

export const URL_ENCODINGS = {
    NEW_LINE: '%0A',
    TAB: '%09',
};

export const NOTIFICATION_PAGE_SIZE = 30;

export const NOTIFICATION_TYPES = {
    ASSIGNMENT: 'ASSIGNMENT',
    MENTION: 'MENTION',
    SHARE: 'SHARE',
    SHARE_REMINDER: 'SHARE_REMINDER',
    UNSHARE: 'UNSHARE',
    NEW_TEMPLATE: 'NEW_TEMPLATE',
    COMMENT: 'COMMENT',
    REACTION: 'REACTION',
    BOARD_VIEW: 'BOARD_VIEW',
    BOARD_UPDATE: 'BOARD_UPDATE',
    BOARD_TASKS_UPDATE: 'BOARD_TASKS_UPDATE',
    BOARD_TASKS_REMINDER: 'BOARD_TASKS_REMINDER',
    // CLIENT_ONLY
    CLIENT_ONLY_GROUPED_BOARD_VIEW: 'CLIENT_ONLY_GROUPED_BOARD_VIEW',
};

export const ELEMENT_CHANGE_TYPES = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    MOVE_IN: 'MOVE_IN',
    MOVE_OUT: 'MOVE_OUT',
    DELETE: 'DELETE',
    RESTORE: 'RESTORE',
    COMPLETE: 'COMPLETE',
};

export const NOTIFICATIONS_MARK_ELEMENTS_VIEWED = 'NOTIFICATIONS_MARK_ELEMENTS_VIEWED';
export const NOTIFICATIONS_MARK_VIEWED = 'NOTIFICATIONS_MARK_VIEWED';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';
export const NOTIFICATIONS_MARK_ALL_VIEWED = 'NOTIFICATIONS_MARK_ALL_VIEWED';
// Observed means that an activity icon relating to a notification has been observed
export const NOTIFICATIONS_MARK_OBSERVED = 'NOTIFICATIONS_MARK_OBSERVED';

export const NOTIFICATIONS_ADD_OR_UPDATE = 'NOTIFICATIONS_ADD_OR_UPDATE';

export const BOARD_NOTIFICATION_PREFERENCES_LOAD = 'BOARD_NOTIFICATION_PREFERENCES_LOAD';
export const BOARD_NOTIFICATION_PREFERENCES_SET = 'BOARD_NOTIFICATION_PREFERENCES_SET';

// The time after a notification has been observed that it will be considered seen
export const NOTIFICATION_OBSERVED_TO_SEEN_TIME = 2 * TIMES.WEEK;
