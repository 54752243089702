import { Extension } from '@tiptap/core';
import { focus } from './focusCommand';
import { MouseEvent } from 'react';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        focusAtClick: {
            /**
             * Focuses the caret at the position of the provided event.
             */
            focusAtClick: (tap: MouseEvent) => ReturnType;
        };
    }
}

/**
 * Extension to override the builtin focus command so that it doesn't scroll into view by default.
 */
export const Focus = Extension.create({
    name: 'focus',

    addCommands() {
        return {
            // just a thin wrapper around tiptap's builtin focus command, but override the scrollIntoView option
            focus: (position, options) => (context) => {
                focus(position, {
                    scrollIntoView: false,
                    ...options,
                })(context);
                return true;
            },
            focusAtClick: (tap) => (context) => {
                const coords = { left: tap.clientX, top: tap.clientY };
                const tappedPos = context.editor.view.posAtCoords(coords)?.pos || 'end';

                // call the implementation directly; dispatching a command from inside a command is buggy
                focus(tappedPos, { scrollIntoView: false })(context);
                return true;
            },
        };
    },
});
