// Lib
import { isEmpty } from 'lodash';

// Utils
import { pluralise } from '../../../common/utils/stringUtil';
import { isBoardLike, isContent, isDocument, isFile, isTask } from '../../../common/elements/utils/elementTypeUtils';

// Types
import { ElementCountMap } from '../../../common/elements/elementCountsUtils';
import { ImMNElement, MNElement } from '../../../common/elements/elementModelTypes';

type ChildType = 'board' | 'card' | 'file' | 'document';

type ChildTypeCount = { type: ChildType; count: number };
type ChildTypeCountMap = { [key in ChildType]: number };

const CHILD_TYPE_ARRAY = ['board', 'card', 'file', 'document'] as const;

export const getChildrenDescription = (childrenTypes: ChildTypeCountMap, isBoardDuplicating = false): string => {
    // When using the cached element count values, children types will be undefined
    // In this case we want to wait until we have cached counts
    if (!childrenTypes) return '';

    const childrenCount = CHILD_TYPE_ARRAY.reduce((acc, type) => {
        const count = childrenTypes[type] || 0;

        if (count === 0) return acc;

        return [...acc, { type, count }];
    }, [] as ChildTypeCount[]);

    if (isEmpty(childrenCount) && isBoardDuplicating) return 'Duplicating...';
    if (isEmpty(childrenCount)) return '0 cards';

    return childrenCount
        .map(({ type, count }) => {
            let typeDescription: string = type;

            // Shorten document to doc if there are more than 2 children element types
            if (type === 'document' && childrenCount.length > 2) {
                typeDescription = 'doc';
            }

            return `${count} ${pluralise(typeDescription, count)}`;
        })
        .join(', ');
};

export const getChildType = (child: MNElement | ImMNElement | string): ChildType => {
    if (isBoardLike(child)) return 'board';
    if (isDocument(child)) return 'document';
    if (isFile(child)) return 'file';
    return 'card';
};

export const mapElementCountsToChildCounts = (elementCounts: ElementCountMap): ChildTypeCountMap =>
    Object.entries(elementCounts).reduce(
        (acc, [type, count]) => {
            if (!(isContent(type) || isBoardLike(type))) return acc;
            if (isTask(type)) return acc;

            const childType = getChildType(type);

            acc[childType] = (acc[childType] || 0) + count;

            return acc;
        },
        {
            board: 0,
            card: 0,
            file: 0,
            document: 0,
        } as ChildTypeCountMap,
    );
