import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GestureCutIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={55}
        height={60}
        viewBox="0 0 55 60"
        className="Icon gesture-cut"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="M31.663 1v7.356" />
            <path fill="#F37221" fillRule="nonzero" d="m28.534 7.556 3.129 4.742 3.128-4.742z" />
            <text
                fill="#F37221"
                fillRule="nonzero"
                fontFamily="Inter-Bold, Inter"
                fontSize={10}
                fontWeight="bold"
                transform="translate(1 1)"
            >
                <tspan x={40.996} y={24.091}>
                    {'x2'}
                </tspan>
            </text>
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="m1 32.383 5.705 5.722" />
            <path fill="#F37221" fillRule="nonzero" d="m3.962 39.611 5.799 1.56-1.554-5.817z" />
            <path
                fill="#323B4A"
                d="M48.372 43.162c-.127-1.57-1.702-5.22-2.27-6.21-.797-1.393-1.627-1.83-2.627-1.766-.969.06-1.359.614-1.114 1.621.028.124.167.557.376 1.06.004.015.004.029.004.029s-.309-.617-.76-1.284a12.66 12.66 0 0 0-.368-.73c-.926-1.724-1.983-2.228-3.2-2.153-.896.057-1.386.46-1.436 1.191-.191.87-.106 1.947-.085 2.206l-.02-.042-.082-.305c-.575-3.997-1.1-8.004-1.742-11.994-.358-2.223-.72-3.816-1.255-6.007-.256-1.047-.919-1.816-2.068-1.288-.464.213-.699.596-1.046 1.153-.504.808-.568 1.883-.54 2.823.068 2.21.316 4.415.494 6.624.106 1.302.503 7.373.617 8.756-.043.383-.078.646-.078.646s-.33-.791-.497-1.185c-2.021-4.773-4.036-9.547-6.078-14.31-.312-.73-.699-1.44-1.139-2.099-.656-.978-1.51-.911-2.248.018-1.146 1.45-.124 4.997.28 6.298 1.262 4.06 2.55 8.118 3.965 12.125.688 1.95.71 3.905.61 5.898-.046.922-.436 1.27-1.38 1.216-.702-.039-1.4-.199-2.092-.351-1.947-.433-3.887-.897-5.83-1.326-1.245-.277-2.469-.16-3.653.312-.809.322-1.469.826-1.862 1.62-.465.937-.245 1.635.688 2.09.436.212.9.386 1.372.5 4.724 1.134 8.402 3.886 11.498 7.493.23.265.55.641.93 1.078l.173.198c.826.876 4.71 4.256 13.977 2.37 5.855-1.192 7.678-5.537 8.249-8.2.287-1.954.44-3.926.422-5.908a14.881 14.881 0 0 0-.185-2.167z"
            />
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="m15.084 4.766 2.455 6.539" />
            <path fill="#F37221" fillRule="nonzero" d="m14.767 11.48 4.084 3.329.922-5.101z" />
        </g>
    </svg>
);
const Memo = memo(GestureCutIcon);
export default Memo;
