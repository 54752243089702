// Lib
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { stopDefaultAndPropagation } from '../../../../utils/domUtil';

// Components
import Button from '../../../../components/buttons/Button';
import TooltipSource from '../../../../components/tooltips/TooltipSource';

// Hooks
import useTouchBlockedPointerEvent from './useTouchBlockedPointerEvent';

// Constants
import { TooltipPositions } from '../../../../components/tooltips/tooltipConstants';

// Styles
import './DrawingModeToolModeButton.scss';

const DrawingModeToolModeButton = ({ currentToolMode, toolMode, setToolMode, isPenMode, children, name, shortcut }) => {
    const updateToolMode = useCallback(() => setToolMode(toolMode), [toolMode, setToolMode]);
    const onButtonPressHandler = useTouchBlockedPointerEvent(isPenMode, updateToolMode);

    return (
        <TooltipSource
            enabled
            tooltipText={name}
            shortcut={shortcut}
            position={TooltipPositions.BOTTOM}
            delay={700}
            pollPosition
            triggerOnMouseEnter
        >
            <Button
                className={classNames('DrawingModeToolModeButton', { active: currentToolMode === toolMode })}
                onPointerUp={onButtonPressHandler}
                onDoubleClick={stopDefaultAndPropagation}
            >
                {children}
            </Button>
        </TooltipSource>
    );
};

DrawingModeToolModeButton.propTypes = {
    isPenMode: PropTypes.bool,
    currentToolMode: PropTypes.string,
    toolMode: PropTypes.string,
    name: PropTypes.string,
    shortcut: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    setToolMode: PropTypes.func,
};

export default DrawingModeToolModeButton;
