import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NotificationSketchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon notification-sketch"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#CCC"
                d="M0 3.002A1 1 0 0 1 1 2h14c.552 0 1 .456 1 1.002v9.996A1 1 0 0 1 15 14H1c-.552 0-1-.456-1-1.002V3.002Z"
            />
            <path fill="#FFF" d="M1 3h14v10H1z" />
            <path
                fill="#767C86"
                d="m9.485 4.963-1.22.798-.69.458-.542.367-.352.242-.317.222-.293.211-.469.35-.17.133-.15.121-.134.113a5.44 5.44 0 0 0-.352.33 2.38 2.38 0 0 0-.215.252c-.094.127-.151.24-.185.368a.628.628 0 0 0 .411.755c.061.02.114.03.178.038.08.008.147.01.242.004.168-.014.307-.036.503-.08l.176-.043.188-.05.41-.119.57-.182 1.01-.342-.559.489-.206.187-.082.079-.142.148-.051.061a.694.694 0 0 0-.084.131l-.018.042-.019.072-.006.067.002.031.006.043a.396.396 0 0 0 .058.133l.019.025.062.061.062.042c.078.044.15.066.23.076.08.01.139.008.248-.002l.084-.01.098-.017.216-.044.12-.029.808-.202c.23-.055.408-.088.58-.108.19-.022.362-.027.519-.016.175.015.32.048.436.1a.654.654 0 0 1 .4.46l.02.125a.37.37 0 0 0 .383.272c.202-.008.367-.162.357-.355l-.008-.081a1.22 1.22 0 0 0-.065-.26l-.031-.078a1.282 1.282 0 0 0-.133-.234 1.425 1.425 0 0 0-.582-.468c-.2-.092-.433-.15-.706-.176a3.68 3.68 0 0 0-.686.011l-.205.026L9 9.545l-.15.03-.26.059-.196.046.544-.462.26-.231.091-.086.179-.178.068-.077.065-.083a.905.905 0 0 0 .062-.097l.029-.06a.466.466 0 0 0 .035-.147.385.385 0 0 0-.01-.125.361.361 0 0 0-.06-.132.416.416 0 0 0-.145-.133.632.632 0 0 0-.133-.053.688.688 0 0 0-.2-.018l-.084.007c-.058.007-.11.015-.172.029l-.243.06-.272.077-.385.12-1.088.353-.454.143-.268.08-.234.067-.215.057-.252.058.092-.087.112-.1.188-.157.228-.178.36-.267.28-.198.296-.205.325-.22.63-.416.367-.239.585-.376 1.11-.704.042-.03c.204-.166.23-.438.06-.627a.56.56 0 0 0-.703-.082Z"
            />
        </g>
    </svg>
);
const Memo = memo(NotificationSketchIcon);
export default Memo;
