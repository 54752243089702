// Constants
import { EDITOR_STATE_CHANGED } from '../../editorConstants';
import { EDITOR_CONTEXT_SET, EDITOR_CONTEXT_REMOVE } from './editorContextConstants';

// Types
import { AnyAction } from 'redux';
import { RemoveEditorContextAction, SetEditorContextAction } from './editorContextActions';
import { EditorContextState } from './editorContextTypes';

const initialState: EditorContextState = { markdown: {} };
let previousEditorId = '';

const handleEditorContextSet = (state: EditorContextState, action: SetEditorContextAction) => {
    const { contextName, contextKey, context } = action;

    const updatedState = { ...state };
    updatedState[contextName] = {
        ...updatedState[contextName],
        [contextKey]: context,
    };
    return updatedState;
};

const handleEditorContextRemove = (state: EditorContextState, action: RemoveEditorContextAction) => {
    const { contextName, contextKey } = action;

    const updatedState = { ...state };
    const context = updatedState[contextName];
    if (context) {
        const updatedContext = { ...context };
        delete updatedContext[contextKey];
        updatedState[contextName] = { ...updatedContext };
    }
    return updatedState;
};

/**
 * Prevent the context from growing out of hand by removing the context whenever the editor changes.
 */
const handleEditorStateChanged = (state: EditorContextState, action: AnyAction): EditorContextState => {
    const { editorId } = action;

    if (editorId === previousEditorId) return state;

    previousEditorId = editorId;

    return initialState;
};

export default (state = initialState, action: AnyAction): EditorContextState => {
    switch (action.type) {
        case EDITOR_STATE_CHANGED:
            return handleEditorStateChanged(state, action);
        case EDITOR_CONTEXT_SET:
            return handleEditorContextSet(state, action as SetEditorContextAction);
        case EDITOR_CONTEXT_REMOVE:
            return handleEditorContextRemove(state, action as RemoveEditorContextAction);
        default:
            return state;
    }
};
