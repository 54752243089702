// Lib
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose, withHandlers } from '../../../node_module_clones/recompose';

// Components
import BoardChildrenLoadObserver from './BoardChildrenLoadObserver';
import Board from './Board';

// Selectors
import { createElementUserPermissionsSelector } from '../../utils/permissions/elementPermissionsSelector';

// Actions
import { navigateToBoard as navigateToBoardAction } from '../../reducers/navigationActions';
import { initializeBoard } from './boardActions';
import { updateElement } from '../actions/elementActions';

// Util
import {
    getColor,
    getElementId,
    getIcon,
    getSecondaryColor,
    getTitle,
} from '../../../common/elements/utils/elementPropertyUtils';
import { hasCommandModifier } from '../../utils/keyboard/keyboardUtility';

// Constants
import { BOARD_DEFAULT_TITLE } from '../../../common/boards/boardConstants';

const makeBoardSelector = () =>
    createSelector(createElementUserPermissionsSelector(), (elementPermissions) => ({ elementPermissions }));

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigateToBoard: (boardId, keepSelection, newTab) =>
        dispatch(
            navigateToBoardAction({
                boardId,
                keepSelection,
                newTab,
            }),
        ),
    dispatchInitializeBoard: (id, title, currentColor, currentSecondaryColor, transactionId) =>
        dispatch(
            initializeBoard({
                id,
                title,
                currentColor,
                currentSecondaryColor,
                transactionId,
            }),
        ),
    dispatchUpdateTitle: (id, title, transactionId) =>
        dispatch(
            updateElement({
                id,
                changes: {
                    title,
                },
                transactionId,
            }),
        ),
});

const isUpdatingDefaultTitle = (currentTitle, updatingTitle) =>
    currentTitle === BOARD_DEFAULT_TITLE && updatingTitle !== BOARD_DEFAULT_TITLE;

const enhance = compose(
    connect(makeBoardSelector, mapDispatchToProps),
    withHandlers({
        dispatchNavigateToBoard: (props) => props.navigateToAlias || props.dispatchNavigateToBoard,
    }),
);

const BoardContainer = React.memo(function BoardContainer(props) {
    const {
        isEditing,
        element,
        startEditing,
        dispatchUpdateTitle,
        dispatchInitializeBoard,
        forceMeasure,
        elementEvents,
        dispatchNavigateToBoard,
        documentMode,
    } = props;

    const elementId = getElementId(element);

    const onHeadingClick = useCallback(
        (event) => {
            event.stopPropagation();
            if (isEditing) return;

            startEditing({ id: elementId });
        },
        [isEditing, startEditing, elementId],
    );

    const onTitleEditDone = useCallback(
        ({ title, transactionId }) => {
            const icon = getIcon(element);

            // When the title changes we want to re-measure the element so that we update the
            // stored size of the title
            forceMeasure && forceMeasure();

            // Initialize board if no icon
            const currentTitle = getTitle(element);
            const currentColor = getColor(element);
            const currentSecondaryColor = getSecondaryColor(element);
            if (!icon && isUpdatingDefaultTitle(currentTitle, title)) {
                return dispatchInitializeBoard(elementId, title, currentColor, currentSecondaryColor, transactionId);
            }

            if (title === currentTitle) return;
            dispatchUpdateTitle(elementId, title, transactionId);
        },
        [element, dispatchUpdateTitle, dispatchInitializeBoard, forceMeasure],
    );

    const navigateToBoard = useCallback(
        (event) => dispatchNavigateToBoard(elementId, false, hasCommandModifier(event)),
        [dispatchNavigateToBoard, elementId],
    );

    const boardElementEvents = useMemo(
        () => ({
            ...elementEvents,
            onDoubleClick(event) {
                navigateToBoard(event);
                elementEvents.onDoubleClick(event);
            },
            onClick(event) {
                if (documentMode) {
                    event && event.stopPropagation();
                    return dispatchNavigateToBoard(elementId);
                }

                return elementEvents.onClick(event);
            },
        }),
        [elementEvents, dispatchNavigateToBoard, documentMode, elementId],
    );

    return (
        <BoardChildrenLoadObserver {...props}>
            <Board
                {...props}
                elementId={elementId}
                elementEvents={boardElementEvents}
                navigateToBoard={navigateToBoard}
                onHeadingClick={onHeadingClick}
                onTitleEditDone={onTitleEditDone}
            />
        </BoardChildrenLoadObserver>
    );
});

BoardContainer.propTypes = {
    element: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    documentMode: PropTypes.bool,
    dispatchUpdateTitle: PropTypes.func,
    dispatchInitializeBoard: PropTypes.func,
    dispatchNavigateToBoard: PropTypes.func,
    navigateToAlias: PropTypes.func,
    startEditing: PropTypes.func,
    isHovered: PropTypes.bool,
    elementEvents: PropTypes.object,
    isAlias: PropTypes.bool,

    setParentHoveredChildAcceptsDrop: PropTypes.func,
    forceMeasure: PropTypes.func,
};

export default enhance(BoardContainer);
