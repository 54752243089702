// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getMedia } from '../../../../../common/elements/utils/elementPropertyUtils';

// Components
import IFrameVideoPlayer from './IFrameVideoPlayer';

const VideoPlayer = (props) => {
    const {
        element,
        dispatchRichMediaReady,
        dispatchRichMediaReturnToPreview,
        dispatchRichMediaError,
        show = false,
    } = props;

    if (!show) return null;

    const videoData = getMedia(element);

    // At this stage we're only supporting Embedly videos
    return (
        <div className="VideoPlayer">
            <IFrameVideoPlayer
                videoData={videoData}
                dispatchRichMediaError={dispatchRichMediaError}
                dispatchRichMediaReturnToPreview={dispatchRichMediaReturnToPreview}
                dispatchRichMediaReady={dispatchRichMediaReady}
            />
        </div>
    );
};

VideoPlayer.propTypes = {
    element: PropTypes.object.isRequired,
    show: PropTypes.bool,
    render: PropTypes.bool,
    dispatchRichMediaReady: PropTypes.func,
    dispatchRichMediaReturnToPreview: PropTypes.func,
    dispatchRichMediaError: PropTypes.func,
};

export default VideoPlayer;
