// Lib
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { get, isEmpty, isEqual } from 'lodash';
import { ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import classNames from 'classnames';

// Components
import TableCellEditor from '../../../components/editor/tableCell/TableCellEditor';

// Utils
import {
    getTableContentCellTextStyle,
    getTableContentCellVerticalAlignment,
} from '../../../../common/elements/utils/elementPropertyUtils';
import {
    getBackgroundColorClass,
    getCellTextAlignment,
    getCellLevelTextStyles,
    getLocalisedContent,
    getCellEditingAlignment,
} from '../utils/tableCellFormattingUtils';
import { getCellVerticalAlignmentClassName } from '../utils/tableDOMUtils';
import applyInlineStylesThroughoutContent from '../../../components/editor/customRichUtils/applyInlineStylesThroughoutContent';
import { isFormula } from '../utils/tableFormulaUtils';
import { getTableCellEditorId } from '../utils/tableCellEditingUtils';

// Constants
import { TABLE_CELL_EDITOR_KEY } from '../../../../common/table/tableConstants';
import { CellTypeNames, TextAlignment } from '../../../../common/table/CellTypeConstants';
import { parseCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';

const MilanoteCellEditorComponent = (props) => {
    const {
        element,
        elementId,
        isEditable,
        currentlyEditingCellEditorCoords,
        tableContentData,
        currentlyEditingEditorId,
        hotTableContainerRef,
        hotTableInstanceRef,
        cellEditorRef,
        locale,
    } = props;

    const verticalAlignment =
        currentlyEditingCellEditorCoords &&
        getTableContentCellVerticalAlignment(currentlyEditingCellEditorCoords)(element);
    const initialTextStyle =
        currentlyEditingCellEditorCoords && getTableContentCellTextStyle(currentlyEditingCellEditorCoords)(element);

    const [textAlignment, setTextAlignment] = useState(null);
    const [textStyle, setTextStyle] = useState(null);

    // Set initial text alignment and styles
    useEffect(() => {
        if (!currentlyEditingCellEditorCoords) return;

        setTextStyle(Array.from(initialTextStyle || []));

        const { row, col } = currentlyEditingCellEditorCoords;
        const cellData = get(tableContentData, [row, col]);

        // For the editor only, we want to ensure that date types are right aligned
        // Even if the initial value is text, the datePicker will be opened with the current date
        const isDateTimeString = !isFormula(cellData?.value) && cellData?.type?.name === CellTypeNames.DATE_TIME;
        const editorTextAlignment = isDateTimeString ? TextAlignment.RIGHT : getCellTextAlignment(cellData, locale);

        setTextAlignment(editorTextAlignment);
    }, [currentlyEditingCellEditorCoords, locale]);

    const textContentInCurrentSelection = useMemo(() => {
        // IMPORTANT: Only update the textContent when user starts editing. This is to prevent a bug where text from
        // previously edited cell is still present in the cell editor when editing a different empty cell.
        // - MilanoteEditor has some optimisations that only updates its internal editorState state when isCurrentlyEditingTableCell
        //   is toggled.
        if (!currentlyEditingCellEditorCoords) return;

        const { row, col } = currentlyEditingCellEditorCoords;

        const cellData = get(tableContentData, [row, col]);
        const { value: cellValue, type } = cellData || {};

        if (cellValue === null && initialTextStyle === null) return null;

        const content = parseCellContentString(cellValue || '');
        const localisedContent = getLocalisedContent(content, type?.name, locale);

        if (isEmpty(initialTextStyle) || isFormula(cellValue || '')) return localisedContent;

        let contentState =
            typeof localisedContent === 'object'
                ? convertFromRaw(localisedContent)
                : ContentState.createFromText(localisedContent.toString());

        contentState = applyInlineStylesThroughoutContent(contentState, initialTextStyle);

        return convertToRaw(contentState);
    }, [currentlyEditingCellEditorCoords, initialTextStyle, locale]);

    const backgroundClasses = useMemo(() => {
        if (!currentlyEditingCellEditorCoords) return;

        const { row, col } = currentlyEditingCellEditorCoords;
        const cellData = get(tableContentData, [row, col]);
        return getBackgroundColorClass(cellData);
    }, [currentlyEditingCellEditorCoords]);

    const onChange = useCallback(
        ({ editorState }) => {
            if (!currentlyEditingCellEditorCoords) return;

            const { row, col } = currentlyEditingCellEditorCoords;

            const cellData = get(tableContentData, [row, col]);
            const currentContent = editorState.getCurrentContent();
            const editCellValue = currentContent.getPlainText();

            // check whether alignment should be updated
            const updateAlignment = getCellEditingAlignment(editCellValue, cellData, locale);
            if (updateAlignment) setTextAlignment(updateAlignment);

            // update the text style if the user has changed the inline styles
            if (editCellValue.length > 0) {
                const cellLevelTextStyles = currentContent ? getCellLevelTextStyles(currentContent) : [];
                if (!isEqual(cellLevelTextStyles.sort(), textStyle?.sort() || [])) {
                    setTextStyle(cellLevelTextStyles);
                }
            }

            hotTableInstanceRef.current?.runHooks('onMilanoteCellEditorChange', editorState);
        },
        [currentlyEditingCellEditorCoords, textStyle, locale],
    );

    if (!hotTableContainerRef.current) return null;

    return createPortal(
        <div className={classNames('MilanoteCellEditorComponent', backgroundClasses)}>
            <div className="MilanoteCellEditorComponent-relative">
                <div className="handsontable-input-positioner">
                    <div
                        className={classNames(
                            'handsontable-input-holder',
                            getCellVerticalAlignmentClassName(verticalAlignment),
                        )}
                    >
                        <TableCellEditor
                            editorRef={cellEditorRef}
                            className="handsontable-input"
                            element={element}
                            isEditing={!!currentlyEditingCellEditorCoords}
                            isEditable={isEditable}
                            textContent={textContentInCurrentSelection}
                            defaultStyleOverride={textStyle}
                            textAlignment={textAlignment}
                            editorId={getTableCellEditorId(elementId)}
                            editorKey={TABLE_CELL_EDITOR_KEY}
                            currentEditorId={currentlyEditingEditorId}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </div>,
        hotTableContainerRef.current,
    );
};

MilanoteCellEditorComponent.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    tableContentData: PropTypes.array,
    isEditable: PropTypes.bool,
    currentlyEditingCellEditorCoords: PropTypes.object,
    currentlyEditingEditorId: PropTypes.string,
    currentlyEditingEditorKey: PropTypes.string,
    textContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    locale: PropTypes.string,

    hotTableContainerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    hotTableInstanceRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    cellEditorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    currentCellSelections: PropTypes.object,
};

export default MilanoteCellEditorComponent;
