// Lib
import { head, uniq } from 'lodash';
import { slugify } from '../../../common/utils/stringUtil';

// Constants
import { STRING_ICON_MAX_LENGTH } from './elementIconConstants';
import { BOARD_DEFAULT_TITLE } from '../../../common/boards/boardConstants';
import { ICON_POPUP_WORD_FILTERS } from '../../workspace/toolbar/components/selectionTools/iconTools/popup/iconsPopupConstants';

// Helper functions
const isUselessWord = (word) => ICON_POPUP_WORD_FILTERS.indexOf(word.toLowerCase()) === -1;

const getTokenizedWords = (string) =>
    slugify(string, '-')
        .split('-')
        .filter((word) => word !== '');

export const filterWords =
    (allowNumbers = false) =>
    (word) =>
        (allowNumbers || isNaN(word)) && isUselessWord(word);

export const removeSpecialCharacters = (word) => word.replace(/[.,\/"'\^&\*;:{}=\-_`~()]/g, '');

const extractFirstCharacter = (word) => word[0];

const getTokenizedLetters = (wordList) =>
    wordList.length === 1 ? wordList[0].split('') : wordList.map(extractFirstCharacter);

const getAcronymCombinations = (letters) => {
    const combinations = [];
    const limit = Math.min(STRING_ICON_MAX_LENGTH, letters.length);
    for (let i = 0; i < limit; i++) {
        combinations.push(letters.slice(0, limit - i).join(''));
    }
    // Include all letters
    letters.forEach((letter) => combinations.push(letter));
    return uniq(combinations);
};

export const getAcronyms = (string) => {
    if (!string || string === BOARD_DEFAULT_TITLE) return [];
    let titleWords = getTokenizedWords(string);
    if (!titleWords.length) return [];
    if (titleWords.length > 2) titleWords = titleWords.filter(filterWords(true));
    const titleLetters = getTokenizedLetters(titleWords);
    return getAcronymCombinations(titleLetters);
};

export const getFirstIconString = (title) => head(getAcronyms(title));
