import { func, object, shape, string } from 'prop-types';

export const routerShape = shape({
    push: func.isRequired,
    replace: func.isRequired,
    go: func.isRequired,
    goBack: func.isRequired,
    goForward: func.isRequired,
    setRouteLeaveHook: func.isRequired,
    isActive: func.isRequired,
});

export const locationShape = shape({
    pathname: string.isRequired,
    search: string.isRequired,
    state: object,
    action: string.isRequired,
    key: string,
});
