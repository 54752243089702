import { memoize } from 'lodash';
import { ClientConfig } from '../../common/config/configTypes';

declare global {
    // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
    var __clientconf: ClientConfig | undefined;

    interface Window {
        __clientconf: ClientConfig | undefined;
    }
}

export default memoize((): Partial<ClientConfig> => {
    if (typeof window !== 'undefined') return window.__clientconf || {};
    if (typeof global !== 'undefined') return global.__clientconf || {};
    throw new Error('Could not load client config from window or global');
});
