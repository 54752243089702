// Utils
import { enterFullscreen, exitFullscreen } from '../../utils/browserUtils';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';

// Constants
import {
    PRESENTATION_MODE_START,
    PRESENTATION_MODE_END,
    PRESENTATION_MODE_SETTINGS_UPDATE,
    FOCUS_MODE_ACTIVE,
    FOCUS_MODE_APPEARING,
    FOCUS_MODE_DISAPPEARING,
    FOCUS_MODE_INACTIVE,
    FOCUS_MODE_ELEMENT_CLICKED,
} from './presentationConstants';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';

export const startPresentationMode =
    ({ hideComments, disableEditing, isFocusModeAllowed }) =>
    async (dispatch) => {
        dispatch({
            type: PRESENTATION_MODE_START,
        });
        dispatch(
            updatePresentationModeSettings({
                type: PRESENTATION_MODE_SETTINGS_UPDATE,
                hideComments,
                disableEditing,
                isFocusModeAllowed,
            }),
        );
        enterFullscreen();
        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.ENTERED_PRESENTATION_MODE,
        });
    };

export const updatePresentationModeSettings =
    ({ hideComments, disableEditing, isFocusModeAllowed }) =>
    async (dispatch) => {
        dispatch({
            type: PRESENTATION_MODE_SETTINGS_UPDATE,
            hideComments,
            disableEditing,
            isFocusModeAllowed,
        });
        if (hideComments) {
            sendAmplitudeEvent({
                eventType: EVENT_TYPE_NAMES.PRESENTATION_MODE_HIDE_COMMENTS_ENABLED,
            });
        }

        if (disableEditing) {
            sendAmplitudeEvent({
                eventType: EVENT_TYPE_NAMES.PRESENTATION_MODE_PREVENT_EDITING_ENABLED,
            });
        }
    };

export const endPresentationMode = () => async (dispatch) => {
    dispatch({
        type: PRESENTATION_MODE_END,
    });
    exitFullscreen();
};

/**
 * "WILL_START" and "DID_START", and "WILL_END" and "DID_END" actions are provided so that
 * native clients are able to sync the animations to be performed on their respective platforms.
 *
 * This alone could be achieved by providing a "WILL_END", for example. i.e. When the "WILL_END"
 * fires, native clients know to begin their animations, matching the timing of those in the web.
 *
 * Providing additional timing information (such as the "DID_END") provides clients with the
 * guarantee of the web's state. This way, clients know that the web's state has been updated
 * (reducers etc.) and "ready".
 */

export const focusElementAppearing = (elementId, initialZoomScale, elementProps) => ({
    type: FOCUS_MODE_APPEARING,
    elementId,
    initialZoomScale,
    elementProps,
});

export const focusElementActive = () => ({ type: FOCUS_MODE_ACTIVE });

export const focusElementDisappearing = () => ({ type: FOCUS_MODE_DISAPPEARING });
export const focusElementInactive = () => ({ type: FOCUS_MODE_INACTIVE });

export const focusedElementClicked = (elementId) => ({
    type: FOCUS_MODE_ELEMENT_CLICKED,
    elementId,
});
