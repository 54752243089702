import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProgressBar from '../../element/file/uploader/ProgressBar';

import useStalledBoolean from '../../utils/react/useStalledBoolean';
import { getFileSizeComparisonString } from '../../../common/files/fileSizeUtils';
import { triggerDownload } from '../../element/attachments/attachmentDownloadActions';
import { getAttachmentProgress } from '../../element/attachments/attachmentsSelector';
import { trackAttachmentDownload } from '../../element/attachments/attachmentsService';

import './DownloadButton.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchTriggerDownload: (args) => dispatch(triggerDownload(args)),
});

const mapStateToProps = getAttachmentProgress;

const DownloadButton = ({
    children,
    url,
    filename,
    onDownloadStart,
    onDownloadEnd,
    dispatchTriggerDownload,
    elementId,
    total,
    loaded,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const onDownload = async (event) => {
        event && event.preventDefault();
        event && event.stopPropagation();

        trackAttachmentDownload({ url });

        dispatchTriggerDownload({
            url,
            filename,
            onDownloadStart: () => {
                setIsDownloading(true);
                onDownloadStart && onDownloadStart();
            },
            onDownloadEnd: () => {
                setIsDownloading(false);
                onDownloadEnd && onDownloadEnd();
            },
            id: elementId,
        });
    };

    const isDownloadingOverride = useStalledBoolean(isDownloading, 1500);
    const maxTotalRef = useRef(total);

    useEffect(() => {
        if (total > maxTotalRef.current) {
            maxTotalRef.current = total;
        }
    }, [total]);

    const visibleTotal = isDownloadingOverride && !isDownloading ? maxTotalRef.current : total;
    const visibleLoaded = isDownloadingOverride && !isDownloading ? maxTotalRef.current : loaded;

    return (
        <span className="DownloadButton">
            {isDownloading || isDownloadingOverride ? (
                <div className="downloading">
                    <ProgressBar barbershop animate percentageComplete={(visibleLoaded / visibleTotal) * 100} />
                    <span className="progress-message">
                        {getFileSizeComparisonString(visibleLoaded, visibleTotal, true)}
                    </span>
                </div>
            ) : (
                <a className="download-button" onClick={onDownload} href={url}>
                    {children}
                </a>
            )}
        </span>
    );
};

DownloadButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    url: PropTypes.string,
    filename: PropTypes.string,
    elementId: PropTypes.string,
    onDownloadStart: PropTypes.func,
    onDownloadEnd: PropTypes.func,
    dispatchTriggerDownload: PropTypes.func,
    total: PropTypes.number,
    loaded: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
