// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import liveCursor from '../cursorTracking/liveCursorReducer';
import liveDrawing from '../../../element/drawing/store/liveDrawingReducer';

export default combineReducers({
    liveCursor,
    liveDrawing,
});
