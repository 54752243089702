// Utils
import { safeObjectIdToString } from '../../utils/objectIdUtil';

// Property accessors
import { getModifiedBy, getCreatorId } from './elementPropertyUtils';

// Constants
import { STARTER_CONTENT_CREATOR_ID } from '../../users/userConstants';

export const isElementLastModifiedByUser = (userId) => (element) =>
    safeObjectIdToString(getModifiedBy(element)) === safeObjectIdToString(userId);
export const isElementCreatedByUser = (userId) => (element) =>
    safeObjectIdToString(getCreatorId(element)) === safeObjectIdToString(userId);
export const isElementRealContent = (element) => !isElementCreatedByUser(STARTER_CONTENT_CREATOR_ID)(element);
