// Lib
import * as Immutable from 'immutable';
import { isEmpty } from 'lodash/fp';

// Utils
import {
    getBoardId,
    getPermissionId,
    getPermissionValue,
} from '../../../server/app/components/permissions/permissionPropertyUtil';
import { isAlias } from '../../../common/elements/utils/elementTypeUtils';
import { getAliasPermissionId, getLinkedElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { canRead, canSave } from '../../../common/permissions/permissionUtil';

// Constants
import { BOARD_PERMISSION_LOAD, PERMISSION_ID_ASSOCIATE_TO_BOARD, PERMISSION_TOKEN_SET } from './permissionsConstants';
import { CURRENT_USER_SET } from '../../user/currentUserConstants';
import { ELEMENT_SET_TYPE } from '../../../common/elements/elementConstants';
import { ELEMENTS_LOAD } from '../../../common/elements/elementsConstants';

const initialState = Immutable.fromJS({
    permissionIds: [],
    aclIds: [],
    boardIdPermissionIdMap: {},
    boardIdEditPermissionIdMap: {},
    boardIdPublishPermissionIdMap: {},
    permissionIdTokenIndexMap: {},
    tokens: [],
});

const handlePermissionTokenSet = (state, action) =>
    state.withMutations((mutableState) => {
        action.token && mutableState.update('tokens', (tokens) => tokens.concat(action.token));

        const addedTokenIndex = mutableState.get('tokens').size - 1;

        if (!isEmpty(action.permissionIds)) {
            mutableState.update('permissionIds', (permissionIds) => permissionIds.concat(action.permissionIds));

            // Do this here as well, just in case he permissions aren't provided
            // (e.g. because a token is set on the URL)
            action.permissionIds.forEach((permissionId) => {
                mutableState.setIn(['permissionIdTokenIndexMap', permissionId], addedTokenIndex);
            });
        }

        if (!isEmpty(action.aclIds)) {
            mutableState.update('aclIds', (aclIds) => aclIds.concat(action.aclIds));
        }

        if (action.token && !isEmpty(action.permissions)) {
            action.permissions.forEach((permissionEntry) => {
                const boardId = getBoardId(permissionEntry);
                const permissionId = getPermissionId(permissionEntry);

                if (permissionId) {
                    mutableState.setIn(['permissionIdTokenIndexMap', permissionId], addedTokenIndex);
                }

                if (boardId && permissionId) {
                    mutableState.setIn(['boardIdPermissionIdMap', boardId], permissionId);
                }
            });
        }

        return mutableState;
    });

/**
 * Keep track of the permission ID for the last viewed board.
 */
const handleCurrentUserSet = (state, action) => {
    const lastViewedBoardId = action?.user?.lastViewedBoard;
    const lastViewedBoardPermissionId = action?.user?.lastViewedBoardPermissionId;

    if (!lastViewedBoardPermissionId || !lastViewedBoardId) return state;

    const existingPermissionId = state.getIn(['boardIdPermissionIdMap', lastViewedBoardId]);

    if (existingPermissionId) return state;

    return state.setIn(['boardIdPermissionIdMap', lastViewedBoardId], lastViewedBoardPermissionId);
};

/**
 * When an alias is created from a link, ensure the permission ID gets associated to the linked board.
 */
const handleElementSetType = (state, action) => {
    const { elementType } = action;

    if (!isAlias(elementType)) return state;

    const permissionId = action?.changes?.permissionId;

    if (!permissionId) return state;

    const boardId = action?.changes?.linkTo;

    if (!boardId) return state;

    return state.setIn(['boardIdPermissionIdMap', boardId], permissionId);
};

/**
 * For any aliases that are loaded, associate their permission ID to their linked board ID.
 */
const handleElementsLoad = (state, action) => {
    const elements = Object.values(action.elements || {});

    return state.withMutations((mutableState) => {
        elements.forEach((element) => {
            if (!isAlias(element)) return;

            const permissionId = getAliasPermissionId(element);

            if (!permissionId) return;

            const boardId = getLinkedElementId(element);

            mutableState.setIn(['boardIdPermissionIdMap', boardId], permissionId);
        });
    });
};

const handleBoardPermissionLoad = (state, action) => {
    const { permissions } = action;

    if (!permissions) return state;

    return state.withMutations((mutableState) => {
        permissions.forEach((permissionEntry) => {
            const permissionId = getPermissionId(permissionEntry);
            const permissionValue = getPermissionValue(permissionEntry);
            const boardId = getBoardId(permissionEntry);

            if (canSave(permissionValue)) {
                mutableState.setIn(['boardIdEditPermissionIdMap', boardId], permissionId);
            } else if (canRead(permissionValue)) {
                mutableState.setIn(['boardIdPublishPermissionIdMap', boardId], permissionId);
            }
        });

        return mutableState;
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PERMISSION_ID_ASSOCIATE_TO_BOARD:
            return state.setIn(['boardIdPermissionIdMap', action.boardId], action.permissionId);
        case ELEMENT_SET_TYPE:
            return handleElementSetType(state, action);
        case ELEMENTS_LOAD:
            return handleElementsLoad(state, action);
        case PERMISSION_TOKEN_SET:
            return handlePermissionTokenSet(state, action);
        case CURRENT_USER_SET:
            return handleCurrentUserSet(state, action);
        case BOARD_PERMISSION_LOAD:
            return handleBoardPermissionLoad(state, action);
        default:
            return state;
    }
};
