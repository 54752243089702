// Lib
import { mergeWith, sum } from 'lodash';

// Utils
import { getElementType } from './utils/elementPropertyUtils';
import { isBoardLike, isContent } from './utils/elementTypeUtils';

// Types
import { MNElement, MNElementSummary } from './elementModelTypes';
import { ElementType } from './elementTypes';

export type ElementCountMap = { [key in ElementType]?: number };

export type ReduceTypeChange = ElementType | Partial<MNElement> | MNElementSummary;

const add = (a = 0, b = 0) => a + b;

export const mergeElementCountMaps = (countMapA: ElementCountMap, countMapB: ElementCountMap): ElementCountMap =>
    mergeWith(countMapA, countMapB, add);

// ----------------------
// Turn an array of [{ elementType: 'BOARD' }, { elementType: 'BOARD' }, { elementType: 'CARD' }, { elementType: 'LINK' }]
// into a map of { BOARD: 2, CARD: 1, LINK: 1 }
// ----------------------
export const reduceTypeCounts = (acc: ElementCountMap, change: ReduceTypeChange): ElementCountMap => {
    const type: ElementType = getElementType(change);
    const count = acc[type] || 0;
    acc[type] = count + 1;
    return acc;
};

type ReduceContentCountsType = { [key in 'board' | 'card']: number };
/**
 * This is used for emails, thus changes all types to either 'board' or 'card'.
 */
export const reduceContentCounts = (
    acc: ReduceContentCountsType,
    change: ReduceTypeChange,
): ReduceContentCountsType => {
    if (!isBoardLike(change) && !isContent(change)) return acc;

    const type = isBoardLike(change) ? 'board' : 'card';
    const count = acc[type] || 0;
    acc[type] = count + 1;
    return acc;
};

/**
 * Instead of showing "Aliases" in a count map for render, just treat aliases as boards.
 */
export const convertElementCountMapForRender = (countMap: ElementCountMap): ElementCountMap => {
    if (!countMap) return countMap;

    const returnCountMap = {
        ...countMap,
    };

    delete returnCountMap[ElementType.ALIAS_TYPE];
    const boardCount = (countMap[ElementType.BOARD_TYPE] || 0) + (countMap[ElementType.ALIAS_TYPE] || 0);

    if (boardCount > 0) {
        returnCountMap[ElementType.BOARD_TYPE] = boardCount;
    }

    return returnCountMap;
};

/**
 * Sums all the counts in a count map.
 */
export const getElementCountMapTotalElementCount = (countMap: ElementCountMap): number => {
    const values = Object.values(countMap);
    return sum(values) || 0;
};

/**
 * Sums the counts for content element types in a count map.
 */
export const getElementCountMapTotalContentCount = (countMap: ElementCountMap): number => {
    let total = 0;
    let elementType: ElementType;
    for (elementType in countMap) {
        if (!isContent(elementType)) continue;

        total += countMap[elementType] || 0;
    }

    return total;
};
