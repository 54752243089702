import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarUploadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-upload"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#323B4A"
                d="M18.586 6a1 1 0 0 1 .707.293l4.414 4.414a1 1 0 0 1 .293.707V24a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h8.586zM17 7.5h-7a.5.5 0 0 0-.5.5v16a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V13h-4.75a.75.75 0 0 1-.743-.648L17 12.25V7.5zm5.379 4L18.5 7.622V11.5h3.879z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarUploadIcon);
export default Memo;
