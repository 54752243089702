// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { hasCommandModifier, hasShiftKey } from '../../utils/keyboard/keyboardUtility';

// Constants
import { KEY_CODES } from '../../utils/keyboard/keyConstants';

export const LIST_DIRECTIONS = {
    HORIZONTAL: 'HORIZONTAL',
    VERTICAL: 'VERTICAL',
};

export default ({ listDirection = LIST_DIRECTIONS.VERTICAL }) =>
    (DecoratedComponent) => {
        class ListKeyboardManager extends React.Component {
            constructor(props) {
                super(props);
                this.keyboardInputRef = props.inputRef || React.createRef();
            }

            componentDidMount() {
                this.listenerRef =
                    this.keyboardInputRef && this.keyboardInputRef.current ? this.keyboardInputRef.current : document;

                this.listenerRef.addEventListener('keydown', this.handleKeyDown);
            }

            componentWillUnmount() {
                if (!this.listenerRef) return;

                this.listenerRef.removeEventListener('keydown', this.handleKeyDown);
            }

            handleKeyDown = (event) => {
                const { moveListFocusDown, moveListFocusUp, onSubmit, onClose, onDelete } = this.props;

                switch (event.keyCode) {
                    case KEY_CODES.TAB:
                        return this.onTab(event);
                    case KEY_CODES.DOWN_ARROW: {
                        if (listDirection === LIST_DIRECTIONS.HORIZONTAL) return;
                        event.preventDefault();
                        return moveListFocusDown && moveListFocusDown();
                    }
                    case KEY_CODES.UP_ARROW: {
                        if (listDirection === LIST_DIRECTIONS.HORIZONTAL) return;
                        event.preventDefault();
                        return moveListFocusUp && moveListFocusUp();
                    }
                    case KEY_CODES.RIGHT_ARROW: {
                        if (listDirection === LIST_DIRECTIONS.VERTICAL) return;
                        event.preventDefault();
                        return moveListFocusDown && moveListFocusDown();
                    }
                    case KEY_CODES.LEFT_ARROW: {
                        if (listDirection === LIST_DIRECTIONS.VERTICAL) return;
                        event.preventDefault();
                        return moveListFocusUp && moveListFocusUp();
                    }
                    case KEY_CODES.ESC:
                        return onClose && onClose(event);
                    case KEY_CODES.ENTER:
                        return onSubmit && onSubmit(event);
                    case KEY_CODES.DELETE:
                    case KEY_CODES.BACKSPACE:
                        return onDelete && onDelete(event);
                    default:
                        return this.onDigit(event);
                }
            };

            onDigit = (event) => {
                const { keyCode } = event;
                const { onDigit } = this.props;

                // Not a digit
                if (keyCode < KEY_CODES[0] || keyCode > KEY_CODES[9]) return;

                if (hasCommandModifier(event)) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                const digit = keyCode - KEY_CODES[0];

                return onDigit && onDigit({ digit, event });
            };

            onTab = (event) => {
                const { moveListFocusDown, moveListFocusUp } = this.props;
                event.preventDefault();
                return hasShiftKey(event) ? moveListFocusUp() : moveListFocusDown();
            };

            render() {
                return <DecoratedComponent {...this.props} keyboardInputRef={this.keyboardInputRef} />;
            }
        }

        ListKeyboardManager.propTypes = {
            moveListFocusDown: PropTypes.func,
            moveListFocusUp: PropTypes.func,
            onDigit: PropTypes.func,
            onSubmit: PropTypes.func,
            onClose: PropTypes.func,
            onDelete: PropTypes.func,
            onSelectIndex: PropTypes.func,
            inputRef: PropTypes.object,
        };

        return ListKeyboardManager;
    };
