// Lib
import { useMemo } from 'react';
import Document from '@tiptap/extension-document';

// Custom extensions
import { TextAlign } from '../../../../common/tiptap/extensions/TextAlign';
import { InlineStyleClearer } from '../../../../common/tiptap/extensions/InlineStyleClearer';
import { MentionsSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';

// Types
import { Extensions } from '@tiptap/core';
import useCommonTiptapEditorExtensions from '../../../../common/tiptap/hooks/useCommonTiptapEditorExtensions';

type UseCaptionTiptapEditorExtensionsArgs = {
    placeholder: string | undefined;
    onEmptyBackspace: (() => void) | undefined;
    saveCaptionContent: (content: object) => void;
    suggestionsManager: MentionsSuggestionsManager;
    isEditable?: boolean;
    dispatch?: Function;
};

/**
 * The extensions required to make the caption Tiptap editors work.
 */
const useCaptionTiptapEditorExtensions = ({
    placeholder,
    onEmptyBackspace,
    saveCaptionContent,
    suggestionsManager,
    isEditable,
    dispatch,
}: UseCaptionTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useCommonTiptapEditorExtensions({
        saveContent: saveCaptionContent,
        onEmptyBackspace,
        placeholder,
        suggestionsManager,
        isEditable,
        dispatch,
    });

    return useMemo(
        () => [
            Document,
            // Allows text to be centred
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/textalign
            TextAlign,
            // Clears inline styles when the user presses Enter
            InlineStyleClearer,
            ...commonExtensions,
        ],
        [commonExtensions],
    );
};

export default useCaptionTiptapEditorExtensions;
