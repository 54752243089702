// Lib
import { get } from 'lodash/fp';

// Constants
import { ELEMENT_CREATE, ELEMENT_MOVE_MULTI, ELEMENT_UPDATE } from '../../../common/elements/elementConstants';
import { CLIPPER_OPERATION_COMPLETE } from '../../element/card/clipper/store/clipperConstants';

export const getCurrentOperation = (state) => state.getIn(['undoRedo', 'operation']);
export const getMostRecentTransactionId = (state) => state.getIn(['undoRedo', 'mostRecentTransactionId']);

const getRedoActionType = get(['redo', 'type']);
const getNextTransactionWithActionType = (stackType) => (actionType) => (state) => {
    const stack = state.getIn(['undoRedo', stackType]);
    const transactions = state.getIn(['undoRedo', 'transactions']);

    if (!stack || !stack.size) return;

    return stack.find((transactionId) => {
        const transaction = transactions.get(transactionId);
        return transaction && transaction.some((undoRedoAction) => getRedoActionType(undoRedoAction) === actionType);
    });
};
const getNextUndoTransactionWithActionType = getNextTransactionWithActionType('undoStack');
const getNextRedoTransactionWithActionType = getNextTransactionWithActionType('redoStack');

// UNDO
export const getNextUndoTransactionIdSelector = (state) => state.getIn(['undoRedo', 'undoStack', 0]);

export const getNextUndoLocationChangeTransactionIdSelector =
    getNextUndoTransactionWithActionType('@@router/LOCATION_CHANGE');
export const getNextUndoElementUpdateTransactionIdSelector = getNextUndoTransactionWithActionType(ELEMENT_UPDATE);
export const getNextUndoElementCreateTransactionIdSelector = getNextUndoTransactionWithActionType(ELEMENT_CREATE);
export const getNextClipperOperationCompleteTransactionIdSelector =
    getNextUndoTransactionWithActionType(CLIPPER_OPERATION_COMPLETE);
export const getNextUndoElementMoveTransactionIdSelector = getNextUndoTransactionWithActionType(ELEMENT_MOVE_MULTI);

// REDO
export const getNextRedoTransactionIdSelector = (state) => state.getIn(['undoRedo', 'redoStack', 0]);

export const getNextRedoMoveTransactionIdSelector = getNextRedoTransactionWithActionType(ELEMENT_MOVE_MULTI);
export const getNextRedoCreateTransactionIdSelector = getNextRedoTransactionWithActionType(ELEMENT_CREATE);
export const getNextRedoClipperOperationCompleteTransactionIdSelector =
    getNextRedoTransactionWithActionType(CLIPPER_OPERATION_COMPLETE);

export const getUndoStack = (state) => state.getIn(['undoRedo', 'undoStack']);
export const getRedoStack = (state) => state.getIn(['undoRedo', 'redoStack']);
export const getIsUndoing = (state) => state.getIn(['undoRedo', 'isUndoing']);
export const getIsRedoing = (state) => state.getIn(['undoRedo', 'isRedoing']);
