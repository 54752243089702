import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GesturePasteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={46}
        height={58}
        viewBox="0 0 46 58"
        className="Icon gesture-paste"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#323B4A"
                d="M45.815 41.088c-.126-1.578-1.69-5.244-2.254-6.237-.793-1.4-1.617-1.838-2.61-1.774-.963.06-1.35.616-1.107 1.628.028.124.166.559.373 1.065.004.014.004.028.004.028s-.307-.62-.754-1.29c-.12-.263-.25-.516-.366-.733-.92-1.731-1.97-2.237-3.178-2.162-.892.057-1.378.463-1.427 1.197-.19.872-.106 1.955-.085 2.215l-.02-.043a13.36 13.36 0 0 0-.082-.306c-.57-4.014-1.092-8.04-1.73-12.047-.355-2.233-.715-3.832-1.247-6.034-.253-1.05-.912-1.823-2.054-1.293-.461.214-.694.599-1.039 1.158-.5.812-.564 1.891-.535 2.835.067 2.22.313 4.435.49 6.654.105 1.307.5 7.406.612 8.795-.042.384-.077.648-.077.648l-.493-1.19c-2.009-4.794-4.01-9.589-6.039-14.372a13.15 13.15 0 0 0-1.13-2.109c-.652-.983-1.502-.915-2.234.018-1.138 1.457-.124 5.019.278 6.326 1.254 4.078 2.533 8.153 3.939 12.178.683 1.96.704 3.922.606 5.924-.046.926-.434 1.275-1.37 1.222-.698-.04-1.392-.2-2.08-.353-1.933-.435-3.86-.901-5.791-1.332-1.237-.278-2.452-.16-3.629.313-.803.324-1.458.83-1.85 1.628-.461.94-.243 1.642.684 2.098.433.214.895.388 1.363.502 4.693 1.14 8.346 3.904 11.422 7.527.229.267.546.645.923 1.083l.173.2c.82.879 4.678 4.274 13.884 2.379 5.816-1.197 7.627-5.56 8.194-8.236.286-1.962.437-3.943.42-5.934a15.11 15.11 0 0 0-.184-2.176z"
            />
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="m3.035 34.6 5.668 5.748" />
            <path fill="#F37221" fillRule="nonzero" d="M1.544 37.364 0 31.522l5.76 1.566z" />
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="m15.895 6.04 2.439 6.569" />
            <path fill="#F37221" fillRule="nonzero" d="m13.676 7.648.915-5.126 4.058 3.342z" />
            <path stroke="#F37221" strokeLinecap="round" strokeWidth={1.406} d="M30.46 3.957v7.391" />
            <path fill="#F37221" fillRule="nonzero" d="M27.351 4.76 30.46 0l3.109 4.76z" />
        </g>
    </svg>
);
const Memo = memo(GesturePasteIcon);
export default Memo;
