// Utils
import { readAsDataURL } from '../../../../common/files/promisedFileReader';

export const loadImageFromDataUrl = (srcBase64) =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = srcBase64;
    });

export const loadImageFromFile = (file) => readAsDataURL(file).then(loadImageFromDataUrl);
