// Utils
import { getBrowserTheme } from './themeService';
import { sendAmplitudeEvent } from '../../../../../analytics/amplitudeService';

// Selectors
import { getThemeIsDarkMode } from './themeSelector';

// Constants
import { THEME_MODE_SET, THEME_SET, THEMES } from './themeConstants';
import { EVENT_TYPE_NAMES } from '../../../../../../common/analytics/amplitudeEventTypesUtil';
import { AMPLITUDE_USER_PROPS } from '../../../../../../common/analytics/statsConstants';

export const setThemeMode = (mode) => {
    if (!mode) return;

    let theme = mode;

    if (mode === THEMES.MATCH_BROWSER) {
        theme = getBrowserTheme();
    }

    sendAmplitudeEvent({
        eventType: EVENT_TYPE_NAMES.SWITCHED_THEME,
        eventProperties: {
            theme,
            mode,
        },
        userProperties: {
            [AMPLITUDE_USER_PROPS.THEME]: theme,
        },
    });

    return {
        type: THEME_MODE_SET,
        mode,
        theme,
    };
};

export const setTheme = (theme) => ({
    type: THEME_SET,
    theme,
});

export const toggleTheme = () => (dispatch, getState) => {
    const state = getState();
    const currentlyDark = getThemeIsDarkMode(state);

    const nextTheme = currentlyDark ? THEMES.LIGHT : THEMES.DARK;

    return dispatch(setThemeMode(nextTheme));
};
