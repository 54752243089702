/**
 * img is a DOM img element with the image loaded.
 */
export default (img) => {
    const { width, height } = img;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');

    // draw image
    ctx.drawImage(img, 0, 0);

    return {
        canvas,
        ctx,
    };
};
