// Lib
import { EditorState, Modifier } from 'draft-js';

// Utils
import forceFocus from './forceFocus';

// Constants
import { EditorChangeType } from '../draftjsConstants';

const applyInlineStyle = (editorState, selectionState, style) => {
    const currentContent = editorState.getCurrentContent();

    const prevSelectionState = editorState.getSelection();

    const newContentState = Modifier.applyInlineStyle(currentContent, selectionState, style);

    const newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_INLINE_STYLE);

    return forceFocus(newEditorState, prevSelectionState);
};

export default applyInlineStyle;
