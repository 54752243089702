// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

export const APP_SET_INITIALISED = 'APP_SET_INITIALISED';

/**
 * Details is a map of { property: value }.
 * "value" is true | false.
 */
export const appSetInitialised = (details) => ({
    type: APP_SET_INITIALISED,
    details,
    timestamp: getTimestamp(),
});
