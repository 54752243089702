// Utils
import { asRect } from '../../../common/maths/geometry/rect';
import { getVisibleCanvasWindowRectInGridUnits } from '../utils/canvasPositionUtils';

// Services
import { intercomSetUserProperties } from '../../analytics/intercomService';

// Selectors
import {
    canvasZoomScaleSelector,
    canvasZoomStateSelector,
    canvasZoomTranslationPxSelector,
} from './canvasZoomSelector';
import getGridSize from '../../utils/grid/gridSizeSelector';
import { currentBoardCanvasElementsSelector } from '../../element/selectors/currentBoardSelector';
import { getCurrentUserId } from '../../user/currentUserSelector';

// Constants
import {
    CANVAS_SET_SIZE,
    CANVAS_SCALE_DISABLE,
    CANVAS_SCALE_ENABLE,
    CANVAS_SCALE_TOGGLE,
    CANVAS_ZOOM_TRANSFORMATION_SET,
    CANVAS_ZOOM_TARGET_SCALE_SET,
    CANVAS_ZOOM_RESET_STATE,
    CANVAS_SET_INITIAL_ORIGIN,
    CANVAS_SET_SCROLL_AREA_SIZE,
} from './canvasConstants';
import { CANVAS_VIEWPORT_ID } from '../utils/canvasConstants';

export const setCanvasSize = ({ canvasSize, scrollAreaSize }) => ({
    type: CANVAS_SET_SIZE,
    canvasSize,
    scrollAreaSize,
});

export const setCanvasScrollAreaSize = (scrollAreaSize) => ({
    type: CANVAS_SET_SCROLL_AREA_SIZE,
    scrollAreaSize,
});

// FIXME-ZOOM - Remove these when the scale is replaced with zoom
export const canvasScale = () => ({ type: CANVAS_SCALE_ENABLE });
export const canvasUnscale = () => ({ type: CANVAS_SCALE_DISABLE });
export const canvasToggleScale = () => ({ type: CANVAS_SCALE_TOGGLE });

// FIXME - this seems like a very strange place for this thunk... It should probably
//  be in currentBoardActions or something
/**
 * Uses a thunk action to get the current elements on a board.
 * This is useful for components that don't always need to know the canvas elements, only during an action.
 */
export const getCurrentBoardCanvasElementsThunk = () => (dispatch, getState) => {
    const state = getState();
    return currentBoardCanvasElementsSelector(state);
};

// Zoom
export const setCanvasZoomTransformation = ({ scale, translation, scrollAreaSize, mode }) => ({
    type: CANVAS_ZOOM_TRANSFORMATION_SET,
    scale,
    translation,
    scrollAreaSize,
    mode,
});

export const setCanvasZoomTargetScale = (targetScale) => ({
    type: CANVAS_ZOOM_TARGET_SCALE_SET,
    targetScale,
});

export const resetCanvasZoomState = () => ({
    type: CANVAS_ZOOM_RESET_STATE,
});

export const sendCanvasZoomIntercomAnalytics = () => (_, getState) => {
    const state = getState();
    const currentUserId = getCurrentUserId(state);

    intercomSetUserProperties(currentUserId, {
        has_used_zoom: true,
    });
};

/**
 * This is used in components such as measureElementDecorator that we want to get the zoom state
 * but we don't want it consistently reacting to changes in the state.
 */
export const getCanvasZoomStateThunk = () => (_, getState) => {
    const state = getState();
    return canvasZoomStateSelector(state);
};

/**
 * Saves the initial origin of the board's canvas, so when elements are moved or removed we can
 * return to the initial origin.
 */
export const setCanvasInitialSize = ({ boardId, canvasOrigin }) => ({
    type: CANVAS_SET_INITIAL_ORIGIN,
    boardId,
    canvasOrigin,
});

/**
 * Gets the "window" that is visible on the canvas in grid units.
 * I.e. The area of the canvas that's currently visible to the user.
 */
export const getVisibleCanvasWindowRectInGridUnitsThunk = () => (dispatch, getState) => {
    const state = getState();
    const gridSize = getGridSize(state);
    const zoomScale = canvasZoomScaleSelector(state);
    const zoomTranslationPx = canvasZoomTranslationPxSelector(state);

    const canvasViewportEl = document.getElementById(CANVAS_VIEWPORT_ID);

    if (!canvasViewportEl) return asRect({ x: 0, y: 0, width: 0, height: 0 });

    return getVisibleCanvasWindowRectInGridUnits(canvasViewportEl, gridSize, zoomScale, zoomTranslationPx);
};
