// Utils
import keyBindingFn from './keyBindingFn';
import handleKeyCommand from './handleKeyCommand';
import blockStyleFn from './blockStyleFn';

export default ({ enableBlockStyles }) => ({
    keyBindingFn: keyBindingFn({ enableBlockStyles }),
    handleKeyCommand: handleKeyCommand({ enableBlockStyles }),
    blockStyleFn,
});
