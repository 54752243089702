import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const PreviewImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={78}
        height={62}
        viewBox="0 0 78 62"
        className="Icon preview-image"
        {...props}
    >
        <g fill="none" fillRule="evenodd" opacity={0.7}>
            <path fill="#DADADA" d="M0 0h78v62H0z" />
            <path fill="#CACACA" d="m26 28 26 34H0z" />
            <path fill="#CACACA" d="m61 41 17 21H44z" />
            <path fill="#C9C9C9" fillRule="nonzero" d="M78 0v62H0V0h78zM3 3v56h72V3H3z" />
            <path fill="#F8F8F8" d="M58 29a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
        </g>
    </svg>
);
const Memo = memo(PreviewImageIcon);
export default Memo;
