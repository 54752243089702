// Lib
import * as Immutable from 'immutable';

// Constants
import { ELEMENT_RESIZING_END, ELEMENT_RESIZING_START } from './resizingConstants';

const initialState = Immutable.fromJS({
    isResizing: false,
    resizedElementId: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_RESIZING_START:
            return state.set('isResizing', true).set('resizedElementId', action.elementId);
        case ELEMENT_RESIZING_END:
            return state.set('isResizing', false).set('resizedElementId', null);
        default:
            return state;
    }
};
