// Lib
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getUserDisableSerifHeadings } from '../../../../../common/users/utils/userPropertyUtils';

// Selectors
import { getCurrentUser } from '../../../currentUserSelector';

const mapStateToProps = (state) => ({
    disableSerifHeadings: getUserDisableSerifHeadings(getCurrentUser(state)),
});

const HeadingStyleManager = ({ disableSerifHeadings }) => {
    useEffect(() => {
        disableSerifHeadings
            ? document.body.classList.add('sans-serif-headings')
            : document.body.classList.remove('sans-serif-headings');

        return () => {
            document.body.classList.remove('sans-serif-headings');
        };
    }, [disableSerifHeadings]);

    return null;
};

HeadingStyleManager.propTypes = {
    disableSerifHeadings: PropTypes.bool,
};

export default connect(mapStateToProps)(HeadingStyleManager);
