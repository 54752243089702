import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PopupModeContent.scss';

const PopupModeContent = React.forwardRef(function PopupModeContent({ children, className, scrollable }, ref) {
    return (
        <div ref={ref} className={classNames('PopupModeContent', className, { scrollable })}>
            <div>{children}</div>
        </div>
    );
});

PopupModeContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    scrollable: PropTypes.bool,
};

export default PopupModeContent;
