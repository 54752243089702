import * as Immutable from 'immutable';
import { POPUP_OPEN, POPUP_TOGGLE, POPUP_CLOSE, PopupIds } from './popupConstants';

import { ELEMENT_MOVE, ELEMENT_MOVE_AND_UPDATE, ELEMENT_MOVE_MULTI } from '../../../common/elements/elementConstants';

import { DRAG_START } from '../../reducers/draggingConstants';
import { USER_NAVIGATE } from '../../../common/users/userConstants';

const initialState = Immutable.Map();

const addPopup = (state, { popupId, predicateFn }) => state.set(popupId, predicateFn);
const removePopup = (state, { popupId }) => state.delete(popupId);
const removeMultiplePopups = (state, { popupIds }) =>
    state.withMutations((mutableState) => popupIds.forEach((popupId) => removePopup(mutableState, { popupId })));

export default (state = initialState, action) => {
    switch (action.type) {
        // popup actions
        case POPUP_OPEN:
            return addPopup(state, action);
        case POPUP_TOGGLE:
            if (state.get(action.popupId)) return removePopup(state, action);
            return addPopup(state, action);
        case POPUP_CLOSE:
            if (action.closeAll) return initialState;
            return action.popupIds ? removeMultiplePopups(state, action) : removePopup(state, action);
        // close popups on these Actions
        case USER_NAVIGATE:
        case ELEMENT_MOVE:
        case ELEMENT_MOVE_AND_UPDATE:
        case ELEMENT_MOVE_MULTI:
        case DRAG_START:
        case '@@router/LOCATION_CHANGE':
            return state.filter((predicateFn, key) => {
                // This needs to be explicitly defined here because the popupToggle action is created
                // from the electron main process, and it cannot serialise a predicateFn to the client
                if (key === PopupIds.ELECTRON_UPDATE_REQUIRED) return true;

                if (predicateFn) return predicateFn && predicateFn(state, action);

                return false;
            });
        default:
            return state;
    }
};
