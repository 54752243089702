// Utils
import { asObject } from '../../../common/utils/immutableHelper';
import { hasShiftKey } from '../../utils/keyboard/keyboardUtility';
import { isCommentThread, isLine } from '../../../common/elements/utils/elementTypeUtils';
import {
    getElementId,
    getElementLocation,
    getIsCollapsed,
    isElementLocked,
} from '../../../common/elements/utils/elementPropertyUtils';
import { validateElementTypeEditPermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { getElementAlignmentMoves } from '../toolbar/components/selectionTools/arrangementTools/alignmentTools/alignmentUtils';
import {
    getHorizontallyDistributedMoves,
    getVerticallyDistributedMoves,
} from '../toolbar/components/selectionTools/arrangementTools/distributeTools/distributionUtils';

// Selectors
import { getCurrentBoard } from '../../element/board/boardSelector';
import { getSelectedElementIds, getSelectedElements } from '../../element/selection/selectedElementsSelector';
import { currentBoardUserPermissionsSelector } from '../../utils/permissions/elementPermissionsSelector';
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getIsModalOpen } from '../../components/modal/activeModalSelector';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getMeasurementsMapThunk } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';
import {
    getPresentationModeFocusedElementId,
    isPresentationModeEnabledSelector,
} from '../presentation/presentationSelector';

// Actions
import { moveMultipleElements, shiftSelectedElements } from '../../element/actions/elementMoveActions';
import { changeElementOrder, moveElementsToTrash } from '../../element/actions/elementShortcutActions';

// Constants
import { KEY_CODES } from '../../utils/keyboard/keyConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';
import { DISTRIBUTION_DIRECTION } from '../toolbar/components/selectionTools/arrangementTools/distributeTools/distributionConstants';

/**
 * Nudge elements if they're selected while hitting arrow keys.
 */
export const handleArrow = (event) => (dispatch, getState) => {
    const state = getState();

    // If there's a focused element in presentation mode then don't allow the shortcut to function
    if (isPresentationModeEnabledSelector(state) && getPresentationModeFocusedElementId(state)) return;

    // If there's no selected elements, then don't prevent the default arrow behaviour
    const selectedElementIds = getSelectedElementIds(state);
    if (!selectedElementIds.size) return;

    // if a modal is open, don't allow the shortcut to function
    const isModalOpen = getIsModalOpen(state);
    if (isModalOpen) return;

    event.preventDefault();

    const axis = event.keyCode === KEY_CODES.LEFT_ARROW || event.keyCode === KEY_CODES.RIGHT_ARROW ? 'x' : 'y';
    const magnitude = hasShiftKey(event) ? 5 : 1;
    const direction = event.keyCode === KEY_CODES.LEFT_ARROW || event.keyCode === KEY_CODES.UP_ARROW ? -1 : 1;

    const change = {
        [axis]: magnitude * direction,
    };

    dispatch(shiftSelectedElements(change));
};

/**
 * Send elements to trash if they're selected when delete is pressed and the user is viewing the canvas.
 */
export const moveToTrash = () => (dispatch, getState) => {
    const state = getState();
    const currentBoardId = getCurrentBoardIdFromState(state);

    // Could potentially use the "app.currentFocus" state here to fix this too
    // Shouldn't be able to move anything to the trash when a modal is open
    const isModalOpen = getIsModalOpen(state);

    if (isModalOpen) return;

    const selectedElementIds = getSelectedElementIds(state).toJS();
    dispatch(moveElementsToTrash({ currentBoardId, elementIds: selectedElementIds }));
};

/**
 * Moves the selected elements to the unsorted notes.
 */
export const moveToUnsorted = () => (dispatch, getState) => {
    const state = getState();
    const currentBoardId = getCurrentBoardIdFromState(state);

    const isModalOpen = getIsModalOpen(state);

    if (isModalOpen) return;

    const permissions = currentBoardUserPermissionsSelector(state);

    const selectedElements = getSelectedElements(state);

    const elementsToMove = selectedElements.filter(
        (el) =>
            validateElementTypeEditPermission(permissions, el) &&
            !isElementLocked(el) &&
            !(isLine(el) || (isCommentThread(el) && getIsCollapsed(el))),
    );

    if (!elementsToMove.size) return;

    const moves = elementsToMove
        .map((element) => ({
            id: getElementId(element),
            location: {
                ...asObject(getElementLocation(element)),
                parentId: currentBoardId,
                section: BoardSections.INBOX,
            },
        }))
        .toJS();

    dispatch(moveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.UNSORTED }));
};

/**
 * Stacking order actions.
 */
export const bringForward = (event, dispatchObj) => changeElementOrder({ increaseScore: true }, dispatchObj);
export const sendBackward = (event, dispatchObj) => changeElementOrder({ increaseScore: false }, dispatchObj);

/**
 * Aligns the selected elements to a particular side.
 */
export const handleAlignmentShortcut =
    (alignSide) =>
    (event, { measurementsDispatch }) =>
    (dispatch, getState) => {
        const state = getState();

        const selectedElements = getSelectedElements(state);

        if (!selectedElements || selectedElements.size < 2) return;

        const gridSize = getGridSize(state);
        const measurements = measurementsDispatch(getMeasurementsMapThunk());

        const currentBoard = getCurrentBoard(state);

        const moves = getElementAlignmentMoves({
            elements: selectedElements,
            currentBoard,
            measurements,
            gridSize,
            alignSide,
        });

        dispatch(moveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.ALIGN }));
    };

/**
 * Aligns the selected elements to a particular side.
 */
export const handleDistributionShortcut =
    (direction) =>
    (event, { measurementsDispatch }) =>
    (dispatch, getState) => {
        const state = getState();

        const selectedElements = getSelectedElements(state);

        if (!selectedElements || selectedElements.size < 3) return;

        const gridSize = getGridSize(state);
        const measurements = measurementsDispatch(getMeasurementsMapThunk());

        const currentBoard = getCurrentBoard(state);

        const moves =
            direction === DISTRIBUTION_DIRECTION.VERTICAL
                ? getVerticallyDistributedMoves({ measurements, selectedElements, currentBoard, gridSize })
                : getHorizontallyDistributedMoves({ measurements, selectedElements, currentBoard, gridSize });

        dispatch(moveMultipleElements({ moves, moveOperation: ELEMENT_MOVE_OPERATIONS.ALIGN }));
    };
