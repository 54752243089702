// Lib
import * as Immutable from 'immutable';
import { cloneDeep } from 'lodash';
import { keyBy } from 'lodash/fp';

// IndexedDB
import { del, get, initKeyvalIdb, keys, set } from '../../utils/services/idbKeyval/idbKeyval';
import { getElementsFromMnObjectsIdb } from '../idb/mnObjectsIdb/mnObjectsIdb';

// Utils
import delay from '../../../common/utils/lib/delay';
import globalLogger, { LoggerComponents } from '../../logger';
import { isDebugEnabled } from '../../debug/debugUtil';
import platformSingleton from '../../platform/platformSingleton';
import { getCurrentUserToken } from '../../auth/authService';
import { asObject, toIdArray } from '../../../common/utils/immutableHelper';
import { simpleDecodeJwt } from '../../auth/authTokenClientUtils';
import { manuallyReportError } from '../../analytics/rollbarService';
import { rebuildPersistedState } from './utils/rebuildPersistedState';
import { createRunningAverage } from '../../utils/milanoteLodash/runningAverage';
import findUnserializablePaths from '../../../common/utils/lib/findUnserializablePaths';
import { getUserId, isFeatureEnabled } from '../../../common/users/utils/userPropertyUtils';
import { isPlatformElectronMac } from '../../platform/utils/platformDetailsUtils';

// Selectors
import { getCurrentUser } from '../../user/currentUserSelector';
import { getElements } from '../../element/selectors/elementSelector';
import { getIsClientPersistenceEnabledForCurrentUser } from '../../element/feature/elementFeatureSelector';

// Config
import getClientConfig from '../../utils/getClientConfig';

// Monitoring
import * as analyticsTimingService from '../../analytics/timingService/analyticsTimingService';
import { wrapAsyncFunctionWithTimingOperation } from '../../analytics/timingService/analyticsTimingService';
import {
    NewRelicCustomAttributes,
    NewRelicPageActions,
    setNewRelicCustomAttribute,
} from '../../analytics/newRelicUtils';

// Local Storage
import { getAppLocalCacheResetLocalStorage, setAppLocalCacheResetLocalStorage } from '../appLocalCacheStorageService';

// Errors
import LimitExceededError from '../../../common/error/LimitExceededError';

// Constants
import { getTimestamp, TIMES } from '../../../common/utils/timeUtil';
import {
    LOCAL_CACHE_CLIENT_PERSISTENCE_ENABLED_HYDRATION_OMIT,
    LOCAL_CACHE_HYDRATION_OMIT,
} from '../../store/initialStateConstants';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';
import { ROLLBAR_LEVELS } from '../../analytics/rollbarConstants';

type CacheObject = {
    version: string;
    timestamp: number;
    state: Record<string, unknown>;
    isClientPersistenceEnabled?: boolean;
    storedElementIds?: string[];
};

const REDUX_STATE_CACHE_KEY = 'redux';

const logger = globalLogger.createChannel(LoggerComponents.INSTANT_APP);

const { appVersion } = getClientConfig();

const keyById = keyBy('id');

// using indexed db seems to be failing on incompatible mobile devices, so adding extra check
// Add extra check to ensure the key exists in the cache before trying to delete it
// Electron was also having an issue - to prevent holding up the release of the instant app feature
//  we're disabling the local cache for Electron
export const SUPPORTS_LOCAL_CACHE =
    !isPlatformElectronMac(platformSingleton) && platformSingleton.features.supportsIndexedDb;

if (SUPPORTS_LOCAL_CACHE) {
    analyticsTimingService.startOperation(NewRelicPageActions.LOCAL_CACHE_KEYVAL_IDB_INIT);

    initKeyvalIdb()
        .then(() => {
            const initTime = analyticsTimingService.endOperation(NewRelicPageActions.LOCAL_CACHE_KEYVAL_IDB_INIT);
            logger.info('%cIndexedDB keyval-store database initialised', 'color: green', { time: initTime });
        })
        .catch((err) => {
            const errorMessage = 'Failed to initialise the keyval-store IndexedDB database';
            logger.error(errorMessage, err);
            manuallyReportError({
                errorMessage,
                level: ROLLBAR_LEVELS.ERROR,
                err,
            });
        });
}

export const clearCachedReduxState = async (): Promise<void> => {
    if (!SUPPORTS_LOCAL_CACHE) return;

    const cacheKeys = await keys();

    if (!cacheKeys.includes(REDUX_STATE_CACHE_KEY)) return;

    return del(REDUX_STATE_CACHE_KEY).catch((err) => {
        logger.error('Failed to delete redux state from Indexed DB', err);
        manuallyReportError({
            errorMessage: '[instant-app] Failed to delete redux state from Indexed DB',
            level: ROLLBAR_LEVELS.ERROR,
            err,
        });
    });
};

const logRestorePersistedState = (persistedState: object) => {
    const logMessage = '%cRestoring redux state from IndexedDB';

    if (process.env.NODE_ENV === 'production') return logger.info(logMessage, 'color: blue');

    const deeplyCopiedState = cloneDeep(persistedState);
    return logger.info(logMessage, 'color: blue', deeplyCopiedState);
};

/**
 * Retrieves the persisted state from the cache object & IndexedDB if necessary.
 *
 * If the cache object has client persistence enabled, the elements will be stored within
 * the mn-objects-idb database, rather than in the cacheObject.
 * This saves time when writing the redux state to IndexedDB.
 * So in this case we need to retrieve the elements from the mn-objects-idb database and
 * then merge them into the state.
 */
export const getPersistedState = async (cacheObject: CacheObject): Promise<Record<string, unknown> | null> => {
    if (!cacheObject.isClientPersistenceEnabled) return cacheObject.state;

    const { storedElementIds } = cacheObject;

    if (!storedElementIds) return null;

    logger.info('Client Persistence - Retrieving elements from mn-objects-idb', { count: storedElementIds.length });

    analyticsTimingService.startOperation(NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_GET_PERSISTED_STATE);

    const elements = (await getElementsFromMnObjectsIdb(storedElementIds)).filter(Boolean);

    const perfTime = analyticsTimingService.endOperation(
        NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_GET_PERSISTED_STATE,
    );

    logger.info('Client Persistence - Finished retrieving elements from mn-objects-idb', { time: perfTime });

    if (!elements || elements.length !== storedElementIds.length) {
        logger.warn('Elements could not be found in IndexedDB', {
            storedElementIds,
            elements,
        });
        return null;
    }

    const elementsMap = keyById(elements);

    return {
        ...cacheObject.state,
        elements: elementsMap,
    };
};

const getCachedReduxStateFromIdb = wrapAsyncFunctionWithTimingOperation(
    NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE,
    async (): Promise<Immutable.Map<string, unknown> | null> => {
        logger.info('Starting restore from cache');
        const perfStart = performance.now();

        analyticsTimingService.startOperation(
            NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_CACHE_OBJECT_RETRIEVAL,
        );
        const cacheObject = await get(REDUX_STATE_CACHE_KEY).catch((err) => {
            logger.error('Failed to retrieve redux state from Indexed DB', err);
            manuallyReportError({
                errorMessage: '[instant-app] Failed to retrieve redux state from Indexed DB',
                level: ROLLBAR_LEVELS.WARNING,
                err,
            });
        });
        analyticsTimingService.endOperation(
            NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_CACHE_OBJECT_RETRIEVAL,
        );

        if (!cacheObject) {
            logger.info('%cNo local cache to restore', 'color: blue');
            return null;
        }

        logger.info('Retrieved cache object');

        const version = cacheObject.version;
        const timestamp = cacheObject.timestamp;

        if (!version || !timestamp) {
            logger.warn('No version & no timestamp in the cache', { cache: cacheObject });
            await clearCachedReduxState();
            return null;
        }

        const now = Date.now();

        if (timestamp < now - TIMES.HOUR * 12) {
            logger.warn('The redux store cache has expired', { timestamp, now });
            await clearCachedReduxState();
            return null;
        }

        if (version !== appVersion) {
            logger.warn('The cached state and current app do not match', {
                cachedVersion: version,
                currentAppVersion: appVersion,
            });
            await clearCachedReduxState();
            return null;
        }

        const persistedState = await getPersistedState(cacheObject);

        const currentUser = getCurrentUser(persistedState);

        if (!isFeatureEnabled(ExperimentId.clientLocalCache, currentUser)) {
            logger.warn('The current user is not feature enabled for the local cache', {
                currentUser,
            });
            await clearCachedReduxState();
            return null;
        }

        const token = getCurrentUserToken();
        const decodedToken = simpleDecodeJwt(token);

        if (!token || !decodedToken) {
            logger.warn('A IndexedDB cache is available but there is no auth token', {
                currentUser,
            });
            await clearCachedReduxState();
            return null;
        }

        const cachedUserId = getUserId(currentUser);
        const tokenUserId = decodedToken?._id;

        if (cachedUserId !== tokenUserId) {
            logger.warn("The local storage user token's user ID does not match the cached user's user ID", {
                cachedUserId,
                tokenUserId,
            });
            await clearCachedReduxState();
            return null;
        }

        const perfEnd = performance.now();
        const perfTime = Math.round(perfEnd - perfStart);

        logger.debugGroupCollapsed('%cRestoring from IDB cache', 'color: blue; font-weight: normal;', {
            time: perfTime,
        });

        logRestorePersistedState(cacheObject);

        const rebuiltState = rebuildPersistedState(persistedState, timestamp, version);

        logger.debugGroupEnd();

        return rebuiltState;
    },
);

/**
 * Finish the promise after awaiting the specified milliseconds
 */
const abandonGetCachedReduxStateFromIdbAfter = async (ms: number): Promise<null> => {
    await delay(ms);
    analyticsTimingService.cancelOperation(NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE);
    analyticsTimingService.cancelOperation(
        NewRelicPageActions.LOCAL_CACHE_GET_CACHED_REDUX_STATE_CACHE_OBJECT_RETRIEVAL,
    );
    return null;
};

/**
 * Retrieves the redux store from IndexedDB.
 */
export const getCachedReduxState = async (): Promise<Immutable.Map<string, unknown> | null> => {
    logger.info('getCachedReduxState - start');

    if (!SUPPORTS_LOCAL_CACHE) {
        logger.info('%cNot supported in this browser', 'color: blue');
        return null;
    }

    // If the app cache reset flag is set, we should reset the cache and return as a first operation
    const shouldResetAppCache = getAppLocalCacheResetLocalStorage() === 'true';

    if (shouldResetAppCache) {
        logger.info('%cResetting the redux store cache', 'color: blue');
        clearCachedReduxState();
        setAppLocalCacheResetLocalStorage('false');
        return null;
    }

    // The controller property is the best way to know if a force refresh has occurred
    // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/controller
    // Electron app is not using service workers, so we don't need to check for it
    if (
        !isPlatformElectronMac(platformSingleton) &&
        navigator?.serviceWorker &&
        navigator?.serviceWorker?.controller === null
    ) {
        logger.info('%cForce resetting local cache', 'color: blue');
        clearCachedReduxState();
        return null;
    }

    // IDB is supported, and we're not explicitly avoiding restoring from the cache, so retrieve from IDB
    return Promise.race([
        getCachedReduxStateFromIdb(),
        // Don't wait for more than 2 seconds to get the data out of IDB
        // TODO-OFFLINE - Only respect the 2 second limit if online
        abandonGetCachedReduxStateFromIdbAfter(2000),
    ]).then((result) => {
        // Only mark the cache as rehydrated if we actually got the data from IDB
        if (result) {
            setNewRelicCustomAttribute(NewRelicCustomAttributes.CLIENT_LOCAL_CACHE_REHYDRATION, true);
        }
        return result;
    });
};

/**
 * Remove parts of the state that we don't want to persist to IndexedDB.
 */
const cleanPersistedState = (state: Immutable.Map<string, any>, omitProps: string[]): Immutable.Map<string, any> =>
    state.withMutations((mutableState) => {
        for (const entry of omitProps) {
            const deletionPath = entry.split('.');
            mutableState.hasIn(deletionPath) && mutableState.deleteIn(deletionPath);
        }
    });

let hasLoggedSetCacheFailure = false;

// Track the time it takes to convert the state to a POJO
const JS_CONVERSION_RUNNING_AVERAGE_WINDOW = 10;
const JS_CONVERSION_THRESHOLD = 150;
const jsConversionTimeRunningAverage = createRunningAverage(JS_CONVERSION_RUNNING_AVERAGE_WINDOW);

const JS_CONVERSION_TIME_VIOLATION_CODE = 'JS_CONVERSION_TIME_VIOLATION';

export const isJSConversionTimeViolation = (err: unknown): err is LimitExceededError =>
    err instanceof LimitExceededError && err.code === JS_CONVERSION_TIME_VIOLATION_CODE;

/**
 * Get the cache object for standard (non-client-persistence-enabled) users.
 */
const getStandardCacheObject = (state: Immutable.Map<string, unknown>): CacheObject => {
    const cleanedState = cleanPersistedState(state, LOCAL_CACHE_HYDRATION_OMIT);
    const pojoState = asObject(cleanedState) as Record<string, unknown>;

    return {
        // NOTE: This does not need to be the app version, but for now it seems like the simplest method
        version: appVersion as string,
        timestamp: getTimestamp(),
        state: pojoState,
    };
};

/**
 * Get the cache object for client-persistence-enabled users.
 *
 * In this case we delete the elements from the state because they're already stored
 * in the mn-objects-idb database.
 * So we can save time when writing to IndexedDB by not serialising the elements.
 */
const getClientPersistenceEnabledCacheObject = (state: Immutable.Map<string, unknown>): CacheObject => {
    const elements = getElements(state);
    const elementIds = toIdArray(elements);

    const cleanedState = cleanPersistedState(state, LOCAL_CACHE_CLIENT_PERSISTENCE_ENABLED_HYDRATION_OMIT);
    const pojoState = asObject(cleanedState) as Record<string, unknown>;

    return {
        // NOTE: This does not need to be the app version, but for now it seems like the simplest method
        version: appVersion as string,
        timestamp: getTimestamp(),
        state: pojoState,
        isClientPersistenceEnabled: true,
        storedElementIds: elementIds,
    };
};

/**
 * Get the cache object for instant app.
 */
const getCacheObject = (state: Immutable.Map<string, unknown>): CacheObject => {
    const isClientPersistenceEnabled = getIsClientPersistenceEnabledForCurrentUser(state);

    const jsConversionStartTime = performance.now();
    const cacheObject = isClientPersistenceEnabled
        ? getClientPersistenceEnabledCacheObject(state)
        : getStandardCacheObject(state);
    const jsConversionTime = performance.now() - jsConversionStartTime;

    // Track the JS conversion time and throw an error if it exceeds a threshold
    const conversionAverage = jsConversionTimeRunningAverage.add(jsConversionTime);

    const isTimeViolation =
        conversionAverage > JS_CONVERSION_THRESHOLD &&
        jsConversionTimeRunningAverage.size() === JS_CONVERSION_RUNNING_AVERAGE_WINDOW;

    if (isTimeViolation) {
        throw new LimitExceededError({
            code: JS_CONVERSION_TIME_VIOLATION_CODE,
            message: '[instant-app] Converting Redux state to POJO is taking too long',
            details: {
                conversionAverage,
            },
        });
    }

    return cacheObject;
};

/**
 * Save the redux store to IndexedDB (after it's cleaned and converted to a POJO).
 */
export const setCachedReduxState = async (reduxState: Immutable.Map<string, unknown>): Promise<void | IDBValidKey> => {
    if (!SUPPORTS_LOCAL_CACHE) return;

    if (isDebugEnabled()) {
        performance.mark('localCache-setCachedReduxState-start');
    }

    const cacheObject = getCacheObject(reduxState);

    // TODO - It would be worth investigating if we can use the "relaxed" "durability" mode of idb-keyval to
    //  see if that offers better performance.
    //  See https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction#durability
    return set(REDUX_STATE_CACHE_KEY, cacheObject)
        .catch((err) => {
            logger.error('Failed to set redux state to Indexed DB', err);

            if (hasLoggedSetCacheFailure) return;

            hasLoggedSetCacheFailure = true;

            if (err.name !== 'DataCloneError') {
                manuallyReportError({
                    errorMessage: '[instant-app] Failed to set redux state to Indexed DB',
                    level: ROLLBAR_LEVELS.ERROR,
                    err,
                });

                return;
            }

            // Unable to save the state due to a property within the state being unserializable
            try {
                const unserializeablePaths = findUnserializablePaths(cacheObject);
                logger.error('Failed to serialise cache object', unserializeablePaths);

                manuallyReportError({
                    errorMessage: '[instant-app] Failed to serialise cache object',
                    level: ROLLBAR_LEVELS.ERROR,
                    err,
                    custom: {
                        unserializeablePaths,
                    },
                });
            } catch (loggingError) {
                logger.error('Failed to log unserializable paths', loggingError);

                manuallyReportError({
                    errorMessage: '[instant-app] Failed to log unserializable paths',
                    level: ROLLBAR_LEVELS.ERROR,
                    err: loggingError,
                });
            }
        })
        .finally(() => {
            if (isDebugEnabled()) {
                performance.mark('localCache-setCachedReduxState-end');
                performance.measure(
                    'localCache-setCachedReduxState',
                    'localCache-setCachedReduxState-start',
                    'localCache-setCachedReduxState-end',
                );
            }
        });
};
