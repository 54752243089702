// Constants
import { BoardExportFormat } from './exportConstants';
import { MimeType } from '../media/mimeConstants';

const mapToOldCanvasExportFormat = (fileType) => {
    switch (fileType) {
        case MimeType.PDF:
            return 'pdf-html';
        case MimeType.PNG:
        default:
            return 'png-html';
    }
};

const mapToOldLinearExportFormat = (fileType) => {
    switch (fileType) {
        case MimeType.PDF:
            return 'pdf';
        case MimeType.DOCX:
            return 'docx';
        case MimeType.PLAIN_TEXT:
            return 'txt';
        case MimeType.MARKDOWN:
        default:
            return 'md';
    }
};

/**
 * We need to do a mapping to keep amplitude data consistent.
 *
 * Previously "format" used to be one of: pdf-html | png=html | pdf | md | docx | txt
 * Now the format is: canvas | linear
 * And the fileType is: application/pdf | text/plain; charset=UTF-8 | ...
 */
export const mapToOldExportFormat = (format, fileType) =>
    format === BoardExportFormat.canvas ? mapToOldCanvasExportFormat(fileType) : mapToOldLinearExportFormat(fileType);
