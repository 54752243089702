// Utils
import getBlockMapForRange from '../blocks/getBlockMapForRange';

/**
 * Gets the block map that spans the editor's selection.
 */
export default (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const blockMap = contentState.getBlockMap();

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    return getBlockMapForRange(blockMap, { startKey, endKey });
};
