// Utils
import { isTerminatingMarkdownCharacter } from './patternMatching/markdownPatternMatcher';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';

// Utils
import handleBlockType from './handleBlockType';
import handleInlineStyle from './handleInlineStyle';
import handleLink from './handleLink';
import addMarkdownChangeToUndoStack from './utils/addMarkdownChangeToUndoStack';
import {
    getSelectionEditorContextKey,
    getSimpleSelectionFromSelection,
} from '../../store/reducers/editorContext/editorContextUtils';

// Types
import { EditorState } from 'draft-js';
import { PluginProps } from '../pluginTypes';
import { EditorStyleTypes } from '../../richText/richTextConstants';
import { EditorContextName } from '../../store/reducers/editorContext/editorContextConstants';

const handleCharacter = (editorState: EditorState, char: string, enableBlockStyles: boolean) => {
    let newEditorState = editorState;

    if (enableBlockStyles) {
        newEditorState = handleBlockType(editorState, char);
    }

    if (editorState === newEditorState) {
        newEditorState = handleInlineStyle(editorState, char);
    }

    if (editorState === newEditorState) {
        newEditorState = handleLink(editorState, char);
    }

    // If the markdown plugin has changed the editor state, add the state without the change to the undo stack
    if (editorState !== newEditorState) {
        newEditorState = addMarkdownChangeToUndoStack(editorState, newEditorState, char);
    }

    return newEditorState;
};

/**
 * For now, we're only handling block type context changes due to the restructure required
 * to support inline changes being too large (this would require range changes to be handled,
 * such as returning to previous range to the old inline styles).
 *
 * Potentially do this by returning a context from the handleCharacter function, and then
 * performing both the markdown change and the context change using the same data.
 */
const handleBlockTypeContextChange = (
    oldEditorState: EditorState,
    newEditorState: EditorState,
    char: string,
    dispatchSetEditorContext: Function,
): void => {
    if (char !== ' ') return;
    if (oldEditorState === newEditorState) return;

    // Can only switch block type if the selection is collapsed
    const selection = newEditorState.getSelection();
    if (!selection.isCollapsed()) return;

    const newSelectionKey = selection.getStartKey();
    const newContentState = newEditorState.getCurrentContent();
    const newBlockType = newContentState.getBlockForKey(newSelectionKey)?.getType();

    if (!newBlockType) return;

    const oldContentState = oldEditorState.getCurrentContent();
    const oldBlockState = oldContentState.getBlockForKey(newSelectionKey);
    const oldBlockType = oldBlockState?.getType();

    if (oldBlockType === newBlockType) return;

    // We've changed block type so we need to save some context
    const oldSelection = oldEditorState.getSelection();
    const oldBlockText = oldBlockState.getText();
    const replacementIndex = oldSelection.getAnchorOffset();
    const replacedText = oldBlockText.slice(0, replacementIndex);
    const restoredText = `${replacedText}${char}`;

    const contextKey = getSelectionEditorContextKey(newEditorState);
    const context = {
        replacementStyleType: EditorStyleTypes.BLOCK,
        replacement: {
            blockType: newBlockType,
            text: replacedText,
            range: getSimpleSelectionFromSelection(selection),
        },
        restore: {
            blockType: oldBlockType,
            text: restoredText,
            range: {
                anchorKey: newSelectionKey,
                anchorOffset: 0,
                focusKey: newSelectionKey,
                focusOffset: 0,
            },
        },
    };

    dispatchSetEditorContext(EditorContextName.markdown, contextKey, context);
};

export default ({ enableBlockStyles }: { enableBlockStyles: boolean }) =>
    (
        char: string,
        editorState: EditorState,
        eventTimeStamp: number,
        { setEditorState, getProps }: PluginProps,
    ): string => {
        if (!isTerminatingMarkdownCharacter(char)) return HANDLER_RETURN_VALUES.notHandled;

        const newEditorState = handleCharacter(editorState, char, enableBlockStyles);

        const { dispatchSetEditorContext } = getProps();
        handleBlockTypeContextChange(editorState, newEditorState, char, dispatchSetEditorContext);

        if (editorState !== newEditorState) {
            setEditorState(newEditorState);
            return HANDLER_RETURN_VALUES.handled;
        }

        return HANDLER_RETURN_VALUES.notHandled;
    };
