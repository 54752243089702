export const CANVAS_SET_SIZE = 'CANVAS_SET_SIZE';
export const CANVAS_SET_SCROLL_AREA_SIZE = 'CANVAS_SET_SCROLL_AREA_SIZE';

// FIXME-ZOOM - remove this when zoom replaces scale
export const CANVAS_SCALE_ENABLE = 'CANVAS_SCALE_ENABLE';
export const CANVAS_SCALE_DISABLE = 'CANVAS_SCALE_DISABLE';
export const CANVAS_SCALE_TOGGLE = 'CANVAS_SCALE_TOGGLE';

// Zoom
export const CANVAS_ZOOM_RESET_STATE = 'CANVAS_ZOOM_RESET_STATE';
export const CANVAS_ZOOM_TRANSFORMATION_SET = 'CANVAS_ZOOM_TRANSFORMATION_SET';
export const CANVAS_ZOOM_TARGET_SCALE_SET = 'CANVAS_ZOOM_TARGET_SCALE_SET';

export const CANVAS_SET_INITIAL_ORIGIN = 'CANVAS_SET_INITIAL_ORIGIN';
