// Lib
import * as Immutable from 'immutable';

// Constants
import { ELEMENT_ACTIVITY_TYPES } from '../elementActivityConstants';

const getDefaultBoardActivity = () => ({
    // FIXME Change this to not be immutable - Check activity indicators
    actorIds: Immutable.List(),
    notificationIds: [],
    changeCount: 0,
    timestamp: 0,
});

export default (existingCollatedBoardActivity, activityEntry) => {
    const collatedBoardActivity = existingCollatedBoardActivity || getDefaultBoardActivity();

    // Don't handle duplicate share notifications
    if (
        collatedBoardActivity.type === ELEMENT_ACTIVITY_TYPES.SHARE &&
        activityEntry.type === ELEMENT_ACTIVITY_TYPES.SHARE
    ) {
        return collatedBoardActivity;
    }

    const changeCount = activityEntry.type === ELEMENT_ACTIVITY_TYPES.CHANGE ? activityEntry.changeCount : 1;

    // If the collated board activity already has a type, then make sure it's a change count
    collatedBoardActivity.type = collatedBoardActivity.type ? ELEMENT_ACTIVITY_TYPES.CHANGE : activityEntry.type;
    collatedBoardActivity.changeCount += changeCount;
    collatedBoardActivity.timestamp = Math.max(collatedBoardActivity.timestamp, activityEntry.timestamp);
    collatedBoardActivity.actorIds = collatedBoardActivity.actorIds.concat(activityEntry.actorIds);
    collatedBoardActivity.notificationIds = collatedBoardActivity.notificationIds.concat(activityEntry.notificationIds);

    return collatedBoardActivity;
};
