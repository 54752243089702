// Lib
import { createSelector } from 'reselect';

// Selector utils
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';

// Utils
import * as rectLib from '../../../../common/maths/geometry/rect';
import { asShallowObject } from '../../../../common/utils/immutableHelper';
import { isLine } from '../../../../common/elements/utils/elementTypeUtils';
import { isWorkspaceSectionCanvas } from '../../../workspace/workspacePropertyUtils';

export const getMeasurementsMap = (state) => state.get('elementMeasurements');
// This is used by the line drag components - so if there's multiple lines the shallow object is only created once
export const getShallowMeasurementsMap = createSelector(getMeasurementsMap, asShallowObject);

// Element's selectors
export const getMeasurementsMapFromProps = (state, ownProps) => ownProps.measurements;
export const getElementMeasurements = (state, { id }) => getMeasurementsMap(state).get(id);

// ACTIONS
/**
 * For performance reasons, it can be useful to access the measurements map via a thunk
 * at known times, rather than always connecting a component to the measurements store.
 */
export const getMeasurementsMapThunk = () => (dispatch, getState) => getMeasurementsMap(getState());

const EMPTY_RECTANGLE = rectLib.asRect({ x: 0, y: 0, width: 0, height: 0 });

/**
 * Inspects the measurements map to determine the bounding rectangle of all the elements on the canvas,
 * excluding LINEs for now.
 *
 * NOTE: This does not account for oddly shaped elements like "icon like" elements (e.g. boards and documents).
 */
export const getCanvasElementMeasurementsBoundingRect = createShallowSelector(getMeasurementsMap, (measurementsMap) => {
    const initialRect = { left: Infinity, right: 0, top: Infinity, bottom: 0 };

    const boundingRect = rectLib.asRect(
        measurementsMap.reduce((acc, measurement) => {
            if (!measurement || !isWorkspaceSectionCanvas(measurement)) return acc;

            // Excludes lines
            if (isLine(measurement)) return acc;

            const left = measurement.get('left');
            const right = measurement.get('right');
            const top = measurement.get('top');
            const bottom = measurement.get('bottom');

            if (left < acc.left) {
                acc.left = left;
            }

            if (right > acc.right) {
                acc.right = right;
            }

            if (top < acc.top) {
                acc.top = top;
            }

            if (bottom > acc.bottom) {
                acc.bottom = bottom;
            }

            return acc;
        }, initialRect),
    );

    // If we don't have any measurements, return a completely empty rectangle
    if (boundingRect.left === Infinity || boundingRect.top === Infinity) return EMPTY_RECTANGLE;

    return boundingRect;
});
