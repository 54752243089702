// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import { isEmpty, defer } from 'lodash';

// Utils
import { stopPropagationOnly } from '../../utils/domUtil';
import { propIn } from '../../../common/utils/immutableHelper';

// Measurements
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';

// Actions
import { startAttachMode } from '../../utils/dnd/dndActions';

// Components
import EdgeDragHandle from './EdgeDragHandle';

// Constants
import { LINE_EDGE_DND_TYPE } from '../../../common/lines/lineConstants';
import { AttachModeType } from '../../utils/dnd/dndConstants';

const EdgeDragHandleDragSource = (props) => {
    const { edge, element, elementId, updateLineEdge, toggleLineVisibility, pos, dispatchPointDragStart } = props;

    const dispatch = useDispatch();
    const dispatchStartAttachMode = (hoveredElementId) =>
        dispatch(startAttachMode(hoveredElementId, AttachModeType.LINE_EDGE));

    const [dragProps, connectDragSource, connectDragPreview] = useDrag(
        {
            item: { id: elementId, type: LINE_EDGE_DND_TYPE },
            begin: () => {
                defer(toggleLineVisibility);
                dispatchPointDragStart();

                // Begin in the snapped mode, if currently snapped to a fixed point
                const snappedElementId = propIn(['content', edge, 'elementId'], element);
                const isFixed = propIn(['content', edge, 'fixed'], element);

                if (isFixed && snappedElementId) {
                    requestAnimationFrame(() => {
                        dispatchStartAttachMode(snappedElementId);
                    });
                }

                const initialScrollPoint = measurementsRegistry.getCanvasViewportScrollAsPoint();

                return {
                    id: elementId,
                    type: LINE_EDGE_DND_TYPE,
                    element,
                    edge,
                    pos,
                    initialScrollPoint,
                };
            },
            end: (item, monitor) => {
                // Doing nothing if it was not dropped onto a compatible target
                const dropResult = monitor.getDropResult();
                if (!monitor.didDrop() || isEmpty(dropResult) || dropResult.ignore) {
                    toggleLineVisibility();
                    return;
                }

                const { newPosition, newControl } = dropResult;

                if (!newPosition) {
                    toggleLineVisibility();
                    return;
                }

                updateLineEdge({ edge, newPosition, newControl });
                toggleLineVisibility();
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        },
        [edge, element, elementId, updateLineEdge, toggleLineVisibility, pos, dispatchPointDragStart],
    );

    return (
        <EdgeDragHandle
            onMouseDown={stopPropagationOnly}
            connectDragSource={connectDragSource}
            connectDragPreview={connectDragPreview}
            isHitArea
            {...dragProps}
            {...props}
        />
    );
};

const ConnectedEdgeDragHandle = (props) => {
    const { show } = props;
    if (!show) return null;

    return <EdgeDragHandleDragSource {...props} />;
};

ConnectedEdgeDragHandle.propTypes = {
    show: PropTypes.bool,
    pos: PropTypes.object,
    edge: PropTypes.string.isRequired,
    element: PropTypes.any,
    elementId: PropTypes.string.isRequired,
    updateLineEdge: PropTypes.func,
    toggleLineVisibility: PropTypes.func,
    dispatchPointDragStart: PropTypes.func,
    gridSize: PropTypes.number,
    origin: PropTypes.object,
};

EdgeDragHandleDragSource.propTypes = ConnectedEdgeDragHandle.propTypes;

export default ConnectedEdgeDragHandle;
