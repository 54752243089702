import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const DrawingInputTouchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon drawing-input-touch"
        {...props}
    >
        <path
            fill="#48505E"
            fillRule="evenodd"
            d="M12.228 5.684c1.266 0 2.318.983 2.404 2.208l.005.16v2.763l2.946.527c1.623.29 2.683 1.858 2.358 3.426l-.04.167-1.275 4.82a2.394 2.394 0 0 1-1.74 1.687l-.17.036-2.853.494a1.899 1.899 0 0 1-1.792-.666l-.093-.125-.78-1.15a2.93 2.93 0 0 0-.666-.699l-.201-.138-4.96-3.106a.785.785 0 0 1-.368-.732c.17-2.014 2.272-3.331 4.186-2.68l.168.064.461.187V8.053c0-1.299 1.089-2.369 2.41-2.369Zm0 1.5c-.46 0-.846.345-.902.763l-.008.106v6.359a.5.5 0 0 1-.688.463l-1.835-.745c-.856-.346-1.818.088-2.168.857l-.038.093 4.539 2.844c.446.28.837.635 1.157 1.051l.154.213.778 1.148a.392.392 0 0 0 .314.164l.076-.006L16.461 20a.906.906 0 0 0 .679-.526l.035-.102 1.279-4.83c.196-.723-.26-1.487-1.001-1.693l-.134-.03-3.358-.6a1 1 0 0 1-.824-.985V8.053c0-.465-.41-.869-.91-.869Zm0-5.184c3.39 0 6.157 2.72 6.157 6.053v.204a.775.775 0 0 1-.849.772.84.84 0 0 1-.76-.871l.003-.105c0-2.48-2.028-4.474-4.551-4.474S7.677 5.573 7.677 8.053c0 .273.052.618.156 1.032a.838.838 0 0 1-.561 1.004l-.059.016a.785.785 0 0 1-.941-.587c-.134-.58-.201-1.068-.201-1.465C6.07 4.719 8.837 2 12.228 2Z"
        />
    </svg>
);
const Memo = memo(DrawingInputTouchIcon);
export default Memo;
