import { defer } from 'lodash';
import { RefObject, useCallback, useRef } from 'react';

import { BoardSections } from '../../../common/boards/boardConstants';
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { Point } from '../../../common/maths/geometry/pointTypes';
import { propIn } from '../../../common/utils/immutableHelper';
import { useMeasurementsDispatch } from '../../components/measurementsStore/MeasurementsProvider';
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';
import { poiAddMultiple, PointOfInterest, poiRemoveMultiple } from '../../components/pointsOfInterest/poiActions';
import { PoiType } from '../../components/pointsOfInterest/poiConstants';

const getTranslatedMeasurement = (element: Element, translation: Point) => {
    const domRect = element.getBoundingClientRect();
    const { x, y } = translation;
    return {
        x: domRect.x + x,
        y: domRect.y + y,
        width: domRect.width,
        height: domRect.height,
        top: domRect.top + y,
        right: domRect.right + x,
        bottom: domRect.bottom + y,
        left: domRect.left + x,
    };
};

const getElementId = propIn(['id']);
const getBoardId = propIn(['location', 'parentId']);
const getSection = propIn(['location', 'section']);

export default ({ innerRef, element }: { innerRef: RefObject<HTMLElement>; element: ImMNElement }) => {
    const measurementsDispatch = useMeasurementsDispatch();
    const registeredPois = useRef<string[]>([]);
    const refreshPois = useCallback(() => {
        const elementId = getElementId(element);
        const currentBoardId = getBoardId(element);
        const section = getSection(element);

        if (!elementId || !currentBoardId || !section) return;

        // This callback occurs before the html decorations are applied, but we need them
        // for the measurements! So defer the rest to let Tiptap finish mounting them.
        defer(() => {
            const poiElements = innerRef.current?.querySelectorAll('.SearchHighlightSpan');
            const toUnregister = new Set(registeredPois.current);
            const registered: string[] = [];
            const pois: PointOfInterest[] = Array.from(poiElements || []).map((poiElement) => {
                const index = poiElement.getAttribute('data-poi-id');
                const id = `${elementId}-${index}`;
                toUnregister.delete(id);
                registered.push(id);

                const translation =
                    section === BoardSections.CANVAS
                        ? measurementsRegistry.getCanvasViewportTranslation()
                        : { x: 0, y: 0 };

                return {
                    id,
                    poiType: PoiType.search,
                    currentBoardId,
                    section,
                    targetRect: getTranslatedMeasurement(poiElement, translation),
                    data: { count: 1 },
                };
            });

            if (pois.length) measurementsDispatch(poiAddMultiple({ pois }));
            if (toUnregister.size) measurementsDispatch(poiRemoveMultiple({ ids: Array.from(toUnregister) }));
            registeredPois.current = registered;
        });
    }, []);

    return refreshPois;
};
