import * as Immutable from 'immutable';

import {
    ONE_TRUST_HIDE_BANNER,
    ONE_TRUST_SET_CONSENT_MODEL,
    ONE_TRUST_SET_STATUS,
    ONE_TRUST_SHOW_BANNER,
    ONE_TRUST_STATUS,
} from './oneTrustConstants';

const initialState = Immutable.Map({
    status: ONE_TRUST_STATUS.UNFETCHED,
    consentModel: null,
    showBanner: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case ONE_TRUST_SET_STATUS:
            return state.set('status', action.status);
        case ONE_TRUST_SET_CONSENT_MODEL:
            return state.set('consentModel', action.consentModel);
        case ONE_TRUST_SHOW_BANNER:
            return state.set('showBanner', true);
        case ONE_TRUST_HIDE_BANNER:
            return state.set('showBanner', false);
        default:
            return state;
    }
};
