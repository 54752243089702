import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileNotificationsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        viewBox="0 0 25 24"
        className="Icon mobile-notifications"
        {...props}
    >
        <path
            fill="#A3A7AE"
            fillRule="evenodd"
            d="M2.48 19v-2a2 2 0 0 0 2-2l.463-6.02a7.56 7.56 0 0 1 15.074 0L20.48 15a2 2 0 0 0 2 2v2h-6.75v.5a3.25 3.25 0 0 1-6.5 0V19H2.48zm8.25 0v.5a1.75 1.75 0 0 0 3.5 0V19h-3.5zm1.75-15.5a6.06 6.06 0 0 0-6.041 5.594l-.46 5.971A3.49 3.49 0 0 1 4.93 17.5h15.102a3.49 3.49 0 0 1-1.04-2.216l-.01-.219-.46-5.97A6.06 6.06 0 0 0 12.48 3.5z"
        />
    </svg>
);
const Memo = memo(MobileNotificationsIcon);
export default Memo;
