// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { stopPropagationOnly } from '../../../utils/domUtil';
import { getTitle } from '../../../../common/elements/utils/elementPropertyUtils';
import { hasCommandModifier } from '../../../utils/keyboard/keyboardUtility';

// Selectors
import { getCurrentBoardIdFromState } from '../../../reducers/currentBoardId/currentBoardIdSelector';
import { cloneInstanceBoardIdsSelector } from '../cloneInstancesSelector';
import { getElement } from '../../selectors/elementSelector';

// Actions
import { navigateToBoard } from '../../../reducers/navigationActions';

// Components
import BreadcrumbIcon from '../../../workspace/breadcrumbs/Components/BreadcrumbIcon';
import Icon from '../../../components/icons/Icon';

// Styles
import './CardCloneInstancesPopupContentMultiBoards.scss';

const mapEntryStateToProps = createStructuredSelector({
    element: getElement,
    currentBoardId: getCurrentBoardIdFromState,
});

const mapEntryDispatchToProps = (dispatch, ownProps) => ({
    dispatchNavigateToBoard: (event) =>
        dispatch(
            navigateToBoard({
                boardId: ownProps.elementId,
                keepSelection: false,
                newTab: hasCommandModifier(event),
            }),
        ),
});

let CloneInstanceBoardEntry = (props) => {
    const { element, elementId, currentBoardId, dispatchNavigateToBoard } = props;

    if (elementId === currentBoardId) return null;

    return (
        <div className="CloneInstanceBoardEntry" onClick={dispatchNavigateToBoard}>
            <BreadcrumbIcon element={element} />
            <div className="title">{getTitle(element)}</div>
            <Icon name="chevron-right" />
        </div>
    );
};

CloneInstanceBoardEntry.propTypes = {
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string,
    element: PropTypes.object,
    dispatchNavigateToBoard: PropTypes.func,
};

CloneInstanceBoardEntry = connect(mapEntryStateToProps, mapEntryDispatchToProps)(CloneInstanceBoardEntry);

const mapStateToProps = createStructuredSelector({
    reachableInstanceBoardIds: cloneInstanceBoardIdsSelector,
});

const CardCloneInstancesPopupContentMultiBoards = (props) => {
    const { reachableInstanceBoardIds } = props;

    return (
        <div className="CardCloneInstancesPopupContentMultiBoards" onClick={stopPropagationOnly}>
            {reachableInstanceBoardIds.map((boardId) => (
                <CloneInstanceBoardEntry key={boardId} elementId={boardId} />
            ))}
        </div>
    );
};

CardCloneInstancesPopupContentMultiBoards.propTypes = {
    reachableInstanceBoardIds: PropTypes.object,
};

export default connect(mapStateToProps)(CardCloneInstancesPopupContentMultiBoards);
