// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { constant } from 'lodash/fp';
import { identity } from 'lodash';
import { connect } from 'react-redux';

// Decorators
import sketchResizeDecorator from './sketchResizeDecorator';
import elementResizeDecorator from '../../resizing/elementResizeDecorator';
import elementLineEdgeDropTarget from '../../line/elementLineEdgeDropTarget';
import dontUpdateForKeys from '../../../utils/milanoteRecompose/dontUpdateForKeys';
import captionContainerDecorator from '../../../components/caption/captionContainerDecorator';

// Actions
import { deselectAllElements } from '../../selection/selectionActions';
import { navigateToElement } from '../../../reducers/navigationActions';

// Components
import Sketch from './Sketch';

// Constants
import { SKETCH_MIN_WIDTH } from '../../../../common/drawings/sketches/sketchConstants';

const mapDispatchToProps = (dispatch) => ({
    dispatchDeselectAndNavigateToElement: (elementId) => {
        dispatch(deselectAllElements({ sync: false }));
        dispatch(navigateToElement({ elementId }));
    },
});

@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys', 'canDrop'])
@captionContainerDecorator
@elementResizeDecorator({ getMinWidth: constant(SKETCH_MIN_WIDTH), enableHeight: true })
@sketchResizeDecorator
@connect(null, mapDispatchToProps)
class SketchContainer extends React.Component {
    handleKey = (event) => {
        const { isSelected, captionKeyHandler } = this.props;

        if (!isSelected) return;
        captionKeyHandler(event, this.props);
    };

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div className="SketchContainer" onKeyDown={this.handleKey} tabIndex="-1">
                <Sketch {...this.props} />
            </div>,
        );
    }
}

SketchContainer.propTypes = {
    element: PropTypes.object.isRequired,

    canConnectLineEdge: PropTypes.bool,
    isSelected: PropTypes.bool,

    connectLineEdgeDropTarget: PropTypes.func,
    captionKeyHandler: PropTypes.func,
};

export default SketchContainer;
