import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardAccessDeniedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon board-access-denied"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <circle cx={8} cy={8} r={8} fill="#333" />
            <rect width={9.6} height={3.2} x={3.2} y={6.4} fill="#FFF" rx={0.8} />
        </g>
    </svg>
);
const Memo = memo(BoardAccessDeniedIcon);
export default Memo;
