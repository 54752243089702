/**
 * Gets UTM data from an object, typically the URL parameters.
 */
export const getUtmDataFromObject = (obj: { [key: string]: string }) => {
    const utmData: { [key: string]: string } = {};

    if (!obj) return utmData;

    for (const param in obj) {
        if (param.indexOf('utm_') !== 0) continue;

        utmData[param] = obj[param];
    }

    return utmData;
};
