// Lib
import React from 'react';
import classNames from 'classnames';

// Components
import DualColorIcon from './DualColorIcon';

// Styles
import './ColorToolIcon.scss';

type ColorToolIconProps = {
    className?: string;
    color: string | null;
    secondaryColor: string | null;
    backgroundColor: string | null;
    isActive: boolean;
    supportColorBar: boolean;
    supportBackgroundColor: boolean;
    selectedType: string;
    selectedStatus: string | null;
};

const ColorToolIcon = ({
    className,
    color,
    backgroundColor,
    isActive,
    selectedType,
    selectedStatus,
    supportColorBar,
    supportBackgroundColor,
    secondaryColor,
}: ColorToolIconProps) => {
    const background = supportColorBar ? backgroundColor : color;

    const showColorBar = supportColorBar && color;

    // We should never have a secondary color without a background color.
    // This check is just to prevent a bug where clones don't show background colors but do show the secondary color.
    const showDualColor = !!secondaryColor && background;

    const containerStyle = background ? { backgroundColor: background } : {};

    return (
        <div
            className={classNames('ColorToolIcon', selectedType, selectedStatus, className, {
                active: isActive,
                'no-color': !color,
                'show-color-bar': showColorBar,
                'show-dual-color': showDualColor,
                'support-color-bar': supportColorBar,
                'support-background-color': supportBackgroundColor,
            })}
        >
            <div
                className={classNames('color-icon-container', {
                    'background-color-target': supportBackgroundColor,
                    'color-target': !supportBackgroundColor,
                })}
                style={containerStyle}
            >
                {showColorBar && (
                    <div
                        className={classNames('color-bar-icon color-target', {
                            'background-color-target': !color,
                        })}
                        style={{ backgroundColor: color }}
                    ></div>
                )}

                {showDualColor && <DualColorIcon secondaryColor={secondaryColor} selectedType={selectedType} />}
            </div>
        </div>
    );
};

export default ColorToolIcon;
