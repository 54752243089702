import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const OldNotificationTemplateIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        viewBox="0 0 50 50"
        className="Icon old-notification-template"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={49} height={49} x={0.5} y={0.5} fill="#FFF" stroke="#C6C6C6" rx={5} />
            <rect width={18} height={18} x={11.5} y={11.5} fill="#D5D5D5" stroke="#C6C6C6" rx={2.778} />
            <rect
                width={18}
                height={18}
                x={20.5}
                y={20.5}
                fill="#B57FE5"
                stroke="#000"
                strokeOpacity={0.2}
                rx={2.778}
            />
        </g>
    </svg>
);
const Memo = memo(OldNotificationTemplateIcon);
export default Memo;
