// Lib
import * as Immutable from 'immutable';

// Constants
import { CURRENT_USER_INCREMENT_TICK, CURRENT_USER_SET } from './currentUserConstants';

const initialState = Immutable.Map({
    clientId: 'NULLID',
    clientTick: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_USER_SET:
            return Immutable.fromJS({
                clientId: action.user && action.user.clientId,
                clientTick: action.user && action.user.clientTick,
            });
        case CURRENT_USER_INCREMENT_TICK:
            return state.update('clientTick', (tick) => tick + action.count);
        default:
            return state;
    }
};
