// Lib
import { EditorState } from 'draft-js';

// Utils
import removeBlockStyle from '../../customRichUtils/removeBlockStyle';

// Constants
import { EMPTY_BACKSPACE, EMPTY_DELETE, REMOVE_BLOCK_STYLE } from './commandConstants';
import { EditorChangeType, HANDLER_RETURN_VALUES } from '../../draftjsConstants';

export default (command, editorState, eventTimeStamp, { getProps, setEditorState }) => {
    if (command === REMOVE_BLOCK_STYLE) {
        const contentState = removeBlockStyle(editorState);
        const newEditorState = EditorState.push(editorState, contentState, EditorChangeType.CHANGE_BLOCK_TYPE);
        setEditorState(newEditorState);
        return HANDLER_RETURN_VALUES.handled;
    }

    if (command !== EMPTY_DELETE && command !== EMPTY_BACKSPACE) return HANDLER_RETURN_VALUES.notHandled;

    const { saveCurrentContent, onEmptyBackspace } = getProps();
    saveCurrentContent && saveCurrentContent();
    onEmptyBackspace && onEmptyBackspace({ forward: command === EMPTY_DELETE });
    return HANDLER_RETURN_VALUES.handled;
};
