import { createSelector } from 'reselect';

import { DEFAULT_PLAYER_STATE } from './mediaPlayerConstants';

export const getMediaPlayer = (state, { playerId }) =>
    state.getIn(['app', 'mediaPlayer', playerId]) || DEFAULT_PLAYER_STATE;

export const getMediaPlayerProgress = createSelector(getMediaPlayer, (player) => player.get('progress'));

export const getMediaPlayerDuration = createSelector(getMediaPlayer, (player) => player.get('duration'));

export const getMediaPlayerPlaying = createSelector(getMediaPlayer, (player) => player.get('playing'));

export const getMediaPlayerVolume = createSelector(getMediaPlayer, (player) => player.get('volume'));

export const getMediaPlayerMute = createSelector(getMediaPlayer, (player) => player.get('mute'));

export const getMediaPlayerDragPreview = createSelector(getMediaPlayer, (player) => player.get('dragPreview'));

export const getMediaPlayerError = createSelector(getMediaPlayer, (player) => player.get('error'));

export const getMediaPlayerHasBeenPlayed = createSelector(getMediaPlayer, (player) => player.get('hasBeenPlayed'));
