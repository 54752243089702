import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarTitleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-title"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#1B2536"
                d="M4 32h24a4 4 0 0 0 4-4V12H0v16a4 4 0 0 0 4 4zM0 11h32V4a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v7z"
                className="background decoration"
            />
            <rect width={20} height={1.5} x={6} y={5} fill="#48505E" className="path" rx={0.75} />
        </g>
    </svg>
);
const Memo = memo(ToolbarTitleIcon);
export default Memo;
