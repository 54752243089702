import { createStore, applyMiddleware } from 'redux';
import { identity } from 'lodash/fp';
import { getSocketManager } from '../utils/socket/socketClient';
import getMiddleware from './getMiddleware';

// Use the local storage version of the persisted send buffer
import createLocalStoragePersistedSendBuffer from '../utils/socket/buffer/localStoragePersistedSendBuffer';

import rootReducer from '../reducers';
import { enableReducerBatching } from './reduxBulkingMiddleware';
import { isGlobalDebugEnabled } from '../debug/debugUtil';

export default function configureStore(initialState, thunkArg) {
    const socketManager = getSocketManager(createLocalStoragePersistedSendBuffer);

    const middlewareList = getMiddleware({
        socketManager,
        thunkArg,
    });

    const composeEnhancers = (isGlobalDebugEnabled() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || identity;
    const middleware = applyMiddleware.apply(null, middlewareList);

    const store = createStore(enableReducerBatching(rootReducer), initialState, composeEnhancers(middleware));
    socketManager.subscribeToStore(store);

    return store;
}
