// Utils
import { prop, propIn } from '../../../../../common/utils/immutableHelper';
import { isAsyncEntityFetched, isAsyncEntityFetching } from './asyncResourceUtils';

// Constants

// Types
import { FALLBACK_ENTITY_ID, ResourceTypes } from './asyncResourceConstants';
import { AsyncResourceEntity, AsyncResourceType } from './reducers/asyncResourceReducerTypes';

// NOTE: This is important to keep separate, because the state will likely be an Immutable
//  object, but the async resources state is a POJO, so two separate prop & propIn invocations
//  are required to ensure it works correctly
export const getAsyncResourcesState = prop('asyncResources');

export const getAsyncResourcesTypeState =
    (resourceName: ResourceTypes) =>
    (state: object): AsyncResourceType =>
        prop(resourceName, getAsyncResourcesState(state)) || {};

export const getAsyncResourceEntityState = (
    state: object,
    resourceName: ResourceTypes,
    id?: string,
): AsyncResourceEntity =>
    id
        ? propIn([resourceName, id], getAsyncResourcesState(state))
        : propIn([resourceName, FALLBACK_ENTITY_ID], getAsyncResourcesState(state));

export const getIsAsyncResourceEntityFetching = (state: object, resourceName: ResourceTypes, id?: string): boolean =>
    isAsyncEntityFetching(getAsyncResourceEntityState(state, resourceName, id));

export const getIsAsyncResourceEntityFetched = (state: object, resourceName: ResourceTypes, id?: string): boolean =>
    isAsyncEntityFetched(getAsyncResourceEntityState(state, resourceName, id));
