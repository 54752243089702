export const PUBLISH_PREVIEW_PATHNAME = 'publish-preview';

export const PREVIEW_MODE_START = 'PREVIEW_MODE_START';
export const PREVIEW_MODE_END = 'PREVIEW_MODE_END';

export const PUBLISH_PREVIEW_PASSWORD_VALIDATED = 'PUBLISH_PREVIEW_PASSWORD_VALIDATED';

export const PREVIEW_MODES = {
    DEFAULT: 'DEFAULT',
    PUBLISH_PREVIEW: 'PUBLISH_PREVIEW',
};
