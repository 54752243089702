import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CloseXIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11}
        height={11}
        viewBox="0 0 11 11"
        className="Icon close-x"
        {...props}
    >
        <path
            fill="#777"
            fillRule="evenodd"
            d="m6.63 5.857 3.89-3.89a.674.674 0 1 0-.954-.955l-3.891 3.89-3.89-3.89a.674.674 0 1 0-.955.955l3.89 3.89-3.89 3.89a.674.674 0 1 0 .954.955l3.891-3.89 3.89 3.89a.674.674 0 1 0 .955-.954l-3.89-3.89Z"
        />
    </svg>
);
const Memo = memo(CloseXIcon);
export default Memo;
