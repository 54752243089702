import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMapIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon element-map"
        {...props}
    >
        <defs>
            <filter
                id="ElementMapIcon__a"
                width="131.2%"
                height="131.2%"
                x="-15.6%"
                y="-12.5%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5} />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.196078431 0 0 0 0 0.231372549 0 0 0 0 0.290196078 0 0 0 0.15 0"
                />
            </filter>
            <rect id="ElementMapIcon__b" width={32} height={32} x={0} y={0} rx={2} />
            <path id="ElementMapIcon__c" d="M2 0h28a2 2 0 0 1 2 2v22H0V2a2 2 0 0 1 2-2z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use xlinkHref="#ElementMapIcon__b" fill="#000" filter="url(#ElementMapIcon__a)" />
            <use xlinkHref="#ElementMapIcon__b" fill="#FFF" />
            <mask id="ElementMapIcon__d" fill="#fff">
                <use xlinkHref="#ElementMapIcon__c" />
            </mask>
            <use xlinkHref="#ElementMapIcon__c" fill="#6FC562" />
            <path fill="#D8D8D8" d="M18-2 0-3v28.556L8.5 26l1.5-8 5-4.888L21.5 10z" mask="url(#ElementMapIcon__d)" />
            <path fill="#7FB6F3" d="m18.768-2 7 28.556H32V-2z" mask="url(#ElementMapIcon__d)" />
            <path
                fill="#F3F3F4"
                d="m22.11 10.112 1.317 3.776-4.744 1.656a8.999 8.999 0 0 0-5.883 6.847l-.05.298-1.005 6.611-3.954-.6 1.004-6.612a13.004 13.004 0 0 1 8.262-10.21l.308-.111 4.744-1.655z"
                mask="url(#ElementMapIcon__d)"
            />
            <path
                fill="#ECC934"
                fillRule="nonzero"
                d="m18.651-2.673 10 28-3.766 1.346-10-28z"
                mask="url(#ElementMapIcon__d)"
            />
            <g mask="url(#ElementMapIcon__d)">
                <g transform="translate(4 4)">
                    <path
                        fill="#E84C4C"
                        d="M6.867 7.165A4.333 4.333 0 0 0 8 4.223C8 1.891 6.209 0 4 0S0 1.891 0 4.223c0 1.145.433 2.18 1.133 2.942l.01.013.014.014.031.034 2.498 2.637a.428.428 0 0 0 .628 0l2.498-2.637.031-.034.013-.014c.005-.004.007-.01.011-.013z"
                    />
                    <circle cx={4} cy={4.132} r={1.5} fill="#FFF" />
                </g>
            </g>
        </g>
    </svg>
);
const Memo = memo(ElementMapIcon);
export default Memo;
