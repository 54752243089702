// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { gridPointsToPixels } from '../../../utils/grid/gridUtils';
import { getColor, getIsTransparentImage } from '../../../../common/elements/utils/elementPropertyUtils';
import { getColorCssValue } from '../../utils/elementColorStyleUtils';

// Components
import ElementImage from '../../../components/images/ElementImage';

// Constants
import { IMAGE_TYPES } from '../../../../common/media/mediaConstants';

// Styles
import './BoardImage.scss';

const getStyle = (size, element) => {
    const isTransparent = getIsTransparentImage(element);

    const styles = {
        width: size,
        height: size,
    };

    if (isTransparent) return styles;

    const color = getColor(element);
    const colorHex = getColorCssValue(color);

    if (!colorHex) return styles;

    styles.background = colorHex;

    return styles;
};

const BoardImage = ({ element, size, gridSize }) => {
    if (!element) return null;

    const sizePx = gridPointsToPixels(size, gridSize);

    return (
        <div className="BoardImage" style={getStyle(sizePx, element)}>
            <ElementImage
                imageType={IMAGE_TYPES.BOARD}
                element={element}
                widthPx={sizePx}
                forcedSize={{ width: sizePx, height: sizePx }}
            />
        </div>
    );
};

BoardImage.propTypes = {
    element: PropTypes.object,
    imageData: PropTypes.object,
    size: PropTypes.number,
    gridSize: PropTypes.number,
};

export default BoardImage;
