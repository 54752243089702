import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarItalicIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-italic"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#EBEDEE" rx={4} />
            <path
                fill="#323B4A"
                d="M14.954 10H20l-.281 1.81h-1.583l-2.074 8.38h1.582L17.046 22H12l.193-1.81h1.583l2.075-8.38h-1.583z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarItalicIcon);
export default Memo;
