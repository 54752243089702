// Lib
import * as Immutable from 'immutable';

// Constants
import { ANNOTATION_MODE_START, ANNOTATION_MODE_END } from './annotationConstants';

const initialState = Immutable.fromJS({
    enabled: false,
    elementId: undefined,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case ANNOTATION_MODE_START:
            return state.set('enabled', true).set('elementId', action.elementId);
        case ANNOTATION_MODE_END:
            return initialState;
        default:
            return state;
    }
};
