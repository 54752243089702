// Lib
import React from 'react';
import loadable from 'react-loadable';

// Spinner
import Spinner from '../../../../../components/loaders/Spinner';

const LoadableTaskDueDatePopupContent = loadable({
    loader: () => import(/* webpackChunkName: "task-due-date-popup" */ './TaskDueDatePopupContent'),
    loading: () => <Spinner show />,
});

export default LoadableTaskDueDatePopupContent;
