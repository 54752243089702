// Utils
import http from '../utils/services/http';

export const fetchNotifications = ({ after, before, notificationIds }) =>
    http({
        url: 'notifications',
        timeout: 15000,
        params: {
            after,
            before,
            ids: notificationIds && notificationIds.join(','),
        },
    }).then((res) => res.data);
