// Lib
import React from 'react';
import PropTypes from 'prop-types';

const AbsolutePositioner = (props) => {
    const { children, x, y, className } = props;

    const style = React.useMemo(
        () => ({
            position: 'absolute',
            top: y,
            left: x,
        }),
        [x, y],
    );

    return (
        <div className={`AbsolutePositioner ${className}`} style={style}>
            {children}
        </div>
    );
};

AbsolutePositioner.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
};

export default AbsolutePositioner;
