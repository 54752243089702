// Utils
import { prop } from '../utils/immutableHelper';
import { getIsCollapsed } from '../elements/utils/elementPropertyUtils';
import { isLocationAttached, isLocationInbox } from '../elements/utils/elementLocationUtils';

// Constants
import { COMMENT_ID_PREFIX } from './commentConstants';

// Individual comment accessors
export const getCommentId = prop('_id');
export const getThreadId = prop('threadId');
export const getText = prop('text');
export const getCreationTimestamp = prop('createdAt');
export const getUpdatedTimestamp = prop('updatedAt');
export const getUserId = prop('userId');
export const getCommentContent = prop('content');

// Keeping this simple for now
export const isCommentId = (id) => id && id.startsWith(COMMENT_ID_PREFIX);

// The comment thread will display as collapsed if the thread is force collapsed, or if it's attached to an element.
export const displayCommentAsCollapsed = (element) =>
    (getIsCollapsed(element) && !isLocationInbox(element)) || isLocationAttached(element);
