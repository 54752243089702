import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateSelectedElements } from '../../../../../element/actions/elementActions';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Constants
import { LINE_MARKER_STYLE } from '../../../../../../common/lines/lineConstants';

const mapStateToProps = (state, ownProps) => ({
    isActive: ownProps.selectedElements.first()?.getIn(['content', ownProps.styleProp]) === LINE_MARKER_STYLE.ARROW,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateSelectedElements: ({ changes }) => dispatch(updateSelectedElements({ changes })),
});

class ArrowTool extends React.Component {
    toggleArrow = () => {
        const { isActive, styleProp, dispatchUpdateSelectedElements } = this.props;
        const changes = {
            [styleProp]: isActive ? LINE_MARKER_STYLE.NONE : LINE_MARKER_STYLE.ARROW,
        };
        dispatchUpdateSelectedElements({ changes });
    };

    render() {
        const { iconDirection, isActive } = this.props;

        return (
            <ToolbarTool {...this.props} onPointerDown={this.toggleArrow} isActive={isActive}>
                <Icon {...this.props} name={`toolbar-arrow-${iconDirection}`} />
            </ToolbarTool>
        );
    }
}

ArrowTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
    styleProp: PropTypes.string.isRequired,
    selectedElements: PropTypes.object.isRequired,
    dispatchUpdateSelectedElements: PropTypes.func,
    iconDirection: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArrowTool);
