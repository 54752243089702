// Utils
import getNewLine from './getNewLine';

/**
 * Return a list of line in this text
 */
const getLines = (text, sep) => {
    const separator = sep || getNewLine(text);
    return text.split(separator);
};

export default getLines;
