import React from 'react';
import PropTypes from 'prop-types';

import largeIcons from './large';

const Icon = ({ name, iconSize, children }) => {
    const RenderIcon = largeIcons[name];
    if (!RenderIcon) return children || null;

    return <RenderIcon />;
};

Icon.propTypes = {
    name: PropTypes.string,
    iconSize: PropTypes.string,
    children: PropTypes.element,
};

export default Icon;
