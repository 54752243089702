// Lib
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Utils
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Components
import RichTextToolList from '../../../workspace/toolbar/components/selectionTools/richContentTools/RichTextToolList';
import ToolbarMeasure from '../../../workspace/toolbar/components/ToolbarMeasure';

// Constants
import { DOCUMENT_MODAL_EDITOR_KEY } from './documentModalConstants';
import { GRID } from '../../../utils/grid/gridConstants';

// Styles
import './DocumentModalDraftJsSidebar.scss';

// FIXME Change the editorKey logic
const DocumentModalDraftJsSidebar = (props) => {
    const { isEditable } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);

    const [availableHeight, setAvailableHeight] = useState(0);

    return (
        <div
            className={classNames('DocumentModalDraftJsSidebar', {
                'hide-sidebar-content': !isEditable || isPlatformLegacyMobileApp(platformDetails),
            })}
        >
            <ToolbarMeasure setAvailableHeight={setAvailableHeight} unavailableHeight={0} />

            {isEditable && (
                <RichTextToolList
                    {...props}
                    gridSize={10}
                    gridSizeName={GRID.LARGE.name}
                    editorKey={DOCUMENT_MODAL_EDITOR_KEY}
                    availableHeight={availableHeight}
                    unavailableHeight={0}
                />
            )}
        </div>
    );
};

DocumentModalDraftJsSidebar.propTypes = {
    isEditable: PropTypes.bool,
};

export default DocumentModalDraftJsSidebar;
