// Lib
import Core from 'handsontable/core';
import { sum } from 'lodash';

// Utils
import { getContainerWidth, getTableElementFromHotInstance, getTableScrollableElement } from './tableDOMUtils';
import { getMaxFromSelections } from './tableCellSelectionUtils';
import { lastColWithContent, lastRowWithContent } from './tableDataUtils';
import { parseCssPx } from '../../../utils/cssUtil';
import { isInList } from '../../../../common/inList/inListUtils';

// Types
import { TableColWidthsGU } from '../../../../common/table/TableTypes';

// Constants
import {
    TABLE_DEFAULT_COL_WIDTH,
    TABLE_DEFAULT_ROW_HEIGHT,
    TABLE_MIN_ROW_COUNT,
} from '../../../../common/table/tableConstants';

/**************************
 * TABLE COLUMN SIZING
 **************************/

export const getColWidthsResizeWidth = (
    inList: string | null,
    hotTableContainerRef: React.MutableRefObject<HTMLDivElement | null>,
    getContextZoomScale?: () => number,
) => {
    if (!isInList(inList)) return null;

    const resizeToWidth =
        getContainerWidth(hotTableContainerRef?.current) ||
        getTableScrollableElement(hotTableContainerRef.current)?.scrollWidth;

    if (!resizeToWidth) return null;

    const zoomScale = getContextZoomScale ? getContextZoomScale() : 1;
    return resizeToWidth / zoomScale;
};

/**
 * Get a list of column widths in Px format. Account for the borders and resizing within lists.
 */
export const convertColWidthsGUtoPx = (
    colWidthsGU: Array<number>,
    gridSize: number,
    resizeToWidth: number | null = null,
): Array<number> => {
    // values for resizing into column/quicknotes etc
    const sumOfColsGu = sum(colWidthsGU);
    const sumOfColsPx = sumOfColsGu * gridSize;
    const nCols = colWidthsGU.length;

    // We will resize a table up to this many px units wider than the container width
    const resizeThresholdGu = 10;
    const resizeThresholdPx = resizeThresholdGu * gridSize;

    // in a list container and table is small enough to be resized
    const shouldResizeToFit = resizeToWidth && sumOfColsPx < resizeToWidth + resizeThresholdPx;
    const multiplier = shouldResizeToFit && resizeToWidth ? (resizeToWidth + 2) / sumOfColsGu : gridSize;

    return colWidthsGU.reduce<Array<number>>((acc, colWidthGU, i) => {
        if (i === nCols - 1) {
            acc.push(sumOfColsGu * multiplier - sum(acc) - 2);
        } else {
            acc.push(Math.round(colWidthGU * multiplier));
        }

        return acc;
    }, []);
};

/**
 * Resize selected columns given a dif in px, accounting for the min column width
 */
export const getNewColumnWidthsPx = (
    selectedCols: number[],
    initialColWidths: number[],
    minColWidth: number,
    dif: number,
) => {
    const resizingColCount = selectedCols.length;
    const difPerCol = dif / resizingColCount;
    const newColWidths = initialColWidths.map((initial: number) => Math.max(initial + difPerCol, minColWidth));

    // if dif is positive, no need to calculate min widths
    if (dif > 0) return newColWidths;

    // Calculate the extra width that still needs to be divided between the columns that aren't already at min width
    let overflow = sum(newColWidths) - sum(initialColWidths) - dif;
    if (overflow === 0) return newColWidths;

    // Keep looping until there is no overflow
    // Ideally we would keep going until overflow is 0 but this way we can avoid too much looping, and it's not a visible difference
    while (overflow > 0.5) {
        // how many col widths are more than the min
        const colsAboveMin = newColWidths.filter((w) => w > minColWidth).length;

        // If all cols are at minimum, return the current newColWidths
        if (colsAboveMin === 0) return newColWidths;

        const removePerCol = overflow / colsAboveMin;

        for (let i = 0; i < resizingColCount; i++) {
            // We don't want to edit the width of a column that is already at the min
            if (newColWidths[i] === minColWidth) continue;

            // current new width, minus the additional width per col
            const newWidth: number = newColWidths[i] - removePerCol;

            // If the new width is less than the min, add the difference to the overflow and set the width to the min
            if (newWidth < minColWidth) {
                overflow += minColWidth - newWidth;
                newColWidths[i] = minColWidth;
                continue;
            }
            // If the new width is more than the min, subtract the additional width from the overflow and set the width
            overflow -= removePerCol;
            newColWidths[i] = newWidth;
        }
    }

    return newColWidths;
};

/**************************
 * GET TABLE SIZES
 **************************/

export const getTotalTableHeightGU = (
    hotInstance: Core,
    gridSize: number,
    getContextZoomScale: () => number,
): number => {
    const tableElement = getTableElementFromHotInstance(hotInstance);
    if (!tableElement) return 0;

    const tableElementHeight = tableElement.getBoundingClientRect().height;
    const tableHeightGU = tableElementHeight / gridSize;
    const zoomScale = getContextZoomScale();
    return tableHeightGU / zoomScale;
};

// Calculate the actual rendered width of 1px borders at the current browser/OS zoom
export const getBorderRenderedWidth = (tdElement: HTMLTableCellElement | null): number => {
    if (!tdElement) return 1;

    return parseCssPx(getComputedStyle(tdElement).borderBottomWidth) || 1;
};

/**************************
 * UPDATE TABLE SIZE
 **************************/

/**
 * This function makes the changes to the data when using the drag handle to
 * resize the table.
 * It takes the difference between the current and temp size, uses that to calculate
 * how many rows/columns to add or delete, then makes the changes to the data and
 * returns that updated data.
 * It will only delete empty cells.
 */
export const resizeTable = (
    hotInstance: Core,
    colWidthsGU: TableColWidthsGU,
    tempSize: { width: number; height: number },
    gridSize: number,
    getContextZoomScale: () => number,
): { columnDif: number; rowDif: number } => {
    const { width: tempWidth, height: tempHeight } = tempSize;

    // Get the difference between table and tempSize in grid units
    const tableWidth = sum(colWidthsGU);
    const widthDif = tempWidth - tableWidth;
    const tableHeight = getTotalTableHeightGU(hotInstance, gridSize, getContextZoomScale);
    const heightDif = tempHeight - tableHeight;

    // Allow the cursor to be a bit closer to the table for something to change
    // e.g. the cursor can be half a col width away to trigger adding a new column
    const widthAdjustment = TABLE_DEFAULT_COL_WIDTH / 2;
    const heightAdjustment = TABLE_DEFAULT_ROW_HEIGHT / 2;

    // positive or negative number that describes how many cols/rows will be added or removed
    let columnDif = Math.floor((widthDif + widthAdjustment) / TABLE_DEFAULT_COL_WIDTH);
    let rowDif = Math.floor((heightDif + heightAdjustment) / TABLE_DEFAULT_ROW_HEIGHT);

    // If either dif is negative, we need to check for any cells that shouldn't be deleted and update the value if necessary
    if (columnDif < 0) {
        const finalColIndex = hotInstance.countCols() - 1;
        const proposedFinalIndex = finalColIndex + columnDif;

        // get the last column with content or selection
        const maxColWithContent = lastColWithContent(hotInstance) || 0;
        const maxSelectedCol = getMaxFromSelections(hotInstance.getSelected())?.col || 0;
        const maxColWithContentOrSelection = Math.max(maxColWithContent, maxSelectedCol);

        // if the proposed final index is less than the max row with content or selection, update the rowDif
        if (proposedFinalIndex < maxColWithContentOrSelection) columnDif = maxColWithContentOrSelection - finalColIndex;
    }
    if (rowDif < 0) {
        const finalRowIndex = hotInstance.countRows() - 1;
        const proposedFinalIndex = finalRowIndex + rowDif;

        // get the last row with content or selection
        const maxRowWithContent = lastRowWithContent(hotInstance) || 0;
        const maxSelectedRow = getMaxFromSelections(hotInstance.getSelected())?.row || 0;
        const maxRowWithContentOrSelection = Math.max(maxRowWithContent, maxSelectedRow);

        // if the proposed final index is less than the max row with content or selection, update the rowDif
        if (proposedFinalIndex < maxRowWithContentOrSelection) rowDif = maxRowWithContentOrSelection - finalRowIndex;

        // if proposed row count is less than the min row count, update the rowDif
        const currentRowCount = hotInstance.countRows();
        if (currentRowCount + rowDif < TABLE_MIN_ROW_COUNT) {
            rowDif = TABLE_MIN_ROW_COUNT - currentRowCount;
        }
    }

    return { columnDif, rowDif };
};
