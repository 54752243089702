import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MinusIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon minus"
        {...props}
    >
        <path fill="#323B4A" fillRule="evenodd" d="M13 8a1 1 0 0 1-1 1H4a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1z" />
    </svg>
);
const Memo = memo(MinusIcon);
export default Memo;
