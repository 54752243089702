// Utils
import { convertMNElementToMNElementSummary } from '../../../common/elements/utils/elementSummaryUtils';
import { buildCacheOperationFromElementsAction } from '../../cache/utils/buildCacheOperationFromElementAction';

// Selectors
import { getElements } from '../selectors/elementsSelector';

// Constants
import { ELEMENTS_LOAD_INTO_WORKER_CACHE } from '../../../common/elements/elementsConstants';

// Types
import { AnyAction } from 'redux';
import Immutable from 'immutable';
import {
    CacheOperationAddElementsData,
    CacheOperationData,
    CacheOperationRemoveElementsData,
    CacheOperationType,
} from '../../cache/utils/cacheOperationTypes';
import { MNElementSummary } from '../../../common/elements/elementModelTypes';

type ElementSummariesState = Map<string, MNElementSummary>;

export const initialElementSummariesState = new Map<string, MNElementSummary>();

const addElementSummaries = (
    state: ElementSummariesState,
    action: CacheOperationAddElementsData,
): ElementSummariesState => {
    for (const element of action.elements) {
        const elementSummary = convertMNElementToMNElementSummary(element);
        state.set(element._id, elementSummary);
    }
    return new Map(state);
};

const deleteElementSummaries = (
    state: ElementSummariesState,
    action: CacheOperationRemoveElementsData,
): ElementSummariesState => {
    for (const elementId of action.elementIds) {
        state.delete(elementId);
    }
    return new Map(state);
};

const clearElementSummaries = (state: ElementSummariesState): ElementSummariesState => {
    state.clear();
    return new Map(state);
};

export const elementSummariesReducer = (
    state: ElementSummariesState = initialElementSummariesState,
    action: CacheOperationData,
): ElementSummariesState => {
    switch (action.type) {
        case CacheOperationType.AddElements:
            return addElementSummaries(state, action);
        case CacheOperationType.RemoveElements:
            return deleteElementSummaries(state, action);
        case CacheOperationType.ClearElements:
            return clearElementSummaries(state);
        default:
            return state;
    }
};

/**
 * The elementSummariesReducer relies on the elements state to build the new state, thus we need to wrap
 * the root reducer in this so we can get the state before and after the update.
 *
 * This is so we can use the same logic to build the cache operation as the persistence thread memory store.
 */
export const wrappedElementSummariesReducer = (
    previousState: Immutable.Map<string, unknown>,
    nextState: Immutable.Map<string, unknown>,
    action: AnyAction,
): Immutable.Map<string, unknown> => {
    // Don't update the main thread's redux store if we're loading elements into the worker cache
    if (action.type === ELEMENTS_LOAD_INTO_WORKER_CACHE) return nextState;

    const prevElements = previousState ? getElements(previousState) : null;
    const nextElements = getElements(nextState);

    const elementActionCacheOperation = buildCacheOperationFromElementsAction(action, nextElements, prevElements);

    if (!elementActionCacheOperation) return nextState;

    return nextState.updateIn(['cache', 'elementSummaries'], (elementSummaries: unknown) =>
        elementSummariesReducer(elementSummaries as ElementSummariesState, elementActionCacheOperation),
    );
};
