// Lib
import { useCallback, useEffect, useRef, useState } from 'react';
import { delay } from 'lodash';

// Utils
import {
    hasProgressiveImageFailed,
    hasProgressiveImageLoaded,
    markProgressiveImageAsFailed,
    markProgressiveImageAsLoaded,
} from './progressiveImagesSingleton';

const IMAGE_FADE_TIMEOUT = 300;

/**
 * Shows an image which progressively becomes better quality by first showing a thumbnail of the image,
 * and then, once the larger version has loaded, replaces the thumbnail with the full size image.
 *
 * NOTE: The component initially shows the source file because it would otherwise cause a flash of content when dragging
 * an element that has already loaded.
 * Now there will be a slight flash when the image has not yet loaded, but that's an acceptable trade off.
 */
export default ({ source, onImageLoadCb }) => {
    const imgRef = useRef();
    const preferredSource = useRef(source);
    const [visibleSource, setVisibleSourceState] = useState(source);
    const [hasLoaded, setHasLoaded] = useState(hasProgressiveImageLoaded(source));
    const [isError, setIsError] = useState(hasProgressiveImageFailed(source));

    const callImageLoadCb = useCallback((newSource, onImageLoadFn) => {
        // Make sure the transparent fade has finished before switching the image
        delay(() => onImageLoadFn && onImageLoadFn(newSource, imgRef.current), IMAGE_FADE_TIMEOUT);
    }, []);

    const setLoadedState = useCallback((newSource, onImageLoadFn) => {
        setIsError(false);

        // If this is the first time we've loaded this image call the image load fn.
        if (!hasProgressiveImageLoaded(newSource)) {
            callImageLoadCb(newSource, onImageLoadFn);
        }

        setVisibleSourceState(newSource);
        setHasLoaded(true);
    }, []);

    const fetchThenShowImage = useCallback((newSource, onImageLoadFn) => {
        const onLoad = () => {
            // Only set the source to the new source if it's currently the source we want to show
            // Otherwise there can be a race condition if a non-preferred source loads slower and after the preferred one
            if (preferredSource.current === newSource) {
                setLoadedState(newSource, onImageLoadFn);
            }

            // Mark image as loaded (regardless of load preference)
            markProgressiveImageAsLoaded({ source: newSource });
        };

        const onError = () => {
            setIsError(true);

            // If error, still call image load callback to treat it as load completed
            callImageLoadCb(newSource, onImageLoadFn);

            markProgressiveImageAsFailed({ source: newSource });
        };

        // Don't repeat if called for same source as previous update
        if (!imgRef?.current?.src || imgRef?.current?.src !== newSource) {
            // Prevent race conditions if multiple sources are fetched concurrently, and assumes to latest one is preferred
            preferredSource.current = newSource;

            // Create a DOM image element object in memory and assign the new source to it so it
            // fetches in the background
            imgRef.current = new Image();
            // Apparently the onLoad event won't fire if it's added to the image AFTER the src is set and
            // the image is loaded from the cache, but if you add it before it WILL.
            // Unfortunately, my empirical evidence suggests that's not the case in Chrome, but I'll add it
            // in this order anyway
            imgRef.current.onload = onLoad;
            imgRef.current.onerror = onError;
            imgRef.current.src = newSource;

            // If the image is loaded from cache, then it will be "complete" immediately, so trigger the onLoad manually
            // I've again seen inconsistent results with this in Chrome, but overall it seems to work
            if (imgRef.current.complete) onLoad();
        }
    }, []);

    useEffect(() => {
        !hasLoaded && fetchThenShowImage(source, onImageLoadCb);

        // Remove any onload callbacks on un-mount
        return () => {
            if (imgRef.current) {
                imgRef.current.onload = () => null;
            }
        };
    }, []);

    // When the source changes, fetch the new image in the background
    useEffect(() => {
        // If the image is already loaded, don't fetch it again
        // e.g. dropping an existing image element onto a link replace the preview
        if (hasProgressiveImageLoaded(source)) {
            preferredSource.current = source;
            setLoadedState(source, onImageLoadCb);
            return;
        }

        fetchThenShowImage(source, onImageLoadCb);
    }, [source]);

    return {
        hideImage: !hasLoaded,
        visibleSource,
        isError,
    };
};
