import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Selectors
import popupOpenSelector from '../../../../../components/popupPanel/popupOpenSelector';

// Components
import ColorDisplayToolOption from './ColorDisplayToolOption';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';

// Actions
import { closePopup, togglePopup } from '../../../../../components/popupPanel/popupActions';
import { updateElementsColorDisplay } from './colorDisplayActions';
import { updateCurrentUser } from '../../../../../user/currentUserActions';

// Utils
import { getColorSpace, getShowColorValue } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { COLOR_DISPLAY, COLOR_SPACE } from '../../../../../../common/colors/colorConstants';

// Styles
import './ColorDisplayTool.scss';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

const colorSpaceToolSelector = createSelector(popupOpenSelector(PopupIds.COLOR_DISPLAY), (popupIsOpen) => ({
    popupIsOpen,
}));

const mapDispatchToProps = (dispatch, ownProps) => ({
    openColorDisplayPopup: () => dispatch(togglePopup(PopupIds.COLOR_DISPLAY)),
    closeColorDisplayPopup: () => dispatch(closePopup(PopupIds.COLOR_DISPLAY)),
    dispatchConvertToColorDisplay: (colorDisplay) => {
        dispatch(
            updateElementsColorDisplay({
                elements: ownProps.selectedElements,
                colorDisplay,
            }),
        );
        dispatch(
            updateCurrentUser({
                changes: { settings: { preferredColorDisplay: colorDisplay } },
            }),
        );
    },
});

const ColorDisplayTool = (props) => {
    const {
        openColorDisplayPopup,
        popupIsOpen,
        selectedElements,
        closeColorDisplayPopup,
        dispatchConvertToColorDisplay,
    } = props;

    const firstSelectedElement = selectedElements.first();

    const showColorValue = getShowColorValue(firstSelectedElement);
    const colorSpace = getColorSpace(firstSelectedElement) || COLOR_SPACE.HEX;
    const colorDisplay = showColorValue ? colorSpace : COLOR_DISPLAY.OFF;

    return (
        <div className="ColorDisplayTool">
            <ColorDisplayToolOption
                className="color-display-toolbar-option"
                name="Display"
                colorDisplay={colorDisplay}
                onPointerDown={openColorDisplayPopup}
                isActive={popupIsOpen}
            />
            <ToolbarPopup
                popupId={PopupIds.COLOR_DISPLAY}
                className="color-display-popup"
                buttonSelector=".ColorDisplayTool .icon"
                closePopup={closeColorDisplayPopup}
            >
                <div className="color-display-popup-content">
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.HEX}
                        onPointerDown={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.HEX)}
                        isActive={colorDisplay === COLOR_DISPLAY.HEX}
                    />
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.RGB}
                        onPointerDown={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.RGB)}
                        isActive={colorDisplay === COLOR_DISPLAY.RGB}
                    />
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.HSL}
                        onPointerDown={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.HSL)}
                        isActive={colorDisplay === COLOR_DISPLAY.HSL}
                    />
                    <ColorDisplayToolOption
                        colorDisplay={COLOR_DISPLAY.OFF}
                        onPointerDown={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.OFF)}
                        isActive={colorDisplay === COLOR_DISPLAY.OFF}
                    />
                </div>
            </ToolbarPopup>
        </div>
    );
};

ColorDisplayTool.propTypes = {
    gridSize: PropTypes.number.isRequired,
    popupIsOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
    firstElementId: PropTypes.string,
    openColorDisplayPopup: PropTypes.func,
    closeColorDisplayPopup: PropTypes.func,
    dispatchConvertToColorDisplay: PropTypes.func,
};

export default connect(colorSpaceToolSelector, mapDispatchToProps)(ColorDisplayTool);
