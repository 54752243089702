// Lib
import { keys } from 'lodash';

// Util
import keysToObject from './keysToObject';

/**
 * Creates an empty object with the same keys as the passed in object.
 * <pre>
 *     emptyObject({ a: '1', b: '2', c: '3' });
 *     // => { a: null, b: null, c: null }
 */
export default (object) => keysToObject(null, keys(object));
