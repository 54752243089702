import { TIMES } from './timeUtil';

export const waitFor = (timeout) => new Promise((resolve) => setTimeout(() => resolve(), timeout));

/**
 * Will try to run a given function until condition is true, capped at options.nTimes
 */
export const tryUntil = async (fn, condition, options = {}) => {
    const { nTimes = 5, timeout = 50 } = options;

    const response = fn();

    if (condition()) return response;

    if (nTimes <= 1) throw new Error('tryUntil: Condition not met');

    await waitFor(timeout);

    return tryUntil(fn, condition, { nTimes: nTimes - 1, timeout });
};

/**
 * By default, will wait a total of 10s for predicate to be true
 */
export const waitUntil = (predicate, options = {}) =>
    new Promise((resolve, reject) => {
        const { duration = TIMES.SECOND * 10, interval = TIMES.SECOND / 5 } = options;

        const nTimes = Math.floor(duration / interval);
        let n = 0;

        const intervalId = window.setInterval(() => {
            n++;

            if (predicate() === true) {
                clearInterval(intervalId);

                return resolve();
            }

            if (n === nTimes) {
                clearInterval(intervalId);

                return reject(new Error('waitUntil: Predicate did not return true before duration'));
            }
        }, interval);
    });
