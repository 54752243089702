// Lib
import { BasePlugin } from 'handsontable/plugins';
import Core from 'handsontable/core';
import { get } from 'lodash';
import Handsontable from 'handsontable/base';

export const MILANOTE_EDITING_CLEANUP_PLUGIN_NAME = 'milanoteEditingCleanup';
export const MILANOTE_EDITING_CLEANUP_PLUGIN_PRIORITY = 1000;

/**
 * MilanoteEditingCleanupPlugin.ts
 *
 *   - This is a custom plugin that has a low priority (called after all other plugins) used for cleanup
 *
 *   This plugin is used to revert back the changes to its original format after being changed in
 *   the MilanoteEditingPlugin for use in the formula plugin
 *
 *   Process:
 *   afterSetDataAtCell hook -> MilanoteEditingPlugin -> FormulaPlugin -> MilanoteEditingCleanupPlugin
 *   For diagram, see https://app.milanote.com/1Qjnx50VbE5g69/table-editing-flow
 */
class MilanoteEditingCleanupPlugin extends BasePlugin {
    private originalChanges: Handsontable.CellChange[] | null = null;

    /**
     * This key will be used as a prop key when enabling this plugin in the HotTable component
     */
    static get PLUGIN_KEY(): string {
        return MILANOTE_EDITING_CLEANUP_PLUGIN_NAME;
    }

    static get PLUGIN_PRIORITY(): number {
        return MILANOTE_EDITING_CLEANUP_PLUGIN_PRIORITY;
    }

    constructor(hotInstance: Core) {
        super(hotInstance);
    }

    isEnabled(): boolean {
        return true;
    }

    enablePlugin(): void {
        this.addHook('afterSetDataAtCell', (...args) => this.onAfterSetDataAtCell(...args));

        // The `super` method sets the `this.enabled` property to `true`.
        // It is a necessary step to update the plugin's settings properly.
        super.enablePlugin();
    }

    setOriginalChanges(originalChanges: Handsontable.CellChange[]): void {
        this.originalChanges = originalChanges;
    }

    /**
     * This function reverts back the changes to its original format. See description of `onAfterSetDataAtCell` function
     * in `MilanoteEditingPlugin.ts` for more details
     */
    onAfterSetDataAtCell(changes: Handsontable.CellChange[], source?: Handsontable.ChangeSource): void {
        if (this.originalChanges && this.originalChanges.length === changes.length) {
            changes.forEach((change, index) => {
                const originalChange = get(this.originalChanges, index);

                if (originalChange) changes[index] = originalChange;
            });
        }
    }
}

export default MilanoteEditingCleanupPlugin;
