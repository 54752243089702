import React from 'react';
import PropTypes from 'prop-types';
import TooltipSource from '../../../components/tooltips/TooltipSource';
import Icon from '../../../components/icons/Icon';
import { getHasClones } from '../../../../common/elements/utils/elementPropertyUtils';

import './DocumentStatus.scss';

const DocumentStatus = (props) => {
    const { element, isClone } = props;

    if (!isClone && !getHasClones(element)) return null;

    return (
        <div className="DocumentStatus">
            <TooltipSource
                enabled
                delay={300}
                className="document-clone-tooltip"
                tooltipId="tooltip-document-status"
                tooltipText={
                    <div className="document-clone-message">
                        <Icon name="clone" />
                        <div className="text">There are other copies of this document</div>
                    </div>
                }
                distance={10}
                triggerOnMouseEnter
            >
                <Icon name="clone-outlined" />
            </TooltipSource>
        </div>
    );
};

DocumentStatus.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string.isRequired,
    isClone: PropTypes.bool,
};

export default DocumentStatus;
