// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * This component will retain a reference to the DOM that was mounted inside the div
 * and render it when it's been unmounted, so that animations will still display the
 * previous DOM.
 *
 * NOTE: This relies on the component being updated at the same time that the content
 *  is being removed from the mount point.
 *  Currently this works because it's being used in the ImageModalToolbar which updates
 *  when the "mode" property changes.
 *  If this can't be achieved for future components, using MutationObserver might be the
 *  best generic solution.
 */
class StickyPortalMount extends React.Component {
    constructor(props) {
        super(props);

        this.mountNodeRef = React.createRef();
        this.prevChild = null;
        this.prevChildMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If we have a child inside the mount node, then a child has been mounted
        if (this.mountNodeRef.current?.children?.[0]) return this.handleChildMounted();

        // Otherwise handle it's unmount
        return this.handleChildUnmounted();
    }

    handleChildMounted = () => {
        // If a prev child was mounted, remove it from the DOM
        if (this.prevChildMounted && this.prevChild) {
            this.mountNodeRef.current.removeChild(this.prevChild);
        }

        // Save a reference to the newly mounted child
        this.prevChildMounted = false;
        this.prevChild = this.mountNodeRef.current?.children?.[0];
    };

    handleChildUnmounted = () => {
        if (!this.prevChild || !this.mountNodeRef.current) return;

        // Add the previous child to the DOM so that it remains visible
        this.mountNodeRef.current.appendChild(this.prevChild);
        this.prevChildMounted = true;
    };

    render() {
        const { className } = this.props;

        return <div {...this.props} ref={this.mountNodeRef} className={classNames('StickyPortalMount', className)} />;
    }
}

StickyPortalMount.propTypes = {
    className: PropTypes.string,
};

export default StickyPortalMount;
