// Lib
import { get, negate } from 'lodash/fp';
import { createSelector } from 'reselect';
import { groupBy } from 'lodash';

// Utils
import { createShallowSelector } from '../../../../utils/milanoteReselect/milanoteReselect';
import { isLocationTrash } from '../../../../../common/elements/utils/elementLocationUtils';
import { isEmpty } from '../../../../../common/utils/immutableHelper';
import { isElementLastModifiedByUser } from '../../../../../common/elements/utils/elementUserUtils';
import { isAnnotation, isLine, isTask } from '../../../../../common/elements/utils/elementTypeUtils';
import { elementIsInterestingForTrash } from './clientTrashUtils';
import { getAsyncResourceEntityState } from '../../../../utils/services/http/asyncResource/asyncResourceSelector';
import {
    isAsyncEntityFetched,
    isAsyncEntityFetching,
} from '../../../../utils/services/http/asyncResource/asyncResourceUtils';

// Selectors
import { getElements } from '../../../../element/selectors/elementsSelector';
import { getElementId, getTrashedDate } from '../../../../../common/elements/utils/elementPropertyUtils';
import { getStartOfDayDate } from '../../../../../common/utils/timeUtil';
import { getCurrentUserId } from '../../../../user/currentUserSelector';
import { elementGraphSelector } from '../../../../element/selectors/elementGraphSelector';
import { threadCommentIdsMapSelector } from '../../../../element/comment/store/commentsSelector';

// Constants
import { TRASH_TABS } from './trashConstants';
import { ResourceTypes } from '../../../../utils/services/http/asyncResource/asyncResourceConstants';
import { TrashElementTypes } from '../popup/elementList/trashElementTypes';

const sortByAddedDate = (elementA, elementB) => {
    const timeDiff = getTrashedDate(elementB) - getTrashedDate(elementA);
    if (timeDiff !== 0) return timeDiff;

    const elementIdA = getElementId(elementA);
    const elementIdB = getElementId(elementB);

    return elementIdA < elementIdB ? 1 : -1;
};
const sortByTimestamp = (groupA, groupB) => get('timestamp', groupB) - get('timestamp', groupA);

export const trashElementsSelector = createShallowSelector(getElements, (elements) => elements.filter(isLocationTrash));

const filteredTrashElementsSelector = createShallowSelector(trashElementsSelector, (trashElements) =>
    trashElements.filter((element) => !isLine(element) && !isTask(element) && !isAnnotation(element)),
);

export const filteredTrashElementIdsSelector = createShallowSelector(filteredTrashElementsSelector, (trashElements) =>
    trashElements.map(getElementId).toArray(),
);

export const trashedByCurrentUserElementsSelector = createShallowSelector(
    filteredTrashElementsSelector,
    getCurrentUserId,
    (trashElements, currentUserId) => {
        const isTrashedByCurrentUser = isElementLastModifiedByUser(currentUserId);
        return trashElements.filter(isTrashedByCurrentUser);
    },
);

export const trashedByCurrentUserElementIdsSelector = createShallowSelector(
    trashedByCurrentUserElementsSelector,
    (trashElements) => trashElements.map(getElementId).toArray(),
);

export const emptyCurrentUserTrashSelector = createShallowSelector(trashedByCurrentUserElementsSelector, isEmpty);

/**
 * This list will filter empty elements on the client side as well.
 */
export const trashedByOtherUsersElementsSelector = createShallowSelector(
    filteredTrashElementsSelector,
    getCurrentUserId,
    elementGraphSelector,
    threadCommentIdsMapSelector,
    (trashElements, currentUserId, elementGraph, commentsByThreadId) => {
        const isTrashedByOtherUsers = negate(isElementLastModifiedByUser(currentUserId));
        return trashElements.filter(
            (element) =>
                isTrashedByOtherUsers(element) &&
                elementIsInterestingForTrash({ element, elementGraph, commentsByThreadId }),
        );
    },
);

export const trashedByOtherUsersElementIdsSelector = createShallowSelector(
    trashedByOtherUsersElementsSelector,
    (trashElements) => trashElements.map(getElementId).toArray(),
);

export const emptyOtherUserTrashSelector = createShallowSelector(trashedByOtherUsersElementsSelector, isEmpty);

// Returns an ordered list of date separators and elements for the trash list
const createOrderedTrashListContentSelector = (trashElementSelector) =>
    createSelector(trashElementSelector, (trashElements) => {
        const groupedElements = groupBy(trashElements.toArray(), (element) => {
            const trashedDate = getTrashedDate(element);
            const startOfTrashedDate = getStartOfDayDate(trashedDate);
            return startOfTrashedDate.getTime();
        });

        const groups = [];
        for (const timestampStr in groupedElements) {
            if (!groupedElements.hasOwnProperty(timestampStr)) continue;

            const timestamp = parseInt(timestampStr, 10);

            groups.push({
                timestamp,
                elementIds: groupedElements[timestampStr].sort(sortByAddedDate).map(getElementId),
            });
        }

        const orderedTrashListMap = new Map();

        groups.sort(sortByTimestamp).forEach((groupData) => {
            const { elementIds, timestamp } = groupData;

            // Add a date element to the list
            orderedTrashListMap.set(timestamp.toString(), { type: TrashElementTypes.DATE, timestamp });

            // Add the elements to the list
            elementIds.forEach((elementId) => {
                orderedTrashListMap.set(elementId, { type: TrashElementTypes.ELEMENT, elementId });
            });
        });

        return orderedTrashListMap;
    });

export const trashedByCurrentUserOrderedListContentSelector = createOrderedTrashListContentSelector(
    trashedByCurrentUserElementsSelector,
);

export const trashedByOtherUsersOrderedListContentSelector = createOrderedTrashListContentSelector(
    trashedByOtherUsersElementsSelector,
);

const getOrderedTrashListMapIds = (orderedTrashListMap) => Array.from(orderedTrashListMap?.keys());

export const trashedByCurrentUserOrderedListIdsSelector = createSelector(
    trashedByCurrentUserOrderedListContentSelector,
    getOrderedTrashListMapIds,
);
export const trashedByOtherUsersOrderedListIdsSelector = createSelector(
    trashedByOtherUsersOrderedListContentSelector,
    getOrderedTrashListMapIds,
);

// Resource state
export const trashFetchedSelector = (tab) => (state) =>
    isAsyncEntityFetched(getAsyncResourceEntityState(state, ResourceTypes.trash, tab));
export const trashFetchingSelector = (tab) => (state) =>
    isAsyncEntityFetching(getAsyncResourceEntityState(state, ResourceTypes.trash, tab));
export const trashHasMoreSelector = (tab) => (state) =>
    getAsyncResourceEntityState(state, ResourceTypes.trash, tab)?.meta?.hasMore || false;
export const trashHasOlderSelector = (tab) => (state) =>
    getAsyncResourceEntityState(state, ResourceTypes.trash, tab)?.meta?.hasOlderTrash || false;
export const trashEarliestTimestampSelector = (tab) => (state) =>
    getAsyncResourceEntityState(state, ResourceTypes.trash, tab)?.meta?.earliestTimestamp;
export const isClearingTrashSelector = (state) =>
    getAsyncResourceEntityState(state, ResourceTypes.trash, TRASH_TABS.PERSONAL)?.meta?.clearing || false;
