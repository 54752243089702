// Lib
import { clamp, range, toUpper } from 'lodash';

// Utils
import logger from '../../logger/logger';

const DEFAULT_LOG_TITLE_STYLE = 'font-weight: bold;';
const DEFAULT_LOG_ENTRY_STYLE = ['font-weight: normal;', 'color: #00a6ff; font-weight: normal;'];

type LogGroup = {
    title: string;
    entries: [key: string, value: string][];
};

/**
 * Logs the given data to the console in the following format:
 * TITLE
 * ______________________________
 * {key}: {value}
 * ...
 */
export const logDebugData = (logGroups: LogGroup[]) => {
    let logString = '\n';
    const logStyles: string[] = [];

    const maxKeyLength = logGroups.reduce((max, logGroup) => {
        const maxGroupKeyLength = logGroup.entries.reduce((max, [key]) => Math.max(max, key.length), 0);
        return Math.max(max, maxGroupKeyLength);
    }, 0);

    const maxValLength = logGroups.reduce((max, logGroup) => {
        const maxGroupValLength = logGroup.entries.reduce((max, [, value]) => Math.max(max, String(value)?.length), 0);
        return Math.max(max, maxGroupValLength);
    }, 0);

    const ruleLength = clamp(maxKeyLength + maxValLength + 6, 20, 60);
    const rule = range(ruleLength)
        .map(() => '_')
        .join('');

    logGroups.forEach((logGroup) => {
        logString += `%c${toUpper(logGroup.title)}\n${rule}\n\n`;
        logStyles.push(DEFAULT_LOG_TITLE_STYLE);

        logGroup.entries.forEach(([key, value]) => {
            const padLength = maxKeyLength - key.length;

            logString += `  %c${key}:${range(padLength)
                .map(() => ' ')
                .join('')} %c${value}\n`;
            logStyles.push(...DEFAULT_LOG_ENTRY_STYLE);
        });
        logString += '\n';
    });

    logger.log(logString, ...logStyles);
};
