// Local storage
export const localStorageFeatureTest = () => {
    const mod = 'local_storage';
    try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * A mock to prevent exceptions from being thrown when the local storage is used.
 */
const localStorageMockFallback = {
    getItem: () => null,
    setItem: () => null,
    removeItem: () => null,
    key: () => null,
    length: 0,
};

const localStorageService = localStorageFeatureTest() ? localStorage : localStorageMockFallback;

export default localStorageService;
