import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ErrorAlertIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={58}
        height={53}
        viewBox="0 0 58 53"
        className="Icon error-alert"
        {...props}
    >
        <g fill="#F4511C" fillRule="evenodd">
            <path d="M51.666 51a4.317 4.317 0 0 0 3.753-2.216 4.517 4.517 0 0 0 0-4.433L32.753 4.216A4.314 4.314 0 0 0 29 2c-1.55 0-2.98.844-3.755 2.216L2.579 44.351a4.53 4.53 0 0 0 0 4.433A4.318 4.318 0 0 0 6.335 51h45.33ZM6.335 52.964a6.282 6.282 0 0 1-5.467-3.217 6.494 6.494 0 0 1 .001-6.361L23.535 3.25A6.281 6.281 0 0 1 29 .036a6.277 6.277 0 0 1 5.463 3.215L57.13 43.386a6.48 6.48 0 0 1 0 6.363 6.28 6.28 0 0 1-5.463 3.215H6.335Z" />
            <path d="M27.135 27.636 27 20h4l-.135 7.636L30.298 36h-2.596zM29 43a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
        </g>
    </svg>
);
const Memo = memo(ErrorAlertIcon);
export default Memo;
