import React from 'react';
import PropTypes from 'prop-types';

// Works around issues with context updates failing to propagate.
// Caveat: the context value is expected to never change its identity.
// https://github.com/facebook/react/issues/2517
// https://github.com/reactjs/react-router/issues/470

const contextProviderShape = PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    eventIndex: PropTypes.number.isRequired,
});

function makeContextName(name) {
    return `@@contextSubscriber/${name}`;
}

const prefixUnsafeLifecycleMethods = typeof React.forwardRef !== 'undefined';

export function ContextProvider(name) {
    const contextName = makeContextName(name);
    const listenersKey = `${contextName}/listeners`;
    const eventIndexKey = `${contextName}/eventIndex`;
    const subscribeKey = `${contextName}/subscribe`;

    const config = {
        childContextTypes: {
            [contextName]: contextProviderShape.isRequired,
        },

        getChildContext() {
            return {
                [contextName]: {
                    eventIndex: this[eventIndexKey],
                    subscribe: this[subscribeKey],
                },
            };
        },

        // this method will be updated to UNSAFE_componentWillMount below for React versions >= 16.3
        componentWillMount() {
            this[listenersKey] = [];
            this[eventIndexKey] = 0;
        },

        // this method will be updated to UNSAFE_componentWillReceiveProps below for React versions >= 16.3
        componentWillReceiveProps() {
            this[eventIndexKey]++;
        },

        componentDidUpdate() {
            this[listenersKey].forEach((listener) => listener(this[eventIndexKey]));
        },

        [subscribeKey](listener) {
            // No need to immediately call listener here.
            this[listenersKey].push(listener);

            return () => {
                this[listenersKey] = this[listenersKey].filter((item) => item !== listener);
            };
        },
    };

    if (prefixUnsafeLifecycleMethods) {
        config.UNSAFE_componentWillMount = config.componentWillMount;
        config.UNSAFE_componentWillReceiveProps = config.componentWillReceiveProps;
        delete config.componentWillMount;
        delete config.componentWillReceiveProps;
    }
    return config;
}

export function ContextSubscriber(name) {
    const contextName = makeContextName(name);
    const lastRenderedEventIndexKey = `${contextName}/lastRenderedEventIndex`;
    const handleContextUpdateKey = `${contextName}/handleContextUpdate`;
    const unsubscribeKey = `${contextName}/unsubscribe`;

    const config = {
        contextTypes: {
            [contextName]: contextProviderShape,
        },

        getInitialState() {
            if (!this.context[contextName]) {
                return {};
            }

            return {
                [lastRenderedEventIndexKey]: this.context[contextName].eventIndex,
            };
        },

        componentDidMount() {
            if (!this.context[contextName]) {
                return;
            }

            this[unsubscribeKey] = this.context[contextName].subscribe(this[handleContextUpdateKey]);
        },

        // this method will be updated to UNSAFE_componentWillReceiveProps below for React versions >= 16.3
        componentWillReceiveProps() {
            if (!this.context[contextName]) {
                return;
            }

            this.setState({
                [lastRenderedEventIndexKey]: this.context[contextName].eventIndex,
            });
        },

        componentWillUnmount() {
            if (!this[unsubscribeKey]) {
                return;
            }

            this[unsubscribeKey]();
            this[unsubscribeKey] = null;
        },

        [handleContextUpdateKey](eventIndex) {
            if (eventIndex !== this.state[lastRenderedEventIndexKey]) {
                this.setState({ [lastRenderedEventIndexKey]: eventIndex });
            }
        },
    };

    if (prefixUnsafeLifecycleMethods) {
        config.UNSAFE_componentWillReceiveProps = config.componentWillReceiveProps;
        delete config.componentWillReceiveProps;
    }
    return config;
}
