// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';

// Utils
import { getIsPublished } from '../../../../common/elements/utils/elementPropertyUtils';
import { hasSecretLinkAcl } from '../../../../common/permissions/elementPermissionsUtil';
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';

// Selectors
import { boardAccessDeniedSelector } from '../../../utils/permissions/elementPermissionsSelector';
import { getPhysicalBoardHasPasswordProtectedErrorSelector } from '../boardPasswordProtectedSelector';

// Components
import TooltipSource from '../../../components/tooltips/TooltipSource';
import Icon from '../../../components/icons/Icon';

// Styles
import './BoardStatus.scss';

const getStyle = (size) => {
    if (!size) return null;

    return {
        fontSize: `${size / 7.2 || 1}em`,
    };
};

const createStandardIconsSelector = () =>
    createShallowSelector(
        (_, ownProps) => ownProps.element,
        (_, ownProps) => ownProps.isShared,
        (element, isShared) => {
            const icons = [];

            const isPublished = getIsPublished(element);

            if (isPublished || hasSecretLinkAcl(element)) {
                icons.push({ icon: 'board-published', tooltip: 'Read-only link enabled' });
            }
            if (isShared) icons.push({ icon: 'board-shared-users', tooltip: 'Shared' });

            return icons;
        },
    );

const aliasIconsSelector = (initialState, initialProps) =>
    createShallowSelector(
        boardAccessDeniedSelector(initialState, initialProps),
        getPhysicalBoardHasPasswordProtectedErrorSelector,
        createStandardIconsSelector(),
        (accessDenied, requiresPassword, standardIcons) => {
            if (requiresPassword) return [{ icon: 'board-password-protected', tooltip: 'Password protected' }];
            if (accessDenied) return [{ icon: 'board-access-denied', tooltip: 'Access denied' }];
            return standardIcons;
        },
    );

const aliasMapStateToProps = (initialState, initialProps) =>
    createStructuredSelector({
        icons: aliasIconsSelector(initialState, initialProps),
    });

const boardMapStateToProps = () =>
    createStructuredSelector({
        icons: createStandardIconsSelector(),
    });

const BoardStatusIconRenderer = React.memo(function BoardStatusIconRenderer({ icons, size }) {
    if (icons.length === 0) return null;

    return (
        <div className={classNames('BoardStatus', { 'multiple-icons': icons.length >= 2 })} style={getStyle(size)}>
            {icons.map((iconDetails, index) => (
                <TooltipSource
                    key={index}
                    enabled
                    delay={300}
                    tooltipId={`tooltip-board-status-${index}`}
                    tooltipText={<span className="board-status-tooltip">{iconDetails.tooltip}</span>}
                    distance={10}
                    triggerOnMouseEnter
                >
                    <Icon name={iconDetails.icon} />
                </TooltipSource>
            ))}
        </div>
    );
});

BoardStatusIconRenderer.propTypes = {
    icons: PropTypes.array,
    size: PropTypes.number,
};

const AliasBoardStatus = connect(aliasMapStateToProps)(BoardStatusIconRenderer);
const BoardBoardStatus = connect(boardMapStateToProps)(BoardStatusIconRenderer);

const BoardStatus = (props) => {
    const { isAlias, isShared, size, element } = props;

    // Alias icons take a little longer to calculate so splitting them out to another
    // component, avoiding the performance hit for normal boards
    return isAlias ? (
        <AliasBoardStatus size={size} isShared={isShared} element={element} />
    ) : (
        <BoardBoardStatus size={size} isShared={isShared} element={element} />
    );
};

BoardStatus.propTypes = {
    element: PropTypes.object,
    isShared: PropTypes.bool,
    isAlias: PropTypes.bool,
    size: PropTypes.number,
};

export default BoardStatus;
