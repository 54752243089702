// Utils
import logger from '../../logger/logger';
import { asObject } from '../../../common/utils/immutableHelper';
import { manuallyReportError } from '../../analytics/rollbarService';
import { getMetaVersionId, getTextContent } from '../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateElement } from '../../element/actions/elementActions';
import { fetchElements } from '../../element/elementService';
import { finishEditingElement, startEditingElement } from '../../element/selection/selectionActions';

// Selectors
import { getElement } from '../../element/selectors/elementSelector';
import {
    getCurrentlyEditingEditorId,
    getCurrentlyEditingEditorKey,
    getCurrentlyEditingId,
} from '../../element/selection/currentlyEditingSelector';

// Types
import { ActionObject } from '../../../common/actions/actionTypes';
import { MNElement } from '../../../common/elements/elementModelTypes';

// Constants
import { ELEMENT_ERROR } from '../../../common/error/errorConstants';
import { ROLLBAR_LEVELS } from '../../analytics/rollbarConstants';
import { ELEMENT_UPDATE_TYPE } from '../../../common/elements/elementConstants';
import { VersionComparisonOutcomes } from '../../../common/elements/utils/elementVersionComparisonUtils';

type SocketResponse = {
    error?: boolean;
    name?: string;
    type?: string;
    code?: string;
};

type SocketAction = ActionObject & MNElement;

/**
 * If the errored action is a diff update, we can silently recover from the error by performing
 * a full ELEMENT_UPDATE action to persist the current client state.
 *
 * This will allow the server to perform a normal version conflict resolution.
 */
const handleDiffUpdateErrorThunk =
    (response: SocketResponse, action: SocketAction) => async (dispatch: Function, getState: Function) => {
        const state = getState();

        const elementId = action?.id;

        const element = getElement(state, { elementId });

        logger.info(
            'handleSocketErrors - handleDiffUpdateError: Handling diff update error',
            action,
            asObject(element),
        );

        // If we can't find the element, we can't recover from the error
        if (!element) {
            logger.error(`Unable to find element with id ${elementId} to recover from diff update error`);
            manuallyReportError({
                errorMessage:
                    'handleSocketErrors - handleDiffUpdateError: Unable to find element to recover from diff update error',
                level: ROLLBAR_LEVELS.ERROR,
                custom: { elementId },
            });
            return false;
        }

        // The server received an action from this client to update an element, but the version ID
        // is older than the version saved in the database.
        // The only scenario that this should happen in is if the client's actions have arrived out
        // of sync. This should be either impossible or extremely rare
        // If this happens, the server has already persisted a newer version, so we don't need to action this one.
        if (response?.code === VersionComparisonOutcomes[VersionComparisonOutcomes.IGNORE_CHANGE]) {
            logger.error(`An ELEMENT_DIFF_UPDATE errored because the server is ahead of the client`, {
                elementId,
                action,
                element,
            });
            manuallyReportError({
                errorMessage:
                    'handleSocketErrors - handleDiffUpdateError: An ELEMENT_DIFF_UPDATE errored because the server is ahead of the client',
                level: ROLLBAR_LEVELS.ERROR,
                custom: { elementId },
            });

            // Force fetch the element to ensure it's up-to-date
            const currentlyEditingElementId = getCurrentlyEditingId(state);
            const editorId = getCurrentlyEditingEditorId(state);
            const editorKey = getCurrentlyEditingEditorKey(state);

            // Stop editing the current element if it's the one that errored, so that the editor will get
            // updated with what's saved on the server
            if (currentlyEditingElementId === elementId) {
                dispatch(finishEditingElement(elementId));
            }

            elementId && (await dispatch(fetchElements({ force: true, elementIds: [elementId] })));

            if (currentlyEditingElementId === elementId) {
                dispatch(
                    startEditingElement({
                        id: elementId,
                        editorId,
                        editorKey,
                        transactionId: undefined,
                        canUndo: false,
                    }),
                );
            }

            return true;
        }

        // Make sure that the element that's being updated is on the same version as the update diff
        // if not, ignore the error
        if (getMetaVersionId(element) !== action?.meta?.versionId) {
            logger.warn(
                'handleSocketErrors - handleDiffUpdateError: Received an error for an outdated version. Ignoring update.',
            );
            return true;
        }

        logger.warn('handleSocketErrors - handleDiffUpdateError: Reverting to a full element update');

        const textContent = asObject(getTextContent(element));

        dispatch(
            updateElement({
                id: action?.id,
                changes: { textContent },
                updateType: ELEMENT_UPDATE_TYPE.DIFF_UPDATE_ERROR_RECOVERY,
            }),
        );

        return true;
    };

/**
 * The error will not be handled on the client, so it will be replayed if appropriate.
 */
const unhandledErrorThunk =
    (response: SocketResponse, action: SocketAction) =>
    (dispatch: Function, getState: Function): boolean =>
        false;

/**
 * Handles any errors that are received from the socket server
 */
export const handleSocketErrors = (response: SocketResponse, action: SocketAction) => {
    if (!response.error) return unhandledErrorThunk(response, action);

    switch (response?.name) {
        case ELEMENT_ERROR.DIFF_UPDATE_ERROR:
            return handleDiffUpdateErrorThunk(response, action);
        default:
            return unhandledErrorThunk(response, action);
    }
};
