import { flatMap, flow, initial, last } from 'lodash';

import { TextAlignment } from '../../../../table/CellTypeConstants';
import { TiptapContentNode, TiptapNodeType } from '../../../tiptapTypes';
import { promoteMarksToNodes } from './promoteMarksToNodes';
import { recursivelyStripEmptyMarks } from './recursivelyStripEmptyMarks';

/**
 * Inserts an object in between each item in an array.
 */
const insertBetween = (array: any[], objectToInsert: unknown) =>
    flatMap(initial(array), (item) => [item, objectToInsert]).concat(last(array));

/**
 * Tiptap represents soft-new-lines as "hardBreak" nodes, which are not present in DraftJS.
 * Thus, we need to traverse through the converted Tiptap content, looking for soft-new-lines
 * and replacing them with hardBreak nodes.
 */
const addHardBreaks = (node: TiptapContentNode): TiptapContentNode => {
    if (node.type !== TiptapNodeType.paragraph) return node;

    return {
        ...node,
        content: node.content?.flatMap((child) => {
            if (!child.text || child.type !== TiptapNodeType.text) return child;

            // Split the text node into multiple text nodes, if there's new line characters
            // and add the hardBreak node in between them.
            return insertBetween(
                child.text.split('\n').map((text) => ({ ...child, text })),
                { type: TiptapNodeType.hardBreak },
                // Remove any empty blocks of text
            ).filter((x) => x.text !== '');
        }),
    };
};

const addLeftAlignToParagraphs = (node: TiptapContentNode): TiptapContentNode => {
    if (node.type !== TiptapNodeType.paragraph) return node;
    return {
        ...node,

        attrs: {
            textAlign: TextAlignment.LEFT,
            ...node.attrs,
        },
    };
};

const addStartToOrderedLists = (node: TiptapContentNode): TiptapContentNode => {
    if (node.type !== TiptapNodeType.orderedList) return node;
    return {
        ...node,

        attrs: {
            start: 1,
            ...node.attrs,
        },
    };
};

export const postprocessContent = flow(
    promoteMarksToNodes,
    recursivelyStripEmptyMarks,
    addLeftAlignToParagraphs,
    addStartToOrderedLists,
    addHardBreaks,
);
