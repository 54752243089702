// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { APP_REDO_CUSTOM_DRAFT_COMMAND, APP_UNDO_CUSTOM_DRAFT_COMMAND } from './undoRedoPluginConstants';

export default (command, editorState, eventTimeStamp, { getProps }) => {
    switch (command) {
        case APP_UNDO_CUSTOM_DRAFT_COMMAND: {
            const { onAppUndo } = getProps();
            onAppUndo && onAppUndo();
            return HANDLER_RETURN_VALUES.handled;
        }
        case APP_REDO_CUSTOM_DRAFT_COMMAND: {
            const { onAppRedo } = getProps();
            onAppRedo && onAppRedo();
            return HANDLER_RETURN_VALUES.handled;
        }
        default:
            return HANDLER_RETURN_VALUES.notHandled;
    }
};
