'use strict';

exports.__esModule = true;

var _extends =
    Object.assign ||
    function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];
            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };

var _addClass = require('dom-helpers/class/addClass');

var _addClass2 = _interopRequireDefault(_addClass);

var _removeClass = require('dom-helpers/class/removeClass');

var _removeClass2 = _interopRequireDefault(_removeClass);

var _requestAnimationFrame = require('dom-helpers/util/requestAnimationFrame');

var _requestAnimationFrame2 = _interopRequireDefault(_requestAnimationFrame);

var _properties = require('dom-helpers/transition/properties');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDom = require('react-dom');

var _PropTypes = require('./utils/PropTypes');

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}

function _possibleConstructorReturn(self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return call && (typeof call === 'object' || typeof call === 'function') ? call : self;
}

function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: { value: subClass, enumerable: false, writable: true, configurable: true },
    });
    if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
}

var events = [];
if (_properties.transitionEnd) events.push(_properties.transitionEnd);
if (_properties.animationEnd) events.push(_properties.animationEnd);

function addEndListener(node, listener) {
    if (events.length) {
        events.forEach(function (e) {
            return node.addEventListener(e, listener, false);
        });
    } else {
        setTimeout(listener, 0);
    }

    return function () {
        if (!events.length) return;
        events.forEach(function (e) {
            return node.removeEventListener(e, listener, false);
        });
    };
}

var propTypes = {
    children: _propTypes2.default.node,
    name: _PropTypes.nameShape.isRequired,

    // Once we require timeouts to be specified, we can remove the
    // boolean flags (appear etc.) and just accept a number
    // or a bool for the timeout flags (appearTimeout etc.)
    appear: _propTypes2.default.bool,
    enter: _propTypes2.default.bool,
    leave: _propTypes2.default.bool,
    appearTimeout: _propTypes2.default.number,
    enterTimeout: _propTypes2.default.number,
    leaveTimeout: _propTypes2.default.number,
};

var CSSTransitionGroupChild = (function (_React$Component) {
    _inherits(CSSTransitionGroupChild, _React$Component);

    function CSSTransitionGroupChild() {
        var _temp, _this, _ret;

        _classCallCheck(this, CSSTransitionGroupChild);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return (
            (_ret =
                ((_temp =
                    ((_this = _possibleConstructorReturn(
                        this,
                        _React$Component.call.apply(_React$Component, [this].concat(args)),
                    )),
                    _this)),
                (_this.componentWillAppear = function (done) {
                    if (_this.props.appear) {
                        _this.transition('appear', done, _this.props.appearTimeout);
                    } else {
                        done();
                    }
                }),
                (_this.componentWillEnter = function (done) {
                    if (_this.props.enter) {
                        _this.transition('enter', done, _this.props.enterTimeout);
                    } else {
                        done();
                    }
                }),
                (_this.componentWillLeave = function (done) {
                    if (_this.props.leave) {
                        _this.transition('leave', done, _this.props.leaveTimeout);
                    } else {
                        done();
                    }
                }),
                _temp)),
            _possibleConstructorReturn(_this, _ret)
        );
    }

    CSSTransitionGroupChild.prototype.componentWillMount = function componentWillMount() {
        this.classNameAndNodeQueue = [];
        this.transitionTimeouts = [];
    };

    CSSTransitionGroupChild.prototype.componentWillUnmount = function componentWillUnmount() {
        this.unmounted = true;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.transitionTimeouts.forEach(function (timeout) {
            clearTimeout(timeout);
        });

        this.classNameAndNodeQueue.length = 0;
    };

    CSSTransitionGroupChild.prototype.transition = function transition(animationType, finishCallback, timeout) {
        var node = (0, _reactDom.findDOMNode)(this);

        if (!node) {
            if (finishCallback) {
                finishCallback();
            }
            return;
        }

        var className = this.props.name[animationType] || this.props.name + '-' + animationType;
        var activeClassName = this.props.name[animationType + 'Active'] || className + '-active';
        var timer = null;
        var removeListeners = void 0;

        (0, _addClass2.default)(node, className);

        // Need to do this to actually trigger a transition.
        this.queueClassAndNode(activeClassName, node);

        // Clean-up the animation after the specified delay
        var finish = function finish(e) {
            if (e && e.target !== node) {
                return;
            }

            clearTimeout(timer);
            if (removeListeners) removeListeners();

            (0, _removeClass2.default)(node, className);
            (0, _removeClass2.default)(node, activeClassName);

            if (removeListeners) removeListeners();

            // Usually this optional callback is used for informing an owner of
            // a leave animation and telling it to remove the child.
            if (finishCallback) {
                finishCallback();
            }
        };

        if (timeout) {
            timer = setTimeout(finish, timeout);
            this.transitionTimeouts.push(timer);
        } else if (_properties.transitionEnd) {
            removeListeners = addEndListener(node, finish);
        }
    };

    CSSTransitionGroupChild.prototype.queueClassAndNode = function queueClassAndNode(className, node) {
        var _this2 = this;

        this.classNameAndNodeQueue.push({
            className: className,
            node: node,
        });

        if (!this.rafHandle) {
            this.rafHandle = (0, _requestAnimationFrame2.default)(function () {
                return _this2.flushClassNameAndNodeQueue();
            });
        }
    };

    CSSTransitionGroupChild.prototype.flushClassNameAndNodeQueue = function flushClassNameAndNodeQueue() {
        if (!this.unmounted) {
            this.classNameAndNodeQueue.forEach(function (obj) {
                // This is for to force a repaint,
                // which is necessary in order to transition styles when adding a class name.
                /* eslint-disable no-unused-expressions */
                obj.node.scrollTop;
                /* eslint-enable no-unused-expressions */
                (0, _addClass2.default)(obj.node, obj.className);
            });
        }
        this.classNameAndNodeQueue.length = 0;
        this.rafHandle = null;
    };

    CSSTransitionGroupChild.prototype.render = function render() {
        var props = _extends({}, this.props);
        delete props.name;
        delete props.appear;
        delete props.enter;
        delete props.leave;
        delete props.appearTimeout;
        delete props.enterTimeout;
        delete props.leaveTimeout;
        delete props.children;
        return _react2.default.cloneElement(_react2.default.Children.only(this.props.children), props);
    };

    return CSSTransitionGroupChild;
})(_react2.default.Component);

CSSTransitionGroupChild.displayName = 'CSSTransitionGroupChild';

CSSTransitionGroupChild.propTypes = process.env.NODE_ENV !== 'production' ? propTypes : {};

exports.default = CSSTransitionGroupChild;
module.exports = exports['default'];
