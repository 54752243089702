// Lib
import { Modifier, EditorState, SelectionState } from 'draft-js';

// Constants
import { EditorChangeType, HANDLER_RETURN_VALUES } from '../../draftjsConstants';

const PUNCTUATION_REGEX = /[,.:;'"?!]/;

export default (char, editorState, eventTimeStamp, { setEditorState }) => {
    if (!PUNCTUATION_REGEX.test(char)) return HANDLER_RETURN_VALUES.notHandled;

    const originalSelection = editorState.getSelection();

    if (!originalSelection.isCollapsed()) return HANDLER_RETURN_VALUES.notHandled;

    const startOffset = originalSelection.getStartOffset();

    // If the current offset is near the start of the block then there's definitely no mention entity before it
    if (startOffset < 2) return HANDLER_RETURN_VALUES.notHandled;

    const startKey = originalSelection.getStartKey();

    const contentState = editorState.getCurrentContent();

    const lastCreatedEntityKey = contentState.getLastCreatedEntityKey();

    if (!lastCreatedEntityKey) return HANDLER_RETURN_VALUES.notHandled;

    const contentBlock = contentState.getBlockForKey(startKey);

    const text = contentBlock.getText();
    const previousChar = text[startOffset - 1];

    if (previousChar !== ' ') return HANDLER_RETURN_VALUES.notHandled;

    const entityBeforeCursor = contentBlock.getEntityAt(startOffset - 2);

    if (!entityBeforeCursor) return HANDLER_RETURN_VALUES.notHandled;

    if (lastCreatedEntityKey !== entityBeforeCursor) return HANDLER_RETURN_VALUES.notHandled;

    const spaceSelection = SelectionState.createEmpty(startKey).merge({
        anchorOffset: startOffset - 1,
        focusOffset: startOffset,
    });

    const newContentState = Modifier.replaceText(contentState, spaceSelection, char, null);

    let newEditorState = EditorState.push(editorState, newContentState, EditorChangeType.INSERT_CHARACTERS);

    newEditorState = EditorState.forceSelection(newEditorState, originalSelection);

    setEditorState(newEditorState);

    return HANDLER_RETURN_VALUES.handled;
};
