import { ENTITIES } from '../../richText/richTextConstants';

export default (contentBlock, cb, contentState) => {
    if (!contentState) return;

    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITIES.LINK;
    }, cb);
};
