import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementMiniCommentThreadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon element-mini-comment-thread"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#D5DFFD"
                d="M4 15a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-3.586L10 17.414 7.586 15H4z"
            />
            <path
                fill="#5882F8"
                d="M5.5 7h9a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1zm0 2h9a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1zm0 2h8a.5.5 0 1 1 0 1h-8a.5.5 0 1 1 0-1z"
            />
        </g>
    </svg>
);
const Memo = memo(ElementMiniCommentThreadIcon);
export default Memo;
