// Lib
import { AnyAction } from 'redux';

// Actions
import {
    checkConnectionToDisableSocketWarnings,
    clearSocketWarningTimeout,
    orchestrateReconnect,
} from './appServerReconnectionActions';

// Types
import { ReduxStore } from '../../types/reduxTypes';

// Constants
import { ELECTRON_APP_SLEEP, ELECTRON_APP_WAKE } from '../../../common/electron/electronConstants';
import { APP_DID_REHYDRATE_FROM_LOCAL_CACHE } from './appServerReconnectionStatusConstants';
import {
    HYBRID_APP_DID_BECOME_ACTIVE,
    HYBRID_APP_ENTER_BACKGROUND,
    HYBRID_APP_ENTER_FOREGROUND,
    HYBRID_APP_RESIGN_ACTIVE,
} from '../../hybridApp/store/hybridAppStoreConstants';

export default (store: ReduxStore) =>
    (next: Function) =>
    (action: AnyAction): AnyAction => {
        if (action.remote) return next(action);
        const { dispatch } = store;

        switch (action.type) {
            case HYBRID_APP_DID_BECOME_ACTIVE:
            case HYBRID_APP_ENTER_FOREGROUND:
            case ELECTRON_APP_WAKE:
            case APP_DID_REHYDRATE_FROM_LOCAL_CACHE:
                dispatch(orchestrateReconnect());
                break;
            // If we've quickly returned the app to the background then we need to stop the
            //  reconnecting spinner from appearing again.
            case HYBRID_APP_RESIGN_ACTIVE:
            case HYBRID_APP_ENTER_BACKGROUND:
            case ELECTRON_APP_SLEEP:
                clearSocketWarningTimeout();
                dispatch(checkConnectionToDisableSocketWarnings());
                break;
        }

        return next(action);
    };
