// Lib
import React, { ReactElement } from 'react';

// Utils
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Hooks
import useCardTiptapEditorExtensions from '../../card/tiptap/useCardTiptapEditorExtensions';
import useClientElementTiptapEditor from '../../../components/tiptapEditor/useClientElementTiptapEditor';

// Components
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { EditorContent } from '@tiptap/react';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './DocumentDetailViewTiptapEditor.scss';

interface DocumentDetailViewTiptapEditorProps {
    element: ImMNElement;
    textContent: TiptapContent;
    editorId: string;
    editorKey: string;
}

const DocumentDetailViewTiptapEditor = (props: DocumentDetailViewTiptapEditorProps): ReactElement => {
    const { textContent, element, editorId, editorKey } = props;

    const extensions = useCardTiptapEditorExtensions({});

    const { editor } = useClientElementTiptapEditor({
        persistedContent: textContent,
        extensions,

        editorId,
        editorKey,

        isEditable: false,
        isEditing: false,
        isSingleSelected: false,
    });

    return (
        <div className="DocumentDetailViewTiptapEditorContainer">
            <DraftJsConversionIndicator element={element} textContent={getTextContent(element)} />
            <EditorContent className="DocumentDetailViewTiptapEditor" editor={editor} />
        </div>
    );
};

export default DocumentDetailViewTiptapEditor;
