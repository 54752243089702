// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Util
import { registerDownloadSource } from '../../../../utils/fileDownloader/javascriptToSwiftFileDownloads';

// Components
import ModalToolbarButton from './ModalToolbarButton';
import DownloadButton from '../../../../components/buttons/DownloadButton';
import Icon from '../../../../components/icons/Icon';

// Styles
import './ModalToolbarDownloadButton.scss';

const ModalToolbarDownloadButton = (props) => {
    const { children } = props;

    return (
        <ModalToolbarButton className="ModalToolbarDownloadButton">
            <DownloadButton {...props} onDownloadStart={() => registerDownloadSource('.ModalToolbarDownloadButton')}>
                <Icon name="modal-toolbar-download" />
                <div className="text">{children}</div>
            </DownloadButton>
        </ModalToolbarButton>
    );
};

ModalToolbarDownloadButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ModalToolbarDownloadButton;
