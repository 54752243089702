// Lib
import { MODAL_CLOSE, MODAL_OPEN } from './modalConstants';

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case MODAL_OPEN:
            return action.modalName || 'true';
        case MODAL_CLOSE:
            return null;
        default:
            return state;
    }
};
