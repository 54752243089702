// Lib
import { Modifier, EditorState } from 'draft-js';

// Utils
import editorGetCurrentInlineStyle, {
    shouldOverrideCurrentInlineStyle,
} from '../../customRichUtils/editorState/editorGetCurrentInlineStyle';

// Constants
import { EditorChangeType, HANDLER_RETURN_VALUES } from '../../draftjsConstants';

export default (char, editorState, eventTimeStamp, { setEditorState }) => {
    if (!shouldOverrideCurrentInlineStyle(editorState)) return HANDLER_RETURN_VALUES.notHandled;

    const inlineStyle = editorGetCurrentInlineStyle(editorState);

    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const newContent = Modifier.insertText(currentContent, selection, char, inlineStyle);
    const updatedEditorState = EditorState.push(editorState, newContent, EditorChangeType.INSERT_CHARACTERS);

    setEditorState(updatedEditorState);

    return HANDLER_RETURN_VALUES.handled;
};
