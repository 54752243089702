// Lib
import React, { ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

// Actions
import { deselectAllElements } from '../../element/selection/selectionActions';

// Styles
import './MobileHeader.scss';

interface MobileHeaderProps {
    className?: string;
    children?: ReactNode;
}

const MobileHeader = (props: MobileHeaderProps): ReactElement => {
    const { children, className } = props;

    const dispatch = useDispatch();
    const dispatchDeselectAllElements = () => {
        dispatch(deselectAllElements());
    };

    return (
        <div className={classNames('MobileHeader', className)} onClick={dispatchDeselectAllElements}>
            <div className="mobile-header-safe-area-padding" />
            <div className="mobile-header-content">{children}</div>
        </div>
    );
};

export default MobileHeader;
