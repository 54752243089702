// Lib
import React, { MutableRefObject } from 'react';
import PropTypes from 'prop-types';
import { convertToRaw, EditorState as DraftEditorState } from 'draft-js';
import { Editor as TiptapEditor } from '@tiptap/core';

// Utils
import { length, prop } from '../../../common/utils/immutableHelper';
import { isReadOnly } from '../../../common/permissions/permissionUtil';

// Components
import AnchorButton from '../../components/buttons/AnchorButton';
import ElementReactionAddButton from '../reactions/ElementReactionAddButton';

// Styles
import './CommentTools.scss';

const isDraftEditorState = (editorState: TiptapEditor | DraftEditorState | null): editorState is DraftEditorState =>
    !!(editorState as DraftEditorState)?.getCurrentContent;

const getEditorStateRawContent = (editorState: TiptapEditor | DraftEditorState | null) => {
    if (!editorState) return null;

    if (isDraftEditorState(editorState)) {
        const contentState = editorState.getCurrentContent();

        if (!contentState || !contentState.hasText()) return null;

        return convertToRaw(contentState);
    }

    if (!editorState.getJSON) return null;

    return editorState.getJSON();
};

type SaveButtonProps = {
    updateComment: (rawText: object) => void;
    editorStateRef: MutableRefObject<TiptapEditor | DraftEditorState | null>;
};

const SaveButton = (props: SaveButtonProps) => {
    const onSave = (e: MouseEvent) => {
        e.stopPropagation();

        const { updateComment, editorStateRef } = props;

        const editorState = editorStateRef?.current;

        const rawText = getEditorStateRawContent(editorState);

        if (!rawText) return;

        updateComment(rawText);
    };

    return <AnchorButton onMouseDown={onSave}>Save</AnchorButton>;
};

SaveButton.propTypes = {
    updateComment: PropTypes.func,
    editorStateRef: PropTypes.object,
};

type CommentToolsProps = {
    _id: string;
    userId: string;
    currentUserId: string;
    startEditing: () => void;
    content: object;
    setKeepToolsVisible: (keepToolsVisible: boolean) => void;
    permissions: number;
    isUpdating: boolean;
    updateComment: (rawText: object) => void;
    editorStateRef: MutableRefObject<TiptapEditor | DraftEditorState | null>;
};

const CommentTools = ({
    _id,
    currentUserId,
    userId,
    startEditing,
    content,
    setKeepToolsVisible,
    permissions,
    isUpdating,
    updateComment,
    editorStateRef,
}: CommentToolsProps) => {
    const canEditComment = currentUserId === userId;
    const hasReactions = length(prop('reactions', content) || []) > 0;

    if (isReadOnly(permissions)) return null;

    return (
        <div className="CommentTools">
            {canEditComment &&
                (isUpdating ? (
                    <SaveButton updateComment={updateComment} editorStateRef={editorStateRef} />
                ) : (
                    <AnchorButton onClickFn={startEditing}>Edit</AnchorButton>
                ))}

            {!canEditComment && !hasReactions && (
                <ElementReactionAddButton elementId={_id} onPopupToggleCb={setKeepToolsVisible} />
            )}
        </div>
    );
};

export default CommentTools;
