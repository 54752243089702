import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';

import { BOARD_DISPLAY_NAME, BOARD_DEFAULT_TITLE } from './boardConstants';
import { DEFAULT_COLOR_PALETTES } from './boardDefaultColorPalettes';
import { ElementType } from '../elements/elementTypes';

const objectConstructorFn = (params) => {
    const { content, acl } = params;

    const colorIndex = Math.floor(Math.random() * DEFAULT_COLOR_PALETTES.length);
    const defaultColorPalette = content?.defaultColorPalette || DEFAULT_COLOR_PALETTES[colorIndex];

    return {
        ...elementConstructor(params),
        elementType: ElementType.BOARD_TYPE,
        content: {
            title: BOARD_DEFAULT_TITLE,
            icon: null,
            color: null,
            secondaryColor: null,
            defaultColorPalette,
            ...content,
        },
        acl,
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.BOARD_TYPE,
        objectConstructorFn,
        displayName: BOARD_DISPLAY_NAME,
        ...params,
    });
