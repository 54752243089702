// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';

// Actions
import { createElementSync } from '../actions/elementActions';

// Components
import Checkbox from '../../components/editor/plugins/checklist/components/Checkbox';
import TaskStyler from './TaskStyler';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import './EmptyTaskListPlaceholder.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateNewTask: ({ location, currentBoardId, creationSource }) =>
        dispatch(
            createElementSync({
                creationSource,
                elementType: ElementType.TASK_TYPE,
                location,
                currentBoardId,
                select: false,
            }),
        ),
});

@connect(null, mapDispatchToProps)
class EmptyTaskListPlaceholder extends React.Component {
    createNewTask = (event) => {
        const { dispatchCreateNewTask, element, currentBoardId, elementEvents } = this.props;

        const location = getListChildNewLocation({ listId: getElementId(element) });

        dispatchCreateNewTask({ location, currentBoardId });
        elementEvents.onClick && elementEvents.onClick(event);
    };

    render() {
        const { elementEvents, largeTop } = this.props;

        return (
            <TaskStyler
                className={classNames('EmptyTaskListPlaceholder', { 'large-top': largeTop })}
                {...elementEvents}
                onClick={this.createNewTask}
            >
                <Checkbox />
                <div className="fake-editor">Add a task...</div>
            </TaskStyler>
        );
    }
}

EmptyTaskListPlaceholder.propTypes = {
    element: PropTypes.object.isRequired,
    currentBoardId: PropTypes.string,
    elementEvents: PropTypes.object,
    dispatchCreateNewTask: PropTypes.func,
    largeTop: PropTypes.bool,
};

export default EmptyTaskListPlaceholder;
