// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, branch } from '../../../node_module_clones/recompose';
import classNames from 'classnames';
import { identity, last } from 'lodash/fp';

// Utils
import { listDropLocationFn } from '../../list/listDropTargetDecorator';
import ElementDropTarget from '../dnd/elementDropTargets/ElementDropTarget';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { isTable } from '../../../common/elements/utils/elementTypeUtils';
import { now } from '../../utils/react/propsComparisons';

// Constants
import { NATIVE_TYPES } from '../../utils/dnd/dragAndDropUtils';
import {
    DROP_TARGET_TYPES,
    ELEMENT_CREATION_SOURCES,
    ELEMENT_DND_TYPE,
} from '../../../common/elements/elementConstants';

// Styles
import './ColumnBottomDropArea.scss';

const nowHovered = now('isHovered');

const dropTargetConfig = {
    drop: listDropLocationFn,
    canDrop: (props, monitor) => {
        const { listCanDropFn } = props;
        return listCanDropFn(props, monitor);
    },
    acceptDropTypes: NATIVE_TYPES,
    hoverType: DROP_TARGET_TYPES.LIST,
    hover: (props, monitor) => {
        if (!monitor || !monitor.canDrop()) return;

        const { isCollapsed } = props;
        monitor.getItem().index = isCollapsed ? 0 : props.listSize;
    },
    collect: (monitor, props) => ({
        isHoveredCanDrop: monitor.canDrop() && monitor.isOver({ shallow: true }) && showHover(monitor, props),
        isHovered: monitor.isOver({ shallow: true }),
    }),
};

const showHover = (monitor, props) => {
    const { childElementIds, isCollapsed } = props;

    // Don't capture the drop if the dragged element is from the column and there's
    // only one element in the list
    const item = monitor.getItem();
    const itemType = monitor.getItemType();

    // For files show the drop target if the column is collapsed or there's children
    if (itemType !== ELEMENT_DND_TYPE) return isCollapsed || !!childElementIds?.length;

    if (!item || (!isCollapsed && (!childElementIds || !childElementIds.length))) return false;

    const { element } = item;

    if (!element) return false;

    const lastElementId = last(childElementIds);
    const elementId = getElementId(element);

    return elementId !== lastElementId;
};

const enhance = branch(
    ({ isPresentational, isEditable }) => isPresentational || !isEditable,
    identity,
    compose(
        withHandlers({
            onDoubleClick:
                ({ createShortcutCard, listSize, listId, isCollapsed }) =>
                () => {
                    // Don't add a new card in the column when double clipping if it's collapsed
                    if (isCollapsed) return;

                    const location = getListChildNewLocation({ listId, index: listSize });

                    createShortcutCard({
                        location,
                        currentBoardId: listId,
                        creationSource: ELEMENT_CREATION_SOURCES.DOUBLE_CLICK,
                    });
                },
        }),
        ElementDropTarget(dropTargetConfig),
    ),
);

/**
 * Note: Using a class component so ReactDnD can find the DOM node that this component is dropped on.
 */
class ColumnBottomDropArea extends React.Component {
    /**
     * This is copied straight from ListContainer.js
     * It ensures that the unsorted notes won't get a drop preview if a column instead will accept the drop.
     */
    componentDidUpdate(prevProps) {
        const { setParentHoveredChildAcceptsDrop, isHoveredCanDrop } = this.props;

        // Drag and Drop - Shallow hover hack
        // If this list is now shallowly hovered, tell a parent list whether this list will accept the drop or not
        if (setParentHoveredChildAcceptsDrop && nowHovered(prevProps, this.props)) {
            setParentHoveredChildAcceptsDrop(isHoveredCanDrop);
        }
    }

    render() {
        const {
            connectDropTarget,
            isHoveredCanDrop,
            listSize,
            isCollapsed,
            onDoubleClick,
            isEditable,
            childrenTypeArray,
        } = this.props;

        // Tables content goes right to the bottom, so we don't want to cover it with the drop area
        const shouldNotCoverFinalChild = isTable(childrenTypeArray.last());

        if (!isEditable || (!isCollapsed && (!listSize || listSize < 1 || shouldNotCoverFinalChild))) return null;

        const classes = classNames('ColumnBottomDropArea', { 'collapsed-hovered': isHoveredCanDrop && isCollapsed });

        return (
            <div className={classes} ref={connectDropTarget} onDoubleClick={onDoubleClick}>
                {isHoveredCanDrop && !isCollapsed && <div className="hovered-placeholder" />}
            </div>
        );
    }
}

ColumnBottomDropArea.propTypes = {
    isPresentational: PropTypes.bool,
    listId: PropTypes.string,
    listCanDropFn: PropTypes.func,
    listSize: PropTypes.number,

    onDoubleClick: PropTypes.func,

    connectDropTarget: PropTypes.func,
    isEditable: PropTypes.bool,
    isHoveredCanDrop: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    childrenTypeArray: PropTypes.object,

    setParentHoveredChildAcceptsDrop: PropTypes.func,
};

export default enhance(ColumnBottomDropArea);
