import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToolbarCollapseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        viewBox="0 0 32 32"
        className="Icon toolbar-collapse"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <rect width={32} height={32} fill="#1B2536" rx={4} />
            <path
                fill="#48505E"
                d="M21.657 9.357c3.124 3.143 3.124 8.238 0 11.381l-4.952 4.981a.997.997 0 0 1-1.41 0l-4.952-4.98c-3.124-3.144-3.124-8.238 0-11.382a7.967 7.967 0 0 1 11.314 0zm-10.25 1.058c-2.482 2.497-2.541 6.511-.177 9.08l.177.186L16 24.3l4.593-4.62c2.482-2.497 2.541-6.51.177-9.08l-.177-.185a6.466 6.466 0 0 0-9.186 0zM16 11.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7zm0 1.5a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 13z"
            />
        </g>
    </svg>
);
const Memo = memo(ToolbarCollapseIcon);
export default Memo;
