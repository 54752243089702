// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Style
import './IconView.scss';

const IconView = React.forwardRef(function IconView(props, ref) {
    const { className, iconElement, secondaryElement, titleElement, style } = props;

    return (
        <div className={classNames('IconView', className)} ref={ref} style={style}>
            <div className="background" />
            <div className="icon-container">{iconElement}</div>
            <div className="details-container">
                {titleElement}
                {secondaryElement && <div className="secondary-details-container">{secondaryElement}</div>}
            </div>
        </div>
    );
});

IconView.propTypes = {
    iconElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    titleElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    secondaryElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    style: PropTypes.object,
};

export default IconView;
