import { createSelector } from 'reselect';
import { getElements } from '../../../selectors/elementsSelector';

export const getIsAnnotationModeEnabled = (state) => state.getIn(['app', 'annotation', 'enabled']);
export const getAnnotationEditedElementId = (state) => state.getIn(['app', 'annotation', 'elementId']);

export const editedAnnotationElementSelector = createSelector(
    getElements,
    getAnnotationEditedElementId,
    (elements, editedElementId) => elements.get(editedElementId),
);
