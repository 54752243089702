import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const NavUndoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        viewBox="0 0 18 19"
        className="Icon nav-undo"
        {...props}
    >
        <path
            fill="#8D929A"
            d="M4.89.728A.5.5 0 0 0 4.188.65l-3.7 2.96a.5.5 0 0 0 0 .78l3.7 2.96A.5.5 0 0 0 5 6.96V4.5h5a6.5 6.5 0 0 1 0 13H7V19h3a8 8 0 1 0 0-16H5V1.04a.5.5 0 0 0-.11-.312z"
        />
    </svg>
);
const Memo = memo(NavUndoIcon);
export default Memo;
