import { DragState } from '../hooks/useModalSheetHandlers';
import { TouchEvent, RefObject } from 'react';

/**
 * Check if the sheet should check for scroll, based on whether the original touch start event.
 */
export const getIsScrollPossible = (event: TouchEvent, sheetContentRef: RefObject<HTMLDivElement>): boolean => {
    const sheetContent = sheetContentRef.current;
    if (!sheetContent) return false;

    const isSheetContentScrollable = sheetContent.scrollHeight > sheetContent.clientHeight;
    const isTouchInScrollableArea = sheetContent.contains(event.target as Node);
    return isSheetContentScrollable && isTouchInScrollableArea;
};

export const isMoveScroll = (dragState: RefObject<DragState>, sheetContentRef: RefObject<HTMLDivElement>): boolean => {
    const { velocity = 0, isScrollPossible } = dragState.current || {};

    if (!isScrollPossible) return false;

    // If the user is touching scrollable content, then this is the only scenario where we want to drag the sheet
    // Note that this is just for the first touch move, so they may pull up after
    const isPullingDown = velocity < 0;
    const isContentScrolledToTop = sheetContentRef.current?.scrollTop === 0;
    const isMoveDrag = isPullingDown && isContentScrolledToTop;
    return !isMoveDrag;
};
