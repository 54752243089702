// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import editorState from './editorStateReducer';
import savedSelection from './savedSelectionReducer';
import editorId from './editorIdReducer';
import originalEditorId from './originalEditorIdReducer';
import editorContext from './editorContext/editorContextReducer';

export default combineReducers({
    editorState,
    editorId,
    originalEditorId,
    savedSelection,
    editorContext,
});
