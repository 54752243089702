// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './PageContent.scss';

const PageContent = ({ children, className, styled = true }) => (
    <div className={classNames('PageContent', className, { StyledPageContent: styled })}>{children}</div>
);

PageContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,

    styled: PropTypes.bool,
};

export default PageContent;
