// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { chooseSingular } from '../../../../common/utils/stringUtil';

// Selectors
import { cloneReachableInstanceCountSelector, cloneInstancesModeSelector } from '../cloneInstancesSelector';

// Actions
import { fetchCloneInstances } from '../cloneInstancesActions';

// Components
import Spinner from '../../../components/loaders/Spinner';
import CardCloneInstancesPopupContentInfo from './CardCloneInstancesPopupContentInfo';
import CardCloneInstancesPopupContentMultiBoards from './CardCloneInstancesPopupContentMultiBoards';

// Constants
import { CLONE_INSTANCES_UI_MODES } from '../cloneInstancesConstants';

// Styles
import './CardCloneInstancesPopupContent.scss';

const mapStateToProps = createStructuredSelector({
    mode: cloneInstancesModeSelector,
    reachableInstanceCount: cloneReachableInstanceCountSelector,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchCloneInstances: (elementId) => dispatch(fetchCloneInstances({ elementId, checkCloneProps: true })),
});

const CardCloneInstancesPopupContent = (props) => {
    const { mode, elementId, reachableInstanceCount, dispatchFetchCloneInstances } = props;

    // When the popup opens, refresh its instances
    React.useEffect(() => {
        dispatchFetchCloneInstances(elementId);
    }, []);

    switch (mode) {
        case CLONE_INSTANCES_UI_MODES.LOADING:
            return (
                <div className="CardCloneInstancesPopupContentLoading">
                    <Spinner show />
                </div>
            );
        case CLONE_INSTANCES_UI_MODES.ERROR:
            return (
                <CardCloneInstancesPopupContentInfo className="error">
                    Something went wrong while fetching synced cards
                </CardCloneInstancesPopupContentInfo>
            );
        case CLONE_INSTANCES_UI_MODES.NO_CLONES:
            return (
                <CardCloneInstancesPopupContentInfo>
                    All other synced cards have now been removed
                </CardCloneInstancesPopupContentInfo>
            );
        case CLONE_INSTANCES_UI_MODES.CURRENT_BOARD_ONLY:
            return (
                <CardCloneInstancesPopupContentInfo className="current-board-only">
                    There {chooseSingular(reachableInstanceCount, 'is', 'are')}&nbsp;
                    {reachableInstanceCount} synced copies on the current board
                </CardCloneInstancesPopupContentInfo>
            );
        case CLONE_INSTANCES_UI_MODES.MANY_BOARDS:
            return <CardCloneInstancesPopupContentMultiBoards {...props} />;
        case CLONE_INSTANCES_UI_MODES.PRIVATE_BOARDS:
            return (
                <CardCloneInstancesPopupContentInfo>
                    <div>All other synced copies are on private boards</div>
                </CardCloneInstancesPopupContentInfo>
            );
        default:
            return (
                <CardCloneInstancesPopupContentInfo className="error">Invalid state</CardCloneInstancesPopupContentInfo>
            );
    }
};

CardCloneInstancesPopupContent.propTypes = {
    mode: PropTypes.string,
    elementId: PropTypes.string,
    reachableInstanceCount: PropTypes.number,
    dispatchFetchCloneInstances: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCloneInstancesPopupContent);
