import * as TYPES from '../../../common/elements/elementConstants';

// Attachment Uploads
const findingIcon = (state, action) =>
    state.update('content', (content) => content.merge({ icon: { fetching: true } }));

const findingIconComplete = (state, action) =>
    state.update('content', (content) => content.merge({ icon: { fetching: false } }));

export default (state, action) => {
    switch (action.type) {
        case TYPES.ELEMENT_ICON_FIND:
            return findingIcon(state, action);
        case TYPES.ELEMENT_ICON_FIND_SUCCESS:
        case TYPES.ELEMENT_ICON_FIND_FAILURE:
            return findingIconComplete(state, action);
        default:
            return state;
    }
};
