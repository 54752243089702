// Lib
import { reduce } from 'lodash';

// Utils
import { matchItalic, matchBold, matchCode, matchStrikethrough } from './markdownPatternMatcher';

// Constants
import { STYLE_COMMANDS } from '../../../richText/richTextConstants';

// Order of precedence matters here due to italic matching bold's pattern
const INLINE_STYLE_MATCH_FUNCTIONS_MAP = {
    [STYLE_COMMANDS.BOLD]: matchBold,
    [STYLE_COMMANDS.ITALIC]: matchItalic,
    [STYLE_COMMANDS.CODE]: matchCode,
    [STYLE_COMMANDS.STRIKETHROUGH]: matchStrikethrough,
};

export default (line) =>
    reduce(
        INLINE_STYLE_MATCH_FUNCTIONS_MAP,
        (styleMatch, fn, inlineStyle) => {
            if (styleMatch) return styleMatch;

            const match = fn(line);
            return match ? { match, inlineStyle } : null;
        },
        null,
    );
