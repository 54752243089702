// Lib
import React, { ReactNode } from 'react';

// Hooks
import { useElementTiptapContent } from '../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Components
import CaptionTiptapEditor from './tiptap/CaptionTiptapEditor';
import CaptionDraftJsEditor from './CaptionDraftJsEditor';

// Types
import { CaptionProps } from './captionComponentTypes';
import { DocumentType } from '../../../common/tiptap/conversion/elementConversion/elementConversion';

const Caption = (props: CaptionProps): ReactNode => {
    const {
        element,
        currentEditorKey,
        currentEditorId,
        textContent,
        filterQuery,
        style,
        isEditing,
        isEditable,
        spellCheck,
        captionVisible,
        isSelected,
        isSingleSelected,
        isFocusedForegroundElement,
        padTop,
        startEditing,
        stopEditing,
        saveCaptionContent,
        onEmptyBackspace,
        createNewCard,
        dispatchUpdateElementType,
        onDoubleClick,
        placeholder,
    } = props;

    if (!captionVisible) return null;

    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.caption);

    if (tiptapContent) {
        return (
            <CaptionTiptapEditor
                captionVisible={captionVisible}
                element={element}
                currentEditorKey={currentEditorKey}
                currentEditorId={currentEditorId}
                textContent={tiptapContent}
                placeholder={placeholder}
                filterQuery={filterQuery}
                style={style}
                isEditing={isEditing}
                isEditable={isEditable}
                isSelected={isSelected}
                isSingleSelected={isSingleSelected}
                isFocusedForegroundElement={isFocusedForegroundElement}
                spellCheck={spellCheck}
                padTop={padTop}
                onDoubleClick={onDoubleClick}
                startEditing={startEditing}
                stopEditing={stopEditing}
                saveCaptionContent={saveCaptionContent}
                onEmptyBackspace={onEmptyBackspace}
                createNewCard={createNewCard}
                dispatchUpdateElementType={dispatchUpdateElementType}
            />
        );
    }

    return <CaptionDraftJsEditor {...props} />;
};

export default Caption;
