// Lib
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Utils
import { getListChildNewLocation } from '../../common/elements/utils/elementLocationUtils';
import {
    getLocationParentId,
    getWidth,
    getXPosition,
    getYPosition,
} from '../../common/elements/utils/elementPropertyUtils';

// Constants
import { BoardSections } from '../../common/boards/boardConstants';

export default (DecoratedComponent) => {
    class newElementDecorator extends React.Component {
        createNewElementInList = (args) => {
            const { dispatchCreateAndEditNewElement, element, elementId, getListIndex, currentBoardId } = this.props;

            const listIndex = (getListIndex && getListIndex(elementId)) || 0;

            const location = getListChildNewLocation({ listId: getLocationParentId(element), index: listIndex + 1 });

            return dispatchCreateAndEditNewElement({
                ...args,
                location,
                currentBoardId,
                element,
            });
        };

        createNewElementOnCanvas = (args) => {
            const { dispatchCreateAndEditNewElement, element, gridSize, currentBoardId } = this.props;

            const thisNode = ReactDOM.findDOMNode(this);
            const thisHeight = Math.round(thisNode.offsetHeight / gridSize);

            const location = {
                parentId: getLocationParentId(element),
                section: BoardSections.CANVAS,
                position: {
                    x: getXPosition(element),
                    y: getYPosition(element) + thisHeight + 1,
                },
            };

            const content = {};

            if (getWidth(element)) {
                content.width = getWidth(element);
            }

            return dispatchCreateAndEditNewElement({
                ...args,
                location,
                currentBoardId,
                element,
                content,
            });
        };

        createNewElement = (args = {}) =>
            this.props.inList ? this.createNewElementInList(args) : this.createNewElementOnCanvas(args);

        render() {
            return <DecoratedComponent {...this.props} createNewElement={this.createNewElement} />;
        }
    }

    newElementDecorator.propTypes = {
        dispatchCreateAndEditNewElement: PropTypes.func,
        element: PropTypes.object,
        elementId: PropTypes.string,
        getListIndex: PropTypes.func,
        gridSize: PropTypes.number,
        currentBoardId: PropTypes.string,
        inList: PropTypes.string,
    };

    return newElementDecorator;
};
