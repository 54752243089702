import { TextAlignment, VerticalAlignment } from './CellTypeConstants';
import { COLORS } from '../colors/colorConstants';

export const TABLE_DISPLAY_NAME = 'Table';

export const TABLE_MIN_WIDTH = 12;
export const TABLE_MIN_HEIGHT = 6;

export const TABLE_CELL_MIN_WIDTH = 4;

export const TABLE_INITIAL_COL_COUNT = 3;
export const TABLE_INITIAL_ROW_COUNT = 4;

export const TABLE_MIN_COL_COUNT = 1;
export const TABLE_MIN_ROW_COUNT = 2;

export const TABLE_DEFAULT_COL_WIDTH = 12;
export const TABLE_DEFAULT_ROW_HEIGHT = 3;

export const TABLE_GRID_EDITOR_KEY = 'TABLE-GRID';
export const TABLE_CELL_EDITOR_KEY = 'TABLE-CELL';
export const TABLE_TITLE_EDITOR_KEY = 'TABLE-TITLE';
export const TABLE_CAPTION_EDITOR_KEY = 'TABLE-CAPTION';

export const TABLE_CELL_DEFAULT_TEXT_ALIGNMENT = TextAlignment.LEFT;
export const TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT = VerticalAlignment.TOP;

// Table colors
export const TABLE_DEFAULT_HEADER_BACKGROUND_COLOR = 'TABLE_DEFAULT_HEADER_BACKGROUND_COLOR';
export const TABLE_DEFAULT_COLORS = {
    ...COLORS,
    GREY: {
        name: 'TABLE_DEFAULT_HEADER_BACKGROUND_COLOR',
        css: 'var(--ws-table-header-row-background)',
        hex: '#D2D3D6',
    },
};
