import { RawDraftContentBlock, RawDraftEntity } from 'draft-js';
import { TiptapContentNode } from '../../../tiptapTypes';

export type StyleAnnotation = { style: string };

export enum FlattenMethod {
    BLOCK = 'block', // become a draft block
    SUBSUME = 'subsume', // become a range in the parent block's text
    NEST = 'nest', // flatten children into parent block, incrementing depth
    GROUP = 'group', // flatten children into parent block, depth doesn't change
}

/**
 * A node in the Tiptap content tree, annotated with information about how it should be
 * flattened into a Draft.js block.
 * This allows us to keep the context of the tree while we're translating things.
 */
export type AnnotatedNode = {
    block: RawDraftContentBlock;
    node: TiptapContentNode;

    flattenMethod: FlattenMethod;

    // root node starts at undefined because nested nodes start at depth 0
    treeDepth: number | undefined;

    entity?: RawDraftEntity;
    styles?: string[];

    children?: AnnotatedNode[];
};

export const getFlattenMethod = (node: TiptapContentNode, parent?: Partial<AnnotatedNode>): FlattenMethod => {
    switch (node.type) {
        case 'doc':
            return FlattenMethod.GROUP;
        case 'text':
        case 'mention':
        case 'hyperlink':
        case 'hardBreak':
            return FlattenMethod.SUBSUME;
        case 'bulletList':
        case 'orderedList':
        case 'taskList':
            return FlattenMethod.NEST;
        case 'paragraph':
            if (['listItem', 'taskItem', 'blockquote'].includes(parent?.node?.type || '')) {
                return FlattenMethod.SUBSUME;
            }
            return FlattenMethod.BLOCK;
        default:
            return FlattenMethod.BLOCK;
    }
};
