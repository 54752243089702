// Types
import { AnyAction } from 'redux';

/**
 * These messages can be sent from the main thread to the Persistence Worker or vice versa.
 */
export enum PersistenceLayerMessageType {
    INITIALISE_WORKER = 'INITIALISE_WORKER',
    // The action sent will be dispatched to that thread's store
    ACTION = 'ACTION',
    // A request / response pair for getting cached board data
    FETCH_BOARDS_REQUEST = 'FETCH_BOARDS_REQUEST',
    FETCH_BOARDS_RESPONSE = 'FETCH_BOARDS_RESPONSE',
    FETCH_BOARDS_ERROR = 'FETCH_BOARDS_ERROR',
}

export type PersistenceLayerMessage = {
    type: PersistenceLayerMessageType;
    payload: unknown;
    requestId?: string;
};

export type PersistenceLayerMessageAction = {
    type: PersistenceLayerMessageType.ACTION;
    payload: AnyAction;
};

export type PersistenceLayerRequestMessage = PersistenceLayerMessage;
export type PersistenceLayerResponseMessage = PersistenceLayerMessage;
