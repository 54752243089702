import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { get } from 'lodash';

// Utils
import { getCurrentUserToken } from './authService';
import { navigateToLogin, navigateToRegister, isSafeRedirectUrl, safeRedirectTo } from '../reducers/navigationActions';

// Selectors
import { getCurrentUser } from '../user/currentUserSelector';
import { getLocation } from '../app/routingSelector';

import PageLoader from '../components/loaders/PageLoader';

const currentUserAvailableSelector = createSelector(
    getCurrentUser,
    (currentUser) => currentUser.get('authenticated') && currentUser.get('fetched'),
);

/**
 * Selector used to build up the user data for Intercom
 */

const authenticatedRouteSelector = createStructuredSelector({
    isUserAvailable: currentUserAvailableSelector,
    location: getLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigateToLogin: (params) => dispatch(navigateToLogin(params)),
    dispatchNavigateToRegister: (params) => dispatch(navigateToRegister(params)),
});

const AuthenticatedRoute = (props) => {
    const {
        isUserAvailable,
        children,
        location,
        redirectParams,
        redirectToRegister,
        redirectToUrl,
        dispatchNavigateToLogin,
        dispatchNavigateToRegister,
    } = props;

    // redirect user if not signed in
    useEffect(() => {
        const pathname = get(location, 'pathname');
        const authToken = getCurrentUserToken();

        if (!authToken) {
            console.warn(
                'AuthenticatedContainer:' +
                    ' Attempted to access an authenticated page without an authentication token',
            );

            let navigateFn;

            if (redirectToUrl && isSafeRedirectUrl(redirectToUrl)) {
                navigateFn = () => {
                    safeRedirectTo(redirectToUrl);
                };
            } else if (redirectToRegister) {
                navigateFn = dispatchNavigateToRegister;
            } else {
                navigateFn = dispatchNavigateToLogin;
            }

            const params = redirectParams || { from: encodeURIComponent(pathname) };

            navigateFn(params);
        }
    }, []);

    if (!isUserAvailable) return <PageLoader />;

    return children;
};

AuthenticatedRoute.propTypes = {
    redirectToRegister: PropTypes.bool,
    isUserAvailable: PropTypes.bool,
    redirectParams: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    location: PropTypes.object,
    requiredUserRoles: PropTypes.array,
    currentUserRole: PropTypes.string,
    redirectToUrl: PropTypes.string,

    dispatchNavigateToLogin: PropTypes.func,
    dispatchNavigateToRegister: PropTypes.func,
};

export default connect(authenticatedRouteSelector, mapDispatchToProps)(AuthenticatedRoute);
