// Utils
import { hasCommandModifier } from '../../../../utils/keyboard/keyboardUtility';

// Constants
import { KEY_CODES } from '../../../../utils/keyboard/keyConstants';
import { EDIT_HYPERLINK_DRAFT_COMMAND } from './hyperlinkPluginConstants';
import getFirstEntityInSelection from '../../store/reducers/getFirstEntityInSelection';

export default (event, { getEditorState }) => {
    // If Cmd+k is used, add a hyperlink
    if (!hasCommandModifier(event) || event.keyCode !== KEY_CODES.K) return;

    const editorState = getEditorState();
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
        const firstEntityKey = getFirstEntityInSelection(editorState);

        if (!firstEntityKey) return;
    }

    return EDIT_HYPERLINK_DRAFT_COMMAND;
};
