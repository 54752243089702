// Utils
import getSelectionBlockMap from '../../customRichUtils/selection/getSelectionBlockMap';

export default (editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    const blockRange = getSelectionBlockMap(editorState);

    let currentBlock = blockRange.first();

    let firstEntityKey = null;
    while (currentBlock && !firstEntityKey) {
        const currentBlockKey = currentBlock.get('key');
        const nextBoardKey = contentState.getKeyAfter(currentBlockKey);

        const startIndex = startKey === currentBlock.get('key') ? Math.max(0, selection.getStartOffset() - 1) : 0;

        const endIndex =
            endKey === currentBlock.get('key') ? Math.max(1, selection.getEndOffset()) : currentBlock.getLength() - 1;

        for (let index = startIndex; index < endIndex; index++) {
            if (firstEntityKey) return firstEntityKey;

            const currentEntityKey = currentBlock.getEntityAt(index);
            if (currentEntityKey) {
                firstEntityKey = currentEntityKey;
            }
        }

        currentBlock = blockRange.get(nextBoardKey);
    }

    return firstEntityKey;
};
