import { CLIPPER_CLEAR, CLIPPER_OPERATION_COMPLETE, CLIPPER_SET } from './clipperConstants';

export const clipperSet = ({ clippedElementId, clippedText }) => ({
    type: CLIPPER_SET,
    clippedElementId,
    clippedText,
});

export const clipperClear = () => ({ type: CLIPPER_CLEAR });

export const clipperOperationComplete = (transactionId) => ({ type: CLIPPER_OPERATION_COMPLETE, transactionId });
