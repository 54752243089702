import { BasePlugin } from 'handsontable/plugins';
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformIos } from '../../../platform/utils/platformDetailsUtils';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import { SelectionController } from 'handsontable/selection';
import { isEmpty } from 'lodash';
import { HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL } from '../../../hybridApp/store/hybridAppStoreConstants';

export const MILANOTE_CONTEXT_MENU_PLUGIN_NAME = 'milanoteContextMenu';

enum IpadClickState {
    UNCLICKED,
    CLICKED_PRE_TIMEOUT,
    CLICKED_POST_TIMEOUT,
}

/**
 * MilanoteContextMenuPlugin.ts
 *  - The purpose of this plugin is to update the way context menu is shown in Milanote table elements, we only
 *    want to show handsontable context menu when one or more cells are selected on the table. Otherwise, show Milanote
 *    context menu
 *  - The way we are doing this is by removing the default handsontable context menu event listeners at the start,
 *    and only reapply it when there is a cell selection on the table
 *  - Some fixes for iPad
 *    - After context menu is shown, any selection on the table will close the context menu
 *    - 2 finger tap on ipad will open context menu on existing selection
 *
 */
class MilanoteContextMenuPlugin extends BasePlugin {
    private contextMenuEventListeners: any[] | null = null;
    private mouseDownEventListener: any | null = null;
    private ipadClickState = IpadClickState.UNCLICKED;
    private ipadClickCount = 0;
    private ipadLastClickTarget: EventTarget | null = null;
    private ipadContextMenuTriggered = false;

    /**
     * This key will be used as a prop key when enabling this plugin in the HotTable component
     */
    static get PLUGIN_KEY(): string {
        return MILANOTE_CONTEXT_MENU_PLUGIN_NAME;
    }

    isEnabled(): boolean {
        return true;
    }

    /**
     * The `enablePlugin` method is triggered on the `beforeInit` hook.
     * It should contain the plugin's initial setup and hook connections.
     * This method is run only if the `isEnabled` method returns `true`.
     */
    enablePlugin(): void {
        this.addHook('beforeOnCellMouseDown', (...args) => this.onBeforeOnCellMouseDown(...args));
        this.addHook('beforeOnCellContextMenu', (...args) => this.beforeOnCellContextMenu(...args));
        this.addHook('afterContextMenuShow', () => this.onAfterContextMenuShow());
        this.addHook('afterContextMenuHide', () => this.onAfterContextMenuHide());
        this.addHook('afterSelectionEnd', (...args) => this.onAfterSelectionEnd(...args));
        this.addHook('afterDeselect', (...args) => this.onAfterDeselect(...args));

        // The `super` method sets the `this.enabled` property to `true`.
        // It is a necessary step to update the plugin's settings properly.
        super.enablePlugin();
    }

    beforeOnCellContextMenu(event: MouseEvent, coords: CellCoords, TD: HTMLTableCellElement): void {
        if (isPlatformIos(platformSingleton) && event.detail === HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL) {
            this.ipadContextMenuTriggered = true;
        }
    }

    onBeforeOnCellMouseDown(
        event: MouseEvent,
        coords: CellCoords,
        TD: HTMLTableCellElement,
        controller: SelectionController,
    ): void {
        // On iOS devices, context menu is triggered by a 2 finger tap. The problem is that the 2 finger tap will
        // trigger 2 separate click events to the table cell before triggering the context menu, which will cause
        // the context menu to be triggered on the wrong cell.
        //
        // To fix this, we want to delay the impact of the first click event by 100ms. and only continue the action
        // once we know for sure it is not a context menu click event.
        const currentCellSelections = this.hot.getSelected();
        if (isPlatformIos(platformSingleton) && currentCellSelections && currentCellSelections.length > 0) {
            this.ipadClickCount += 1;

            if (this.ipadClickState === IpadClickState.UNCLICKED) {
                // @ts-ignore - Handsontable private property. This will prevent stop propagation on Handsontable only,
                // but still allow our element-level event listeners to be triggered
                event.isImmediatePropagationEnabled = false;

                this.ipadClickState = IpadClickState.CLICKED_PRE_TIMEOUT;

                setTimeout(() => {
                    if (!this.ipadContextMenuTriggered && this.ipadClickCount === 1) {
                        this.ipadClickState = IpadClickState.CLICKED_POST_TIMEOUT;

                        // Re-trigger event with fake detail to skip this check
                        const clonedEvent = new MouseEvent(event.type, event);
                        event.target?.dispatchEvent(clonedEvent);

                        // Manually dispatch mouseup event to make sure selection is ended properly
                        const mouseUpEvent = new MouseEvent('mouseup', event);
                        event.target?.dispatchEvent(mouseUpEvent);
                    } else {
                        this.ipadClickState = IpadClickState.UNCLICKED;
                    }

                    this.ipadClickCount = 0;
                    this.ipadLastClickTarget = null;
                    this.ipadContextMenuTriggered = false;
                }, 100);

                return;
            }

            if (this.ipadClickState === IpadClickState.CLICKED_PRE_TIMEOUT) {
                // @ts-ignore - Handsontable private property. This will prevent stop propagation on Handsontable only,
                // but still allow our element-level event listeners to be triggered
                event.isImmediatePropagationEnabled = false;
            }

            if (this.ipadClickState === IpadClickState.CLICKED_POST_TIMEOUT) {
                this.ipadClickState = IpadClickState.UNCLICKED;
            }
        }

        // If we right click on a table when there is no cell selection, we want to prevent the cell that is
        // right-clicked on to be selected. This is because we want to show the Milanote context menu in this case
        const isRightMouseClick = event.button === 2;
        if (isRightMouseClick && isEmpty(this.hot.getSelected())) {
            // @ts-ignore - Handsontable private property. This will prevent stop propagation on Handsontable only,
            // but still allow our element-level event listeners to be triggered
            event.isImmediatePropagationEnabled = false;
        }
    }

    onAfterSelectionEnd(row: number, column: number, row2: number, column2: number, selectionLayerLevel: number): void {
        this.addHotContextMenuEventListeners();
    }

    onAfterDeselect(): void {
        this.removeHotContextMenuEventListeners();
    }

    addHotContextMenuEventListeners(): void {
        if (this.contextMenuEventListeners === null) return;

        this.contextMenuEventListeners.forEach(({ element, event, callback, eventManager }) =>
            eventManager.addEventListener(element, event, callback),
        );

        this.contextMenuEventListeners = null;
    }

    removeHotContextMenuEventListeners(): void {
        if (this.contextMenuEventListeners !== null) return;

        // Collect all potential 'contextmenu' event listeners from handsontable
        this.contextMenuEventListeners = [
            // @ts-ignore - Handsontable private property
            ...(this.hot.eventListeners || []),

            ...(this.hot.getPlugin('contextMenu')?.menu?.eventListeners || []),

            // @ts-ignore - Handsontable private property
            ...(this.hot.view?._wt?.eventListeners || []),
        ].filter((listener) => listener.event === 'contextmenu');

        this.contextMenuEventListeners.forEach(({ element, event, callback, eventManager }) =>
            eventManager.removeEventListener(element, event, callback),
        );
    }

    /**
     * There is an issue on iPad where after Handsontable context menu is shown, any selection on the table will not
     * hide the context menu. This is due to the fact that hiding context menu is only triggered by a 'mousedown' event.
     *
     * This function applies the same event listener for 'touchstart' event to hide the context menu for iPad as well.
     */
    onAfterContextMenuShow(): void {
        // Suspend render of the context menu table to improve performance
        // and avoid re-rendering issues when column outside the viewport
        this.hot.getPlugin('contextMenu')?.menu?.hotMenu.suspendRender();

        if (this.mouseDownEventListener !== null) return;

        // @ts-ignore - Handsontable private property
        const contextMenuPluginEventListeners = this.hot.getPlugin('contextMenu')?.menu?.eventListeners;

        // @ts-ignore - Handsontable private property
        this.mouseDownEventListener = contextMenuPluginEventListeners?.find(({ event }) => event === 'mousedown');
        if (this.mouseDownEventListener) {
            const { element, callback, eventManager } = this.mouseDownEventListener;

            eventManager.addEventListener(element, 'touchstart', callback);
        }
    }

    onAfterContextMenuHide(): void {
        if (this.mouseDownEventListener === null) return;

        const { element, callback, eventManager } = this.mouseDownEventListener;

        eventManager.removeEventListener(element, 'touchstart', callback);

        this.mouseDownEventListener = null;
    }
}

export default MilanoteContextMenuPlugin;
