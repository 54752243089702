// Handlers
import onTab from './onTab';
import onRightOrDown from './onRightOrDown';

// Constants
import { KEY_CODES } from '../../../../../utils/keyboard/keyConstants';

export default (event, draftArgs) => {
    switch (event.keyCode) {
        case KEY_CODES.DOWN_ARROW:
        case KEY_CODES.RIGHT_ARROW:
            return onRightOrDown(event, draftArgs);
        case KEY_CODES.TAB:
            return onTab(event, draftArgs);
        default:
            return undefined;
    }
};
