// Lib
import React from 'react';
import classNames from 'classnames';

// Components
import Tool from './Tool';

// Styles
import './ToolbarTool.scss';

// NOTE: ToolProps is not defined because the Tool file is not TypeScript
interface ToolbarToolProps /* extends ToolProps */ {
    children: React.ReactNode;
    name?: string;
    className?: string;
    isOpen?: boolean;
    onClick?: () => void;
}

const ToolbarTool = (props: ToolbarToolProps) => (
    <Tool {...props} className={classNames('ToolbarTool', props.className, { open: props.isOpen })} />
);

export default ToolbarTool;
