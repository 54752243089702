// Lib
import { createSelector } from 'reselect';
import { getElements } from '../selectors/elementsSelector';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getLocationParentId } from '../../../common/elements/utils/elementPropertyUtils';

export const getCurrentlyEditingState = (state) => state.getIn(['app', 'currentlyEditing']);

export const isCurrentlyEditing = (state) => !!getCurrentlyEditingState(state).get('id');

export const getCurrentlyEditingId = (state) => state.getIn(['app', 'currentlyEditing', 'id']);
export const getCurrentlyEditingEditorKey = (state) => state.getIn(['app', 'currentlyEditing', 'editorKey']);
export const getCurrentlyEditingEditorId = (state) => state.getIn(['app', 'currentlyEditing', 'editorId']);

export const getCurrentlyEditingEditorIdForElement = (elementId) =>
    createSelector(
        getCurrentlyEditingId,
        getCurrentlyEditingEditorId,
        (currentlyEditingId, currentlyEditingEditorId) => {
            return elementId === currentlyEditingId ? currentlyEditingEditorId : null;
        },
    );

// NOTE: This is currently used by the "EditableTitle" as that component does not require an
//   editorId.  MilanoteEditors (Milanote Draft JS editors) do require an editorId, so don't use
//   this in that case.
export const getIsCurrentlyEditingThisElement = (state, { elementId, editorKey }) =>
    getCurrentlyEditingId(state) === elementId && getCurrentlyEditingEditorKey(state) === editorKey;

export const getCurrentlyEditingElement = createSelector(getElements, getCurrentlyEditingId, getElement);

export const getCurrentlyEditingElementParent = createSelector(
    getElements,
    getCurrentlyEditingElement,
    (elements, element) => {
        const parentId = getLocationParentId(element);
        return getElement(elements, parentId);
    },
);
