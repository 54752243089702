const getTextSelectionFocusElement = (): HTMLElement | null => {
    const selection = global.window?.getSelection();

    if (!selection || !selection.focusNode) return null;

    let selectionElement;

    if (selection.focusNode.nodeType === Node.ELEMENT_NODE) {
        selectionElement = selection.focusNode as HTMLElement;
    } else if (selection.focusNode.nodeType === Node.TEXT_NODE) {
        selectionElement = selection.focusNode.parentElement;
    }

    if (!selectionElement) return null;

    return selectionElement;
};

export default getTextSelectionFocusElement;
