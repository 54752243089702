import * as Immutable from 'immutable';

import { POPUP_OPEN, POPUP_TOGGLE, PopupIds } from '../../../../../../components/popupPanel/popupConstants';
import * as POPUP from './iconsPopupConstants';

const initialState = Immutable.Map({
    mode: POPUP.ICON_POPUP_MODE_DEFAULT,
    modesMenu: Immutable.Map(),
    searchResults: Immutable.Map(),
    elementSearches: Immutable.Map(),
});

const setPopupMode = (state, action) => {
    let updateState = state;
    if (action.id) {
        updateState = state.setIn(['elementSearches', action.id, action.mode], action.mode);
    }
    return updateState.set('mode', action.mode);
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POPUP_OPEN:
        case POPUP_TOGGLE:
            if (action.popupId !== PopupIds.ICON) return state;
            return state.set('mode', null);
        case POPUP.ICON_POPUP_MODE_SET:
            return setPopupMode(state, action);
        case POPUP.ICON_POPUP_SEARCH_SUBMIT:
            return state.setIn(['searchResults', action.query], null);
        case POPUP.ICON_POPUP_SEARCH_SUCCESS:
            return state.setIn(['searchResults', action.query], Immutable.fromJS(action.icons));
        case POPUP.ICON_POPUP_SEARCH_FAILURE:
            return state.setIn(['searchResults', action.query], Immutable.List());
        default:
            return state;
    }
};
