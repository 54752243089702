// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';

// Actions
import { elementUnsuggestFeature } from './suggestionActions';

// Components
import ElementTip from './ElementTip';
import AnchorButton from '../../components/buttons/AnchorButton';

const mapDispatchToProps = (dispatch) => ({
    dispatchUnsuggestFeature: ({ id, suggestion }) => dispatch(elementUnsuggestFeature({ id, feature: suggestion })),
});

@connect(null, mapDispatchToProps)
class FeatureSuggestion extends React.Component {
    onConfirm = (event) => {
        const { dispatchUnsuggestFeature, suggestion, element, onConfirm } = this.props;

        const transactionId = getNewTransactionId();
        dispatchUnsuggestFeature({ id: getElementId(element), suggestion, transactionId });

        onConfirm(event, transactionId);
    };

    onDismiss = (event) => {
        const { dispatchUnsuggestFeature, suggestion, element, onDismiss } = this.props;

        event.preventDefault();
        event.stopPropagation();

        dispatchUnsuggestFeature({ id: getElementId(element), suggestion });

        onDismiss(event);
    };

    render() {
        const { question } = this.props;

        return (
            <ElementTip className="FeatureSuggestion" {...this.props}>
                <span className="question">{question}</span>
                <AnchorButton onClickFn={this.onConfirm}>Yes</AnchorButton>
                <AnchorButton onMouseDown={this.onDismiss}>No thanks</AnchorButton>
            </ElementTip>
        );
    }
}

FeatureSuggestion.propTypes = {
    question: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    added: PropTypes.bool,
    icon: PropTypes.object,

    suggestion: PropTypes.string,
    element: PropTypes.object,
    dispatchUnsuggestFeature: PropTypes.func,
};

export default FeatureSuggestion;
