// Lib
import { get, isEmpty } from 'lodash/fp';

// Utils
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isClone } from '../../../common/elements/utils/elementTypeUtils';
import { getLinkedElementId, getHasClones } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getElements } from '../selectors/elementsSelector';

// Actions
import { fetchCloneInstances } from './cloneInstancesActions';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';
import {
    ELEMENT_CONVERT_TO_CLONE,
    ELEMENT_CREATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
    ELEMENT_DIFF_UPDATE,
} from '../../../common/elements/elementConstants';

export const CLONE_PROPERTIES = {
    autoHeight: true,
    maxHeight: true,
    minHeight: true,
    width: true,
    height: true,
    locked: true,
    clone: true,
    displayMode: true,
    showMedia: true,
};

// common util for ELEMENT_UPDATE and ELEMENT_DIFF_UPDATE
const processElementUpdate = (elements, update) => {
    const { id, changes, ...rest } = update;

    const element = getElement(elements, id);
    if (!element) return update;

    if (getHasClones(element)) {
        update.recalculateChannels = true;
    }

    if (!isClone(element)) return update;

    const keys = Object.keys(changes);
    const isCloneChange = keys.every((key) => CLONE_PROPERTIES[key]);

    if (isCloneChange) return update;

    const originalElementId = getLinkedElementId(element);
    if (!originalElementId) return update;

    update.recalculateChannels = true;
    return {
        ...rest,
        id: originalElementId,
        changes,
        isCloneUpdate: true,
        cloneId: id,
    };
};

const handleElementDiffUpdateAction = (store, next, action) => {
    const { getState } = store;
    const state = getState();
    const elements = getElements(state);

    action.recalculateChannels = false;

    const updatedAction = processElementUpdate(elements, action);

    return next(updatedAction);
};

const handleElementUpdateAction = (store, next, action) => {
    const { getState } = store;
    const state = getState();
    const elements = getElements(state);

    action.recalculateChannels = false;
    action.updates = action.updates.map((update) => processElementUpdate(elements, update));

    return next(action);
};

/**
 * This handler doesn't modify the move action, it just refreshes the clone instance counts if a
 * cloned element is deleted or retrieved from the trash.
 */
const handleElementMove = (store, next, action) => {
    const { getState, dispatch } = store;
    const state = getState();

    const elements = getElements(state);

    const changedCloneInstanceCountIds = [];

    action.moves.forEach((move) => {
        const fromSection = get(['from', 'section'], move);
        const toSection = get(['location', 'section'], move);

        if (fromSection !== BoardSections.TRASH && toSection !== BoardSections.TRASH) return;

        const elementId = get('id', move);

        const element = getElement(elements, elementId);

        if (!element) return;

        if (!isClone(element) && !getHasClones(element)) return;

        changedCloneInstanceCountIds.push(elementId);
    });

    if (!isEmpty(changedCloneInstanceCountIds)) {
        changedCloneInstanceCountIds.forEach((elementId) => {
            // Give the clone a chance to get deleted on the server
            setTimeout(() => {
                dispatch(fetchCloneInstances({ elementId, force: true, checkCloneProps: true }));
            }, 150);
        });
    }

    return next(action);
};

/**
 * Similar to the move handler, just refreshes the instance counts to ensure they're up to date
 * when opening the popup.
 */
const handleConvertToClone = (store, next, action) => {
    const { id } = action;

    const { dispatch } = store;

    // Give the clone a chance to get created on the server
    setTimeout(() => {
        dispatch(fetchCloneInstances({ elementId: id, checkCloneProps: false, force: true }));
    }, 200);

    return next(action);
};

export default (store) => (next) => (action) => {
    if (action.remote) return next(action);

    switch (action.type) {
        // This will change the element ID to be the original element ID when necessary for clones
        case ELEMENT_UPDATE:
            return handleElementUpdateAction(store, next, action);
        case ELEMENT_DIFF_UPDATE:
            return handleElementDiffUpdateAction(store, next, action);
        // This will re-fetch the clone instance state when clones get moved into or out of trash
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(store, next, action);
        case ELEMENT_CONVERT_TO_CLONE:
            return handleConvertToClone(store, next, action);
        case ELEMENT_CREATE:
            return isClone(action) ? handleConvertToClone(store, next, action) : next(action);
        default:
            return next(action);
    }
};
