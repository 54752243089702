// Utils
import hasSelectionInCodeBlock from './utils/hasSelectionInCodeBlock';
import insertNewLine from './utils/insertNewLine';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';

export default (evt, editorState, { setEditorState }) => {
    if (!hasSelectionInCodeBlock(editorState)) return HANDLER_RETURN_VALUES.notHandled;

    setEditorState(insertNewLine(editorState));
    return HANDLER_RETURN_VALUES.handled;
};
