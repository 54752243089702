import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCheckoutContext } from '../context/useCheckoutContext';

import CheckoutModalContent from '../components/CheckoutModalContent';
import LinkButton from '../../../../components/buttons/LinkButton';
import ModalBackButton from '../../../../components/modal/ModalBackButton';

import './CheckoutErrorModal.scss';

type CheckoutErrorModalProps = {
    error: string;
};

const CheckoutErrorModal = ({ error }: CheckoutErrorModalProps) => {
    const { t } = useTranslation('checkout', { keyPrefix: 'error' });

    const { dispatchOpenIntercomMessages } = useCheckoutContext();

    return (
        <CheckoutModalContent
            className="CheckoutErrorModal"
            header={
                <>
                    <ModalBackButton />
                    <h1>{t('title')}</h1>
                </>
            }
        >
            <p className="error-message">{error}</p>
            <p className="support-message">
                {t('description')}&nbsp;
                <LinkButton onClick={dispatchOpenIntercomMessages}>{t('contactSupportText')}</LinkButton>
            </p>
        </CheckoutModalContent>
    );
};

export default CheckoutErrorModal;
