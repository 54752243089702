// Lib
import * as Immutable from 'immutable';

// Constants
import { ELEMENT_MOVE_MULTI } from '../../../common/elements/elementConstants';

const initialState = Immutable.fromJS({});

const handleElementMove = (state, action) => {
    if (!action.moves) return state;

    return state.withMutations((mutableState) => {
        action.moves.forEach((move) => {
            if (!move) return;

            const { id, from, autoPush, saveOriginalLocation } = move;

            if (autoPush && saveOriginalLocation) {
                mutableState.set(id, Immutable.fromJS(from));
            } else if (!autoPush) {
                mutableState.delete(id);
            }
        });
    });
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(state, action);
        default:
            return state;
    }
};
