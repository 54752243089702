// Libs
import React from 'react';

// Components
import ToolbarPopupTool from '../../../ToolbarPopupTool';
import DistributePopup from './DistributePopup';

const DistributeTool = (props) => (
    <ToolbarPopupTool {...props} toolName="distribute" PopupComponent={DistributePopup} />
);

export default DistributeTool;
