export const getFloatingPanelMountPoint = (mountPointSelector) =>
    // Allow a custom mount point to be provided
    (mountPointSelector && document.querySelector(mountPointSelector)) ||
    // Match the modal first, if one exists
    document.querySelector('#app .FullScreenModal .modal-positioner') ||
    // The previous selector would match the page loader.
    // Then the tooltip would be removed from the screen when the page loader was replaced with the
    // real workspace.
    // This selector specifically targets the workspace only when it's rendered, otherwise it won't
    // render the tooltip
    document.querySelector('#app .Workspace.workspace-ready');
