// Lib
import * as Immutable from 'immutable';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import { DEBUG_TOOL_UPDATE } from './debugToolConstants';

const initialState = Immutable.fromJS({});

const handleDebugToolUpdate = (state, action) => {
    // eslint-disable-next-line no-unused-vars
    const { toolName, user, type, ...rest } = action;

    const update = Immutable.fromJS({
        lastUpdateTimestamp: getTimestamp(),
        ...rest,
    });

    return state.update(toolName, Immutable.fromJS({}), (originalState) => originalState.merge(update));
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DEBUG_TOOL_UPDATE:
            return handleDebugToolUpdate(state, action);
        default:
            return state;
    }
};
