// Lib
import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

// Utils
import { createShallowSelector } from '../../../../../../utils/milanoteReselect/milanoteReselect';
import { getAttachments, getDataUri } from '../../../../../../element/attachments/attachmentsSelector';
import makeStringIcon from '../../../../../../components/elementIcon/makeStringIconDef';
import popupOpenSelector from '../../../../../../components/popupPanel/popupOpenSelector';
import { getSelectedElements } from '../../../../../../element/selection/selectedElementsSelector';
import { getElementId } from '../../../../../../../common/elements/utils/elementPropertyUtils';
import {
    getAcronyms,
    filterWords,
    removeSpecialCharacters,
} from '../../../../../../components/elementIcon/elementIconUtils';

// Constants
import {
    ICON_POPUP_MODE_DEFAULT,
    ICON_POPUP_MODE_LETTERS,
    ICON_POPUP_MODE_IMAGES,
    ICON_POPUP_MODE_LIST_MAX,
    STRING_ICONS,
    NUMBER_ICONS,
} from './iconsPopupConstants';
import { PopupIds } from '../../../../../../components/popupPanel/popupConstants';

// Helper functions
const getFirstSelected = (propertyPath) => (selectedElements) =>
    selectedElements.first() ? selectedElements.first().getIn(propertyPath) : null;
const getFirstAttachmentData = (selectedElements, attachments) => {
    const firstSelectedElement = selectedElements.first();
    const firstSelectedElementId = getElementId(firstSelectedElement);
    return getDataUri(attachments.get(firstSelectedElementId));
};
const getSelectedImage = (selectedElements, attachments) =>
    getFirstAttachmentData(selectedElements, attachments) ||
    getFirstSelected(['content', 'image', 'regular'])(selectedElements) ||
    getFirstSelected(['content', 'image', 'original'])(selectedElements);
const getSelectedIcon = getFirstSelected(['content', 'icon']);

const getFirstElementId = getFirstSelected(['id']);

// Selected property selectors
export const firstElementIdSelector = createSelector(getSelectedElements, getFirstElementId);

// Selected property selectors
export const selectedIconSelector = createSelector(getSelectedElements, getSelectedIcon);

export const selectedImageSelector = createSelector(getSelectedElements, getAttachments, getSelectedImage);

// Popup state selectors
export const popupModeSelector = createSelector(
    (state, ownProps) => state.getIn(['app', 'popup', 'icons', 'mode']),
    selectedImageSelector,
    (popupMode, selectedImage) => {
        let actualPopupMode = popupMode;
        if (!actualPopupMode) {
            actualPopupMode = selectedImage ? ICON_POPUP_MODE_IMAGES : ICON_POPUP_MODE_DEFAULT;
        }
        return actualPopupMode;
    },
);

const selectedElementTitleSelector = createSelector(
    (state, ownProps) => ownProps.selectedElements,
    (selectedElements) => selectedElements && selectedElements.first().getIn(['content', 'title']),
);

const popupQuerySelector = createSelector(
    popupModeSelector,
    selectedElementTitleSelector,
    (popupMode, selectedTitle) => {
        if (popupMode === ICON_POPUP_MODE_DEFAULT) {
            return selectedTitle;
        }

        if (popupMode === ICON_POPUP_MODE_LETTERS) {
            return ICON_POPUP_MODE_LETTERS;
        }

        return popupMode;
    },
);

const lettersSelector = createShallowSelector(selectedElementTitleSelector, (selectedTitle) => {
    const iconStringList = getAcronyms(selectedTitle);
    const stringIcons = iconStringList.map((letter) => makeStringIcon(letter));
    return Immutable.List(stringIcons);
});

const iconListSelector = createShallowSelector(
    popupModeSelector,
    popupQuerySelector,
    selectedElementTitleSelector,
    lettersSelector,
    (state, ownProps) => state.getIn(['app', 'popup', 'icons', 'searchResults']),
    (popupMode, popupQuery, selectedTitle, lettersList, searchResults) => {
        switch (popupMode) {
            case ICON_POPUP_MODE_DEFAULT:
                return (
                    searchResults.get(popupQuery) &&
                    searchResults.get(popupQuery).slice(0, 8).concat(lettersList).concat(NUMBER_ICONS)
                );
            case ICON_POPUP_MODE_LETTERS:
                return lettersList.concat(NUMBER_ICONS).concat(STRING_ICONS);
            default:
                return searchResults.get(popupQuery);
        }
    },
);

const iconPopupModeListSelector = createSelector(
    (state, ownProps) => ownProps.selectedElements,
    selectedElementTitleSelector,
    (state) => state.getIn(['app', 'popup', 'icons', 'elementSearches']),
    selectedImageSelector,
    (selectedElements, selectedTitle, elementSearches, selectedImage) => {
        let popupModeList = [{ slug: ICON_POPUP_MODE_DEFAULT, title: 'Recommended' }];
        let customList = [];

        const title = selectedTitle || '';

        const splitTitle = title
            .split(' ')
            .filter(filterWords())
            .map(removeSpecialCharacters)
            .filter((word) => word.length > 0);

        if (splitTitle.length > 1) {
            splitTitle.forEach((part) => {
                customList.push({
                    slug: part,
                    title: part,
                });
            });
        }

        const firstEl = getElementId(selectedElements.first());
        const savedSearches = elementSearches.get(firstEl) && elementSearches.get(firstEl).valueSeq().toArray();

        if (savedSearches) {
            savedSearches.forEach((q) => {
                customList.push({
                    title: q,
                    slug: q,
                });
            });
        }

        customList = customList.slice(-(ICON_POPUP_MODE_LIST_MAX - 3));
        popupModeList = popupModeList.concat(customList);

        popupModeList.push({ slug: ICON_POPUP_MODE_LETTERS, title: 'Letters & numbers' });

        const imageModeTitle = selectedImage ? 'Custom image' : 'Upload an image';
        popupModeList.push({ slug: ICON_POPUP_MODE_IMAGES, title: imageModeTitle });
        return popupModeList;
    },
);

const iconPopupSelector = createSelector(
    popupOpenSelector(PopupIds.ICON),
    popupQuerySelector,
    iconPopupModeListSelector,
    iconListSelector,
    selectedImageSelector,
    (isActive, popupQuery, modeList, iconList, selectedImage) => {
        if (!isActive) return { isActive };

        return {
            isActive,
            popupQuery,
            modeList,
            iconList,
            selectedImage,
        };
    },
);

export default iconPopupSelector;
