// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import data from './notificationsDataReducer';
import hasMore from './notificationsHasMoreReducer';
import boardNotificationPreferences from '../preferences/boardNotificationPreferencesReducer';

export default combineReducers({
    data,
    hasMore,
    boardNotificationPreferences,
});
