import { ELEMENT_DEFAULT_WIDTH, ELEMENT_SMALL_WIDTH } from '../elements/elementConstants';

export const IMAGE_DISPLAY_NAME = 'Image';

// Image size constants
export const IMAGE_WIDTH_THUMB = 1;
export const IMAGE_WIDTH_REGULAR = 400;
export const IMAGE_WIDTH_LARGE = 800;
export const IMAGE_WIDTH_MAX = 1600;

export const IMAGE_MIN_WIDTH_GRID = 4;
export const IMAGE_SMALL_WIDTH_GRID = ELEMENT_SMALL_WIDTH;

export const IMAGE_PLACEHOLDER_DEFAULT_HEIGHT = 20;

// This size is relative, so the * 10 is not really important
export const DEFAULT_IMAGE_UPLOADER_SIZE = {
    width: ELEMENT_DEFAULT_WIDTH * 10,
    height: IMAGE_PLACEHOLDER_DEFAULT_HEIGHT * 10,
};
export const DEFAULT_PLACEHOLDER_SIZE = DEFAULT_IMAGE_UPLOADER_SIZE;

/*

Note: images sizes also declared in:
    - infra/src/edge/lambda/appMediaViewerRequest/imageSizes.js
    - infra/src/aws-serverless-image-handler/image-handler/imageSizes.ts

These definitions should be kept in sync.
TODO: Put in a build process for Lambda@Edge functions so these can pull from the same file

*/

export const IMAGE_SIZES = {
    THUMB: {
        name: 'thumb',
        width: IMAGE_WIDTH_THUMB,
        isDataUri: true,
    },
    REGULAR: {
        name: 'regular',
        width: IMAGE_WIDTH_REGULAR,
        quality: 75,
    },
    LARGE: {
        name: 'large',
        width: IMAGE_WIDTH_LARGE,
        quality: 70,
    },
    HUGE: {
        name: 'huge',
        width: IMAGE_WIDTH_MAX,
        quality: 65,
    },
    ORIGINAL: {
        name: 'original',
        width: Infinity,
        original: true,
    },
};

export const EXPORT_HIGH_QUALITY_IMAGE_WIDTH = 999999999;

export const LINKED_IMAGE_URL_PREFIX = '/api/media/safe?url=';

export const PEXELS_ATTRIBUTION_LINK = 'https://www.pexels.com';
