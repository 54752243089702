// Lib
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

// Actions
import { updateReaction } from './reactionActions';

// Components
import ElementReactionPill from './ElementReactionPill';
import { canGiveFeedback, canWrite } from '../../../common/permissions/permissionUtil';

const mapDispatchToProps = (dispatch: Function) => ({
    toggleReaction: ({ elementIds, emojiCode }: { elementIds: string[]; emojiCode: string }) =>
        dispatch(
            updateReaction({
                updateIds: elementIds,
                emojiCode,
            }),
        ),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ElementReactionPillContainerProps extends PropsFromRedux {
    elementId: string;
    className: string;
    permissions: number;
}

const ElementReactionPillContainer = (props: ElementReactionPillContainerProps) => {
    const { toggleReaction, elementId, permissions } = props;

    const userCanWriteReactions = canWrite(permissions) || canGiveFeedback(permissions);

    const toggleReactionForEmoji = (emojiCode: string) =>
        toggleReaction({
            elementIds: [elementId],
            emojiCode,
        });

    return <ElementReactionPill {...props} toggleReaction={toggleReactionForEmoji} disabled={!userCanWriteReactions} />;
};

export default connector(ElementReactionPillContainer);
