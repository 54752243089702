// Lib
import React from 'react';

// Actions
import { getIsOnlyParentTaskListSelectedThunk, returnFocusFromPillPopup } from './taskPillActions';
import { closePopup } from '../../../../components/popupPanel/popupActions';
import { deselectAllElements } from '../../../selection/selectionActions';

/**
 * This hook is used to manage the editor focus & selection state when a task popup is focused.
 * When the popup is opened, we want the following:
 *  - The toolbar to show the task's options
 * When the popup is closed:
 *  - If the task was previously being edited, then it should be returned to in its editing state.
 *  - Otherwise finish editing everything
 */
export default (props) => {
    React.useEffect(() => {
        const { elementId, popupId, dispatch, dispatchSetFocusToPopupInput } = props;

        const initiallySelected = dispatch(getIsOnlyParentTaskListSelectedThunk({ elementId }));
        if (!initiallySelected) dispatch(deselectAllElements());

        // Do this so we can return focus later if we need to
        dispatchSetFocusToPopupInput && dispatchSetFocusToPopupInput({ elementId });

        return () => {
            // If the task was being edited when the popup was opened, then return focus to the task
            dispatch(returnFocusFromPillPopup({ elementId, initiallyEditing: initiallySelected }));

            // Ensure the popup state is cleaned up when this is removed
            dispatch(closePopup(popupId, false));
        };
    }, []);
};
