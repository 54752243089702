// Lib
import { AnyAction } from 'redux';

// Utils
import {
    FALLBACK_ENTITY_ID,
    RESOURCES_FETCH_ERROR,
    RESOURCES_FETCHED,
    RESOURCES_FETCHED_FROM_CACHE,
    RESOURCES_FETCHING,
    RESOURCES_MARKED_AS_STALE,
    RESOURCES_CACHED,
    RESOURCES_UPDATE_FETCHED_TIME,
} from '../asyncResourceConstants';

// Sub-reducer
import defaultAsyncResourceEntityReducer, {
    defaultAsyncResourceEntityError,
} from './defaultAsyncResourceEntityReducer';

// Constants

// Types
import { AsyncResourceType } from './asyncResourceReducerTypes';
import {
    AsyncResourceFetchedAction,
    AsyncResourceFetchErrorAction,
    AsyncResourceFetchingAction,
    AsyncResourceMarkAsStaleAction,
} from '../asyncResourceActionsTypes';

const delegateToEntityReducer = (
    state: AsyncResourceType,
    action: AsyncResourceFetchingAction | AsyncResourceFetchedAction | AsyncResourceMarkAsStaleAction,
): AsyncResourceType => {
    const { ids } = action;

    const idsToUpdate = ids ? ids : [FALLBACK_ENTITY_ID];

    // Ensure there's a new version of the state object returned
    const updatedState = {
        ...state,
    };

    for (const id of idsToUpdate) {
        updatedState[id] = defaultAsyncResourceEntityReducer(updatedState[id], action, id);
    }

    return updatedState;
};

const delegateErrorEntityReducer = (state: AsyncResourceType, action: AsyncResourceFetchErrorAction) => {
    const { errors } = action;

    if (!errors) return state;

    // Ensure there's a new version of the state object returned
    const updatedState = {
        ...state,
    };

    for (const error of errors) {
        const id = error.id || FALLBACK_ENTITY_ID;

        updatedState[id] = defaultAsyncResourceEntityError(updatedState[id], action, id, error);
    }

    return updatedState;
};

const initialState: AsyncResourceType = {};

export default (state = { ...initialState }, action: AnyAction): AsyncResourceType => {
    switch (action.type) {
        case RESOURCES_FETCHING:
        case RESOURCES_FETCHED:
        case RESOURCES_CACHED:
        case RESOURCES_FETCHED_FROM_CACHE:
        case RESOURCES_UPDATE_FETCHED_TIME:
        case RESOURCES_MARKED_AS_STALE:
            return delegateToEntityReducer(
                state,
                action as AsyncResourceFetchingAction | AsyncResourceFetchedAction | AsyncResourceMarkAsStaleAction,
            );
        // TODO Determine whether this should use IDs instead
        case RESOURCES_FETCH_ERROR:
            return delegateErrorEntityReducer(state, action as AsyncResourceFetchErrorAction);
        default:
            return state;
    }
};
