/**
 * Return the output format depending on first four hex values of an image file.
 * Uses the image buffer's magic number to determine the image type.
 * See list here: https://en.wikipedia.org/wiki/List_of_file_signatures / https://gist.github.com/leommoore/f9e57ba2aa4bf197ebc5
 * @param fileSignature first 4 bytes of the file as a hex string
 * @returns The output format.
 */
export const lookupImageMagicBytes = (fileSignature: string): string | undefined => {
    switch (fileSignature) {
        case '89504E47':
            return 'image/png';
        case 'FFD8FFDB':
        case 'FFD8FFE0': /* Infer that any file in in FFD8FFE0-EF range is a JPEG (https://gist.github.com/leommoore/f9e57ba2aa4bf197ebc5?permalink_comment_id=3863054#gistcomment-3863054) */
        case 'FFD8FFE1':
        case 'FFD8FFE2':
        case 'FFD8FFE3':
        case 'FFD8FFE4':
        case 'FFD8FFE5':
        case 'FFD8FFE6':
        case 'FFD8FFE7':
        case 'FFD8FFE8':
        case 'FFD8FFE9':
        case 'FFD8FFEA':
        case 'FFD8FFEB':
        case 'FFD8FFEC':
        case 'FFD8FFED':
        case 'FFD8FFEE':
        case 'FFD8FFEF':
            return 'image/jpeg';
        case '52494646':
            return 'image/webp';
        case '49492A00':
        case '4d4d002A':
            return 'image/tiff';
        case '47494638':
            return 'image/gif';
        default:
            return;
    }
};

export const inferFileMimeType = async (file: File): Promise<string | undefined> => {
    // short circuit if the file already has a mime type
    if (file.type && file.type !== '') return file.type;

    // otherwise, read the first 4 bytes of the file and infer the mime type from that
    const fileReader = new FileReader();
    // only read first 4 bytes
    const blob = file.slice(0, 4);

    const fileReaderPromise = new Promise<string>((resolve) => {
        fileReader.onloadend = () => {
            const arr = new Uint8Array(fileReader.result as ArrayBuffer);
            let header = '';

            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }

            resolve(header.toUpperCase());
        };

        // if this fails, we can't infer the mime type, so just return an empty string — this will allow the file to be uploaded with an octet-stream mime type
        fileReader.onerror = () => {
            resolve('');
        };
    });

    fileReader.readAsArrayBuffer(blob);

    const header = await fileReaderPromise;

    // Return the mime format depending on first four hex values of an image file.
    return lookupImageMagicBytes(header);
};

export const getFileContentType = async (file: File): Promise<string> => {
    const { type } = file;
    if (type) return type;

    const inferredType = await inferFileMimeType(file);
    if (inferredType) return inferredType;

    return '';
};
