// Lib
import React from 'react';

// Components
import DefaultErrorRenderer from '../../error/errorBoundary/DefaultErrorRenderer';

// Styles
import './ModalErrorRenderer.scss';

const ModalErrorRenderer = (props) => (
    <DefaultErrorRenderer {...props} className="ModalErrorRenderer" heading="Something went wrong within this modal" />
);

export default ModalErrorRenderer;
