import React from 'react';
import PropTypes from 'prop-types';

// Components
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import Caption from '../../components/caption/Caption';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import ElementResizeHandle from '../resizing/ElementResizeHandle';
import ElementActivityIndicator from '../activity/ElementActivityIndicator';
import QuickNotesActivityIndicator from '../activity/QuickNotesActivityIndicator';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import ColorSwatchRenderer from './ColorSwatchRenderer';

// Utils
import { elementClassNames, getDomElementId, isElementBelowEditableWidth } from '../utils/elementUtil';
import { getElementStyle } from '../../utils/grid/gridUtils';
import { getCaption, getShowCaption, getWidth } from '../../../common/elements/utils/elementPropertyUtils';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

// Styles
import './ColorSwatch.scss';

const ColorSwatch = (props) => {
    const {
        currentBoardId,
        elementId,
        element,
        gridSize,
        inList,
        tempSize,
        elementEvents,
        isEditable,
        isLocked,
        permissions,
        isPresentational,
        setParentHoveredChildAcceptsDrop,
        getContextZoomScale,
        getContextZoomTranslationPx,
        onSwatchDoubleClick,
    } = props;

    const captionContent = getCaption(element);
    const captionVisible = getShowCaption(element) && !isElementBelowEditableWidth(props);
    const elementSize = getWidth(element);

    const width = (tempSize && tempSize.width) || elementSize || ELEMENT_DEFAULT_WIDTH;

    const classes = elementClassNames(`ColorSwatch drag-handle`, props, {
        'small-width': width <= 15,
        'caption-visible': captionVisible,
    });

    return (
        <div
            id={getDomElementId(elementId)}
            className={classes}
            style={getElementStyle(width, gridSize, inList)}
            {...elementEvents}
        >
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementActivityIndicator {...props} />
            <QuickNotesActivityIndicator {...props} />
            <ElementUserTag {...props} />

            <ColorSwatchRenderer onDoubleClick={onSwatchDoubleClick} {...props} />

            <Caption
                {...props}
                padTop
                placeholder="Add a caption"
                textContent={captionContent}
                captionVisible={captionVisible}
            />

            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                isRelative
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                elementId={elementId}
                element={element}
                gridSize={gridSize}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
            <ElementResizeHandle {...props} showHandle={!isLocked && isEditable && !inList} />
        </div>
    );
};

ColorSwatch.propTypes = {
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    inList: PropTypes.string,
    uploadFile: PropTypes.func,
    isHovered: PropTypes.bool,
    tempSize: PropTypes.object,
    className: PropTypes.string,
    elementEvents: PropTypes.object,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    filterQuery: PropTypes.string,
    filterState: PropTypes.string,
    permissions: PropTypes.number,
    isPresentational: PropTypes.bool,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
    onSwatchDoubleClick: PropTypes.func,
};

export default ColorSwatch;
