export const getAllDraggableAncestors = (targetElement) => {
    const draggableAncestors = [];

    let element = targetElement;

    while (element) {
        element = element.parentElement && element.parentElement.closest('[draggable]');
        if (element) draggableAncestors.push(element);
    }

    return draggableAncestors;
};

/**
 * Prevents the comment thread from being dragged when another user's comment text is being selected.
 */
export const allowTextSelection = (event) => {
    const eventTarget = event.target;

    const draggableAncestors = getAllDraggableAncestors(eventTarget);
    draggableAncestors.forEach((el) => el.setAttribute('draggable', false));
};
