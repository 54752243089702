import { useEffect } from 'react';
import { Extension, Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { useSelector } from 'react-redux';
import { getElementFilterQuerySelector } from '../../../client/element/elementFilter/elementFilterSelector';
import { findMatchingTextRanges } from '../utils/findMatchingTextRanges';

const NAME = 'searchHighlighter';

// implemented as a component to avoid re-rendering editors unnecessarily when the selector changes
export const SearchHighlightObserver = ({ editor }: { editor: Editor | null }) => {
    const currentQuery: string = useSelector(getElementFilterQuerySelector) || '';
    useEffect(() => {
        if (!editor) return;
        const { view } = editor;
        view.dispatch(view.state.tr.setMeta(NAME, currentQuery));
    }, [currentQuery]);

    return null;
};

// onHighlightsChanged doesn't take any arguments, it just needs to tell the
// containing component that it's time to recalculate.
const createPlugin = (onHighlightsChanged?: Function) =>
    new Plugin({
        key: new PluginKey(NAME),
        state: {
            init: () => DecorationSet.empty,

            apply: (transaction, oldState) => {
                const query = transaction.getMeta(NAME);

                if (query === undefined) return oldState; // Transaction isn't related to this plugin, do nothing

                onHighlightsChanged?.();

                if (query === '') return DecorationSet.empty;

                const { doc } = transaction;
                const ranges = findMatchingTextRanges(doc, new RegExp(query.toLowerCase(), 'gi'));

                return DecorationSet.create(
                    doc,
                    ranges.map((m, index) => {
                        return Decoration.inline(m.from, m.to, {
                            class: 'SearchHighlightSpan',
                            'data-poi-id': `${m.from}-${m.to}`,
                        });
                    }),
                );
            },
        },

        props: {
            decorations(state) {
                return this.getState(state);
            },
        },
    });

export const SearchHighlighter = Extension.create({
    name: NAME,

    addOptions() {
        return {
            onHighlightsChanged: () => null,
        };
    },

    addProseMirrorPlugins() {
        return [createPlugin(this.options.onHighlightsChanged)];
    },
});
