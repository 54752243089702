// Lib
import { EditorState } from 'draft-js';

// Utils
import getFirstEntityInSelection from './getFirstEntityInSelection';
import setLink from '../../customRichUtils/setLink';
import { prop } from '../../../../../common/utils/immutableHelper';

export default (editorState, action) => {
    const { entityType, mutability, data, forceSelection, mergeData } = action;

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    let entityKey = getFirstEntityInSelection(editorState);

    // Ensure the entity that we're applying is the same type as the first entity.
    // Otherwise apply another one over the top.
    // NOTE: Draft JS and this method doesn't currently support overlapping entities.  Though it
    //  might be possible to create custom decorators to support this.
    if (entityKey) {
        const firstEntity = contentState.getEntity(entityKey);
        const firstEntityType = prop('type', firstEntity);

        entityKey = firstEntityType === entityType ? entityKey : null;
    }

    // Do nothing if no text is selected and we're not currently within an entity
    if (selection.isCollapsed() && !entityKey) return editorState;

    if (entityKey) {
        // Modify the existing data
        mergeData ? contentState.mergeEntityData(entityKey, data) : contentState.replaceEntityData(entityKey, data);
    } else {
        const contentStateWithEntity = contentState.createEntity(entityType, mutability, data);
        entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    }

    const updatedEditorState = forceSelection
        ? EditorState.forceSelection(editorState, editorState.getSelection())
        : editorState;

    return setLink(updatedEditorState, updatedEditorState.getSelection(), entityKey);
};
