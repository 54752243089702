// Lib
import * as Rematrix from 'rematrix';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import * as rectLib from '../../../common/maths/geometry/rect';
import * as matrixLib from '../../../common/maths/matrix/matrixUtils';
import * as rectMatrixUtils from '../../../common/maths/matrix/rectMatrixUtils';
import { gridPointsToPixels, pixelsToGridPoints } from '../../utils/grid/gridUtils';
import { convertToRect, convertToRectPoints, RectPointVectors } from '../../../common/maths/matrix/rectMatrixUtils';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';
import { DEFAULT_CANVAS_ORIGIN } from '../../../common/elements/utils/elementPositionUtils';

// Types
import { Matrix3D } from 'rematrix';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';
import { Point } from '../../../common/maths/geometry/pointTypes';
import { MNElementLocation, MNElementPosition } from '../../../common/elements/elementModelTypes';

const NO_TRANSLATION = { x: 0, y: 0 };

const getPropX = prop('x');
const getPropY = prop('y');

/**
 * Determines the number of pixels into the canvas-document that a client position equates to.
 */
const getCanvasDocumentPixels = (
    clientPositionDimension: number,
    scrollDimension: number,
    componentPositionDimension: number,
    zoomTranslationDimension = 0,
    zoomScale = 1,
): number =>
    (clientPositionDimension + scrollDimension - componentPositionDimension - zoomTranslationDimension) / zoomScale;

/**
 * Determines the number of grid points into the canvas-document that a client position equates to.
 */
const getCanvasDocumentGridPoints = (
    clientPositionDimension: number,
    scrollDimension: number,
    componentPositionDimension: number,
    canvasOriginDimension: number,
    zoomTranslationDimension = 0,
    zoomScale = 1,
    gridSize: number,
): number => {
    const pixels = getCanvasDocumentPixels(
        clientPositionDimension,
        scrollDimension,
        componentPositionDimension,
        zoomTranslationDimension,
        zoomScale,
    );
    return pixelsToGridPoints(pixels, gridSize) + canvasOriginDimension;
};

/**
 * Determines the canvas-document grid position that a client position equates to.
 */
const getGridPosition = (
    canvasViewportNode: HTMLElement,
    clientPositionPx: Point,
    gridSize: number,
    canvasOrigin: Point = DEFAULT_CANVAS_ORIGIN,
    zoomScale: number,
    zoomTranslationPx: Point,
): Point => {
    const domComponentRect = canvasViewportNode.getBoundingClientRect();
    const { scrollLeft, scrollTop } = canvasViewportNode;

    return {
        x: getCanvasDocumentGridPoints(
            clientPositionPx.x,
            scrollLeft,
            domComponentRect.left,
            getPropX(canvasOrigin),
            getPropX(zoomTranslationPx),
            zoomScale,
            gridSize,
        ),
        y: getCanvasDocumentGridPoints(
            clientPositionPx.y,
            scrollTop,
            domComponentRect.top,
            getPropY(canvasOrigin),
            getPropY(zoomTranslationPx),
            zoomScale,
            gridSize,
        ),
    };
};

/**
 * This determines how far off the grid point an element is dropped.
 * This is used to aid the drop animation, so the dropped element can begin off the grid and then animate
 * to the grid point.
 */
export const getGridOffset = (
    domComponent: HTMLElement,
    clientPositionPx: Point,
    gridSize: number,
    zoomScale: number,
    zoomTranslationPx: Point,
): Point => {
    const domComponentRect = domComponent.getBoundingClientRect();
    const { scrollLeft, scrollTop } = domComponent;

    const xPos = (clientPositionPx.x + scrollLeft - domComponentRect.left - zoomTranslationPx.x) / zoomScale;
    const yPos = (clientPositionPx.y + scrollTop - domComponentRect.top - zoomTranslationPx.y) / zoomScale;

    const gridDropPosition = getGridPosition(
        domComponent,
        clientPositionPx,
        gridSize,
        // The canvas offset doesn't matter here
        undefined,
        zoomScale,
        zoomTranslationPx,
    );

    return {
        x: xPos - gridPointsToPixels(gridDropPosition.x, gridSize),
        y: yPos - gridPointsToPixels(gridDropPosition.y, gridSize),
    };
};

interface GetCanvasLocationResponse {
    location: MNElementLocation;
}

/**
 * Gets the element location object (what gets stored on the element model) based on
 *
 */
export const getCanvasLocationFromClientOffset = (
    canvasViewportNode: HTMLElement,
    clientPositionPx: Point,
    gridSize: number,
    parentId: string,
    canvasOrigin: Point = DEFAULT_CANVAS_ORIGIN,
    zoomScale: number,
    zoomTranslationPx: Point,
    order?: number,
): GetCanvasLocationResponse => {
    const position: MNElementPosition = getGridPosition(
        canvasViewportNode,
        clientPositionPx,
        gridSize,
        canvasOrigin,
        zoomScale,
        zoomTranslationPx,
    );

    position.order = order;

    return {
        location: {
            parentId,
            section: BoardSections.CANVAS,
            position,
        },
    };
};

/**
 * Converts a client rect into a section's coordinate system (unscaled).
 * E.g. [Canvas coordinate system]{@link https://bit.ly/3pSia9b}
 *
 * @param clientRect https://bit.ly/3dZuMsy
 * @param zoomScale https://bit.ly/3ARMo2C
 * @param zoomTranslationPx https://bit.ly/3AVbn5d
 * @param sectionClientOffset Point for the scrollable section's client offset.
 * @param sectionScroll Point for the scrollable section's current scroll.
 */
export const convertClientRectToUnscaledSectionCoordinates = (
    clientRect: Rect,
    sectionClientOffset = NO_TRANSLATION,
    sectionScroll = NO_TRANSLATION,
    zoomScale = 1,
    zoomTranslationPx = NO_TRANSLATION,
): Rect => {
    // 1 - Create a matrix to transform element clientRect into the canvas document coordinate system
    // 1.1 - Add scroll dimension - scroll position of the canvas viewport
    const translateScrollDimensionMatrix = Rematrix.translate(sectionScroll.x, sectionScroll.y);

    // 1.2 - Subtract Canvas viewport position
    const translateCanvasViewportMatrix = Rematrix.translate(-sectionClientOffset.x, -sectionClientOffset.y);

    // 1.3 - Subtract zoom translation
    const translateZoomTranslationMatrix = Rematrix.translate(-zoomTranslationPx.x, -zoomTranslationPx.y);

    // 1.4 - Divide by the zoom scale
    const zoomScaleMatrix = Rematrix.scale(1 / zoomScale);

    // NOTE: Matrix multiplication is in reverse order
    const productArray = [
        zoomScaleMatrix,
        translateZoomTranslationMatrix,
        translateCanvasViewportMatrix,
        translateScrollDimensionMatrix,
    ];

    // 1.5 - Create transformation matrix by multiplying the matrices together
    const transformationMatrix = productArray.reduce(Rematrix.multiply);

    // 2 - Convert elementRect into matrix form
    const elementRectMatrix = convertToRectPoints(clientRect);

    // 3 - Apply transformation matrix on elementRect matrix
    const unscaledElementRectMatrix = matrixLib.transformAllPoints(transformationMatrix, elementRectMatrix);

    // 4 - Convert transformed elementRect matrix into rect format
    const sectionRect = convertToRect(unscaledElementRectMatrix as RectPointVectors);

    return rectLib.roundVals(sectionRect);
};

// ======== CANVAS VIEWPORT =========

/**
 * Creates a transformation matrix to convert canvas viewport coordinates into canvas document coordinates.
 */
export const getCanvasViewportToCanvasDocumentTransformationMatrix = (
    gridSize: number,
    canvasOrigin: Point,
    zoomScale: number,
    zoomTranslationPx: Point,
): Matrix3D => {
    const reverseZoomTranslateTransformationMatrix = Rematrix.translate(
        -getPropX(zoomTranslationPx) || 0,
        -getPropY(zoomTranslationPx) || 0,
    );
    const reverseZoomScaleTransformationMatrix = Rematrix.scale(1 / zoomScale);
    const canvasOriginTranslation = Rematrix.translate(
        getPropX(canvasOrigin) * gridSize || 0,
        getPropY(canvasOrigin) * gridSize || 0,
    );

    // Applies in reverse order
    const productArray = [
        // Then translate to the canvas origin
        canvasOriginTranslation,
        // Then undo the canvas scale
        reverseZoomScaleTransformationMatrix,
        // First undo the zoom translation
        reverseZoomTranslateTransformationMatrix,
    ];

    return productArray.reduce(Rematrix.multiply);
};

/**
 * Transform a canvas viewport rectangle into the canvas document, based on the current zoom transformation and
 * canvas document origin.
 */
export const transformCanvasViewportCoordsRectIntoCanvasDocumentCoords = (
    // The rectangle should be in the coordinate system of the Canvas Viewport
    rectCVCoords: Rect,
    gridSize: number,
    canvasOrigin: Point,
    zoomScale: number,
    zoomTranslationPx: Point,
): Rect => {
    const transformationMatrix = getCanvasViewportToCanvasDocumentTransformationMatrix(
        gridSize,
        canvasOrigin,
        zoomScale,
        zoomTranslationPx,
    );
    const rectMatrix = rectMatrixUtils.convertToRectPoints(rectCVCoords);
    const transformedRectMatrix = matrixLib.transformAllPoints(transformationMatrix, rectMatrix);
    return rectMatrixUtils.convertToRect(transformedRectMatrix as RectPointVectors);
};

/**
 * Determines the visible "window" that the user can see on the canvas.
 * E.g. if the user has zoomed in or scrolled, the visible window will be only showing a portion of the actual canvas.
 *  But if zoomed out the user might be viewing the entire canvas.
 */
export const getVisibleCanvasWindowRectInGridUnits = (
    canvasViewportEl: HTMLElement,
    gridSize: number,
    zoomScale: number,
    zoomTranslationPx: Point,
    canvasOrigin?: Point,
): Rect => {
    const canvasViewportRect = canvasViewportEl.getBoundingClientRect();

    const canvasTopLeft = getGridPosition(
        canvasViewportEl,
        // We need the _client coordinates_ of the canvas top left, so we can get this from the bounding rect
        { x: canvasViewportRect.left, y: canvasViewportRect.top },
        gridSize,
        canvasOrigin,
        zoomScale,
        zoomTranslationPx,
    );

    const canvasBottomRight = getGridPosition(
        canvasViewportEl,
        // We need the _client coordinates_ of the canvas viewport bottom right, so we can get this from the bounding rect
        { x: canvasViewportRect.right, y: canvasViewportRect.bottom },
        gridSize,
        canvasOrigin,
        zoomScale,
        zoomTranslationPx,
    );

    return rectLib.asRect({
        left: canvasTopLeft.x,
        top: canvasTopLeft.y,
        right: canvasBottomRight.x,
        bottom: canvasBottomRight.y,
    });
};
