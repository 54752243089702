// Lib
import { isUndefined } from 'lodash';

// Constants
import { ELEMENT_VERSIONABLE_CONTENT_FIELDS } from '../elementConstants';

const DEFAULT_SESSION_ID = 'anon';

/**
 * Determines if the provided object has properties that should be managed by the
 * versioning system.
 */
export const hasVersionableProperties = (obj?: { [field: string]: unknown }): boolean => {
    if (!obj) return false;

    for (const field of ELEMENT_VERSIONABLE_CONTENT_FIELDS) {
        if (!isUndefined(obj[field])) return true;
    }

    return false;
};

export const VERSION_ID_REGEX = /^([a-zA-Z0-9]+)-([0-9]+)$/;

/**
 * Gets the device ID for a given version ID.
 */
export const getVersionSessionId = (versionId?: string): string => {
    if (!versionId) return '';

    const matchResult = versionId.match(VERSION_ID_REGEX);
    return matchResult?.[1] || '';
};

/**
 * Gets the version number for a given version ID.
 */
export const getVersionNumber = (versionId?: string): number => {
    if (!versionId) return 0;

    const matchResult = versionId.match(VERSION_ID_REGEX);
    return Number.parseInt(matchResult?.[2] || '0');
};

// This matches the text after the last "-" if there is one, or the whole text otherwise
const VERSION_SESSION_STRING_REGEX = /^(.*-)?([a-zA-Z0-9]+)$/;

/**
 * Retrieves the session string to use in the version ID from a session ID.
 * It strips out anything before the final "-" if one exists.
 * E.g. "msid-1PhgKtQJal" becomes "1PhgKtQJal", but "server" remains as "server"
 */
export const getVersionSessionString = (sessionId: string) => {
    if (!sessionId) return DEFAULT_SESSION_ID;

    const matches = sessionId.match(VERSION_SESSION_STRING_REGEX);

    return matches?.[2] || DEFAULT_SESSION_ID;
};

/**
 * Creates a version ID of the form "<sessionId>-<versionNumber>" where session ID is the current user's session ID
 * excluding the "msid-" prefix.
 */
export const createVersionId = (sessionId = DEFAULT_SESSION_ID, versionNumber = 1): string => {
    // Shorten the session ID to remove anything before the final "-" (usually "msid-")
    const sessionIdSubstring = getVersionSessionString(sessionId);
    return `${sessionIdSubstring}-${versionNumber}`;
};

/**
 * Makes the next version ID based on the current version ID.
 */
export const createNextVersionId = (sessionId: string, currentVersionId?: string): string => {
    const currentVersionNumber = getVersionNumber(currentVersionId) || 1;
    const nextVersionNumber = currentVersionNumber + 1;
    return createVersionId(sessionId, nextVersionNumber);
};
