// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { isRightButton } from '../../../../utils/domUtil';
import DraftLinkAnchor from './DraftLinkAnchor';
import { getLinkTarget } from './draftLinkUtil';

const allowNativeEvent = (event) => {
    // If pressing the alt key and left button, then don't open the link and just place the cursor
    if (event.altKey && event.button === 0) return true;

    // On right click, show the default right click menu
    if (isRightButton(event)) return true;

    return false;
};

const DraftClickableLinkAnchor = (props) => {
    const { className, href, target } = props;

    const onMouseDown = (event) => {
        // If event should be allowed to pass through, do nothing
        if (allowNativeEvent(event)) return;

        // Otherwise prevent the cursor from updating
        event.preventDefault();
        event.stopPropagation();

        window.open(href, getLinkTarget({ href, target }));
    };

    return (
        <DraftLinkAnchor
            {...props}
            className={classNames('clickable', className)}
            onMouseDown={onMouseDown}
            shouldHandleClickPredicate={allowNativeEvent}
        />
    );
};

DraftClickableLinkAnchor.propTypes = {
    target: PropTypes.string,
    href: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DraftClickableLinkAnchor;
