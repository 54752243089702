// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Editor
import hyperlinkOpenPopupDecorator from '../plugins/hyperlink/hyperlinkOpenPopupDecorator';
import MilanoteMentionsEditor from '../plugins/mentions/MilanoteMentionsEditor';

// Plugins
import {
    createColorConverterPlugin,
    createLinkConverterPlugin,
    createTaskConverterPlugin,
} from '../plugins/universalElement';
import { createLinkPlugin, createLinkifyPlugin } from '../plugins/link';
import createHighlightPlugin from '../plugins/textColor';
import createChecklistPlugin from '../plugins/checklist';
import createDeleteOnEmptyPlugin from '../plugins/deleteOnEmpty';
import createUndoRedoHelperPlugin from '../plugins/undoRedoHelper';
import createMarkdownPlugin from '../plugins/markdownSupport';
import createStyleShorcutsPlugin from '../plugins/styleShortcuts';
import createEditingHelperPlugin from '../plugins/editingHelper';
import createMilanoteShortcutsPlugin from '../plugins/milanoteShortcuts';
import createSearchHighlightPlugin from '../plugins/searchHighlight';
import createClippingPlugin from '../plugins/clipping';
import createCodeEditorPlugin from '../plugins/codeEditor';
import createTabReplacementPlugin from '../plugins/tabReplacementPlugin';

// Styles
import './RichTextEditor.scss';

export default ({ clickableLinks = false }) => {
    const clippingPlugin = createClippingPlugin();
    const colorConverterPlugin = createColorConverterPlugin();
    const linkConverterPlugin = createLinkConverterPlugin();
    const taskConverterPlugin = createTaskConverterPlugin();
    const checklistPlugin = createChecklistPlugin();
    const highlightPlugin = createHighlightPlugin();
    const markdownPlugin = createMarkdownPlugin({ enableBlockStyles: true });
    const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
    const undoRedoHelperPlugin = createUndoRedoHelperPlugin();
    const styleShortcutsPlugin = createStyleShorcutsPlugin({ enableBlockStyles: true });
    const editingHelperPlugin = createEditingHelperPlugin();
    const milanoteShortcutsPlugin = createMilanoteShortcutsPlugin();
    const linkifyPlugin = createLinkifyPlugin({ clickableLinks });
    const linkPlugin = createLinkPlugin({ clickableLinks });
    const searchHighlightPlugin = createSearchHighlightPlugin();
    const codeEditorPlugin = createCodeEditorPlugin();
    const tabReplacementPlugin = createTabReplacementPlugin();

    const plugins = [
        codeEditorPlugin,
        clippingPlugin,
        searchHighlightPlugin,
        highlightPlugin,
        colorConverterPlugin,
        linkConverterPlugin,
        taskConverterPlugin,
        linkPlugin,
        linkifyPlugin,
        markdownPlugin,
        deleteOnEmptyPlugin,
        undoRedoHelperPlugin,
        checklistPlugin,
        milanoteShortcutsPlugin,
        styleShortcutsPlugin,
        // Ensure this is last as it uses a handleReturn helper to remove inline styles by default
        editingHelperPlugin,
        tabReplacementPlugin,
    ];

    const RichTextEditor = (props) => (
        <MilanoteMentionsEditor
            {...props}
            plugins={plugins}
            className={classNames('RichTextEditor', props.className)}
        />
    );

    RichTextEditor.propTypes = {
        className: PropTypes.string,
    };

    return hyperlinkOpenPopupDecorator(RichTextEditor);
};
