/**
 * Due to the potential performance impacts of storing this state in redux, as well as the fact that
 * we don't require react re-rendering based on this state (at this time) I'm using a singleton here.
 *
 * Its intention at this time is to prevent element measurements during a zoom operation
 * - see measureElementDecorator.js
 *
 * If we end up needing things to re-render via react then we should revisit this.
 */

const createZoomState = () => {
    let isZooming = false;

    // A list of subscriber functions that will be invoked when zoom finishes
    let subscribers: Set<() => void> = new Set();

    /**
     * Is a zoom operation in progress.
     */
    const getIsZooming = (): boolean => isZooming;

    /**
     * Sets whether a zoom operation is in progress.
     */
    const setIsZooming = (isNowZooming: boolean) => {
        isZooming = isNowZooming;

        // If we've finished zooming, invoke each subscriber callback
        if (!isNowZooming) {
            subscribers.forEach((subscriberCallback) => {
                subscriberCallback();
            });
            subscribers = new Set();
        }
    };

    /**
     * Registers a callback function that will be invoked when we finish zooming.
     *
     * This is currently used in the measureElementDecorator.js to allow elements to
     * be re-measured when a zoom has finished, if the element has not been measured yet.
     */
    const registerZoomEndSubscriber = (subscriberCallback: () => void) => {
        subscribers.add(subscriberCallback);
    };

    return {
        getIsZooming,
        setIsZooming,
        registerZoomEndSubscriber,
    };
};

const zoomState = createZoomState();

export default zoomState;
