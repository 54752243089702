// Lib
import { includes } from 'lodash/fp';
import { RichUtils } from 'draft-js';

// Utils
import getAllSelectionInlineStyles from '../../customRichUtils/selection/getAllSelectionInlineStyles';
import { updateSelectionBlockMetadata } from '../../customRichUtils/blocks/updateBlockMetadata';
import editorGetCurrentBlock from '../../customRichUtils/editorState/editorGetCurrentBlock';
import { handleColorStyleCommand } from '../../store/reducers/handleColorStyleCommand';
import { handleCodeBlockCommand } from '../codeEditor/utils/handleCodeBlockCommand';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { BLOCK_METADATA, STYLE_COMMANDS } from '../../richText/richTextConstants';
import {
    BACKGROUND_COLOR_PREFIX,
    CUSTOM_SELECTION_STYLE,
    TEXT_BACKGROUND_PRESETS,
    TEXT_COLOR_PREFIX,
} from '../textColor/textColorConstants';

const HANDLED_INLINE_STYLES = [STYLE_COMMANDS.STRIKETHROUGH, STYLE_COMMANDS.CODE];

const HANDLED_BLOCK_STYLES = [
    STYLE_COMMANDS.UNSTYLED,
    STYLE_COMMANDS.SMALL_TEXT,
    STYLE_COMMANDS.LARGE_HEADING,
    STYLE_COMMANDS.HEADING,
    STYLE_COMMANDS.LIST,
    STYLE_COMMANDS.ORDERED_LIST,
    STYLE_COMMANDS.BLOCKQUOTE,
    STYLE_COMMANDS.CHECKLIST,
    STYLE_COMMANDS.CODE_BLOCK,
];

const getNextHighlightStyle = (currentHighlightStyle) => {
    switch (currentHighlightStyle) {
        case STYLE_COMMANDS.HIGHLIGHT:
        case TEXT_BACKGROUND_PRESETS.GREEN:
            return TEXT_BACKGROUND_PRESETS.ORANGE;
        case TEXT_BACKGROUND_PRESETS.ORANGE:
            return TEXT_BACKGROUND_PRESETS.PINK;
        case TEXT_BACKGROUND_PRESETS.PINK:
            return TEXT_BACKGROUND_PRESETS.BLUE;
        case TEXT_BACKGROUND_PRESETS.BLUE:
            return TEXT_BACKGROUND_PRESETS.GREY;
        case TEXT_BACKGROUND_PRESETS.GREY:
            return null;
        default:
            return TEXT_BACKGROUND_PRESETS.GREEN;
    }
};

const cycleThroughHighlightStyles = (editorState, eventTimeStamp, { setEditorState }) => {
    const selectedStyles = getAllSelectionInlineStyles(editorState);

    const removedStyles = [];
    let currentHighlightStyle = null;

    selectedStyles.forEach((style) => {
        const isBackgroundColor = style.startsWith(BACKGROUND_COLOR_PREFIX);
        const isLegacyHighlightColor = style === STYLE_COMMANDS.HIGHLIGHT;

        const shouldRemoveStyle =
            style.startsWith(TEXT_COLOR_PREFIX) ||
            isBackgroundColor ||
            isLegacyHighlightColor ||
            style === CUSTOM_SELECTION_STYLE;

        if (shouldRemoveStyle) removedStyles.push(style);

        if (isLegacyHighlightColor || isBackgroundColor) {
            currentHighlightStyle = style;
        }
    });

    const nextHighlightStyle = getNextHighlightStyle(currentHighlightStyle);

    const addedStyles = nextHighlightStyle ? [nextHighlightStyle] : [];

    const updatedEditorState = handleColorStyleCommand(editorState, { addedStyles, removedStyles });

    if (updatedEditorState) setEditorState(updatedEditorState);
};

export const toggleTextAlignCenter = (editorState) => {
    const currentBlock = editorGetCurrentBlock(editorState);
    if (!currentBlock) return editorState;

    const data = currentBlock.getData();
    if (!data) return editorState;

    const shouldCenter = !data.get(BLOCK_METADATA.TEXT_ALIGN_CENTER);

    const metadata = {
        [BLOCK_METADATA.TEXT_ALIGN_CENTER]: shouldCenter,
    };

    return updateSelectionBlockMetadata(editorState, metadata);
};

export default ({ enableBlockStyles }) =>
    (command, editorState, eventTimeStamp, { setEditorState }) => {
        if (command === STYLE_COMMANDS.ALIGN_CENTER) {
            const newEditorState = toggleTextAlignCenter(editorState, eventTimeStamp, { setEditorState });
            setEditorState(newEditorState);
            return HANDLER_RETURN_VALUES.handled;
        }

        if (command === STYLE_COMMANDS.CODE_BLOCK) {
            const newEditorState = handleCodeBlockCommand(editorState, command);
            setEditorState(newEditorState);
            return HANDLER_RETURN_VALUES.handled;
        }

        if (command === STYLE_COMMANDS.HIGHLIGHT) {
            cycleThroughHighlightStyles(editorState, eventTimeStamp, { setEditorState });
            return HANDLER_RETURN_VALUES.handled;
        }

        if (includes(command, HANDLED_INLINE_STYLES)) {
            const newEditorState = RichUtils.toggleInlineStyle(editorState, command);
            setEditorState(newEditorState);
            return HANDLER_RETURN_VALUES.handled;
        }

        if (enableBlockStyles && includes(command, HANDLED_BLOCK_STYLES)) {
            const newEditorState = RichUtils.toggleBlockType(editorState, command);
            setEditorState(newEditorState);
            return HANDLER_RETURN_VALUES.handled;
        }

        return HANDLER_RETURN_VALUES.notHandled;
    };
