import {
    RICH_MEDIA_EMBEDDING,
    RICH_MEDIA_ERROR,
    RICH_MEDIA_READY,
    RICH_MEDIA_RETURN_TO_PREVIEW,
} from './richMediaConstants';

export const richMediaReturnToPreview = (id) => ({
    id,
    type: RICH_MEDIA_RETURN_TO_PREVIEW,
});

export const richMediaEmbedding = (id) => ({
    id,
    type: RICH_MEDIA_EMBEDDING,
});

export const richMediaReady = (id) => ({
    id,
    type: RICH_MEDIA_READY,
});

export const richMediaError = (id) => ({
    id,
    type: RICH_MEDIA_ERROR,
});
