// Lib
import { trim } from 'lodash';

// Utils
import isOnlyColor from './utils/isOnlyColor';
import rawGetText from '../../../../../common/utils/editor/rawUtils/rawGetText';
import { isCard } from '../../../../../common/elements/utils/elementTypeUtils';
import { parseColorObject } from '../../../../../common/colors/colorObjectUtil';
import { getColorName } from '../../../../../common/colors/colorNameUtil';
import rawFromText from '../../../../../common/utils/editor/rawUtils/rawFromText';

// Constants
import { GRID } from '../../../../utils/grid/gridConstants';
import {
    COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
    COLOR_SWATCH_DEFAULT_WIDTH_GRID,
} from '../../../../../common/colorSwatches/colorSwatchesConstants';
import { ElementType } from '../../../../../common/elements/elementTypes';

export default (editorState, getProps) => {
    const { element, updateElementType, preventSaveOnUnmount, textContent } = getProps();

    if (!isCard(element) || !isOnlyColor(editorState)) return false;

    const inputText = trim(editorState.getCurrentContent().getPlainText());

    const originalText = rawGetText(textContent);
    if (originalText === inputText) return false;

    const color = parseColorObject(inputText);
    if (!color) return false;

    const colorName = getColorName(color);

    // Ensure the editor component doesn't force an update event now that we're
    // changing the component type
    preventSaveOnUnmount();
    const data = {
        color,
        showColorValue: true,
        showCaption: true,
        caption: rawFromText(colorName),
        width: COLOR_SWATCH_DEFAULT_WIDTH_GRID,
        media: {
            height: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
            width: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_WIDTH_GRID,
        },
    };

    updateElementType({ elementType: ElementType.COLOR_SWATCH_TYPE, data });

    return true;
};
