import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const CheckboxUncheckedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon checkbox-unchecked"
        {...props}
    >
        <rect width={15} height={15} x={0.5} y={0.5} fill="#FFF" fillRule="evenodd" stroke="#333D4E" rx={2} />
    </svg>
);
const Memo = memo(CheckboxUncheckedIcon);
export default Memo;
