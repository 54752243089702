// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './Button.scss';

const AnchorButton = (props) => (
    <a
        disabled={props.disabled}
        className={classNames('AnchorButton Button', props.className)}
        href={props.href}
        onMouseDown={props.onMouseDown}
        onClick={props.onClickFn || props.onClick}
        target={props.target}
        tabIndex={props.tabIndex}
    >
        {props.children}
    </a>
);

AnchorButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    onKeyDown: PropTypes.func,
    onMouseDown: PropTypes.func,
    onClickFn: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    tabIndex: PropTypes.number,
};

export default AnchorButton;
