// Lib
import * as Immutable from 'immutable';
import { AnyAction } from 'redux';

// Constants
import {
    APP_START_CURRENT_BOARD_REFRESH,
    APP_END_CURRENT_BOARD_REFRESH,
    APP_ENABLE_SOCKET_STATUS_WARNINGS,
    APP_DISABLE_SOCKET_STATUS_WARNINGS,
    APP_DID_REHYDRATE_FROM_LOCAL_CACHE,
} from './appServerReconnectionStatusConstants';

const initialState = Immutable.Map({
    isRefreshingCurrentBoard: false,
    disableSocketStatusWarnings: false,
});

export default (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case APP_START_CURRENT_BOARD_REFRESH:
            return state.set('isRefreshingCurrentBoard', true);
        case APP_END_CURRENT_BOARD_REFRESH:
            return state.set('isRefreshingCurrentBoard', false);
        case APP_DID_REHYDRATE_FROM_LOCAL_CACHE:
        case APP_DISABLE_SOCKET_STATUS_WARNINGS:
            return state.set('disableSocketStatusWarnings', true);
        case APP_ENABLE_SOCKET_STATUS_WARNINGS:
            return state.set('disableSocketStatusWarnings', false);
        default:
            return state;
    }
};
