import { useRef } from 'react';
import { getTimestamp } from '../../../../../common/utils/timeUtil';
import { DOM_POINTER_TYPES } from '../../../../utils/domConstants';

const useLastMousePointerEventAge = () => {
    const lastMousePointerDownEventTimestampRef = useRef(0);

    const recordPointerEvent = (event) => {
        if (event.pointerType === DOM_POINTER_TYPES.MOUSE) {
            lastMousePointerDownEventTimestampRef.current = getTimestamp();
        }
    };

    const getLastMouseEventAge = () => getTimestamp() - lastMousePointerDownEventTimestampRef.current;

    return {
        recordPointerEvent,
        getLastMouseEventAge,
    };
};

export default useLastMousePointerEventAge;
